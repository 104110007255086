const payConstants = {
    TEAM_MEMBER_SEATS: 'team_member_seats',
    CUSTOM_DOMAIN: 'custom_domain',
    CUSTOM_EMAIL: 'custom_email',
    EMAIL_CREDITS: 'email_credits',
    SMS_CREDITS: 'sms_credits',
    FILE_STORAGE: 'file_storage',
    CONTACT_RECORDS: 'contact_records',
    CONTACT_CONVERSATIONS: 'contact_conversations',
    CUSTOM_FILTERS: 'custom_filters',
    CUSTOM_PROPERTIES: 'custom_properties',
    CONTACT_TAGS: 'contact_tags',
    INSIGHTS: 'insights',
    TRACKED_SEO_KEYWORDS: 'tracked_seo_keywords',
    LISTING_MANAGER: 'listing_manager',

    SALES_PIPELINES: 'sales_pipelines',
    DEAL_CONVERSATIONS: 'deal_conversations',
    DEAL_CUSTOM_PROPERTIES: 'deal_custom_properties',
    DEAL_CUSTOM_FILTERS: 'deal_custom_filters',
    DEAL_AUTOMATIONS: 'deal_automations',
    DEAL_TAGS: 'deal_tags',
    FORMS: 'forms',
    GROUP_FORM: 'group_form',
    CARD_FORM: 'card_form',
    CAMPAIGNS: 'campaigns',
    ROUND_ROBIN_LEADS: 'round_robin_leads',
    AUTO_CREATE_CONTACTS: 'auto_create_contacts',
    AUTO_CREATE_DEALS: 'auto_create_deals',
    INSTAREPORTS: 'instareports',
    INSTASITES: 'instasites',
    ECOMMERCE_TEMPLATES: 'ecommerce_templates',
    ACTIVATE_SITES: 'activate_sites',
    WEBSITE_ANALYTICS: 'website_analytics',

    TEMPLATES: 'templates',

    REVIEW_REPLY: 'review_reply',
    REVIEW_INTEGRATIONS: 'review_integrations',
    REVIEW_REQUESTS: 'review_requests',

    TWO_WAY_TEXTING: 'two_way_texting',
    TWO_WAY_PHONE_CALLS: 'two_way_phone_calls',
    VOICE_NOTES: 'voice_notes',
    BRANDED_LIVE_CHAT: 'branded_live_chat',
    INBOX_ROUND_ROBIN: 'inbox_round_robin',
    CUSTOM_SUPPORT_INBOXES: 'custom_support_inboxes',
};

export default payConstants;
