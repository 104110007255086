import React from 'react';

import { SVGIcon } from '../../UIKit/index';

export default function DCMarketplace(props) {
    return (
        <SVGIcon {...props} viewBox="0 0 512 448">
            <path
                d="M512,147c0.1-2.2-0.5-4.3-1.6-6.2L434.2,6.9c-2.2-3.9-6.4-6.3-10.9-6.4H88.6c-4.5,0-8.7,2.4-10.9,6.4L1.6,140.8
	c-1,1.9-1.6,4-1.6,6.2c0,29.1,17.3,55.5,43.9,67.2V435c0,6.9,5.6,12.5,12.5,12.5h398.9c6.9,0,12.5-5.6,12.5-12.5V215.7
	c0-0.5,0-0.9-0.1-1.4C494.6,202.6,512,176.2,512,147z M95.9,25.7h320l59.5,104.7h-439L95.9,25.7z M364.4,155.4
	c-4.7,26.2-29.7,43.7-56,39c-19.9-3.6-35.5-19.1-39-39H364.4z M242.7,155.4c-4.7,26.3-29.9,43.7-56.1,39c-19.9-3.6-35.4-19.1-39-39
	H242.7z M26,155.4h95.1c-4.7,26.3-29.9,43.7-56.1,39C45.1,190.8,29.5,175.3,26,155.4z M145.8,420.4v-102c0-1.7,2-3.6,5-3.6h207.6
	c2.9,0,5,1.9,5,3.6v102c0,0.7-0.3,1.4-0.9,2c-40,0-87.8,0-126.4,0c-39.2,0-68.9,0-89.2,0.1C146.2,421.9,145.8,421.1,145.8,420.4z
	 M442.9,422.4c0,0-23.9,0-57.6,0c0.1-0.7,0.1-1.3,0.1-2v-102c0-14.1-12.1-25.6-27-25.6H150.8c-14.9,0-27,11.5-27,25.6v102
	c0,0.7,0,1.4,0.1,2.1c-7,0-10.4,0-10.4,0H69.1V220.3c1.5,0.1,2.9,0.1,4.4,0.1c24.4,0,47.3-12.2,60.9-32.4
	c22.7,33.6,68.3,42.5,102,19.8c7.8-5.3,14.5-12,19.8-19.8c22.5,33.6,67.9,42.5,101.5,20c7.9-5.3,14.7-12.1,20-20
	c13.6,20.3,36.5,32.4,60.9,32.4c1.5,0,2.8-0.1,4.3-0.1V422.4z M438.6,195.3c-23.4,0-43.4-16.7-47.5-39.8h95.1
	C481.9,178.5,461.9,195.2,438.6,195.3z"
            />
        </SVGIcon>
    );
}
