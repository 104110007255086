import React, { useState, useEffect, Fragment, useContext } from 'react';

import { Link, Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Div, Popover, Image, Strong, Button } from 'UIKit/index';
import { getAccountType } from 'Utils/auth';
import { logout } from 'apis/login/index.api';
import './style.scss';
import { getUserDetails, getAccountDetails, removeSession } from 'Utils/localStorageUtils';
import { generateUserImage } from 'Utils/generateImage';
import { useQueryClient } from 'react-query';
import { GlobalConversationsContext } from 'Contexts/Context';

const UserDropdown = ({ open, anchorEl, onClose }) => {
    const queryClient = useQueryClient();
    const { setConversationsSocket } = useContext(GlobalConversationsContext);
    const [isRedirect, setIsRedirect] = useState(false);
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [accountId, setAccountId] = useState('');
    const [image, setImage] = useState('');
    const [isLoading, setIsLoading] = useState(false);

    const handleSignout = async () => {
        setIsLoading(true);
        const res = await logout();
        if (res.success) {
            removeSession();
            setConversationsSocket(null);
            queryClient.resetQueries();
            setIsRedirect(true);
        }
        setIsLoading(false);
    };

    useEffect(() => {
        const userObject = getUserDetails();
        const accountObject = getAccountDetails();
        if (userObject) {
            setName(userObject.name);
            setEmail(userObject.email);
            setAccountId(accountObject.id);
            setImage(userObject.image);
        }
    }, []);

    if (isRedirect) {
        return <Redirect to="/auth/login" />;
    }

    return (
        <Popover
            open={open}
            anchorEl={anchorEl}
            onClose={onClose}
            width={300}
            withOutPadding={true}
            customClass={'UserDropdown ArrowPopover'}
        >
            <Div className={'UserDropdownInfo'}>
                <Link to={'/settings/general?tab=my-profile'} onClick={onClose}>
                    <Div className={'UDIImg'}>
                        <Image src={generateUserImage(image)} alt={name} displayName={''} />
                    </Div>
                    <Div className={'UDIName'}>{name}</Div>
                    <Div className={'UDIMail'}>{email}</Div>
                </Link>
            </Div>
            <Div className={'UDAcID'}>
                Account <Strong>#{accountId.slice(-8)}</Strong>
            </Div>
            <Div className={'UDMenu'}>
                {getAccountType() === 'main' && getUserDetails()?.is_owner && (
                    <Fragment>
                        <Link to={'/subaccounts'} onClick={onClose}>
                            Sub-Accounts
                        </Link>
                        <Link to={'/affiliate-center'} onClick={onClose}>
                            Affiliate Center
                        </Link>
                    </Fragment>
                )}
                <Link to={'/settings'} onClick={onClose}>
                    Settings
                </Link>
            </Div>
            <Div className={'UDBtns'}>
                <Button onClick={handleSignout} loading={isLoading}>
                    Sign Out
                </Button>
            </Div>
        </Popover>
    );
};

export default UserDropdown;

UserDropdown.propTypes = {
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    anchorEl: PropTypes.oneOfType([PropTypes.object, PropTypes.arrayOf(PropTypes.object)]),
};
