import { instaSiteActions } from '../Actions';

const instaSiteReducer = (state, action) => {
    switch (action.type) {
        case instaSiteActions.SET_CATEGORIES:
            return { ...state, categories: action.value };
        case instaSiteActions.SET_MAIN_CATEGORIES:
            return { ...state, mainCategory: action.value };
        case instaSiteActions.SET_TEMPLATES_DATA:
            return { ...state, templates: action.value };
        case instaSiteActions.SET_INITIAL_TEMPLATE_LOAD:
            return { ...state, initialTemplateLoad: action.value };
        case instaSiteActions.SET_ACTIVE_CATEGORY:
            return { ...state, activeCategory: action.value };
        case instaSiteActions.SET_TEMPLATE_SEARCH_TEXT:
            return { ...state, templateSearchText: action.value };

        case instaSiteActions.SET_STATUS:
            return { ...state, status: action.value };
        case instaSiteActions.SET_INITIAL_MY_INSTASITE_LOAD:
            return { ...state, initialMyInstasiteLoad: action.value };
        case instaSiteActions.SET_FIELDS:
            return { ...state, fields: action.value };
        case instaSiteActions.SET_PAGE:
            return { ...state, page: action.value };

        case instaSiteActions.SET_TOTAL_RESULTS:
            return { ...state, totalResults: action.value };
        case instaSiteActions.SET_TOTAL_PAGES:
            return { ...state, totalPages: action.value };
        case instaSiteActions.SET_PAGE_LIMIT:
            return { ...state, pageLimit: action.value };
        case instaSiteActions.SET_DATA:
            return { ...state, data: action.value };
        case instaSiteActions.SET_SORT_STRING:
            return { ...state, sortString: action.value };

        case instaSiteActions.SET_MY_INSTASITE_SEARCH_TEXT:
            return { ...state, myInstaSiteSearchText: action.value };

        case instaSiteActions.SET_SELECTED_INSTASITE:
            return { ...state, selectedInstaSite: action.value };
        case instaSiteActions.SET_STATUS_STRING:
            return { ...state, statusString: action.value };
        case instaSiteActions.SET_ADDITIONAL_INFO:
            return { ...state, additionalInfo: action.value };

        default:
            return;
    }
};

export default instaSiteReducer;
