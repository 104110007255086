import React from 'react';

import { SVGIcon } from '../../UIKit/index';

export default function DCDataTransfer(props) {
    return (
        <SVGIcon {...props} viewBox="0 0 95.033 108.799">
            <g transform="translate(-42 -10.4)">
                <circle
                    cx="47.428"
                    cy="47.428"
                    r="47.428"
                    transform="translate(42.178 15.862)"
                    fill="#f3f9ff"
                />
                <path
                    d="M118.914,215.7a2.053,2.053,0,0,0-1.821,1.843V268.1a2.007,2.007,0,0,0,1.821,2.087h59.418l12.434-37.036s.466-1.754-1.865-2.487h-10.68v-6.994s.089-1.421-2.065-1.887H141.5s-.888.244-1.932-.888-3.952-4.4-3.952-4.4a2.4,2.4,0,0,0-1.4-.8h-15.3Z"
                    transform="translate(-58.418 -159.715)"
                    fill="#2b88f8"
                />
                <path
                    d="M117.357,319.729s-.577,2.354,1.6,2.842l59.484.311,12.7-37.547a1.985,1.985,0,0,0-2.043-2.2H132.211s-1.377-.311-2.065,1.643Z"
                    transform="translate(-58.571 -212.164)"
                    fill="#d9eaff"
                />
                <circle
                    cx="14.299"
                    cy="14.299"
                    r="14.299"
                    transform="translate(101.862 90.601)"
                    fill="#2b88f8"
                />
                <circle
                    cx="1.932"
                    cy="1.932"
                    r="1.932"
                    transform="translate(81.234 24.788)"
                    fill="#fff"
                    stroke="#aacffc"
                    strokeMiterlimit="10"
                    strokeWidth="4.351"
                />
                <circle
                    cx="1.932"
                    cy="1.932"
                    r="1.932"
                    transform="translate(125.687 59.648)"
                    fill="#fff"
                    stroke="#aacffc"
                    strokeMiterlimit="10"
                    strokeWidth="4.351"
                />
                <path
                    d="M315.747,201.7h-7.882a.525.525,0,0,0-.311.755l4.063,4.086s.022.155.422,0l4.108-4.086A.587.587,0,0,0,315.747,201.7Z"
                    transform="translate(-206.536 -148.824)"
                    fill="#00acfd"
                />
                <rect
                    width="1.532"
                    height="4.396"
                    transform="translate(104.615 45.771)"
                    fill="#00acfd"
                />
                <path
                    d="M307.344,134.9s2.8,1.288,3.442,3.175l-1.177.844s-1.443-2.176-2.909-2.553Z"
                    transform="translate(-205.926 -96.856)"
                    fill="#00acfd"
                />
                <rect
                    width="4.33"
                    height="1.621"
                    transform="translate(92.558 37.444)"
                    fill="#00acfd"
                />
                <rect
                    width="4.33"
                    height="1.621"
                    transform="translate(83.899 37.444)"
                    fill="#00acfd"
                />
                <rect
                    width="4.33"
                    height="1.621"
                    transform="translate(75.395 37.444)"
                    fill="#00acfd"
                />
                <path
                    d="M159.052,124.754l-.333,1.576s-2.887-.91-4.019-2.576l1.132-.955S157.52,124.621,159.052,124.754Z"
                    transform="translate(-87.676 -87.443)"
                    fill="#00acfd"
                />
                <rect
                    width="1.621"
                    height="4.374"
                    transform="translate(65.314 27.608)"
                    fill="#00acfd"
                />
                <rect
                    width="1.621"
                    height="4.374"
                    transform="translate(65.314 19.037)"
                    fill="#00acfd"
                />
                <rect
                    width="1.621"
                    height="4.374"
                    transform="translate(65.314 10.4)"
                    fill="#00acfd"
                />
                <g transform="translate(110.521 96.707)">
                    <path
                        d="M354.53,399.1l-3.93,3.863,3.775,3.775v-2.62h7.194v-2.243H354.53Z"
                        transform="translate(-350.6 -399.1)"
                        fill="#fff"
                    />
                    <path
                        d="M359.039,446.138l3.93-3.863-3.775-3.775v2.62H352v2.243h7.039Z"
                        transform="translate(-351.689 -429.752)"
                        fill="#fff"
                    />
                </g>
                <rect
                    width="1.243"
                    height="4.729"
                    transform="translate(43.665 93.065)"
                    fill="#2b88f8"
                />
                <rect width="4.752" height="1.31" transform="translate(42 94.686)" fill="#2b88f8" />
            </g>
        </SVGIcon>
    );
}
