export const SliderImages = [
    {
        id: 0,
        image: 'https://www.dashclicks.com/wp-content/uploads/2021/10/Screen-Shot-2021-12-08-at-3.46.21-PM.png',
    },
    {
        id: 1,
        image: 'https://www.dashclicks.com/wp-content/uploads/2021/09/Screen-Shot-2021-12-03-at-4.20.06-PM.png',
    },
    {
        id: 2,
        image: 'https://www.dashclicks.com/wp-content/uploads/2021/10/Screen-Shot-2021-12-03-at-1.05.14-PM.png',
    },
    {
        id: 3,
        image: 'https://www.dashclicks.com/wp-content/uploads/2021/12/screencapture-demo-beta-dashclicks-dashboard-2021-12-03-12_22_36.png',
    },
    {
        id: 4,
        image: 'https://www.dashclicks.com/wp-content/uploads/2021/12/Screen-Shot-2021-12-03-at-12.26.21-PM.png',
    },
    {
        id: 5,
        image: 'https://www.dashclicks.com/wp-content/uploads/2021/09/Screen-Shot-2021-12-03-at-4.20.06-PM.png',
    },
];

export const Emailverifyimage = [
    {
        id: 0,
        image: 'https://www.dashclicks.com/wp-content/themes/dashclicks/assets/images/img_email.png',
    },
];
