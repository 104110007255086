export const fieldTypes = {
    Single_Line_Text: 'Single Line Text',
    Multi_Line_Text: 'Multi Line Text',
    Number: 'Number',
    Phone_Number: 'Phone Number',
    Website: 'Website',
    Email_Address: 'Email Address',
    Date_Time: 'Date & Time',
    Dropdown_Option: 'Dropdown Option',
    Yes_No: 'Yes/No',
    Multiple_Checkbox: 'Multiple Checkbox',
    Date_Picker: 'Date Picker',
    Time_Picker: 'Time Picker',
};

export const manageColumnSources = {
    people: 'people',
    businessess: 'businessess',
    inbound: 'inbound',
    Single_Line_Text: 'Single Line Text',
    Multi_Line_Text: 'Multi Line Text',
    Number: 'Number',
    Dropdown_Option: 'Dropdown Option',
    Single_Checkbox: 'Single Checkbox',
    Multiple_Checkbox: 'Multiple Checkbox',
    Date_Picker: 'Date Picker',
    Time_Picker: 'Time Picker',
};
