import React from 'react';

import { SVGIcon } from '../../UIKit/index';

export default function DCMessage(props) {
    return (
        <SVGIcon {...props} viewBox="0 0 512 512">
            <g>
                <path d="m0 349.022c0 12.187 13.808 19.277 23.711 12.211l82.471-58.832c6.917-4.934 15.065-7.542 23.563-7.542h181.382c31.928 0 57.902-25.975 57.902-57.902v-190.331c0-8.284-6.716-15-15-15h-296.127c-31.927 0-57.902 25.974-57.902 57.902zm30-259.494c0-15.386 12.517-27.902 27.902-27.902h281.126v175.331c0 15.386-12.517 27.902-27.902 27.902h-181.382c-14.78 0-28.952 4.537-40.984 13.12l-58.76 41.917z" />
                <path d="m512 205.876c0-31.932-25.974-57.91-57.9-57.91h-40.07c-8.284 0-15 6.716-15 15s6.716 15 15 15h40.07c15.385 0 27.9 12.521 27.9 27.91v230.364l-58.759-41.915c-12.031-8.583-26.202-13.119-40.981-13.119h-181.39c-15.385 0-27.9-12.521-27.9-27.91v-13.439c0-8.284-6.716-15-15-15s-15 6.716-15 15v13.439c0 31.932 25.974 57.91 57.9 57.91h181.39c8.497 0 16.644 2.607 23.56 7.541l82.47 58.83c9.853 7.031 23.711.015 23.711-12.211v-259.49z" />
                <path d="m107.862 143.339h173.304c8.284 0 15-6.716 15-15s-6.716-15-15-15h-173.304c-8.284 0-15 6.716-15 15s6.716 15 15 15z" />
                <path d="m107.862 213.339h173.304c8.284 0 15-6.716 15-15s-6.716-15-15-15h-173.304c-8.284 0-15 6.716-15 15s6.716 15 15 15z" />
            </g>
        </SVGIcon>
    );
}
