import React from 'react';

import { SVGIcon } from '../../UIKit/index';

export default function DCIntegration(props) {
    return (
        <SVGIcon {...props} viewBox="0 0 24 24">
            <g>
                <path d="m12.5 20h-9.9c-1.479 0-2.6-1.122-2.6-2.5v-11c0-1.378 1.121-2.5 2.5-2.5h9.9c.378 0 .652.046.915.138.261.091.398.376.307.637-.09.262-.374.398-.637.308-.155-.056-.319-.083-.485-.083h-9.9c-.927 0-1.6.673-1.6 1.5v11c0 .827.673 1.5 1.5 1.5h9.9c.266 0 .43-.027.585-.082.262-.092.546.046.637.308.092.261-.046.546-.307.637-.262.091-.537.137-.815.137z" />
                <path d="m12.399 5c-.253 0-.471-.192-.496-.45l-.311-3.1c-.025-.253-.24-.45-.492-.45h-7.2c-.252 0-.467.197-.492.449l-.311 3.101c-.028.275-.276.475-.547.448-.275-.028-.476-.273-.447-.548l.311-3.1c.073-.769.713-1.35 1.486-1.35h7.2c.773 0 1.413.581 1.488 1.351l.31 3.099c.028.275-.173.52-.447.547-.017.002-.035.003-.052.003z" />
                <path d="m11.1 24h-7.2c-.773 0-1.413-.581-1.488-1.351l-.31-3.099c-.028-.275.173-.52.447-.547.27-.027.52.173.547.448l.311 3.1c.026.252.241.449.493.449h7.2c.252 0 .468-.197.492-.449l.312-3.101c.027-.275.28-.473.547-.448.274.027.476.272.447.547l-.311 3.1c-.074.77-.714 1.351-1.487 1.351z" />
                <path d="m21.5 14c-1.103 0-2-.897-2-2s.897-2 2-2 2 .897 2 2-.897 2-2 2zm0-3c-.552 0-1 .449-1 1s.448 1 1 1 1-.449 1-1-.448-1-1-1z" />
                <path d="m20 12.5h-10c-.276 0-.5-.224-.5-.5s.224-.5.5-.5h10c.276 0 .5.224.5.5s-.224.5-.5.5z" />
                <path d="m13.5 9h-3.5c-.276 0-.5-.224-.5-.5s.224-.5.5-.5h3.293l3.354-3.354c.093-.093.22-.146.353-.146h3c.276 0 .5.224.5.5s-.224.5-.5.5h-2.793l-3.354 3.354c-.093.093-.22.146-.353.146z" />
                <path d="m21.5 7c-1.103 0-2-.897-2-2s.897-2 2-2 2 .897 2 2-.897 2-2 2zm0-3c-.552 0-1 .449-1 1s.448 1 1 1 1-.449 1-1-.448-1-1-1z" />
                <path d="m20 19.5h-3c-.133 0-.26-.053-.354-.146l-3.353-3.354h-3.293c-.276 0-.5-.224-.5-.5s.224-.5.5-.5h3.5c.133 0 .26.053.354.146l3.354 3.354h2.792c.276 0 .5.224.5.5s-.224.5-.5.5z" />
                <path d="m21.5 21c-1.103 0-2-.897-2-2s.897-2 2-2 2 .897 2 2-.897 2-2 2zm0-3c-.552 0-1 .449-1 1s.448 1 1 1 1-.449 1-1-.448-1-1-1z" />
            </g>
        </SVGIcon>
    );
}
