import React, { useState, useCallback } from 'react';
import { Dialog, Div, Image, Input, Checkbox, Link, Button } from '../../../UIKit/index';

import DCAgency from '../../../Assets/icons/DCAgency';
import DCAnalytics from '../../../Assets/icons/DCAnalytics';
import DCContacts from '../../../Assets/icons/DCContacts';
import DCDashboardMenu from '../../../Assets/icons/DCDashboardMenu';
import DCDealMenu from '../../../Assets/icons/DCDealMenu';
import DCForms from '../../../Assets/icons/DCForms';
import DCInbound from '../../../Assets/icons/DCInbound';
import DCInstareports from '../../../Assets/icons/DCInstareports';
import DCInstasites from '../../../Assets/icons/DCInstasites';
import DCLeads from '../../../Assets/icons/DCLeads';
import DCProject from '../../../Assets/icons/DCProject';
import DCTemplates from '../../../Assets/icons/DCTemplates';
import MacView from '../../../Assets/images/macView.png';
import SkyLogo from '../../../Assets/images/skylogo.png';
import TestView from '../../../Assets/images/test.png';
import '../style.scss';

const LoginDialog = ({ open, handleClose }) => {
    const [rememberMe, setRememberMe] = useState(false);
    const handleRememberMe = useCallback(e => {
        setRememberMe(e.target.checked);
    }, []);
    return (
        <Dialog
            dialogType={'centerZoom sizeLg'}
            open={open}
            onClose={handleClose}
            classes={{ row: 'LoginDialogRow' }}
        >
            <Div className="LoginPage">
                <Div className="LoginPageInner">
                    <Div className="LPIFormSection">
                        <Div className="LPIFSInner">
                            <Div className="LPIFSIHeader">
                                <Div className="LPIFSIHLogo">
                                    <Image src={SkyLogo} alt={''} />
                                </Div>
                            </Div>
                            <Div className="LPIFSIBody">
                                <Div className="LPIFSIFormBox">
                                    <Div className="LPIFSIFBTitle">Login</Div>
                                    <Div className="LPIFSIFBForm">
                                        <Input type="email" placeholder={'Email address'} />
                                        <Input type="password" placeholder={'Password'} />
                                        <Div className="LPIFSIFBFRF">
                                            <Div className="LPIFSIFBFRFRM">
                                                <Checkbox
                                                    unCheckColor={'#f6f8fc'}
                                                    checkColor={'#f6f8fc'}
                                                    label={'Remember me'}
                                                    checked={rememberMe}
                                                    onChange={handleRememberMe}
                                                />
                                            </Div>
                                            <Div className="LPIFSIFBFRFFP">
                                                <Link href={'#'}>Forgot Password?</Link>
                                            </Div>
                                        </Div>
                                        <Div className="LPIFSIFBFSBtn">
                                            <Button
                                                fullWidth
                                                buttonType={'BlueFillBtn'}
                                                // onClick={}
                                            >
                                                Log in
                                            </Button>
                                        </Div>
                                    </Div>
                                </Div>
                            </Div>
                        </Div>
                    </Div>
                    <Div className="LPIAppsIcons">
                        <Div className="LPIAIInnner">
                            <Div className="LPIAIIScreen">
                                <Image src={MacView} alt={''} className="LPIAIISVImg" />
                                <Div className="LPIAIISWeb">
                                    <Image src={TestView} alt={''} className="LPIAIISWVImg" />
                                </Div>
                            </Div>
                            <Div className="LPIAIIApps">
                                <Div className="LPIAIIAIcon LPIAIIAI1">
                                    <DCAgency />
                                </Div>
                                <Div className="LPIAIIAIcon LPIAIIAI2">
                                    <DCAnalytics />
                                </Div>
                                <Div className="LPIAIIAIcon LPIAIIAI3">
                                    <DCContacts />
                                </Div>
                                <Div className="LPIAIIAIcon LPIAIIAI4">
                                    <DCDashboardMenu />
                                </Div>
                                <Div className="LPIAIIAIcon LPIAIIAI5">
                                    <DCDealMenu />
                                </Div>
                                <Div className="LPIAIIAIcon LPIAIIAI6">
                                    <DCForms />
                                </Div>
                                <Div className="LPIAIIAIcon LPIAIIAI7">
                                    <DCInbound />
                                </Div>
                                <Div className="LPIAIIAIcon LPIAIIAI8">
                                    <DCInstareports />
                                </Div>
                                <Div className="LPIAIIAIcon LPIAIIAI9">
                                    <DCInstasites />
                                </Div>
                                <Div className="LPIAIIAIcon LPIAIIAI10">
                                    <DCLeads />
                                </Div>
                                <Div className="LPIAIIAIcon LPIAIIAI11">
                                    <DCProject />
                                </Div>
                                <Div className="LPIAIIAIcon LPIAIIAI12">
                                    <DCTemplates />
                                </Div>
                            </Div>
                        </Div>
                    </Div>
                </Div>
            </Div>
        </Dialog>
    );
};

export default LoginDialog;
