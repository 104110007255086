import React from 'react';

import { SVGIcon } from '../../UIKit/index';

export default function DCSeo(props) {
    return (
        <SVGIcon {...props} viewBox="0 0 512 512">
            <g transform="translate(0 0)">
                <path
                    fill="#F78E36"
                    d="M466.4,510.2L363.1,406.8c-2.4-2.4-2.4-6.3-0.1-8.7c0,0,0,0,0.1-0.1l34.9-34.9   c2.4-2.4,6.3-2.4,8.7-0.1c0,0,0,0,0.1,0.1l103.3,103.4c2.4,2.4,2.4,6.3,0.1,8.7c0,0,0,0-0.1,0.1l-34.9,34.9   C472.8,512.6,468.9,512.6,466.4,510.2C466.5,510.2,466.5,510.2,466.4,510.2z"
                />
                <path
                    fill="#F86F1B"
                    d="M398.1,363.2L376.3,385c2.4-2.4,6.3-2.4,8.7-0.1c0,0,0,0,0.1,0.1l103.3,103.3   c2.4,2.4,2.4,6.3,0.1,8.7c0,0,0,0-0.1,0.1l21.8-21.8c2.4-2.4,2.4-6.3,0.1-8.7c0,0,0,0-0.1-0.1L406.9,363.1   C404.5,360.7,400.6,360.7,398.1,363.2C398.1,363.1,398.1,363.1,398.1,363.2z"
                />
                <path fill="#4380A0" d="M322,344l21.8-21.8l47.6,47.6l-21.8,21.8L322,344z" />
                <path fill="#7BE492" d="M382.3,426.1l43.7-43.7l21.8,21.8l-43.7,43.7L382.3,426.1z" />
                <path fill="#45D987" d="M404.1,404.3l21.8-21.8l21.8,21.8L426,426.1L404.1,404.3z" />
                <circle fill="#43809F" cx="201.1" cy="201.2" r="200.7" />
                <path
                    fill="#3A7190"
                    d="M201.1,0.5c-5.2,0-10.3,0.2-15.4,0.6c110.5,8.6,193.2,105.1,184.6,215.7   c-7.7,98.6-86,176.9-184.6,184.6c5.1,0.4,10.2,0.6,15.4,0.6c110.8-0.9,200-91.4,199.1-202.3C399.3,90,310.7,1.3,201.1,0.5   L201.1,0.5z"
                />
                <circle fill="#F3F3F3" cx="201.1" cy="201.2" r="169.8" />
                <path
                    fill="#E4E4E4"
                    d="M201.1,31.3c-5.2,0-10.4,0.3-15.4,0.7c93.4,8.6,162.2,91.2,153.6,184.6   c-7.5,81.5-72.1,146.1-153.6,153.6c5.1,0.5,10.2,0.7,15.4,0.7c93.8,0,169.8-76,169.8-169.8S294.9,31.4,201.1,31.3   C201.1,31.3,201.1,31.3,201.1,31.3L201.1,31.3z"
                />
                <g transform="translate(7.817 7.618)">
                    <path
                        fill="#29CEF6"
                        d="M152.4,222.2H90.7v-60.5c0-1.5,1.8-2.7,4.1-2.7h53.5c2.3,0,4.1,1.3,4.1,2.7L152.4,222.2z"
                    />
                    <path
                        fill="#22B5ED"
                        d="M121.5,159v63.2h30.9v-60.5c0-1.5-1.8-2.7-4.1-2.7H121.5z"
                    />
                    <path
                        fill="#7BE492"
                        d="M275.9,222.2h-61.7V110.9c0-4.9,1.8-8.9,4.1-8.9h53.5c2.3,0,4.1,4,4.1,8.9L275.9,222.2z"
                    />
                    <path
                        fill="#45D987"
                        d="M245.1,102v120.2h30.9V110.9c0-4.9-1.8-8.9-4.1-8.9L245.1,102z"
                    />
                    <path
                        fill="#F78E36"
                        d="M214.2,222.2h-61.8v-91.1c0-1.6,1.8-2.9,4.1-2.9h53.5c2.3,0,4.1,1.3,4.1,2.9V222.2z"
                    />
                    <path
                        fill="#F86F1B"
                        d="M183.3,128.2v94h30.9v-91.1c0-1.6-1.8-2.9-4.1-2.9L183.3,128.2z"
                    />
                </g>
                <path
                    fill="#43809F"
                    d="M301.8,222.4H80.4c-4.1,0.4-7.2,4.1-6.7,8.2c0.4,3.6,3.2,6.4,6.7,6.7h221.4   c4.1-0.4,7.2-4.1,6.7-8.2C308.2,225.5,305.4,222.7,301.8,222.4z"
                />
                <path
                    fill="#29CEF6"
                    d="M267.5,290.8h-32.2l-14.6-37.4c-1.3-3.4-5.2-5.1-8.6-3.7c-1.8,0.7-3.1,2.1-3.8,3.9l-20.1,54.2   l-14.1-40c-1.2-3.4-5-5.3-8.4-4c-1.5,0.5-2.8,1.6-3.6,3l-13.4,24h-32.1c-3.7,0-6.6,3-6.6,6.6c0,3.7,3,6.6,6.6,6.6h36   c2.4,0,4.6-1.3,5.8-3.4l8.2-14.6l15.3,43.4c0.9,2.6,3.4,4.4,6.2,4.4l0,0c2.8,0,5.2-1.7,6.2-4.3l20.4-55.1l9.9,25.4   c1,2.5,3.4,4.2,6.2,4.2h36.7c3.7,0,6.6-3,6.6-6.6C274.1,293.7,271.1,290.8,267.5,290.8L267.5,290.8z"
                />
            </g>
        </SVGIcon>
    );
}
