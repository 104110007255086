import React from 'react';

import { SVGIcon } from '../../UIKit/index';

export default function DCWhatsNew(props) {
    return (
        <SVGIcon {...props} viewBox="0 0 42 42" fill="none">
            <g clip-path="url(#clip0_66_676)">
                <path
                    d="M20 14.5037C19.2149 14.5037 18.4355 14.6381 17.6958 14.9011L5.63172 19.1905L20 24.6516L20.1993 24.4965V14.508C20.1329 14.506 20.0665 14.5037 20 14.5037Z"
                    fill="#C78A6B"
                />
                <path
                    d="M12.427 16.7744L5.63172 19.1904L12.3501 21.7439C10.8382 20.4583 10.872 18.0147 12.427 16.7744Z"
                    fill="#D08459"
                />
                <path
                    d="M22.3042 14.9011C21.5645 14.638 20.7852 14.5037 20 14.5037V24.5712L34.3683 19.1905L22.3042 14.9011Z"
                    fill="#E19974"
                />
                <path
                    d="M20 24.3296L17.5704 27.201C17.369 27.4791 17.0081 27.5908 16.6848 27.4752L5.63172 23.5217V34.1671C5.63172 34.7982 6.02828 35.3612 6.6225 35.5738L18.0532 39.6623C18.678 39.8857 19.3365 39.9999 20 39.9999C20.0665 39.9999 20.1329 39.9979 20.1993 39.9956V24.5718L20 24.3296Z"
                    fill="#C78A6B"
                />
                <path
                    d="M8.50828 34.9893V24.5506L5.63172 23.5217V34.1671C5.63172 34.7982 6.02828 35.3612 6.6225 35.5738L9.20953 36.4991C8.77407 36.1302 8.50828 35.583 8.50828 34.9893Z"
                    fill="#D08459"
                />
                <path
                    d="M23.3152 27.5557C22.9919 27.6714 22.631 27.5596 22.4296 27.2816L20 24.3297V40C20.6635 40 21.322 39.8858 21.9468 39.6624L33.3775 35.5739C33.9717 35.3614 34.3683 34.7983 34.3683 34.1672V23.6023L23.3152 27.5557Z"
                    fill="#E19974"
                />
                <path
                    d="M20 3.82837C19.6666 3.82837 19.3964 4.0986 19.3964 4.43196V11.9759C19.3964 12.3093 19.6666 12.5795 20 12.5795C20.3334 12.5795 20.6036 12.3092 20.6036 11.9759V4.43196C20.6036 4.0986 20.3334 3.82837 20 3.82837Z"
                    fill="#FF636E"
                />
                <path
                    d="M28.7877 5.89715C28.6542 5.5916 28.2982 5.4523 27.9928 5.5859C27.8205 5.66129 23.763 7.4898 23.0923 12.5193C23.0482 12.8497 23.2803 13.1534 23.6108 13.1975C23.941 13.2415 24.2448 13.0095 24.2889 12.6789C24.5309 10.864 25.3037 9.30839 26.5854 8.05504C27.5548 7.10722 28.4689 6.69535 28.478 6.69129C28.7824 6.5573 28.921 6.20215 28.7877 5.89715Z"
                    fill="#60B8FF"
                />
                <path
                    d="M32.509 9.46019C31.0935 9.81011 28.4652 10.8402 26.7661 13.8086C26.6005 14.0979 26.7008 14.4668 26.9901 14.6323C27.2792 14.7978 27.6481 14.6978 27.8138 14.4083C29.2158 11.9591 31.2984 11.0029 32.7987 10.6322C33.1223 10.5522 33.3198 10.225 33.2398 9.90136C33.1598 9.57769 32.8327 9.38011 32.509 9.46019Z"
                    fill="#FF636E"
                />
                <path
                    d="M12.0072 5.58577C11.7016 5.45241 11.3459 5.59163 11.2123 5.89702C11.0787 6.20241 11.218 6.55835 11.5234 6.69194C11.5595 6.70765 15.1291 8.31608 15.711 12.6788C15.7552 13.0101 16.0596 13.2414 16.3891 13.1974C16.7195 13.1533 16.9517 12.8497 16.9077 12.5193C16.237 7.48976 12.1795 5.66116 12.0072 5.58577Z"
                    fill="#60B8FF"
                />
                <path
                    d="M7.49102 9.46021C7.1675 9.38005 6.84016 9.57779 6.76016 9.90138C6.68016 10.2251 6.87766 10.5522 7.20125 10.6322C8.70149 11.003 10.7841 11.9591 12.1861 14.4083C12.3518 14.6979 12.7208 14.7979 13.0098 14.6323C13.2991 14.4667 13.3995 14.0979 13.2338 13.8086C11.5348 10.8402 8.90649 9.81013 7.49102 9.46021Z"
                    fill="#FF636E"
                />
                <path
                    d="M33.0963 5.5353H32.6759V5.11507C32.6759 4.78163 32.4057 4.51147 32.0723 4.51147C31.739 4.51147 31.4688 4.78171 31.4688 5.11507V5.5353H31.0484C30.7151 5.5353 30.4448 5.80554 30.4448 6.1389C30.4448 6.47226 30.7151 6.74249 31.0484 6.74249H31.4688V7.1628C31.4688 7.49624 31.739 7.7664 32.0723 7.7664C32.4057 7.7664 32.6759 7.49616 32.6759 7.1628V6.74249H33.0963C33.4296 6.74249 33.6998 6.47226 33.6998 6.1389C33.6998 5.80554 33.4297 5.5353 33.0963 5.5353Z"
                    fill="#FFE02F"
                />
                <path
                    d="M8.37008 13.9001H7.94977V13.4798C7.94977 13.1464 7.67953 12.8762 7.34617 12.8762C7.01281 12.8762 6.74258 13.1465 6.74258 13.4798V13.9001H6.32227C5.98891 13.9001 5.71867 14.1704 5.71867 14.5037C5.71867 14.8372 5.98891 15.1073 6.32227 15.1073H6.74258V15.5276C6.74258 15.8611 7.01281 16.1312 7.34617 16.1312C7.67953 16.1312 7.94977 15.861 7.94977 15.5276V15.1073H8.37008C8.70344 15.1073 8.97367 14.8371 8.97367 14.5037C8.97367 14.1704 8.70344 13.9001 8.37008 13.9001Z"
                    fill="#FFE02F"
                />
                <path
                    d="M26.7762 1.79461C26.1083 1.45289 25.5627 0.906406 25.2226 0.237578C25.1487 0.0923437 25.0004 0 24.8373 0C24.6743 0 24.526 0.0923437 24.4521 0.237578C24.112 0.906406 23.5663 1.45289 22.8984 1.79461C22.7537 1.86867 22.6618 2.01672 22.6618 2.1793V2.18992C22.6618 2.35078 22.7508 2.49937 22.8941 2.57242C23.5599 2.91195 24.1041 3.45492 24.4453 4.11977C24.5186 4.26258 24.6668 4.35109 24.8273 4.35109H24.8474C25.0079 4.35109 25.1561 4.26258 25.2294 4.11977C25.5705 3.45492 26.1148 2.91203 26.7805 2.57242C26.9239 2.4993 27.0129 2.35078 27.0129 2.18992V2.1793C27.0129 2.01672 26.9211 1.86867 26.7762 1.79461Z"
                    fill="#FFE02F"
                />
                <path
                    d="M17.0352 3.57913C16.5354 3.32343 16.127 2.91437 15.8725 2.4139C15.8172 2.30515 15.7062 2.23608 15.5842 2.23608C15.4623 2.23608 15.3513 2.30515 15.2959 2.4139C15.0414 2.91444 14.6331 3.32343 14.1332 3.57913C14.0249 3.6346 13.9561 3.74538 13.9561 3.86702V3.87491C13.9561 3.9953 14.0227 4.10647 14.13 4.16116C14.6283 4.4153 15.0356 4.82163 15.2909 5.31913C15.3457 5.42601 15.4566 5.49226 15.5767 5.49226H15.5918C15.7119 5.49226 15.8228 5.42601 15.8777 5.31913C16.133 4.82163 16.5403 4.41522 17.0385 4.16116C17.1458 4.10647 17.2124 3.9953 17.2124 3.87491V3.86694C17.2124 3.74538 17.1436 3.6346 17.0352 3.57913Z"
                    fill="#FFEA7E"
                />
                <path
                    d="M24.524 2.57242C24.3806 2.4993 24.2916 2.35078 24.2916 2.18992V2.1793C24.2916 2.01664 24.3835 1.86867 24.5283 1.79461C24.9624 1.5725 25.3444 1.26352 25.6523 0.891016C25.4864 0.690391 25.3416 0.471719 25.2226 0.237578C25.1487 0.0923437 25.0004 0 24.8373 0C24.6743 0 24.526 0.0923437 24.4521 0.237578C24.112 0.906406 23.5663 1.45289 22.8984 1.79461C22.7537 1.86867 22.6618 2.01672 22.6618 2.1793V2.18992C22.6618 2.35086 22.7508 2.49937 22.8941 2.57242C23.5599 2.91195 24.1041 3.45492 24.4453 4.11977C24.5186 4.2625 24.6668 4.35109 24.8273 4.35109H24.8474C25.0079 4.35109 25.1562 4.26258 25.2294 4.11977C25.3471 3.89039 25.4895 3.67594 25.6523 3.47891C25.3434 3.10492 24.96 2.79484 24.524 2.57242Z"
                    fill="#FBC700"
                />
                <path
                    d="M15.5384 4.16116C15.4311 4.10647 15.3645 3.9953 15.3645 3.87491V3.86702C15.3645 3.7453 15.4332 3.63452 15.5416 3.57913C15.824 3.43468 16.0757 3.23991 16.2884 3.00874C16.1246 2.83069 15.9833 2.63163 15.8725 2.4139C15.8172 2.30515 15.7062 2.23608 15.5842 2.23608C15.4622 2.23608 15.3513 2.30515 15.2959 2.4139C15.0414 2.91444 14.6331 3.32343 14.1332 3.57913C14.0249 3.6346 13.9561 3.7453 13.9561 3.86702V3.87491C13.9561 3.9953 14.0227 4.10647 14.13 4.16116C14.6283 4.4153 15.0356 4.82163 15.2909 5.31921C15.3457 5.42608 15.4566 5.49233 15.5767 5.49233H15.5918C15.7119 5.49233 15.8228 5.42608 15.8777 5.31921C15.9876 5.10499 16.1271 4.90905 16.2885 4.73358C16.0749 4.50132 15.8221 4.30593 15.5384 4.16116Z"
                    fill="#FFE02F"
                />
                <path
                    d="M36.5341 22.1804L34.3683 19.1904L20 24.3296L22.4296 27.6839C22.631 27.962 22.9919 28.0736 23.3152 27.958L36.1687 23.3606C36.6534 23.1872 36.8359 22.5972 36.5341 22.1804Z"
                    fill="#D08459"
                />
                <path
                    d="M3.46594 22.1804L5.63172 19.1904L20 24.3296L17.5704 27.6839C17.369 27.962 17.0081 28.0736 16.6848 27.958L3.83133 23.3606C3.34664 23.1872 3.16406 22.5972 3.46594 22.1804Z"
                    fill="#E19974"
                />
                <path
                    d="M5.79141 23.035L7.97031 20.0268L5.6318 19.1904L3.46602 22.1804C3.16406 22.5973 3.34672 23.1873 3.83133 23.3606L5.84164 24.0796C5.59461 23.7984 5.55125 23.3665 5.79141 23.035Z"
                    fill="#DC8758"
                />
            </g>
            <defs>
                <clipPath id="clip0_66_676">
                    <rect width="40" height="40" fill="white" />
                </clipPath>
            </defs>
        </SVGIcon>
    );
}
