import { useCallback } from 'react';
import { useQueryClient } from 'react-query';
import useToast from 'Modules/Toasts/useToast';
import { useHistory } from 'react-router-dom';
import usePubSub from 'Hooks/PubSub';

const useConversation = () => {
    const history = useHistory();
    const queryClient = useQueryClient();
    const { showToast } = useToast();

    usePubSub(
        'supportUpdateRoom',
        useCallback(() => {
            try {
                queryClient.invalidateQueries(['support-rooms']);
            } catch (err) {
                log.error(err || 'Failed to update room');
            }
        }, [queryClient]),
    );

    usePubSub(
        'supportNewRoom',
        useCallback(() => {
            try {
                queryClient.invalidateQueries(['support-rooms']);
                queryClient.invalidateQueries(['conversationsWidgetAPI']);
            } catch (err) {
                log.error(err || 'Failed to update room');
            }
        }, [queryClient]),
    );

    usePubSub(
        'supportRoomDeleted',
        useCallback(() => {
            try {
                queryClient.invalidateQueries(['support-rooms']);
                queryClient.invalidateQueries(['peopleData']);
            } catch (err) {
                log.error(err || 'Failed to update room');
            }
        }, [queryClient]),
    );

    usePubSub(
        'updateSupportMessage',
        useCallback(
            data => {
                try {
                    if (!history?.location?.pathname.includes('conversations')) {
                        queryClient.invalidateQueries(['support_messages', data.message?.room_id]);
                        queryClient.invalidateQueries(['support-rooms']);
                    }
                } catch (error) {
                    showToast({
                        type: 'error',
                        message: typeof error === 'string' ? error : 'Something went wrong',
                    });
                }
            },
            [history?.location?.pathname, queryClient, showToast],
        ),
    );

    usePubSub(
        'supportMessageAck',
        useCallback(
            data => {
                try {
                    if (!history?.location?.pathname.includes('conversations')) {
                        queryClient.invalidateQueries(['support_messages', data?.room_id]);
                        queryClient.invalidateQueries(['support-rooms']);
                    }
                } catch (error) {
                    showToast({
                        type: 'error',
                        message: typeof error === 'string' ? error : 'Something went wrong',
                    });
                }
            },
            [history?.location?.pathname, queryClient, showToast],
        ),
    );
};

export default useConversation;
