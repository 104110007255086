import React from 'react';

import { SVGIcon } from '../../UIKit/index';

export default function DCCheckSuit(props) {
    return (
        <SVGIcon {...props} viewBox="0 0 111.866 101.915">
            <g transform="translate(-1035.443 -444.943)">
                <g transform="translate(1037.943 445)">
                    <path
                        d="M197.138,49.52V45.686s.1-8.652,9.793-9.92H228.8s8.779,1.062,9.381,9.777v4.738h-5.77V45.923s-.127-3.928-3.09-4.292H206.2a5.541,5.541,0,0,0-3.254,4.086v6.334h-5.812Z"
                        transform="translate(-172.973 -35.766)"
                        fill="#005eff"
                    />
                    <path
                        d="M8,0H83.918a8,8,0,0,1,8,8V64.345a8,8,0,0,1-8,8H8a8,8,0,0,1-8-8V8A8,8,0,0,1,8,0Z"
                        transform="translate(14.165 29.514)"
                        fill="#f3f9ff"
                    />
                    <rect
                        width="91.918"
                        height="72.345"
                        rx="4"
                        transform="translate(0 17.091)"
                        fill="#f9f9f9"
                        stroke="#005eff"
                        strokeMiterlimit="10"
                        strokeWidth="5"
                    />
                    <path
                        d="M7,0H68.933a7,7,0,0,1,7,7V37.094a0,0,0,0,1,0,0H0a0,0,0,0,1,0,0V7A7,7,0,0,1,7,0Z"
                        transform="translate(8 26.066)"
                        fill="#d9eaff"
                    />
                    <rect
                        width="85.653"
                        height="6.719"
                        transform="translate(3.166 68.328)"
                        fill="#d9eaff"
                    />
                    <circle
                        cx="4.564"
                        cy="4.564"
                        r="4.564"
                        transform="translate(94.462 87.781)"
                        fill="#d9eaff"
                    />
                    <g transform="translate(6 28.514)">
                        <path
                            d="M20.123,0A20.123,20.123,0,1,1,0,20.123,20.123,20.123,0,0,1,20.123,0Z"
                            transform="translate(63.12 -28.571)"
                            fill="#00f339"
                        />
                        <path
                            d="M34.9,23.67l-6.606-6.606,3.451-3.451L34.9,16.769,45.375,6.293l3.451,3.451Z"
                            transform="translate(46.135 -23.637)"
                            fill="#fff"
                        />
                    </g>
                </g>
            </g>
        </SVGIcon>
    );
}
