import React from 'react';

import { SVGIcon } from '../../UIKit/index';

export default function DCEcommerceTemplates(props) {
    return (
        <SVGIcon {...props} viewBox="0 0 480.752 510.999">
            <defs>
                <linearGradient
                    id="linear-ecommerce-templates"
                    x1="-18.479"
                    y1="0.047"
                    x2="-17.759"
                    y2="0.677"
                    gradientUnits="objectBoundingBox"
                >
                    <stop offset="0" stopColor="#321944" />
                    <stop offset="1" stopColor="#8a3a77" />
                </linearGradient>
            </defs>
            <g id="eCommerce_Templates" transform="translate(-15.93 -5.45)">
                <g id="Layer_1" data-name="Layer 1">
                    <ellipse
                        id="_Path_"
                        data-name="&lt;Path&gt;"
                        cx="238.34"
                        cy="49.42"
                        rx="238.34"
                        ry="49.42"
                        transform="matrix(0.999, -0.046, 0.046, 0.999, 15.93, 417.716)"
                        fill="#f4f5f6"
                    />
                    <path
                        id="Path_1"
                        data-name="Path 1"
                        d="M316.14,416.27a20,20,0,0,1-13.23-15l-9.83-48.67-84.12,3-10.35,50.7c-1.45,7.1,4.36,14.22,11,16.84L244.8,436.9a45.63,45.63,0,0,0,20.58,3l74.37-6.36a4.62,4.62,0,0,0,4.17-4.09c.25-2.19,1.78-4.66-.3-5.34Z"
                        fill="#48494a"
                    />
                    <path
                        id="Path_2"
                        data-name="Path 2"
                        d="M317,413.31a19.77,19.77,0,0,1-13.21-14.83l-9.83-48.06-82.06,2.95-10.34,50.07a14.64,14.64,0,0,0,9.06,16.62l35.16,13.6a46.28,46.28,0,0,0,20.55,2.94l74.3-6.28a4.58,4.58,0,0,0,1-8.92Z"
                        fill="#707272"
                    />
                    <path
                        id="Path_3"
                        data-name="Path 3"
                        d="M37.09,384.93c-9.12.54-16.59-5.38-16.59-13.2V89.87C20.5,82.05,28,76,37.09,76.41L458.93,94.69a12.75,12.75,0,0,1,12.12,12.58V347a13,13,0,0,1-12.12,12.79Z"
                        fill="#707272"
                    />
                    <path
                        id="Path_4"
                        data-name="Path 4"
                        d="M40.88,384.93c-9.12.54-16.59-5.38-16.59-13.2V89.87c0-7.82,7.47-13.86,16.59-13.46L462.72,94.69a12.74,12.74,0,0,1,12.12,12.58V347a13,13,0,0,1-12.12,12.79Z"
                        fill="#5e5f5f"
                    />
                    <path
                        id="Path_5"
                        data-name="Path 5"
                        d="M462.72,100.33,40.88,83C36,82.77,32,86,32,90.17V371.31c0,4.17,4,7.33,8.84,7.05l421.84-24.22a7,7,0,0,0,6.49-6.84V107.06a6.81,6.81,0,0,0-6.45-6.73Z"
                        opacity="0.3"
                    />
                    <path
                        id="Path_6"
                        data-name="Path 6"
                        d="M40.88,354.42a3.06,3.06,0,0,1-3.45-2.78V90.38a3.09,3.09,0,0,1,3.45-2.81l421.84,16.71a2.65,2.65,0,0,1,2.54,2.62v224a2.7,2.7,0,0,1-2.54,2.65Z"
                        fill="#f4f5f6"
                    />
                    <path
                        id="Path_7"
                        data-name="Path 7"
                        d="M418.68,342.33C383.19,320.38,408,258,368.12,226.6,327.94,195,271.4,233.76,243.26,201c-21-24.38,3.56-53.82-16.45-76.75-31.38-36-111.72,13.49-148.62-21.93-5.25-5-10.53-12.66-13.54-24.9l-23.77-1c-9.12-.4-16.59,5.64-16.59,13.46V371.73c0,7.82,7.47,13.74,16.59,13.2l421.84-25.15A13,13,0,0,0,474.84,347v-4.26C446.83,352.24,429.7,349.15,418.68,342.33Z"
                        opacity="0.05"
                    />
                    <path
                        id="Path_8"
                        data-name="Path 8"
                        d="M279.64,141.64v42.14s-10.68,3.31-12.91,13.45H252.36c-2.26-10.21-13-13.67-13-13.67V140.85l9,.18a5.439,5.439,0,0,0-.36,1.73c0,4.75,5.22,8.61,11.69,8.72s11.62-3.54,11.62-8.25a6.07,6.07,0,0,0-.55-1.77Z"
                        fill="#707272"
                    />
                    <path
                        id="Path_9"
                        data-name="Path 9"
                        d="M239.13,140.59l-14.64,9.58,7.33,11.3,11.47-3.14Z"
                        fill="#707272"
                    />
                    <path
                        id="Path_10"
                        data-name="Path 10"
                        d="M279.98,141.38l14.16,9.93-7.06,10.88-11.17-3.41Z"
                        fill="#707272"
                    />
                    <path
                        id="Path_11"
                        data-name="Path 11"
                        d="M189.84,223.6l-117.42.63V124.5l117.42,2.7ZM74.15,222.43,187,221.88V129.45L74.15,126.94Z"
                        fill="#707272"
                    />
                    <path
                        id="Path_12"
                        data-name="Path 12"
                        d="M189.84,329.81,72.42,334.1V234.38l117.42-1ZM74.15,332.24,187,328.17v-93l-112.82,1Z"
                        fill="#707272"
                    />
                    <path
                        id="Path_13"
                        data-name="Path 13"
                        d="M314.17,222.91l-107.63.59V127.58l107.63,2.48Zm-105.91-1.14,104.19-.52v-89l-104.19-2.32Z"
                        fill="#707272"
                    />
                    <path
                        id="Path_14"
                        data-name="Path 14"
                        d="M314.17,325.22l-107.63,4V233.26l107.63-.89Zm-105.91,2.17,104.19-3.77V234.05L208.26,235Z"
                        fill="#707272"
                    />
                    <path
                        id="Path_15"
                        data-name="Path 15"
                        d="M430.45,222.29l-100.73.55V130.41l100.73,2.32ZM332,221.16l96.7-.47V134.84L332,132.68Z"
                        fill="#707272"
                    />
                    <path
                        id="Path_16"
                        data-name="Path 16"
                        d="M430.45,321l-100.73,3.7V232.24l100.73-.84Zm-98.43,2,96.7-3.51V233l-96.7.85Z"
                        fill="#707272"
                    />
                    <path
                        id="Path_17"
                        data-name="Path 17"
                        d="M174.48,211.5a4.86,4.86,0,0,1-5,4.65l-79.78.26a5,5,0,0,1-5.28-4.75h0a5,5,0,0,1,5.28-4.76h79.78a4.84,4.84,0,0,1,5,4.64Z"
                        fill="#707272"
                    />
                    <path
                        id="Path_18"
                        data-name="Path 18"
                        d="M301.79,211.26a4.58,4.58,0,0,1-4.65,4.48l-73.85.24a4.72,4.72,0,0,1-4.88-4.56h0a4.73,4.73,0,0,1,4.88-4.58h73.85a4.56,4.56,0,0,1,4.65,4.46Z"
                        fill="#707272"
                    />
                    <path
                        id="Path_19"
                        data-name="Path 19"
                        d="M419.94,211a4.34,4.34,0,0,1-4.33,4.32l-68.56.22a4.45,4.45,0,0,1-4.53-4.39h0a4.47,4.47,0,0,1,4.53-4.41h68.56a4.32,4.32,0,0,1,4.33,4.3Z"
                        fill="#707272"
                    />
                    <path
                        id="Path_20"
                        data-name="Path 20"
                        d="M174.48,314.7a5,5,0,0,1-5,4.8L89.7,322.18a4.86,4.86,0,0,1-5.28-4.59h0a5.23,5.23,0,0,1,5.28-4.92l79.78-2.46a4.68,4.68,0,0,1,5,4.49Z"
                        fill="#707272"
                    />
                    <path
                        id="Path_21"
                        data-name="Path 21"
                        d="M301.79,310.61a4.75,4.75,0,0,1-4.65,4.62l-73.85,2.47a4.55,4.55,0,0,1-4.88-4.41h0a4.91,4.91,0,0,1,4.88-4.73l73.85-2.28a4.4,4.4,0,0,1,4.65,4.33Z"
                        fill="#707272"
                    />
                    <path
                        id="Path_22"
                        data-name="Path 22"
                        d="M419.94,306.81a4.5,4.5,0,0,1-4.33,4.45l-68.56,2.3a4.3,4.3,0,0,1-4.53-4.26h0a4.64,4.64,0,0,1,4.53-4.55l68.56-2.11a4.16,4.16,0,0,1,4.33,4.17Z"
                        fill="#707272"
                    />
                    <path
                        id="Path_23"
                        data-name="Path 23"
                        d="M210,109.9a5.81,5.81,0,0,1-6.2,5.55L80.33,112.18c-3.64-.1-6.76-2.84-6.76-6.12V106c0-3.28,3.08-5.85,6.72-5.74L203.73,104c3.39.1,6.26,2.74,6.26,5.89Z"
                        fill="#707272"
                    />
                    <path
                        id="Path_24"
                        data-name="Path 24"
                        d="M271.58,257.64a1.71,1.71,0,0,0-1.76-1.8l-18.75.29a1.93,1.93,0,0,0-1.94,1.87v.29a1.93,1.93,0,0,0,2,1.81l18.63-.31a1.81,1.81,0,0,0,1.8-1.86Z"
                        fill="#707272"
                    />
                    <path
                        id="Path_25"
                        data-name="Path 25"
                        d="M259.47,245.83a1.91,1.91,0,0,0-1.6,2,3.2,3.2,0,0,1-1.84,3l-.43.21a4.77,4.77,0,0,0-2.82,4.19l.05,1.17,8.39.33V245.81Z"
                        fill="#707272"
                    />
                    <path
                        id="Path_26"
                        data-name="Path 26"
                        d="M261.46,245.8a1.84,1.84,0,0,1,1.58,2,3.1,3.1,0,0,0,1.83,3l.42.2a4.57,4.57,0,0,1,2.81,4l.14,1.16-8.17.58V245.82Z"
                        fill="#707272"
                    />
                    <path
                        id="Path_27"
                        data-name="Path 27"
                        d="M268.07,259.25l-15.57.26c-3.38.05-6.25,2.5-6.25,5.71V267l5.4-.1c.5,0,.93.16.93.63v.25a.88.88,0,0,1-.93.82l-5.4.1v4l5.4-.11a.89.89,0,0,1,.93.85v.24c0,.48-.43.6-.93.61l-5.4.11v4l5.4-.12a1.105,1.105,0,0,1,.93,1.07v.25a1,1,0,0,1-.93.95l-5.4.12v4l5.4-.13c.5,0,.93.25.93.72v.24c0,.48-.43.72-.93.74l-5.4.13v4l5.4-.14a1,1,0,0,1,.93.94v.25a1.12,1.12,0,0,1-.93,1.08l-5.4.14v.39c0,3.21,3,5.77,6.35,5.68l15.47-.44a6,6,0,0,0,5.81-6V264.72a5.44,5.44,0,0,0-5.81-5.47Z"
                        fill="#707272"
                    />
                    <path
                        id="Path_28"
                        data-name="Path 28"
                        d="M386,256a2.42,2.42,0,0,0-3.33.57l-13.85,19a2.31,2.31,0,0,0,.53,3.29l.39.27a2.46,2.46,0,0,0,3.36-.6l13.82-19a2.32,2.32,0,0,0-.53-3.28Z"
                        fill="#f4f7fa"
                    />
                    <path
                        id="Path_29"
                        data-name="Path 29"
                        d="M366.55,261a1.87,1.87,0,0,0,.74,2.47,3.22,3.22,0,0,1,1.47,3.28l-.08.47a4.73,4.73,0,0,0,1.88,4.76l1,.65L377,266l-9.36-6.51Z"
                        fill="#f4f7fa"
                    />
                    <path
                        id="Path_30"
                        data-name="Path 30"
                        d="M367.73,259.35a2,2,0,0,1,2.63-.12,3.42,3.42,0,0,0,3.65.29l.42-.23a5,5,0,0,1,5.22.19l1,.69-4.54,7-9.36-6.51Z"
                        fill="#f4f7fa"
                    />
                    <path
                        id="Path_31"
                        data-name="Path 31"
                        d="M370.22,256.68a4.49,4.49,0,0,1,1,6.37,4.77,4.77,0,0,1-6.53,1.14,4.5,4.5,0,0,1-1-6.39,4.75,4.75,0,0,1,6.53-1.12Z"
                        fill="#f4f7fa"
                    />
                    <path
                        id="Path_32"
                        data-name="Path 32"
                        d="M397.94,281.63a13.15,13.15,0,0,1-18,3.25,12.54,12.54,0,0,1-2.86-17.78,13.14,13.14,0,0,1,18.03-3.1,12.53,12.53,0,0,1,2.83,17.63Zm-17.78-12.38a8.85,8.85,0,0,0,2,12.53,9.28,9.28,0,0,0,12.71-2.28,8.85,8.85,0,0,0-2-12.48A9.28,9.28,0,0,0,380.16,269.25Z"
                        fill="#f4f7fa"
                    />
                    <path
                        id="Path_33"
                        data-name="Path 33"
                        d="M117.89,267.6l-21.29.38v2.37l21.29-.39Z"
                        fill="#707272"
                    />
                    <path
                        id="Path_34"
                        data-name="Path 34"
                        d="M123.43,284.56l-5.54.13V272.9l-21.29.42v21.35h.09c.32,4.15,3.93,7,8.43,6.89l18.29-.52c4.65-.12,8.3-3.56,8.3-7.79v-.93a7.94,7.94,0,0,0-8.28-7.76Z"
                        fill="#707272"
                    />
                    <path
                        id="Path_35"
                        data-name="Path 35"
                        d="M150.13,247.75l-21.3.26v2.35l21.3-.28Z"
                        fill="#707272"
                    />
                    <path
                        id="Path_36"
                        data-name="Path 36"
                        d="M155,265.18l-4.89.08V253l-21.3.29v21.16h-.19c.31,4.11,3.91,7,8.33,6.87l17.92-.4c4.57-.1,7.9-3.41,7.9-7.61v-.92C162.79,268.2,159.58,265.1,155,265.18Z"
                        fill="#707272"
                    />
                    <path
                        id="Path_37"
                        data-name="Path 37"
                        d="M392.46,165a5.81,5.81,0,0,1-5.92,5.89l-15-.16a6.17,6.17,0,0,1-6.15-6.08V149.84a6,6,0,0,1,6.22-5.89l14.88.28a6.08,6.08,0,0,1,6,6.08Zm-1.73-15a4.55,4.55,0,0,0-4.45-4.64L371.22,145a4.53,4.53,0,0,0-4.67,4.5v14.9a4.77,4.77,0,0,0,4.75,4.64l14.95.17a4.41,4.41,0,0,0,4.48-4.5Z"
                        fill="#707272"
                    />
                    <path
                        id="Path_38"
                        data-name="Path 38"
                        d="M379.54,152.39c-.19.72-.73,2.59-1.63,5.31h3.27c-.84-2.73-1.31-4.17-1.42-4.52A7.35,7.35,0,0,1,379.54,152.39Z"
                        fill="#707272"
                    />
                    <path
                        id="Path_39"
                        data-name="Path 39"
                        d="M390.15,149.92a3.08,3.08,0,0,0-3-3l-15.48-.28a3.24,3.24,0,0,0-3.35,2.88v14.92a3.3,3.3,0,0,0,3.31,3l15.36.18a3.11,3.11,0,0,0,3.2-2.88Zm-7.43,12.75-.9-2.74-4.58-.06-.91,2.71h-2.87l4.44-11.93h3.25l4.43,12Z"
                        fill="#707272"
                    />
                    <path
                        id="Path_40"
                        data-name="Path 40"
                        d="M378.64,192.6a6.14,6.14,0,0,1-6.23,6H357.25a6.18,6.18,0,0,1-6.24-6V177.63a6,6,0,0,1,6.17-6l15.24.15a6.23,6.23,0,0,1,6.22,6.05Zm-1.72-14.53a4.76,4.76,0,0,0-4.76-4.62l-15.25-.15a4.64,4.64,0,0,0-4.75,4.56v15a4.66,4.66,0,0,0,4.7,4.62h15.31a4.68,4.68,0,0,0,4.75-4.56Z"
                        fill="#707272"
                    />
                    <path
                        id="Path_41"
                        data-name="Path 41"
                        d="M376.34,177.5a3.32,3.32,0,0,0-3.34-2.95l-15.39-.15a3.06,3.06,0,0,0-3.14,2.91v15a3,3,0,0,0,3,2.95l15.59.05a3.26,3.26,0,0,0,3.32-2.92Zm-7.73,12.34a5.08,5.08,0,0,1-3.27,1H360.8v-12.6h3.74a7.45,7.45,0,0,1,3.85.76,2.56,2.56,0,0,1,1.18,2.38,2.93,2.93,0,0,1-.49,1.81c-.36.47-1.37.75-1.37.84v.08c0,.19,1.32.52,1.67,1a3.31,3.31,0,0,1,.5,2A3.25,3.25,0,0,1,368.61,189.84Z"
                        fill="#707272"
                    />
                    <path
                        id="Path_42"
                        data-name="Path 42"
                        d="M364.7,185.42h-1.6v2.74h1.69a3,3,0,0,0,1.7-.25,1.17,1.17,0,0,0,.49-1.1C367,185.78,366.2,185.43,364.7,185.42Z"
                        fill="#707272"
                    />
                    <path
                        id="Path_43"
                        data-name="Path 43"
                        d="M366.3,182.88a1.24,1.24,0,0,0,.49-1.11,1.05,1.05,0,0,0-.53-1,4.22,4.22,0,0,0-1.81-.29H363.1v2.74h1.5a3.35,3.35,0,0,0,1.7-.34Z"
                        fill="#707272"
                    />
                    <path
                        id="Path_44"
                        data-name="Path 44"
                        d="M406.85,192.71a5.91,5.91,0,0,1-6,5.92H386.2a5.88,5.88,0,0,1-5.88-6V177.88a5.82,5.82,0,0,1,5.9-5.93l14.71.16a5.93,5.93,0,0,1,5.87,6Zm-1.15-14.39a4.55,4.55,0,0,0-4.51-4.58l-14.7-.15a4.35,4.35,0,0,0-4.39,4.52v14.82a4.44,4.44,0,0,0,4.43,4.58h14.76A4.4,4.4,0,0,0,405.7,193Z"
                        fill="#707272"
                    />
                    <path
                        id="Path_45"
                        data-name="Path 45"
                        d="M404,177.75a2.85,2.85,0,0,0-2.81-2.93l-14.67-.14a2.66,2.66,0,0,0-2.67,2.88v14.85a2.76,2.76,0,0,0,2.73,2.93h14.73A2.7,2.7,0,0,0,404,192.5Zm-8.47,11a7.76,7.76,0,0,0,3.29-.63v2.18a8.07,8.07,0,0,1-3.53.62,5.39,5.39,0,0,1-4.21-1.65,6.87,6.87,0,0,1-1.47-4.68,7.56,7.56,0,0,1,.69-3.36,5,5,0,0,1,2-2.19,6,6,0,0,1,3.1-.74,8.36,8.36,0,0,1,3.62.89l-.84,2.11a14.846,14.846,0,0,0-1.4-.58,4.37,4.37,0,0,0-1.39-.26,2.69,2.69,0,0,0-2.3,1.09,5,5,0,0,0-.76,3.07c-.01,2.79,1.09,4.16,3.17,4.17Z"
                        fill="#707272"
                    />
                    <path
                        id="Path_46"
                        data-name="Path 46"
                        d="M144.28,188.43l-2.18,1.55L126,171.07l2.19-1.55Z"
                        fill="#707272"
                    />
                    <path
                        id="Path_47"
                        data-name="Path 47"
                        d="M147.54,194.32a4.41,4.41,0,0,1-5.81-.67,3.59,3.59,0,0,1,.7-5.33,4.43,4.43,0,0,1,5.82.67,3.6,3.6,0,0,1-.71,5.33Zm-4.11-4.83a2.18,2.18,0,0,0-.43,3.25,2.68,2.68,0,0,0,3.55.41,2.19,2.19,0,0,0,.43-3.25A2.68,2.68,0,0,0,143.43,189.49Z"
                        fill="#707272"
                    />
                    <path
                        id="Path_48"
                        data-name="Path 48"
                        d="M130.33,155.1a13.82,13.82,0,0,0-17.13-.93c-5.22,3.64-6.8,9.89-3.4,15.31Z"
                        fill="#707272"
                    />
                    <path
                        id="Path_49"
                        data-name="Path 49"
                        d="M114,173.68a14.61,14.61,0,0,0,15.49-.37,11.56,11.56,0,0,0,4.23-13.49Z"
                        fill="#707272"
                    />
                    <path
                        id="Path_50"
                        data-name="Path 50"
                        d="M133.58,156.9a.848.848,0,0,1-.32,1.31l-20.74,14.56a1,1,0,0,1-1.4-.1l-.89-1a.82.82,0,0,1,.21-1.3l20.77-14.55a1.09,1.09,0,0,1,1.5.09Z"
                        fill="#707272"
                    />
                    <path
                        id="Path_51"
                        data-name="Path 51"
                        d="M282.42,211.6a11.94,11.94,0,0,0,1.93-6.11c.15-5.68-4.05-10.1-4.62-9.85-.3.14.08,1.8.3,2.54.45,1.53.93,1.68.89,2.38-.08,1.45-2.17,1.51-3.28,3.43a5.22,5.22,0,0,0,0,4.93C279.07,211.51,282.28,211.6,282.42,211.6Z"
                        fill="#e4e3e3"
                    />
                    <path
                        id="Path_52"
                        data-name="Path 52"
                        d="M372.31,334.85l-1.61-5.57,6.75-5,4.21-1.58-2.49,7.79Z"
                        fill="#e4e3e3"
                    />
                    <path
                        id="Path_53"
                        data-name="Path 53"
                        d="M364,447s3.7,9.3,2.73,13.42a41.286,41.286,0,0,0,6.53-.59,44.433,44.433,0,0,1-1.91-6.33,62.6,62.6,0,0,1-.39-7.43Z"
                        fill="#e4e3e3"
                    />
                    <path
                        id="Path_54"
                        data-name="Path 54"
                        d="M323.24,468.69a2.73,2.73,0,0,1,.49,2.85c-.63,1.66-1.62,1.88-2.69,3.63-.65,1.08-.53,1.4-1.13,1.89a3.9,3.9,0,0,1-2.56.68,44.993,44.993,0,0,1-5.94-.33c1.33-1.71,2.74-3.45,4.24-5.2.65-.77,1.31-1.52,2-2.25l3.89-1.32Z"
                        fill="#48494a"
                    />
                    <path
                        id="Path_55"
                        data-name="Path 55"
                        d="M320,449.76s-2.23,11.63.6,14.88c2.42,3.26-1.16.94-1.16.94l-4.28,3.73-2.57,4.43s2.37-11.37,1.74-15.89-.95-7.56-.95-7.56Z"
                        fill="#e4e3e3"
                    />
                    <path
                        id="Path_56"
                        data-name="Path 56"
                        d="M311.6,322.61s-6,5.35-6,27.4,2.84,69.93,2.84,69.93L313,454.22a30.121,30.121,0,0,0,7.86-.77c2.83-12.92,7.45-34.77,5.25-53-.95-19.84,7.87-44.73,7.87-44.73s7.56,25.2,12.92,37.8,15.55,52.08,16.49,54.91c6.27.57,8.94-1.09,8.94-1.09s-4-42.48-7.79-58.54-3.47-54.81-9.77-65.84C340.58,317.25,311.6,322.61,311.6,322.61Z"
                        fill="#48494a"
                    />
                    <path
                        id="Path_57"
                        data-name="Path 57"
                        d="M311.6,322.61s-6,5.35-6,27.4,2.84,69.93,2.84,69.93L313,454.22a30.121,30.121,0,0,0,7.86-.77c2.83-12.92,7.45-34.77,5.25-53-.95-19.84,7.87-44.73,7.87-44.73s7.56,25.2,12.92,37.8,15.55,52.08,16.49,54.91c6.27.57,8.94-1.09,8.94-1.09s-4-42.48-7.79-58.54-3.47-54.81-9.77-65.84C340.58,317.25,311.6,322.61,311.6,322.61Z"
                        fill="#48494a"
                    />
                    <path
                        id="Path_58"
                        data-name="Path 58"
                        d="M342.69,363c1.86,12.13,5.6,18.66,14.93,27.06,7.46,5.6,1.86,17.73,4.66,27.06.94,2.8,3.74,5.6,4.67,8.4,3.47,7,.23,15.44,1.8,22.79a9.761,9.761,0,0,0,3.56-1s-4-42.48-7.79-58.54-3.47-54.81-9.77-65.84c-14.17-5.67-43.15-.31-43.15-.31s-6,5.35-6,27.4c0,1.79,0,3.75.05,5.84,6.22,5.5,18.92,6.72,26.76.63a3.739,3.739,0,0,1,1.5-.68v-.01C337.56,355.14,341.88,359.77,342.69,363Z"
                        opacity="0.25"
                        fill="url(#linear-ecommerce-templates)"
                    />
                    <path
                        id="Path_59"
                        data-name="Path 59"
                        d="M365.7,463.89s-.92,0-.84,1.19-.41,5.28,4.27,5.47,5.4-.33,5.55-.33a39.163,39.163,0,0,0-1.23-3.91L372,464.39l-4-.42Z"
                        fill="#48494a"
                    />
                    <path
                        id="Path_60"
                        data-name="Path 60"
                        d="M376.08,468a14.678,14.678,0,0,0-1.21-3.79c-.82-1.86-.15-2.56-.94-4.25a4.89,4.89,0,0,0-1.11-1.69,4.81,4.81,0,0,0-3.31-1.26,3.39,3.39,0,0,0-1.55.36,3.62,3.62,0,0,0-1.4,1.48c-.49.8-.38,1-1.13,3.35a8.065,8.065,0,0,0-.29,1.13,9.736,9.736,0,0,0-.13,1c1.23,0,5.95.2,6.95.93a3.9,3.9,0,0,1,1.49,3.18c0,1.23.62,1.8,1.23,1.76a2,2,0,0,0,1.4-2.2Z"
                        fill="#707272"
                    />
                    <path
                        id="Path_61"
                        data-name="Path 61"
                        d="M319.4,463c1.05,0,1.82.86,2.41,2.88s3.38,3.81.11,3.38-5.63,4.2-6.8,5.91-4.3,3.23-4.22,1.56,1.46-2.45,1.85-4.16S317,463,319.4,463Z"
                        fill="#707272"
                    />
                    <path
                        id="Path_62"
                        data-name="Path 62"
                        d="M374.79,325.27c.78-2.16,5.61-16.31-2.2-29.22-6.4-10.56-17.48-14-19.06-14.48-9.61-2.84-14.43.81-17.46-2.55-5.15-5.69.68-25.09,12-29,12.71-4.38,28,12.46,34.14,23.53a51,51,0,0,1,5.64,31.91,49.749,49.749,0,0,1-6.15,17.22Z"
                        fill="#707272"
                    />
                    <path
                        id="Path_63"
                        data-name="Path 63"
                        d="M341.6,247.3c-11.35-.45-20.1-.27-25,4.67-5.22,5.22.05,11.1-1.08,24.75-1.74,20.9-15.05,26-16.05,47.2a54,54,0,0,0,1.81,16.49A56.779,56.779,0,0,0,316,353.48c10.27,6.25,16.23,5.16,31,9.2,24.13,6.6,26.27,14.46,38.24,12.46s22.7-12.2,21.42-17.79c-.77-3.34-5.65-2.39-11.26-7.5-7.23-6.58-4.44-12.86-9.94-19.92-7.9-10.16-18.9-3.95-25.63-12.75s-4.11-26.44,1.63-36c3.75-6.25,5.35-13.57,8.54-20.11a4.591,4.591,0,0,0,.44-2.56c-.52-4.26-7.63-6.57-13-8.27a64.311,64.311,0,0,0-15.84-2.94Z"
                        fill="#707272"
                    />
                    <path
                        id="Path_64"
                        data-name="Path 64"
                        d="M332,246.83l2.24-.69s.58,3,7.78,3.41a10.76,10.76,0,0,0,6.57-2.23l1.53,1.11a11.74,11.74,0,0,1-8.69,4.08,10.69,10.69,0,0,1-9.43-5.68Z"
                        fill="#707272"
                    />
                    <path
                        id="Path_65"
                        data-name="Path 65"
                        d="M336.6,237.62a9.62,9.62,0,0,1,3.63-5.92c2.41-1.91,2.9-2.1,3.72-2.26l1.13-.17h.12a11.351,11.351,0,0,1,3,1.06c1.15.81.3,6.53-3.18,13.64-.14.61-.27,1.21-.41,1.81,3,.54,3.79,1.08,3.81,1.52.05.95-3.27,2.16-6.35,2.23-3.86.1-8-1.56-7.89-2.8,0-.5.76-1.12,3.57-1.73.44-.61.87-1.23,1.31-1.85C338.66,242.68,336.28,240.07,336.6,237.62Z"
                        fill="#e4e3e3"
                    />
                    <path
                        id="Path_66"
                        data-name="Path 66"
                        d="M341.37,236.36c.88-.52,1.55.61,1.13,1.59a2,2,0,0,1-2.64,1C339,238.38,340.19,237.07,341.37,236.36Z"
                        fill="#e4e3e3"
                    />
                    <path
                        id="Path_67"
                        data-name="Path 67"
                        d="M454.92,236.35c-11.53-12.52-38.36-9.08-53.25,1C394.76,242,391.21,247.5,383,248c-6.21.36-16.09-2-21.3-8.71-.65-.85-3.28-4.4-7.21-8.88-1.74-2-3.47-3.84-6.14-4.26-.68-.1-2.94-.47-4.44,1a5.23,5.23,0,0,0-1.15,4.72c.22.89.49,1,.45,1.57-.11,1.63-3.08,2.34-2.91,3.16.11.59,1.66.37,1.93,1.11s-1.41,1.5-2.14,3.53c-1.37.92-2.23,3.19-2.53,6.24a27.719,27.719,0,0,0,.86,10.86c4.09,13.2,7,22.64,14.09,27.91,9.36,7,17.8.54,41.62-.48,32.63-1.4,40.76,9.66,52.49,2.1C461.3,278.37,466.89,249.34,454.92,236.35Z"
                        fill="#48494a"
                    />
                    <path
                        id="Path_68"
                        data-name="Path 68"
                        d="M283.79,210.81c-.29,2.73-1.27,14.8,7.06,25,7.78,9.53,18.8,11.43,20.85,11.74,9.91,1.5,14.19-2.78,17.65.13,5.88,4.94,2.75,25-7.89,30.35-12,6.08-29.4-8.52-37-18.64a51,51,0,0,1-9.95-30.84,49.67,49.67,0,0,1,3.74-17.9,8.81,8.81,0,0,1,5.57.16Z"
                        fill="#707272"
                    />
                    <path
                        id="Path_69"
                        data-name="Path 69"
                        d="M385.58,367.16c-2.9-3,.29-9-4-13.44-4-4.14-8.48-.79-14.89-4.35-8.21-4.57-5.95-12.93-13.79-17.43-5.64-3.23-8.29.25-17.06-1.45s-19.42-7.73-24.69-18.51A31.83,31.83,0,0,1,308,298.76c-3.94,7-8,13.78-8.56,25.16a54,54,0,0,0,1.81,16.49A56.78,56.78,0,0,0,316,353.48c10.27,6.25,16.23,5.16,31,9.2,24.13,6.6,26.27,14.46,38.24,12.46a32.42,32.42,0,0,0,18.63-10.54C392.7,369.56,387.69,369.31,385.58,367.16Z"
                        fill="#48494a"
                        opacity="0.5"
                    />
                    <path
                        id="Path_70"
                        data-name="Path 70"
                        d="M130.58,476.6c0,3.58-2.2,6.48-4.92,6.48s-4.92-2.9-4.92-6.48,2.2-6.47,4.92-6.47S130.58,473,130.58,476.6Z"
                        fill="#48494a"
                    />
                    <path
                        id="Path_71"
                        data-name="Path 71"
                        d="M132,476.67c0,3.57-2.21,6.47-4.93,6.47s-4.92-2.9-4.92-6.47,2.2-6.48,4.92-6.48S132,473.09,132,476.67Z"
                        fill="#707272"
                    />
                    <path
                        id="Path_72"
                        data-name="Path 72"
                        d="M129.09,476.8c0,1.49-.92,2.71-2.06,2.71S125,478.29,125,476.8s.92-2.72,2.06-2.72S129.09,475.3,129.09,476.8Z"
                        fill="#f4f5f6"
                    />
                    <path
                        id="Path_73"
                        data-name="Path 73"
                        d="M125.66,468.76v7.11a1.89,1.89,0,0,0,1.89,1.89h0a1.92,1.92,0,0,0,1.53-.77l6.39-8.76Z"
                        fill="#48494a"
                    />
                    <path
                        id="Path_74"
                        data-name="Path 74"
                        d="M183.29,470.93c0,3.57-2.2,6.47-4.92,6.47s-4.92-2.9-4.92-6.47,2.2-6.48,4.92-6.48S183.29,467.35,183.29,470.93Z"
                        fill="#48494a"
                    />
                    <path
                        id="Path_75"
                        data-name="Path 75"
                        d="M184.67,471c0,3.57-2.21,6.47-4.93,6.47s-4.92-2.9-4.92-6.47,2.2-6.48,4.92-6.48S184.67,467.41,184.67,471Z"
                        fill="#707272"
                    />
                    <path
                        id="Path_76"
                        data-name="Path 76"
                        d="M181.81,471.12c0,1.5-.93,2.71-2.07,2.71s-2.06-1.21-2.06-2.71.92-2.72,2.06-2.72S181.81,469.62,181.81,471.12Z"
                        fill="#f4f5f6"
                    />
                    <path
                        id="Path_77"
                        data-name="Path 77"
                        d="M178.37,463.08v7.11a1.89,1.89,0,0,0,1.89,1.89h0a1.88,1.88,0,0,0,1.53-.77l6.39-8.76Z"
                        fill="#48494a"
                    />
                    <path
                        id="Path_78"
                        data-name="Path 78"
                        d="M124.93,469.87a1.292,1.292,0,0,1-.15-2.58l62.42-7a9.08,9.08,0,0,0,7.15-6.57L218.82,347a12.58,12.58,0,0,1,11.28-9.28l19-1.13a1.292,1.292,0,1,1,.15,2.58l-19,1.13a10,10,0,0,0-9,7.37L196.84,454.36a11.65,11.65,0,0,1-9.27,8.45l-62.5,7.05Z"
                        fill="#48494a"
                    />
                    <path
                        id="Path_79"
                        data-name="Path 79"
                        d="M166.68,422.16a1.29,1.29,0,0,1-1.24-.94l-14.7-51.43a1.29,1.29,0,1,1,2.48-.71l14.7,51.43a1.29,1.29,0,0,1-.89,1.6,1.34,1.34,0,0,1-.35.05Z"
                        fill="#c2c2c2"
                    />
                    <path
                        id="Path_80"
                        data-name="Path 80"
                        d="M181.72,420a1.29,1.29,0,0,1-1.27-1.07L171.38,368a1.29,1.29,0,1,1,2.54-.45L183,418.49a1.3,1.3,0,0,1-1.05,1.5Z"
                        fill="#c2c2c2"
                    />
                    <path
                        id="Path_81"
                        data-name="Path 81"
                        d="M191.06,418.34H191a1.29,1.29,0,0,1-1.24-1.35l2.09-50.41a1.26,1.26,0,0,1,1.34-1.23,1.29,1.29,0,0,1,1.24,1.34l-2.09,50.41a1.29,1.29,0,0,1-1.28,1.24Z"
                        fill="#c2c2c2"
                    />
                    <path
                        id="Path_82"
                        data-name="Path 82"
                        d="M146.17,424a1.3,1.3,0,0,1-1.23-.88L127.68,371.7a1.292,1.292,0,1,1,2.45-.82l17.26,51.46a1.28,1.28,0,0,1-.81,1.63,1.219,1.219,0,0,1-.41.03Z"
                        fill="#e4e3e3"
                    />
                    <path
                        id="Path_83"
                        data-name="Path 83"
                        d="M293.51,343.6h-.22l-42.48-3.49a2.584,2.584,0,1,1,.43-5.15l42.48,3.49a2.582,2.582,0,0,1-.21,5.16Z"
                        fill="#48494a"
                    />
                    <path
                        id="Path_84"
                        data-name="Path 84"
                        d="M175.15,484.89c0,3.57-2.2,6.47-4.92,6.47s-4.92-2.9-4.92-6.47,2.2-6.48,4.92-6.48S175.15,481.31,175.15,484.89Z"
                        fill="#48494a"
                    />
                    <path
                        id="Path_85"
                        data-name="Path 85"
                        d="M176.53,485c0,3.57-2.21,6.47-4.93,6.47s-4.92-2.9-4.92-6.47,2.2-6.48,4.92-6.48S176.53,481.37,176.53,485Z"
                        fill="#707272"
                    />
                    <path
                        id="Path_86"
                        data-name="Path 86"
                        d="M173.66,485.08c0,1.5-.92,2.71-2.06,2.71s-2.06-1.21-2.06-2.71.92-2.72,2.06-2.72S173.66,483.58,173.66,485.08Z"
                        fill="#f4f5f6"
                    />
                    <path
                        id="Path_87"
                        data-name="Path 87"
                        d="M170.23,477v7.11a1.89,1.89,0,0,0,1.89,1.89h0a1.89,1.89,0,0,0,1.53-.77l6.39-8.76Z"
                        fill="#48494a"
                    />
                    <path
                        id="Path_88"
                        data-name="Path 88"
                        d="M220.79,480.32c0,3.57-2.2,6.47-4.92,6.47s-4.93-2.9-4.93-6.47,2.21-6.48,4.93-6.48S220.79,476.74,220.79,480.32Z"
                        fill="#48494a"
                    />
                    <path
                        id="Path_89"
                        data-name="Path 89"
                        d="M222.16,480.38c0,3.57-2.2,6.47-4.92,6.47s-4.92-2.9-4.92-6.47,2.2-6.48,4.92-6.48S222.16,476.8,222.16,480.38Z"
                        fill="#707272"
                    />
                    <path
                        id="Path_90"
                        data-name="Path 90"
                        d="M219.3,480.51c0,1.5-.92,2.71-2.06,2.71s-2.06-1.21-2.06-2.71.92-2.72,2.06-2.72S219.3,479,219.3,480.51Z"
                        fill="#f4f5f6"
                    />
                    <path
                        id="Path_91"
                        data-name="Path 91"
                        d="M215.87,472.47v7.11a1.88,1.88,0,0,0,1.89,1.89h0a1.86,1.86,0,0,0,1.52-.77l6.4-8.76Z"
                        fill="#48494a"
                    />
                    <path
                        id="Path_92"
                        data-name="Path 92"
                        d="M103.83,373.76a1.291,1.291,0,1,1-.09-2.58l111.17-8a1.561,1.561,0,0,1,.31,0l46,7.84a1.29,1.29,0,0,1-.44,2.54l-45.87-7.81-111,8Z"
                        fill="#e4e3e3"
                    />
                    <path
                        id="Path_93"
                        data-name="Path 93"
                        d="M246.65,425.81a.68.68,0,0,1-.2,0l-45.29-6.94-68.9,6.63a1.291,1.291,0,0,1-.25-2.57l69.07-6.65a1.161,1.161,0,0,1,.32,0l45.44,7a1.29,1.29,0,0,1-.19,2.57Z"
                        fill="#caced6"
                    />
                    <path
                        id="Path_94"
                        data-name="Path 94"
                        d="M200.67,357.62a.83.83,0,0,1-.94-.73c-1.13-7.88-4.34-16.46-9-22.37-4.16-5.3-7.37-7.59-13.43-8.64h-.15c-12.73-1.35-13.43,28.34-13.32,32.44.17,6.64-.36,1.48-.87,2s-.89,2.5-1-1c-.26-9.38.67-34.33,15-35.4h.1c6.82.92,10.49,3.48,15.12,9.33,4.85,6.14,8.17,14.95,9.25,23.15a1.13,1.13,0,0,1-.74,1.2Z"
                        fill="#707272"
                    />
                    <path
                        id="Path_95"
                        data-name="Path 95"
                        d="M141.85,426.08c6.85,2.37,15.18,3.56,22.29,6,2.49-10.82,6.81-21.27,10.26-31.81-6.67-14.53-12.34-29.51-19.84-43.64l-5.28,1.49c-.72,23.62-5.46,42.1-10,64.66C138.84,424.72,139.84,425.38,141.85,426.08Z"
                        fill="#48494a"
                    />
                    <path
                        id="Path_96"
                        data-name="Path 96"
                        d="M174.4,400.28c-6.66-14.53-12.34-29.51-19.84-43.65a18,18,0,0,0-3.09,1c0,3.19.68,11.75,1,14.17,1.31,10.2,5.26,20.2,3.81,29.2-1.19,7.41-5.46,9.48-5.45,16.19,0,7.22,6,11.25,11.86,14.42.56.14,1,.28,1.47.44C166.6,421.27,171,410.82,174.4,400.28Z"
                        opacity="0.2"
                    />
                    <path
                        id="Path_97"
                        data-name="Path 97"
                        d="M154.56,356.64a380.721,380.721,0,0,0,66.81-4.88c9.93,24.71,16.73,48.19,24.15,73.63-26,1.05-55.78,2.56-81.36,6.7C158.31,408.24,159.64,381.13,154.56,356.64Z"
                        fill="#707272"
                    />
                    <g id="Group_1" data-name="Group 1" opacity="0.3">
                        <path
                            id="Path_98"
                            data-name="Path 98"
                            d="M221.37,351.76c-11.61,1.77-22,3.08-34,4-1.86.14-1.38,3-1.3,5,.51,12,22.89,11.52,26.36,25.73,2.59,10.61-8.28,23-16.32,27.67-8.4,4.91-16,3-22.74,8.62-2.94,2.45-4,5.21-5.16,8.7,24.57-3.52,52.38-5.09,77.29-6.08C238.13,399.93,231.25,376.48,221.37,351.76Z"
                            fill="#48494a"
                        />
                    </g>
                    <path
                        id="Path_99"
                        data-name="Path 99"
                        d="M176.17,361.87c-1-1.84.08,2.77-1.11-1.3-2.24-7.71-5.74-21.16-1.17-30.1a12.27,12.27,0,0,1,7.24-5.71c14.77-3.86,24.93,20.58,28.21,30.21,1.1,3.25.17,1.81-.58,1.26s.92,5.37-1.49-1.49c-1.68-4.77-12.93-33.12-25.6-27.93a16.68,16.68,0,0,0-6,4.67c-4.86,6.22.07,24.81.82,27.43,2,6.85-.35.06-.07,2.95C176.43,362.27,176.33,362.18,176.17,361.87Z"
                        fill="#707272"
                    />
                    <path
                        id="Path_100"
                        data-name="Path 100"
                        d="M178.72,361.89a2.51,2.51,0,0,1-2.32,2.79,2.56,2.56,0,0,1-2.68-2.46,2.46,2.46,0,0,1,2.29-2.76,2.56,2.56,0,0,1,2.71,2.43Z"
                        fill="#707272"
                    />
                    <path
                        id="Path_101"
                        data-name="Path 101"
                        d="M211.61,356.9a3.14,3.14,0,0,1-2.28,3.21,2.1,2.1,0,0,1-2.6-2.12,3,3,0,0,1,2.32-3.1,2,2,0,0,1,2.56,2.01Z"
                        fill="#707272"
                    />
                    <path
                        id="Path_102"
                        data-name="Path 102"
                        d="M230,388.73c-7.1-6.66-14.95-13-22.67-19a1.17,1.17,0,0,0-1.66,0c-3.33,2-7.3,4.23-10.59,6.2-1.1.67-1.38,1.51-.51,2.56,6.34,7.67,13.08,15.66,19.27,23.48a1.38,1.38,0,0,0,2.22.24c4.52-3.34,9.6-7.13,13.85-10.77a1.6,1.6,0,0,0,.09-2.71Zm-25.14-11.47c-.83.73-1.91.13-2.5-.64a2.05,2.05,0,0,1-.09-2.74c.81-.75,1.89-.16,2.47.61s.92,2.05.09,2.77Z"
                        fill="#e4e3e3"
                    />
                    <path
                        id="Path_103"
                        data-name="Path 103"
                        d="M230,388.44c-7.1-6.66-15-13-22.69-19-.66-.51-1-.44-1.65,0-3.33,2.05-7.29,4.24-10.58,6.21-1.11.66-1.38,1.5-.52,2.55,6.35,7.67,13.09,15.66,19.29,23.49a1.38,1.38,0,0,0,2.22.24c4.51-3.35,9.59-7.15,13.85-10.81a1.59,1.59,0,0,0,.08-2.68ZM204.81,377c-.83.72-1.91.13-2.49-.64a2.07,2.07,0,0,1-.1-2.75c.8-.75,1.88-.16,2.47.61S205.64,376.27,204.81,377Z"
                        fill="#fff"
                        opacity="0.3"
                    />
                    <path
                        id="Path_104"
                        data-name="Path 104"
                        d="M200.21,372.77a.21.21,0,0,1-.19-.11,12.61,12.61,0,0,0-3.13-3.81c-3.53-2.56-7.53-2.73-11.63-2.89s-7.44-.42-10.4-3.32c-.75-.74-1.53-1.85-1.2-2.89.13-.41.73-.43,1.11-.46a.22.22,0,0,1,.21.21c0,.12-.14.17-.19.21s-.77-.15-.78.3a2.93,2.93,0,0,0,1.24,2.45c2.62,2.52,6.12,3,10.05,3.09,4.1.08,8.29.36,11.85,2.95a13.08,13.08,0,0,1,3.23,3.94.25.25,0,0,1-.07.29.21.21,0,0,1-.1.04Z"
                        fill="#48494a"
                    />
                    <path
                        id="Path_105"
                        data-name="Path 105"
                        d="M202.21,375.84c-.12.13-.14.11-.16,0s-.28.22.06-.41,1.45-2,1-3.49a3.85,3.85,0,0,0-1.88-3c-3.54-2.21-9.07-3.7-13.47-5a81.623,81.623,0,0,1-8.3-2.91c-.59-.25-.93-.39-1.48-.59a.21.21,0,1,1,.13-.4c.56.2.91.35,1.51.6a83.463,83.463,0,0,0,8.27,2.89c4.41,1.29,10,2.82,13.58,5.05,1.75,1.1,1.75,2.24,2.05,3.24.47,1.62-.74,3.17-1.1,3.83S202.34,375.71,202.21,375.84Z"
                        fill="#48494a"
                    />
                    <path
                        id="Path_106"
                        data-name="Path 106"
                        d="M230,388.45c-7.1-6.7-15-13-22.69-19-.66-.52-1-.27-1.64,0-.29.1-.64.44-.93.58a13.081,13.081,0,0,0,1.45,2.84c2.83,4,8.34,4.2,10,8.36,1.44,3.67-2.07,6.37-2.23,10.75-.07,2.18,1.32,7,3.38,9,4.18-3.05,8.74-6.48,12.61-9.85a1.59,1.59,0,0,0,.05-2.68Z"
                        fill="#fff"
                    />
                    <path
                        id="Path_107"
                        data-name="Path 107"
                        d="M141.61,385.23a1.581,1.581,0,0,1-.38-.05l-37.77-11.47a1.291,1.291,0,1,1,.75-2.47l37.53,11.39L260.9,371a1.291,1.291,0,1,1,.25,2.57L141.73,385.23Z"
                        fill="#e4e3e3"
                    />
                    <path
                        id="Path_108"
                        data-name="Path 108"
                        d="M164.53,433.62a1.32,1.32,0,0,1-1.17-.74l-22.92-48.39a1.29,1.29,0,1,1,2.33-1.1L165.31,431l81.22-7.73a1.291,1.291,0,0,1,.24,2.57l-82.12,7.81Z"
                        fill="#e4e3e3"
                    />
                    <path
                        id="Path_109"
                        data-name="Path 109"
                        d="M164.53,433.62a1.1,1.1,0,0,1-.31,0l-32.4-8.13a1.32,1.32,0,0,1-.82-.64l-28.3-51.72a1.29,1.29,0,0,1,2.26-1.24l28,51.23,31.85,8a1.3,1.3,0,0,1-.32,2.55Z"
                        fill="#e4e3e3"
                    />
                    <path
                        id="Path_110"
                        data-name="Path 110"
                        d="M149.94,402.83a1.488,1.488,0,0,1-.36-.05L114,392.35a1.293,1.293,0,0,1,.73-2.48l35.34,10.36,105.06-9.16a1.28,1.28,0,0,1,1.4,1.17,1.3,1.3,0,0,1-1.18,1.4l-105.29,9.19Z"
                        fill="#e4e3e3"
                    />
                    <path
                        id="Path_111"
                        data-name="Path 111"
                        d="M158.64,421.18a1.341,1.341,0,0,1-.35-.05l-34.09-9.55a1.293,1.293,0,1,1,.7-2.49l33.86,9.49,91.22-8a1.29,1.29,0,1,1,.23,2.57l-91.46,8Z"
                        fill="#e4e3e3"
                    />
                    <path
                        id="Path_112"
                        data-name="Path 112"
                        d="M181.3,432a1.29,1.29,0,0,1-1.24-.92l-14.62-49.05a1.294,1.294,0,1,1,2.48-.74l14.62,49.05a1.3,1.3,0,0,1-.87,1.61A1.181,1.181,0,0,1,181.3,432Z"
                        fill="#e4e3e3"
                    />
                    <path
                        id="Path_113"
                        data-name="Path 113"
                        d="M197.47,430.49a1.29,1.29,0,0,1-1.27-1.07l-8.77-49.7a1.295,1.295,0,1,1,2.55-.45l8.76,49.7a1.29,1.29,0,0,1-1,1.5Z"
                        fill="#e4e3e3"
                    />
                    <path
                        id="Path_114"
                        data-name="Path 114"
                        d="M215.71,428.41a1.29,1.29,0,0,1-1.29-1.22l-2.76-49.34a1.292,1.292,0,1,1,2.58-.15L217,427.05a1.29,1.29,0,0,1-1.21,1.36Z"
                        fill="#e4e3e3"
                    />
                    <path
                        id="Path_115"
                        data-name="Path 115"
                        d="M233,426.48h-.07a1.29,1.29,0,0,1-1.23-1.35L234.33,375a1.292,1.292,0,1,1,2.58.14l-2.63,50.13a1.29,1.29,0,0,1-1.28,1.21Z"
                        fill="#e4e3e3"
                    />
                    <path
                        id="Path_116"
                        data-name="Path 116"
                        d="M154.73,431.16a1.29,1.29,0,0,1-1.16-.73l-23.63-49.12a1.293,1.293,0,1,1,2.33-1.12l23.63,49.12a1.28,1.28,0,0,1-.61,1.72,1.24,1.24,0,0,1-.56.13Z"
                        fill="#e4e3e3"
                    />
                    <path
                        id="Path_117"
                        data-name="Path 117"
                        d="M141.61,427.86a1.3,1.3,0,0,1-1.16-.71L115.37,376.9a1.29,1.29,0,1,1,2.31-1.15L142.76,426a1.3,1.3,0,0,1-.58,1.74A1.28,1.28,0,0,1,141.61,427.86Z"
                        fill="#e4e3e3"
                    />
                    <path
                        id="Path_118"
                        data-name="Path 118"
                        d="M170,479a1.27,1.27,0,0,1-.47-.09l-45.06-9.17a1.291,1.291,0,0,1,.95-2.4l44.75,9.05,56.38-6.89a9.89,9.89,0,0,0,8.37-7.22l30.9-112.19a12.59,12.59,0,0,1,11.22-9.2l16.39-1.19a1.293,1.293,0,0,1,.19,2.58l-16.4,1.18a10,10,0,0,0-8.91,7.32L237.39,463a12.46,12.46,0,0,1-10.55,9.1l-56.7,6.9Z"
                        fill="#48494a"
                    />
                    <circle
                        id="Ellipse_1"
                        data-name="Ellipse 1"
                        cx="7.25"
                        cy="7.25"
                        r="7.25"
                        transform="translate(37.33 409)"
                        fill="#fff"
                        stroke="#e2e2e2"
                        strokeMiterlimit="10"
                        strokeWidth="2"
                    />
                    <circle
                        id="Ellipse_2"
                        data-name="Ellipse 2"
                        cx="12.05"
                        cy="12.05"
                        r="12.05"
                        transform="translate(61.52 6.45)"
                        fill="#fff"
                        stroke="#e2e2e2"
                        strokeMiterlimit="10"
                        strokeWidth="2"
                    />
                    <circle
                        id="Ellipse_3"
                        data-name="Ellipse 3"
                        cx="8.28"
                        cy="8.28"
                        r="8.28"
                        transform="translate(132.57 42.53)"
                        fill="#fff"
                        stroke="#e2e2e2"
                        strokeMiterlimit="10"
                        strokeWidth="2"
                    />
                    <path
                        id="Path_119"
                        data-name="Path 119"
                        d="M460.72,388.36h-6.8v-6.79a.85.85,0,0,0-.85-.85h0a.86.86,0,0,0-.86.85v6.79h-6.79a.86.86,0,0,0,0,1.71h6.79v6.8a.86.86,0,0,0,.86.85.85.85,0,0,0,.85-.85h0v-6.8h6.8a.85.85,0,0,0,.85-.85.86.86,0,0,0-.85-.86Z"
                        fill="#e2e2e2"
                        stroke="#e2e2e2"
                        strokeMiterlimit="10"
                        strokeWidth="0.75"
                    />
                    <path
                        id="Path_120"
                        data-name="Path 120"
                        d="M476.22,19.6h-9.75V9.87a1.22,1.22,0,0,0-1.21-1.22h0A1.24,1.24,0,0,0,464,9.87V19.6h-9.7a1.24,1.24,0,0,0-1.23,1.24,1.22,1.22,0,0,0,1.23,1.21H464V31.8a1.23,1.23,0,0,0,1.26,1.2,1.21,1.21,0,0,0,1.21-1.21h0V22.05h9.75a1.22,1.22,0,0,0,1.22-1.21,1.24,1.24,0,0,0-1.22-1.24Z"
                        fill="#e2e2e2"
                        stroke="#e2e2e2"
                        strokeMiterlimit="10"
                        strokeWidth="0.75"
                    />
                    <path
                        id="Path_121"
                        data-name="Path 121"
                        d="M359.32,48.44H353V42.09a.8.8,0,0,0-.8-.8h0a.8.8,0,0,0-.8.8v6.35H345a.81.81,0,0,0-.8.81.8.8,0,0,0,.8.8h6.35v6.36a.8.8,0,1,0,1.6,0h0V50.05h6.36a.8.8,0,0,0,.8-.8A.81.81,0,0,0,359.32,48.44Z"
                        fill="#e2e2e2"
                        stroke="#e2e2e2"
                        strokeMiterlimit="10"
                        strokeWidth="0.75"
                    />
                </g>
            </g>
        </SVGIcon>
    );
}
