import React from 'react';

import { SVGIcon } from '../../UIKit/index';

export default function DCLegend(props) {
    return (
        <SVGIcon {...props} viewBox="0 0 512 512">
            <defs>
                <linearGradient
                    id="linear-gradient"
                    x1="0.795"
                    y1="0.112"
                    x2="0.213"
                    y2="0.906"
                    gradientUnits="objectBoundingBox"
                >
                    <stop offset="0" stopColor="#fab51f" />
                    <stop offset="1" stopColor="#aa7b13" />
                </linearGradient>
                <clipPath id="clip-Legend_Icon">
                    <rect width="512" height="512" />
                </clipPath>
            </defs>
            <g id="Legend_Icon" clipPath="url(#clip-Legend_Icon)">
                <rect width="512" height="512" fill="#fff" />
                <circle
                    id="Ellipse_1"
                    data-name="Ellipse 1"
                    cx="256"
                    cy="256"
                    r="256"
                    fill="url(#linear-gradient)"
                />
                <g id="king" transform="translate(128.238 119.958)">
                    <ellipse
                        id="Ellipse_2"
                        data-name="Ellipse 2"
                        cx="10.178"
                        cy="10.687"
                        rx="10.178"
                        ry="10.687"
                        transform="translate(117.823 24.042)"
                        fill="#fff"
                    />
                    <circle
                        id="Ellipse_3"
                        data-name="Ellipse 3"
                        cx="10.687"
                        cy="10.687"
                        r="10.687"
                        transform="translate(234.866 76.966)"
                        fill="#fff"
                    />
                    <circle
                        id="Ellipse_4"
                        data-name="Ellipse 4"
                        cx="10.687"
                        cy="10.687"
                        r="10.687"
                        transform="translate(-0.238 76.966)"
                        fill="#fff"
                    />
                    <path
                        id="Path_1"
                        data-name="Path 1"
                        d="M29.073,197.329H221.594l20.813-93.684a10.666,10.666,0,0,0-16.33-11.188l-53.948,35.962L134.87,53.9a10.666,10.666,0,0,0-19.079,0L78.532,128.419,24.582,92.457A10.666,10.666,0,0,0,8.254,103.645Z"
                        transform="translate(2.67 7.999)"
                        fill="#fff"
                    />
                    <path
                        id="Path_2"
                        data-name="Path 2"
                        d="M24,168v21.333A10.666,10.666,0,0,0,34.666,200H205.328a10.666,10.666,0,0,0,10.666-10.666V168Z"
                        transform="translate(8.003 47.994)"
                        fill="#fff"
                    />
                </g>
            </g>
        </SVGIcon>
    );
}
