import React from 'react';

import { SVGIcon } from '../../UIKit/index';

export default function DCBoxCheck(props) {
    return (
        <SVGIcon {...props} viewBox="0 0 512 512">
            <path d="M110.53,459.51c0,0,30.7,36.76,70.92,36.32l229.19,0c0,0,79.14-5.84,85.01-84.33V183.83   c0,0,0.61-46.38-36.69-72v295.76c0,0-3.04,44.76-48.45,51.57L110.53,459.51z" />
            <path d="M337.43,385.86H102.62c0,0-36.76-2.59-48-49.73v-224.3c0,0,2.16-48.99,51.03-55.48h231.78   c0,0,45.23,5.55,48.26,51.38v232.4c0,0-6.96,37.95-47.01,45.73v36.49c0,0,73.95-4.86,83.03-84V109.11c0,0,0.92-77.19-85.14-89.62   H105.22c0,0-79.35,1.23-86.38,92.34v217.6c0,0-1.41,84.86,87.68,92.92h230.92l3.76-36.57L337.43,385.86z" />
            <path d="M303.09,130.73c0,0-7.82-4.97-12.14,3.24l-87.14,129.3l-1.19-0.59l-40.76-40.7c0,0-4.11-2.81-8.22,0.22   l-24.43,23.78c0,0-3.03,4.54,0,7.14L193,317.54c0,0,18.05,13.84,32.32-5.3l102.16-150.38c0,0,8-10.05-1.19-15.03L303.09,130.73z" />
        </SVGIcon>
    );
}
