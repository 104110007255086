import React from 'react';

import { SVGIcon } from '../../UIKit/index';

export default function DCFillUpArray(props) {
    return (
        <SVGIcon {...props} viewBox="0 0 512 512">
            <polygon points="256,94.2 483.2,418.8 28.8,418.8 " />
        </SVGIcon>
    );
}
