import './style.scss';

import React, { Fragment, useCallback, useEffect, useRef, useState } from 'react';

import DCFillPencil from 'Assets/icons/DCFillPencil';
import AutoComplete from 'Components/AutoComplete';
import CurrencyList from 'Components/CurrencyList';
import DatePicker from 'Components/DatePicker';
import {
    Button,
    Div,
    FieldControl,
    IconButton,
    Input,
    Label,
    LabelControl,
    Popover,
    TextOverflowTooltip,
    ToolTip,
} from 'UIKit/index';
import { formateDate } from 'Utils/dateUtils';
import Span from '../InlineElement/span';
import DropdownOptions from 'UIKit/FormElement/dropdownOptions';
import areValidScopes from 'Utils/scopeUtils';
import AccessScopes from 'Constants/scopes';
import useToast from 'Modules/Toasts';
import { formatNumberString } from 'Utils';
import useMediaQuery from 'UIKit/useMediaQuery';
import { contractOptions } from 'Constants/deals';
import { Validation } from 'Utils/validator';
import PropTypes from 'prop-types';
import countryCurrency from 'Constants/currency.json';
function DealDetailEdit({
    customClass,
    name,
    status,
    currency,
    value,
    closeDate,
    stage,
    stageOptions,
    saveInformation,
    closePopover,
    loading,
    showEdit,
    contractType,
    contractLength,
    original,
    dealPreview = false,
}) {
    const matche575 = useMediaQuery('(max-width: 575.98px)');
    const editButtonRef = useRef();
    const [editPopoverOpen, setEditPopoverOpen] = useState(false);
    const [nameState, setNameState] = useState(name);
    const [currencyState, setCurrencyState] = useState(original?.currency);
    const [closeDateState, setCloseDateState] = useState(closeDate);
    const [stageState, setStageState] = useState(stage);
    const [contractTypeState, setContractTypeState] = useState(
        contractOptions.find(item => item.value === contractType) || contractOptions[0],
    );
    const [contractLengthState, setContractLengthState] = useState(contractLength);
    const [totalDealValue, setTotalDealValue] = useState(
        contractLength * original?.value ? contractLength * parseInt(original?.value) : 0,
    );
    const [disableSave, setDisableSave] = useState(true);
    const [contractTypeDropdownWidth, setContractTypeDropdownWidth] = useState(0);
    const [contractTypeDropdownOpen, setContractTypeDropdownOpen] = useState(false);
    const [errorVal, setErrorVal] = useState({
        dealValue: false,
        contractLength: false,
    });
    const getDealValue = useCallback(() => {
        let dealvalue = original?.value;
        if (original?.value - Math.floor(original?.value) !== 0) {
            const tempValue = parseFloat(original?.value).toFixed(2).toString().split('.');
            if (tempValue[1] === '00') {
                dealvalue = tempValue[0];
            }
        } else {
            dealvalue = original?.value;
        }
        return dealvalue;
    }, [original?.value]);
    const [valueState, setValueState] = useState(getDealValue());
    const contractButtonRef = useRef();

    const { showToast } = useToast();
    const handleEditPopoverToggle = useCallback(() => {
        setEditPopoverOpen(prev => !prev);
    }, []);
    const handleEditPopoverClose = useCallback(() => {
        setEditPopoverOpen(false);
        setNameState(name);
        const val = getDealValue();
        setValueState(val);
        setCurrencyState(original?.currency);
        setTotalDealValue(contractLength * val);
        setContractLengthState(contractLength);
        setContractTypeState(
            contractOptions.find(item => item.value === contractType) || contractOptions[0],
        );
    }, [contractLength, contractType, original, name, getDealValue]);

    useEffect(() => {
        if (closePopover !== undefined) {
            handleEditPopoverClose();
        }
    }, [closePopover, handleEditPopoverClose]);

    const handleContractTypeDropdownToggle = useCallback(() => {
        setContractTypeDropdownOpen(prevOpen => !prevOpen);
        setContractTypeDropdownWidth(contractButtonRef.current.offsetWidth);
    }, []);

    const handleContractTypeDropdownClose = useCallback(() => {
        setContractTypeDropdownOpen(false);
    }, []);

    const handleSelectContractTypeOption = useCallback(
        item => {
            setContractTypeState(item);
            setContractTypeDropdownOpen(false);
            if (item.value === 'one_time') {
                setContractLengthState(1);
            }
            if (disableSave) setDisableSave(false);
            if (contractTypeState.id === item.id) setDisableSave(true);
        },
        [contractTypeState, disableSave],
    );

    const generateStageOptions = useCallback(() => {
        const tempRowArray = [];
        if (stageOptions) {
            stageOptions.map((item, index) => tempRowArray.push({
                    id: index,
                    label: (
                        <Button
                            buttonClass={
                                'ACSingleOption ' + (stageState.name === item.name ? 'active' : '')
                            }
                            onClick={async () => {
                                if (
                                    !areValidScopes(
                                        AccessScopes.root.DEALS.text,
                                        AccessScopes.deals.DEALS_UPDATE.text,
                                    )
                                ) {
                                    showToast({ message: 'You do not have access to that page' });
                                    return;
                                }
                                setStageState(item);
                                saveInformation({ stage_id: item.id });
                            }}
                        >
                            {item.name}
                        </Button>
                    ),
                    value: item.name,
                }),
            );
        }
        return tempRowArray;
    }, [saveInformation, showToast, stageOptions, stageState.name]);

    const generateContractTypeOption = useCallback(() => {
        const tempOptionArray = [];
        contractOptions.map(item => tempOptionArray.push({
                label: (
                    <Button
                        buttonClass={
                            'DropdownOptionsBtn ' +
                            (item.id === contractTypeState.id ? 'active' : '')
                        }
                        onClick={() => {
                            handleSelectContractTypeOption(item);
                        }}
                    >
                        {item.label}
                    </Button>
                ),
            }),
        );
        return tempOptionArray;
    }, [contractTypeState, handleSelectContractTypeOption]);

    const generateStagePopoverOptions = useCallback(() => {
        const tempRowArray = [];
        if (stageOptions) {
            stageOptions.map((item, index) => tempRowArray.push({
                    id: index,
                    label: (
                        <Button
                            buttonClass={
                                'ACSingleOption ' + (stageState.name === item.name ? 'active' : '')
                            }
                            onClick={() => {
                                setStageState(item);
                                if (disableSave) setDisableSave(false);
                            }}
                        >
                            {item.name}
                        </Button>
                    ),
                    value: item.name,
                }),
            );
        }
        return tempRowArray;
    }, [disableSave, stageOptions, stageState]);

    const handleValueState = useCallback(
        e => {
            if (e.target.value.length > 75 && valueState.length === 75) {
                showToast({
                    type: 'warning',
                    message: 'Value can not be more than 75 characters',
                });
                return;
            }
            setValueState(e.target.valueAsNumber);
            setTotalDealValue(e.target.valueAsNumber * contractLengthState);
            if (disableSave) setDisableSave(false);
            if (value === e.target.value) setDisableSave(true);
            setErrorVal({
                ...errorVal,
                dealValue: !Validation('number', e.target.valueAsNumber),
            });
        },
        [contractLengthState, disableSave, errorVal, showToast, value, valueState],
    );
    const handleContractDuration = useCallback(() => {
        if (contractTypeState?.value === 'monthly') {
            return contractLength <= 1 ? 'Month' : 'Months';
        } else {
            return contractLength <= 1 ? 'Year' : 'Years';
        }
    }, [contractLength, contractTypeState?.value]);

    const generatePopOverFields = useCallback(() => {
        return (
            <Fragment>
                <FieldControl>
                    <LabelControl>
                        <Label props={{ htmlFor: 'Name' }}>Deal Name</Label>
                    </LabelControl>
                    <Input
                        id={'Name'}
                        autoFocus={true}
                        value={nameState}
                        onChange={e => {
                            setNameState(e.target.value);
                            if (disableSave) setDisableSave(false);
                            if (name === e.target.value) setDisableSave(true);
                        }}
                    />
                </FieldControl>
                <FieldControl>
                    <LabelControl>
                        <Label props={{ htmlFor: 'stage' }}>Stage</Label>
                    </LabelControl>
                    <AutoComplete
                        generateRows={generateStagePopoverOptions}
                        singleSelection
                        isAPICall={false}
                        value={stageState.name}
                        itemHeight={38}
                        height={stageOptions?.length < 5 ? 38 * stageOptions?.length : 190}
                    />
                </FieldControl>
                <FieldControl>
                    <LabelControl>
                        <Label props={{ htmlFor: 'Value' }}>Deal Value</Label>
                    </LabelControl>
                    <Input
                        id={'Value'}
                        type={'number'}
                        value={valueState}
                        onChange={handleValueState}
                        min={0}
                        exactAfterValue={
                            countryCurrency[
                                countryCurrency.findIndex(obj => obj.value === currencyState)
                            ]?.symbol || ''
                        }
                        showBefore={true}
                        error={errorVal.dealValue}
                        errorMessage={'Deal Value cannot be less than 0'}
                    />
                </FieldControl>

                <FieldControl>
                    <LabelControl>
                        <Label props={{ htmlFor: 'StatusName' }}>Contract Type</Label>
                    </LabelControl>
                    <Div className={'DCDropdownSelect fullWidth'}>
                        <Button
                            buttonClass={
                                'DCDSMainBtn ' + (contractTypeDropdownOpen === true ? 'focus' : '')
                            }
                            reference={contractButtonRef}
                            onClick={handleContractTypeDropdownToggle}
                        >
                            {contractTypeState?.label || 'Select Contract Type'}
                        </Button>
                        <DropdownOptions
                            options={generateContractTypeOption()}
                            onClose={handleContractTypeDropdownClose}
                            wrapperWidth={contractTypeDropdownWidth}
                            open={contractTypeDropdownOpen}
                            reference={contractButtonRef}
                            viewHeight={contractOptions.length * 40}
                            itemSize={40}
                            placement={'bottom-end'}
                        />
                    </Div>
                </FieldControl>
                {contractTypeState?.value !== 'one_time' ? (
                    <>
                        <FieldControl>
                            <LabelControl>
                                <Label props={{ htmlFor: 'ContractLength' }}>Contract Length</Label>
                            </LabelControl>
                            <Input
                                id={'ContractLength'}
                                type={'number'}
                                value={contractLengthState}
                                onChange={e => {
                                    setContractLengthState(e.target.value);
                                    setTotalDealValue(value * e.target.value);
                                    if (disableSave) setDisableSave(false);
                                    if (value === e.target.value) setDisableSave(true);
                                    setErrorVal({
                                        ...errorVal,
                                        contractLength: e.target.value.length
                                            ? !Validation('number', e.target.value)
                                            : !e.target.value.length,
                                    });
                                }}
                                exactAfterValue={handleContractDuration()}
                                error={errorVal.contractLength}
                                errorMessage={'Value cannot be less than 1'}
                                min={1}
                            />
                        </FieldControl>
                        <ToolTip
                            content={
                                'This is your "Deal Value" multiplied by contract length to give you a "Total Deal Value"'
                            }
                        >
                            <FieldControl>
                                <LabelControl>
                                    <Label props={{ htmlFor: 'TotalValue' }}>
                                        Total Deal Value
                                    </Label>
                                </LabelControl>
                                <Input
                                    id={'TotalValue'}
                                    type={'text'}
                                    readOnly
                                    disabled
                                    value={formatNumberString(totalDealValue)}
                                    min={0}
                                />
                            </FieldControl>
                        </ToolTip>
                    </>
                ) : null}
                <FieldControl>
                    <LabelControl>
                        <Label props={{ htmlFor: 'Currency' }} required>
                            Currency
                        </Label>
                    </LabelControl>

                    <CurrencyList
                        value={currencyState}
                        singleSelect
                        onChange={data => {
                            setCurrencyState(data[0]);
                            if (disableSave) setDisableSave(false);
                            if (original?.currency === data[0]) setDisableSave(true);
                        }}
                    />
                </FieldControl>
                <FieldControl>
                    <LabelControl>
                        <Label props={{ htmlFor: 'closeDate' }}>Expected Close Date</Label>
                    </LabelControl>
                    <DatePicker
                        height={405}
                        value={closeDateState}
                        onChange={val => {
                            setCloseDateState(val);
                            if (disableSave) setDisableSave(false);
                            if (closeDate === val) setDisableSave(true);
                        }}
                        onlyFutureDates
                    />
                </FieldControl>
            </Fragment>
        );
    }, [
        closeDate,
        closeDateState,
        contractLengthState,
        contractTypeDropdownOpen,
        contractTypeDropdownWidth,
        contractTypeState?.label,
        contractTypeState?.value,
        currencyState,
        disableSave,
        errorVal,
        generateContractTypeOption,
        generateStagePopoverOptions,
        handleContractDuration,
        handleContractTypeDropdownClose,
        handleContractTypeDropdownToggle,
        handleValueState,
        name,
        nameState,
        stageOptions?.length,
        stageState.name,
        totalDealValue,
        value,
        valueState,
        original,
    ]);

    const handleResetData = useCallback(() => {
        const val = getDealValue();
        setNameState(name);
        setValueState(val);
        setCloseDateState(closeDate);
        setStageState(stage);
        setCurrencyState(original?.currency);
        setTotalDealValue(contractLength * val);
        setContractLengthState();
        setContractTypeState(
            contractOptions.find(item => item.value === contractType) || contractOptions[0],
        );
    }, [closeDate, contractLength, contractType, original, name, stage, getDealValue]);

    return (
        <Fragment>
            {editPopoverOpen ? (
                <Popover
                    customClass={'DealDetailEditPopover ArrowPopover'}
                    open={editPopoverOpen}
                    anchorEl={editButtonRef}
                    onClose={handleEditPopoverClose}
                    placement={matche575 ? 'no-placement-left' : 'bottom-start'}
                    width={350}
                    maxHeight={400}
                >
                    <Fragment>
                        {generatePopOverFields()}
                        <Div className="DDEPBtns">
                            <Button
                                buttonType={'BlueFillBtn'}
                                onClick={async () => {
                                    if (
                                        !areValidScopes(
                                            AccessScopes.root.DEALS.text,
                                            AccessScopes.deals.DEALS_DELETE.text,
                                        )
                                    ) {
                                        showToast({
                                            message: 'You do not have access to that page',
                                        });
                                        handleResetData();
                                        return;
                                    }
                                    if (
                                        contractTypeState?.value === 'one_time' &&
                                        parseInt(contractLengthState) !== 1
                                    ) {
                                        showToast({
                                            type: 'warning',
                                            message:
                                                'For Contract type One-Time, contract length can be only 1',
                                        });
                                        return;
                                    }
                                    if (nameState.length === 0) {
                                        showToast({
                                            type: 'warning',
                                            message: 'Deal name cannot be empty',
                                        });
                                    } else {
                                        saveInformation({
                                            name: nameState,
                                            currency: currencyState,
                                            value: valueState,
                                            expected_close_date: closeDateState,
                                            stage_id: stageState.id,
                                            contract_type: contractTypeState?.value,
                                            contract_length: contractLengthState,
                                        });
                                    }
                                }}
                                disabled={
                                    loading ||
                                    disableSave ||
                                    Object.values(errorVal).some(val => val)
                                }
                            >
                                Save
                            </Button>
                            <Button
                                buttonType={'RedOutlineBtn'}
                                onClick={handleEditPopoverClose}
                                disabled={loading}
                            >
                                Cancel
                            </Button>
                        </Div>
                    </Fragment>
                </Popover>
            ) : null}
            <Div className={'DealDetailEdit' + (customClass ? customClass : '')}>
                <Div className="DDEInner">
                    <Div className="DDEIInfo">
                        {!showEdit && <Span className={`DDEIIStatus ${status}`}>{status}</Span>}
                        <Div className="DDEIIName">
                            <Div className="DDEIIInnar">
                                <TextOverflowTooltip tooltipContent={name}>
                                    {name}
                                </TextOverflowTooltip>
                                {showEdit && (
                                    <Div className="DDEIIBtn">
                                        <IconButton
                                            reference={editButtonRef}
                                            onClick={handleEditPopoverToggle}
                                        >
                                            <DCFillPencil />
                                        </IconButton>
                                    </Div>
                                )}
                            </Div>
                        </Div>
                        {value && (
                            <Div className="DDEIIValue">
                                <TextOverflowTooltip tooltipContent={value}>
                                    {!dealPreview
                                        ? `${
                                              countryCurrency[
                                                  countryCurrency.findIndex(
                                                      obj => obj.value === currencyState,
                                                  )
                                              ]?.symbol || ''
                                          } ${formatNumberString(
                                              original?.value * contractLength,
                                          )} ${original?.currency}`
                                        : `${currency.sign} ${formatNumberString(
                                              value * contractLength,
                                          )} ${currency.name}`}
                                </TextOverflowTooltip>
                            </Div>
                        )}
                        {closeDate && (
                            <Div className={'DDEIIContainer'}>
                                <Div className="DDEIILabel">
                                    {status === 'open' ? 'Expected Close Date' : 'Close Date'}:
                                </Div>
                                <Div className="DDEIIText">
                                    {showEdit ? (
                                        <DatePicker
                                            scope={{
                                                masterScope: AccessScopes.root.DEALS.text,
                                                childScope: AccessScopes.deals.DEALS_UPDATE.text,
                                            }}
                                            value={closeDate}
                                            onChange={async val => {
                                                saveInformation({
                                                    expected_close_date: val,
                                                });
                                            }}
                                            disabled={loading}
                                            onlyFutureDates
                                            currentStatus
                                        />
                                    ) : (
                                        formateDate(closeDate)
                                    )}
                                </Div>
                            </Div>
                        )}
                        {stage && (
                            <Div className={'DDEIIContainer'}>
                                <Div className="DDEIILabel">Stage:</Div>
                                <Div className="DDEIIText">
                                    {showEdit ? (
                                        <AutoComplete
                                            generateRows={generateStageOptions}
                                            singleSelection
                                            isAPICall={false}
                                            value={stage.name}
                                            itemHeight={38}
                                            height={
                                                stageOptions?.length < 5
                                                    ? 38 * stageOptions.length
                                                    : 190
                                            }
                                            readOnly={loading}
                                        />
                                    ) : (
                                        stage.name
                                    )}
                                </Div>
                            </Div>
                        )}
                    </Div>
                </Div>
            </Div>
        </Fragment>
    );
}

DealDetailEdit.propTypes = {
    customClass: PropTypes.string,
    name: PropTypes.string.isRequired,
    status: PropTypes.string.isRequired,
    currency: PropTypes.object.isRequired,
    value: PropTypes.string.isRequired,
    closeDate: PropTypes.string.isRequired,
    stage: PropTypes.object.isRequired,
    stageOptions: PropTypes.arrayOf(PropTypes.object).isRequired,
    saveInformation: PropTypes.func.isRequired,
    closePopover: PropTypes.bool.isRequired,
    loading: PropTypes.bool,
    showEdit: PropTypes.bool,
    contractType: PropTypes.string.isRequired,
    contractLength: PropTypes.number,
};

export default DealDetailEdit;
