import React from 'react';

import { SVGIcon } from '../../UIKit/index';

export default function DCOverviewMenu(props) {
    return (
        <SVGIcon {...props} viewBox="0 0 512 512">
            <path
                fill="#2F8DFF"
                d="M160.79,462.21h137.83c11.02,0,19.96,8.94,19.96,19.96l0,0c0,11.02-8.94,19.96-19.96,19.96  H160.79c-11.02,0-19.96-8.94-19.96-19.96l0,0C140.83,471.14,149.77,462.21,160.79,462.21z"
            />
            <rect fill="#EEF4FF" x="189.77" y="408.3" width="79.87" height="53.9" />
            <path
                fill="#2F8DFF"
                d="M9.19,350.41H448.3l0,0l0,0c0,31.97-25.92,57.89-57.89,57.89H67.08  C35.11,408.3,9.19,382.38,9.19,350.41C9.19,350.41,9.19,350.41,9.19,350.41L9.19,350.41L9.19,350.41z"
            />
            <rect fill="#E6EFFF" x="8.92" y="29.4" width="439.12" height="321.01" />
            <path fill="#231F20" d="M133.9,82.86h357.28" />
            <path
                fill="none"
                d="M404.59,150.86c-24.21-23.3-55.96-37.15-89.51-39.04c-33.46,2.11-65.12,15.86-89.51,38.86  c-9.41,8.13-18.19,16.97-26.25,26.43c-11.59,13.65-11.59,15.84-11.59,18.59c0,2.75,0,4.79,11.61,18.41  c8.07,9.45,16.84,18.29,26.23,26.43c24.21,23.3,55.96,37.15,89.51,39.04c33.46-2.11,65.12-15.86,89.51-38.86  c9.41-8.13,18.19-16.97,26.25-26.43c11.59-13.65,11.59-15.84,11.59-18.59s0-4.79-11.61-18.41  C422.75,167.84,413.98,159,404.59,150.86z M315.08,259.64c-50.05,0-92.72-48.54-105.08-64.12c13.09-16.9,56.76-63.74,105.08-63.74  c50.05,0,92.72,48.52,105.1,64.12C407.07,212.8,363.43,259.64,315.08,259.64L315.08,259.64z"
            />
            <path
                fill="none"
                d="M315.11,145.61c-27.65,0-50.07,22.42-50.07,50.07c0,27.65,22.42,50.07,50.07,50.07  c27.65,0,50.07-22.42,50.07-50.07C365.14,168.04,342.74,145.64,315.11,145.61z M315.11,225.79c-16.63,0-30.11-13.48-30.11-30.11  c0-16.63,13.48-30.11,30.11-30.11c16.63,0,30.11,13.48,30.11,30.11C345.2,212.3,331.73,225.78,315.11,225.79z"
            />
            <g transform="translate(5.109 6.452)">
                <path
                    fill="#2F8DFF"
                    d="M342.92,211.4c0,1.37-52.55,73.91-117.42,73.91s-117.42-72.98-117.42-73.91   c0-1.37,52.55-73.91,117.42-73.91S342.92,210.47,342.92,211.4z"
                />
                <circle fill="#FFFFFF" cx="225.59" cy="211.4" r="40.1" />
                <path
                    fill="#2F8DFF"
                    d="M225.58,201.43c-5.52,0-9.99,4.48-9.99,10c0,5.52,4.48,9.99,10,9.99c5.52,0,9.99-4.48,9.99-10   c0-2.65-1.05-5.19-2.93-7.06C230.78,202.49,228.23,201.43,225.58,201.43z"
                />
            </g>
            <path
                fill="none"
                d="M501.28,1H128.91c-5.52,0-9.99,4.47-9.99,9.99c0,0,0,0,0,0v298.48c0,5.52,4.47,9.99,9.99,9.99h0  h308.47v21h-0.91H20.06V105.82h43.96c5.51-0.37,9.67-5.13,9.3-10.63c-0.33-4.99-4.31-8.97-9.3-9.3h-53.9c-5.5,0-9.97,4.45-9.99,9.95  v312.39c0,5.52,4.47,9.99,9.99,9.99c0,0,0,0,0,0h168.73v33.94H139.9c-5.52,0-9.99,4.47-9.99,9.99l0,0v39.88  c0,5.52,4.47,9.99,9.99,9.99h0h177.75c5.52,0,9.99-4.47,9.99-9.99l0,0v-39.88c0-5.52-4.47-9.99-9.99-9.99l0,0h-38.97v-33.85h168.67  c5.52,0,9.99-4.47,9.99-9.99l0,0v-88.84h43.93c5.52,0,9.99-4.47,9.99-9.99l0,0V10.99C511.27,5.47,506.8,1,501.28,1  C501.28,1,501.28,1,501.28,1z M307.66,492.16H149.87v-19.94h157.77v19.94H307.66z M258.72,452.28H198.9v-33.99h59.82V452.28z   M268.71,398.38H20.06v-38h416.41h0.91v37.97L268.71,398.38z M491.28,20.94v51.93h-352.4V20.94H491.28z M138.88,299.5V92.83h352.4  v206.64L138.88,299.5z"
            />
            <g transform="translate(6.724 2.479)">
                <path
                    fill="#2F8DFF"
                    d="M256.21,73.38h152.07c5.51-0.37,9.67-5.13,9.3-10.63c-0.33-4.99-4.31-8.97-9.3-9.31H256.14   c-5.51-0.37-10.27,3.8-10.63,9.31c-0.37,5.51,3.8,10.27,9.31,10.63c0.44,0.03,0.89,0.03,1.33,0H256.21z"
                />
                <path
                    fill="#2F8DFF"
                    d="M181.25,73.38c5.52,0,9.99-4.48,9.99-10s-4.48-9.99-10-9.99c-5.52,0-9.99,4.48-9.99,10   c0,2.65,1.05,5.19,2.93,7.06C176.07,72.33,178.61,73.38,181.25,73.38z"
                />
                <path
                    fill="#2F8DFF"
                    d="M152.34,73.38c2.65-0.01,5.2-1.06,7.09-2.92c3.9-3.87,3.92-10.17,0.04-14.07   c-0.02-0.01-0.03-0.03-0.04-0.04c-3.93-3.9-10.27-3.9-14.2,0c-3.89,3.91-3.87,10.24,0.04,14.13c1.87,1.86,4.41,2.91,7.05,2.91   H152.34z"
                />
                <path
                    fill="#2F8DFF"
                    d="M212.65,73.38c2.58-0.02,5.05-1.07,6.85-2.92c3.78-3.94,3.78-10.17,0-14.11   c-3.62-3.79-9.62-3.92-13.41-0.31c-0.11,0.1-0.21,0.2-0.31,0.31c-3.78,3.94-3.78,10.17,0,14.11   C207.58,72.32,210.06,73.37,212.65,73.38z"
                />
            </g>
        </SVGIcon>
    );
}
