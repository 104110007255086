import React from 'react';

import { SVGIcon } from '../../UIKit/index';

export default function DCOverviewChart(props) {
    return (
        <SVGIcon {...props} viewBox="0 0 512 512">
            <g id="Group_700" transform="translate(-553.833 -486.918)">
                <path
                    d="M1050.09,486.61H562.94c-5.38,0-9.74,5.79-9.74,12.92v387.79c0,7.14,4.36,12.92,9.74,12.92
		h487.15c5.38,0,9.74-5.79,9.74-12.92V499.53C1059.83,492.4,1055.47,486.61,1050.09,486.61z M1040.35,874.4h-68.24V667.57
		c0-7.14-4.36-12.92-9.74-12.92s-9.74,5.79-9.74,12.92V874.4H894.2v-64.63c0-7.14-4.36-12.92-9.74-12.92
		c-5.38,0-9.74,5.79-9.74,12.92v64.63h-58.45V719.28c0-7.14-4.36-12.92-9.74-12.92c-5.38,0-9.74,5.79-9.74,12.92V874.4H738.3v-51.72
		c0-7.14-4.36-12.92-9.74-12.92c-5.38,0-9.74,5.79-9.74,12.92v51.71h-58.45c0.04-3.27-0.95-6.48-2.85-9.14
		c-2.42-3.81-7.48-4.93-11.28-2.5c-1.01,0.64-1.86,1.5-2.5,2.5c-1.9,2.67-2.89,5.87-2.85,9.14h-68.24v-370.4h467.66L1040.35,874.4z"
                />
                <path
                    d="M629.94,780.57c18,0.04,32.63-14.53,32.67-32.53c0-1.39-0.08-2.77-0.26-4.15l32.05-16.02
		c12.98,12.51,33.65,12.12,46.16-0.86c9.43-9.79,11.81-24.39,5.97-36.67l0,0l43.55-43.54l0,0c9.59,4.59,20.81,4.21,30.07-1.01
		l40.76,35.67c-6.69,16.75,1.48,35.75,18.23,42.44c16.75,6.69,35.75-1.48,42.44-18.23c4.04-10.14,2.76-21.62-3.43-30.62l50.89-69.94
		c17.32,5.16,35.54-4.7,40.7-22.02c5.16-17.32-4.7-35.54-22.02-40.7c-17.32-5.16-35.54,4.7-40.7,22.02
		c-2.83,9.49-1.2,19.75,4.42,27.9l-50.89,69.94c-8.47-2.53-17.6-1.5-25.3,2.84l-40.76-35.67c6.67-16.76-1.5-35.75-18.26-42.43
		c-16.76-6.67-35.75,1.5-42.43,18.26c-3.37,8.46-3.05,17.95,0.87,26.17l0,0l-43.55,43.55l0,0c-16.27-7.78-35.76-0.89-43.54,15.38
		c-2.1,4.4-3.19,9.22-3.19,14.09c0,1.35,0.09,2.69,0.26,4.03l-32.05,16.02c-12.98-12.52-33.65-12.14-46.17,0.85
		c-12.52,12.98-12.14,33.65,0.85,46.17c6.09,5.87,14.21,9.15,22.67,9.14V780.57z M978.33,562.82c6.01,0,10.88,4.87,10.88,10.88
		s-4.87,10.88-10.88,10.88c-6.01,0-10.88-4.87-10.88-10.88C967.46,567.7,972.33,562.83,978.33,562.82L978.33,562.82z M891.23,682.58
		c6.01,0,10.88,4.87,10.88,10.88c0,6.01-4.87,10.88-10.88,10.88c-6.01,0-10.88-4.87-10.88-10.88
		C880.36,687.45,885.23,682.59,891.23,682.58L891.23,682.58z M804.13,606.36c6.01,0,10.88,4.87,10.88,10.88
		c0,6.01-4.87,10.88-10.88,10.88c-6.01,0-10.88-4.87-10.88-10.88C793.26,611.24,798.13,606.38,804.13,606.36L804.13,606.36z
		 M717.04,693.47c6.01,0,10.88,4.87,10.88,10.88c0,6.01-4.87,10.88-10.88,10.88c-6.01,0-10.88-4.87-10.88-10.88
		C706.16,698.35,711.03,693.48,717.04,693.47z M629.94,737.02c6.01,0,10.88,4.87,10.88,10.88c0,6.01-4.87,10.88-10.88,10.88
		c-6.01,0-10.88-4.87-10.88-10.88C619.07,741.9,623.93,737.03,629.94,737.02L629.94,737.02z"
                />
            </g>
        </SVGIcon>
    );
}
