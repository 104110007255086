import React, { useCallback, useEffect, useRef, useState } from 'react';

import dayjs from 'dayjs';
import PropTypes from 'prop-types';
// import { useDateInput } from 'react-nice-dates';

import { Div, List, ListItem, Popover } from '../../UIKit/index';
import { getUserTimeZone, timeInUserTimeZone } from 'Utils/dateUtils';

import './TimePicker.scss';

const hoursArray = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
const minutesArray = [
    0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25,
    26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49,
    50, 51, 52, 53, 54, 55, 56, 57, 58, 59,
];
const timeFormateArray = ['AM', 'PM'];

function TimePicker(props) {
    const { value, onChange, placement, readOnly, disabled, resetVal } = props;
    const [date, setDate] = useState(timeInUserTimeZone(value));
    const timeZone = getUserTimeZone();
    const [hrSelected, setHrSelected] = useState(false);
    const [minSelected, setMinSelected] = useState(false);
    const [formateSelected, setFormateSelected] = useState(false);
    const inputRef = useRef();
    const [openDropdown, setOpenDropdown] = useState(false);

    useEffect(() => {
        if (resetVal) {
            setDate(timeInUserTimeZone(value));
        }
    }, [resetVal, value]);
    const handleTimeDropdownToggle = () => {
        if (!readOnly) setOpenDropdown(prevOpen => !prevOpen);
    };

    const handleTimeDropdownClose = () => {
        setOpenDropdown(false);
    };

    const handleSelectHours = useCallback(
        selectHours => {
            let newDate = dayjs(date).tz(timeZone);

            if (newDate.hour() >= 12) {
                // PM is currently selected
                newDate = newDate.hour(selectHours + 12);
            } else {
                newDate = newDate.hour(selectHours);
            }

            setDate(newDate.format());
            onChange && onChange(newDate.format());
            setHrSelected(true);
            // if (minSelected && formateSelected) {
            //     setOpenDropdown(false);
            // }
        },
        [date, onChange, timeZone],
    );

    const handleSelectMinutes = useCallback(
        selectMinutes => {
            let newDate = dayjs(date).tz(timeZone);

            newDate = newDate.minute(selectMinutes);
            setDate(newDate.format());
            setMinSelected(true);
            onChange && onChange(newDate.format());
            // if (hrSelected && formateSelected) {
            //     setOpenDropdown(false);
            // }
        },

        [date, onChange, timeZone],
    );

    const handleSelectTimeFormate = useCallback(
        timeFormate => {
            let newDate = dayjs(date).tz(timeZone);

            if (timeFormate === 'AM') {
                if (newDate.hour() === 12) {
                    newDate = newDate.hour(0);
                } else if (newDate.hour() > 12) {
                    newDate = newDate.hour(newDate.hour() - 12);
                }
            } else {
                if (newDate.hour() < 12) {
                    newDate = newDate.hour(newDate.hour() + 12);
                }
            }
            setDate(newDate.format());
            setFormateSelected(true);
            onChange && onChange(newDate.format());
        },
        [date, onChange, timeZone],
    );

    useEffect(() => {
        if (hrSelected && minSelected && formateSelected) {
            setOpenDropdown(false);
            setHrSelected(false);
            setMinSelected(false);
            setFormateSelected(false);
        }
    }, [formateSelected, hrSelected, minSelected]);

    return (
        <Div className={'TimePicker'}>
            <Div className={'TimePickerInput'}>
                <input
                    type="text"
                    value={dayjs(date).tz(timeZone).format('hh : mm a') || ''}
                    placeholder={'hh : mm a'}
                    onChange={setDate}
                    disabled={disabled}
                    ref={inputRef}
                    onClick={handleTimeDropdownToggle}
                    readOnly={true}
                />
            </Div>
            <Popover
                open={openDropdown}
                anchorEl={inputRef}
                onClose={handleTimeDropdownClose}
                width={135}
                placement={placement || 'bottom-start'}
                withOutPadding
            >
                <Div className={'TimePickerOptionsLists'}>
                    <List className={'TPOHours'}>
                        <ListItem>Hr</ListItem>
                        {hoursArray.map((item, index) => (
                            <ListItem
                                key={index}
                                onClick={() => {
                                    handleSelectHours(item);
                                }}
                                className={
                                    ('0' + item).slice(-2) === dayjs(date).tz(timeZone).format('hh')
                                        ? 'active'
                                        : ''
                                }
                            >
                                {('0' + item).slice(-2)}
                            </ListItem>
                        ))}
                    </List>
                    <List className={'TPOMin'}>
                        <ListItem>Min</ListItem>
                        {minutesArray.map((item, index) => (
                            <ListItem
                                key={index}
                                onClick={() => {
                                    handleSelectMinutes(item);
                                }}
                                className={
                                    ('0' + item).slice(-2) === dayjs(date).tz(timeZone).format('mm')
                                        ? 'active'
                                        : ''
                                }
                            >
                                {('0' + item).slice(-2)}
                            </ListItem>
                        ))}
                    </List>
                    <List className={'TPOFormate'}>
                        <ListItem />
                        {timeFormateArray.map((item, index) => (
                            <ListItem
                                key={index}
                                onClick={() => {
                                    handleSelectTimeFormate(item);
                                }}
                                className={
                                    item === dayjs(date).tz(timeZone).format('A') ? 'active' : ''
                                }
                            >
                                {('0' + item).slice(-2)}
                            </ListItem>
                        ))}
                    </List>
                </Div>
            </Popover>
        </Div>
    );
}

TimePicker.propTypes = {
    value: PropTypes.string,
    timezone: PropTypes.string,
    onChange: PropTypes.func,
    placement: PropTypes.string,
    readOnly: PropTypes.bool,
    disabled: PropTypes.bool,
    resetVal: PropTypes.bool,
};

export default TimePicker;
