import React from 'react';

import { SVGIcon } from '../../UIKit/index';

export default function DCWebDevices(props) {
    return (
        <SVGIcon {...props} viewBox="0 0 512 512">
            <path d="M161.7,257.6h71.4V274h-71.4v13.6h224.6V274H303v-16.5h92.6V220H161.7V257.6z" />
            <path d="M99.5,31.3h340.4v136.1h29.2V2.1H70.3v91.1h29.2V31.3z" />
            <path d="M0,330.8h152.3V102.4H0V330.8z M79.3,319.3c-4,0-7.3-3.3-7.3-7.3c0-4,3.3-7.3,7.3-7.3    s7.3,3.3,7.3,7.3C86.7,316,83.4,319.3,79.3,319.3C79.4,319.3,79.4,319.3,79.3,319.3L79.3,319.3z M22,124.4h108.2v168.4H22V124.4z" />
            <path d="M405.9,176.1v160.8H512V176.1H405.9z M461.1,182.8c1.3,0,2.3,1.1,2.3,2.3    c0,1.3-1.1,2.3-2.3,2.3c-1.3,0-2.3-1-2.3-2.3C458.8,183.8,459.8,182.7,461.1,182.8C461.1,182.7,461.1,182.7,461.1,182.8    L461.1,182.8z M447.3,190.5h27.5v2.2h-27.5V190.5z M460.2,330c-3,0-5.5-2.5-5.5-5.5c0-3,2.5-5.5,5.5-5.5c3,0,5.5,2.5,5.5,5.5    c0,0,0,0,0,0C465.7,327.5,463.2,330,460.2,330L460.2,330z M496.7,311.1c-24.8,0.1-63.1,0.2-75.4,0.1V200.6h75.4V311.1z" />
        </SVGIcon>
    );
}
