import React from 'react';

import { SVGIcon } from '../../UIKit/index';

export default function DCChat1Filled(props) {
    return (
        <SVGIcon {...props} viewBox="0 0 512 512">
            <path d="M512.04,367.74c-.22,1.86-5.83,45.91-48.59,51.92-.05,0-.11,.01-.16,.01l-58.76,.31c-.59,0-1.07,.48-1.08,1.07l-.92,69.74c-.01,.89-1.03,1.39-1.74,.85l-32.95-24.91c-.27-.2-.43-.51-.43-.85l-.06-3.22v1.59c-.02,.89-1.04,1.4-1.75,.86l-59.93-44.82c-.19-.14-.42-.22-.65-.22h-111.12c-.06,0-.11,0-.17-.01-1.21-.21-15.29-2.78-27.94-12.89-3.68-2.93-7.23-6.5-10.32-10.84-.34-.48-.23-1.15,.24-1.51l26.18-19.58,.46-.34,34.02-25.44c.19-.14,.42-.22,.65-.22h111.13c.06,0,.11,0,.17-.01,.28-.05,27.18-.62,27.18-.62,.06,0,.11,0,.17-.01,2.06-.35,41.42-7.56,47.51-47.07,0-.06,.01-.12,.01-.18V129.78c0-.6,.49-1.09,1.09-1.09h60.75s.09,0,.14,0c1.88,.25,42.67,6.15,46.14,49.69,0,.03,0,.07,0,.1l.75,189.13s0,.08,0,.13Z" />
            <path d="M154.84,395.98l-.14,.11v-.32c.04,.07,.09,.15,.14,.21Z" />
            <path d="M317.03,20.12H46.91S4.23,25.26,.7,69.87L-.05,259.11s4.92,45.89,48.67,51.99l59.91,.32,.96,72.96,35.09-26.53,.07-3.74,.03,3.74,61.94-46.32h111.58s41.4-6.53,47.6-47.18V68.8s-6.42-44.72-48.78-48.67Z" />
        </SVGIcon>
    );
}
