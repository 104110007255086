import React from 'react';

import { SVGIcon } from '../../UIKit/index';

export default function DCLineBreak(props) {
    return (
        <SVGIcon {...props} viewBox="0 0 23.153 23.153">
            <g transform="translate(-7.8 -7.8)">
                <path d="M29.8,8a.948.948,0,0,0-.948.948v5.688H9.9V8.948a.948.948,0,0,0-1.9,0v5.688a1.9,1.9,0,0,0,1.9,1.9H28.857a1.9,1.9,0,0,0,1.9-1.9V8.948A.948.948,0,0,0,29.8,8Z" />
                <path
                    d="M28.857,38H9.9A1.9,1.9,0,0,0,8,39.9v5.688a.948.948,0,0,0,1.9,0V39.9H28.857v5.688a.948.948,0,0,0,1.9,0V39.9A1.9,1.9,0,0,0,28.857,38Z"
                    transform="translate(0 -15.779)"
                />
                <path
                    d="M9.948,31.9H10.9a.948.948,0,0,0,0-1.9H9.948a.948.948,0,1,0,0,1.9Z"
                    transform="translate(-0.526 -11.572)"
                />
                <path
                    d="M20.9,31.9a.948.948,0,0,0,0-1.9h-.948a.948.948,0,1,0,0,1.9Z"
                    transform="translate(-5.786 -11.572)"
                />
                <path
                    d="M30.9,31.9a.948.948,0,1,0,0-1.9h-.948a.948.948,0,1,0,0,1.9Z"
                    transform="translate(-11.046 -11.572)"
                />
                <path
                    d="M40.9,31.9a.948.948,0,0,0,0-1.9h-.948a.948.948,0,1,0,0,1.9Z"
                    transform="translate(-16.305 -11.572)"
                />
                <path
                    d="M50.9,31.9a.948.948,0,0,0,0-1.9h-.948a.948.948,0,0,0,0,1.9Z"
                    transform="translate(-21.565 -11.572)"
                />
            </g>
        </SVGIcon>
    );
}
