import React, { useCallback, useEffect, useRef, useState } from 'react';
import _ from 'lodash';

import PropTypes from 'prop-types';
import { FixedSizeList as List } from 'react-window';
import DCNoResultFound from '../../Assets/icons/DCNoResultFound';
import { SearchBox } from '../../Components/index';
import InfiniteScroll from '../../Modules/InfiniteScroll';
import { CircularLoader, Div, Popover, Skeleton, Button, NoData } from '../../UIKit/index';

import areValidScopes from 'Utils/scopeUtils';
import useToast from 'Modules/Toasts';
import './style.scss';

const Row = ({ style, data, index }) => (
    <Div
        style={style}
        onClick={data.func.singleSelection === true ? data.func.handlePopoverClose : null}
    >
        {data.data[index].label}
    </Div>
);

const AutoComplete = props => {
    const {
        generateRows,
        searchAPI,
        isAPICall,
        value,
        placeholder,
        singleSelection,
        itemHeight,
        loading,
        hasNextPage,
        getMoreOptions,
        customClass,
        popoverCustomClass,
        customWidth,
        readOnly,
        placement,
        height,
        reference,
        inputReference,
        scope,
        searchValue,
        isSearch = true,
        searchKey = 'value',
        disabled,
        customMessage,
        dataPrivate = false,
        showNoResults = true,
        enteredValue,
        type = 'normal',
        searchBarClass,
        searchBoxPlaceholder,
        searchbarExpandBreakpoint,
        autoFocus = true,
    } = props;
    const [searchText, setSearchText] = useState('');
    const [aPICall, setAPICall] = useState(false);
    const [data, setData] = useState([]);
    const [skeletonCount, setSkeletonCount] = useState(0);
    const [popoverOpen, setPopoverOpen] = useState(false);
    const [popoverMoreOpen, setPopoverMoreOpen] = useState(false);
    const [isSearchAnimationDone, setIsSearchAnimationDone] = useState(false);

    let moreDataValue = useRef([]);
    let initRef = useRef(true);
    const searchInputReference = useRef();

    const { showToast } = useToast();
    useEffect(() => {
        if (enteredValue && initRef.current) {
            setSearchText(value);
            initRef.current = false;
        }
    }, [enteredValue, value]);

    useEffect(() => {
        const tempData = generateRows();

        setData(tempData);
        if (popoverOpen === 'false') {
            setData(tempData);
        }
    }, [generateRows, popoverOpen]);

    useEffect(() => {
        setAPICall(isAPICall);
    }, [isAPICall]);

    const handleInternalSearch = useCallback(
        text => {
            const lowerText = text.toLowerCase();
            const temp = [];
            const initialData = generateRows();
            initialData.forEach(option => {
                if (option[searchKey].toLowerCase().indexOf(lowerText) !== -1) {
                    temp.push(option);
                }
            });
            setData(temp);
        },
        [generateRows, searchKey],
    );

    // Popover
    const inputRef = useRef();
    const [popoverWidth, setPopoverWidth] = useState(0);
    const handlePopoverToggle = useCallback(async () => {
        setSearchText(searchValue || '');
        if (!disabled) {
            if (scope && !popoverOpen) {
                if (!areValidScopes(scope.masterScope, scope.childScope)) {
                    {
                        showToast({ message: 'You do not have access to that page' });
                        return;
                    }
                }
            }
            if (!readOnly) {
                setPopoverOpen(prevOpen => !prevOpen);
                setPopoverWidth(
                    inputReference !== undefined
                        ? inputReference.current.offsetWidth
                        : inputRef?.current?.offsetWidth,
                );
            }
        }
    }, [disabled, inputReference, popoverOpen, readOnly, scope, searchValue, showToast]);

    const handlePopoverClose = useCallback(() => {
        setPopoverOpen(false);
        if (!enteredValue) setSearchText('');
        if (aPICall) {
            searchAPI && searchAPI('');
        }
    }, [aPICall, searchAPI, enteredValue]);

    const handlePopoverMoreClose = useCallback(() => {
        setPopoverMoreOpen(false);
        if (!enteredValue) setSearchText('');
    }, [enteredValue]);

    const handleSearch = useCallback(
        text => {
            enteredValue && enteredValue(text);
            setSearchText(text);
            if (aPICall) {
                searchAPI && searchAPI(text);
            } else {
                handleInternalSearch(text);
            }
        },
        [aPICall, handleInternalSearch, searchAPI, enteredValue],
    );

    const getMoreSearchOptions = useCallback(() => {
        if (aPICall) searchAPI && searchAPI(searchText);
    }, [aPICall, searchAPI, searchText]);

    const infiniteRef = InfiniteScroll({
        loading,
        hasNextPage,
        onLoadMore: () => {
            if (searchText.length) {
                getMoreSearchOptions();
            } else getMoreOptions();
        },
        threshold: 50,
    });

    const handleMoreValue = useCallback(() => {
        setPopoverMoreOpen(prevOpen => !prevOpen);
    }, []);

    const generateDisplayValue = useCallback(() => {
        if (typeof value === 'object') {
            if (value?.length > 2) {
                let temp = [];
                for (let i = 0; i < 2; i++) {
                    temp.push(value[i]);
                }
                let moreData = generateRows().filter(
                    el => el.label?.props?.children?.props?.checked === true,
                );
                let moreDataFiltered = moreData.filter(
                    el => !temp.find(element => element.props?.title === el?.value),
                );
                moreDataValue.current = [...moreDataFiltered];
                temp.push(<Button onClick={handleMoreValue}>+ {value.length - 2} more</Button>);
                return temp;
            } else {
                moreDataValue.current = [];
                if (popoverMoreOpen) {
                    setPopoverMoreOpen(false);
                }
                return value;
            }
        } else return value;
    }, [generateRows, handleMoreValue, popoverMoreOpen, value]);

    useEffect(() => {
        let temp = (height || 250) / (itemHeight || 35);
        setSkeletonCount(parseInt(temp));
    }, [height, itemHeight]);
    const [key, setKey] = useState(0);

    useEffect(() => {
        window.addEventListener('resize', () => {
            setKey(Math.random());
        });
    }, []);

    useEffect(() => {
        if (popoverOpen) {
            if (searchbarExpandBreakpoint) {
                setTimeout(() => {
                    setIsSearchAnimationDone(true);
                }, 400);
            } else {
                setIsSearchAnimationDone(true);
            }
        }

        return () => {
            setIsSearchAnimationDone(false);
        };
    }, [popoverOpen, searchbarExpandBreakpoint]);

    return (
        <>
            {type === 'search' ? (
                // This one is Search Bar Without Popup
                <Div
                    className={`ACRSearch  ${searchBarClass ? searchBarClass : 'HeaderSearch'} ${
                        popoverOpen && searchbarExpandBreakpoint ? 'Big' : ''
                    }`}
                    onClick={() => {
                        setPopoverOpen(prevOpen => !prevOpen);
                    }}
                    reference={searchInputReference}
                >
                    <SearchBox
                        delay={isAPICall ? 1500 : 1}
                        handleSearch={handleSearch}
                        fullWidth={true}
                        value={searchText}
                        setValue={setSearchText}
                        autoFocus={autoFocus}
                        placeHolder={searchBoxPlaceholder}
                    />

                    {searchInputReference.current && isSearchAnimationDone && (
                        <Popover
                            customClass={
                                'AutoCompleteRootPopover ' +
                                (popoverCustomClass ? popoverCustomClass : '') +
                                ' HeaderSearchPopover'
                            }
                            open={popoverOpen}
                            anchorEl={searchInputReference}
                            onClose={handlePopoverClose}
                            minWidth={searchInputReference.current.getBoundingClientRect().width}
                            placement={placement || 'bottom-start'}
                            withOutPadding
                            height={
                                (showNoResults && height) || (showNoResults ? height || 250 : 0)
                            }
                        >
                            {data?.length > 0 ? (
                                <>
                                    <List
                                        className={'ACRResults'}
                                        height={height || 250}
                                        itemCount={data.length}
                                        itemSize={itemHeight || 35}
                                        itemData={{
                                            func: { singleSelection, handlePopoverClose },
                                            data,
                                        }}
                                        outerRef={infiniteRef}
                                    >
                                        {Row}
                                    </List>
                                    {loading && <CircularLoader />}
                                </>
                            ) : loading ? (
                                <Div className={'ACSkeletonContainer'}>
                                    {_.times(skeletonCount, () => (
                                        <Skeleton
                                            className={'ACSkeleton'}
                                            height={itemHeight || 35}
                                            width={
                                                customWidth ? customWidth - 10 : popoverWidth - 10
                                            }
                                        />
                                    ))}
                                </Div>
                            ) : (
                                showNoResults && (
                                    <Div className={'ACRNotResults'} style={{ height: 250 }}>
                                        <NoData
                                            icon={<DCNoResultFound />}
                                            title={customMessage || 'No Options'}
                                            type="small"
                                        />
                                    </Div>
                                )
                            )}
                        </Popover>
                    )}
                </Div>
            ) : (
                // This one is Search Bar with Popup
                <Div
                    className={'AutoCompleteRoot ' + (customClass ? customClass : '')}
                    reference={reference}
                    data-private={dataPrivate}
                >
                    <Div
                        className={
                            'ACRLabel' +
                            (readOnly ? ' ACRLabelReadonly' : '') +
                            (singleSelection ? ' SingleSelection' : ' ACRMultiSelection') +
                            (disabled ? ' ACRLabelDisabled' : '')
                        }
                        key={key}
                        onClick={handlePopoverToggle}
                        reference={inputReference !== undefined ? inputReference : inputRef}
                    >
                        {generateDisplayValue() || placeholder}
                    </Div>
                    <Popover
                        customClass={
                            'AutoCompleteRootPopover ' +
                            (popoverCustomClass ? popoverCustomClass : '')
                        }
                        open={popoverOpen && !popoverMoreOpen}
                        anchorEl={inputReference !== undefined ? inputReference : inputRef}
                        onClose={handlePopoverClose}
                        width={customWidth || popoverWidth}
                        placement={placement || 'bottom-start'}
                        withOutPadding
                        height={(showNoResults && height) || (showNoResults ? height || 250 : 0)}
                    >
                        {isSearch && (
                            <Div className={'ACRSearch'}>
                                <SearchBox
                                    delay={isAPICall ? 1500 : 1}
                                    handleSearch={handleSearch}
                                    fullWidth={true}
                                    value={searchText}
                                    setValue={setSearchText}
                                    autoFocus={autoFocus}
                                />
                            </Div>
                        )}
                        {data?.length > 0 ? (
                            <>
                                <List
                                    className={'ACRResults'}
                                    height={height || 250}
                                    itemCount={data.length}
                                    itemSize={itemHeight || 35}
                                    itemData={{
                                        func: { singleSelection, handlePopoverClose },
                                        data,
                                    }}
                                    outerRef={infiniteRef}
                                >
                                    {Row}
                                </List>
                                {loading && <CircularLoader />}
                            </>
                        ) : loading ? (
                            <Div className={'ACSkeletonContainer'}>
                                {_.times(skeletonCount, () => (
                                    <Skeleton
                                        className={'ACSkeleton'}
                                        height={itemHeight || 35}
                                        width={customWidth ? customWidth - 10 : popoverWidth - 10}
                                    />
                                ))}
                            </Div>
                        ) : (
                            showNoResults && (
                                <Div className={'ACRNotResults'} style={{ height: 250 }}>
                                    <NoData
                                        icon={<DCNoResultFound />}
                                        title={customMessage || 'No Options'}
                                        type="small"
                                    />
                                </Div>
                            )
                        )}
                    </Popover>
                    {/* **second popover is for more button** */}
                    <Popover
                        customClass={
                            'AutoCompleteRootPopover ' +
                            (popoverCustomClass ? popoverCustomClass : '')
                        }
                        open={popoverMoreOpen}
                        anchorEl={inputReference !== undefined ? inputReference : inputRef}
                        onClose={() => {
                            setPopoverMoreOpen(false);
                        }}
                        width={customWidth || popoverWidth}
                        placement={placement || 'bottom-start'}
                        height={height || 250}
                        withOutPadding
                    >
                        <List
                            className={'ACRResults'}
                            height={height || 250}
                            itemCount={moreDataValue.current?.length}
                            itemSize={itemHeight || 35}
                            itemData={{
                                func: { singleSelection, handlePopoverMoreClose },
                                data: moreDataValue.current,
                            }}
                        >
                            {Row}
                        </List>
                    </Popover>
                </Div>
            )}
        </>
    );
};

AutoComplete.propTypes = {
    generateRows: PropTypes.func.isRequired,
    searchAPI: PropTypes.func,
    isAPICall: PropTypes.bool.isRequired,
    readOnly: PropTypes.bool,
    isSearch: PropTypes.bool,
    searchKey: PropTypes.string,
    searchValue: PropTypes.string,
    showNoResults: PropTypes.bool,
    enteredValue: PropTypes.func,
    singleSelection: PropTypes.bool,
    hasNextPage: PropTypes.bool,
    loading: PropTypes.bool,
    getMoreOptions: PropTypes.func,
    value: PropTypes.any,
    itemHeight: PropTypes.number,
    height: PropTypes.number,
    placeholder: PropTypes.string,
    parentRef: PropTypes.any,
    matchSearchbarWidth: PropTypes.bool,
    searchbarExpandBreakpoint: PropTypes.bool,
    autoFocus: PropTypes.bool,
};

Row.propTypes = {
    style: PropTypes.shape({
        height: PropTypes.number,
        left: PropTypes.number,
        position: PropTypes.string,
        right: PropTypes.number,
        width: PropTypes.string,
    }).isRequired,
    data: PropTypes.arrayOf(PropTypes.object).isRequired,
    index: PropTypes.number.isRequired,
};

export default AutoComplete;
