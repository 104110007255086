import React from 'react';

import { Div } from '../index';
import PropTypes from 'prop-types';
import './style.scss';

const SwitchGroup = ({ customClass, types, selected, handleSelect }) => {
    return (
        <Div className={`SwitchMain SwitchGroup ${customClass ? customClass : ''}`}>
            <Div className={'SwitchMainInner'}>
                {types?.map((item, index) => (
                    <Div
                        key={index}
                        className={'SMIBtn ' + (item.name === selected.name ? 'selected' : '')}
                        onClick={() => {
                            handleSelect(item);
                        }}
                    >
                        {item.label}
                    </Div>
                ))}
            </Div>
        </Div>
    );
};

SwitchGroup.propTypes = {
    customClass: PropTypes.string,
    types: PropTypes.arrayOf(PropTypes.object),
    selected: PropTypes.object,
    handleSelect: PropTypes.func,
};
export default SwitchGroup;

// <SwitchGroup types={[]} handleSelect={onClick} selected={{}} />;
