import React from 'react';

import { SVGIcon } from '../../UIKit/index';

export default function DCCardBg(props) {
    return (
        <SVGIcon {...props} viewBox="0 0 441.43 238.309">
            <defs>
                <linearGradient
                    id="DCCardBgFilter"
                    x1="0.5"
                    x2="0.5"
                    y2="1"
                    gradientUnits="objectBoundingBox"
                >
                    <stop offset="0" stopColor="#c340cb" />
                    <stop offset="1" stopColor="#ff3b66" />
                </linearGradient>
            </defs>
            <g transform="translate(0.526)">
                <rect
                    width="440.83"
                    height="238.146"
                    rx="10"
                    transform="translate(0.074)"
                    fill="url(#DCCardBgFilter)"
                />
                <path
                    d="M95.421,0a118.983,118.983,0,0,1,0,237.967c-1.428,0-4.474-.408-4.474-.408L91.565,0Z"
                    transform="translate(-91.473 0.179)"
                    fill="#fff"
                    opacity="0.1"
                />
                <path
                    d="M94.736,0C150.388,0,195.5,48.239,195.5,107.744S150.388,215.489,94.736,215.489c-1.21,0-3.789-.369-3.789-.369L91.471,0Z"
                    transform="translate(-91.343 11.418)"
                    fill="#fff"
                    opacity="0.05"
                />
                <circle
                    cx="55.297"
                    cy="55.297"
                    r="55.297"
                    transform="translate(330.309 127.714)"
                    fill="#fff"
                    opacity="0.06"
                />
                <circle
                    cx="55.297"
                    cy="55.297"
                    r="55.297"
                    transform="translate(276.722 122.013)"
                    fill="#fff"
                    opacity="0.06"
                />
            </g>
        </SVGIcon>
    );
}
