import { CallAPI } from 'Utils/apiCall';
import _ from 'lodash';
import {
    getCurrentDomain,
    getAccountDetails,
    getUserDetails,
    getUserConfigs,
    setUserConfigs,
} from 'Utils/localStorageUtils';
import config from 'config';

const mainUrl = `${config.REACT_APP_API_BASE_URL}/${process.env.REACT_APP_API_VERSION}`;

const api = new CallAPI();

// General
export const getProfile = async () => {
    const url = `${mainUrl}/${process.env.REACT_APP_API_USERS}/${process.env.REACT_APP_API_ME}`;
    return api.request('GET', url);
};

export const updateProfile = async (id, body) => {
    const url = `${mainUrl}/${process.env.REACT_APP_API_USERS}/${id}`;
    return api.request('PUT', url, body);
};

export const getAccount = async id => {
    const url = `${mainUrl}/${process.env.REACT_APP_API_ACCOUNTS}/${id}`;
    return api.request('GET', url);
};

export const updateAccount = async (id, body) => {
    const url = `${mainUrl}/${process.env.REACT_APP_API_ACCOUNTS}/${id}`;
    return api.request('PUT', url, body);
};

export const getSubAccountRequest = async (page, limit) => {
    const url = `${mainUrl}/${process.env.REACT_APP_API_ACCOUNTS}/public-account-requests?page=${page}&limit=${limit}`;
    return api.request('GET', url);
};

export const updateSubAccountRequestStaus = async (id, body) => {
    const url = `${mainUrl}/${process.env.REACT_APP_API_ACCOUNTS}/public-account-requests/${id}/status`;
    return api.request('PUT', url, body);
};

export const resendPublicVerificationEmail = async id => {
    let body = {};
    return api.request(
        'POST',
        `${mainUrl}/${process.env.REACT_APP_API_ACCOUNTS}/resend-public-verification-email/${id}`,
        body,
    );
};

export const clearSubscriptionNotification = async () => {
    const url = `${mainUrl}/${process.env.REACT_APP_API_STORE}/${process.env.REACT_APP_API_SUBSCRIPTIONS}/notification/stop`;
    return api.request('PUT', url);
};

export const getAccounts = async () => {
    const url = `${mainUrl}/${process.env.REACT_APP_API_ACCOUNTS}?v=2`;
    return api.request('GET', url);
};

export const updateDomain = async body => {
    const url = `${mainUrl}/${process.env.REACT_APP_API_ACCOUNTS}/${process.env.REACT_APP_API_DOMAIN}`;
    return api.request('PUT', url, body);
};

export const deleteCustomDomain = async () => {
    const url = `${mainUrl}/${process.env.REACT_APP_API_ACCOUNTS}/${process.env.REACT_APP_API_DOMAIN}`;
    return api.request('DELETE', url);
};

export const deletePendingCustomDomain = async () => {
    const url = `${mainUrl}/${process.env.REACT_APP_API_ACCOUNTS}/${process.env.REACT_APP_API_DOMAIN}/pending`;
    return api.request('DELETE', url);
};

// Users
export const inviteUser = async (body, query) => {
    const url = `${mainUrl}/${process.env.REACT_APP_API_USERS}`;
    return api.request('POST', url, body, null, null, query);
};

export const editUser = async (id, body) => {
    const url = `${mainUrl}/${process.env.REACT_APP_API_USERS}/${id}`;
    return api.request('PUT', url, body);
};

export const archiveUser = async (id, query, body) => {
    const url = `${mainUrl}/${process.env.REACT_APP_API_USERS}/${id}`;
    return api.request('DELETE', url, body, null, null, query);
};

export const getArchiveUser = async id => {
    const url = `${mainUrl}/${process.env.REACT_APP_API_USERS}/${id}/associated`;
    return api.request('GET', url);
};

// UnArchive
export const unArchive = async (id, body) => {
    const url = `${mainUrl}/${process.env.REACT_APP_API_USERS}/${id}`;
    return api.request('PUT', url, body);
};

// Subscriptions
export const getSubscriptions = async params => {
    const url = `${mainUrl}/store/subscriptions`;
    return api.request('GET', url, null, null, null, params);
};

export const getSubscription = async query => {
    let url = `${mainUrl}/${process.env.REACT_APP_API_STORE}/${process.env.REACT_APP_API_PRODUCTS}?type=software`;
    if (query) url = `${url}&${query}`;
    return api.request('GET', url);
};

// Purchase History
export const getInvoices = async (page, limit, params) => {
    const url = `${mainUrl}/store/invoices`;
    params.billing = false;
    return api.request('GET', url, null, limit, page, params);
};

// Phone number

// Apps
export const disconnectSEOAccount = async (id, global) => {
    let url = `${mainUrl}/${process.env.REACT_APP_API_INTEGRATIONS}/${process.env.REACT_APP_API_SEO}/${process.env.REACT_APP_API_RAPID}/${process.env.REACT_APP_API_CONFIG}/${id}`;
    if (global) {
        url += '?global=true';
    }
    return api.request('DELETE', url);
};

export const disconnectGoogleAdsAccount = async (global, id) => {
    let url = `${mainUrl}/${process.env.REACT_APP_API_INTEGRATIONS}/google/ads/auth`;
    if (global) {
        url += '?global=true';
    }
    if (id) url += `?account=${id}`;
    return api.request('DELETE', url);
};

export const disconnectFacebookAccount = async (global, id) => {
    let url = `${mainUrl}/${process.env.REACT_APP_API_INTEGRATIONS}/facebook/auth`;
    if (global) {
        url += '?global=true';
    }
    if (id) url += `?subaccountid=${id}`;
    return api.request('DELETE', url);
};

export const disconnectTiktokAccount = async (global, id) => {
    let url = `${mainUrl}/${process.env.REACT_APP_API_INTEGRATIONS}/tiktok/auth`;
    if (global) {
        url += '?global=true';
    }
    if (id) url += `?subaccountid=${id}`;
    return api.request('DELETE', url);
};

export const disconnectCallRailAccount = async (global, id) => {
    let url = `${mainUrl}/${process.env.REACT_APP_API_INTEGRATIONS}/${process.env.REACT_APP_API_CALLRAIL}/auth`;
    if (global) {
        url += '?global=true';
    }
    if (id) url += `?subaccountid=${id}`;
    return api.request('DELETE', url);
};

export const disconnectGoogleAnalyticsAccount = async (global, id) => {
    let url = `${mainUrl}/${process.env.REACT_APP_API_INTEGRATIONS}/google/${process.env.REACT_APP_API_ANALYTICS}/auth`;
    if (global) {
        url += '?global=true';
    }
    if (id) url += `?subaccountid=${id}`;
    return api.request('DELETE', url);
};

export const disconnectCallTrackingAnalyticsAccount = async (global, id) => {
    let url = `${mainUrl}/${process.env.REACT_APP_API_INTEGRATIONS}/calltrackingmetrics/auth`;
    if (global) {
        url += '?global=true';
    }
    if (id) url += `?subaccountid=${id}`;
    return api.request('DELETE', url);
};

export const disconnectProjectSEOAccount = async id => {
    let url = `${mainUrl}/${process.env.REACT_APP_API_INTEGRATIONS}/${process.env.REACT_APP_API_SEO}/${process.env.REACT_APP_API_RAPID}/${process.env.REACT_APP_API_CONFIG}`;

    if (id) url += `?account=${id}`;
    return api.request('DELETE', url);
};

export const saveSetting = async data => {
    let url = `${mainUrl}/${process.env.REACT_APP_API_USERS}/${process.env.REACT_APP_API_ME}/${process.env.REACT_APP_API_CONFIG}`;
    const body = { ...data };
    const res = await api.request('PUT', url, body, null, null, null);
    if (res.success) {
        const userConfig = getUserConfigs();
        const newUserConfig = _.cloneDeep(userConfig);

        const ind = userConfig.configurations.findIndex(_config => _config.type === res.data.type);
        if (ind >= 0) newUserConfig.configurations[ind] = res.data;

        const additionalDataIndex = userConfig.configurations.findIndex(
            _config => _config.type === res.additional_data?.type,
        );
        if (additionalDataIndex >= 0)
            newUserConfig.configurations[additionalDataIndex] = res.additional_data;
        setUserConfigs(newUserConfig);
    }
    return res;
};

export const getSettings = async () => {
    let url = `${mainUrl}/${process.env.REACT_APP_API_USERS}/${process.env.REACT_APP_API_ME}/${process.env.REACT_APP_API_CONFIG}/all`;
    return api.request('GET', url);
};
export const getAppSettings = async () => {
    let url = `${mainUrl}/settings`;
    return api.request('GET', url, null, null, null);
};

export const activatePayments = async () => {
    let url = `${mainUrl}/${process.env.REACT_APP_API_STORE}/${process.env.REACT_APP_API_ACCOUNTS}`;
    return api.request('POST', url, {
        business_type: 'individual',
        return_uri: getCurrentDomain() + '/settings/general?tab=payments',
    });
};

export const searchPhoneNumber = async query => {
    let url =
        `${mainUrl}/${process.env.REACT_APP_API_INTEGRATIONS}/${process.env.REACT_APP_API_TWILIO}/numbers/search` +
        '?' +
        query;
    return api.request('GET', url, null, null, null);
};

export const validatePhoneNumber = async body => {
    const url = `${mainUrl}/${process.env.REACT_APP_API_INTEGRATIONS}/${process.env.REACT_APP_API_TWILIO}/address`;
    return api.request('POST', url, body);
};

export const getTwilioAddresses = async () => {
    const url = `${mainUrl}/${process.env.REACT_APP_API_INTEGRATIONS}/${process.env.REACT_APP_API_TWILIO}/address`;
    return api.request('GET', url);
};

export const updatePayments = async () => {
    return api.request(
        'GET',
        `${mainUrl}/store/accounts/${
            getAccountDetails()?.id
        }/link?return_uri=settings/general?tab=payments`,
    );
};

export const addNewBank = async tokenId => {
    return api.request(
        'POST',
        `${mainUrl}/store/accounts/${getAccountDetails()?.id}/external_accounts`,
        { external_account: tokenId },
    );
};

export const editBank = (bankAccId, body) => {
    return api.request(
        'PUT',
        `${mainUrl}/store/accounts/${getAccountDetails()?.id}/external_accounts/${bankAccId}`,
        body,
    );
};

export const deleteBank = bankAccId => {
    return api.request(
        'DELETE',
        `${mainUrl}/store/accounts/${getAccountDetails()?.id}/external_accounts/${bankAccId}`,
    );
};

export const getBankAccounts = async () => {
    return api.request('GET', `${mainUrl}/store/accounts/${getAccountDetails()?.id}`);
};

export const getCreditBalance = async () => {
    return api.request('GET', `${mainUrl}/accounts/balance`);
};

// Branding image remove api

export const updateBrandingImages = async body => {
    return api.request('PUT', `${mainUrl}/accounts/${getAccountDetails()?.id}/branding`, body);
};

// Contact disconnect Integration api
export const disconnectContactsIntegration = async integrationName => {
    const url = `${mainUrl}/${process.env.REACT_APP_API_INTEGRATIONS}/${integrationName}/auth`;
    return api.request('DELETE', url);
};

// get All connected contact integration

export const getContactIntegration = async () => {
    const url = `${mainUrl}/${process.env.REACT_APP_API_CRM}/${
        process.env.REACT_APP_API_CONTACTS
    }/connected?owner=${getUserDetails()?.id}`;
    return api.request('GET', url);
};

export const getUserPaymentDetails = async () => {
    const url = `${mainUrl}/store/customer`;
    return api.request('GET', url);
};

export const setDefaultPaymentDetails = async cardId => {
    const url = `${mainUrl}/store/payment-methods/updatedefaultsource`;
    return api.request('POST', url, {
        card_id: cardId,
    });
};

export const setDefaultPhoneNumber = async phoneId => {
    const url = `${mainUrl}/e/twilio/numbers/${phoneId}/default`;
    return api.request('PUT', url);
};

export const validateEmailDomain = id => {
    const url = `${mainUrl}/e/sendgrid/subusers/domains/${id}/validate`;
    return api.request('POST', url);
};

export const deleteEmailDomain = id => {
    const url = `${mainUrl}/e/sendgrid/subusers/domains/${id}`;
    return api.request('DELETE', url);
};

export const addEmailDomain = body => {
    const url = `${mainUrl}/e/sendgrid/subusers/domains`;
    return api.request('POST', url, body);
};

export const addCustomEmail = body => {
    const url = `${mainUrl}/users/email`;
    return api.request('PUT', url, body);
};

export const deleteCustomEmail = body => {
    const url = `${mainUrl}/users/email`;
    return api.request('DELETE', url, body);
};
