import React from 'react';

import { SVGIcon } from '../../UIKit/index';

export default function DCInsights(props) {
    return (
        <SVGIcon {...props} viewBox="0 0 500.76 441">
            <g id="Insights" transform="translate(-5.1 -31.25)">
                <ellipse
                    id="_Path_"
                    data-name="&lt;Path&gt;"
                    cx="250.38"
                    cy="24.58"
                    rx="250.38"
                    ry="24.58"
                    transform="translate(5.1 423.09)"
                    fill="#f4f5f6"
                />
                <path
                    id="Path_1"
                    data-name="Path 1"
                    d="M143.19,435.61s.72,11.65,3,11.82h11.44l14.22-.08s-.1-3.43-4.25-4.15-11.12-3.65-13.63-9.14C151.3,428.3,143.19,435.61,143.19,435.61Z"
                    fill="#444445"
                />
                <path
                    id="Path_2"
                    data-name="Path 2"
                    d="M138.07,299.58s6.19,1.59,10.13.4,10.71.77,11.8,2.2.7,2-1.25,2.5-8.85,3.44-13.38,1.74a36.381,36.381,0,0,0-10.45-1.77Z"
                    fill="#e4e3e3"
                />
                <path
                    id="Path_3"
                    data-name="Path 3"
                    d="M84.54,245.77s7,49.46,18.48,53.89c5.68,2.18,30.81,7.14,38.24,7.63,0,0,1.82-1.47,1.38-7.48,0,0-26.33-9.67-30.07-14.92-5.56-7.83,3.46-32-3.53-39.3S90.3,235.08,84.54,245.77Z"
                    fill="#565656"
                />
                <path
                    id="Path_4"
                    data-name="Path 4"
                    d="M85.43,436.32V425.54h-17a.83.83,0,0,1-.83-.83V423a.83.83,0,0,1,.83-.83h17v-61.4a3.14,3.14,0,0,1,3.13-3.13h3.36a3.14,3.14,0,0,1,3.13,3.13v61.38h17a.84.84,0,0,1,.84.83v1.73a.84.84,0,0,1-.84.83h-17v10.78c12.28.93,22.12,5.35,24.88,11.07H60.54C63.3,441.67,73.14,437.25,85.43,436.32Z"
                    fill="#e4e3e3"
                />
                <path
                    id="Path_5"
                    data-name="Path 5"
                    d="M86.88,350a86.591,86.591,0,0,0,6.8,9.73c7.48,9.44,15.62,17.22,17,24.58A33.448,33.448,0,0,0,112,389c.59,1.69,1.33,3.46,2.17,5.29a162.608,162.608,0,0,0,12.66,21.64c8.19,12.14,15.89,22.26,15.89,22.26l14.13-.6c-.57-1.41-6.73-18.17-15-38.65l-10.34-25.69c-.46-1.14-.92-2.27-1.37-3.4-1-2.61-2.08-5.18-3.09-7.68l-13.9-34.54s-30.93-11.72-34.14-4.12C76.42,329.75,78.58,335.88,86.88,350Z"
                    fill="#565656"
                />
                <path
                    id="Path_6"
                    data-name="Path 6"
                    d="M110.48,402.31s-9.82,6.31-8.87,8.36c.13.27,5.56,10,5.56,10l7,12.37s3-1.75,1.55-5.73-2.23-11.48,1.34-16.36C120.81,405.82,110.48,402.31,110.48,402.31Z"
                    fill="#444445"
                />
                <path
                    id="Path_7"
                    data-name="Path 7"
                    d="M123.78,322.05s38.82,24.18,43.15,40.43-51.64,50.92-51.64,50.92-6.14-5.41-7.22-9.39c0,0,25.3-30.31,30.79-37.11,3.37-4.17-49.84-14.44-56.34-24.55s-4.18-18.62-4.18-18.62Z"
                    fill="#707272"
                />
                <rect
                    id="Rectangle_1"
                    data-name="Rectangle 1"
                    width="85.23"
                    height="21.67"
                    rx="2.89"
                    transform="translate(47.62 353.1)"
                    fill="#444445"
                />
                <path
                    id="Path_8"
                    data-name="Path 8"
                    d="M105.83,237.3a21.88,21.88,0,0,1,5.91,5.36c-9.3,2.4-24.72-6.22-24.72-6.22a22.81,22.81,0,0,0,8.21-12.86c.11-.44.21-.9.3-1.37a27.78,27.78,0,0,0,.5-4.84l4.28,3.83,7.1,6.36a60.6,60.6,0,0,1-1,7.19C106.21,235.54,106,236.39,105.83,237.3Z"
                    fill="#e4e3e3"
                />
                <path
                    id="Path_9"
                    data-name="Path 9"
                    d="M77.22,281.17c1.33,17.92-.91,45.16.6,49.88,1.3,4.07,16.65,1.66,22.58.3,2.2-.5,4.86-1.22,7.61-2.07,7.66-2.37,16-5.76,16.9-8.27a1.15,1.15,0,0,0,.08-.29,2.23,2.23,0,0,0,0-1.06c-.3-1.28-1.45-2.66-1.59-5.84-.15-3.54-.13-7.71-.26-12.2a11.563,11.563,0,0,0-.07-1.56v-.47c-.13-5-.39-10.27-.69-15.49-.19-3.27-.42-6.52-.67-9.63-.67-8-1.57-15.16-2.76-19.67a31.963,31.963,0,0,0-10.15-15.67L106,236.7a16.33,16.33,0,0,1-10.26-1.37c-3.65-1.73-4.91-3-5.55-2.5C81.71,239.33,75.64,259.27,77.22,281.17Z"
                    fill="#707272"
                />
                <path
                    id="Path_10"
                    data-name="Path 10"
                    d="M108,329.32c7.66-2.37,16-5.76,16.91-8.28a2.452,2.452,0,0,0,.08-.29V319.7c-.29-1.28-1.45-2.67-1.58-5.84-.15-3.55-.13-7.72-.26-12.21a11.863,11.863,0,0,0-.07-1.55v-.48L94.07,286.5S92.4,309.82,108,329.32Z"
                    fill="#565656"
                />
                <path
                    id="Path_11"
                    data-name="Path 11"
                    d="M107.41,227.56a60.6,60.6,0,0,1-1,7.19c-6.94-2.24-10.08-8.51-11.13-11.17-.3-.75-.43-1.21-.43-1.21l.73-.16,4.78-1Z"
                    fill="#707272"
                    opacity="0.3"
                />
                <path
                    id="Path_12"
                    data-name="Path 12"
                    d="M110.22,227.49S95,229.21,94.05,219.71s-3.51-15.69,6.18-17.19,11.94,1.94,13,5S114.16,226.8,110.22,227.49Z"
                    fill="#e4e3e3"
                />
                <path
                    id="Path_13"
                    data-name="Path 13"
                    d="M94.12,220.36s1-12.62,14.84-13.93c9.5-.89,4.2-9.07,3.19-10.37,0,0-7.28,1-14.74,2.23C81.74,200.87,92.58,218.4,94.12,220.36Z"
                    fill="#707272"
                />
                <rect
                    id="Rectangle_2"
                    data-name="Rectangle 2"
                    width="1.4"
                    height="36.73"
                    transform="translate(169.233 312.401) rotate(-162)"
                    fill="#565656"
                />
                <rect
                    id="Rectangle_3"
                    data-name="Rectangle 3"
                    width="38.55"
                    height="2.31"
                    transform="translate(169.213 312.418) rotate(-178.78)"
                    fill="#565656"
                />
                <path
                    id="Path_14"
                    data-name="Path 14"
                    d="M134.88,307.18s6,2.27,10,1.52,10.56,1.94,11.49,3.49.46,2.07-1.53,2.34-9.17,2.45-13.49.25a36.129,36.129,0,0,0-10.19-2.9Z"
                    fill="#e4e3e3"
                />
                <path
                    id="Path_15"
                    data-name="Path 15"
                    d="M87.61,247.8s1.46,49.92,12.42,55.6c5.41,2.79,29.84,10.49,37.17,11.8,0,0,2-1.26,2.2-7.29,0,0-25.1-12.51-28.24-18.14-4.67-8.39,7-31.38.83-39.45S94.51,237.81,87.61,247.8Z"
                    fill="#707272"
                />
                <path
                    id="Path_16"
                    data-name="Path 16"
                    d="M108,329.32s-16,5.35-30,2.12l-.18-.39s15.13,3.08,29.76-2.22Z"
                    fill="#565656"
                />
                <rect
                    id="Rectangle_4"
                    data-name="Rectangle 4"
                    width="249.13"
                    height="322.17"
                    rx="77.73"
                    transform="translate(451.31 367.03) rotate(180)"
                    fill="#f4f5f6"
                />
                <rect
                    id="Rectangle_5"
                    data-name="Rectangle 5"
                    width="200.58"
                    height="255.8"
                    transform="translate(427.24 334.42) rotate(180)"
                    fill="#e4e3e3"
                />
                <path
                    id="Path_17"
                    data-name="Path 17"
                    d="M398.79,323.19h0a10.58,10.58,0,0,1-10.58-10.58V166.29a10.57,10.57,0,0,1,10.58-10.58h0a10.58,10.58,0,0,1,10.58,10.58V312.61a10.58,10.58,0,0,1-10.58,10.58Z"
                    fill="#707272"
                />
                <path
                    id="Path_18"
                    data-name="Path 18"
                    d="M364.11,323.19h0a10.58,10.58,0,0,1-10.58-10.58v-193A10.58,10.58,0,0,1,364.11,109h0a10.59,10.59,0,0,1,10.59,10.6v193A10.59,10.59,0,0,1,364.11,323.19Z"
                    fill="#707272"
                />
                <path
                    id="Path_19"
                    data-name="Path 19"
                    d="M326.28,323.19h0a10.59,10.59,0,0,1-10.59-10.58V206.79a10.59,10.59,0,0,1,10.59-10.58h0a10.58,10.58,0,0,1,10.58,10.58V312.61A10.58,10.58,0,0,1,326.28,323.19Z"
                    fill="#707272"
                />
                <path
                    id="Path_20"
                    data-name="Path 20"
                    d="M289.46,323.19h0a10.58,10.58,0,0,1-10.58-10.58V148.2a10.58,10.58,0,0,1,10.58-10.59h0A10.59,10.59,0,0,1,300,148.2V312.61a10.58,10.58,0,0,1-10.54,10.58Z"
                    fill="#707272"
                />
                <path
                    id="Path_21"
                    data-name="Path 21"
                    d="M263,231.64v81a10.58,10.58,0,0,1-10.58,10.58h0a10.58,10.58,0,0,1-10.58-10.58v-81a10.58,10.58,0,0,1,10.58-10.58h0A10.58,10.58,0,0,1,263,231.64Z"
                    fill="#707272"
                />
                <g id="Group_1" data-name="Group 1" opacity="0.3">
                    <path
                        id="Path_22"
                        data-name="Path 22"
                        d="M279.88,124.51H297.8a1.85,1.85,0,1,1,0,3.7H279.88a1.85,1.85,0,1,1,0-3.7Z"
                        fill="#707272"
                    />
                    <path
                        id="Path_23"
                        data-name="Path 23"
                        d="M293.72,131.25h4.08a1.86,1.86,0,1,1,0,3.71h-4.08a1.86,1.86,0,0,1,0-3.71Z"
                        fill="#707272"
                    />
                    <path
                        id="Path_24"
                        data-name="Path 24"
                        d="M353.74,96.05h17.93a1.85,1.85,0,0,1,0,3.7H353.74a1.85,1.85,0,1,1,0-3.7Z"
                        fill="#707272"
                    />
                    <path
                        id="Path_25"
                        data-name="Path 25"
                        d="M367.59,102.79h4.08a1.86,1.86,0,0,1,1.85,1.86h0a1.85,1.85,0,0,1-1.85,1.85h-4.08a1.86,1.86,0,0,1-1.86-1.85h0A1.87,1.87,0,0,1,367.59,102.79Z"
                        fill="#707272"
                    />
                    <path
                        id="Path_26"
                        data-name="Path 26"
                        d="M389.59,142.7h17.93a1.86,1.86,0,0,1,0,3.71H389.59a1.86,1.86,0,0,1,0-3.71Z"
                        fill="#707272"
                    />
                    <path
                        id="Path_27"
                        data-name="Path 27"
                        d="M403.43,149.45h4.09a1.85,1.85,0,0,1,1.85,1.85h0a1.86,1.86,0,0,1-1.85,1.86h-4.09a1.87,1.87,0,0,1-1.85-1.86h0a1.86,1.86,0,0,1,1.85-1.85Z"
                        fill="#707272"
                    />
                    <path
                        id="Path_28"
                        data-name="Path 28"
                        d="M317.31,183.22h17.93a1.86,1.86,0,0,1,0,3.71H317.31a1.86,1.86,0,0,1,0-3.71Z"
                        fill="#707272"
                    />
                    <path
                        id="Path_29"
                        data-name="Path 29"
                        d="M331.16,190h4.08a1.85,1.85,0,0,1,1.85,1.85h0a1.86,1.86,0,0,1-1.85,1.86h-4.08a1.87,1.87,0,0,1-1.86-1.86h0a1.86,1.86,0,0,1,1.86-1.85Z"
                        fill="#707272"
                    />
                    <path
                        id="Path_30"
                        data-name="Path 30"
                        d="M243.49,208h17.92a1.86,1.86,0,1,1,0,3.71H243.49a1.86,1.86,0,1,1,0-3.71Z"
                        fill="#707272"
                    />
                    <path
                        id="Path_31"
                        data-name="Path 31"
                        d="M257.33,214.75h4.08a1.86,1.86,0,1,1,0,3.71h-4.08a1.86,1.86,0,0,1,0-3.71Z"
                        fill="#707272"
                    />
                </g>
                <path
                    id="Path_32"
                    data-name="Path 32"
                    d="M252.45,202.72a6.59,6.59,0,1,1,6.59-6.58,6.59,6.59,0,0,1-6.59,6.58Zm0-10.62a4,4,0,1,0,4,4A4,4,0,0,0,252.45,192.1Z"
                    fill="#707272"
                />
                <path
                    id="Path_33"
                    data-name="Path 33"
                    d="M249.65,192.1a1.28,1.28,0,0,1-.91-.37L229,171.93H68.14a1.28,1.28,0,1,1,0-2.55H230l20.54,20.54a1.3,1.3,0,0,1,0,1.81A1.27,1.27,0,0,1,249.65,192.1Z"
                    fill="#707272"
                />
                <path
                    id="Path_34"
                    data-name="Path 34"
                    d="M290.33,341.61a6.59,6.59,0,1,1,6.59-6.58A6.59,6.59,0,0,1,290.33,341.61Zm0-10.62a3.995,3.995,0,1,1,0,.01Z"
                    fill="#707272"
                />
                <path
                    id="Path_35"
                    data-name="Path 35"
                    d="M472.09,361.78H312.77l-20.54-20.54a1.273,1.273,0,1,1,1.8-1.8l19.8,19.79H472.09a1.28,1.28,0,1,1,0,2.55Z"
                    fill="#707272"
                />
                <path
                    id="Path_36"
                    data-name="Path 36"
                    d="M363.19,91a6.59,6.59,0,1,1,6.59-6.59A6.59,6.59,0,0,1,363.19,91Zm0-10.63a1.661,1.661,0,1,0,0-.05Z"
                    fill="#707272"
                />
                <path
                    id="Path_37"
                    data-name="Path 37"
                    d="M360.38,80.32a1.27,1.27,0,0,1-.9-.37L339.69,60.16H178.88a1.28,1.28,0,1,1,0-2.55H340.75l20.54,20.54a1.27,1.27,0,0,1-.91,2.17Z"
                    fill="#707272"
                />
                <path
                    id="Path_38"
                    data-name="Path 38"
                    d="M175.61,166.43H70a3.22,3.22,0,0,1-3.23-3.23h0A3.23,3.23,0,0,1,70,160H175.61a3.23,3.23,0,0,1,3.23,3.23h0A3.23,3.23,0,0,1,175.61,166.43Z"
                    fill="#e4e3e3"
                />
                <path
                    id="Path_39"
                    data-name="Path 39"
                    d="M175.61,156.23H110.76a3.23,3.23,0,0,1-3.23-3.23h0a3.23,3.23,0,0,1,3.23-3.22h64.85a3.23,3.23,0,0,1,3.23,3.22h0A3.23,3.23,0,0,1,175.61,156.23Z"
                    fill="#e4e3e3"
                />
                <path
                    id="Path_40"
                    data-name="Path 40"
                    d="M175.61,146H110.76a3.23,3.23,0,0,1-3.23-3.23h0a3.23,3.23,0,0,1,3.23-3.23h64.85a3.23,3.23,0,0,1,3.23,3.23h0A3.23,3.23,0,0,1,175.61,146Z"
                    fill="#e4e3e3"
                />
                <path
                    id="Path_41"
                    data-name="Path 41"
                    d="M285.61,54H180a3.23,3.23,0,0,1-3.23-3.23h0A3.22,3.22,0,0,1,180,47.54H285.61a3.22,3.22,0,0,1,3.23,3.23h0A3.23,3.23,0,0,1,285.61,54Z"
                    fill="#e4e3e3"
                />
                <circle
                    id="Ellipse_1"
                    data-name="Ellipse 1"
                    cx="22.99"
                    cy="22.99"
                    r="22.99"
                    transform="translate(333.777 406.137) rotate(-67.5)"
                    fill="#707272"
                />
                <path
                    id="Path_42"
                    data-name="Path 42"
                    d="M363.81,374.26a19.43,19.43,0,0,1,13.85,33.07L363.81,393.7l-18.16,6.93a19.46,19.46,0,0,1,18.16-26.37Z"
                    fill="#fff"
                />
                <path
                    id="Path_43"
                    data-name="Path 43"
                    d="M366.65,383.83h8.28a.59.59,0,0,1,.59.59h0a.59.59,0,0,1-.59.59h-8.28a.59.59,0,0,1-.59-.59h0A.59.59,0,0,1,366.65,383.83Z"
                    fill="#707272"
                />
                <path
                    id="Path_44"
                    data-name="Path 44"
                    d="M366.65,385.7h8.28a.6.6,0,0,1,.59.59.59.59,0,0,1-.59.59h-8.28a.59.59,0,0,1-.59-.59A.6.6,0,0,1,366.65,385.7Z"
                    fill="#707272"
                />
                <path
                    id="Path_45"
                    data-name="Path 45"
                    d="M371.51,387.57h3.42a.6.6,0,0,1,.59.59.59.59,0,0,1-.59.59h-3.42a.58.58,0,0,1-.59-.59.59.59,0,0,1,.59-.59Z"
                    fill="#707272"
                />
                <path
                    id="Path_46"
                    data-name="Path 46"
                    d="M343.68,382.9l9.09-9.09h-9.09Z"
                    fill="#707272"
                />
                <circle
                    id="Ellipse_2"
                    data-name="Ellipse 2"
                    cx="22.99"
                    cy="22.99"
                    r="22.99"
                    transform="matrix(0.05, -0.999, 0.999, 0.05, 417.388, 415.552)"
                    fill="#707272"
                />
                <path
                    id="Path_47"
                    data-name="Path 47"
                    d="M422,393.7a19.42,19.42,0,1,1,7.64,15.45l11.79-15.45-18.16,6.93A19.459,19.459,0,0,1,422,393.7Z"
                    fill="#fff"
                />
                <path
                    id="Path_48"
                    data-name="Path 48"
                    d="M444.45,383.83h8.27a.58.58,0,0,1,.59.59h0a.58.58,0,0,1-.59.59h-8.27a.59.59,0,0,1-.59-.59h0A.59.59,0,0,1,444.45,383.83Z"
                    fill="#707272"
                />
                <path
                    id="Path_49"
                    data-name="Path 49"
                    d="M444.45,385.7h8.27a.59.59,0,0,1,.59.59.58.58,0,0,1-.59.59h-8.27a.59.59,0,0,1-.59-.59A.6.6,0,0,1,444.45,385.7Z"
                    fill="#707272"
                />
                <path
                    id="Path_50"
                    data-name="Path 50"
                    d="M449.31,387.57h3.41a.59.59,0,0,1,.59.59.58.58,0,0,1-.59.59h-3.41a.58.58,0,0,1-.59-.59.59.59,0,0,1,.59-.59Z"
                    fill="#707272"
                />
                <path
                    id="Path_51"
                    data-name="Path 51"
                    d="M421.45,382.9l9.09-9.09h-9.09Z"
                    fill="#707272"
                />
                <circle
                    id="Ellipse_3"
                    data-name="Ellipse 3"
                    cx="9.26"
                    cy="9.26"
                    r="9.26"
                    transform="translate(47.62 78.41)"
                    fill="#fff"
                    stroke="#e2e2e2"
                    strokeMiterlimit="10"
                    strokeWidth="2"
                />
                <circle
                    id="Ellipse_4"
                    data-name="Ellipse 4"
                    cx="9.26"
                    cy="9.26"
                    r="9.26"
                    transform="translate(451.63 32.25)"
                    fill="#fff"
                    stroke="#e2e2e2"
                    strokeMiterlimit="10"
                    strokeWidth="2"
                />
                <circle
                    id="Ellipse_5"
                    data-name="Ellipse 5"
                    cx="5.36"
                    cy="5.36"
                    r="5.36"
                    transform="translate(87.53 97.43)"
                    fill="#fff"
                    stroke="#e2e2e2"
                    strokeMiterlimit="10"
                    strokeWidth="2"
                />
                <path
                    id="Path_52"
                    data-name="Path 52"
                    d="M31.12,405.67H24.51v-6.6a.82.82,0,0,0-.82-.82h0a.85.85,0,0,0-.84.82v6.6H16.27a.84.84,0,0,0-.83.84.82.82,0,0,0,.83.82h6.58v6.61a.85.85,0,0,0,.84.82.82.82,0,0,0,.82-.82h0v-6.61h6.61a.82.82,0,0,0,.83-.82.84.84,0,0,0-.83-.84Z"
                    fill="#e2e2e2"
                    stroke="#e2e2e2"
                    strokeMiterlimit="10"
                    strokeWidth="0.75"
                />
                <path
                    id="Path_53"
                    data-name="Path 53"
                    d="M501.35,285.66h-6.61v-6.59a.82.82,0,0,0-.82-.82h0a.83.83,0,0,0-.84.82v6.59h-6.59a.84.84,0,0,0,0,1.67h6.59v6.6a.83.83,0,0,0,.84.82.82.82,0,0,0,.82-.82h0v-6.6h6.61a.84.84,0,0,0,0-1.67Z"
                    fill="#e2e2e2"
                    stroke="#e2e2e2"
                    strokeMiterlimit="10"
                    strokeWidth="0.75"
                />
                <path
                    id="Path_54"
                    data-name="Path 54"
                    d="M483.69,330h-4.93v-4.92a.61.61,0,0,0-.61-.61h0a.63.63,0,0,0-.63.61V330h-4.91a.62.62,0,0,0-.62.62.63.63,0,0,0,.62.62h4.91v4.92a.63.63,0,0,0,.63.61.61.61,0,0,0,.61-.61h0v-4.92h4.93a.63.63,0,0,0,.62-.62A.62.62,0,0,0,483.69,330Z"
                    fill="#e2e2e2"
                    stroke="#e2e2e2"
                    strokeMiterlimit="10"
                    strokeWidth="0.75"
                />
            </g>
        </SVGIcon>
    );
}
