import React from 'react';

import { SVGIcon } from '../../UIKit/index';

export default function DCFillDownArray(props) {
    return (
        <SVGIcon {...props} viewBox="0 0 512 412">
            <polygon points="256,418.8 28.8,94.2 483.2,94.2 " />
        </SVGIcon>
    );
}
