import React from 'react';
import { Page, Text, View, Link, Image, Font } from '@react-pdf/renderer';
import { LIcon, LHalfCheck, LWarn } from './svgDownloadIcons/svgDownloadIcons';
import generateImage from 'Utils/generateImage';
import config from 'config';
import { styles } from './style';
import { instareportFontFamily } from '../components/constant';
import { getInitialsListingSvg } from './downloadUtils/colorBox';

Font.register({ ...instareportFontFamily });

const TotalListingDownload = ({ listings, user, details }) => {
    return (
        <Page wrap>
            <View fixed style={styles.FixedTopHeight}>
                <Text />
            </View>
            <View style={styles.LSBoxSection} wrap>
                {listings?.map((listing, i) => (
                    <View key={i} style={styles.LSBox} wrap={false}>
                        <View style={styles.LSBoxHeader}>
                            <Image
                                src={{
                                    uri: `${config.REACT_APP_API_BASE_URL}/v1/corsproxy`,
                                    method: 'GET',
                                    body: '',
                                    headers: { 'Target-URL': `${listing?.logoURL}` },
                                }}
                                cache={false}
                                // src={listing?.logoURL}
                                alt="image"
                                style={styles.ListingLogoIcon}
                            />
                            <Text style={styles.ListingTextFont}>{listing.siteName}</Text>
                            <Link src={listing.url} style={styles.LinkText} target={'_blank'}>
                                <LIcon />
                            </Link>
                        </View>
                        <View>
                            <View style={styles.LSFields}>
                                {listing?.name?.score < 0.9 ? (
                                    <LWarn color={'red'} />
                                ) : (
                                    <LHalfCheck color={'blue'} />
                                )}
                                <Text style={styles.LSFieldsText}>{listing?.name?.value}</Text>
                            </View>
                            <View style={styles.LSFields}>
                                {listing?.address?.score < 0.9 ? (
                                    <LWarn color={'red'} />
                                ) : (
                                    <LHalfCheck color={'blue'} />
                                )}
                                <Text style={styles.LSFieldsText}>
                                    {`${listing?.address?.value} ${listing?.city} ${listing?.state}
                                    ${listing?.zip}`}
                                </Text>
                            </View>
                            <View style={styles.LSFields}>
                                {listing?.phone?.score < 0.9 ? (
                                    <LWarn color={'red'} />
                                ) : (
                                    <LHalfCheck color={'blue'} />
                                )}
                                <Text style={styles.LSFieldsText}>{listing?.phone?.value}</Text>
                            </View>
                        </View>
                    </View>
                ))}
            </View>
            <View style={styles.GITSection}>
                {!(typeof user?.image === 'string') ? (
                    <>
                        {Object.keys(user?.image || {})?.length > 0 ? (
                            <Image
                                src={generateImage(user?.image, user?.name)}
                                style={styles.GITUserImage}
                                alt={'image'}
                            />
                        ) : (
                            <View style={styles.GITSvgImage}>
                                {getInitialsListingSvg(user?.name)}
                            </View>
                        )}
                    </>
                ) : (
                    <Image
                        src={{
                            uri: `${config.REACT_APP_API_BASE_URL}/v1/corsproxy/`,
                            method: 'GET',
                            body: '',
                            headers: { 'Target-URL': `${user?.image}` },
                        }}
                        cache={false}
                        style={styles.GITUserImage}
                        alt={'image'}
                    />
                )}
                <Text style={styles.GITContent}>
                    Would you like to be found in all these directory listings with accurate
                    business information and start generating more customers for {details?.name}? We
                    can help!
                </Text>
            </View>
            <View fixed style={styles.FixedBottomHeight}>
                <Text />
            </View>
        </Page>
    );
};

export default TotalListingDownload;
