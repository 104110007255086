import React from 'react';
import PropTypes from 'prop-types';
export default function Strong({ onClick, props, children, className, style }) {
    return (
        <strong {...props} style={style} onClick={onClick} className={className}>
            {children}
        </strong>
    );
}

Strong.propTypes = {
    onClick: PropTypes.func,
    children: PropTypes.element,
    className: PropTypes.string,
    style: PropTypes.object,
};
