import React from 'react';

import { SVGIcon } from '../../UIKit/index';

export default function DCMicFilled(props) {
    return (
        <SVGIcon {...props} viewBox="0 0 512 512">
            <path d="M421,255h-30c0,74.4-60.6,135-135,135s-135-60.6-135-135h-30c0,85.9,66,156.7,150,164.3v62.7h-105v30h240v-30h-105v-62.7c84-7.6,150-78.4,150-164.3Z" />
            <g>
                <path d="M361,105V255c0,5.1-.36,10.12-1.07,15.03-7.29,50.87-51.04,89.97-103.93,89.97s-96.64-39.1-103.93-89.97c-.71-4.91-1.07-9.93-1.07-15.03V105c0-5.09,.36-10.1,1.07-15C159.34,39.12,203.1,0,256,0c29,0,55.24,11.75,74.25,30.75,15.66,15.67,26.4,36.26,29.68,59.25,.71,4.9,1.07,9.91,1.07,15Z" />
                <path
                    d="M225.85,90v30.06h-74.85v-15.06c0-5.09,.36-10.1,1.07-15h73.78Z"
                    style={{ fill: '#fff' }}
                />
                <rect x="151" y="164.97" width="74.85" height="30.06" style={{ fill: '#fff' }} />
                <path
                    d="M225.85,239.97v30.06h-73.78c-.71-4.91-1.07-9.93-1.07-15.03v-15.03h74.85Z"
                    style={{ fill: '#fff' }}
                />
                <path
                    d="M361,105v15.06h-74.85v-30.06h73.78c.71,4.9,1.07,9.91,1.07,15Z"
                    style={{ fill: '#fff' }}
                />
                <rect x="286.15" y="164.97" width="74.85" height="30.06" style={{ fill: '#fff' }} />
                <path
                    d="M361,239.97v15.03c0,5.1-.36,10.12-1.07,15.03h-73.78v-30.06h74.85Z"
                    style={{ fill: '#fff' }}
                />
            </g>
        </SVGIcon>
    );
}
