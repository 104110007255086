import React from 'react';

import { SVGIcon } from '../../UIKit/index';

export default function DCRedo(props) {
    return (
        <SVGIcon {...props} viewBox="0 0 512 512">
            <path d="M255.68,2.76c62.09,0.38,122.14,22.23,169.95,61.85l57.2-57.2c6.2-6.21,16.27-6.21,22.48-0.01  c2.98,2.98,4.66,7.03,4.66,11.25v137.71c0,8.78-7.12,15.89-15.89,15.89H356.33c-8.78,0-15.89-7.11-15.9-15.89  c0-4.22,1.67-8.26,4.66-11.25l50.42-50.39c-39.7-31.8-88.97-49.27-139.83-49.59C138.65,45.08,43.73,139.9,43.67,256.93  s94.76,211.95,211.79,212.01c56.26,0.03,110.23-22.32,150-62.12c8.28-8.28,21.7-8.28,29.98,0c8.28,8.28,8.28,21.7,0,29.98l0,0  c-99.28,99.3-260.26,99.32-359.57,0.04s-99.32-260.26-0.04-359.57c47.7-47.71,112.4-74.5,179.87-74.49L255.68,2.76z" />
        </SVGIcon>
    );
}
