import React from 'react';

import { SVGIcon } from '../../UIKit/index';

export default function DCOverview(props) {
    return (
        <SVGIcon {...props} viewBox="0 0 460.3 512">
            <g transform="translate(-26.483)">
                <path
                    fill="#00D2FF"
                    d="M433.7,135.5l-176,146.6L92.6,123.6C4.9,214.8,7.9,359.8,99.1,447.4   c91.2,87.6,236.2,84.7,323.9-6.6C503.8,356.7,508.4,225.2,433.7,135.5L433.7,135.5z"
                />
                <path
                    fill="#A3F5DF"
                    d="M92.5,123.5l165.2,158.6V53.1C195.4,53,135.7,78.5,92.5,123.5z"
                />
                <path
                    fill="#C3E678"
                    d="M458.2,82.7L458.2,82.7C414.7,30.4,350.2,0.1,282.2,0.2v229.1L458.2,82.7z"
                />
                <path
                    fill="#18BDF6"
                    d="M310.6,476C184.1,476,81.5,373.5,81.4,246.9c0-37.7,9.3-74.8,27-108l-15.9-15.3   C4.6,214.5,7,359.5,97.9,447.4c86.3,83.5,222.3,86.1,311.8,6.2C378.8,468.4,344.9,476.1,310.6,476z"
                />
                <path
                    fill="#78D2BE"
                    d="M158.4,75.8c-24.6,11.9-46.9,28.1-65.9,47.8l16,15.4C121.3,115.1,138.2,93.7,158.4,75.8z"
                />
            </g>
        </SVGIcon>
    );
}
