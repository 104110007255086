import React from 'react';

import { SVGIcon } from '../../UIKit/index';

export default function DCNotificationBell(props) {
    return (
        <SVGIcon {...props} viewBox="0 0 512 512">
            <g>
                <path d="M319.38,433.54c0,0,3.95,61.62-63.38,71.35c0,0-20.41,0.76-26.03-2.27c0,0-49.95-12.54-51.89-68.11   l22.49-2.92l0.54,10.05c0,0,5.84,33.3,37.19,40c0,0,12.54,3.46,15.46-1.62c0,0,1.08-5.41-3.35-6.05c0,0-12.97,0.65-19.89-4.97   c0,0-17.19-7.68-21.08-29.08l-0.49-7.51L319.38,433.54z" />
                <path d="M46.65,434.08h131.43l22.49-2.49c0,0,5.95-4.43,8.38,0.81l110.43,1.14h128.43c0,0,30.81-2.92,31.46-34.7   c0,0-51.89-42.81-79.78-112.22c0,0-15.35-30.49-24.43-107.03l-3.89-51.46c0,0-4.76-71.78-97.73-82.16c0,0,4.11-8.65,2.27-15.46   c0,0-3.24-17.3-21.95-21.73h-9.19c0,0-16.97,2.92-21.51,21.51c0,0-0.97,9.51,1.95,15.24c0,0-60.65,2.38-89.51,53.95   c0,0-9.08,20.43-9.3,33.84l-1.08,12.86l-0.65,27.57c0,0-1.73,48-15.35,84.97c0,0-17.73,75.68-89.51,139.24   c0,0-2.16,27.14,27.46,36.11l23.14-35.46c0,0,57.95-67.24,70.49-123.46c0,0,24-74.81,19.46-128c0,0-2.27-66.16,83.03-67.14   L268.14,81c0,0,61.3,0.65,67.46,54.49l1.62,14.92c0,0-1.51,71.03,23.68,134.27c0,0,26.27,73.51,66.49,112.43l1.08,1.51   l-359.62,0.43L46.65,434.08z" />
            </g>
        </SVGIcon>
    );
}
