import React from 'react';

import { SVGIcon } from '../../UIKit/index';

export default function DCAtTheRate(props) {
    return (
        <SVGIcon {...props} viewBox="0 0 512 477">
            <g>
                <path d="M322.51,158.49c0,0-18.7-28.35-61.83-29.65c0,0-42.59-5.46-75.68,22.43c0,0-28.76,22.16-40.76,63.35   c0,0-15.89,43.78-3.24,95.35c0,0,6.16,44.76,50.59,73.3c0,0,32.11,18.49,69.41,6.81c0,0,33.88-10.79,44.34-35.18l-27.59-28.28   c0,0-12.54,10.59-28.11,11.73c0,0-24.65,6.16-41.84-20.92c0,0-7.68-8.11-11.35-36c0,0-1.73-36.11,6.7-54.49   c0,0,12.76-42.59,51.24-41.95c0,0,32.43-5.84,51.24,23.78c0,0,7.9,11.88,8,30.92c0,0,3.46,38.27-17.08,65.3c0,0-8,13.19-18.05,21.3   l-0.65,0.32l-0.11,10.82l12.11,22.49l24.65-2.59l13.84-176.43L322.51,158.49z" />
                <path d="M417.81,407.76L435.16,453v0.81c0,0-43.14,25.3-104.11,36.97c0,0-94.81,22.65-187.78-21.03   c0,0-62.92-24.22-105.73-113.08c0,0-43.46-84.32-5.19-187.46c0,0,38.27-103.78,158.27-143.35c0,0,100.54-34.81,196.11,17.51   c0,0,97.73,46.7,106.81,147.89c0,0,10.38,42.38-7.78,96.43c0,0-22.49,66.16-73.51,93.84c0,0-21.51,13.89-54.92,11.3   c0,0-37.41-3.08-52.32-34l17.62-201.62l2.59-22.38l58.7-0.32l-27.24,167.35c0,0-4.32,23.46,1.51,36c0,0,10.59,13.19,32.65,4.11   c0,0,49.95-22.7,57.3-104c0,0,6.49-61.84-28.11-104.65c0,0-39.35-64-136.22-73.08c0,0-75.24-10.38-141.41,43.68   c0,0-61.84,41.95-69.19,136.22c0,0-9.51,83.89,44.54,143.14c0,0,37.19,47.57,96.86,57.95c0,0,36.54,10.81,87.57,4.11   c0,0,41.51-4.46,78.81-20.38l17.19-7.46l18.81-9.41L417.81,407.76z" />
            </g>
        </SVGIcon>
    );
}
