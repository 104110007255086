import React from 'react';

import { SVGIcon } from '../../UIKit/index';

export default function DCHubspot(props) {
    return (
        <SVGIcon {...props} viewBox="0 0 2500 2500">
            <path
                fill="#FF7A59"
                d="M1877.3,828.3V534.7c79.2-37,130-116.4,130.4-203.8v-6.7c0-124.9-101.2-226.1-226.1-226.1h-6.7
	c-124.9,0-226.1,101.2-226.1,226.1v6.7c0.4,87.4,51.2,166.8,130.4,203.8v293.6c-111.5,17.1-216.5,63.3-304.4,134L569.5,335.1
	c5.7-20.7,8.7-42,9.1-63.4C578.8,131,465,16.7,324.3,16.5C183.6,16.2,69.4,130,69.1,270.7c-0.3,140.7,113.5,255,254.2,255.2
	c0.1,0,0.1,0,0.2,0c44.1-0.2,87.3-12,125.4-34.1l792.9,617c-145.8,220.2-141.9,507.2,9.8,723.4l-241.2,241.2
	c-19.5-6.2-39.8-9.5-60.3-9.8c-115.5,0.1-209.1,93.8-209,209.3c0,115.5,93.7,209.1,209.1,209.1c115.5,0,209.2-93.5,209.3-209
	c-0.3-20.5-3.6-40.8-9.8-60.3l238.6-238.7c213.2,164.1,505.9,178.2,733.9,35.4c228-142.8,343-412.4,288.4-675.8
	C2356,1070.2,2143.3,868.6,1877.3,828.3 M1778.4,1793.9c-182.2,4.9-333.9-138.8-338.8-321.1c-4.9-182.2,138.8-333.9,321.1-338.8
	c5.9-0.2,11.8-0.2,17.8,0c177.6,6.2,318.4,152,318.5,329.7c0.1,177.7-140.6,323.6-318.2,330"
            />
        </SVGIcon>
    );
}
