import React from 'react';

import { SVGIcon } from '../../UIKit/index';

export default function DCVoice(props) {
    return (
        <SVGIcon {...props} viewBox="0 0 512 512">
            <g>
                <path d="M273.65,469.11v-24c0,0-1.3-12.97-16.22-16.22h-5.41c0,0-12.76,1.08-14.54,14.22l0.49,26h-22.86   c0,0-11.84,3.03-13.46,15.03c0,0-3.08,15.84,15.57,20.97h77.19c0,0,13.95-3.78,16.22-16.43c0,0,2.27-16.7-15.24-20.27   L273.65,469.11z" />
                <path d="M152.68,103.38v144c0,0,8.43,84.65,100.22,91.14h14.92c0,0,74.27-7.78,89.84-88.86V103.38   c0,0-5.08-75.78-90.27-91.24H242.3c0,0-72.65,6.16-87.78,84.43L152.68,103.38l37.73,1.41c0,0,12.97-55.35,59.68-55.78h12.97   c0,0,51.03,4.76,57.95,57.51v139.24c0,0-7.35,44.54-51.03,54.92l-20.32,0.43c0,0-45.41,3.46-59.24-55.35V104.78L152.68,103.38z" />
                <path d="M116.46,233.22c0,0-15.8-35.89-37.09,0c0,0-3.99,81.3,67.79,137.51c0,0,29.84,33.3,102.92,38.92h15.14   c0,0,69.41,0.22,120-49.08c0,0,35.03-32,45.19-94.27l1.09-47.57c0,0-5.2-14.7-16.22-15.57c0,0-16.43-2.16-21.19,15.57l-0.43,44.54   c0,0-2.59,70.7-76.11,98.38c0,0-14.92,10.16-72.86,11.03c0,0-37.62,0-73.3-28.32C171.38,344.35,114.73,306.36,116.46,233.22z" />
            </g>
        </SVGIcon>
    );
}
