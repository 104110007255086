import React from 'react';

import { SVGIcon } from '../../UIKit/index';

export default function DCWatch1(props) {
    return (
        <SVGIcon {...props} viewBox="0 0 512 512">
            <g>
                <path d="M255.52,2.8C115.51,2.79,2,116.28,1.99,256.28S115.47,509.8,255.47,509.81     C395.48,509.82,508.99,396.33,509,256.33c0-0.01,0-0.01,0-0.02C508.84,116.37,395.45,2.97,255.52,2.8z M255.52,472.82     c-119.57-0.01-216.49-96.95-216.47-216.52c0.01-119.57,96.95-216.49,216.52-216.47c119.54,0.01,216.45,96.91,216.47,216.45     C471.93,375.82,375.06,472.7,255.52,472.82z" />
                <path d="M331.7,250.64h-80.19V139.61c0-10.22-8.28-18.5-18.5-18.5c-10.22,0-18.5,8.28-18.5,18.5     V269.2c0,10.21,8.28,18.49,18.49,18.49h98.68c10.22,0,18.5-8.28,18.5-18.5s-8.28-18.5-18.5-18.5l0,0L331.7,250.64z" />
            </g>
        </SVGIcon>
    );
}
