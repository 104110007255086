import React from 'react';

import { SVGIcon } from '../../UIKit/index';

export default function DCCup(props) {
    return (
        <SVGIcon {...props} viewBox="0 0 49.775 49.776">
            <g transform="translate(0 0.001)">
                <path
                    d="M369.677,0h-4.2l-2.984,2.917h7.188a1.459,1.459,0,0,1,1.458,1.458V6.6a13,13,0,0,1-8.885,12.327l-2.056.685a1.459,1.459,0,0,0,.461,2.842,1.5,1.5,0,0,0,.461-.074l2.056-.687A15.907,15.907,0,0,0,374.051,6.6V4.375A4.38,4.38,0,0,0,369.677,0Zm0,0"
                    transform="translate(-324.276)"
                    fill="#ff7816"
                />
                <path
                    d="M13.858,19.608,11.8,18.924A13,13,0,0,1,2.917,6.6V4.374A1.459,1.459,0,0,1,4.375,2.916h7.536L8.578,0h-4.2A4.38,4.38,0,0,0,0,4.374V6.6A15.911,15.911,0,0,0,10.879,21.7l2.056.685a1.5,1.5,0,0,0,.461.074,1.459,1.459,0,0,0,.461-2.842Zm0,0"
                    fill="#ff9f00"
                />
                <path
                    d="M108.985,28.466v2.158a1.444,1.444,0,0,0,1.458,1.458,4.369,4.369,0,0,1,4.375,4.375v2.917a1.444,1.444,0,0,1-1.458,1.458H95.666a1.444,1.444,0,0,1-1.458-1.458V36.458a4.369,4.369,0,0,1,4.375-4.375,1.444,1.444,0,0,0,1.458-1.458V28.466A14.538,14.538,0,0,1,90.008,16.275L88.2,0h32.626l-1.808,16.274a14.538,14.538,0,0,1-10.033,12.191Zm0,0"
                    transform="translate(-79.625 -0.001)"
                    fill="#ffd400"
                />
                <path
                    d="M266.382,40.831H256V0h19.143l-2.122,16.274c-.753,5.687-5.407,10.47-11.773,12.191v2.158a1.584,1.584,0,0,0,1.711,1.458c2.841,0,5.134,1.954,5.134,4.375v2.917a1.584,1.584,0,0,1-1.711,1.458Zm0,0"
                    transform="translate(-233.942)"
                    fill="#ff9f00"
                />
                <path
                    d="M195.555,65.7a1.483,1.483,0,0,0-1.2-1.02l-3.267-.467-1.458-2.888a1.546,1.546,0,0,0-2.625,0l-1.458,2.888-3.267.466a1.452,1.452,0,0,0-.787,2.508l2.333,2.246-.554,3.15a1.479,1.479,0,0,0,.583,1.429,1.431,1.431,0,0,0,1.516.117l2.946-1.517,2.946,1.517a1.427,1.427,0,0,0,1.517-.117,1.48,1.48,0,0,0,.583-1.429l-.554-3.15,2.333-2.246a1.491,1.491,0,0,0,.408-1.488Zm0,0"
                    transform="translate(-163.434 -54.707)"
                    fill="#ff9f00"
                />
                <path
                    d="M146.443,391.458v8.827H120v-8.827A1.444,1.444,0,0,1,121.458,390h23.527a1.444,1.444,0,0,1,1.458,1.458Zm0,0"
                    transform="translate(-108.334 -352.085)"
                    fill="#ec5569"
                />
                <path
                    d="M258.946,74.13,256,72.614V60.6a1.445,1.445,0,0,1,1.312.729l1.458,2.888,3.267.467a1.452,1.452,0,0,1,.787,2.508l-2.333,2.246.554,3.15a1.479,1.479,0,0,1-.583,1.429,1.426,1.426,0,0,1-1.516.117Zm0,0"
                    transform="translate(-231.112 -54.706)"
                    fill="#ff7816"
                />
                <path
                    d="M269.222,391.458v8.827H256V390h11.763a1.444,1.444,0,0,1,1.458,1.458Zm0,0"
                    transform="translate(-231.112 -352.085)"
                    fill="#cc2e43"
                />
                <path
                    d="M122.276,483.458a1.444,1.444,0,0,1-1.458,1.458H91.458a1.458,1.458,0,0,1,0-2.917h29.36a1.444,1.444,0,0,1,1.458,1.459Zm0,0"
                    transform="translate(-81.25 -435.141)"
                    fill="#697c86"
                />
                <path
                    d="M272.138,483.458a1.444,1.444,0,0,1-1.458,1.458H256V482h14.68a1.444,1.444,0,0,1,1.458,1.458Zm0,0"
                    transform="translate(-231.112 -435.141)"
                    fill="#465a61"
                />
            </g>
        </SVGIcon>
    );
}
