import { Validation } from 'Utils/validator';

export const getCurrentStatusMessage = currStatus => {
    if (currStatus === 'pending') {
        return 'You need to verify your email before submitting the profile.';
    } else if (currStatus === 'waiting for approval') {
        return 'Your profile is waiting for approval. Clicking on update will update your details.';
    } else if (currStatus === 'verified') {
        return '';
    } else if (currStatus === 'approved') {
        return 'Your profile is already approved. You cannot make changes to current form';
    } else if (currStatus === 'rejected') {
        return 'Your profile is rejected. You need to start the sign-up process again.';
    }
};

const isValidEmail = email => {
    return /\S+@\S+\.\S+/.test(email);
};

export const ValidatePersonalDetails = (personalDetails, setErrorObject) => {
    let userDetails = { ...personalDetails };
    const { first_name, last_name, password, confirmpassword } = userDetails;
    let personalErrorObject = {};
    let arr = [first_name, last_name, password, confirmpassword];
    let arrs = ['first_name', 'last_name', 'password', 'confirmpassword'];
    let arr1 = ['First Name', 'Last Name', 'Password', 'Confirm password'];
    arr?.forEach((item, index) => {
        if (item && item.length === 0) {
            personalErrorObject[`${arrs[index]}`] = `${arr1[index]} is empty`;
        } else if (!item) {
            personalErrorObject[`${arrs[index]}`] = `${arr1[index]} is empty`;
        }
    });
    if (password && confirmpassword && password !== confirmpassword) {
        personalErrorObject['password'] = 'Password and Confirm password field do not match';
        personalErrorObject['confirmpassword'] = 'Password and Confirm password field do not match';
    }
    if (password && !Validation('password', password)) {
        personalErrorObject['password'] =
            'Password should have minimum eight characters, at least one uppercase letter, one lowercase letter, one number and one special character';
    }
    setErrorObject({ ...personalErrorObject });
    if (Object.keys({ ...personalErrorObject })?.length > 0) {
        return false;
    } else {
        return true;
    }
};

export const ValidateBusinessDetails = (businessDetails, setBusinessErrorArr) => {
    let business_Details = { ...businessDetails };
    const { name, email, phone } = business_Details;
    let businessErrorObject = {};
    let arrs = ['name', 'email', 'phone'];
    let arr = [name, email, phone];
    let arr1 = ['Business Name', 'Business Email', 'Business Phone'];
    arr?.forEach((item, index) => {
        if (item && item.length === 0) {
            businessErrorObject[`${arrs[index]}`] = `${arr1[index]} is empty`;
        } else if (!item) {
            businessErrorObject[`${arrs[index]}`] = `${arr1[index]} is empty`;
        }
    });
    if (email && !isValidEmail(email)) {
        businessErrorObject[`email`] = `Email is invalid`;
    }
    if (email && !Validation('email', email)) {
        businessErrorObject[`email`] = `Email is invalid`;
    }
    setBusinessErrorArr({ ...businessErrorObject });
    if (Object.keys({ ...businessErrorObject })?.length > 0) {
        return false;
    } else {
        return true;
    }
};
