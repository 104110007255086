import React from 'react';

import { SVGIcon } from '../../UIKit/index';

export default function DCHorizontalLine(props) {
    return (
        <SVGIcon {...props} viewBox="0 0 512 240">
            <rect y="-0.11" width="511.74" height="90.08" />
            <rect y="150.03" width="511.74" height="90.08" />
        </SVGIcon>
    );
}
