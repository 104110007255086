import React from 'react';

import { SVGIcon } from '../../UIKit/index';

export default function DCCallTracking(props) {
    return (
        <SVGIcon {...props} viewBox="0 0 512 512">
            <g transform="translate(60.637 3.274)">
                <circle cx="195.4" cy="252.7" r="256" fill="#7E5FFE" />
                <g transform="translate(-53.428 4.629)">
                    <path
                        fill="#FFFFFF"
                        d="M350.7,366.9L335,382.6c-26.1,26.1-96.7-2.1-157.6-63s-89.1-131.5-63-157.6l15.7-15.7l43,20.7    l20,42.3c-6.6,6.7-7.3,17.1-1.7,24.6c20.4,27.2,44.5,51.3,71.6,71.6c7.5,5.6,18,4.9,24.6-1.7l42.3,20L350.7,366.9z"
                    />
                    <path
                        fill="#FFFFFF"
                        d="M350.7,366.9L335,382.6c-26.1,26.1-96.7-2.1-157.6-63l47.3-47.3c12,12,24.8,23.1,38.4,33.3    c7.5,5.6,18,4.9,24.6-1.7l42.3,20L350.7,366.9z"
                    />
                    <path
                        fill="#D5CBFF"
                        d="M319.2,272.3l-31.5,31.5l0,0l63,63l31.6-31.6L319.2,272.3z"
                    />
                    <path
                        fill="#D5CBFF"
                        d="M193.2,209.3l31.5-31.5l-63-63.1l-31.6,31.6L193.2,209.3L193.2,209.3z"
                    />
                </g>
            </g>
        </SVGIcon>
    );
}
