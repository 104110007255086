import React, { Fragment, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { AutoComplete } from '..';
import currency from 'Constants/currency.json';
import { Button, Checkbox, Div, SingleTextChip } from 'UIKit';
import { getAccountDetails } from 'Utils/localStorageUtils';

function CurrencyList({ value, singleSelect, onChange, options, resetVal, setResetVal }) {
    const autocompleteRef = useRef();
    const [selected, setSelected] = useState([]);
    useEffect(() => {
        if (value) {
            if (singleSelect === true) {
                const temp = (currency || options).find(item => item.value === value);
                setSelected(temp ? [temp] : []);
            } else {
                const selected = (currency || options).filter(item => value.includes(item.value));
                setSelected(selected);
            }
        } else {
            const defaultCurrency = getAccountDetails()?.currency;
            if (defaultCurrency) {
                const currencyObject = currency.find(el => el.value === defaultCurrency.code);
                if (currencyObject) {
                    setSelected([currencyObject]);
                    onChange([currencyObject.value]);
                }
            }
        }
        if (resetVal) setResetVal(false);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [value]);

    const handleSelection = useCallback(
        item => {
            if (selected.includes(item)) {
                let temp = [...selected];
                temp = temp.filter(data => data.value !== item.value);
                setSelected([...temp]);
                onChange(
                    temp.map(item => {
                        return item.value;
                    }),
                );
            } else {
                setSelected(prev => {
                    const selectedOpt = [...prev, item];
                    return selectedOpt;
                });
                onChange([...selected, item]);
            }
        },
        [onChange, selected],
    );

    const generateSingleRows = useCallback(() => {
        let temp = [];
        (currency || options).forEach((item, index) => {
            temp.push({
                id: index,
                label: (
                    <Button
                        buttonClass={'ACSingleOption ' + (selected[0] === item ? 'active' : '')}
                        onClick={() => {
                            onChange([item.value]);
                            setSelected([item]);
                        }}
                    >
                        {/* {`${item.name} (${item.code})`} */}
                        {item.label}
                    </Button>
                ),
                // value: `${item.name} (${item.code})`,
                value: item.label,
            });
        });
        return temp;
    }, [onChange, options, selected]);

    const generateMultipleRows = useCallback(() => {
        let temp = [];
        (currency || options).map((item, index) => temp.push({
                id: index,
                label: (
                    <Div className={'ACMultiOption ' + (selected.includes(item) ? 'active' : '')}>
                        <Checkbox
                            key={index}
                            unCheckColor={'#eaeaea'}
                            checkColor={'var(--dark-blue)'}
                            classes={{ main: 'ACMOICheckbox' }}
                            fullWidth={true}
                            // label={`${item.name} (${item.code})`}
                            label={item.label}
                            onChange={() => handleSelection(item)}
                            checked={selected.includes(item)}
                        />
                    </Div>
                ),
                value: item.label,
            }),
        );
        return temp;
    }, [handleSelection, options, selected]);

    const generateChips = useMemo(() => {
        return (selected || []).map((item, index) => {
            return {
                id: index,
                chip: <SingleTextChip title={item.label} onDelete={() => handleSelection(item)} />,
            };
        });
    }, [handleSelection, selected]);

    const generateValue = useCallback(() => {
        if (singleSelect) {
            return selected.length
                ? // ? `${selected[0].name} (${selected[0].code})`
                  selected[0].label
                : 'Select Currency';
        } else {
            if (selected.length) {
                return generateChips.map(item => item.chip);
            } else return 'Select Currencies';
        }
    }, [generateChips, selected, singleSelect]);

    return (
        <Fragment>
            <AutoComplete
                reference={autocompleteRef}
                isAPICall={false}
                generateRows={singleSelect ? generateSingleRows : generateMultipleRows}
                singleSelection={singleSelect}
                value={generateValue()}
                itemHeight={38}
                height={190}
                customWidth={autocompleteRef.current?.clientWidth || 310}
            />
        </Fragment>
    );
}

export default CurrencyList;

CurrencyList.propTypes = {
    singleSelect: PropTypes.bool,
    value: PropTypes.string.isRequired,
    onChange: PropTypes.func,
    options: PropTypes.string,
};

/*
For Single select

<CurrencyList
    value={"USD"}
    singleSelect
    onChange={(data) => {setCurrency(data[0])}}
/>

For Multiple select

<CurrencyList
    value={["USD", "EURO"]}
    singleSelect
    onChange={(data) => {setCurrency(data)}}
/>
*/
