/* eslint-disable no-unused-vars */
import React, { useState, Fragment, useRef, useCallback, useEffect } from 'react';
import { useQuery } from 'react-query';
import {
    getPartnerLeaders,
    getAffiliateStats,
    getAffiliateRecord,
} from 'apis/affiliate-center/index.api';
import { useHistory } from 'react-router-dom';
import DCAddIcon from 'Assets/icons/DCAddIcon';
import DCCopyLink from 'Assets/icons/DCCopyLink';
import DCArrowDown from 'Assets/icons/DCArrowDown';
import DCFillUpArray from 'Assets/icons/DCFillUpArray';
import DCFillDownArray from 'Assets/icons/DCFillDownArray';
import DCMinusIcon from 'Assets/icons/DCMinus';
import DCLogoExpanded from 'Assets/images/Dashclicks-Expanded.png';
import {
    Button,
    Div,
    H1,
    PageContainer,
    PageView,
    Image,
    Link,
    UserInfo,
    Pagination,
    DropdownOptions,
    Skeleton,
    TextOverflowTooltip,
    NoData,
    TooltipWithIcon,
    Span,
} from 'UIKit/index';
import { DCTable } from 'Components/index';
import { activatePayments } from 'apis/settings/index.api';
import { getAccountDetails } from 'Utils/localStorageUtils';
import generateImage from 'Utils/generateImage';
import { formatNumberString, setTitle } from 'Utils';
import { formateDate, getUserTimeZone } from 'Utils/dateUtils';
import { getLocalStorageAuthenticateObject, isAuthenticated } from 'Utils/auth';
import dayjs from 'dayjs';
import useMediaQuery from 'UIKit/useMediaQuery';
import useToast from 'Modules/Toasts';
import DCGrayUser from 'Assets/icons/DCGrayUser';
import DCDashClicks from 'Assets/icons/DCDashClicks';

const leaderBoardType = [
    { id: 0, title: 'Month', value: 'month' },
    { id: 1, title: 'All Time', value: 'all_time' },
];

const AffiliateCenter = ({ activeTab, handleActiveTab }) => {
    const matches991 = useMediaQuery('(max-width: 991px)');

    const { showToast } = useToast();
    const history = useHistory();
    const [page, setPage] = useState(1);
    const [limit, setLimit] = useState(25);
    const buttonLBRef = useRef();
    const [lbDropdownOpen, setLBDropdownOpen] = useState(false);
    const [selectLBType, setSelectLBType] = useState(leaderBoardType[0]);
    const [dateToBePaidOn, setDateToBePaidOn] = useState('NA');
    const [loading, setLoading] = useState(false);
    const {
        isLoading: leaderboardLoading,
        data: leaderboardData,
        error: leaderboardError,
        isError: isLeaderboardError,
    } = useQuery(['partner_leadboard', selectLBType], () => getPartnerLeaders(selectLBType.value), {
        staleTime: 1000 * 60 * 10, // 10 minutes
        cacheTime: 1000 * 60 * 15, // 15 minutes
        keepPreviousData: true,
        enabled: isAuthenticated(),
    });

    const {
        isLoading: statsLoading,
        data: statsData,
        error: statsError,
        isError: isStatsError,
    } = useQuery(['affiliate_stats'], () => getAffiliateStats(), {
        staleTime: 1000 * 60 * 10, // 10 minutes
        cacheTime: 1000 * 60 * 15, // 15 minutes
        keepPreviousData: true,
        enabled: isAuthenticated(),
    });

    const {
        isLoading: recordLoading,
        data: recordData,
        error: recordError,
        isError: isRecordError,
        isFetching,
    } = useQuery(['affiliate_record', page, limit], () => getAffiliateRecord(page, limit), {
        staleTime: 1000 * 60 * 10, // 10 minutes
        cacheTime: 1000 * 60 * 15, // 15 minutes
        keepPreviousData: true,
        enabled: isAuthenticated(),
    });

    const userReferralUrl = useRef();

    const handleCopyUrl = () => {
        const range = document.createRange();
        range.selectNode(userReferralUrl.current);
        const selection = getSelection();
        selection.removeAllRanges();
        selection.addRange(range);
        document.execCommand('copy');
        showToast({ type: 'success', message: 'Referral Link copied' });
    };

    useEffect(() => {
        let today = dayjs().tz(getUserTimeZone());
        let cutoff = dayjs().tz(getUserTimeZone()).date(5);
        let firstOfNextMonth = dayjs().tz(getUserTimeZone()).startOf('month').add(1, 'month');
        let firstOfCurrentMonth = dayjs().tz(getUserTimeZone()).startOf('month');

        today.isBefore(cutoff)
            ? setDateToBePaidOn(formateDate(firstOfCurrentMonth))
            : setDateToBePaidOn(formateDate(firstOfNextMonth));
    }, []);

    const handleLBDropdownToggle = useCallback(() => {
        setLBDropdownOpen(prevOpen => !prevOpen);
    }, []);

    const handleLBSelect = useCallback(item => {
        setSelectLBType(item);
        setLBDropdownOpen(false);
    }, []);

    const generateLBOptions = useCallback(() => {
        return leaderBoardType.map(item => {
            return {
                label: (
                    <Button
                        buttonClass={'DropdownOptionsBtn'}
                        onClick={() => {
                            handleLBSelect(item);
                        }}
                    >
                        {item.title}
                    </Button>
                ),
            };
        });
    }, [handleLBSelect]);

    const [recordColumns] = useState([
        {
            id: 1,
            Header: 'Signed Up',
            type: 'text',
            accessor: 'createdAt',
            draggable: false,
            sortable: false,
            active: true,
            default: false,
            allow_update: false,
            width: '250',
            visibleInCard: true,
            custom: false,
        },
        {
            id: 2,
            Header: 'Purchases',
            type: 'text',
            accessor: 'purchases',
            draggable: false,
            sortable: false,
            active: true,
            default: false,
            allow_update: false,
            width: '250',
            visibleInCard: true,
            custom: false,
        },
        {
            id: 4,
            Header: 'Commission',
            type: 'text',
            accessor: 'software_commission',
            draggable: false,
            sortable: false,
            active: true,
            default: false,
            allow_update: false,
            width: '250',
            visibleInCard: true,
            custom: false,
        },
        {
            id: 5,
            Header: 'Agency Id',
            type: 'text',
            accessor: 'id',
            draggable: false,
            sortable: false,
            active: true,
            default: false,
            allow_update: false,
            width: '250',
            visibleInCard: true,
            custom: false,
        },
    ]);

    const generateData = () => {
        return recordData?.data?.referrals?.map(element => {
            return {
                id: element.id.slice(-8),
                createdAt: formateDate(element.created_at, true),
                purchases: recordData.data.currency + formatNumberString(element.purchases / 100),
                software_commission:
                    recordData.data.currency +
                    formatNumberString(element.software_commission / 100),
                fulfillment_commission:
                    recordData.data.currency +
                    formatNumberString(element.fulfillment_commission / 100),
            };
        });
    };

    const stats = [
        {
            title: 'Total Clicks',
            amount: formatNumberString(statsData?.total_clicks),
            description:
                'This is the total number of times that other people clicked on your referral link.',
        },
        {
            title: 'Trialing Referrals',
            amount: formatNumberString(statsData?.trialing_referrals),
            description:
                'This is the number of users who created an account with DashClicks as a result of your referral. However, they are only using a free account.',
        },
        {
            title: 'Active Referrals',
            amount: formatNumberString(statsData?.active_referrals),
            description:
                ' This is the number of referred users that have active software subscriptions with DashClicks. You will receive a commission each month from these sales.',
        },

        {
            title: `To Be Paid On ${dateToBePaidOn}`,
            amount:
                statsData?.to_be_paid?.currency +
                formatNumberString(statsData?.to_be_paid?.value / 100),
            description: 'This shows the amount you can expect to be paid during the next period.',
        },
        {
            title: 'Lifetime Earnings',
            amount:
                statsData?.lifetime_earning?.currency +
                formatNumberString(statsData?.lifetime_earning?.value / 100),
            description:
                'This is the total amount of commission earned throughout your time as an affiliate.',
        },
    ];

    const Rating = change => {
        if (change.order === 'up') {
            return (
                <Div className="AFRLBBIcon AFRLBBIUp">
                    <DCFillUpArray />
                </Div>
            );
        }
        if (change.order === 'down') {
            return (
                <Div className="AFRLBBIcon AFRLBBIDown">
                    <DCFillDownArray />
                </Div>
            );
        }
        return (
            <Div className="AFRLBBIcon AFRLBBINeutral">
                <DCMinusIcon />
            </Div>
        );
    };

    const handlePageNumber = newPage => {
        setPage(newPage);
    };

    const handleChangeLimit = newLimit => {
        setLimit(newLimit);
    };

    const [referralLink, setReferralLink] = useState('');
    const [partnerSince, setPartnerSince] = useState(new Date());

    const getLocalStorageData = () => {
        const item = getLocalStorageAuthenticateObject();
        if (item?.auth?.account?.partnership?.referral_link) {
            setReferralLink(item?.auth?.account?.partnership?.referral_link);
        }
        if (item?.auth?.account?.partnership?.partner_since) {
            setPartnerSince(item?.auth?.account?.partnership?.partner_since);
        }
    };

    useEffect(() => {
        setTitle('Affiliate Center');
        getLocalStorageData();
    }, []);

    const SkeletonDiv = () => {
        return Array.from(Array(5).keys()).map(item => (
            <Div className="AFRLBBRow" key={item}>
                <Skeleton height={30} />
                <Skeleton height={30} />
                <Skeleton height={30} />
            </Div>
        ));
    };

    const Partner = ({ start, end, title }) => (
        <Div className="AFRTCCol">
            <Div className="AFRTCCHead">{title}</Div>
            {stats.slice(start, end).map((col, index) => {
                return (
                    <Div className="AFRTCCInfo" key={index}>
                        {col?.title.length > 25 ? (
                            <>
                                <Div className="AFRTCCITitle">
                                    <TextOverflowTooltip tooltipContent={col?.title}>
                                        {col.title}
                                    </TextOverflowTooltip>
                                    <TooltipWithIcon
                                        tooltipContent={col?.description}
                                        customClass={
                                            'ArrowPopover HeaderTextOverflowTooltip AFRTCCITitleTextOverflowTooltip'
                                        }
                                        placement={'bottom-center'}
                                    />
                                </Div>
                            </>
                        ) : (
                            <Div className="AFRTCCITitle">
                                <Span>{col?.title}</Span>
                                <TooltipWithIcon
                                    tooltipContent={col?.description}
                                    customClass={
                                        'ArrowPopover HeaderTextOverflowTooltip AFRTCCITitleTextOverflowTooltip'
                                    }
                                    placement={'bottom-center'}
                                />
                            </Div>
                        )}

                        <Div className="AFRTCCISTitle">
                            {col?.amount ? col.amount : <Skeleton height={24} />}
                        </Div>
                    </Div>
                );
            })}
        </Div>
    );
    // {avatar} -- DEVELOPMENT -- {order} -- arrow
    const LeaderBoard = ({ start, end }) => (
        <Div className="AFRLBBoard">
            {leaderboardData ? (
                leaderboardData.slice(start, end).map(leader => {
                    return (
                        <Div className="AFRLBBRow" key={leader.id}>
                            <UserInfo
                                className={'AFRLBBTitle'}
                                name={leader.name}
                                image={generateImage(leader.avatar, leader.name)}
                            />
                            <Div className="AFRLBBNum">{leader.order}</Div>
                            <Rating order={leader.change} />
                        </Div>
                    );
                })
            ) : (
                <SkeletonDiv />
            )}
        </Div>
    );
    const handleActiveAffiliateAccount = async () => {
        try {
            setLoading(true);
            await activatePayments();
        } catch (error) {
            setLoading(false);
            history.push('/settings/general?tab=affiliate');
        }
    };
    return (
        <Fragment>
            <Div className="subMenuBar">
                <Div className="sMBInner">
                    <Div className="sMBILeft">
                        <Div className="sMBILPageHeading">
                            <H1 className="sMBILPHTitle">Affiliate Center</H1>
                        </Div>
                    </Div>
                    <Div className="sMBIRight sMBIRAffilateCenter">
                        {getAccountDetails().stripe_connected && (
                            <Div className="sMBIRSInner">
                                <Button
                                    buttonType={'headerBtn'}
                                    iconName={<DCAddIcon />}
                                    iconColor={'var(--dark-blue)'}
                                    onClick={handleActiveAffiliateAccount}
                                    loading={loading}
                                >
                                    Create Affiliate Account
                                </Button>
                            </Div>
                        )}
                    </Div>
                </Div>
            </Div>
            <PageContainer>
                <PageView noSidebar classes={{ row: 'AFRPageView' }}>
                    <Div className="AFRContainer">
                        <Div className="AFRCCol1">
                            <Div className="AFRUserCol">
                                <Div className="AFRUCInfo">
                                    <Div className="AFRUCIImg">
                                        <DCDashClicks />
                                    </Div>
                                    <Div className="AFRUCIDet">
                                        <Div className="AFRUCIDLevel"> DashClicks Partner</Div>
                                        <Div className="AFRUCIDSDate">
                                            Since {formateDate(partnerSince, true)}
                                        </Div>
                                    </Div>
                                </Div>
                                <Div className="AFRReferralLink">
                                    <Div className="AFRRLInfo">
                                        <Div className="AFRRLITitle">Your Referral Link</Div>
                                        <Div className="AFRRLICopyLink">
                                            <Div
                                                className="AFRRLICLLink"
                                                reference={userReferralUrl}
                                            >
                                                {referralLink}
                                            </Div>
                                            <Div className="AFRRLICLBtn" onClick={handleCopyUrl}>
                                                <DCCopyLink />
                                            </Div>
                                        </Div>
                                    </Div>
                                    <Div className="AFRRLIMore">
                                        <Link
                                            href={'https://dashclicks.com/affiliates/'}
                                            target={'_blank'}
                                        >
                                            Learn More About Partner Rewards
                                        </Link>
                                    </Div>
                                </Div>
                            </Div>
                        </Div>
                        <Div className="AFRCCol2">
                            <Div className="AFRLeaderBoard">
                                <Div className="AFRLBHead">
                                    <Div className="AFRLBHTitleBox">
                                        <Div className="AFRLBHTBLogo">
                                            <Image src={DCLogoExpanded} alt={''} />
                                        </Div>
                                        <Div className="AFRLBHTBTitle">Partner Leaderboard</Div>
                                    </Div>
                                    <Button
                                        iconPlacement={'right'}
                                        iconName={<DCArrowDown />}
                                        reference={buttonLBRef}
                                        onClick={handleLBDropdownToggle}
                                    >
                                        {selectLBType.title}
                                    </Button>
                                    <DropdownOptions
                                        options={generateLBOptions()}
                                        onClose={handleLBDropdownToggle}
                                        wrapperWidth={100}
                                        open={lbDropdownOpen}
                                        reference={buttonLBRef}
                                        viewHeight={80}
                                        itemSize={40}
                                        placement={'bottom-end'}
                                    />
                                </Div>
                                <Div
                                    className={`AFRLBody ${
                                        leaderboardData?.length == 0 ? 'AFRLBody--NoData' : ''
                                    }`}
                                >
                                    <Fragment>
                                        {leaderboardData?.length > 0 ? (
                                            <>
                                                {matches991 ? (
                                                    <>{LeaderBoard({ start: 0, end: 10 })}</>
                                                ) : (
                                                    <>
                                                        {LeaderBoard({ start: 0, end: 5 })}
                                                        {leaderboardData.length > 5 &&
                                                            LeaderBoard({ start: 5, end: 10 })}
                                                    </>
                                                )}
                                            </>
                                        ) : (
                                            <NoData type="small" />
                                        )}
                                    </Fragment>
                                </Div>
                            </Div>
                        </Div>
                    </Div>
                    <Div className="AFRThreeCol">
                        <Div className="AFRTCol1">
                            <Partner title="Partner Stats" start={0} end={3} />
                            <Partner title="Partner Earnings" start={3} end={6} />
                        </Div>
                        <Div className="AFRTCol2">
                            <Div className="AFRTTable">
                                <DCTable
                                    loader={recordLoading || isFetching}
                                    selectOnRowClick={false}
                                    columns={recordColumns}
                                    data={generateData()}
                                    title={'OS'}
                                    totalResults={recordData?.length}
                                    checkboxes={false}
                                    onWidthChange={() => {
                                        // No width Change API call
                                    }}
                                    noData={{
                                        icon: <DCGrayUser />,
                                        title: 'No data',
                                        buttonHandle: () => {},
                                    }}
                                />

                                <Pagination
                                    totalResults={recordData?.pagination?.total}
                                    loader={recordLoading || isFetching}
                                    page={page}
                                    totalPages={recordData?.pagination?.pages}
                                    limit={limit}
                                    handlePageNumber={handlePageNumber}
                                    handleChangeLimit={handleChangeLimit}
                                />
                            </Div>
                        </Div>
                    </Div>
                </PageView>
            </PageContainer>
        </Fragment>
    );
};

export default AffiliateCenter;
