import React from 'react';

import { SVGIcon } from '../../UIKit/index';

export default function DCNumber(props) {
    return (
        <SVGIcon {...props} viewBox="0 0 459 512">
            <g>
                <path d="M321.3,82.8c2.9,0.3,5.7-0.6,7.8-2.5l28.8-24.7l-0.2,148.8c0,2.8,1.1,5.5,3.1,7.4c2,2,4.6,3.1,7.4,3.1h27.4    c5.8,0,10.5-4.7,10.5-10.5V11.4c0-5.8-4.7-10.5-10.5-10.5h-25.7c-2.4,0-4.7,0.8-6.5,2.3l-59,46.9c-2.2,1.7-3.6,4.3-3.9,7    c-0.3,2.8,0.5,5.6,2.2,7.7L314,79C315.8,81.2,318.4,82.6,321.3,82.8z" />
                <path d="M449.3,336.5c-6.4-14.2-15.7-25.2-27.6-32.6c-11.9-7.5-26-11.3-41.9-11.3c-22.5,0-40.6,6.8-53.8,20.3    c-13.1,13.4-19.8,31.9-19.8,54.9c0,21.3,5.5,38.3,16.4,50.4c10.9,12.2,26.1,18.4,44.9,18.4c11.2,0,20.8-1.9,28.3-5.7    c6.1-3.1,11.7-7.9,16.6-14.5c-1.1,12.6-3.8,22.8-7.9,30.4c-4.7,8.7-11.7,15.2-20.6,19.3c-9.2,4.2-21.1,6.3-35.4,6.3    c-5.3,0-10.6-0.4-15.7-1.2c-3-0.4-6.1,0.4-8.4,2.4c-2.3,2-3.7,4.9-3.7,8v19.1c0,5.4,4,9.8,9.3,10.4c5.8,0.6,13,1,21.4,1    c35.8,0,62.9-10.5,80.7-31.3c17.7-20.7,26.6-52.3,26.6-93.9C458.8,367.5,455.6,350.6,449.3,336.5z M400.9,389.4    c-5.9,5.5-12.9,8.1-21.2,8.1c-8.8,0-15.1-2.6-19.2-7.9c-4.3-5.4-6.4-12.9-6.4-22.4c0-11.1,2.4-19.7,7.1-25.5    c4.7-5.7,11-8.5,19.4-8.5c8.4,0,15.2,3.5,20.7,10.7c5.6,7.3,8.4,16.5,8.4,27.1C409.7,378,406.8,384,400.9,389.4z" />
                <path d="M200.4,344.2h-42V10.5c0-5.8-4.7-10.5-10.5-10.5H63.2c-5.8,0-10.5,4.7-10.5,10.5v333.7h-42c-4,0-7.7,2.3-9.5,6    c-1.7,3.6-1.2,8,1.3,11.1l94.8,141.4c2,2.5,5,3.9,8.2,3.9s6.2-1.4,8.2-3.9l94.8-141.4c2.5-3.1,3-7.5,1.3-11.1    C208.1,346.5,204.4,344.2,200.4,344.2z" />{' '}
            </g>
        </SVGIcon>
    );
}
