import React from 'react';

import { SVGIcon } from '../../UIKit/index';

export default function DCEdit(props) {
    return (
        <SVGIcon {...props} viewBox="0 0 85.253 85.246">
            <g id="edit_5_" data-name="edit (5)" transform="translate(0.5 0.501)">
                <path
                    id="Path_2008"
                    data-name="Path 2008"
                    d="M60.557,155.5H9.654A9.662,9.662,0,0,1,0,145.846v-50.9a9.662,9.662,0,0,1,9.654-9.654H39.493a2.633,2.633,0,0,1,0,5.266H9.654a4.395,4.395,0,0,0-4.388,4.388v50.9a4.395,4.395,0,0,0,4.388,4.388h50.9a4.395,4.395,0,0,0,4.388-4.388V116.006a2.633,2.633,0,1,1,5.266,0v29.839A9.662,9.662,0,0,1,60.557,155.5Zm0,0"
                    transform="translate(0 -71.255)"
                    fill="#00acfd"
                    stroke="#00acfd"
                    strokeWidth="1"
                />
                <path
                    id="Path_2009"
                    data-name="Path 2009"
                    d="M173.777,56.083a2.633,2.633,0,0,1-2.58-3.149l2.482-12.413a2.625,2.625,0,0,1,.719-1.345L210.757,2.821A9.651,9.651,0,1,1,224.406,16.47L188.051,52.829a2.614,2.614,0,0,1-1.345.719L174.3,56.03a2.594,2.594,0,0,1-.519.053Zm12.413-5.115h0Zm-7.505-8.632-1.548,7.758,7.755-1.552,35.794-35.793a4.389,4.389,0,0,0-6.206-6.207Zm0,0"
                    transform="translate(-142.982)"
                    fill="#00acfd"
                    stroke="#00acfd"
                    strokeWidth="1"
                />
                <path
                    id="Path_2010"
                    data-name="Path 2010"
                    d="M389.567,57.809a2.612,2.612,0,0,1-1.86-.773l-9.928-9.931a2.634,2.634,0,0,1,3.725-3.724l9.927,9.931a2.635,2.635,0,0,1-1.864,4.5Zm0,0"
                    transform="translate(-314.969 -35.599)"
                    fill="#00acfd"
                    stroke="#00acfd"
                    strokeWidth="2"
                />
            </g>
        </SVGIcon>
    );
}
