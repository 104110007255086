import DCGrayReminder from 'Assets/icons/DCGrayReminder';
import dayjs from 'dayjs';
import React, { Fragment, useEffect, useState } from 'react';
import { Div, NoData } from 'UIKit';
import CardContainer from './cardContainer';
import { calenderConfig, getSegmentedReminders } from './helpers';
import './style.scss';

var calendar = require('dayjs/plugin/calendar');
dayjs.extend(calendar);

const Upcoming = props => {
    const { reminders, handleClose } = props;
    const [segmentedReminders, setSegmentedReminders] = useState({});

    useEffect(() => {
        setSegmentedReminders(getSegmentedReminders(reminders));
    }, [reminders]);

    return (
        <Div className="UpcomingSection">
            {Object.keys(segmentedReminders).length > 0 ? (
                Object.keys(segmentedReminders)
                    .sort((a, b) => new Date(a) - new Date(b))
                    .map((date, index) => (
                        <Fragment key={index}>
                            <Div className="UBTitle">
                                {dayjs(date).calendar(null, calenderConfig)}
                            </Div>
                            <Div className="UpcomingBody">
                                <CardContainer
                                    type="upcoming"
                                    data={segmentedReminders[date]}
                                    origin={'upcoming'}
                                    handleClose={handleClose}
                                />
                            </Div>
                        </Fragment>
                    ))
            ) : (
                <NoData
                    icon={<DCGrayReminder />}
                    title={'No Upcoming Reminders'}
                    description={'There are no upcoming reminders at this time.'}
                />
            )}
        </Div>
    );
};

export default Upcoming;
