import { CallAPI } from 'Utils/apiCall';
import config from 'config';
const api = new CallAPI();

const baseUrl = `${config.REACT_APP_API_BASE_URL}/${process.env.REACT_APP_API_VERSION}`;
const notesUrl = `${baseUrl}/${process.env.REACT_APP_API_CRM}/${process.env.REACT_APP_API_NOTES}`;
const reminderUrl = `${baseUrl}/${process.env.REACT_APP_API_CRM}/${process.env.REACT_APP_API_REMINDERS}`;
const activityUrl = `${baseUrl}/${process.env.REACT_APP_API_ACTIVITIES}`;
const smsUrl = `${baseUrl}/${process.env.REACT_APP_API_INTEGRATIONS}/${process.env.REACT_APP_API_TWILIO}/${process.env.REACT_APP_API_SEND}`;
const conversationsUrl = `${baseUrl}/${process.env.REACT_APP_API_CONVERSATIONS}/${process.env.REACT_APP_API_MESSAGES}`;
// Notes
export const getNotes = async (limit, page, query) => {
    return api.request('GET', notesUrl, null, limit, page, query);
};

export const filterNotes = async (body, limit, page, query) => {
    const url = `${notesUrl}/${process.env.REACT_APP_API_FILTER}`;
    return api.request('POST', url, body, limit, page, query);
};

export const createNote = async body => {
    return api.request('POST', notesUrl, body);
};

export const updateNote = async (noteId, body, query) => {
    const url = `${notesUrl}/${noteId}`;
    return api.request('PUT', url, body, null, null, query);
};

export const deleteNote = async (id, query) => {
    const url = `${notesUrl}/${id}`;
    return api.request('DELETE', url, null, null, null, query);
};

export const addNoteComment = async (noteId, body) => {
    const url = `${notesUrl}/${noteId}/${process.env.REACT_APP_API_COMMENTS}`;
    return api.request('POST', url, body, null, null, { expand: true });
};

export const updateNoteComment = async (noteId, commentId, body) => {
    const url = `${notesUrl}/${noteId}/${process.env.REACT_APP_API_COMMENTS}/${commentId}`;
    return api.request('PUT', url, body, null, null, { expand: true });
};

export const deleteNoteComment = async (noteId, commentId) => {
    const url = `${notesUrl}/${noteId}/${process.env.REACT_APP_API_COMMENTS}/${commentId}?expand=true`;
    return api.request('DELETE', url);
};

// Reminders
export const getRemindersApi = async (limit, page, query) => {
    return api.request('GET', reminderUrl, null, limit, page, query);
};

export const filterRemindersApi = async (limit, page, body, query) => {
    const url = `${reminderUrl}/${process.env.REACT_APP_API_FILTER}`;
    return api.request('POST', url, body, limit, page, query);
};

export const createReminder = async body => {
    return api.request('POST', reminderUrl, body);
};

export const deleteReminder = async (id, query) => {
    const url = `${reminderUrl}/${id}`;
    return api.request('DELETE', url, null, null, null, query);
};

export const updateReminderApi = async (reminderId, body, query) => {
    const url = `${reminderUrl}/${reminderId}`;
    return api.request('PUT', url, body, null, null, query);
};

// Activity
export const getActivities = async (limit, page, params) => {
    return api.request('GET', activityUrl, null, limit, page, params);
};

export const filterActivities = async (body, limit, page, query) => {
    const url = `${activityUrl}/${process.env.REACT_APP_API_FILTER}`;
    return api.request('POST', url, body, limit, page, query);
};

export const createActivity = async (type, body) => {
    const url = `${activityUrl}/${type}?expand=true`;
    return api.request('POST', url, body);
};

export const updateSingleActivity = async (activityType, id, body, query) => {
    const url = `${activityUrl}/${activityType}/${id}`;
    return api.request('PUT', url, body, null, null, query);
};

export const deleteActivityApi = async (activityType, id, query) => {
    const url = `${activityUrl}/${activityType}/${id}`;
    return api.request('DELETE', url, null, null, null, query);
};

export const createActivityComment = async (activityType, activityId, body) => {
    const url = `${activityUrl}/${activityType}/${activityId}/${process.env.REACT_APP_API_COMMENTS}`;
    return api.request('POST', url, body);
};
export const updateActivityComment = async (activityType, activityId, commentId, body, query) => {
    const url = `${activityUrl}/${activityType}/${activityId}/${process.env.REACT_APP_API_COMMENTS}/${commentId}`;
    return api.request('PUT', url, body, null, null, query);
};

export const deleteActivityComment = async (activityType, activityId, commentId) => {
    const url = `${activityUrl}/${activityType}/${activityId}/${process.env.REACT_APP_API_COMMENTS}/${commentId}?expand=true`;
    return api.request('DELETE', url);
};

// Conversation
export const sendSMS = async body => {
    return api.request('POST', smsUrl, body);
};

export const getConversations = async (id, limit, page, query, fromDeals) => {
    if (fromDeals) {
        let updatedQuery = query || {};
        updatedQuery.contactIDs = id;
        return api.request('GET', conversationsUrl, null, limit, page, updatedQuery);
    } else {
        const url = `${conversationsUrl}/${id}`;
        return api.request('GET', url, null, limit, page, query);
    }
};

// logs

export const getLogs = async params => {
    return api.request(
        'GET',
        `${activityUrl}/${process.env.REACT_APP_API_LOGS}`,
        null,
        null,
        null,
        params,
    );
};

export const getFilterLogs = async (params, body) => {
    return api.request(
        'POST',
        `${activityUrl}/${process.env.REACT_APP_API_FILTER}`,
        body,
        null,
        null,
        params,
    );
};
