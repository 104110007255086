/* eslint-disable no-unused-vars */
import React, { Fragment, useCallback, useEffect, useRef, useState } from 'react';
import loadable from '@loadable/component';
import PropTypes from 'prop-types';
import DCAddIcon from '../../Assets/icons/DCAddIcon';
import DCCalendarDate from '../../Assets/icons/DCCalendarDate';
import DCCheckedBox from '../../Assets/icons/DCCheckedBox';
import DCCrossClose from '../../Assets/icons/DCCrossClose';
import DCDateTime from '../../Assets/icons/DCDateTime';
import DCEnvelope1 from '../../Assets/icons/DCEnvelope1';
import DCMultiOption from '../../Assets/icons/DCMultiOption';
import DCNumber from '../../Assets/icons/DCNumber';
import DCPhone from '../../Assets/icons/DCPhone';
import DCSingleOption from '../../Assets/icons/DCSingleOption';
import DCTextarea from '../../Assets/icons/DCTextarea';
import DCTextField from '../../Assets/icons/DCTextField';
import DCWatch3 from '../../Assets/icons/DCWatch3';
import DCWebGlobe from '../../Assets/icons/DCWebGlobe';
import './style.scss';
import { fieldTypes } from '../../Constants/manageColumnConst';
import useToast from '../../Modules/Toasts';
import {
    Br,
    Button,
    Checkbox,
    Dialog,
    Div,
    DropdownOptions,
    FieldControl,
    FooterBar,
    H1,
    IconButton,
    Input,
    Label,
    LabelControl,
    SingleTextChip,
    Textarea,
    TopBar,
} from '../../UIKit/index';
import { Validation } from '../../Utils/validator';
const AutoComplete = loadable(() => import('../../Components/AutoComplete'));
const DatePicker = loadable(() => import('../../Components/DatePicker'));
const TimePicker = loadable(() => import('../../Components/TimePicker'));
const DateTimePicker = loadable(() => import('../../Components/DateTimePicker'));
const PhoneNumber = loadable(() => import('../../Modules/PhoneNumber/phoneNumber'));

const CreateProperty = ({
    type,
    open,
    onClose,
    openAsEdit,
    editItem,
    createColumn,
    editColumn,
    showAddToView,
}) => {
    const [dropdownOptions] = useState([
        {
            id: 1,
            title: 'Single Line Text',
            type: 'text',
            icon: <DCTextField />,
        },
        {
            id: 2,
            title: 'Multi Line Text',
            type: 'longtext',
            icon: <DCTextarea />,
        },
        {
            id: 3,
            title: 'Number',
            type: 'number',
            icon: <DCNumber />,
        },
        {
            id: 4,
            title: 'Phone Number',
            type: 'phone',
            icon: <DCPhone />,
        },
        {
            id: 5,
            title: 'Website',
            type: 'website',
            icon: <DCWebGlobe />,
        },
        {
            id: 6,
            title: 'Email Address',
            type: 'email',
            icon: <DCEnvelope1 />,
        },
        {
            id: 7,
            title: 'Dropdown Option',
            type: 'dropdown',
            icon: <DCSingleOption />,
        },
        {
            id: 8,
            title: 'Yes/No',
            type: 'singleCheck',
            icon: <DCCheckedBox />,
        },
        {
            id: 9,
            title: 'Multiple Checkbox',
            type: 'multipleCheck',
            icon: <DCMultiOption />,
        },
        {
            id: 10,
            title: 'Date & Time',
            type: 'datetime',
            icon: <DCDateTime />,
        },
        {
            id: 11,
            title: 'Date Picker',
            type: 'date',
            icon: <DCCalendarDate />,
        },
        {
            id: 12,
            title: 'Time Picker',
            type: 'time',
            icon: <DCWatch3 />,
        },
    ]);
    const [dropdownOptionsForFieldType] = useState([
        {
            id: 1,
            title: 'People',
            text: 'contacts',
        },
        {
            id: 2,
            title: 'Business',
            text: 'companies',
        },
    ]);
    const { showToast } = useToast();
    const buttonRef = useRef();
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const [dropdownWidth, setDropdownWidth] = useState(0);
    const [fieldName, setFieldName] = useState('');
    const [selectedFieldType, setSelectedFieldType] = useState(null);
    const [optionValue, setOptionValue] = useState('');
    const [options, setOptions] = useState([]);
    const optionPreviewRef = useRef();
    const [optionPreviewDropdownOpen, setOptionPreviewDropdownOpen] = useState(false);
    const [optionPreviewDropdownWidth, setOptionPreviewDropdownWidth] = useState(0);
    const [selectedPreviewOption, setSelectedPreviewOption] = useState(null);
    const [autoCompleteSelectOption] = useState({});
    const [disableCreateButton, setDisableCreateButton] = useState(false);
    const [selectedPreviewOptions, setSelectedPreviewOptions] = useState(null);
    const [autoCompleteSelectOptions, setAutoCompleteSelectOptions] = useState([]);
    const [errorState, setErrorState] = useState(false);
    const [previewValue, setPreviewValue] = useState('');
    const [addToView, setAddToView] = useState(true);

    useEffect(() => {
        if (editItem) {
            if (editItem?.Header) setFieldName(editItem.Header);
            setSelectedFieldType(dropdownOptions.filter(item => item.type === editItem.type)[0]);
            if (editItem?.options) setOptions(editItem.options);
        }
    }, [dropdownOptions, editItem]);

    const handleDropdownToggle = () => {
        setDropdownOpen(prevOpen => !prevOpen);
        setDropdownWidth(buttonRef.current.offsetWidth);
    };

    const handleDropdownClose = () => {
        setDropdownOpen(false);
    };

    const handleFieldTypeSelection = useCallback(item => {
        if (item.title === fieldTypes.Yes_No)
            setOptions([
                { label: 'Yes', value: 'yes' },
                { label: 'No', value: 'no' },
            ]);
        else setOptions([]);
        setSelectedFieldType(item);
        handleDropdownToggle();
    }, []);

    const [fieldType, setFieldType] = useState(dropdownOptionsForFieldType[0]);
    const [fieldTypeOpen, setFieldTypeOpen] = useState(false);
    const buttonRefForFieldType = useRef();
    const [fieldTypeDropdownWidth, setFieldTypeDropdownWidth] = useState(0);

    const generateFieldOptions = useCallback(() => {
        const tempOptionArray = [];
        dropdownOptionsForFieldType.map(item => tempOptionArray.push({
                label: (
                    <Button
                        buttonClass={`DropdownOptionsBtn ${
                            fieldType?.id === item?.id ? 'active' : ''
                        }`}
                        onClick={() => {
                            setFieldType(item);
                            setFieldTypeOpen(false);
                        }}
                    >
                        {item?.title}
                    </Button>
                ),
            }),
        );
        return tempOptionArray;
    }, [dropdownOptionsForFieldType, fieldType?.id]);

    const handleDropdownToggleFieldType = () => {
        setFieldTypeOpen(prevOpen => !prevOpen);
        setFieldTypeDropdownWidth(buttonRefForFieldType.current.offsetWidth);
    };

    const generateOptions = useCallback(() => {
        const tempOptionArray = [];
        dropdownOptions.map(item => tempOptionArray.push({
                label: (
                    <Button
                        buttonClass={`DropdownOptionsBtn ${
                            selectedFieldType?.id === item?.id ? 'active' : ''
                        }`}
                        iconName={item?.icon}
                        onClick={() => {
                            if (fieldName?.length === 0) setErrorState(true);
                            else {
                                if (errorState) setErrorState(false);
                            }
                            handleFieldTypeSelection(item);
                            setPreviewValue('');
                        }}
                    >
                        {item?.title}
                    </Button>
                ),
            }),
        );
        return tempOptionArray;
    }, [dropdownOptions, fieldName, handleFieldTypeSelection, errorState, selectedFieldType]);

    const handleOptionInputChange = event => {
        setOptionValue(event.target.value);
    };

    const addOption = useCallback(() => {
        const temp = options.filter(item => item.value === optionValue);
        if (temp.length) {
            return showToast({
                type: 'warning',
                message: 'Duplicate options are not allowed',
                duration: 1500,
            });
        }
        setOptions(prev => [
            ...prev,
            {
                id: options.length,
                label: optionValue?.trim(),
                value: optionValue?.trim()?.toLowerCase().split(' ').join('_'),
            },
        ]);
        setOptionValue('');
    }, [optionValue, options, showToast]);

    const handleKeyUp = useCallback(
        event => {
            if (event.key === 'Enter') {
                if (optionValue?.trim()?.length === 0)
                    return showToast({
                        type: 'warning',
                        message: 'Cannot add empty option',
                        duration: 1500,
                    });
                addOption();
            }
        },
        [addOption, optionValue, showToast],
    );

    const handleDeleteOption = useCallback(
        data => {
            const tempArray = options.filter(item => item !== data);
            setOptions(tempArray);

            if (selectedPreviewOption === data) {
                setSelectedPreviewOption(null);
            }

            if (autoCompleteSelectOptions.includes(data)) {
                const temp = autoCompleteSelectOptions.filter(item => item !== data);
                setAutoCompleteSelectOptions(temp);
            }
        },
        [autoCompleteSelectOptions, options, selectedPreviewOption],
    );

    const handleEditOption = (event, index) => {
        let temp = [...options];
        temp[index].label = event.target.value;
        temp[index].value = event.target.value.toLowerCase().split(' ').join('_');
        setOptions(temp);
    };

    const handlePreviewOptionToggle = () => {
        setOptionPreviewDropdownOpen(prevOpen => !prevOpen);
        setOptionPreviewDropdownWidth(optionPreviewRef.current.offsetWidth);
    };

    const handlePreviewOptionClose = () => {
        setOptionPreviewDropdownOpen(false);
    };

    const handlePreviewOptionSelection = useCallback(item => {
        setSelectedPreviewOption(item);
        handlePreviewOptionClose();
    }, []);

    const generatePreviewOptions = useCallback(() => {
        const tempOptionArray = [];
        options.map(item => tempOptionArray.push({
                label: (
                    <Button
                        buttonClass={'DropdownOptionsBtn'}
                        iconName={item.icon}
                        onClick={() => {
                            handlePreviewOptionSelection(item);
                        }}
                    >
                        {item.label}
                    </Button>
                ),
            }),
        );
        return tempOptionArray;
    }, [handlePreviewOptionSelection, options]);

    const handleAutocompleteSelection = useCallback(
        data => {
            if (autoCompleteSelectOptions.includes(data)) {
                const temp = autoCompleteSelectOptions.filter(item => item.value !== data.value);
                setAutoCompleteSelectOptions(temp);
            } else {
                setAutoCompleteSelectOptions(prev => [...prev, data]);
            }
        },
        [autoCompleteSelectOptions],
    );

    const generateChips = useCallback(() => {
        const tempOptionArray = [];
        autoCompleteSelectOptions.map(item => tempOptionArray.push({
                id: item.id,
                chip: (
                    <SingleTextChip
                        title={item.label}
                        onDelete={() => handleAutocompleteSelection(item)}
                    />
                ),
            }),
        );
        setSelectedPreviewOptions(tempOptionArray);
    }, [autoCompleteSelectOptions, handleAutocompleteSelection]);

    useEffect(() => {
        generateChips();
    }, [autoCompleteSelectOptions, generateChips]);

    const generateAutoCompleteMultiple = useCallback(() => {
        const tempRowArray = [];
        options.map((item, index) => tempRowArray.push({
                id: index,
                label: (
                    <Div
                        className={
                            'ACMultiOption ' +
                            (autoCompleteSelectOption.value === item.value ? 'active' : '')
                        }
                    >
                        <Checkbox
                            unCheckColor={'#eaeaea'}
                            checkColor={'var(--dark-blue)'}
                            classes={{ main: 'ACMOICheckbox' }}
                            fullWidth={true}
                            label={item.label}
                            onChange={() => handleAutocompleteSelection(item)}
                            checked={autoCompleteSelectOptions.includes(item)}
                        />
                    </Div>
                ),
                value: item.value,
            }),
        );
        return tempRowArray;
    }, [autoCompleteSelectOption, autoCompleteSelectOptions, handleAutocompleteSelection, options]);

    const generateFieldPreview = useCallback(() => {
        switch (selectedFieldType?.title) {
            case fieldTypes.Single_Line_Text:
                return (
                    <FieldControl>
                        <LabelControl>
                            <Label props={{ htmlFor: 'FieldName' }}>{fieldName}</Label>
                        </LabelControl>
                        <Input
                            type="text"
                            value={previewValue}
                            onChange={e => setPreviewValue(e.target.value)}
                            id={'FieldName'}
                        />
                    </FieldControl>
                );
            case fieldTypes.Multi_Line_Text:
                return (
                    <FieldControl>
                        <LabelControl>
                            <Label props={{ htmlFor: 'FieldName' }} s>
                                {fieldName}
                            </Label>
                        </LabelControl>
                        <Textarea id={'FieldName'} />
                    </FieldControl>
                );
            case fieldTypes.Number:
                return (
                    <FieldControl>
                        <LabelControl>
                            <Label props={{ htmlFor: 'FieldName' }}>{fieldName}</Label>
                        </LabelControl>
                        <Input
                            type={'number'}
                            value={previewValue}
                            onChange={e => setPreviewValue(e.target.value)}
                            id={'FieldName'}
                        />
                    </FieldControl>
                );
            case fieldTypes.Phone_Number:
                return (
                    <FieldControl>
                        <LabelControl>
                            <Label props={{ htmlFor: 'FieldName' }}>{fieldName}</Label>
                        </LabelControl>
                        <PhoneNumber id={'FieldName'} readOnly />
                    </FieldControl>
                );
            case fieldTypes.Website:
                return (
                    <FieldControl>
                        <LabelControl>
                            <Label props={{ htmlFor: 'FieldName' }}>{fieldName}</Label>
                        </LabelControl>
                        <Input
                            type={'url'}
                            value={previewValue}
                            onChange={e => setPreviewValue(e.target.value)}
                            error={
                                Validation('website', previewValue, 'default') === false &&
                                previewValue.length > 0
                            }
                            errorMessage="Not a valid url"
                        />
                    </FieldControl>
                );
            case fieldTypes.Email_Address:
                return (
                    <FieldControl>
                        <LabelControl>
                            <Label props={{ htmlFor: 'FieldName' }}>{fieldName}</Label>
                        </LabelControl>
                        <Input
                            type={'email'}
                            value={previewValue}
                            onChange={e => setPreviewValue(e.target.value)}
                            error={
                                Validation('email', previewValue, 'default') === false &&
                                previewValue.length > 0
                            }
                            errorMessage="Not a valid email"
                        />
                    </FieldControl>
                );
            case fieldTypes.Dropdown_Option:
                return (
                    <FieldControl>
                        <LabelControl>
                            <Label required>{fieldName}</Label>
                        </LabelControl>
                        <Div className={'DCDropdownSelect fullWidth'}>
                            <Button
                                buttonClass={
                                    'DCDSMainBtn ' +
                                    (optionPreviewDropdownOpen === true ? 'focus' : '')
                                }
                                reference={optionPreviewRef}
                                onClick={handlePreviewOptionToggle}
                            >
                                {selectedPreviewOption
                                    ? selectedPreviewOption.label
                                    : 'Select Option'}
                            </Button>
                        </Div>
                        <DropdownOptions
                            options={generatePreviewOptions()}
                            wrapperWidth={optionPreviewDropdownWidth}
                            onClose={handlePreviewOptionClose}
                            open={optionPreviewDropdownOpen}
                            reference={optionPreviewRef}
                            viewHeight={options.length * 40}
                            itemSize={40}
                        />
                    </FieldControl>
                );
            case fieldTypes.Yes_No:
                return (
                    <FieldControl>
                        <LabelControl>
                            <Label props={{ htmlFor: 'FieldName' }}>{fieldName}</Label>
                        </LabelControl>

                        <Div className={'DCDropdownSelect fullWidth'}>
                            <Button
                                buttonClass={
                                    'DCDSMainBtn ' +
                                    (optionPreviewDropdownOpen === true ? 'focus' : '')
                                }
                                reference={optionPreviewRef}
                                onClick={handlePreviewOptionToggle}
                            >
                                {selectedPreviewOption
                                    ? selectedPreviewOption.label
                                    : 'Select Option'}
                            </Button>
                        </Div>
                        <DropdownOptions
                            options={generatePreviewOptions()}
                            wrapperWidth={optionPreviewDropdownWidth}
                            onClose={handlePreviewOptionClose}
                            open={optionPreviewDropdownOpen}
                            reference={optionPreviewRef}
                            viewHeight={options.length * 40}
                            itemSize={40}
                        />
                    </FieldControl>
                );
            case fieldTypes.Multiple_Checkbox:
                return (
                    <FieldControl>
                        <LabelControl>
                            <Label props={{ htmlFor: 'FieldName' }}>{fieldName}</Label>
                        </LabelControl>
                        <AutoComplete
                            generateRows={generateAutoCompleteMultiple}
                            isAPICall={false}
                            singleSelection={false}
                            value={
                                selectedPreviewOptions.length
                                    ? selectedPreviewOptions.map(item => item.chip)
                                    : 'Select Option'
                            }
                            itemHeight={45}
                            height={
                                options.length < 5 && options.length > 0
                                    ? (options.length + 1) * 45
                                    : 225
                            }
                        />
                    </FieldControl>
                );
            case fieldTypes.Date_Time:
                return (
                    <FieldControl>
                        <LabelControl>
                            <Label props={{ htmlFor: 'FieldName' }}>{fieldName}</Label>
                        </LabelControl>
                        <DateTimePicker />
                    </FieldControl>
                );
            case fieldTypes.Date_Picker:
                return (
                    <FieldControl>
                        <LabelControl>
                            <Label props={{ htmlFor: 'FieldName' }}>{fieldName}</Label>
                        </LabelControl>
                        <DatePicker readOnly />
                    </FieldControl>
                );
            case fieldTypes.Time_Picker:
                return (
                    <FieldControl>
                        <LabelControl>
                            <Label props={{ htmlFor: 'FieldName' }}>{fieldName}</Label>
                        </LabelControl>
                        <TimePicker />
                    </FieldControl>
                );
            default:
        }
    }, [
        fieldName,
        generateAutoCompleteMultiple,
        generatePreviewOptions,
        optionPreviewDropdownOpen,
        optionPreviewDropdownWidth,
        options.length,
        previewValue,
        selectedFieldType,
        selectedPreviewOption,
        selectedPreviewOptions,
    ]);

    const handleDisableCreateButton = () => {
        if (openAsEdit) {
            if (fieldName?.trim()?.length && fieldName !== editItem.Header) return false;
            return true;
        } else {
            if (selectedFieldType && fieldName?.trim()?.length) {
                if (
                    [
                        fieldTypes.Dropdown_Option,
                        fieldTypes.Yes_No,
                        fieldTypes.Multiple_Checkbox,
                    ].includes(selectedFieldType.title)
                ) {
                    if (options.length) return false;
                    return true;
                }
                return false;
            } else return true;
        }
    };

    const createBody = (type, title, options) => {
        let body = {};
        if (type) {
            body['valueType'] = type;
        }
        if (title) {
            body['title'] = title?.trim();
        }
        if (options) {
            if (options.length > 0) {
                body['options'] = options;
            }
        }

        return body;
    };

    const handleCreateProperty = useCallback(() => {
        let body;
        setDisableCreateButton(true);
        if (
            [fieldTypes.Dropdown_Option, fieldTypes.Yes_No, fieldTypes.Multiple_Checkbox].includes(
                selectedFieldType?.title,
            )
        )
            body = createBody(selectedFieldType.type, fieldName, options);
        else body = createBody(selectedFieldType.type, fieldName);
        if (type === 'both') body.fieldType = fieldType.text;
        if (openAsEdit) {
            delete body.valueType;
            editColumn(body, editItem?.accessor);
        } else {
            if (addToView && showAddToView) body.field_options = { in_dialog_card: addToView };
            createColumn(body);
            setDisableCreateButton(false);
        }
    }, [
        addToView,
        createColumn,
        editColumn,
        editItem?.accessor,
        fieldName,
        fieldType.text,
        openAsEdit,
        options,
        selectedFieldType,
        showAddToView,
        type,
    ]);

    return (
        <Dialog dialogType={'rightSlide'} open={open} onClose={onClose}>
            <Div className={'CreateProperty'}>
                <TopBar DialogTopBar>
                    <Div className={'HeaderBar'}>
                        <H1 className={'HeaderBarTitle'}>
                            {`${openAsEdit ? 'Rename' : 'Create'} Property`}
                        </H1>
                        <Div className={'HeaderBarClose'}>
                            <IconButton onClick={onClose}>
                                <DCCrossClose />
                            </IconButton>
                        </Div>
                    </Div>
                </TopBar>
                <Div className={'BodyBox'}>
                    <Div className={'BodyBoxInner'}>
                        <Div className={'CreatePropertyForm'}>
                            {type === 'both' && (
                                <FieldControl>
                                    <LabelControl>
                                        <Label required>Wants to create property for?</Label>
                                    </LabelControl>
                                    <Div className={'DCDropdownSelect fullWidth'}>
                                        <Button
                                            buttonClass={
                                                'DCDSMainBtn ' +
                                                (dropdownOpen === true ? 'focus' : '')
                                            }
                                            reference={buttonRefForFieldType}
                                            onClick={handleDropdownToggleFieldType}
                                            disabled={openAsEdit}
                                        >
                                            {fieldType.title}
                                        </Button>
                                    </Div>
                                    <DropdownOptions
                                        options={generateFieldOptions()}
                                        wrapperWidth={fieldTypeDropdownWidth}
                                        onClose={handleDropdownClose}
                                        open={fieldTypeOpen}
                                        reference={buttonRefForFieldType}
                                        viewHeight={190}
                                        itemSize={38}
                                    />
                                </FieldControl>
                            )}
                            <FieldControl>
                                <LabelControl>
                                    <Label props={{ htmlFor: 'FieldName' }} required>
                                        Field Name
                                    </Label>
                                </LabelControl>
                                <Input
                                    id={'FieldName'}
                                    value={fieldName}
                                    onChange={event => {
                                        if (errorState) setErrorState(false);
                                        if (selectedFieldType && event.target.value.length === 0)
                                            setErrorState(true);
                                        setFieldName(event.target.value);
                                    }}
                                    error={errorState}
                                    errorMessage={'Field Name cannot be empty'}
                                />
                            </FieldControl>
                            <FieldControl>
                                <LabelControl>
                                    <Label required>Field Type</Label>
                                </LabelControl>
                                <Div className={'DCDropdownSelect fullWidth'}>
                                    <Button
                                        buttonClass={
                                            'DCDSMainBtn ' + (dropdownOpen === true ? 'focus' : '')
                                        }
                                        reference={buttonRef}
                                        onClick={handleDropdownToggle}
                                        iconName={selectedFieldType ? selectedFieldType.icon : ''}
                                        disabled={openAsEdit}
                                    >
                                        {selectedFieldType
                                            ? selectedFieldType.title
                                            : 'Select Option'}
                                    </Button>
                                </Div>
                                <DropdownOptions
                                    options={generateOptions()}
                                    wrapperWidth={dropdownWidth}
                                    onClose={handleDropdownClose}
                                    open={dropdownOpen}
                                    reference={buttonRef}
                                    viewHeight={190}
                                    itemSize={38}
                                />
                            </FieldControl>
                            {[fieldTypes.Dropdown_Option, fieldTypes.Multiple_Checkbox].includes(
                                selectedFieldType?.title,
                            ) && (
                                <Div className={'CPFOptions'}>
                                    <Div className={'CPFOHead'}>Options</Div>
                                    <Div className={'CPFOBody'}>
                                        {!(
                                            selectedFieldType === fieldTypes.Single_Option &&
                                            options.length === 1
                                        ) && (
                                            <Div className={'CPFOBOption'}>
                                                <Input
                                                    id={'FieldName'}
                                                    value={optionValue}
                                                    onChange={handleOptionInputChange}
                                                    onKeyUp={handleKeyUp}
                                                />
                                                <Div className={'CPFOBOBtns'}>
                                                    <IconButton
                                                        customClass={'BlueFillBtn'}
                                                        onClick={addOption}
                                                        disabled={optionValue?.trim()?.length === 0}
                                                    >
                                                        <DCAddIcon />
                                                    </IconButton>
                                                </Div>
                                            </Div>
                                        )}
                                        {options.map((item, index) => (
                                            <Div className={'CPFOBOption'} key={`option_${index}`}>
                                                <Input
                                                    value={item.label}
                                                    onChange={event => {
                                                        handleEditOption(event, index);
                                                    }}
                                                />
                                                <Div className={'CPFOBOBtns'}>
                                                    <IconButton
                                                        customClass={'RedOutlineBtn'}
                                                        onClick={() => handleDeleteOption(item)}
                                                    >
                                                        <DCCrossClose />
                                                    </IconButton>
                                                </Div>
                                            </Div>
                                        ))}
                                    </Div>
                                </Div>
                            )}
                            {selectedFieldType && (
                                <Fragment>
                                    <LabelControl>
                                        <Label>Preview</Label>
                                    </LabelControl>
                                    <Div className={'fieldPreview'}>{generateFieldPreview()}</Div>
                                </Fragment>
                            )}

                            {showAddToView && (
                                <>
                                    <Br />
                                    <FieldControl>
                                        <Checkbox
                                            unCheckColor={'#eaeaea'}
                                            checkColor={'var(--dark-blue)'}
                                            fullWidth={true}
                                            label={<Fragment>Add to view</Fragment>}
                                            onChange={e => setAddToView(e.target.checked)}
                                            checked={addToView}
                                        />
                                    </FieldControl>
                                </>
                            )}
                        </Div>
                    </Div>
                </Div>

                <FooterBar DialogFooterBar>
                    <Div className={'ButtonWrapper'}>
                        <Button buttonClass={'RedOutlineBtn'} onClick={onClose}>
                            Cancel
                        </Button>
                        <Button
                            buttonClass={'BlueFillBtn'}
                            disabled={
                                disableCreateButton
                                    ? disableCreateButton
                                    : handleDisableCreateButton()
                            }
                            onClick={handleCreateProperty}
                        >
                            {openAsEdit ? 'Update' : 'Create'}
                        </Button>
                    </Div>
                </FooterBar>
            </Div>
        </Dialog>
    );
};

CreateProperty.propTypes = {
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    openAsEdit: PropTypes.bool.isRequired,
    editItem: PropTypes.any,
    createColumn: PropTypes.func,
    editColumn: PropTypes.func,
    type: PropTypes.string,
    showAddToView: PropTypes.bool,
};

export default CreateProperty;
