import React from 'react';

import { SVGIcon } from '../../UIKit/index';

export default function DCGrayInstaReport(props) {
    return (
        <SVGIcon {...props} viewBox="0 0 430.22 476.425">
            <defs>
                <linearGradient
                    id="linear-gradient"
                    x1="0.978"
                    y1="0.047"
                    x2="0.263"
                    y2="0.702"
                    gradientUnits="objectBoundingBox"
                >
                    <stop offset="0" stopColor="#fff" />
                    <stop offset="1" stopColor="#eceff2" />
                </linearGradient>
                <linearGradient id="linear-gradient-2" x1="0" y1="0.501" x2="1" y2="0.501" />
                <linearGradient id="linear-gradient-3" x1="0" y1="0.499" x2="1" y2="0.499" />
                <linearGradient id="linear-gradient-4" x1="0" y1="0.499" x2="1" y2="0.499" />
                <linearGradient id="linear-gradient-5" x1="0" y1="0.501" x2="1" y2="0.501" />
                <linearGradient id="linear-gradient-6" x1="0" y1="0.499" x2="1" y2="0.499" />
                <linearGradient id="linear-gradient-7" x1="0" y1="0.5" x2="1" y2="0.5" />
                <linearGradient
                    id="linear-gradient-8"
                    x1="0.978"
                    y1="0.047"
                    x2="0.263"
                    y2="0.702"
                />
            </defs>
            <g id="Artboard_1" transform="translate(-38.17 -19.835)">
                <g id="Layer_1">
                    <ellipse
                        id="Ellipse_1"
                        cx="91.5"
                        cy="18.5"
                        rx="91.5"
                        ry="18.5"
                        transform="translate(170.12 458.76)"
                        fill="#f5f6f7"
                        stroke="#f5f6f7"
                        strokeMiterlimit="10"
                        strokeWidth="1"
                    />
                    <circle
                        id="Ellipse_2"
                        cx="211.83"
                        cy="211.83"
                        r="211.83"
                        transform="translate(43.23 44.17)"
                        fill="#fff"
                        stroke="#717272"
                        strokeMiterlimit="10"
                        strokeWidth="3"
                    />
                    <path
                        id="Path_1"
                        d="M281.91,66.17,80.31,59.35a8,8,0,0,0-8.35,8V433.54a7.75,7.75,0,0,0,8.35,7.78l201.6-11.46a8.24,8.24,0,0,0,7.54-8.26V74.26A8,8,0,0,0,281.91,66.17Z"
                        fill="#717171"
                    />
                    <g id="Group_1" opacity="0.3">
                        <path
                            id="Path_2"
                            d="M199.19,336.59c-3.43-41.87,22.3-59.15,12.49-97.09-12.13-46.89-58.76-48.9-74.64-94.89-6.09-17.62-8.49-44.26,8.93-83L80.31,59.35a8,8,0,0,0-8.35,8V433.54a7.75,7.75,0,0,0,8.35,7.78L260.07,431.1C214.33,395.69,201.3,362.33,199.19,336.59Z"
                            fill="#d8dee8"
                        />
                    </g>
                    <path
                        id="Path_3"
                        d="M281.91,70.8,80.31,64.21a3.28,3.28,0,0,0-3.43,3.27V433.27a3.2,3.2,0,0,0,3.43,3.2l201.6-11.23a3.39,3.39,0,0,0,3.1-3.4V74.12a3.28,3.28,0,0,0-3.1-3.32Z"
                        opacity="0.3"
                        fill="url(#linear-gradient)"
                    />
                    <path
                        id="Path_4"
                        d="M243.83,347.71l-122.32,4.41a5.46,5.46,0,0,0-5.23,5.41h0a5,5,0,0,0,5.23,5l122.32-4.71a5.23,5.23,0,0,0,4.92-5.25h0a4.78,4.78,0,0,0-4.92-4.86Z"
                        opacity="0.6"
                        fill="url(#linear-gradient-2)"
                    />
                    <path
                        id="Path_5"
                        d="M243.83,365.75l-122.32,5a5.48,5.48,0,0,0-5.23,5.43h0a5,5,0,0,0,5.23,5l122.32-5.26a5.25,5.25,0,0,0,4.92-5.27h0a4.76,4.76,0,0,0-4.92-4.9Z"
                        opacity="0.6"
                        fill="url(#linear-gradient-3)"
                    />
                    <path
                        id="Path_6"
                        d="M243.83,383.79l-122.32,5.5a5.52,5.52,0,0,0-5.23,5.46h0a4.94,4.94,0,0,0,5.23,5l122.32-5.81a5.28,5.28,0,0,0,4.92-5.29h0a4.73,4.73,0,0,0-4.92-4.86Z"
                        opacity="0.6"
                        fill="url(#linear-gradient-4)"
                    />
                    <path
                        id="Path_7"
                        d="M180.4,85.44,118.87,83.7a2.5,2.5,0,0,0-2.59,2.51h0a2.68,2.68,0,0,0,2.59,2.66l61.53,1.66a2.44,2.44,0,0,0,2.51-2.47h0a2.61,2.61,0,0,0-2.51-2.62Z"
                        opacity="0.6"
                        fill="url(#linear-gradient-5)"
                    />
                    <path
                        id="Path_8"
                        d="M180.4,94.51l-61.53-1.6a2.5,2.5,0,0,0-2.59,2.51h0a2.66,2.66,0,0,0,2.59,2.65L180.4,99.6a2.44,2.44,0,0,0,2.51-2.48h0a2.61,2.61,0,0,0-2.51-2.61Z"
                        opacity="0.6"
                        fill="url(#linear-gradient-6)"
                    />
                    <path
                        id="Path_9"
                        d="M180.4,103.58l-61.53-1.47a2.52,2.52,0,0,0-2.59,2.52h0a2.66,2.66,0,0,0,2.59,2.65l61.53,1.39a2.45,2.45,0,0,0,2.51-2.48h0A2.61,2.61,0,0,0,180.4,103.58Z"
                        opacity="0.6"
                        fill="url(#linear-gradient-7)"
                    />
                    <path
                        id="Path_10"
                        d="M199.66,336.58c-3.43-41.87,22.28-59.15,12.48-97.08-12.12-46.89-58.75-48.9-74.62-94.88-5.84-16.89-8.29-42.07,6.88-78.32L80.8,64.22a3.29,3.29,0,0,0-3.43,3.28V433.24a3.2,3.2,0,0,0,3.43,3.2l174.26-9.71C213.74,392.93,201.68,361.25,199.66,336.58Z"
                        fill="#e4e4e4"
                    />
                    <path
                        id="Path_11"
                        d="M239,432.9c-13-.22-24.75-30.27-25.78-54.83-.39-9.5-.79-18.95,4.7-28.47,9.27-16.05,23.49-11.84,31.8-24.92,12.27-19.31-10.2-41.94,2-58,9-11.8,24-3.29,34.86-16.26,12.63-15.05.3-35.87,12-43.46,6.79-4.41,12.74,1.46,32.28-3,7.65-1.74,11.47-2.61,14.68-5.28,11.24-9.39,2.64-25.37,11-37.26,9.16-13.09,33.33-13.38,48.36-9.25,26.95,7.39,42.66,33.9,46.51,56.32.83,4.8,5.71,36.16-14.21,56.23-14.48,14.6-36.52,17.64-40.09,18.13-13.92,1.91-17.33-1.69-21.81,2-12.95,10.63,12.44,43.23-2.25,68.79C367,364.09,355.16,371.79,344,374c-18,3.63-27.89-8.28-36.84-2.29-11.59,7.76-.25,31.15-12.82,40.5-8.89,6.61-17.94-2.59-33.6,4.24C246.84,422.57,246.63,433,239,432.9Z"
                        fill="#e4e4e4"
                    />
                    <path
                        id="Path_12"
                        d="M236.14,410c6,.54,6.64-15.59,23.13-39.17,18-25.76,30-24.76,46.87-48,19.26-26.57,7.47-33.12,23.84-53.92,22.18-28.19,46.15-19.07,72.95-52.2,8-9.94,18.69-23.5,14.46-37.46-3.67-12.11-18.05-22.94-32.12-20.79-.59.09-12.4,2.08-18.54,12.34-7.54,12.59,2.21,23-5.07,32.75-3.81,5.13-10.75,6.72-24.62,9.92-20,4.59-24,.84-28.93,5.38-11.19,10.26,5.13,33.26-5.94,45.56-9.5,10.55-27.19-.13-35.4,9.85-9.92,12,11.62,32.72,2.41,51.7-7.92,16.34-28.39,10.3-39.75,27.85C216,374.5,227.51,409.24,236.14,410Z"
                        fill="#f5f6f7"
                    />
                    <path
                        id="Path_13"
                        d="M323.26,407.15c9.25-.22,8.91-17.24,24.49-23.81,17.23-7.27,30.07,8.31,41.84.79,13.23-8.46,3.11-32,17.86-42.6,10.08-7.24,18.37,1.2,31.24-5.78C452.11,328.47,464,308,456.81,289.7c-5.95-15.28-23-23.7-36.05-22.57-10.63.92-13,7.76-22.52,6.71-11.81-1.32-12.58-12.44-25.19-16.29s-28.65,2-36.81,12.74c-6.78,8.91-6.59,19.24-6.42,28.25.46,24.19,12.29,32.41,7.31,42.19-6.22,12.21-27.15,4.33-35.2,16.61C291.86,372.7,310,407.47,323.26,407.15Z"
                        fill="#e4e4e4"
                    />
                    <path
                        id="Path_14"
                        d="M317.13,389.9c3.43.35,3.39-10.68,13.1-20.82,11.14-11.62,20.77-7.11,33.56-16.66,15.84-11.83,8.23-24.13,22.22-35.94,19.6-16.56,47.16-3.06,53.08-15.62,3.46-7.35-2.35-19.59-10.16-23-8.34-3.66-13.16,5.24-26.95,4.54-12.9-.66-14.39-8.76-30.49-11.39-7.85-1.28-18.16-3-24.12,2.58-11.69,10.84-1.61,44.66,1.47,55,2.83,9.5,5,13.09,2.82,17.27-5.63,10.94-28.52,2.35-37.59,14.46C306.48,370.39,312.7,389.44,317.13,389.9Z"
                        fill="#f5f6f7"
                    />
                    <path
                        id="Path_15"
                        d="M318.55,58.32l-210.2-7.11a8.32,8.32,0,0,0-8.71,8.32V441.35a8.09,8.09,0,0,0,8.71,8.11l210.2-11.95a8.59,8.59,0,0,0,7.86-8.61V66.76a8.36,8.36,0,0,0-7.86-8.44Z"
                        fill="#e4e4e4"
                    />
                    <g id="Group_2" opacity="0.3">
                        <path
                            id="Path_16"
                            d="M232.3,340.27c-3.57-43.66,23.25-61.68,13-101.23-12.64-48.9-61.26-51-77.82-98.94-6.34-18.37-8.85-46.14,9.31-86.57l-68.46-2.32a8.32,8.32,0,0,0-8.71,8.32V441.35a8.09,8.09,0,0,0,8.71,8.11l187.42-10.65C248.09,401.88,234.5,367.1,232.3,340.27Z"
                            fill="#d8dee8"
                        />
                    </g>
                    <path
                        id="Path_17"
                        d="M318.55,63.15l-210.2-6.88a3.42,3.42,0,0,0-3.57,3.42V441.07a3.33,3.33,0,0,0,3.57,3.33l210.2-11.71a3.54,3.54,0,0,0,3.23-3.54V66.61a3.43,3.43,0,0,0-3.23-3.46Z"
                        fill="url(#linear-gradient-8)"
                    />
                    <path
                        id="Path_18"
                        d="M278.85,351.86l-127.54,4.6a5.69,5.69,0,0,0-5.46,5.64h0a5.22,5.22,0,0,0,5.46,5.24l127.54-4.92A5.45,5.45,0,0,0,284,357h0a5,5,0,0,0-5.15-5.14Z"
                        fill="#e4e4e4"
                    />
                    <path
                        id="Path_19"
                        d="M278.85,370.67l-127.54,5.17a5.73,5.73,0,0,0-5.46,5.66h0a5.19,5.19,0,0,0,5.46,5.22l127.54-5.49a5.47,5.47,0,0,0,5.12-5.49h0a5,5,0,0,0-5.12-5.07Z"
                        fill="#e4e4e4"
                    />
                    <path
                        id="Path_20"
                        d="M278.85,389.48l-127.54,5.74a5.76,5.76,0,0,0-5.46,5.69h0a5.16,5.16,0,0,0,5.46,5.19L278.85,400a5.5,5.5,0,0,0,5.12-5.51h0a4.93,4.93,0,0,0-5.12-5.01Z"
                        fill="#e4e4e4"
                    />
                    <path
                        id="Path_21"
                        d="M212.71,78.41,148.55,76.6a2.6,2.6,0,0,0-2.7,2.62h0a2.79,2.79,0,0,0,2.7,2.76l64.16,1.74a2.55,2.55,0,0,0,2.62-2.58h0a2.73,2.73,0,0,0-2.62-2.73Z"
                        fill="#e4e4e4"
                    />
                    <path
                        id="Path_22"
                        d="M212.71,87.87l-64.16-1.68a2.62,2.62,0,0,0-2.7,2.63h0a2.77,2.77,0,0,0,2.7,2.76l64.16,1.6a2.56,2.56,0,0,0,2.62-2.59h0a2.72,2.72,0,0,0-2.62-2.72Z"
                        fill="#e4e4e4"
                    />
                    <path
                        id="Path_23"
                        d="M212.71,97.33l-64.16-1.54a2.62,2.62,0,0,0-2.7,2.63h0a2.77,2.77,0,0,0,2.7,2.76l64.16,1.46a2.57,2.57,0,0,0,2.62-2.6h0A2.71,2.71,0,0,0,212.71,97.33Z"
                        fill="#e4e4e4"
                    />
                    <path
                        id="Path_24"
                        d="M188,198.9a27.44,27.44,0,0,1,21.09-14.52l.64-38.94a65.89,65.89,0,0,0-58.51,39.74Z"
                        fill="#717171"
                    />
                    <path
                        id="Path_25"
                        d="M239.05,216.61a27.57,27.57,0,0,1-26.77,23,26.92,26.92,0,0,1-11.17-2.31L180.7,270.84a64.58,64.58,0,0,0,31.58,7.53c33.85-.62,61.27-27.1,64.36-60.34Z"
                        fill="#717171"
                    />
                    <path
                        id="Path_26"
                        d="M174.4,267l20.46-33.57a27.93,27.93,0,0,1-9.43-27.58L148.61,192.2a67.291,67.291,0,0,0-3,19.89A66.4,66.4,0,0,0,174.4,267Z"
                        fill="#717171"
                    />
                    <path
                        id="Path_27"
                        d="M216.38,184.52a27.47,27.47,0,0,1,23,24.75l62.21,2.46v-.08c0-48.22-36.81-88.28-84.12-91.84Z"
                        fill="#e4e4e4"
                    />
                    <path
                        id="Path_28"
                        d="M232.79,340.25C229.21,296.6,256,278.58,245.8,239c-12.64-48.88-61.25-51-77.8-98.92-6.08-17.61-8.64-43.86,7.17-81.65l-66.31-2.17a3.42,3.42,0,0,0-3.57,3.42V441a3.32,3.32,0,0,0,3.57,3.33l181.69-10.12C247.47,399,234.89,366,232.79,340.25Z"
                        fill="#f5f6f7"
                        opacity="0.16"
                    />
                    <path
                        id="Path_29"
                        d="M162.49,161.71l-101.73-1a4.16,4.16,0,0,1-4.16-4.1v-38.7a4,4,0,0,1,4.16-4l88.56,2.11a4.05,4.05,0,0,1,3.94,4v33.25Z"
                        fill="#e4e4e4"
                    />
                    <path
                        id="Path_30"
                        d="M138.98,130.05l-67.37-1.31V124.1l67.37,1.41Z"
                        fill="#fff"
                        opacity="0.67"
                    />
                    <path
                        id="Path_31"
                        d="M138.98,139.14l-67.37-1.12v-4.64l67.37,1.22Z"
                        fill="#fff"
                        opacity="0.67"
                    />
                    <path
                        id="Path_32"
                        d="M138.98,148.23l-67.37-.94v-4.64l67.37,1.04Z"
                        fill="#fff"
                        opacity="0.67"
                    />
                    <path
                        id="Path_33"
                        d="M216.2,273.76l122.33-2.63a5.72,5.72,0,0,1,5.76,5.84v57a6.1,6.1,0,0,1-5.76,6.15L209.6,344.43a5.84,5.84,0,0,1-6-5.91V264.07Z"
                        fill="#e4e4e4"
                    />
                    <path
                        id="Path_34"
                        d="M225.33,321.98l98.08-2.93v6.88l-98.08,3.05Z"
                        fill="#fff"
                        opacity="0.67"
                    />
                    <path
                        id="Path_35"
                        d="M225.33,307.97l98.08-2.69v6.88l-98.08,2.82Z"
                        fill="#fff"
                        opacity="0.67"
                    />
                    <path
                        id="Path_36"
                        d="M225.33,293.97l98.08-2.46v6.88l-98.08,2.58Z"
                        fill="#fff"
                        opacity="0.67"
                    />
                    <path
                        id="Path_37"
                        d="M393.5,327.65l.67-2a5.79,5.79,0,0,0-3.89-7.4L264.73,280l-3.82,13,124.91,38.47A6.19,6.19,0,0,0,393.5,327.65Z"
                        fill="#e4e4e4"
                    />
                    <path
                        id="Path_38"
                        d="M140,244.91c9.6-37.92,47.22-60.78,84.42-50.06,37.88,10.9,59.57,52,48.06,90.64-11.24,37.79-49.78,58.17-85.75,46.51C151.35,320.55,130.59,282,140,244.91Zm121.55,37.23c9.52-32.38-8.63-66.67-40.27-75.88-31.16-9.06-62.81,10-71,41.81-8,31.23,9.48,63.78,39.33,73.37,30.28,9.73,62.59-7.55,71.91-39.3Z"
                        fill="#717171"
                    />
                    <path
                        id="Path_39"
                        d="M264.73,280l16,4.88c1.1,5.1,3.24,8,5.38,9.81,8.09,6.7,18.89-.61,33.95,7.25,7.56,3.95,6.12,6.46,13.21,9.21,11.71,4.55,17.07-1.75,27.44,2.39s11.52,13.24,17.47,12.91c2.54-.14,5.81-2,9.47-9l2.62.8a5.8,5.8,0,0,1,3.89,7.41l-.67,1.95a6.19,6.19,0,0,1-7.68,3.83L260.91,293Z"
                        fill="#717171"
                    />
                    <path
                        id="Path_40"
                        d="M186.64,326.63c31.33,10.08,64.77-7.77,74.45-40.62,9.87-33.53-8.93-69.06-41.7-78.58-32.26-9.36-65,10.36-73.44,43.31C137.69,283,155.78,316.7,186.64,326.63Z"
                        fill="#fff"
                        opacity="0.56"
                    />
                    <path
                        id="Path_41"
                        d="M137.54,249c9.6-37.59,47.22-60.24,84.42-49.62,37.88,10.81,59.57,51.49,48.06,89.83-11.24,37.45-49.79,57.65-85.75,46.09C148.92,323.93,128.16,285.7,137.54,249Zm121.55,36.89c9.51-32.09-8.64-66.07-40.27-75.2-31.16-9-62.81,9.88-71,41.43-8,31,9.48,63.21,39.33,72.72,30.31,9.62,62.62-7.51,71.94-38.97Z"
                        fill="#e4e4e4"
                    />
                    <path
                        id="Path_42"
                        d="M141.18,249.28c9.09-35.77,44.61-57.27,79.66-47.13,35.67,10.3,56.1,48.95,45.31,85.41-10.56,35.65-46.9,54.94-80.86,44C151.88,320.73,132.28,284.28,141.18,249.28ZM259.09,285.4c9.51-32.38-8.64-66.68-40.27-75.88-31.16-9.07-62.81,10-71,41.8-8,31.23,9.48,63.78,39.33,73.37C217.46,334.42,249.77,317.14,259.09,285.4Z"
                        fill="#f5f6f7"
                    />
                    <path
                        id="Path_43"
                        d="M263.55,90.43v72a4.32,4.32,0,0,0,4.32,4.33H405.65a3.9,3.9,0,0,0,3.75-4v-67Z"
                        fill="#f5f6f7"
                    />
                    <path
                        id="Path_44"
                        d="M405.65,87.09,267.87,81.47a4.11,4.11,0,0,0-4.32,4.15V93L409.4,98.13V91.27a4.1,4.1,0,0,0-3.75-4.18Z"
                        fill="#717171"
                    />
                    <path
                        id="Path_45"
                        d="M273.44,87.44a1.82,1.82,0,0,1-1.9,1.84,2,2,0,0,1-1.91-2,1.83,1.83,0,0,1,1.91-1.84A2,2,0,0,1,273.44,87.44Z"
                        fill="#fff"
                    />
                    <path
                        id="Path_46"
                        d="M293.18,157.41l-10.8-.05V103.83l10.8.32Z"
                        fill="#e4e4e4"
                    />
                    <path
                        id="Path_47"
                        d="M293.18,157.41l-10.8-.05V131.94l10.8.19Z"
                        fill="#717171"
                    />
                    <path
                        id="Path_48"
                        d="M308.03,157.48l-10.65-.05V104.28l10.65.32Z"
                        fill="#e4e4e4"
                    />
                    <path
                        id="Path_49"
                        d="M308.03,157.48l-10.65-.05V144.81l10.65.12Z"
                        fill="#717171"
                    />
                    <path
                        id="Path_50"
                        d="M322.67,157.54l-10.5-.04V104.73l10.5.32Z"
                        fill="#e4e4e4"
                    />
                    <path id="Path_51" d="M322.67,157.54l-10.5-.04v-6.58l10.5.08Z" fill="#717171" />
                    <path
                        id="Path_52"
                        d="M337.1,157.61l-10.35-.05V105.17l10.35.31Z"
                        fill="#e4e4e4"
                    />
                    <path
                        id="Path_53"
                        d="M337.1,157.61l-10.35-.05V116.52l10.35.26Z"
                        fill="#717171"
                    />
                    <path
                        id="Path_54"
                        d="M351.32,157.67l-10.2-.04V105.61l10.2.31Z"
                        fill="#e4e4e4"
                    />
                    <path
                        id="Path_55"
                        d="M351.32,157.67l-10.2-.04V121.51l10.2.23Z"
                        fill="#717171"
                    />
                    <path
                        id="Path_56"
                        d="M365.35,157.74l-10.06-.05V106.04l10.06.3Z"
                        fill="#e4e4e4"
                    />
                    <path
                        id="Path_57"
                        d="M365.35,157.74l-10.06-.05V113.55l10.06.26Z"
                        fill="#717171"
                    />
                    <path id="Path_58" d="M379.18,157.8l-9.92-.05V106.46l9.92.3Z" fill="#e4e4e4" />
                    <path id="Path_59" d="M379.18,157.8l-9.92-.05v-31.5l9.92.2Z" fill="#717171" />
                    <path
                        id="Path_60"
                        d="M392.82,157.86l-9.78-.04V106.88l9.78.29Z"
                        fill="#e4e4e4"
                    />
                    <path
                        id="Path_61"
                        d="M392.82,157.86l-9.78-.04V140.13l9.78.13Z"
                        fill="#717171"
                    />
                    <circle
                        id="Ellipse_3"
                        cx="7.17"
                        cy="7.17"
                        r="7.17"
                        transform="translate(411.18 422.11)"
                        fill="#fff"
                        stroke="#e4e4e4"
                        strokeMiterlimit="10"
                        strokeWidth="2"
                    />
                    <path
                        id="Path_62"
                        d="M466.3,390.5h-9.86v-9.93a1.24,1.24,0,0,0-1.24-1.24,1.27,1.27,0,0,0-1.27,1.24v9.93h-10a1.26,1.26,0,0,0-1.24,1.26,1.24,1.24,0,0,0,1.24,1.24h9.93v10a1.26,1.26,0,0,0,1.26,1.24,1.23,1.23,0,0,0,1.24-1.24V393h9.95a1.23,1.23,0,0,0,1.24-1.24,1.26,1.26,0,0,0-1.25-1.26Z"
                        fill="#e4e4e4"
                        stroke="#e4e4e4"
                        strokeMiterlimit="10"
                        strokeWidth="0.75"
                    />
                    <circle
                        id="Ellipse_4"
                        cx="10.75"
                        cy="10.75"
                        r="10.75"
                        transform="translate(39.17 79.79)"
                        fill="#fff"
                        stroke="#e4e4e4"
                        strokeMiterlimit="10"
                        strokeWidth="2"
                    />
                    <path
                        id="Path_63"
                        d="M145.06,30h-8.69V21.29a1.08,1.08,0,0,0-1.08-1.08h0a1.11,1.11,0,0,0-1.11,1.08V30h-8.66a1.1,1.1,0,1,0,0,2.19h8.66v8.69a1.11,1.11,0,0,0,1.11,1.08,1.08,1.08,0,0,0,1.08-1.08h0V32.16h8.69a1.1,1.1,0,0,0,0-2.19Z"
                        fill="#e4e4e4"
                        stroke="#e4e4e4"
                        strokeMiterlimit="10"
                        strokeWidth="0.75"
                    />
                    <circle
                        id="Ellipse_5"
                        cx="10.92"
                        cy="10.92"
                        r="10.92"
                        transform="translate(434.92 101.29)"
                        fill="#fff"
                        stroke="#e4e4e4"
                        strokeMiterlimit="10"
                        strokeWidth="2"
                    />
                    <path
                        id="Path_64"
                        d="M401.05,58.42h-7.32V51.11a.91.91,0,0,0-.91-.91h0a.93.93,0,0,0-.93.91v7.31h-7.3a.92.92,0,1,0,0,1.84h7.3v7.32a.93.93,0,0,0,.93.91.91.91,0,0,0,.91-.91h0V60.26h7.32a.92.92,0,0,0,.92-.91.93.93,0,0,0-.92-.93Z"
                        fill="#e4e4e4"
                        stroke="#e4e4e4"
                        strokeMiterlimit="10"
                        strokeWidth="0.75"
                    />
                </g>
            </g>
        </SVGIcon>
    );
}
