import React from 'react';

import { SVGIcon } from '../../UIKit/index';

export default function DCFillSMS(props) {
    return (
        <SVGIcon {...props} viewBox="0 0 512 512">
            <path d="M402.4,67.4c38.6,5.6,42.2,39.5,42.2,39.5v226.7c-4.5,33.4-39.6,40.5-39.6,40.5l-113.5,0.3l-93.1,69.4  c-10.1,3.6-13.6-6.2-13.6-6.2v-64.5h-79.1c-34.1-4.9-38.3-42.5-38.3-42.5l0.8-224.3C74.4,71.5,111,67.4,111,67.4H402.4z" />
        </SVGIcon>
    );
}
