import React from 'react';
import PropTypes from 'prop-types';
export default function Span(props) {
    const { children, className, onClick, onMouseOver, onMouseOut, style, reference, ...rest } =
        props;
    return (
        <span
            className={className}
            onClick={onClick}
            onMouseOut={onMouseOut}
            onMouseOver={onMouseOver}
            style={style}
            ref={reference}
            {...rest}
        >
            {children}
        </span>
    );
}
Span.propTypes = {
    children: PropTypes.any,
    className: PropTypes.string,
    onClick: PropTypes.func,
    onMouseOver: PropTypes.func,
    onMouseOut: PropTypes.func,
    style: PropTypes.object,
    reference: PropTypes.object,
    props: PropTypes.any,
};
