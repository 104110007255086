import React, { useRef, useState, useLayoutEffect } from 'react';
import PropTypes from 'prop-types';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';

import { Div } from '../index';

import './style.scss';

const AreaChart = ({ height, chartData, backgroundColor, lineColor, xAxis, yAxis, xAxisWidth }) => {
    const boxWidthRef = useRef();
    const [boxWidth, setWidth] = useState(null);
    const ChartOptions = {
        chart: {
            height: height || 200,
            width: boxWidth,
            type: 'area',
            spacingTop: 0,
            spacingRight: 0,
            spacingBottom: 0,
            spacingLeft: 0,
            plotBorderWidth: 0,
            marginTop: 20,
            backgroundColor: backgroundColor || '#fff',
            // styledMode: true,
        },
        tooltip: {
            backgroundColor: '#ffffff',
            opacity: 1,
        },
        title: {
            text: null,
        },
        subtitle: {
            text: null,
        },
        caption: {
            text: null,
        },
        plotOptions: {
            series: {
                shadow: {
                    enabled: true,
                },
                fillOpacity: 0.1,
                lineWidth: 3,
                pointPlacement: 'on',
                color: lineColor,
                marker: {
                    enabled: false,
                },
            },
        },
        navigator: {
            enabled: false,
        },
        yAxis: yAxis
            ? yAxis
            : {
                  visible: false,
              },
        xAxis: xAxis
            ? xAxis
            : {
                  visible: false,
              },
        credits: {
            enabled: false,
        },
        series: chartData || [],
    };

    useLayoutEffect(() => {
        /**
         * To measure box height, Using ref [useRef hooks] object to measure height and
         * setting it into states which is being used to set height of infinite scroll bar
         */
        function updateSize() {
            if (boxWidthRef.current) {
                const tempWidth = xAxis?.categories?.length * xAxisWidth || 150;
                if (tempWidth > boxWidthRef.current.clientWidth) {
                    setWidth(tempWidth);
                } else {
                    setWidth(boxWidthRef.current.clientWidth);
                }
            }
        }
        window.addEventListener('resize', updateSize);
        updateSize();
        return () => window.removeEventListener('resize', updateSize);
    }, [chartData, xAxis?.categories, xAxisWidth]);

    return (
        <Div className={'AreaChart'} reference={boxWidthRef}>
            <Div className={'AreaChartInner'} style={{ width: boxWidth }}>
                <HighchartsReact highcharts={Highcharts} options={ChartOptions} />
            </Div>
        </Div>
    );
};

AreaChart.propTypes = {
    height: PropTypes.number,
    chartData: PropTypes.arrayOf(PropTypes.object).isRequired,
    backgroundColor: PropTypes.string,
    lineColor: PropTypes.string,
    xAxis: PropTypes.object,
    yAxis: PropTypes.object,
    xAxisWidth: PropTypes.number,
};

export default AreaChart;
