import React from 'react';

import { SVGIcon } from '../../UIKit/index';

export default function DCVoiceMessage(props) {
    return (
        <SVGIcon {...props} viewBox="0 0 495.4 512">
            <g transform="translate(-8)">
                <g transform="translate(8)">
                    <g transform="translate(0)">
                        <path d="M429.7,1.5H68C31.7,1.5,2.3,30.9,2.2,67.2v295.8c0,36.3,29.4,65.8,65.8,65.8v65.8     c0,6.4,3.7,12.2,9.5,14.9c2.2,1,4.5,1.5,6.9,1.4c3.8,0,7.6-1.4,10.5-3.8l94.2-78.3h240.6c36.3,0,65.7-29.5,65.8-65.8V67.2     C495.4,30.9,466,1.5,429.7,1.5z M462.5,363.1c0,18.2-14.7,32.9-32.9,32.9H183.1c-3.8,0-7.6,1.4-10.5,3.8l-71.7,59.7v-47.1     c0-9.1-7.4-16.4-16.4-16.4h0H68c-18.1,0-32.8-14.7-32.9-32.9V67.2c0-18.1,14.7-32.8,32.9-32.9h361.6c18.1,0,32.8,14.7,32.9,32.9     V363.1z" />
                    </g>
                </g>
                <g transform="translate(11.484 4.645)">
                    <g>
                        <path d="M327.5,128.3c-36.3,0-65.7,29.5-65.8,65.8c0.1,11.6,3.2,22.9,9.1,32.9h-51.1     c5.9-9.9,9.1-21.3,9.1-32.9c0-36.3-29.4-65.8-65.8-65.8c-36.3,0-65.8,29.4-65.8,65.8c0,36.3,29.4,65.8,65.8,65.8c0,0,0,0,0,0     h164.3c36.3,0.8,66.4-28.1,67.1-64.4c0.8-36.3-28.1-66.4-64.4-67.1C329.3,128.3,328.4,128.3,327.5,128.3L327.5,128.3z      M163.1,226.9c-18.2,0-32.9-14.7-32.9-32.9c0-18.1,14.7-32.9,32.9-32.9c18.2,0,32.9,14.7,32.9,32.9l0,0     C196,212.2,181.3,226.9,163.1,226.9z M327.5,226.9c-18.2,0-32.9-14.7-32.9-32.9c0-18.1,14.7-32.9,32.9-32.9     c18.2,0,32.9,14.7,32.9,32.9l0,0C360.3,212.2,345.6,226.9,327.5,226.9z" />
                    </g>
                </g>
            </g>
        </SVGIcon>
    );
}
