import React, { Fragment, useRef, useState } from 'react';

import { Div, Popover, Span } from '../index';
import PropTypes from 'prop-types';
import './style.scss';

const TextOverflowTooltip = ({ children, tooltipContent }) => {
    const buttonRef = useRef();
    const [toolTipOpen, setToolTipOpen] = useState(false);

    const handleTooltipOpen = e => {
        if (e) {
            if (e.target.offsetWidth < e.target.scrollWidth) {
                setToolTipOpen(true);
            } else {
                setToolTipOpen(false);
            }
        }
    };

    const handleTooltipClose = () => {
        setToolTipOpen(false);
    };

    return (
        <Fragment>
            <Popover
                customClass={'TextOverflowTooltipPopover'}
                open={toolTipOpen}
                anchorEl={buttonRef}
                onClose={handleTooltipClose}
                withOutPadding
                width={'auto'}
            >
                <Div className={'TOFTPWrapper '}>
                    <Div className={'TOFTPWInner'}>{tooltipContent}</Div>
                </Div>
            </Popover>
            <Span
                reference={buttonRef}
                onMouseOver={handleTooltipOpen}
                onMouseOut={handleTooltipClose}
                className={'TOFTPWText'}
            >
                {children}
            </Span>
        </Fragment>
    );
};

TextOverflowTooltip.propTypes = {
    children: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
    tooltipContent: PropTypes.string,
};

export default TextOverflowTooltip;
