import React from 'react';

import { SVGIcon } from '../../UIKit/index';

export default function DCOverviewReport(props) {
    return (
        <SVGIcon {...props} viewBox="0 0 512 462">
            <g transform="translate(-16.316 -23.707)">
                <path fill="#005EFF" d="M270.2,244.11h92v241.81h-92V244.11z" />
                <path fill="#005EFF" d="M143.86,204.54h92v281.38h-92V204.54z" />
                <path fill="#005EFF" d="M396.54,204.54h92v281.38h-92V204.54z" />
                <path fill="#005EFF" d="M17.53,244.11h92v241.82h-92V244.11z" />
                <path fill="#D9EAFF" d="M347.66,244.11h18.19v241.81h-18.19V244.11z" />
                <path fill="#D9EAFF" d="M221.32,204.54h18.19v281.38h-18.19V204.54z" />
                <path fill="#D9EAFF" d="M473.99,204.54h18.19v281.38h-18.19V204.54z" />
                <path fill="#D9EAFF" d="M94.99,244.11h18.19v241.82H94.99V244.11z" />
                <g transform="translate(50.423 24.103)">
                    <circle fill="#D9EAFF" cx="443.02" cy="73.99" r="29.19" />
                    <path
                        fill="#6B8AAA"
                        d="M425.89,142.65l-68.28-35.83V41.18l68.28-35.83V142.65z"
                    />
                    <path fill="#D9EAFF" d="M425.86,5.34h17.16V142.6h-17.16V5.34z" />
                    <path fill="#D9EAFF" d="M300.16,41.18h57.43v65.62h-57.43V41.18z" />
                    <path fill="#D9EAFF" d="M277.37,57.56h22.74v32.83h-22.74V57.56z" />
                    <path fill="#D9EAFF" d="M316.2,106.8h25.33v51.38H316.2V106.8z" />
                </g>
                <g transform="translate(16.878 23.707)">
                    <path
                        fill="none"
                        d="M16.57,202.69c5.97,0,10.81-4.84,10.81-10.81c-0.01-1.15-0.21-2.3-0.59-3.38l121.89-95.46    c3.59,2.37,8.24,2.37,11.83,0l121.9,95.42c-1.9,5.65,1.13,11.77,6.78,13.67s11.77-1.13,13.67-6.78    c1.88-5.59-1.07-11.66-6.64-13.62V77.99h11.14v12.8c0,2.01,1.63,3.64,3.64,3.64l0,0h19.19v12.81c0,2.01,1.63,3.64,3.64,3.64l0,0    h12.34v47.67c0,2.01,1.63,3.64,3.64,3.64l0,0h25.33c2.01,0,3.64-1.63,3.64-3.64v0V110.8h11.52l67.44,35.39l0,0    c0.51,0.28,1.09,0.43,1.67,0.43h17.16c2.01,0,3.64-1.63,3.64-3.64v0v-36.03c17.98-1.93,31-18.07,29.07-36.05    c-1.64-15.32-13.75-27.43-29.07-29.07V5.71c0-2.01-1.63-3.64-3.64-3.64l0,0H459.4c-0.58,0-1.16,0.15-1.67,0.43l0,0l-67.46,35.47    H333.7c-2.01,0-3.64,1.63-3.64,3.64c0,0,0,0,0,0v12.78h-19.15c-2.01,0-3.64,1.63-3.64,3.64c0,0,0,0,0,0v12.83h-14.71    c-2.01,0-3.64,1.63-3.64,3.64c0,0,0,0,0,0v107.34c-0.76,0.27-1.5,0.62-2.18,1.05L164.77,87.37c1.9-5.66-1.14-11.79-6.8-13.69    c-5.66-1.9-11.79,1.14-13.69,6.8c-0.75,2.24-0.75,4.66,0,6.89L22.35,182.88c-5.02-3.21-11.7-1.74-14.91,3.28    c-3.21,5.02-1.74,11.7,3.28,14.91c1.74,1.11,3.76,1.7,5.82,1.7L16.57,202.69z M502.15,74.38c0,12.74-9.38,23.53-21.99,25.33V49.08    C492.75,50.91,502.09,61.67,502.15,74.38z M463.04,9.35h9.95v130.08h-9.95V9.35z M371.51,154.9h-18.16v-44.1h18.12L371.51,154.9z     M455.83,137.01l-61.11-32.02V43.75l61.04-32.05L455.83,137.01z M337.33,45.13h50.2v58.46H337.3V45.17L337.33,45.13z M314.59,61.5    h15.58v25.62h-15.66L314.59,61.5z"
                    />
                    <path fill="none" d="M58.97,224.02L58.97,224.02z" />
                </g>
            </g>
        </SVGIcon>
    );
}
