import React from 'react';
import PropTypes from 'prop-types';
export default function Link(props) {
    const { href, children, className, target, ...rest } = props;
    return (
        <a target={target} className={className} {...rest} href={href} rel="noreferrer">
            {children}
        </a>
    );
}

Link.propTypes = {
    href: PropTypes.string,
    children: PropTypes.any,
    className: PropTypes.string,
    target: PropTypes.string,
};
