import React from 'react';
import DCNoGroup from 'Assets/icons/DCNoGroup';
import { Div } from '../../UIKit';
import './style.scss';
function AccessDenied({ client = false }) {
    return (
        <Div className={'AccessDenied'}>
            <Div className={'AccessDeniedInner'}>
                <Div className={'ADIIcon'}>
                    <DCNoGroup />
                </Div>
                <Div className={'ADITitle'}>
                    {client
                        ? 'No Projects Found'
                        : "You don't have the permissions needed to access this page"}
                    <br />
                    {!client && 'please contact the account owner.'}
                </Div>
            </Div>
        </Div>
    );
}

AccessDenied.propTypes = {};
export default AccessDenied;
