import React from 'react';

import { SVGIcon } from '../../UIKit/index';

export default function DCCheckout(props) {
    return (
        <SVGIcon {...props} viewBox="0 0 512.003 512.003">
            <g>
                <circle cx="226" cy="436.002" r="45" />
                <circle cx="377" cy="436.002" r="45" />
                <path d="m15 61.002h62.553l72.418 253.513-5.68 11.36c-14.956 29.88 6.755 65.127 40.254 65.127h252.455c8.291 0 15-6.709 15-15s-6.709-15-15-15h-252.455c-11.139 0-18.419-11.729-13.418-21.709l4.146-8.291h261.727c6.694 0 12.583-4.438 14.429-10.884l60-210c1.289-4.526.381-9.39-2.446-13.154-2.842-3.75-7.28-5.962-11.982-5.962h-379.678l-14.033-49.117c-1.846-6.445-7.734-10.884-14.429-10.884h-73.861c-8.291 0-15 6.709-15 15s6.709 15.001 15 15.001z" />
            </g>
        </SVGIcon>
    );
}
