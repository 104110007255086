import React, { useCallback, useEffect, useRef, useState } from 'react';

import { enGB } from 'date-fns/locale';
import dayjs from 'dayjs';
import PropTypes from 'prop-types';

import { Button, Div, DropdownOptions, List, ListItem, Popover } from 'UIKit/index';

import { getUserTimeZone, timeInUserTimeZone } from 'Utils/dateUtils';
import OnlyDatePickerCalendar from '../DatePicker/OnlyDatePickerCalendar';

import 'react-nice-dates/build/style.css';

import './style.scss';
var objectSupport = require('dayjs/plugin/objectSupport');

dayjs.extend(objectSupport);

const months = [
    {
        id: 0,
        title: 'January',
        value: 0,
    },
    {
        id: 1,
        title: 'February',
        value: 1,
    },
    {
        id: 2,
        title: 'March',
        value: 2,
    },
    {
        id: 3,
        title: 'April',
        value: 3,
    },
    {
        id: 4,
        title: 'May',
        value: 4,
    },
    {
        id: 5,
        title: 'June',
        value: 5,
    },
    {
        id: 6,
        title: 'July',
        value: 6,
    },
    {
        id: 7,
        title: 'August',
        value: 7,
    },
    {
        id: 8,
        title: 'September',
        value: 8,
    },
    {
        id: 9,
        title: 'October',
        value: 9,
    },
    {
        id: 10,
        title: 'November',
        value: 10,
    },
    {
        id: 11,
        title: 'December',
        value: 11,
    },
];
const hoursArray = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
const minutesArray = [
    0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25,
    26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49,
    50, 51, 52, 53, 54, 55, 56, 57, 58, 59,
];
const timeFormateArray = ['AM', 'PM'];

function generateArrayOfYears() {
    var max = new Date().getFullYear();
    var min = max - 100;
    var years = [];

    for (var i = max; i >= min; i--) {
        years.push(i);
    }
    return years;
}
function DateTimePicker({
    value,
    onChange,
    disabled,
    readOnly,
    autoFocus,
    placement,
    width,
    placeholder,
    resetVal,
}) {
    const firstRender = useRef(true);
    const [datetime, setDatetime] = useState(timeInUserTimeZone(value));
    const inputRef = useRef();
    const [popoverWidth, setPopoverWidth] = useState(0);
    const [datePickerCalendar, setDatePickerCalendar] = useState(false);
    const [month, setMonth] = useState(
        value ? months[dayjs(value).month()] : months[dayjs().tz(getUserTimeZone()).month()],
    );
    const years = generateArrayOfYears();
    const [year, setYear] = useState(
        value ? dayjs(value).tz(getUserTimeZone()).year() : dayjs().tz(getUserTimeZone()).year(),
    );
    const monthButtonRef = useRef();
    const yearButtonRef = useRef();
    const [openMonthDropdown, setOpenMonthDropdown] = useState(false);
    const [openYearDropdown, setOpenYearDropdown] = useState(false);
    const [showCalendar, setShowCalendar] = useState(true);

    useEffect(() => {
        if (resetVal) {
            setDatetime(timeInUserTimeZone(value));
        }
    }, [resetVal, value]);
    const getValue = useCallback(() => {
        if (value?.length)
            return dayjs(datetime).tz(getUserTimeZone()).format('dddd MMMM D, YYYY, hh:mm a');
        else return null;
    }, [datetime, value]);

    const modifiersClassNames = {
        highlight: '-highlight',
    };

    const handleDatePickerCalendarToggle = () => {
        if (!readOnly) {
            setDatePickerCalendar(prevOpen => !prevOpen);
            setPopoverWidth(inputRef.current.offsetWidth);
        }
    };

    const handleDatePickerCalendarClose = () => {
        setDatePickerCalendar(false);
    };

    const handleDatePicker = date => {
        const oldDate = dayjs(datetime);

        const temp = dayjs(date);
        let newDate = dayjs({
            year: temp.year(),
            month: temp.month(),
            date: temp.date(),
            hour: oldDate.hour(),
            minute: oldDate.minute(),
            second: oldDate.second(),
        }).tz(getUserTimeZone());
        setDatetime(newDate.format());
        setMonth(months[newDate.month()]);
        setYear(newDate.year());
        setDatePickerCalendar(false);
        onChange && onChange(newDate.format());
    };

    const handleMonthDropdownToggle = useCallback(() => {
        setOpenMonthDropdown(prev => !prev);
    }, []);

    const handleYearDropdownToggle = useCallback(() => {
        setOpenYearDropdown(prev => !prev);
    }, []);

    // Generate options for month dropdown
    const generateMonthOptions = useCallback(() => {
        return months.map(item => {
            return {
                label: (
                    <Button
                        buttonClass={`DropdownOptionsBtn ${month.id === item.id ? ' active' : ''}`}
                        onClick={() => {
                            setMonth(item);
                            setOpenMonthDropdown(false);
                        }}
                    >
                        {item.title}
                    </Button>
                ),
            };
        });
    }, [month]);

    // Generate options for year dropdown
    const generateYearOptions = useCallback(() => {
        return years.map(item => {
            return {
                label: (
                    <Button
                        buttonClass={`DropdownOptionsBtn ${year === item ? ' active' : ''}`}
                        onClick={() => {
                            setYear(item);
                            setOpenYearDropdown(false);
                        }}
                    >
                        {item}
                    </Button>
                ),
            };
        });
    }, [year, years]);

    useEffect(() => {
        if (!firstRender.current) {
            let newDate = dayjs(datetime);
            if (month) newDate = newDate.month(month.value);
            if (year) newDate = newDate.year(year);
            setDatetime(newDate.format());
            setShowCalendar(false);
        } else firstRender.current = false;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [month, year]);

    useEffect(() => {
        if (!showCalendar) setShowCalendar(true);
    }, [showCalendar]);

    const handleSelectHours = selectHours => {
        let newDate = dayjs(datetime).tz(getUserTimeZone());
        if (newDate.hour() >= 12) {
            // PM is currently selected
            newDate = newDate.hour(selectHours + 12);
        } else {
            newDate = newDate.hour(selectHours);
        }
        setDatetime(newDate.format());
        onChange && onChange(newDate.format());
    };

    const handleSelectMinutes = selectMinutes => {
        let newDate = dayjs(datetime).tz(getUserTimeZone());
        newDate = newDate.minute(selectMinutes);
        setDatetime(newDate.format());
        onChange && onChange(newDate.format());
    };

    const handleSelectTimeFormate = timeFormate => {
        let newDate = dayjs(datetime).tz(getUserTimeZone());
        if (timeFormate === 'AM') {
            if (newDate.hour() === 12) {
                newDate = newDate.hour(0);
            } else if (newDate.hour() > 12) {
                newDate = newDate.hour(newDate.hour() - 12);
            }
        } else {
            if (newDate.hour() < 12) {
                newDate = newDate.hour(12 + newDate.hour());
            }
        }
        setDatetime(newDate.format());
        onChange && onChange(newDate.format());
    };

    return (
        <Div className={'DateTimePicker'}>
            <Div className={'DateTimePickerInput ' + (datePickerCalendar === true ? 'active' : '')}>
                <input
                    type="text"
                    value={getValue() || ''}
                    onChange={setDatetime}
                    locale={enGB}
                    ref={inputRef}
                    disabled={disabled}
                    readOnly={readOnly}
                    autoFocus={autoFocus}
                    onClick={handleDatePickerCalendarToggle}
                    autoComplete="off"
                    placeholder={placeholder || 'Select Date & Time'}
                />
            </Div>
            <Popover
                open={datePickerCalendar}
                anchorEl={inputRef}
                onClose={handleDatePickerCalendarClose}
                width={width ? width : popoverWidth || 400}
                placement={placement || 'bottom-start'}
                withOutPadding
            >
                <Div className="DateTimePickerPopover">
                    <Div>
                        <Div className={'DPMonthYearContainer'}>
                            <Div className={'DPMYCMonth DCDropdownSelect fullWidth'}>
                                <Button
                                    buttonClass={
                                        'DCDSMainBtn ' + (openMonthDropdown === true ? 'focus' : '')
                                    }
                                    reference={monthButtonRef}
                                    onClick={handleMonthDropdownToggle}
                                >
                                    {month?.title || 'Select Month'}
                                </Button>
                                <DropdownOptions
                                    options={generateMonthOptions()}
                                    open={openMonthDropdown}
                                    wrapperWidth={monthButtonRef.current?.offsetWidth}
                                    onClose={() => setOpenMonthDropdown(false)}
                                    reference={monthButtonRef}
                                    viewHeight={200}
                                    itemSize={40}
                                    defaultPlacement={'bottom-start'}
                                />
                            </Div>

                            <Div className={'DPMYCMonth DCDropdownSelect fullWidth'}>
                                <Button
                                    buttonClass={
                                        'DCDSMainBtn ' + (openYearDropdown === true ? 'focus' : '')
                                    }
                                    reference={yearButtonRef}
                                    onClick={handleYearDropdownToggle}
                                >
                                    {year || 'Select Year'}
                                </Button>
                                <DropdownOptions
                                    options={generateYearOptions()}
                                    open={openYearDropdown}
                                    wrapperWidth={yearButtonRef.current?.offsetWidth}
                                    onClose={() => setOpenYearDropdown(false)}
                                    reference={yearButtonRef}
                                    viewHeight={200}
                                    itemSize={40}
                                    defaultPlacement={'bottom-start'}
                                />
                            </Div>
                        </Div>
                        {showCalendar && (
                            <OnlyDatePickerCalendar
                                locale={enGB}
                                date={dayjs(datetime).startOf('day').toDate()}
                                handleDatePicker={handleDatePicker}
                                modifiersClassNames={modifiersClassNames}
                            />
                        )}
                    </Div>
                    <Div className={'TimePickerOptionsLists'}>
                        <List className={'TPOHours'}>
                            <ListItem>Hrs</ListItem>
                            {hoursArray.map((item, index) => (
                                <ListItem
                                    key={index}
                                    onClick={() => {
                                        handleSelectHours(item);
                                    }}
                                    className={
                                        ('0' + item).slice(-2) ===
                                        dayjs(datetime).tz(getUserTimeZone()).format('hh')
                                            ? 'active'
                                            : ''
                                    }
                                >
                                    {('0' + item).slice(-2)}
                                </ListItem>
                            ))}
                        </List>
                        <List className={'TPOMin'}>
                            <ListItem>Min</ListItem>
                            {minutesArray.map((item, index) => (
                                <ListItem
                                    key={index}
                                    onClick={() => {
                                        handleSelectMinutes(item);
                                    }}
                                    className={
                                        ('0' + item).slice(-2) ===
                                        dayjs(datetime).tz(getUserTimeZone()).format('mm')
                                            ? 'active'
                                            : ''
                                    }
                                >
                                    {('0' + item).slice(-2)}
                                </ListItem>
                            ))}
                        </List>
                        <List className={'TPOFormate'}>
                            <ListItem />
                            {timeFormateArray.map((item, index) => (
                                <ListItem
                                    key={index}
                                    onClick={() => {
                                        handleSelectTimeFormate(item);
                                    }}
                                    className={
                                        item === dayjs(datetime).tz(getUserTimeZone()).format('A')
                                            ? 'active'
                                            : ''
                                    }
                                >
                                    {('0' + item).slice(-2)}
                                </ListItem>
                            ))}
                        </List>
                    </Div>
                </Div>
            </Popover>
        </Div>
    );
}

DateTimePicker.propTypes = {
    value: PropTypes.string,
    onChange: PropTypes.func,
    disabled: PropTypes.bool,
    readOnly: PropTypes.bool,
    autoFocus: PropTypes.bool,
    placeholder: PropTypes.string,
};

export default DateTimePicker;
