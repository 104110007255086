import React from 'react';

import { SVGIcon } from '../../UIKit/index';

export default function DCMailResend(props) {
    return (
        <SVGIcon {...props} viewBox="0 0 481.898 481.898">
            <path d="m481.8 148.768v254.882c0 29.214-23.767 52.981-52.981 52.981h-375.838c-29.214-.001-52.981-23.768-52.981-52.981v-263.887c0-29.214 23.767-52.981 52.981-52.981h213.019c4.971 0 9 4.029 9 9s-4.029 9-9 9h-213.019c-19.289 0-34.981 15.693-34.981 34.981v26.685l205.905 99.994c10.806 5.247 23.205 5.256 34.018.026l137.058-66.292c4.475-2.164 9.857-.291 12.021 4.183 2.164 4.475.292 9.856-4.183 12.021l-137.058 66.292c-15.439 7.468-33.566 7.805-49.719-.039l-198.042-96.175v217.192c0 19.289 15.692 34.981 34.981 34.981h375.838c19.289 0 34.981-15.692 34.981-34.981v-254.882c0-4.971 4.029-9 9-9s9 4.029 9 9zm-341.223 154.558-87.712 87.712c-5.687 5.687-1.581 15.364 6.364 15.364 2.303 0 4.607-.879 6.364-2.636l87.712-87.712c3.515-3.515 3.515-9.213 0-12.728-3.515-3.514-9.214-3.514-12.728 0zm187.919 0c-3.515 3.515-3.515 9.213 0 12.728l87.712 87.712c1.757 1.757 4.061 2.636 6.364 2.636 7.943 0 12.052-9.676 6.364-15.364l-87.712-87.712c-3.516-3.514-9.214-3.514-12.728 0zm-13.87-119.068c23.135-26.028 29.699-35.757 48.383-44.148 4.534-2.036 6.56-7.362 4.523-11.897-2.036-4.534-7.359-6.56-11.897-4.523-20.619 9.259-30.578 21.739-38.733 30.914.365-42.699 35.213-77.325 77.997-77.325 4.971 0 9-4.029 9-9v-15.35l54.536 43.349-54.535 43.35v-15.349c0-4.971-4.029-9-9-9s-9 4.029-9 9v34c0 7.522 8.717 11.723 14.6 7.045l78-62c4.531-3.602 4.529-10.49 0-14.091l-78-62c-5.894-4.684-14.6-.467-14.6 7.045v25.418c-48.731 4.55-87 45.681-87 95.582v23c0 8.288 10.269 12.12 15.726 5.98z" />
        </SVGIcon>
    );
}
