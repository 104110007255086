const templateStore = {
    allTemplates: new Map(),
    allTemplatePageLimit: 25,
    allTemplatePage: 1,
    totalAllTemplatePages: 1,
    allTemplateResults: 1,
    allTemplateTPage: 1,
    initialAllTemplateLoad: true,

    emailTemplates: new Map(),
    emailTemplatePageLimit: 25,
    emailTemplatePage: 1,
    totalEmailTemplatePages: 1,
    emailTemplateResults: 1,
    emailTemplateTPage: 1,
    initialEmailTemplateLoad: true,

    edocTemplates: new Map(),
    edocTemplatePageLimit: 25,
    edocTemplatePage: 1,
    totalEdocTemplatePages: 1,
    edocTemplateResults: 1,
    edocTemplateTPage: 1,
    initialEdocTemplateLoad: true,

    smsTemplates: new Map(),
    smsTemplatePageLimit: 25,
    smsTemplatePage: 1,
    totalSmsTemplatePages: 1,
    smsTemplateResults: 1,
    smsTemplateTPage: 1,
    initialSmsTemplateLoad: true,

    searchText: '',
    templateColumns: [],
    sortTemplate: '',
};
export default templateStore;
