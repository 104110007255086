import React from 'react';

import { SVGIcon } from '../../UIKit/index';

export default function DC4Boxes(props) {
    return (
        <SVGIcon {...props} viewBox="0 0 512 512">
            <g>
                <path d="M59.5,115.2H27v103c0,0,2.2,42,43.7,43.5h108.5c0,0,36.6-2.8,42.8-38.1V107.8c0,0-4-35.1-40.1-41.4H66.7   c0,0-32.2,0.6-39.7,41.3v7.6H59.5c0,0-0.2-16.9,15.4-16.7l101.7,0.3c0,0,10.2,0.3,13.5,11.5l0.7,107.4c0,0,0.6,9.3-15.1,12.3h-102   c0,0-11,0-14.3-13.5L59.5,115.2z" />
                <path d="M337.4,76l-28-16.5l-52.2,88.8c0,0-19.4,37.3,15.6,59.6l93.5,55c0,0,33,16.2,56.2-11.1l58.7-99.8   c0,0,14.4-32.3-13.6-56l-99.3-58.4c0,0-28.1-15.7-55.1,15.5l-3.8,6.5L337.4,76c0,0,8.4-14.6,21.7-6.6l87.5,51.8   c0,0,8.6,5.5,5.8,16.7l-53.8,92.9c0,0-4.2,8.3-19.3,3l-87.9-51.7c0,0-9.5-5.6-5.5-18.8L337.4,76z" />
                <path d="M59.5,332.1H27v103c0,0,2.2,42,43.7,43.5h108.5c0,0,36.6-2.8,42.8-38.1V324.6c0,0-4-35.1-40.1-41.4H66.7   c0,0-32.2,0.6-39.7,41.3v7.6H59.5c0,0-0.2-16.9,15.4-16.7l101.7,0.3c0,0,10.2,0.3,13.5,11.5l0.7,107.4c0,0,0.6,9.3-15.1,12.3h-102   c0,0-11,0-14.3-13.5L59.5,332.1z" />
                <path d="M269,332.1h-32.5v103c0,0,2.2,42,43.7,43.5h108.5c0,0,36.6-2.8,42.8-38.1V324.6c0,0-4-35.1-40.1-41.4H276.2   c0,0-32.2,0.6-39.7,41.3v7.6H269c0,0-0.2-16.9,15.4-16.7l101.7,0.3c0,0,10.2,0.3,13.5,11.5l0.7,107.4c0,0,0.6,9.3-15.1,12.3h-102   c0,0-11,0-14.3-13.5L269,332.1z" />
            </g>
        </SVGIcon>
    );
}
