import React, { useState } from 'react';

import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import DCAddIcon from 'Assets/icons/DCAddIcon';
import DCFillLock from 'Assets/icons/DCFillLock';
import { IconButton, ToolTip, Strong } from 'UIKit/index';

import './style.scss';

const Favorites = ({ favoriteMenus, onDragEnd, handleAddFavorite, closeDrawer }) => {
    const [dragItem, setDragItem] = useState(null);
    const onDragStart = result => {
        setDragItem(result?.source?.index + 1);
    };

    const getItemStyle = (isDragging, draggableStyle, totalItems) => {
        const { transform, width } = draggableStyle;
        let activeTransform = {};
        if (isDragging && transform && dragItem) {
            let transformX = transform.substring(
                transform.indexOf('(') + 1,
                transform.indexOf(','),
            );
            activeTransform = {
                transform: `translate(${
                    parseInt(transformX) < 0
                        ? parseInt(transformX) > -((dragItem - 1) * (width + 7))
                            ? transformX
                            : -((dragItem - 1) * (width + 7))
                        : parseInt(transformX) < (totalItems - dragItem) * (width + 17)
                        ? transformX
                        : (totalItems - dragItem) * (width + 7)
                }, 0)`,
            };
        }
        return {
            userSelect: 'none',
            ...draggableStyle,
            ...activeTransform,
        };
    };

    const handleClick = () => {
        closeDrawer();
    };

    return (
        <DragDropContext onDragEnd={onDragEnd} onDragStart={onDragStart}>
            <Droppable droppableId="droppable" direction={'horizontal'}>
                {provided => (
                    <div
                        className={
                            'FavoritesMenus ' +
                            (favoriteMenus.length === 10 ? 'complete ' : '') +
                            (favoriteMenus.length > 0 ? 'added' : '')
                        }
                        {...provided.droppableProps}
                        ref={provided.innerRef}
                    >
                        {favoriteMenus.map((item, index) => (
                            <Draggable key={item.name} draggableId={item.name} index={index}>
                                {(provided, snapshot) => (
                                    <Link
                                        ref={provided.innerRef}
                                        {...provided.draggableProps}
                                        {...provided.dragHandleProps}
                                        to={item.link}
                                        className={'FMItem'}
                                        style={{
                                            ...getItemStyle(
                                                snapshot.isDragging,
                                                provided.draggableProps.style,
                                                favoriteMenus.length,
                                            ),
                                            pointerEvents: item.isLock ? 'none' : 'auto',
                                        }}
                                        onClick={handleClick}
                                    >
                                        <ToolTip
                                            content={item.label}
                                            customPopoverClass={'FMIPopover ArrowPopover'}
                                        >
                                            {item.isLock && (
                                                <Strong className={'MHIMLILock'}>
                                                    <DCFillLock />
                                                </Strong>
                                            )}
                                            {item.icon}
                                        </ToolTip>
                                    </Link>
                                )}
                            </Draggable>
                        ))}
                        {favoriteMenus.length !== 10 && (
                            <ToolTip
                                wrapperClass={'FMIAdd'}
                                content={'Add your top 10 favorite apps to the menu'}
                                customPopoverClass={'FMIPopover ArrowPopover'}
                            >
                                <IconButton onClick={handleAddFavorite}>
                                    <DCAddIcon />
                                </IconButton>
                            </ToolTip>
                        )}
                        {provided.placeholder}
                    </div>
                )}
            </Droppable>
        </DragDropContext>
    );
};
export default Favorites;

Favorites.propTypes = {
    favoriteMenus: PropTypes.oneOfType([PropTypes.object, PropTypes.arrayOf(PropTypes.object)]),
    onDragEnd: PropTypes.func,
    handleAddFavorite: PropTypes.func,
};
