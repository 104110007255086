import React from 'react';

import { SVGIcon } from '../../UIKit/index';

export default function DCUserForm(props) {
    return (
        <SVGIcon {...props} viewBox="0 0 512 512">
            <path d="M147.9,487.4c0,10.8-8.8,19.6-19.6,19.6H83.2c-43.2,0-78.2-35-78.3-78.2V84.3C5,41.1,40,6,83.2,6  h240.6C367,6,402,41.1,402.1,84.3v116.5c0,10.8-8.7,19.6-19.6,19.6s-19.6-8.7-19.6-19.6c0,0,0,0,0,0V84.3  c0-21.6-17.5-39.1-39.1-39.1H83.2c-21.6,0-39.1,17.5-39.1,39.1v344.4c0,21.6,17.5,39.1,39.1,39.1h45  C139,467.8,147.8,476.5,147.9,487.4C147.9,487.4,147.9,487.4,147.9,487.4z M323.8,143c0-10.8-8.8-19.6-19.6-19.6H102.7  c-10.8,0-19.6,8.8-19.6,19.6c0,10.8,8.8,19.6,19.6,19.6h201.6C315,162.6,323.8,153.8,323.8,143L323.8,143z M245.6,221.3  c0-10.8-8.8-19.6-19.6-19.6H102.7c-10.8,0-19.6,8.8-19.6,19.6c0,10.8,8.8,19.6,19.6,19.6h123.4C236.9,240.8,245.6,232.1,245.6,221.3  C245.6,221.3,245.6,221.3,245.6,221.3z M102.7,280c-10.8,0-19.6,8.8-19.6,19.6c0,10.8,8.8,19.5,19.6,19.6H184  c10.8,0,19.6-8.8,19.6-19.6c0-10.8-8.8-19.5-19.6-19.6H102.7z M444.1,492.4c-7.4,9.2-18.6,14.6-30.5,14.6H222.5  c-21.8,0-39.5-17.8-39.5-39.6c0-2.7,0.3-5.4,0.8-8.1c8.9-42.6,37.4-78.5,76.9-96.7c-27.9-31.6-24.8-79.9,6.8-107.7  s79.9-24.8,107.7,6.8c25.4,28.8,25.4,72.1,0,100.9c10.7,4.9,20.7,11.2,29.8,18.7c23.6,19.5,40.1,46.3,46.7,76.2  c0.1,0.5,0.3,1,0.4,1.6C454.7,470.8,451.7,483.1,444.1,492.4L444.1,492.4z M280.9,312.2c0,20.5,16.6,37.2,37.2,37.2  c20.5,0,37.2-16.6,37.2-37.2c0-20.5-16.6-37.2-37.1-37.2C297.6,275,280.9,291.7,280.9,312.2C280.9,312.2,280.9,312.2,280.9,312.2  L280.9,312.2z M413.9,467.3L413.9,467.3c-10.9-52.9-62.7-87-115.6-76.1c-38.3,7.9-68.2,37.8-76.1,76.1c-0.1,0.2,0,0.5,0.2,0.6h191.3  C413.8,467.7,414,467.6,413.9,467.3L413.9,467.3z" />
        </SVGIcon>
    );
}
