import React from 'react';

import { SVGIcon } from '../../UIKit/index';

export default function DCPipeDrive(props) {
    return (
        <SVGIcon {...props} viewBox="0 0 512 117">
            <g>
                <path fill="#525254" d="M80.2,0.3h16.3v17.2H80.2V0.3z" />
                <path fill="#525254" d="M366,0.3h16.3v17.2H366V0.3z" />
                <path
                    fill="#231F1F"
                    d="M44.1,23.6c-10.3,0-16.2,4.6-19.1,7.8c-0.3-2.7-2.2-6.3-9.2-6.3H0.4v16.1h6.3c1.1,0,1.4,0.3,1.4,1.4V116h18.3   V88.5c0-0.7,0-1.4,0-2.1c2.9,2.6,8.3,6.2,16.9,6.2c17.9,0,30.4-14.2,30.4-34.5C73.7,37.5,61.8,23.6,44.1,23.6 M40.4,76.7   c-9.9,0-14.3-9.4-14.3-18.2c0-13.8,7.5-18.7,14.6-18.7c8.6,0,14.5,7.5,14.5,18.6C55.1,71.1,47.7,76.7,40.4,76.7"
                />
                <path
                    fill="#231F1F"
                    d="M97.8,73.8V34.9c0-6.6-3.2-9.8-9.7-9.8H71.8v16.1h6.3c1.1,0,1.4,0.3,1.4,1.4v38.8c0,6.7,3.1,9.8,9.6,9.8h16.4   V75.2h-6.3C98.2,75.2,97.8,74.8,97.8,73.8"
                />
                <path
                    fill="#231F1F"
                    d="M145.6,23.6c-10.3,0-16.2,4.6-19.1,7.8c-0.3-2.7-2.1-6.3-9.2-6.3h-15.4v16.1h6.3c1.1,0,1.4,0.3,1.4,1.4V116   h18.3V88.5c0-0.7,0-1.4,0-2.1c2.8,2.6,8.3,6.2,16.9,6.2c17.9,0,30.4-14.2,30.4-34.5C175.2,37.5,163.3,23.6,145.6,23.6 M141.9,76.7   c-9.8,0-14.3-9.4-14.3-18.2c0-13.8,7.5-18.7,14.6-18.7c8.6,0,14.5,7.5,14.5,18.6C156.6,71.1,149.2,76.7,141.9,76.7"
                />
                <path
                    fill="#231F1F"
                    d="M208.9,23.6c-19.7,0-33.5,14.2-33.5,34.5c0,20,14.9,34.5,35.4,34.5c16.1,0,26-9.5,26.4-10l0.7-0.7l-8-13.2   l-1.2,1.1c-0.1,0.1-7.3,6.7-16.9,6.7c-9.2,0-15.9-5.6-17.5-14.4h43.4l0.1-1.2c0-0.2,0.4-4.1,0.4-6   C238.3,36.3,226.5,23.6,208.9,23.6 M195.1,48.6c2-6.5,7-10.2,13.8-10.2c5.5,0,9.8,4.2,10.5,10.2H195.1"
                />
                <path
                    fill="#231F1F"
                    d="M304.9,73.8V10.1c0-6.6-3.2-9.8-9.8-9.8h-16.4v16.1h6.3c1.1,0,1.4,0.3,1.4,1.4v11.5c-2.7-2.4-8-5.6-17.1-5.6   c-17.8,0-30.2,14.2-30.2,34.5c0,20.6,11.9,34.5,29.5,34.5c10.1,0,15.9-4.7,18.8-8c0.4,2.8,2.2,6.5,9.1,6.5h15.9V75.2h-6.2   C305.3,75.2,304.9,74.8,304.9,73.8 M272.3,76.7c-8.7,0-14.3-7.3-14.3-18.6c0-11,5.9-18.3,14.6-18.3c9.9,0,14.3,9.1,14.3,18.2   C286.8,71.8,279.3,76.7,272.3,76.7"
                />
                <path
                    fill="#231F1F"
                    d="M353.3,24.3c-7.6,0-14.6,4.4-18.8,11.4v-1.5c0-6-3.3-9-9.8-9H309v16.1h6.3c1.1,0,1.4,0.3,1.4,1.4v48.5H335   V64.7c0-3.5,0.4-6.8,1.3-9.9c2.8-9.1,10.1-12.4,16-12.4c2.1,0,3.6,0.2,3.7,0.2l1.5,0.2V24.7l-1.1-0.2   C356.3,24.5,354.8,24.3,353.3,24.3"
                />
                <path
                    fill="#231F1F"
                    d="M383.6,73.8V34.9c0-6.6-3.2-9.8-9.6-9.8h-14.8v16.1h4.7c1.1,0,1.4,0.3,1.4,1.4v38.8c0,6.7,3.1,9.8,9.7,9.8   h16.4V75.2H385C384,75.2,383.6,74.8,383.6,73.8"
                />
                <path
                    fill="#231F1F"
                    d="M452.4,41.2V25.1h-9.8c-5.7,0-8.8,2-10.6,6.8l-11.9,33.3c-0.4,1.2-0.8,2.6-1.1,4c-0.3-1.3-0.8-2.7-1.2-4   L405.8,32c-1.8-4.9-4.8-6.8-10.6-6.8h-10.4v16.1h2.7c1.4,0,1.9,0.5,2.2,1.4l18.5,48.6h20.6l18.5-48.6c0.3-0.9,0.8-1.4,2.2-1.4   H452.4"
                />
                <path
                    fill="#231F1F"
                    d="M511.2,61c0-0.2,0.4-4.1,0.4-6c0-18.8-11.8-31.4-29.4-31.4c-19.7,0-33.5,14.2-33.5,34.5   c0,20,14.9,34.5,35.4,34.5c16.1,0,26-9.5,26.4-10l0.7-0.7l-8-13.2l-1.2,1.1c-0.1,0.1-7.3,6.7-16.9,6.7c-9.2,0-15.9-5.6-17.5-14.4   h43.4L511.2,61L511.2,61z M468.3,48.6c2-6.5,7-10.2,13.8-10.2c5.5,0,9.8,4.2,10.5,10.2H468.3L468.3,48.6z"
                />
                <path
                    fill="#3B3B3C"
                    d="M353.3,24.3c-5.9,0-11.4,2.7-15.6,7.1c3.5,0,7-0.1,10.5-0.1c3.1,0,6.2,0,9.2,0v-6.7l-1.1-0.2   C356.3,24.5,354.8,24.3,353.3,24.3"
                />
                <path
                    fill="#3B3B3C"
                    d="M432,32l-0.8,2.1c7.1,0.5,14.1,1,21.1,1.6V25.1h-9.8C436.9,25.1,433.8,27.1,432,32"
                />
                <path
                    fill="#3B3B3C"
                    d="M44.1,23.6c-10.3,0-16.2,4.6-19.1,7.8c-0.3-2.7-2.2-6.3-9.2-6.3H0.4v16.1h6.3c1.1,0,1.4,0.3,1.4,1.4v40.9   c8.4-2.9,17-5.7,25.8-8.5c-5.4-3.1-7.9-10-7.9-16.5c0-13.8,7.5-18.7,14.6-18.7c8.6,0,14.5,7.5,14.5,18.6c0,4.7-1,8.4-2.6,11.2   c6.8-1.9,13.8-3.8,20.8-5.6c0.2-1.9,0.4-3.8,0.4-5.8C73.7,37.5,61.8,23.6,44.1,23.6"
                />
                <path
                    fill="#3B3B3C"
                    d="M88.1,25.1H71.8v16.1h6.3c1.1,0,1.4,0.3,1.4,1.4v19.8c6-1.5,12.1-3,18.3-4.4V34.9   C97.8,28.3,94.6,25.1,88.1,25.1"
                />
                <path fill="#3B3B3C" d="M80.2,0.3h16.3v17.2H80.2V0.3z" />
                <path
                    fill="#3B3B3C"
                    d="M145.6,23.6c-10.3,0-16.2,4.6-19.1,7.8c-0.3-2.7-2.1-6.3-9.2-6.3h-15.4v16.1h6.3c1.1,0,1.4,0.3,1.4,1.4v12.8   c6.2-1.3,12.5-2.6,18.8-3.8c2.1-8.5,8-11.8,13.7-11.8c5.2,0,9.3,2.7,11.8,7.2c6.3-1.1,12.7-2.1,19.1-3   C168.8,31.4,158.8,23.6,145.6,23.6"
                />
                <path
                    fill="#3B3B3C"
                    d="M208.9,23.6c-14.4,0-25.6,7.6-30.6,19.6c7.8-1.1,15.6-2.2,23.6-3.1c2-1.1,4.4-1.6,7.1-1.6c1,0,1.9,0.2,2.9,0.4   c7.2-0.8,14.5-1.6,21.8-2.3C228.5,28.5,219.8,23.6,208.9,23.6"
                />
                <path
                    fill="#3B3B3C"
                    d="M295.1,0.3h-16.4v16.1h6.3c1.1,0,1.4,0.3,1.4,1.4v11.5c-2.7-2.4-8-5.6-17.1-5.6c-9.9,0-18.2,4.4-23.6,11.9   c19.3-1.6,39-2.8,59.1-3.5v-22C304.9,3.5,301.7,0.3,295.1,0.3"
                />
                <path
                    fill="#3B3B3C"
                    d="M324.7,25.1H309V32c8.4-0.3,16.7-0.5,25.2-0.6C333.3,27.3,330.1,25.1,324.7,25.1"
                />
                <path fill="#3B3B3C" d="M366,0.3h16.3v17.2H366V0.3z" />
                <path
                    fill="#3B3B3C"
                    d="M373.9,25.1h-14.8v6.3c8.1,0.1,16.1,0.2,24.1,0.4C382.3,27.4,379.3,25.1,373.9,25.1"
                />
                <path
                    fill="#3B3B3C"
                    d="M395.2,25.1h-10.4v6.7c7.1,0.2,14.2,0.5,21.2,0.8l-0.2-0.7C404,27.1,401,25.1,395.2,25.1"
                />
                <path
                    fill="#3B3B3C"
                    d="M482.2,23.6c-11.2,0-20.5,4.6-26.5,12.4c8.6,0.8,17.1,1.6,25.6,2.5c1.3,0.1,2.6,0.3,3.9,0.4   c8.2,0.9,16.2,1.9,24.2,3C505.2,30.7,495.3,23.6,482.2,23.6"
                />
            </g>
        </SVGIcon>
    );
}
