import React, { useMemo, useState } from 'react';
import DCCheckCircle from 'Assets/icons/DCCheckCircle';
import {
    Div,
    IconButton,
    UserInfo,
    AvatarGroups,
    ToolTip,
    SingleAvatar,
    FieldControl,
    Checkbox,
    Link,
    Button,
} from 'UIKit';

import { Link as LinkR, useHistory } from 'react-router-dom';
import DCHorizontal3Dots from 'Assets/icons/DCHorizontal3Dots';
import './style.scss';
import DCContacts from 'Assets/icons/DCContacts';
import DCDealMenu from 'Assets/icons/DCDealMenu';
import { formateDate } from 'Utils/dateUtils';
import generateImage from 'Utils/generateImage';
import DCDashboard from 'Assets/icons/DCDashboard';
import { updateReminderApi } from 'apis/activities/index.api';
import { useQueryClient } from 'react-query';
import useToast from 'Modules/Toasts';
import DCRadioButtonUnchecked from 'Assets/icons/DCRadioButtonUnchecked';

type Props = {
    reminder: any;
    origin: string;
    handleClose: Function;
    cardType: string;
};

const types = [
    { id: 0, title: 'Call', name: 'call' },
    { id: 1, title: 'Email', name: 'email' },
    { id: 2, title: 'To-Do', name: 'to-do' },
];

const Card: React.FC<Props> = props => {
    const { reminder, origin, handleClose, cardType } = props;
    const queryClient = useQueryClient();
    const { showToast } = useToast();
    const [showMore, setShowMore] = useState(false);
    const [showSlide, setShowSlide] = useState(false);
    let history = useHistory();

    const appIconSwitch = (iconName: string) => {
        switch (iconName) {
            case 'contact':
                return {
                    link: '/contacts',
                    icon: <DCContacts />,
                };
            case 'deal':
                return {
                    link: '/deals',
                    icon: <DCDealMenu />,
                };
            default:
                return { link: '/dashboard', icon: <DCDashboard /> };
        }
    };

    const handleUpdateReminder = () => {
        updateReminderApi(reminder.id, {
            type: reminder.type,
            reminder_status: !reminder.reminder_status,
        })
            .then(() => {
                setShowSlide(true);
                setTimeout(() => {
                    queryClient.resetQueries(['reminders-all']);
                    setShowSlide(false);
                }, 500);
            })
            .catch(err => {
                showToast({
                    message: err?.message || err || 'Something went wrong',
                    type: 'error',
                });
            });
    };

    const handleShowMoreToogle = () => {
        setShowMore(prev => !prev);
    };

    const handelMiddleBox = () => {
        switch (reminder.type) {
            case 'deal':
                return !!reminder.deal;
            case 'contact':
                return !!reminder.contact;
            default:
                return false;
        }
    };

    const generateContactView = useMemo(() => {
        switch (reminder.type) {
            case 'contact':
                let associated: any[] = [];
                if (reminder.contact)
                    associated = associated.concat(
                        reminder.contact.people,
                        reminder.contact.businesses,
                        reminder.contact.deals,
                    );
                return (
                    <>
                        {reminder.contact && (
                            <Div className="CCMBUser">
                                <Link
                                    href={`/contacts?type=${
                                        reminder.contact.type === 'person' ? 'people' : 'businesses'
                                    }&id=${reminder.contact.id}`}
                                >
                                    <UserInfo
                                        name={reminder.contact.name}
                                        email={reminder.contact.email}
                                        image={generateImage(
                                            reminder.contact.image,
                                            reminder.contact.name,
                                        )}
                                    />
                                </Link>
                            </Div>
                        )}
                        <Div className="CCMBRight">
                            <AvatarGroups>
                                {associated.slice(0, 4).map((el: any, index: number) => (
                                    <LinkR
                                        key={index}
                                        onClick={() => handleClose()}
                                        to={{
                                            pathname: el.pipeline_id
                                                ? '/deals/my-deals'
                                                : '/contacts',
                                            search:
                                                (el.pipeline_id
                                                    ? `type=deal&pipeline=${el.pipeline_id}&id=${el.id}`
                                                    : el.type === 'business'
                                                    ? `type=businesses&id=${el.id}`
                                                    : `type=people&id=${el.id}`) + '&tab=reminders',
                                            state: {
                                                returnPath: `${history.location.pathname}`,
                                                backButtonTitle: 'Notification Center',
                                                origin: origin,
                                            },
                                        }}
                                    >
                                        <ToolTip
                                            customPopoverClass={'UserInfoToolTip'}
                                            withBackground
                                            placement={'top-end'}
                                            content={
                                                <UserInfo
                                                    name={el.name}
                                                    email={el.email}
                                                    image={generateImage(el.image, el.name)}
                                                />
                                            }
                                        >
                                            <SingleAvatar
                                                name={el.name}
                                                image={generateImage(el.image, el.name)}
                                                zIndex={1}
                                            />
                                        </ToolTip>
                                    </LinkR>
                                ))}
                                {associated.length > 4 && '+' + (associated.length - 4)}
                            </AvatarGroups>
                        </Div>
                    </>
                );
            case 'deal':
                return (
                    <>
                        <Div className="CCMBUser">
                            {reminder.deal && (
                                <Link
                                    href={`/deals/my-deals?type=deal&pipeline=${reminder.deal.pipeline_id}&id=${reminder.deal.id}`}
                                >
                                    <UserInfo
                                        name={reminder.deal.name}
                                        image={generateImage(null, reminder.deal.name)}
                                    />
                                </Link>
                            )}
                        </Div>
                        <Div className="CCMBRight">
                            <AvatarGroups customClass={'TableAvatarGroups'}>
                                {reminder.deal?.person && (
                                    <LinkR
                                        onClick={() => handleClose()}
                                        to={{
                                            pathname: '/contacts',
                                            search:
                                                `type=people&id=${reminder.deal.person.id}` +
                                                '&tab=reminders',
                                            state: {
                                                returnPath: `${history.location.pathname}`,
                                                backButtonTitle: 'Notification Center',
                                                origin: origin,
                                            },
                                        }}
                                    >
                                        <ToolTip
                                            customPopoverClass={'UserInfoToolTip'}
                                            withBackground
                                            placement={'top-end'}
                                            content={
                                                <UserInfo
                                                    name={reminder.deal.person.name}
                                                    email={reminder.deal.person.email}
                                                    image={generateImage(
                                                        reminder.deal.person.image,
                                                        reminder.deal.person.name,
                                                    )}
                                                />
                                            }
                                        >
                                            <SingleAvatar
                                                name={reminder.deal.person.name}
                                                image={generateImage(
                                                    null,
                                                    reminder.deal.person.name,
                                                )}
                                                zIndex={1}
                                            />
                                        </ToolTip>
                                    </LinkR>
                                )}
                                {reminder.deal?.business && (
                                    <LinkR
                                        onClick={() => handleClose()}
                                        to={{
                                            pathname: '/contacts',
                                            search:
                                                `type=businesses&id=${reminder.deal.business.id}` +
                                                '&tab=reminders',
                                            state: {
                                                returnPath: `${history.location.pathname}`,
                                                backButtonTitle: 'Notification Center',
                                                origin: origin,
                                            },
                                        }}
                                    >
                                        <ToolTip
                                            customPopoverClass={'UserInfoToolTip'}
                                            withBackground
                                            placement={'top-end'}
                                            content={
                                                <UserInfo
                                                    name={reminder.deal.business.name}
                                                    email={reminder.deal.business.email}
                                                    image={generateImage(
                                                        null,
                                                        reminder.deal.business.name,
                                                    )}
                                                />
                                            }
                                        >
                                            <SingleAvatar
                                                name={reminder.deal.business.name}
                                                image={generateImage(
                                                    reminder.deal.business.image,
                                                    reminder.deal.business.name,
                                                )}
                                                zIndex={1}
                                            />
                                        </ToolTip>
                                    </LinkR>
                                )}
                            </AvatarGroups>
                        </Div>
                    </>
                );
        }
    }, [
        history.location.pathname,
        reminder.contact,
        reminder.deal,
        reminder.type,
        handleClose,
        origin,
    ]);

    return (
        <Div className={`CardContainerBox ${showSlide ? 'ReminderCard--completed' : ''}`}>
            <Div className={`CCTopBar ${cardType}`}>
                <Div className="CCTBDate">
                    {formateDate(reminder.due_date_time, true, false, false)}
                </Div>
                <Div className={'CCTBRight type ' + reminder.reminder_type}>
                    {types.find(el => el.name === reminder.reminder_type)?.title}
                </Div>

                <Div className="CCTBRight Icon">
                    <Link href={appIconSwitch(reminder.type).link}>
                        {appIconSwitch(reminder.type).icon}
                    </Link>
                </Div>
            </Div>

            {handelMiddleBox() ? <Div className="CCMiddleBox">{generateContactView}</Div> : ''}
            <Div className="CCBottomBox">
                <Div className={`CCBBTitle ${cardType}`}>
                    <Div>{reminder.headline}</Div>
                </Div>
                <Div className="CCBBBody">
                    <FieldControl>
                        <Div
                            className="CCBBBViewMoreContainer"
                            dangerouslySetInnerHTML={{
                                __html: showMore
                                    ? reminder.content
                                    : reminder.content.substring(0, 100),
                            }}
                        />
                    </FieldControl>
                    <Div className="CCBBBQuickActions">
                        {reminder.content.length > 100 && (
                            <IconButton
                                buttonType="circleBtn"
                                customClass={'showMorebtn'}
                                onClick={handleShowMoreToogle}
                            >
                                <DCHorizontal3Dots />
                            </IconButton>
                        )}
                        {false && (
                            <Checkbox
                                checkIcon={<DCCheckCircle />}
                                unCheckIcon={<DCRadioButtonUnchecked />}
                                label={reminder.reminder_title}
                                unCheckColor={origin === 'pastDue' ? 'red' : 'blue'}
                                checkColor={'green'}
                                onChange={handleUpdateReminder}
                                checked={reminder.reminder_status}
                            />
                        )}

                        {reminder.reminder_status ? (
                            <Div className="CCBBBQAStatus completed">Completed</Div>
                        ) : (
                            <Button
                                buttonClass={'CCBBBQAStatus'}
                                buttonType={'headerBtn'}
                                onClick={handleUpdateReminder}
                            >
                                Mark as completed
                            </Button>
                        )}
                    </Div>
                </Div>
            </Div>
        </Div>
    );
};

export default Card;
