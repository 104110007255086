import {
    createSubAccountProfile,
    getBrandingData,
    getPublicAccountRequestData,
} from 'apis/login/index.api';
import DCArrowNextIcon from 'Assets/icons/DCArrowNext';
import PhoneNumber from 'Modules/PhoneNumber/phoneNumber';
import PhoneNumberView from 'Modules/PhoneNumber/phoneNumberView';
import useToast from 'Modules/Toasts';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Button, Div, FieldControl, H4, Image, Input, Label, LabelControl, P, Span } from 'UIKit';
import DCRightArrow from 'Assets/icons/DCRightArrow';
import DCDashboardView from 'Assets/images/DcScreen.png';
import './style.scss';
import { useQuery } from 'react-query';
import generateImage from 'Utils/generateImage';
import DCLock from 'Assets/icons/DCLock';
import { capitalizeFirstLetter } from 'Utils/stringUtils';
import { Validation } from 'Utils/validator';
import { SubAccountStatus } from './constant.js';
import {
    getCurrentStatusMessage,
    ValidateBusinessDetails,
    ValidatePersonalDetails,
} from './helper';

const CreateProfile = () => {
    const firstTime = useRef(false);
    const { showToast } = useToast();
    const history = useHistory();
    const [checkToken, setCheckToken] = useState(false);
    const [personalDetails, setPersonalDetails] = useState({});
    const [businessDetails, setBusinessDetails] = useState({});
    const [businessErrorArr, setBusinessErrorArr] = useState({});
    const [currStatus, setCurrStatus] = useState('');
    const [subLoading, setSubLoading] = useState(false);
    const [brandingData, setBrandingData] = useState({});
    const [errorObject, setErrorObject] = useState({});
    const [emailError, setEmailError] = useState(false);
    const [passwordError, setPasswordError] = useState(false);

    const { data } = useQuery(
        ['signup-profile-data'],
        async () => getPublicAccountRequestData(window.location.href.split('?d=')[1]),
        {
            staleTime: 1000 * 60 * 10, // 10 minutes
            cacheTime: 1000 * 60 * 15, // 15 minutes
            keepPreviousData: true,
            retry: 1,
            onError: err => {
                setCheckToken(true);
                showToast({
                    type: 'error',
                    duration: 1500,
                    message: err || 'Failed to load data. Please refresh the page',
                });
            },
        },
    );

    const showBrandingData = useCallback(
        async id => {
            try {
                const res = await getBrandingData(id);
                if (res.success) {
                    if (!res?.data?.hasOwnProperty('allow_public_sub_account')) {
                        res.data['allow_public_sub_account'] = false;
                    }
                    if (!res?.data?.hasOwnProperty('allow_public_sub_account_auto_approve')) {
                        res.data['allow_public_sub_account_auto_approve'] = false;
                    }
                    setBrandingData(res.data);
                }
            } catch (err) {
                showToast({
                    type: 'error',
                    message: err || err.message || 'Something went wrong!',
                });
            }
        },
        [showToast],
    );

    useEffect(() => {
        if (!firstTime.current && data?.data) {
            const urlParam = new URLSearchParams(window.location.search);
            const myParam = urlParam.get('d');
            if (!myParam) {
                history.enteries = [];
                history.index = -1;
                history.push('/');
            }
            showBrandingData(data?.data?.parent_account);
            setPersonalDetails({ ...data.data?.user, password: '' });
            setBusinessDetails({ ...data.data?.business });
            setCurrStatus(data?.data?.status);
            firstTime.current = true;
        }
    }, [history, showBrandingData, data?.data]);

    useEffect(() => {
        if (checkToken) {
            history.enteries = [];
            history.index = -1;
            history.push('/');
        }
    }, [checkToken, history]);

    const handlePersonalDetails = useCallback(
        (e, type) => {
            let body = {};
            if (type === 'first_name') {
                body.first_name = e.target.value;
            } else if (type === 'last_name') {
                body.last_name = e.target.value;
            } else if (type === 'password') {
                body.password = e.target.value;
            } else {
                body.confirmpassword = e.target.value.trim();
            }
            setPersonalDetails({ ...personalDetails, ...body });
        },
        [personalDetails],
    );

    const handleCheckEmptyDetails = useCallback(e => {
        if (e.target.value.length > 0) {
            e.target.classList.remove('check-warning');
        } else {
            e.target.classList.add('check-warning');
        }
    }, []);

    const handleBusinessDetails = useCallback(
        (e, type) => {
            let body = {};
            if (type === 'name') {
                body.name = e.target.value;
            } else if (type === 'email') {
                body.email = e.target.value.trim();
            } else if (type === 'phone') {
                body.phone = e.trim();
            }
            setBusinessDetails({ ...businessDetails, ...body });
        },
        [businessDetails],
    );

    const handleSubmit = useCallback(async () => {
        try {
            const s1 = ValidatePersonalDetails(personalDetails, setErrorObject);
            const s2 = ValidateBusinessDetails(businessDetails, setBusinessErrorArr);
            if (!s1 || !s2) {
                return;
            } else {
                setSubLoading(true);
                let itoken = window.location.href.split('create-profile?d=')[1];
                let userDetails = { ...personalDetails };
                delete userDetails.confirmpassword;
                delete userDetails.email;
                delete businessDetails?.id;
                let body = {
                    user: { ...userDetails },
                    business: { ...businessDetails },
                    token: itoken,
                };
                const res = await createSubAccountProfile(body);
                if (res.success) {
                    showToast({
                        type: 'success',
                        message: 'Your Sub-Account Creation Request has been sent.',
                    });
                    history.entries = [];
                    history.index = -1;
                    history.push(`/`);
                    setPersonalDetails({});
                    setBusinessDetails({});
                    setSubLoading(false);
                }
            }
        } catch (err) {
            showToast({
                type: 'error',
                message: err || err?.message || 'Something went wrong!',
            });
            setSubLoading(false);
        }
    }, [personalDetails, businessDetails, showToast, history]);

    return (
        <>
            <Div className="CreateProfilePage">
                <Div className="CPCHeader">
                    <Div className="CPCHeaderContent">
                        <P props={{ className: 'CPCHCHeading' }}>Account Setup</P>
                        {currStatus ? (
                            <Span className={'CPCHCBCIAccountStatus'}>
                                {capitalizeFirstLetter(currStatus)}
                            </Span>
                        ) : null}
                        <Div className="CPCHCBreadCrum">
                            <Div className="CPCHCBCItem">Sign Up</Div>
                            <Div className="CPCHCBCIIcon">
                                <DCArrowNextIcon />
                            </Div>
                            <Div className="CPCHCBCItem">Create Profile</Div>
                        </Div>
                    </Div>
                    <Div className="CPCHeaderLogo">
                        <Image
                            src={
                                typeof brandingData?.image === 'object'
                                    ? generateImage(brandingData?.image)
                                    : brandingData?.image
                            }
                            alt={''}
                            displayName={''}
                        />
                    </Div>
                </Div>

                <Div className="CPCBody">
                    <Div className="CPCBForm">
                        <Div className="CPCBFHeader">
                            <H4 props={{ className: 'CPCBFHHeading' }}>
                                Create Your Dashboard Account
                            </H4>
                            <P props={{ className: 'CPCBFHSubHeading' }}>
                                Setup your profile and login credentials
                            </P>
                            {currStatus ? (
                                <Div className={'SAPHCMessage'}>
                                    {getCurrentStatusMessage(currStatus)}
                                </Div>
                            ) : (
                                ''
                            )}
                        </Div>
                        <Div className="CPCBFMainContent CPCBFMainContent--border">
                            <Div>
                                <FieldControl>
                                    <LabelControl>
                                        <Label
                                            customClass={'CPCBFMCFieldLabel'}
                                            props={{ htmlFor: 'CPFullName' }}
                                        >
                                            First Name
                                        </Label>
                                    </LabelControl>
                                    <Input
                                        id={'CPFullName'}
                                        className={`CPCBFMCFieldInput ${
                                            currStatus === SubAccountStatus[3] ||
                                            currStatus === SubAccountStatus[4]
                                                ? 'create_profile_email'
                                                : ''
                                        }`}
                                        type="text"
                                        onChange={e => {
                                            handlePersonalDetails(e, 'first_name');
                                            handleCheckEmptyDetails(e);
                                        }}
                                        disabled={
                                            currStatus === SubAccountStatus[3] ||
                                            currStatus === SubAccountStatus[4]
                                        }
                                        value={personalDetails?.first_name}
                                        error={errorObject['first_name']?.length > 0}
                                        errorMessage={errorObject['first_name']}
                                    />
                                </FieldControl>
                                <FieldControl>
                                    <LabelControl>
                                        <Label
                                            customClass={'CPCBFMCFieldLabel'}
                                            props={{ htmlFor: 'CPFullName' }}
                                        >
                                            Last Name
                                        </Label>
                                    </LabelControl>
                                    <Input
                                        id={'CPFullName'}
                                        className={`CPCBFMCFieldInput ${
                                            currStatus === SubAccountStatus[3] ||
                                            currStatus === SubAccountStatus[4]
                                                ? 'create_profile_email'
                                                : ''
                                        }`}
                                        type="text"
                                        onChange={e => {
                                            handlePersonalDetails(e, 'last_name');
                                            handleCheckEmptyDetails(e);
                                        }}
                                        disabled={
                                            currStatus === SubAccountStatus[3] ||
                                            currStatus === SubAccountStatus[4]
                                        }
                                        value={personalDetails?.last_name}
                                        error={errorObject['last_name']?.length > 0}
                                        errorMessage={errorObject['last_name']}
                                    />
                                </FieldControl>
                                <FieldControl>
                                    <LabelControl>
                                        <Label
                                            customClass={'CPCBFMCFieldLabel'}
                                            props={{ htmlFor: 'CPEmail' }}
                                        >
                                            Email Address
                                        </Label>
                                    </LabelControl>
                                    <Input
                                        id={'CPEmail'}
                                        className={'CPCBFMCFieldInput create_profile_email'}
                                        type="text"
                                        value={personalDetails?.email}
                                        disabled
                                        onChange={() => {}}
                                        error={false}
                                        errorMessage={'errorMessage'}
                                    />
                                </FieldControl>

                                <FieldControl>
                                    <LabelControl>
                                        <Label
                                            customClass={'CPCBFMCFieldLabel'}
                                            props={{ htmlFor: 'CPPassword' }}
                                        >
                                            Password
                                        </Label>
                                    </LabelControl>
                                    <Input
                                        id={'CPPassword'}
                                        type="text"
                                        required
                                        className={`CPCBFMCFieldInput ${
                                            currStatus === SubAccountStatus[3] ||
                                            currStatus === SubAccountStatus[4]
                                                ? 'create_profile_email'
                                                : ''
                                        }`}
                                        onChange={e => {
                                            const validationPassword = Validation(
                                                'password',
                                                e.target.value,
                                            );
                                            if (!validationPassword) {
                                                setPasswordError(true);
                                            } else {
                                                setPasswordError(false);
                                            }
                                            handlePersonalDetails(e, 'password');
                                            handleCheckEmptyDetails(e);
                                        }}
                                        disabled={
                                            currStatus === SubAccountStatus[3] ||
                                            currStatus === SubAccountStatus[4]
                                        }
                                        value={personalDetails?.password}
                                        error={errorObject['password']?.length > 0 || passwordError}
                                        errorMessage={
                                            passwordError
                                                ? 'Password should have minimum eight characters, at least one uppercase letter, one lowercase letter, one number and one special character'
                                                : errorObject['password']
                                        }
                                    />
                                </FieldControl>

                                <FieldControl>
                                    <LabelControl>
                                        <Label
                                            customClass={'CPCBFMCFieldLabel'}
                                            props={{ htmlFor: 'CPConfirmPassword' }}
                                        >
                                            Confirm Password
                                        </Label>
                                    </LabelControl>
                                    <Input
                                        id={'CPConfirmPassword'}
                                        type="text"
                                        required
                                        onChange={e => {
                                            handlePersonalDetails(e, 'confirmpassword');
                                            handleCheckEmptyDetails(e);
                                        }}
                                        className={`CPCBFMCFieldInput ${
                                            currStatus === SubAccountStatus[3] ||
                                            currStatus === SubAccountStatus[4]
                                                ? 'create_profile_email'
                                                : ''
                                        }`}
                                        disabled={
                                            currStatus === SubAccountStatus[3] ||
                                            currStatus === SubAccountStatus[4]
                                        }
                                        value={personalDetails?.confirmpassword}
                                        error={errorObject['confirmpassword']?.length > 0}
                                        errorMessage={errorObject['confirmpassword']}
                                    />
                                </FieldControl>
                            </Div>
                        </Div>
                        <Div className="CPCBFMainContent">
                            <Div>
                                <FieldControl>
                                    <LabelControl>
                                        <Label
                                            customClass={'CPCBFMCFieldLabel'}
                                            props={{ htmlFor: 'CPEmail' }}
                                        >
                                            Business Email
                                        </Label>
                                    </LabelControl>
                                    <Input
                                        id={'CPEmail'}
                                        className={`CPCBFMCFieldInput ${
                                            currStatus === SubAccountStatus[3] ||
                                            currStatus === SubAccountStatus[4]
                                                ? 'create_profile_email'
                                                : ''
                                        }`}
                                        type="text"
                                        onChange={e => {
                                            const validationEmail = Validation(
                                                'email',
                                                e.target.value,
                                            );
                                            if (!validationEmail) {
                                                setEmailError(true);
                                            } else {
                                                setEmailError(false);
                                            }
                                            handleBusinessDetails(e, 'email');
                                            handleCheckEmptyDetails(e);
                                        }}
                                        disabled={
                                            currStatus === SubAccountStatus[3] ||
                                            currStatus === SubAccountStatus[4]
                                        }
                                        value={businessDetails?.email}
                                        error={businessErrorArr['email']?.length > 0 || emailError}
                                        errorMessage={
                                            emailError
                                                ? 'Email is invalid'
                                                : businessErrorArr['email']
                                        }
                                    />
                                </FieldControl>
                                {currStatus === SubAccountStatus[2] ||
                                currStatus === SubAccountStatus[1] ? (
                                    <FieldControl>
                                        <LabelControl>
                                            <Label
                                                customClass={'CPCBFMCFieldLabel'}
                                                props={{ htmlFor: 'CPPhoneNumber' }}
                                            >
                                                Phone Number
                                            </Label>
                                        </LabelControl>
                                        <PhoneNumber
                                            value={businessDetails?.phone}
                                            onChange={value => {
                                                handleBusinessDetails(value, 'phone');
                                            }}
                                            error={businessErrorArr['phone']?.length > 0}
                                            ifEmpty={businessErrorArr['phone']?.length > 0}
                                            required
                                            readOnly={
                                                currStatus === SubAccountStatus[0] ||
                                                currStatus === SubAccountStatus[3] ||
                                                currStatus === SubAccountStatus[4]
                                            }
                                            disabled={
                                                currStatus === SubAccountStatus[0] ||
                                                currStatus === SubAccountStatus[3] ||
                                                currStatus === SubAccountStatus[4]
                                            }
                                        />
                                    </FieldControl>
                                ) : (
                                    <FieldControl>
                                        <LabelControl>
                                            <Label
                                                customClass={'CPCBFMCFieldLabel'}
                                                props={{ htmlFor: 'CPPhoneNumber' }}
                                            >
                                                Phone Number
                                            </Label>
                                        </LabelControl>
                                        <Div className={'SACPPhoneView'}>
                                            <PhoneNumberView
                                                disabled
                                                value={businessDetails?.phone}
                                            />
                                        </Div>
                                    </FieldControl>
                                )}

                                <FieldControl>
                                    <LabelControl>
                                        <Label
                                            customClass={'CPCBFMCFieldLabel'}
                                            props={{ htmlFor: 'CPBusinessName' }}
                                        >
                                            Business Name
                                        </Label>
                                    </LabelControl>
                                    <Input
                                        id={'CPBusinessName'}
                                        className={`CPCBFMCFieldInput ${
                                            currStatus === SubAccountStatus[3] ||
                                            currStatus === SubAccountStatus[4]
                                                ? 'create_profile_email'
                                                : ''
                                        }`}
                                        type="text"
                                        onChange={e => {
                                            handleBusinessDetails(e, 'name');
                                            handleCheckEmptyDetails(e);
                                        }}
                                        disabled={
                                            currStatus === SubAccountStatus[3] ||
                                            currStatus === SubAccountStatus[4]
                                        }
                                        value={businessDetails?.name}
                                        error={businessErrorArr['name']?.length > 0}
                                        errorMessage={businessErrorArr['name']}
                                    />
                                </FieldControl>
                            </Div>
                        </Div>
                        <FieldControl customClass={'CPCBFCTABtn'}>
                            {currStatus === SubAccountStatus[1] ||
                            currStatus === SubAccountStatus[2] ? (
                                <Button
                                    buttonType={'BlueFillBtn'}
                                    buttonClass={'CPCBFCTABButton'}
                                    iconClass={'CPCBFCTABButtonIcon'}
                                    iconName={<DCRightArrow />}
                                    iconPlacement={'right'}
                                    onClick={handleSubmit}
                                    disabled={subLoading}
                                    fullWidth
                                >
                                    {currStatus === SubAccountStatus[2] ? 'Update' : 'Submit'}
                                </Button>
                            ) : (
                                <>
                                    <Button
                                        buttonType={`${
                                            currStatus === SubAccountStatus[3]
                                                ? 'BlueFillBtn'
                                                : 'RedFillBtn'
                                        }`}
                                        buttonClass={`${
                                            currStatus === SubAccountStatus[3]
                                                ? 'CPCBFCTABButton'
                                                : 'CPCBFCTABButtonRed'
                                        }`}
                                        iconClass={'CPCBFCTABButtonIcon'}
                                        iconName={<DCLock />}
                                        iconPlacement={'right'}
                                        disabled={true}
                                        fullWidth
                                    >
                                        {currStatus === SubAccountStatus[3]
                                            ? 'Approved'
                                            : 'Rejected'}
                                    </Button>
                                </>
                            )}
                        </FieldControl>
                    </Div>
                    <Div className="CPCBBrandingImage">
                        <Image src={DCDashboardView} />
                    </Div>
                </Div>
            </Div>
        </>
    );
};

export default CreateProfile;
