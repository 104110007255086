import React from 'react';

import { SVGIcon } from '../../UIKit/index';

export default function DCEDocs(props) {
    return (
        <SVGIcon {...props} viewBox="0 0 2000 2000" fill="none">
            <g clipPath="url(#DCEDocsClip0)">
                <rect width="2000" height="2000" rx="400" fill="url(#DCEDocsPaint0_linear)" />
                <g filter="url(#DCEDocsFilter0_d)">
                    <g filter="url(#DCEDocsFilter1_d)">
                        <path
                            d="M1580.38 1774.43H423.48C400.567 1774.43 382 1755.84 382 1732.93V267.48C382 244.567 400.567 226 423.48 226H1329.63C1330.52 226 1331.29 226.454 1332.18 226.494C1335.69 226.711 1339.07 227.383 1342.41 228.469C1343.75 228.904 1345.08 229.239 1346.36 229.792C1350.67 231.708 1354.78 234.138 1358.33 237.536L1604.78 473.915C1608.5 477.49 1611.16 481.697 1613.26 486.181C1613.89 487.465 1614.28 488.788 1614.76 490.151C1616 493.766 1616.75 497.46 1617.01 501.252C1617.07 502.161 1617.54 502.951 1617.54 503.86V1732.93V1737.27C1617.54 1757.79 1600.91 1774.43 1580.38 1774.43Z"
                            fill="url(#DCEDocsPaint1_linear)"
                        />
                    </g>
                    <path
                        opacity="0.1"
                        d="M699.939 555.168H1030.72C1053.63 555.168 1072.2 536.6 1072.2 513.687C1072.2 490.774 1053.65 472.207 1030.72 472.207H699.939C677.026 472.207 658.459 490.774 658.459 513.687C658.459 536.62 677.026 555.168 699.939 555.168Z"
                        fill="black"
                    />
                    <path
                        opacity="0.1"
                        d="M699.939 710.062H824.44C847.353 710.062 865.92 691.495 865.92 668.582C865.92 645.669 847.373 627.102 824.44 627.102H699.939C677.026 627.102 658.459 645.669 658.459 668.582C658.459 691.495 677.026 710.062 699.939 710.062Z"
                        fill="black"
                    />
                    <g filter="url(#DCEDocsFilter2_d)">
                        <path
                            d="M1355.78 489.629L1607.49 485.094C1610.17 485.046 1611.45 481.787 1609.52 479.933L1352.82 232.882C1350.23 230.386 1345.92 232.32 1346.05 235.918L1355.78 489.629Z"
                            fill="white"
                        />
                    </g>
                </g>
                <g filter="url(#DCEDocsFilter3_di)">
                    <path
                        d="M1703.97 1142.12C1666.23 1130.07 1625.85 1150.94 1613.82 1188.72C1586.57 1274.23 1488.77 1330.68 1429.35 1348.27C1430.17 1340.95 1431.2 1333.59 1431.95 1327.74C1435.47 1301.94 1439.03 1275.29 1435.54 1250.41C1432.67 1229.44 1420.64 1210.86 1402.79 1199.57C1384.91 1188.27 1362.96 1185.47 1342.79 1191.8C1289.7 1208.63 1254.25 1255.34 1223.01 1296.64C1215.72 1306.25 1205.71 1319.53 1196.44 1330.31C1195.69 1310.46 1197.09 1285.69 1197.91 1270.91C1199.11 1250.24 1200 1231.28 1199.25 1216.57C1197.98 1190.64 1182.77 1167.44 1159.56 1155.87C1136.34 1144.34 1108.69 1146.15 1087.22 1160.73C1055.84 1182.05 1020.9 1213.73 980.422 1250.38C912.221 1312.1 785.357 1426.93 728.609 1410.99C715.652 1407.33 702.764 1392.82 691.107 1369.48C806.86 1283.4 930.374 1117.69 938.784 983.831C942.852 919.162 921.486 864.21 877.078 824.964C867.814 816.718 856.498 811.038 844.396 808.506C771.82 793.142 701.67 817.368 646.904 876.596C550.602 980.683 512.656 1181.5 524.553 1305.94C407.773 1373.93 294.31 1416.26 292.943 1416.74C255.748 1430.42 236.707 1471.76 250.484 1508.99C264.158 1546.21 305.421 1565.31 342.615 1551.52C348.256 1549.43 452.454 1510.73 569.234 1445.86C599.557 1500.57 639.862 1535.2 689.602 1549.22C705.909 1553.88 722.318 1556 738.796 1556C849.148 1556 961.791 1460.5 1057.17 1374.58C1062.3 1402.57 1072.59 1429.06 1092.11 1450.78C1114.12 1475.25 1143.97 1489.07 1178.53 1490.75C1228.48 1492.9 1265.74 1466.63 1296.16 1434.09C1300.78 1444.18 1306.97 1453.83 1315.21 1462.76C1330.93 1479.94 1359.72 1499.88 1407.27 1497.52C1496.08 1493.21 1694.46 1408.56 1750.56 1232.35C1762.67 1194.57 1741.78 1154.13 1703.97 1142.12ZM669.364 1196.83C679.483 1115.32 708.131 1022.02 752.3 974.285C767.717 957.587 781.05 950.641 792.776 948.417C794.93 954.713 796.263 963.198 795.545 974.866C791.477 1038.71 734.284 1128.94 669.364 1196.83Z"
                        fill="url(#DCEDocsPaint2_linear)"
                    />
                </g>
            </g>
            <defs>
                <filter
                    id="DCEDocsFilter0_d"
                    x="378"
                    y="226"
                    width="1243.54"
                    height="1563.43"
                    filterUnits="userSpaceOnUse"
                    colorInterpolationFilters="sRGB"
                >
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feColorMatrix
                        in="SourceAlpha"
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    />
                    <feOffset dy="11" />
                    <feGaussianBlur stdDeviation="2" />
                    <feColorMatrix
                        type="matrix"
                        values="0 0 0 0 0.745833 0 0 0 0 0.0497222 0 0 0 0 0.142064 0 0 0 1 0"
                    />
                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
                    <feBlend
                        mode="normal"
                        in="SourceGraphic"
                        in2="effect1_dropShadow"
                        result="shape"
                    />
                </filter>
                <filter
                    id="DCEDocsFilter1_d"
                    x="212"
                    y="60"
                    width="1735.54"
                    height="2048.43"
                    filterUnits="userSpaceOnUse"
                    colorInterpolationFilters="sRGB"
                >
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feColorMatrix
                        in="SourceAlpha"
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    />
                    <feOffset dx="80" dy="84" />
                    <feGaussianBlur stdDeviation="125" />
                    <feColorMatrix
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.05 0"
                    />
                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
                    <feBlend
                        mode="normal"
                        in="SourceGraphic"
                        in2="effect1_dropShadow"
                        result="shape"
                    />
                </filter>
                <filter
                    id="DCEDocsFilter2_d"
                    x="1327.05"
                    y="222.754"
                    width="302.394"
                    height="295.874"
                    filterUnits="userSpaceOnUse"
                    colorInterpolationFilters="sRGB"
                >
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feColorMatrix
                        in="SourceAlpha"
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    />
                    <feOffset dy="10" />
                    <feGaussianBlur stdDeviation="9.5" />
                    <feColorMatrix
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
                    />
                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
                    <feBlend
                        mode="normal"
                        in="SourceGraphic"
                        in2="effect1_dropShadow"
                        result="shape"
                    />
                </filter>
                <filter
                    id="DCEDocsFilter3_di"
                    x="172"
                    y="768"
                    width="1656"
                    height="956"
                    filterUnits="userSpaceOnUse"
                    colorInterpolationFilters="sRGB"
                >
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feColorMatrix
                        in="SourceAlpha"
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    />
                    <feOffset dy="94" />
                    <feGaussianBlur stdDeviation="37" />
                    <feColorMatrix
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"
                    />
                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
                    <feBlend
                        mode="normal"
                        in="SourceGraphic"
                        in2="effect1_dropShadow"
                        result="shape"
                    />
                    <feColorMatrix
                        in="SourceAlpha"
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                        result="hardAlpha"
                    />
                    <feOffset dx="10" dy="-36" />
                    <feGaussianBlur stdDeviation="24.5" />
                    <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
                    <feColorMatrix
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
                    />
                    <feBlend mode="normal" in2="shape" result="effect2_innerShadow" />
                </filter>
                <linearGradient
                    id="DCEDocsPaint0_linear"
                    x1="849"
                    y1="0"
                    x2="849"
                    y2="2000"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#F45E7A" />
                    <stop offset="1" stopColor="#F72D46" />
                </linearGradient>
                <linearGradient
                    id="DCEDocsPaint1_linear"
                    x1="999.772"
                    y1="30.6873"
                    x2="999.772"
                    y2="1774.43"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#ECECEC" stopOpacity="0.96" />
                    <stop offset="1" stopColor="white" />
                </linearGradient>
                <linearGradient
                    id="DCEDocsPaint2_linear"
                    x1="1000"
                    y1="967.505"
                    x2="1000"
                    y2="1650.92"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#4864A4" />
                    <stop offset="1" stopColor="#0C2662" />
                </linearGradient>
                <clipPath id="DCEDocsClip0">
                    <rect width="2000" height="2000" fill="white" />
                </clipPath>
            </defs>
        </SVGIcon>
    );
}
