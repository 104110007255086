import React, { useCallback, useEffect, useState } from 'react';
import { useRouteMatch } from 'react-router';
import { useReactToPrint } from 'react-to-print';
import { PDFDownloadLink } from '@react-pdf/renderer';
import DCHorizontal3Line from 'Assets/icons/DCHorizontal3Line';
import DCPDF from 'Assets/icons/DCPDF';
import DCPrinter from 'Assets/icons/DCPrinter';
import { Button, Dialog, Div, H1, H2, IconButton, TopBar, Span } from 'UIKit/index';
import DownloadComponent from './components/downloadComponent';
import BusinessDetails from './components/businessDetails';
// import FacebookAds from './components/facebookAds';
import GoogleAds from './components/googleAds';
import Listings from './components/listings';
import Reviews from './components/Reviews/reviews';
import SEO from './components/seo';
import Sidebar from './components/sidebar';
import Website from './components/website';

import './style.scss';
import { getExternalPage } from 'apis/instareports/index.api';
import { useHistory } from 'react-router-dom';
import GetInTouch from './components/getInTouch';
import OverallScore from './components/overallScore';
import useMediaQuery from 'UIKit/useMediaQuery';
import { Ripple } from 'react-preloaders2';
import Social from './components/social';
import { data1 } from './_report_data';
import { getBrandingData } from 'apis/login/index.api';
import { getCookie } from 'Utils/auth';
import { useQuery } from 'react-query';
import log from 'Utils/logger';
import { getAuthDetails } from 'Utils/localStorageUtils';
import config from 'config';

const sortListings = data => {
    if (data?.data?.length > 0) {
        let sortedListings = [...data.data];
        sortedListings.sort((a, b) => {
            if (a.status === 'LISTING_FOUND' && b.status === 'LISTING_FOUND') return 0;
            if (a.status === 'LISTING_FOUND' && b.status !== 'LISTING_FOUND') return -1;
            if (a.status !== 'LISTING_FOUND' && b.status === 'LISTING_FOUND') return 1;
        });
        return { ...data, data: sortedListings };
    } else return [];
};

const ExternalPage = ({ preview }) => {
    const matche768 = useMediaQuery('(max-width: 768.98px)');

    const match = useRouteMatch();
    const [content, setContent] = useState({});
    const [loading, setLoading] = useState(true);
    const [notFound, setNotFound] = useState(false);
    const [branding, setBranding] = useState({});
    const history = useHistory();
    /**
     * Get Account Branding
     */
    const {
        isError,
        data: brandingData,
        isFetching: brandingLoading,
    } = useQuery(
        ['login', 'branding_data'],
        () => getBrandingData(getCookie('aid') ? getCookie('aid') : config.REACT_APP_TEST_AID),
        {
            staleTime: 1000 * 60 * 10, // 10 minutes
            cacheTime: 1000 * 60 * 15, // 15 minutes
            keepPreviousData: true,
            retry: 1,
        },
    );
    if (isError) {
        log.error('Account Branding API is not working!');
    }

    useEffect(() => {
        if (!brandingLoading && brandingData) {
            document.documentElement.style.setProperty(
                '--dark-blue',
                brandingData.data?.colors?.secondary,
            );
            document.documentElement.style.setProperty(
                '--blue-invert',
                brandingData.data?.colors?.font_primary,
            );
            setBranding(brandingData.data);
        }
    }, [brandingData, brandingLoading]);

    const getReportData = useCallback(
        async id => {
            try {
                const resp = await getExternalPage(id);
                if (!resp?.success || resp?.data?.status !== 'GENERATED') {
                    setNotFound(true);
                    setLoading(false);
                    setTimeout(() => {
                        history.push('/instareports/my-instareports');
                    }, 4000);
                    return;
                }
                setContent(resp.data);

                setLoading(false);
            } catch (err) {
                setLoading(false);
            }
        },
        [history],
    );

    useEffect(() => {
        if (preview) {
            const auth = getAuthDetails();
            let repdata = {
                ...data1.data,
                userDetails: {
                    _id: '5ef0fb9ad373758c837e79e1',
                    name: auth?.user?.name,
                    email: auth?.user?.email,
                    first_name: auth?.user?.name?.split(' ')[0],
                    image: 'https://s3.wasabisys.com/open/assets/team-placeholder.png',
                    businessName: auth?.account?.name,
                },
            };
            setContent(repdata);
            setLoading(false);
        } else if (match.params.id) {
            getReportData(match.params.id);
        }
    }, [getReportData, match.params.id, preview]);
    const [getInTouch, setGetInTouch] = useState(false);

    const handleGetInTouchOpen = useCallback(() => {
        setGetInTouch(true);
    }, []);

    const handleGetInTouchClose = useCallback(() => {
        setGetInTouch(false);
    }, []);

    // website
    const myRef = React.createRef();
    const [webDe, setWebDe] = useState(false);
    const [websiteDet, setWebsiteDet] = useState(false);

    const handlePrint = useReactToPrint({
        content: () => {
            return myRef.current;
        },
        onAfterPrint: () => {
            setWebsiteDet(false);
            setWebDe(false);
        },
    });
    let list = [];
    if (content && Object.keys(content).length > 0) {
        list = [
            {
                id: 0,
                componentName: (
                    <BusinessDetails
                        details={content?.businessDetails}
                        user={content?.userDetails}
                        handleGetInTouchOpen={handleGetInTouchOpen}
                    />
                ),
            },
            {
                id: 1,
                componentName: (
                    <OverallScore
                        scores={content?.overallScore || {}}
                        details={content?.businessDetails}
                    />
                ),
            },
            {
                id: 2,
                componentName: (
                    <Listings
                        data={sortListings(content?.listings)}
                        details={content?.businessDetails}
                        user={content?.userDetails}
                        webDe={webDe}
                        handleGetInTouchOpen={handleGetInTouchOpen}
                    />
                ),
            },
            {
                id: 3,
                componentName: (
                    <Reviews
                        data={content?.reviews || []}
                        details={content?.businessDetails}
                        user={content?.userDetails}
                        handleGetInTouchOpen={handleGetInTouchOpen}
                    />
                ),
            },
            {
                id: 4,
                componentName: (
                    <Social
                        facebook={content?.social?.facebook}
                        twitter={content?.social?.twitter}
                        grade={content?.social?.grade || 'F'}
                        details={content?.businessDetails}
                        user={content?.userDetails}
                        handleGetInTouchOpen={handleGetInTouchOpen}
                    />
                ),
            },
            {
                id: 5,
                componentName: (
                    <Website
                        visible={content?.businessDetails?.website?.length > 0}
                        data={content?.website}
                        details={content?.businessDetails}
                        user={content?.userDetails}
                        webDe={webDe}
                        handleGetInTouchOpen={handleGetInTouchOpen}
                    />
                ),
            },
            {
                id: 6,
                componentName: (
                    <SEO
                        visible={content?.businessDetails?.website?.length > 0}
                        grade={content.seo?.grade || 'F'}
                        keywords={content.seo?.organicKeywordRanking}
                        competitors={content.seo?.organicCompetitorAnalysis}
                        backlinks={content.seo?.backlinks}
                        details={content?.businessDetails}
                        user={content?.userDetails}
                        handleGetInTouchOpen={handleGetInTouchOpen}
                    />
                ),
            },
            {
                id: 7,
                componentName: (
                    <GoogleAds
                        visible={content?.businessDetails?.website?.length > 0}
                        grade={content?.googleAds?.grade || 'F'}
                        overview={content.googleAds?.overview}
                        paid_competitor={content.googleAds?.competitorAnalysis}
                        details={content?.businessDetails}
                        user={content?.userDetails}
                        handleGetInTouchOpen={handleGetInTouchOpen}
                    />
                ),
            },
            // Not using this for now, in future
            // {
            //     id: 8,
            //     componentName: (
            //         <FacebookAds
            //             ads={content.facebookAds}
            //             details={content?.businessDetails}
            //             user={content?.userDetails}
            //             handleGetInTouchOpen={handleGetInTouchOpen}
            //         />
            //     ),
            // },
        ];
    }
    const [sidebarMenuMob, setSidebarMenuMob] = useState(false);
    const [sidebarMenuWidth, setSidebarMenuWidth] = useState(false);
    const refs = list.reduce((acc, value) => {
        acc[value.id] = React.createRef();
        return acc;
    }, {});

    const handleClick = id => {
        refs[id].current.scrollIntoView({
            behavior: 'smooth',
            block: 'start',
        });
        setSidebarMenuMob(false);
    };

    useEffect(() => {
        if (websiteDet && webDe) {
            setTimeout(() => {
                handlePrint();
            }, 100);
        }
    }, [websiteDet, handlePrint, webDe]);

    const handleOnScroll = () => {
        var scrollTop = myRef.current.scrollTop;
        var LeftInnerSection = document.getElementById('allDOTabs').childNodes;
        var RightInnerSection = document.getElementById('allTabSection').childNodes;
        RightInnerSection.forEach(item => {
            var top = item.offsetTop - 90;
            var height = item.offsetHeight;
            if (top - 5 <= scrollTop && top + height > scrollTop) {
                var current = item.id;
                LeftInnerSection.forEach(leftItem => {
                    leftItem.classList.remove('active');
                });
                document.getElementById(current).classList.add('active');
            }
        });
    };

    const handleMenuWidthToggle = () => {
        if (matche768) {
            setSidebarMenuMob(prevOpen => !prevOpen);
        } else {
            setSidebarMenuWidth(prevOpen => !prevOpen);
        }
    };

    if (loading) {
        return <Ripple customLoading={loading} />;
    }

    if (notFound) {
        return (
            <Div>
                <H2>Instareport not found. Redirecting...</H2>
            </Div>
        );
    }

    return (
        <Dialog open={true} dialogType={'fullWidth'}>
            {!loading && (
                <Div className={'ExternalPage '}>
                    <GetInTouch
                        open={getInTouch}
                        onClose={handleGetInTouchClose}
                        details={content?.businessDetails}
                        user={content?.userDetails}
                        preview={preview}
                    />
                    <TopBar>
                        <Div className={'HeaderBar ExternalPageHeaderBar'}>
                            <Div className={'EPHBTitle'}>
                                <IconButton onClick={handleMenuWidthToggle}>
                                    <DCHorizontal3Line />
                                </IconButton>
                                <H1 className={'HeaderBarTitle'}>Online Business Report</H1>
                            </Div>
                            <Div className={'EPHBOption'}>
                                {matche768 ? (
                                    <>
                                        <IconButton
                                            customClass={'BlackOutlineBtn'}
                                            buttonType={'headerBtn'}
                                        >
                                            <PDFDownloadLink
                                                document={<DownloadComponent content={content} />}
                                                fileName="downloadInstaReport.pdf"
                                                style={{ color: 'inherit' }}
                                            >
                                                <DCPDF />
                                            </PDFDownloadLink>
                                        </IconButton>
                                        <IconButton
                                            buttonType={'headerBtn'}
                                            onClick={() => {
                                                setWebsiteDet(true);
                                                setWebDe(true);
                                            }}
                                            customClass={'BlackOutlineBtn'}
                                            id={'printButton'}
                                        >
                                            <DCPrinter />
                                        </IconButton>
                                    </>
                                ) : (
                                    <>
                                        <Button
                                            iconName={<DCPDF />}
                                            buttonClass={'BlackOutlineBtn'}
                                            buttonType={'headerBtn'}
                                        >
                                            <PDFDownloadLink
                                                document={<DownloadComponent content={content} />}
                                                fileName="downloadInstaReport.pdf"
                                                style={{ color: 'inherit' }}
                                            >
                                                {({ loading }) => {
                                                    return loading ? (
                                                        <Span>Loading document...</Span>
                                                    ) : (
                                                        <Span className="BtnText">
                                                            Download now!
                                                        </Span>
                                                    );
                                                }}
                                            </PDFDownloadLink>
                                        </Button>
                                        <Button
                                            buttonType={'headerBtn'}
                                            onClick={() => {
                                                setWebsiteDet(true);
                                                setWebDe(true);
                                            }}
                                            iconName={<DCPrinter />}
                                            buttonClass={'BlackOutlineBtn'}
                                            id={'printButton'}
                                        >
                                            <Span className="BtnText">Print Report</Span>
                                        </Button>
                                    </>
                                )}
                            </Div>
                        </Div>
                    </TopBar>
                    <Div className={'ExternalPageBody'}>
                        <Div
                            className={
                                'EPBInner ' +
                                (sidebarMenuWidth === true ? 'EPSMinimize' : '') +
                                (sidebarMenuMob ? ' EPSMSMM' : '')
                            }
                        >
                            <Sidebar
                                handleClick={handleClick}
                                scores={content?.overallScore}
                                details={content?.businessDetails}
                                user={content?.userDetails}
                                branding={branding}
                                preview={preview}
                            />
                            <Div className={'EPBTabSections'}>
                                <Div
                                    className={'EPBTSInner'}
                                    id={'allTabSection'}
                                    reference={myRef}
                                    onScroll={handleOnScroll}
                                >
                                    {list.map(item => (
                                        <Div
                                            className={'EPComponentMain'}
                                            id={'TabID' + item.id}
                                            key={item.id}
                                            reference={refs[item.id]}
                                        >
                                            {item.componentName}
                                        </Div>
                                    ))}
                                </Div>
                            </Div>
                        </Div>
                    </Div>
                </Div>
            )}
        </Dialog>
    );
};

export default ExternalPage;
