import React from 'react';

import { SVGIcon } from '../../UIKit/index';

export default function DCWatch(props) {
    return (
        <SVGIcon {...props} viewBox="0 0 512 512">
            <g>
                <path d="M80.35,89.11c0,0,54.16-70.05,164.76-76.86c0,0,56.11-6.49,118.05,24.65c0,0,71.35,31.46,109.3,106.7  c0,0,27.84,46.05,27.84,103.78v17.51c0,0-1.57,88.86-59.63,150.16c0,0-63.57,81.41-176.43,84.7h-27.57c0,0-114.59-3.62-184.22-111.3  c0,0-84.32-113.51-8.43-250.38c0,0,30.16-12,27.73,18.81c0,0-20.81,39.57-23.62,79.35l-0.86,23.14c0,0,2.81,72.43,36.97,113.73  c0,0,31.14,50.59,84.54,72.22c0,0,28.97,17.3,79.14,19.68l13.62-0.22c0,0,62.51,0.72,112.89-36.9c0,0,88.36-52.88,90.95-164.45  c0,0,10.65-122.76-108.05-189.57c0,0-125.19-80.43-249.08,35.03C108.24,108.89,76.95,123.32,80.35,89.11z" />
                <path d="M239.27,115.16c0,0,2.35-14.22,15.16-15.76h5.19c0,0,13.54,2.08,14.35,15.65V247.7l83.46,66.7  c0,0,7.89,8.32,1.08,19.78c0,0-5.84,11.03-19.46,8.76l-3.14-1.41l-52.65-41.51l-36.86-29.84c0,0-6.49-4.97-7.14-11.78V115.16z" />
            </g>
        </SVGIcon>
    );
}
