import React, { Fragment, useCallback, useEffect, useRef, useState } from 'react';
import DCRightArrow from 'Assets/icons/DCRightArrow';
import DCCrossClose from 'Assets/icons/DCCrossClose';
import {
    Div,
    PageContainer,
    PageView,
    Span,
    RadioButton,
    Button,
    Image,
    DropdownOptions,
    IconButton,
    Strong,
    Dialog,
    TopBar,
    H1,
    Input,
} from 'UIKit/index';
import './style.scss';
import { formatNumberString, getInterval } from 'Utils';
import AddNewCard from './addNewCard';
import {
    deletePaymentCard,
    getPaymentCards,
    checkout,
    singleItemCheckout,
    applyPromoCode,
    removePromoCode,
} from 'apis/store/index.api';
import { useQuery, useQueryClient } from 'react-query';
import { getAccountDetails } from 'Utils/localStorageUtils';
import CardInput from 'Components/CardInput/cardInput';
import DCTrash from 'Assets/icons/DCTrash';
import useToast from 'Modules/Toasts/useToast';
import PaymentProcess from './paymentProcess/paymentProcess';
import { Redirect } from 'react-router-dom/cjs/react-router-dom.min';
import { Confirmation } from 'Components/index';
import { getAccountType, isAuthenticated } from 'Utils/auth';
import { formateDate } from 'Utils/dateUtils';
import _ from 'lodash';
import { getUserPaymentDetails } from 'apis/settings/index.api';

const Checkout = props => {
    const {
        all,
        data,
        totals,
        handleCongrats,
        siteApp = false,
        dialogMode,
        handleDialogClose,
        singleItem,
        actualCost,
        isSubscription = true,
        separateTotal = false,
        externalAction = false,
        isSetup = true,
        externalActionData = {},
        businessId,
        subscriptionExplicitId,
        fetchSingleItemData,
        waive_setup,
        setOrderSuccess,
    } = props;

    const queryClient = useQueryClient();
    const { showToast } = useToast();
    const [selectedMethod, setSelectedMethod] = useState('existing-card');
    const onMethodChange = useCallback(e => {
        setSelectedMethod(e.target.value);
    }, []);
    const [cardDropdown, setCardDropdown] = useState(false);
    const [selectedCard, setSelectedCard] = useState(null);
    const cardBtnRef = useRef();
    const [openDeleteConfirmation, setOpenDeleteConfirmation] = useState(false);
    const [loadingCard, setLoadingCard] = useState(false);
    const [cardIData, setCardIdData] = useState();
    const [paymentMethods, setPaymentMethods] = useState(false);
    const [loader, setLoader] = useState(false);
    const [congratsPage, setCongratsPage] = useState(false);
    const [promocode, setPromocode] = useState('');
    const [loadingPromo, setLoadingPromo] = useState(false);
    const [_data, setData] = useState(null);
    const [_actualCost, setActualCost] = useState(null);

    useEffect(() => {
        if (totals?.promocode?.length > 0) {
            setPromocode(totals.promocode);
        }
    }, [totals?.promocode]);

    useEffect(() => {
        setData(data);
        setActualCost(actualCost);
    }, [data, actualCost]);

    useEffect(() => {
        if (totals?.promocode?.length > 0) {
            setPromocode(totals.promocode);
        }
    }, [totals?.promocode]);

    const handleApplyPromo = async () => {
        try {
            setLoadingPromo(true);
            const res = await applyPromoCode({ promotion_code: promocode });
            if (res.success) {
                showToast({
                    type: 'success',
                    message: 'Promo code applied',
                });
                if (singleItem) {
                    const response = await fetchSingleItemData();
                    if (response.success) {
                        setData({ [response.data.buyer?.id]: [response.data] });
                        setActualCost(response.data.item?.actual_cost);
                    }
                } else queryClient.invalidateQueries(['cart']);
            }
        } catch (err) {
            showToast({
                type: 'error',
                message: err || 'Something went wrong',
            });
        } finally {
            setLoadingPromo(false);
        }
    };

    const handleCouponRemove = async () => {
        try {
            setLoadingPromo(true);
            const res = await removePromoCode();
            if (res.success) {
                setPromocode('');
                if (singleItem) {
                    const response = await fetchSingleItemData();
                    if (response.success) {
                        setData({ [response.data.buyer?.id]: [response.data] });
                        setActualCost(response.data.item?.actual_cost);
                    }
                } else queryClient.invalidateQueries(['cart']);
            }
        } catch (err) {
            showToast({
                type: 'error',
                message: err || 'Something went wrong',
            });
        } finally {
            setLoadingPromo(false);
        }
    };

    const handleCardOptions = useCallback(item => {
        setSelectedCard(item);
        setCardDropdown(false);
    }, []);

    const handleCardDropdownToggle = useCallback(() => {
        setCardDropdown(prevOpen => !prevOpen);
    }, []);

    const { data: userConfigs } = useQuery('user-configs-payment', () => getUserPaymentDetails());

    const { data: paymentCards } = useQuery(['payment-cards'], async () => getPaymentCards(), {
        staleTime: 1000 * 60 * 10, // 10 minutes
        cacheTime: 1000 * 60 * 15, // 15 minutes
        keepPreviousData: true,
        enabled: isAuthenticated(),
    });

    // Select the selected card from localStorage auth object
    useEffect(() => {
        if (paymentCards?.data && getAccountDetails()?.plan && userConfigs) {
            const card = paymentCards.data?.find(
                el => el.id === getAccountDetails().plan.selected_payment_method?.id,
            );
            if (card) setSelectedCard(card);
            else {
                if (userConfigs.data) {
                    if (paymentCards.data.length) {
                        if (userConfigs.data.default_source) {
                            let defaultCard = paymentCards.data.find(
                                obj => obj.id === userConfigs.data.default_source,
                            );
                            setSelectedCard(defaultCard);
                        } else {
                            setSelectedCard(paymentCards.data[0]);
                        }
                    }
                } else {
                    setSelectedMethod('new-card');
                }
            }
        }
    }, [paymentCards, userConfigs]);

    const handleCardDelete = useCallback(
        async cardId => {
            try {
                setLoadingCard(true);
                let res = await deletePaymentCard(cardId);
                if (res.success) {
                    queryClient.invalidateQueries(['payment-cards']);
                    showToast({
                        type: 'success',
                        message: 'Card Deleted',
                    });
                }
                setOpenDeleteConfirmation(false);
            } catch (err) {
                let errorMessage = err;
                if (
                    errorMessage ===
                    "Can't delete the last payment method on the account. Please add a new payment method before removing this one"
                ) {
                    errorMessage =
                        'You cannot remove the primary payment method on file without adding a new one first.';
                }
                showToast({
                    type: 'error',
                    message: errorMessage || 'Something went wrong',
                });
            } finally {
                setLoadingCard(false);
            }
        },
        [queryClient, showToast],
    );

    const generateCardOptions = useCallback(() => {
        return paymentCards?.data?.map(item => {
            return {
                label: (
                    <Div className={'COPICPMLCardOption'}>
                        <Button
                            buttonClass={`DropdownOptionsBtn SPAPMCDOBtn ${
                                selectedCard?.id === item.id ? 'active' : ''
                            }`}
                            onClick={() => {
                                handleCardOptions(item);
                            }}
                        >
                            <CardInput
                                disabled={true}
                                cardBrand={item.card?.brand}
                                cartNumberValue={`**** **** **** ${item.card?.last4}`}
                                expiryDateValue={`${item.card?.exp_month}/${item.card?.exp_year}`}
                                cvcValue={'***'}
                            />
                        </Button>
                        <IconButton
                            buttonType={'RedFillBtn circleBtn'}
                            onClick={() => {
                                setCardIdData(item.id);
                                setOpenDeleteConfirmation(true);
                                setCardDropdown(false);
                            }}
                        >
                            <DCTrash />
                        </IconButton>
                    </Div>
                ),
            };
        });
    }, [handleCardOptions, paymentCards, selectedCard]);

    const handlePaymentMethods = useCallback(() => {
        setPaymentMethods(prevOpen => !prevOpen);
    }, []);

    const handleDelete = useCallback(() => {
        handleCardDelete(cardIData);
    }, [handleCardDelete, cardIData]);

    const handleDeleteClose = useCallback(() => {
        setOpenDeleteConfirmation(false);
    }, []);

    if (_data) {
        if (Object.keys(_data).length === 0) {
            showToast({ message: 'Your Cart is empty. Please add items first.', type: 'error' });
            if (getAccountType() === 'main') {
                return <Redirect to="/store" />;
            } else {
                return <Redirect to="/dashboard" />;
            }
        }
    }

    const generateDisplay = () => {
        return (
            <Fragment>
                {(loader || congratsPage) && (
                    <PaymentProcess
                        loading={loader}
                        congratsPage={congratsPage}
                        setCongratsPage={setCongratsPage}
                    />
                )}
                <Div className={'CheckoutPage' + (dialogMode ? '' : ' CheckoutPagePadding')}>
                    <Div className="CheckoutPageInner">
                        <Div className="COPIRow">
                            <Div className="COPICol">
                                <Div className="COPICHead">
                                    <Span>Order Details</Span>
                                </Div>
                                <Div className="COPICartItems">
                                    <Div className="COPICIHead">
                                        <Div className="COPICITitle">Product/Service</Div>
                                        <Div className="COPICITitle">Quantity</Div>
                                        <Div className="COPICITitle">Recurring</Div>
                                        {isSetup && <Div className="COPICITitle">Setup</Div>}
                                    </Div>
                                    <Div className="COPICIList">
                                        {_data &&
                                            Object.keys(_data).map((item, index) => {
                                                return (
                                                    <Div
                                                        className="COPICIGroup"
                                                        key={`list${index + 1}`}
                                                    >
                                                        <Div className="COPICIGTitle">
                                                            {_data[item][0].buyer.name}
                                                        </Div>
                                                        {_data[item].map((product, _index) => {
                                                            return (
                                                                <Div
                                                                    className="COPICIGProduct"
                                                                    key={`product-${_index + 1}`}
                                                                >
                                                                    <Div className="COPICIGPInfo">
                                                                        <Div className="COPICIGPIIcon">
                                                                            <Image
                                                                                src={
                                                                                    product.item
                                                                                        .image
                                                                                }
                                                                                displayName={''}
                                                                            />
                                                                        </Div>
                                                                        <Div className="COPICIGPIDetail">
                                                                            <Div className="COPICIGPIDTitle">
                                                                                {product.item.name}
                                                                            </Div>
                                                                            <Div className="COPICIGPIDType">
                                                                                <Span>
                                                                                    {getInterval(
                                                                                        product.item
                                                                                            .type,
                                                                                    )}
                                                                                </Span>
                                                                            </Div>
                                                                            {product.item
                                                                                .transaction_type ===
                                                                                'downgrade' && (
                                                                                <Div
                                                                                    className="COPICIGPIDUD"
                                                                                    style={{
                                                                                        backgroundColor:
                                                                                            '#f44336',
                                                                                    }}
                                                                                >
                                                                                    Downgrade
                                                                                </Div>
                                                                            )}
                                                                            {product.item
                                                                                .transaction_type ===
                                                                                'upgrade' && (
                                                                                <Div
                                                                                    className="COPICIGPIDUD"
                                                                                    style={{
                                                                                        backgroundColor:
                                                                                            '#4caf50',
                                                                                    }}
                                                                                >
                                                                                    Upgrade
                                                                                </Div>
                                                                            )}
                                                                        </Div>
                                                                    </Div>
                                                                    <Div className="COPICIGPQun">
                                                                        <Strong>Quantity</Strong>{' '}
                                                                        <Span>
                                                                            {product.item.qty}
                                                                        </Span>
                                                                    </Div>
                                                                    <Div className="COPICIGPRec">
                                                                        <Strong>Recurring</Strong>
                                                                        <Div
                                                                            className={
                                                                                product.item
                                                                                    .actual_cost
                                                                                    ?.discount_amount >
                                                                                    0 ||
                                                                                (singleItem &&
                                                                                    product.item
                                                                                        .amount !==
                                                                                        product.item
                                                                                            .actual_cost
                                                                                            ?.recurring_fee)
                                                                                    ? ' discounted'
                                                                                    : ' '
                                                                            }
                                                                        >
                                                                            <Span
                                                                                className={
                                                                                    product.item
                                                                                        .actual_cost
                                                                                        ?.discount_amount >
                                                                                        0 ||
                                                                                    (singleItem &&
                                                                                        product.item
                                                                                            .amount !==
                                                                                            product
                                                                                                .item
                                                                                                .actual_cost
                                                                                                ?.recurring_fee)
                                                                                        ? 'strike'
                                                                                        : ''
                                                                                }
                                                                            >
                                                                                $
                                                                                {formatNumberString(
                                                                                    (product.item
                                                                                        .amount *
                                                                                        product.item
                                                                                            .qty) /
                                                                                        100,
                                                                                    true,
                                                                                )}
                                                                            </Span>
                                                                            {(product.item
                                                                                .actual_cost
                                                                                ?.discount_amount >
                                                                                0 ||
                                                                                (singleItem &&
                                                                                    product.item
                                                                                        .amount !==
                                                                                        product.item
                                                                                            .actual_cost
                                                                                            ?.recurring_fee)) && (
                                                                                <Span>
                                                                                    $
                                                                                    {formatNumberString(
                                                                                        (product
                                                                                            .item
                                                                                            .actual_cost
                                                                                            .recurring_fee ||
                                                                                            product
                                                                                                .item
                                                                                                .actual_cost
                                                                                                ?.amount_due) /
                                                                                            100,
                                                                                        true,
                                                                                    )}
                                                                                </Span>
                                                                            )}
                                                                        </Div>
                                                                    </Div>
                                                                    {isSetup && (
                                                                        <Div className="COPICIGPRec">
                                                                            <Strong>Setup</Strong>
                                                                            <Div
                                                                                className={
                                                                                    product.item
                                                                                        .setup_subtotal
                                                                                        ? product
                                                                                              .item
                                                                                              .setup_amount !==
                                                                                          product
                                                                                              .item
                                                                                              .setup_subtotal
                                                                                            ? ' discounted'
                                                                                            : ''
                                                                                        : product
                                                                                              .item
                                                                                              .setup_amount
                                                                                        ? product
                                                                                              .item
                                                                                              .setup_amount !==
                                                                                          product
                                                                                              .item
                                                                                              .actual_cost
                                                                                              .setup_fee
                                                                                            ? ' discounted'
                                                                                            : ''
                                                                                        : ''
                                                                                }
                                                                            >
                                                                                <Span
                                                                                    className={
                                                                                        product.item
                                                                                            .setup_subtotal
                                                                                            ? product
                                                                                                  .item
                                                                                                  .setup_amount !==
                                                                                              product
                                                                                                  .item
                                                                                                  .setup_subtotal
                                                                                                ? 'strike'
                                                                                                : ''
                                                                                            : product
                                                                                                  .item
                                                                                                  .setup_amount
                                                                                            ? product
                                                                                                  .item
                                                                                                  .setup_amount !==
                                                                                              product
                                                                                                  .item
                                                                                                  .actual_cost
                                                                                                  .setup_fee
                                                                                                ? 'strike'
                                                                                                : ''
                                                                                            : ''
                                                                                    }
                                                                                >
                                                                                    $
                                                                                    {formatNumberString(
                                                                                        product.item
                                                                                            .setup_amount /
                                                                                            100,
                                                                                        true,
                                                                                    )}
                                                                                </Span>
                                                                                {product.item
                                                                                    .setup_subtotal &&
                                                                                    product.item
                                                                                        .setup_amount &&
                                                                                    product.item
                                                                                        .setup_amount !==
                                                                                        product.item
                                                                                            .setup_subtotal && (
                                                                                        <Span>
                                                                                            $
                                                                                            {formatNumberString(
                                                                                                product
                                                                                                    .item
                                                                                                    .setup_subtotal /
                                                                                                    100,
                                                                                                true,
                                                                                            )}
                                                                                        </Span>
                                                                                    )}
                                                                                {product.item
                                                                                    .setup_amount &&
                                                                                    product.item
                                                                                        .actual_cost
                                                                                        .setup_fee &&
                                                                                    product.item
                                                                                        .setup_amount !==
                                                                                        product.item
                                                                                            .actual_cost
                                                                                            .setup_fee && (
                                                                                        <Span>
                                                                                            $
                                                                                            {formatNumberString(
                                                                                                product
                                                                                                    .item
                                                                                                    .actual_cost
                                                                                                    .setup_fee /
                                                                                                    100,
                                                                                                true,
                                                                                            )}
                                                                                        </Span>
                                                                                    )}
                                                                            </Div>
                                                                        </Div>
                                                                    )}
                                                                </Div>
                                                            );
                                                        })}
                                                    </Div>
                                                );
                                            })}
                                    </Div>
                                </Div>
                                {singleItem && (
                                    <>
                                        {isSubscription && (
                                            <Div className="COPIFooter">
                                                <Div className="COPIAMDue">
                                                    <Div className="COPIAMDTitle">
                                                        <span
                                                            style={{
                                                                color: '#787878',
                                                                fontSize: '15px',
                                                            }}
                                                        >
                                                            Remaining time on software after{' '}
                                                            {formateDate(new Date(), false, true)}
                                                        </span>
                                                    </Div>
                                                    <Div className="COPIAMDPrice">
                                                        $
                                                        {formatNumberString(
                                                            _actualCost?.remaining_time / 100,
                                                            true,
                                                        )}
                                                    </Div>
                                                </Div>
                                                <Div className="COPIAMDue">
                                                    <Div className="COPIAMDTitle">
                                                        <span
                                                            style={{
                                                                color: '#787878',
                                                                fontSize: '15px',
                                                            }}
                                                        >
                                                            Unused time on software after{' '}
                                                            {formateDate(new Date(), false, true)}
                                                        </span>{' '}
                                                    </Div>
                                                    <Div className="COPIAMDPrice">
                                                        $
                                                        {formatNumberString(
                                                            _actualCost?.unused_time / 100,
                                                            true,
                                                        )}
                                                    </Div>
                                                </Div>
                                            </Div>
                                        )}
                                        <Div className="COPIFooter">
                                            <Div className="COPIAMDue COPIAMACDue">
                                                <Div className="COPIAMDTitleSpace">Sub-total</Div>
                                                <Div className="COPIAMDPrice COPIAMDPriceSpace">
                                                    $
                                                    {formatNumberString(
                                                        _actualCost?.subtotal / 100,
                                                        true,
                                                    )}
                                                </Div>
                                            </Div>
                                            {_actualCost?.subtotal > 0 &&
                                                _actualCost?.beginning_balance < 0 && (
                                                    <Div className="COPIAMDue COPIAMACDue">
                                                        <Div className="COPIAMDTitleSpace">
                                                            Applied balance
                                                        </Div>
                                                        <Div className="COPIAMDPrice COPIAMDPriceSpace">
                                                            $
                                                            {_actualCost?.beginning_balance * -1 -
                                                                _actualCost?.subtotal >=
                                                            0
                                                                ? formatNumberString(
                                                                      _actualCost?.subtotal / 100,
                                                                      true,
                                                                  )
                                                                : formatNumberString(
                                                                      (_actualCost?.beginning_balance *
                                                                          -1) /
                                                                          100,
                                                                      true,
                                                                  )}
                                                        </Div>
                                                    </Div>
                                                )}
                                            {_actualCost?.subtotal > _actualCost?.amount_due &&
                                                _actualCost?.beginning_balance < 0 && (
                                                    <Div className="COPIAMDue COPIAMACDue">
                                                        <Div className="COPIAMDTitleSpace">
                                                            Available credit
                                                        </Div>
                                                        <Div className="COPIAMDPrice COPIAMDPriceSpace">
                                                            $
                                                            {formatNumberString(
                                                                (_actualCost?.beginning_balance *
                                                                    -1) /
                                                                    100,
                                                                true,
                                                            )}
                                                        </Div>
                                                    </Div>
                                                )}
                                            <Div className="COPIAMDue COPIAMACDue">
                                                <Div className="COPIAMDTitleSpace">Amount due</Div>
                                                <Div className="COPIAMDPrice COPIAMDPriceSpace">
                                                    $
                                                    {formatNumberString(
                                                        _actualCost?.amount_due / 100,
                                                        true,
                                                    )}
                                                </Div>
                                            </Div>
                                        </Div>
                                    </>
                                )}

                                {/* Multiple Item Start */}
                                {isSubscription && separateTotal && (
                                    <Div className="COPIFooter">
                                        <Div className="COPISubTotal">
                                            <Div className="COPIFTitle">Sub-total</Div>
                                            <Div className="COPIFTRec">
                                                <Div className="COPIFTRPrice">
                                                    $
                                                    {formatNumberString(
                                                        _.sumBy(all, o => {
                                                            if (o.item.type === 'monthly') {
                                                                return (
                                                                    o.item.actual_cost?.amount_due |
                                                                    0
                                                                );
                                                            } else {
                                                                return 0;
                                                            }
                                                        }) / 100,
                                                        true,
                                                    ) || 0}
                                                </Div>
                                                <Div className="COPIFTRTime">Monthly</Div>
                                            </Div>
                                            <Div className="COPIFTSet">
                                                <Div className="COPIFTSPrice">
                                                    $
                                                    {formatNumberString(
                                                        _.sumBy(all, o => {
                                                            if (o.item.type === 'monthly') {
                                                                return o.item.setup_subtotal | 0;
                                                            } else {
                                                                return 0;
                                                            }
                                                        }) / 100,
                                                        true,
                                                    ) || 0}
                                                </Div>
                                                <Div className="COPIFTSTime">One-time</Div>
                                            </Div>
                                        </Div>

                                        <Div className="COPIAMDue">
                                            <Div className="COPIAMDTitle">
                                                Amount due
                                                {totals?.promocode?.length > 0 &&
                                                    totals?.total_discount_amounts > 0 && (
                                                        <span className="DCCoupon">
                                                            {' '}
                                                            ({totals.promocode} Coupon Applied)
                                                        </span>
                                                    )}
                                            </Div>

                                            <Div className="COPIAMDPrice">
                                                $
                                                {formatNumberString(totals?.amount_due / 100, true)}
                                            </Div>
                                        </Div>
                                    </Div>
                                )}

                                {/* Multiple Item End */}
                                <Div className="COPICont">
                                    <Button
                                        onClick={handlePaymentMethods}
                                        buttonType={'BlueFillBtn'}
                                        iconName={<DCRightArrow />}
                                        iconPlacement={'right'}
                                    >
                                        Continue
                                    </Button>
                                </Div>
                            </Div>
                            <Div className={'COPICol COPIMethod ' + (paymentMethods ? 'open' : '')}>
                                <Div>
                                    <Div className="COPICHead">
                                        <Span>Apply Promo Code</Span>{' '}
                                        <IconButton
                                            buttonType={'circleBtn'}
                                            onClick={handlePaymentMethods}
                                        >
                                            <DCCrossClose />
                                        </IconButton>
                                    </Div>

                                    <Div className="COPICPromoCode">
                                        <Input
                                            type="text"
                                            placeholder={'Type promo code...'}
                                            value={promocode}
                                            onChange={e => {
                                                setPromocode(e.target.value?.toUpperCase());
                                            }}
                                            disabled={
                                                totals?.promocode?.length > 0 &&
                                                totals?.total_discount_amounts > 0
                                            }
                                        />
                                        <Button
                                            buttonType={'BlueFillBtn'}
                                            onClick={handleApplyPromo}
                                            loading={loadingPromo}
                                            disabled={
                                                totals?.promocode?.length > 0 &&
                                                totals?.total_discount_amounts > 0
                                            }
                                        >
                                            Apply
                                        </Button>
                                    </Div>

                                    {totals?.promocode?.length > 0 &&
                                        totals?.total_discount_amounts > 0 && (
                                            <Button
                                                buttonType={'SimpleLink'}
                                                buttonClass={'DCCouponRemove DCCoupon'}
                                                onClick={handleCouponRemove}
                                                disabled={loadingPromo}
                                            >
                                                Remove coupon {totals?.promocode}
                                            </Button>
                                        )}
                                </Div>
                                <Div className="COPICHead">
                                    <Span>Payment Method</Span>{' '}
                                </Div>
                                <Div className="COPICPayMethList">
                                    <Div className="COPICPMLItem">
                                        {paymentCards?.data.length > 0 && (
                                            <Div className="COPICPMLIRadio">
                                                <RadioButton
                                                    label={'Use existing card:'}
                                                    name="existing-card"
                                                    id={'existing-card'}
                                                    value="existing-card"
                                                    checkColor={'var(--dark-blue)'}
                                                    fullWidth={true}
                                                    onChange={onMethodChange}
                                                    checked={selectedMethod === 'existing-card'}
                                                />
                                            </Div>
                                        )}
                                        {selectedMethod === 'existing-card' && (
                                            <Div
                                                className={
                                                    'DCDropdownSelect fullWidth COPICPMLCards'
                                                }
                                            >
                                                <Button
                                                    buttonClass={
                                                        'DCDSMainBtn ' +
                                                        (cardDropdown === true ? 'focus' : '') +
                                                        (selectedCard ? ' DCDSMSelect' : '')
                                                    }
                                                    reference={cardBtnRef}
                                                    onClick={handleCardDropdownToggle}
                                                >
                                                    {selectedCard ? (
                                                        <CardInput
                                                            disabled={true}
                                                            cardBrand={selectedCard.card?.brand}
                                                            cartNumberValue={`**** **** **** ${selectedCard.card?.last4}`}
                                                            expiryDateValue={`${selectedCard.card?.exp_month}/${selectedCard.card?.exp_year}`}
                                                            cvcValue={'***'}
                                                        />
                                                    ) : (
                                                        'Select a payment method'
                                                    )}
                                                </Button>
                                                <DropdownOptions
                                                    options={generateCardOptions()}
                                                    onClose={handleCardDropdownToggle}
                                                    open={cardDropdown}
                                                    reference={cardBtnRef}
                                                    viewHeight={
                                                        paymentCards?.data?.length <= 5
                                                            ? paymentCards.data.length * 40
                                                            : 200
                                                    }
                                                    itemSize={40}
                                                    placement={'bottom-end'}
                                                    wrapperWidth={cardBtnRef.current?.offsetWidth}
                                                />
                                            </Div>
                                        )}
                                    </Div>

                                    <Div className="COPICPMLItem">
                                        <Div className="COPICPMLIRadio">
                                            <RadioButton
                                                label={'Add new card'}
                                                name="new-card"
                                                id={'new-card'}
                                                value="new-card"
                                                checkColor={'var(--dark-blue)'}
                                                fullWidth={true}
                                                onChange={onMethodChange}
                                                checked={selectedMethod === 'new-card'}
                                            />
                                        </Div>
                                    </Div>

                                    {selectedMethod === 'new-card' && (
                                        <AddNewCard
                                            handleSuccess={() => {
                                                setSelectedMethod('existing-card');
                                                queryClient.invalidateQueries(['payment-cards']);
                                            }}
                                        />
                                    )}
                                    {selectedMethod === 'existing-card' && (
                                        <Div className="COPICPayBtn">
                                            <Button
                                                buttonType={'BlueFillBtn'}
                                                fullWidth
                                                iconName={<DCRightArrow />}
                                                iconPlacement={'right'}
                                                onClick={async () => {
                                                    setLoader(true);
                                                    let res;
                                                    try {
                                                        if (singleItem) {
                                                            const body = {
                                                                business: businessId
                                                                    ? businessId
                                                                    : getAccountDetails()
                                                                          .business_id,
                                                                price: _data[
                                                                    Object.keys(_data)[0]
                                                                ]?.[0].item?.price_id,
                                                                card: selectedCard.id,
                                                            };
                                                            if (waive_setup)
                                                                body.waive_setup = true;
                                                            if (isSubscription) {
                                                                body.subscription =
                                                                    subscriptionExplicitId
                                                                        ? subscriptionExplicitId
                                                                        : getAccountDetails()?.plan
                                                                              ?.id;
                                                            } else if (waive_setup) {
                                                                body.reactivate_subscription =
                                                                    subscriptionExplicitId;
                                                            }

                                                            if (externalAction) {
                                                                body.external_action = {
                                                                    ...externalActionData,
                                                                };
                                                            }
                                                            res = await singleItemCheckout(body, {
                                                                type: 'finalize',
                                                            });
                                                        } else
                                                            res = await checkout(selectedCard.id);
                                                        setLoader(false);
                                                        if (res.success) {
                                                            if (
                                                                res.message ===
                                                                'FINISHED WITH ERRORS'
                                                            ) {
                                                                showToast({
                                                                    message:
                                                                        res.data[0]?.message ||
                                                                        'Something went wrong',
                                                                    type: 'error',
                                                                });
                                                            } else {
                                                                setCongratsPage(true);
                                                                setTimeout(() => {
                                                                    handleCongrats(res.data);
                                                                    if (siteApp) {
                                                                        setOrderSuccess(true);
                                                                    }
                                                                }, 4000);
                                                            }
                                                        } else {
                                                            showToast({
                                                                message: 'Something went wrong',
                                                                type: 'error',
                                                            });
                                                        }
                                                    } catch (err) {
                                                        setLoader(false);
                                                        showToast({
                                                            message: err || 'Something went wrong',
                                                            type: 'error',
                                                        });
                                                    }
                                                }}
                                                disabled={
                                                    !selectedCard || selectedMethod === 'new-card'
                                                    // loadingPromo
                                                }
                                            >
                                                Place Your Order
                                            </Button>
                                        </Div>
                                    )}
                                </Div>
                            </Div>
                        </Div>
                    </Div>
                </Div>
            </Fragment>
        );
    };

    return (
        <Fragment>
            {openDeleteConfirmation && (
                <Confirmation
                    open={openDeleteConfirmation}
                    onClose={handleDeleteClose}
                    title={`Delete Card`}
                    confirmationType={'Are you sure you want delete the card ?'}
                    type={'delete'}
                    loading={loadingCard}
                    acceptButton={{
                        title: 'Delete',
                        onClick: handleDelete,
                    }}
                    declinedButton={{
                        title: 'Cancel',
                        onClick: handleDeleteClose,
                    }}
                />
            )}

            {dialogMode ? (
                <Dialog
                    dialogType={'centerZoom sizeLg'}
                    open={true}
                    onClose={() => {
                        if (!(loader || congratsPage)) handleDialogClose();
                    }}
                >
                    <TopBar DialogTopBar>
                        <Div className={'HeaderBar'}>
                            <H1 className={'HeaderBarTitle'}>Checkout</H1>
                            <Div className={'HeaderBarClose'}>
                                <IconButton
                                    onClick={handleDialogClose}
                                    disabled={loader || congratsPage}
                                >
                                    <DCCrossClose />
                                </IconButton>
                            </Div>
                        </Div>
                    </TopBar>
                    <Div className={'BodyBox '}>
                        <Div className={'BodyBoxInner'}>{generateDisplay()}</Div>
                    </Div>
                </Dialog>
            ) : (
                <PageContainer>
                    <PageView
                        noSidebar
                        classes={{
                            container: loader || congratsPage ? 'CheckoutPageContainer' : '',
                        }}
                    >
                        {generateDisplay()}
                    </PageView>
                </PageContainer>
            )}
        </Fragment>
    );
};

export default Checkout;
