import React from 'react';

import { SVGIcon } from '../../UIKit/index';

export default function DCFileUpload1(props) {
    return (
        <SVGIcon {...props} viewBox="0 0 512 492.7">
            <g>
                <path d="M503.4,343.6c-5.5-5.6-13.1-8.8-20.9-8.6H351.1c-4.2,11.4-11.8,21.2-21.7,28.3   c-9.8,7.3-21.8,11.2-34,11.1h-78.8c-12.2,0.1-24.2-3.8-34-11.1c-9.9-7.1-17.5-16.9-21.7-28.3H29.5c-7.9-0.1-15.4,3-20.9,8.6   c-5.7,5.5-8.8,13.2-8.6,21.1v98.5c-0.1,7.9,3,15.4,8.6,20.9c5.5,5.6,13.1,8.8,20.9,8.6h452.9c16.2,0.1,29.5-13,29.5-29.3   c0-0.1,0-0.2,0-0.3v-98.5C512.2,356.8,509.1,349.2,503.4,343.6z M388,447.4c-7.6,7.4-19.9,7.3-27.3-0.4c-7.4-7.6-7.3-19.9,0.4-27.3   c7.6-7.4,19.9-7.3,27.3,0.4c3.5,3.6,5.5,8.4,5.5,13.5C393.9,438.7,391.8,443.8,388,447.4L388,447.4z M466.8,447.4   c-7.6,7.4-19.9,7.3-27.3-0.4c-7.4-7.6-7.3-19.9,0.4-27.3c7.6-7.4,19.9-7.3,27.3,0.4c3.5,3.6,5.5,8.4,5.5,13.5   C472.7,438.7,470.6,443.8,466.8,447.4L466.8,447.4z" />
                <path d="M118.1,177.6h78.8v137.8c0.1,10.8,8.9,19.5,19.7,19.7h78.8c10.8-0.1,19.5-8.9,19.7-19.7V177.6   h78.8c8.1,0.3,15.5-4.6,18.2-12.3c3.6-7.2,1.9-16-4.3-21.2L269.9,6.2c-7.4-7.6-19.7-7.8-27.3-0.4c-0.1,0.1-0.2,0.2-0.4,0.4   L104.4,144c-6.2,5.2-8,14-4.3,21.2C102.8,172.8,110.1,177.8,118.1,177.6z" />
            </g>
        </SVGIcon>
    );
}
