import React from 'react';
import PropTypes from 'prop-types';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import _ from 'lodash';
import { formatNumberString } from '../../Utils/index';
import { Div } from '../index';

import mapDataWorld from './mapDataWorld';

import './style.scss';
// Load Highcharts modules
require('highcharts/modules/map')(Highcharts);

const LocationMap = ({ height, width, margin, colorAxis, mapData, name }) => {
    // const mapOptions = {
    //     chart: {
    //         map: "custom/world",
    //         height: height || 500,
    //         width: width || null,
    //         margin: margin || [30, 0, 30, 0],
    //     },
    //     title: {
    //         text: null,
    //     },
    //     colorAxis: {
    //         min: 1,
    //         stops: colorAxis,
    //     },
    //     credits: {
    //         enabled: false,
    //     },
    //     legend: {
    //         enabled: false,
    //     },
    //     series: [
    //         {
    //             mapData: mapDataWorld,
    //             data: _.cloneDeep(mapData),
    //             states: {
    //                 hover: {
    //                   color: '#a4edba'
    //                 }
    //               }
    //         },
    //     ],
    // };
    const mapOptions = {
        chart: {
            map: 'custom/world',
            height: height || 500,
            width: width || null,
            margin: margin || [30, 0, 30, 0],
        },
        title: {
            text: null,
        },
        mapNavigation: {
            enabled: false,
            enableButtons: false,
            enableDoubleClickZoomTo: false,
        },
        tooltip: {
            pointFormatter: function () {
                return `${this.name} : ${formatNumberString(this.value)}`;
            },
        },
        cursor: 'pointer',
        colorAxis: {
            min: 1,
            stops: colorAxis,
        },
        credits: {
            enabled: false,
        },
        legend: {
            enabled: false,
        },
        series: [
            {
                name: name,
                mapData: mapDataWorld,
                tooltip: {
                    ySuffix: ' %',
                },
                joinBy: ['iso-a2', 'code'],
                data: _.cloneDeep(mapData),
            },
        ],
    };
    return (
        <Div className={'AreaSplineChart'}>
            <HighchartsReact
                highcharts={Highcharts}
                constructorType={'mapChart'}
                options={mapOptions}
            />
        </Div>
    );
};

LocationMap.propTypes = {
    height: PropTypes.number,
    width: PropTypes.number,
    margin: PropTypes.number,
    colorAxis: PropTypes.array,
    mapData: PropTypes.arrayOf(PropTypes.object).isRequired,
    name: PropTypes.string,
};

export default LocationMap;
