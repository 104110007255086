import React from 'react';

import { SVGIcon } from '../../UIKit/index';

export default function DCDealsMenu(props) {
    return (
        <SVGIcon {...props} viewBox="0 0 512 512">
            <g transform="translate(0 -0.063)">
                <g transform="translate(4.312 0.063)">
                    <path
                        fill="#E6EFFF"
                        d="M278.39,63.48L322.29,2H181.78l43.9,61.48v17.55h52.68L278.39,63.48z"
                    />
                    <path
                        fill="#2F8DFF"
                        d="M418.88,332.81c0.01-3.85-1.06-7.62-3.07-10.89l-19.59-31.52c-2.44-4.85-3.7-10.21-3.69-15.64    v-97.14c0-63.05-51.11-114.16-114.16-114.16l0,0h-52.68c-63.05,0-114.16,51.11-114.16,114.16l0,0v97.14    c0.02,5.43-1.24,10.79-3.69,15.64l-19.67,31.52c-2.02,3.27-3.08,7.05-3.07,10.89l0,0c0.1,11.21,9.16,20.26,20.36,20.36h292.79    C409.56,353.22,418.78,344.12,418.88,332.81z"
                    />
                </g>
                <path
                    fill="#ffffff"
                    d="M300.25,154.53v-5.62c-0.04-16.28-13.23-29.46-29.51-29.51h-5.62v-17.55h-17.55v17.55h-5.62   c-16.3-0.04-29.54,13.14-29.58,29.43c-0.03,12.35,7.64,23.42,19.22,27.73l15.99,5.97v33.46h-5.62c-6.6,0-11.95-5.35-11.95-11.95   c0,0,0,0,0,0l0,0v-5.62h-17.55v5.62c0.04,16.28,13.23,29.46,29.51,29.51h28.79c16.3,0.04,29.54-13.14,29.58-29.43   c0.03-12.35-7.64-23.42-19.22-27.73l-15.99-5.97v-33.46h5.62c6.6,0,11.95,5.35,11.95,11.95c0,0,0,0,0,0l0,0v5.62H300.25z    M274.96,192.81c4.62,1.83,7.67,6.27,7.72,11.24c0,6.6-5.35,11.95-11.95,11.95c0,0,0,0,0,0h-5.62v-26.88L274.96,192.81z    M247.57,163.83l-9.84-3.69c-4.62-1.83-7.67-6.27-7.72-11.24c0.01-6.59,5.36-11.93,11.95-11.93h5.62L247.57,163.83z"
                />
                <path
                    fill="#E6EFFF"
                    d="M507.29,308.46c-7.21-12.13-22.7-16.43-35.13-9.75l-124.61,53.21c-2.2,0.93-4.56,1.41-6.94,1.41   H186.09c-9.7,0-17.56-7.86-17.56-17.56c0-9.7,7.86-17.56,17.56-17.56l0,0h70.26c29.1,0,52.68-23.59,52.68-52.68l0,0H162.9   c-24.22,0.03-47.12,11.05-62.25,29.96l-54.98,66.65l62.01,113.63l238.6-57.34c10.31-2.38,20.29-6.04,29.69-10.89l122.94-62.97   C511.12,336.85,514.86,320.75,507.29,308.46z"
                />
                <path
                    fill="#2F8DFF"
                    d="M1.68,353.36v158.06h114.16l26.35-26.37L36.81,326.99L1.68,353.36z"
                />
                <circle fill="#ffffff" cx="80.71" cy="467.52" r="17.55" />
            </g>
        </SVGIcon>
    );
}
