import React from 'react';

import { SVGIcon } from '../../UIKit/index';

export default function DCForm(props) {
    return (
        <SVGIcon {...props} viewBox="0 0 209 182">
            <g transform="translate(-709 -582)">
                <ellipse
                    cx="71.5"
                    cy="17"
                    rx="71.5"
                    ry="17"
                    transform="translate(748 730)"
                    fill="#f5f5f5"
                />
                <g transform="translate(764 612)" fill="#f5f5f5" stroke="#405d63" strokeWidth="1.6">
                    <rect width="110" height="136" rx="10" stroke="none" />
                    <rect x="0.8" y="0.8" width="108.4" height="134.4" rx="9.2" fill="none" />
                </g>
                <g transform="translate(774 625)" fill="#fff" stroke="#405d63" strokeWidth="1.6">
                    <rect width="90" height="113" rx="6" stroke="none" />
                    <rect x="0.8" y="0.8" width="88.4" height="111.4" rx="5.2" fill="none" />
                </g>
                <g transform="translate(-10 -40)">
                    <rect width="25" height="3" transform="translate(802 691)" fill="#405d63" />
                </g>
                <g transform="translate(-10 -1)">
                    <rect width="40" height="3" transform="translate(802 691)" fill="#405d63" />
                </g>
                <g transform="translate(-10 12)">
                    <rect width="15" height="3" transform="translate(802 691)" fill="#405d63" />
                </g>
                <g transform="translate(-10 -27)">
                    <rect width="35" height="3" transform="translate(802 691)" fill="#405d63" />
                </g>
                <g transform="translate(-10 -14)">
                    <rect width="40" height="3" transform="translate(802 691)" fill="#405d63" />
                </g>
                <g opacity="0.5">
                    <rect
                        width="3"
                        height="23"
                        rx="1.5"
                        transform="translate(884 582)"
                        fill="#c3c5c4"
                    />
                    <rect
                        width="3"
                        height="23"
                        rx="1.5"
                        transform="translate(897 592) rotate(90)"
                        fill="#c3c5c4"
                    />
                </g>
                <g opacity="0.5">
                    <g transform="translate(902 615)" fill="#fff" stroke="#c3c5c4" strokeWidth="1">
                        <rect width="2" height="10" rx="1" stroke="none" />
                        <rect x="0.5" y="0.5" width="1" height="9" rx="0.5" fill="none" />
                    </g>
                    <g
                        transform="translate(908 619) rotate(90)"
                        fill="#fff"
                        stroke="#c3c5c4"
                        strokeWidth="1"
                    >
                        <rect width="2" height="10" rx="1" stroke="none" />
                        <rect x="0.5" y="0.5" width="1" height="9" rx="0.5" fill="none" />
                    </g>
                </g>
                <g transform="translate(-146 -19)" opacity="0.5">
                    <g transform="translate(902 615)" fill="#fff" stroke="#c3c5c4" strokeWidth="1">
                        <rect width="2" height="10" rx="1" stroke="none" />
                        <rect x="0.5" y="0.5" width="1" height="9" rx="0.5" fill="none" />
                    </g>
                    <g
                        transform="translate(908 619) rotate(90)"
                        fill="#fff"
                        stroke="#c3c5c4"
                        strokeWidth="1"
                    >
                        <rect width="2" height="10" rx="1" stroke="none" />
                        <rect x="0.5" y="0.5" width="1" height="9" rx="0.5" fill="none" />
                    </g>
                </g>
                <g transform="translate(-160 108)" opacity="0.5">
                    <g transform="translate(0 -11)">
                        <g
                            transform="translate(902 613)"
                            fill="#fff"
                            stroke="#c3c5c4"
                            strokeWidth="1"
                        >
                            <rect width="2" height="12" rx="1" stroke="none" />
                            <rect x="0.5" y="0.5" width="1" height="11" rx="0.5" fill="none" />
                        </g>
                        <g
                            transform="translate(909 618) rotate(90)"
                            fill="#fff"
                            stroke="#c3c5c4"
                            strokeWidth="1"
                        >
                            <rect width="2" height="12" rx="1" stroke="none" />
                            <rect x="0.5" y="0.5" width="1" height="11" rx="0.5" fill="none" />
                        </g>
                    </g>
                </g>
                <g
                    transform="translate(709 688)"
                    fill="#fff"
                    stroke="#c3c5c4"
                    strokeWidth="1.6"
                    opacity="0.5"
                >
                    <circle cx="4.5" cy="4.5" r="4.5" stroke="none" />
                    <circle cx="4.5" cy="4.5" r="3.7" fill="none" />
                </g>
                <g
                    transform="translate(909 688)"
                    fill="#fff"
                    stroke="#c3c5c4"
                    strokeWidth="1.6"
                    opacity="0.5"
                >
                    <circle cx="4.5" cy="4.5" r="4.5" stroke="none" />
                    <circle cx="4.5" cy="4.5" r="3.7" fill="none" />
                </g>
                <path d="M874,665.731l-26.235,24.913,13.2,13.87,27.2-26.529Z" fill="#fff" />
                <g transform="translate(731.456 648.06)">
                    <path
                        d="M166.167,3.107a9.719,9.719,0,0,0-13.745,0L113.89,41.639a2.159,2.159,0,0,0-.554.951l-5.067,18.293a2.159,2.159,0,0,0,2.658,2.658l18.293-5.068a2.159,2.159,0,0,0,.951-.554L168.7,19.387a9.73,9.73,0,0,0,0-13.745ZM118.6,43.043l31.535-31.536,10.17,10.17L128.766,53.213Zm-2.032,4.077,8.125,8.126L113.451,58.36Zm49.083-30.787-2.291,2.291L153.185,8.452l2.291-2.291a5.4,5.4,0,0,1,7.635,0L165.647,8.7A5.407,5.407,0,0,1,165.647,16.333Zm0,0"
                        fill="#405d63"
                        stroke="#fff"
                        strokeWidth="2"
                    />
                </g>
            </g>
        </SVGIcon>
    );
}
