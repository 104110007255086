import React from 'react';

import { SVGIcon } from '../../UIKit/index';

export default function DCCredentials(props) {
    return (
        <SVGIcon {...props} viewBox="0 0 491 512">
            <g transform="translate(9 17)">
                <path d="M415.44,494.01c-36.81,0-66.65-29.84-66.65-66.65s29.84-66.65,66.65-66.65   s66.65,29.84,66.65,66.65S452.25,494.01,415.44,494.01z M415.44,394.03c-18.41,0-33.33,14.92-33.33,33.33s14.92,33.33,33.33,33.33   s33.33-14.92,33.33-33.33S433.85,394.03,415.44,394.03z" />
                <path d="M209.92,477.35c-9.2,0-16.66-7.46-16.66-16.66v-22.22c0.02-15.33,12.44-27.75,27.77-27.77   h138.86c9.2,0,16.66,7.46,16.66,16.66s-7.46,16.66-16.66,16.66H226.59v16.66C226.59,469.89,219.13,477.35,209.92,477.35z" />
                <path d="M254.36,477.35c-9.2,0-16.66-7.46-16.66-16.66v-33.33c0-9.2,7.46-16.66,16.66-16.66   s16.66,7.46,16.66,16.66v33.33C271.02,469.89,263.56,477.35,254.36,477.35z" />
            </g>
            <path d="M157.83,111.09c-15.93,0-155.53-1.56-155.53-55.54S141.89,0,157.83,0s155.53,1.56,155.53,55.54  S173.76,111.09,157.83,111.09z M37.05,55.54c10.44,8.89,54.34,22.22,120.78,22.22S268.16,64.43,278.6,55.54  c-10.44-8.89-54.34-22.22-120.78-22.22S47.49,46.66,37.05,55.54z M280.49,57.63h0.22H280.49z" />
            <path d="M157.83,199.96c-15.93,0-155.53-1.56-155.53-55.54c0-9.2,7.46-16.66,16.66-16.66  s16.66,7.46,16.66,16.66c4.87,6.67,50.37,22.22,122.2,22.22s117.33-15.55,122.66-24.31c-0.01-8.59,6.94-15.56,15.53-15.58  c0.3,0,0.59,0.01,0.89,0.02c9.38,0.51,16.66,8.38,16.44,17.77C313.35,198.41,173.76,199.96,157.83,199.96z" />
            <path d="M157.83,288.83c-15.93,0-155.53-1.56-155.53-55.54c0-9.2,7.46-16.66,16.66-16.66  s16.66,7.46,16.66,16.66c4.87,6.67,50.37,22.22,122.2,22.22s117.33-15.55,122.66-24.31c-0.01-8.59,6.94-15.56,15.53-15.58  c0.3,0,0.59,0.01,0.89,0.02c9.38,0.51,16.66,8.38,16.44,17.77C313.35,287.28,173.76,288.83,157.83,288.83z" />
            <path d="M157.83,377.7c-15.93,0-155.53-1.56-155.53-55.54V55.54c0-9.2,7.46-16.66,16.66-16.66  s16.66,7.46,16.66,16.66v266.61c4.87,6.67,50.37,22.22,122.2,22.22s117.33-15.55,122.66-24.31l-0.47-264.53  c0-9.2,7.46-16.66,16.66-16.66s16.66,7.46,16.66,16.66v266.61C313.35,376.15,173.76,377.7,157.83,377.7z" />
        </SVGIcon>
    );
}
