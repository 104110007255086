import React, { useState, Fragment } from 'react';
import { Redirect } from 'react-router-dom';
import { Div, Image, Input, Button, Span, IconButton } from 'UIKit/index';
import { useQuery } from 'react-query';
import DCLock from 'Assets/icons/DCLock';
import log from 'Utils/logger';
import RightSideLogin from './RightSideLogin.jsx';
import { resetPassword, getBrandingData } from 'apis/login/index.api';
import { getCookie, isAuthenticated } from 'Utils/auth';
import './style.scss';
import DCCrossClose from 'Assets/icons/DCCrossClose';
import generateImage from 'Utils/generateImage.js';

import useToast from 'Modules/Toasts/useToast.js';
import RedirectPage from './Redirect';
import DCEye from 'Assets/icons/DCEye.js';
import DCVisibilityEye from 'Assets/icons/DCVisibilityEye.js';
import { Validation } from 'Utils/validator.js';
import config from 'config';

const getFaviconEl = () => {
    return document.getElementById('favicon');
};

const ResetPassword = () => {
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [isLoading] = useState(false);
    const [error, setError] = useState(null);
    const [redirect, setRedirect] = useState(false);
    const [timer, setTimer] = useState(false);
    const { showToast } = useToast();
    const [passwordVisible, setPasswordVisible] = useState(false);
    const [confirmPasswordVisible, setConfirmPasswordVisible] = useState(false);

    const handlePasswordVisibilityToggle = () => {
        setPasswordVisible(prev => !prev);
    };

    const handleConfirmPasswordVisibilityToggle = () => {
        setConfirmPasswordVisible(prev => !prev);
    };

    /**
     * Get Account Branding
     */
    const { isError, data: brandingData } = useQuery(
        ['login', 'branding_data'],
        () => getBrandingData(getCookie('aid') ? getCookie('aid') : config.REACT_APP_TEST_AID),
        {
            onSuccess: data => {
                document.title = new URLSearchParams(window.location.search).get('isnewuser')
                    ? `New Password | ${data.data?.name}`
                    : `Reset Password | ${data.data?.name}`;
                const favicon = getFaviconEl();
                document.documentElement.style.setProperty(
                    '--dark-blue',
                    data.data?.colors?.secondary,
                );
                document.documentElement.style.setProperty(
                    '--blue-invert',
                    data.data?.colors?.font_primary,
                );

                favicon.setAttribute(
                    'href',
                    data.data?.images?.favicon?.key
                        ? generateImage(data.data?.images.favicon, null, true)
                        : 'https://s3.wasabisys.com/open/general/branding/dc_dashboard.ico',
                );
            },
            enabled: isAuthenticated(),
        },
    );

    if (isError) {
        log.error('Account Branding API is not working!');
    }

    const handleResetPassword = async () => {
        if (password.trim() === '') {
            setError(`Password can't be empty`);
        }
        if (password !== confirmPassword) {
            setError(`Password doesn't match`);
            return false;
        }
        try {
            const res = await resetPassword({
                token: new URLSearchParams(window.location.search).get('d'), // GET TOKEN FROM QUERY
                password: password,
                type: 'reset',
            });
            if (res.success) {
                showToast({
                    type: 'success',
                    message: 'Password has been reset successfully',
                });
                setTimer(true);
                setTimeout(() => {
                    setRedirect(true);
                }, 2000);
            }
        } catch (e) {
            showToast({
                type: 'error',
                message: 'Link Expired please reset password again',
            });
            setTimer(true);
            setTimeout(() => {
                setRedirect(true);
            }, 2000);
        }
    };

    const handleEnter = e => {
        if (e.keyCode === 13) {
            handleResetPassword();
        }
    };

    const handleConfirmPassword = e => {
        setError(null);
        setConfirmPassword(e.target.value);
    };

    const handlePassword = e => {
        if (!Validation('password', e.target.value)) {
            setError(
                'Password should have minimum eight characters, at least one uppercase letter, one lowercase letter, one number and one special character',
            );
        } else {
            setError(null);
        }
        setPassword(e.target.value);
    };

    const loginBtnText = () => {
        if (isLoading) {
            return '';
        }
        if (new URLSearchParams(window.location.search).get('isnewuser')) {
            return 'New Password';
        } else {
            return 'Reset Password';
        }
    };

    if (redirect) return <Redirect to={'/auth/login'} />;

    if (timer) return <RedirectPage />;

    return (
        <Fragment>
            <Div className="LoginPage">
                <Div className="LoginPageInner">
                    <Div
                        className="LPIFormSection"
                        style={{ backgroundColor: brandingData?.data?.colors?.primary }}
                    >
                        <Div className="LPIFSInner">
                            <Div className="LPIFSIHeader">
                                <Div className="LPIFSIHLogo">
                                    <Image
                                        src={generateImage(brandingData?.data?.image, null, true)}
                                        alt={brandingData?.data?.name}
                                    />
                                </Div>
                            </Div>
                            <Div className="LPIFSIBody">
                                <Div className="LPIFSIFormBox">
                                    <Div className="LPIFSIFBTitle">
                                        <Div
                                            className="LPIFSIFBTText"
                                            style={{
                                                color: brandingData?.data?.colors?.font_primary,
                                            }}
                                        >
                                            Type your new password
                                        </Div>
                                    </Div>
                                    <Div className="LPIFSIFBForm">
                                        <Div className="LPIFSIFBPassword">
                                            <Div className={'LPIFSIFBPIcon'}>
                                                <DCLock />
                                            </Div>
                                            <Input
                                                name="password"
                                                type={passwordVisible ? 'text' : 'password'}
                                                placeholder={'Type your new Password'}
                                                autoFocus={true}
                                                required={true}
                                                onChange={handlePassword}
                                                errorMessage={error}
                                                value={password}
                                                error={''}
                                                min={4}
                                            />
                                            <Div
                                                className={'LPIFSIFBPRightIcon'}
                                                onClick={handlePasswordVisibilityToggle}
                                            >
                                                {passwordVisible ? <DCEye /> : <DCVisibilityEye />}
                                            </Div>
                                        </Div>
                                        <br />
                                        <Div className="LPIFSIFBPassword">
                                            <Div className={'LPIFSIFBPIcon'}>
                                                <DCLock />
                                            </Div>
                                            <Input
                                                name="password"
                                                type={confirmPasswordVisible ? 'text' : 'password'}
                                                placeholder={'Confirm your new Password'}
                                                required={true}
                                                onChange={handleConfirmPassword}
                                                errorMessage={error}
                                                value={confirmPassword}
                                                error={''}
                                                onKeyUp={handleEnter}
                                            />
                                            <Div
                                                className={'LPIFSIFBPRightIcon'}
                                                onClick={handleConfirmPasswordVisibilityToggle}
                                            >
                                                {confirmPasswordVisible ? (
                                                    <DCEye />
                                                ) : (
                                                    <DCVisibilityEye />
                                                )}
                                            </Div>
                                        </Div>
                                        <br />
                                        {error && (
                                            <Div className="LPIForgetBtn">
                                                <Span>{error}</Span>
                                                <IconButton
                                                    onClick={() => {
                                                        setError(null);
                                                    }}
                                                >
                                                    <DCCrossClose />
                                                </IconButton>
                                            </Div>
                                        )}
                                        <Div className="LPIFSIFBFSBtn">
                                            <Button
                                                buttonClass={isLoading && 'LPIFSIFBFSBtnLoad'}
                                                fullWidth
                                                buttonType={'BlueFillBtn'}
                                                onClick={handleResetPassword}
                                                loading={isLoading}
                                                disabled={isLoading || error?.length}
                                            >
                                                {loginBtnText()}
                                            </Button>
                                        </Div>
                                    </Div>
                                </Div>
                            </Div>
                        </Div>
                    </Div>
                    <RightSideLogin
                        image={
                            brandingData?.data?.images?.login_image
                                ? generateImage(brandingData?.data?.images?.login_image, null, true)
                                : null
                        }
                    />
                </Div>
            </Div>
        </Fragment>
    );
};

export default ResetPassword;
