import React from 'react';

import { SVGIcon } from '../../UIKit/index';

export default function DCAddToMyLeads(props) {
    return (
        <SVGIcon {...props} viewBox="0 0 99.284 79">
            <g transform="translate(-407 -445)">
                <g transform="translate(407 444.186)">
                    <path d="M0,0H96V10H0Z" transform="translate(1.642 0.814)" fill="#005eff" />
                    <rect
                        width="90"
                        height="69"
                        transform="translate(4.642 10.814)"
                        fill="#d9eaff"
                    />
                    <path
                        d="M97.629,50.809H1.655a1.655,1.655,0,0,1,0-3.309H97.629a1.655,1.655,0,0,1,0,3.309Z"
                        transform="translate(0 -39.228)"
                        fill="#6b8aaa"
                    />
                </g>
                <g transform="translate(419 456.786)">
                    <path
                        d="M48.692,88.2a15.355,15.355,0,0,1-15.4-15.4V54.276a.868.868,0,0,1,.868-.868h6.77a.868.868,0,0,1,.868.868V72.907a6.737,6.737,0,0,0,6.374,6.788,6.637,6.637,0,0,0,6.885-6.625V54.276a.868.868,0,0,1,.868-.868H62.7a.868.868,0,0,1,.868.868V73.07A15.153,15.153,0,0,1,48.692,88.2Z"
                        transform="translate(-10.31 -26.401)"
                        fill="#005eff"
                    />
                    <path
                        d="M48.428,79.691c-.086,0-.17.007-.256,0A6.737,6.737,0,0,1,41.8,72.907V54.275a.868.868,0,0,0-.868-.868H34.16a.868.868,0,0,0-.868.868V72.8A15.359,15.359,0,0,0,48.428,88.2Z"
                        transform="translate(-10.31 -26.401)"
                        fill="#005eff"
                    />
                    <path
                        d="M89.111,22.06a4.6,4.6,0,0,1-5.3,0,5.368,5.368,0,0,0-3.85,5.147V29.1a.962.962,0,0,0,.962.962H92a.962.962,0,0,0,.962-.962V27.207a5.368,5.368,0,0,0-3.85-5.147Z"
                        transform="translate(-33.206 -9.594)"
                        fill="#577797"
                    />
                    <circle
                        cx="4.619"
                        cy="4.619"
                        r="4.619"
                        transform="translate(48.635 4.067)"
                        fill="#fff"
                    />
                    <path
                        d="M57.631,22.06a4.6,4.6,0,0,1-5.3,0,5.368,5.368,0,0,0-3.85,5.147V29.1a.962.962,0,0,0,.962.962H60.52a.962.962,0,0,0,.962-.962V27.207A5.368,5.368,0,0,0,57.631,22.06Z"
                        transform="translate(-16.862 -9.594)"
                        fill="#577797"
                    />
                    <path
                        d="M60.52,34.656H49.441a.962.962,0,0,1-.962-.962v1.442a.962.962,0,0,0,.962.962H60.52a.962.962,0,0,0,.962-.962V33.694A.962.962,0,0,1,60.52,34.656Z"
                        transform="translate(-16.862 -15.634)"
                        fill="#fff"
                    />
                    <circle
                        cx="4.619"
                        cy="4.619"
                        r="4.619"
                        transform="translate(33.499 4.067)"
                        fill="#fff"
                    />
                    <path
                        d="M26.152,22.06a4.6,4.6,0,0,1-5.3,0A5.368,5.368,0,0,0,17,27.207V29.1a.962.962,0,0,0,.962.962H29.04A.962.962,0,0,0,30,29.1V27.207a5.368,5.368,0,0,0-3.85-5.147Z"
                        transform="translate(-0.519 -9.594)"
                        fill="#577797"
                    />
                    <circle
                        cx="4.619"
                        cy="4.619"
                        r="4.619"
                        transform="translate(18.363 4.067)"
                        fill="#fff"
                    />
                    <g transform="translate(16 3.58)">
                        <path
                            d="M59.588,52.408H53.415a1.274,1.274,0,0,0-1.273,1.272V70.82a5.572,5.572,0,0,1-5.781,5.562,5.693,5.693,0,0,1-5.35-5.71V53.68a1.274,1.274,0,0,0-1.273-1.272H33.564a1.274,1.274,0,0,0-1.272,1.272V70.575a14.726,14.726,0,0,0,4.389,10.434,14.047,14.047,0,0,0,9.9,4.1h.243A14.35,14.35,0,0,0,60.861,70.82V67.454a.481.481,0,1,0-.962,0V70.82a13.382,13.382,0,0,1-12.842,13.31v-6.8A6.53,6.53,0,0,0,53.1,70.82V58.252h6.8v2.011a.481.481,0,1,0,.962,0V53.68A1.274,1.274,0,0,0,59.588,52.408Zm-26.024.962h6.174a.311.311,0,0,1,.311.311v3.61h-6.8V53.68A.311.311,0,0,1,33.564,53.37Zm-.311,17.2V58.252h6.8V70.671a6.668,6.668,0,0,0,6.046,6.65v6.812a13.085,13.085,0,0,1-8.74-3.813,13.756,13.756,0,0,1-4.1-9.746ZM53.1,57.29V53.68a.311.311,0,0,1,.311-.311h6.174a.311.311,0,0,1,.311.311v3.61Z"
                            transform="translate(-24.458 -28.93)"
                            fill="none"
                        />
                        <path
                            d="M89.523,12.312a5.1,5.1,0,1,0-7.164,0,5.849,5.849,0,0,0-3.4,5.3v1.892A1.442,1.442,0,0,0,80.4,20.948H91.48a1.442,1.442,0,0,0,1.442-1.442v-1.89a5.847,5.847,0,0,0-3.4-5.3ZM81.8,8.687a4.139,4.139,0,1,1,4.139,4.138A4.139,4.139,0,0,1,81.8,8.687ZM91.961,19.505a.481.481,0,0,1-.481.481H80.4a.481.481,0,0,1-.481-.481v-1.89A4.89,4.89,0,0,1,83.225,13a5.058,5.058,0,0,0,5.433,0,4.89,4.89,0,0,1,3.3,4.616Z"
                            transform="translate(-48.687 -3.58)"
                            fill="none"
                        />
                        <path
                            d="M48.921,20.949H60a1.442,1.442,0,0,0,1.442-1.442v-1.89a5.847,5.847,0,0,0-3.4-5.3,5.1,5.1,0,1,0-7.164,0,5.847,5.847,0,0,0-3.4,5.3v1.892a1.442,1.442,0,0,0,1.442,1.442Zm1.4-12.261a4.138,4.138,0,1,1,4.138,4.138,4.138,4.138,0,0,1-4.138-4.138Zm-1.882,8.927A4.89,4.89,0,0,1,51.745,13a5.059,5.059,0,0,0,5.433,0,4.89,4.89,0,0,1,3.3,4.616v1.892a.481.481,0,0,1-.481.481H48.921a.481.481,0,0,1-.481-.481Z"
                            transform="translate(-32.343 -3.582)"
                            fill="none"
                        />
                        <path
                            d="M29.964,19.508v-1.89a5.849,5.849,0,0,0-3.4-5.3,5.1,5.1,0,1,0-7.164,0,5.847,5.847,0,0,0-3.4,5.3v1.892a1.442,1.442,0,0,0,1.442,1.442H28.522A1.442,1.442,0,0,0,29.964,19.508ZM18.843,8.69a4.139,4.139,0,1,1,4.139,4.138A4.139,4.139,0,0,1,18.843,8.69ZM16.962,19.508v-1.89A4.89,4.89,0,0,1,20.266,13,5.058,5.058,0,0,0,25.7,13,4.89,4.89,0,0,1,29,17.619v1.89a.481.481,0,0,1-.481.481H17.442A.481.481,0,0,1,16.962,19.508Z"
                            transform="translate(-16 -3.584)"
                            fill="none"
                        />
                    </g>
                </g>
            </g>
        </SVGIcon>
    );
}
