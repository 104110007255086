import React from 'react';

import { SVGIcon } from '../../UIKit/index';

export default function DCNoData(props) {
    return (
        <SVGIcon {...props} viewBox="0 0 512 411">
            <g transform="translate(0 -44.798)">
                <path
                    fill="#A7BAC2"
                    d="M473.4,197.6v-73.3c0.2-9.4-7.3-17.2-16.7-17.3c-0.2,0-0.4,0-0.6,0H259.7l-31.2-52   c-3.1-5.4-8.8-8.7-15-8.7H55.9c-4.8,0-9.3,1.8-12.7,5.2c-3.4,3.3-5.1,8-4.6,12.7l5.8,133.4L473.4,197.6L473.4,197.6z"
                />
                <path
                    fill="#F2F9FC"
                    d="M505.7,200.4c-3.3-4-8.1-6.3-13.3-6.3H18.9c-5.1,0-10,2.3-13.3,6.3c-3.3,3.8-4.8,8.9-4,13.9   L38,441.8c1.3,8.5,8.7,14.7,17.3,14.4h401.4c8.5-0.1,15.7-6.1,17.3-14.4l35.8-227.5C510.6,209.2,509.1,204.1,505.7,200.4z"
                />
                <g transform="translate(-870.245 -127.409)">
                    <path
                        fill="#A7BAC2"
                        d="M1121.5,390.3c0,0,56.7-7,68,48.3c-1.4,0-45.5,7-45.5,7c-7.6,0.4-14.3-4.9-15.6-12.4    C1125.1,419.1,1122.8,404.7,1121.5,390.3z"
                    />
                    <path
                        fill="#A7BAC2"
                        d="M1111.7,402.9c0,0,2.1,51.8,18.9,56c16.8,4.2,49.1-6.3,49.1-6.3s-0.7,67.3-68,63.1    c-0.8-0.4-41.7-1.2-44.8-56.7C1068.9,413.4,1111.7,402.9,1111.7,402.9z"
                    />
                </g>
            </g>
        </SVGIcon>
    );
}
