import React from 'react';

import { SVGIcon } from '../../UIKit/index';

export default function DCTemplate(props) {
    return (
        <SVGIcon {...props} viewBox="0 0 512  512 ">
            <g transform="translate(-289.7 -157.846)">
                <g>
                    <path
                        fill="#D5E5FF"
                        d="M377.5,361.2h332.4c14.7,0,26.6,11.9,26.6,26.6l0,0c0,14.7-11.9,26.6-26.6,26.6H377.5    c-14.7,0-26.6-11.9-26.6-26.6l0,0C350.9,373.1,362.8,361.2,377.5,361.2z"
                    />
                    <g transform="translate(287.237 158.146)">
                        <g transform="translate(2.763)">
                            <g>
                                <path
                                    fill="#005EFF"
                                    stroke="#FFFFFF"
                                    strokeWidth="7.9783"
                                    d="M467.6,5.5H43.8C24.1,5.3,7.9,21.1,7.7,40.8c0,0.3,0,0.5,0,0.8v429.3       c-0.2,19.7,15.6,35.9,35.3,36.1c0.3,0,0.5,0,0.8,0h423.8c19.7,0.2,35.9-15.6,36.1-35.3c0-0.3,0-0.5,0-0.8V41.6       c0.2-19.7-15.6-35.9-35.3-36.1C468.1,5.5,467.9,5.5,467.6,5.5z M467.6,470.8H43.8L43.5,41.6c0.1,0,0.2,0,0.3,0h423.8V470.8z"
                                />
                            </g>
                        </g>
                        <g transform="translate(4.119 11.213)">
                            <g>
                                <rect
                                    fill="#005EFF"
                                    x="24.4"
                                    y="143.4"
                                    width="459.3"
                                    height="30.7"
                                />
                            </g>
                        </g>
                        <g transform="translate(6.809 5.34)">
                            <g transform="translate(0 0)">
                                <ellipse fill="#005EFF" cx="79" cy="92.7" rx="21.6" ry="21.6" />
                            </g>
                        </g>
                        <g transform="translate(13.002 5.34)">
                            <g transform="translate(0 0)">
                                <ellipse fill="#005EFF" cx="155.2" cy="92.7" rx="21.6" ry="21.6" />
                            </g>
                        </g>
                        <g transform="translate(22.391 5.606)">
                            <g>
                                <path
                                    fill="#005EFF"
                                    d="M392.2,74.4h-125c-8.8-1.1-16.9,5.1-18,13.9c-1.1,8.8,5.1,16.9,13.9,18       c1.4,0.2,2.8,0.2,4.1,0h125c8.8,1.1,16.9-5.1,18-13.9c1.1-8.8-5.1-16.9-13.9-18C394.9,74.3,393.5,74.3,392.2,74.4z"
                                />
                            </g>
                        </g>
                    </g>
                </g>
            </g>
        </SVGIcon>
    );
}
