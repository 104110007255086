import React from 'react';

import { SVGIcon } from '../../UIKit/index';

export default function DCTiktok(props) {
    return (
        <SVGIcon
            {...props}
            viewBox="0 0 2000 2000"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            id="Layer_1"
            xmlSpace={'preserve'}
            version={'1.1'}
            x="0px"
            y="0px"
            style={{
                enableBackground: 'new 0 0 2000 2000',
            }}
        >
            <g xmlns="http://www.w3.org/2000/svg">
                <path
                    style={{ fill: '#FF0B53' }}
                    d="M1879,541.7c-246.8,0-446.8-200-446.8-446.8h-335.1v1296.9c0,150.8-122.2,273-273,273s-273-122.2-273-273   s122.2-273,273-273c28.3,0,60.3,4.3,86,12.3V789.8c-28.1-4-56.8-6-86-6c-335.9,0-608.1,272.3-608.1,608.1   c0,335.9,272.3,608.1,608.1,608.1c335.9,0,608.1-272.3,608.1-608.1V705.4c121.8,99.4,277.3,159,446.8,159V541.7z"
                />
                <path
                    style={{ fill: '#00F2EA' }}
                    d="M1784.1,446.8c-246.8,0-446.8-200-446.8-446.8h-335.1v1296.9c0,150.8-122.2,273-273,273s-273-122.2-273-273   s122.2-273,273-273c28.3,0,60.3,4.3,86,12.3V694.8c-28.1-4-56.8-6-86-6c-335.9,0-608.1,272.3-608.1,608.1   c0,335.9,272.3,608.1,608.1,608.1c335.9,0,608.1-272.3,608.1-608.1V610.5c121.8,99.4,277.3,159,446.8,159L1784.1,446.8   L1784.1,446.8z"
                />
                <path d="M1784.1,531.5c-108-23.4-201.5-85.8-264.9-171.7c-86-63.4-148.4-156.8-171.7-264.9h-250.4v1296.9   c0,150.8-122.2,273-273,273c-99.7,0-187-53.5-234.6-133.4c-79.9-47.6-133.4-134.9-133.4-234.6c0-150.8,122.2-273,273-273   c28.3,0,60.3,4.3,86,12.3V783.8c-331.7,4.8-599.2,275.2-599.2,608c0,143.7,49.9,275.8,133.3,379.9   c104.1,83.4,236.2,133.3,379.9,133.3c335.9,0,608.1-272.3,608.1-608.1V610.5c121.8,99.4,277.3,159,446.8,159L1784.1,531.5   L1784.1,531.5z" />
            </g>
        </SVGIcon>
    );
}
