import React from 'react';

import { SVGIcon } from '../../UIKit/index';

export default function DCFacebook2(props) {
    return (
        <SVGIcon {...props} viewBox="0 0 20 20">
            <g transform="translate(8 7)">
                <path
                    d="M18.9,0H1.1A1.1,1.1,0,0,0,0,1.1V18.9A1.1,1.1,0,0,0,1.1,20H18.9A1.1,1.1,0,0,0,20,18.9h0V1.1A1.1,1.1,0,0,0,18.9,0Zm0,0"
                    transform="translate(-8 -7)"
                    fill="#4267b2"
                />
                <path
                    d="M212.723,94.3V86.569h2.607l.391-3.027h-3V81.614c0-.874.243-1.47,1.5-1.47h1.589v-2.7a21.365,21.365,0,0,0-2.329-.119,3.637,3.637,0,0,0-3.882,3.99v2.228H207v3.027h2.6V94.3Zm0,0"
                    transform="translate(-206.914 -81.304)"
                    fill="#fff"
                />
            </g>
        </SVGIcon>
    );
}
