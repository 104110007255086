import React, { useEffect, useState } from 'react';

import { Div } from '../../UIKit/index';
import PropTypes from 'prop-types';
import './style.scss';

const Switch = ({
    customClass,
    onClick,
    checked,
    switchOnTitle,
    switchOffTitle,
    id,
    disabled = false,
}) => {
    const [defaultChecked, setDefaultChecked] = useState(checked || false);

    useEffect(() => {
        setDefaultChecked(checked);
    }, [checked]);

    return (
        <Div
            className={`SwitchMain ${customClass ? customClass : ''} ${disabled ? 'disabled' : ''}`}
            id={id}
        >
            <Div className={'SwitchMainInner'}>
                <Div
                    className={'SMIBtn SMIBtnOn ' + (defaultChecked === true ? 'active' : '')}
                    onClick={() => !disabled && onClick(true)}
                >
                    {switchOnTitle || 'ON'}
                </Div>
                <Div
                    className={'SMIBtn SMIBtnOff ' + (defaultChecked === false ? 'active' : '')}
                    onClick={() => !disabled && onClick(false)}
                >
                    {switchOffTitle || 'OFF'}
                </Div>
            </Div>
        </Div>
    );
};

Switch.propTypes = {
    customClass: PropTypes.string,
    onClick: PropTypes.func,
    checked: PropTypes.bool,
    switchOnTitle: PropTypes.string,
    switchOffTitle: PropTypes.string,
    id: PropTypes.number,
    disabled: PropTypes.bool,
};

export default Switch;

// <Switch onClick={onClick} checked={checked} />;
