import React from 'react';

import { SVGIcon } from '../../UIKit/index';

export default function DCSales(props) {
    return (
        <SVGIcon {...props} viewBox="0 0 511.746 511.746">
            <g>
                <path d="m496.721 425.962h-30.245v-200.04c0-8.284-6.727-15-15.025-15h-82.959c-8.298 0-15.025 6.716-15.025 15v200.04h-41.089v-259.469c0-8.284-6.727-15-15.025-15h-82.959c-8.298 0-15.025 6.716-15.025 15v259.469h-41.089v-215.914c0-8.284-6.727-15-15.025-15h-82.96c-8.298 0-15.025 6.716-15.025 15v215.914h-30.245c-8.298 0-15.025 6.716-15.025 15s6.727 15 15.025 15h481.696c8.298 0 15.025-6.716 15.025-15s-6.727-15-15.025-15zm-421.401 0v-200.914h52.91v200.914zm154.098 0v-244.469h52.91v244.469zm154.099 0v-185.04h52.91v185.04z" />
                <path d="m500.766 112.862c.498-6.269-3.156-13.967-11.523-15.914l-48.465-11.78c-8.053-1.963-16.162 2.982-18.118 11.032-1.957 8.05 2.982 16.162 11.032 18.118l13.708 3.332-38.858 23.979-147.336-83.88c-4.236-2.413-9.384-2.616-13.794-.543l-146.454 68.742-70.011-43.693c-7.027-4.389-16.28-2.245-20.667 4.783-4.386 7.027-2.244 16.28 4.784 20.667l76.936 48.015c4.317 2.695 9.707 3.017 14.315.854l146.805-68.908 148.31 84.435c3.859 2.024 9.238 3.073 15.299-.271l47.105-29.069-3.137 14.692c-3.452 19.543 24.213 25.093 29.338 6.266l10.398-48.694c.118-.682.162-.586.333-2.163z" />
            </g>
        </SVGIcon>
    );
}
