import React from 'react';

import { SVGIcon } from '../../UIKit/index';

export default function DCeDocsMenu(props) {
    return (
        <SVGIcon {...props} viewBox="0 0 497 512">
            <g transform="translate(0 -1.962)">
                <path
                    fill="#E6EFFF"
                    d="M436.35,40.5c1.13-19.27-13.58-35.81-32.86-36.93c-1.77-0.1-3.54-0.07-5.3,0.09H124.82v87.88   c-0.37,10.06-8.81,17.93-18.87,17.58H2.1v386.7c0.4,10.05,8.82,17.9,18.87,17.58h396.48c10.05,0.31,18.48-7.53,18.87-17.58   L436.35,40.5z"
                />
                <path
                    fill="#CED8E5"
                    d="M146.76,463.16l13.35-93.44l60.09,20.03l20.03,60.09L146.76,463.16z"
                />
                <path
                    fill="#CED8E5"
                    d="M360.36,169.44l26.79-26.65l80.1,80.1l-26.69,26.69L360.36,169.44z"
                />
                <path
                    fill="#CED8E5"
                    d="M434.41,113.13c-3.19,0.31-6.35,0.91-9.44,1.79c-9.28,2.64-17.72,7.62-24.54,14.44l-13.31,13.48   l80.06,80.06l13.31-13.31c22.07-22.17,22-58.03-0.17-80.1c-12.08-12.03-28.94-17.98-45.9-16.19L434.41,113.13z"
                />
                <path
                    fill="#2F8DFF"
                    d="M360.4,169.4L160.1,369.69l53.46,26.67l120.12-120.16L213.57,396.37l12.06,24.09l14.68,29.25   l194.17-194.17l6.05-6.05l-15.41-15.41L360.4,169.4z M361.26,248.65l13.37-13.35L361.26,248.65z"
                />
                <path
                    fill="#2F8DFF"
                    d="M124.82,98.76V4.39L2.1,117.67h103.84c10.42,0,18.87-8.45,18.87-18.87   C124.82,98.79,124.82,98.77,124.82,98.76z"
                />
                <path
                    fill="#F1F2F2"
                    d="M238.82,380.54c-5.21,0.01-9.45-4.2-9.46-9.42c-0.01-2.51,0.99-4.93,2.77-6.7l101.3-101.26   c3.44-3.92,9.4-4.31,13.32-0.88c3.92,3.43,4.31,9.4,0.88,13.32c-0.27,0.31-0.56,0.6-0.87,0.87L245.5,377.8   C243.72,379.56,241.32,380.55,238.82,380.54z"
                />
                <path
                    fill="#F1F2F2"
                    d="M366.79,252.58c-5.21,0.01-9.45-4.21-9.46-9.42c-0.01-2.51,0.99-4.93,2.77-6.7l13.35-13.35   c3.69-3.69,9.66-3.69,13.35,0c3.69,3.69,3.69,9.66,0,13.35l-13.35,13.35C371.69,251.58,369.29,252.58,366.79,252.58z"
                />
                <path
                    fill="#2F8DFF"
                    d="M266.43,220.75H68.18c-5.21,0.02-9.45-4.2-9.46-9.41c-0.02-5.21,4.2-9.45,9.41-9.46   c0.02,0,0.04,0,0.06,0h198.25c5.21-0.02,9.45,4.2,9.46,9.41c0.02,5.21-4.2,9.45-9.41,9.46   C266.47,220.75,266.45,220.75,266.43,220.75z"
                />
                <path
                    fill="#2F8DFF"
                    d="M209.79,277.39H68.18c-5.21,0.02-9.45-4.2-9.46-9.41c-0.02-5.21,4.2-9.45,9.41-9.46   c0.02,0,0.04,0,0.06,0h141.61c5.21-0.02,9.45,4.2,9.46,9.41c0.02,5.21-4.2,9.45-9.41,9.46   C209.83,277.39,209.81,277.39,209.79,277.39z"
                />
                <path
                    fill="#2F8DFF"
                    d="M153.11,334.03H68.18c-5.21,0-9.44-4.23-9.44-9.44c0-5.21,4.23-9.44,9.44-9.44h84.93   c5.21,0,9.44,4.23,9.44,9.44C162.55,329.8,158.33,334.03,153.11,334.03z"
                />
                <path
                    fill="#2F8DFF"
                    d="M323,164.11H143.7c-5.21,0.02-9.45-4.2-9.46-9.41c-0.02-5.21,4.2-9.45,9.41-9.46   c0.02,0,0.04,0,0.06,0H323c5.21-0.02,9.45,4.2,9.46,9.41c0.02,5.21-4.2,9.45-9.41,9.46C323.03,164.11,323.01,164.11,323,164.11z"
                />
                <path
                    fill="#2F8DFF"
                    d="M105.95,164.11H68.18c-5.21,0.02-9.45-4.2-9.46-9.41c-0.02-5.21,4.2-9.45,9.41-9.46   c0.02,0,0.04,0,0.06,0h37.77c5.21-0.02,9.45,4.2,9.46,9.41c0.02,5.21-4.2,9.45-9.41,9.46   C105.99,164.11,105.97,164.11,105.95,164.11z"
                />
            </g>
        </SVGIcon>
    );
}
