import React, { useEffect, useState, Suspense, lazy } from 'react';

import './style.scss';
import PropTypes from 'prop-types';
import DCCrossClose from '../../Assets/icons/DCCrossClose';
import DCDownload1Icon from '../../Assets/icons/DCDownload1';
import { Dialog, Div, H1, IconButton, TopBar } from '../../UIKit';
const FileViewer = lazy(() => import('react-file-viewer'));
export default function FilePreviewDialog({
    open,
    fileName,
    fileUrl,
    fileType,
    onClose,
    handleDownload,
    hideDownload,
    isDownloading,
    fullWidth,
    parentType,
}) {
    const [txtContent, setTxtContent] = useState('Loading...');
    useEffect(() => {
        if (fileType === 'plain')
            fetch(fileUrl)
                .then(response => {
                    return response.text();
                })
                .then(data => {
                    // Do something with your data
                    setTxtContent(data);
                })
                .catch(err => setTxtContent('Failed to load content of the file.\n' + err));
    }, [fileType, fileUrl]);
    return (
        <Dialog
            open={open}
            classes={{ root: 'FilePreviewDialogMain' }}
            dialogType={fullWidth ? 'fullWidth' : 'centerZoom sizeLg'}
            onClose={onClose}
        >
            <Div className={'FilePreviewDialog'}>
                <TopBar>
                    <Div className={'HeaderBar'}>
                        <H1 className={'HeaderBarTitle'}>{fileName || 'File Preview'}</H1>
                        <Div className={'HeaderBarClose'}>
                            {!hideDownload && (
                                <IconButton onClick={handleDownload} loading={isDownloading}>
                                    <DCDownload1Icon />
                                </IconButton>
                            )}
                            <IconButton onClick={onClose}>
                                <DCCrossClose />
                            </IconButton>
                        </Div>
                    </Div>
                </TopBar>
                <Div className={'BodyBox'}>
                    <Div className={'BodyBoxInner'}>
                        {fileType === 'plain' ? (
                            <pre>{txtContent}</pre>
                        ) : (
                            <Suspense fallback={<div>Loading...</div>}>
                                <FileViewer
                                    fileType={parentType === 'audio' ? 'mp3' : fileType}
                                    filePath={fileUrl}
                                    allowFullScreen={true}
                                    onError={() => {}}
                                />
                            </Suspense>
                        )}
                    </Div>
                </Div>
            </Div>
        </Dialog>
    );
}

FilePreviewDialog.propTypes = {
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    fileName: PropTypes.string.isRequired,
    fileUrl: PropTypes.string.isRequired,
    fileType: PropTypes.string.isRequired,
    handleDownload: PropTypes.func.isRequired,
    hideDownload: PropTypes.bool,
    isDownloading: PropTypes.bool,
    fullWidth: PropTypes.string,
    parentType: PropTypes.any,
};
