import React from 'react';

import { SVGIcon } from '../../UIKit/index';

export default function DCTrash1(props) {
    return (
        <SVGIcon {...props} viewBox="0 0 105.507 140.542">
            <g transform="translate(-63.818)">
                <path
                    d="M129.79,45.12H104.32l29.111-29.11a4.117,4.117,0,0,0,0-5.823L126.054,2.81a9.618,9.618,0,0,0-13.588,0l-7.585,7.585L101,6.512a10.993,10.993,0,0,0-15.529,0L70.329,21.653a10.993,10.993,0,0,0,0,15.529l3.882,3.882-7.585,7.585a9.619,9.619,0,0,0,0,13.588L74,69.613a4.117,4.117,0,0,0,5.823,0L90.264,59.176V122.7a17.864,17.864,0,0,0,17.843,17.843h43.374A17.864,17.864,0,0,0,169.324,122.7V45.262ZM76.153,27.476,91.293,12.335a2.749,2.749,0,0,1,3.883,0l3.882,3.882L80.035,35.241l-3.882-3.882a2.749,2.749,0,0,1,0-3.883Zm.763,33.4L72.45,56.413a1.374,1.374,0,0,1,0-1.942L118.29,8.633a1.374,1.374,0,0,1,1.942,0L124.7,13.1ZM161.089,122.7a9.619,9.619,0,0,1-9.608,9.608H108.107A9.619,9.619,0,0,1,98.5,122.7V53.355h62.59Z"
                    transform="translate(0)"
                />
                <path
                    d="M226.548,290.917a4.118,4.118,0,0,0,4.117-4.117V237.5a4.117,4.117,0,1,0-8.235,0v49.3A4.117,4.117,0,0,0,226.548,290.917Z"
                    transform="translate(-115.074 -169.316)"
                />
                <path
                    d="M293.289,290.917a4.118,4.118,0,0,0,4.117-4.117V237.5a4.117,4.117,0,0,0-8.235,0v49.3A4.117,4.117,0,0,0,293.289,290.917Z"
                    transform="translate(-163.495 -169.316)"
                />
                <path
                    d="M360.029,290.917a4.118,4.118,0,0,0,4.117-4.117V237.5a4.117,4.117,0,0,0-8.235,0v49.3A4.118,4.118,0,0,0,360.029,290.917Z"
                    transform="translate(-211.915 -169.316)"
                />
            </g>
        </SVGIcon>
    );
}
