import React from 'react';
import { Button, Div, Image } from 'UIKit/index';
import Image404 from 'Assets/images/404.svg';
import Meteor from 'Assets/images/meteor.svg';
import Astronaut from 'Assets/images/astronaut.svg';
import Spaceship from 'Assets/images/spaceship.svg';
import Rocket from 'Assets/images/rocket.png';
import Planet1 from 'Assets/images/planet1.png';
import Planet2 from 'Assets/images/planet2.png';
import Planet4 from 'Assets/images/planet4.png';
import Planet5 from 'Assets/images/planet5.png';
import Planet6 from 'Assets/images/planet6.png';
import Planet7 from 'Assets/images/planet7.png';
import Planet8 from 'Assets/images/planet8.png';
import Planet9 from 'Assets/images/planet9.png';
import Planet10 from 'Assets/images/planet10.png';
import Planet11 from 'Assets/images/planet11.png';
import './style.scss';

const NotFound = () => {
    return (
        <Div className="NotFound404">
            <Div className="NF404mars" />
            <Image src={Image404} className="NF404logo-404" />
            <Image src={Meteor} className="NF404meteor" />
            <p className="NF404title">Oh no!!</p>
            <p className="NF404subtitle">
                {window.location.href.includes('signup-url-not-active') ? (
                    'Sorry, The signup url is not active any more'
                ) : (
                    <span>
                        You’re either misspelling the URL <br /> or requesting a page that`s no
                        longer here.
                    </span>
                )}
            </p>
            <Div className={'NF404subtitle'}>
                <Button
                    onClick={() => {
                        window.location.href = '/dashboard';
                    }}
                >
                    Go Back Home
                </Button>
            </Div>
            <Image src={Spaceship} className="NF404spaceship" />

            <Div className="NotFoundshapes">
                <Div className="NFSInner">
                    <Div className="rocket-holder">
                        <Image src={Rocket} className="rocket" />
                    </Div>
                    <Div className="shape1 shape0" />
                    <Div className="shape2 shape0" />
                    <Div className="shape3 shape0" />
                    <Div className="shape4 shape0" />
                    <Div className="shape5 shape0" />
                    <Image src={Planet1} className="planet1 planets" />
                    <Image src={Planet2} className="planet2 planets" />
                    <Image src={Planet4} className="planet4 planets" />
                    <Image src={Planet5} className="planet5 planets" />
                    <Image src={Planet6} className="planet6 planets" />
                    <Image src={Planet7} className="planet7 planets" />
                    <Image src={Planet8} className="planet8 planets" />
                    <Image src={Planet9} className="planet9 planets" />
                    <Div className="final">
                        <Image src={Astronaut} className="human" />
                        <Image src={Planet10} className="planet10" />
                        <Image src={Planet11} className="planet11" />
                    </Div>
                </Div>
            </Div>
        </Div>
    );
};

export default NotFound;
