import React, { Fragment } from 'react';
import DCFacebook1 from 'Assets/icons/DCFacebook1';
import './style.scss';
import DCGoogle from 'Assets/icons/DCGoogle';
import { Button, Dialog, Div, Image } from 'UIKit';
import { useQuery } from 'react-query';
import { getBrandingData } from 'apis/login/index.api';
import generateImage from 'Utils/generateImage';
import { useParams } from 'react-router-dom';

const getFaviconEl = () => {
    return document.getElementById('favicon');
};

function PublicReview() {
    const acc_ID: { id: string } = useParams();
    /**
     * Get Account Branding
     */
    const { data } = useQuery(['login', 'branding_data'], () => getBrandingData(acc_ID?.id), {
        onSuccess: data => {
            document.title = `${data.data?.name}`;
            const favicon = getFaviconEl();
            document.documentElement.style.setProperty('--dark-blue', data.data?.colors?.secondary);
            document.documentElement.style.setProperty(
                '--blue-invert',
                data.data?.colors?.font_primary,
            );

            favicon?.setAttribute(
                'href',
                data.data?.images?.favicon?.key
                    ? generateImage(data.data?.images.favicon, null, true) || ''
                    : 'https://s3.wasabisys.com/open/general/branding/dc_dashboard.ico',
            );
        },
    });

    return (
        <Dialog open={true} onClose={() => {}} dialogType={'fullwidth RAPRDialogContainer'}>
            <Div className={'CreateProperty11'}>
                <Div className={'BodyBox'}>
                    <Div className={'BodyBoxInner'}>
                        <Div className={'RAPRDIMContainer'} style={{ backgroundColor: '#fff' }}>
                            <Div className={'RAPRDIMCBox'}>
                                <Div
                                    className="RAPRDIMCBLogoBox"
                                    style={{
                                        backgroundColor: data?.data?.colors?.primary || '#fff',
                                    }}
                                >
                                    <Image
                                        src={generateImage(data?.data?.image, null, true) || ''}
                                        alt={data?.data?.name}
                                        style={{ width: '230px' }}
                                        title={null}
                                        className={null}
                                        onClick={null}
                                        displayName={data?.data?.name}
                                    />
                                </Div>
                                {data?.data?.review_links?.google ||
                                data?.data?.review_links?.facebook ? (
                                    <Fragment>
                                        <Div className={'RAPRDIMCBTitle1'}>
                                            How was your experience?
                                        </Div>
                                        <Div className={'RAPRDIMCBText1'}>
                                            Thanks for choosing us! Would you take a moment and
                                            please leave us a review?
                                        </Div>
                                    </Fragment>
                                ) : (
                                    <Div className={'RAPRDIMCBTitle1'}>
                                        Please integrate at least 1 source to review
                                    </Div>
                                )}
                                {data?.data?.review_links?.google && (
                                    <Button
                                        iconName={<DCGoogle />}
                                        buttonClass={'RAPRDIMCBSButton'}
                                        iconClass={'RAPRDIMCBSBIcon'}
                                        style={{
                                            textAlign: 'left',
                                        }}
                                        onClick={() => {
                                            window.open(data?.data?.review_links?.google, '_blank');
                                        }}
                                    >
                                        Review us on Google
                                    </Button>
                                )}
                                {data?.data?.review_links?.facebook && (
                                    <Button
                                        iconName={<DCFacebook1 />}
                                        buttonClass={'RAPRDIMCBSButton'}
                                        style={{
                                            textAlign: 'left',
                                        }}
                                        onClick={() => {
                                            window.open(
                                                data?.data?.review_links?.facebook,
                                                '_blank',
                                            );
                                        }}
                                    >
                                        Review us on Facebook
                                    </Button>
                                )}
                            </Div>
                        </Div>
                    </Div>
                </Div>
            </Div>
        </Dialog>
    );
}

export default PublicReview;
