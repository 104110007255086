import React from 'react';

import { SVGIcon } from '../../UIKit/index';

export default function DCCopy1(props) {
    return (
        <SVGIcon {...props} viewBox="0 0 512 512">
            <g transform="translate(0 0)">
                <path d="M468.3,0H138.8c-23.5,0.1-42.5,19.1-42.5,42.6v58.1h38.9V42.6c0-2,1.6-3.6,3.6-3.6l0,0h329.5     c2,0,3.6,1.6,3.6,3.6c0,0,0,0,0,0v329.5c0,2-1.6,3.6-3.6,3.6l0,0h-55v38.9h55c23.5,0,42.6-19.1,42.6-42.6V42.6     C510.9,19.1,491.8,0,468.3,0z" />
                <path d="M373.7,97.9H44.1c-23.5,0-42.6,19-42.7,42.5v329.5c0,23.5,19.1,42.6,42.6,42.6h329.5     c23.5,0,42.6-19.1,42.6-42.6V140.4C416.1,117,397.1,98,373.7,97.9z M373.7,473.7H44.1c-2,0-3.6-1.6-3.6-3.6l0,0V140.6     c0-2,1.6-3.6,3.6-3.6c0,0,0,0,0,0h329.5c2,0,3.6,1.6,3.6,3.6l0,0v329.5l0,0C377.2,472,375.6,473.6,373.7,473.7L373.7,473.7z" />
            </g>
        </SVGIcon>
    );
}
