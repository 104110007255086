import React from 'react';

import { SVGIcon } from '../../UIKit/index';

export default function DCImagePlaceholder(props) {
    return (
        <SVGIcon {...props} viewBox="0 0 512 400">
            <path d="M479.45,1.17H31.94c-17.97,0-32.54,14.58-32.54,32.55V367.3c0,17.97,14.57,32.54,32.54,32.54  h447.51c17.97,0,32.55-14.57,32.55-32.54V33.72c0-17.97-14.57-32.55-32.54-32.55C479.46,1.17,479.45,1.17,479.45,1.17z   M479.45,33.71v236.7l-64.14-58.29c-9.64-8.76-24.47-8.41-33.68,0.81l-64.91,64.89l-128-152.89c-8.65-10.34-24.04-11.7-34.38-3.05  c-1.02,0.85-1.97,1.79-2.84,2.8L31.94,263.63V33.72L479.45,33.71z M341.13,119.13c0.02-24.72,20.08-44.73,44.79-44.71  c24.72,0.02,44.73,20.08,44.71,44.79c-0.02,24.7-20.05,44.71-44.75,44.71c-24.71,0-44.75-20.03-44.75-44.75c0-0.01,0-0.02,0-0.02  L341.13,119.13z" />
        </SVGIcon>
    );
}
