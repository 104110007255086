import React from 'react';

import { SVGIcon } from '../../UIKit/index';

export default function DCTIFFFile(props) {
    return (
        <SVGIcon {...props} viewBox="0 0 512 512">
            <path
                d="m392 488h-272a48 48 0 0 1 -48-48v-368a48 48 0 0 1 48-48h224l96 96v320a48 48 0 0 1 -48 48z"
                fill="#005eff33"
            />
            <path
                d="m72 360h368a0 0 0 0 1 0 0v80a48 48 0 0 1 -48 48h-272a48 48 0 0 1 -48-48v-80a0 0 0 0 1 0 0z"
                fill="#005eff"
            />
            <g fill="#005eff50">
                <path d="m440 120h-48a48 48 0 0 1 -48-48v-48z" />
                <path d="m215.329 148.794a8 8 0 0 0 -14.658 0l-56 128a8 8 0 1 0 14.658 6.412l18.903-43.206h59.536l18.9 43.206a8 8 0 0 0 14.658-6.412zm-30.097 75.206 22.768-52.041 22.768 52.041z" />
                <path d="m360 208a8 8 0 0 0 -8 8v.022a40 40 0 1 0 0 63.956v.022a8 8 0 0 0 16 0v-64a8 8 0 0 0 -8-8zm-32 64a24 24 0 1 1 24-24 24.028 24.028 0 0 1 -24 24z" />
            </g>
            <path
                d="m336 384h-32a8 8 0 0 0 -8 8v64a8 8 0 0 0 16 0v-24h8a8 8 0 0 0 0-16h-8v-16h24a8 8 0 0 0 0-16z"
                fill="#fff"
            />
            <path
                d="m208 384h-32a8 8 0 0 0 0 16h8v56a8 8 0 0 0 16 0v-56h8a8 8 0 0 0 0-16z"
                fill="#fff"
            />
            <path
                d="m272 384h-32a8 8 0 0 0 0 16h8v56a8 8 0 0 0 16 0v-56h8a8 8 0 0 0 0-16z"
                fill="#fff"
            />
        </SVGIcon>
    );
}
