import { initializeApp } from 'firebase/app';
import { getMessaging } from 'firebase/messaging';

initializeApp({
    apiKey: process.env.REACT_APP_FCM_API_KEY,
    authDomain: process.env.REACT_APP_FCM_AUTH_DOMAIN,
    databaseURL: process.env.REACT_APP_FCM_DATABASE_URL,
    projectId: process.env.REACT_APP_FCM_PROJECT_ID,
    storageBucket: process.env.REACT_APP_FCM_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_FCM_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_FCM_APP_ID,
});

let messaging;
try {
    messaging = getMessaging();
} catch (e) {
    console.error('messaging error', e);
}

export { messaging };
