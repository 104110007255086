import React from 'react';

import { SVGIcon } from '../../UIKit/index';

export default function DCEmptyBox({ props }) {
    return (
        <SVGIcon {...props} viewBox="0 0 24 24">
            <path d="M19 5v14H5V5h14m0-2H5c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2z" />
        </SVGIcon>
    );
}
