import React from 'react';

import { SVGIcon } from '../../UIKit/index';

export default function DCCloseIcon(props) {
    return (
        <SVGIcon {...props} viewBox="0 0 512 512">
            <polygon points="359.88 181.41 329.69 151.22 258.02 222.89 186.35 151.22 156.17 181.41 227.83 253.07 156.17 324.73 186.35 354.92 258.02 283.25 329.69 354.92 359.88 324.73 288.21 253.07 359.88 181.41" />
            <path d="M256.02,511.98C114.85,511.98,.02,397.15,.02,255.98S114.85,.02,256.02,.02s255.95,114.82,255.95,255.95-114.82,256-255.95,256Zm0-469.26c-117.62,0-213.3,95.67-213.3,213.26s95.69,213.3,213.3,213.3,213.26-95.69,213.26-213.3S373.6,42.72,256.02,42.72Z" />
        </SVGIcon>
    );
}
