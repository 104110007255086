import React from 'react';

import { SVGIcon } from '../../UIKit/index';

export default function DCSoundMessage(props) {
    return (
        <SVGIcon {...props} viewBox="0 0 506 398">
            <g>
                <path d="M16.71,120.02c-8.85,0-16.03,7.18-16.03,16.03c0,0,0,0,0,0v125.18   c0,8.85,7.18,16.03,16.03,16.03s16.03-7.18,16.03-16.03V136.04C32.74,127.19,25.56,120.02,16.71,120.02   C16.71,120.02,16.71,120.02,16.71,120.02z" />
                <path d="M356.54,26.46h-66.72c-8.85,0-16.03,7.18-16.03,16.03l0,0v14.49L59.04,120.55   c-6.8,2.01-11.47,8.26-11.48,15.36v125.11c0.01,0.81,0.07,1.61,0.2,2.41v0.43c0.11,0.67,0.27,1.33,0.47,1.97L93.7,411.16   c2.64,8.44,11.63,13.15,20.08,10.51l66.19-20.71c8.44-2.64,13.15-11.63,10.51-20.08l-22.45-71.97l105.74,31.35v14.49   c0,8.85,7.18,16.03,16.03,16.03l0,0h66.72c8.85,0,16.03-7.18,16.03-16.03l0,0V42.49C372.53,33.65,365.38,26.48,356.54,26.46z    M155.17,375.16l-35.6,11.14L87.88,285.15l43.1,12.78L155.17,375.16z M148.48,269.62L148.48,269.62l-68.63-20.34V148.02   l194.07-57.59v216.39L148.48,269.62z M340.58,338.72h-34.73V58.55h34.63L340.58,338.72z" />
                <path d="M481.68,288.43l-69.4-34.63c-7.92-3.96-17.55-0.76-21.52,7.16   c-3.96,7.92-0.76,17.55,7.16,21.52l0,0l69.4,34.7c7.92,3.96,17.55,0.76,21.52-7.16c3.96-7.92,0.76-17.55-7.16-21.52l0,0V288.43z" />
                <path d="M405.13,145.25c2.49,0,4.94-0.59,7.16-1.71l69.4-34.7c7.92-3.96,11.12-13.6,7.16-21.52   c-3.96-7.92-13.6-11.12-21.52-7.16l-69.4,34.7c-7.93,3.94-11.16,13.56-7.23,21.48C393.42,141.82,399.02,145.27,405.13,145.25z" />
                <path d="M489.48,182.59h-57.95c-8.86,0-16.04,7.18-16.04,16.04s7.18,16.04,16.04,16.04h57.95   c8.86,0,16.04-7.18,16.04-16.04S498.34,182.59,489.48,182.59z" />
            </g>
        </SVGIcon>
    );
}
