import React from 'react';

import { Div } from '../index';
import PropTypes from 'prop-types';
import './style.scss';

const GridContainer = ({ containerClass, rowClass, children }) => {
    return (
        <Div className={'GridContainer ' + (containerClass ? containerClass : '')}>
            <Div className={'GridContainerRow ' + (rowClass ? rowClass : '')}>{children}</Div>
        </Div>
    );
};

GridContainer.propTypes = {
    containerClass: PropTypes.string,
    rowClass: PropTypes.string,
    children: PropTypes.element,
};

export default GridContainer;
