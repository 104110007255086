/* eslint-disable no-unused-vars */
import React, { useMemo, useReducer, useCallback } from 'react';
import FormsContext from '../Context/Context';
import initialState from '../Store/store';
import actions from '../Actions/actions';
import debounce from 'Utils/debounce';
import useToast from 'Modules/Toasts';
import reducer from '../Reducer/reducer';
import { autoSaveAPI } from 'apis/Forms/index.api';

const FormsContextProvider = props => {
    const [state, dispatch] = useReducer(reducer, initialState);
    const { showToast } = useToast();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const save = useCallback(
        debounce((value, id) => {
            const createTemplates = localStorage.getItem('createTemplate');
            dispatch({ type: actions.LOADING, value: true });
            let sendData =
                createTemplates === 'true'
                    ? {
                          templatedata: value,
                      }
                    : { formdata: value };

            autoSaveAPI(sendData, id, createTemplates)
                .then(response => {
                    dispatch({ type: actions.LOADING, value: false });
                    dispatch({
                        type: actions.AUTO_SAVE,
                        value: response.updatedAt,
                    });
                })
                .catch(err => {
                    dispatch({ type: actions.LOADING, value: false });
                    showToast({
                        type: 'error',
                        message: err.message || 'Failed to AutoSave',
                    });
                });
        }, 2500),
        [],
    );

    const value = useMemo(() => {
        return {
            formsColumns: state.formsColumns,
            forms: state.forms,
            formsCurrentPage: state.formsCurrentPage,
            searchTerm: state.searchTerm,
            formsCategories: state.formsCategories,
            formsTags: state.formsTags,
            filters: state.filters,

            activeCategory: state.activeCategory,
            categories: state.categories,
            templates: state.templates,
            templateSearchText: state.templateSearchText,
            initialTemplateLoad: state.initialTemplateLoad,
            categoryAll: state.categoryAll,

            id: state.id,
            time: state.time,
            loading: state.loading,
            options: state.options,
            style: state.style,
            domain: state.domain,
            formComponents: state.formComponents,
            categoriesLoaded: state.categoriesLoaded,
            tagsLoaded: state.tagsLoaded,

            setFormsColumns: value => {
                dispatch({ type: actions.SET_FORMS_COLUMNS, value });
            },

            /**
             *
             * @param {Array} data : array of form objects
             * @param {Number} page : current page from pagination
             * @param {Number} next_page : next_page of forms from pagination
             * @param {Number} total : total no. of forms from pagination
             * @param {Number} total_pages : total no. of pages from pagination
             * @param {String} mode : "replace" or undefined
             */
            setForms: (data, page, next_page, total, total_pages, mode) => {
                dispatch({
                    type: actions.SET_FORMS,
                    payload: { data, page, next_page, total, total_pages, mode },
                });
            },

            setFormsTotal: value => {
                dispatch({
                    type: actions.SET_FORMS_TOTAL,
                    value,
                });
            },

            clearForms: () => {
                dispatch({
                    type: actions.CLEAR_FORMS,
                });
            },

            /**
             * @param {number} value
             */
            setFormsCurrentPage: value => {
                dispatch({ type: actions.SET_FORMS_CURRENT_PAGE, payload: value });
            },

            setSearchTerm: value => {
                dispatch({ type: actions.SET_FORMS_SEARCH_TERM, payload: value });
            },

            setFormsCategories: value => {
                dispatch({ type: actions.SET_FORMS_CATEGORIES, payload: value });
            },

            setFormsTags: value => {
                dispatch({ type: actions.SET_FORMS_TAGS, payload: value });
            },

            setFormsActiveCategory: value => {
                dispatch({ type: actions.SET_FORMS_ACTIVE_CATEGORIES, payload: value });
            },

            setFormsActiveTags: value => {
                dispatch({ type: actions.SET_FORMS_ACTIVE_TAGS, payload: value });
            },

            /* My Forms End */

            setActiveCategory: value => {
                dispatch({ type: actions.SET_ACTIVE_CATEGORY, value });
            },
            setCategoryAll: value => {
                dispatch({ type: actions.SET_CATEGORY_ALL, value });
            },
            setCategories: value => {
                dispatch({
                    type: actions.SET_CATEGORIES,
                    value,
                });
            },
            setTemplates: value => {
                dispatch({
                    type: actions.SET_TEMPLATES_DATA,
                    value,
                });
            },
            setInitialTemplateLoad: value => {
                dispatch({
                    type: actions.SET_INITIAL_TEMPLATE_LOAD,
                    value,
                });
            },
            setTemplateSearchText: value => {
                dispatch({
                    type: actions.SET_TEMPLATE_SEARCH_TEXT,
                    value,
                });
            },

            // builder Provider
            resetStyles: () => {
                dispatch({ type: actions.RESET_STYLES });
            },
            addId: value => {
                dispatch({ type: actions.ADD_ID, value });
            },

            autoSave: save,

            updateOptions: value => {
                dispatch({ type: actions.UPDATE_OPTIONS, value });
            },
            updateStyle: value => {
                dispatch({ type: actions.UPDATE_STYLE, value });
            },
            addDomain: value => {
                dispatch({ type: actions.ADD_DOMAIN, value });
            },
            addFormComponents: value => {
                dispatch({ type: actions.ADD_FORM_COMPONENTS, value });
            },
            setCategoriesLoaded: value => {
                dispatch({ type: actions.SET_CATEGORIES_LOADED, value });
            },
            setTagsLoaded: value => {
                dispatch({ type: actions.SET_TAGS_LOADED, value });
            },
        };
    }, [save, state]);

    return <FormsContext.Provider value={value}>{props.children}</FormsContext.Provider>;
};

export default FormsContextProvider;
