import React, { useCallback, useEffect, useState } from 'react';
import Cropper from 'react-cropper';
import PropTypes from 'prop-types';
import 'cropperjs/dist/cropper.css';
import './style.scss';

import { Div } from 'UIKit';
import UserPlaceholder from 'Assets/images/userplaceholder.jpg';

const CropImage = ({ uploadImg, className, onChange, roundCrop, cropRef, maintainRatio }) => {
    const [image, setImage] = useState(uploadImg || UserPlaceholder);
    const [cropper, setCropper] = useState();

    useEffect(() => {
        setImage(uploadImg);
    }, [uploadImg]);

    const getCropData = useCallback(() => {
        if (typeof cropper !== 'undefined') {
            const temp = cropper.getCroppedCanvas();
            onChange && onChange(temp);
        }
    }, [cropper, onChange]);

    return (
        <Div className={'CropImage ' + (className ? className : '')}>
            <button hidden onClick={getCropData} ref={cropRef} />
            <Cropper
                style={{ height: '100%', width: '100%' }}
                zoomTo={0.0}
                aspectRatio={maintainRatio ? 1 : null}
                initialAspectRatio={roundCrop ? 1 : 16 / 9}
                preview=".img-preview"
                src={image}
                viewMode={1}
                minCropBoxHeight={10}
                minCropBoxWidth={10}
                background={false}
                responsive={true}
                autoCropArea={0.8}
                checkOrientation={false}
                onInitialized={instance => {
                    setCropper(instance);
                }}
                guides={true}
                // center={true}
                // cropend={getCropData}
            />
        </Div>
    );
};

CropImage.propTypes = {
    uploadImg: PropTypes.string.isRequired,
    className: PropTypes.string,
    onChange: PropTypes.func.isRequired,
    roundCrop: PropTypes.bool,
    cropRef: PropTypes.object,
    maintainRatio: PropTypes.bool,
};

export default CropImage;
