import React from 'react';

import { SVGIcon } from '../../UIKit/index';

export default function DCGrayConversations(props) {
    return (
        <SVGIcon {...props} viewBox="0 0 450.8 500.33">
            <g id="Conversation_" transform="translate(-32.76 -5.52)">
                <ellipse
                    id="_Path_"
                    cx="152.38"
                    cy="14.96"
                    rx="152.38"
                    ry="14.96"
                    transform="translate(105.35 475.93)"
                    fill="#f4f5f6"
                />
                <ellipse
                    id="Ellipse_9"
                    cx="223.74"
                    cy="226.68"
                    rx="223.74"
                    ry="226.68"
                    transform="translate(34.26 7.29)"
                    fill="#fff"
                    stroke="#5e5e5e"
                    strokeMiterlimit="10"
                    strokeWidth="3"
                />
                <rect
                    id="Rectangle_89"
                    width="35.78"
                    height="0.26"
                    transform="translate(369.4 393.9)"
                    fill="#e0e0e0"
                />
                <rect
                    id="Rectangle_90"
                    width="20.13"
                    height="0.26"
                    transform="translate(107.75 394.03)"
                    fill="#e0e0e0"
                />
                <rect
                    id="Rectangle_91"
                    width="40.81"
                    height="0.26"
                    transform="translate(232.64 393.42)"
                    fill="#e0e0e0"
                />
                <rect
                    id="Rectangle_92"
                    width="18.24"
                    height="0.26"
                    transform="translate(213.35 389.72)"
                    fill="#e0e0e0"
                />
                <path
                    id="Path_120"
                    d="M469.3,252.71c-12.8-19.48-1.11-40.42-3.06-62.09s-22.54-26.39-18.64-44.76c4.51-21.29-7.52-41.73-21.15-41.73s-25.66,20.44-21.15,41.73c3.9,18.37-16.69,23.1-18.64,44.76s9.74,42.61-3.06,62.09-9.44,45.27-2.23,58.07a50.522,50.522,0,0,0,6.88,9.42,52.21,52.21,0,0,0,76.4,0,50,50,0,0,0,6.87-9.42C478.74,298,482.1,272.18,469.3,252.71Z"
                    fill="#f4f5f6"
                />
                <path
                    id="Path_121"
                    d="M345.11,382.89H327.5V274a8.83,8.83,0,0,1,8.81-8.81h0a8.83,8.83,0,0,1,8.8,8.81Z"
                    fill="#f4f5f6"
                />
                <circle
                    id="Ellipse_10"
                    cx="54.26"
                    cy="54.26"
                    r="54.26"
                    transform="translate(282.05 244.99)"
                    fill="#e3e2e3"
                />
                <circle
                    id="Ellipse_11"
                    cx="21.38"
                    cy="21.38"
                    r="21.38"
                    transform="translate(353.404 293.405) rotate(-76.05)"
                    fill="#f0f0f0"
                />
                <circle
                    id="Ellipse_12"
                    cx="12.66"
                    cy="12.66"
                    r="12.66"
                    transform="translate(277.888 275.604) rotate(-80.75)"
                    fill="#f4f5f6"
                />
                <circle
                    id="Ellipse_13"
                    cx="8.89"
                    cy="8.89"
                    r="8.89"
                    transform="translate(297.3 249.72)"
                    fill="#f4f5f6"
                />
                <circle
                    id="Ellipse_14"
                    cx="13.8"
                    cy="13.8"
                    r="13.8"
                    transform="translate(248.737 415.548) rotate(-80.75)"
                    fill="#f4f5f6"
                />
                <circle
                    id="Ellipse_15"
                    cx="9.69"
                    cy="9.69"
                    r="9.69"
                    transform="translate(269.9 387.33)"
                    fill="#e3e2e3"
                />
                <path
                    id="Path_122"
                    d="M232.85,317.88a58.86,58.86,0,0,0-23.62,9c-.07-8.23-1.57-16.05-5.44-22.36-5.89,5.74-10.66,13.16-14.52,21.15-1.44-15-5.38-29.56-14.11-38.75-13.07,8.22-19.81,21.93-23,36.45a66,66,0,0,0-22.32-24.13c-8,8.42-10.55,20.08-10.34,32-9.09-10.58-19.18-18.84-29-19.81-2.42,16.76,2.74,32.56,9.29,45a49.85,49.85,0,0,0,44.17,26.42h42.73c18.22,0,35.29-9.72,43.83-25.8C235.9,346.9,238.47,333.78,232.85,317.88Z"
                    fill="#e3e2e3"
                />
                <path
                    id="Path_123"
                    d="M212.35,101.81c5.28-15.47,1.09-26.48-11.82-26.48-8.09,0-18.13,4.34-27.9,11.39-1.11-4-4.22-6.43-9.22-6.43-8.81,0-20.94,7.45-30.46,18-2.31-2.35-5.64-3.78-9.93-4-8.42-.48-18.63,3.8-27.5,10.77a4.77,4.77,0,0,0-3.91-2c-4.85-.27-11.43,4-14.69,9.64-.21.36-.39.69-.57,1H214.91C215.33,108.8,214.47,104.72,212.35,101.81Z"
                    fill="#e3e2e3"
                />
                <path
                    id="Path_124"
                    d="M368.89,138.58a10.809,10.809,0,0,0-1.61-.12c.5-7.54-2.39-12.36-8.55-12.36-4.44,0-9.9,2.51-15.46,6.77,1.59-14.65-3.5-24.17-15-24.17-9.31,0-21.09,6.19-32.73,16.29-1-5.74-4.45-9.19-10.21-9.19-10.12,0-24.5,10.65-36,25.66h0a14.46,14.46,0,0,0-11.15-5.74c-9.64-.68-21.6,5.43-32.21,15.4a4.93,4.93,0,0,0-4.38-2.87c-5.27-.36-12.54,5.13-16.75,12.48H377.37C381.89,149.59,378.29,139.84,368.89,138.58Z"
                    fill="#f4f5f6"
                />
                <g id="Group_2" opacity="0.67">
                    <path
                        id="Path_125"
                        d="M63.21,141.77l237.1,34.13,31.82,32.11Z"
                        fill="#707171"
                        stroke="#707171"
                        strokeMiterlimit="10"
                        strokeWidth="1"
                    />
                    <path
                        id="Path_126"
                        d="M63.21,141.77l237.1,34.13,31.82,32.11Z"
                        fill="#707171"
                        stroke="#707171"
                        strokeMiterlimit="10"
                        strokeWidth="1"
                        opacity="0.6"
                    />
                </g>
                <path
                    id="Path_127"
                    d="M63.21,141.77l237.1,34.13-11,46.43Z"
                    fill="#5e5e5e"
                    stroke="#707171"
                    strokeMiterlimit="10"
                    strokeWidth="1"
                />
                <path
                    id="Path_128"
                    d="M63.21,141.77l237.1,34.13-11,46.43Z"
                    fill="#5e5e5e"
                    stroke="#707171"
                    strokeMiterlimit="10"
                    strokeWidth="1"
                    opacity="0.2"
                />
                <path
                    id="Path_129"
                    d="M284.44,88l13.76,64.52a6.5,6.5,0,0,1-5,7.7L170.4,186.44a6.51,6.51,0,0,1-7.71-5l-13.76-64.51a6.48,6.48,0,0,1,5-7.7L276.73,83a6.5,6.5,0,0,1,7.71,5Z"
                    fill="#407bff"
                />
                <path
                    id="Path_130"
                    d="M284.44,88l13.76,64.52a6.5,6.5,0,0,1-5,7.7L170.4,186.44a6.51,6.51,0,0,1-7.71-5l-13.76-64.51a6.48,6.48,0,0,1,5-7.7L276.73,83a6.5,6.5,0,0,1,7.71,5Z"
                    fill="#707171"
                    stroke="#707171"
                    strokeMiterlimit="10"
                    strokeWidth="1"
                />
                <path
                    id="Path_131"
                    d="M281.42,83.8l-41,50.37A26.43,26.43,0,0,1,208,141.08l-58-29.23a6.5,6.5,0,0,1,4-2.62L276.73,83a6.49,6.49,0,0,1,4.69.8Z"
                    fill="#e3e2e3"
                />
                <g id="Group_3" opacity="0.29">
                    <path
                        id="Path_132"
                        d="M63.21,141.77l195.56,46.48,30.54,34.08Z"
                        fill="#e3e2e3"
                    />
                    <path
                        id="Path_133"
                        d="M63.21,141.77l195.56,46.48,30.54,34.08Z"
                        fill="#e3e2e3"
                        opacity="0.6"
                    />
                </g>
                <rect
                    id="Rectangle_93"
                    width="1.04"
                    height="25.22"
                    transform="matrix(0.505, -0.863, 0.863, 0.505, 134.024, 187.461)"
                    fill="#e3e2e3"
                />
                <rect
                    id="Rectangle_94"
                    width="1.04"
                    height="9.07"
                    transform="matrix(0.505, -0.863, 0.863, 0.505, 165.394, 205.835)"
                    fill="#e3e2e3"
                />
                <path id="Path_134" d="M63.21,141.77l195.56,46.48-38.01,45.81Z" fill="#e3e2e3" />
                <path
                    id="Path_135"
                    d="M324.78,250.66a2.06,2.06,0,0,1-.76-.15L292.16,237.9a2.081,2.081,0,0,1,1.53-3.87l31.86,12.62a2.08,2.08,0,0,1-.77,4Z"
                    fill="#707171"
                    stroke="#707171"
                    strokeMiterlimit="10"
                    strokeWidth="1"
                />
                <path
                    id="Path_136"
                    d="M324.78,250.66a2.06,2.06,0,0,1-.76-.15L292.16,237.9a2.081,2.081,0,0,1,1.53-3.87l31.86,12.62a2.08,2.08,0,0,1-.77,4Z"
                    fill="#707171"
                    stroke="#707171"
                    strokeMiterlimit="10"
                    strokeWidth="1"
                    opacity="0.3"
                />
                <path
                    id="Path_137"
                    d="M322.91,232.39a2.07,2.07,0,0,1-.77-.15L305,225.47a2.081,2.081,0,1,1,1.53-3.87l17.11,6.78a2.08,2.08,0,0,1-.76,4Z"
                    fill="#e3e2e3"
                />
                <path
                    id="Path_138"
                    d="M322.91,232.39a2.07,2.07,0,0,1-.77-.15L305,225.47a2.081,2.081,0,1,1,1.53-3.87l17.11,6.78a2.08,2.08,0,0,1-.76,4Z"
                    fill="#e3e2e3"
                    opacity="0.5"
                />
                <path
                    id="Path_139"
                    d="M287.13,250.35a2.06,2.06,0,0,1-.76-.15l-31.06-12.3a2.081,2.081,0,1,1,1.53-3.87l31.06,12.3a2.08,2.08,0,0,1-.77,4Z"
                    fill="#707171"
                    stroke="#707171"
                    strokeMiterlimit="10"
                    strokeWidth="1"
                />
                <path
                    id="Path_140"
                    d="M287.13,250.35a2.06,2.06,0,0,1-.76-.15l-31.06-12.3a2.081,2.081,0,1,1,1.53-3.87l31.06,12.3a2.08,2.08,0,0,1-.77,4Z"
                    fill="#707171"
                    stroke="#707171"
                    strokeMiterlimit="10"
                    strokeWidth="1"
                    opacity="0.3"
                />
                <path
                    id="Path_141"
                    d="M129.67,252.85a2.311,2.311,0,0,1-.8-.15L95.6,239.52a2.17,2.17,0,1,1,1.6-4l33.27,13.17a2.17,2.17,0,0,1-.8,4.19Z"
                    fill="#707171"
                    stroke="#707171"
                    strokeMiterlimit="10"
                    strokeWidth="1"
                />
                <path
                    id="Path_142"
                    d="M129.67,252.85a2.311,2.311,0,0,1-.8-.15L95.6,239.52a2.17,2.17,0,1,1,1.6-4l33.27,13.17a2.17,2.17,0,0,1-.8,4.19Z"
                    fill="#707171"
                    stroke="#707171"
                    strokeMiterlimit="10"
                    strokeWidth="1"
                    opacity="0.3"
                />
                <path
                    id="Path_143"
                    d="M127.71,233.77a2.31,2.31,0,0,1-.8-.15l-17.86-7.08a2.17,2.17,0,1,1,1.6-4l17.86,7.07a2.17,2.17,0,0,1-.8,4.19Z"
                    fill="#e3e2e3"
                />
                <path
                    id="Path_144"
                    d="M127.71,233.77a2.31,2.31,0,0,1-.8-.15l-17.86-7.08a2.17,2.17,0,1,1,1.6-4l17.86,7.07a2.17,2.17,0,0,1-.8,4.19Z"
                    fill="#e3e2e3"
                    opacity="0.5"
                />
                <path
                    id="Path_145"
                    d="M90.36,252.52a2.22,2.22,0,0,1-.8-.15L57.12,239.52a2.17,2.17,0,1,1,1.6-4l32.44,12.84a2.17,2.17,0,0,1-.8,4.19Z"
                    fill="#707171"
                    stroke="#707171"
                    strokeMiterlimit="10"
                    strokeWidth="1"
                />
                <path
                    id="Path_146"
                    d="M90.36,252.52a2.22,2.22,0,0,1-.8-.15L57.12,239.52a2.17,2.17,0,1,1,1.6-4l32.44,12.84a2.17,2.17,0,0,1-.8,4.19Z"
                    fill="#707171"
                    stroke="#707171"
                    strokeMiterlimit="10"
                    strokeWidth="1"
                    opacity="0.3"
                />
                <path
                    id="Path_147"
                    d="M399.6,156.6c2.76,5.38,5.29,10.76,7.69,16.28s4.64,11.09,6.59,16.94l.71,2.22c.12.4.3,1,.4,1.5s.2,1,.26,1.42a38,38,0,0,1,.35,5.06,85.855,85.855,0,0,1-.43,9.31,149.628,149.628,0,0,1-2.83,17.91l-5.7-.63c-.08-5.86-.13-11.78-.33-17.55-.11-2.88-.27-5.75-.56-8.46a34.9,34.9,0,0,0-.62-3.77c-.06-.29-.14-.52-.21-.76a5.56,5.56,0,0,0-.23-.62l-.78-2c-2.17-5.26-4.62-10.57-7.11-15.85l-7.55-15.85Z"
                    fill="#e3e2e3"
                />
                <path
                    id="Path_148"
                    d="M406.72,224.83l-2.66,5,8.5,2.38s1.62-4-.31-7.17Z"
                    fill="#e3e2e3"
                />
                <path id="Path_149" d="M400.8,236.98l7.82.62,3.94-5.42-8.5-2.37Z" fill="#e3e2e3" />
                <path
                    id="Path_150"
                    d="M363.8,120.66a1.132,1.132,0,0,1-.94,1c-.4,0-.61-.64-.49-1.24a1.122,1.122,0,0,1,.94-1C363.71,119.42,363.92,120.06,363.8,120.66Z"
                    fill="#707171"
                    stroke="#707171"
                    strokeMiterlimit="10"
                    strokeWidth="1"
                />
                <path
                    id="Path_151"
                    d="M363.31,121.7a22,22,0,0,1-3.93,4.58,3.56,3.56,0,0,0,2.77,1.13Z"
                    fill="#e3e2e3"
                />
                <path
                    id="Path_152"
                    d="M366.69,117.76a.37.37,0,0,1-.27-.12,2.91,2.91,0,0,0-2.42-1,.36.36,0,1,1-.12-.71,3.61,3.61,0,0,1,3.08,1.2.37.37,0,0,1,0,.51.39.39,0,0,1-.27.12Z"
                    fill="#707171"
                    stroke="#707171"
                    strokeMiterlimit="10"
                    strokeWidth="1"
                />
                <path
                    id="Path_153"
                    d="M381,129.15c-1.09,5.59-2.19,15.84,1.72,19.57,0,0-1.53,5.67-11.91,5.67-11.42,0-5.46-5.67-5.46-5.67,6.23-1.49,6.07-6.12,5-10.46Z"
                    fill="#e3e2e3"
                />
                <path
                    id="Path_154"
                    d="M384.29,152.25c.82-2.74,1.73-5.88-.15-6.86-2.06-1.08-13.37-2.18-17.27-.15s-2.8,7.58-2.8,7.58Z"
                    fill="#707171"
                    stroke="#707171"
                    strokeMiterlimit="10"
                    strokeWidth="1"
                />
                <path
                    id="Path_155"
                    d="M384.28,152.25c.83-2.74,1.73-5.87-.14-6.86-2.07-1.08-13.38-2.18-17.28-.15s-2.8,7.58-2.8,7.58Z"
                    fill="#fdfeff"
                    opacity="0.15"
                />
                <path
                    id="Path_156"
                    d="M408.28,411.61a2.51,2.51,0,0,1-1.84-.5.74.74,0,0,1,0-.86.83.83,0,0,1,.57-.42c1.12-.26,3.22,1.18,3.31,1.24a.19.19,0,0,1,.08.19.18.18,0,0,1-.14.14,10,10,0,0,1-1.98.21Zm-1-1.45h-.22a.5.5,0,0,0-.33.25c-.17.29-.07.42,0,.46.33.43,1.89.44,3,.24a6,6,0,0,0-2.41-.95Z"
                    fill="#5e5e5e"
                />
                <path
                    id="Path_157"
                    d="M410.23,411.4a.15.15,0,0,1-.09,0c-.79-.43-2.32-2.1-2.15-2.95a.59.59,0,0,1,.57-.48,1,1,0,0,1,.77.23c.89.73,1.07,2.93,1.08,3a.15.15,0,0,1-.08.16.17.17,0,0,1-.1.04Zm-1.54-3.09h-.08c-.24,0-.26.14-.27.18-.09.51.88,1.77,1.67,2.37a4.35,4.35,0,0,0-.91-2.39.61.61,0,0,0-.41-.16Z"
                    fill="#5e5e5e"
                />
                <path
                    id="Path_158"
                    d="M362.4,411.62c-1,0-1.93-.14-2.24-.58a.67.67,0,0,1,0-.79.89.89,0,0,1,.57-.42c1.27-.34,3.95,1.17,4.06,1.24a.177.177,0,0,1-.06.33,13,13,0,0,1-2.33.22Zm-1.21-1.49a1.779,1.779,0,0,0-.35,0,.58.58,0,0,0-.36.26c-.12.22-.08.33,0,.4.34.47,2.27.5,3.71.3A8.291,8.291,0,0,0,361.19,410.13Z"
                    fill="#5e5e5e"
                />
                <path
                    id="Path_159"
                    d="M364.71,411.4h-.08c-.92-.42-2.74-2.07-2.61-2.93,0-.21.18-.46.68-.51a1.35,1.35,0,0,1,1,.31c1,.8,1.18,2.86,1.19,2.95a.18.18,0,0,1-.08.16.17.17,0,0,1-.1.02Zm-1.87-3.09h-.1c-.33,0-.35.16-.36.21-.08.51,1.16,1.81,2.1,2.38a4.28,4.28,0,0,0-1-2.36.94.94,0,0,0-.64-.23Z"
                    fill="#5e5e5e"
                />
                <path id="Path_160" d="M373.11,411.22h-7.64l-.76-17.69h7.64Z" fill="#e3e2e3" />
                <path id="Path_161" d="M418.98,411.22h-7.64l-3.84-17.69h7.64Z" fill="#e3e2e3" />
                <path
                    id="Path_162"
                    d="M410.8,410.34h8.58a.66.66,0,0,1,.65.52l1.51,6.79a1.12,1.12,0,0,1-1.11,1.36c-3-.05-4.46-.23-8.23-.23-2.32,0-7.11.24-10.32.24s-3.62-3.17-2.31-3.45c5.89-1.29,8.11-3.07,10-4.76a1.829,1.829,0,0,1,1.23-.47Z"
                    fill="#5e5e5e"
                />
                <path
                    id="Path_163"
                    d="M365.52,410.34h8.34a.66.66,0,0,1,.65.52l1.51,6.79a1.11,1.11,0,0,1-1.1,1.36c-3-.05-7.35-.23-11.14-.23-4.42,0-8.24.24-13.43.24-3.14,0-4-3.17-2.69-3.45,6-1.31,10.85-1.45,16-4.64a3.581,3.581,0,0,1,1.86-.59Z"
                    fill="#5e5e5e"
                />
                <path
                    id="Path_164"
                    d="M361.83,162.14a177.375,177.375,0,0,1-19-2.54c-3.15-.6-6.31-1.28-9.47-2.13a75.759,75.759,0,0,1-9.53-3.14c-.8-.33-1.61-.7-2.41-1.08a22.143,22.143,0,0,1-2.6-1.52c-1.5-1-2.79-2-4.07-3-2.54-2-4.91-4.16-7.24-6.3-4.65-4.3-9.07-8.75-13.32-13.44l3.83-4.27,15.09,10.84c2.49,1.8,5,3.55,7.48,5.21,1.23.82,2.49,1.59,3.64,2.24a11,11,0,0,0,1.49.73c.62.25,1.25.49,1.91.71a110.94,110.94,0,0,0,17.18,3.84c5.94,1,12.08,1.72,18,2.33Z"
                    fill="#e3e2e3"
                />
                <path
                    id="Path_165"
                    d="M396.4,150.44c7.16,1.64,14.4,22.58,14.4,22.58L396.06,183.6s-4.12-9.76-8.17-18.28C383.65,156.38,389.08,148.76,396.4,150.44Z"
                    fill="#407bff"
                />
                <path
                    id="Path_166"
                    d="M396.4,150.44c7.16,1.64,14.4,22.58,14.4,22.58L396.06,183.6s-4.12-9.76-8.17-18.28C383.65,156.38,389.08,148.76,396.4,150.44Z"
                    fill="#707171"
                    stroke="#707171"
                    strokeMiterlimit="10"
                    strokeWidth="1"
                />
                <path
                    id="Path_167"
                    d="M392.27,161.88l-2.92,6.54c3,6.54,5.79,13,6.53,14.73C395.78,175.42,394.31,165.83,392.27,161.88Z"
                    fill="#5e5e5e"
                />
                <path
                    id="Path_168"
                    d="M392.27,161.88l-2.92,6.54c3,6.54,5.79,13,6.53,14.73C395.78,175.42,394.31,165.83,392.27,161.88Z"
                    fill="#5e5e5e"
                    opacity="0.6"
                />
                <path
                    id="Path_169"
                    d="M353.6,150.84s-8.13,9.18-.45,66H394c.28-6.28-3.66-36.88,2.39-66.44a106.81,106.81,0,0,0-13.68-1.72,147.924,147.924,0,0,0-17.37,0A76.41,76.41,0,0,0,353.6,150.84Z"
                    fill="#407bff"
                />
                <path
                    id="Path_170"
                    d="M353.6,150.84s-8.13,9.18-.45,66H394c.28-6.28-3.66-36.88,2.39-66.44a106.81,106.81,0,0,0-13.68-1.72,147.924,147.924,0,0,0-17.37,0A76.41,76.41,0,0,0,353.6,150.84Z"
                    fill="#707171"
                    stroke="#707171"
                    strokeMiterlimit="10"
                    strokeWidth="1"
                />
                <path
                    id="Path_171"
                    d="M350.58,161.88c-.28,2.16-.48,4.7-.62,7.71a42.81,42.81,0,0,0,11.38-5.35Z"
                    fill="#5e5e5e"
                />
                <path
                    id="Path_172"
                    d="M350.58,161.88c-.28,2.16-.48,4.7-.62,7.71a42.81,42.81,0,0,0,11.38-5.35Z"
                    fill="#5e5e5e"
                    opacity="0.6"
                />
                <path
                    id="Path_173"
                    d="M368.06,163c-5.47,4.84-26.36,4.36-26.36,4.36l-.7-20.19a155.462,155.462,0,0,0,21.29,1.54C374,148.47,374.06,157.68,368.06,163Z"
                    fill="#407bff"
                />
                <path
                    id="Path_174"
                    d="M368.06,163c-5.47,4.84-26.36,4.36-26.36,4.36l-.7-20.19a155.462,155.462,0,0,0,21.29,1.54C374,148.47,374.06,157.68,368.06,163Z"
                    fill="#707171"
                    stroke="#707171"
                    strokeMiterlimit="10"
                    strokeWidth="1"
                />
                <path
                    id="Path_175"
                    d="M298.17,124.79l-5.25-6.12-5.6,6.2s3.45,5.8,8.94,4.89Z"
                    fill="#e3e2e3"
                />
                <path
                    id="Path_176"
                    d="M285.52,111.53l-2.02,7.99,3.82,5.35,5.6-6.2Z"
                    fill="#e3e2e3"
                />
                <path
                    id="Path_177"
                    d="M364.71,393.53l.39,9.12h7.65l-.4-9.12Z"
                    fill="#5e5e5e"
                    opacity="0.15"
                />
                <path
                    id="Path_178"
                    d="M415.15,393.53H407.5l1.99,9.12h7.64Z"
                    fill="#5e5e5e"
                    opacity="0.15"
                />
                <path
                    id="Path_179"
                    d="M369.31,113.07c-6.85-3.17-9-17,10.7-13.57,0,0-3,3.29,3.06,5.37S384.05,119.88,369.31,113.07Z"
                    fill="#707171"
                    stroke="#707171"
                    strokeMiterlimit="10"
                    strokeWidth="1"
                />
                <path
                    id="Path_180"
                    d="M384.2,123.47c-2,7.33-2.74,11.72-7.26,14.76-6.8,4.58-15.26-.68-15.53-8.44-.24-7,3-17.79,10.87-19.27a10.35,10.35,0,0,1,11.92,12.95Z"
                    fill="#e3e2e3"
                />
                <path
                    id="Path_181"
                    d="M379.74,103.22c4.66,2.61,11,4.17,10.35,7.86-.77,4.53,3.83,1.7,2.76,8.44-.7,4.39-8.82,17.73-14.53,17.08-8.59-1-7.5-14.1-7.53-21C370.78,111.55,370.59,98.1,379.74,103.22Z"
                    fill="#707171"
                    stroke="#707171"
                    strokeMiterlimit="10"
                    strokeWidth="1"
                />
                <path
                    id="Path_182"
                    d="M369.76,109.06c-2.08.49-4.83,12.48,3.22,13.58C382.26,123.9,369.76,109.06,369.76,109.06Z"
                    fill="#707171"
                    stroke="#707171"
                    strokeMiterlimit="10"
                    strokeWidth="1"
                />
                <path
                    id="Path_183"
                    d="M370,113.32c-2.54.22-8.29-2.85-7.61-7.46C362.36,105.86,366,111.26,370,113.32Z"
                    fill="#707171"
                    stroke="#707171"
                    strokeMiterlimit="10"
                    strokeWidth="1"
                />
                <path
                    id="Path_184"
                    d="M373.75,123.6a8.48,8.48,0,0,1-3.77,4c-2.19,1.14-3.43-.53-2.93-2.73.45-2,2.13-5,4.39-5.2S374.67,121.52,373.75,123.6Z"
                    fill="#e3e2e3"
                />
                <path
                    id="Path_185"
                    d="M385.19,115.13a10.2,10.2,0,0,0,6-7.62S394.64,117.1,385.19,115.13Z"
                    fill="#707171"
                    stroke="#707171"
                    strokeMiterlimit="10"
                    strokeWidth="1"
                />
                <path
                    id="Path_186"
                    d="M393.88,115a5.28,5.28,0,0,0-5-.49S392.1,110.39,393.88,115Z"
                    fill="#707171"
                    stroke="#707171"
                    strokeMiterlimit="10"
                    strokeWidth="1"
                />
                <path
                    id="Path_187"
                    d="M367.64,216.88s8.67,58.39,13.43,80.26c5.22,23.95,24.85,103.75,24.85,103.75h14S407,323,404,299.31c-3.22-25.74-10-82.43-10-82.43Z"
                    fill="#5e5e5e"
                />
                <path
                    id="Path_188"
                    d="M403.69,395.73c-.06,0,1.44,5.38,1.44,5.38h15.75l-.29-4.8Z"
                    fill="#407bff"
                />
                <path
                    id="Path_189"
                    d="M403.69,395.73c-.06,0,1.44,5.38,1.44,5.38h15.75l-.29-4.8Z"
                    fill="#707171"
                    stroke="#707171"
                    strokeMiterlimit="10"
                    strokeWidth="1"
                />
                <path
                    id="Path_190"
                    d="M373.12,237.68c10,2,9,39.35,7.76,58.58-2.89-13.59-7.12-39.75-10-58.31a3.4,3.4,0,0,1,2.24-.27Z"
                    fill="#407bff"
                />
                <path
                    id="Path_191"
                    d="M373.12,237.68c10,2,9,39.35,7.76,58.58-2.89-13.59-7.12-39.75-10-58.31a3.4,3.4,0,0,1,2.24-.27Z"
                    fill="#707171"
                    stroke="#707171"
                    strokeMiterlimit="10"
                    strokeWidth="1"
                />
                <path
                    id="Path_192"
                    d="M353.15,216.88s-1.06,57.12-.23,80.42c.87,24.24,9.33,103.59,9.33,103.59H375.2s.47-77.94,1.05-101.74c.63-25.95,3.76-82.27,3.76-82.27Z"
                    fill="#5e5e5e"
                />
                <path
                    id="Path_193"
                    d="M365,391.59a.13.13,0,0,1-.13-.12c-2.28-22-7.66-75.1-8.34-94.16-.69-19.46-.06-63.12.15-76a.13.13,0,0,1,.13-.13h0a.13.13,0,0,1,.13.13c-.21,12.92-.84,56.57-.15,76,.68,19.05,6.06,72.19,8.34,94.15a.13.13,0,0,1-.12.14Z"
                    fill="#407bff"
                />
                <path
                    id="Path_194"
                    d="M365,391.59a.13.13,0,0,1-.13-.12c-2.28-22-7.66-75.1-8.34-94.16-.69-19.46-.06-63.12.15-76a.13.13,0,0,1,.13-.13h0a.13.13,0,0,1,.13.13c-.21,12.92-.84,56.57-.15,76,.68,19.05,6.06,72.19,8.34,94.15a.13.13,0,0,1-.12.14Z"
                    fill="#707171"
                    stroke="#707171"
                    strokeMiterlimit="10"
                    strokeWidth="1"
                />
                <path
                    id="Path_195"
                    d="M359.76,395.73c-.06,0,.73,5.38.73,5.38h15.75l.42-4.8Z"
                    fill="#407bff"
                />
                <path
                    id="Path_196"
                    d="M359.76,395.73c-.06,0,.73,5.38.73,5.38h15.75l.42-4.8Z"
                    fill="#707171"
                    stroke="#707171"
                    strokeMiterlimit="10"
                    strokeWidth="1"
                />
                <path
                    id="Path_197"
                    d="M426.45,267.49h0A6.49,6.49,0,0,0,420,274V391.1l12.94-14.5V274a6.49,6.49,0,0,0-6.49-6.51Z"
                    fill="#e3e2e3"
                />
                <ellipse
                    id="Ellipse_16"
                    cx="223.74"
                    cy="226.68"
                    rx="223.74"
                    ry="226.68"
                    transform="translate(34.58 7.02)"
                    fill="none"
                    stroke="#5e5e5e"
                    strokeMiterlimit="10"
                    strokeWidth="3"
                />
                <circle
                    id="Ellipse_17"
                    cx="9.26"
                    cy="9.26"
                    r="9.26"
                    transform="translate(64.88 174.34)"
                    fill="#fff"
                    stroke="#e2e2e2"
                    strokeMiterlimit="10"
                    strokeWidth="2"
                />
                <path
                    id="Path_198"
                    d="M175.43,405.54h-6.61v-6.6a.82.82,0,0,0-.82-.82h0a.84.84,0,0,0-.84.82v6.6h-6.59a.84.84,0,0,0-.83.84.83.83,0,0,0,.83.82h6.59v6.61a.83.83,0,0,0,.84.82.82.82,0,0,0,.82-.82h0V407.2h6.61a.83.83,0,0,0,.83-.82.84.84,0,0,0-.83-.84Z"
                    fill="#e2e2e2"
                    stroke="#e2e2e2"
                    strokeMiterlimit="10"
                    strokeWidth="0.75"
                />
                <path
                    id="Path_199"
                    d="M83.78,286.09H77.17V279.5a.81.81,0,0,0-.82-.82h0a.84.84,0,0,0-.84.82v6.59H68.92a.84.84,0,0,0,0,1.67h6.59v6.6a.84.84,0,0,0,.84.82.81.81,0,0,0,.82-.82h0v-6.6h6.61a.83.83,0,0,0,.83-.83.84.84,0,0,0-.83-.84Z"
                    fill="#e2e2e2"
                    stroke="#e2e2e2"
                    strokeMiterlimit="10"
                    strokeWidth="0.75"
                />
                <path
                    id="Path_200"
                    d="M348.44,60.86h-6.6v-6.6a.83.83,0,0,0-.83-.82h0a.83.83,0,0,0-.83.82v6.6h-6.59a.84.84,0,0,0-.83.84.82.82,0,0,0,.83.82h6.59v6.61a.83.83,0,1,0,1.66,0h0V62.52h6.6a.82.82,0,0,0,.83-.82.84.84,0,0,0-.83-.84Z"
                    fill="#e2e2e2"
                    stroke="#e2e2e2"
                    strokeMiterlimit="10"
                    strokeWidth="0.75"
                />
            </g>
        </SVGIcon>
    );
}
