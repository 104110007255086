import React from 'react';

import { SVGIcon } from '../../UIKit/index';

export default function DCEmailMic(props) {
    return (
        <SVGIcon {...props} viewBox="0 0 512 501">
            <g transform="translate(-3 -3.322)">
                <path
                    fill="#005EFF"
                    d="M325.01,360.68L499.3,499.81c4.25-3.25,6.75-8.28,6.79-13.63V226.05   c-0.02-2.82-0.72-5.6-2.05-8.08L325.01,360.68z"
                />
                <path
                    fill="#005EFF"
                    d="M6.64,217.96c-1.33,2.49-2.03,5.26-2.05,8.08v260.09c0.02,5.36,2.53,10.42,6.79,13.67   L185.7,361.05L6.64,217.96z"
                />
                <path
                    fill="#D9EAFF"
                    d="M504.01,218l-178.4,142.78l-59.46-47.58c-6.33-5.02-15.28-5.02-21.61,0l-59.41,47.58L6.66,218   c0.96-1.93,2.28-3.66,3.89-5.1L244.01,11.7c6.5-5.64,16.16-5.64,22.66,0l233.45,201.2C501.72,214.34,503.05,216.07,504.01,218z"
                />
                <path
                    fill="#2B88F8"
                    d="M266.14,313.16c-6.33-5.02-15.28-5.02-21.61,0l-59.4,47.55L11.37,499.75   c2.99,2.36,6.69,3.65,10.5,3.67h466.88c3.81-0.02,7.51-1.31,10.5-3.67l-173.63-139L266.14,313.16z"
                />
                <path
                    fill="#91BCF1"
                    d="M194.32,114.18c-23.24,5.54-37.58,28.86-32.05,52.1c5.54,23.24,28.86,37.58,52.1,32.05   c0.79-0.19,1.57-0.4,2.34-0.63l58.45-15.68L252.8,98.51L194.32,114.18z"
                />
                <path
                    fill="#FFFFFF"
                    d="M275.17,182.03l147.64,14.32l-49.54-183.7L315.6,53.67l0,0l0.26,0.18l-0.26-0.18l0,0   l-62.8,44.88L275.17,182.03z"
                />
                <path
                    fill="#6B8AAB"
                    d="M447.29,189.63c4.61-1.24,7.35-5.98,6.11-10.59l-2.22-8.23L408.64,11.99   c-1.24-4.6-5.97-7.34-10.58-6.11l-25.07,6.72l49.23,183.74L447.29,189.63z"
                />
                <path
                    fill="#2B88F8"
                    d="M272.48,272.27c9.21-2.48,14.67-11.96,12.2-21.17l-17.91-66.82l-33.41,8.96l17.97,66.79   C253.79,269.25,263.26,274.73,272.48,272.27z"
                />
                <path
                    fill="#0372F8"
                    d="M402.33,477.49H39.26l-27.89,22.27c2.99,2.36,6.69,3.65,10.5,3.67h466.88   c3.81-0.02,7.51-1.31,10.5-3.67l-50.44-40.37c-12.71,11.65-29.33,18.11-46.58,18.1H402.33z"
                />
                <path
                    fill="#0372F8"
                    d="M266.15,313.16c-6.33-5.02-15.28-5.02-21.61,0l-59.4,47.55L39.22,477.49h363.11   c17.25,0.01,33.87-6.45,46.58-18.11l-123.26-98.62L266.15,313.16z"
                />
                <g transform="translate(42.017 3.322)">
                    <path
                        fill="#7BB7FF"
                        d="M414.05,85.47c1.01,3.78,4.43,6.42,8.35,6.42c0.75,0,1.5-0.1,2.22-0.3l33.41-8.95    c4.55-1.45,7.07-6.31,5.63-10.86c-1.35-4.25-5.71-6.78-10.06-5.84l-33.41,8.96c-4.6,1.24-7.34,5.97-6.11,10.58L414.05,85.47z"
                    />
                    <path
                        fill="#7BB7FF"
                        d="M403.91,43.13c2.3,0,4.5-0.91,6.12-2.53l24.4-24.4c3.62-3.12,4.03-8.58,0.91-12.2    c-3.12-3.62-8.58-4.03-12.2-0.91c-0.33,0.28-0.63,0.59-0.91,0.91l-24.44,24.36c-3.37,3.38-3.37,8.86,0.01,12.23    c1.62,1.62,3.81,2.52,6.1,2.53H403.91z"
                    />
                    <path
                        fill="#7BB7FF"
                        d="M422.41,132.47c-1.23,4.61,1.5,9.35,6.11,10.59l33.41,8.95c0.72,0.2,1.47,0.3,2.22,0.3    c4.78-0.02,8.63-3.91,8.61-8.69c-0.02-3.89-2.63-7.3-6.39-8.31l-33.41-8.96c-4.61-1.23-9.35,1.5-10.59,6.11L422.41,132.47z"
                    />
                </g>
            </g>
        </SVGIcon>
    );
}
