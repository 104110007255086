import React from 'react';

import PhoneInput from 'react-phone-number-input';

import 'react-phone-number-input/style.css';

import { Div } from '../../UIKit/index';

import './style.scss';
import { sanitizePhoneNumber } from 'Utils';

export default function PhoneNumberView(props) {
    const { value, placeholder } = props;
    return (
        <Div className={'PhoneNumberView '}>
            <PhoneInput
                value={sanitizePhoneNumber(value)}
                disabled={true}
                readOnly={true}
                placeholder={placeholder}
                flagUrl={'https://assets.mydashmetrics.com/flag/{XX}.png'}
            />
        </Div>
    );
}
