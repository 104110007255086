import React from 'react';

import { SVGIcon } from '../../UIKit/index';

export default function DCParagraph(props) {
    return (
        <SVGIcon {...props} viewBox="0 0 23 28">
            <g transform="translate(-0.018 3.104)">
                <g
                    transform="translate(0.018 -3.104)"
                    fill="#fff"
                    stroke="#7b89a4"
                    strokeWidth="1.4"
                >
                    <rect width="23" height="28" rx="3" stroke="none" />
                    <rect x="0.7" y="0.7" width="21.6" height="26.6" rx="2.3" fill="none" />
                </g>
                <g transform="translate(2.305 -2.843)">
                    <g transform="translate(2.714 6.739)">
                        <g>
                            <path
                                d="M70.126,150H60.692a.674.674,0,0,0,0,1.348h9.434a.674.674,0,1,0,0-1.348Z"
                                transform="translate(-60.018 -150)"
                                fill="#7b89a4"
                            />
                        </g>
                    </g>
                    <g transform="translate(2.714 15.739)">
                        <g>
                            <path
                                d="M70.126,150H60.692a.674.674,0,0,0,0,1.348h9.434a.674.674,0,1,0,0-1.348Z"
                                transform="translate(-60.018 -150)"
                                fill="#7b89a4"
                            />
                        </g>
                    </g>
                    <g transform="translate(2.714 9.434)">
                        <g>
                            <path
                                d="M71.337,210H61.047c-.568,0-1.029.3-1.029.674s.461.674,1.029.674h10.29c.568,0,1.029-.3,1.029-.674S71.905,210,71.337,210Z"
                                transform="translate(-60.018 -210)"
                                fill="#7b89a4"
                            />
                        </g>
                    </g>
                    <g transform="translate(2.714 18.434)">
                        <g>
                            <path
                                d="M71.337,210H61.047c-.568,0-1.029.3-1.029.674s.461.674,1.029.674h10.29c.568,0,1.029-.3,1.029-.674S71.905,210,71.337,210Z"
                                transform="translate(-60.018 -210)"
                                fill="#7b89a4"
                            />
                        </g>
                    </g>
                    <g transform="translate(2.714 12.13)">
                        <g>
                            <path
                                d="M70.12,270H60.936c-.507,0-.918.3-.918.674s.411.674.918.674H70.12c.507,0,.918-.3.918-.674S70.627,270,70.12,270Z"
                                transform="translate(-60.018 -270)"
                                fill="#7b89a4"
                            />
                        </g>
                    </g>
                </g>
            </g>
        </SVGIcon>
    );
}
