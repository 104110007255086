import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Div } from '../index';

export default function Textarea(props) {
    const {
        rows,
        reference,
        value,
        name,
        placeholder,
        variant,
        autoFocus,
        error,
        errorMessage,
        id,
        onChange,
        disabled,
        readOnly,
        onBlur,
        onFocus,
    } = props;
    const [errorState, setErrorState] = useState(error || false);
    const [textareaRows, setTextareaRow] = useState(rows || 4);

    useEffect(() => {
        setTextareaRow(rows || 4);
    }, [rows]);

    useEffect(() => {
        setErrorState(error);
    }, [error]);

    return (
        <Div className={'DCTextareaWrapper'}>
            <textarea
                className={
                    'DCTextarea ' +
                    (variant ? variant : '') +
                    ' ' +
                    (errorState === true ? 'textareaError' : '') +
                    ' '
                }
                {...props}
                rows={textareaRows}
                name={name}
                id={id}
                ref={reference}
                placeholder={placeholder}
                autoFocus={autoFocus}
                onChange={onChange}
                autoComplete="off"
                disabled={disabled}
                value={value}
                readOnly={readOnly}
                onBlur={onBlur}
                onFocus={onFocus}
            />
            {errorState === true ? (
                <Div className={'DCTextareaError'}>{errorMessage || 'Please enter valid text'}</Div>
            ) : null}
        </Div>
    );
}

Textarea.propTypes = {
    rows: PropTypes.number,
    reference: PropTypes.object,
    value: PropTypes.string,
    name: PropTypes.string,
    placeholder: PropTypes.string,
    variant: PropTypes.string,
    autoFocus: PropTypes.bool,
    error: PropTypes.bool,
    errorMessage: PropTypes.string,
    id: PropTypes.string,
    onChange: PropTypes.func,
    disabled: PropTypes.bool,
    readOnly: PropTypes.bool,
    onBlur: PropTypes.func,
    onFocus: PropTypes.func,
};
