import React from 'react';
import { Page, Text, View, Document, Font } from '@react-pdf/renderer';
import BusinessDetailsDownload from '../downloadComponents/businessDetailsDownload';
import OverallScoreDownload from '../downloadComponents/overallScoreDownload';
import ListingDetailsDownload from '../downloadComponents/listingDetailsDownload';
import TotalListingDownload from '../downloadComponents/totalListingDownload';
import ReviewDownload from '../downloadComponents/reviewDownload';
import SocialDownload from '../downloadComponents/socialDownload';
import WebsiteMobileDownload from '../downloadComponents/websiteMobileDownload';
import SeoDownload from '../downloadComponents/seoDownload';
import GoogleAdsDownload from '../downloadComponents/googleAdsDownload';
import { sanitizePhoneNumber } from 'Utils';
import { formatPhoneNumber } from '../downloadComponents/downloadUtils/phoneNumberIntlCode';
import { instareportFontFamily } from './constant';
// import FacebookAdsDownload from '../downloadComponents/facebookAdsDownload';
import { styles } from '../downloadComponents/style';

Font.register({ ...instareportFontFamily });

const FrontPage = ({ details, user }) => {
    const addressArray = [
        details?.address?.street,
        details?.address?.city,
        details?.address?.state_province,
        details?.address?.country,
    ];
    const addressFilteredArray = addressArray.filter(x => typeof x === 'string' && x.length > 0);
    const addressString = addressFilteredArray.join(', ');
    return (
        <Page>
            <View style={styles.FrontSection}>
                <View style={styles.Title}>
                    <Text style={styles.MP3}>{user?.businessName || ''}</Text>
                    <Text style={styles.MP3}>Online Business Report</Text>
                </View>
                <View style={styles.TSection}>
                    <Text style={styles.MP3}>To be presented to</Text>
                    <Text style={styles.FS20}>{details?.name}</Text>
                    <Text style={styles.MP3}>{addressString || ''}</Text>
                </View>
                <View style={styles.TSection}>
                    <Text style={styles.MP3}>Produced by</Text>
                    <Text style={styles.MP3}>
                        {user?.name || ''} {user?.businessName ? `at ${user?.businessName}` : ''}
                    </Text>
                    <Text style={styles.MP3}>{user?.email || ''}</Text>
                    <Text style={styles.MP3}>
                        {formatPhoneNumber(sanitizePhoneNumber(user?.phone)) || ''}
                    </Text>
                </View>
            </View>
        </Page>
    );
};

const DownloadComponent = ({ content }) => {
    const user = content?.userDetails;
    const details = content.businessDetails;
    const listings = content?.listings?.data.filter(item => item.status === 'LISTING_FOUND');
    const score = content?.overallScore;
    const listingData = content?.listings;
    const reviews = content?.reviews;
    const facebook = content?.social?.facebook;
    const twitter = content?.social?.twitter;
    const seo = content?.seo;
    const keywords = content.seo?.organicKeywordRanking;
    const competitors = content.seo?.organicCompetitorAnalysis;
    const backlinks = content.seo?.backlinks;
    const webData = content?.website;
    const googleAds = content?.googleAds;
    const googleOverview = content.googleAds?.overview;
    const paidCompetitor = content.googleAds?.competitorAnalysis;
    // const facebookAds = content.facebookAds;
    return (
        <Document>
            <FrontPage user={user} details={details} />
            <BusinessDetailsDownload details={details} />
            <OverallScoreDownload score={score} />
            <ListingDetailsDownload
                listingData={listingData}
                details={details}
                listings={listings}
                user={user}
                score={score}
            />
            {listings?.length ? (
                <TotalListingDownload listings={listings} user={user} details={details} />
            ) : null}
            <ReviewDownload reviews={reviews} details={details} user={user} score={score} />
            <SocialDownload
                facebook={facebook}
                twitter={twitter}
                details={details}
                user={user}
                score={score}
            />
            {Object.keys(webData || {}).length ? (
                <WebsiteMobileDownload
                    webData={webData}
                    details={details}
                    user={user}
                    score={score}
                />
            ) : null}
            {Object.keys(seo || {}).length ? (
                <SeoDownload
                    backlinks={backlinks}
                    keywords={keywords}
                    competitors={competitors}
                    details={details}
                    user={user}
                    score={score}
                />
            ) : null}
            {Object.keys(googleAds || {}).length ? (
                <GoogleAdsDownload
                    googleOverview={googleOverview}
                    paidCompetitor={paidCompetitor}
                    user={user}
                    score={score}
                />
            ) : null}
            {/* <FacebookAdsDownload facebookAds={facebookAds} /> */}
        </Document>
    );
};

export default DownloadComponent;
