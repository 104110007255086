import React from 'react';

import { SVGIcon } from '../../UIKit/index';

export default function DCVimeo(props) {
    return (
        <SVGIcon {...props} viewBox="0 0 512.011 512.011">
            <path d="M293.792,152.808c58.304-35.008,89.728,14.336,59.84,70.112c-29.952,56-57.44,92.512-71.68,92.512    c-13.984,0-25.056-37.568-41.44-102.784c-16.8-67.552-16.8-189.344-87.072-175.52C87.008,50.248,0,154.248,0,154.248    l20.672,27.232c0,0,42.88-33.824,57.152-16.992c14.464,16.992,69.216,221.024,87.328,258.752    c15.808,33.056,59.68,76.672,107.616,45.568c48.384-31.296,208.096-167.68,236.704-329.056    C538.016-21.336,316.96,12.392,293.792,152.808z" />
        </SVGIcon>
    );
}
