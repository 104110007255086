import { CallAPI } from 'Utils/apiCall';
import { getProviderURIByType } from 'Utils/helper';

const api = new CallAPI();
import config from 'config';

const mainUrl = `${config.REACT_APP_API_BASE_URL}/${process.env.REACT_APP_API_VERSION}`;
const contactsUrl = `${mainUrl}/${process.env.REACT_APP_API_CRM}/${process.env.REACT_APP_API_CONTACTS}`;
const filterUrl = `${mainUrl}/filters`;
const fieldsUrl = `${mainUrl}/${process.env.REACT_APP_API_CRM}/${process.env.REACT_APP_API_FIELDS}`;
/**
 *
 * @param {String} type - people/businesses
 * @param {*} params - object of query string params
 * @returns
 */
export const getContacts = async (type, params) => {
    const url = `${contactsUrl}/${
        type === 'people'
            ? process.env.REACT_APP_CONTACTS_PEOPLE
            : process.env.REACT_APP_CONTACTS_BUSINESSES
    }`;
    return api.request('GET', url, null, null, null, params);
};

export const deleteContacts = async (type, body, query) => {
    const url = `${contactsUrl}/${
        type === 'people'
            ? process.env.REACT_APP_CONTACTS_PEOPLE
            : process.env.REACT_APP_CONTACTS_BUSINESSES
    }/${process.env.REACT_APP_API_DELETE}`;

    return api.request('POST', url, body, null, null, query);
};

// filters API
export const initializeFilters = async type => {
    let url = `${filterUrl}/${
        type === 'people'
            ? process.env.REACT_APP_CONTACTS_PEOPLE
            : process.env.REACT_APP_CONTACTS_BUSINESSES
    }`;
    return api.request('GET', url);
};

export const onEditAPI = async (filterId, body) => {
    let url = `${filterUrl}/${filterId}`;

    return api.request('PUT', url, body);
};

export const onDeleteAPI = async filterId => {
    let url = `${filterUrl}/${filterId}`;

    return api.request('DELETE', url);
};

export const updateAPI = async (body, operationType, filterId, type) => {
    let url, method;
    if (operationType === 'create') {
        url = `${filterUrl}/${
            type === 'people'
                ? process.env.REACT_APP_CONTACTS_PEOPLE
                : process.env.REACT_APP_CONTACTS_BUSINESSES
        }`;
        method = 'POST';
    } else {
        method = 'PUT';
        url = `${filterUrl}/${filterId}`;
    }
    return api.request(method, url, body);
};
// Filter end

export const previewAPI = async (source, contactId) => {
    let url = `${contactsUrl}/${source}/${contactId}`;
    return api.request('GET', url, null, null, null, {
        [process.env.REACT_APP_API_EXPAND]: true,
    });
};

export const duplicateEmailAPI = async (type, email) => {
    let url = `${contactsUrl}/${
        type === 'people'
            ? process.env.REACT_APP_CONTACTS_PEOPLE
            : process.env.REACT_APP_CONTACTS_BUSINESSES
    }?${process.env.REACT_APP_CONTACTS_DUPLICATE_EMAIL}=${email}`;

    return api.request('GET', url);
};
export const duplicatePhoneAPI = async (type, email) => {
    let url = `${contactsUrl}/${
        type === 'people'
            ? process.env.REACT_APP_CONTACTS_PEOPLE
            : process.env.REACT_APP_CONTACTS_BUSINESSES
    }?${process.env.REACT_APP_CONTACTS_DUPLICATE_PHONE}=${email}`;

    return api.request('GET', url);
};

export const createPropertyAPI = async (type, body) => {
    let url = `${fieldsUrl}/${type}`;

    return api.request('POST', url, body);
};

export const getSingleContact = (type, contactId) => {
    const url = `${contactsUrl}/${type}/${contactId}?expand=true`;
    return api.request('GET', url);
};

export const deleteSingleContact = async (type, contactId) => {
    const url = `${contactsUrl}/${type}/${contactId}`;
    return api.request('DELETE', url);
};

export const updateContactDetailAPI = async (type, contactId, body) => {
    let url = `${contactsUrl}/${type}/${contactId}`;

    return api.request('PUT', url, body);
};

export const associateOptionsAPI = async (params, type) => {
    let url = `${contactsUrl}/${type}`;
    return api.request('GET', url, null, null, null, params);
};

export const updateAssociateAPI = async (url, body) => {
    return api.request('PUT', url, body);
};

export const uploadAttachementsAPI = async (contactId, body) => {
    let url = `${contactsUrl}/${contactId}/${process.env.REACT_APP_API_ATTACHMENTS}`;

    return api.request('PUT', url, body);
};

export const deleteAttachmentAPI = async (contactId, itemToDelete) => {
    let url = `${contactsUrl}/${contactId}/${process.env.REACT_APP_API_ATTACHMENTS}/${itemToDelete}`;

    return api.request('DELETE', url);
};

export const getAssociatedInstaSites = async query => {
    const url = `${config.REACT_APP_API_BASE_URL}/${process.env.REACT_APP_API_VERSION}/${process.env.REACT_APP_API_INSTASITES}/${process.env.REACT_APP_API_ASSOCIATED}`;
    return api.request('GET', url, null, null, null, query);
};

export const getTags = async () => {
    const url = `${config.REACT_APP_API_BASE_URL}/${process.env.REACT_APP_API_VERSION}/${process.env.REACT_APP_API_CRM}/${process.env.REACT_APP_API_TAGS}`;
    return api.request('GET', url);
};

export const getSingleTag = async tagId => {
    const url = `${config.REACT_APP_API_BASE_URL}/${process.env.REACT_APP_API_VERSION}/${process.env.REACT_APP_API_CRM}/${process.env.REACT_APP_API_TAGS}/${tagId}`;
    return api.request('GET', url);
};

export const createTags = async body => {
    const url = `${config.REACT_APP_API_BASE_URL}/${process.env.REACT_APP_API_VERSION}/${process.env.REACT_APP_API_CRM}/${process.env.REACT_APP_API_TAGS}`;
    return api.request('POST', url, body);
};

export const deleteTags = async id => {
    const url = `${config.REACT_APP_API_BASE_URL}/${process.env.REACT_APP_API_VERSION}/${process.env.REACT_APP_API_CRM}/${process.env.REACT_APP_API_TAGS}/${id}`;
    return api.request('DELETE', url);
};

export const deleteMultipleTags = async ids => {
    const url = `${config.REACT_APP_API_BASE_URL}/${process.env.REACT_APP_API_VERSION}/${process.env.REACT_APP_API_CRM}/${process.env.REACT_APP_API_TAGS}/${process.env.REACT_APP_API_DELETE}`;
    return api.request('POST', url, { tags: ids });
};

export const editTags = async (id, body) => {
    const url = `${config.REACT_APP_API_BASE_URL}/${process.env.REACT_APP_API_VERSION}/${process.env.REACT_APP_API_CRM}/${process.env.REACT_APP_API_TAGS}/${id}`;
    return api.request('PUT', url, body);
};

export const getAssociatedInstaReports = async query => {
    const url = `${config.REACT_APP_API_BASE_URL}/${process.env.REACT_APP_API_VERSION}/${process.env.REACT_APP_API_INSTAREPORTS}/${process.env.REACT_APP_API_ASSOCIATED}`;
    return api.request('GET', url, null, null, null, query);
};

export const bulkAction = (body, mode) => {
    let url;
    if (mode === 'user') url = `${contactsUrl}/associate`;
    else url = `${contactsUrl}/associate/tag`;
    return api.request('PUT', url, body);
};

export const contactExportApi = (type, params) => {
    const url = `${contactsUrl}/${type}/export`;
    return api.request('GET', url, null, null, null, params);
};

export const createContact = (type, body) => {
    const url = `${contactsUrl}/${type}`;
    return api.request('POST', url, body);
};

// Contact import

const mainURL = `${config.REACT_APP_API_BASE_URL}/${process.env.REACT_APP_API_VERSION}`;
const fieldsURL = `${mainURL}/${process.env.REACT_APP_API_CRM}/${process.env.REACT_APP_API_FIELDS}/`;
const configURL = `${mainURL}/${process.env.REACT_APP_API_USERS}/${process.env.REACT_APP_API_ME}/${process.env.REACT_APP_API_CONFIG}`;

export const getConfigData = config => {
    return api.request('GET', configURL, null, null, null, {
        type: config,
    });
};

export const getFieldsData = field => {
    return api.request('GET', fieldsURL + field);
};
export const getLastImportStatus = () => {
    const url = `${mainURL}/${process.env.REACT_APP_API_CRM}/import/importreport`;
    return api.request('GET', url);
};
export const getAuthWindowUrl = (service, type) => {
    return api.request('GET', getProviderURIByType(service, type));
};
export const callContactImport = body => {
    return api.request('POST', `${mainURL}/crm/import`, body);
};
export const getContactImportLogs = ({ pageParam }) => {
    return api.request('GET', `${mainURL}/crm/import/report?type=contacts&page=${pageParam}`);
};
export const activeCampaignAuth = body => {
    return api.request('POST', `${mainURL}/e/activecampaign/auth`, body);
};
export const callCreateColumn = (type, body) => {
    let url = `${fieldsURL}`;
    switch (type) {
        case 'contacts':
            url += 'people';
            break;
        case 'deals':
            url += 'deals';

            break;
        case 'companies':
            url += 'businesses';

            break;
        case 'notes':
            url += 'businesses';

            break;
        default:
            break;
    }
    return api.request('POST', url, body);
};
