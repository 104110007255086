import React, { Fragment, useCallback, useEffect, useRef, useState } from 'react';

import dayjs from 'dayjs';
import { getContacts, getSingleTag, getTags, previewAPI } from 'apis/contacts/index.api';
import DCCrossCloseIcon from '../../Assets/icons/DCCrossClose';
import DCUserIcon from '../../Assets/icons/DCUser';
import DateTimePicker from '../../Components/DateTimePicker';
import { OnlyDatePickerCalendar, TimePicker } from '../../Components/index';
import IndustryList from '../../Components/IndustryList';
import PhoneNumber from '../../Modules/PhoneNumber/phoneNumber';
import { enGB } from 'date-fns/locale';

import {
    Button,
    Div,
    DropdownOptions,
    IconButton,
    Input,
    Label,
    LabelControl,
    Popover,
    RadioButton,
    Skeleton,
    ToolTip,
    UserInfo,
} from '../../UIKit/index';
import { sanitizePhoneNumber } from 'Utils';
import { formateDate, getUserTimeZone, timeInUserTimeZone } from 'Utils/dateUtils';
import DCCompare from 'Assets/icons/DCCompare';
import DCArrowBack from 'Assets/icons/DCArrowBack';
import AutoComplete from 'Components/AutoComplete';
import useToast from 'Modules/Toasts';
import { useInfiniteQuery } from 'react-query';
import log from 'Utils/logger';
import generateImage from 'Utils/generateImage';
import useErrorDialog from 'Hooks/ErrorDialog/useErrorDialog';

function FilterItem({
    field,
    onRemove,
    onUpdate,
    setHasError,
    closeOptions,
    setCloseOptions,
    fields,
}) {
    const { operator, value, name, type, options } = field;
    const { showToast } = useToast();
    const showErrorDialog = useErrorDialog();
    const [filterItemDropdownState, setFilterItemDropdownState] = useState(false);

    const filterItemRef = useRef(null);
    const [fieldOptions, setFieldOptions] = useState([]);
    const autocompleteRef = useRef(null);
    const [tags, setTags] = useState([]);
    const [contacts, setContacts] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [loading, setLoading] = useState(false);

    const handleFilterItemDropdownToggle = () => {
        setFilterItemDropdownState(prevOpen => !prevOpen);
    };

    const handleFilterItemDropdownClose = () => {
        setFilterItemDropdownState(false);
    };

    const handleBack = useCallback(() => {
        onUpdate({
            ...field,
            value: '',
        });
        handleFilterItemDropdownClose();
        autocompleteRef.current?.click();
    }, [field, onUpdate]);

    useEffect(() => {
        if (closeOptions === true) {
            handleFilterItemDropdownClose();
            setCloseOptions(false);
        }
    }, [closeOptions, setCloseOptions]);

    const buttonRef = useRef();
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const [dropdownWidth, setDropdownWidth] = useState(0);
    const handleDropdownToggle = () => {
        setDropdownOpen(prevOpen => !prevOpen);
        setDropdownWidth(buttonRef.current.offsetWidth);
    };

    const handleDropdownClose = () => {
        setDropdownOpen(false);
    };

    const updateField = useCallback(
        _field => {
            setHasError(prev => {
                return { ...prev, [_field.id]: false };
            });
            onUpdate(_field);
        },
        [onUpdate, setHasError],
    );

    const onValueChange = useCallback(
        (event, selectedItem, isContact) => {
            let _value = '';
            if (type === 'time') {
                _value = event;
            } else {
                _value = selectedItem
                    ? selectedItem
                    : event && event.target
                    ? event.target.value
                    : event;
            }
            if (type === 'datetime') {
                if (['after', 'on', 'before'].includes(operator)) {
                    _value = timeInUserTimeZone(_value);
                }
            }
            if (type === 'date') {
                if (['after', 'on', 'before'].includes(operator)) {
                    _value = timeInUserTimeZone(dayjs(_value).format('YYYY/MM/DD'));
                }
            }
            let newField = { ...field, value: _value };
            if (isContact) newField = { ...newField, contactData: _value };
            updateField(newField);
            setDropdownOpen(false);
        },
        [field, operator, type, updateField],
    );

    const getTagOptions = useCallback(() => {
        getTags()
            .then(res => {
                if (res?.data) setTags(res.data);

                return res;
            })
            .catch(err => {
                showToast({
                    type: 'error',
                    message: err.additional_info || 'Something went wrong',
                    duration: 2500,
                });
            });
    }, [showToast]);

    const {
        data: contactPages,
        fetchNextPage: fetchContactNextPage,
        hasNextPage: hasContactNextPage,
        isLoading: isContactLoading,
        isFetchingNextPage: isFetchingContactNextPage,
        refetch: refetchContacts,
        isRefetching: isRefetchingContacts,
    } = useInfiniteQuery(
        ['contactFilter', searchTerm],
        ({ pageParam = 1 }) => getContacts(['people', 'person'].includes(name) ? 'people' : 'businesses', {
                page: pageParam,
                limit: 25,
                search: searchTerm?.length ? searchTerm : null,
            }),
        {
            staleTime: 1000 * 60 * 10, // 10 minutes
            cacheTime: 1000 * 60 * 15, // 15 minutes
            retry: 1,
            getNextPageParam: lastPage => {
                if (lastPage?.pagination?.next_page) {
                    return lastPage.pagination.next_page;
                } else return undefined;
            },
            onError: err => {
                showToast({
                    type: 'error',
                    duration: 1500,
                    message:
                        err?.message || err || 'Failed to load People. Please refresh the page',
                });
                log.error(err || 'Failed to load people');
            },
        },
    );

    useEffect(() => {
        const temp = [];
        if (contactPages) {
            contactPages.pages.forEach(page => {
                temp.push(...page.data);
            });
            setContacts(temp);
        }
    }, [contactPages]);

    const getContactData = useCallback(
        async contactType => {
            setLoading(true);
            previewAPI(contactType, field.value)
                .then(res => {
                    updateField({
                        ...field,
                        contactData: { label: res.data.name, value: res.data.id },
                        value: { label: res.data.name, value: res.data.id },
                    });
                    setLoading(false);
                })
                .catch(err => {
                    showErrorDialog(err);
                });
        },
        [field, showErrorDialog, updateField],
    );

    const getTagData = useCallback(async () => {
        setLoading(true);
        getSingleTag(field.value)
            .then(res => {
                updateField({
                    ...field,
                    contactData: { label: res.data.tag, value: res.data.id },
                    value: { label: res.data.tag, value: res.data.id },
                });
                setLoading(false);
            })
            .catch(err => {
                showErrorDialog(err);
            });
    }, [field, showErrorDialog, updateField]);

    useEffect(() => {
        if (name === 'tags') {
            getTagOptions();
            if (!field.contactData && field.value?.length) getTagData();
        } else if (['people', 'person', 'business', 'businesses'].includes(name)) {
            refetchContacts();
            if (!field.contactData && field.value?.length)
                getContactData(['people', 'person'].includes(name) ? 'people' : 'businesses');
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [name]);

    const getOptions = useCallback(() => {
        if (name === 'tags') {
            return tags;
        } else {
            return options || [];
        }
    }, [tags, name, options]);

    const generateOptions = () => getOptions().map(item => {
            if (item?.label) {
                return {
                    label: (
                        <Button
                            buttonClass={'DropdownOptionsBtn'}
                            onClick={() => {
                                onValueChange(null, item);
                            }}
                        >
                            {item.label}
                        </Button>
                    ),
                };
            } else {
                let temp = {
                    label: item?.tag,
                    value: item?.id,
                };
                return {
                    label: (
                        <Button
                            buttonClass={'DropdownOptionsBtn'}
                            onClick={() => {
                                onValueChange(null, temp, true);
                            }}
                        >
                            {item.tag}
                        </Button>
                    ),
                };
            }
        });

    const getContactDisplay = useCallback(
        (item, id) => {
            return {
                id,
                label: (
                    <Button
                        buttonClass={'ACSingleOption ' + (value?.id === item.id ? 'active' : '')}
                        onClick={() => {
                            onValueChange(
                                {
                                    label: item.name || item.email,
                                    value: item.id,
                                },
                                null,
                                true,
                            );
                        }}
                    >
                        <UserInfo
                            name={item.name}
                            email={item.email}
                            image={generateImage(item.image, item.name, false, item.email)}
                        />
                    </Button>
                ),
                value: item.nane,
            };
        },
        [onValueChange, value],
    );

    const generateContactOptions = useCallback(() => {
        return isRefetchingContacts
            ? []
            : contacts.map((item, index) => {
                  return getContactDisplay(item, index);
              });
    }, [isRefetchingContacts, contacts, getContactDisplay]);

    useEffect(() => {
        if (field.isNew) {
            filterItemRef.current.click();
            const newField = { ...field };
            delete newField.isNew;
            onUpdate(newField);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (
            !(field.operator === 'is_unknown' || field.operator === 'has_any_value') &&
            !field.value &&
            field.value !== false
        ) {
            setHasError(prev => {
                return { ...prev, [field.id]: true };
            });
        } else {
            setHasError(prev => {
                return { ...prev, [field.id]: false };
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [field]);

    const getErrorClass = () => {
        let classStr = '';
        if (
            !(field.operator === 'is_unknown' || field.operator === 'has_any_value') &&
            !field.value &&
            field.value !== false
        ) {
            classStr = ' filterError';
        }
        return classStr;
    };

    const getFieldText = () => {
        let text = '';
        if (field.operator === 'is_unknown' || field.operator === 'has_any_value') {
            text = field.operator.replace(/_/g, ' ');
        } else if (field.type === 'date' || field.type === 'datetime') {
            if (!field.value) {
                text = ' is missing value';
            } else {
                if (['after', 'on', 'before'].includes(field.operator)) {
                    if (field.type === 'date') {
                        text = field.operator + ' ' + formateDate(field.value);
                    } else {
                        text = field.operator + ' ' + formateDate(field.value, true);
                    }
                } else {
                    text =
                        field.operator.replace(/_/g, ' ') +
                        ' ' +
                        field.value +
                        (field.value > 1 ? ' days' : 'day') +
                        ' ago';
                }
            }
        } else if (field.type === 'dropdown' || field.type === 'multipleoptions') {
            if (field.contactData) text = field.contactData.label;
            else
                text = field.value
                    ? field.operator.replace(/_/g, ' ') +
                      ' ' +
                      (field.value.label || field.value.value || field.value)
                    : ' is missing value';
        } else if (field.type === 'boolean') {
            text =
                field.value || field.value === false
                    ? field.operator.replace(/_/g, ' ')
                    : ' is missing value';
        } else if (field.type === 'time') {
            text =
                field.value || field.value === false
                    ? field.operator.replace(/_/g, ' ') +
                      ' ' +
                      dayjs(field.value).format('h:mm:ss a')
                    : ' is missing value';
        } else if (field.type === 'singleCheck' || field.type === 'multipleCheck') {
            text = field.value
                ? field.operator.replace(/_/g, ' ') +
                  ' ' +
                  (field.value.label || field.value.value || field.value)
                : ' is missing value';
        } else {
            text =
                field.value || field.value === false
                    ? field.operator.replace(/_/g, ' ') + ' ' + field.value
                    : ' is missing value';
        }
        return text;
    };

    const onOperatorChange = event => {
        let newOperator = '';
        if (event) {
            newOperator = event.target.value;
        } else {
            if (
                [
                    'text',
                    'email',
                    'phone',
                    'longtext',
                    'number',
                    'dropdown',
                    'multipleoptions',
                    'website',
                    'multipleCheck',
                    'singleCheck',
                ].includes(type) &&
                !operator
            ) {
                newOperator = 'is';
            } else if ((type === 'date' || type === 'datetime') && !operator) {
                newOperator = 'exactly';
            } else if (type === 'time' && !operator) {
                newOperator = 'after';
            } else if (type === 'boolean' && !operator) {
                newOperator = 'is_true';
            }
        }
        if (newOperator) {
            let newField = { ...field, operator: newOperator };
            if (newOperator === 'is_unknown' || newOperator === 'has_any_value') {
                newField.value = '';
            } else if (
                ['after', 'on', 'before'].includes(newOperator) &&
                (type === 'date' || type === 'datetime')
            ) {
                if (newField.value && !(newField.value instanceof Date)) {
                    newField.value = new Date();
                }
            } else if (
                ['more_than', 'exactly', 'less_than'].includes(newOperator) &&
                (type === 'date' || type === 'datetime')
            ) {
                if (newField.value instanceof Date) {
                    newField.value = '';
                }
            } else if (newOperator === 'is_true') {
                newField.value = true;
            } else if (newOperator === 'is_false') {
                newField.value = false;
            }
            updateField(newField);
            setDropdownOpen(false);
        }
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(onOperatorChange, []);

    const getFields = () => {
        const commonFields = [
            <Div key={name + 'is_unknown'} className="FBFIFLItem">
                <RadioButton
                    checkColor={'var(--dark-blue)'}
                    label="is unknown"
                    name="operator"
                    id={name + 'is_unknown'}
                    value="is_unknown"
                    fullWidth={true}
                    onChange={onOperatorChange}
                    checked={operator === 'is_unknown'}
                />
            </Div>,
            <Div key={name + 'has_any_value'} className="FBFIFLItem">
                <RadioButton
                    checkColor={'var(--dark-blue)'}
                    label="has any value"
                    name="operator"
                    id={name + 'has_any_value'}
                    value="has_any_value"
                    fullWidth={true}
                    onChange={onOperatorChange}
                    checked={operator === 'has_any_value'}
                />
            </Div>,
        ];

        if (
            type === 'text' ||
            type === 'email' ||
            type === 'phone' ||
            type === 'longtext' ||
            type === 'website'
        ) {
            let fieldType = type;
            if (type === 'longtext') {
                fieldType = 'text';
            }
            let inputField = (
                <Input
                    id={name}
                    type={fieldType}
                    autoFocus={true}
                    onChange={onValueChange}
                    value={value || ''}
                />
            );

            if (type === 'phone') {
                inputField = (
                    <PhoneNumber
                        id={name}
                        onChange={(value, country, e, formattedValue) => {
                            onValueChange(sanitizePhoneNumber(formattedValue));
                        }}
                        value={value || ''}
                        error={false}
                        positionCross={true}
                    />
                );
            }

            return [
                <Div key={name + 'is'} className="FBFIFLItem">
                    <RadioButton
                        checkColor={'var(--dark-blue)'}
                        label="is"
                        name="operator"
                        id={name + 'is'}
                        value="is"
                        fullWidth={true}
                        onChange={onOperatorChange}
                        checked={operator === 'is'}
                    />
                    {operator === 'is' ? inputField : ''}
                </Div>,
                <Div key={name + 'is_not'} className="FBFIFLItem">
                    <RadioButton
                        checkColor={'var(--dark-blue)'}
                        label="is not"
                        name="operator"
                        id={name + 'is_not'}
                        value="is_not"
                        fullWidth={true}
                        onChange={onOperatorChange}
                        checked={operator === 'is_not'}
                    />
                    {operator === 'is_not' ? inputField : ''}
                </Div>,
                <Div key={name + 'starts_with'} className="FBFIFLItem">
                    <RadioButton
                        checkColor={'var(--dark-blue)'}
                        label="starts with"
                        name="operator"
                        id={name + 'starts_with'}
                        value="starts_with"
                        fullWidth={true}
                        onChange={onOperatorChange}
                        checked={operator === 'starts_with'}
                    />
                    {operator === 'starts_with' ? inputField : ''}
                </Div>,

                <Div key={name + 'ends_with'} className="FBFIFLItem">
                    <RadioButton
                        checkColor={'var(--dark-blue)'}
                        label="ends with"
                        name="operator"
                        id={name + 'ends_with'}
                        value="ends_with"
                        fullWidth={true}
                        onChange={onOperatorChange}
                        checked={operator === 'ends_with'}
                    />
                    {operator === 'ends_with' ? inputField : ''}
                </Div>,

                <Div key={name + 'contains'} className="FBFIFLItem">
                    <RadioButton
                        checkColor={'var(--dark-blue)'}
                        label="contains"
                        name="operator"
                        id={name + 'contains'}
                        value="contains"
                        fullWidth={true}
                        onChange={onOperatorChange}
                        checked={operator === 'contains'}
                    />
                    {operator === 'contains' ? inputField : ''}
                </Div>,

                <Div key={name + 'does_not_contain'} className="FBFIFLItem">
                    <RadioButton
                        checkColor={'var(--dark-blue)'}
                        label="does not contain"
                        name="operator"
                        id={name + 'does_not_contain'}
                        value="does_not_contain"
                        fullWidth={true}
                        onChange={onOperatorChange}
                        checked={operator === 'does_not_contain'}
                    />
                    {operator === 'does_not_contain' ? inputField : ''}
                </Div>,
                ...commonFields,
            ];
        } else if (type === 'number') {
            let inputField = (
                <Input
                    id={name}
                    type={type}
                    autoFocus={true}
                    onChange={onValueChange}
                    value={value || ''}
                />
            );

            return [
                <Div key={name + 'greater_than'} className="FBFIFLItem">
                    <RadioButton
                        checkColor={'var(--dark-blue)'}
                        label="greater than"
                        name="operator"
                        id={name + 'greater_than'}
                        value="greater_than"
                        fullWidth={true}
                        onChange={onOperatorChange}
                        checked={operator === 'greater_than'}
                    />
                    {operator === 'greater_than' ? inputField : ''}
                </Div>,
                <Div key={name + 'less_than'} className="FBFIFLItem">
                    <RadioButton
                        checkColor={'var(--dark-blue)'}
                        label="less than"
                        name="operator"
                        id={name + 'less_than'}
                        value="less_than"
                        fullWidth={true}
                        onChange={onOperatorChange}
                        checked={operator === 'less_than'}
                    />
                    {operator === 'less_than' ? inputField : ''}
                </Div>,
                <Div key={name + 'is'} className="FBFIFLItem">
                    <RadioButton
                        checkColor={'var(--dark-blue)'}
                        label="is"
                        name="operator"
                        id={name + 'is'}
                        value="is"
                        fullWidth={true}
                        onChange={onOperatorChange}
                        checked={operator === 'is'}
                    />
                    {operator === 'is' ? inputField : ''}
                </Div>,
                <Div key={name + 'is_not'} className="FBFIFLItem">
                    <RadioButton
                        checkColor={'var(--dark-blue)'}
                        label="is not"
                        name="operator"
                        id={name + 'is_not'}
                        value="is_not"
                        fullWidth={true}
                        onChange={onOperatorChange}
                        checked={operator === 'is_not'}
                    />
                    {operator === 'is_not' ? inputField : ''}
                </Div>,
                ...commonFields,
            ];
        } else if (type === 'date' || type === 'datetime') {
            let dateField = null;
            if (type === 'datetime') {
                dateField = (
                    <DateTimePicker
                        value={
                            dayjs(value).tz(getUserTimeZone()).toDate() ||
                            dayjs().tz(getUserTimeZone()).toDate()
                        }
                        onChange={onValueChange}
                        width={320}
                    />
                );
            } else {
                dateField = (
                    <OnlyDatePickerCalendar
                        date={
                            dayjs(value).tz(getUserTimeZone()).toDate() ||
                            dayjs().tz(getUserTimeZone()).toDate()
                        }
                        handleDatePicker={onValueChange}
                        locale={enGB}
                    />
                );
            }

            let numberField = (
                <Div className="FIDORORGOInput">
                    <Input
                        id="isNotOption"
                        type={'nubmer'}
                        autoFocus={true}
                        onChange={onValueChange}
                        value={value || ''}
                    />
                    <Div className="FIDORORGOILabel">days ago</Div>
                </Div>
            );

            return [
                <LabelControl key={'relative'}>
                    <Label>Relative</Label>
                </LabelControl>,
                <Div key={name + 'more_than'} className="FBFIFLItem">
                    <RadioButton
                        checkColor={'var(--dark-blue)'}
                        label="more than"
                        name="operator"
                        id={name + 'more_than'}
                        value="more_than"
                        fullWidth={true}
                        onChange={onOperatorChange}
                        checked={operator === 'more_than'}
                    />
                    {operator === 'more_than' ? numberField : ''}
                </Div>,
                <Div key={name + 'exactly'} className="FBFIFLItem">
                    <RadioButton
                        checkColor={'var(--dark-blue)'}
                        label="exactly"
                        name="operator"
                        id={name + 'exactly'}
                        value="exactly"
                        fullWidth={true}
                        onChange={onOperatorChange}
                        checked={operator === 'exactly'}
                    />
                    {operator === 'exactly' ? numberField : ''}
                </Div>,
                <Div key={name + 'less_than'} className="FBFIFLItem">
                    <RadioButton
                        checkColor={'var(--dark-blue)'}
                        label="less than"
                        name="operator"
                        id={name + 'less_than'}
                        value="less_than"
                        fullWidth={true}
                        onChange={onOperatorChange}
                        checked={operator === 'less_than'}
                    />
                    {operator === 'less_than' ? numberField : ''}
                </Div>,
                <LabelControl key={'absolute'}>
                    <Label>Absolute</Label>
                </LabelControl>,
                <Div key={name + 'after'} className="FBFIFLItem">
                    <RadioButton
                        checkColor={'var(--dark-blue)'}
                        label="after"
                        name="operator"
                        id={name + 'after'}
                        value="after"
                        fullWidth={true}
                        onChange={onOperatorChange}
                        checked={operator === 'after'}
                    />
                    {operator === 'after' ? dateField : ''}
                </Div>,
                <Div key={name + 'on'} className="FBFIFLItem">
                    <RadioButton
                        checkColor={'var(--dark-blue)'}
                        label="on"
                        name="operator"
                        id={name + 'on'}
                        value="on"
                        fullWidth={true}
                        onChange={onOperatorChange}
                        checked={operator === 'on'}
                    />
                    {operator === 'on' ? dateField : ''}
                </Div>,
                <Div key={name + 'before'} className="FBFIFLItem">
                    <RadioButton
                        checkColor={'var(--dark-blue)'}
                        label="before"
                        name="operator"
                        id={name + 'before'}
                        value="before"
                        fullWidth={true}
                        onChange={onOperatorChange}
                        checked={operator === 'before'}
                    />
                    {operator === 'before' ? dateField : ''}
                </Div>,
                ...commonFields,
            ];
        } else if (
            type === 'dropdown' ||
            type === 'multipleoptions' ||
            type === 'multipleCheck' ||
            type === 'singleCheck'
        ) {
            let inputField = (
                <Fragment>
                    <Div className={'DCDropdownSelect fullWidth'}>
                        <Button
                            buttonClass={'DCDSMainBtn ' + (dropdownOpen === true ? 'focus' : '')}
                            reference={buttonRef}
                            onClick={handleDropdownToggle}
                        >
                            {value?.label || 'Select Value'}
                        </Button>
                    </Div>
                    <DropdownOptions
                        options={generateOptions()}
                        wrapperWidth={dropdownWidth}
                        onClose={handleDropdownClose}
                        open={dropdownOpen}
                        reference={buttonRef}
                        viewHeight={getOptions().length > 5 ? 200 : getOptions().length * 40}
                        itemSize={40}
                    />
                </Fragment>
            );

            if (name === 'industry') {
                inputField = (
                    <IndustryList
                        singleSelect={true}
                        value={value?.value}
                        onChange={onValueChange}
                        fromFilter={true}
                    />
                );
            }

            if (['people', 'person', 'businesses', 'business'].includes(name)) {
                inputField = (
                    <AutoComplete
                        generateRows={generateContactOptions}
                        isAPICall
                        singleSelection={true}
                        searchAPI={text => setSearchTerm(text)}
                        hasNextPage={hasContactNextPage}
                        loading={
                            isContactLoading || isFetchingContactNextPage || isRefetchingContacts
                        }
                        getMoreOptions={fetchContactNextPage}
                        value={value?.label || 'Select Option'}
                        itemHeight={58}
                        customWidth={300}
                        height={contacts?.length > 5 ? 290 : contacts?.length * 58}
                        placement={'bottom-center'}
                    />
                );
            }

            return [
                <Div key={name + 'is'} className="FBFIFLItem">
                    <RadioButton
                        checkColor={'var(--dark-blue)'}
                        label="is"
                        name="operator"
                        id={name + 'is'}
                        value="is"
                        fullWidth={true}
                        onChange={onOperatorChange}
                        checked={operator === 'is'}
                    />
                    {operator === 'is' ? inputField : ''}
                </Div>,
                <Div key={name + 'is_not'} className="FBFIFLItem">
                    <RadioButton
                        checkColor={'var(--dark-blue)'}
                        label="is not"
                        name="operator"
                        id={name + 'is_not'}
                        value="is_not"
                        fullWidth={true}
                        onChange={onOperatorChange}
                        checked={operator === 'is_not'}
                    />
                    {operator === 'is_not' ? inputField : ''}
                </Div>,
                ...commonFields,
            ];
        } else if (type === 'time') {
            // TimePickerNew
            let inputField = <TimePicker onChange={onValueChange} value={value || ''} />;
            return [
                <Div key={name + 'after'} className="FBFIFLItem">
                    <RadioButton
                        checkColor={'var(--dark-blue)'}
                        label="after"
                        name="operator"
                        id={name + 'after'}
                        value="after"
                        fullWidth={true}
                        onChange={onOperatorChange}
                        checked={operator === 'after'}
                    />
                    {operator === 'after' ? inputField : ''}
                </Div>,
                <Div key={name + 'on'} className="FBFIFLItem">
                    <RadioButton
                        checkColor={'var(--dark-blue)'}
                        label="on"
                        name="operator"
                        id={name + 'on'}
                        value="on"
                        fullWidth={true}
                        onChange={onOperatorChange}
                        checked={operator === 'on'}
                    />
                    {operator === 'on' ? inputField : ''}
                </Div>,
                <Div key={name + 'before'} className="FBFIFLItem">
                    <RadioButton
                        checkColor={'var(--dark-blue)'}
                        label="before"
                        name="operator"
                        id={name + 'before'}
                        value="before"
                        fullWidth={true}
                        onChange={onOperatorChange}
                        checked={operator === 'before'}
                    />
                    {operator === 'before' ? inputField : ''}
                </Div>,
                ...commonFields,
            ];
        } else if (type === 'boolean') {
            return [
                <Div key={name + 'is_true'} className="FBFIFLItem">
                    <RadioButton
                        checkColor={'var(--dark-blue)'}
                        label="is true"
                        name="operator"
                        id={name + 'is_true'}
                        value="is_true"
                        fullWidth={true}
                        onChange={onOperatorChange}
                        checked={operator === 'is_true'}
                    />
                </Div>,
                <Div key={name + 'is_false'} className="FBFIFLItem">
                    <RadioButton
                        checkColor={'var(--dark-blue)'}
                        label="is false"
                        name="operator"
                        id={name + 'is_false'}
                        value="is_false"
                        fullWidth={true}
                        onChange={onOperatorChange}
                        checked={operator === 'is_false'}
                    />
                </Div>,
                ...commonFields,
            ];
        }

        return commonFields;
    };

    useEffect(() => {
        setFieldOptions(
            (fields || []).map((f, i) => {
                return {
                    id: i + f.name,
                    show_in_filter: f.show_in_filter,
                    title: f.title,
                    field: f,
                };
            }),
        );
    }, [fields]);

    const generateFieldsOption = () => {
        const tempRowArray = [];
        const optionsNew = fieldOptions.filter(f => f.show_in_filter !== false);

        optionsNew.map(item => {
            tempRowArray.push({
                id: item.id,
                label: (
                    <Fragment>
                        <Button
                            buttonClass={'fullWidth'}
                            iconName={
                                item.field?.custom ? (
                                    <ToolTip content={'Custom Property'}>
                                        <DCCompare />
                                    </ToolTip>
                                ) : (
                                    <DCUserIcon />
                                )
                            }
                            onClick={() => {
                                onUpdate({
                                    ...field,
                                    name: item.field.name,
                                    title: item.title,
                                    type: item.field.type,
                                    options: item.field.options,
                                });
                                handleFilterItemDropdownToggle();
                            }}
                        >
                            {item.title}
                        </Button>
                    </Fragment>
                ),
                value: item.title,
            });
        });
        return tempRowArray;
    };

    return (
        <Fragment>
            <Div className="FBFilterItem">
                <Div className={'FBFIBtnOuter' + getErrorClass()}>
                    <AutoComplete
                        customClass={'CreateFilterDropdown'}
                        customWidth={300}
                        value={''}
                        generateRows={generateFieldsOption}
                        isAPICall={false}
                        singleSelection={true}
                        itemHeight={36}
                        inputReference={autocompleteRef}
                        // disabled={preventAdding}
                    />
                    <Button
                        reference={filterItemRef}
                        onClick={handleFilterItemDropdownToggle}
                        buttonClass={'FBFIBtn'}
                        iconName={<DCUserIcon />}
                    >
                        {field.title} &nbsp;
                        {loading ? (
                            <Skeleton clas width={30} />
                        ) : (
                            <Div className="FBFIBEnterText">{getFieldText()}</Div>
                        )}
                    </Button>
                    <ToolTip content={'Remove this filter'} wrapperClass={'FBFICTooltip'}>
                        <IconButton customClass="FBFICloseBtn" onClick={onRemove}>
                            <DCCrossCloseIcon />
                        </IconButton>
                    </ToolTip>
                </Div>
            </Div>

            <Popover
                open={filterItemDropdownState}
                anchorEl={filterItemRef}
                onClose={handleFilterItemDropdownClose}
                placement={'bottom-start'}
                withOutPadding
            >
                <Div className="FBFIFieldWrapper">
                    <Div className="FBFIFieldList">
                        <Button
                            buttonClass="SimpleLink"
                            iconPlacement="left"
                            iconName={<DCArrowBack />}
                            onClick={handleBack}
                        >
                            Back
                        </Button>
                        {getFields()}
                    </Div>

                    <Div onClick={handleFilterItemDropdownClose} className="FBFIFSave">
                        <Button>Done</Button>
                    </Div>
                </Div>
            </Popover>
        </Fragment>
    );
}

export default FilterItem;
