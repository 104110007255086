import React from 'react';

import { SVGIcon } from '../../UIKit/index';

export default function DCCampaigns(props) {
    return (
        <SVGIcon {...props} viewBox="0 0 512 512">
            <path d="M219,134.1l-7.4-16.3l-56.7,33.5c0,0-9.5,4.5-3.4,19.8c0,0-11.5,9.1-7.9,26.2c0,0,5.6,21.7,24.6,27.1  c0,0,6.9,1.8,12.1-0.6l1.6,0.3l9,6.4c0,0,4.2,1.3,9,0l69.9-40.9L224,109.7l-12.8,8.3l6.4,16.1l1.6,2.7l26.8,45.7l-49.3,29l-9.2-12.6  l-12.6,7.1c0,0-1.5,1.5-4.5,0.2l-8.4-13.9c0,0-1.2-4.1,2.4-6.3l12.3-7.8l-8.2-14.7L219,134.1z" />
            <path d="M204.6,237.5l34.1-19.8c0,0,8.6,8.9,7,29.4l22.4,26.1L246.8,286L204.6,237.5z" />
            <path d="M233.9,103.1l46.7,80.9l65.3-14.9c0,0,3.4-1,4.2,0.5l4.8,5.7c0,0,3.2,1.3,5.8-0.2c0,0,5.6-2.2,8.5-6.5  c0,0,1.2-2.9,0-6.1L313.8,67l-19.5-32.9c0,0-7.8-6.4-16.7,5.7c0,0-3.1,0.6,3.4,11.8l-1.1,2.4L233.9,103.1z" />
            <path d="M329.2,77.3l19.9,33.7c0,0,10.4-8.2,2.2-25.7C351.3,85.3,345.2,72.9,329.2,77.3z" />
            <path d="M345.2,19.2l-11.8,35.5c0,0-1.8,7.4,4.4,10.2c0,0,6.5,1.7,8.2-4.4l9.1-25l3.7-10.8c0,0,1.1-7-4.2-8.7  C354.5,16,347.9,14,345.2,19.2z" />
            <path d="M395.4,51.7l-33.1,17.4c0,0-6.4,4-3.8,10.4c0,0,3.5,5.7,9,2.5l23.8-11.7l10.1-5.3c0,0,5.6-4.3,3-9.2  C404.3,55.9,401,49.9,395.4,51.7z" />
            <path d="M409.5,105.2l-37.3-3.5c0,0-7.6-0.1-8.9,6.6c0,0-0.2,6.7,6.2,7l26.4,3.2l11.4,1.1c0,0,7-0.5,7.5-6.1  C414.7,113.6,415.2,106.7,409.5,105.2z" />
            <path d="M357.1,191.7c0,0,19-2.4,26.4-14.7l53.8,46.1c0,0,12.3,9.1,13.2,21v222.6c0,0,0.4,29.7-33.5,31.4H84.9  c0,0-27.9-2.5-29.4-30V245.8c0,0,0.6-16.6,10.6-21.6l64-52.3c0,0-4.3,14.7-0.4,34.8v1.9l-38.6,32.3l-4.2,24.5L87,466.6l332.1,0.1  V266c0,0,2.8-13.2-4.3-24.9L357.1,191.7z" />
            <path d="M318.7,366.2c0,0-3.8-2.7-8.8-0.9c0,0-5.8,1.5-6.9,7.8c0,0-0.3,3,0,5.2l2.1,3.8l77.8,72.8c0,0,4.9,2.2,9.8,0  c0,0,9-4.2,6.9-12.5c0,0-1.4-3.1-3.6-4.9L318.7,366.2z" />
            <path d="M198.9,381.1c0,0,3-3.5,1.6-8.7c0,0-1-5.9-7.2-7.6c0,0-3-0.6-5.2-0.4l-3.9,1.7l-79.1,71.3c0,0-2.6,4.7-0.8,9.8  c0,0,3.4,9.3,11.9,8c0,0,3.2-1.1,5.2-3.1L198.9,381.1z" />
            <path d="M84.6,262.9L250,388.8c0,0,2.4,1.6,5.4,0l171-127.5l-9.1-22.1l-2.9,3.1L255.5,360.7h-5L89,239.3l-4.4,12.5  V262.9z" />
        </SVGIcon>
    );
}
