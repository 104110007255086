import React from 'react';

import { SVGIcon } from '../../UIKit/index';

export default function DCNotes(props) {
    return (
        <SVGIcon {...props} viewBox="0 0 87.411 103">
            <g transform="translate(-1285.584 -442.634)">
                <g transform="translate(1285.584 442.634)">
                    <path
                        d="M74.185,3.225A3.236,3.236,0,0,0,70.959,0H20.965V16.127a3.226,3.226,0,0,1-3.225,3.225H0V90.312a3.236,3.236,0,0,0,3.225,3.225H70.959a3.236,3.236,0,0,0,3.225-3.225ZM54.832,32.254ZM17.74,32.254ZM45.156,41.93ZM35.48,51.607ZM25.8,61.283Zm0,0"
                        transform="translate(13.227 9.463)"
                        fill="#f9f9f9"
                    />
                    <path
                        d="M74.185,3.225A3.236,3.236,0,0,0,70.959,0H20.965V16.127a3.226,3.226,0,0,1-3.225,3.225H0V90.312a3.236,3.236,0,0,0,3.225,3.225H70.959a3.236,3.236,0,0,0,3.225-3.225ZM54.832,32.254ZM17.74,32.254ZM45.156,41.93ZM35.48,51.607ZM25.8,61.283Zm0,0"
                        fill="#d9eaff"
                    />
                    <path
                        d="M122.586,358.195l2.28-15.965,10.264,3.421,3.422,10.264Zm0,0"
                        transform="translate(-97.874 -273.24)"
                        fill="#fff"
                    />
                    <path
                        d="M104,266.574h49.994a3.226,3.226,0,0,0,3.225-3.225V216.727A80.812,80.812,0,0,1,104,266.574Zm0,0"
                        transform="translate(-83.035 -173.037)"
                        fill="#d9eaff"
                    />
                    <path
                        d="M303.609,154.459l4.561-4.561,13.684,13.684-4.561,4.561Zm0,0"
                        transform="translate(-242.405 -119.68)"
                        fill="#fff"
                    />
                    <path
                        d="M334.352,124.582a9.123,9.123,0,0,0-1.613.306,9.66,9.66,0,0,0-4.193,2.467l-2.273,2.291,13.676,13.676,2.274-2.274a9.676,9.676,0,0,0-7.87-16.466Zm0,0"
                        transform="translate(-260.5 -99.423)"
                        fill="#2b88f8"
                    />
                    <path
                        d="M168.109,172.48,133.887,206.7l9.134,4.558,20.519-20.527-20.519,20.527,2.059,4.117,2.507,5,33.171-33.17,1.033-1.032-2.648-2.647Zm.147,13.537,2.282-2.281Zm0,0"
                        transform="translate(-106.897 -137.71)"
                        fill="#2b88f8"
                    />
                    <path
                        d="M20.965,16.127V0L0,19.352H17.74A3.226,3.226,0,0,0,20.965,16.127Zm0,0"
                        fill="#2b88f8"
                    />
                    <path
                        d="M194.217,269.786a1.613,1.613,0,0,1-1.14-2.754l17.306-17.3A1.613,1.613,0,1,1,212.66,252l-17.3,17.309A1.613,1.613,0,0,1,194.217,269.786Zm0,0"
                        transform="translate(-153.776 -198.947)"
                        fill="#f1f2f2"
                    />
                    <path
                        d="M302.678,221.217a1.613,1.613,0,0,1-1.14-2.753l2.281-2.28a1.613,1.613,0,0,1,2.28,2.28l-2.28,2.281A1.6,1.6,0,0,1,302.678,221.217Zm0,0"
                        transform="translate(-240.375 -172.242)"
                        fill="#f1f2f2"
                    />
                </g>
                <g transform="translate(1295.083 471.932)">
                    <rect
                        width="32.489"
                        height="4.332"
                        rx="2.166"
                        transform="translate(12.996)"
                        fill="#2b88f8"
                    />
                    <rect
                        width="36.821"
                        height="4.332"
                        rx="2.166"
                        transform="translate(0 10.83)"
                        fill="#2b88f8"
                    />
                    <rect
                        width="28.158"
                        height="4.332"
                        rx="2.166"
                        transform="translate(0 20.577)"
                        fill="#2b88f8"
                    />
                    <rect
                        width="20.577"
                        height="4.332"
                        rx="2.166"
                        transform="translate(0 31.407)"
                        fill="#2b88f8"
                    />
                    <rect width="8.664" height="4.332" rx="2.166" fill="#2b88f8" />
                </g>
            </g>
        </SVGIcon>
    );
}
