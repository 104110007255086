import { contactActions } from '../Actions';

const contactReducer = (state, action) => {
    switch (action.type) {
        case contactActions.ADD_CONTACT:
            return {
                contacts: [...state.contacts, action.value],
            };
        case contactActions.DEL_CONTACT:
            return {
                contacts: state.contacts.filter(contact => contact.id !== action.value),
            };
        case contactActions.START:
            return {
                loading: true,
            };
        case contactActions.COMPLETE:
            return {
                loading: false,
            };
        default:
            throw new Error();
    }
};

export default contactReducer;
