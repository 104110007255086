import React from 'react';

import { SVGIcon } from '../../UIKit/index';

export default function DCQuote(props) {
    return (
        <SVGIcon {...props} viewBox="0 0 409.294 409.294">
            <path d="m0 204.647v175.412h175.412v-175.412h-116.941c0-64.48 52.461-116.941 116.941-116.941v-58.471c-96.728 0-175.412 78.684-175.412 175.412z" />
            <path d="m409.294 87.706v-58.471c-96.728 0-175.412 78.684-175.412 175.412v175.412h175.412v-175.412h-116.941c0-64.48 52.461-116.941 116.941-116.941z" />
        </SVGIcon>
    );
}
