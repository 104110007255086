import React from 'react';

import { SVGIcon } from '../../UIKit/index';

export default function DCTwoStar(props) {
    return (
        <SVGIcon {...props} viewBox="0 0 496 512">
            <g>
                <path d="M493.04,330.69c-1.2-1.15-2.68-1.97-4.29-2.36l-87.89-20.04l-10.71-17.58l91.7-105.42    c3.53-3.75,3.36-9.65-0.39-13.18c-1.21-1.14-2.71-1.94-4.33-2.31l-148.18-34.3L250.59,5.29c-2.56-4.44-8.23-5.97-12.67-3.41    c-1.42,0.82-2.59,1.99-3.41,3.41l-78.36,130.23L7.96,169.83c-4.99,1.16-8.09,6.15-6.93,11.14c0.34,1.47,1.04,2.83,2.03,3.97    l99.69,114.9L89.59,451.45c-0.49,5.1,3.24,9.63,8.34,10.13c1.54,0.15,3.09-0.09,4.52-0.7l140.1-59.47l11.04,4.72l4.72,5.36    l-7.77,89.67c-0.49,5.1,3.24,9.63,8.34,10.13c1.54,0.15,3.09-0.09,4.52-0.7l83.13-34.73l82.06,34.7    c4.55,2.41,10.19,0.68,12.61-3.87c0.84-1.58,1.2-3.36,1.06-5.14l-7.77-90.08l58.94-67.58c3.55-3.73,3.39-9.64-0.34-13.18    C493.06,330.71,493.05,330.7,493.04,330.69z M109.47,437.69l12.16-140.1c0.24-2.77-0.76-5.5-2.73-7.45l-91.7-105.62l136.98-31.74    c2.6-0.59,4.83-2.27,6.11-4.61l72.25-120.16l72.52,120.48c1.3,2.18,3.43,3.73,5.89,4.29l136.98,31.74l-77.68,89.49l-25.67-42.87    c-2.56-4.44-8.23-5.97-12.67-3.41c-1.42,0.82-2.59,1.99-3.41,3.41l-46.34,77.15l-87.63,20.27c-4.99,1.15-8.11,6.13-6.95,11.13    c0.34,1.47,1.04,2.84,2.03,3.99l35.36,40.73L109.47,437.69z M417.91,402.59c-1.65,1.89-2.46,4.36-2.25,6.86l6.8,78.2l-72.41-30.77    c-2.3-0.96-4.88-0.96-7.18,0l-72.25,30.65l6.8-78.14c0.24-2.77-0.76-5.5-2.73-7.45l-50.97-58.94l76.38-17.28    c2.6-0.59,4.83-2.27,6.11-4.61l40.25-66.88l40.41,67.19c1.32,2.2,3.49,3.75,6,4.29l0,0l76.43,17.68L417.91,402.59z" />
            </g>
        </SVGIcon>
    );
}
