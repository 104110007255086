import React from 'react';

import { SVGIcon } from '../../UIKit/index';

export default function DCSignPay(props) {
    return (
        <SVGIcon {...props} viewBox="0 0 512 512">
            <g>
                <g>
                    <path
                        d="M251,131.7l-80.8,0c-17.5,0-31.8,14.3-31.8,31.8c0,17.5,14.3,31.8,31.8,31.8c9.2,0,16.7,7.5,16.7,16.7
			c0,9.2-7.5,16.7-16.7,16.7H8.5c-4.2,0-7.5,3.4-7.5,7.5s3.4,7.5,7.5,7.5h161.6c17.5,0,31.8-14.3,31.8-31.8
			c0-17.5-14.3-31.8-31.8-31.8c-9.2,0-16.7-7.5-16.7-16.7c0-9.2,7.5-16.7,16.7-16.7l80.8,0c4.2,0,7.5-3.4,7.5-7.5
			S255.1,131.7,251,131.7z"
                    />
                </g>
            </g>
            <g>
                <g>
                    <path
                        d="M36.2,67.3L2.7,107.7c-1.6,1.9-2.1,4.5-1.5,6.9l20.6,72.7l28.8-69.6c1.6-3.8,6-5.7,9.9-4.1c3.8,1.6,5.7,6,4.1,9.9
			L35.8,193l66-36.8c2.2-1.2,3.6-3.4,3.8-5.9l4.9-52.3L36.2,67.3z"
                    />
                </g>
            </g>
            <g>
                <g>
                    <path
                        d="M129.8,24.8L74.4,1.9c-1.8-0.8-3.9-0.8-5.8,0c-1.8,0.8-3.3,2.2-4.1,4.1L44.5,54.4l69.3,28.7l20-48.4
			C135.4,30.8,133.6,26.4,129.8,24.8z"
                    />
                </g>
            </g>
            <path
                d="M432.7,398c34.7,0,63-28.3,63-63s-28.2-63-63-63c-34.7,0-63,28.3-63,63S397.9,398,432.7,398z M432.7,342
	c-11.6,0-21-9.4-21-21c0-9.1,5.9-16.8,14-19.7V293c0-3.9,3.1-7,7-7c3.9,0,7,3.1,7,7v8.3c8.1,2.9,14,10.6,14,19.7c0,3.9-3.1,7-7,7
	c-3.9,0-7-3.1-7-7c0-3.9-3.1-7-7-7c-3.9,0-7,3.1-7,7s3.1,7,7,7c11.6,0,21,9.4,21,21c0,9.1-5.9,16.8-14,19.7v8.3c0,3.9-3.1,7-7,7
	c-3.9,0-7-3.1-7-7v-8.3c-8.1-2.9-14-10.6-14-19.7c0-3.9,3.1-7,7-7c3.9,0,7,3.1,7,7c0,3.9,3.1,7,7,7c3.9,0,7-3.1,7-7
	C439.7,345.1,436.5,342,432.7,342z"
            />
            <path
                d="M508,423.7c-5.5-5.5-14.4-5.3-19.7,0.3L467.7,446c-13.4,14.4-17.9,22.8-30.2,22.8H390c-3.9,0-7-3.1-7-7c0-3.9,3.1-7,7-7
	h35.7c7.7,0,14-6.3,14-14c0-7.7-6.3-14-14-14h-18.9c-0.9-0.4-1.7-0.7-2.7-1.1c-18.5-18.2-47.7-18.7-66.8-2l-19.5,17.1h-11.2
	c-3.9,0-7,3.1-7,7v56c0,3.9,3.1,7,7,7h126c15.4,0,29.9-7.3,39.2-19.6l37.4-49.8C513.3,436.1,512.7,428.4,508,423.7z"
            />
            <g>
                <path d="M267.7,242.6v52h49.8V317h-49.8v52.4h-23.4V317h-49.8v-22.4h49.8v-52H267.7z" />
            </g>
        </SVGIcon>
    );
}
