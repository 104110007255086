import './style.scss';

import React from 'react';
import PropTypes from 'prop-types';
import { Div, H5 } from '../index';

const IconTitleCard = ({
    customClass,
    iconName,
    title,
    description,
    onClick,
    iconHeight,
    iconWidth,
    disabled,
}) => {
    return (
        <Div
            className={
                'IconTitleCard ' +
                (customClass ? customClass : '') +
                (iconWidth ? ' ITCWidth' : '') +
                (disabled ? ' disabled' : '')
            }
            onClick={() => {
                if (!disabled) onClick();
            }}
        >
            <Div className={'ITCInner'}>
                <Div className={'ITCIIcon'} style={{ width: iconWidth, height: iconHeight }}>
                    {iconName}
                </Div>
                <Div className={'ITCITitle'}>
                    <H5>{title}</H5>
                </Div>
                {description ? <Div className={'ITCIDes'}>{description}</Div> : null}
            </Div>
        </Div>
    );
};
IconTitleCard.propTypes = {
    customClass: PropTypes.string,
    iconName: PropTypes.element,
    title: PropTypes.string,
    description: PropTypes.string,
    onClick: PropTypes.func,
    iconHeight: PropTypes.number || PropTypes.string,
    iconWidth: PropTypes.number || PropTypes.string,
};

export default IconTitleCard;
