import React from 'react';
import { SVGIcon } from '../../UIKit/index';

export default function DCDashClicks(props) {
    return (
        <SVGIcon
            {...props}
            id="Layer_1"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            viewBox="0 0 2000 2000"
        >
            <defs>
                <linearGradient
                    id="linear-gradient"
                    x1="0"
                    y1="1000"
                    x2="2000"
                    y2="1000"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset="0" stop-color="#0070ee" />
                    <stop offset="1" stop-color="#00bdf0" />
                </linearGradient>
            </defs>
            <path
                d="M2000,400.78V1599.22c0,35.55-4.61,70-13.32,102.81-8.2,31.09-20.08,60.66-35.08,88.32-45.16,83.05-118.79,148.36-207.81,182.73-26.76,10.39-54.88,17.97-84.02,22.38-19.77,2.97-39.96,4.53-60.55,4.53H400.78c-3.55,0-7.11-.04-10.62-.12-124.84-3.28-235.47-63.59-306.68-155.86-17.62-22.73-32.77-47.38-45.16-73.59C13.75,1718.52,0,1660.51,0,1599.22V400.78C0,386.41,.74,372.23,2.23,358.24c9.53-90.43,49.1-171.84,108.59-234.14,25.51-26.72,54.69-49.96,86.72-68.79C257.11,20.16,326.6,0,400.78,0H1599.22c11.48,0,22.85,.47,34.1,1.45,29.61,2.46,58.28,8.2,85.7,16.76,103.24,32.3,188.48,105.2,237.07,200,13.67,26.68,24.45,55.08,31.87,84.8,7.85,31.29,12.03,64.02,12.03,97.77Z"
                style={{ fill: 'url(#linear-gradient)' }}
            />
            <g>
                <path
                    d="M997.42,263.09c-209.17,0-397.95,87.16-532.07,227.14-16.25,16.96-37.94,43.92-43.03,64.03-13.3,52.55,54.54,83.48,92.65,37.68,.25-.3,4.27-5.39,4.47-5.62,115.91-133.81,287.06-218.47,477.99-218.47,348.8,0,636.12,290.79,632.11,639.57-3.98,345.7-285.46,624.72-632.1,624.72-182.66,0-347.23-77.48-462.63-201.36-3.77-4.05-19.68-21.22-30.91-29.61-54.21-40.52-118.44,23.57-60.8,84.11,.97,1.02,1.77,2.21,2.71,3.28,138.36,156.12,341.96,253.1,568.09,248.17,406.35-8.86,726.9-342.46,720.34-748.85-6.48-401.39-333.88-724.78-736.81-724.78Z"
                    style={{ fill: '#f9fcff' }}
                />
                <path
                    d="M896.66,621.7c-46.86,0-81.32,30.44-107.8,65.36-11.88,15.66-29.64,12.87-29.64,12.87H330.19l-33.92,89.88s430.6,.5,458.04,0c17.63-.32,32.45,9.41,39.21,17.87,25.32,31.63,59.63,57.21,103.14,57.21,67.15,0,121.59-54.44,121.59-121.59s-54.44-121.59-121.59-121.59Z"
                    style={{ fill: '#f9fcff' }}
                />
                <path
                    d="M896.66,1135.11c-46.86,0-81.32,30.44-107.8,65.36-11.88,15.66-29.64,12.87-29.64,12.87H296.27l33.92,89.88s396.68,.5,424.12,0c17.63-.32,32.45,9.41,39.21,17.87,25.32,31.63,59.63,57.21,103.14,57.21,67.15,0,121.59-54.44,121.59-121.59s-54.44-121.59-121.59-121.59Z"
                    style={{ fill: '#f9fcff' }}
                />
                <path
                    d="M1273.8,878.41c-46.86,0-81.32,30.44-107.8,65.36-11.88,15.66-29.64,12.87-29.64,12.87H265.67v89.99H787.48c129.64,.11,325.74,.22,343.97-.11,17.63-.32,32.45,9.41,39.21,17.87,25.32,31.63,59.63,57.21,103.14,57.21,67.15,0,121.59-54.44,121.59-121.59s-54.44-121.59-121.59-121.59Z"
                    style={{ fill: '#f9fcff' }}
                />
            </g>
        </SVGIcon>
    );
}
