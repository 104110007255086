import React from 'react';

import { SVGIcon } from '../../UIKit/index';

export default function DCSEOSearch(props) {
    return (
        <SVGIcon {...props} viewBox="0 0 512 512">
            <g transform="translate(-284.732 -102.987)">
                <g transform="translate(284.732 102.987)">
                    <g transform="translate(0)">
                        <g>
                            <path d="M394.09,0H32.11C14.47-0.01,0.16,14.28,0.15,31.92c0,0.01,0,0.02,0,0.02v255.52      c0,17.64,14.3,31.94,31.94,31.94c0.01,0,0.02,0,0.02,0h138.4v-21.3H32.11c-5.88,0-10.64-4.76-10.64-10.64l0,0V31.94      c0-5.88,4.76-10.64,10.64-10.64l0,0h361.97c5.88,0,10.64,4.76,10.64,10.64c0,0,0,0,0,0v191.64h21.3V31.94      C426.03,14.3,411.73,0,394.09,0z" />
                        </g>
                    </g>
                    <g transform="translate(5.53 13.252)">
                        <g transform="translate(0 0)">
                            <rect x="123.1" y="294.58" width="22.42" height="59.79" />
                        </g>
                    </g>
                    <g transform="translate(1.833 9.625)">
                        <g>
                            <path d="M72.86,245.89c-5.88,0-10.64-4.76-10.64-10.64v-21.3h-21.3v21.3      c0,17.64,14.3,31.94,31.94,31.94l0,0h21.3v-21.3H72.86z" />
                        </g>
                    </g>
                    <g transform="translate(1.668 7.782)">
                        <g transform="translate(0 0)">
                            <rect x="37.25" y="172.98" width="22.42" height="22.42" />
                        </g>
                    </g>
                    <g transform="translate(8.249 8.249)">
                        <g>
                            <path d="M381.01,217.26c-45.17-45.18-118.41-45.19-163.6-0.02c-45.18,45.17-45.19,118.42-0.02,163.6      s118.42,45.19,163.6,0.02c0.01-0.01,0.01-0.01,0.02-0.02C426.17,335.66,426.17,262.43,381.01,217.26z M299.15,393.42      c-52.12-0.01-94.37-42.28-94.36-94.4s42.28-94.37,94.4-94.36c52.12,0.01,94.37,42.28,94.36,94.4c0,0,0,0,0,0      C393.54,351.19,351.27,393.44,299.15,393.42z" />
                        </g>
                    </g>
                    <g transform="translate(16.927 16.927)">
                        <g>
                            <path d="M491.14,446.19l-66.83-66.81c-4.15-4.16-10.89-4.16-15.05-0.01c0,0,0,0-0.01,0.01      l-29.71,29.71c-4.16,4.15-4.16,10.89-0.01,15.05c0,0,0,0,0.01,0.01l66.83,66.83c2,1.99,4.7,3.11,7.53,3.11l0,0      c2.83-0.01,5.54-1.15,7.53-3.16l29.71-29.71C495.29,457.06,495.29,450.34,491.14,446.19z M453.98,468.39l-51.78-51.78      l14.66-14.66l51.78,51.78L453.98,468.39z" />
                        </g>
                    </g>
                    <g transform="translate(16.102 16.102)">
                        <g transform="translate(0 0)">
                            <rect
                                x="373.54"
                                y="357.76"
                                transform="matrix(0.7071 -0.7071 0.7071 0.7071 -159.0094 384.1385)"
                                width="21.3"
                                height="52.5"
                            />
                        </g>
                    </g>
                    <g transform="translate(13.291 10.083)">
                        <g>
                            <path d="M295.61,224.13v21.3c29.39,0.03,53.21,23.85,53.24,53.24h21.3      C370.12,257.52,336.77,224.17,295.61,224.13z" />
                        </g>
                    </g>
                    <g transform="translate(4.564 15.505)">
                        <g transform="translate(0 0)">
                            <rect x="101.62" y="344.66" width="82.23" height="22.42" />
                        </g>
                    </g>
                    <g transform="translate(5.5 1.833)">
                        <g>
                            <path d="M154.37,40.75c-17.64,0-31.94,14.3-31.94,31.94s14.3,31.94,31.94,31.94      s31.94-14.3,31.94-31.94l0,0C186.31,55.05,172.01,40.75,154.37,40.75z M154.37,83.32c-5.88,0-10.64-4.76-10.64-10.64      s4.76-10.64,10.64-10.64c5.88,0,10.64,4.76,10.64,10.64c0,0,0,0,0,0C165.01,78.56,160.25,83.32,154.37,83.32z" />
                        </g>
                    </g>
                    <g transform="translate(8.708 5.042)">
                        <g>
                            <path d="M225.68,112.08c-17.64,0-31.94,14.3-31.94,31.94s14.3,31.94,31.94,31.94      s31.94-14.3,31.94-31.94S243.32,112.08,225.68,112.08L225.68,112.08z M225.68,154.66c-5.88,0-10.64-4.76-10.64-10.64      c0-5.88,4.76-10.64,10.64-10.64c5.88,0,10.64,4.76,10.64,10.64v0C236.32,149.89,231.56,154.66,225.68,154.66z" />
                        </g>
                    </g>
                    <g transform="translate(3.801 3.343)">
                        <g transform="translate(0 0)">
                            <rect
                                x="81.17"
                                y="97.81"
                                transform="matrix(0.7071 -0.7071 0.7071 0.7071 -41.8976 115.7809)"
                                width="75.28"
                                height="21.3"
                            />
                        </g>
                    </g>
                    <g transform="translate(7.007 3.344)">
                        <g transform="translate(0 0)">
                            <rect
                                x="179.43"
                                y="70.83"
                                transform="matrix(0.7071 -0.7071 0.7071 0.7071 -21.0289 166.1752)"
                                width="21.3"
                                height="75.28"
                            />
                        </g>
                    </g>
                    <g transform="translate(11.916 1.833)">
                        <g>
                            <path d="M296.99,40.75c-17.64,0-31.94,14.3-31.94,31.94s14.3,31.94,31.94,31.94      c17.64,0,31.94-14.3,31.94-31.94l0,0C328.93,55.05,314.63,40.75,296.99,40.75z M296.99,83.32c-5.88,0-10.64-4.76-10.64-10.64      c0-5.88,4.76-10.64,10.64-10.64c5.88,0,10.64,4.76,10.64,10.64C307.63,78.56,302.87,83.32,296.99,83.32L296.99,83.32z" />
                        </g>
                    </g>
                    <g transform="translate(10.217 3.344)">
                        <g transform="translate(0 0)">
                            <rect
                                x="223.79"
                                y="97.84"
                                transform="matrix(0.7071 -0.7071 0.7071 0.7071 -0.1408 216.6351)"
                                width="75.28"
                                height="21.3"
                            />
                        </g>
                    </g>
                </g>
            </g>
        </SVGIcon>
    );
}
