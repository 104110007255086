import React from 'react';
import { Div } from '../index';

interface InputProps {
    type?: string;
    reference?: any;
    value?: string;
    defaultValue?: any;
    name?: string;
    placeholder?: string;
    onBlur?: any;
    disabled?: boolean;
    readOnly?: boolean;
    variant?: any;
    autoFocus?: any;
    error?: any;
    required?: boolean;
    id?: string;
    onChange?: any;
    onClick?: any;
    onKeyUp?: any;
    className?: string;
    errorMessage?: string;
    min?: number;
    max?: number;
    exactAfterValue?: string;
    autoComplete?: 'off';
    showBefore?: boolean;
    props?: boolean;
    dataPrivate?: boolean;
}

const Input: React.FC<InputProps> = ({
    type,
    reference,
    value,
    defaultValue,
    name,
    placeholder,
    onBlur,
    disabled,
    readOnly,
    variant,
    autoFocus,
    error,
    required,
    id,
    onChange,
    onClick,
    onKeyUp,
    className,
    errorMessage,
    min,
    max,
    exactAfterValue,
    autoComplete = 'new-password',
    showBefore,
    dataPrivate = 'false',
    ...props
}) => {
    return (
        <Div
            className={
                'DCInputWrapper ' +
                (className ? className : '') +
                (exactAfterValue ? 'DCIEOH' : '') +
                (type === 'range' ? 'DCIWRange' : '')
            }
        >
            <input
                className={
                    'DCInput ' +
                    (variant ? variant : '') +
                    ' ' +
                    (error === true ? 'inputError' : '') +
                    ' '
                }
                {...props}
                type={type}
                name={name}
                id={id}
                ref={reference}
                value={value == null ? '' : value}
                defaultValue={defaultValue}
                placeholder={placeholder}
                autoFocus={autoFocus}
                onChange={onChange}
                onClick={onClick}
                onKeyUp={onKeyUp}
                required={required}
                disabled={disabled}
                onBlur={onBlur}
                readOnly={readOnly}
                min={min}
                max={max}
                autoComplete={autoComplete}
                data-private={dataPrivate}
                style={{
                    paddingLeft:
                        exactAfterValue && value !== undefined && showBefore
                            ? `${exactAfterValue.toString()?.length * 7 + 15}px`
                            : '',
                }}
            />
            {exactAfterValue && value !== undefined ? (
                <Div
                    className={'DCInputExact ' + (showBefore ? 'DCIEBefore' : '')}
                    style={{ left: `${!showBefore ? value.toString()?.length * 7 + 17 : 10}px` }}
                >
                    {exactAfterValue}
                </Div>
            ) : null}
            {error === true ? (
                <Div className={'DCInputError'}>{errorMessage || 'Value is not valid'}</Div>
            ) : null}
        </Div>
    );
};

export default Input;
