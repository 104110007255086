import React from 'react';

import { SVGIcon } from '../../UIKit/index';

export default function DCAnniversary(props) {
    return (
        <SVGIcon {...props} viewBox="0 0 520 520">
            <g id="_x35_36_x2C__Anniversary_x2C__Congratulation_x2C__Lunching_x2C__New_x2C__Product">
                <g>
                    <path d="m508.32 304.453-40-60c-1.854-2.782-4.977-4.453-8.32-4.453-15.479 0-383.668 0-400 0-3.344 0-6.466 1.671-8.32 4.453l-40 60c-4.421 6.631.34 15.547 8.32 15.547h30v180c0 5.522 4.477 10 10 10h400c5.522 0 10-4.478 10-10v-180h30c7.968 0 12.748-8.906 8.32-15.547zm-442.967-44.453h175.962l-26.667 40c-5.335 0-164.16 0-175.962 0zm184.647 230h-180v-170h150c3.344 0 6.466-1.671 8.32-4.453l21.68-32.52zm200 0h-180v-206.973l21.68 32.52c1.854 2.782 4.977 4.453 8.32 4.453h150zm-144.648-190-26.667-40h175.964l26.666 40c-35.004 0-134.4 0-175.963 0z" />
                    <path d="m170 410h-70c-5.523 0-10 4.478-10 10s4.477 10 10 10h70c5.523 0 10-4.478 10-10s-4.477-10-10-10z" />
                    <path d="m170 450h-70c-5.523 0-10 4.478-10 10s4.477 10 10 10h70c5.523 0 10-4.478 10-10s-4.477-10-10-10z" />
                    <path d="m181.692 84.556 38.886 28.253-14.854 45.713c-1.338 4.12.128 8.634 3.633 11.181 3.504 2.546 8.25 2.546 11.756 0l38.887-28.252 38.887 28.252c1.753 1.273 3.815 1.91 5.878 1.91 6.759 0 11.616-6.61 9.511-13.09l-14.854-45.713 38.887-28.253c3.505-2.547 4.972-7.061 3.633-11.181s-5.179-6.91-9.511-6.91h-47.912l-14.947-49.364c-1.278-4.218-5.166-7.102-9.572-7.102-4.407 0-8.294 2.884-9.571 7.102l-14.948 49.364h-47.911c-4.332 0-8.172 2.79-9.511 6.91-1.338 4.12.128 8.633 3.633 11.18zm61.209 1.91c4.407 0 8.294-2.884 9.571-7.102l7.528-24.86 7.527 24.859c1.277 4.218 5.165 7.102 9.571 7.102h24.556l-19.866 14.433c-3.505 2.547-4.972 7.06-3.633 11.181l7.588 23.353-19.865-14.432c-3.506-2.546-8.25-2.546-11.756 0l-19.866 14.433 7.588-23.353c1.338-4.12-.128-8.634-3.633-11.181l-19.866-14.433z" />
                    <path d="m250 170v40c0 5.523 4.477 10 10 10s10-4.477 10-10v-40c0-5.523-4.478-10-10-10s-10 4.477-10 10z" />
                    <path d="m35.479 141.217-4.724 27.543c-1.4 8.16 7.189 14.39 14.509 10.542l24.736-13.004 24.736 13.004c7.267 3.82 15.917-2.336 14.509-10.542l-4.724-27.543 20.012-19.507c5.925-5.774 2.649-15.867-5.542-17.057l-27.655-4.019-12.368-25.06c-3.662-7.419-14.272-7.421-17.935 0l-12.368 25.06-27.655 4.019c-8.187 1.189-11.47 11.28-5.542 17.057zm21.264-21.547c3.257-.473 6.073-2.519 7.529-5.47l5.728-11.605 5.728 11.605c1.457 2.951 4.272 4.997 7.529 5.47l12.807 1.861-9.267 9.033c-2.357 2.297-3.433 5.607-2.876 8.851l2.188 12.754-11.455-6.021c-2.912-1.532-6.394-1.532-9.307 0l-11.455 6.021 2.188-12.754c.557-3.244-.519-6.554-2.876-8.851l-9.267-9.033z" />
                    <path d="m413.031 141.217-4.724 27.543c-1.396 8.154 7.187 14.395 14.51 10.542l24.735-13.004 24.736 13.004c7.325 3.851 15.908-2.389 14.51-10.542l-4.725-27.543 20.012-19.507c5.924-5.774 2.647-15.867-5.542-17.057l-27.656-4.019-12.367-25.06c-3.662-7.419-14.272-7.421-17.936 0l-12.367 25.06-27.656 4.019c-8.186 1.189-11.469 11.28-5.542 17.057zm21.266-21.547c3.257-.473 6.072-2.519 7.529-5.47l5.727-11.604 5.727 11.604c1.457 2.951 4.272 4.997 7.529 5.47l12.808 1.861-9.268 9.033c-2.356 2.297-3.432 5.607-2.876 8.851l2.188 12.754-11.455-6.022c-2.914-1.531-6.395-1.532-9.307 0l-11.454 6.021 2.188-12.754c.556-3.244-.52-6.554-2.876-8.851l-9.268-9.033z" />
                    <path d="m330.001 220.003c4.188 0 8.089-2.65 9.486-6.841l40-120c1.746-5.239-1.086-10.902-6.325-12.649-5.238-1.746-10.902 1.086-12.649 6.325l-40 120c-2.164 6.494 2.697 13.165 9.488 13.165z" />
                    <path d="m180.514 213.162c1.397 4.191 5.298 6.841 9.485 6.841 6.792 0 11.653-6.671 9.488-13.165l-40-120c-1.747-5.24-7.41-8.071-12.649-6.325-5.24 1.747-8.071 7.41-6.325 12.649z" />
                    <path d="m112.929 182.929c-3.905 3.905-3.905 10.237 0 14.143l20 20c3.906 3.905 10.237 3.904 14.143 0 3.905-3.905 3.905-10.237 0-14.143l-20-20c-3.905-3.905-10.238-3.905-14.143 0z" />
                    <path d="m392.929 182.929-20 20c-3.905 3.905-3.905 10.237 0 14.143 3.907 3.905 10.236 3.904 14.143 0l20-20c3.905-3.905 3.905-10.237 0-14.143-3.907-3.905-10.237-3.905-14.143 0z" />
                </g>
            </g>
        </SVGIcon>
    );
}
