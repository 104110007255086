import React from 'react';

import { Div, CircularLoader } from 'UIKit/index';

import './style.scss';

const RedirectPage = () => {
    return (
        <Div className={'RedirectPage'}>
            <Div className={'RedirectPageInner'}>
                <Div className={'RPIContent'}>
                    <Div className={'RPICLoader'}>
                        <CircularLoader />
                    </Div>
                    <Div className={'RPICTitle'}>Redirecting you to Login</Div>
                </Div>
            </Div>
        </Div>
    );
};

export default RedirectPage;
