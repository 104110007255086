import React from 'react';

import { SVGIcon } from '../../UIKit/index';

export default function DCSalesPipelines(props) {
    return (
        <SVGIcon {...props} viewBox="0 0 501.32 497.33">
            <defs>
                <clipPath id="clip-sales-pipelines">
                    <path
                        id="Path_28"
                        data-name="Path 28"
                        d="M431.79,108.4c-.66-.67-2.09-3.25-3.32-9.92a136,136,0,0,1-1.84-23.55,136,136,0,0,1,1.84-23.56c1.23-6.67,2.66-9.25,3.32-9.92.65.67,2.09,3.25,3.32,9.92,1.18,6.45,10.55,14.82,10.55,23.56S436.29,92,435.11,98.48C433.88,105.15,432.44,107.73,431.79,108.4Z"
                        fill="none"
                    />
                </clipPath>
                <linearGradient
                    id="linear-sales-pipelines"
                    x1="0.501"
                    y1="-14.713"
                    x2="0.501"
                    y2="-3.15"
                    gradientUnits="objectBoundingBox"
                >
                    <stop offset="0" stopColor="#5560ab" />
                    <stop offset="1" stopColor="#4c57a6" />
                </linearGradient>
            </defs>
            <g id="Sales_Pipelines" transform="translate(-5.29 -7.58)">
                <circle
                    id="Ellipse_1"
                    data-name="Ellipse 1"
                    cx="24.23"
                    cy="24.23"
                    r="24.23"
                    transform="translate(344.65 7.58)"
                    fill="#f4f5f6"
                />
                <ellipse
                    id="_Path_"
                    data-name="&lt;Path&gt;"
                    cx="250.66"
                    cy="36.38"
                    rx="250.66"
                    ry="36.38"
                    transform="translate(5.29 432.15)"
                    fill="#f4f5f6"
                />
                <path
                    id="Path_1"
                    data-name="Path 1"
                    d="M199.41,213.63H87.35a7,7,0,0,1-6.95-6L67.35,120.8a3.41,3.41,0,0,1,3.37-3.91H199.41Z"
                    fill="#e8e8e8"
                />
                <path
                    id="Path_2"
                    data-name="Path 2"
                    d="M154.87,172.52H92.16a.94.94,0,0,1-.92-.78l-2.18-12.91a.94.94,0,0,1,.92-1.09h61.07a.93.93,0,0,1,.92.78l3.6,13.08a.73.73,0,0,1-.7.92Z"
                    fill="#f4f5f6"
                />
                <path
                    id="Path_3"
                    data-name="Path 3"
                    d="M159,187.77a.77.77,0,0,1-.74,1H94.75a.77.77,0,0,1-.76-.64L92.21,177.5a.76.76,0,0,1,.76-.89H155a1,1,0,0,1,1,.75Z"
                    fill="#f4f5f6"
                />
                <path
                    id="Path_4"
                    data-name="Path 4"
                    d="M150.87,153.91H89.37a1.52,1.52,0,0,1-1.5-1.25L86.29,144a1.53,1.53,0,0,1,1.5-1.81h60.12a1.52,1.52,0,0,1,1.5,1.28l2.35,9.29a.91.91,0,0,1-.89,1.15Z"
                    fill="#f4f5f6"
                />
                <path
                    id="Path_5"
                    data-name="Path 5"
                    d="M240.12,336.89H128.06a7,7,0,0,1-7-6l-13-86.86a3.4,3.4,0,0,1,3.36-3.91h128.7Z"
                    fill="#e4e3e3"
                />
                <path
                    id="Path_6"
                    data-name="Path 6"
                    d="M187.8,295.75H125.1a.94.94,0,0,1-.93-.78L122,282.06a.94.94,0,0,1,.92-1.09H184a.94.94,0,0,1,.92.78l3.59,13.08a.73.73,0,0,1-.71.92Z"
                    fill="#f4f5f6"
                />
                <path
                    id="Path_7"
                    data-name="Path 7"
                    d="M192,311a.77.77,0,0,1-.74,1H127.69a.78.78,0,0,1-.77-.64l-1.78-10.61a.77.77,0,0,1,.76-.89H188a1.05,1.05,0,0,1,1,.76Z"
                    fill="#f4f5f6"
                />
                <path
                    id="Path_8"
                    data-name="Path 8"
                    d="M183.81,277.15h-61.5a1.53,1.53,0,0,1-1.51-1.26l-1.58-8.64a1.53,1.53,0,0,1,1.5-1.8h60.12a1.53,1.53,0,0,1,1.51,1.27l2.34,9.29a.91.91,0,0,1-.88,1.14Z"
                    fill="#f4f5f6"
                />
                <path
                    id="Path_9"
                    data-name="Path 9"
                    d="M274.86,460.14H164.29a8.76,8.76,0,0,1-8.66-7.449l-12.5-83.221a5.28,5.28,0,0,1,5.22-6.07H274.86Z"
                    fill="#707272"
                />
                <path
                    id="Path_10"
                    data-name="Path 10"
                    d="M224.2,418.81H161.49a.94.94,0,0,1-.92-.78l-2.18-12.91a.94.94,0,0,1,.92-1.09h61.07a.94.94,0,0,1,.93.78l3.59,13.08a.73.73,0,0,1-.7.92Z"
                    fill="#f4f5f6"
                />
                <path
                    id="Path_11"
                    data-name="Path 11"
                    d="M228.36,434.06a.77.77,0,0,1-.74,1H164.08a.77.77,0,0,1-.76-.64l-1.78-10.61a.77.77,0,0,1,.76-.89h62.06a1,1,0,0,1,1,.75Z"
                    fill="#f4f5f6"
                />
                <path
                    id="Path_12"
                    data-name="Path 12"
                    d="M220.21,400.2h-61.5a1.52,1.52,0,0,1-1.51-1.2l-1.58-8.64a1.53,1.53,0,0,1,1.5-1.81h60.12a1.54,1.54,0,0,1,1.51,1.28l2.34,9.29a.91.91,0,0,1-.88,1.08Z"
                    fill="#f4f5f6"
                />
                <path
                    id="Path_13"
                    data-name="Path 13"
                    d="M381.92,356c0,2.75-37.88,8.14-85.15,8.14s-84-6-84-8.76S249.05,351,296.32,351,381.92,353.27,381.92,356Z"
                    fill="#595a5a"
                />
                <path
                    id="Path_14"
                    data-name="Path 14"
                    d="M417.25,232.92c0,3.48-59.38,12.07-126.09,12.07s-112.44-8.38-112.44-11.86,51.5-6,118.21-6S417.25,229.44,417.25,232.92Z"
                    fill="#707272"
                />
                <path
                    id="Path_15"
                    data-name="Path 15"
                    d="M373.79,341.45a685,685,0,0,1-155-.11,15.94,15.94,0,0,1-13.47-11.26L176.92,235a1.46,1.46,0,0,1,1.8-1.82c38.08,10.66,204.53,11.53,237.35-.16a.88.88,0,0,1,1.14,1.08l-28.52,95a17.59,17.59,0,0,1-14.9,12.35Z"
                    fill="#e4e3e3"
                />
                <path
                    id="Path_16"
                    data-name="Path 16"
                    d="M338.62,464.64a298.069,298.069,0,0,1-84.79-.15,16.28,16.28,0,0,1-13.3-11.44l-28.68-96a1.32,1.32,0,0,1,1.48-1.68c53.65,8.58,117.86,8.14,166.39.12a1.73,1.73,0,0,1,1.93,2.21L353.35,452A18,18,0,0,1,338.62,464.64Z"
                    fill="#707272"
                />
                <path
                    id="Path_17"
                    data-name="Path 17"
                    d="M449.81,109.54c0,4.06-67.26,7.35-152.7,7.35s-152.69-3.29-152.69-7.35,67.26-7.35,152.69-7.35S449.81,105.48,449.81,109.54Z"
                    fill="#e4e3e3"
                />
                <path
                    id="Path_18"
                    data-name="Path 18"
                    d="M207.43,125.74a50.23,50.23,0,0,0,66.84-33L226,79.07Z"
                    fill="#f4f5f6"
                />
                <path
                    id="Path_19"
                    data-name="Path 19"
                    d="M274.27,92.7a50.373,50.373,0,0,0-2.48-34.11L226,79.07Z"
                    fill="#e4e3e3"
                />
                <path
                    id="Path_20"
                    data-name="Path 20"
                    d="M271.79,58.59A50.23,50.23,0,0,0,249,34.49L226,79.07Z"
                    fill="#e4e3e3"
                />
                <path
                    id="Path_21"
                    data-name="Path 21"
                    d="M221.67,80l-30-40.27a50.21,50.21,0,0,0,11.47,86.93Z"
                    fill="#707272"
                />
                <path
                    id="Path_22"
                    data-name="Path 22"
                    d="M249,34.49a50.649,50.649,0,0,0-14.22-4.84c-3,18.86-8.86,49.42-8.86,49.42Z"
                    fill="#e4e3e3"
                />
                <path
                    id="Path_23"
                    data-name="Path 23"
                    d="M234.81,29.65A50.29,50.29,0,0,0,196,38.81l30,40.26S231.85,48.51,234.81,29.65Z"
                    fill="#e4e3e3"
                />
                <path
                    id="Path_24"
                    data-name="Path 24"
                    d="M341,88.75s11.92,31.43,15.68,38c4.37,7.59,17.14,2.76,16.5-3.6s-15.55-32.6-15.55-32.6Z"
                    fill="#707272"
                />
                <path
                    id="Path_25"
                    data-name="Path 25"
                    d="M357.09,65.86l74.08-25.69-.78,69.5-75.32-16.8Z"
                    fill="#e4e3e3"
                />
                <ellipse
                    id="Ellipse_2"
                    data-name="Ellipse 2"
                    cx="7.28"
                    cy="35.72"
                    rx="7.28"
                    ry="35.72"
                    transform="translate(423.95 39.41)"
                    fill="#707272"
                />
                <path
                    id="Path_26"
                    data-name="Path 26"
                    d="M431.79,108.4c-.66-.67-2.09-3.25-3.32-9.92a136,136,0,0,1-1.84-23.55,136,136,0,0,1,1.84-23.56c1.23-6.67,2.66-9.25,3.32-9.92.65.67,2.09,3.25,3.32,9.92A137,137,0,0,1,437,74.93a137,137,0,0,1-1.84,23.55C433.88,105.15,432.44,107.73,431.79,108.4Z"
                    fill="#e4e3e3"
                />
                <g id="Group_1" data-name="Group 1" clipPath="url(#clip-sales-pipelines)">
                    <path
                        id="Path_27"
                        data-name="Path 27"
                        d="M441.11,74.66c0,6.07-3.26,11-9.74,11s-11.71-4.92-11.71-11,5.24-11,11.71-11S441.11,68.59,441.11,74.66Z"
                        fill="#707272"
                    />
                </g>
                <path
                    id="Path_29"
                    data-name="Path 29"
                    d="M345.15,64.36a41.54,41.54,0,0,1,13.88.69,74.09,74.09,0,0,0,.55,29H344.34a4.93,4.93,0,0,1-4.88-4.16c-.76-4.81-1.69-12.89-.7-19.39A7.31,7.31,0,0,1,345.15,64.36Z"
                    fill="#707272"
                />
                <path
                    id="Path_30"
                    data-name="Path 30"
                    d="M354.29,71.48H342.42a1.115,1.115,0,0,1-.95-1.67.31.31,0,0,1,.42-.09.32.32,0,0,1,.09.43.46.46,0,0,0,.4.72h11.87a.47.47,0,0,0,.47-.47v-.84a.47.47,0,0,0-.47-.47h-12a.31.31,0,0,1,0-.62h12a1.08,1.08,0,0,1,1.08,1.09v.84a1.08,1.08,0,0,1-1.04,1.08Z"
                    fill="#e4e3e3"
                />
                <path
                    id="Path_31"
                    data-name="Path 31"
                    d="M353.23,76.74H341.36a1.08,1.08,0,0,1-.91-1.67.31.31,0,0,1,.42-.09.3.3,0,0,1,.09.42.48.48,0,0,0,0,.49.45.45,0,0,0,.41.24h11.87a.47.47,0,0,0,.47-.47v-.84a.47.47,0,0,0-.47-.48h-12a.29.29,0,0,1-.3-.3.3.3,0,0,1,.3-.31h12a1.08,1.08,0,0,1,1.08,1.09v.84a1.08,1.08,0,0,1-1.09,1.08Z"
                    fill="#e4e3e3"
                />
                <path
                    id="Path_32"
                    data-name="Path 32"
                    d="M367.86,64a.33.33,0,0,1-.3-.22.3.3,0,0,1,.2-.38l56.1-17.85a.31.31,0,0,1,.39.2.3.3,0,0,1-.2.38L368,64Z"
                    fill="#707272"
                />
                <path
                    id="Path_33"
                    data-name="Path 33"
                    d="M360.27,67.08a.29.29,0,0,1-.28-.19.3.3,0,0,1,.16-.4L363,65.31a.31.31,0,0,1,.4.17.3.3,0,0,1-.17.39l-2.89,1.18a.2.2,0,0,1-.07.03Z"
                    fill="#707272"
                />
                <path
                    id="Path_34"
                    data-name="Path 34"
                    d="M354.29,82.9H342.42a1.115,1.115,0,0,1-.95-1.67.3.3,0,0,1,.51.33.48.48,0,0,0,0,.48.47.47,0,0,0,.42.25h11.87a.47.47,0,0,0,.47-.47V81a.47.47,0,0,0-.47-.47h-12a.31.31,0,0,1,0-.61h12a1.08,1.08,0,0,1,1.1,1.08v.85a1.08,1.08,0,0,1-1.08,1.05Z"
                    fill="#e4e3e3"
                />
                <path
                    id="Path_35"
                    data-name="Path 35"
                    d="M355.4,89.74H343.53a1.09,1.09,0,0,1-.91-1.68.31.31,0,1,1,.51.34.46.46,0,0,0,0,.48.45.45,0,0,0,.41.24H355.4a.47.47,0,0,0,.47-.47v-.84a.47.47,0,0,0-.47-.47h-12a.3.3,0,0,1-.3-.31.29.29,0,0,1,.3-.3h12a1.08,1.08,0,0,1,1.08,1.08v.84a1.08,1.08,0,0,1-1.08,1.09Z"
                    fill="#e4e3e3"
                />
                <path
                    id="Path_36"
                    data-name="Path 36"
                    d="M366,126.9a7.88,7.88,0,0,1-1.66-.18,7.67,7.67,0,0,1-5.39-4.37l-9.48-21.07a.3.3,0,0,1,.15-.4.29.29,0,0,1,.4.15l9.49,21.07a7,7,0,0,0,5,4,6.89,6.89,0,0,0,6.07-1.55l.27-.24a.3.3,0,1,1,.41.45L371,125a7.47,7.47,0,0,1-5,1.9Z"
                    fill="#e4e3e3"
                />
                <path
                    id="Path_37"
                    data-name="Path 37"
                    d="M348.35,98.34a.29.29,0,0,1-.27-.18L347,95.81a.3.3,0,0,1,.55-.25l1.06,2.35a.31.31,0,0,1-.15.41Z"
                    fill="#e4e3e3"
                />
                <path
                    id="Path_38"
                    data-name="Path 38"
                    d="M439.38,74.34a.3.3,0,0,1-.3-.28c-.22-3-1.25-5.19-3.07-6.59-3.15-2.42-7.64-1.59-7.69-1.58a.305.305,0,1,1-.11-.6c.19,0,4.81-.89,8.17,1.7,2,1.51,3.08,3.87,3.3,7a.29.29,0,0,1-.28.32Z"
                    fill="#e4e3e3"
                />
                <path
                    id="Path_39"
                    data-name="Path 39"
                    d="M316.15,96.69c.12-.53-.28-1.13-1.06-1.75,1.13-.25,1.83-.66,2-1.23.37-1.73-4.71-4.28-11.34-5.69s-12.31-1.15-12.67.58c-.12.52.28,1.13,1.06,1.75-1.13.24-1.83.65-1.95,1.22s.28,1.13,1.06,1.75c-1.13.25-1.83.66-1.95,1.23s.28,1.13,1.06,1.75c-1.12.24-1.83.65-1.95,1.23-.16.76.74,1.69,2.37,2.61a13.821,13.821,0,0,0-.5,1.84c-.68,3.19,2.1,6.69,6.74,8.07s9.65-.75,10.63-3.86a16.913,16.913,0,0,0,.52-2.07c2.44-.08,4.06-.58,4.26-1.48.11-.53-.28-1.13-1.07-1.75,1.13-.24,1.83-.65,1.95-1.23s-.28-1.12-1.06-1.75C315.33,97.67,316,97.26,316.15,96.69Z"
                    fill="#707272"
                />
                <path
                    id="Path_40"
                    data-name="Path 40"
                    d="M298.12,102.48a30,30,0,0,1-5-2,.17.17,0,0,1-.07-.22.16.16,0,0,1,.22-.07,34.78,34.78,0,0,0,8.47,2.82,38.83,38.83,0,0,0,7.55.91.16.16,0,1,1,0,.32,38.54,38.54,0,0,1-7.61-.91A30.413,30.413,0,0,1,298.12,102.48Z"
                    fill="#98b1dc"
                />
                <path
                    id="Path_41"
                    data-name="Path 41"
                    d="M299,99.51a25.54,25.54,0,0,1-6.1-2.57.16.16,0,0,1,.17-.27,31.47,31.47,0,0,0,9.51,3.42,31.87,31.87,0,0,0,10,.76.18.18,0,0,1,.19.14.16.16,0,0,1-.14.18,32,32,0,0,1-10.13-.77C301.34,100.15,300.16,99.84,299,99.51Z"
                    fill="#98b1dc"
                />
                <path
                    id="Path_42"
                    data-name="Path 42"
                    d="M299.9,96.53a23.721,23.721,0,0,1-6.41-2.79.15.15,0,0,1,0-.22.14.14,0,0,1,.22,0c2,1.38,5.67,2.74,9.84,3.63a31.57,31.57,0,0,0,10,.76.18.18,0,0,1,.19.14.16.16,0,0,1-.14.18,32,32,0,0,1-10.13-.76C302.21,97.17,301,96.86,299.9,96.53Z"
                    fill="#98b1dc"
                />
                <path
                    id="Path_43"
                    data-name="Path 43"
                    d="M300.81,93.56a27.86,27.86,0,0,1-5.45-2.19.15.15,0,0,1-.06-.22.17.17,0,0,1,.22-.06,33.269,33.269,0,0,0,8.88,3,31.55,31.55,0,0,0,10,.77.16.16,0,0,1,.18.14.18.18,0,0,1-.14.19,32.57,32.57,0,0,1-10.07-.78A32.017,32.017,0,0,1,300.81,93.56Z"
                    fill="#98b1dc"
                />
                <path
                    id="Path_44"
                    data-name="Path 44"
                    d="M292.84,73.36a22.75,22.75,0,0,0-1.63-8.82,52.81,52.81,0,0,1-2.49-31.49c4.73-19.95,25.06-24.58,40.54-18.51s27.61,22.58,20.44,40.31c-4.4,10.92-13.23,17.56-19.37,21.05a27.09,27.09,0,0,0-9.54,9.19c-2.92,4.65-8.15,10-16.21,8C294.15,90.51,292.75,79.65,292.84,73.36Z"
                    fill="#e4e3e3"
                />
                <path
                    id="Path_45"
                    data-name="Path 45"
                    d="M308.38,90l16.34-34.47c.34-.71.85-1.1,1.15-.88s.27,1-.06,1.71v.06l-17,33.9c-.13.26-.31.39-.42.29A.573.573,0,0,1,308.38,90Z"
                    fill="#707272"
                />
                <path
                    id="Path_46"
                    data-name="Path 46"
                    d="M302.52,88.51l5.24-37.78c.11-.78-.11-1.4-.49-1.37s-.77.68-.88,1.46v.07L302,88.57c0,.29.06.5.2.47S302.49,88.79,302.52,88.51Z"
                    fill="#707272"
                />
                <path
                    id="Path_47"
                    data-name="Path 47"
                    d="M325.61,52.36a.62.62,0,0,0-.68-.4l-3.52.52-.16-3.38a.69.69,0,0,0-.26-.49.8.8,0,0,0-1,0h0L316,52.5l-.39-7.3h0a.78.78,0,0,0-.24-.51,1,1,0,0,0-1.23-.12l-5,3.83a.45.45,0,0,0-.06.66.63.63,0,0,0,.78.14L314,46.63l.94,7.12h0a.48.48,0,0,0,.18.32.62.62,0,0,0,.79,0l4-3.56.37,2.52h0a.63.63,0,0,0,.68.41l4.18-.62a.46.46,0,0,0,.47-.46Z"
                    fill="#707272"
                />
                <path
                    id="Path_48"
                    data-name="Path 48"
                    d="M331.75,71.57a1.07,1.07,0,0,1-.53-.37,1,1,0,0,1,.21-1.46,40.641,40.641,0,0,0,3.72-3.12,1.036,1.036,0,0,1,1.42,1.51,44.745,44.745,0,0,1-3.9,3.28,1,1,0,0,1-.92.16Z"
                    fill="#fff"
                    opacity="0.49"
                />
                <path
                    id="Path_49"
                    data-name="Path 49"
                    d="M338.66,65.09a1.015,1.015,0,0,1-.49-1.66,34,34,0,0,0,8.14-24.52,1.039,1.039,0,0,1,2.06-.28c.08.55,1.8,13.77-8.61,26.14A1.05,1.05,0,0,1,338.66,65.09Z"
                    fill="#707272"
                />
                <path
                    id="Path_50"
                    data-name="Path 50"
                    d="M368.88,47a15.2,15.2,0,1,1,15.2-15.19A15.2,15.2,0,0,1,368.88,47Zm0-27.84a12.65,12.65,0,1,0,12.65,12.65,12.65,12.65,0,0,0-12.65-12.65Z"
                    fill="#e4e3e3"
                />
                <path
                    id="Path_51"
                    data-name="Path 51"
                    d="M364.12,37.66V26.73a1.51,1.51,0,0,1,2.28-1.29l9.2,5.46a1.5,1.5,0,0,1,0,2.59L366.4,39a1.51,1.51,0,0,1-2.28-1.34Z"
                    fill="#e4e3e3"
                    fillRule="evenodd"
                />
                <circle
                    id="Ellipse_3"
                    data-name="Ellipse 3"
                    cx="25.47"
                    cy="25.47"
                    r="25.47"
                    transform="translate(113.06 42.93)"
                    fill="#f4f5f6"
                />
                <path
                    id="Path_52"
                    data-name="Path 52"
                    d="M138.53,86.2a4.25,4.25,0,0,1-3.66-2.07c-4.41-7.28-9.67-16.88-9.67-21.22,0-6.78,6-12.3,13.33-12.3s13.33,5.52,13.33,12.3c0,4.34-5.25,13.94-9.66,21.22a4.26,4.26,0,0,1-3.67,2.07Zm0-33.87c-6.4,0-11.61,4.75-11.61,10.58,0,3,3.43,10.44,9.42,20.33a2.56,2.56,0,0,0,4.39,0c6-9.89,9.42-17.29,9.42-20.33,0-5.83-5.21-10.58-11.62-10.58Z"
                    fill="#e4e3e3"
                />
                <path
                    id="Path_53"
                    data-name="Path 53"
                    d="M137.5,71.5a8.27,8.27,0,0,1-8.26-8.26.86.86,0,1,1,1.71,0,6.54,6.54,0,1,0,1.3-3.92.86.86,0,1,1-1.38-1,8.27,8.27,0,1,1,6.63,13.2Z"
                    fill="#e4e3e3"
                />
                <path
                    id="Path_54"
                    data-name="Path 54"
                    d="M401.68,218.1c-70.13,4.57-141.67,4.26-214.47-.41a19.89,19.89,0,0,1-17.84-14.4l-26.08-91.21a2.18,2.18,0,0,1,2.3-2.76c99.07,9.53,200.12,9.28,303,0a2.19,2.19,0,0,1,2.3,2.79l-25,86.52a27,27,0,0,1-24.21,19.47Z"
                    fill="#e8e8e8"
                />
                <circle
                    id="Ellipse_4"
                    data-name="Ellipse 4"
                    cx="35.41"
                    cy="35.41"
                    r="35.41"
                    transform="translate(322.232 366.907) rotate(-67.5)"
                    fill="#707272"
                />
                <circle
                    id="Ellipse_5"
                    data-name="Ellipse 5"
                    cx="34.24"
                    cy="34.24"
                    r="34.24"
                    transform="matrix(0.634, -0.773, 0.773, 0.634, 320.317, 352.548)"
                    fill="#e4e3e3"
                />
                <path
                    id="Path_55"
                    data-name="Path 55"
                    d="M340.27,334.31a.729.729,0,0,1-.35-.09.77.77,0,0,1-.35-1.05,32.48,32.48,0,0,1,34.77-17.29.79.79,0,0,1-.28,1.55,31.237,31.237,0,0,0-5.56-.5A30.69,30.69,0,0,0,341,333.88a.79.79,0,0,1-.73.43Z"
                    fill="#707272"
                />
                <circle
                    id="Ellipse_6"
                    data-name="Ellipse 6"
                    cx="29.029"
                    cy="29.029"
                    r="29.029"
                    transform="translate(334.32 328.62) rotate(-14.452)"
                    fill="#f4f5f6"
                />
                <path
                    id="Path_56"
                    data-name="Path 56"
                    d="M366.4,370v-3.15a25.59,25.59,0,0,1-8.84-1.92,1.81,1.81,0,0,1-1-2.15l.8-2.94a1.83,1.83,0,0,1,2.49-1.2,21.36,21.36,0,0,0,8.17,1.72c3.17,0,5.53-1.08,5.53-3.28,0-2-1.83-3.16-6.23-4.34-6.27-1.72-10.78-4.45-10.78-10,0-5,3.65-8.8,10-9.82v-3.15a1.84,1.84,0,0,1,1.84-1.84h1.85a1.84,1.84,0,0,1,1.83,1.84v2.83a23.32,23.32,0,0,1,7,1.26,1.83,1.83,0,0,1,1.14,2.21l-.74,2.71a1.83,1.83,0,0,1-2.42,1.24,19.089,19.089,0,0,0-6.87-1.25c-3.76,0-4.83,1.56-4.83,2.95,0,1.72,2.25,2.9,7.13,4.4,7,2.09,9.87,5,9.87,10.19,0,4.78-3.54,9.07-10.4,10.14V370a1.84,1.84,0,0,1-1.84,1.84h-1.85A1.84,1.84,0,0,1,366.4,370Z"
                    fill="#707272"
                />
                <rect
                    id="Rectangle_1"
                    data-name="Rectangle 1"
                    width="69.39"
                    height="13.22"
                    rx="3.63"
                    transform="translate(333.8 392.34)"
                    fill="#e4e3e3"
                />
                <rect
                    id="Rectangle_2"
                    data-name="Rectangle 2"
                    width="69.39"
                    height="13.22"
                    rx="3.63"
                    transform="translate(333.86 408.03)"
                    fill="#e4e3e3"
                />
                <rect
                    id="Rectangle_3"
                    data-name="Rectangle 3"
                    width="69.39"
                    height="13.22"
                    rx="3.63"
                    transform="translate(333.91 423.73)"
                    fill="#e4e3e3"
                />
                <rect
                    id="Rectangle_4"
                    data-name="Rectangle 4"
                    width="69.39"
                    height="13.22"
                    rx="3.63"
                    transform="translate(333.97 439.42)"
                    fill="#e4e3e3"
                />
                <rect
                    id="Rectangle_5"
                    data-name="Rectangle 5"
                    width="69.39"
                    height="13.22"
                    rx="3.63"
                    transform="translate(334.02 455.12)"
                    fill="#e4e3e3"
                />
                <circle
                    id="Ellipse_7"
                    data-name="Ellipse 7"
                    cx="35.41"
                    cy="35.41"
                    r="35.41"
                    transform="translate(400.257 383.916) rotate(-69.01)"
                    fill="#707272"
                />
                <circle
                    id="Ellipse_8"
                    data-name="Ellipse 8"
                    cx="34.24"
                    cy="34.24"
                    r="34.24"
                    transform="matrix(0.472, -0.882, 0.882, 0.472, 399.661, 377.558)"
                    fill="#e4e3e3"
                />
                <path
                    id="Path_57"
                    data-name="Path 57"
                    d="M417.8,350.14a.8.8,0,0,1-.36-.09.78.78,0,0,1-.34-1.05,32.48,32.48,0,0,1,34.77-17.29.79.79,0,0,1-.28,1.55,31.237,31.237,0,0,0-5.56-.5,30.68,30.68,0,0,0-27.53,16.95.8.8,0,0,1-.7.43Z"
                    fill="#707272"
                />
                <circle
                    id="Ellipse_9"
                    data-name="Ellipse 9"
                    cx="28.98"
                    cy="28.98"
                    r="28.98"
                    transform="translate(414.472 339.083) rotate(-7.02)"
                    fill="#f4f5f6"
                />
                <path
                    id="Path_58"
                    data-name="Path 58"
                    d="M443.93,385.86v-3.15a25.7,25.7,0,0,1-8.85-1.92,1.81,1.81,0,0,1-1-2.15l.8-2.94a1.83,1.83,0,0,1,2.49-1.2,21.36,21.36,0,0,0,8.17,1.72c3.16,0,5.53-1.08,5.53-3.27,0-2-1.83-3.17-6.23-4.35-6.28-1.72-10.78-4.45-10.78-10,0-5,3.65-8.8,10-9.82v-3.15a1.84,1.84,0,0,1,1.83-1.84h1.86a1.84,1.84,0,0,1,1.83,1.84v2.83a23.249,23.249,0,0,1,7,1.26,1.84,1.84,0,0,1,1.15,2.21l-.74,2.71a1.83,1.83,0,0,1-2.42,1.24,19.14,19.14,0,0,0-6.87-1.25c-3.76,0-4.83,1.56-4.83,3,0,1.72,2.25,2.9,7.13,4.4,7,2.09,9.87,5,9.87,10.19,0,4.78-3.54,9.07-10.4,10.14v3.53a1.84,1.84,0,0,1-1.84,1.84h-1.85a1.84,1.84,0,0,1-1.85-1.87Z"
                    fill="#707272"
                />
                <rect
                    id="Rectangle_6"
                    data-name="Rectangle 6"
                    width="69.39"
                    height="13.22"
                    rx="3.63"
                    transform="translate(411.33 408.17)"
                    fill="#e4e3e3"
                />
                <rect
                    id="Rectangle_7"
                    data-name="Rectangle 7"
                    width="69.39"
                    height="13.22"
                    rx="3.63"
                    transform="translate(411.39 423.86)"
                    fill="#e4e3e3"
                />
                <rect
                    id="Rectangle_8"
                    data-name="Rectangle 8"
                    width="69.39"
                    height="13.22"
                    rx="3.63"
                    transform="translate(411.44 439.56)"
                    fill="#e4e3e3"
                />
                <rect
                    id="Rectangle_9"
                    data-name="Rectangle 9"
                    width="69.39"
                    height="13.22"
                    rx="3.63"
                    transform="translate(411.5 455.26)"
                    fill="#e4e3e3"
                />
                <path
                    id="Path_59"
                    data-name="Path 59"
                    d="M95.56,461.2c-4.1.35-11.21.93-16.81,1.17-3.37.15-5.31-.18-6.43-.63v.36a1.16,1.16,0,0,0,1.1,1.1,197.8,197.8,0,0,0,22,0,.83.83,0,0,0,.67-1.24A5.892,5.892,0,0,0,95.56,461.2Z"
                    fill="#595a5a"
                />
                <path
                    id="Path_60"
                    data-name="Path 60"
                    d="M78.75,462.37c5.6-.24,12.71-.82,16.81-1.17-4-4.94-16.81-8.28-16.81-8.28H75.13a15.14,15.14,0,0,0-2.81,8.82C73.44,462.19,75.38,462.52,78.75,462.37Z"
                    fill="#707272"
                />
                <path
                    id="Path_61"
                    data-name="Path 61"
                    d="M74.53,447l.33,6.69a1.53,1.53,0,0,0,1.31,1.43,5.839,5.839,0,0,0,2.71-.22,1.79,1.79,0,0,0,1.21-1.39L81.26,447Z"
                    fill="#e4e3e3"
                />
                <path
                    id="Path_62"
                    data-name="Path 62"
                    d="M32.62,468.91a3.52,3.52,0,0,1-2.92-2.79h0a3.18,3.18,0,0,0,1.56,3.4,8.06,8.06,0,0,0,4.31.87,4,4,0,0,0,3.23-1.92h0C37.46,469.34,35.58,469.45,32.62,468.91Z"
                    fill="#595a5a"
                />
                <path
                    id="Path_63"
                    data-name="Path 63"
                    d="M41,449.09H38c-4.54-.12-7.39,12.24-8.32,17a3.52,3.52,0,0,0,2.92,2.79c3,.54,4.84.43,6.18-.42,2.45-4.17,3.66-10.27,4.25-13.84C43.71,450.6,41,449.09,41,449.09Z"
                    fill="#707272"
                />
                <path
                    id="Path_64"
                    data-name="Path 64"
                    d="M38.4,442.88l-1.66,6.58a1.79,1.79,0,0,0,.93,2,6.27,6.27,0,0,0,3.43.83,1.48,1.48,0,0,0,1.21-1l2.43-6.92Z"
                    fill="#e4e3e3"
                />
                <path
                    id="Path_65"
                    data-name="Path 65"
                    d="M96.57,356.26S81.79,448.63,82,449.14a12.27,12.27,0,0,1-8.53.24S74.84,400,78,381.07c0,0-25.22,44.13-33.23,66.12a20,20,0,0,1-7.63-2.77s27.15-79.44,34.4-90Z"
                    fill="#595a5a"
                />
                <path
                    id="Path_66"
                    data-name="Path 66"
                    d="M78.56,380.59a.48.48,0,0,1-.23-.07.43.43,0,0,1-.14-.6l5.52-8.82a.46.46,0,0,1,.29-.2l4.06-.82a.44.44,0,0,1,.17.86l-3.88.78-5.42,8.66a.44.44,0,0,1-.37.21Z"
                    fill="url(#linear-sales-pipelines)"
                />
                <path
                    id="Path_67"
                    data-name="Path 67"
                    d="M164.38,265.81s-10,2.2-16,5a5.58,5.58,0,0,0-2.71,2.73c-1.81,4-7.24,13.94-17.77,18.69,1.52,2,4,6.2,5,13.23,6.32-4.7,11.78-12.56,14.4-25.45a5.14,5.14,0,0,0,4.49-.12,45.3,45.3,0,0,0,4-2.26,5.23,5.23,0,0,0,2.31-3.1,18.13,18.13,0,0,0,.18-4.8l5.12-1.59a1.83,1.83,0,0,0,.98-2.33Z"
                    fill="#e4e3e3"
                />
                <path
                    id="Path_68"
                    data-name="Path 68"
                    d="M132.88,305.46c-1.06-7-3.5-11.22-5-13.23-6.53,3-15,3.9-25.87.15a11.3,11.3,0,0,0-3.09-.61c-5.77-.34-24.77-1-40.49,4.24a12.19,12.19,0,0,0-7.37,6.7c-4.25,9.76-11.13,32,8,43.21a47.231,47.231,0,0,1,4.61-8.73c-3.08-5.19-4-12.28,1.47-21.52,4,10.76,5.86,26.45,6.25,33.39a15.14,15.14,0,0,1-1.17,6.68l-.1.23a.79.79,0,0,0,.55,1.08c10.76,2.42,22,1.76,25.3,1.49a1.38,1.38,0,0,0,1.23-1.06c5.23-22,5.24-37.08,4-46.59C106,312,121,314.32,132.88,305.46Z"
                    fill="#707272"
                />
                <path
                    id="Path_69"
                    data-name="Path 69"
                    d="M101.14,311.48a.44.44,0,0,1-.43-.38C99.48,301.82,97,297.77,97,297.73a.45.45,0,0,1,.14-.6.44.44,0,0,1,.6.13c.11.17,2.63,4.23,3.89,13.73a.44.44,0,0,1-.38.49Z"
                    opacity="0.5"
                />
                <path
                    id="Path_70"
                    data-name="Path 70"
                    d="M74.59,278.92s-1.09,11.51-2,14.92a1.18,1.18,0,0,0,.55,1.33c1.63.93,5.53,2.44,10.86-.17a2.43,2.43,0,0,0,1.35-2.28l-.47-11.12Z"
                    fill="#e4e3e3"
                />
                <path
                    id="Path_71"
                    data-name="Path 71"
                    d="M85.06,264.2s3.48,8,3.72,12.2,1.14,6.92-3.72,7.76-8.94-1.57-11.15-5.75c0,0-3.48-6.44-2.7-9.08S85.06,264.2,85.06,264.2Z"
                    fill="#e4e3e3"
                />
                <path
                    id="Path_72"
                    data-name="Path 72"
                    d="M75.56,275.4l.28-.12a.68.68,0,0,0,.33-.92c-.69-1.32-2-4.35-.3-5.05,2.22-.92,11-2.71,12.92-5.83a5.82,5.82,0,0,0-2.22-8,.35.35,0,0,0-.44.14l-.2.39a.33.33,0,0,1-.61-.07.33.33,0,0,0-.59-.08c-.59.93-2.16,2.45-6.38,3.23-6,1.1-9.94,3.52-10,7.66a.33.33,0,0,1-.51.27c-.44-.27-1.15-.39-2.1.4-1.64,1.39-1,5,2.64,7.92a.33.33,0,0,1,0,.53c-.65.43-1.63,1.44.9,2.79a8.58,8.58,0,0,0,5.31,1.16.32.32,0,0,0,.26-.4L74,275.8a.33.33,0,0,1,.33-.4Z"
                    fill="#707272"
                />
                <path
                    id="Path_73"
                    data-name="Path 73"
                    d="M75.71,276.17s-1.32-5.11-3.61-3.68,1.18,5.73,3,5.92Z"
                    fill="#e4e3e3"
                />
                <path
                    id="Path_74"
                    data-name="Path 74"
                    d="M149.94,278.83a.45.45,0,0,1-.27-.1.44.44,0,0,1-.07-.62c.38-.46,3.73-4.55,5-5.28.46-.26.74-.54.8-.79a.62.62,0,0,0-.06-.41l-3.93.79-2.22,1.91a.45.45,0,0,1-.62-.05.42.42,0,0,1,0-.61l2.31-2a.54.54,0,0,1,.2-.1l4.35-.87a.45.45,0,0,1,.45.18,1.68,1.68,0,0,1,.32,1.35,2.24,2.24,0,0,1-1.23,1.35c-1,.55-3.76,3.85-4.76,5.07a.44.44,0,0,1-.27.18Z"
                    fill="#707272"
                    opacity="0.5"
                />
                <path
                    id="Path_75"
                    data-name="Path 75"
                    d="M75.55,351.45l15.38.19-5.38-5.07a4,4,0,0,0-4.93-.46,11.68,11.68,0,0,0-2.73,2.44s-9.6-3.51-14.26-11.36A47.232,47.232,0,0,0,59,345.92a48.06,48.06,0,0,0,16.55,5.53Z"
                    fill="#e4e3e3"
                />
                <path
                    id="Path_76"
                    data-name="Path 76"
                    d="M77.2,353.43h.09l39.07-.05a1.73,1.73,0,1,0,0-3.46L77.29,350a1.73,1.73,0,0,0-.09,3.45Z"
                    fill="#595a5a"
                />
                <path
                    id="Path_77"
                    data-name="Path 77"
                    d="M151.47,318.34H118.94a6.21,6.21,0,0,0-5.61,3.52l-15,31.55h40.43a3.77,3.77,0,0,0,3.51-2.35l11.66-29.2a2.58,2.58,0,0,0-2.46-3.52Z"
                    fill="#595a5a"
                />
                <ellipse
                    id="Ellipse_10"
                    data-name="Ellipse 10"
                    cx="4.13"
                    cy="2.56"
                    rx="4.13"
                    ry="2.56"
                    transform="translate(127.267 338.412) rotate(-71.76)"
                    fill="#e4e3e3"
                />
                <circle
                    id="Ellipse_11"
                    data-name="Ellipse 11"
                    cx="10.03"
                    cy="10.03"
                    r="10.03"
                    transform="translate(444.26 261.67)"
                    fill="#fff"
                    stroke="#e2e2e2"
                    strokeMiterlimit="10"
                    strokeWidth="2"
                />
                <circle
                    id="Ellipse_12"
                    data-name="Ellipse 12"
                    cx="10.03"
                    cy="10.03"
                    r="10.03"
                    transform="translate(28.77 39.28)"
                    fill="#fff"
                    stroke="#e2e2e2"
                    strokeMiterlimit="10"
                    strokeWidth="2"
                />
                <circle
                    id="Ellipse_13"
                    data-name="Ellipse 13"
                    cx="13.55"
                    cy="13.55"
                    r="13.55"
                    transform="translate(467.34 200.08)"
                    fill="#fff"
                    stroke="#e2e2e2"
                    strokeMiterlimit="10"
                    strokeWidth="2"
                />
                <path
                    id="Path_78"
                    data-name="Path 78"
                    d="M48.77,220.46H41.61v-7.15a.89.89,0,0,0-.89-.89h0a.91.91,0,0,0-.91.89v7.15H32.67a.91.91,0,0,0-.9.91.89.89,0,0,0,.9.89h7.14v7.16a.91.91,0,0,0,.91.89.89.89,0,0,0,.89-.89h0v-7.16h7.16a.91.91,0,0,0,.9-.89.92.92,0,0,0-.9-.91Z"
                    fill="#e2e2e2"
                    stroke="#e2e2e2"
                    strokeMiterlimit="10"
                    strokeWidth="0.75"
                />
                <path
                    id="Path_79"
                    data-name="Path 79"
                    d="M493.54,18.25h-7.16V11.1a.89.89,0,0,0-.89-.89h0a.91.91,0,0,0-.91.89v7.15h-7.14a.91.91,0,0,0-.9.91.9.9,0,0,0,.9.89h7.14v7.16a.9.9,0,0,0,.91.89.89.89,0,0,0,.89-.89h0V20.05h7.16a.9.9,0,0,0,.9-.89.91.91,0,0,0-.9-.91Z"
                    fill="#e2e2e2"
                    stroke="#e2e2e2"
                    strokeMiterlimit="10"
                    strokeWidth="0.75"
                />
            </g>
        </SVGIcon>
    );
}
