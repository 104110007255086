import React from 'react';

import { SVGIcon } from '../../UIKit/index';

export default function DCNotebook(props) {
    return (
        <SVGIcon {...props} viewBox="0 0 50 64">
            <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g>
                    <rect fill="#C2A787" x="0" y="0" width="50" height="64" rx="4" />
                    <polygon fill="#5A4343" points="41 0 44 0 44 64 41 64" />
                    <rect fill="#FFFFFF" x="12" y="11" width="26" height="16" rx="1" />
                    <rect
                        stroke="#979797"
                        fill="#D8D8D8"
                        x="16.5"
                        y="15.5"
                        width="15"
                        height="1"
                        rx="0.5"
                    />
                    <rect
                        stroke="#979797"
                        fill="#D8D8D8"
                        x="16.5"
                        y="19.5"
                        width="11"
                        height="1"
                        rx="0.5"
                    />
                    <path
                        d="M4,0 L4,64 L4,64 C1.790861,64 2.705415e-16,62.209139 0,60 L0,4 C-2.705415e-16,1.790861 1.790861,4.05812251e-16 4,0 Z"
                        fill="#AE9679"
                    />
                </g>
            </g>
        </SVGIcon>
    );
}
