import React from 'react';

import { SVGIcon } from '../../UIKit/index';

export default function DCSpeedMeter(props) {
    return (
        <SVGIcon {...props} viewBox="0 0 512 264">
            <g transform="translate(-0.17 0.001)">
                <path
                    fill="#E8E8E8"
                    d="M2.95,253.38c0,0,9.22-68.93,22.39-100.54l0,0c0-0.02,52.24-128.2,189.23-148.42   c0,0,68.49-10.54,129.07,10.1c0,0,134.34,40.83,162.01,189.67c2.47,16.27,3.64,32.71,3.51,49.17h-82.1c0,0,2.2-34.24-3.95-46.55   c0,0-18-86.05-108.44-117.22c0,0-183.52-45.66-225.66,125.12c-2.33,13.2-3.65,26.55-3.95,39.95H2.95V253.38z"
                />
                <path
                    fill="#FFAB2E"
                    d="M313.35,89.18c0,0-179.13-41.27-224.77,125.57c-2.34,13.2-3.67,26.56-3.98,39.96H2.51v-1.32   c0,0,0-207.92,208.98-248.31c0,0,71.12-11.42,131.7,9.22"
                />
                <path
                    fill="#191919"
                    d="M321.8,68.73L279.44,259.7c0,0-32.43,12.09-29.02-19.5L321.8,68.73z"
                />
            </g>
        </SVGIcon>
    );
}
