import React from 'react';

import { SVGIcon } from '../../UIKit/index';

export default function DCFilter1(props) {
    return (
        <SVGIcon {...props} viewBox="0 0 512 512">
            <path d="M488.7,5.8H23.3c-9.9-0.1-18,7.9-18.1,17.8c0,0,0,0.1,0,0.1v89.5c0,5.1,2.2,9.9,5.9,13.3    l173.2,155.8v206.9c0,9.9,8,17.9,17.9,17.9c2.8,0,5.5-0.6,8-1.9l107.4-53.7c6.1-3,9.9-9.2,9.9-16V282.2l173.1-155.7    c3.8-3.4,5.9-8.2,5.9-13.3V23.7C506.6,13.8,498.6,5.8,488.7,5.8z M470.8,105.2L297.7,261c-3.8,3.4-5.9,8.2-5.9,13.3v150    l-71.6,35.8V274.3c0-5.1-2.2-9.9-5.9-13.3L41.2,105.2V41.6h429.6V105.2z" />
        </SVGIcon>
    );
}
