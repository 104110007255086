import React from 'react';
import { Div, IconButton, Link } from '../index';
import UserInfo from './userInfo';
// import DCCrossClose from '../../Assets/icons/DCCrossClose';
import DCTrash from 'Assets/icons/DCTrash';
import './style.scss';

const UserInfoWithDelete = ({ name, email, image, link, onRemove, customDelete, ...rest }) => (
    <Div className="UserInfoWithDelete" {...rest}>
        {link ? (
            <Link href={link.href} props={{ target: link.target }}>
                <UserInfo name={name} email={email} image={image} />
            </Link>
        ) : (
            <UserInfo name={name} email={email} image={image} />
        )}
        {onRemove || customDelete ? (
            <Div className="UIWDelete">
                {customDelete ? (
                    customDelete
                ) : (
                    <IconButton buttonType={'circleBtn RedOutlineBtn'} onClick={onRemove}>
                        <DCTrash />
                        {/* <DCCrossClose /> */}
                    </IconButton>
                )}
            </Div>
        ) : null}
    </Div>
);

export default UserInfoWithDelete;
