import React from 'react';

import { SVGIcon } from '../../UIKit/index';

export default function DCLiveChat1(props) {
    return (
        <SVGIcon {...props} viewBox="0 0 42 42" fill="none">
            <g clip-path="url(#clip0_66_562)">
                <path
                    d="M16.3072 22.1182H2.64109C1.18242 22.1182 0 20.9358 0 19.4771V9.47414C0 4.24172 4.24172 0 9.47414 0C14.7066 0 18.9483 4.24172 18.9483 9.47414V19.4771C18.9483 20.9358 17.7658 22.1182 16.3072 22.1182Z"
                    fill="#E19974"
                />
                <path
                    d="M9.47414 0C9.06516 0 8.66219 0.0259375 8.26688 0.0761719C12.9292 0.669141 16.5338 4.65062 16.5338 9.47406V19.477C16.5338 20.9357 15.3513 22.1181 13.8927 22.1181H16.3071C17.7657 22.1181 18.9482 20.9356 18.9482 19.477V9.47414C18.9483 4.24172 14.7066 0 9.47414 0V0Z"
                    fill="#DC8758"
                />
                <path
                    d="M17.1414 13.3847H15.6426V9.771H17.1414C18.1393 9.771 18.9483 10.58 18.9483 11.5779C18.9483 12.5758 18.1393 13.3847 17.1414 13.3847Z"
                    fill="#FFCEBF"
                />
                <path
                    d="M1.80687 13.3847H3.3057V9.771H1.80687C0.808984 9.77107 0 10.58 0 11.5779C0 12.5758 0.808984 13.3847 1.80687 13.3847Z"
                    fill="#FFB09E"
                />
                <path
                    d="M16.8498 10.0677C16.8498 9.7872 16.6438 9.5504 16.3662 9.51009C15.6396 9.40454 14.5013 9.11235 13.4102 8.26392C12.6663 7.6854 12.1877 7.03774 11.8916 6.54095C11.7942 6.37759 11.5727 6.34532 11.4335 6.47501C10.697 7.16157 9.58532 8.02376 8.06657 8.67735C5.91211 9.60454 3.93586 9.68282 2.69375 9.61087C2.37039 9.59212 2.09852 9.84931 2.09852 10.1732V12.367C2.09852 16.4405 5.40071 19.7427 9.47414 19.7427C13.5476 19.7427 16.8498 16.4405 16.8498 12.367V10.0677Z"
                    fill="#FFCEBF"
                />
                <path
                    d="M16.3662 9.51019C15.6396 9.40464 14.5013 9.11245 13.4102 8.26402C12.6661 7.68542 12.1877 7.03761 11.8915 6.54081C11.7946 6.37831 11.5736 6.34441 11.4353 6.47347C11.0965 6.78949 10.677 7.14277 10.176 7.49589C10.4045 7.75308 10.6754 8.01495 10.9957 8.26402C11.7774 8.87191 12.5834 9.19425 13.2448 9.36691C13.9486 9.55074 14.4353 10.1923 14.4353 10.9196V12.3671C14.4353 16.0294 11.7659 19.0677 8.26688 19.6439C8.65977 19.7086 9.06289 19.7428 9.47414 19.7428C13.5476 19.7428 16.8498 16.4407 16.8498 12.3672V10.0678C16.8498 9.7873 16.6438 9.5505 16.3662 9.51019Z"
                    fill="#FFB09E"
                />
                <path
                    d="M12.8382 13.6146C12.5048 13.6146 12.2346 13.3444 12.2346 13.011V11.8209C12.2346 11.4874 12.5048 11.2173 12.8382 11.2173C13.1716 11.2173 13.4418 11.4875 13.4418 11.8209V13.011C13.4419 13.3444 13.1716 13.6146 12.8382 13.6146Z"
                    fill="#3D6D93"
                />
                <path
                    d="M6.11 13.6146C5.77664 13.6146 5.50641 13.3444 5.50641 13.011V11.8209C5.50641 11.4874 5.77664 11.2173 6.11 11.2173C6.44336 11.2173 6.7136 11.4875 6.7136 11.8209V13.011C6.71367 13.3444 6.44336 13.6146 6.11 13.6146Z"
                    fill="#3D6D93"
                />
                <path
                    d="M8.08523 17.1603C7.8132 16.9675 7.74891 16.5908 7.94156 16.3187C8.13438 16.0467 8.51102 15.9824 8.78305 16.1751C8.90734 16.2632 9.16906 16.4093 9.51688 16.3965C9.82531 16.3854 10.055 16.2534 10.1645 16.1756C10.4362 15.9825 10.813 16.0463 11.0061 16.3181C11.1992 16.5898 11.1354 16.9667 10.8637 17.1598C10.5955 17.3503 10.1518 17.5818 9.56016 17.603C8.89922 17.6267 8.33211 17.3352 8.08523 17.1603Z"
                    fill="#FFB09E"
                />
                <path
                    d="M22.8586 33.311H24.3574V29.6973H22.8586C21.8607 29.6973 21.0517 30.5062 21.0517 31.5041C21.0517 32.502 21.8607 33.311 22.8586 33.311Z"
                    fill="#FFB09E"
                />
                <path
                    d="M38.1931 33.311H36.6943V29.6973H38.1931C39.191 29.6973 40 30.5062 40 31.5041C40 32.502 39.191 33.311 38.1931 33.311Z"
                    fill="#FFCEBF"
                />
                <path
                    d="M30.5259 40C26.4523 40 23.1502 36.6978 23.1502 32.6244V29.8189L24.0626 25.072C24.0626 23.0673 25.6877 21.4422 27.6923 21.4422L33.1615 20C35.1662 20 36.7912 21.6251 36.7912 23.6298L37.9015 29.8188V32.3902L37.5441 33.6563C37.5441 34.5474 37.7435 34.3696 37.454 35.1603C36.4199 37.9845 33.7083 40 30.5259 40Z"
                    fill="#FFCEBF"
                />
                <path
                    d="M36.7913 23.6298C36.7913 21.6251 35.1662 20 33.1615 20L32.1277 20.2726C33.4474 20.8159 34.3768 22.1141 34.3768 23.6298L35.487 29.1659V32.6244C35.487 36.2866 32.8177 39.3249 29.3186 39.9011C29.7115 39.9658 30.1146 40 30.5259 40C33.7079 40 36.4193 37.985 37.4536 35.1613C37.7434 34.3703 37.5441 34.5477 37.5441 33.6563L37.9015 32.3902V29.8189L36.7913 23.6298Z"
                    fill="#FFB09E"
                />
                <path
                    d="M34.2717 19.7939H26.7799C24.7752 19.7939 23.1502 21.419 23.1502 23.4237V29.8188C24.2829 29.9408 26.5766 30.0284 29.1182 28.9346C30.6362 28.2813 31.7475 27.4196 32.484 26.7333C32.6234 26.6034 32.8459 26.6347 32.9434 26.7984C33.2396 27.2951 33.718 27.9427 34.4618 28.5211C35.8431 29.5951 37.3001 29.7778 37.9015 29.8188V23.4237C37.9016 21.419 36.2764 19.7939 34.2717 19.7939Z"
                    fill="#E19974"
                />
                <path
                    d="M34.2717 19.7939H31.8573C33.862 19.7939 35.487 21.419 35.487 23.4237V29.1658C36.5118 29.6767 37.4489 29.7879 37.9015 29.8188V23.4237C37.9016 21.419 36.2764 19.7939 34.2717 19.7939Z"
                    fill="#DC8758"
                />
                <path
                    d="M33.89 33.8715C33.5566 33.8715 33.2864 33.6012 33.2864 33.2679V32.0777C33.2864 31.7443 33.5566 31.4741 33.89 31.4741C34.2234 31.4741 34.4936 31.7444 34.4936 32.0777V33.2679C34.4936 33.6013 34.2234 33.8715 33.89 33.8715Z"
                    fill="#3D6D93"
                />
                <path
                    d="M27.1618 33.8715C26.8284 33.8715 26.5582 33.6012 26.5582 33.2679V32.0777C26.5582 31.7443 26.8284 31.4741 27.1618 31.4741C27.4952 31.4741 27.7654 31.7444 27.7654 32.0777V33.2679C27.7654 33.6013 27.4952 33.8715 27.1618 33.8715Z"
                    fill="#3D6D93"
                />
                <path
                    d="M29.137 37.4172C28.8649 37.2244 28.8006 36.8477 28.9933 36.5756C29.186 36.3037 29.5628 36.2394 29.8348 36.432C29.9591 36.5201 30.2191 36.6645 30.5686 36.6534C30.877 36.6423 31.1067 36.5102 31.2162 36.4325C31.488 36.2392 31.8647 36.3032 32.0579 36.5751C32.2509 36.8469 32.1871 37.2237 31.9153 37.4167C31.6471 37.6072 31.2034 37.8386 30.6119 37.8599C29.9506 37.8836 29.3838 37.5921 29.137 37.4172Z"
                    fill="#FFB09E"
                />
                <path
                    d="M37.9016 32.3901C37.6229 33.0762 36.89 34.477 35.398 35.0409C34.9688 35.2031 34.5079 35.2854 34.028 35.2854C34.0244 35.2854 34.0209 35.2854 34.017 35.2854C33.6843 35.2854 33.4142 35.5547 33.4134 35.8876C33.4127 36.221 33.6823 36.4918 34.0157 36.4926H34.028C34.6542 36.4926 35.2587 36.3841 35.8248 36.1701C36.4784 35.9231 37.0149 35.5639 37.4537 35.1612C37.7434 34.3701 37.9016 33.5156 37.9016 32.6242V32.3901H37.9016Z"
                    fill="#3D6D93"
                />
                <path
                    d="M35.398 35.0408C35.2809 35.0851 35.1614 35.1228 35.04 35.1551C34.8701 35.62 34.6546 36.0627 34.3991 36.4784C34.8932 36.4439 35.3714 36.3415 35.8248 36.1701C36.4784 35.9231 37.0149 35.5639 37.4537 35.1612C37.4869 35.0704 37.5179 34.9787 37.5477 34.8863C37.7549 34.227 37.9002 33.4977 37.9016 32.6242V32.3901C37.6228 33.0761 36.89 34.4769 35.398 35.0408Z"
                    fill="#335E80"
                />
                <path
                    d="M2.14742 27.5449H18.9959C19.9222 27.5449 20.3527 28.6941 19.6542 29.3026L17.2666 31.383C16.6737 31.8996 16.3334 32.6475 16.3334 33.4338V37.8525C16.3334 39.0385 15.372 39.9999 14.186 39.9999H2.14742C0.961406 39.9999 0 39.0385 0 37.8525V29.6923C0 28.5063 0.961406 27.5449 2.14742 27.5449Z"
                    fill="#AEE69C"
                />
                <path
                    d="M18.9959 27.5449H16.5814C17.5077 27.5449 17.9381 28.6941 17.2398 29.3026L14.8521 31.383C14.2592 31.8995 13.919 32.6474 13.919 33.4338V37.8524C13.919 39.0384 12.9576 39.9998 11.7716 39.9998H14.186C15.372 39.9998 16.3334 39.0384 16.3334 37.8524V33.4338C16.3334 32.6474 16.6737 31.8995 17.2666 31.383L19.6542 29.3026C20.3527 28.6941 19.9222 27.5449 18.9959 27.5449Z"
                    fill="#89DAA4"
                />
                <path
                    d="M12.7116 31.9616H3.04054C2.70718 31.9616 2.43695 31.6913 2.43695 31.358C2.43695 31.0246 2.70718 30.7544 3.04054 30.7544H12.7116C13.045 30.7544 13.3152 31.0246 13.3152 31.358C13.3152 31.6913 13.045 31.9616 12.7116 31.9616Z"
                    fill="white"
                />
                <path
                    d="M12.7116 34.3761H3.04054C2.70718 34.3761 2.43695 34.1059 2.43695 33.7725C2.43695 33.4392 2.70718 33.1689 3.04054 33.1689H12.7116C13.045 33.1689 13.3152 33.4392 13.3152 33.7725C13.3152 34.1059 13.045 34.3761 12.7116 34.3761Z"
                    fill="white"
                />
                <path
                    d="M9.08992 36.7907H3.04054C2.70718 36.7907 2.43695 36.5205 2.43695 36.1871C2.43695 35.8537 2.70718 35.5835 3.04054 35.5835H9.08992C9.42328 35.5835 9.69351 35.8537 9.69351 36.1871C9.69351 36.5205 9.42328 36.7907 9.08992 36.7907Z"
                    fill="white"
                />
                <path
                    d="M37.8526 12.455H22.227C21.3007 12.455 20.8703 11.3059 21.5687 10.6973L23.9563 8.61687C24.5492 8.10031 24.8895 7.35242 24.8895 6.56609V2.14742C24.8895 0.961406 25.8509 0 27.037 0H37.8526C39.0386 0 40 0.961406 40 2.14742V10.3076C40 11.4936 39.0386 12.455 37.8526 12.455Z"
                    fill="#FFE179"
                />
                <path
                    d="M37.8526 0H35.4381C36.6241 0 37.5855 0.961406 37.5855 2.14742V10.3076C37.5855 11.4936 36.6241 12.455 35.4381 12.455H37.8526C39.0386 12.455 40 11.4936 40 10.3076V2.14742C40 0.961406 39.0386 0 37.8526 0V0Z"
                    fill="#FBD268"
                />
                <path
                    d="M36.9595 9.24576H28.5113C28.1779 9.24576 27.9077 8.97553 27.9077 8.64217C27.9077 8.30881 28.1779 8.03857 28.5113 8.03857H36.9595C37.2928 8.03857 37.5631 8.30881 37.5631 8.64217C37.5631 8.97553 37.2928 9.24576 36.9595 9.24576Z"
                    fill="white"
                />
                <path
                    d="M36.9595 6.83121H28.5113C28.1779 6.83121 27.9077 6.56098 27.9077 6.22762C27.9077 5.89418 28.1779 5.62402 28.5113 5.62402H36.9595C37.2928 5.62402 37.5631 5.89426 37.5631 6.22762C37.5631 6.56098 37.2928 6.83121 36.9595 6.83121Z"
                    fill="white"
                />
                <path
                    d="M36.9595 4.41666H32.133C31.7996 4.41666 31.5294 4.14643 31.5294 3.81307C31.5294 3.47971 31.7996 3.20947 32.133 3.20947H36.9595C37.2928 3.20947 37.5631 3.47971 37.5631 3.81307C37.5631 4.14643 37.2928 4.41666 36.9595 4.41666Z"
                    fill="white"
                />
            </g>
            <defs>
                <clipPath id="clip0_66_562">
                    <rect width="40" height="40" fill="white" />
                </clipPath>
            </defs>
        </SVGIcon>
    );
}
