import React from 'react';

import { SVGIcon } from '../../UIKit/index';

export default function DCEnvelopeOpen(props) {
    return (
        <SVGIcon {...props} viewBox="0 0 512 448.8">
            <path d="M56.5,145.9c5.9-13.5,19.9-21.6,34.5-20h330c14.6-1.5,28.6,6.6,34.5,20l33-17.8L281.4,8.3  c-15.8-8.5-34.9-8.5-50.7,0L23.4,128.1L56.5,145.9z" />
            <path d="M426,168.9v-17.1c0-2.8-2.2-5-5-5l0,0H91c-2.8,0-5,2.2-5,5l0,0v17.1l163.8,128  c3.6,2.8,8.6,2.8,12.2,0L426,168.9z" />
            <path d="M472,448.8c4,0,8-0.6,11.8-1.8L295.5,312c-0.7-0.5-1.3-1-1.9-1.6l-13,10.1  c-14.5,11.3-34.8,11.3-49.2,0l-13-10.1c-0.6,0.6-1.2,1.1-1.9,1.6L28.2,447c3.8,1.2,7.8,1.8,11.8,1.8H472z" />
            <path d="M512,139.8L318.1,291.3l189.5,135.8c2.9-5.7,4.5-12,4.5-18.4V139.8z" />
            <path d="M193.9,291.3L0,139.8v269c0,6.4,1.5,12.7,4.5,18.4L193.9,291.3z" />
        </SVGIcon>
    );
}
