import React from 'react';

import { SVGIcon } from '../../UIKit/index';

export default function DCContactRecords(props) {
    return (
        <SVGIcon {...props} viewBox="0 0 505.48 488.025">
            <defs>
                <linearGradient
                    id="linear-gradient"
                    x1="-121.133"
                    y1="151.213"
                    x2="-125.481"
                    y2="156.798"
                    gradientUnits="objectBoundingBox"
                >
                    <stop offset="0" stopColor="#8d50a0" />
                    <stop offset="1" stopColor="#ef4a7b" />
                </linearGradient>
                <linearGradient
                    id="linear-gradient-2"
                    x1="-120.623"
                    y1="150.256"
                    x2="-124.98"
                    y2="155.843"
                    xlinkHref="#linear-gradient"
                />
                <linearGradient
                    id="linear-gradient-3"
                    x1="-119.855"
                    y1="150.252"
                    x2="-125.589"
                    y2="156.266"
                    xlinkHref="#linear-gradient"
                />
                <linearGradient
                    id="linear-gradient-4"
                    x1="-119.046"
                    y1="148.388"
                    x2="-123.4"
                    y2="153.982"
                    xlinkHref="#linear-gradient"
                />
            </defs>
            <g id="Contact_Records" transform="translate(-3.44 -12.535)">
                <ellipse
                    id="_Path_"
                    data-name="&lt;Path&gt;"
                    cx="252.74"
                    cy="77.26"
                    rx="252.74"
                    ry="77.26"
                    transform="translate(3.44 346.04)"
                    fill="#f4f5f6"
                />
                <path
                    id="Path_1"
                    data-name="Path 1"
                    d="M306.93,62.73c-.1-4.51-4.77-8-9.59-7.17L68.56,74c-7.52.61-12.92,6.45-12.34,13.37C61.34,148,94.61,411.12,94.61,411.12c.19,3.88,212.47,9.5,212.47,9.5,4.2.05,7.48-3,7.4-6.78Z"
                    fill="#707272"
                />
                <path
                    id="Path_2"
                    data-name="Path 2"
                    d="M314.5,366.82A9.76,9.76,0,0,0,324.78,376l168.63-9.34a9.76,9.76,0,0,0,9.2-10.28l-14-252.57a9.76,9.76,0,0,0-10.25-9.2l-170,9a9.75,9.75,0,0,0-9.22,10.33Z"
                    fill="#707272"
                />
                <rect
                    id="Rectangle_1"
                    data-name="Rectangle 1"
                    width="146.84"
                    height="8.36"
                    rx="3.55"
                    transform="matrix(0.998, -0.055, 0.055, 0.998, 325.188, 122.799)"
                    fill="#e4e3e3"
                />
                <path
                    id="Path_3"
                    data-name="Path 3"
                    d="M335,189.1a3.35,3.35,0,0,0-2.87,3.76l.09,1.25a3.37,3.37,0,0,0,3.36,3.33l122.48-8.5a3.36,3.36,0,0,0,2.87-3.76l-.09-1.25a3.36,3.36,0,0,0-3.36-3.33Z"
                    fill="#e4e3e3"
                />
                <path
                    id="Path_4"
                    data-name="Path 4"
                    d="M335.27,215.35a3.37,3.37,0,0,0-2.87,3.77l.09,1.24a3.35,3.35,0,0,0,3.36,3.33l122.48-8.49a3.37,3.37,0,0,0,2.87-3.76l-.09-1.25a3.35,3.35,0,0,0-3.36-3.33Z"
                    fill="#e4e3e3"
                />
                <path
                    id="Path_5"
                    data-name="Path 5"
                    d="M337.71,240.44a3.37,3.37,0,0,0-2.87,3.77l.09,1.24a3.36,3.36,0,0,0,3.36,3.33l122.48-8.49a3.36,3.36,0,0,0,2.87-3.76l-.09-1.25a3.35,3.35,0,0,0-3.36-3.33Z"
                    fill="#e4e3e3"
                />
                <path
                    id="Path_6"
                    data-name="Path 6"
                    d="M341.13,266.32a3.36,3.36,0,0,0-2.87,3.77l.09,1.24a3.35,3.35,0,0,0,3.36,3.33l122.48-8.49a3.37,3.37,0,0,0,2.87-3.77l-.09-1.24a3.36,3.36,0,0,0-3.36-3.33Z"
                    fill="#e4e3e3"
                />
                <path
                    id="Path_7"
                    data-name="Path 7"
                    d="M341.82,141.19h0a3.56,3.56,0,0,0,3.67,3.16l122.14-4.53a3.56,3.56,0,0,0,3.41-3.75v-.29a3.56,3.56,0,0,0-3.69-3.36l-122.12,4.83a3.56,3.56,0,0,0-3.41,3.94Z"
                    fill="#e4e3e3"
                />
                <path
                    id="Path_8"
                    data-name="Path 8"
                    d="M340.78,357.3h.1L478.22,348a8.44,8.44,0,0,0,7.84-9L472.2,152.75a8.44,8.44,0,0,0-9.05-7.78l-137,10.27a8.45,8.45,0,0,0-7.78,9h0L331.9,349.49a8.46,8.46,0,0,0,8.88,7.81ZM321.13,164.06a5.64,5.64,0,0,1,5.2-6l137-10.27a5.63,5.63,0,0,1,6,5.19l.77,10.33L321.93,171.5h-.25ZM334.7,349.29l-12.82-175h.2l148.29-8.22L483.26,339.2a5.63,5.63,0,0,1-5.23,6l-137.34,9.26h-.06A5.65,5.65,0,0,1,334.7,349.29Z"
                    fill="#e4e3e3"
                />
                <path
                    id="Path_9"
                    data-name="Path 9"
                    d="M267.15,382.18a10.08,10.08,0,0,0,10.8,9.27l174.34-12.82a10.09,10.09,0,0,0,9.32-10.81L442.4,106.7a10.1,10.1,0,0,0-10.78-9.33L255.85,109.83a10.1,10.1,0,0,0-9.35,10.87Z"
                    fill="#707272"
                />
                <path
                    id="Path_10"
                    data-name="Path 10"
                    d="M257.2,133.1a10.23,10.23,0,0,1,9.51-11.1l156-10.51a10.24,10.24,0,0,1,10.9,9.51l16.16,234.44a10.25,10.25,0,0,1-9.28,10.91l-151.3,13.9a10.26,10.26,0,0,1-11.14-9.31Z"
                    fill="#fff"
                    opacity="0.35"
                />
                <rect
                    id="Rectangle_2"
                    data-name="Rectangle 2"
                    width="151.99"
                    height="8.65"
                    rx="3.68"
                    transform="matrix(0.997, -0.073, 0.073, 0.997, 273.633, 129.44)"
                    fill="#e4e3e3"
                />
                <path
                    id="Path_11"
                    data-name="Path 11"
                    d="M291.19,148.17h0a3.67,3.67,0,0,0,3.85,3.2l126.31-7a3.68,3.68,0,0,0,3.47-3.94v-.3a3.69,3.69,0,0,0-3.88-3.41L294.63,144a3.68,3.68,0,0,0-3.44,4.17Z"
                    fill="#e4e3e3"
                />
                <path
                    id="Path_12"
                    data-name="Path 12"
                    d="M294.16,371.83h.11l142-12.16a8.72,8.72,0,0,0,7.94-9.5L426.32,157.69a8.71,8.71,0,0,0-9.5-7.88L275.2,163a8.75,8.75,0,0,0-7.89,9.49h0l17.52,191.43a8.75,8.75,0,0,0,9.33,7.91Zm-24-199.6a5.81,5.81,0,0,1,5.26-6.33l141.62-13.2a5.83,5.83,0,0,1,6.34,5.26l1,10.68L271.17,179.91a1.19,1.19,0,0,0-.26.05Zm17.51,191.42-16.5-180.84h.21l153.31-11.28,16.58,178.9a5.83,5.83,0,0,1-5.3,6.34L294,368.93h0a5.84,5.84,0,0,1-6.28-5.28Z"
                    fill="#e4e3e3"
                />
                <path
                    id="Path_13"
                    data-name="Path 13"
                    d="M192.44,112.28C218,115.79,306,126.67,378.73,121.59a5.86,5.86,0,0,1,6.34,5.46l13.12,265.72a6.69,6.69,0,0,1-6.43,6.94c-30.61,1.62-156.65,7.81-201.57,3a5.31,5.31,0,0,1-4.8-5.23l-.24-279a6.34,6.34,0,0,1,7.29-6.2Z"
                    fill="#707272"
                />
                <path
                    id="Path_14"
                    data-name="Path 14"
                    d="M195.14,119.19c24.61,3.37,109.24,13.84,179.28,8.95a5.64,5.64,0,0,1,6.1,5.26l12.63,255.71a6.44,6.44,0,0,1-6.19,6.68c-29.45,1.56-150.75,7.52-194,2.89a5.1,5.1,0,0,1-4.62-5l-.23-268.53a6.1,6.1,0,0,1,7.03-5.96Z"
                    fill="#fff"
                    opacity="0.35"
                />
                <path
                    id="Path_15"
                    data-name="Path 15"
                    d="M364.28,150.48c1.59,0,2.88-.81,2.85-1.82s-1.35-1.87-2.94-1.91L298,145.28c-1.6,0-2.5,1.05-2,2.4a4.21,4.21,0,0,0,3.77,2.48Z"
                    fill="#e4e3e3"
                />
                <path
                    id="Path_16"
                    data-name="Path 16"
                    d="M298,155.5c-1.59,0-2.5,1-2,2.4a4.23,4.23,0,0,0,3.77,2.48l64.51.32c1.59,0,2.87-.81,2.85-1.83s-1.35-1.86-2.94-1.9Z"
                    fill="#e4e3e3"
                />
                <path
                    id="Path_17"
                    data-name="Path 17"
                    d="M297.91,165.86c-1.59,0-2.5,1-2,2.41a4.26,4.26,0,0,0,3.78,2.48l64.5.31c1.6,0,2.88-.81,2.85-1.82s-1.34-1.87-2.94-1.9Z"
                    fill="#e4e3e3"
                />
                <path
                    id="Path_18"
                    data-name="Path 18"
                    d="M370.63,192.5c1.59,0,2.84-.81,2.78-1.83s-1.42-1.85-3-1.87l-156.92-2c-1.59,0-2.07,1-1.07,2.22l.36.44a6.89,6.89,0,0,0,4.72,2.26Z"
                    fill="#e4e3e3"
                />
                <path
                    id="Path_19"
                    data-name="Path 19"
                    d="M213.43,197c-1.59,0-2.08,1-1.08,2.21l.36.45a7,7,0,0,0,4.72,2.26l153.14.76c1.6,0,2.85-.82,2.78-1.83s-1.41-1.85-3-1.88Z"
                    fill="#e4e3e3"
                />
                <path
                    id="Path_20"
                    data-name="Path 20"
                    d="M239.91,248.11h-.06l-21.08.36a5.68,5.68,0,0,1-6-4.58l-2.34-17.17a3.7,3.7,0,0,1,.84-2.87,4.74,4.74,0,0,1,3.22-1.66h0c1.78-.16,4.22-.43,6.91-.73,5.85-.64,12.49-1.37,16.39-1.52h.09a5.93,5.93,0,0,1,6,3.9c1.21,3.93,1.32,11.42,1.34,19.4C245.23,245.85,242.85,248,239.91,248.11Zm-25.23-24.25a3,3,0,0,0-2,1,2.06,2.06,0,0,0-.48,1.61l2.34,17.17a4,4,0,0,0,4.23,3.13l21.08-.37h0a3.51,3.51,0,0,0,3.65-3.19c0-7.85-.13-15.2-1.27-18.92a4.26,4.26,0,0,0-4.35-2.7c-3.84.14-10.73.9-16.27,1.51-2.7.29-5.15.56-6.94.73Z"
                    fill="#fff"
                />
                <path
                    id="Path_21"
                    data-name="Path 21"
                    d="M217,225.53c4.42-.41,14-1.62,18.58-1.8a4.09,4.09,0,0,1,4.16,2.64c1,3.15,1,9.47,1,15.31a3.46,3.46,0,0,1-3.62,3.22l-16.85.29a3.86,3.86,0,0,1-4.07-3.08l-1.87-13.72a2.69,2.69,0,0,1,2.67-2.86Z"
                    fill="#fff"
                    opacity="0.6"
                />
                <path
                    id="Path_22"
                    data-name="Path 22"
                    d="M259.08,229.74a2,2,0,0,0-2.06,2.79l1.39,4.63a4,4,0,0,0,3.73,2.7L366,237.09a2.82,2.82,0,0,0,2.75-3l-.1-1.86a3.07,3.07,0,0,0-3-2.88Z"
                    fill="#e4e3e3"
                />
                <path
                    id="Path_23"
                    data-name="Path 23"
                    d="M218,228.2h0a3.3,3.3,0,0,1,4.5,1.07l4.36,7.14a1.05,1.05,0,0,0,1.85-.12l9.69-22.63a1.15,1.15,0,0,1,2.21.43,43.43,43.43,0,0,1-12.33,31.06S222,243,216.73,232.46A3.26,3.26,0,0,1,218,228.2Z"
                    fill="url(#linear-gradient)"
                />
                <path
                    id="Path_24"
                    data-name="Path 24"
                    d="M241.14,292.09h0l-21.09.37a5.68,5.68,0,0,1-6-4.59l-2.34-17.16a3.72,3.72,0,0,1,.84-2.88,4.71,4.71,0,0,1,3.21-1.65h0c1.78-.17,4.22-.44,6.91-.73,5.86-.65,12.49-1.37,16.4-1.53h.08a5.93,5.93,0,0,1,6,3.9c1.21,3.93,1.33,11.42,1.34,19.4C246.47,289.84,244.09,292,241.14,292.09Zm-25.22-24.25a3,3,0,0,0-2,1,2.13,2.13,0,0,0-.49,1.62l2.34,17.16a4,4,0,0,0,4.23,3.13l21.09-.36h0a3.52,3.52,0,0,0,3.64-3.2c0-7.85-.12-15.2-1.27-18.92a4.28,4.28,0,0,0-4.35-2.7c-3.84.15-10.73.9-16.27,1.51-2.7.3-5.15.57-6.93.73Z"
                    fill="#fff"
                />
                <path
                    id="Path_25"
                    data-name="Path 25"
                    d="M218.23,269.51c4.42-.41,14-1.62,18.58-1.79a4.08,4.08,0,0,1,4.17,2.63c1,3.15,1,9.48,1,15.31a3.48,3.48,0,0,1-3.62,3.23l-16.85.29a3.9,3.9,0,0,1-4.08-3.08l-1.86-13.72a2.68,2.68,0,0,1,2.66-2.87Z"
                    fill="#fff"
                    opacity="0.6"
                />
                <path
                    id="Path_26"
                    data-name="Path 26"
                    d="M260.31,273.73a2,2,0,0,0-2.06,2.78l1.4,4.63a4,4,0,0,0,3.73,2.7l103.81-2.76a2.82,2.82,0,0,0,2.75-3l-.09-1.87a3.07,3.07,0,0,0-3.05-2.88Z"
                    fill="#e4e3e3"
                />
                <path
                    id="Path_27"
                    data-name="Path 27"
                    d="M219.21,272.19h0a3.27,3.27,0,0,1,4.49,1.06l4.36,7.15a1.06,1.06,0,0,0,1.86-.13l9.68-22.63a1.16,1.16,0,0,1,2.22.43,43.51,43.51,0,0,1-12.33,31.06S223.24,287,218,276.44a3.25,3.25,0,0,1,1.21-4.25Z"
                    fill="url(#linear-gradient-2)"
                />
                <path
                    id="Path_28"
                    data-name="Path 28"
                    d="M240.36,334.38h-.06l-21.09.36a5.67,5.67,0,0,1-6-4.58L210.91,313a3.7,3.7,0,0,1,.84-2.87,4.72,4.72,0,0,1,3.22-1.66h0c1.77-.16,4.21-.43,6.9-.72,5.56-.62,12.49-1.38,16.4-1.53a6,6,0,0,1,6.06,3.9c1.22,3.93,1.33,11.42,1.34,19.4C245.68,332.12,243.3,334.3,240.36,334.38Zm-25.23-24.25a3,3,0,0,0-2,1,2.06,2.06,0,0,0-.48,1.61l2.35,17.2a4,4,0,0,0,4.23,3.13l21.09-.37c2-.08,3.69-1.46,3.68-3.19,0-7.85-.12-15.2-1.26-18.92a4.33,4.33,0,0,0-4.35-2.7c-3.85.14-10.74.9-16.28,1.51-2.7.3-5.15.56-6.93.73Z"
                    fill="#fff"
                />
                <path
                    id="Path_29"
                    data-name="Path 29"
                    d="M217.45,311.8c4.42-.41,14-1.62,18.58-1.8a4.09,4.09,0,0,1,4.16,2.64c1,3.15,1,9.47,1,15.31a3.47,3.47,0,0,1-3.62,3.22l-16.85.29a3.86,3.86,0,0,1-4.07-3.08l-1.87-13.72a2.69,2.69,0,0,1,2.67-2.86Z"
                    fill="#fff"
                    opacity="0.6"
                />
                <path
                    id="Path_30"
                    data-name="Path 30"
                    d="M259.53,316a2,2,0,0,0-2.06,2.79l1.39,4.63a4,4,0,0,0,3.73,2.7l103.82-2.76a2.82,2.82,0,0,0,2.74-3l-.09-1.87a3.07,3.07,0,0,0-3-2.88Z"
                    fill="#e4e3e3"
                />
                <path
                    id="Path_31"
                    data-name="Path 31"
                    d="M218.42,314.48h0a3.27,3.27,0,0,1,4.49,1.06l4.37,7.14a1.05,1.05,0,0,0,1.85-.12l9.68-22.63a1.16,1.16,0,0,1,2.22.43,43.43,43.43,0,0,1-12.33,31.06s-6.25-2.18-11.53-12.69a3.26,3.26,0,0,1,1.25-4.25Z"
                    fill="url(#linear-gradient-3)"
                />
                <path
                    id="Path_32"
                    data-name="Path 32"
                    d="M239.19,378h-.06l-21.09.36a5.68,5.68,0,0,1-6-4.58l-2.34-17.17a3.7,3.7,0,0,1,.84-2.87,4.68,4.68,0,0,1,3.22-1.66h0c1.77-.16,4.21-.43,6.9-.73,5.86-.64,12.49-1.37,16.4-1.52a6,6,0,0,1,6.06,3.9c1.21,3.93,1.33,11.42,1.34,19.4C244.51,375.74,242.13,377.92,239.19,378ZM214,353.75a3,3,0,0,0-2,1,2.05,2.05,0,0,0-.48,1.61l2.33,17.17a4,4,0,0,0,4.23,3.13l21.09-.37h0a3.51,3.51,0,0,0,3.64-3.19c0-7.85-.12-15.21-1.27-18.92a4.34,4.34,0,0,0-4.34-2.71c-3.85.15-10.74.91-16.27,1.52-2.7.29-5.16.56-6.94.73Z"
                    fill="#fff"
                />
                <path
                    id="Path_33"
                    data-name="Path 33"
                    d="M216.27,355.42c4.42-.42,14-1.62,18.58-1.8a4.1,4.1,0,0,1,4.17,2.64c1,3.15,1,9.47,1,15.31a3.47,3.47,0,0,1-3.62,3.22l-16.85.29a3.88,3.88,0,0,1-4.08-3.08l-1.86-13.72a2.67,2.67,0,0,1,2.66-2.86Z"
                    fill="#fff"
                    opacity="0.6"
                />
                <path
                    id="Path_34"
                    data-name="Path 34"
                    d="M258.35,359.63a2,2,0,0,0-2.06,2.78l1.4,4.64a4,4,0,0,0,3.73,2.7L365.23,367a2.81,2.81,0,0,0,2.75-3l-.09-1.86a3.07,3.07,0,0,0-3-2.88Z"
                    fill="#e4e3e3"
                />
                <path
                    id="Path_35"
                    data-name="Path 35"
                    d="M217.25,358.09h0a3.28,3.28,0,0,1,4.49,1.07l4.36,7.14a1.06,1.06,0,0,0,1.86-.13l9.68-22.62a1.16,1.16,0,0,1,2.22.42A43.49,43.49,0,0,1,227.53,375s-6.25-2.18-11.53-12.69A3.26,3.26,0,0,1,217.25,358.09Z"
                    fill="url(#linear-gradient-4)"
                />
                <path
                    id="Path_36"
                    data-name="Path 36"
                    d="M47.8,406.61,50.2,76.13a4.89,4.89,0,0,1,4.92-4.85L292.32,73a8.19,8.19,0,0,1,8.14,8.25l-2.35,323.39a8.84,8.84,0,0,1-8.91,8.78L52.83,411.71a5.06,5.06,0,0,1-5.03-5.1Z"
                    fill="#e4e3e3"
                />
                <path
                    id="Path_37"
                    data-name="Path 37"
                    d="M290.3,258.73a1,1,0,0,1-1-1l-.47-32.13a1,1,0,0,1,1-1.05h0a1,1,0,0,1,1,1l.48,32.13a1,1,0,0,1-1,1.05Z"
                    fill="#707272"
                />
                <path
                    id="Path_38"
                    data-name="Path 38"
                    d="M289.69,217.19a1,1,0,0,1-1-1l-.94-62.92a1,1,0,0,1,1-1.05h0a1,1,0,0,1,1,1l.93,62.92a1,1,0,0,1-1,1.05Z"
                    fill="#707272"
                />
                <path
                    id="Path_39"
                    data-name="Path 39"
                    d="M288.42,131.33a1,1,0,0,1-1-1l-.55-36.52c-.07-5-4.92-9.14-10.81-9.19l-33.32-.29a1.035,1.035,0,0,1,0-2.07l33.32.29c7,.07,12.77,5.1,12.86,11.23l.54,36.52a1,1,0,0,1-1,1.05Z"
                    fill="#707272"
                />
                <path
                    id="Path_40"
                    data-name="Path 40"
                    d="M83.16,208.58a17.51,17.51,0,0,1,17.94-17.05l148.74,3.79a15.42,15.42,0,0,1,15,15l5.48,215.93a11.37,11.37,0,0,1-11.44,11.66L88.34,436.68a10.8,10.8,0,0,1-10.71-11.07Z"
                    fill="#707272"
                />
                <path
                    id="Path_41"
                    data-name="Path 41"
                    d="M113.35,433.68h0l-19.07-.74A11.78,11.78,0,0,1,83,420.9l.73-28.8a.44.44,0,0,1,.45-.42h0a.42.42,0,0,1,.42.44l-.73,28.8a10.92,10.92,0,0,0,10.46,11.15l19.06.74a.42.42,0,0,1,.42.45.44.44,0,0,1-.46.42Z"
                    fill="#f4f5f6"
                />
                <path
                    id="Path_42"
                    data-name="Path 42"
                    d="M84.36,383.63a.44.44,0,0,1-.42-.45l.12-4.66a.44.44,0,0,1,.44-.43h0a.45.45,0,0,1,.43.45l-.12,4.66a.45.45,0,0,1-.45.43Z"
                    fill="#f4f5f6"
                />
                <path
                    id="Path_43"
                    data-name="Path 43"
                    d="M95.71,194.72a5.07,5.07,0,0,1,5.89-4.92c20.24,3.27,89.88,13.57,147.74,10.61a4.69,4.69,0,0,1,4.94,4.56l6,216.9a5.41,5.41,0,0,1-5.22,5.56c-24.32.81-124.49,3.82-160.08-.83a4.28,4.28,0,0,1-3.72-4.33Z"
                    fill="#e4e3e3"
                />
                <path
                    id="Path_44"
                    data-name="Path 44"
                    d="M98,200.2a4.88,4.88,0,0,1,5.67-4.73c19.47,3.15,86.48,13.06,142.17,10.21a4.52,4.52,0,0,1,4.76,4.39l5.76,208.74a5.19,5.19,0,0,1-5,5.34c-23.4.79-119.8,3.68-154-.79a4.12,4.12,0,0,1-3.59-4.17Z"
                    fill="#fff"
                />
                <path
                    id="Path_45"
                    data-name="Path 45"
                    d="M115.65,216.74l.94,44.74a2.84,2.84,0,0,0,2.69,2.78c8.27.45,34,1.73,48.62,1.34a3.18,3.18,0,0,0,3.09-3c.49-8.37,1.72-32.75.07-43a3.58,3.58,0,0,0-3.33-3l-48.88-3a3,3,0,0,0-3.2,3.14Z"
                    fill="#e4e3e3"
                />
                <circle
                    id="Ellipse_1"
                    data-name="Ellipse 1"
                    cx="9.61"
                    cy="9.61"
                    r="9.61"
                    transform="translate(133.78 223.82)"
                    fill="#fff"
                />
                <path
                    id="Path_46"
                    data-name="Path 46"
                    d="M128.08,261.27l30.61.78a2.37,2.37,0,0,0,2.08-3.61c-2.49-4.13-7.78-10.24-17.21-10.21a21.32,21.32,0,0,0-17.41,9.09,2.48,2.48,0,0,0,1.93,3.95Z"
                    fill="#fff"
                />
                <path
                    id="Path_47"
                    data-name="Path 47"
                    d="M237.48,223.74c1.21,0,2.2-.62,2.2-1.45s-1-1.54-2.21-1.59l-52.69-2.29a1.41,1.41,0,0,0-1.55,1.93,3.29,3.29,0,0,0,2.86,2.09Z"
                    fill="#e4e3e3"
                />
                <path
                    id="Path_48"
                    data-name="Path 48"
                    d="M184.56,226.74a1.4,1.4,0,0,0-1.54,1.93,3.27,3.27,0,0,0,2.86,2.09l51.39,1.31c1.21,0,2.2-.62,2.2-1.45s-1-1.54-2.21-1.59Z"
                    fill="#e4e3e3"
                />
                <path
                    id="Path_49"
                    data-name="Path 49"
                    d="M184.35,235.2a1.41,1.41,0,0,0-1.55,1.93,3.29,3.29,0,0,0,2.86,2.08l51.39,1.31c1.22,0,2.21-.62,2.2-1.44s-1-1.55-2.21-1.6Z"
                    fill="#e4e3e3"
                />
                <path
                    id="Path_50"
                    data-name="Path 50"
                    d="M241.23,281.41c1.21,0,2.17-.61,2.14-1.44s-1.05-1.54-2.27-1.58l-124.72-4.16c-1.21,0-1.61.72-.88,1.69l.44.57a5.18,5.18,0,0,0,3.53,1.82Z"
                    fill="#e4e3e3"
                />
                <path
                    id="Path_51"
                    data-name="Path 51"
                    d="M116.17,282.56c-1.22,0-1.61.72-.88,1.69l.44.58a5.21,5.21,0,0,0,3.53,1.81L241,289.75c1.22,0,2.18-.62,2.15-1.45s-1.06-1.53-2.27-1.57Z"
                    fill="#e4e3e3"
                />
                <path
                    id="Path_52"
                    data-name="Path 52"
                    d="M131.3,315.17h0l-10.61-.37a2.88,2.88,0,0,1-2.87-2.53l-.71-8.92a2,2,0,0,1,.5-1.46,2.31,2.31,0,0,1,1.67-.77h0c.89,0,2.15-.12,3.49-.2,3-.17,6.31-.37,8.28-.35a3,3,0,0,1,2.94,2.18c.5,2.06.35,5.93.14,10.05A2.65,2.65,0,0,1,131.3,315.17Zm-12-13.18a1.45,1.45,0,0,0-1,.47,1.12,1.12,0,0,0-.29.82l.71,8.92a2,2,0,0,0,2,1.73l10.61.37h0a1.77,1.77,0,0,0,1.91-1.55c.21-4.05.36-7.85-.12-9.8a2.14,2.14,0,0,0-2.07-1.51h0c-1.93,0-5.41.19-8.21.35-1.34.08-2.6.16-3.51.2Z"
                    fill="#707272"
                />
                <path
                    id="Path_53"
                    data-name="Path 53"
                    d="M142.19,306.19a1.61,1.61,0,0,0-1.7,2.08l.25,1.05a3,3,0,0,0,2.71,2.18l95.3,1.43A2.08,2.08,0,0,0,241,311a2.16,2.16,0,0,0-2.19-2Z"
                    fill="#e4e3e3"
                />
                <path
                    id="Path_54"
                    data-name="Path 54"
                    d="M120.83,304.31h0a1.63,1.63,0,0,1,2.23.67l2,3.81a.53.53,0,0,0,.94,0l5.48-11.42a.58.58,0,0,1,1.1.28,22.8,22.8,0,0,1-7,15.7s-3.08-1.29-5.44-6.85A1.7,1.7,0,0,1,120.83,304.31Z"
                    fill="#707272"
                />
                <path
                    id="Path_55"
                    data-name="Path 55"
                    d="M130.73,337.9h0l-10.61-.37a2.87,2.87,0,0,1-2.87-2.52l-.71-8.93a2,2,0,0,1,.5-1.46,2.39,2.39,0,0,1,1.67-.77h0l3.49-.19c3-.18,6.31-.38,8.28-.35a3,3,0,0,1,2.94,2.17c.5,2.06.36,5.93.14,10a2.64,2.64,0,0,1-2.83,2.42Zm-12-13.18a1.46,1.46,0,0,0-1,.48,1.09,1.09,0,0,0-.29.81l.71,8.93a2,2,0,0,0,2,1.72l10.61.37h0a1.77,1.77,0,0,0,1.91-1.54c.21-4.06.36-7.85-.12-9.8a2.13,2.13,0,0,0-2.07-1.51h0c-1.94,0-5.42.18-8.22.35l-3.51.19Z"
                    fill="#707272"
                />
                <path
                    id="Path_56"
                    data-name="Path 56"
                    d="M119.83,325.64c2.23-.1,7.09-.46,9.39-.43a2.06,2.06,0,0,1,2,1.47c.4,1.65.26,4.91.1,7.93a1.75,1.75,0,0,1-1.9,1.56l-8.48-.29a2,2,0,0,1-2-1.7l-.57-7.13a1.39,1.39,0,0,1,1.46-1.41Z"
                    fill="#e4e3e3"
                />
                <path
                    id="Path_57"
                    data-name="Path 57"
                    d="M141.61,328.92a1.62,1.62,0,0,0-1.7,2.09l.25,1a3,3,0,0,0,2.71,2.18l95.3,1.43a2.08,2.08,0,0,0,2.21-1.94,2.17,2.17,0,0,0-2.19-2.05Z"
                    fill="#e4e3e3"
                />
                <path
                    id="Path_58"
                    data-name="Path 58"
                    d="M130.15,360.64h0l-10.61-.38a2.87,2.87,0,0,1-2.87-2.52l-.71-8.92a2,2,0,0,1,.51-1.47,2.35,2.35,0,0,1,1.66-.77h0c.89,0,2.15-.11,3.48-.19,2.82-.17,6.32-.38,8.29-.35a3,3,0,0,1,2.94,2.18c.5,2.06.36,5.92.15,10a2.65,2.65,0,0,1-2.84,2.42Zm-.26-13.73h0c-1.94,0-5.42.18-8.22.35-1.34.08-2.6.16-3.5.19h0a1.46,1.46,0,0,0-1,.48,1.09,1.09,0,0,0-.29.82l.71,8.92a2,2,0,0,0,2,1.72l10.6.38h0a1.8,1.8,0,0,0,1.92-1.55c.2-4.05.35-7.85-.13-9.8A2.14,2.14,0,0,0,129.89,346.91Z"
                    fill="#707272"
                />
                <path
                    id="Path_59"
                    data-name="Path 59"
                    d="M119.25,348.37c2.23-.09,7.09-.46,9.39-.43a2.06,2.06,0,0,1,2,1.47c.4,1.65.26,4.92.1,7.93a1.76,1.76,0,0,1-1.9,1.57l-8.48-.3a2,2,0,0,1-2-1.7l-.57-7.13a1.39,1.39,0,0,1,1.46-1.41Z"
                    fill="#e4e3e3"
                />
                <path
                    id="Path_60"
                    data-name="Path 60"
                    d="M141,351.66a1.61,1.61,0,0,0-1.7,2.08l.25,1a3,3,0,0,0,2.71,2.18l95.3,1.44a2.09,2.09,0,0,0,2.22-1.95,2.17,2.17,0,0,0-2.2-2Z"
                    fill="#e4e3e3"
                />
                <path
                    id="Path_61"
                    data-name="Path 61"
                    d="M129.57,383.37h0l-10.6-.37a2.89,2.89,0,0,1-2.88-2.53l-.71-8.92a2,2,0,0,1,.51-1.46,2.27,2.27,0,0,1,1.66-.77c.89,0,2.15-.12,3.48-.2,2.82-.16,6.32-.37,8.29-.35a3,3,0,0,1,2.94,2.18c.5,2.06.36,5.93.15,10.05a2.65,2.65,0,0,1-2.84,2.37Zm-.26-13.72h0c-1.94,0-5.42.19-8.22.35-1.34.08-2.6.16-3.5.2h0a1.49,1.49,0,0,0-1,.47,1.12,1.12,0,0,0-.29.82l.71,8.92a2,2,0,0,0,2,1.73l10.6.37h0a1.78,1.78,0,0,0,1.92-1.55c.2-4.05.35-7.85-.12-9.8a2.14,2.14,0,0,0-2.1-1.51Z"
                    fill="#707272"
                />
                <path
                    id="Path_62"
                    data-name="Path 62"
                    d="M118.67,371.11c2.23-.1,7.09-.47,9.39-.44a2.08,2.08,0,0,1,2,1.48c.4,1.65.26,4.91.1,7.92a1.75,1.75,0,0,1-1.9,1.57l-8.48-.3a2,2,0,0,1-2-1.69l-.56-7.13a1.37,1.37,0,0,1,1.45-1.41Z"
                    fill="#e4e3e3"
                />
                <path
                    id="Path_63"
                    data-name="Path 63"
                    d="M140.45,374.39a1.61,1.61,0,0,0-1.7,2.08l.25,1a3,3,0,0,0,2.71,2.18l95.3,1.43a2.09,2.09,0,0,0,2.22-1.95,2.17,2.17,0,0,0-2.2-2Z"
                    fill="#e4e3e3"
                />
                <path
                    id="Path_64"
                    data-name="Path 64"
                    d="M185.45,164.26c-5.28-3.87-9.95-3.92-13.27-3a6.55,6.55,0,0,0-4.83,6.77l1,15.52,16.84,1.93S195.8,171.86,185.45,164.26Zm-7.74,12.3a4.21,4.21,0,1,1,4.31-4.1,4.21,4.21,0,0,1-4.31,4.1Z"
                    fill="#5c5c5c"
                />
                <path
                    id="Path_65"
                    data-name="Path 65"
                    d="M170.22,177.08a.43.43,0,0,1-.42-.41l-.16-2.47a.43.43,0,1,1,.86,0l.17,2.47a.44.44,0,0,1-.41.46Zm-.32-4.84a.43.43,0,0,1-.42-.41l-.12-1.88a6.28,6.28,0,0,1,4.62-6.48,12.71,12.71,0,0,1,6.19-.1.44.44,0,1,1-.19.85,11.73,11.73,0,0,0-5.77.09,5.42,5.42,0,0,0-4,5.59l.13,1.87a.45.45,0,0,1-.41.47Z"
                    fill="#fff"
                />
                <path
                    id="Path_66"
                    data-name="Path 66"
                    d="M145.08,192.94l.2-7.79a4.75,4.75,0,0,1,5-4.62l53.62,3.21a4.61,4.61,0,0,1,4.34,4.45l.26,7.49Z"
                    fill="#5c5c5c"
                />
                <path
                    id="Path_67"
                    data-name="Path 67"
                    d="M163,183.82a.45.45,0,0,1-.43-.45.44.44,0,0,1,.45-.42l2.31.06h0a.42.42,0,0,1,.42.44.43.43,0,0,1-.45.43Z"
                    fill="#fff"
                />
                <path
                    id="Path_68"
                    data-name="Path 68"
                    d="M148.05,190.6a.45.45,0,0,1-.43-.45l.08-3.15a4.46,4.46,0,0,1,4.56-4.33l8.48.22h0a.45.45,0,0,1,.43.45.44.44,0,0,1-.45.42l-8.48-.21a3.57,3.57,0,0,0-3.66,3.47l-.09,3.15a.44.44,0,0,1-.44.43Z"
                    fill="#fff"
                />
                <path
                    id="Path_69"
                    data-name="Path 69"
                    d="M128.34,189.66l93.84,2.39a6.94,6.94,0,0,1,6.68,5.93l.07.46a6,6,0,0,1-6.05,6.82l-96.26-2.45a3.88,3.88,0,0,1-3.77-4.16l.32-4.3A5.05,5.05,0,0,1,128.34,189.66Z"
                    fill="#707272"
                />
                <path
                    id="Path_70"
                    data-name="Path 70"
                    d="M329.18,463.75c4.23.37,11.59,1,17.38,1.22,3.48.15,5.49-.19,6.64-.65v.36a1.2,1.2,0,0,1-1.14,1.14,203.117,203.117,0,0,1-22.75,0,.85.85,0,0,1-.7-1.28,7.127,7.127,0,0,1,.57-.79Z"
                    fill="#5c5c5c"
                />
                <path
                    id="Path_71"
                    data-name="Path 71"
                    d="M346.56,465c-5.79-.26-13.15-.85-17.38-1.22,4.16-5.11,17.38-8.55,17.38-8.55h3.73a15.61,15.61,0,0,1,2.91,9.12A18.215,18.215,0,0,1,346.56,465Z"
                    fill="#707272"
                />
                <path
                    id="Path_72"
                    data-name="Path 72"
                    d="M350.92,449.11l-.35,6.91a1.57,1.57,0,0,1-1.35,1.48,6.11,6.11,0,0,1-2.8-.23,1.89,1.89,0,0,1-1.26-1.44l-1.2-6.72Z"
                    fill="#e4e3e3"
                />
                <path
                    id="Path_73"
                    data-name="Path 73"
                    d="M399.4,464.92a3.36,3.36,0,0,0,3-2.23h0a2.39,2.39,0,0,1-1.61,2.72,10.559,10.559,0,0,1-4.45.7,4.44,4.44,0,0,1-3.34-1.54h0C394.4,465.26,396.34,465.34,399.4,464.92Z"
                    fill="#5c5c5c"
                />
                <path
                    id="Path_74"
                    data-name="Path 74"
                    d="M390.74,449.06h3.08c4.69-.09,7.64,9.8,8.6,13.63a3.36,3.36,0,0,1-3,2.23c-3.06.42-5,.34-6.38-.34-2.54-3.34-3.79-8.22-4.4-11.07A3.89,3.89,0,0,1,390.74,449.06Z"
                    fill="#707272"
                />
                <path
                    id="Path_75"
                    data-name="Path 75"
                    d="M393.43,442.65l1.71,6.79a1.86,1.86,0,0,1-1,2.1,6.57,6.57,0,0,1-3.54.85,1.54,1.54,0,0,1-1.26-1l-2.51-7.15Z"
                    fill="#e4e3e3"
                />
                <path
                    id="Path_76"
                    data-name="Path 76"
                    d="M395.47,444.61c-2.13,1.89-9.05,2.14-9.05,2.14-24.91-59.81-35.11-68.3-35.11-68.3,3.6,22.57.58,72.54.58,72.54-4.11,1.82-9.41,0-9.41,0a385.072,385.072,0,0,0-12.72-94.59c16.84-.38,25.79-6.14,28.72-8.45C372.51,370.58,395.47,444.61,395.47,444.61Z"
                    fill="#5c5c5c"
                />
                <path
                    id="Path_77"
                    data-name="Path 77"
                    d="M354.2,279.17c3.19,15.9,2,44.36,1.29,57.34-.21,3.65,2.34,8.71,4.4,12.16a1.08,1.08,0,0,1-.44,1.52c-3.44,1.77-15.12,7.26-28.65,7.94a1.9,1.9,0,0,1-1.94-1.44c-10-37.48-21.7-61.43-21.7-61.43C320,274.71,354.2,279.17,354.2,279.17Z"
                    fill="#707272"
                />
                <path
                    id="Path_78"
                    data-name="Path 78"
                    d="M349.61,377a.81.81,0,0,0,.42-.12.79.79,0,0,0,.26-1.09,25,25,0,0,0-9.88-8.58.79.79,0,0,0-1.06.37.8.8,0,0,0,.37,1.07,23.83,23.83,0,0,1,9.21,8,.8.8,0,0,0,.68.35Z"
                    opacity="0.5"
                />
                <path
                    id="Path_79"
                    data-name="Path 79"
                    d="M374.19,324.81c-1,7.4-4.05,15.48-10.1,24.06l-2.3-1.87a71,71,0,0,0,1.15-23C366.67,323.27,371.08,324,374.19,324.81Z"
                    fill="#f4f5f6"
                />
                <path
                    id="Path_80"
                    data-name="Path 80"
                    d="M350.64,298.79l3.56-19.62s23.79,18.19,20,45.64c-3.11-.79-7.52-1.54-11.25-.81C361.85,315.19,358.6,305.62,350.64,298.79Z"
                    fill="#707272"
                />
                <path
                    id="Path_81"
                    data-name="Path 81"
                    d="M307.16,295.26,316.34,315c-1.48,11.94-7.9,17.1-14.81,19.08a41.829,41.829,0,0,0-3.09-12.88C307.28,312.12,307.16,295.26,307.16,295.26Z"
                    fill="#5c5c5c"
                />
                <path
                    id="Path_82"
                    data-name="Path 82"
                    d="M298.44,321.24a41.83,41.83,0,0,1,3.09,12.88c-18.45,7.16-23.34-6.73-23.34-6.73l3.41-.6C289.3,327.6,294.68,325.12,298.44,321.24Z"
                    fill="#f4f5f6"
                />
                <path
                    id="Path_83"
                    data-name="Path 83"
                    d="M315.62,270.85l4.43,16.29a3.17,3.17,0,0,0,4.24,2.11,62.835,62.835,0,0,0,6.39-2.92,4.27,4.27,0,0,0,1.51-6.14L323,266.45Z"
                    fill="#f4f5f6"
                />
                <path
                    id="Path_84"
                    data-name="Path 84"
                    d="M269.74,316.49l113.59,41.34a8.71,8.71,0,0,1,4.94,11.78l-.34.75a8.08,8.08,0,0,1-10.76,4l-110.29-51Z"
                    fill="#707272"
                />
                <ellipse
                    id="Ellipse_2"
                    data-name="Ellipse 2"
                    cx="56.07"
                    cy="55.06"
                    rx="56.07"
                    ry="55.06"
                    transform="translate(161.46 236.56)"
                    fill="#707272"
                    opacity="0.1"
                />
                <path
                    id="Path_85"
                    data-name="Path 85"
                    d="M155.13,291.62c0,33.84,27.94,61.27,62.4,61.27s62.39-27.43,62.39-61.27S252,230.35,217.53,230.35,155.13,257.78,155.13,291.62Zm9.17,0c0-28.87,23.83-52.27,53.23-52.27s53.23,23.4,53.23,52.27-23.84,52.27-53.23,52.27S164.3,320.49,164.3,291.62Z"
                    fill="#707272"
                />
                <path
                    id="Path_86"
                    data-name="Path 86"
                    d="M275.18,299.14h-.05a.36.36,0,0,1-.3-.39c.18-1.43.31-2.89.38-4.34a.351.351,0,1,1,.7.04c-.07,1.46-.2,2.94-.38,4.39A.36.36,0,0,1,275.18,299.14ZM274.75,282a.34.34,0,0,1-.34-.29,56.06,56.06,0,0,0-3.32-11.36h0a57.54,57.54,0,0,0-53.55-35.44,58.34,58.34,0,0,0-31,8.87.352.352,0,0,1-.37-.6,59,59,0,0,1,31.39-9,58.23,58.23,0,0,1,54.2,35.88h0a56.281,56.281,0,0,1,3.37,11.51.35.35,0,0,1-.29.4Z"
                    fill="#fff"
                />
                <path
                    id="Path_87"
                    data-name="Path 87"
                    d="M305.92,258.47c0,.28,3.7,18.59,3.7,18.59L320,274.71l-1-17.89S306.6,253.78,305.92,258.47Z"
                    fill="#f4f5f6"
                />
                <path
                    id="Path_88"
                    data-name="Path 88"
                    d="M316.72,260.8l1.25,9.38a1.45,1.45,0,0,1,0,.36c-.11.73-.65,3.24-3.18,2.52-2.29-.65-4.39-1.21-5.84-.76a1.31,1.31,0,0,0-.91,1.52,57,57,0,0,0,2.39,8.54,1.32,1.32,0,0,0,1.6.78,42.642,42.642,0,0,0,9.18-3.76,1.32,1.32,0,0,0,.65-1.19c-.1-2.36-.38-9.56-.15-11.58s2.54-3,3.55-3.43a1.29,1.29,0,0,0,.73-.72c.46-1.18,1.11-3.89-2.31-5a1.32,1.32,0,0,1-.83-1.71c.5-1.41.71-3.56-1.93-4.74a4.84,4.84,0,0,0-5.77,1.39,1.33,1.33,0,0,1-1.69.2,1,1,0,0,0-1.19,0,1.3,1.3,0,0,1-1.84-.56,3.94,3.94,0,0,0-4.58-2.28c-4.67.8-5.2,6-4,9s6.24,1.87,8.76.35c1.91-1.17,4.39.05,5.46.7a1.29,1.29,0,0,1,.65.99Z"
                    fill="#707272"
                />
                <path
                    id="Path_89"
                    data-name="Path 89"
                    d="M322.13,270s-1.92-5.37.05-6.68,4.89-.64,4.25,3.25C325.63,271.45,322.13,270,322.13,270Z"
                    fill="#f4f5f6"
                />
                <path
                    id="Path_90"
                    data-name="Path 90"
                    d="M362.74,346.67,352,345.8l-4.65,4.28s1.1,1.52,4.54.65a49.832,49.832,0,0,1,8.15-1.09s4.75,2.14,4.81-1.86Z"
                    fill="#f4f5f6"
                />
                <path
                    id="Path_91"
                    data-name="Path 91"
                    d="M279.56,329.93c-.93.73-4.11-.79-6.06-1.83a2.26,2.26,0,0,1-1.08-2.65c.47-1.48,1.25-3.38,2.21-3.62C276.23,321.43,283.39,326.94,279.56,329.93Z"
                    fill="#f4f5f6"
                />
                <path
                    id="Path_92"
                    data-name="Path 92"
                    d="M381.09,372.7a.34.34,0,0,1-.33-.24A.35.35,0,0,1,381,372c3.24-1.11,4.93-2.93,5-5.39a6,6,0,0,0-3.92-5.76l-13.64-5.32a.33.33,0,0,1-.19-.45.35.35,0,0,1,.45-.2l13.64,5.32a6.68,6.68,0,0,1,4.36,6.44c-.12,2.78-2,4.81-5.51,6Z"
                    fill="#fff"
                />
                <path
                    id="Path_93"
                    data-name="Path 93"
                    d="M364.38,353.93h-.13l-1.77-.69a.355.355,0,1,1,.26-.66l1.77.69a.35.35,0,0,1,.19.46.33.33,0,0,1-.32.2Z"
                    fill="#fff"
                />
                <circle
                    id="Ellipse_3"
                    data-name="Ellipse 3"
                    cx="8.88"
                    cy="8.88"
                    r="8.88"
                    transform="translate(351.16 53.19)"
                    fill="#fff"
                    stroke="#e2e2e2"
                    strokeMiterlimit="10"
                    strokeWidth="2"
                />
                <circle
                    id="Ellipse_4"
                    data-name="Ellipse 4"
                    cx="12.72"
                    cy="12.72"
                    r="12.72"
                    transform="translate(386.01 13.54)"
                    fill="#fff"
                    stroke="#e2e2e2"
                    strokeMiterlimit="10"
                    strokeWidth="2"
                />
                <path
                    id="Path_94"
                    data-name="Path 94"
                    d="M23.18,351.65h-7v-7a.87.87,0,0,0-.87-.87h0a.88.88,0,0,0-.89.87v7h-7a.89.89,0,0,0,0,1.77h7v7a.88.88,0,0,0,.89.87.87.87,0,0,0,.87-.87h0v-7h7a.89.89,0,0,0,.88-.88.9.9,0,0,0-.88-.89Z"
                    fill="#e2e2e2"
                    stroke="#e2e2e2"
                    strokeMiterlimit="10"
                    strokeWidth="0.75"
                />
                <path
                    id="Path_95"
                    data-name="Path 95"
                    d="M106,22.86H97.2V14a1.09,1.09,0,0,0-1.1-1.09h0A1.11,1.11,0,0,0,95,14v8.83H86.17a1.135,1.135,0,0,0,0,2.27H95v8.84A1.12,1.12,0,0,0,96.1,35a1.1,1.1,0,0,0,1.1-1.1h0V25.08H106a1.11,1.11,0,1,0,0-2.22Z"
                    fill="#e2e2e2"
                    stroke="#e2e2e2"
                    strokeMiterlimit="10"
                    strokeWidth="0.75"
                />
            </g>
        </SVGIcon>
    );
}
