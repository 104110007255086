import React from 'react';

import { Div } from '../../UIKit/index';
import PropTypes from 'prop-types';
import './style.scss';

const PageView = ({ classes, children, noSidebar, innerRef }) => (
    <Div
        className={`PCRViewMain ${classes?.main ? classes.main : ''} ${
            noSidebar ? ' noSidebar' : 'WithSidebar'
        }`}
    >
        <Div className={`PCRViewContainer ${classes?.container ? classes.container : ''}`}>
            <Div className={`PCRVBInner ${classes?.row ? classes.row : ''}`} reference={innerRef}>
                {children}
            </Div>
        </Div>
    </Div>
);

PageView.propTypes = {
    classes: PropTypes.object,
    children: PropTypes.element,
    noSidebar: PropTypes.bool,
    innerRef: PropTypes.object,
};

export default PageView;

// noSidebar
