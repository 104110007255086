import log from 'loglevel';
import remote from 'loglevel-plugin-remote';

const customJSON = log => ({
    timestamp: new Date().toISOString(),
    domain: document.location.href,
    message: log.message,
    level: log.level.label,
    stacktrace: log.stacktrace,
});

if (process.env.REACT_APP_SEND_LOGS === 'true') {
    const apiPath = `http://localhost:4000/logger`;
    remote.apply(log, {
        format: customJSON,
        interval: 1000 * 60 * 5,
        url: apiPath,
        stacktrace: {
            depth: 3,
            excess: 1,
        },
    });
}

export default log;
