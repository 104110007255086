import PhoneNumber from 'Modules/PhoneNumber/phoneNumber';
import useToast from 'Modules/Toasts';
import React, { useCallback, useEffect, useState } from 'react';
import { Button, Div, FieldControl, Label, LabelControl } from 'UIKit';
import { convertHTMLToText, sanitizePhoneNumber } from 'Utils';

const TestSMS = ({ editorState, editorHtml, apiCb: smsApi, origin, from }) => {
    const { showToast } = useToast();

    const [loading, setLoading] = useState(false);
    const [testPhone, setTestPhone] = useState('');
    const [testPhoneError, setTestPhoneError] = useState(false);
    const [disabledTestButton, setDisabledTestButton] = useState(false);

    const SendtestSMS = useCallback(async () => {
        setLoading(true);
        try {
            if (!from) {
                throw new Error('No phone selected to send from.');
            }
            if (editorState.getCurrentContent().getPlainText('\u0001').length === 0) {
                throw new Error('SMS body cannot be empty.');
            }
            const body = {
                origin,
                type: 'SMS',
                recipients: [{ phone: sanitizePhoneNumber('+' + testPhone) }],
                contents: convertHTMLToText(editorHtml),
                from,
            };
            const response = await smsApi(body);

            if (response.success) {
                showToast({
                    type: 'success',
                    message: 'SMS Sent Successfully',
                });
            }
        } catch (error) {
            showToast({
                type: 'error',
                message: error.message || 'Something went wrong.Please try again',
            });
        } finally {
            setLoading(false);
        }
    }, [editorHtml, editorState, from, origin, showToast, smsApi, testPhone]);

    useEffect(() => {
        if (loading) {
            setDisabledTestButton(true);
        } else {
            if (testPhone.length && editorHtml.length && !testPhoneError) {
                setDisabledTestButton(false);
            } else {
                setDisabledTestButton(true);
            }
        }
    }, [editorHtml.length, editorState, from, loading, testPhone, testPhoneError]);

    return (
        <FieldControl>
            <LabelControl>
                <Label>Test your SMS:</Label>
            </LabelControl>
            <Div className={'CESFTestForm'}>
                <PhoneNumber
                    inTop={true}
                    value={testPhone}
                    onChange={(value, country, e, formattedValue, errorState) => {
                        setTestPhoneError(errorState);
                        setTestPhone(value.length === 0 ? '' : formattedValue);
                    }}
                    // error={testPhoneError}
                />
                <Button
                    buttonType={'BlueFillBtn'}
                    onClick={SendtestSMS}
                    disabled={disabledTestButton}
                >
                    Send Test
                </Button>
            </Div>
        </FieldControl>
    );
};

export default TestSMS;
