import { CallAPI } from 'Utils/apiCall';
import config from 'config';

const baseUri = `${config.REACT_APP_API_BASE_URL}/${process.env.REACT_APP_API_VERSION}`;
const api = new CallAPI();

export const getBrandingData = async acc_id => {
    return api.requestPublic('GET', `${baseUri}/accounts/${acc_id}/branding`);
};

export const getAccounts = async (email, id) => {
    return api.requestPublic('GET', `${baseUri}/auth/users/${email}/accounts?account=${id}`);
};

export const sendSubAccountEmail = async body => {
    return api.requestPublic('POST', `${baseUri}/accounts/email`, body);
};

export const createSubAccountProfile = async body => {
    return api.requestPublic('POST', `${baseUri}/accounts/public-profile`, body);
};

export const getPublicAccountRequestData = async token => {
    return api.requestPublic('GET', `${baseUri}/accounts/public-account-requests/${token}`);
};

export const sendOTPForPhone = async body => {
    return api.requestPublic('POST', `${baseUri}/users/recovery`, body);
};

export const login = async credentials => {
    return api.requestPublic('POST', `${baseUri}/auth/login`, credentials);
};

export const logout = async credentials => {
    return api.request('DELETE', `${baseUri}/auth/logout`, credentials);
};

export const sendForgetPasswordMail = async credentials => {
    return api.requestPublic('POST', `${baseUri}/auth/password/reset`, credentials);
};

export const resetPassword = async credentials => {
    return api.requestPublic('POST', `${baseUri}/auth/password/reset`, credentials);
};

export const ssoLogin = accountId => {
    return api.request('POST', `${baseUri}/auth/sso`, { account: accountId });
};
