import React, { Fragment, useCallback, useState } from 'react';

import DCChatReview from 'Assets/icons/DCChatReview';
import DCSourceReview from 'Assets/icons/DCSourceReview';
import DCTwoStar from 'Assets/icons/DCTwoStar';
import DCUserReview3 from 'Assets/icons/DCUserReview3';
import { Button, Div, Image, Strong } from 'UIKit/index';

import UserReviewBox from './UserReviewBox';
import NoFound from '../noFound';
import HowToDialog from '../howToDialog';
import generateImage from 'Utils/generateImage';
import { abbreviateNumber } from 'Utils/stringUtils';

const Reviews = ({ data, details, user, handleGetInTouchOpen }) => {
    const [howToDialog, setHowToDialog] = useState(false);
    const handleHowToDialogOpen = useCallback(() => {
        setHowToDialog(true);
    }, []);

    const handleHowToDialogClose = useCallback(() => {
        setHowToDialog(false);
    }, []);

    return (
        <Fragment>
            <HowToDialog
                open={howToDialog}
                onClose={handleHowToDialogClose}
                title={'How we calculate the Reviews grade'}
                description={
                    <Fragment>
                        Your <Strong>Reviews score</Strong> is calculated by adding together the
                        following custom metrics which are created by our weigh algorithm; reviews
                        found in the last 6-months, industry reviews found in the last 6-months,
                        average review score and average review sources.
                    </Fragment>
                }
            />
            <Div className={'EPComponent'}>
                <Div className={'EPCHead'}>
                    <Div className={'EPCHTBox'}>
                        <Div className={'EPCHTBIcon Grade' + (data?.grade || 'F')}>
                            {data?.grade || 'F'}
                        </Div>
                        <Div className={'EPCHTBTitle'}>Reviews</Div>
                        <Div className={'EPCHTBSTitle'}>
                            What are customers saying about your business?
                        </Div>
                    </Div>
                    <Div className={'EPCHSTitle'} onClick={handleHowToDialogOpen}>
                        How we calculated this grade?
                    </Div>
                </Div>
                <Div className={'EPCBody'}>
                    <Div className={'EPCVideoContent'}>
                        <Div className={'EPCVCVideo'}>
                            <iframe
                                src="https://player.vimeo.com/video/356237773"
                                title="video"
                                frameBorder="0"
                                webkitallowfullscreen=""
                                mozallowfullscreen=""
                            />
                        </Div>
                        <Div className={'EPCVCContent'}>
                            <Div className={'EPCVCCDes'}>
                                88% of consumers trust online reviews as much as personal
                                recommendations. It’s important to know what people are saying about{' '}
                                {details?.name}. Good reviews can help skyrocket your business,
                                whereas bad reviews can severely damage it.
                            </Div>
                            <br />
                            <Div className={'EPCVCCDes'}>
                                We scanned the 3 major review platforms, Google, Facebook and Yelp
                                to see what people are saying about {details?.name} online.
                            </Div>
                        </Div>
                    </Div>

                    {Object.keys(data?.googleReviews || {}).length !== 0 ||
                    Object.keys(data?.facebookReviews || {}).length !== 0 ||
                    Object.keys(data?.yelpReviews || {}).length !== 0 ? (
                        <Fragment>
                            <Div className={'EPCInnerHeading'}>
                                <Div className={'EPCIHLTitle'}>Total Online Reviews Found</Div>
                                <Div className={'EPCIHRTitle'}>
                                    Your Business’s Online Review Overview
                                </Div>
                            </Div>
                            <Div className={'EPCRow'}>
                                <Div className={'EPCRCol5'}>
                                    <Div className={'EPCReviews'}>
                                        <Div
                                            className={'EPCReviewBox'}
                                            style={{ backgroundColor: '#fff7eb' }}
                                        >
                                            <Div
                                                className={'EPCRBHead'}
                                                style={{ borderColor: '#f2eadf' }}
                                            >
                                                <Div className={'EPCRBHInfo'}>
                                                    <Div className={'EPCRBHITitle'}>
                                                        Reviews Found
                                                    </Div>
                                                    <Div className={'EPCRBHISTitle'}>
                                                        {abbreviateNumber(
                                                            data?.totalOnlineReviews?.reviewsFound
                                                                ?.text,
                                                        )}
                                                    </Div>
                                                </Div>
                                                <Div className={'EPCRBHIcon'}>
                                                    <DCTwoStar />
                                                </Div>
                                            </Div>
                                            <Div className={'EPCRBBody'}>
                                                <Div className={'EPCRBBCol'}>
                                                    <Div className={'EPCRBBCTitle'}>
                                                        Total Reviews
                                                    </Div>
                                                    <Div className={'EPCRBBCNum'}>
                                                        {abbreviateNumber(
                                                            data?.totalOnlineReviews?.reviewsFound
                                                                ?.totalReviews,
                                                        )}
                                                    </Div>
                                                </Div>
                                                <Div className={'EPCRBBCol'}>
                                                    <Div className={'EPCRBBCTitle'}>
                                                        Industry Avg.
                                                    </Div>
                                                    <Div className={'EPCRBBCNum'}>
                                                        {abbreviateNumber(
                                                            data?.totalOnlineReviews?.reviewsFound
                                                                ?.industryAvg,
                                                        )}
                                                    </Div>
                                                </Div>
                                            </Div>
                                        </Div>
                                        <Div
                                            className={'EPCReviewBox'}
                                            style={{ backgroundColor: '#eef5ff' }}
                                        >
                                            <Div
                                                className={'EPCRBHead'}
                                                style={{ borderColor: '#e2e5ff' }}
                                            >
                                                <Div className={'EPCRBHInfo'}>
                                                    <Div className={'EPCRBHITitle'}>
                                                        Reviews Found
                                                    </Div>
                                                    <Div className={'EPCRBHISTitle'}>
                                                        (in last 6 month)
                                                    </Div>
                                                </Div>
                                                <Div
                                                    className={'EPCRBHIcon'}
                                                    style={{ color: '#3F50F6' }}
                                                >
                                                    <DCChatReview />
                                                </Div>
                                            </Div>
                                            <Div className={'EPCRBBody'}>
                                                <Div className={'EPCRBBCol'}>
                                                    <Div className={'EPCRBBCTitle'}>
                                                        Total Reviews
                                                    </Div>
                                                    <Div className={'EPCRBBCNum'}>
                                                        {abbreviateNumber(
                                                            data?.totalOnlineReviews
                                                                ?.reviewsFoundLastSix
                                                                ?.totalLastSixMonths,
                                                        )}
                                                    </Div>
                                                </Div>
                                                <Div className={'EPCRBBCol'}>
                                                    <Div className={'EPCRBBCTitle'}>
                                                        Industry Avg.
                                                    </Div>
                                                    <Div className={'EPCRBBCNum'}>
                                                        {abbreviateNumber(
                                                            data?.totalOnlineReviews
                                                                ?.reviewsFoundLastSix?.industryAvg,
                                                        )}
                                                    </Div>
                                                </Div>
                                            </Div>
                                        </Div>
                                        <Div
                                            className={'EPCReviewBox'}
                                            style={{ backgroundColor: '#fff2f3' }}
                                        >
                                            <Div
                                                className={'EPCRBHead'}
                                                style={{ borderColor: '#e2e5ff' }}
                                            >
                                                <Div className={'EPCRBHInfo'}>
                                                    <Div className={'EPCRBHITitle'}>
                                                        Review Score
                                                    </Div>
                                                    <Div className={'EPCRBHISTitle'} />
                                                </Div>
                                                <Div
                                                    className={'EPCRBHIcon'}
                                                    style={{ color: '#E92C34' }}
                                                >
                                                    <DCUserReview3 />
                                                </Div>
                                            </Div>
                                            <Div className={'EPCRBBody'}>
                                                <Div className={'EPCRBBCol'}>
                                                    <Div className={'EPCRBBCTitle'}>
                                                        Your Rating
                                                    </Div>
                                                    <Div className={'EPCRBBCNum'}>
                                                        {
                                                            data?.totalOnlineReviews
                                                                ?.averageReviewScore?.yourRating
                                                        }
                                                    </Div>
                                                </Div>
                                                <Div className={'EPCRBBCol'}>
                                                    <Div className={'EPCRBBCTitle'}>
                                                        Industry Avg.
                                                    </Div>
                                                    <Div className={'EPCRBBCNum'}>
                                                        {abbreviateNumber(
                                                            data?.totalOnlineReviews
                                                                ?.averageReviewScore?.industryAvg,
                                                        )}
                                                    </Div>
                                                </Div>
                                            </Div>
                                        </Div>
                                        <Div
                                            className={'EPCReviewBox'}
                                            style={{ backgroundColor: '#e4fbf8' }}
                                        >
                                            <Div
                                                className={'EPCRBHead'}
                                                style={{ borderColor: '#d3efeb' }}
                                            >
                                                <Div className={'EPCRBHInfo'}>
                                                    <Div className={'EPCRBHITitle'}>
                                                        Sources For Reviews
                                                    </Div>
                                                    <Div className={'EPCRBHISTitle'} />
                                                </Div>
                                                <Div
                                                    className={'EPCRBHIcon'}
                                                    style={{ color: '#02CCCD' }}
                                                >
                                                    <DCSourceReview />
                                                </Div>
                                            </Div>
                                            <Div className={'EPCRBBody'}>
                                                <Div className={'EPCRBBCol'}>
                                                    <Div className={'EPCRBBCTitle'}>
                                                        Your Rating
                                                    </Div>
                                                    <Div className={'EPCRBBCNum'}>
                                                        {
                                                            data?.totalOnlineReviews
                                                                ?.sourcesForReviews?.yourRating
                                                        }
                                                    </Div>
                                                </Div>
                                                <Div className={'EPCRBBCol'}>
                                                    <Div className={'EPCRBBCTitle'}>
                                                        Industry Avg.
                                                    </Div>
                                                    <Div className={'EPCRBBCNum'}>
                                                        {abbreviateNumber(
                                                            data?.totalOnlineReviews
                                                                ?.sourcesForReviews?.industryAvg,
                                                        )}
                                                    </Div>
                                                </Div>
                                            </Div>
                                        </Div>
                                    </Div>
                                </Div>
                                <Div className={'EPCRCol5'}>
                                    <Div className={'EPCRComment'}>
                                        {data?.totalOnlineReviews &&
                                            data.totalOnlineReviews.reviews?.map((rev, i) => (
                                                <UserReviewBox
                                                    companyName={details?.name}
                                                    key={i}
                                                    type={rev.type}
                                                    name={rev.name}
                                                    body={rev.content}
                                                    rating={rev.stars}
                                                    profileImg={rev.image}
                                                    createdAt={rev.date}
                                                    reviewPageURL={rev?.reviewPageURL}
                                                />
                                            ))}
                                    </Div>
                                </Div>
                            </Div>

                            {/* google review */}
                            {Object.keys(data?.googleReviews).length !== 0 && (
                                <Fragment>
                                    <Div className={'EPCInnerHeading'}>
                                        <Div className={'EPCIHLTitle'}>Google Reviews Found</Div>
                                        <Div className={'EPCIHRTitle'}>
                                            Your Business’s Google Reviews Overview
                                        </Div>
                                    </Div>
                                    <Div className={'EPCRow'}>
                                        <Div className={'EPCRCol5'}>
                                            <Div className={'EPCReviews EPCReviews3'}>
                                                <Div
                                                    className={'EPCReviewBox'}
                                                    style={{ backgroundColor: '#fff7eb' }}
                                                >
                                                    <Div
                                                        className={'EPCRBHead'}
                                                        style={{ borderColor: '#f2eadf' }}
                                                    >
                                                        <Div className={'EPCRBHInfo'}>
                                                            <Div className={'EPCRBHITitle'}>
                                                                Total Reviews Found
                                                            </Div>
                                                        </Div>
                                                        <Div className={'EPCRBHIcon'}>
                                                            <DCTwoStar />
                                                        </Div>
                                                    </Div>
                                                    <Div className={'EPCRBBody'}>
                                                        <Div className={'EPCRBBCol'}>
                                                            <Div className={'EPCRBBCNum'}>
                                                                {abbreviateNumber(
                                                                    data?.googleReviews
                                                                        ?.totalReview,
                                                                )}
                                                            </Div>
                                                        </Div>
                                                    </Div>
                                                </Div>
                                                <Div
                                                    className={'EPCReviewBox'}
                                                    style={{ backgroundColor: '#eef5ff' }}
                                                >
                                                    <Div
                                                        className={'EPCRBHead'}
                                                        style={{ borderColor: '#e2e5ff' }}
                                                    >
                                                        <Div className={'EPCRBHInfo'}>
                                                            <Div className={'EPCRBHITitle'}>
                                                                Reviews Found
                                                            </Div>
                                                            <Div className={'EPCRBHISTitle'}>
                                                                (in last 6 month)
                                                            </Div>
                                                        </Div>
                                                        <Div
                                                            className={'EPCRBHIcon'}
                                                            style={{ color: '#3F50F6' }}
                                                        >
                                                            <DCChatReview />
                                                        </Div>
                                                    </Div>
                                                    <Div className={'EPCRBBody'}>
                                                        <Div className={'EPCRBBCol'}>
                                                            <Div className={'EPCRBBCNum'}>
                                                                {abbreviateNumber(
                                                                    data?.googleReviews
                                                                        ?.lastSixMonthReviewsCount,
                                                                )}
                                                            </Div>
                                                        </Div>
                                                    </Div>
                                                </Div>
                                                <Div
                                                    className={'EPCReviewBox'}
                                                    style={{ backgroundColor: '#fff2f3' }}
                                                >
                                                    <Div
                                                        className={'EPCRBHead'}
                                                        style={{ borderColor: '#e2e5ff' }}
                                                    >
                                                        <Div className={'EPCRBHInfo'}>
                                                            <Div className={'EPCRBHITitle'}>
                                                                Review Score
                                                            </Div>
                                                            <Div className={'EPCRBHISTitle'} />
                                                        </Div>
                                                        <Div
                                                            className={'EPCRBHIcon'}
                                                            style={{ color: '#E92C34' }}
                                                        >
                                                            <DCUserReview3 />
                                                        </Div>
                                                    </Div>
                                                    <Div className={'EPCRBBody'}>
                                                        <Div className={'EPCRBBCol'}>
                                                            <Div className={'EPCRBBCNum'}>
                                                                {abbreviateNumber(
                                                                    data?.googleReviews
                                                                        ?.avgReviewScore,
                                                                )}
                                                            </Div>
                                                        </Div>
                                                    </Div>
                                                </Div>
                                            </Div>
                                        </Div>
                                        <Div className={'EPCRCol5'}>
                                            {Object.keys(data?.googleReviews?.lastreview || {})
                                                ?.length > 0 && (
                                                <Div className={'EPCRComment'}>
                                                    <UserReviewBox
                                                        companyName={details?.name}
                                                        type="google"
                                                        name={data?.googleReviews?.lastreview.name}
                                                        body={
                                                            data?.googleReviews?.lastreview.content
                                                        }
                                                        rating={
                                                            data?.googleReviews?.lastreview.stars
                                                        }
                                                        profileImg={
                                                            data?.googleReviews?.lastreview.image
                                                        }
                                                        createdAt={
                                                            data?.googleReviews?.lastreview.date
                                                        }
                                                        reviewPageURL={
                                                            data?.googleReviews?.reviewPageURL
                                                        }
                                                    />
                                                </Div>
                                            )}
                                        </Div>
                                    </Div>
                                </Fragment>
                            )}
                            {/* Yelp review */}
                            {Object.keys(data?.yelpReviews).length !== 0 && (
                                <Fragment>
                                    <Div className={'EPCInnerHeading'}>
                                        <Div className={'EPCIHLTitle'}>Yelp Reviews Found</Div>
                                        <Div className={'EPCIHRTitle'}>
                                            Your Business’s Yelp Reviews Overview
                                        </Div>
                                    </Div>
                                    <Div className={'EPCRow'}>
                                        <Div className={'EPCRCol5'}>
                                            <Div className={'EPCReviews EPCReviews3'}>
                                                <Div
                                                    className={'EPCReviewBox'}
                                                    style={{ backgroundColor: '#fff7eb' }}
                                                >
                                                    <Div
                                                        className={'EPCRBHead'}
                                                        style={{ borderColor: '#f2eadf' }}
                                                    >
                                                        <Div className={'EPCRBHInfo'}>
                                                            <Div className={'EPCRBHITitle'}>
                                                                Total Reviews Found
                                                            </Div>
                                                        </Div>
                                                        <Div className={'EPCRBHIcon'}>
                                                            <DCTwoStar />
                                                        </Div>
                                                    </Div>
                                                    <Div className={'EPCRBBody'}>
                                                        <Div className={'EPCRBBCol'}>
                                                            <Div className={'EPCRBBCNum'}>
                                                                {abbreviateNumber(
                                                                    data?.yelpReviews?.totalReview,
                                                                )}
                                                            </Div>
                                                        </Div>
                                                    </Div>
                                                </Div>
                                                <Div
                                                    className={'EPCReviewBox'}
                                                    style={{ backgroundColor: '#eef5ff' }}
                                                >
                                                    <Div
                                                        className={'EPCRBHead'}
                                                        style={{ borderColor: '#e2e5ff' }}
                                                    >
                                                        <Div className={'EPCRBHInfo'}>
                                                            <Div className={'EPCRBHITitle'}>
                                                                Reviews Found
                                                            </Div>
                                                            <Div className={'EPCRBHISTitle'}>
                                                                (in last 6 month)
                                                            </Div>
                                                        </Div>
                                                        <Div
                                                            className={'EPCRBHIcon'}
                                                            style={{ color: '#3F50F6' }}
                                                        >
                                                            <DCChatReview />
                                                        </Div>
                                                    </Div>
                                                    <Div className={'EPCRBBody'}>
                                                        <Div className={'EPCRBBCol'}>
                                                            <Div className={'EPCRBBCNum'}>
                                                                {abbreviateNumber(
                                                                    data?.yelpReviews
                                                                        ?.lastSixMonthReviewsCount,
                                                                )}
                                                            </Div>
                                                        </Div>
                                                    </Div>
                                                </Div>
                                                <Div
                                                    className={'EPCReviewBox'}
                                                    style={{ backgroundColor: '#fff2f3' }}
                                                >
                                                    <Div
                                                        className={'EPCRBHead'}
                                                        style={{ borderColor: '#e2e5ff' }}
                                                    >
                                                        <Div className={'EPCRBHInfo'}>
                                                            <Div className={'EPCRBHITitle'}>
                                                                Review Score
                                                            </Div>
                                                            <Div className={'EPCRBHISTitle'} />
                                                        </Div>
                                                        <Div
                                                            className={'EPCRBHIcon'}
                                                            style={{ color: '#E92C34' }}
                                                        >
                                                            <DCUserReview3 />
                                                        </Div>
                                                    </Div>
                                                    <Div className={'EPCRBBody'}>
                                                        <Div className={'EPCRBBCol'}>
                                                            <Div className={'EPCRBBCNum'}>
                                                                {abbreviateNumber(
                                                                    data?.yelpReviews
                                                                        ?.avgReviewScore,
                                                                )}
                                                            </Div>
                                                        </Div>
                                                    </Div>
                                                </Div>
                                            </Div>
                                        </Div>
                                        <Div className={'EPCRCol5'}>
                                            {Object.keys(data?.yelpReviews?.lastreview || {})
                                                ?.length > 0 && (
                                                <Div className={'EPCRComment'}>
                                                    <UserReviewBox
                                                        companyName={details?.name}
                                                        type="yelp"
                                                        name={data?.yelpReviews?.lastreview.name}
                                                        body={data?.yelpReviews?.lastreview.content}
                                                        rating={data?.yelpReviews?.lastreview.stars}
                                                        profileImg={
                                                            data?.yelpReviews?.lastreview.image
                                                        }
                                                        createdAt={
                                                            data?.yelpReviews?.lastreview.date
                                                        }
                                                        reviewPageURL={
                                                            data?.yelpReviews?.reviewPageURL
                                                        }
                                                    />
                                                </Div>
                                            )}
                                        </Div>
                                    </Div>
                                </Fragment>
                            )}

                            {/* Facebook reviews */}
                            {Object.keys(data?.facebookReviews).length !== 0 && (
                                <Fragment>
                                    <Div className={'EPCInnerHeading'}>
                                        <Div className={'EPCIHLTitle'}>Facebook Reviews Found</Div>
                                        <Div className={'EPCIHRTitle'}>
                                            Your Business’s Facebook Reviews Overview
                                        </Div>
                                    </Div>
                                    <Div className={'EPCRow'}>
                                        <Div className={'EPCRCol5'}>
                                            <Div className={'EPCReviews'}>
                                                <Div
                                                    className={'EPCReviewBox'}
                                                    style={{ backgroundColor: '#fff7eb' }}
                                                >
                                                    <Div
                                                        className={'EPCRBHead'}
                                                        style={{ borderColor: '#f2eadf' }}
                                                    >
                                                        <Div className={'EPCRBHInfo'}>
                                                            <Div className={'EPCRBHITitle'}>
                                                                Total Reviews Found
                                                            </Div>
                                                        </Div>
                                                        <Div className={'EPCRBHIcon'}>
                                                            <DCTwoStar />
                                                        </Div>
                                                    </Div>
                                                    <Div className={'EPCRBBody'}>
                                                        <Div className={'EPCRBBCol'}>
                                                            <Div className={'EPCRBBCNum'}>
                                                                {abbreviateNumber(
                                                                    data?.facebookReviews
                                                                        ?.totalReview,
                                                                )}
                                                            </Div>
                                                        </Div>
                                                    </Div>
                                                </Div>
                                                <Div
                                                    className={'EPCReviewBox'}
                                                    style={{ backgroundColor: '#fff2f3' }}
                                                >
                                                    <Div
                                                        className={'EPCRBHead'}
                                                        style={{ borderColor: '#e2e5ff' }}
                                                    >
                                                        <Div className={'EPCRBHInfo'}>
                                                            <Div className={'EPCRBHITitle'}>
                                                                Review Score
                                                            </Div>
                                                            <Div className={'EPCRBHISTitle'} />
                                                        </Div>
                                                        <Div
                                                            className={'EPCRBHIcon'}
                                                            style={{ color: '#E92C34' }}
                                                        >
                                                            <DCUserReview3 />
                                                        </Div>
                                                    </Div>
                                                    <Div className={'EPCRBBody'}>
                                                        <Div className={'EPCRBBCol'}>
                                                            <Div className={'EPCRBBCNum'}>
                                                                {abbreviateNumber(
                                                                    data?.facebookReviews
                                                                        ?.avgReviewScore,
                                                                )}
                                                            </Div>
                                                        </Div>
                                                    </Div>
                                                </Div>
                                            </Div>
                                        </Div>
                                        <Div className={'EPCRCol5'}>
                                            {Object.keys(data?.facebookReviews?.lastreview || {})
                                                ?.length > 0 && (
                                                <Div className={'EPCRComment'}>
                                                    <UserReviewBox
                                                        reviewPageURL={
                                                            data?.facebookReviews?.reviewPageURL
                                                        }
                                                        companyName={details?.name}
                                                        type="facebook"
                                                        name={
                                                            data?.facebookReviews?.lastreview.name
                                                        }
                                                        body={
                                                            data?.facebookReviews?.lastreview
                                                                .content
                                                        }
                                                        rating={
                                                            data?.facebookReviews?.lastreview.stars
                                                        }
                                                        profileImg={
                                                            data?.facebookReviews?.lastreview.image
                                                        }
                                                        createdAt={
                                                            data?.facebookReviews?.lastreview.date
                                                        }
                                                    />
                                                </Div>
                                            )}
                                        </Div>
                                    </Div>
                                </Fragment>
                            )}
                        </Fragment>
                    ) : (
                        <NoFound
                            title={'You do not have any reviews!'}
                            description={
                                <Fragment>
                                    <p>
                                        Having great reviews will help build trust with potential
                                        customers.
                                    </p>
                                </Fragment>
                            }
                        />
                    )}
                    <Div className={'EPCUserComt'} style={{ marginTop: '15px' }}>
                        <Div className={'EPCUCInner'}>
                            <Div className={'EPCUCInfo'}>
                                <Div className={'EPCUCIImg'}>
                                    <Image
                                        src={generateImage(user?.image, user?.name)}
                                        displayName={user?.name}
                                        alt={user?.name}
                                    />
                                </Div>
                                <Div className={'EPCUCICmt'}>
                                    Would you like to start generating more potential customers to
                                    these review platforms for {details?.name}? We can help!
                                </Div>
                            </Div>
                            <Div className={'EPCUCBtn'}>
                                <Button
                                    buttonType={'BlueFillBtn'}
                                    onClick={() => {
                                        handleGetInTouchOpen();
                                    }}
                                >
                                    Get in touch
                                </Button>
                            </Div>
                        </Div>
                    </Div>
                </Div>
            </Div>
        </Fragment>
    );
};

export default Reviews;
