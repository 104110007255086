import React from 'react';

import { SVGIcon } from '../../UIKit/index';

export default function DCAdvancedSearch(props) {
    return (
        <SVGIcon {...props} viewBox="0 0 505.965 372.4">
            <g id="Advanced_Search" transform="translate(-3.945 -64.43)">
                <ellipse
                    id="_Path_"
                    data-name="&lt;Path&gt;"
                    cx="239.71"
                    cy="41.87"
                    rx="239.71"
                    ry="41.87"
                    transform="translate(30.49 353.09)"
                    fill="#f4f5f6"
                />
                <path
                    id="Path_1"
                    data-name="Path 1"
                    d="M295.78,322.51H208.49s3.62,55.61-13.67,55.61H309.45C292.16,378.12,295.78,322.51,295.78,322.51Z"
                    fill="#707272"
                />
                <path
                    id="Path_2"
                    data-name="Path 2"
                    d="M207.5,355.94l88-17.83c-.16-8.82.28-15.6.28-15.6H208.49S209.65,340.44,207.5,355.94Z"
                    opacity="0.3"
                />
                <rect
                    id="Rectangle_1"
                    data-name="Rectangle 1"
                    width="350.33"
                    height="229.88"
                    rx="18.42"
                    transform="translate(76.97 109.39)"
                    fill="#5a5b5c"
                />
                <rect
                    id="Rectangle_2"
                    data-name="Rectangle 2"
                    width="330.65"
                    height="213.59"
                    rx="14.75"
                    transform="translate(86.81 117.54)"
                    fill="#fff"
                    stroke="#707273"
                    strokeMiterlimit="10"
                    strokeWidth="3"
                />
                <line
                    id="Line_1"
                    data-name="Line 1"
                    x2="330.65"
                    transform="translate(86.81 144.48)"
                    fill="none"
                    stroke="#707273"
                    strokeMiterlimit="10"
                    strokeWidth="2"
                />
                <path
                    id="Path_3"
                    data-name="Path 3"
                    d="M377.75,136.09H150a5.65,5.65,0,0,1-5.65-5.65h0a5.66,5.66,0,0,1,5.65-5.66H377.8a5.66,5.66,0,0,1,5.65,5.66h0a5.65,5.65,0,0,1-5.7,5.65Z"
                    fill="#e4e3e3"
                />
                <path
                    id="Path_4"
                    data-name="Path 4"
                    d="M103,130.72a3,3,0,1,1-3-3,3,3,0,0,1,3,3Z"
                    fill="none"
                    stroke="#707273"
                    strokeMiterlimit="10"
                    strokeWidth="1.5"
                />
                <path
                    id="Path_5"
                    data-name="Path 5"
                    d="M117.11,130.72a3,3,0,1,1-3-3A3,3,0,0,1,117.11,130.72Z"
                    fill="none"
                    stroke="#707273"
                    strokeMiterlimit="10"
                    strokeWidth="1.5"
                />
                <path
                    id="Path_6"
                    data-name="Path 6"
                    d="M131.18,130.72a3,3,0,1,1-3-3A3,3,0,0,1,131.18,130.72Z"
                    fill="none"
                    stroke="#707273"
                    strokeMiterlimit="10"
                    strokeWidth="1.5"
                />
                <path
                    id="Path_7"
                    data-name="Path 7"
                    d="M344.42,221.87H159.85a12.66,12.66,0,0,1-12.66-12.66h0a12.66,12.66,0,0,1,12.66-12.66H344.42a12.66,12.66,0,0,1,12.66,12.66h0A12.66,12.66,0,0,1,344.42,221.87Z"
                    fill="none"
                    stroke="#707273"
                    strokeMiterlimit="10"
                    strokeWidth="1.5"
                />
                <path
                    id="Path_8"
                    data-name="Path 8"
                    d="M344.42,196.55H323.17v25.32h21.25a12.66,12.66,0,0,0,12.66-12.66h0a12.66,12.66,0,0,0-12.66-12.66Z"
                    fill="#707273"
                />
                <path
                    id="Path_9"
                    data-name="Path 9"
                    d="M348.12,215.32l-4.19-4.19a6.59,6.59,0,1,0-2.24,1.9l4.36,4.36a1.49,1.49,0,0,0,1,.42,1.486,1.486,0,0,0,1.07-2.49Zm-4.74-8.13a4.73,4.73,0,1,1-4.72-4.72,4.73,4.73,0,0,1,4.72,4.72Z"
                    fill="#fff"
                />
                <path
                    id="Path_10"
                    data-name="Path 10"
                    d="M213,214.66H160.16a5.45,5.45,0,0,1-5.45-5.45h0a5.45,5.45,0,0,1,5.45-5.45H213a5.45,5.45,0,0,1,5.45,5.45h0A5.45,5.45,0,0,1,213,214.66Z"
                    fill="#e4e3e3"
                />
                <rect
                    id="Rectangle_3"
                    data-name="Rectangle 3"
                    width="145.8"
                    height="8.11"
                    transform="translate(179.23 376.53)"
                    fill="#5a5b5c"
                />
                <rect
                    id="Rectangle_4"
                    data-name="Rectangle 4"
                    width="47.92"
                    height="12.18"
                    rx="5.54"
                    transform="translate(185.53 237.64)"
                    fill="#e4e3e3"
                />
                <line
                    id="Line_2"
                    data-name="Line 2"
                    x2="34.82"
                    transform="translate(192.08 245.25)"
                    fill="none"
                    stroke="#707273"
                    strokeMiterlimit="10"
                    strokeWidth="1.5"
                />
                <rect
                    id="Rectangle_5"
                    data-name="Rectangle 5"
                    width="75.63"
                    height="12.18"
                    rx="5.54"
                    transform="translate(243.11 237.64)"
                    fill="#e4e3e3"
                />
                <line
                    id="Line_3"
                    data-name="Line 3"
                    x2="49.09"
                    transform="translate(256.38 245.25)"
                    fill="none"
                    stroke="#707273"
                    strokeMiterlimit="10"
                    strokeWidth="1.5"
                />
                <line
                    id="Line_4"
                    data-name="Line 4"
                    x2="13.38"
                    transform="translate(352.69 154.68)"
                    fill="none"
                    stroke="#4778bc"
                    strokeMiterlimit="10"
                    strokeWidth="1.5"
                />
                <line
                    id="Line_5"
                    data-name="Line 5"
                    x2="13.38"
                    transform="translate(372.55 154.68)"
                    fill="none"
                    stroke="#4778bc"
                    strokeMiterlimit="10"
                    strokeWidth="1.5"
                />
                <rect
                    id="Rectangle_6"
                    data-name="Rectangle 6"
                    width="1.8"
                    height="1.8"
                    transform="translate(390.74 151.06)"
                    fill="#e1ebf7"
                    opacity="0.75"
                />
                <rect
                    id="Rectangle_7"
                    data-name="Rectangle 7"
                    width="1.8"
                    height="1.8"
                    transform="translate(393.26 151.06)"
                    fill="#e1ebf7"
                    opacity="0.75"
                />
                <rect
                    id="Rectangle_8"
                    data-name="Rectangle 8"
                    width="1.8"
                    height="1.8"
                    transform="translate(395.77 151.06)"
                    fill="#e1ebf7"
                    opacity="0.75"
                />
                <rect
                    id="Rectangle_9"
                    data-name="Rectangle 9"
                    width="1.8"
                    height="1.8"
                    transform="translate(390.74 153.78)"
                    fill="#e1ebf7"
                    opacity="0.75"
                />
                <rect
                    id="Rectangle_10"
                    data-name="Rectangle 10"
                    width="1.8"
                    height="1.8"
                    transform="translate(393.26 153.78)"
                    fill="#e1ebf7"
                    opacity="0.75"
                />
                <rect
                    id="Rectangle_11"
                    data-name="Rectangle 11"
                    width="1.8"
                    height="1.8"
                    transform="translate(395.77 153.78)"
                    fill="#e1ebf7"
                    opacity="0.75"
                />
                <rect
                    id="Rectangle_12"
                    data-name="Rectangle 12"
                    width="1.8"
                    height="1.8"
                    transform="translate(390.74 156.5)"
                    fill="#e1ebf7"
                    opacity="0.75"
                />
                <rect
                    id="Rectangle_13"
                    data-name="Rectangle 13"
                    width="1.8"
                    height="1.8"
                    transform="translate(393.26 156.5)"
                    fill="#e1ebf7"
                    opacity="0.75"
                />
                <rect
                    id="Rectangle_14"
                    data-name="Rectangle 14"
                    width="1.8"
                    height="1.8"
                    transform="translate(395.77 156.5)"
                    fill="#e1ebf7"
                    opacity="0.75"
                />
                <circle
                    id="Ellipse_1"
                    data-name="Ellipse 1"
                    cx="4.56"
                    cy="4.56"
                    r="4.56"
                    transform="translate(400.44 150.12)"
                    fill="#e4e3e3"
                />
                <path
                    id="Path_11"
                    data-name="Path 11"
                    d="M168.58,165.17H77v34.5h91.61a3.71,3.71,0,0,0,3.69-3.73v-27a3.71,3.71,0,0,0-3.72-3.77Z"
                    fill="#707272"
                    opacity="0.2"
                />
                <circle
                    id="Ellipse_2"
                    data-name="Ellipse 2"
                    cx="1.19"
                    cy="1.19"
                    r="1.19"
                    transform="translate(407.14 123.59)"
                    fill="#707272"
                />
                <circle
                    id="Ellipse_3"
                    data-name="Ellipse 3"
                    cx="1.19"
                    cy="1.19"
                    r="1.19"
                    transform="translate(407.14 129.25)"
                    fill="#707272"
                />
                <circle
                    id="Ellipse_4"
                    data-name="Ellipse 4"
                    cx="1.19"
                    cy="1.19"
                    r="1.19"
                    transform="translate(407.14 134.9)"
                    fill="#707272"
                />
                <line
                    id="Line_6"
                    data-name="Line 6"
                    x1="74.61"
                    transform="translate(230.86 376.96)"
                    fill="none"
                    stroke="#e4e3e3"
                    strokeMiterlimit="10"
                    strokeWidth="1.5"
                />
                <line
                    id="Line_7"
                    data-name="Line 7"
                    x1="24.36"
                    transform="translate(198.13 376.96)"
                    fill="none"
                    stroke="#e4e3e3"
                    strokeMiterlimit="10"
                    strokeWidth="1.5"
                />
                <rect
                    id="Rectangle_15"
                    data-name="Rectangle 15"
                    width="168.15"
                    height="52.47"
                    rx="7.15"
                    transform="translate(238.18 265.27)"
                    fill="#707272"
                    opacity="0.2"
                />
                <rect
                    id="Rectangle_16"
                    data-name="Rectangle 16"
                    width="168.15"
                    height="52.47"
                    rx="7.15"
                    transform="translate(231.12 260.55)"
                    fill="#f4f5f6"
                    stroke="#707273"
                    strokeMiterlimit="10"
                    strokeWidth="1.5"
                />
                <path
                    id="Path_12"
                    data-name="Path 12"
                    d="M388.12,284.54H289.7a3,3,0,0,1-3-3h0a3,3,0,0,1,3-3h98.42a3,3,0,0,1,3,3h0A3,3,0,0,1,388.12,284.54Z"
                    fill="#e4e3e3"
                />
                <path
                    id="Path_13"
                    data-name="Path 13"
                    d="M388.12,295.08H289.7a3,3,0,0,1-3-3h0a3,3,0,0,1,3-3h98.42a3,3,0,0,1,3,3h0a3,3,0,0,1-3,3Z"
                    fill="#e4e3e3"
                />
                <path
                    id="Path_14"
                    data-name="Path 14"
                    d="M388.12,274H289.7a3,3,0,0,1-3-3h0a3,3,0,0,1,3-3h98.42a3,3,0,0,1,3,3h0A3,3,0,0,1,388.12,274Z"
                    fill="#e4e3e3"
                />
                <rect
                    id="Rectangle_17"
                    data-name="Rectangle 17"
                    width="27.84"
                    height="6.05"
                    rx="2.75"
                    transform="translate(286.68 299.58)"
                    fill="#e4e3e3"
                />
                <rect
                    id="Rectangle_18"
                    data-name="Rectangle 18"
                    width="37.69"
                    height="37.69"
                    rx="5.37"
                    transform="translate(237.46 267.94)"
                    fill="#e4e3e3"
                />
                <path
                    id="Path_15"
                    data-name="Path 15"
                    d="M252.11,275.49l-10.4,20.03h20.8Z"
                    fill="#fff"
                />
                <path
                    id="Path_16"
                    data-name="Path 16"
                    d="M263.22,282.07l-2.5,4.82,4.48,8.63h5Z"
                    fill="#fff"
                />
                <path
                    id="Path_17"
                    data-name="Path 17"
                    d="M268.78,278.32a2.17,2.17,0,1,1-2.17-2.17A2.17,2.17,0,0,1,268.78,278.32Z"
                    fill="#fff"
                />
                <rect
                    id="Rectangle_19"
                    data-name="Rectangle 19"
                    width="122.79"
                    height="50.39"
                    rx="6.86"
                    transform="translate(275.35 111.08)"
                    fill="#707272"
                    opacity="0.2"
                />
                <rect
                    id="Rectangle_20"
                    data-name="Rectangle 20"
                    width="122.79"
                    height="50.39"
                    rx="6.86"
                    transform="translate(266.99 105.76)"
                    fill="#f4f5f6"
                    stroke="#707273"
                    strokeMiterlimit="10"
                    strokeWidth="1.5"
                />
                <path
                    id="Path_18"
                    data-name="Path 18"
                    d="M378.28,128.79H322.46a2.9,2.9,0,0,1-2.91-2.9h0a2.91,2.91,0,0,1,2.91-2.91h55.82a2.91,2.91,0,0,1,2.91,2.91h0a2.9,2.9,0,0,1-2.91,2.9Z"
                    fill="#e4e3e3"
                />
                <path
                    id="Path_19"
                    data-name="Path 19"
                    d="M378.28,138.92H322.46a2.91,2.91,0,0,1-2.91-2.91h0a2.9,2.9,0,0,1,2.91-2.9h55.82a2.9,2.9,0,0,1,2.91,2.9h0A2.91,2.91,0,0,1,378.28,138.92Z"
                    fill="#e4e3e3"
                />
                <path
                    id="Path_20"
                    data-name="Path 20"
                    d="M378.28,118.67H322.46a2.91,2.91,0,0,1-2.91-2.91h0a2.91,2.91,0,0,1,2.91-2.91h55.82a2.91,2.91,0,0,1,2.91,2.91h0a2.91,2.91,0,0,1-2.91,2.91Z"
                    fill="#e4e3e3"
                />
                <path
                    id="Path_21"
                    data-name="Path 21"
                    d="M343.38,149.05H322.46a2.91,2.91,0,0,1-2.91-2.91h0a2.91,2.91,0,0,1,2.91-2.91h20.92a2.9,2.9,0,0,1,2.9,2.91h0a2.91,2.91,0,0,1-2.9,2.91Z"
                    fill="#e4e3e3"
                />
                <path
                    id="Path_22"
                    data-name="Path 22"
                    d="M309.76,129.9a16.77,16.77,0,0,0-.72-4,17,17,0,0,0-1.62-3.69l-3.31,1.56a13.79,13.79,0,0,0-2.3-2.79l2.17-3a17.2,17.2,0,0,0-7.07-3.77l-1.23,3.46a13.55,13.55,0,0,0-3.6-.36l-.55-3.62a17.271,17.271,0,0,0-4,.71,18.35,18.35,0,0,0-3.7,1.62l1.57,3.32a13.52,13.52,0,0,0-2.79,2.3l-3-2.18a17.3,17.3,0,0,0-3.76,7.07l3.45,1.24a14,14,0,0,0-.35,3.6l-3.62.55a17.269,17.269,0,0,0,.71,4,17,17,0,0,0,1.62,3.69l3.32-1.56a13.459,13.459,0,0,0,2.3,2.79l-2.18,3a17.26,17.26,0,0,0,7.07,3.77l1.24-3.46a13.51,13.51,0,0,0,3.6.36l.55,3.62a17.27,17.27,0,0,0,4-.71,18,18,0,0,0,3.69-1.62l-1.56-3.32a13.791,13.791,0,0,0,2.79-2.3l3,2.18a17.44,17.44,0,0,0,3.77-7.07l-3.46-1.24a13.149,13.149,0,0,0,.35-3.6Zm-14.76,9a12.457,12.457,0,1,0,0-.02Z"
                    fill="#e4e3e3"
                />
                <rect
                    id="Rectangle_21"
                    data-name="Rectangle 21"
                    width="115.61"
                    height="34.24"
                    rx="4.66"
                    transform="translate(54.76 157.4)"
                    fill="#f4f5f6"
                    stroke="#707273"
                    strokeMiterlimit="10"
                    strokeWidth="1.5"
                />
                <path
                    id="Path_23"
                    data-name="Path 23"
                    d="M163.1,173.05H93a2,2,0,0,1-2-2h0a2,2,0,0,1,2-2h70.1a2,2,0,0,1,2,2h0A2,2,0,0,1,163.1,173.05Z"
                    fill="#e4e3e3"
                />
                <path
                    id="Path_24"
                    data-name="Path 24"
                    d="M163.1,179.94H93a2,2,0,0,1-2-2h0a2,2,0,0,1,2-2h70.1a2,2,0,0,1,2,2h0A2,2,0,0,1,163.1,179.94Z"
                    fill="#e4e3e3"
                />
                <path
                    id="Path_25"
                    data-name="Path 25"
                    d="M163.1,166.17H93a2,2,0,0,1-2-2h0a2,2,0,0,1,2-2h70.1a2,2,0,0,1,2,2h0A2,2,0,0,1,163.1,166.17Z"
                    fill="#e4e3e3"
                />
                <path
                    id="Path_26"
                    data-name="Path 26"
                    d="M139.38,186.82H93a2,2,0,0,1-2-2h0a2,2,0,0,1,2-2h46.38a2,2,0,0,1,2,2h0A2,2,0,0,1,139.38,186.82Z"
                    fill="#e4e3e3"
                />
                <rect
                    id="Rectangle_22"
                    data-name="Rectangle 22"
                    width="21.98"
                    height="21.98"
                    rx="3.13"
                    transform="translate(62.69 163.53)"
                    fill="#e4e3e3"
                />
                <path
                    id="Path_27"
                    data-name="Path 27"
                    d="M70,169.05V180a1.13,1.13,0,0,0,1.8.91l7.48-5.47a1.12,1.12,0,0,0,0-1.82l-7.48-5.47a1.13,1.13,0,0,0-1.8.9Z"
                    fill="#fff"
                />
                <path
                    id="Path_28"
                    data-name="Path 28"
                    d="M120.81,280.82a73.6,73.6,0,0,0-37.1,45,71.629,71.629,0,0,0-2.82,17.33.17.17,0,0,0,.33,0,75.87,75.87,0,0,1,8-30,74.12,74.12,0,0,1,18.19-23A76,76,0,0,1,120.94,281c.14-.07,0-.29-.13-.21Z"
                    fill="#fff"
                />
                <path
                    id="Path_29"
                    data-name="Path 29"
                    d="M421.81,244.7s-9.91,11.83-5.37,14.46,9,2.2,10.63.71,6.55-5.63,5.62-8.6C431.37,247.09,426,242.91,421.81,244.7Z"
                    fill="#e4e3e3"
                />
                <path
                    id="Path_30"
                    data-name="Path 30"
                    d="M422.17,259.63l-.48,8.37,10.16-2.27-3.21-9.94"
                    fill="#e4e3e3"
                />
                <g id="Group_1" data-name="Group 1" opacity="0.55">
                    <path
                        id="Path_31"
                        data-name="Path 31"
                        d="M429.47,259a6.05,6.05,0,0,1-2.79-1.16c-.33-.25-.63-.77-.29-1a1,1,0,0,1,.62,0,2.21,2.21,0,0,0,1.63-1,4.77,4.77,0,0,0,1.19-1.95,1.67,1.67,0,0,0-.82-2,1.87,1.87,0,0,0-2,.63,1,1,0,0,1-.5.34c-.33.06-.6-.32-.6-.65a2.43,2.43,0,0,1,.32-1,4.08,4.08,0,0,0,.2-2.69c-.12-.46-.29-1.05.09-1.33a1,1,0,0,1,.91,0,15.379,15.379,0,0,1,3.25,1.35,5.85,5.85,0,0,1,2.79,5.79c-.37,2.11-2.34,3.6-4,4.66"
                        fill="#707273"
                    />
                </g>
                <path
                    id="Path_32"
                    data-name="Path 32"
                    d="M433.41,254.78a6.52,6.52,0,0,0-2.87-4.41q-3.12-1.92-6.41-3.53a5.14,5.14,0,0,1-2.57-2,4.521,4.521,0,0,1-.35-1.83,8.159,8.159,0,0,1,.34-3.07,3.51,3.51,0,0,1,2.07-2.17c1.43-.45,2.93.37,4.09,1.31a15.87,15.87,0,0,1,5.49,9.34,2.52,2.52,0,0,1,2.74,1,3.77,3.77,0,0,1-2.53,5.33"
                    fill="#707273"
                />
                <path
                    id="Path_33"
                    data-name="Path 33"
                    d="M398.15,282.12l-24.71-6.81s-5-8.29-6.1-8.1,1.83,4.14.31,4.4c-.85.14-3.63.05-4.16-.38s-2-4.08-2.35-4c-.63.11-.07,5.1,1.05,6.22A35.857,35.857,0,0,0,369,278c2.72,1.36,29.31,20.13,34.45,19.27"
                    fill="#d3d3d3"
                />
                <path
                    id="Path_34"
                    data-name="Path 34"
                    d="M388.24,279.18l-3.25-.69-1.11,9.89,8.89,3.32"
                    fill="#fff"
                />
                <path
                    id="Path_35"
                    data-name="Path 35"
                    d="M439,272.54s-2.88-9-12.21-7.56-26.33,16.28-26.33,16.28l-12.84-2.19-1.2,16.66s10.38,5.87,16.88,5.87c13.35,0,31.82-24.26,31.82-24.26"
                    fill="#5a5b5c"
                />
                <path
                    id="Path_36"
                    data-name="Path 36"
                    d="M420.43,292.91c9-6.72,11.21-12.83,14.67-12.83"
                    fill="none"
                    stroke="#1b598a"
                    strokeMiterlimit="10"
                    strokeWidth="1"
                />
                <path
                    id="Path_37"
                    data-name="Path 37"
                    d="M447.32,342.62s9.84-1.75,13.22-2.29c6.57-1.06,13.36-1.14,10.71-3.8-3.47-3.46-19.65-11-19.65-11l-8.33,6.64Z"
                    fill="#5a5b5c"
                />
                <path
                    id="Path_38"
                    data-name="Path 38"
                    d="M398.86,373.19l3.83,16.13c-5,7.3-6.16-.3-6.16-.3l-9.3-17.36"
                    fill="#d3d3d3"
                />
                <path
                    id="Path_39"
                    data-name="Path 39"
                    d="M403.55,392.33l-.86-3c-4.22,2.6-6.16-.3-6.16-.3l-8.24,2.46c-2.39.83-2.73,3.48-1.06,3.48h14.34a2.06,2.06,0,0,0,1.98-2.64Z"
                    fill="#19202d"
                />
                <path
                    id="Path_40"
                    data-name="Path 40"
                    d="M456.23,376.72h16.43c5.53,4.36-1.64,5.76-1.64,5.76l-16.22,4.36"
                    fill="#d3d3d3"
                />
                <path
                    id="Path_41"
                    data-name="Path 41"
                    d="M475.73,376.79l-3.07-.07a4.48,4.48,0,0,1-1.64,5.76s1.45,5.3,1.45,5.89-1.5,1.91-.88,2.78,4.09.41,4.55-.77c1.25-3.2,2.05-11.74,2.05-11.74C478.19,377.36,477.15,376.94,475.73,376.79Z"
                    fill="#19202d"
                />
                <path
                    id="Path_42"
                    data-name="Path 42"
                    d="M426,321.8s-25.6,51-17.57,62.71c4.6,6.68,12.6,7.5,23.47,7.5h28.92l4.05-15.39-36.11-1.8s26.16-24.31,27.56-34.82c1.8-13.49-4.72-25.18-4.72-25.18"
                    fill="#707272"
                />
                <path
                    id="Path_43"
                    data-name="Path 43"
                    d="M414,321.41s-1.69,2.85-3.4,2.85H383.88c-10.34,0-18.06,5.95-14.53,22.08s11.32,37.72,11.32,37.72h21.42l-7.83-33.26h29c11.88,0,18.57-9.45,13-26.54"
                    fill="#707272"
                />
                <path
                    id="Path_44"
                    data-name="Path 44"
                    d="M445.45,333.32a29.82,29.82,0,0,1-2.68,15.23c-3.29,7.15-13.71,19.63-18.19,26.05a1.77,1.77,0,0,0,1.37,2.78l38.17,1.89"
                    fill="none"
                    stroke="#fff"
                    strokeMiterlimit="10"
                    strokeWidth="0.75"
                    opacity="0.5"
                />
                <path
                    id="Path_45"
                    data-name="Path 45"
                    d="M399.44,384.06l-9.4-35a1.41,1.41,0,0,1,1.36-1.78h23.33"
                    fill="none"
                    stroke="#fff"
                    strokeMiterlimit="10"
                    strokeWidth="0.75"
                    opacity="0.5"
                />
                <line
                    id="Line_8"
                    data-name="Line 8"
                    y1="17.48"
                    x2="7.17"
                    transform="translate(413.39 333.32)"
                    fill="none"
                    stroke="#000"
                    strokeMiterlimit="10"
                    strokeWidth="0.75"
                    opacity="0.3"
                />
                <path
                    id="Path_46"
                    data-name="Path 46"
                    d="M418.73,280.2s-3.08,29.3-5.22,42c4.94,9,41,8.17,42.57,6.59s-5.86-38.54-11.95-47.42S418.73,269.86,418.73,280.2Z"
                    fill="#5a5b5c"
                />
                <path
                    id="Path_47"
                    data-name="Path 47"
                    d="M429.77,265.08s15.81-.88,22.83,25.84c7.14,27.18,18.65,45.61,18.65,45.61s-4.37-1.52-11.94-.7c-12.56,1.36-28.17,7.92-28.17,7.92a185.928,185.928,0,0,1-9.94-27.68c-7.6-28.45-2.53-45.51-2.53-45.51Z"
                    fill="#707272"
                />
                <path
                    id="Path_48"
                    data-name="Path 48"
                    d="M424.39,321.67s3.1-18.32,2.23-19.11-4.92-.52-4.92-1.21,6-1.79,4.92-2.75-6.54-24.09-3.44-27.89"
                    fill="none"
                    stroke="#000"
                    strokeLinejoin="round"
                    strokeWidth="0.75"
                    opacity="0.3"
                />
                <g id="Group_2" data-name="Group 2" clipPath="url(#clip-path)">
                    <path
                        id="Path_49"
                        data-name="Path 49"
                        d="M353.06,169.05s-42,73.55-42.2,74.07H329.4l42.2-74.07Z"
                        fill="url(#linear-gradient)"
                    />
                    <path
                        id="Path_50"
                        data-name="Path 50"
                        d="M381.35,169.05s-42,73.55-42.2,74.07H345l42.19-74.07Z"
                        fill="url(#linear-gradient-2)"
                    />
                </g>
                <rect
                    id="Rectangle_23"
                    data-name="Rectangle 23"
                    width="6.51"
                    height="26.55"
                    transform="translate(352.104 241.648) rotate(-18.15)"
                    fill="#e4e3e3"
                />
                <path
                    id="Path_51"
                    data-name="Path 51"
                    d="M334.71,178.17a33.05,33.05,0,1,0,41.71,21.11,33.05,33.05,0,0,0-41.71-21.11Zm19.12,58.35a28.35,28.35,0,1,1,18.11-35.77A28.35,28.35,0,0,1,353.83,236.52Z"
                    fill="#707272"
                />
                <path
                    id="Path_52"
                    data-name="Path 52"
                    d="M371.45,290.28h0a4.84,4.84,0,0,1-6.1-3.09L354,252.67l9.19-3,11.32,34.51a4.84,4.84,0,0,1-3.06,6.1Z"
                    fill="#707272"
                />
                <path
                    id="Path_53"
                    data-name="Path 53"
                    d="M430.3,289.38c3,2.38,4.27,6.21,5.41,9.84,4,12.85,7.56,25.75,14.51,38.27"
                    fill="none"
                    stroke="#e4e3e3"
                    strokeMiterlimit="10"
                    strokeWidth="0.5"
                    opacity="0.5"
                />
                <path
                    id="Path_54"
                    data-name="Path 54"
                    d="M395.27,288l-23.12-10s-5-8.29-6.1-8.1,1.83,4.14.31,4.4c-.85.14-3.63.05-4.17-.38s-2-4.08-2.34-4c-.63.1-.07,5.1,1,6.21a36,36,0,0,0,6.82,4.59c2.73,1.36,27.73,23.3,32.87,22.44"
                    fill="#d3d3d3"
                />
                <path
                    id="Path_55"
                    data-name="Path 55"
                    d="M391.8,285.76,384.77,283l-3.9,10.3,9.6,6.7"
                    fill="#fff"
                />
                <path
                    id="Path_56"
                    data-name="Path 56"
                    d="M436.22,268s-2.92-4.36-12.19-2.6c-5.35,1-26.31,22.34-26.31,22.34L387.36,284l-5.65,17.88s12.77,8,19.27,8c13.35,0,31.83-24.26,31.83-24.26"
                    fill="#707272"
                />
                <path
                    id="Path_57"
                    data-name="Path 57"
                    d="M418.13,301.2c9-6.71,11.21-12.83,14.68-12.83"
                    fill="none"
                    stroke="#000"
                    strokeLinejoin="round"
                    strokeWidth="0.75"
                    opacity="0.3"
                />
                <path
                    id="Path_58"
                    data-name="Path 58"
                    d="M430.72,324.26l10.19.95v1.61l-9.16-.02Z"
                    opacity="0.3"
                />
                <path
                    id="Path_59"
                    data-name="Path 59"
                    d="M420.25,248.11s-5.42,2.35-5.42,3.26,2.87,2,2.87,2"
                    fill="#e4e3e3"
                />
                <path
                    id="Path_60"
                    data-name="Path 60"
                    d="M422.2,249.17a.45.45,0,0,0-.48-.21c-.42.11-.7.92-.62,1.3s.36.51.62.39a.67.67,0,0,0,.24-.26,1.52,1.52,0,0,0,.36-.86.65.65,0,0,0-.12-.36Z"
                    fill="#707273"
                />
                <path
                    id="Path_61"
                    data-name="Path 61"
                    d="M421.64,247c.28.16.57.35.84.54a8.815,8.815,0,0,1,.76.61,4.191,4.191,0,0,1,.59.73,5.058,5.058,0,0,1,.26.44,1.706,1.706,0,0,0,.13.23,1.059,1.059,0,0,0,.23.19.24.24,0,0,0,.34-.14.69.69,0,0,0,0-.32,1.329,1.329,0,0,0-.11-.3,2.471,2.471,0,0,0-.27-.44,5.572,5.572,0,0,0-.75-.86,3.591,3.591,0,0,0-.9-.64,3.23,3.23,0,0,0-1.06-.3.14.14,0,0,0-.07.26Z"
                    fill="#707273"
                />
                <path
                    id="Path_62"
                    data-name="Path 62"
                    d="M415.47,255.33a6.39,6.39,0,0,0,4.33.31"
                    fill="none"
                    stroke="#707273"
                    strokeMiterlimit="10"
                    strokeWidth="0.75"
                    opacity="0.5"
                />
                <path
                    id="Path_63"
                    data-name="Path 63"
                    d="M401.07,216.47a12.31,12.31,0,0,1,12.3-12.31h39.19a12.31,12.31,0,1,1,0,24.62H413.37a12.28,12.28,0,0,1-3.44-.5l-4.63,3.19,1.34-4.7a12.31,12.31,0,0,1-5.57-10.3Z"
                    fill="#e4e3e3"
                />
                <path
                    id="Path_64"
                    data-name="Path 64"
                    d="M434.89,217.67a2,2,0,1,0,2-2A2,2,0,0,0,434.89,217.67Z"
                    fill="#707272"
                />
                <path
                    id="Path_65"
                    data-name="Path 65"
                    d="M426.32,217.67a2,2,0,1,0,2-2A2,2,0,0,0,426.32,217.67Z"
                    fill="#707272"
                />
                <path
                    id="Path_66"
                    data-name="Path 66"
                    d="M443.46,217.67a2,2,0,1,0,2-2A2,2,0,0,0,443.46,217.67Z"
                    fill="#707272"
                />
                <path
                    id="Path_67"
                    data-name="Path 67"
                    d="M452,217.67a2,2,0,1,0,2-2A2,2,0,0,0,452,217.67Z"
                    fill="#707272"
                />
                <path
                    id="Path_68"
                    data-name="Path 68"
                    d="M417.75,217.67a2,2,0,1,0,2-2A2,2,0,0,0,417.75,217.67Z"
                    fill="#707272"
                />
                <path
                    id="Path_69"
                    data-name="Path 69"
                    d="M409.18,217.67a2,2,0,1,0,2-2A2,2,0,0,0,409.18,217.67Z"
                    fill="#707272"
                />
                <path
                    id="Path_70"
                    data-name="Path 70"
                    d="M154.17,248.11h11.08s4.38-2.78,6.37-2.94c2.72-.22,7.86,0,7.58,1s-8,2.42-7.72,3.32,4.17.43,4.17,1.19c0,1.61-10.62,1.68-10.62,1.68l-15.78,2"
                    fill="#e4e3e3"
                />
                <path
                    id="Path_71"
                    data-name="Path 71"
                    d="M105,206.31s16.39,3.71,22.4,13.82l14.26,24,22.58,4v5.09s-20.59,5.3-26.76,5.3c-11.31,0-25.35-29.28-25.35-29.28"
                    fill="#707272"
                />
                <line
                    id="Line_9"
                    data-name="Line 9"
                    y1="5.98"
                    transform="translate(162.14 247.74)"
                    fill="none"
                    stroke="#5a5c5d"
                    strokeMiterlimit="10"
                    strokeWidth="0.75"
                />
                <path
                    id="Path_72"
                    data-name="Path 72"
                    d="M127.42,387.69a7.28,7.28,0,0,0-1.62,4.68,13.92,13.92,0,0,0,.4,3.48h.9l.2-1.71a14.391,14.391,0,0,1,5,1.69c1.24.7,7.16.24,8-.31a1.19,1.19,0,0,0,.26-1.93c-.37-.38-7-2.75-7-2.75"
                    fill="#5a5b5c"
                />
                <path
                    id="Path_73"
                    data-name="Path 73"
                    d="M116.26,320.28c-8.32,25.46,11.16,67.41,11.16,67.41l3.84,1.49s2.85-44.11,2.85-65.15c0-17.74,2.79-46.29-15.47-54.79"
                    fill="#e4e3e3"
                />
                <path
                    id="Path_74"
                    data-name="Path 74"
                    d="M130.84,387.69a22.13,22.13,0,0,0,5.74,4.25c1.08.54-.31,1.41-1.46,1.51-3.6.32-8.42-4.69-8.42-4.69l1.2-2.38"
                    fill="#e4e3e3"
                />
                <path
                    id="Path_75"
                    data-name="Path 75"
                    d="M81.23,392.65c-2.61,2.6-6.43,8.93-4,9.43s8.91-5.67,8.91-5.67v.52h1s.81-3.39-.64-4.84"
                    fill="#5a5b5c"
                />
                <path
                    id="Path_76"
                    data-name="Path 76"
                    d="M78.43,303.15s.73,22.14.32,26.09,2.48,62.63,2.48,63.41c-.16,1.13-3.25,5.49-1.69,5.49s7.11-5.07,6.87-6c.33-.84,10-33.42,12.07-46.1,2.42-14.77-.55-24.32-.55-24.32l8.13-47.79"
                    fill="#e4e3e3"
                />
                <path
                    id="Path_77"
                    data-name="Path 77"
                    d="M118.92,263.59s11.86,4.35,14.83,23.16c2.35,14.85,1.4,38.07,1.4,38.07s-4.67,5.34-22.25,8.33c-23.72,4-34.18-1.69-34.18-1.69S74.37,301.88,77.49,283C80.67,263.7,96,257.05,96,257.05"
                    fill="#5a5b5c"
                />
                <path
                    id="Path_78"
                    data-name="Path 78"
                    d="M81.65,332.6S73,276.91,90.38,263"
                    fill="none"
                    stroke="#000"
                    strokeMiterlimit="10"
                    strokeWidth="0.75"
                    opacity="0.3"
                />
                <path
                    id="Path_79"
                    data-name="Path 79"
                    d="M120.83,220.13a9.49,9.49,0,0,0,.76,4.36c1.77,3.13,4.1,5.49,4.1,12.4,0,4.26-3.72,8.42-3.72,9.75S119.06,263,119.06,263h-14.7C92.05,263,88,258.5,88,258.5l-9.34-33.82,20.73-17.45,6.78-.72Z"
                    fill="#707272"
                />
                <line
                    id="Line_10"
                    data-name="Line 10"
                    x2="6"
                    transform="translate(112.99 221.52)"
                    fill="none"
                    stroke="#5a5c5d"
                    strokeMiterlimit="10"
                    strokeWidth="0.75"
                />
                <path
                    id="Path_80"
                    data-name="Path 80"
                    d="M120.83,220.13a9.49,9.49,0,0,0,.76,4.36c1.77,3.13,4.1,6.81,4.1,12.4,0,4.26-3.72,8.42-3.72,9.75s-3,16.95-3,16.95"
                    fill="none"
                    stroke="#5a5c5d"
                    strokeMiterlimit="10"
                    strokeWidth="0.75"
                />
                <path
                    id="Path_81"
                    data-name="Path 81"
                    d="M126.92,238.13l-5.63,25.22h35.92l5.72-25.56a.74.74,0,0,0-.63-1H128.42A1.66,1.66,0,0,0,126.92,238.13Z"
                    fill="#5a5b5c"
                />
                <path
                    id="Path_82"
                    data-name="Path 82"
                    d="M131.19,263.35H103.31l.15-1.4a1.86,1.86,0,0,1,1.79-1.63h26.26Z"
                    fill="#5a5b5c"
                />
                <path
                    id="Path_83"
                    data-name="Path 83"
                    d="M145.12,250.07a3.75,3.75,0,0,1-3.27,3c-1.42,0-2.27-1.34-1.89-3a3.72,3.72,0,0,1,3.26-3C144.64,247.09,145.49,248.42,145.12,250.07Z"
                    fill="#e4e3e3"
                />
                <path
                    id="Path_84"
                    data-name="Path 84"
                    d="M93.37,266.71h28.91a86.153,86.153,0,0,0,8.73.44c3.85-.07,6.15-3.14,6.15-3.8s-2.24,0-5.42,0c-2,0-3.33.29-3.49-.14-.36-1,3.34-1.65,2.62-2.37-.28-.28-1.92-.23-3,0a21.819,21.819,0,0,0-5.68,2.27L98,257.52"
                    fill="#e4e3e3"
                />
                <path
                    id="Path_85"
                    data-name="Path 85"
                    d="M101.52,206.31s-22.86,2.25-27,19c-4.69,18.63,7.12,39.69,13.18,41.4,2.52.71,31.29.72,31.29.72l1.31-5L96,257.05l-2.65-32.37"
                    fill="#707272"
                />
                <line
                    id="Line_11"
                    data-name="Line 11"
                    x1="0.77"
                    y2="5.54"
                    transform="translate(116.97 261.89)"
                    fill="none"
                    stroke="#5a5c5d"
                    strokeMiterlimit="10"
                    strokeWidth="0.75"
                />
                <path
                    id="Path_86"
                    data-name="Path 86"
                    d="M120.3,262.46l-24.27-5.41-2.65-32.37"
                    fill="none"
                    stroke="#5a5c5d"
                    strokeMiterlimit="10"
                    strokeWidth="0.75"
                />
                <path
                    id="Path_87"
                    data-name="Path 87"
                    d="M98.66,184.54a11.53,11.53,0,0,0-6.1,4.14c-1.62,2.1-2.56,4.68-3.83,7a30.592,30.592,0,0,1-2.32,3.45c-.61.83-2,1.73-1.69,2.91s2.14,1.76,3.19,1.88c1.36.17,2.76,0,4.12.19a16.75,16.75,0,0,1,3.37,1.08,17.07,17.07,0,0,0,7.69,1.3c1.62-.17,3-1,4.55-1.37s3.42-.05,5.06-.07a2.63,2.63,0,0,0,1.79-.48,2.37,2.37,0,0,0,.59-1.57,11.07,11.07,0,0,0-1.53-6.67c-1.13-1.87-2.8-3.37-3.92-5.24-1.29-2.13-2.16-4.27-4.21-5.83C103.61,183.92,100.76,184,98.66,184.54Z"
                    fill="#5a5b5c"
                />
                <path
                    id="Path_88"
                    data-name="Path 88"
                    d="M107.16,201.89l-.65,5.88A2.93,2.93,0,0,1,104,210c-4.59.34-4.68-2.77-4.68-2.77l3.51-7.39"
                    fill="#e4e3e3"
                />
                <path
                    id="Path_89"
                    data-name="Path 89"
                    d="M103.16,201.42s6.79,3.08,10.1-.43c1.9-2-2.93-12-5-13.44s-6.22.1-7.1,2.47-3.18,8.59,2,11.4"
                    fill="#e4e3e3"
                />
                <path
                    id="Path_90"
                    data-name="Path 90"
                    d="M111.25,193.32a.51.51,0,1,1-.51-.51A.51.51,0,0,1,111.25,193.32Z"
                    fill="#707273"
                />
                <path
                    id="Path_91"
                    data-name="Path 91"
                    d="M105.63,195.37a.5.5,0,0,0,.5.51.51.51,0,1,0,0-1,.5.5,0,0,0-.5.49Z"
                    fill="#707273"
                />
                <path
                    id="Path_92"
                    data-name="Path 92"
                    d="M106.88,193.24a2,2,0,0,0-1-.22,2.39,2.39,0,0,0-1,.27,2.23,2.23,0,0,0-.69.61,1.43,1.43,0,0,0-.32.84.13.13,0,0,0,.23.06c.15-.2.26-.43.43-.62a2.2,2.2,0,0,1,.56-.49,2.28,2.28,0,0,1,1.66-.18c.15,0,.26-.19.11-.27Z"
                    fill="#707273"
                />
                <path
                    id="Path_93"
                    data-name="Path 93"
                    d="M111.47,191.49a1,1,0,0,0-.36-.3,1.711,1.711,0,0,0-.49-.14,1.74,1.74,0,0,0-1.67.76,1.33,1.33,0,0,0-.19.42.66.66,0,0,0,.06.48.11.11,0,0,0,.17,0l.06-.08h0a.46.46,0,0,0,.11-.16,3.653,3.653,0,0,1,.18-.34,1.38,1.38,0,0,1,.5-.49,1.3,1.3,0,0,1,.7-.14,1.15,1.15,0,0,1,.37.06,2.546,2.546,0,0,1,.39.13c.13.05.25-.08.15-.2Z"
                    fill="#707273"
                />
                <path
                    id="Path_94"
                    data-name="Path 94"
                    d="M108.89,194s2,2.1,1.76,2.58c-.34.79-1.52.79-1.52.79"
                    fill="none"
                    stroke="#707273"
                    strokeMiterlimit="10"
                    strokeWidth="0.75"
                    opacity="0.5"
                />
                <path
                    id="Path_95"
                    data-name="Path 95"
                    d="M109.28,199.12l2.62-.89s.46,1.53-.39,1.84a1.67,1.67,0,0,1-2.23-.95Z"
                    fill="#fff"
                />
                <path
                    id="Path_96"
                    data-name="Path 96"
                    d="M107.08,202.67a8,8,0,0,1-4-1.18"
                    fill="none"
                    stroke="#707273"
                    strokeMiterlimit="10"
                    strokeWidth="0.75"
                    opacity="0.5"
                />
                <path
                    id="Path_97"
                    data-name="Path 97"
                    d="M100.87,198.6c2.85-2,4-6.14,4.52-9.61,2,.5,3.9,1.06,5.82,1.7.2-.95,0-4.57-4.46-6a6,6,0,0,0-6.19,2.13,10.76,10.76,0,0,0-2.19,9.57"
                    fill="#5a5b5c"
                />
                <path
                    id="Path_98"
                    data-name="Path 98"
                    d="M101.6,198.36c-1.55-1.55-2.68-.64-2.93,0a2.36,2.36,0,0,0,.33,2,3,3,0,0,0,2,1.55,1.57,1.57,0,0,0,1.82-1.4"
                    fill="#e4e3e3"
                />
                <path
                    id="Path_99"
                    data-name="Path 99"
                    d="M100.45,204.89s1.36,4.19,3.57,5.11a21.62,21.62,0,0,0-3.08,3.58,11.33,11.33,0,0,1-2.91-7Z"
                    fill="#fff"
                />
                <path
                    id="Path_100"
                    data-name="Path 100"
                    d="M106.67,206.31s-1,3-2.65,3.69a38.239,38.239,0,0,1,2.76,3.18s1.26-1.07.91-4.2A8.87,8.87,0,0,0,106.67,206.31Z"
                    fill="#fff"
                />
                <path
                    id="Path_101"
                    data-name="Path 101"
                    d="M70.79,193.41a12.31,12.31,0,0,0-12.3-12.31H37.18a12.31,12.31,0,1,0,0,24.62H58.49a12.23,12.23,0,0,0,3.44-.5l4.63,3.18-1.34-4.7a12.28,12.28,0,0,0,5.57-10.29Z"
                    fill="#e4e3e3"
                />
                <path
                    id="Path_102"
                    data-name="Path 102"
                    d="M37,194.61a2,2,0,1,1-2-2A2,2,0,0,1,37,194.61Z"
                    fill="#707272"
                />
                <path
                    id="Path_103"
                    data-name="Path 103"
                    d="M45.55,194.61a2,2,0,1,1-2-2A2,2,0,0,1,45.55,194.61Z"
                    fill="#707272"
                />
                <path
                    id="Path_104"
                    data-name="Path 104"
                    d="M54.12,194.61a2,2,0,1,1-2-2A2,2,0,0,1,54.12,194.61Z"
                    fill="#707272"
                />
                <path
                    id="Path_105"
                    data-name="Path 105"
                    d="M62.69,194.61a2,2,0,1,1-2-2A2,2,0,0,1,62.69,194.61Z"
                    fill="#707272"
                />
                <circle
                    id="Ellipse_6"
                    data-name="Ellipse 6"
                    cx="15.14"
                    cy="15.14"
                    r="15.14"
                    transform="translate(30.4 102.4)"
                    fill="#e4e3e3"
                />
                <path
                    id="Path_106"
                    data-name="Path 106"
                    d="M53.3,123.61l-4.16-4.17a6.58,6.58,0,1,0-2.23,1.89l4.34,4.33a1.43,1.43,0,0,0,2.43-1,1.46,1.46,0,0,0-.38-1.05Zm-4.71-8.08a4.7,4.7,0,1,1-4.69-4.7,4.7,4.7,0,0,1,4.69,4.7Z"
                    fill="#fff"
                />
                <circle
                    id="Ellipse_7"
                    data-name="Ellipse 7"
                    cx="17.11"
                    cy="17.11"
                    r="17.11"
                    transform="translate(164.33 262.05)"
                    fill="#e4e3e3"
                />
                <rect
                    id="Rectangle_24"
                    data-name="Rectangle 24"
                    width="15.55"
                    height="12.08"
                    rx="1.88"
                    transform="translate(170.67 273.5)"
                    fill="#fff"
                />
                <path
                    id="Path_107"
                    data-name="Path 107"
                    d="M192.4,284.43l-4.51-2.14V276.8l4.51-2.14a.43.43,0,0,1,.62.39v9a.43.43,0,0,1-.62.38Z"
                    fill="#fff"
                />
                <circle
                    id="Ellipse_8"
                    data-name="Ellipse 8"
                    cx="17.11"
                    cy="17.11"
                    r="17.11"
                    transform="matrix(0.228, -0.974, 0.974, 0.228, 443.047, 156.8)"
                    fill="#e4e3e3"
                />
                <path
                    id="Path_108"
                    data-name="Path 108"
                    d="M454.12,143.28l7.08,7.78,11.87-13.78"
                    fill="none"
                    stroke="#fff"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="3.5"
                />
                <circle
                    id="Ellipse_9"
                    data-name="Ellipse 9"
                    cx="6.89"
                    cy="6.89"
                    r="6.89"
                    transform="translate(471.3 253.43)"
                    fill="#fff"
                    stroke="#e2e2e2"
                    strokeMiterlimit="10"
                    strokeWidth="2"
                />
                <circle
                    id="Ellipse_10"
                    data-name="Ellipse 10"
                    cx="6.89"
                    cy="6.89"
                    r="6.89"
                    transform="translate(365.66 71.61)"
                    fill="#fff"
                    stroke="#e2e2e2"
                    strokeMiterlimit="10"
                    strokeWidth="2"
                />
                <circle
                    id="Ellipse_11"
                    data-name="Ellipse 11"
                    cx="9.23"
                    cy="9.23"
                    r="9.23"
                    transform="translate(444.79 65.43)"
                    fill="#fff"
                    stroke="#e2e2e2"
                    strokeMiterlimit="10"
                    strokeWidth="2"
                />
                <circle
                    id="Ellipse_12"
                    data-name="Ellipse 12"
                    cx="8.18"
                    cy="8.18"
                    r="8.18"
                    transform="translate(37.37 234.46)"
                    fill="#fff"
                    stroke="#e2e2e2"
                    strokeMiterlimit="10"
                    strokeWidth="2"
                />
                <path
                    id="Path_109"
                    data-name="Path 109"
                    d="M49.46,341.39H44.2v-5.25a.65.65,0,0,0-.65-.65h0a.67.67,0,0,0-.67.65v5.25H37.64a.665.665,0,0,0,0,1.33h5.24V348a.67.67,0,0,0,.67.66.65.65,0,0,0,.65-.66h0v-5.25h5.26a.66.66,0,0,0,.66-.66.67.67,0,0,0-.66-.7Z"
                    fill="#e2e2e2"
                    stroke="#e2e2e2"
                    strokeMiterlimit="10"
                    strokeWidth="0.75"
                />
                <path
                    id="Path_110"
                    data-name="Path 110"
                    d="M22.57,154.61H14.9V147a1,1,0,0,0-1-.95h0a1,1,0,0,0-1,.95v7.66H5.32a.975.975,0,1,0,0,1.95H13v7.67a1,1,0,0,0,2,0h0v-7.67h7.67a1,1,0,1,0,0-1.93Z"
                    fill="#e2e2e2"
                    stroke="#e2e2e2"
                    strokeMiterlimit="10"
                    strokeWidth="0.75"
                />
                <path
                    id="Path_111"
                    data-name="Path 111"
                    d="M106.08,83.78h-4.5V79.3a.56.56,0,0,0-.56-.56h0a.57.57,0,0,0-.57.56v4.48H96a.58.58,0,0,0-.56.58.55.55,0,0,0,.56.55h4.48v4.5A.57.57,0,0,0,101,90a.56.56,0,0,0,.56-.56h0v-4.5h4.5a.55.55,0,0,0,.56-.55.58.58,0,0,0-.54-.61Z"
                    fill="#e2e2e2"
                    stroke="#e2e2e2"
                    strokeMiterlimit="10"
                    strokeWidth="0.75"
                />
            </g>
        </SVGIcon>
    );
}
