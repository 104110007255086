import React from 'react';

import { SVGIcon } from '../../UIKit/index';

export default function DCNoTemplate(props) {
    return (
        <SVGIcon {...props} viewBox="0 0 512 493">
            <g transform="translate(-538.488 -820.992)">
                <path
                    fill="#D2D7D9"
                    d="M976.5,1295.3c0,0,6.7-6.4,10.8-33.7c4.1-27.4,10.5-200,10.5-200l-25.1-74.2l-10.3-5.1H608.1   c-13,2.7-22.6,14-23.1,27.3c-1.8,23.7,272.9,103.9,272.9,103.9L976.5,1295.3z"
                />
                <g transform="translate(-737.154 358.402)">
                    <path
                        fill="#A7BAC2"
                        d="M1583.3,463h185.1c10.5,0,19,8.5,19,19v213.6c0,10.5-8.5,19-19,19h-185.1    c-10.5,0-19-8.5-19-19V481.9C1564.4,471.5,1572.9,463,1583.3,463z"
                    />
                    <g transform="translate(1351.474 475.59)">
                        <circle fill="#FFFFFF" cx="337.2" cy="164.1" r="15.9" />
                        <path
                            fill="#FFFFFF"
                            d="M299.5,91.3l-15.4-13.9c0,0,32.5-39.3,65.2-25.4c0,0,23.6,10,16.8,39.3     c0-0.5-14.9,18.3-16.8,29.8c-1,6-1.7,12-2.1,18.1h-22.2v-23.6c0,0,26.4-27.7,15.4-40.8S299.5,91.3,299.5,91.3z"
                        />
                    </g>
                </g>
                <path
                    fill="#F2F9FC"
                    d="M965,1062.6c0,0,0.5-35.2-34.4-36.7c-34.9-1.5-266.2,0-266.2,0l-25-16.6   c-2.6-2.2-5.8-3.5-9.1-3.7c-19.8-0.3-72.5,0-72.5,0s-15.2,0.8-19.3,23.6c0.3,0.5,23.9,256.2,23.9,256.2s8.7,22.4,30.8,28.5   c0-0.3,361.4,0,361.4,0s17,0.5,23.2-22.2C978,1291.5,965,1062.6,965,1062.6z"
                />
                <g transform="translate(566.584 888.259)">
                    <circle fill="#A7BAC2" cx="118.2" cy="265.3" r="14.2" />
                    <ellipse fill="#A7BAC2" cx="258.2" cy="265.3" rx="11.9" ry="14.2" />
                    <path
                        fill="none"
                        stroke="#A7BAC2"
                        strokeWidth="4"
                        d="M141,337.8c15.2-8.7,45.1-29,89.8,0"
                    />
                    <path
                        fill="none"
                        stroke="#A7BAC2"
                        strokeWidth="4"
                        d="M141,341.8c15.2-8.7,45.1-29,89.8,0"
                    />
                </g>
            </g>
        </SVGIcon>
    );
}
