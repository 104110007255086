import './style.scss';

import React, { Fragment, useCallback, useEffect, useMemo, useState } from 'react';
import _ from 'lodash';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import PropTypes from 'prop-types';
import DCCrossClose from 'Assets/icons/DCCrossClose';
import DCPencil from 'Assets/icons/DCPencil';
import DCTrash from 'Assets/icons/DCTrash';
import DCLock from 'Assets/icons/DCLock';
import CreateProperty from 'Components/CreateProperty/createProperty';
import Confirmation from 'Components/Confirmation';
import DCCompare from 'Assets/icons/DCCompare';
import useToast from 'Modules/Toasts';
import {
    Button,
    Checkbox,
    Dialog,
    Div,
    DragBox,
    GridContainer,
    GridType,
    H1,
    H5,
    IconButton,
    Span,
    TopBar,
    ToolTip,
} from 'UIKit';
import { findItemIndex } from 'Utils';
import SearchBox from 'Components/SearchBox';

import { createFieldApi, deleteFieldApi, updateFieldApi } from 'apis/manageColumns/index.api';
import isUnderPayWall from 'Utils/payWallUtils';
import { payWallConstants } from 'Constants/payWallLimits';
import usePayWallDialog from 'Hooks/PayWallDialog/usePayWallDialog';
import payConstants from 'Constants/payWallConstants';

function alphabaticalCompare(a, b) {
    if (a.Header?.trim().toLowerCase() < b.Header?.trim().toLowerCase()) {
        return -1;
    }
    if (a.Header?.trim().toLowerCase() > b.Header?.trim().toLowerCase()) {
        return 1;
    }
    return 0;
}

const ManageColumns = ({
    open,
    onClose,
    title,
    columns,
    activeColumns,
    handleSave,
    source,
    hideCreateProperty,
}) => {
    const { showToast } = useToast();
    const [openCreatePropertyDialog, setOpenCreatePropertyDialog] = useState(false);
    const [allCols, setAllCols] = useState([]);
    const [searched, setSearched] = useState(false);
    const [searchResult, setSearchResult] = useState([]);
    const [allChecked, setAllChecked] = useState(false);
    const [editItem, setEditItem] = useState(null);
    const [draggableItems, setDraggableItems] = useState([]);
    const [nonDraggableItems, setNonDraggableItems] = useState([]);
    const [openConfirmation, setOpenConfirmation] = useState(false);
    const [deleteInfo, setDeleteInfo] = useState(null);
    const [loadingDialog, setLoadingDialog] = useState(false);
    const [disableSave, setDisableSave] = useState(true);
    const showPayWallDialog = usePayWallDialog();

    useEffect(() => {
        const tempColumns = _.cloneDeep(columns).filter(el => el.show_in_table !== false);
        setAllCols(tempColumns);
        const tempActiveColumns = _.cloneDeep(activeColumns);
        setNonDraggableItems(tempActiveColumns.filter(el => el.default === true));
        setDraggableItems(tempActiveColumns.filter(el => el.default === false));
        if (columns.length && columns.length === activeColumns.length) setAllChecked(true);
    }, [activeColumns, columns]);

    const handleCheck = useCallback(
        accessor => {
            setDisableSave(false);
            const index = allCols.findIndex(item => item.accessor === accessor);
            const tempColumns = [...allCols];
            const tempActiveColumns = [...draggableItems];

            tempColumns[index].active = !tempColumns[index].active;
            if (!tempColumns[index].active) {
                tempColumns[index].order = -1;
                const indActiveColumn = tempActiveColumns.findIndex(
                    col => col.accessor === tempColumns[index].accessor,
                );
                if (indActiveColumn !== -1) {
                    tempActiveColumns.splice(indActiveColumn, 1);
                }
            } else {
                tempActiveColumns.push(tempColumns[index]);
                tempColumns[index].order = draggableItems.length;
            }
            setAllCols(tempColumns);
            setDraggableItems(tempActiveColumns);
            if (searched) {
                const searchIndex = searchResult.findIndex(item => item.accessor === accessor);
                if (searchIndex !== -1) {
                    const tempSearchedColumns = [...searchResult];
                    tempSearchedColumns[searchIndex].active =
                        !tempSearchedColumns[searchIndex].active;
                    setSearchResult(tempSearchedColumns);
                }
            }
            if (tempActiveColumns.length !== columns?.length) setAllChecked(false);
        },
        [allCols, columns, draggableItems, searchResult, searched],
    );

    const handleCreatePropertyOpen = useCallback(async () => {
        if (source === 'people' || source === 'businesses' || source === 'deals') {
            let field =
                source === 'people' || 'businesses'
                    ? payWallConstants.CONTACTS_CUSTOM_PROPERTIES
                    : payWallConstants.DEALS_CUSTOM_PROPERTIES;
            const paywallCheck = await isUnderPayWall(field);
            if (!paywallCheck.success) {
                showPayWallDialog({
                    type:
                        field === payWallConstants.CONTACTS_CUSTOM_PROPERTIES
                            ? payConstants.CUSTOM_PROPERTIES
                            : payConstants.DEALS_CUSTOM_PROPERTIES,
                });
                return;
            }
        }
        setOpenCreatePropertyDialog(true);
    }, [showPayWallDialog, source]);

    const handleCreatePropertyClose = useCallback(() => {
        setEditItem(null);
        setOpenCreatePropertyDialog(false);
    }, []);

    const handleAllChecked = useCallback(
        e => {
            setDisableSave(false);
            setAllChecked(e.target.checked);
            let tempColumns = allCols;
            let orderToBe = draggableItems.length + nonDraggableItems.length;
            for (let i = 0; i < tempColumns.length; i++) {
                if (!tempColumns[i].default) {
                    tempColumns[i].active = e.target.checked;
                    if (tempColumns[i].order < 0) {
                        tempColumns.order = orderToBe++;
                    }
                }
            }
            setAllCols(tempColumns);
            if (!e.target.checked) tempColumns = [];
            else tempColumns = tempColumns.filter(el => el.default === false);
            setDraggableItems(tempColumns);
        },
        [allCols, draggableItems, nonDraggableItems],
    );

    // to generate views for DragAndDrop
    const draggableItemViews = useMemo(() => {
        return draggableItems.map(item => {
            return {
                ...item,
                data: (
                    <DragBox
                        title={item.Header}
                        buttons={[
                            {
                                iconName: !item.default && <DCCrossClose />,
                                iconColor: '',
                                onClick: () => {
                                    handleCheck(item.accessor);
                                },
                            },
                        ]}
                    />
                ),
            };
        });
    }, [draggableItems, handleCheck]);

    // to generate views for non draggable items
    const nonDraggableItemViews = useMemo(() => {
        return nonDraggableItems.map(item => {
            return {
                ...item,
                data: (
                    <DragBox
                        title={item.Header}
                        disabled
                        buttons={[
                            {
                                iconName: <DCLock />,
                                iconColor: '',
                                tooltipMessage: 'This column cannot be deleted.',
                            },
                        ]}
                    />
                ),
            };
        });
    }, [nonDraggableItems]);

    const reorder = (list, startIndex, endIndex) => {
        const result = list.map((item, index) => {
            if (index === startIndex) item.order = endIndex;
            if (index === endIndex) item.order = startIndex;
            return item;
        });

        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);
        return result;
    };

    const onDragEnd = result => {
        if (!result.destination) {
            return;
        }

        if (result.destination.index === result.source.index) {
            return;
        }
        const quotes = reorder(draggableItems, result.source.index, result.destination.index);

        setDraggableItems(quotes);
        setDisableSave(false);
    };

    const handleSearch = useCallback(
        searchTerm => {
            if (searchTerm) {
                setSearched(true);
                try {
                    let tempArr = columns.filter(
                        item => item.Header?.toLowerCase().search(searchTerm.toLowerCase()) >= 0,
                    );
                    setSearchResult(tempArr);
                } catch (err) {
                    showToast({ type: 'error', message: 'Invalid search text', duration: 1500 });
                }
            } else {
                setSearched(false);
            }
        },
        [columns, showToast],
    );

    const handleCreateColumn = useCallback(
        async body => {
            try {
                const response = await createFieldApi(source, body);
                if (response && response.success) {
                    let data = response.data;
                    let newColumn = {
                        Header: data.title,
                        accessor: data.field,
                        active: true,
                        sortable: true,
                        draggable: true,
                        mandatory: data.config.mandatory,
                        default: data.config.is_disallowed,
                        order: draggableItems.length,
                        width: data.config.width,
                        options: data['options'],
                        custom: data.custom || false,
                        type: data.type,
                    };
                    setAllCols(prev => [...prev, newColumn]);
                    handleSave([...allCols, newColumn], draggableItems);
                    setOpenCreatePropertyDialog(false);
                    setDisableSave(true);
                    showToast({
                        type: 'success',
                        message: 'Successfully Added',
                        duration: 1500,
                    });
                }
            } catch (err) {
                let message = 'Something went wrong!';
                if (err.response) {
                    message = err.response.data.message;
                } else {
                    message = err.message;
                }
                showToast({
                    type: 'error',
                    message: message,
                    duration: 1500,
                });
                setOpenCreatePropertyDialog(false);
            }
        },
        [allCols, draggableItems, handleSave, showToast, source],
    );

    const handleEditColumn = useCallback(
        async (body, fieldAccessor) => {
            try {
                const response = await updateFieldApi(source, fieldAccessor, body);
                if (response && response.success) {
                    let tempArray = allCols;
                    const index = findItemIndex(tempArray, 'accessor', fieldAccessor);
                    tempArray[index].Header = response.data.title;
                    tempArray[index].accessor = response.data.field;
                    setAllCols(tempArray);
                    handleSave(tempArray, draggableItems);
                    setOpenCreatePropertyDialog(false);
                    setDisableSave(false);
                    showToast({
                        type: 'success',
                        message: 'Column Updated',
                        duration: 1500,
                    });
                }
            } catch (err) {
                let message = 'Something went wrong!';
                if (err.response) {
                    message = err.response.data.message;
                } else {
                    message = err.message;
                }
                showToast({
                    type: 'error',
                    message: message,
                    duration: 1500,
                });
            }
        },
        [allCols, draggableItems, handleSave, showToast, source],
    );

    const handleOpenDeleteConfirmationDialog = useCallback(item => {
        setOpenConfirmation(true);
        setDeleteInfo(item.accessor);
    }, []);

    const handleConfirmationClose = useCallback(() => {
        setDeleteInfo(null);
        setOpenConfirmation(false);
    }, []);

    const handleDeleteColumn = useCallback(async () => {
        try {
            setLoadingDialog(true);
            const response = await deleteFieldApi(source, deleteInfo);
            if (response && response.success) {
                let tempArray = allCols;
                tempArray = tempArray.filter(item => item.accessor !== deleteInfo);
                setAllCols(tempArray);
                setDraggableItems(tempArray);
                handleSave(tempArray, draggableItems);
                showToast({
                    type: 'success',
                    message: 'Deleted Successfully',
                    duration: 1500,
                });
                setLoadingDialog(false);
                setDisableSave(true);

                handleConfirmationClose();
            }
        } catch (err) {
            let message = 'Something went wrong!';
            if (err['response']) {
                message = err.response.data.message;
            } else {
                message = err.message;
            }
            showToast({
                type: 'error',
                message: message,
                duration: 1500,
            });
            setLoadingDialog(false);
            handleConfirmationClose();
        }
    }, [
        allCols,
        deleteInfo,
        draggableItems,
        handleConfirmationClose,
        handleSave,
        showToast,
        source,
    ]);

    return (
        <Fragment>
            {openConfirmation && (
                <Confirmation
                    open={openConfirmation}
                    onClose={handleConfirmationClose}
                    title="Delete Column"
                    message="Are you sure you want to delete this column?"
                    confirmationType="delete"
                    type="delete"
                    loading={loadingDialog}
                    declinedButton={{
                        title: 'Cancel',
                        onClick: handleConfirmationClose,
                    }}
                    acceptButton={{
                        title: 'Delete',
                        onClick: handleDeleteColumn,
                    }}
                />
            )}
            <Dialog dialogType={'centerZoom sizeMd'} open={open} onClose={onClose}>
                <Div className={'ManageColumns'}>
                    <TopBar DialogTopBar>
                        <Div className={'HeaderBar'}>
                            <H1 className={'HeaderBarTitle'}>Manage Columns</H1>
                            <Div className={'HeaderBarClose'}>
                                <IconButton onClick={onClose}>
                                    <DCCrossClose />
                                </IconButton>
                            </Div>
                        </Div>
                    </TopBar>

                    <GridContainer containerClass={'MCGridContainer'}>
                        <GridType gridNumber={6}>
                            <Div className={'MCColumnsCol'}>
                                <Div className={'MCColSearch'}>
                                    <SearchBox
                                        fullWidth={true}
                                        placeholder={'Search columns'}
                                        handleSearch={handleSearch}
                                        delay={100}
                                    />
                                </Div>
                                <Div className={'MCColHead'}>
                                    <Div className={'MCColHTitle'}>
                                        <H5>{title}</H5>
                                    </Div>
                                    <Div className={'MCColHCheck'}>
                                        <Checkbox
                                            label="All Columns"
                                            uncheckColor={'var(--dark-blue)'}
                                            checkColor={'var(--dark-blue)'}
                                            checked={allChecked}
                                            onChange={handleAllChecked}
                                        />
                                    </Div>
                                </Div>
                                <Div className={'MCColList'}>
                                    <Div className={'MCColLInner'}>
                                        {searched
                                            ? searchResult.sort(alphabaticalCompare).map(col => {
                                                  return (
                                                      <Div
                                                          className={'MCColLItem'}
                                                          key={`${col.accessor})_div`}
                                                      >
                                                          <Checkbox
                                                              key={col.accessor}
                                                              label={
                                                                  <Span className="MCCILabel">
                                                                      {col?.description ? (
                                                                          <ToolTip
                                                                              content={
                                                                                  col?.description
                                                                              }
                                                                          >
                                                                              {col.Header}
                                                                          </ToolTip>
                                                                      ) : (
                                                                          col.Header
                                                                      )}
                                                                      {col.custom && (
                                                                          <ToolTip
                                                                              content={
                                                                                  'Custom Property'
                                                                              }
                                                                          >
                                                                              <DCCompare />
                                                                          </ToolTip>
                                                                      )}
                                                                  </Span>
                                                              }
                                                              uncheckColor={'var(--dark-blue)'}
                                                              checkColor={'var(--dark-blue)'}
                                                              fullWidth={true}
                                                              disabled={!!col.default}
                                                              checked={col.active}
                                                              onChange={() => {
                                                                  if (!col.default) {
                                                                      handleCheck(col.accessor);
                                                                  }
                                                              }}
                                                          />
                                                          {col.custom && (
                                                              <Div className={'MCColHCCBtn'}>
                                                                  <IconButton
                                                                      onClick={() => {
                                                                          setEditItem(col);
                                                                          setOpenCreatePropertyDialog(
                                                                              true,
                                                                          );
                                                                      }}
                                                                      customClass={'primary'}
                                                                  >
                                                                      <DCPencil />
                                                                  </IconButton>
                                                                  <IconButton
                                                                      onClick={() => handleOpenDeleteConfirmationDialog(
                                                                              col,
                                                                          )
                                                                      }
                                                                      customClass={'danger'}
                                                                  >
                                                                      <DCTrash />
                                                                  </IconButton>
                                                              </Div>
                                                          )}
                                                      </Div>
                                                  );
                                              })
                                            : [...allCols].sort(alphabaticalCompare).map(col => {
                                                  return (
                                                      <Div
                                                          className={'MCColLItem'}
                                                          key={`${col.accessor})_div`}
                                                      >
                                                          <Checkbox
                                                              key={col.accessor}
                                                              label={
                                                                  <Span className="MCCILabel">
                                                                      {col?.description ? (
                                                                          <ToolTip
                                                                              content={
                                                                                  col?.description
                                                                              }
                                                                          >
                                                                              {col.Header}
                                                                          </ToolTip>
                                                                      ) : (
                                                                          col.Header
                                                                      )}
                                                                      {col.custom && (
                                                                          <ToolTip
                                                                              content={
                                                                                  'Custom Property'
                                                                              }
                                                                          >
                                                                              <DCCompare />
                                                                          </ToolTip>
                                                                      )}
                                                                  </Span>
                                                              }
                                                              uncheckColor={'var(--dark-blue)'}
                                                              checkColor={'var(--dark-blue)'}
                                                              fullWidth={true}
                                                              disabled={!!col.default}
                                                              checked={col.active}
                                                              onChange={() => {
                                                                  if (!col.default) {
                                                                      handleCheck(col.accessor);
                                                                  }
                                                              }}
                                                          />
                                                          {col.custom && (
                                                              <Div className={'MCColHCCBtn'}>
                                                                  <IconButton
                                                                      onClick={() => {
                                                                          setEditItem(col);
                                                                          setOpenCreatePropertyDialog(
                                                                              true,
                                                                          );
                                                                      }}
                                                                      customClass={'primary'}
                                                                  >
                                                                      <DCPencil />
                                                                  </IconButton>
                                                                  <IconButton
                                                                      onClick={() => handleOpenDeleteConfirmationDialog(
                                                                              col,
                                                                          )
                                                                      }
                                                                      customClass={'danger'}
                                                                  >
                                                                      <DCTrash />
                                                                  </IconButton>
                                                              </Div>
                                                          )}
                                                      </Div>
                                                  );
                                              })}
                                    </Div>
                                </Div>
                                <Div className={'MCColFooter'}>
                                    {!hideCreateProperty && (
                                        <Div className={'MCColFText'}>
                                            Don't see the property you're looking for?{' '}
                                            <Span onClick={handleCreatePropertyOpen}>
                                                Create a property
                                            </Span>
                                        </Div>
                                    )}
                                    <Div className={'MCColFBtns'}>
                                        <Button
                                            buttonType={'BlueFillBtn'}
                                            onClick={() => {
                                                handleSave(allCols, draggableItems);
                                                onClose();
                                            }}
                                            disabled={disableSave}
                                        >
                                            Save
                                        </Button>
                                        <Button buttonType={'RedOutlineBtn'} onClick={onClose}>
                                            Cancel
                                        </Button>
                                    </Div>
                                </Div>
                            </Div>
                        </GridType>
                        <GridType gridNumber={6}>
                            <Div className={'MCSelectedColumns'}>
                                <Div className={'MCSCHeader'}>
                                    <H5>{title}</H5>
                                </Div>
                                <Div className={'MCSCBody'}>
                                    {nonDraggableItems.length > 0 && (
                                        <DragDropContext onDragEnd={onDragEnd}>
                                            <Droppable
                                                droppableId="droppableDisabled"
                                                // mode="virtual"
                                                // isDropDisabled={true}
                                                // renderClone={(provided, snapshot, rubric) => (
                                                //     <Item
                                                //         provided={provided}
                                                //         isDragging={snapshot.isDragging}
                                                //         item={
                                                //             nonDraggableItems[rubric.source.index]
                                                //         }
                                                //     />
                                                // )}
                                            >
                                                {provided => (
                                                    <div
                                                        component="div"
                                                        {...provided.droppableProps}
                                                        ref={provided.innerRef}
                                                    >
                                                        {nonDraggableItemViews.map(
                                                            (item, index) => (
                                                                <Draggable
                                                                    key={item.accessor}
                                                                    draggableId={item.accessor}
                                                                    index={index}
                                                                    isDragDisabled
                                                                >
                                                                    {provided => (
                                                                        <div
                                                                            ref={provided.innerRef}
                                                                            {...provided.draggableProps}
                                                                            {...provided.dragHandleProps}
                                                                            className={'MCSCBDrag'}
                                                                        >
                                                                            {item.data}
                                                                        </div>
                                                                    )}
                                                                </Draggable>
                                                            ),
                                                        )}
                                                        {provided.placeholder}
                                                    </div>
                                                )}
                                            </Droppable>
                                        </DragDropContext>
                                    )}
                                    <DragDropContext onDragEnd={onDragEnd}>
                                        <Droppable
                                            droppableId="droppable"
                                            // mode="virtual"
                                            // renderClone={(provided, snapshot, rubric) => (
                                            //     <Item
                                            //         provided={provided}
                                            //         isDragging={snapshot.isDragging}
                                            //         item={draggableItems[rubric.source.index]}
                                            //     />
                                            // )}
                                        >
                                            {provided => (
                                                <div
                                                    component="div"
                                                    {...provided.droppableProps}
                                                    ref={provided.innerRef}
                                                >
                                                    {draggableItemViews.map((item, index) => (
                                                        <Draggable
                                                            key={item.accessor}
                                                            draggableId={item.accessor}
                                                            index={index}
                                                        >
                                                            {provided => (
                                                                <div
                                                                    ref={provided.innerRef}
                                                                    {...provided.draggableProps}
                                                                    {...provided.dragHandleProps}
                                                                    className={'MCSCBDrag'}
                                                                >
                                                                    {item.data}
                                                                </div>
                                                            )}
                                                        </Draggable>
                                                    ))}
                                                    {provided.placeholder}
                                                </div>
                                            )}
                                        </Droppable>
                                    </DragDropContext>
                                </Div>
                            </Div>
                        </GridType>
                    </GridContainer>
                </Div>
            </Dialog>
            {!hideCreateProperty && openCreatePropertyDialog === true ? (
                <CreateProperty
                    open={openCreatePropertyDialog}
                    onClose={handleCreatePropertyClose}
                    openAsEdit={!!editItem}
                    editItem={editItem}
                    createColumn={handleCreateColumn}
                    editColumn={handleEditColumn}
                />
            ) : null}{' '}
        </Fragment>
    );
};

ManageColumns.propTypes = {
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    title: PropTypes.string.isRequired,
    columns: PropTypes.arrayOf(PropTypes.object).isRequired,
    activeColumns: PropTypes.arrayOf(PropTypes.object).isRequired,
    handleSave: PropTypes.func.isRequired,
    source: PropTypes.string.isRequired,
    hideCreateProperty: PropTypes.bool.isRequired,
};

export default ManageColumns;
