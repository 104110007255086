import React from 'react';

import { SVGIcon } from '../../UIKit/index';

export default function DCUploadCSV(props) {
    return (
        <SVGIcon {...props} viewBox="0 0 393 512">
            <g>
                <path
                    fill="#D9EAFF"
                    d="M278.67,0H13.85C6.48,0,0.51,5.98,0.51,17.57v484.29c2,5.62,7.39,9.3,13.35,9.13h365.66  c5.96,0.18,11.35-3.51,13.35-9.13V118.42c0-6.35-0.84-8.39-2.35-9.9L284.35,2.35C282.84,0.85,280.8,0,278.67,0z"
                />
                <path fill="#1A7AEE" d="M283.37,1.38v108.08h108.08L283.37,1.38z" />
                <path
                    fill="#2B88F8"
                    d="M379.52,511H13.85c-7.37,0-13.35-6.09-13.35-13.6V352.93h392.36V497.4  C392.87,504.91,386.89,511,379.52,511z"
                />
                <g transform="translate(4.605 18.491)">
                    <path
                        fill="none"
                        d="M51.67,137.64h261.46c9.33,0,16.89,7.56,16.89,16.89v122.36c0,9.33-7.56,16.89-16.89,16.89H51.67   c-9.33,0-16.89-7.56-16.89-16.89V154.53C34.78,145.2,42.34,137.64,51.67,137.64z"
                    />
                    <path
                        fill="none"
                        stroke="#6B8AAA"
                        strokeWidth="15"
                        d="M51.67,146.08h261.46c4.66,0,8.44,3.78,8.44,8.44v122.36c0,4.66-3.78,8.44-8.44,8.44H51.67   c-4.66,0-8.44-3.78-8.44-8.44V154.53C43.23,149.86,47.01,146.08,51.67,146.08z"
                    />
                </g>
                <line
                    fill="none"
                    stroke="#6B8AAA"
                    strokeWidth="15"
                    x1="47.71"
                    y1="211.33"
                    x2="327.93"
                    y2="211.33"
                />
                <line
                    fill="none"
                    stroke="#6B8AAA"
                    strokeWidth="15"
                    x1="50.76"
                    y1="257.02"
                    x2="330.98"
                    y2="257.02"
                />
                <text
                    transform="matrix(1 0 0 1 110.9893 462.6982)"
                    fill="#FFFFFF"
                    fontFamily="Source Sans Pro"
                    fontSize="109.7676px"
                >
                    csv
                </text>
            </g>
        </SVGIcon>
    );
}
