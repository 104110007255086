import React from 'react';

import { SVGIcon } from '../../UIKit/index';

export default function DCDesktopEye(props) {
    return (
        <SVGIcon {...props} viewBox="0 0 512 512">
            <path d="M491.8,371.8H20.2c-8.7,0-15.7-7-15.7-15.7s7-15.7,15.7-15.7h471.6c8.7,0,15.7,7,15.7,15.7  S500.5,371.8,491.8,371.8z" />
            <path d="M324.1,508H187.9c-8.7,0-15.7-7.1-15.7-15.7c0-4.2,1.7-8.2,4.6-11.1c13.9-15,23.1-33.7,26.6-53.9  c1.2-8.6,9.1-14.6,17.7-13.5c8.6,1.2,14.6,9.1,13.5,17.7c-2.6,15.8-7.8,31.1-15.6,45.1h73.8c-7.7-14-12.9-29.2-15.6-45  c-1.2-8.6,4.8-16.5,13.4-17.8c8.6-1.2,16.5,4.8,17.8,13.4c3.5,19.3,12,37.3,24.9,52.1c7.1,5,8.8,14.8,3.8,21.9  C333.9,505.5,329.2,508,324.1,508z" />
            <path d="M256,193.6c-34.7,0-62.9-28.2-62.9-62.9s28.2-62.9,62.9-62.9s62.9,28.2,62.9,62.9  S290.7,193.6,256,193.6z M256,99.3c-17.4,0-31.4,14.1-31.4,31.4s14.1,31.4,31.4,31.4s31.4-14.1,31.4-31.4S273.4,99.3,256,99.3z" />
            <path d="M256,256.5c-78.9-2.7-150.3-47.8-186.5-118c-2.8-4.8-2.8-10.8,0-15.6C105.7,52.8,177.1,7.7,256,5  c78.9,2.7,150.3,47.8,186.5,118c2.8,4.8,2.8,10.8,0,15.6C406.3,208.7,334.9,253.8,256,256.5z M101.4,130.8  C133,187,191.6,222.7,256,225.1c64.4-2.4,123-38.1,154.6-94.3C379,74.5,320.4,38.8,256,36.4C191.6,38.8,133,74.5,101.4,130.8z" />
            <path d="M470.8,445.1H41.2c-20.2,0-36.7-16.4-36.7-36.7V104.6c0-8.7,7-15.7,15.7-15.7s15.7,7,15.7,15.7  v303.9c0,2.9,2.4,5.2,5.2,5.2h429.6c2.9,0,5.2-2.4,5.2-5.2V104.6c0-8.7,7-15.7,15.7-15.7s15.7,7,15.7,15.7v303.9  C507.5,428.7,491.1,445.1,470.8,445.1z" />
        </SVGIcon>
    );
}
