import React from 'react';

import { Div, Switch } from '../index';
import PropTypes from 'prop-types';
import './style.scss';

const SwitchWithLabel = ({ customClass, onClick, checked, label, onLabelClick }) => {
    return (
        <Div className={`SwitchWithLabel ${customClass ? customClass : ''}`}>
            <Div className={'SWLInner'}>
                <Div className={'SWLILabel'} onClick={onLabelClick}>
                    {label}
                </Div>
                <Switch
                    checked={checked}
                    switchOnTitle={'On'}
                    switchOffTitle={'Off'}
                    onClick={onClick}
                />
            </Div>
        </Div>
    );
};

SwitchWithLabel.propTypes = {
    customClass: PropTypes.string,
    onClick: PropTypes.func,
    checked: PropTypes.bool,
    label: PropTypes.element,
    onLabelClick: PropTypes.func,
};

export default SwitchWithLabel;

// <SwitchWithLabel label={"label"} onClick={onClick} checked={checked} />;
