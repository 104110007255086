import React from 'react';

import { SVGIcon } from '../../UIKit/index';

export default function DCPDFFile(props) {
    return (
        <SVGIcon {...props} viewBox="0 0 512 512">
            <path
                d="m392 488h-272a48 48 0 0 1 -48-48v-368a48 48 0 0 1 48-48h224l96 96v320a48 48 0 0 1 -48 48z"
                fill="#005eff33"
            />
            <path d="m136 136h80v96h-80z" fill="#005eff" />
            <path
                d="m72 360h368a0 0 0 0 1 0 0v80a48 48 0 0 1 -48 48h-272a48 48 0 0 1 -48-48v-80a0 0 0 0 1 0 0z"
                fill="#005eff"
            />
            <g fill="#005eff50">
                <path d="m440 120h-48a48 48 0 0 1 -48-48v-48z" />
                <path d="m376 176h-128a8 8 0 0 1 0-16h128a8 8 0 0 1 0 16z" />
                <path d="m280 208h-32a8 8 0 0 1 0-16h32a8 8 0 0 1 0 16z" />
                <path d="m376 240h-128a8 8 0 0 1 0-16h128a8 8 0 0 1 0 16z" />
                <path d="m320 272h-184a8 8 0 0 1 0-16h184a8 8 0 0 1 0 16z" />
                <path d="m376 208h-64a8 8 0 0 1 0-16h64a8 8 0 0 1 0 16z" />
                <path d="m376 272h-24a8 8 0 0 1 0-16h24a8 8 0 0 1 0 16z" />
                <path d="m184 304h-48a8 8 0 0 1 0-16h48a8 8 0 0 1 0 16z" />
                <path d="m296 304h-80a8 8 0 0 1 0-16h80a8 8 0 0 1 0 16z" />
                <path d="m376 304h-48a8 8 0 0 1 0-16h48a8 8 0 0 1 0 16z" />
                <path d="m312 144h-64a8 8 0 0 1 0-16h64a8 8 0 0 1 0 16z" />
            </g>
            <path
                d="m336 384h-32a8 8 0 0 0 -8 8v64a8 8 0 0 0 16 0v-24h8a8 8 0 0 0 0-16h-8v-16h24a8 8 0 0 0 0-16z"
                fill="#fff"
            />
            <path
                d="m192 384h-16a8 8 0 0 0 -8 8v64a8 8 0 0 0 16 0v-24h8a24 24 0 0 0 0-48zm0 32h-8v-16h8a8 8 0 0 1 0 16z"
                fill="#fff"
            />
            <path
                d="m240 384a8 8 0 0 0 -8 8v64a8 8 0 0 0 8 8 40 40 0 0 0 0-80zm8 62.629v-45.258a24 24 0 0 1 0 45.258z"
                fill="#fff"
            />
        </SVGIcon>
    );
}
