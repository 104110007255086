import React from 'react';

import { SVGIcon } from '../../UIKit/index';

export default function DCGrayBusiness(props) {
    return (
        <SVGIcon {...props} viewBox="0 0 455.48 499.22">
            <g id="Artboard_5" transform="translate(-29.12 -6.65)">
                <circle
                    id="Ellipse_1"
                    cx="226.24"
                    cy="226.24"
                    r="226.24"
                    transform="translate(30.62 8.15)"
                    fill="#fff"
                    stroke="#707171"
                    strokeMiterlimit="10"
                    strokeWidth="3"
                />
                <rect
                    id="Rectangle_1"
                    width="33.12"
                    height="0.25"
                    transform="translate(423.98 385.49)"
                    fill="#ececec"
                />
                <rect
                    id="Rectangle_2"
                    width="8.69"
                    height="0.25"
                    transform="translate(329.72 388.2)"
                    fill="#ececec"
                />
                <rect
                    id="Rectangle_3"
                    width="19.19"
                    height="0.25"
                    transform="translate(403.79 376.2)"
                    fill="#ececec"
                />
                <rect
                    id="Rectangle_4"
                    width="43.2"
                    height="0.25"
                    transform="translate(59.64 377.88)"
                    fill="#ececec"
                />
                <rect
                    id="Rectangle_5"
                    width="6.33"
                    height="0.25"
                    transform="translate(111.74 377.88)"
                    fill="#ececec"
                />
                <rect
                    id="Rectangle_6"
                    width="93.68"
                    height="0.25"
                    transform="translate(138.66 382.1)"
                    fill="#ececec"
                />
                <path
                    id="Path_1"
                    d="M82.84,135h90.7c11.62,0,14.42-16.27,3.45-20.09l-.43-.14c12.14-10.11-14.49-31.44-43.42-1.91,2.26-9.85-23.46-6.29-31.33,7.14C99,111.17,74.5,126.49,82.84,135Z"
                    fill="#f4f5f6"
                />
                <path
                    id="Path_2"
                    d="M325.43,94.43h54.38s12.75-4.8,11.1-17.52S370,74.79,370,74.79,375.84,57,357.2,62.08a27.647,27.647,0,0,0-20.46,22.63S312.25,80.43,325.43,94.43Z"
                    fill="#f4f5f6"
                />
                <path
                    id="Path_3"
                    d="M375.39,130.21h35.9s8.42-3.17,7.33-11.56-13.8-1.4-13.8-1.4,3.85-11.72-8.46-8.4a18.22,18.22,0,0,0-13.5,14.94S366.69,121,375.39,130.21Z"
                    fill="#f4f5f6"
                />
                <path id="Path_4" d="M401.32,244.44l.27,113.57h64.39l.36-113.57Z" fill="#f4f5f6" />
                <path id="Path_5" d="M413.89,229.99l.03,25.32h39.85l.06-25.32Z" fill="#f4f5f6" />
                <path id="Path_6" d="M413.97,255.44h-8.42l-.02-7.59h8.43Z" fill="#fff" />
                <rect
                    id="Rectangle_7"
                    width="8.42"
                    height="7.59"
                    transform="translate(421.7 247.85)"
                    fill="#e3e2e3"
                />
                <rect
                    id="Rectangle_8"
                    width="8.42"
                    height="7.59"
                    transform="translate(437.86 247.85)"
                    fill="#fff"
                />
                <path id="Path_7" d="M462.3,255.31h-8.42l.02-7.59h8.42Z" fill="#e3e2e3" />
                <path id="Path_8" d="M413.73,269.14h-8.22l-.02-7.41h8.23Z" fill="#e3e2e3" />
                <rect
                    id="Rectangle_9"
                    width="8.23"
                    height="7.4"
                    transform="translate(421.64 261.73)"
                    fill="#e3e2e3"
                />
                <rect
                    id="Rectangle_10"
                    width="8.23"
                    height="7.4"
                    transform="translate(437.9 261.86)"
                    fill="#e3e2e3"
                />
                <path id="Path_9" d="M462.25,269.26h-8.23l.02-7.4h8.23Z" fill="#fff" />
                <path id="Path_10" d="M413.67,282.62h-8.04l-.01-7.22h8.03Z" fill="#fff" />
                <rect
                    id="Rectangle_11"
                    width="8.04"
                    height="7.22"
                    transform="translate(421.86 275.53)"
                    fill="#fff"
                />
                <rect
                    id="Rectangle_12"
                    width="8.04"
                    height="7.22"
                    transform="translate(437.98 275.53)"
                    fill="#e3e2e3"
                />
                <rect
                    id="Rectangle_13"
                    width="8.59"
                    height="7.74"
                    transform="translate(421.37 236.7)"
                    fill="#fff"
                />
                <rect
                    id="Rectangle_14"
                    width="8.59"
                    height="7.74"
                    transform="translate(437.53 236.7)"
                    fill="#e3e2e3"
                />
                <path id="Path_11" d="M462,282.62h-8.04l.02-7.22h8.04Z" fill="#e3e2e3" />
                <path id="Path_12" d="M413.76,295.91h-7.85l-.01-7.05h7.85Z" fill="#e3e2e3" />
                <rect
                    id="Rectangle_15"
                    width="7.85"
                    height="7.05"
                    transform="translate(421.88 288.74)"
                    fill="#fff"
                />
                <path id="Path_13" d="M445.83,295.79h-7.85l.01-7.05h7.85Z" fill="#e3e2e3" />
                <path id="Path_14" d="M462.05,295.91H454.2l.02-7.05h7.85Z" fill="#fff" />
                <path id="Path_15" d="M413.99,310.59h-8.75l-.02-7.84h8.76Z" fill="#e3e2e3" />
                <path id="Path_16" d="M430.18,310.71h-8.75l-.01-7.84h8.76Z" fill="#e3e2e3" />
                <path id="Path_17" d="M446.25,310.71H437.5v-7.84h8.76Z" fill="#fff" />
                <path id="Path_18" d="M462.18,310.59h-8.75l.02-7.84h8.76Z" fill="#e3e2e3" />
                <path id="Path_19" d="M414.04,323.09h-8.55l-.02-7.66h8.56Z" fill="#e3e2e3" />
                <rect
                    id="Rectangle_16"
                    width="8.55"
                    height="7.66"
                    transform="translate(421.67 315.56)"
                    fill="#e3e2e3"
                />
                <rect
                    id="Rectangle_17"
                    width="8.55"
                    height="7.66"
                    transform="translate(437.61 315.43)"
                    fill="#fff"
                />
                <path id="Path_20" d="M462.21,323.09h-8.54l.01-7.66h8.56Z" fill="#e3e2e3" />
                <path id="Path_21" d="M413.86,337.16h-8.34l-.01-7.46h8.34Z" fill="#e3e2e3" />
                <rect
                    id="Rectangle_18"
                    width="8.34"
                    height="7.46"
                    transform="translate(421.55 329.7)"
                    fill="#fff"
                />
                <rect
                    id="Rectangle_19"
                    width="8.34"
                    height="7.46"
                    transform="translate(437.58 329.7)"
                    fill="#fff"
                />
                <path id="Path_22" d="M461.95,337.16h-8.34l.02-7.46h8.34Z" fill="#e3e2e3" />
                <path id="Path_23" d="M413.86,349.95h-8.14l-.01-7.27h8.14Z" fill="#e3e2e3" />
                <path id="Path_24" d="M461.92,349.95h-8.14l.02-7.27h8.14Z" fill="#fafafa" />
                <path
                    id="Path_25"
                    d="M445.96,342.68h-24.3l.05,1.83h.67l.01,13.5h2.43l-.01-13.5h18.04l-.02,13.5h2.43l.02-13.5h.73Z"
                    fill="#e3e2e3"
                />
                <path id="Path_26" d="M433.94,358.01h-9.01v-13.5h9.01Z" fill="#f4f5f6" />
                <path id="Path_27" d="M442.72,358.01h-9v-13.5h9.02Z" fill="#f4f5f6" />
                <path id="Path_28" d="M401.41,280.57l.19,77.44H320.86l-.74-77.44Z" fill="#f4f5f6" />
                <path id="Path_29" d="M385.96,297.78h10.08l-.03-9.04H385.93Z" fill="#e3e2e3" />
                <path id="Path_30" d="M365.85,297.78h10.08l-.05-9.04H365.8Z" fill="#e3e2e3" />
                <path id="Path_31" d="M345.74,297.78h10.08l-.06-9.04H345.67Z" fill="#e3e2e3" />
                <path id="Path_32" d="M325.63,297.78h10.08l-.08-9.04H325.55Z" fill="#e3e2e3" />
                <path id="Path_33" d="M385.58,315.43h10.86l-.03-9.73H385.54Z" fill="#e3e2e3" />
                <path id="Path_34" d="M365.5,315.43h10.87l-.05-9.73H365.45Z" fill="#e3e2e3" />
                <path id="Path_35" d="M345.43,315.43h10.86l-.06-9.73H345.36Z" fill="#e3e2e3" />
                <path id="Path_36" d="M325.36,315.43h10.86l-.08-9.73H325.27Z" fill="#e3e2e3" />
                <path id="Path_37" d="M385.86,331.58H396.4l-.02-9.44H385.82Z" fill="#e3e2e3" />
                <path id="Path_38" d="M365.81,331.58h10.54l-.04-9.44H365.75Z" fill="#e3e2e3" />
                <path id="Path_39" d="M345.75,331.58H356.3l-.06-9.44H345.68Z" fill="#e3e2e3" />
                <path id="Path_40" d="M325.7,331.58h10.54l-.07-9.44H325.61Z" fill="#e3e2e3" />
                <path id="Path_41" d="M386.09,348.14h10.22l-.02-9.14H386.05Z" fill="#e3e2e3" />
                <path id="Path_42" d="M326.01,348.14h10.22l-.07-9.14H325.93Z" fill="#e3e2e3" />
                <path
                    id="Path_43"
                    d="M345.28,339h31.63l.01,2.76H374.9l.08,16.25h-2.93l-.08-16.25h-21.7l.11,16.25h-2.92l-.12-16.25h-2.03Z"
                    fill="#e3e2e3"
                />
                <path id="Path_44" d="M361.29,358.01h10.83l-.08-16.25H361.19Z" fill="#f4f5f6" />
                <path id="Path_45" d="M350.31,358.01h10.83l-.09-16.25H350.2Z" fill="#f4f5f6" />
                <path
                    id="Path_46"
                    d="M45.23,244.44l1.86,113.57h64.39l-1.24-113.57Z"
                    fill="#f4f5f6"
                />
                <path
                    id="Path_47"
                    d="M49.29,247.72l.22,13.83.06,3.79.06,3.8h8.44l-.06-3.8-.06-3.79-.21-13.83Z"
                    fill="#e3e2e3"
                />
                <path
                    id="Path_48"
                    d="M65.45,247.72l.2,13.83.06,3.79.06,3.8H74.2l-.05-3.8-.06-3.79-.19-13.83Z"
                    fill="#e3e2e3"
                />
                <path
                    id="Path_49"
                    d="M81.61,247.72l.19,13.83.05,3.79.05,3.8h8.43l-.05-3.8-.04-3.79-.18-13.83Z"
                    fill="#e3e2e3"
                />
                <path
                    id="Path_50"
                    d="M97.77,247.72l.17,13.83.07,5.86.02,1.73h8.43l-.02-1.73-.06-5.86-.15-13.83Z"
                    fill="#e3e2e3"
                />
                <path
                    id="Path_51"
                    d="M49.8,275.4l.22,13.81.06,3.79.06,3.79h8.45L58.53,293l-.06-3.79-.21-13.81Z"
                    fill="#e3e2e3"
                />
                <path
                    id="Path_52"
                    d="M65.92,275.4l.2,13.81.08,5.34.03,2.24h8.45l-.04-2.24-.07-5.34-.19-13.81Z"
                    fill="#e3e2e3"
                />
                <path
                    id="Path_53"
                    d="M82.03,275.4l.19,13.81.05,3.79.05,3.79h8.44L90.72,293l-.05-3.79L90.5,275.4Z"
                    fill="#e3e2e3"
                />
                <path
                    id="Path_54"
                    d="M98.15,275.4l.16,13.81.05,3.79.04,3.79h8.45l-.04-3.79-.04-3.79-.16-13.81Z"
                    fill="#e3e2e3"
                />
                <path
                    id="Path_55"
                    d="M50.28,302.75l.22,13.74.06,3.78.06,3.77h8.43L59,320.27l-.06-3.78-.21-13.74Z"
                    fill="#e3e2e3"
                />
                <path
                    id="Path_56"
                    d="M66.35,302.75l.21,13.74.05,3.78.06,3.77H75.1l-.05-3.77L75,316.49l-.19-13.74Z"
                    fill="#e3e2e3"
                />
                <path
                    id="Path_57"
                    d="M82.43,302.75l.18,13.74.05,3.78.05,3.77h8.43l-.04-3.77-.05-3.78-.17-13.74Z"
                    fill="#e3e2e3"
                />
                <path
                    id="Path_58"
                    d="M98.5,302.75l.17,13.74.06,5.22.02,2.33h8.44l-.03-2.33-.05-5.22-.16-13.74Z"
                    fill="#e3e2e3"
                />
                <path
                    id="Path_59"
                    d="M103.19,329.7H50.54l.12,7.46h56.78l-.08-7.46Z"
                    fill="#e3e2e3"
                />
                <path
                    id="Path_60"
                    d="M91.2,342.68H66.9l-.03,1.83h.73l.21,13.5h2.42l-.19-13.5H88.07l.17,13.5h2.43l-.17-13.5h.68Z"
                    fill="#e3e2e3"
                />
                <path id="Path_61" d="M79.35,358.01H70.34l-.19-13.5h9.02Z" fill="#f4f5f6" />
                <path id="Path_62" d="M88.13,358.01h-9l-.19-13.5h9.02Z" fill="#f4f5f6" />
                <path
                    id="Path_63"
                    d="M171.57,250.99l.73,107.02H111.63L110.3,250.99Z"
                    fill="#f4f5f6"
                />
                <path
                    id="Path_64"
                    d="M149.93,331.58h18.16l.02,3.42.03,3.43.09,12.42h-7.69l-.1-12.77H145.66l-.06-6.5Z"
                    fill="#e3e2e3"
                />
                <path
                    id="Path_65"
                    d="M120.63,331.58h17.06l.07,6.5H123.17l.14,12.77h-7.68l-.15-12.42-.06-4.96-.02-1.89Z"
                    fill="#e3e2e3"
                />
                <path
                    id="Path_66"
                    d="M131.37,343.6h20.91l.01,1.82h.24l.1,12.59h-2.26l-.11-12.59H133.44l.13,12.59h-2.26l-.14-12.59h.21Z"
                    fill="#e3e2e3"
                />
                <path id="Path_67" d="M141.88,358.01h8.4l-.11-12.59h-8.41Z" fill="#f4f5f6" />
                <path id="Path_68" d="M133.66,358.01h8.4l-.12-12.59h-8.41Z" fill="#f4f5f6" />
                <path id="Path_69" d="M144.5,255.31h23.31l.14,20.09H144.69Z" fill="#e3e2e3" />
                <path id="Path_70" d="M114.27,255.31h23.32l.19,20.09H114.52Z" fill="#e3e2e3" />
                <path id="Path_71" d="M145.15,280.57h22.31l.15,19.2H145.32Z" fill="#e3e2e3" />
                <path id="Path_72" d="M114.97,280.57h22.32l.2,19.2H115.2Z" fill="#e3e2e3" />
                <path id="Path_73" d="M145.38,306.68h22.44l.14,19.26H145.55Z" fill="#e3e2e3" />
                <path id="Path_74" d="M115.29,306.68h22.45l.19,19.26H115.52Z" fill="#e3e2e3" />
                <path id="Path_75" d="M168.02,71.6l3.73,4.82H342.62l3.75-4.82Z" fill="#707171" />
                <g id="Group_1" data-name="Group 1" opacity="0.4">
                    <path
                        id="Path_76"
                        d="M168.02,71.6l3.73,4.82H342.62l3.75-4.82Z"
                        fill="#707171"
                    />
                </g>
                <rect
                    id="Rectangle_20"
                    width="171.26"
                    height="303.59"
                    transform="translate(171.4 65.39)"
                    fill="#f4f5f6"
                />
                <g id="Group_2" data-name="Group 2" opacity="0.4">
                    <rect
                        id="Rectangle_21"
                        width="171.26"
                        height="303.59"
                        transform="translate(171.4 65.39)"
                        fill="#f4f5f6"
                    />
                </g>
                <path
                    id="Path_77"
                    d="M178.34,72.86a3.11,3.11,0,0,1-3.06,3.57,4,4,0,0,1-3.82-3.57,3.12,3.12,0,0,1,3.07-3.6,4,4,0,0,1,3.81,3.6Z"
                    fill="#e3e2e3"
                />
                <path
                    id="Path_78"
                    d="M184.65,72.86a3.14,3.14,0,0,1-3.1,3.57,4,4,0,0,1-3.78-3.57,3.14,3.14,0,0,1,3.09-3.6A4,4,0,0,1,184.65,72.86Z"
                    fill="#e3e2e3"
                />
                <path
                    id="Path_79"
                    d="M191,72.86a3.16,3.16,0,0,1-3.12,3.57,3.93,3.93,0,0,1-3.76-3.57,3.17,3.17,0,0,1,3.12-3.6,3.92,3.92,0,0,1,3.76,3.6Z"
                    fill="#e3e2e3"
                />
                <path
                    id="Path_80"
                    d="M197.26,72.86a3.2,3.2,0,0,1-3.16,3.57,3.87,3.87,0,0,1-3.72-3.57,3.2,3.2,0,0,1,3.15-3.6A3.88,3.88,0,0,1,197.26,72.86Z"
                    fill="#e3e2e3"
                />
                <path
                    id="Path_81"
                    d="M203.56,72.86a3.23,3.23,0,0,1-3.18,3.57,3.85,3.85,0,0,1-3.7-3.57,3.23,3.23,0,0,1,3.18-3.6,3.84,3.84,0,0,1,3.7,3.6Z"
                    fill="#e3e2e3"
                />
                <path
                    id="Path_82"
                    d="M209.86,72.86a3.26,3.26,0,0,1-3.21,3.57A3.81,3.81,0,0,1,203,72.86a3.26,3.26,0,0,1,3.21-3.6,3.8,3.8,0,0,1,3.65,3.6Z"
                    fill="#e3e2e3"
                />
                <path
                    id="Path_83"
                    d="M216.17,72.86a3.29,3.29,0,0,1-3.24,3.57,3.77,3.77,0,0,1-3.64-3.57,3.29,3.29,0,0,1,3.23-3.6A3.78,3.78,0,0,1,216.17,72.86Z"
                    fill="#e3e2e3"
                />
                <path
                    id="Path_84"
                    d="M222.47,72.86a3.32,3.32,0,0,1-3.27,3.57,3.74,3.74,0,0,1-3.61-3.57,3.32,3.32,0,0,1,3.27-3.6,3.73,3.73,0,0,1,3.61,3.6Z"
                    fill="#e3e2e3"
                />
                <path
                    id="Path_85"
                    d="M228.78,72.86a3.36,3.36,0,0,1-3.3,3.57,3.69,3.69,0,0,1-3.58-3.57,3.35,3.35,0,0,1,3.29-3.6,3.7,3.7,0,0,1,3.59,3.6Z"
                    fill="#e3e2e3"
                />
                <path
                    id="Path_86"
                    d="M235.08,72.86a3.38,3.38,0,0,1-3.32,3.57,3.66,3.66,0,0,1-3.56-3.57,3.38,3.38,0,0,1,3.32-3.6,3.66,3.66,0,0,1,3.56,3.6Z"
                    fill="#e3e2e3"
                />
                <path
                    id="Path_87"
                    d="M241.38,72.86A3.42,3.42,0,0,1,238,76.43a3.63,3.63,0,0,1-3.53-3.57,3.43,3.43,0,0,1,3.36-3.6,3.62,3.62,0,0,1,3.55,3.6Z"
                    fill="#e3e2e3"
                />
                <path
                    id="Path_88"
                    d="M247.69,72.86a3.45,3.45,0,0,1-3.38,3.57,3.59,3.59,0,0,1-3.5-3.57,3.45,3.45,0,0,1,3.38-3.6A3.59,3.59,0,0,1,247.69,72.86Z"
                    fill="#e3e2e3"
                />
                <path
                    id="Path_89"
                    d="M254,72.86a3.48,3.48,0,0,1-3.41,3.57,3.56,3.56,0,0,1-3.47-3.57,3.48,3.48,0,0,1,3.41-3.6,3.55,3.55,0,0,1,3.47,3.6Z"
                    fill="#e3e2e3"
                />
                <path
                    id="Path_90"
                    d="M260.3,72.86a3.44,3.44,0,1,1-3.45-3.6,3.52,3.52,0,0,1,3.45,3.6Z"
                    fill="#e3e2e3"
                />
                <path
                    id="Path_91"
                    d="M266.6,72.86a3.55,3.55,0,0,1-3.47,3.57,3.48,3.48,0,0,1-3.41-3.57,3.55,3.55,0,0,1,3.47-3.6,3.49,3.49,0,0,1,3.41,3.6Z"
                    fill="#e3e2e3"
                />
                <path
                    id="Path_92"
                    d="M272.9,72.86a3.58,3.58,0,0,1-3.49,3.57A3.46,3.46,0,0,1,266,72.86a3.59,3.59,0,0,1,3.5-3.6,3.45,3.45,0,0,1,3.4,3.6Z"
                    fill="#e3e2e3"
                />
                <path
                    id="Path_93"
                    d="M279.21,72.86a3.62,3.62,0,0,1-3.53,3.57,3.42,3.42,0,0,1-3.35-3.57,3.61,3.61,0,0,1,3.52-3.6,3.43,3.43,0,0,1,3.36,3.6Z"
                    fill="#e3e2e3"
                />
                <path
                    id="Path_94"
                    d="M285.51,72.86A3.65,3.65,0,0,1,282,76.43a3.39,3.39,0,0,1-3.33-3.57,3.65,3.65,0,0,1,3.55-3.6,3.39,3.39,0,0,1,3.29,3.6Z"
                    fill="#e3e2e3"
                />
                <path
                    id="Path_95"
                    d="M291.82,72.86a3.7,3.7,0,0,1-3.58,3.57,3.35,3.35,0,0,1-3.3-3.57,3.68,3.68,0,0,1,3.58-3.6,3.37,3.37,0,0,1,3.3,3.6Z"
                    fill="#e3e2e3"
                />
                <path
                    id="Path_96"
                    d="M298.12,72.86a3.73,3.73,0,0,1-3.61,3.57,3.32,3.32,0,0,1-3.27-3.57,3.72,3.72,0,0,1,3.61-3.6,3.32,3.32,0,0,1,3.27,3.6Z"
                    fill="#e3e2e3"
                />
                <path
                    id="Path_97"
                    d="M304.42,72.86a3.75,3.75,0,0,1-3.63,3.57,3.3,3.3,0,0,1-3.25-3.57,3.76,3.76,0,0,1,3.64-3.6,3.29,3.29,0,0,1,3.24,3.6Z"
                    fill="#e3e2e3"
                />
                <path
                    id="Path_98"
                    d="M310.73,72.86a3.81,3.81,0,0,1-3.67,3.57,3.26,3.26,0,0,1-3.21-3.57,3.79,3.79,0,0,1,3.66-3.6,3.27,3.27,0,0,1,3.22,3.6Z"
                    fill="#e3e2e3"
                />
                <path
                    id="Path_99"
                    d="M317,72.86a3.84,3.84,0,0,1-3.69,3.57,3.24,3.24,0,0,1-3.19-3.57,3.84,3.84,0,0,1,3.7-3.6,3.23,3.23,0,0,1,3.18,3.6Z"
                    fill="#e3e2e3"
                />
                <path
                    id="Path_100"
                    d="M323.34,72.86a3.89,3.89,0,0,1-3.73,3.57,3.2,3.2,0,0,1-3.15-3.57,3.86,3.86,0,0,1,3.72-3.6,3.21,3.21,0,0,1,3.16,3.6Z"
                    fill="#e3e2e3"
                />
                <path
                    id="Path_101"
                    d="M329.64,72.86a3.92,3.92,0,0,1-3.75,3.57,3.18,3.18,0,0,1-3.13-3.57,3.91,3.91,0,0,1,3.75-3.6,3.18,3.18,0,0,1,3.13,3.6Z"
                    fill="#e3e2e3"
                />
                <path
                    id="Path_102"
                    d="M335.94,72.86a4,4,0,0,1-3.78,3.57,3.14,3.14,0,0,1-3.1-3.57,4,4,0,0,1,3.78-3.6,3.14,3.14,0,0,1,3.1,3.6Z"
                    fill="#e3e2e3"
                />
                <path
                    id="Path_103"
                    d="M342.25,72.86a4,4,0,0,1-3.81,3.57,3.11,3.11,0,0,1-3.07-3.57,4,4,0,0,1,3.81-3.6,3.12,3.12,0,0,1,3.07,3.6Z"
                    fill="#e3e2e3"
                />
                <path id="Path_104" d="M168.01,66.72H346.38l-.01,4.88H168.02Z" fill="#707171" />
                <path id="Path_105" d="M168,61.84l3.72,4.88H342.64l3.75-4.88Z" fill="#707171" />
                <g id="Group_3" data-name="Group 3" opacity="0.4">
                    <path id="Path_106" d="M168,61.84l3.72,4.88H342.64l3.75-4.88Z" fill="#707171" />
                </g>
                <path id="Path_107" d="M167.98,56.94H346.41l-.02,4.9H168Z" fill="#707171" />
                <g id="Group_4" data-name="Group 4">
                    <path id="Path_108" d="M171.71,61.84H342.65l-.01,4.88H171.72Z" fill="#007cff" />
                </g>
                <path id="Path_109" d="M171.71,61.84H342.65l-.01,4.88H171.72Z" fill="#f4f5f6" />
                <g id="Group_5" data-name="Group 5" opacity="0.7">
                    <rect
                        id="Rectangle_22"
                        width="171.25"
                        height="2.15"
                        transform="translate(171.45 162.8)"
                        fill="#fff"
                    />
                </g>
                <g id="Group_6" data-name="Group 6" opacity="0.8">
                    <rect
                        id="Rectangle_23"
                        width="170.89"
                        height="2.14"
                        transform="translate(171.8 269.51)"
                        fill="#fff"
                    />
                </g>
                <rect
                    id="Rectangle_24"
                    width="21.17"
                    height="22.87"
                    transform="translate(206.95 85.77)"
                    fill="#e3e2e3"
                />
                <rect
                    id="Rectangle_25"
                    width="21.17"
                    height="22.87"
                    transform="translate(247.61 85.77)"
                    fill="#e3e2e3"
                />
                <rect
                    id="Rectangle_26"
                    width="21.17"
                    height="22.87"
                    transform="translate(288.26 85.77)"
                    fill="#e3e2e3"
                />
                <rect
                    id="Rectangle_27"
                    width="21.17"
                    height="22.87"
                    transform="translate(206.95 120.01)"
                    fill="#e3e2e3"
                />
                <rect
                    id="Rectangle_28"
                    width="21.17"
                    height="22.87"
                    transform="translate(247.61 120.01)"
                    fill="#e3e2e3"
                />
                <rect
                    id="Rectangle_29"
                    width="21.17"
                    height="22.87"
                    transform="translate(288.26 120.01)"
                    fill="#e3e2e3"
                />
                <rect
                    id="Rectangle_30"
                    width="21.17"
                    height="22.87"
                    transform="translate(206.95 285.98)"
                    fill="#e3e2e3"
                />
                <rect
                    id="Rectangle_31"
                    width="21.17"
                    height="22.87"
                    transform="translate(247.61 285.98)"
                    fill="#e3e2e3"
                />
                <rect
                    id="Rectangle_32"
                    width="21.17"
                    height="22.87"
                    transform="translate(288.26 285.98)"
                    fill="#e3e2e3"
                />
                <rect
                    id="Rectangle_33"
                    width="21.17"
                    height="22.87"
                    transform="translate(184.26 334.13)"
                    fill="#e3e2e3"
                />
                <rect
                    id="Rectangle_34"
                    width="21.17"
                    height="22.87"
                    transform="translate(308.65 334.13)"
                    fill="#e3e2e3"
                />
                <rect
                    id="Rectangle_35"
                    width="21.17"
                    height="22.87"
                    transform="translate(206.95 182.96)"
                    fill="#e3e2e3"
                />
                <rect
                    id="Rectangle_36"
                    width="21.17"
                    height="22.87"
                    transform="translate(247.61 182.96)"
                    fill="#e3e2e3"
                />
                <rect
                    id="Rectangle_37"
                    width="21.17"
                    height="22.87"
                    transform="translate(288.26 182.96)"
                    fill="#e3e2e3"
                />
                <rect
                    id="Rectangle_38"
                    width="21.17"
                    height="22.87"
                    transform="translate(206.95 217.21)"
                    fill="#e3e2e3"
                />
                <rect
                    id="Rectangle_39"
                    width="21.17"
                    height="22.87"
                    transform="translate(247.61 217.21)"
                    fill="#e3e2e3"
                />
                <rect
                    id="Rectangle_40"
                    width="21.17"
                    height="22.87"
                    transform="translate(288.26 217.21)"
                    fill="#e3e2e3"
                />
                <path id="Path_110" d="M232.54,336.81h21.91l-.08,22.75H232.39Z" fill="#e3e2e3" />
                <path id="Path_111" d="M258.68,336.81H281.5l-1.08,22.75H258.44Z" fill="#e3e2e3" />
                <path id="Path_112" d="M284.99,364.15H227.17l-.23-4.59H285.2Z" fill="#707171" />
                <g id="Group_7" data-name="Group 7" opacity="0.1">
                    <path id="Path_113" d="M284.99,364.15H227.17l-.23-4.59H285.2Z" fill="#707171" />
                </g>
                <path id="Path_114" d="M287.32,368.98H225.13l-.25-4.51h62.67Z" fill="#707171" />
                <g id="Group_8" data-name="Group 8" opacity="0.4">
                    <path id="Path_115" d="M289.37,328.75H222.91l-.29-5.15h67.02Z" fill="#fff" />
                </g>
                <path id="Path_116" d="M222.91,328.75l9.63,8.06H281.5l7.87-8.06Z" fill="#707171" />
                <path
                    id="Path_117"
                    d="M227.42,332.52l3.39,27h2.7l-1.08-27h0a5.47,5.47,0,0,0-5,0Z"
                    fill="#707171"
                />
                <path
                    id="Path_118"
                    d="M285.81,332.52l-3.33,27h-2.7l1-27h0a4.56,4.56,0,0,1,5,0Z"
                    fill="#707171"
                />
                <path
                    id="Path_119"
                    d="M388.05,70.43a13,13,0,0,1-2.09-.68c-1.26-.49-2.88-1.32-4.56-2.13a13.85,13.85,0,0,0-4.46-1.34c-1.19-.13-2,0-2.11-.31s.59-.89,2-1.16a9.19,9.19,0,0,1,5.43.93,16.77,16.77,0,0,1,4.23,3.14C387.47,69.81,388.05,70.43,388.05,70.43Z"
                    fill="#707171"
                />
                <path
                    id="Path_120"
                    d="M395.39,64.52l-1.51.59c-.9.37-2,.89-3.3,1.44-2.53,1.12-4.69,2.28-5,1.77-.16-.23.17-.8.9-1.5a11.271,11.271,0,0,1,3.32-2.18,7.58,7.58,0,0,1,4-.56,6,6,0,0,1,1.21.29C395.25,64.46,395.39,64.52,395.39,64.52Z"
                    fill="#707171"
                />
                <path
                    id="Path_121"
                    d="M374.43,74.84l-.41-.25c-.26-.17-.65-.39-1.12-.64a24,24,0,0,0-3.7-1.61c-2.94-1.06-5.57-.82-5.65-1.27,0-.2.6-.54,1.74-.7a9.92,9.92,0,0,1,4.42.49,8.81,8.81,0,0,1,3.68,2.48,6.761,6.761,0,0,1,.8,1.08Z"
                    fill="#707171"
                />
                <path
                    id="Path_122"
                    d="M377.32,68.76l-1.06.61-2.35,1.31c-1.77,1-3.14,2.11-3.48,1.81-.15-.15,0-.62.45-1.23a7.25,7.25,0,0,1,2.28-2,5.94,5.94,0,0,1,2.91-.7,5,5,0,0,1,1.25.2Z"
                    fill="#707171"
                />
                <path
                    id="Path_123"
                    d="M372,65.3s-1-.35-2.52-1-3.57-1.55-5.82-2.29a16,16,0,0,0-5.9-.82c-1.53.09-2.51.39-2.65.11s.67-1,2.39-1.55a11.75,11.75,0,0,1,6.81.28A17.11,17.11,0,0,1,370,63.42a24,24,0,0,1,2,1.88Z"
                    fill="#707171"
                />
                <path
                    id="Path_124"
                    d="M378.75,56.71l-.57.16c-.36.09-.87.25-1.48.44a42.3,42.3,0,0,0-4.53,1.78c-3.42,1.53-5.62,3.8-6.09,3.44-.22-.17.06-1,.93-2.08a12.13,12.13,0,0,1,4.29-3.25,10.65,10.65,0,0,1,5.28-.92,8.41,8.41,0,0,1,1.6.27Z"
                    fill="#707171"
                />
                <path
                    id="Path_125"
                    d="M147.12,95.69A14.36,14.36,0,0,1,145,95c-1.26-.49-2.88-1.32-4.56-2.14A13.84,13.84,0,0,0,136,91.54c-1.19-.14-2,0-2.11-.32s.59-.89,2-1.15a9.1,9.1,0,0,1,5.43.93,16.13,16.13,0,0,1,4.23,3.14C146.54,95.06,147.12,95.69,147.12,95.69Z"
                    fill="#707171"
                />
                <path
                    id="Path_126"
                    d="M154.46,89.78l-1.52.59c-.9.37-2,.88-3.3,1.44-2.53,1.11-4.68,2.27-5,1.77-.17-.23.17-.8.89-1.51a11.07,11.07,0,0,1,3.32-2.17,7.64,7.64,0,0,1,4-.57,6.59,6.59,0,0,1,1.2.29,4.058,4.058,0,0,1,.41.16Z"
                    fill="#707171"
                />
                <path
                    id="Path_127"
                    d="M133.5,100.09l-.42-.25c-.26-.16-.64-.38-1.11-.64a26,26,0,0,0-3.7-1.61c-3-1-5.58-.82-5.66-1.27,0-.2.6-.53,1.74-.7a9.84,9.84,0,0,1,4.43.5,8.86,8.86,0,0,1,3.68,2.47,7.809,7.809,0,0,1,.79,1.08Z"
                    fill="#707171"
                />
                <path
                    id="Path_128"
                    d="M136.38,94l-1,.61c-.63.35-1.46.81-2.35,1.32-1.78,1-3.15,2.11-3.48,1.8-.16-.14,0-.61.45-1.23a7.12,7.12,0,0,1,2.28-1.94,5.94,5.94,0,0,1,2.91-.71A5,5,0,0,1,136.38,94Z"
                    fill="#707171"
                />
                <path
                    id="Path_129"
                    d="M362.87,223.25c-5.38,7.25-7.45,10.58-9.53,12.63-2.27,2.24-25.45.53-28.25-.64-2.15-.9-.67-3.68.35-3.54,3.81.52,18.16-2,22.41-3.84.74-.33,8-9.16,8-9.16Z"
                    fill="#e3e2e3"
                />
                <path
                    id="Path_130"
                    d="M354.05,218.47c4.54-8.33,13.49-14.45,16.2-14.79,3.9-.51,5.21.26,4.07,3.4-1.94,5.32-5.51,10.93-10.54,17.71Z"
                    fill="#707171"
                />
                <g id="Group_9" data-name="Group 9" opacity="0.1">
                    <path
                        id="Path_131"
                        d="M354.05,218.47c4.54-8.33,13.49-14.45,16.2-14.79,3.9-.51,5.21.26,4.07,3.4-1.94,5.32-5.51,10.93-10.54,17.71Z"
                        fill="#707171"
                    />
                </g>
                <path
                    id="Path_132"
                    d="M370.25,203.68a61.572,61.572,0,0,1,6.7-2,88.169,88.169,0,0,1,12.61-2.07,71.714,71.714,0,0,1,7.44-.42,3.2,3.2,0,0,1,.75.09,3.74,3.74,0,0,1,2.76,4.38c-4.18,22.05-4.38,31.83-3.78,43.18l-25.38.91a1.37,1.37,0,0,1-1.35-1.14c-1.12-5.72-4.63-18.44-4.63-18.44-3.17-3-3.7-6.8-4-9-.18-1.19,2.48-2.48,5.37-8.78C366.73,210.39,369,204.16,370.25,203.68Z"
                    fill="#707171"
                />
                <path
                    id="Path_133"
                    d="M386.29,247.19l-9.25.34c-1.38-11-1.12-20.71-.64-31.65a315.369,315.369,0,0,0,9.89,31.31Z"
                    fill="#f4f5f6"
                />
                <g id="Group_11" data-name="Group 11" opacity="0.8">
                    <path
                        id="Path_134"
                        d="M389,331.79c-.23-.51-.46-1-.68-1.58-10-23.85-17.62-72-17.62-72l17.81-9.08s5.06,42.38,12,69.95c1.06,4.2,2.15,8.05,3.28,11.33l-12.15,5.58Z"
                        fill="#e3e2e3"
                    />
                    <g id="Group_10" data-name="Group 10" opacity="0.8">
                        <path
                            id="Path_135"
                            d="M389,331.79c-.23-.51-.46-1-.68-1.58-10-23.85-17.62-72-17.62-72l17.81-9.08s5.06,42.38,12,69.95c1.06,4.2,2.15,8.05,3.28,11.33l-12.15,5.58Z"
                            fill="#e3e2e3"
                        />
                    </g>
                </g>
                <path
                    id="Path_136"
                    d="M372,251.91s-8.13,57-6.21,80.32c2,24.56,9.3,66.64,9.3,66.64l6-.41a286.771,286.771,0,0,0-1.49-65.89c13.75-35.39,15.29-79.76,15.29-79.76Z"
                    fill="#e3e2e3"
                />
                <path
                    id="Path_137"
                    d="M372,251.91s-8.13,57-6.21,80.32c2,24.56,9.3,66.64,9.3,66.64l6-.41a286.771,286.771,0,0,0-1.49-65.89c13.75-35.39,15.29-79.76,15.29-79.76Z"
                    fill="#e3e2e3"
                    opacity="0.8"
                />
                <path
                    id="Path_138"
                    d="M381.86,400.33a5.92,5.92,0,0,1,.67,2.58c.16,3.3.39,12.06.39,12.06h-.72s-.44-4.29-.94-6.44-.83-.3-1.93,2-4.19,4.76-8.89,5.2c-5.52.52-7.76.41-6.25-1.13s5.12-2.71,5.12-2.71,4.8-8.22,4.52-13.75c-.3-5.91,6.53-.36,7.85-1.19C382.15,397.11,381.59,399.85,381.86,400.33Z"
                    fill="#707171"
                />
                <path
                    id="Path_139"
                    d="M400.88,321.11c-3.49.76-6.59,3.32-15.76,4.24h-.55c-.39,0-.79.07-1.21.09-2.47.13-16.55-.53-19.16-2.06,2.27-23.45,6.74-73.28,6.74-73.28l10.76-1.31,13.87-.42s15.3,15.8,11.78,30.14C402.09,300,404.72,303.34,400.88,321.11Z"
                    fill="#707171"
                />
                <path
                    id="Path_140"
                    d="M396.6,235.57a3.5,3.5,0,0,1-1-2.84s0-1.58-.05-4c-.08-7-.14-21,.59-22.87,1.6-4,4.34-6.18,5.77-3.84,1.68,2.74,1.8,23.53,1.8,23.53C403.28,239.31,398.25,237.49,396.6,235.57Z"
                    fill="#e3e2e3"
                />
                <path
                    id="Path_141"
                    d="M393.27,198.77l-.83,1.52c-3.25,5.88-10.75,13.45-13.68,14.19a11,11,0,0,0-3.07-4.09,6.42,6.42,0,0,0-3.28,3.86c-2.52-4.08-2.29-9.24-1.66-11.8h0c1.48-.44,3.06-.9,4.56-1.27a91,91,0,0,1,12.61-2,44.555,44.555,0,0,1,5.35-.41Z"
                    fill="#e3e2e3"
                />
                <path
                    id="Path_142"
                    d="M393.27,198.77l-.83,1.52c-3.25,5.88-10.75,13.45-13.68,14.19a37.005,37.005,0,0,1-1.39-4.34c-1.57.77-2.73,3-3.25,4.68-2.52-4.09-4-9.81-3.37-12.37h0c1.48-.44,3.06-.9,4.56-1.27a91,91,0,0,1,12.61-2,44.555,44.555,0,0,1,5.35-.41Z"
                    fill="#f4f5f6"
                />
                <path
                    id="Path_143"
                    d="M374,227.39l-3.48-4.72-3.11,6.16s3.18,1.78,5.82,1.18Z"
                    fill="#e3e2e3"
                />
                <path
                    id="Path_144"
                    d="M364.97,221.21l-1.48,4.98,3.89,2.61,3.11-6.13Z"
                    fill="#e3e2e3"
                />
                <path
                    id="Path_145"
                    d="M396.71,249.74l-12.85.35h-1.08l-2.78.07h-1.06l-7.55.19c-.23,0-.44-.11-.45-.26l-.3-2.42c0-.16.18-.31.45-.32l7.46-.3h.89l3.69-.14H384l12.38-.49a.5.5,0,0,1,.45.19l.3,2.75C397.23,249.53,397,249.73,396.71,249.74Z"
                    fill="#007cff"
                />
                <path
                    id="Path_146"
                    d="M396.71,249.74l-12.85.35h-1.08l-2.78.07h-1.06l-7.55.19c-.23,0-.44-.11-.45-.26l-.3-2.42c0-.16.18-.31.45-.32l7.46-.3h.89l3.69-.14H384l12.38-.49a.5.5,0,0,1,.45.19l.3,2.75C397.23,249.53,397,249.73,396.71,249.74Z"
                    fill="#fff"
                    opacity="0.2"
                />
                <path
                    id="Path_147"
                    d="M375,250.64h.82a.23.23,0,0,0,.28-.21l-.46-3.43c0-.1-.16-.19-.33-.19h-.82a.23.23,0,0,0-.28.2l.46,3.44a.32.32,0,0,0,.33.19Z"
                    fill="#1a2e35"
                />
                <path
                    id="Path_148"
                    d="M389.27,250.43h.78a.22.22,0,0,0,.27-.18l-.47-3.45c0-.1-.16-.18-.31-.17h-.78c-.16,0-.27.08-.26.19l.46,3.42A.3.3,0,0,0,389.27,250.43Z"
                    fill="#1a2e35"
                />
                <path
                    id="Path_149"
                    d="M393.53,265.24l-2-.71,8.9-26.63c1.42-4.23.88-8.27-4.37-10.12-4.9-1.73-8.77.74-10.2,5l-9,26.55-2-.71,9-26.54c1.66-4.9,4.86-8.23,12.58-5.51s7.57,7.14,5.92,12.07Z"
                    fill="#707171"
                />
                <path
                    id="Path_150"
                    d="M398.56,281.67l-39-14,8.84-25.83a4.74,4.74,0,0,1,6.06-3L402.89,249a6.39,6.39,0,0,1,3.85,8Z"
                    fill="#707171"
                />
                <path
                    id="Path_151"
                    d="M394.24,280.12,363.85,269.2a4.69,4.69,0,0,1-2.77-5.95l7.33-21.42a4.74,4.74,0,0,1,6.06-3L402.89,249a6.39,6.39,0,0,1,3.85,8L400,277.23a4.51,4.51,0,0,1-5.76,2.89Z"
                    fill="#fff"
                    opacity="0.4"
                />
                <g id="Group_12" data-name="Group 12" opacity="0.3">
                    <path
                        id="Path_152"
                        d="M393.53,265.24,392,266.61l-.41-2.08,8.9-26.63c1.42-4.23.88-8.27-4.37-10.12-4.9-1.73-8.77.74-10.2,5l-9,26.55-1.62,1.35-.35-2.06,9-26.54c1.66-4.9,4.86-8.23,12.58-5.51s7.57,7.14,5.92,12.07Z"
                        fill="#fff"
                    />
                </g>
                <path
                    id="Path_153"
                    d="M380.93,249.21l-3.25-1.16.41-1.23,3.25,1.16Z"
                    fill="#e3e2e3"
                />
                <path
                    id="Path_154"
                    d="M378.23,257.19l-3.26-1.16.42-1.24,3.25,1.17Z"
                    fill="#e3e2e3"
                />
                <path
                    id="Path_155"
                    d="M397.64,255.17,394.38,254l.41-1.23,3.26,1.16Z"
                    fill="#e3e2e3"
                />
                <path
                    id="Path_156"
                    d="M394.97,263.18l-3.27-1.17.41-1.24,3.27,1.17Z"
                    fill="#e3e2e3"
                />
                <path
                    id="Path_157"
                    d="M391.19,253.66l-6.98-2.49.89-2.64,6.97,2.49Z"
                    fill="#f4f5f6"
                />
                <path
                    id="Path_158"
                    d="M399.61,236.8c-3.13,1.8-15.79-.23-26.5-6.67a6.921,6.921,0,0,0-1.19-.39c-.67-.49,1-1.62,2-2.14.55-.28,24.18,2.7,25.81,2.93C401.58,235.35,399.91,235.29,399.61,236.8Z"
                    fill="#e3e2e3"
                />
                <path
                    id="Path_159"
                    d="M389.52,333.72c1.73,4.91,7.49,7.07,11.53,4a5.7,5.7,0,0,0,.47-.39c17.5-16.2,39.76-39.82,39.76-39.82l-3.75-4.75a247.443,247.443,0,0,0-37,26.25c-4,3.44-8.15,7.18-12.19,11.18h0Z"
                    fill="#e3e2e3"
                />
                <path
                    id="Path_160"
                    d="M389.52,333.72c1.73,4.91,7.49,7.07,11.53,4a5.7,5.7,0,0,0,.47-.39c17.5-16.2,39.76-39.82,39.76-39.82l-3.75-4.75a247.443,247.443,0,0,0-37,26.25c-4,3.44-8.15,7.18-12.19,11.18h0Z"
                    fill="#e3e2e3"
                    opacity="0.8"
                />
                <path
                    id="Path_161"
                    d="M435.26,293.36a5.16,5.16,0,0,1,1.28-2.13c2.1-2.18,7.78-7.82,7.78-7.82l.51.5s-2.56,3-3.66,4.72.35.8,2.65.15,5.94.14,9.37,3.36c4,3.79,5.46,5.54,3.42,5.37s-5.21-2.11-5.21-2.11-8.68,1.59-12.2,5.3c-3.77,4-4.59-4.67-6-5.13C432.9,295.17,435.12,293.86,435.26,293.36Z"
                    fill="#707171"
                />
                <path
                    id="Path_162"
                    d="M375.2,198.05c1.88,2.24,3.34,4.94.84,8.25a3.81,3.81,0,0,0-.82,2.44c.06,1.41,1,2.72,5.32-.29,6.3-4.36,5.44-8.73,5.44-8.73-3.52-.82-6.08-8-7.16-12.07Z"
                    fill="#e3e2e3"
                />
                <path
                    id="Path_163"
                    d="M378.88,193.92c.62,1.74-1.05,4.7-2.39,5.92a11.368,11.368,0,0,0-1.29-1.8l2.15-6.15a6.25,6.25,0,0,1,1.53,2.03Z"
                    opacity="0.2"
                />
                <path
                    id="Path_164"
                    d="M360.16,179c-1.67,6.52.83,13.69,5.44,18.61,4.66-5.11,13.47-4,18.37-8.88,3.28-3.24,3.88-8.69,1.85-12.82-2.18-4.47-8.62-8.76-13.7-8.06C366.42,168.6,361.58,173.46,360.16,179Z"
                    fill="#707171"
                />
                <path
                    id="Path_165"
                    d="M379.58,176.74a9.16,9.16,0,0,0-12.74-2.41c-5.91,4-4.81,14-2.17,19.64,2.9,6.33,11.37,6.58,15.32.38,2.65-4.12,2.09-6.95,1.11-13.65a9.27,9.27,0,0,0-1.52-3.96Z"
                    fill="#e3e2e3"
                />
                <path
                    id="Path_166"
                    d="M368,190.74a2.35,2.35,0,0,1-2,0,14.87,14.87,0,0,0,1.21-3.85Z"
                    fill="#707171"
                />
                <path
                    id="Path_167"
                    d="M373.14,190.16a.162.162,0,1,0-.3-.12,4.32,4.32,0,0,1-3.12,2.4.17.17,0,0,0-.12.19h0a.16.16,0,0,0,.18.12,5,5,0,0,0,.76-.21,4.56,4.56,0,0,0,2.6-2.38Z"
                    fill="#707171"
                />
                <path
                    id="Path_168"
                    d="M368.39,171.28a7,7,0,0,0-1,2.79c7.81,1.81,13.57,10,15.78,17.78,2.12-2.78,6.26-6.18,7.87-9.52,1.79-3.75-.6-8-4.2-10.11a64.691,64.691,0,0,0-9.9-3.91A7.39,7.39,0,0,0,368.39,171.28Z"
                    fill="#707171"
                />
                <path
                    id="Path_169"
                    d="M384,184.74a2.22,2.22,0,0,0-2.61-1.74c-1.65.37-2.25,2.68-2.15,4.15.14,1.64,1.41,2.76,2.81,1.74a5,5,0,0,0,2-3.24A2.319,2.319,0,0,0,384,184.74Z"
                    fill="#e3e2e3"
                />
                <path
                    id="Path_170"
                    d="M371.17,184.07c.18.66,0,1.28-.46,1.4s-.91-.32-1.08-1,0-1.28.45-1.4S371,183.41,371.17,184.07Z"
                    fill="#707171"
                />
                <path
                    id="Path_171"
                    d="M365.21,186.21c.17.66,0,1.28-.46,1.4s-.91-.32-1.09-1,0-1.28.46-1.4S365,185.55,365.21,186.21Z"
                    fill="#707171"
                />
                <path
                    id="Path_172"
                    d="M364.32,185.22h-1.69S363.76,186.2,364.32,185.22Z"
                    fill="#707171"
                />
                <path
                    id="Path_173"
                    d="M372.43,181.11a.25.25,0,0,0,.12-.11.34.34,0,0,0-.09-.46h0a3.83,3.83,0,0,0-3.53-.42.34.34,0,0,0-.17.43.33.33,0,0,0,.43.17,3.22,3.22,0,0,1,2.92.38.32.32,0,0,0,.32.01Z"
                    fill="#707171"
                />
                <path
                    id="Path_174"
                    d="M362.37,184.69a.31.31,0,0,0,.11-.14,3.23,3.23,0,0,1,2.16-2,.33.33,0,0,0,.27-.38h0a.35.35,0,0,0-.4-.26,3.93,3.93,0,0,0-2.65,2.4.33.33,0,0,0,.16.44A.32.32,0,0,0,362.37,184.69Z"
                    fill="#707171"
                />
                <path
                    id="Path_175"
                    d="M370.28,183.08h-1.69S369.72,184.06,370.28,183.08Z"
                    fill="#707171"
                />
                <path
                    id="Path_176"
                    d="M361.46,188.39c.77,4.1,4.1,9.78,7.65,12-2.39,1.82-6.67-.24-9.2-1.84a10.66,10.66,0,0,1-4.51-7.45,18.08,18.08,0,0,1,1.19-8.81c1.71-4.63,5-8.3,8.78-11.37.77-.61,2.07-1.11,2.57-.26.35.58-.05,1.3-.43,1.86C363.81,177.85,360.92,181.6,361.46,188.39Z"
                    fill="#707171"
                />
                <path
                    id="Path_177"
                    d="M380,195.9h0a.25.25,0,0,1-.18-.3c2.67-10.8-8.9-19-12.51-21.3a.251.251,0,0,1,.26-.43c3.68,2.32,15.49,10.73,12.74,21.85A.26.26,0,0,1,380,195.9Z"
                    fill="#707171"
                />
                <path id="Path_178" d="M382.05,188.08l-.22,3.81,1.02.84.5-1.17Z" fill="#f4f5f6" />
                <path
                    id="Path_179"
                    d="M398.9,199.52a4.74,4.74,0,0,0-4.52,1.26,9.19,9.19,0,0,0-2.3,5.06c-.37,2.88.36,5.79,1.14,8.61a29.21,29.21,0,0,1,1.58,8.6c3.06-1.68,7.51-5,11.79-5.44-.59-6.82-1.38-11.35-2.7-13.61a12.5,12.5,0,0,0-2.71-3.56A6.549,6.549,0,0,0,398.9,199.52Z"
                    fill="#707171"
                />
                <g id="Group_13" data-name="Group 13" opacity="0.1">
                    <path
                        id="Path_180"
                        d="M398.9,199.52a4.74,4.74,0,0,0-4.52,1.26,9.19,9.19,0,0,0-2.3,5.06c-.37,2.88.36,5.79,1.14,8.61a29.21,29.21,0,0,1,1.58,8.6c3.06-1.68,7.51-5,11.79-5.44-.59-6.82-1.38-11.35-2.7-13.61a12.5,12.5,0,0,0-2.71-3.56A6.549,6.549,0,0,0,398.9,199.52Z"
                        fill="#fff"
                    />
                </g>
                <path
                    id="Path_181"
                    d="M326,231.63s-7.55,1.25-7.65,1.59-.06,4.59-.06,4.59l6.78-2.57Z"
                    fill="#e3e2e3"
                />
                <path
                    id="Path_182"
                    d="M318.31,237.81l-6.38-6.01,2.85-.83,4.73,2.56Z"
                    fill="#e3e2e3"
                />
                <path id="Path_183" d="M125.69,390.31l7.13-.38.62,16.88-7.12.39Z" fill="#e3e2e3" />
                <path
                    id="Path_184"
                    d="M135.2,405.29c2.21,1.37,5.43,2.57,11.28,3.16,1.29.13,1.72,3.1-1.29,3.43s-8.26.8-10.44,1c-3.55.39-4.87.7-7.67,1a1.28,1.28,0,0,1-1.4-1.13l-.3-6.42a.51.51,0,0,1,.48-.55l8.06-.88a2.06,2.06,0,0,1,1.28.39Z"
                    fill="#707171"
                />
                <path id="Path_185" d="M126.01,399.02l7.14-.38-.34-8.71-7.12.39Z" opacity="0.2" />
                <path
                    id="Path_186"
                    d="M138.67,405.89a.93.93,0,0,0,.11-.92.63.63,0,0,0-.45-.43c-.91-.25-2.79,1.35-3,1.55a.17.17,0,0,0,0,.17.19.19,0,0,0,.15.12,8.311,8.311,0,0,0,1.93,0A2,2,0,0,0,138.67,405.89Zm-2.8.2c.76-.58,1.9-1.36,2.39-1.22a.31.31,0,0,1,.21.22.57.57,0,0,1-.06.61c-.29.38-1.23.52-2.55.4Z"
                    fill="#e3e2e3"
                />
                <path
                    id="Path_187"
                    d="M135.56,406.34c.67-.51,1.57-2.12,1.33-2.88a.66.66,0,0,0-.67-.44.82.82,0,0,0-.68.31c-.65.77-.23,2.83-.24,2.91a.16.16,0,0,0,.09.12h.08Zm.63-3c.25,0,.36.11.34.2a3.38,3.38,0,0,1-1,2.32,3.91,3.91,0,0,1,.22-2.34.54.54,0,0,1,.35-.18Z"
                    fill="#e3e2e3"
                />
                <path
                    id="Path_188"
                    d="M69.34,382.82l7.07,1.5-5.15,15.99-7.06-1.49Z"
                    fill="#e3e2e3"
                />
                <path
                    id="Path_189"
                    d="M73.17,400.32c1.66,2,5.08,4.43,10.46,6.81,1.2.53.67,3.48-2.3,2.85s-8.82-2.1-11-2.56c-3.49-.74-4.85-.85-7.62-1.38h0a1.28,1.28,0,0,1-1-1.52l1.71-6.2a.51.51,0,0,1,.63-.37L72,399.63A2.06,2.06,0,0,1,73.17,400.32Z"
                    fill="#707171"
                />
                <path id="Path_190" d="M66.69,391.07l7.06,1.5,2.66-8.24-7.07-1.5Z" opacity="0.2" />
                <path
                    id="Path_191"
                    d="M114.22,233.11s7.53,65.08,8.1,89.41c.53,22.41.16,72,.16,72l13-.61s5.4-46.08,7-68.66c1.42-20.68-3-86.82-3-86.82Z"
                    fill="#707171"
                />
                <path
                    id="Path_192"
                    d="M117.32,258.8c1.16,13.46,2.74,30.78,4.18,45.28,3-8.39,4.8-29.49,1.24-42.42C120.59,253.79,118.63,255.52,117.32,258.8Z"
                    fill="#707171"
                    opacity="0.2"
                />
                <path
                    id="Path_193"
                    d="M120.35,391.93l16.77-1.27-.77,5.51-14.11,1.17Z"
                    fill="#707171"
                />
                <path
                    id="Path_194"
                    d="M99.51,235.64S87.37,295.53,83.17,319.5c-3.86,22.08-19,68.91-19,68.91l12.94,2.74s21.44-47.37,27.38-69.24c5.43-20,19-81.2,19-81.2Z"
                    fill="#707171"
                />
                <path
                    id="Path_195"
                    d="M63.83,384.02l17.38,2.87-1.82,4.75L63.62,388.3Z"
                    fill="#707171"
                />
                <path
                    id="Path_196"
                    d="M75.64,401.33a.93.93,0,0,0,.39-.87.64.64,0,0,0-.28-.55c-.79-.51-3.08.43-3.34.53a.23.23,0,0,0-.1.16.18.18,0,0,0,.1.16,8.841,8.841,0,0,0,1.83.65,2,2,0,0,0,1.4-.08ZM73,400.6c.93-.35,2.23-.7,2.61-.4a.29.29,0,0,1,.13.27.61.61,0,0,1-.24.56c-.41.26-1.34.1-2.55-.41Z"
                    fill="#e3e2e3"
                />
                <path
                    id="Path_197"
                    d="M72.57,400.76c.77-.27,2.15-1.52,2.15-2.33a.64.64,0,0,0-.49-.62.81.81,0,0,0-.74.09c-.86.53-1.12,2.59-1.14,2.68a.15.15,0,0,0,.06.15.23.23,0,0,1,.16.03Zm1.56-2.65c.24.09.29.21.27.31,0,.53-1,1.5-1.67,1.88a3.75,3.75,0,0,1,.94-2.14.52.52,0,0,1,.39-.07Z"
                    fill="#e3e2e3"
                />
                <path
                    id="Path_198"
                    d="M75.81,249c1.69-1.68,1.93-3.52,3-5.47.71-1.33-.93-.82.71-.64a7.269,7.269,0,0,1,1.75.61c1.43.55,2.8,1.23,4.24,1.74,1.23.44,3.44.23,4.49.85,1.7,1,.06,5.4-.29,6.82-.42,1.72,2.2,2.62,2.63.88.61-2.48,2.2-6.9.28-9.23-1.18-1.45-3-1.16-4.66-1.48-3.1-.61-6.28-3.44-9.55-3.1a2.45,2.45,0,0,0-2.21,1.61c-.52,1.14-.21,2.41-.89,3.61a8.3,8.3,0,0,1-1.3,1.71c-1.27,1.26.55,3.35,1.82,2.09Z"
                    fill="#007cff"
                />
                <path
                    id="Path_199"
                    d="M75.81,249c1.69-1.68,1.93-3.52,3-5.47.71-1.33-.93-.82.71-.64a7.269,7.269,0,0,1,1.75.61c1.43.55,2.8,1.23,4.24,1.74,1.23.44,3.44.23,4.49.85,1.7,1,.06,5.4-.29,6.82-.42,1.72,2.2,2.62,2.63.88.61-2.48,2.2-6.9.28-9.23-1.18-1.45-3-1.16-4.66-1.48-3.1-.61-6.28-3.44-9.55-3.1a2.45,2.45,0,0,0-2.21,1.61c-.52,1.14-.21,2.41-.89,3.61a8.3,8.3,0,0,1-1.3,1.71c-1.27,1.26.55,3.35,1.82,2.09Z"
                    fill="#707171"
                />
                <path
                    id="Path_200"
                    d="M49.8,249.96h52.859a3.248,3.248,0,0,1,3.248,3.248V281.5a5.6,5.6,0,0,1-5.6,5.6H52.127a5.6,5.6,0,0,1-5.6-5.6V253.208a3.248,3.248,0,0,1,3.248-3.248Z"
                    transform="matrix(0.948, 0.319, -0.319, 0.948, 89.05, -10.28)"
                    fill="#707171"
                />
                <path
                    id="Path_201"
                    d="M49.8,249.96h52.859a3.248,3.248,0,0,1,3.248,3.248V281.5a5.6,5.6,0,0,1-5.6,5.6H52.127a5.6,5.6,0,0,1-5.6-5.6V253.208a3.248,3.248,0,0,1,3.248-3.248Z"
                    transform="matrix(0.948, 0.319, -0.319, 0.948, 89.05, -10.28)"
                    fill="#fff"
                    opacity="0.3"
                />
                <rect
                    id="Rectangle_41"
                    width="23.82"
                    height="1"
                    transform="translate(71.2 285.08) rotate(-70.71)"
                    fill="#f4f5f6"
                />
                <g id="Group_14" data-name="Group 14" opacity="0.3">
                    <rect
                        id="Rectangle_42"
                        width="23.82"
                        height="1"
                        transform="translate(71.2 285.08) rotate(-70.71)"
                        fill="#f4f5f6"
                    />
                </g>
                <path
                    id="Path_202"
                    d="M106.05,262,55.59,245a3,3,0,0,0-3.8,1.89l-.34,1a8.44,8.44,0,0,0,5.32,10.69L96.9,272.11a8.45,8.45,0,0,0,10.69-5.33l.35-1A3,3,0,0,0,106.05,262Z"
                    opacity="0.2"
                />
                <path
                    id="Path_203"
                    d="M107.16,258.77,56.7,241.86a3,3,0,0,0-3.79,1.89l-.35,1a8.45,8.45,0,0,0,5.32,10.69L98,268.93a8.45,8.45,0,0,0,10.69-5.33l.35-1a3,3,0,0,0-1.88-3.83Z"
                    fill="#007cff"
                />
                <path
                    id="Path_204"
                    d="M107.16,258.77,56.7,241.86a3,3,0,0,0-3.79,1.89l-.35,1a8.45,8.45,0,0,0,5.32,10.69L98,268.93a8.45,8.45,0,0,0,10.69-5.33l.35-1a3,3,0,0,0-1.88-3.83Z"
                    fill="#e3e2e3"
                />
                <path
                    id="Path_205"
                    d="M107.16,258.77,56.7,241.86a3,3,0,0,0-3.79,1.89l-.35,1a8.45,8.45,0,0,0,5.32,10.69L98,268.93a8.45,8.45,0,0,0,10.69-5.33l.35-1a3,3,0,0,0-1.88-3.83Z"
                    fill="#e3e2e3"
                    opacity="0.5"
                />
                <rect
                    id="Rectangle_43"
                    width="5.66"
                    height="11.9"
                    rx="1.95"
                    transform="translate(63.333 251.975) rotate(18.52)"
                    fill="#007cff"
                />
                <rect
                    id="Rectangle_44"
                    width="5.66"
                    height="11.9"
                    rx="1.95"
                    transform="translate(90.505 261.065) rotate(18.51)"
                    fill="#007cff"
                />
                <rect
                    id="Rectangle_45"
                    width="5.66"
                    height="11.9"
                    rx="1.95"
                    transform="translate(63.333 251.975) rotate(18.52)"
                    fill="#f4f5f6"
                />
                <rect
                    id="Rectangle_46"
                    width="5.66"
                    height="11.9"
                    rx="1.95"
                    transform="translate(90.505 261.065) rotate(18.51)"
                    fill="#f4f5f6"
                />
                <path
                    id="Path_206"
                    d="M148.54,184.42c.45.89,1,2,1.54,2.93s1.1,2,1.67,3c1.15,1.95,2.32,3.88,3.56,5.7a58.371,58.371,0,0,0,3.88,5.09,17.07,17.07,0,0,0,3.93,3.51l.2.13h.09l.25.05a4,4,0,0,0,1.61-.38,19.149,19.149,0,0,0,4.71-3.16,47.569,47.569,0,0,0,4.72-4.59c.76-.83,1.52-1.66,2.25-2.53s1.48-1.79,2.13-2.6l.12-.15a3.89,3.89,0,0,1,6.46,4.32,62.79,62.79,0,0,1-9,12.53,28.88,28.88,0,0,1-6.78,5.37,14.46,14.46,0,0,1-5.21,1.74,11.27,11.27,0,0,1-3.33-.1,10,10,0,0,1-1.69-.45,4.069,4.069,0,0,1-.79-.32l-.6-.27a27.48,27.48,0,0,1-7.23-5.44,53.351,53.351,0,0,1-5.17-6.06c-1.54-2.07-3-4.17-4.3-6.32-.7-1.07-1.33-2.12-1.94-3.25s-1.21-2.17-1.84-3.42a6.026,6.026,0,1,1,10.75-5.45h0Z"
                    fill="#707171"
                />
                <path
                    id="Path_207"
                    d="M182.41,194.55l5.67,2-.39-6.62s-5.9-2.08-6.68,1.05v1.45a2.2,2.2,0,0,0,1.4,2.12Z"
                    fill="#e3e2e3"
                />
                <path id="Path_208" d="M193.91,195.03l1.2-5.65-7.42.6.39,6.62Z" fill="#e3e2e3" />
                <g id="Character--inject-2">
                    <path
                        id="Path_209"
                        d="M150.22,207.62c-.67-.76-1.3-1.52-1.92-2.29a63.179,63.179,0,0,1-5.22-7.84,83,83,0,0,1-4.1-8.23c-.17-.4-.33-.8-.49-1.2,3-1.82,9.48-.93,11.57,7.49A31.23,31.23,0,0,1,150.22,207.62Z"
                        opacity="0.3"
                    />
                    <path
                        id="Path_210"
                        d="M137.23,243.62l-37.72-8c.44-3.43,3.52-14.25,5.92-28.34A227.406,227.406,0,0,0,108,177.94a.22.22,0,0,1,0-.08,4.61,4.61,0,0,1,4.87-4.35H113c2.35.15,5.28.41,8.11.87a144,144,0,0,1,16,3.4,42.905,42.905,0,0,1,5.1,2,7,7,0,0,1,4.21,5.82C147.11,193.88,146.79,210,137.23,243.62Z"
                        fill="#fff"
                    />
                    <path
                        id="Path_211"
                        d="M132.47,183.23l-1.08,2.16a.91.91,0,0,1-.83.6l-1.87-.16c-.27,0-.49-.24-.59-.59l-.76-2.59,2.8-1.74Z"
                        fill="#707171"
                    />
                    <path
                        id="Path_212"
                        d="M120.68,173.72c-3-.48-6.07-.76-8.55-.92h-.23a4.86,4.86,0,0,0-5,4.69,238.65,238.65,0,0,1-2.72,30.95c-3.66,26.3-7.29,31.53-7.29,31.53l16.83,3.18c5.09-23.89,9-56.51,8.6-69.19Z"
                        fill="#707171"
                    />
                    <path
                        id="Path_213"
                        d="M104.74,204.8a231.012,231.012,0,0,0,2-23.65,1.419,1.419,0,0,1,.25-.24c2.12-1.8,7.39,2.52,6,10.58C111.61,199.16,107.89,203.77,104.74,204.8Z"
                        opacity="0.3"
                    />
                    <path
                        id="Path_214"
                        d="M140.07,249.09l-10.17-2.72c6.38-44.75,7.88-56.65,6.44-69.19a73.873,73.873,0,0,1,7.43,2.39,8.15,8.15,0,0,1,4.58,6.64C149.21,193.22,143.89,211.14,140.07,249.09Z"
                        fill="#707171"
                    />
                    <path
                        id="Path_215"
                        d="M128.36,230.64a2.15,2.15,0,0,1,2.88,1h0a2.16,2.16,0,1,1-2.89-.95Z"
                        fill="#1a2e35"
                    />
                    <path
                        id="Path_216"
                        d="M127.09,233.73a2.52,2.52,0,0,1,1.09-3.39h0a2.52,2.52,0,1,1-1.09,3.39Z"
                        fill="#fff"
                    />
                    <path
                        id="Path_217"
                        d="M130.39,181c-8-1.25-9.93-6.63-9.93-6.63,4.23-2.75,4.88-12.45,4.78-17.87l3.54,4.47L133.6,167a22.5,22.5,0,0,0-.87,2.29c-1,3.1-.77,6.16,3.66,8.25C136.39,177.56,137.25,182,130.39,181Z"
                        fill="#e3e2e3"
                    />
                    <path
                        id="Path_218"
                        d="M119,173.39l2.64-.38a24.75,24.75,0,0,0,8.26,7.83,9.53,9.53,0,0,0-4.33,3.5C117.67,179.67,119,173.39,119,173.39Z"
                        fill="#f4f5f6"
                    />
                    <path
                        id="Path_219"
                        d="M136.45,177.11l-2.18-1s-1.93,4.56-4.42,4.73c3.76,2.46,4,4.63,4,4.63C140.17,184.85,136.45,177.11,136.45,177.11Z"
                        fill="#f4f5f6"
                    />
                    <path
                        id="Path_220"
                        d="M133,168.58l-4.62-5.8A10,10,0,0,0,128,165c-.15,2.3,2.24,5.2,4.32,6.13Z"
                        opacity="0.2"
                    />
                    <path
                        id="Path_221"
                        d="M138.06,158s.24,3.61,4,2.23c.16-3.84,1.42-3.54,2.72-5.79a8.33,8.33,0,0,0,.5-8.52l-2.39-1.2C133.57,141.81,138.06,158,138.06,158Z"
                        fill="#1a2e35"
                    />
                    <path
                        id="Path_222"
                        d="M138.06,158s.24,3.61,4,2.23c.16-3.84,1.42-3.54,2.72-5.79a8.33,8.33,0,0,0,.5-8.52l-2.39-1.2C133.57,141.81,138.06,158,138.06,158Z"
                        fill="#fff"
                        opacity="0.6"
                    />
                    <path
                        id="Path_223"
                        d="M128.31,143.43a9.46,9.46,0,0,1,13.35-.77c5.81,4.94,3.77,15.44.5,21.12a9.34,9.34,0,0,1-16.53-.33c-2.4-4.67-1.12-8.67.49-15.67A9.42,9.42,0,0,1,128.31,143.43Z"
                        fill="#e3e2e3"
                    />
                    <path
                        id="Path_224"
                        d="M125,154.15s.93,3.11,4.34,1.44c-.59-3.36.69-3.27,1.53-5.39a6.82,6.82,0,0,0-1.16-7.54l-2.57-.75C114.91,140.74,125,154.15,125,154.15Z"
                        fill="#1a2e35"
                    />
                    <path
                        id="Path_225"
                        d="M125,154.15s.93,3.11,4.34,1.44c-.59-3.36.69-3.27,1.53-5.39a6.82,6.82,0,0,0-1.16-7.54l-2.57-.75C114.91,140.74,125,154.15,125,154.15Z"
                        fill="#707171"
                    />
                    <path
                        id="Path_226"
                        d="M137.11,134.93a3.83,3.83,0,0,0-3.76.67c-.67.59-1.32,1.48-2.19,1.3a3.32,3.32,0,0,1-1.14-.7,4.16,4.16,0,0,0-4.8.48,14.289,14.289,0,0,0-3.12,4.09,9.42,9.42,0,0,1-3.47,3.74,10.92,10.92,0,0,0,8.92.22,5.81,5.81,0,0,1-3.12,2,12.12,12.12,0,0,0,8.5-.69c1.49-.71,2.87-1.74,4.5-2s3.63.6,3.78,2.25a1.59,1.59,0,0,1,2.22-.77,4.41,4.41,0,0,1,1.74,2,5.11,5.11,0,0,0,1.65,2.07,1.68,1.68,0,0,0,2.34-.44,2.49,2.49,0,0,1-2.3-2.3,4.29,4.29,0,0,0,2.89.47c-1.3-.09-3.85-4.73-4.24-6a6,6,0,0,0-2.08-3.25c-1.21-.83-2.44.62-3.59,0S138.41,135.43,137.11,134.93Z"
                        fill="#707171"
                    />
                    <path
                        id="Path_227"
                        d="M135.49,160.19a4.6,4.6,0,0,1-1.64-3.11l2,.8c.85.33,3.29,1.36,2.75,2.71S136.14,160.73,135.49,160.19Z"
                        fill="#707171"
                    />
                    <path
                        id="Path_228"
                        d="M141.23,162.39a4.62,4.62,0,0,0,3.22-1.41l-2.09-.64c-.87-.27-3.43-1-3.84.4S140.38,162.41,141.23,162.39Z"
                        fill="#707171"
                    />
                    <path
                        id="Path_229"
                        d="M138.46,160.08a3.32,3.32,0,0,0,2.76.37,21.16,21.16,0,0,1-1.24-5.66Z"
                        fill="#707171"
                    />
                    <path
                        id="Path_230"
                        d="M125.38,149.3c1.7.58,2.36,3.42,2.29,5.21-.09,2-1.4,3.22-2.88,2a6.75,6.75,0,0,1-2.06-4C122.47,150.5,123.68,148.73,125.38,149.3Z"
                        fill="#e3e2e3"
                    />
                    <path
                        id="Path_231"
                        d="M136.67,152.47c.37.12.51.73.36,1.23s-.58.86-.94.75-.48-.66-.36-1.23S136.31,152.34,136.67,152.47Z"
                        fill="#707171"
                    />
                    <path
                        id="Path_232"
                        d="M143,154.2c.36.13.52.67.36,1.23s-.59.92-1,.8-.51-.68-.35-1.24S142.63,154.08,143,154.2Z"
                        fill="#707171"
                    />
                    <path
                        id="Path_233"
                        d="M137.83,163.21a.17.17,0,0,0,.2-.14.19.19,0,0,0-.14-.2,4.77,4.77,0,0,1-3.51-2.36.172.172,0,1,0-.31.15,5,5,0,0,0,3.1,2.41C137.38,163.13,137.61,163.15,137.83,163.21Z"
                        fill="#707171"
                    />
                    <path
                        id="Path_234"
                        d="M142.77,151.8a.32.32,0,0,0,.21.24,2.88,2.88,0,0,1,1.84,1.73.34.34,0,0,0,.41.24h0a.35.35,0,0,0,.23-.42h0a3.54,3.54,0,0,0-2.28-2.18.34.34,0,0,0-.43.21A.31.31,0,0,0,142.77,151.8Z"
                        fill="#707171"
                    />
                    <path
                        id="Path_235"
                        d="M135.48,150a.37.37,0,0,0,.32.07,2.9,2.9,0,0,1,2.52.22.32.32,0,0,0,.46-.06h0a.35.35,0,0,0-.07-.48h0a3.56,3.56,0,0,0-3.14-.32.35.35,0,0,0-.21.44A.49.49,0,0,0,135.48,150Z"
                        fill="#707171"
                    />
                    <path
                        id="Path_236"
                        d="M81.42,237l-1.29-.14c-3.06,1.47.26,7.13.26,7.13l9-1.1-2.61-3.06A8.34,8.34,0,0,0,81.42,237Z"
                        fill="#e3e2e3"
                    />
                    <path
                        id="Path_237"
                        d="M89.66,246.8l-.29-3.89-9,1.09.51,4.62a1.42,1.42,0,0,0,0,.2,1.36,1.36,0,0,0,1.66,1l6.07-1.59a1.37,1.37,0,0,0,1.05-1.43Z"
                        fill="#e3e2e3"
                    />
                    <path
                        id="Path_238"
                        d="M133.86,220.72s-3.13,7.53-4.51,6.49-4.56-6.8-4.57-6.92,3.91-34.46,3.91-34.46l1.87.16C129.79,189.84,133.86,220.72,133.86,220.72Z"
                        fill="#707171"
                    />
                </g>
                <path
                    id="Path_239"
                    d="M82.31,202.1c-3.73,5.35-4.63,30.45-3.65,36.9a4.89,4.89,0,0,1,6.4-.8c.09-4.42,3.68-20.21,5.22-26.8a7.87,7.87,0,0,1,3.07-4.61c6.6-4.78,18.55-14.24,19.86-18,2.14-6,.3-18.31-5-14.52C103.22,177.88,85.29,197.84,82.31,202.1Z"
                    fill="#707171"
                />
                <ellipse
                    id="_Path_"
                    data-name="&lt;Path&gt;"
                    cx="152.7"
                    cy="14.99"
                    rx="152.7"
                    ry="14.99"
                    transform="translate(105.49 475.89)"
                    fill="#f4f5f6"
                />
                <circle
                    id="Ellipse_2"
                    data-name="Ellipse 2"
                    cx="9.07"
                    cy="9.07"
                    r="9.07"
                    transform="translate(417.05 29)"
                    fill="#fff"
                    stroke="#e2e2e2"
                    strokeMiterlimit="10"
                    strokeWidth="2"
                />
                <circle
                    id="Ellipse_3"
                    cx="8.13"
                    cy="8.13"
                    r="8.13"
                    transform="translate(32.29 366.83)"
                    fill="#fff"
                    stroke="#e2e2e2"
                    strokeMiterlimit="10"
                    strokeWidth="2"
                />
                <path
                    id="Path_240"
                    d="M471.61,77.52h-9V68.58a1.12,1.12,0,0,0-1.11-1.12h0a1.14,1.14,0,0,0-1.14,1.12v8.94h-8.94a1.14,1.14,0,0,0-1.12,1.14,1.12,1.12,0,0,0,1.12,1.11h8.94v9a1.14,1.14,0,0,0,1.14,1.12,1.12,1.12,0,0,0,1.11-1.12h0v-9h9a1.11,1.11,0,0,0,1.12-1.11,1.14,1.14,0,0,0-1.12-1.14Z"
                    fill="#e2e2e2"
                    stroke="#e2e2e2"
                    strokeMiterlimit="10"
                    strokeWidth="0.75"
                />
                <path
                    id="Path_241"
                    d="M113.43,431.59h-7.72v-7.71a1,1,0,0,0-1-1h0a1,1,0,0,0-1,1v7.71h-7.7a1,1,0,0,0,0,2h7.7v7.73a1,1,0,0,0,1.95,0h0v-7.73h7.72a1,1,0,0,0,.05-2Z"
                    fill="#e2e2e2"
                    stroke="#e2e2e2"
                    strokeMiterlimit="10"
                    strokeWidth="0.75"
                />
            </g>
        </SVGIcon>
    );
}
