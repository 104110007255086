import React from 'react';

import { SVGIcon } from '../../UIKit/index';

export default function DCNoLead(props) {
    return (
        <SVGIcon {...props} viewBox="0 0 258 198.619">
            <g transform="translate(-1558.999 -676.512)">
                <g transform="translate(316 297)">
                    <g transform="translate(1 -47)">
                        <g transform="translate(13639 2351)" fill="#f5f5f5">
                            <path
                                d="M -12240.0009765625 -1857.51904296875 L -12245.3740234375 -1863.660034179688 L -12245.6728515625 -1864.00146484375 L -12246.126953125 -1864.00146484375 L -12320.9990234375 -1864.00146484375 C -12323.7568359375 -1864.00146484375 -12326.0009765625 -1866.243896484375 -12326.0009765625 -1869.000122070313 L -12326.0009765625 -1905.001098632813 C -12326.0009765625 -1907.75732421875 -12323.7568359375 -1909.999755859375 -12320.9990234375 -1909.999755859375 L -12231.0009765625 -1909.999755859375 C -12228.2431640625 -1909.999755859375 -12225.9990234375 -1907.75732421875 -12225.9990234375 -1905.001098632813 L -12225.9990234375 -1869.000122070313 C -12225.9990234375 -1866.243896484375 -12228.2431640625 -1864.00146484375 -12231.0009765625 -1864.00146484375 L -12233.8740234375 -1864.00146484375 L -12234.328125 -1864.00146484375 L -12234.626953125 -1863.660034179688 L -12240.0009765625 -1857.51904296875 Z"
                                stroke="none"
                            />
                            <path
                                d="M -12240.0009765625 -1859.037475585938 L -12235.37890625 -1864.318603515625 L -12234.78125 -1865.00146484375 L -12233.8740234375 -1865.00146484375 L -12231.0009765625 -1865.00146484375 C -12228.7939453125 -1865.00146484375 -12226.9990234375 -1866.795288085938 -12226.9990234375 -1869.000122070313 L -12226.9990234375 -1905.001098632813 C -12226.9990234375 -1907.205932617188 -12228.7939453125 -1908.999755859375 -12231.0009765625 -1908.999755859375 L -12320.9990234375 -1908.999755859375 C -12323.2060546875 -1908.999755859375 -12325.0009765625 -1907.205932617188 -12325.0009765625 -1905.001098632813 L -12325.0009765625 -1869.000122070313 C -12325.0009765625 -1866.795288085938 -12323.2060546875 -1865.00146484375 -12320.9990234375 -1865.00146484375 L -12246.126953125 -1865.00146484375 L -12245.2197265625 -1865.00146484375 L -12244.6220703125 -1864.318481445313 L -12240.0009765625 -1859.037475585938 M -12240.0009765625 -1856.00048828125 L -12246.126953125 -1863.00146484375 L -12320.9990234375 -1863.00146484375 C -12324.3125 -1863.00146484375 -12327.0009765625 -1865.686767578125 -12327.0009765625 -1869.000122070313 L -12327.0009765625 -1905.001098632813 C -12327.0009765625 -1908.314453125 -12324.3125 -1910.999755859375 -12320.9990234375 -1910.999755859375 L -12231.0009765625 -1910.999755859375 C -12227.6875 -1910.999755859375 -12224.9990234375 -1908.314453125 -12224.9990234375 -1905.001098632813 L -12224.9990234375 -1869.000122070313 C -12224.9990234375 -1865.686767578125 -12227.6875 -1863.00146484375 -12231.0009765625 -1863.00146484375 L -12233.8740234375 -1863.00146484375 L -12240.0009765625 -1856.00048828125 Z"
                                stroke="none"
                                fill="#707070"
                            />
                        </g>
                    </g>
                    <g transform="translate(0 2)">
                        <rect
                            width="79"
                            height="5"
                            rx="2.5"
                            transform="translate(1321 401)"
                            fill="#d4d4d4"
                        />
                        <rect
                            width="61"
                            height="4"
                            rx="2"
                            transform="translate(1321 411)"
                            fill="#d4d4d4"
                        />
                        <g>
                            <rect
                                width="32"
                                height="10"
                                rx="3"
                                transform="translate(1375 421)"
                                fill="#d4d4d4"
                            />
                            <rect
                                width="30"
                                height="10"
                                rx="3"
                                transform="translate(1375 421)"
                                fill="#d4d4d4"
                            />
                        </g>
                    </g>
                </g>
                <ellipse
                    cx="117.33"
                    cy="27.966"
                    rx="117.33"
                    ry="27.966"
                    transform="translate(1561.983 819.199)"
                    fill="#f5f5f5"
                />
                <g transform="translate(245.857 397.201)">
                    <g transform="translate(1313.142 392.798)">
                        <g transform="translate(13558.999 2471)" fill="#fff">
                            <path
                                d="M -13540.001953125 -2396.50634765625 L -13546.365234375 -2403.66552734375 L -13546.6630859375 -2404.001220703125 L -13547.1123046875 -2404.001220703125 L -13553.0009765625 -2404.001220703125 C -13555.7578125 -2404.001220703125 -13558 -2406.243896484375 -13558 -2409.00048828125 L -13558 -2465.00146484375 C -13558 -2467.758056640625 -13555.7578125 -2470.000732421875 -13553.0009765625 -2470.000732421875 L -13445.0009765625 -2470.000732421875 C -13442.244140625 -2470.000732421875 -13440.0009765625 -2467.758056640625 -13440.0009765625 -2465.00146484375 L -13440.0009765625 -2409.00048828125 C -13440.0009765625 -2406.243896484375 -13442.244140625 -2404.001220703125 -13445.0009765625 -2404.001220703125 L -13532.8876953125 -2404.001220703125 L -13533.3369140625 -2404.001220703125 L -13533.634765625 -2403.665771484375 L -13540.001953125 -2396.50634765625 Z"
                                stroke="none"
                            />
                            <path
                                d="M -13540.001953125 -2398.01123046875 L -13534.3818359375 -2404.330322265625 L -13533.78515625 -2405.001220703125 L -13532.8876953125 -2405.001220703125 L -13445.0009765625 -2405.001220703125 C -13442.794921875 -2405.001220703125 -13441.0009765625 -2406.79541015625 -13441.0009765625 -2409.00048828125 L -13441.0009765625 -2465.00146484375 C -13441.0009765625 -2467.20654296875 -13442.794921875 -2469.000732421875 -13445.0009765625 -2469.000732421875 L -13553.0009765625 -2469.000732421875 C -13555.2060546875 -2469.000732421875 -13557 -2467.20654296875 -13557 -2465.00146484375 L -13557 -2409.00048828125 C -13557 -2406.79541015625 -13555.2060546875 -2405.001220703125 -13553.0009765625 -2405.001220703125 L -13547.1123046875 -2405.001220703125 L -13546.2138671875 -2405.001220703125 L -13545.6171875 -2404.329833984375 L -13540.001953125 -2398.01123046875 M -13540.001953125 -2395.001220703125 L -13547.1123046875 -2403.001220703125 L -13553.0009765625 -2403.001220703125 C -13556.3154296875 -2403.001220703125 -13559 -2405.68603515625 -13559 -2409.00048828125 L -13559 -2465.00146484375 C -13559 -2468.312255859375 -13556.3154296875 -2471.000732421875 -13553.0009765625 -2471.000732421875 L -13445.0009765625 -2471.000732421875 C -13441.6865234375 -2471.000732421875 -13439.0009765625 -2468.312255859375 -13439.0009765625 -2465.00146484375 L -13439.0009765625 -2409.00048828125 C -13439.0009765625 -2405.68603515625 -13441.6865234375 -2403.001220703125 -13445.0009765625 -2403.001220703125 L -13532.8876953125 -2403.001220703125 L -13540.001953125 -2395.001220703125 Z"
                                stroke="none"
                                fill="#707070"
                            />
                        </g>
                    </g>
                    <g transform="translate(1323.142 402.799)">
                        <rect
                            width="92"
                            height="8"
                            rx="3"
                            transform="translate(0.001 0)"
                            fill="#d4d4d4"
                        />
                        <rect
                            width="71"
                            height="4"
                            rx="2"
                            transform="translate(0.001 14)"
                            fill="#d4d4d4"
                        />
                        <g transform="translate(62 25.5)">
                            <rect
                                width="38"
                                height="13"
                                rx="3"
                                transform="translate(0.001 0.5)"
                                fill="#d4d4d4"
                            />
                            <rect
                                width="36"
                                height="13"
                                rx="3"
                                transform="translate(0.001 0.5)"
                                fill="#d4d4d4"
                            />
                        </g>
                    </g>
                </g>
                <g transform="translate(388.857 369.201)">
                    <g transform="translate(1313.143 392.799)">
                        <g transform="translate(13712 2433)" fill="#fff">
                            <path
                                d="M -13654.001953125 -2352.578125 L -13660.5908203125 -2360.63427734375 L -13660.8916015625 -2361.001220703125 L -13661.365234375 -2361.001220703125 L -13705.9990234375 -2361.001220703125 C -13708.7568359375 -2361.001220703125 -13711 -2363.243408203125 -13711 -2365.999267578125 L -13711 -2426.9990234375 C -13711 -2429.757080078125 -13708.7568359375 -2432.000732421875 -13705.9990234375 -2432.000732421875 L -13602.9990234375 -2432.000732421875 C -13600.2431640625 -2432.000732421875 -13598.0009765625 -2429.757080078125 -13598.0009765625 -2426.9990234375 L -13598.0009765625 -2365.999267578125 C -13598.0009765625 -2363.243408203125 -13600.2431640625 -2361.001220703125 -13602.9990234375 -2361.001220703125 L -13646.634765625 -2361.001220703125 L -13647.1083984375 -2361.001220703125 L -13647.408203125 -2360.634521484375 L -13654.001953125 -2352.578125 Z"
                                stroke="none"
                            />
                            <path
                                d="M -13654.001953125 -2354.157470703125 L -13648.1826171875 -2361.267822265625 L -13647.58203125 -2362.001220703125 L -13646.634765625 -2362.001220703125 L -13602.9990234375 -2362.001220703125 C -13600.794921875 -2362.001220703125 -13599.0009765625 -2363.794677734375 -13599.0009765625 -2365.999267578125 L -13599.0009765625 -2426.9990234375 C -13599.0009765625 -2429.20556640625 -13600.794921875 -2431.000732421875 -13602.9990234375 -2431.000732421875 L -13705.9990234375 -2431.000732421875 C -13708.205078125 -2431.000732421875 -13710 -2429.20556640625 -13710 -2426.9990234375 L -13710 -2365.999267578125 C -13710 -2363.794677734375 -13708.205078125 -2362.001220703125 -13705.9990234375 -2362.001220703125 L -13661.365234375 -2362.001220703125 L -13660.4169921875 -2362.001220703125 L -13659.8173828125 -2361.267578125 L -13654.001953125 -2354.157470703125 M -13654.001953125 -2350.9990234375 L -13661.365234375 -2360.001220703125 L -13705.9990234375 -2360.001220703125 C -13709.3125 -2360.001220703125 -13712 -2362.685546875 -13712 -2365.999267578125 L -13712 -2426.9990234375 C -13712 -2430.31298828125 -13709.3125 -2433.000732421875 -13705.9990234375 -2433.000732421875 L -13602.9990234375 -2433.000732421875 C -13599.6884765625 -2433.000732421875 -13597.0009765625 -2430.31298828125 -13597.0009765625 -2426.9990234375 L -13597.0009765625 -2365.999267578125 C -13597.0009765625 -2362.685546875 -13599.6884765625 -2360.001220703125 -13602.9990234375 -2360.001220703125 L -13646.634765625 -2360.001220703125 L -13654.001953125 -2350.9990234375 Z"
                                stroke="none"
                                fill="#707070"
                            />
                        </g>
                    </g>
                    <g transform="translate(1323.142 402.799)">
                        <rect
                            width="87"
                            height="7"
                            rx="3"
                            transform="translate(0 0)"
                            fill="#d4d4d4"
                        />
                        <rect
                            width="71"
                            height="4"
                            rx="2"
                            transform="translate(0 12)"
                            fill="#d4d4d4"
                        />
                        <g transform="translate(57 26.5)">
                            <rect
                                width="38"
                                height="12"
                                rx="3"
                                transform="translate(0 0.5)"
                                fill="#d4d4d4"
                            />
                            <rect
                                width="36"
                                height="12"
                                rx="3"
                                transform="translate(0 0.5)"
                                fill="#d4d4d4"
                            />
                        </g>
                    </g>
                </g>
                <g transform="translate(1771.99 698.461) rotate(180)" opacity="0.5">
                    <rect
                        width="3.634"
                        height="21.949"
                        rx="1.817"
                        transform="translate(21.949 9.158) rotate(90)"
                        fill="#c3c5c4"
                    />
                    <rect
                        width="3.634"
                        height="21.949"
                        rx="1.817"
                        transform="translate(12.791 21.949) rotate(180)"
                        fill="#c3c5c4"
                    />
                </g>
                <g transform="translate(1575.042 736.554) rotate(180)" opacity="0.5">
                    <rect
                        width="2.162"
                        height="13.059"
                        rx="1.081"
                        transform="translate(13.059 5.448) rotate(90)"
                        fill="#c3c5c4"
                    />
                    <rect
                        width="2.162"
                        height="13.059"
                        rx="1.081"
                        transform="translate(7.61 13.059) rotate(180)"
                        fill="#c3c5c4"
                    />
                </g>
                <g
                    transform="translate(1781.959 733.488) rotate(180)"
                    fill="#fff"
                    stroke="#c3c5c4"
                    strokeWidth="1.6"
                    opacity="0.5"
                >
                    <circle cx="9.969" cy="9.969" r="9.969" stroke="none" />
                    <circle cx="9.969" cy="9.969" r="9.169" fill="none" />
                </g>
            </g>
        </SVGIcon>
    );
}
