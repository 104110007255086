import React from 'react';

import { SVGIcon } from '../../UIKit/index';

export default function DCCopy(props) {
    return (
        <SVGIcon {...props} viewBox="0 0 512 512">
            <path d="M460.86,66.13h-47.35V50.35C413.51,24.2,392.31,3,366.16,3H50.53     C24.38,3,3.18,24.2,3.18,50.35v315.63c0,26.15,21.2,47.35,47.35,47.35l0,0h15.78v47.35c0.01,26.14,21.21,47.33,47.35,47.33     h347.21c26.15,0,47.35-21.2,47.35-47.35l0,0V113.48C508.21,87.33,487.01,66.13,460.86,66.13L460.86,66.13z M66.31,113.48v268.28     H50.53c-8.71,0-15.78-7.06-15.78-15.78V50.35c0-8.71,7.06-15.78,15.78-15.78l0,0h315.63c8.71,0,15.78,7.06,15.78,15.78v15.78     H113.66C87.51,66.13,66.31,87.33,66.31,113.48z M476.64,460.68c0,8.71-7.06,15.78-15.78,15.78H113.66     c-8.71,0-15.78-7.06-15.78-15.78V113.48c0.02-8.7,7.08-15.73,15.78-15.73h347.21c8.71,0,15.78,7.06,15.78,15.78L476.64,460.68z" />
        </SVGIcon>
    );
}
