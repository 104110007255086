import React from 'react';

import { SVGIcon } from '../../UIKit/index';

export default function DCNotification(props) {
    return (
        <SVGIcon {...props} viewBox="0 0 483 512">
            <path
                d="M408.2,262.1v-47.7c-1.8-70.2-50.1-130.7-118.1-148V44.7C288,18,264.7-1.9,238,0.1
	c-23.8,1.9-42.7,20.8-44.6,44.6v21.6C125.3,83.7,77,144.2,75.2,214.4v47.7C75,324,49.4,383,4.5,425.5c-4.4,4.2-5.7,10.7-3.2,16.2
	c2.7,5.6,8.5,9.2,14.8,9.1h146.6c12.3,43.6,57.6,69,101.2,56.7c27.5-7.8,48.9-29.2,56.7-56.7h146.7c6.3,0.1,12-3.5,14.8-9.1
	c2.5-5.5,1.2-12.1-3.2-16.2C434,383,408.5,324,408.2,262.1z M225.6,44.7c0.7-8.9,8.5-15.5,17.4-14.8c7.9,0.6,14.1,6.9,14.8,14.8
	v15.7c-5.3-0.5-10.7-0.7-16.1-0.7s-10.8,0.2-16.1,0.7V44.7z M241.7,480.8c-19.9,0.3-37.9-11.5-45.5-29.9h91.1
	C279.7,469.3,261.6,481.2,241.7,480.8z M50.8,420.9C87.3,376,107.3,320,107.4,262.1v-47.7c2.8-71.4,62.8-127.2,134.3-124.7
	c71.5-2.5,131.5,53.3,134.3,124.7v47.7c0.1,57.8,20.1,113.9,56.6,158.8H50.8z"
            />
        </SVGIcon>
    );
}
