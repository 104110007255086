import React, { Fragment, useCallback, useEffect, useMemo, useState } from 'react';
import { Div, IconButton, Checkbox, UserInfo, Skeleton, NoData } from 'UIKit/index';
import DCRightArrow2 from 'Assets/icons/DCRightArrow2';
import DCTrash from 'Assets/icons/DCTrash';
import DCCheckCircle from 'Assets/icons/DCCheckCircle';
import DCInfoCircle from 'Assets/icons/DCInfoCircle';
import './style.scss';
import DCSettingMenu from 'Assets/icons/DCSettingMenu';
import { getAccountDetails, getSessionId, getUserDetails } from 'Utils/localStorageUtils';
import DCContacts from 'Assets/icons/DCContacts';
import DCDealMenu from 'Assets/icons/DCDealMenu';
import DCForms from 'Assets/icons/DCForms';
import DCInbound from 'Assets/icons/DCInbound';
import DCAnalytics from 'Assets/icons/DCAnalytics';
import DCTemplates from 'Assets/icons/DCTemplates';
import { useInfiniteQuery, useQuery } from 'react-query';
import { getCampaigns, getUsers } from 'apis/inbound/index.api';
import useToast from 'Modules/Toasts';
import generateImage from 'Utils/generateImage';
import useInfiniteScroll from 'Modules/InfiniteScroll/useInfiniteScroll';
import { getPipelines } from 'apis/deals/index.api';
import DCBars from 'Assets/icons/DCBars';
import { getAllForms } from 'apis/Forms/index.api';
import DCForm from 'Assets/icons/DCForm';
import { loadData } from 'apis/templates/index.api';
import DCTemplates1 from 'Assets/icons/DCTemplates1';
import DCCampaigns1 from 'Assets/icons/DCCampaigns1';
import { getConfig } from 'apis/analytics/index.api';
import DCTrackedSeoKeywords from 'Assets/icons/DCTrackedSeoKeywords';
import { updateSubscription } from 'apis/store/index.api';
import { formateDate } from 'Utils/dateUtils';
import dayjs from 'dayjs';
import DCUndo from 'Assets/icons/DCUndo';
import { TYPES } from 'Pages/Templates/constants';

interface CancelProps {
    type: { service: boolean; serviceName: string; plan: any };
    setCanContinue: any;
    stepsData: { current: any };
    setStepsData: any;
    onContinue: { current: any };
    doContinue: () => void;
    setContinueLoading: any;
    dataDomain: any;
    downGradeMessage: string;
    downGradePromt?: boolean;
    setCurrentTier?: any;
}
const SCStep22: React.FC<CancelProps> = CancelProps => {
    const {
        dataDomain,
        type,
        setCanContinue,
        stepsData,
        onContinue,
        doContinue,
        setContinueLoading,
        downGradeMessage,
        downGradePromt,
        setCurrentTier,
    } = CancelProps;

    const { showToast } = useToast();
    const usageData = JSON.parse(localStorage.getItem('usage_data') || '');
    const [userCount, setUserCount] = useState(usageData['USERS']?.used || 0);
    const [pipelineCount, setPipelineCount] = useState(usageData['SALES_PIPELINE']?.used || 0);
    const [formCount, setFormCount] = useState(usageData['FORMS']?.used || 0);
    const [campaignCount, setCampaignCount] = useState(usageData['INBOUND_CAMPAIGNS']?.used || 0);
    const [seoCount, setSeoCount] = useState(usageData['TRACKED_SEO_KEYWORDS']?.used || 0);
    const [templateCount, setTemplateCount] = useState(usageData['TEMPLATES']?.used || 0);
    const [checks, setChecks] = useState<Array<string>>([]);
    const [users, setUsers] = useState<Array<any>>([]);
    const [usersToDelete, setUsersToDelete] = useState<Array<string>>([]);
    const [pipelines, setPipelines] = useState<Array<any>>([]);
    const [pipelinesToDelete, setPipelinesToDelete] = useState<Array<string>>([]);
    const [forms, setForms] = useState<Array<any>>([]);
    const [formsToDelete, setFormsToDelete] = useState<Array<string>>([]);
    const [campaigns, setCampaigns] = useState<Array<any>>([]);
    const [campaignsToDelete, setCampaignsToDelete] = useState<Array<string>>([]);
    const [keywords, setKeywords] = useState<Array<any>>([]);
    const [keywordsToDelete, setKeywordsToDelete] = useState<Array<string>>([]);
    const [templates, setTemplates] = useState<Array<any>>([]);
    const [templatesToDelete, setTemplatesToDelete] = useState<Array<string>>([]);

    const getPlanLimit = (plan: any, parent: string, child: string) => {
        return plan?.details
            ?.find((el: { label: string }) => el.label === parent)
            ?.data.find((el: { label: string }) => el.label === child)?.value;
    };

    const cancleChecks = useMemo(() => {
        return [
            {
                id: 1,
                key: 'users',
                title: 'Team member seats',
                icon: <DCSettingMenu />,
                type: 'delete',
                description:
                    'You currently have too many team users and need to archive some of them to reach your new plans limits.',
                limitDescription: `${
                    userCount <= getPlanLimit(type.plan, 'System', 'Team Member Seats')
                        ? 'You are good to go'
                        : `You need to remove ${
                              userCount - getPlanLimit(type.plan, 'System', 'Team Member Seats') >=
                              0
                                  ? userCount -
                                    getPlanLimit(type.plan, 'System', 'Team Member Seats')
                                  : 0
                          } users, your new plan only allows ${
                              getPlanLimit(type.plan, 'System', 'Team Member Seats') - 1
                          }.`
                }`,
                visible:
                    usageData['USERS']?.used >
                    getPlanLimit(type.plan, 'System', 'Team Member Seats'),
            },
            {
                id: 2,
                key: 'files',
                title: 'File storage',
                icon: <DCSettingMenu />,
                type: 'warning',
                description:
                    'Your account is over the file storage limit which is allowed on your new plan',
                warningDescription: (
                    <div>
                        You currently have of file storage, your new plan only allows $
                        {getPlanLimit(type.plan, 'System', 'File Storage')}. We will{' '}
                        <strong>
                            <u>NOT</u>
                        </strong>{' '}
                        delete or archive any of your files, but in order to add new files moving
                        forward you will need to delete files later on.
                    </div>
                ),
                visible: false,
            },
            {
                id: 3,
                key: 'domain',
                title: 'Custom domain',
                icon: <DCSettingMenu />,
                type: 'warning',
                description: 'Your new plan does not include custom domain setup.',
                warningDescription: `You currently have ${
                    dataDomain?.domain?.custom
                } setup for your dashboard and your new plan does not allow for custom domains. We will automatically switch your custom domain back to your regular dashboard domain which is ${getAccountDetails().name.toLowerCase()}.dashclicks.com.`,
                visible:
                    dataDomain?.domain?.custom &&
                    getPlanLimit(
                        getAccountDetails().plan.additional_info,
                        'Branding',
                        'Custom Domain',
                    ) === true &&
                    getPlanLimit(type.plan, 'Branding', 'Custom Domain') === false,
            },
            {
                id: 4,
                key: 'contacts',
                title: 'Contact records',
                icon: <DCContacts />,
                type: 'warning',
                description: 'You currently have too many contacts in your account.',
                warningDescription: (
                    <div>
                        You currently have {usageData['CONTACT_RECORDS']?.used} contacts in your
                        account and your new plan only allows{' '}
                        {getPlanLimit(type.plan, 'Contacts', 'Contact Records')}. We will{' '}
                        <strong>
                            <u>NOT</u>
                        </strong>{' '}
                        delete or archive any of your contacts, but in order to add new contacts
                        moving forward you will need to delete contacts later on.
                    </div>
                ),
                visible:
                    usageData['CONTACT_RECORDS']?.used >
                    getPlanLimit(type.plan, 'Contacts', 'Contact Records'),
            },
            {
                id: 5,
                key: 'contacts_custom_property_tags',
                title: 'Contacts - custom properties & tags',
                icon: <DCContacts />,
                type: 'warning',
                description:
                    'Your new plan does not include the custom properties or tags feature for Contacts.',
                warningDescription: (
                    <div>
                        You currently have{' '}
                        {usageData['CONTACTS_CUSTOM_PROPERTIES']?.used > 0
                            ? `${usageData['CONTACTS_CUSTOM_PROPERTIES']?.used} custom properties and`
                            : ''}{' '}
                        {usageData['CRM_TAGS']?.used} tags in your account. Your new plan does not
                        allow for the use of custom properties or Tags in Contacts. We will{' '}
                        <strong>
                            <u>NOT</u>
                        </strong>{' '}
                        delete any of them. You can still view contact records that have custom
                        properties and tags, but you can't create or edit existing custom properties
                        or tags. Also, moving forward custom properties and tags can no longer be
                        associated with new Contact records.
                    </div>
                ),
                visible:
                    (usageData['CONTACTS_CUSTOM_PROPERTIES']?.used > 0 ||
                        usageData['CRM_TAGS']?.used > 0) &&
                    getPlanLimit(
                        getAccountDetails().plan.additional_info,
                        'Contacts',
                        'Custom Properties',
                    ) === true &&
                    getPlanLimit(type.plan, 'Contacts', 'Custom Properties') === false,
            },
            {
                id: 6,
                key: 'contacts_smart_lists',
                title: 'Contacts - smart lists',
                icon: <DCContacts />,
                type: 'warning',
                description: 'Your new plan does not include the smart lists feature for Contacts.',
                warningDescription: `You currently have ${usageData['CONTACTS_CUSTOM_FILTERS']?.used} smart lists in your account. Your new plan does not allow for the use of smart lists. Once you downgrade, all your smart lists will be deleted and you will not be able to use this feature moving forward.`,
                visible:
                    usageData['CONTACTS_CUSTOM_FILTERS']?.used > 0 &&
                    getPlanLimit(
                        getAccountDetails().plan.additional_info,
                        'Contacts',
                        'Custom Filters',
                    ) === true &&
                    getPlanLimit(type.plan, 'Contacts', 'Custom Filters') === false,
            },
            {
                id: 7,
                key: 'sales_pipelines',
                title: 'Sales pipelines',
                icon: <DCDealMenu />,
                type: 'delete',
                description:
                    'You currently have too many sales pipelines and need to delete some of them to reach your new plans limits. Keep in mind, once you delete a pipeline it will automatically delete all the deals inside it as well.',
                limitDescription: `${
                    pipelineCount <= getPlanLimit(type.plan, 'Deals', 'Sales Pipelines')
                        ? 'You are good to go'
                        : `You need to remove ${
                              pipelineCount - getPlanLimit(type.plan, 'Deals', 'Sales Pipelines') >=
                              0
                                  ? pipelineCount -
                                    getPlanLimit(type.plan, 'Deals', 'Sales Pipelines')
                                  : 0
                          } pipelines, your new plan only allows ${getPlanLimit(
                              type.plan,
                              'Deals',
                              'Sales Pipelines',
                          )}.`
                }`,
                visible:
                    usageData['SALES_PIPELINE']?.used > 0 &&
                    usageData['SALES_PIPELINE']?.used >
                        getPlanLimit(type.plan, 'Deals', 'Sales Pipelines'),
            },
            {
                id: 8,
                key: 'deals_custom_property_tags',
                title: 'Deals - custom properties & tags',
                icon: <DCDealMenu />,
                type: 'warning',
                description:
                    'Your new plan does not include the custom properties or tags feature for Deals.',
                warningDescription: (
                    <div>
                        You currently have{' '}
                        {usageData['DEALS_CUSTOM_PROPERTIES']?.used > 0
                            ? `${usageData['DEALS_CUSTOM_PROPERTIES']?.used} custom
                        properties and `
                            : ''}{' '}
                        {usageData['CRM_TAGS']?.used} tags in your account. Your new plan does not
                        allow for the use of custom properties or Tags in Deals. We will{' '}
                        <strong>
                            <u>NOT</u>
                        </strong>{' '}
                        delete any of them. You can still view deal records that have custom
                        properties and tags, but you can't create or edit existing custom properties
                        or tags. Also, moving forward custom properties and tags can no longer be
                        associated with new deal records.
                    </div>
                ),
                visible:
                    (usageData['DEALS_CUSTOM_PROPERTIES']?.used > 0 ||
                        usageData['CRM_TAGS']?.used > 0) &&
                    getPlanLimit(
                        getAccountDetails().plan.additional_info,
                        'Deals',
                        'Custom Properties',
                    ) === true &&
                    getPlanLimit(type.plan, 'Deals', 'Custom Properties') === false,
            },
            {
                id: 9,
                key: 'deals_smart_lists',
                title: 'Deals - smart lists',
                icon: <DCDealMenu />,
                type: 'warning',
                description: 'Your new plan does not include the smart lists feature for Deals.',
                warningDescription: `You currently have ${usageData['DEALS_CUSTOM_FILTERS']?.used} smart lists in your account. Your new plan does not allow for the use of smart lists. Once you downgrade, all your smart lists will be deleted and you will not be able to use this feature moving forward.`,
                visible:
                    usageData['DEALS_CUSTOM_FILTERS']?.used > 0 &&
                    getPlanLimit(
                        getAccountDetails().plan.additional_info,
                        'Deals',
                        'Custom Filters',
                    ) === true &&
                    getPlanLimit(type.plan, 'Deals', 'Custom Filters') === false,
            },
            {
                id: 10,
                key: 'deal_automation',
                title: 'Deal automations & log',
                icon: <DCDealMenu />,
                type: 'warning',
                description: 'Your new plan does not include the deal automations or log feature.',
                warningDescription: `You currently have ${usageData['DEALS_AUTOMATIONS']?.used} deal automations activated in your account. Your new plan does not allow for the use of deal automations. Once you downgrade, all your deal automations will be automatically turned off and you will not be able to use this feature moving forward.`,
                visible:
                    usageData['DEALS_AUTOMATIONS']?.used > 0 &&
                    getPlanLimit(
                        getAccountDetails().plan.additional_info,
                        'Deals',
                        'Deal Automations & Logs',
                    ) === true &&
                    getPlanLimit(type.plan, 'Deals', 'Deal Automations & Logs') === false,
            },
            {
                id: 11,
                key: 'forms',
                title: 'Forms',
                icon: <DCForms />,
                type: 'delete',
                description: `You currently have too many forms and need to delete some of them to reach your new plan's limits. Keep in mind, once you delete a form it will automatically delete all the entries inside it as well.`,
                limitDescription: `${
                    formCount <= getPlanLimit(type.plan, 'Forms', 'Forms')
                        ? 'You are good to go'
                        : `You need to remove ${
                              formCount - getPlanLimit(type.plan, 'Forms', 'Forms') >= 0
                                  ? formCount - getPlanLimit(type.plan, 'Forms', 'Forms')
                                  : 0
                          } forms, your new plan only allows ${getPlanLimit(
                              type.plan,
                              'Forms',
                              'Forms',
                          )}.`
                } `,
                visible:
                    usageData['FORMS']?.used > 0 &&
                    usageData['FORMS']?.used > getPlanLimit(type.plan, 'Forms', 'Forms'),
            },
            {
                id: 12,
                key: 'group_forms',
                title: 'Group forms',
                icon: <DCForms />,
                type: 'warning',
                description: 'Your new plan does not include the group forms feature.',
                warningDescription: `You can still view existing group forms, but you can't create new ones or edit existing ones.`,
                visible:
                    usageData['FORMS']?.used > 0 &&
                    getPlanLimit(
                        getAccountDetails().plan.additional_info,
                        'Forms',
                        'Group Form',
                    ) === true &&
                    getPlanLimit(type.plan, 'Forms', 'Group Form') === false,
            },
            {
                id: 13,
                key: 'card_forms',
                title: 'Card forms',
                icon: <DCForms />,
                type: 'warning',
                description: 'Your new plan does not include the card forms feature.',
                warningDescription: `You can still view existing card forms, but you can't create new ones or edit existing ones.`,
                visible:
                    usageData['FORMS']?.used > 0 &&
                    getPlanLimit(getAccountDetails().plan.additional_info, 'Forms', 'Card Form') ===
                        true &&
                    getPlanLimit(type.plan, 'Forms', 'Card Form') === false,
            },
            {
                id: 14,
                key: 'campaigns',
                title: 'Campaigns',
                icon: <DCInbound />,
                type: 'delete',
                description: `You currently have too many campaigns and need to delete some of them to reach your new plan's limits. Keep in mind, once you delete a campaign it will automatically delete all the leads inside it as well.`,
                limitDescription: `${
                    campaignCount <= getPlanLimit(type.plan, 'Inbound', 'Campaigns')
                        ? 'You are good to go'
                        : `You need to remove ${
                              campaignCount - getPlanLimit(type.plan, 'Inbound', 'Campaigns') >= 0
                                  ? campaignCount - getPlanLimit(type.plan, 'Inbound', 'Campaigns')
                                  : 0
                          } campaigns, your new plan only allows ${getPlanLimit(
                              type.plan,
                              'Inbound',
                              'Campaigns',
                          )}.`
                }`,
                visible:
                    usageData['INBOUND_CAMPAIGNS']?.used > 0 &&
                    usageData['INBOUND_CAMPAIGNS']?.used >
                        getPlanLimit(type.plan, 'Inbound', 'Campaigns'),
            },
            {
                id: 15,
                key: 'round_robin',
                title: 'Round robin',
                icon: <DCInbound />,
                type: 'warning',
                description:
                    'Your new plan does not include the round robin feature in Inbound campaigns.',
                warningDescription: `We will automatically remove all round robin users on campaigns and default all campaigns to the owner user.`,
                visible:
                    getPlanLimit(
                        getAccountDetails().plan.additional_info,
                        'Inbound',
                        'Round Robin Leads',
                    ) === true && getPlanLimit(type.plan, 'Inbound', 'Round Robin Leads') === false,
            },
            {
                id: 16,
                key: 'seo_keywords',
                title: 'Tracked SEO keywords',
                icon: <DCAnalytics />,
                type: 'delete',
                description: `You currently have too many tracked SEO keywords and need to delete some of them to reach your new plan's limits. Keep in mind, once you delete a keyword it will no longer gather future data and you will lose all historical data as well for that specific keyword.`,
                limitDescription: `${
                    seoCount <= getPlanLimit(type.plan, 'Analytics', 'Tracked SEO Keywords')
                        ? 'You are good to go'
                        : `You need to remove ${
                              seoCount -
                                  getPlanLimit(type.plan, 'Analytics', 'Tracked SEO Keywords') >=
                              0
                                  ? seoCount -
                                    getPlanLimit(type.plan, 'Analytics', 'Tracked SEO Keywords')
                                  : 0
                          } keywords, your new plan only allows ${getPlanLimit(
                              type.plan,
                              'Analytics',
                              'Tracked SEO Keywords',
                          )}.`
                }`,
                visible:
                    usageData['TRACKED_SEO_KEYWORDS']?.used > 0 &&
                    usageData['TRACKED_SEO_KEYWORDS']?.used >
                        getPlanLimit(type.plan, 'Analytics', 'Tracked SEO Keywords'),
            },
            {
                id: 17,
                key: 'templates',
                title: 'Templates',
                icon: <DCTemplates />,
                type: 'delete',
                description: `You currently have too many templates and need to delete some of them to reach your new plan's limits. Keep in mind, once you delete a template it will also remove any association of where that template is being used.`,
                limitDescription: `${
                    templateCount <= getPlanLimit(type.plan, 'Templates', 'Custom Templates')
                        ? 'You are good to go'
                        : `You need to remove ${
                              templateCount -
                                  getPlanLimit(type.plan, 'Templates', 'Custom Templates') >=
                              0
                                  ? templateCount -
                                    getPlanLimit(type.plan, 'Templates', 'Custom Templates')
                                  : 0
                          } templates, your new plan only allows ${getPlanLimit(
                              type.plan,
                              'Templates',
                              'Custom Templates',
                          )}.`
                }`,
                visible:
                    usageData['TEMPLATES']?.used > 0 &&
                    usageData['TEMPLATES']?.used >
                        getPlanLimit(type.plan, 'Templates', 'Custom Templates'),
            },
        ];
    }, [
        userCount,
        type,
        usageData,
        dataDomain,
        pipelineCount,
        formCount,
        campaignCount,
        seoCount,
        templateCount,
    ]);

    const handleCheck = (_key: string) => {
        if (checks.includes(_key)) {
            const temp = checks.filter(el => el !== _key);
            setChecks([...temp]);
        } else setChecks(prev => [...prev, _key]);
    };

    // to enable or disable continue button
    useEffect(() => {
        const enabledChecks = cancleChecks.filter(el => el.visible === true);
        if (enabledChecks.length === 0) setCanContinue(true);
        else {
            if (enabledChecks.every(el => checks.includes(el.key))) setCanContinue(true);
            else setCanContinue(false);
        }
    }, [cancleChecks, checks, setCanContinue]);

    const {
        data: usersData,
        fetchNextPage: fetchUsersNextPage,
        hasNextPage: hasUsersNextPage,
        isLoading: isLoadingUsers,
        isFetchingNextPage: isFetchingUsersNextPage,
    } = useInfiniteQuery(['users'], ({ pageParam = 1 }) => getUsers(50, pageParam), {
        staleTime: 1000 * 60 * 10, // 10 minutes
        cacheTime: 1000 * 60 * 15, // 15 minutes
        retry: 1,
        getNextPageParam: (lastPage: any) => {
            if (lastPage?.pagination?.next_page) {
                return lastPage.pagination.next_page;
            } else return undefined;
        },
        onError: (err: any) => {
            showToast({
                type: 'error',
                duration: 1500,
                message: err?.message || err || 'Failed to load users.',
            });
        },
        enabled: cancleChecks[0].visible === true,
    });

    useEffect(() => {
        const temp: Array<any> = [];
        if (usersData) {
            usersData.pages.forEach((page: any) => {
                const tempPageData = page.data.map((item: any) => {
                    return item;
                });
                temp.push(...tempPageData);
            });

            setUsers(temp.filter(el => el.id !== getUserDetails()?.id));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [usersData]);

    const userInfiniteRef = useInfiniteScroll({
        loading: isLoadingUsers || isFetchingUsersNextPage,
        hasNextPage: hasUsersNextPage,
        onLoadMore: fetchUsersNextPage,
    });

    const { data: pendingUsersData } = useInfiniteQuery(
        ['users-pending'],
        ({ pageParam = 1 }) => getUsers(50, pageParam, '', 'pending'),
        {
            staleTime: 1000 * 60 * 10, // 10 minutes
            cacheTime: 1000 * 60 * 15, // 15 minutes
            retry: 1,
            getNextPageParam: (lastPage: any) => {
                if (lastPage?.pagination?.next_page) {
                    return lastPage.pagination.next_page;
                } else return undefined;
            },
            onError: (err: any) => {
                showToast({
                    type: 'error',
                    duration: 1500,
                    message: err?.message || err || 'Failed to load pending users.',
                });
            },
            enabled: !!usersData && cancleChecks[0].visible === true,
        },
    );

    useEffect(() => {
        const temp: Array<any> = [...users];
        if (pendingUsersData) {
            pendingUsersData.pages.forEach((page: any) => {
                const tempPageData = page.data.map((item: any) => {
                    return item;
                });
                temp.push(...tempPageData);
                setUsers(prev => [...prev, ...tempPageData]);
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pendingUsersData]);

    const {
        data: pipelinesData,
        fetchNextPage: fetchPipelinesNextPage,
        hasNextPage: hasPipelinesNextPage,
        isLoading: isLoadingPipelines,
        isFetchingNextPage: isFetchingPipelinesNextPage,
    } = useInfiniteQuery(['pipelines'], ({ pageParam = 1 }) => getPipelines(50, pageParam), {
        staleTime: 1000 * 60 * 10, // 10 minutes
        cacheTime: 1000 * 60 * 15, // 15 minutes
        retry: 1,
        getNextPageParam: (lastPage: any) => {
            if (lastPage?.pagination?.next_page) {
                return lastPage.pagination.next_page;
            } else return undefined;
        },
        onError: (err: any) => {
            showToast({
                type: 'error',
                duration: 1500,
                message: err?.message || err || 'Failed to load pipelines.',
            });
        },
        enabled: cancleChecks[6].visible === true,
    });

    useEffect(() => {
        const temp: Array<any> = [];
        if (pipelinesData) {
            pipelinesData.pages.forEach((page: any) => {
                const tempPageData = page.data.map((item: any) => {
                    return item;
                });
                temp.push(...tempPageData);
            });

            setPipelines(temp);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pipelinesData]);

    const pipelineInfiniteRef = useInfiniteScroll({
        loading: isLoadingPipelines || isFetchingPipelinesNextPage,
        hasNextPage: hasPipelinesNextPage,
        onLoadMore: fetchPipelinesNextPage,
    });

    const {
        data: formsData,
        fetchNextPage: fetchFormsNextPage,
        hasNextPage: hasFormsNextPage,
        isLoading: isLoadingForms,
        isFetchingNextPage: isFetchingFormsNextPage,
    } = useInfiniteQuery(['forms'], ({ pageParam = 1 }) => getAllForms(50, pageParam), {
        staleTime: 1000 * 60 * 10, // 10 minutes
        cacheTime: 1000 * 60 * 15, // 15 minutes
        retry: 1,
        getNextPageParam: (lastPage: any) => {
            if (lastPage?.pagination?.next_page) {
                return lastPage.pagination.next_page;
            } else return undefined;
        },
        onError: (err: any) => {
            showToast({
                type: 'error',
                duration: 1500,
                message: err?.message || err || 'Failed to load forms.',
            });
        },
        enabled: cancleChecks[10].visible === true,
    });

    useEffect(() => {
        const temp: Array<any> = [];
        if (formsData) {
            formsData.pages.forEach((page: any) => {
                const tempPageData = page.data.map((item: any) => {
                    return item;
                });
                temp.push(...tempPageData);
            });

            setForms(temp);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [formsData]);

    const formInfiniteRef = useInfiniteScroll({
        loading: isLoadingForms || isFetchingFormsNextPage,
        hasNextPage: hasFormsNextPage,
        onLoadMore: fetchFormsNextPage,
    });

    const {
        data: campaignsData,
        fetchNextPage: fetchCampaignsNextPage,
        hasNextPage: hasCampaignsNextPage,
        isLoading: isLoadingCampaigns,
        isFetchingNextPage: isFetchingCampaignsNextPage,
    } = useInfiniteQuery(
        ['campaigns'],
        ({ pageParam = 1 }) => getCampaigns(pageParam === 1 ? null : pageParam, 50),
        {
            staleTime: 1000 * 60 * 10, // 10 minutes
            cacheTime: 1000 * 60 * 15, // 15 minutes
            retry: 1,
            getNextPageParam: (lastPage: any) => {
                if (lastPage?.pagination?.next_page) {
                    return lastPage.pagination.next_page;
                } else return undefined;
            },
            onError: (err: any) => {
                showToast({
                    type: 'error',
                    duration: 1500,
                    message: err?.message || err || 'Failed to load campaigns.',
                });
            },
            enabled: cancleChecks[13].visible === true,
        },
    );

    useEffect(() => {
        const temp: Array<any> = [];
        if (campaignsData) {
            campaignsData.pages.forEach((page: any) => {
                const tempPageData = page.data.map((item: any) => {
                    return item;
                });
                temp.push(...tempPageData);
            });

            setCampaigns(temp);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [campaignsData]);

    const campaignInfiniteRef = useInfiniteScroll({
        loading: isLoadingCampaigns || isFetchingCampaignsNextPage,
        hasNextPage: hasCampaignsNextPage,
        onLoadMore: fetchCampaignsNextPage,
    });

    const { data: seoData, isLoading: isLoadingKeywords } = useQuery(['seo'], () => getConfig(), {
        staleTime: 1000 * 60 * 10, // 10 minutes
        cacheTime: 1000 * 60 * 15, // 15 minutes
        retry: 1,
        onError: (err: any) => {
            showToast({
                type: 'error',
                duration: 1500,
                message: err?.message || err || 'Failed to load tracked keywords.',
            });
        },
        enabled: cancleChecks[15].visible === true,
    });

    useEffect(() => {
        setKeywords(seoData?.data?.keywords);
    }, [seoData]);

    const {
        data: templatesData,
        fetchNextPage: fetchTemplatesNextPage,
        hasNextPage: hasTemplatesNextPage,
        isLoading: isLoadingTemplates,
        isFetchingNextPage: isFetchingTemplatesNextPage,
    } = useInfiniteQuery(
        ['templates'],
        ({ pageParam = 1 }) => loadData(pageParam, 50, TYPES.MY_TEMPLATES),
        {
            staleTime: 1000 * 60 * 10, // 10 minutes
            cacheTime: 1000 * 60 * 15, // 15 minutes
            retry: 1,
            getNextPageParam: (lastPage: any) => {
                if (lastPage?.pagination?.next_page) {
                    return lastPage.pagination.next_page;
                } else return undefined;
            },
            onError: (err: any) => {
                showToast({
                    type: 'error',
                    duration: 1500,
                    message: err?.message || err || 'Failed to load templates.',
                });
            },
            enabled: cancleChecks[16].visible === true,
        },
    );

    useEffect(() => {
        const temp: Array<any> = [];
        if (templatesData) {
            templatesData.pages.forEach((page: any) => {
                const tempPageData = page.data.map((item: any) => {
                    return item;
                });
                temp.push(...tempPageData);
            });

            setTemplates(temp);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [templatesData]);

    const templateInfiniteRef = useInfiniteScroll({
        loading: isLoadingTemplates || isFetchingTemplatesNextPage,
        hasNextPage: hasTemplatesNextPage,
        onLoadMore: fetchTemplatesNextPage,
    });

    const generateListDisplay = useCallback(
        (key: string) => {
            switch (key) {
                case 'users':
                    return (
                        <Div className={'SDS2TBLInner'} reference={userInfiniteRef}>
                            {isLoadingUsers ? (
                                Array(4)
                                    .fill(0)
                                    .map((el, index) => (
                                        <Skeleton
                                            key={el + index}
                                            className={'SDS2TBLIItemSkeleton'}
                                            height={35}
                                            marginTop={3}
                                            width={'90%'}
                                        />
                                    ))
                            ) : (
                                <Fragment>
                                    {users.map((user: any, index) => (
                                        <Div
                                            className={
                                                usersToDelete.includes(user.id)
                                                    ? 'SDS2TBLIItem active'
                                                    : 'SDS2TBLIItem'
                                            }
                                            key={index}
                                        >
                                            <UserInfo
                                                className={'SDS2TBLITitle'}
                                                name={user.name}
                                                email={user.email}
                                                image={generateImage(user.image, user.name)}
                                            />
                                            {!usersToDelete.includes(user.id) ? (
                                                <IconButton
                                                    buttonType="circleBtn RedFillBtn"
                                                    onClick={() => {
                                                        setUsersToDelete(prev => [
                                                            ...prev,
                                                            user.id,
                                                        ]);
                                                        const temp = users.filter(
                                                            el => el.id !== user.id,
                                                        );

                                                        setUserCount((prev: number) => prev - 1);
                                                        if (
                                                            userCount - 1 <=
                                                            getPlanLimit(
                                                                type.plan,
                                                                'System',
                                                                'Team Member Seats',
                                                            )
                                                        ) {
                                                            if (!checks.includes('users'))
                                                                setChecks(prev => [
                                                                    ...prev,
                                                                    'users',
                                                                ]);
                                                        }
                                                        if (temp.length < 5 && hasUsersNextPage)
                                                            fetchUsersNextPage();
                                                    }}
                                                >
                                                    <DCTrash />
                                                </IconButton>
                                            ) : (
                                                <IconButton
                                                    buttonType="circleBtn BlueFillBtn"
                                                    onClick={() => {
                                                        let temp = usersToDelete.filter(
                                                            el => el !== user.id,
                                                        );
                                                        setUsersToDelete(temp);

                                                        setUserCount((prev: number) => prev + 1);
                                                        if (
                                                            userCount + 1 <=
                                                            getPlanLimit(
                                                                type.plan,
                                                                'System',
                                                                'Team Member Seats',
                                                            )
                                                        ) {
                                                            if (!checks.includes('users'))
                                                                setChecks(prev => [
                                                                    ...prev,
                                                                    'users',
                                                                ]);
                                                        }
                                                    }}
                                                >
                                                    <DCUndo />
                                                </IconButton>
                                            )}
                                        </Div>
                                    ))}
                                    {isFetchingUsersNextPage &&
                                        Array(2)
                                            .fill(0)
                                            .map((el, index) => (
                                                <Skeleton
                                                    key={el + index}
                                                    className={'SDS2TBLIItemSkeleton'}
                                                    height={35}
                                                    marginTop={3}
                                                    width={'90%'}
                                                />
                                            ))}
                                    {users.length === 0 && <NoData title={'No data found'} />}
                                </Fragment>
                            )}
                        </Div>
                    );
                case 'sales_pipelines':
                    return (
                        <Div className={'SDS2TBLInner'} reference={pipelineInfiniteRef}>
                            {isLoadingPipelines ? (
                                Array(4)
                                    .fill(0)
                                    .map((el, index) => (
                                        <Skeleton
                                            key={el + index}
                                            className={'SDS2TBLIItemSkeleton'}
                                            height={35}
                                            marginTop={3}
                                            width={'90%'}
                                        />
                                    ))
                            ) : (
                                <Fragment>
                                    {pipelines.map((pipeline: any, index) => (
                                        <Div
                                            className={
                                                pipelinesToDelete.includes(pipeline.id)
                                                    ? 'SDS2TBLIItem active'
                                                    : 'SDS2TBLIItem'
                                            }
                                            key={index}
                                        >
                                            <Div className={'SDS2TBLIImg'}>
                                                <DCBars />
                                            </Div>
                                            <Div className={'SDS2TBLITitle'}>{pipeline.name}</Div>
                                            {/* {pipelinesToDelete } */}
                                            {!pipelinesToDelete.includes(pipeline.id) ? (
                                                <IconButton
                                                    buttonType="circleBtn RedFillBtn"
                                                    onClick={() => {
                                                        setPipelinesToDelete(prev => [
                                                            ...prev,
                                                            pipeline.id,
                                                        ]);
                                                        const temp = pipelines.filter(
                                                            el => el.id !== pipeline.id,
                                                        );
                                                        // setPipelines([...temp]);
                                                        setPipelineCount(
                                                            (prev: number) => prev - 1,
                                                        );
                                                        if (
                                                            pipelineCount - 1 <=
                                                            getPlanLimit(
                                                                type.plan,
                                                                'Deals',
                                                                'Sales Pipelines',
                                                            )
                                                        ) {
                                                            if (!checks.includes('sales_pipelines'))
                                                                setChecks(prev => [
                                                                    ...prev,
                                                                    'sales_pipelines',
                                                                ]);
                                                        }
                                                        if (temp.length < 5 && hasPipelinesNextPage)
                                                            fetchPipelinesNextPage();
                                                    }}
                                                >
                                                    <DCTrash />
                                                </IconButton>
                                            ) : (
                                                <IconButton
                                                    buttonType="circleBtn BlueFillBtn"
                                                    onClick={() => {
                                                        let temp = pipelinesToDelete.filter(
                                                            el => el !== pipeline.id,
                                                        );
                                                        setPipelinesToDelete(temp);
                                                        setPipelineCount(
                                                            (prev: number) => prev + 1,
                                                        );
                                                        if (
                                                            pipelineCount + 1 <=
                                                            getPlanLimit(
                                                                type.plan,
                                                                'Deals',
                                                                'Sales Pipelines',
                                                            )
                                                        ) {
                                                            if (!checks.includes('sales_pipelines'))
                                                                setChecks(prev => [
                                                                    ...prev,
                                                                    'sales_pipelines',
                                                                ]);
                                                        }
                                                    }}
                                                >
                                                    <DCUndo />
                                                </IconButton>
                                            )}
                                        </Div>
                                    ))}
                                    {isFetchingPipelinesNextPage &&
                                        Array(2)
                                            .fill(0)
                                            .map((el, index) => (
                                                <Skeleton
                                                    key={el + index}
                                                    className={'SDS2TBLIItemSkeleton'}
                                                    height={35}
                                                    marginTop={3}
                                                    width={'90%'}
                                                />
                                            ))}
                                    {pipelines.length === 0 && <NoData title={'No data found'} />}
                                </Fragment>
                            )}
                        </Div>
                    );
                case 'forms':
                    return (
                        <Div className={'SDS2TBLInner'} reference={formInfiniteRef}>
                            {isLoadingForms ? (
                                Array(4)
                                    .fill(0)
                                    .map((el, index) => (
                                        <Skeleton
                                            key={el + index}
                                            className={'SDS2TBLIItemSkeleton'}
                                            height={35}
                                            marginTop={3}
                                            width={'90%'}
                                        />
                                    ))
                            ) : (
                                <Fragment>
                                    {forms.map((form: any, index) => (
                                        <Div
                                            className={
                                                formsToDelete.includes(form.id)
                                                    ? 'SDS2TBLIItem active'
                                                    : 'SDS2TBLIItem'
                                            }
                                            key={index}
                                        >
                                            <Div className={'SDS2TBLIImg'}>
                                                <DCForm />
                                            </Div>
                                            <Div className={'SDS2TBLITitle'}>
                                                {form.formdata.form_name}
                                            </Div>
                                            {!formsToDelete.includes(form.id) ? (
                                                <IconButton
                                                    buttonType="circleBtn RedFillBtn"
                                                    onClick={() => {
                                                        setFormsToDelete(prev => [
                                                            ...prev,
                                                            form.id,
                                                        ]);
                                                        const temp = forms.filter(
                                                            el => el.id !== form.id,
                                                        );

                                                        setFormCount((prev: number) => prev - 1);
                                                        if (
                                                            formCount - 1 <=
                                                            getPlanLimit(
                                                                type.plan,
                                                                'Forms',
                                                                'Forms',
                                                            )
                                                        ) {
                                                            if (!checks.includes('forms'))
                                                                setChecks(prev => [
                                                                    ...prev,
                                                                    'forms',
                                                                ]);
                                                        }
                                                        if (temp.length < 5 && hasFormsNextPage)
                                                            fetchFormsNextPage();
                                                    }}
                                                >
                                                    <DCTrash />
                                                </IconButton>
                                            ) : (
                                                <IconButton
                                                    buttonType="circleBtn BlueFillBtn"
                                                    onClick={() => {
                                                        let temp = formsToDelete.filter(
                                                            el => el !== form.id,
                                                        );
                                                        setFormsToDelete(temp);

                                                        setFormCount((prev: number) => prev + 1);
                                                        if (
                                                            formCount + 1 <=
                                                            getPlanLimit(
                                                                type.plan,
                                                                'Forms',
                                                                'Forms',
                                                            )
                                                        ) {
                                                            if (!checks.includes('forms'))
                                                                setChecks(prev => [
                                                                    ...prev,
                                                                    'forms',
                                                                ]);
                                                        }
                                                    }}
                                                >
                                                    <DCUndo />
                                                </IconButton>
                                            )}
                                        </Div>
                                    ))}
                                    {isFetchingFormsNextPage &&
                                        Array(2)
                                            .fill(0)
                                            .map((el, index) => (
                                                <Skeleton
                                                    key={el + index}
                                                    className={'SDS2TBLIItemSkeleton'}
                                                    height={35}
                                                    marginTop={3}
                                                    width={'90%'}
                                                />
                                            ))}
                                    {forms.length === 0 && <NoData title={'No data found'} />}
                                </Fragment>
                            )}
                        </Div>
                    );
                case 'campaigns':
                    return (
                        <Div className={'SDS2TBLInner'} reference={campaignInfiniteRef}>
                            {isLoadingCampaigns ? (
                                Array(4)
                                    .fill(0)
                                    .map((el, index) => (
                                        <Skeleton
                                            key={el + index}
                                            className={'SDS2TBLIItemSkeleton'}
                                            height={35}
                                            marginTop={3}
                                            width={'90%'}
                                        />
                                    ))
                            ) : (
                                <Fragment>
                                    {campaigns.map((campaign: any, index) => (
                                        <Div
                                            className={
                                                campaignsToDelete.includes(campaign.id)
                                                    ? 'SDS2TBLIItem active'
                                                    : 'SDS2TBLIItem'
                                            }
                                            key={index}
                                        >
                                            <Div className={'SDS2TBLIImg'}>
                                                <DCCampaigns1 />
                                            </Div>
                                            <Div className={'SDS2TBLITitle'}>
                                                {campaign.campaign_name}
                                            </Div>
                                            {!campaignsToDelete.includes(campaign.id) ? (
                                                <IconButton
                                                    buttonType="circleBtn RedFillBtn"
                                                    onClick={() => {
                                                        setCampaignsToDelete(prev => [
                                                            ...prev,
                                                            campaign.id,
                                                        ]);
                                                        const temp = campaigns.filter(
                                                            el => el.id !== campaign.id,
                                                        );

                                                        setCampaignCount(
                                                            (prev: number) => prev - 1,
                                                        );
                                                        if (
                                                            campaignCount - 1 <=
                                                            getPlanLimit(
                                                                type.plan,
                                                                'Inbound',
                                                                'Campaigns',
                                                            )
                                                        ) {
                                                            if (!checks.includes('campaigns'))
                                                                setChecks(prev => [
                                                                    ...prev,
                                                                    'campaigns',
                                                                ]);
                                                        }
                                                        if (temp.length < 5 && hasCampaignsNextPage)
                                                            fetchCampaignsNextPage();
                                                    }}
                                                >
                                                    <DCTrash />
                                                </IconButton>
                                            ) : (
                                                <IconButton
                                                    buttonType="circleBtn BlueFillBtn"
                                                    onClick={() => {
                                                        let temp = formsToDelete.filter(
                                                            el => el !== campaign.id,
                                                        );
                                                        setCampaignsToDelete(temp);
                                                        setCampaignCount(
                                                            (prev: number) => prev + 1,
                                                        );
                                                        if (
                                                            campaignCount + 1 <=
                                                            getPlanLimit(
                                                                type.plan,
                                                                'Inbound',
                                                                'Campaigns',
                                                            )
                                                        ) {
                                                            if (!checks.includes('campaigns'))
                                                                setChecks(prev => [
                                                                    ...prev,
                                                                    'campaigns',
                                                                ]);
                                                        }
                                                    }}
                                                >
                                                    <DCUndo />
                                                </IconButton>
                                            )}
                                        </Div>
                                    ))}
                                    {isFetchingCampaignsNextPage &&
                                        Array(2)
                                            .fill(0)
                                            .map((el, index) => (
                                                <Skeleton
                                                    key={el + index}
                                                    className={'SDS2TBLIItemSkeleton'}
                                                    height={35}
                                                    marginTop={3}
                                                    width={'90%'}
                                                />
                                            ))}
                                    {campaigns.length === 0 && <NoData title={'No data found'} />}
                                </Fragment>
                            )}
                        </Div>
                    );
                case 'seo_keywords':
                    return (
                        <Div className={'SDS2TBLInner'}>
                            {isLoadingKeywords ? (
                                Array(4)
                                    .fill(0)
                                    .map((el, index) => (
                                        <Skeleton
                                            key={el + index}
                                            className={'SDS2TBLIItemSkeleton'}
                                            height={35}
                                            marginTop={3}
                                            width={'90%'}
                                        />
                                    ))
                            ) : (
                                <Fragment>
                                    {keywords?.map((keyword: any, index) => (
                                        <Div
                                            className={
                                                keywordsToDelete.includes(keyword)
                                                    ? 'SDS2TBLIItem active'
                                                    : 'SDS2TBLIItem'
                                            }
                                            key={index}
                                        >
                                            <Div className={'SDS2TBLIImg'}>
                                                <DCTrackedSeoKeywords />
                                            </Div>
                                            <Div className={'SDS2TBLITitle'}>{keyword.keyword}</Div>
                                            {!keywordsToDelete.includes(keyword) ? (
                                                <IconButton
                                                    buttonType="circleBtn RedFillBtn"
                                                    onClick={() => {
                                                        setKeywordsToDelete(prev => [
                                                            ...prev,
                                                            keyword,
                                                        ]);

                                                        setSeoCount((prev: number) => prev - 1);
                                                        if (
                                                            seoCount - 1 <=
                                                            getPlanLimit(
                                                                type.plan,
                                                                'Analytics',
                                                                'Tracked SEO Keywords',
                                                            )
                                                        ) {
                                                            if (!checks.includes('seo_keywords'))
                                                                setChecks(prev => [
                                                                    ...prev,
                                                                    'seo_keywords',
                                                                ]);
                                                        }
                                                    }}
                                                >
                                                    <DCTrash />
                                                </IconButton>
                                            ) : (
                                                <IconButton
                                                    buttonType="circleBtn BlueFillBtn"
                                                    onClick={() => {
                                                        let temp = keywordsToDelete.filter(
                                                            el => el !== keyword.keyword,
                                                        );
                                                        setKeywordsToDelete(temp);
                                                        setSeoCount((prev: number) => prev + 1);
                                                        if (
                                                            seoCount + 1 <=
                                                            getPlanLimit(
                                                                type.plan,
                                                                'Analytics',
                                                                'Tracked SEO Keywords',
                                                            )
                                                        ) {
                                                            if (!checks.includes('seo_keywords'))
                                                                setChecks(prev => [
                                                                    ...prev,
                                                                    'seo_keywords',
                                                                ]);
                                                        }
                                                    }}
                                                >
                                                    <DCUndo />
                                                </IconButton>
                                            )}
                                        </Div>
                                    ))}
                                    {keywords?.length === 0 && <NoData title={'No data found'} />}
                                </Fragment>
                            )}
                        </Div>
                    );
                case 'templates':
                    return (
                        <Div className={'SDS2TBLInner'} reference={templateInfiniteRef}>
                            {isLoadingTemplates ? (
                                Array(4)
                                    .fill(0)
                                    .map((el, index) => (
                                        <Skeleton
                                            key={el + index}
                                            className={'SDS2TBLIItemSkeleton'}
                                            height={35}
                                            marginTop={3}
                                            width={'90%'}
                                        />
                                    ))
                            ) : (
                                <Fragment>
                                    {templates.map((template: any, index) => (
                                        <Div
                                            className={
                                                templatesToDelete.includes(template.id)
                                                    ? 'SDS2TBLIItem active'
                                                    : 'SDS2TBLIItem'
                                            }
                                            key={index}
                                        >
                                            <Div className={'SDS2TBLIImg'}>
                                                <DCTemplates1 />
                                            </Div>
                                            <Div className={'SDS2TBLITitle'}>{template.title}</Div>
                                            {!templatesToDelete.includes(template.id) ? (
                                                <IconButton
                                                    buttonType="circleBtn RedFillBtn"
                                                    onClick={() => {
                                                        setTemplatesToDelete(prev => [
                                                            ...prev,
                                                            template.id,
                                                        ]);
                                                        const temp = templates.filter(
                                                            el => el.id !== template.id,
                                                        );
                                                        // setTemplates([...temp]);
                                                        setTemplateCount(
                                                            (prev: number) => prev - 1,
                                                        );
                                                        if (
                                                            templateCount - 1 <=
                                                            getPlanLimit(
                                                                type.plan,
                                                                'Templates',
                                                                'Custom Templates',
                                                            )
                                                        ) {
                                                            if (!checks.includes('templates'))
                                                                setChecks(prev => [
                                                                    ...prev,
                                                                    'templates',
                                                                ]);
                                                        }
                                                        if (temp.length < 5 && hasTemplatesNextPage)
                                                            fetchTemplatesNextPage();
                                                    }}
                                                >
                                                    <DCTrash />
                                                </IconButton>
                                            ) : (
                                                <IconButton
                                                    buttonType="circleBtn BlueFillBtn"
                                                    onClick={() => {
                                                        let temp = templatesToDelete.filter(
                                                            el => el !== template.id,
                                                        );
                                                        setTemplatesToDelete(temp);

                                                        // setTemplates([...temp]);
                                                        setTemplateCount(
                                                            (prev: number) => prev + 1,
                                                        );
                                                        if (
                                                            templateCount + 1 <=
                                                            getPlanLimit(
                                                                type.plan,
                                                                'Templates',
                                                                'Custom Templates',
                                                            )
                                                        ) {
                                                            if (!checks.includes('templates'))
                                                                setChecks(prev => [
                                                                    ...prev,
                                                                    'templates',
                                                                ]);
                                                        }
                                                    }}
                                                >
                                                    <DCUndo />
                                                </IconButton>
                                            )}
                                        </Div>
                                    ))}
                                    {isFetchingTemplatesNextPage &&
                                        Array(2)
                                            .fill(0)
                                            .map((el, index) => (
                                                <Skeleton
                                                    key={el + index}
                                                    className={'SDS2TBLIItemSkeleton'}
                                                    height={35}
                                                    marginTop={3}
                                                    width={'90%'}
                                                />
                                            ))}
                                    {templates.length === 0 && <NoData title={'No data found'} />}
                                </Fragment>
                            )}
                        </Div>
                    );
            }
        },
        [
            userInfiniteRef,
            isLoadingUsers,
            users,
            isFetchingUsersNextPage,
            pipelineInfiniteRef,
            isLoadingPipelines,
            pipelines,
            isFetchingPipelinesNextPage,
            formInfiniteRef,
            isLoadingForms,
            forms,
            isFetchingFormsNextPage,
            campaignInfiniteRef,
            isLoadingCampaigns,
            campaigns,
            isFetchingCampaignsNextPage,
            isLoadingKeywords,
            keywords,
            templateInfiniteRef,
            isLoadingTemplates,
            templates,
            isFetchingTemplatesNextPage,
            usersToDelete,
            userCount,
            type,
            hasUsersNextPage,
            fetchUsersNextPage,
            checks,
            pipelinesToDelete,
            pipelineCount,
            hasPipelinesNextPage,
            fetchPipelinesNextPage,
            formsToDelete,
            formCount,
            hasFormsNextPage,
            fetchFormsNextPage,
            campaignsToDelete,
            campaignCount,
            hasCampaignsNextPage,
            fetchCampaignsNextPage,
            keywordsToDelete,
            seoCount,
            templatesToDelete,
            templateCount,
            hasTemplatesNextPage,
            fetchTemplatesNextPage,
        ],
    );

    const getProgressBarValue = useCallback(
        (key: string) => {
            switch (key) {
                case 'users': {
                    const val =
                        (usersToDelete.length /
                            (usageData['USERS']?.used -
                                getPlanLimit(type.plan, 'System', 'Team Member Seats'))) *
                        100;
                    if (val < 100) return val;
                    return 100;
                }
                case 'sales_pipelines': {
                    const val =
                        (pipelinesToDelete.length /
                            (usageData['SALES_PIPELINE']?.used -
                                getPlanLimit(type.plan, 'Deals', 'Sales Pipelines'))) *
                        100;
                    if (val < 100) return val;
                    return 100;
                }
                case 'forms': {
                    const val =
                        (formsToDelete.length /
                            (usageData['FORMS']?.used -
                                getPlanLimit(type.plan, 'Forms', 'Forms'))) *
                        100;
                    if (val < 100) return val;
                    return 100;
                }
                case 'campaigns': {
                    const val =
                        (campaignsToDelete.length /
                            (usageData['INBOUND_CAMPAIGNS']?.used -
                                getPlanLimit(type.plan, 'Inbound', 'Campaigns'))) *
                        100;
                    if (val < 100) return val;
                    return 100;
                }
                case 'seo_keywords': {
                    const val =
                        (keywordsToDelete.length /
                            (usageData['TRACKED_SEO_KEYWORDS']?.used -
                                getPlanLimit(type.plan, 'Analytics', 'Tracked SEO Keywords'))) *
                        100;
                    if (val < 100) return val;
                    return 100;
                }
                case 'templates': {
                    const val =
                        (templatesToDelete.length /
                            (usageData['TEMPLATES']?.used -
                                getPlanLimit(type.plan, 'Templates', 'Custom Templates'))) *
                        100;
                    if (val < 100) return val;
                    return 100;
                }
            }
        },
        [
            usersToDelete,
            usageData,
            type,
            pipelinesToDelete,
            formsToDelete,
            campaignsToDelete,
            keywordsToDelete,
            templatesToDelete,
        ],
    );

    const createBody = useCallback(() => {
        return {
            subscription_id: getAccountDetails()?.plan.id,
            reason: stepsData.current.cancellationReason?.reason,
            feedback: stepsData.current.cancellationReason?.description,
            product_type: 'software',
            new_plan: {
                price: type.plan.priceId,
                business: getAccountDetails()?.business_id,
                display_name: type.plan.nickname,
            },
            software_app: {
                platform: {
                    team_member_seats: usersToDelete.map(el => {
                        return {
                            user: el,
                            assigned_to: {
                                contact: getUserDetails().id,
                                deals: getUserDetails().id,
                                instareports: getUserDetails().id,
                                instasites: getUserDetails().id,
                                inbound_campaigns: getUserDetails().id,
                                inbound_round_robin: getUserDetails().id,
                                forms: getUserDetails().id,
                                template: getUserDetails().id,
                            },
                        };
                    }),
                },
                deals: {
                    sales_pipelines: pipelinesToDelete,
                },
                forms: {
                    form: formsToDelete,
                },
                inbound: {
                    campaigns: campaignsToDelete,
                },
                analytics: {
                    seo_keywords: keywordsToDelete,
                },
                templates: {
                    custom_template: templatesToDelete,
                },
            },
        };
    }, [
        campaignsToDelete,
        formsToDelete,
        keywordsToDelete,
        pipelinesToDelete,
        stepsData,
        templatesToDelete,
        type,
        usersToDelete,
    ]);

    useEffect(() => {
        onContinue.current = () => {
            return new Promise((resolve, reject) => {
                setCanContinue(false);
                setContinueLoading(true);
                const body = createBody();
                updateSubscription(body)
                    .then((res: any) => {
                        if (res.success) {
                            doContinue();
                            const authObject = JSON.parse(
                                localStorage.getItem('auth-user') || '{}',
                            );
                            const sessionId = getSessionId();
                            authObject[sessionId].auth.account.plan.tier =
                                res.data.plan.metadata.tier;
                            authObject[sessionId].auth.account.plan.display_name =
                                res.data.plan.nickname;
                            setCurrentTier && setCurrentTier(res.data.plan.metadata.tier);
                            resolve(true);
                            if (!type.service) {
                                if (res.data.cancel_at_period_end) {
                                    authObject[sessionId].auth.account.downgrade.plan = 'tier0';

                                    showToast({
                                        type: 'success',
                                        message: `Your plan will be downgraded on ${formateDate(
                                            dayjs(res.data.current_period_end * 1000).toDate(),
                                        )}`,
                                    });
                                } else {
                                    showToast({
                                        type: 'success',
                                        message: `Your plan is downgrading.`,
                                    });
                                }
                            }
                            localStorage.setItem('auth-user', JSON.stringify(authObject));
                        }
                    })
                    .catch((err: any) => {
                        showToast({
                            type: 'error',
                            message: err || 'Something went wrong',
                            duration: 2000,
                        });
                        setCanContinue(true);
                        reject();
                    })
                    .finally(() => {
                        setContinueLoading(false);
                    });
            });
        };
        onContinue.current.funcType = 'promise';
        onContinue.current.shouldContinue = false;
        return () => {};
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [createBody, doContinue, onContinue, setCanContinue, showToast]);

    return (
        <Fragment>
            <Div className={'SimpleInputViewMain'}>
                {downGradeMessage && (
                    <Div className={'SimpleInputViewMainMessage'}> {downGradeMessage}</Div>
                )}
                <Div className={'SimpleInputView'}>
                    <Div className={'SCStep2'}>
                        {type.serviceName !== 'Software' ? (
                            <Div className={'SDStepHead2'}>
                                <Div className={'SDSH2Title'}>
                                    <b>
                                        This subscription is <u>NOT</u> downgraded yet...
                                    </b>
                                </Div>
                                <Div className={'SDSH2STitle'}>
                                    Please adjust your limits below to fit your new downgraded plan
                                    and confirm all information needed to continue.
                                </Div>
                            </Div>
                        ) : (
                            <Div className={'SDStepHead2'}>
                                <Div className={'SDSH2Title'}>
                                    <b>
                                        This subscription is <u>NOT</u> canceled yet...
                                    </b>
                                </Div>
                                <Div className={'SDSH2STitle'}>
                                    Please confirm that you would like to cancel this subscription
                                    and that you are aware of all the items listed below.
                                </Div>
                            </Div>
                        )}
                        {downGradePromt ? (
                            <Div className={'SDS2Plan'}>
                                <Div className={'SDS2PCur'}>
                                    We need some final changes below in order to process the
                                    downgrade for your subscription
                                </Div>
                            </Div>
                        ) : (
                            <Div className={'SDS2Plan'}>
                                <Div className={'SDS2PCur'}>
                                    Current Plan :{' '}
                                    <strong style={{ color: '#0fdac2' }}>
                                        {getAccountDetails()?.plan.display_name}
                                    </strong>
                                </Div>
                                <Div className={'SDS2PArr'}>
                                    <DCRightArrow2 />
                                </Div>
                                <Div className={'SDS2PNew'}>
                                    New Plan :{' '}
                                    <strong style={{ color: '#005eb8' }}>
                                        {type?.plan?.nickname}
                                    </strong>
                                </Div>
                            </Div>
                        )}

                        {cancleChecks
                            .filter(el => el.visible)
                            .map(el => el.type === 'delete' ? (
                                    <Div key={el.id} className={'SDS2Team'}>
                                        <Div className={'SDS2THead'}>
                                            <Div className={'SDS2THTitle'}>
                                                <Div className={'SDS2THTIcon'}>{el.icon}</Div>
                                                <Div className={'SDS2THTText'}>{el.title}</Div>
                                            </Div>
                                            <Div className={'SDS2THDes'}>{el.description}</Div>
                                            <Div
                                                className={'SDS2THIcon'}
                                                style={{
                                                    color: checks.includes(el.key) ? '#83e814' : '',
                                                }}
                                            >
                                                <DCCheckCircle />
                                            </Div>
                                        </Div>
                                        <Div className={'SDS2TBody'}>
                                            <Div className={'SDS2TBLeft'}>
                                                {generateListDisplay(el.key)}
                                            </Div>
                                            <Div className={'SDS2TBRight'}>
                                                <Div className={'SDS2TBRInner'}>
                                                    <Div className={'SDS2TBRIProcess'}>
                                                        <Div className={'SDS2TBRIPBG'}>
                                                            <Div
                                                                className={'SDS2TBRIPBGInner'}
                                                                style={{
                                                                    width: `${getProgressBarValue(
                                                                        el.key,
                                                                    )}%`,
                                                                }}
                                                            >
                                                                {' '}
                                                            </Div>
                                                        </Div>
                                                        <Div className={'SDS2TBRIPText'}>
                                                            {el.limitDescription}
                                                        </Div>
                                                    </Div>
                                                </Div>
                                            </Div>
                                        </Div>
                                    </Div>
                                ) : (
                                    <Div key={el.id} className={'SDS2File'}>
                                        <Div className={'SDS2FHead'}>
                                            <Div className={'SDS2FHTitle'}>
                                                <Div className={'SDS2FHTIcon'}>{el.icon}</Div>
                                                <Div className={'SDS2FHText'}>{el.title}</Div>
                                            </Div>
                                            <Div className={'SDS2FHDes'}>{el.description}</Div>
                                            <Div
                                                className={'SDS2FHIcon'}
                                                style={{
                                                    color: checks.includes(el.key) ? '#83e814' : '',
                                                }}
                                            >
                                                <DCCheckCircle />
                                            </Div>
                                        </Div>
                                        <Div className={'SDS2FBody'}>
                                            <Div className={'SDS2FBIHelp'}>
                                                <Div className={'SDS2FBIHIcon'}>
                                                    <DCInfoCircle />
                                                </Div>
                                                <Div className={'SDS2FBIHTitle'}>
                                                    {el.warningDescription}
                                                </Div>
                                            </Div>
                                            <Div className={'SDS2FBFooter'}>
                                                <Checkbox
                                                    unCheckColor={'#eaeaea'}
                                                    checkColor={'var(--dark-blue)'}
                                                    fullWidth={true}
                                                    label={
                                                        type.serviceName !== 'Software' ? (
                                                            <>
                                                                I acknowledge and confirm that I am
                                                                aware of the items listed above and
                                                                would still like to downgrade this
                                                                subscription.
                                                            </>
                                                        ) : (
                                                            <>
                                                                I acknowledge and confirm that I am
                                                                aware of the items listed above and
                                                                would still like to cancel this
                                                                subscription.
                                                            </>
                                                        )
                                                    }
                                                    checked={checks.includes(el.key)}
                                                    onChange={() => handleCheck(el.key)}
                                                />
                                            </Div>
                                        </Div>
                                    </Div>
                                ),
                            )}
                        {cancleChecks.filter(el => el.visible).length === 0 && (
                            <NoData title={'No Action Needed'} />
                        )}
                    </Div>
                </Div>
            </Div>
        </Fragment>
    );
};

export default SCStep22;
