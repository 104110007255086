import React from 'react';

import { SVGIcon } from '../../UIKit/index';

export default function DCControl(props) {
    return (
        <SVGIcon {...props} viewBox="0 0 512 512">
            <path d="M493.7,49.4h-309V37.1c-0.1-17.8-14.5-32.3-32.4-32.4H102C84,4.7,69.5,19.2,69.4,37.1v12.2     H18.4c-7.2,0-13,5.8-13,13c0,7.2,5.8,13,13,13h50.9v12.2c0,18,14.5,32.6,32.5,32.6h0h50.3c17.9,0,32.4-14.6,32.5-32.5V75.4h308.9     c7.2,0,13-5.8,13-13C506.6,55.3,500.7,49.4,493.7,49.4L493.7,49.4z M158.8,87.6c0,3.6-2.9,6.5-6.5,6.5h0H102     c-3.6,0-6.5-2.9-6.5-6.5l0,0V37.1c0-3.6,2.9-6.5,6.5-6.5c0,0,0,0,0,0h50.3c3.6,0,6.5,2.9,6.5,6.5l0,0V87.6z" />
            <path d="M493.7,189.6h-50.9v-12.2c0-17.9-14.6-32.4-32.5-32.5H360c-17.9,0-32.4,14.6-32.5,32.5v12.2     H18.4c-7.2,0-13,5.8-13,13c0,7.2,5.8,13,13,13h308.9v12.2c0,17.9,14.6,32.4,32.5,32.5h50.3c17.9,0,32.4-14.6,32.5-32.5v-12.2     h50.9c7.2,0,13-5.8,13-13c0-7.2-5.8-13-13-13H493.7z M416.7,227.8c0,3.6-2.9,6.5-6.5,6.5c0,0,0,0,0,0h-50.3     c-3.6,0-6.5-2.9-6.5-6.5l0,0v-50.3c0-3.6,2.9-6.5,6.5-6.5c0,0,0,0,0,0h50.3c3.6,0,6.5,2.9,6.5,6.5l0,0V227.8z" />
            <path d="M493.7,340H276.1v-12.2c0-17.9-14.6-32.4-32.5-32.5h-50.3c-17.9,0-32.4,14.6-32.5,32.5V340     H18.4c-7.2,0-13,5.8-13,13c0,7.2,5.8,13,13,13h142.2v12.2c0,17.9,14.6,32.4,32.5,32.5h50.3c17.9,0,32.4-14.6,32.5-32.5v-12.3     h217.6c7.2,0,13-5.8,13-13c0-7.2-5.8-13-13-13L493.7,340z M250.1,378.2c0,3.6-2.9,6.5-6.5,6.5h0h-50.4c-3.6,0-6.5-2.9-6.5-6.5     l0,0v-50.3c0-3.6,2.9-6.5,6.5-6.5h0h50.3c3.6,0,6.5,2.9,6.5,6.5l0,0v50.3H250.1z" />
        </SVGIcon>
    );
}
