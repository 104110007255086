import React from 'react';
import PropTypes from 'prop-types';
import { Div } from '../../UIKit/index';

import './style.scss';

const TitleIcon = ({ customClass, title, icon, button }) => (
    <Div className={`TitleIconMain ${customClass ? customClass : ''}`}>
        <Div className={'TIMInner'}>
            <Div className={'TIMITitle'}>{title}</Div>
            <Div className={'TIMIIcon'}>{icon}</Div>
        </Div>
        {button && button}
    </Div>
);

TitleIcon.propTypes = {
    customClass: PropTypes.string,
    title: PropTypes.string,
    icon: PropTypes.element,
    button: PropTypes.element,
};

export default TitleIcon;
