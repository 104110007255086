import React from 'react';

import { SVGIcon } from '../../UIKit/index';

export default function DCUserComment(props) {
    return (
        <SVGIcon {...props} viewBox="0 0 512 512">
            <ellipse cx="138.9" cy="87.2" rx="86" ry="86" />
            <path d="M0.5,312.3c0,16,13,29,29,29c0,0,0,0,0,0h233.8c16,0,29-13,29-29l0,0  c-0.1-80-64.9-144.8-144.9-144.9C66.9,167.3,1.5,231.9,0.5,312.3z" />
            <path d="M511.2,220.3V99.6c0-8.3-6.7-15.1-15-15.1c0,0,0,0-0.1,0H268.3c1,5,1.5,10,1.5,15.1  c-0.1,13.4-3.7,26.5-10.6,38c25.6,19.8,40.6,50.3,40.8,82.6c0,5.2-1,10.3-2.8,15.1h198.9C504.4,235.3,511.2,228.6,511.2,220.3z   M345.2,175c-8.3,0-15.1-6.8-15.1-15.1c0-8.3,6.8-15.1,15.1-15.1c8.3,0,15.1,6.8,15.1,15.1C360.4,168.3,353.6,175,345.2,175  C345.3,175.1,345.3,175.1,345.2,175L345.2,175z M405.6,175c-8.3,0-15.1-6.8-15.1-15.1c0-8.3,6.8-15.1,15.1-15.1  c8.3,0,15.1,6.8,15.1,15.1C420.7,168.3,414,175,405.6,175C405.6,175.1,405.6,175.1,405.6,175L405.6,175z M465.9,175  c-8.3,0-15.1-6.8-15.1-15.1c0-8.3,6.8-15.1,15.1-15.1c8.3,0,15.1,6.8,15.1,15.1C481,168.3,474.3,175,465.9,175  C465.9,175.1,465.9,175.1,465.9,175L465.9,175z" />
        </SVGIcon>
    );
}
