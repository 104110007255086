import React, { useReducer } from 'react';

import { instaSiteActions } from '../Actions/index';
import { InstaSiteContext } from '../Context/index';
import { instaSiteReducer } from '../Reducer/index';
import { instaSiteStore } from '../Store/index';

const InstaSiteContextProvider = props => {
    const [state, dispatch] = useReducer(instaSiteReducer, instaSiteStore);

    const value = {
        templates: state.templates,
        categories: state.categories,
        mainCategory: state.mainCategory,
        initialTemplateLoad: state.initialTemplateLoad,
        activeCategory: state.activeCategory,
        templateSearchText: state.templateSearchText,

        status: state.status,
        initialMyInstasiteLoad: state.initialMyInstasiteLoad,
        fields: state.fields,
        page: state.page,
        totalResults: state.totalResults,
        totalPages: state.totalPages,
        pageLimit: state.pageLimit,
        data: state.data,
        sortString: state.sortString,
        myInstaSiteSearchText: state.myInstaSiteSearchText,

        selectedInstaSite: state.selectedInstaSite,
        statusString: state.statusString,
        additionalInfo: state.additionalInfo,

        setTemplates: value => {
            dispatch({
                type: instaSiteActions.SET_TEMPLATES_DATA,
                value,
            });
        },
        setCategories: value => {
            dispatch({
                type: instaSiteActions.SET_CATEGORIES,
                value,
            });
        },
        setMainCategory: value => {
            dispatch({
                type: instaSiteActions.SET_MAIN_CATEGORIES,
                value,
            });
        },
        setInitialTemplateLoad: value => {
            dispatch({
                type: instaSiteActions.SET_INITIAL_TEMPLATE_LOAD,
                value,
            });
        },
        setActiveCategory: value => {
            dispatch({ type: instaSiteActions.SET_ACTIVE_CATEGORY, value });
        },
        setTemplateSearchText: value => {
            dispatch({
                type: instaSiteActions.SET_TEMPLATE_SEARCH_TEXT,
                value,
            });
        },

        setStatus: value => {
            dispatch({
                type: instaSiteActions.SET_STATUS,
                value,
            });
        },
        setMyInstaSiteInitialLoad: value => {
            dispatch({
                type: instaSiteActions.SET_INITIAL_MY_INSTASITE_LOAD,
                value,
            });
        },
        setFields: value => {
            dispatch({
                type: instaSiteActions.SET_FIELDS,
                value,
            });
        },
        setPage: value => {
            dispatch({
                type: instaSiteActions.SET_PAGE,
                value,
            });
        },
        setTotalResults: value => {
            dispatch({
                type: instaSiteActions.SET_TOTAL_RESULTS,
                value,
            });
        },
        setTotalPages: value => {
            dispatch({
                type: instaSiteActions.SET_TOTAL_PAGES,
                value,
            });
        },
        setPageLimit: value => {
            dispatch({
                type: instaSiteActions.SET_PAGE_LIMIT,
                value,
            });
        },
        setData: value => {
            dispatch({
                type: instaSiteActions.SET_DATA,
                value,
            });
        },
        setSortString: value => {
            dispatch({
                type: instaSiteActions.SET_SORT_STRING,
                value,
            });
        },
        setMyInstaSiteSearch: value => {
            dispatch({
                type: instaSiteActions.SET_MY_INSTASITE_SEARCH_TEXT,
                value,
            });
        },
        setSelectedInstaSite: value => {
            dispatch({
                type: instaSiteActions.SET_SELECTED_INSTASITE,
                value,
            });
        },
        setStatusString: value => {
            dispatch({
                type: instaSiteActions.SET_STATUS_STRING,
                value,
            });
        },
        setAdditionalInfo: value => {
            dispatch({
                type: instaSiteActions.SET_ADDITIONAL_INFO,
                value,
            });
        },
    };

    return <InstaSiteContext.Provider value={value}>{props.children}</InstaSiteContext.Provider>;
};

export default InstaSiteContextProvider;
