import React from 'react';

import { SVGIcon } from '../../UIKit/index';

export default function DCBoxChange(props) {
    return (
        <SVGIcon {...props} viewBox="0 0 385 512">
            <g>
                <path d="M155.49,18.84C155.48,8.98,147.48,0.99,137.62,1H18.92C9.04,1,1.01,8.96,0.93,18.84v118.8     c0.01,9.86,8.01,17.85,17.87,17.84c0.04,0,0.08,0,0.11,0h118.8c9.88,0,17.9-7.96,17.99-17.84V18.84H155.49z M119.78,119.7H36.76     V36.68h83.03V119.7z" />
                <path d="M137.74,179.15H18.92c-9.88,0-17.9,7.96-17.99,17.84v118.92c0.01,9.86,8.01,17.85,17.87,17.84     c0.04,0,0.08,0,0.11,0h118.8c9.88,0,17.9-7.96,17.99-17.84V196.99C155.62,187.12,147.61,179.16,137.74,179.15z M119.75,297.95     H36.76v-83.03h83.03L119.75,297.95z" />
                <path d="M18.92,512.01h118.8c9.88,0,17.9-7.96,17.99-17.84v-41.63h210.92     c9.86-0.01,17.85-8.01,17.84-17.87c0-0.04,0-0.08,0-0.11V76c0-9.88-7.96-17.9-17.84-17.99H259.04l7.19-7.19     c7.6-6.4,8.56-17.75,2.16-25.34c-6.4-7.6-17.75-8.56-25.34-2.16c-0.78,0.66-1.5,1.38-2.16,2.16l-37.75,37.87     c-6.96,7.01-6.96,18.33,0,25.34l37.61,37.61c7.09,6.87,18.42,6.7,25.29-0.4c6.74-6.96,6.72-18.01-0.05-24.94l-7.19-7.19h89.63     v322.92H155.49v-41.51c0-9.93-8.05-17.99-17.99-17.99H18.92c-9.93,0-17.99,8.05-17.99,17.99v118.8     c-0.04,9.93,7.99,18.01,17.92,18.05C18.87,512.01,18.9,512.01,18.92,512.01z M36.76,393.21h83.03v83.03H36.76V393.21z" />
            </g>
        </SVGIcon>
    );
}
