import React, { memo } from 'react';

import { Div } from '../index';
import PropTypes from 'prop-types';
import './style.scss';

const TabWithTitleNumber = ({ active, customClass, onClick, title, number }) => {
    return (
        <Div
            className={
                'TabWithTitleNumber ' +
                (active === true ? 'active ' : '') +
                (customClass ? customClass : '')
            }
            onClick={onClick}
        >
            <Div className="TWTNTitle">{title}</Div>
            {number ? <Div className="TWTNTNumber">{number}</Div> : null}
        </Div>
    );
};

TabWithTitleNumber.propTypes = {
    active: PropTypes.bool,
    customClass: PropTypes.string,
    onClick: PropTypes.func,
    title: PropTypes.string,
    number: PropTypes.string,
};

export default memo(TabWithTitleNumber);
// <TabWithTitleNumber
//      title={"title"}
//      number={"10"}
//      active={true}
//      customClass={"any class name"}
//      onClick={handle onClick}
// />
