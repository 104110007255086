import React from 'react';

import { SVGIcon } from '../../UIKit/index';

export default function DCContent(props) {
    return (
        <SVGIcon {...props} viewBox="0 0 512 512">
            <g transform="translate(0.1 0.1)">
                <g>
                    <g>
                        <path d="M492.5,1.9H19.3C9.5,1.9,1.6,9.8,1.6,19.6c0,0,0,0,0,0v443.5c0,9.8,7.9,17.7,17.7,17.7         c0,0,0,0,0,0h473.1c9.8,0,17.7-7.9,17.7-17.7l0,0V19.6C510.2,9.8,502.3,1.9,492.5,1.9z M474.7,445.4H37.1v-408h437.6         L474.7,445.4L474.7,445.4z" />
                    </g>
                    <g transform="translate(0.824 4.944)">
                        <g>
                            <rect x="18.5" y="103.4" width="473.1" height="35.5" />
                        </g>
                    </g>
                    <g transform="translate(5.247 2.225)">
                        <g>
                            <circle cx="132.4" cy="70.6" r="23.1" />
                        </g>
                    </g>
                    <g transform="translate(7.993 2.225)">
                        <g>
                            <circle cx="188.7" cy="70.6" r="23.1" />
                        </g>
                    </g>
                    <g transform="translate(2.5 2.225)">
                        <g>
                            <circle cx="76" cy="70.6" r="23.1" />
                        </g>
                    </g>
                    <g transform="translate(3.873 9.669)">
                        <g>
                            <path d="M405.2,200.4H98.8c-9.8,0-17.7,8-17.7,17.8c0,9.8,7.9,17.7,17.7,17.7h306.4         c9.8,0,17.8-7.9,17.8-17.7C423,208.4,415.1,200.4,405.2,200.4C405.3,200.4,405.3,200.4,405.2,200.4z" />
                        </g>
                    </g>
                    <g transform="translate(3.873 12.965)">
                        <g>
                            <path d="M405.2,268.1H98.8c-9.8,0-17.7,7.9-17.7,17.7s7.9,17.7,17.7,17.7h306.4         c9.8,0,17.7-7.9,17.7-17.7S415,268.1,405.2,268.1z" />
                        </g>
                    </g>
                    <g transform="translate(3.873 16.262)">
                        <g>
                            <path d="M405.2,335.8H98.8c-9.8,0-17.7,7.9-17.7,17.7c0,9.8,7.9,17.7,17.7,17.7h306.4         c9.8,0,17.7-7.9,17.7-17.7C423,343.7,415,335.8,405.2,335.8z" />
                        </g>
                    </g>
                </g>
            </g>
        </SVGIcon>
    );
}
