import { CallAPI } from 'Utils/apiCall';
import { getAuthDetails } from 'Utils/localStorageUtils';
import config from 'config';

const baseURl = `${config.REACT_APP_API_BASE_URL}/${process.env.REACT_APP_API_VERSION}`;
const mainURL = `${baseURl}/${process.env.REACT_APP_API_INBOUND}`;
const callrailUrl = `${baseURl}/${process.env.REACT_APP_API_INTEGRATIONS}/${process.env.REACT_APP_API_CALLRAIL}`;
const facebookUrl = `${baseURl}/${process.env.REACT_APP_API_INTEGRATIONS}/facebook/accounts`;
const api = new CallAPI();

// My leads
const filterDataApi = new CallAPI();
export const getLeadsFilterData = async (date, search, filter) => {
    filterDataApi.cancelAllRequests();
    let url = `${mainURL}/leads/getfilters?startdate=${encodeURIComponent(
        date.startDate.toString(),
    )}&enddate=${encodeURIComponent(date.endDate.toString())}`;
    if (search) url += `&searchtext=${search}`;
    if (filter) url += `&${filter}`;
    return filterDataApi.request('GET', url);
};

const leadsApi = new CallAPI();
export const fetchLeads = async (date, search, filter, sortinginfo, limit, page) => {
    leadsApi.cancelAllRequests();
    let url = `${mainURL}/leads?startdate=${encodeURIComponent(
        date.startDate.toString(),
    )}&enddate=${encodeURIComponent(date.endDate.toString())}`;
    if (search) url += `&searchtext=${search}`;
    if (filter) url += `&${filter}`;
    if (sortinginfo && sortinginfo.field)
        url += `&sort_by=${sortinginfo.field}&order=${sortinginfo.order}`;
    return leadsApi.request('GET', url, null, limit, page);
};

// delete leads
export const deleteLeads = async (selectedLeads, deleteAll) => {
    if (deleteAll) return api.request('DELETE', `${mainURL}/leads?deleteAll=true`);
    else return api.request('DELETE', `${mainURL}/leads`, { leads: selectedLeads });
};

// add contact in crm
export const addToCRM = async data => {
    return api.request('POST', `${mainURL}/leads/addtocrm`, data);
};

// campaigns

export const retryInboundInstaLead = async id => {
    return api.request('GET', `${mainURL}/leads/${id}/rebuild`);
};

//  campaigns filters
export const getCampaignsFilters = async (filter, search = '') => {
    filterDataApi.cancelAllRequests();
    let url = `${mainURL}/campaigns/getcampaignfilters`;
    if (search) {
        url += `?searchtext=${search}`;
    }
    if (search && filter) url += '&';
    else if (filter) url += '?';
    if (filter) {
        url += `${filter}`;
    }
    return filterDataApi.request('GET', url);
};

// get All campaigns
export const getCampaigns = async (next = 1, pageSize = 5, search = '', filter = '') => {
    leadsApi.cancelAllRequests();
    let url = `${mainURL}/campaigns?limit=${pageSize}`;
    if (next) {
        url += `&next=${next}`;
    }
    if (search) {
        url += `&searchtext=${search}`;
    }
    if (filter) {
        url += `&${filter}`;
    }
    return leadsApi.request('GET', url);
};

// callrail authentication
export const callrailAuth = async (data, subAccount) => {
    let url = `${callrailUrl}/auth`;
    if (subAccount) {
        url += `?subaccountid=${subAccount}`;
    }
    return api.request('POST', url, data);
};
// Select property step apis
export const fetchFacebookLeadPagesData = async () => {
    return api.request('GET', `${facebookUrl}?fields=name,id,access_token`);
};

export const getLeadPageAds = async (facebookPageId, facebookPageAccesstoken) => {
    return api.request(
        'GET',
        `${facebookUrl}/getleadforms/${facebookPageId}?pageaccesstoken=${facebookPageAccesstoken}`,
    );
};

export const getAllAccounts = async (id, page, limit = 25, subAccount = null) => {
    let url = `${callrailUrl}/${id}/accounts`;
    if (subAccount) {
        url += `?subaccountid=${subAccount}`;
    }
    return api.request('GET', url, null, limit, page);
};

export const fetchExistingTrackingNumbers = async type => {
    let params = 'type=callrail';
    if (type) params = 'type=calltrackingmetrics';
    return api.request('GET', `${mainURL}/campaigns/getaddedtrackingnumbers?${params}`);
};

export const fetchCallrailCompanies = async (
    id,
    page = 1,
    accountId,
    search = '',
    limit = 25,
    subAccount = null,
) => {
    const url = `${callrailUrl}/${id}/accounts/${accountId}/companies`;
    let params = {};
    if (search) {
        params.search = search;
    }
    if (subAccount) {
        params.subaccountid = subAccount;
    }
    return api.request('GET', url, null, limit, page, params);
};

export const fetchCallrailTrackingNumbers = async (id, page, accountId, comapnyId, limit = 25) => {
    let url = `${callrailUrl}/${id}/accounts/${accountId}/companies/${comapnyId}/trackers`;
    return api.request('GET', url, null, limit, page);
};

// get All pipelines
export const getPipelines = async (page = 1, pageSize = 5, search = '') => {
    let url = `${config.REACT_APP_API_BASE_URL}/${process.env.REACT_APP_API_VERSION}/${process.env.REACT_APP_API_CRM}/${process.env.REACT_APP_API_PIPELINES}`;
    if (search) {
        url += `?q=${search}`;
    }
    return api.request('GET', url, null, pageSize, page);
};

// get All users
export const getUsers = async (limit, nextpage, search, type, account) => {
    const userListUrl = `${config.REACT_APP_API_BASE_URL}/${process.env.REACT_APP_API_VERSION}/${process.env.REACT_APP_API_USERS}`;
    const searchUserUrl = `${userListUrl}/${process.env.REACT_APP_API_SEARCH}`;
    let query = {};
    if (type === 'pending') query.pending = true;
    if (type === 'archived') query.archived = true;
    if (account) query.account = account;
    let response = '';
    if (search) {
        response = api.request('POST', searchUserUrl, { search }, limit, nextpage, query);
    } else {
        response = api.request('GET', userListUrl, null, limit, nextpage, query);
    }
    return response;
};

// add new contact for reps
export const addNewContact = async (id, data) => {
    return api.request('PUT', `${baseURl}/${process.env.REACT_APP_API_USERS}/${id}/phones`, data);
};

export const newEditContact = async (userid, phoneid, body) => {
    return api.request(
        'PUT',
        `${baseURl}/${process.env.REACT_APP_API_USERS}/${userid}/phones/${phoneid}`,
        body,
    );
};

// automate facebook webhhook
export const createfacebookWebhook = async id => {
    try {
        const response = await api.request('POST', `${facebookUrl}/leadforms/createwebhook`, {
            campaign_id: id,
        });
        if (response.success) {
            return 'success';
        }
    } catch (error) {
        return 'error';
    }
};
// automate callrail webhhook
export const createCallRailWebhook = async (callRailAccountID, accountId, companyId, id) => {
    try {
        const authData = getAuthDetails();
        const xAccountID = authData.account.id;
        const response = api.request(
            'POST',
            `${callrailUrl}/${callRailAccountID}/accounts/${accountId}/companies/${companyId}/webhook`,
            {
                webhookdata: {
                    type: 'post_call_webhook',
                    urls: [
                        `${config.REACT_APP_API_BASE_URL}/${process.env.REACT_APP_API_VERSION}/${process.env.REACT_APP_API_INBOUND}/${process.env.REACT_APP_API_CALLRAIL}/webhook/${xAccountID}/${id}`,
                    ],
                },
            },
        );
        if (response.success) {
            return 'success';
        }
    } catch (error) {
        return 'error';
    }
};

// create new campaign
export const saveCampaignData = async data => {
    try {
        const saveCampaignInfo = await api.request('POST', `${mainURL}/campaigns`, {
            campaigndata: data,
        });
        if (saveCampaignInfo.success) {
            return saveCampaignInfo.data[0];
        }
    } catch (error) {
        return 'Something went wrong! Please try Again';
    }
};

// edit campign
export const modifyCampaign = async (data, id) => {
    try {
        const modifyCampaignInfo = await api.request('PUT', `${mainURL}/campaigns/${id}`, {
            campaigndata: data,
        });
        if (modifyCampaignInfo.success) {
            return modifyCampaignInfo.data[0];
        }
    } catch (error) {
        return 'Failed to edit the campaign. Please try again';
    }
};

// Reporting
const filterapi = new CallAPI();
export const fetchReportFilters = async date => {
    filterapi.cancelAllRequests();
    let url = `${mainURL}/reports/${process.env.REACT_APP_API_INBOUND_REPORT_FILTERS}`;
    if (date && date.startDate) {
        url += `?startdate=${encodeURIComponent(date.startDate)}&enddate=${encodeURIComponent(
            date.endDate,
        )}`;
    }
    return filterapi.request('GET', url);
};

const reportapi = new CallAPI();
export const fetchReportData = async (date = {}, leadType = [], integrations = [], reps = []) => {
    reportapi.cancelAllRequests();
    let url = `${mainURL}/reports`;
    if (date && date.startDate) {
        url += `?startdate=${encodeURIComponent(date.startDate)}&enddate=${encodeURIComponent(
            date.endDate,
        )}`;
    }
    if (leadType && leadType.length) {
        leadType.map(x => (url += `&type[]=${x}`));
    }
    if (integrations && integrations.length) {
        integrations.map(x => (url += `&integration[]=${x}`));
    }
    if (reps && reps.length) {
        reps.map(x => (url += `&reps[]=${x}`));
    }
    return reportapi.request('GET', url);
};

const repsapi = new CallAPI();
export const fetchInboundReportsReps = async (
    date = {},
    leadType = [],
    integrations = [],
    reps = [],
    page = 1,
    limit = 25,
    sorting = {},
) => {
    repsapi.cancelAllRequests();
    let url = `${mainURL}/reports/${process.env.REACT_APP_API_INBOUND_REPORT_REPS}`;
    if (date && date.startDate) {
        url += `?startdate=${encodeURIComponent(date.startDate)}&enddate=${encodeURIComponent(
            date.endDate,
        )}`;
    }
    if (leadType && leadType.length) {
        leadType.map(x => (url += `&type[]=${x}`));
    }
    if (integrations && integrations.length) {
        integrations.map(x => (url += `&integration[]=${x}`));
    }
    if (reps && reps.length) {
        reps.map(x => (url += `&reps[]=${x}`));
    }
    if (sorting && sorting?.field) {
        url += `&sort=${sorting.field}&order=${sorting.order}`;
    }
    return repsapi.request('GET', url, null, limit, page);
};
