import React from 'react';

import { SVGIcon } from '../../UIKit/index';

export default function DCBell(props) {
    return (
        <SVGIcon {...props} viewBox="0 0 21.703 26.044">
            <path
                d="M158.013,345.673a4.341,4.341,0,1,1-4.341-4.341A4.34,4.34,0,0,1,158.013,345.673Zm0,0"
                transform="translate(-142.821 -323.97)"
            />
            <path
                d="M214.417,4.341a1.086,1.086,0,0,1-1.085-1.085V1.085a1.085,1.085,0,0,1,2.17,0v2.17A1.085,1.085,0,0,1,214.417,4.341Zm0,0"
                transform="translate(-203.566)"
            />
            <path
                d="M42.362,58.849a7.266,7.266,0,0,1-2.582-5.56V50.263a7.6,7.6,0,0,0-15.192,0v3.025A7.277,7.277,0,0,1,22,58.859,1.9,1.9,0,0,0,23.231,62.2H41.136a1.9,1.9,0,0,0,1.225-3.351Zm0,0"
                transform="translate(-21.332 -40.497)"
            />
        </SVGIcon>
    );
}
