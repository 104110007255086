import React from 'react';

import { SVGIcon } from '../../UIKit/index';

export default function DCSubmission(props) {
    return (
        <SVGIcon {...props} viewBox="0 0 512 394">
            <g transform="translate(-30.445 -40.247)">
                <rect fill="#FCFBFF" x="31.34" y="77.54" width="509.6" height="356.72" />
                <rect fill="#EDE4FF" x="287.44" y="77.54" width="253.5" height="356.72" />
                <path fill="#EDE4FF" d="M171.86,41.25h228.55v57.11H171.86V41.25z" />
                <rect fill="#6E1EFF" x="287.44" y="41.25" width="112.99" height="57.14" />
                <rect fill="#6E1EFF" x="82.3" y="172.51" width="114.65" height="152.88" />
                <g transform="translate(65.421 69.659)">
                    <rect fill="#6E1EFF" x="169.76" y="143" width="254.8" height="9.27" />
                    <rect fill="#6E1EFF" x="169.76" y="178.89" width="254.8" height="9.27" />
                    <rect fill="#6E1EFF" x="169.76" y="214.8" width="254.8" height="9.27" />
                </g>
            </g>
        </SVGIcon>
    );
}
