import React from 'react';

import { SVGIcon } from '../../UIKit/index';
import ReviewReply from 'Assets/images/ReviewReply.svg';

const backgroundStyle = {
    backgroundImage: 'url(' + ReviewReply + ')',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
};
export default function DCAutoCreateDeals(props) {
    return <SVGIcon {...props} viewBox="0 0 512 512" style={backgroundStyle} />;
}
