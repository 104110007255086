import React from 'react';

import { SVGIcon } from '../../UIKit/index';

export default function DCCursor(props) {
    return (
        <SVGIcon {...props} viewBox="0 0 12.027 12.028">
            <g>
                <path
                    d="M83.112,81.365l-2.394-2.394,1.708-.986a.4.4,0,0,0-.052-.722l-7.615-3.038a.4.4,0,0,0-.523.523l3.037,7.616a.4.4,0,0,0,.722.052l.986-1.708L81.375,83.1a.4.4,0,0,0,.569,0l1.168-1.168A.4.4,0,0,0,83.112,81.365Zm-1.452.884-2.48-2.48a.4.4,0,0,0-.633.083L77.714,81.3,75.331,75.32,81.306,77.7l-1.444.834a.4.4,0,0,0-.083.633l2.48,2.48Z"
                    transform="translate(-71.203 -71.192)"
                />
                <path
                    d="M27.34,26.763a.4.4,0,0,0-.569.569l.894.894a.4.4,0,1,0,.569-.569Z"
                    transform="translate(-25.575 -25.566)"
                />
                <path
                    d="M2.081,88.686a.4.4,0,0,0-.4-.4H.415a.4.4,0,1,0,0,.8H1.679A.4.4,0,0,0,2.081,88.686Z"
                    transform="translate(-0.013 -84.709)"
                />
                <path
                    d="M23.094,126.079l-.894.894a.4.4,0,1,0,.569.569l.894-.894a.4.4,0,0,0-.569-.569Z"
                    transform="translate(-21.188 -120.86)"
                />
                <path
                    d="M88.693,2.069a.4.4,0,0,0,.4-.4V.4a.4.4,0,1,0-.8,0V1.666A.4.4,0,0,0,88.693,2.069Z"
                    transform="translate(-84.716)"
                />
                <path
                    d="M126.364,23.771a.4.4,0,0,0,.284-.118l.894-.894a.4.4,0,0,0-.569-.569l-.894.894a.4.4,0,0,0,.284.687Z"
                    transform="translate(-120.861 -21.179)"
                />
            </g>
        </SVGIcon>
    );
}
