import React from 'react';

import { SVGIcon } from '../../UIKit/index';

export default function DCPurchase(props) {
    return (
        <SVGIcon {...props} viewBox="0 0 512 512">
            <g>
                <path d="m290.491 328.591-51.138-37.843c-11.107-8.218-5.275-25.955 8.56-25.955h16.072c7.934 0 14.389 6.455 14.389 14.389 0 8.284 6.716 15 15 15s15-6.716 15-15c0-22.089-16.22-40.456-37.374-43.829v-16.558c0-8.284-6.716-15-15-15s-15 6.716-15 15v16.539c-21.204 3.33-37.476 21.723-37.476 43.847 0 14.01 6.723 27.348 17.983 35.681l51.137 37.842c6.957 5.149 7.913 15.534 1.682 21.846-5.272 5.341-9.76 4.278-26.198 4.378-.03 0-.06 0-.09 0-7.893 0-14.339-6.396-14.387-14.299-.051-8.253-6.757-14.908-14.998-14.908-.031 0-.063 0-.094 0-8.284.051-14.959 6.808-14.908 15.092.136 21.987 16.316 40.184 37.349 43.551v16.562c0 8.284 6.716 15 15 15s15-6.716 15-15v-16.63c21.201-3.296 37.626-21.727 37.475-44.14l-.001-.187c-.094-13.887-6.817-27.113-17.983-35.378z" />
                <path d="m260.461 0h-8.922c-43.296 0-78.521 35.224-78.521 78.52v53.204h-60.405c-7.725 0-14.187 5.867-14.931 13.556l-33.873 350.276c-.853 8.812 6.084 16.444 14.93 16.444h354.521c8.853 0 15.782-7.638 14.931-16.444l-33.875-350.276c-.744-7.689-7.206-13.556-14.931-13.556h-60.404v-53.204c0-43.296-35.224-78.52-78.52-78.52zm-57.442 78.52c0-26.754 21.767-48.52 48.521-48.52h8.922c26.754 0 48.521 21.766 48.521 48.52v53.204h-105.964zm213.721 403.48h-321.48l30.973-320.276h259.534z" />
            </g>
        </SVGIcon>
    );
}
