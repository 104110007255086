import React from 'react';

import { SVGIcon } from '../../UIKit/index';

export default function DCTag3(props) {
    return (
        <SVGIcon {...props} viewBox="0 0 512 512">
            <path d="M499.4,272.3L258.3,31.6c-9.9-9.4-21.6-16.8-34.4-21.7c-12.4-5.5-25.8-8.6-39.4-8.9H44.2  C32.8,0.8,21.8,5.5,13.9,13.7C5.6,21.6,0.9,32.6,1,44v140.3c0.4,13.6,3.4,27,8.9,39.4c4.9,12.7,12.3,24.3,21.7,34.1l241.2,241.4  c8,8.2,18.9,12.7,30.3,12.5c11.5,0.1,22.5-4.4,30.7-12.5l165.6-165.9c8.2-8,12.7-18.9,12.5-30.3C512,291.5,507.5,280.5,499.4,272.3z   M139.5,139.3c-8,8.3-19,12.8-30.5,12.7c-23.8,0-43.1-19.4-43.1-43.2c0.1-23.8,19.4-43,43.1-43c23.8,0,43.1,19.3,43.1,43.1  C152.3,120.3,147.7,131.3,139.5,139.3z" />
        </SVGIcon>
    );
}
