import React, { Fragment, useCallback, useState } from 'react';

import { Div, H3, H6, Image, Button, Span } from '../../UIKit/index';

import browserSignup from 'Assets/images/browser_sign.png';
import browserSignupArrowPointer from 'Assets/images/browser_sign_arrowpointer.png';
import './style.scss';
import { Redirect, useHistory } from 'react-router-dom';
import { sendSubAccountEmail } from 'apis/login/index.api';
import useToast from 'Modules/Toasts';
import { Emailverifyimage } from './constant';

const SignupNext = () => {
    const history = useHistory();
    const [resendLinkDisabled, setResendLinkDisabled] = useState(false);
    const showToast = useToast();

    const handleResendVerificationEmail = useCallback(async () => {
        try {
            setResendLinkDisabled(true);
            let body = {
                email: history.location.state?.email,
                parent_account: history.location.state.parent_account,
            };
            const res = await sendSubAccountEmail(body);
            if (res.success) {
                showToast({
                    type: 'success',
                    message: 'Email is rensent to your email account',
                });
                setResendLinkDisabled(false);
            }
        } catch (err) {
            showToast({
                type: 'error',
                message: err || err?.message || 'Something went wrong!',
            });
            setResendLinkDisabled(false);
        }
    }, [history, showToast]);
    return (
        <Fragment>
            {history.location && history.location?.state?.emailVerified ? (
                <Div className="SignupNextPage">
                    <Div className="SNPBody">
                        <Div className="SNPBVerificationDetails">
                            <H3 className="SNPBVDHeading">
                                Almost done, just verify your email...
                            </H3>
                            <H6 props={{ className: 'SNPBVDSubHeading' }}>
                                Check your inbox and click the link to verify your email
                            </H6>
                            <H6 props={{ className: 'SNPBVDSubHeading--important' }}>
                                If you're unable to find the email in your inbox, don't forget to
                                check your spam folder just in case!
                            </H6>
                            <Div className="SUPIFSISEmailVerifyImage">
                                <Image src={Emailverifyimage[0]?.image} />
                                <Span>Verify</Span>
                            </Div>
                            <Div className="SUPIFSISEmailResend">
                                Didn't get the email ?
                                <Button
                                    buttonType={'SimpleLink'}
                                    disabled={resendLinkDisabled}
                                    onClick={handleResendVerificationEmail}
                                    style={{ color: 'blue', textDecoration: 'none' }}
                                >
                                    Resend
                                </Button>
                            </Div>
                        </Div>

                        <Div className="SNPBVerificationImage">
                            <Div className="SNPBVIEmailPreview">
                                <Image src={browserSignup} className={'SNPBVIEmailPreview--img'} />

                                <Div className="SNPBVIEPArrowPointer">
                                    <Image src={browserSignupArrowPointer} />
                                </Div>
                            </Div>
                        </Div>
                    </Div>
                </Div>
            ) : (
                <Redirect to="/" />
            )}
        </Fragment>
    );
};

export default SignupNext;
