import React from 'react';

import { SVGIcon } from '../../UIKit/index';

export default function DCUserInfo1(props) {
    return (
        <SVGIcon {...props} viewBox="0 0 22.602 18.835">
            <path
                d="M20.012,59.619H2.59A2.592,2.592,0,0,1,0,57.029V45.258a2.592,2.592,0,0,1,2.59-2.59H8.24a.706.706,0,0,1,0,1.413H2.59a1.179,1.179,0,0,0-1.177,1.177V57.029A1.179,1.179,0,0,0,2.59,58.207H20.012a1.179,1.179,0,0,0,1.177-1.177V45.258a1.179,1.179,0,0,0-1.177-1.177h-5.65a.706.706,0,1,1,0-1.413h5.65a2.592,2.592,0,0,1,2.59,2.59V57.029A2.592,2.592,0,0,1,20.012,59.619Zm0,0"
                transform="translate(0 -40.784)"
            />
            <path
                d="M176.554,5.18h-4.238a1.65,1.65,0,0,1-1.648-1.648V1.648A1.65,1.65,0,0,1,172.316,0h4.238A1.65,1.65,0,0,1,178.2,1.648V3.532A1.65,1.65,0,0,1,176.554,5.18Zm-4.238-3.767a.235.235,0,0,0-.235.235V3.532a.235.235,0,0,0,.235.235h4.238a.235.235,0,0,0,.235-.235V1.648a.235.235,0,0,0-.235-.235Zm0,0"
                transform="translate(-163.134 0)"
            />
            <path
                d="M109.022,154.041a2.354,2.354,0,1,1,2.354-2.354A2.358,2.358,0,0,1,109.022,154.041Zm0-3.3a.942.942,0,1,0,.942.942A.943.943,0,0,0,109.022,150.745Zm0,0"
                transform="translate(-101.959 -142.74)"
            />
            <path
                d="M71.769,281.1a.707.707,0,0,1-.706-.706v-.471a1.179,1.179,0,0,0-1.177-1.177h-3.3a1.179,1.179,0,0,0-1.177,1.177v.471a.706.706,0,0,1-1.413,0v-.471a2.592,2.592,0,0,1,2.59-2.59h3.3a2.592,2.592,0,0,1,2.59,2.59v.471A.707.707,0,0,1,71.769,281.1Zm0,0"
                transform="translate(-61.175 -265.089)"
            />
            <path
                d="M304.554,246.745h-5.18a.706.706,0,0,1,0-1.413h5.18a.706.706,0,1,1,0,1.413Zm0,0"
                transform="translate(-285.483 -234.502)"
            />
        </SVGIcon>
    );
}
