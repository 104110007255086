import React from 'react';

import { SVGIcon } from '../../UIKit/index';

export default function DCAverage(props) {
    return (
        <SVGIcon {...props} viewBox="0 0 512 432">
            <path d="M420.1,3.4H44.9C24.1,6.7,7.6,22.9,4,43.6v375c2.9,21.8,20.2,38.8,42,41.4h420.2  c21.2-2.2,38.3-18.4,41.8-39.4v-375C505.6,22.7,487.1,5,464.2,3.4L460.9,3h-63.2v32h78.5v393.5H35.5V35.2h384.3L420.1,3.4z" />
            <rect x="93.4" y="233.8" width="39.1" height="119" />
            <rect x="187.6" y="92.5" width="39.1" height="260.2" />
            <rect x="281.8" y="163.1" width="39.1" height="189.6" />
            <rect x="376" y="92.5" width="39.1" height="260.2" />
        </SVGIcon>
    );
}
