import React, { useReducer } from 'react';

import editorActions from './Action';
import EditorContext from './context';
import editorReducer from './Reducer';
import intialStore from './store';

const EditorContextProvider = props => {
    const [state, dispatch] = useReducer(editorReducer, intialStore);

    const value = {
        fallbackEntity: state.fallbackEntity,
        editorState: state.editorState,
        setfallbackEntity: value => {
            dispatch({ type: editorActions.SET_FALLBACK_ENTITY, value });
        },
        setEditorState: value => {
            dispatch({ type: editorActions.SET_EDITOR_STATE, value });
        },
    };

    return <EditorContext.Provider value={value}>{props.children}</EditorContext.Provider>;
};

export default EditorContextProvider;
