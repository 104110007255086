import { setTitle } from 'Utils';

export const afterLogin = auth => {
    const authObj = auth;
    if (authObj?.account.name) {
        setTitle(authObj.account.name);
    }

    if (authObj?.account.branding) {
        if (document.getElementsByClassName('topBar').length) {
            let item;
            for (item of document.getElementsByClassName('topBar')) {
                item.style.backgroundColor = authObj?.auth?.account.branding.colors.secondary;
            }
        }
    }
};
