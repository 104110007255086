import React from 'react';

import { SVGIcon } from '../../UIKit/index';

export default function DCFillWeb(props) {
    return (
        <SVGIcon {...props} viewBox="0 0 25.123 22.776">
            <path
                id="Path_1862"
                data-name="Path 1862"
                d="M25.123,80.356V98.172a1.1,1.1,0,0,1-1.153,1.045H1.153A1.1,1.1,0,0,1,0,98.172V80.356A.375.375,0,0,1,.393,80H24.73A.375.375,0,0,1,25.123,80.356Z"
                transform="translate(0 -80)"
                fill="#00a0fb"
            />
        </SVGIcon>
    );
}
