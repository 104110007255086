import React from 'react';

import { SVGIcon } from '../../UIKit/index';

export default function DCScores(props) {
    return (
        <SVGIcon {...props} viewBox="0 0 512 379">
            <g>
                <path d="M265.41,268.05c11.51,0,20.84-9.33,20.84-20.84s-9.33-20.84-20.84-20.84l-94.4-69.44    l73.58,91.59C245.3,259.49,254.41,268.03,265.41,268.05z" />
                <rect x="152.45" y="329.6" width="208.12" height="49.4" />
                <path d="M436.91,74.85c-99.77-99.58-261.37-99.43-360.95,0.34c-80.3,80.45-97.72,204.31-42.73,303.79    h47.72l34.88-20.09l-15.39-26.67l-44.88,25.84c-16.34-31.79-24.84-67.02-24.81-102.76c0-6.55,0.3-13.03,0.85-19.44l53.49,9.39    l5.35-30.34l-54.09-9.54c9.57-41.95,30.94-80.29,61.58-110.5l36.67,43.7l23.61-19.81l-36.97-44.07    C156.02,48.5,197.66,33,241.1,30.07v58.44h30.81V30.07c43.43,2.93,85.08,18.43,119.87,44.6l-36.94,44.02l23.61,19.81l36.67-43.7    c30.64,30.21,52.01,68.55,61.58,110.5l-54.09,9.54l5.3,30.39l53.47-9.44c0.55,6.4,0.85,12.88,0.85,19.44    c0.05,35.75-8.45,70.99-24.79,102.79l-44.85-25.89l-15.39,26.67l34.88,20.09h47.72c55.08-99.6,37.62-223.63-42.82-304.15    L436.91,74.85z" />
            </g>
        </SVGIcon>
    );
}
