import React, { useCallback, useEffect, useState, Fragment, useRef } from 'react';

import PropTypes from 'prop-types';
import DCCrossClose from 'Assets/icons/DCCrossClose';
import DCSearch from 'Assets/icons/DCSearch';
import useMediaQuery from 'UIKit/useMediaQuery';

import './style.scss';

import useDebounce from 'Hooks/useDebounce';
import { CircularLoader, Div, Span, IconButton, Popover } from 'UIKit/index';

const SearchBox = props => {
    const matches991 = useMediaQuery('(max-width: 991.98px)');
    const matches575 = useMediaQuery('(max-width: 575.98px)');

    const {
        fullWidth,
        customMediaQuery,
        buttonSearch,
        mobilePlacement,
        customPlacement,
        customClass,
        loader,
        handleSearch,
        delay,
        value,
        placeHolder,
        autoFocus,
        btnOrder,
        onFocus,
        onBlur,
    } = props;

    const [defaultWidth] = useState(fullWidth || false);
    const [searchInputValue, setSearchInputValue] = useState('');
    const [circularStatus, setCircularStatus] = useState(false);

    const doSearch = useCallback(
        str => {
            handleSearch && handleSearch(str);
            if (loader === undefined) {
                setCircularStatus(false);
            }
        },
        [handleSearch, loader],
    );

    const doSave = useDebounce(doSearch, delay || 1500);

    useEffect(() => {
        if (value) {
            setSearchInputValue(value);
        }
    }, [doSave, value]);

    const handleSearchInput = useCallback(
        e => {
            if (!loader) {
                setCircularStatus(true);
            }
            setSearchInputValue(e.target.value);
            doSave(e.target.value);
        },
        [doSave, loader],
    );

    const handleSearchInputEmpty = useCallback(() => {
        setSearchInputValue('');
        handleSearch('');
    }, [handleSearch]);

    const searchBtnRef = useRef(null);

    const [searchPopover, setSearchPopover] = useState(false);

    const handleSearchPopoverToggle = useCallback(() => {
        setSearchPopover(prevOpen => !prevOpen);
    }, []);

    const handleSearchPopoverClose = useCallback(() => {
        setSearchPopover(false);
    }, []);

    const handleSearchInputClose = useCallback(e => {
        if (e.key === 'Enter' || e.keyCode === 13) {
            setSearchPopover(false);
        }
    }, []);
    return (
        <Fragment>
            {(matches991 || customMediaQuery) && buttonSearch ? (
                <Fragment>
                    <IconButton
                        buttonType={'headerIconBtn'}
                        reference={searchBtnRef}
                        onClick={handleSearchPopoverToggle}
                        customClass={
                            searchInputValue?.length > 0 || searchPopover ? 'searchIconBtn' : ''
                        }
                    >
                        <DCSearch />
                    </IconButton>

                    <Popover
                        open={searchPopover}
                        anchorEl={searchBtnRef}
                        onClose={handleSearchPopoverClose}
                        placement={
                            matches575
                                ? mobilePlacement || 'bottom-center'
                                : customPlacement || 'bottom-end'
                        }
                        customClass={
                            'ArrowPopover PopoverSearchBox ' + (btnOrder ? 'psb' + btnOrder : '')
                        }
                    >
                        <Div className={`SearchBox fullWidth ${customClass || ''}`}>
                            <Span className="SearchIcon">
                                <DCSearch />
                            </Span>
                            <input
                                type="text"
                                placeholder={placeHolder ? placeHolder : 'Search...'}
                                value={searchInputValue}
                                onChange={handleSearchInput}
                                onKeyUp={handleSearchInputClose}
                                autoComplete="off"
                                autoFocus={autoFocus}
                                onFocus={onFocus}
                                onBlur={onBlur}
                            />
                            {searchInputValue.length > 0 &&
                            (loader === false || !circularStatus) ? (
                                <Span className="SearchCross" onClick={handleSearchInputEmpty}>
                                    <DCCrossClose />
                                </Span>
                            ) : null}
                            {searchInputValue.length >= 3 && (loader === true || circularStatus) ? (
                                <Span className="SearchLoader">
                                    <CircularLoader />
                                </Span>
                            ) : null}
                        </Div>
                    </Popover>
                </Fragment>
            ) : (
                <Div
                    className={`SearchBox ${defaultWidth === true ? 'fullWidth ' : ''}${
                        customClass || ''
                    }`}
                >
                    <Span className="SearchIcon">
                        <DCSearch />
                    </Span>
                    <input
                        type="text"
                        placeholder={placeHolder ? placeHolder : 'Search...'}
                        value={searchInputValue}
                        onChange={handleSearchInput}
                        autoComplete="off"
                        autoFocus={autoFocus}
                        onFocus={onFocus}
                        onBlur={onBlur}
                    />
                    {searchInputValue.length > 0 && (loader === false || !circularStatus) ? (
                        <Span className="SearchCross" onClick={handleSearchInputEmpty}>
                            <DCCrossClose />
                        </Span>
                    ) : null}
                    {searchInputValue.length >= 3 && (loader === true || circularStatus) ? (
                        <Span className="SearchLoader">
                            <CircularLoader />
                        </Span>
                    ) : null}
                </Div>
            )}
        </Fragment>
    );
};

SearchBox.propTypes = {
    fullWidth: PropTypes.bool,
    customClass: PropTypes.string,
    loader: PropTypes.bool,
    handleSearch: PropTypes.func,
    delay: PropTypes.number,
    placeHolder: PropTypes.string,
    value: PropTypes.string,
    autoFocus: PropTypes.bool,
    buttonSearch: PropTypes.bool,
    mobilePlacement: PropTypes.string,
    customPlacement: PropTypes.string,
    btnOrder: PropTypes.number,
};

export default SearchBox;
