import { sanitizePhoneNumber } from 'Utils';
import { CallAPI } from '../../Utils/apiCall';
import config from 'config';

const baseUrl = `${config.REACT_APP_API_BASE_URL}/${process.env.REACT_APP_API_VERSION}`;

let configURL = `${baseUrl}/${process.env.REACT_APP_API_USERS}/${process.env.REACT_APP_API_ME}/${process.env.REACT_APP_API_CONFIG}`;
let fieldsURL = `${baseUrl}/${process.env.REACT_APP_API_CRM}/${process.env.REACT_APP_API_FIELDS}`;
const api = new CallAPI();

export const loadTemplates = async (page, limit, searchText, category_id, main_category_id) => {
    const url = `${baseUrl}/instasites/templates`;
    let params = { page, limit };
    if (category_id !== 1) {
        params.category_id = category_id;
    }
    if (main_category_id?.length > 0) {
        params.main_category_ids =
            main_category_id?.length > 1 ? main_category_id : main_category_id[0];
    }
    if (searchText) {
        params.query = searchText;
    }
    return api.request('GET', url, null, null, null, params);
};

export const getCategories = async searchTerm => {
    const url = `${baseUrl}/instasites/categories`;
    let params = {};
    if (searchTerm) {
        params.query = searchTerm;
    }
    return api.request('GET', url, null, null, null, params);
};

export const getMyInstaSites = async (page, limit, sort, search, statusInstaSites) => {
    const url = `${baseUrl}/instasites`;
    let params = { page, limit };

    if (sort) {
        const temp = sort.split('&');
        params.order = temp[0].split('=')[1];
        params.sort_by = temp[1].split('=')[1];
    }
    if (search) {
        params.query = search;
    }
    if (statusInstaSites) {
        params.status = statusInstaSites;
    }

    return api.request('GET', url, null, null, null, params);
};

export const getStatus = async () => {
    const url = `${baseUrl}/instasites/statuses`;

    return api.request('GET', url, null);
};

export const openInstaSiteEditor = async id => {
    const url = `${baseUrl}/instasites/${id}/sso?target=EDITOR`;
    const res = api.request('GET', url, null);
    return res;
};

export const getAdditionInformation = async id => {
    const url = `${baseUrl}/instasites/${id}/additional-information`;
    return api.request('GET', url, null);
};

export const getInstaSitesStats = async (startTime, endTime) => {
    const url = `${baseUrl}/instasites/reporting/status?startDate=${encodeURIComponent(
        startTime,
    )}&endDate=${encodeURIComponent(endTime)}`;
    return api.request('GET', url, null);
};

export const getIndustryStats = async (startTime, endTime) => {
    const url = `${baseUrl}/instasites/reporting/industry?startDate=${encodeURIComponent(
        startTime,
    )}&endDate=${encodeURIComponent(endTime)}`;
    return api.request('GET', url, null);
};

// Steps api
export const getBusinessColumn = async () => {
    const instasiteContacts = await api.request('GET', configURL, null, null, null, {
        type: 'contacts_instasite',
    });
    let businessCols = await api.request('GET', fieldsURL + '/businesses');
    if (instasiteContacts.success && businessCols.success) {
        let mandatory = instasiteContacts.data.fields.businesses[0].fields;
        for (let i = 0; i < businessCols.data.length; i++) {
            if (mandatory.includes(businessCols.data[i].field)) {
                businessCols.data[i].default = true;
            }

            businessCols.data[i] = {
                Header: businessCols.data[i].title,
                accessor: businessCols.data[i].field,
                type: businessCols.data[i].type,
                default: businessCols.data[i].default || false,
                subType: businessCols.data[i].sub_type,
            };
        }
    }
    return {
        colData: businessCols.data,
        visibleFields: instasiteContacts.data.fields.businesses[1].fields,
    };
};

export const editBusiness = async (body, id) => {
    const updateDataURl = `${baseUrl}/${process.env.REACT_APP_API_CRM}/${process.env.REACT_APP_API_CONTACTS}/${process.env.REACT_APP_CONTACTS_BUSINESSES}`;
    let url = `${updateDataURl}/${id}?expand=true`;
    return api.request('PUT', url, body);
};
export const editPeople = async (body, id) => {
    const updateDataURl = `${baseUrl}/${process.env.REACT_APP_API_CRM}/${process.env.REACT_APP_API_CONTACTS}/${process.env.REACT_APP_CONTACTS_PEOPLE}`;
    let url = `${updateDataURl}/${id}?expand=true`;
    return api.request('PUT', url, body);
};
export const removeFile = file => {
    api.cancelAllRequests();
    const url = `${baseUrl}/${process.env.REACT_APP_API_INTEGRATIONS}/${process.env.REACT_APP_API_WASABI}/${process.env.REACT_APP_API_DELETE_BY_KEY}`;
    return api.request('DELETE', url, {
        key: file.key,
        bucket: file.bucket,
    });
};

export const testSMS = async data => {
    return api.request(
        'POST',
        `${baseUrl}/${process.env.REACT_APP_API_INTEGRATIONS}/twilio/send`,
        data,
    );
};

export const getBusinesses = (page, limit, search) => {
    const url = `${baseUrl}/${process.env.REACT_APP_API_CRM}/${process.env.REACT_APP_API_CONTACTS}/${process.env.REACT_APP_CONTACTS_BUSINESSES}`;

    let params = { page, limit };
    if (search) {
        params.search = search;
    }

    return api.request('GET', url, null, null, null, params);
};
export const addQueueCSV = async body => {
    await api.request('POST', baseUrl + '/instasites/queue/csv', body);
};
export const addQueue = async body => {
    await api.request('POST', baseUrl + '/instasites/queue', body);
};

export const callInstasiteView = async params => {
    new CallAPI()
        .requestPublic('POST', `${baseUrl}/instasites/${params.iid}/${params.rid}/view`)
        .then(res => {
            if (res.url) {
                document.location.href = res.url;
            } else throw new Error('Something went wrong!');
        });
};

export const getCSVData = async (page, limit) => {
    return new CallAPI().request('GET', `${baseUrl}/instasites/csv-import`, null, limit, page);
};

export const resendSMS = (instasiteId, recipientId, phone) => {
    const url = `${baseUrl}/instasites/resend-sms`;
    const body = {
        instasite_id: instasiteId,
        recipient_id: recipientId,
        phone: sanitizePhoneNumber(phone),
    };
    return api.request('POST', url, body);
};
export const resendEmailApi = (instasiteId, recipientId, email) => {
    const url = `${baseUrl}/instasites/resend-email`;
    const body = {
        instasite_id: instasiteId,
        recipient_id: recipientId,
        email: email,
    };
    return api.request('POST', url, body);
};

export const getAssociatedPeople = (id, body) => {
    const updateDataURl = `${baseUrl}/crm/contacts/people`;

    let url = `${updateDataURl}/${id}?expand=true`;
    return api.request('PUT', url, body);
};

export const getBusinessContacts = (businessIds, associated, associated_limit, page, limit) => {
    const url = `${baseUrl}/crm/contacts/businesses?business=${businessIds.join(
        ',',
    )}&associated=${associated}&associated_limit=${associated_limit}&page=${page}&limit=${limit}`;

    return api.request('GET', url, null);
};
