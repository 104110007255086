import { useCallback } from 'react';
import useErrorDialog from 'Hooks/ErrorDialog/useErrorDialog';
import usePubSub from '../../Hooks/PubSub';

function useToast() {
    const { publish } = usePubSub();
    const showErrorDialog = useErrorDialog();

    const showToast = useCallback(
        data => {
            publish('show-toast', data);
            if (data.message === 'Please wait, we are processing downgrade') {
                showErrorDialog('DOWNGRADE_IN_PROGRESS');
            }
        },
        [publish, showErrorDialog],
    );

    return { showToast };
}

export default useToast;
