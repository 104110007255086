import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import DCDownArrow from 'Assets/icons/DCDownArrow';
import DCUpArrow from 'Assets/icons/DCUpArrow';

import { Div, Skeleton, Strong, Span } from '../../UIKit/index';

import './style.scss';

const TitleNumPerCard = ({
    className,
    title,
    number,
    percentage,
    other,
    chartData,
    lineColor,
    xAxisData,
    dataTitle,
    loader,
    showArrow,
    arrow,
    height,
}) => {
    const ChartOptions = {
        chart: {
            height: height || 200,
            type: 'areaspline',
            spacingTop: 0,
            spacingRight: 0,
            spacingBottom: 0,
            spacingLeft: 0,
            plotBorderWidth: 0,
            margin: [50, 0, 0, 0],
            backgroundColor: '#fff',
        },
        tooltip: {
            backgroundColor: '#ffffff',
            opacity: 1,
        },
        title: {
            text: '',
        },
        subtitle: {
            text: '',
        },
        caption: {
            text: '',
        },
        plotOptions: {
            series: {
                shadow: {
                    enabled: true,
                },
                fillOpacity: 0.1,
                pointPlacement: 'on',
                lineWidth: 3,
                color: lineColor,
                marker: {
                    enabled: false,
                },
            },
        },
        navigator: {
            enabled: false,
        },
        yAxis: {
            visible: false,
        },
        xAxis: {
            visible: false,
            categories: xAxisData,
        },
        credits: {
            enabled: false,
        },
        series: [
            {
                name: dataTitle,
                showInLegend: false,
                data: chartData,
            },
        ],
    };
    return (
        <Fragment>
            {loader === true ? (
                <Div className={'IRTLPCFSChart IRTLPCFSCLoader'}>
                    <Div className={'IRTLPCFSCHead'}>
                        <Skeleton className={'IRTLPCFSCHTitle'} height={30} />
                        <Div className={'IRTLPCFSCHSTitle'}>
                            <Skeleton height={25} width={100} />
                            <Skeleton height={25} width={100} marginLeft={5} />
                        </Div>
                    </Div>
                    <Div className={'IRTLPCFSCGraph'}>
                        <Skeleton height={130} />
                    </Div>
                </Div>
            ) : (
                <Div className={'IRTLPCFSChart ' + (className ? className : '')}>
                    <Div className={'IRTLPCFSCHead'}>
                        <Div className={'IRTLPCFSCHTitle'}>{title}</Div>
                        <Div className={'IRTLPCFSCHSTitle'}>
                            <Strong>{number} </Strong>
                            {showArrow ? (
                                arrow === 'up' ? (
                                    <Span className={'IRTLPCFSCHS' + arrow}>
                                        <DCUpArrow />
                                    </Span>
                                ) : (
                                    <Span className={'IRTLPCFSCHS' + arrow}>
                                        <DCDownArrow />
                                    </Span>
                                )
                            ) : null}
                            {percentage}
                        </Div>
                        {other && (
                            <Div className={'IRTLPCFSCHOther'} style={{ color: lineColor }}>
                                {other}
                            </Div>
                        )}
                    </Div>
                    <Div className={'IRTLPCFSCGraph'}>
                        <HighchartsReact highcharts={Highcharts} options={ChartOptions} />
                    </Div>
                </Div>
            )}
        </Fragment>
    );
};

TitleNumPerCard.propTypes = {
    className: PropTypes.string,
    title: PropTypes.element,
    number: PropTypes.string,
    percentage: PropTypes.string,
    other: PropTypes.string,
    chartData: PropTypes.arrayOf(PropTypes.number).isRequired,
    lineColor: PropTypes.string,
    xAxisData: PropTypes.arrayOf(PropTypes.string),
    dataTitle: PropTypes.string,
    loader: PropTypes.bool,
    showArrow: PropTypes.bool,
    arrow: PropTypes.string,
    height: PropTypes.number,
};

export default TitleNumPerCard;
