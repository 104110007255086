import React from 'react';

import { SVGIcon } from '../../UIKit/index';

export default function DCNotificationBell1(props) {
    return (
        <SVGIcon {...props} viewBox="0 0 452 512">
            <g transform="translate(-30.999)">
                <path
                    fill="#005EFF"
                    d="M408.8,262.6v-46.8C408.7,148.9,365,90,301.1,70.5V49.3C300.7,25,280.6,5.6,256.3,6   c-23.8,0.4-42.9,19.6-43.3,43.3v21.2C149.1,90,105.4,148.9,105.3,215.7v46.8c0.1,59.8-23,117.2-64.4,160.3   c-5.6,5.9-5.4,15.2,0.4,20.8c2.7,2.6,6.4,4.1,10.2,4.1h133.7c8.1,39.7,46.9,65.4,86.7,57.2c28.8-5.9,51.4-28.4,57.2-57.2h133.7   c8.1,0,14.7-6.6,14.7-14.7c0-3.8-1.5-7.4-4.1-10.2C431.8,379.8,408.7,322.4,408.8,262.6z M242.4,49.3c0-8.1,6.6-14.7,14.7-14.7   c8.1,0,14.7,6.6,14.7,14.7v15.4c-4.8-0.5-9.7-0.7-14.7-0.7c-5,0-9.9,0.3-14.7,0.7L242.4,49.3z M257.1,477.1   c-18.7,0-35.3-11.8-41.5-29.4h83.1C292.4,465.4,275.7,477.1,257.1,477.1L257.1,477.1z M83.1,418.4c33.6-45,51.7-99.7,51.6-155.8   v-46.8c0-67.6,54.8-122.4,122.4-122.4s122.4,54.8,122.4,122.4v46.8c-0.1,56.1,17.9,110.8,51.5,155.8H83.1z"
                />
                <path
                    fill="#D5E5FF"
                    d="M448,215.7c0,8.1,6.6,14.7,14.7,14.7c8.1,0,14.6-6.6,14.7-14.7   c0.2-58.5-23.1-114.5-64.5-155.8c-5.9-5.6-15.2-5.3-20.8,0.6c-5.4,5.7-5.4,14.5,0,20.2C428,116.5,448.1,165.1,448,215.7z"
                />
                <path
                    fill="#D5E5FF"
                    d="M51.5,230.4c8.1,0,14.7-6.6,14.7-14.7c-0.2-50.7,20-99.3,55.9-135c5.4-6.1,4.9-15.3-1.2-20.7   c-5.6-5-14-5-19.6,0c-41.4,41.2-64.7,97.3-64.5,155.8C36.8,223.9,43.4,230.4,51.5,230.4z"
                />
            </g>
        </SVGIcon>
    );
}
