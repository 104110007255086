import React from 'react';

import { SVGIcon } from '../../UIKit/index';

export default function DCQuestion(props) {
    return (
        <SVGIcon {...props} viewBox="0 0 512 512">
            <g>
                <path
                    d="M256.1,102.5c-47.4,0.1-85.8,38.5-85.9,85.9c0,11.9,9.6,21.5,21.5,21.5c11.9,0,21.5-9.6,21.5-21.5
		c0-23.7,19.2-42.9,42.9-42.9c23.7,0,42.9,19.2,42.9,42.9c0,23.7-19.2,42.9-42.9,42.9c-11.9,0-21.5,9.6-21.5,21.5v53.7
		c0,11.9,9.6,21.5,21.5,21.5c11.9,0,21.5-9.6,21.5-21.5v-35c45.9-12,73.4-58.9,61.4-104.8C329.1,128.9,295.1,102.6,256.1,102.5z"
                />
                <circle cx="256.1" cy="382.7" r="26.8" />
                <path
                    d="M256,0C114.8,0,0,114.8,0,256c0,141.2,114.8,256,256,256s256-114.8,256-256C512,114.8,397.2,0,256,0z
		 M256,479.8C132.6,479.8,32.2,379.4,32.2,256C32.2,132.6,132.6,32.2,256,32.2S479.8,132.6,479.8,256
		C479.8,379.4,379.4,479.8,256,479.8z"
                />
            </g>
        </SVGIcon>
    );
}
