import React from 'react';

import { SVGIcon } from '../../UIKit/index';

export default function DCFormatting(props) {
    return (
        <SVGIcon {...props} viewBox="0 0 409.294 409.294">
            <path d="m116.915 263.118h175.412l28.516 58.471h64.407l-148.431-321.589h-64.398l-148.43 321.588h64.407zm87.705-193.356 62.252 134.885h-124.503z" />
            <path d="m0 350.824h409.294v58.471h-409.294z" />
        </SVGIcon>
    );
}
