import React from 'react';

import { SVGIcon } from '../../UIKit/index';

export default function DCRecipient(props) {
    return (
        <SVGIcon {...props} viewBox="0 0 365.88 365.881">
            <path d="M301.606,113.017c0,0,0.35,4.095,0.078,10.282H63.06c-1.03-6.181-1.159-10.282-1.159-10.282H0v139.848h365.88V113.017   H301.606z M299.784,138.797c-0.961,4.706-2.391,9.647-4.438,14.458H73.135c-2.666-4.811-4.734-9.751-6.28-14.458H299.784z    M127.302,182.939c-19.123,0.78-32.638-5.323-42.22-14.188h200.382c-7.834,8.324-19.344,14.188-36.307,14.188H127.302z" />
        </SVGIcon>
    );
}
