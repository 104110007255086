import React from 'react';

import { SVGIcon } from '../../UIKit/index';

export default function DCChatReview(props) {
    return (
        <SVGIcon {...props} viewBox="0 0 512 365">
            <g transform="translate(-601.07 -3554.508)">
                <g transform="translate(601.07 3480.971)">
                    <path d="M480.22,75.54H152.82c-5.58,0-10.11,4.52-10.11,10.11c0,5.58,4.52,10.11,10.11,10.11h327.41    c5.58,0,10.11,4.52,10.11,10.11l0,0v238.96c0,5.58-4.52,10.11-10.11,10.11h0H303.18c-3.1,0-6.02,1.42-7.94,3.85l-40.78,51.65    l-43.25-51.88c-1.92-2.31-4.77-3.64-7.77-3.64H31.15c-5.58,0-10.11-4.52-10.11-10.11l0,0V105.87c0-5.58,4.52-10.11,10.11-10.11    c0,0,0,0,0,0h38.49c5.58,0,10.11-4.52,10.11-10.11c0-5.58-4.52-10.11-10.11-10.11l0,0H31.15c-16.74,0-30.32,13.57-30.32,30.32    v238.94c0,16.74,13.57,30.32,30.32,30.32h167.56l48.21,57.82c3.63,4.29,10.04,4.83,14.34,1.21c0.51-0.43,0.97-0.9,1.38-1.42    l45.47-57.61h172.12c16.74,0,30.32-13.57,30.32-30.32V105.87c0.01-16.74-13.56-30.32-30.31-30.33    C480.23,75.54,480.23,75.54,480.22,75.54z" />
                    <path d="M56.82,205.63c-1.07,3.3-0.38,6.91,1.83,9.58l19.68,23.77l-1.95,30.8    c-1.54,24.13,34.69-1.49,42.5-1.34c7.82,0.16,44.03,25.2,42.5,1.34l-1.95-30.8l19.68-23.77c3.52-4.44,2.78-10.88-1.66-14.4    c-1.07-0.85-2.3-1.48-3.62-1.84l-29.89-7.66l-16.52-26.06c-3.13-4.72-9.5-6-14.21-2.87c-1.14,0.76-2.11,1.73-2.87,2.87    L93.83,191.3l-29.89,7.66C60.58,199.82,57.89,202.33,56.82,205.63z M102.72,209.9c7.48-1.91,12.35-14.42,16.16-20.36    c3.71,5.84,8.76,18.47,16.16,20.36l18.33,4.69l-12.07,14.6c-1.64,1.98-2.46,4.52-2.3,7.09l1.2,18.89l-17.58-6.97    c-2.39-0.95-5.06-0.95-7.46,0l-17.58,6.97l1.2-18.89c0.16-2.57-0.66-5.1-2.3-7.09l-12.07-14.6L102.72,209.9z" />
                    <path d="M316.75,205.63c-1.07-3.29-3.75-5.81-7.11-6.67l-29.89-7.66l-16.52-26.06    c-3.13-4.72-9.5-6-14.21-2.87c-1.14,0.76-2.11,1.73-2.87,2.87l-16.52,26.06l-29.89,7.66c-5.41,1.38-8.67,6.88-7.3,12.29    c0.37,1.45,1.06,2.8,2.02,3.96l19.68,23.77l-1.95,30.8c-1.54,24.13,34.69-1.49,42.5-1.34c7.82,0.16,44.03,25.2,42.5,1.34    l-1.95-30.8l19.68-23.77C317.14,212.54,317.82,208.93,316.75,205.63z M277.1,229.21c-1.64,1.98-2.46,4.52-2.3,7.09l1.2,18.89    c-6.62-3.76-13.84-6.36-21.34-7.69c-7.5,1.34-14.71,3.94-21.34,7.69l1.2-18.89c0.16-2.57-0.66-5.1-2.3-7.09l-12.07-14.6    l18.33-4.69c7.48-1.91,12.35-14.42,16.16-20.36c3.71,5.84,8.76,18.47,16.16,20.36l18.33,4.69L277.1,229.21z" />
                    <path d="M452.54,205.63c-1.07-3.29-3.75-5.81-7.11-6.67l-29.9-7.66l-16.52-26.06    c-3.13-4.72-9.5-6-14.21-2.87c-1.14,0.76-2.11,1.73-2.87,2.87l-16.52,26.06l-29.89,7.66c-5.45,1.52-8.64,7.17-7.12,12.63    c0.37,1.32,0.99,2.55,1.85,3.62l19.68,23.77l-1.95,30.8c-1.54,24.13,34.68-1.49,42.5-1.34c7.83,0.16,44.03,25.2,42.5,1.34    l-1.95-30.8l19.68-23.77C452.92,212.54,453.61,208.93,452.54,205.63z M412.89,229.21c-1.64,1.98-2.46,4.52-2.3,7.09l1.2,18.89    l-17.58-6.97c-2.39-0.95-5.06-0.95-7.46,0l-17.58,6.97l1.2-18.89c0.16-2.57-0.66-5.1-2.3-7.09l-12.07-14.6l18.33-4.69    c7.48-1.91,12.35-14.42,16.16-20.36c3.71,5.84,8.76,18.47,16.16,20.36l18.33,4.69L412.89,229.21z" />
                    <path d="M105.84,94.05c4.64,3.1,10.92,1.85,14.02-2.79c3.1-4.64,1.85-10.92-2.79-14.02    s-10.92-1.85-14.02,2.79c-0.41,0.62-0.76,1.28-1.03,1.98C100.35,86.42,101.93,91.41,105.84,94.05z" />
                </g>
            </g>
        </SVGIcon>
    );
}
