import React from 'react';

import { SVGIcon } from '../../UIKit/index';

export default function DCNotification1(props) {
    return (
        <SVGIcon {...props} viewBox="0 0 450 512">
            <g transform="translate(-31)">
                <path
                    fill="#E4E4EE"
                    d="M286.3,93.2c-8.3,0-15-6.7-15-15c0,0,0,0,0,0V45c0-8.3-6.7-15-15-15s-15,6.7-15,15v33.2
		c0,8.3-6.7,15-15,15s-15-6.7-15-15V45c0-24.9,20.1-45,45-45s45,20.1,45,45v33.2C301.3,86.5,294.6,93.2,286.3,93.2L286.3,93.2z"
                />
                <path
                    fill="#FFFFFF"
                    d="M271.4,45v33.2c0,8.3,6.7,15,15,15c8.3,0,15-6.7,15-15V45c0-24.8-20.2-45-45-45v30
		C264.6,30,271.4,36.7,271.4,45z"
                />
                <path
                    fill="#E4E4EE"
                    d="M256.4,512c-41.4,0-74.9-33.6-75-75c0-8.3,6.7-15,15-15h120c8.3,0,15,6.7,15,15
		C331.3,478.4,297.8,512,256.4,512z"
                />
                <path
                    fill="#FFFFFF"
                    d="M316.4,422h-60v90c41.4,0,74.9-33.6,75-75C331.4,428.7,324.6,422,316.4,422L316.4,422z"
                />
                <path
                    fill="#FFFFFF"
                    d="M466.4,452h-420c-8.3,0-15-6.7-15-15c0-3.9,1.5-7.6,4.2-10.4c42.3-44,65.9-102.7,65.8-163.8
		V215c0-85.6,69.4-155,155-155s155,69.4,155,155v47.9c-0.1,61.1,23.5,119.8,65.8,163.8c5.7,6,5.5,15.5-0.5,21.2
		C473.9,450.5,470.2,452,466.4,452L466.4,452z"
                />
                <path
                    fill="#E4E4EE"
                    d="M466.4,452c8.3,0,15-6.7,15-15c0-3.9-1.5-7.6-4.2-10.4c-42.3-44-65.9-102.7-65.8-163.8V215
		c-0.1-85.6-69.5-154.9-155-155v392H466.4z"
                />
            </g>
        </SVGIcon>
    );
}
