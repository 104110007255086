import React, { useReducer } from 'react';

import { themeActions } from '../Actions/index';
import { themeContext } from '../Context/index';
import { themeReducer } from '../Reducer/index';
import { themeStore } from '../Store/index';

const ThemeContextProvider = props => {
    const [state, dispatch] = useReducer(themeReducer, themeStore);

    const value = {
        primaryColor: state.primaryColor,
        secondaryColor: state.secondaryColor,
        textColor: state.textColor,
        notificationCount: state.notificationCount,
        notify: state.notify,

        setPrimaryColor: _value => {
            dispatch({ type: themeActions.SET_PRIMARY_COLOR, value: _value });
        },
        setSecondaryColor: _value => {
            dispatch({ type: themeActions.SET_SECONDARY_COLOR, value: _value });
        },
        setTextColor: _value => {
            dispatch({ type: themeActions.SET_TEXT_COLOR, value: _value });
        },
        setNotificationCount: _value => {
            dispatch({ type: themeActions.SET_NOTIFICATIONS_COUNT, value: _value });
        },
        setNotify: value => {
            dispatch({ type: themeActions.SET_NOTIFICATION, value });
        },
    };

    return <themeContext.Provider value={value}>{props.children}</themeContext.Provider>;
};

export default ThemeContextProvider;
