import React, { Fragment, useCallback, useState } from 'react';

import HighchartsReact from 'highcharts-react-official';
import highchartsMore from 'highcharts/highcharts-more.js';
import Highcharts from 'highcharts/highcharts.js';
import solidGauge from 'highcharts/modules/solid-gauge.js';

import DCIPs from 'Assets/icons/DCIPs';
import DCLink2 from 'Assets/icons/DCLink2';
import DCWWW from 'Assets/icons/DCWWW';
import { Button, Div, Image, Strong, TooltipWithIcon } from 'UIKit';
import { abbreviateNumber } from 'Utils/stringUtils';
import NoFound from './noFound';
import HowToDialog from './howToDialog';
import generateImage from 'Utils/generateImage';
import useMediaQuery from 'UIKit/useMediaQuery';

highchartsMore(Highcharts);
solidGauge(Highcharts);

const SEO = ({
    visible,
    grade,
    keywords,
    competitors,
    backlinks,
    details,
    user,
    handleGetInTouchOpen,
}) => {
    const matches1367 = useMediaQuery('(min-width: 1280px) and (max-width: 1600px)');
    const options = {
        chart: {
            type: 'solidgauge',
            height: matches1367 ? '40px' : '66px',
            width: matches1367 ? 80 : 160,
            spacingTop: 0,
            spacingRight: 0,
            spacingBottom: 0,
            spacingLeft: 0,
            plotBorderWidth: 0,
            margin: [0, 0, 0, 0],
            backgroundColor: 'transparent',
        },
        title: {
            text: null,
        },
        credits: {
            enabled: false,
        },
        tooltip: {
            enabled: false,
        },
        pane: {
            center: ['50%', '100%'],
            size: '200%',
            startAngle: -90,
            endAngle: 90,
            background: [
                {
                    outerRadius: '100%',
                    innerRadius: '60%',
                    backgroundColor: '#E8E8E8',
                    borderWidth: 0,
                    shape: 'arc',
                },
            ],
        },
        yAxis: {
            min: 0,
            max: 100,
            lineWidth: 0,
            color: '#000',
            tickPositions: [],
            plotBands: [
                {
                    from: 0,
                    to: backlinks?.authorityScore,
                    thickness: '40%',
                    color: '#35B7F3', // green
                },
            ],
        },
        series: [
            {
                data: [0],
                dataLabels: {
                    enabled: false,
                },
            },
        ],
    };

    const [howToDialog, setHowToDialog] = useState(false);
    const handleHowToDialogOpen = useCallback(() => {
        setHowToDialog(true);
    }, []);

    const handleHowToDialogClose = useCallback(() => {
        setHowToDialog(false);
    }, []);

    if (!visible) {
        return null;
    }

    return (
        <Fragment>
            <HowToDialog
                open={howToDialog}
                onClose={handleHowToDialogClose}
                title={'How we calculate the SEO grade'}
                description={
                    <Fragment>
                        Your <Strong>SEO score</Strong> is determined by our weigh algorithm which
                        assigns a value to 3 separate search engines (Google, Yahoo and Bing). We
                        then track keyword ranking positions based on percentile scores, per value
                        of each search engine, which results in a total score average.
                    </Fragment>
                }
            />

            <Div className={'EPComponent'}>
                <Div className={'EPCHead'}>
                    <Div className={'EPCHTBox'}>
                        <Div className={'EPCHTBIcon Grade' + grade}>{grade}</Div>
                        <Div className={'EPCHTBTitle'}>SEO</Div>
                        <Div className={'EPCHTBSTitle'}>
                            Are potential customers finding you when searching?
                        </Div>
                    </Div>
                    <Div className={'EPCHSTitle'} onClick={handleHowToDialogOpen}>
                        How we calculated this grade?
                    </Div>
                </Div>
                <Div className={'EPCBody'}>
                    <Div className={'EPCVideoContent'}>
                        <Div className={'EPCVCVideo'}>
                            <iframe
                                src="https://player.vimeo.com/video/356237738"
                                title="video"
                                frameBorder="0"
                                webkitallowfullscreen=""
                                mozallowfullscreen=""
                            />
                        </Div>
                        <Div className={'EPCVCContent'}>
                            <Div className={'EPCVCCDes'}>
                                91% of online experiences begin with a Google search, which is why
                                it’s critical that your website is listed at the top of search
                                results. It’s no secret that search engine optimization is one of
                                the leading marketing tactics to get a continuous flow of new
                                customers.
                            </Div>
                            <br />
                            <Div className={'EPCVCCDes'}>
                                We scanned {details?.website} to find it’s positioning within
                                Google’s search results for industry specific keywords, then stacked
                                it up against your competitors to see how you’re competing.
                            </Div>
                        </Div>
                    </Div>
                    {keywords?.length && competitors?.length && Object.keys(backlinks)?.length ? (
                        <Fragment>
                            {competitors && (
                                <Fragment>
                                    <Div className={'EPCInnerHeading'}>
                                        <Div className={'EPCIHLTitle'}>
                                            Organic Competitor Analysis
                                        </Div>
                                        <Div className={'EPCIHRTitle'}>
                                            Here’s your SEO compared to your competitors
                                        </Div>
                                    </Div>
                                    <Div className={'EPCTable'}>
                                        <Div className={'EPCTInner'}>
                                            <Div className={'EPCTIRow EPCTIRHeader EPCTIRCol5'}>
                                                <Div className={'EPCTIRCol'}>
                                                    <Div className={'EPCTIRHTitle'}>
                                                        <Div className={'EPCTIRHText'}>Domain</Div>
                                                        {/* <TooltipWithIcon
                                                            tooltipContent={
                                                                'Domain name'
                                                            }
                                                        /> */}
                                                    </Div>
                                                </Div>
                                                <Div className={'EPCTIRCol'}>
                                                    <Div className={'EPCTIRHTitle'}>
                                                        <Div className={'EPCTIRHText'}>Overlap</Div>
                                                        <TooltipWithIcon
                                                            tooltipContent={
                                                                'How similar you are to your competitors, based on common SEO keywords.'
                                                            }
                                                        />
                                                    </Div>
                                                </Div>
                                                <Div className={'EPCTIRCol'}>
                                                    <Div className={'EPCTIRHTitle'}>
                                                        <Div className={'EPCTIRHText'}>
                                                            Keywords
                                                        </Div>
                                                        <TooltipWithIcon
                                                            tooltipContent={
                                                                'The number of keywords for which you and your competitors show up in the top 50 search results on Google.'
                                                            }
                                                        />
                                                    </Div>
                                                </Div>
                                                <Div className={'EPCTIRCol'}>
                                                    <Div className={'EPCTIRHTitle'}>
                                                        <Div className={'EPCTIRHText'}>Clicks</Div>
                                                        <TooltipWithIcon
                                                            tooltipContent={
                                                                'The estimated number of clicks you and your competitors get from all of your organic keywords.'
                                                            }
                                                        />
                                                    </Div>
                                                </Div>
                                                <Div className={'EPCTIRCol'}>
                                                    <Div className={'EPCTIRHTitle'}>
                                                        <Div className={'EPCTIRHText'}>Value</Div>
                                                        <TooltipWithIcon
                                                            tooltipContent={
                                                                'The estimated value you and your competitors are getting from all of your organic keywords.'
                                                            }
                                                        />
                                                    </Div>
                                                </Div>
                                            </Div>
                                            <Div className={'EPCTIBody'}>
                                                {competitors?.map((item, index) => (
                                                    <Div
                                                        key={index}
                                                        className={'EPCTIRow EPCTIRBody EPCTIRCol5'}
                                                    >
                                                        <Div className={'EPCTIRCol'}>
                                                            <Div className={'EPCTIRBText'}>
                                                                <a
                                                                    href={`https://${item?.domain}`}
                                                                    target="_blank"
                                                                    rel="noreferrer"
                                                                >
                                                                    {item?.domain}
                                                                </a>
                                                            </Div>
                                                        </Div>
                                                        <Div className={'EPCTIRCol'}>
                                                            {item?.overlap !== '' && (
                                                                <Div className={'EPCTProgress'}>
                                                                    <Div className={'EPCTPField'}>
                                                                        <Div
                                                                            className={
                                                                                'EPCTPFInner'
                                                                            }
                                                                            style={{
                                                                                width: `${item?.overlap}%`,
                                                                            }}
                                                                        />
                                                                    </Div>
                                                                    <Div className={'EPCTPPre'}>
                                                                        {item?.overlap}%
                                                                    </Div>
                                                                </Div>
                                                            )}
                                                        </Div>
                                                        <Div className={'EPCTIRCol'}>
                                                            <Div className={'EPCTIRBText'}>
                                                                {abbreviateNumber(item?.keywords)}
                                                            </Div>
                                                        </Div>
                                                        <Div className={'EPCTIRCol'}>
                                                            <Div className={'EPCTIRBText'}>
                                                                {abbreviateNumber(item?.clicks)}
                                                            </Div>
                                                        </Div>
                                                        <Div className={'EPCTIRCol'}>
                                                            <Div className={'EPCTIRBText'}>
                                                                {abbreviateNumber(item?.value)}
                                                            </Div>
                                                        </Div>
                                                    </Div>
                                                ))}
                                            </Div>
                                        </Div>
                                    </Div>
                                </Fragment>
                            )}
                            {keywords && (
                                <Fragment>
                                    <Div className={'EPCInnerHeading'}>
                                        <Div className={'EPCIHLTitle'}>Organic Keyword Ranking</Div>
                                        <Div className={'EPCIHRTitle'}>
                                            Here’s how your business ranks in searches
                                        </Div>
                                    </Div>
                                    <Div className={'EPCTable'}>
                                        <Div className={'EPCTInner'}>
                                            <Div className={'EPCTIRow EPCTIRHeader EPCTIRCol5'}>
                                                <Div className={'EPCTIRCol'}>
                                                    <Div className={'EPCTIRHTitle'}>
                                                        <Div className={'EPCTIRHText'}>Keyword</Div>
                                                        <TooltipWithIcon
                                                            tooltipContent={
                                                                'The words and phrases that customers are using to find you on Google.'
                                                            }
                                                        />
                                                    </Div>
                                                </Div>
                                                <Div className={'EPCTIRCol'}>
                                                    <Div className={'EPCTIRHTitle'}>
                                                        <Div className={'EPCTIRHText'}>
                                                            Keyword Difficulty
                                                        </Div>
                                                        <TooltipWithIcon
                                                            tooltipContent={
                                                                'How difficult it is to appear in the top Google Search results.'
                                                            }
                                                        />
                                                    </Div>
                                                </Div>
                                                <Div className={'EPCTIRCol'}>
                                                    <Div className={'EPCTIRHTitle'}>
                                                        <Div className={'EPCTIRHText'}>
                                                            Position
                                                        </Div>
                                                        <TooltipWithIcon
                                                            tooltipContent={
                                                                'The position of your website in Google Search.'
                                                            }
                                                        />
                                                    </Div>
                                                </Div>
                                                <Div className={'EPCTIRCol'}>
                                                    <Div className={'EPCTIRHTitle'}>
                                                        <Div className={'EPCTIRHText'}>
                                                            Search Volume
                                                        </Div>
                                                        <TooltipWithIcon
                                                            tooltipContent={
                                                                'The estimated number of clicks you get per month within your country.'
                                                            }
                                                        />
                                                    </Div>
                                                </Div>
                                                <Div className={'EPCTIRCol'}>
                                                    <Div className={'EPCTIRHTitle'}>
                                                        <Div className={'EPCTIRHText'}>Results</Div>
                                                        <TooltipWithIcon
                                                            tooltipContent={
                                                                'The estimated number of searches per month across Google.'
                                                            }
                                                        />
                                                    </Div>
                                                </Div>
                                            </Div>
                                            <Div className={'EPCTIBody'}>
                                                {keywords.map((item, index) => (
                                                    <Div
                                                        key={index}
                                                        className={'EPCTIRow EPCTIRBody EPCTIRCol5'}
                                                    >
                                                        <Div className={'EPCTIRCol'}>
                                                            <Div className={'EPCTIRBText'}>
                                                                {item?.keyword}
                                                            </Div>
                                                        </Div>
                                                        <Div className={'EPCTIRCol'}>
                                                            <Div className={'EPCTProgress'}>
                                                                <Div className={'EPCTPField'}>
                                                                    <Div
                                                                        className={'EPCTPFInner'}
                                                                        style={{
                                                                            width: `${item?.keywordDifficulty}%`,
                                                                        }}
                                                                    />
                                                                </Div>
                                                                <Div className={'EPCTPPre'}>
                                                                    {item?.keywordDifficulty}%
                                                                </Div>
                                                            </Div>
                                                        </Div>
                                                        <Div className={'EPCTIRCol'}>
                                                            <Div className={'EPCTIRBText'}>
                                                                {item?.position}
                                                            </Div>
                                                        </Div>
                                                        <Div className={'EPCTIRCol'}>
                                                            <Div className={'EPCTIRBText'}>
                                                                {abbreviateNumber(
                                                                    item?.searchVolume,
                                                                )}
                                                            </Div>
                                                        </Div>
                                                        <Div className={'EPCTIRCol'}>
                                                            <Div className={'EPCTIRBText'}>
                                                                {abbreviateNumber(item?.results)}
                                                            </Div>
                                                        </Div>
                                                    </Div>
                                                ))}
                                            </Div>
                                        </Div>
                                    </Div>
                                </Fragment>
                            )}
                            {backlinks && (
                                <Fragment>
                                    <Div className={'EPCInnerHeading'}>
                                        <Div className={'EPCIHLTitle'}>Backlinks</Div>
                                        <Div className={'EPCIHRTitle'}>
                                            Here’s the links pointing to your website
                                        </Div>
                                    </Div>
                                    <Div className={'EPCRow'}>
                                        <Div className={'EPCRCol25'}>
                                            <Div
                                                className={'EPCRTSIH'}
                                                style={{ backgroundColor: '#f0f2ff' }}
                                            >
                                                <Div className={'EPCRTSIHInfo'}>
                                                    <Div className={'EPCRTSIHITBox'}>
                                                        <Div className={'EPCRTSIHITTitle'}>
                                                            Total Backlinks
                                                        </Div>
                                                        <TooltipWithIcon
                                                            tooltipContent={
                                                                'The total number of backlinks leading to an analyzed domain. Only backlinks that we have seen in the last few months are taken into account.'
                                                            }
                                                        />
                                                    </Div>
                                                    <Div className={'EPCRTSIHISTBox'}>
                                                        {abbreviateNumber(
                                                            backlinks?.totalBacklinks,
                                                        )}
                                                    </Div>
                                                </Div>
                                                <Div className={'EPCRTSIHIcon'}>
                                                    <DCLink2 />
                                                </Div>
                                            </Div>
                                        </Div>
                                        <Div className={'EPCRCol25'}>
                                            <Div
                                                className={'EPCRTSIH'}
                                                style={{ backgroundColor: '#fef6fb' }}
                                            >
                                                <Div className={'EPCRTSIHInfo'}>
                                                    <Div className={'EPCRTSIHITBox'}>
                                                        <Div className={'EPCRTSIHITTitle'}>
                                                            Referring Domains
                                                        </Div>
                                                        <TooltipWithIcon
                                                            tooltipContent={
                                                                'The total number of referring domains that have at least one link pointing to an analyzed domain. Only referring domains that we have seen in the last few months are taken into account.'
                                                            }
                                                        />
                                                    </Div>
                                                    <Div className={'EPCRTSIHISTBox'}>
                                                        {abbreviateNumber(
                                                            backlinks?.referringDomains,
                                                        )}
                                                    </Div>
                                                </Div>
                                                <Div className={'EPCRTSIHIcon'}>
                                                    <DCWWW />
                                                </Div>
                                            </Div>
                                        </Div>
                                        <Div className={'EPCRCol25'}>
                                            <Div
                                                className={'EPCRTSIH'}
                                                style={{ backgroundColor: '#fff6ef' }}
                                            >
                                                <Div className={'EPCRTSIHInfo'}>
                                                    <Div className={'EPCRTSIHITBox'}>
                                                        <Div className={'EPCRTSIHITTitle'}>
                                                            Referrings IPs
                                                        </Div>
                                                        <TooltipWithIcon
                                                            tooltipContent={
                                                                'The number of unique IP addresses pointing to the queried URL. Only IPs that we have seen in the last few months are taken into account.'
                                                            }
                                                        />
                                                    </Div>
                                                    <Div className={'EPCRTSIHISTBox'}>
                                                        {abbreviateNumber(backlinks?.referringsIPs)}
                                                    </Div>
                                                </Div>
                                                <Div className={'EPCRTSIHIcon'}>
                                                    <DCIPs />
                                                </Div>
                                            </Div>
                                        </Div>
                                        <Div className={'EPCRCol25'}>
                                            <Div
                                                className={'EPCRTSIH'}
                                                style={{ backgroundColor: '#eef5f9' }}
                                            >
                                                <Div className={'EPCRTSIHInfo'}>
                                                    <Div className={'EPCRTSIHITBox'}>
                                                        <Div className={'EPCRTSIHITTitle'}>
                                                            Authority Score
                                                        </Div>
                                                        <TooltipWithIcon
                                                            tooltipContent={
                                                                'Authority Score is a compound metric that measures the strength and popularity of the domain. It is calculated based on Page Score, Domain Score, and Trust Score and other data.'
                                                            }
                                                        />
                                                    </Div>
                                                    <Div className={'EPCRTSIHISTBox'}>
                                                        {backlinks?.authorityScore}
                                                    </Div>
                                                </Div>
                                                <Div className={'EPCRTSIHIcon EPCRTSIHASIcon'}>
                                                    <HighchartsReact
                                                        highcharts={Highcharts}
                                                        options={options}
                                                    />
                                                </Div>
                                            </Div>
                                        </Div>
                                    </Div>
                                    <Div className={'EPCRow'}>
                                        <Div className={'EPCRCol5'}>
                                            <Div className={'EPCRCBType'}>
                                                <Div className={'EPCRCBTHead'}>
                                                    <Div className={'EPCRCBTHTitle'}>
                                                        Backlinks Types
                                                    </Div>
                                                    <TooltipWithIcon
                                                        tooltipContent={
                                                            'The total number backlinks segmented by type. Only backlinks that we have seen in the last few months are taken into account.'
                                                        }
                                                    />
                                                </Div>
                                                <Div className={'EPCRCBTBTList'}>
                                                    <Div className={'EPCRCBTBTank'}>
                                                        <Div className={'EPCRCBTBTBox'}>
                                                            <Div
                                                                className={'EPCRCBTBTBInner'}
                                                                style={{
                                                                    height: `${backlinks?.backlinksTypes?.text?.percentage}%`,
                                                                }}
                                                            />
                                                        </Div>
                                                        <Div className={'EPCRCBTBTBTText'}>
                                                            <Div className={'EPCRCBTBTBTTitle'}>
                                                                {
                                                                    backlinks?.backlinksTypes?.text
                                                                        ?.percentage
                                                                }
                                                                %
                                                            </Div>
                                                            <Div className={'EPCRCBTBTBTSTitle'}>
                                                                {abbreviateNumber(
                                                                    backlinks?.backlinksTypes?.text
                                                                        ?.number,
                                                                )}{' '}
                                                                Text
                                                            </Div>
                                                        </Div>
                                                    </Div>
                                                    <Div className={'EPCRCBTBTank'}>
                                                        <Div className={'EPCRCBTBTBox'}>
                                                            <Div
                                                                className={'EPCRCBTBTBInner'}
                                                                style={{
                                                                    height: `${backlinks?.backlinksTypes?.image?.percentage}%`,
                                                                }}
                                                            />
                                                        </Div>
                                                        <Div className={'EPCRCBTBTBTText'}>
                                                            <Div className={'EPCRCBTBTBTTitle'}>
                                                                {
                                                                    backlinks?.backlinksTypes?.image
                                                                        ?.percentage
                                                                }
                                                                %
                                                            </Div>
                                                            <Div className={'EPCRCBTBTBTSTitle'}>
                                                                {abbreviateNumber(
                                                                    backlinks?.backlinksTypes?.image
                                                                        ?.number,
                                                                )}{' '}
                                                                Image
                                                            </Div>
                                                        </Div>
                                                    </Div>
                                                    <Div className={'EPCRCBTBTank'}>
                                                        <Div className={'EPCRCBTBTBox'}>
                                                            <Div
                                                                className={'EPCRCBTBTBInner'}
                                                                style={{
                                                                    height: `${backlinks?.backlinksTypes?.form?.percentage}%`,
                                                                }}
                                                            />
                                                        </Div>
                                                        <Div className={'EPCRCBTBTBTText'}>
                                                            <Div className={'EPCRCBTBTBTTitle'}>
                                                                {
                                                                    backlinks?.backlinksTypes?.form
                                                                        ?.percentage
                                                                }
                                                                %
                                                            </Div>
                                                            <Div className={'EPCRCBTBTBTSTitle'}>
                                                                {abbreviateNumber(
                                                                    backlinks?.backlinksTypes?.form
                                                                        ?.number,
                                                                )}{' '}
                                                                Form
                                                            </Div>
                                                        </Div>
                                                    </Div>
                                                    <Div className={'EPCRCBTBTank'}>
                                                        <Div className={'EPCRCBTBTBox'}>
                                                            <Div
                                                                className={'EPCRCBTBTBInner'}
                                                                style={{
                                                                    height: `${backlinks?.backlinksTypes?.frame?.percentage}%`,
                                                                }}
                                                            />
                                                        </Div>
                                                        <Div className={'EPCRCBTBTBTText'}>
                                                            <Div className={'EPCRCBTBTBTTitle'}>
                                                                {
                                                                    backlinks?.backlinksTypes?.frame
                                                                        ?.percentage
                                                                }
                                                                %
                                                            </Div>
                                                            <Div className={'EPCRCBTBTBTSTitle'}>
                                                                {abbreviateNumber(
                                                                    backlinks?.backlinksTypes?.frame
                                                                        ?.number,
                                                                )}{' '}
                                                                Frame
                                                            </Div>
                                                        </Div>
                                                    </Div>
                                                </Div>
                                            </Div>
                                        </Div>
                                        <Div className={'EPCRCol5'}>
                                            <Div className={'EPCRCBType'}>
                                                <Div className={'EPCRCBTHead'}>
                                                    <Div className={'EPCRCBTHTitle'}>
                                                        Follow vs Nofollow
                                                    </Div>
                                                    <TooltipWithIcon
                                                        tooltipContent={
                                                            'The total number of Follow versus Nofollow backlinks pointing back to the domain name. Only backlinks that we have seen in the last few months are taken into account.'
                                                        }
                                                    />
                                                </Div>
                                                <Div className={'EPCRCBTFNBody'}>
                                                    <Div className={'EPCRCBTFNProgress'}>
                                                        <Div
                                                            className={'EPCRCBTFNPInner'}
                                                            style={{
                                                                width: `${backlinks?.follow?.percentage}%`,
                                                            }}
                                                        />
                                                    </Div>
                                                    <Div className={'EPCRCBTFNPTitle'}>
                                                        <Div className={'EPCRCBTFNPTBox'}>
                                                            <Div className={'EPCRCBTFNPTBText'}>
                                                                {backlinks?.follow?.percentage}%
                                                            </Div>
                                                            <Div className={'EPCRCBTFNPTBSText'}>
                                                                {abbreviateNumber(
                                                                    backlinks?.follow?.number,
                                                                )}{' '}
                                                                Follow
                                                            </Div>
                                                        </Div>
                                                        <Div className={'EPCRCBTFNPTBox'}>
                                                            <Div className={'EPCRCBTFNPTBText'}>
                                                                {backlinks?.noFollow?.percentage}%
                                                            </Div>
                                                            <Div className={'EPCRCBTFNPTBSText'}>
                                                                {abbreviateNumber(
                                                                    backlinks?.noFollow?.number,
                                                                )}{' '}
                                                                Non Follow
                                                            </Div>
                                                        </Div>
                                                    </Div>
                                                </Div>
                                            </Div>
                                        </Div>
                                    </Div>
                                </Fragment>
                            )}
                        </Fragment>
                    ) : (
                        <NoFound
                            title={'Potential customers can’t find your website!'}
                            description={
                                <Fragment>
                                    <p>
                                        We’re having trouble finding your website. Either you don’t
                                        have a website setup at all, or the website is not optimized
                                        properly for potential customers to find.
                                        <br />
                                        We see this as a missed opportunity for {details?.name} to
                                        take advantage of right away. Start an SEO campaign today to
                                        get found better in search engine results.
                                    </p>
                                </Fragment>
                            }
                        />
                    )}
                    <Div className={'EPCUserComt'}>
                        <Div className={'EPCUCInner'}>
                            <Div className={'EPCUCInfo'}>
                                <Div className={'EPCUCIImg'}>
                                    <Image
                                        src={generateImage(user?.image, user?.name)}
                                        displayName={user?.name}
                                        alt={user?.name}
                                    />
                                </Div>
                                <Div className={'EPCUCICmt'}>
                                    Would you like to get {details?.website} ranked at the top of
                                    search engine results and get more customers? We can help!
                                </Div>
                            </Div>
                            <Div className={'EPCUCBtn'}>
                                <Button buttonType={'BlueFillBtn'} onClick={handleGetInTouchOpen}>
                                    Get in touch
                                </Button>
                            </Div>
                        </Div>
                    </Div>
                </Div>
            </Div>
        </Fragment>
    );
};

export default SEO;
