import React from 'react';

import { SVGIcon } from '../../UIKit/index';

export default function DCGrayActivity(props) {
    return (
        <SVGIcon {...props} viewBox="0 0 430.52 500.694">
            <g id="Activity_" data-name="Activity " transform="translate(-41.3 -3.627)">
                <ellipse
                    id="_Path_"
                    data-name="&lt;Path&gt;"
                    cx="146.52"
                    cy="14.39"
                    rx="146.52"
                    ry="14.39"
                    transform="translate(112.82 475.54)"
                    fill="#f4f5f6"
                />
                <circle
                    id="Ellipse_9"
                    data-name="Ellipse 9"
                    cx="213.76"
                    cy="213.76"
                    r="213.76"
                    transform="translate(42.8 34.66)"
                    fill="#fff"
                    stroke="#707171"
                    strokeMiterlimit="10"
                    strokeWidth="3"
                />
                <rect
                    id="Rectangle_89"
                    data-name="Rectangle 89"
                    width="84.5"
                    height="114.28"
                    rx="4.11"
                    transform="matrix(0.989, 0.148, -0.148, 0.989, 387.502, 251.756)"
                    fill="#e3e2e3"
                />
                <rect
                    id="Rectangle_90"
                    data-name="Rectangle 90"
                    width="71.08"
                    height="102.37"
                    transform="matrix(0.989, 0.148, -0.148, 0.989, 393.259, 258.641)"
                    fill="#fff"
                />
                <path
                    id="Path_120"
                    data-name="Path 120"
                    d="M448.52,369.84l-.08.54-70.29-10.5,15.11-101.24,27.08,4A64.44,64.44,0,0,0,448.52,369.8Z"
                    fill="#f0f5ff"
                    opacity="0.55"
                />
                <path
                    id="Path_121"
                    data-name="Path 121"
                    d="M412.89,288.63l38.21,5.71a1.1,1.1,0,1,0,.32-2.18l-38.21-5.71a1.1,1.1,0,0,0-.32,2.18Z"
                    fill="#e3e2e3"
                />
                <path
                    id="Path_122"
                    data-name="Path 122"
                    d="M412,294.48l38.2,5.71a1.1,1.1,0,1,0,.33-2.18l-38.21-5.7a1.1,1.1,0,1,0-.32,2.17Z"
                    fill="#e3e2e3"
                />
                <path
                    id="Path_123"
                    data-name="Path 123"
                    d="M406.45,289.55a5.06,5.06,0,1,1-4.25-5.75A5.06,5.06,0,0,1,406.45,289.55Z"
                    fill="#e3e2e3"
                />
                <path
                    id="Path_124"
                    data-name="Path 124"
                    d="M399,288.44l1.39,1.88,3.76-2.79"
                    fill="none"
                    stroke="#707171"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="1.25"
                />
                <path
                    id="Path_125"
                    data-name="Path 125"
                    d="M410.12,307.2l38.2,5.71a1.1,1.1,0,1,0,.33-2.18L410.44,305a1.1,1.1,0,1,0-.32,2.17Z"
                    fill="#e3e2e3"
                />
                <path
                    id="Path_126"
                    data-name="Path 126"
                    d="M409.24,313.06l38.21,5.7a1.1,1.1,0,0,0,.33-2.18l-38.21-5.7a1.1,1.1,0,0,0-.33,2.18Z"
                    fill="#e3e2e3"
                />
                <path
                    id="Path_127"
                    data-name="Path 127"
                    d="M403.68,308.12a5.06,5.06,0,1,1-4.26-5.74A5.06,5.06,0,0,1,403.68,308.12Z"
                    fill="#e3e2e3"
                />
                <path
                    id="Path_128"
                    data-name="Path 128"
                    d="M396.22,307.01l1.4,1.88,3.76-2.79"
                    fill="none"
                    stroke="#707171"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="1.25"
                />
                <path
                    id="Path_129"
                    data-name="Path 129"
                    d="M407.34,325.77l38.21,5.71a1.1,1.1,0,1,0,.33-2.18l-38.21-5.7a1.1,1.1,0,1,0-.33,2.17Z"
                    fill="#e3e2e3"
                />
                <path
                    id="Path_130"
                    data-name="Path 130"
                    d="M406.47,331.63l38.21,5.7a1.1,1.1,0,1,0,.32-2.18l-38.2-5.7a1.1,1.1,0,1,0-.33,2.18Z"
                    fill="#e3e2e3"
                />
                <path
                    id="Path_131"
                    data-name="Path 131"
                    d="M400.9,326.69a5,5,0,1,1-.919-3.763,5.05,5.05,0,0,1,.919,3.763Z"
                    fill="#e3e2e3"
                />
                <path
                    id="Path_132"
                    data-name="Path 132"
                    d="M393.45,325.58l1.39,1.88,3.76-2.79"
                    fill="none"
                    stroke="#707171"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="1.25"
                />
                <path
                    id="Path_133"
                    data-name="Path 133"
                    d="M404.57,344.34l38.21,5.71a1.1,1.1,0,1,0,.33-2.18l-38.21-5.7a1.1,1.1,0,0,0-.33,2.17Z"
                    fill="#e3e2e3"
                />
                <path
                    id="Path_134"
                    data-name="Path 134"
                    d="M403.7,350.2l38.21,5.7a1.1,1.1,0,1,0,.32-2.18L404,348a1.11,1.11,0,1,0-.3,2.2Z"
                    fill="#e3e2e3"
                />
                <path
                    id="Path_135"
                    data-name="Path 135"
                    d="M398.13,345.26a5,5,0,1,1-.919-3.763A5.05,5.05,0,0,1,398.13,345.26Z"
                    fill="#e3e2e3"
                />
                <path
                    id="Path_136"
                    data-name="Path 136"
                    d="M390.68,344.15l1.39,1.88,3.76-2.78"
                    fill="none"
                    stroke="#707171"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="1.25"
                />
                <rect
                    id="Rectangle_91"
                    data-name="Rectangle 91"
                    width="46.39"
                    height="3.29"
                    rx="1.44"
                    transform="matrix(0.989, 0.148, -0.148, 0.989, 403.638, 272.694)"
                    fill="#707171"
                    stroke="#707171"
                    strokeMiterlimit="10"
                    strokeWidth="1"
                />
                <path
                    id="Path_137"
                    data-name="Path 137"
                    d="M417.59,252.91h23.17a6.1,6.1,0,0,1,6.1,6.1v4.93H411.49V259a6.1,6.1,0,0,1,6.1-6.1Z"
                    transform="matrix(0.989, 0.148, -0.148, 0.989, 42.86, -60.53)"
                    fill="#707171"
                    stroke="#707171"
                    strokeMiterlimit="10"
                    strokeWidth="1"
                />
                <rect
                    id="Rectangle_92"
                    data-name="Rectangle 92"
                    width="138.53"
                    height="80.28"
                    rx="4.94"
                    transform="matrix(-0.998, 0.055, -0.055, -0.998, 352.291, 433.961)"
                    fill="#f4f5f6"
                />
                <path
                    id="Path_138"
                    data-name="Path 138"
                    d="M209.88,357.63H348.41v11.49a4.94,4.94,0,0,1-4.94,4.94H214.82a4.94,4.94,0,0,1-4.94-4.94Z"
                    transform="matrix(-0.998, 0.055, -0.055, -0.998, 578.15, 715.65)"
                    fill="#707171"
                />
                <text
                    id="EFFICIENCY"
                    transform="matrix(0.998, -0.055, 0.055, 0.998, 245.2, 372.2)"
                    fill="#fff"
                    fontSize="9.94"
                    fontFamily="Montserrat-Bold, Montserrat"
                    fontWeight="700"
                    letterSpacing="0.079em"
                >
                    <tspan x="0" y="0">
                        EFFICIEN
                    </tspan>
                    <tspan y="0" letterSpacing="0.069em">
                        C
                    </tspan>
                    <tspan y="0">Y</tspan>
                </text>
                <path
                    id="Path_139"
                    data-name="Path 139"
                    d="M224.25,392.51l56.45-3.13a1.613,1.613,0,0,0-.18-3.22l-56.44,3.14a1.61,1.61,0,0,0,.17,3.21Z"
                    fill="#e3e2e3"
                />
                <path
                    id="Path_140"
                    data-name="Path 140"
                    d="M225.21,409.8l56.45-3.13a1.613,1.613,0,0,0-.18-3.22L225,406.59a1.61,1.61,0,0,0,.17,3.21Z"
                    fill="#e3e2e3"
                />
                <path
                    id="Path_141"
                    data-name="Path 141"
                    d="M224.73,401.16,281.18,398a1.61,1.61,0,1,0-.18-3.21l-56.44,3.13a1.612,1.612,0,0,0,.17,3.22Z"
                    fill="#e3e2e3"
                />
                <path
                    id="Path_142"
                    data-name="Path 142"
                    d="M225.69,418.45l56.45-3.14a1.61,1.61,0,1,0-.18-3.21l-56.44,3.13a1.612,1.612,0,0,0,.17,3.22Z"
                    fill="#e3e2e3"
                />
                <path
                    id="Path_143"
                    data-name="Path 143"
                    d="M226.17,427.09,282.62,424a1.613,1.613,0,0,0-.18-3.22L226,423.88a1.61,1.61,0,0,0,.17,3.21Z"
                    fill="#e3e2e3"
                />
                <rect
                    id="Rectangle_93"
                    data-name="Rectangle 93"
                    width="47.84"
                    height="37.86"
                    rx="3.65"
                    transform="matrix(0.998, -0.055, 0.055, 0.998, 290.327, 385.628)"
                    fill="#707171"
                />
                <line
                    id="Line_22"
                    data-name="Line 22"
                    y1="2.15"
                    x2="38.8"
                    transform="translate(295.11 388.83)"
                    fill="none"
                    stroke="#f4f5f6"
                    strokeLinecap="round"
                    strokeMiterlimit="10"
                    strokeWidth="0.74"
                    opacity="0.5"
                />
                <line
                    id="Line_23"
                    data-name="Line 23"
                    y1="2.15"
                    x2="38.81"
                    transform="translate(295.4 394.15)"
                    fill="none"
                    stroke="#f4f5f6"
                    strokeLinecap="round"
                    strokeMiterlimit="10"
                    strokeWidth="0.74"
                    opacity="0.5"
                />
                <line
                    id="Line_24"
                    data-name="Line 24"
                    y1="2.15"
                    x2="38.8"
                    transform="translate(295.7 399.47)"
                    fill="none"
                    stroke="#f4f5f6"
                    strokeLinecap="round"
                    strokeMiterlimit="10"
                    strokeWidth="0.74"
                    opacity="0.5"
                />
                <line
                    id="Line_25"
                    data-name="Line 25"
                    y1="2.16"
                    x2="38.81"
                    transform="translate(295.99 404.79)"
                    fill="none"
                    stroke="#f4f5f6"
                    strokeLinecap="round"
                    strokeMiterlimit="10"
                    strokeWidth="0.74"
                    opacity="0.5"
                />
                <line
                    id="Line_26"
                    data-name="Line 26"
                    y1="2.16"
                    x2="38.81"
                    transform="translate(296.29 410.11)"
                    fill="none"
                    stroke="#f4f5f6"
                    strokeLinecap="round"
                    strokeMiterlimit="10"
                    strokeWidth="0.74"
                    opacity="0.5"
                />
                <line
                    id="Line_27"
                    data-name="Line 27"
                    y1="2.16"
                    x2="38.81"
                    transform="translate(296.58 415.43)"
                    fill="none"
                    stroke="#f4f5f6"
                    strokeLinecap="round"
                    strokeMiterlimit="10"
                    strokeWidth="0.74"
                    opacity="0.5"
                />
                <path
                    id="Path_144"
                    data-name="Path 144"
                    d="M297.46,416.93a1.11,1.11,0,0,1-.65-.18,1.06,1.06,0,0,1-.28-1.46l6.14-9a1.043,1.043,0,0,1,1.64-.12l4,4.35,7.1-11.76a1.06,1.06,0,0,1,.77-.5,1.08,1.08,0,0,1,.87.3l4.31,4.3,9.11-13.36a1.05,1.05,0,1,1,1.73,1.19l-9.81,14.4a1.05,1.05,0,0,1-.77.45,1,1,0,0,1-.84-.3l-4.3-4.24-7.13,11.82a1.06,1.06,0,0,1-.8.5,1,1,0,0,1-.88-.34l-4-4.41-5.38,7.89A1.07,1.07,0,0,1,297.46,416.93Z"
                    fill="#fff"
                />
                <path
                    id="Path_145"
                    data-name="Path 145"
                    d="M332.8,393.64a1.05,1.05,0,0,1-1.09-.85l-.5-2.61-2.62.5a1.049,1.049,0,1,1-.4-2.06l3.65-.71a1,1,0,0,1,1.23.84l.71,3.64a1.06,1.06,0,0,1-.84,1.24Z"
                    fill="#fff"
                />
                <path
                    id="Path_146"
                    data-name="Path 146"
                    d="M250,168.89c-24.12-21.62-44.79-50.62-58.21-81.72l6.76-3c12.83,30.24,37.4,54.94,62.74,69.26"
                    fill="#f4f5f6"
                />
                <path
                    id="Path_147"
                    data-name="Path 147"
                    d="M181.62,92.41,159.72,49,159,60.81a52.192,52.192,0,0,1-1.16,8.73,49.614,49.614,0,0,1-1.47,5.3,33.91,33.91,0,0,0,.78,23.65l2.13,5.18.05.11Z"
                    fill="#707171"
                    stroke="#707171"
                    strokeMiterlimit="10"
                    strokeWidth="1"
                />
                <path
                    id="Path_148"
                    data-name="Path 148"
                    d="M155.91,44.46l3.56,7.07L159,60.81a52.192,52.192,0,0,1-1.16,8.73L147.39,48.75a4.77,4.77,0,1,1,8.52-4.29Z"
                    fill="#e3e2e3"
                />
                <path
                    id="Path_149"
                    data-name="Path 149"
                    d="M180.46,93.15c.62,1.23-3.61,4.61-9.43,7.54S160,105,159.35,103.79s3.61-4.61,9.43-7.54S179.84,91.92,180.46,93.15Z"
                    fill="#e3e2e3"
                />
                <path
                    id="Path_150"
                    data-name="Path 150"
                    d="M230.49,67.93l-.07-.11L227.53,63A33.92,33.92,0,0,0,209,48.34a51.45,51.45,0,0,1-5.14-2,50.759,50.759,0,0,1-7.71-4.25l-9.92-6.49L208.1,79.05Z"
                    fill="#707171"
                    stroke="#707171"
                    strokeMiterlimit="10"
                    strokeWidth="1"
                />
                <path
                    id="Path_151"
                    data-name="Path 151"
                    d="M184.85,29.87l3.56,7.07,7.72,5.17a50.759,50.759,0,0,0,7.71,4.25L193.36,25.58a4.77,4.77,0,1,0-8.51,4.29Z"
                    fill="#e3e2e3"
                />
                <path
                    id="Path_152"
                    data-name="Path 152"
                    d="M209.39,78.56c.62,1.23,5.85-.15,11.67-3.09s10.05-6.31,9.43-7.54-5.84.15-11.67,3.09S208.77,77.34,209.39,78.56Z"
                    fill="#e3e2e3"
                />
                <path
                    id="Path_153"
                    data-name="Path 153"
                    d="M163.88,8.52l-12.67,6.39,1.07-8.15a3,3,0,0,1,4.43-2.23Z"
                    fill="#707171"
                    stroke="#707171"
                    strokeMiterlimit="10"
                    strokeWidth="1"
                />
                <path
                    id="Path_154"
                    data-name="Path 154"
                    d="M183.77,27.72l25.56,50.71-29,14.6L154.82,42.32a41.19,41.19,0,0,1-4.13-23.38l.52-4,12.67-6.39,3.56,2a41.21,41.21,0,0,1,16.33,17.17Z"
                    fill="#fff"
                />
                <path
                    id="Path_155"
                    data-name="Path 155"
                    d="M171.88,55.91A52,52,0,0,0,202.21,82L180.38,93,154.82,42.3A41.26,41.26,0,0,1,150.68,19l.53-4,12.67-6.39,3.56,2q1.49.84,2.88,1.8a52.08,52.08,0,0,0,1.56,43.5Z"
                    fill="#e3e2e3"
                />
                <path
                    id="Path_156"
                    data-name="Path 156"
                    d="M181.89,85.46H210v2.91a1.51,1.51,0,0,1-1.51,1.51h-25.1a1.51,1.51,0,0,1-1.51-1.51V85.46Z"
                    transform="translate(-18.49 97.58) rotate(-26.75)"
                    fill="#707171"
                    stroke="#707171"
                    strokeMiterlimit="10"
                    strokeWidth="1"
                />
                <path
                    id="Path_157"
                    data-name="Path 157"
                    d="M206.88,84.61l.28.54a5,5,0,0,1-1.33,6.16,5.451,5.451,0,0,1-.89.58l-11,5.53a4.84,4.84,0,0,1-1,.37,5,5,0,0,1-5.74-2.59l-.27-.55Z"
                    fill="#e3e2e3"
                />
                <path
                    id="Path_158"
                    data-name="Path 158"
                    d="M182.33,40.24a9.29,9.29,0,1,1-12.47-4.11,9.29,9.29,0,0,1,12.47,4.11Z"
                    fill="#707171"
                    stroke="#707171"
                    strokeMiterlimit="10"
                    strokeWidth="1"
                />
                <path
                    id="Path_159"
                    data-name="Path 159"
                    d="M205.83,91.31a5.451,5.451,0,0,1-.89.58l-11,5.53a4.84,4.84,0,0,1-1,.37c-.38-1.15,2-3.41,5.47-5.15S205.13,90.32,205.83,91.31Z"
                    fill="#707171"
                    stroke="#707171"
                    strokeMiterlimit="10"
                    strokeWidth="1"
                />
                <rect
                    id="Rectangle_94"
                    data-name="Rectangle 94"
                    width="19.25"
                    height="5.55"
                    rx="1.82"
                    transform="translate(175.658 68.271) rotate(-26.75)"
                    fill="#707171"
                    stroke="#707171"
                    strokeMiterlimit="10"
                    strokeWidth="1"
                />
                <path
                    id="Path_160"
                    data-name="Path 160"
                    d="M78.66,202.33H195.12v77.79a2.9,2.9,0,0,1-2.9,2.9H81.56a2.9,2.9,0,0,1-2.9-2.9V202.33Z"
                    transform="translate(-63.6 49.2) rotate(-16.59)"
                    fill="#f4f5f6"
                />
                <path
                    id="Path_161"
                    data-name="Path 161"
                    d="M152.41,280.14,95.56,297.08A3.08,3.08,0,0,1,91.73,295L69.57,220.64l31.18-9.29a66.16,66.16,0,0,0,51.66,68.79Z"
                    fill="#707171"
                    opacity="0.09"
                />
                <path
                    id="Path_162"
                    data-name="Path 162"
                    d="M72,182.92H172.62a7.91,7.91,0,0,1,7.91,7.91v13.63H64.07V190.83A7.91,7.91,0,0,1,72,182.92Z"
                    transform="translate(-50.22 42.99) rotate(-16.59)"
                    fill="#707171"
                />
                <path
                    id="Path_163"
                    data-name="Path 163"
                    d="M81.85,200.69a3.94,3.94,0,1,0-3.75,6.93,3.87,3.87,0,0,0,3,.31,3.94,3.94,0,0,0,.75-7.24Z"
                    fill="#fff"
                />
                <path
                    id="Path_164"
                    data-name="Path 164"
                    d="M95.65,195.37a3.94,3.94,0,0,0,1.12,7.72,4,4,0,0,0,1.13-.17,3.939,3.939,0,0,0-2.25-7.55Z"
                    fill="#fff"
                />
                <path
                    id="Path_165"
                    data-name="Path 165"
                    d="M132.26,185.66a3.944,3.944,0,1,0-3.75,6.94,4,4,0,0,0,3,.31,3.95,3.95,0,0,0,.75-7.25Z"
                    fill="#fff"
                />
                <path
                    id="Path_166"
                    data-name="Path 166"
                    d="M115.46,190.67a3.94,3.94,0,0,0-5.34,1.59,3.83,3.83,0,0,0-.31,3,3.915,3.915,0,0,0,4.9,2.65,3.94,3.94,0,0,0,.75-7.24Z"
                    fill="#f4f5f6"
                />
                <path
                    id="Path_167"
                    data-name="Path 167"
                    d="M149.07,180.66a3.94,3.94,0,1,0-1.88,7.4,4.06,4.06,0,0,0,1.13-.16,3.94,3.94,0,0,0,.75-7.24Z"
                    fill="#fff"
                />
                <path
                    id="Path_168"
                    data-name="Path 168"
                    d="M167.77,178a3.952,3.952,0,1,0-2.65,4.9,3.939,3.939,0,0,0,2.65-4.9Z"
                    fill="#fff"
                />
                <path
                    id="Path_169"
                    data-name="Path 169"
                    d="M79.8,205.84a2.29,2.29,0,0,1-2.19-1.63l-3-10.17a2.28,2.28,0,1,1,4.37-1.3l3,10.17a2.29,2.29,0,0,1-1.53,2.84,2.478,2.478,0,0,1-.65.09Z"
                    fill="#e3e2e3"
                />
                <path
                    id="Path_170"
                    data-name="Path 170"
                    d="M96.6,200.83a2.27,2.27,0,0,1-2.18-1.63l-3-10.16a2.28,2.28,0,1,1,4.37-1.3l3,10.16a2.29,2.29,0,0,1-1.54,2.84A2.37,2.37,0,0,1,96.6,200.83Z"
                    fill="#e3e2e3"
                />
                <path
                    id="Path_171"
                    data-name="Path 171"
                    d="M113.41,195.83a2.29,2.29,0,0,1-2.19-1.63l-3-10.17a2.28,2.28,0,1,1,4.37-1.3l3,10.17a2.28,2.28,0,0,1-1.53,2.83A2.5,2.5,0,0,1,113.41,195.83Z"
                    fill="#e3e2e3"
                />
                <path
                    id="Path_172"
                    data-name="Path 172"
                    d="M130.21,190.82a2.27,2.27,0,0,1-2.18-1.63L125,179a2.275,2.275,0,0,1,4.36-1.3l3,10.17a2.27,2.27,0,0,1-1.53,2.83A2.1,2.1,0,0,1,130.21,190.82Z"
                    fill="#e3e2e3"
                />
                <path
                    id="Path_173"
                    data-name="Path 173"
                    d="M147,185.81a2.28,2.28,0,0,1-2.18-1.63l-3-10.17a2.28,2.28,0,1,1,4.37-1.3l3,10.17a2.27,2.27,0,0,1-1.53,2.83,2.16,2.16,0,0,1-.66.1Z"
                    fill="#e3e2e3"
                />
                <path
                    id="Path_174"
                    data-name="Path 174"
                    d="M163.82,180.8a2.27,2.27,0,0,1-2.18-1.63l-3-10.16A2.28,2.28,0,1,1,163,167.7l3,10.17a2.28,2.28,0,0,1-1.53,2.84,2.479,2.479,0,0,1-.65.09Z"
                    fill="#e3e2e3"
                />
                <rect
                    id="Rectangle_95"
                    data-name="Rectangle 95"
                    width="16.59"
                    height="14.68"
                    transform="translate(86.057 228.351) rotate(-16.59)"
                    fill="#e3e2e3"
                />
                <rect
                    id="Rectangle_96"
                    data-name="Rectangle 96"
                    width="16.59"
                    height="14.68"
                    transform="translate(109.256 221.435) rotate(-16.59)"
                    fill="#e3e2e3"
                />
                <rect
                    id="Rectangle_97"
                    data-name="Rectangle 97"
                    width="16.59"
                    height="14.68"
                    transform="translate(132.467 214.526) rotate(-16.59)"
                    fill="#e3e2e3"
                />
                <path
                    id="Path_175"
                    data-name="Path 175"
                    d="M136.9,218a.8.8,0,0,1-.75-.56.78.78,0,0,1,.53-1l9.25-2.76a.782.782,0,0,1,.44,1.5L137.13,218a.62.62,0,0,1-.23,0Z"
                    fill="#707171"
                />
                <path
                    id="Path_176"
                    data-name="Path 176"
                    d="M137.9,221.33a.78.78,0,0,1-.22-1.53l9.24-2.75a.78.78,0,1,1,.45,1.49l-9.25,2.76A.83.83,0,0,1,137.9,221.33Z"
                    fill="#707171"
                />
                <path
                    id="Path_177"
                    data-name="Path 177"
                    d="M138.9,224.68a.78.78,0,0,1-.23-1.53l9.25-2.76a.783.783,0,1,1,.45,1.5l-9.25,2.75A.57.57,0,0,1,138.9,224.68Z"
                    fill="#707171"
                />
                <rect
                    id="Rectangle_98"
                    data-name="Rectangle 98"
                    width="16.59"
                    height="14.68"
                    transform="translate(155.675 207.61) rotate(-16.59)"
                    fill="#e3e2e3"
                />
                <rect
                    id="Rectangle_99"
                    data-name="Rectangle 99"
                    width="16.59"
                    height="14.68"
                    transform="translate(92.069 248.535) rotate(-16.59)"
                    fill="#e3e2e3"
                />
                <rect
                    id="Rectangle_100"
                    data-name="Rectangle 100"
                    width="16.637"
                    height="14.722"
                    transform="translate(116.541 241.491) rotate(-16.809)"
                    fill="#e3e2e3"
                />
                <rect
                    id="Rectangle_101"
                    data-name="Rectangle 101"
                    width="16.59"
                    height="14.68"
                    transform="translate(138.478 234.71) rotate(-16.59)"
                    fill="#e3e2e3"
                />
                <rect
                    id="Rectangle_102"
                    data-name="Rectangle 102"
                    width="16.59"
                    height="14.68"
                    transform="translate(161.689 227.8) rotate(-16.59)"
                    fill="#e3e2e3"
                />
                <rect
                    id="Rectangle_103"
                    data-name="Rectangle 103"
                    width="16.59"
                    height="14.68"
                    transform="translate(97.994 268.433) rotate(-16.59)"
                    fill="#e3e2e3"
                />
                <rect
                    id="Rectangle_104"
                    data-name="Rectangle 104"
                    width="16.59"
                    height="14.68"
                    transform="translate(121.205 261.524) rotate(-16.59)"
                    fill="#e3e2e3"
                />
                <rect
                    id="Rectangle_105"
                    data-name="Rectangle 105"
                    width="16.59"
                    height="14.68"
                    transform="translate(144.403 254.608) rotate(-16.59)"
                    fill="#e3e2e3"
                />
                <rect
                    id="Rectangle_106"
                    data-name="Rectangle 106"
                    width="16.59"
                    height="14.68"
                    transform="translate(167.614 247.699) rotate(-16.59)"
                    fill="#e3e2e3"
                />
                <path
                    id="Path_178"
                    data-name="Path 178"
                    d="M125.64,265a.8.8,0,0,1-.75-.56.79.79,0,0,1,.53-1l9.25-2.76a.782.782,0,0,1,.44,1.5L125.87,265a.619.619,0,0,1-.23,0Z"
                    fill="#707171"
                />
                <path
                    id="Path_179"
                    data-name="Path 179"
                    d="M126.64,268.33a.78.78,0,0,1-.22-1.53l9.24-2.75a.79.79,0,0,1,1,.52.78.78,0,0,1-.53,1l-9.25,2.76a.83.83,0,0,1-.24,0Z"
                    fill="#707171"
                />
                <path
                    id="Path_180"
                    data-name="Path 180"
                    d="M127.64,271.68a.78.78,0,0,1-.23-1.53l9.25-2.76a.783.783,0,1,1,.45,1.5l-9.25,2.76a.828.828,0,0,1-.22.03Z"
                    fill="#707171"
                />
                <path
                    id="Path_181"
                    data-name="Path 181"
                    d="M148.33,243.31a1.1,1.1,0,0,1-.81-.09l-3.3-1.78a1.07,1.07,0,0,1,1-1.88l2.35,1.27,3.06-5.66a1.07,1.07,0,0,1,1.89,1l-3.57,6.6A1.09,1.09,0,0,1,148.33,243.31Z"
                    fill="#707171"
                />
                <path
                    id="Path_182"
                    data-name="Path 182"
                    d="M95.84,236.73a1.1,1.1,0,0,1-.81-.09l-3.3-1.78a1.07,1.07,0,1,1,1-1.88l2.36,1.27,3.06-5.65a1.07,1.07,0,1,1,1.88,1l-3.56,6.6a1.12,1.12,0,0,1-.63.53Z"
                    fill="#707171"
                />
                <path
                    id="Path_183"
                    data-name="Path 183"
                    d="M171.54,236.39a1.07,1.07,0,0,1-.82-.08l-3.3-1.79a1.07,1.07,0,0,1,1-1.88l2.36,1.28,3.06-5.66a1.07,1.07,0,1,1,1.88,1l-3.57,6.59a1.07,1.07,0,0,1-.61.54Z"
                    fill="#707171"
                />
                <rect
                    id="Rectangle_107"
                    data-name="Rectangle 107"
                    width="7.07"
                    height="4.23"
                    transform="translate(435.443 111.69) rotate(-135)"
                    fill="#e3e2e3"
                />
                <rect
                    id="Rectangle_108"
                    data-name="Rectangle 108"
                    width="14.21"
                    height="8.77"
                    rx="3.48"
                    transform="translate(440.563 111.619) rotate(-135)"
                    fill="#707171"
                    stroke="#707171"
                    strokeMiterlimit="10"
                    strokeWidth="1"
                />
                <rect
                    id="Rectangle_109"
                    data-name="Rectangle 109"
                    width="13.46"
                    height="4.61"
                    transform="translate(395.66 91.23) rotate(180)"
                    fill="#e3e2e3"
                />
                <rect
                    id="Rectangle_110"
                    data-name="Rectangle 110"
                    width="27.08"
                    height="14.2"
                    rx="2.81"
                    transform="translate(402.47 86.52) rotate(180)"
                    fill="#707171"
                    stroke="#707171"
                    strokeMiterlimit="10"
                    strokeWidth="1"
                />
                <circle
                    id="Ellipse_10"
                    data-name="Ellipse 10"
                    cx="62.99"
                    cy="62.99"
                    r="62.99"
                    transform="matrix(0.971, -0.238, 0.238, 0.971, 312.683, 107.111)"
                    fill="#707171"
                    stroke="#707171"
                    strokeMiterlimit="10"
                    strokeWidth="1"
                />
                <circle
                    id="Ellipse_11"
                    data-name="Ellipse 11"
                    cx="57.22"
                    cy="57.22"
                    r="57.22"
                    transform="translate(307.954 153.258) rotate(-45)"
                    fill="#fff"
                />
                <path
                    id="Path_184"
                    data-name="Path 184"
                    d="M401.57,97.45a57.23,57.23,0,1,0,0,111.62,57.24,57.24,0,0,1,0-111.62Z"
                    fill="#f4f5f6"
                />
                <path
                    id="Path_185"
                    data-name="Path 185"
                    d="M388.87,155.13a1.87,1.87,0,0,0,1.87-1.87v-41.9a1.87,1.87,0,0,0-3.74,0v41.9a1.87,1.87,0,0,0,1.87,1.87Z"
                    fill="#e3e2e3"
                />
                <path
                    id="Path_186"
                    data-name="Path 186"
                    d="M405,182.78a1.87,1.87,0,0,0,1.62-2.81l-16.1-27.65a1.873,1.873,0,1,0-3.24,1.88l16.11,27.65a1.85,1.85,0,0,0,1.61.93Z"
                    fill="#e3e2e3"
                />
                <path
                    id="Path_187"
                    data-name="Path 187"
                    d="M383.73,153.26a5.14,5.14,0,1,0,5.14-5.14,5.14,5.14,0,0,0-5.14,5.14Z"
                    fill="#707171"
                    stroke="#707171"
                    strokeMiterlimit="10"
                    strokeWidth="1"
                />
                <path
                    id="Path_188"
                    data-name="Path 188"
                    d="M388.87,106.23a.94.94,0,0,0,.94-.93V99.6a.94.94,0,1,0-1.88,0v5.7a.94.94,0,0,0,.94.93Z"
                    fill="#707171"
                    stroke="#707171"
                    strokeMiterlimit="10"
                    strokeWidth="1"
                />
                <path
                    id="Path_189"
                    data-name="Path 189"
                    d="M412.85,112.66a.94.94,0,0,0,.81-.47l2.85-4.93a.94.94,0,0,0-1.62-.94L412,111.25a.94.94,0,0,0,.34,1.28.9.9,0,0,0,.51.13Z"
                    fill="#707171"
                    stroke="#707171"
                    strokeMiterlimit="10"
                    strokeWidth="1"
                />
                <path
                    id="Path_190"
                    data-name="Path 190"
                    d="M430.41,130.21a1,1,0,0,0,.47-.12l4.93-2.85a.94.94,0,1,0-.94-1.62l-4.93,2.85a.93.93,0,0,0,.47,1.74Z"
                    fill="#707171"
                    stroke="#707171"
                    strokeMiterlimit="10"
                    strokeWidth="1"
                />
                <path
                    id="Path_191"
                    data-name="Path 191"
                    d="M436.83,154.2h5.7a.94.94,0,1,0,0-1.88h-5.7a.94.94,0,1,0,0,1.88Z"
                    fill="#707171"
                    stroke="#707171"
                    strokeMiterlimit="10"
                    strokeWidth="1"
                />
                <path
                    id="Path_192"
                    data-name="Path 192"
                    d="M435.34,181a.94.94,0,0,0,.47-1.75l-4.93-2.85a.94.94,0,1,0-.94,1.62l4.93,2.85A1,1,0,0,0,435.34,181Z"
                    fill="#707171"
                    stroke="#707171"
                    strokeMiterlimit="10"
                    strokeWidth="1"
                />
                <path
                    id="Path_193"
                    data-name="Path 193"
                    d="M415.7,200.67a.9.9,0,0,0,.47-.13.94.94,0,0,0,.34-1.28l-2.85-4.93a.94.94,0,0,0-1.62.94l2.85,4.93A.94.94,0,0,0,415.7,200.67Z"
                    fill="#707171"
                    stroke="#707171"
                    strokeMiterlimit="10"
                    strokeWidth="1"
                />
                <path
                    id="Path_194"
                    data-name="Path 194"
                    d="M388.87,207.85a.93.93,0,0,0,.94-.93v-5.7a.94.94,0,1,0-1.88,0v5.7a.93.93,0,0,0,.94.93Z"
                    fill="#707171"
                    stroke="#707171"
                    strokeMiterlimit="10"
                    strokeWidth="1"
                />
                <path
                    id="Path_195"
                    data-name="Path 195"
                    d="M362,200.67a.94.94,0,0,0,.81-.47l2.85-4.93a.94.94,0,1,0-1.62-.94l-2.85,4.93a.94.94,0,0,0,.34,1.28.9.9,0,0,0,.47.13Z"
                    fill="#707171"
                    stroke="#707171"
                    strokeMiterlimit="10"
                    strokeWidth="1"
                />
                <path
                    id="Path_196"
                    data-name="Path 196"
                    d="M342.4,181a1,1,0,0,0,.47-.13l4.93-2.85a.94.94,0,0,0-.94-1.62l-4.93,2.85a.94.94,0,0,0,.47,1.75Z"
                    fill="#707171"
                    stroke="#707171"
                    strokeMiterlimit="10"
                    strokeWidth="1"
                />
                <path
                    id="Path_197"
                    data-name="Path 197"
                    d="M335.21,154.2h5.7a.94.94,0,0,0,0-1.88h-5.7a.94.94,0,0,0,0,1.88Z"
                    fill="#707171"
                    stroke="#707171"
                    strokeMiterlimit="10"
                    strokeWidth="1"
                />
                <path
                    id="Path_198"
                    data-name="Path 198"
                    d="M347.33,130.21a.91.91,0,0,0,.81-.46.93.93,0,0,0-.34-1.28l-4.93-2.85a.94.94,0,1,0-.94,1.62l4.93,2.85A1,1,0,0,0,347.33,130.21Z"
                    fill="#707171"
                    stroke="#707171"
                    strokeMiterlimit="10"
                    strokeWidth="1"
                />
                <path
                    id="Path_199"
                    data-name="Path 199"
                    d="M364.89,112.66a.9.9,0,0,0,.47-.13.94.94,0,0,0,.34-1.28l-2.85-4.93a.94.94,0,1,0-1.62.94l2.85,4.93a.94.94,0,0,0,.81.47Z"
                    fill="#707171"
                    stroke="#707171"
                    strokeMiterlimit="10"
                    strokeWidth="1"
                />
                <path
                    id="Path_200"
                    data-name="Path 200"
                    d="M290.63,234.1c19.09-14,54.27-44.76,54.27-80.42H212.39c0,35.66,35.18,66.38,54.26,80.42-19.08,14.05-54.26,44.77-54.26,80.43H344.9C344.9,278.87,309.72,248.15,290.63,234.1Z"
                    fill="#f4f5f6"
                />
                <path
                    id="Path_201"
                    data-name="Path 201"
                    d="M280.69,234.1c-19.09,14.05-54.3,44.76-54.3,80.43H212.12c0-35.67,35.21-66.38,54.31-80.43-19.1-14-54.31-44.76-54.31-80.42h14.27C226.39,189.34,261.6,220.05,280.69,234.1Z"
                    fill="#707171"
                    opacity="0.08"
                />
                <path
                    id="Path_202"
                    data-name="Path 202"
                    d="M338.09,300a22.4,22.4,0,0,0-15-15.31c-8.75-2.79-20.77-5.43-31.79-3.8a2.112,2.112,0,0,1-.44,0,4,4,0,0,1-3.54-3.56l-4.59-40.6a5.46,5.46,0,0,1,2.17-5l2.84-2.1c10.41-7.64,24.57-19.45,35.51-33.82a2.13,2.13,0,0,0-2-3.41c-11.13,1.65-33.9,3.62-53-3.77-16.64-6.47-25.62-1.47-30,3.08a6.91,6.91,0,0,0-.14,9.24,186.024,186.024,0,0,0,31.38,28.68l2.76,2a5.48,5.48,0,0,1,2.16,5.15l-5.55,39.26a4,4,0,0,1-5.07,3.27l-1.95-.57a61.249,61.249,0,0,0-19.51-2.38h0a22,22,0,0,0-19.11,12.48,62.059,62.059,0,0,0-5.76,20.24h122.3A56.619,56.619,0,0,0,338.09,300Z"
                    fill="#e3e2e3"
                />
                <path
                    id="Path_203"
                    data-name="Path 203"
                    d="M339.25,142.07H218a11.61,11.61,0,0,0-11.61,11.61H350.86A11.61,11.61,0,0,0,339.25,142.07Z"
                    fill="#707171"
                />
                <path
                    id="Path_204"
                    data-name="Path 204"
                    d="M218,326.13H339.25a11.6,11.6,0,0,0,11.61-11.6H206.43A11.6,11.6,0,0,0,218,326.13Z"
                    fill="#707171"
                />
                <path
                    id="Path_205"
                    data-name="Path 205"
                    d="M115.82,362.37a30.45,30.45,0,1,1,55.75-1.95,33.57,33.57,0,0,0-2.5,14,25.42,25.42,0,0,0,2,9.49L162,388.28l-9,4.36a25.51,25.51,0,0,0-6.14-7.52,33.55,33.55,0,0,0-12.51-6.83,30.36,30.36,0,0,1-18.53-15.92Z"
                    fill="#e3e2e3"
                />
                <path
                    id="Path_206"
                    data-name="Path 206"
                    d="M132.07,353.07a1,1,0,0,1,1.4.18l2.51,3.3a4.43,4.43,0,0,1,3.21.21,2.32,2.32,0,0,0,2.1.13c.74-.35.92-.84,1.22-1.72a4.1,4.1,0,0,1,5.91-2.84,2.32,2.32,0,0,0,2.1.13c.73-.35.91-.83,1.2-1.72a4.48,4.48,0,0,1,1.85-2.63l-1-4a1,1,0,1,1,1.94-.48l11,44.33a1,1,0,0,1-.54,1.14l-.19.08a1,1,0,0,1-1.21-.73L154.1,350.3a4.17,4.17,0,0,0-.47,1.09,4.1,4.1,0,0,1-5.91,2.83,2.32,2.32,0,0,0-2.1-.13c-.74.35-.92.84-1.22,1.72a4.09,4.09,0,0,1-5.91,2.84,4.591,4.591,0,0,0-1.16-.31l23.84,31.25a.994.994,0,1,1-1.57,1.22l-27.7-36.32a1,1,0,0,1,.17-1.42Z"
                    fill="#f4f5f6"
                />
                <path
                    id="Path_207"
                    data-name="Path 207"
                    d="M158.72,387.44h14a3.06,3.06,0,0,1,3.06,3.06v14H155.66v-14A3.06,3.06,0,0,1,158.72,387.44Z"
                    transform="translate(486.44 681.31) rotate(154.37)"
                    fill="#fff"
                />
                <path
                    id="Path_208"
                    data-name="Path 208"
                    d="M168.64,408.39l4.93-2.37a3.9,3.9,0,0,0,1.83-5.2h0l-12,5.74h0a3.9,3.9,0,0,0,5.24,1.83Z"
                    fill="#707171"
                />
                <path
                    id="Path_209"
                    data-name="Path 209"
                    d="M154.15,399.18a1.37,1.37,0,0,1-.76-.68,1.34,1.34,0,0,1,.63-1.78l19.59-9.41a1.34,1.34,0,0,1,1.16,2.41l-19.6,9.4a1.34,1.34,0,0,1-1.02.06Z"
                    fill="#707171"
                />
                <path
                    id="Path_210"
                    data-name="Path 210"
                    d="M156.81,404.72a1.35,1.35,0,0,1-.76-.69,1.33,1.33,0,0,1,.63-1.78l19.59-9.4a1.34,1.34,0,1,1,1.15,2.41l-19.59,9.4A1.34,1.34,0,0,1,156.81,404.72Z"
                    fill="#707171"
                />
                <circle
                    id="Ellipse_12"
                    data-name="Ellipse 12"
                    cx="6.45"
                    cy="6.45"
                    r="6.45"
                    transform="translate(274.24 119.05)"
                    fill="#fff"
                    stroke="#e2e2e2"
                    strokeMiterlimit="10"
                    strokeWidth="2"
                />
                <circle
                    id="Ellipse_13"
                    data-name="Ellipse 13"
                    cx="10.42"
                    cy="10.42"
                    r="10.42"
                    transform="translate(245.11 89.44)"
                    fill="#fff"
                    stroke="#e2e2e2"
                    strokeMiterlimit="10"
                    strokeWidth="2"
                />
                <circle
                    id="Ellipse_14"
                    data-name="Ellipse 14"
                    cx="8.9"
                    cy="8.9"
                    r="8.9"
                    transform="translate(211.04 218.8)"
                    fill="#fff"
                    stroke="#e2e2e2"
                    strokeMiterlimit="10"
                    strokeWidth="2"
                />
                <circle
                    id="Ellipse_15"
                    data-name="Ellipse 15"
                    cx="8.9"
                    cy="8.9"
                    r="8.9"
                    transform="translate(73.18 311.99)"
                    fill="#fff"
                    stroke="#e2e2e2"
                    strokeMiterlimit="10"
                    strokeWidth="2"
                />
                <circle
                    id="Ellipse_16"
                    data-name="Ellipse 16"
                    cx="9.66"
                    cy="9.66"
                    r="9.66"
                    transform="translate(368.49 379.39)"
                    fill="#fff"
                    stroke="#e2e2e2"
                    strokeMiterlimit="10"
                    strokeWidth="2"
                />
                <path
                    id="Path_211"
                    data-name="Path 211"
                    d="M200.29,361.84h-7.56v-7.55a.94.94,0,0,0-.94-.94h0a1,1,0,0,0-1,.94v7.55H183.3a.97.97,0,1,0,0,1.94h7.53v7.56a1,1,0,0,0,1,.94.94.94,0,0,0,.94-.94h0v-7.56h7.56a.94.94,0,0,0,1-.94,1,1,0,0,0-1.04-1Z"
                    fill="#e2e2e2"
                    stroke="#e2e2e2"
                    strokeMiterlimit="10"
                    strokeWidth="0.75"
                />
                <path
                    id="Path_212"
                    data-name="Path 212"
                    d="M99.66,153.24H92.1v-7.55a.94.94,0,0,0-.94-.94h0a1,1,0,0,0-1,.94v7.55H82.67a1,1,0,0,0-1,1,.94.94,0,0,0,1,.94H90.2v7.56a1,1,0,0,0,1,.94.94.94,0,0,0,.94-.94h0v-7.56H99.7a.94.94,0,0,0,1-.94,1,1,0,0,0-1.04-1Z"
                    fill="#e2e2e2"
                    stroke="#e2e2e2"
                    strokeMiterlimit="10"
                    strokeWidth="0.75"
                />
                <path
                    id="Path_213"
                    data-name="Path 213"
                    d="M447.7,215.8h-6.49v-6.49a.81.81,0,0,0-.81-.81h0a.83.83,0,0,0-.83.81v6.49h-6.48a.82.82,0,0,0,0,1.63h6.48v6.5a.83.83,0,0,0,.83.81.82.82,0,0,0,.81-.81h0v-6.5h6.49a.82.82,0,1,0,0-1.63Z"
                    fill="#e2e2e2"
                    stroke="#e2e2e2"
                    strokeMiterlimit="10"
                    strokeWidth="0.75"
                />
                <path
                    id="Path_214"
                    data-name="Path 214"
                    d="M460.26,242.1h-4.5v-4.49a.56.56,0,0,0-.55-.56h0a.58.58,0,0,0-.58.56v4.49h-4.48a.57.57,0,0,0-.56.57.56.56,0,0,0,.56.56h4.48v4.5a.58.58,0,0,0,.58.56.56.56,0,0,0,.55-.56h0v-4.5h4.5a.57.57,0,1,0,0-1.13Z"
                    fill="#e2e2e2"
                    stroke="#e2e2e2"
                    strokeMiterlimit="10"
                    strokeWidth="0.75"
                />
            </g>
        </SVGIcon>
    );
}
