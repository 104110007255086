import React, { useEffect } from 'react';

import DCCrossClose from 'Assets/icons/DCCrossClose';
import CongratulationsImg from 'Assets/images/Congratulations.svg';
import ThumbImg from 'Assets/images/thumb.svg';
import { Button, Div, H3, H5, Image } from 'UIKit/index';

import 'Assets/styles/StepperSteps/style.scss';

const Congratulations = ({ title, message, doClose, onFinish }) => {
    useEffect(() => {
        return () => {
            onFinish && onFinish();
        };
    }, [onFinish]);

    return (
        <Div className={'CongratulationsBox'}>
            <Div className={'CBInner'}>
                <Div className={'CBIImg'}>
                    <Image src={CongratulationsImg} alt={''} displayName={''} />
                </Div>
                <Div className={'CBIDetail'}>
                    <H3>{title || 'Congratulations'}</H3>
                    <H5>{message || 'Your data import has completed successfully'}</H5>
                    <Div className={'CBIDBtn'}>
                        <Button
                            buttonType={'BlueFillBtn'}
                            iconName={<DCCrossClose />}
                            iconPlacement={'right'}
                            onClick={() => {
                                onFinish && onFinish();
                                doClose();
                            }}
                        >
                            Close
                        </Button>
                    </Div>
                    <Div className={'CBIDThumb'}>
                        <Image src={ThumbImg} alt={''} displayName={''} />
                    </Div>
                </Div>
            </Div>
        </Div>
    );
};

export default Congratulations;
