import React from 'react';

import { SVGIcon } from '../../UIKit/index';

export default function DCCheckList(props) {
    return (
        <SVGIcon {...props} viewBox="0 0 368.98 367.878">
            <g transform="translate(-2047.314 -356.993)">
                <g transform="translate(2047.314 356.993)">
                    <g transform="translate(30.595 200.086)">
                        <path
                            d="M166.811,423.9v24.558h-5.665a34.677,34.677,0,0,0,0-7.778c-.538-2.886-6.692-10.342-6.506-16.741Z"
                            transform="translate(-117.969 -282.803)"
                            fill="#e0e0e0"
                        />
                        <path
                            d="M132.75,300.13l1.008,121.881h35.556V300.13Z"
                            transform="translate(-117.496 -280.131)"
                            fill="#e0e0e0"
                        />
                        <path
                            d="M119.43,279.69l9.95,20-12.22-.137,2.27-19.862"
                            transform="translate(-117.16 -279.69)"
                            fill="#ebebeb"
                        />
                        <path
                            d="M387.71,382.3c-.284.861,9.784,39.137,9.784,39.137L404.4,420l-9.5-37.7Z"
                            transform="translate(-122.999 -281.905)"
                            fill="#ebebeb"
                        />
                        <path
                            d="M442.115,376.71,428.3,421.316h8.062L449.6,379.88Z"
                            transform="translate(-123.875 -281.784)"
                            fill="#f5f5f5"
                        />
                        <path
                            d="M455.011,369.65,439.18,421.164h8.062L462.5,372.82Z"
                            transform="translate(-124.11 -281.632)"
                            fill="#ebebeb"
                        />
                        <path
                            d="M392.71,419.07l8.062,27.914h40.575l7.485-27.914Z"
                            transform="translate(-123.107 -282.698)"
                            fill="#e0e0e0"
                        />
                        <path
                            d="M413.655,417.8a2.545,2.545,0,0,1-.088-.294l-.215-.851c-.186-.783-.44-1.888-.773-3.3l-2.788-12.191-9.109-40.653.245.205h-7.1l.245-.313c3.61,16,6.849,30.331,9.168,40.683,1.135,5.156,2.055,9.315,2.7,12.23.294,1.419.538,2.524.7,3.317.069.362.127.646.166.861a.978.978,0,0,1,0,.3,2.531,2.531,0,0,1-.088-.293c-.059-.215-.127-.5-.215-.851-.2-.783-.46-1.888-.8-3.3-.675-2.935-1.644-7.054-2.847-12.191-2.378-10.352-5.655-24.666-9.315-40.653l-.078-.3h7.632l.049.2c3.513,16,6.673,30.331,8.953,40.682,1.106,5.156,2.006,9.315,2.632,12.23.294,1.419.519,2.524.685,3.317l.166.861A.979.979,0,0,1,413.655,417.8Z"
                            transform="translate(-123.124 -281.428)"
                            fill="#e0e0e0"
                        />
                    </g>

                    <g transform="translate(266.076 98.118)">
                        <path
                            d="M375.8,348.619c-16.985-13.7-19.451-37.111-17.318-57.864.362-3.464.225-7.152,2.084-10.087s5.87-4.745,8.874-3.072c2.495,1.389,3.4,4.471,4.677,7.045a20.391,20.391,0,0,0,7.612,8.336c2.133,1.3,4.824,2.192,7.084,1.135,3.111-1.448,3.747-5.557,3.963-9.011l1.2-19.314c.215-3.464.45-6.986,1.751-10.185s3.914-6.086,7.23-6.79,7.279,1.487,7.739,4.892a31.768,31.768,0,0,1-.2,4.3c0,1.438.558,3.072,1.888,3.561s2.8-.382,3.914-1.321c3.806-3.239,6.526-7.563,9.1-11.878s5.068-8.737,8.571-12.318,8.17-6.291,13.14-6.233,10.019,3.473,10.939,8.414-2.26,9.706-5.626,13.434a77.225,77.225,0,0,1-17.142,14.216,6.34,6.34,0,0,0-2.935,2.7c-.91,2.25,1.115,4.687,3.336,5.606,2.515,1.027,5.332.978,8,1.458s5.508,1.888,6.35,4.5c1.174,3.63-2.035,7.123-5.107,9.354a66.287,66.287,0,0,1-20.224,9.96c-2.612.753-5.342,1.38-7.514,3.023s-3.62,4.667-2.544,7.182,4.2,3.522,6.849,3.209,5.176-1.595,7.778-2.378c4.892-1.468,10.978-.714,13.7,3.591,1.81,2.837,1.732,6.6.5,9.784a25.321,25.321,0,0,1-5.822,8.18,66.532,66.532,0,0,1-29.724,17.983c-11.271,3.062-21.525,3.4-32.21-1.36"
                            transform="translate(-357.836 -176.645)"
                            fill="#00acfd"
                        />
                        <path
                            d="M374.84,364.114a152.374,152.374,0,0,1,7.593-28.071A311.01,311.01,0,0,1,397,303.569l4.256-8.317c1.409-2.73,2.8-5.43,4.325-7.994a156.96,156.96,0,0,1,9.6-14.207c3.278-4.373,6.536-8.4,9.618-12.152s6.037-7.172,8.855-10.185A123.632,123.632,0,0,1,448,237.5c1.81-1.419,3.248-2.475,4.237-3.18l1.125-.793a3.139,3.139,0,0,1,.391-.254,1.675,1.675,0,0,1-.352.3l-1.086.851c-.978.734-2.378,1.82-4.158,3.278a129.591,129.591,0,0,0-14.167,13.326c-2.788,3.023-5.714,6.467-8.806,10.215s-6.311,7.827-9.559,12.172a153.3,153.3,0,0,0-9.53,14.167c-1.517,2.554-2.935,5.235-4.3,7.964l-4.256,8.317a320.4,320.4,0,0,0-14.588,32.385,155.569,155.569,0,0,0-7.7,27.944"
                            transform="translate(-358.203 -176.719)"
                            fill="#018ed1"
                        />
                        <path
                            d="M406.4,287.574a6.7,6.7,0,0,1-.245-1.5c-.108-.978-.235-2.368-.362-4.109-.245-3.464-.431-8.277-.616-13.571s-.44-10.1-.744-13.561c-.147-1.722-.274-3.121-.382-4.09a6.565,6.565,0,0,1-.088-1.517,7.684,7.684,0,0,1,.333,1.477c.176.978.382,2.358.587,4.09.421,3.454.744,8.258.978,13.571s.323,9.96.431,13.58c.068,1.634.117,3,.166,4.109a7.929,7.929,0,0,1-.059,1.517Z"
                            transform="translate(-358.831 -177.064)"
                            fill="#018ed1"
                        />
                        <path
                            d="M451.417,283.046a8.942,8.942,0,0,1-1.79.1c-1.145,0-2.8,0-4.892.137-4.09.166-9.784.577-15.958,1.321s-11.809,1.654-15.841,2.436c-2.016.372-3.64.724-4.765.978a10.162,10.162,0,0,1-1.761.3,10.156,10.156,0,0,1,1.7-.548c1.106-.313,2.72-.724,4.736-1.164,4.012-.89,9.618-1.879,15.85-2.632a156.554,156.554,0,0,1,16.026-1.125c2.055,0,3.718,0,4.892.059A10.184,10.184,0,0,1,451.417,283.046Z"
                            transform="translate(-358.884 -177.79)"
                            fill="#018ed1"
                        />
                        <path
                            d="M382.813,336.205a2.016,2.016,0,0,1-.264-.558l-.656-1.663c-.558-1.438-1.311-3.552-2.2-6.164-1.79-5.235-4.041-12.543-6.418-20.635s-4.55-15.42-6.233-20.684c-.822-2.563-1.507-4.667-2.006-6.233l-.538-1.693a2.3,2.3,0,0,1-.147-.607,2.447,2.447,0,0,1,.264.558c.176.45.391.978.646,1.663.558,1.438,1.311,3.552,2.211,6.164,1.79,5.234,4.041,12.543,6.409,20.645s4.559,15.41,6.242,20.674c.822,2.563,1.5,4.667,1.957,6.233l.538,1.693A2.3,2.3,0,0,1,382.813,336.205Z"
                            transform="translate(-357.976 -177.684)"
                            fill="#018ed1"
                        />
                        <path
                            d="M443.53,322.19a1.958,1.958,0,0,1-.607.205l-1.751.46-6.458,1.614c-5.46,1.36-12.964,3.327-21.261,5.518s-15.831,4.09-21.32,5.342c-2.74.626-4.961,1.115-6.506,1.419l-1.781.342a2.312,2.312,0,0,1-.626.068,2.433,2.433,0,0,1,.6-.2l1.761-.46,6.458-1.614c5.45-1.36,12.964-3.336,21.261-5.528s15.831-4.09,21.31-5.342c2.74-.626,4.97-1.106,6.506-1.409l1.781-.342A2.757,2.757,0,0,1,443.53,322.19Z"
                            transform="translate(-358.384 -178.639)"
                            fill="#018ed1"
                        />
                    </g>
                    <g transform="translate(50.721 0)">
                        <rect width="237.276" height="325.128" fill="#455a64" />
                        <path
                            d="M366.059,381.274H157.93V88.62H366.059V381.274Z"
                            transform="translate(-138.166 -75.48)"
                            fill="#e0e0e0"
                        />
                        <path
                            d="M362.74,371.894H156.48V84.67H362.74V371.894Z"
                            transform="translate(-138.135 -71.53)"
                            fill="#ebebeb"
                        />
                        <path
                            d="M340.494,350.2l-23.756,19.132H152.55V85.42H360.679V334.014Z"
                            transform="translate(-138.05 -72.28)"
                            fill="#fafafa"
                        />
                        <path
                            d="M326.527,330.23,303.71,377.488H320Z"
                            transform="translate(-141.312 -80.695)"
                            fill="#e0e0e0"
                        />
                        <path
                            d="M320.36,377.758l6.526-47.258L364.3,342.045Z"
                            transform="translate(-141.672 -80.7)"
                            fill="#fafafa"
                        />
                        <g transform="translate(0 13)">
                            <g transform="translate(-30.246 -2.817)">
                                <rect
                                    width="31"
                                    height="32"
                                    rx="4"
                                    transform="translate(67.058 20.256)"
                                    fill="#ecf5ff"
                                />
                                <path
                                    d="M7.9,16.116a1.053,1.053,0,0,1-1.489,0L.463,10.168a1.579,1.579,0,0,1,0-2.234l.745-.745a1.579,1.579,0,0,1,2.234,0L7.155,10.9,17.188.869a1.579,1.579,0,0,1,2.234,0l.745.745a1.579,1.579,0,0,1,0,2.234Zm0,0"
                                    transform="translate(82.63 28.333)"
                                    fill="#2b88f8"
                                />
                            </g>
                            <g data-name="Group 670">
                                <rect
                                    width="124"
                                    height="8"
                                    transform="translate(88.813 19.439)"
                                    fill="#ebebeb"
                                />
                                <rect
                                    width="124"
                                    height="4"
                                    transform="translate(88.813 32.439)"
                                    fill="#ebebeb"
                                />
                                <rect
                                    width="124"
                                    height="4"
                                    transform="translate(88.813 41.439)"
                                    fill="#ebebeb"
                                />
                            </g>
                        </g>
                        <g id="Group_671" data-name="Group 671" transform="translate(0 60)">
                            <g
                                id="Group_663-2"
                                data-name="Group 663"
                                transform="translate(-30.246 -2.817)"
                            >
                                <rect
                                    id="Rectangle_170-2"
                                    data-name="Rectangle 170"
                                    width="31"
                                    height="32"
                                    rx="4"
                                    transform="translate(67.058 20.256)"
                                    fill="#ecf5ff"
                                />
                                <path
                                    id="check_2_2"
                                    data-name="check (2)"
                                    d="M7.9,16.116a1.053,1.053,0,0,1-1.489,0L.463,10.168a1.579,1.579,0,0,1,0-2.234l.745-.745a1.579,1.579,0,0,1,2.234,0L7.155,10.9,17.188.869a1.579,1.579,0,0,1,2.234,0l.745.745a1.579,1.579,0,0,1,0,2.234Zm0,0"
                                    transform="translate(82.63 28.333)"
                                    fill="#2b88f8"
                                />
                            </g>
                            <g id="Group_670-2" data-name="Group 670">
                                <rect
                                    id="Rectangle_171-2"
                                    data-name="Rectangle 171"
                                    width="124"
                                    height="8"
                                    transform="translate(88.813 19.439)"
                                    fill="#ebebeb"
                                />
                                <rect
                                    id="Rectangle_172-2"
                                    data-name="Rectangle 172"
                                    width="124"
                                    height="4"
                                    transform="translate(88.813 32.439)"
                                    fill="#ebebeb"
                                />
                                <rect
                                    id="Rectangle_173-2"
                                    data-name="Rectangle 173"
                                    width="124"
                                    height="4"
                                    transform="translate(88.813 41.439)"
                                    fill="#ebebeb"
                                />
                            </g>
                        </g>
                        <g id="Group_672" data-name="Group 672" transform="translate(0 107)">
                            <g
                                id="Group_663-3"
                                data-name="Group 663"
                                transform="translate(-30.246 -2.817)"
                            >
                                <rect
                                    id="Rectangle_170-3"
                                    data-name="Rectangle 170"
                                    width="31"
                                    height="32"
                                    rx="4"
                                    transform="translate(67.058 20.256)"
                                    fill="#ecf5ff"
                                />
                                <path
                                    id="check_2_3"
                                    data-name="check (2)"
                                    d="M7.9,16.116a1.053,1.053,0,0,1-1.489,0L.463,10.168a1.579,1.579,0,0,1,0-2.234l.745-.745a1.579,1.579,0,0,1,2.234,0L7.155,10.9,17.188.869a1.579,1.579,0,0,1,2.234,0l.745.745a1.579,1.579,0,0,1,0,2.234Zm0,0"
                                    transform="translate(82.63 28.333)"
                                    fill="#2b88f8"
                                />
                            </g>
                            <g id="Group_670-3" data-name="Group 670">
                                <rect
                                    id="Rectangle_171-3"
                                    data-name="Rectangle 171"
                                    width="124"
                                    height="8"
                                    transform="translate(88.813 19.439)"
                                    fill="#ebebeb"
                                />
                                <rect
                                    id="Rectangle_172-3"
                                    data-name="Rectangle 172"
                                    width="124"
                                    height="4"
                                    transform="translate(88.813 32.439)"
                                    fill="#ebebeb"
                                />
                                <rect
                                    id="Rectangle_173-3"
                                    data-name="Rectangle 173"
                                    width="124"
                                    height="4"
                                    transform="translate(88.813 41.439)"
                                    fill="#ebebeb"
                                />
                            </g>
                        </g>
                        <g id="Group_673" data-name="Group 673" transform="translate(0 154)">
                            <g
                                id="Group_663-4"
                                data-name="Group 663"
                                transform="translate(-30.246 -2.817)"
                            >
                                <rect
                                    id="Rectangle_170-4"
                                    data-name="Rectangle 170"
                                    width="31"
                                    height="32"
                                    rx="4"
                                    transform="translate(67.058 20.256)"
                                    fill="#ecf5ff"
                                />
                                <path
                                    id="check_2_4"
                                    data-name="check (2)"
                                    d="M7.9,16.116a1.053,1.053,0,0,1-1.489,0L.463,10.168a1.579,1.579,0,0,1,0-2.234l.745-.745a1.579,1.579,0,0,1,2.234,0L7.155,10.9,17.188.869a1.579,1.579,0,0,1,2.234,0l.745.745a1.579,1.579,0,0,1,0,2.234Zm0,0"
                                    transform="translate(82.63 28.333)"
                                    fill="#2b88f8"
                                />
                            </g>
                            <g id="Group_670-4" data-name="Group 670">
                                <rect
                                    id="Rectangle_171-4"
                                    data-name="Rectangle 171"
                                    width="124"
                                    height="8"
                                    transform="translate(88.813 19.439)"
                                    fill="#ebebeb"
                                />
                                <rect
                                    id="Rectangle_172-4"
                                    data-name="Rectangle 172"
                                    width="124"
                                    height="4"
                                    transform="translate(88.813 32.439)"
                                    fill="#ebebeb"
                                />
                                <rect
                                    id="Rectangle_173-4"
                                    data-name="Rectangle 173"
                                    width="124"
                                    height="4"
                                    transform="translate(88.813 41.439)"
                                    fill="#ebebeb"
                                />
                            </g>
                        </g>
                        <g id="Group_674" data-name="Group 674" transform="translate(0 201)">
                            <g
                                id="Group_663-5"
                                data-name="Group 663"
                                transform="translate(-30.246 -2.817)"
                            >
                                <rect
                                    id="Rectangle_170-5"
                                    data-name="Rectangle 170"
                                    width="31"
                                    height="32"
                                    rx="4"
                                    transform="translate(67.058 20.256)"
                                    fill="#ecf5ff"
                                />
                                <path
                                    id="check_2_5"
                                    data-name="check (2)"
                                    d="M7.9,16.116a1.053,1.053,0,0,1-1.489,0L.463,10.168a1.579,1.579,0,0,1,0-2.234l.745-.745a1.579,1.579,0,0,1,2.234,0L7.155,10.9,17.188.869a1.579,1.579,0,0,1,2.234,0l.745.745a1.579,1.579,0,0,1,0,2.234Zm0,0"
                                    transform="translate(82.63 28.333)"
                                    fill="#2b88f8"
                                />
                            </g>
                            <g id="Group_670-5" data-name="Group 670">
                                <rect
                                    id="Rectangle_171-5"
                                    data-name="Rectangle 171"
                                    width="124"
                                    height="8"
                                    transform="translate(88.813 19.439)"
                                    fill="#ebebeb"
                                />
                                <rect
                                    id="Rectangle_172-5"
                                    data-name="Rectangle 172"
                                    width="124"
                                    height="4"
                                    transform="translate(88.813 32.439)"
                                    fill="#ebebeb"
                                />
                                <rect
                                    id="Rectangle_173-5"
                                    data-name="Rectangle 173"
                                    width="124"
                                    height="4"
                                    transform="translate(88.813 41.439)"
                                    fill="#ebebeb"
                                />
                            </g>
                        </g>
                    </g>
                    <g id="freepik--Character--inject-9" transform="translate(0 67.1)">
                        <path
                            id="Path_111"
                            data-name="Path 111"
                            d="M133.267,217.465h0a8.806,8.806,0,0,0,9.461-8.434c.186-4.794.352-9.637.352-9.637s7.26-.675,7.827-7.827.284-23.766.284-23.766h0a25.018,25.018,0,0,0-25.3,1.624l.44,25.282v13.61C126.34,212.837,128.718,217.123,133.267,217.465Z"
                            transform="translate(-86.753 -144.231)"
                            fill="#ffbe9d"
                        />
                        <path
                            id="Path_112"
                            data-name="Path 112"
                            d="M143.624,182.625a3.689,3.689,0,0,0-4.892-1.076,4.178,4.178,0,0,0-1.389,5.225,5.479,5.479,0,0,0,3.522,2.661,2.759,2.759,0,0,1,2.162,2.935,9.2,9.2,0,0,1-2.524,5,13.248,13.248,0,0,1-8.317,3.914c-3.062.362-6.565-.274-8.346-2.788-1.3-1.83-1.389-4.227-1.438-6.467l-.333-15.889a23.169,23.169,0,0,1,.362-5.7,7.671,7.671,0,0,1,3.014-4.716c1.751-1.155,3.963-1.252,5.949-1.888,2.495-.812,4.608-2.456,6.937-3.649s5.176-1.908,7.514-.734c1.683.841,2.808,2.515,4.432,3.444,2.329,1.321,5.489.978,6.614,4.442a5.753,5.753,0,0,1,0,3.649c-1.575,4.647-7.328,6.526-11.477,4.52-.078.783.695,2.534-.137,5.019-.264.8-.352,2.006-1.595,2.035"
                            transform="translate(-86.67 -144.095)"
                            fill="#263238"
                        />
                        <path
                            id="Path_113"
                            data-name="Path 113"
                            d="M123.562,167.65a24.46,24.46,0,0,0,2.309-7.221,8.277,8.277,0,0,0-2.26-6.976,6.849,6.849,0,0,0-4.736-1.673,8,8,0,0,0-7.221,5.724,8.61,8.61,0,0,0,2.935,8.806,9.363,9.363,0,0,0,9.315,1.213"
                            transform="translate(-86.44 -143.943)"
                            fill="#263238"
                        />
                        <path
                            id="Path_114"
                            data-name="Path 114"
                            d="M125.195,165.35a3.542,3.542,0,0,1-3.4,3.327,12.321,12.321,0,0,0,1.957-1.37C124.647,166.368,125.058,165.311,125.195,165.35Z"
                            transform="translate(-86.665 -144.236)"
                            fill="#ff725e"
                        />
                        <path
                            id="Path_115"
                            data-name="Path 115"
                            d="M122.679,167.006a6.693,6.693,0,0,1-1.957,1.419,5.626,5.626,0,0,1-2.563.734,3.591,3.591,0,0,1-3-1.35,3.963,3.963,0,0,1-.528-3.248,6.3,6.3,0,0,1,1.2-2.378,7.3,7.3,0,0,1,1.7-1.712,23.221,23.221,0,0,1-1.409,1.957,6.408,6.408,0,0,0-1.037,2.27,3.581,3.581,0,0,0,.479,2.847,3.209,3.209,0,0,0,2.593,1.174,5.6,5.6,0,0,0,2.407-.587A20.994,20.994,0,0,1,122.679,167.006Z"
                            transform="translate(-86.507 -144.13)"
                            fill="#263238"
                        />
                        <path
                            id="Path_116"
                            data-name="Path 116"
                            d="M110.38,175.279a3.385,3.385,0,0,1,.342.793,7.974,7.974,0,0,0,1.066,2.055,2.935,2.935,0,0,0,2.808,1.292,3.914,3.914,0,0,0,2.71-2.73,27.457,27.457,0,0,0,.734-4.168,6.467,6.467,0,0,1,1.37-3.3,3.64,3.64,0,0,1,2.094-1.194,1.37,1.37,0,0,1,.881.078,4.223,4.223,0,0,0-.841.117,3.591,3.591,0,0,0-1.859,1.233,6.37,6.37,0,0,0-1.184,3.131,24.7,24.7,0,0,1-.714,4.266,4.285,4.285,0,0,1-3.1,3.023,2.935,2.935,0,0,1-1.957-.372,4.041,4.041,0,0,1-1.233-1.164,7.132,7.132,0,0,1-.978-2.2,2.289,2.289,0,0,1-.137-.861Z"
                            transform="translate(-86.419 -144.293)"
                            fill="#263238"
                        />
                        <path
                            id="Path_117"
                            data-name="Path 117"
                            d="M106.734,237.129s-3.669,26.828,0,30.233,7.182,2.27,7.182,2.27l6.976-5.871,1.37-26.672Z"
                            transform="translate(-86.305 -145.784)"
                            fill="#ffbe9d"
                        />
                        <path
                            id="Path_119"
                            data-name="Path 119"
                            d="M205.7,171.55l-6.751,4.334c-.3-2.671-2.338-5.313-2.241-3.972s0,8.3,0,8.3L184.688,195.54c-1.634,4.012-4.951,6.536-7.426,7.133L166,206.019l4.393,14.862,15.655-5.186a13.473,13.473,0,0,0,8.561-8.581l10.763-22.8a50.966,50.966,0,0,0,1.722-6.458C207.563,175.111,205.7,171.55,205.7,171.55Z"
                            transform="translate(-87.619 -144.154)"
                            fill="#ffbe9d"
                        />
                        <path
                            id="Path_120"
                            data-name="Path 120"
                            d="M149.736,444.554l-.176-7.5-14.07.225.127,10.988.881.049c3.914.147,19.774.5,22.357-.323C161.731,447.059,149.736,444.554,149.736,444.554Z"
                            transform="translate(-86.961 -150.1)"
                            fill="#ff725e"
                        />
                        <g
                            id="Group_48"
                            data-name="Group 48"
                            transform="translate(48.608 294.046)"
                            opacity="0.5"
                        >
                            <path
                                id="Path_121"
                                data-name="Path 121"
                                d="M140.726,448.233a4.892,4.892,0,0,0-1.849-2.935,4.725,4.725,0,0,0-3.307-.978l.059,3.914Z"
                                transform="translate(-135.57 -444.302)"
                                fill="#fff"
                            />
                        </g>
                        <g
                            id="Group_49"
                            data-name="Group 49"
                            transform="translate(48.618 295.893)"
                            opacity="0.4"
                        >
                            <path
                                id="Path_122"
                                data-name="Path 122"
                                d="M155.559,446.19s4.207,1.135,3.7,1.839-16.574.978-23.678.431v-.313l18.746-.127S154.786,446.239,155.559,446.19Z"
                                transform="translate(-135.58 -446.19)"
                                fill="#fff"
                            />
                        </g>
                        <path
                            id="Path_123"
                            data-name="Path 123"
                            d="M135.409,448.171h.939l2.563.068c2.162.049,5.146.069,8.444,0s6.291-.157,8.444-.264l2.563-.147.695-.049h-.7l-2.554.1c-2.162.069-5.146.166-8.444.215s-6.281,0-8.444,0H135.38Z"
                            transform="translate(-86.958 -150.332)"
                            fill="#263238"
                        />
                        <path
                            id="Path_124"
                            data-name="Path 124"
                            d="M154.8,448.231a5.254,5.254,0,0,1,.46-1.223,5.813,5.813,0,0,1,.851-.978,2.29,2.29,0,0,0-1.311,2.221Z"
                            transform="translate(-87.377 -150.294)"
                            fill="#263238"
                        />
                        <path
                            id="Path_125"
                            data-name="Path 125"
                            d="M150.52,446.164a2.962,2.962,0,0,0,.44-.538c.205-.323.323-.616.284-.636s-.235.215-.43.538S150.481,446.145,150.52,446.164Z"
                            transform="translate(-87.285 -150.271)"
                            fill="#263238"
                        />
                        <path
                            id="Path_126"
                            data-name="Path 126"
                            d="M149.35,445.582a2.247,2.247,0,0,0,.489-.342c.245-.225.4-.43.372-.46s-.254.117-.489.333S149.311,445.543,149.35,445.582Z"
                            transform="translate(-87.26 -150.267)"
                            fill="#263238"
                        />
                        <path
                            id="Path_127"
                            data-name="Path 127"
                            d="M148.59,444.248a1.536,1.536,0,0,0,.734.078,1.585,1.585,0,0,0,.734-.088,1.849,1.849,0,0,0-.734-.078,1.613,1.613,0,0,0-.734.088Z"
                            transform="translate(-87.243 -150.253)"
                            fill="#263238"
                        />
                        <path
                            id="Path_128"
                            data-name="Path 128"
                            d="M148.32,443.3a1.527,1.527,0,0,0,.822.225,1.5,1.5,0,0,0,.851-.127,4.369,4.369,0,0,0-.841,0A4.559,4.559,0,0,0,148.32,443.3Z"
                            transform="translate(-87.237 -150.235)"
                            fill="#263238"
                        />
                        <path
                            id="Path_129"
                            data-name="Path 129"
                            d="M151,444.9a2.5,2.5,0,0,0,.9,0,5.332,5.332,0,0,0,.978-.225,2.939,2.939,0,0,0,.548-.235.45.45,0,0,0,.225-.284.372.372,0,0,0-.147-.362,1.673,1.673,0,0,0-2.045.519,1.8,1.8,0,0,0-.284.607.587.587,0,0,0,0,.245,2.865,2.865,0,0,1,.4-.773,1.7,1.7,0,0,1,.763-.519,1.438,1.438,0,0,1,1.086.059c.137.108.088.274-.069.372a2.931,2.931,0,0,1-.509.225,6.437,6.437,0,0,1-.978.254C151.333,444.873,151,444.873,151,444.9Z"
                            transform="translate(-87.295 -150.242)"
                            fill="#263238"
                        />
                        <path
                            id="Path_130"
                            data-name="Path 130"
                            d="M151.2,444.962a.871.871,0,0,0,.1-.626,1.566,1.566,0,0,0-.264-.656.91.91,0,0,0-.753-.45.352.352,0,0,0-.274.421.978.978,0,0,0,.157.4,2.319,2.319,0,0,0,.44.538,1.272,1.272,0,0,0,.519.362,6.027,6.027,0,0,1-.431-.44,3.654,3.654,0,0,1-.391-.538c-.127-.186-.215-.528,0-.577s.46.2.6.372a1.575,1.575,0,0,1,.274.587,3.574,3.574,0,0,1,.029.607Z"
                            transform="translate(-87.274 -150.233)"
                            fill="#263238"
                        />
                        <path
                            id="Path_131"
                            data-name="Path 131"
                            d="M135.65,444.237h1.037a4.413,4.413,0,0,1,3.728,2.877c.225.6.254.978.284.978a.7.7,0,0,0,0-.284,4.149,4.149,0,0,0-.166-.753,4.3,4.3,0,0,0-3.835-2.935,3.493,3.493,0,0,0-.773,0A.755.755,0,0,0,135.65,444.237Z"
                            transform="translate(-86.964 -150.252)"
                            fill="#263238"
                        />
                        <path
                            id="Path_132"
                            data-name="Path 132"
                            d="M137.048,438.24a22.631,22.631,0,0,0-.078,2.886,23.75,23.75,0,0,0,.088,2.935,22.846,22.846,0,0,0,.078-2.935A26.274,26.274,0,0,0,137.048,438.24Z"
                            transform="translate(-86.992 -150.126)"
                            fill="#263238"
                        />
                        <path
                            id="Path_133"
                            data-name="Path 133"
                            d="M142.62,446.889a8.47,8.47,0,0,0,2.064.166,7.531,7.531,0,0,0,2.055-.2c0-.049-.978,0-2.055,0A18.869,18.869,0,0,0,142.62,446.889Z"
                            transform="translate(-87.114 -150.311)"
                            fill="#263238"
                        />
                        <path
                            id="Path_134"
                            data-name="Path 134"
                            d="M139.81,446.92s.078.2.166.411.157.391.205.391.068-.225-.049-.47S139.839,446.891,139.81,446.92Z"
                            transform="translate(-87.054 -150.313)"
                            fill="#263238"
                        />
                        <path
                            id="Path_135"
                            data-name="Path 135"
                            d="M138.72,445.62a.873.873,0,0,0,.215.323c.157.147.294.235.323.205s-.059-.186-.206-.323S138.759,445.581,138.72,445.62Z"
                            transform="translate(-87.03 -150.285)"
                            fill="#263238"
                        />
                        <path
                            id="Path_136"
                            data-name="Path 136"
                            d="M137.19,445a.985.985,0,0,1,.411.108c.205.108.352.176.391.147s-.078-.225-.342-.3A.574.574,0,0,0,137.19,445Z"
                            transform="translate(-86.997 -150.27)"
                            fill="#263238"
                        />
                        <path
                            id="Path_137"
                            data-name="Path 137"
                            d="M136.11,444.769a.342.342,0,0,0,.176.157c.117.039.225.049.245,0s-.068-.117-.186-.157S136.12,444.72,136.11,444.769Z"
                            transform="translate(-86.974 -150.266)"
                            fill="#263238"
                        />
                        <path
                            id="Path_138"
                            data-name="Path 138"
                            d="M99,441.943l4.775-5.8L92.954,427.13l-7.064,8.414.626.607c2.847,2.652,14.676,13.277,17.161,14.334C106.466,451.669,99,441.943,99,441.943Z"
                            transform="translate(-85.89 -149.886)"
                            fill="#ff725e"
                        />
                        <g
                            id="Group_50"
                            data-name="Group 50"
                            transform="translate(0.108 282.518)"
                            opacity="0.5"
                        >
                            <path
                                id="Path_139"
                                data-name="Path 139"
                                d="M89.914,438.86a4.892,4.892,0,0,0,.538-3.454,4.716,4.716,0,0,0-1.879-2.886l-2.573,3Z"
                                transform="translate(-86 -432.52)"
                                fill="#fff"
                            />
                        </g>
                        <g
                            id="Group_51"
                            data-name="Group 51"
                            transform="translate(0 285.433)"
                            opacity="0.4"
                        >
                            <path
                                id="Path_140"
                                data-name="Path 140"
                                d="M102.513,447.036s2.446,3.61,1.6,3.806-13.228-10.1-18.228-15.117l.215-.225L100.4,447.632S101.858,446.537,102.513,447.036Z"
                                transform="translate(-85.89 -435.5)"
                                fill="#fff"
                            />
                        </g>
                        <path
                            id="Path_141"
                            data-name="Path 141"
                            d="M85.94,435.35l.166.176.509.479,1.9,1.722c1.6,1.438,3.855,3.4,6.389,5.518s4.892,3.982,6.575,5.313l2.035,1.556.558.411.205.137-.186-.166-.538-.44-1.957-1.595c-1.693-1.35-4.012-3.229-6.546-5.342s-4.794-4.06-6.418-5.479l-1.957-1.683-.528-.46Z"
                            transform="translate(-85.891 -150.063)"
                            fill="#263238"
                        />
                        <path
                            id="Path_142"
                            data-name="Path 142"
                            d="M100.57,448.049a5.242,5.242,0,0,1,1.155-.636,6.668,6.668,0,0,1,1.291-.2,2.137,2.137,0,0,0-2.446.832Z"
                            transform="translate(-86.207 -150.317)"
                            fill="#263238"
                        />
                        <path
                            id="Path_143"
                            data-name="Path 143"
                            d="M98.69,443.68c0,.049.323,0,.685-.127s.656-.245.636-.293-.323,0-.685.127a1.643,1.643,0,0,0-.636.294Z"
                            transform="translate(-86.166 -150.234)"
                            fill="#263238"
                        />
                        <path
                            id="Path_144"
                            data-name="Path 144"
                            d="M98.19,442.468a2.111,2.111,0,0,0,.6.069c.333-.01.587-.059.587-.108s-.274-.078-.6-.068A1.312,1.312,0,0,0,98.19,442.468Z"
                            transform="translate(-86.155 -150.215)"
                            fill="#263238"
                        />
                        <path
                            id="Path_145"
                            data-name="Path 145"
                            d="M98.49,441a3.31,3.31,0,0,0,.509.538,1.957,1.957,0,0,0,.616.421,3.14,3.14,0,0,0-.509-.548C98.793,441.146,98.529,440.921,98.49,441Z"
                            transform="translate(-86.162 -150.185)"
                            fill="#263238"
                        />
                        <path
                            id="Path_146"
                            data-name="Path 146"
                            d="M98.91,440.06a2.035,2.035,0,0,0,1.2,1.164,6.466,6.466,0,0,0-.607-.577C99.184,440.334,99,440,98.91,440.06Z"
                            transform="translate(-86.171 -150.165)"
                            fill="#263238"
                        />
                        <path
                            id="Path_147"
                            data-name="Path 147"
                            d="M99.869,442.995a2.466,2.466,0,0,0,.695.577,4.891,4.891,0,0,0,.881.45,2.76,2.76,0,0,0,.567.186.479.479,0,0,0,.352-.078.352.352,0,0,0,.127-.362,1.663,1.663,0,0,0-1.888-.978,1.517,1.517,0,0,0-.616.274.656.656,0,0,0-.176.166,2.613,2.613,0,0,1,.812-.323,1.634,1.634,0,0,1,.92.108,1.418,1.418,0,0,1,.783.744.228.228,0,0,1-.294.245,3.307,3.307,0,0,1-.528-.166,5.622,5.622,0,0,1-.871-.411A3.306,3.306,0,0,1,99.869,442.995Z"
                            transform="translate(-86.19 -150.223)"
                            fill="#263238"
                        />
                        <path
                            id="Path_148"
                            data-name="Path 148"
                            d="M100,443.187a1.062,1.062,0,0,0,.489-.411,1.624,1.624,0,0,0,.235-.665.978.978,0,0,0-.284-.841.362.362,0,0,0-.489.147.978.978,0,0,0-.137.4,2.828,2.828,0,0,0,0,.695,1.291,1.291,0,0,0,.157.616s0-.245,0-.616a3.346,3.346,0,0,1,.059-.665c0-.225.176-.538.382-.43s.215.45.215.675a1.526,1.526,0,0,1-.186.616,2.931,2.931,0,0,1-.44.479Z"
                            transform="translate(-86.19 -150.19)"
                            fill="#263238"
                        />
                        <path
                            id="Path_149"
                            data-name="Path 149"
                            d="M88.69,432.52a5.382,5.382,0,0,1,.773.685,4.559,4.559,0,0,1,1.145,2.172,4.511,4.511,0,0,1-.2,2.446c-.225.607-.47.929-.44.978a.919.919,0,0,0,.186-.225,3.384,3.384,0,0,0,.362-.675,4.275,4.275,0,0,0,.254-2.524,4.325,4.325,0,0,0-1.223-2.221,3.7,3.7,0,0,0-.607-.479.784.784,0,0,0-.254-.157Z"
                            transform="translate(-85.95 -150.002)"
                            fill="#263238"
                        />
                        <path
                            id="Path_150"
                            data-name="Path 150"
                            d="M93.587,428.87a23.93,23.93,0,0,0-1.957,2.143,27.4,27.4,0,0,0-1.82,2.25,21.528,21.528,0,0,0,1.957-2.133A28.372,28.372,0,0,0,93.587,428.87Z"
                            transform="translate(-85.975 -149.923)"
                            fill="#263238"
                        />
                        <path
                            id="Path_151"
                            data-name="Path 151"
                            d="M92.25,439.07a8.092,8.092,0,0,0,1.448,1.468,7.709,7.709,0,0,0,1.693,1.194s-.714-.587-1.575-1.321A15.643,15.643,0,0,0,92.25,439.07Z"
                            transform="translate(-86.027 -150.144)"
                            fill="#263238"
                        />
                        <path
                            id="Path_152"
                            data-name="Path 152"
                            d="M90.085,437.26s-.068.2-.127.421-.147.391-.108.421.2-.117.274-.382S90.134,437.25,90.085,437.26Z"
                            transform="translate(-85.975 -150.104)"
                            fill="#263238"
                        />
                        <path
                            id="Path_153"
                            data-name="Path 153"
                            d="M90.119,435.56c-.049,0-.068.176-.049.382s.068.372.117.372.068-.176,0-.382S90.168,435.56,90.119,435.56Z"
                            transform="translate(-85.98 -150.068)"
                            fill="#263238"
                        />
                        <path
                            id="Path_154"
                            data-name="Path 154"
                            d="M89.34,434.12c0,.049.127.166.235.352s.157.372.205.372.078-.225-.059-.46S89.36,434.081,89.34,434.12Z"
                            transform="translate(-85.964 -150.037)"
                            fill="#263238"
                        />
                        <path
                            id="Path_155"
                            data-name="Path 155"
                            d="M88.69,433.21v.245a.181.181,0,0,0,.186.157c.039-.02,0-.137,0-.245A.181.181,0,0,0,88.69,433.21Z"
                            transform="translate(-85.95 -150.017)"
                            fill="#263238"
                        />
                        <path
                            id="Path_157"
                            data-name="Path 157"
                            d="M153.125,273.33l2.319,164.883-24.138.147L119.184,304.042h0c-2.563-2.446-6.174-6.634-5.675-15.978l.587-10.851Z"
                            transform="translate(-86.485 -146.566)"
                            fill="#263238"
                        />
                        <path
                            id="Path_156"
                            data-name="Path 156"
                            d="M128.311,281.57s-4.687,19.823-3.356,23.746,9.911,6.184,9.911,6.184l-.294,54.292L87.1,424.5l17.514,14.324s51.406-58.793,55.936-69.468-2.935-88.693-2.935-88.693Z"
                            transform="translate(-85.916 -146.725)"
                            fill="#263238"
                        />
                        <path
                            id="Path_163"
                            data-name="Path 163"
                            d="M123.955,198.482a21.056,21.056,0,0,1-1.145-29.353"
                            transform="translate(-86.57 -144.317)"
                            fill="#263238"
                        />
                        <path
                            id="Path_164"
                            data-name="Path 164"
                            d="M110.09,214.85s-5.244,5.929-6.33,28.6c0,0,14.94,2.837,15.214,2.025S110.09,214.85,110.09,214.85Z"
                            transform="translate(-86.276 -145.304)"
                            fill="#0096dc"
                        />
                        <path
                            id="Path_166"
                            data-name="Path 166"
                            d="M112.621,280.638H158.5l-.744-7.172s-5.244-27.259-5.205-27.621l.822-1.732a17.289,17.289,0,0,0,.205-14.432h0l22.5-7.681-5.5-18.531c-10.2,1.712-25.605,3.64-25.605,3.64l-2.289.186s-15.909.832-16.252.871c-6.653.812-14.393,1.321-17.22,8.444-1.517,3.806-.078,9.52,2.4,15.41l5.87,18.1c2.348,9.951-1.4,9.471-3.464,19.49C113.688,271.118,112.592,279.248,112.621,280.638Z"
                            transform="translate(-86.38 -145.059)"
                            fill="#00acfd"
                        />
                    </g>
                </g>
            </g>
        </SVGIcon>
    );
}
