import { useContext, useEffect } from 'react';

import PubSubContext from './Context/context';

/**
 *
 * @param {string | null} e Event name
 * @param {*} cb A callback created using useCallback
 * @returns
 */
function usePubSub(e = undefined, cb = undefined) {
    const { publish, subscribe, unsubscribe } = useContext(PubSubContext);

    useEffect(() => {
        if (e && cb) {
            subscribe(e, cb);
        }
        return () => {
            // Remove subscription when the component using this hook is unmounted or the callback updates
            if (e && cb) unsubscribe(e, cb);
        };
    }, [subscribe, unsubscribe, e, cb]);

    return { publish, subscribe, unsubscribe };
}

export default usePubSub;
