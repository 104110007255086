import React from 'react';

import { SVGIcon } from '../../UIKit/index';

export default function DCNoBrowserData(props) {
    return (
        <SVGIcon {...props} viewBox="0 0 512 512">
            <ellipse
                cx="237.5"
                cy="460"
                rx="91.5"
                ry="18.5"
                fill="#f5f6f7"
                stroke="#f5f6f7"
                strokeMiterlimit="10"
            />
            <circle
                cx="238"
                cy="261"
                r="190.5"
                fill="#fff"
                stroke="#707070"
                strokeMiterlimit="10"
                strokeWidth="3"
            />
            <path
                d="M364.5,403.5H54.75a8.25,8.25,0,0,1-8.25-8.25V133.75a8.25,8.25,0,0,1,8.25-8.25H372.5c45,47,57,91,53.58,168.34C412.5,353.5,369.06,403.5,364.5,403.5Z"
                fill="#fff"
                stroke="#707070"
                strokeMiterlimit="10"
                strokeWidth="3"
            />
            <rect
                x="57"
                y="192"
                width="131"
                height="98"
                rx="5.29"
                ry="5.29"
                fill="#fff"
                stroke="#707070"
                strokeMiterlimit="10"
            />
            <rect
                x="196.5"
                y="192.5"
                width="131"
                height="98"
                rx="5.31"
                ry="5.31"
                fill="#fff"
                stroke="#707070"
                strokeMiterlimit="10"
            />
            <path
                d="M425.19,290.5H339.81a5.31,5.31,0,0,1-5.31-5.31V197.81a5.31,5.31,0,0,1,5.31-5.31H414.5c-.27-.54,10,25,13,47C427.57,260.5,428.12,290.5,425.19,290.5Z"
                fill="#fff"
                stroke="#707070"
                strokeMiterlimit="10"
            />
            <path
                d="M373.5,393.5l-33.69,1a5.31,5.31,0,0,1-5.31-5.31V301.81a5.31,5.31,0,0,1,5.31-5.31H424.5c-1,14-6,23-18,52C396.5,368.5,376.5,392.5,373.5,393.5Z"
                fill="#fff"
                stroke="#707070"
                strokeMiterlimit="10"
            />
            <rect
                x="57"
                y="296"
                width="131"
                height="98"
                rx="5.31"
                ry="5.31"
                fill="#fff"
                stroke="#707070"
                strokeMiterlimit="10"
            />
            <rect
                x="196.5"
                y="296.5"
                width="131"
                height="98"
                rx="5.31"
                ry="5.31"
                fill="#fff"
                stroke="#707070"
                strokeMiterlimit="10"
            />
            <polyline
                points="67 211 78 211 90.5 215.5 90.5 220.5 101.5 222.5 106.5 226.5 107.5 234.5 123.5 235.5 132.5 243.5 144.5 244.5 157.5 257.5 167.5 258.5 177 276.7 177 278"
                fill="none"
                stroke="#e3e3e4"
                strokeMiterlimit="10"
                strokeWidth="2"
            />
            <rect
                x="121.99"
                y="212.5"
                width="50.41"
                height="24"
                rx="3.19"
                ry="3.19"
                fill="none"
                stroke="#e3e3e4"
                strokeMiterlimit="10"
            />
            <polyline
                points="178 277.5 67.5 276.5 67.5 210"
                fill="none"
                stroke="#707070"
                strokeMiterlimit="10"
                strokeWidth="2"
            />
            <rect x="128.5" y="219.5" width="35.5" height="3" rx="0.38" ry="0.38" fill="#e3e3e4" />
            <rect x="128.5" y="227.5" width="20.55" height="3" rx="0.39" ry="0.39" fill="#e3e3e4" />
            <rect x="212.5" y="223.5" width="9" height="34" fill="#707070" />
            <rect x="215.5" y="242.5" width="9" height="15" fill="#e3e3e4" />
            <rect x="226.5" y="233.5" width="9" height="24" fill="#707070" />
            <rect x="229.5" y="248.5" width="9" height="9" fill="#e3e3e4" />
            <rect x="240.5" y="239.5" width="9" height="18" fill="#707070" />
            <rect x="243.5" y="250.5" width="9" height="7" fill="#e3e3e4" />
            <rect x="254.5" y="228.5" width="9" height="29" fill="#707070" />
            <rect x="257.5" y="248.5" width="9" height="9" fill="#e3e3e4" />
            <rect x="270.5" y="238.5" width="9" height="19" fill="#707070" />
            <rect x="273.5" y="244.5" width="9" height="13" fill="#e3e3e4" />
            <rect x="284.5" y="232.5" width="9" height="25" fill="#707070" />
            <rect x="287.5" y="253.5" width="9" height="4" fill="#e3e3e4" />
            <rect x="298.5" y="239.5" width="9" height="18" fill="#707070" />
            <rect x="301.5" y="255.5" width="9" height="2" fill="#e3e3e4" />
            <circle
                cx="351.5"
                cy="256.5"
                r="5.5"
                fill="none"
                stroke="#707070"
                strokeMiterlimit="10"
                strokeWidth="2"
            />
            <circle
                cx="376"
                cy="232"
                r="5.5"
                fill="none"
                stroke="#707070"
                strokeMiterlimit="10"
                strokeWidth="2"
            />
            <circle
                cx="403"
                cy="259"
                r="5.5"
                fill="none"
                stroke="#707070"
                strokeMiterlimit="10"
                strokeWidth="2"
            />
            <line
                x1="355.5"
                y1="252.5"
                x2="372"
                y2="236"
                fill="none"
                stroke="#707070"
                strokeMiterlimit="10"
                strokeWidth="2"
            />
            <line
                x1="379.5"
                y1="235.5"
                x2="399"
                y2="255"
                fill="none"
                stroke="#707070"
                strokeMiterlimit="10"
                strokeWidth="2"
            />
            <line
                x1="407.5"
                y1="256.5"
                x2="426"
                y2="238"
                fill="none"
                stroke="#707070"
                strokeMiterlimit="10"
                strokeWidth="2"
            />
            <path
                d="M386,380H354.22a7.24,7.24,0,0,1-7.22-7.22v-54.6a7.24,7.24,0,0,1,7.22-7.22L422,311"
                fill="none"
                stroke="#707070"
                strokeMiterlimit="10"
                strokeWidth="2"
            />
            <line
                x1="383"
                y1="311"
                x2="383"
                y2="379"
                fill="none"
                stroke="#707070"
                strokeMiterlimit="10"
                strokeWidth="2"
            />
            <line
                x1="348"
                y1="329"
                x2="415"
                y2="329"
                fill="none"
                stroke="#707070"
                strokeMiterlimit="10"
                strokeWidth="2"
            />
            <line
                x1="347"
                y1="346"
                x2="408"
                y2="346"
                fill="none"
                stroke="#707070"
                strokeMiterlimit="10"
                strokeWidth="2"
            />
            <line
                x1="347"
                y1="364"
                x2="398"
                y2="364"
                fill="none"
                stroke="#707070"
                strokeMiterlimit="10"
                strokeWidth="2"
            />
            <rect
                x="206"
                y="309.5"
                width="111"
                height="15"
                rx="1.42"
                ry="1.42"
                fill="none"
                stroke="#707070"
                strokeMiterlimit="10"
                strokeWidth="2"
            />
            <rect
                x="206"
                y="338"
                width="111"
                height="15"
                rx="1.42"
                ry="1.42"
                fill="none"
                stroke="#707070"
                strokeMiterlimit="10"
                strokeWidth="2"
            />
            <rect
                x="206"
                y="367.25"
                width="111"
                height="15"
                rx="1.42"
                ry="1.42"
                fill="none"
                stroke="#707070"
                strokeMiterlimit="10"
                strokeWidth="2"
            />
            <rect x="241" y="314.5" width="29" height="5" fill="#e3e3e4" />
            <rect x="297.5" y="314.5" width="12" height="5" fill="#e3e3e4" />
            <rect x="240" y="343" width="22" height="5" fill="#e3e3e4" />
            <rect x="290.5" y="343.5" width="19" height="5" fill="#e3e3e4" />
            <rect x="240.5" y="372.25" width="34" height="5" fill="#e3e3e4" />
            <rect x="300.5" y="372.5" width="9" height="5" fill="#e3e3e4" />
            <path
                d="M412.5,184.5H62.33A5.31,5.31,0,0,1,57,179.21V140.82a5.31,5.31,0,0,1,5.35-5.3L380.67,137a4.56,4.56,0,0,1,3.71,1.51c2.94,4,16.88,23.67,17,24C404.44,168.5,410.49,179.5,412.5,184.5Z"
                fill="#fff"
                stroke="#707070"
                strokeMiterlimit="10"
            />
            <path
                d="M139.33,345h12.06A32,32,0,0,0,121,315.54v12A20,20,0,0,1,139.33,345Z"
                fill="#e3e3e4"
            />
            <path
                d="M132.06,363A20,20,0,1,1,117,327.67V315.61a32,32,0,1,0,22.15,57.14C135.86,368.43,134.83,366.44,132.06,363Z"
                fill="#f5f6f7"
                stroke="#f5f6f7"
                strokeMiterlimit="10"
            />
            <path
                d="M139.42,349a19.78,19.78,0,0,1-3.16,9.35c2.73,3.66,4.67,6.52,7.37,10.16A31.86,31.86,0,0,0,151.46,349Z"
                fill="#707070"
            />
            <path
                d="M406,171.38H351.15a3.15,3.15,0,0,1-3.15-3.15V154.52a3.14,3.14,0,0,1,3.15-3.14L393,151c1.74,0,7,10,7,10C401,163,404.15,167,406,171.38Z"
                fill="none"
                stroke="#707070"
                strokeMiterlimit="10"
                strokeWidth="2"
            />
            <rect x="361" y="159.13" width="34" height="4.5" rx="1.06" ry="1.06" fill="#e3e3e4" />
            <rect x="100" y="149" width="105" height="5" fill="#e3e3e4" />
            <rect x="67" y="149" width="21" height="5" fill="#707070" />
            <rect x="100" y="166" width="58" height="5" fill="#e3e3e4" />
            <rect x="67" y="166" width="22" height="5" fill="#707070" />
            <path
                d="M238,70.5c105.21,0,190.5,85.29,190.5,190.5S343.21,451.5,238,451.5"
                fill="none"
                stroke="#707070"
                strokeMiterlimit="10"
                strokeWidth="3"
            />
            <circle
                cx="112.83"
                cy="444.04"
                r="7.17"
                fill="#fff"
                stroke="#e3e3e4"
                strokeMiterlimit="10"
                strokeWidth="2"
            />
            <path
                d="M89.44,424.58H85v-4.47a.56.56,0,0,0-.56-.56.57.57,0,0,0-.57.56v4.47H79.36a.57.57,0,0,0-.56.57.56.56,0,0,0,.56.56h4.47v4.48a.57.57,0,0,0,.57.56.56.56,0,0,0,.56-.56v-4.48h4.48a.56.56,0,0,0,.56-.56A.57.57,0,0,0,89.44,424.58Z"
                fill="#e3e3e4"
                stroke="#e3e3e4"
                strokeMiterlimit="10"
                strokeWidth="0.75"
            />
            <circle
                cx="67.83"
                cy="80.04"
                r="16.96"
                fill="#fff"
                stroke="#e3e3e4"
                strokeMiterlimit="10"
                strokeWidth="2"
            />
            <path
                d="M130.63,62.22h-7.32V54.91a.91.91,0,0,0-.91-.91.93.93,0,0,0-.93.91v7.31h-7.3a.93.93,0,0,0-.92.93.92.92,0,0,0,.92.91h7.3v7.32a.93.93,0,0,0,.93.91.91.91,0,0,0,.91-.91V64.06h7.32a.92.92,0,0,0,.92-.91A.93.93,0,0,0,130.63,62.22Z"
                fill="#e3e3e4"
                stroke="#e3e3e4"
                strokeMiterlimit="10"
                strokeWidth="0.75"
            />
            <circle
                cx="450.83"
                cy="165.04"
                r="16.96"
                fill="#fff"
                stroke="#e3e3e4"
                strokeMiterlimit="10"
                strokeWidth="2"
            />
            <path
                d="M485.63,108.22h-7.32v-7.31a.91.91,0,0,0-.91-.91.93.93,0,0,0-.93.91v7.31h-7.3a.93.93,0,0,0-.92.93.92.92,0,0,0,.92.91h7.3v7.32a.93.93,0,0,0,.93.91.91.91,0,0,0,.91-.91v-7.32h7.32a.92.92,0,0,0,.92-.91A.93.93,0,0,0,485.63,108.22Z"
                fill="#e3e3e4"
                stroke="#e3e3e4"
                strokeMiterlimit="10"
                strokeWidth="0.75"
            />
        </SVGIcon>
    );
}
