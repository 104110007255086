import React from 'react';

import { SVGIcon } from '../../UIKit/index';

export default function DCFillChat(props) {
    return (
        <SVGIcon {...props} viewBox="0 0 13.821 13.057">
            <path
                d="M118.584,44.282c0-3.2-3.1-5.8-6.911-5.8s-6.911,2.6-6.911,5.8,3.1,5.8,6.911,5.8a8.345,8.345,0,0,0,.958-.055,9.265,9.265,0,0,0,3.223,1.517.649.649,0,0,0,.07,0,.64.64,0,0,0,.557-.954,3.319,3.319,0,0,1-.374-1.859A5.444,5.444,0,0,0,118.584,44.282Zm-8.788.875a1.024,1.024,0,1,1,1.024-1.024A1.024,1.024,0,0,1,109.8,45.158Zm3.754,0a1.024,1.024,0,1,1,1.024-1.024A1.024,1.024,0,0,1,113.551,45.158Z"
                transform="translate(-104.763 -38.487)"
            />
        </SVGIcon>
    );
}
