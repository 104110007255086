import React from 'react';

import { SVGIcon } from '../../UIKit/index';

export default function DCEmailCredits(props) {
    return (
        <SVGIcon {...props} viewBox="0 0 501.305 321.845">
            <g id="Email_Credits" transform="translate(-5.38 -95.315)">
                <text
                    id="Email_Credits-2"
                    data-name="Email Credits"
                    transform="translate(255.45 256.99)"
                    fill="#231f20"
                    fontSize="12"
                    fontFamily="MyriadPro-Regular, Myriad Pro"
                >
                    <tspan x="0" y="0">
                        Email C
                    </tspan>
                    <tspan y="0" letterSpacing="-0.01em">
                        r
                    </tspan>
                    <tspan y="0">edits</tspan>
                </text>
                <text
                    id="Email_Credits-3"
                    data-name="Email Credits"
                    transform="translate(255.45 256.99)"
                    fill="#231f20"
                    fontSize="12"
                    fontFamily="MyriadPro-Regular, Myriad Pro"
                >
                    <tspan x="0" y="0">
                        Email C
                    </tspan>
                    <tspan y="0" letterSpacing="-0.01em">
                        r
                    </tspan>
                    <tspan y="0">edits</tspan>
                </text>
                <circle
                    id="Ellipse_1"
                    data-name="Ellipse 1"
                    cx="7.21"
                    cy="7.21"
                    r="7.21"
                    transform="translate(424.87 312.83)"
                    fill="#fff"
                    stroke="#e2e2e2"
                    strokeMiterlimit="10"
                    strokeWidth="2"
                />
                <circle
                    id="Ellipse_2"
                    data-name="Ellipse 2"
                    cx="7.21"
                    cy="7.21"
                    r="7.21"
                    transform="translate(126.07 106.21)"
                    fill="#fff"
                    stroke="#e2e2e2"
                    strokeMiterlimit="10"
                    strokeWidth="2"
                />
                <circle
                    id="Ellipse_3"
                    data-name="Ellipse 3"
                    cx="6.95"
                    cy="6.95"
                    r="6.95"
                    transform="translate(76.19 201.76)"
                    fill="#fff"
                    stroke="#e2e2e2"
                    strokeMiterlimit="10"
                    strokeWidth="2"
                />
                <circle
                    id="Ellipse_4"
                    data-name="Ellipse 4"
                    cx="11.32"
                    cy="11.32"
                    r="11.32"
                    transform="translate(461.36 280.11)"
                    fill="#fff"
                    stroke="#e2e2e2"
                    strokeMiterlimit="10"
                    strokeWidth="2"
                />
                <path
                    id="Path_1"
                    data-name="Path 1"
                    d="M76.78,280.89h-5v-5a.63.63,0,0,0-.63-.62h0a.65.65,0,0,0-.64.62v5h-5a.63.63,0,0,0,0,1.26h5v5a.65.65,0,0,0,.64.62.63.63,0,0,0,.63-.62h0v-5h5a.64.64,0,0,0,.63-.63A.63.63,0,0,0,76.78,280.89Z"
                    fill="#e2e2e2"
                    stroke="#e2e2e2"
                    strokeMiterlimit="10"
                    strokeWidth="0.75"
                />
                <path
                    id="Path_2"
                    data-name="Path 2"
                    d="M480.66,342h-5v-5a.63.63,0,0,0-.62-.63h0a.64.64,0,0,0-.64.63v5h-5a.63.63,0,0,0,0,1.26h5v5a.64.64,0,0,0,.64.63.63.63,0,0,0,.62-.63h0v-5h5a.63.63,0,1,0,0-1.26Z"
                    fill="#e2e2e2"
                    stroke="#e2e2e2"
                    strokeMiterlimit="10"
                    strokeWidth="0.75"
                />
                <path
                    id="Path_3"
                    data-name="Path 3"
                    d="M51.64,247.38H43.76v-7.87a1,1,0,0,0-1-1h0a1,1,0,0,0-1,1v7.87H33.93a1,1,0,0,0,0,2h7.85v7.88a1,1,0,0,0,2,0h0v-7.88h7.88a1,1,0,1,0-.02-2Z"
                    fill="#e2e2e2"
                    stroke="#e2e2e2"
                    strokeMiterlimit="10"
                    strokeWidth="0.75"
                />
                <path
                    id="Path_4"
                    data-name="Path 4"
                    d="M505.31,192.34h-7.88v-7.87a1,1,0,0,0-1-1h0a1,1,0,0,0-1,1v7.87h-7.86a1,1,0,0,0,0,2h7.86v7.88a1,1,0,0,0,2,0h0v-7.88h7.88a1,1,0,0,0,0-2Z"
                    fill="#e2e2e2"
                    stroke="#e2e2e2"
                    strokeMiterlimit="10"
                    strokeWidth="0.75"
                />
                <path
                    id="Path_5"
                    data-name="Path 5"
                    d="M342.63,104.55h-7.88V96.69a1,1,0,0,0-1-1h0a1,1,0,0,0-1,1v7.86H324.9a1,1,0,0,0,0,2h7.85v7.88a1,1,0,0,0,2,0h0v-7.88h7.88a1,1,0,0,0,0-2Z"
                    fill="#e2e2e2"
                    stroke="#e2e2e2"
                    strokeMiterlimit="10"
                    strokeWidth="0.75"
                />
                <ellipse
                    id="_Path_"
                    data-name="&lt;Path&gt;"
                    cx="238.35"
                    cy="41.43"
                    rx="238.35"
                    ry="41.43"
                    transform="translate(5.38 334.3)"
                    fill="#f4f5f6"
                />
                <rect
                    id="Rectangle_1"
                    data-name="Rectangle 1"
                    width="61.86"
                    height="41.7"
                    transform="translate(374.829 248.571) rotate(-12.87)"
                    fill="#e4e3e3"
                />
                <path
                    id="Path_6"
                    data-name="Path 6"
                    d="M435.45,235.22a5.72,5.72,0,0,1-.44.67l-1.34,1.85-5,6.77-16.79,22.25-.14.18-.2-.1-.51-.24-25.41-12.34-7.67-3.8-2.07-1.06a3.45,3.45,0,0,1-.71-.4,4.278,4.278,0,0,1,.75.3l2.12,1,7.75,3.64,25.49,12.19.5.24-.35.09,16.93-22.15,5.16-6.66c.6-.74,1.07-1.34,1.43-1.79a3.58,3.58,0,0,1,.5-.64Z"
                    fill="#707272"
                />
                <path
                    id="Path_7"
                    data-name="Path 7"
                    d="M384.43,289.65c-.14-.07,3.12-6.68,7.28-14.76s7.65-14.57,7.79-14.5-3.13,6.68-7.29,14.76S384.57,289.72,384.43,289.65Z"
                    fill="#707272"
                />
                <path
                    id="Path_8"
                    data-name="Path 8"
                    d="M419.43,255.3c.1-.12,5.84,4.39,12.83,10.07s12.58,10.38,12.48,10.5-5.84-4.39-12.83-10.07S419.33,255.42,419.43,255.3Z"
                    fill="#707272"
                />
                <rect
                    id="Rectangle_2"
                    data-name="Rectangle 2"
                    width="24.59"
                    height="36.48"
                    transform="translate(160.954 148.215) rotate(-77)"
                    fill="#e4e3e3"
                />
                <path
                    id="Path_9"
                    data-name="Path 9"
                    d="M202.08,132.79a2.27,2.27,0,0,1-.4.24l-1.19.64-4.41,2.3-14.63,7.48-.12.06-.08-.11-.21-.25c-4-5.1-7.65-9.71-10.3-13.09l-3.1-4c-.34-.45-.62-.81-.82-1.09a3.44,3.44,0,0,1-.28-.4,4.157,4.157,0,0,1,.33.36l.87,1,3.18,3.93c2.68,3.35,6.34,8,10.38,13l.21.26h-.21l14.7-7.36,4.45-2.2,1.22-.59A2.578,2.578,0,0,1,202.08,132.79Z"
                    fill="#707272"
                />
                <path
                    id="Path_10"
                    data-name="Path 10"
                    d="M161,148.54c0-.07,3.38-2.74,7.67-6s7.8-5.76,7.86-5.69-3.38,2.74-7.67,6S161.07,148.61,161,148.54Z"
                    fill="#707272"
                />
                <path
                    id="Path_11"
                    data-name="Path 11"
                    d="M188.42,139.32c.08,0,2,3.83,4.22,8.64s4,8.75,3.91,8.79-2-3.83-4.21-8.65S188.34,139.36,188.42,139.32Z"
                    fill="#707272"
                />
                <rect
                    id="Rectangle_3"
                    data-name="Rectangle 3"
                    width="28.26"
                    height="19.05"
                    transform="translate(365.81 141.442) rotate(-23.25)"
                    fill="#e4e3e3"
                />
                <path
                    id="Path_12"
                    data-name="Path 12"
                    d="M392,130.42a1.6,1.6,0,0,1-.14.34c-.12.24-.27.55-.45.94-.42.84-1,2-1.7,3.46-1.48,2.93-3.49,6.94-5.72,11.38l-.05.09h-.1l-.24-.06-12.44-3.46L367.35,142l-1-.31-.35-.12a2.5,2.5,0,0,1,.37.07l1,.26,3.78,1,12.46,3.38.24.07-.15.06c2.3-4.41,4.3-8.41,5.79-11.33l1.78-3.42.49-.92A2.829,2.829,0,0,1,392,130.42Z"
                    fill="#707272"
                />
                <path
                    id="Path_13"
                    data-name="Path 13"
                    d="M373.5,159.08c-.06,0,.86-3.25,2.06-7.23s2.24-7.18,2.31-7.16-.86,3.26-2.06,7.24S373.57,159.1,373.5,159.08Z"
                    fill="#707272"
                />
                <path
                    id="Path_14"
                    data-name="Path 14"
                    d="M386.4,140.77c0-.07,3,1.49,6.6,3.46s6.5,3.63,6.47,3.7-3-1.49-6.59-3.47S386.37,140.83,386.4,140.77Z"
                    fill="#707272"
                />
                <rect
                    id="Rectangle_4"
                    data-name="Rectangle 4"
                    width="260.79"
                    height="172.46"
                    rx="14.57"
                    transform="translate(132.06 191.03)"
                    fill="#707272"
                />
                <path
                    id="Path_15"
                    data-name="Path 15"
                    d="M121.87,352.35H404.41v2.44a12.65,12.65,0,0,1-12.65,12.65H134.52a12.65,12.65,0,0,1-12.65-12.65Z"
                    fill="#4f4f4f"
                />
                <rect
                    id="Rectangle_5"
                    data-name="Rectangle 5"
                    width="246.06"
                    height="145.64"
                    transform="translate(139.06 197.88)"
                    fill="#4f4f4f"
                />
                <g id="Group_1" data-name="Group 1" opacity="0.5">
                    <path
                        id="Path_16"
                        data-name="Path 16"
                        d="M236.75,350.73l3.5,5.58h49.34l3.25-5.58Z"
                    />
                </g>
                <rect
                    id="Rectangle_6"
                    data-name="Rectangle 6"
                    width="236.43"
                    height="140.24"
                    rx="3.27"
                    transform="translate(144.13 202.81)"
                    fill="#e4e3e3"
                />
                <path
                    id="Path_17"
                    data-name="Path 17"
                    d="M380.31,343.26H143.88l.25-129.97H380.31Z"
                    fill="#fafbfb"
                />
                <path
                    id="Path_18"
                    data-name="Path 18"
                    d="M163.52,208.22a1.89,1.89,0,1,1-1.89-1.88,1.89,1.89,0,0,1,1.89,1.88Z"
                    fill="#4f4f4f"
                />
                <path
                    id="Path_19"
                    data-name="Path 19"
                    d="M152.07,208.22a1.89,1.89,0,1,1-1.88-1.88,1.89,1.89,0,0,1,1.88,1.88Z"
                    fill="#4f4f4f"
                />
                <path
                    id="Path_20"
                    data-name="Path 20"
                    d="M157.57,208.22a1.89,1.89,0,1,1-1.89-1.88,1.89,1.89,0,0,1,1.89,1.88Z"
                    fill="#4f4f4f"
                />
                <path
                    id="Path_21"
                    data-name="Path 21"
                    d="M390.61,350.73c0,.09-57.88.17-129.27.17s-129.28-.08-129.28-.17,57.87-.17,129.28-.17S390.61,350.64,390.61,350.73Z"
                    fill="#263238"
                />
                <path
                    id="Path_22"
                    data-name="Path 22"
                    d="M219.84,264.06l96.19-.9,2.81,65.39-99.6-.71Z"
                    fill="#707272"
                />
                <path
                    id="Path_23"
                    data-name="Path 23"
                    d="M219.84,264.06l45.45-36.93a5.23,5.23,0,0,1,6.6,0l44.14,36"
                    fill="#e7515b"
                />
                <path
                    id="Path_24"
                    data-name="Path 24"
                    d="M219.84,264.06l45.45-36.93a5.23,5.23,0,0,1,6.6,0l44.14,36"
                    fill="#707272"
                />
                <path
                    id="Path_25"
                    data-name="Path 25"
                    d="M219.24,327.84c-.12-.12,7.56-8.54,17.16-18.82s17.47-18.52,17.6-18.41-7.56,8.54-17.16,18.82S219.36,328,219.24,327.84Z"
                    fill="#4f4f4f"
                />
                <path
                    id="Path_26"
                    data-name="Path 26"
                    d="M318.84,328.55c-.12.11-7.64-8.11-16.78-18.36s-16.47-18.65-16.35-18.76,7.64,8.11,16.79,18.36S319,328.44,318.84,328.55Z"
                    fill="#4f4f4f"
                />
                <path
                    id="Path_27"
                    data-name="Path 27"
                    d="M316.03,263.16l-48.22,38.69-47.97-37.79"
                    fill="#e4e3e3"
                />
                <path
                    id="Path_28"
                    data-name="Path 28"
                    d="M224.68,267.81l-.21-29.88,83.06-1.17,1,31.44-41.34,33.14Z"
                    fill="#fafbfb"
                />
                <path
                    id="Path_29"
                    data-name="Path 29"
                    d="M224.68,267.81l.79.59,2.27,1.76,8.59,6.74,30.95,24.33h-.17l41.31-33.17-.06.14c-.19-6-.4-12.29-.6-18.69-.14-4.31-.27-8.57-.4-12.74l.17.17-83,1.1.11-.11c0,9.39.07,16.85.1,22v5.9c0-1.34,0-3.31-.07-5.86,0-5.13-.12-12.6-.21-22v-.11h.11l83.06-1.22h.17v.18c.14,4.17.27,8.43.41,12.74.2,6.4.4,12.7.59,18.69v.09l-.06.05-41.36,33.13-.08.07-.09-.07L236.2,277l-8.54-6.79-2.23-1.78Z"
                    fill="#707272"
                />
                <path
                    id="Path_30"
                    data-name="Path 30"
                    d="M295.42,251.56c0,.1-12.88.28-28.76.41s-28.77.16-28.78.06,12.88-.28,28.77-.41S295.42,251.47,295.42,251.56Z"
                    fill="#707272"
                />
                <path
                    id="Path_31"
                    data-name="Path 31"
                    d="M295.44,260.05c0,.1-12.88.28-28.77.41s-28.77.16-28.77.06,12.88-.28,28.77-.41S295.44,260,295.44,260.05Z"
                    fill="#707272"
                />
                <path
                    id="Path_32"
                    data-name="Path 32"
                    d="M295.46,269.83c0,.1-12.88.28-28.77.41s-28.77.16-28.77.06,12.88-.28,28.77-.41S295.46,269.73,295.46,269.83Z"
                    fill="#707272"
                />
                <path
                    id="Path_33"
                    data-name="Path 33"
                    d="M295.48,279.61c0,.1-12.29.28-27.44.4s-27.45.15-27.45.05,12.28-.28,27.44-.4S295.48,279.51,295.48,279.61Z"
                    fill="#707272"
                />
                <path
                    id="Path_34"
                    data-name="Path 34"
                    d="M284.73,288.22h-1.34l-3.56.09-11.74.21c-4.58.09-8.73.11-11.74.11h-4.89a1.061,1.061,0,0,1,.33,0h1l3.56-.09,11.74-.21c4.58-.09,8.73-.12,11.74-.11h4.56A1,1,0,0,1,284.73,288.22Z"
                    fill="#707272"
                />
                <path
                    id="Path_35"
                    data-name="Path 35"
                    d="M273.9,296.49a53.471,53.471,0,0,1-6.13.19,52.626,52.626,0,0,1-6.14-.16,53.583,53.583,0,0,1,6.14-.19A55.317,55.317,0,0,1,273.9,296.49Z"
                    fill="#707272"
                />
                <path
                    id="Path_36"
                    data-name="Path 36"
                    d="M285.25,236.14c.1,0,.14.4.09.87s-.16.83-.26.82-.14-.39-.09-.86S285.15,236.13,285.25,236.14Z"
                    fill="#707272"
                />
                <path
                    id="Path_37"
                    data-name="Path 37"
                    d="M286.15,229.54a4.089,4.089,0,0,1-.07,1.67,4,4,0,0,1-.43,1.62,4,4,0,0,1,.08-1.67A4.249,4.249,0,0,1,286.15,229.54Z"
                    fill="#707272"
                />
                <path
                    id="Path_38"
                    data-name="Path 38"
                    d="M287.48,223a4.06,4.06,0,0,1-.18,1.66,4,4,0,0,1-.54,1.59,4.23,4.23,0,0,1,.19-1.67A4.06,4.06,0,0,1,287.48,223Z"
                    fill="#707272"
                />
                <path
                    id="Path_39"
                    data-name="Path 39"
                    d="M289.29,216.59a4.2,4.2,0,0,1-.31,1.64,4.11,4.11,0,0,1-.66,1.55,8.08,8.08,0,0,1,1-3.19Z"
                    fill="#707272"
                />
                <path
                    id="Path_40"
                    data-name="Path 40"
                    d="M291.62,210.34a4,4,0,0,1-.45,1.61,4.179,4.179,0,0,1-.78,1.49,3.91,3.91,0,0,1,.45-1.62,4.07,4.07,0,0,1,.78-1.48Z"
                    fill="#707272"
                />
                <path
                    id="Path_41"
                    data-name="Path 41"
                    d="M294.51,204.34a4.3,4.3,0,0,1-.6,1.56c-.42.82-.83,1.45-.92,1.4a4,4,0,0,1,.6-1.56C294,204.92,294.42,204.29,294.51,204.34Z"
                    fill="#707272"
                />
                <path
                    id="Path_42"
                    data-name="Path 42"
                    d="M298,198.66a4.22,4.22,0,0,1-.78,1.48,5.093,5.093,0,0,1-1,1.31,4.11,4.11,0,0,1,.75-1.5C297.43,199.18,297.91,198.6,298,198.66Z"
                    fill="#707272"
                />
                <path
                    id="Path_43"
                    data-name="Path 43"
                    d="M302.1,193.41a4.1,4.1,0,0,1-.93,1.39c-.58.71-1.12,1.23-1.2,1.17s.34-.68.93-1.39S302,193.34,302.1,193.41Z"
                    fill="#707272"
                />
                <path
                    id="Path_44"
                    data-name="Path 44"
                    d="M306.8,188.68c.06.07-.42.64-1.09,1.27s-1.26,1.09-1.33,1,.42-.64,1.09-1.27S306.74,188.61,306.8,188.68Z"
                    fill="#707272"
                />
                <path
                    id="Path_45"
                    data-name="Path 45"
                    d="M312.05,184.57c.05.08-.5.59-1.24,1.13a4.06,4.06,0,0,1-1.45.84c-.06-.08.49-.58,1.24-1.13A4.06,4.06,0,0,1,312.05,184.57Z"
                    fill="#707272"
                />
                <path
                    id="Path_46"
                    data-name="Path 46"
                    d="M317.8,181.19a4,4,0,0,1-1.39.95,3.92,3.92,0,0,1-1.55.64c-.05-.08.57-.51,1.39-.95A4.17,4.17,0,0,1,317.8,181.19Z"
                    fill="#707272"
                />
                <path
                    id="Path_47"
                    data-name="Path 47"
                    d="M324,178.68a4,4,0,0,1-1.51.74,4.14,4.14,0,0,1-1.63.4A8.25,8.25,0,0,1,324,178.68Z"
                    fill="#707272"
                />
                <path
                    id="Path_48"
                    data-name="Path 48"
                    d="M330.46,177.17a4,4,0,0,1-1.61.45,4.32,4.32,0,0,1-1.67.18,4,4,0,0,1,1.6-.52A4.19,4.19,0,0,1,330.46,177.17Z"
                    fill="#707272"
                />
                <path
                    id="Path_49"
                    data-name="Path 49"
                    d="M337.11,176.81a4.39,4.39,0,0,1-1.67.19,4.1,4.1,0,0,1-1.67-.16,4.3,4.3,0,0,1,1.67-.19,4,4,0,0,1,1.67.16Z"
                    fill="#707272"
                />
                <path
                    id="Path_50"
                    data-name="Path 50"
                    d="M343.72,177.59a4.34,4.34,0,0,1-1.67-.09,4.42,4.42,0,0,1-1.62-.44,4,4,0,0,1,1.68.09A3.74,3.74,0,0,1,343.72,177.59Z"
                    fill="#707272"
                />
                <path
                    id="Path_51"
                    data-name="Path 51"
                    d="M350.11,179.49a4.11,4.11,0,0,1-1.63-.38,4.17,4.17,0,0,1-1.52-.71,4.17,4.17,0,0,1,1.63.38A4,4,0,0,1,350.11,179.49Z"
                    fill="#707272"
                />
                <path
                    id="Path_52"
                    data-name="Path 52"
                    d="M356,182.52a4.11,4.11,0,0,1-1.53-.68c-.8-.45-1.41-.89-1.36-1a3.93,3.93,0,0,1,1.53.67C355.49,182,356.1,182.44,356,182.52Z"
                    fill="#707272"
                />
                <path
                    id="Path_53"
                    data-name="Path 53"
                    d="M361.27,186.64c-.07.07-.69-.36-1.37-1s-1.2-1.16-1.14-1.24a4.27,4.27,0,0,1,1.37,1C360.83,186,361.34,186.57,361.27,186.64Z"
                    fill="#707272"
                />
                <path
                    id="Path_54"
                    data-name="Path 54"
                    d="M364.56,190.44c-.08.06-.37-.19-.66-.56s-.45-.72-.38-.78.37.19.66.56S364.64,190.38,364.56,190.44Z"
                    fill="#707272"
                />
                <path
                    id="Path_55"
                    data-name="Path 55"
                    d="M395.93,231.3a4.11,4.11,0,0,1-1.62-.55,4.48,4.48,0,0,1-1.46-.87,4.19,4.19,0,0,1,1.61.55,4.35,4.35,0,0,1,1.47.87Z"
                    fill="#707272"
                />
                <path
                    id="Path_56"
                    data-name="Path 56"
                    d="M402.33,233.53a4.08,4.08,0,0,1-1.67-.32,4.2,4.2,0,0,1-1.57-.67,8.41,8.41,0,0,1,3.24,1Z"
                    fill="#707272"
                />
                <path
                    id="Path_57"
                    data-name="Path 57"
                    d="M409,234.6a8.22,8.22,0,0,1-3.37-.37,8.27,8.27,0,0,1,3.37.37Z"
                    fill="#707272"
                />
                <path
                    id="Path_58"
                    data-name="Path 58"
                    d="M415.79,234.39a4.17,4.17,0,0,1-1.68.3,4.1,4.1,0,0,1-1.7-.05,4.411,4.411,0,0,1,1.67-.3A4.21,4.21,0,0,1,415.79,234.39Z"
                    fill="#707272"
                />
                <path
                    id="Path_59"
                    data-name="Path 59"
                    d="M422.45,233.14a4.31,4.31,0,0,1-1.61.58,4.23,4.23,0,0,1-1.7.16,4.42,4.42,0,0,1,1.62-.51,4.36,4.36,0,0,1,1.69-.23Z"
                    fill="#707272"
                />
                <path
                    id="Path_60"
                    data-name="Path 60"
                    d="M428.74,230.64c.05.09-.61.48-1.46.87a4.11,4.11,0,0,1-1.62.55,4.349,4.349,0,0,1,1.47-.87,4.46,4.46,0,0,1,1.61-.55Z"
                    fill="#707272"
                />
                <path
                    id="Path_61"
                    data-name="Path 61"
                    d="M434.36,226.86c.07.08-.46.64-1.22,1.2a3.82,3.82,0,0,1-1.48.85c-.06-.09.53-.57,1.27-1.13a5.181,5.181,0,0,1,1.43-.92Z"
                    fill="#707272"
                />
                <path
                    id="Path_62"
                    data-name="Path 62"
                    d="M439,221.91a3.76,3.76,0,0,1-.91,1.46c-.6.73-1.18,1.22-1.25,1.16a4.932,4.932,0,0,1,1-1.39C438.45,222.42,438.89,221.86,439,221.91Z"
                    fill="#707272"
                />
                <path
                    id="Path_63"
                    data-name="Path 63"
                    d="M442.15,215.94a3.53,3.53,0,0,1-.47,1.65c-.38.86-.82,1.5-.91,1.45a5.3,5.3,0,0,1,.59-1.6,5.438,5.438,0,0,1,.79-1.5Z"
                    fill="#707272"
                />
                <path
                    id="Path_64"
                    data-name="Path 64"
                    d="M443.4,209.31a3.41,3.41,0,0,1,.08,1.71,3.52,3.52,0,0,1-.42,1.66,5.341,5.341,0,0,1,.07-1.7,5.869,5.869,0,0,1,.27-1.67Z"
                    fill="#707272"
                />
                <path
                    id="Path_65"
                    data-name="Path 65"
                    d="M442.43,202.62a3.66,3.66,0,0,1,.61,1.6,3.55,3.55,0,0,1,.14,1.71,5,5,0,0,1-.48-1.63,4.871,4.871,0,0,1-.27-1.68Z"
                    fill="#707272"
                />
                <path
                    id="Path_66"
                    data-name="Path 66"
                    d="M439.48,196.54a4.232,4.232,0,0,1,1.06,1.35,3.439,3.439,0,0,1,.64,1.58,4.941,4.941,0,0,1-.94-1.41,5.059,5.059,0,0,1-.76-1.52Z"
                    fill="#707272"
                />
                <path
                    id="Path_67"
                    data-name="Path 67"
                    d="M434.77,191.71c.06-.08.73.29,1.45.91s1.18,1.24,1.11,1.3a5.8,5.8,0,0,1-1.34-1C435.29,192.34,434.72,191.79,434.77,191.71Z"
                    fill="#707272"
                />
                <path
                    id="Path_68"
                    data-name="Path 68"
                    d="M428.62,189a3.49,3.49,0,0,1,1.7.28,3.3,3.3,0,0,1,1.52.78,5.54,5.54,0,0,1-1.63-.45,5.64,5.64,0,0,1-1.59-.61Z"
                    fill="#707272"
                />
                <path
                    id="Path_69"
                    data-name="Path 69"
                    d="M421.9,189.06a3.33,3.33,0,0,1,1.64-.49,3.46,3.46,0,0,1,1.71.05,5.64,5.64,0,0,1-1.66.3,5.57,5.57,0,0,1-1.69.14Z"
                    fill="#707272"
                />
                <path
                    id="Path_70"
                    data-name="Path 70"
                    d="M416.05,192.34c-.07-.07.4-.7,1.16-1.27a3.09,3.09,0,0,1,1.53-.78c.05.09-.58.5-1.31,1.06a7.35,7.35,0,0,1-1.38.99Z"
                    fill="#707272"
                />
                <path
                    id="Path_71"
                    data-name="Path 71"
                    d="M413.37,198.42a3.12,3.12,0,0,1,.1-1.71,3,3,0,0,1,.71-1.57q-.334,1.657-.81,3.28Z"
                    fill="#707272"
                />
                <path
                    id="Path_72"
                    data-name="Path 72"
                    d="M414.69,205a3.39,3.39,0,0,1-.82-1.51,3.27,3.27,0,0,1-.29-1.69,5.682,5.682,0,0,1,.62,1.58,6,6,0,0,1,.49,1.62Z"
                    fill="#707272"
                />
                <path
                    id="Path_73"
                    data-name="Path 73"
                    d="M419.13,210c-.06.08-.74-.29-1.45-.92s-1.16-1.26-1.09-1.32a6.013,6.013,0,0,1,1.32,1.06A10.4,10.4,0,0,1,419.13,210Z"
                    fill="#707272"
                />
                <path
                    id="Path_74"
                    data-name="Path 74"
                    d="M425.28,212.8a3.66,3.66,0,0,1-1.68-.31,3.48,3.48,0,0,1-1.52-.79,5.27,5.27,0,0,1,1.63.45,5.111,5.111,0,0,1,1.57.65Z"
                    fill="#707272"
                />
                <path
                    id="Path_75"
                    data-name="Path 75"
                    d="M432,213.44a3.57,3.57,0,0,1-1.7.2,3.78,3.78,0,0,1-1.69-.27A12.55,12.55,0,0,1,432,213.44Z"
                    fill="#707272"
                />
                <path
                    id="Path_76"
                    data-name="Path 76"
                    d="M438.66,212.21a3.69,3.69,0,0,1-1.59.64,3.58,3.58,0,0,1-1.69.19,5.211,5.211,0,0,1,1.61-.53,5,5,0,0,1,1.67-.3Z"
                    fill="#707272"
                />
                <path
                    id="Path_77"
                    data-name="Path 77"
                    d="M444.81,209.39a4.18,4.18,0,0,1-1.42,1,4.4,4.4,0,0,1-1.58.64,8.35,8.35,0,0,1,3-1.59Z"
                    fill="#707272"
                />
                <path
                    id="Path_78"
                    data-name="Path 78"
                    d="M450.25,205.35c.06.07-.48.62-1.2,1.21a4.594,4.594,0,0,1-1.43.94c-.06-.06.48-.62,1.2-1.21A4.28,4.28,0,0,1,450.25,205.35Z"
                    fill="#707272"
                />
                <path
                    id="Path_79"
                    data-name="Path 79"
                    d="M454.86,200.39c.08.06-.35.69-1,1.41s-1.17,1.24-1.24,1.17.36-.69,1-1.4S454.79,200.33,454.86,200.39Z"
                    fill="#707272"
                />
                <path
                    id="Path_80"
                    data-name="Path 80"
                    d="M458.59,194.73a4,4,0,0,1-.72,1.55,4.459,4.459,0,0,1-1,1.36,4.1,4.1,0,0,1,.72-1.54,4.56,4.56,0,0,1,1-1.37Z"
                    fill="#707272"
                />
                <path
                    id="Path_81"
                    data-name="Path 81"
                    d="M461.34,188.54a4,4,0,0,1-.45,1.64c-.34.88-.7,1.55-.8,1.51a5,5,0,0,1,.47-1.64,4.271,4.271,0,0,1,.78-1.51Z"
                    fill="#707272"
                />
                <path
                    id="Path_82"
                    data-name="Path 82"
                    d="M463.24,182a8,8,0,0,1-.85,3.28,4.35,4.35,0,0,1,.25-1.68,4.221,4.221,0,0,1,.6-1.6Z"
                    fill="#707272"
                />
                <path
                    id="Path_83"
                    data-name="Path 83"
                    d="M464.43,175.36a4.259,4.259,0,0,1-.09,1.7,4.139,4.139,0,0,1-.43,1.65A4.32,4.32,0,0,1,464,177,4.06,4.06,0,0,1,464.43,175.36Z"
                    fill="#707272"
                />
                <path
                    id="Path_84"
                    data-name="Path 84"
                    d="M465.05,168.6a4.19,4.19,0,0,1,.05,1.71,4.139,4.139,0,0,1-.3,1.67,4.13,4.13,0,0,1-.05-1.7,4.27,4.27,0,0,1,.3-1.68Z"
                    fill="#707272"
                />
                <path
                    id="Path_85"
                    data-name="Path 85"
                    d="M465.27,161.83a4.38,4.38,0,0,1,.14,1.7,4.23,4.23,0,0,1-.21,1.69,4.231,4.231,0,0,1-.14-1.7,4.181,4.181,0,0,1,.21-1.69Z"
                    fill="#707272"
                />
                <path
                    id="Path_86"
                    data-name="Path 86"
                    d="M465.22,155a4.149,4.149,0,0,1,.2,1.7,4.571,4.571,0,0,1-.15,1.7,8.35,8.35,0,0,1,0-3.4Z"
                    fill="#707272"
                />
                <path
                    id="Path_87"
                    data-name="Path 87"
                    d="M465.12,150.87c.09,0,.18.17.18.39s-.06.39-.16.39-.18-.17-.19-.38S465,150.88,465.12,150.87Z"
                    fill="#707272"
                />
                <rect
                    id="Rectangle_7"
                    data-name="Rectangle 7"
                    width="46.37"
                    height="31.26"
                    transform="translate(433.885 135.745) rotate(-6.2)"
                    fill="#e4e3e3"
                />
                <path
                    id="Path_88"
                    data-name="Path 88"
                    d="M437.24,166.85h0l-3.37-31.09h0l46.12-5h0l3.38,31.09h0Zm-3.35-31.07,3.38,31,46.06-5-3.37-31Z"
                    fill="#e4e3e3"
                />
                <path
                    id="Path_89"
                    data-name="Path 89"
                    d="M480.17,131.11a3.745,3.745,0,0,1-.39.46l-1.16,1.27-4.33,4.6c-3.72,3.88-8.81,9.2-14.44,15.1l-.12.12-.15-.09-.35-.22-17.84-11.41L436,137.45l-1.46-1a3.633,3.633,0,0,1-.49-.36,3.36,3.36,0,0,1,.54.29l1.49.9,5.46,3.38c4.61,2.92,10.93,6.9,17.91,11.3l.35.22h-.26l14.53-15,4.42-4.51,1.22-1.21a3.112,3.112,0,0,1,.46-.35Z"
                    fill="#707272"
                />
                <path
                    id="Path_90"
                    data-name="Path 90"
                    d="M437.44,167.19c-.1-.06,2.91-4.7,6.71-10.35s7-10.18,7.06-10.12-2.91,4.7-6.71,10.35S437.53,167.26,437.44,167.19Z"
                    fill="#707272"
                />
                <path
                    id="Path_91"
                    data-name="Path 91"
                    d="M466.49,144.67c.08-.08,4,3.77,8.68,8.61s8.45,8.83,8.37,8.91-4-3.78-8.68-8.62S466.41,144.75,466.49,144.67Z"
                    fill="#707272"
                />
                <path
                    id="Path_92"
                    data-name="Path 92"
                    d="M269.45,225c.1,0,.24.34.32.81a1.514,1.514,0,0,1,0,.86,1.4,1.4,0,0,1-.32-.81C269.37,225.39,269.36,225,269.45,225Z"
                    fill="#707272"
                />
                <path
                    id="Path_93"
                    data-name="Path 93"
                    d="M268.37,218.35a4.14,4.14,0,0,1,.44,1.63,4,4,0,0,1,.09,1.68,4.27,4.27,0,0,1-.44-1.63,4,4,0,0,1-.09-1.68Z"
                    fill="#707272"
                />
                <path
                    id="Path_94"
                    data-name="Path 94"
                    d="M267.4,211.72a7.789,7.789,0,0,1,.46,3.32,4.42,4.42,0,0,1-.4-1.64A4,4,0,0,1,267.4,211.72Z"
                    fill="#707272"
                />
                <path
                    id="Path_95"
                    data-name="Path 95"
                    d="M266.64,205.05a4.22,4.22,0,0,1,.35,1.65,4,4,0,0,1,0,1.69,8.092,8.092,0,0,1-.35-3.34Z"
                    fill="#707272"
                />
                <path
                    id="Path_96"
                    data-name="Path 96"
                    d="M266.17,198.37a4,4,0,0,1,.27,1.66,3.92,3.92,0,0,1-.08,1.68,4.169,4.169,0,0,1-.27-1.66,4.3,4.3,0,0,1,.08-1.68Z"
                    fill="#707272"
                />
                <path
                    id="Path_97"
                    data-name="Path 97"
                    d="M266.1,191.66a7.88,7.88,0,0,1,0,3.35,4.091,4.091,0,0,1-.17-1.67A4.161,4.161,0,0,1,266.1,191.66Z"
                    fill="#707272"
                />
                <path
                    id="Path_98"
                    data-name="Path 98"
                    d="M266.52,185a7.85,7.85,0,0,1-.28,3.34,4.249,4.249,0,0,1,0-1.68A4,4,0,0,1,266.52,185Z"
                    fill="#707272"
                />
                <path
                    id="Path_99"
                    data-name="Path 99"
                    d="M267.53,178.34a4.089,4.089,0,0,1-.12,1.68,4.45,4.45,0,0,1-.46,1.63,3.94,3.94,0,0,1,.12-1.69A4.27,4.27,0,0,1,267.53,178.34Z"
                    fill="#707272"
                />
                <path
                    id="Path_100"
                    data-name="Path 100"
                    d="M269.24,171.86a4.3,4.3,0,0,1-.31,1.66,4.1,4.1,0,0,1-.65,1.56,8.05,8.05,0,0,1,1-3.22Z"
                    fill="#707272"
                />
                <path
                    id="Path_101"
                    data-name="Path 101"
                    d="M271.75,165.65a4,4,0,0,1-.52,1.6,4.269,4.269,0,0,1-.83,1.47,4.15,4.15,0,0,1,.51-1.61C271.28,166.26,271.66,165.6,271.75,165.65Z"
                    fill="#707272"
                />
                <path
                    id="Path_102"
                    data-name="Path 102"
                    d="M275,159.8a4.21,4.21,0,0,1-.72,1.52,4.44,4.44,0,0,1-1,1.35,4,4,0,0,1,.71-1.53A4.644,4.644,0,0,1,275,159.8Z"
                    fill="#707272"
                />
                <path
                    id="Path_103"
                    data-name="Path 103"
                    d="M279,154.44a4.419,4.419,0,0,1-.91,1.41c-.58.72-1.12,1.25-1.19,1.2a4.308,4.308,0,0,1,.91-1.42A5.213,5.213,0,0,1,279,154.44Z"
                    fill="#707272"
                />
                <path
                    id="Path_104"
                    data-name="Path 104"
                    d="M283.8,149.72c.07.08-.43.64-1.11,1.27s-1.29,1.07-1.36,1,.43-.64,1.12-1.27A4.29,4.29,0,0,1,283.8,149.72Z"
                    fill="#707272"
                />
                <path
                    id="Path_105"
                    data-name="Path 105"
                    d="M289.28,145.87c.05.09-.54.56-1.31,1a4.46,4.46,0,0,1-1.51.76c-.06-.08.53-.55,1.32-1.06a4.19,4.19,0,0,1,1.5-.7Z"
                    fill="#707272"
                />
                <path
                    id="Path_106"
                    data-name="Path 106"
                    d="M295.39,143.12a7.876,7.876,0,0,1-3.12,1.22,4.31,4.31,0,0,1,1.5-.77,4.14,4.14,0,0,1,1.62-.45Z"
                    fill="#707272"
                />
                <path
                    id="Path_107"
                    data-name="Path 107"
                    d="M300.29,141.92c0,.09-.34.24-.8.33s-.84.08-.86,0,.33-.24.79-.33S300.27,141.82,300.29,141.92Z"
                    fill="#707272"
                />
                <rect
                    id="Rectangle_8"
                    data-name="Rectangle 8"
                    width="33.99"
                    height="50.42"
                    transform="translate(251.949 148.148) rotate(-68.86)"
                    fill="#e4e3e3"
                />
                <path
                    id="Path_108"
                    data-name="Path 108"
                    d="M299,166.36h0l-47.05-18.19h0l12.26-31.71h0l47,18.2h0Zm-47-18.22,47,18.17,12.25-31.66-47-18.18Z"
                    fill="#e4e3e3"
                />
                <path
                    id="Path_109"
                    data-name="Path 109"
                    d="M311.24,135.07a2.491,2.491,0,0,1-.6.26l-1.75.64-6.48,2.28-21.49,7.37-.18.06-.09-.16-.23-.39-11.54-19.92-3.45-6.07c-.39-.69-.69-1.24-.92-1.66a3.934,3.934,0,0,1-.3-.59,2.65,2.65,0,0,1,.38.55l1,1.61,3.58,6c3,5.12,7.11,12.12,11.66,19.86l.23.39-.28-.1,21.53-7.25,6.53-2.14,1.78-.56Z"
                    fill="#707272"
                />
                <path
                    id="Path_110"
                    data-name="Path 110"
                    d="M252,148.59c-.06-.11,5.15-3.09,11.65-6.65s11.81-6.36,11.87-6.25-5.16,3.09-11.65,6.65S252,148.7,252,148.59Z"
                    fill="#707272"
                />
                <path
                    id="Path_111"
                    data-name="Path 111"
                    d="M291.26,141.34c.13,0,1.95,5.63,4.08,12.65s3.77,12.75,3.65,12.78-1.95-5.62-4.08-12.65S291.14,141.37,291.26,141.34Z"
                    fill="#707272"
                />
                <path
                    id="Path_112"
                    data-name="Path 112"
                    d="M249.7,232.39c.1,0,.15.39.11.86s-.15.84-.24.83-.15-.39-.11-.86S249.61,232.39,249.7,232.39Z"
                    fill="#707272"
                />
                <path
                    id="Path_113"
                    data-name="Path 113"
                    d="M249.88,225.49a4.62,4.62,0,0,1,.17,1.73,4.5,4.5,0,0,1-.18,1.73,4.36,4.36,0,0,1-.18-1.73A4.47,4.47,0,0,1,249.88,225.49Z"
                    fill="#707272"
                />
                <path
                    id="Path_114"
                    data-name="Path 114"
                    d="M249.41,218.61a4.6,4.6,0,0,1,.33,1.7,4.26,4.26,0,0,1,0,1.74,4.741,4.741,0,0,1-.33-1.7,4.26,4.26,0,0,1,0-1.74Z"
                    fill="#707272"
                />
                <path
                    id="Path_115"
                    data-name="Path 115"
                    d="M248.26,211.81a4.43,4.43,0,0,1,.5,1.66,4.38,4.38,0,0,1,.16,1.73,4.49,4.49,0,0,1-.51-1.66,4.26,4.26,0,0,1-.15-1.73Z"
                    fill="#707272"
                />
                <path
                    id="Path_116"
                    data-name="Path 116"
                    d="M246.38,205.17a4.32,4.32,0,0,1,.68,1.59,4.2,4.2,0,0,1,.35,1.7,8.63,8.63,0,0,1-1-3.29Z"
                    fill="#707272"
                />
                <path
                    id="Path_117"
                    data-name="Path 117"
                    d="M243.74,198.8c.09-.05.48.63.87,1.5a4.24,4.24,0,0,1,.55,1.64,4.3,4.3,0,0,1-.87-1.5,4.57,4.57,0,0,1-.55-1.64Z"
                    fill="#707272"
                />
                <path
                    id="Path_118"
                    data-name="Path 118"
                    d="M240.32,192.81c.08-.05.57.55,1.08,1.36a4.27,4.27,0,0,1,.73,1.58,12.189,12.189,0,0,1-1.81-2.94Z"
                    fill="#707272"
                />
                <path
                    id="Path_119"
                    data-name="Path 119"
                    d="M236.09,187.36c.07-.06.63.48,1.24,1.21a7.377,7.377,0,0,1,1,1.44,5.9,5.9,0,0,1-1.24-1.21A4.37,4.37,0,0,1,236.09,187.36Z"
                    fill="#707272"
                />
                <path
                    id="Path_120"
                    data-name="Path 120"
                    d="M231.14,182.56a4.55,4.55,0,0,1,1.4,1c.71.64,1.23,1.21,1.16,1.28s-.69-.39-1.4-1S231.08,182.64,231.14,182.56Z"
                    fill="#707272"
                />
                <path
                    id="Path_121"
                    data-name="Path 121"
                    d="M225.54,178.53a4.46,4.46,0,0,1,1.54.81c.79.53,1.39,1,1.34,1.1a4.29,4.29,0,0,1-1.54-.81C226.09,179.11,225.49,178.61,225.54,178.53Z"
                    fill="#707272"
                />
                <path
                    id="Path_122"
                    data-name="Path 122"
                    d="M219.4,175.4a8.49,8.49,0,0,1,3.13,1.44,4.27,4.27,0,0,1-1.64-.56,4.43,4.43,0,0,1-1.49-.88Z"
                    fill="#707272"
                />
                <path
                    id="Path_123"
                    data-name="Path 123"
                    d="M212.82,173.32a8.53,8.53,0,0,1,3.34.9,4.7,4.7,0,0,1-1.72-.28A4.65,4.65,0,0,1,212.82,173.32Z"
                    fill="#707272"
                />
                <path
                    id="Path_124"
                    data-name="Path 124"
                    d="M206,172.44a4.47,4.47,0,0,1,1.73-.07,4.2,4.2,0,0,1,1.71.35,10.62,10.62,0,0,1-3.44-.28Z"
                    fill="#707272"
                />
                <path
                    id="Path_125"
                    data-name="Path 125"
                    d="M199.11,172.92a4.15,4.15,0,0,1,1.69-.38,4.4,4.4,0,0,1,1.74,0,4.42,4.42,0,0,1-1.7.38,4.6,4.6,0,0,1-1.73,0Z"
                    fill="#707272"
                />
                <path
                    id="Path_126"
                    data-name="Path 126"
                    d="M192.45,174.69A4.32,4.32,0,0,1,194,174a4.15,4.15,0,0,1,1.7-.35,4.651,4.651,0,0,1-1.59.69,4.59,4.59,0,0,1-1.66.35Z"
                    fill="#707272"
                />
                <path
                    id="Path_127"
                    data-name="Path 127"
                    d="M186.24,177.68a4.44,4.44,0,0,1,1.43-1,4.33,4.33,0,0,1,1.6-.66,4.44,4.44,0,0,1-1.43,1,4.33,4.33,0,0,1-1.6.66Z"
                    fill="#707272"
                />
                <path
                    id="Path_128"
                    data-name="Path 128"
                    d="M180.73,181.82c-.07-.07.47-.62,1.21-1.24a5.327,5.327,0,0,1,1.44-1,4.786,4.786,0,0,1-1.22,1.24,4.39,4.39,0,0,1-1.43,1Z"
                    fill="#707272"
                />
                <path
                    id="Path_129"
                    data-name="Path 129"
                    d="M176.22,187a3.87,3.87,0,0,1,.87-1.51c.59-.76,1.17-1.29,1.24-1.22a5.32,5.32,0,0,1-1,1.44C176.79,186.49,176.3,187.08,176.22,187Z"
                    fill="#707272"
                />
                <path
                    id="Path_130"
                    data-name="Path 130"
                    d="M173.7,191.51c-.09,0,0-.42.21-.84s.45-.74.53-.69,0,.42-.21.84S173.79,191.55,173.7,191.51Z"
                    fill="#707272"
                />
                <path
                    id="Path_131"
                    data-name="Path 131"
                    d="M130.61,231.53c0-.1.36-.22.82-.28s.84,0,.86.08-.35.22-.82.27S130.61,231.63,130.61,231.53Z"
                    fill="#707272"
                />
                <path
                    id="Path_132"
                    data-name="Path 132"
                    d="M123.89,230.77a5.16,5.16,0,0,1,1.68.25,5.71,5.71,0,0,1,1.64.44,3.44,3.44,0,0,1-1.71-.09,3.59,3.59,0,0,1-1.61-.6Z"
                    fill="#707272"
                />
                <path
                    id="Path_133"
                    data-name="Path 133"
                    d="M117.82,227.77a5,5,0,0,1,1.52.77,5.18,5.18,0,0,1,1.4,1,3.71,3.71,0,0,1-1.58-.67C118.34,228.36,117.76,227.85,117.82,227.77Z"
                    fill="#707272"
                />
                <path
                    id="Path_134"
                    data-name="Path 134"
                    d="M112.77,223.24c.08-.07.63.45,1.29,1.11s1.17,1.23,1.1,1.3-.69-.38-1.35-1.05A3.951,3.951,0,0,1,112.77,223.24Z"
                    fill="#707272"
                />
                <path
                    id="Path_135"
                    data-name="Path 135"
                    d="M108.78,217.74c.09-.05.57.55,1.08,1.33a4.3,4.3,0,0,1,.78,1.51c-.07.06-.56-.53-1.07-1.32A4.31,4.31,0,0,1,108.78,217.74Z"
                    fill="#707272"
                />
                <path
                    id="Path_136"
                    data-name="Path 136"
                    d="M105.85,211.62a4.24,4.24,0,0,1,.83,1.49,4.12,4.12,0,0,1,.5,1.63,8.54,8.54,0,0,1-1.33-3.12Z"
                    fill="#707272"
                />
                <path
                    id="Path_137"
                    data-name="Path 137"
                    d="M103.9,205.11a4.14,4.14,0,0,1,.61,1.6,4.48,4.48,0,0,1,.26,1.68,4,4,0,0,1-.61-1.59,4.32,4.32,0,0,1-.26-1.69Z"
                    fill="#707272"
                />
                <path
                    id="Path_138"
                    data-name="Path 138"
                    d="M102.72,198.41a4.22,4.22,0,0,1,.43,1.66,4.15,4.15,0,0,1,.08,1.7,4.27,4.27,0,0,1-.43-1.65A4.42,4.42,0,0,1,102.72,198.41Z"
                    fill="#707272"
                />
                <path
                    id="Path_139"
                    data-name="Path 139"
                    d="M102.07,191.65a4.07,4.07,0,0,1,.31,1.68,4.35,4.35,0,0,1,0,1.7,4.15,4.15,0,0,1-.32-1.67A4.28,4.28,0,0,1,102.07,191.65Z"
                    fill="#707272"
                />
                <path
                    id="Path_140"
                    data-name="Path 140"
                    d="M101.79,186.55c.1,0,.19.38.22.84s0,.85-.14.86-.19-.37-.22-.84S101.69,186.56,101.79,186.55Z"
                    fill="#707272"
                />
                <rect
                    id="Rectangle_9"
                    data-name="Rectangle 9"
                    width="56.46"
                    height="38.05"
                    transform="matrix(0.999, -0.047, 0.047, 0.999, 70.943, 148.085)"
                    fill="#e4e3e3"
                />
                <path
                    id="Path_141"
                    data-name="Path 141"
                    d="M72.71,186.12h0l-1.8-38h0l56.41-2.66h0l1.8,38h0Zm-1.76-38,1.79,38,56.36-2.66-1.79-38Z"
                    fill="#e4e3e3"
                />
                <path
                    id="Path_142"
                    data-name="Path 142"
                    d="M127.53,145.88a3.271,3.271,0,0,1-.5.53l-1.51,1.45-5.6,5.27-18.67,17.28-.16.14-.17-.12-.41-.3L79.67,155l-6.28-4.65L71.7,149a3.852,3.852,0,0,1-.57-.48,4.279,4.279,0,0,1,.63.39l1.75,1.21,6.38,4.52,20.92,15.05.41.3h-.32l18.77-17.16,5.72-5.15,1.57-1.38a4.691,4.691,0,0,1,.57-.42Z"
                    fill="#707272"
                />
                <path
                    id="Path_143"
                    data-name="Path 143"
                    d="M72.92,186.55c-.11-.08,3.89-5.49,8.93-12.08s9.21-11.86,9.33-11.77-3.88,5.49-8.93,12.08S73,186.64,72.92,186.55Z"
                    fill="#707272"
                />
                <path
                    id="Path_144"
                    data-name="Path 144"
                    d="M109.9,161.34c.11-.09,4.54,4.88,9.9,11.11s9.63,11.35,9.52,11.45-4.54-4.89-9.9-11.12S109.79,161.43,109.9,161.34Z"
                    fill="#707272"
                />
                <path
                    id="Path_145"
                    data-name="Path 145"
                    d="M171.86,280.46a1.54,1.54,0,0,0-1.17-1.72l-1.51.1L163.77,277a1.61,1.61,0,0,0-2,1.47c0,1.56-.65,5.79-1.91,4.46l-.09-.09c-1.79-2-3.85-2.94-4.65-2.83a.9.9,0,0,0-.39,1.6.91.91,0,0,0,.32.22l.18.1a13.1,13.1,0,0,1,2.07,1.85,7.18,7.18,0,0,1,1.36,2.05c.37.84.65,1.58.87,2.21l.08,5-2.16,23.24s-14.3-15-14-15.17l.82,23.39,8.67,7.17A10.89,10.89,0,0,0,170.77,323l-.49-30,.35-3.94Z"
                    fill="#e4e3e3"
                />
                <path
                    id="Path_146"
                    data-name="Path 146"
                    d="M91.51,313.5,75.86,349.34,63.47,375.19l-9.21,3.1S47,380.4,47,381.91s1.92,1.16,1.92,1.16c.36,2.25,3.47,1,5.65,0s7-1.22,7-1.22a2,2,0,0,0-.13,3.17c.77.54,2.11-.12,3-1.09s3.25-1.42,5.86-2.35,3-4.44,3-4.44l31.35-52.82Z"
                    fill="#e4e3e3"
                />
                <path
                    id="Path_147"
                    data-name="Path 147"
                    d="M143.49,326.51l3.95,5.21,8.53-19s-8.28-10.11-11.31-14.35-5.26-4.94-5.26-4.94-5.51-1.63-8.26-1.57l-13.6.81-13.82,6a19.15,19.15,0,0,0-8.32,7l-13,20.9,18.42,7.87,6.18,35.4c1.18,5.3,2.22,10.3,7.11,12.74l34.16-32.84-4-6.3Z"
                    fill="#707272"
                />
                <path
                    id="Path_148"
                    data-name="Path 148"
                    d="M110.83,320c.08,0-2,3.32-4.7,7.31s-4.91,7.16-5,7.11,2-3.33,4.71-7.31S110.75,320,110.83,320Z"
                    fill="#263238"
                />
                <path
                    id="Path_149"
                    data-name="Path 149"
                    d="M139.47,353.56a5.4,5.4,0,0,1-.91.54c-.59.33-1.46.79-2.54,1.35-2.15,1.12-5.16,2.62-8.51,4.2s-6.43,3-8.66,3.9c-1.12.47-2,.85-2.66,1.1a4.858,4.858,0,0,1-1,.35,6.9,6.9,0,0,1,.94-.48l2.62-1.2c2.2-1,5.25-2.41,8.61-4s6.37-3,8.55-4.1L138.5,354a6.783,6.783,0,0,1,.97-.44Z"
                    fill="#263238"
                />
                <path
                    id="Path_150"
                    data-name="Path 150"
                    d="M109.74,312.39a11.35,11.35,0,0,1-1.2-1.51,24,24,0,0,0-3-3.46,10.71,10.71,0,0,0-3.91-2.31c-1.12-.34-1.86-.34-1.86-.4a1.26,1.26,0,0,1,.52,0,6.42,6.42,0,0,1,1.41.2,9.93,9.93,0,0,1,4.08,2.28,19.54,19.54,0,0,1,3,3.58,9.522,9.522,0,0,1,.96,1.62Z"
                    fill="#263238"
                />
                <path
                    id="Path_151"
                    data-name="Path 151"
                    d="M137.85,329.6a2.119,2.119,0,0,1,0,.37v1.08a25.571,25.571,0,0,1-.55,3.91,28,28,0,0,1-15.78,19.68,25.359,25.359,0,0,1-3.71,1.38c-.44.14-.8.21-1,.27a1.478,1.478,0,0,1-.37.07,12.568,12.568,0,0,1,1.37-.47,32.058,32.058,0,0,0,3.64-1.47A28.71,28.71,0,0,0,137,334.91a31.236,31.236,0,0,0,.65-3.88A12.46,12.46,0,0,1,137.85,329.6Z"
                    fill="#263238"
                />
                <g id="Group_2" data-name="Group 2" opacity="0.3">
                    <path
                        id="Path_152"
                        data-name="Path 152"
                        d="M103.2,348.18c-.06-.34,4.38-23.3,4.38-23.3l-6.7,10.05"
                    />
                </g>
                <path
                    id="Path_153"
                    data-name="Path 153"
                    d="M142.62,304.47a1.869,1.869,0,0,1,0,.4,7.053,7.053,0,0,0,.07,1.13c0,1,.12,2.45.21,4.18.17,3.56.41,8.41.67,13.77s.45,10.25.56,13.78c0,1.76.09,3.19.1,4.18V343a1.79,1.79,0,0,1,0,.4,1.871,1.871,0,0,1,0-.4l-.07-1.13c0-1-.12-2.45-.21-4.17-.17-3.57-.41-8.42-.67-13.78s-.46-10.25-.56-13.77c0-1.77-.09-3.2-.1-4.18v-1.14a1.79,1.79,0,0,1,0-.36Z"
                    fill="#263238"
                />
                <g id="Group_3" data-name="Group 3" opacity="0.2">
                    <path
                        id="Path_154"
                        data-name="Path 154"
                        d="M115.35,365c-1.09-.91-.84-2.76.12-3.81a8.51,8.51,0,0,1,3.75-1.93c6.5-2.1,13-5.08,17.3-10.39a10.461,10.461,0,0,1,1.92-2.06,2.63,2.63,0,0,1,2.67-.37c1.31.68,1.36,2.58.81,3.95a7.63,7.63,0,0,1-3.58,3.91"
                    />
                </g>
                <g id="Group_4" data-name="Group 4" opacity="0.2">
                    <path id="Path_155" data-name="Path 155" d="M142.76,308.55l3.79,22-2.95-3.9Z" />
                </g>
                <path
                    id="Path_156"
                    data-name="Path 156"
                    d="M178.83,366.34l-6.41,22,27.31,8.11c.62-2.66-11.95-12.09-11.95-12.09l4.68-13.92Z"
                    fill="#707272"
                />
                <g id="Group_5" data-name="Group 5" opacity="0.6">
                    <path
                        id="Path_157"
                        data-name="Path 157"
                        d="M179.68,380.65a1.18,1.18,0,0,0-1.15,1.05,1.14,1.14,0,0,0,1,1.15,1.23,1.23,0,0,0,1.21-1.11,1.138,1.138,0,0,0-1.2-1.08"
                        fill="#fff"
                    />
                </g>
                <path
                    id="Path_158"
                    data-name="Path 158"
                    d="M172.42,388.33l.73-2.2,25.92,8.61s1.06.89.66,1.7Z"
                    fill="#4f4f4f"
                />
                <path
                    id="Path_159"
                    data-name="Path 159"
                    d="M188.26,384.34c0,.13-.73,0-1.51.23s-1.3.69-1.41.6.32-.8,1.27-1.07S188.32,384.23,188.26,384.34Z"
                    fill="#263238"
                />
                <path
                    id="Path_160"
                    data-name="Path 160"
                    d="M190.77,386.38c0,.13-.65.19-1.27.62s-.93,1-1.06.94,0-.82.79-1.34S190.81,386.25,190.77,386.38Z"
                    fill="#263238"
                />
                <path
                    id="Path_161"
                    data-name="Path 161"
                    d="M191.38,390.29c-.13,0-.12-.73.44-1.36s1.27-.73,1.3-.61-.48.41-.93.93S191.52,390.31,191.38,390.29Z"
                    fill="#263238"
                />
                <path
                    id="Path_162"
                    data-name="Path 162"
                    d="M189,381.05c-.1.11-.65-.26-1.42-.43s-1.43-.11-1.47-.25.67-.44,1.58-.22S189.07,381,189,381.05Z"
                    fill="#263238"
                />
                <path
                    id="Path_163"
                    data-name="Path 163"
                    d="M189.29,377.62a2.74,2.74,0,0,1-1.37-.42,6.179,6.179,0,0,1-1.3-.86,6.711,6.711,0,0,1-.66-.65,1.859,1.859,0,0,1-.32-.45.61.61,0,0,1,.15-.72.78.78,0,0,1,.68-.14,2.24,2.24,0,0,1,.49.23,4.9,4.9,0,0,1,.76.54,5.131,5.131,0,0,1,1,1.2c.46.78.53,1.34.47,1.35s-.26-.48-.76-1.15a5.461,5.461,0,0,0-1-1.05,5.3,5.3,0,0,0-.72-.48c-.26-.16-.51-.24-.62-.13s0,.06,0,.16a1.5,1.5,0,0,0,.24.33,5.916,5.916,0,0,0,.6.62,7.081,7.081,0,0,0,1.17.9C188.81,377.36,189.31,377.54,189.29,377.62Z"
                    fill="#263238"
                />
                <path
                    id="Path_164"
                    data-name="Path 164"
                    d="M189.06,377.76a2.2,2.2,0,0,1,0-1.43,4.58,4.58,0,0,1,1.23-2.17,1.16,1.16,0,0,1,1.07-.43.64.64,0,0,1,.46.55,1.61,1.61,0,0,1,0,.54,2.91,2.91,0,0,1-.27.92,3.28,3.28,0,0,1-1,1.24c-.75.55-1.35.55-1.35.5a10.748,10.748,0,0,0,1.13-.76,3.33,3.33,0,0,0,.81-1.16,2.851,2.851,0,0,0,.21-.8c0-.3,0-.57-.11-.57a.82.82,0,0,0-.61.31,4.138,4.138,0,0,0-.56.64,4.7,4.7,0,0,0-.67,1.29C189.14,377.22,189.14,377.76,189.06,377.76Z"
                    fill="#263238"
                />
                <path
                    id="Path_165"
                    data-name="Path 165"
                    d="M206.87,333.6c.69,5.07-1,10.8-2.88,15.57l-12,28.17-13.25-2a17.11,17.11,0,0,1-6.3,4.26c-1.58.52-6.78,1.55-11.06-1.53h0s-6.06,8.43-15.59,11-24,4-30.94-7.19l33.25-32c.69.17,28.64-18.69,43.74-23C191.86,326.8,205.6,324.28,206.87,333.6Z"
                    fill="#4f4f4f"
                />
                <path
                    id="Path_166"
                    data-name="Path 166"
                    d="M177.77,375.19l.18-.11.54-.28c.48-.26,1.19-.62,2.08-1.12a32.1,32.1,0,0,0,3.17-1.95,16.19,16.19,0,0,0,3.59-3.33,11.43,11.43,0,0,0,2.33-5.38,18.21,18.21,0,0,0-.28-6.78,10.94,10.94,0,0,0-3.66-6.36,13.77,13.77,0,0,0-7.49-2.67,23.36,23.36,0,0,0-4.1,0,29.734,29.734,0,0,0-3.94.69,46.561,46.561,0,0,0-7.15,2.47c-4.44,1.94-8.28,4-11.66,5.47a41.63,41.63,0,0,1-8.24,2.75c-.5.11-1,.19-1.35.25s-.72.11-1,.13l-.61.06H140l.21-.05.6-.09a5.952,5.952,0,0,0,1-.17c.4-.08.83-.15,1.33-.27a43.836,43.836,0,0,0,8.17-2.85c3.35-1.47,7.18-3.58,11.63-5.54a46.488,46.488,0,0,1,7.2-2.51,30.352,30.352,0,0,1,4-.7,22.318,22.318,0,0,1,4.17,0,14.07,14.07,0,0,1,7.67,2.75,9.55,9.55,0,0,1,2.5,3,13.451,13.451,0,0,1,1.26,3.53,18.2,18.2,0,0,1,.26,6.9,11.75,11.75,0,0,1-2.42,5.49,16.411,16.411,0,0,1-3.67,3.33,31.671,31.671,0,0,1-3.22,1.92c-.91.47-1.63.82-2.12,1.05l-.56.26Z"
                    fill="#707272"
                />
                <path
                    id="Path_167"
                    data-name="Path 167"
                    d="M186.09,338.24a42.232,42.232,0,0,1-2.78,4.53,39.409,39.409,0,0,1-3.08,4.34,42.229,42.229,0,0,1,2.78-4.53A38.256,38.256,0,0,1,186.09,338.24Z"
                    fill="#707272"
                />
                <path
                    id="Path_168"
                    data-name="Path 168"
                    d="M176.52,373a22.271,22.271,0,0,1-2.4.62c-1.49.37-3.53.94-5.74,1.71s-4.16,1.61-5.55,2.25a18.243,18.243,0,0,1-2.27,1,2.732,2.732,0,0,1,.56-.37c.37-.22.91-.52,1.6-.87a46.312,46.312,0,0,1,5.54-2.35,45.788,45.788,0,0,1,5.8-1.62c.75-.15,1.37-.26,1.8-.32a2.709,2.709,0,0,1,.66-.05Z"
                    fill="#707272"
                />
                <path
                    id="Path_169"
                    data-name="Path 169"
                    d="M191.78,375.49c-.06,0-.22-.6-.78-1.43a6,6,0,0,0-3-2.3,5.89,5.89,0,0,0-3.8.09c-.94.34-1.43.75-1.47.7s.09-.14.32-.32a4.581,4.581,0,0,1,1.06-.61,5.76,5.76,0,0,1,4-.2,5.82,5.82,0,0,1,3.13,2.51,4.63,4.63,0,0,1,.49,1.12A1.33,1.33,0,0,1,191.78,375.49Z"
                    fill="#707272"
                />
                <path
                    id="Path_170"
                    data-name="Path 170"
                    d="M134.41,365a1.77,1.77,0,0,1,.18.64,10.529,10.529,0,0,1,.15,1.78,11,11,0,0,1-1.56,5.66,8.63,8.63,0,0,1-4.55,3.7,6.632,6.632,0,0,1-1.76.34,1.75,1.75,0,0,1-.66,0,12.149,12.149,0,0,0,2.34-.54,8.81,8.81,0,0,0,4.33-3.64,11.57,11.57,0,0,0,1.61-5.48C134.55,365.91,134.35,365,134.41,365Z"
                    fill="#707272"
                />
                <path
                    id="Path_171"
                    data-name="Path 171"
                    d="M119.45,380.2c-.07.06-.42-.25-.75-.71s-.51-.9-.42-.95.38.29.71.74S119.53,380.13,119.45,380.2Z"
                    fill="#707272"
                />
                <path
                    id="Path_172"
                    data-name="Path 172"
                    d="M122.25,383.14c-.07.08-.45-.18-.86-.57s-.67-.77-.6-.84.45.2.85.58S122.31,383.07,122.25,383.14Z"
                    fill="#707272"
                />
                <path
                    id="Path_173"
                    data-name="Path 173"
                    d="M125.54,385.53c-.05.08-.47-.1-1-.41s-.81-.62-.76-.7.49.1,1,.4S125.59,385.44,125.54,385.53Z"
                    fill="#707272"
                />
                <path
                    id="Path_174"
                    data-name="Path 174"
                    d="M129.27,387.13c0,.09-.48,0-1-.18s-.93-.42-.89-.51.49,0,1,.18S129.29,387,129.27,387.13Z"
                    fill="#707272"
                />
                <path
                    id="Path_175"
                    data-name="Path 175"
                    d="M133.28,387.76c0,.1-.46.13-1,.08s-1-.17-1-.27.48-.13,1-.08S133.28,387.66,133.28,387.76Z"
                    fill="#707272"
                />
                <path
                    id="Path_176"
                    data-name="Path 176"
                    d="M137.34,387.54c0,.1-.44.21-1,.24a4.751,4.751,0,0,1-.76,0h0c-.16,0-.27,0-.27-.08s.09-.1.25-.15h0a4,4,0,0,1,.75-.11C136.87,387.39,137.32,387.44,137.34,387.54Z"
                    fill="#707272"
                />
                <path
                    id="Path_177"
                    data-name="Path 177"
                    d="M141.33,386.82c0,.09-.4.26-1,.38s-1,.14-1,0,.41-.27,1-.38S141.31,386.72,141.33,386.82Z"
                    fill="#707272"
                />
                <path
                    id="Path_178"
                    data-name="Path 178"
                    d="M145.22,385.63c0,.09-.37.31-.91.49s-1,.25-1,.16.38-.32.91-.49S145.19,385.54,145.22,385.63Z"
                    fill="#707272"
                />
                <path
                    id="Path_179"
                    data-name="Path 179"
                    d="M149,384c0,.09-.34.35-.85.59s-1,.35-1,.26.34-.35.85-.59A2.555,2.555,0,0,1,149,384Z"
                    fill="#707272"
                />
                <path
                    id="Path_180"
                    data-name="Path 180"
                    d="M152.52,382.09c.06.08-.3.38-.78.66s-.93.45-1,.36.3-.38.79-.66S152.47,382,152.52,382.09Z"
                    fill="#707272"
                />
                <path
                    id="Path_181"
                    data-name="Path 181"
                    d="M155.88,379.8c.06.08-.26.4-.72.73s-.87.53-.93.45.27-.41.73-.73S155.83,379.72,155.88,379.8Z"
                    fill="#707272"
                />
                <path
                    id="Path_182"
                    data-name="Path 182"
                    d="M159,377.21c.06.07-.23.43-.66.8s-.82.6-.88.53.23-.43.65-.8S159,377.14,159,377.21Z"
                    fill="#707272"
                />
                <path
                    id="Path_183"
                    data-name="Path 183"
                    d="M162,374.41c.06.08-.22.45-.62.83s-.8.64-.87.57.21-.44.62-.83S161.91,374.34,162,374.41Z"
                    fill="#707272"
                />
                <path
                    id="Path_184"
                    data-name="Path 184"
                    d="M165.19,371.91c.06.09-.27.42-.72.74s-.87.54-.93.46.26-.41.72-.74S165.14,371.83,165.19,371.91Z"
                    fill="#707272"
                />
                <path
                    id="Path_185"
                    data-name="Path 185"
                    d="M168.76,370c0,.09-.34.36-.84.61s-.94.38-1,.29.33-.36.84-.61S168.72,369.86,168.76,370Z"
                    fill="#707272"
                />
                <path
                    id="Path_186"
                    data-name="Path 186"
                    d="M172.64,368.74c0,.1-.41.28-.95.42s-1,.17-1,.07.4-.28,1-.42S172.62,368.64,172.64,368.74Z"
                    fill="#707272"
                />
                <path
                    id="Path_187"
                    data-name="Path 187"
                    d="M176.69,368.53c0,.1-.46.13-1,.13s-1-.06-1-.16.45-.21,1-.2S176.7,368.44,176.69,368.53Z"
                    fill="#707272"
                />
                <path
                    id="Path_188"
                    data-name="Path 188"
                    d="M180.61,369.56c0,.09-.48,0-1-.22s-.94-.37-.91-.47.49-.06,1,.13S180.66,369.48,180.61,369.56Z"
                    fill="#707272"
                />
                <path
                    id="Path_189"
                    data-name="Path 189"
                    d="M179.75,372.51c-.06-.08.25-.41.61-.82s.65-.74.74-.69-.09.49-.48.93S179.81,372.59,179.75,372.51Z"
                    fill="#707272"
                />
                <path
                    id="Path_190"
                    data-name="Path 190"
                    d="M176.16,374.38c0-.09.36-.34.87-.55s1-.31,1-.23-.34.34-.87.55S176.19,374.47,176.16,374.38Z"
                    fill="#707272"
                />
                <path
                    id="Path_191"
                    data-name="Path 191"
                    d="M172.29,375.62c0-.09.37-.31.9-.49s1-.25,1-.16-.37.32-.9.5S172.32,375.71,172.29,375.62Z"
                    fill="#707272"
                />
                <path
                    id="Path_192"
                    data-name="Path 192"
                    d="M168.48,377.07c0-.09.36-.33.89-.53s1-.3,1-.21-.36.33-.89.53S168.52,377.16,168.48,377.07Z"
                    fill="#707272"
                />
                <path
                    id="Path_193"
                    data-name="Path 193"
                    d="M164.73,378.66c0-.09.35-.35.87-.57s1-.33,1-.24-.35.34-.86.57S164.77,378.75,164.73,378.66Z"
                    fill="#707272"
                />
                <path
                    id="Path_194"
                    data-name="Path 194"
                    d="M157.15,262.36s4.44-1.34,10.33-7.91,8.27-14.9,9.05-14.92,4.06-1.84,11.55,11.71,5.2,17,3.91,17.07-10.18-1.71-15.58-.69S164.23,272,164.23,272Z"
                    fill="#707272"
                />
                <path
                    id="Path_195"
                    data-name="Path 195"
                    d="M148.14,270.72l1.76,2.81.78,1.26a3.84,3.84,0,0,0,5.11,1.32L165.2,272l-6.58-12.43-9.28,5.86a3.84,3.84,0,0,0-1.2,5.29Z"
                    fill="#4f4f4f"
                />
                <path
                    id="Path_196"
                    data-name="Path 196"
                    d="M154.06,275l3.53,5.8,1.51-.54,10.67,7.44,2.4-5.72s-6.13-2.11-7.39-4.15,0-2.62,0-2.62l-3-3Z"
                    fill="#707272"
                />
                <path
                    id="Path_197"
                    data-name="Path 197"
                    d="M187.58,251.53c4,7.35,5.93,14,4.31,14.9s-6.16-4.37-10.16-11.71-5.93-14-4.31-14.9S183.58,244.19,187.58,251.53Z"
                    fill="#e4e3e3"
                />
                <path
                    id="Path_198"
                    data-name="Path 198"
                    d="M147.63,275.01l2.56-1.02-1.85-2.96-1.88,1.36Z"
                    fill="#707272"
                />
                <g id="Group_6" data-name="Group 6" opacity="0.5">
                    <path
                        id="Path_199"
                        data-name="Path 199"
                        d="M162.76,273.25l-6.7,5.01-1.01-1.65Z"
                    />
                </g>
                <path
                    id="Path_200"
                    data-name="Path 200"
                    d="M148.34,271.03l1.85,2.96-.53.21-1.55-3.01Z"
                    fill="#455b64"
                />
                <path
                    id="Path_201"
                    data-name="Path 201"
                    d="M150.19,271.6a13.762,13.762,0,0,0,2.84-1.35,14.581,14.581,0,0,0,2.67-1.65,15.581,15.581,0,0,0-2.83,1.35,15.369,15.369,0,0,0-2.68,1.65Z"
                    fill="#707272"
                />
                <path
                    id="Path_202"
                    data-name="Path 202"
                    d="M149.25,269.83a24.6,24.6,0,0,0,4.95-2.91A24.6,24.6,0,0,0,149.25,269.83Z"
                    fill="#707272"
                />
                <path
                    id="Path_203"
                    data-name="Path 203"
                    d="M151.35,273.45c0,.05.33-.05.81-.24l.85-.35c.31-.14.63-.33,1-.52a12,12,0,0,0,2.39-1.58,13.521,13.521,0,0,0-2.55,1.28c-.35.19-.66.39-1,.54l-.8.41A2.611,2.611,0,0,0,151.35,273.45Z"
                    fill="#707272"
                />
                <path
                    id="Path_204"
                    data-name="Path 204"
                    d="M180.45,250.36s3.74-2.72,6.28,2.42-1.52,6.31-1.52,6.31a35.43,35.43,0,0,1-4.76-8.73Z"
                    fill="#707272"
                />
                <path
                    id="Path_205"
                    data-name="Path 205"
                    d="M175.82,244.36a5.37,5.37,0,0,0,.22,1c.18.64.42,1.57.76,2.7.21.56.43,1.16.68,1.81.12.33.25.67.38,1,.06.18.13.35.2.53s.16.35.24.53l1.06,2.22.28.58c.09.2.21.38.31.57.23.38.45.77.68,1.16l.67,1.16c.11.18.21.38.34.56l.37.53,1.4,2c.12.16.22.32.34.47l.36.44.69.83c.44.54.86,1,1.24,1.49.82.85,1.5,1.52,2,2a6.317,6.317,0,0,0,.75.69,6.467,6.467,0,0,0-.66-.78l-1.89-2.06-1.2-1.51-.67-.83c-.11-.15-.23-.29-.35-.44l-.33-.47-1.38-2-.36-.52c-.12-.18-.22-.37-.33-.56-.22-.38-.45-.76-.67-1.15l-.67-1.15a6.382,6.382,0,0,1-.32-.57l-.28-.57-1.06-2.2a5.007,5.007,0,0,1-.25-.52l-.21-.52c-.13-.34-.26-.68-.39-1L177,248c-.35-1.11-.64-2-.85-2.65a7.8,7.8,0,0,0-.33-.99Z"
                    fill="#4f4f4f"
                />
                <path
                    id="Path_206"
                    data-name="Path 206"
                    d="M165.73,275.22a1.15,1.15,0,0,1,.8,1.39c-.29,1.15-.77,3-1.15,4.07a3.1,3.1,0,0,1-.51,1,1,1,0,0,1-1.73-.49,3.19,3.19,0,0,1,.1-1.19c.24-1.07.68-2.69,1.06-4a1.15,1.15,0,0,1,1.43-.79Z"
                    fill="#e4e3e3"
                />
                <path
                    id="Path_207"
                    data-name="Path 207"
                    d="M167.57,277.09a1.14,1.14,0,0,1,.79,1.39c-.29,1.16-.76,3-1.15,4.08a3,3,0,0,1-.5,1,1.08,1.08,0,0,1-1,.38,1.05,1.05,0,0,1-.72-.87,3,3,0,0,1,.1-1.18c.24-1.08.67-2.69,1-4a1.16,1.16,0,0,1,1.44-.79Z"
                    fill="#e4e3e3"
                />
                <path
                    id="Path_208"
                    data-name="Path 208"
                    d="M169.34,278.22a1,1,0,0,1,.69,1.2c-.25,1-.66,2.57-1,3.53a2.39,2.39,0,0,1-.44.87.92.92,0,0,1-.87.33.93.93,0,0,1-.62-.76,2.53,2.53,0,0,1,.09-1c.2-.93.58-2.33.91-3.47a1,1,0,0,1,1.24-.68Z"
                    fill="#e4e3e3"
                />
                <path
                    id="Path_209"
                    data-name="Path 209"
                    d="M171.36,278.44a1,1,0,0,1,.69,1.21c-.26,1-.67,2.58-1,3.55a2.39,2.39,0,0,1-.44.87.9.9,0,0,1-.87.33.91.91,0,0,1-.63-.75,2.55,2.55,0,0,1,.09-1c.2-.94.59-2.34.92-3.49a1,1,0,0,1,1.25-.69Z"
                    fill="#e4e3e3"
                />
                <g id="Group_7" data-name="Group 7" opacity="0.5">
                    <path
                        id="Path_210"
                        data-name="Path 210"
                        d="M165.09,281.71a14,14,0,0,1,1.34-4.24,7.211,7.211,0,0,1-.5,2.17A7,7,0,0,1,165.09,281.71Z"
                        fill="#707272"
                    />
                </g>
                <g id="Group_8" data-name="Group 8" opacity="0.5">
                    <path
                        id="Path_211"
                        data-name="Path 211"
                        d="M168.91,283.25a28,28,0,0,1,1.27-4.27,6,6,0,0,1-.38,2.21,5.77,5.77,0,0,1-.89,2.06Z"
                        fill="#707272"
                    />
                </g>
                <g id="Group_9" data-name="Group 9" opacity="0.5">
                    <path
                        id="Path_212"
                        data-name="Path 212"
                        d="M167,283a9.42,9.42,0,0,1,.55-2.16,9.2,9.2,0,0,1,.72-2.1,5.94,5.94,0,0,1-.38,2.2A5.66,5.66,0,0,1,167,283Z"
                        fill="#707272"
                    />
                </g>
                <path
                    id="Path_213"
                    data-name="Path 213"
                    d="M201.48,225.65c.08,0-2.7,3.83-6.21,8.43s-6.42,8.28-6.49,8.22,2.7-3.83,6.21-8.43S201.4,225.59,201.48,225.65Z"
                    fill="#707272"
                />
                <path
                    id="Path_214"
                    data-name="Path 214"
                    d="M219.68,236.41c0,.09-5.81,2.89-13.06,6.26s-13.17,6-13.21,5.93,5.8-2.89,13.06-6.25S219.64,236.32,219.68,236.41Z"
                    fill="#707272"
                />
                <path
                    id="Path_215"
                    data-name="Path 215"
                    d="M217,258.81c0,.09-4.18-.23-9.31-.72s-9.29-1-9.28-1.08,4.18.23,9.31.73S217,258.71,217,258.81Z"
                    fill="#707272"
                />
                <path
                    id="Path_216"
                    data-name="Path 216"
                    d="M110,271.37l4.61,26.18a8.61,8.61,0,0,0,9.7,7h0a8.52,8.52,0,0,0,7.33-9.79l-.75-4.51s6.44-1.75,5.77-8.22c-.32-3.11-1.52-9.48-2.67-15.23a12.34,12.34,0,0,0-14.11-9.65h0A12,12,0,0,0,110,271.37Z"
                    fill="#e4e3e3"
                />
                <g id="Group_10" data-name="Group 10" opacity="0.5">
                    <path
                        id="Path_217"
                        data-name="Path 217"
                        d="M130.93,290.23a15.6,15.6,0,0,1-8.83-1.42s2.56,4.42,9,3.1Z"
                        fill="#707272"
                    />
                </g>
                <path
                    id="Path_218"
                    data-name="Path 218"
                    d="M132.62,271.14a.94.94,0,0,1-.8,1,.91.91,0,0,1-1.06-.72.94.94,0,0,1,.79-1,.91.91,0,0,1,1.07.72Z"
                    fill="#707272"
                />
                <path
                    id="Path_219"
                    data-name="Path 219"
                    d="M132.83,269.57c-.1.13-.87-.3-1.88-.19s-1.69.7-1.81.59,0-.28.33-.54a2.57,2.57,0,0,1,1.43-.62,2.47,2.47,0,0,1,1.51.3C132.75,269.31,132.88,269.51,132.83,269.57Z"
                    fill="#707272"
                />
                <path
                    id="Path_220"
                    data-name="Path 220"
                    d="M123.14,272.28a.93.93,0,0,1-.8,1,.9.9,0,0,1-1.06-.72.92.92,0,0,1,.79-1,.9.9,0,0,1,1.07.72Z"
                    fill="#707272"
                />
                <path
                    id="Path_221"
                    data-name="Path 221"
                    d="M123.09,270.88c-.1.13-.87-.31-1.87-.19s-1.69.7-1.82.59,0-.28.33-.55a2.68,2.68,0,0,1,1.43-.62,2.58,2.58,0,0,1,1.52.3C123,270.61,123.15,270.82,123.09,270.88Z"
                    fill="#707272"
                />
                <path
                    id="Path_222"
                    data-name="Path 222"
                    d="M127.61,278.42a7.168,7.168,0,0,1,1.6-.47c.26-.06.5-.14.52-.32a1.27,1.27,0,0,0-.26-.73c-.31-.58-.64-1.2-1-1.84a32.87,32.87,0,0,1-2.29-4.86,34.83,34.83,0,0,1,2.68,4.66l1,1.86a1.46,1.46,0,0,1,.25,1,.64.64,0,0,1-.38.42,1.6,1.6,0,0,1-.43.11A6.472,6.472,0,0,1,127.61,278.42Z"
                    fill="#707272"
                />
                <path
                    id="Path_223"
                    data-name="Path 223"
                    d="M124.72,279.44c.17,0,.3,1.05,1.32,1.71s2.18.41,2.2.56-.23.24-.72.31a2.74,2.74,0,0,1-1.83-.41,2.34,2.34,0,0,1-1-1.44C124.56,279.73,124.65,279.45,124.72,279.44Z"
                    fill="#707272"
                />
                <path
                    id="Path_224"
                    data-name="Path 224"
                    d="M122.9,267.55c-.07.28-1.09.27-2.25.55s-2.09.69-2.27.47.05-.38.4-.69a4.16,4.16,0,0,1,3.45-.77C122.69,267.23,122.93,267.42,122.9,267.55Z"
                    fill="#707272"
                />
                <path
                    id="Path_225"
                    data-name="Path 225"
                    d="M132.27,267.34c-.15.24-.87.09-1.7.17s-1.53.33-1.72.11,0-.35.29-.59a2.49,2.49,0,0,1,2.75-.23C132.22,267,132.34,267.22,132.27,267.34Z"
                    fill="#707272"
                />
                <path
                    id="Path_226"
                    data-name="Path 226"
                    d="M134.05,255.71a2.3,2.3,0,0,0-3,.19,2.05,2.05,0,0,0-.57-2.71,3.45,3.45,0,0,0-3-.43,10.19,10.19,0,0,0-2.77,1.36,1,1,0,0,0-.46-1.06,2.43,2.43,0,0,0-1.16-.36,8.83,8.83,0,0,0-4.89,1.1,30.814,30.814,0,0,0-4.53,3.07h0a21.539,21.539,0,0,0-4.14,4.42h0a7.21,7.21,0,0,0-1.62,4.69c.54,4.66,2.08,7.35,3,11.76a1.61,1.61,0,0,0,.33.82c.54.56,1.5.08,2-.5a8.35,8.35,0,0,0,1.72-5.24c.06-1.87-.2-3.74-.16-5.61v-.14a9.1,9.1,0,0,1,.14-1.43c.08-.3.87-3,3.44-3.41,2.73-.46,8.11,2.19,10.77,1.76s2.81-2.3,2.81-2.3l1.56,3.19a9.54,9.54,0,0,0,.26-4,4.56,4.56,0,0,0,1.32-2.33A3,3,0,0,0,134.05,255.71Z"
                    fill="#707272"
                />
                <path
                    id="Path_227"
                    data-name="Path 227"
                    d="M112.6,274.15c-.12,0-4.66-.8-4,3.6s5,2.84,5,2.71S112.6,274.15,112.6,274.15Z"
                    fill="#e4e3e3"
                />
                <g id="Group_11" data-name="Group 11" opacity="0.5">
                    <path
                        id="Path_228"
                        data-name="Path 228"
                        d="M112,279a1.182,1.182,0,0,1-.2.15.79.79,0,0,1-.58.09,1.92,1.92,0,0,1-1.14-1.57,2.65,2.65,0,0,1,0-1.13.89.89,0,0,1,.5-.67.4.4,0,0,1,.49.14c.08.12.06.21.09.21s.08-.09,0-.26a.47.47,0,0,0-.22-.25.59.59,0,0,0-.44-.05,1.09,1.09,0,0,0-.72.8,2.64,2.64,0,0,0-.08,1.26,2,2,0,0,0,1.42,1.74.88.88,0,0,0,.69-.22C112,279.1,112,279,112,279Z"
                        fill="#707272"
                    />
                </g>
                <path
                    id="Path_229"
                    data-name="Path 229"
                    d="M102.56,306a.78.78,0,0,1,.44,0,1.5,1.5,0,0,1,1,.5,2.2,2.2,0,0,1,.59,1.66,10.2,10.2,0,0,1-.52,2.29,3.11,3.11,0,0,0,.15,2.61c.49.84,1.47,1.48,1.87,2.65a3.13,3.13,0,0,1-1,3.41,3.17,3.17,0,0,1-1.83.71,7.12,7.12,0,0,1-2-.31,3.47,3.47,0,0,0-1.82-.12,2.62,2.62,0,0,0-1.35,1.1,9.89,9.89,0,0,1-1.16,1.44,2.26,2.26,0,0,1-1.71.63,3.41,3.41,0,0,1-2.71-1.79,6.69,6.69,0,0,1-.69-2.77c0-.87,0-1.65,0-2.33a10.209,10.209,0,0,0-.15-1.73,8.521,8.521,0,0,0-.41-1.42,1.457,1.457,0,0,1,.2.34,5.271,5.271,0,0,1,.35,1.05,10,10,0,0,1,.21,1.76,21.331,21.331,0,0,0,.09,2.31,6.34,6.34,0,0,0,.7,2.64,3.11,3.11,0,0,0,2.46,1.62,2,2,0,0,0,1.47-.55,9,9,0,0,0,1.11-1.41A3,3,0,0,1,99.34,319a3.9,3.9,0,0,1,2,.11,6.579,6.579,0,0,0,1.86.31,2.81,2.81,0,0,0,1.63-.63,2.78,2.78,0,0,0,.89-3c-.33-1-1.28-1.7-1.81-2.61a2.8,2.8,0,0,1-.39-1.47,5.76,5.76,0,0,1,.25-1.36,9.61,9.61,0,0,0,.56-2.22,2.07,2.07,0,0,0-.49-1.57,1.52,1.52,0,0,0-.91-.52A2.4,2.4,0,0,0,102.56,306Z"
                    fill="#fafbfb"
                />
                <path
                    id="Path_230"
                    data-name="Path 230"
                    d="M104.43,353.49a.79.79,0,0,0,.2.42,1.81,1.81,0,0,0,1.05.73,2.1,2.1,0,0,0,1.94-.41,4.1,4.1,0,0,0,1.3-2.37c.21-1,.18-2.16.37-3.41a5.49,5.49,0,0,1,.63-1.85,3.06,3.06,0,0,1,1.55-1.39,4.36,4.36,0,0,1,2.18-.2c.74.08,1.46.24,2.2.34a5.311,5.311,0,0,0,2.21-.11,5,5,0,0,0,2-1.1,4.32,4.32,0,0,0,1.22-1.89,3.65,3.65,0,0,0,0-2.17,3.74,3.74,0,0,0-3-2.62c-1.33-.15-2.58.51-3.86.54a3.8,3.8,0,0,1-1.79-.41,9.22,9.22,0,0,1-1.41-.9,8.411,8.411,0,0,0-2.37-1.45,2.43,2.43,0,0,0-2,.13,1.81,1.81,0,0,0-.87.95,3.168,3.168,0,0,0-.1.45.94.94,0,0,1,0-.48,1.83,1.83,0,0,1,.87-1,2.37,2.37,0,0,1,.94-.32,2.92,2.92,0,0,1,1.19.12,8.79,8.79,0,0,1,2.46,1.44,4.82,4.82,0,0,0,3,1.21,9.179,9.179,0,0,0,1.85-.34,5.781,5.781,0,0,1,2.06-.22,4,4,0,0,1,3.23,2.84,3.83,3.83,0,0,1,0,2.37,4.58,4.58,0,0,1-1.31,2,5.341,5.341,0,0,1-2.13,1.18,5.52,5.52,0,0,1-2.35.11c-.76-.1-1.49-.27-2.2-.35a4.13,4.13,0,0,0-2,.17,2.82,2.82,0,0,0-1.41,1.25,5.3,5.3,0,0,0-.6,1.74c-.2,1.2-.18,2.37-.42,3.41a4.2,4.2,0,0,1-1.42,2.48,2.25,2.25,0,0,1-2.09.38,1.77,1.77,0,0,1-1.08-.83A1,1,0,0,1,104.43,353.49Z"
                    fill="#fafbfb"
                />
                <path
                    id="Path_231"
                    data-name="Path 231"
                    d="M127.47,312.4a2.18,2.18,0,0,1,.47-.64,3.69,3.69,0,0,1,2-1,3.52,3.52,0,0,1,3.25,1.29,3.76,3.76,0,0,1,.81,2.1,5.49,5.49,0,0,0,.52,2.36,1.86,1.86,0,0,0,1,.74,5.229,5.229,0,0,0,1.35.21,10.128,10.128,0,0,1,1.46.07,2.8,2.8,0,0,1,1.49.52,2,2,0,0,1,.52.66,1.9,1.9,0,0,1,.13.85,2.81,2.81,0,0,1-.7,1.53,4.27,4.27,0,0,1-1.35,1.07c-.51.26-1,.47-1.57.71a5.65,5.65,0,0,0-1.45.89,2.85,2.85,0,0,0-.84,1.44,21.079,21.079,0,0,0-.09,3.67,4.76,4.76,0,0,1-.51,1.9,2.26,2.26,0,0,1-1.62,1.21,2.5,2.5,0,0,1-1.94-.64,6.34,6.34,0,0,1-1.3-1.58,16.252,16.252,0,0,0-1-1.67,3.1,3.1,0,0,0-1.5-1.09,2.58,2.58,0,0,0-1.77.11,10.451,10.451,0,0,0-1.65.88,8.749,8.749,0,0,1-1.69.88,5.191,5.191,0,0,1-1.86.31,5.88,5.88,0,0,1-3.4-1.19,4.85,4.85,0,0,1-1.86-2.87,3.31,3.31,0,0,1,.81-3.1,3.261,3.261,0,0,1,1.39-.72A11.457,11.457,0,0,1,118,321a4.73,4.73,0,0,0,2.5-.85,4.92,4.92,0,0,0,1.33-2c.29-.72.48-1.45.73-2.1a6,6,0,0,1,.91-1.73,7.842,7.842,0,0,1,1.19-1.14,4.12,4.12,0,0,1,2-1,.89.89,0,0,1,.75.21,1.08,1.08,0,0,0-.75-.14,4.25,4.25,0,0,0-2,1,7.528,7.528,0,0,0-1.13,1.13,6,6,0,0,0-.87,1.7c-.23.65-.42,1.37-.7,2.11a5.09,5.09,0,0,1-1.38,2.12,5,5,0,0,1-2.62.93,4.76,4.76,0,0,0-2.69.92,3,3,0,0,0-.71,2.82,4.55,4.55,0,0,0,1.76,2.67,5.48,5.48,0,0,0,3.2,1.11,4.72,4.72,0,0,0,1.74-.29,9.762,9.762,0,0,0,1.63-.85,10.19,10.19,0,0,1,1.7-.91,3,3,0,0,1,2-.13,3.41,3.41,0,0,1,1.62,1.25,17.891,17.891,0,0,1,1.07,1.7,5.639,5.639,0,0,0,1.21,1.48,2.1,2.1,0,0,0,1.67.57,1.88,1.88,0,0,0,1.36-1,4.44,4.44,0,0,0,.48-1.75,20.27,20.27,0,0,1,.1-3.73,3.2,3.2,0,0,1,.95-1.61,6,6,0,0,1,1.53-.94c.54-.24,1.07-.45,1.56-.69a4.1,4.1,0,0,0,1.26-1,2.53,2.53,0,0,0,.64-1.35,1.4,1.4,0,0,0-.54-1.26,2.54,2.54,0,0,0-1.33-.47c-.48-.05-1-.05-1.45-.08a5.691,5.691,0,0,1-1.42-.23,2.11,2.11,0,0,1-1.11-.85,5.65,5.65,0,0,1-.53-2.47,3.58,3.58,0,0,0-.75-2,3.35,3.35,0,0,0-3.07-1.28,3.62,3.62,0,0,0-2,.93,6.143,6.143,0,0,0-.37.6Z"
                    fill="#fafbfb"
                />
                <path
                    id="Path_232"
                    data-name="Path 232"
                    d="M116.05,301a2.48,2.48,0,0,1-1.41.69,4.48,4.48,0,0,1-1.72,0,3,3,0,0,1-1.85-1.06,4.07,4.07,0,0,1-.67-2,5.47,5.47,0,0,1,.12-1.72,2.24,2.24,0,0,1,.72-1.38,6,6,0,0,0-.47,1.43,5.81,5.81,0,0,0,0,1.64,3.94,3.94,0,0,0,.63,1.84,2.8,2.8,0,0,0,1.63.95,4.76,4.76,0,0,0,1.62.06,7.669,7.669,0,0,0,1.4-.45Z"
                    fill="#fafbfb"
                />
                <path
                    id="Path_233"
                    data-name="Path 233"
                    d="M145.25,299.21a.553.553,0,0,1-.05.19,2,2,0,0,1-.26.49,3.11,3.11,0,0,1-3.12,1.15,3.61,3.61,0,0,1-1.44-.77,4.82,4.82,0,0,0-1.43-.87,1.31,1.31,0,0,0-1.45.57,12.721,12.721,0,0,0-.83,1.65,3.34,3.34,0,0,1-1.2,1.37,3.22,3.22,0,0,1-4.53-1,1.61,1.61,0,0,1-.28-.69,4.79,4.79,0,0,0,.4.62,3.35,3.35,0,0,0,1.53,1.14,3.073,3.073,0,0,0,3.77-1.61,12.443,12.443,0,0,1,.86-1.71,1.82,1.82,0,0,1,.8-.65,1.56,1.56,0,0,1,1,0,4.74,4.74,0,0,1,1.53.95,3.66,3.66,0,0,0,1.32.74,2.86,2.86,0,0,0,1.3,0,3,3,0,0,0,1.64-1A6.217,6.217,0,0,0,145.25,299.21Z"
                    fill="#fafbfb"
                />
                <path
                    id="Path_234"
                    data-name="Path 234"
                    d="M150.08,325.84a3.51,3.51,0,0,1-.75-.49,14.889,14.889,0,0,1-1.85-1.61,8.179,8.179,0,0,1-1.07-1.32,4.56,4.56,0,0,1-.68-1.87,3.65,3.65,0,0,1,.34-2.18,2.92,2.92,0,0,1,.77-.91,9.9,9.9,0,0,1,1-.64,4.162,4.162,0,0,0,.9-.65,2,2,0,0,0,.52-.9,5.06,5.06,0,0,0,.07-2,10.92,10.92,0,0,1-.14-2,2.38,2.38,0,0,1,.63-1.62,3.64,3.64,0,0,1,2.29-.92,1,1,0,0,1,.84.32,1.47,1.47,0,0,0-.84-.18,3.56,3.56,0,0,0-2.11,1,2.12,2.12,0,0,0-.52,1.46,11.93,11.93,0,0,0,.17,1.89,5.42,5.42,0,0,1-.05,2.19,2.43,2.43,0,0,1-.6,1,4.452,4.452,0,0,1-1,.71,3.76,3.76,0,0,0-1.64,1.41,3.44,3.44,0,0,0-.33,2,5.51,5.51,0,0,0,1.61,3.06C149.06,325.06,150.13,325.79,150.08,325.84Z"
                    fill="#fafbfb"
                />
                <path
                    id="Path_235"
                    data-name="Path 235"
                    d="M135.9,339.3a1.14,1.14,0,0,1,.32,0,6.843,6.843,0,0,1,.91.05,8.8,8.8,0,0,1,3.21.94,5.85,5.85,0,0,1,3,3.85,4.11,4.11,0,0,1-.42,2.93,4,4,0,0,1-2.55,1.91,3.64,3.64,0,0,1-1.65-.09,2.2,2.2,0,0,0-1.41,0,2.77,2.77,0,0,0-1.1.85,8,8,0,0,0-.75,1.17,4.66,4.66,0,0,1-1.77,2,3.32,3.32,0,0,1-2.33.27,2.92,2.92,0,0,1-1.62-1,3.18,3.18,0,0,1-.62-1.32,2.489,2.489,0,0,1,0-.91.8.8,0,0,1,.07-.32,4.16,4.16,0,0,0,.11,1.2,3.14,3.14,0,0,0,.64,1.22,2.75,2.75,0,0,0,1.53.94,3.06,3.06,0,0,0,2.13-.29,4.31,4.31,0,0,0,1.62-1.92,4.34,4.34,0,0,1,2-2.18,2.53,2.53,0,0,1,1.61,0,3.32,3.32,0,0,0,1.5.08,3.66,3.66,0,0,0,2.32-1.73,3.79,3.79,0,0,0,.4-2.7,5.66,5.66,0,0,0-2.84-3.69,9.079,9.079,0,0,0-3.11-1C136.34,339.35,135.9,339.33,135.9,339.3Z"
                    fill="#fafbfb"
                />
                <path
                    id="Path_236"
                    data-name="Path 236"
                    d="M123.2,361.7a4.78,4.78,0,0,0-.63-1.4,3.42,3.42,0,0,0-1.27-1,2.81,2.81,0,0,0-1.93-.21,2.87,2.87,0,0,0-1.57,1.15,3.35,3.35,0,0,0-.58,1.52,4.52,4.52,0,0,0,.15,1.53,1.157,1.157,0,0,1-.22-.38,2.47,2.47,0,0,1-.18-1.17,3.38,3.38,0,0,1,.56-1.68,2.93,2.93,0,0,1,3.91-1.05,3.29,3.29,0,0,1,1.33,1.17,2.81,2.81,0,0,1,.44,1.11C123.24,361.55,123.22,361.7,123.2,361.7Z"
                    fill="#fafbfb"
                />
                <path
                    id="Path_237"
                    data-name="Path 237"
                    d="M125.38,371.57a4.379,4.379,0,0,0-.31-.83,3.49,3.49,0,0,0-1.62-1.67,3.35,3.35,0,0,0-3.31.25,5.16,5.16,0,0,0-1.37,1.54,4.55,4.55,0,0,1-.77.89,1.87,1.87,0,0,1-1.19.43,3,3,0,0,1-2-1.22,6.68,6.68,0,0,0-1.44-1.5,2.52,2.52,0,0,0-1.76-.32,3.47,3.47,0,0,0-1.48.62,6,6,0,0,0-1.57,1.8,6.3,6.3,0,0,1-.46.77,3.12,3.12,0,0,1,.34-.84,5.82,5.82,0,0,1,1.54-1.93,3.811,3.811,0,0,1,1.59-.71,2.8,2.8,0,0,1,2,.33,6.67,6.67,0,0,1,1.53,1.56,2.64,2.64,0,0,0,1.78,1.09,2.235,2.235,0,0,0,1.67-1.17,5.41,5.41,0,0,1,1.47-1.61,3.52,3.52,0,0,1,5.24,1.63,2.71,2.71,0,0,1,.17.65.62.62,0,0,1-.05.24Z"
                    fill="#fafbfb"
                />
                <path
                    id="Path_238"
                    data-name="Path 238"
                    d="M130.58,347a8.151,8.151,0,0,0-2-.86,6,6,0,0,0-2.28-.12,5.52,5.52,0,0,0-2.58,1,4.69,4.69,0,0,0-1.63,2.21,4.91,4.91,0,0,0-.24,2.25,6.08,6.08,0,0,0,.72,2,1.939,1.939,0,0,1-.38-.45,4,4,0,0,1-.59-1.5,4.95,4.95,0,0,1,1.91-4.78,5.51,5.51,0,0,1,2.75-1,6,6,0,0,1,2.39.2,4.88,4.88,0,0,1,1.45.7,1.779,1.779,0,0,1,.48.35Z"
                    fill="#fafbfb"
                />
            </g>
        </SVGIcon>
    );
}
