import { useCallback, useEffect, useRef } from 'react';

import PropTypes from 'prop-types';

function useInfiniteScroll({
    loading,
    hasNextPage,
    onLoadMore,
    // pixels from bottom at which we will fetch more
    threshold = 200,
    componentRef = null,
}) {
    const ref = useRef();
    const reactToVanillaBridge = useRef({}); // Connect react to vanilla JS code like addEventListener

    const handlingLoad = useRef(loading);
    const handleScroll = useCallback(() => {
        if (!hasNextPage || handlingLoad.current) return;
        if (
            ref.current.scrollHeight - (ref.current.scrollTop + ref.current.clientHeight) <
            threshold
        ) {
            onLoadMore();
            handlingLoad.current = true;
        }
    }, [hasNextPage, onLoadMore, threshold]);
    reactToVanillaBridge.current.handleScroll = handleScroll;

    const _handleScroll = useCallback(() => {
        reactToVanillaBridge.current.handleScroll();
    }, []);

    useEffect(() => {
        if (!loading) {
            handlingLoad.current = false;
        }
    }, [loading]);

    const setRef = useCallback(
        r => {
            if (!r) return;
            ref.current = r;
            if (componentRef) componentRef.current = r;
            ref.current.addEventListener('scroll', _handleScroll);
            document.addEventListener('resize', _handleScroll);
        },
        [_handleScroll, componentRef],
    );

    return setRef;
}

useInfiniteScroll.propTypes = {
    loading: PropTypes.bool,
    hasNextPage: PropTypes.bool,
    onLoadMore: PropTypes.func,
    threshold: PropTypes.number,
};

export default useInfiniteScroll;
