import React from 'react';

import { SVGIcon } from '../../UIKit/index';

export default function DCGrayForm(props) {
    return (
        <SVGIcon {...props} viewBox="0 0 500.6 476.39">
            <g id="Forms" transform="translate(-4.95 -20.99)">
                <circle
                    id="Ellipse_9"
                    cx="217.63"
                    cy="217.63"
                    r="217.63"
                    transform="translate(6.45 22.49)"
                    fill="#fff"
                    stroke="#707171"
                    strokeMiterlimit="10"
                    strokeWidth="3"
                />
                <rect
                    id="Rectangle_89"
                    width="1.83"
                    height="142.78"
                    transform="translate(86.31 106.49)"
                    fill="#f4f5f6"
                />
                <rect
                    id="Rectangle_90"
                    width="1.83"
                    height="142.78"
                    transform="translate(200.28 106.49)"
                    fill="#f4f5f6"
                />
                <path id="Path_120" d="M209.28,115.47H79.13l-2.74-6.41H212.03Z" fill="#f4f5f6" />
                <path id="Path_121" d="M209.28,124.27H79.13l-2.74-6.41H212.03Z" fill="#f4f5f6" />
                <path id="Path_122" d="M209.28,133.06H79.13l-2.74-6.4H212.03Z" fill="#f4f5f6" />
                <path id="Path_123" d="M209.28,141.86H79.13l-2.74-6.4H212.03Z" fill="#f4f5f6" />
                <path id="Path_124" d="M209.28,150.66H79.13l-2.74-6.41H212.03Z" fill="#f4f5f6" />
                <path id="Path_125" d="M209.28,159.46H79.13l-2.74-6.41H212.03Z" fill="#f4f5f6" />
                <path id="Path_126" d="M209.28,168.26H79.13l-2.74-6.41H212.03Z" fill="#f4f5f6" />
                <path id="Path_127" d="M209.28,177.06H79.13l-2.74-6.41H212.03Z" fill="#f4f5f6" />
                <path id="Path_128" d="M209.28,185.85H79.13l-2.74-6.41H212.03Z" fill="#f4f5f6" />
                <path id="Path_129" d="M209.28,194.65H79.13l-2.74-6.41H212.03Z" fill="#f4f5f6" />
                <path id="Path_130" d="M209.28,203.45H79.13l-2.74-6.41H212.03Z" fill="#f4f5f6" />
                <path id="Path_131" d="M209.28,212.25H79.13l-2.74-6.41H212.03Z" fill="#f4f5f6" />
                <path id="Path_132" d="M209.28,221.04H79.13l-2.74-6.4H212.03Z" fill="#f4f5f6" />
                <path id="Path_133" d="M209.28,229.84H79.13l-2.74-6.4H212.03Z" fill="#f4f5f6" />
                <path id="Path_134" d="M209.28,238.64H79.13l-2.74-6.41H212.03Z" fill="#f4f5f6" />
                <path id="Path_135" d="M209.28,247.44H79.13l-2.74-6.41H212.03Z" fill="#f4f5f6" />
                <rect
                    id="Rectangle_91"
                    width="135.64"
                    height="3.66"
                    transform="translate(76.39 247.44)"
                    fill="#f4f5f6"
                />
                <rect
                    id="Rectangle_92"
                    width="145.1"
                    height="8.55"
                    transform="translate(71.66 101.03)"
                    fill="#f4f5f6"
                />
                <path
                    id="Path_136"
                    d="M73.15,187.05h0a1.48,1.48,0,0,1-1.48-1.48V135.74a1.48,1.48,0,0,1,1.48-1.48h0a1.47,1.47,0,0,1,1.47,1.48v49.83a1.47,1.47,0,0,1-1.47,1.48Z"
                    fill="#f4f5f6"
                />
                <rect
                    id="Rectangle_93"
                    width="1.07"
                    height="29.6"
                    transform="translate(72.61 107.87)"
                    fill="#f4f5f6"
                />
                <rect
                    id="Rectangle_94"
                    width="1.83"
                    height="142.78"
                    transform="translate(367.61 106.49)"
                    fill="#f4f5f6"
                />
                <rect
                    id="Rectangle_95"
                    width="1.83"
                    height="142.78"
                    transform="translate(481.59 106.49)"
                    fill="#f4f5f6"
                />
                <path id="Path_137" d="M490.59,115.47H360.44l-2.75-6.41H493.33Z" fill="#f4f5f6" />
                <path id="Path_138" d="M490.59,124.27H360.44l-2.75-6.41H493.33Z" fill="#f4f5f6" />
                <path id="Path_139" d="M490.59,133.06H360.44l-2.75-6.4H493.33Z" fill="#f4f5f6" />
                <path id="Path_140" d="M490.59,141.86H360.44l-2.75-6.4H493.33Z" fill="#f4f5f6" />
                <path id="Path_141" d="M490.59,150.66H360.44l-2.75-6.41H493.33Z" fill="#f4f5f6" />
                <path id="Path_142" d="M490.59,159.46H360.44l-2.75-6.41H493.33Z" fill="#f4f5f6" />
                <path id="Path_143" d="M490.59,168.26H360.44l-2.75-6.41H493.33Z" fill="#f4f5f6" />
                <path id="Path_144" d="M490.59,177.06H360.44l-2.75-6.41H493.33Z" fill="#f4f5f6" />
                <path id="Path_145" d="M490.59,185.85H360.44l-2.75-6.41H493.33Z" fill="#f4f5f6" />
                <path id="Path_146" d="M490.59,194.65H360.44l-2.75-6.41H493.33Z" fill="#f4f5f6" />
                <path id="Path_147" d="M490.59,203.45H360.44l-2.75-6.41H493.33Z" fill="#f4f5f6" />
                <path id="Path_148" d="M490.59,212.25H360.44l-2.75-6.41H493.33Z" fill="#f4f5f6" />
                <path id="Path_149" d="M490.59,221.04H360.44l-2.75-6.4H493.33Z" fill="#f4f5f6" />
                <path id="Path_150" d="M490.59,229.84H360.44l-2.75-6.4H493.33Z" fill="#f4f5f6" />
                <path id="Path_151" d="M490.59,238.64H360.44l-2.75-6.41H493.33Z" fill="#f4f5f6" />
                <path id="Path_152" d="M490.59,247.44H360.44l-2.75-6.41H493.33Z" fill="#f4f5f6" />
                <rect
                    id="Rectangle_96"
                    width="135.64"
                    height="3.66"
                    transform="translate(357.69 247.44)"
                    fill="#f4f5f6"
                />
                <rect
                    id="Rectangle_97"
                    width="145.1"
                    height="8.55"
                    transform="translate(352.96 101.03)"
                    fill="#f4f5f6"
                />
                <path
                    id="Path_153"
                    d="M354.45,187.05h0a1.47,1.47,0,0,1-1.47-1.48V135.74a1.47,1.47,0,0,1,1.47-1.48h0a1.48,1.48,0,0,1,1.48,1.48v49.83A1.48,1.48,0,0,1,354.45,187.05Z"
                    fill="#f4f5f6"
                />
                <rect
                    id="Rectangle_98"
                    width="1.07"
                    height="29.6"
                    transform="translate(353.92 107.87)"
                    fill="#f4f5f6"
                />
                <rect
                    id="Rectangle_99"
                    width="309.85"
                    height="175.27"
                    transform="translate(135.55 85.97)"
                    fill="#e3e2e3"
                />
                <rect
                    id="Rectangle_100"
                    width="316.7"
                    height="7.32"
                    rx="1.64"
                    transform="translate(132.12 258.18)"
                    fill="#e3e2e3"
                />
                <rect
                    id="Rectangle_101"
                    width="295.62"
                    height="168.56"
                    transform="translate(142.66 91.29)"
                    fill="#f4f5f6"
                />
                <path
                    id="Path_154"
                    d="M306.75,291.21l-14.61-16.08v-9.62a1.67,1.67,0,1,0-3.33,0v9.62L274.2,291.21c-1.52,1.67.83,4.26,2.35,2.59q6.13-6.75,12.26-13.49v12.06a1.67,1.67,0,1,0,3.33,0V280.31L304.4,293.8C305.91,295.47,308.27,292.88,306.75,291.21Z"
                    fill="#e3e2e3"
                />
                <rect
                    id="Rectangle_102"
                    width="333.08"
                    height="16.09"
                    rx="4.14"
                    transform="translate(123.93 77.93)"
                    fill="#e3e2e3"
                />
                <rect
                    id="Rectangle_103"
                    width="130.96"
                    height="45.2"
                    transform="translate(89.46 294.89)"
                    fill="#e3e2e3"
                />
                <rect
                    id="Rectangle_104"
                    width="130.96"
                    height="45.2"
                    transform="translate(89.46 340.1)"
                    fill="#e3e2e3"
                />
                <path
                    id="Path_155"
                    d="M139.23,340.1l16.29-45.21H130.45l-16.3,45.21Z"
                    fill="#f0f0f0"
                />
                <path id="Path_156" d="M167.66,294.89h-5.51L145.86,340.1h5.51Z" fill="#f0f0f0" />
                <path
                    id="Path_157"
                    d="M122.94,385.3l16.29-45.2H114.15L97.86,385.3Z"
                    fill="#f0f0f0"
                />
                <path id="Path_158" d="M151.37,340.1h-5.51l-16.3,45.2h5.51Z" fill="#f0f0f0" />
                <rect
                    id="Rectangle_105"
                    width="4.57"
                    height="130.96"
                    transform="translate(220.42 297.28) rotate(90)"
                    fill="#e1e1e1"
                />
                <rect
                    id="Rectangle_106"
                    width="4.57"
                    height="130.96"
                    transform="translate(220.42 342.13) rotate(90)"
                    fill="#e1e1e1"
                />
                <path
                    id="Path_159"
                    d="M158.29,323.28a2,2,0,1,0,0-3.93h-6.7a2,2,0,0,0,0,3.93Z"
                    fill="#f4f5f6"
                />
                <path
                    id="Path_160"
                    d="M158.29,322.59a2,2,0,1,0,0-3.93h-6.7a2,2,0,0,0,0,3.93Z"
                    fill="#f4f5f6"
                />
                <path
                    id="Path_161"
                    d="M158.29,365a2,2,0,1,0,0-3.93h-6.7a2,2,0,0,0,0,3.93Z"
                    fill="#e1e1e1"
                />
                <path
                    id="Path_162"
                    d="M158.29,364.32a2,2,0,1,0,0-3.93h-6.7a2,2,0,0,0,0,3.93Z"
                    fill="#f4f5f6"
                />
                <path
                    id="Path_163"
                    d="M87.18,292.87v94.41H222.71V292.87Zm131,89.83H91.75V342.49H218.13Zm0-44.79H91.75V297.7H218.13Z"
                    fill="#f4f5f6"
                />
                <rect
                    id="Rectangle_107"
                    width="130.96"
                    height="45.2"
                    transform="translate(224.99 294.89)"
                    fill="#e3e2e3"
                />
                <rect
                    id="Rectangle_108"
                    width="130.96"
                    height="45.2"
                    transform="translate(224.99 340.1)"
                    fill="#e3e2e3"
                />
                <path
                    id="Path_164"
                    d="M326.53,340.1l16.29-45.21H317.75l-16.3,45.21Z"
                    fill="#f0f0f0"
                />
                <path id="Path_165" d="M354.96,294.89h-5.51L333.16,340.1h5.51Z" fill="#f0f0f0" />
                <path
                    id="Path_166"
                    d="M310.24,385.3l16.29-45.2H301.45l-16.29,45.2Z"
                    fill="#f0f0f0"
                />
                <path id="Path_167" d="M338.67,340.1h-5.51l-16.3,45.2h5.51Z" fill="#f0f0f0" />
                <rect
                    id="Rectangle_109"
                    width="4.57"
                    height="130.96"
                    transform="translate(355.95 297.28) rotate(90)"
                    fill="#e1e1e1"
                />
                <rect
                    id="Rectangle_110"
                    width="4.57"
                    height="130.96"
                    transform="translate(355.95 342.13) rotate(90)"
                    fill="#e1e1e1"
                />
                <path
                    id="Path_168"
                    d="M293.82,323.28a2,2,0,1,0,0-3.93h-6.7a2,2,0,0,0,0,3.93Z"
                    fill="#e1e1e1"
                />
                <path
                    id="Path_169"
                    d="M293.82,322.59a2,2,0,1,0,0-3.93h-6.7a2,2,0,0,0,0,3.93Z"
                    fill="#f4f5f6"
                />
                <path
                    id="Path_170"
                    d="M293.82,365a2,2,0,1,0,0-3.93h-6.7a2,2,0,0,0,0,3.93Z"
                    fill="#e1e1e1"
                />
                <path
                    id="Path_171"
                    d="M293.82,364.32a2,2,0,1,0,0-3.93h-6.7a2,2,0,0,0,0,3.93Z"
                    fill="#f4f5f6"
                />
                <path
                    id="Path_172"
                    d="M222.71,292.87v94.41H358.24V292.87Zm131,89.83H227.28V342.49H353.66Zm0-44.79H227.28V297.7H353.66Z"
                    fill="#f4f5f6"
                />
                <rect
                    id="Rectangle_111"
                    width="130.96"
                    height="45.2"
                    transform="translate(360.52 294.89)"
                    fill="#e3e2e3"
                />
                <rect
                    id="Rectangle_112"
                    width="130.96"
                    height="45.2"
                    transform="translate(360.52 340.1)"
                    fill="#e3e2e3"
                />
                <path
                    id="Path_173"
                    d="M443.45,340.1l16.3-45.21H434.67L418.38,340.1Z"
                    fill="#f0f0f0"
                />
                <path id="Path_174" d="M471.88,294.89h-5.51L450.08,340.1h5.51Z" fill="#f0f0f0" />
                <path
                    id="Path_175"
                    d="M427.16,385.3l16.29-45.2H418.38l-16.3,45.2Z"
                    fill="#f0f0f0"
                />
                <path id="Path_176" d="M455.59,340.1h-5.51l-16.29,45.2h5.5Z" fill="#f0f0f0" />
                <rect
                    id="Rectangle_113"
                    width="4.57"
                    height="130.96"
                    transform="translate(491.48 297.28) rotate(90)"
                    fill="#e1e1e1"
                />
                <rect
                    id="Rectangle_114"
                    width="4.57"
                    height="130.96"
                    transform="translate(491.48 342.13) rotate(90)"
                    fill="#e1e1e1"
                />
                <path
                    id="Path_177"
                    d="M429.35,323.28a2,2,0,1,0,0-3.93h-6.7a2,2,0,0,0,0,3.93Z"
                    fill="#e1e1e1"
                />
                <path
                    id="Path_178"
                    d="M429.35,322.59a2,2,0,1,0,0-3.93h-6.7a2,2,0,0,0,0,3.93Z"
                    fill="#f5f5f5"
                />
                <path
                    id="Path_179"
                    d="M429.35,365a2,2,0,1,0,0-3.93h-6.7a2,2,0,0,0,0,3.93Z"
                    fill="#e1e1e1"
                />
                <path
                    id="Path_180"
                    d="M429.35,364.32a2,2,0,1,0,0-3.93h-6.7a2,2,0,0,0,0,3.93Z"
                    fill="#f4f5f6"
                />
                <path
                    id="Path_181"
                    d="M358.24,292.87v94.41H493.77V292.87Zm131,89.83H362.81V342.49H489.19Zm0-44.79H362.81V297.7H489.19Z"
                    fill="#f4f5f6"
                />
                <path id="Path_182" d="M480.66,394.08H100.28l-1.98-6.8H482.64Z" fill="#e1e1e1" />
                <ellipse
                    id="_Path_"
                    cx="129.74"
                    cy="12.56"
                    rx="129.74"
                    ry="12.56"
                    transform="translate(246.07 414.27)"
                    fill="#f5f5f5"
                />
                <path
                    id="Path_183"
                    d="M160.4,235.6H406.64a11,11,0,0,0,11.09-12.15L404.5,72.18A13.54,13.54,0,0,0,391.28,60H145a11,11,0,0,0-11,12.18l13.23,151.27A13.54,13.54,0,0,0,160.4,235.6Z"
                    fill="#1a2e35"
                />
                <path
                    id="Path_184"
                    d="M160.4,235.6H406.64a11,11,0,0,0,11.09-12.15L404.5,72.18A13.54,13.54,0,0,0,391.28,60H145a11,11,0,0,0-11,12.18l13.23,151.27A13.54,13.54,0,0,0,160.4,235.6Z"
                    fill="#e3e2e3"
                />
                <path id="Path_185" d="M160.66,220.12H400.79L387.47,67.9H147.35Z" fill="#007cff" />
                <path
                    id="Path_186"
                    d="M164,232H410.21a11,11,0,0,0,11.09-12.15L408.06,68.61a13.53,13.53,0,0,0-13.21-12.16H148.61a11,11,0,0,0-11.09,12.16l13.23,151.27A13.54,13.54,0,0,0,164,232Z"
                    fill="#606060"
                />
                <path id="Path_187" d="M160.66,220.12H400.79L387.47,67.9H147.35Z" fill="#f4f5f6" />
                <path
                    id="Path_188"
                    d="M160.66,220.35H400.79L387.47,68.14H147.35Z"
                    fill="#e3e2e3"
                    opacity="0.4"
                />
                <path id="Path_189" d="M388.67,81.85H148.55l-1.2-13.71H387.47Z" fill="#e3e2e3" />
                <path
                    id="Path_190"
                    d="M388.67,81.85H148.55l-1.2-13.71H387.47Z"
                    fill="#e3e2e3"
                    opacity="0.5"
                />
                <path
                    id="Path_191"
                    d="M157.49,73.78,157.6,75h2.15l.08.86h-2.16l.15,1.7h-1.08l-.4-4.66h3.52l.07.87Z"
                    fill="#707171"
                />
                <path
                    id="Path_192"
                    d="M160.45,75.24a2.18,2.18,0,0,1,2.34-2.41,2.68,2.68,0,0,1,2.76,2.41,2.18,2.18,0,0,1-2.34,2.41,2.68,2.68,0,0,1-2.76-2.41Zm4,0a1.59,1.59,0,0,0-1.59-1.49,1.28,1.28,0,0,0-1.32,1.49,1.56,1.56,0,0,0,1.58,1.49,1.28,1.28,0,0,0,1.34-1.49Z"
                    fill="#707171"
                />
                <path
                    id="Path_193"
                    d="M169.54,77.57l-1-1.3h-1l.12,1.3h-1.08l-.41-4.66h2a1.94,1.94,0,0,1,2.17,1.69,1.37,1.37,0,0,1-.83,1.47l1.17,1.5Zm-1.35-3.78h-.88l.15,1.62h.88c.65,0,1-.3.92-.81s-.41-.81-1.07-.81Z"
                    fill="#707171"
                />
                <path
                    id="Path_194"
                    d="M175.7,77.57l-.25-2.8-1.17,2.31h-.49l-1.56-2.25.24,2.74h-1l-.41-4.66h.89l2,2.9,1.46-2.9h.89l.42,4.66Z"
                    fill="#707171"
                />
                <path
                    id="Path_195"
                    d="M164.28,92.71l.09,1h1.8l.06.72h-1.8l.13,1.42h-.9L163.32,92h2.94l.06.72Z"
                    fill="#707171"
                />
                <path
                    id="Path_196"
                    d="M166.64,92a.45.45,0,0,1,.5-.48.55.55,0,0,1,.58.47.46.46,0,0,1-.5.5A.55.55,0,0,1,166.64,92Zm.18.9h.87l.26,3h-.86Z"
                    fill="#707171"
                />
                <path
                    id="Path_197"
                    d="M170.34,92.85l.07.8h-.19c-.48,0-.77.27-.72.84l.12,1.41h-.86l-.27-3h.83v.4A1.08,1.08,0,0,1,170.34,92.85Z"
                    fill="#707171"
                />
                <path
                    id="Path_198"
                    d="M170.82,95.6l.24-.62a2.18,2.18,0,0,0,1.06.29c.4,0,.54-.11.52-.28,0-.48-1.8,0-1.9-1.18-.05-.56.42-1,1.3-1a2.55,2.55,0,0,1,1.17.26l-.24.61a1.9,1.9,0,0,0-.88-.22c-.39,0-.54.12-.53.28.05.51,1.81,0,1.91,1.19.05.56-.43,1-1.33,1a2.76,2.76,0,0,1-1.32-.33Z"
                    fill="#707171"
                />
                <path
                    id="Path_199"
                    d="M176,95.73a1.13,1.13,0,0,1-.69.19,1.09,1.09,0,0,1-1.21-1.07l-.1-1.23h-.46l-.06-.66h.46l-.06-.73h.86l.07.73h.74l.06.66h-.75l.11,1.22a.38.38,0,0,0,.41.39.58.58,0,0,0,.34-.11Z"
                    fill="#707171"
                />
                <path
                    id="Path_200"
                    d="M181.35,92l.34,3.89H181l-2.15-2.36.21,2.36h-.89L177.78,92h.75l2.13,2.36-.2-2.36Z"
                    fill="#707171"
                />
                <path
                    id="Path_201"
                    d="M185,94.17l.15,1.71h-.82V95.5a.87.87,0,0,1-.87.42,1.08,1.08,0,0,1-1.2-.9c0-.53.3-.9,1.21-.9h.69c0-.37-.28-.58-.74-.58a1.34,1.34,0,0,0-.84.27l-.36-.6a2.15,2.15,0,0,1,1.25-.36A1.37,1.37,0,0,1,185,94.17Zm-.8.76v-.3h-.59c-.41,0-.52.15-.51.35s.22.36.52.36a.58.58,0,0,0,.62-.41Z"
                    fill="#707171"
                />
                <path
                    id="Path_202"
                    d="M190.86,94.17l.15,1.71h-.86L190,94.3a.614.614,0,0,0-.63-.7c-.35,0-.64.25-.59.78l.13,1.5h-.87l-.14-1.58c0-.48-.26-.7-.62-.7s-.64.25-.59.78l.13,1.5H186l-.26-3h.83v.35a1.1,1.1,0,0,1,.91-.39,1.22,1.22,0,0,1,1,.49,1.21,1.21,0,0,1,1-.49,1.28,1.28,0,0,1,1.38,1.33Z"
                    fill="#707171"
                />
                <path
                    id="Path_203"
                    d="M194.62,94.63h-2.26a.88.88,0,0,0,.9.6,1,1,0,0,0,.73-.29l.5.5a1.45,1.45,0,0,1-1.19.48,1.72,1.72,0,0,1-1.84-1.54,1.37,1.37,0,0,1,1.46-1.53,1.64,1.64,0,0,1,1.69,1.55,1.69,1.69,0,0,1,.01.23Zm-2.32-.51h1.47a.78.78,0,0,0-.78-.62.64.64,0,0,0-.69.62Z"
                    fill="#707171"
                />
                <path
                    id="Path_204"
                    d="M173.09,169.31l.07.72h-3l-.34-3.88h2.93l.07.72h-2.05l.08.84h1.8l.06.7h-1.8l.08.9Z"
                    fill="#707171"
                />
                <path
                    id="Path_205"
                    d="M178.7,168.33l.15,1.7H178l-.14-1.57a.623.623,0,0,0-.63-.71c-.35,0-.64.25-.59.79l.13,1.49h-.87l-.13-1.57c0-.48-.27-.71-.63-.71s-.63.25-.59.79l.13,1.49h-.86l-.26-3h.82v.34a1.13,1.13,0,0,1,.91-.39,1.25,1.25,0,0,1,1,.49,1.26,1.26,0,0,1,1-.49,1.28,1.28,0,0,1,1.41,1.35Z"
                    fill="#707171"
                />
                <path
                    id="Path_206"
                    d="M182.12,168.33l.15,1.7h-.81v-.37a.91.91,0,0,1-.88.42,1.06,1.06,0,0,1-1.19-.91c-.05-.52.29-.89,1.2-.89h.69c0-.37-.27-.59-.74-.59a1.35,1.35,0,0,0-.84.28l-.36-.6a2.11,2.11,0,0,1,1.26-.37,1.37,1.37,0,0,1,1.52,1.33Zm-.8.76v-.3h-.59c-.41,0-.52.15-.5.35s.21.36.52.36A.59.59,0,0,0,181.32,169.09Z"
                    fill="#707171"
                />
                <path
                    id="Path_207"
                    d="M182.6,166.15a.45.45,0,0,1,.5-.48.55.55,0,0,1,.58.46.45.45,0,0,1-.5.5A.55.55,0,0,1,182.6,166.15Zm.19.9h.86l.26,3h-.86Z"
                    fill="#707171"
                />
                <path id="Path_208" d="M184.36,165.92h.86l.36,4.11h-.86Z" fill="#707171" />
                <path
                    id="Path_209"
                    d="M168.11,117.54h-1.8l-.27.83h-.92l1.39-3.88h.89l2.07,3.88h-.94Zm-.34-.68-.75-1.49-.48,1.49Z"
                    fill="#707171"
                />
                <path
                    id="Path_210"
                    d="M172.47,114.25l.36,4.12H172v-.34a1,1,0,0,1-.9.38,1.61,1.61,0,0,1-1.63-1.53,1.32,1.32,0,0,1,1.36-1.54,1.27,1.27,0,0,1,.93.36l-.13-1.45Zm-.62,2.63a.85.85,0,0,0-.83-.83.69.69,0,0,0-.7.83.85.85,0,0,0,.84.82.68.68,0,0,0,.69-.82Z"
                    fill="#707171"
                />
                <path
                    id="Path_211"
                    d="M176.31,114.25l.36,4.12h-.83v-.34a1,1,0,0,1-.9.38,1.61,1.61,0,0,1-1.63-1.53,1.32,1.32,0,0,1,1.36-1.54,1.27,1.27,0,0,1,.93.36l-.13-1.45Zm-.62,2.63a.85.85,0,0,0-.84-.83.68.68,0,0,0-.69.83.85.85,0,0,0,.84.82.68.68,0,0,0,.69-.82Z"
                    fill="#707171"
                />
                <path
                    id="Path_212"
                    d="M179.05,115.34l.07.8h-.19c-.48,0-.77.26-.72.83l.12,1.41h-.87l-.26-3h.8v.39A1.11,1.11,0,0,1,179.05,115.34Z"
                    fill="#707171"
                />
                <path
                    id="Path_213"
                    d="M182.6,117.12h-2.26a.9.9,0,0,0,.91.6.92.92,0,0,0,.73-.29l.5.5a1.42,1.42,0,0,1-1.19.48,1.72,1.72,0,0,1-1.84-1.53,1.37,1.37,0,0,1,1.46-1.54,1.64,1.64,0,0,1,1.69,1.55A1.769,1.769,0,0,1,182.6,117.12Zm-2.31-.5h1.47A.79.79,0,0,0,181,116a.63.63,0,0,0-.71.62Z"
                    fill="#707171"
                />
                <path
                    id="Path_214"
                    d="M183,118.09l.23-.62a2.25,2.25,0,0,0,1.07.29c.4,0,.54-.11.52-.27,0-.49-1.8,0-1.9-1.18,0-.57.42-1,1.29-1a2.52,2.52,0,0,1,1.17.26l-.23.62a1.79,1.79,0,0,0-.88-.22c-.39,0-.54.12-.53.27,0,.51,1.81,0,1.91,1.2.05.55-.44.94-1.33.94a2.65,2.65,0,0,1-1.32-.29Z"
                    fill="#707171"
                />
                <path
                    id="Path_215"
                    d="M185.93,118.09l.24-.62a2.21,2.21,0,0,0,1.06.29c.4,0,.54-.11.53-.27,0-.49-1.81,0-1.91-1.18,0-.57.43-1,1.3-1a2.55,2.55,0,0,1,1.17.26l-.23.62a1.79,1.79,0,0,0-.88-.22c-.39,0-.55.12-.54.27.05.51,1.81,0,1.91,1.2,0,.55-.43.94-1.32.94a2.69,2.69,0,0,1-1.33-.29Z"
                    fill="#707171"
                />
                <path
                    id="Path_216"
                    d="M175.44,192c.07.86-.53,1.4-1.57,1.4h-.78l.09,1.07h-.9l-.34-3.88h1.69a1.61,1.61,0,0,1,1.81,1.41Zm-.91,0c0-.43-.34-.68-.89-.68h-.73l.11,1.35h.74C174.31,192.66,174.56,192.41,174.53,192Z"
                    fill="#707171"
                />
                <path
                    id="Path_217"
                    d="M178.69,192.76l.15,1.7H178v-.37a.9.9,0,0,1-.87.41,1.07,1.07,0,0,1-1.2-.9c-.05-.53.29-.89,1.2-.89h.69c0-.38-.27-.59-.74-.59a1.28,1.28,0,0,0-.83.28l-.37-.61a2.18,2.18,0,0,1,1.26-.36,1.36,1.36,0,0,1,1.55,1.33Zm-.8.76v-.31h-.6c-.4,0-.52.15-.5.35s.22.36.52.36a.56.56,0,0,0,.58-.4Z"
                    fill="#707171"
                />
                <path
                    id="Path_218"
                    d="M179.31,194.18l.23-.62a2.22,2.22,0,0,0,1.07.29c.4,0,.54-.11.52-.27,0-.49-1.8,0-1.91-1.18-.05-.57.43-1,1.3-1a2.52,2.52,0,0,1,1.17.26l-.23.62a1.79,1.79,0,0,0-.88-.22c-.39,0-.54.12-.53.27,0,.51,1.8,0,1.91,1.2,0,.55-.44.94-1.33.94a2.62,2.62,0,0,1-1.32-.29Z"
                    fill="#707171"
                />
                <path
                    id="Path_219"
                    d="M182.25,194.18l.24-.62a2.18,2.18,0,0,0,1.06.29c.4,0,.54-.11.53-.27-.05-.49-1.81,0-1.91-1.18,0-.57.43-1,1.3-1a2.55,2.55,0,0,1,1.17.26l-.24.62a1.74,1.74,0,0,0-.88-.22c-.38,0-.54.12-.53.27,0,.51,1.81,0,1.91,1.2,0,.55-.43.94-1.32.94a2.69,2.69,0,0,1-1.33-.29Z"
                    fill="#707171"
                />
                <path
                    id="Path_220"
                    d="M190,191.48l-.82,3h-.83l-.84-1.85-.53,1.85h-.83l-1.34-3h.82l.88,2,.55-2h.74l.88,2,.56-2Z"
                    fill="#707171"
                />
                <path
                    id="Path_221"
                    d="M190.22,193a1.39,1.39,0,0,1,1.51-1.54A1.7,1.7,0,0,1,193.5,193a1.38,1.38,0,0,1-1.5,1.53A1.7,1.7,0,0,1,190.22,193Zm2.4,0a.83.83,0,0,0-.83-.83.69.69,0,0,0-.7.83.85.85,0,0,0,.84.82.68.68,0,0,0,.69-.82Z"
                    fill="#707171"
                />
                <path
                    id="Path_222"
                    d="M195.79,191.43l.07.8h-.19c-.48,0-.77.26-.72.83l.12,1.41h-.87l-.26-3h.83v.39a1.11,1.11,0,0,1,1.02-.43Z"
                    fill="#707171"
                />
                <path
                    id="Path_223"
                    d="M199.23,190.34l.36,4.12h-.83v-.34a1.06,1.06,0,0,1-.9.38,1.61,1.61,0,0,1-1.66-1.5,1.32,1.32,0,0,1,1.36-1.54,1.27,1.27,0,0,1,.93.36l-.13-1.45Zm-.62,2.63a.85.85,0,0,0-.84-.83.68.68,0,0,0-.69.83.83.83,0,0,0,.84.82.68.68,0,0,0,.69-.79Z"
                    fill="#707171"
                />
                <path
                    id="Path_224"
                    d="M327.33,209.57h-21.9a8.36,8.36,0,0,1-8.16-7.51h0a6.77,6.77,0,0,1,6.85-7.5H326a8.35,8.35,0,0,1,8.16,7.5h0a6.76,6.76,0,0,1-6.83,7.51Z"
                    fill="#e3e2e3"
                />
                <path
                    id="Path_225"
                    d="M308.72,203.68,309,203a2.41,2.41,0,0,0,1.31.4c.54,0,.74-.17.72-.41-.07-.73-2.28-.23-2.41-1.67-.06-.66.43-1.21,1.53-1.21a2.85,2.85,0,0,1,1.38.34l-.22.69a2.27,2.27,0,0,0-1.1-.31c-.53,0-.72.2-.7.44.06.72,2.28.23,2.4,1.65.06.65-.43,1.21-1.54,1.21a3,3,0,0,1-1.65-.45Z"
                    fill="#707171"
                />
                <path
                    id="Path_226"
                    d="M315,202.36l.15,1.7h-.81v-.37a.9.9,0,0,1-.88.41,1.06,1.06,0,0,1-1.19-.9c0-.53.29-.89,1.2-.89h.69c0-.38-.27-.59-.74-.59a1.34,1.34,0,0,0-.84.27l-.36-.6a2.18,2.18,0,0,1,1.26-.36,1.36,1.36,0,0,1,1.52,1.33Zm-.8.76v-.31h-.59c-.41,0-.52.15-.5.35s.21.36.52.36a.57.57,0,0,0,.6-.4Z"
                    fill="#707171"
                />
                <path
                    id="Path_227"
                    d="M318.61,201.07l-1,3h-.9l-1.51-3h.89l1,2,.67-2Z"
                    fill="#707171"
                />
                <path
                    id="Path_228"
                    d="M321.94,202.81h-2.26a.88.88,0,0,0,.9.6.92.92,0,0,0,.73-.29l.5.5a1.42,1.42,0,0,1-1.19.48,1.72,1.72,0,0,1-1.84-1.53,1.38,1.38,0,0,1,1.46-1.54,1.64,1.64,0,0,1,1.69,1.55,1.693,1.693,0,0,1,.01.23Zm-2.32-.5h1.47a.79.79,0,0,0-.79-.63.64.64,0,0,0-.68.63Z"
                    fill="#707171"
                />
                <path
                    id="Path_229"
                    d="M369,209.57H347.08a8.36,8.36,0,0,1-8.16-7.51h0a6.77,6.77,0,0,1,6.85-7.5h21.89a8.35,8.35,0,0,1,8.16,7.5h0a6.76,6.76,0,0,1-6.82,7.51Z"
                    fill="#e3e2e3"
                />
                <path
                    id="Path_230"
                    d="M344.37,202.12a1.8,1.8,0,0,1,1.93-2,2.19,2.19,0,0,1,1.66.69l-.53.53a1.39,1.39,0,0,0-1-.46,1.08,1.08,0,0,0-1.13,1.25,1.32,1.32,0,0,0,1.35,1.24,1.15,1.15,0,0,0,.94-.46l.62.53a1.83,1.83,0,0,1-1.54.7A2.21,2.21,0,0,1,344.37,202.12Z"
                    fill="#707171"
                />
                <path
                    id="Path_231"
                    d="M348.33,202.57a1.39,1.39,0,0,1,1.51-1.54,1.7,1.7,0,0,1,1.77,1.54,1.38,1.38,0,0,1-1.5,1.53A1.7,1.7,0,0,1,348.33,202.57Zm2.4,0a.83.83,0,0,0-.83-.83.68.68,0,0,0-.69.83.83.83,0,0,0,.83.82.68.68,0,0,0,.69-.82Z"
                    fill="#707171"
                />
                <path
                    id="Path_232"
                    d="M355.22,202.35l.15,1.71h-.87l-.14-1.58a.621.621,0,0,0-.66-.7.644.644,0,0,0-.65.8l.13,1.48h-.87l-.26-3h.83v.35a1.21,1.21,0,0,1,.95-.39,1.3,1.3,0,0,1,1.39,1.33Z"
                    fill="#707171"
                />
                <path
                    id="Path_233"
                    d="M358.06,203.92a1.22,1.22,0,0,1-.69.18,1.09,1.09,0,0,1-1.21-1.07l-.11-1.22h-.46v-.67H356l-.07-.73h.87l.06.73h.75l.05.67h-.74L357,203a.38.38,0,0,0,.4.4.49.49,0,0,0,.34-.12Z"
                    fill="#707171"
                />
                <path
                    id="Path_234"
                    d="M358.11,200.18a.45.45,0,0,1,.5-.49.54.54,0,0,1,.58.47.45.45,0,0,1-.5.5.55.55,0,0,1-.58-.48Zm.19.89h.86l.26,3h-.86Z"
                    fill="#707171"
                />
                <path
                    id="Path_235"
                    d="M363.13,202.35l.15,1.71h-.86l-.14-1.58a.628.628,0,0,0-.67-.7c-.38,0-.69.25-.64.8l.12,1.48h-.86l-.26-3h.82v.35a1.23,1.23,0,0,1,1-.39,1.29,1.29,0,0,1,1.34,1.33Z"
                    fill="#707171"
                />
                <path
                    id="Path_236"
                    d="M366.81,201.07l.26,3h-.82v-.36a1.12,1.12,0,0,1-.89.4,1.32,1.32,0,0,1-1.4-1.34l-.15-1.69h.87l.14,1.56a.628.628,0,0,0,.66.72c.38,0,.67-.25.62-.8l-.13-1.48Z"
                    fill="#707171"
                />
                <path
                    id="Path_237"
                    d="M370.67,202.81h-2.26a.89.89,0,0,0,.91.6.91.91,0,0,0,.72-.29l.51.5a1.42,1.42,0,0,1-1.19.48,1.72,1.72,0,0,1-1.84-1.53A1.37,1.37,0,0,1,369,201a1.65,1.65,0,0,1,1.69,1.55Zm-2.31-.5h1.47a.8.8,0,0,0-.79-.63.63.63,0,0,0-.68.63Z"
                    fill="#707171"
                />
                <path
                    id="Path_238"
                    d="M281.66,168.86c-.09-1,.75-1.66,2-1.66a2.39,2.39,0,0,1,1.65.57l-.54.44a1.47,1.47,0,0,0-1-.38c-.73,0-1.21.43-1.15,1a1.37,1.37,0,0,0,2.27.65l.62.44a2,2,0,0,1-1.55.58,2.06,2.06,0,0,1-2.3-1.64Z"
                    fill="#707171"
                />
                <path
                    id="Path_239"
                    d="M285.61,169.24c-.07-.75.58-1.28,1.53-1.28a1.6,1.6,0,0,1,1.75,1.28c.07.74-.58,1.27-1.53,1.27a1.59,1.59,0,0,1-1.75-1.27Zm2.4,0a.76.76,0,0,0-.82-.69.63.63,0,0,0-.7.69.76.76,0,0,0,.82.68c.44,0,.74-.26.69-.68Z"
                    fill="#707171"
                />
                <path
                    id="Path_240"
                    d="M292.5,169.06l.13,1.41h-.87l-.11-1.3a.6.6,0,0,0-.66-.59c-.42,0-.7.21-.66.67l.11,1.22h-.87l-.21-2.47h.82v.29a1.34,1.34,0,0,1,1-.33,1.19,1.19,0,0,1,1.32,1.1Z"
                    fill="#707171"
                />
                <path
                    id="Path_241"
                    d="M295.32,170.35a1.31,1.31,0,0,1-.69.16c-.71,0-1.14-.3-1.19-.89l-.09-1h-.46l-.05-.56h.46l-.05-.6h.86l.06.6h.74v.56h-.75l.09,1a.35.35,0,0,0,.4.33.62.62,0,0,0,.34-.09Z"
                    fill="#707171"
                />
                <path
                    id="Path_242"
                    d="M298.34,169.06l.12,1.41h-.81v-.3a1,1,0,0,1-.88.34c-.7,0-1.14-.32-1.18-.75s.31-.74,1.22-.74h.69c0-.31-.27-.49-.73-.49a1.54,1.54,0,0,0-.84.23l-.36-.5a2.55,2.55,0,0,1,1.26-.3C297.71,168,298.27,168.31,298.34,169.06Zm-.81.63v-.25h-.59c-.41,0-.52.12-.51.29s.21.3.52.3a.59.59,0,0,0,.58-.34Z"
                    fill="#707171"
                />
                <path
                    id="Path_243"
                    d="M298.94,169.24c-.06-.75.58-1.28,1.56-1.28a1.59,1.59,0,0,1,1.39.63l-.65.3a.87.87,0,0,0-.7-.34c-.44,0-.76.25-.72.69a.77.77,0,0,0,.84.68.69.69,0,0,0,.64-.34l.7.31a1.32,1.32,0,0,1-1.28.62,1.62,1.62,0,0,1-1.78-1.27Z"
                    fill="#707171"
                />
                <path
                    id="Path_244"
                    d="M304.51,170.35a1.31,1.31,0,0,1-.69.16c-.71,0-1.14-.3-1.19-.89l-.09-1h-.46l-.05-.56h.46l-.05-.6h.86l.06.6h.74v.56h-.75l.09,1a.35.35,0,0,0,.4.33.62.62,0,0,0,.34-.09Z"
                    fill="#707171"
                />
                <path
                    id="Path_245"
                    d="M309.92,167.25l.28,3.22h-.74l-2.1-1.95.17,1.95h-.89l-.28-3.22h.74l2.1,2-.17-2Z"
                    fill="#707171"
                />
                <path
                    id="Path_246"
                    d="M313.85,168l.22,2.47h-.82v-.29a1.22,1.22,0,0,1-.9.33A1.2,1.2,0,0,1,311,169.4l-.12-1.4h.86l.11,1.29a.59.59,0,0,0,.66.6.56.56,0,0,0,.63-.67L313,168Z"
                    fill="#707171"
                />
                <path
                    id="Path_247"
                    d="M319.79,169.06l.13,1.41h-.87l-.11-1.3a.58.58,0,0,0-.62-.59.55.55,0,0,0-.61.65l.11,1.24H317l-.12-1.3a.56.56,0,0,0-.61-.59.54.54,0,0,0-.6.65l.11,1.24h-.87l-.22-2.47h.83v.28a1.46,1.46,0,0,1,1.93.09,1.33,1.33,0,0,1,1-.41,1.17,1.17,0,0,1,1.34,1.1Z"
                    fill="#707171"
                />
                <path
                    id="Path_248"
                    d="M323.83,169.24c.07.77-.54,1.27-1.37,1.27a1.39,1.39,0,0,1-1-.32v.28h-.82l-.3-3.41h.86l.11,1.2a1.19,1.19,0,0,1,.87-.3,1.49,1.49,0,0,1,1.65,1.28Zm-.87,0a.77.77,0,0,0-.82-.69.63.63,0,0,0-.71.69.77.77,0,0,0,.83.68.63.63,0,0,0,.74-.68Z"
                    fill="#707171"
                />
                <path
                    id="Path_249"
                    d="M327.33,169.44h-2.26a.88.88,0,0,0,.9.5,1.05,1.05,0,0,0,.73-.24l.5.41a1.959,1.959,0,0,1-3.02-.87c-.07-.73.58-1.28,1.49-1.28a1.52,1.52,0,0,1,1.66,1.28,1.339,1.339,0,0,1,0,.2ZM325,169h1.47a.77.77,0,0,0-.78-.52A.681.681,0,0,0,325,169Z"
                    fill="#707171"
                />
                <path
                    id="Path_250"
                    d="M329.65,168l.06.66h-.2c-.48,0-.77.22-.73.7l.1,1.16H328l-.2-2.52h.82v.32a1.26,1.26,0,0,1,1.03-.32Z"
                    fill="#707171"
                />
                <path
                    id="Path_251"
                    d="M167.4,142.19l.26-.56A2.92,2.92,0,0,0,169,142c.54,0,.74-.15.72-.34-.05-.61-2.27-.19-2.38-1.39-.05-.55.45-1,1.55-1a3.21,3.21,0,0,1,1.38.28l-.23.57a2.73,2.73,0,0,0-1.1-.25c-.54,0-.73.16-.71.36.05.6,2.28.19,2.38,1.37,0,.54-.45,1-1.56,1a3.43,3.43,0,0,1-1.65-.41Z"
                    fill="#707171"
                />
                <path
                    id="Path_252"
                    d="M173.13,142.39a1.41,1.41,0,0,1-.69.15c-.71,0-1.14-.3-1.19-.89l-.09-1h-.46v-.56h.46l-.05-.6h.86l.06.6h.74l.05.56H172l.09,1a.35.35,0,0,0,.4.33.62.62,0,0,0,.34-.09Z"
                    fill="#707171"
                />
                <path
                    id="Path_253"
                    d="M176.15,141.09l.12,1.42h-.81v-.31a1,1,0,0,1-.88.34c-.7,0-1.14-.32-1.18-.75s.31-.74,1.22-.74h.69c0-.31-.27-.49-.73-.49a1.54,1.54,0,0,0-.84.23l-.36-.5a2.55,2.55,0,0,1,1.26-.3C175.52,140,176.08,140.34,176.15,141.09Zm-.81.63v-.25h-.59c-.41,0-.52.12-.51.29s.21.3.52.3a.59.59,0,0,0,.58-.34Z"
                    fill="#707171"
                />
                <path
                    id="Path_254"
                    d="M179,142.39a1.45,1.45,0,0,1-.7.15c-.7,0-1.14-.3-1.19-.89l-.09-1h-.46l-.05-.56h.46l-.05-.6h.87l.05.6h.74v.56h-.74l.09,1c0,.22.16.33.4.33a.64.64,0,0,0,.34-.09Z"
                    fill="#707171"
                />
                <path
                    id="Path_255"
                    d="M182.19,141.47h-2.26a.88.88,0,0,0,.9.5,1,1,0,0,0,.72-.24l.5.41a1.62,1.62,0,0,1-1.2.4c-1,0-1.75-.54-1.81-1.27s.57-1.28,1.48-1.28a1.52,1.52,0,0,1,1.67,1.29Zm-2.31-.42h1.47a.76.76,0,0,0-.78-.51C180.18,140.54,179.91,140.73,179.88,141.05Z"
                    fill="#707171"
                />
                <path id="Path_256" d="M275.17,92h.9l.28,3.15h1.95l.06.74h-2.85Z" fill="#707171" />
                <path
                    id="Path_257"
                    d="M281.33,94.17l.15,1.71h-.81V95.5a.89.89,0,0,1-.88.42,1.06,1.06,0,0,1-1.19-.9c-.05-.53.29-.9,1.2-.9h.69c0-.37-.27-.58-.74-.58a1.34,1.34,0,0,0-.84.27l-.36-.6a2.18,2.18,0,0,1,1.26-.36,1.36,1.36,0,0,1,1.52,1.32Zm-.8.76v-.3h-.59c-.41,0-.52.15-.5.35s.21.36.52.36a.57.57,0,0,0,.57-.41Z"
                    fill="#707171"
                />
                <path
                    id="Path_258"
                    d="M282,95.6l.23-.62a2.21,2.21,0,0,0,1.06.29c.4,0,.54-.11.53-.28,0-.48-1.8,0-1.91-1.18-.05-.56.43-1,1.3-1a2.55,2.55,0,0,1,1.17.26l-.23.61a1.9,1.9,0,0,0-.88-.22c-.39,0-.55.12-.53.28,0,.51,1.8,0,1.9,1.19.05.56-.43,1-1.32,1A2.73,2.73,0,0,1,282,95.6Z"
                    fill="#707171"
                />
                <path
                    id="Path_259"
                    d="M287.12,95.73a1.17,1.17,0,0,1-.69.19,1.08,1.08,0,0,1-1.21-1.07l-.11-1.23h-.46l-.06-.66h.46l-.06-.73h.87l.06.73h.74l.06.66H286l.1,1.22a.39.39,0,0,0,.41.39.55.55,0,0,0,.34-.11Z"
                    fill="#707171"
                />
                <path
                    id="Path_260"
                    d="M292.47,92l.34,3.19h-.74l-2.14-1.93.2,1.93h-.88L288.91,92h.74l2.14,1.94L291.58,92Z"
                    fill="#707171"
                />
                <path
                    id="Path_261"
                    d="M296.16,94.17l.15,1.71h-.81V95.5a.89.89,0,0,1-.88.42,1.06,1.06,0,0,1-1.19-.9c0-.53.29-.9,1.2-.9h.69c0-.37-.27-.58-.74-.58a1.36,1.36,0,0,0-.84.27l-.36-.6a2.18,2.18,0,0,1,1.26-.36,1.36,1.36,0,0,1,1.52,1.32Zm-.8.76v-.3h-.59c-.41,0-.52.15-.5.35s.21.36.52.36a.57.57,0,0,0,.57-.41Z"
                    fill="#707171"
                />
                <path
                    id="Path_262"
                    d="M302,94.17l.15,1.71h-.87l-.14-1.58a.614.614,0,0,0-.63-.7c-.35,0-.64.25-.59.78l.13,1.5h-.86L299,94.3c0-.48-.27-.7-.63-.7s-.63.25-.59.78l.13,1.5h-.86l-.26-3h.82v.35a1.11,1.11,0,0,1,.91-.39,1.23,1.23,0,0,1,1,.49,1.2,1.2,0,0,1,1-.49A1.28,1.28,0,0,1,302,94.17Z"
                    fill="#707171"
                />
                <path
                    id="Path_263"
                    d="M305.74,94.63h-2.26a.89.89,0,0,0,.91.6,1,1,0,0,0,.72-.29l.51.5a1.45,1.45,0,0,1-1.19.48,1.72,1.72,0,0,1-1.84-1.54,1.37,1.37,0,0,1,1.46-1.53,1.65,1.65,0,0,1,1.69,1.55Zm-2.31-.51h1.47a.8.8,0,0,0-.79-.62.63.63,0,0,0-.68.62Z"
                    fill="#707171"
                />
                <path id="Path_264" d="M268.48,108.21H164.24l-.79-8.96H267.69Z" fill="#fff" />
                <path id="Path_265" d="M272.54,154.63H168.3l-.79-8.97H271.75Z" fill="#fff" />
                <path id="Path_266" d="M274.91,181.76H170.67l-.78-8.96H274.13Z" fill="#fff" />
                <path id="Path_267" d="M277.08,206.54H172.84l-.79-8.96H276.3Z" fill="#fff" />
                <path id="Path_268" d="M387.01,181.76H282.77l-.79-8.96H386.23Z" fill="#fff" />
                <path id="Path_269" d="M382.51,130.35H166.17l-.78-8.96H381.73Z" fill="#fff" />
                <path id="Path_270" d="M380.57,108.21H276.33l-.78-8.96H379.79Z" fill="#fff" />
                <path id="Path_271" d="M306.52,180.81h-8.03l-.65-7.39h8.04Z" fill="#e1e1e1" />
                <path id="Path_272" d="M304.65,175.86l-2.29,2.85-2.79-2.85Z" fill="#1a2e35" />
                <path
                    id="Path_273"
                    d="M287.52,177.28h-1l.08.95h-.72l-.08-.95h-1l-.06-.67h1l-.08-1h.72l.08,1h1Z"
                    fill="#1a2e35"
                />
                <path
                    id="Path_274"
                    d="M290.87,177.7c.06.65-.39,1.25-1.5,1.25a2.92,2.92,0,0,1-1.52-.41l.29-.69a2.07,2.07,0,0,0,1.14.35c.45,0,.71-.18.69-.5s-.26-.48-.77-.48h-.41l-.06-.59.72-.91H287.8l-.06-.72h2.72l.05.59-.79,1a1.24,1.24,0,0,1,1.15,1.11Z"
                    fill="#1a2e35"
                />
                <path
                    id="Path_275"
                    d="M294.2,178.15l.06.74h-2.93l-.05-.59,1.37-1.41c.32-.33.36-.53.34-.72a.636.636,0,0,0-.66-.48.86.86,0,0,0-.77.39l-.69-.42a1.63,1.63,0,0,1,1.48-.72c.86,0,1.48.44,1.54,1.14a1.42,1.42,0,0,1-.54,1.23l-.82.84Z"
                    fill="#1a2e35"
                />
                <path id="Path_276" d="M364.29,178.93H311.3l-.26-3.07h52.98Z" fill="#606060" />
                <path id="Path_277" d="M226.16,178.93H173.18l-.27-3.07h52.98Z" fill="#606060" />
                <path id="Path_278" d="M195.05,151.68H170.8l-.27-3.07h24.25Z" fill="#606060" />
                <path id="Path_279" d="M190.99,105.26H166.74l-.27-3.07h24.25Z" fill="#606060" />
                <path id="Path_280" d="M303.56,105.26H279.31l-.27-3.07h24.25Z" fill="#606060" />
                <path id="Path_281" d="M359.78,127.4H168.67l-.27-3.07H359.51Z" fill="#606060" />
                <path
                    id="Path_282"
                    d="M178.48,202.27l.89.46-.33.64-.9-.51.1.94h-.73l-.08-1-.81.52-.42-.64.8-.46-.88-.46.31-.64.9.51-.09-1H178l.07,1,.81-.51.44.64Z"
                    fill="#1a2e35"
                />
                <path
                    id="Path_283"
                    d="M184.05,202.27l.88.46-.32.64-.9-.51.1.94h-.73l-.08-1-.81.52-.43-.64.81-.46-.89-.46.32-.64.9.51-.09-1h.73l.07,1,.81-.51.43.64Z"
                    fill="#1a2e35"
                />
                <path
                    id="Path_284"
                    d="M189.61,202.27l.89.46-.32.64-.9-.51.1.94h-.74l-.07-1-.81.52-.43-.64.81-.46-.89-.46.32-.64.9.51-.09-1h.73l.06,1,.81-.51.44.64Z"
                    fill="#1a2e35"
                />
                <path
                    id="Path_285"
                    d="M195.18,202.27l.89.46-.33.64-.9-.51.1.94h-.73l-.07-1-.81.52-.43-.64.81-.46-.89-.46.32-.64.89.51-.09-1h.73l.07,1,.81-.51.44.64Z"
                    fill="#1a2e35"
                />
                <path
                    id="Path_286"
                    d="M200.75,202.27l.89.46-.33.64-.9-.51.1.94h-.73l-.08-1-.81.52-.42-.64.8-.46-.88-.46.31-.64.9.51-.09-1h.73l.07,1,.81-.51.44.64Z"
                    fill="#1a2e35"
                />
                <path
                    id="Path_287"
                    d="M206.32,202.27l.88.46-.32.64-.9-.51.1.94h-.73l-.08-1-.81.52-.43-.64.81-.46-.89-.46.32-.64.9.51-.09-1h.73l.07,1,.81-.51.43.64Z"
                    fill="#1a2e35"
                />
                <path
                    id="Path_288"
                    d="M211.88,202.27l.89.46-.32.64-.9-.51.1.94h-.73l-.08-1-.81.52-.43-.64.81-.46-.89-.46.32-.64.9.51-.09-1h.73l.06,1,.81-.51.44.64Z"
                    fill="#1a2e35"
                />
                <path
                    id="Path_289"
                    d="M160.66,220.12H400.79L387.47,67.9H147.35Z"
                    fill="#fff"
                    opacity="0.1"
                />
                <path
                    id="Path_290"
                    d="M199.16,56.53l46.9,175.43H230.28L183.9,56.53Z"
                    fill="#fff"
                    opacity="0.1"
                />
                <path
                    id="Path_291"
                    d="M174.81,56.53l46.9,175.43H190.42L144.03,56.53Z"
                    fill="#fff"
                    opacity="0.1"
                />
                <rect
                    id="Rectangle_115"
                    width="6.84"
                    height="1.42"
                    transform="translate(167.357 51.835) rotate(-0.48)"
                    fill="#e3e2e3"
                />
                <rect
                    id="Rectangle_116"
                    width="19.83"
                    height="1.42"
                    transform="translate(180.246 51.727) rotate(-0.48)"
                    fill="#e3e2e3"
                />
                <path
                    id="Path_292"
                    d="M412.31,147.52a6.6,6.6,0,0,1-6.68,7.31,8.13,8.13,0,0,1-7.95-7.31,6.59,6.59,0,0,1,6.67-7.32,8.16,8.16,0,0,1,7.96,7.32Z"
                    opacity="0.5"
                />
                <path
                    id="Path_293"
                    d="M152,173.43h0a1.57,1.57,0,0,1-1.53-1.41l-4.86-55.55a1.27,1.27,0,0,1,1.28-1.41h0a1.56,1.56,0,0,1,1.53,1.41L153.25,172a1.26,1.26,0,0,1-1.25,1.43Z"
                    opacity="0.5"
                />
                <path
                    id="Path_294"
                    d="M368.78,259.65h30.41c1,0,1.44-.8,1-1.79l-7.63-17.16a3.14,3.14,0,0,0-2.59-1.79H359.56c-1,0-1.44.8-1,1.79l7.62,17.16a3.15,3.15,0,0,0,2.6,1.79Z"
                    fill="#007cff"
                />
                <path
                    id="Path_295"
                    d="M368.78,259.65h30.41c1,0,1.44-.8,1-1.79l-7.63-17.16a3.14,3.14,0,0,0-2.59-1.79H359.56c-1,0-1.44.8-1,1.79l7.62,17.16a3.15,3.15,0,0,0,2.6,1.79Z"
                    fill="#606060"
                />
                <path
                    id="Path_296"
                    d="M358.25,238.91h1.31c-1,0-1.44.8-1,1.79l7.63,17.16a3.13,3.13,0,0,0,2.58,1.79h-1.31a3.14,3.14,0,0,1-2.59-1.79l-7.63-17.16C356.81,239.71,357.26,238.91,358.25,238.91Z"
                    fill="#007cff"
                />
                <path
                    id="Path_297"
                    d="M358.25,238.91h1.31c-1,0-1.44.8-1,1.79l7.63,17.16a3.13,3.13,0,0,0,2.58,1.79h-1.31a3.14,3.14,0,0,1-2.59-1.79l-7.63-17.16C356.81,239.71,357.26,238.91,358.25,238.91Z"
                    fill="#e3e2e3"
                />
                <path id="Path_298" d="M369.35,257.68h27.52l-7.47-16.8H361.88Z" fill="#1a2e35" />
                <path id="Path_299" d="M369.35,257.68h27.52l-7.47-16.8H361.88Z" fill="#f4f5f6" />
                <path
                    id="Path_300"
                    d="M356.87,401.32,355,410.1l-9.53-1.25,1.38-9.74Z"
                    fill="#e3e2e3"
                />
                <path
                    id="Path_301"
                    d="M340.62,408.84c-1.24-.12-2.5-.47-2.86-1a.88.88,0,0,1,.12-1,1.23,1.23,0,0,1,.8-.49c1.71-.29,5,2,5.15,2.15a.24.24,0,0,1,.09.26.25.25,0,0,1-.21.17,16.786,16.786,0,0,1-3.09-.09Zm-1.4-2.1a1.7,1.7,0,0,0-.45,0,.64.64,0,0,0-.5.28c-.21.28-.16.43-.1.52.4.69,2.92,1,4.8.91a11.051,11.051,0,0,0-3.75-1.71Z"
                    fill="#e3e2e3"
                />
                <path
                    id="Path_302"
                    d="M343.67,408.85a.14.14,0,0,1-.09,0c-1.15-.65-3.34-3.06-3-4.16.05-.27.28-.58.94-.58a1.81,1.81,0,0,1,1.28.53c1.17,1.18,1.17,3.9,1.17,4a.24.24,0,0,1-.12.2A.2.2,0,0,1,343.67,408.85Zm-2-4.29h-.14c-.43,0-.47.16-.5.22-.12.67,1.28,2.52,2.45,3.38a5.63,5.63,0,0,0-1-3.2,1.31,1.31,0,0,0-.79-.38Z"
                    fill="#e3e2e3"
                />
                <path
                    id="Path_303"
                    d="M344.87,407.56l10.91,1.07a.89.89,0,0,1,.8.77l1.1,9.08a1.47,1.47,0,0,1-1.27,1.64,1.611,1.611,0,0,1-.31,0c-3.93-.46-7.41-1-12.31-1.49-5.78-.57-4.38.27-11.19-.3-4.1-.35-4.39-5.08-2.63-5.14,8-.22,9.1-2.53,12.45-5.08a3.53,3.53,0,0,1,2.45-.55Z"
                    fill="#606060"
                />
                <path id="Path_304" d="M346.13,404.23l9.95.78.79-3.69-10.02-2.21Z" opacity="0.2" />
                <path
                    id="Path_305"
                    d="M354.2,328.69l-9.4,69.12,14.62,1.15,17.01-68.52Z"
                    fill="#1a2e35"
                />
                <path
                    id="Path_306"
                    d="M354.2,328.69l-9.4,69.12,14.62,1.15,17.01-68.52Z"
                    fill="#606060"
                />
                <path
                    id="Path_307"
                    d="M360.75,397.94l-17.61-1.39a3.82,3.82,0,0,0,.14,4.35l17,1.53S362,401.46,360.75,397.94Z"
                    fill="#606060"
                />
                <path
                    id="Path_308"
                    d="M371,260.39l-3.58-8.65a2.651,2.651,0,0,0-1.28-1l-1.8-.65a2.48,2.48,0,0,1-1-.68l-.83-.89a2.61,2.61,0,0,0-2.95-.62l-.77.34a2.58,2.58,0,0,0-1.51,1.89c-.47,2.55-.84,8.12,3.77,11.88l5.16,2.12Z"
                    fill="#e3e2e3"
                />
                <path
                    id="Path_309"
                    d="M366.21,264.1l6.86,10.31,4.81-5.08-6.87-8.94Z"
                    fill="#e3e2e3"
                />
                <path
                    id="Path_310"
                    d="M407.71,239.57c-.36-.71-5.87,1.35-12.31,4.59s-11.39,6.43-11,7.13,5.86-1.35,12.31-4.59S408.06,240.27,407.71,239.57Z"
                    fill="#606060"
                />
                <path
                    id="Path_311"
                    d="M399.47,242.21l1.74-.77,1.11,2.22c-.52.31-1.07.62-1.65.94Z"
                    fill="#007cff"
                />
                <path
                    id="Path_312"
                    d="M399.47,242.21l1.74-.77,1.11,2.22c-.52.31-1.07.62-1.65.94Z"
                    fill="#fff"
                    opacity="0.5"
                />
                <path
                    id="Path_313"
                    d="M405.73,239.36c.31-1.47-5.51,1.12-5.84,1.29s-.68-.45-.28-.65,7.45-3.57,6.82-.56C406.34,239.88,405.64,239.8,405.73,239.36Z"
                    fill="#007cff"
                />
                <g id="Group_2" opacity="0.2">
                    <path
                        id="Path_314"
                        d="M405.73,239.36c.31-1.47-5.51,1.12-5.84,1.29s-.68-.45-.28-.65,7.45-3.57,6.82-.56C406.34,239.88,405.64,239.8,405.73,239.36Z"
                    />
                </g>
                <path
                    id="Path_315"
                    d="M406,262.81l-5.68-9.65a18.28,18.28,0,0,0-2.71-9.36,22.458,22.458,0,0,0-2.13-1.91,28.538,28.538,0,0,0-4.44,2.77c-2.06,1.58-2.61,2.73-.08,6.57a20.62,20.62,0,0,0,4.58,5L401,267.16Z"
                    fill="#e3e2e3"
                />
                <path
                    id="Path_316"
                    d="M407.31,261.65l-2.46-3.91a11.46,11.46,0,0,0-7.52,5.82l2.08,4.51Z"
                    fill="#606060"
                />
                <path
                    id="Path_317"
                    d="M435.82,240.09c-4.3.29-8-.47-15.37,22.66a30.769,30.769,0,0,1-4.88,8.58l-8.08-11s-5.29.31-8.95,6.78c0,0,6.23,20.89,17.92,19.38s18.22-14.94,20.8-22.95S445.33,239.44,435.82,240.09Z"
                    fill="#1a2e35"
                />
                <path id="Path_318" d="M414.98,229.54l-.8,7.15,12.02.05.26-9.03Z" fill="#e3e2e3" />
                <path
                    id="Path_319"
                    d="M414.69,233c3-.8,5.05-4.37,5.05-4.37l-2.55.49-2.21.43Z"
                    fill="#e3e2e3"
                />
                <path
                    id="Path_320"
                    d="M411.7,209.45c-.23.57-.12,1.15.26,1.29s.91-.21,1.12-.76.11-1.15-.27-1.29S411.93,208.89,411.7,209.45Z"
                    fill="#606060"
                />
                <path
                    id="Path_321"
                    d="M413.18,211l-4.6,3.34a4.34,4.34,0,0,0,3.47,1.69Z"
                    fill="#f4f5f6"
                />
                <path
                    id="Path_322"
                    d="M413.9,206a.33.33,0,0,1,.26.17,2.49,2.49,0,0,0,1.85,1.36.31.31,0,0,1,.29.34.33.33,0,0,1-.36.29,3.07,3.07,0,0,1-2.36-1.69.31.31,0,0,1,.14-.43h0a.28.28,0,0,1,.18-.04Z"
                    fill="#606060"
                />
                <path
                    id="Path_323"
                    d="M434.67,216c-2.7,7.67-3.67,11-8.84,13.89-7.32,2.41-16.85-.52-16.56-8.82.27-7.47,4.62-18.72,13.34-19.76A11.28,11.28,0,0,1,435.24,211a10.731,10.731,0,0,1-.57,5Z"
                    fill="#f4f5f6"
                />
                <path
                    id="Path_324"
                    d="M438.48,222.3a58.5,58.5,0,0,0-5.59,5.32,12.531,12.531,0,0,1-3.77,2.76,12.72,12.72,0,0,1-9.17.37h0s-8-7.07-2.66-20.19a7.67,7.67,0,0,1,.34-12.73c2.52-1.59,6.42-.58,8.75,1.29a9.84,9.84,0,0,1,8.82,2.59,20.19,20.19,0,0,1,4.91,8.23,15.23,15.23,0,0,1,1,8.09,7.67,7.67,0,0,1-2.63,4.27Z"
                    fill="#606060"
                />
                <path
                    id="Path_325"
                    d="M418.05,213.37a6.23,6.23,0,0,0-3.24,3.58c-.72,2.08,1.17,3.25,3.24,2.78,1.88-.43,4.49-2,4.31-4.17a2.9,2.9,0,0,0-4.31-2.19Z"
                    fill="#f4f5f6"
                />
                <path
                    id="Path_326"
                    d="M428.84,232c-2.67-1.15-7.47-1.26-14.42-.18a2.36,2.36,0,0,0-1.64,1.59,1.37,1.37,0,0,0,.88,1.84c-.06,1-.31,2.12-.37,3.14a41.389,41.389,0,0,1,6.08.33,77.565,77.565,0,0,1,9.34,1.25c-.2-1.47-.65-3-.31-4.47a1.61,1.61,0,0,0,1.62-1.27A2.41,2.41,0,0,0,428.84,232Z"
                    fill="#606060"
                />
                <path
                    id="Path_327"
                    d="M428.84,232c-2.67-1.15-7.47-1.26-14.42-.18a2.36,2.36,0,0,0-1.64,1.59,1.37,1.37,0,0,0,.88,1.84c-.06,1-.31,2.12-.37,3.14a41.389,41.389,0,0,1,6.08.33,77.565,77.565,0,0,1,9.34,1.25c-.2-1.47-.65-3-.31-4.47a1.61,1.61,0,0,0,1.62-1.27A2.41,2.41,0,0,0,428.84,232Z"
                    fill="#606060"
                />
                <path
                    id="Path_328"
                    d="M396,308.1s-2.47-33-2.32-56.72c.11-17.54,12.69-14.39,28.89-14.3,12.85.06,17.22,1.16,19,8.17s-6.9,63-6.9,63Z"
                    fill="#606060"
                />
                <path
                    id="Path_329"
                    d="M396,308.1s-2.47-33-2.32-56.72c.11-17.54,12.69-14.39,28.89-14.3,12.85.06,17.22,1.16,19,8.17s-6.9,63-6.9,63Z"
                    opacity="0.5"
                />
                <path
                    id="Path_330"
                    d="M372.29,261.58a4.94,4.94,0,0,0-3,1.15,9.6,9.6,0,0,0-3.05,4.09h0a13.52,13.52,0,0,0-4.3-4.44l-.09-.06c.57-7.8,6.95-7.2,6.95-7.2l.16.4a33.508,33.508,0,0,0,3.33,6.06Z"
                    fill="#606060"
                />
                <path
                    id="Path_331"
                    d="M372.29,261.58a4.94,4.94,0,0,0-3,1.15,9.6,9.6,0,0,0-3.05,4.09h0a13.52,13.52,0,0,0-4.3-4.44l-.09-.06c.57-7.8,6.95-7.2,6.95-7.2l.16.4a33.508,33.508,0,0,0,3.33,6.06Z"
                    opacity="0.2"
                />
                <path
                    id="Path_332"
                    d="M389.31,289.27c-11.79-.25-23.09-19.75-23.09-19.75a4.5,4.5,0,0,1,0-2.7,9.6,9.6,0,0,1,3.05-4.09,4.94,4.94,0,0,1,3-1.15,5.74,5.74,0,0,1,2.16.3s8.69,10.73,10.81,9.77,2.93-4.53,3.71-9c4.13-23.77,8.67-24.48,12.66-24.59C409.9,237.87,399.75,289.5,389.31,289.27Z"
                    fill="#606060"
                />
                <g id="Group_3" opacity="0.1">
                    <path
                        id="Path_333"
                        d="M389.31,289.27c-11.79-.25-23.09-19.75-23.09-19.75a4.5,4.5,0,0,1,0-2.7,9.6,9.6,0,0,1,3.05-4.09,4.94,4.94,0,0,1,3-1.15,5.74,5.74,0,0,1,2.16.3s8.69,10.73,10.81,9.77,2.93-4.53,3.71-9c4.13-23.77,8.67-24.48,12.66-24.59C409.9,237.87,399.75,289.5,389.31,289.27Z"
                        fill="#fff"
                    />
                </g>
                <path
                    id="Path_334"
                    d="M434.68,308.29s1,10,.87,13.56c-.75,18.72-18.48,25.32-32.61,22.73l-50.21-5.44c-2,6.8-25.69,44.24-25.69,44.24l-12.43-7.14s12.89-30.91,21.22-52.42c4.75-12.28,42.47-12.65,56.71-10.13,4.33,1.17,3.5-5.59,3.5-5.59Z"
                    fill="#606060"
                />
                <path
                    id="Path_335"
                    d="M323.87,384.08l-4.75,7.91-8.86-4.4,4.61-9.02Z"
                    fill="#e3e2e3"
                />
                <path
                    id="Path_336"
                    d="M305.55,386c-1.17-.53-2.28-1.3-2.44-2a.94.94,0,0,1,.46-1,1.26,1.26,0,0,1,.94-.2c1.77.29,4.19,3.66,4.3,3.81a.24.24,0,0,1,0,.28.26.26,0,0,1-.26.1,17.79,17.79,0,0,1-3-.99Zm-.66-2.51a1.39,1.39,0,0,0-.45-.14.71.71,0,0,0-.58.11c-.29.2-.29.36-.27.47.16.81,2.52,1.91,4.37,2.49a11.269,11.269,0,0,0-3.07-2.97Z"
                    opacity="0.2"
                />
                <path
                    id="Path_337"
                    d="M308.51,387a.1.1,0,0,1-.08-.05c-.9-1-2.23-4.08-1.54-5.05.14-.24.47-.47,1.11-.25a1.83,1.83,0,0,1,1.07.95c.74,1.53-.16,4.18-.21,4.31a.23.23,0,0,1-.19.15A.25.25,0,0,1,308.51,387Zm-.55-4.86-.13-.07c-.41-.15-.51,0-.55.05-.35.61.4,2.89,1.25,4.11a5.83,5.83,0,0,0,.07-3.46A1.31,1.31,0,0,0,307.96,382.14Z"
                    opacity="0.2"
                />
                <path
                    id="Path_338"
                    d="M310.11,386.13l10.26,4.7a.9.9,0,0,1,.51,1l-2,9.2a1.52,1.52,0,0,1-1.78,1.18l-.31-.1c-3.67-1.76-6.87-3.47-11.47-5.57-5.44-2.48-4.36-1.2-10.79-4-3.88-1.71-2.58-6.41-.85-5.88,7.91,2.48,9.71.58,13.82-.78a3.63,3.63,0,0,1,2.61.25Z"
                    fill="#606060"
                />
                <path id="Path_339" d="M321.46,388.1l2.41-4.02-9-5.51-2.23,4.36Z" opacity="0.2" />
                <path
                    id="Path_340"
                    d="M329,384l-15.42-9.62a3.93,3.93,0,0,0-1.94,4l14.81,9.46S328.46,387.82,329,384Z"
                    fill="#606060"
                />
                <path
                    id="Path_341"
                    d="M416.21,347.48h25.57a18.9,18.9,0,0,0,18.83-17.4l3.64-45.93a12.79,12.79,0,0,0-12.76-13.81H437.37a37.278,37.278,0,0,0-35.728,26.655l-9.8,32.805A13,13,0,0,1,379.4,339H361.54a8.47,8.47,0,0,0-8.47,8.47h63.14Z"
                    fill="#e3e2e3"
                />
                <path
                    id="Path_342"
                    d="M404.71,347.48c.7,10.92,2.59,50.73-.23,59.49-2.48,7.71-13.81,9.55-20.5,13.64-1.13.68-.74,2.86.5,2.86h52.15c1.24,0,1.63-2.18.5-2.86-6.69-4.09-18-5.93-20.5-13.64-2.82-8.76-.93-48.57-.23-59.49Z"
                    fill="#e3e2e3"
                />
                <path
                    id="Path_343"
                    d="M405.19,355.74h10.73c.18-3.35.35-6.21.48-8.26H404.71C404.84,349.53,405,352.39,405.19,355.74Z"
                    fill="#606060"
                />
                <path
                    id="Path_344"
                    d="M416.21,347.48h25.57a18.9,18.9,0,0,0,18.83-17.4l3.64-45.93a12.79,12.79,0,0,0-12.76-13.81H437.37a37.278,37.278,0,0,0-35.728,26.655l-9.8,32.805A13,13,0,0,1,379.4,339H361.54a8.47,8.47,0,0,0-8.47,8.47h63.14Z"
                    fill="#f4f5f6"
                    opacity="0.7"
                />
                <ellipse
                    id="_Path_2"
                    cx="154.66"
                    cy="11.62"
                    rx="154.66"
                    ry="11.62"
                    transform="translate(70.33 474.14)"
                    fill="#f5f5f5"
                />
                <circle
                    id="Ellipse_10"
                    cx="10.72"
                    cy="10.72"
                    r="10.72"
                    transform="translate(35.66 154.33)"
                    fill="#fff"
                    stroke="#e2e2e2"
                    strokeMiterlimit="10"
                    strokeWidth="2"
                />
                <circle
                    id="Ellipse_11"
                    cx="6.73"
                    cy="6.73"
                    r="6.73"
                    transform="translate(226.7 402.19)"
                    fill="#fff"
                    stroke="#e2e2e2"
                    strokeMiterlimit="10"
                    strokeWidth="2"
                />
                <circle
                    id="Ellipse_12"
                    cx="14.39"
                    cy="14.39"
                    r="14.39"
                    transform="translate(37.49 212.25)"
                    fill="#fff"
                    stroke="#e2e2e2"
                    strokeMiterlimit="10"
                    strokeWidth="2"
                />
                <path
                    id="Path_345"
                    d="M50.89,293.9H43.05v-7.83a1,1,0,0,0-1-1h0a1,1,0,0,0-1,1v7.83h-7.8a1,1,0,0,0,0,2h7.82v7.84a1,1,0,0,0,2,0h0V295.9h7.84a1,1,0,0,0,0-2Z"
                    fill="#e2e2e2"
                    stroke="#e2e2e2"
                    strokeMiterlimit="10"
                    strokeWidth="0.75"
                />
                <path
                    id="Path_346"
                    d="M277.92,42.85h-4.79V38.07a.58.58,0,0,0-.59-.59h0a.61.61,0,0,0-.61.59v4.78h-4.77a.6.6,0,1,0,0,1.2h4.77v4.79a.61.61,0,0,0,.61.59.58.58,0,0,0,.59-.59h0V44.05h4.79a.59.59,0,0,0,.6-.59.6.6,0,0,0-.6-.61Z"
                    fill="#e2e2e2"
                    stroke="#e2e2e2"
                    strokeMiterlimit="10"
                    strokeWidth="0.75"
                />
                <path
                    id="Path_347"
                    d="M150.58,413.47h-6.26v-6.24a.78.78,0,0,0-.78-.78h0a.79.79,0,0,0-.79.78v6.24h-6.24a.79.79,0,0,0,0,1.58h6.24v6.26a.79.79,0,0,0,.79.77.77.77,0,0,0,.78-.77h0v-6.26h6.26a.8.8,0,0,0,.79-.78.81.81,0,0,0-.79-.8Z"
                    fill="#e2e2e2"
                    stroke="#e2e2e2"
                    strokeMiterlimit="10"
                    strokeWidth="0.75"
                />
                <path
                    id="Path_348"
                    d="M210.46,432.28h-7.85v-7.83a1,1,0,0,0-1-1h0a1,1,0,0,0-1,1v7.83h-7.82a1,1,0,0,0,0,2h7.82v7.85a1,1,0,0,0,2,0h0v-7.85h7.85a1,1,0,0,0,0-2Z"
                    fill="#e2e2e2"
                    stroke="#e2e2e2"
                    strokeMiterlimit="10"
                    strokeWidth="0.75"
                />
            </g>
        </SVGIcon>
    );
}
