import React from 'react';

import { SVGIcon } from '../../UIKit/index';

export default function DCLeadType(props) {
    return (
        <SVGIcon {...props} viewBox="0 0 512 512">
            <g>
                <path
                    d="M451.449,168.599C447.967,162.061,441.204,158,433.797,158h-56.81c-4.743-26.675-28.087-47-56.104-47
			c-28.017,0-51.361,20.325-56.104,47h-57.555c4.514-6.908,7.15-15.15,7.15-24c0-24.262-19.738-44-44-44c-24.262,0-44,19.738-44,44
			c0,8.85,2.636,17.092,7.15,24H86.598c-7.407,0-14.17,4.061-17.652,10.599c-3.481,6.537-3.077,14.416,1.056,20.562l134.196,199.556
			V502c0,3.372,1.699,6.517,4.52,8.364c1.653,1.083,3.562,1.636,5.481,1.636c1.354,0,2.713-0.275,3.992-0.832l92-40.063
			c3.648-1.589,6.007-5.189,6.007-9.168v-73.221L450.393,189.16C454.526,183.015,454.931,175.136,451.449,168.599z M320.883,131
			c16.937,0,31.241,11.443,35.616,27h-71.231C289.642,142.443,303.947,131,320.883,131z M170.375,110c13.233,0,24,10.767,24,24
			c0,13.233-10.767,24-24,24c-13.233,0-24-10.767-24-24C146.375,120.767,157.142,110,170.375,110z M297.899,380.086
			c-1.109,1.649-1.702,3.592-1.702,5.58v69.719l-72,31.353v-91.072h36.127c5.523,0,10-4.477,10-10s-4.477-10-10-10h-40.801
			L118.88,226h202.003c5.523,0,10-4.477,10-10s-4.477-10-10-10H105.431l-18.829-28h347.194L297.899,380.086z"
                />
                <path
                    d="M294.324,0c-24.262,0-44,19.738-44,44s19.739,44,44,44c24.262,0,44-19.738,44-44S318.586,0,294.324,0z M294.324,68
			c-13.233,0-24-10.767-24-24c0-13.233,10.767-24,24-24c13.233,0,24,10.767,24,24S307.557,68,294.324,68z"
                />
                <path
                    d="M401.197,48.333c-5.523,0-10,4.477-10,10v56.834c0,5.524,4.477,10.001,10,10.001s10-4.477,10-10V58.333
			C411.197,52.81,406.72,48.333,401.197,48.333z"
                />
                <path
                    d="M408.267,18.43c-1.86-1.86-4.44-2.93-7.07-2.93s-5.21,1.07-7.07,2.93s-2.93,4.44-2.93,7.07s1.07,5.21,2.93,7.07
			s4.44,2.93,7.07,2.93s5.21-1.07,7.07-2.93c1.86-1.86,2.93-4.44,2.93-7.07S410.127,20.29,408.267,18.43z"
                />
                <path
                    d="M204.375,12.755c-5.523,0-10,4.477-10,10v41c0,5.523,4.477,10,10,10s10-4.477,10-10v-41
			C214.375,17.232,209.898,12.755,204.375,12.755z"
                />
                <path d="M68.197,0c-5.523,0-10,4.477-10,10v65.168c0,5.523,4.477,10,10,10s10-4.477,10-10V10C78.197,4.477,73.72,0,68.197,0z" />
                <path
                    d="M75.267,105.6c-1.86-1.87-4.44-2.93-7.07-2.93s-5.21,1.06-7.07,2.93c-1.86,1.86-2.93,4.43-2.93,7.07
			c0,2.63,1.07,5.21,2.93,7.07s4.44,2.93,7.07,2.93s5.21-1.07,7.07-2.93c1.86-1.86,2.93-4.44,2.93-7.07
			C78.197,110.03,77.127,107.46,75.267,105.6z"
                />
                <path
                    d="M372.417,208.93c-1.86-1.86-4.44-2.93-7.07-2.93c-2.64,0-5.22,1.07-7.07,2.93c-1.87,1.86-2.93,4.44-2.93,7.07
			s1.06,5.21,2.93,7.07c1.86,1.86,4.43,2.93,7.07,2.93c2.63,0,5.2-1.07,7.07-2.93c1.86-1.86,2.93-4.44,2.93-7.07
			S374.277,210.79,372.417,208.93z"
                />
            </g>
        </SVGIcon>
    );
}
