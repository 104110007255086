import './style.scss';
import PropTypes from 'prop-types';
import React from 'react';
import Div from 'UIKit/BlockElement/div';
import CircularLoader from 'UIKit/Loader/circularLoader';

const IconButton = props => {
    const {
        customClass,
        onClick,
        children,
        buttonType,
        reference,
        disabled,
        loading,
        title,
        ...rest
    } = props;
    return (
        <button
            {...rest}
            onClick={onClick}
            className={`iconButton ${buttonType || ''} ${customClass || ''} `}
            ref={reference}
            disabled={disabled}
            title={title}
        >
            {loading && (
                <Div className="buttonLoading">
                    <CircularLoader />
                </Div>
            )}
            {children}
        </button>
    );
};

IconButton.propTypes = {
    props: PropTypes.any,
    title: PropTypes.any,
    customClass: PropTypes.string,
    onClick: PropTypes.func,
    children: PropTypes.element,
    buttonType: PropTypes.string,
    reference: PropTypes.object,
    disabled: PropTypes.bool,
    loading: PropTypes.bool,
    title: PropTypes.string,
};

export default IconButton;

// RedFillBtn
// RedOutlineBtn
// BlueFillBtn
// BlueOutlineBtn
// WhiteFillBtn
// WhiteOutlineBtn
// BlackFillBtn
// BlackOutlineBtn
// headerIconBtn
// circleBtn
