import AWS, { Credentials } from 'aws-sdk';

import { CallAPI } from 'Utils/apiCall';
import configAPI from 'config';

const axiosCall = new CallAPI();

export const publicFileUpload = async (file, fileDetails, formId, onProgressChange) => {
    try {
        let dataToSend = {
            file_type: fileDetails.type,
            file_name: fileDetails.name.replaceAll(' ', '_'),
            file_size: fileDetails.size,
        };
        let data = await axiosCall.requestPublic(
            'POST',
            `${configAPI.REACT_APP_API_BASE_URL}/${process.env.REACT_APP_API_VERSION}/${process.env.REACT_APP_API_INTEGRATIONS}/${process.env.REACT_APP_API_WASABI}/${process.env.REACT_APP_API_TOOLS}/${process.env.REACT_APP_API_FORMS}/${process.env.REACT_APP_API_TOOL_ID}/${formId}`,
            dataToSend,
        );
        let creds = new Credentials({
            accessKeyId: data.data.credentials.AccessKeyId,
            secretAccessKey: data.data.credentials.SecretAccessKey,
            sessionToken: data.data.credentials.SessionToken,
        });
        let config = {
            apiVersion: '2006-03-01',
            credentials: creds,
            bucketName: data.data.additionalInfo.bucket,
            region: 'us-east-1',
            endpoint: 'https://s3.wasabisys.com',
            ACL: 'private',
        };
        var myBucket = data.data.additionalInfo.bucket;
        var myKey = data.data.key;

        try {
            AWS.config.update(config);

            let fileUpload = new AWS.S3.ManagedUpload({
                params: {
                    Bucket: myBucket,
                    Key: myKey,
                    Body: new Uint8Array(await file.arrayBuffer()),
                },
            });
            return new Promise((resolve, reject) => {
                fileUpload.send(async (err, resfile) => {
                    if (err) {
                        reject({ status: 'Failed', error: err });
                    } else {
                        resolve({
                            status: 'Success',
                            key: myKey,
                            bucket: resfile.Bucket,
                        });
                    }
                });
                fileUpload.on('httpUploadProgress', progressEvent => {
                    onProgressChange(progressEvent, fileDetails.id);
                });
            });
        } catch (err) {
            return { status: 'Failed', error: err };
        }
    } catch (err) {
        return { status: 'Failed', error: err };
    }
};
