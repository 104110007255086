import { useCallback, useContext } from 'react';
import socketIOClient, { io } from 'socket.io-client';
import usePubSub from 'Hooks/PubSub';
import config from 'config';
import { getSessionId, getAccountDetails } from 'Utils/localStorageUtils';
import { useQueryClient } from 'react-query';
import { GlobalConversationsContext } from 'Contexts/Context';

let conversationSocketUri = `${config.REACT_APP_NEW_CONVERSATION_SOCKET}/`;

const useSocketSetup = () => {
    const { publish } = usePubSub();
    const queryClient = useQueryClient();
    const { conversationsSocket, setConversationsSocket } = useContext(GlobalConversationsContext);

    return useCallback(() => {
        const sessionId = getSessionId();
        const client = socketIOClient(`${config.REACT_APP_API_BASE_URL}/v1/shared`, {
            query: {
                session_id: sessionId,
            },
            transports: ['websocket'],
        });
        client.on('connect', () => {
            client.emit(
                'join',
                {
                    session_id: sessionId,
                },
                () => {
                    //
                },
            );
            client.on('scraper_update', data => {
                publish('scraper_update', data);
            });
            client.on('scraper_finish', data => {
                publish('scraper_finish', data);
            });

            client.on('leadadded', data => {
                publish('leadadded', data);
            });

            client.on('campaigncountupdated', data => {
                publish('campaigncountupdated', data);
            });
            client.on('instasite_status_changed', data => {
                publish('instasite_status_changed', data);
            });
            client.on('instasite_added', data => {
                publish('instasite_added', data);
            });
            client.on('instareport_status_update', data => {
                publish('instareport_status_update', data);
            });
            client.on('subscription_downgrade', data => {
                publish('subscription_downgrade', data);
            });
            client.on('new_review', data => {
                publish('new_review', data);
            });
            client.on('new_review_search_start', data => {
                publish('new_review_search_start', data);
            });
            client.on('new_review_search_end', data => {
                publish('new_review_search_end', data);
            });
            client.on('new_email_processed', data => {
                publish('new_email_processed', data);
            });
            client.on('new_sms_processed', data => {
                publish('new_sms_processed', data);
            });
        });

        // Conversations socket
        if (!conversationsSocket?.connected) {
            conversationSocketUri += getAccountDetails().id;
            const newConversationSocket = io(conversationSocketUri, {
                auth: {
                    session: sessionId,
                },
                transports: ['websocket'],
            });
            newConversationSocket.on('connect', () => {
                setConversationsSocket(newConversationSocket);
                newConversationSocket?.on('actives', data => {
                    publish('setActives', data);
                });

                newConversationSocket?.on('newMessage', data => {
                    setNewMessageData(data?.message);
                    publish('updateMessage', data);
                    setNewMessage(data?.qid, data?.message);
                    setRoomData(data?.qid, data?.message);
                });
                newConversationSocket?.on('support:newMessage', data => {
                    publish('updateSupportMessage', data);
                });
                newConversationSocket?.on('support:updatedMessage', data => {
                    publish('updateSupportMessage', { ...data, message: data });
                });
                newConversationSocket?.on('support:newRoom', data => {
                    newConversationSocket?.emit('support:joinRooms');
                    publish('supportNewRoom', {
                        ...data,
                        room: {
                            ...data?.room,
                            unread_count: 1,
                        },
                    });
                    newConversationSocket?.emit('support:updateInbox', null, data => {
                        queryClient.setQueryData('conversationSupportInboxCount', () => {
                            return {
                                data: { ...data?.inboxes },
                            };
                        });
                    });
                });
                newConversationSocket?.on('support:room:updated', data => {
                    publish('supportUpdateRoom', data);
                    newConversationSocket?.emit('support:updateInbox', null, data => {
                        queryClient.setQueryData('conversationSupportInboxCount', () => {
                            return {
                                data: { ...data?.inboxes },
                            };
                        });
                    });
                });
                newConversationSocket?.on('support:room:deleted', data => {
                    publish('supportRoomDeleted', data);
                    newConversationSocket?.emit('support:updateInbox', null, data => {
                        queryClient.setQueryData('conversationSupportInboxCount', () => {
                            return {
                                data: { ...data?.inboxes },
                            };
                        });
                    });
                });
                newConversationSocket?.on('support:actives', data => {
                    publish('setSupportActives', data);
                });
                newConversationSocket?.on('support:msgAck', data => {
                    publish('supportMessageAck', data);
                });
            });
        }
    }, [conversationsSocket, publish, setConversationsSocket, queryClient]);
};

export default useSocketSetup;
