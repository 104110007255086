import React, { useReducer } from 'react';

import { reviewActions } from '../Actions/index';
import { ReviewContext } from '../Context/index';
import { reviewReducer } from '../Reducer/index';
import { reviewStore } from '../Store/index';

const ReviewContextProvider = props => {
    const [state, dispatch] = useReducer(reviewReducer, reviewStore);

    const value = {
        need_response_count: state.need_response_count,

        setNeedResponseCount: value => {
            dispatch({
                type: reviewActions.SET_NEED_RESPONSE_COUNT,
                value,
            });
        },
    };

    return <ReviewContext.Provider value={value}>{props.children}</ReviewContext.Provider>;
};

export default ReviewContextProvider;
