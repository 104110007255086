import React from 'react';

import { SVGIcon } from '../../UIKit/index';

export default function DCFileDownload(props) {
    return (
        <SVGIcon {...props} viewBox="0 0 512 512">
            <g>
                <path d="m497 128.04h-29.88v-45.76c0-8.284-6.716-15-15-15h-204.003l-23.164-49.435c-2.469-5.27-7.764-8.636-13.583-8.636h-196.37c-8.284 0-15 6.716-15 15v402.58c0 8.284 6.716 15 15 15h45v46c0 8.284 6.716 15 15 15h422c8.284 0 15-6.716 15-15v-344.749c0-8.284-6.716-15-15-15zm-467 283.75v-372.58h171.833l23.164 49.435c2.469 5.27 7.764 8.636 13.583 8.636h198.54v30.76h-362.12c-8.284 0-15 6.716-15 15v268.75h-30zm452 61h-392v-46-268.75h362.12 29.88z" />
                <path d="m393.144 378.906c-8.284 0-15 6.716-15 15v18.884h-184.288v-18.884c0-8.284-6.716-15-15-15s-15 6.716-15 15v33.884c0 8.284 6.716 15 15 15h214.288c8.284 0 15-6.716 15-15v-33.884c0-8.284-6.716-15-15-15z" />
                <path d="m275.394 377.396c5.891 5.892 15.408 5.817 21.213 0l71.495-71.495c5.858-5.857 5.858-15.355 0-21.213-5.857-5.857-15.355-5.857-21.213 0l-45.889 45.889v-127.537c0-8.284-6.716-15-15-15s-15 6.716-15 15v127.537l-45.889-45.889c-5.857-5.857-15.355-5.857-21.213 0s-5.858 15.355 0 21.213z" />
            </g>
        </SVGIcon>
    );
}
