import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Div, Image } from 'UIKit';
import DCRightArrowIcon from 'Assets/icons/DCRightArrow';
import './style.scss';
import { getBrandingData } from 'apis/login/index.api';
import { getCookie, isValidSession } from 'Utils/auth';
import { useQuery } from 'react-query';
import { unregister } from 'serviceWorker';
import generateImage from 'Utils/generateImage';
import config from 'config';
import RightSideLogin from 'Pages/Login/RightSideLogin';
import { getDefaultPage, removeSession } from 'Utils/localStorageUtils';

const getFaviconEl = () => {
    return document.getElementById('favicon');
};

const Sessions = (props:any) => {
    const history = useHistory();
    const [allSessions, setAllSessions] = useState<any>({});

    useEffect(() => {
        const sessions = JSON.parse(localStorage.getItem('auth-user') || '{}');
        if (!Object.keys(sessions).length) {
            console.info('Not logged in user redirected to login page');
            window.location.href = '/auth/login';
        } else if (Object.keys(sessions).length === 1) {
            console.info('User logged in 1 account redirect to dashboard page');
            sessionStorage.setItem('active-session', Object.keys(sessions)[0]);
            let url = props.location.search.substr(1).split('=');
            if (url.length) {
                url = url[1];
            }
            window.location.href = url ? url : '/' + getDefaultPage();
        } else {
            console.info('User logged in multiple account');
            // check if session storage ('active-session') is set
            const activeSession = sessionStorage.getItem('active-session') || '';
            if (activeSession) {
                let url = props.location.search.substr(1).split('=');
                if (url.length) {
                    url = url[1];
                }
                window.location.href = url ? url : '/' + getDefaultPage();
            } else {
                Object.keys(sessions).forEach((session: any) => {
                    const valid = isValidSession(session);
                    if (!valid) {
                        removeSession(session);
                    }
                });
                const updatedSessions = JSON.parse(localStorage.getItem('auth-user') || '{}');
                if (Object.keys(updatedSessions).length) {
                    setAllSessions(updatedSessions);
                } else {
                    window.location.href = '/auth/login';
                }
            }
            // if not set, redirect to select session page
        }
    }, [history, props.location.search]);

    const handleSelectSession = (sessionId: string) => {
        sessionStorage.setItem('active-session', sessionId);
        let url = props.location.search.substr(1).split('=');
        if (url.length) {
            url = url[1];
        }
        window.location.href = url ? url : '/' + getDefaultPage();
    };

    /**
     * Get Account Branding
     */
    const { data: brandingData } = useQuery(
        ['login', 'branding_data'],
        () => getBrandingData(getCookie('aid') ? getCookie('aid') : config.REACT_APP_TEST_AID),
        {
            onSuccess: (data: { data: any }) => {
                document.title = `Sign in | ${data.data?.name}`;
                const favicon = getFaviconEl();
                document.documentElement.style.setProperty(
                    '--dark-blue',
                    data.data?.colors?.secondary,
                );
                document.documentElement.style.setProperty(
                    '--blue-invert',
                    data.data?.colors?.font_primary,
                );

                unregister();
                favicon?.setAttribute(
                    'href',
                    data.data?.images?.favicon?.key
                        ? generateImage(data.data?.images.favicon, null, true) || ''
                        : 'https://s3.wasabisys.com/open/general/branding/dc_dashboard.ico',
                );
            },
        },
    );
    return (
        <Div className="LoginPage">
            <Div className="LoginPageInner">
                <Div
                    className="LPIFormSection"
                    style={{ backgroundColor: brandingData?.data?.colors?.primary }}
                >
                    <Div className="LPIFSInner">
                        <Div className="LPIFSIHeader">
                            <Div className="LPIFSIHLogo">
                                <Image
                                    src={generateImage(brandingData?.data?.image, null, true) || ''}
                                    alt={brandingData?.data?.name}
                                />
                            </Div>
                        </Div>
                        <Div className="LPIFSIBody">
                            <Div className="LPIFSIFormBox">
                                <Div className="LPIFSIFBTitle">
                                    <Div
                                        className="LPIFSIFBTText"
                                        style={{
                                            color: brandingData?.data?.colors?.font_primary,
                                            fontSize: '26px',
                                            fontWeight: 600,
                                        }}
                                    >
                                        Select the active session you want to continue with
                                    </Div>
                                </Div>
                                <Div className="SPListContainer">
                                    {Object.keys(allSessions || {}).map(session => (
                                        <Div
                                            key={session}
                                            onClick={() => {
                                                handleSelectSession(session);
                                            }}
                                            className={'SPLItem'}
                                        >
                                            <Div className="SPLITitle">
                                                {allSessions[session].auth?.account.name}{' '}
                                                <span>({session.substring(0, 8)})</span>
                                            </Div>

                                            <Div className="SPLIActions">
                                                <span className="SPLIACTABtn">
                                                    <DCRightArrowIcon />
                                                </span>
                                            </Div>
                                        </Div>
                                    ))}
                                </Div>
                            </Div>
                        </Div>
                    </Div>
                </Div>
                <RightSideLogin
                    image={
                        brandingData?.data?.images?.login_image?.key
                            ? generateImage(brandingData?.data?.images?.login_image, null, true)
                            : null
                    }
                    hideFloatingApps={brandingData?.data?.hide_floating_apps}
                />
            </Div>
        </Div>
    );
};

export default Sessions;
