import React from 'react';

import { SVGIcon } from '../../UIKit/index';

export default function DCNoDrag(props) {
    return (
        <SVGIcon {...props} viewBox="0 0 359 232">
            <g transform="translate(-496 -435)">
                <rect
                    width="359"
                    height="67"
                    rx="33.5"
                    transform="translate(496 600)"
                    fill="#ebf2ff"
                />
                <g transform="translate(-7 13.796)">
                    <g transform="translate(384.172 296.26)">
                        <path
                            d="M11.083,0H347.837c6.121,0,11.083,4.123,11.083,9.209V141.587c0,5.086-4.962,9.209-11.083,9.209H11.083C4.962,150.8,0,146.673,0,141.587V9.209C0,4.123,4.962,0,11.083,0Z"
                            transform="translate(118.828 124.944)"
                            fill="#fff"
                        />
                        <ellipse
                            cx="16.5"
                            cy="17"
                            rx="16.5"
                            ry="17"
                            transform="translate(227.828 131.74)"
                            fill="#5bcbff"
                        />
                        <ellipse
                            cx="28.5"
                            cy="28"
                            rx="28.5"
                            ry="28"
                            transform="translate(234.828 170.74)"
                            fill="#005eff"
                        />
                        <path
                            d="M239.11,170s-8.377,16.753-32.11,11.169v2.792s20.942,6.98,33.506-11.169Z"
                            transform="translate(19.409 15.052)"
                            fill="#5bcbff"
                        />
                        <path
                            d="M296.961,175,283,229.448h33.506L330.467,176.4Z"
                            transform="translate(49.513 17.032)"
                            fill="#5bcbff"
                        />
                        <path
                            d="M158,216.792a67.122,67.122,0,0,1,34.9,26.526h4.188S178.942,216.792,158,214Z"
                            transform="translate(0 32.48)"
                            fill="#f5f9ff"
                        />
                        <path
                            d="M158,204.188s37.695,4.188,46.071,44.675h4.188S202.675,208.377,158,200Z"
                            transform="translate(0 26.935)"
                            fill="#f5f9ff"
                        />
                        <path
                            d="M316.871,256.019H214s12.415-40.794,37.246-47.888C251.246,208.13,283.172,201.036,316.871,256.019Z"
                            transform="translate(11.233 24.909)"
                            fill="#f5f9ff"
                        />
                        <rect
                            width="7"
                            height="3"
                            transform="translate(365.828 197.74)"
                            fill="#fff"
                        />
                        <rect
                            width="14"
                            height="3"
                            transform="translate(343.828 229.74)"
                            fill="#fff"
                        />
                        <rect
                            width="20"
                            height="3"
                            transform="translate(341.828 236.74)"
                            fill="#fff"
                        />
                        <ellipse
                            cx="4.188"
                            cy="5.759"
                            rx="4.188"
                            ry="5.759"
                            transform="translate(273.876 185.575)"
                            fill="none"
                            stroke="#fff"
                            strokeMiterlimit="10"
                            strokeWidth="1.6"
                        />
                        <text
                            transform="translate(347.828 218.74)"
                            fill="#fff"
                            fontSize="29"
                            fontWeight="700"
                        >
                            <tspan x="0" y="0">
                                +
                            </tspan>
                        </text>
                        <path
                            d="M273.584,166,268,182.753h19.545L291.734,166H273.584"
                            transform="translate(43.571 13.467)"
                            fill="#ebf2ff"
                        />
                        <circle
                            cx="4"
                            cy="4"
                            r="4"
                            transform="translate(332.828 175.74)"
                            fill="#005eff"
                        />
                    </g>
                    <text
                        transform="translate(577 628.204)"
                        fill="#005eff"
                        fontSize="20"
                        fontWeight="500"
                    >
                        <tspan x="0" y="0">
                            DRAG ELEMENTS HERE
                        </tspan>
                    </text>
                    <g transform="translate(739.079 445.309)">
                        <path
                            d="M209.383,219.349a1.58,1.58,0,0,1-1.469-1.012l-5.165-13.556a1.434,1.434,0,0,1-.105-.563,1.575,1.575,0,0,1,1.573-1.574,1.486,1.486,0,0,1,.62.129l13.5,5.141a1.573,1.573,0,0,1-.074,2.966L212.7,212.7l-1.823,5.564A1.572,1.572,0,0,1,209.383,219.349Zm-4.675-14.64,4.656,12.218,1.72-5.25a.928.928,0,0,1,.593-.593l5.25-1.72Zm12.971,4.942h0Zm-13.557-5.166a.045.045,0,0,0,.016.006Zm.345-.411.019.047C204.479,204.107,204.475,204.091,204.467,204.075Zm0,0"
                            transform="translate(-190.891 -190.891)"
                            fill="#005eff"
                        />
                        <path
                            d="M12.373,24.746A12.373,12.373,0,1,1,24.746,12.373c0,.181,0,.36-.015.542a.928.928,0,0,1-1.851-.142c.011-.133.01-.267.01-.4A10.517,10.517,0,1,0,12.373,22.89c.138,0,.28,0,.411-.009a.928.928,0,1,1,.144,1.85c-.181.014-.374.015-.554.015Zm0,0"
                            fill="#005eff"
                        />
                    </g>
                </g>
            </g>
        </SVGIcon>
    );
}
