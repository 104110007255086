import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file

import { DateRangePicker as DateRange } from 'react-date-range';
import dayjs from 'dayjs';
import { Popover, Div, Dialog, TopBar, IconButton, H1 } from 'UIKit';
import { getUserTimeZone } from 'Utils/dateUtils';
import useMediaQuery from 'UIKit/useMediaQuery';
import { addDays } from 'date-fns';
import _ from 'lodash';
import DCCrossClose from 'Assets/icons/DCCrossClose';

function DateRangePicker(props) {
    const matche1023 = useMediaQuery('(max-width: 1023.98px)');
    const matche756 = useMediaQuery('(max-width: 756px)');

    const [selectionRange, setSelectionRange] = useState({
        startDate: dayjs(props.startDate).tz(getUserTimeZone()).toDate(),
        endDate: dayjs(props.endDate).tz(getUserTimeZone()).toDate(),
        key: 'selection',
    });
    const [selectedPreset, setSelectedPreset] = useState(null);
    const [clicked, setClicked] = useState(false);
    const [index, setIndex] = useState(false);

    const defaultPresets = useMemo(
        () => [
            ...(props.allTimeFilter
                ? [
                      {
                          label: 'All Time',
                          name: 'all_time',
                          hasCustomRendering: false,
                          range: () => ({
                              startDate: '00-00-0000',
                              endDate: dayjs().tz(getUserTimeZone()).toDate(),
                          }),
                          isSelected() {
                              return selectedPreset === 'all_time';
                          },
                      },
                  ]
                : []),
            {
                label: 'Today',
                name: 'today',
                hasCustomRendering: false,
                range: () => ({
                    startDate: dayjs().tz(getUserTimeZone()).toDate(),
                    endDate: dayjs().tz(getUserTimeZone()).toDate(),
                }),
                isSelected() {
                    return selectedPreset === 'today';
                },
            },
            {
                label: 'Yesterday',
                name: 'yesterday',
                hasCustomRendering: false,
                range: () => ({
                    startDate: addDays(dayjs().tz(getUserTimeZone()).toDate(), -1),
                    endDate: addDays(dayjs().tz(getUserTimeZone()).toDate(), -1),
                }),
                isSelected() {
                    return selectedPreset === 'yesterday';
                },
            },
            {
                label: 'Last 7 Days',
                name: 'last_7_days',
                hasCustomRendering: false,
                range: () => ({
                    startDate: addDays(dayjs().tz(getUserTimeZone()).toDate(), -6),
                    endDate: dayjs().tz(getUserTimeZone()).toDate(),
                }),
                isSelected() {
                    return selectedPreset === 'last_7_days';
                },
            },
            {
                label: 'Last 14 Days',
                name: 'last_14_days',
                hasCustomRendering: false,
                range: () => ({
                    startDate: addDays(dayjs().tz(getUserTimeZone()).toDate(), -13),
                    endDate: dayjs().tz(getUserTimeZone()).toDate(),
                }),
                isSelected() {
                    return selectedPreset === 'last_14_days';
                },
            },
            {
                label: 'Last 30 Days',
                name: 'last_30_days',
                hasCustomRendering: false,
                range: () => ({
                    startDate: addDays(dayjs().tz(getUserTimeZone()).toDate(), -29),
                    endDate: dayjs().tz(getUserTimeZone()).toDate(),
                }),
                isSelected() {
                    return selectedPreset === 'last_30_days';
                },
            },
            {
                label: 'This Week',
                name: 'this_week',
                hasCustomRendering: false,
                range: () => ({
                    startDate: dayjs().tz(getUserTimeZone()).startOf('week').toDate(),
                    endDate: addDays(dayjs().tz(getUserTimeZone()).endOf('week').toDate(), -1),
                }),
                isSelected() {
                    return selectedPreset === 'this_week';
                },
            },
            {
                label: 'Last Week',
                name: 'last_week',
                hasCustomRendering: false,
                range: () => ({
                    startDate: dayjs(
                        addDays(dayjs().tz(getUserTimeZone()).startOf('week').toDate(), -1),
                    )
                        .tz(getUserTimeZone())
                        .startOf('week')
                        .toDate(),
                    endDate: addDays(dayjs().tz(getUserTimeZone()).startOf('week').toDate(), -1),
                }),
                isSelected() {
                    return selectedPreset === 'last_week';
                },
            },
            {
                label: 'This Month',
                name: 'this_march',
                hasCustomRendering: false,
                range: () => ({
                    startDate: dayjs().tz(getUserTimeZone()).startOf('month').toDate(),
                    endDate: addDays(dayjs().tz(getUserTimeZone()).endOf('month').toDate(), -1),
                }),
                isSelected() {
                    return selectedPreset === 'this_month';
                },
            },
            {
                label: 'Last Month',
                name: 'last_month',
                hasCustomRendering: false,
                range: () => ({
                    startDate: dayjs(
                        addDays(dayjs().tz(getUserTimeZone()).startOf('month').toDate(), -1),
                    )
                        .tz(getUserTimeZone())
                        .startOf('month')
                        .toDate(),
                    endDate: addDays(dayjs().tz(getUserTimeZone()).startOf('month').toDate(), -1),
                }),
                isSelected() {
                    return selectedPreset === 'last_month';
                },
            },
            {
                label: 'This Year',
                name: 'this_year',
                hasCustomRendering: false,
                range: () => ({
                    startDate: dayjs().tz(getUserTimeZone()).startOf('year').toDate(),
                    endDate: addDays(dayjs().tz(getUserTimeZone()).endOf('year').toDate(), -1),
                }),
                isSelected() {
                    return selectedPreset === 'this_year';
                },
            },
            {
                label: 'Last Year',
                name: 'last_year',
                hasCustomRendering: false,
                range: () => ({
                    startDate: dayjs(
                        addDays(dayjs().tz(getUserTimeZone()).startOf('year').toDate(), -1),
                    )
                        .tz(getUserTimeZone())
                        .startOf('year')
                        .toDate(),
                    endDate: addDays(dayjs().tz(getUserTimeZone()).startOf('year').toDate(), -1),
                }),
                isSelected() {
                    return selectedPreset === 'last_year';
                },
            },
        ],
        [props.allTimeFilter, selectedPreset],
    );
    const [presets, setPresets] = useState(_.cloneDeep(defaultPresets));
    const firstRun = useRef(true);

    useEffect(() => {
        if (props.presetNotRequired && firstRun.current) {
            let newPreset = _.cloneDeep(defaultPresets);

            props.presetNotRequired.forEach(name => {
                newPreset = newPreset.filter(preset => preset.name !== name);
            });
            setPresets(newPreset);
            firstRun.current = false;
        } else if (!props.presetNotRequired && firstRun.current) {
            setPresets(_.cloneDeep(defaultPresets));
            firstRun.current = false;
        }
    }, [defaultPresets, presets, props.presetNotRequired]);

    const handleSelect = useCallback(
        ranges => {
            if (ranges.selection.startDate !== '00-00-0000') {
                setSelectionRange(ranges.selection);
            } else {
                setSelectionRange({
                    ...ranges.selection,
                    startDate: dayjs().tz(getUserTimeZone()).toDate(),
                    endDate: dayjs().tz(getUserTimeZone()).toDate(),
                });
            }
            const selectedStartState =
                ranges.selection.startDate !== '00-00-0000'
                    ? ranges.selection.startDate
                    : dayjs().tz(getUserTimeZone()).toDate();
            const selectedEndDate = ranges.selection.endDate
                ? ranges.selection.endDate
                : dayjs().tz(getUserTimeZone()).toDate();
            props.onChange({
                selection: {
                    startDate: dayjs(selectedStartState).format('YYYY-MM-DD'),
                    endDate: dayjs(selectedEndDate).format('YYYY-MM-DD'),
                },
            });
            let anySelected = false;
            for (let preset in presets) {
                if (
                    dayjs(ranges.selection.startDate).format('DD-MM-YYYY') ===
                        dayjs(presets[preset].range().startDate).format('DD-MM-YYYY') &&
                    dayjs(ranges.selection.endDate).format('DD-MM-YYYY') ===
                        dayjs(presets[preset].range().endDate).format('DD-MM-YYYY')
                ) {
                    anySelected = true;
                    if (props.allTimeFilter) {
                        if (presets[preset].name === 'all_time') {
                            props.setAllTime(true);
                        } else props.setAllTime(false);
                    }
                    setSelectedPreset(presets[preset]?.name);
                    break;
                }
            }
            if (!anySelected) {
                setSelectedPreset(null);
            }
            if (clicked) {
                props.onClose();
                setClicked(false);
            }
        },
        [clicked, presets, props],
    );

    useEffect(() => {
        const el = document.querySelectorAll('.rdrStaticRange');
        if (props.ctrail && el && !index) {
            el.forEach(element => {
                element.addEventListener('click', () => {
                    setIndex(true);
                });
            });
        } else if (!props.ctrail && el && !index) {
            el.forEach(element => {
                element.addEventListener('click', () => {
                    setIndex(true);
                });
            });
        } else if (!props.ctrail && index) {
            setClicked(true);
            setIndex(false);
        } else if (props.ctrail && index) {
            setClicked(true);
            setIndex(false);
        }
    }, [props.ctrail, index]);

    const getMinDate = useCallback(() => {
        if (props.minimumDate) {
            return dayjs(props.minimumDate).tz(getUserTimeZone()).toDate();
        } else if (props.onlyFutureDates) return dayjs().tz(getUserTimeZone()).toDate();
        else return addDays(new Date(), -8000);
    }, [props]);

    const getMaxDate = useCallback(() => {
        if (props.maximumDate) {
            return dayjs(props.maximumDate).tz(getUserTimeZone()).toDate();
        } else {
            return addDays(dayjs(props.startDate).tz(getUserTimeZone()).toDate(), 9000);
        }
    }, [props]);

    return (
        <>
            {props.ctrail && (
                <Popover
                    open={props.open}
                    anchorEl={props.reference}
                    onClose={props.onClose}
                    width={matche1023 ? 375 : 850}
                    placement={'top-center'}
                    customClass={'ALertPopover'}
                >
                    <Div style={{ textAlign: 'center' }}>Select another date range</Div>
                </Popover>
            )}
            {matche756 ? (
                <Dialog dialogType={'fullWidth'} open={props.open} onClose={props.onClose}>
                    <Div
                        className={
                            'DateRangePicker DateRangePickerPopover' +
                            (props.className ? props.className : '')
                        }
                    >
                        <TopBar DialogTopBar>
                            <Div className={'HeaderBar'}>
                                <H1 className={'HeaderBarTitle'} />
                                <Div className={'HeaderBarClose'}>
                                    <IconButton onClick={props.onClose}>
                                        <DCCrossClose />
                                    </IconButton>
                                </Div>
                            </Div>
                        </TopBar>
                        <Div className={'BodyBox'}>
                            <Div className={'BodyBoxInner'}>
                                <DateRange
                                    ranges={[selectionRange]}
                                    onChange={handleSelect}
                                    months={matche1023 ? 1 : 2}
                                    direction={matche1023 ? 'vertical' : 'horizontal'}
                                    minDate={getMinDate()}
                                    maxDate={getMaxDate()}
                                    staticRanges={presets}
                                />
                            </Div>
                        </Div>
                    </Div>
                </Dialog>
            ) : (
                <Popover
                    open={props.open}
                    anchorEl={props.reference}
                    onClose={props.onClose}
                    width={matche1023 ? 500 : 850}
                    placement={props.placement || 'bottom-start'}
                    withOutPadding
                    customClass={
                        'DateRangePickerPopover ArrowPopover ' +
                        (props.className ? props.className : '')
                    }
                >
                    <DateRange
                        ranges={[selectionRange]}
                        onChange={handleSelect}
                        months={matche1023 ? 1 : 2}
                        direction={matche1023 ? 'vertical' : 'horizontal'}
                        minDate={getMinDate()}
                        maxDate={getMaxDate()}
                        staticRanges={presets}
                    />
                </Popover>
            )}
        </>
    );
}

export default DateRangePicker;
