import React from 'react';

import { SVGIcon } from '../../UIKit/index';

export default function DCLeadAutomations(props) {
    return (
        <SVGIcon {...props} viewBox="0 0 505 362.692">
            <defs>
                <clipPath id="clip-path-lead-automations">
                    <path
                        id="Path_271"
                        data-name="Path 271"
                        d="M106.27,290c-.42-.16-1.57-1-3.46-3.61A72.69,72.69,0,0,1,93.16,266c-.83-3.13-.74-4.54-.6-5,.43.16,1.57,1,3.47,3.61s7.6,4.25,9.39,8-.55,9.31.26,12.34S106.42,289.59,106.27,290Z"
                        fill="none"
                    />
                </clipPath>
                <linearGradient
                    id="linear-lead-automations"
                    y1="0.5"
                    x2="1"
                    y2="0.5"
                    gradientUnits="objectBoundingBox"
                >
                    <stop offset="0" stopColor="#dbe2f3" />
                    <stop offset="1" stopColor="#eaeff8" />
                </linearGradient>
                <linearGradient
                    id="linear-lead-automations-2"
                    x1="0"
                    y1="0.501"
                    x2="1"
                    y2="0.501"
                    xlinkHref="#linear-lead-automations"
                />
                <linearGradient
                    id="linear-lead-automations-3"
                    x1="0"
                    y1="0.5"
                    x2="1"
                    y2="0.5"
                    xlinkHref="#linear-lead-automations"
                />
            </defs>
            <g id="Lead_Automations" transform="translate(-2.38 -70.838)">
                <ellipse
                    id="_Path_"
                    data-name="&lt;Path&gt;"
                    cx="250.11"
                    cy="27.6"
                    rx="250.11"
                    ry="27.6"
                    transform="translate(7.16 378.33)"
                    fill="#f4f5f6"
                />
                <path
                    id="Path_1"
                    data-name="Path 1"
                    d="M432.73,182.55H459.4a3.31,3.31,0,0,0,3.31-3.32h0a3.31,3.31,0,0,0-3.31-3.31H432.73a3.32,3.32,0,0,0-3.32,3.31h0a3.32,3.32,0,0,0,3.32,3.32Z"
                    fill="#e4e3e3"
                    opacity="0.6"
                />
                <path
                    id="Path_2"
                    data-name="Path 2"
                    d="M377.6,194.83h85.72a3.32,3.32,0,0,0,3.32-3.32h0a3.32,3.32,0,0,0-3.32-3.31H377.6a3.31,3.31,0,0,0-3.31,3.31h0a3.32,3.32,0,0,0,3.31,3.32Z"
                    fill="#e4e3e3"
                    opacity="0.6"
                />
                <path
                    id="Path_3"
                    data-name="Path 3"
                    d="M367.3,207.82H453a3.32,3.32,0,0,0,3.32-3.32h0a3.32,3.32,0,0,0-3.32-3.31H367.3a3.31,3.31,0,0,0-3.3,3.31h0A3.31,3.31,0,0,0,367.3,207.82Z"
                    fill="#e4e3e3"
                    opacity="0.53"
                />
                <path
                    id="Path_4"
                    data-name="Path 4"
                    d="M391.68,233.81H453a3.32,3.32,0,0,0,3.32-3.32h0a3.32,3.32,0,0,0-3.32-3.32H391.68a3.32,3.32,0,0,0-3.32,3.32h0A3.32,3.32,0,0,0,391.68,233.81Z"
                    fill="#e4e3e3"
                    opacity="0.53"
                />
                <path
                    id="Path_5"
                    data-name="Path 5"
                    d="M357.69,220.81h85.72a3.32,3.32,0,0,0,3.32-3.31h0a3.32,3.32,0,0,0-3.32-3.32H357.69a3.32,3.32,0,0,0-3.31,3.32h0a3.31,3.31,0,0,0,3.31,3.31Z"
                    fill="#e4e3e3"
                    opacity="0.6"
                />
                <path
                    id="Path_6"
                    data-name="Path 6"
                    d="M41.77,168.16H15.55a3.26,3.26,0,0,1-3.26-3.26h0a3.26,3.26,0,0,1,3.26-3.26H41.77A3.26,3.26,0,0,1,45,164.9h0a3.26,3.26,0,0,1-3.23,3.26Z"
                    fill="#e4e3e3"
                />
                <path
                    id="Path_7"
                    data-name="Path 7"
                    d="M106.54,185.34H22.26A3.26,3.26,0,0,1,19,182.08h0a3.26,3.26,0,0,1,3.26-3.26h84.28a3.26,3.26,0,0,1,3.26,3.26h0a3.26,3.26,0,0,1-3.26,3.26Z"
                    fill="#e4e3e3"
                />
                <path
                    id="Path_8"
                    data-name="Path 8"
                    d="M116.67,198.12H32.38a3.26,3.26,0,0,1-3.26-3.26h0a3.26,3.26,0,0,1,3.26-3.26h84.29a3.26,3.26,0,0,1,3.26,3.26h0a3.26,3.26,0,0,1-3.26,3.26Z"
                    fill="#e4e3e3"
                />
                <path
                    id="Path_9"
                    data-name="Path 9"
                    d="M92.7,223.67H32.38a3.26,3.26,0,0,1-3.26-3.26h0a3.26,3.26,0,0,1,3.26-3.26H92.7a3.26,3.26,0,0,1,3.3,3.26h0a3.26,3.26,0,0,1-3.3,3.26Z"
                    fill="#e4e3e3"
                />
                <path
                    id="Path_10"
                    data-name="Path 10"
                    d="M126.12,210.89H41.84a3.26,3.26,0,0,1-3.26-3.26h0a3.26,3.26,0,0,1,3.26-3.26h84.28a3.26,3.26,0,0,1,3.26,3.26h0a3.26,3.26,0,0,1-3.26,3.26Z"
                    fill="#e4e3e3"
                />
                <path
                    id="Path_11"
                    data-name="Path 11"
                    d="M222.18,330.27l-7.08,63.54h82.18l-10.7-63.54Z"
                    fill="#707272"
                />
                <path
                    id="Path_12"
                    data-name="Path 12"
                    d="M84.13,173.75v145.5a22.079,22.079,0,0,0,21.5,22.07c53.152,1.37,239,4.69,289.612,3.94a16.23,16.23,0,0,0,16-15.77c1-33.81,2.56-115.09-3.3-152.83a16.73,16.73,0,0,0-16-14.14c-44.52-1.43-241.56-6-292.13-4.81a16,16,0,0,0-15.68,16.04Z"
                    fill="#5c5c5c"
                />
                <path
                    id="Path_13"
                    data-name="Path 13"
                    d="M93.26,173V311.28a12.78,12.78,0,0,0,12.6,12.78L388,327.55a12.44,12.44,0,0,0,12.63-12.4l.47-137a9.09,9.09,0,0,0-8.88-9.13L103.4,163.18A9.86,9.86,0,0,0,93.26,173Z"
                    fill="#f4f5f6"
                />
                <path
                    id="Path_14"
                    data-name="Path 14"
                    d="M402.72,333.26h0L93.25,328a.43.43,0,1,1,.01-.86h0l309.45,5.27a.44.44,0,0,1,0,.87Z"
                    fill="#e4e3e3"
                />
                <path
                    id="Path_15"
                    data-name="Path 15"
                    d="M315,406.8H194.7c-3.26,0-5.72-2.32-5.09-4.82l1.55-6.11c.46-1.83,2.48-3.18,4.88-3.25L312,389c2.5-.08,4.72,1.25,5.27,3.16l2.78,9.72C320.77,404.41,318.31,406.8,315,406.8Z"
                    fill="#5c5c5c"
                />
                <path
                    id="Path_16"
                    data-name="Path 16"
                    d="M259.45,394.31a.43.43,0,0,1-.43-.43.44.44,0,0,1,.43-.44l5-.07a.45.45,0,0,1,.44.43.44.44,0,0,1-.43.44l-5,.07Z"
                    fill="#707272"
                />
                <path
                    id="Path_17"
                    data-name="Path 17"
                    d="M194.07,403.48H194a.42.42,0,0,1-.36-.49l.71-4.44a5,5,0,0,1,4.86-4.21l52.71-.78a.46.46,0,0,1,.44.43.44.44,0,0,1-.43.44l-52.7.77a4.14,4.14,0,0,0-4,3.48l-.71,4.44a.42.42,0,0,1-.45.36Z"
                    fill="#707272"
                />
                <path
                    id="Path_18"
                    data-name="Path 18"
                    d="M195.5,222.4a5.81,5.81,0,1,0-5.52-6.08A5.81,5.81,0,0,0,195.5,222.4Z"
                    fill="#e4e3e3"
                />
                <path
                    id="Path_19"
                    data-name="Path 19"
                    d="M227.51,213.86l-1.43-7.06a.49.49,0,0,0-.42-.39l-5.95-.8a26.151,26.151,0,0,0-4.73-7l1.51-5.82a.48.48,0,0,0-.21-.53l-6-4a.51.51,0,0,0-.58,0l-4.77,3.65a26.119,26.119,0,0,0-8.32-1.63l-3-5.18a.51.51,0,0,0-.53-.23L186,186.3a.48.48,0,0,0-.39.41l-.8,6a26.149,26.149,0,0,0-7,4.73l-5.82-1.51a.48.48,0,0,0-.53.21l-4,6a.5.5,0,0,0,0,.57l3.65,4.78a26.12,26.12,0,0,0-1.63,8.32l-5.18,3a.5.5,0,0,0-.23.52l1.43,7.07a.5.5,0,0,0,.41.39l6,.79a26.2,26.2,0,0,0,4.73,7l-1.51,5.81a.5.5,0,0,0,.21.54l6,4a.5.5,0,0,0,.57,0l4.78-3.65a26.4,26.4,0,0,0,8.32,1.63l3,5.17a.49.49,0,0,0,.52.24l7.07-1.43a.5.5,0,0,0,.39-.42l.79-5.95a26.2,26.2,0,0,0,7-4.73l5.81,1.51a.5.5,0,0,0,.54-.21l4-6a.51.51,0,0,0,0-.58l-3.65-4.77a26.4,26.4,0,0,0,1.63-8.32l5.17-3A.5.5,0,0,0,227.51,213.86Zm-16.66,12.56a18,18,0,0,1-21,7l.39-1.11a16.8,16.8,0,1,0-10.86-12.51c.06.33.14.66.23,1a17.625,17.625,0,0,0,.56,1.74h0l.09.22c.05.14.11.28.17.42l-1.08.47A17.8,17.8,0,0,1,178.2,220a18,18,0,0,1,8.95-19.32c.53-.29,1.07-.55,1.63-.79a18.279,18.279,0,0,1,3.48-1.08,18.009,18.009,0,0,1,18.54,27.61Zm-22-10.16a7,7,0,1,1,4,6.68,7,7,0,0,1-4-6.68Z"
                    fill="#e4e3e3"
                />
                <path
                    id="Path_20"
                    data-name="Path 20"
                    d="M147,254.32a3.51,3.51,0,0,0,1.91.2,3.6,3.6,0,1,0-3.88-4.79,3.47,3.47,0,0,0,0,2.7,3.51,3.51,0,0,0,1.97,1.89Z"
                    fill="#e4e3e3"
                />
                <path
                    id="Path_21"
                    data-name="Path 21"
                    d="M169.54,251.12l-3.6-1.81a17.75,17.75,0,0,0-1.47-5.51l2.23-3.36a.31.31,0,0,0,0-.38l-2.94-3.85a.32.32,0,0,0-.37-.11l-3.83,1.27a17.479,17.479,0,0,0-4.92-2.86l-.8-3.95a.34.34,0,0,0-.28-.27l-4.8-.64a.34.34,0,0,0-.34.18l-1.81,3.61a17.68,17.68,0,0,0-5.5,1.46l-3.36-2.23a.35.35,0,0,0-.39,0l-3.85,2.94a.35.35,0,0,0-.11.37l1.27,3.83a17.77,17.77,0,0,0-2.86,4.93l-4,.8a.32.32,0,0,0-.26.28l-.64,4.79a.34.34,0,0,0,.18.35l3.6,1.8a17.48,17.48,0,0,0,1.47,5.51L130,261.64a.33.33,0,0,0,0,.38l2.94,3.85a.32.32,0,0,0,.37.11l3.83-1.27a17.479,17.479,0,0,0,4.92,2.86l.8,3.95a.34.34,0,0,0,.28.27l4.8.64a.34.34,0,0,0,.34-.18l1.81-3.61a17.68,17.68,0,0,0,5.5-1.46l3.36,2.23a.35.35,0,0,0,.39,0l3.84-2.94a.34.34,0,0,0,.12-.37l-1.3-3.84a17.5,17.5,0,0,0,2.85-4.93l4-.8a.32.32,0,0,0,.26-.28l.64-4.79A.32.32,0,0,0,169.54,251.12Zm-8.78,1.51a12.45,12.45,0,0,1-20.09,8.09l1-1.19a11.183,11.183,0,1,0-4.02-9.99,10.8,10.8,0,0,0-.07,2.2.49.49,0,0,0,0,.12L136,252a12.3,12.3,0,0,1,.08-2.64.76.76,0,0,1,0-.21,12.45,12.45,0,0,1,10.18-10.41c.42-.07.83-.12,1.26-.15h0a12.44,12.44,0,0,1,13.19,14.06Zm-17.13-3.47a5,5,0,0,1,2.71-2.77,5.149,5.149,0,0,1,1.14-.34,5.06,5.06,0,1,1-3.85,3.11Z"
                    fill="#e4e3e3"
                />
                <path
                    id="Path_22"
                    data-name="Path 22"
                    d="M369.65,224.48l-1-4.95a.34.34,0,0,0-.29-.28l-4.17-.55a18.18,18.18,0,0,0-3.32-4.93l1.06-4.08a.34.34,0,0,0-.14-.37l-4.21-2.79a.33.33,0,0,0-.4,0l-3.35,2.56A18.1,18.1,0,0,0,348,208l-2.13-3.62a.34.34,0,0,0-.36-.17l-4.95,1a.35.35,0,0,0-.28.3l-.55,4.17a18.38,18.38,0,0,0-4.93,3.31l-4.08-1.06a.36.36,0,0,0-.37.15l-2.8,4.21a.35.35,0,0,0,0,.4l2.55,3.34a18.411,18.411,0,0,0-1.14,5.83l-3.63,2.13a.36.36,0,0,0-.16.37l1,4.95a.35.35,0,0,0,.29.27l4.17.56A18.411,18.411,0,0,0,334,239l-1.06,4.07a.35.35,0,0,0,.15.38l4.2,2.79a.34.34,0,0,0,.4,0l3.35-2.56a18.14,18.14,0,0,0,5.83,1.14l2.13,3.63a.34.34,0,0,0,.37.16l5-1a.34.34,0,0,0,.27-.29l.56-4.17a18.34,18.34,0,0,0,4.93-3.32l4.07,1.06a.36.36,0,0,0,.38-.14l2.79-4.21a.36.36,0,0,0,0-.4l-2.56-3.34a18.47,18.47,0,0,0,1.14-5.83l3.63-2.14a.33.33,0,0,0,.07-.35Zm-11.35,9a13,13,0,0,1-15.14,5.06l.53-1.5a11.4,11.4,0,1,0-7.36-8.49,11.67,11.67,0,0,0,.67,2.2l.06.12-1.47.63a13.871,13.871,0,0,1-.83-2.63,2.051,2.051,0,0,0,0-.22,13,13,0,0,1,6.51-13.72,10.74,10.74,0,0,1,1.18-.58h0a12.769,12.769,0,0,1,2.5-.78,13,13,0,0,1,15.29,10.16,12.87,12.87,0,0,1-1.94,9.76Zm-16.15-7.35a5.24,5.24,0,0,1,1.72-3.66,5.461,5.461,0,0,1,1-.72,5.29,5.29,0,1,1-1.37,8.18,5.21,5.21,0,0,1-1.35-3.79Z"
                    fill="#e4e3e3"
                />
                <path
                    id="Path_23"
                    data-name="Path 23"
                    d="M347.24,230.08a3.552,3.552,0,1,1,.04,0Z"
                    fill="#e4e3e3"
                />
                <path
                    id="Path_24"
                    data-name="Path 24"
                    d="M287.5,270.49a7.54,7.54,0,0,0,3.63.9h0a7.466,7.466,0,1,0-3.63-.9Z"
                    fill="#f4f5f6"
                />
                <path
                    id="Path_25"
                    data-name="Path 25"
                    d="M331.2,268.79l-6.41-4.23a33.22,33.22,0,0,0-1.53-10.73l5-5.85a.64.64,0,0,0,.07-.73l-4.69-7.94A.62.62,0,0,0,323,239l-7.53,1.53a33.43,33.43,0,0,0-8.66-6.51l-.62-7.65a.62.62,0,0,0-.47-.56l-8.92-2.3a.61.61,0,0,0-.68.27l-4.24,6.4a33.22,33.22,0,0,0-10.73,1.53l-5.85-5a.62.62,0,0,0-.73-.06l-7.93,4.68a.65.65,0,0,0-.3.67l1.54,7.53a33.69,33.69,0,0,0-6.52,8.66l-7.65.62a.64.64,0,0,0-.56.47l-2.29,8.92a.62.62,0,0,0,.26.68l6.41,4.24A33.491,33.491,0,0,0,259,273.89l-5,5.85a.63.63,0,0,0-.06.73l4.69,7.93a.63.63,0,0,0,.67.3l7.52-1.54a33.741,33.741,0,0,0,8.67,6.52l.61,7.65a.63.63,0,0,0,.47.56l8.93,2.29a.63.63,0,0,0,.68-.26l4.23-6.41A33.491,33.491,0,0,0,301.16,296l5.85,5a.63.63,0,0,0,.73.06l7.94-4.69a.61.61,0,0,0,.29-.67l-1.53-7.52a33.481,33.481,0,0,0,6.51-8.67l7.65-.61a.62.62,0,0,0,.56-.47l2.3-8.93A.63.63,0,0,0,331.2,268.79Zm-47.77-9.13a8.771,8.771,0,0,1,16.12,1.72,8.12,8.12,0,0,1-.71,6.43,8.56,8.56,0,0,1-7.71,4.34h0a8.391,8.391,0,0,1-7.7-12.49Zm29.89,9.71h0l.17.19.18.16h-.27v-.4h0v.42h0v.09h0a.049.049,0,0,0-.05.05h.51c-2.53,9.91-12.15,16.49-22.43,16.49h0a14.67,14.67,0,0,1-5.7-.76h0a22.33,22.33,0,0,1-17.14-21.91,21.82,21.82,0,0,1,.73-5.61,22.61,22.61,0,0,1,6.05-10.5.58.58,0,0,1,.83,0,.57.57,0,0,1,.17.42.59.59,0,0,1-.17.43,21.61,21.61,0,0,0-5.74,10,20.672,20.672,0,0,0-.69,5.3,21.34,21.34,0,0,0,16.26,20.83h0l.24.11a22.11,22.11,0,0,0,5.63.83h0a21.13,21.13,0,0,0,20.68-16.15h-.11v-.13h.24A19.689,19.689,0,0,0,313,264a21.69,21.69,0,0,0-16.31-20.95,21.37,21.37,0,0,0-4.48-.67.52.52,0,0,1-.49-.59h0c.26-.33.18-.58.51-.57a23.3,23.3,0,0,1,4.75.71,22.58,22.58,0,0,1,17.11,21.91A21,21,0,0,1,313.32,269.37Z"
                    fill="#e4e3e3"
                />
                <circle
                    id="Ellipse_1"
                    data-name="Ellipse 1"
                    cx="21.6"
                    cy="21.6"
                    r="21.6"
                    transform="translate(245.221 359.288) rotate(-89.74)"
                    fill="#707272"
                />
                <circle
                    id="Ellipse_2"
                    data-name="Ellipse 2"
                    cx="20.88"
                    cy="20.88"
                    r="20.88"
                    transform="translate(245.936 358.565) rotate(-89.73)"
                    fill="#d6d6d6"
                />
                <path
                    id="Path_26"
                    data-name="Path 26"
                    d="M249.69,329.32a.17.17,0,0,1-.1,0,.23.23,0,0,1-.11-.31,19.59,19.59,0,0,1,20.94-10.41.233.233,0,1,1-.08.46,19.08,19.08,0,0,0-20.45,10.16.23.23,0,0,1-.2.1Z"
                    fill="#707272"
                />
                <circle
                    id="Ellipse_3"
                    data-name="Ellipse 3"
                    cx="17.67"
                    cy="17.67"
                    r="17.67"
                    transform="matrix(1, -0.003, 0.003, 1, 249.622, 320.618)"
                    fill="#707272"
                />
                <path
                    id="Path_27"
                    data-name="Path 27"
                    d="M265.62,351.35v-1.92a15.589,15.589,0,0,1-5.39-1.17,1.1,1.1,0,0,1-.61-1.31l.48-1.79a1.13,1.13,0,0,1,1.53-.74,12.9,12.9,0,0,0,5,1.05c1.93,0,3.37-.65,3.37-2s-1.12-1.93-3.8-2.65c-3.83-1.05-6.58-2.72-6.58-6.09,0-3,2.23-5.37,6.09-6v-1.92a1.12,1.12,0,0,1,1.12-1.12h1.13a1.12,1.12,0,0,1,1.12,1.12v1.73a14.37,14.37,0,0,1,4.27.76,1.13,1.13,0,0,1,.7,1.35l-.45,1.66a1.12,1.12,0,0,1-1.47.75,11.76,11.76,0,0,0-4.2-.76c-2.29,0-2.94.95-2.94,1.8,0,1.05,1.37,1.77,4.35,2.68,4.25,1.28,6,3,6,6.22,0,2.91-2.16,5.53-6.35,6.18v2.15a1.12,1.12,0,0,1-1.12,1.12h-1.13a1.12,1.12,0,0,1-1.12-1.1Z"
                    fill="#f4f5f6"
                />
                <circle
                    id="Ellipse_4"
                    data-name="Ellipse 4"
                    cx="18.31"
                    cy="18.31"
                    r="18.31"
                    transform="translate(297.161 321.555) rotate(-80.78)"
                    fill="#707272"
                />
                <circle
                    id="Ellipse_5"
                    data-name="Ellipse 5"
                    cx="17.7"
                    cy="17.7"
                    r="17.7"
                    transform="translate(295.02 315.98) rotate(-67.5)"
                    fill="#d6d6d6"
                />
                <path
                    id="Path_28"
                    data-name="Path 28"
                    d="M303.56,299.24a.15.15,0,0,1-.09,0,.2.2,0,0,1-.09-.26,16.55,16.55,0,0,1,17.75-8.82.19.19,0,0,1,.16.22.2.2,0,0,1-.23.16,16.568,16.568,0,0,0-2.91-.26,16.08,16.08,0,0,0-14.42,8.88A.19.19,0,0,1,303.56,299.24Z"
                    fill="#707272"
                />
                <circle
                    id="Ellipse_6"
                    data-name="Ellipse 6"
                    cx="14.98"
                    cy="14.98"
                    r="14.98"
                    transform="translate(300.995 294.913) rotate(-10.9)"
                    fill="#707272"
                />
                <path
                    id="Path_29"
                    data-name="Path 29"
                    d="M317.07,317.92v-1.63a13.47,13.47,0,0,1-4.58-1,1,1,0,0,1-.52-1.11l.42-1.52a.94.94,0,0,1,1.29-.62,11.11,11.11,0,0,0,4.22.89c1.64,0,2.86-.56,2.86-1.7s-.95-1.63-3.22-2.24c-3.25-.89-5.58-2.3-5.58-5.16,0-2.58,1.89-4.55,5.16-5.08v-1.63a1,1,0,0,1,.95-.95h1a1,1,0,0,1,1,.95v1.47a11.929,11.929,0,0,1,3.62.65.94.94,0,0,1,.59,1.14l-.38,1.4a.94.94,0,0,1-1.25.64,10,10,0,0,0-3.55-.64c-1.94,0-2.5.8-2.5,1.52,0,.89,1.17,1.5,3.69,2.28,3.61,1.08,5.1,2.58,5.1,5.27,0,2.46-1.83,4.68-5.38,5.24v1.82a.94.94,0,0,1-.95.95h-1a.94.94,0,0,1-.99-.94Z"
                    fill="#f4f5f6"
                />
                <circle
                    id="Ellipse_7"
                    data-name="Ellipse 7"
                    cx="12.98"
                    cy="12.98"
                    r="12.98"
                    transform="translate(218.823 328.739) rotate(-85.27)"
                    fill="#707272"
                />
                <circle
                    id="Ellipse_8"
                    data-name="Ellipse 8"
                    cx="12.55"
                    cy="12.55"
                    r="12.55"
                    transform="translate(218.445 327.269) rotate(-80.78)"
                    fill="#d6d6d6"
                />
                <path
                    id="Path_30"
                    data-name="Path 30"
                    d="M222.49,311.81h-.06a.13.13,0,0,1-.06-.18,11.66,11.66,0,0,1,10.47-6.45,12.365,12.365,0,0,1,2.11.19.137.137,0,1,1-.05.27,11.859,11.859,0,0,0-2.06-.18,11.4,11.4,0,0,0-10.23,6.29A.13.13,0,0,1,222.49,311.81Z"
                    fill="#707272"
                />
                <circle
                    id="Ellipse_9"
                    data-name="Ellipse 9"
                    cx="10.62"
                    cy="10.62"
                    r="10.62"
                    transform="translate(222.49 306.53)"
                    fill="#707272"
                />
                <path
                    id="Path_31"
                    data-name="Path 31"
                    d="M232.07,325.05v-1.16a9.4,9.4,0,0,1-3.24-.7.66.66,0,0,1-.37-.79l.29-1.08a.68.68,0,0,1,.91-.44,7.7,7.7,0,0,0,3,.63c1.16,0,2-.39,2-1.2s-.67-1.16-2.28-1.59c-2.3-.63-4-1.63-4-3.66,0-1.82,1.34-3.22,3.66-3.59v-1.16a.67.67,0,0,1,.67-.67h.68a.67.67,0,0,1,.67.67v1a8.29,8.29,0,0,1,2.57.46.67.67,0,0,1,.42.81l-.27,1a.67.67,0,0,1-.89.46,6.86,6.86,0,0,0-2.52-.46c-1.37,0-1.77.57-1.77,1.08s.83,1.06,2.62,1.61c2.56.77,3.62,1.83,3.62,3.74,0,1.75-1.3,3.32-3.82,3.71v1.3a.67.67,0,0,1-.67.67h-.68a.67.67,0,0,1-.6-.64Z"
                    fill="#f4f5f6"
                />
                <circle
                    id="Ellipse_10"
                    data-name="Ellipse 10"
                    cx="11.79"
                    cy="11.79"
                    r="11.79"
                    transform="matrix(0.045, -0.999, 0.999, 0.045, 299.333, 361.145)"
                    fill="#707272"
                />
                <circle
                    id="Ellipse_11"
                    data-name="Ellipse 11"
                    cx="11.39"
                    cy="11.39"
                    r="11.39"
                    transform="translate(298.947 359.812) rotate(-82.98)"
                    fill="#d6d6d6"
                />
                <path
                    id="Path_32"
                    data-name="Path 32"
                    d="M302.24,345.27h-.06a.13.13,0,0,1-.06-.17,10.61,10.61,0,0,1,9.51-5.85,11.144,11.144,0,0,1,1.92.17.12.12,0,0,1,.1.15.13.13,0,0,1-.14.1,9.89,9.89,0,0,0-1.88-.17,10.35,10.35,0,0,0-9.28,5.71.12.12,0,0,1-.11.06Z"
                    fill="#707272"
                />
                <circle
                    id="Ellipse_12"
                    data-name="Ellipse 12"
                    cx="9.64"
                    cy="9.64"
                    r="9.64"
                    transform="translate(300.273 357.276) rotate(-76.72)"
                    fill="#707272"
                />
                <path
                    id="Path_33"
                    data-name="Path 33"
                    d="M310.93,357.3v-1.05a8.39,8.39,0,0,1-2.94-.64.61.61,0,0,1-.34-.71l.27-1a.61.61,0,0,1,.83-.4,7.07,7.07,0,0,0,2.72.57c1,0,1.84-.36,1.84-1.09s-.61-1.05-2.07-1.45c-2.09-.57-3.59-1.48-3.59-3.32,0-1.66,1.21-2.93,3.32-3.27v-1a.61.61,0,0,1,.61-.62h.62a.62.62,0,0,1,.61.62v.94a7.6,7.6,0,0,1,2.33.42.59.59,0,0,1,.38.73l-.24.91a.63.63,0,0,1-.81.41,6.23,6.23,0,0,0-2.29-.42c-1.25,0-1.6.52-1.6,1s.75,1,2.37,1.47c2.32.69,3.29,1.66,3.29,3.39s-1.18,3-3.47,3.38v1.17a.61.61,0,0,1-.61.61h-.62a.61.61,0,0,1-.61-.65Z"
                    fill="#f4f5f6"
                />
                <path
                    id="Path_34"
                    data-name="Path 34"
                    d="M482,407.28,416.74,281.42a1.442,1.442,0,0,1,2.56-1.33L484.56,406a1.46,1.46,0,0,1-.62,1.95,1.57,1.57,0,0,1-.67.16,1.44,1.44,0,0,1-1.27-.83Z"
                    fill="#707272"
                />
                <path
                    id="Path_35"
                    data-name="Path 35"
                    d="M451,408.24,395.22,280.09a1.44,1.44,0,1,1,2.64-1.15l55.78,128.15a1.47,1.47,0,0,1,0,1.11,1.42,1.42,0,0,1-.77.79,1.389,1.389,0,0,1-.57.12A1.44,1.44,0,0,1,451,408.24Z"
                    fill="#707272"
                />
                <path
                    id="Path_36"
                    data-name="Path 36"
                    d="M407.08,304.48H429.7a.9.9,0,1,0,0-1.8H407.08a.9.9,0,0,0,0,1.8Z"
                    fill="#707272"
                />
                <path
                    id="Path_37"
                    data-name="Path 37"
                    d="M413.12,318.5h22.63a.91.91,0,0,0,0-1.81H413.12a.91.91,0,0,0,0,1.81Z"
                    fill="#707272"
                />
                <path
                    id="Path_38"
                    data-name="Path 38"
                    d="M421.1,335.28h24.06a.9.9,0,0,0,0-1.8H421.1a.9.9,0,1,0,0,1.8Z"
                    fill="#707272"
                />
                <path
                    id="Path_39"
                    data-name="Path 39"
                    d="M401.07,290.89h21.58a.91.91,0,0,0,0-1.81H401.07a.91.91,0,0,0,0,1.81Z"
                    fill="#707272"
                />
                <path
                    id="Path_40"
                    data-name="Path 40"
                    d="M429,354.59h26.14a.91.91,0,1,0,0-1.81H429a.91.91,0,0,0,0,1.81Z"
                    fill="#707272"
                />
                <path
                    id="Path_41"
                    data-name="Path 41"
                    d="M435.18,369.57h28.05a.91.91,0,0,0,0-1.81H435.18a.91.91,0,0,0,0,1.81Z"
                    fill="#707272"
                />
                <path
                    id="Path_42"
                    data-name="Path 42"
                    d="M440.7,351.36c2.88-2.52,9.09-4.53,9.09-4.53l3.73.08a9.31,9.31,0,0,1,1.56,4.43,10,10,0,0,1-4.43.9,36.249,36.249,0,0,1-9.95-.88Z"
                    fill="#707272"
                />
                <path
                    id="Path_43"
                    data-name="Path 43"
                    d="M440.7,351.36a36.25,36.25,0,0,0,9.95.88,10,10,0,0,0,4.43-.9v.3a1.08,1.08,0,0,1-.77,1.18c-3.41,1.05-10.91.71-13.84.53a.77.77,0,0,1-.55-1.25,6.463,6.463,0,0,1,.78-.74Z"
                    fill="#5c5c5c"
                />
                <path
                    id="Path_44"
                    data-name="Path 44"
                    d="M453.22,343.69v3a1.21,1.21,0,0,1-1,1.18,4.57,4.57,0,0,1-2.09,0,1.13,1.13,0,0,1-.86-1l-.38-3.18Z"
                    fill="#d6d6d6"
                />
                <path
                    id="Path_45"
                    data-name="Path 45"
                    d="M434.34,328.48c1.16-2.58.83-3.74,3.12-3.48a3.17,3.17,0,0,1,.7.15,2.12,2.12,0,0,1,0,1.34,25.53,25.53,0,0,1-4,7.32,2.52,2.52,0,0,1-1.93.95H429.7a16.11,16.11,0,0,0,4.64-6.28Z"
                    fill="#5c5c5c"
                />
                <path
                    id="Path_46"
                    data-name="Path 46"
                    d="M429.52,331c1.94-1.37,2.66-7,2.66-7l3-1.52,2.26,1.63a2.15,2.15,0,0,1,.73,1,3.173,3.173,0,0,0-.7-.15c-2.29-.26-2,.9-3.12,3.48a16.11,16.11,0,0,1-4.66,6.32h-1.74a.94.94,0,0,1-.76-1.5A11.311,11.311,0,0,1,429.52,331Z"
                    fill="#707272"
                />
                <path
                    id="Path_47"
                    data-name="Path 47"
                    d="M433.37,319.7l1.8,2.81a.82.82,0,0,1-.18,1.09,2.69,2.69,0,0,1-2.81.43l-2.9-2Z"
                    fill="#d6d6d6"
                />
                <path
                    id="Path_48"
                    data-name="Path 48"
                    d="M446.92,290.06s.95,2.67,2.33,12.08,2.28,16.24,3,20A163.657,163.657,0,0,1,454,343.17a1.28,1.28,0,0,1-1.33,1.27l-3.55-.11a1.13,1.13,0,0,1-1.1-.95c-.94-5.53-7.44-34.07-11.36-42.28,0,0-10.43,2.95-13,4.14,0,0,3.37,1.34,10.25,14.26,0,0-3,2.36-5.08,2.85l-16.66-13.81c-1.6-1.31-3.05-3.56,1-7.67s16.53-10.58,16.53-10.58Z"
                    fill="#5c5c5c"
                />
                <path
                    id="Path_49"
                    data-name="Path 49"
                    d="M437.23,301.4h.07L441,300a4.68,4.68,0,0,0,2.65-4.17.18.18,0,0,0-.18-.18.17.17,0,0,0-.18.18,4.31,4.31,0,0,1-2.44,3.84l-3.72,1.43a.19.19,0,0,0-.09.24.18.18,0,0,0,.19.06Z"
                    opacity="0.5"
                />
                <path
                    id="Path_50"
                    data-name="Path 50"
                    d="M413.2,289.27s-3.91-3-5.7-2.41a7.4,7.4,0,0,0-3.09,2.22h3.94s.94,1.81,0,1.81H407S410.63,294.74,413.2,289.27Z"
                    fill="#d6d6d6"
                />
                <path
                    id="Path_51"
                    data-name="Path 51"
                    d="M419.83,261.31s-4.59,18.17-10.82,25.48a8.77,8.77,0,0,0,4.19,2.48s9.56-8.38,12.47-14.2S419.83,261.31,419.83,261.31Z"
                    fill="#707272"
                />
                <path
                    id="Path_52"
                    data-name="Path 52"
                    d="M426,244l4.56,8.35h-7.2a14.7,14.7,0,0,0-2.48-6.11Z"
                    fill="#d6d6d6"
                />
                <path
                    id="Path_53"
                    data-name="Path 53"
                    d="M419.06,234.07s-2.29,5.25-2.44,8-.75,4.55,2.44,5.1a6.77,6.77,0,0,0,7.33-3.78s2.28-4.23,1.77-6S419.06,234.07,419.06,234.07Z"
                    fill="#d6d6d6"
                />
                <path
                    id="Path_54"
                    data-name="Path 54"
                    d="M425.3,241.43h-.4s1.66-3.41.2-4-7.24-1.78-8.49-3.83a3.82,3.82,0,0,1,1.47-5.25.23.23,0,0,1,.28.11l.13.25a.22.22,0,0,0,.4,0h0a.22.22,0,0,1,.39-.06c.38.61,1.41,1.61,4.19,2.12,3.93.73,6.53,2.32,6.6,5a.22.22,0,0,0,.33.18c.29-.18.76-.27,1.38.26,1.08.91.64,3.26-1.73,5.2a.22.22,0,0,0,0,.35c.43.28,1.07.94-.59,1.83a4.38,4.38,0,0,1-3.08.71.21.21,0,0,1-.16-.22l.14-2.41a.21.21,0,0,0-.21-.23Z"
                    fill="#5c5c5c"
                />
                <path
                    id="Path_55"
                    data-name="Path 55"
                    d="M425.2,241.93s.87-3.36,2.38-2.41-.78,3.76-2,3.88Z"
                    fill="#d6d6d6"
                />
                <path
                    id="Path_56"
                    data-name="Path 56"
                    d="M431.56,251.71s-2-3.35-15.35-2.53c-6.93.43-22.49-5.6-26-8.64a6.5,6.5,0,0,0-4.34,2.17s14.38,15.17,31.14,17.11c0,0,12.72,26.22,12.63,30.47a20.93,20.93,0,0,0,17.8,1.44s.31-7.28-3.69-17.35A113.131,113.131,0,0,0,431.56,251.71Z"
                    fill="#707272"
                />
                <path
                    id="Path_57"
                    data-name="Path 57"
                    d="M425.52,260.75a.18.18,0,0,0,.18-.16.19.19,0,0,0-.17-.2c-.06,0-6.45-.52-7.45-.63a.176.176,0,1,0-.04.35c1,.12,7.4.64,7.46.64Z"
                    fill="#5c5c5c"
                />
                <path
                    id="Path_58"
                    data-name="Path 58"
                    d="M386.17,242c-.11.43,1.44,1,2.28-.74,0-.82.37-4.37-.15-5a65.534,65.534,0,0,0-8-7.77,1.22,1.22,0,0,0-.09,1.69l2.56,2.47a11.82,11.82,0,0,0-1.33,2.9,3.41,3.41,0,0,0,.43,2.53,29.8,29.8,0,0,0,1.68,2.52,3.38,3.38,0,0,0,2.62,1.4Z"
                    fill="#d6d6d6"
                />
                <circle
                    id="Ellipse_13"
                    data-name="Ellipse 13"
                    cx="27.49"
                    cy="27.49"
                    r="27.49"
                    transform="matrix(0.081, -0.997, 0.997, 0.081, 218.525, 142.707)"
                    fill="#707272"
                />
                <circle
                    id="Ellipse_14"
                    data-name="Ellipse 14"
                    cx="26.58"
                    cy="26.58"
                    r="26.58"
                    transform="matrix(0.507, -0.862, 0.862, 0.507, 211.765, 126.971)"
                    fill="#e4e3e3"
                />
                <path
                    id="Path_59"
                    data-name="Path 59"
                    d="M223.65,116.51h0a.28.28,0,0,1-.27-.29,27.084,27.084,0,0,1,.36-3.12.284.284,0,1,1,.56.1,25.9,25.9,0,0,0-.36,3,.27.27,0,0,1-.29.31Z"
                    fill="#707272"
                />
                <path
                    id="Path_60"
                    data-name="Path 60"
                    d="M226.24,106.77a.31.31,0,0,1-.13,0,.27.27,0,0,1-.12-.37,24.87,24.87,0,0,1,26.64-13.25.28.28,0,1,1-.1.55,24.49,24.49,0,0,0-4.37-.39,24.15,24.15,0,0,0-21.67,13.34A.27.27,0,0,1,226.24,106.77Z"
                    fill="#707272"
                />
                <circle
                    id="Ellipse_15"
                    data-name="Ellipse 15"
                    cx="22.5"
                    cy="22.5"
                    r="22.5"
                    transform="translate(221.505 101.64) rotate(-13.86)"
                    fill="#707272"
                />
                <path
                    id="Path_61"
                    data-name="Path 61"
                    d="M255.33,109.22c0,3.94-3.19,8.19-7.14,8.19s-7.13-4.25-7.13-8.19a6.8,6.8,0,0,1,7.13-6.74C252.14,102.48,255.33,105.28,255.33,109.22Z"
                    fill="#f4f5f6"
                />
                <path
                    id="Path_62"
                    data-name="Path 62"
                    d="M239.34,132.59h17.42a4.21,4.21,0,0,0,4-2.93l.94-2.93a4.21,4.21,0,0,0-2.54-5.24c-7.09-2.6-14.42-2.26-21.93.23a4.19,4.19,0,0,0-2.69,5.09l.72,2.66a4.23,4.23,0,0,0,4.08,3.12Z"
                    fill="#f4f5f6"
                />
                <circle
                    id="Ellipse_16"
                    data-name="Ellipse 16"
                    cx="17.54"
                    cy="17.54"
                    r="17.54"
                    transform="matrix(0.202, -0.979, 0.979, 0.202, 294.184, 151.045)"
                    fill="#707272"
                />
                <circle
                    id="Ellipse_17"
                    data-name="Ellipse 17"
                    cx="16.96"
                    cy="16.96"
                    r="16.96"
                    transform="translate(291.661 143.282) rotate(-59.1)"
                    fill="#e4e3e3"
                />
                <path
                    id="Path_63"
                    data-name="Path 63"
                    d="M299.28,136.76h0a.17.17,0,0,1-.17-.18,14.6,14.6,0,0,1,.23-2,.178.178,0,1,1,.35.06,15.791,15.791,0,0,0-.23,1.94.17.17,0,0,1-.18.18Z"
                    fill="#707272"
                />
                <path
                    id="Path_64"
                    data-name="Path 64"
                    d="M300.92,130.55h-.08a.18.18,0,0,1-.07-.24,15.77,15.77,0,0,1,14.14-8.71,16.1,16.1,0,0,1,2.86.26.18.18,0,0,1-.07.35,15.242,15.242,0,0,0-2.79-.25,15.43,15.43,0,0,0-13.83,8.51A.18.18,0,0,1,300.92,130.55Z"
                    fill="#707272"
                />
                <circle
                    id="Ellipse_18"
                    data-name="Ellipse 18"
                    cx="14.35"
                    cy="14.35"
                    r="14.35"
                    transform="translate(300.93 123.44)"
                    fill="#707272"
                />
                <path
                    id="Path_65"
                    data-name="Path 65"
                    d="M319.49,132.11c0,2.51-2,5.23-4.56,5.23s-4.55-2.72-4.55-5.23a4.56,4.56,0,0,1,9.11,0Z"
                    fill="#f4f5f6"
                />
                <path
                    id="Path_66"
                    data-name="Path 66"
                    d="M309.28,147H320.4a2.7,2.7,0,0,0,2.56-1.87l.6-1.87a2.68,2.68,0,0,0-1.63-3.34,20.64,20.64,0,0,0-14,.15,2.68,2.68,0,0,0-1.71,3.25l.46,1.69a2.67,2.67,0,0,0,2.6,1.99Z"
                    fill="#f4f5f6"
                />
                <circle
                    id="Ellipse_19"
                    data-name="Ellipse 19"
                    cx="17.54"
                    cy="17.54"
                    r="17.54"
                    transform="translate(163.86 117.66)"
                    fill="#707272"
                />
                <circle
                    id="Ellipse_20"
                    data-name="Ellipse 20"
                    cx="16.96"
                    cy="16.96"
                    r="16.96"
                    transform="translate(164.44 118.24)"
                    fill="#e4e3e3"
                />
                <path
                    id="Path_67"
                    data-name="Path 67"
                    d="M165.77,134.54h0a.18.18,0,0,1-.17-.18,16.374,16.374,0,0,1,.23-2,.18.18,0,0,1,.21-.15.17.17,0,0,1,.14.21,15.782,15.782,0,0,0-.22,1.94.18.18,0,0,1-.19.18Z"
                    fill="#707272"
                />
                <path
                    id="Path_68"
                    data-name="Path 68"
                    d="M167.42,128.33h-.08a.17.17,0,0,1-.08-.24,15.77,15.77,0,0,1,14.14-8.71,16.1,16.1,0,0,1,2.86.26.178.178,0,1,1-.06.35,15.52,15.52,0,0,0-16.62,8.26A.19.19,0,0,1,167.42,128.33Z"
                    fill="#707272"
                />
                <circle
                    id="Ellipse_21"
                    data-name="Ellipse 21"
                    cx="14.35"
                    cy="14.35"
                    r="14.35"
                    transform="translate(167.42 121.21)"
                    fill="#707272"
                />
                <path
                    id="Path_69"
                    data-name="Path 69"
                    d="M186,129.89c0,2.51-2,5.23-4.55,5.23s-4.56-2.72-4.56-5.23a4.56,4.56,0,0,1,9.11,0Z"
                    fill="#f4f5f6"
                />
                <path
                    id="Path_70"
                    data-name="Path 70"
                    d="M175.78,144.8h11.11a2.69,2.69,0,0,0,2.56-1.87l.6-1.87a2.68,2.68,0,0,0-1.62-3.34,20.64,20.64,0,0,0-14,.15,2.68,2.68,0,0,0-1.72,3.25l.46,1.69a2.69,2.69,0,0,0,2.61,1.99Z"
                    fill="#f4f5f6"
                />
                <circle
                    id="Ellipse_22"
                    data-name="Ellipse 22"
                    cx="17.54"
                    cy="17.54"
                    r="17.54"
                    transform="translate(174.214 107.755) rotate(-80.99)"
                    fill="#707272"
                />
                <circle
                    id="Ellipse_23"
                    data-name="Ellipse 23"
                    cx="16.96"
                    cy="16.96"
                    r="16.96"
                    transform="translate(171.941 84.407) rotate(-23.58)"
                    fill="#e4e3e3"
                />
                <path
                    id="Path_71"
                    data-name="Path 71"
                    d="M178.64,92.52h0a.18.18,0,0,1-.17-.19,14.79,14.79,0,0,1,.23-2,.17.17,0,0,1,.21-.14.18.18,0,0,1,.14.21,15.56,15.56,0,0,0-.22,1.94A.19.19,0,0,1,178.64,92.52Z"
                    fill="#707272"
                />
                <path
                    id="Path_72"
                    data-name="Path 72"
                    d="M180.29,86.3h-.08a.19.19,0,0,1-.08-.25,15.76,15.76,0,0,1,14.14-8.7,16.089,16.089,0,0,1,2.86.25.17.17,0,0,1,.14.21.19.19,0,0,1-.21.15,15.24,15.24,0,0,0-2.79-.26,15.41,15.41,0,0,0-13.82,8.52.18.18,0,0,1-.16.08Z"
                    fill="#707272"
                />
                <circle
                    id="Ellipse_24"
                    data-name="Ellipse 24"
                    cx="14.391"
                    cy="14.391"
                    r="14.391"
                    transform="matrix(0.16, -0.987, 0.987, 0.16, 178.915, 105.243)"
                    fill="#707272"
                />
                <path
                    id="Path_73"
                    data-name="Path 73"
                    d="M198.85,87.86c0,2.52-2,5.23-4.55,5.23s-4.56-2.71-4.56-5.23a4.56,4.56,0,0,1,9.11,0Z"
                    fill="#f4f5f6"
                />
                <path
                    id="Path_74"
                    data-name="Path 74"
                    d="M188.65,102.77h11.11a2.67,2.67,0,0,0,2.56-1.87l.6-1.87a2.68,2.68,0,0,0-1.62-3.34,20.76,20.76,0,0,0-14,.15,2.68,2.68,0,0,0-1.72,3.25l.46,1.7a2.69,2.69,0,0,0,2.61,1.98Z"
                    fill="#f4f5f6"
                />
                <circle
                    id="Ellipse_25"
                    data-name="Ellipse 25"
                    cx="17.54"
                    cy="17.54"
                    r="17.54"
                    transform="translate(281.98 109.569) rotate(-80.99)"
                    fill="#707272"
                />
                <circle
                    id="Ellipse_26"
                    data-name="Ellipse 26"
                    cx="16.96"
                    cy="16.96"
                    r="16.96"
                    transform="translate(279.706 86.215) rotate(-23.58)"
                    fill="#f4f5f6"
                />
                <path
                    id="Path_75"
                    data-name="Path 75"
                    d="M286.41,94.33h0a.17.17,0,0,1-.17-.19,14.638,14.638,0,0,1,.23-2,.18.18,0,1,1,.35.06,15.782,15.782,0,0,0-.22,1.94.18.18,0,0,1-.19.19Z"
                    fill="#707272"
                />
                <path
                    id="Path_76"
                    data-name="Path 76"
                    d="M288.06,88.12h-.08a.17.17,0,0,1-.08-.24A15.77,15.77,0,0,1,302,79.15a16.1,16.1,0,0,1,2.86.26.18.18,0,1,1-.07.35,15.24,15.24,0,0,0-2.79-.25A15.4,15.4,0,0,0,288.22,88,.19.19,0,0,1,288.06,88.12Z"
                    fill="#707272"
                />
                <circle
                    id="Ellipse_27"
                    data-name="Ellipse 27"
                    cx="14.35"
                    cy="14.35"
                    r="14.35"
                    transform="translate(288.06 81)"
                    fill="#707272"
                />
                <path
                    id="Path_77"
                    data-name="Path 77"
                    d="M306.62,89.68c0,2.51-2,5.23-4.55,5.23s-4.56-2.72-4.56-5.23a4.56,4.56,0,0,1,9.11,0Z"
                    fill="#f4f5f6"
                />
                <path
                    id="Path_78"
                    data-name="Path 78"
                    d="M296.42,104.59h11.11a2.7,2.7,0,0,0,2.56-1.87l.6-1.87a2.69,2.69,0,0,0-1.62-3.35,20.73,20.73,0,0,0-14,.16,2.67,2.67,0,0,0-1.71,3.24l.46,1.7a2.7,2.7,0,0,0,2.6,1.99Z"
                    fill="#f4f5f6"
                />
                <path
                    id="Path_79"
                    data-name="Path 79"
                    d="M295,263.31H201.43C183.12,226,164.8,188.08,146.49,150.72H349.9C331.59,188.08,313.28,226,295,263.31Z"
                    fill="#d6d6d6"
                />
                <rect
                    id="Rectangle_1"
                    data-name="Rectangle 1"
                    width="216.35"
                    height="8.58"
                    rx="2.36"
                    transform="translate(139.98 146.17)"
                    fill="#707272"
                />
                <path
                    id="Path_80"
                    data-name="Path 80"
                    d="M288.25,307.49H208.14c-2.32-14.39-4.64-29.28-7-44.18h94C292.89,278.21,290.57,293.1,288.25,307.49Z"
                    fill="#d6d6d6"
                />
                <path
                    id="Path_81"
                    data-name="Path 81"
                    d="M290.69,307.81c0,1.62-1.05,3-2.23,3H207.32c-1.18,0-1.95-1.34-1.95-3V306c0-1.63.77-3.05,1.95-3.05h81.14c1.18,0,2.23,1.42,2.23,3.05Z"
                    fill="#707272"
                />
                <path
                    id="Path_82"
                    data-name="Path 82"
                    d="M196.29,244.58a.67.67,0,0,1-.61-.4L160,162.51a.68.68,0,0,1,.35-.88.67.67,0,0,1,.87.35l35.66,81.67a.66.66,0,0,1-.34.88.86.86,0,0,1-.25.05Z"
                    fill="#707272"
                />
                <path
                    id="Path_83"
                    data-name="Path 83"
                    d="M209.53,297.6a.66.66,0,0,1-.66-.58l-3.37-27.14a.65.65,0,0,1,.58-.74.66.66,0,0,1,.74.58l3.37,27.14a.67.67,0,0,1-.58.74Z"
                    fill="#707272"
                />
                <path
                    id="Path_84"
                    data-name="Path 84"
                    d="M184.58,113.42l-1.49-.07a.5.5,0,1,1,.04-1h0l1.5.07a.5.5,0,0,1,0,1Z"
                    fill="#707272"
                />
                <path
                    id="Path_85"
                    data-name="Path 85"
                    d="M179.6,113.2l-1.5-.07a.506.506,0,1,1,.05-1.01h0l1.49.07a.505.505,0,0,1-.04,1.01Z"
                    fill="#707272"
                />
                <path
                    id="Path_86"
                    data-name="Path 86"
                    d="M174.61,113l-1.49-.06a.51.51,0,1,1,0-1h0l1.5.06a.51.51,0,0,1,.48.54.5.5,0,0,1-.49.46Z"
                    fill="#707272"
                />
                <path
                    id="Path_87"
                    data-name="Path 87"
                    d="M169.63,112.77h-1.5a.54.54,0,0,1-.5-.54.52.52,0,0,1,.54-.5h0l1.5.06a.52.52,0,0,1,0,1Z"
                    fill="#707272"
                />
                <path
                    id="Path_88"
                    data-name="Path 88"
                    d="M164.64,112.6h-1.49a.51.51,0,0,1-.51-.53.53.53,0,0,1,.54-.51h1.5a.51.51,0,0,1,.5.53A.53.53,0,0,1,164.64,112.6Z"
                    fill="#707272"
                />
                <path
                    id="Path_89"
                    data-name="Path 89"
                    d="M159.66,112.47h-1.5a.53.53,0,0,1-.52-.54.54.54,0,0,1,.54-.52h1.5a.53.53,0,1,1,0,1Z"
                    fill="#707272"
                />
                <path
                    id="Path_90"
                    data-name="Path 90"
                    d="M154.67,112.4h-1.49a.54.54,0,0,1,0-1.07h1.5a.53.53,0,0,1,.53.54A.54.54,0,0,1,154.67,112.4Z"
                    fill="#707272"
                />
                <path
                    id="Path_91"
                    data-name="Path 91"
                    d="M149.69,112.39h-1.5a.53.53,0,0,1-.54-.53.54.54,0,0,1,.53-.55h1.5a.55.55,0,0,1,.55.54.54.54,0,0,1-.54.54Z"
                    fill="#707272"
                />
                <path
                    id="Path_92"
                    data-name="Path 92"
                    d="M144.71,112.46h-1.5a.55.55,0,0,1-.56-.53.54.54,0,0,1,.53-.56h1.49a.55.55,0,0,1,0,1.09Z"
                    fill="#707272"
                />
                <path
                    id="Path_93"
                    data-name="Path 93"
                    d="M139.73,112.62l-1.5.07a.55.55,0,0,1-.57-.53.54.54,0,0,1,.52-.57h0l1.49-.07a.55.55,0,0,1,0,1.1Z"
                    fill="#707272"
                />
                <path
                    id="Path_94"
                    data-name="Path 94"
                    d="M134.76,112.88l-1.5.11a.561.561,0,0,1-.07-1.12h0l1.49-.1a.57.57,0,0,1,.6.52A.56.56,0,0,1,134.76,112.88Z"
                    fill="#707272"
                />
                <path
                    id="Path_95"
                    data-name="Path 95"
                    d="M129.79,113.26l-1.49.14a.562.562,0,1,1-.1-1.12h0l1.49-.14a.57.57,0,0,1,.61.51.56.56,0,0,1-.51.61Z"
                    fill="#707272"
                />
                <path
                    id="Path_96"
                    data-name="Path 96"
                    d="M124.84,113.77l-1.48.18a.57.57,0,1,1-.14-1.13h0l1.49-.18a.58.58,0,0,1,.63.5.56.56,0,0,1-.5.63Z"
                    fill="#707272"
                />
                <path
                    id="Path_97"
                    data-name="Path 97"
                    d="M119.91,114.42l-1.48.23a.58.58,0,0,1-.17-1.14h0l1.48-.22a.571.571,0,1,1,.17,1.13Z"
                    fill="#707272"
                />
                <path
                    id="Path_98"
                    data-name="Path 98"
                    d="M115,115.23l-1.47.28a.581.581,0,1,1-.22-1.14h0l1.47-.28a.581.581,0,0,1,.22,1.14Z"
                    fill="#707272"
                />
                <path
                    id="Path_99"
                    data-name="Path 99"
                    d="M110.14,116.22l-1.46.33a.585.585,0,0,1-.26-1.14h0l1.46-.33a.58.58,0,0,1,.7.44.59.59,0,0,1-.44.7Z"
                    fill="#707272"
                />
                <path
                    id="Path_100"
                    data-name="Path 100"
                    d="M105.31,117.4l-1.44.4a.6.6,0,1,1-.31-1.15h0l1.44-.39a.59.59,0,0,1,.73.41.6.6,0,0,1-.42.73Z"
                    fill="#707272"
                />
                <path
                    id="Path_101"
                    data-name="Path 101"
                    d="M100.55,118.79l-1.43.46a.58.58,0,0,1-.75-.38.6.6,0,0,1,.38-.75h0l1.42-.46a.595.595,0,0,1,.37,1.13Z"
                    fill="#707272"
                />
                <path
                    id="Path_102"
                    data-name="Path 102"
                    d="M95.86,120.41l-1.4.54a.6.6,0,0,1-.43-1.13h0l1.4-.53a.6.6,0,1,1,.43,1.12Z"
                    fill="#707272"
                />
                <path
                    id="Path_103"
                    data-name="Path 103"
                    d="M91.27,122.28l-1.37.62a.613.613,0,1,1-.5-1.12h0l1.37-.61a.6.6,0,0,1,.8.31A.61.61,0,0,1,91.27,122.28Z"
                    fill="#707272"
                />
                <path
                    id="Path_104"
                    data-name="Path 104"
                    d="M86.8,124.41l-1.33.7A.624.624,0,0,1,84.9,124h0l1.33-.7a.62.62,0,0,1,.57,1.09Z"
                    fill="#707272"
                />
                <path
                    id="Path_105"
                    data-name="Path 105"
                    d="M82.47,126.82l-1.28.78a.62.62,0,0,1-.85-.2.63.63,0,0,1,.2-.86h0l1.27-.78a.61.61,0,0,1,.85.21A.62.62,0,0,1,82.47,126.82Z"
                    fill="#707272"
                />
                <path
                    id="Path_106"
                    data-name="Path 106"
                    d="M78.32,129.5l-1.22.87a.63.63,0,0,1-.88-.14.64.64,0,0,1,.15-.88h0l1.22-.86a.62.62,0,1,1,.73,1Z"
                    fill="#707272"
                />
                <path
                    id="Path_107"
                    data-name="Path 107"
                    d="M74.36,132.48l-1.15,1a.64.64,0,1,1-.8-1h0l1.15-1a.64.64,0,0,1,.89.08.63.63,0,0,1-.09.92Z"
                    fill="#707272"
                />
                <path
                    id="Path_108"
                    data-name="Path 108"
                    d="M70.63,135.73l-1.07,1a.62.62,0,0,1-.89,0,.63.63,0,0,1,0-.89h0l1.08-1.05a.63.63,0,0,1,.87.91Z"
                    fill="#707272"
                />
                <path
                    id="Path_109"
                    data-name="Path 109"
                    d="M67.16,139.24l-1,1.11a.64.64,0,0,1-.9.05.62.62,0,0,1,0-.89h0l1-1.11a.63.63,0,1,1,.94.84Z"
                    fill="#707272"
                />
                <path
                    id="Path_110"
                    data-name="Path 110"
                    d="M63.93,143,63,144.17a.634.634,0,0,1-1-.78h0l.93-1.18a.637.637,0,0,1,1,.79Z"
                    fill="#707272"
                />
                <path
                    id="Path_111"
                    data-name="Path 111"
                    d="M61,147l-.85,1.24a.64.64,0,1,1-1-.72h0l.85-1.23a.63.63,0,0,1,1,.71Z"
                    fill="#707272"
                />
                <path
                    id="Path_112"
                    data-name="Path 112"
                    d="M58.26,151.1l-.77,1.29a.64.64,0,0,1-.87.22.65.65,0,0,1-.22-.88h0l.76-1.28a.64.64,0,0,1,1.1.65Z"
                    fill="#707272"
                />
                <path
                    id="Path_113"
                    data-name="Path 113"
                    d="M55.81,155.41l-.68,1.33a.65.65,0,0,1-.87.27.64.64,0,0,1-.27-.86h0l.69-1.33a.637.637,0,1,1,1.13.59Z"
                    fill="#707272"
                />
                <path
                    id="Path_114"
                    data-name="Path 114"
                    d="M53.63,159.85,53,161.22a.63.63,0,0,1-.84.32.64.64,0,0,1-.33-.85h0l.61-1.36a.645.645,0,0,1,1.18.52Z"
                    fill="#707272"
                />
                <path
                    id="Path_115"
                    data-name="Path 115"
                    d="M51.69,164.41l-.53,1.4a.65.65,0,0,1-.83.38.66.66,0,0,1-.38-.84h0l.53-1.4a.65.65,0,0,1,.83-.37.64.64,0,0,1,.38.83Z"
                    fill="#707272"
                />
                <path
                    id="Path_116"
                    data-name="Path 116"
                    d="M50,169.08l-.46,1.42a.65.65,0,1,1-1.23-.39h0l.46-1.43a.65.65,0,0,1,.82-.41.64.64,0,0,1,.41.81Z"
                    fill="#707272"
                />
                <path
                    id="Path_117"
                    data-name="Path 117"
                    d="M48.58,173.82l-.39,1.45a.65.65,0,0,1-1.25-.33h0l.39-1.45a.64.64,0,0,1,.79-.46A.65.65,0,0,1,48.58,173.82Z"
                    fill="#707272"
                />
                <path
                    id="Path_118"
                    data-name="Path 118"
                    d="M47.39,178.64l-.31,1.46a.654.654,0,0,1-1.28-.27h0l.32-1.46a.64.64,0,0,1,.77-.5.65.65,0,0,1,.5.77Z"
                    fill="#707272"
                />
                <path
                    id="Path_119"
                    data-name="Path 119"
                    d="M46.44,183.51,46.2,185a.65.65,0,0,1-.75.54.64.64,0,0,1-.54-.75h0l.24-1.47a.65.65,0,0,1,.75-.54.66.66,0,0,1,.54.73Z"
                    fill="#707272"
                />
                <path
                    id="Path_120"
                    data-name="Path 120"
                    d="M45.73,188.41l-.17,1.49a.66.66,0,0,1-.73.58.65.65,0,0,1-.57-.73h0l.17-1.48a.654.654,0,0,1,1.3.14Z"
                    fill="#707272"
                />
                <path
                    id="Path_121"
                    data-name="Path 121"
                    d="M45.25,193.35l-.1,1.5a.66.66,0,1,1-1.31-.09h0l.1-1.49a.66.66,0,1,1,1.31.08Z"
                    fill="#707272"
                />
                <path
                    id="Path_122"
                    data-name="Path 122"
                    d="M45,198.31v1.49a.66.66,0,0,1-.67.65.67.67,0,0,1-.65-.67h0v-1.5a.66.66,0,1,1,1.32,0Z"
                    fill="#707272"
                />
                <path
                    id="Path_123"
                    data-name="Path 123"
                    d="M45,203.27v1.49a.66.66,0,1,1-1.32,0h0v-1.5a.66.66,0,1,1,1.32,0Z"
                    fill="#707272"
                />
                <path
                    id="Path_124"
                    data-name="Path 124"
                    d="M45.23,208.22l.11,1.49a.66.66,0,0,1-.61.72.67.67,0,0,1-.71-.61h0l-.11-1.5a.66.66,0,0,1,.61-.71.67.67,0,0,1,.71.61Z"
                    fill="#707272"
                />
                <path
                    id="Path_125"
                    data-name="Path 125"
                    d="M45.69,213.16l.19,1.49a.665.665,0,0,1-1.32.16h0l-.19-1.48a.68.68,0,0,1,.58-.75.67.67,0,0,1,.74.58Z"
                    fill="#707272"
                />
                <path
                    id="Path_126"
                    data-name="Path 126"
                    d="M46.4,218.07l.26,1.47a.67.67,0,1,1-1.32.23h0l-.26-1.47a.67.67,0,1,1,1.32-.23Z"
                    fill="#707272"
                />
                <path
                    id="Path_127"
                    data-name="Path 127"
                    d="M47.35,222.93l.33,1.46a.67.67,0,0,1-.5.8.66.66,0,0,1-.8-.5h0L46,223.23a.672.672,0,0,1,1.31-.3Z"
                    fill="#707272"
                />
                <path
                    id="Path_128"
                    data-name="Path 128"
                    d="M48.54,227.74l.42,1.44a.68.68,0,0,1-.46.83.67.67,0,0,1-.83-.46h0l-.41-1.44a.67.67,0,1,1,1.28-.37Z"
                    fill="#707272"
                />
                <path
                    id="Path_129"
                    data-name="Path 129"
                    d="M50,232.48l.49,1.41a.68.68,0,0,1-.42.86.67.67,0,0,1-.85-.41h0l-.49-1.41a.672.672,0,0,1,1.27-.44Z"
                    fill="#707272"
                />
                <path
                    id="Path_130"
                    data-name="Path 130"
                    d="M51.71,237.13l.57,1.38A.685.685,0,1,1,51,239h0l-.57-1.38a.67.67,0,0,1,1.24-.51Z"
                    fill="#707272"
                />
                <path
                    id="Path_131"
                    data-name="Path 131"
                    d="M53.7,241.66l.65,1.35a.68.68,0,0,1-1.22.59h0l-.65-1.35a.68.68,0,0,1,.31-.9.69.69,0,0,1,.91.31Z"
                    fill="#707272"
                />
                <path
                    id="Path_132"
                    data-name="Path 132"
                    d="M55.94,246.08l.73,1.31a.68.68,0,0,1-1.19.66h0l-.72-1.31a.674.674,0,0,1,1.18-.65Z"
                    fill="#707272"
                />
                <path
                    id="Path_133"
                    data-name="Path 133"
                    d="M58.41,250.38l.79,1.27a.683.683,0,0,1-1.16.72h0l-.78-1.28a.68.68,0,1,1,1.15-.71Z"
                    fill="#707272"
                />
                <path
                    id="Path_134"
                    data-name="Path 134"
                    d="M61.09,254.55l.84,1.24a.68.68,0,0,1-.17,1,.69.69,0,0,1-.95-.18h0L60,255.33a.69.69,0,0,1,.18-1,.68.68,0,0,1,.91.22Z"
                    fill="#707272"
                />
                <path
                    id="Path_135"
                    data-name="Path 135"
                    d="M64,258.6l.9,1.19a.69.69,0,0,1-1.09.83h0l-.9-1.2a.67.67,0,0,1,.13-.95A.69.69,0,0,1,64,258.6Z"
                    fill="#707272"
                />
                <path
                    id="Path_136"
                    data-name="Path 136"
                    d="M67,262.5l1,1.15a.68.68,0,0,1-.08,1,.69.69,0,0,1-1-.09h0l-1-1.15A.706.706,0,0,1,67,262.5Z"
                    fill="#707272"
                />
                <path
                    id="Path_137"
                    data-name="Path 137"
                    d="M70.25,266.26l1,1.11a.69.69,0,1,1-1,.93h0l-1-1.11a.69.69,0,0,1,1-.93Z"
                    fill="#707272"
                />
                <path
                    id="Path_138"
                    data-name="Path 138"
                    d="M73.66,269.86l1.06,1.06a.7.7,0,0,1,0,1,.69.69,0,0,1-1,0h0l-1.06-1.06a.707.707,0,0,1,1-1Z"
                    fill="#707272"
                />
                <path
                    id="Path_139"
                    data-name="Path 139"
                    d="M77.23,273.3l1.11,1a.69.69,0,1,1-.92,1h0l-1.1-1a.69.69,0,0,1,.92-1Z"
                    fill="#707272"
                />
                <path
                    id="Path_140"
                    data-name="Path 140"
                    d="M81,276.57l1.16.95a.7.7,0,0,1,.1,1,.69.69,0,0,1-1,.09h0l-1.16-.95a.693.693,0,1,1,.88-1.07Z"
                    fill="#707272"
                />
                <path
                    id="Path_141"
                    data-name="Path 141"
                    d="M84.84,279.66l1.2.89a.7.7,0,1,1-.83,1.12h0l-1.2-.9a.693.693,0,1,1,.83-1.11Z"
                    fill="#707272"
                />
                <path
                    id="Path_142"
                    data-name="Path 142"
                    d="M88.87,282.56l1.24.83a.72.72,0,1,1-.81,1.19h0l-1.24-.84a.695.695,0,1,1,.78-1.15Z"
                    fill="#707272"
                />
                <path
                    id="Path_143"
                    data-name="Path 143"
                    d="M93,285.26l1.29.77a.7.7,0,0,1-.73,1.2h0l-1.28-.78a.7.7,0,1,1,.72-1.19Z"
                    fill="#707272"
                />
                <path
                    id="Path_144"
                    data-name="Path 144"
                    d="M97.31,287.75l1.32.71A.7.7,0,0,1,98,289.7h0l-1.36-.7a.69.69,0,0,1-.28-1,.7.7,0,0,1,.95-.25Z"
                    fill="#707272"
                />
                <path
                    id="Path_145"
                    data-name="Path 145"
                    d="M101.71,290l1.35.65a.7.7,0,0,1-.6,1.27h0l-1.35-.65a.7.7,0,0,1-.34-.93.72.72,0,0,1,.94-.34Z"
                    fill="#707272"
                />
                <path
                    id="Path_146"
                    data-name="Path 146"
                    d="M106.22,292.07l1.39.57a.71.71,0,0,1-.54,1.31h0l-1.39-.58a.7.7,0,0,1,.54-1.3Z"
                    fill="#707272"
                />
                <path
                    id="Path_147"
                    data-name="Path 147"
                    d="M110.84,293.88l1.41.5a.71.71,0,0,1-.47,1.33h0l-1.41-.5a.71.71,0,1,1,.47-1.33Z"
                    fill="#707272"
                />
                <path
                    id="Path_148"
                    data-name="Path 148"
                    d="M115.54,295.44l1.43.42a.71.71,0,1,1-.39,1.36h0l-1.44-.42a.71.71,0,1,1,.4-1.36Z"
                    fill="#707272"
                />
                <path
                    id="Path_149"
                    data-name="Path 149"
                    d="M120.32,296.74l1.45.34a.71.71,0,1,1-.32,1.38h0l-1.46-.34a.71.71,0,1,1,.33-1.38Z"
                    fill="#707272"
                />
                <path
                    id="Path_150"
                    data-name="Path 150"
                    d="M125.16,297.77l1.47.25a.71.71,0,0,1,.58.83.7.7,0,0,1-.82.58h0l-1.48-.26a.711.711,0,1,1,.25-1.4Z"
                    fill="#707272"
                />
                <path
                    id="Path_151"
                    data-name="Path 151"
                    d="M130.05,298.52l1.49.17a.72.72,0,0,1,.63.79.71.71,0,0,1-.8.63h0l-1.48-.17a.714.714,0,0,1,.16-1.42Z"
                    fill="#707272"
                />
                <path
                    id="Path_152"
                    data-name="Path 152"
                    d="M135,299l1.49.08a.72.72,0,1,1-.08,1.43h0l-1.49-.08a.72.72,0,0,1-.68-.76A.71.71,0,0,1,135,299Z"
                    fill="#707272"
                />
                <path
                    id="Path_153"
                    data-name="Path 153"
                    d="M139.92,299.16h1.51a.72.72,0,1,1,0,1.43h-1.5a.72.72,0,0,1-.72-.72.71.71,0,0,1,.71-.72Z"
                    fill="#707272"
                />
                <path
                    id="Path_154"
                    data-name="Path 154"
                    d="M144.88,299.05l1.49-.08a.721.721,0,0,1,.08,1.44h0l-1.49.08a.721.721,0,1,1-.08-1.44Z"
                    fill="#707272"
                />
                <path
                    id="Path_155"
                    data-name="Path 155"
                    d="M149.82,298.69l1.49-.16a.73.73,0,0,1,.79.65.72.72,0,0,1-.64.79h0l-1.49.16a.724.724,0,0,1-.15-1.44Z"
                    fill="#707272"
                />
                <path
                    id="Path_156"
                    data-name="Path 156"
                    d="M154.74,298.09l1.48-.23a.723.723,0,1,1,.22,1.43h0l-1.48.23a.723.723,0,0,1-.22-1.43Z"
                    fill="#707272"
                />
                <path
                    id="Path_157"
                    data-name="Path 157"
                    d="M159.63,297.25l1.46-.29a.725.725,0,0,1,.29,1.42h0l-1.47.3a.73.73,0,0,1-.28-1.43Z"
                    fill="#707272"
                />
                <path
                    id="Path_158"
                    data-name="Path 158"
                    d="M164.47,296.2l1.46-.36a.73.73,0,1,1,.34,1.41h0l-1.45.36a.73.73,0,0,1-.35-1.41Z"
                    fill="#707272"
                />
                <path
                    id="Path_159"
                    data-name="Path 159"
                    d="M169.27,294.94l1.44-.42a.72.72,0,0,1,.9.5.73.73,0,0,1-.49.91h0l-1.44.41a.74.74,0,0,1-.91-.5.73.73,0,0,1,.5-.9Z"
                    fill="#707272"
                />
                <path
                    id="Path_160"
                    data-name="Path 160"
                    d="M174,293.49l1.42-.47a.732.732,0,0,1,.46,1.39h0l-1.42.47a.732.732,0,0,1-.46-1.39Z"
                    fill="#707272"
                />
                <path
                    id="Path_161"
                    data-name="Path 161"
                    d="M178.71,291.87l1.4-.52a.733.733,0,1,1,.52,1.37h0l-1.41.52a.72.72,0,0,1-.94-.43.73.73,0,0,1,.43-.94Z"
                    fill="#707272"
                />
                <path
                    id="Path_162"
                    data-name="Path 162"
                    d="M183.35,290.08l1.38-.56a.74.74,0,0,1,.56,1.36h0l-1.39.57a.74.74,0,0,1-.55-1.37Z"
                    fill="#707272"
                />
                <path
                    id="Path_163"
                    data-name="Path 163"
                    d="M187.92,288.15l1.37-.61a.74.74,0,0,1,1,.38.73.73,0,0,1-.38,1h0l-1.37.61a.74.74,0,0,1-.6-1.35Z"
                    fill="#707272"
                />
                <path
                    id="Path_164"
                    data-name="Path 164"
                    d="M192.45,286.08l1.35-.64a.74.74,0,0,1,.64,1.33h0l-1.36.65a.73.73,0,0,1-1-.35.74.74,0,0,1,.37-.99Z"
                    fill="#707272"
                />
                <path
                    id="Path_165"
                    data-name="Path 165"
                    d="M196.91,283.9l1.34-.68a.72.72,0,0,1,1,.32.74.74,0,0,1-.32,1h0l-1.33.68a.74.74,0,0,1-1-.33.73.73,0,0,1,.31-.99Z"
                    fill="#707272"
                />
                <path
                    id="Path_166"
                    data-name="Path 166"
                    d="M201.32,281.6l1.32-.71a.75.75,0,1,1,.71,1.31h0l-1.32.71a.75.75,0,1,1-.71-1.31Z"
                    fill="#707272"
                />
                <path
                    id="Path_167"
                    data-name="Path 167"
                    d="M205.68,279.2l1.31-.74a.75.75,0,0,1,.73,1.3h0l-1.3.74a.75.75,0,0,1-.74-1.3Z"
                    fill="#707272"
                />
                <path
                    id="Path_168"
                    data-name="Path 168"
                    d="M210,276.72l1.29-.77a.75.75,0,0,1,.76,1.29h0l-1.29.76a.744.744,0,1,1-.76-1.28Z"
                    fill="#707272"
                />
                <path
                    id="Path_169"
                    data-name="Path 169"
                    d="M214.27,274.15l1.27-.78a.75.75,0,0,1,1,.24.76.76,0,0,1-.24,1h0l-1.28.78a.75.75,0,0,1-.78-1.28Z"
                    fill="#707272"
                />
                <path
                    id="Path_170"
                    data-name="Path 170"
                    d="M218.49,271.52l1.27-.8a.75.75,0,1,1,.8,1.27h0l-1.26.8a.753.753,0,0,1-.81-1.27Z"
                    fill="#707272"
                />
                <path
                    id="Path_171"
                    data-name="Path 171"
                    d="M222.69,268.83l1.25-.82a.752.752,0,1,1,.82,1.26l-1.25.82a.754.754,0,0,1-.83-1.26Z"
                    fill="#707272"
                />
                <path
                    id="Path_172"
                    data-name="Path 172"
                    d="M226.85,266.09l1.24-.84a.76.76,0,0,1,1,.21.73.73,0,0,1-.21,1l-1.24.84a.75.75,0,0,1-1-.21.73.73,0,0,1,.21-1Z"
                    fill="#707272"
                />
                <path
                    id="Path_173"
                    data-name="Path 173"
                    d="M231,263.3l1.24-.85a.745.745,0,1,1,.84,1.23l-1.24.85a.74.74,0,0,1-1-.2.73.73,0,0,1,.19-1Z"
                    fill="#707272"
                />
                <path
                    id="Path_174"
                    data-name="Path 174"
                    d="M235.09,260.48l1.24-.85a.73.73,0,0,1,1,.18.74.74,0,0,1-.18,1l-1.23.86a.743.743,0,0,1-.85-1.22Z"
                    fill="#707272"
                />
                <path
                    id="Path_175"
                    data-name="Path 175"
                    d="M239.19,257.64l1.23-.86a.74.74,0,0,1,.85,1.21l-1.23.86a.74.74,0,0,1-.85-1.21Z"
                    fill="#707272"
                />
                <path
                    id="Path_176"
                    data-name="Path 176"
                    d="M243.29,254.79l1.23-.86a.732.732,0,0,1,.84,1.2l-1.23.86a.732.732,0,1,1-.84-1.2Z"
                    fill="#707272"
                />
                <path
                    id="Path_177"
                    data-name="Path 177"
                    d="M247.39,251.93l1.23-.85a.73.73,0,1,1,.83,1.19l-1.23.86a.72.72,0,0,1-1-.18.74.74,0,0,1,.18-1Z"
                    fill="#707272"
                />
                <path
                    id="Path_178"
                    data-name="Path 178"
                    d="M251.5,249.09l1.23-.85a.723.723,0,1,1,.82,1.19l-1.23.85a.73.73,0,1,1-.83-1.19Z"
                    fill="#707272"
                />
                <path
                    id="Path_179"
                    data-name="Path 179"
                    d="M255.61,246.26l1.24-.84a.72.72,0,0,1,1,.19.71.71,0,0,1-.19,1l-1.23.84a.723.723,0,0,1-.82-1.19Z"
                    fill="#707272"
                />
                <path
                    id="Path_180"
                    data-name="Path 180"
                    d="M259.75,243.46l1.25-.83a.72.72,0,0,1,1,.19.73.73,0,0,1-.19,1l-1.25.83a.71.71,0,0,1-1-.19A.72.72,0,0,1,259.75,243.46Z"
                    fill="#707272"
                />
                <path
                    id="Path_181"
                    data-name="Path 181"
                    d="M263.92,240.69l1.25-.82a.72.72,0,1,1,.78,1.2l-1.25.82a.711.711,0,0,1-.78-1.19Z"
                    fill="#707272"
                />
                <path
                    id="Path_182"
                    data-name="Path 182"
                    d="M268.11,238l1.27-.8a.71.71,0,1,1,.76,1.2l-1.26.8a.71.71,0,0,1-1-.21.72.72,0,0,1,.21-1Z"
                    fill="#707272"
                />
                <path
                    id="Path_183"
                    data-name="Path 183"
                    d="M272.35,235.31l1.27-.79a.71.71,0,0,1,1,.23.7.7,0,0,1-.23,1l-1.27.79a.7.7,0,0,1-1-.23.69.69,0,0,1,.23-1Z"
                    fill="#707272"
                />
                <path
                    id="Path_184"
                    data-name="Path 184"
                    d="M276.62,232.71l1.29-.76a.7.7,0,1,1,.72,1.2l-1.29.77a.69.69,0,0,1-1-.25.7.7,0,0,1,.24-1Z"
                    fill="#707272"
                />
                <path
                    id="Path_185"
                    data-name="Path 185"
                    d="M280.94,230.19l1.31-.74a.7.7,0,1,1,.69,1.21l-1.31.74a.7.7,0,0,1-.69-1.21Z"
                    fill="#707272"
                />
                <path
                    id="Path_186"
                    data-name="Path 186"
                    d="M285.32,227.75l1.32-.71a.69.69,0,0,1,.94.28.7.7,0,0,1-.28.94L286,229a.694.694,0,0,1-.66-1.22Z"
                    fill="#707272"
                />
                <path
                    id="Path_187"
                    data-name="Path 187"
                    d="M289.75,225.41l1.34-.67a.67.67,0,0,1,.92.3.69.69,0,0,1-.3.93l-1.33.68a.7.7,0,0,1-.93-.31.69.69,0,0,1,.3-.93Z"
                    fill="#707272"
                />
                <path
                    id="Path_188"
                    data-name="Path 188"
                    d="M294.24,223.19l1.35-.64a.69.69,0,0,1,.92.32.7.7,0,0,1-.33.92l-1.35.64a.69.69,0,0,1-.92-.33.68.68,0,0,1,.33-.91Z"
                    fill="#707272"
                />
                <path
                    id="Path_189"
                    data-name="Path 189"
                    d="M298.79,221.09l1.37-.6a.67.67,0,0,1,.9.35.68.68,0,0,1-.35.89l-1.37.61a.69.69,0,0,1-.9-.35.68.68,0,0,1,.35-.9Z"
                    fill="#707272"
                />
                <path
                    id="Path_190"
                    data-name="Path 190"
                    d="M303.4,219.12l1.39-.55a.68.68,0,1,1,.51,1.25l-1.39.56a.68.68,0,1,1-.51-1.26Z"
                    fill="#707272"
                />
                <path
                    id="Path_191"
                    data-name="Path 191"
                    d="M308.08,217.31l1.4-.51a.68.68,0,0,1,.47,1.27l-1.41.51a.68.68,0,1,1-.46-1.27Z"
                    fill="#707272"
                />
                <path
                    id="Path_192"
                    data-name="Path 192"
                    d="M312.81,215.66l1.43-.46a.66.66,0,0,1,.84.43.68.68,0,0,1-.43.85l-1.42.46a.674.674,0,0,1-.42-1.28Z"
                    fill="#707272"
                />
                <path
                    id="Path_193"
                    data-name="Path 193"
                    d="M317.61,214.18l1.44-.4a.65.65,0,0,1,.82.46.66.66,0,0,1-.46.82h0l-1.44.41h0a.67.67,0,1,1-.36-1.29Z"
                    fill="#707272"
                />
                <path
                    id="Path_194"
                    data-name="Path 194"
                    d="M322.47,212.9l1.46-.33a.661.661,0,1,1,.29,1.29l-1.46.34a.66.66,0,0,1-.79-.5.67.67,0,0,1,.5-.8Z"
                    fill="#707272"
                />
                <path
                    id="Path_195"
                    data-name="Path 195"
                    d="M327.39,211.88l1.48-.25a.66.66,0,1,1,.22,1.3l-1.48.26a.67.67,0,0,1-.76-.55.66.66,0,0,1,.54-.76Z"
                    fill="#707272"
                />
                <path
                    id="Path_196"
                    data-name="Path 196"
                    d="M332.37,211.16l1.49-.16a.66.66,0,0,1,.72.59.65.65,0,0,1-.58.72l-1.49.15a.654.654,0,1,1-.14-1.3Z"
                    fill="#707272"
                />
                <path
                    id="Path_197"
                    data-name="Path 197"
                    d="M337.4,210.78h1.5a.63.63,0,0,1,.66.63.65.65,0,0,1-.63.67h-1.5a.65.65,0,0,1-.67-.63A.66.66,0,0,1,337.4,210.78Z"
                    fill="#707272"
                />
                <path
                    id="Path_198"
                    data-name="Path 198"
                    d="M342.45,210.8l1.49.09a.65.65,0,0,1,.61.69.64.64,0,0,1-.69.6l-1.49-.09a.65.65,0,1,1,.08-1.29Z"
                    fill="#707272"
                />
                <path
                    id="Path_199"
                    data-name="Path 199"
                    d="M347.48,211.29l1.48.24a.65.65,0,0,1-.21,1.28l-1.48-.25a.644.644,0,1,1,.21-1.27Z"
                    fill="#707272"
                />
                <path
                    id="Path_200"
                    data-name="Path 200"
                    d="M352.44,212.32l1.44.41a.641.641,0,0,1-.36,1.23l-1.44-.41a.641.641,0,1,1,.36-1.23Z"
                    fill="#707272"
                />
                <path
                    id="Path_201"
                    data-name="Path 201"
                    d="M357.24,213.94l1.38.6a.634.634,0,1,1-.51,1.16l-1.37-.59a.64.64,0,1,1,.5-1.17Z"
                    fill="#707272"
                />
                <path
                    id="Path_202"
                    data-name="Path 202"
                    d="M361.8,216.19l1.27.78a.633.633,0,0,1-.66,1.08l-1.28-.78a.633.633,0,1,1,.66-1.08Z"
                    fill="#707272"
                />
                <path
                    id="Path_203"
                    data-name="Path 203"
                    d="M366,219.05l1.15.95a.64.64,0,0,1-.8,1l-1.15-.95a.62.62,0,0,1-.09-.88.63.63,0,0,1,.89-.09Z"
                    fill="#707272"
                />
                <path
                    id="Path_204"
                    data-name="Path 204"
                    d="M369.75,222.45l1,1.1a.63.63,0,0,1-.92.85l-1-1.09a.63.63,0,1,1,.91-.86Z"
                    fill="#707272"
                />
                <path
                    id="Path_205"
                    data-name="Path 205"
                    d="M373,226.31l.88,1.21a.63.63,0,0,1-.14.86.6.6,0,0,1-.85-.14L372,227a.62.62,0,0,1,1-.72Z"
                    fill="#707272"
                />
                <path
                    id="Path_206"
                    data-name="Path 206"
                    d="M375.82,230.53l.73,1.31a.6.6,0,1,1-1.05.59l-.73-1.31a.6.6,0,0,1,1.05-.59Z"
                    fill="#707272"
                />
                <path
                    id="Path_207"
                    data-name="Path 207"
                    d="M378.11,235l.57,1.38a.59.59,0,0,1-1.08.46l-.6-1.34a.6.6,0,0,1,.32-.78.61.61,0,0,1,.78.32Z"
                    fill="#707272"
                />
                <path
                    id="Path_208"
                    data-name="Path 208"
                    d="M379.87,239.78l.42,1.44a.57.57,0,0,1-.39.71.58.58,0,0,1-.72-.39l-.43-1.43a.59.59,0,0,1,.4-.73.58.58,0,0,1,.72.4Z"
                    fill="#707272"
                />
                <path
                    id="Path_209"
                    data-name="Path 209"
                    d="M381.1,244.68l.26,1.47a.57.57,0,0,1-.46.66.56.56,0,0,1-.66-.46l-.27-1.47a.57.57,0,0,1,.46-.66.58.58,0,0,1,.67.46Z"
                    fill="#707272"
                />
                <path
                    id="Path_210"
                    data-name="Path 210"
                    d="M381.8,249.68l.11,1.49a.57.57,0,0,1-.51.6.56.56,0,0,1-.6-.51l-.12-1.5a.56.56,0,0,1,.52-.6.57.57,0,0,1,.6.51Z"
                    fill="#707272"
                />
                <path
                    id="Path_211"
                    data-name="Path 211"
                    d="M382,254.71v1.5a.55.55,0,1,1-1.1,0v-1.49a.55.55,0,0,1,.55-.55.56.56,0,0,1,.55.56Z"
                    fill="#707272"
                />
                <path
                    id="Path_212"
                    data-name="Path 212"
                    d="M381.75,259.75l-.16,1.49a.54.54,0,0,1-1.07-.12l.16-1.49a.538.538,0,1,1,1.07.11Z"
                    fill="#707272"
                />
                <path
                    id="Path_213"
                    data-name="Path 213"
                    d="M381.06,264.73l-.28,1.47a.53.53,0,0,1-.62.42.52.52,0,0,1-.41-.61l.28-1.47a.52.52,0,1,1,1,.19Z"
                    fill="#707272"
                />
                <path
                    id="Path_214"
                    data-name="Path 214"
                    d="M380,269.65l-.39,1.44a.52.52,0,0,1-.63.36.51.51,0,0,1-.36-.63l.39-1.45a.52.52,0,0,1,.63-.36.5.5,0,0,1,.36.63Z"
                    fill="#707272"
                />
                <path
                    id="Path_215"
                    data-name="Path 215"
                    d="M378.52,274.46l-.51,1.41a.5.5,0,0,1-.94-.34l.49-1.41a.528.528,0,0,1,1,.34Z"
                    fill="#707272"
                />
                <circle
                    id="Ellipse_28"
                    data-name="Ellipse 28"
                    cx="20.81"
                    cy="20.81"
                    r="20.81"
                    transform="translate(337.096 123.961) rotate(-83.08)"
                    fill="#707272"
                />
                <circle
                    id="Ellipse_29"
                    data-name="Ellipse 29"
                    cx="20.12"
                    cy="20.12"
                    r="20.12"
                    transform="matrix(1, -0.024, 0.024, 1, 339.63, 86.204)"
                    fill="#f4f5f6"
                />
                <path
                    id="Path_216"
                    data-name="Path 216"
                    d="M341.7,105h0a.23.23,0,0,1-.21-.23,20.8,20.8,0,0,1,.28-2.36.21.21,0,0,1,.24-.17.22.22,0,0,1,.18.25,17.763,17.763,0,0,0-.27,2.31A.22.22,0,0,1,341.7,105Z"
                    fill="#707272"
                />
                <path
                    id="Path_217"
                    data-name="Path 217"
                    d="M343.65,97.66a.16.16,0,0,1-.09,0,.21.21,0,0,1-.1-.28A18.72,18.72,0,0,1,360.24,87a19.159,19.159,0,0,1,3.39.3.22.22,0,0,1,.17.25.21.21,0,0,1-.25.17,18.37,18.37,0,0,0-19.71,9.8.22.22,0,0,1-.19.14Z"
                    fill="#707272"
                />
                <circle
                    id="Ellipse_30"
                    data-name="Ellipse 30"
                    cx="17.03"
                    cy="17.03"
                    r="17.03"
                    transform="translate(343.65 89.21)"
                    fill="#707272"
                />
                <path
                    id="Path_218"
                    data-name="Path 218"
                    d="M365.67,99.51c0,3-2.42,6.2-5.4,6.2s-5.4-3.22-5.4-6.2a5.41,5.41,0,0,1,10.8,0Z"
                    fill="#f4f5f6"
                />
                <path
                    id="Path_219"
                    data-name="Path 219"
                    d="M353.57,117.19h13.18a3.18,3.18,0,0,0,3-2.22l.71-2.21a3.19,3.19,0,0,0-1.93-4c-5.36-2-10.91-1.71-16.59.18a3.18,3.18,0,0,0-2,3.85l.55,2a3.18,3.18,0,0,0,3.08,2.4Z"
                    fill="#f4f5f6"
                />
                <circle
                    id="Ellipse_31"
                    data-name="Ellipse 31"
                    cx="20.81"
                    cy="20.81"
                    r="20.81"
                    transform="translate(113.634 127.382) rotate(-85.34)"
                    fill="#707272"
                />
                <circle
                    id="Ellipse_32"
                    data-name="Ellipse 32"
                    cx="20.12"
                    cy="20.12"
                    r="20.12"
                    transform="translate(108.501 115.347) rotate(-59.25)"
                    fill="#e4e3e3"
                />
                <path
                    id="Path_220"
                    data-name="Path 220"
                    d="M117.53,107.56h0a.2.2,0,0,1-.2-.22,18.531,18.531,0,0,1,.27-2.36.21.21,0,0,1,.25-.17.22.22,0,0,1,.17.25,17.607,17.607,0,0,0-.27,2.3.22.22,0,0,1-.22.2Z"
                    fill="#707272"
                />
                <path
                    id="Path_221"
                    data-name="Path 221"
                    d="M119.48,100.19h-.09a.22.22,0,0,1-.1-.29,18.72,18.72,0,0,1,16.78-10.33,19.17,19.17,0,0,1,3.39.31.2.2,0,0,1,.17.24.22.22,0,0,1-.25.18,18.38,18.38,0,0,0-19.71,9.79.2.2,0,0,1-.19.1Z"
                    fill="#707272"
                />
                <circle
                    id="Ellipse_33"
                    data-name="Ellipse 33"
                    cx="17.03"
                    cy="17.03"
                    r="17.03"
                    transform="translate(112.426 108.775) rotate(-45)"
                    fill="#707272"
                />
                <path
                    id="Path_222"
                    data-name="Path 222"
                    d="M141.5,102c0,3-2.42,6.2-5.4,6.2s-5.4-3.22-5.4-6.2a5.41,5.41,0,0,1,10.8,0Z"
                    fill="#f4f5f6"
                />
                <path
                    id="Path_223"
                    data-name="Path 223"
                    d="M129.4,119.73h13.18a3.19,3.19,0,0,0,3-2.22l.71-2.22a3.17,3.17,0,0,0-1.93-4c-5.36-2-10.91-1.71-16.59.18a3.18,3.18,0,0,0-2,3.85l.55,2a3.2,3.2,0,0,0,3.08,2.41Z"
                    fill="#f4f5f6"
                />
                <path
                    id="Path_224"
                    data-name="Path 224"
                    d="M367.83,297.49s-5.67,19.24,13.35,29l4.74-2.41s-14.74-10.91-16-24.25a1.25,1.25,0,0,1,.86-1.32l4.24-2.29c.68-.22,1.07-1.57,1.43-2.19l.86-4.8Z"
                    fill="#d6d6d6"
                />
                <circle
                    id="Ellipse_34"
                    data-name="Ellipse 34"
                    cx="26.42"
                    cy="26.42"
                    r="26.42"
                    transform="matrix(0.109, -0.994, 0.994, 0.109, 330.972, 296.062)"
                    fill="#707272"
                />
                <circle
                    id="Ellipse_35"
                    data-name="Ellipse 35"
                    cx="25.54"
                    cy="25.54"
                    r="25.54"
                    transform="matrix(0.267, -0.964, 0.964, 0.267, 328.683, 290.47)"
                    fill="#d6d6d6"
                />
                <path
                    id="Path_225"
                    data-name="Path 225"
                    d="M339.06,262.36a.19.19,0,0,1-.12,0,.27.27,0,0,1-.13-.37,23.75,23.75,0,0,1,21.31-13.12,24.764,24.764,0,0,1,4.31.38.29.29,0,0,1-.11.56,23.519,23.519,0,0,0-4.2-.38,23.21,23.21,0,0,0-20.81,12.81.26.26,0,0,1-.25.12Z"
                    fill="#707272"
                />
                <circle
                    id="Ellipse_36"
                    data-name="Ellipse 36"
                    cx="21.61"
                    cy="21.61"
                    r="21.61"
                    transform="translate(330.12 273.24) rotate(-45)"
                    fill="#707272"
                />
                <path
                    id="Path_226"
                    data-name="Path 226"
                    d="M358.56,289.31V287a19.32,19.32,0,0,1-6.6-1.43,1.35,1.35,0,0,1-.75-1.61l.59-2.19a1.38,1.38,0,0,1,1.87-.9,15.78,15.78,0,0,0,6.09,1.28c2.36,0,4.12-.8,4.12-2.44,0-1.48-1.36-2.36-4.64-3.24-4.68-1.28-8.05-3.32-8.05-7.44,0-3.72,2.73-6.57,7.45-7.33v-2.35a1.37,1.37,0,0,1,1.37-1.37h1.38a1.37,1.37,0,0,1,1.37,1.37v2.11a17.462,17.462,0,0,1,5.23.94,1.37,1.37,0,0,1,.85,1.65l-.55,2a1.36,1.36,0,0,1-1.8.93,14.2,14.2,0,0,0-5.13-.93c-2.8,0-3.6,1.16-3.6,2.2,0,1.28,1.68,2.16,5.32,3.28,5.2,1.56,7.36,3.72,7.36,7.6,0,3.56-2.64,6.77-7.76,7.57v2.63a1.37,1.37,0,0,1-1.37,1.37h-1.38a1.37,1.37,0,0,1-1.37-1.39Z"
                    fill="#f4f5f6"
                />
                <path
                    id="Path_227"
                    data-name="Path 227"
                    d="M384.2,369s10,21.81,15.26,30.84c0,0,1,5.69,0,7.47l-.89.4,4.14-.13s.37-7.64.09-8.07a1.1,1.1,0,0,0-.87-.4S400.15,385,395,382l-5-17.19Z"
                    fill="#d6d6d6"
                />
                <path
                    id="Path_228"
                    data-name="Path 228"
                    d="M396.8,408.78c0,.1,3.23.05,5,0a1.4,1.4,0,0,0,1.34-1.1l1.33-7a1.25,1.25,0,0,0-1-1.46l-.75-.17a4,4,0,0,0-.72,2.83c.33,1.36.63,4.91-.21,5.32a12.119,12.119,0,0,1-3.27.49S396.85,407.88,396.8,408.78Z"
                    fill="#5c5c5c"
                />
                <path
                    id="Path_229"
                    data-name="Path 229"
                    d="M374.72,408.91a.36.36,0,0,0,.18.68c3,0,9.56-.12,10.15-.37.76-.31-.28-3-1.37-4l-6,2Z"
                    fill="#5c5c5c"
                />
                <path
                    id="Path_230"
                    data-name="Path 230"
                    d="M372.85,367.61s6.47,31.28,8.3,37.38l-4.33,2.72s2.17-.37,3.78-.49,3.08-2,3.08-2l-.52-.83s-1.62-31.1-1.22-34.64Z"
                    fill="#d6d6d6"
                />
                <path
                    id="Path_231"
                    data-name="Path 231"
                    d="M356.8,294.66s-.89,16.27,13.92,24.58l-3.56,8.13s-13.4-8.1-12.92-30.19a2.6,2.6,0,0,0-.63-1.77l-2.85-3.31a5.14,5.14,0,0,1-1.09-4.58l.38-1.5a.77.77,0,0,1,1.14-.49l.3.18a5.22,5.22,0,0,1,2.15,2.38l1.43,2.26c.4.88,2.46,1.62,3.45,1.93a.32.32,0,0,1,.13.54Z"
                    fill="#d6d6d6"
                />
                <path
                    id="Path_232"
                    data-name="Path 232"
                    d="M370.21,318c2.48.69,10.87,2.9,16.11,2.81L383.11,339l.25,3-10.49-.05a81.189,81.189,0,0,0-5.28-10.28c-.68-1-.18-2.16.2-3.3l-.52-.34a1.46,1.46,0,0,1-.57-1.64l2.56-7.81a.78.78,0,0,1,.95-.58Z"
                    fill="#707272"
                />
                <path
                    id="Path_233"
                    data-name="Path 233"
                    d="M372,340.35l.21,1.8s-3,8.88,1.48,29.62c0,0,9.63,2.73,17.55-3.8,0,0-2.24-19.58-2.85-20.49-3-4.54-5.06-5.53-5.06-5.53l-.09-2S374.8,341.52,372,340.35Z"
                    fill="#5c5c5c"
                />
                <path
                    id="Path_234"
                    data-name="Path 234"
                    d="M376.14,319.49c0-.05,2.19-6.73,2.19-6.73l3.19.29,1.24,7.57S377.76,321.63,376.14,319.49Z"
                    fill="#d6d6d6"
                />
                <path
                    id="Path_235"
                    data-name="Path 235"
                    d="M380.75,302.23c-.21.13-6.81,2.86-5.4,9.72a1.31,1.31,0,0,0,.74.92,11.24,11.24,0,0,0,5.55.92c3.46-.35,5.73-6.39,5.63-8.15S382.86,300.92,380.75,302.23Z"
                    fill="#d6d6d6"
                />
                <path
                    id="Path_236"
                    data-name="Path 236"
                    d="M382.78,298.76c-.22,0-4.81,1.63-2.53,5s3.13,5.29,2.05,6.75-3.16.57-3,3.34a1.55,1.55,0,0,1-.66,1.41c-2.05,1.39-7.05,5.66-2.09,11.75a1.56,1.56,0,0,1,.17,1.74c-1.45,2.6-4.5,10.23,6.54,14.46s14.85-2.95,15.95-5.8a1.5,1.5,0,0,1,1.5-1c1.2.05,2.9-.15,2.82-1.75a4,4,0,0,0-1.37-2.54,1.52,1.52,0,0,1,.29-2.39,3,3,0,0,0,1.47-1.85c.66-2.45-.66-9.78-7.68-11a1.64,1.64,0,0,1-1.28-1.24h0a1.52,1.52,0,0,1,.57-1.49,3.94,3.94,0,0,0,1.39-5.13,5.74,5.74,0,0,0-4.5-3.4,1.52,1.52,0,0,1-1.25-1.83C391.68,301.41,391.25,297.65,382.78,298.76Z"
                    fill="#5c5c5c"
                />
                <path
                    id="Path_237"
                    data-name="Path 237"
                    d="M137.69,291.22s-2.59,1.26-3.07,2.7c-.38,1.16-3.24,1.31-3.52,3.59a2.63,2.63,0,0,1-2.2,2.31c-2.92.44-7.42,2-8.14,7-.55,3.87,1.42,5.34,3.49,5.85a2.93,2.93,0,0,1,2.23,3.39c-.3,1.47.14,3.18,2.7,4.53,5.72,3,11.43-7.16,11.43-7.16l1.18-17.76Z"
                    fill="#5c5c5c"
                />
                <path
                    id="Path_238"
                    data-name="Path 238"
                    d="M164.62,281.85s-7.36,20.07-19.91,26.35c0,0,4.18,6.81,6.36,7.22,0,0,13.4-17.15,14.77-31.21Z"
                    fill="#d6d6d6"
                />
                <path
                    id="Path_239"
                    data-name="Path 239"
                    d="M114.16,402.6l-.37,3a1.24,1.24,0,0,1-1.1,1.1,19.6,19.6,0,0,1-4.07.07,1.09,1.09,0,0,1-.91-1.4,11.12,11.12,0,0,1,2.05-4h3.37a1,1,0,0,1,1.03,1.23Z"
                    fill="#5c5c5c"
                />
                <path
                    id="Path_240"
                    data-name="Path 240"
                    d="M140.61,366.94l.57,3.35s-1.9,19.33-2.24,27.07a6,6,0,0,0,.89,3.44l3.32,5.39-8.43-5-.17-1,1.38-1.89-1.81-21.66a6.71,6.71,0,0,1,.76-3.68l1.74-3.28Z"
                    fill="#d6d6d6"
                />
                <path
                    id="Path_241"
                    data-name="Path 241"
                    d="M143.71,407H141a1.62,1.62,0,0,1-1.29-.63c-.84-1.09-2.58-3.22-3.41-3.27-1.12-.08-1,3.9-1,3.9h-.62l-.8-4.94a2.92,2.92,0,0,1,1.54-3.06h0a4.12,4.12,0,0,0,1.64,2.73c1.39.83,5.65,3.83,5.65,3.83a4,4,0,0,1,1.09,0,.721.721,0,0,1-.09,1.44Z"
                    fill="#5c5c5c"
                />
                <path
                    id="Path_242"
                    data-name="Path 242"
                    d="M129.06,368.53s-11.18,21.21-13,26.05a46,46,0,0,0-2.42,9.27c0,.6-.78,1.17-2.83,1.17a2,2,0,0,1-1.83-.83,1.2,1.2,0,0,1,0-1.16c.65-1.31,2.7-5.49,3.46-7.39.91-2.27,2.86-12.53,3.93-16.1s2.28-7.44,7-11C123.31,368.53,126.51,365.39,129.06,368.53Z"
                    fill="#d6d6d6"
                />
                <path
                    id="Path_243"
                    data-name="Path 243"
                    d="M136.13,333.77c2.88-12.22-4.43-19.13-4.43-19.13,4.61-4.43,13-6.44,13-6.44s8,5.86,7.07,10.44c-.56,2.69-5.64,16.11-5.64,16.11A11.14,11.14,0,0,1,136.13,333.77Z"
                    fill="#707272"
                />
                <path
                    id="Path_244"
                    data-name="Path 244"
                    d="M129.73,339.82c1.17-3.53,6.4-6.05,6.4-6.05a11.14,11.14,0,0,0,10,1,20,20,0,0,1,.33,7.76c-.73,3.74-5.29,27.78-5.29,27.78C134,371.74,123,368.82,123,368.82,127.53,360.7,128.56,343.36,129.73,339.82Z"
                    fill="#5c5c5c"
                />
                <path
                    id="Path_245"
                    data-name="Path 245"
                    d="M139.47,300.12l-2.34,10.53c-.23.93.62.78,1.56.6,1.53-.29,3.51-.88,4.18-2.08a1.33,1.33,0,0,0,.12-.4l1-7.3a1.32,1.32,0,0,0-.76-1.38l-1.95-.86a1.31,1.31,0,0,0-1.81.89Z"
                    fill="#d6d6d6"
                />
                <path
                    id="Path_246"
                    data-name="Path 246"
                    d="M131.7,314.64s-16.37,11.71-2.09,24a3.64,3.64,0,0,0,1.61.94,17.691,17.691,0,0,1,2.95,2,.57.57,0,0,0,.82-.15h0a.77.77,0,0,0,0-.72l-2-4.29a1.57,1.57,0,0,0-2-.09h0a.77.77,0,0,1-1.17-.26c-1.1-2.25-3.18-8.86,5.35-16.33a.91.91,0,0,0,.2-1.12C134.75,317.48,133.38,315.19,131.7,314.64Z"
                    fill="#d6d6d6"
                />
                <path
                    id="Path_247"
                    data-name="Path 247"
                    d="M142.23,290.09a10.39,10.39,0,0,1,4.66,5.19c1.89,4,.48,5.86-1.29,6.12a8.34,8.34,0,0,1-7.23-2.38C135.57,296.43,138.61,288.7,142.23,290.09Z"
                    fill="#d6d6d6"
                />
                <path
                    id="Path_248"
                    data-name="Path 248"
                    d="M140.26,296.88s-2.1-5.87,3.11-5.94l1.22.85s1.91-3.25-1.22-4.81-6.81-.27-8.14,4-1.56,7.05.66,9.07,3.13-.27,3.13-.27-1.21-1.89-.71-2.9,1.62.21,1.62.21Z"
                    fill="#5c5c5c"
                />
                <path
                    id="Path_249"
                    data-name="Path 249"
                    d="M139.67,297.53s-1-3-2.47-1.82,1.15,2.8,2.15,3.31Z"
                    fill="#d6d6d6"
                />
                <path
                    id="Path_250"
                    data-name="Path 250"
                    d="M165.33,285.5c.11.36-1.19.9-2-.56,0-.7-.46-3.7,0-4.27a56.371,56.371,0,0,1,6.55-6.84,1,1,0,0,1,.13,1.43l-2.1,2.18a10,10,0,0,1,1.23,2.42,2.92,2.92,0,0,1-.3,2.16c-.36.66-.87,1.52-1.34,2.2a2.91,2.91,0,0,1-2.17,1.28Z"
                    fill="#d6d6d6"
                />
                <path
                    id="Path_251"
                    data-name="Path 251"
                    d="M166.45,284l-.5-.09a21.519,21.519,0,0,1,1-4,.89.89,0,0,0,.19-.6.34.34,0,0,0-.14-.18l-1.72,1.45-.58,1.55-.47-.17.62-1.67,2.09-1.77.15.07a1,1,0,0,1,.53.58,1.28,1.28,0,0,1-.24,1,20.422,20.422,0,0,0-.93,3.83Z"
                    fill="#707272"
                    opacity="0.3"
                />
                <path
                    id="Path_252"
                    data-name="Path 252"
                    d="M52.87,315.27s13.93,9.53,16.94,5-12.23-18.36-12.23-18.36Z"
                    fill="#707272"
                />
                <path
                    id="Path_253"
                    data-name="Path 253"
                    d="M49.76,405.09a.86.86,0,0,0,0-.22c-1.05-4.17-6.27-6.76-6.27-6.76l-2.07.16a14.36,14.36,0,0,0-.85,6.93v.08a48.3,48.3,0,0,0,9.19-.19Z"
                    fill="#5c5c5c"
                />
                <path
                    id="Path_254"
                    data-name="Path 254"
                    d="M40.57,395.19l.87,4.22A.69.69,0,0,0,42,400a5.221,5.221,0,0,0,1.36,0,.87.87,0,0,0,.69-1l-.61-3.74Z"
                    fill="#d6d6d6"
                />
                <path
                    id="Path_255"
                    data-name="Path 255"
                    d="M68.08,404.9a.41.41,0,0,0,0-.09,16,16,0,0,0-9.5-6l-2.68-.08a12.82,12.82,0,0,0-1.21,5.9,12.25,12.25,0,0,0,4.08.58,92.727,92.727,0,0,0,9.31-.31Z"
                    fill="#5c5c5c"
                />
                <path
                    id="Path_256"
                    data-name="Path 256"
                    d="M55.36,394.7c0,.2.4,3.23.54,4.41a.57.57,0,0,0,.49.52,5.74,5.74,0,0,0,1.84,0,.78.78,0,0,0,.59-.79v-4.76Z"
                    fill="#d6d6d6"
                />
                <path
                    id="Path_257"
                    data-name="Path 257"
                    d="M53.21,339.41a238.391,238.391,0,0,1,6.34,55.36,1.2,1.2,0,0,1-.95,1.17,13.8,13.8,0,0,1-3,.34,1.1,1.1,0,0,1-1.06-.92c-.9-5.31-6.09-35.19-9-40,0,0-1.94,26.62-1.32,40.42a.66.66,0,0,1-.58.68,11,11,0,0,1-2.4,0,.89.89,0,0,1-.78-.75c-.64-4.49-4.35-31.21-4.19-50.45,0-1.09,2.87-5.81,2.87-5.81Z"
                    fill="#5c5c5c"
                />
                <path
                    id="Path_258"
                    data-name="Path 258"
                    d="M57.66,301.84c-8.88-1.28-18.63-.11-23.36.49a6.22,6.22,0,0,0-3.95,2.12c-4,4.63-12.79,17.32-2.45,30.11a19.251,19.251,0,0,0,4.48-3.28c-1.14-3.23-1.23-7.35,1.16-12.4.09.4.19.79.31,1.18a73.77,73.77,0,0,1,3.48,21.2.65.65,0,0,0,.41.62c4.35,1.74,12.73.7,15.54.28a.94.94,0,0,0,.79-1.07c-1-7.16,2.37-17.64,5-24.52C60.16,313.86,58.21,302.6,57.66,301.84Z"
                    fill="#707272"
                />
                <path
                    id="Path_259"
                    data-name="Path 259"
                    d="M43.07,291.74l-1.23,10.13a1,1,0,0,0,.67,1,10.7,10.7,0,0,0,6.78-.1,1.1,1.1,0,0,0,.66-1.26l-1.55-7.13Z"
                    fill="#d6d6d6"
                />
                <path
                    id="Path_260"
                    data-name="Path 260"
                    d="M47.47,284c.12.1,5.42,9.2,5.42,9.2l-5.25,2.91-6.31-7.87S45.42,282.28,47.47,284Z"
                    fill="#d6d6d6"
                />
                <path
                    id="Path_261"
                    data-name="Path 261"
                    d="M43.73,288.33a.87.87,0,0,0,.11.75l2.95,4.35a1.29,1.29,0,0,0,.16.17c.34.28,1.49,1.09,2.27-.14a7.65,7.65,0,0,1,2-2.43.86.86,0,0,1,1.18.32,31.492,31.492,0,0,1,2.1,4.27.86.86,0,0,1-.44,1.08A24.109,24.109,0,0,1,49,298.51a.88.88,0,0,1-.84-.3c-.89-1.09-3.4-4.12-4.24-4.86s-2.13-.34-2.73-.13a.87.87,0,0,1-.69-.05,1.9,1.9,0,0,1-.88-2.85.88.88,0,0,0-.34-1.16,1.91,1.91,0,0,1-.88-2.66,2.71,2.71,0,0,1,2.83-1.6.9.9,0,0,0,.91-.57.56.56,0,0,1,.42-.38.83.83,0,0,0,.62-1,2.21,2.21,0,0,1,1.09-2.6c2.26-1.42,4.43.55,5,2.29s-1.92,3.13-3.55,3.43C44.54,286.27,44,287.63,43.73,288.33Z"
                    fill="#5c5c5c"
                />
                <path
                    id="Path_262"
                    data-name="Path 262"
                    d="M45,294.94s-1.21-3-2.53-2.79-2.3,1.57-.56,3C44.06,296.85,45,294.94,45,294.94Z"
                    fill="#d6d6d6"
                />
                <path
                    id="Path_263"
                    data-name="Path 263"
                    d="M33.11,318.09A26.87,26.87,0,0,1,32,307.44l.48.06a26.53,26.53,0,0,0,1.08,10.43Z"
                    fill="#5c5c5c"
                />
                <path
                    id="Path_264"
                    data-name="Path 264"
                    d="M45.26,354.7a.24.24,0,0,1-.23-.18c-.54-2.07-4.54-4.33-4.58-4.36a.23.23,0,0,1-.09-.33.24.24,0,0,1,.33-.09c.17.1,4.22,2.39,4.81,4.65a.25.25,0,0,1-.17.3Z"
                    opacity="0.5"
                />
                <path
                    id="Path_265"
                    data-name="Path 265"
                    d="M32.38,331.28a19.251,19.251,0,0,1-4.48,3.28,37.25,37.25,0,0,0,8.19,7.28l.43,1a2.39,2.39,0,0,0,2.07,1.41c4.95.24,6.32-.81,1.77-4C40.36,340.22,34.53,337.32,32.38,331.28Z"
                    fill="#d6d6d6"
                />
                <path
                    id="Path_266"
                    data-name="Path 266"
                    d="M73.77,307.63s-7,5.66-8.72,7.82-.67,6.61,2.71,6,8.56-11.28,8.56-11.28Z"
                    fill="#d6d6d6"
                />
                <path
                    id="Path_267"
                    data-name="Path 267"
                    d="M63,300.11s11.59,11.15,14.55,13.2c3.45,2.39,8-2.32,6.4-4.93s-13.4-10.92-13.4-10.92Z"
                    fill="#707272"
                />
                <path
                    id="Path_268"
                    data-name="Path 268"
                    d="M65.27,286.91l26.76-26.27,13.9,30.21-36,8.15Z"
                    fill="#d6d6d6"
                />
                <ellipse
                    id="Ellipse_37"
                    data-name="Ellipse 37"
                    cx="3.48"
                    cy="17.09"
                    rx="3.48"
                    ry="17.09"
                    transform="translate(88.748 261.797) rotate(-25.34)"
                    fill="#5c5c5c"
                />
                <path
                    id="Path_269"
                    data-name="Path 269"
                    d="M106.27,290c-.42-.16-1.57-1-3.46-3.61A72.69,72.69,0,0,1,93.16,266c-.83-3.13-.74-4.54-.6-5,.43.16,1.57,1,3.47,3.61a65.75,65.75,0,0,1,5.62,9.81,65.5,65.5,0,0,1,4,10.56C106.51,288.18,106.42,289.59,106.27,290Z"
                    fill="#d6d6d6"
                />
                <g id="Group_1" data-name="Group 1" clipPath="url(#clip-path-lead-automations)">
                    <path
                        id="Path_270"
                        data-name="Path 270"
                        d="M103.4,273.51c1.24,2.63.83,5.42-2,6.75a5.27,5.27,0,1,1-4.5-9.5C99.73,269.43,102.15,270.89,103.4,273.51Z"
                        fill="#707272"
                    />
                </g>
                <path
                    id="Path_272"
                    data-name="Path 272"
                    d="M59.8,288.71a19.72,19.72,0,0,1,6.14-2.54,35.6,35.6,0,0,0,6.18,12.43l-6.59,3.12a2.36,2.36,0,0,1-3-.8,33.2,33.2,0,0,1-4.27-8.24,3.5,3.5,0,0,1,1.54-3.97Z"
                    fill="#5c5c5c"
                />
                <path
                    id="Path_273"
                    data-name="Path 273"
                    d="M65.25,290l-5.13,2.44a.63.63,0,0,1-.62-.06.6.6,0,0,1-.24-.57.232.232,0,0,1,.46.06.15.15,0,0,0,.05.14.17.17,0,0,0,.15,0l5.13-2.43a.15.15,0,0,0,.07-.19l-.18-.37a.12.12,0,0,0-.18-.06l-5.17,2.45a.24.24,0,0,1-.31-.11.22.22,0,0,1,.11-.31l5.17-2.45a.6.6,0,0,1,.8.29l.17.36a.59.59,0,0,1-.28.81Z"
                    fill="#d6d6d6"
                />
                <path
                    id="Path_274"
                    data-name="Path 274"
                    d="M65.87,292.48l-5.14,2.44a.61.61,0,0,1-.61-.06.6.6,0,0,1-.24-.57.24.24,0,0,1,.26-.2.25.25,0,0,1,.2.26.15.15,0,0,0,0,.14.17.17,0,0,0,.15,0l5.13-2.43a.15.15,0,0,0,.07-.19l-.18-.37a.12.12,0,0,0-.18-.06L60.2,293.9a.23.23,0,0,1-.3-.11.22.22,0,0,1,.11-.31L65.18,291a.6.6,0,0,1,.8.29l.17.36a.59.59,0,0,1-.28.83Z"
                    fill="#d6d6d6"
                />
                <path
                    id="Path_275"
                    data-name="Path 275"
                    d="M69.57,284a.22.22,0,0,1-.26-.05.24.24,0,0,1,0-.33l20.6-19.2a.22.22,0,0,1,.32,0,.24.24,0,0,1,0,.33l-20.6,19.2A.09.09,0,0,1,69.57,284Z"
                    fill="#707272"
                />
                <path
                    id="Path_276"
                    data-name="Path 276"
                    d="M66.93,286.87a.25.25,0,0,1-.25,0,.23.23,0,0,1,0-.33l1-1.1a.23.23,0,1,1,.34.31l-1,1.1A.2.2,0,0,1,66.93,286.87Z"
                    fill="#707272"
                />
                <path
                    id="Path_277"
                    data-name="Path 277"
                    d="M67.59,294.93l-5.14,2.43a.6.6,0,0,1-.85-.62.22.22,0,0,1,.26-.2.23.23,0,0,1,.19.26.136.136,0,0,0,.21.13l5.13-2.43a.13.13,0,0,0,.06-.19l-.17-.36a.15.15,0,0,0-.19-.07l-5.17,2.45a.21.21,0,0,1-.3-.11.23.23,0,0,1,.11-.31l5.17-2.44a.59.59,0,0,1,.8.28l.17.37a.6.6,0,0,1-.28.81Z"
                    fill="#d6d6d6"
                />
                <path
                    id="Path_278"
                    data-name="Path 278"
                    d="M69.47,297.66l-5.14,2.43a.61.61,0,0,1-.61-.06.6.6,0,0,1-.24-.57.227.227,0,0,1,.45.06.15.15,0,0,0,.06.14.17.17,0,0,0,.15,0l5.13-2.43a.15.15,0,0,0,.07-.19l-.18-.36a.15.15,0,0,0-.19-.07l-5.17,2.45a.231.231,0,0,1-.19-.42l5.17-2.45a.6.6,0,0,1,.8.29l.17.36a.61.61,0,0,1-.28.82Z"
                    fill="#d6d6d6"
                />
                <path
                    id="Path_279"
                    data-name="Path 279"
                    d="M81.66,311.55a3.352,3.352,0,0,1-.77.27,3.76,3.76,0,0,1-3.3-.8l-8.41-7.17a.24.24,0,0,1,0-.33.25.25,0,0,1,.33,0l8.41,7.17a3.31,3.31,0,0,0,2.9.7A3.24,3.24,0,0,0,83,309.51l.07-.16a.23.23,0,0,1,.3-.13.24.24,0,0,1,.13.3,1.224,1.224,0,0,0-.07.17A3.71,3.71,0,0,1,81.66,311.55Z"
                    fill="#d6d6d6"
                />
                <path
                    id="Path_280"
                    data-name="Path 280"
                    d="M68.18,302.82a.23.23,0,0,1-.25,0L67,302a.23.23,0,0,1,.3-.35l.94.8a.22.22,0,0,1,0,.32A.16.16,0,0,1,68.18,302.82Z"
                    fill="#d6d6d6"
                />
                <path
                    id="Path_281"
                    data-name="Path 281"
                    d="M102.62,273.81a.23.23,0,0,1-.3-.1,4.07,4.07,0,0,0-2.62-2.18,5.12,5.12,0,0,0-3.58.88.248.248,0,0,1-.32-.37,5.43,5.43,0,0,1,4-1,4.49,4.49,0,0,1,2.92,2.4.24.24,0,0,1-.09.32Z"
                    fill="#d6d6d6"
                />
                <path
                    id="Path_282"
                    data-name="Path 282"
                    d="M76.42,303.16s1.77-1.51,3.17,0,1.66,4.15,1.38,4.28S72.09,305.43,76.42,303.16Z"
                    fill="#d6d6d6"
                />
                <path
                    id="Path_283"
                    data-name="Path 283"
                    d="M126.19,263.46l-11.29-.64,1.59,3.59-7.53,1.87,12.44,3.26-1.74-5.03Z"
                    fill="url(#linear-lead-automations)"
                />
                <path
                    id="Path_284"
                    data-name="Path 284"
                    d="M125.2,280.44l-8.32-3.98.09,3.19-6.22-.92,8.36,6.3.24-4.32Z"
                    fill="url(#linear-lead-automations-2)"
                />
                <path
                    id="Path_285"
                    data-name="Path 285"
                    d="M110.95,250.45l-7.55,5.29,2.82,1.49-3.86,4.97,9.59-4.2-3.64-2.33Z"
                    fill="url(#linear-lead-automations-3)"
                />
                <circle
                    id="Ellipse_38"
                    data-name="Ellipse 38"
                    cx="5.71"
                    cy="5.71"
                    r="5.71"
                    transform="translate(29.81 118.51)"
                    fill="#fff"
                    stroke="#e2e2e2"
                    strokeMiterlimit="10"
                    strokeWidth="2"
                />
                <circle
                    id="Ellipse_39"
                    data-name="Ellipse 39"
                    cx="8.91"
                    cy="8.91"
                    r="8.91"
                    transform="translate(3.38 81.28)"
                    fill="#fff"
                    stroke="#e2e2e2"
                    strokeMiterlimit="10"
                    strokeWidth="2"
                />
                <circle
                    id="Ellipse_40"
                    data-name="Ellipse 40"
                    cx="6.37"
                    cy="6.37"
                    r="6.37"
                    transform="translate(465.01 257.96)"
                    fill="#fff"
                    stroke="#e2e2e2"
                    strokeMiterlimit="10"
                    strokeWidth="2"
                />
                <circle
                    id="Ellipse_41"
                    data-name="Ellipse 41"
                    cx="9.26"
                    cy="9.26"
                    r="9.26"
                    transform="translate(446.73 76.47)"
                    fill="#fff"
                    stroke="#e2e2e2"
                    strokeMiterlimit="10"
                    strokeWidth="2"
                />
                <path
                    id="Path_286"
                    data-name="Path 286"
                    d="M18.05,261.07H12.93V256a.64.64,0,0,0-.64-.63h0a.65.65,0,0,0-.65.63v5.12H6.53a.65.65,0,0,0-.64.65.64.64,0,0,0,.64.63h5.11v5.12a.65.65,0,0,0,.65.64.64.64,0,0,0,.64-.64h0V262.4h5.12a.64.64,0,0,0,.64-.63.65.65,0,0,0-.64-.7Z"
                    fill="#e2e2e2"
                    stroke="#e2e2e2"
                    strokeMiterlimit="10"
                    strokeWidth="0.75"
                />
                <path
                    id="Path_287"
                    data-name="Path 287"
                    d="M423,131.65h-4.53v-4.54a.57.57,0,0,0-.57-.56h0a.58.58,0,0,0-.58.56v4.54H412.8a.57.57,0,0,0-.57.57.58.58,0,0,0,.57.57h4.52v4.53a.58.58,0,0,0,.58.57.57.57,0,0,0,.57-.57h0v-4.53H423a.57.57,0,0,0,0-1.14Z"
                    fill="#e2e2e2"
                    stroke="#e2e2e2"
                    strokeMiterlimit="10"
                    strokeWidth="0.75"
                />
                <path
                    id="Path_288"
                    data-name="Path 288"
                    d="M468.82,147.54h-8.6V139a1.06,1.06,0,0,0-1.06-1.07h0a1.1,1.1,0,0,0-1.1,1.07v8.58h-8.57a1.08,1.08,0,1,0,0,2.16h8.57v8.6a1.1,1.1,0,0,0,1.1,1.07,1.06,1.06,0,0,0,1.06-1.07h0v-8.6h8.6a1.08,1.08,0,1,0,0-2.16Z"
                    fill="#e2e2e2"
                    stroke="#e2e2e2"
                    strokeMiterlimit="10"
                    strokeWidth="0.75"
                />
            </g>
        </SVGIcon>
    );
}
