import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';

import App from './App';
import PubSubProvider from './Hooks/PubSub/Provider';
import ReactQueryProvider from './Hooks/ReactQuery';
import { GlobalConversationsContextProvider, ThemeContextProvider } from 'Contexts/Provider/index';
import { IntercomProvider } from 'react-use-intercom';

if ('serviceWorker' in navigator) {
    navigator.serviceWorker
        .register('/firebase-messaging-sw.js')
        .then(() => {
            // service worker registered
        })
        .catch(err => {
            console.error('err:', err);
        });
}

ReactDOM.render(
    <PubSubProvider>
        <ThemeContextProvider>
            <ReactQueryProvider>
                <BrowserRouter>
                    <IntercomProvider appId={'gy1hlcgu'}>
                        <GlobalConversationsContextProvider>
                            <App />
                        </GlobalConversationsContextProvider>
                    </IntercomProvider>
                </BrowserRouter>
            </ReactQueryProvider>
        </ThemeContextProvider>
    </PubSubProvider>,
    document.getElementById('root'),
);
