import React from 'react';

import { SVGIcon } from '../../UIKit/index';

export default function DCWatch2(props) {
    return (
        <SVGIcon {...props} viewBox="0 0 512 512">
            <g>
                <path d="M270.6,250.1v-82.5c0-8.2-6.6-14.8-14.8-14.8c-8.2,0-14.8,6.6-14.8,14.8v88.6   c0,3.9,1.6,7.7,4.3,10.5l59.1,59.1c5.3,6.2,14.7,6.8,20.8,1.5c6.2-5.3,6.8-14.7,1.5-20.8c-0.5-0.5-1-1-1.5-1.5L270.6,250.1z    M255.8,64.2c-106,0-192,86-192,192s86,192,192,192c106,0,192-86,192-192C447.7,150.3,361.8,64.4,255.8,64.2z M380.6,360.1   l-9.9-9.9c-5.7-5.8-15-6-20.9-0.3c-5.8,5.7-6,15-0.3,20.9c0.1,0.1,0.2,0.2,0.3,0.3l9.9,9.9c-25.3,21.1-56.3,34-89.1,36.9v-14   c0-8.2-6.6-14.8-14.8-14.8c-8.2,0-14.8,6.6-14.8,14.8v14c-32.8-3-63.9-15.9-89.1-36.9l9.9-9.9c5.8-5.7,6-15,0.3-20.9   s-15-6-20.9-0.3c-0.1,0.1-0.2,0.2-0.3,0.3l-9.9,9.9c-21.1-25.3-34-56.3-36.9-89.1h14c8.2,0,14.8-6.6,14.8-14.8s-6.6-14.8-14.8-14.8   h-14c3-32.8,15.9-63.9,36.9-89.1l9.9,9.9c6.1,5.4,15.5,4.8,20.8-1.3c4.9-5.6,4.9-13.9,0-19.5l-9.9-9.9c25.3-21.1,56.3-34,89.1-36.9   v14c0,8.2,6.6,14.8,14.8,14.8c8.2,0,14.8-6.6,14.8-14.8v-14c32.8,3,63.9,15.9,89.1,36.9l-9.9,9.9c-6,5.5-6.4,14.9-0.8,20.9   c5.5,6,14.9,6.4,20.9,0.8c0.3-0.3,0.6-0.6,0.8-0.9l9.9-9.9c21.1,25.3,34,56.3,36.9,89.1h-14c-8.2,0-14.8,6.6-14.8,14.8   c0,8.2,6.6,14.8,14.8,14.8h14C414.6,303.8,401.7,334.9,380.6,360.1L380.6,360.1z M255.8,4.2C116.6,4.2,3.8,117,3.8,256.2   s112.9,252.1,252.1,252.1c139.2,0,252.1-112.9,252.1-252.1c0,0,0,0,0,0C507.4,117.3,394.8,4.7,255.8,4.2z M255.8,478.8   c-122.9,0-222.5-99.6-222.5-222.5c0-122.9,99.6-222.5,222.5-222.5c122.9,0,222.5,99.6,222.5,222.5c0,0,0,0,0,0   C477.9,378.9,378.5,478.3,255.8,478.8z" />
            </g>
        </SVGIcon>
    );
}
