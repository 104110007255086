import React from 'react';

import { SVGIcon } from '../../UIKit/index';

export default function DCGoogle(props) {
    return (
        <SVGIcon {...props} viewBox="0 0 512 512">
            <g transform="translate(0 0)">
                <path
                    fill="#FBBD00"
                    d="M121.4,256.51c-0.03-24.23,6.52-48.01,18.95-68.81v-85.46H54.89   c-69.79,91.04-69.79,217.59,0,308.63h85.46V325.4C127.91,304.58,121.36,280.76,121.4,256.51z"
                />
                <path
                    fill="#0F9D58"
                    d="M256.08,391.19l-59.41,59.41l59.41,59.41c55.82,0.17,110.1-18.24,154.31-52.31v-85.37h-85.37   C304.16,384.71,280.34,391.23,256.08,391.19z"
                />
                <path
                    fill="#31AA52"
                    d="M140.35,325.31l-85.46,85.46c6.77,8.78,14.1,17.12,21.94,24.96   c47.44,47.7,112,74.44,179.28,74.25v-118.8C208.6,391.17,164.62,366.14,140.35,325.31z"
                />
                <path
                    fill="#3C79E6"
                    d="M509.59,256.51c0-15.4-1.39-30.78-4.15-45.93l-2.27-12.19H256.08v118.82h120.27   c-11.51,22.9-29.34,42.02-51.38,55.1l85.37,85.37c8.78-6.77,17.12-14.1,24.96-21.94C483.01,388.31,509.75,323.77,509.59,256.51z"
                />
                <path
                    fill="#CF2D48"
                    d="M351.33,161.29l10.51,10.51l83.96-83.96l-10.51-10.51C387.87,29.61,323.35,2.85,256.08,3   l-59.41,59.41l59.41,59.41C291.83,121.74,326.12,135.95,351.33,161.29z"
                />
                <path
                    fill="#EB4132"
                    d="M256.08,121.82V3C188.81,2.82,124.25,29.55,76.81,77.25c-7.85,7.84-15.18,16.18-21.94,24.96   l85.46,85.46c24.29-40.8,68.26-65.8,115.73-65.81L256.08,121.82z"
                />
            </g>
        </SVGIcon>
    );
}
