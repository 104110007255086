import React, { Fragment, useState, useCallback, useRef } from 'react';
import { Div, Popover } from '../index';
import { SketchPicker } from 'react-color';
import PropTypes from 'prop-types';
import './style.scss';

const ColorPickerMain = ({
    className,
    label,
    selectedColor,
    handleColorChange,
    readOnly,
    customPlacement,
}) => {
    const [colorPickerPopover, setColorPickerPopover] = useState(false);
    const cpBtnRef = useRef(null);

    const handleColorPickerPopoverToggle = useCallback(() => {
        if (!readOnly) setColorPickerPopover(prevOpen => !prevOpen);
    }, [readOnly]);

    return (
        <Fragment>
            <Popover
                open={colorPickerPopover}
                anchorEl={cpBtnRef}
                onClose={handleColorPickerPopoverToggle}
                width={220}
                placement={customPlacement || 'right-center'}
                withOutPadding
            >
                <SketchPicker
                    disableAlpha={true}
                    color={selectedColor || '#000000'}
                    onChange={col => {
                        handleColorChange(col.hex);
                    }}
                />
            </Popover>
            <Div className={'ColorPickerMain ' + (className ? className : '')}>
                {label && <Div className={'CPMLabel'}>{label}</Div>}
                <Div
                    className={'CPMLColor'}
                    style={{ backgroundColor: selectedColor }}
                    reference={cpBtnRef}
                    onClick={handleColorPickerPopoverToggle}
                />
            </Div>
        </Fragment>
    );
};

ColorPickerMain.propTypes = {
    className: PropTypes.string,
    label: PropTypes.string,
    selectedColor: PropTypes.string,
    handleColorChange: PropTypes.func,
    readOnly: PropTypes.bool,
    customPlacement: PropTypes.string,
};

export default ColorPickerMain;
