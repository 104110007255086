import React from 'react';

import { SVGIcon } from '../../UIKit/index';

export default function DCCheck(props) {
    return (
        <SVGIcon {...props} viewBox="0 0 512 512">
            <path d="M15.32,276.83c0,0-10.7-50.04,42.4-38.2l140.57,134.08h3.15L447.73,72.85c0,0,17.38-17.19,34.57-6.49  c0,0,27.31,14.32,14.13,41.45L222.99,440.83c0,0-14.26,14.52-34.89,4.07L16.97,280.65C16.97,280.65,15.51,277.9,15.32,276.83z" />
        </SVGIcon>
    );
}
