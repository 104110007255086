import { getUserDetails, isLocalStorageAvailable } from 'Utils/localStorageUtils';
import { defaultDateFormat } from 'Constants/settings';

export const dateFormat =
    isLocalStorageAvailable() && getUserDetails()?.preferences?.date_number_format
        ? getUserDetails().preferences.date_number_format
        : defaultDateFormat;

export const calenderConfig = {
    sameDay: '[Today]',
    nextDay: '[Tomorrow]',
    lastDay: '[Yesterday]',
    nextWeek: dateFormat,
    lastWeek: dateFormat,
    sameElse: dateFormat,
};

export const getSegmentedReminders = (reminders: any[]) => {
    let tempRemindersObj: any = {};
    reminders.forEach(el => {
        const onlyDate = el.due_date_time.substring(0, 10);
        if (!tempRemindersObj[onlyDate]) {
            tempRemindersObj[onlyDate] = [];
        }
        tempRemindersObj[onlyDate].push(el);
    });
    return tempRemindersObj;
};
