import React from 'react';

import DCBarChart1 from '../../Assets/icons/DCBarChart1';
import DCBattery from '../../Assets/icons/DCBattery';
import DCUserPlaceholder from '../../Assets/icons/DCUserPlaceholder';
import MobileViewImg from '../../Assets/images/mobile.png';
import { Div, Image, Span, Strong } from '../../UIKit/index';
import PropTypes from 'prop-types';
import './style.scss';

const MobileView = ({ htmlContent, from, to, subject, type, fallbackBody, fallbackSubject }) => {
    const PERSONALISE_REGEX = /{{[\w\u0590-\u05ff]+-[\w \/\u0590-\u05ff]+}}/g;
    const allEntity = new Set();
    const subEntity = new Set();
    const text = htmlContent;
    let fallbackBody1 = {};
    Object.keys(fallbackBody).map(item => {
        let tmpfallback = item?.trim();
        fallbackBody1[tmpfallback] = fallbackBody[item];
    });
    let matchArr, start;
    while ((matchArr = PERSONALISE_REGEX.exec(text)) !== null) {
        start = matchArr.index;
        allEntity.add(text.substring(start, start + matchArr[0].length));
    }
    const text1 = subject;
    let matchArr1, start1;
    while ((matchArr1 = PERSONALISE_REGEX.exec(text1)) !== null) {
        start1 = matchArr1.index;
        subEntity.add(text1.substring(start1, start1 + matchArr1[0].length));
    }
    let sub = '';
    [...allEntity].map(item => {
        if (fallbackBody1?.hasOwnProperty(item)) {
            if (!fallbackBody1[item]) {
                htmlContent = htmlContent.replaceAll(
                    item,
                    `<span class="MobilePersonalise">${item.replace(/{{|}}/g, '')}</span>`,
                );
            } else {
                htmlContent = htmlContent.replaceAll(
                    item,
                    `<span class="MobilePersonaliseSaved">${item.replace(/{{|}}/g, '')}</span>`,
                );
            }
        } else {
            htmlContent = htmlContent.replaceAll(
                item,
                `<span class="MobilePersonalise">${item.replace(/{{|}}/g, '')}</span>`,
            );
        }
    });
    [...subEntity].map(item => {
        if (fallbackSubject?.hasOwnProperty(item)) {
            subject = subject.replaceAll(
                item,
                `<span class="MobilePersonaliseSaved">${item.replace(/{{|}}/g, '')}</span>`,
            );
        } else {
            subject = subject.replaceAll(
                item,
                `<span class="MobilePersonalise">${item.replace(/{{|}}/g, '')}</span>`,
            );
        }
    });
    sub = htmlContent;
    return (
        <Div className={'MobileView'}>
            <Image src={MobileViewImg} alt={''} className={'MobileViewImg'} displayName={''} />
            <Div className={'MobileViewMob'}>
                <Div className={'MobileViewMPreview'}>
                    <Div className={'MobileViewMPMobFeature'}>
                        <Div className={'MobileViewMPMFTime'} />
                        <Div className={'MobileViewMPMFBT'}>
                            <DCBarChart1 />
                            <DCBattery />
                        </Div>
                    </Div>
                    <Div className={'MobileViewMEmailView'}>
                        <Div className={'MobileViewMEVHead'}>
                            {from && (
                                <Div className={'MobileViewMEVHOption'}>
                                    <Strong>From : </Strong> <Span>{from} </Span>
                                </Div>
                            )}
                            {to && (
                                <Div className={'MobileViewMEVHOption'}>
                                    <Strong>To : </Strong> <Span>{to}</Span>
                                </Div>
                            )}
                            {subject &&
                                (subject.includes('<div>') ? (
                                    <Div
                                        className={'MobileViewMEVHOption'}
                                        dangerouslySetInnerHTML={{
                                            __html: subject
                                                .replace(
                                                    '<div>',
                                                    '<div><strong style="min-width:60px;margin-right:10px">Subject:</strong>',
                                                )
                                                .replace(/{{|}}/g, ''),
                                        }}
                                    />
                                ) : (
                                    <Div
                                        className={'MobileViewMEVHOption'}
                                        dangerouslySetInnerHTML={{
                                            __html: `<div><strong style="min-width:60px;margin-right:10px">Subject:</strong>${subject}</div>`,
                                        }}
                                    />
                                ))}
                        </Div>
                        {type && type === 'sms' ? (
                            htmlContent && (
                                <Div className={'MVEVMSMSView'}>
                                    <Div className={'MVEVMSMSVUser'}>
                                        <DCUserPlaceholder />
                                    </Div>
                                    <Div
                                        className={'MVEVMSMSVContent'}
                                        dangerouslySetInnerHTML={{
                                            __html: sub,
                                        }}
                                    />
                                </Div>
                            )
                        ) : (
                            <Div
                                className={'MobileViewMEVMsg'}
                                dangerouslySetInnerHTML={{
                                    __html: sub,
                                }}
                            />
                        )}
                    </Div>
                </Div>
            </Div>
        </Div>
    );
};

MobileView.propTypes = {
    htmlContent: PropTypes.string,
    from: PropTypes.string,
    to: PropTypes.string,
    subject: PropTypes.string,
    type: PropTypes.string,
};

export default MobileView;

//   <MobileView
//       htmlContent={html}
//       from={"dprajapat@dashclicks.com"}
//       to={"amittal@dashclicks.com"}
//       subject={"Email Subject"}
//   />;
