export const contractOptions = [
    {
        id: 0,
        label: 'One-Time',
        value: 'one_time',
    },
    {
        id: 1,
        label: 'Monthly',
        value: 'monthly',
    },
    {
        id: 2,
        label: 'Yearly',
        value: 'yearly',
    },
];

export const views = {
    BOARD: 'board',
    TABLE: 'table',
    LIST: 'list',
};

export const actions = {
    AUTOMATION: 'automation',
};
