import React from 'react';

import { SVGIcon } from '../../UIKit/index';

export default function DCTickets(props) {
    return (
        <SVGIcon {...props} viewBox="0 0 512 512">
            <g>
                <path d="M18.14,91.62v126.89v0.65h20.7c0,0,30.49,0.49,34.22,34.22v4.11c0,0-0.49,27.19-30.65,33.68H18.14v127.38   h474.66V292.46v-0.81h-20.28c0,0-30.22-0.81-35.08-33.89c0,0,0.32-34.7,32.76-38.59l22.6,0.16V91.62H18.49l36.41,36.19H455.7v57.62   c0,0-49.08,13.95-53.62,63.57c0,0-7.57,57.3,53.92,75.65v57.97H54.84v-57.73c0,0,44.32-8.92,54.22-57.57c0,0,9.57-64.81-54-81.24   v-67.89L18.14,91.62z" />
            </g>
        </SVGIcon>
    );
}
