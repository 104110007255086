import React from 'react';

import { SVGIcon } from '../../UIKit/index';

export default function DCReverseBar(props) {
    return (
        <SVGIcon {...props} viewBox="0 0 429 512">
            <rect y="0" width="117" height="393.85" />
            <rect x="156" y="0" width="117" height="512" />
            <rect x="312" y="0" width="117" height="275.69" />
        </SVGIcon>
    );
}
