import React from 'react';

import { SVGIcon } from '../../UIKit/index';

export default function DCSocialMedia1(props) {
    return (
        <SVGIcon {...props} viewBox="0 0 512 512">
            <g transform="translate(0 0)">
                <path
                    fill="#2B88F8"
                    d="M185.2,202.64v-52.86c0-19.47,15.78-35.25,35.25-35.25l0,0l0,0c22.18,11.09,48.3,11.09,70.48,0   l0,0c19.47,0,35.25,15.78,35.25,35.25c0,0,0,0,0,0v52.86c0,9.73-7.89,17.62-17.62,17.62H202.82   C193.09,220.26,185.2,212.37,185.2,202.64z"
                />
                <circle fill="#6B8AAA" cx="255.69" cy="44.05" r="44.05" />
                <g transform="translate(4.898 4.917)">
                    <path
                        fill="#D9EAFF"
                        d="M48.15,259.4c-4.87,0-8.81-3.95-8.81-8.81l0,0c-0.08-84.84,50.61-161.5,128.71-194.65    c4.58-1.66,9.63,0.7,11.29,5.28c1.53,4.22-0.36,8.92-4.38,10.91l0,0c-71.59,30.4-118.05,100.68-117.97,178.46    c0,4.87-3.95,8.81-8.81,8.81C48.16,259.4,48.15,259.4,48.15,259.4z"
                    />
                    <path
                        fill="#D9EAFF"
                        d="M453.43,259.4c-4.87,0-8.81-3.95-8.81-8.81c0,0,0,0,0,0c0.07-77.78-46.39-148.05-117.99-178.44    c-4.58-1.66-6.94-6.71-5.28-11.29c1.66-4.58,6.71-6.94,11.29-5.28c0.31,0.11,0.61,0.24,0.9,0.38    c78.1,33.15,128.78,109.81,128.71,194.65C462.23,255.47,458.29,259.4,453.43,259.4z"
                    />
                    <path
                        fill="#D9EAFF"
                        d="M250.79,462.03c-28.43,0.05-56.58-5.66-82.74-16.8c-4.36-2.16-6.15-7.44-4-11.81    c1.99-4.03,6.69-5.91,10.91-4.38c48.5,20.42,103.19,20.42,151.68,0c4.58-1.66,9.63,0.71,11.29,5.28    c1.53,4.22-0.36,8.92-4.38,10.91C307.38,456.37,279.23,462.09,250.79,462.03z"
                    />
                    <path
                        fill="#D9EAFF"
                        d="M189.11,479.66c-3.29,0.01-6.3-1.82-7.82-4.73l-17.62-33.72c-2.25-4.31-0.59-9.63,3.72-11.88    c0.52-0.27,1.05-0.49,1.61-0.66l35.25-10.33c4.67-1.37,9.57,1.31,10.94,5.98c1.37,4.67-1.31,9.57-5.98,10.94l-24.94,7.31    l12.66,24.2c2.25,4.32,0.58,9.64-3.74,11.89C191.93,479.32,190.53,479.66,189.11,479.66L189.11,479.66z"
                    />
                    <path
                        fill="#D9EAFF"
                        d="M453.43,259.4c-2.34,0-4.58-0.93-6.23-2.58l-26.43-26.43c-3.31-3.57-3.11-9.14,0.46-12.46    c3.38-3.14,8.62-3.14,12,0l20.2,20.2l20.2-20.2c3.57-3.31,9.14-3.11,12.46,0.46c3.14,3.38,3.14,8.62,0,12l-26.41,26.46    C458.01,258.49,455.76,259.41,453.43,259.4z"
                    />
                    <path
                        fill="#D9EAFF"
                        d="M153.87,109.62c-1.32-0.01-2.62-0.31-3.8-0.9c-4.38-2.11-6.22-7.37-4.12-11.75l13.73-28.67    l-27.07-12.34c-4.33-2.23-6.03-7.54-3.8-11.87c2.08-4.04,6.89-5.83,11.11-4.15l35.21,16.13c4.42,2.04,6.35,7.27,4.32,11.69    l-0.05,0.11l-17.62,36.77C160.31,107.66,157.25,109.6,153.87,109.62z"
                    />
                </g>
                <path
                    fill="#D9EAFF"
                    stroke="#D9EAFF"
                    strokeWidth="0.1"
                    d="M282.01,8.91c14.63,19.31,10.84,46.83-8.47,61.46c-15.67,11.88-37.33,11.88-53,0   c14.46,19.48,41.98,23.56,61.46,9.1c19.48-14.46,23.56-41.98,9.1-61.46C288.54,14.55,285.47,11.48,282.01,8.91z"
                />
                <circle fill="#6B8AAA" cx="440.7" cy="334.79" r="44.05" />
                <path
                    fill="#D9EAFF"
                    stroke="#D9EAFF"
                    strokeWidth="0.1"
                    d="M467.03,299.66c14.63,19.31,10.84,46.83-8.47,61.46c-15.67,11.88-37.33,11.88-53,0   c14.46,19.48,41.98,23.56,61.46,9.1c19.48-14.46,23.56-41.98,9.1-61.46C473.56,305.3,470.49,302.23,467.03,299.66z"
                />
                <path
                    fill="#6B8AAA"
                    d="M70.68,290.74c24.34,0,44.07,19.73,44.07,44.07c0,24.34-19.73,44.07-44.07,44.07   s-44.07-19.73-44.07-44.07C26.61,310.47,46.34,290.74,70.68,290.74C70.68,290.74,70.68,290.74,70.68,290.74z"
                />
                <path
                    fill="#D9EAFF"
                    stroke="#D9EAFF"
                    strokeWidth="0.1"
                    d="M96.99,299.66c14.63,19.31,10.84,46.83-8.47,61.46c-15.67,11.88-37.33,11.88-53,0   c14.46,19.48,41.98,23.56,61.46,9.1c19.48-14.46,23.56-41.98,9.1-61.46C103.51,305.3,100.45,302.23,96.99,299.66z"
                />
                <path
                    fill="#005EFF"
                    d="M185.2,202.64c0,9.73,7.89,17.62,17.62,17.62h105.73c9.73,0,17.62-7.89,17.62-17.62v-52.86   c-0.03-6.13-1.68-12.14-4.78-17.43C290.05,176.44,239.3,202.64,185.2,202.64z"
                />
                <path
                    fill="#2B88F8"
                    d="M0.18,493.39v-52.86c0-19.47,15.78-35.25,35.25-35.25c0,0,0,0,0,0l0,0   c22.19,11.09,48.3,11.09,70.48,0l0,0c19.47,0,35.25,15.78,35.25,35.25l0,0v52.86c0,9.73-7.89,17.62-17.62,17.62c0,0,0,0,0,0H17.8   C8.07,511.01,0.18,503.12,0.18,493.39C0.18,493.39,0.18,493.39,0.18,493.39z"
                />
                <path
                    fill="#005EFF"
                    d="M0.18,493.39c0,9.73,7.89,17.62,17.62,17.62c0,0,0,0,0,0h105.73c9.73,0,17.62-7.89,17.62-17.62   l0,0v-52.86c-0.03-6.13-1.68-12.14-4.78-17.43C105.02,467.2,54.28,493.39,0.18,493.39z"
                />
                <path
                    fill="#2B88F8"
                    d="M370.22,493.39v-52.86c0-19.47,15.78-35.25,35.25-35.25l0,0l0,0c22.19,11.09,48.3,11.09,70.48,0   l0,0c19.47,0,35.25,15.78,35.25,35.25l0,0v52.86c0,9.73-7.89,17.62-17.62,17.62h0H387.84   C378.11,511.01,370.22,503.12,370.22,493.39z"
                />
                <path
                    fill="#005EFF"
                    d="M370.22,493.39c0,9.73,7.89,17.62,17.62,17.62h0h105.73c9.73,0,17.62-7.89,17.62-17.62v0v-52.86   c-0.03-6.13-1.68-12.14-4.78-17.43C475.06,467.2,424.31,493.39,370.22,493.39z"
                />
            </g>
        </SVGIcon>
    );
}
