import React from 'react';

import { SVGIcon } from '../../UIKit/index';

export default function DCUndo(props) {
    return (
        <SVGIcon {...props} viewBox="0 0 512 512">
            <path d="M256.22,2c-61.8,0.39-121.56,22.14-169.14,61.56L30.16,6.66c-6.18-6.19-16.2-6.19-22.39-0.01  C4.81,9.6,3.14,13.62,3.13,17.8v137.1c0,8.74,7.09,15.83,15.83,15.83h137.1c8.73,0.01,15.82-7.05,15.83-15.78  c0.01-4.21-1.67-8.25-4.65-11.22l-50.29-50.21c39.55-31.66,88.61-49.05,139.27-49.35c116.49-0.05,210.96,94.34,211.01,210.83  C467.28,371.48,372.88,465.95,256.4,466c-55.99,0.02-109.7-22.22-149.28-61.82c-8.13-8.34-21.48-8.5-29.81-0.37  c-8.34,8.13-8.5,21.48-0.37,29.81c0.12,0.12,0.24,0.24,0.36,0.36c98.82,98.83,259.04,98.85,357.88,0.03s98.85-259.04,0.03-357.88  C387.74,28.66,323.35,1.99,256.22,2z" />
        </SVGIcon>
    );
}
