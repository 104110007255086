import { instaReportActions } from '../Actions';

const instaReportReducer = (state, action) => {
    switch (action.type) {
        case instaReportActions.SET_STATUS:
            return { ...state, status: action.value };
        case instaReportActions.SET_INITIAL_MY_INSTAREPORT_LOAD:
            return { ...state, initialMyInstareportLoad: action.value };
        case instaReportActions.SET_FIELDS:
            return { ...state, fields: action.value };
        case instaReportActions.SET_PAGE:
            return { ...state, page: action.value };

        case instaReportActions.SET_TOTAL_RESULTS:
            return { ...state, totalResults: action.value };
        case instaReportActions.SET_TOTAL_PAGES:
            return { ...state, totalPages: action.value };
        case instaReportActions.SET_PAGE_LIMIT:
            return { ...state, pageLimit: action.value };
        case instaReportActions.SET_DATA:
            return { ...state, data: action.value };
        case instaReportActions.SET_SORT_STRING:
            return { ...state, sortString: action.value };

        case instaReportActions.SET_MY_INSTAREPORT_SEARCH_TEXT:
            return { ...state, myInstaReportSearchText: action.value };

        case instaReportActions.SET_SELECTED_INSTAREPORT:
            return { ...state, selectedInstaReport: action.value };
        case instaReportActions.SET_STATUS_STRING:
            return { ...state, statusString: action.value };
        case instaReportActions.SET_ADDITIONAL_INFO:
            return { ...state, additionalInfo: action.value };

        default:
            return;
    }
};

export default instaReportReducer;
