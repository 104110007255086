import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { formatNumberString } from '../../Utils/index';
import { Div } from '../../UIKit/index';

import './style.scss';

const CircleChart = ({ className, height, chartData, width, seriesLabels, title, subtitle }) => {
    const ChartOptions = {
        chart: {
            type: 'pie',
            height: height || 200,
            width: width || '',
        },
        tooltip: {
            backgroundColor: '#ffffff',
            opacity: 1,
            formatter: function () {
                return this.point.name + ' : ' + formatNumberString(this.y);
            },
        },
        title: title
            ? title
            : {
                  text: null,
              },
        subtitle: subtitle
            ? subtitle
            : {
                  text: null,
              },
        accessibility: {
            point: {
                valueSuffix: '%',
            },
        },

        plotOptions: {
            pie: {
                innerSize: '70%',
                depth: 45,
                startAngle: 0,
                endAngle: 360,
                size: '100%',
                dataLabels: {
                    enabled: false,
                },
            },
        },
        credits: {
            enabled: false,
        },
        legend: {
            enabled: false,
        },
        series: chartData,
    };

    return (
        <Div className={'CircleChart ' + (className ? className : '')}>
            <Div className={'CircleChartGraph'}>
                <HighchartsReact highcharts={Highcharts} options={ChartOptions} />
            </Div>
            {seriesLabels === true ? (
                <Div className={'ChartSeries'}>
                    {chartData.map((item, index) => (
                        <Fragment key={index}>
                            {item.data.map((series, seriesIndex) => (
                                <Div className={'CSItem'} key={seriesIndex}>
                                    {series.color && (
                                        <Div
                                            className={'CSIDot'}
                                            style={{
                                                backgroundColor: series.color,
                                                boxShadow: `0 0 5px  ${series.color}`,
                                            }}
                                        />
                                    )}
                                    <Div className={'CSITitle'}>{series.name}</Div>
                                </Div>
                            ))}
                        </Fragment>
                    ))}
                </Div>
            ) : null}
        </Div>
    );
};

CircleChart.propTypes = {
    className: PropTypes.string,
    height: PropTypes.number,
    chartData: PropTypes.arrayOf(PropTypes.object).isRequired,
    width: PropTypes.number,
    seriesLabels: PropTypes.bool,
    title: PropTypes.object,
    subtitle: PropTypes.object,
};

export default CircleChart;
