import React from 'react';
import { Page, Text, View, Image, Font } from '@react-pdf/renderer';
import { sanitizePhoneNumber } from 'Utils';
import { formatPhoneNumber } from './downloadUtils/phoneNumberIntlCode';
import { styles } from './style';
import { instareportFontFamily } from '../components/constant';

Font.register({ ...instareportFontFamily });

const BusinessDetailsDownload = ({ details }) => {
    return (
        <Page size="A4" style={styles.page}>
            <View fixed style={styles.FixedTopHeight}>
                <Text />
            </View>
            <View style={styles.section}>
                <View style={styles.HeadingView}>
                    <Text style={styles.heading}>Business Details</Text>
                </View>
                <View style={styles.content}>
                    <View>
                        <Text style={styles.MainHeading}>Report created for {details?.name}</Text>
                        <Text style={styles.RTRSectionText}>
                            Marketing is the force behind every business which allows them to propel
                            and dominate in their local market. With our extensive marketing
                            background, accompanied with our proprietary technology, we’re able to
                            pinpoint exactly where {details?.name} needs the most help.
                        </Text>
                    </View>
                </View>
                <View style={styles.TableSectionMain}>
                    <View style={styles.tableSection}>
                        <View style={styles.BDTableContent}>
                            <View style={styles.BDTableFieldRow}>
                                <Text style={styles.BDTableFieldRowText}>Name</Text>
                                <Text style={styles.BDTableFieldRowTextSide}>
                                    {details?.name || '---'}
                                </Text>
                            </View>
                            <View style={styles.BDTableFieldRow}>
                                <Text style={styles.BDTableFieldRowText}>Address</Text>
                                <Text style={styles.BDTableFieldRowTextSide}>
                                    {' '}
                                    {details?.address?.street || '---'}
                                </Text>
                            </View>
                            <View style={styles.BDTableFieldRow}>
                                <Text style={styles.BDTableFieldRowText}>City, State</Text>
                                <Text style={styles.BDTableFieldRowTextSide}>
                                    {details?.address?.city || ''}
                                    {details?.address?.state_province ? ', ' : ''}
                                    {details?.address?.state_province}
                                </Text>
                            </View>
                            <View style={styles.BDTableFieldRow}>
                                <Text style={styles.BDTableFieldRowText}>Zip Code</Text>
                                <Text style={styles.BDTableFieldRowTextSide}>
                                    {details?.address?.postal_code || '---'}
                                </Text>
                            </View>
                            <View style={styles.BDTableFieldRow}>
                                <Text style={styles.BDTableFieldRowText}>Country</Text>
                                <Text style={styles.BDTableFieldRowTextSide}>
                                    {details?.address.country || '---'}
                                </Text>
                            </View>
                            <View style={styles.BDTableFieldRow}>
                                <Text style={styles.BDTableFieldRowText}>Phone Number</Text>
                                <Text style={styles.BDTableFieldRowTextSide}>
                                    {details?.phoneNumber
                                        ? formatPhoneNumber(
                                              sanitizePhoneNumber(details?.phoneNumber),
                                          )
                                        : '---'}
                                </Text>
                            </View>
                            <View style={styles.BDTableFieldRow}>
                                <Text style={styles.BDTableFieldRowText}>Website</Text>
                                <Text style={styles.BDTableFieldRowTextSide}>
                                    {details?.website || '---'}
                                </Text>
                            </View>
                            <View style={styles.BDTableFieldRow}>
                                <Text style={styles.BDTableFieldRowText}>Business Category </Text>
                                <Text style={styles.BDTableFieldRowTextSide}>
                                    {details?.businessCategory || '---'}
                                </Text>
                            </View>
                        </View>
                    </View>
                    <Text style={styles.HeadingSubView}>Business Photos</Text>
                    {details?.photos?.length ? (
                        <View style={styles.ImageArea}>
                            {details?.photos.slice(0, 10).map((item, i) => (
                                <Image
                                    src={item}
                                    alt={'images'}
                                    key={i}
                                    cache={false}
                                    style={styles.BusinessImage}
                                />
                            ))}
                        </View>
                    ) : (
                        <View style={styles.NPFound}>
                            <Text style={styles.NPFoundText}>No photos Found</Text>
                        </View>
                    )}
                </View>
            </View>
            <View fixed style={{ height: 20 }}>
                <Text />
            </View>
        </Page>
    );
};

export default BusinessDetailsDownload;
