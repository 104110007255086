import React from 'react';

import { SVGIcon } from '../../UIKit/index';

export default function DCFileLink(props) {
    return (
        <SVGIcon {...props} viewBox="0 0 512 512">
            <path d="M351.45,191.51h15.96c8.79-0.06,15.9-7.17,15.96-15.96v21.28c0,8.81-7.15,15.96-15.96,15.96  s-15.96-7.15-15.96-15.96V191.51z" />
            <path d="M402.74,352.81c-12.71-12.26-32.51-13.26-46.39-2.34c-6.87,5.46-16.83,4.52-22.56-2.13  c-5.59-6.83-4.64-16.89,2.13-22.56c26.76-20.95,64.84-19.04,89.37,4.47l66.18,66.6c12.59,12.4,19.65,29.36,19.58,47.03  c0.03,17.73-7.01,34.73-19.58,47.24c-25.8,25.97-67.77,26.11-93.74,0.32c-0.18-0.18-0.35-0.35-0.53-0.53l-10.85-10.85  c-6.01-6.45-5.65-16.55,0.8-22.56c6.13-5.71,15.63-5.71,21.76,0l11.07,11.07c13.65,13.19,35.29,13.19,48.94,0  c6.57-6.53,10.25-15.42,10.21-24.68c0.08-9.21-3.61-18.05-10.21-24.47L402.74,352.81z" />
            <path d="M32.26,159.6h319.19v31.92H32.26V159.6z" />
            <circle cx="276.97" cy="106.4" r="21.28" />
            <path d="M276.76,275.99c-13.69,13.4-13.93,35.36-0.53,49.05c0.1,0.11,0.21,0.21,0.32,0.32l66.18,66.6  c12.56,12.59,32.62,13.6,46.39,2.34c6.65-5.78,16.73-5.07,22.51,1.58s5.07,16.73-1.58,22.51c-0.23,0.2-0.47,0.4-0.72,0.59  c-11.89,9.72-26.77,15.06-42.13,15.11c-17.7,0-34.65-7.13-47.03-19.79l-66.18-66.39c-12.6-12.48-19.65-29.51-19.58-47.24  c0.01-17.72,7.05-34.71,19.58-47.24c26.37-25.07,67.8-24.98,94.05,0.21l11.49,11.28c6.23,6.29,6.18,16.43-0.11,22.66  c-6.29,6.23-16.43,6.18-22.66-0.11l0,0l-11.28-11.28C311.94,263.06,290.43,262.97,276.76,275.99z" />
            <circle cx="191.86" cy="106.4" r="21.28" />
            <path d="M175.9,319.19c8.81,0,15.96,7.15,15.96,15.96c0,8.81-7.15,15.96-15.96,15.96H32.26v-31.92H175.9  z" />
            <circle cx="106.74" cy="106.4" r="21.28" />
            <path d="M32.26,191.51v127.68H16.3c-8.79,0.06-15.9,7.17-15.96,15.96v-159.6  c0.06,8.79,7.17,15.9,15.96,15.96H32.26z" />
            <path d="M58.86,31.92c-14.69,0-26.6,11.91-26.6,26.6V159.6H16.3c-8.79,0.06-15.9,7.17-15.96,15.96V58.52  C0.32,26.22,26.48,0.02,58.78,0c0.03,0,0.06,0,0.09,0h265.99c32.3-0.02,58.49,26.14,58.52,58.43c0,0.03,0,0.06,0,0.09v117.04  c-0.06-8.79-7.17-15.9-15.96-15.96h-15.96V58.52c0-14.69-11.91-26.6-26.6-26.6H58.86z" />
            <path d="M324.85,510.71H58.86c-32.3,0.02-58.49-26.14-58.52-58.43c0-0.03,0-0.06,0-0.09V335.15  c0.06,8.79,7.17,15.9,15.96,15.96h15.96v101.08c0,14.69,11.91,26.6,26.6,26.6h265.99c8.81,0,15.96,7.15,15.96,15.96  C340.81,503.56,333.67,510.71,324.85,510.71z" />
            <g transform="translate(16 5)">
                <path d="M357.06,179.73l-10.97-0.79v-66.42l10.97,1.13V179.73z" />
                <path d="M335.45,100.73l32.25,3.32v87.11l-32.25-2.33V100.73z" />
            </g>
            <g transform="translate(-0.5 7)">
                <path d="M22.45,390.88l-10.97-2.3v-233l10.97,3.29V390.88z" />
                <path d="M0.84,141.29l32.25,9.66v253.04l-32.25-6.76V141.29z" />
            </g>
        </SVGIcon>
    );
}
