import React from 'react';

import { SVGIcon } from '../../UIKit/index';

export default function DCHelp(props) {
    return (
        <SVGIcon {...props} viewBox="0 0 512.001 512.001">
            <g transform="translate(-61.38)">
                <path d="M252.692,139.985c-47.057,0-68.668,27.89-68.668,46.715c0,13.597,11.502,19.872,20.914,19.872  c18.822,0,11.154-26.844,46.708-26.844c17.429,0,31.372,7.671,31.372,23.707c0,18.826-19.52,29.633-31.023,39.394  c-10.108,8.716-23.354,23.01-23.354,52.991c0,18.128,4.879,23.357,19.171,23.357c17.079,0,20.565-7.669,20.565-14.293  c0-18.129,0.349-28.588,19.52-43.578c9.412-7.321,39.04-31.028,39.04-63.798S297.308,139.986,252.692,139.985z" />
                <path d="M248.16,343.232c-14.639,0-26.491,12.202-26.491,26.844c0,14.293,11.503,26.844,26.491,26.844  c14.988,0,26.84-12.55,26.84-26.844C275,355.434,262.799,343.232,248.16,343.232z" />
            </g>
        </SVGIcon>
    );
}
