import React from 'react';

import { SVGIcon } from '../../UIKit/index';

export default function DCSchedule1(props) {
    return (
        <SVGIcon {...props} viewBox="0 0 42 42" fill="none">
            <g style={{ clipPath: 'url(#clip0_66_450)' }}>
                <path
                    d="M37.5855 40H2.41445C1.08102 40 0 38.9189 0 37.5855V10.614H40V37.5854C40 38.9189 38.919 40 37.5855 40Z"
                    fill="#EAF6FF"
                />
                <path
                    d="M37.5855 10.614V37.5854C37.5855 38.9189 36.5045 39.9999 35.1711 39.9999H37.5855C38.919 39.9999 40 38.9189 40 37.5855V10.614H37.5855Z"
                    fill="#D8ECFE"
                />
                <path
                    d="M37.5855 3.47168H2.41445C1.08102 3.47168 0 4.5527 0 5.88621V10.6141H40V5.88621C40 4.5527 38.919 3.47168 37.5855 3.47168Z"
                    fill="#8FD8FA"
                />
                <path
                    d="M37.5855 3.47168H35.1711C36.5046 3.47168 37.5855 4.5527 37.5855 5.88613V10.614H40V5.88621C40 4.5527 38.919 3.47168 37.5855 3.47168Z"
                    fill="#68CBF2"
                />
                <path
                    d="M9.13484 1.81086V5.13266C9.13484 6.13273 8.32406 6.94352 7.32398 6.94352C6.3239 6.94352 5.51312 6.13273 5.51312 5.13266V1.81086C5.51304 0.810781 6.32383 0 7.3239 0C8.32406 0 9.13484 0.810781 9.13484 1.81086Z"
                    fill="#3D6D93"
                />
                <path
                    d="M7.3239 0C6.86 0 6.43703 0.174609 6.11664 0.461484C6.48703 0.793047 6.72023 1.27461 6.72023 1.81086V5.13266C6.72023 5.66891 6.48695 6.15047 6.11664 6.48203C6.43703 6.76891 6.86 6.94352 7.3239 6.94352C8.32398 6.94352 9.13476 6.13273 9.13476 5.13266V1.81086C9.13484 0.810781 8.32406 0 7.3239 0V0Z"
                    fill="#335E80"
                />
                <path
                    d="M34.487 1.81086V5.13266C34.487 6.13273 33.6762 6.94352 32.6761 6.94352C31.676 6.94352 30.8652 6.13273 30.8652 5.13266V1.81086C30.8652 0.810781 31.6759 0 32.6761 0C33.6762 0 34.487 0.810781 34.487 1.81086Z"
                    fill="#3D6D93"
                />
                <path
                    d="M32.6761 0C32.2122 0 31.7892 0.174609 31.4688 0.461484C31.8392 0.793047 32.0724 1.27461 32.0724 1.81086V5.13266C32.0724 5.66891 31.8391 6.15047 31.4688 6.48203C31.7892 6.76891 32.2122 6.94352 32.6761 6.94352C33.6762 6.94352 34.487 6.13273 34.487 5.13266V1.81086C34.487 0.810781 33.6762 0 32.6761 0V0Z"
                    fill="#335E80"
                />
                <path
                    d="M22.7349 33.8039C20.9855 34.2287 19.1681 33.5062 18.2141 31.9967C17.1416 30.3 16.4855 27.9451 16.501 25.3389C16.5166 22.7323 17.2008 20.3694 18.2935 18.6598C19.2657 17.1389 21.0917 16.3947 22.8362 16.7987C23.2272 16.8892 23.5016 17.2373 23.4992 17.6398L23.4814 20.629C23.478 21.1898 22.9522 21.6067 22.4073 21.4805L21.4215 21.2523C21.0077 21.1565 20.5816 21.3751 20.4161 21.7694C20.0568 22.6251 19.6646 23.9144 19.6858 25.4987C19.7048 26.9158 20.0477 28.0696 20.375 28.8657C20.5356 29.2565 20.9594 29.468 21.3729 29.3676L22.362 29.1274C22.9084 28.9948 23.4293 29.4055 23.4259 29.9662L23.4081 32.9549C23.4057 33.3574 23.1272 33.7088 22.735 33.804L22.7349 33.8039Z"
                    fill="#AEE69C"
                />
                <path
                    d="M22.3619 29.1271L21.3728 29.3673C21.3659 29.369 21.3589 29.3698 21.352 29.3714C21.497 29.5246 21.5879 29.7318 21.5865 29.9659L21.5687 32.9547C21.5663 33.3572 21.2877 33.7086 20.8956 33.8037H20.8954C20.868 33.8104 20.8405 33.8159 20.813 33.822C21.4315 33.9605 22.0877 33.9609 22.7348 33.8037H22.7349C23.1271 33.7085 23.4056 33.3571 23.408 32.9547L23.4259 29.9659C23.4293 29.4052 22.9083 28.9945 22.3619 29.1271Z"
                    fill="#89DAA4"
                />
                <path
                    d="M19.5821 21.2522C19.1684 21.1563 18.7423 21.375 18.5767 21.7693C18.2174 22.625 17.8252 23.9142 17.8464 25.4986C17.8654 26.9157 18.2084 28.0694 18.5356 28.8656C18.6963 29.2565 19.12 29.4679 19.5335 29.3675L20.5226 29.1273C20.5289 29.1258 20.5352 29.1253 20.5415 29.1239C20.4723 29.0505 20.4154 28.9637 20.3751 28.8656C20.0479 28.0694 19.7048 26.9157 19.6859 25.4986C19.6647 23.9142 20.057 22.625 20.4162 21.7693C20.4617 21.6608 20.5276 21.5661 20.607 21.4872C20.594 21.4847 20.5811 21.4836 20.5681 21.4805L19.5821 21.2522Z"
                    fill="#89DAA4"
                />
                <path
                    d="M22.8362 16.7985C22.2084 16.6532 21.5704 16.6572 20.9652 16.7918C20.9757 16.7942 20.9863 16.796 20.9968 16.7985C21.3879 16.8891 21.6623 17.2372 21.6598 17.6397L21.642 20.6289C21.6405 20.8724 21.5405 21.0885 21.3833 21.245C21.396 21.2474 21.4088 21.2492 21.4215 21.2521L22.4073 21.4803C22.9522 21.6065 23.478 21.1896 23.4814 20.6289L23.4992 17.6396C23.5016 17.2372 23.2273 16.8891 22.8362 16.7985Z"
                    fill="#89DAA4"
                />
                <path
                    d="M35.5734 19.2307H32.2737C31.8291 19.2307 31.4688 18.8704 31.4688 18.4259V15.1261C31.4688 14.6816 31.8291 14.3213 32.2737 14.3213H35.5734C36.018 14.3213 36.3783 14.6816 36.3783 15.1261V18.4259C36.3783 18.8704 36.018 19.2307 35.5734 19.2307Z"
                    fill="#8FD8FA"
                />
                <path
                    d="M35.5734 14.3213H33.159C33.6035 14.3213 33.9638 14.6816 33.9638 15.1261V18.4259C33.9638 18.8704 33.6035 19.2307 33.159 19.2307H35.5734C36.018 19.2307 36.3783 18.8704 36.3783 18.4259V15.1261C36.3783 14.6816 36.018 14.3213 35.5734 14.3213Z"
                    fill="#68CBF2"
                />
                <path
                    d="M35.5734 27.762H32.2737C31.8291 27.762 31.4688 27.4017 31.4688 26.9571V23.6574C31.4688 23.2129 31.8291 22.8525 32.2737 22.8525H35.5734C36.018 22.8525 36.3783 23.2129 36.3783 23.6574V26.9571C36.3783 27.4016 36.018 27.762 35.5734 27.762Z"
                    fill="#8FD8FA"
                />
                <path
                    d="M35.5734 36.293H32.2737C31.8291 36.293 31.4688 35.9327 31.4688 35.4882V32.1884C31.4688 31.7439 31.8291 31.3835 32.2737 31.3835H35.5734C36.018 31.3835 36.3783 31.7439 36.3783 32.1884V35.4882C36.3783 35.9327 36.018 36.293 35.5734 36.293Z"
                    fill="#8FD8FA"
                />
                <path
                    d="M35.5734 22.8667H33.159C33.6035 22.8667 33.9638 23.227 33.9638 23.6715V26.9713C33.9638 27.4158 33.6035 27.7762 33.159 27.7762H35.5734C36.018 27.7762 36.3783 27.4158 36.3783 26.9713V23.6715C36.3783 23.2271 36.018 22.8667 35.5734 22.8667Z"
                    fill="#68CBF2"
                />
                <path
                    d="M35.5734 31.4124H33.159C33.6035 31.4124 33.9638 31.7727 33.9638 32.2172V35.517C33.9638 35.9615 33.6035 36.3218 33.159 36.3218H35.5734C36.018 36.3218 36.3783 35.9615 36.3783 35.517V32.2172C36.3783 31.7727 36.018 31.4124 35.5734 31.4124Z"
                    fill="#68CBF2"
                />
                <path
                    d="M7.72633 19.2307H4.42656C3.98203 19.2307 3.62172 18.8704 3.62172 18.4259V15.1261C3.62172 14.6816 3.98203 14.3213 4.42656 14.3213H7.72633C8.17086 14.3213 8.53117 14.6816 8.53117 15.1261V18.4259C8.53117 18.8704 8.17086 19.2307 7.72633 19.2307Z"
                    fill="#8FD8FA"
                />
                <path
                    d="M7.72633 27.762H4.42656C3.98203 27.762 3.62172 27.4017 3.62172 26.9571V23.6574C3.62172 23.2129 3.98203 22.8525 4.42656 22.8525H7.72633C8.17086 22.8525 8.53117 23.2129 8.53117 23.6574V26.9571C8.53117 27.4016 8.17086 27.762 7.72633 27.762Z"
                    fill="#8FD8FA"
                />
                <path
                    d="M7.72633 36.293H4.42656C3.98203 36.293 3.62172 35.9327 3.62172 35.4882V32.1884C3.62172 31.7439 3.98203 31.3835 4.42656 31.3835H7.72633C8.17086 31.3835 8.53117 31.7439 8.53117 32.1884V35.4882C8.53117 35.9327 8.17086 36.293 7.72633 36.293Z"
                    fill="#8FD8FA"
                />
                <path
                    d="M7.72633 14.3213H5.31187C5.75641 14.3213 6.11672 14.6816 6.11672 15.1261V18.4259C6.11672 18.8704 5.75641 19.2307 5.31187 19.2307H7.72633C8.17086 19.2307 8.53117 18.8704 8.53117 18.4259V15.1261C8.53117 14.6816 8.17086 14.3213 7.72633 14.3213Z"
                    fill="#68CBF2"
                />
                <path
                    d="M7.72633 22.8667H5.31187C5.75641 22.8667 6.11672 23.227 6.11672 23.6715V26.9713C6.11672 27.4158 5.75641 27.7762 5.31187 27.7762H7.72633C8.17086 27.7762 8.53117 27.4158 8.53117 26.9713V23.6715C8.53117 23.2271 8.17086 22.8667 7.72633 22.8667Z"
                    fill="#68CBF2"
                />
                <path
                    d="M7.72633 31.4124H5.31187C5.75641 31.4124 6.11672 31.7727 6.11672 32.2172V35.517C6.11672 35.9615 5.75641 36.3218 5.31187 36.3218H7.72633C8.17086 36.3218 8.53117 35.9615 8.53117 35.517V32.2172C8.53117 31.7727 8.17086 31.4124 7.72633 31.4124Z"
                    fill="#68CBF2"
                />
            </g>
            <defs>
                <clipPath id="clip0_66_450">
                    <rect width="40" height="40" fill="white" />
                </clipPath>
            </defs>
        </SVGIcon>
    );
}
