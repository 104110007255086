import { CallAPI } from 'Utils/apiCall';
import config from 'config';
const mainUrl = `${config.REACT_APP_API_BASE_URL}/${process.env.REACT_APP_API_VERSION}/${process.env.REACT_APP_API_STORE}`;
const api = new CallAPI();

export const getProductDetail = (productId, priceId) => {
    const url = `${mainUrl}/${process.env.REACT_APP_API_PRODUCTS}/${productId}/${process.env.REACT_APP_API_PRICES}/${priceId}`;
    return api.request('GET', url);
};

export const getProducts = () => {
    const url = `${mainUrl}/${process.env.REACT_APP_API_PRODUCTS}/?type=store`;
    return api.request('GET', url);
};

export const addToCart = (body, params) => {
    const url = `${mainUrl}/cart`;
    return api.request('POST', url, body, null, null, params);
};

export const getCart = () => {
    const url = `${mainUrl}/cart`;
    return api.request('GET', url);
};

export const updateCart = body => {
    const url = `${mainUrl}/cart`;
    return api.request('PUT', url, body);
};

export const getPaymentCards = () => {
    const url = `${mainUrl}/payment-methods`;
    return api.request('GET', url);
};

export const deletePaymentCard = id => {
    const url = `${mainUrl}/payment-methods/${id}`;
    return api.request('DELETE', url);
};

export const addPaymentCard = body => {
    const url = `${mainUrl}/payment-methods`;
    return api.request('POST', url, body);
};

export const getProductDetails = prod_id => {
    const url = `${mainUrl}/${process.env.REACT_APP_API_PRODUCTS}/${prod_id}`;
    return api.request('GET', url);
};

export const checkout = card => {
    const url = `${mainUrl}/${process.env.REACT_APP_API_CART}/${process.env.REACT_APP_API_CHECKOUT}`;
    return api.request('POST', url, { card }, null, null, null);
};

export const deActivateSubscription = id => {
    const url = `${mainUrl}/subscriptions/${id}?end_of_cycle=true`;
    return api.request('DELETE', url);
};

export const getInvoices = (accountId, subscriptionId) => {
    const url = `${mainUrl}/${process.env.REACT_APP_API_INVOICES}?account=${accountId}&subscription=${subscriptionId}`;
    return api.request('GET', url);
};

export const getInvoiceDownloadPdf = invoiceId => {
    const url = `${mainUrl}/${process.env.REACT_APP_API_INVOICES}/${invoiceId}/retrieve`;
    return api.request('GET', url);
};

export const updateSubscription = body => {
    const url = `${mainUrl}/subscriptions/change`;
    return api.request('POST', url, body);
};

export const singleItemCheckout = (body, query) => {
    const url = `${mainUrl}/cart/single-item-checkout`;
    return api.request('POST', url, body, null, null, query);
};

export const retrySubscriptions = async id => {
    const url = `${mainUrl}/subscriptions/${id}/retry`;
    return api.request('POST', url);
};

export const cancelSubscription = async (id, body) => {
    const url = `${mainUrl}/subscriptions/${id}?end_of_cycle=true`;
    return api.request('DELETE', url, body);
};

export const RetractCancellationApi = (id, body) => {
    const url = `${mainUrl}/subscriptions/${id}/cancel-downgrade`;
    return api.request('PUT', url, body);
};

export const createPromocode = body => {
    const url = `${mainUrl}/promo`;
    return api.request('POST', url, body);
};

export const applyPromoCode = body => {
    const url = `${mainUrl}/cart/promo`;
    return api.request('PUT', url, body);
};

export const removePromoCode = () => {
    const url = `${mainUrl}/cart/promo`;
    return api.request('DELETE', url);
};

export const getOrders = (page, limit, params) => {
    const url = `${mainUrl}/orders/myorders`;
    return api.request('GET', url, null, limit, page, params);
};

export const getSubscriptionInvoices = id => {
    const url = `${mainUrl}/orders/${id}/invoices`;
    return api.request('GET', url);
};

export const getOrdersTimeline = id => {
    const url = `${mainUrl}/orders/${id}/timeline`;
    return api.request('GET', url);
};

export const resendOnboarding = body => {
    const url = `${mainUrl}/orders/resendonboardning`;
    return api.request('POST', url, body);
};
