import React from 'react';

import { SVGIcon } from '../../UIKit/index';

export default function DCAds(props) {
    return (
        <SVGIcon {...props} viewBox="0 0 128 128">
            <path d="m121.5 13.6h-115a1.75 1.75 0 0 0 -1.75 1.75v97.308a1.75 1.75 0 0 0 1.75 1.742h115a1.749 1.749 0 0 0 1.75-1.75v-97.304a1.749 1.749 0 0 0 -1.75-1.746zm-113.25 3.5h111.5v10.637h-111.5zm0 93.808v-79.671h111.5v79.663z" />
            <path d="m82.958 19.393a3.024 3.024 0 1 0 3.024 3.024 3.024 3.024 0 0 0 -3.024-3.024z" />
            <path d="m94.792 19.393a3.024 3.024 0 1 0 3.023 3.024 3.024 3.024 0 0 0 -3.023-3.024z" />
            <path d="m106.625 19.393a3.024 3.024 0 1 0 3.023 3.024 3.023 3.023 0 0 0 -3.023-3.024z" />
            <path d="m36.367 58.83-11.024 24.5h4.689l2.415-5.67h12.253l2.45 5.67h4.76l-11.062-24.5zm-2.38 15.26 4.585-10.71 4.62 10.71z" />
            <path d="m72.7 60.353a15.367 15.367 0 0 0 -6.965-1.523h-10.713v24.5h10.71a15.38 15.38 0 0 0 6.968-1.522 11.272 11.272 0 0 0 4.723-4.308 13.118 13.118 0 0 0 0-12.846 11.263 11.263 0 0 0 -4.723-4.301zm.718 15.155a7.381 7.381 0 0 1 -3.15 2.939 10.391 10.391 0 0 1 -4.743 1.033h-5.95v-16.8h5.95a10.391 10.391 0 0 1 4.743 1.033 7.387 7.387 0 0 1 3.15 2.939 9.44 9.44 0 0 1 0 8.856z" />
            <path d="m97.2 70.537a33.489 33.489 0 0 0 -4.479-1.312q-1.995-.49-3.151-.875a5.432 5.432 0 0 1 -1.907-1.05 2.182 2.182 0 0 1 -.753-1.715 2.808 2.808 0 0 1 1.313-2.415 7.09 7.09 0 0 1 4.008-.91 12.606 12.606 0 0 1 3.395.49 14.132 14.132 0 0 1 3.359 1.436l1.436-3.536a12.223 12.223 0 0 0 -3.745-1.61 17.6 17.6 0 0 0 -4.41-.56 13.476 13.476 0 0 0 -5.443.98 7.452 7.452 0 0 0 -3.329 2.64 6.535 6.535 0 0 0 -1.1 3.692 5.612 5.612 0 0 0 1.242 3.833 7.452 7.452 0 0 0 2.975 2.082 36.66 36.66 0 0 0 4.533 1.33q2 .49 3.15.875a5.287 5.287 0 0 1 1.906 1.07 2.241 2.241 0 0 1 .752 1.733 2.65 2.65 0 0 1 -1.347 2.31 7.592 7.592 0 0 1 -4.078.875 13.418 13.418 0 0 1 -4.392-.753 12.379 12.379 0 0 1 -3.693-1.942l-1.576 3.535a11.427 11.427 0 0 0 4.183 2.135 18.717 18.717 0 0 0 5.477.805 13.585 13.585 0 0 0 5.46-.98 7.5 7.5 0 0 0 3.343-2.625 6.452 6.452 0 0 0 1.1-3.675 5.491 5.491 0 0 0 -1.243-3.78 7.691 7.691 0 0 0 -2.986-2.083z" />
        </SVGIcon>
    );
}
