import * as React from 'react';
import { SVGIcon } from 'UIKit/index';

export default function DCNoConversation(props) {
    return (
        <SVGIcon
            viewBox="0 0 512 512"
            {...props}
            style={{ fill: '#565656', transform: 'scaleX(-1)' }}
        >
            <ellipse cx="271.88" cy="470.48" rx="215.21" ry="41.52" style={{ fill: '#f4f5f6' }} />
            <path
                d="M146.96,40.18c-.64-.63-33.64,4.68-23.64,17.45,10,12.76,38.49-1.41,38.31-8.66-.18-7.24-14.67-8.79-14.67-8.79Z"
                style={{ fill: '#e3e2e3' }}
            />
            <path
                d="M120.71,.31c-1.25-.77-51.68,19.7-31.15,36.31,20.53,16.61,60.57-16.28,57.65-27.71C144.28-2.52,120.71,.31,120.71,.31Z"
                style={{ fill: '#e3e2e3' }}
            />
            <g>
                <path
                    d="M163.47,93.29s56.8-27.69,78.51-11.74c21.71,15.95-37.66,77.53-37.66,77.53l-32.46-27.25,18.17-18.61-22.48,5.77-4.08-25.7Z"
                    style={{ fill: '#707171' }}
                />
                <path
                    d="M376.84,468.13c5.8,1.67,13.96,2.65,15.43,2.26s-8.45-3.73-3.34-5.9c2.83-1.2,7.38-2.18,10.88-2.83-.51-.38-1.02-.77-1.53-1.2-7.01-5.79-12.11-19.52-12.11-19.52-5.26-.26-20.32,4.9-20.32,4.9,0,0,3.99,11.62,4.47,17.12,.19,2.24,.22,3.54,.19,4.58,1.22-.25,3.25-.31,6.34,.58Z"
                    style={{ fill: '#707171' }}
                />
                <path
                    d="M417.39,250.19s7.57-2.73,13.31-3.31c6.79-.69,17.04-1.33,24.68,1.51,6.26,2.32,8.2,4.64,8.56,7.17,.59,4.12-6.3,9.31-14.7,11.36-6.05,1.48-10.35,1.93-17,.19-6.65-1.74-22.72-14.33-14.85-16.9Z"
                    style={{ fill: '#565656;' }}
                />
                <path
                    d="M410.04,253.45s3.59-2.98,9.12-4.14c2.6-.55,12.42-2.16,20.44-2.09,10.03,.08,21.24,3.5,21.93,8.19,.77,5.27-12.34,11.51-17.22,12.54-4.88,1.03-23.63,2.6-27.93,3.17-4.3,.57-10.24-9.71-6.34-17.67Z"
                    style={{ fill: '#707171' }}
                />
                <path
                    d="M407.02,251.51s15.18-.48,17.67,1.84c2.49,2.33,1.5,10.48,1,12.81-.5,2.33-20.17,3.46-20.17,3.46l1.5-18.11Z"
                    style={{ fill: '#707171' }}
                />
                <g>
                    <path
                        d="M194.02,328.33v26c0,3.29,2.13,6.2,5.27,7.18,2.83,.88,6.95,1.65,12.73,1.65s9.86-.78,12.71-1.68c3.14-.98,5.29-3.89,5.29-7.18v-25.97h-36Z"
                        style={{ fill: '#565656;' }}
                    />
                    <path
                        d="M70,171.74s-1.94,3.82-11.66-1.27c-9.71-5.09-10.2-22.47,0-28.84,7.67-4.78,68.3-13.26,97.94-17.17,9.52-1.26,18.69,4.09,22.28,13l53.4,132.76s60.71-1.28,73.34,6.49c12.63,7.77,24.88,21.59,23.8,25.02-1.46,4.63-51.97,40.43-61.2,42.67-9.23,2.24-122.88-10.56-127.25-12.98-4.37-2.43-70.65-159.68-70.65-159.68Z"
                        style={{ fill: '#565656;' }}
                    />
                    <path
                        d="M218.85,421.65c0,1.74-1.08,5.74-2.72,6.33-1.13,.41-2.54,.75-4.11,.75s-2.97-.34-4.11-.75c-1.64-.59-2.72-4.59-2.72-6.33v-69.82h13.66v69.83Z"
                        style={{ fill: '#565656;' }}
                    />
                    <path
                        d="M294.11,440.22c-8.42-8.26-74.64-20.08-74.64-20.08h-14.89s-66.22,11.82-74.64,20.08c-8.42,8.26-4.86,14.57,1.62,15.7,3.44,.6,6.43-1.95,8.47-4.48,2.42-3,5.73-5.15,9.47-6.1l56.69-14.35v14.98c0,1.83,1.49,3.32,3.32,3.32h5.02c1.83,0,3.32-1.49,3.32-3.32v-14.98l56.69,14.35c3.74,.95,7.05,3.1,9.47,6.1,2.04,2.53,5.03,5.08,8.47,4.48,6.48-1.13,10.04-7.45,1.62-15.7Z"
                        style={{ fill: '#565656;' }}
                    />
                    <circle
                        cx="137.66"
                        cy="457.62"
                        r="9.15"
                        transform="translate(-323.99 334.14) rotate(-58.28)"
                        style={{ fill: '#565656;' }}
                    />
                    <circle
                        cx="287.25"
                        cy="457.62"
                        r="9.15"
                        transform="translate(-197.68 219.36) rotate(-31.72)"
                        style={{ fill: '#565656;' }}
                    />
                    <rect
                        x="207.33"
                        y="446.37"
                        width="12.66"
                        height="20.4"
                        rx="4.7"
                        ry="4.7"
                        style={{ fill: '#565656;' }}
                    />
                </g>
                <g style={{ opacity: '.3' }}>
                    <path d="M214.37,147.92c-8.55-4.27-20.71-8.53-20.71-8.53l4.97,14.93,5.68,4.77s4.25-4.41,10.06-11.17Z" />
                </g>
                <path
                    d="M361.9,281.15c-20.6-13.96-119.71-35.12-119.71-35.12,0,0-49.13,20.89-79.26,30.04l11.48,22.46,33.23-3.65,114.29,27.91,41.64,134.7s12.69,.65,16.85,0c4.72-.73,18.2-5.78,18.2-5.78,0,0-16.11-156.6-36.71-170.55Z"
                    style={{ fill: '#e3e2e3' }}
                />
                <path
                    d="M232.89,269.01s42.61-29.8,63.47-31.39c10.01-.76,113.73,7.99,113.73,7.99,0,0,5.18,19.53,.53,32.49l-98.55,7.55s-36.74,37.3-62.56,39.69c-28.31,2.61-45.53-9.53-75.1-26.8l-10.35-20.24,68.83-9.27Z"
                    style={{ fill: '#e3e2e3' }}
                />
                <path
                    d="M134.37,97.4s-48.91-5.42-84.89-2.12c-13.05,1.2-22.42,10.86-24.09,18.33-7.64,34.22,79.08,67.78,79.08,67.78l22.93-46.12-41.94-12.52,52.18-1.53s7.01,.38,8.36-7.77c2.5-15.02-11.63-16.05-11.63-16.05Z"
                    style={{ fill: '#707171' }}
                />
                <path
                    d="M221.93,206.82c-7.64-13.62-13.62-72.1-45.19-76.75-31.57-4.65-68.11,6.85-74.11,16-13.68,20.87,11.31,64.63,25.93,93.87,9.48,18.96,40.19,83.07,40.19,83.07,15.14-4.6,39.22-16.17,59.49-45.74,3.38-4.92-8.94-18.42-7.64-19.6,.98-.89,16.25,8.78,17.21,7.9,16.16-14.92,17.46-16.63,17.46-16.63,0,0-25.69-28.48-33.33-42.11Z"
                    style={{ fill: '#707171' }}
                />
                <path
                    d="M429.01,253.17s-9.91-2.74-11.3-1.7c-1.38,1.04-1.77,17.49,.72,17.54,2.49,.06,10.63-1.22,11.46-2.66,.83-1.44-.89-13.18-.89-13.18Z"
                    style={{ fill: '#707171' }}
                />
                <g>
                    <path
                        d="M156.76,131.47c-2.9,.28-8.34,2.64-11.52,7.03-3.98,5.5,43.24,78.63,46.91,80.66,3.67,2.03-23.24-88.88-35.4-87.69Z"
                        style={{ fill: '#fff' }}
                    />
                    <path
                        d="M158.87,138.61s2.53,1.13,1.77,2.61c-.76,1.47-3.37,3.86-4.9,3.04-1.52-.82-3.49-2.92-2.61-3.59,.89-.67,4.57-2.79,5.73-2.05Z"
                        style={{ fill: '#565656;' }}
                    />
                    <path
                        d="M155.9,142.42l3.52-1.5s17.35,32.56,26.25,50.75c4.89,15.77,7.87,28.25,6.48,27.49-1.41-.78-9.27-12.09-18.19-26.03-6.14-16.9-18.07-50.71-18.07-50.71Z"
                        style={{ fill: '#565656;' }}
                    />
                </g>
                <g>
                    <path
                        d="M161.31,80.63s.47-5.03-6.96-5.21c-7.43-.18-9.06,1.93-9.06,1.93,0,0-5.85-2.26-9.5,.7-3.64,2.97-3.8,4.45-3.8,4.45,0,0-3.8,.02-6.39,4.84-2.59,4.83,3.48,11.75,3.48,11.75,0,0-1.92,.75-.12,5.13,1.81,4.38,4.47,19.48,14.16,11.95,9.69-7.54,21.32-32.04,18.19-35.54Z"
                        style={{ fill: '#565656;' }}
                    />
                    <g>
                        <path
                            d="M156.03,85.42c-9.89,1.16-17.18,9.93-16.44,19.86,.47,6.26,1.55,11.42,2.86,13.25,3.66,5.11,18.44,7.3,24.27,4.54,3.51-1.65,3.78-5.44,3.44-11.37-.23-3.95-1.23-7.13-1.42-8.8-.46-4.18-.5-18.23-8.5-17.84-1.33,.07-2.76,.19-4.22,.36Z"
                            style={{ fill: '#e3e2e3' }}
                        />
                        <path
                            d="M145.62,96.79c-1.84,2.19-.64,8.2-2.1,10.51-1.46,2.31-6.16,2.8-7.84,1.67-4.27-2.86-.55-15.24,11.34-18.42,4.85-1.3,.88,3.53-1.39,6.24Z"
                            style={{ fill: '#565656;' }}
                        />
                        <path
                            d="M141.95,111.74s.69,6.58,.84,12.85c.12,4.91-.54,9.61,.83,11.03,1.27,1.32,14.29,2.02,16.24,.17,1.96-1.84-2.85-17.95-2.85-17.95l-15.06-6.1Z"
                            style={{ fill: '#e3e2e3' }}
                        />
                        <path
                            d="M143.08,105.31c1.55-.06,1.18,2.35,1.79,4.16s3.92,2.62,7.01,2.58,6.4-3.68,11.48-4.06c3.48-.26,5.5,.08,6.43,.31,1.14,.85,1.08,3.31,1.38,4.48,1.5,5.9,.59,10.65-2.91,12.3-5.09,2.4-20.58,1.86-24.66-4.86-1.47-2.43-2.06-14.86-.51-14.92Z"
                            style={{ fill: '#565656;' }}
                        />
                        <path
                            d="M159.29,111.52s5.58-1.06,9.41-1.5c0,0,.67,3.63-3,4.23-3.47,.57-6.41-2.73-6.41-2.73Z"
                            style={{ fill: '#fff' }}
                        />
                        <path
                            d="M160.94,102.94c.03-.31,.41-.43,.62-.21,.6,.63,1.71,1.63,3.39,2.43,2.47,1.18,2.48,2.79-.61,2.84-3.1,.05-3.38-.15-3.54-1.73-.08-.84,.04-2.38,.14-3.33Z"
                            style={{ fill: '#707171' }}
                        />
                        <g>
                            <path
                                d="M153.73,102.8c-.94,0-1.82-.03-1.87-.04-.19,0-.34-.17-.33-.36,0-.19,.16-.34,.36-.33,.02,0,2.01,.08,3.22,0,1.01-.07,1.32-.59,1.34-.62,.09-.16,.31-.23,.47-.13,.17,.09,.23,.3,.14,.47-.02,.04-.49,.89-1.9,.98-.42,.03-.93,.04-1.42,.04Z"
                                style={{ fill: '#565656;' }}
                            />
                            <path
                                d="M166.26,101.28c-.95,0-2.12-.25-2.58-.36-.19-.04-.3-.23-.26-.42,.04-.19,.23-.3,.42-.26,1.41,.33,2.89,.44,3.14,.24,.01-.18,.18-.32,.35-.33,.19,0,.35,.16,.35,.35,0,.2-.09,.39-.25,.52-.24,.19-.67,.26-1.16,.26Z"
                                style={{ fill: '#565656;' }}
                            />
                        </g>
                        <path
                            d="M140.99,105.96c-.74-1-1.82-1.71-3.05-1.9-1.33-.21-2.9,.14-3.47,2.58-1.07,4.56,6.89,10.09,8.47,6.54,1.11-2.49-.74-5.58-1.95-7.22Z"
                            style={{ fill: '#e3e2e3' }}
                        />
                        <g style={{ opacity: '.3' }}>
                            <path
                                d="M141.04,112.92s-.07,0-.11-.01c-.28-.06-.46-.34-.4-.62,.07-.32,.11-1.08-.22-1.24-.22-.11-.62-.02-.99,.06-.56,.12-1.26,.28-1.76-.21-.59-.58-1.49-1.96-1.53-3.36-.05-1.53,1.15-2.06,1.2-2.08,.27-.11,.57,.01,.68,.28,.11,.26,0,.57-.27,.68-.07,.03-.6,.3-.57,1.09,.04,1.03,.74,2.17,1.22,2.65,.1,.1,.51,0,.81-.06,.53-.12,1.13-.25,1.68,.02,.93,.46,.93,1.72,.78,2.4-.05,.24-.27,.41-.51,.41Z"
                                style={{ fill: '#565656;' }}
                            />
                        </g>
                        <path
                            d="M150.65,99.39c-.14,0-.26-.08-.32-.21-.07-.18,0-.38,.19-.46,3.51-1.48,6.9-1.66,7.05-1.67,.19-.01,.36,.14,.37,.33,0,.19-.14,.36-.33,.37-.03,0-3.42,.19-6.81,1.61-.04,.02-.09,.03-.13,.03Z"
                            style={{ fill: '#565656;' }}
                        />
                        <path
                            d="M167.55,96.82h0l-4.01-.04c-.19,0-.35-.16-.34-.35,0-.19,.16-.34,.35-.34h0l4.01,.04c.19,0,.35,.16,.34,.35,0,.19-.16,.34-.35,.34Z"
                            style={{ fill: '#565656;' }}
                        />
                        <path
                            d="M144.65,87.54s-1.21-2.9,4.95-5.52c2.52-1.07,6.73,.32,6.73,.32,0,0,5.87-5.95,10.98-3.54,5.11,2.42,7.81,10.64,1.21,12.26-6.6,1.63-15.85,.15-15.85,.15,0,0-7.06,6.92-10.46,5.35-3.4-1.57-3.94-6.56,2.45-9.03Z"
                            style={{ fill: '#565656;' }}
                        />
                    </g>
                    <path
                        d="M154.8,133.23l5.09-3.71,2.99,3.36,2.66,6.66s-9.33-.78-9.76-.64c-.43,.14-6.68,5.27-8.01,5.29-1.33,.02-7.77-3.44-7.45-4.97,.32-1.52,1.45-8.66,1.45-8.66l13.02,2.66Z"
                        style={{ fill: '#fff' }}
                    />
                </g>
                <g>
                    <path
                        d="M437.46,469.98c-.96-9.23-31.35-3.64-37.65-8.31-3.5,.64-8.05,1.63-10.88,2.83-5.11,2.16,4.82,5.5,3.34,5.9-1.47,.39-9.63-.59-15.43-2.26-3.09-.89-5.12-.83-6.34-.58-.04,1.51-.19,2.5-.07,5.08,.2,4.37,3.21,9.77,3.21,9.77l.7,1.77c3.43-.57,11.59-1.92,15.21-2.56,4.41-.79,22.13-2.77,47.9-11.62Z"
                        style={{ fill: '#707171' }}
                    />
                    <path
                        d="M389.56,481.61c-3.62,.65-11.77,2-15.21,2.56l1.89,4.74,12.98-1.86-.83-3.45s3.49,2.12,9.48,1.53c3.18-.31,39.58-11.31,39.61-14.66,0-.17,0-.33-.03-.49-25.77,8.85-43.49,10.84-47.9,11.62Z"
                        style={{ fill: '#565656;' }}
                    />
                </g>
                <g style={{ opacity: '.3' }}>
                    <path d="M220.6,258.7c-.27,0-.53-.1-.73-.3-9.18-9.07-26.02-36.25-26.73-37.41-.3-.49-.15-1.13,.34-1.44,.49-.3,1.13-.15,1.44,.34,.17,.28,17.42,28.13,26.42,37.02,.41,.41,.41,1.07,0,1.48-.2,.21-.47,.31-.74,.31Z" />
                </g>
                <g style={{ opacity: '.3' }}>
                    <path d="M167.27,271.99c-.58,0-1.04-.47-1.04-1.04,0-.62,0-.76,10.46-10.58,5.01-4.7,10.09-9.41,10.14-9.45,.42-.39,1.08-.37,1.48,.06,.39,.42,.37,1.08-.06,1.48-7.27,6.74-19.03,17.74-20.06,19-.18,.33-.52,.55-.92,.55Zm1.04-1.04h0Z" />
                </g>
                <g style={{ opacity: '.3' }}>
                    <path d="M212.79,235.52c-.5,0-.94-.36-1.03-.86-.1-.57,.28-1.11,.85-1.21l17.28-3.07c.57-.1,1.11,.28,1.21,.85,.1,.57-.28,1.11-.85,1.21l-17.28,3.07c-.06,.01-.12,.02-.18,.02Z" />
                </g>
                <g style={{ opacity: '.3' }}>
                    <path d="M101.74,181.11c-.43,0-.84-.27-.99-.71-4.26-12.45-12.27-30.56-12.35-30.74-.23-.53,0-1.14,.53-1.38,.53-.23,1.14,0,1.38,.53,.08,.18,8.13,18.38,12.42,30.91,.19,.55-.1,1.14-.65,1.33-.11,.04-.23,.06-.34,.06Z" />
                </g>
                <g style={{ opacity: '.3' }}>
                    <path d="M190.02,114.27c-.25,0-.5-.09-.7-.27-.43-.39-.45-1.05-.06-1.48,.55-.6,13.55-14.66,26.98-18.25,.56-.15,1.13,.18,1.28,.74,.15,.56-.18,1.13-.74,1.28-12.85,3.43-25.84,17.5-25.97,17.64-.21,.23-.49,.34-.77,.34Z" />
                </g>
                <g style={{ opacity: '.3' }}>
                    <path d="M85.46,123.8c-.09,0-.18-.01-.28-.04-.2-.06-20.5-5.56-35.66-4.88-.57,.03-1.06-.42-1.09-1-.03-.58,.42-1.06,1-1.09,15.49-.7,36.1,4.89,36.3,4.95,.56,.15,.88,.73,.73,1.28-.13,.46-.55,.77-1.01,.77Z" />
                </g>
                <g style={{ opacity: '.3' }}>
                    <path d="M79.11,122.27c-.21,0-.42-.06-.6-.19l-13.46-9.48c-.47-.33-.58-.98-.25-1.45,.33-.47,.98-.58,1.46-.25l13.46,9.48c.47,.33,.58,.98,.25,1.45-.2,.29-.53,.44-.85,.44Z" />
                </g>
                <g style={{ opacity: '.5' }}>
                    <path
                        d="M279.59,313.83c-.4,0-.8-.17-1.07-.51-.49-.59-.4-1.47,.19-1.96l32.85-27.09,.46-.02c.4-.02,39.69-1.78,49.52-4.45,.74-.2,1.51,.24,1.71,.98,.2,.74-.24,1.51-.98,1.71-9.65,2.61-44.96,4.3-49.66,4.52l-32.13,26.5c-.26,.21-.57,.32-.89,.32Z"
                        style={{ fill: '#565656;' }}
                    />
                </g>
                <g style={{ opacity: '.5' }}>
                    <path
                        d="M247.51,261.93c-.47,0-.93-.24-1.19-.67-.4-.66-.19-1.51,.47-1.91l15.33-9.31c.66-.4,1.51-.19,1.91,.47,.4,.66,.19,1.51-.47,1.91l-15.33,9.31c-.23,.14-.48,.2-.72,.2Z"
                        style={{ fill: '#565656;' }}
                    />
                </g>
            </g>
            <circle cx="84.65" cy="381.24" r="13.42" style={{ fill: '#fff' }} />
            <path
                d="M334.62,179.09h-7.63v-7.63c0-.52-.39-.9-.9-.9h0c-.52,0-.9,.39-1.03,.9v7.63h-7.63c-.52,0-.9,.39-.9,1.03s.39,.9,.9,.9h7.63v7.63c0,.52,.39,.9,1.03,.9s.9-.39,.9-.9h0v-7.63h7.63c.52,0,.9-.39,.9-.9,0-.52-.39-1.03-.9-1.03Z"
                style={{
                    fill: '#e2e2e2',
                    stroke: '#e2e2e2',
                    strokeMiterlimit: '10',
                    strokeWidth: '.75px',
                }}
            />
            <path
                d="M453.51,347.21h-5.85v-5.85c0-.4-.3-.69-.69-.69h0c-.4,0-.69,.3-.79,.69v5.85h-5.85c-.4,0-.69,.3-.69,.79s.3,.69,.69,.69h5.85v5.85c0,.4,.3,.69,.79,.69s.69-.3,.69-.69h0v-5.85h5.85c.4,0,.69-.3,.69-.69,0-.4-.3-.79-.69-.79Z"
                style={{
                    fill: '#e2e2e2',
                    stroke: '#e2e2e2',
                    strokeMiterlimit: '10',
                    strokeWidth: '.75px',
                }}
            />
        </SVGIcon>
    );
}
