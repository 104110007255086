import React from 'react';

import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';

const ReactQueryProvider = props => {
    const queryClient = new QueryClient({
        defaultOptions: {
            queries: {
                refetchOnWindowFocus: false,
            },
        },
    });

    return (
        <QueryClientProvider client={queryClient}>
            {props.children} <ReactQueryDevtools initialIsOpen={false} />
        </QueryClientProvider>
    );
};
export default ReactQueryProvider;
