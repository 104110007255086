import _ from 'lodash';
import dayjs from 'dayjs';
import { getAccountDetails, getUserDetails } from './localStorageUtils';
var utc = require('dayjs/plugin/utc');
var timezone = require('dayjs/plugin/timezone');
var localizedFormat = require('dayjs/plugin/localizedFormat');
var relativeTime = require('dayjs/plugin/relativeTime');

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(localizedFormat);
dayjs.extend(relativeTime);

/**
 * @returns  the time Zone set by user.
 */
export const getUserTimeZone = () => {
    const userData = getUserDetails();
    if (userData?.preferences?.timezone) {
        return userData.preferences.timezone;
    }
    return dayjs.tz.guess();
};

/**
 *
 * @param {Date} date
 * @param {Boolean} showTime
 * @param {Boolean} isGraph
 * @param {Boolean} isMobile
 * @returns a formatted Date String
 */

export const formateDate = (
    date = new Date(),
    showTime = false,
    isGraph = false,
    isMobile = false,
) => {
    let formatString = isGraph
        ? getAccountDetails()?.date_formats?.graph || 'MMMM D, YYYY'
        : getUserDetails()?.preferences?.date_number_format || 'dddd, MMMM D, YYYY';

    if (showTime) {
        let fullDate = dayjs(date.toString())
            .tz(getUserTimeZone())
            .format(`${isMobile ? 'ddd, MMM D, YYYY' : formatString}`);
        let time = dayjs(date.toString()).tz(getUserTimeZone()).format(`hh:mm A`);
        return `${fullDate} at ${time}`;
    }
    return dayjs(date.toString())
        .tz(getUserTimeZone())
        .format(isMobile ? 'ddd, MMM D, YYYY' : formatString);
};

/**
 *
 * @param {timestamp-string} time
 * @returns timestamp String
 */
export const timeInUserTimeZone = (time = dayjs()) => {
    let _time = time;
    if (!time) {
        _time = dayjs();
    }
    return dayjs(_time.toString()).tz(getUserTimeZone()).format();
};

export const timeInUserTimeZoneAddingTime = (additionalTime, unit) => {
    let _time = new Date();
    return dayjs(_time.toString()).tz(getUserTimeZone()).add(additionalTime, unit).format();
};

export const getUserOffset = () => {
    const mins = dayjs().tz(getUserTimeZone()).utcOffset();
    const sign = mins < 0 ? '-' : '+';
    const abs = Math.abs(mins);
    const hours = Math.floor(abs / 60);
    const minutes = abs % 60;
    return (
        sign +
        (hours > 0 ? (hours > 9 ? hours : '0' + hours) + ':' : '') +
        (minutes > 9 ? minutes : '0' + minutes)
    );
};

export const timeForBtn = date => {
    const onlyDate = date.toString().substring(0, 10);
    return dayjs(onlyDate).format('MMMM D, YYYY');
};

/**
 * @param {String} dateString
 * @description This function formats a date and time string from a date
 */
export const getFormattedDateRange = (startDate, endDate) => {
    return {
        startDate: startDate + 'T00:00:00' + getUserOffset(),
        endDate: endDate + 'T23:59:59' + getUserOffset(),
    };
};

export const getDates = (
    startDate = new Date(),
    endDate = new Date(),
    steps = 1,
    isMonth = false,
) => {
    const dateArray = [];
    let currentDate = new Date(startDate);
    const format = isMonth ? 'MMM YYYY' : 'MMMM D, YYYY';
    steps = isMonth ? 28 : steps;
    while (currentDate <= new Date(endDate)) {
        dateArray.push(dayjs(currentDate).format(format));
        // Use UTC date to prevent problems with time zones and DST
        currentDate.setUTCDate(currentDate.getUTCDate() + steps);
    }

    return _.uniq(dateArray);
};
