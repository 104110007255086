const initialState = {
    formsColumns: [],
    forms: {
        data: {},
        nextPage: 1,
        total: 0,
        totalPages: 0,
        loaded: false,
    },
    formsCurrentPage: 1,
    searchTerm: '',
    formsCategories: [],
    formsTags: [],
    filters: {
        categories: null,
        tags: null,
    },

    // Templates
    templates: new Map(),
    templateSearchText: '',
    categories: [],
    activeCategory: null,
    initialTemplateLoad: true,
    categoryAll: {},

    // Builder store
    style: {
        pageBackground: '#ffffff',
        backgroundColor: '#ffffff',
        fontColor: '#000000',
        borderColor: '#000000',
        borderWidth: 0,
        cornerRadius: 0,
        width: 100,
        btnBackgroundColor: 'var(--dark-blue)',
        btnFontColor: '#ffffff',
    },
    id: '',
    time: '',
    loading: false,
    formComponents: {},
    options: {
        formName: '',
        category: '',
        tag: '',
        message: 'Thank you for submitting the form!',
        redirect: '',
        fbPixelId: '',
        fbPixelEvents: {
            pageView: '',
            onSubmit: '',
        },
        categories: [],
        tags: [],
    },
    categoriesLoaded: false,
    tagsLoaded: false,
    domain: '',
};

export default initialState;
