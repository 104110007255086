import React from 'react';

import { SVGIcon } from '../../UIKit/index';

export default function DCVideoCall(props) {
    return (
        <SVGIcon {...props} viewBox="0 0 512 512">
            <g>
                <path d="M463.9,303.5h-416l-43,58.8c-6.7,9-4.9,21.6,4.1,28.3c3.4,2.6,7.6,4,11.9,4h470.1   c11.2-0.1,20.1-9.3,20-20.5c-0.1-4.3-1.5-8.4-4-11.9L463.9,303.5z" />
                <path d="M193.9,223.7l-12.7,51h149.7l-12.7-51c-4.5-18.3-21-31.2-39.9-31.1h-44.5   C214.9,192.5,198.4,205.4,193.9,223.7z" />
                <circle cx="255.9" cy="133.6" r="29.8" />
                <path d="M166.3,219c5.2-22.1,20.7-40.4,41.7-49.1c-7.2-10.3-11-22.5-11-35   c-0.7-32.5,25.1-59.5,57.6-60.2c32.5-0.7,59.5,25.1,60.2,57.6c0,0.9,0,1.7,0,2.6c0,12.5-3.8,24.8-11,35c21,8.8,36.5,27,41.7,49.1   l14.3,58.4H463v-235c0.2-22.1-17.5-40.2-39.6-40.5h-335C66.3,2.1,48.6,20.2,48.8,42.3v235.1h103.4L166.3,219z" />
            </g>
        </SVGIcon>
    );
}
