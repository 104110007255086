import React from 'react';

import { SVGIcon } from '../../UIKit/index';

export default function DCFilter(props) {
    return (
        <SVGIcon {...props} viewBox="0 0 512 512">
            <path d="M190.3,242.4L63,123.2c0,0-32.9-25.5-20.1-58.4c0,0,9.1-19.5,35.5-24.4l355.6,0.1c0,0,31.8,4.8,37.9,34.3  c0,0,2.8,21.1-12.3,37.4L321.8,242.4l-1.3,131l-47.8,47.9l-43.9,41c0,0-8.8,8.5-15.7,9.2c0,0-16.1,0.7-19.5-9.5l-2.1-28.2  L190.3,242.4l40.9-1.9l1.6,158.5l48-45.8l-0.1-117.7l0.6-9.9l3-4.3L425.4,85.3v-1.6l-1.6-0.5L89.4,82.8l-3.2,1.1l144,139.5l1,17.1  L190.3,242.4z" />
        </SVGIcon>
    );
}
