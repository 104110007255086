import React from 'react';

import { SVGIcon } from '../../UIKit/index';

export default function DCAddFollower(props) {
    return (
        <SVGIcon {...props} viewBox="0 0 512.008 440.29">
            <g transform="translate(0 -36.71)">
                <path
                    d="M475.79,36.71H36.57A36.65,36.65,0,0,0,0,73.28V358.83A36.65,36.65,0,0,0,36.57,395.4H202.43L256,477l53.56-81.64H475.42A36.65,36.65,0,0,0,512,358.83V73.65a36.19,36.19,0,0,0-36.2-36.94Z"
                    fill="#d9eaff"
                />
                <circle
                    cx="48.76"
                    cy="48.76"
                    r="48.76"
                    transform="translate(147.089 199.289) rotate(-76.72)"
                    fill="#005eff"
                />
                <path
                    d="M205.76,212.17A103.89,103.89,0,0,0,102,316H310c-.37-57.26-46.91-103.81-104.17-103.81Z"
                    fill="#005eff"
                />
                <path
                    d="M410.78,282.73h-10V133.12a11.51,11.51,0,0,0-11.45-11.45,12.47,12.47,0,0,0-6.28,1.85L360.17,139a11.22,11.22,0,1,0,12.19,18.84.36.36,0,0,0,.37-.37l5.54-3.69V282.36H366.45a11.45,11.45,0,0,0,0,22.9h44.33a11.51,11.51,0,0,0,11.45-11.45,11.19,11.19,0,0,0-11.45-11.08Z"
                    fill="#005eff"
                />
            </g>
        </SVGIcon>
    );
}
