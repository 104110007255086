import React from 'react';

import { SVGIcon } from '../../UIKit/index';

export default function DCResize(props) {
    return (
        <SVGIcon {...props} viewBox="0 0 512 512">
            <path d="M496,0H304c-8.832,0-16,7.168-16,16v32c0,8.832,7.168,16,16,16h98.752L64,402.752V304c0-8.832-7.168-16-16-16H16    c-8.832,0-16,7.168-16,16v192c0,8.832,7.168,16,16,16h192c8.832,0,16-7.168,16-16v-32c0-8.832-7.168-16-16-16h-98.752L448,109.248    V208c0,8.832,7.168,16,16,16h32c8.832,0,16-7.168,16-16V16C512,7.168,504.832,0,496,0z" />
        </SVGIcon>
    );
}
