import React from 'react';

import { SVGIcon } from '../../UIKit/index';

export default function DCUserInfo(props) {
    return (
        <SVGIcon {...props} viewBox="0 0 512 416.7">
            <g>
                <path d="M249.9,245.5h-12.2c-6.3,0-11.4-5.1-11.4-11.4c0-2.3,0.9-4.6,2.6-6.2    c7.4-7.5,13.3-16.4,17.4-26.1c0.8,0.7,1.8,1.1,2.9,1.1c6.8,0,14.9-15.1,14.9-25.3c0-10.2-1-18.7-7.8-18.7c-0.9,0-1.7,0.1-2.5,0.4    c-0.5-28-7.5-62.8-50.2-62.8c-44.5,0-49.7,34.8-50.2,62.7c-0.6-0.1-1.2-0.2-1.8-0.3c-6.9,0-7.8,8.4-7.8,18.7    c0,10.3,8,25.3,14.9,25.3c0.8,0,1.7-0.2,2.4-0.7c4,9.6,9.9,18.3,17.2,25.7c1.7,1.6,2.6,3.9,2.6,6.2c0,6.3-5.1,11.4-11.4,11.4H157    c-26.1,0-47.2,21.1-47.2,47.2l0,0v13.1c0,8.2,6.7,14.9,14.9,14.9h157.5c8.2,0,14.9-6.7,14.9-14.9v-13.1    C297.1,266.7,276,245.6,249.9,245.5C249.9,245.5,249.9,245.5,249.9,245.5z" />
                <path d="M391.2,116H259.1c4.3,10,6.9,20.8,7.7,31.7c2.9,2,5.2,4.7,6.7,7.9h117.7c6.1,0,11-4.9,11-11    c0,0,0,0,0,0v-17.5C402.1,121,397.3,116.1,391.2,116z" />
                <path d="M391.2,188.2H276.2c-2.3,12.1-10.3,22.3-21.5,27.4c-0.3,0.6-0.7,1.1-1,1.7v10.5h137.5    c6.1,0,11-4.9,11-11c0,0,0,0,0,0v-17.5C402.3,193.2,397.4,188.2,391.2,188.2C391.3,188.2,391.3,188.2,391.2,188.2z" />
                <path d="M391.2,260.4h-89.8c6.1,9.7,9.4,20.9,9.4,32.4v7.2h80.4c6.1,0,11-4.9,11-11c0,0,0,0,0,0v-17.5    C402.3,265.3,397.3,260.4,391.2,260.4C391.3,260.4,391.2,260.4,391.2,260.4z" />
                <path d="M438.7,5.9H73.4C35.9,5.9,5.6,36.2,5.5,73.7v269.5c0,37.5,30.3,67.8,67.8,67.8c0,0,0,0,0,0    h365.3c37.5,0,67.8-30.4,67.8-67.8c0,0,0,0,0,0V73.7C506.5,36.2,476.1,5.9,438.7,5.9z M474.5,343.2c0,19.8-16,35.8-35.8,35.8H73.4    c-19.8,0-35.8-16-35.8-35.8V73.7C37.6,53.9,53.6,38,73.4,37.9h365.3c19.7,0.1,35.7,16,35.7,35.7V343.2z" />
            </g>
        </SVGIcon>
    );
}
