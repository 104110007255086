import React from 'react';

import { SVGIcon } from '../../UIKit/index';

export default function DCTablet(props) {
    return (
        <SVGIcon {...props} viewBox="0 0 33.994 33.994">
            <path d="M27.125,0H6.867C5.59,0,4.562,1.033,4.562,2.309v29.377c0,1.272,1.028,2.308,2.305,2.308h20.258   c1.273,0,2.306-1.035,2.306-2.308V2.309C29.433,1.033,28.399,0,27.125,0z M16.997,33.129c-0.758,0-1.371-0.613-1.371-1.37   c0-0.758,0.613-1.372,1.371-1.372c0.756,0,1.371,0.614,1.371,1.372C18.368,32.516,17.753,33.129,16.997,33.129z M27.098,29.186   H6.896V2.774h20.202V29.186z" />
        </SVGIcon>
    );
}
