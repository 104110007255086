import React from 'react';
import PropTypes from 'prop-types';
export default function H2(props) {
    const { children, ...rest } = props;
    return <h2 {...rest}>{children}</h2>;
}

H2.propTypes = {
    children: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
};
