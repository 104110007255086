import React from 'react';

import { SVGIcon } from '../../UIKit/index';

export default function DCReset2(props) {
    return (
        <SVGIcon {...props} viewBox="0 0 374 512">
            <g transform="translate(0)">
                <g transform="translate(0)">
                    <g>
                        <path d="M187.4,396.1c-77.2,0-139.8-62.6-139.8-139.9c0-22.8,5.6-45.2,16.2-65.3l-34-34     c-54.9,87-28.9,202.1,58.1,257c29.8,18.8,64.3,28.8,99.5,28.8v69.9l93.2-93.2l-93.2-93.2L187.4,396.1z" />
                        <path d="M187.5,69.9V0L94.3,93.2l93.2,93.2v-69.9c77.2,0,139.8,62.6,139.8,139.9     c0,22.8-5.6,45.2-16.2,65.3l34,34c54.9-87,28.9-202.1-58.1-257C257.2,79.9,222.7,69.9,187.5,69.9L187.5,69.9z" />
                    </g>
                </g>
            </g>
        </SVGIcon>
    );
}
