import React from 'react';

import { SVGIcon } from '../../UIKit/index';

export default function DCUser1(props) {
    return (
        <SVGIcon {...props} viewBox="0 0 484.1 512">
            <g transform="translate(0.2 0.2)">
                <g transform="translate(0 11.641)">
                    <g>
                        <path d="M238.7,274.5C87.9,274.5,4.9,345.8,4.9,475.3c0,10.1,8.2,18.3,18.3,18.3h431     c10.1,0,18.3-8.2,18.3-18.3C472.6,345.8,389.5,274.5,238.7,274.5z M42.2,457c7.3-96.8,73.2-145.9,196.5-145.9     S428.1,360.2,435.2,457H42.2z" />
                    </g>
                </g>
                <g transform="translate(4.626)">
                    <g>
                        <path d="M234.1,3.4C167.8,2.7,113.4,55.7,112.6,122c0,1.8,0,3.5,0.1,5.3     c-5.5,67.1,44.4,126,111.5,131.5c67.1,5.5,126-44.4,131.5-111.5c0.6-6.7,0.6-13.4,0-20C357.8,61,305.8,5.6,239.5,3.5     C237.7,3.4,235.9,3.4,234.1,3.4z M234.1,222.6c-49.7-3.1-87.5-45.6-84.8-95.3c-2.3-45.9,33.1-85,79-87.3c1.9-0.1,3.9-0.1,5.8-0.1     c46.5-0.4,84.5,37,84.9,83.5c0,1.3,0,2.6-0.1,3.9C321.7,177,283.8,219.6,234.1,222.6z" />
                    </g>
                </g>
            </g>
        </SVGIcon>
    );
}
