import React from 'react';

import { SVGIcon } from '../../UIKit/index';

export default function DCSearchReport(props) {
    return (
        <SVGIcon {...props} viewBox="0 0 512 512">
            <g transform="translate(0 0)">
                <g transform="translate(18.533 19.39)">
                    <path
                        fill="#005EFF"
                        d="M211.3,366.44c-4.1,0-7.43,3.31-7.43,7.41c0,0.01,0,0.01,0,0.02v56.88    c-0.14,4.1,3.07,7.54,7.17,7.68c4.1,0.14,7.54-3.07,7.68-7.17c0.01-0.17,0.01-0.34,0-0.51v-56.88    C218.73,369.77,215.41,366.44,211.3,366.44C211.31,366.44,211.31,366.44,211.3,366.44z"
                    />
                    <path
                        fill="#005EFF"
                        d="M151.2,366.44c-4.1,0-7.43,3.31-7.43,7.41c0,0.01,0,0.01,0,0.02v56.88    c0.14,4.1,3.58,7.31,7.68,7.17c3.9-0.13,7.04-3.27,7.17-7.17v-56.88C158.62,369.77,155.3,366.44,151.2,366.44    C151.2,366.44,151.2,366.44,151.2,366.44z"
                    />
                    <path
                        fill="#005EFF"
                        d="M181.25,370.02c-4.1,0-7.43,3.33-7.43,7.43v89.27c0.14,4.1,3.58,7.31,7.68,7.17    c3.9-0.13,7.04-3.27,7.17-7.17v-89.27C188.68,373.35,185.35,370.02,181.25,370.02z"
                    />
                    <path
                        fill="#005EFF"
                        d="M376.72,165.03h56.88c4.1-0.14,7.31-3.58,7.17-7.68c-0.13-3.9-3.27-7.04-7.17-7.17h-56.88    c-4.1,0.14-7.31,3.58-7.17,7.68C369.68,161.76,372.82,164.9,376.72,165.03z"
                    />
                    <path
                        fill="#005EFF"
                        d="M433.59,210.24h-56.88c-4.1-0.14-7.54,3.07-7.68,7.17c-0.14,4.1,3.07,7.54,7.17,7.68    c0.17,0.01,0.34,0.01,0.51,0h56.88c4.1,0.14,7.54-3.07,7.68-7.17c0.14-4.1-3.07-7.54-7.17-7.68    C433.93,210.24,433.76,210.24,433.59,210.24z"
                    />
                    <path
                        fill="#005EFF"
                        d="M470.05,180.18h-91.06c-4.1-0.14-7.54,3.07-7.68,7.17c-0.14,4.1,3.07,7.54,7.17,7.68    c0.17,0.01,0.34,0.01,0.51,0h91.06c4.1-0.14,7.31-3.58,7.17-7.68C477.09,183.45,473.96,180.32,470.05,180.18z"
                    />
                </g>
                <path
                    fill="#005EFF"
                    d="M464.14,507.16L361.67,404.77c-2.38-2.39-2.38-6.25,0-8.64l34.63-34.63   c2.39-2.38,6.25-2.38,8.64,0l102.4,102.46c2.38,2.39,2.38,6.25,0,8.64l-34.56,34.56C470.39,509.54,466.52,509.54,464.14,507.16z"
                />
                <path
                    fill="#2B88F8"
                    d="M396.3,361.49l-21.6,21.6c2.39-2.38,6.25-2.38,8.64,0l102.4,102.47c2.38,2.39,2.38,6.25,0,8.64   l21.6-21.6c2.38-2.39,2.38-6.25,0-8.64L404.95,361.49C402.56,359.1,398.69,359.1,396.3,361.49z"
                />
                <path
                    fill="#6B8AAA"
                    d="M320.93,342.46l21.64-21.64l47.17,47.17l-21.64,21.64L320.93,342.46z"
                />
                <path
                    fill="#005EFF"
                    d="M380.7,423.89l43.28-43.28l21.64,21.64l-43.28,43.28L380.7,423.89z"
                />
                <path
                    fill="#2B88F8"
                    d="M402.35,402.25l21.64-21.64l21.64,21.64l-21.64,21.64L402.35,402.25z"
                />
                <circle fill="#005EFF" cx="201.1" cy="200.92" r="198.92" />
                <path
                    fill="#005EFF"
                    d="M201.07,2c-5.15,0-10.25,0.19-15.3,0.58c109.54,8.44,191.5,104.08,183.06,213.62   c-7.53,97.81-85.25,175.53-183.06,183.06c5.05,0.39,10.15,0.58,15.3,0.58c109.86,0,198.92-89.06,198.92-198.92S310.94,2,201.07,2z"
                />
                <circle fill="#FFFFFF" cx="201.1" cy="200.92" r="168.32" />
                <path
                    fill="#FFFFFF"
                    d="M201.07,32.6c-5.18,0-10.26,0.25-15.3,0.7c92.57,8.47,160.75,90.37,152.28,182.94   c-7.4,80.84-71.44,144.88-152.28,152.28c5.05,0.46,10.14,0.7,15.3,0.7c92.96-0.01,168.31-75.37,168.31-168.33   c-0.01-92.95-75.36-168.3-168.31-168.31V32.6z"
                />
                <g transform="translate(10.693 14.139)">
                    <path
                        fill="#D9EAFF"
                        d="M145.08,242.64h-61.2v-89.77c0-2.25,1.83-4.08,4.08-4.08l0,0H141c2.25,0,4.08,1.83,4.08,4.08    l0,0V242.64z"
                    />
                    <path
                        fill="#BDD7F6"
                        d="M114.47,148.78v93.85h30.6v-89.77c0-2.25-1.83-4.08-4.08-4.08l0,0L114.47,148.78z"
                    />
                    <path
                        fill="#D9EAFF"
                        d="M267.49,242.64h-61.2V119.86c0-5.43,1.82-9.82,4.08-9.82h53.04c2.25,0,4.08,4.4,4.08,9.82    L267.49,242.64z"
                    />
                    <path
                        fill="#BDD7F6"
                        d="M236.88,110.02v132.62h30.6V119.86c0-5.43-1.82-9.82-4.08-9.82L236.88,110.02z"
                    />
                    <path
                        fill="#D9EAFF"
                        d="M206.28,242.63h-61.2V131.89c0.17-2.09,1.98-3.66,4.08-3.52h53.05    c2.09-0.14,3.91,1.42,4.08,3.52V242.63z"
                    />
                    <path
                        fill="#BDD7F6"
                        d="M175.69,128.38v114.25h30.58V131.89c-0.17-2.09-1.98-3.66-4.08-3.52H175.69z"
                    />
                </g>
                <path
                    fill="#005EFF"
                    d="M296.07,249.35H76.67c-4.1,0.14-7.31,3.58-7.17,7.68c0.13,3.9,3.27,7.04,7.17,7.17h219.41   c4.1-0.14,7.31-3.58,7.17-7.68C303.12,252.61,299.98,249.48,296.07,249.35L296.07,249.35z"
                />
            </g>
        </SVGIcon>
    );
}
