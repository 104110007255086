import React from 'react';

import { SVGIcon } from '../../UIKit/index';

export default function DCKeep(props) {
    return (
        <SVGIcon {...props} viewBox="0 0 503 234.7">
            <path
                fill="#36A635"
                d="M120.9,163.7L105,179.9c-1.9,2.3-5.2,2.7-7.6,0.8c-0.3-0.2-0.6-0.5-0.8-0.8l-57-57.2v53.2  c0.3,2.9-1.8,5.5-4.7,5.9c-0.4,0-0.8,0-1.2,0H10.9c-2.9,0.3-5.5-1.8-5.8-4.7c0-0.4,0-0.8,0-1.2V8.9C4.8,6,6.9,3.4,9.8,3  c0.4,0,0.8,0,1.2,0h22.9c2.9-0.3,5.5,1.8,5.8,4.7c0,0.4,0,0.8,0,1.2v54.6H117c2.9-0.3,5.5,1.8,5.8,4.7c0,0.4,0,0.8,0,1.2v23.2  c0.3,2.9-1.8,5.5-4.7,5.9c-0.4,0-0.8,0-1.2,0H64.4l56.5,56.8c2.3,2,2.6,5.6,0.5,7.9C121.3,163.3,121.1,163.5,120.9,163.7z   M247.7,122.4c0,2.3-0.2,4.9-0.5,7.3c-0.1,3.1-2.8,5.6-5.9,5.4c-0.2,0-0.3,0-0.5,0h-76.6c6.7,14.7,24.1,21.2,38.8,14.5  c3.4-1.6,6.5-3.8,9.1-6.5c1.6-2.6,4.9-3.5,7.6-1.9c0.1,0.1,0.3,0.2,0.4,0.3l17.7,10.8c3.3,2.1,4.2,4.9,1.9,8  c-11.5,15.6-29.9,24.6-49.3,24.1c-33,0.8-60.4-25.3-61.2-58.4c0-1,0-2.1,0-3.1c0-35.2,24.5-61.9,59.3-61.9  C223.7,61,247.7,87.2,247.7,122.4L247.7,122.4z M213.6,110c-3.1-10.9-13.4-18.2-24.8-17.4c-12.8,0-22.2,6.1-25.2,17.4H213.6z   M363.3,107.6v68.2c0.3,2.9-1.8,5.5-4.7,5.9c-0.4,0-0.8,0-1.2,0h-22.2c-2.9,0.3-5.5-1.8-5.8-4.7c0-0.4,0-0.8,0-1.2v-6.8  c-7.8,9.9-19.8,15.6-32.5,15.2c-25.7,0-42.7-14.8-42.7-37.1c0-23.2,16.8-38,41.8-38h33.4v-2.6c0-8.4-7.9-14.1-19.6-14.1  c-6.7-0.3-13.2,2.2-18,6.8c-1.5,2.5-4.1,4.1-7,4.2h-21.7c-4,0-6.3-2.1-5.6-5.6C262,75.7,281.1,61,309.4,61  C341.8,61,363.3,79.7,363.3,107.6L363.3,107.6z M329.5,137.9v-4h-27.8c-9.3,0-13.1,4.9-13.1,11.5c0,6.1,4.9,11,16.8,11  C319.9,156.4,329.5,148.9,329.5,137.9L329.5,137.9z M499,122.9c0,34.2-24.1,61.4-55.8,61.4c-12,0.4-23.6-4.4-31.8-13.1v54.4  c0.3,2.9-1.8,5.5-4.7,5.9c-0.4,0-0.8,0-1.2,0h-22.9c-2.9,0.3-5.5-1.8-5.8-4.7c0-0.4,0-0.8,0-1.2V69.4c-0.3-2.9,1.8-5.5,4.7-5.9  c0.4,0,0.8,0,1.2,0h22.2c2.9-0.3,5.5,1.8,5.8,4.7c0,0.4,0,0.8,0,1.2V76c7.7-9.7,19.6-15.3,32-15C474.2,61,499,87.9,499,122.9  L499,122.9z M464.4,122.9c0.6-15-11.1-27.5-26.1-28.1c-15-0.6-27.5,11.1-28.1,26.1c0,0.7,0,1.3,0,2c0,15,12.1,27.1,27.1,27.1  S464.4,137.8,464.4,122.9z"
            />
        </SVGIcon>
    );
}
