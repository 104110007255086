/* eslint-disable react-hooks/exhaustive-deps */
import { getPublicForm } from 'apis/Forms/index.api';
import DCNoForm from 'Assets/icons/DCNoForm';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import ReactPixel from 'react-facebook-pixel';
import { useLocation, useParams } from 'react-router';
import { CircularLoader, Div } from 'UIKit';
import { setTitle } from 'Utils';
import FormsContextProvider from '../FormsContext/Provider/Provider';
import useErrorDialog from 'Hooks/ErrorDialog/useErrorDialog';

import FormView from '../FormBuilder/formView';
import './style.scss';

const UserForm = () => {
    const showErrorDialog = useErrorDialog();
    const [fieldsData, setFieldsData] = useState(null);
    const [formInfo, setFormInfo] = useState({});
    const [isLoading, setIsLoading] = useState(true);

    const [duplicateResponse, setDuplicateResponse] = useState(false);
    const [cancelledForm, setCancelledForm] = useState(false);
    let { id } = useParams();
    const search = useLocation().search;
    var rId = new URLSearchParams(search).get('rid'); // recepient id for private sharing
    var rqId = new URLSearchParams(search).get('rqid'); // recepient id for private sharing

    const firstRun = useRef(true);
    const handlePixelId = (pixel_id, event) => {
        const options = {
            autoConfig: true,
            debug: false,
        };
        ReactPixel.init(pixel_id, options);

        ReactPixel.trackSingle(pixel_id, event);
    };

    const handleForm = useCallback(async () => {
        let allData = {};
        let info;
        setIsLoading(true);
        let response = getPublicForm(id, rId);

        response
            .then(resp => {
                info = resp.data[0];
                setIsLoading(false);

                allData = {
                    type: info.type,
                    id: info.id,
                    formId: info.form_secret_id,
                    pixelId: info.fbPixelId,
                    pageViewOption: info.page_view_option,
                    onSubmitOption: info.on_submit_option,
                    style: info.style,
                    message: info.thank_you_message,
                    redirect: info.redirectUrl,
                    form_type: info.form_type,
                    iscampaignConnected: info.iscampaignConnected,
                    rId,
                    rqId,
                };
                setFormInfo(allData);
                setFieldsData(info.forms);
                if (allData.style) {
                    document.documentElement.style.setProperty(
                        '--user-form-page-background',
                        allData?.style?.pageBackground,
                    );
                    document.documentElement.style.setProperty(
                        '--user-form-background',
                        allData?.style?.backgroundColor,
                    );
                    document.documentElement.style.setProperty(
                        '--user-form-label-font-color',
                        allData?.style?.fontColor,
                    );
                    document.documentElement.style.setProperty(
                        '--user-form-border-color',
                        allData?.style?.borderColor,
                    );
                    document.documentElement.style.setProperty(
                        '--user-form-button-background-color',
                        allData?.style?.btnBackgroundColor,
                    );
                    document.documentElement.style.setProperty(
                        '--user-form-button-font-color',
                        allData?.style?.btnFontColor,
                    );
                    document.documentElement.style.setProperty(
                        '--user-form-border-width',
                        `${allData?.style?.borderWidth}px`,
                    );
                    document.documentElement.style.setProperty(
                        '--user-form-border-radius',
                        `${allData?.style?.borderRadius}px`,
                    );
                    document.documentElement.style.setProperty(
                        '--user-form-width',
                        `${allData?.style?.width}%`,
                    );
                }
                if (info.page_view_option) {
                    handlePixelId(info.fbPixelId, info.page_view_option);
                }
                setTitle(info.form_name);
            })
            .catch(error => {
                if (error === 'ALREADY_SUBMITTED') {
                    setDuplicateResponse(true);
                } else if (error === 'REQUEST_CANCELLED') {
                    setCancelledForm(true);
                } else {
                    showErrorDialog(error);
                }
                setIsLoading(false);
            });
    }, [id, rId]);

    useEffect(() => {
        if (firstRun.current) {
            handleForm();
        }
    }, [handleForm]);
    return (
        <FormsContextProvider>
            <Div className="FormPresenterDialog FPDUserInfo">
                {duplicateResponse || cancelledForm ? (
                    <Div className="FPDThanks">
                        <Div className="FPDTInner">
                            <Div className="FPDTIcon">
                                <DCNoForm />
                            </Div>
                            <Div className="FPDTTitle">
                                {duplicateResponse
                                    ? 'Already Submitted'
                                    : 'This form is no longer available'}
                            </Div>
                            {duplicateResponse && (
                                <Div className="FPDTSTitle">
                                    Response for this form has been already submitted
                                </Div>
                            )}
                        </Div>
                    </Div>
                ) : (
                    <FormView
                        formData={fieldsData}
                        formInfo={formInfo}
                        // editAllowed={true}
                        inUserForm={true}
                        // formType={formData?.type}
                    />
                )}
                {isLoading ? <CircularLoader /> : null}
            </Div>
        </FormsContextProvider>
    );
};

export default UserForm;
