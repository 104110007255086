import React from 'react';

import { SVGIcon } from '../../UIKit/index';

export default function DCTextField(props) {
    return (
        <SVGIcon {...props} viewBox="0 0 427 512">
            <g>
                <path d="M198,223.5c-8.8,0-16-7.2-16-16v-5.3H96.6v5.3c0,8.8-7.2,16-16,16s-16-7.2-16-16v-21.3c0-8.8,7.2-16,16-16H198  c8.8,0,16,7.2,16,16v21.3C214,216.3,206.8,223.5,198,223.5z" />
                <path d="M139.3,319.5c-8.8,0-16-7.2-16-16V186.2c0-8.8,7.2-16,16-16c8.8,0,16,7.2,16,16v117.3C155.3,312.3,148.1,319.5,139.3,319.5z  " />
                <path d="M155.3,319.5h-32c-8.8,0-16-7.2-16-16s7.2-16,16-16h32c8.8,0,16,7.2,16,16S164.1,319.5,155.3,319.5z" />
                <path d="M347.3,202.2h-74.7c-8.8,0-16-7.2-16-16c0-8.8,7.2-16,16-16h74.7c8.8,0,16,7.2,16,16C363.3,195,356.1,202.2,347.3,202.2z" />
                <path d="M347.3,260.8h-74.7c-8.8,0-16-7.2-16-16c0-8.8,7.2-16,16-16h74.7c8.8,0,16,7.2,16,16C363.3,253.7,356.1,260.8,347.3,260.8z" />
                <path d="M347.3,319.5h-74.7c-8.8,0-16-7.2-16-16s7.2-16,16-16h74.7c8.8,0,16,7.2,16,16S356.1,319.5,347.3,319.5z" />
                <path d="M347.3,383.5H80.6c-8.8,0-16-7.2-16-16c0-8.8,7.2-16,16-16h266.7c8.8,0,16,7.2,16,16C363.3,376.3,356.1,383.5,347.3,383.5z" />
                <path d="M347.3,447.5H80.6c-8.8,0-16-7.2-16-16c0-8.8,7.2-16,16-16h266.7c8.8,0,16,7.2,16,16C363.3,440.3,356.1,447.5,347.3,447.5z" />
                <path d="M368.6,511.5H59.3c-32.4,0-58.7-26.3-58.7-58.7V58.2c0-32.4,26.3-58.7,58.7-58.7h309.3c32.4,0,58.7,26.3,58.7,58.7v394.7  C427.3,485.2,401,511.5,368.6,511.5z M59.3,31.5c-14.7,0-26.7,12-26.7,26.7v394.7c0,14.7,12,26.7,26.7,26.7h309.3  c14.7,0,26.7-12,26.7-26.7V58.2c0-14.7-12-26.7-26.7-26.7H59.3z" />
            </g>
        </SVGIcon>
    );
}
