import React from 'react';

import { SVGIcon } from '../../UIKit/index';

export default function DCRssFeed(props) {
    return (
        <SVGIcon {...props} viewBox="0 0 124 124">
            <g>
                <circle cx="20.3" cy="103.749" r="20" />
                <path d="M67,113.95c0,5.5,4.5,10,10,10s10-4.5,10-10c0-42.4-34.5-77-77-77c-5.5,0-10,4.5-10,10s4.5,10,10,10   C41.5,56.95,67,82.55,67,113.95z" />
                <path d="M114,123.95c5.5,0,10-4.5,10-10c0-62.8-51.1-113.9-113.9-113.9c-5.5,0-10,4.5-10,10s4.5,10,10,10   c51.8,0,93.9,42.1,93.9,93.9C104,119.45,108.4,123.95,114,123.95z" />
            </g>
        </SVGIcon>
    );
}
