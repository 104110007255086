import React from 'react';

import { SVGIcon } from '../../UIKit/index';

export default function DCEnvelope(props) {
    return (
        <SVGIcon {...props} viewBox="0 0 512 512">
            <path d="M39.05,129v265.08c0,0,2.05,18.54,19.24,17.89h36.32h363.89c0,0,16.65-4.81,17.73-21.14V129  c0,0-3.78-16.32-24.11-15.35L58.3,113.59L58.62,86h398.27c0,0,37.95,1.92,45.95,41.49v264.86c0,0-2.65,39.08-44.97,45.65H55.7  c0,0-40.38-6.24-45.73-47.43v-259.3c0,0,4.86-42.16,48.65-45.27v27.27C58.62,113.27,43.49,116.19,39.05,129z" />
            <path d="M67.13,115.59l191.19,190.65c0,0,5.03,2.97,9.19,0l195.54-194.32l18.89,16.92L332.68,278.11l0.24,1.7  l134.59,134.59h-38.11L313.3,297.32l-46.86,46.7c0,0-1.95,2.92-7.46,0l-48.16-48L92.43,413.92l-36.19-1.14L192,277.7L39.38,124.08  l18.07-15.39L67.13,115.59z" />
        </SVGIcon>
    );
}
