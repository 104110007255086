import React from 'react';

import { SVGIcon } from '../../UIKit/index';

export default function DCProducts(props) {
    return (
        <SVGIcon {...props} viewBox="0 0 512 452">
            <g>
                <path d="M512,0.6H120.5v210.9H0v180.6h45.1c19.6,0,36.4-12.6,42.6-30.1h11.5l90.4,90.4l201.9,0v-45.1c0-5.3-0.9-10.4-2.6-15.1H512   V0.6z M482,30.6v30.3H376.4V30.6H482z M346.4,30.6v102.5l-30.2-13.4L286,133.1V30.6H346.4z M256,30.6v30.3H150.5V30.6H256z    M45.1,362.1H30V241.5h30.2V347C60.2,355.3,53.4,362.1,45.1,362.1z M361.5,407.2v15.1c-50.5,0-135.9,0-159.5,0L111.6,332H90.2   v-90.5h65c33-0.3,58.4,29.1,55.6,61.8l41.7,41.7c5.9,5.9,5.9,15.5,0,21.4c-5.9,5.9-15.5,5.9-21.4,0l-57.2-57.2l-21.2,21.2   l57.2,57.2c15.9,15.9,40.9,17.4,58.6,4.5h77.9C354.7,392.1,361.5,398.9,361.5,407.2L361.5,407.2z M286.5,362.1   c1.9-13.6-2.3-27.9-12.7-38.3l-33.1-33.1c-3.2-45.6-43.6-81.6-90.1-79.2V90.9H256v88.3l60.2-26.6l60.2,26.6V90.9H482v271.2H286.5z" />
                <path d="M361.5,241.6h90.2v30h-90.2V241.6z" />
                <path d="M391.5,301.9h60.1v30h-60.1V301.9z" />
            </g>
        </SVGIcon>
    );
}
