import React from 'react';

import { SVGIcon } from '../../UIKit/index';

export default function DCNoTemplateYet(props) {
    return (
        <SVGIcon {...props} viewBox="0 0 266.101 198.063">
            <g transform="translate(-940.657 -108.256)">
                <ellipse
                    cx="117.33"
                    cy="23.466"
                    rx="117.33"
                    ry="23.466"
                    transform="translate(958.641 259.386)"
                    fill="#f5f5f5"
                />
                <g
                    transform="translate(989.363 148.804)"
                    fill="#fff"
                    stroke="#405d63"
                    strokeWidth="2"
                >
                    <rect width="178.969" height="128.821" rx="6" stroke="none" />
                    <rect x="1" y="1" width="176.969" height="126.821" rx="5" fill="none" />
                </g>
                <g
                    transform="translate(998.856 159.14)"
                    fill="#fff"
                    stroke="#405d63"
                    strokeWidth="2"
                >
                    <rect width="160.353" height="107.576" rx="2" stroke="none" />
                    <rect x="1" y="1" width="158.353" height="105.576" rx="1" fill="none" />
                </g>
                <g transform="translate(946 273.376)" fill="#fff" stroke="#405d63" strokeWidth="2">
                    <path
                        d="M0,0H253.3a0,0,0,0,1,0,0V6.163a8,8,0,0,1-8,8H8a8,8,0,0,1-8-8V0A0,0,0,0,1,0,0Z"
                        stroke="none"
                    />
                    <path
                        d="M2,1H251.3a1,1,0,0,1,1,1V6.163a7,7,0,0,1-7,7H8a7,7,0,0,1-7-7V2A1,1,0,0,1,2,1Z"
                        fill="none"
                    />
                </g>
                <line
                    x2="84"
                    transform="translate(1036.5 149.5)"
                    fill="none"
                    stroke="#fff"
                    strokeWidth="3"
                />
                <path
                    d="M11490.8,341.8l40.2,41.547"
                    transform="translate(-10409.467 -232.572)"
                    fill="none"
                    stroke="#405d63"
                    strokeWidth="1.6"
                />
                <path
                    d="M11491.551,341.425l-45.915,41.021"
                    transform="translate(-10409.467 -232.572)"
                    fill="none"
                    stroke="#405d63"
                    strokeWidth="1.6"
                />
                <path d="M0,0,44.75,28,87,0V57H0Z" transform="translate(1035 151)" fill="#f5f5f5" />
                <g transform="translate(2 -2.124)">
                    <rect
                        width="57.5"
                        height="51.551"
                        transform="translate(1047.533 114.5)"
                        fill="#fff"
                    />
                    <g transform="translate(1050.533 120.928)">
                        <g
                            transform="translate(0 8.5)"
                            fill="none"
                            stroke="#405d63"
                            strokeWidth="1.6"
                        >
                            <rect width="51" height="23" stroke="none" />
                            <rect x="0.8" y="0.8" width="49.4" height="21.4" fill="none" />
                        </g>
                        <line
                            x2="28"
                            transform="translate(0.5)"
                            fill="none"
                            stroke="#405d63"
                            strokeWidth="1.6"
                        />
                        <line
                            x2="28"
                            transform="translate(0.5 4)"
                            fill="none"
                            stroke="#405d63"
                            strokeWidth="1.6"
                        />
                    </g>
                    <g
                        transform="translate(1086.533 157.551)"
                        fill="#fff"
                        stroke="#405d63"
                        strokeWidth="1.6"
                    >
                        <rect width="15" height="5" stroke="none" />
                        <rect x="0.8" y="0.8" width="13.4" height="3.4" fill="none" />
                    </g>
                    <line
                        x2="0.467"
                        y2="48.124"
                        transform="translate(1047.033 114.5)"
                        fill="none"
                        stroke="#405d63"
                        strokeWidth="1.6"
                    />
                    <path
                        d="M0,0-.089,48.676"
                        transform="translate(1105.033 114.5)"
                        fill="none"
                        stroke="#405d63"
                        strokeWidth="1.6"
                    />
                    <line
                        x2="48"
                        transform="translate(1052.5 165.624)"
                        fill="none"
                        stroke="#405d63"
                        strokeWidth="1.6"
                    />
                    <line
                        x2="58"
                        transform="translate(1047.033 114.928)"
                        fill="none"
                        stroke="#405d63"
                        strokeWidth="1.6"
                    />
                </g>
                <path
                    d="M11531.19,440.271l-74.431-49.12-12.03-7.8"
                    transform="translate(-10409.467 -232.572)"
                    fill="none"
                    stroke="#405d63"
                    strokeWidth="1.6"
                />
                <path
                    d="M11488.5,411.809c1.181,0,42.688-28.819,42.688-28.819"
                    transform="translate(-10409.467 -232.572)"
                    fill="none"
                    stroke="#405d63"
                    strokeWidth="1.6"
                />
                <line
                    y2="58"
                    transform="translate(1036.033 149.928)"
                    fill="none"
                    stroke="#405d63"
                    strokeWidth="1.6"
                />
                <line
                    y2="58"
                    transform="translate(1122.033 149.928)"
                    fill="none"
                    stroke="#405d63"
                    strokeWidth="1.6"
                />
                <line
                    x2="88"
                    transform="translate(1035.033 207.928)"
                    fill="none"
                    stroke="#405d63"
                    strokeWidth="1.6"
                />
                <g transform="translate(1196.789 134.326) rotate(180)" opacity="0.5">
                    <rect
                        width="3.634"
                        height="21.949"
                        rx="1.817"
                        transform="translate(21.949 9.158) rotate(90)"
                        fill="#c3c5c4"
                    />
                    <rect
                        width="3.634"
                        height="21.949"
                        rx="1.817"
                        transform="translate(12.791 21.949) rotate(180)"
                        fill="#c3c5c4"
                    />
                </g>
                <g transform="translate(951.343 264.729) rotate(180)" opacity="0.5">
                    <rect
                        width="1.769"
                        height="10.686"
                        rx="0.885"
                        transform="translate(10.686 4.458) rotate(90)"
                        fill="#c3c5c4"
                    />
                    <rect
                        width="1.769"
                        height="10.686"
                        rx="0.885"
                        transform="translate(6.227 10.686) rotate(180)"
                        fill="#c3c5c4"
                    />
                </g>
                <g
                    transform="translate(1206.758 169.352) rotate(180)"
                    fill="#fff"
                    stroke="#c3c5c4"
                    strokeWidth="1.6"
                    opacity="0.5"
                >
                    <circle cx="9.969" cy="9.969" r="9.969" stroke="none" />
                    <circle cx="9.969" cy="9.969" r="9.169" fill="none" />
                </g>
                <g transform="translate(976.136 246.488) rotate(180)" fill="#fff" opacity="0.5">
                    <path
                        d="M 9.968544006347656 19.13708305358887 C 4.912993907928467 19.13708305358887 0.8000040054321289 15.02409362792969 0.8000040054321289 9.968544006347656 C 0.8000040054321289 4.912993907928467 4.912993907928467 0.8000040054321289 9.968544006347656 0.8000040054321289 C 15.02409362792969 0.8000040054321289 19.13708305358887 4.912993907928467 19.13708305358887 9.968544006347656 C 19.13708305358887 15.02409362792969 15.02409362792969 19.13708305358887 9.968544006347656 19.13708305358887 Z"
                        stroke="none"
                    />
                    <path
                        d="M 9.968544006347656 1.600004196166992 C 5.354113578796387 1.600004196166992 1.600004196166992 5.354113578796387 1.600004196166992 9.968544006347656 C 1.600004196166992 14.58297348022461 5.354113578796387 18.33708381652832 9.968544006347656 18.33708381652832 C 14.58297348022461 18.33708381652832 18.33708381652832 14.58297348022461 18.33708381652832 9.968544006347656 C 18.33708381652832 5.354113578796387 14.58297348022461 1.600004196166992 9.968544006347656 1.600004196166992 M 9.968544006347656 3.814697265625e-06 C 15.47401428222656 3.814697265625e-06 19.93708419799805 4.46307373046875 19.93708419799805 9.968544006347656 C 19.93708419799805 15.47401428222656 15.47401428222656 19.93708419799805 9.968544006347656 19.93708419799805 C 4.46307373046875 19.93708419799805 3.814697265625e-06 15.47401428222656 3.814697265625e-06 9.968544006347656 C 3.814697265625e-06 4.46307373046875 4.46307373046875 3.814697265625e-06 9.968544006347656 3.814697265625e-06 Z"
                        stroke="none"
                        fill="#c3c5c4"
                    />
                </g>
                <g transform="translate(995.869 143.107) rotate(180)" fill="#fff" opacity="0.5">
                    <path
                        d="M 6.786338806152344 12.77267837524414 C 3.485458850860596 12.77267837524414 0.7999988198280334 10.08721923828125 0.7999988198280334 6.786338806152344 C 0.7999988198280334 3.485458850860596 3.485458850860596 0.7999988198280334 6.786338806152344 0.7999988198280334 C 10.08721923828125 0.7999988198280334 12.77267837524414 3.485458850860596 12.77267837524414 6.786338806152344 C 12.77267837524414 10.08721923828125 10.08721923828125 12.77267837524414 6.786338806152344 12.77267837524414 Z"
                        stroke="none"
                    />
                    <path
                        d="M 6.786338806152344 1.599998474121094 C 3.926578521728516 1.599998474121094 1.599998474121094 3.926578521728516 1.599998474121094 6.786338806152344 C 1.599998474121094 9.646099090576172 3.926578521728516 11.97267913818359 6.786338806152344 11.97267913818359 C 9.646099090576172 11.97267913818359 11.97267913818359 9.646099090576172 11.97267913818359 6.786338806152344 C 11.97267913818359 3.926578521728516 9.646099090576172 1.599998474121094 6.786338806152344 1.599998474121094 M 6.786338806152344 -9.5367431640625e-07 C 10.53432846069336 -9.5367431640625e-07 13.572678565979 3.038349151611328 13.572678565979 6.786338806152344 C 13.572678565979 10.53432846069336 10.53432846069336 13.572678565979 6.786338806152344 13.572678565979 C 3.038349151611328 13.572678565979 -9.5367431640625e-07 10.53432846069336 -9.5367431640625e-07 6.786338806152344 C -9.5367431640625e-07 3.038349151611328 3.038349151611328 -9.5367431640625e-07 6.786338806152344 -9.5367431640625e-07 Z"
                        stroke="none"
                        fill="#c3c5c4"
                    />
                </g>
            </g>
        </SVGIcon>
    );
}
