import React from 'react';

import { SVGIcon } from '../../UIKit/index';

export default function DCAffiliateCenter(props) {
    return (
        <SVGIcon {...props} viewBox="0 0 512 512">
            <g transform="translate(-289.8 -272.8)">
                <ellipse fill="#D5E5FF" cx="552.5" cy="621.6" rx="72.3" ry="52.6" />
                <g transform="translate(290 272.999)">
                    <path
                        fill="#005EFF"
                        stroke="#FFFFFF"
                        strokeWidth="5.26"
                        d="M64.7,389.3C32.3,389.2,6,415.4,6,447.8s26.2,58.7,58.6,58.7c32.4,0,58.7-26.2,58.7-58.6    c0-10.5-2.8-20.8-8.1-29.8l37.6-37.6c59.3,50.6,146.5,50.7,205.8,0.3l37.3,37.3c-16.4,27.8-7.2,63.7,20.6,80.1    c9,5.3,19.3,8.1,29.8,8.1c15.6,0,30.6-6.1,41.7-17c23-21.9,23.8-58.3,1.9-81.2c-0.6-0.7-1.3-1.3-1.9-1.9    c-11.2-10.9-26.1-17-41.7-17c-10.5,0-20.7,2.8-29.7,8.1L379.1,360c24.5-29.3,37.9-66.3,37.8-104.4c0-37.7-13.2-74.1-37.3-103    l36.9-36.9c9,5.3,19.3,8.1,29.7,8.1c15.6,0,30.6-6.1,41.7-17c23-21.9,23.8-58.3,1.9-81.2c-0.6-0.7-1.3-1.3-1.9-1.9    c-11.2-10.9-26.1-17-41.7-17c-32.3,0-58.5,26.2-58.6,58.5c0,10.5,2.8,20.8,8.1,29.8l-36.9,36.9C299,82,212,82.1,152.2,132.1    L115.1,95c16.5-27.9,7.2-63.8-20.7-80.3S30.6,7.5,14.1,35.4s-7.2,63.8,20.7,80.3c18.4,10.8,41.2,10.9,59.6,0l37.1,37.1    c-24.3,28.7-37.6,65.2-37.5,102.8c0,38.1,13.5,75.1,38.1,104.2l-37.7,37.6C85.4,392.1,75.1,389.3,64.7,389.3z M64.7,477.1    c-16.2,0-29.3-13.1-29.3-29.3c0-16.2,13.1-29.3,29.3-29.3s29.3,13.1,29.3,29.3c0,0,0,0,0,0C93.9,464,80.8,477.1,64.7,477.1z     M446.3,418.5c16.2-0.6,29.8,12,30.4,28.2c0.6,16.2-12,29.8-28.2,30.4c-0.7,0-1.4,0-2.2,0c-16.2-0.3-29-13.7-28.7-29.9    C417.9,431.5,430.6,418.9,446.3,418.5z M446.3,36c16.3,0,29.7,13,30.2,29.3c-0.5,16.3-13.9,29.3-30.2,29.3    c-16.2-0.3-29-13.7-28.7-29.9C417.9,48.9,430.6,36.3,446.3,36L446.3,36z M64.7,94.5c-16.2,0-29.3-13.1-29.3-29.3    c0-16.2,13.1-29.3,29.3-29.3c16.2,0,29.3,13.1,29.3,29.3v0C93.9,81.4,80.8,94.5,64.7,94.5z M314.5,375.3    c-36.8,18.7-80.4,18.6-117.1-0.3v-15c-0.1-32.7,25.9-59.5,58.6-60.5c32.7,1,58.6,27.8,58.6,60.5V375.3z M123.2,255.6    c0.7-73,60.5-131.7,133.6-130.9c72,0.7,130.2,58.9,130.9,130.9c0.1,38-15.9,74.2-44.1,99.7c-2.3-47-40.6-84.2-87.7-85.1    c-47,0.8-85.3,37.9-87.7,84.8C139.9,329.7,123.5,293.6,123.2,255.6z"
                    />
                    <path
                        fill="#005EFF"
                        stroke="#FFFFFF"
                        strokeWidth="5.26"
                        d="M314.5,211.6c0-32.3-26.2-58.6-58.6-58.6s-58.6,26.2-58.6,58.6s26.2,58.6,58.6,58.6    C288.3,270.2,314.5,244,314.5,211.6z M256,240.9c-16.2,0-29.3-13.1-29.3-29.3c0-16.2,13.1-29.3,29.3-29.3    c16.2,0,29.3,13.1,29.3,29.3C285.2,227.8,272.1,240.9,256,240.9L256,240.9z"
                    />
                </g>
            </g>
        </SVGIcon>
    );
}
