import React from 'react';

import { SVGIcon } from '../../UIKit/index';

export default function DCGraySEO(props) {
    return (
        <SVGIcon {...props} viewBox="0 0 502.43 496.78">
            <g id="SEO" transform="translate(-4.19 -7.72)">
                <circle
                    id="Ellipse_9"
                    cx="225.56"
                    cy="225.56"
                    r="225.56"
                    transform="translate(5.69 9.22)"
                    fill="#fff"
                    stroke="#707171"
                    strokeMiterlimit="10"
                    strokeWidth="3"
                />
                <circle
                    id="Ellipse_10"
                    cx="40.03"
                    cy="40.03"
                    r="40.03"
                    transform="translate(214.81 300.34)"
                    fill="#fff"
                    stroke="#e2e2e2"
                    strokeMiterlimit="10"
                    strokeWidth="2"
                />
                <path
                    id="Path_120"
                    d="M506.62,131.7a70.21,70.21,0,0,1-134.41,28.42A69.388,69.388,0,0,1,368.63,150a70.59,70.59,0,0,1-.82-33.27v-.16a70.06,70.06,0,0,1,18.9-34.5,69.309,69.309,0,0,1,8.5-7.26c1.45-1,2.93-2,4.46-3A69.84,69.84,0,0,1,436.42,61.5c1.78,0,3.55.06,5.3.2a67.4,67.4,0,0,1,7.3.93,69.56,69.56,0,0,1,27.47,11.43h0A70.512,70.512,0,0,1,490,86.38a72.62,72.62,0,0,1,5.71,7.78q1.62,2.55,3,5.24a70,70,0,0,1,7.86,32.3Z"
                    fill="#e3e2e3"
                />
                <path
                    id="Path_121"
                    d="M412.57,100.28c-2.81,4.43,1.17,12.14-7.24,10s-13.54-3-14,1.63,6.31,4.67,6.31,9.57-3,6.53-7.71,4.2-6-5.72-11.44-3.62-8.58.7-9.8-3.5a6.289,6.289,0,0,0-.87-1.84v-.16a70.06,70.06,0,0,1,18.9-34.5,69.311,69.311,0,0,1,8.5-7.26c1.45-1,2.93-2,4.46-3a9.22,9.22,0,0,1,7,3.67c4.9,6.07,8.17,3,7,10s.7,7.94,4.66,7.7,8.64-.23,9.11,2.8S415.37,95.84,412.57,100.28Z"
                    fill="#707171"
                />
                <path
                    id="Path_122"
                    d="M441.63,142.3s-6.18,2-7.82-2.34.23-5.25-5.25-6.53-5.95-5-9.46-4.56-1.75,1.87-5.83,4.91-7.82,1.63-7.71,7.23,6.19,6.48,7.42,10.16-.88,5.25,1.75,8.05,7.52,1.58,5.25,5.43-5.78,6.83-4,12.78.53,6.31,3.5,10.51,8.76,5.6,9.63,2.8-4-6.48-2.45-9.28,4.2-.88,5.26-5.08,5.31-4.2,5.66-7.12-5.14-7.35-3-9.22,6.3,3.39,9.57-1.28,1.05-8.41,4.44-12.84S444,139.73,441.63,142.3Z"
                    fill="#a9c2ff"
                />
                <path
                    id="Path_123"
                    d="M506.62,131.7a70,70,0,0,1-12.22,39.58,8,8,0,0,1-5.61-.15c-8.75-3.5-2.28-13,1.23-17.69s-5.43-2.62-11.91-7,.7-16.11-5.95-14.18-14.71,4.38-18.56-2.1,3.5-7.53.17-13,1.93-8.58,8.58-9.1,5.78-5.26,14-6,8.05,9.81,17.16,9.11,8.69,1.4,8.69,1.4a4.19,4.19,0,0,1,1.9.4,70,70,0,0,1,2.52,18.73Z"
                    fill="#707171"
                />
                <path
                    id="Path_124"
                    d="M498.76,99.4c-7.57,3.68-10.61-6.34-17.08-3.33s-5.38-2.8-10.52-6.53-15.4,3-14.94-2.8,8.17-4.21,9.81-8.87,9.1-3.51,9.1-3.51a5.08,5.08,0,0,1,1.36-.3h0A70.509,70.509,0,0,1,490,86.38a72.623,72.623,0,0,1,5.71,7.78A60.552,60.552,0,0,1,498.76,99.4Z"
                    fill="#707171"
                />
                <path
                    id="Path_125"
                    d="M435,79.27s4-5.73-2.34-7.42-6.18.52-11.09,0-6.82,3.27-3.15,7.42,6.66,12.09,10,7.85S431.07,79.67,435,79.27Z"
                    fill="#707171"
                />
                <path
                    id="Path_126"
                    d="M461.6,121.05V339.57c0,3.82-2.76,6.92-6.17,6.92H140.16c-3.4,0-6.16-3.1-6.16-6.92V121.05c0-3.82,2.76-6.92,6.16-6.92H455.43C458.84,114.13,461.6,117.23,461.6,121.05Z"
                    fill="#f4f5f6"
                />
                <path
                    id="Path_127"
                    d="M461.6,120.29v16.5H134v-16.5a6.16,6.16,0,0,1,6.16-6.16H455.43a6.16,6.16,0,0,1,6.17,6.16Z"
                    fill="#e3e2e3"
                />
                <path
                    id="Path_128"
                    d="M144,125.46a2.86,2.86,0,1,1,.838,2.032A2.87,2.87,0,0,1,144,125.46Z"
                    fill="#707171"
                />
                <path
                    id="Path_129"
                    d="M155.32,125.46a2.87,2.87,0,1,1,2.87,2.87A2.87,2.87,0,0,1,155.32,125.46Z"
                    fill="#707171"
                />
                <path
                    id="Path_130"
                    d="M166.91,125.46a2.87,2.87,0,1,1,2.87,2.87A2.87,2.87,0,0,1,166.91,125.46Z"
                    fill="#707171"
                />
                <rect
                    id="Rectangle_89"
                    width="34.55"
                    height="34.55"
                    transform="translate(310.27 278.46)"
                    fill="#fff"
                />
                <path
                    id="Path_131"
                    d="M312.87,309.07l10.2-18.4,7.5,10.23,3.75-4.09,7.94,12.26Z"
                    fill="#e3e2e3"
                />
                <path
                    id="Path_132"
                    d="M327.93,288a2.65,2.65,0,1,1,2.64,2.64,2.65,2.65,0,0,1-2.64-2.64Z"
                    fill="#e3e2e3"
                />
                <rect
                    id="Rectangle_90"
                    width="86.15"
                    height="49.93"
                    transform="translate(310.27 215.22)"
                    fill="#fff"
                />
                <path id="Path_133" d="M343.62,227.39v25.58l19.44-12.79Z" fill="#e3e2e3" />
                <path
                    id="Path_134"
                    d="M435.83,178A20.66,20.66,0,0,1,430,192.51a19.68,19.68,0,0,1-14.13,6H182.7c-11.9,0-21.64-9.22-21.64-20.49a19.87,19.87,0,0,1,6.36-14.47,22.2,22.2,0,0,1,15.28-6H415.82A20.3,20.3,0,0,1,435.83,178Z"
                    fill="#fff"
                />
                <path
                    id="Path_135"
                    d="M435.83,178A20.66,20.66,0,0,1,430,192.51a19.68,19.68,0,0,1-14.13,6H373.64v-41h42.18A20.362,20.362,0,0,1,435.83,178Z"
                    fill="#e3e2e3"
                />
                <path
                    id="Path_136"
                    d="M409.13,185.49a10.25,10.25,0,0,1-1.56-.12,10.44,10.44,0,0,1-4.67-18.72h0a10.44,10.44,0,1,1,6.23,18.84ZM403.92,168a6.138,6.138,0,1,1,0,.02Z"
                    fill="#fff"
                />
                <rect
                    id="Rectangle_91"
                    width="1.72"
                    height="14.25"
                    transform="translate(400.907 180.046) rotate(53.49)"
                    fill="#fff"
                />
                <path
                    id="Path_137"
                    d="M386,192.17h0a1.54,1.54,0,0,1,.33-2.15l6.74-5a1.54,1.54,0,0,1,2.15.32h0a1.54,1.54,0,0,1-.32,2.15l-6.75,5a1.54,1.54,0,0,1-2.15-.32Z"
                    fill="#fff"
                />
                <path
                    id="Path_138"
                    d="M230,149.81a1.54,1.54,0,0,0-.27,1.66h0c.4,1.12,1.73,2.36,3.28,3.6.19.16.37.3.52.44l.12.1.09.09c1.44,1.32.9,1.63,3.49,4.33,2.95,3.07,5,3.12,5.56,2.84s-.51-1.86-1.61-3.69c-.05-.09-.1-.18-.16-.27l-.43-.74c-1.29-2.2-1-3.23-.66-6.51.25-2.67,4.16-14.89,4.16-14.89l-5.66-1s-1.71,9.73-3.58,11.23c-1,.79-2.13.8-3.1,1.4l-.2.12A7.73,7.73,0,0,0,230,149.81Z"
                    fill="#e3e2e3"
                />
                <path
                    id="Path_139"
                    d="M230,149.81a1.54,1.54,0,0,0-.27,1.66h0a12.07,12.07,0,0,0,3.28,3.59c.2.16.37.31.52.45l.12.1.09.09c1.44,1.32.9,1.63,3.49,4.33,2.95,3.07,5,3.12,5.56,2.84s-.51-1.86-1.61-3.69a12.75,12.75,0,0,1-5.26-3.88c-1.71-2.19-2.87-5-4-6.69l-.34-.06a7.73,7.73,0,0,0-1.58,1.26Z"
                    fill="#707171"
                />
                <path
                    id="Path_140"
                    d="M275.62,146.29c.35,1.74,2.84,1.45,5.62.78s2.12.12,6.08-.31,5.16-1.91,5.28-2.5-1.85-.71-4-.95l-.79-.1c-2.37-.29-4.1-1.82-6.25-3.08s-5.08-6-5.08-6l-5.43.43s2.85,4.32,3.95,6.2a6.55,6.55,0,0,1,.53,3.7,7.24,7.24,0,0,0,.09,1.83Z"
                    fill="#e3e2e3"
                />
                <path
                    id="Path_141"
                    d="M281.24,147.07c2.78-.68,2.12.12,6.08-.31s5.16-1.91,5.28-2.5-1.85-.71-4-.95h0a11.15,11.15,0,0,1-5.66,1.76c-2.69.06-5.53-.71-7.39-.68a7.241,7.241,0,0,0,0,1.86C276,148,278.46,147.74,281.24,147.07Z"
                    fill="#707171"
                />
                <path
                    id="Path_142"
                    d="M254.84,111.58l-5.08,13.65-6.63,17.82a10.87,10.87,0,0,1-3.52-.32,15.349,15.349,0,0,1-3.4-1.66s1.85-19.14,5.28-30.12a19.84,19.84,0,0,1,2.95-6.39C249.77,98.28,254.84,111.58,254.84,111.58Z"
                    fill="#5f6060"
                />
                <path
                    id="Path_143"
                    d="M249.69,89.9c-2.89-.93-10.42,0-18.6,1.46a5.56,5.56,0,0,1-5.72-2.59h0L204.8,87.16s-6.79,15-3.08,24.64a3.62,3.62,0,0,0,3.6,2.32h16.4c10.23,0,21.65-7,23.13-7.32,2.41-.48,3.38,4.35,5.8,9.43s22.72,22.5,22.72,22.5a18,18,0,0,0,4.41-.45,4.35,4.35,0,0,0,1.89-1.2S257.18,92.32,249.69,89.9Z"
                    fill="#5f6060"
                />
                <path
                    id="Path_144"
                    d="M223.2,28.37a7.21,7.21,0,0,0-3.92-1,12,12,0,0,1-1.9-.12c-7.85-1.15-12.58,10.25-12.86,13.69-.51,6.33-3.86,7.29-8.57,11.25-9.44,7.94-6.68,17.17-1.52,20.6,3.1,2.06,8.56,0,8.56,0s14.94-8.31,22.27-12.34C236.24,58.89,227.61,31,223.2,28.37Z"
                    fill="#5f6060"
                />
                <path
                    id="Path_145"
                    d="M219.12,57s-3.6,1.26-6,.18-4-4.77-4-4.77c1-.05,2.18-1,3.07-6.93l.44.16,7.45,2.68s-.54,2.39-.89,4.67C218.87,54.91,218.71,56.74,219.12,57Z"
                    fill="#e3e2e3"
                />
                <path
                    id="Path_146"
                    d="M220.06,48.33s-.3,1.29-.74,3.7a8.41,8.41,0,0,1-6.71-6.38Z"
                    fill="#707171"
                    opacity="0.3"
                />
                <path
                    id="Path_147"
                    d="M221.17,50.7S211,49.33,211.93,43s.27-10.78,6.82-10.17,7.45,3.22,7.64,5.41S223.84,50.9,221.17,50.7Z"
                    fill="#e3e2e3"
                />
                <path
                    id="Path_148"
                    d="M226,34.77s-6.52,5.62-11,6.07-6.12-1-6.12-1a17.26,17.26,0,0,0,4.19-5.67,3.15,3.15,0,0,1,2.58-1.84C218.87,32.08,224.88,32,226,34.77Z"
                    fill="#5f6060"
                />
                <path
                    id="Path_149"
                    d="M221.94,34.26a15.81,15.81,0,0,1,2.87,3.68,12.22,12.22,0,0,1,.33,6.55s3.41-6.61,1.48-9.82C224.58,31.26,221.94,34.26,221.94,34.26Z"
                    fill="#5f6060"
                />
                <path
                    id="Path_150"
                    d="M227.7,72c-.32,20.63-.09,16.32-2.35,18.27-.54.47-2.07.75-4.09.89-6.32.41-17.44-.63-17.86-1.8-1.46-4-.43-5.3-1.65-11.5-.12-.66-.28-1.37-.46-2.15-1-4.25-2-5.7,1.6-12.53,3.27-6.19,6.66-11.21,7-11,8.61,5.12,9.31.82,9.31.82S227.85,62,227.7,72Z"
                    fill="#707171"
                />
                <path
                    id="Path_151"
                    d="M249.76,125.23l-6.63,17.82a10.87,10.87,0,0,1-3.52-.32,15.349,15.349,0,0,1-3.4-1.66s1.85-19.14,5.28-30.12Z"
                    fill="#fff"
                    opacity="0.3"
                />
                <path
                    id="Path_152"
                    d="M219.17,53s8.86,1.5,9.9,5.32,4.46,27,4.46,27-2.5,2.44-4.58.36S222.4,64,222.4,64Z"
                    fill="#707171"
                />
                <path
                    id="Path_153"
                    d="M221.26,91.19c-6.32.41-17.44-.63-17.86-1.8-1.46-4-.43-5.3-1.65-11.5l3.06-8.59s-.51,9.57,2.31,12.09,11.55,4.86,12.92,7a7.58,7.58,0,0,1,1.22,2.8Z"
                    fill="#fff"
                    opacity="0.3"
                />
                <path
                    id="Path_154"
                    d="M214,87.16s6.24-1.39,7.25-.83A14.73,14.73,0,0,1,225,89.6c.31.7-2.24-.53-3.07-.76a7.53,7.53,0,0,0-2.65.58,8.86,8.86,0,0,1-4.75.48Z"
                    fill="#e3e2e3"
                />
                <path
                    id="Path_155"
                    d="M209.85,52.18s-8.38.51-11.28,4.55c-4.09,5.7-12.2,30-7.56,33.21,5.23,3.65,23.8,1,23.8,1s.62-2.89-.24-4.29S199.74,83,199.1,82.31s8.06-12.57,8.06-14.5S209.85,52.18,209.85,52.18Z"
                    fill="#707171"
                />
                <path
                    id="Path_156"
                    d="M221.57,88.06A12.36,12.36,0,0,0,223.3,90a1.3,1.3,0,0,0,.71.15l-.88-1.58Z"
                    fill="#e3e2e3"
                />
                <path
                    id="Path_157"
                    d="M221.27,86.33l3.8,1.84a.67.67,0,0,1-.63.25,9.66,9.66,0,0,1-1.65-.46Z"
                    fill="#e3e2e3"
                />
                <path id="Path_158" d="M253.73,90.68l-39.71-.02v.88l39.36.01Z" fill="#e3e2e3" />
                <path
                    id="Path_159"
                    d="M223.3,91.54l30.08.01,9.05-22.49-30.03-.01Z"
                    fill="#5f6060"
                />
                <rect
                    id="Rectangle_92"
                    width="58.39"
                    height="4.95"
                    rx="1.52"
                    transform="translate(168.89 219.88)"
                    fill="#707171"
                />
                <path
                    id="Path_160"
                    d="M197.38,241.87h-26.8a1.69,1.69,0,0,1-1.69-1.69h0a1.69,1.69,0,0,1,1.69-1.69h26.8a1.69,1.69,0,0,1,1.69,1.69h0a1.69,1.69,0,0,1-1.69,1.69Z"
                    fill="#6b98f6"
                />
                <rect
                    id="Rectangle_93"
                    width="50.57"
                    height="3.38"
                    rx="1.04"
                    transform="translate(168.89 231.4)"
                    fill="#e3e2e3"
                />
                <rect
                    id="Rectangle_94"
                    width="47.22"
                    height="3.38"
                    rx="1.04"
                    transform="translate(224.08 231.4)"
                    fill="#e3e2e3"
                />
                <path
                    id="Path_161"
                    d="M230.79,241.87H204a1.69,1.69,0,0,1-1.69-1.69h0a1.69,1.69,0,0,1,1.69-1.69h26.79a1.7,1.7,0,0,1,1.7,1.69h0a1.69,1.69,0,0,1-1.7,1.69Z"
                    fill="#e3e2e3"
                />
                <rect
                    id="Rectangle_95"
                    width="58.39"
                    height="4.95"
                    rx="1.52"
                    transform="translate(235.02 219.88)"
                    fill="#707171"
                />
                <rect
                    id="Rectangle_96"
                    width="58.39"
                    height="4.95"
                    rx="1.52"
                    transform="translate(168.89 308.59)"
                    fill="#707171"
                />
                <path
                    id="Path_162"
                    d="M197.38,330.58h-26.8a1.69,1.69,0,0,1-1.69-1.69h0a1.69,1.69,0,0,1,1.69-1.69h26.8a1.69,1.69,0,0,1,1.69,1.69h0a1.69,1.69,0,0,1-1.69,1.69Z"
                    fill="#e3e2e3"
                />
                <rect
                    id="Rectangle_97"
                    width="50.57"
                    height="3.38"
                    rx="1.04"
                    transform="translate(168.89 320.11)"
                    fill="#e3e2e3"
                />
                <rect
                    id="Rectangle_98"
                    width="47.22"
                    height="3.38"
                    rx="1.04"
                    transform="translate(224.08 320.11)"
                    fill="#e3e2e3"
                />
                <path
                    id="Path_163"
                    d="M230.79,330.58H204a1.69,1.69,0,0,1-1.69-1.69h0A1.69,1.69,0,0,1,204,327.2h26.79a1.69,1.69,0,0,1,1.7,1.69h0a1.7,1.7,0,0,1-1.7,1.69Z"
                    fill="#e3e2e3"
                />
                <rect
                    id="Rectangle_99"
                    width="58.39"
                    height="4.95"
                    rx="1.52"
                    transform="translate(235.02 308.59)"
                    fill="#707171"
                />
                <rect
                    id="Rectangle_100"
                    width="58.39"
                    height="4.95"
                    rx="1.52"
                    transform="translate(168.89 264.24)"
                    fill="#707171"
                />
                <path
                    id="Path_164"
                    d="M197.38,286.23h-26.8a1.69,1.69,0,0,1-1.69-1.69h0a1.69,1.69,0,0,1,1.69-1.69h26.8a1.69,1.69,0,0,1,1.69,1.69h0a1.69,1.69,0,0,1-1.69,1.69Z"
                    fill="#e3e2e3"
                />
                <rect
                    id="Rectangle_101"
                    width="50.57"
                    height="3.38"
                    rx="1.04"
                    transform="translate(168.89 275.75)"
                    fill="#e3e2e3"
                />
                <rect
                    id="Rectangle_102"
                    width="47.22"
                    height="3.38"
                    rx="1.04"
                    transform="translate(224.08 275.75)"
                    fill="#e3e2e3"
                />
                <path
                    id="Path_165"
                    d="M230.79,286.23H204a1.69,1.69,0,0,1-1.69-1.69h0a1.69,1.69,0,0,1,1.69-1.69h26.79a1.69,1.69,0,0,1,1.7,1.69h0a1.7,1.7,0,0,1-1.7,1.69Z"
                    fill="#e3e2e3"
                />
                <rect
                    id="Rectangle_103"
                    width="58.39"
                    height="4.95"
                    rx="1.52"
                    transform="translate(235.02 264.24)"
                    fill="#707171"
                />
                <path
                    id="Path_166"
                    d="M138.86,245.33s.61-1.37,1-2,2.09-2.56,2.38-2.13,1.44,3.35.83,4.16,0,2.24-3,3.1Z"
                    fill="#e3e2e3"
                />
                <path
                    id="Path_167"
                    d="M105,236.64s15.6,13,21.68,11.82A121.512,121.512,0,0,0,138.73,245l1.46,3.73s-6.55,8.89-13.37,8.94a38.87,38.87,0,0,1-18.56-4.61Z"
                    fill="#5f6060"
                />
                <path
                    id="Path_168"
                    d="M77.62,374s.46,7.31,1.87,7.41h7.17l8.91-.05a2.89,2.89,0,0,0-2.66-2.6c-2.61-.46-7-2.29-8.54-5.73C82.71,369.44,77.62,374,77.62,374Z"
                    fill="#707171"
                />
                <path
                    id="Path_169"
                    d="M77.11,374.52a18.531,18.531,0,0,0,4.83.91,17.581,17.581,0,0,0,4.5-.65A123.16,123.16,0,0,1,89.76,359c1.47-5.69,3.57-13,6.56-22.12,8.27-25.15,10.78-39.87,11-41.26a.66.66,0,0,1,0-.11h0L81.9,284.32s3.15,34.66-.86,48a58,58,0,0,0-1.46,6.38C77.05,353.12,77.11,374.52,77.11,374.52Z"
                    fill="#5f6060"
                />
                <path
                    id="Path_170"
                    d="M89.76,359c1.47-5.69,3.57-13,6.56-22.12,8.27-25.15,10.78-39.87,11-41.26v-.12L81.9,284.32s3.15,34.66-.86,48C80.48,334.15,89.76,359,89.76,359Z"
                    fill="#fff"
                    opacity="0.3"
                />
                <path
                    id="Path_171"
                    d="M97.47,374s.46,7.31,1.87,7.41h7.17l8.91-.05a2.89,2.89,0,0,0-2.66-2.6c-2.61-.46-7-2.29-8.54-5.73C102.56,369.44,97.47,374,97.47,374Z"
                    fill="#707171"
                />
                <path
                    id="Path_172"
                    d="M111.88,285.13a362.4,362.4,0,0,1-3.55,50.05c-3.89,27.59-2.57,39.29-2.57,39.29a26.5,26.5,0,0,1-3.75,1,17,17,0,0,1-5.56-.65s-5.56-27.59-4-41.4S84.86,286,84.86,286Z"
                    fill="#5f6060"
                />
                <path
                    id="Path_173"
                    d="M93.86,232.56l5,3.26,4.52-1.28c.83-1.18,1-3.45,1.06-5.42a34.055,34.055,0,0,0-.18-3.61l-6.69-2.95-2.5-1.1s.2,2,.22,2.34a17,17,0,0,1-1.43,8.76Z"
                    fill="#e3e2e3"
                />
                <path
                    id="Path_174"
                    d="M112,291.38c-6.18,6-29.55,5-31-1.61s1.57-55.92,12.12-59c2.19-.64,13.89,3.14,13.89,3.14a73.449,73.449,0,0,1,3,10.92c.24,1.19.92,13.8,1.43,25.6.24,5.52.44,10.86.54,14.77a42.479,42.479,0,0,1,.02,6.18Z"
                    fill="#707171"
                />
                <path
                    id="Path_175"
                    d="M95.75,223.89c1.36,2.3,6.13,4.31,8.65,5.23a34.051,34.051,0,0,0-.18-3.61l-6.69-2.95C96.05,222.54,95.15,222.87,95.75,223.89Z"
                    fill="#707171"
                    opacity="0.3"
                />
                <path
                    id="Path_176"
                    d="M106.75,225.1S96,228.47,94,221.72s-4.72-10.86,2.08-13.29,8.9-.26,10.12,1.83S109.5,224.06,106.75,225.1Z"
                    fill="#e3e2e3"
                />
                <path
                    id="Path_177"
                    d="M105.74,209.61s-.69,7.86-2.31,7.52-3.41-1.07-3.17,1.29-1.58,6.43-3.92,6.42c-5,0-10.48-12.91-9.11-15.35C91.2,202.43,111.48,201,105.74,209.61Z"
                    fill="#707171"
                />
                <path
                    id="Path_178"
                    d="M104.16,230.92c-.14-.06-8-3.6-9-3.29s-2.07,3.12-2.07,3.12L107,233.89a6.91,6.91,0,0,0-2.84-2.97Z"
                    fill="#f4f5f6"
                />
                <path
                    id="Path_179"
                    d="M153.27,269.14l-44.22,41.64c-.87.81-2,1.07-2.43.58l-1.49-1.59c-.47-.5-.15-1.57.72-2.39l44.22-41.64c.87-.81,2-1.07,2.43-.58l1.49,1.59C154.46,267.25,154.14,268.32,153.27,269.14Z"
                    fill="#707171"
                />
                <path
                    id="Path_180"
                    d="M128.45,292.51l-19.4,18.27c-.87.81-2,1.07-2.43.58l-1.49-1.59c-.47-.5-.15-1.57.72-2.39l19.4-18.27Z"
                    fill="#e3e2e3"
                />
                <path
                    id="Path_181"
                    d="M143.11,253.42a34.66,34.66,0,1,0,57.45-34.83c-.18-.18-.36-.36-.55-.53a34.66,34.66,0,0,0-56.9,35.36Zm9.9-32a29.789,29.789,0,1,1-.01.01Z"
                    fill="#707171"
                />
                <path
                    id="Path_182"
                    d="M199.52,265.18a31.79,31.79,0,1,1,8.714-22.853,31.93,31.93,0,0,1-8.714,22.853Z"
                    fill="#f4f5f6"
                />
                <path
                    id="Path_183"
                    d="M112,285.18c-24.37-17.37-22.34-40.54-22.34-40.54,8.91,5,17.74,16.13,21.8,25.77C111.73,275.93,111.93,281.27,112,285.18Z"
                    fill="#fff"
                    opacity="0.3"
                />
                <path
                    id="Path_184"
                    d="M136.08,277.27a6.69,6.69,0,0,0,3.12-1.92s.73,0-.13,1.61c-.07.13-.22.47-.34.71a.14.14,0,0,0,.12.21,16.5,16.5,0,0,1,3.9.09c.27,0,.34.68.33.95a2.45,2.45,0,0,1-.51,1.67c-.31.4-1.17.84-3.08,1.61-.73.29-2.81.07-4.76-.6l-1.56-.72.48-2.9.39.08C134.66,278.19,135.43,277.43,136.08,277.27Z"
                    fill="#e3e2e3"
                />
                <path
                    id="Path_185"
                    d="M97.25,233.89s-21.89,4.17,6.45,35.88c4.53,5.07,29,11.67,29,11.67s2.49-2.11,1.23-3.82c0,0-16.86-12.24-20.56-14.19-1.67-.88-6.48-19.54-9.2-24a12.28,12.28,0,0,0-6.92-5.54Z"
                    fill="#707171"
                />
                <path
                    id="Path_186"
                    d="M139.45,245.72a11.139,11.139,0,0,0,.86-2.09,11.63,11.63,0,0,1,1.41-3.2c.15-.15.64,0,.7.68a3.87,3.87,0,0,1-.35,1.39,16.159,16.159,0,0,0-.47,2.75C141.26,246.35,139.45,245.72,139.45,245.72Z"
                    fill="#e3e2e3"
                />
                <path
                    id="Path_187"
                    d="M432.2,203.93l-12.39,2.88-6.13,11.16L409,192.55Z"
                    fill="#707171"
                />
                <rect
                    id="Rectangle_104"
                    width="2.27"
                    height="13.11"
                    transform="translate(417.329 205.745) rotate(-37.15)"
                    fill="#707171"
                />
                <path
                    id="Path_188"
                    d="M391.53,321.41s5.12-6,.28-9.82c0,0-3.53-2.12-6.4-1.94,0,0,4.09,6.82,2.78,7.5s-4-7.92-4-7.92-8-.75-10.6.49c0,0,6.87,5.6,5.43,5.93s-8.87-5.14-8.87-5.14-7.46,1.29-7.05,5.85,3,5.95,4.12,5.82,7.74-2.67,7.89-1.74-3.83,4-6.62,4.12c0,0,5.29,5.79,8.78,4.1s3.78-4.35,6-5.35,4-1,3.14,0-4.84,2-6.08,4.23-2.35,3.71,1.27,3.15,9.49-3.22,9.74-6.26Z"
                    fill="#e3e2e3"
                />
                <path
                    id="Path_189"
                    d="M366.75,316.47s20.28-.85,24.78,4.94a14.679,14.679,0,0,0,4.05,5.65l.48,1.3s-3.52-3.7-4.78-3.92C391.28,324.44,393.22,317.25,366.75,316.47Z"
                    fill="#707171"
                />
                <path
                    id="Path_190"
                    d="M395.39,327.06a12.75,12.75,0,0,1,4.68,9l-.79.07s-1.41-5.94-3.93-8.26S395.39,327.06,395.39,327.06Z"
                    fill="#73b471"
                />
                <path
                    id="Path_191"
                    d="M416.37,313.51a15.08,15.08,0,0,0-1.48,11.9l.9-.25s-.9-7.19.94-10.81S416.37,313.51,416.37,313.51Z"
                    fill="#73b471"
                />
                <path
                    id="Path_192"
                    d="M405.68,299.26s10.77-6.08,5.71-14.26c0,0-4-5-8.36-6.3,0,0,2.34,12.22,0,12.51s-1.63-13.8-1.63-13.8-11.38-5.43-15.83-5c0,0,7.08,11.91,4.78,11.63S380.1,271.7,380.1,271.7s-11.66-2.12-13.5,4.79,1.18,10.35,2.91,10.78,12.8.25,12.51,1.69-7.75,3.76-11.93,2.48c0,0,4.64,11.35,10.68,10.75s7.9-4.34,11.64-4.64,6.47.7,4.6,1.71-8.19.36-11.21,2.94-5.45,4.16.17,5.3,15.67.38,17.67-3.94Z"
                    fill="#e3e2e3"
                />
                <path
                    id="Path_193"
                    d="M372,278.65s30.24,9.69,33.72,20.61a22.92,22.92,0,0,0,2.91,10.49v2.15s-3.19-7.32-4.92-8.32C403.68,303.58,410.41,294.06,372,278.65Z"
                    fill="#707171"
                />
                <path
                    id="Path_194"
                    d="M408.4,309.75a19.89,19.89,0,0,1,2.05,15.67l-1.2-.32s1.13-9.48-1.32-14.24S408.4,309.75,408.4,309.75Z"
                    fill="#73b471"
                />
                <path
                    id="Path_195"
                    d="M491.84,382H381.3c-15.41-17.64-11.34-41.45,9.11-61.54,2.58-2.52,7.51-4.32,12.83-5.58a142.375,142.375,0,0,1,21-2.91,78.36,78.36,0,0,0,9.39-9c10.92-13.23,20.22-12.09,20.22-12.09,11.13-2.17,20.63-2.8,27.67,1.12h0c6.52,3.64,10.94,11.19,12.59,25.06.31,2.63.53,5.48.64,8.57.4,11.26,2.62,21.28,3.94,29.76h0C500.63,367.66,502,377,491.84,382Z"
                    fill="#e3e2e3"
                />
                <path
                    id="Path_196"
                    d="M481.29,310.07c-6.76,3.68-7.5,15.8-18.08,18.94s-28.53,2.84-38.71,13.89c-9.82,10.65-20.66-16.46-16-24.08a142.889,142.889,0,0,1,21-2.92,77.563,77.563,0,0,0,9.39-9c10.93-13.22,20.22-12.09,20.22-12.09,11.14-2.16,20.64-2.8,27.68,1.12h0C494.57,303.94,488.05,306.39,481.29,310.07Z"
                    fill="#f4f5f6"
                />
                <path
                    id="Path_197"
                    d="M402.89,358.55s1.94,5.16,1.54,7.08.27,5.63-.51,7.29-2.17,4.09-.83,4.69c.52.24,6.44-4.39,6.77-5.75s.59-5.37,1.72-7.55a3.24,3.24,0,0,0-1.47-4.73c-1.65-.79-3.95-5.17-3.95-5.17Z"
                    fill="#5f6060"
                />
                <path
                    id="Path_198"
                    d="M434.55,309.61s-32.35-2-36.51,2.29c-4.39,4.52,2.76,49,2.76,49s8.77.25,9.46-2.07c0,0-1.65-21.52-.28-25.25s.5-8.87.5-8.87,17.17,2.56,22.61,1.25S434.55,309.61,434.55,309.61Z"
                    fill="#5f6060"
                />
                <path
                    id="Path_199"
                    d="M442.38,285.87s-4.76,11.13-7.09,12.31S424.55,309,422.74,308.87s1.87,2,1.87,2l15.82-7.73c.85-.42,5.36-7.84,6-11.6S442.38,285.87,442.38,285.87Z"
                    fill="#e3e2e3"
                />
                <path
                    id="Path_200"
                    d="M396.92,367.07s-1.85,5.2-3.39,6.4-3.43,4.48-5.1,5.25-4.29,1.71-3.66,3c.24.5,7.75.79,8.89,0s3.91-3.72,6.18-4.66a3.24,3.24,0,0,0,1.93-4.56c-.75-1.67.32-6.5.32-6.5Z"
                    fill="#5f6060"
                />
                <path
                    id="Path_201"
                    d="M452.12,280.94c-.69-.8,16.83,1.17,19.16,10.12,0,0,13.45-10.6,1.11-19.54-4.27-3.09-4.7-6.4-5.32-9.42s-4.88-11.71-12.28-7.39a9.58,9.58,0,0,0-5.52,1.87C444.41,260.79,439.11,287.57,452.12,280.94Z"
                    fill="#5f6060"
                />
                <path
                    id="Path_202"
                    d="M448.72,311.18s-35.17,3.59-38.26,7.18-15.22,50.27-15.22,50.27,3.59,2,6.85.12c0,0,9.3-15.83,13.42-20.8s5.65-20.32,6.33-20.48,22.71,3.07,30.3-2.76S448.72,311.18,448.72,311.18Z"
                    fill="#707171"
                />
                <path
                    id="Path_203"
                    d="M468.29,279.26a36.67,36.67,0,0,0-19-2,7.26,7.26,0,0,0-5.68,3.62c-2.2,3.62-3.22,5.56-3.22,5.56s.21,1.68,3.21,3c-.82,3,.75,8.24-.69,10.27s-10.38,7.08-10.82,9.88c-.81,5.15,15.28,14.48,20.07,13.48C465.93,320.19,475.3,283.2,468.29,279.26Z"
                    fill="#707171"
                />
                <path
                    id="Path_204"
                    d="M463.54,268.65a19.6,19.6,0,0,0,1.87-5.92c.16-1.9-1.46-8.76-8.34-7.1a9.54,9.54,0,0,0-3.8,1.86c-1.42,1.17-3.39,3.58-.37,9.41l.82,2Z"
                    fill="#5f6060"
                />
                <path
                    id="Path_205"
                    d="M454.1,279.63c-.7.74-.42,3.18,2.84,3,2.14-.13,6.72-2.77,6.29-4.6a22.8,22.8,0,0,1-2.24-8.27l-.38.11-6.54,1.84s.32,2.08.48,4a8.45,8.45,0,0,1-.45,3.92Z"
                    fill="#e3e2e3"
                />
                <path
                    id="Path_206"
                    d="M454.07,271.71s.32,2.08.48,4c3.7-.39,5.4-3.94,6.06-5.88Z"
                    fill="#707171"
                    opacity="0.3"
                />
                <path
                    id="Path_207"
                    d="M453.94,274.73s8.54-2.15,7.12-7.45-1.28-9.15-6.8-8-6,3.45-6,5.34S451.68,275.15,453.94,274.73Z"
                    fill="#e3e2e3"
                />
                <path
                    id="Path_208"
                    d="M450.35,260.19s2.88,5.47,8.29,6.06c3.17.34,4,2.43,4,2.43a49.384,49.384,0,0,0-2.55-9.62S451.31,256.76,450.35,260.19Z"
                    fill="#5f6060"
                />
                <path
                    id="Path_209"
                    d="M451.33,260.91a14.681,14.681,0,0,0-2.44,3.26c-.86,2,.81,6.2,1.5,7.1,0,0-4-6.74-2.65-9.67C449.15,258.5,451.33,260.91,451.33,260.91Z"
                    fill="#5f6060"
                />
                <path
                    id="Path_210"
                    d="M425.28,310.14s-1.7,1.81-1.9,1.9c-1.13.53-2.55.22-3.24.4s-.57-.26-.06-.61,1.75-.21,1.24-.33a13.659,13.659,0,0,0-4.29-.36,3.89,3.89,0,0,1-3-.25,13.649,13.649,0,0,1,4.84-2c1.1.05,4.26-.09,4.26-.09Z"
                    fill="#e3e2e3"
                />
                <path
                    id="Path_211"
                    d="M471.18,282.59s10.61,23.94,7.66,27.45-23.33-.75-23.33-.75l.63-2.22s15-.92,16.08-1.22c1.53-.44-7.61-17.23-7.61-17.23Z"
                    fill="#e3e2e3"
                />
                <path
                    id="Path_212"
                    d="M467.23,278.87c.25,0,3.42,1,5.7,5.55S476,291,476,291s-9.92,7.69-13.86,4.86S463.59,278.78,467.23,278.87Z"
                    fill="#707171"
                />
                <path
                    id="Path_213"
                    d="M457.89,307s-1.71-1.11-2.39-1.71-4.69-1.1-4.68-.69,2.32.95,2.58,1.35,1.67,2.07,2.88,2.07a1.75,1.75,0,0,0,1.61-1.02Z"
                    fill="#e3e2e3"
                />
                <path
                    id="Path_214"
                    d="M453.23,310.56l-4.57-.65a2,2,0,0,1-1.55-1.28l-2.52-8.93c-.18-.6.24-1,.92-.92l4.58.65a2,2,0,0,1,1.54,1.28l2.53,8.93C454.33,310.24,453.92,310.66,453.23,310.56Z"
                    fill="#e3e2e3"
                />
                <path
                    id="Path_215"
                    d="M452.28,310.42l-4.57-.65a2,2,0,0,1-1.55-1.27l-2.53-8.93c-.17-.61.25-1,.93-.93l4.57.65a2,2,0,0,1,1.55,1.28l2.53,8.93C453.38,310.11,453,310.52,452.28,310.42Z"
                    fill="#5f6060"
                />
                <path
                    id="Path_216"
                    d="M444.42,299.78c-.08-.25.1-.42.38-.38a.83.83,0,0,1,.64.53c.07.25-.1.42-.38.38a.8.8,0,0,1-.64-.53Z"
                    fill="#e3e2e3"
                />
                <path
                    id="Path_217"
                    d="M455.51,309.29a30.166,30.166,0,0,1-3.87-.14c-.78-.18-2.76-2.64-2.23-3.26.33-.39,2.18.31,3.06.31s3.17-1.28,4.74,1.33C457.38,307.82,455.51,309.29,455.51,309.29Z"
                    fill="#e3e2e3"
                />
                <path
                    id="Path_218"
                    d="M468.79,315.18s-3.33,3.12-17.27,3.57S419.4,334.6,419.23,339s28.07,11,28.51,10.34,17.91-15.63,17.91-15.63Z"
                    fill="#f4f5f6"
                />
                <path
                    id="Path_219"
                    d="M498.72,355.37c-15.51-19.36-47.37,7-55.61,6.29-7.58-.69,14.78-27.15,42.51-31.08,11.21-1.59,8.52-13.18,8.52-13.54.31,2.63.53,5.48.64,8.57C495.18,336.87,497.4,346.89,498.72,355.37Z"
                    fill="#f4f5f6"
                />
                <path
                    id="Path_220"
                    d="M320.82,88.83l30.2,6.25L331.66,80.03l-1.3,4.36-7.5.88Z"
                    fill="#707171"
                />
                <path id="Path_221" d="M351.02,95.08,326.48,80.42l-24.78-2.8Z" fill="#e3e2e3" />
                <path id="Path_222" d="M331.66,80.03l19.36,15.05L336.71,72.56Z" fill="#e3e2e3" />
                <path
                    id="Path_223"
                    d="M149.78,388.56l30.2,6.25-19.36-15.05-1.3,4.36-7.5.88Z"
                    fill="#707171"
                />
                <path id="Path_224" d="M179.98,394.81l-24.54-14.66-24.78-2.8Z" fill="#e3e2e3" />
                <path id="Path_225" d="M160.62,379.76l19.36,15.05-14.31-22.52Z" fill="#e3e2e3" />
                <path
                    id="Path_226"
                    d="M131.04,173.82l-30.2,6.25,19.36-15.04,1.31,4.35,7.49.88Z"
                    fill="#707171"
                />
                <path id="Path_227" d="M100.84,180.07l24.54-14.66,24.78-2.8Z" fill="#e3e2e3" />
                <path id="Path_228" d="M120.2,165.03l-19.36,15.04,14.31-22.52Z" fill="#e3e2e3" />
                <path
                    id="Path_229"
                    d="M180.53,182.55l.88-1.05a3.86,3.86,0,0,0,2.64,1.09c.9,0,1.48-.42,1.48-1.08s-.34-.92-1.89-1.29c-1.78-.42-2.78-.94-2.78-2.5s1.19-2.43,2.85-2.43a4.61,4.61,0,0,1,3,1.05l-.78,1.11a3.84,3.84,0,0,0-2.27-.86c-.86,0-1.36.45-1.36,1s.38.93,2,1.31c1.77.43,2.69,1.06,2.69,2.47,0,1.57-1.23,2.5-3,2.5a5.1,5.1,0,0,1-3.46-1.32Z"
                    fill="#707171"
                />
                <path
                    id="Path_230"
                    d="M188.21,180.62a3.13,3.13,0,0,1,3.08-3.29c2,0,3,1.58,3,3.39v.41h-4.63a1.75,1.75,0,0,0,1.81,1.6,2.28,2.28,0,0,0,1.71-.75l.85.75a3.258,3.258,0,0,1-5.82-2.11Zm4.67-.45a1.63,1.63,0,0,0-1.6-1.65,1.7,1.7,0,0,0-1.63,1.65Z"
                    fill="#707171"
                />
                <path
                    id="Path_231"
                    d="M199.62,183.77V183a2.52,2.52,0,0,1-2,.91,2,2,0,0,1-2.24-2c0-1.4,1.09-2.07,2.55-2.07a5.18,5.18,0,0,1,1.75.27V180c0-.87-.55-1.35-1.56-1.35a4.36,4.36,0,0,0-1.8.39l-.39-1.16a5.4,5.4,0,0,1,2.4-.52,2.43,2.43,0,0,1,2.77,2.66v3.74Zm0-2.64a4.18,4.18,0,0,0-1.44-.25c-.91,0-1.45.37-1.45,1s.54.93,1.22.93c.95,0,1.67-.53,1.67-1.32Z"
                    fill="#707171"
                />
                <path
                    id="Path_232"
                    d="M202.78,177.46h1.44v1.43a2.22,2.22,0,0,1,2.23-1.54v1.52h-.08c-1.27,0-2.15.83-2.15,2.5v2.4h-1.44Z"
                    fill="#707171"
                />
                <path
                    id="Path_233"
                    d="M207.14,180.63a3.26,3.26,0,0,1,3.28-3.3,3.14,3.14,0,0,1,2.51,1.09l-.89,1a2.11,2.11,0,0,0-1.64-.79,1.9,1.9,0,0,0-1.81,2,1.92,1.92,0,0,0,1.89,2,2.19,2.19,0,0,0,1.63-.79l.87.86a3.31,3.31,0,0,1-5.84-2.1Z"
                    fill="#707171"
                />
                <path
                    id="Path_234"
                    d="M214.31,175.05h1.44v3.39a2.29,2.29,0,0,1,2-1.11,2.17,2.17,0,0,1,2.25,2.43v4h-1.45v-3.58c0-1-.49-1.54-1.35-1.54a1.4,1.4,0,0,0-1.42,1.56v3.56h-1.44Z"
                    fill="#707171"
                />
                <path id="Path_235" d="M221.61,182.13h1.59v1.64h-1.59Z" fill="#707171" />
                <path id="Path_236" d="M224.82,182.13h1.59v1.64h-1.59Z" fill="#707171" />
                <path id="Path_237" d="M228,182.13h1.59v1.64H228Z" fill="#707171" />
                <path id="Path_238" d="M231.25,182.13h1.58v1.64h-1.58Z" fill="#707171" />
                <path
                    id="Path_239"
                    d="M314.75,260.65a1.55,1.55,0,1,1,1.54,1.54,1.55,1.55,0,0,1-1.54-1.54Z"
                    fill="#e3e2e3"
                />
                <path
                    id="Path_240"
                    d="M386.64,261.06H316.71a.41.41,0,0,1-.42-.41h0a.42.42,0,0,1,.42-.42h69.93a.41.41,0,0,1,.41.42h0a.41.41,0,0,1-.41.41Z"
                    fill="#e3e2e3"
                />
                <path
                    id="Path_241"
                    d="M223.31,91.54l30.07.01,9.06-22.49-30.03-.01Z"
                    fill="#fff"
                    opacity="0.3"
                />
                <circle
                    id="Ellipse_11"
                    cx="9.49"
                    cy="9.49"
                    r="9.49"
                    transform="translate(31.85 265.26)"
                    fill="#fff"
                    stroke="#e2e2e2"
                    strokeMiterlimit="10"
                    strokeWidth="2"
                />
                <circle
                    id="Ellipse_12"
                    cx="15.19"
                    cy="15.19"
                    r="15.19"
                    transform="translate(22.59 201.46)"
                    fill="#fff"
                    stroke="#e2e2e2"
                    strokeMiterlimit="10"
                    strokeWidth="2"
                />
                <path
                    id="Path_242"
                    d="M359,362.46h-6v-6a.75.75,0,0,0-.75-.75h0a.77.77,0,0,0-.76.75v6h-6a.77.77,0,0,0-.76.77.76.76,0,0,0,.76.74h6v6a.76.76,0,0,0,.76.75A.75.75,0,0,0,353,370h0v-6h6a.76.76,0,0,0,.76-.74.77.77,0,0,0-.76-.8Z"
                    fill="#e2e2e2"
                    stroke="#e2e2e2"
                    strokeMiterlimit="10"
                    strokeWidth="0.75"
                />
                <path
                    id="Path_243"
                    d="M159.62,74.73H151.2v-8.4a1,1,0,0,0-1-1h0a1.07,1.07,0,0,0-1.07,1v8.4H140.7a1.06,1.06,0,0,0,0,2.12h8.39v8.41a1.07,1.07,0,0,0,1.07,1,1,1,0,0,0,1-1h0V76.85h8.42a1.06,1.06,0,0,0,0-2.12Z"
                    fill="#e2e2e2"
                    stroke="#e2e2e2"
                    strokeMiterlimit="10"
                    strokeWidth="0.75"
                />
                <path
                    id="Path_244"
                    d="M342,399.17H331.84V389a1.27,1.27,0,0,0-1.27-1.27h0a1.3,1.3,0,0,0-1.29,1.27v10.17H319.12a1.3,1.3,0,0,0-1.28,1.3,1.28,1.28,0,0,0,1.28,1.26h10.16v10.19a1.3,1.3,0,0,0,1.29,1.27,1.27,1.27,0,0,0,1.27-1.27h0V401.73H342a1.28,1.28,0,0,0,1.28-1.26,1.3,1.3,0,0,0-1.28-1.3Z"
                    fill="#e2e2e2"
                    stroke="#e2e2e2"
                    strokeMiterlimit="10"
                    strokeWidth="0.75"
                />
                <ellipse
                    id="_Path_"
                    cx="157.29"
                    cy="15.44"
                    rx="157.29"
                    ry="15.44"
                    transform="translate(74.49 473.62)"
                    fill="#f4f5f6"
                />
                <path
                    id="Path_245"
                    d="M245.79,80.3a1,1,0,0,0-1-1.43,2,2,0,0,0-1.84,1.43,1,1,0,0,0,1,1.43A2.05,2.05,0,0,0,245.79,80.3Z"
                    fill="#e3e2e3"
                />
                <path
                    id="Path_246"
                    d="M237.83,422.75c-8.54-12.63-16.77-6-14.67-1.19A3.28,3.28,0,0,0,228.3,423a1.34,1.34,0,0,0-.76-2.33c-1,0-.87-1.05.18-1.16,1.41-.14,2.67,1.23,3.58,2.13,2.13,2.1,4.93,4.78,4.41,8.08-.76,4.93-7.07,8.51-13.38,3.62-9.11-7-3.61-23.74,8.14-23.29a16.13,16.13,0,0,1,14.21,10.64c.43,1.17,2,.43,1.93-.64-.75-8.12-9.18-13.64-16.93-13.27-8,.37-13.8,6.9-14.91,14.55-1.16,8,3.51,16.39,11.78,18C235.62,441,243.8,431.58,237.83,422.75Z"
                    fill="#e3e2e3"
                />
                <path
                    id="Path_247"
                    d="M96.88,113.75c-8.54-12.63-16.78-6-14.67-1.19A3.28,3.28,0,0,0,87.35,114a1.35,1.35,0,0,0-.76-2.33c-1,0-.87-1,.17-1.16,1.42-.14,2.67,1.24,3.59,2.13,2.13,2.1,4.92,4.78,4.41,8.08-.77,4.93-7.08,8.51-13.39,3.62-9.11-7-3.6-23.74,8.14-23.29a16.15,16.15,0,0,1,14.22,10.64c.42,1.17,2,.43,1.93-.64-.75-8.12-9.19-13.64-16.94-13.27-8,.38-13.8,6.9-14.91,14.55-1.16,8,3.51,16.39,11.78,18C94.67,132,102.85,122.58,96.88,113.75Z"
                    fill="#e3e2e3"
                />
            </g>
        </SVGIcon>
    );
}
