import { useCallback, useRef } from 'react';

function useRefWithCallback(onMount, onUnmount, ref) {
    const nodeRef = useRef(null);

    const setRef = useCallback(
        node => {
            if (nodeRef.current) {
                onUnmount(nodeRef.current, ref);
            }

            nodeRef.current = node;

            if (nodeRef.current) {
                onMount(nodeRef.current, ref);
            }
        },
        [onMount, onUnmount, ref],
    );

    return setRef;
}

export default useRefWithCallback;
