import React from 'react';

import { SVGIcon } from '../../UIKit/index';

export default function DCAppStore(props) {
    return (
        <SVGIcon {...props} viewBox="0 0 2000 2000" fill="none">
            <g filter="url(#DCAppStoreFilter)">
                <rect width="2000" height="2000" rx="400" fill="url(#DCAppStorePaint0_linear)" />
                <g filter="url(#DCAppStoreFilter)">
                    <path
                        d="M1650 1646.6H1381.47L1126.22 1000.3L870.963 354H1129.62L1389.81 1000.3L1650 1646.6Z"
                        fill="url(#DCAppStorePaint1_linear)"
                    />
                </g>
                <g filter="url(#DCAppStoreFilter2_f)">
                    <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M1000.3 681.438L1020.71 733.161L1150.24 405.197L1129.62 354.004L1000.3 681.438Z"
                        fill="#E1E4ED"
                    />
                </g>
                <path
                    d="M618.773 1646.6L1129.28 354H870.623L350.246 1646.6H618.773Z"
                    fill="url(#DCAppStorePaint2_linear)"
                />
                <g style={{ mixBlendMode: 'multiply' }} filter="url(#DCAppStoreFilter3_f)">
                    <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M375.541 1583.85C455.566 1411.38 604.959 1277.64 787.893 1218.46L806.71 1170.82C652.917 1215.71 521.417 1312.88 432.733 1441.79L375.541 1583.85Z"
                        fill="#E1E4ED"
                    />
                </g>
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M350 1646.94C443.391 1377.9 699.119 1184.76 999.956 1184.76C1300.79 1184.76 1556.52 1377.9 1649.91 1646.94H1388.9C1311.05 1513.03 1166.01 1423 999.954 1423C833.895 1423 688.864 1513.03 611.008 1646.94H350Z"
                    fill="url(#DCAppStorePaint3_linear)"
                />
                <g style={{ mixBlendMode: 'multiply' }} filter="url(#DCAppStoreFilter4_f)">
                    <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M1369.85 1617.01L1212.45 1218.48C1192.49 1212.01 1172.12 1206.42 1151.4 1201.77L1276.35 1518.15C1312.28 1546.16 1343.84 1579.51 1369.85 1617.01Z"
                        fill="#E1E4ED"
                    />
                </g>
                <path
                    d="M1650 1646.61H1381.47L1126.22 1000.3H1389.81L1650 1646.61Z"
                    fill="url(#DCAppStorePaint4_linear)"
                />
            </g>
            <defs>
                <filter
                    id="DCAppStoreFilter"
                    x="0"
                    y="-26"
                    width="2000"
                    height="2026"
                    filterUnits="userSpaceOnUse"
                    colorInterpolationFilters="sRGB"
                >
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feBlend
                        mode="normal"
                        in="SourceGraphic"
                        in2="BackgroundImageFix"
                        result="shape"
                    />
                    <feColorMatrix
                        in="SourceAlpha"
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                        result="hardAlpha"
                    />
                    <feOffset dy="-26" />
                    <feGaussianBlur stdDeviation="125" />
                    <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
                    <feColorMatrix
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"
                    />
                    <feBlend mode="normal" in2="shape" result="effect1_innerShadow" />
                </filter>
                <filter
                    id="DCAppStoreFilter1_d"
                    x="866.963"
                    y="354"
                    width="787.033"
                    height="1300.6"
                    filterUnits="userSpaceOnUse"
                    colorInterpolationFilters="sRGB"
                >
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feColorMatrix
                        in="SourceAlpha"
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    />
                    <feOffset dy="4" />
                    <feGaussianBlur stdDeviation="2" />
                    <feColorMatrix
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
                    />
                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
                    <feBlend
                        mode="normal"
                        in="SourceGraphic"
                        in2="effect1_dropShadow"
                        result="shape"
                    />
                </filter>
                <filter
                    id="DCAppStoreFilter2_f"
                    x="995.303"
                    y="349.004"
                    width="159.937"
                    height="389.158"
                    filterUnits="userSpaceOnUse"
                    colorInterpolationFilters="sRGB"
                >
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feBlend
                        mode="normal"
                        in="SourceGraphic"
                        in2="BackgroundImageFix"
                        result="shape"
                    />
                    <feGaussianBlur stdDeviation="2.5" result="effect1_foregroundBlur" />
                </filter>
                <filter
                    id="DCAppStoreFilter3_f"
                    x="370.541"
                    y="1165.82"
                    width="441.169"
                    height="423.033"
                    filterUnits="userSpaceOnUse"
                    colorInterpolationFilters="sRGB"
                >
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feBlend
                        mode="normal"
                        in="SourceGraphic"
                        in2="BackgroundImageFix"
                        result="shape"
                    />
                    <feGaussianBlur stdDeviation="2.5" result="effect1_foregroundBlur" />
                </filter>
                <filter
                    id="DCAppStoreFilter4_f"
                    x="1146.4"
                    y="1196.77"
                    width="228.45"
                    height="425.239"
                    filterUnits="userSpaceOnUse"
                    colorInterpolationFilters="sRGB"
                >
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feBlend
                        mode="normal"
                        in="SourceGraphic"
                        in2="BackgroundImageFix"
                        result="shape"
                    />
                    <feGaussianBlur stdDeviation="2.5" result="effect1_foregroundBlur" />
                </filter>
                <linearGradient
                    id="DCAppStorePaint0_linear"
                    x1="1000"
                    y1="0"
                    x2="1000"
                    y2="2000"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#279BFE" />
                    <stop offset="1" stopColor="#3E51FF" />
                </linearGradient>
                <linearGradient
                    id="DCAppStorePaint1_linear"
                    x1="976.002"
                    y1="354"
                    x2="1519"
                    y2="1647"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#F5F5F5" />
                    <stop offset="1" stopColor="white" />
                </linearGradient>
                <linearGradient
                    id="DCAppStorePaint2_linear"
                    x1="561.002"
                    y1="1647"
                    x2="958.502"
                    y2="398"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#F5F5F5" />
                    <stop offset="1" stopColor="white" />
                </linearGradient>
                <linearGradient
                    id="DCAppStorePaint3_linear"
                    x1="1429.5"
                    y1="1465"
                    x2="493.002"
                    y2="1578.5"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#F5F5F5" />
                    <stop offset="1" stopColor="white" />
                </linearGradient>
                <linearGradient
                    id="DCAppStorePaint4_linear"
                    x1="1243"
                    y1="1000"
                    x2="1510"
                    y2="1647"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#FAFAFA" />
                    <stop offset="1" stopColor="white" />
                </linearGradient>
            </defs>
        </SVGIcon>
    );
}
