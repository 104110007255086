import './style.scss';

import React from 'react';
import PropTypes from 'prop-types';
import { Span, CircularLoader, Div } from '../index';

const Button = ({
    id,
    iconName,
    iconColor,
    children,
    buttonClass,
    buttonType,
    iconClass,
    titleClass,
    iconPlacement,
    fullWidth,
    disabled,
    onClick,
    reference,
    style,
    onMouseEnter,
    onMouseLeave,
    loading,
    type,
}) => {
    return (
        <button
            id={id}
            className={
                'mainButton ' +
                (buttonClass ? buttonClass : '') +
                (iconName ? '' : ' withOutIconBtn') +
                ' ' +
                (buttonType ? buttonType : '') +
                ' ' +
                (iconPlacement ? iconPlacement : 'left') +
                ' ' +
                (fullWidth ? 'fullWidth' : '') +
                ' ' +
                (disabled ? 'disabled' : ' ')
            }
            disabled={disabled || loading}
            onClick={onClick}
            ref={reference}
            style={style}
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
            type={type}
        >
            {loading && (
                <Div className="buttonLoading">
                    <CircularLoader />
                </Div>
            )}
            {iconName ? (
                <Span
                    className={'buttonIcon ' + (iconClass ? iconClass : '')}
                    style={{ color: iconColor }}
                >
                    {iconName}
                </Span>
            ) : (
                ''
            )}
            {children ? (
                <Span className={'buttonTitle ' + (titleClass ? titleClass : '')}>{children}</Span>
            ) : (
                ''
            )}
        </button>
    );
};
Button.propTypes = {
    id: PropTypes.number,
    iconName: PropTypes.element,
    iconColor: PropTypes.string,
    children: PropTypes.node,
    buttonClass: PropTypes.string,
    buttonType: PropTypes.string,
    iconClass: PropTypes.string,
    titleClass: PropTypes.string,
    iconPlacement: PropTypes.string,
    fullWidth: PropTypes.bool,
    disabled: PropTypes.bool,
    onClick: PropTypes.func,
    reference: PropTypes.object,
    style: PropTypes.object,
    onMouseEnter: PropTypes.func,
    onMouseLeave: PropTypes.func,
    loading: PropTypes.bool,
    type: PropTypes.string,
};

export default Button;
// Button Types

// RedFillBtn
// RedOutlineBtn
// BlueFillBtn
// BlueOutlineBtn
// WhiteFillBtn
// WhiteOutlineBtn
// BlackFillBtn
// BlackOutlineBtn
// SimpleLink
// headerBtn
// roundBtn

// iconPlacement
// right
