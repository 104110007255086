import React from 'react';

import { SVGIcon } from '../../UIKit/index';

export default function DCContactInfo(props) {
    return (
        <SVGIcon {...props} viewBox="0 0 512 404">
            <g>
                <path
                    fill="#D9EAFF"
                    d="M29.52,1h452.34c16.14,0,29.23,13.09,29.23,29.23v344.55c0,16.14-13.09,29.23-29.23,29.23  H29.52c-16.14,0-29.23-13.09-29.23-29.23V30.23C0.29,14.09,13.37,1,29.52,1z"
                />
                <path
                    fill="#D9EAFF"
                    d="M52.94,10.38h405.47c16.14,0,29.23,13.09,29.23,29.23v311.75  c0,16.14-13.09,29.23-29.23,29.23H52.94c-16.14,0-29.23-13.09-29.23-29.23V39.61C23.71,23.46,36.8,10.38,52.94,10.38z"
                />
                <g transform="translate(9.805 16.032)">
                    <ellipse fill="#6B8AAA" cx="156.84" cy="148.99" rx="49.2" ry="46.86" />
                    <path
                        fill="#6B8AAA"
                        d="M62.13,303.89h185.13c0,0,1.78-94.33-89-96.12c-0.73,0.27-79.21-6.58-96.13,85.85   C62.35,294.99,62.13,303.89,62.13,303.89z"
                    />
                </g>
                <path
                    fill="#489BFF"
                    d="M44.13,1h423.11c24.22,0,43.85,19.63,43.85,43.85v26.45l0,0H0.29l0,0V44.85  C0.29,20.63,19.92,1,44.13,1z"
                />
                <path
                    fill="#1A7AEE"
                    d="M44.13,10.38h423.11c24.22,0,43.85,19.63,43.85,43.85V71.3l0,0H0.29l0,0V54.22  C0.29,30.01,19.92,10.38,44.13,10.38z"
                />
                <path
                    fill="#2B88F8"
                    d="M309.58,136.9h117.15c7.76,0,14.06,6.29,14.06,14.06l0,0c0,7.76-6.29,14.06-14.06,14.06  H309.58c-7.76,0-14.06-6.29-14.06-14.06l0,0C295.52,143.19,301.81,136.9,309.58,136.9z"
                />
                <path
                    fill="#FFFFFF"
                    d="M302.42,266.37h127.75c4.35-0.35,8.18,2.85,8.6,7.19c-0.42,4.34-4.25,7.54-8.6,7.19H302.42  c-4.35,0.35-8.18-2.85-8.6-7.19C294.25,269.22,298.08,266.02,302.42,266.37z"
                />
                <path
                    fill="#FFFFFF"
                    d="M302.42,237.14h127.75c4.35-0.35,8.18,2.85,8.6,7.19c-0.42,4.34-4.25,7.54-8.6,7.19H302.42  c-4.35,0.35-8.18-2.85-8.6-7.19C294.25,239.99,298.08,236.79,302.42,237.14z"
                />
                <path
                    fill="#FFFFFF"
                    d="M302.42,207.91h127.75c4.35-0.35,8.18,2.85,8.6,7.19c-0.42,4.34-4.25,7.54-8.6,7.19H302.42  c-4.35,0.35-8.18-2.85-8.6-7.19C294.25,210.76,298.08,207.56,302.42,207.91z"
                />
            </g>
        </SVGIcon>
    );
}
