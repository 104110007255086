import React from 'react';
import { Page, Text, View, Link, Image, Font } from '@react-pdf/renderer';
import { IBar, ITraffic, ITrafficCost } from './svgDownloadIcons/svgDownloadIcons';
import { abbreviateNumber } from 'Utils/stringUtils';
import { formatNumberString } from 'Utils';
import generateImage from 'Utils/generateImage';
import ColorBox, { getInitialsListingSvg } from './downloadUtils/colorBox';
import config from 'config';
import { styles } from './style';
import { instareportFontFamily } from '../components/constant';

Font.register({ ...instareportFontFamily });

const GoogleAdsDownload = ({ details, paidCompetitor, googleOverview, user, score }) => {
    return (
        <Page>
            <View fixed style={styles.FixedTopHeight}>
                <Text />
            </View>
            <View style={styles.Section}>
                <View style={styles.HeadingView}>
                    <View style={styles.HeadingLogo}>
                        {score?.googleAds ? <ColorBox score={score?.googleAds} /> : null}
                        <Text style={styles.HeadingDiv}>Google Ads</Text>
                        <Text style={styles.HeadingCaption}>
                            Are your Google Ads Campaign performing well?
                        </Text>
                    </View>
                </View>
                <View style={styles.SectionArea}>
                    <View>
                        <Text style={styles.RTRSectionText}>
                            On average, 41% of searches click on the top 3 paid ads on the search
                            results page. Start expanding the reach of people who are looking for
                            your products and services.
                        </Text>
                        <Text style={styles.RTRSectionText}>
                            We ran a comprehensive PPC analysis to see how your direct competitors
                            are spending their advertising budgets with Google Ads.
                        </Text>
                    </View>
                </View>
                {Object.keys(paidCompetitor || {}).length !== 0 ? (
                    <View>
                        <View>
                            <View style={styles.SectionBar}>
                                <Text>Google Ads Overview</Text>
                                <Text>How your Google Ads are performing</Text>
                            </View>
                            <View style={styles.MidSection}>
                                <View style={styles.MSBox1}>
                                    <View style={styles.MSBoxContainer}>
                                        <View style={styles.MP3}>
                                            <Text>Keywords</Text>
                                            <View style={styles.MP}>
                                                <Text style={styles.GreyText}>
                                                    {abbreviateNumber(googleOverview?.keywords)}
                                                </Text>
                                            </View>
                                        </View>
                                        <View style={styles.WH55}>
                                            <IBar color={'blue'} />
                                        </View>
                                    </View>
                                </View>
                                <View style={styles.MSBox2}>
                                    <View style={styles.MSBoxContainer}>
                                        <View style={styles.MP3}>
                                            <Text>Traffic</Text>
                                            <View style={styles.MP}>
                                                <Text style={styles.GreyText}>
                                                    {abbreviateNumber(googleOverview?.traffic)}
                                                </Text>
                                            </View>
                                        </View>
                                        <View style={styles.WH55}>
                                            <ITraffic color={'#FFAB2E'} />
                                        </View>
                                    </View>
                                </View>
                                <View style={styles.MSBox3}>
                                    <View style={styles.MSBoxContainer}>
                                        <View style={styles.MP3}>
                                            <Text>Traffic Cost</Text>
                                            <View style={styles.MP}>
                                                <Text style={styles.GreyText}>
                                                    {' '}
                                                    $
                                                    {formatNumberString(
                                                        googleOverview?.trafficCost,
                                                    )}
                                                </Text>
                                            </View>
                                        </View>
                                        <View style={styles.WH55}>
                                            <ITrafficCost />
                                        </View>
                                    </View>
                                </View>
                            </View>
                        </View>
                    </View>
                ) : null}
                {Object.keys(paidCompetitor || {}).length !== 0 ? (
                    <View>
                        <View style={styles.SectionBar}>
                            <Text>Google Ads Competitor Analysis</Text>
                            <Text>Here’s your Google Ads compared to your competitors</Text>
                        </View>
                        <View style={styles.TableView} wrap={false}>
                            <View style={styles.TableViewHeader}>
                                <View style={styles.TableViewHeaderCell1}>
                                    <Text>Domain</Text>
                                </View>
                                <View style={styles.TableViewHeaderCell2}>
                                    <Text style={styles.ColumnHeaderViewText}>
                                        Competition level
                                    </Text>
                                </View>
                                <View style={styles.ColumnHeaderView}>
                                    <Text style={styles.ColumnHeaderViewText}>Common Keywords</Text>
                                </View>
                                <View style={styles.ColumnHeaderView}>
                                    <Text style={styles.ColumnHeaderViewText}>Paid Keywords</Text>
                                </View>
                                <View style={styles.ColumnHeaderView}>
                                    <Text style={styles.ColumnHeaderViewText}>
                                        Paid Traffic Price
                                    </Text>
                                </View>
                                <View style={styles.ColumnHeaderView}>
                                    <Text style={styles.ColumnHeaderViewText}>Paid Traffic</Text>
                                </View>
                                <View style={styles.ColumnHeaderView}>
                                    <Text style={styles.ColumnHeaderViewText}>SE Keywords</Text>
                                </View>
                            </View>
                            {paidCompetitor?.map((item, index) => (
                                <View style={styles.TableViewContent} key={index}>
                                    <View style={styles.TableViewCell1}>
                                        <Link style={{ textDecoration: 'none' }}>
                                            {item?.domain}
                                        </Link>
                                    </View>
                                    <View style={styles.TableViewCell2}>
                                        <View style={styles.TableViewCell2Cover}>
                                            <View
                                                style={{
                                                    backgroundColor: 'blue',
                                                    width: parseInt(item?.competitionLevel),
                                                    height: 7,
                                                    borderRadius: 3,
                                                }}
                                            />
                                        </View>
                                        <Text style={styles.GAdsLevel}>
                                            {item?.competitionLevel}%
                                        </Text>
                                    </View>
                                    <View style={styles.ColumnView}>
                                        <Text style={styles.ColumnViewText}>
                                            {abbreviateNumber(item?.commonKeywords)}
                                        </Text>
                                    </View>
                                    <View style={styles.ColumnView}>
                                        <Text style={styles.ColumnViewText}>
                                            {abbreviateNumber(item?.paidKeywords)}
                                        </Text>
                                    </View>
                                    <View style={styles.ColumnView}>
                                        <Text style={styles.ColumnViewText}>
                                            ${abbreviateNumber(item?.paidKeywordsPrice)}
                                        </Text>
                                    </View>
                                    <View style={styles.ColumnView}>
                                        <Text style={styles.ColumnViewText}>
                                            {abbreviateNumber(item?.paidTraffic)}
                                        </Text>
                                    </View>
                                    <View style={styles.ColumnView}>
                                        <Text style={styles.ColumnViewText}>
                                            {abbreviateNumber(item?.seKeywords)}
                                        </Text>
                                    </View>
                                </View>
                            ))}
                        </View>
                    </View>
                ) : (
                    <View style={styles.AlertInfo}>
                        <View>
                            <Text>
                                We’re having trouble finding PPC competitor data for your business.
                                This could result in your competitors not running Google Ads
                                campaigns. We see this as a missed opportunity for your competitors,
                                but an actionable item for {details?.name} to take advantage of.
                                Start running a Google Ads campaign to get customers faster.
                            </Text>
                        </View>
                    </View>
                )}
            </View>
            <View style={styles.GITSection} wrap={false}>
                <View style={styles.BottomSection}>
                    {!(typeof user?.image === 'string') ? (
                        <>
                            {Object.keys(user?.image || {})?.length > 0 ? (
                                <Image
                                    src={generateImage(user?.image, user?.name)}
                                    style={styles.GITUserImage}
                                    alt={'image'}
                                />
                            ) : (
                                <View style={styles.GITSvgImage}>
                                    {getInitialsListingSvg(user?.name)}
                                </View>
                            )}
                        </>
                    ) : (
                        <Image
                            src={{
                                uri: `${config.REACT_APP_API_BASE_URL}/v1/corsproxy/`,
                                method: 'GET',
                                body: '',
                                headers: { 'Target-URL': `${user?.image}` },
                            }}
                            cache={false}
                            style={styles.GITUserImage}
                            alt={'image'}
                        />
                    )}
                    <Text style={styles.GITContent}>
                        Would you like to start running PPC campaigns for{' '}
                        {details?.name || 'your company'} to get customers faster? We can help!
                    </Text>
                </View>
            </View>
            <View fixed style={styles.FixedBottomHeight}>
                <Text />
            </View>
        </Page>
    );
};

export default GoogleAdsDownload;
