import React from 'react';

import { SVGIcon } from '../../UIKit/index';

export default function DCGearTool(props) {
    return (
        <SVGIcon {...props} viewBox="0 0 512 512">
            <g>
                <g>
                    <g>
                        <path d="M451.1,213.7c-5.5-23.1-14.8-45.1-27.5-65.1l25-52.4l-34.9-34.9l-52.4,25     c-19.2-11.9-40.4-20.4-62.6-25L281,6h-50.1l-20,55.1c-22.3,5.5-43.4,14.8-62.6,27.5L96,63.6L61,96.1l25,52.4     c-11.9,19.2-20.4,40.4-25,62.6L5.7,231.2v50.1l55.1,20c5.3,22,13.7,43,25,62.6l-25,52.4l34.9,34.9l52.4-25     c19.1,12.7,40.3,22,62.6,27.5l20,52.4H281l20-55.1c7.5-2.5,17.5-5,25-7.5l-40-39.6c-81.5,15.4-160.1-38.2-175.4-119.8     s38.2-160.1,119.8-175.4c8.5-1.6,17-2.5,25.7-2.6c81.7-0.8,148.6,64.9,149.3,146.6c0.1,7.9-0.5,15.8-1.7,23.6l42.5,42.5     c2.8-6.3,4.5-13.1,5-20l55.1-17.5v-50.1L451.1,213.7z" />
                    </g>
                </g>
                <g>
                    <g>
                        <path d="M491.8,437.2L344.2,289.6c22.6-50.5-0.1-109.8-50.6-132.4c-21.1-9.4-44.7-11.3-67-5.3l45,45     c19.3,19.3,19.3,50.7,0,70.1s-50.7,19.3-70.1,0l-45-42.5c-14.4,52.7,16.7,107.1,69.4,121.4c21,5.7,43.2,4.4,63.4-3.8l147.7,147.7     c13.9,14.5,36.8,15,51.3,1.1c0.4-0.4,0.7-0.7,1.1-1.1C503.9,475.7,505,452.7,491.8,437.2z" />
                    </g>
                </g>
            </g>
        </SVGIcon>
    );
}
