import React from 'react';

import { SVGIcon } from '../../UIKit/index';

export default function DCStarFill(props) {
    return (
        <SVGIcon {...props} viewBox="0 0 512 512">
            <g transform="translate(-1.475 -1.484)">
                <g transform="translate(0 0)">
                    <path d="M264.6,426.3l134.6,59.9l-30.1-151c-1.1-5.6,0.6-11.4,4.7-15.5l105.6-105.8l-151.8-30.4    c-5.4-1.1-10-4.8-12.3-9.8L257.6,44L200,173.8c-2.2,5.1-6.8,8.7-12.3,9.8L36,214.1l105.6,105.8c4,4.1,5.8,9.8,4.7,15.5l-30.1,151    l134.6-59.9C255.3,425.3,260,425.3,264.6,426.3z" />
                </g>
            </g>
        </SVGIcon>
    );
}
