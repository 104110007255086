import React from 'react';

import { SVGIcon } from '../../UIKit/index';

export default function DCSearch(props) {
    return (
        <SVGIcon {...props} viewBox="0 0 512 507">
            <path
                d="M506.1,471.6L377.5,343.1c29.4-37.1,45.3-82.7,45.3-130.8c0-56.5-22-109.6-61.9-149.5
	C321,22.8,267.9,0.9,211.4,0.9s-109.6,22-149.5,61.9S0,155.8,0,212.3s22,109.6,61.9,149.5c39.9,39.9,93,61.9,149.5,61.9
	c51.3,0,99.8-18.1,138.2-51.4l127.9,127.9c7.9,7.9,20.7,7.9,28.6,0v0C514,492.3,514,479.5,506.1,471.6z M211.4,391
	c-98.6,0-178.7-80.2-178.7-178.7S112.8,33.5,211.4,33.5s178.7,80.2,178.7,178.7S310,391,211.4,391z"
            />
        </SVGIcon>
    );
}
