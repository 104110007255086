import React from 'react';

import { SVGIcon } from '../../UIKit/index';

export default function DCAddNewStage(props) {
    return (
        <SVGIcon {...props} viewBox="0 0 108 108">
            <g transform="translate(-1059 -384)">
                <circle cx="54" cy="54" r="54" transform="translate(1059 384)" fill="#ddecff" />
                <path
                    d="M3084.223,463.42l-11.129,31.058-.664-31.058Z"
                    transform="translate(-1989.08 -7.959)"
                    fill="#2b88f8"
                />
                <path
                    d="M3131.436,415.5"
                    transform="translate(-1990)"
                    fill="none"
                    stroke="#707070"
                    strokeWidth="1"
                />
                <path
                    d="M3126.6,445.964l8.879,1s1.236-8.159-1.349-9.732c.225.225-29.336,0-29.336,0v8.733Z"
                    transform="translate(-1990)"
                    fill="#2b88f8"
                />
                <circle cx="10" cy="10" r="10" transform="translate(1124.208 410.83)" fill="#fff" />
                <g transform="translate(1155 384)" fill="#fff" stroke="#abcffc" strokeWidth="1.6">
                    <circle cx="3.5" cy="3.5" r="3.5" stroke="none" />
                    <circle cx="3.5" cy="3.5" r="2.7" fill="none" />
                </g>
                <g>
                    <g transform="translate(1065 399)" fill="#fff" stroke="#b1cef2" strokeWidth="2">
                        <rect width="50" height="57" rx="4" stroke="none" />
                        <rect x="1" y="1" width="48" height="55" rx="3" fill="none" />
                    </g>
                    <g transform="translate(0.5)">
                        <line
                            x2="30"
                            transform="translate(1070.5 407.5)"
                            fill="none"
                            stroke="#cee4ff"
                            strokeWidth="2"
                        />
                        <line
                            x2="25"
                            transform="translate(1070.5 411.5)"
                            fill="none"
                            stroke="#e6ebf7"
                            strokeWidth="2"
                        />
                        <line
                            x2="25"
                            transform="translate(1070.5 415.5)"
                            fill="none"
                            stroke="#e6ebf7"
                            strokeWidth="2"
                        />
                        <line
                            x2="28"
                            transform="translate(1077.5 428.5)"
                            fill="none"
                            stroke="#e6ebf7"
                            strokeWidth="2"
                        />
                        <line
                            x2="34"
                            transform="translate(1070.5 442.5)"
                            fill="none"
                            stroke="#e6ebf7"
                            strokeWidth="2"
                        />
                        <line
                            x2="23"
                            transform="translate(1073.5 420.5)"
                            fill="none"
                            stroke="#b7ceeb"
                            strokeWidth="2"
                        />
                        <line
                            x2="27"
                            transform="translate(1073.5 424.5)"
                            fill="none"
                            stroke="#b7ceeb"
                            strokeWidth="2"
                        />
                        <line
                            x2="27"
                            transform="translate(1073.5 438.5)"
                            fill="none"
                            stroke="#b7ceeb"
                            strokeWidth="2"
                        />
                        <line
                            x2="35"
                            transform="translate(1073.5 432.5)"
                            fill="none"
                            stroke="#b7ceeb"
                            strokeWidth="2"
                        />
                        <line
                            y2="6"
                            transform="translate(1070.5 419.5)"
                            fill="none"
                            stroke="#bbcce4"
                            strokeWidth="2"
                        />
                        <line
                            y2="6"
                            transform="translate(1070.5 432.5)"
                            fill="none"
                            stroke="#bbcce4"
                            strokeWidth="2"
                        />
                    </g>
                </g>
                <path
                    d="M3086.99,445.964,3072.983,486.6H3134.8l13.834-39.253a1.828,1.828,0,0,0-1.816-1.383C3146.647,446.05,3086.99,445.964,3086.99,445.964Z"
                    transform="translate(-1989)"
                    fill="#abcffc"
                />
                <g transform="translate(3 -1)">
                    <g transform="translate(5.67 4.67)">
                        <rect
                            width="2"
                            height="12"
                            rx="1"
                            transform="translate(1124.538 411.16)"
                            fill="#2b88f8"
                        />
                    </g>
                    <g transform="translate(1548.368 -703.708) rotate(90)">
                        <rect
                            width="2"
                            height="12"
                            rx="1"
                            transform="translate(1124.538 411.16)"
                            fill="#2b88f8"
                        />
                    </g>
                </g>
            </g>
        </SVGIcon>
    );
}
