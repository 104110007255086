import React from 'react';

import { SVGIcon } from '../../UIKit/index';

export default function DCUserReview3(props) {
    return (
        <SVGIcon {...props} viewBox="0 0 512 512">
            <g transform="translate(4.837 23.217)">
                <path d="M442.48,317.47c-5.55,0.33-10.31-3.89-10.64-9.44c-0.02-0.4-0.02-0.8,0-1.2v-53.23H69.86v53.23   c0.33,5.55-3.89,10.31-9.44,10.64c-0.4,0.02-0.8,0.02-1.2,0c-5.55,0.33-10.31-3.89-10.64-9.44c-0.02-0.4-0.02-0.8,0-1.2v-63.83   c-0.36-5.54,3.84-10.33,9.38-10.69c0.42-0.03,0.83-0.03,1.25,0h383.27c5.55-0.33,10.31,3.89,10.64,9.44c0.02,0.4,0.02,0.8,0,1.2   v63.83c0.36,5.54-3.84,10.33-9.38,10.69C443.32,317.49,442.9,317.49,442.48,317.47z" />
            </g>
            <g transform="translate(22.249 14.51)">
                <path d="M233.42,326.16c-5.55,0.33-10.31-3.89-10.64-9.44c-0.02-0.4-0.02-0.8,0-1.2v-159.7   c0-5.88,4.77-10.65,10.65-10.65s10.65,4.77,10.65,10.65v159.69c0.34,5.55-3.88,10.31-9.43,10.65   C234.24,326.19,233.83,326.19,233.42,326.16z" />
            </g>
            <g transform="translate(20.315 32.89)">
                <path d="M235.35,392.91c-17.63,0.01-31.92-14.27-31.93-31.9c-0.01-17.63,14.27-31.92,31.9-31.93   c0.01,0,0.03,0,0.04,0c17.63,0.01,31.91,14.31,31.9,31.93C267.23,378.63,252.96,392.9,235.35,392.91z M235.35,350.33   c-5.88,0-10.64,4.76-10.64,10.64s4.76,10.64,10.64,10.64c5.88,0,10.64-4.76,10.64-10.64l0,0c0.31-5.55-3.94-10.29-9.49-10.6   c-0.38-0.02-0.77-0.02-1.16,0V350.33z" />
            </g>
            <g transform="translate(17.413 40.629)">
                <path d="M291.5,470.34c-5.55,0.33-10.31-3.89-10.64-9.44c-0.02-0.4-0.02-0.8,0-1.2v-10.64   c0.67-11.09-7.78-20.62-18.87-21.3c-0.81-0.05-1.62-0.05-2.43,0h-42.59c-11.09-0.67-20.62,7.78-21.3,18.87   c-0.05,0.81-0.05,1.62,0,2.43v10.64c0.33,5.55-3.89,10.31-9.44,10.64c-0.4,0.02-0.8,0.02-1.2,0c-5.55,0.33-10.31-3.9-10.64-9.44   c-0.02-0.38-0.02-0.77,0-1.16V449.1c0.07-23.49,19.09-42.52,42.58-42.59h42.58c23.49,0.07,42.51,19.09,42.58,42.58v10.64   c0.31,5.55-3.93,10.3-9.48,10.61C292.27,470.37,291.89,470.37,291.5,470.34z" />
            </g>
            <g transform="translate(37.727 32.89)">
                <path d="M409.56,392.91c-17.63,0-31.92-14.29-31.92-31.92c0-17.63,14.29-31.92,31.92-31.92   c17.63,0,31.92,14.29,31.92,31.92c0,0,0,0,0,0C441.04,378.44,427,392.47,409.56,392.91z M409.56,350.33   c-5.88,0-10.64,4.76-10.64,10.64c0,5.88,4.76,10.64,10.64,10.64c5.88,0,10.64-4.76,10.64-10.64c0.31-5.55-3.94-10.29-9.49-10.6   c-0.39-0.02-0.77-0.02-1.16,0V350.33z" />
            </g>
            <g transform="translate(34.825 40.629)">
                <path d="M465.72,470.34c-5.55,0.33-10.31-3.89-10.64-9.44c-0.02-0.4-0.02-0.8,0-1.2v-10.64   c0.67-11.09-7.78-20.62-18.87-21.3c-0.81-0.05-1.62-0.05-2.43,0h-42.59c-11.09-0.67-20.62,7.78-21.3,18.87   c-0.05,0.81-0.05,1.62,0,2.43v10.64c0,5.88-4.77,10.65-10.65,10.65c-5.88,0-10.65-4.77-10.65-10.65v-10.64   c0.09-23.48,19.11-42.48,42.59-42.55h42.58c23.49,0.07,42.51,19.09,42.58,42.58v10.64c0.31,5.55-3.93,10.3-9.48,10.61   C466.49,470.37,466.1,470.37,465.72,470.34z" />
            </g>
            <g transform="translate(2.902 32.89)">
                <path d="M61.12,392.91c-17.63-0.01-31.91-14.31-31.9-31.93c0.01-17.61,14.28-31.89,31.9-31.9   c17.63,0.01,31.91,14.31,31.9,31.93C93.01,378.63,78.73,392.9,61.12,392.91z M61.12,350.33c-5.88,0-10.65,4.77-10.65,10.65   c0,5.88,4.77,10.65,10.65,10.65s10.65-4.77,10.65-10.65C71.77,355.1,67,350.33,61.12,350.33z" />
            </g>
            <g transform="translate(0 40.629)">
                <path d="M117.28,470.34c-5.55,0.33-10.31-3.89-10.64-9.44c-0.02-0.4-0.02-0.8,0-1.2v-10.64   c0.67-11.09-7.78-20.62-18.87-21.3c-0.81-0.05-1.62-0.05-2.43,0H42.75c-11.09-0.67-20.62,7.78-21.3,18.87   c-0.05,0.81-0.05,1.62,0,2.43v10.64c0.33,5.55-3.89,10.31-9.44,10.64c-0.4,0.02-0.8,0.02-1.2,0c-5.55,0.33-10.31-3.9-10.64-9.44   c-0.02-0.38-0.02-0.77,0-1.16V449.1c0.07-23.49,19.09-42.52,42.58-42.59h42.59c23.49,0.07,42.51,19.09,42.58,42.58v10.64   c0.31,5.55-3.93,10.3-9.47,10.61C118.06,470.37,117.67,470.37,117.28,470.34z" />
            </g>
            <g transform="translate(13.543 0)">
                <path d="M182.51,212.92c-2.12,0-4.26,0-4.26-2.12c-3.99-2.04-6.46-6.17-6.38-10.64l10.64-63.83   L137.8,91.56c-1.93-3.19-2.68-6.96-2.12-10.64c2.12-4.26,4.26-6.38,8.52-6.38l61.75-10.7l27.68-57.45c0-4.26,4.26-6.38,8.52-6.38   c4.48-0.08,8.61,2.4,10.64,6.38l25.55,57.45l59.62,8.52c4.26,0,6.38,4.26,8.52,6.38s0,8.52-2.12,10.64l-44.69,44.77l10.64,63.83   c0,4.26,0,8.52-4.26,10.64c-3.22,2.17-7.43,2.17-10.64,0l-53.23-27.68l-53.23,29.81c-1.7,1.63-4.04,2.41-6.38,2.12L182.51,212.92z    M167.59,91.56l34.12,34.12c2.12,2.12,4.26,6.38,2.12,8.52l-6.38,48.97l40.43-21.38c3.22-2.17,7.43-2.17,10.64,0l40.46,21.3   l-8.52-46.84c0-4.26,0-6.38,2.12-8.52L316.7,93.6l-44.71-6.38c-4.26,0-6.38-2.12-8.52-6.38l-19.2-42.5L225.1,80.91   c-3.95,0.08-7.73,1.6-10.64,4.26L167.59,91.56z" />
            </g>
        </SVGIcon>
    );
}
