import React from 'react';

import { SVGIcon } from '../../UIKit/index';

export default function DCXLSXFile(props) {
    return (
        <SVGIcon {...props} viewBox="0 0 512 512">
            <path
                d="m392 488h-272a48 48 0 0 1 -48-48v-368a48 48 0 0 1 48-48h224l96 96v320a48 48 0 0 1 -48 48z"
                fill="#005eff33"
            />
            <path
                d="m72 360h368a0 0 0 0 1 0 0v80a48 48 0 0 1 -48 48h-272a48 48 0 0 1 -48-48v-80a0 0 0 0 1 0 0z"
                fill="#005eff"
            />
            <path d="m440 120h-48a48 48 0 0 1 -48-48v-48z" fill="#005eff50" />
            <path d="m136 144h240v144h-240z" fill="#005eff50" />
            <path d="m312 144h16v144h-16z" fill="#fff" />
            <path d="m248 144h16v144h-16z" fill="#fff" />
            <path d="m136 112h64v176h-64z" fill="#005eff" />
            <g fill="#fff">
                <path d="m240 448h-24v-56a8 8 0 0 0 -16 0v64a8 8 0 0 0 8 8h32a8 8 0 0 0 0-16z" />
                <path d="m179.578 384.845a8 8 0 0 0 -10.733 3.577l-8.845 17.689-8.845-17.689a8 8 0 0 0 -14.31 7.156l14.211 28.422-14.211 28.422a8 8 0 1 0 14.31 7.156l8.845-17.689 8.845 17.689a8 8 0 1 0 14.31-7.156l-14.211-28.422 14.211-28.422a8 8 0 0 0 -3.577-10.733z" />
                <path d="m371.578 384.845a8 8 0 0 0 -10.733 3.577l-8.845 17.689-8.845-17.689a8 8 0 0 0 -14.31 7.156l14.211 28.422-14.211 28.422a8 8 0 1 0 14.31 7.156l8.845-17.689 8.845 17.689a8 8 0 1 0 14.31-7.156l-14.211-28.422 14.211-28.422a8 8 0 0 0 -3.577-10.733z" />
                <path d="m288 400h16a8 8 0 0 0 0-16h-16a24 24 0 0 0 0 48 8 8 0 0 1 0 16h-16a8 8 0 0 0 0 16h16a24 24 0 0 0 0-48 8 8 0 0 1 0-16z" />
                <path d="m184 112h16v176h-16z" fill="#005eff33" />
                <path d="m136 144h240v16h-240z" fill="#005eff33" />
                <path d="m136 240h240v16h-240z" fill="#005eff33" />
                <path d="m136 192h240v16h-240z" fill="#005eff33" />
            </g>
        </SVGIcon>
    );
}
