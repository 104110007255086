const AccessScopes: {
    [el: string]: {
        [el: string]: {
            text: string;
            description?: string;
            active?: boolean;
        };
    };
} = {
    root: {
        ACCOUNT: {
            text: 'account',
            description: 'Grants full access to all actions related to accounts',
            active: true,
        },
        ANALYTICS: {
            text: 'analytics',
            description: 'Grants access to view account analytics',
            active: true,
        },
        CONTACTS: {
            text: 'contacts',
            description: 'Grants full access to all actions inside of contacts',
            active: true,
        },
        CONVERSATION: {
            text: 'conversation',
            description: 'Grants full access to all conversations',
            active: true,
        },
        DASHBOARD: { text: 'dashboard', active: false },
        DASHCLICKS: {
            text: 'dashclicks',
        },
        DEALS: {
            text: 'deals',
            description: 'Grants full access to everything inside deals',
            active: true,
        },
        FILES: { text: 'files', active: false },
        FORMS: {
            text: 'forms',
            description: 'Grants full access to all actions inside forms',
            active: true,
        },
        INBOUND: {
            text: 'inbound',
            description: 'Grants full access to all actions inside inbound',
            active: true,
        },
        INSTASITES: {
            text: 'instasites',
            description: 'Grants full access to all actions inside instasites',
            active: true,
        },
        INSTAREPORTS: {
            text: 'instareports',
            description: 'Grants full access to all actions inside instareports',
            active: true,
        },
        LEADS: {
            text: 'leads',
            description: 'Grants full access to all actions inside leads',
            active: false,
        },
        PROJECTS: {
            text: 'projects',
            description: 'Grants full access to all actions inside projects',
            active: true,
        },
        REVIEW: {
            text: 'review',
            description: 'Grants full access to all actions inside review',
            active: true,
        },
        SITES: {
            text: 'sites',
            description: 'Grants access to view and interact with account website',
            active: true,
        },
        SYSTEM: {
            text: 'system',
            description:
                'Grants full access to all actions related to activities, announcements, communications, files & users',
            active: true,
        },
        SETTINGS: {
            text: 'settings',
            description: 'Grants full access to general & app specific settings',
            active: false,
        },
        STORE: {
            text: 'store',
            description: 'Grants full access to all actions inside store',
            active: true,
        },
        TEMPLATES: {
            text: 'templates',
            description: 'Grants full access to all actions inside templates',
            active: true,
        },
        REPUTATION: {
            text: 'reputation',
            description: 'Grants full access to all actions inside reputation',
            active: true,
        },
        USERS: { text: 'users', active: false },
    },
    analytics: {
        ADS: {
            text: 'ads',
            description:
                'Grants access to view and manage ad data (Facebook, Google Ads, Bing, etc.)',
            active: true,
        },
    },
    contacts: {
        CONTACTS_READ: {
            text: 'contacts.read',
            description: 'Grants read access to select contacts',
            active: true,
        },
        CONTACTS_CREATE: {
            text: 'contacts.create',
            description: 'Grants access to create new contacts',
            active: true,
        },
        CONTACTS_DELETE: {
            text: 'contacts.delete',
            description: 'Grants delete access to select contacts',
            active: true,
        },
        CONTACTS_UPDATE: {
            text: 'contacts.update',
            description: 'Grants update access to select contacts',
            active: true,
        },
        CONTACTS_EXTERNAL: {
            text: 'contacts.external',
            description:
                'Grants access to import contacts from external sources (ex: Pipedrive, Hubspot, etc)',
            active: true,
        },
        CONVERSATION: {
            text: 'conversations',
            description: 'Grants access to view conversations between users and contacts',
            active: true,
        },
    },
    deals: {
        DEALS_READ: {
            text: 'deals.read',
            description: 'Grants read access to select deals',
            active: true,
        },
        DEALS_CREATE: {
            text: 'deals.create',
            description: 'Grants access to create new deals',
            active: true,
        },
        DEALS_DELETE: {
            text: 'deals.delete',
            description: 'Grants access to delete select deals',
            active: true,
        },
        DEALS_UPDATE: {
            text: 'deals.update',
            description: 'Grants update access to select deals',
            active: true,
        },
        CONVERSATION: { text: 'conversations', active: false },
    },
    forms: {
        FORMS_READ: {
            text: 'forms.read',
            description: 'Grants read access to forms',
            active: true,
        },
        FORMS_CREATE: {
            text: 'forms.create',
            description: 'Grants access to create new forms',
            active: true,
        },
        FORMS_DELETE: {
            text: 'forms.delete',
            description: 'Grants access to delete forms',
            active: true,
        },
        FORMS_UPDATE: {
            text: 'forms.update',
            description: 'Grants access to update forms',
            active: true,
        },
        FORMS_TEMPLATES: {
            text: 'forms.templates',
            description: 'Grants access to view form templates',
            active: true,
        },
    },
    inbound: {
        INBOUND_LEADS: {
            text: 'inbound.leads',
            description: 'Grants access to view and interact with inbound leads',
            active: true,
        },
        INBOUND_CAMPAIGNS: {
            text: 'inbound.campaigns',
            description: 'Grants access to view and interact with inbound campaigns',
            active: true,
        },
        INBOUND_REPORTING: {
            text: 'inbound.reporting',
            description: 'Grants access to view inbound reporting',
            active: true,
        },
    },
    instareports: {
        INSTAREPORTS_LIST: {
            text: 'instareports.list',
            description: 'Grants access to view and interact with existing instareports',
            active: true,
        },
        INSTAREPORTS_REPORTING: {
            text: 'instareports.reporting',
            description: 'Grants access to view instarepors reporting',
            active: true,
        },
        INSTAREPORTS_TEMPLATES: {
            text: 'instareports.templates',
            description: 'Grants access to view instareports templates',
            active: true,
        },
        INSTAREPORTS_CREATE: {
            text: 'instareports.create',
            description: 'Grants access to create instareports',
            active: true,
        },
    },
    instasites: {
        INSTASITES_LIST: {
            text: 'instasites.list',
            description: 'Grants access to view and interact with existing instasites',
            active: true,
        },
        INSTASITES_REPORTING: {
            text: 'instasites.reporting',
            description: 'Grants access to view instasite reporting',
            active: true,
        },
        INSTASITES_TEMPLATES: {
            text: 'instasites.templates',
            description: 'Grants access to view instasite templates',
            active: true,
        },
        INSTASITES_CREATE: {
            text: 'instasites.create',
            description: 'Grants access to create instasite',
            active: true,
        },
    },
    leads: {
        LEADS_SEARCH: {
            text: 'leads.search',
            description: 'Grants access to start new lead searches',
            active: true,
        },
        LEADS_LIST: {
            text: 'leads.list',
            description: 'Grants access to view list of existing leads',
            active: true,
        },
    },
    templates: {
        TEMPLATES_READ: {
            text: 'templates.read',
            description: 'Grants read access to account templates',
            active: true,
        },
        TEMPLATES_CREATE: {
            text: 'templates.create',
            description: 'Grants access to create new account templates',
            active: true,
        },
        TEMPLATES_DELETE: {
            text: 'templates.delete',
            description: 'Grants delete access to account templates',
            active: true,
        },
        TEMPLATES_UPDATE: {
            text: 'templates.update',
            description: 'Grants update access to account templates',
            active: true,
        },
    },
    projects: {
        PROJECTS_ANALYTICS: {
            text: 'projects.analytics',
            description: 'Grants analytics access in projects',
            active: true,
        },
        PROJECTS_FORMS: {
            text: 'projects.forms',
            description: 'Grants forms access in projects',
            active: true,
        },
        PROJECTS_WEBSITE: {
            text: 'projects.website',
            description: 'Grants website access in projects',
            active: true,
        },
        PROJECTS_FILES: {
            text: 'projects.files',
            description: 'Grants files access in projects',
            active: true,
        },
        PROJECTS_NOTEBOOKS: {
            text: 'projects.notebooks',
            description: 'Grants notebook access in projects',
            active: true,
        },
    },
    system: {
        ACTIVITIES: {
            text: 'activities',
            description: 'Grant access to recent activity logs throughout the dashboard',
            active: true,
        },
        ANNOUNCEMENTS: {
            text: 'announcements',
            description: 'Grant access to view public announcements in the notifications sidebar',
            active: true,
        },
        COMMUNICATIONS: {
            text: 'communications',
            description: 'Grants full access to all actions inside contacts',
            active: true,
        },
        FILES_UPLOAD: {
            text: 'files.upload',
            description: 'Grants access to upload files',
            active: true,
        },
        FILES_DOWNLOAD: {
            text: 'files.download',
            description: 'Grants access to download files',
            active: true,
        },
        FILES_DELETE: {
            text: 'files.delete',
            description: 'Grants access to delete files',
            active: true,
        },
        USERS_ME: {
            text: 'users.me',
            description: 'Default permission for every user. Allows access to own user profile',
            active: true,
        },
        USERS_READ: {
            text: 'users.read',
            description: 'Grants read access to user profiles',
            active: true,
        },
        USERS_CREATE: {
            text: 'users.create',
            description: 'Grants access to invite new users to your dashboard account',
            active: true,
        },
        USERS_UPDATE: {
            text: 'users.update',
            description: 'Grants access to manage user profiles',
            active: true,
        },
        USERS_DELETE: {
            text: 'users.delete',
            description: 'Grants access to remove users from dashboard account',
            active: true,
        },
    },
    settings: {
        SETTINGS_GENERAL: {
            text: 'settings.general',
            description: 'Grants access to general settings',
            active: true,
        },
        SETTINGS_APP: {
            text: 'settings.app',
            description: 'Grants access to app specific settings',
            active: true,
        },
    },
    account: {
        ACCOUNT_READ: {
            text: 'accounts.read',
            description: 'Grants read access to account business profile and sub-accounts',
            active: true,
        },
        ACCOUNT_UPDATE: {
            text: 'accounts.update',
            description: 'Grants access to modify account business profile',
            active: true,
        },
        ACCOUNT_CREATE: {
            text: 'accounts.create',
            description: 'Grants access to invite new sub-accounts',
            active: true,
        },
        EMAIL_CUSTOM_DOMAIN: {
            text: 'accounts.email_custom_domain',
            description: 'Grants access to email custom domain',
            active: true,
        },
    },
    store: {
        STORE_INVOICES: {
            text: 'store.invoices',
            description: 'Grants access to storefront and billing details',
            active: true,
        },
    },
    dashboard: {},
    sites: {},
    conversation: {
        SUPPORT: {
            text: 'conversation.support',
            description: 'Grants access to conversation support',
            active: true,
        },
        ALL: {
            text: 'conversation.all',
            description: 'Grants access to all conversations',
            active: true,
        },
        ASSIGNED_INBOX: {
            text: 'conversation.assigned_inbox',
            description: 'Grants access to assigned teams and inbox',
            active: true,
        },
        ASSIGNED: {
            text: 'conversation.assigned',
            description: 'Grants access to assigned teams and inbox',
            active: true,
        },
        MANAGER: {
            text: 'conversation.manager',
            description: 'Grants access to manager',
            active: true,
        },
        MACROS: {
            text: 'conversation.macros',
            description: 'Grants access to macros',
            active: true,
        },
        REASSIGN: {
            text: 'conversation.reassign',
            description: 'Grants access to reassign teams and inbox',
            active: true,
        },
    },
    reputation: {
        REPUTATION_READ: {
            text: 'reputation.read',
            description: 'Grant access to view reviews',
            active: true,
        },
        REVIEW_REQUESTS: {
            text: 'reputation.requests',
            description: 'Grant access to review requests',
            active: true,
        },
        REVIEW_INTEGRATIONS: {
            text: 'reputation.integrations',
            description: 'Grant access to review integrations',
            active: true,
        },
        REVIEW_REPLY: {
            text: 'reputation.reply',
            description: 'Grant access to review reply',
            active: true,
        },
    },
};
export default AccessScopes;
