/**
 * Camel to Human
 * Convert camelcase strings to space-separated,
 * capitalized words
 */
const capitalizeFirstLetter = function (str) {
    return str.substr(0, 1).toUpperCase() + str.substr(1);
};
const camelToHuman = function (str) {
    let words = [];
    let regex = /[A-Z]/g;
    let caps = str.match(regex);
    let remainingChars = str;
    if (!caps) {
        return capitalizeFirstLetter(str);
    }
    while (caps.length) {
        let word = remainingChars.substr(0, remainingChars.indexOf(caps.shift()));
        words.push(capitalizeFirstLetter(word));
        remainingChars = remainingChars.substr(word.length);
    }
    words.push(capitalizeFirstLetter(remainingChars));
    return words.join(' ');
};
/**
 *
 * @param {String} path - String path relative to the object
 * @param {Object} obj - The object from which you want to extract path
 * @param {String|'.'} separator - character(s) by which properties are seperated
 * @returns any
 */
const resolvePath = (path, obj = self, separator = '.') => {
    var properties = Array.isArray(path) ? path : path.split(separator);
    return properties.reduce((prev, curr) => prev && prev[curr], obj);
};

const appendParam = (str, p, val) => {
    let temp = str.slice(1);
    let params = temp.split('&');
    let found = false;
    params.forEach(el => {
        if (el.includes(p)) {
            found = true;
            el = p + '=' + val;
        }
    });
    if (!found) {
        params.push(p + '=' + val);
    }
    return `?${params.join('&')}`;
};

const camelToSnakeCase = str => str.replace(/[A-Z]/g, letter => `_${letter.toLowerCase()}`);

const abbreviateNumber = number => {
    const SI_SYMBOL = ['', 'K', 'M', 'G', 'T', 'P', 'E'];

    // what tier? (determines SI symbol)
    var tier = (Math.log10(Math.abs(number)) / 3) | 0;

    // if zero, we don't need a suffix
    if (tier === 0) return number;

    // get suffix and determine scale
    var suffix = SI_SYMBOL[tier];
    var scale = Math.pow(10, tier * 3);

    // scale the number
    var scaled = number / scale;

    // format number and add suffix
    return scaled.toFixed(1) + suffix;
};

/**
 *
 * @param {string} currVal- String already present
 * @param {string} newValue- Incoming string value
 * @param {integer} maxLength- Max length which is allowed.
 * @returns {string} if space available =>Concated string else return new value.
 */
const concatSubstring = (currVal, newValue, maxLength) => {
    // check if we can add more chars to the currVal and
    // concate only when newValue is greater than max length
    if (currVal.length < maxLength && newValue.length > maxLength) {
        // get the character length which can be added in currValue
        let charsMoreAvailable = maxLength - currVal.length;
        // add the charaters from new string for charsMoreAvailable length in currValue
        return currVal + newValue.substr(0, charsMoreAvailable);
    }
    // return false means cannot concate substring.
    else return newValue;
};

export {
    concatSubstring,
    capitalizeFirstLetter,
    camelToHuman,
    camelToSnakeCase,
    resolvePath,
    appendParam,
    abbreviateNumber,
};
