import React from 'react';

import { SVGIcon } from '../../UIKit/index';

export default function DCFacebookGroup(props) {
    return (
        <SVGIcon {...props} viewBox="0 0 42 42" fill="none">
            <path
                d="M38.1891 30.6145H1.81086V6.41445C1.81086 5.08094 2.89188 4 4.22531 4H35.7746C37.1081 4 38.1891 5.08102 38.1891 6.41445V30.6145H38.1891Z"
                fill="#3D6D93"
            />
            <path
                d="M35.7746 4H33.3602C34.6937 4 35.7746 5.08102 35.7746 6.41445V30.6145H38.1891V6.41445C38.1891 5.08102 37.1081 4 35.7746 4Z"
                fill="#335E80"
            />
            <path
                d="M4.22539 31.2369V6.7365C4.22539 6.55869 4.36953 6.41455 4.54734 6.41455H35.4528C35.6306 6.41455 35.7748 6.55869 35.7748 6.7365V31.2369C35.7748 31.4147 35.6306 31.5588 35.4528 31.5588H4.54726C4.36945 31.5588 4.22539 31.4146 4.22539 31.2369Z"
                fill="#EAF6FF"
            />
            <path
                d="M35.4527 6.41455H33.0383C33.2161 6.41455 33.3602 6.55869 33.3602 6.7365V31.2368C33.3602 31.4146 33.2161 31.5588 33.0383 31.5588H35.4527C35.6305 31.5588 35.7747 31.4146 35.7747 31.2368V6.7365C35.7746 6.55869 35.6305 6.41455 35.4527 6.41455Z"
                fill="#D8ECFE"
            />
            <path
                d="M0.402422 30.6145H39.5976C39.8198 30.6145 40 30.7947 40 31.0169V33.7975C40 35.3733 38.7226 36.6507 37.1468 36.6507H2.8532C1.27742 36.6508 0 35.3733 0 33.7975V31.017C0 30.7947 0.180156 30.6145 0.402422 30.6145Z"
                fill="#3D6D93"
            />
            <path
                d="M39.5976 30.6145H37.1831C37.4054 30.6145 37.5855 30.7947 37.5855 31.0169V33.7975C37.5855 35.3733 36.3081 36.6507 34.7323 36.6507H37.1468C38.7226 36.6507 40 35.3733 40 33.7975V31.0169C40 30.7947 39.8198 30.6145 39.5976 30.6145Z"
                fill="#335E80"
            />
            <path
                d="M22.6523 33.029H17.3477C16.9031 33.029 16.5428 32.6687 16.5428 32.2242V30.6145H23.4571V32.2242C23.4572 32.6687 23.0968 33.029 22.6523 33.029Z"
                fill="#335E80"
            />
            <path
                d="M19.5984 27.8478C24.8995 27.8478 29.1969 23.5504 29.1969 18.2493C29.1969 12.9482 24.8995 8.65088 19.5984 8.65088C14.2974 8.65088 10 12.9482 10 18.2493C10 23.5504 14.2974 27.8478 19.5984 27.8478Z"
                fill="#8FD8FA"
            />
            <path
                d="M19.5984 8.65088C19.1895 8.65088 18.7866 8.67666 18.3912 8.72627C23.1223 9.32002 26.7824 13.3572 26.7824 18.2494C26.7824 23.1416 23.1224 27.1788 18.3912 27.7725C18.7866 27.8221 19.1895 27.8479 19.5984 27.8479C24.8995 27.8479 29.1969 23.5505 29.1969 18.2495C29.1969 12.9485 24.8995 8.65088 19.5984 8.65088V8.65088Z"
                fill="#68CBF2"
            />
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M23.8906 13.2454C23.8906 13.3942 23.8312 13.537 23.7255 13.6423C23.6198 13.7476 23.4765 13.8067 23.327 13.8067H21.6362C21.4867 13.8067 21.3433 13.8659 21.2376 13.9711C21.1319 14.0764 21.0725 14.2192 21.0725 14.3681V16.6134H23.203C23.2864 16.6134 23.3687 16.6319 23.4441 16.6674C23.5195 16.7029 23.586 16.7546 23.6389 16.8189C23.6917 16.8831 23.7296 16.9582 23.7498 17.0388C23.77 17.1193 23.772 17.2034 23.7557 17.2848C23.6511 17.806 23.5089 18.5139 23.4175 18.9688C23.392 19.0961 23.323 19.2106 23.2222 19.2929C23.1214 19.3752 22.9951 19.4201 22.8648 19.4201H21.0725V26.1562H18.2545V19.4201H16.5636C16.4141 19.4201 16.2708 19.361 16.1651 19.2557C16.0594 19.1505 16 19.0077 16 18.8588V17.1748C16 17.0259 16.0594 16.8831 16.1651 16.7778C16.2708 16.6726 16.4141 16.6134 16.5636 16.6134H18.2545V14.3681C18.2545 13.4748 18.6107 12.6181 19.2449 11.9865C19.8791 11.3548 20.7393 11 21.6362 11H23.327C23.4765 11 23.6198 11.0591 23.7255 11.1644C23.8312 11.2697 23.8906 11.4125 23.8906 11.5613V13.2454Z"
                fill="white"
            />
        </SVGIcon>
    );
}
