import React from 'react';

import { SVGIcon } from '../../UIKit/index';

export default function DCLeadFlowMenu(props) {
    return (
        <SVGIcon {...props} viewBox="0 0 512 512">
            <g transform="translate(-1368.146 -379.616)">
                <path fill="#E6EFFF" d="M1371.62,494.24v314.58h406.07V494.24h-406.13" />
                <path
                    fill="#2F8DFF"
                    d="M1405.83,381.01c-19.03,4.34-33.16,20.35-35.1,39.78c-1.81,24.4-1.81,48.89,0,73.29h406.3   v-73.29c0,0-8.77-36.64-38.23-39.78C1709.34,377.87,1405.83,381.01,1405.83,381.01z"
                />
                <g transform="translate(1370.036 386.116)">
                    <path
                        fill="#2F8DFF"
                        d="M54.05,180.68c-13.56,0-24.59-11.03-24.59-24.59s11.03-24.59,24.59-24.59s24.59,11.03,24.59,24.59    S67.61,180.68,54.05,180.68z"
                    />
                    <path
                        fill="#2F8DFF"
                        d="M54.05,145.58c5.8,0,10.51,4.71,10.51,10.51s-4.71,10.51-10.51,10.51s-10.51-4.71-10.51-10.51    S48.25,145.58,54.05,145.58z"
                    />
                </g>
                <g transform="translate(1370.036 389.116)">
                    <path
                        fill="#2F8DFF"
                        d="M54.05,247.88c-13.56,0-24.59-11.03-24.59-24.59s11.03-24.59,24.59-24.59s24.59,11.03,24.59,24.59    S67.61,247.88,54.05,247.88z"
                    />
                    <path
                        fill="#2F8DFF"
                        d="M54.05,212.78c5.8,0,10.51,4.71,10.51,10.51s-4.71,10.51-10.51,10.51s-10.51-4.71-10.51-10.51    S48.25,212.78,54.05,212.78z"
                    />
                </g>
                <g transform="translate(1370.036 392.116)">
                    <path
                        fill="#2F8DFF"
                        d="M54.05,315.08c-13.56,0-24.59-11.03-24.59-24.59s11.03-24.59,24.59-24.59s24.59,11.03,24.59,24.59    S67.61,315.08,54.05,315.08z"
                    />
                    <path
                        fill="#2F8DFF"
                        d="M54.05,279.98c5.8,0,10.51,4.71,10.51,10.51S59.85,301,54.05,301s-10.51-4.71-10.51-10.51    S48.25,279.98,54.05,279.98z"
                    />
                </g>
                <g transform="translate(1370.036 395.116)">
                    <path
                        fill="#2F8DFF"
                        d="M54.05,382.27c-13.56,0-24.59-11.03-24.59-24.59s11.03-24.59,24.59-24.59s24.59,11.03,24.59,24.59    S67.61,382.27,54.05,382.27z"
                    />
                    <path
                        fill="#2F8DFF"
                        d="M54.05,347.17c5.8,0,10.51,4.71,10.51,10.51s-4.71,10.51-10.51,10.51s-10.51-4.71-10.51-10.51    S48.25,347.17,54.05,347.17z"
                    />
                </g>
                <circle fill="#ffffff" cx="1424.08" cy="437.1" r="10.51" />
                <circle fill="#ffffff" cx="1470.88" cy="437.1" r="10.51" />
                <circle fill="#ffffff" cx="1517.68" cy="437.1" r="10.51" />
                <path
                    fill="#2F8DFF"
                    d="M1621.4,600.05c-6.65,1.74-11.64,7.25-12.73,14.04v66.01l87.21,98.04v62.71   c0,0,1.9,12.73,8.49,15.09c0.94,0,65.99,34.42,65.99,34.42s9.9,2.83,18.39-11.33c-0.47-0.47,0-100.87,0-100.87l89.57-98.04v-70.2   c-1.16-4.91-4.99-8.74-9.9-9.9C1860.5,598.64,1621.4,600.05,1621.4,600.05z"
                />
                <path
                    fill="#2F8DFF"
                    d="M1487.14,531.7h231.23c5.8,0,10.51,4.7,10.51,10.51l0,0c0,5.8-4.7,10.51-10.51,10.51   h-231.23c-5.8,0-10.51-4.7-10.51-10.51l0,0C1476.63,536.4,1481.34,531.7,1487.14,531.7z"
                />
                <path
                    fill="#2F8DFF"
                    d="M1487.14,601.9h44.04c5.8,0,10.51,4.7,10.51,10.51l0,0c0,5.8-4.7,10.51-10.51,10.51h-44.04   c-5.8,0-10.51-4.7-10.51-10.51l0,0C1476.63,606.6,1481.34,601.9,1487.14,601.9z"
                />
                <path
                    fill="#2F8DFF"
                    d="M1487.14,672.09h44.04c5.8,0,10.51,4.7,10.51,10.51l0,0c0,5.8-4.7,10.51-10.51,10.51   h-44.04c-5.8,0-10.51-4.7-10.51-10.51l0,0C1476.63,676.8,1481.34,672.09,1487.14,672.09z"
                />
                <path
                    fill="#2F8DFF"
                    d="M1487.14,742.29h44.04c5.8,0,10.51,4.7,10.51,10.51l0,0c0,5.8-4.7,10.51-10.51,10.51   h-44.04c-5.8,0-10.51-4.7-10.51-10.51l0,0C1476.63,746.99,1481.34,742.29,1487.14,742.29z"
                />
            </g>
        </SVGIcon>
    );
}
