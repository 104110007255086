import React from 'react';

import { SVGIcon } from '../../UIKit/index';

export default function DCGeoLocations(props) {
    return (
        <SVGIcon {...props} viewBox="0 0 512 439">
            <g>
                <g>
                    <g transform="translate(0)">
                        <g>
                            <path d="M347.05,1.41c-87.33,0.1-158.11,71.01-158.2,158.51c0,108.49,141.59,267.73,147.61,274.45       c5.26,5.86,14.26,6.34,20.11,1.08c0.38-0.34,0.74-0.7,1.08-1.08c6.02-6.73,147.61-165.97,147.61-274.45       C505.14,72.43,434.38,1.52,347.05,1.41z M347.05,402.97c-47.64-56.72-129.72-168.6-129.72-243.05       C217.27,88.14,275.3,29.9,346.94,29.84s129.77,58.09,129.83,129.87c0,0.07,0,0.14,0,0.21       C476.78,234.4,394.72,346.25,347.05,402.97z" />
                        </g>
                    </g>
                    <g transform="translate(2.716 2.716)">
                        <g transform="translate(0)">
                            <path d="M344.34,77.46c-43.96,0-79.59,35.71-79.59,79.75s35.64,79.75,79.59,79.75       s79.59-35.71,79.59-79.75l0,0C423.88,113.18,388.27,77.51,344.34,77.46z M344.34,208.42c-28.23,0-51.11-22.93-51.11-51.21       s22.88-51.21,51.11-51.21c28.23,0,51.11,22.93,51.11,51.21C395.42,185.48,372.55,208.39,344.34,208.42z" />
                        </g>
                    </g>
                </g>
                <g transform="translate(0 6.284)">
                    <g transform="translate(0)">
                        <g>
                            <path d="M96.49,166.36c-53.06,0.06-96.06,43.15-96.12,96.31c0,65.92,86.02,162.66,89.72,166.75       c3.22,3.56,8.71,3.84,12.26,0.62c0.22-0.2,0.42-0.4,0.62-0.62c3.68-4.09,89.72-100.83,89.72-166.75       C192.63,209.47,149.58,166.38,96.49,166.36z M96.49,410.34c-28.94-34.45-78.81-102.43-78.81-147.67       c-0.02-43.61,35.24-78.99,78.77-79.01s78.83,35.31,78.86,78.92c0,0.03,0,0.06,0,0.09C175.3,307.91,125.43,375.88,96.49,410.34z       " />
                        </g>
                    </g>
                    <g transform="translate(1.65 1.65)">
                        <g transform="translate(0)">
                            <path d="M94.84,212.56c-26.71,0-48.36,21.7-48.36,48.46c0,26.76,21.65,48.46,48.36,48.46       c26.71,0,48.36-21.7,48.36-48.46c0-0.05,0-0.1,0-0.14C143.09,234.18,121.48,212.59,94.84,212.56z M94.84,292.14       c-17.15,0-31.06-13.93-31.06-31.12s13.9-31.12,31.06-31.12c17.15,0,31.06,13.93,31.06,31.12c0,0,0,0,0,0       C125.88,278.2,111.99,292.12,94.84,292.14z" />
                        </g>
                    </g>
                </g>
            </g>
        </SVGIcon>
    );
}
