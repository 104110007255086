import React from 'react';

import { SVGIcon } from '../../UIKit/index';

export default function DCTemplateLibrary(props) {
    return (
        <SVGIcon {...props} viewBox="0 0 389 512">
            <g transform="translate(-61.604 0.52)">
                <path
                    d="M445.5,125L326.3,5.8c-2.8-2.8-6.6-4.4-10.5-4.4H107.1c-24.7,0-44.7,20-44.7,44.7v419.3
		c0,24.7,20,44.7,44.7,44.7h298.1c24.7,0,44.7-20,44.7-44.7V135.6C449.9,131.7,448.3,127.9,445.5,125z M330.6,52.4l68.4,68.4h-53.5
		c-8.2,0-14.9-6.7-14.9-14.9V52.4z M405.1,480.4H107.1c-8.2,0-14.9-6.7-14.9-14.9V46.2c0-8.2,6.7-14.9,14.9-14.9h193.8v74.5
		c0.1,24.7,20,44.7,44.7,44.7H420v315.1C420,473.7,413.4,480.4,405.1,480.4L405.1,480.4z"
                />
                <g>
                    <rect x="221.3" y="219.5" width="145.4" height="36.3" />
                    <rect x="221.3" y="292.2" width="145.4" height="36.3" />
                    <rect x="221.3" y="364.9" width="145.4" height="36.3" />
                    <rect x="148.6" y="219.5" width="36.3" height="36.3" />
                    <rect x="148.6" y="292.2" width="36.3" height="36.3" />
                    <rect x="148.6" y="364.9" width="36.3" height="36.3" />
                </g>
            </g>
        </SVGIcon>
    );
}
