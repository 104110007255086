import React from 'react';

import { SVGIcon } from '../../UIKit/index';

export default function DCForms1(props) {
    return (
        <SVGIcon {...props} viewBox="0 0 503.24 399.7">
            <g id="Forms" transform="translate(-4.15 -57.54)">
                <ellipse
                    id="_Path_"
                    data-name="&lt;Path&gt;"
                    cx="251.62"
                    cy="67.84"
                    rx="251.62"
                    ry="67.84"
                    transform="translate(4.15 321.56)"
                    fill="#f4f5f6"
                />
                <rect
                    id="Rectangle_1"
                    data-name="Rectangle 1"
                    width="120.39"
                    height="55.52"
                    transform="translate(311.54 328.62)"
                    fill="#f4f5f6"
                />
                <path
                    id="Path_1"
                    data-name="Path 1"
                    d="M429.06,381.52H314.29v-50H429.06Zm-114-.75H428.31V332.29H315Z"
                    fill="#e4e3e3"
                />
                <rect
                    id="Rectangle_2"
                    data-name="Rectangle 2"
                    width="29.41"
                    height="55.52"
                    transform="translate(431.93 328.62)"
                    fill="#e4e3e3"
                />
                <path
                    id="Path_2"
                    data-name="Path 2"
                    d="M314.67,347h114a.16.16,0,0,0,0-.31h-114a.16.16,0,0,0,0,.31Z"
                    fill="#e4e3e3"
                />
                <path
                    id="Path_3"
                    data-name="Path 3"
                    d="M372.05,346.86a126.609,126.609,0,0,0,0-15.09.16.16,0,0,0-.31,0,126.6,126.6,0,0,0,0,15.09.12.12,0,0,0,.23,0Z"
                    fill="#e4e3e3"
                />
                <path
                    id="Path_4"
                    data-name="Path 4"
                    d="M402.74,339.31a1.56,1.56,0,1,1-1.55-1.55,1.56,1.56,0,0,1,1.55,1.55Z"
                    fill="#e4e3e3"
                />
                <path
                    id="Path_5"
                    data-name="Path 5"
                    d="M342.9,339.31a1.56,1.56,0,1,1-1.55-1.55,1.56,1.56,0,0,1,1.55,1.55Z"
                    fill="#e4e3e3"
                />
                <path
                    id="Path_6"
                    data-name="Path 6"
                    d="M373.23,356.53a1.56,1.56,0,1,1-1.55-1.55A1.56,1.56,0,0,1,373.23,356.53Z"
                    fill="#e4e3e3"
                />
                <path
                    id="Path_7"
                    data-name="Path 7"
                    d="M325.93,384.14,319.73,419h3.49l11.43-34.86Z"
                    fill="#e4e3e3"
                />
                <path
                    id="Path_8"
                    data-name="Path 8"
                    d="M423.41,384.14l5.18,34.92h-3.48l-10.42-34.92Z"
                    fill="#e4e3e3"
                />
                <path
                    id="Path_9"
                    data-name="Path 9"
                    d="M446.68,384.14l5.88,34.92h-3.49l-11.1-34.92Z"
                    fill="#e4e3e3"
                />
                <path
                    id="Path_10"
                    data-name="Path 10"
                    d="M373.13,372.24a1.56,1.56,0,1,1-1.56-1.55,1.56,1.56,0,0,1,1.56,1.55Z"
                    fill="#e4e3e3"
                />
                <path
                    id="Path_11"
                    data-name="Path 11"
                    d="M314.67,364.2h114a.15.15,0,1,0,0-.3h-114a.15.15,0,0,0,0,.3Z"
                    fill="#e4e3e3"
                />
                <rect
                    id="Rectangle_3"
                    data-name="Rectangle 3"
                    width="7.41"
                    height="36.4"
                    transform="matrix(0.005, 1, -1, 0.005, 395.541, 302.355)"
                    fill="#e0e0e0"
                />
                <rect
                    id="Rectangle_4"
                    data-name="Rectangle 4"
                    width="11.51"
                    height="40.57"
                    transform="matrix(0.005, 1, -1, 0.005, 399.882, 316.932)"
                    fill="#f5f5f5"
                />
                <path
                    id="Path_12"
                    data-name="Path 12"
                    d="M392.15,328.38a82.9,82.9,0,0,1,0-11.51A82.9,82.9,0,0,1,392.15,328.38Z"
                    fill="#e0e0e0"
                />
                <path
                    id="Path_13"
                    data-name="Path 13"
                    d="M393.85,328.38a87.255,87.255,0,0,1,0-11.51,82.9,82.9,0,0,1,0,11.51Z"
                    fill="#e0e0e0"
                />
                <path
                    id="Path_14"
                    data-name="Path 14"
                    d="M365.5,328.38a82.893,82.893,0,0,1,0-11.51,82.9,82.9,0,0,1,0,11.51Z"
                    fill="#e0e0e0"
                />
                <path
                    id="Path_15"
                    data-name="Path 15"
                    d="M367.2,328.38a87.25,87.25,0,0,1,0-11.51,82.9,82.9,0,0,1,0,11.51Z"
                    fill="#e0e0e0"
                />
                <path
                    id="Path_16"
                    data-name="Path 16"
                    d="M370.92,322.81c0-.11,4.09-.2,9.14-.2s9.14.09,9.14.2-4.09.2-9.14.2S370.92,322.92,370.92,322.81Z"
                    fill="#e0e0e0"
                />
                <rect
                    id="Rectangle_5"
                    data-name="Rectangle 5"
                    width="7.41"
                    height="50.06"
                    transform="matrix(0.005, 1, -1, 0.005, 415.138, 309.457)"
                    fill="#ebebeb"
                />
                <path
                    id="Path_17"
                    data-name="Path 17"
                    d="M408,316a2.4,2.4,0,1,1,2.37-2.39A2.4,2.4,0,0,1,408,316Z"
                    fill="#e0e0e0"
                />
                <rect
                    id="Rectangle_6"
                    data-name="Rectangle 6"
                    width="1.48"
                    height="33.51"
                    transform="translate(401.53 312.83) rotate(90)"
                    fill="#e0e0e0"
                />
                <path
                    id="Path_18"
                    data-name="Path 18"
                    d="M23.6,173.79s-4.26,3.24-.17,7.53a.38.38,0,0,0,.27.12l37.22-.22a6.49,6.49,0,0,0,.54-7.68Z"
                    fill="#e4e3e3"
                />
                <path
                    id="Path_19"
                    data-name="Path 19"
                    d="M52.69,177.17c0,.05-4.63.08-10.35.08s-10.35,0-10.35-.08,4.63-.07,10.35-.07S52.69,177.13,52.69,177.17Z"
                    fill="#f4f5f6"
                />
                <path
                    id="Path_20"
                    data-name="Path 20"
                    d="M46.9,178.52s-2.18.08-4.86.08-4.87,0-4.87-.08,2.18-.07,4.87-.07S46.9,178.48,46.9,178.52Z"
                    fill="#f4f5f6"
                />
                <path
                    id="Path_21"
                    data-name="Path 21"
                    d="M56.5,181.1s.08-.43.2-1.12a16.479,16.479,0,0,0,.21-2.71,15.74,15.74,0,0,0-.27-2.72c-.12-.68-.24-1.1-.21-1.11a1.651,1.651,0,0,1,.1.29,7.491,7.491,0,0,1,.22.8,13.75,13.75,0,0,1,.31,2.74A13.43,13.43,0,0,1,56.8,180a7.941,7.941,0,0,1-.2.81,1.65,1.65,0,0,1-.1.29Z"
                    fill="#f4f5f6"
                />
                <path
                    id="Path_22"
                    data-name="Path 22"
                    d="M59.28,173.5a1.27,1.27,0,0,1,.16.27c.05.09.11.2.17.33a4.308,4.308,0,0,1,.19.46,8.14,8.14,0,0,1,.51,2.8,8.6,8.6,0,0,1-.13,1.57,7.531,7.531,0,0,1-.34,1.24,4,4,0,0,1-.18.46,2.56,2.56,0,0,1-.16.33,2.43,2.43,0,0,1-.16.28s.18-.42.4-1.11a8.881,8.881,0,0,0,.3-1.23,10.47,10.47,0,0,0,.12-1.54,9.18,9.18,0,0,0-.14-1.54,8.69,8.69,0,0,0-.32-1.23C59.46,173.91,59.25,173.51,59.28,173.5Z"
                    fill="#f4f5f6"
                />
                <path
                    id="Path_23"
                    data-name="Path 23"
                    d="M29.48,181.15a1.349,1.349,0,0,1-.18-.25,2.7,2.7,0,0,1-.17-.31,4.43,4.43,0,0,1-.2-.43,6.521,6.521,0,0,1-.36-1.17,7.86,7.86,0,0,1-.15-1.5,7.58,7.58,0,0,1,.19-1.5,7.12,7.12,0,0,1,.39-1.16c.07-.16.15-.3.21-.42a2.48,2.48,0,0,1,.19-.31c.1-.16.17-.25.18-.24s-.22.37-.48,1a8.78,8.78,0,0,0-.36,1.15,8.241,8.241,0,0,0-.17,1.47A8.46,8.46,0,0,0,28.7,179a7.5,7.5,0,0,0,.33,1.16C29.27,180.77,29.5,181.13,29.48,181.15Z"
                    fill="#f4f5f6"
                />
                <path
                    id="Path_24"
                    data-name="Path 24"
                    d="M25.46,182.06a1.54,1.54,0,0,1-.19-.29,3.08,3.08,0,0,1-.2-.34,4.68,4.68,0,0,1-.22-.49,7.45,7.45,0,0,1-.43-1.31,9.52,9.52,0,0,1-.21-1.68,9,9,0,0,1,.14-1.69,8.39,8.39,0,0,1,.36-1.33c.07-.18.15-.34.21-.49a2.25,2.25,0,0,1,.18-.35,1.25,1.25,0,0,1,.18-.3,10.2,10.2,0,0,0-.47,1.18,9.381,9.381,0,0,0-.33,1.32,9.6,9.6,0,0,0-.12,1.66,9.2,9.2,0,0,0,.2,1.65,8.24,8.24,0,0,0,.39,1.31C25.23,181.63,25.48,182,25.46,182.06Z"
                    fill="#f4f5f6"
                />
                <path
                    id="Path_25"
                    data-name="Path 25"
                    d="M59.55,192.55v7.13H45.77v-7.13Z"
                    fill="#ebebeb"
                />
                <path
                    id="Path_26"
                    data-name="Path 26"
                    d="M15,196.18a1.15,1.15,0,0,0,0,.19,4.07,4.07,0,0,0,4.1,3.82H60.2v-.51H50.45a3.56,3.56,0,0,1-3.56-3.56h0a3.56,3.56,0,0,1,3.56-3.57H60.2v-.75l-41.07.14A4.1,4.1,0,0,0,15,196.18Z"
                    fill="#e4e3e3"
                />
                <path
                    id="Path_27"
                    data-name="Path 27"
                    d="M59.55,196.21s-2.83.08-6.33.08-6.33,0-6.33-.08,2.84-.07,6.33-.07S59.55,196.17,59.55,196.21Z"
                    fill="#f4f5f6"
                />
                <path
                    id="Path_28"
                    data-name="Path 28"
                    d="M59.35,197.82s-2.68.07-6,.07-6,0-6-.07,2.68-.08,6-.08S59.35,197.78,59.35,197.82Z"
                    fill="#f4f5f6"
                />
                <path
                    id="Path_29"
                    data-name="Path 29"
                    d="M59.55,199.28c0,.05-2.41.08-5.38.08s-5.39,0-5.39-.08,2.41-.07,5.39-.07S59.55,199.24,59.55,199.28Z"
                    fill="#f4f5f6"
                />
                <path
                    id="Path_30"
                    data-name="Path 30"
                    d="M59.55,194.77s-2.77.07-6.2.07-6.2,0-6.2-.07,2.78-.08,6.2-.08S59.55,194.73,59.55,194.77Z"
                    fill="#f4f5f6"
                />
                <path
                    id="Path_31"
                    data-name="Path 31"
                    d="M59.35,193.33s-2.47.07-5.51.07-5.52,0-5.52-.07,2.47-.08,5.52-.08S59.35,193.29,59.35,193.33Z"
                    fill="#f4f5f6"
                />
                <path
                    id="Path_32"
                    data-name="Path 32"
                    d="M20,192.06a8.252,8.252,0,0,0-.87,1,5.27,5.27,0,0,0,.07,6.11,8.248,8.248,0,0,0,.9,1,1.574,1.574,0,0,1-.3-.22,4.171,4.171,0,0,1-.68-.73,5.19,5.19,0,0,1-.08-6.23,4.9,4.9,0,0,1,.67-.75,1.241,1.241,0,0,1,.29-.18Z"
                    fill="#f4f5f6"
                />
                <path
                    id="Path_33"
                    data-name="Path 33"
                    d="M18.62,191.9a12.13,12.13,0,0,0-.87,1,5.47,5.47,0,0,0-.94,3.06,5.54,5.54,0,0,0,1,3.05,8.25,8.25,0,0,0,.9,1,1.574,1.574,0,0,1-.3-.22,4.59,4.59,0,0,1-.68-.73,5.19,5.19,0,0,1-.08-6.23,4.9,4.9,0,0,1,.67-.75,2.579,2.579,0,0,1,.3-.18Z"
                    fill="#f4f5f6"
                />
                <path
                    id="Path_34"
                    data-name="Path 34"
                    d="M46.52,192.06a7.729,7.729,0,0,0-.88,1,5.3,5.3,0,0,0,.08,6.11,7.8,7.8,0,0,0,.89,1,1.24,1.24,0,0,1-.29-.22,4.161,4.161,0,0,1-.69-.73,5.21,5.21,0,0,1-.07-6.23,5.55,5.55,0,0,1,.66-.75A1.353,1.353,0,0,1,46.52,192.06Z"
                    fill="#f4f5f6"
                />
                <path
                    id="Path_35"
                    data-name="Path 35"
                    d="M45.13,191.9a10.23,10.23,0,0,0-.87,1,5.27,5.27,0,0,0,.07,6.11,8.841,8.841,0,0,0,.89,1,1.24,1.24,0,0,1-.29-.22,4.56,4.56,0,0,1-.69-.73,5.21,5.21,0,0,1-.07-6.23,4.9,4.9,0,0,1,.67-.75,1.4,1.4,0,0,1,.29-.18Z"
                    fill="#f4f5f6"
                />
                <path
                    id="Path_36"
                    data-name="Path 36"
                    d="M24.68,197.18v-2.89a1.89,1.89,0,0,0-.1-.75.7.7,0,0,0-.55-.46,1.1,1.1,0,0,0-1.18.56,2.73,2.73,0,0,0-.28,1.42,19.339,19.339,0,0,0,.11,2.28,2.63,2.63,0,0,0,.27,1,.87.87,0,0,0,1.65-.14A2.76,2.76,0,0,0,24.68,197.18Z"
                    fill="#f4f5f6"
                />
                <path
                    id="Path_37"
                    data-name="Path 37"
                    d="M40.63,196.35s-3,.08-6.63.08-6.63,0-6.63-.08,3-.07,6.63-.07S40.63,196.31,40.63,196.35Z"
                    fill="#f4f5f6"
                />
                <path
                    id="Path_38"
                    data-name="Path 38"
                    d="M21.93,191.77l-.07-6.75h3.67l.13,6.75Z"
                    fill="#f4f5f6"
                />
                <path
                    id="Path_39"
                    data-name="Path 39"
                    d="M25.32,186.22a9.7,9.7,0,0,1-1.71.08,9.611,9.611,0,0,1-1.71-.08,10.273,10.273,0,0,1,1.71-.07,10.369,10.369,0,0,1,1.71.07Z"
                    fill="#e4e3e3"
                />
                <path
                    id="Path_40"
                    data-name="Path 40"
                    d="M25.32,187.26a9.7,9.7,0,0,1-1.71.08,9.611,9.611,0,0,1-1.71-.08,10.273,10.273,0,0,1,1.71-.07,10.371,10.371,0,0,1,1.71.07Z"
                    fill="#e4e3e3"
                />
                <path
                    id="Path_41"
                    data-name="Path 41"
                    d="M25.32,188.3a8.68,8.68,0,0,1-1.71.08,8.6,8.6,0,0,1-1.71-.08,10.271,10.271,0,0,1,1.71-.07A10.368,10.368,0,0,1,25.32,188.3Z"
                    fill="#e4e3e3"
                />
                <path
                    id="Path_42"
                    data-name="Path 42"
                    d="M25.32,189.35a10.371,10.371,0,0,1-1.71.07,10.271,10.271,0,0,1-1.71-.07,9.609,9.609,0,0,1,1.71-.08,9.7,9.7,0,0,1,1.71.08Z"
                    fill="#e4e3e3"
                />
                <path
                    id="Path_43"
                    data-name="Path 43"
                    d="M25.32,190.39a10.369,10.369,0,0,1-1.71.07,10.27,10.27,0,0,1-1.71-.07,9.609,9.609,0,0,1,1.71-.08,9.7,9.7,0,0,1,1.71.08Z"
                    fill="#e4e3e3"
                />
                <path
                    id="Path_44"
                    data-name="Path 44"
                    d="M21.66,184.84H62.4a19.25,19.25,0,0,1,0,7.3H21.84v-.5h2.47a1.17,1.17,0,0,0,1.17-1.17v-4a1.17,1.17,0,0,0-1.17-1.17H21.66Z"
                    fill="#f4f5f6"
                />
                <path
                    id="Path_45"
                    data-name="Path 45"
                    d="M31.29,188.49h.79a.16.16,0,0,1,.07.13h0v.18a2.06,2.06,0,0,1-.43.77.81.81,0,0,1-.58.3H31a.66.66,0,0,1-.46-.33,1.49,1.49,0,0,1-.21-.82,2,2,0,0,1,.09-.58,1.9,1.9,0,0,1,.4-.79.81.81,0,0,1,.61-.33.58.58,0,0,1,.26.06.87.87,0,0,1,.4.5.13.13,0,0,1,0,.06.12.12,0,0,1,0,.08.13.13,0,0,1-.12.07h-.05a.11.11,0,0,1-.09-.09h0a.8.8,0,0,0-.08-.16.4.4,0,0,0-.33-.2.35.35,0,0,0-.22.09,1.11,1.11,0,0,0-.37.53,2,2,0,0,0-.16.77,1.34,1.34,0,0,0,.11.6.39.39,0,0,0,.36.26.48.48,0,0,0,.33-.19,1.89,1.89,0,0,0,.34-.63h-.48a.161.161,0,0,1-.17-.16h0a.16.16,0,0,1,.13-.12Z"
                    fill="#e4e3e3"
                />
                <path
                    id="Path_46"
                    data-name="Path 46"
                    d="M33.61,187.17a.43.43,0,0,1,0,.05q0,.15.48,2.52a.19.19,0,0,1,0,.07.14.14,0,0,1-.12.08h0a.14.14,0,0,1-.11-.1l-.16-.83h-.84l-.4.84h0a.19.19,0,0,1-.13.07.13.13,0,0,1-.08,0,.14.14,0,0,1-.06-.12h0l.13-.28c.59-1.28.94-2,1-2.26a.17.17,0,0,1,.14-.11.2.2,0,0,1,.15.07Zm-.19.56-.45,1h.64Z"
                    fill="#e4e3e3"
                />
                <path
                    id="Path_47"
                    data-name="Path 47"
                    d="M35.88,189.93H34.65a.11.11,0,0,1-.08,0,.21.21,0,0,1-.07-.15h0c0-.05.11-.89.34-2.52a.13.13,0,0,1,.13-.09h.07a.13.13,0,0,1,.07.13,2.893,2.893,0,0,1-.08.69c-.14,1.07-.21,1.64-.22,1.69h1.08a.15.15,0,0,1,.1.14h0C36,189.88,36,189.91,35.88,189.93Z"
                    fill="#e4e3e3"
                />
                <path
                    id="Path_48"
                    data-name="Path 48"
                    d="M37.56,187.17a.43.43,0,0,1,0,.05q0,.15.48,2.52a.19.19,0,0,1,0,.07.14.14,0,0,1-.12.08h0a.14.14,0,0,1-.12-.1l-.16-.83H36.8l-.4.84h0a.19.19,0,0,1-.13.07.13.13,0,0,1-.08,0,.14.14,0,0,1-.06-.12h0a1.075,1.075,0,0,1,.12-.28c.59-1.28.94-2,1.05-2.26a.17.17,0,0,1,.14-.11A.2.2,0,0,1,37.56,187.17Zm-.19.56-.45,1h.64Z"
                    fill="#e4e3e3"
                />
                <path
                    id="Path_49"
                    data-name="Path 49"
                    d="M39.51,188.62c.1.26.17.47.22.61a5.45,5.45,0,0,1,.19.56.1.1,0,0,1,0,.07.15.15,0,0,1-.12.07.13.13,0,0,1-.12-.1.16.16,0,0,0,0-.06l-.33-.87-.69.95a.17.17,0,0,1-.13.08h-.08a.16.16,0,0,1-.07-.13h0a.26.26,0,0,1,.07-.12c.23-.3.49-.65.77-1.07a.9.9,0,0,0-.08-.23c-.26-.7-.39-1.06-.39-1.09a.17.17,0,0,1,0-.07.14.14,0,0,1,.12-.08.16.16,0,0,1,.14.09l.41,1.1.82-1.11a.15.15,0,0,1,.13-.07h.07a.16.16,0,0,1,.07.13.19.19,0,0,1,0,.07Z"
                    fill="#e4e3e3"
                />
                <path
                    id="Path_50"
                    data-name="Path 50"
                    d="M42.55,187.1h.07a.17.17,0,0,1,.08.13c-.2,1.58-.32,2.44-.34,2.57a1.5,1.5,0,0,1-.26.67,1,1,0,0,1-.81.4,1.121,1.121,0,0,1-.4-.1.91.91,0,0,1-.43-.38.24.24,0,0,1,0-.08.19.19,0,0,1,0-.07.143.143,0,0,1,.24-.01.78.78,0,0,0,.63.36.64.64,0,0,0,.49-.25,1.44,1.44,0,0,0,.27-.77,1.07,1.07,0,0,1-.18.19.79.79,0,0,1-.44.14h-.09a.66.66,0,0,1-.46-.34,1.49,1.49,0,0,1-.21-.82c0-.14,0-.3,0-.46a8.485,8.485,0,0,1,.17-1.05v-.05a.15.15,0,0,1,.13-.1h.08a.15.15,0,0,1,.07.12,4.341,4.341,0,0,1-.07.56c-.06.44-.1.72-.1.86a1.75,1.75,0,0,0,.11.66c.09.2.21.3.37.3h0a.69.69,0,0,0,.36-.3,1.72,1.72,0,0,0,.31-.67l.18-1.4a.64.64,0,0,1,0-.07.14.14,0,0,1,.23-.04Z"
                    fill="#e4e3e3"
                />
                <path
                    id="Path_51"
                    data-name="Path 51"
                    d="M60.87,192.23s-.08-1.67-.08-3.74a31.122,31.122,0,0,1,.08-3.74,32.562,32.562,0,0,1,.07,3.74C60.94,190.56,60.91,192.23,60.87,192.23Z"
                    fill="#e4e3e3"
                />
                <path
                    id="Path_52"
                    data-name="Path 52"
                    d="M27.53,192.23s-.07-1.67-.07-3.74a35.551,35.551,0,0,1,.07-3.74,33.84,33.84,0,0,1,.08,3.74C27.61,190.56,27.58,192.23,27.53,192.23Z"
                    fill="#e4e3e3"
                />
                <path
                    id="Path_53"
                    data-name="Path 53"
                    d="M61.58,192.23s-.07-1.67-.07-3.74a35.551,35.551,0,0,1,.07-3.74,33.839,33.839,0,0,1,.08,3.74C61.66,190.56,61.62,192.23,61.58,192.23Z"
                    fill="#e4e3e3"
                />
                <path
                    id="Path_54"
                    data-name="Path 54"
                    d="M26.87,192.23c-.05,0-.08-1.67-.08-3.74a31.123,31.123,0,0,1,.08-3.74,32.563,32.563,0,0,1,.07,3.74C26.94,190.56,26.91,192.23,26.87,192.23Z"
                    fill="#e4e3e3"
                />
                <path
                    id="Path_55"
                    data-name="Path 55"
                    d="M51,186h0c1.12.89,2.63,2.11,4.31,3.47h0l-.57.15-3.64,1h0l-3.3-1.4-.93-.4-.25-.11h.01l.25.1.93.38,3.3,1.39h0l3.63-.95.58-.16h0L51,186h0"
                    fill="#e0e0e0"
                />
                <path
                    id="Path_56"
                    data-name="Path 56"
                    d="M54.9,188.9l.23.22.32-.07-.14.29.16.28-.32-.04-.21.24-.06-.32-.3-.13.29-.15Z"
                    fill="#e0e0e0"
                />
                <path
                    id="Path_57"
                    data-name="Path 57"
                    d="M50.77,190.27l.16.13.2-.06-.08.2.12.17-.21-.01-.13.16-.05-.2-.19-.07.17-.11Z"
                    fill="#e0e0e0"
                />
                <path
                    id="Path_58"
                    data-name="Path 58"
                    d="M46.21,188.4l.25.01.16-.2.07.25.24.09-.22.13-.01.26-.2-.16-.24.07.09-.24Z"
                    fill="#e0e0e0"
                />
                <path
                    id="Path_59"
                    data-name="Path 59"
                    d="M56.83,186.03l.26.02.15-.2.07.25.24.08-.22.14-.01.26-.19-.17-.25.07.09-.23Z"
                    fill="#e0e0e0"
                />
                <path
                    id="Path_60"
                    data-name="Path 60"
                    d="M50.78,185.87l.16.01.11-.13.04.16.15.05-.14.09-.01.16-.12-.1-.16.04.06-.15Z"
                    fill="#e0e0e0"
                />
                <path
                    id="Path_61"
                    data-name="Path 61"
                    d="M57.19,186.21s-1.39,0-3.11-.1S51,186,51,186s1.39,0,3.11.1S57.19,186.2,57.19,186.21Z"
                    fill="#e0e0e0"
                />
                <path
                    id="Path_62"
                    data-name="Path 62"
                    d="M58.75,184.78H17.44a3.48,3.48,0,0,1,0-3.44H58.75A4,4,0,0,1,58.75,184.78Z"
                    fill="#e4e3e3"
                />
                <rect
                    id="Rectangle_7"
                    data-name="Rectangle 7"
                    width="22.38"
                    height="1.25"
                    transform="translate(25.99 182.32)"
                    fill="#f4f5f6"
                />
                <path
                    id="Path_63"
                    data-name="Path 63"
                    d="M19,184.8a1.13,1.13,0,0,1-.33-.42,3,3,0,0,1-.38-1.22,3.17,3.17,0,0,1,.16-1.27c.11-.31.23-.47.25-.46a3.43,3.43,0,0,1-.15.49,3.77,3.77,0,0,0-.11,1.23,3.83,3.83,0,0,0,.32,1.18,3.78,3.78,0,0,1,.24.47Z"
                    fill="#f4f5f6"
                />
                <path
                    id="Path_64"
                    data-name="Path 64"
                    d="M19.92,184.8a1.42,1.42,0,0,1-.33-.42,3.12,3.12,0,0,1-.22-2.49,1.27,1.27,0,0,1,.25-.46s-.06.19-.14.49a3.29,3.29,0,0,0-.11,1.23,3.34,3.34,0,0,0,.32,1.18,4.583,4.583,0,0,1,.23.47Z"
                    fill="#f4f5f6"
                />
                <path
                    id="Path_65"
                    data-name="Path 65"
                    d="M57.08,184.72a7,7,0,0,0,.29-1.69,7.62,7.62,0,0,0-.27-1.68,3.52,3.52,0,0,1,0,3.37Z"
                    fill="#f4f5f6"
                />
                <path
                    id="Path_66"
                    data-name="Path 66"
                    d="M56.31,184.71a10.635,10.635,0,0,0,.13-1.65c0-.91-.18-1.64-.13-1.65a4.19,4.19,0,0,1,.28,1.65A4,4,0,0,1,56.31,184.71Z"
                    fill="#f4f5f6"
                />
                <rect
                    id="Rectangle_8"
                    data-name="Rectangle 8"
                    width="14.71"
                    height="15.6"
                    transform="matrix(1, -0.005, 0.005, 1, 78.222, 133.625)"
                    fill="#e4e3e3"
                />
                <rect
                    id="Rectangle_9"
                    data-name="Rectangle 9"
                    width="14.71"
                    height="15.6"
                    transform="matrix(1, -0.005, 0.005, 1, 92.851, 133.546)"
                    fill="#f4f5f6"
                />
                <path
                    id="Path_67"
                    data-name="Path 67"
                    d="M78.23,137.94c9.78.08,19.58,0,29.35-.13a.16.16,0,0,0,0-.32c-9.77-.05-19.57,0-29.35.18a.14.14,0,0,0,0,.27Z"
                    fill="#fff"
                />
                <rect
                    id="Rectangle_10"
                    data-name="Rectangle 10"
                    width="26.73"
                    height="12.72"
                    transform="matrix(1, -0.005, 0.005, 1, 69.045, 149.035)"
                    fill="#f4f5f6"
                />
                <rect
                    id="Rectangle_11"
                    data-name="Rectangle 11"
                    width="26.73"
                    height="12.72"
                    transform="matrix(1, -0.005, 0.005, 1, 95.644, 148.901)"
                    fill="#f5f5f5"
                />
                <path
                    id="Path_68"
                    data-name="Path 68"
                    d="M69.06,152.58c17.75.2,35.57,0,53.32-.27a.16.16,0,0,0,0-.31c-17.75-.09-35.57-.08-53.32.31a.14.14,0,1,0,0,.27Z"
                    fill="#e4e3e3"
                />
                <rect
                    id="Rectangle_12"
                    data-name="Rectangle 12"
                    width="26.73"
                    height="12.72"
                    transform="matrix(1, -0.005, 0.005, 1, 44.925, 221.015)"
                    fill="#e0e0e0"
                />
                <rect
                    id="Rectangle_13"
                    data-name="Rectangle 13"
                    width="26.73"
                    height="12.72"
                    transform="matrix(1, -0.005, 0.005, 1, 71.524, 220.871)"
                    fill="#f4f5f6"
                />
                <path
                    id="Path_69"
                    data-name="Path 69"
                    d="M44.93,224.55c17.76.2,35.57,0,53.33-.26a.16.16,0,0,0,0-.31c-17.76-.1-35.58-.09-53.33.3a.14.14,0,0,0,0,.27Z"
                    fill="#fff"
                />
                <rect
                    id="Rectangle_14"
                    data-name="Rectangle 14"
                    width="6.74"
                    height="33.55"
                    transform="matrix(1, -0.005, 0.005, 1, 29.034, 128.281)"
                    fill="#e4e3e3"
                />
                <rect
                    id="Rectangle_15"
                    data-name="Rectangle 15"
                    width="6.74"
                    height="46.14"
                    transform="matrix(1, -0.005, 0.005, 1, 35.616, 115.766)"
                    fill="#f4f5f6"
                />
                <rect
                    id="Rectangle_16"
                    data-name="Rectangle 16"
                    width="10.48"
                    height="37.39"
                    transform="matrix(1, -0.005, 0.005, 1, 42.402, 124.379)"
                    fill="#e4e3e3"
                />
                <path
                    id="Path_70"
                    data-name="Path 70"
                    d="M52.81,131.53a38.229,38.229,0,0,1-5.24.18,38.229,38.229,0,0,1-5.24-.18,37.209,37.209,0,0,1,5.24-.19,37.209,37.209,0,0,1,5.24.19Z"
                    fill="#f4f5f6"
                />
                <path
                    id="Path_71"
                    data-name="Path 71"
                    d="M52.81,130a37.209,37.209,0,0,1-5.24.19,37.209,37.209,0,0,1-5.24-.19,38.234,38.234,0,0,1,5.24-.18,38.231,38.231,0,0,1,5.24.18Z"
                    fill="#f4f5f6"
                />
                <path
                    id="Path_72"
                    data-name="Path 72"
                    d="M52.81,156.09a38.229,38.229,0,0,1-5.24.18,38.229,38.229,0,0,1-5.24-.18,37.214,37.214,0,0,1,5.24-.19,37.211,37.211,0,0,1,5.24.19Z"
                    fill="#f4f5f6"
                />
                <path
                    id="Path_73"
                    data-name="Path 73"
                    d="M52.81,154.52a38.229,38.229,0,0,1-5.24.18,38.226,38.226,0,0,1-5.24-.18,37.211,37.211,0,0,1,5.24-.19,37.211,37.211,0,0,1,5.24.19Z"
                    fill="#f4f5f6"
                />
                <path
                    id="Path_74"
                    data-name="Path 74"
                    d="M47.74,151.09c-.1,0-.18-3.77-.18-8.43s.08-8.42.18-8.42.18,3.77.18,8.42S47.84,151.09,47.74,151.09Z"
                    fill="#f4f5f6"
                />
                <rect
                    id="Rectangle_17"
                    data-name="Rectangle 17"
                    width="11.4"
                    height="28.22"
                    transform="translate(52.92 133.5)"
                    fill="#f4f5f6"
                />
                <path
                    id="Path_75"
                    data-name="Path 75"
                    d="M40.81,123.61a1.93,1.93,0,1,1-.562-1.371A1.94,1.94,0,0,1,40.81,123.61Z"
                    fill="#e0e0e0"
                />
                <rect
                    id="Rectangle_18"
                    data-name="Rectangle 18"
                    width="1.2"
                    height="27.45"
                    transform="translate(38.28 128.94)"
                    fill="#e4e3e3"
                />
                <rect
                    id="Rectangle_19"
                    data-name="Rectangle 19"
                    width="118.7"
                    height="4.06"
                    transform="translate(10.24 200.42)"
                    fill="#e4e3e3"
                />
                <rect
                    id="Rectangle_20"
                    data-name="Rectangle 20"
                    width="118.7"
                    height="4.06"
                    transform="translate(10.24 161.91)"
                    fill="#e4e3e3"
                />
                <rect
                    id="Rectangle_21"
                    data-name="Rectangle 21"
                    width="94.47"
                    height="3.83"
                    transform="translate(34.47 233.75)"
                    fill="#e0e0e0"
                />
                <rect
                    id="Rectangle_22"
                    data-name="Rectangle 22"
                    width="4.53"
                    height="38.31"
                    transform="translate(65.06 165.17)"
                    fill="#e4e3e3"
                />
                <rect
                    id="Rectangle_23"
                    data-name="Rectangle 23"
                    width="4.53"
                    height="37.15"
                    transform="translate(34.49 200.42)"
                    fill="#e4e3e3"
                />
                <path
                    id="Path_76"
                    data-name="Path 76"
                    d="M92.25,183l-2.78,4s6.26,1.74,6.26,7.31-3.83,6.61-8,6.61-8.17-1.22-8-6.79,5.39-7.65,5.39-7.65L82.51,183Z"
                    fill="#f5f5f5"
                />
                <rect
                    id="Rectangle_24"
                    data-name="Rectangle 24"
                    width="4.69"
                    height="100.9"
                    transform="translate(460.39 132.25)"
                    fill="#e4e3e3"
                />
                <rect
                    id="Rectangle_25"
                    data-name="Rectangle 25"
                    width="67.36"
                    height="100.26"
                    rx="0.07"
                    transform="translate(392.32 133.03)"
                    fill="#f4f5f6"
                />
                <path
                    id="Path_77"
                    data-name="Path 77"
                    d="M460.39,234.08H391.61a.08.08,0,0,1-.07-.07V132.32a.07.07,0,0,1,.07-.07h68.78a.07.07,0,0,1,.07.07V234A.08.08,0,0,1,460.39,234.08Zm-67.22-1.57h65.66a.07.07,0,0,0,.07-.06V133.88a.07.07,0,0,0-.07-.07H393.17a.07.07,0,0,0-.07.07v98.57a.07.07,0,0,0,.07.06Z"
                    fill="#e4e3e3"
                />
                <rect
                    id="Rectangle_26"
                    data-name="Rectangle 26"
                    width="53.92"
                    height="87.82"
                    rx="0.07"
                    transform="translate(399.1 139.22)"
                    fill="#fff"
                />
                <rect
                    id="Rectangle_27"
                    data-name="Rectangle 27"
                    width="30.27"
                    height="35.48"
                    rx="0.06"
                    transform="translate(410.86 164.12)"
                    fill="#e4e3e3"
                />
                <path
                    id="Path_78"
                    data-name="Path 78"
                    d="M438.74,195.26c0,.11-1.45.77-4.3.43a10.47,10.47,0,0,1-4.83-1.93,9.8,9.8,0,0,1-3.75-5.9,8.46,8.46,0,0,1,2.87-8.07,7.36,7.36,0,0,1,2.17-1.28,5.75,5.75,0,0,1,2.73-.31,4.73,4.73,0,0,1,2.72,1.19,3.52,3.52,0,0,1,1.07,3.17,5.14,5.14,0,0,1-1.49,2.72,9.79,9.79,0,0,1-2.28,1.68,12.47,12.47,0,0,1-5.44,1.55,10.71,10.71,0,0,1-5.8-1.32,10.59,10.59,0,0,1-4.27-4.42,9.05,9.05,0,0,1-.76-6.19,9.22,9.22,0,0,1,3.26-5.11,8.23,8.23,0,0,1,2.74-1.38,6.68,6.68,0,0,1,3.08-.09,5,5,0,0,1,2.8,1.57,4.66,4.66,0,0,1,1.09,3,5.72,5.72,0,0,1-.72,2.85,7.661,7.661,0,0,1-1.73,2.07,11.6,11.6,0,0,1-4.29,2.28,10.87,10.87,0,0,1-4.51.35,9.27,9.27,0,0,1-3.93-1.5,8.53,8.53,0,0,1-3.75-5.94,7.21,7.21,0,0,1,1.39-5.1,6.19,6.19,0,0,1,2.57-2c.71-.27,1.11-.26,1.13-.19s-.32.25-.89.63a6.59,6.59,0,0,0-2,2.15,6.34,6.34,0,0,0-.82,4.35,7.12,7.12,0,0,0,3.33,4.67,8.2,8.2,0,0,0,6.88.66,9.67,9.67,0,0,0,3.44-1.95,4.22,4.22,0,0,0,1.61-3.23,2.31,2.31,0,0,0-2.07-2.28,4.3,4.3,0,0,0-2,.09,5.79,5.79,0,0,0-1.87,1,6.83,6.83,0,0,0-2.35,3.75,6.51,6.51,0,0,0,.58,4.5,8.09,8.09,0,0,0,3.25,3.38,8.45,8.45,0,0,0,4.48,1.05,10.26,10.26,0,0,0,4.41-1.21,7.56,7.56,0,0,0,1.76-1.24,3,3,0,0,0,.89-1.45,1.25,1.25,0,0,0-.36-1.17,2.56,2.56,0,0,0-1.46-.64,3.63,3.63,0,0,0-1.71.14,5.441,5.441,0,0,0-1.6.88,6.64,6.64,0,0,0-2.09,2.87,6.71,6.71,0,0,0-.35,3.3,8.46,8.46,0,0,0,2.89,5.11,10.12,10.12,0,0,0,4.14,2.07C437.18,195.34,438.72,195,438.74,195.26Z"
                    fill="#fff"
                />
                <path
                    id="Path_79"
                    data-name="Path 79"
                    d="M405.22,151.8l-.1,204.25a11.45,11.45,0,0,1-11.45,11.43l-303.06-.16a11.45,11.45,0,0,1-11.43-11.44l.11-204.25A11.44,11.44,0,0,1,90.73,140.2l303.06.16a11.43,11.43,0,0,1,11.43,11.44Z"
                    fill="#e4e3e3"
                />
                <path
                    id="Path_80"
                    data-name="Path 80"
                    d="M405.22,151.8v-.7a5.127,5.127,0,0,0-.09-.87,8.453,8.453,0,0,0-.24-1.2,10.69,10.69,0,0,0-1.33-3.16,11.529,11.529,0,0,0-3.24-3.44,11.25,11.25,0,0,0-2.54-1.31,10.881,10.881,0,0,0-3.1-.67H92.09a18.561,18.561,0,0,0-3,.13,11.08,11.08,0,0,0-2.86.8,11.33,11.33,0,0,0-4.61,3.7c-.32.39-.54.84-.82,1.26a14.113,14.113,0,0,0-.61,1.38,10.9,10.9,0,0,0-.69,2.92c-.08,1-.06,2-.06,3.06V355.89a12.3,12.3,0,0,0,.51,3.41,12.14,12.14,0,0,0,1.53,3.08,11.21,11.21,0,0,0,2.38,2.48,10.62,10.62,0,0,0,3,1.64c2.16.87,4.52.65,6.84.69h88.49l102,.06,92.28.06h16.05a25.1,25.1,0,0,0,2.61-.09,10.708,10.708,0,0,0,2.51-.61,11.31,11.31,0,0,0,6.87-7.1c.13-.4.22-.82.33-1.22a7.531,7.531,0,0,0,.18-1.25c.06-.84.05-1.69.05-2.54V310.92c.05-49.15.09-88.92.11-116.41,0-13.74,0-24.42.05-31.65V354.5c0,.85,0,1.7-.05,2.56a9.385,9.385,0,0,1-.18,1.28,6.8,6.8,0,0,1-.34,1.25,11.6,11.6,0,0,1-7,7.28,10.931,10.931,0,0,1-2.58.63,23.37,23.37,0,0,1-2.64.09H90.14a11.079,11.079,0,0,1-3.47-.68,11.68,11.68,0,0,1-5.55-4.25,12,12,0,0,1-1.58-3.18,13,13,0,0,1-.53-3.51c0-37.5,0-74.11.06-109.47V153.61c0-1,0-2.05.05-3.09a11.6,11.6,0,0,1,.72-3,12.641,12.641,0,0,1,.63-1.41,13.709,13.709,0,0,1,.84-1.3,11.68,11.68,0,0,1,4.75-3.8,11.49,11.49,0,0,1,2.94-.83,19.262,19.262,0,0,1,3-.13h80.21l80.39.06,116.42.12h25.59a11,11,0,0,1,3.12.68,11.572,11.572,0,0,1,2.55,1.33,11.761,11.761,0,0,1,3.25,3.47,11.11,11.11,0,0,1,1.32,3.18,10.314,10.314,0,0,1,.23,1.2,6.481,6.481,0,0,1,.08.88C405.22,151.56,405.22,151.8,405.22,151.8Z"
                    fill="#707272"
                />
                <rect
                    id="Rectangle_28"
                    data-name="Rectangle 28"
                    width="212.79"
                    height="272.19"
                    transform="translate(101.63 360.53) rotate(-90)"
                    fill="#fff"
                />
                <path
                    id="Path_81"
                    data-name="Path 81"
                    d="M388.8,245.2a8.7,8.7,0,1,1-6.168,2.545A8.72,8.72,0,0,1,388.8,245.2Z"
                    fill="#263238"
                />
                <rect
                    id="Rectangle_29"
                    data-name="Rectangle 29"
                    width="68.4"
                    height="3.6"
                    rx="1.22"
                    transform="translate(88.58 287.83) rotate(-90)"
                    fill="#707272"
                />
                <rect
                    id="Rectangle_30"
                    data-name="Rectangle 30"
                    width="106.44"
                    height="17.63"
                    rx="5.43"
                    transform="translate(122.55 328.45)"
                    fill="#f4f5f6"
                />
                <rect
                    id="Rectangle_31"
                    data-name="Rectangle 31"
                    width="106.44"
                    height="17.63"
                    rx="5.43"
                    transform="translate(121.64 290.68)"
                    fill="#f4f5f6"
                />
                <rect
                    id="Rectangle_32"
                    data-name="Rectangle 32"
                    width="106.44"
                    height="17.63"
                    rx="5.43"
                    transform="translate(236.02 290.68)"
                    fill="#f4f5f6"
                />
                <rect
                    id="Rectangle_33"
                    data-name="Rectangle 33"
                    width="102.48"
                    height="17.63"
                    rx="5.43"
                    transform="translate(238.87 183.37)"
                    fill="#f4f5f6"
                />
                <rect
                    id="Rectangle_34"
                    data-name="Rectangle 34"
                    width="221.22"
                    height="17.63"
                    rx="5.43"
                    transform="translate(122.55 218.38)"
                    fill="#f4f5f6"
                />
                <rect
                    id="Rectangle_35"
                    data-name="Rectangle 35"
                    width="106.44"
                    height="17.63"
                    rx="5.43"
                    transform="translate(122.38 183.37)"
                    fill="#f4f5f6"
                />
                <path
                    id="Path_82"
                    data-name="Path 82"
                    d="M129.77,192.61v1.2h-.5V192.6l-1.38-2.27h.54l1.11,1.84,1.11-1.84h.5Z"
                    fill="#e4e3e3"
                />
                <path
                    id="Path_83"
                    data-name="Path 83"
                    d="M131,192.49a1.37,1.37,0,1,1,1.37,1.35,1.31,1.31,0,0,1-1.37-1.35Zm2.25,0a.89.89,0,1,0-.413.849.89.89,0,0,0,.413-.849Z"
                    fill="#e4e3e3"
                />
                <path
                    id="Path_84"
                    data-name="Path 84"
                    d="M136.89,191.18v2.63h-.46v-.4a1.07,1.07,0,0,1-.89.43,1,1,0,0,1-1.13-1.15v-1.51h.47v1.46c0,.51.26.77.71.77a.78.78,0,0,0,.82-.87v-1.36Z"
                    fill="#e4e3e3"
                />
                <path
                    id="Path_85"
                    data-name="Path 85"
                    d="M139.21,191.15v.46h-.11a.79.79,0,0,0-.83.89v1.31h-.48v-2.63h.46v.44A1,1,0,0,1,139.21,191.15Z"
                    fill="#e4e3e3"
                />
                <path
                    id="Path_86"
                    data-name="Path 86"
                    d="M144.22,190.33v3.48h-.4l-2.09-2.59v2.59h-.49v-3.48h.4l2.09,2.59v-2.59Z"
                    fill="#e4e3e3"
                />
                <path
                    id="Path_87"
                    data-name="Path 87"
                    d="M147.28,192.22v1.59h-.45v-.35a1,1,0,0,1-.86.38c-.6,0-1-.32-1-.78s.28-.77,1.06-.77h.76v-.09c0-.4-.24-.64-.71-.64a1.29,1.29,0,0,0-.83.29l-.2-.36a1.73,1.73,0,0,1,1.09-.34,1,1,0,0,1,1.14,1.07Zm-.47.78v-.36h-.74c-.47,0-.6.18-.6.4s.21.43.58.43a.76.76,0,0,0,.76-.47Z"
                    fill="#e4e3e3"
                />
                <path
                    id="Path_88"
                    data-name="Path 88"
                    d="M152.53,192.29v1.52h-.47v-1.46c0-.52-.25-.77-.68-.77a.76.76,0,0,0-.79.87v1.36h-.48v-1.46c0-.52-.25-.77-.68-.77a.76.76,0,0,0-.79.87v1.36h-.47v-2.63h.45v.39a1.06,1.06,0,0,1,.91-.42,1,1,0,0,1,.91.49,1.19,1.19,0,0,1,1-.49,1,1,0,0,1,1.09,1.14Z"
                    fill="#e4e3e3"
                />
                <path
                    id="Path_89"
                    data-name="Path 89"
                    d="M155.8,192.65h-2.14a.89.89,0,0,0,1,.77,1,1,0,0,0,.76-.32l.26.31a1.43,1.43,0,0,1-2.45-.92,1.29,1.29,0,0,1,1.32-1.34,1.27,1.27,0,0,1,1.3,1.36A.531.531,0,0,0,155.8,192.65Zm-2.14-.34h1.69a.85.85,0,0,0-1.69,0Z"
                    fill="#e4e3e3"
                />
                <path
                    id="Path_90"
                    data-name="Path 90"
                    d="M141.33,175.39v3.47h-.54l-1.91-2.35v2.35h-.64v-3.47h.53l1.91,2.35v-2.35Z"
                    fill="#707272"
                />
                <path
                    id="Path_91"
                    data-name="Path 91"
                    d="M144.42,177.31v1.55h-.59v-.32a.93.93,0,0,1-.83.36c-.61,0-1-.33-1-.79s.3-.79,1.1-.79h.69c0-.37-.22-.58-.66-.58a1.35,1.35,0,0,0-.81.26l-.24-.45a1.86,1.86,0,0,1,1.12-.33C144,176.18,144.42,176.55,144.42,177.31Zm-.62.73v-.31h-.64c-.42,0-.54.16-.54.35s.19.37.51.37a.69.69,0,0,0,.67-.45Z"
                    fill="#707272"
                />
                <path
                    id="Path_92"
                    data-name="Path 92"
                    d="M149.65,177.35v1.51H149v-1.43c0-.48-.22-.7-.6-.7a.692.692,0,0,0-.69.79v1.34h-.62v-1.43c0-.48-.22-.7-.6-.7a.692.692,0,0,0-.69.79v1.34h-.62v-2.65h.59v.34a1.1,1.1,0,0,1,.87-.37,1,1,0,0,1,.9.45,1.21,1.21,0,0,1,1-.45,1,1,0,0,1,1.11,1.17Z"
                    fill="#707272"
                />
                <path
                    id="Path_93"
                    data-name="Path 93"
                    d="M153,177.74h-2.08a.8.8,0,0,0,.86.63,1,1,0,0,0,.72-.28l.33.38a1.36,1.36,0,0,1-1.07.43,1.34,1.34,0,0,1-1.46-1.36,1.32,1.32,0,0,1,1.37-1.36,1.3,1.3,0,0,1,1.34,1.37S153,177.68,153,177.74Zm-2.09-.41h1.51a.72.72,0,0,0-.75-.65.74.74,0,0,0-.81.65Z"
                    fill="#707272"
                />
                <path
                    id="Path_94"
                    data-name="Path 94"
                    d="M126.65,176.08v.86h1.51v.6h-1.51v1.19h-.75v-3.26h2.46v.61Z"
                    fill="#707272"
                />
                <path
                    id="Path_95"
                    data-name="Path 95"
                    d="M128.68,175.47a.451.451,0,0,1,.9-.01.45.45,0,0,1-.9,0Zm.09.76h.72v2.5h-.72Z"
                    fill="#707272"
                />
                <path
                    id="Path_96"
                    data-name="Path 96"
                    d="M131.72,176.19v.67h-.17a.61.61,0,0,0-.66.7v1.18h-.73v-2.5h.7v.33A1,1,0,0,1,131.72,176.19Z"
                    fill="#707272"
                />
                <path
                    id="Path_97"
                    data-name="Path 97"
                    d="M131.91,178.49l.25-.52a1.64,1.64,0,0,0,.86.25c.34,0,.46-.09.46-.23,0-.41-1.51,0-1.51-1,0-.48.43-.81,1.16-.81a2,2,0,0,1,1,.22l-.24.51a1.4,1.4,0,0,0-.72-.18c-.32,0-.46.1-.46.23,0,.43,1.51,0,1.51,1,0,.46-.44.79-1.18.79a2.11,2.11,0,0,1-1.13-.26Z"
                    fill="#707272"
                />
                <path
                    id="Path_98"
                    data-name="Path 98"
                    d="M136.23,178.61a1.08,1.08,0,0,1-.59.15.82.82,0,0,1-.93-.89v-1h-.39v-.56h.39v-.61h.72v.61h.63v.56h-.63v1a.3.3,0,0,0,.32.33.5.5,0,0,0,.29-.09Z"
                    fill="#707272"
                />
                <path
                    id="Path_99"
                    data-name="Path 99"
                    d="M156.14,175l-.57.75h0l.9-.12V176l-.9-.11h0l.58.72-.38.21-.36-.83h0l-.39.84-.34-.22.57-.72h0l-.88.12v-.41l.87.11h0l-.56-.72.37-.21.37.83h0l.36-.84Z"
                    fill="#e4e3e3"
                />
                <path
                    id="Path_100"
                    data-name="Path 100"
                    d="M130.45,300.55v1.2H130v-1.21l-1.38-2.27h.53l1.11,1.84,1.12-1.84h.49Z"
                    fill="#e4e3e3"
                />
                <path
                    id="Path_101"
                    data-name="Path 101"
                    d="M131.72,300.43a1.37,1.37,0,1,1,1.37,1.35,1.31,1.31,0,0,1-1.37-1.35Zm2.26,0a.89.89,0,1,0-.89.89.89.89,0,0,0,.89-.89Z"
                    fill="#e4e3e3"
                />
                <path
                    id="Path_102"
                    data-name="Path 102"
                    d="M137.57,299.12v2.63h-.45v-.4a1.09,1.09,0,0,1-.9.43,1,1,0,0,1-1.13-1.15v-1.51h.48v1.46c0,.51.26.77.71.77a.77.77,0,0,0,.81-.87v-1.36Z"
                    fill="#e4e3e3"
                />
                <path
                    id="Path_103"
                    data-name="Path 103"
                    d="M139.89,299.09v.46h-.11a.78.78,0,0,0-.82.89v1.31h-.48v-2.63h.46v.44a1,1,0,0,1,.95-.47Z"
                    fill="#e4e3e3"
                />
                <path
                    id="Path_104"
                    data-name="Path 104"
                    d="M144.91,298.27v3.48h-.41l-2.08-2.59v2.59h-.5v-3.48h.41l2.08,2.59v-2.59Z"
                    fill="#e4e3e3"
                />
                <path
                    id="Path_105"
                    data-name="Path 105"
                    d="M148,300.16v1.59h-.45v-.35a1,1,0,0,1-.87.38c-.59,0-1-.32-1-.78s.27-.77,1.06-.77h.75v-.09c0-.4-.23-.64-.7-.64a1.31,1.31,0,0,0-.84.29l-.2-.36a1.77,1.77,0,0,1,1.09-.34,1,1,0,0,1,1.16,1.07Zm-.48.78v-.36h-.73c-.48,0-.61.18-.61.4s.22.43.58.43a.76.76,0,0,0,.73-.47Z"
                    fill="#e4e3e3"
                />
                <path
                    id="Path_106"
                    data-name="Path 106"
                    d="M153.22,300.23v1.52h-.48v-1.46c0-.52-.25-.77-.68-.77a.76.76,0,0,0-.79.87v1.36h-.47v-1.46c0-.52-.25-.77-.68-.77a.76.76,0,0,0-.79.87v1.36h-.48v-2.63h.46v.39a1.06,1.06,0,0,1,.91-.42,1,1,0,0,1,.91.49,1.19,1.19,0,0,1,1-.49,1,1,0,0,1,1.09,1.14Z"
                    fill="#e4e3e3"
                />
                <path
                    id="Path_107"
                    data-name="Path 107"
                    d="M156.48,300.59h-2.13a.89.89,0,0,0,1,.77,1,1,0,0,0,.76-.32l.26.31a1.43,1.43,0,0,1-2.45-.92,1.29,1.29,0,0,1,1.32-1.34,1.27,1.27,0,0,1,1.3,1.36.66.66,0,0,1-.06.14Zm-2.13-.34H156a.83.83,0,0,0-.85-.76.82.82,0,0,0-.8.76Z"
                    fill="#e4e3e3"
                />
                <path
                    id="Path_108"
                    data-name="Path 108"
                    d="M150.31,281.52l-.57.75h0l.9-.12v.41l-.9-.11h0l.58.72-.38.21-.36-.83h0l-.39.84-.34-.22.57-.72h0l-.88.12v-.41l.87.11h0l-.56-.73.37-.2.37.82h0l.36-.83Z"
                    fill="#e4e3e3"
                />
                <path
                    id="Path_109"
                    data-name="Path 109"
                    d="M255.18,283l-.57.75h0l.9-.12v.41l-.9-.11h0l.57.72-.37.21-.36-.83h0l-.39.84-.34-.22.56-.72h0l-.88.12v-.41l.88.12h0l-.56-.72.36-.21.37.83h0l.37-.84Z"
                    fill="#e4e3e3"
                />
                <path
                    id="Path_110"
                    data-name="Path 110"
                    d="M243.93,300.55v1.2h-.5v-1.21l-1.38-2.27h.54l1.11,1.84,1.11-1.84h.5Z"
                    fill="#e4e3e3"
                />
                <path
                    id="Path_111"
                    data-name="Path 111"
                    d="M245.2,300.43a1.37,1.37,0,1,1,1.37,1.35,1.31,1.31,0,0,1-1.37-1.35Zm2.25,0a.89.89,0,1,0-.413.849.89.89,0,0,0,.413-.849Z"
                    fill="#e4e3e3"
                />
                <path
                    id="Path_112"
                    data-name="Path 112"
                    d="M251.05,299.12v2.63h-.46v-.4a1.07,1.07,0,0,1-.89.43,1,1,0,0,1-1.13-1.15v-1.51H249v1.46c0,.51.26.77.71.77a.78.78,0,0,0,.82-.87v-1.36Z"
                    fill="#e4e3e3"
                />
                <path
                    id="Path_113"
                    data-name="Path 113"
                    d="M253.37,299.09v.46h-.11a.79.79,0,0,0-.83.89v1.31H252v-2.63h.46v.44a1,1,0,0,1,.91-.47Z"
                    fill="#e4e3e3"
                />
                <path
                    id="Path_114"
                    data-name="Path 114"
                    d="M258.38,298.27v3.48H258l-2.09-2.59v2.59h-.49v-3.48h.4l2.09,2.59v-2.59Z"
                    fill="#e4e3e3"
                />
                <path
                    id="Path_115"
                    data-name="Path 115"
                    d="M261.44,300.16v1.59H261v-.35a1,1,0,0,1-.86.38c-.6,0-1-.32-1-.78s.28-.77,1.06-.77h.8v-.09c0-.4-.24-.64-.71-.64a1.29,1.29,0,0,0-.83.29l-.2-.36a1.73,1.73,0,0,1,1.09-.34,1,1,0,0,1,1.09,1.07Zm-.47.78v-.36h-.74c-.47,0-.6.18-.6.4s.21.43.58.43a.76.76,0,0,0,.79-.47Z"
                    fill="#e4e3e3"
                />
                <path
                    id="Path_116"
                    data-name="Path 116"
                    d="M266.69,300.23v1.52h-.47v-1.46c0-.52-.25-.77-.68-.77a.76.76,0,0,0-.79.87v1.36h-.48v-1.46c0-.52-.25-.77-.68-.77a.76.76,0,0,0-.79.87v1.36h-.47v-2.63h.45v.39a1.06,1.06,0,0,1,.91-.42,1,1,0,0,1,.91.49,1.19,1.19,0,0,1,1-.49,1,1,0,0,1,1.09,1.14Z"
                    fill="#e4e3e3"
                />
                <path
                    id="Path_117"
                    data-name="Path 117"
                    d="M270,300.59h-2.14a.89.89,0,0,0,1,.77,1,1,0,0,0,.76-.32l.26.31a1.43,1.43,0,0,1-2.45-.92,1.29,1.29,0,0,1,1.32-1.34,1.27,1.27,0,0,1,1.3,1.36A.532.532,0,0,0,270,300.59Zm-2.14-.34h1.69a.85.85,0,0,0-1.69,0Z"
                    fill="#e4e3e3"
                />
                <path
                    id="Path_118"
                    data-name="Path 118"
                    d="M129.26,213.39v.54h-2.61v-3.48h2.54V211H127.3v.91H129v.53h-1.7v1Z"
                    fill="#707272"
                />
                <path
                    id="Path_119"
                    data-name="Path 119"
                    d="M134.35,212.41v1.52h-.62v-1.44c0-.47-.22-.7-.59-.7a.7.7,0,0,0-.69.79v1.35h-.62v-1.44c0-.47-.22-.7-.6-.7a.7.7,0,0,0-.69.79v1.35h-.62v-2.65h.59v.34a1.1,1.1,0,0,1,.87-.37,1,1,0,0,1,.89.44,1.26,1.26,0,0,1,1-.44,1,1,0,0,1,1.08,1.16Z"
                    fill="#707272"
                />
                <path
                    id="Path_120"
                    data-name="Path 120"
                    d="M137.37,212.37v1.56h-.59v-.32a1,1,0,0,1-.83.35c-.61,0-1-.33-1-.79s.3-.79,1.1-.79h.69c0-.36-.22-.58-.66-.58a1.29,1.29,0,0,0-.81.27l-.24-.45a1.86,1.86,0,0,1,1.12-.33C136.93,211.25,137.37,211.61,137.37,212.37Zm-.62.73v-.31h-.64c-.42,0-.54.16-.54.35s.19.37.51.37a.69.69,0,0,0,.67-.41Z"
                    fill="#707272"
                />
                <path
                    id="Path_121"
                    data-name="Path 121"
                    d="M138.08,210.47a.38.38,0,0,1,.4-.37.36.36,0,0,1,.39.35.4.4,0,0,1-.79,0Zm.08.81h.62v2.65h-.62Z"
                    fill="#707272"
                />
                <path
                    id="Path_122"
                    data-name="Path 122"
                    d="M139.6,210.25h.62v3.68h-.62Z"
                    fill="#707272"
                />
                <path
                    id="Path_123"
                    data-name="Path 123"
                    d="M130.45,228.25v1.2H130v-1.21L128.58,226h.53l1.11,1.83,1.12-1.83h.49Z"
                    fill="#e4e3e3"
                />
                <path
                    id="Path_124"
                    data-name="Path 124"
                    d="M131.72,228.14a1.37,1.37,0,1,1,1.37,1.34,1.31,1.31,0,0,1-1.37-1.34Zm2.26,0a.89.89,0,1,0-.89.92.87.87,0,0,0,.91-.92Z"
                    fill="#e4e3e3"
                />
                <path
                    id="Path_125"
                    data-name="Path 125"
                    d="M137.57,226.82v2.63h-.45v-.4a1.07,1.07,0,0,1-.9.43,1,1,0,0,1-1.13-1.15v-1.51h.48v1.46c0,.52.26.77.71.77a.76.76,0,0,0,.81-.87v-1.36Z"
                    fill="#e4e3e3"
                />
                <path
                    id="Path_126"
                    data-name="Path 126"
                    d="M139.89,226.8v.46h-.11a.78.78,0,0,0-.82.89v1.31h-.48v-2.63h.46v.44A1,1,0,0,1,139.89,226.8Z"
                    fill="#e4e3e3"
                />
                <path
                    id="Path_127"
                    data-name="Path 127"
                    d="M144.44,229v.43h-2.52V226h2.45v.43h-2v1.07h1.74v.42h-1.74V229Z"
                    fill="#e4e3e3"
                />
                <path
                    id="Path_128"
                    data-name="Path 128"
                    d="M149.54,227.94v1.51h-.47V228c0-.51-.25-.77-.68-.77a.793.793,0,0,0-.79.88v1.35h-.48V228c0-.51-.25-.77-.68-.77a.77.77,0,0,0-.79.88v1.35h-.47v-2.63h.45v.39a1.07,1.07,0,0,1,.91-.41,1,1,0,0,1,.92.48,1.16,1.16,0,0,1,1-.48,1,1,0,0,1,1.08,1.13Z"
                    fill="#e4e3e3"
                />
                <path
                    id="Path_129"
                    data-name="Path 129"
                    d="M152.51,227.86v1.59h-.45v-.35a1,1,0,0,1-.87.38c-.59,0-1-.32-1-.77s.27-.77,1.06-.77H152v-.1c0-.4-.23-.63-.7-.63a1.39,1.39,0,0,0-.84.28l-.19-.36a1.72,1.72,0,0,1,1.08-.33C152.1,226.8,152.51,227.14,152.51,227.86Zm-.48.79v-.37h-.73c-.47,0-.61.18-.61.41s.22.42.58.42a.76.76,0,0,0,.73-.46Z"
                    fill="#e4e3e3"
                />
                <path
                    id="Path_130"
                    data-name="Path 130"
                    d="M153.31,226a.33.33,0,0,1,.65,0,.325.325,0,0,1-.65,0Zm.08.81h.48v2.63h-.48Z"
                    fill="#e4e3e3"
                />
                <path
                    id="Path_131"
                    data-name="Path 131"
                    d="M154.78,225.77h.48v3.68h-.48Z"
                    fill="#e4e3e3"
                />
                <path
                    id="Path_132"
                    data-name="Path 132"
                    d="M144.17,210.05l-.56.74h0l.9-.11v.4l-.9-.11h0l.57.71-.38.21-.35-.83h0l-.39.84-.34-.21.56-.73h0l-.88.11v-.4l.88.11h0l-.56-.73.36-.21.37.83h0l.37-.84Z"
                    fill="#e4e3e3"
                />
                <path
                    id="Path_133"
                    data-name="Path 133"
                    d="M171.29,246.09l-.56.75h0l.9-.12v.41l-.9-.11h0l.57.72-.37.21-.36-.83h0l-.39.84-.34-.22.56-.73h0l-.88.12v-.41l.88.11h0l-.56-.73.36-.2.37.82h0l.37-.83Z"
                    fill="#e4e3e3"
                />
                <rect
                    id="Rectangle_36"
                    data-name="Rectangle 36"
                    width="221.54"
                    height="17.63"
                    rx="5.43"
                    transform="translate(122.22 255.8)"
                    fill="#f4f5f6"
                />
                <path
                    id="Path_134"
                    data-name="Path 134"
                    d="M241.59,174.06h.65V177h1.81v.55h-2.46Z"
                    fill="#707272"
                />
                <path
                    id="Path_135"
                    data-name="Path 135"
                    d="M246.75,176v1.56h-.59v-.33a.92.92,0,0,1-.83.36c-.6,0-1-.33-1-.79s.3-.79,1.11-.79h.68c0-.36-.22-.58-.66-.58a1.25,1.25,0,0,0-.8.27l-.25-.46a1.94,1.94,0,0,1,1.12-.32C246.31,174.86,246.75,175.22,246.75,176Zm-.62.73v-.31h-.64c-.42,0-.54.16-.54.35s.19.37.51.37a.68.68,0,0,0,.67-.43Z"
                    fill="#707272"
                />
                <path
                    id="Path_136"
                    data-name="Path 136"
                    d="M247.24,177.27l.24-.47a1.71,1.71,0,0,0,.92.26c.4,0,.56-.1.56-.29,0-.5-1.64,0-1.64-1.09,0-.5.45-.82,1.17-.82a2.16,2.16,0,0,1,1,.22l-.24.48a1.42,1.42,0,0,0-.76-.2c-.37,0-.55.12-.55.3,0,.53,1.64.05,1.64,1.09,0,.5-.46.82-1.21.82a2.1,2.1,0,0,1-1.13-.3Z"
                    fill="#707272"
                />
                <path
                    id="Path_137"
                    data-name="Path 137"
                    d="M251.7,177.39a1,1,0,0,1-.6.18.81.81,0,0,1-.91-.89V175.4h-.44v-.49h.44v-.61h.62v.61h.71v.49h-.71v1.26c0,.26.13.4.36.4a.58.58,0,0,0,.35-.11Z"
                    fill="#707272"
                />
                <path
                    id="Path_138"
                    data-name="Path 138"
                    d="M256.72,174.06v3.48h-.53l-1.91-2.36v2.36h-.64v-3.48h.53l1.91,2.35v-2.35Z"
                    fill="#707272"
                />
                <path
                    id="Path_139"
                    data-name="Path 139"
                    d="M259.82,176v1.56h-.59v-.33a.93.93,0,0,1-.83.36c-.61,0-1-.33-1-.79s.3-.79,1.1-.79h.69c0-.36-.22-.58-.66-.58a1.29,1.29,0,0,0-.81.27l-.24-.46a1.92,1.92,0,0,1,1.12-.32C259.38,174.86,259.82,175.22,259.82,176Zm-.62.73v-.31h-.64c-.42,0-.54.16-.54.35s.19.37.51.37a.69.69,0,0,0,.67-.43Z"
                    fill="#707272"
                />
                <path
                    id="Path_140"
                    data-name="Path 140"
                    d="M265.05,176v1.52h-.62V176.1c0-.47-.22-.7-.6-.7a.7.7,0,0,0-.69.79v1.35h-.62V176.1c0-.47-.22-.7-.6-.7a.7.7,0,0,0-.69.79v1.35h-.62v-2.65h.59v.33a1.13,1.13,0,0,1,.87-.36,1,1,0,0,1,.9.44,1.21,1.21,0,0,1,1-.44,1,1,0,0,1,1.08,1.14Z"
                    fill="#707272"
                />
                <path
                    id="Path_141"
                    data-name="Path 141"
                    d="M268.35,176.41h-2.08a.8.8,0,0,0,.86.63,1,1,0,0,0,.72-.28l.33.38a1.36,1.36,0,0,1-1.07.43,1.34,1.34,0,0,1-1.46-1.36,1.31,1.31,0,0,1,1.37-1.35,1.29,1.29,0,0,1,1.34,1.37C268.36,176.28,268.35,176.35,268.35,176.41Zm-2.09-.41h1.51a.73.73,0,0,0-.75-.65A.74.74,0,0,0,266.26,176Z"
                    fill="#707272"
                />
                <path
                    id="Path_142"
                    data-name="Path 142"
                    d="M247.74,192.61v1.2h-.49V192.6l-1.38-2.27h.53l1.11,1.84,1.11-1.84h.5Z"
                    fill="#e0e0e0"
                />
                <path
                    id="Path_143"
                    data-name="Path 143"
                    d="M249,192.49a1.37,1.37,0,1,1,1.37,1.35,1.31,1.31,0,0,1-1.37-1.35Zm2.25,0a.89.89,0,1,0-.413.849A.89.89,0,0,0,251.25,192.49Z"
                    fill="#e0e0e0"
                />
                <path
                    id="Path_144"
                    data-name="Path 144"
                    d="M254.86,191.18v2.63h-.46v-.4a1.07,1.07,0,0,1-.89.43,1,1,0,0,1-1.13-1.15v-1.51h.48v1.46c0,.51.25.77.71.77a.77.77,0,0,0,.81-.87v-1.36Z"
                    fill="#e0e0e0"
                />
                <path
                    id="Path_145"
                    data-name="Path 145"
                    d="M257.18,191.15v.46h-.11a.79.79,0,0,0-.83.89v1.31h-.47v-2.63h.45v.44A1,1,0,0,1,257.18,191.15Z"
                    fill="#e0e0e0"
                />
                <path
                    id="Path_146"
                    data-name="Path 146"
                    d="M262.19,190.33v3.48h-.4l-2.09-2.59v2.59h-.49v-3.48h.4l2.09,2.59v-2.59Z"
                    fill="#e0e0e0"
                />
                <path
                    id="Path_147"
                    data-name="Path 147"
                    d="M265.25,192.22v1.59h-.45v-.35a1,1,0,0,1-.86.38c-.6,0-1-.32-1-.78s.27-.77,1-.77h.76v-.09c0-.4-.24-.64-.71-.64a1.29,1.29,0,0,0-.83.29l-.2-.36a1.73,1.73,0,0,1,1.09-.34,1,1,0,0,1,1.2,1.07Zm-.47.78v-.36H264c-.47,0-.6.18-.6.4s.22.43.58.43a.76.76,0,0,0,.8-.47Z"
                    fill="#e0e0e0"
                />
                <path
                    id="Path_148"
                    data-name="Path 148"
                    d="M270.51,192.29v1.52H270v-1.46c0-.52-.25-.77-.68-.77a.76.76,0,0,0-.79.87v1.36h-.48v-1.46c0-.52-.24-.77-.68-.77a.76.76,0,0,0-.79.87v1.36h-.47v-2.63h.45v.39a1.08,1.08,0,0,1,.91-.42,1,1,0,0,1,.92.49,1.16,1.16,0,0,1,1-.49,1,1,0,0,1,1.12,1.14Z"
                    fill="#e0e0e0"
                />
                <path
                    id="Path_149"
                    data-name="Path 149"
                    d="M273.77,192.65h-2.14a.9.9,0,0,0,1,.77,1,1,0,0,0,.76-.32l.26.31a1.43,1.43,0,0,1-2.45-.92,1.29,1.29,0,0,1,1.32-1.34,1.27,1.27,0,0,1,1.3,1.36A.531.531,0,0,0,273.77,192.65Zm-2.14-.34h1.69a.85.85,0,0,0-1.69,0Z"
                    fill="#e0e0e0"
                />
                <path
                    id="Path_150"
                    data-name="Path 150"
                    d="M271.76,173.66l-.57.74h0l.9-.12v.41l-.9-.11h0l.57.72-.37.21-.36-.83h0l-.39.84-.34-.22.57-.72h0l-.89.11v-.41l.88.12h0l-.56-.72.36-.21.37.83h0l.36-.84Z"
                    fill="#e4e3e3"
                />
                <path
                    id="Path_151"
                    data-name="Path 151"
                    d="M124.9,247.83a1.88,1.88,0,0,1,3.23-1.21l-.41.4a1.22,1.22,0,1,0,0,1.62l.41.4a1.845,1.845,0,0,1-3.23-1.21Z"
                    fill="#707272"
                />
                <path
                    id="Path_152"
                    data-name="Path 152"
                    d="M128.38,248.24a1.41,1.41,0,1,1,1.41,1.36,1.33,1.33,0,0,1-1.41-1.36Zm2.2,0a.79.79,0,1,0-.79.83.77.77,0,0,0,.79-.83Z"
                    fill="#707272"
                />
                <path
                    id="Path_153"
                    data-name="Path 153"
                    d="M134.41,248.05v1.52h-.62v-1.44c0-.47-.24-.7-.63-.7a.71.71,0,0,0-.74.8v1.34h-.62v-2.65h.59v.34a1.15,1.15,0,0,1,.91-.37,1,1,0,0,1,1.11,1.16Z"
                    fill="#707272"
                />
                <path
                    id="Path_154"
                    data-name="Path 154"
                    d="M136.79,249.42a1,1,0,0,1-.59.18.82.82,0,0,1-.92-.89v-1.28h-.43v-.49h.43v-.61h.62v.61h.71v.49h-.71v1.26c0,.26.13.4.37.4a.61.61,0,0,0,.35-.11Z"
                    fill="#707272"
                />
                <path
                    id="Path_155"
                    data-name="Path 155"
                    d="M139.52,248v1.56h-.58v-.33a1,1,0,0,1-.84.36c-.6,0-1-.33-1-.79s.29-.79,1.1-.79h.68c0-.36-.22-.58-.66-.58a1.27,1.27,0,0,0-.8.27l-.25-.46a2,2,0,0,1,1.13-.32C139.08,246.89,139.52,247.25,139.52,248Zm-.62.73v-.31h-.64c-.42,0-.53.16-.53.35s.18.37.5.37a.68.68,0,0,0,.67-.4Z"
                    fill="#707272"
                />
                <path
                    id="Path_156"
                    data-name="Path 156"
                    d="M140.12,248.24a1.34,1.34,0,0,1,1.44-1.35,1.19,1.19,0,0,1,1.13.61l-.48.28a.75.75,0,0,0-.66-.37.83.83,0,0,0,0,1.66.73.73,0,0,0,.66-.37l.48.28a1.2,1.2,0,0,1-1.13.62,1.35,1.35,0,0,1-1.44-1.36Z"
                    fill="#707272"
                />
                <path
                    id="Path_157"
                    data-name="Path 157"
                    d="M144.86,249.42a1,1,0,0,1-.6.18.81.81,0,0,1-.91-.89v-1.28h-.44v-.49h.44v-.61H144v.61h.71v.49H144v1.26c0,.26.13.4.36.4a.58.58,0,0,0,.35-.11Z"
                    fill="#707272"
                />
                <path
                    id="Path_158"
                    data-name="Path 158"
                    d="M150.67,246.09v3.48h-.54l-1.91-2.36v2.36h-.64v-3.48h.53l1.92,2.35v-2.35Z"
                    fill="#707272"
                />
                <path
                    id="Path_159"
                    data-name="Path 159"
                    d="M154.11,246.92v2.65h-.59v-.34a1.11,1.11,0,0,1-.86.37,1,1,0,0,1-1.14-1.17v-1.51h.62v1.42c0,.49.23.71.62.71a.7.7,0,0,0,.73-.79v-1.34Z"
                    fill="#707272"
                />
                <path
                    id="Path_160"
                    data-name="Path 160"
                    d="M159.35,248.05v1.52h-.62v-1.44c0-.47-.22-.7-.59-.7a.7.7,0,0,0-.69.79v1.35h-.62v-1.44c0-.47-.22-.7-.6-.7a.7.7,0,0,0-.69.79v1.35h-.62v-2.65h.59v.33a1.13,1.13,0,0,1,.87-.36,1,1,0,0,1,.89.44,1.24,1.24,0,0,1,1-.44,1,1,0,0,1,1.08,1.16Z"
                    fill="#707272"
                />
                <path
                    id="Path_161"
                    data-name="Path 161"
                    d="M163,248.24a1.29,1.29,0,0,1-1.34,1.36,1.08,1.08,0,0,1-.88-.38v.35h-.59v-3.69h.62v1.36a1.09,1.09,0,0,1,.85-.35,1.28,1.28,0,0,1,1.34,1.35Zm-.63,0a.79.79,0,1,0-.78.83.76.76,0,0,0,.74-.83Z"
                    fill="#707272"
                />
                <path
                    id="Path_162"
                    data-name="Path 162"
                    d="M166,248.44h-2a.8.8,0,0,0,.86.63,1,1,0,0,0,.72-.28l.33.38a1.36,1.36,0,0,1-1.07.43,1.34,1.34,0,0,1-1.46-1.36,1.31,1.31,0,0,1,1.37-1.35,1.29,1.29,0,0,1,1.34,1.37A.343.343,0,0,0,166,248.44Zm-2-.44h1.51a.73.73,0,0,0-.75-.65A.74.74,0,0,0,164,248Z"
                    fill="#707272"
                />
                <path
                    id="Path_163"
                    data-name="Path 163"
                    d="M168.18,246.89v.59h-.15a.72.72,0,0,0-.77.82v1.29h-.62v-2.65h.6v.38A1,1,0,0,1,168.18,246.89Z"
                    fill="#707272"
                />
                <path
                    id="Path_164"
                    data-name="Path 164"
                    d="M128.91,284.6h-1.74l-.34.8h-.67l1.57-3.47h.63l1.57,3.47h-.68Zm-.22-.51-.65-1.51-.65,1.51Z"
                    fill="#707272"
                />
                <path
                    id="Path_165"
                    data-name="Path 165"
                    d="M132.88,281.72v3.68h-.59v-.34a1.11,1.11,0,0,1-.88.38,1.36,1.36,0,0,1,0-2.72,1.08,1.08,0,0,1,.85.36v-1.36Zm-.61,2.36a.79.79,0,1,0-.78.83.77.77,0,0,0,.78-.83Z"
                    fill="#707272"
                />
                <path
                    id="Path_166"
                    data-name="Path 166"
                    d="M136.3,281.72v3.68h-.6v-.34a1.11,1.11,0,0,1-.88.38,1.36,1.36,0,0,1,0-2.72,1.12,1.12,0,0,1,.86.36v-1.36Zm-.61,2.36a.79.79,0,1,0-.79.83.78.78,0,0,0,.79-.83Z"
                    fill="#707272"
                />
                <path
                    id="Path_167"
                    data-name="Path 167"
                    d="M138.64,282.72v.59h-.14a.72.72,0,0,0-.77.82v1.28h-.62v-2.65h.59v.39a1,1,0,0,1,.94-.43Z"
                    fill="#707272"
                />
                <path
                    id="Path_168"
                    data-name="Path 168"
                    d="M141.63,284.28h-2.08a.8.8,0,0,0,.86.63.93.93,0,0,0,.72-.29l.33.39a1.36,1.36,0,0,1-1.07.43,1.34,1.34,0,0,1-1.46-1.36,1.32,1.32,0,0,1,1.37-1.36,1.29,1.29,0,0,1,1.34,1.37C141.64,284.14,141.63,284.22,141.63,284.28Zm-2.09-.41h1.51a.72.72,0,0,0-.75-.65.74.74,0,0,0-.76.65Z"
                    fill="#707272"
                />
                <path
                    id="Path_169"
                    data-name="Path 169"
                    d="M141.93,285.14l.24-.47a1.8,1.8,0,0,0,.92.26c.4,0,.56-.11.56-.29,0-.51-1.64,0-1.64-1.09,0-.5.45-.83,1.17-.83a2.16,2.16,0,0,1,1,.23l-.24.47a1.52,1.52,0,0,0-.76-.2c-.37,0-.55.13-.55.3,0,.54,1.64.06,1.64,1.1,0,.5-.46.82-1.21.82a2.1,2.1,0,0,1-1.13-.3Z"
                    fill="#707272"
                />
                <path
                    id="Path_170"
                    data-name="Path 170"
                    d="M144.49,285.14l.24-.47a1.8,1.8,0,0,0,.92.26c.39,0,.56-.11.56-.29,0-.51-1.64,0-1.64-1.09,0-.5.45-.83,1.17-.83a2.16,2.16,0,0,1,1,.23l-.24.47a1.54,1.54,0,0,0-.76-.2c-.38,0-.56.13-.56.3,0,.54,1.64.06,1.64,1.1,0,.5-.45.82-1.2.82a2.1,2.1,0,0,1-1.13-.3Z"
                    fill="#707272"
                />
                <path
                    id="Path_171"
                    data-name="Path 171"
                    d="M131.1,337.16l1.45.82-.38.67-1.47-.87v1.66H130v-1.66l-1.47.87-.37-.67,1.45-.82-1.45-.8.37-.68,1.47.86V334.9h.73v1.64l1.47-.86.38.68Z"
                    fill="#e4e3e3"
                />
                <path
                    id="Path_172"
                    data-name="Path 172"
                    d="M136.2,337.16l1.45.82-.38.67-1.47-.87v1.66h-.73v-1.66l-1.47.87-.38-.67,1.46-.82-1.46-.8.38-.68,1.47.86V334.9h.73v1.64l1.47-.86.38.68Z"
                    fill="#e4e3e3"
                />
                <path
                    id="Path_173"
                    data-name="Path 173"
                    d="M141.31,337.16l1.45.82-.38.67-1.47-.87v1.66h-.73v-1.66l-1.47.87-.38-.67,1.46-.82-1.46-.8.38-.68,1.47.86V334.9h.73v1.64l1.47-.86.38.68Z"
                    fill="#e4e3e3"
                />
                <path
                    id="Path_174"
                    data-name="Path 174"
                    d="M146.41,337.16l1.45.82-.38.67-1.47-.87v1.66h-.72v-1.66l-1.47.87-.38-.67,1.46-.82-1.46-.8.38-.68,1.47.86V334.9H146v1.64l1.47-.86.38.68Z"
                    fill="#e4e3e3"
                />
                <path
                    id="Path_175"
                    data-name="Path 175"
                    d="M151.52,337.16,153,338l-.37.67-1.47-.87v1.66h-.73V337.8l-1.47.87-.38-.67,1.46-.82-1.46-.8.38-.68,1.47.86V334.9h.73v1.64l1.47-.86.37.68Z"
                    fill="#e4e3e3"
                />
                <path
                    id="Path_176"
                    data-name="Path 176"
                    d="M156.62,337.16l1.45.82-.38.67-1.47-.87v1.66h-.73v-1.66l-1.47.87-.38-.67,1.46-.82-1.46-.8.38-.68,1.47.86V334.9h.73v1.64l1.47-.86.38.68Z"
                    fill="#e4e3e3"
                />
                <path
                    id="Path_177"
                    data-name="Path 177"
                    d="M161.73,337.16l1.44.82-.37.67-1.47-.87v1.66h-.73v-1.66l-1.47.87-.38-.67,1.46-.82-1.46-.8.38-.68,1.47.86V334.9h.73v1.64l1.47-.86.37.68Z"
                    fill="#e4e3e3"
                />
                <path
                    id="Path_178"
                    data-name="Path 178"
                    d="M239,286.56l.23-.51a1.86,1.86,0,0,0,1.14.39c.54,0,.77-.2.77-.45,0-.75-2.06-.26-2.06-1.55,0-.56.44-1,1.4-1a2.17,2.17,0,0,1,1.15.31l-.2.5a1.87,1.87,0,0,0-1-.27c-.53,0-.75.2-.75.47,0,.74,2.06.25,2.06,1.53,0,.56-.45,1-1.41,1a2.3,2.3,0,0,1-1.33-.42Z"
                    fill="#707272"
                />
                <path
                    id="Path_179"
                    data-name="Path 179"
                    d="M244,286.78a1,1,0,0,1-.59.18.82.82,0,0,1-.92-.9v-1.27h-.43v-.5h.43v-.6h.62v.6h.71v.5h-.71v1.26c0,.26.13.4.37.4a.61.61,0,0,0,.35-.11Z"
                    fill="#707272"
                />
                <path
                    id="Path_180"
                    data-name="Path 180"
                    d="M246.72,285.37v1.55h-.58v-.32a1,1,0,0,1-.84.36c-.6,0-1-.34-1-.8s.29-.79,1.1-.79h.68c0-.36-.22-.57-.66-.57a1.32,1.32,0,0,0-.8.26l-.24-.45a1.84,1.84,0,0,1,1.12-.33C246.28,284.24,246.72,284.6,246.72,285.37Zm-.62.72v-.3h-.64c-.42,0-.53.16-.53.35s.18.37.5.37A.66.66,0,0,0,246.1,286.09Z"
                    fill="#707272"
                />
                <path
                    id="Path_181"
                    data-name="Path 181"
                    d="M249.11,286.78a1,1,0,0,1-.59.18.82.82,0,0,1-.92-.9v-1.27h-.43v-.5h.43v-.6h.62v.6h.71v.5h-.71v1.26c0,.26.13.4.37.4a.61.61,0,0,0,.35-.11Z"
                    fill="#707272"
                />
                <path
                    id="Path_182"
                    data-name="Path 182"
                    d="M252,285.8h-2.08a.79.79,0,0,0,.85.63.91.91,0,0,0,.72-.29l.34.39a1.36,1.36,0,0,1-1.07.43,1.34,1.34,0,0,1-1.46-1.36,1.32,1.32,0,0,1,1.37-1.36,1.29,1.29,0,0,1,1.34,1.37A1.149,1.149,0,0,0,252,285.8Zm-2.09-.42h1.51a.72.72,0,0,0-.75-.64.73.73,0,0,0-.74.64Z"
                    fill="#707272"
                />
                <path
                    id="Path_183"
                    data-name="Path 183"
                    d="M128.05,321.12c0,.77-.56,1.24-1.48,1.24h-.78v1h-.65v-3.47h1.43C127.49,319.89,128.05,320.36,128.05,321.12Zm-.65,0c0-.43-.29-.69-.86-.69h-.75v1.38h.75C127.11,321.81,127.4,321.56,127.4,321.12Z"
                    fill="#707272"
                />
                <path
                    id="Path_184"
                    data-name="Path 184"
                    d="M130.83,321.81v1.55h-.58V323a1,1,0,0,1-.84.36c-.6,0-1-.33-1-.8s.29-.79,1.1-.79h.68c0-.37-.22-.58-.66-.58a1.34,1.34,0,0,0-.8.26l-.24-.45a1.84,1.84,0,0,1,1.12-.33C130.39,320.68,130.83,321.05,130.83,321.81Zm-.62.72v-.3h-.64c-.42,0-.53.16-.53.35s.19.37.5.37a.66.66,0,0,0,.67-.42Z"
                    fill="#707272"
                />
                <path
                    id="Path_185"
                    data-name="Path 185"
                    d="M131.33,323.1l.23-.47a1.87,1.87,0,0,0,.93.26c.39,0,.55-.11.55-.29,0-.51-1.63,0-1.63-1.09,0-.5.45-.83,1.16-.83a2.131,2.131,0,0,1,1,.23l-.23.47a1.47,1.47,0,0,0-.76-.2c-.38,0-.56.13-.56.3,0,.54,1.64.06,1.64,1.1,0,.5-.46.82-1.2.82a2.07,2.07,0,0,1-1.13-.3Z"
                    fill="#707272"
                />
                <path
                    id="Path_186"
                    data-name="Path 186"
                    d="M133.88,323.1l.24-.47a1.8,1.8,0,0,0,.92.26c.4,0,.56-.11.56-.29,0-.51-1.64,0-1.64-1.09,0-.5.45-.83,1.17-.83a2.16,2.16,0,0,1,1,.23l-.24.47a1.42,1.42,0,0,0-.76-.2c-.37,0-.55.13-.55.3,0,.54,1.64.06,1.64,1.1,0,.5-.46.82-1.2.82a2.11,2.11,0,0,1-1.14-.3Z"
                    fill="#707272"
                />
                <path
                    id="Path_187"
                    data-name="Path 187"
                    d="M140.83,320.71l-1,2.65h-.6l-.68-1.82-.7,1.82h-.59l-1-2.65h.58l.7,2,.73-2h.52l.72,2,.72-2Z"
                    fill="#707272"
                />
                <path
                    id="Path_188"
                    data-name="Path 188"
                    d="M141,322a1.42,1.42,0,1,1,1.41,1.36A1.34,1.34,0,0,1,141,322Zm2.2,0a.79.79,0,1,0-.79.83.77.77,0,0,0,.75-.83Z"
                    fill="#707272"
                />
                <path
                    id="Path_189"
                    data-name="Path 189"
                    d="M145.92,320.68v.59h-.15a.72.72,0,0,0-.77.82v1.28h-.62v-2.65h.6v.39A1,1,0,0,1,145.92,320.68Z"
                    fill="#707272"
                />
                <path
                    id="Path_190"
                    data-name="Path 190"
                    d="M149,319.68v3.68h-.6V323a1.11,1.11,0,0,1-.88.38,1.36,1.36,0,0,1,0-2.72,1.12,1.12,0,0,1,.86.36v-1.36Zm-.61,2.36a.79.79,0,1,0-.79.83.78.78,0,0,0,.81-.87Z"
                    fill="#707272"
                />
                <path
                    id="Path_191"
                    data-name="Path 191"
                    d="M327.84,346.08a5,5,0,0,0,1-.19,3.63,3.63,0,0,0,2.19-1.95,4.55,4.55,0,0,0,.31-2.14v-9.06a5.26,5.26,0,0,0-.18-1.77,3.57,3.57,0,0,0-1-1.51,3.46,3.46,0,0,0-1.71-.82,19.241,19.241,0,0,0-2-.05H297a3.47,3.47,0,0,0-3.44,3.15c0,1.74,0,3.51,0,5.21v5a3.69,3.69,0,0,0,.52,2.25,3.39,3.39,0,0,0,1.73,1.42,6.23,6.23,0,0,0,2.25.23h14.4l11.27.06h0l-11.27.06H296.9a3.56,3.56,0,0,1-1.2-.24,3.82,3.82,0,0,1-1.9-1.54,4.08,4.08,0,0,1-.57-2.43c0-1.65,0-3.33,0-5v-5.24a3.75,3.75,0,0,1,1.2-2.45,3.84,3.84,0,0,1,2.58-1h29.42a16.071,16.071,0,0,1,2.08.07,3.79,3.79,0,0,1,2.91,2.52,5.38,5.38,0,0,1,.19,1.85v9.06a4.58,4.58,0,0,1-.36,2.2,3.64,3.64,0,0,1-2.29,2,2.861,2.861,0,0,1-1.12.31Z"
                    fill="#707272"
                />
                <path
                    id="Path_192"
                    data-name="Path 192"
                    d="M300.44,337.52a2,2,0,0,1,3.47-1.24l-.54.5a1.171,1.171,0,1,0-.91,1.91,1.15,1.15,0,0,0,.91-.44l.54.5a1.84,1.84,0,0,1-1.5.65,1.86,1.86,0,0,1-1.97-1.88Z"
                    fill="#707272"
                />
                <path
                    id="Path_193"
                    data-name="Path 193"
                    d="M304.12,337.94a1.54,1.54,0,1,1,1.54,1.44,1.44,1.44,0,0,1-1.54-1.44Zm2.25,0a.72.72,0,1,0-.71.78.71.71,0,0,0,.71-.78Z"
                    fill="#707272"
                />
                <path
                    id="Path_194"
                    data-name="Path 194"
                    d="M310.6,337.74v1.6h-.81v-1.48c0-.45-.21-.66-.57-.66a.668.668,0,0,0-.67.76v1.38h-.81v-2.8h.77v.33a1.23,1.23,0,0,1,.93-.37,1.1,1.1,0,0,1,1.16,1.24Z"
                    fill="#707272"
                />
                <path
                    id="Path_195"
                    data-name="Path 195"
                    d="M313.14,339.21a1.16,1.16,0,0,1-.66.17.92.92,0,0,1-1.05-1v-1.15H311v-.63h.43v-.68h.81v.68h.7v.63h-.7v1.14a.33.33,0,0,0,.35.37.55.55,0,0,0,.33-.11Z"
                    fill="#707272"
                />
                <path
                    id="Path_196"
                    data-name="Path 196"
                    d="M313.5,335.7a.51.51,0,0,1,1,0,.47.47,0,0,1-.51.47.46.46,0,0,1-.49-.47Zm.1.84h.81v2.8h-.81Z"
                    fill="#707272"
                />
                <path
                    id="Path_197"
                    data-name="Path 197"
                    d="M318,337.74v1.6h-.81v-1.48c0-.45-.21-.66-.57-.66a.668.668,0,0,0-.67.76v1.38h-.82v-2.8h.78v.33a1.23,1.23,0,0,1,.93-.37,1.1,1.1,0,0,1,1.16,1.24Z"
                    fill="#707272"
                />
                <path
                    id="Path_198"
                    data-name="Path 198"
                    d="M321.58,336.54v2.8h-.77V339a1.13,1.13,0,0,1-.87.37,1.11,1.11,0,0,1-1.19-1.26v-1.58h.81V338c0,.47.21.68.57.68s.64-.24.64-.75v-1.39Z"
                    fill="#707272"
                />
                <path
                    id="Path_199"
                    data-name="Path 199"
                    d="M325.07,338.17H323a.74.74,0,0,0,.8.56.94.94,0,0,0,.7-.27l.43.47a1.63,1.63,0,0,1-2.75-1,1.42,1.42,0,0,1,1.49-1.44,1.38,1.38,0,0,1,1.46,1.45A.379.379,0,0,0,325.07,338.17Zm-2.13-.47h1.38a.67.67,0,0,0-.69-.59A.66.66,0,0,0,322.94,337.7Z"
                    fill="#707272"
                />
                <rect
                    id="Rectangle_37"
                    data-name="Rectangle 37"
                    width="38.1"
                    height="17.63"
                    rx="5.43"
                    transform="translate(248.5 328.45)"
                    fill="#707272"
                />
                <path
                    id="Path_200"
                    data-name="Path 200"
                    d="M261.35,339l.29-.63a2.14,2.14,0,0,0,1.2.38c.5,0,.7-.17.7-.39,0-.69-2.12-.22-2.12-1.57,0-.62.5-1.14,1.54-1.14a2.4,2.4,0,0,1,1.27.33l-.26.64a2.11,2.11,0,0,0-1-.29c-.5,0-.69.19-.69.42,0,.67,2.12.21,2.12,1.54,0,.61-.5,1.13-1.55,1.13a2.6,2.6,0,0,1-1.5-.42Z"
                    fill="#fff"
                />
                <path
                    id="Path_201"
                    data-name="Path 201"
                    d="M267.38,337.74v1.6h-.76V339a.9.9,0,0,1-.85.39c-.66,0-1-.36-1-.85s.35-.83,1.2-.83h.65c0-.35-.21-.56-.65-.56a1.4,1.4,0,0,0-.81.26l-.29-.56a2.15,2.15,0,0,1,1.21-.34A1.125,1.125,0,0,1,267.38,337.74Zm-.81.72v-.29H266c-.38,0-.5.14-.5.33s.17.34.46.34A.61.61,0,0,0,266.57,338.46Z"
                    fill="#fff"
                />
                <path
                    id="Path_202"
                    data-name="Path 202"
                    d="M270.84,336.54l-1.18,2.8h-.84l-1.18-2.8h.84l.78,1.91.8-1.91Z"
                    fill="#fff"
                />
                <path
                    id="Path_203"
                    data-name="Path 203"
                    d="M273.83,338.17h-2.12a.73.73,0,0,0,.8.56,1,1,0,0,0,.7-.27l.43.47a1.63,1.63,0,0,1-2.75-1,1.42,1.42,0,0,1,1.49-1.44,1.38,1.38,0,0,1,1.46,1.45C273.84,338,273.83,338.1,273.83,338.17Zm-2.13-.47h1.38a.67.67,0,0,0-.69-.59.66.66,0,0,0-.69.59Z"
                    fill="#fff"
                />
                <path
                    id="Path_204"
                    data-name="Path 204"
                    d="M131.59,265.26v1.2h-.49v-1.21L129.72,263h.53l1.12,1.83,1.11-1.83H133Z"
                    fill="#e4e3e3"
                />
                <path
                    id="Path_205"
                    data-name="Path 205"
                    d="M132.87,265.15a1.37,1.37,0,1,1,1.37,1.34,1.31,1.31,0,0,1-1.37-1.34Zm2.25,0a.89.89,0,1,0-.413.849.89.89,0,0,0,.413-.849Z"
                    fill="#e4e3e3"
                />
                <path
                    id="Path_206"
                    data-name="Path 206"
                    d="M138.71,263.83v2.63h-.45v-.39a1.06,1.06,0,0,1-.89.42,1,1,0,0,1-1.13-1.14v-1.52h.47v1.46c0,.52.26.78.71.78a.78.78,0,0,0,.82-.88v-1.36Z"
                    fill="#e4e3e3"
                />
                <path
                    id="Path_207"
                    data-name="Path 207"
                    d="M141,263.81v.46h-.11a.78.78,0,0,0-.83.88v1.31h-.48v-2.63h.46v.45A1,1,0,0,1,141,263.81Z"
                    fill="#e4e3e3"
                />
                <path
                    id="Path_208"
                    data-name="Path 208"
                    d="M146.05,263v3.47h-.41l-2.08-2.59v2.59h-.5V263h.41l2.09,2.59V263Z"
                    fill="#e4e3e3"
                />
                <path
                    id="Path_209"
                    data-name="Path 209"
                    d="M149.48,263.83v2.63H149v-.39a1.1,1.1,0,0,1-.9.42,1,1,0,0,1-1.13-1.14v-1.52h.48v1.46c0,.52.26.78.71.78a.78.78,0,0,0,.81-.88v-1.36Z"
                    fill="#e4e3e3"
                />
                <path
                    id="Path_210"
                    data-name="Path 210"
                    d="M154.75,265v1.51h-.47V265c0-.52-.25-.77-.68-.77a.76.76,0,0,0-.79.87v1.35h-.48V265c0-.52-.25-.77-.68-.77a.76.76,0,0,0-.79.87v1.35h-.47v-2.63h.45v.4a1.06,1.06,0,0,1,.91-.42,1,1,0,0,1,.91.49,1.19,1.19,0,0,1,1-.49,1,1,0,0,1,1.09,1.2Z"
                    fill="#e4e3e3"
                />
                <path
                    id="Path_211"
                    data-name="Path 211"
                    d="M158.36,265.15a1.28,1.28,0,0,1-1.33,1.34,1.1,1.1,0,0,1-.94-.44v.41h-.45v-3.68h.47v1.45a1.13,1.13,0,0,1,.92-.42,1.28,1.28,0,0,1,1.33,1.34Zm-.48,0a.89.89,0,1,0-.89.93.86.86,0,0,0,.89-.93Z"
                    fill="#e4e3e3"
                />
                <path
                    id="Path_212"
                    data-name="Path 212"
                    d="M161.39,265.31h-2.14a.9.9,0,0,0,1,.77,1,1,0,0,0,.76-.32l.26.31a1.44,1.44,0,0,1-2.45-.92,1.29,1.29,0,0,1,1.32-1.34,1.27,1.27,0,0,1,1.3,1.35A.505.505,0,0,0,161.39,265.31Zm-2.14-.34h1.69a.82.82,0,0,0-.84-.76.83.83,0,0,0-.85.79Z"
                    fill="#e4e3e3"
                />
                <path
                    id="Path_213"
                    data-name="Path 213"
                    d="M163.47,263.81v.46h-.1a.78.78,0,0,0-.83.88v1.31h-.48v-2.63h.46v.45A1,1,0,0,1,163.47,263.81Z"
                    fill="#e4e3e3"
                />
                <rect
                    id="Rectangle_38"
                    data-name="Rectangle 38"
                    width="272.47"
                    height="16.68"
                    transform="translate(101.62 147.51)"
                    fill="#707272"
                />
                <path
                    id="Path_214"
                    data-name="Path 214"
                    d="M117.88,155v1.1h1.93v.77h-1.93v1.52h-1v-4.17h3.16V155Z"
                    fill="#fff"
                />
                <path
                    id="Path_215"
                    data-name="Path 215"
                    d="M120.42,156.35a2.28,2.28,0,1,1,2.28,2.16,2.16,2.16,0,0,1-2.28-2.16Zm3.59,0a1.31,1.31,0,1,0-1.31,1.33,1.27,1.27,0,0,0,1.3-1.33Z"
                    fill="#fff"
                />
                <path
                    id="Path_216"
                    data-name="Path 216"
                    d="M128.37,158.43l-.81-1.16h-.89v1.16h-1v-4.17h1.81c1.11,0,1.81.58,1.81,1.52a1.35,1.35,0,0,1-.86,1.31l.94,1.34Zm-.91-3.38h-.79v1.45h.79c.59,0,.89-.27.89-.72s-.3-.73-.89-.73Z"
                    fill="#fff"
                />
                <path
                    id="Path_217"
                    data-name="Path 217"
                    d="M133.89,158.43v-2.5L132.65,158h-.43L131,156v2.45h-.9v-4.17h.8l1.56,2.59,1.54-2.59h.79v4.17Z"
                    fill="#fff"
                />
                <path
                    id="Path_218"
                    data-name="Path 218"
                    d="M374,147.94H101.82l.15-.16c0,63.51-.06,136.06-.09,212.77h0l-.17-.18,272.19.18-.14.14c.15-128.44.25-211.6.25-212.79V360.83h-.14l-272.19-.11h-.18v-.2c0-76.71.09-149.26.13-212.77v-.15h.16Z"
                    fill="#707272"
                />
                <path
                    id="Path_219"
                    data-name="Path 219"
                    d="M388.73,210.2s-5.35-.86-8.46,1.63a12.91,12.91,0,0,0-4.9,9c-.39,3.42-2.86,16.64-2.86,16.64L367.68,231l-5.34,4.68s4.51,21.57,8.73,23,8.81-3.29,9.9-4.14S388.73,210.2,388.73,210.2Z"
                    fill="#707272"
                />
                <path
                    id="Path_220"
                    data-name="Path 220"
                    d="M386.92,210.06s-7,10-7.83,12.58-6.74,7.19-6.63,13.25a58.909,58.909,0,0,0,.74,8.26,12.65,12.65,0,0,1-.09,4.67L370.37,262a8,8,0,0,0,6,3.49c4.3.47,15.27,3.05,22.55,0l5.23-2.56V242.52l3.61-8.37,10.25-16.62a46.191,46.191,0,0,0-10.12-5.2C402.79,210.68,394.49,210.54,386.92,210.06Z"
                    fill="#707272"
                />
                <g id="Group_1" data-name="Group 1" opacity="0.5">
                    <path
                        id="Path_221"
                        data-name="Path 221"
                        d="M370.92,259.4a2.626,2.626,0,0,1,.06-.46l.24-1.31c.23-1.17.55-2.81.95-4.82.17-1,.5-2.11.67-3.31.05-.3.09-.61.14-.92a9.651,9.651,0,0,0,.08-1,16.161,16.161,0,0,0,.05-2,17.109,17.109,0,0,0-.71-4.19,14.289,14.289,0,0,1-.78-4.58,13.09,13.09,0,0,1,1.15-4.5,29.234,29.234,0,0,1,2-3.82c1.45-2.33,2.92-4.32,4.13-6l2.87-4,.78-1.08a2.779,2.779,0,0,1,.29-.37,2,2,0,0,1-.24.41c-.18.28-.42.65-.73,1.11-.64,1-1.59,2.35-2.79,4.05s-2.63,3.7-4.06,6a30.508,30.508,0,0,0-2,3.78,13,13,0,0,0-1.12,4.39,14.331,14.331,0,0,0,.77,4.48,18.119,18.119,0,0,1,.7,4.27,16.788,16.788,0,0,1-.06,2l-.09,1c-.05.31-.11.62-.16.92-.17,1.21-.52,2.3-.71,3.32-.44,2-.79,3.63-1.05,4.8-.12.54-.22,1-.3,1.3a2.47,2.47,0,0,1-.08.53Z"
                        fill="#263238"
                    />
                </g>
                <path
                    id="Path_222"
                    data-name="Path 222"
                    d="M414.62,215.4s12.79,4.58,7.93,20.53c-3.86,12.7-11.81,27.85-16.83,28.15-2.61.16-5.46.06-7.23-1.34-7.19-5.67-11.57-20.86-11.57-20.86l6.88-7.68,8.34,12.35c.93-.4,6.48-17.5,6.48-17.5S412.61,215.94,414.62,215.4Z"
                    fill="#707272"
                />
                <path
                    id="Path_223"
                    data-name="Path 223"
                    d="M408.54,230.48a1.26,1.26,0,0,1-.09.3l-.3.88L407,235c-1.07,2.86-2.57,6.91-4.36,11.74l-.1.28-.17-.25c-2.46-3.63-5.32-7.85-8.35-12.34h.26l-.84.86L387,241.77v-.17a79.376,79.376,0,0,0,3.33,9.13,41.243,41.243,0,0,0,3.7,6.72,34.591,34.591,0,0,0,3.13,4l.94,1a2.191,2.191,0,0,1,.31.35,2.191,2.191,0,0,1-.35-.31l-1-.93a33.679,33.679,0,0,1-3.22-3.93,42.11,42.11,0,0,1-3.79-6.72,80,80,0,0,1-3.38-9.14v-.1l.07-.07,6.34-6.49.85-.87.14-.15.12.18,8.32,12.36h-.27c1.84-4.81,3.39-8.84,4.48-11.7.54-1.39,1-2.49,1.28-3.27.14-.36.26-.65.35-.86A.845.845,0,0,1,408.54,230.48Z"
                    fill="#263238"
                    opacity="0.5"
                />
                <path
                    id="Path_224"
                    data-name="Path 224"
                    d="M377.43,398l.85,13s-12.92,5.23-13,7.65l25.26-.75-.5-20.3Z"
                    fill="#707272"
                />
                <path
                    id="Path_225"
                    data-name="Path 225"
                    d="M386.06,409.64a.97.97,0,0,1-.39,1.9,1.09,1.09,0,0,1-.79-1.23,1,1,0,0,1,1.27-.65"
                    fill="#e0e0e0"
                />
                <path
                    id="Path_226"
                    data-name="Path 226"
                    d="M390.58,418.75l-.05-2.33-24.29.88s-1.11.52-1,1.31Z"
                    fill="#545554"
                />
                <path
                    id="Path_227"
                    data-name="Path 227"
                    d="M377.87,410.84c0,.13.62.17,1.24.54s1,.89,1.07.84-.09-.76-.85-1.21S377.84,410.73,377.87,410.84Z"
                    fill="#263238"
                />
                <path
                    id="Path_228"
                    data-name="Path 228"
                    d="M375.25,412c0,.12.52.31.95.82s.57,1.06.7,1.05.18-.71-.38-1.33S375.25,411.9,375.25,412Z"
                    fill="#263238"
                />
                <path
                    id="Path_229"
                    data-name="Path 229"
                    d="M373.84,415.23c.11,0,.27-.59-.07-1.26s-.92-.92-1-.81.32.45.59,1S373.72,415.22,373.84,415.23Z"
                    fill="#263238"
                />
                <path
                    id="Path_230"
                    data-name="Path 230"
                    d="M378,407.86c.06.11.62-.07,1.32-.05s1.25.23,1.31.13-.48-.54-1.3-.56S377.94,407.77,378,407.86Z"
                    fill="#263238"
                />
                <path
                    id="Path_231"
                    data-name="Path 231"
                    d="M400.7,398.22l.74,13s-13,5.12-13.06,7.53l25.26-.53-.33-20.3Z"
                    fill="#707272"
                />
                <path
                    id="Path_232"
                    data-name="Path 232"
                    d="M409.23,410a.972.972,0,0,1-.41,1.9,1.1,1.1,0,0,1-.78-1.23,1,1,0,0,1,1.28-.64"
                    fill="#e0e0e0"
                />
                <path
                    id="Path_233"
                    data-name="Path 233"
                    d="M413.67,418.75v-2l-24.29.66s-1.12.52-1,1.3Z"
                    fill="#545554"
                />
                <path
                    id="Path_234"
                    data-name="Path 234"
                    d="M401,411.1c0,.12.62.17,1.24.55s1,.9,1.06.84a1.486,1.486,0,0,0-.84-1.21C401.71,410.83,401,411,401,411.1Z"
                    fill="#263238"
                />
                <path
                    id="Path_235"
                    data-name="Path 235"
                    d="M398.4,412.25c0,.12.51.32.94.83s.57,1.06.69,1.06.19-.71-.36-1.34S398.4,412.13,398.4,412.25Z"
                    fill="#263238"
                />
                <path
                    id="Path_236"
                    data-name="Path 236"
                    d="M397,415.45c.12,0,.27-.59-.06-1.26s-.91-.93-1-.82.31.45.58,1S396.84,415.44,397,415.45Z"
                    fill="#263238"
                />
                <path
                    id="Path_237"
                    data-name="Path 237"
                    d="M401.19,408.12c.05.11.61-.07,1.31,0s1.25.24,1.31.13-.47-.53-1.29-.56S401.12,408,401.19,408.12Z"
                    fill="#263238"
                />
                <path
                    id="Path_238"
                    data-name="Path 238"
                    d="M404.55,262.53a54.83,54.83,0,0,1,9.81,15.06c3.38,8.32,1.9,48.33,1.9,48.33l-.71,21s1,18,.24,30.89-1.65,27.1-1.65,27.1l-14,.67-14.88-109L389,346.82c2.37,12.82,2.09,35.44,1.63,58.73H375.72s-5.11-34.8-6.28-43.07c-1.32-9.34-5.21-35-5.21-35s-3-24.59-2-37.84,8.93-31.77,9.19-32.55l31.73,5.72"
                    fill="#545554"
                />
                <path
                    id="Path_239"
                    data-name="Path 239"
                    d="M406.17,259l3.72,9.9s27.66,38.58,26.86,41.8-15.88,15.68-20.3,13.67-15.88-10.25-19.7-18.49-14.67-31.36-14.67-31.36l-3.41-6.43,3.81-2.61Z"
                    fill="#707272"
                />
                <path
                    id="Path_240"
                    data-name="Path 240"
                    d="M371.22,257.63h0c3.35,1.8,3,7.42,6.35,9.22-5.6,7.83-7.51,17.69-8.27,27.28s-.53,19.31-2.45,28.74l-6.94-.49a163.15,163.15,0,0,1,5.92-55A24.239,24.239,0,0,1,371.22,257.63Z"
                    fill="#707272"
                />
                <path
                    id="Path_241"
                    data-name="Path 241"
                    d="M398.28,273.87a8.57,8.57,0,0,0,1.75-.41A36.623,36.623,0,0,0,404.1,272a37.256,37.256,0,0,0,3.87-1.94,8.7,8.7,0,0,0,1.5-1,10,10,0,0,0-1.62.77c-1,.51-2.34,1.18-3.89,1.84s-3,1.2-4,1.56A10.417,10.417,0,0,0,398.28,273.87Z"
                    fill="#263238"
                />
                <path
                    id="Path_242"
                    data-name="Path 242"
                    d="M416.21,303.24l.67.05a16.31,16.31,0,0,1-18.17,3.34c-2-.95-4-2.71-4-4.95a40.71,40.71,0,0,0,21.5,1.56Z"
                    opacity="0.5"
                />
                <path
                    id="Path_243"
                    data-name="Path 243"
                    d="M381.67,268.13c2.59-.48,7.55.88,9.37,2.78a19.08,19.08,0,0,1,3.8,6.84c1.94,5.35,3.32,11.29,7.53,15.12,4,3.61,9.59,4.58,14.89,5.4.14,2,.12,4.28-1.49,5.41a5.36,5.36,0,0,1-1.6.66,26.211,26.211,0,0,1-4.81.85c-7.54.62-15.53-2.41-20-8.55-5.6-7.76-6-17.09-9.21-26.12"
                    fill="#707272"
                />
                <g id="Group_2" data-name="Group 2" opacity="0.5">
                    <path
                        id="Path_244"
                        data-name="Path 244"
                        d="M380.19,270.52a2.62,2.62,0,0,0,.08.28l.26.83c.23.72.55,1.8.92,3.19s.8,3.11,1.28,5.1,1,4.27,1.79,6.74a37.339,37.339,0,0,0,3.3,7.78,22.829,22.829,0,0,0,2.74,3.85,19.451,19.451,0,0,0,3.79,3.28,23.55,23.55,0,0,0,10,3.67,25.143,25.143,0,0,0,5.69.06,28.829,28.829,0,0,0,2.88-.48c.48-.11.95-.23,1.43-.36a5.459,5.459,0,0,0,1.4-.58,3.3,3.3,0,0,0,1.08-1.18,5.279,5.279,0,0,0,.5-1.48,12.359,12.359,0,0,0,.07-3v-.13h-.14a43.17,43.17,0,0,1-10.09-2.43,16.332,16.332,0,0,1-4.2-2.48,15.37,15.37,0,0,1-3.07-3.49,35.147,35.147,0,0,1-3.53-7.87,67.18,67.18,0,0,0-2.65-7.05,16.419,16.419,0,0,0-1.68-2.82,11.225,11.225,0,0,0-1-1.15,5.419,5.419,0,0,0-1.13-.87,14.1,14.1,0,0,0-4.35-1.64,12.2,12.2,0,0,0-2.91-.3,4.71,4.71,0,0,0-1,.13,6.577,6.577,0,0,1,1-.06,13.209,13.209,0,0,1,2.89.35,14.639,14.639,0,0,1,4.28,1.67,6.683,6.683,0,0,1,1.09.85,12,12,0,0,1,.95,1.14,16.4,16.4,0,0,1,1.65,2.8,71.9,71.9,0,0,1,2.59,7,35.581,35.581,0,0,0,3.53,7.95,15.749,15.749,0,0,0,3.13,3.57,16.932,16.932,0,0,0,4.27,2.54,44,44,0,0,0,10.17,2.46l-.14-.16a12.1,12.1,0,0,1-.07,2.88,3.5,3.5,0,0,1-1.43,2.43,9.451,9.451,0,0,1-2.72.89A25.345,25.345,0,0,1,410,305a24.443,24.443,0,0,1-5.61-.06,23.32,23.32,0,0,1-9.91-3.59,19.809,19.809,0,0,1-3.74-3.22,22.371,22.371,0,0,1-2.71-3.79,36.911,36.911,0,0,1-3.32-7.72c-.77-2.45-1.33-4.72-1.83-6.71s-.94-3.7-1.34-5.09-.73-2.46-1-3.17l-.29-.82a1,1,0,0,0-.06-.31Z"
                        fill="#263238"
                    />
                </g>
                <g id="Group_3" data-name="Group 3" opacity="0.5">
                    <path
                        id="Path_245"
                        data-name="Path 245"
                        d="M411.35,304.83a10.59,10.59,0,0,1-.48-3.82,10.48,10.48,0,0,1,1.26-3.64,3.631,3.631,0,0,0-.73.91,6.909,6.909,0,0,0-.87,2.69,6.81,6.81,0,0,0,.3,2.82,2.581,2.581,0,0,0,.52,1.04Z"
                        fill="#263238"
                    />
                </g>
                <g id="Group_4" data-name="Group 4" opacity="0.5">
                    <path
                        id="Path_246"
                        data-name="Path 246"
                        d="M411.16,300a13.491,13.491,0,0,0,3,.37,12.419,12.419,0,0,0,3,0,13.489,13.489,0,0,0-3-.37,12.419,12.419,0,0,0-3,0Z"
                        fill="#263238"
                    />
                </g>
                <g id="Group_5" data-name="Group 5" opacity="0.5">
                    <path
                        id="Path_247"
                        data-name="Path 247"
                        d="M410.61,302.17a27.109,27.109,0,0,0,6.15.13,14.441,14.441,0,0,0-3.07-.24,14.6,14.6,0,0,0-3.08.11Z"
                        fill="#263238"
                    />
                </g>
                <path
                    id="Path_248"
                    data-name="Path 248"
                    d="M375.32,269.81c-4.63,4.18-6.36,10.81-6.19,17s2,12.29,3.37,18.37,2.37,12.47.87,18.52l5.94.85a24.9,24.9,0,0,0,6.58-19.44c-.61-5.82-3.25-11.19-5.14-16.72s-3-11.77-.63-17.11"
                    fill="#707272"
                />
                <g id="Group_6" data-name="Group 6" opacity="0.5">
                    <path
                        id="Path_249"
                        data-name="Path 249"
                        d="M380.12,271.32a1.632,1.632,0,0,0-.13.26c-.08.19-.18.45-.32.78a17,17,0,0,0-.8,3.16,21.17,21.17,0,0,0-.05,5.15,34.051,34.051,0,0,0,1.45,6.69c.76,2.42,1.79,5,2.83,7.81a44.3,44.3,0,0,1,2.53,9.16,24.32,24.32,0,0,1-.85,10.4,24.851,24.851,0,0,1-5.59,9.75l.14-.06-2.91-.41-3-.44.14.22A31.61,31.61,0,0,0,374,312.4a90.888,90.888,0,0,0-2.1-10.4c-.8-3.26-1.56-6.36-2-9.3a40.622,40.622,0,0,1-.58-8.23,23.25,23.25,0,0,1,3.3-11.3,18,18,0,0,1,2-2.56l.59-.6.2-.21s-.08.06-.22.18-.35.34-.62.58a16.562,16.562,0,0,0-2,2.54A23,23,0,0,0,369,284.47a38.809,38.809,0,0,0,.54,8.28c.47,3,1.22,6.07,2,9.34a89.374,89.374,0,0,1,2.06,10.35,31.539,31.539,0,0,1-.44,11.26v.19h.19l3,.44,2.92.41h.08l.07-.07a25.121,25.121,0,0,0,5.66-9.88,24.8,24.8,0,0,0,.84-10.54,43.578,43.578,0,0,0-2.58-9.22c-1.05-2.8-2.09-5.38-2.86-7.78a35.542,35.542,0,0,1-1.5-6.64,21.759,21.759,0,0,1,0-5.12,17.733,17.733,0,0,1,.73-3.15c.13-.33.22-.59.3-.78Z"
                        fill="#263238"
                    />
                </g>
                <g id="Group_7" data-name="Group 7" opacity="0.5">
                    <path
                        id="Path_250"
                        data-name="Path 250"
                        d="M374.34,319a28.8,28.8,0,0,1,4.15.08,28.081,28.081,0,0,1,4,1,3.2,3.2,0,0,0-1.09-.59,11.14,11.14,0,0,0-2.89-.77,11.441,11.441,0,0,0-3,0,3.551,3.551,0,0,0-1.17.28Z"
                        fill="#263238"
                    />
                </g>
                <g id="Group_8" data-name="Group 8" opacity="0.5">
                    <path
                        id="Path_251"
                        data-name="Path 251"
                        d="M377.8,324.44a22,22,0,0,0,2.48-4.86,10.68,10.68,0,0,0-1.39,2.35,10.54,10.54,0,0,0-1.09,2.51Z"
                        fill="#263238"
                    />
                </g>
                <g id="Group_9" data-name="Group 9" opacity="0.5">
                    <path
                        id="Path_252"
                        data-name="Path 252"
                        d="M374.7,324a12.29,12.29,0,0,0,1.28-2.53,11.528,11.528,0,0,0,1-2.66,12,12,0,0,0-1.28,2.52A11.508,11.508,0,0,0,374.7,324Z"
                        fill="#263238"
                    />
                </g>
                <g id="Group_10" data-name="Group 10" opacity="0.5">
                    <path
                        id="Path_253"
                        data-name="Path 253"
                        d="M383.22,267.87a.8.8,0,0,0,.18-.4,1.9,1.9,0,0,0-.8-1.86c-.23-.15-.4-.17-.42-.13a4.131,4.131,0,0,1,.77,1.08C383.23,267.23,383.12,267.84,383.22,267.87Z"
                        fill="#263238"
                    />
                </g>
                <path
                    id="Path_254"
                    data-name="Path 254"
                    d="M375.5,266.42c.8-1.26,2.7-1.69,4.18-1.86a3.64,3.64,0,0,1,3.75,3.2,4,4,0,0,1-2.92,4.1,4.5,4.5,0,0,1-4.77-1.87A3.36,3.36,0,0,1,375.5,266.42Z"
                    fill="#545554"
                />
                <g id="Group_11" data-name="Group 11" opacity="0.5">
                    <path
                        id="Path_255"
                        data-name="Path 255"
                        d="M378.83,265.26a2.14,2.14,0,0,0-.79.93,4,4,0,0,0,1.42,5.29,2.22,2.22,0,0,0,1.15.42c0-.06-.43-.19-1-.62a4,4,0,0,1-1.33-5C378.55,265.64,378.87,265.3,378.83,265.26Z"
                        fill="#263238"
                    />
                </g>
                <g id="Group_12" data-name="Group 12" opacity="0.5">
                    <path
                        id="Path_256"
                        data-name="Path 256"
                        d="M375.87,266a1.718,1.718,0,0,0-.48.62,3.56,3.56,0,0,0-.31,1.87,3.48,3.48,0,0,0,.7,1.77c.3.38.57.54.6.51a4.815,4.815,0,0,0-.4-.65,4,4,0,0,1-.55-1.67,4.16,4.16,0,0,1,.19-1.74C375.76,266.23,375.92,266,375.87,266Z"
                        fill="#263238"
                    />
                </g>
                <path
                    id="Path_257"
                    data-name="Path 257"
                    d="M375.41,269.8a6.15,6.15,0,0,0,4.52,2.05,3.11,3.11,0,0,1-2.34,1.62,2.4,2.4,0,0,1-2.18-3.67Z"
                    opacity="0.5"
                />
                <path
                    id="Path_258"
                    data-name="Path 258"
                    d="M396.55,275.62a5.31,5.31,0,0,1,2.48-1.45c3.44-1.24,7.15-3.36,10.59-4.61-3.14,2.79-6.54,6.07-10.14,8.22-.87.52-2,1-2.83.43A1.85,1.85,0,0,1,396.55,275.62Z"
                    opacity="0.5"
                />
                <path
                    id="Path_259"
                    data-name="Path 259"
                    d="M368.05,281.8a15.85,15.85,0,0,1,5.78-10.7c-1.79,3.26-3.6,6.58-4.4,10.22-.88,4-.24,6.55.16,10.62l-.38,2a30.11,30.11,0,0,1-1.16-12.14Z"
                    opacity="0.5"
                />
                <path
                    id="Path_260"
                    data-name="Path 260"
                    d="M398.4,278.47a1.73,1.73,0,0,0,.54-.26c.33-.18.8-.47,1.37-.85a41.75,41.75,0,0,0,8.05-6.95,10.622,10.622,0,0,0,1-1.23c.19-.3.36-.48.34-.5a20.145,20.145,0,0,0-1.56,1.56,51.947,51.947,0,0,1-3.85,3.64c-1.57,1.35-3.06,2.49-4.16,3.28A15.75,15.75,0,0,0,398.4,278.47Z"
                    fill="#263238"
                />
                <path
                    id="Path_261"
                    data-name="Path 261"
                    d="M381.41,178.54a4.8,4.8,0,0,1,1.1-5.59,7.86,7.86,0,0,1,5.67-1.9,23.241,23.241,0,0,1,6,1.24,6.53,6.53,0,0,1,11.45,2.16,4.14,4.14,0,0,1,5.57,4.77l-8.65,4.36-7.65,18.71a8,8,0,0,1-13-9,6.58,6.58,0,0,1-1.62-1.54,7,7,0,0,1-3.76-3.87,7.39,7.39,0,0,1,4.89-9.34Z"
                    fill="#707272"
                />
                <path
                    id="Path_262"
                    data-name="Path 262"
                    d="M392.19,212.3a29.337,29.337,0,0,1,.27-3.1s-5.61-1.38-6.21-8.9c-.3-3.74.35-9.78,1.05-14.7.63-4.44,4-9.67,8.41-8.86l13.58,4.59c1.34.24,1.72,1.42,1.59,2.77l.06.65L408.64,206l-.71,6.37c-.42,4-4,8.14-8.1,8.08h0a7.58,7.58,0,0,1-7.64-8.15Z"
                    fill="#e4e3e3"
                />
                <path
                    id="Path_263"
                    data-name="Path 263"
                    d="M388.72,192.38a1,1,0,0,0,.84,1,.93.93,0,0,0,1.05-.79,1,1,0,0,0-.84-1,.92.92,0,0,0-1.05.79Z"
                    fill="#707272"
                />
                <path
                    id="Path_264"
                    data-name="Path 264"
                    d="M388.55,189.6c.11.13.87-.34,1.89-.26s1.74.65,1.86.54,0-.29-.35-.55a2.62,2.62,0,0,0-1.48-.59,2.55,2.55,0,0,0-1.52.37C388.62,189.32,388.5,189.54,388.55,189.6Z"
                    fill="#707272"
                />
                <path
                    id="Path_265"
                    data-name="Path 265"
                    d="M397.55,192.9a1,1,0,0,0,.84,1,.91.91,0,0,0,1.05-.78,1,1,0,0,0-.84-1,.92.92,0,0,0-1.05.78Z"
                    fill="#707272"
                />
                <path
                    id="Path_266"
                    data-name="Path 266"
                    d="M398.35,190.17c.11.13.87-.35,1.9-.26s1.73.65,1.85.53,0-.28-.35-.55a2.66,2.66,0,0,0-1.47-.58,2.57,2.57,0,0,0-1.53.36C398.42,189.89,398.3,190.11,398.35,190.17Z"
                    fill="#707272"
                />
                <path
                    id="Path_267"
                    data-name="Path 267"
                    d="M394.12,197.13a7.829,7.829,0,0,0-1.65-.43c-.25,0-.5-.11-.53-.3a1.34,1.34,0,0,1,.24-.76c.29-.61.6-1.26.93-1.93a34.8,34.8,0,0,0,2.14-5.08,35.184,35.184,0,0,0-2.55,4.89l-.9,1.95a1.55,1.55,0,0,0-.21,1,.63.63,0,0,0,.4.41,1.609,1.609,0,0,0,.44.1,6.289,6.289,0,0,0,1.69.15Z"
                    fill="#707272"
                />
                <path
                    id="Path_268"
                    data-name="Path 268"
                    d="M392.46,209.2a18.88,18.88,0,0,0,10.15-1.87s-2.91,5-10.29,3.77Z"
                    fill="#707272"
                />
                <path
                    id="Path_269"
                    data-name="Path 269"
                    d="M398.56,187.39c.1.28,1.13.14,2.33.27s2.19.46,2.35.21-.1-.39-.5-.65a4,4,0,0,0-3.56-.37C398.73,187,398.51,187.25,398.56,187.39Z"
                    fill="#707272"
                />
                <path
                    id="Path_270"
                    data-name="Path 270"
                    d="M388.75,186.61c.2.25,1.05.07,2.06.14s1.84.3,2.05.07,0-.35-.37-.6a3.18,3.18,0,0,0-1.64-.51,3.1,3.1,0,0,0-1.68.34C388.8,186.26,388.67,186.49,388.75,186.61Z"
                    fill="#707272"
                />
                <path
                    id="Path_271"
                    data-name="Path 271"
                    d="M405.53,176.93a5.09,5.09,0,0,1-6.38,4.56c-1.11-.29-2.31-1-3.34-.43s-1.24,2.11-2.22,2.84c-1.4,1-3.4-.19-5.1.18-1.31.28-2.2,1.45-3.13,2.42s-2.29,1.85-3.54,1.37a15,15,0,0,1,.77-3.39,5.1,5.1,0,0,1,2.15-2.64,18.136,18.136,0,0,0,1.72-.83c1.13-.78,1.49-2.26,2.24-3.41a5.58,5.58,0,0,1,4.45-2.49c1.47,0,3,.51,4.4.26,1.07-.18,2-.8,3.12-.92,2.11-.24,3.93,1.4,5.32,3"
                    fill="#707272"
                />
                <path
                    id="Path_272"
                    data-name="Path 272"
                    d="M398.66,179.38c.95-.2,2.9-.65,3.46-1.42a5.68,5.68,0,0,1,5.17-2.47,4.7,4.7,0,0,1,3.94,4,6.79,6.79,0,0,1,5.53,4.18A7.84,7.84,0,0,1,416,191a14.325,14.325,0,0,0-.89,1.74,11.588,11.588,0,0,1,1.43,1.65c2,3.2-2,5-2,5,.28,2.28-1.82,2.54-3.17,2.71s-1.63,3.87-2.7,3.86l-.37-11.25a1.43,1.43,0,0,1-.48.15c-2.54.38-2.5-4.52-1.84-5.87a15,15,0,0,1-2.31-2.82,10.521,10.521,0,0,1-.76-5.13C402,181,398.24,179.47,398.66,179.38Z"
                    fill="#707272"
                />
                <path
                    id="Path_273"
                    data-name="Path 273"
                    d="M408,195.35a2.17,2.17,0,0,1,3.31-1.6c.72.49,1.23,1.41,1,3.08-.52,4.46-4.89,3-4.88,2.88S407.73,197.19,408,195.35Z"
                    fill="#e4e3e3"
                />
                <path
                    id="Path_274"
                    data-name="Path 274"
                    d="M409,198.18a1.015,1.015,0,0,0,.19.14.76.76,0,0,0,.58.08,1.94,1.94,0,0,0,1.1-1.62,2.849,2.849,0,0,0-.09-1.14.89.89,0,0,0-.52-.66.4.4,0,0,0-.48.16c-.07.12-.06.21-.08.21s-.09-.08,0-.26a.46.46,0,0,1,.21-.26.55.55,0,0,1,.44-.06,1.09,1.09,0,0,1,.73.78,2.58,2.58,0,0,1,.12,1.28,2,2,0,0,1-1.35,1.79.82.82,0,0,1-.7-.2C409,198.29,409,198.19,409,198.18Z"
                    fill="#707272"
                />
                <path
                    id="Path_275"
                    data-name="Path 275"
                    d="M384,192.32a13.428,13.428,0,0,1-1.07-.85,6.43,6.43,0,0,1-1.78-3.18,6.209,6.209,0,0,1-.08-2.52,5.35,5.35,0,0,1,1.22-2.58,6.34,6.34,0,0,1,6-2,4.771,4.771,0,0,1,.62.17l.56.23.29.11v-.29a2.19,2.19,0,0,1,.36-1.48,3.43,3.43,0,0,1,1.13-1.05,4.6,4.6,0,0,1,2.85-.59c1.91.18,3.59.83,5.09.81a4.81,4.81,0,0,0,3.26-1.11,2.6,2.6,0,0,0,.57-.76,1.317,1.317,0,0,0,.11-.31,3.821,3.821,0,0,1-.77,1,4.76,4.76,0,0,1-3.17,1,12.38,12.38,0,0,1-2.33-.36,20.379,20.379,0,0,0-2.73-.52,6.15,6.15,0,0,0-1.54,0,4.84,4.84,0,0,0-1.52.57,3.87,3.87,0,0,0-1.24,1.15,2.54,2.54,0,0,0-.42,1.71l.26-.18L389,181c-.21-.06-.43-.14-.65-.18a6.64,6.64,0,0,0-6.33,2.14,5.59,5.59,0,0,0-1.27,2.74,6.58,6.58,0,0,0,2.06,5.85,4.912,4.912,0,0,0,.84.6A1.58,1.58,0,0,0,384,192.32Z"
                    fill="#545554"
                />
                <path
                    id="Path_276"
                    data-name="Path 276"
                    d="M382.92,177a5.089,5.089,0,0,1,1.33-.66,3.65,3.65,0,0,1,1.67-.06,3.29,3.29,0,0,1,1.86,1l.2.22.06-.28a5.09,5.09,0,0,1,3.7-3.57,6.56,6.56,0,0,1,4.08.63,17.19,17.19,0,0,0,3.33,1.2,5,5,0,0,0,2.4,0,3,3,0,0,0,.6-.26.59.59,0,0,0,.19-.13,4.751,4.751,0,0,1-.83.25,5.12,5.12,0,0,1-2.31-.08A19.742,19.742,0,0,1,396,174a10.442,10.442,0,0,0-2-.68,5.06,5.06,0,0,0-2.29,0,5.31,5.31,0,0,0-3.91,3.84l.26-.07a3.52,3.52,0,0,0-2.05-1.06,3.8,3.8,0,0,0-1.76.14,3.3,3.3,0,0,0-1,.54A3.128,3.128,0,0,0,382.92,177Z"
                    fill="#545554"
                />
                <path
                    id="Path_277"
                    data-name="Path 277"
                    d="M394.76,199.3a1.47,1.47,0,0,1,1.13-.5,1.78,1.78,0,0,1,1.24.68h0l.05.05h0a.81.81,0,0,1,.19.49,1.21,1.21,0,0,1-.58,1,1.55,1.55,0,0,1-1.79,0A1.27,1.27,0,0,1,394.76,199.3Z"
                    fill="#707272"
                />
                <path
                    id="Path_278"
                    data-name="Path 278"
                    d="M397.41,198.46c-.06,0-.15.22-.37.53a2.53,2.53,0,0,1-1.12.89,2.49,2.49,0,0,1-1.43.1c-.38-.08-.6-.19-.63-.14s.14.25.54.41a2.29,2.29,0,0,0,1.66,0,2.24,2.24,0,0,0,1.22-1.13C397.47,198.74,397.46,198.47,397.41,198.46Z"
                    fill="#707272"
                />
                <path
                    id="Path_279"
                    data-name="Path 279"
                    d="M414.59,186.14a.94.94,0,0,0,.2-.14c.12-.11.29-.27.5-.5a5.22,5.22,0,0,0,1.2-2.29,4.09,4.09,0,0,0,0-1.81,3.75,3.75,0,0,0-1-1.82,3,3,0,0,0-2.12-.86,2.8,2.8,0,0,0-2.22,1.05l.21.11h0a6.15,6.15,0,0,0-.13-4.67,4,4,0,0,0-3-2.3,3.32,3.32,0,0,0-2.48.7,2.942,2.942,0,0,0-.48.53,1.649,1.649,0,0,0-.12.21,4.886,4.886,0,0,1,.66-.67,3.29,3.29,0,0,1,2.4-.61,3.92,3.92,0,0,1,2.84,2.23,6,6,0,0,1,.09,4.51h0l-.23.7.43-.59a2.58,2.58,0,0,1,2-1,2.78,2.78,0,0,1,2,.79,3.54,3.54,0,0,1,1,1.71,4.061,4.061,0,0,1,0,1.73,5.391,5.391,0,0,1-1.11,2.26C414.83,185.91,414.57,186.13,414.59,186.14Z"
                    fill="#263238"
                />
                <path
                    id="Path_280"
                    data-name="Path 280"
                    d="M350.58,227.15l4.33,16.35a2,2,0,0,0,2,1.51l22.42-.44a2,2,0,0,0,1.95-2.45L378,226.46a2,2,0,0,0-2-1.62H352.35a1.84,1.84,0,0,0-1.77,2.31Z"
                    fill="#545554"
                />
                <path
                    id="Path_281"
                    data-name="Path 281"
                    d="M348.69,230.84a1.65,1.65,0,0,1,0-2,2.51,2.51,0,0,1,1.93-.91,5.34,5.34,0,0,1,2.14.45,13,13,0,0,1,2.42,1.21,2.27,2.27,0,0,1,1.21,1.41c.11.63-.58,1.35-1.13,1a2.67,2.67,0,0,1,1.56,1.87c.12.8-.31,1.23-1.08,1.46a1.2,1.2,0,0,1,.7,1.53,1.38,1.38,0,0,1-1.73.53,4,4,0,0,1-1.51-1.26,3.71,3.71,0,0,1,.43,2.53,4.93,4.93,0,0,1-3.24-4,2.3,2.3,0,0,1-.63-2.82,2.38,2.38,0,0,1-1.41-2"
                    fill="#e4e3e3"
                />
                <path
                    id="Path_282"
                    data-name="Path 282"
                    d="M355.72,235.33a5.2,5.2,0,0,1-.74-.56,5.15,5.15,0,0,0-2-1,2.75,2.75,0,0,0-1.18,0,2.09,2.09,0,0,0-.87.43,2.59,2.59,0,0,0-.56.71.656.656,0,0,1,.07-.25,1.6,1.6,0,0,1,.41-.56,2.1,2.1,0,0,1,.9-.5,2.709,2.709,0,0,1,1.27,0,4.55,4.55,0,0,1,2,1.08,4,4,0,0,1,.7.65Z"
                    fill="#707272"
                />
                <path
                    id="Path_283"
                    data-name="Path 283"
                    d="M355.53,232.08a3.742,3.742,0,0,1-.9-.37,9.851,9.851,0,0,0-.95-.42,8.159,8.159,0,0,0-1.23-.32,3.751,3.751,0,0,0-1.25,0,2.29,2.29,0,0,0-.92.4,2.33,2.33,0,0,0-.6.72.48.48,0,0,1,.07-.25,1.61,1.61,0,0,1,.45-.57,2.26,2.26,0,0,1,1-.47,3.39,3.39,0,0,1,1.31,0,7.208,7.208,0,0,1,1.26.34,7.721,7.721,0,0,1,.95.46A7.369,7.369,0,0,1,355.53,232.08Z"
                    fill="#707272"
                />
                <path
                    id="Path_284"
                    data-name="Path 284"
                    d="M365.32,232.75h.13a1.14,1.14,0,0,1,1.19,1,1.1,1.1,0,0,1-2.17.33A1.14,1.14,0,0,1,365.32,232.75Z"
                    fill="#fff"
                />
                <path
                    id="Path_285"
                    data-name="Path 285"
                    d="M387.1,227.13s-2.37-4.64-3.08-4.37-1.62.29-1.17,2,1.62,3,1.55,4.34-1.15,4.47.05,5.91a39.738,39.738,0,0,1,3.22,5.77l5.27-5.37-1.18-1.73a18,18,0,0,0,2.36-4.33,9.651,9.651,0,0,0-.19-3.84,3.81,3.81,0,0,0-.58-2.34,17.355,17.355,0,0,0,.11-2.13c0-1.08-2.8-5.5-2.8-5.5a1.61,1.61,0,0,0-.94,2,20.517,20.517,0,0,0,1.09,2.74l-3.23-1.24a1.81,1.81,0,0,0,.87,2.5c1.72.93,2,1.71,2,1.71l-2.47.18s-.22,1.37.76,1.65l1,.28Z"
                    fill="#e4e3e3"
                />
                <path
                    id="Path_286"
                    data-name="Path 286"
                    d="M388.54,225.06l2.22.48h0a3.88,3.88,0,0,1,.43,2,3.64,3.64,0,0,1-1,2.2,8.78,8.78,0,0,1-2.2,1.41,5.29,5.29,0,0,0-1.69,1.21,2.65,2.65,0,0,0-.62,1.24,4.564,4.564,0,0,0,0,.51.419.419,0,0,1,0-.13,1.732,1.732,0,0,1,0-.4,2.51,2.51,0,0,1,.58-1.31,5.429,5.429,0,0,1,1.71-1.28,8.75,8.75,0,0,0,2.15-1.39,3.45,3.45,0,0,0,1-2.07,3.92,3.92,0,0,0-.38-1.9h.05a11.733,11.733,0,0,1-2.25-.57Z"
                    fill="#707272"
                />
                <path
                    id="Path_287"
                    data-name="Path 287"
                    d="M393.4,225.36a4.372,4.372,0,0,1-.23-.52,2.29,2.29,0,0,0-.9-1,4.7,4.7,0,0,0-1.28-.45,2,2,0,0,1-.55-.13h.58a3.65,3.65,0,0,1,1.34.41,2.209,2.209,0,0,1,.61.51,2.05,2.05,0,0,1,.33.55A1,1,0,0,1,393.4,225.36Z"
                    fill="#707272"
                />
                <path
                    id="Path_288"
                    data-name="Path 288"
                    d="M393.12,222.62c-.05,0-.42-.59-1.05-1.2a12.723,12.723,0,0,0-1.22-1,4.14,4.14,0,0,1,2.27,2.23Z"
                    fill="#707272"
                />
                <circle
                    id="Ellipse_1"
                    data-name="Ellipse 1"
                    cx="7.24"
                    cy="7.24"
                    r="7.24"
                    transform="translate(134.68 100.35)"
                    fill="#fff"
                    stroke="#e2e2e2"
                    strokeMiterlimit="10"
                    strokeWidth="2"
                />
                <circle
                    id="Ellipse_2"
                    data-name="Ellipse 2"
                    cx="12.37"
                    cy="12.37"
                    r="12.37"
                    transform="translate(86.04 58.54)"
                    fill="#fff"
                    stroke="#e2e2e2"
                    strokeMiterlimit="10"
                    strokeWidth="2"
                />
                <circle
                    id="Ellipse_3"
                    data-name="Ellipse 3"
                    cx="7.79"
                    cy="7.79"
                    r="7.79"
                    transform="translate(7.25 263.31)"
                    fill="#fff"
                    stroke="#e2e2e2"
                    strokeMiterlimit="10"
                    strokeWidth="2"
                />
                <path
                    id="Path_289"
                    data-name="Path 289"
                    d="M54.69,316H47.51v-7.17a.9.9,0,0,0-.9-.9h0a.92.92,0,0,0-.91.9V316H38.54a.91.91,0,1,0,0,1.81H45.7V325a.92.92,0,0,0,.91.89.89.89,0,0,0,.9-.89h0v-7.18h7.18a.91.91,0,0,0,0-1.81Z"
                    fill="#e2e2e2"
                    stroke="#e2e2e2"
                    strokeMiterlimit="10"
                    strokeWidth="0.75"
                />
                <path
                    id="Path_290"
                    data-name="Path 290"
                    d="M472.5,272.3h-6.28V266a.78.78,0,0,0-.78-.78h0a.8.8,0,0,0-.8.78v6.27h-6.26a.79.79,0,0,0-.79.8.78.78,0,0,0,.79.78h6.26v6.28a.81.81,0,0,0,.8.78.78.78,0,0,0,.78-.78h0v-6.28h6.28a.78.78,0,0,0,.79-.78A.79.79,0,0,0,472.5,272.3Z"
                    fill="#e2e2e2"
                    stroke="#e2e2e2"
                    strokeMiterlimit="10"
                    strokeWidth="0.75"
                />
                <path
                    id="Path_291"
                    data-name="Path 291"
                    d="M370.4,91.07h-7.16V83.92a.89.89,0,0,0-.9-.89h0a.91.91,0,0,0-.91.89v7.15h-7.14a.92.92,0,0,0-.9.91.9.9,0,0,0,.9.9h7.14V100a.9.9,0,0,0,.91.89.89.89,0,0,0,.9-.89h0V92.88h7.16a.9.9,0,0,0,.9-.9A.92.92,0,0,0,370.4,91.07Z"
                    fill="#e2e2e2"
                    stroke="#e2e2e2"
                    strokeMiterlimit="10"
                    strokeWidth="0.75"
                />
            </g>
        </SVGIcon>
    );
}
