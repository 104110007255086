import React, { Fragment, useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';

import useToast from 'Modules/Toasts';
import { Button, Checkbox, Div, SingleTextChip, UserInfo } from 'UIKit/index';
import { CallAPI } from 'Utils/apiCall';
import generateImage from 'Utils/generateImage';
import AutoComplete from 'Components/AutoComplete/AutoComplete';
import config from 'config';

const mainURL = `${config.REACT_APP_API_BASE_URL}/${process.env.REACT_APP_API_VERSION}`;
const listOwnerURL = `${mainURL}/${process.env.REACT_APP_API_USERS}`;

const OwnerAutocomplete = ({
    singleSelect = false,
    selectedUser,
    onChange,
    placeholder,
    customPlacement,
    customWidth,
}) => {
    const { showToast } = useToast();
    const api = useMemo(() => new CallAPI(), []);
    const [autoCompleteSelectOptions, setAutoCompleteSelectOptions] = useState([]);
    const [loading, setLoading] = useState(false);
    const [nextOwnerPage, setNextOwnerPage] = useState(null);
    const [ownerOptions, setOwnerOptions] = useState([]);
    const [searchTerm, setSearchTerm] = useState(null);
    const [searchResult, setSearchResult] = useState([]);
    const [searchNextPage, setSearchNextPage] = useState(null);
    const [noScope, setNoScope] = useState(false);

    useEffect(() => {
        if (selectedUser) {
            if (singleSelect) {
                if (typeof selectedUser === 'string') {
                    const temp = ownerOptions.find(el => el.id === selectedUser);
                    if (temp) setAutoCompleteSelectOptions([temp]);
                } else setAutoCompleteSelectOptions([selectedUser]);
            } else setAutoCompleteSelectOptions(selectedUser);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedUser, singleSelect, ownerOptions]);

    const getOwnerOptions = useCallback(
        async (searchText, pageNumber) => {
            try {
                if (
                    ownerOptions.length === 0 ||
                    (ownerOptions.length > 0 && nextOwnerPage !== null) ||
                    (searchTerm && searchNextPage) ||
                    (searchText && pageNumber)
                ) {
                    setLoading(true);
                    const response = await api.request(
                        searchText || searchTerm ? 'POST' : 'GET',
                        searchText || searchTerm
                            ? `${listOwnerURL}/search?page=${
                                  searchNextPage ? searchNextPage : 1
                              }&limit=25`
                            : `${listOwnerURL}?page=${nextOwnerPage ? nextOwnerPage : 1}&limit=25`,
                        searchText
                            ? { search: searchText }
                            : searchTerm
                            ? { search: searchTerm }
                            : null,
                    );

                    if (response) {
                        setLoading(false);
                        if (searchText || searchTerm) {
                            setSearchResult(() => response.data);
                            setSearchNextPage(response.pagination.next_page);
                        } else {
                            setOwnerOptions(prev => [...prev, ...response.data]);
                            setNextOwnerPage(response.pagination.next_page);
                            sessionStorage.setItem(
                                'users',
                                JSON.stringify({
                                    data: [...ownerOptions, ...response.data],
                                    nextPage: response.pagination.next_page,
                                }),
                            );
                        }
                    }
                }
            } catch (err) {
                setLoading(false);
                if (err === 'NOT_ENOUGH_SCOPE') setNoScope(true);
                else
                    showToast({
                        type: 'error',
                        message: err || 'Something went wrong',
                        duration: 1500,
                    });
            }
        },
        [api, nextOwnerPage, ownerOptions, searchNextPage, searchTerm, showToast],
    );

    useEffect(() => {
        let users = JSON.parse(sessionStorage.getItem('users') || '{}');
        if (users.data && users.data.length > 0) {
            setOwnerOptions([...users.data]);
            setNextOwnerPage(users.nextPage);
        } else {
            getOwnerOptions();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleOwnerSearchAPI = useCallback(
        searchText => {
            if (searchText.length > 0 && searchTerm !== searchText) {
                setSearchNextPage(null);
                setSearchTerm(searchText);
                getOwnerOptions(searchText, nextOwnerPage ? nextOwnerPage : 1);
            } else if (!searchText) {
                setSearchTerm(null);
                setSearchResult([]);
                setSearchNextPage(null);
            } else {
                getOwnerOptions();
            }
        },
        [getOwnerOptions, nextOwnerPage, searchTerm],
    );

    const handleAutocompleteSelection = useCallback(
        data => {
            if (singleSelect) {
                setAutoCompleteSelectOptions([data]);
                onChange && onChange(data);
            } else {
                if (autoCompleteSelectOptions.find(el => el.id === data.id)) {
                    const temp = autoCompleteSelectOptions.filter(item => item.id !== data.id);

                    setAutoCompleteSelectOptions(temp);
                    onChange && onChange(temp);
                } else {
                    setAutoCompleteSelectOptions(prev => [...prev, data]);
                    onChange && onChange([...autoCompleteSelectOptions, data]);
                }
            }
        },
        [autoCompleteSelectOptions, singleSelect, onChange],
    );

    const generateAutoCompleteSingle = useCallback(() => {
        const tempRowArray = [];

        if (searchTerm) {
            searchResult.map((item, index) => tempRowArray.push({
                    id: index,
                    label: (
                        <Button
                            buttonClass={
                                'ACSingleOption ' +
                                (autoCompleteSelectOptions[0]?.id === item.id ? 'active' : '')
                            }
                            onClick={() => {
                                handleAutocompleteSelection(item);
                            }}
                        >
                            <UserInfo
                                name={item.name}
                                email={item.email}
                                image={generateImage(item.image, item.name, false, item.email)}
                            />
                        </Button>
                    ),
                    value: item.name,
                }),
            );
        } else {
            ownerOptions.map((item, index) => tempRowArray.push({
                    id: index,
                    label: (
                        <Button
                            buttonClass={
                                'ACSingleOption ' +
                                (autoCompleteSelectOptions[0]?.id === item.id ? 'active' : '')
                            }
                            onClick={() => {
                                handleAutocompleteSelection(item);
                            }}
                        >
                            <UserInfo
                                name={item.name}
                                email={item.email}
                                image={generateImage(item.image, item.name, false, item.email)}
                            />
                        </Button>
                    ),
                    value: item.nane,
                }),
            );
        }
        return tempRowArray;
    }, [
        autoCompleteSelectOptions,
        handleAutocompleteSelection,
        ownerOptions,
        searchResult,
        searchTerm,
    ]);

    const generateAutoCompleteMultiple = useCallback(() => {
        const tempRowArray = [];

        if (searchTerm) {
            searchResult.map((item, index) => tempRowArray.push({
                    id: index,
                    label: (
                        <Div
                            className={
                                'ACMultiOption ' +
                                (autoCompleteSelectOptions.includes(item) ? 'active' : '')
                            }
                        >
                            <Checkbox
                                key={index}
                                unCheckColor={'#eaeaea'}
                                checkColor={'var(--dark-blue)'}
                                classes={{ main: 'ACMOICheckbox' }}
                                fullWidth={true}
                                label={
                                    <UserInfo
                                        name={item.name}
                                        email={item.email}
                                        image={generateImage(
                                            item.image,
                                            item.name,
                                            false,
                                            item.email,
                                        )}
                                    />
                                }
                                onChange={() => handleAutocompleteSelection(item)}
                                checked={autoCompleteSelectOptions.includes(item)}
                            />
                        </Div>
                    ),
                    value: item.name,
                }),
            );
        } else {
            ownerOptions.map((item, index) => tempRowArray.push({
                    id: index,
                    label: (
                        <Div
                            className={
                                'ACMultiOption ' +
                                (autoCompleteSelectOptions
                                    .map(el => {
                                        return el.id;
                                    })
                                    .includes(item.id)
                                    ? 'active'
                                    : '')
                            }
                        >
                            <Checkbox
                                key={index}
                                unCheckColor={'#eaeaea'}
                                checkColor={'var(--dark-blue)'}
                                classes={{ main: 'ACMOICheckbox' }}
                                fullWidth={true}
                                label={
                                    <UserInfo
                                        name={item.name}
                                        email={item.email}
                                        image={generateImage(
                                            item.image,
                                            item.name,
                                            false,
                                            item.email,
                                        )}
                                    />
                                }
                                onChange={() => handleAutocompleteSelection(item)}
                                checked={autoCompleteSelectOptions
                                    .map(el => {
                                        return el.id;
                                    })
                                    .includes(item.id)}
                            />
                        </Div>
                    ),
                    value: item.name,
                }),
            );
        }
        return tempRowArray;
    }, [
        autoCompleteSelectOptions,
        handleAutocompleteSelection,
        ownerOptions,
        searchResult,
        searchTerm,
    ]);

    const generateChips = useMemo(() => {
        return autoCompleteSelectOptions.map((item, index) => {
            return {
                key: index,
                id: item.id,
                chip: (
                    <SingleTextChip
                        title={item.name}
                        onDelete={() => handleAutocompleteSelection(item)}
                    />
                ),
            };
        });
    }, [autoCompleteSelectOptions, handleAutocompleteSelection]);

    const generateValue = useCallback(() => {
        if (singleSelect) {
            if (selectedUser && typeof selectedUser === 'object') {
                return (
                    <UserInfo
                        name={selectedUser.name}
                        email={selectedUser.email}
                        image={generateImage(
                            selectedUser.image,
                            selectedUser.name,
                            false,
                            selectedUser.email,
                        )}
                    />
                );
            } else {
                if (autoCompleteSelectOptions.length) {
                    return (
                        <UserInfo
                            name={autoCompleteSelectOptions[0].name}
                            email={autoCompleteSelectOptions[0].email}
                            image={generateImage(
                                autoCompleteSelectOptions[0].image,
                                autoCompleteSelectOptions[0].name,
                                false,
                                autoCompleteSelectOptions[0].email,
                            )}
                        />
                    );
                } else return placeholder || 'User';
            }
        } else {
            if (generateChips.length) {
                return generateChips.map(item => item.chip);
            } else return placeholder || 'User';
        }
    }, [autoCompleteSelectOptions, generateChips, placeholder, selectedUser, singleSelect]);
    return (
        <Fragment>
            <AutoComplete
                generateRows={
                    singleSelect ? generateAutoCompleteSingle : generateAutoCompleteMultiple
                }
                isAPICall
                singleSelection={singleSelect}
                searchAPI={handleOwnerSearchAPI}
                hasNextPage={searchTerm ? !!searchNextPage : !!nextOwnerPage}
                loading={loading}
                getMoreOptions={getOwnerOptions}
                value={generateValue()}
                itemHeight={58}
                customWidth={customWidth || 300}
                height={ownerOptions?.length > 5 ? 290 : (ownerOptions?.length + 1) * 58}
                placement={customPlacement || 'bottom-center'}
                customMessage={noScope ? "You don't have access to users" : ''}
            />
        </Fragment>
    );
};

OwnerAutocomplete.propTypes = {
    singleSelect: PropTypes.bool,
    selectedUser: PropTypes.oneOfType([PropTypes.object, PropTypes.arrayOf(PropTypes.object)]),
    onChange: PropTypes.func.isRequired,
    placeholder: PropTypes.string,
    customPlacement: PropTypes.string,
    customWidth: PropTypes.number,
};
export default OwnerAutocomplete;
