import React from 'react';

import { SVGIcon } from '../../UIKit/index';

export default function DCLaptop(props) {
    return (
        <SVGIcon {...props} viewBox="0 0 512 450">
            <path d="M54.6,331.6V110c0,0,4.5-32.1,38.2-34.6h328.1c0,0,29.8,2.2,35,34.9l0.3,3v222.8h-20.1V113.1  c0,0-1.3-15.4-17.8-17.8H93.5c0,0-14.6,1.1-18.3,15.9v221.2H54.6V331.6z" />{' '}
            <path d="M36.9,351.4c0,0-2.2,15.9,14.6,19.4h405.7c0,0,17.2-0.5,19-19.4H310l-10.8,10H212l-10.1-10H34.8l3-19.8h449.6  c0,0,8.3,0.8,9.2,9.7v15c0,0-4.8,30.6-37.4,34.2H49.9c0,0-27.7-1.5-34.8-33.9v-17.7c0,0,2.5-7.1,9.3-7.4h14.7L36.9,351.4z" />
        </SVGIcon>
    );
}
