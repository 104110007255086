import React from 'react';

import { SVGIcon } from '../../UIKit/index';

export default function DCSocialMedia(props) {
    return (
        <SVGIcon {...props} viewBox="0 0 512 512">
            <g transform="translate(0.5 0.5)">
                <g transform="translate(21.975)">
                    <g>
                        <path d="M233.5,11c-27,0-48.9,21.9-48.9,48.9c0,27,21.9,48.9,48.9,48.9c27,0,48.9-21.9,48.9-48.9     S260.5,11,233.5,11C233.5,11,233.5,11,233.5,11z M233.5,92.5c-18,0-32.6-14.6-32.6-32.6c0-18,14.6-32.6,32.6-32.6     c18,0,32.6,14.6,32.6,32.6C266.1,77.9,251.5,92.5,233.5,92.5z" />
                    </g>
                </g>
                <g transform="translate(19.228 11.903)">
                    <g>
                        <path d="M268.8,105h-1.9l-1.7,0.9c-18.2,9.1-39.7,9.1-57.9,0l-1.7-0.9h-1.9     c-22.5,0-40.7,18.3-40.7,40.7v48.9c0,13.5,10.9,24.5,24.5,24.5h97.8c13.5,0,24.5-10.9,24.5-24.5v-48.9     C309.6,123.3,291.3,105.1,268.8,105z M293.3,194.7c0,4.5-3.7,8.2-8.2,8.2l0,0h-97.8c-4.5,0-8.2-3.7-8.2-8.2l0,0v-48.9     c0-12.8,9.9-23.5,22.7-24.4c21.8,10.1,46.9,10.1,68.7,0c12.8,0.9,22.7,11.6,22.7,24.4L293.3,194.7z" />
                    </g>
                </g>
                <g transform="translate(41.203 30.216)">
                    <g>
                        <path d="M385.4,249.7c-27,0-48.9,21.9-48.9,48.9s21.9,48.9,48.9,48.9c27,0,48.9-21.9,48.9-48.9v0     C434.3,271.6,412.5,249.7,385.4,249.7z M385.4,331.2c-18,0-32.6-14.6-32.6-32.6c0-18,14.6-32.6,32.6-32.6     c18,0,32.6,14.6,32.6,32.6C418,316.6,403.5,331.2,385.4,331.2C385.4,331.2,385.4,331.2,385.4,331.2z" />
                    </g>
                </g>
                <g transform="translate(38.456 42.119)">
                    <g>
                        <path d="M420.8,343.8h-1.9l-1.7,0.8c-18.2,9.1-39.7,9.1-57.9,0l-1.7-0.8h-1.9     c-22.5,0-40.7,18.3-40.7,40.7v48.9c0,13.5,10.9,24.5,24.5,24.5c0,0,0,0,0,0h97.8c13.5,0,24.5-10.9,24.5-24.5c0,0,0,0,0,0v-48.9     C461.5,362,443.3,343.8,420.8,343.8z M445.2,433.4c0,4.5-3.7,8.2-8.2,8.2h-97.8c-4.5,0-8.2-3.7-8.2-8.2v-48.9     c0-12.8,9.9-23.5,22.7-24.4c21.8,10.1,46.9,10.1,68.7,0c12.8,0.9,22.7,11.6,22.7,24.4L445.2,433.4z" />
                    </g>
                </g>
                <g transform="translate(2.747 30.216)">
                    <g>
                        <path d="M81.6,249.7c-27,0-48.9,21.9-48.9,48.9c0,27,21.9,48.9,48.9,48.9s48.9-21.9,48.9-48.9l0,0     C130.5,271.6,108.6,249.7,81.6,249.7z M81.6,331.2c-18,0-32.6-14.6-32.6-32.6c0-18,14.6-32.6,32.6-32.6s32.6,14.6,32.6,32.6l0,0     C114.2,316.6,99.6,331.2,81.6,331.2z" />
                    </g>
                </g>
                <g transform="translate(0 42.119)">
                    <g>
                        <path d="M116.9,343.8H115l-1.7,0.8c-18.2,9.1-39.7,9.1-57.9,0l-1.7-0.8h-1.9     C29.3,343.8,11,362,11,384.5v48.9c0,13.5,10.9,24.5,24.5,24.5c0,0,0,0,0,0h97.8c13.5,0,24.5-10.9,24.5-24.5l0,0v-48.9     C157.7,362,139.4,343.8,116.9,343.8z M141.4,433.4c0,4.5-3.7,8.2-8.2,8.2H35.5c-4.5,0-8.2-3.7-8.2-8.2v-48.9     c0-12.8,9.9-23.5,22.7-24.4c21.8,10.1,46.9,10.1,68.7,0c12.8,0.9,22.7,11.6,22.7,24.4L141.4,433.4z" />
                    </g>
                </g>
                <g transform="translate(18.312 44.865)">
                    <g>
                        <path d="M318,380L318,380c-1.8-4.1-6.5-6.1-10.7-4.3l0,0c-36.8,15.4-77.7,18.2-116.2,8l7.6-2.2     c4.3-1.3,6.7-5.9,5.4-10.2c-1.3-4.2-5.7-6.7-10-5.5l-32.6,9.6c-0.1,0-0.2,0.1-0.3,0.2c-0.8,0.3-1.5,0.7-2.1,1.2     c-0.3,0.2-0.6,0.3-0.8,0.5c-0.9,0.8-1.5,1.7-2,2.8c-0.4,1-0.6,2.2-0.6,3.3c0.2,1.1,0.5,2.2,0.8,3.3c0,0.1,0,0.2,0.1,0.4     l16.3,31.2c2.1,4,7,5.5,11,3.5s5.5-7,3.5-11l-6.6-12.7c43.8,13.2,90.9,10.6,133-7.2C317.8,388.9,319.7,384.1,318,380z" />
                    </g>
                </g>
                <g transform="translate(34.793 7.167)">
                    <g>
                        <path d="M438.4,218.1c-3.2-3.2-8.3-3.2-11.5,0l-11.6,11.6c-6.7-71.4-52-133.3-118-161.4     c-4.2-1.7-8.9,0.4-10.6,4.5c-1.6,4.1,0.2,8.7,4.2,10.5c59.2,25.2,100.3,80.1,107.8,144l-9.2-9.2c-3.3-3.1-8.4-3-11.5,0.3     c-3,3.1-3,8.1,0,11.2l24.5,24.5c3.2,3.2,8.3,3.2,11.5,0l0,0l24.5-24.5C441.5,226.5,441.6,221.4,438.4,218.1     C438.4,218.2,438.4,218.1,438.4,218.1z" />
                    </g>
                </g>
                <g transform="translate(5.494 5.509)">
                    <g>
                        <path d="M184.6,76.1c0-0.5-0.1-0.9-0.2-1.4c0-0.1-0.1-0.1-0.1-0.2s0-0.1,0-0.2     c-0.2-0.4-0.5-0.8-0.8-1.1c-0.3-0.5-0.6-0.9-0.9-1.3c-0.5-0.4-1-0.8-1.6-1.1c-0.3-0.2-0.6-0.5-0.9-0.7l-32.7-15     c-4.2-1.7-8.9,0.3-10.6,4.5c-1.6,3.9,0.1,8.4,3.9,10.3l16.9,7.7C94,111.7,54.4,177.9,54.4,250c-0.1,4.5,3.4,8.3,7.9,8.4     c4.5,0.1,8.3-3.4,8.4-7.9c0-0.2,0-0.4,0-0.5c0-63.5,33.5-122.2,88.2-154.4l-5.9,12.3c-1.9,4.1-0.2,8.9,3.8,10.9     c1.1,0.5,2.3,0.8,3.6,0.8c3.1,0,6-1.8,7.3-4.6L184,80.9c0-0.1,0-0.2,0.1-0.2c0.2-0.6,0.4-1.1,0.4-1.7c0.1-0.4,0.2-0.9,0.3-1.3     C184.8,77.1,184.7,76.6,184.6,76.1z" />
                    </g>
                </g>
            </g>
        </SVGIcon>
    );
}
