import React from 'react';
import { Skeleton, Div } from 'UIKit';
import './style.scss';

function ReminderSkeleton() {
    return (
        <Div className={'CardContainerBox'}>
            <Div className="CCTopBar">
                <Skeleton height={30}  />
                <Div style={{ display: 'flex', justifyContent: 'flex-end', flex: 1 }}>
                    <Skeleton width={70} height={30} marginRight={5} />
                    <Skeleton width={30} height={30} />
                </Div>
            </Div>
            <Div className="CCMiddleBox">
                <Skeleton className="CCMBUser" height={50} marginRight={20} />
                <Div className="CCMBRight" style={{ display: 'flex' }}>
                    <Skeleton className="CCMBRightSkeleton" width={40} height={40} />
                    <Skeleton className="CCMBRightSkeleton" width={40} height={40} />
                    <Skeleton className="CCMBRightSkeleton" width={40} height={40} />
                </Div>
            </Div>
            <Div className="CCBottomBox" style={{ display: 'flex', flexDirection: 'row' }}>
                <Skeleton height={30} width={30} marginRight={10} style={{ borderRadius: '50%' }} />
                <Skeleton height={30} width={150} />
            </Div>
            <Div style={{ padding: '0px 8px 8px 8px' }}>
                <Skeleton height={60} />
            </Div>
        </Div>
    );
}

export default ReminderSkeleton;
