import React from 'react';

import { SVGIcon } from '../../UIKit/index';

export default function DCHorizontal3Line(props) {
    return (
        <SVGIcon {...props} viewBox="0 0 512 399">
            <g transform="translate(-257 -30)">
                <path d="M286.3,31.42h452.77c15.63,0,28.3,12.67,28.3,28.3l0,0c0,15.63-12.67,28.3-28.3,28.3H286.3   c-15.63,0-28.3-12.67-28.3-28.3l0,0C258,44.09,270.67,31.42,286.3,31.42z" />
                <path d="M427.79,371h311.28c15.63,0,28.3,12.67,28.3,28.3l0,0c0,15.63-12.67,28.3-28.3,28.3H427.79   c-15.63,0-28.3-12.67-28.3-28.3l0,0C399.49,383.67,412.16,371,427.79,371z" />
                <path d="M342.9,201.21h396.18c15.63,0,28.3,12.67,28.3,28.3l0,0c0,15.63-12.67,28.3-28.3,28.3   H342.9c-15.63,0-28.3-12.67-28.3-28.3l0,0C314.6,213.88,327.27,201.21,342.9,201.21z" />
            </g>
        </SVGIcon>
    );
}
