import React from 'react';

import { SVGIcon } from '../../UIKit/index';

export default function DCTotalLeads(props) {
    return (
        <SVGIcon {...props} viewBox="0 0 64.75 55.312">
            <g>
                <circle cx="4" cy="4" r="4" transform="translate(53.91 0.299)" fill="#ffc682" />
                <path
                    d="M398.608,133.922h8.313a2.507,2.507,0,0,0,2.455-2.432v-.521A6.557,6.557,0,0,0,402.8,124.5h-.062a6.483,6.483,0,0,0-6.483,6.483v.583A2.358,2.358,0,0,0,398.608,133.922Z"
                    transform="translate(-344.626 -113.005)"
                    fill="#50758d"
                />
                <ellipse
                    cx="4"
                    cy="4.5"
                    rx="4"
                    ry="4.5"
                    transform="translate(53.91 34.299)"
                    fill="#ffc682"
                />
                <path
                    d="M398.608,397.922h8.313a2.507,2.507,0,0,0,2.455-2.432v-.521A6.557,6.557,0,0,0,402.8,388.5h-.062a6.483,6.483,0,0,0-6.483,6.483v.583A2.358,2.358,0,0,0,398.608,397.922Z"
                    transform="translate(-344.626 -342.61)"
                    fill="#50758d"
                />
                <path
                    d="M220.25,67.8V78.225a1.3,1.3,0,0,1-1.3,1.3H212.3l-1.3-6.4,1.3-6.624h6.644A1.3,1.3,0,0,1,220.25,67.8Z"
                    transform="translate(-183.51 -62.563)"
                    fill="#f5f5f5"
                />
                <path
                    d="M220.25,331.8v10.423a1.3,1.3,0,0,1-1.3,1.3H212.3l-1.3-6.514,1.3-6.514h6.644A1.3,1.3,0,0,1,220.25,331.8Z"
                    transform="translate(-183.51 -292.168)"
                    fill="#f5f5f5"
                />
                <path
                    d="M28.792,250.486v13.028H23.916A23.96,23.96,0,0,1,0,239.815V239.8l6.5-1.3,6.529,1.3a10.684,10.684,0,0,0,10.683,10.683Z"
                    transform="translate(0 -212.154)"
                    fill="#fd646f"
                />
                <path
                    d="M28.792,66.5V79.528H23.711q-.125,0-.25,0A10.681,10.681,0,0,0,13.028,90.211H0A23.737,23.737,0,0,1,23.711,66.5Z"
                    transform="translate(0 -62.563)"
                    fill="#2edffc"
                />
                <g transform="translate(39.541 2.96)">
                    <path
                        d="M311.512,424.954h-7.035a.977.977,0,1,1,0-1.954h7.035a.977.977,0,1,1,0,1.954Z"
                        transform="translate(-303.5 -375.577)"
                        fill="#fad207"
                    />
                    <path
                        d="M311.949,324.954h-5.472a.977.977,0,1,1,0-1.954h5.472a.977.977,0,1,1,0,1.954Z"
                        transform="translate(-305.239 -288.605)"
                        fill="#fad207"
                    />
                    <path
                        d="M356.081,374.954h-4.1a.977.977,0,1,1,0-1.954h4.1a.977.977,0,1,1,0,1.954Z"
                        transform="translate(-344.812 -332.091)"
                        fill="#fad207"
                    />
                    <path
                        d="M311.715,374.954h-1.238a.977.977,0,1,1,0-1.954h1.238a.977.977,0,1,1,0,1.954Z"
                        transform="translate(-308.718 -332.091)"
                        fill="#fad207"
                    />
                    <path
                        d="M311.512,60.954h-7.035a.977.977,0,0,1,0-1.954h7.035a.977.977,0,0,1,0,1.954Z"
                        transform="translate(-303.5 -59)"
                        fill="#fad207"
                    />
                    <path
                        d="M311.949,160.954h-5.472a.977.977,0,0,1,0-1.954h5.472a.977.977,0,0,1,0,1.954Z"
                        transform="translate(-305.239 -145.972)"
                        fill="#fad207"
                    />
                    <path
                        d="M364.778,110.954h-2.8a.977.977,0,1,1,0-1.954h2.8a.977.977,0,1,1,0,1.954Z"
                        transform="translate(-353.509 -102.486)"
                        fill="#fad207"
                    />
                    <path
                        d="M313.018,110.954h-2.541a.977.977,0,0,1,0-1.954h2.541a.977.977,0,0,1,0,1.954Z"
                        transform="translate(-308.718 -102.486)"
                        fill="#fad207"
                    />
                </g>
                <path
                    d="M220.25,141.5v1.954a1.3,1.3,0,0,1-1.3,1.3H212.3l-1.3-1.629,1.3-1.629Z"
                    transform="translate(-183.51 -127.792)"
                    fill="#e6e6e6"
                />
                <path
                    d="M115.521,141.5v3.257H110.44q-.125,0-.25,0A10.681,10.681,0,0,0,99.756,155.44c-.082-.53,0-2.7,0-3.257,0-5.9,6.216-10.683,12.116-10.683Z"
                    transform="translate(-86.728 -127.792)"
                    fill="#27bdf3"
                />
                <path
                    d="M220.25,405.5v1.954a1.3,1.3,0,0,1-1.3,1.3H212.3l-1.3-1.629,1.3-1.629Z"
                    transform="translate(-183.51 -357.397)"
                    fill="#e6e6e6"
                />
                <path
                    d="M28.8,270.494v3.257c-4.41.055-9.424.155-13.529-1.634A23.966,23.966,0,0,1,.01,250.27a24.036,24.036,0,0,0,23.654,20.224Z"
                    transform="translate(-0.009 -222.391)"
                    fill="#fc4755"
                />
                <path
                    d="M401.214,133.923h-2.606a2.357,2.357,0,0,1-2.358-2.357v-.584a6.483,6.483,0,0,1,6.483-6.483h.063a6.53,6.53,0,0,1,1.274.125,6.483,6.483,0,0,0-5.214,6.358v.584A2.357,2.357,0,0,0,401.214,133.923Z"
                    transform="translate(-344.626 -113.006)"
                    fill="#2b597f"
                />
                <path
                    d="M401.214,397.923h-2.606a2.357,2.357,0,0,1-2.358-2.357v-.584a6.483,6.483,0,0,1,6.483-6.483h.063a6.53,6.53,0,0,1,1.274.125,6.483,6.483,0,0,0-5.214,6.358v.584A2.357,2.357,0,0,0,401.214,397.923Z"
                    transform="translate(-344.626 -342.612)"
                    fill="#2b597f"
                />
                <path
                    d="M421.532,43.677a4.1,4.1,0,1,1-2.772-7.4,4.1,4.1,0,0,0,2.428,7.412C421.3,43.692,421.419,43.687,421.532,43.677Z"
                    transform="translate(-360.933 -36.28)"
                    fill="#ffac5c"
                />
                <path
                    d="M421.532,307.673a4.1,4.1,0,1,1-2.772-7.4,4.1,4.1,0,0,0,2.428,7.412c.116,0,.231-.005.344-.014Z"
                    transform="translate(-360.933 -265.882)"
                    fill="#ffac5c"
                />
                <path
                    d="M311.512,424.954h-7.035a.977.977,0,1,1,0-1.954h7.035a.977.977,0,1,1,0,1.954Z"
                    transform="translate(-263.959 -372.617)"
                    fill="#fad207"
                />
                <path
                    d="M311.949,324.954h-5.472a.977.977,0,1,1,0-1.954h5.472a.977.977,0,1,1,0,1.954Z"
                    transform="translate(-265.699 -285.645)"
                    fill="#fad207"
                />
                <path
                    d="M356.081,374.954h-4.1a.977.977,0,1,1,0-1.954h4.1a.977.977,0,1,1,0,1.954Z"
                    transform="translate(-305.271 -329.131)"
                    fill="#fad207"
                />
                <path
                    d="M311.715,374.954h-1.238a.977.977,0,1,1,0-1.954h1.238a.977.977,0,1,1,0,1.954Z"
                    transform="translate(-269.178 -329.131)"
                    fill="#fad207"
                />
                <path
                    d="M311.512,60.954h-7.035a.977.977,0,0,1,0-1.954h7.035a.977.977,0,0,1,0,1.954Z"
                    transform="translate(-263.959 -56.04)"
                    fill="#fad207"
                />
                <path
                    d="M311.949,160.954h-5.472a.977.977,0,0,1,0-1.954h5.472a.977.977,0,0,1,0,1.954Z"
                    transform="translate(-265.699 -143.012)"
                    fill="#fad207"
                />
                <path
                    d="M364.778,110.954h-2.8a.977.977,0,1,1,0-1.954h2.8a.977.977,0,1,1,0,1.954Z"
                    transform="translate(-313.968 -99.526)"
                    fill="#fad207"
                />
                <path
                    d="M313.018,110.954h-2.541a.977.977,0,0,1,0-1.954h2.541a.977.977,0,0,1,0,1.954Z"
                    transform="translate(-269.178 -99.526)"
                    fill="#fad207"
                />
            </g>
        </SVGIcon>
    );
}
