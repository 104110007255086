export const dealInformationSection = [
    { id: 'dif-0', name: 'Deal Information', accessor: 'deal_info' },
    { id: 'dif-1', name: 'Additional Information', accessor: 'additional_info' },
];

export const dealConfigInformation = [
    { name: 'DealInformation', title: 'Deal Information' },
    { name: 'AdditionalInformation', title: 'Additional Information' },
];

export const baseColumnFields = {
    name: { name: 'Deal Name', section: 'deal_info' },
    stage_id: { name: 'Stage', section: 'deal_info' },
    currency: { name: 'Currency', section: 'deal_info' },
    status: { name: 'Status', section: 'deal_info' },
    expected_close_date: { name: 'Expected Close Date', section: 'deal_info' },
    value: { name: 'Deal Value', section: 'deal_info' },
    contract_type: { name: 'Contract Type', section: 'deal_info' },
    contract_length: { name: 'Contract Length', section: 'deal_info' },
    total_deal_value: { name: 'Total Deal Value', section: 'deal_info' },
    deal_source: { name: 'Deal Source', section: 'deal_info' },
};
