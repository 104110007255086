import React from 'react';

import { SVGIcon } from '../../UIKit/index';

export default function DCChat(props) {
    return (
        <SVGIcon {...props} viewBox="0 0 512 512">
            <g>
                <path
                    d="M256.4,8.7C115,8.7,0.4,109,0.4,232.8c0,48.9,16.3,95.7,48.5,131.1c8.9,9.8,3.7,74.8-44,129.8c-3.3,3.7-1.9,10.4,7.8,8.4
		c0,0,34.3-6.4,80.6-24.5c47.5-18.6,66.4-37.2,66.4-37.2s62.5,16.5,96.7,16.5c141.3,0,255.9-100.4,255.9-224.1
		C512.3,109,397.8,8.7,256.4,8.7z"
                />
                <g>
                    <ellipse
                        transform="matrix(0.7071 -0.7071 0.7071 0.7071 -132.3381 165.1182)"
                        style={{ fill: '#fff' }}
                        cx="133.1"
                        cy="242.3"
                        rx="36.6"
                        ry="36.6"
                    />

                    <ellipse
                        transform="matrix(0.7071 -0.7071 0.7071 0.7071 -96.2536 252.2337)"
                        style={{ fill: '#fff' }}
                        cx="256.3"
                        cy="242.3"
                        rx="36.6"
                        ry="36.6"
                    />

                    <ellipse
                        transform="matrix(0.7071 -0.7071 0.7071 0.7071 -60.1399 339.42)"
                        style={{ fill: '#fff' }}
                        cx="379.6"
                        cy="242.3"
                        rx="36.6"
                        ry="36.6"
                    />
                </g>
            </g>
        </SVGIcon>
    );
}
