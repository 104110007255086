import React from 'react';

import { SVGIcon } from '../../UIKit/index';

export default function DCStop(props) {
    return (
        <SVGIcon {...props} viewBox="0 0 320 320">
            <path d="M288,0H32C14.368,0,0,14.368,0,32v256c0,17.632,14.368,32,32,32h256c17.632,0,32-14.368,32-32V32  C320,14.368,305.632,0,288,0z" />
        </SVGIcon>
    );
}
