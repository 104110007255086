import React from 'react';

import { SVGIcon } from '../../UIKit/index';

export default function DCFilleTransfer(props) {
    return (
        <SVGIcon {...props} viewBox="0 0 466 512">
            <g transform="translate(-42 -10.4)">
                <circle cx="276.12" cy="245.19" r="231.76" fill="#F3F9FF" />
                <path
                    fill="#2B88F8"
                    d="M133.87,209.5c-4.72,0.52-8.44,4.27-8.9,9v247.06c-0.23,5.23,3.69,9.72,8.9,10.2h290.35   l60.76-180.99c0,0,2.28-8.57-9.11-12.15h-52.21v-34.18c0,0,0.44-6.95-10.1-9.26H244.22c0,0-4.34,1.2-9.44-4.34   s-19.32-21.48-19.32-21.48c-1.76-2.05-4.18-3.43-6.84-3.91h-74.76V209.5z"
                />
                <path
                    fill="#D9EAFF"
                    d="M125.52,461.54c0,0-2.82,11.5,7.81,13.89l290.68,1.52l62.06-183.48   c0.58-5.33-3.27-10.11-8.59-10.69c-0.46-0.05-0.92-0.07-1.38-0.05H198.11c0,0-6.74-1.52-10.1,8.02L125.52,461.54z"
                />
                <circle fill="#2B88F8" cx="405.89" cy="448.53" r="69.87" />
                <circle
                    fill="#FFFFFF"
                    stroke="#AACFFC"
                    strokeWidth="4.351"
                    strokeMiterlimit="10"
                    cx="244.66"
                    cy="66.49"
                    r="9.44"
                />
                <circle
                    fill="#FFFFFF"
                    stroke="#AACFFC"
                    strokeWidth="4.351"
                    strokeMiterlimit="10"
                    cx="461.88"
                    cy="236.84"
                    r="9.44"
                />
                <path
                    fill="#00ACFD"
                    d="M371.93,194.3h-38.56c-1.34,0.37-2.13,1.77-1.76,3.11c0.06,0.2,0.14,0.4,0.24,0.58l19.86,19.97   c0,0,0.11,0.76,2.06,0l20.07-19.97c0.51-1.5-0.29-3.13-1.79-3.65C372.02,194.33,371.97,194.32,371.93,194.3z"
                />
                <rect fill="#00ACFD" x="349.47" y="159.58" width="7.49" height="21.48" />
                <path
                    fill="#00ACFD"
                    d="M333.85,121.82c0,0,13.68,6.29,16.84,15.52l-5.75,4.13c0,0-7.06-10.64-14.21-12.48   L333.85,121.82z"
                />
                <rect fill="#00ACFD" x="290.55" y="118.89" width="21.16" height="7.92" />
                <rect fill="#00ACFD" x="248.24" y="118.89" width="21.16" height="7.92" />
                <rect fill="#00ACFD" x="206.68" y="118.89" width="21.16" height="7.92" />
                <path
                    fill="#00ACFD"
                    d="M187.05,118.24l-1.63,7.7c-7.56-2.34-14.35-6.69-19.64-12.59l5.53-4.67   C175.51,113.34,180.98,116.66,187.05,118.24z"
                />
                <rect fill="#00ACFD" x="157.42" y="70.82" width="7.92" height="21.37" />
                <rect fill="#00ACFD" x="157.42" y="28.94" width="7.92" height="21.37" />
                <g transform="translate(81.764 60.486)">
                    <path
                        fill="#FFFFFF"
                        d="M315.77,348.01l-19.21,18.88l18.45,18.44v-12.8h35.16v-10.95h-34.4V348.01z"
                    />
                    <path
                        fill="#FFFFFF"
                        d="M332.48,428.09l19.21-18.88l-18.45-18.45v12.81h-35.16v10.95h34.4V428.09z"
                    />
                </g>
                <rect fill="#2B88F8" x="51.62" y="390.69" width="6.08" height="23.11" />
                <rect fill="#2B88F8" x="43.49" y="398.62" width="23.22" height="6.4" />
            </g>
        </SVGIcon>
    );
}
