import React from 'react';

import { SVGIcon } from '../../UIKit/index';

export default function DCFilledGender(props) {
    return (
        <SVGIcon {...props} viewBox="0 0 512 512">
            <g>
                <path
                    id="XMLID_484_"
                    d="M447.4,23.3c0-0.7,0-1.5-0.1-2.2c0-0.4-0.1-0.7-0.2-1.1c-0.1-0.4-0.1-0.8-0.2-1.2
					c-0.1-0.4-0.2-0.8-0.3-1.2c-0.1-0.3-0.2-0.7-0.3-1c-0.1-0.4-0.3-0.8-0.4-1.2c-0.1-0.3-0.2-0.7-0.4-1c-0.2-0.4-0.3-0.7-0.5-1.1
					c-0.2-0.3-0.3-0.7-0.5-1c-0.2-0.4-0.4-0.7-0.6-1c-0.2-0.3-0.4-0.6-0.6-1c-0.3-0.4-0.6-0.8-0.8-1.2c-0.2-0.2-0.3-0.5-0.5-0.7
					c-1-1.2-2.1-2.3-3.3-3.3c-0.2-0.2-0.5-0.3-0.7-0.5c-0.4-0.3-0.8-0.6-1.2-0.9c-0.3-0.2-0.6-0.4-0.9-0.5c-0.4-0.2-0.7-0.4-1.1-0.6
					c-0.3-0.2-0.6-0.3-0.9-0.5c-0.4-0.2-0.8-0.4-1.2-0.5c-0.3-0.1-0.6-0.2-0.9-0.3c-0.4-0.2-0.8-0.3-1.3-0.4c-0.3-0.1-0.6-0.2-0.9-0.2
					c-0.5-0.1-0.9-0.2-1.4-0.3c-0.3-0.1-0.7-0.1-1-0.1c-0.4-0.1-0.9-0.1-1.3-0.2c-0.5,0-1-0.1-1.6-0.1c-0.3,0-0.5,0-0.8,0h-75.4
					c-13.2,0-23.8,10.7-23.8,23.8s10.7,23.8,23.8,23.8h17.7l-35.7,35.6C302.4,62.3,267.9,50,230.5,50C139.1,50,64.6,124.5,64.6,216
					c0,83.4,61.8,152.5,142,164.1v31h-29.4c-13.2,0-23.8,10.7-23.8,23.8s10.7,23.8,23.8,23.8h29.4v29.4c0,13.2,10.7,23.8,23.8,23.8
					c13.2,0,23.8-10.7,23.8-23.8v-29.4h29.4c13.2,0,23.8-10.7,23.8-23.8s-10.7-23.8-23.8-23.8h-29.4v-31c80.2-11.6,142-80.8,142-164.1
					c0-36.9-12.2-71.2-32.7-98.8l36-36v17.7c0,13.2,10.7,23.8,23.8,23.8c13.2,0,23.8-10.7,23.8-23.8V23.4
					C447.5,23.4,447.4,23.4,447.4,23.3z M230.5,334.1c-65.2,0-118.2-53-118.2-118.2s53-118.2,118.2-118.2s118.2,53,118.2,118.2
					S295.7,334.1,230.5,334.1z"
                />
                <circle cx="230.5" cy="215.9" r="75.6" />
            </g>
        </SVGIcon>
    );
}
