import React, { Fragment, useCallback, useRef, useState, useEffect } from 'react';
import mime from 'mime-types';
import PropTypes from 'prop-types';
import DCCrossClose from '../../Assets/icons/DCCrossClose';
import DCDocFile from '../../Assets/icons/DCDocFile';
import DCDocXFile from '../../Assets/icons/DCDocXFile';
import DCGIFFile from '../../Assets/icons/DCGIFFile';
import DCJPEGFile from '../../Assets/icons/DCJPEGFile';
import DCJPGFile from '../../Assets/icons/DCJPGFile';
import DCPDFFile from '../../Assets/icons/DCPDFFile';
import DCPNGFile from '../../Assets/icons/DCPNGFile';
import DCPPTFile from '../../Assets/icons/DCPPTFile';
import DCPSDFile from '../../Assets/icons/DCPSDFile';
import DCRestart from '../../Assets/icons/DCRestart';
import DCSVGFile from '../../Assets/icons/DCSVGFile';
import DCTIFFFile from '../../Assets/icons/DCTIFFFile';
import DCTXTFile from '../../Assets/icons/DCTXTFile';
import DCUploadFile from '../../Assets/icons/DCUploadFile';
import DCXLSFile from '../../Assets/icons/DCXLSFile';
import DCXLSXFile from '../../Assets/icons/DCXLSXFile';
import DCCSVFile from '../../Assets/icons/DCCSVFile';
import DCFileIcon from '../../Assets/icons/DCFileIcon';

import './style.scss';

import useToast from '../../Modules/Toasts';
import { Div, IconButton, TextOverflowTooltip } from '../../UIKit/index';
import { makeId } from '../../Utils';

import { fileUploadSTS } from '../../Utils/uploadFunc';
import DCCheck from 'Assets/icons/DCCheck';
import config from 'config';

const mainURL = `${config.REACT_APP_API_BASE_URL}/${process.env.REACT_APP_API_VERSION}`;

const DropZone = ({
    className,
    api,
    onChange,
    message,
    multiple,
    accept,
    progress,
    setProgress,
    customValidation,
    noUpload,
    publicUpload,
    onFileRemove,
    onClickDone,
    type,
    doneDisabled,
    maxSize,
    doneBtn,
    onDoneBtnClick,
}) => {
    const { showToast } = useToast();
    let fileInputRef = useRef();
    const [files, setFiles] = useState([]);
    const maxFileUploadSize = 26200000;
    const [totalFileSize, setTotalFileSize] = useState(0);
    const [inRetry, setInRetry] = useState([]);
    const [onCick, setOnClick] = useState(false);
    const preventDefault = e => {
        e.preventDefault();
        e.stopPropagation();
        // e.stopPropagation();
    };

    // return array of files metadata once all files are uploaded successfully
    useEffect(() => {
        if (files && files.length && !files.some(el => el.isUploaded === false)) {
            doneDisabled && doneDisabled(true);
            if (onCick) {
                let returnData = files.map(el => {
                    return {
                        key: el.key,
                        bucket: el.bucket,
                        file_name: el.name,
                        size: el.size,
                        type: el.type,
                        id: el.id,
                    };
                });
                onChange && onChange(returnData);
            }
        }
        if (files.length === 0) {
            doneDisabled && doneDisabled(false);
        }

        if (onClickDone === true) {
            setOnClick(true);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [files, doneDisabled, onClickDone, onCick]);

    const dragOver = e => {
        preventDefault(e);
    };

    const dragEnter = e => {
        preventDefault(e);
    };

    const dragLeave = e => {
        preventDefault(e);
    };

    const fileInputClicked = () => {
        fileInputRef.current.click();
    };

    const checkValidFile = useCallback(
        async file => {
            const reg =
                /(.*?)\.(csv|png|gif|bmp|jpg|jpeg|ico|tiff|psd|pdf|doc|docx|txt|ppt|pptx|webm|mpg|mp2|mp3|wav|aac|mpe|mpv|ogg|mp4|m4p|m4v|avi|wmv|mov|qt|flv|swf|xlsx|xls)$/;
            if (!file.name.match(reg)) {
                throw new Error('This type of file is currently not supported');
            }
            if (customValidation) {
                await customValidation(file);
                return true;
            }
            return true;
        },
        [customValidation],
    );

    const checkValidImageFile = useCallback(
        async file => {
            const reg = /(.*?)\.(png|gif|bmp|jpeg|jpg)$/;
            if (!file.name.toLowerCase().match(reg)) {
                throw new Error('Upload png, jpeg, jpg, gif, bmp files.');
            }
            if (customValidation) {
                await customValidation(file);
                return true;
            }
            return true;
        },
        [customValidation],
    );

    const handleProgress = useCallback(
        (e, filename) => {
            const { loaded, total } = e;
            const percentage = (loaded / total) * 100;

            setProgress(prev => {
                return { ...prev, [filename]: percentage };
            });
        },
        [setProgress],
    );

    const handleFileUpload = useCallback(
        file => {
            return new Promise((resolve, reject) => {
                if (publicUpload) {
                    fileUploadSTS(file.file, file, handleProgress, true, accept)
                        .then(res => {
                            if (res.status !== 'Success') {
                                reject({ message: res.error || 'Uploading attachment failed.' });
                            }
                            resolve({
                                key: res.key,
                                bucket: res.bucket,
                            });
                        })

                        .catch(err => {
                            reject(err);
                        })
                        .finally(() => {
                            setProgress(prev => {
                                let temp = { ...prev };
                                delete temp[file.id];
                                return temp;
                            });
                        });
                } else {
                    fileUploadSTS(file.file, file, handleProgress, accept)
                        .then(res => {
                            if (res.status !== 'Success') {
                                reject({ message: res.error || 'Uploading attachment failed.' });
                            }
                            resolve({
                                key: res.key,
                                bucket: res.bucket,
                            });
                        })
                        .catch(err => {
                            reject(err);
                        })
                        .finally(() => {
                            setProgress(prev => {
                                let temp = { ...prev };
                                delete temp[file.id];
                                return temp;
                            });
                        });
                }
            });
        },
        [handleProgress, publicUpload, setProgress, accept],
    );

    const onFilesChange = useCallback(
        async files => {
            try {
                let tempArray = [];
                let uploadedFiles = Array.from(files);
                const filesCount = uploadedFiles.length;
                if (filesCount >= 30)
                    return showToast({
                        type: 'warning',
                        message: 'Maximum 30 files can be uploaded at a time',
                    });

                for (let i = 0; i < filesCount; i++) {
                    let fileName = uploadedFiles[i] && uploadedFiles[i]['name'];
                    let fileSize = uploadedFiles[i] && uploadedFiles[i]['size'];
                    let fileType = uploadedFiles[i] && uploadedFiles[i]['type'];
                    if (fileType.split('/')[0] === 'image') {
                        if (fileSize > 2 * 1024 * 1024)
                            return showToast({
                                message: `Maximum size for an image is 2 MB`,
                                type: 'warning',
                                duration: 3000,
                            });
                    } else {
                        if (fileSize > 10 * 1024 * 1024)
                            return showToast({
                                message: `Maximum size for a file is 10 MB`,
                                type: 'warning',
                                duration: 3000,
                            });
                    }
                    if (maxSize && fileSize > maxSize) {
                        return showToast({
                            message: `File cannot be more than ${maxSize / 1000000} MB`,
                            type: 'warning',
                            duration: 3000,
                        });
                    }
                    try {
                        if (type == 'image') {
                            await checkValidImageFile(uploadedFiles[i]);
                        } else {
                            await checkValidFile(uploadedFiles[i]);
                        }
                        if (totalFileSize + parseInt(fileSize) > maxFileUploadSize) {
                            return showToast({
                                message: 'Total attachment size can not exceed 25 MB.',
                                type: 'error',
                                duration: 3000,
                            });
                        }
                        let attachmentID = makeId(8);
                        if (fileName.split('.').pop() === 'csv') {
                            fileType = mime.lookup('csv');
                        }
                        let fileObj = {
                            id: attachmentID,
                            name: fileName,
                            size: fileSize,
                            type: fileType,
                            file: uploadedFiles[i],
                            isUploaded: false,
                            hasError: false,
                        };
                        if (!fileObj.type) {
                            const type = fileObj.name.split('.').pop();
                            fileObj.type = mime.lookup(type);
                            fileObj.file.type = mime.lookup(type);
                        }
                        tempArray.push(fileObj);
                    } catch (err) {
                        showToast({
                            message: err.message,
                            type: 'error',
                            duration: 3000,
                        });
                    }
                }
                if (tempArray.length > 0) {
                    if (!multiple) setFiles(tempArray);
                    else setFiles(prev => [...prev, ...tempArray]);
                }
                for (let file of tempArray) {
                    setTotalFileSize(prev => prev + parseInt(file.size));
                    if (noUpload) {
                        setProgress(prev => ({ ...prev, [file.name]: 100 }));
                        setFiles(prev => {
                            let temp = [...prev];
                            temp.forEach(el => {
                                if (el.id === file.id) {
                                    el.isUploaded = true;
                                    el.hasError = false;
                                }
                                return el;
                            });
                            return [...temp];
                        });
                    } else {
                        handleFileUpload(file)
                            .then(fileData => {
                                setFiles(prev => {
                                    let temp = [...prev];
                                    temp.forEach(el => {
                                        if (el.id === file.id) {
                                            el.isUploaded = true;
                                            el.hasError = false;
                                            el.key = fileData.key;
                                            el.bucket = fileData.bucket;
                                        }
                                        return el;
                                    });
                                    return [...temp];
                                });
                            })
                            .catch(err => {
                                showToast({
                                    type: 'error',
                                    message:
                                        err.message ||
                                        'Something went wrong while uploading your file',
                                    duration: 2500,
                                });
                                setProgress(prev => {
                                    let updated = prev;
                                    delete prev[file.id];
                                    return updated;
                                });
                                setFiles(prev => {
                                    let temp = [...prev];
                                    temp.forEach(el => {
                                        if (el.id === file.id) {
                                            el.isUploaded = false;
                                            el.hasError = true;
                                        }
                                        return el;
                                    });
                                    return [...temp];
                                });
                            });
                    }
                }
            } catch (error) {
                showToast({
                    type: 'error',
                    message: 'Something went wrong while uploading your file',
                });
            }
        },
        [
            maxSize,
            showToast,
            type,
            totalFileSize,
            checkValidImageFile,
            checkValidFile,
            multiple,
            noUpload,
            setProgress,
            handleFileUpload,
        ],
    );

    const fileDrop = useCallback(
        e => {
            preventDefault(e);
            const dropfiles = Array.from(e.dataTransfer.files);
            if (!multiple && dropfiles.length > 1) {
                showToast({ type: 'warning', message: 'Only one file is allowed currently.' });
            }
            onFilesChange(!multiple ? [dropfiles[0]] : dropfiles);
        },
        [multiple, onFilesChange, showToast],
    );

    const handleRetry = useCallback(
        file => {
            handleFileUpload(file)
                .then(fileData => {
                    setFiles(prev => {
                        const attachmentArr = prev || [];
                        let attachments = attachmentArr.map(a => {
                            if (a.id === file.id) {
                                a.isUploaded = true;
                                a.hasError = false;
                                a.key = fileData.key;
                                a.bucket = fileData.bucket;
                            }
                            return a;
                        });
                        return [...attachments];
                    });
                })
                .catch(err => {
                    showToast({
                        type: 'error',
                        message: err.message || 'Something went wrong',
                        duration: 2000,
                    });
                    let fileID = file.id;
                    setProgress(prev => {
                        let updated = prev;
                        delete prev[fileID];
                        return updated;
                    });
                    setFiles(prev => {
                        const attachmentArr = prev || [];
                        let attachments = attachmentArr.map(a => {
                            if (a.id === fileID) {
                                a.isUploaded = false;
                                a.hasError = true;
                            }
                            return a;
                        });
                        return [...attachments];
                    });
                })
                .finally(() => {
                    let temp = [...inRetry].filter(item => item !== file.id);
                    setInRetry([...temp]);
                });
        },
        [handleFileUpload, inRetry, setProgress, showToast],
    );

    const handleRemoveFile = useCallback(
        file => {
            if (
                !file.key &&
                file.hasError &&
                (!file.hasOwnProperty('bucket') || !navigator.onLine)
            ) {
                setFiles([...files.filter(item => item.id !== file.id)]);
            } else {
                api.cancelAllRequests();
                const url = `${mainURL}/${process.env.REACT_APP_API_INTEGRATIONS}/${process.env.REACT_APP_API_WASABI}/${process.env.REACT_APP_API_DELETE_BY_KEY}`;
                api.request('DELETE', url, {
                    key: file.key,
                    bucket: file.bucket,
                })
                    .then(() => {
                        let temp = files.filter(item => item.id !== file.id);
                        setFiles([...temp]);
                    })
                    .catch(err => {
                        showToast({
                            type: 'error',
                            message: err.message || 'Something went wrong',
                            duration: 1500,
                        });
                    });
            }
        },
        [api, files, showToast],
    );

    const attachmentClicked = e => {
        e.stopPropagation();
    };

    const generateIcon = useCallback(filetype => {
        switch (filetype) {
            case 'jpg':
                return <DCJPGFile />;
            case 'jpeg':
                return <DCJPEGFile />;
            case 'png':
                return <DCPNGFile />;
            case 'gif':
                return <DCGIFFile />;
            case 'tiff':
                return <DCTIFFFile />;
            case 'psd':
                return <DCPSDFile />;
            case 'pdf':
                return <DCPDFFile />;
            case 'doc':
                return <DCDocFile />;
            case 'docx':
                return <DCDocXFile />;
            case 'txt':
                return <DCTXTFile />;
            case 'svg':
                return <DCSVGFile />;
            case 'xls':
                return <DCXLSFile />;
            case 'xlsx':
                return <DCXLSXFile />;
            case 'ppt':
                return <DCPPTFile />;
            case 'csv':
                return <DCCSVFile />;
            default:
                return <DCFileIcon />;
        }
    }, []);

    return (
        <Div className={'DropZoneMain ' + (className ? className : '')}>
            <Div
                className={'DropZoneContainer ' + (files?.length > 0 ? 'DZCUpload' : '')}
                onDragOver={dragOver}
                onDragEnter={dragEnter}
                onDragLeave={dragLeave}
                onDrop={fileDrop}
                onClick={fileInputClicked}
            >
                <input
                    ref={fileInputRef}
                    className="file-input"
                    type="file"
                    multiple={!!multiple}
                    onChange={e => {
                        onFilesChange(e.target.files);
                        e.target.value = '';
                    }}
                    accept={accept || '*'}
                />
                {files?.length > 0 ? (
                    <Div className={'DZCUFiles'}>
                        {Array.from(files).map((file, index) => (
                            <Div className={'DZCIUAttach'} onClick={attachmentClicked} key={index}>
                                <Div className={'DZCIUAIIIcon'}>
                                    {generateIcon(file.type.split('/').pop()?.toLowerCase())}
                                </Div>
                                <Div className={'DZCIUAIIInfo'}>
                                    {progress[file.id] ? (
                                        <Div className={'DZCIUAIIILoader'}>
                                            <Div
                                                key={index}
                                                className={'DZCIUAIIILInner '}
                                                style={{
                                                    width: progress[file.id] + '%',
                                                }}
                                            />
                                        </Div>
                                    ) : null}
                                    <Fragment>
                                        <Div className={'DZCIUAIIIBtns'}>
                                            {file.hasError && !inRetry.includes(file.id) && (
                                                <IconButton
                                                    onClick={() => {
                                                        setInRetry(prev => [...prev, file.id]);
                                                        handleRetry(file);
                                                    }}
                                                    customClass={'DZCIUAIIIReset'}
                                                    buttonType={'circleBtn'}
                                                >
                                                    <DCRestart />
                                                </IconButton>
                                            )}
                                            {(file.hasError || file.key || !multiple) && (
                                                <IconButton
                                                    onClick={() => {
                                                        !multiple
                                                            ? setFiles([])
                                                            : handleRemoveFile(file);
                                                        onFileRemove && onFileRemove();
                                                    }}
                                                    customClass={'DZCIUAIIIRemover'}
                                                    buttonType={'circleBtn'}
                                                >
                                                    <DCCrossClose />
                                                </IconButton>
                                            )}
                                        </Div>
                                    </Fragment>
                                </Div>
                                <Div className={'DZCIUATitleSize'}>
                                    <Div className={'DZCIUATSSize'}>
                                        {file.size / 1024 > 1024
                                            ? `${(file.size / (1024 * 1024)).toFixed(2)} MB`
                                            : `${(file.size / 1024).toFixed(2)} KB`}
                                    </Div>
                                    <Div className={'DZCIUATSTitle'}>
                                        <TextOverflowTooltip tooltipContent={file.name}>
                                            {file.name}
                                        </TextOverflowTooltip>
                                    </Div>
                                </Div>
                            </Div>
                        ))}
                    </Div>
                ) : (
                    <Div className={'DZCInner'}>
                        <Div className={'DZCIMessage'}>
                            <Div className="DZCIMIcon">
                                <DCUploadFile />
                            </Div>
                            <Div className="DZCIMTitle">
                                {message ||
                                    `Drag & Drop ${
                                        multiple ? 'files' : 'file'
                                    } here or click to select ${multiple ? 'files' : 'file'}`}
                            </Div>
                        </Div>
                    </Div>
                )}

                {files.length > 0 && doneBtn ? (
                    <IconButton
                        buttonType={'BlueFillBtn CEMIPBImageBtn'}
                        onClick={e => {
                            e.stopPropagation();
                            onDoneBtnClick();
                        }}
                        disabled={
                            Object.keys(progress).length > 0 ||
                            !files.every(file => file.key !== undefined)
                        }
                    >
                        <DCCheck />
                    </IconButton>
                ) : null}
            </Div>
        </Div>
    );
};

export default DropZone;

DropZone.propTypes = {
    api: PropTypes.object.isRequired,
    message: PropTypes.string.isRequired,
    multiple: PropTypes.bool.isRequired,
    accept: PropTypes.string.isRequired,
    publicUpload: PropTypes.bool,
    noUpload: PropTypes.bool,
    customValidation: PropTypes.func,
    onFileRemove: PropTypes.func,
    onChange: PropTypes.func.isRequired,
    progress: PropTypes.object.isRequired,
    setProgress: PropTypes.func.isRequired,
    className: PropTypes.string,
    doneDisabled: PropTypes.bool,
    maxSize: PropTypes.number,
    doneBtn: PropTypes.bool,
    onDoneBtnClick: PropTypes.func,
};
