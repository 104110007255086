import { StyleSheet } from '@react-pdf/renderer';

export const styles = StyleSheet.create({
    section: {
        margin: 10,
        padding: 5,
        marginTop: 0,
    },
    heading: {
        color: 'blue',
        fontSize: 16,
        fontFamily: 'Source Sans Pro',
        fontWeight: 600,
        margin: 2,
    },
    content: {
        display: 'flex',
        flexDirection: 'row',
        marginTop: 2,
        marginBottom: 2,
        padding: 10,
    },
    HeadingView: {
        borderBottom: 1,
        borderBottomColor: '#666',
        margin: 4,
        padding: 4,
    },
    HeadingSubView: {
        borderBottom: 1,
        borderBottomColor: '#666',
        color: 'blue',
        fontSize: 14,
        margin: 3,
        fontFamily: 'Source Sans Pro',
        fontWeight: 600,
    },
    tableSection: {
        display: 'flex',
        flexDirection: 'row',
        borderColor: '#ededed',
        borderRadius: 5,
        width: '100%',
    },
    BDTableContent: {
        backgroundColor: '#f6f8fc',
        border: 1,
        borderColor: '#ededed',
        borderRadius: 5,
        flex: 1,
        margin: 5,
        padding: 3,
    },
    BDTableFieldRow: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        borderBottom: 1,
        borderColor: '#666',
        borderBottomColor: '#ededed',
        padding: 6,
    },
    BDTableFieldRowText: {
        color: '#666',
        fontSize: 10,
        fontFamily: 'Source Sans Pro',
        fontWeight: 600,
    },
    BDTableFieldRowTextSide: {
        fontSize: 10,
        fontFamily: 'Source Sans Pro',
        fontWeight: 600,
    },
    NPFound: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
    },
    NPFoundText: {
        fontSize: 10,
        color: '#666',
    },
    BusinessImage: {
        width: '104px',
        height: '90px',
        padding: 1,
        margin: 1,
    },
    ImageArea: {
        display: 'flex',
        flexWrap: 'wrap',
        flexDirection: 'row',
        justifyContent: 'center',
    },
    TableSectionMain: {
        borderRadius: 5,
        width: '100%',
        marginBottom: 5,
    },
    MainHeading: {
        fontSize: 14,
        fontFamily: 'Source Sans Pro',
        fontWeight: 600,
    },
    RTRSectionText: {
        color: '#666',
        fontSize: 10,
        fontFamily: 'Source Sans Pro',
        fontWeight: 400,
    },
    // google ads
    Section: {
        margin: 10,
        padding: 5,
        marginTop: 0,
    },
    SectionArea: {
        display: 'flex',
        flexDirection: 'row',
        margin: 3,
        padding: 3,
    },
    RTRSectionText: {
        fontSize: 10,
        color: '#666',
        fontFamily: 'Source Sans Pro',
        fontWeight: 400,
    },
    HeadingDiv: {
        color: 'blue',
        fontSize: 16,
        fontFamily: 'Source Sans Pro',
        fontWeight: 600,
    },
    HeadingLogo: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
    },
    HeadingCaption: {
        fontSize: 10,
        color: '#666',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        marginLeft: 10,
    },
    FixedTopHeight: {
        height: 20,
    },
    FixedBottomHeight: {
        height: 30,
    },
    GITContent: {
        fontSize: 9,
        width: 500,
        fontFamily: 'Source Sans Pro',
        fontWeight: 400,
    },
    GITUserImage: {
        width: 30,
        height: 30,
        borderRadius: 60,
        margin: 3,
        padding: 3,
    },
    GITSvgImage: {
        width: 30,
        height: 30,
    },
    GITSection: {
        backgroundColor: '#ededed',
        height: 40,
        display: 'flex',
        flexDirection: 'row',
        borderRadius: 5,
        justifyContent: 'space-between',
        margin: 20,
        alignItems: 'center',
        objectFit: 'contain',
    },
    SectionBar: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        margin: 2,
        padding: 4,
        fontSize: 10,
        backgroundColor: '#ededed',
        borderRadius: 3,
        height: 30,
        alignContent: 'center',
        alignItems: 'center',
        fontFamily: 'Source Sans Pro',
        fontWeight: 600,
    },
    MidSection: {
        display: 'flex',
        flexDirection: 'row',
        margin: 5,
        padding: 5,
        fontSize: 10,
        alignContent: 'center',
        alignItems: 'center',
        justifyContent: 'center',
        fontFamily: 'Source Sans Pro',
        fontWeight: 700,
    },
    AlertInfo: {
        border: 1,
        borderColor: '#ededed',
        borderRadius: 4,
        fontSize: 10,
        backgroundColor: '#ededed',
        padding: 4,
        margin: 4,
        fontFamily: 'Source Sans Pro',
        fontWeight: 600,
    },
    MSBox1: {
        backgroundColor: '#eef5fe',
        padding: 3,
        margin: 3,
        width: 157,
        height: 60,
        borderRadius: 5,
    },
    MSBoxContainer: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        margin: 2,
        padding: 2,
    },
    MP3: {
        margin: 3,
        padding: 3,
    },
    GreyText: {
        color: '#666',
    },
    MP: {
        margin: 1,
        padding: 1,
    },
    WH55: {
        width: 55,
        height: 55,
    },
    MSBox2: {
        backgroundColor: '#fef7eb',
        padding: 3,
        margin: 3,
        width: 155,
        height: 60,
        borderRadius: 5,
    },
    MSBox3: {
        backgroundColor: '#eef5fe',
        padding: 3,
        margin: 3,
        width: 155,
        height: 60,
        borderRadius: 5,
    },
    ColumnHeaderView: {
        width: 70,
        padding: 2,
        backgroundColor: '#e8eef9',
        fontSize: 10,
    },
    ColumnHeaderViewText: {
        fontSize: 10,
        display: 'flex',
        alignSelf: 'center',
    },
    ColumnView: {
        width: 70,
        padding: 2,
        margin: 2,
        fontSize: 10,
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
    },
    ColumnViewText: {
        display: 'flex',
        alignSelf: 'center',
        margin: 2,
    },
    TableViewContent: {
        display: 'flex',
        flexDirection: 'row',
        border: 1,
        borderColor: '#ededed',
        fontSize: 10,
    },
    TableView: {
        padding: 5,
        margin: 5,
        fontSize: 10,
        fontFamily: 'Source Sans Pro',
        fontWeight: 400,
    },
    TableViewHeader: {
        display: 'flex',
        flexDirection: 'row',
        border: 1,
        borderColor: '#ededed',
        backgroundColor: '#e8eef9',
        alignItems: 'center',
        justifyContent: 'center',
    },
    TableViewHeaderCell1: {
        width: 150,
        padding: 2,
        backgroundColor: '#e8eef9',
        fontSize: 10,
    },
    TableViewHeaderCell2: {
        width: 140,
        padding: 2,
        backgroundColor: '#e8eef9',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        fontSize: 10,
    },
    TableViewCell1: {
        width: 150,
        padding: 2,
        margin: 2,
        fontSize: 10,
        textDecoration: 'none',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
    },
    TableViewCell2: {
        width: 140,
        padding: 2,
        margin: 2,
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    TableViewCell2Cover: {
        backgroundColor: '#ededed',
        height: 7,
        fontSize: 10,
        width: 100,
        borderRadius: 3,
    },
    BottomSection: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
    },
    GAdsLevel: {
        fontSize: 10,
        padding: 3,
        margin: 3,
    },
    HeaderDiv: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        margin: 2,
        padding: 4,
        fontSize: 10,
        backgroundColor: '#ededed',
        borderRadius: 3,
        height: 30,
        alignContent: 'center',
        alignItems: 'center',
        fontFamily: 'Source Sans Pro',
        fontWeight: 600,
    },
    LSFieldOverallScore: {
        display: 'flex',
        border: 1,
        borderRadius: 5,
        borderColor: '#ededed',
        margin: 4,
        padding: 2,
        textAlign: 'center',
        fontSize: 12,
        fontFamily: 'Source Sans Pro',
        fontWeight: 600,
        width: 120,
        height: 100,
        marginLeft: 210,
        marginTop: 8,
    },
    LSFieldFixture: {
        border: 1,
        borderRadius: 5,
        padding: 5,
        margin: 8,
        borderColor: '#ededed',
    },
    LSFieldFixtureText: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        fontSize: 10,
        fontFamily: 'Source Sans Pro',
        fontWeight: 700,
        margin: 2,
        padding: 2,
    },
    LSSubHeading: {
        padding: 2,
        margin: 2,
        fontSize: 11,
        fontFamily: 'Source Sans Pro',
        fontWeight: 600,
    },
    LSFieldFixtureCover: {
        backgroundColor: '#ededed',
        height: 5,
    },
    LSFieldOverallScoreView: {
        marginTop: 10,
        fontFamily: 'Source Sans Pro',
        fontWeight: 600,
    },
    LSFieldOverallScoreViewText: {
        fontSize: 32,
        fontFamily: 'Source Sans Pro',
        fontWeight: 600,
        color: 'blue',
        marginTop: 15,
    },
    // overall score
    LSFieldOverall: {
        display: 'flex',
        margin: 4,
        textAlign: 'center',
        fontSize: 12,
        fontWeight: 'bold',
        width: 70,
        height: 90,
        justifyContent: 'center',
        fontFamily: 'Source Sans Pro',
        fontWeight: 600,
    },
    OverAllSection: {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        padding: 4,
        justifyContent: 'center',
    },
    ACContent: {
        alignContent: 'center',
    },
    // website mobile download

    HeaderDivs: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        margin: 2,
        padding: 4,
        fontSize: 11,
        backgroundColor: '#ededed',
        marginTop: 8,
        borderRadius: 3,
        height: 35,
        alignContent: 'center',
        alignItems: 'center',
        fontFamily: 'Source Sans Pro',
        fontWeight: 600,
    },
    RSubSection: {
        display: 'flex',
        flexDirection: 'row',
        margin: 3,
        padding: 3,
        fontFamily: 'Source Sans Pro',
        fontWeight: 600,
    },
    WBSSection: {
        display: 'flex',
        border: 1,
        borderRadius: 5,
        borderColor: '#ededed',
        padding: 2,
        margin: 2,
        width: 220,
    },
    WBSSectionRow: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        borderBottom: 1,
        borderColor: '#ededed',
        alignContent: 'center',
        alignItems: 'center',
    },
    WBSSectionRowText: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        margin: 1,
        fontSize: 10,
        fontFamily: 'Source Sans Pro',
        fontWeight: 600,
    },
    WBSSectionRowIcon: {
        width: 25,
        height: 25,
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        margin: 1,
        padding: 1,
    },
    WBRSectionHeader: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
    },
    WBSSectionRowSubText: {
        fontSize: 10,
        margin: 1,
        padding: 1,
        fontFamily: 'Source Sans Pro',
        fontWeight: 600,
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
    },
    RSubSectionText: {
        fontSize: 10,
        color: '#666',
        fontFamily: 'Source Sans Pro',
        fontWeight: 400,
    },
    PSSummaryBox: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
    },
    MobileViewBox: {
        margin: 5,
        justifyContent: 'center',
    },
    MobileViewImageCover: {
        width: 88,
        height: 175,
        position: 'relative',
        marginLeft: 98,
    },
    MobileViewImage: {
        width: 78,
        height: 163,
        position: 'absolute',
        marginLeft: 103,
        borderRadius: 7,
    },
    DesktopViewImageCover: {
        width: 178,
        height: 122,
        position: 'relative',
        marginLeft: 70,
    },
    DesktopViewImage: {
        width: 133,
        height: 108,
        position: 'absolute',
        marginLeft: 93,
        borderRadius: 1,
    },
    DesktopViewBox: {
        margin: 5,
        justifyContent: 'center',
    },
    MobileViewAnalysisBox: {
        border: 1,
        borderColor: '#ededed',
        borderRadius: 5,
        padding: 4,
        margin: 4,
    },
    MobileViewAnalysisBox1: {
        border: 1,
        borderColor: '#ededed',
        borderRadius: 5,
        padding: 4,
        margin: 4,
        fontFamily: 'Source Sans Pro',
        fontWeight: 600,
    },
    MP2: {
        margin: 2,
        padding: 2,
    },
    DesktopViewSection: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        margin: 10,
    },
    DesktopAlign: {
        margin: 3,
        padding: 5,
    },
    DesktopMainSection: {
        justifyContent: 'center',
        margin: 2,
    },
    DesktopViewPSSummary: {
        display: 'flex',
        border: 1,
        borderRadius: 5,
        borderColor: '#ededed',
        padding: 2,
        margin: 2,
        width: 220,
    },
    AnalysisInfoCard: {
        border: 1,
        borderRadius: 5,
        borderColor: '#ededed',
        fontSize: 9,
        padding: 4,
        margin: 4,
        fontFamily: 'Source Sans Pro',
        fontWeight: 600,
    },
    AnalysisInfoCardBox: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
    },
    AnalysisInfoCardTitle: {
        fontSize: 9.5,
        fontFamily: 'Source Sans Pro',
        fontWeight: 700,
    },
    FSStandard: {
        fontSize: 10,
    },
    // total Listing download
    LSFields: {
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'row',
        flexWrap: 'wrap',
        padding: 1,
        boxSizing: 'border-box',
    },
    LSFieldsText: {
        padding: 2,
        margin: 2,
        fontSize: 8.5,
        color: '#666',
        display: 'flex',
        flexDirection: 'row',
        flexWap: 'wrap',
        boxSizing: 'border-box',
        width: 100,
    },
    LSBoxSection: {
        display: 'flex',
        flexWrap: 'wrap',
        margin: 10,
        padding: 2,
        alignItems: 'flex-start',
        flexDirection: 'row',
        alignContent: 'center',
        fontSize: 12,
        border: 1,
        borderColor: '#ededed',
        fontFamily: 'Source Sans Pro',
        fontWeight: 400,
    },
    LSBox: {
        width: 136,
        height: 140,
        backgroundColor: 'white',
        margin: 3,
        borderRadius: 5,
        border: 1,
        borderColor: '#4bcffa',
        padding: 7,
        fontFamily: 'Source Sans Pro',
        fontWeight: 400,
    },
    LSBoxHeader: {
        display: 'flex',
        flexDirection: 'row',
        alignContent: 'center',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    BDFieldsButton: {
        color: 'white',
        backgroundColor: 'blue',
        fontSize: 10,
        textAlign: 'center',
        padding: 2,
        border: 1,
        borderRadius: 4,
        width: 100,
    },
    ListingLogoIcon: {
        width: 15,
        height: 15,
        margin: 2,
    },
    ListingTextFont: {
        fontSize: 8.5,
    },
    LinkText: {
        textDecoration: 'none',
        height: 10,
        width: 10,
        margin: 5,
    },

    // Review Downloads
    TextCenter: {
        display: 'flex',
        alignSelf: 'center',
        margin: 2,
        padding: 2,
        fontFamily: 'Source Sans Pro',
        fontWeight: 600,
    },
    RTRSectionRowText: {
        fontSize: 9,
        color: '#666',
        fontFamily: 'Source Sans Pro',
        fontWeight: 600,
        marginTop: 2,
    },
    RTRsection: {
        display: 'flex',
        flexDirection: 'row',
        margin: 1,
        padding: 1,
        flexWrap: 'wrap',
        fontSize: 9,
        fontFamily: 'Source Sans Pro',
        fontWeight: 600,
        justifyContent: 'center',
    },
    RTRSecionRow: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        margin: 2,
        padding: 2,
        borderBottom: 1,
        borderColor: '#ededed',
    },
    RTRSectionRow: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        margin: 2,
        padding: 2,
        borderBottom: 1,
        borderColor: '#ededed',
        height: 50,
    },
    RTRSecionRowBottom: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        margin: 2,
        padding: 2,
    },
    RTUSection: {
        fontSize: 9,
        margin: 2,
        padding: 2,
        fontFamily: 'Source Sans Pro',
        fontWeight: 400,
    },
    RTUSectionBox: {
        border: 1,
        borderColor: '#ededed',
        marginTop: 4,
        marginBottom: 4,
        borderRadius: 5,
    },
    RTUSectionBoxHeader: {
        display: 'flex',
        flexDirection: 'row',
        margin: 1,
        padding: 1,
        justifyContent: 'space-between',
        alignItems: 'center',
        fontFamily: 'Source Sans Pro',
        fontWeight: 600,
    },
    RYRRatingBox: {
        display: 'flex',
        flexDirection: 'row',
        border: 1,
        width: 100,
        borderColor: '#ededed',
        borderRadius: 4,
        alignItems: 'center',
        alignContent: 'center',
        backgroundColor: '#f5f7fb',
        marginLeft: 300,
    },
    UserRating: {
        display: 'flex',
        flexDirection: 'row',
        margin: 2,
    },
    WH20: {
        width: 20,
        height: 20,
    },
    GRBox1: {
        backgroundColor: '#fef7eb',
        padding: 3,
        margin: 3,
        width: 130,
        height: 94,
        borderRadius: 4,
    },
    GRBox2: {
        backgroundColor: '#eef5fe',
        padding: 3,
        margin: 3,
        width: 132,
        height: 94,
        borderRadius: 4,
    },
    GRBox3: {
        backgroundColor: '#fdf1f3',
        padding: 3,
        margin: 3,
        width: 132,
        height: 94,
        borderRadius: 4,
    },
    GRBox4: {
        backgroundColor: '#e4fbf8',
        padding: 3,
        margin: 3,
        width: 132,
        height: 94,
        borderRadius: 4,
    },
    BORSection: {
        display: 'flex',
        flexDirection: 'row',
        alignContent: 'center',
        alignItems: 'center',
    },
    BORSectionWithoutAlign: {
        display: 'flex',
        flexDirection: 'row',
    },
    BORSectionImage: {
        width: 25,
        height: 25,
        border: 1,
        borderRadius: 13,
        margin: 3,
        padding: 3,
    },
    FSStandardGrey: {
        fontSize: 10,
        color: '#666',
    },
    WH25: {
        width: 25,
        height: 25,
    },
    // MP2: {
    //     margin: 2,
    //     padding: 2,
    // },
    RatingViewBox: {
        display: 'flex',
        flexDirection: 'row',
        border: 1,
        width: 100,
        borderColor: '#ededed',
        borderRadius: 4,
        alignItems: 'center',
        alignContent: 'center',
        backgroundColor: '#f5f7fb',
        padding: 2,
        marginTop: 10,
        marginRight: -15,
    },
    FBox1: {
        backgroundColor: '#fef7eb',
        margin: 6,
        width: 137,
        height: 60,
        borderRadius: 4,
    },
    FBox2: {
        backgroundColor: '#eef5fe',
        margin: 6,
        width: 137,
        height: 60,
        borderRadius: 4,
    },
    FBox3: {
        backgroundColor: '#fdf1f3',
        margin: 6,
        width: 137,
        height: 60,
        borderRadius: 4,
    },
    YBox1: {
        backgroundColor: '#fef7eb',
        margin: 6,
        width: 137,
        height: 60,
        borderRadius: 4,
    },
    YBox2: {
        backgroundColor: '#eef5fe',
        margin: 6,
        width: 137,
        height: 60,
        borderRadius: 4,
    },
    YBox3: {
        backgroundColor: '#fdf1f3',
        margin: 6,
        width: 137,
        height: 60,
        borderRadius: 4,
    },
    FBBox1: {
        backgroundColor: '#fef7eb',
        margin: 6,
        width: 137,
        height: 60,
        borderRadius: 4,
    },
    FBBox2: {
        backgroundColor: '#fdf1f3',
        margin: 6,
        width: 137,
        height: 60,
        borderRadius: 4,
    },
    YRView: {
        fontWeight: 'ultrabold',
        fontSize: 10,
        width: 100,
        margin: 2,
        padding: 2,
        alignContent: 'center',
        alignItems: 'center',
    },
    GRView: {
        display: 'flex',
        flexDirection: 'row',
        border: 1,
        width: 100,
        borderColor: '#ededed',
        borderRadius: 4,
        alignItems: 'center',
        backgroundColor: '#f5f7fb',
        padding: 2,
        marginLeft: 280,
    },
    FRView: {
        display: 'flex',
        flexDirection: 'row',
        border: 1,
        width: 100,
        borderColor: '#ededed',
        borderRadius: 4,
        alignItems: 'center',
        backgroundColor: '#f5f7fb',
        padding: 2,
        marginLeft: 150,
    },
    RColumnView: {
        display: 'flex',
        flexDirection: 'column',
    },
    CenterContent: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
    },
    FSStandardGreyWithPadding: {
        fontSize: 10,
        color: '#666',
        padding: 2,
    },
    TRView: {
        fontWeight: 'ultrabold',
        fontSize: 14,
        width: 160,
        height: 40,
        alignContent: 'center',
        alignItems: 'center',
    },
    FRViewArea: {
        fontSize: 10,
        padding: 2,
        alignContent: 'center',
        alignItems: 'center',
    },
    YRLRArea: {
        display: 'flex',
        flexDirection: 'row',
        margin: 3,
        padding: 3,
    },
    // SEO Downloads
    STextCenter: {
        fontSize: 10,
        display: 'flex',
        alignSelf: 'center',
    },
    HeadingTitle: {
        borderBottom: 1,
        borderBottomColor: '#666',
        margin: 4,
        padding: 4,
    },
    RSubSectionView: {
        display: 'flex',
        flexDirection: 'row',
        margin: 3,
        padding: 3,
    },
    SColumnView: {
        width: 80,
        padding: 2,
        margin: 2,
        fontSize: 10,
    },
    FollowSection: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        fontSize: 8,
    },
    BacklinkSection: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        fontWeight: 600,
    },
    BacklinkView: {
        margin: 3,
        padding: 3,
        fontSize: 10,
    },
    FollowMainView: {
        fontSize: 12,
        margin: 3,
        padding: 3,
        marginTop: 10,
        fontWeight: 600,
    },
    FollowNFollowSection: {
        border: 1,
        borderRadius: 5,
        borderColor: '#ededed',
        margin: 5,
        marginTop: 10,
        padding: 5,
        width: 220,
        fontFamily: 'Source Sans Pro',
        fontWeight: 400,
    },
    FNFCover: {
        height: 30,
        backgroundColor: '#ededed',
        borderRadius: 5,
        width: 210,
    },
    STableView: {
        padding: 5,
        margin: 5,
        fontFamily: 'Source Sans Pro',
        fontWeight: 400,
    },
    STableViewHeader: {
        display: 'flex',
        flexDirection: 'row',
        border: 1,
        borderColor: '#ededed',
        backgroundColor: '#e8eef9',
    },
    TableColumn: {
        width: 80,
        padding: 2,
        margin: 2,
        fontSize: 10,
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
    },
    TTtableColumn: {
        width: 80,
        padding: 2,
        margin: 2,
        backgroundColor: '#e8eef9',
        fontSize: 10,
    },
    TableColumnCenterText: {
        display: 'flex',
        flexDirection: 'row',
        alignSelf: 'center',
    },
    SText: {
        display: 'flex',
        alignSelf: 'center',
        margin: 2,
        fontSize: 8,
    },
    SeoAnalyticsBox: {
        marginLeft: 50,
        margin: 5,
        borderRadius: 5,
    },
    TableColumnText: {
        display: 'flex',
        alignSelf: 'center',
    },
    SBottomSection: {
        display: 'flex',
        flexDirection: 'row',
        margin: 5,
        padding: 5,
        flexWrap: 'wrap',
        fontSize: 10,
        justifyContent: 'center',
    },
    BottomSectionBox1: {
        backgroundColor: '#eef5fe',
        padding: 1,
        margin: 3,
        width: 125,
        height: 50,
    },
    BottomSectionBoxContainer: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        margin: 2,
        padding: 2,
    },
    BottomSectionBox2: {
        backgroundColor: '#fdf1f3',
        padding: 1,
        margin: 3,
        width: 130,
        height: 50,
    },
    BottomSectionBox3: {
        backgroundColor: '#fef7eb',
        padding: 1,
        margin: 3,
        width: 125,
        height: 50,
    },
    BottomSectionBox4: {
        backgroundColor: '#e4fbf8',
        padding: 1,
        margin: 3,
        width: 125,
        height: 50,
    },
    BSContainerRow: {
        margin: 1,
        padding: 3,
        fontSize: 10,
        fontFamily: 'Source Sans Pro',
        fontWeight: 700,
    },
    BSContainerRowText: {
        display: 'flex',
        alignSelf: 'center',
        fontSize: 10,
        margin: 1,
    },
    BSContainerRowText1: {
        display: 'flex',
        alignSelf: 'center',
        fontSize: 10,
        margin: 1,
        fontFamily: 'Source Sans Pro',
        fontWeight: 700,
    },
    TableViewHeaderBColumn: {
        width: 160,
        padding: 2,
        margin: 2,
        fontSize: 10,
    },
    TTtableView: {
        display: 'flex',
        flexDirection: 'row',
        border: 1,
        borderColor: '#ededed',
    },
    TableLinkText: {
        width: 160,
        padding: 2,
        margin: 2,
        fontSize: 10,
        textDecoration: 'none',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
    },
    TableBigColumnCell: {
        width: 160,
        padding: 2,
        margin: 2,
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    TCProgressCover: {
        backgroundColor: '#ededed',
        height: 7,
        fontSize: 10,
        width: 100,
        borderRadius: 4,
    },
    TCOverlapPercent: {
        fontSize: 10,
        padding: 2,
        margin: 2,
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
    },
    BacklinkText: {
        display: 'flex',
        alignSelf: 'center',
        margin: 2,
        fontWeight: 600,
    },
    BSContainerRowBText: {
        display: 'flex',
        alignSelf: 'center',
        margin: 2,
        fontWeight: 600,
    },
    STableViewHeaderCell1: {
        width: 140,
        padding: 2,
        margin: 2,
        backgroundColor: '#e8eef9',
        fontSize: 10,
    },
    STableViewHeaderCell2: {
        width: 180,
        padding: 2,
        margin: 2,
        justifyContent: 'space-between',
        backgroundColor: '#e8eef9',
    },
    STableViewHeaderCell2Text: {
        fontSize: 10,
        backgroundColor: '#e8eef9',
        display: 'flex',
        alignSelf: 'center',
    },
    TTtableViewKeywordCell: {
        width: 140,
        padding: 2,
        margin: 2,
        fontSize: 10,
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        textDecoration: 'none',
    },
    TTtableViewKDCell: {
        width: 180,
        padding: 2,
        margin: 2,
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    TTtableViewKDCellCover: {
        backgroundColor: '#ededed',
        height: 7,
        fontSize: 10,
        width: 100,
        borderRadius: 4,
    },
    TTtableViewKD: {
        fontSize: 10,
        padding: 2,
        margin: 2,
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
    },
    BarGraph: {
        backgroundColor: '#d6f2ff',
        width: 50,
        height: 100,
        borderRadius: 4,
    },
    BarGraphText: {
        display: 'flex',
        alignSelf: 'center',
        margin: 2,
        fontSize: 8,
    },
    BarGraphSection: {
        display: 'flex',
        flexDirection: 'row',
        border: 1,
        borderRadius: 5,
        borderColor: '#ededed',
    },
    BarGraphSectionArea: {
        fontSize: 12,
        margin: 3,
        padding: 3,
        fontFamily: 'Source Sans Pro',
        fontWeight: 600,
    },
    StatsSection: {
        margin: 10,
        padding: 5,
        fontSize: 10,
        border: 1,
        borderRadius: 5,
        borderColor: '#ededed',
        width: 260,
        fontFamily: 'Source Sans Pro',
        fontWeight: 400,
    },
    WH30: {
        height: 30,
        width: 30,
    },
    BSStatsSection: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
    },

    // Social Downloads
    SMLTextCenter: {
        display: 'flex',
        alignSelf: 'center',
        margin: 2,
        padding: 2,
        marginTop: 8,
        fontFamily: 'Source Sans Pro',
        fontWeight: 600,
    },
    SMLsection: {
        display: 'flex',
        flexDirection: 'row',
        marginTop: 2,
        flexWrap: 'wrap',
        fontSize: 9,
        justifyContent: 'center',
    },
    SMLSecionRow: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        borderBottom: 1,
        borderColor: '#ededed',
    },
    SMLSecionRowBottom: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        fontFamily: 'Source Sans Pro',
        fontWeight: 600,
    },
    WF20: {
        height: 20,
        width: 20,
    },
    SMLBox: {
        backgroundColor: 'white',
        marginTop: 3,
        marginRight: 8,
        width: 132,
        height: 100,
        border: 1,
        borderColor: '#ededed',
        borderRadius: 4,
    },
    SMLBoxRight1: {
        width: 20,
        height: 20,
        backgroundColor: '#4ab8ff',
        borderRadius: 20,
        marginTop: 5,
        marginRight: 5,
    },
    SMLBoxRight2: {
        width: 20,
        height: 20,
        backgroundColor: '#f18954',
        borderRadius: 20,
        marginTop: 5,
        marginRight: 5,
    },
    SMLBoxRight3: {
        width: 20,
        height: 20,
        backgroundColor: '#ed4d7f',
        borderRadius: 20,
        marginTop: 4,
        marginRight: 4,
    },
    SMLBoxRight: {
        width: 20,
        height: 20,
        backgroundColor: '#7242db',
        borderRadius: 20,
        marginTop: 5,
        marginRight: 5,
    },
    SMLBoxIcon: {
        width: 11,
        height: 11,
        display: 'flex',
        alignSelf: 'center',
        marginTop: 4,
    },
    TBox1: {
        backgroundColor: '#fef7eb',
        margin: 6,
        width: 240,
        height: 100,
    },
    TBox2: {
        backgroundColor: '#eef5fe',
        margin: 6,
        width: 240,
        height: 100,
    },
    TBox3: {
        backgroundColor: '#fdf1f3',
        margin: 6,
        width: 240,
        height: 100,
    },
    TBox4: {
        backgroundColor: '#e4fbf8',
        margin: 6,
        width: 240,
        height: 100,
    },
    TBoxArea: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
    },
    // Front Page
    FrontSection: {
        margin: 10,
        padding: 5,
        border: 1,
        borderColor: '#ededed',
        borderRadius: 5,
        fontFamily: 'Source Sans Pro',
        fontWeight: 600,
    },
    Title: {
        borderBottom: 3,
        borderBottomColor: '#c0392b',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
    },
    TSection: {
        border: 1,
        borderColor: '#ededed',
        margin: 5,
        padding: 5,
        alignContent: 'center',
        alignItems: 'center',
        marginTop: 60,
        borderRadius: 5,
    },
    FS20: {
        fontSize: 20,
    },
});
