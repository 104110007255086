import React from 'react';

import { SVGIcon } from '../../UIKit/index';

export default function DCSettings(props) {
    return (
        <SVGIcon {...props} viewBox="0 0 512 507">
            <g transform="translate(1161 16)">
                <g>
                    <path
                        d="M-649.5,281.7v-84.4l-69.3-13.7c-3.7-12.5-8.8-24.7-15.1-36.3l38.8-58l-59.8-59.8l-58,38.7
			c-11.5-6.2-23.7-11.3-36.3-15L-862.6-16h-84.4l-13.6,69.3c-12.5,3.7-24.7,8.8-36.3,15.1l-58-38.8l-59.8,59.8l38.7,57.9
			c-6.2,11.5-11.3,23.7-15,36.3l-69.3,13.6v84.4l69.3,13.6c3.7,12.5,8.8,24.7,15.1,36.3l-38.8,58l59.8,59.8l58-38.6
			c11.5,6.3,23.7,11.3,36.3,15.1l13.6,69.3h84.4l13.6-69.3c12.5-3.7,24.7-8.8,36.3-15.1l58,38.6l59.8-59.8l-38.6-58
			c6.3-11.5,11.3-23.7,15.1-36.3L-649.5,281.7z M-733.7,385.6l-25,25l-53.2-35.4c-19,12.8-40.4,21.7-63,26.2l-12.5,63.6h-35.2
			l-12.5-63.6c-22.4-4.5-43.8-13.4-62.9-26.2l-53.2,35.4l-25-25l35.4-53.2c-12.8-19-21.7-40.4-26.2-63l-63.6-12.5v-35.1l63.6-12.5
			c4.5-22.4,13.4-43.8,26.2-62.9l-35.4-53.2l25-25l53.2,35.4c19-12.8,40.4-21.7,63-26.2l12.5-63.6h35.2l12.5,63.6
			c22.4,4.5,43.8,13.4,62.9,26.2l53.2-35.4l25,25l-35.4,53.2c12.8,19,21.7,40.4,26.2,63l63.6,12.5v35.2l-63.6,12.5
			c-4.5,22.5-13.4,43.9-26.2,63L-733.7,385.6z"
                    />
                </g>
            </g>
            <g transform="translate(1169.143 24.143)">
                <g transform="translate(0 0)">
                    <path
                        d="M-913,124.4c-59,0-106.9,47.9-106.9,106.9s47.9,106.9,106.9,106.9s106.9-47.9,106.9-106.9l0,0
			C-806.3,172.3-854,124.5-913,124.4z M-913,307.6c-42.1,0-76.4-34.1-76.4-76.4s34.1-76.4,76.4-76.4c42.1,0,76.4,34.1,76.4,76.4
			C-836.8,273.4-870.9,307.5-913,307.6z"
                    />
                </g>
            </g>
        </SVGIcon>
    );
}
