import React, { useState, useCallback, useRef } from 'react';
import DCCrossClose from 'Assets/icons/DCCrossClose';
import DCAgency from 'Assets/icons/DCAgency';
import DCHalfCheck from 'Assets/icons/DCHalfCheck';
import DCInstaSite from 'Assets/icons/DCInstasites';
import DCCheckout from 'Assets/icons/DCCheckout';
import DC4Boxes from 'Assets/icons/DC4Boxes';
import Checkout from 'Pages/Store/Checkout/checkout';
import {
    TopBar,
    Dialog,
    Div,
    IconButton,
    H1,
    Span,
    Image,
    Button,
    DropdownOptions,
} from 'UIKit/index';
import './style.scss';
import usePubSub from '../PubSub';
import { singleItemCheckout, getProductDetail, getProductDetails } from 'apis/store/index.api';
import { formatNumberString, getInterval } from 'Utils';
import useToast from 'Modules/Toasts/useToast';
import areValidScopes from 'Utils/scopeUtils';
import AccessScopes from 'Constants/scopes';
import OrderProcess from 'Pages/Store/Checkout/paymentProcess/orderProcess';

const features = [
    {
        icon: <DCHalfCheck />,
        title: 'Drag & Drop Builder',
        subTitle: `Use our drag and drop website builder and take control of your website without any IT or coding knowledge. Our custom content management system (CMS) makes editing your website a breeze.`,
    },
    {
        icon: <DCHalfCheck />,
        title: 'Lightning Fast Web Hosting',
        subTitle: `Web hosting is included in your Sites subscription! Hosted on Amazon Web Servers (AWS), we deliver light-speed load times so your visitors can browse fast with a 99.99% uptime rating.`,
    },
    {
        icon: <DCHalfCheck />,
        title: 'SSL Certificate & Daily Backups',
        subTitle: `An SSL certificate is included in your Sites subscription! Enable encrypted connections automatically and no more worrying about who's visiting your website. You're fully secure with our automated daily backups as well.`,
    },
    {
        icon: <DCHalfCheck />,
        title: 'Built-In Website Analytics',
        subTitle: `You'll automatically start tracking website visitors and the actions they take on your site with our built-in website analytics. View visitor volume, user engagements, form submissions and much more.`,
    },
];

export default function PurchaseDialog() {
    const { showToast } = useToast();
    const [addClick, setAddClick] = useState(false);
    const [productData, setProductData] = useState({});
    const [showPurchaseModal, setShowPurchaseModal] = useState(false);
    const [setupData, setSetupData] = useState({});
    const [thumbnail, setThumbnail] = useState();
    const { publish } = usePubSub();
    const [title, setTitle] = useState('');
    const buttonRef = useRef();
    const [dropdownOpen, setDropdownOpen] = useState(false);

    const [openCheckout, setOpenCheckout] = useState(false);
    const [checkoutData, setCheckoutData] = useState(null);

    const [singleItemBody, setSingleItemBody] = useState(null);
    const [orderSuccess, setOrderSuccess] = useState(false);
    const [priceOptions, setPriceOptions] = useState([]);

    const handleAddClick = useCallback(async () => {
        if (!areValidScopes(AccessScopes.root.STORE.text)) {
            showToast({ message: `You don't access of cart`, type: 'warning' });
            return;
        }

        let priceId = productData.id;
        if (priceId) {
            try {
                const res = await singleItemCheckout(
                    {
                        business: setupData?.businessId,
                        price: priceId,
                        external_action: setupData?.external_action,
                        ...(setupData?.waive_setup ? { waive_setup: true } : {}),
                    },
                    { type: 'preview' },
                );
                if (res.success) {
                    setSingleItemBody({
                        business: setupData?.businessId,
                        price: priceId,
                        external_action: setupData?.external_action,
                        ...(setupData?.waive_setup ? { waive_setup: true } : {}),
                    });
                    setCheckoutData(res.data);
                    setOpenCheckout(true);
                    setAddClick(true);
                } else {
                    // something went wrong
                    throw new Error(res.message || 'Something went wrong. Please try again');
                }
            } catch (err) {
                showToast({
                    message: err,
                    type: 'error',
                });
                setAddClick(false);
            }
        }
    }, [productData, setupData, showToast]);

    const handleClose = () => {
        setProductData(null);
        setOrderSuccess(false);
        setSetupData({});
        setAddClick(false);
        setShowPurchaseModal(false);
        title === 'Sites' && orderSuccess && publish('count-down-refreshBar');
    };

    usePubSub(
        'purchase_modal',
        useCallback(async data => {
            if (data?.utm === 'sites') {
                setTitle('Sites');
            } else {
                setTitle('InstaSites');
            }
            if (data?.thumbnail) {
                setThumbnail(data?.thumbnail);
            }
            setSetupData(data);
            setShowPurchaseModal(true);
            let _data;
            if (data?.utm !== 'sites' && data?.sitetype === 'E-commerce') {
                _data = await getProductDetails(data?.productId);
                const temp = [];
                _data.data?.prices?.forEach(item => {
                    item.metadata?.type !== 'informational' &&
                        temp.push({
                            priceId: item.id,
                            priceUnit: item.unit_amount,
                            priceName: item.nickname,
                            ...item,
                        });
                });
                temp.sort((a, b) => a.priceUnit - b.priceUnit);
                setPriceOptions(temp);
                setProductData({
                    ..._data.data,
                    priceId: temp[0].priceId,
                    unit_amount: temp[0].priceUnit,
                    nickname: temp[0].priceName,
                    ...temp[0],
                });
            } else {
                _data = await getProductDetail(data?.productId, data?.priceId);
                setProductData(_data.data);
            }
        }, []),
    );

    const handleDropdownToggle = useCallback(() => {
        setDropdownOpen(prevOpen => !prevOpen);
    }, []);

    const generateOptions = useCallback(() => {
        return priceOptions.map(item => {
            return {
                label: (
                    <Button
                        buttonClass={'DropdownOptionsBtn'}
                        onClick={() => {
                            setProductData(prev => ({
                                ...prev,
                                priceId: item.priceId,
                                unit_amount: item.priceUnit,
                                nickname: item.priceName,
                                ...item,
                            }));
                            handleDropdownToggle();
                        }}
                    >
                        {item.priceName} (${formatNumberString(item.priceUnit / 100)})
                    </Button>
                ),
            };
        });
    }, [handleDropdownToggle, priceOptions]);

    return (
        <Dialog
            classes={{ row: 'PCDRow' }}
            dialogType={'centerZoom sizeLg'}
            open={showPurchaseModal}
            onClose={handleClose}
        >
            <Div className={'PurchaseDialog'}>
                <TopBar>
                    <Div className={'HeaderBar'}>
                        <Div className={'PDHLeft'}>
                            <Div className={'PDHIcon'}>
                                {title === 'Sites' ? <DCAgency /> : <DCInstaSite />}
                            </Div>
                            <H1 className={'HeaderBarTitle'}>{title}</H1>
                        </Div>
                        <Div className={'PDHRight'}>
                            <Div className={'PDHRTitle'}>
                                Order {`${!orderSuccess ? 'Details' : 'Confirmation'}`}
                            </Div>
                            <Div className={'HeaderBarClose'}>
                                <IconButton onClick={handleClose}>
                                    <DCCrossClose />
                                </IconButton>
                            </Div>
                        </Div>
                    </Div>
                </TopBar>
                <Div className={'PDBody'}>
                    <Div className={'PDBInner'}>
                        {openCheckout && checkoutData && (
                            <Checkout
                                data={{ [checkoutData.buyer?.id]: [checkoutData] }}
                                actualCost={checkoutData.item?.actual_cost}
                                handleCongrats={() => {
                                    setOpenCheckout(false);
                                    let message = `Your plan has been successfully updated.`;
                                    showToast({
                                        message,
                                        type: 'success',
                                        duration: 3000,
                                    });
                                }}
                                waive_setup={!!setupData?.waive_setup}
                                dialogMode={true}
                                handleDialogClose={() => setOpenCheckout(false)}
                                singleItem={true}
                                isSubscription={false}
                                externalActionData={setupData?.external_action}
                                externalAction={true}
                                businessId={setupData?.businessId}
                                setOrderSuccess={setOrderSuccess}
                                siteApp={title === 'Sites' || title === 'InstaSites'}
                                fetchSingleItemData={() => {
                                    handleAddClick();
                                    return singleItemCheckout(singleItemBody, { type: 'preview' });
                                }}
                                totals={{
                                    promocode: checkoutData.promocode,
                                    total_discount_amounts:
                                        checkoutData.item.setup_amount -
                                            checkoutData.item.actual_cost.setup_fee ||
                                        checkoutData.item.amount -
                                            checkoutData.item.actual_cost.recurring_fee,
                                    total: 0,
                                }}
                            />
                        )}
                        <Div className={'PDBIVideoContent'}>
                            {!orderSuccess ? (
                                <>
                                    <Div className={'PDBIVCVideo  PDBIVCVideoAvailable'}>
                                        <iframe
                                            src={
                                                'https://player.vimeo.com/video/665362082?h=952d643ece'
                                            }
                                            frameBorder="0"
                                            webkitallowfullscreen=""
                                            mozallowfullscreen=""
                                            // width={'100%'}
                                        />
                                    </Div>
                                    <Div className={'PDBIVCFeatures'}>
                                        {features.map((feature, _index) => {
                                            return (
                                                <Div
                                                    className={'PDBIVCFBox'}
                                                    key={`feature-${_index}`}
                                                >
                                                    <Div className={'PDBIVCFBTitle'}>
                                                        <Div className={'PDBIVCFBTIcon'}>
                                                            {feature.icon}
                                                        </Div>
                                                        <Div className={'PDBIVCFBTText'}>
                                                            {feature.title}
                                                        </Div>
                                                    </Div>
                                                    <Div className={'PDBIVCFBDes'}>
                                                        {feature.subTitle}
                                                    </Div>
                                                </Div>
                                            );
                                        })}
                                    </Div>
                                </>
                            ) : (
                                <OrderProcess loading={false} congratsPage={true} title={title} />
                            )}
                        </Div>
                        {!orderSuccess ? (
                            <Div className={'PDBOrderDetail'}>
                                <Div className={'PDBODPreview'}>
                                    <Image
                                        src={thumbnail || productData?.product?.images[0]}
                                        alt={''}
                                        displayName={''}
                                    />
                                </Div>
                                <Div className={'PDBODDes'}>
                                    {productData?.additional_info?.description}
                                </Div>
                                <Div className={'PDBODPlans'}>
                                    <Div className={'PDBODPHead'}>
                                        <Div className={'PDBODPHCol'}>Subscription</Div>
                                        <Div className={'PDBODPHCol'}>
                                            {productData?.type === 'recurring'
                                                ? 'Recurring'
                                                : 'One Time'}
                                        </Div>
                                        <Div className={'PDBODPHCol'}>Setup</Div>
                                    </Div>
                                    <Div className={'PDBODPRow'}>
                                        <Div className={'PDBODPRPlan'}>
                                            <Div className={'PDBODPRPIcon'}>
                                                {title === 'Sites' ? <DCAgency /> : <DCInstaSite />}
                                            </Div>
                                            <Div className={'PDBODPRPInfo'}>
                                                <Div className={'PDBODPRPITitle'}>
                                                    {productData?.product?.name ||
                                                        productData?.name}{' '}
                                                    ({productData?.metadata?.type})
                                                </Div>
                                                <Div className={'PDBODPRPISub'}>
                                                    <Span>
                                                        {getInterval(
                                                            productData?.recurring?.interval,
                                                        )}
                                                    </Span>
                                                </Div>
                                            </Div>
                                        </Div>
                                        {title !== 'Sites' &&
                                        setupData?.sitetype === 'E-commerce' ? (
                                            <Div className={'PDBODPRCol'}>
                                                <Div className={'DCDropdownSelect fullWidth'}>
                                                    <Button
                                                        buttonClass={
                                                            'DCDSMainBtn ' +
                                                            (dropdownOpen === true ? 'focus' : '')
                                                        }
                                                        reference={buttonRef}
                                                        onClick={handleDropdownToggle}
                                                    >
                                                        {productData?.nickname} ($
                                                        {formatNumberString(
                                                            productData?.unit_amount / 100,
                                                        )}
                                                        )
                                                    </Button>
                                                    <DropdownOptions
                                                        options={generateOptions()}
                                                        onClose={handleDropdownToggle}
                                                        wrapperWidth={265}
                                                        open={dropdownOpen}
                                                        reference={buttonRef}
                                                        viewHeight={120}
                                                        itemSize={40}
                                                        placement={'bottom-end'}
                                                    />
                                                </Div>
                                            </Div>
                                        ) : (
                                            <Div className={'PDBODPRCol'}>
                                                $
                                                {formatNumberString(productData?.unit_amount / 100)}
                                            </Div>
                                        )}

                                        {!setupData?.waive_setup ? (
                                            <Div className={'PDBODPRCol'}>
                                                $
                                                {productData?.additional_info?.setup_fee
                                                    ? formatNumberString(
                                                          productData?.additional_info?.setup_fee /
                                                              100,
                                                      )
                                                    : 0}
                                            </Div>
                                        ) : (
                                            <Div className={'PDBODPRCol'}>$0</Div>
                                        )}
                                    </Div>
                                </Div>
                                <Div className={'PDBODBtn'}>
                                    <button
                                        className={'cart-button ' + (addClick ? 'clicked' : '')}
                                        onClick={handleAddClick}
                                    >
                                        <span className="add-to-cart">Checkout</span>
                                        <span className="added">Checkout</span>
                                        <i className="shopping-cart">
                                            <DCCheckout />
                                        </i>
                                        <i className="shoppingbox">
                                            <DC4Boxes />
                                        </i>
                                    </button>
                                </Div>
                            </Div>
                        ) : (
                            <Div className={'PDBOrderDetail PDBOrderCongrats'}>
                                <Div className={'PDBODPreview'}>
                                    <Image
                                        src={thumbnail || productData?.product?.images[0]}
                                        alt={''}
                                        displayName={''}
                                    />
                                </Div>
                                <Div className={'PDBODDes'}>
                                    <Div className={'PDBIVCFeatures'}>
                                        {features.map((feature, _index) => {
                                            return (
                                                <Div
                                                    className={'PDBIVCFBox'}
                                                    key={`feature-${_index}`}
                                                >
                                                    <Div className={'PDBIVCFBTitle'}>
                                                        <Div className={'PDBIVCFBTIcon'}>
                                                            {feature.icon}
                                                        </Div>
                                                        <Div className={'PDBIVCFBTText'}>
                                                            {feature.title}
                                                        </Div>
                                                    </Div>
                                                    <Div className={'PDBIVCFBDes'}>
                                                        {feature.subTitle}
                                                    </Div>
                                                </Div>
                                            );
                                        })}
                                    </Div>
                                </Div>
                            </Div>
                        )}
                    </Div>
                </Div>
            </Div>
        </Dialog>
    );
}
