import React from 'react';

import { OnlyDatePickerCalendar } from '../../Components/index';
import { Popover } from '../../UIKit/index';

import 'react-nice-dates/build/style.css';

import './style.scss';

function DCDatePickerCalendar({
    open,
    reference,
    onClose,
    width,
    height,
    placement,
    date,
    handleDatePicker,
    modifiersClassNames,
}) {
    return (
        <Popover
            open={open}
            anchorEl={reference}
            onClose={onClose}
            width={width}
            placement={placement || 'bottom-start'}
            withOutPadding
            height={height || 'auto'}
        >
            <OnlyDatePickerCalendar
                date={date}
                handleDatePicker={handleDatePicker}
                modifiersClassNames={modifiersClassNames}
            />
        </Popover>
    );
}

export default DCDatePickerCalendar;
