import React from 'react';

import { SVGIcon } from '../../UIKit/index';

export default function DCNote(props) {
    return (
        <SVGIcon {...props} viewBox="0 0 512 512">
            <g>
                <rect x="215.5" y="217.5" width="155.7" height="40.2" />
                <rect x="215.5" y="294.5" width="155.7" height="40.2" />
                <rect x="139.8" y="294.5" width="37.6" height="40.2" />
                <rect x="214.9" y="371.4" width="155.7" height="40.2" />
                <rect x="139.2" y="371.4" width="37.6" height="40.2" />
                <rect x="139.8" y="217.5" width="37.6" height="40.2" />
                <path d="M255.5,102.7v42.8c0,0,1.9,11.5,5.1,14.7H154c0,0-11.9-1.1-15.6-15.8v-26.6c0,0,2.6-14.5,17.5-15.1H255.5z" />
                <path d="M409.9,178.8v251.5c0,0,1,37.6-38.8,40.6H131.6c0,0-30.3-6.5-32-40.2V77.8c0,0,7.6-30.4,33.2-33.3h147.1   c0,0,12.8,0,13.3,20.1v75.7c0,0,0.7,17.8,17,19.9l81.4,0.9h56.2c0,0,3.9-12.1-8.1-26.4L419.1,110l-47.6-47.1l-36.1-36.3l-16-12.8   c0,0-12.5-9.1-23.6-8.4H129c0,0-62.1,7.4-67.9,72.2l-0.7,357.7c0,0,5.4,67.6,72,73h248.2c0,0,57.4-6.3,67.6-64.6V157.4l-55.8,3.6   l3.2,0.8C410,166.7,409.7,175.3,409.9,178.8L409.9,178.8z" />
            </g>
        </SVGIcon>
    );
}
