import React from 'react';

import { SVGIcon } from '../../UIKit/index';

export default function DCConstantContact(props) {
    return (
        <SVGIcon {...props} viewBox="0 0 332 57">
            <g>
                <path
                    fillRule="evenodd"
                    clipped="evenodd"
                    fill="#1856ed"
                    d="M230.3,35c-0.5-0.7-1.1-0.9-2-0.4c-1.3,0.7-2.9,1.2-4.5,1.2c-4.5,0-7.6-3.1-7.6-7.5c0-4.4,3.1-7.5,7.6-7.5   c1.5,0,3.2,0.4,4.5,1.2c0.9,0.5,1.5,0.4,2-0.4l0.5-0.7c0.5-0.8,0.4-1.5-0.3-2c-2-1.3-4.1-1.9-6.6-1.9c-3.5,0-6.6,1.2-8.8,3.4   c-2.1,2.1-3.2,4.9-3.2,8c0,3.1,1.1,5.9,3.2,8c2.2,2.2,5.2,3.4,8.8,3.4c2.6,0,4.7-0.6,6.8-2c0.6-0.5,0.7-1.1,0.2-1.9L230.3,35z"
                />
                <path
                    fillRule="evenodd"
                    clipped="evenodd"
                    fill="#1856ed"
                    d="M240.9,22.9c-4.8,0-8.6,3.7-8.6,8.3c0,4.7,3.8,8.4,8.6,8.4c4.9,0,8.7-3.7,8.7-8.4   C249.5,26.6,245.6,22.9,240.9,22.9z M240.9,36c-2.6,0-4.6-2.1-4.6-4.7c0-2.7,2-4.8,4.6-4.8c2.6,0,4.6,2.1,4.6,4.8   C245.4,33.9,243.4,36,240.9,36z"
                />
                <path
                    fillRule="evenodd"
                    clipped="evenodd"
                    fill="#1856ed"
                    d="M261.3,22.9c-2.3,0-4.1,1-4.9,2.7v-0.9c0-0.9-0.5-1.4-1.4-1.4h-1.4c-0.9,0-1.4,0.5-1.4,1.4v13.2   c0,0.9,0.5,1.4,1.4,1.4h1.4c0.9,0,1.4-0.5,1.4-1.4v-6.4c0-1.7,0.8-5,4-5c1.7,0,2.7,1.4,2.7,3.8v7.5c0,0.9,0.5,1.4,1.4,1.4h1.4   c0.9,0,1.4-0.5,1.4-1.4v-7.7C267.3,25.6,265,22.9,261.3,22.9z"
                />
                <path
                    fillRule="evenodd"
                    clipped="evenodd"
                    fill="#1856ed"
                    d="M298.5,23.2h-1.4c-0.9,0-1.4,0.5-1.4,1.4v0.7c-1.2-1.6-3.1-2.5-5.2-2.5c-4.5,0-7.8,3.5-7.8,8.3   c0,4.8,3.3,8.4,7.8,8.4c2.2,0,4-0.9,5.2-2.5v0.7c0,0.9,0.5,1.4,1.4,1.4h1.4c0.9,0,1.4-0.5,1.4-1.4V24.7   C300,23.8,299.4,23.2,298.5,23.2z M291.3,36c-2.6,0-4.4-2-4.4-4.8c0-2.8,1.8-4.8,4.4-4.8c2.6,0,4.5,2,4.5,4.8   C295.8,34.1,293.9,36,291.3,36z"
                />
                <path
                    fillRule="evenodd"
                    clipped="evenodd"
                    fill="#1856ed"
                    d="M312,26.5c0.5,0,1.6,0.1,2.7,0.6c0.9,0.4,1.5,0.3,2-0.4l0.4-0.5c0.3-0.4,0.4-0.8,0.3-1.1   c-0.1-0.3-0.3-0.6-0.7-0.9c-1.9-1.1-3.7-1.4-4.9-1.4c-5.4,0-9.1,3.5-9.1,8.5c0,4.8,3.8,8.2,9.1,8.2c1.1,0,2.8-0.2,4.5-1.1   c0.5-0.3,0.9-0.7,1-1.1c0.1-0.4,0-0.7-0.3-1.1l-0.4-0.5c-0.5-0.7-1.1-0.8-2-0.3c-1.2,0.6-2.5,0.6-2.7,0.6c-3,0-5.1-2-5.1-4.8   C306.8,28.5,309,26.5,312,26.5z"
                />
                <path
                    fillRule="evenodd"
                    clipped="evenodd"
                    fill="#1856ed"
                    d="M331.2,36.5l-1-1.3c-0.2-0.2-0.3-0.3-0.5-0.3c-0.2,0-0.4,0.1-0.6,0.3c0,0-0.1,0.1-0.1,0.1l0,0   c-0.1,0.2-0.6,0.6-1.2,0.7c0,0,0,0,0,0c-0.2,0-0.4,0.1-0.6,0c-0.9-0.1-1.4-0.7-1.4-1.9v-7.7h3.8c0.5,0,0.8-0.3,0.8-0.8v-1.6   c0-0.5-0.3-0.8-0.8-0.8h-3.8v-1.4h0v-1.6c0-0.9-0.5-1.4-1.4-1.4h-1.3c-0.9,0-1.4,0.5-1.4,1.4v2.9h-1.6c-0.5,0-0.8,0.3-0.8,0.8v1.6   c0,0.5,0.3,0.8,0.8,0.8h1.6v1.4h0V34c0,4.9,3.4,5.6,5.4,5.6c0.7,0,1.3-0.1,1.9-0.2c0,0,0,0,0,0c0.3-0.1,0.6-0.2,0.9-0.4   c0.5-0.2,0.8-0.5,1.2-0.9c0.2-0.2,0.4-0.4,0.5-0.6C331.6,37.2,331.5,36.8,331.2,36.5z"
                />
                <path
                    fillRule="evenodd"
                    clipped="evenodd"
                    fill="#1856ed"
                    d="M280.2,35.2c-0.2-0.2-0.3-0.3-0.5-0.3c-0.2,0-0.4,0.1-0.6,0.3c0,0-0.1,0.1-0.1,0.1c0,0,0,0,0,0   c-0.1,0.2-0.6,0.6-1.2,0.7c0,0,0,0,0,0c-0.2,0-0.4,0.1-0.6,0c-0.9-0.1-1.4-0.7-1.4-1.9v-7.7h3.8c0.5,0,0.8-0.3,0.8-0.8v-1.6   c0-0.5-0.3-0.8-0.8-0.8h-3.8v-1.4v-1.6c0-0.9-0.5-1.4-1.4-1.4H273c-0.9,0-1.4,0.5-1.4,1.4v2.9h-1.6c-0.5,0-0.8,0.3-0.8,0.8v1.6   c0,0.5,0.3,0.8,0.8,0.8h1.6v1.4V34c0,4.9,3.4,5.6,5.4,5.6c0.7,0,1.3-0.1,1.9-0.2c0,0,0,0,0,0c0.3-0.1,0.6-0.2,0.9-0.4   c0.5-0.2,0.8-0.5,1.2-0.9c0.2-0.2,0.4-0.4,0.5-0.6c0.3-0.3,0.2-0.7-0.1-1.1L280.2,35.2z"
                />
                <path
                    fillRule="evenodd"
                    clipped="evenodd"
                    fill="#1856ed"
                    d="M85.9,34.9c-0.5-0.7-1.1-0.9-2-0.4c-1.3,0.7-2.9,1.2-4.5,1.2c-4.5,0-7.6-3.1-7.6-7.5c0-4.4,3.1-7.5,7.6-7.5   c1.5,0,3.2,0.4,4.5,1.2c0.9,0.5,1.5,0.4,2-0.4l0.5-0.7c0.5-0.8,0.4-1.5-0.3-2c-2-1.3-4.1-1.9-6.6-1.9c-3.5,0-6.6,1.2-8.8,3.4   c-2.1,2.1-3.2,4.9-3.2,8c0,3.1,1.1,5.9,3.2,8c2.2,2.2,5.2,3.4,8.8,3.4c2.6,0,4.7-0.6,6.8-2c0.6-0.5,0.7-1.1,0.2-1.9L85.9,34.9z"
                />
                <path
                    fillRule="evenodd"
                    clipped="evenodd"
                    fill="#1856ed"
                    d="M96.5,22.9c-4.8,0-8.6,3.7-8.6,8.3c0,4.7,3.8,8.4,8.6,8.4c4.9,0,8.7-3.7,8.7-8.4   C105.1,26.6,101.2,22.9,96.5,22.9z M96.5,36c-2.6,0-4.6-2.1-4.6-4.7c0-2.7,2-4.8,4.6-4.8c2.6,0,4.6,2.1,4.6,4.8   C101,33.9,99,36,96.5,36z"
                />
                <path
                    fillRule="evenodd"
                    clipped="evenodd"
                    fill="#1856ed"
                    d="M116.9,22.9c-2.3,0-4.1,1-4.9,2.7v-0.9c0-0.9-0.5-1.4-1.4-1.4h-1.4c-0.9,0-1.4,0.5-1.4,1.4v13.2   c0,0.9,0.5,1.4,1.4,1.4h1.4c0.9,0,1.4-0.5,1.4-1.4v-6.4c0-1.7,0.8-5,4-5c1.7,0,2.7,1.4,2.7,3.8v7.5c0,0.9,0.5,1.4,1.4,1.4h1.4   c0.9,0,1.4-0.5,1.4-1.4v-7.7C122.9,25.6,120.6,22.9,116.9,22.9z"
                />
                <path
                    fillRule="evenodd"
                    clipped="evenodd"
                    fill="#1856ed"
                    d="M133.4,29.9l-2.1-0.7c-1.1-0.3-1.5-0.8-1.5-1.5c0-0.9,0.8-1.5,1.8-1.5c0.7,0,1.2,0.2,1.6,0.7   c0.5,0.6,1,0.8,1.8,0.7l0.7-0.2c0.5-0.1,0.9-0.4,1-0.8c0.1-0.3,0.1-0.7-0.2-1.1c-1-1.7-2.7-2.6-5-2.6c-3.3,0-5.7,2.1-5.7,5   c0,2.1,1.4,3.7,4.3,4.5l2,0.7c1.2,0.4,1.6,0.9,1.6,1.5c0,0.9-0.9,1.6-2.2,1.6c-1,0-2-0.4-2.5-1.1c-0.5-0.6-0.9-1-1.8-0.8l-0.8,0.2   c-0.5,0.1-0.9,0.4-1,0.7c-0.1,0.3-0.1,0.7,0.1,1.1l0,0c1,2,3.2,3.2,6,3.2c4.2,0,6.1-2.5,6.1-5C137.6,33,136.8,31,133.4,29.9z"
                />
                <path
                    fillRule="evenodd"
                    clipped="evenodd"
                    fill="#1856ed"
                    d="M167.9,23.2h-1.4c-0.9,0-1.4,0.5-1.4,1.4v0.7c-1.2-1.6-3.1-2.5-5.2-2.5c-4.5,0-7.8,3.5-7.8,8.3   c0,4.8,3.3,8.4,7.8,8.4c2.2,0,4-0.9,5.2-2.5v0.7c0,0.9,0.5,1.4,1.4,1.4h1.4c0.9,0,1.4-0.5,1.4-1.4V24.6   C169.4,23.7,168.8,23.2,167.9,23.2z M160.7,36c-2.6,0-4.4-2-4.4-4.8c0-2.8,1.8-4.8,4.4-4.8c2.6,0,4.5,2,4.5,4.8   C165.2,34,163.3,36,160.7,36z"
                />
                <path
                    fillRule="evenodd"
                    clipped="evenodd"
                    fill="#1856ed"
                    d="M182.2,22.9c-2.3,0-4.1,1-4.9,2.7v-0.9c0-0.9-0.5-1.4-1.4-1.4h-1.4c-0.9,0-1.4,0.5-1.4,1.4v13.2   c0,0.9,0.5,1.4,1.4,1.4h1.4c0.9,0,1.4-0.5,1.4-1.4v-6.4c0-1.7,0.8-5,4-5c1.7,0,2.7,1.4,2.7,3.8v7.5c0,0.9,0.5,1.4,1.4,1.4h1.4   c0.9,0,1.4-0.5,1.4-1.4v-7.7C188.2,25.6,185.9,22.9,182.2,22.9z"
                />
                <path
                    fillRule="evenodd"
                    clipped="evenodd"
                    fill="#1856ed"
                    d="M200.9,35.1c-0.2-0.2-0.3-0.3-0.5-0.3c-0.2,0-0.4,0.1-0.6,0.3c0,0-0.1,0.1-0.1,0.1c0,0,0,0,0,0   c-0.1,0.2-0.6,0.6-1.2,0.7c0,0,0,0,0,0c-0.2,0-0.4,0.1-0.6,0c-0.9-0.1-1.4-0.7-1.4-1.9v-7.7h3.8c0.5,0,0.8-0.3,0.8-0.8V24   c0-0.5-0.3-0.8-0.8-0.8h-3.8v-1.4v-1.6c0-0.9-0.5-1.4-1.4-1.4h-1.3c-0.9,0-1.4,0.5-1.4,1.4v2.9h-1.6c-0.5,0-0.8,0.3-0.8,0.8v1.6   c0,0.5,0.3,0.8,0.8,0.8h1.6V34c0,4.9,3.4,5.6,5.4,5.6c0.7,0,1.3-0.1,1.9-0.2c0,0,0,0,0,0c0.3-0.1,0.6-0.2,0.9-0.4   c0.5-0.2,0.8-0.5,1.2-0.9c0.2-0.2,0.4-0.4,0.5-0.6c0.3-0.3,0.2-0.7-0.1-1.1L200.9,35.1z"
                />
                <path
                    fillRule="evenodd"
                    clipped="evenodd"
                    fill="#1856ed"
                    d="M149.9,35.1c-0.2-0.2-0.3-0.3-0.5-0.3c-0.2,0-0.4,0.1-0.6,0.3c0,0-0.1,0.1-0.1,0.1l0,0   c-0.1,0.2-0.6,0.6-1.2,0.7c0,0,0,0,0,0c-0.2,0-0.4,0.1-0.6,0c-0.9-0.1-1.4-0.7-1.4-1.9v-7.7h3.8c0.5,0,0.8-0.3,0.8-0.8V24   c0-0.5-0.3-0.8-0.8-0.8h-3.8v-1.4h0v-1.6c0-0.9-0.5-1.4-1.4-1.4h-1.3c-0.9,0-1.4,0.5-1.4,1.4v2.9h-1.6c-0.5,0-0.8,0.3-0.8,0.8v1.6   c0,0.5,0.3,0.8,0.8,0.8h1.6v1.4V34c0,4.9,3.4,5.6,5.4,5.6c0.7,0,1.3-0.1,1.9-0.2c0,0,0,0,0,0c0.3-0.1,0.6-0.2,0.9-0.4   c0.5-0.2,0.8-0.5,1.2-0.9c0.2-0.2,0.4-0.4,0.5-0.6c0.3-0.3,0.2-0.7-0.1-1.1L149.9,35.1z"
                />
                <path
                    fillRule="evenodd"
                    clipped="evenodd"
                    fill="#FF9E1A"
                    d="M36.3,23.1c0.5,1.5,1.9,2.4,3.5,2.4c0.4,0,0.8-0.1,1.2-0.2c1.9-0.7,2.9-2.8,2.2-4.7c-1.2-3.3-3.7-5.9-7.2-7.5   c-1.8-0.8-4,0-4.9,1.8c-0.8,1.8,0,4,1.8,4.9C34.7,20.6,35.8,21.7,36.3,23.1z"
                />
                <path
                    fillRule="evenodd"
                    clipped="evenodd"
                    fill="#FF9E1A"
                    d="M35.1,0.1c-2-0.4-3.9,0.9-4.3,2.9c-0.4,2,0.9,3.9,2.9,4.3C42,8.9,47.6,14.5,49,22.6c0.3,1.8,1.9,3,3.6,3   c0.2,0,0.4,0,0.6-0.1c2-0.4,3.3-2.3,3-4.2C54.3,10.3,46.4,2.3,35.1,0.1z"
                />
                <path
                    fillRule="evenodd"
                    clipped="evenodd"
                    fill="#1856ed"
                    d="M25.8,36.7c-3.3,0-6.1-2.7-6.1-6.1c0-3.3,2.7-6.1,6.1-6.1c2,0,3.7-1.6,3.7-3.7s-1.6-3.7-3.7-3.7   c-7.4,0-13.4,6-13.4,13.4c0,7.4,6,13.4,13.4,13.4c7.4,0,13.4-6,13.4-13.4c0-2-1.6-3.7-3.7-3.7c-2,0-3.7,1.6-3.7,3.7   C31.9,34,29.1,36.7,25.8,36.7z"
                />
                <path
                    fillRule="evenodd"
                    clipped="evenodd"
                    fill="#1856ed"
                    d="M47.9,27c-2,0-3.7,1.6-3.7,3.7c0,10.2-8.3,18.5-18.5,18.5c-10.2,0-18.5-8.3-18.5-18.5   c0-10.2,8.3-18.5,18.5-18.5c2,0,3.7-1.6,3.7-3.7c0-2-1.6-3.7-3.7-3.7C11.6,4.8,0,16.4,0,30.6c0,14.2,11.6,25.8,25.8,25.8   s25.8-11.6,25.8-25.8C51.6,28.6,50,27,47.9,27z"
                />
            </g>
        </SVGIcon>
    );
}
