import React from 'react';

import { SVGIcon } from '../../UIKit/index';

export default function DCLongText(props) {
    return (
        <SVGIcon {...props} viewBox="0 0 512 512">
            <g>
                <g transform="translate(3.903 8.667)">
                    <path d="M152.51,196.02H99.35c-8.8-0.02-15.91-7.17-15.88-15.97c0.02-8.8,7.17-15.91,15.97-15.88h53.16    c8.8,0.01,15.92,7.14,15.92,15.94c-0.01,8.79-7.13,15.91-15.92,15.92H152.51z" />
                </g>
                <g transform="translate(10.612 8.667)">
                    <path d="M400.54,196.02h-159.2c-8.79-0.33-15.65-7.72-15.32-16.51c0.31-8.33,6.99-15.01,15.32-15.32    h159.2c8.8,0.01,15.92,7.14,15.92,15.94c-0.01,8.79-7.13,15.91-15.92,15.92V196.02z" />
                </g>
                <g transform="translate(3.903 12.5)">
                    <path d="M152.51,277.09H99.35c-8.79-0.33-15.65-7.72-15.32-16.51c0.31-8.33,6.99-15.01,15.32-15.32    h53.16c8.8,0.01,15.92,7.14,15.92,15.94c-0.01,8.79-7.13,15.91-15.92,15.92V277.09z" />
                </g>
                <g transform="translate(10.612 12.5)">
                    <path d="M400.54,277.09h-159.2c-8.79-0.33-15.65-7.72-15.32-16.51c0.31-8.33,6.99-15.01,15.32-15.32    h159.2c8.8,0.01,15.92,7.14,15.92,15.94c-0.01,8.79-7.13,15.91-15.92,15.92V277.09z" />
                </g>
                <g transform="translate(3.903 16.333)">
                    <path d="M152.51,358.16H99.35c-8.79-0.33-15.65-7.72-15.32-16.51c0.31-8.33,6.99-15.01,15.32-15.32    h53.16c8.8,0.01,15.92,7.14,15.92,15.94c-0.01,8.79-7.13,15.91-15.92,15.92V358.16z" />
                </g>
                <g transform="translate(10.612 16.333)">
                    <path d="M400.54,358.16h-159.2c-8.79-0.33-15.65-7.72-15.32-16.51c0.31-8.33,6.99-15.01,15.32-15.32    h159.2c8.8,0.01,15.92,7.14,15.92,15.94c-0.01,8.79-7.13,15.91-15.92,15.92V358.16z" />
                </g>
                <g transform="translate(0 1)">
                    <path d="M452.07,469.01H59.33C27.11,468.97,1,442.86,0.96,410.64V60.37C1,28.15,27.11,2.04,59.33,2    h392.74c32.22,0.04,58.33,26.15,58.37,58.37v350.28C510.4,442.86,484.29,468.97,452.07,469.01z M59.33,33.85    c-14.68,0-26.58,11.9-26.58,26.58l0,0v350.21c0,14.68,11.9,26.58,26.58,26.58c0,0,0,0,0,0h392.74c14.68,0,26.58-11.9,26.58-26.58    v0V60.37c0-14.68-11.9-26.58-26.58-26.58l0,0L59.33,33.85z" />
                </g>
                <g transform="translate(0 4.354)">
                    <path d="M494.51,104.79H16.89C8.1,104.47,1.24,97.07,1.56,88.28c0.31-8.33,6.99-15.01,15.32-15.32    h477.62c8.8,0.01,15.92,7.14,15.92,15.94c-0.01,8.79-7.13,15.91-15.92,15.92V104.79z" />
                </g>
            </g>
        </SVGIcon>
    );
}
