import React from 'react';

import { SVGIcon } from '../../UIKit/index';

export default function DCConversations(props) {
    return (
        <SVGIcon
            width="512"
            height="512"
            viewBox="0 0 512 512"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <rect width="512" height="512" rx="100" fill="#0083FF" />
            <g filter="url(#filter0_i_2708_66)">
                <rect width="512" height="512" rx="100" fill="url(#paint0_linear_2708_66)" />
            </g>
            <path
                d="M53 123C53 84.3401 84.3401 53 123 53H303C341.66 53 373 84.3401 373 123V231.42C373 236.943 368.523 241.42 363 241.42H123C84.3401 241.42 53 210.08 53 171.42V123Z"
                fill="#286AEA"
            />
            <path
                d="M459 340C459 301.34 427.66 270 389 270H209C170.34 270 139 301.34 139 340V448.42C139 453.943 143.477 458.42 149 458.42H389C427.66 458.42 459 427.08 459 388.42V340Z"
                fill="#BEE9FF"
            />
            <rect x="117" y="109" width="190.98" height="25.09" rx="12.545" fill="white" />
            <rect x="204" y="334" width="190.98" height="25.09" rx="12.545" fill="white" />
            <rect x="117" y="150" width="110.08" height="25.09" rx="12.545" fill="white" />
            <rect x="204" y="375" width="110.08" height="25.09" rx="12.545" fill="white" />
            <defs>
                <filter
                    id="filter0_i_2708_66"
                    x="0"
                    y="-4"
                    width="512"
                    height="516"
                    filterUnits="userSpaceOnUse"
                    color-interpolation-filters="sRGB"
                >
                    <feFlood flood-opacity="0" result="BackgroundImageFix" />
                    <feBlend
                        mode="normal"
                        in="SourceGraphic"
                        in2="BackgroundImageFix"
                        result="shape"
                    />
                    <feColorMatrix
                        in="SourceAlpha"
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                        result="hardAlpha"
                    />
                    <feOffset dy="-4" />
                    <feGaussianBlur stdDeviation="5" />
                    <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
                    <feColorMatrix
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"
                    />
                    <feBlend mode="normal" in2="shape" result="effect1_innerShadow_2708_66" />
                </filter>
                <linearGradient
                    id="paint0_linear_2708_66"
                    x1="256"
                    y1="0"
                    x2="256"
                    y2="512"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stop-color="white" />
                    <stop offset="1" stop-color="#F1F3F3" />
                </linearGradient>
            </defs>
        </SVGIcon>
    );
}
