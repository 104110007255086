import React from 'react';

import { SVGIcon } from '../../UIKit/index';

export default function DCAwardTag(props) {
    return (
        <SVGIcon {...props} viewBox="0 0 512 512">
            <g transform="translate(1.936 17.563)">
                <g>
                    <path d="M170,382.3c-19.3,18.3-48,22.6-71.9,10.8c-8.6-4.1-16.1-9.9-22.2-17.2l-33.3,73.6    c-1.5,3.2-0.1,7,3,8.5c1.4,0.7,3,0.8,4.5,0.4l24.2-6.7c14.4-4,29.6,2.9,36.1,16.3l10.9,22.6c1.5,3.2,5.4,4.5,8.5,3    c1.4-0.7,2.4-1.8,3.1-3.1l52.9-117C180,375.2,174.6,378.2,170,382.3z" />
                </g>
            </g>
            <g transform="translate(9.585 17.563)">
                <g transform="translate(0)">
                    <path d="M347.7,449.5l-33.3-73.6c-6.1,7.3-13.7,13.1-22.2,17.2c-23.9,11.8-52.6,7.5-71.9-10.8    c-4.6-4.2-10-7.3-16-9l52.9,117c1.4,3.2,5.2,4.7,8.5,3.2c1.4-0.6,2.5-1.7,3.1-3.1l10.9-22.6c6.5-13.5,21.7-20.3,36.1-16.3    l24.2,6.7c3.4,1,6.9-1,7.9-4.4c0.4-1.5,0.3-3.1-0.4-4.5L347.7,449.5z" />
                </g>
            </g>
            <g transform="translate(0)">
                <g>
                    <path d="M383.4,208.2c-27.7-19.1-36.2-56.2-19.5-85.5c11.1-19.2,4.5-43.7-14.8-54.8    c-9.7-5.6-21.2-6.9-31.9-3.6c-32.2,9.7-66.5-6.8-79-38.1c-8.1-20.6-31.5-30.7-52.1-22.6c-10.3,4.1-18.5,12.3-22.6,22.6    c-12.5,31.2-46.8,47.8-79,38.1c-21.2-6.6-43.7,5.3-50.2,26.5c-3.3,10.7-2,22.2,3.6,31.9c16.6,29.3,8.2,66.4-19.5,85.5    C0.1,220.7-4.6,245.7,8,263.9c6.3,9.2,16.1,15.3,27,17c33.3,5.1,57.2,34.9,54.9,68.6c-1.6,22.1,15,41.3,37.1,42.9    c11.1,0.8,22-3,30.1-10.5c24.8-22.7,62.9-22.7,87.7,0c16.3,15,41.7,14.1,56.7-2.2c7.6-8.2,11.4-19.1,10.6-30.2    c-2.3-33.6,21.4-63.3,54.7-68.6c21.9-3.4,37-23.8,33.6-45.7C398.7,224.2,392.6,214.4,383.4,208.2L383.4,208.2z M201,336    c-72.5,0-131.3-58.8-131.3-131.3S128.5,73.3,201,73.3c72.5,0,131.3,58.8,131.3,131.3C332.3,277.2,273.5,335.9,201,336L201,336z" />
                </g>
            </g>
            <g transform="translate(4.189 4.368)">
                <g>
                    <path d="M196.8,93.5c-59,0-106.9,47.8-106.9,106.9c0,59,47.8,106.9,106.9,106.9    c59,0,106.9-47.8,106.9-106.9C303.6,141.3,255.8,93.5,196.8,93.5z M251.5,190l-66.5,48.6c-5.5,4-13.1,2.8-17.1-2.7    c0-0.1-0.1-0.1-0.1-0.2l-20-28.3c-4.1-5.3-3.2-13,2.2-17.2c5.3-4.1,13-3.2,17.2,2.2c0.2,0.3,0.4,0.6,0.6,0.9l12.7,18.1l56.4-41.2    c5.5-4,13.1-2.8,17.1,2.7c4,5.5,2.8,13.1-2.7,17.1l0,0L251.5,190z" />
                </g>
            </g>
        </SVGIcon>
    );
}
