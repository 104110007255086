import React from 'react';

import { SVGIcon } from '../../UIKit/index';

export default function DCStep(props) {
    return (
        <SVGIcon {...props} viewBox="0 0 512 512">
            <g>
                <path d="m459.406 389.683c-2.812-2.813-6.628-4.394-10.606-4.394h-17.133v-196.117c45.465-7.217 80.333-46.686 80.333-94.15-4.793-126.3-185.893-126.264-190.667.001 0 47.463 34.868 86.932 80.334 94.148v196.117h-64.894l31.379 31.38c8.5 8.499 13.181 19.8 13.181 31.82s-4.681 23.321-13.181 31.82l-31.379 31.38h112.027c3.979 0 7.794-1.581 10.606-4.394l48.2-48.2c2.813-2.813 4.394-6.628 4.394-10.606 0-3.979-1.58-7.793-4.394-10.606zm-50.773-263.594c-3.839 0-7.678-1.464-10.606-4.394l-16.066-16.066c-13.534-14.57 6.643-34.748 21.213-21.213 0 0 5.46 5.459 5.46 5.459l21.527-21.526c14.567-13.533 34.748 6.639 21.213 21.213 0 0-32.133 32.133-32.133 32.133-2.93 2.93-6.769 4.394-10.608 4.394z" />
                <path d="m346.94 437.882-48.199-48.2c-2.812-2.813-6.628-4.394-10.606-4.394h-17.135v-35.45c45.465-7.217 80.333-46.685 80.333-94.149-4.793-126.3-185.893-126.264-190.667.001 0 47.463 34.868 86.932 80.334 94.148v35.45h-64.894l31.38 31.38c17.545 17.545 17.545 46.094 0 63.64l-31.38 31.38h112.028c3.979 0 7.794-1.581 10.606-4.394l48.199-48.2c5.859-5.857 5.859-15.354.001-21.212zm-98.974-151.126c-3.839 0-7.678-1.464-10.606-4.394l-16.066-16.066c-13.534-14.57 6.643-34.748 21.213-21.213 0 0 5.46 5.459 5.46 5.459l21.527-21.526c14.567-13.533 34.748 6.639 21.213 21.213 0 0-32.133 32.133-32.133 32.133-2.93 2.93-6.769 4.394-10.608 4.394z" />
                <path d="m186.273 437.882-48.2-48.2c-2.813-2.813-6.628-4.394-10.606-4.394h-17.133v-196.116c45.465-7.217 80.333-46.685 80.333-94.149-4.794-126.301-185.893-126.264-190.667.001 0 47.463 34.868 86.932 80.334 94.148v196.117h-65.334c-8.284 0-15 6.716-15 15v96.4c0 8.284 6.716 15 15 15h112.467c3.978 0 7.793-1.581 10.606-4.394l48.2-48.2c5.858-5.858 5.858-15.355 0-21.213zm-98.973-311.793c-3.839 0-7.678-1.464-10.606-4.394l-16.066-16.066c-13.534-14.57 6.642-34.748 21.212-21.213 0 0 5.46 5.459 5.46 5.459l21.527-21.526c14.567-13.533 34.748 6.639 21.213 21.213 0 0-32.133 32.133-32.133 32.133-2.929 2.93-6.768 4.394-10.607 4.394z" />
            </g>
        </SVGIcon>
    );
}
