import React from 'react';

import { SVGIcon } from '../../UIKit/index';

export default function DCAppMarketplace(props) {
    return (
        <SVGIcon {...props} viewBox="0 0 512 450">
            <g transform="translate(-167 -273.577)">
                <g transform="translate(167 242.527)">
                    <g transform="translate(0 31.05)">
                        <path
                            fill="#005EFF"
                            d="M362.1,245.1h-68.5c-18.8,0-34,15.2-34,34v52.5c0,18.8,15.2,34,34,34h68.5     c18.8,0,34-15.2,34-34v-52.5C396,260.3,380.8,245.1,362.1,245.1z M371.3,331.6c0,5.1-4.2,9.2-9.3,9.3h-68.5     c-5.1,0-9.2-4.2-9.3-9.3v-52.5c0-5.1,4.2-9.2,9.3-9.3h68.5c5.1,0,9.2,4.2,9.3,9.3V331.6z"
                        />
                        <path
                            fill="#005EFF"
                            d="M508,149.6c0-2.1-0.5-4.3-1.6-6.1l-75-131.8c-2.2-3.9-6.3-6.2-10.7-6.3H91.2     c-4.5,0-8.6,2.4-10.7,6.3L5.6,143.6c-1.1,1.9-1.6,4-1.6,6.1c0,28.7,17,54.6,43.3,66.1v217.4c0,6.8,5.5,12.4,12.4,12.4h392.7     c6.8,0,12.3-5.5,12.4-12.4V217.3c0-0.4,0-0.9-0.1-1.3C490.9,204.4,508,178.4,508,149.6z M98.4,30.3h315L472,133.4H39.9L98.4,30.3     z M362.7,158c-4.6,25.8-29.2,43.1-55,38.5c-19.6-3.5-35-18.8-38.5-38.5H362.7z M242.9,158c-4.7,25.9-29.4,43-55.2,38.4     c-19.5-3.5-34.8-18.8-38.4-38.4H242.9z M29.5,158h93.6c-4.7,25.9-29.4,43-55.2,38.4C48.4,192.9,33.1,177.6,29.5,158z      M211.6,420.8h-71.1V287.4c0-9.7,7.9-17.6,17.6-17.6c0,0,0,0,0,0h35.9c9.7,0,17.6,7.9,17.6,17.6c0,0,0,0,0,0v133.4H211.6z      M439.9,420.8H236.3V287.4c0-23.4-19-42.3-42.3-42.3h-35.9c-23.4,0-42.3,19-42.3,42.3v133.5H71.9V221.8c1.4,0.1,2.9,0.1,4.3,0.1     c24,0,46.5-12,59.9-31.9c22.3,33.1,67.1,41.9,100.2,19.6c7.8-5.2,14.4-11.9,19.6-19.6c22.2,33,66.9,41.9,99.9,19.7     c7.8-5.2,14.5-11.9,19.7-19.7c13.4,20,35.9,31.9,59.9,31.9c1.4,0,2.8-0.1,4.2-0.1L439.9,420.8L439.9,420.8z M435.7,197.2     c-23,0-42.7-16.5-46.8-39.1h93.6C478.4,180.7,458.7,197.1,435.7,197.2z"
                        />
                    </g>
                </g>
                <path fill="#D5E5FF" d="M265.3,304l314.9-0.3l28.2,48.7l-370.7,0.5L265.3,304z" />
            </g>
        </SVGIcon>
    );
}
