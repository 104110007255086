import React from 'react';

import { SVGIcon } from '../../UIKit/index';

export default function DCCheckedBoxFilled(props) {
    return (
        <SVGIcon {...props} viewBox="0 0 512 512">
            <rect x="-.42" y="-.9" width="512.9" height="512.9" rx="95.2" ry="95.2" />
            <polygon
                points="438.34 159.68 408.22 129.56 213.05 324.74 106.39 218.08 76.27 248.2 212.96 384.9 213.05 384.81 213.13 384.9 438.34 159.68"
                style={{ fill: '#fff' }}
            />
        </SVGIcon>
    );
}
