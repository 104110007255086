import React from 'react';

import { SVGIcon } from '../../UIKit/index';

export default function DCIndeterminateCheckBox1(props) {
    return (
        <SVGIcon {...props} viewBox="0 0 512 512">
            <path d="M404.52,512H107.48c-59.27,0-107.48-48.22-107.48-107.48V107.48C0,48.22,48.22,0,107.48,0H404.52c59.27,0,107.48,48.22,107.48,107.48V404.52c0,59.27-48.22,107.48-107.48,107.48ZM107.48,39.6c-37.43,0-67.88,30.45-67.88,67.88V404.52c0,37.43,30.45,67.88,67.88,67.88H404.52c37.43,0,67.88-30.45,67.88-67.88V107.48c0-37.43-30.45-67.88-67.88-67.88H107.48Z" />
            <path d="M379.38,279.48H132.62c-10.94,0-19.8-8.87-19.8-19.8s8.87-19.8,19.8-19.8h246.76c10.94,0,19.8,8.87,19.8,19.8s-8.87,19.8-19.8,19.8Z" />
        </SVGIcon>
    );
}
