import React from 'react';

import { SVGIcon } from '../../UIKit/index';

export default function DCCustomProperties(props) {
    return (
        <SVGIcon {...props} viewBox="0 0 503.46 384.08">
            <g id="Custom_Properties" transform="translate(-3.92 -61.61)">
                <g id="Layer_1" data-name="Layer 1">
                    <circle
                        id="Ellipse_1"
                        data-name="Ellipse 1"
                        cx="11.31"
                        cy="11.31"
                        r="11.31"
                        transform="translate(4.92 290.11)"
                        fill="#fff"
                        stroke="#e1e1e1"
                        strokeMiterlimit="10"
                        strokeWidth="2"
                    />
                    <circle
                        id="Ellipse_2"
                        data-name="Ellipse 2"
                        cx="7.94"
                        cy="7.94"
                        r="7.94"
                        transform="translate(409.1 111.23)"
                        fill="#fff"
                        stroke="#e1e1e1"
                        strokeMiterlimit="10"
                        strokeWidth="2"
                    />
                    <circle
                        id="Ellipse_3"
                        data-name="Ellipse 3"
                        cx="11.6"
                        cy="11.6"
                        r="11.6"
                        transform="translate(458.75 127.53)"
                        fill="#fff"
                        stroke="#e1e1e1"
                        strokeMiterlimit="10"
                        strokeWidth="2"
                    />
                    <path
                        id="Path_1"
                        data-name="Path 1"
                        d="M87.56,170.16H80.41V163a.89.89,0,0,0-.89-.89h0a.91.91,0,0,0-.91.89v7.13H71.49a.9.9,0,1,0,0,1.8h7.12v7.15a.91.91,0,0,0,.91.88.88.88,0,0,0,.89-.88h0V172h7.15a.89.89,0,0,0,.89-.89.91.91,0,0,0-.89-.95Z"
                        fill="#e1e1e1"
                        stroke="#e1e1e1"
                        strokeMiterlimit="10"
                        strokeWidth="0.75"
                    />
                    <path
                        id="Path_2"
                        data-name="Path 2"
                        d="M499.15,362.76h-7.24v-7.24a.9.9,0,0,0-.91-.9h0a.93.93,0,0,0-.92.9v7.24h-7.22a.92.92,0,0,0-.91.92.9.9,0,0,0,.91.9h7.22v7.24a.93.93,0,0,0,.92.9.9.9,0,0,0,.91-.9h0v-7.24h7.24a.91.91,0,0,0,0-1.82Z"
                        fill="#e1e1e1"
                        stroke="#e1e1e1"
                        strokeMiterlimit="10"
                        strokeWidth="0.75"
                    />
                    <ellipse
                        id="_Path_"
                        data-name="&lt;Path&gt;"
                        cx="234.29"
                        cy="35.31"
                        rx="234.29"
                        ry="35.31"
                        transform="translate(38.8 375.07)"
                        fill="#f3f4f5"
                    />
                    <path
                        id="Path_3"
                        data-name="Path 3"
                        d="M414.89,195.37a.49.49,0,0,1-.46-.45l-.17-2.43a.48.48,0,0,1,.45-.51.47.47,0,0,1,.51.45l.17,2.43a.48.48,0,0,1-.45.51Z"
                        fill="#717272"
                    />
                    <path
                        id="Path_4"
                        data-name="Path 4"
                        d="M425.8,305.81l-6.42.45-7.15-103.55,3.21-.23,3.21-.22Z"
                        fill="#e3e2e2"
                    />
                    <path
                        id="Path_5"
                        data-name="Path 5"
                        d="M415.49,193.88l-1.3.09-1.96,8.74,6.42-.45Z"
                        fill="#e3e2e2"
                    />
                    <path
                        id="Path_6"
                        data-name="Path 6"
                        d="M394.43,218.87l21.66,84.92-4.12,1.05-26.8-83.61"
                        fill="#e3e2e2"
                    />
                    <path
                        id="Path_7"
                        data-name="Path 7"
                        d="M374.39,220.8c-11.06,2.83-22.83-5.75-26.24-19.12S351,175.16,362,172.33s22.83,5.76,26.24,19.12S385.45,218,374.39,220.8Zm-10.47-41c-7,1.77-10.7,10.75-8.34,20s9.95,15.36,16.91,13.58,10.71-10.75,8.34-20-9.95-15.38-16.91-13.61Z"
                        fill="#717272"
                    />
                    <path
                        id="Path_8"
                        data-name="Path 8"
                        d="M385.78,197.2c.35,1.41,6.58,25.83,6.58,25.83a2.42,2.42,0,0,1-1.75,2.95h0a2.43,2.43,0,0,1-2.95-1.75l-.75-2.92s-3.45-7.38-13-4.34c0,0,11.4-1.42,11.79-1.39S385.42,195.79,385.78,197.2Z"
                        fill="#717272"
                    />
                    <path
                        id="Path_9"
                        data-name="Path 9"
                        d="M391.56,229.69a6.27,6.27,0,0,1-7.62-4.52,10,10,0,0,0-1.18-3.15c-1.24-1.76-3.77-2.42-8.39-1.24l-1.17-6.53c2.43-.78,4.18-1.95,6.13-1.81l2.65-.32c0-2-.05-4.64-.08-6.66s2.76-4.56,2.75-6.1c0-2.77-2.85-5.57.59-6l3.37-.45,7.47,29.2A6.27,6.27,0,0,1,391.56,229.69Z"
                        fill="#717272"
                    />
                    <path
                        id="Path_10"
                        data-name="Path 10"
                        d="M397.62,218.1l.06,87.64h4.25l5.24-87.65"
                        fill="#e3e2e2"
                    />
                    <path
                        id="Path_11"
                        data-name="Path 11"
                        d="M396.8,190c0-13.79,9.27-25,20.68-25s20.71,11.2,20.72,25-9.27,25-20.68,25S396.81,203.8,396.8,190Zm7.67,0c0,9.56,5.86,17.33,13,17.33s13-7.79,13-17.35-5.86-17.34-13-17.33S404.46,180.44,404.47,190Z"
                        fill="#717272"
                    />
                    <path
                        id="Path_12"
                        data-name="Path 12"
                        d="M400.64,195v26.65a2.44,2.44,0,0,0,2.43,2.43h0a2.43,2.43,0,0,0,2.43-2.43v-3s1.52-8,11.53-7.43c0,0-11.4,1.45-11.76,1.57S400.63,193.51,400.64,195Z"
                        fill="#717272"
                    />
                    <path
                        id="Path_13"
                        data-name="Path 13"
                        d="M403.09,227.87a6.25,6.25,0,0,0,6.25-6.26,10.05,10.05,0,0,1,.37-3.35c.76-2,3.05-3.28,7.82-3.28l-.49-6.62c-2.54-.15-4.53-.85-6.39-.23l-2.64.34c-.49-1.93-1.1-4.5-1.57-6.46s-3.8-3.74-4.18-5.24c-.68-2.68,1.38-6.1-2.06-5.69l-3.39.4v30.14a6.26,6.26,0,0,0,6.28,6.25Z"
                        fill="#717272"
                    />
                    <path
                        id="Path_14"
                        data-name="Path 14"
                        d="M433.26,192.25h8.86a1.17,1.17,0,0,1,1.17,1.17V290h-11.2V193.4A1.17,1.17,0,0,1,433.26,192.25Z"
                        transform="translate(49.82 -73.95) rotate(10.24)"
                        fill="#e3e2e2"
                    />
                    <path
                        id="Path_15"
                        data-name="Path 15"
                        d="M441,192.95h8.86a1.17,1.17,0,0,1,1.17,1.17v9.52h-11.2v-9.52a1.17,1.17,0,0,1,1.17-1.17Z"
                        transform="translate(42.33 -76.01) rotate(10.24)"
                        fill="#5b5b5b"
                    />
                    <rect
                        id="Rectangle_1"
                        data-name="Rectangle 1"
                        width="3.35"
                        height="11.2"
                        transform="translate(438.395 205.874) rotate(-79.76)"
                        fill="#717272"
                    />
                    <rect
                        id="Rectangle_2"
                        data-name="Rectangle 2"
                        width="19.34"
                        height="104.61"
                        transform="matrix(0.93, 0.367, -0.367, 0.93, 463.527, 207.556)"
                        fill="#e3e2e2"
                    />
                    <line
                        id="Line_1"
                        data-name="Line 1"
                        x2="5.93"
                        y2="2.34"
                        transform="translate(474.33 215.48)"
                        fill="none"
                        stroke="#717272"
                        strokeMiterlimit="10"
                        strokeWidth="1.39"
                    />
                    <line
                        id="Line_2"
                        data-name="Line 2"
                        x2="5.93"
                        y2="2.33"
                        transform="translate(472.34 220.54)"
                        fill="none"
                        stroke="#717272"
                        strokeMiterlimit="10"
                        strokeWidth="1.39"
                    />
                    <line
                        id="Line_3"
                        data-name="Line 3"
                        x2="5.93"
                        y2="2.34"
                        transform="translate(470.35 225.59)"
                        fill="none"
                        stroke="#717272"
                        strokeMiterlimit="10"
                        strokeWidth="1.39"
                    />
                    <line
                        id="Line_4"
                        data-name="Line 4"
                        x2="8.83"
                        y2="3.48"
                        transform="translate(465.45 229.5)"
                        fill="none"
                        stroke="#717272"
                        strokeMiterlimit="10"
                        strokeWidth="1.39"
                    />
                    <line
                        id="Line_5"
                        data-name="Line 5"
                        x2="5.93"
                        y2="2.34"
                        transform="translate(466.36 235.7)"
                        fill="none"
                        stroke="#717272"
                        strokeMiterlimit="10"
                        strokeWidth="1.39"
                    />
                    <line
                        id="Line_6"
                        data-name="Line 6"
                        x2="5.93"
                        y2="2.33"
                        transform="translate(464.37 240.76)"
                        fill="none"
                        stroke="#717272"
                        strokeMiterlimit="10"
                        strokeWidth="1.39"
                    />
                    <line
                        id="Line_7"
                        data-name="Line 7"
                        x2="5.93"
                        y2="2.34"
                        transform="translate(462.38 245.81)"
                        fill="none"
                        stroke="#717272"
                        strokeMiterlimit="10"
                        strokeWidth="1.39"
                    />
                    <line
                        id="Line_8"
                        data-name="Line 8"
                        x2="8.83"
                        y2="3.48"
                        transform="translate(457.48 249.72)"
                        fill="none"
                        stroke="#717272"
                        strokeMiterlimit="10"
                        strokeWidth="1.39"
                    />
                    <line
                        id="Line_9"
                        data-name="Line 9"
                        x2="5.93"
                        y2="2.34"
                        transform="translate(458.39 255.92)"
                        fill="none"
                        stroke="#717272"
                        strokeMiterlimit="10"
                        strokeWidth="1.39"
                    />
                    <line
                        id="Line_10"
                        data-name="Line 10"
                        x2="5.93"
                        y2="2.33"
                        transform="translate(456.4 260.98)"
                        fill="none"
                        stroke="#717272"
                        strokeMiterlimit="10"
                        strokeWidth="1.39"
                    />
                    <line
                        id="Line_11"
                        data-name="Line 11"
                        x2="5.93"
                        y2="2.34"
                        transform="translate(454.41 266.03)"
                        fill="none"
                        stroke="#717272"
                        strokeMiterlimit="10"
                        strokeWidth="1.39"
                    />
                    <line
                        id="Line_12"
                        data-name="Line 12"
                        x2="8.83"
                        y2="3.48"
                        transform="translate(449.51 269.94)"
                        fill="none"
                        stroke="#717272"
                        strokeMiterlimit="10"
                        strokeWidth="1.39"
                    />
                    <line
                        id="Line_13"
                        data-name="Line 13"
                        x2="5.93"
                        y2="2.34"
                        transform="translate(450.42 276.14)"
                        fill="none"
                        stroke="#717272"
                        strokeMiterlimit="10"
                        strokeWidth="1.39"
                    />
                    <line
                        id="Line_14"
                        data-name="Line 14"
                        x2="5.93"
                        y2="2.34"
                        transform="translate(448.43 281.2)"
                        fill="none"
                        stroke="#717272"
                        strokeMiterlimit="10"
                        strokeWidth="1.39"
                    />
                    <line
                        id="Line_15"
                        data-name="Line 15"
                        x2="5.93"
                        y2="2.34"
                        transform="translate(446.44 286.25)"
                        fill="none"
                        stroke="#717272"
                        strokeMiterlimit="10"
                        strokeWidth="1.39"
                    />
                    <line
                        id="Line_16"
                        data-name="Line 16"
                        x2="8.83"
                        y2="3.48"
                        transform="translate(441.54 290.17)"
                        fill="none"
                        stroke="#717272"
                        strokeMiterlimit="10"
                        strokeWidth="1.39"
                    />
                    <line
                        id="Line_17"
                        data-name="Line 17"
                        x2="5.93"
                        y2="2.34"
                        transform="translate(442.45 296.36)"
                        fill="none"
                        stroke="#717272"
                        strokeMiterlimit="10"
                        strokeWidth="1.39"
                    />
                    <line
                        id="Line_18"
                        data-name="Line 18"
                        x2="5.93"
                        y2="2.34"
                        transform="translate(440.46 301.42)"
                        fill="none"
                        stroke="#717272"
                        strokeMiterlimit="10"
                        strokeWidth="1.39"
                    />
                    <line
                        id="Line_19"
                        data-name="Line 19"
                        x2="5.93"
                        y2="2.34"
                        transform="translate(438.47 306.47)"
                        fill="none"
                        stroke="#717272"
                        strokeMiterlimit="10"
                        strokeWidth="1.39"
                    />
                    <path
                        id="Path_16"
                        data-name="Path 16"
                        d="M456.3,400.83H366.6l-15.07-135.7a2.91,2.91,0,0,1,2.9-3.23h114a2.91,2.91,0,0,1,2.89,3.23Z"
                        fill="#dbdbdb"
                    />
                    <path
                        id="Path_17"
                        data-name="Path 17"
                        d="M256.16,61.61A12.2,12.2,0,0,0,244,73.81V89.44h24.4V73.81a12.2,12.2,0,0,0-12.24-12.2Zm0,19.2A6.85,6.85,0,1,1,263,74a6.85,6.85,0,0,1-6.84,6.81Z"
                        fill="#717272"
                    />
                    <path
                        id="Path_18"
                        data-name="Path 18"
                        d="M263,74a6.84,6.84,0,0,0-6.84-6.84,7,7,0,0,0-1,.1,5.09,5.09,0,0,1,2.78-.66,6.84,6.84,0,0,1,6.84,6.84c0,3.43-1.75,5.29-4.39,6A7.44,7.44,0,0,0,263,74ZM247.69,87.88V72.25a11.88,11.88,0,0,1,5.13-10.16A12.19,12.19,0,0,0,244,73.81V89.44h24.4V87.88Z"
                        opacity="0.3"
                    />
                    <rect
                        id="Rectangle_3"
                        data-name="Rectangle 3"
                        width="229.9"
                        height="325.06"
                        rx="9.74"
                        transform="translate(138.89 90.26)"
                        fill="#717272"
                    />
                    <rect
                        id="Rectangle_4"
                        data-name="Rectangle 4"
                        width="203.25"
                        height="299.56"
                        transform="translate(152.22 103.01)"
                        fill="#e3e2e2"
                    />
                    <rect
                        id="Rectangle_5"
                        data-name="Rectangle 5"
                        width="187.62"
                        height="281.26"
                        transform="translate(160.04 112.16)"
                        fill="#fff"
                    />
                    <path
                        id="Path_19"
                        data-name="Path 19"
                        d="M327.79,95.64v12.22H179.9V95.64a8.58,8.58,0,0,1,8.57-8.58H319.21a8.58,8.58,0,0,1,8.58,8.58Z"
                        fill="#5b5b5b"
                    />
                    <path
                        id="Path_20"
                        data-name="Path 20"
                        d="M327.79,104.8v3.06H179.9V95.64a8.58,8.58,0,0,1,8.57-8.58h1.19a12.86,12.86,0,0,0-4,8.9v8.84Z"
                        opacity="0.3"
                    />
                    <g id="Group_1" data-name="Group 1" opacity="0.15">
                        <line
                            id="Line_20"
                            data-name="Line 20"
                            x2="30.47"
                            transform="translate(212.07 142.06)"
                            fill="none"
                            stroke="#717272"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="3.12"
                        />
                        <line
                            id="Line_21"
                            data-name="Line 21"
                            x2="23.17"
                            transform="translate(310.94 151.78)"
                            fill="none"
                            stroke="#717272"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="3.12"
                        />
                        <line
                            id="Line_22"
                            data-name="Line 22"
                            x2="88.82"
                            transform="translate(212.07 151.78)"
                            fill="none"
                            stroke="#717272"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="3.12"
                        />
                        <line
                            id="Line_23"
                            data-name="Line 23"
                            x2="122.04"
                            transform="translate(212.07 161.49)"
                            fill="none"
                            stroke="#717272"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="3.12"
                        />
                        <line
                            id="Line_24"
                            data-name="Line 24"
                            x2="38.79"
                            transform="translate(295.32 171.21)"
                            fill="none"
                            stroke="#717272"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="3.12"
                        />
                        <line
                            id="Line_25"
                            data-name="Line 25"
                            x2="73.59"
                            transform="translate(212.07 171.21)"
                            fill="none"
                            stroke="#717272"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="3.12"
                        />
                        <line
                            id="Line_26"
                            data-name="Line 26"
                            x2="81.57"
                            transform="translate(252.54 142.06)"
                            fill="none"
                            stroke="#717272"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="3.12"
                        />
                    </g>
                    <g id="Group_2" data-name="Group 2" opacity="0.15">
                        <line
                            id="Line_27"
                            data-name="Line 27"
                            x2="30.47"
                            transform="translate(212.07 211.91)"
                            fill="none"
                            stroke="#717272"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="3.12"
                        />
                        <line
                            id="Line_28"
                            data-name="Line 28"
                            x2="23.17"
                            transform="translate(310.94 221.62)"
                            fill="none"
                            stroke="#717272"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="3.12"
                        />
                        <line
                            id="Line_29"
                            data-name="Line 29"
                            x2="88.82"
                            transform="translate(212.07 221.62)"
                            fill="none"
                            stroke="#717272"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="3.12"
                        />
                        <line
                            id="Line_30"
                            data-name="Line 30"
                            x2="122.04"
                            transform="translate(212.07 231.34)"
                            fill="none"
                            stroke="#717272"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="3.12"
                        />
                        <line
                            id="Line_31"
                            data-name="Line 31"
                            x2="38.79"
                            transform="translate(295.32 241.06)"
                            fill="none"
                            stroke="#717272"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="3.12"
                        />
                        <line
                            id="Line_32"
                            data-name="Line 32"
                            x2="73.59"
                            transform="translate(212.07 241.06)"
                            fill="none"
                            stroke="#717272"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="3.12"
                        />
                        <line
                            id="Line_33"
                            data-name="Line 33"
                            x2="81.57"
                            transform="translate(252.54 211.91)"
                            fill="none"
                            stroke="#717272"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="3.12"
                        />
                    </g>
                    <g id="Group_3" data-name="Group 3" opacity="0.15">
                        <line
                            id="Line_34"
                            data-name="Line 34"
                            x2="30.47"
                            transform="translate(212.07 279.31)"
                            fill="none"
                            stroke="#717272"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="3.12"
                        />
                        <line
                            id="Line_35"
                            data-name="Line 35"
                            x2="23.17"
                            transform="translate(310.94 289.03)"
                            fill="none"
                            stroke="#717272"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="3.12"
                        />
                        <line
                            id="Line_36"
                            data-name="Line 36"
                            x2="88.82"
                            transform="translate(212.07 289.03)"
                            fill="none"
                            stroke="#717272"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="3.12"
                        />
                        <line
                            id="Line_37"
                            data-name="Line 37"
                            x2="122.04"
                            transform="translate(212.07 298.74)"
                            fill="none"
                            stroke="#717272"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="3.12"
                        />
                        <line
                            id="Line_38"
                            data-name="Line 38"
                            x2="38.79"
                            transform="translate(295.32 308.46)"
                            fill="none"
                            stroke="#717272"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="3.12"
                        />
                        <line
                            id="Line_39"
                            data-name="Line 39"
                            x2="73.59"
                            transform="translate(212.07 308.46)"
                            fill="none"
                            stroke="#717272"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="3.12"
                        />
                        <line
                            id="Line_40"
                            data-name="Line 40"
                            x2="81.57"
                            transform="translate(252.54 279.31)"
                            fill="none"
                            stroke="#717272"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="3.12"
                        />
                    </g>
                    <g id="Group_4" data-name="Group 4" opacity="0.15">
                        <line
                            id="Line_41"
                            data-name="Line 41"
                            x2="30.47"
                            transform="translate(212.07 347.94)"
                            fill="none"
                            stroke="#717272"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="3.12"
                        />
                        <line
                            id="Line_42"
                            data-name="Line 42"
                            x2="23.17"
                            transform="translate(310.94 357.65)"
                            fill="none"
                            stroke="#717272"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="3.12"
                        />
                        <line
                            id="Line_43"
                            data-name="Line 43"
                            x2="88.82"
                            transform="translate(212.07 357.65)"
                            fill="none"
                            stroke="#717272"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="3.12"
                        />
                        <line
                            id="Line_44"
                            data-name="Line 44"
                            x2="122.04"
                            transform="translate(212.07 367.37)"
                            fill="none"
                            stroke="#717272"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="3.12"
                        />
                        <line
                            id="Line_45"
                            data-name="Line 45"
                            x2="38.79"
                            transform="translate(295.32 377.09)"
                            fill="none"
                            stroke="#717272"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="3.12"
                        />
                        <line
                            id="Line_46"
                            data-name="Line 46"
                            x2="73.59"
                            transform="translate(212.07 377.09)"
                            fill="none"
                            stroke="#717272"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="3.12"
                        />
                        <line
                            id="Line_47"
                            data-name="Line 47"
                            x2="81.57"
                            transform="translate(252.54 347.94)"
                            fill="none"
                            stroke="#717272"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="3.12"
                        />
                    </g>
                    <rect
                        id="Rectangle_6"
                        data-name="Rectangle 6"
                        width="27.94"
                        height="27.94"
                        rx="2.1"
                        transform="translate(173.84 139.13)"
                        fill="none"
                        stroke="#e3e2e2"
                        strokeMiterlimit="10"
                        strokeWidth="1.16"
                    />
                    <rect
                        id="Rectangle_7"
                        data-name="Rectangle 7"
                        width="27.94"
                        height="27.94"
                        rx="2.1"
                        transform="translate(173.84 208.75)"
                        fill="none"
                        stroke="#e3e2e2"
                        strokeMiterlimit="10"
                        strokeWidth="1.16"
                    />
                    <rect
                        id="Rectangle_8"
                        data-name="Rectangle 8"
                        width="27.94"
                        height="27.94"
                        rx="2.1"
                        transform="translate(173.84 278.37)"
                        fill="none"
                        stroke="#e3e2e2"
                        strokeMiterlimit="10"
                        strokeWidth="1.16"
                    />
                    <rect
                        id="Rectangle_9"
                        data-name="Rectangle 9"
                        width="27.94"
                        height="27.94"
                        rx="2.1"
                        transform="translate(173.84 347.99)"
                        fill="none"
                        stroke="#e3e2e2"
                        strokeMiterlimit="10"
                        strokeWidth="1.16"
                    />
                    <path
                        id="Path_21"
                        data-name="Path 21"
                        d="M177.28,148.18l10.53,12.72,20.26-31.97"
                        fill="none"
                        stroke="#717272"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="3.47"
                    />
                    <path
                        id="Path_22"
                        data-name="Path 22"
                        d="M177.28,217.22l10.53,12.72,20.26-31.97"
                        fill="none"
                        stroke="#717272"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="3.47"
                    />
                    <path
                        id="Path_23"
                        data-name="Path 23"
                        d="M101.63,273.88a3.14,3.14,0,0,0,1,1.74,3.6,3.6,0,0,0,2,.93c2,.21,5.15.64,8.46,1.08,5.3.71,11.08,1.44,13.51,1.3a12.63,12.63,0,0,0,4.3-.59.391.391,0,0,0,.14,0h0c.36-.32.37-1.17.26-2s-.34-1.87-.36-2.11a4.15,4.15,0,0,0-3.35-3.62,3.534,3.534,0,0,0-.55-.11l-.38-.12c-5-1.58-10.6-2-14.22-2.57a12,12,0,0,0-5.24.26,10.67,10.67,0,0,0-3.2,1.43C102.47,270.58,101.24,272.05,101.63,273.88Z"
                        fill="#fff"
                    />
                    <path
                        id="Path_24"
                        data-name="Path 24"
                        d="M126,276.26a3.67,3.67,0,0,0,.51.94q.15.18.3.33a4.6,4.6,0,0,0,3.68,1h0l.45-.15.16-.07c3.18-1.35,9.92-6.29,14.53-9.81a.07.07,0,0,0,.05,0c2.75-2.12,4.74-3.71,4.74-3.71l-.41-.63-1.64-2.4s-10.24,4.84-15.08,6.53a31.6,31.6,0,0,0-5.64,2.3,4.725,4.725,0,0,0-.52.35,5.83,5.83,0,0,0-1.13,5.32Z"
                        fill="#35aad3"
                    />
                    <path
                        id="Path_25"
                        data-name="Path 25"
                        d="M113.14,277.63c5.3.71,11.08,1.44,13.51,1.3a12.631,12.631,0,0,0,4.3-.59.39.39,0,0,0,.14,0h0c3.18-1.35,9.92-6.29,14.53-9.81-.66.39-9.39,5.47-14.27,7.79a17.619,17.619,0,0,1-2.75,1.12,10.733,10.733,0,0,1-1.81.16h-.28a13.13,13.13,0,0,1-11-6.26,23.37,23.37,0,0,0-2.15-3S113.24,273.76,113.14,277.63Z"
                        fill="#2496b5"
                    />
                    <path
                        id="Path_26"
                        data-name="Path 26"
                        d="M128.72,270s-.79.58-3.12,0,.12,2.28.12,2.28l3.67-.23.31-.94Z"
                        fill="#fff"
                    />
                    <path
                        id="Path_27"
                        data-name="Path 27"
                        d="M91.62,296.67a13.91,13.91,0,0,1,.63,8c-1.18,5.31.16,5.66.16,5.66s7.2,4.57,13.18,6.44c4.29,1.33,8,1.11,7.85-3.63v-.9c-.2-10.64,2-11.18,2.57-23.1.56-12.23.44-19.48-3.48-21.45-4.26-2.15-10.13-3.62-18.46-1.66-6.25,1.48-8.31,3.14-8.26,7.76a35.132,35.132,0,0,0,.67,5.7,102.612,102.612,0,0,0,5.14,17.18Z"
                        fill="#5b5b5b"
                    />
                    <path
                        id="Path_28"
                        data-name="Path 28"
                        d="M91.62,296.67a13.91,13.91,0,0,1,.63,8c-1.18,5.31.16,5.66.16,5.66s7.2,4.57,13.18,6.44l5.6-.53,2.23-1.76v-2.24A40.11,40.11,0,0,1,105,312a34.869,34.869,0,0,1-8.32-2,3.24,3.24,0,0,1-2-3.59,46.12,46.12,0,0,0,.77-7.42c0-2.35-1.1-6.31-2-9.17a18.25,18.25,0,0,1-.74-6.77,61.588,61.588,0,0,0,0-7.72l-4.43-2.41-2.47.92a35.132,35.132,0,0,0,.67,5.7,102.609,102.609,0,0,0,5.14,17.13Z"
                        opacity="0.3"
                    />
                    <path
                        id="Path_29"
                        data-name="Path 29"
                        d="M111.65,253.1c-.82.33-.48,3.11-.63,4s-.8,4.37-2.63,4.9-3,.67-3,1a10.668,10.668,0,0,0,.09,1.41,3.045,3.045,0,0,0,.06.76v.17a.66.66,0,0,1,0,.14,3.3,3.3,0,0,1-.16,1.3l-1.19.79a.86.86,0,0,1-.63.32l-1.25.1a.769.769,0,0,1-.27,0l-.71-.2a7.649,7.649,0,0,0-.19-.77s-2.23-1.48-3.51-2.32c-.08-1.32-.19-2.85-.28-3.24s-1-2.15-2-4.32c-.08-.18-.17-.36-.24-.54-.22-.49-.43-1-.64-1.5a1.542,1.542,0,0,1-.08-.19,15.28,15.28,0,0,1-1.26-4.69c0-3.2.11-6.55,2.09-8.2s14.38-7.53,14.79,2.13a1,1,0,0,0,0,.17c.18.66,1.52,5.58,2.15,6.48a1.53,1.53,0,0,1-.51,2.3Z"
                        fill="#e3e2e2"
                    />
                    <path
                        id="Path_30"
                        data-name="Path 30"
                        d="M105.44,266.75l-.12.6a.87.87,0,0,1-.89.71L102.3,268a.51.51,0,0,1-.2,0l-.71-.2a7.651,7.651,0,0,0-.19-.77s-2.23-1.48-3.51-2.32c-.08-1.32-.19-2.85-.28-3.24s-1-2.15-2-4.32c-.08-.18-.17-.36-.24-.54-.22-.49-.43-1-.64-1.5a.93.93,0,0,1-.06-.41l.09-.95a.86.86,0,0,1,.77-.8c1.05-.12,2.93-.33,3-.32a14.659,14.659,0,0,1,1.28,2.34c0,.1-.53,1.71-.67,2.15a.71.71,0,0,0,0,.22c0,.49-.09,2.49,0,4.34a3.65,3.65,0,0,0,1.9,3.12l.16.09a31.731,31.731,0,0,0,4.44,1.86Z"
                        fill="#e3e2e2"
                    />
                    <path
                        id="Path_31"
                        data-name="Path 31"
                        d="M112.18,254c2.66,2.42-.22,7.66-1.08,8.41s-7,2.6-10.47-.77a6.71,6.71,0,0,1-1.5-2.14h0a12.1,12.1,0,0,1-.82-4.81l-1,.25a26.43,26.43,0,0,0,.17,5,.88.88,0,0,1-1.47.63c-1.59-1.55-4-5-4.81-11.81-1.26-10.51,9.28-11.24,11.65-11,1.7.18,4.63.08,5.41-.37,5.93-3.42,5.38,2.68,4.54,5-.28.78-.92,1.77-1.05,2.12a18.9,18.9,0,0,0,.14,2.63,1.37,1.37,0,0,1-.63,1.08s-.6-2-.67-2.24-.31-1.09-.42-1.48a.46.46,0,0,0-.24-.27,7,7,0,0,0-.74-.21,27.469,27.469,0,0,0-3.11-.25,1.43,1.43,0,0,0-1.51,1.35c-.14,3.29-1.83,3.89-2.6,4a.47.47,0,0,0-.41.42c-.52,4,1,6.26,1.68,6.37S106,254.2,106,254.2,109.52,251.56,112.18,254Z"
                        fill="#5b5b5b"
                    />
                    <path
                        id="Path_32"
                        data-name="Path 32"
                        d="M98.62,252s-.17-1.79-1.55-1.54c-1.63.29-2.44,4.11.08,5a.87.87,0,0,0,1.15-.81A11.69,11.69,0,0,1,98.62,252Z"
                        fill="#e3e2e2"
                    />
                    <path
                        id="Path_33"
                        data-name="Path 33"
                        d="M106.73,270.48s6.48,21.27,5.19,31.46l-2.39,4.34-2.62-4.74s2.13-20.37-1.94-30.7Z"
                        fill="#e3e2e2"
                    />
                    <path
                        id="Path_34"
                        data-name="Path 34"
                        d="M107.18,268.67v.28a5.421,5.421,0,0,1-.41,1.53h0l-1.76.36-1.53-1.14,1.76-2,.27.07a3.85,3.85,0,0,1,1.68.92Z"
                        fill="#e3e2e2"
                    />
                    <path
                        id="Path_35"
                        data-name="Path 35"
                        d="M97.64,263.8a21.882,21.882,0,0,0-1.28,1.78,15.368,15.368,0,0,1,2.22,1.07c.92.56,5.32,3.67,5.32,3.67l1.3-2.66S100.12,264.49,97.64,263.8Z"
                        fill="#717272"
                    />
                    <path
                        id="Path_36"
                        data-name="Path 36"
                        d="M105.2,267.66a3.68,3.68,0,0,1,2,1.68s.74-2.28.12-3.54a3.38,3.38,0,0,0-1.83-1.74Z"
                        fill="#717272"
                    />
                    <path
                        id="Path_37"
                        data-name="Path 37"
                        d="M112.05,408.73a7.589,7.589,0,0,1,.53,2.59c-.07,1-3.35,1.1-3.73.57s-.57-5.26-.57-5.26"
                        fill="#e3e2e2"
                    />
                    <path
                        id="Path_38"
                        data-name="Path 38"
                        d="M108,412.8a22.312,22.312,0,0,0,.29,2.81h3.78l.18-.63a6,6,0,0,0,2.19.63h3.18c2.34,0,3.66-.29,3.72-.7.25-1.72-2.54-1.64-3.26-1.64-2.09,0-4.34-1-4.83-2-.36-.77-.2-1-.63-.82a1.07,1.07,0,0,0-.65.56,1.9,1.9,0,0,1-2,1.07,2.29,2.29,0,0,1-1.28-.61,1.37,1.37,0,0,0-.69,1.33Z"
                        fill="#5b5b5b"
                    />
                    <path
                        id="Path_39"
                        data-name="Path 39"
                        d="M75.33,405.17a31.321,31.321,0,0,1-1.15,3.69c-.45.93-3.52-.22-3.68-.85s1.42-5.1,1.42-5.1"
                        fill="#e3e2e2"
                    />
                    <path
                        id="Path_40"
                        data-name="Path 40"
                        d="M69.36,408.53c-.4,1-1,2.92-1,2.92l2.86,1.71.57-.42a5.62,5.62,0,0,0,1.5,1.57,12.25,12.25,0,0,0,2.83,1.17c2.62.6,4.64.31,4.84-.05.87-1.5-2.6-2.33-3.27-2.59A4.69,4.69,0,0,1,74.81,409c0-.85.17-1-.28-1a1.12,1.12,0,0,0-.81.28,1.9,1.9,0,0,1-2.22.27,2.25,2.25,0,0,1-1-1A1.36,1.36,0,0,0,69.36,408.53Z"
                        fill="#5b5b5b"
                    />
                    <path
                        id="Path_41"
                        data-name="Path 41"
                        d="M88.59,331.55s-5.13-12.28,3.82-21.2c0,0,7.63,3.42,13.74,3.83a30,30,0,0,0,7.26-.17,111.931,111.931,0,0,1,1.27,23.66Z"
                        fill="#717272"
                    />
                    <path
                        id="Path_42"
                        data-name="Path 42"
                        d="M107.83,409.91h.45a25.339,25.339,0,0,0,5-.34v-.92c-.18-14.06,1.52-37,1.86-44.3a7.751,7.751,0,0,0,0-1.39c-.55-5.32-6-4.62-8.35.27a18.59,18.59,0,0,0-1.31,3.75,34.89,34.89,0,0,0-.85,5.31C103.51,384.44,106.89,400.85,107.83,409.91Z"
                        fill="#717272"
                    />
                    <path
                        id="Path_43"
                        data-name="Path 43"
                        d="M98.3,337.22a2.561,2.561,0,0,0,.09.45c1.22,6.4,5.16,23.7,7,29.31h0a3.38,3.38,0,0,0,2.42,1.77c2.77.75,6.89-.27,7.24-5.79a12.121,12.121,0,0,0,0-2c-.23-2.87-.44-15.15-.39-23.31a33.461,33.461,0,0,0-1.16-8.29c-.19-2.39-.62-5.87-1.88-6.31C105.35,320.89,96.66,328.26,98.3,337.22Z"
                        fill="#717272"
                    />
                    <path
                        id="Path_44"
                        data-name="Path 44"
                        d="M104.58,372.3c-1.07,12.14,2.31,28.55,3.25,37.61h.45s-.78-14.35-2.06-24.3a39.44,39.44,0,0,1,1.63-16.86c.26-.77.43-1.19.43-1.19l-1.54-4.33C105,357.6,105,344.45,105,344.45c4.2-9.27,4.78-20.93,4.78-20.93l-3.59.5-6.43,7.71-1.34,5.94c1.22,6.4,5.16,23.7,7,29.31h0a34.892,34.892,0,0,0-.84,5.32Z"
                        opacity="0.3"
                    />
                    <path
                        id="Path_45"
                        data-name="Path 45"
                        d="M104.17,362.68a30.88,30.88,0,0,1,.41,9.62c-.67,4.75,2.28-4.18,2.28-4.18l-1.57-3.23Z"
                        fill="#5b5b5b"
                    />
                    <path
                        id="Path_46"
                        data-name="Path 46"
                        d="M85.77,362.88c4.26-4,10.18-2.11,7.42,4.1-3,6.65-12.11,25.1-17.07,38.25l-.34.86a26.621,26.621,0,0,1-5.19-1.68c2.36-8.54,4.08-23.25,9-33.51a27.88,27.88,0,0,1,6.18-8.02Z"
                        fill="#717272"
                    />
                    <path
                        id="Path_47"
                        data-name="Path 47"
                        d="M109.76,323.53c.83-9.89-11.5-3.33-17.41-.06-2.49,1.37-3.4,4.12-3.73,6.71s-3.53,29.24-4.17,34.1c-1.45,11,8.12,5,9,1.93C95.76,358.54,109.31,328.91,109.76,323.53Z"
                        fill="#717272"
                    />
                    <path
                        id="Path_48"
                        data-name="Path 48"
                        d="M85.3,357.48s-.4,5.25-4.8,11.71c0,0,7.21-3.32,7.56-3.81a27.06,27.06,0,0,0,1.47-6.11C89.39,358.73,85.3,357.48,85.3,357.48Z"
                        fill="#717272"
                    />
                    <path
                        id="Path_49"
                        data-name="Path 49"
                        d="M182.6,210.27l-8.39,29.44L69.82,352.16l-.96,1.04L58.83,363.74,39.58,345.86l10.68-11.23,2.08-2.25L154.97,221.84Z"
                        fill="#717272"
                    />
                    <rect
                        id="Rectangle_10"
                        data-name="Rectangle 10"
                        width="8.06"
                        height="26.26"
                        transform="matrix(0.68, -0.733, 0.733, 0.68, 39.567, 345.882)"
                        fill="#717272"
                    />
                    <rect
                        id="Rectangle_11"
                        data-name="Rectangle 11"
                        width="8.06"
                        height="26.26"
                        transform="matrix(0.681, -0.732, 0.732, 0.681, 45.064, 339.942)"
                        fill="#5b5b5b"
                    />
                    <path
                        id="Path_50"
                        data-name="Path 50"
                        d="M154.97,221.84l19.24,17.87,8.39-29.44Z"
                        fill="#e3e2e2"
                    />
                    <path
                        id="Path_51"
                        data-name="Path 51"
                        d="M172.71,214.41l6.99,6.04,2.9-10.18Z"
                        fill="#717272"
                    />
                    <path
                        id="Path_52"
                        data-name="Path 52"
                        d="M182.6,210.27l-12.34,25.77L53.49,358.78l5.33,4.96L174.21,239.71Z"
                        opacity="0.3"
                    />
                    <path
                        id="Path_53"
                        data-name="Path 53"
                        d="M100.22,315.77l-.07.69-1,1a3,3,0,0,1-1.49-.93,9.149,9.149,0,0,1-1-1.56l-.44.51S85.39,307,80.69,298.82l2-2.14,1.8.71s5.25,5.29,5.59,5.78,4.06,6.11,4.06,6.11Z"
                        fill="#bec4cc"
                        opacity="0.67"
                    />
                    <path
                        id="Path_54"
                        data-name="Path 54"
                        d="M95.27,313.55l3.18-2.78a2.71,2.71,0,0,0,1.83,1.9,10.323,10.323,0,0,0,1.09-.18,6.07,6.07,0,0,1,2.78.86c.81.37,1.87.71,1.92,1s-.52.84-1.09.79a1.281,1.281,0,0,1-.28-.05,1,1,0,0,1-.06.34c-.24.72-3.61,5.43-7,.31a9.44,9.44,0,0,0-2.37-2.19Z"
                        fill="#e3e2e2"
                    />
                    <path
                        id="Path_55"
                        data-name="Path 55"
                        d="M93.53,280c-1.13,3.49-2.34,9-4.63,13.7l-.14.29v.06a4.049,4.049,0,0,1-.17.51,4.17,4.17,0,0,1-4.08,2.8H81.94a2.025,2.025,0,0,1-1.54-.54h0c0-.08-.06-.2-.09-.36a1.079,1.079,0,0,1,0-.19.71.71,0,0,1,0-.21v-.59a2.708,2.708,0,0,1,0-.29,22,22,0,0,1,.23-2.86c.37-3.93,4.25-16.25,5.57-21.38a3.57,3.57,0,0,1,1.21-1.89,3.17,3.17,0,0,1,1.85-.74h.32c1.72.05,2.9,1.41,3.7,3a10.63,10.63,0,0,1,1,3.41A12.07,12.07,0,0,1,93.53,280Z"
                        fill="#5b5b5b"
                    />
                    <path
                        id="Path_56"
                        data-name="Path 56"
                        d="M99.45,312.31l-2.15,1.94h0l-.56.49s-4.36-3.79-7.76-7c-3.14-3-6.34-6.3-8.33-10.28-.1-.19-.19-.39-.28-.58h0c0-.08-.06-.2-.09-.36a1.079,1.079,0,0,1,0-.19.71.71,0,0,1,0-.21v-.59a2.708,2.708,0,0,1,0-.29,3.63,3.63,0,0,1,.79-2.3l.16-.18a3.8,3.8,0,0,1,.86-.62,3.73,3.73,0,0,1,.63-.27,6.07,6.07,0,0,1,4.87.66,5.892,5.892,0,0,1,.41.44c.29.32.6.67.91,1a17.6,17.6,0,0,1,2.34,3.92C93.18,302.74,99.45,312.31,99.45,312.31Z"
                        fill="#5b5b5b"
                    />
                    <path
                        id="Path_57"
                        data-name="Path 57"
                        d="M150.64,265.1s5.24-3.65,5.48-6.44c.26-3.07-1.91-4.55-3.33-2.85-2.24,2.67-1.64,7.49-1.64,7.49l-.51,1.8Z"
                        fill="#e3e2e2"
                    />
                    <path
                        id="Path_58"
                        data-name="Path 58"
                        d="M97.3,314.25h0l-.56.49s-4.36-3.79-7.76-7c-3.14-3-6.34-6.3-8.33-10.28-.1-.19-.19-.39-.28-.58h0c0-.08-.06-.2-.09-.36a1.079,1.079,0,0,1,0-.19.71.71,0,0,1,0-.21v-.59a2.708,2.708,0,0,1,0-.29,22,22,0,0,1,.23-2.86c.37-3.93,4.25-16.25,5.57-21.38a3.57,3.57,0,0,1,1.21-1.89,3.17,3.17,0,0,1,1.85-.74h.32c-.82,1.32-2,4-3.29,9.32-1.74,7.13-3,11.43-3.45,14.19a10,10,0,0,0-.22,3,9.369,9.369,0,0,0,1.25,2.59C86.59,302.39,94.19,312.13,97.3,314.25Z"
                        opacity="0.3"
                    />
                </g>
            </g>
        </SVGIcon>
    );
}
