import React from 'react';

import { SVGIcon } from '../../UIKit/index';

export default function DCOwner(props) {
    return (
        <SVGIcon {...props} viewBox="0 0 504.91 504.91">
            <g transform="translate(-3.57 -3.79)">
                <path
                    d="M508.48,256.25c0,73.9-31.88,140.18-82.37,186.44a252.13,252.13,0,0,1-340.17,0C35.44,396.43,3.57,330.15,3.57,256.25,3.57,116.9,116.68,3.79,256,3.79S508.48,116.9,508.48,256.25Z"
                    fill="#d9eaff"
                />
                <path
                    d="M508.48,256.25c0,73.9-31.88,140.18-82.37,186.44A251.85,251.85,0,0,1,256,508.7V3.79C395.37,3.79,508.48,116.9,508.48,256.25Z"
                    fill="#005eff"
                    opacity="0.3"
                />
                <path
                    d="M426.11,441.57v1.12a252.13,252.13,0,0,1-340.17,0v-1.12A170.11,170.11,0,0,1,314.7,281.91C379.85,306.17,426.11,368.51,426.11,441.57Z"
                    fill="#3781ff"
                />
                <path
                    d="M426.11,441.57v1.12A251.85,251.85,0,0,1,256,508.7V271.76a171.8,171.8,0,0,1,59.031,10.451C379.85,306.17,426.11,368.51,426.11,441.57Z"
                    fill="#005eff"
                />
                <path
                    d="M360.39,196.16A104.37,104.37,0,1,1,256.017,91.8,104.372,104.372,0,0,1,360.39,196.16Z"
                    fill="#6da4ff"
                />
                <path
                    d="M360.39,196.16A104.49,104.49,0,0,1,256,300.53V91.8A104.49,104.49,0,0,1,360.39,196.16Z"
                    fill="#3781ff"
                />
            </g>
        </SVGIcon>
    );
}
