import React, { useState, useEffect, Fragment, useCallback } from 'react';
import { Div, Dialog, TopBar, H1, IconButton, Span, Button, ToolTip } from 'UIKit/index';
import DCCrossClose from 'Assets/icons/DCCrossClose';
import DCFillCheck from 'Assets/icons/DCFillCheck';
import DCProject from 'Assets/icons/DCProject';
import DCInbound from 'Assets/icons/DCInbound';
import DCContacts from 'Assets/icons/DCContacts';
import DCAgency from 'Assets/icons/DCAgency';
import DCAnalytics from 'Assets/icons/DCAnalytics';
import DCDashboardMenu from 'Assets/icons/DCDashboardMenu';
import DCDealMenu from 'Assets/icons/DCDealMenu';
import DCForms from 'Assets/icons/DCForms';
import DCInstareports from 'Assets/icons/DCInstareports';
import DCInstasites from 'Assets/icons/DCInstasites';
import DCTemplates from 'Assets/icons/DCTemplates';
import DCSettingMenu from 'Assets/icons/DCSettingMenu';
import DCConversations from 'Assets/icons/DCConversations';
import DCLeads from 'Assets/icons/DCLeads';
import DCReputation from 'Assets/icons/DCReputation';
import { RetractCancellationApi, singleItemCheckout } from 'apis/store/index.api';
import PropTypes from 'prop-types';
import './style.scss';
import { formatNumberString } from 'Utils';
import { getAccountDetails, getSessionId } from 'Utils/localStorageUtils';
import useToast from 'Modules/Toasts/useToast';
import usePubSub from 'Hooks/PubSub';
import Checkout from 'Pages/Store/Checkout/checkout';
import CancelSubscription from 'Components/CancelSubscription/cancelSubscription';
import { useHistory } from 'react-router-dom';
import Confirmation from 'Components/Confirmation';
import { formateDate } from 'Utils/dateUtils';
import dayjs from 'dayjs';

const AppComparePlans = ({ planTime, handlePlanTime, data, open, onClose, hidePurchaseBtn }) => {
    const { showToast } = useToast();
    const { publish } = usePubSub();
    const [rawData, setRawData] = useState(data);
    const [formattedData, setFormattedData] = useState([]);
    const history = useHistory();
    const [downgradeData, setDowngradeData] = useState(getAccountDetails()?.downgrade);
    const [currentTier, setCurrentTier] = useState(getAccountDetails()?.plan?.tier || 'tier0');
    const [newcurrentTier, setNewCurrentTier] = useState(null);
    const currentInterval = getAccountDetails().plan.recurring?.interval || 'month';
    const [openConfirmation, setOpenConfirmation] = useState(false);
    const [loadingRetract, setLoadingRetract] = useState(false);
    const [isDownGrade, setIsDownGrade] = useState(false);
    useEffect(() => {
        if (isDownGrade && newcurrentTier) {
            setDowngradeData({
                plan: 'tier0',
            });
        }
    }, [isDownGrade, newcurrentTier]);

    useEffect(() => {
        const arr = data;
        if (arr && arr.length) {
            setRawData(arr);
            const sections = arr[0]?.details?.map(obj => {
                return obj.label;
            });

            const output = [];
            sections?.forEach(section => {
                let tempFeatures = [];

                arr.forEach(obj => {
                    const sec = obj.details.findIndex(c => c.label === section);
                    tempFeatures = obj.details[sec]?.data;
                });
                const labels = tempFeatures?.map(obj => {
                    return { label: obj.label, tooltip: obj.tooltip };
                });
                const tempArr = [];
                labels?.forEach(objL => {
                    let label = objL.label;
                    const newRes = [];
                    arr.forEach(objM => {
                        objM.details.forEach(obj => {
                            if (obj.label === section) {
                                const ind = obj.data.findIndex(f => f.label === label);
                                if (ind >= 0) newRes.push(obj.data[ind].value);
                            }
                        });
                    });

                    tempArr.push({ label: label, values: newRes, tooltip: objL.tooltip });
                });
                output.push({
                    section: section,
                    features: tempArr,
                });
            });
            setFormattedData(output);
        }
    }, [data]);

    const getIcon = app => {
        switch (app) {
            case 'Projects':
                return <DCProject />;
            case 'Inbound':
                return <DCInbound />;
            case 'Contacts':
                return <DCContacts />;
            case 'Templates':
                return <DCTemplates />;
            case 'InstaSites':
                return <DCInstasites />;
            case 'InstaReports':
                return <DCInstareports />;
            case 'Forms':
                return <DCForms />;
            case 'Sites':
                return <DCAgency />;
            case 'Deals':
                return <DCDealMenu />;
            case 'Analytics':
                return <DCAnalytics />;
            case 'Dashboard':
                return <DCDashboardMenu />;
            case 'Leads':
                return <DCLeads />;
            case 'Conversations':
                return <DCConversations />;
            case 'Reputation':
                return <DCReputation />;
            default:
                return <DCSettingMenu />;
        }
    };

    const [openCheckout, setOpenCheckout] = useState(false);
    const [checkoutData, setCheckoutData] = useState(null);
    const [loadingUpgrade, setLoadingUpgrade] = useState(null);

    const [cancellationStepperOpen, setCancellationStepperOpen] = useState(false);
    const [planData, setPlanData] = useState({});

    const [singleItemBody, setSingleItemBody] = useState(null);

    const upgradePlan = useCallback(
        async (priceId, tier) => {
            try {
                setLoadingUpgrade(tier);
                const body = {
                    business: getAccountDetails().business_id,
                    price: priceId,
                    subscription: getAccountDetails()?.plan?.id,
                };
                const res = await singleItemCheckout(body, { type: 'preview' });
                if (res.success) {
                    setSingleItemBody(body);
                    setCheckoutData(res.data);
                    setOpenCheckout(true);
                }
            } catch (err) {
                showToast({
                    type: 'error',
                    message:
                        err.message || (typeof err === 'string' ? err : 'Something went wrong'),
                });
            } finally {
                setLoadingUpgrade(null);
            }
        },
        [showToast],
    );

    const getButtonLabel = tier => {
        const tempTier = parseInt(tier[tier.length - 1]);
        const tempCurrentTier = parseInt(currentTier[currentTier.length - 1]);
        if (planTime === currentInterval || tempCurrentTier === 0) {
            if (tempTier < tempCurrentTier) {
                return 'Downgrade';
            } else if (tempTier > tempCurrentTier) {
                return 'Upgrade';
            } else if (planTime !== currentInterval) {
                return 'Upgrade';
            } else {
                return 'Current Plan';
            }
        } else {
            return `Switch to ${planTime}ly`;
        }
    };

    const getAmount = header => {
        if (planTime === 'month') {
            return formatNumberString(header.unit_amount / 100);
        }
        return parseInt(formatNumberString(header.unit_amount / 1200));
    };
    const handleConfirmationClose = useCallback(() => {
        setOpenConfirmation(false);
    }, []);

    const handleRetractCancellation = useCallback(async () => {
        let priceID = rawData?.find(el => {
            if (el.tier === currentTier) return el;
        });

        try {
            setLoadingRetract(true);
            const body = {
                price: priceID.id,
            };

            const res = await RetractCancellationApi(getAccountDetails()?.plan?.id, body);
            if (res.success) {
                handleConfirmationClose();
                const authObject = JSON.parse(localStorage.getItem('auth-user')) || {};
                const sessionId = getSessionId();
                delete authObject[sessionId].auth.account.downgrade.plan;
                localStorage.setItem('auth-user', JSON.stringify(authObject));
                setDowngradeData({
                    plan: '',
                });
                publish('settings-updated');
            }
        } catch (err) {
            showToast({
                type: 'error',
                message: err || 'Something went wrong',
            });
        } finally {
            setLoadingRetract(false);
        }
    }, [currentTier, handleConfirmationClose, publish, rawData, showToast]);

    const getRenewalDate = () => {
        if (getAccountDetails()?.plan?.tier === 'tier0') {
            let date = dayjs().add(1, 'month');
            date = date.date(1);
            return formateDate(date, false, false, true);
        } else {
            return formateDate(
                dayjs(getAccountDetails()?.plan?.renewal * 1000),
                false,
                false,
                true,
            );
        }
    };

    return (
        <Fragment>
            {cancellationStepperOpen && planData && (
                <CancelSubscription
                    open={cancellationStepperOpen}
                    close={() => setCancellationStepperOpen(false)}
                    type={{ service: false, serviceName: '', plan: planData }}
                    setIsDownGraded={setIsDownGrade}
                    setCurrentTier={setCurrentTier}
                />
            )}
            {openConfirmation && (
                <Confirmation
                    open={openConfirmation}
                    onClose={handleConfirmationClose}
                    confirmationType="confirm"
                    title={'Are you sure you want to resume the subscription?'}
                    message={`This will update your subscription so that it does not cancel on ${getRenewalDate()} and will not delete any resources that were selected during the downgrade process.`}
                    type="confirm"
                    loading={loadingRetract}
                    declinedButton={{ title: 'Cancel', onClick: handleConfirmationClose }}
                    acceptButton={{
                        title: 'Confirm',
                        onClick: () => {
                            handleRetractCancellation();
                        },
                    }}
                />
            )}
            {openCheckout && checkoutData && (
                <Checkout
                    data={{ [checkoutData.buyer?.id]: [checkoutData] }}
                    actualCost={checkoutData.item?.actual_cost}
                    handleCongrats={() => {
                        setOpenCheckout(false);
                        onClose();
                        let message = `Your plan has been successfully updated.`;
                        showToast({
                            message,
                            type: 'success',
                            duration: 3000,
                        });
                        publish('settings-updated');
                    }}
                    dialogMode={true}
                    handleDialogClose={() => setOpenCheckout(false)}
                    singleItem={true}
                    fetchSingleItemData={() => {
                        return singleItemCheckout(singleItemBody, { type: 'preview' });
                    }}
                    totals={{
                        promocode: checkoutData.promocode,
                        total_discount_amounts:
                            checkoutData.item.setup_amount -
                                checkoutData.item.actual_cost.setup_fee ||
                            checkoutData.item.amount - checkoutData.item.actual_cost.recurring_fee,
                        total: 0,
                    }}
                />
            )}
            <Dialog
                dialogType={'fullWidth'}
                classes={{ root: 'AppComparePlansRoot' }}
                open={open}
                onClose={onClose}
            >
                <Div className={'AppComparePlans'}>
                    <TopBar>
                        <Div className={'HeaderBar'}>
                            <H1 className={'HeaderBarTitle'}>Compare plans</H1>
                            <Div className={'HeaderBarClose'}>
                                <IconButton onClick={onClose}>
                                    <DCCrossClose />
                                </IconButton>
                            </Div>
                        </Div>
                    </TopBar>
                    <Div className={'ACPBOuter'}>
                        <Div className={'ACPBody'}>
                            <Div className={'ACPBHead'}>
                                <Div className={'ACPBHCol'}>
                                    <Div className={'ACPBHCText'}>Save 10% with annual plans</Div>
                                    <Div className={'ACPBHCSwitch'}>
                                        <Span
                                            className={planTime === 'month' ? 'ACPBHCSActive' : ''}
                                            onClick={() => {
                                                handlePlanTime('month');
                                            }}
                                        >
                                            Monthly
                                        </Span>
                                        <Span
                                            className={planTime === 'year' ? 'ACPBHCSActive' : ''}
                                            onClick={() => {
                                                handlePlanTime('year');
                                            }}
                                        >
                                            Yearly
                                        </Span>
                                    </Div>
                                </Div>
                                {rawData.map((header, index) => {
                                    return (
                                        <Div
                                            key={`header=${index + 1}`}
                                            className={'ACPBHCol'}
                                            style={{
                                                background: header.color,
                                            }}
                                        >
                                            <Div className={'ACPBHCTitle'}>{header.nickname}</Div>
                                            <Div className={'ACPBHCPrice'}>
                                                {hidePurchaseBtn ? (
                                                    <ToolTip
                                                        content={
                                                            'This is the the retail price a sub-account will pay when purchasing this tier.'
                                                        }
                                                    >
                                                        ${getAmount(header)}
                                                        /mo
                                                    </ToolTip>
                                                ) : (
                                                    <div>
                                                        ${getAmount(header)}
                                                        /mo
                                                    </div>
                                                )}
                                            </Div>
                                            {!hidePurchaseBtn && (
                                                <Div className={'ACPBHCBtn'}>
                                                    {isDownGrade &&
                                                    getButtonLabel(header.tier) === 'Downgrade' ? (
                                                        <ToolTip
                                                            content={
                                                                'Downgrade is under progress please refresh'
                                                            }
                                                            wrapperClass={'ACPBHCBtnTooltip'}
                                                        >
                                                            <Button
                                                                style={{ color: header.color }}
                                                                disabled={
                                                                    (header.tier === currentTier &&
                                                                        planTime ===
                                                                            currentInterval) ||
                                                                    isDownGrade
                                                                }
                                                                onClick={() => {
                                                                    if (
                                                                        getButtonLabel(
                                                                            header.tier,
                                                                        ) === 'Downgrade'
                                                                    ) {
                                                                        if (
                                                                            downgradeData?.plan ===
                                                                                'tier0' &&
                                                                            header.tier === 'tier0'
                                                                        ) {
                                                                            setOpenConfirmation(
                                                                                true,
                                                                            );
                                                                            return;
                                                                        } else {
                                                                            history.push({
                                                                                search: `step=cancellation-reason`,
                                                                            });
                                                                            if (
                                                                                header.tier ===
                                                                                'tier0'
                                                                            ) {
                                                                                setNewCurrentTier(
                                                                                    header.tier,
                                                                                );
                                                                            }
                                                                            setPlanData(header);
                                                                            setCancellationStepperOpen(
                                                                                true,
                                                                            );
                                                                            return;
                                                                        }
                                                                    }
                                                                    upgradePlan(
                                                                        header.id,
                                                                        header.tier,
                                                                    );
                                                                }}
                                                                loading={
                                                                    loadingUpgrade === header.tier
                                                                }
                                                            >
                                                                {downgradeData?.plan === 'tier0' &&
                                                                header.tier === 'tier0' &&
                                                                getButtonLabel(header.tier) ===
                                                                    'Downgrade'
                                                                    ? 'Cancellation Pending'
                                                                    : getButtonLabel(header.tier)}
                                                            </Button>
                                                        </ToolTip>
                                                    ) : (
                                                        <Button
                                                            style={{ color: header.color }}
                                                            disabled={
                                                                (header.tier === currentTier &&
                                                                    planTime === currentInterval) ||
                                                                isDownGrade
                                                            }
                                                            onClick={() => {
                                                                if (
                                                                    getButtonLabel(header.tier) ===
                                                                    'Downgrade'
                                                                ) {
                                                                    if (
                                                                        downgradeData?.plan ===
                                                                            'tier0' &&
                                                                        header.tier === 'tier0'
                                                                    ) {
                                                                        setOpenConfirmation(true);
                                                                        return;
                                                                    } else {
                                                                        history.push({
                                                                            search: `step=cancellation-reason`,
                                                                        });
                                                                        if (
                                                                            header.tier === 'tier0'
                                                                        ) {
                                                                            setNewCurrentTier(
                                                                                header.tier,
                                                                            );
                                                                        }
                                                                        setPlanData(header);
                                                                        setCancellationStepperOpen(
                                                                            true,
                                                                        );
                                                                        return;
                                                                    }
                                                                }
                                                                upgradePlan(header.id, header.tier);
                                                            }}
                                                            loading={loadingUpgrade === header.tier}
                                                        >
                                                            {downgradeData?.plan === 'tier0' &&
                                                            header.tier === 'tier0' &&
                                                            getButtonLabel(header.tier) ===
                                                                'Downgrade'
                                                                ? 'Cancellation Pending'
                                                                : getButtonLabel(header.tier)}
                                                        </Button>
                                                    )}
                                                </Div>
                                            )}
                                        </Div>
                                    );
                                })}
                            </Div>
                            <Div className={'ACPBCompare'}>
                                {formattedData.map((section, index) => {
                                    return (
                                        <Div
                                            className={'ACPBCGroup'}
                                            key={`master-plan-${index + 1}`}
                                        >
                                            <Div className={'ACPBCGHead'}>
                                                <Div className={'ACPBCGHInner'}>
                                                    <Div className={'ACPBCGIcon'}>
                                                        {getIcon(section.section)}
                                                    </Div>
                                                    <Div className={'ACPBCGTitle'}>
                                                        {section.section}
                                                    </Div>
                                                </Div>
                                            </Div>
                                            {section.features.map((feature, index) => {
                                                return (
                                                    <Div
                                                        className={'ACPBCRow'}
                                                        key={`feature-${index + 1}`}
                                                    >
                                                        <Div className={'ACPBCRCol'}>
                                                            <ToolTip
                                                                content={
                                                                    <Fragment>
                                                                        <Div className="TooltipTitle">
                                                                            {feature.label}
                                                                        </Div>
                                                                        <Div className="TooltipDes">
                                                                            {feature.tooltip}
                                                                        </Div>
                                                                    </Fragment>
                                                                }
                                                            >
                                                                {feature.label}
                                                            </ToolTip>
                                                        </Div>

                                                        {feature.values.map((value, index) => {
                                                            return (
                                                                <Fragment
                                                                    key={`value-highlight-${
                                                                        index + 1
                                                                    }`}
                                                                >
                                                                    {typeof value === 'boolean' ? (
                                                                        value ? (
                                                                            <Div
                                                                                className={
                                                                                    'ACPBCRCol ACPBCRCIcon'
                                                                                }
                                                                                style={{
                                                                                    color: rawData[
                                                                                        index
                                                                                    ]?.color,
                                                                                }}
                                                                            >
                                                                                <DCFillCheck />
                                                                            </Div>
                                                                        ) : (
                                                                            <Div
                                                                                className={
                                                                                    'ACPBCRCol'
                                                                                }
                                                                            >
                                                                                ---
                                                                            </Div>
                                                                        )
                                                                    ) : (
                                                                        <Div
                                                                            className={'ACPBCRCol'}
                                                                            key={`value-highlight-${
                                                                                index + 1
                                                                            }`}
                                                                        >
                                                                            {Number.isInteger(value)
                                                                                ? formatNumberString(
                                                                                      value,
                                                                                  )
                                                                                : value}
                                                                        </Div>
                                                                    )}
                                                                </Fragment>
                                                            );
                                                        })}
                                                    </Div>
                                                );
                                            })}
                                        </Div>
                                    );
                                })}
                            </Div>
                        </Div>
                    </Div>
                </Div>
            </Dialog>
        </Fragment>
    );
};

AppComparePlans.propTypes = {
    planTime: PropTypes.string.isRequired,
    handlePlanTime: PropTypes.func.isRequired,
    data: PropTypes.arrayOf(PropTypes.object).isRequired,
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    hidePurchaseBtn: PropTypes.bool,
};

export default AppComparePlans;
