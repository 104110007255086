export const payWallLimits = {
    tier0: {
        CONTACT_RECORDS: {
            unit: 500,
            interval: 'lifetime',
        },
        SALES_PIPELINES: {
            unit: 1,
            interval: 'lifetime',
        },
        FORMS: {
            unit: 1,
            interval: 'lifetime',
        },
        INBOUND_CAMPAIGNS: {
            unit: 1,
            interval: 'lifetime',
        },
        TRACKED_SEO_KEYWORDS: {
            unit: 10,
            interval: 'lifetime',
        },
        CUSTOM_TEMPLATES: {
            unit: 5,
            interval: 'lifetime',
        },
        INSTASITES_BUILDS: {
            unit: 3,
            interval: 'month',
        },
        INSTAREPORTS_BUILDS: {
            unit: 1,
            interval: 'month',
        },
        EMAIL: {
            unit: 50,
            interval: 'month',
        },
        SMS: {
            unit: 0,
            interval: 'month',
        },
        LEADS: {
            unit: 1,
            interval: 'daily',
        },
        CONVERSATIONS: {
            unit: 0,
            interval: 'lifetime',
        },
        CONTACTS_CUSTOM_FILTERS: {
            unit: 0,
            interval: 'lifetime',
        },
        CONTACTS_CUSTOM_PROPERTIES: {
            unit: 0,
            interval: 'lifetime',
        },
        CONTACTS_TAGS: {
            unit: 0,
            interval: 'lifetime',
        },
        DEALS_CUSTOM_FILTERS: {
            unit: 0,
            interval: 'lifetime',
        },
        DEALS_CUSTOM_PROPERTIES: {
            unit: 0,
            interval: 'lifetime',
        },
        DEALS_AUTOMATION: {
            unit: 0,
            interval: 'lifetime',
        },
        DEALS_TAGS: {
            unit: 0,
            interval: 'lifetime',
        },
        GROUP_FORM: {
            unit: 0,
            interval: 'lifetime',
        },
        CARD_FORM: {
            unit: 0,
            interval: 'lifetime',
        },
        ROUND_ROBIN_LEADS: {
            unit: 0,
            interval: 'lifetime',
        },
        ADD_TO_CRM: {
            unit: 0,
            interval: 'lifetime',
        },
        ADD_TO_DEALS: {
            unit: 0,
            interval: 'lifetime',
        },
        INSIGHTS: {
            unit: 0,
            interval: 'lifetime',
        },
        ECOMMERCE_TEMPLATES: {
            unit: 0,
            interval: 'lifetime',
        },
        ADVANCED_SEARCH: {
            unit: 0,
            interval: 'lifetime',
        },
        LEAD_AUTOMATION: {
            unit: 0,
            interval: 'lifetime',
        },
        CUSTOM_DOMAIN: {
            unit: 0,
            interval: 'lifetime',
        },
        CUSTOM_EMAIL: {
            unit: 0,
            interval: 'lifetime',
        },
        PARTNER_ONBOARDING: {
            unit: 0,
            interval: 'lifetime',
        },
        PHONE_SUPPORT: {
            unit: 0,
            interval: 'lifetime',
        },
        TEAM_MEMBER_SEATS: {
            unit: 2,
            interval: 'lifetime',
        },
        REVIEW_REQUESTS: {
            unit: 5,
            interval: 'lifetime',
        },
        REVIEW_INTEGRATIONS: {
            unit: 1,
            interval: 'lifetime',
        },
        REVIEW_REPLY: {
            unit: 0,
            interval: 'lifetime',
        },
        TWO_WAY_TEXTING: {
            unit: 0,
            interval: 'lifetime',
        },
        TWO_WAY_PHONE_CALLS: {
            unit: 0,
            interval: 'lifetime',
        },
        VOICE_NOTES: {
            unit: 0,
            interval: 'lifetime',
        },
        BRANDED_LIVE_CHAT: {
            unit: 0,
            interval: 'lifetime',
        },
        INBOX_ROUND_ROBIN: {
            unit: 0,
            interval: 'lifetime',
        },
        CUSTOM_SUPPORT_INBOXES: {
            unit: 1,
            interval: 'lifetime',
        },
    },
    tier1: {
        CONTACT_RECORDS: {
            unit: 10000,
            interval: 'lifetime',
        },
        SALES_PIPELINES: {
            unit: 3,
            interval: 'lifetime',
        },
        FORMS: {
            unit: 5,
            interval: 'lifetime',
        },
        INBOUND_CAMPAIGNS: {
            unit: 3,
            interval: 'lifetime',
        },
        TRACKED_SEO_KEYWORDS: {
            unit: 25,
            interval: 'lifetime',
        },
        CUSTOM_TEMPLATES: {
            unit: 50,
            interval: 'lifetime',
        },
        INSTASITES_BUILDS: {
            unit: 100,
            interval: 'month',
        },
        INSTAREPORTS_BUILDS: {
            unit: 10,
            interval: 'month',
        },
        LEADS: {
            unit: 5,
            interval: 'daily',
        },
        EMAIL: {
            unit: 1000,
            interval: 'month',
        },
        SMS: {
            unit: 100,
            interval: 'month',
        },
        CONVERSATIONS: {
            unit: -1,
            interval: 'lifetime',
        },
        CONTACTS_CUSTOM_FILTERS: {
            unit: -1,
            interval: 'lifetime',
        },
        CONTACTS_CUSTOM_PROPERTIES: {
            unit: -1,
            interval: 'lifetime',
        },
        CONTACTS_TAGS: {
            unit: -1,
            interval: 'lifetime',
        },
        DEALS_CUSTOM_FILTERS: {
            unit: -1,
            interval: 'lifetime',
        },
        DEALS_CUSTOM_PROPERTIES: {
            unit: -1,
            interval: 'lifetime',
        },
        DEALS_TAGS: {
            unit: -1,
            interval: 'lifetime',
        },
        DEALS_AUTOMATION: {
            unit: 0,
            interval: 'lifetime',
        },
        GROUP_FORM: {
            unit: -1,
            interval: 'lifetime',
        },
        CARD_FORM: {
            unit: -1,
            interval: 'lifetime',
        },
        ROUND_ROBIN_LEADS: {
            unit: -1,
            interval: 'lifetime',
        },
        ADD_TO_CRM: {
            unit: 0,
            interval: 'lifetime',
        },
        ADD_TO_DEALS: {
            unit: 0,
            interval: 'lifetime',
        },
        INSIGHTS: {
            unit: -1,
            interval: 'lifetime',
        },
        ECOMMERCE_TEMPLATES: {
            unit: -1,
            interval: 'lifetime',
        },
        ADVANCED_SEARCH: {
            unit: -1,
            interval: 'lifetime',
        },
        LEAD_AUTOMATION: {
            unit: 0,
            interval: 'lifetime',
        },
        CUSTOM_DOMAIN: {
            unit: -1,
            interval: 'lifetime',
        },
        CUSTOM_EMAIL: {
            unit: -1,
            interval: 'lifetime',
        },
        PARTNER_ONBOARDING: {
            unit: -1,
            interval: 'lifetime',
        },
        PHONE_SUPPORT: {
            unit: 0,
            interval: 'lifetime',
        },
        TEAM_MEMBER_SEATS: {
            unit: 5,
            interval: 'lifetime',
        },
        REVIEW_REQUESTS: {
            unit: 100,
            interval: 'lifetime',
        },
        REVIEW_INTEGRATIONS: {
            unit: 3,
            interval: 'lifetime',
        },
        REVIEW_REPLY: {
            unit: -1,
            interval: 'lifetime',
        },
        TWO_WAY_TEXTING: {
            unit: -1,
            interval: 'lifetime',
        },
        TWO_WAY_PHONE_CALLS: {
            unit: -1,
            interval: 'lifetime',
        },
        VOICE_NOTES: {
            unit: -1,
            interval: 'lifetime',
        },
        BRANDED_LIVE_CHAT: {
            unit: -1,
            interval: 'lifetime',
        },
        INBOX_ROUND_ROBIN: {
            unit: 0,
            interval: 'lifetime',
        },
        CUSTOM_SUPPORT_INBOXES: {
            unit: 3,
            interval: 'lifetime',
        },
    },
    tier2: {
        CONTACT_RECORDS: {
            unit: 50000,
            interval: 'lifetime',
        },
        SALES_PIPELINES: {
            unit: 10,
            interval: 'lifetime',
        },
        FORMS: {
            unit: 25,
            interval: 'lifetime',
        },
        INBOUND_CAMPAIGNS: {
            unit: 10,
            interval: 'lifetime',
        },
        TRACKED_SEO_KEYWORDS: {
            unit: 75,
            interval: 'lifetime',
        },
        CUSTOM_TEMPLATES: {
            unit: 100,
            interval: 'lifetime',
        },
        INSTASITES_BUILDS: {
            unit: 200,
            interval: 'month',
        },
        INSTAREPORTS_BUILDS: {
            unit: 100,
            interval: 'month',
        },
        LEADS: {
            unit: 25,
            interval: 'daily',
        },
        EMAIL: {
            unit: 5000,
            interval: 'month',
        },
        SMS: {
            unit: 500,
            interval: 'month',
        },
        CONVERSATIONS: {
            unit: -1,
            interval: 'lifetime',
        },
        CONTACTS_CUSTOM_FILTERS: {
            unit: -1,
            interval: 'lifetime',
        },
        CONTACTS_CUSTOM_PROPERTIES: {
            unit: -1,
            interval: 'lifetime',
        },
        CONTACTS_TAGS: {
            unit: -1,
            interval: 'lifetime',
        },
        DEALS_CUSTOM_FILTERS: {
            unit: -1,
            interval: 'lifetime',
        },
        DEALS_CUSTOM_PROPERTIES: {
            unit: -1,
            interval: 'lifetime',
        },
        DEALS_TAGS: {
            unit: -1,
            interval: 'lifetime',
        },
        DEALS_AUTOMATION: {
            unit: -1,
            interval: 'lifetime',
        },
        GROUP_FORM: {
            unit: -1,
            interval: 'lifetime',
        },
        CARD_FORM: {
            unit: -1,
            interval: 'lifetime',
        },
        ROUND_ROBIN_LEADS: {
            unit: -1,
            interval: 'lifetime',
        },
        ADD_TO_CRM: {
            unit: -1,
            interval: 'lifetime',
        },
        ADD_TO_DEALS: {
            unit: -1,
            interval: 'lifetime',
        },
        INSIGHTS: {
            unit: -1,
            interval: 'lifetime',
        },
        ECOMMERCE_TEMPLATES: {
            unit: -1,
            interval: 'lifetime',
        },
        ADVANCED_SEARCH: {
            unit: -1,
            interval: 'lifetime',
        },
        LEAD_AUTOMATION: {
            unit: -1,
            interval: 'lifetime',
        },
        CUSTOM_DOMAIN: {
            unit: -1,
            interval: 'lifetime',
        },
        CUSTOM_EMAIL: {
            unit: -1,
            interval: 'lifetime',
        },
        PARTNER_ONBOARDING: {
            unit: -1,
            interval: 'lifetime',
        },
        PHONE_SUPPORT: {
            unit: 0,
            interval: 'lifetime',
        },
        TEAM_MEMBER_SEATS: {
            unit: 10,
            interval: 'lifetime',
        },
        REVIEW_REQUESTS: {
            unit: 1000,
            interval: 'lifetime',
        },
        REVIEW_INTEGRATIONS: {
            unit: 5,
            interval: 'lifetime',
        },
        REVIEW_REPLY: {
            unit: -1,
            interval: 'lifetime',
        },
        TWO_WAY_TEXTING: {
            unit: -1,
            interval: 'lifetime',
        },
        TWO_WAY_PHONE_CALLS: {
            unit: -1,
            interval: 'lifetime',
        },
        VOICE_NOTES: {
            unit: -1,
            interval: 'lifetime',
        },
        BRANDED_LIVE_CHAT: {
            unit: -1,
            interval: 'lifetime',
        },
        INBOX_ROUND_ROBIN: {
            unit: -1,
            interval: 'lifetime',
        },
        CUSTOM_SUPPORT_INBOXES: {
            unit: 5,
            interval: 'lifetime',
        },
    },
    tier3: {
        CONTACT_RECORDS: {
            unit: 250000,
            interval: 'lifetime',
        },
        SALES_PIPELINES: {
            unit: -1,
            interval: 'lifetime',
        },
        FORMS: {
            unit: -1,
            interval: 'lifetime',
        },
        INBOUND_CAMPAIGNS: {
            unit: -1,
            interval: 'lifetime',
        },
        TRACKED_SEO_KEYWORDS: {
            unit: 100,
            interval: 'lifetime',
        },
        CUSTOM_TEMPLATES: {
            unit: -1,
            interval: 'lifetime',
        },
        INSTASITES_BUILDS: {
            unit: 300,
            interval: 'month',
        },
        INSTAREPORTS_BUILDS: {
            unit: 200,
            interval: 'month',
        },
        LEADS: {
            unit: 100,
            interval: 'daily',
        },
        EMAIL: {
            unit: 25000,
            interval: 'month',
        },
        SMS: {
            unit: 1000,
            interval: 'month',
        },
        CONVERSATIONS: {
            unit: -1,
            interval: 'lifetime',
        },
        CONTACTS_CUSTOM_FILTERS: {
            unit: -1,
            interval: 'lifetime',
        },
        CONTACTS_CUSTOM_PROPERTIES: {
            unit: -1,
            interval: 'lifetime',
        },
        CONTACTS_TAGS: {
            unit: -1,
            interval: 'lifetime',
        },
        DEALS_CUSTOM_FILTERS: {
            unit: -1,
            interval: 'lifetime',
        },
        DEALS_CUSTOM_PROPERTIES: {
            unit: -1,
            interval: 'lifetime',
        },
        DEALS_TAGS: {
            unit: -1,
            interval: 'lifetime',
        },
        DEALS_AUTOMATION: {
            unit: -1,
            interval: 'lifetime',
        },
        GROUP_FORM: {
            unit: -1,
            interval: 'lifetime',
        },
        CARD_FORM: {
            unit: -1,
            interval: 'lifetime',
        },
        ROUND_ROBIN_LEADS: {
            unit: -1,
            interval: 'lifetime',
        },
        ADD_TO_CRM: {
            unit: -1,
            interval: 'lifetime',
        },
        ADD_TO_DEALS: {
            unit: -1,
            interval: 'lifetime',
        },
        INSIGHTS: {
            unit: -1,
            interval: 'lifetime',
        },
        ECOMMERCE_TEMPLATES: {
            unit: -1,
            interval: 'lifetime',
        },
        ADVANCED_SEARCH: {
            unit: -1,
            interval: 'lifetime',
        },
        LEAD_AUTOMATION: {
            unit: -1,
            interval: 'lifetime',
        },
        CUSTOM_DOMAIN: {
            unit: -1,
            interval: 'lifetime',
        },
        CUSTOM_EMAIL: {
            unit: -1,
            interval: 'lifetime',
        },
        PARTNER_ONBOARDING: {
            unit: -1,
            interval: 'lifetime',
        },
        PHONE_SUPPORT: {
            unit: -1,
            interval: 'lifetime',
        },
        TEAM_MEMBER_SEATS: {
            unit: 20,
            interval: 'lifetime',
        },
        REVIEW_REQUESTS: {
            unit: -1,
            interval: 'lifetime',
        },
        REVIEW_INTEGRATIONS: {
            unit: -1,
            interval: 'lifetime',
        },
        REVIEW_REPLY: {
            unit: -1,
            interval: 'lifetime',
        },
        TWO_WAY_TEXTING: {
            unit: -1,
            interval: 'lifetime',
        },
        TWO_WAY_PHONE_CALLS: {
            unit: -1,
            interval: 'lifetime',
        },
        VOICE_NOTES: {
            unit: -1,
            interval: 'lifetime',
        },
        BRANDED_LIVE_CHAT: {
            unit: -1,
            interval: 'lifetime',
        },
        INBOX_ROUND_ROBIN: {
            unit: -1,
            interval: 'lifetime',
        },
        CUSTOM_SUPPORT_INBOXES: {
            unit: -1,
            interval: 'lifetime',
        },
    },
    tier4: {
        CONTACT_RECORDS: {
            unit: -1,
            interval: 'lifetime',
        },
        SALES_PIPELINES: {
            unit: -1,
            interval: 'lifetime',
        },
        FORMS: {
            unit: -1,
            interval: 'lifetime',
        },
        INBOUND_CAMPAIGNS: {
            unit: -1,
            interval: 'lifetime',
        },
        TRACKED_SEO_KEYWORDS: {
            unit: -1,
            interval: 'lifetime',
        },
        CUSTOM_TEMPLATES: {
            unit: -1,
            interval: 'lifetime',
        },
        INSTASITES_BUILDS: {
            unit: -1,
            interval: 'month',
        },
        INSTAREPORTS_BUILDS: {
            unit: -1,
            interval: 'month',
        },
        LEADS: {
            unit: -1,
            interval: 'daily',
        },
        EMAIL: {
            unit: -1,
            interval: 'month',
        },
        SMS: {
            unit: -1,
            interval: 'month',
        },
        CONVERSATIONS: {
            unit: -1,
            interval: 'lifetime',
        },
        CONTACTS_CUSTOM_FILTERS: {
            unit: -1,
            interval: 'lifetime',
        },
        CONTACTS_CUSTOM_PROPERTIES: {
            unit: -1,
            interval: 'lifetime',
        },
        CONTACTS_TAGS: {
            unit: -1,
            interval: 'lifetime',
        },
        DEALS_CUSTOM_FILTERS: {
            unit: -1,
            interval: 'lifetime',
        },
        DEALS_CUSTOM_PROPERTIES: {
            unit: -1,
            interval: 'lifetime',
        },
        DEALS_TAGS: {
            unit: -1,
            interval: 'lifetime',
        },
        DEALS_AUTOMATION: {
            unit: -1,
            interval: 'lifetime',
        },
        GROUP_FORM: {
            unit: -1,
            interval: 'lifetime',
        },
        CARD_FORM: {
            unit: -1,
            interval: 'lifetime',
        },
        ROUND_ROBIN_LEADS: {
            unit: -1,
            interval: 'lifetime',
        },
        ADD_TO_CRM: {
            unit: -1,
            interval: 'lifetime',
        },
        ADD_TO_DEALS: {
            unit: -1,
            interval: 'lifetime',
        },
        INSIGHTS: {
            unit: -1,
            interval: 'lifetime',
        },
        ECOMMERCE_TEMPLATES: {
            unit: -1,
            interval: 'lifetime',
        },
        ADVANCED_SEARCH: {
            unit: -1,
            interval: 'lifetime',
        },
        LEAD_AUTOMATION: {
            unit: -1,
            interval: 'lifetime',
        },
        CUSTOM_DOMAIN: {
            unit: -1,
            interval: 'lifetime',
        },
        CUSTOM_EMAIL: {
            unit: -1,
            interval: 'lifetime',
        },
        PARTNER_ONBOARDING: {
            unit: -1,
            interval: 'lifetime',
        },
        PHONE_SUPPORT: {
            unit: -1,
            interval: 'lifetime',
        },
        TEAM_MEMBER_SEATS: {
            unit: -1,
            interval: 'lifetime',
        },
        REVIEW_REQUESTS: {
            unit: -1,
            interval: 'lifetime',
        },
        REVIEW_INTEGRATIONS: {
            unit: -1,
            interval: 'lifetime',
        },
        REVIEW_REPLY: {
            unit: -1,
            interval: 'lifetime',
        },
        TWO_WAY_TEXTING: {
            unit: -1,
            interval: 'lifetime',
        },
        TWO_WAY_PHONE_CALLS: {
            unit: -1,
            interval: 'lifetime',
        },
        VOICE_NOTES: {
            unit: -1,
            interval: 'lifetime',
        },
        BRANDED_LIVE_CHAT: {
            unit: -1,
            interval: 'lifetime',
        },
        INBOX_ROUND_ROBIN: {
            unit: -1,
            interval: 'lifetime',
        },
        CUSTOM_SUPPORT_INBOXES: {
            unit: -1,
            interval: 'lifetime',
        },
    },
};

export const payWallConstants = {
    CONTACT_RECORDS: 'CONTACT_RECORDS',
    SALES_PIPELINES: 'SALES_PIPELINES',
    FORMS: 'FORMS',
    CAMPAIGNS: 'INBOUND_CAMPAIGNS',
    TRACKED_SEO_KEYWORDS: 'TRACKED_SEO_KEYWORDS',
    CUSTOM_TEMPLATES: 'CUSTOM_TEMPLATES',
    INSTASITES_BUILDS: 'INSTASITES_BUILDS',
    INSTAREPORTS_BUILDS: 'INSTAREPORTS_BUILDS',
    LEADS: 'LEADS',
    CONVERSATIONS: 'CONVERSATIONS',
    CONTACTS_CUSTOM_FILTERS: 'CONTACTS_CUSTOM_FILTERS',
    CONTACTS_CUSTOM_PROPERTIES: 'CONTACTS_CUSTOM_PROPERTIES',
    CONTACTS_TAGS: 'CONTACTS_TAGS',
    DEALS_CUSTOM_FILTERS: 'DEALS_CUSTOM_FILTERS',
    DEALS_CUSTOM_PROPERTIES: 'DEALS_CUSTOM_PROPERTIES',
    DEALS_TAGS: 'DEALS_TAGS',
    DEALS_AUTOMATION: 'DEALS_AUTOMATION',
    GROUP_FORM: 'GROUP_FORM',
    CARD_FORM: 'CARD_FORM',
    ROUND_ROBIN_LEADS: 'ROUND_ROBIN_LEADS',
    ADD_TO_CRM: 'ADD_TO_CRM',
    ADD_TO_DEALS: 'ADD_TO_DEALS',
    INSIGHTS: 'INSIGHTS',
    ECOMMERCE_TEMPLATES: 'ECOMMERCE_TEMPLATES',
    ADVANCED_SEARCH: 'ADVANCED_SEARCH',
    LEAD_AUTOMATION: 'LEAD_AUTOMATION',
    CUSTOM_DOMAIN: 'CUSTOM_DOMAIN',
    CUSTOM_EMAIL: 'CUSTOM_EMAIL',
    PARTNER_ONBOARDING: 'PARTNER_ONBOARDING',
    PHONE_SUPPORT: 'PHONE_SUPPORT',
    TEAM_MEMBER_SEATS: 'TEAM_MEMBER_SEATS',
    SMS_CREDITS: 'SMS_CREDITS',
    SMS: 'SMS',
    EMAIL: 'EMAIL',
    REVIEW_REQUESTS: 'REVIEW_REQUESTS',
    REVIEW_INTEGRATIONS: 'REVIEW_INTEGRATIONS',
    REVIEW_REPLY: 'REVIEW_REPLY',
    TWO_WAY_TEXTING: 'TWO_WAY_TEXTING',
    TWO_WAY_PHONE_CALLS: 'TWO_WAY_PHONE_CALLS',
    VOICE_NOTES: 'VOICE_NOTES',
    BRANDED_LIVE_CHAT: 'BRANDED_LIVE_CHAT',
    INBOX_ROUND_ROBIN: 'INBOX_ROUND_ROBIN',
    CUSTOM_SUPPORT_INBOXES: 'CUSTOM_SUPPORT_INBOXES',
};

export default payWallLimits;
