import React from 'react';
import DCProject from 'Assets/icons/DCProject';
import DCInbound from 'Assets/icons/DCInbound';
import DCContacts from 'Assets/icons/DCContacts';
import DCAgency from 'Assets/icons/DCAgency';
import DCAnalytics from 'Assets/icons/DCAnalytics';
import DCDashboardMenu from 'Assets/icons/DCDashboardMenu';
import DCDealMenu from 'Assets/icons/DCDealMenu';
import DCForms from 'Assets/icons/DCForms';
import DCInstareports from 'Assets/icons/DCInstareports';
import DCInstasites from 'Assets/icons/DCInstasites';
import DCTemplates from 'Assets/icons/DCTemplates';
import DCSettingMenu from 'Assets/icons/DCSettingMenu';
import DCLeads from 'Assets/icons/DCLeads';
import DCReputation from 'Assets/icons/DCReputation';

const GetIcon = app => {
    switch (app) {
        case 'Projects':
            return <DCProject />;
        case 'Inbound':
            return <DCInbound />;
        case 'Contacts':
            return <DCContacts />;
        case 'Templates':
            return <DCTemplates />;
        case 'InstaSites':
            return <DCInstasites />;
        case 'InstaReports':
            return <DCInstareports />;
        case 'Forms':
            return <DCForms />;
        case 'Sites':
            return <DCAgency />;
        case 'Deals':
            return <DCDealMenu />;
        case 'Analytics':
            return <DCAnalytics />;
        case 'Dashboard':
            return <DCDashboardMenu />;
        case 'Leads':
            return <DCLeads />;
        case 'Reputation':
            return <DCReputation />;
        default:
            return <DCSettingMenu />;
    }
};

export default GetIcon;
