import './style.scss';
import PropTypes from 'prop-types';
import React from 'react';

const LinkIconButton = ({
    customClass,
    props,
    href,
    onClick,
    children,
    buttonType,
    reference,
    disabled,
    target,
    style,
}) => (
    <a
        href={href}
        {...props}
        target={target}
        onClick={onClick}
        className={`linkIconButton iconButton ${customClass || ''}${
            buttonType ? ` ${buttonType}` : ''
        }`}
        ref={reference}
        disabled={disabled}
        style={style}
    >
        {children}
    </a>
);

LinkIconButton.propTypes = {
    customClass: PropTypes.string,
    href: PropTypes.string,
    onClick: PropTypes.func,
    children: PropTypes.element,
    buttonType: PropTypes.string,
    reference: PropTypes.object,
    disabled: PropTypes.bool,
    target: PropTypes.string,
};
export default LinkIconButton;

// RedFillBtn
// RedOutlineBtn
// BlueFillBtn
// BlueOutlineBtn
// WhiteFillBtn
// WhiteOutlineBtn
// BlackFillBtn
// BlackOutlineBtn
// headerIconBtn
// circleBtn
