import React from 'react';
import PropTypes from 'prop-types';
import { Div } from '../index';

import './style.scss';

const AvatarGroups = ({ customClass, children }) => (
    <Div className={`AvatarGroups ${customClass || ''}`}>
        <Div className="AvatarGroupsInner">{children}</Div>
    </Div>
);

AvatarGroups.propTypes = {
    customClass: PropTypes.string,
    children: PropTypes.node.isRequired,
};
export default AvatarGroups;
