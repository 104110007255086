import React from 'react';
import generateImage from 'Utils/generateImage';
import PropTypes from 'prop-types';
import { Div, Link, UserInfo } from '../../UIKit/index';

import './style.scss';

export default function DuplicateUser({ content, name, email, image, link }) {
    return (
        <Div className={'DCDuplicateUser'}>
            <Div className={'DCDUInner'}>
                <Div className={'DCDUIContent'}>{content}</Div>
                <Link href={link} target={'_blank'} className={'DCDUIUser'}>
                    <UserInfo name={name} email={email} image={generateImage(image, name)} />
                </Link>
            </Div>
        </Div>
    );
}

DuplicateUser.propTypes = {
    content: PropTypes.element,
    name: PropTypes.string,
    email: PropTypes.string,
    image: PropTypes.object,
    link: PropTypes.string,
};
