import React from 'react';

import { Div, Link, LinkIconButton, TextOverflowTooltip } from '../index';
import DCExternalWindowIcon from 'Assets/icons/DCExternalWindow';
import PropTypes from 'prop-types';
import './style.scss';

const instasiteStatus = status => {
    return (
        <>
            {status === 'FAILED' && (
                <Div
                    className={'PTBudge'}
                    style={{
                        borderColor: '#ea4335',
                        color: '#ea4335',
                    }}
                >
                    Failed
                </Div>
            )}
            {status === 'NOT_PUBLISHED_YET' && (
                <Div
                    className={'PTBudge'}
                    style={{
                        borderColor: '#F4AD3B',
                        color: '#F4AD3B',
                    }}
                >
                    Built
                </Div>
            )}
            {status === 'PENDING' && (
                <Div
                    className={'PTBudge'}
                    style={{
                        borderColor: '#2269ec',
                        color: '#2269ec',
                    }}
                >
                    Queued
                </Div>
            )}
            {status === 'PUBLISHED' && (
                <Div
                    className={'PTBudge'}
                    style={{
                        borderColor: '#3a703c',
                        color: '#3a703c',
                    }}
                >
                    Purchased
                </Div>
            )}
            {status === 'UNPUBLISHED' && (
                <Div
                    className={'PTBudge'}
                    style={{
                        borderColor: '#ff2917',
                        color: '#ff2917',
                    }}
                >
                    Cancelled
                </Div>
            )}
            {status === 'RETRYING' && (
                <Div
                    className={'PTBudge'}
                    style={{
                        borderColor: '#651268',
                        color: '#651268',
                    }}
                >
                    Retrying
                </Div>
            )}
            {status === 'PURGED' && (
                <Div
                    className={'PTBudge'}
                    style={{
                        borderColor: '#646464',
                        color: '#646464',
                    }}
                >
                    PURGED
                </Div>
            )}
        </>
    );
};

const instareportStatus = status => {
    return (
        <>
            {status === 'FAILED' && (
                <Div
                    className={'PTBudge'}
                    style={{
                        borderColor: '#ea4335',
                        color: '#ea4335',
                    }}
                >
                    Failed
                </Div>
            )}
            {status === 'GENERATED' && (
                <Div
                    className={'PTBudge'}
                    style={{
                        borderColor: '#F4AD3B',
                        color: '#F4AD3B',
                    }}
                >
                    Built
                </Div>
            )}
            {status === 'QUEUED' && (
                <Div
                    className={'PTBudge'}
                    style={{
                        borderColor: '#2269ec',
                        color: '#2269ec',
                    }}
                >
                    Queued
                </Div>
            )}

            {status === 'RETRYING' && (
                <Div
                    className={'PTBudge'}
                    style={{
                        borderColor: '#651268',
                        color: '#651268',
                    }}
                >
                    Retrying
                </Div>
            )}
        </>
    );
};

const IconTitleSubtitle = props => {
    const { className, title, date, link, icon, buttons, instareportInfo, instasiteInfo, status } =
        props;
    return (
        <Div className={'IconTitleSubtitle ' + (className ? className : '')}>
            <Div className={'IconTitleSubtitleInner'}>
                <Div className={'ITSIIcon'}>{icon}</Div>
                <Div className={'ITSIInfo'}>
                    {status && !['GENERATED', 'NOT_PUBLISHED_YET', 'PUBLISHED'].includes(status) ? (
                        <Link href={'#'} target={'#'} className={'ITSIITitle'}>
                            <TextOverflowTooltip tooltipContent={title}>
                                {title}
                            </TextOverflowTooltip>
                        </Link>
                    ) : (
                        <Link
                            href={link && link.href}
                            target={link?.target}
                            className={'ITSIITitle'}
                        >
                            <TextOverflowTooltip tooltipContent={title}>
                                {title}
                            </TextOverflowTooltip>
                        </Link>
                    )}
                    <Div className={'ITSIIDate'}>{date}</Div>
                </Div>
                {buttons && buttons.length > 0 ? (
                    <Div className={'ITSIBtn'}>{buttons.map(button => button)}</Div>
                ) : null}
                {['GENERATED', 'NOT_PUBLISHED_YET', 'PUBLISHED'].includes(status) ? (
                    <>
                        {(instareportInfo || instasiteInfo) && (
                            <LinkIconButton
                                customClass={'WIRightButton'}
                                target={link?.target}
                                href={link && link.href}
                                buttonType={'circleBtn'}
                            >
                                <DCExternalWindowIcon />
                            </LinkIconButton>
                        )}
                    </>
                ) : (
                    <>{instareportInfo ? instareportStatus(status) : instasiteStatus(status)}</>
                )}
            </Div>
        </Div>
    );
};

IconTitleSubtitle.propTypes = {
    className: PropTypes.string,
    title: PropTypes.string,
    date: PropTypes.string,
    link: PropTypes.object,
    icon: PropTypes.element,
    buttons: PropTypes.arrayOf(PropTypes.element),
};

export default IconTitleSubtitle;

//  <IconTitleSubtitle
//   icon={<Icon />}
//   title={"File Name"}
//   link={{href: "#", target: "_blank"}}
//   date={"Upload Date"}
//  />
