import React from 'react';

import { SVGIcon } from '../../UIKit/index';

export default function DCDocText(props) {
    return (
        <SVGIcon {...props} viewBox="0 0 512 469">
            <g transform="translate(0 -1)">
                <g transform="translate(2.875 8.667)">
                    <path d="M258.14,227.85c-8.8,0-15.93-7.13-15.93-15.93V196H93.62v15.93    c-0.33,8.79-7.72,15.65-16.51,15.32c-8.33-0.31-15.01-6.99-15.32-15.32v-31.83c0-8.79,7.12-15.91,15.9-15.93h180.44    c8.8,0,15.93,7.13,15.93,15.93v31.85C274.05,220.73,266.93,227.85,258.14,227.85z" />
                </g>
                <g transform="translate(6.948 8.906)">
                    <path d="M163.84,370.88c-8.8,0-15.93-7.13-15.93-15.93V185.15c-0.33-8.79,6.53-16.18,15.32-16.51    c8.79-0.33,16.18,6.53,16.51,15.32c0.01,0.4,0.01,0.79,0,1.19v169.83C179.74,363.76,172.62,370.87,163.84,370.88z" />
                </g>
                <g transform="translate(5.75 16.813)">
                    <path d="M191.58,368.29h-53.16c-8.79-0.33-15.65-7.72-15.32-16.51c0.31-8.33,6.99-15.01,15.32-15.32    h53.16c8.8,0.01,15.92,7.14,15.92,15.94c-0.01,8.79-7.13,15.91-15.92,15.92V368.29z" />
                </g>
                <g transform="translate(14.375 8.667)">
                    <path d="M416.45,196.02h-95.52c-8.79-0.33-15.65-7.72-15.32-16.51c0.31-8.33,6.99-15.01,15.32-15.32    h95.54c8.8,0.01,15.92,7.14,15.92,15.94c-0.01,8.79-7.13,15.91-15.92,15.92L416.45,196.02z" />
                </g>
                <g transform="translate(14.375 12.5)">
                    <path d="M416.45,277.09h-95.52c-8.79-0.33-15.65-7.72-15.32-16.51c0.31-8.33,6.99-15.01,15.32-15.32    h95.54c8.8,0.01,15.92,7.14,15.92,15.94c-0.01,8.79-7.13,15.91-15.92,15.92L416.45,277.09z" />
                </g>
                <g transform="translate(14.375 16.333)">
                    <path d="M416.45,358.16h-95.52c-8.79-0.33-15.65-7.72-15.32-16.51c0.31-8.33,6.99-15.01,15.32-15.32    h95.54c8.8,0.01,15.92,7.14,15.92,15.94c-0.01,8.79-7.13,15.91-15.92,15.92L416.45,358.16z" />
                </g>
                <g transform="translate(0 1)">
                    <path d="M452.07,469.01H59.33C27.11,468.97,1,442.86,0.96,410.64V60.37C1,28.15,27.11,2.04,59.33,2    h392.74c32.22,0.04,58.33,26.15,58.37,58.37v350.28C510.4,442.86,484.29,468.97,452.07,469.01z M59.33,33.85    c-14.68,0-26.58,11.9-26.58,26.58l0,0v350.21c0,14.68,11.9,26.58,26.58,26.58c0,0,0,0,0,0h392.74c14.68,0,26.58-11.9,26.58-26.58    v0V60.37c0-14.68-11.9-26.58-26.58-26.58l0,0L59.33,33.85z" />
                </g>
                <g transform="translate(0 4.354)">
                    <path d="M494.51,104.79H16.89C8.1,104.47,1.24,97.07,1.56,88.28c0.31-8.33,6.99-15.01,15.32-15.32    h477.62c8.8,0.01,15.92,7.14,15.92,15.94c-0.01,8.79-7.13,15.91-15.92,15.92V104.79z" />
                </g>
            </g>
        </SVGIcon>
    );
}
