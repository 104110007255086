import React from 'react';

import { SVGIcon } from '../../UIKit/index';

export default function DCHelpCenter(props) {
    return (
        <SVGIcon {...props} viewBox="0 0 42 42" fill="none">
            <g clip-path="url(#clip0_66_640)">
                <path
                    d="M30.8593 40C30.4401 40 30.0179 39.9715 29.5941 39.9136L21.0794 38.7514C20.3631 38.6536 19.6368 38.6536 18.9206 38.7514L10.4058 39.9136C7.54336 40.3043 4.72945 39.3568 2.68648 37.3137C0.643437 35.2707 -0.304141 32.4571 0.0865623 29.5943L1.24883 21.0793C1.34664 20.3631 1.34664 19.6368 1.24883 18.9206L0.0866405 10.4056C-0.304141 7.54286 0.643515 4.72919 2.68656 2.68614C4.72961 0.643095 7.5432 -0.304327 10.4058 0.0862977L18.9205 1.24856C19.6368 1.3463 20.3631 1.3463 21.0793 1.24856L29.5941 0.0862977C32.4567 -0.304327 35.2704 0.643173 37.3134 2.68622C39.3565 4.72927 40.3041 7.54286 39.9134 10.4057L38.7511 18.9206C38.6533 19.6368 38.6533 20.3632 38.7511 21.0793L39.9134 29.5943C40.3041 32.4571 39.3565 35.2708 37.3134 37.3138C35.5728 39.0543 33.2725 40 30.8593 40ZM29.7541 38.7413C32.247 39.0814 34.6976 38.2563 36.4768 36.4771C38.2561 34.6978 39.0813 32.2474 38.741 29.7543L37.5788 21.2393C37.4665 20.417 37.4665 19.5829 37.5788 18.7605L38.741 10.2456C39.0813 7.75247 38.2561 5.30208 36.4768 3.52278C34.6976 1.74356 32.247 0.918329 29.7541 1.25856L21.2394 2.42083C20.417 2.53309 19.583 2.53309 18.7605 2.42083L10.2459 1.25856C7.75289 0.918329 5.30242 1.74356 3.5232 3.52278C1.74391 5.30208 0.918672 7.75247 1.25898 10.2456L2.42125 18.7605C2.53352 19.5829 2.53352 20.4169 2.42125 21.2393L1.25891 29.7543C0.918594 32.2474 1.74383 34.6978 3.52312 36.4771C5.30234 38.2563 7.75281 39.0817 10.2458 38.7413L18.7605 37.579C19.5829 37.4668 20.4169 37.4668 21.2394 37.579L29.7541 38.7413Z"
                    fill="#3D6D93"
                />
                <path
                    d="M33.7892 6.44003C32.8334 5.48425 31.8804 4.34081 30.7934 3.62401C24.1987 -0.725519 15.5351 -0.676534 8.98696 3.77097C7.98071 4.4544 7.24117 5.57753 6.35 6.4687C5.45883 7.35987 4.45469 7.98034 3.77125 8.98667C-0.725702 15.6078 -0.725702 24.3919 3.77125 31.0131C4.45469 32.0193 5.2911 32.4981 6.18227 33.3892C7.07344 34.2804 7.98063 35.5453 8.98696 36.2288C15.5296 40.6725 24.1841 40.7252 30.7767 36.3868C31.8698 35.6675 32.6542 34.6914 33.6148 33.7307C34.5756 32.77 35.6674 31.8698 36.3867 30.7767C40.673 24.2631 40.673 15.7367 36.3867 9.22307C35.6674 8.12995 34.7498 7.40065 33.7892 6.44003ZM29.6159 29.8189C28.8323 30.6025 27.6026 30.6915 26.6925 31.2346C22.3002 33.8556 16.7016 33.6753 12.4752 30.6939C11.8715 30.2679 11.0404 30.0488 10.5002 29.5085C9.96664 28.9749 9.74422 28.1429 9.32219 27.5474C6.12758 23.0391 6.12758 16.9607 9.32219 12.4524C9.74422 11.8568 10.0256 11.109 10.5591 10.5755C11.0926 10.042 11.857 9.74393 12.4526 9.32198C16.6816 6.32511 22.2923 6.13956 26.6925 8.76518C27.6026 9.30823 28.5118 9.70768 29.2954 10.4913C30.079 11.2749 30.6914 12.3971 31.2345 13.3072C33.6683 17.3859 33.6867 22.5046 31.2898 26.5989C30.7366 27.5439 30.4263 29.0085 29.6159 29.8189Z"
                    fill="#EAF6FF"
                />
                <path
                    d="M18.8168 6.9801C15.0884 6.64331 11.2431 7.90127 8.38906 10.7554C3.28344 15.8611 3.28344 24.139 8.38906 29.2447C11.2431 32.0989 15.0884 33.3568 18.8168 33.02C16.5724 32.8173 14.3705 32.0367 12.4527 30.6779C11.8571 30.256 11.174 29.9928 10.6405 29.4593C10.107 28.9258 9.74422 28.1431 9.32227 27.5475C6.12766 23.0392 6.12766 16.9608 9.32227 12.4525C9.7443 11.857 10.0256 11.1092 10.5591 10.5756C11.0927 10.0421 11.8572 9.74416 12.4527 9.32213C14.3705 7.9633 16.5725 7.18284 18.8168 6.9801Z"
                    fill="#D8ECFE"
                />
                <path
                    d="M33.7892 6.44009C32.8285 5.47938 31.8697 4.33235 30.7766 3.61313C27.1781 1.24548 22.9652 0.18657 18.8169 0.435086C23.4373 0.711882 27.9778 2.61024 31.5005 6.13298C39.1466 13.7792 39.1466 26.2207 31.5005 33.867C27.9778 37.3897 23.4373 39.2881 18.8169 39.5649C22.9653 39.8134 27.1781 38.7545 30.7767 36.3868C31.8698 35.6676 32.6543 34.6915 33.615 33.7308C34.5757 32.7701 35.6676 31.8699 36.3869 30.7768C40.6731 24.2631 40.6731 15.7368 36.3869 9.22313C35.6674 8.13001 34.7498 7.40071 33.7892 6.44009Z"
                    fill="#D8ECFE"
                />
                <path
                    d="M3.77258 8.98745L9.32297 12.4528C9.745 11.8573 10.222 11.2887 10.7555 10.7552C11.289 10.2217 11.8576 9.74472 12.4531 9.32269L8.98782 3.77222C7.98149 4.45573 7.02446 5.24167 6.13321 6.13292C5.24196 7.02417 4.45602 7.98105 3.77258 8.98745Z"
                    fill="#F58A97"
                />
                <path
                    d="M8.38915 10.755C8.16024 10.984 7.94212 11.2194 7.73376 11.4604L9.32305 12.4527C9.32329 12.4523 9.3236 12.452 9.32384 12.4516C9.48048 12.2306 9.64462 12.0133 9.81665 11.8001C10.3852 11.107 11.0664 10.3775 12.012 9.64919C12.1572 9.53677 12.3044 9.42794 12.4531 9.32255L11.8099 8.29224C10.5756 8.9063 9.41774 9.72646 8.38915 10.755Z"
                    fill="#F07281"
                />
                <path
                    d="M36.3865 30.7766L31.234 26.6921C30.6909 27.6023 30.0281 28.4608 29.2445 29.2445C28.4609 30.0282 27.6023 30.6909 26.6922 31.234L30.7766 36.3866C31.8697 35.6673 32.9061 34.8275 33.8668 33.8668C34.8275 32.9061 35.6672 31.8697 36.3865 30.7766Z"
                    fill="#F58A97"
                />
                <path
                    d="M34.2889 33.43C34.9138 32.7818 35.7855 31.7011 36.3865 30.7764L34.7867 29.5083C33.6202 31.6278 31.9017 33.7048 29.9096 35.2928L30.7756 36.3852C31.8891 35.6499 33.1463 34.6568 34.2889 33.43Z"
                    fill="#F07281"
                />
                <path
                    d="M8.98782 36.2275L12.4531 30.677C11.8576 30.255 11.289 29.778 10.7555 29.2445C10.222 28.711 9.745 28.1424 9.32297 27.5469L3.77258 31.0123C4.45602 32.0187 5.24204 32.9757 6.13321 33.8669C7.02438 34.758 7.98149 35.544 8.98782 36.2275Z"
                    fill="#F58A97"
                />
                <path
                    d="M11.8099 31.7073L12.4531 30.677C12.3043 30.5716 12.1572 30.4627 12.012 30.3504C11.2731 29.7738 10.5077 29.0625 9.81665 28.1995C9.64462 27.9863 9.48048 27.769 9.32384 27.548C9.3236 27.5476 9.32329 27.5473 9.32305 27.5469L7.73376 28.5391C7.94212 28.7802 8.16032 29.0157 8.38915 29.2445C9.41774 30.2731 10.5756 31.0933 11.8099 31.7073Z"
                    fill="#F07281"
                />
                <path
                    d="M30.7766 3.61304L26.6922 8.76562C27.6023 9.30866 28.4609 9.97147 29.2445 10.7551C30.0281 11.5388 30.6909 12.3973 31.234 13.3075L36.3865 9.22304C35.6672 8.12991 34.8274 7.09351 33.8667 6.1328C32.9061 5.1721 31.8697 4.33241 30.7766 3.61304Z"
                    fill="#F58A97"
                />
                <path
                    d="M33.3578 5.6438C32.6245 4.96794 31.8707 4.3438 30.8776 3.68146C30.8437 3.65888 30.8095 3.63685 30.7756 3.6145L29.9096 4.70692C30.4591 5.14864 30.9907 5.62325 31.5005 6.13294C32.8258 7.45825 33.9209 8.92786 34.7868 10.4914L36.3866 9.22325C36.3865 9.22317 36.3865 9.2231 36.3864 9.22302C36.1171 8.81372 35.8309 8.41239 35.5277 8.01997C34.9574 7.29731 34.3569 6.57403 33.3578 5.6438Z"
                    fill="#F07281"
                />
            </g>
            <defs>
                <clipPath id="clip0_66_640">
                    <rect width="40" height="40" fill="white" />
                </clipPath>
            </defs>
        </SVGIcon>
    );
}
