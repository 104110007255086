import React from 'react';

import { SVGIcon } from '../../UIKit/index';

export default function DCAttachment1(props) {
    return (
        <SVGIcon {...props} viewBox="0 0 512 512">
            <path d="M437.7,284.2c0,0,5.4-6.8,0-14.6l-25.8-25.8c0,0-6.3-4.6-12.7,0L243.2,403.3c0,0-15.5,16.4-40.8,19.5h-20.1  c0,0-53.6-5.5-63.4-65.4c0,0-6.8-36.3,15.2-60.5L328.2,96.9c0,0,23.2-15.8,45-1.8c0,0,28.9,11.6,20.5,51.2c0,0,0,11.6-27.1,35.1  L223.4,328.3c0,0-5.7,5.4-12.8-0.2c0,0-6.9-4.7-1.1-14.7l127.8-130.4c0,0,5.4-6.5,1.3-15l-27.2-27c0,0-6.7-3.4-14.1,2.7L171.7,272.4  c0,0-34.8,34.4-10.7,83.1c0,0,28.1,38.4,65.7,30.4c0,0,23-1.7,38.6-20.4l157.6-162.3c0,0,38.7-38.1,23.8-96.5  c0,0-13.7-69.7-100.7-73.3c0,0-35.7,0-68.5,35.7L97.7,253.1c0,0-63.2,61.5-21.7,153.6c0,0,23.8,55.4,89.6,69.4  c0,0,60.2,13.8,105.5-23.1l19.3-17.3l19.7-20.4l122.4-125.7L437.7,284.2z" />
        </SVGIcon>
    );
}
