import React from 'react';

import { SVGIcon } from '../../UIKit/index';

export default function DCSelectProduct(props) {
    return (
        <SVGIcon {...props} viewBox="0 0 92.951 105.606">
            <g transform="translate(-130 -148.842)">
                <path
                    d="M0,0H65.935V54.946H0Z"
                    transform="translate(151.612 148.842)"
                    fill="#d9eaff"
                />
                <path
                    d="M25,52.125H72.62V10C72.62,33.284,51.321,52.125,25,52.125Z"
                    transform="translate(144.927 151.663)"
                    fill="#6b8aaa"
                />
                <path
                    d="M34.911,64.553h20.33a7.189,7.189,0,0,0,3.663-.989L87.333,47.153a5.506,5.506,0,0,0,2.015-7.509A5.37,5.37,0,0,0,87.058,37.5a5.5,5.5,0,0,0-5.22.128L60.519,49.937a5.154,5.154,0,0,1-1.264,2.015,5.482,5.482,0,0,1-3.883,1.612h0a5.482,5.482,0,0,0,3.883-1.612,5.154,5.154,0,0,0,1.263-2.014,5.084,5.084,0,0,0,.33-2.418,5.412,5.412,0,0,0-1.813-3.571,5.608,5.608,0,0,0-3.681-1.374H44.068A3.663,3.663,0,0,1,41.479,41.5l-.447-.447a7.326,7.326,0,0,0-5.174-2.143H21.782c-.238,0-.476.018-.733.037A7.306,7.306,0,0,0,16.6,41.054l-6.1,6.1L30.647,67.3l1.674-1.674A3.663,3.663,0,0,1,34.911,64.553Z"
                    transform="translate(132.87 174.034)"
                    fill="#d9eaff"
                />
                <path
                    d="M13.566,40.86,3.2,51.208,29.1,77.124,39.464,66.758l-2.747-2.747L16.57,43.864Z"
                    transform="translate(126.8 177.324)"
                    fill="#006df0"
                />
                <rect
                    width="10.989"
                    height="18.315"
                    transform="translate(179.085 148.842)"
                    fill="#006df0"
                />
                <g transform="translate(157.107 183.64)">
                    <path
                        d="M30.821,28H19.832a1.832,1.832,0,0,0,0,3.663H30.821a1.832,1.832,0,1,0,0-3.663Z"
                        transform="translate(-18 -17.01)"
                        fill="#6b8aaa"
                    />
                    <path
                        d="M30.821,25H19.832a1.832,1.832,0,0,0,0,3.663H30.821a1.832,1.832,0,1,0,0-3.663Z"
                        transform="translate(-18 -19.505)"
                        fill="#6b8aaa"
                    />
                    <path
                        d="M30.663,25H28.832a1.832,1.832,0,1,0,0,3.663h1.832a1.832,1.832,0,1,0,0-3.663Z"
                        transform="translate(-10.516 -19.505)"
                        fill="#6b8aaa"
                    />
                    <path
                        d="M30.821,22H19.832a1.832,1.832,0,0,0,0,3.663H30.821a1.832,1.832,0,1,0,0-3.663Z"
                        transform="translate(-18 -22)"
                        fill="#6b8aaa"
                    />
                    <path
                        d="M30.663,22H28.832a1.832,1.832,0,1,0,0,3.663h1.832a1.832,1.832,0,1,0,0-3.663Z"
                        transform="translate(-10.516 -22)"
                        fill="#6b8aaa"
                    />
                    <path
                        d="M30.663,28H28.832a1.832,1.832,0,1,0,0,3.663h1.832a1.832,1.832,0,1,0,0-3.663Z"
                        transform="translate(-10.516 -17.01)"
                        fill="#6b8aaa"
                    />
                </g>
                <path
                    d="M37.87,44h-.055v.037Z"
                    transform="translate(155.583 179.935)"
                    fill="#e1d7cb"
                />
                <path
                    d="M28,47.663H40.821A5.482,5.482,0,0,0,44.7,46.051a5.154,5.154,0,0,0,1.263-2.014V44l-11.735.224Z"
                    transform="translate(147.422 179.935)"
                    fill="#fff"
                />
            </g>
        </SVGIcon>
    );
}
