import React from 'react';

import { SVGIcon } from '../../UIKit/index';

export default function DCLeadFinderMenu(props) {
    return (
        <SVGIcon {...props} viewBox="0 0 512 438">
            <g transform="translate(-11.5 -14)">
                <path
                    d="M479.03,15.12H55.89c-26.46,3.87-45.09,28.03-42.11,54.6V397.4   c-2.98,26.58,15.65,50.74,42.11,54.6h423.14c26.48-3.87,45.12-28.05,42.11-54.65V69.68C524.1,43.12,505.48,18.99,479.03,15.12z"
                    fill="#E6EFFF"
                />
                <g transform="translate(9.405 3.468)">
                    <path
                        d="M243.68,359.79H60.53c-9.48,0-17.17-7.69-17.17-17.17l0,0v-11.44    c0-60.06,48.69-108.74,108.74-108.74c60.06,0,108.74,48.69,108.74,108.74v11.44C260.85,352.1,253.16,359.79,243.68,359.79    C243.68,359.79,243.68,359.79,243.68,359.79z"
                        fill="#2F8DFF"
                    />
                    <path
                        d="M152.11,245.27c-34.77,0-62.96-28.19-62.96-62.95s28.19-62.95,62.95-62.95    c34.77,0,62.96,28.19,62.96,62.95l0,0C215.01,217.07,186.86,245.22,152.11,245.27z"
                        fill="#CED8E5"
                    />
                    <g transform="translate(15.852 18.062)">
                        <g transform="translate(0 0)">
                            <circle cx="303.91" cy="170.51" r="17.74" fill="#2F8DFF" />
                            <circle cx="357.1" cy="170.51" r="17.74" fill="#2F8DFF" />
                            <path
                                d="M418.87,250.1h-126.1c-9.48-2.32-15.28-11.89-12.96-21.37c1.57-6.4,6.56-11.39,12.96-12.96      h126.1c9.48,2.32,15.28,11.89,12.96,21.37C430.27,243.54,425.27,248.54,418.87,250.1z"
                                fill="#2F8DFF"
                            />
                            <path
                                d="M418.87,318.81h-126.1c-9.49-2.3-15.31-11.85-13.02-21.34c1.56-6.43,6.58-11.46,13.02-13.02      h126.1c9.49,2.3,15.31,11.85,13.02,21.34C430.33,312.23,425.31,317.25,418.87,318.81z"
                                fill="#2F8DFF"
                            />
                        </g>
                        <circle cx="410.27" cy="170.51" r="17.74" fill="#2F8DFF" />
                    </g>
                </g>
            </g>
        </SVGIcon>
    );
}
