import React from 'react';

import { SVGIcon } from '../../UIKit/index';

export default function DCSSL(props) {
    return (
        <SVGIcon {...props} viewBox="0 0 23.225 20.115">
            <g transform="translate(0 -34.281)">
                <path
                    d="M18.82,51.9h2.8a1.6,1.6,0,0,0,1.6-1.6V35.883a1.6,1.6,0,0,0-1.6-1.6H1.6a1.6,1.6,0,0,0-1.6,1.6V50.3a1.6,1.6,0,0,0,1.6,1.6H14.816"
                    transform="translate(0 0)"
                    fill="#4fba6f"
                />
                <path
                    d="M330.624,343.247v5.7a.4.4,0,0,1-.606.343l-1.19-.714a.4.4,0,0,0-.412,0l-1.19.714a.4.4,0,0,1-.606-.343v-5.7Z"
                    transform="translate(-311.804 -294.951)"
                    fill="#c03a2b"
                />
                <g transform="translate(1.201 35.482)">
                    <path
                        d="M28.651,61.8a.8.8,0,0,1,.234.566.4.4,0,0,0,.8,0,1.6,1.6,0,1,0-1.6,1.6.8.8,0,1,1-.8.8.4.4,0,1,0-.8,0,1.6,1.6,0,1,0,1.6-1.6.8.8,0,0,1-.566-1.367A.821.821,0,0,1,28.651,61.8Z"
                        transform="translate(-26.483 -60.764)"
                        fill="#ecf0f1"
                    />
                    <path
                        d="M116.927,61.8a.8.8,0,0,1,.234.566.4.4,0,0,0,.8,0,1.6,1.6,0,1,0-1.6,1.6.8.8,0,1,1-.8.8.4.4,0,0,0-.8,0,1.6,1.6,0,1,0,1.6-1.6.8.8,0,0,1-.566-1.367A.821.821,0,0,1,116.927,61.8Z"
                        transform="translate(-110.755 -60.764)"
                        fill="#ecf0f1"
                    />
                    <path
                        d="M205.837,65.568h-2v-4.4a.4.4,0,0,0-.8,0v4.4a.8.8,0,0,0,.8.8h2a.4.4,0,1,0,0-.8Z"
                        transform="translate(-195.025 -60.763)"
                        fill="#ecf0f1"
                    />
                </g>
                <g transform="translate(1.201 41.889)">
                    <path
                        d="M26.882,202.8h1.2a.4.4,0,1,0,0-.8h-1.2a.4.4,0,1,0,0,.8Z"
                        transform="translate(-26.482 -202.004)"
                        fill="#4c8056"
                    />
                    <path
                        d="M88.675,202.8h6.006a.4.4,0,0,0,0-.8H88.675a.4.4,0,1,0,0,.8Z"
                        transform="translate(-85.472 -202.004)"
                        fill="#4c8056"
                    />
                    <path
                        d="M195.808,246.143h-1.2a.4.4,0,1,0,0,.8h1.2a.4.4,0,0,0,0-.8Z"
                        transform="translate(-186.598 -244.141)"
                        fill="#4c8056"
                    />
                    <path
                        d="M26.882,246.943h6.006a.4.4,0,0,0,0-.8H26.882a.4.4,0,0,0,0,.8Z"
                        transform="translate(-26.482 -244.14)"
                        fill="#4c8056"
                    />
                    <path
                        d="M35.692,290.281H26.882a.4.4,0,1,0,0,.8h8.809a.4.4,0,1,0,0-.8Z"
                        transform="translate(-26.482 -286.277)"
                        fill="#4c8056"
                    />
                    <path
                        d="M36.311,343.247h6.807a1,1,0,0,1,1,1h0a1,1,0,0,1-1,1H36.311a1,1,0,0,1-1-1h0A1,1,0,0,1,36.311,343.247Z"
                        transform="translate(-34.91 -336.84)"
                        fill="#4c8056"
                    />
                </g>
                <path
                    d="M273.448,170.856l1.094-.354a.8.8,0,0,1,.961.4l.523,1.024a.8.8,0,0,0,.349.349l1.024.523a.8.8,0,0,1,.4.961l-.354,1.094a.8.8,0,0,0,0,.493l.354,1.094a.8.8,0,0,1-.4.961l-1.024.523a.8.8,0,0,0-.349.349L275.5,179.3a.8.8,0,0,1-.961.4l-1.094-.354a.8.8,0,0,0-.493,0l-1.094.354a.8.8,0,0,1-.961-.4l-.523-1.024a.8.8,0,0,0-.349-.349L269,177.4a.8.8,0,0,1-.4-.961l.354-1.094a.8.8,0,0,0,0-.493l-.354-1.094a.8.8,0,0,1,.4-.961l1.024-.523a.8.8,0,0,0,.349-.349l.523-1.024a.8.8,0,0,1,.961-.4l1.094.354A.8.8,0,0,0,273.448,170.856Z"
                    transform="translate(-256.383 -130.004)"
                    fill="#e64c3c"
                />
                <circle
                    cx="2.803"
                    cy="2.803"
                    r="2.803"
                    transform="translate(14.015 42.29)"
                    fill="#f0c419"
                />
            </g>
        </SVGIcon>
    );
}
