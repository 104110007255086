import React from 'react';

import { SVGIcon } from '../../UIKit/index';

export default function DCDetailsCard1(props) {
    return (
        <SVGIcon {...props} viewBox="0 0 512 512">
            <path d="M452.26,427.01H59.13c-32.27-0.02-58.42-26.18-58.44-58.44V102.96  c0.02-32.27,26.18-58.42,58.44-58.44h127.49c8.8,0,15.94,7.14,15.94,15.94c0,8.8-7.14,15.94-15.94,15.94H59.13  c-14.66,0.02-26.53,11.9-26.56,26.56v265.61c0.01,14.67,11.89,26.56,26.56,26.58h393.13c14.66-0.02,26.53-11.9,26.56-26.56V102.96  c-0.02-14.66-11.9-26.53-26.56-26.56H324.77c-8.8,0-15.94-7.14-15.94-15.94c0-8.8,7.14-15.94,15.94-15.94h127.49  c32.27,0.02,58.42,26.18,58.44,58.44v265.61C510.68,400.84,484.53,426.99,452.26,427.01z" />
            <path d="M303.51,118.89h-95.63c-20.53-0.02-37.16-16.66-37.19-37.19V39.19  C170.72,18.66,187.35,2.02,207.88,2h95.63c20.51,0.05,37.12,16.68,37.14,37.19V81.7C340.63,102.21,324.02,118.84,303.51,118.89z   M207.88,33.88c-2.93,0-5.3,2.37-5.3,5.3c0,0,0,0,0,0V81.7c0,2.93,2.37,5.3,5.3,5.3l0,0h95.63c2.93,0,5.3-2.37,5.3-5.3c0,0,0,0,0,0  V39.19c0-2.93-2.37-5.3-5.3-5.3l0,0H207.88z" />
            <path d="M160.07,257.01c-29.34,0-53.12-23.78-53.12-53.12s23.78-53.12,53.12-53.12  c29.34,0,53.12,23.78,53.12,53.12C213.13,233.2,189.38,256.96,160.07,257.01z M160.07,182.54c-11.74,0-21.26,9.52-21.26,21.26  c0,11.74,9.52,21.26,21.26,21.26c11.74,0,21.26-9.52,21.26-21.26c-0.06-11.7-9.56-21.15-21.26-21.17V182.54z" />
            <path d="M239.74,363.29c-8.79-0.01-15.92-7.14-15.93-15.93v-10.63c-0.02-14.66-11.9-26.53-26.56-26.56  h-74.46c-14.66,0.02-26.53,11.9-26.56,26.56v10.63c0,8.8-7.14,15.94-15.94,15.94s-15.94-7.14-15.94-15.94v-10.63  c0.02-32.27,26.18-58.42,58.44-58.44h74.46c32.27,0.02,58.42,26.18,58.44,58.44v10.63C255.68,356.16,248.55,363.29,239.74,363.29z" />
            <path d="M431.03,278.26H314.14c-8.8,0-15.94-7.14-15.94-15.94c0-8.8,7.14-15.94,15.94-15.94h116.89  c8.8,0,15.94,7.14,15.94,15.94C446.97,271.13,439.83,278.26,431.03,278.26z" />
        </SVGIcon>
    );
}
