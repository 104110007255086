import { CallAPI } from 'Utils/apiCall';
import { GlobalActions as Actions } from '../Actions';
import { GlobalStore as initState } from '../Store';
import config from 'config';

const callApi = new CallAPI();
const mainURL = `${config.REACT_APP_API_BASE_URL}/${process.env.REACT_APP_API_VERSION}`;
export const apiCalls = {
    [Actions.CALL_GET_CARD_API]: payload => {
        return callApi.request(
            'GET',
            `${mainURL}/${process.env.REACT_APP_API_CRM}/${process.env.REACT_APP_API_CONTACTS}/${payload.type}/${payload.id}?${process.env.REACT_APP_API_EXPAND}=true`,
        );
    },
    [Actions.CALL_ADD_CONTACT_API]: payload => {
        callApi.cancelAllRequests();
        return callApi.request(
            'POST',
            `${mainURL}/${process.env.REACT_APP_API_CRM}/${process.env.REACT_APP_API_CONTACTS}/${payload.type}`,
            payload.data,
        );
    },
    [Actions.CALL_GET_DEAL_API]: payload => {
        return callApi.request(
            'GET',
            `${mainURL}/${process.env.REACT_APP_API_CRM}/${process.env.REACT_APP_API_PIPELINES}/${payload.pipelineId}/${process.env.REACT_APP_API_DEALS}/${payload.dealID}?${process.env.REACT_APP_API_EXPAND}=true`,
        );
    },
    [Actions.CALL_ADD_DEAL_API]: payload => {
        callApi.cancelAllRequests();
        return callApi.request(
            'POST',
            `${mainURL}/${process.env.REACT_APP_API_CRM}/${process.env.REACT_APP_API_PIPELINES}/${payload.pipelineId}/${process.env.REACT_APP_API_DEALS}`,
            payload.data,
        );
    },
};

export default function GlobalReducer(state, action) {
    switch (action.type) {
        case Actions.LOADING:
            return { ...state, loading: action.payload };
        case Actions.ADD_CONTACT_API:
            return { ...state, apiResponse: action.payload };
        case Actions.ERROR:
            return { ...state, error: action.payload };
        case Actions.CALL_GET_CARD_API:
            return { ...state, apiData: action.payload };
        case Actions.CLEANUP:
            return initState;
        case Actions.CALL_ADD_CONTACT_API:
            return { ...state, apiResponse: action.payload };
        case Actions.CALL_GET_DEAL_API:
            return { ...state, apiData: action.payload };
        case Actions.CALL_ADD_DEAL_API:
            return { ...state, apiResponse: action.payload };
        case Actions.PRIMARY_COLOR:
            return { ...state, primaryColor: action.payload };
        case Actions.SECONDARY_COLOR:
            return { ...state, secondaryColor: action.payload };
        default:
            throw new Error(JSON.stringify(action));
    }
}
