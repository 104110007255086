import React from 'react';

import { SVGIcon } from '../../UIKit/index';

export default function DCImage(props) {
    return (
        <SVGIcon {...props} viewBox="0 0 512 366">
            <path d="M456.41,1H54.99C24.76,1,0.26,25.5,0.26,55.73c0,0,0,0,0,0v255.44     c-0.06,30.23,24.39,54.78,54.62,54.84c0.04,0,0.07,0,0.11,0h401.42c30.23,0,54.73-24.5,54.73-54.73c0-0.04,0-0.07,0-0.11V55.73     C511.14,25.5,486.63,1,456.41,1C456.41,1,456.41,1,456.41,1z M474.64,311.17c0,10.07-8.16,18.24-18.24,18.24H54.99     c-10.07,0-18.24-8.16-18.24-18.24v-10.68l72.99-72.99l59.97,59.97c7.12,7.12,18.67,7.12,25.79,0l151.31-151.31l127.72,127.72     v47.18L474.64,311.17z M474.64,212.39L359.83,97.58c-7.12-7.12-18.67-7.12-25.79,0L182.71,248.89l-59.97-59.97     c-7.12-7.12-18.67-7.12-25.79,0l-59.97,59.97V55.64c0-10.07,8.16-18.24,18.24-18.24h401.19c10.07,0,18.24,8.16,18.24,18.24V212.3     V212.39z" />
            <path d="M159.54,70.7c-30.23,0.01-54.72,24.53-54.71,54.75c0.01,30.23,24.53,54.72,54.75,54.71     c30.19-0.01,54.67-24.47,54.71-54.66c0.04-30.23-24.44-54.76-54.66-54.8C159.6,70.7,159.57,70.7,159.54,70.7z M159.54,143.69     c-10.07,0-18.24-8.16-18.24-18.24s8.16-18.24,18.24-18.24c10.07,0,18.24,8.16,18.24,18.24l0,0     C177.78,135.53,169.62,143.69,159.54,143.69z" />
        </SVGIcon>
    );
}
