import React, { memo, useEffect, useRef, useState } from 'react';

import { FixedSizeList as List, areEqual } from 'react-window';
import NoData from 'UIKit/NoData/noData';
import DCNoResultFound from '../../Assets/icons/DCNoResultFound';
import { Div, Popover } from '../index';
import PropTypes from 'prop-types';
import './style.scss';

const Row = memo(props => {
    const { data, index, style } = props;
    return <Div style={style}>{data.data[index]['label']}</Div>;
}, areEqual);

export default function DropdownOptions({
    customClass,
    options,
    wrapperWidth,
    minWidth,
    onClose,
    open,
    reference,
    itemSize,
    viewHeight,
    defaultPlacement,
    noDataMessage,
    header,
    selectedItemIndex,
    noDataClassName,
    // listRef,
}) {
    const [addPlacement, setAddPlacement] = useState(defaultPlacement || 'bottom-start');
    useEffect(() => {
        if (defaultPlacement) setAddPlacement(defaultPlacement);
    }, [defaultPlacement]);

    // To scroll to the selected item in dropdown
    const listReff = useRef();
    useEffect(() => {
        if (selectedItemIndex > 0) listReff.current?.scrollToItem(selectedItemIndex, 'start');
    }, [open, selectedItemIndex]);

    return (
        <Popover
            customClass={customClass}
            open={open}
            anchorEl={reference}
            onClose={onClose}
            width={wrapperWidth}
            minWidth={minWidth}
            withOutPadding
            placement={addPlacement}
            maxHeight={350}
        >
            {header}
            {options && options.length > 0 ? (
                <List
                    ref={listReff}
                    className={'DCDSOptionList'}
                    height={viewHeight}
                    itemSize={itemSize}
                    itemCount={options.length}
                    itemData={{ data: options }}
                >
                    {Row}
                </List>
            ) : (
                <Div className={'DCDSOptionList'}>
                    <NoData
                        title={noDataMessage ? noDataMessage : 'No Options'}
                        icon={<DCNoResultFound />}
                        type={'small'}
                        className={noDataClassName}
                    />
                </Div>
            )}
        </Popover>
    );
}

DropdownOptions.propTypes = {
    customClass: PropTypes.string,
    options: PropTypes.any.isRequired,
    wrapperWidth: PropTypes.number,
    minWidth: PropTypes.number,
    onClose: PropTypes.func,
    open: PropTypes.bool,
    reference: PropTypes.object,
    itemSize: PropTypes.number,
    viewHeight: PropTypes.number,
    defaultPlacement: PropTypes.string,
    noDataMessage: PropTypes.string,
    header: PropTypes.string,
    selectedItemIndex: PropTypes.number,
    noDataClassName: PropTypes.string,
    onClick: PropTypes.func,
};
