import React from 'react';

import { SVGIcon } from '../../UIKit/index';

export default function DCNewFeature(props) {
    return (
        <SVGIcon {...props} viewBox="0 0 42 42" fill="none">
            <g clip-path="url(#clip0_66_513)">
                <path
                    d="M30.252 39.2188V30.383C30.252 28.3598 28.9538 26.5649 27.0323 25.9314L20.6617 23.8311C20.2464 23.6943 19.9185 23.3946 19.7392 23.0169C19.642 22.8121 18.4167 22.5844 18.4167 22.3472V17.4619H14.2763V22.3472C14.2763 22.5844 13.2018 23.1742 13.1045 23.379C12.9252 23.7566 12.4466 23.6942 12.0313 23.8311L5.6607 25.9314C3.73922 26.5648 2.44102 28.3597 2.44102 30.383V39.2188C2.44102 39.6503 2.79078 40 3.22227 40H13.876L16.3465 38.8282L18.817 40H29.4708C29.9023 40 30.252 39.6503 30.252 39.2188Z"
                    fill="#3D6D93"
                />
                <path
                    d="M21.7248 24.1816H10.9681L13.8759 40H18.817L21.7248 24.1816Z"
                    fill="#EAF6FF"
                />
                <path
                    d="M27.0323 25.9314L21.7248 24.1816H20.5962C20.1809 24.0447 18.7466 23.3946 18.5673 23.0169C18.4702 22.8121 18.4166 22.5844 18.4166 22.3472V17.4619L17.2448 18.6337V23.519C17.2448 24.1934 17.6776 24.7918 18.318 25.0029L24.6887 27.1031C26.6102 27.7365 27.9084 29.5314 27.9084 31.5547V40H29.4708C29.9023 40 30.252 39.6502 30.252 39.2187V30.3829C30.252 28.3597 28.9538 26.5648 27.0323 25.9314Z"
                    fill="#335E80"
                />
                <path
                    d="M20.9963 39.4141L25.2334 32.1484C25.3452 31.9257 25.2924 31.6556 25.105 31.4916L23.8227 30.3688C23.6432 30.2116 23.5863 29.9561 23.6823 29.7376L24.4895 27.8985C24.5737 27.7066 24.5408 27.4834 24.4048 27.3238L22.2163 24.3437L21.7248 24.1816L18.817 40H20.6431L20.9963 39.4141Z"
                    fill="#335E80"
                />
                <path
                    d="M11.6966 39.414L7.45946 32.1483C7.34766 31.9256 7.40047 31.6555 7.58789 31.4915L8.87016 30.3687C9.04969 30.2115 9.10656 29.956 9.01063 29.7375L8.20344 27.8984C8.11922 27.7065 8.15211 27.4833 8.28813 27.3237L10.4766 24.3437L10.9682 24.1816L13.8759 39.9999H12.0498L11.6966 39.414Z"
                    fill="#335E80"
                />
                <path
                    d="M16.3465 34.7661C16.6701 34.7661 16.9324 34.5038 16.9324 34.1802C16.9324 33.8566 16.6701 33.5942 16.3465 33.5942C16.0229 33.5942 15.7606 33.8566 15.7606 34.1802C15.7606 34.5038 16.0229 34.7661 16.3465 34.7661Z"
                    fill="#3D6D93"
                />
                <path
                    d="M16.3465 31.2505C16.6701 31.2505 16.9324 30.9882 16.9324 30.6646C16.9324 30.3409 16.6701 30.0786 16.3465 30.0786C16.0229 30.0786 15.7606 30.3409 15.7606 30.6646C15.7606 30.9882 16.0229 31.2505 16.3465 31.2505Z"
                    fill="#3D6D93"
                />
                <path
                    d="M16.3465 38.2812C16.6701 38.2812 16.9324 38.0189 16.9324 37.6953C16.9324 37.3717 16.6701 37.1094 16.3465 37.1094C16.0229 37.1094 15.7606 37.3717 15.7606 37.6953C15.7606 38.0189 16.0229 38.2812 16.3465 38.2812Z"
                    fill="#3D6D93"
                />
                <path
                    d="M24.0562 12.817H23.2128V9.57568H24.0562C24.9513 9.57568 25.6768 10.3013 25.6768 11.1963C25.6768 12.0914 24.9512 12.817 24.0562 12.817Z"
                    fill="#FFCEBF"
                />
                <path
                    d="M8.63672 12.817H9.48008V9.57568H8.63672C7.74165 9.57568 7.0161 10.3013 7.0161 11.1963C7.0161 12.0914 7.74172 12.817 8.63672 12.817Z"
                    fill="#FFB09E"
                />
                <path
                    d="M20.6617 23.8311C20.0213 23.62 18.4166 23.0217 18.4166 22.3472V17.4619H13.1044V22.3472C13.1044 23.0217 12.6716 23.62 12.0312 23.8311L16.3463 26.4103L20.6617 23.8311Z"
                    fill="#FFCEBF"
                />
                <path
                    d="M17.2448 23.519C17.2448 24.1934 17.6776 24.7918 18.318 25.0029L18.5649 25.0843L20.6617 23.8311C20.0964 23.6482 19.5854 23.0685 19.5885 22.3472V17.4619L17.2448 18.6337V23.519Z"
                    fill="#FFB09E"
                />
                <path
                    d="M19.1563 28.3449L22.2163 24.3438L20.6617 23.8313C20.2464 23.6944 19.9195 23.3943 19.7402 23.0166L16.3463 26.4104L18.3352 28.3993C18.5691 28.6332 18.9555 28.6076 19.1563 28.3449Z"
                    fill="#3D6D93"
                />
                <path
                    d="M19.7394 23.0171L17.9395 24.8169C18.0551 24.8942 18.1815 24.9577 18.3181 25.0028L21.0286 25.8964L22.2163 24.3435L20.6617 23.831C20.2466 23.6941 19.9187 23.3946 19.7394 23.0171Z"
                    fill="#355F7C"
                />
                <path
                    d="M21.3877 26.015L24.1004 26.9092L22.2163 24.3437L21.7248 24.1816L21.3877 26.015Z"
                    fill="#2D5870"
                />
                <path
                    d="M13.5364 28.3448C13.7373 28.6075 14.1236 28.6331 14.3575 28.3993L16.3464 26.4104L12.9527 23.0166C12.7734 23.3942 12.4464 23.6943 12.0312 23.8312L10.4765 24.3437L13.5364 28.3448Z"
                    fill="#3D6D93"
                />
                <path
                    d="M9.48016 7.13525V14.6928C9.48016 17.2999 10.9332 19.5677 13.0736 20.7304C14.0465 21.2589 16.3464 21.2732 16.3464 21.2732C17.5315 21.2732 18.6465 21.2589 19.6194 20.7304C21.7598 19.5677 23.2128 17.2999 23.2128 14.6928V7.13525H9.48016Z"
                    fill="#FFCEBF"
                />
                <path
                    d="M20.8691 7.13525V14.6928C20.8857 15.1461 20.9205 17.376 19.2346 19.1397C17.7079 20.7368 15.3515 21.3435 13.1045 20.747C13.7736 21.0958 14.6677 21.438 15.7474 21.5331C15.9549 21.5514 16.1548 21.5593 16.3465 21.5592C20.1387 21.5592 23.2128 18.485 23.2128 14.6928V7.13525H20.8691Z"
                    fill="#FFB09E"
                />
                <path
                    d="M11.8238 1.36914H20.8691C22.1635 1.36914 23.2128 2.41844 23.2128 3.71281V9.47898C22.718 9.49461 21.5357 9.46391 20.3261 8.72594C19.7429 8.37008 19.3205 7.9518 19.0271 7.59453C18.8251 7.34844 18.4713 7.29391 18.2048 7.46805C17.4759 7.94414 16.5476 8.44711 15.4213 8.8432C12.9382 9.71656 10.7435 9.63086 9.48008 9.47898V3.71273C9.48016 2.41836 10.5294 1.36914 11.8238 1.36914Z"
                    fill="#E19974"
                />
                <path
                    d="M20.8691 1.36914H18.5255C19.8198 1.36914 20.8691 2.41844 20.8691 3.71281V9.01219C21.8764 9.47031 22.7927 9.49219 23.2128 9.47891V3.71273C23.2127 2.41836 22.1635 1.36914 20.8691 1.36914Z"
                    fill="#DC8758"
                />
                <path
                    d="M13.0336 14.2216C12.71 14.2216 12.4477 13.9593 12.4477 13.6357V12.3105C12.4477 11.987 12.71 11.7246 13.0336 11.7246C13.3572 11.7246 13.6195 11.987 13.6195 12.3105V13.6357C13.6195 13.9594 13.3572 14.2216 13.0336 14.2216Z"
                    fill="#3D6D93"
                />
                <path
                    d="M19.6593 14.2216C19.3357 14.2216 19.0734 13.9593 19.0734 13.6357V12.3105C19.0734 11.987 19.3357 11.7246 19.6593 11.7246C19.9829 11.7246 20.2452 11.987 20.2452 12.3105V13.6357C20.2452 13.9594 19.9829 14.2216 19.6593 14.2216Z"
                    fill="#3D6D93"
                />
                <path
                    d="M16.3116 19.1154C15.6198 19.1081 15.0749 18.8873 14.7396 18.7033C14.4559 18.5477 14.352 18.1916 14.5077 17.9079C14.6632 17.6241 15.0194 17.5201 15.3031 17.6759C15.521 17.7954 15.875 17.9389 16.3239 17.9436C16.7941 17.9474 17.1623 17.8007 17.39 17.6758C17.6738 17.5203 18.0298 17.6242 18.1855 17.9079C18.3411 18.1916 18.2372 18.5477 17.9534 18.7033C17.607 18.8932 17.0026 19.1226 16.3116 19.1154Z"
                    fill="#FFB09E"
                />
                <path
                    d="M35.7486 8.33305C35.7486 5.9536 33.7734 4.03547 31.3733 4.12875C29.1888 4.21367 27.4129 5.99664 27.3361 8.18149C27.2856 9.61891 27.9566 10.9016 29.0151 11.6972C29.5609 12.1074 29.9113 12.7222 29.9641 13.3958C29.9688 13.4548 30.1505 13.4252 30.1505 13.4849V15.0738C30.1505 15.3879 30.4052 15.6426 30.7193 15.6426L32.3809 15.5578C32.695 15.5578 32.9019 15.477 32.9019 15.1629V13.5741C32.9019 13.5142 33.1133 13.4548 33.118 13.3958C33.1709 12.7221 33.5215 12.1073 34.0673 11.697C35.0881 10.9295 35.7486 9.70867 35.7486 8.33305Z"
                    fill="#FFE179"
                />
                <path
                    d="M33.1179 13.3957C33.1708 12.722 33.5214 12.1072 34.0673 11.6969C35.0881 10.9294 35.7486 9.7086 35.7486 8.33305C35.7486 5.9536 33.7734 4.03547 31.3733 4.12875C31.027 4.14219 30.6912 4.1986 30.3706 4.29219C32.1233 4.80024 33.405 6.41656 33.405 8.33305C33.405 9.70867 32.7445 10.9295 31.7237 11.6969C31.1772 12.1077 30.8265 12.7234 30.7741 13.398L33.1179 13.3957Z"
                    fill="#FBD268"
                />
                <path
                    d="M33.1184 13.396H29.9638L29.9634 13.3967C29.968 13.4555 29.9712 13.5147 29.9712 13.5743V15.1631C29.9712 15.4772 30.2259 15.7319 30.54 15.7319H32.5422C32.8563 15.7319 33.111 15.4772 33.111 15.1631V13.5743C33.111 13.5152 33.1141 13.4566 33.1187 13.3983L33.1184 13.396Z"
                    fill="#3D6D93"
                />
                <path
                    d="M33.1184 13.396H30.7747L30.7749 13.3983C30.7704 13.4566 30.7673 13.5152 30.7673 13.5743V15.1631C30.7673 15.4178 30.5999 15.6333 30.3691 15.7058C30.423 15.7227 30.4804 15.7319 30.5398 15.7319H32.542C32.8562 15.7319 33.1109 15.4772 33.1109 15.1631V13.5743C33.1109 13.5152 33.114 13.4566 33.1185 13.3983L33.1184 13.396Z"
                    fill="#335E80"
                />
                <path
                    d="M31.541 2.63445C31.2174 2.63445 30.9551 2.37211 30.9551 2.04852V0.585938C30.9551 0.262344 31.2174 0 31.541 0C31.8646 0 32.127 0.262344 32.127 0.585938V2.04852C32.127 2.37211 31.8646 2.63445 31.541 2.63445Z"
                    fill="#FFE179"
                />
                <path
                    d="M35.4224 3.92817C35.2513 3.65356 35.3351 3.29215 35.6097 3.12098L36.6632 2.46426C36.9377 2.29309 37.2992 2.37692 37.4704 2.65153C37.6416 2.92614 37.5577 3.28754 37.2831 3.45871L36.2296 4.11543C35.9583 4.28481 35.5949 4.20489 35.4224 3.92817Z"
                    fill="#FFE179"
                />
                <path
                    d="M26.8524 4.11541L25.7989 3.4587C25.5243 3.28752 25.4405 2.92612 25.6116 2.65151C25.7828 2.37698 26.1442 2.29307 26.4188 2.46424L27.4723 3.12096C27.747 3.29213 27.8308 3.65354 27.6596 3.92815C27.4869 4.20526 27.1231 4.2844 26.8524 4.11541Z"
                    fill="#FFE179"
                />
                <path
                    d="M7.36688 40.0001C7.04328 40.0001 6.78094 39.7377 6.78094 39.4141L6.78086 36.1792C6.78086 35.8556 7.0432 35.5933 7.3668 35.5933C7.69039 35.5933 7.95274 35.8556 7.95274 36.1792L7.95281 39.4141C7.95281 39.7377 7.69047 40.0001 7.36688 40.0001Z"
                    fill="#335E80"
                />
                <path
                    d="M25.326 40.0001C25.0024 40.0001 24.7401 39.7377 24.7401 39.4141L24.74 36.1792C24.74 35.8556 25.0023 35.5933 25.3259 35.5933C25.6495 35.5933 25.9119 35.8556 25.9119 36.1792L25.912 39.4141C25.912 39.7377 25.6496 40.0001 25.326 40.0001Z"
                    fill="#335E80"
                />
            </g>
            <defs>
                <clipPath id="clip0_66_513">
                    <rect width="40" height="40" fill="white" />
                </clipPath>
            </defs>
        </SVGIcon>
    );
}
