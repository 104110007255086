import React from 'react';

import { SVGIcon } from '../../UIKit/index';

export default function DCVoiceDrop(props) {
    return (
        <SVGIcon {...props} viewBox="0 0 512 512">
            <g transform="translate(-0.306 -0.306)">
                <g transform="translate(0.34 0.34)">
                    <circle fill="#D9EAFF" cx="255.65" cy="255.47" r="255.51" />
                </g>
                <g transform="translate(7.615 3.84)">
                    <path fill="#005EFF" d="M193.41,375.04h109.91v66.61H193.41V375.04z" />
                    <path fill="#005eff" d="M248.38,375.04h54.95v66.61h-54.95V375.04z" />
                    <g transform="translate(2.695 21.383)">
                        <path fill="#6B8AAB" d="M84.85,210.88h321.67v32.47H84.85V210.88z" />
                    </g>
                    <g transform="translate(19.691 21.383)">
                        <g>
                            <path fill="#6B8AAB" d="M228.69,210.88h160.87v32.47H228.69V210.88z" />
                        </g>
                    </g>
                    <g transform="translate(10.959 42.97)">
                        <path fill="#005EFF" d="M154.78,393.57h165.23v32.47H154.78V393.57z" />
                    </g>
                    <g transform="translate(19.691 42.97)">
                        <path fill="#005eff" d="M228.69,393.57h82.63v32.47h-82.63V393.57z" />
                    </g>
                    <g transform="translate(8.873)">
                        <path
                            fill="#FFFFFF"
                            d="M243.92,325.41h-8.84c-54.03-0.16-97.8-43.92-97.95-97.95V95.49     c0.11-36.17,29.41-65.47,65.58-65.58h73.58c36.17,0.11,65.47,29.41,65.58,65.58v131.95     C341.71,281.47,297.95,325.24,243.92,325.41z"
                        />
                        <path
                            fill="#FFFFFF"
                            d="M341.88,95.5v131.95c0,1.61,0,3.22-0.12,4.81c-0.54,11.16-3.01,22.15-7.3,32.47     c-15.18,36.63-50.88,60.56-90.53,60.67h-4.42V29.92h36.78C312.46,30.02,341.77,59.32,341.88,95.5z"
                        />
                        <g transform="translate(1.625 8.757)">
                            <g transform="translate(0 0)">
                                <path
                                    fill="#6b8aab"
                                    d="M166.9,101.26h37.85c7.84,0,14.19,6.36,14.19,14.19l0,0c0,7.84-6.36,14.19-14.19,14.19       H166.9c-7.84,0-14.19-6.36-14.19-14.19l0,0C152.7,107.61,159.06,101.26,166.9,101.26z"
                                />
                            </g>
                            <g transform="translate(0 7.354)">
                                <path
                                    fill="#6b8aab"
                                    d="M162.16,169.61h47.32c5.23,0,9.46,4.24,9.46,9.46l0,0c0,5.23-4.24,9.46-9.46,9.46       h-47.32c-5.23,0-9.46-4.24-9.46-9.46l0,0C152.7,173.85,156.94,169.61,162.16,169.61z"
                                />
                            </g>
                        </g>
                        <g transform="translate(12.657 8.757)">
                            <g transform="translate(0 0)">
                                <path
                                    fill="#6b8aab"
                                    d="M259.97,101.26h37.85c7.84,0,14.19,6.36,14.19,14.19l0,0       c0,7.84-6.36,14.19-14.19,14.19h-37.85c-7.84,0-14.19-6.36-14.19-14.19l0,0C245.77,107.61,252.13,101.26,259.97,101.26z"
                                />
                            </g>
                            <g transform="translate(0 7.354)">
                                <path
                                    fill="#6b8aab"
                                    d="M255.24,169.61h47.32c5.23,0,9.46,4.24,9.46,9.46l0,0c0,5.23-4.24,9.46-9.46,9.46       h-47.32c-5.23,0-9.46-4.24-9.46-9.46l0,0C245.77,173.85,250.01,169.61,255.24,169.61z"
                                />
                            </g>
                        </g>
                    </g>
                    <g transform="translate(0 15.794)">
                        <path
                            fill="#005EFF"
                            d="M258.42,376.83h-20.08c-97.32-0.11-176.19-78.98-176.3-176.3v-36.95h35.16v36.95     c0.08,77.92,63.23,141.06,141.14,141.14h20.08c77.91-0.09,141.05-63.23,141.13-141.14v-36.95h35.16v36.95     C434.6,297.85,355.73,376.71,258.42,376.83z"
                        />
                    </g>
                    <g transform="translate(19.691 15.793)">
                        <path
                            fill="#005EFF"
                            d="M415.02,163.57v36.95c0.05,97.31-78.79,176.25-176.11,176.3c-0.06,0-0.12,0-0.18,0h-10.05     v-35.16h10.04c77.9,0.04,141.09-63.08,141.13-140.98c0-0.05,0-0.11,0-0.16v-36.95H415.02z"
                        />
                    </g>
                </g>
            </g>
        </SVGIcon>
    );
}
