import React from 'react';

import { SVGIcon } from '../../UIKit/index';

export default function DCThunderbolt(props) {
    return (
        <SVGIcon
            {...props}
            height="511pt"
            viewBox="-2 0 511 511.99989"
            width="511pt"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path d="m507.855469 14.847656c-.242188-3.410156-1.640625-6.605468-3.914063-9.078125-4.203125-5.394531-11.714844-7.371093-18.164062-4.359375l-.300782.140625c-.21875.101563-.4375.210938-.652343.324219l-373.050781 182.859375c-4.738282 2.320313-7.90625 6.960937-8.347657 12.21875-.4375 5.257813 1.917969 10.359375 6.203125 13.4375l103.65625 74.378906-208.214844 201.449219c-5.394531 5.21875-6.101562 13.625-1.652343 19.667969 2.914062 3.964843 7.453125 6.113281 12.089843 6.113281 2.441407 0 4.910157-.597656 7.179688-1.835938l393.332031-214.753906c4.527344-2.46875 7.464844-7.09375 7.78125-12.242187.316407-5.152344-2.035156-10.101563-6.226562-13.109375l-96.046875-68.917969 181.75-174.402344c3.21875-3.085937 4.894531-7.445312 4.578125-11.890625zm-220.117188 177.136719c-3.214843 3.085937-4.894531 7.441406-4.578125 11.886719.320313 4.449218 2.597656 8.523437 6.21875 11.121094l91.316406 65.527343-275.140624 150.21875 141.5-136.902343c3.195312-3.09375 4.855468-7.441407 4.53125-11.878907-.328126-4.433593-2.601563-8.496093-6.214844-11.085937l-97.878906-70.238282 268.269531-131.496093zm0 0" />
        </SVGIcon>
    );
}
