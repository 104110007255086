import { CallAPI } from 'Utils/apiCall';
import config from 'config';

const api = new CallAPI();
const baseUrl = `${config.REACT_APP_API_BASE_URL}/${process.env.REACT_APP_API_VERSION}/${process.env.REACT_APP_API_CRM}/${process.env.REACT_APP_API_PIPELINES}`;
const filterUrl = `${config.REACT_APP_API_BASE_URL}/${process.env.REACT_APP_API_VERSION}/filters`;
const fieldsUrl = `${config.REACT_APP_API_BASE_URL}/${process.env.REACT_APP_API_VERSION}/${process.env.REACT_APP_API_CRM}/${process.env.REACT_APP_API_FIELDS}`;

// Board view API's

export const getDeals = async (pipelineId, params) => {
    let url = `${baseUrl}/${pipelineId}/${process.env.REACT_APP_API_DEALS}`;
    const response = await api.request('GET', url, null, null, null, params);
    return response;
};

export const createDealApi = async (pipelineId, body) => {
    let url = `${baseUrl}/${pipelineId}/${process.env.REACT_APP_API_DEALS}`;
    return api.request('POST', url, body);
};

export const updateDealAPI = async (pipelineId, dealId, body) => {
    let url = `${baseUrl}/${pipelineId}/${process.env.REACT_APP_API_DEALS}/${dealId}`;
    const response = await api.request('PUT', url, body);
    return response;
};

export const deleteDealOnBoardView = async (pipelineId, dealId) => {
    let url = `${baseUrl}/${pipelineId}/${process.env.REACT_APP_API_DEALS}/${dealId}`;
    const response = await api.request('DELETE', url);
    return response;
};
export const getPipelines = async (limit, page, query) => {
    const response = await api.request('GET', baseUrl, null, limit, page, query);
    return response;
};

export const updatePipelines = async body => {
    const response = await api.request('PUT', baseUrl, body);
    return response;
};

export const updatePipeline = async (pipelineId, body) => {
    const url = `${baseUrl}/${pipelineId}`;
    const response = await api.request('PUT', url, body);
    return response;
};

export const createPipeline = async body => {
    const response = await api.request('POST', baseUrl, body);
    return response;
};

export const deletePipeline = async pipelineId => {
    const url = `${baseUrl}/${pipelineId}`;
    const response = await api.request('DELETE', url);
    return response;
};

export const updatePipelineStages = async (pipelineId, body) => {
    const url = `${baseUrl}/${pipelineId}/${process.env.REACT_APP_API_STAGES}`;
    const response = await api.request('PUT', url, body);
    return response;
};

export const createPropertyAPI = async (type, body) => {
    const url = `${fieldsUrl}/${type}`;
    return api.request('POST', url, body);
};

export const addAttachmentApi = async (pipelineId, dealId, body) => {
    const url = `${baseUrl}/${pipelineId}/${process.env.REACT_APP_API_DEALS}/${dealId}/${process.env.REACT_APP_API_ATTACHMENT}`;
    return api.request('PUT', url, body);
};

export const deleteAttachmentAPI = async (pipelineId, dealId, attachmentId) => {
    let url = `${baseUrl}/${pipelineId}/${process.env.REACT_APP_API_DEALS}/${dealId}/${process.env.REACT_APP_API_ATTACHMENT}/${attachmentId}`;
    return api.request('DELETE', url);
};

export const bulkAction = (body, mode) => {
    let url;
    if (mode === 'user') url = `${baseUrl}/associate`;
    else url = `${baseUrl}/associate/tag`;
    return api.request('PUT', url, body);
};

export const deleteAllDeals = pipelineId => {
    let url = `${baseUrl}/${pipelineId}/${process.env.REACT_APP_API_DEALS}/delete?all=true`;
    return api.request('POST', url);
};

export const deleteMultipleDeals = (pipelineId, body) => {
    let url = `${baseUrl}/${pipelineId}/${process.env.REACT_APP_API_DEALS}/delete`;
    return api.request('POST', url, body);
};
// Automation

export const updateStage = async (pipelineId, stageId, body) => {
    const url = `${baseUrl}/${pipelineId}/${process.env.REACT_APP_API_STAGES}/${stageId}`;

    const response = await api.request('PUT', url, body);
    return response;
};

export const sendTestSms = async body => {
    const url = `${config.REACT_APP_API_BASE_URL}/${process.env.REACT_APP_API_VERSION}/${process.env.REACT_APP_API_INTEGRATIONS}/${process.env.REACT_APP_API_TWILIO}/${process.env.REACT_APP_API_SEND}`;

    const response = await api.request('POST', url, body);
    return response;
};

// Reporting Page API's

export const getDealStages = async (params, userList) => {
    let users = `${process.env.REACT_APP_API_USERS}=${userList}`;

    let url = `${baseUrl}/${process.env.REACT_APP_API_REPORTING}/${process.env.REACT_APP_API_STAGES}`;

    const response = await api.request(
        'GET',
        userList.length === 0 ? url : `${url}?${users}`,
        null,
        null,
        null,
        params,
    );
    return response;
};

export const getLeaderBoard = async (params, userList) => {
    let users = `${process.env.REACT_APP_API_USERS}=${userList}`;
    let url = `${baseUrl}/${process.env.REACT_APP_API_REPORTING}/${process.env.REACT_APP_API_LEADERBOARD}`;
    const response = await api.request(
        'GET',
        userList.length === 0 ? url : `${url}?${users}`,
        null,
        null,
        null,
        params,
    );
    return response;
};

export const getGraph = async (params, userList) => {
    let users = `${process.env.REACT_APP_API_USERS}=${userList}`;
    let url = `${baseUrl}/${process.env.REACT_APP_API_REPORTING}/${process.env.REACT_APP_API_LIST}`;
    const response = await api.request(
        'GET',
        userList.length === 0 ? url : `${url}?${users}`,
        null,
        null,
        null,
        params,
    );
    return response;
};

export const getUserList = async (params, body) => {
    let url = `${config.REACT_APP_API_BASE_URL}/${process.env.REACT_APP_API_VERSION}/${process.env.REACT_APP_API_USERS}`;

    const response = await api.request(
        body.search === '' ? 'GET' : 'POST',
        body.search !== '' ? `${url}/search` : url,
        body,
        null,
        null,
        params,
    );
    return response;
};
// Deals Table(My deals)

export const getTableData = async (params, pipelineId) => {
    let url = `${baseUrl}/${pipelineId}/${process.env.REACT_APP_API_DEALS}/${process.env.REACT_APP_API_SEARCH}`;
    const response = await api.request('GET', url, null, null, null, params);
    return response;
};

export const dealExportApi = async (pipelineId, params) => {
    let url = `${config.REACT_APP_API_BASE_URL}/${process.env.REACT_APP_API_VERSION}/${process.env.REACT_APP_API_CRM}/pipelines/${pipelineId}/${process.env.REACT_APP_API_DEALS}/export`;
    return api.request('GET', url, null, null, null, params);
};

// ## Filter on My deals

export const initializeFilters = async pipelineId => {
    let url = `${filterUrl}/deal?pipeline=${pipelineId}`;
    const response = await api.request('GET', url);
    return response;
};

export const onEditAPI = async (filterId, body, query) => {
    let url = `${filterUrl}/${filterId}`;

    const response = await api.request('PUT', url, body, undefined, undefined, query);
    return response;
};

export const onDeleteAPI = async filterId => {
    let url = `${filterUrl}/${filterId}`;

    const response = await api.request('DELETE', url);
    return response;
};

export const updateAPI = async (body, operationType, filterId, query) => {
    let url, method;
    if (operationType === 'create') {
        url = `${filterUrl}/deal`;
        method = 'POST';
    } else {
        method = 'PUT';
        url = `${filterUrl}/${filterId}`;
    }
    const response = await api.request(method, url, body, undefined, undefined, query);
    return response;
};

export const handlePreviewAPI = async (pipelineId, dealId) => {
    let url = `${baseUrl}/${pipelineId}/${process.env.REACT_APP_API_DEALS}/${dealId}?${process.env.REACT_APP_API_EXPAND}=true`;

    const response = await api.request('GET', url);
    return response;
};

// Automations Logs

export const getAutomationsLogs = async (limit, page, search, filters, order, sort) => {
    let params = {
        limit,
        page,
        ...(search.length > 0 && { search }),
        ...(order.length > 0 && { order }),
        ...(sort.length && { sort }),
    };
    let filterParam = ``;
    if (filters?.pipeline) {
        filterParam = `filters[pipeline.id]=${filters.pipeline}`;
    }

    if (filters?.status?.length) {
        filters.status.forEach(item => {
            filterParam = filterParam.concat(
                filterParam.length ? '&' : '',
                `filters[currentAutomationTrigger.${item}]=YES`,
            );
        });
    }
    if (filters?.automationType?.length) {
        filterParam = filterParam.concat(
            filterParam.length ? '&' : '',
            `filters[currentAutomationTrigger.automation.type]=${filters.automationType.join(',')}`,
        );
    }
    if (filters?.person) {
        filterParam = filterParam.concat(
            filterParam.length ? '&' : '',
            `filters[person.id]=${filters.person.map(item => item.id).join(',')}`,
        );
    }
    if (filters?.business) {
        filterParam = filterParam.concat(
            filterParam.length ? '&' : '',
            `filters[business.id]=${filters.business.map(item => item.id).join(',')}`,
        );
    }
    // ${process.env.REACT_APP_API_AUTOMATIONS}
    let url = `${baseUrl}/${process.env.REACT_APP_API_AUTOMATIONS}/${process.env.REACT_APP_API_LOGS}?${filterParam}`;
    return api.request('GET', url, null, null, null, params);
};

export const getAllAutoamtions = async (dealId, limit, page, filters, order, sort) => {
    let params = { page, limit, ...(order.length > 0 && { order }), ...(sort.length && { sort }) };
    let filterParam = ``;
    if (filters?.pipeline) {
        filterParam = `filters[pipeline.id]=${filters.pipeline}`;
    }

    if (filters?.status?.length) {
        filters.status = filters.status.map(item => item.toUpperCase());
        filterParam = filterParam.concat(
            filterParam.length ? '&' : '',
            `filters[automation_status]=${filters.status.join(',')}`,
        );
    }
    if (filters?.automationType?.length) {
        filterParam = filterParam.concat(
            filterParam.length ? '&' : '',
            `filters[automation.type]=${filters?.automationType.join(',')}`,
        );
    }

    let url = `${baseUrl}/${process.env.REACT_APP_API_AUTOMATIONS}/${process.env.REACT_APP_API_LOGS}/${dealId}?${filterParam}`;
    return api.request('GET', url, null, null, null, params);
};

export const getPreviewInformation = async (dealId, logId) => {
    let url = `${baseUrl}/${process.env.REACT_APP_API_AUTOMATIONS}/${process.env.REACT_APP_API_LOGS}/${dealId}/${logId}`;
    return api.request('GET', url);
};

export const handleActions = async (automationId, action, pipelineId, dealId) => {
    let url = `${baseUrl}/${pipelineId}/${process.env.REACT_APP_API_DEALS}/${dealId}/automation/${automationId}/${action}`;
    return api.request('PUT', url);
};
