import React from 'react';

import { SVGIcon } from '../../UIKit/index';

export default function DCPriority(props) {
    return (
        <SVGIcon {...props} viewBox="0 0 512 388">
            <g>
                <rect x="306.56" y="282.51" width="203.44" height="50.86" />
                <path d="M1.39,168.08c0,91.17,74.13,165.3,165.3,165.3h12.72v50.86l76.29-76.29l-76.29-76.29v50.86h-12.72     c-63.07,0-114.44-51.37-114.44-114.44S103.62,53.64,166.69,53.64h89.01V2.78h-89.01C75.52,2.78,1.39,76.91,1.39,168.08z" />
                <rect x="306.56" y="142.65" width="203.44" height="50.86" />
                <rect x="306.56" y="2.78" width="203.44" height="50.86" />
            </g>
        </SVGIcon>
    );
}
