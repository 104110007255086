import React, { Fragment, memo, useCallback, useRef, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import DCCheck from 'Assets/icons/DCCheck';
import DCPencil from 'Assets/icons/DCPencil';
import DCTrash from 'Assets/icons/DCTrash';
import { Div, IconButton } from '../index';
import { formatNumberString } from 'Utils/';

import './style.scss';
import areValidScopes from 'Utils/scopeUtils';
import useToast from 'Modules/Toasts';

const TabWithTitleNumberBtn = ({
    active,
    item,
    customClass,
    onChange,
    value,
    onRemove,
    handleActiveTab,
    total,
    scope,
    customEditCheck,
    updateAfterDeleteFromMiddle,
    onSelect,
    disabled = false,
    isError,
    setIsError,
    isCheckBoxchecked,
}) => {
    const tabRef = useRef();
    const inputRef = useRef();
    const [title, setTitle] = useState(value);
    const [editState, setEditState] = useState(false);
    const { showToast } = useToast();
    const handleEditState = useCallback(() => {
        setEditState(prev => !prev);
        setTimeout(() => {
            inputRef.current.focus();
        }, 100);
    }, []);

    useEffect(() => {
        if (!editState && updateAfterDeleteFromMiddle) {
            setTitle(value);
        }
    }, [editState, updateAfterDeleteFromMiddle, value]);

    const onUpdate = () => {
        onChange(item.id, title);
    };

    useEffect(() => {
        if (isError) {
            setTitle(value);
            setIsError && setIsError(false);
        }
    }, [isError, setIsError, value]);

    useEffect(() => {
        if (editState) {
            onUpdate();
        }
        setEditState(false);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isCheckBoxchecked]);

    const onDelete = () => {
        onRemove(item.id);
    };

    const onUpdateField = event => {
        setTitle(event.target.value);
    };

    const handleEditOn = useCallback(async () => {
        if (scope) {
            if (!areValidScopes(scope?.master, scope?.child)) {
                showToast({ message: 'You do not have access to this function' });
                return;
            }
        }
        if (customEditCheck) {
            if (await customEditCheck()) handleEditState();
        } else handleEditState();
    }, [customEditCheck, handleEditState, scope, showToast]);
    return (
        <Fragment>
            <Div className="TNTitleMain">
                <Div
                    onClick={() => {
                        onSelect && onSelect(false);
                        if (!editState && !disabled) {
                            handleActiveTab && handleActiveTab(item);
                        }
                    }}
                    reference={tabRef}
                    className={
                        'TitleNumber ' +
                        (active && !editState ? 'active ' : '') +
                        (disabled ? 'disabled ' : '') +
                        (customClass || '')
                    }
                >
                    <Div className="TNTitle">
                        <input
                            type="text"
                            value={title}
                            disabled={!editState}
                            onChange={onUpdateField}
                            ref={inputRef}
                            onFocus={e => e.currentTarget.setSelectionRange(
                                    e.currentTarget.value.length,
                                    e.currentTarget.value.length,
                                )
                            }
                        />
                        {item.showDelete || item.showEdit ? (
                            <Div className="TNTBtns">
                                {item.showEdit ? (
                                    <Fragment>
                                        {editState === true ? (
                                            <IconButton
                                                onClick={() => {
                                                    handleEditState();
                                                    onUpdate();
                                                }}
                                                customClass={'primary'}
                                            >
                                                <DCCheck />
                                            </IconButton>
                                        ) : (
                                            <IconButton
                                                onClick={handleEditOn}
                                                customClass={'primary'}
                                            >
                                                <DCPencil />
                                            </IconButton>
                                        )}
                                    </Fragment>
                                ) : null}
                            </Div>
                        ) : null}
                    </Div>
                    <Div className="TNNumber">{total === 0 ? '0' : formatNumberString(total)}</Div>
                </Div>
                {editState === false && item.showDelete ? (
                    <IconButton customClass={'danger'} onClick={onDelete}>
                        <DCTrash />
                    </IconButton>
                ) : null}
            </Div>
        </Fragment>
    );
};

TabWithTitleNumberBtn.propTypes = {
    active: PropTypes.bool,
    item: PropTypes.object,
    customClass: PropTypes.string,
    onChange: PropTypes.func,
    value: PropTypes.string,
    onRemove: PropTypes.func,
    handleActiveTab: PropTypes.func,
    total: PropTypes.number,
    scope: PropTypes.object,
    customEditCheck: PropTypes.func,
    updateAfterDeleteFromMiddle: PropTypes.bool,
    isCheckBoxchecked: PropTypes.bool,
    onSelect: PropTypes.func,
    disabled: PropTypes.bool,
    setIsError: PropTypes.func,
    isError: PropTypes.bool,
};

export default memo(TabWithTitleNumberBtn);
// <TabWithTitleNumberBtn
//      value={"input Value"}
//      active={true}
//      customClass={"any class name"}
//      onChange={handle Input Change}
//      onRemove={handle Delete},
// />
