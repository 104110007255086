import React from 'react';

import { SVGIcon } from '../../UIKit/index';

export default function DCBarChart1(props) {
    return (
        <SVGIcon {...props} viewBox="0 0 55 45">
            <g transform="translate(-1402 -340)">
                <rect x="1402.37" y="369.96" width="9.93" height="14.9" />
                <rect x="1417.27" y="360.02" width="9.93" height="24.83" />
                <rect x="1432.17" y="350.09" width="9.93" height="34.76" />
                <rect x="1447.07" y="340.16" width="9.93" height="44.7" />
            </g>
        </SVGIcon>
    );
}
