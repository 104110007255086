import React from 'react';

import { SVGIcon } from '../../UIKit/index';

export default function DC3DAdd(props) {
    return (
        <SVGIcon {...props} viewBox="0 0 512 512">
            <g transform="translate(-3.963 -8.723)">
                <g transform="translate(4 8.723)">
                    <path
                        fill="#FFFFFF"
                        d="M256.7,485.8c-61.2,0-118.7-23.8-161.9-67.1C51.6,375.5,27.8,318,27.8,256.9S51.6,138.2,94.8,95    c43.2-43.2,100.7-67.1,161.9-67.1S375.4,51.7,418.6,95c43.2,43.2,67.1,100.7,67.1,161.9s-23.8,118.7-67.1,161.9    C375.4,462,317.9,485.8,256.7,485.8z"
                    />
                    <path
                        fill="#08CBF5"
                        d="M256.7,54.1c-54.2,0-105.1,21.1-143.4,59.4C75,151.8,53.9,202.7,53.9,256.9S75,362,113.3,400.3    c38.3,38.3,89.2,59.4,143.4,59.4s105.1-21.1,143.4-59.4c38.3-38.3,59.4-89.2,59.4-143.4s-21.1-105.1-59.4-143.4    C361.8,75.2,310.9,54.1,256.7,54.1 M256.7,1.7c140.9,0,255.1,114.2,255.1,255.1S397.6,512,256.7,512S1.6,397.8,1.6,256.9    S115.8,1.7,256.7,1.7z"
                    />
                </g>
                <path
                    fill="#00D2FF"
                    d="M241.1,23.5c-130.3,0-236,105.7-236,236s105.7,236,236,236s236-105.7,236-236   C477,129.3,371.4,23.7,241.1,23.5z M344.4,279.2h-83.6v83.6c0,10.9-8.8,19.7-19.7,19.7s-19.7-8.8-19.7-19.7v-83.6h-83.6   c-10.9,0-19.7-8.8-19.7-19.7c0-10.9,8.8-19.7,19.7-19.7h83.6v-83.6c0-10.9,8.8-19.7,19.7-19.7s19.7,8.8,19.7,19.7v83.6h83.6   c10.9,0,19.7,8.8,19.7,19.7C364,270.4,355.2,279.2,344.4,279.2z"
                />
            </g>
        </SVGIcon>
    );
}
