import React from 'react';

import { SVGIcon } from '../../UIKit/index';

export default function DCDisputeUsers(props) {
    return (
        <SVGIcon {...props} viewBox="0 0 512 512">
            <g transform="translate(0.8 0.8)">
                <path d="M136.9,132.5c35,0,63.4-28.4,63.4-63.4S172,5.6,136.9,5.6c-35,0-63.4,28.4-63.4,63.4   c0,0,0,0,0,0C73.5,104,101.9,132.4,136.9,132.5z M136.9,34.9c18.9,0,34.2,15.3,34.2,34.2s-15.3,34.2-34.2,34.2   c-18.9,0-34.2-15.3-34.2-34.2C102.8,50.2,118.1,34.9,136.9,34.9z" />
                <path d="M5.2,235v87.8c0,24.3,19.7,43.9,43.9,43.9c5,0,9.9-0.8,14.6-2.5v141.1h146.4V161.7H78.4   C38,161.8,5.2,194.5,5.2,235z M180.9,476h-29.3V331.6h-29.3V476H93v-247H63.7v93.7c0,8.1-6.6,14.6-14.6,14.6   c-8.1,0-14.6-6.6-14.6-14.6v-87.8c0-24.2,19.7-43.9,43.9-43.9h43.9v96.6h29.3V191h29.3V476z" />
                <path d="M373.1,132.5c35,0,63.4-28.4,63.4-63.4S408.2,5.6,373.1,5.6S309.7,34,309.7,69c0,0,0,0,0,0   C309.7,104,338.1,132.4,373.1,132.5z M373.1,34.9c18.9,0,34.2,15.3,34.2,34.2s-15.3,34.2-34.2,34.2c-18.9,0-34.2-15.3-34.2-34.2   c0,0,0,0,0,0C339,50.2,354.3,34.9,373.1,34.9z" />
                <path d="M431.7,161.7H299.9v343.6h146.4V364.2c22.9,8.1,48-3.9,56.1-26.8c1.7-4.7,2.5-9.6,2.5-14.6   v-87.8C504.9,194.5,472.1,161.8,431.7,161.7z M475.6,322.8c0,8.1-6.6,14.6-14.6,14.6c-8.1,0-14.6-6.6-14.6-14.6v-93.7h-29.3V476   h-29.3V331.6h-29.3V476h-29.3V191h29.3v96.6h29.3V191h43.9c24.2,0,43.9,19.7,43.9,43.9L475.6,322.8z" />
                <rect x="240.4" y="5.6" width="29.3" height="58.9" />
                <rect x="240.4" y="93.7" width="29.3" height="58.9" />
                <rect x="240.4" y="181.9" width="29.3" height="58.9" />
                <rect x="240.4" y="270.1" width="29.3" height="58.9" />
                <rect x="240.4" y="358.3" width="29.3" height="58.9" />
                <rect x="240.4" y="446.4" width="29.3" height="58.9" />
            </g>
        </SVGIcon>
    );
}
