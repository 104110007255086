import React from 'react';

import { SVGIcon } from '../../UIKit/index';

export default function DCGoogleAd(props) {
    return (
        <SVGIcon {...props} viewBox="0 0 512 512">
            <path d="M499.3,348.6l-161-278.9C302,6.8,209.8,7,173.7,69.6l-161,278.9c-36.2,62.7,9.7,140.9,81.1,140.9  c32.1,0,63.8-16.5,81.5-47L256,302.6l80.7,139.8c17.6,30.5,49.3,47,81.5,47C489.6,489.4,535.6,411.3,499.3,348.6L499.3,348.6z   M146.8,426c-14.2,24.6-43.1,35.8-69.7,28.1c-38.2-11.1-55.6-55-35.9-89.1c16.8-29.2,54.1-39.2,83.3-22.3  C153.6,359.5,163.6,396.8,146.8,426L146.8,426z M182.5,364.2c-15.3-43.5-60.2-69.5-105.6-61l90.9-157.5c4.1,12.4,1.2,6.1,69.2,124  L182.5,364.2z M448.5,448.3c-29.2,16.8-66.4,6.9-83.3-22.3l-161-278.9c-30.7-53.2,28.5-113.8,81.3-83.3  c19.8,11.4,14.3,5,185.3,301.2C487.6,394.1,477.6,431.5,448.5,448.3L448.5,448.3z" />
        </SVGIcon>
    );
}
