import React from 'react';

import { SVGIcon } from '../../UIKit/index';

export default function DCMoveStage(props) {
    return (
        <SVGIcon {...props} viewBox="0 0 504.739 487.731">
            <g transform="translate(-3.75 -12.379)">
                <path
                    d="M180.34,407.61,3.75,315.11,29,374,3.75,407.61,29,441.25,3.75,500.11Z"
                    fill="#3781ff"
                />
                <path
                    d="M382.16,54.43a84.09,84.09,0,1,1-83.67,92.5h-42a126.14,126.14,0,1,0,0-16.82h42a84.1,84.1,0,0,1,83.67-75.68Z"
                    fill="#3781ff"
                />
                <path
                    d="M359.4,119.24l11.89-11.9,31.17,31.18-31.17,31.17L359.4,157.8l10.87-10.87H341a42.05,42.05,0,1,0,0-16.82h29.31Z"
                    fill="#005eff"
                />
                <path
                    d="M340.12,138.52a42.38,42.38,0,0,1,.84-8.41H298.49c-.27,2.76-.42,5.57-.42,8.41s.15,5.64.42,8.41H341a42.38,42.38,0,0,1-.88-8.41Z"
                    fill="#3781ff"
                />
                <path
                    d="M191.19,169.71,214,147h42.47c-.19-2.79-.43-5.57-.43-8.4s.24-5.62.42-8.41H213.94l-22.77-22.76-11.88,11.89,10.87,10.87H155.8a88.3,88.3,0,1,0,0,176.6l93.22-.005,22.77,22.76,11.89-11.9L272.85,306.7H445.24a46.25,46.25,0,1,1,0,92.5H356.95L334.2,376.43l-11.89,11.89,10.87,10.87h-136V416h136l-10.87,10.87,11.89,11.89L357,416h88.3a63.07,63.07,0,0,0,0-126.14l-172.38.05L283.72,279l-11.9-11.89-22.75,22.77H155.84a71.5,71.5,0,1,1,0-143H190.2l-10.9,10.95Z"
                    fill="#005eff"
                />
            </g>
        </SVGIcon>
    );
}
