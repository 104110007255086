import React from 'react';
import { Button, Dialog, Div } from '../../UIKit';
import './style.scss';
import PropTypes from 'prop-types';
function SingleButtonAlert(props) {
    const { open, onClose, title, message, button } = props;
    return (
        <Dialog
            dialogType={'centerZoom sizeSm'}
            open={open}
            onClose={onClose}
            classes={{
                root: 'AlertDialog ',
            }}
        >
            <Div className={'AlertMain '}>
                <Div className={'AMIHead'}>
                    <Div className={'AMIHTitle'}>{title || 'Information'}</Div>
                </Div>
                <Div className={'AMIBody'}>
                    <Div className={'AMIBInner'}>
                        <Div className={'AMIBIDes'}>{message}</Div>
                        <Div className={'AMIBIBtns'}>
                            <Button buttonClass={'BlueFillBtn'} onClick={button?.onClick}>
                                {button?.title || 'Okay'}
                            </Button>
                        </Div>
                    </Div>
                </Div>
            </Div>
        </Dialog>
    );
}

SingleButtonAlert.propTypes = {
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    title: PropTypes.string,
    message: PropTypes.string.isRequired,
    button: PropTypes.object.isRequired,
};

export default SingleButtonAlert;
