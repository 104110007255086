import React from 'react';
import PropTypes from 'prop-types';
export default function ListItem({ props, children, onClick, className }) {
    return (
        <li {...props} className={className} onClick={onClick}>
            {children}
        </li>
    );
}

ListItem.propTypes = {
    children: PropTypes.element,
    onClick: PropTypes.func,
    className: PropTypes.string,
};
