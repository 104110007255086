import { getLocalStorageAuthenticateObject, isAuthenticated } from 'Utils/auth';
import axios from 'axios';
import config from 'config';
import { CallAPI } from 'Utils/apiCall';
import {
    getAccountDetails,
    isLocalStorageAvailable,
    setAuthConfigData,
} from 'Utils/localStorageUtils';
import { useQuery } from 'react-query';
const mainURL = `${config.REACT_APP_API_BASE_URL}/${process.env.REACT_APP_API_VERSION}`;
const emailURL = `${mainURL}/${process.env.REACT_APP_API_INTEGRATIONS}/${process.env.REACT_APP_API_SENDGRID}/${process.env.REACT_APP_API_MAIL}`;

const api = new CallAPI();

export const handleShortenURL = async body => {
    return api.request('POST', `${mainURL}/url`, body);
};

const CancelSource = axios.CancelToken.source();

export const Emailtest = async data => {
    const headers = {
        'x-session-id': getLocalStorageAuthenticateObject()?.auth?.session_id,
        'x-Account-Id': getLocalStorageAuthenticateObject()?.auth?.account?.id,
        'Content-Type': 'multipart/form-data',
    };
    const url = `${config.REACT_APP_API_BASE_URL}/${process.env.REACT_APP_API_VERSION}/${process.env.REACT_APP_API_INTEGRATIONS}/${process.env.REACT_APP_API_SENDGRID}/${process.env.REACT_APP_API_MAIL}`;
    return axios.post(url, data, { headers, cancelToken: CancelSource.token });
};

export const SendEmails = async body => {
    let url = `${emailURL}`;
    let headers = {
        'x-session-id': getLocalStorageAuthenticateObject()?.auth?.session_id,
        'x-Account-Id': getLocalStorageAuthenticateObject()?.auth?.account?.id,
        'Content-Type': 'multipart/form-data',
    };
    return axios.post(url, body, {
        headers,
        cancelToken: CancelSource.token,
    });
};

// test sms
export const testSMS = async data => {
    const url = `${config.REACT_APP_API_BASE_URL}/${process.env.REACT_APP_API_VERSION}/${process.env.REACT_APP_API_INTEGRATIONS}/twilio/send`;
    return api.request('POST', url, data);
};

export const menuData = async () => {
    const url = `${config.REACT_APP_API_BASE_URL}/${process.env.REACT_APP_API_VERSION}/users/me/config/dashboard-menu`;
    const res = await api.request('GET', url);
    if (res.success) {
        return res.data;
    }
    return [];
};

export const updateMenuData = async body => {
    const url = `${config.REACT_APP_API_BASE_URL}/${process.env.REACT_APP_API_VERSION}/users/me/config/dashboard-menu`;
    if (Object.keys(body).length > 0) {
        const res = await api.request('PUT', url, body);
        if (res.success) {
            return res.data;
        }
    }
    return [];
};

export const useUsersData = () => {
    const { data, isLoading, isFetching } = useQuery(
        ['auth-user'],
        async () => {
            const _data = await getUsersScopes();
            const _authenticated = isAuthenticated();
            _data.authenticated = _authenticated;
            // localStorage.setItem('auth-user', JSON.stringify({ auth: _data }));
            setAuthConfigData(_data);
            return _data;
        },
        {
            keepPreviousData: true,
            staleTime: 1000 * 60 * 10, // 10 minutes
            cacheTime: 1000 * 60 * 15, // 15 minutes
            retry: 3,
            enabled: isLocalStorageAvailable() && isAuthenticated(),
        },
    );

    return { data, loading: isLoading || isFetching };
};

export const getUsersScopes = async () => {
    const url = `${config.REACT_APP_API_BASE_URL}/${process.env.REACT_APP_API_VERSION}/auth/status`;
    const res = await api.request('GET', url);
    if (res.success) {
        if (res.message === 'INVALID_SESSION') {
            return null;
        }
        let authData = {
            authenticated: true,
            ...res.data,
        };
        setAuthConfigData(authData);
        return res.data;
    }

    return [];
};

export const getUserConfigs = async () => {
    const url = `${config.REACT_APP_API_BASE_URL}/${process.env.REACT_APP_API_VERSION}/users/me/config/all `;
    const res = await api.request('GET', url);
    if (res.success) {
        return res.data;
    }
    return [];
};

export const getUsageData = async query => {
    const url = `${config.REACT_APP_API_BASE_URL}/${process.env.REACT_APP_API_VERSION}/accounts/usage?resources=${query}`;
    return api.request('GET', url);
};

export const getWhatsNew = async query => {
    const url =
        `${config.REACT_APP_API_BASE_URL}/${process.env.REACT_APP_API_VERSION}/e/announcements/beamer/posts?` +
        query;
    return api.request('GET', url);
};

export const getNotifications = async query => {
    return api.request(
        'GET',
        `${config.REACT_APP_API_BASE_URL}/${process.env.REACT_APP_API_VERSION}/${process.env.REACT_APP_API_INTEGRATIONS_FCM}?${query}`,
    );
};

export const updateNotification = async (body = {}) => {
    return api.request(
        'PUT',
        `${config.REACT_APP_API_BASE_URL}/${process.env.REACT_APP_API_VERSION}/${process.env.REACT_APP_API_INTEGRATIONS_FCM}/read`,
        body,
    );
};

export const getReminders = async query => {
    const url = `${config.REACT_APP_API_BASE_URL}/${process.env.REACT_APP_API_VERSION}/${process.env.REACT_APP_API_CRM}/${process.env.REACT_APP_API_REMINDERS}/all?${query}`;
    return api.request('GET', url);
};

export const authSetupForEmail = async () => {
    const account_id = getAccountDetails()?.id;
    const url = `${config.REACT_APP_API_BASE_URL}/${process.env.REACT_APP_API_VERSION}/e/sendgrid/subusers`;
    return api.request('POST', url, {
        username: `${account_id}-dyn@dashboardnotifications.com`,
        email: `${account_id}-dyn@dashboardnotifications.com`,
    });
};

export const authSendgrid = () => {
    const url = `${config.REACT_APP_API_BASE_URL}/${process.env.REACT_APP_API_VERSION}/e/sendgrid/auth`;
    return api.request('POST', url);
};

export const authTwilio = () => {
    const url = `${config.REACT_APP_API_BASE_URL}/${process.env.REACT_APP_API_VERSION}/e/twilio/subaccounts`;
    return api.request('POST', url);
};

export const checkSSO = async token => {
    const url = `${config.REACT_APP_API_BASE_URL}/${process.env.REACT_APP_API_VERSION}/auth/sso?d=${token}`;
    return api.requestPublic('GET', url);
};
