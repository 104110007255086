import React, { Fragment, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import loadable from '@loadable/component';
import _ from 'lodash';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';

import DCAddIcon from 'Assets/icons/DCAddIcon';
import DCNoResultFound from 'Assets/icons/DCNoResultFound';
import DCCheckList from 'Assets/icons/DCCheckList';
import DCCrossClose from 'Assets/icons/DCCrossClose';
import SearchBox from 'Components/SearchBox';
import useToast from 'Modules/Toasts';
import { Button, Dialog, Div, DragBox, H1, IconButton, TopBar, NoData, ToolTip } from 'UIKit';

import './style.scss';

import { findItemIndex } from 'Utils';
import { updateColumns } from 'Utils/Columns';
import { createFieldApi, deleteFieldApi, updateFieldApi } from 'apis/manageColumns/index.api';
import areValidScopes from 'Utils/scopeUtils';
import { payWallConstants } from 'Constants/payWallLimits';
import isUnderPayWall from 'Utils/payWallUtils';
import usePayWallDialog from 'Hooks/PayWallDialog/usePayWallDialog';
import payConstants from 'Constants/payWallConstants';
import DCLock from 'Assets/icons/DCLock';
import DCCompare from 'Assets/icons/DCCompare';
import PropTypes from 'prop-types';

import {
    baseColumnFields,
    contactInformationSection,
} from 'Components/ContactCard/LeftSIdeBar/constant';
import {
    baseColumnFields as baseDealFields,
    dealInformationSection,
} from 'Pages/Deals/DealCard/constant';
import DCNoProperty1 from 'Assets/icons/DCNoProperty1';
import { useQueryClient } from 'react-query';

const CreateProperty = loadable(() => import('../CreateProperty/createProperty'));
const Confirmation = loadable(() => import('../Confirmation'));

function alphabaticalCompare(a, b) {
    if (a.Header?.trim().toLowerCase() < b.Header?.trim().toLowerCase()) {
        return -1;
    }
    if (a.Header?.trim().toLowerCase() > b.Header?.trim().toLowerCase()) {
        return 1;
    }
    return 0;
}

const ManageProperties = ({
    open,
    onClose,
    source,
    fieldType,
    columns,
    setColumns,
    masterScope,
    childScope,
}) => {
    const { showToast } = useToast();
    const showPayWallDialog = usePayWallDialog();

    const [properties, setProperties] = useState([]);
    const [nonDraggableItems, setNonDraggableItems] = useState([]);
    const [draggableItems, setDraggableItems] = useState([]);
    const [openCreateProperty, setOpenCreateProperty] = useState(false);
    const [searched, setSearched] = useState(false);
    const [searchResult, setSearchResult] = useState([]);
    const [itemToDelete, setItemToDelete] = useState(null);
    const [openConfirmation, setOpenConfirmation] = useState(false);
    const [editItem, setEditItem] = useState(null);
    const [loading, setLoading] = useState(false);
    const [disableSave, setDisableSave] = useState(true);
    const [contactDraggable, setContactDraggable] = useState([]);
    const [generalDraggable, setGeneralDraggable] = useState([]);
    const [additionalDraggable, setAdditionalDraggable] = useState([]);
    const [dealDraggable, setDealDraggable] = useState([]);
    const queryClient = useQueryClient();
    function compare(a, b) {
        if (a.orderInCard < b.orderInCard) {
            return -1;
        }
        if (a.orderInCard > b.orderInCard) {
            return 1;
        }
        return 0;
    }

    useEffect(() => {
        const tempFields = _.cloneDeep(columns);
        const bFields = { ...baseColumnFields };
        const dFields = { ...baseDealFields };
        for (let val of tempFields) {
            val['subSection'] =
                fieldType === 'people'
                    ? bFields.person_contact_information[val.accessor]?.section || 'additional_info'
                    : fieldType === 'businesses'
                    ? bFields.business_contact_information[val.accessor]?.section ||
                      'additional_info'
                    : dFields[val.accessor]?.section || 'additional_info';
        }
        const temp = tempFields.filter(el => el.accessor !== 'owner' && !el?.hide_in_card);
        setProperties(temp);
        const tempVisibleProperties = temp.filter(col => col.visibleInCard === true);
        tempVisibleProperties.forEach(data => {
            data.active = data.visibleInCard;
        });
        // tempVisibleProperties.forEach((item, index) => {
        //     item.orderInCard = index;
        // });
        setNonDraggableItems(tempVisibleProperties.filter(el => el.default === true).sort(compare));
        const newDraggableItems = tempVisibleProperties
            .filter(el => el.default === false)
            .sort(compare);
        setDraggableItems(tempVisibleProperties.filter(el => el.default === false).sort(compare));
        if (fieldType === 'deals') {
            setDealDraggable(newDraggableItems.filter(el => el.subSection === 'deal_info'));
            setAdditionalDraggable(
                newDraggableItems.filter(el => el.subSection === 'additional_info'),
            );
        } else {
            setContactDraggable(newDraggableItems.filter(el => el.subSection === 'contact_info'));
            setGeneralDraggable(newDraggableItems.filter(el => el.subSection === 'general_info'));
            setAdditionalDraggable(
                newDraggableItems.filter(el => el.subSection === 'additional_info'),
            );
        }
    }, [columns, source, fieldType]);

    const adjustAddRemoveProperty = (list, element, endIndex) => {
        const result = list;
        result.splice(endIndex, 0, element);
        return result;
    };

    // to change visibility of a property in contact card
    const handleAddRemove = useCallback(
        (accessor, section_type) => {
            const index = properties.findIndex(item => item.accessor === accessor);
            const tempProperties = _.cloneDeep(properties);
            const tempDraggableItems = _.cloneDeep(draggableItems);
            let currentDraggableSectionItems = [];
            if (section_type === 'contact_info') {
                currentDraggableSectionItems = [...contactDraggable];
            } else if (section_type === 'general_info') {
                currentDraggableSectionItems = [...generalDraggable];
            } else if (section_type === 'deal_info') {
                currentDraggableSectionItems = [...dealDraggable];
            } else {
                currentDraggableSectionItems = [...additionalDraggable];
            }
            tempProperties[index].visibleInCard = !tempProperties[index].visibleInCard;
            if (!tempProperties[index].visibleInCard) {
                tempProperties[index].orderInCard = 9999999;
                const indDraggableItems = tempDraggableItems.findIndex(
                    col => col.accessor === tempProperties[index].accessor,
                );
                if (indDraggableItems !== -1) {
                    tempDraggableItems.splice(indDraggableItems, 1);
                }
                const indSectionDraggableItems = currentDraggableSectionItems.findIndex(
                    col => col.accessor === tempProperties[index].accessor,
                );
                if (indSectionDraggableItems !== -1) {
                    currentDraggableSectionItems.splice(indSectionDraggableItems, 1);
                }
                if (section_type === 'contact_info') {
                    setContactDraggable(currentDraggableSectionItems);
                } else if (section_type === 'general_info') {
                    setGeneralDraggable(currentDraggableSectionItems);
                } else if (section_type === 'deal_info') {
                    setDealDraggable(currentDraggableSectionItems);
                } else {
                    tempProperties[index].orderInCard = draggableItems.length;
                    setAdditionalDraggable(currentDraggableSectionItems);
                }
            } else {
                let temp = tempProperties[index];
                temp.active = true;
                tempDraggableItems.push(temp);
                let newArray = [];
                newArray = adjustAddRemoveProperty(
                    currentDraggableSectionItems,
                    temp,
                    currentDraggableSectionItems.length,
                );
                if (section_type === 'contact_info') {
                    tempProperties[index].orderInCard = currentDraggableSectionItems.length;
                    setContactDraggable(newArray);
                } else if (section_type === 'general_info') {
                    tempProperties[index].orderInCard = currentDraggableSectionItems.length;
                    setGeneralDraggable(newArray);
                } else if (section_type === 'deal_info') {
                    tempProperties[index].orderInCard = currentDraggableSectionItems.length;
                    setDealDraggable(newArray);
                } else {
                    tempProperties[index].orderInCard = draggableItems.length;
                    setAdditionalDraggable(newArray);
                }
            }
            setProperties(tempProperties);
            setDraggableItems(tempDraggableItems);
            if (searched) {
                const tempSearchResult = _.cloneDeep(searchResult);
                const index = searchResult.findIndex(item => item.accessor === accessor);
                if (index >= 0) {
                    tempSearchResult[index].visibleInCard = !tempSearchResult[index].visibleInCard;
                }
                setSearchResult(tempSearchResult);
            }
            if (disableSave) setDisableSave(false);
        },
        [
            properties,
            draggableItems,
            searched,
            disableSave,
            contactDraggable,
            generalDraggable,
            dealDraggable,
            additionalDraggable,
            searchResult,
        ],
    );

    // to generate views for DragAndDrop
    const draggableItemViews = useMemo(() => {
        return contactDraggable.map(item => {
            return {
                ...item,
                data: (
                    <DragBox
                        title={item.Header}
                        buttons={[
                            {
                                iconName: !item.default && <DCCrossClose />,
                                iconColor: '',
                                onClick: () => {
                                    handleAddRemove(item.accessor, 'contact_info');
                                },
                            },
                        ]}
                    />
                ),
            };
        });
    }, [contactDraggable, handleAddRemove]);

    const generalItemViews = useMemo(() => {
        return generalDraggable.map(item => {
            return {
                ...item,
                data: (
                    <DragBox
                        title={item.Header}
                        buttons={[
                            {
                                iconName: !item.default && <DCCrossClose />,
                                iconColor: '',
                                onClick: () => {
                                    handleAddRemove(item.accessor, 'general_info');
                                },
                            },
                        ]}
                    />
                ),
            };
        });
    }, [generalDraggable, handleAddRemove]);

    const dealItemViews = useMemo(() => {
        return dealDraggable.map(item => {
            return {
                ...item,
                data: (
                    <DragBox
                        title={item.Header}
                        buttons={[
                            {
                                iconName: !item.default && <DCCrossClose />,
                                iconColor: '',
                                onClick: () => {
                                    handleAddRemove(item.accessor, 'deal_info');
                                },
                            },
                        ]}
                    />
                ),
            };
        });
    }, [dealDraggable, handleAddRemove]);

    const additionalItemViews = useMemo(() => {
        return additionalDraggable.map(item => {
            return {
                ...item,
                data: (
                    <DragBox
                        title={item.Header}
                        buttons={[
                            {
                                iconName: !item.default && <DCCrossClose />,
                                iconColor: '',
                                onClick: () => {
                                    handleAddRemove(item.accessor, 'additional_info');
                                },
                            },
                        ]}
                    />
                ),
            };
        });
    }, [additionalDraggable, handleAddRemove]);

    // to generate views for non draggable items
    const nonDraggableItemViews = useMemo(() => {
        return nonDraggableItems.map(item => {
            return {
                ...item,
                data: (
                    <DragBox
                        title={item.Header}
                        disabled
                        buttons={[
                            {
                                iconName: <DCLock />,
                                iconColor: '',
                            },
                        ]}
                    />
                ),
            };
        });
    }, [nonDraggableItems]);

    const reOrderArray = (list, startIndex, endIndex) => {
        const result = list;
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);
        return result;
    };

    const onDragEnd = result => {
        // dropped outside the list
        if (!result.destination) {
            return;
        }
        const newDraggableItemViews = [...contactDraggable];
        const items = reOrderArray(
            newDraggableItemViews,
            result.source.index,
            result.destination.index,
        );
        setContactDraggable([...items]);
        const allitems = [...items, ...generalDraggable, ...additionalDraggable];
        setDraggableItems([...allitems]);
        let tempProperties = [...properties];
        tempProperties.forEach(data => {
            const index = allitems.findIndex(x => x.accessor === data.accessor);
            data.orderInCard = index;
        });
        setProperties(tempProperties);
        if (disableSave) setDisableSave(false);
    };

    const onDealDragEnd = result => {
        if (!result.destination) {
            return;
        }
        const newDraggableItemViews = [...dealDraggable];
        const items = reOrderArray(
            newDraggableItemViews,
            result.source.index,
            result.destination.index,
        );
        setDealDraggable([...items]);
        const allitems = [...items, ...additionalDraggable];
        setDraggableItems([...allitems]);
        let tempProperties = [...properties];
        tempProperties.forEach(data => {
            const index = allitems.findIndex(x => x.accessor === data.accessor);
            data.orderInCard = index;
        });
        setProperties(tempProperties);
        if (disableSave) setDisableSave(false);
    };

    const onGeneralInfoDragEnd = result => {
        // dropped outside the list
        if (!result.destination) {
            return;
        }
        const newDraggableItemViews = [...generalDraggable];
        const items = reOrderArray(
            newDraggableItemViews,
            result.source.index,
            result.destination.index,
        );
        setGeneralDraggable([...items]);
        const allitems = [...contactDraggable, ...items, ...additionalDraggable];
        setDraggableItems([...allitems]);
        let tempProperties = [...properties];
        tempProperties.forEach(data => {
            const index = allitems.findIndex(x => x.accessor === data.accessor);
            data.orderInCard = index;
        });
        setProperties(tempProperties);
        if (disableSave) setDisableSave(false);
    };

    const onAdditionalInfoDragEnd = result => {
        // dropped outside the list
        if (!result.destination) {
            return;
        }
        const newDraggableItemViews = [...additionalDraggable];
        const items = reOrderArray(
            newDraggableItemViews,
            result.source.index,
            result.destination.index,
        );
        setAdditionalDraggable([...items]);
        let allitems = [];
        if (fieldType === 'deals') {
            allitems = [...dealDraggable, ...items];
        } else {
            allitems = [...contactDraggable, ...generalDraggable, ...items];
        }
        setDraggableItems([...allitems]);
        let tempProperties = [...properties];
        tempProperties.forEach(data => {
            const index = allitems.findIndex(x => x.accessor === data.accessor);
            data.orderInCard = index;
        });
        setProperties(tempProperties);
        if (disableSave) setDisableSave(false);
    };

    const firstSearchRef = useRef({ search: '' });

    const handleSearch = useCallback(
        searchTerm => {
            if (searchTerm) {
                firstSearchRef.current.search = searchTerm;
                setSearched(true);
                let tempArr;

                tempArr = properties.filter(
                    item => item.Header?.toLowerCase().search(searchTerm.toLowerCase()) >= 0,
                );

                setSearchResult(tempArr);
            } else {
                setSearched(false);
                setSearchResult([]);
            }
        },
        [properties],
    );

    /**
     * @param {String} mode - "edit", "clone" or undefined for create
     * @param {Object} property - property to edit or clone. not necessary fro create
     */
    // handle opening and closing od create property dialog
    const handleCreatePropertyToggle = useCallback(
        async (mode, property) => {
            if (source === 'contact' || source === 'deals') {
                let field =
                    source === 'contact'
                        ? payWallConstants.CONTACTS_CUSTOM_PROPERTIES
                        : payWallConstants.DEALS_CUSTOM_PROPERTIES;
                const paywallCheck = isUnderPayWall(field);
                if (!paywallCheck.success) {
                    showPayWallDialog({
                        type:
                            field === payWallConstants.CONTACTS_CUSTOM_PROPERTIES
                                ? payConstants.CUSTOM_PROPERTIES
                                : payConstants.DEAL_CUSTOM_PROPERTIES,
                    });
                    return;
                }
            }

            if (masterScope || childScope) {
                if (!areValidScopes(masterScope, childScope)) {
                    showToast({
                        message: `You don't have access to this app.`,
                    });
                    return;
                }
            }
            if (mode === 'edit') {
                setEditItem(property);
            } else if (mode === 'clone') {
                delete property.Header;
                setEditItem(property);
            }
            if (openCreateProperty) setEditItem(null);
            setOpenCreateProperty(prev => !prev);
        },
        [childScope, masterScope, openCreateProperty, showPayWallDialog, showToast, source],
    );

    /**
     * @param {Array} data - array of properties
     * @param {Boolean} updateApi - if true PUT api of config will be hit
     */
    const updateContext = useCallback(
        async (data, updateApi) => {
            data.forEach(item => {
                const temp = columns.find(x => x.accessor === item.accessor);
                if (temp) item.active = temp.active;
            });
            if (updateApi)
                await updateColumns(data, source === 'contact' ? 'contacts' : source, fieldType);
            setColumns([...data]);
        },
        [columns, setColumns, source, fieldType],
    );

    const addToDraggableItems = useCallback(
        property => {
            property.active = true;
            let temp = draggableItems;
            temp.push(property);
            setDraggableItems(temp);
        },
        [draggableItems],
    );

    const handleCreateProperty = useCallback(
        async body => {
            try {
                const response = await createFieldApi(fieldType, body);
                if (response && response.success) {
                    let data = response.data;
                    let newColumn = {
                        Header: data.title,
                        accessor: data.field,
                        active: true,
                        sortable: true,
                        draggable: true,
                        mandatory: data.config.mandatory,
                        default: data.config.is_disallowed,
                        order: 9999999,
                        width: data.config.width,
                        options: data['options'],
                        custom: data.custom || false,
                        type: data.type,
                        visibleInCard: data.config.in_dialog_card || false,
                        orderInCard: draggableItems.length + 1,
                        allow_update: true,
                    };
                    setProperties([...properties, newColumn]);
                    const updatedProperties = [...properties, newColumn];
                    updateContext([...updatedProperties]);
                    if (searched) {
                        let tempArr;
                        tempArr = updatedProperties.filter(
                            item => item.Header?.toLowerCase().search(
                                    firstSearchRef.current.search?.toLowerCase(),
                                ) >= 0,
                        );
                        setSearchResult(tempArr);
                    }
                    addToDraggableItems(newColumn);
                    setOpenCreateProperty(false);
                    if (disableSave) setDisableSave(false);
                    showToast({
                        type: 'success',
                        message: 'Property Created Successfully',
                        duration: 1500,
                    });
                    if (fieldType === 'people') {
                        queryClient.invalidateQueries(['person-fields']);
                    } else {
                        queryClient.invalidateQueries(['business-fields']);
                    }
                }
            } catch (err) {
                let message = 'Something went wrong!';
                if (err.message) {
                    message = err.message;
                } else {
                    message = err;
                }
                showToast({ type: 'error', message: message, duration: 1500 });
                setOpenCreateProperty(false);
            }
        },
        [
            addToDraggableItems,
            disableSave,
            draggableItems,
            properties,
            showToast,
            updateContext,
            fieldType,
            searched,
            queryClient,
        ],
    );

    const handleEditProperty = useCallback(
        async (body, propertyAccessor) => {
            try {
                const response = await updateFieldApi(fieldType, propertyAccessor, body);
                if (response && response.success) {
                    let tempArray = properties;
                    const index = findItemIndex(tempArray, 'accessor', propertyAccessor);
                    tempArray[index].Header = response.data.title;
                    setProperties(tempArray);
                    updateContext(tempArray, false);
                    if (searched) {
                        const searchArray = [...searchResult];
                        const index = findItemIndex(searchArray, 'accessor', propertyAccessor);
                        searchArray[index].Header = response.data.title;
                        searchArray[index].accessor = response.data.field;
                        setSearchResult([...searchArray]);
                    }
                    handleCreatePropertyToggle(false);
                    showToast({
                        type: 'success',
                        message: 'Property Updated',
                        duration: 1500,
                    });
                    if (fieldType === 'people') {
                        queryClient.invalidateQueries(['person-fields']);
                    } else {
                        queryClient.invalidateQueries(['business-fields']);
                    }
                }
            } catch (err) {
                let message = 'Something went wrong!';
                if (err.response) {
                    message = err.response.data.message;
                } else {
                    message = err;
                }
                showToast({
                    type: 'error',
                    message: message,
                    duration: 1500,
                });
            }
            if (disableSave) setDisableSave(false);
        },
        [
            disableSave,
            handleCreatePropertyToggle,
            showToast,
            fieldType,
            updateContext,
            searchResult,
            searched,
            queryClient,
            properties,
        ],
    );

    const handleCloneProperty = useCallback(
        async property => {
            if (masterScope || childScope) {
                if (!areValidScopes(masterScope, childScope)) {
                    showToast({
                        message: `You don't have access to this app.`,
                    });
                    return;
                }
            }
            let body = {};
            body.title = property.Header;
            body.valueType = property.type;
            if (
                ['dropdown', 'multiplecheck', 'singlecheck'].includes(
                    property.valueType?.toLowerCase(),
                )
            )
                body.options = property.options;
            handleCreateProperty(body);
            if (disableSave) setDisableSave(false);
        },
        [childScope, disableSave, handleCreateProperty, masterScope, showToast],
    );

    const handleConfirmationToggle = useCallback(() => {
        setOpenConfirmation(prev => !prev);
        if (openConfirmation) setItemToDelete(null);
    }, [openConfirmation]);

    /**
     * @param {accessor} - accessor of the property to be deleted
     */
    const handleOpenConfirmation = useCallback(
        async accessor => {
            if (masterScope || childScope) {
                if (!areValidScopes(masterScope, childScope)) {
                    showToast({
                        message: `You don't have access to this app.`,
                    });
                    return;
                }
            }
            setItemToDelete(accessor);
            handleConfirmationToggle();
        },
        [childScope, handleConfirmationToggle, masterScope, showToast],
    );

    const handleDelete = useCallback(async () => {
        try {
            setLoading(true);
            const response = await deleteFieldApi(fieldType, itemToDelete);
            if (response && response.success) {
                let tempArray = properties;
                tempArray = tempArray.filter(item => item.accessor !== itemToDelete);
                setProperties(tempArray);
                let tempDraggableItems = draggableItems.filter(
                    item => item.accessor !== itemToDelete,
                );
                setDraggableItems(tempDraggableItems);
                showToast({
                    type: 'success',
                    message: 'Property Deleted',
                    duration: 1500,
                });
                updateContext(tempArray, false);
                if (searched) {
                    let searchArray = [...searchResult];
                    searchArray = searchArray.filter(item => item.accessor !== itemToDelete);
                    setSearchResult([...searchArray]);
                }
                handleConfirmationToggle();
                setLoading(false);
                setDisableSave(true);
            }
        } catch (err) {
            let message = 'Something went wrong!';
            if (err['response']) {
                message = err.response.data.message;
            } else {
                message = err;
            }
            setLoading(false);
            showToast({ type: 'error', message: message, duration: 1500 });
        }
        if (disableSave) setDisableSave(false);
    }, [
        disableSave,
        draggableItems,
        handleConfirmationToggle,
        itemToDelete,
        properties,
        showToast,
        fieldType,
        updateContext,
        searchResult,
        searched,
    ]);

    const fieldDragZone = (section_type, item_name) => {
        const modColumns = [...nonDraggableItemViews];
        const newNonDraggableItemViews = modColumns?.filter(
            item => item.subSection === section_type,
        );
        const newDraggableItemViews =
            section_type === 'contact_info'
                ? [...draggableItemViews]
                : section_type === 'general_info'
                ? [...generalItemViews]
                : section_type === 'deal_info'
                ? [...dealItemViews]
                : [...additionalItemViews];
        return (
            <Div>
                <Div className={'MPISIAITitle'} style={{ padding: '5px 20px' }}>
                    {item_name}
                </Div>
                <Div className={'MPISIAIAdded'}>
                    <DragDropContext
                        onDragEnd={
                            section_type === 'contact_info'
                                ? onDragEnd
                                : section_type === 'general_info'
                                ? onGeneralInfoDragEnd
                                : section_type === 'deal_info'
                                ? onDealDragEnd
                                : onAdditionalInfoDragEnd
                        }
                    >
                        <Droppable droppableId="droppableDisabled" isDropDisabled={true}>
                            {provided => (
                                <div
                                    component="div"
                                    className={'MPISIAIAPInnerNonDraggable'}
                                    {...provided.droppableProps}
                                    ref={provided.innerRef}
                                >
                                    {newNonDraggableItemViews.map((item, index) => (
                                        <Draggable
                                            key={item.accessor}
                                            draggableId={item.accessor}
                                            index={index}
                                            isDragDisabled
                                        >
                                            {provided => (
                                                <div
                                                    ref={provided.innerRef}
                                                    {...provided.draggableProps}
                                                    {...provided.dragHandleProps}
                                                    className={'MPISIAIAPIITem'}
                                                >
                                                    {item.data}
                                                </div>
                                            )}
                                        </Draggable>
                                    ))}
                                    {provided.placeholder}
                                </div>
                            )}
                        </Droppable>
                    </DragDropContext>
                    {newDraggableItemViews.length > 0 ? (
                        <DragDropContext
                            onDragEnd={
                                section_type === 'contact_info'
                                    ? onDragEnd
                                    : section_type === 'general_info'
                                    ? onGeneralInfoDragEnd
                                    : section_type === 'deal_info'
                                    ? onDealDragEnd
                                    : onAdditionalInfoDragEnd
                            }
                        >
                            <Droppable droppableId="droppable">
                                {provided => (
                                    <div
                                        component="div"
                                        className={'MPISIAIAPInner '}
                                        {...provided.droppableProps}
                                        ref={provided.innerRef}
                                    >
                                        {newDraggableItemViews.map((item, index) => (
                                            <Draggable
                                                key={item.accessor}
                                                draggableId={item.accessor}
                                                index={index}
                                            >
                                                {provided => (
                                                    <div
                                                        ref={provided.innerRef}
                                                        {...provided.draggableProps}
                                                        {...provided.dragHandleProps}
                                                        className={'MPISIAIAPIITem'}
                                                    >
                                                        {item.data}
                                                    </div>
                                                )}
                                            </Draggable>
                                        ))}
                                        {provided.placeholder}
                                    </div>
                                )}
                            </Droppable>
                        </DragDropContext>
                    ) : (
                        section_type !== 'contact_info' &&
                        section_type !== 'deal_info' && (
                            <NoData
                                className={'MPISIAIAPNoData'}
                                icon={<DCNoProperty1 />}
                                type={'small'}
                                title={' '}
                                description={'No Properties Found'}
                            />
                        )
                    )}
                </Div>
            </Div>
        );
    };

    const allPropertyZone = (section_type, item_name) => {
        const modColumns = [...properties];
        const newProperties = modColumns?.filter(item => item.subSection === section_type);
        return (
            <Div>
                <Div className={'MPSectionTitle'} style={{ marginTop: '1rem' }}>
                    {item_name}
                </Div>
                <Div className={'MPIRSBIBInner'}>
                    {newProperties.length ? (
                        [...newProperties].sort(alphabaticalCompare).map((property, index) => (
                            <Div className={'MPIRSBIBITitleBtns'} key={index}>
                                <Div className={'MPIRSBIBITTitle'}>
                                    {property.Header}
                                    {property.custom && (
                                        <ToolTip content={'Custom Property'}>
                                            <DCCompare />
                                        </ToolTip>
                                    )}
                                </Div>
                                <Div className={'MPIRSBIBITOptions'}>
                                    {!property.default && (
                                        <Button
                                            buttonClass={'BlueOutlineBtn'}
                                            onClick={() => {
                                                handleAddRemove(property.accessor, section_type);
                                            }}
                                        >
                                            {`${
                                                property.visibleInCard ? 'Remove from' : 'Add to'
                                            } view`}
                                        </Button>
                                    )}
                                    <Button
                                        buttonClass={'BlueOutlineBtn'}
                                        onClick={() => handleCloneProperty(property)}
                                    >
                                        Clone
                                    </Button>
                                    {property.custom && (
                                        <Button
                                            buttonClass={'BlueOutlineBtn'}
                                            onClick={() => handleCreatePropertyToggle('edit', property)
                                            }
                                        >
                                            Rename
                                        </Button>
                                    )}
                                    {property.custom && (
                                        <Button
                                            buttonClass={'RedOutlineBtn'}
                                            onClick={() => handleOpenConfirmation(property.accessor)
                                            }
                                        >
                                            Delete
                                        </Button>
                                    )}
                                </Div>
                            </Div>
                        ))
                    ) : (
                        <NoData icon={<DCNoResultFound />} title={'No Properties Found'} />
                    )}
                </Div>
            </Div>
        );
    };

    return (
        <Fragment>
            {openCreateProperty && (
                <CreateProperty
                    open={openCreateProperty}
                    onClose={handleCreatePropertyToggle}
                    openAsEdit={editItem}
                    editItem={editItem}
                    showAddToView={!editItem}
                    createColumn={handleCreateProperty}
                    editColumn={handleEditProperty}
                />
            )}
            {openConfirmation && (
                <Confirmation
                    open={openConfirmation}
                    onClose={handleConfirmationToggle}
                    declinedButton={{
                        title: 'Cancel',
                        onClick: handleConfirmationToggle,
                    }}
                    acceptButton={{ title: 'Delete', onClick: handleDelete }}
                    confirmationType={'delete'}
                    loading={loading}
                />
            )}
            <Dialog dialogType={'fullWidth'} open={open} onClose={onClose}>
                <Div className={'ManageProperties'}>
                    <TopBar>
                        <Div className={'HeaderBar'}>
                            <Div className={'HeaderBarLeft'}>
                                <H1 className={'HeaderBarTitle'}>Manage Properties</H1>
                            </Div>
                            <Div className={'HeaderBarClose'}>
                                <Button
                                    buttonClass={'BlackOutlineBtn'}
                                    onClick={async () => {
                                        if (masterScope || childScope) {
                                            if (!areValidScopes(masterScope, childScope)) {
                                                showToast({
                                                    message: `You don't have access to this app.`,
                                                });
                                                return;
                                            }
                                        }
                                        updateContext(properties, true);
                                        onClose();
                                    }}
                                    disabled={disableSave}
                                    style={{ marginRight: 10 }}
                                >
                                    Save
                                </Button>
                                <IconButton onClick={onClose}>
                                    <DCCrossClose />
                                </IconButton>
                            </Div>
                        </Div>
                    </TopBar>
                    <Div className={'BodyBox'}>
                        <Div className={'BodyBoxInner'}>
                            <Div className={'ManagePropertiesInner'}>
                                <Div className={'MPISidebar'}>
                                    <Div className={'MPISInner'}>
                                        <Div className={'MPISIAbout'}>
                                            <Div className={'MPISIAInner'}>
                                                <Div className={'MPISIAINfo'}>
                                                    <Div className={'MPISIAITitle'}>Abouts</Div>
                                                    <Div className={'MPISIAIDes'}>
                                                        {`These properties will appear when you view information about ${source}. These changes are global and will affect your entire team.`}
                                                    </Div>
                                                </Div>
                                                {fieldType === 'deals' ? (
                                                    <>
                                                        {dealInformationSection?.map(item => (
                                                            <Div key={item.accessor}>
                                                                {fieldDragZone(
                                                                    item?.accessor,
                                                                    item?.name,
                                                                )}
                                                            </Div>
                                                        ))}
                                                    </>
                                                ) : (
                                                    <>
                                                        {contactInformationSection?.map(item => (
                                                            <Div key={item?.accessor}>
                                                                {fieldDragZone(
                                                                    item?.accessor,
                                                                    item?.name,
                                                                )}
                                                            </Div>
                                                        ))}
                                                    </>
                                                )}
                                            </Div>
                                        </Div>
                                    </Div>
                                </Div>
                                <Div className={'MPIRightSection'}>
                                    <Div className={'MPIRSInner'}>
                                        <Div className={'MPIRSHeader'}>
                                            <Div className={'MPIRSHInfo'}>
                                                <Div className={'MPIRSHTitle'}>All Properties</Div>
                                                <Div className={'MPIRSHDes'}>
                                                    Browse through and select {source} properties
                                                    below to customize your view.
                                                </Div>
                                            </Div>
                                        </Div>
                                        <Div className={'MPIRSBody'}>
                                            <Div className={'MPIRSBInner'}>
                                                <Div className={'MPIRSBIHead'}>
                                                    <SearchBox
                                                        placeholder={'Search properties'}
                                                        fullWidth={true}
                                                        handleSearch={handleSearch}
                                                        delay={50}
                                                    />
                                                    <Div className={'MPIRSHBtn'}>
                                                        <Button
                                                            buttonType={'BlueFillBtn'}
                                                            iconName={<DCAddIcon />}
                                                            onClick={handleCreatePropertyToggle}
                                                        >
                                                            New Property
                                                        </Button>
                                                    </Div>
                                                </Div>
                                                <Div className={'MPIRSBIBody'}>
                                                    {searched ? (
                                                        <Div className={'MPIRSBIBInner'}>
                                                            {searchResult.length ? (
                                                                [...searchResult]
                                                                    .sort(alphabaticalCompare)
                                                                    .map((property, index) => (
                                                                        <Div
                                                                            className={
                                                                                'MPIRSBIBITitleBtns'
                                                                            }
                                                                            key={index}
                                                                        >
                                                                            <Div
                                                                                className={
                                                                                    'MPIRSBIBITTitle'
                                                                                }
                                                                            >
                                                                                {property.Header}
                                                                                {property.custom && (
                                                                                    <ToolTip
                                                                                        content={
                                                                                            'Custom Property'
                                                                                        }
                                                                                    >
                                                                                        <DCCompare />
                                                                                    </ToolTip>
                                                                                )}
                                                                            </Div>
                                                                            <Div
                                                                                className={
                                                                                    'MPIRSBIBITOptions'
                                                                                }
                                                                            >
                                                                                {!property.default && (
                                                                                    <Button
                                                                                        buttonClass={
                                                                                            'BlueOutlineBtn'
                                                                                        }
                                                                                        onClick={() => {
                                                                                            handleAddRemove(
                                                                                                property.accessor,
                                                                                                property.subSection,
                                                                                            );
                                                                                        }}
                                                                                    >
                                                                                        {`${
                                                                                            property.visibleInCard
                                                                                                ? 'Remove from'
                                                                                                : 'Add to'
                                                                                        } view`}
                                                                                    </Button>
                                                                                )}
                                                                                <Button
                                                                                    buttonClass={
                                                                                        'BlueOutlineBtn'
                                                                                    }
                                                                                    onClick={() => handleCloneProperty(
                                                                                            property,
                                                                                        )
                                                                                    }
                                                                                >
                                                                                    Clone
                                                                                </Button>
                                                                                {property.custom && (
                                                                                    <Button
                                                                                        buttonClass={
                                                                                            'BlueOutlineBtn'
                                                                                        }
                                                                                        onClick={() => handleCreatePropertyToggle(
                                                                                                'edit',
                                                                                                property,
                                                                                            )
                                                                                        }
                                                                                    >
                                                                                        Rename
                                                                                    </Button>
                                                                                )}
                                                                                {property.custom && (
                                                                                    <Button
                                                                                        buttonClass={
                                                                                            'RedOutlineBtn'
                                                                                        }
                                                                                        onClick={() => handleOpenConfirmation(
                                                                                                property.accessor,
                                                                                            )
                                                                                        }
                                                                                    >
                                                                                        Delete
                                                                                    </Button>
                                                                                )}
                                                                            </Div>
                                                                        </Div>
                                                                    ))
                                                            ) : (
                                                                <NoData
                                                                    icon={<DCNoResultFound />}
                                                                    title={'No Properties Found'}
                                                                />
                                                            )}
                                                        </Div>
                                                    ) : (
                                                        <>
                                                            {fieldType === 'deals' ? (
                                                                <>
                                                                    {dealInformationSection?.map(
                                                                        item => (
                                                                            <Div
                                                                                key={item.accessor}
                                                                            >
                                                                                {allPropertyZone(
                                                                                    item?.accessor,
                                                                                    item?.name,
                                                                                )}
                                                                            </Div>
                                                                        ),
                                                                    )}
                                                                </>
                                                            ) : (
                                                                <>
                                                                    {contactInformationSection?.map(
                                                                        item => (
                                                                            <Div
                                                                                key={item.accessor}
                                                                            >
                                                                                {allPropertyZone(
                                                                                    item?.accessor,
                                                                                    item?.name,
                                                                                )}
                                                                            </Div>
                                                                        ),
                                                                    )}
                                                                </>
                                                            )}
                                                        </>
                                                    )}
                                                </Div>
                                            </Div>
                                        </Div>
                                    </Div>
                                </Div>
                            </Div>
                            <Div className={'MPIIcon'}>
                                <DCCheckList />
                            </Div>
                        </Div>
                    </Div>
                </Div>
            </Dialog>
        </Fragment>
    );
};

ManageProperties.propTypes = {
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    data: PropTypes.object,
    source: PropTypes.string.isRequired,
    fieldType: PropTypes.string.isRequired,
    columns: PropTypes.arrayOf(PropTypes.object).isRequired,
    setColumns: PropTypes.func.isRequired,
    hideInfoBar: PropTypes.bool,
    masterScope: PropTypes.string,
    childScope: PropTypes.string,
};

export default ManageProperties;
