import React from 'react';

import { SVGIcon } from '../../UIKit/index';

export default function DCActiveCampaign(props) {
    return (
        <SVGIcon {...props} viewBox="0 0 500 500">
            <g>
                <rect fill="#4174B4" x="67.5" y="67.5" width="365" height="365" />
                <path
                    fill="#FFFFFF"
                    d="M327.2,264.5c5.6-3.9,7.2-9,7.2-13.8c0-4.9,0,4.7,0-2.2c0-5.1-2.8-9-7.2-12.2   c-3.7-2.7-139.2-96.9-139.2-96.9s0,12.2,0,20c0,7,3.8,9.3,8.5,12.6c6.8,4.8,112.6,78.3,112.6,78.3s-107.2,74.1-113.6,78.3   c-6.7,4.4-7.5,7.4-7.5,13.3c0,5.9,0,18.7,0,18.7S321.2,268.7,327.2,264.5z"
                />
                <path
                    fill="#FFFFFF"
                    d="M188,205.8c0,5.4,0,13.8,0,13.8s41.8,29.2,49.9,34.6c6.9,4.6,12.7,4.2,19.8-1   c4.4-3.2,8.5-6.1,8.5-6.1s-60.9-42.3-67.4-46.5C193.7,197.2,188,199.6,188,205.8z"
                />
            </g>
        </SVGIcon>
    );
}
