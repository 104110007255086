import React from 'react';

import { SVGIcon } from '../../UIKit/index';

export default function DCMyProjectsMenu(props) {
    return (
        <SVGIcon {...props} viewBox="0 0 512 512">
            <g>
                <g transform="translate(0 0)">
                    <path
                        fill="#2F8DFF"
                        d="M325.69,399.28v-33.42H187.2v33.42c-0.84,23.85-7.4,47.15-19.13,67.94h176.8    C333.12,446.44,326.54,423.14,325.69,399.28z"
                    />
                    <path
                        fill="#2F8DFF"
                        d="M499.43,365.86h-486c-6.9,0-12.49-5.59-12.49-12.49V54.59c0-6.9,5.59-12.49,12.49-12.49h486.03    c6.9,0,12.49,5.59,12.49,12.49v298.78c0,6.9-5.59,12.49-12.49,12.49C499.45,365.86,499.44,365.86,499.43,365.86z"
                    />
                    <path
                        fill="#2F8DFF"
                        d="M512,54.59v298.78c0,6.9-5.59,12.49-12.49,12.49h-24.35c6.9,0,12.49-5.59,12.49-12.49V54.59    c0-6.9-5.59-12.49-12.49-12.49h24.35C506.41,42.1,512,47.69,512,54.59z"
                    />
                    <path
                        fill="#E6EFFF"
                        d="M40.71,320.44V91.02c-0.33-6.37,4.55-11.81,10.92-12.18h409.6    c6.37,0.37,11.25,5.81,10.92,12.18v229.43H40.71z"
                    />
                    <g transform="translate(3.825)">
                        <path
                            fill="#EAF6FF"
                            d="M339.45,306.2H97.06V15.13c0-7.39,5.99-13.38,13.38-13.38l0,0h215.63     c7.39,0,13.38,5.99,13.38,13.38l0,0V306.2z"
                        />
                        <path
                            fill="#D8ECFE"
                            d="M339.45,15.13V306.2h-24.09V15.13c-0.01-7.38-6-13.36-13.38-13.38h24.09     C333.46,1.75,339.45,7.74,339.45,15.13z"
                        />
                        <g transform="translate(1.254 1.733)">
                            <g>
                                <path
                                    fill="#2F8DFF"
                                    d="M217,63.44h-79.41c-4.98,0-9.02-4.04-9.02-9.02s4.04-9.02,9.02-9.02H217       c4.98,0,9.02,4.04,9.02,9.02S221.98,63.44,217,63.44L217,63.44z"
                                />
                            </g>
                            <g transform="translate(0 1.499)">
                                <path
                                    fill="#2F8DFF"
                                    d="M245.09,101.01h-107.5c-4.98,0-9.02-4.04-9.02-9.02s4.04-9.02,9.02-9.02h107.5       c4.98,0,9.02,4.04,9.02,9.02S250.07,101.01,245.09,101.01L245.09,101.01z"
                                />
                            </g>
                            <g transform="translate(0 2.998)">
                                <path
                                    fill="#2F8DFF"
                                    d="M296.44,138.68H137.59c-4.98,0-9.02-4.04-9.02-9.02c0-4.98,4.04-9.02,9.02-9.02h158.85       c4.98,0,9.02,4.04,9.02,9.02C305.45,134.64,301.42,138.68,296.44,138.68L296.44,138.68z"
                                />
                            </g>
                            <g transform="translate(0 4.498)">
                                <path
                                    fill="#2F8DFF"
                                    d="M296.44,176.37H137.59c-4.98,0-9.02-4.04-9.02-9.02s4.04-9.02,9.02-9.02h158.85       c4.98,0,9.02,4.04,9.02,9.02S301.42,176.37,296.44,176.37L296.44,176.37z"
                                />
                            </g>
                            <g transform="translate(0 5.997)">
                                <path
                                    fill="#2F8DFF"
                                    d="M296.44,214.05H137.59c-4.98,0-9.02-4.04-9.02-9.02c0-4.98,4.04-9.02,9.02-9.02h158.85       c4.98,0,9.02,4.04,9.02,9.02C305.45,210.01,301.42,214.05,296.44,214.05L296.44,214.05z"
                                />
                            </g>
                            <g transform="translate(0 7.496)">
                                <path
                                    fill="#2F8DFF"
                                    d="M296.44,251.72H137.59c-4.98,0-9.02-4.04-9.02-9.02c0-4.98,4.04-9.02,9.02-9.02h158.85       c4.98,0,9.02,4.04,9.02,9.02C305.45,247.68,301.42,251.72,296.44,251.72L296.44,251.72z"
                                />
                            </g>
                        </g>
                    </g>
                </g>
                <path
                    fill="#2F8DFF"
                    d="M511.92,306.23v47.03c0,6.9-5.59,12.49-12.49,12.49h-24.35c6.9,0,12.49-5.59,12.49-12.49v-47.03   H511.92z"
                />
                <g transform="translate(4.936 17.47)">
                    <path
                        fill="#2F8DFF"
                        d="M368.99,458.82H134c-4.98,0-9.02-4.04-9.02-9.02s4.04-9.02,9.02-9.02h234.99    c4.98,0,9.02,4.04,9.02,9.02S373.97,458.82,368.99,458.82z"
                    />
                </g>
            </g>
        </SVGIcon>
    );
}
