import React from 'react';

import { SVGIcon } from '../../UIKit/index';

export default function DCUserPortfolio(props) {
    return (
        <SVGIcon {...props} viewBox="0 0 512 512">
            <g>
                <path d="M155.6,153.9c-10.5,10.5-16.8,24.5-17.7,39.4c12.4-1.2,24-6.5,33.1-15  C162.4,173,156.7,164,155.6,153.9z" />
                <path d="M198.3,306.5c16.2,0,31.6-6.7,42.8-18.3c11.4-11.7,17.7-27.3,17.7-43.6v-61.2h-67.3  c-13,17.1-32.8,27.5-54.3,28.6v32.6c0.1,16.3,6.6,32,18.1,43.6C166.5,299.8,182,306.4,198.3,306.5L198.3,306.5z M216.2,221.6  c5.1,0,9.3,4.1,9.3,9.2c0,0,0,0,0,0c0,1.5,1.2,2.7,2.7,2.7s2.7-1.2,2.7-2.7l0,0c-0.2-5.1,3.7-9.5,8.8-9.7c5.1-0.2,9.5,3.7,9.7,8.8  c0,0.3,0,0.6,0,0.9c0.4,11.7-8.8,21.5-20.5,21.9c-11.7,0.4-21.5-8.8-21.9-20.5c0-0.5,0-0.9,0-1.4C207,225.8,211.1,221.6,216.2,221.6  L216.2,221.6L216.2,221.6z M147.3,230.9c-0.2-5.1,3.7-9.5,8.8-9.7c5.1-0.2,9.5,3.7,9.7,8.8c0,0.3,0,0.6,0,0.9c0,1.5,1.2,2.7,2.7,2.7  c1.5,0,2.7-1.2,2.7-2.7c-0.2-5.1,3.7-9.5,8.8-9.7c5.1-0.2,9.5,3.7,9.7,8.8c0,0.3,0,0.6,0,0.9c-0.4,11.7-10.2,20.9-21.9,20.5  C156.7,251,147.7,242.1,147.3,230.9L147.3,230.9z" />
                <path d="M188.5,164.8H275c-4.5-17-19.8-28.8-37.3-28.9h-49.1c-8,0-14.5,6.5-14.5,14.5  C174.1,158.3,180.5,164.8,188.5,164.8L188.5,164.8z" />
                <path d="M386.1,110.3l-92.5-95.2v81.4c0,7.6,6.2,13.8,13.8,13.7c0,0,0.1,0,0.1,0L386.1,110.3z" />
                <path d="M399,473.8V128.9h-91.4c-17.8,0.1-32.4-14.3-32.5-32.2c0,0,0,0,0-0.1V1.5H38  C17.5,1.6,0.8,18.2,0.7,38.7v435.1C0.8,494.3,17.5,510.9,38,511h323.7C382.2,510.9,398.9,494.3,399,473.8z M318,467H74.5  c-5.1,0-9.3-4.2-9.3-9.3l0,0c0-5.1,4.2-9.2,9.3-9.2H318c5.1-0.3,9.5,3.7,9.7,8.8c0.3,5.1-3.7,9.5-8.8,9.7  C318.6,467,318.3,467,318,467L318,467z M328.4,418.5c0.1,4.9-3.7,9-8.6,9.1c-0.2,0-0.3,0-0.5,0h-239c-2.4,0-4.8-0.9-6.5-2.7  c-1.7-1.7-2.6-4.1-2.6-6.5v-42.2c-0.1-31.5,25.3-57.2,56.8-57.5h25.4v-8.5c-4.1-2.6-7.9-5.7-11.2-9.3  c-14.9-15.1-23.4-35.4-23.5-56.6v-47.7c0.2-32.6,20.3-61.7,50.6-73.6c5.6-3.8,12.3-5.9,19.1-5.9h49.1c31.4,0,56.9,25.4,57,56.8  c0,5-4.1,9.1-9.1,9.1c0,0-0.1,0-0.1,0h-8v61.2c0,21.2-8.3,41.5-23.2,56.6c-2.4,2.7-5.1,5.1-8.1,7.1v10.6h25.4  c31.5,0.3,56.9,26,56.8,57.5L328.4,418.5z" />
                <path d="M227.6,319.1c-9.2,3.9-19.2,5.9-29.2,5.9c-9,0-18-1.6-26.4-4.8v3.3l27,17.9l28.6-18L227.6,319.1z  " />
                <path d="M271.6,337.3h-31.8l-31.9,20.3v51.5h101.8v-32.8C309.8,355,292.8,337.7,271.6,337.3z" />
                <path d="M128.1,337.3c-21.3,0.3-38.4,17.7-38.2,39v32.8h99.6v-51.7l-29.8-20.1H128.1z" />
            </g>
        </SVGIcon>
    );
}
