import React from 'react';
import PropTypes from 'prop-types';
import { Span } from '../index';

export default function Label({
    props,
    children,
    customClass,
    size,
    reference,
    required,
    onClick,
}) {
    return (
        <label
            {...props}
            className={'InputLabel ' + (customClass ? customClass : '') + (size ? size : '')}
            ref={reference}
            onClick={onClick}
        >
            {children} {required ? <Span> *</Span> : ''}
        </label>
    );
}

Label.propTypes = {
    children: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
    customClass: PropTypes.string,
    size: PropTypes.string,
    reference: PropTypes.object,
    required: PropTypes.bool,
    onClick: PropTypes.func,
    props: PropTypes.object,
};
