import useToast from 'Modules/Toasts';
import React, { useCallback, useEffect, useState } from 'react';
import { Button, Div, FieldControl, Input, Label, LabelControl } from 'UIKit';
import { getUserWorkspaceInfo } from 'Utils/localStorageUtils';
import { Validation } from 'Utils/validator';
import log from 'Utils/logger';
import { convertHTMLToText } from 'Utils';

const TestEmail = ({
    subject,
    editorState,
    editorHtml,
    attachments,
    apiCb: EmailApi,
    origin,
    replyToEmail,
    fallback,
}) => {
    const { showToast } = useToast();

    const [disabledTestButton, setDisabledTestButton] = useState(true);
    const [testName, setTestName] = useState('');
    const [testEmail, setTestEmail] = useState('');
    const [loading, setLoading] = useState(false);

    const handleTestEmail = useCallback(async () => {
        setLoading(true);
        try {
            if (convertHTMLToText(subject).trim().length === 0) {
                throw new Error('No subject provided.');
            }
            if (editorState.getCurrentContent().getPlainText('\u0001').length === 0) {
                throw new Error('Email body cannot be empty.');
            }

            const body = new FormData();
            const sub = convertHTMLToText(subject);
            body.append('origin', origin);
            body.append('subject', sub);
            body.append('recipients[0][email]', testEmail.toString()); // testEmail
            body.append('recipients[0][name]', testName);
            body.append('content[0][type]', 'text/html');
            body.append('content[0][value]', editorHtml);
            body.append('sender[email]', getUserWorkspaceInfo().email);
            body.append('sender[name]', testName);
            if (replyToEmail.length) {
                body.append('reply_to[email]', replyToEmail);
            }
            if (attachments.list.length) {
                attachments.list.forEach(item => {
                    body.append('attachments', item.file);
                });
            }
            body.append('fallback_values', JSON.stringify(fallback));
            const response = await EmailApi(body);
            if (response.data.success) {
                showToast({
                    type: 'success',
                    message: 'Email Sent Successfully',
                });
            }
        } catch (error) {
            if (!['No subject provided.', 'Email body is not vaild.'].includes(error.message))
                log.error('test email error' + error);
            showToast({
                type: 'error',
                message: error.message || 'Something went wrong.Please try again',
            });
        } finally {
            setLoading(false);
        }
    }, [
        EmailApi,
        attachments,
        editorHtml,
        editorState,
        origin,
        replyToEmail,
        showToast,
        subject,
        testEmail,
        testName,
        fallback,
    ]);

    useEffect(() => {
        if (loading) {
            setDisabledTestButton(true);
        } else {
            if (testName.length && testEmail.length && Validation('email', testEmail.trim())) {
                setDisabledTestButton(false);
            } else {
                setDisabledTestButton(true);
            }
        }
    }, [loading, testEmail, testName.length]);
    return (
        <FieldControl>
            <LabelControl>
                <Label>Test your email</Label>
            </LabelControl>
            <Div className={'CESFTestForm'}>
                <Input
                    placeholder={'From Name'}
                    onChange={e => setTestName(e.target.value)}
                    value={testName}
                />
                <Input
                    placeholder={'Email'}
                    onChange={e => setTestEmail(e.target.value)}
                    error={testEmail.length !== 0 && !Validation('email', testEmail)}
                    errorMessage={'Invalid email address'}
                    value={testEmail}
                />
                <Button
                    buttonType={'BlueFillBtn'}
                    onClick={handleTestEmail}
                    disabled={disabledTestButton}
                >
                    Send Test
                </Button>
            </Div>
        </FieldControl>
    );
};

export default TestEmail;
