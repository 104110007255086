import React from 'react';

import { SVGIcon } from '../../UIKit/index';

export default function DCDesktop(props) {
    return (
        <SVGIcon {...props} viewBox="0 0 512 420">
            <path d="M480.3,11.6H46.7c-20.4,0-37,16.6-37,37v285.5c0,20.4,16.6,37,37,37h433.6c20.4,0,37-16.6,37-37   V48.6C517.3,28.2,500.7,11.6,480.3,11.6z M475,286.6H52V53.9h423V286.6z" />
            <path d="M345,416.5c-16.1-14.5-26.3-34.5-28.6-56c0-5.8-4.7-10.5-10.5-10.5h-85.1   c-5.9,0-10.6,4.8-10.6,10.6c-2.1,21.5-12.1,41.5-28.2,55.9c-4.1,4.1-4.1,10.8,0.1,15c2,2,4.6,3.1,7.4,3.1h148.1   c5.8,0,10.6-4.7,10.6-10.6C348.1,421.2,347,418.5,345,416.5L345,416.5z" />
        </SVGIcon>
    );
}
