import React from 'react';

import { SVGIcon } from '../../UIKit/index';

export default function DCGeoLocation(props) {
    return (
        <SVGIcon {...props} viewBox="0 0 331 512">
            <g>
                <path d="M268.5,167.1c0-57.2-46.4-103.6-103.6-103.6c-57.2,0-103.6,46.4-103.6,103.6   c0,57.2,46.4,103.6,103.6,103.6C221.9,270.2,268,224.1,268.5,167.1z M90.9,167.1c0-40.9,33.1-74,74-74c40.9,0,74,33.1,74,74   s-33.1,74-74,74C124.2,240.8,91.2,207.9,90.9,167.1z" />
                <path d="M327.6,167.1c0-89.9-72.9-162.7-162.7-162.7S2.2,77.3,2.2,167.1C2.2,227,60.8,317.4,95.5,367   c-38.7,7.8-93.3,27.8-93.3,67.4c0,56.6,105.3,74,162.7,74s162.7-17.4,162.7-74c0-39.5-54.7-59.6-93.3-67.4   C268.9,317.6,327.6,227.1,327.6,167.1z M31.8,167.1C31.8,93.6,91.4,34,164.9,34C238.4,34,298,93.6,298,167.1   c0,62.6-87.5,186.1-133.1,243.7C119.3,353.3,31.8,229.8,31.8,167.1z M298,434.5c0,24.7-72.4,44.4-133.1,44.4S31.8,459.1,31.8,434.5   c0-11.8,26.4-32.2,82.9-40.7c21.5,29.3,37.8,48.9,38.8,50.2c5.2,6.3,14.5,7.2,20.8,2c0.7-0.6,1.4-1.3,2-2   c1-1.2,17.3-20.9,38.8-50.2C271.6,402.2,298,422.6,298,434.5L298,434.5z" />
            </g>
        </SVGIcon>
    );
}
