import React, { Fragment, useCallback, useState } from 'react';
import { formatNumberString } from 'Utils';
import { abbreviateNumber } from 'Utils/stringUtils';

import DCBarChart2 from 'Assets/icons/DCBarChart2';
import DCLineChart from 'Assets/icons/DCLineChart';
import DCSpeedMeter from 'Assets/icons/DCSpeedMeter';
import NoFound from './noFound';
import { Button, Div, Image, Strong, TextOverflowTooltip, TooltipWithIcon } from 'UIKit/index';
import HowToDialog from './howToDialog';
import generateImage from 'Utils/generateImage';

const GoogleAds = ({
    visible,
    grade,
    overview,
    paid_competitor,
    details,
    user,
    handleGetInTouchOpen,
}) => {
    const [howToDialog, setHowToDialog] = useState(false);
    const handleHowToDialogOpen = useCallback(() => {
        setHowToDialog(true);
    }, []);

    const handleHowToDialogClose = useCallback(() => {
        setHowToDialog(false);
    }, []);

    if (!visible) {
        return null;
    }

    return (
        <Fragment>
            <HowToDialog
                open={howToDialog}
                onClose={handleHowToDialogClose}
                title={'How we calculate the Google Ads grade'}
                description={
                    <Fragment>
                        Your <Strong>Google Ads score</Strong> is calculated by taking the ad
                        traffic and dividing it by the ad cost. Then doing this once again between
                        you, versus your competitors, which results in a final average score.
                    </Fragment>
                }
            />
            <Div className={'EPComponent'}>
                <Div className={'EPCHead'}>
                    <Div className={'EPCHTBox'}>
                        <Div className={'EPCHTBIcon Grade' + grade}>{grade}</Div>
                        <Div className={'EPCHTBTitle'}>Google Ads</Div>
                        <Div className={'EPCHTBSTitle'}>
                            Are your Google Ads Campaign performing well?
                        </Div>
                    </Div>
                    <Div className={'EPCHSTitle'} onClick={handleHowToDialogOpen}>
                        How we calculated this grade?
                    </Div>
                </Div>
                <Div className={'EPCBody'}>
                    <Div className={'EPCVideoContent'}>
                        <Div className={'EPCVCVideo'}>
                            <iframe
                                src="https://player.vimeo.com/video/356237804"
                                title="video"
                                frameBorder="0"
                                webkitallowfullscreen=""
                                mozallowfullscreen=""
                            />
                        </Div>
                        <Div className={'EPCVCContent'}>
                            <Div className={'EPCVCCDes'}>
                                On average, 41% of searches click on the top 3 paid ads on the
                                search results page. Start expanding the reach of people who are
                                looking for your products and services.
                            </Div>
                            <br />
                            <Div className={'EPCVCCDes'}>
                                We ran a comprehensive PPC analysis to see how your direct
                                competitors are spending their advertising budgets with Google Ads.
                            </Div>
                        </Div>
                    </Div>

                    {paid_competitor?.length > 0 && Object.keys(overview || {}).length > 0 ? (
                        <Fragment>
                            <Div className={'EPCInnerHeading'}>
                                <Div className={'EPCIHLTitle'}>Google Ads Overview</Div>
                                <Div className={'EPCIHRTitle'}>
                                    How your Google Ads are performing
                                </Div>
                            </Div>
                            <Div className={'EPCRow'}>
                                <Div className={'EPCRCol3'}>
                                    <Div
                                        className={'EPCRCKTTC'}
                                        style={{ backgroundColor: '#eef5ff' }}
                                    >
                                        <Div className={'EPCRCKTTCInfo'}>
                                            <Div className={'EPCRCKTTCITitleBox'}>
                                                <Div className={'EPCRCKTTCITBTitle'}>Keywords</Div>
                                                <TooltipWithIcon
                                                    tooltipContent={
                                                        "The number of keywords bringing users to an analyzed domain via Google's paid search results."
                                                    }
                                                />
                                            </Div>
                                            <Div className={'EPCRCKTTCINumber'}>
                                                {abbreviateNumber(overview?.keywords)}
                                            </Div>
                                        </Div>
                                        <Div
                                            className={'EPCRCKTTCIcon'}
                                            style={{ color: '#3f50f6' }}
                                        >
                                            <DCBarChart2 />
                                        </Div>
                                    </Div>
                                </Div>

                                <Div className={'EPCRCol3'}>
                                    <Div
                                        className={'EPCRCKTTC'}
                                        style={{ backgroundColor: '#fff7eb' }}
                                    >
                                        <Div className={'EPCRCKTTCInfo'}>
                                            <Div className={'EPCRCKTTCITitleBox'}>
                                                <Div className={'EPCRCKTTCITBTitle'}>Traffic</Div>
                                                <TooltipWithIcon
                                                    tooltipContent={
                                                        'The number of users expected to visit an analyzed domain in the following month on the condition that the average amount of monthly paid traffic stays relatively the same.'
                                                    }
                                                />
                                            </Div>
                                            <Div className={'EPCRCKTTCINumber'}>
                                                {abbreviateNumber(overview?.traffic)}
                                            </Div>
                                        </Div>
                                        <Div className={'EPCRCKTTCIcon'}>
                                            <DCSpeedMeter />
                                        </Div>
                                    </Div>
                                </Div>

                                <Div className={'EPCRCol3'}>
                                    <Div
                                        className={'EPCRCKTTC'}
                                        style={{ backgroundColor: '#e4fbf8' }}
                                    >
                                        <Div className={'EPCRCKTTCInfo'}>
                                            <Div className={'EPCRCKTTCITitleBox'}>
                                                <Div className={'EPCRCKTTCITBTitle'}>
                                                    Traffic Cost
                                                </Div>
                                                <TooltipWithIcon
                                                    tooltipContent={
                                                        'The estimated average monthly cost to rank for the listed keywords in Google AdWords.'
                                                    }
                                                />
                                            </Div>
                                            <Div className={'EPCRCKTTCINumber'}>
                                                ${formatNumberString(overview?.trafficCost)}
                                            </Div>
                                        </Div>
                                        <Div className={'EPCRCKTTCIcon'}>
                                            <DCLineChart />
                                        </Div>
                                    </Div>
                                </Div>
                            </Div>
                            <Div className={'EPCInnerHeading'}>
                                <Div className={'EPCIHLTitle'}>Google Ads Competitor Analysis</Div>
                                <Div className={'EPCIHRTitle'}>
                                    Here’s your Google Ads compared to your competitors
                                </Div>
                            </Div>

                            <Div className={'EPCTable'}>
                                <Div className={'EPCTInner'}>
                                    <Div className={'EPCTIRow EPCTIRHeader EPCTIRCol7'}>
                                        <Div className={'EPCTIRCol'}>
                                            <Div className={'EPCTIRHTitle'}>
                                                <Div className={'EPCTIRHText'}>Domain</Div>
                                            </Div>
                                        </Div>
                                        <Div className={'EPCTIRCol'}>
                                            <Div className={'EPCTIRHTitle'}>
                                                <Div className={'EPCTIRHText'}>
                                                    <TextOverflowTooltip
                                                        tooltipContent={'Competition Level'}
                                                    >
                                                        Competition Level
                                                    </TextOverflowTooltip>
                                                </Div>
                                                <TooltipWithIcon
                                                    tooltipContent={
                                                        'This metric is calculated based on the number of keywords of each competitor and the number of common keywords of both competitors. If websites have a large number of common keywords, they can be considered competitors.'
                                                    }
                                                />
                                            </Div>
                                        </Div>
                                        <Div className={'EPCTIRCol'}>
                                            <Div className={'EPCTIRHTitle'}>
                                                <Div className={'EPCTIRHText'}>
                                                    <TextOverflowTooltip
                                                        tooltipContent={'Common Keywords'}
                                                    >
                                                        Common Keywords
                                                    </TextOverflowTooltip>
                                                </Div>
                                                <TooltipWithIcon
                                                    tooltipContent={
                                                        "The number of common keywords an analyzed domain and a given competitor are ranking for in Google's paid search results."
                                                    }
                                                />
                                            </Div>
                                        </Div>
                                        <Div className={'EPCTIRCol'}>
                                            <Div className={'EPCTIRHTitle'}>
                                                <Div className={'EPCTIRHText'}>
                                                    <TextOverflowTooltip
                                                        tooltipContent={'Paid Keywords'}
                                                    >
                                                        Paid Keywords
                                                    </TextOverflowTooltip>
                                                </Div>
                                                <TooltipWithIcon
                                                    tooltipContent={
                                                        'The number of keywords a given domain is buying in Google AdWords for ads that appear in paid search results.'
                                                    }
                                                />
                                            </Div>
                                        </Div>
                                        <Div className={'EPCTIRCol'}>
                                            <Div className={'EPCTIRHTitle'}>
                                                <Div className={'EPCTIRHText'}>
                                                    <TextOverflowTooltip
                                                        tooltipContent={
                                                            'Paid Peywords Price ($USD)'
                                                        }
                                                    >
                                                        Paid traffic Price ($USD)
                                                    </TextOverflowTooltip>
                                                </Div>
                                                <TooltipWithIcon
                                                    tooltipContent={
                                                        'Paid keyword traffic price in USD.'
                                                    }
                                                />
                                            </Div>
                                        </Div>
                                        <Div className={'EPCTIRCol'}>
                                            <Div className={'EPCTIRHTitle'}>
                                                <Div className={'EPCTIRHText'}>
                                                    <TextOverflowTooltip
                                                        tooltipContent={'Paid Traffic'}
                                                    >
                                                        Paid Traffic
                                                    </TextOverflowTooltip>
                                                </Div>
                                                <TooltipWithIcon
                                                    tooltipContent={
                                                        'Traffic driven to a given domain via Google AdWords paid search results.'
                                                    }
                                                />
                                            </Div>
                                        </Div>
                                        <Div className={'EPCTIRCol'}>
                                            <Div className={'EPCTIRHTitle'}>
                                                <Div className={'EPCTIRHText'}>
                                                    <TextOverflowTooltip
                                                        tooltipContent={'SE Keywords'}
                                                    >
                                                        SE Keywords
                                                    </TextOverflowTooltip>
                                                </Div>
                                                <TooltipWithIcon
                                                    tooltipContent={
                                                        "The number of keywords bringing users to a given domain via Google's top 100 organic search results."
                                                    }
                                                />
                                            </Div>
                                        </Div>
                                    </Div>
                                    <Div className={'EPCTIBody'}>
                                        {paid_competitor?.map(item => (
                                            <Div
                                                key={item.id}
                                                className={'EPCTIRow EPCTIRBody EPCTIRCol7'}
                                            >
                                                <Div className={'EPCTIRCol'}>
                                                    <Div className={'EPCTIRBText'}>
                                                        <a
                                                            href={`https://${item?.domain}`}
                                                            target="_blank"
                                                            rel="noreferrer"
                                                        >
                                                            {item.domain}
                                                        </a>
                                                    </Div>
                                                </Div>
                                                <Div className={'EPCTIRCol'}>
                                                    <Div className={'EPCTProgress'}>
                                                        <Div className={'EPCTPField'}>
                                                            <Div
                                                                className={'EPCTPFInner'}
                                                                style={{
                                                                    width: `${item.competitionLevel}%`,
                                                                }}
                                                            />
                                                        </Div>
                                                        <Div className={'EPCTPPre'}>
                                                            {item.competitionLevel}%
                                                        </Div>
                                                    </Div>
                                                </Div>
                                                <Div className={'EPCTIRCol'}>
                                                    <Div className={'EPCTIRBText'}>
                                                        {abbreviateNumber(item.commonKeywords)}
                                                    </Div>
                                                </Div>
                                                <Div className={'EPCTIRCol'}>
                                                    <Div className={'EPCTIRBText'}>
                                                        {abbreviateNumber(item.paidKeywords)}
                                                    </Div>
                                                </Div>
                                                <Div className={'EPCTIRCol'}>
                                                    <Div className={'EPCTIRBText'}>
                                                        ${abbreviateNumber(item.paidKeywordsPrice)}
                                                    </Div>
                                                </Div>
                                                <Div className={'EPCTIRCol'}>
                                                    <Div className={'EPCTIRBText'}>
                                                        {abbreviateNumber(item.paidTraffic)}
                                                    </Div>
                                                </Div>
                                                <Div className={'EPCTIRCol'}>
                                                    <Div className={'EPCTIRBText'}>
                                                        {abbreviateNumber(item.seKeywords)}
                                                    </Div>
                                                </Div>
                                            </Div>
                                        ))}
                                    </Div>
                                </Div>
                            </Div>
                        </Fragment>
                    ) : (
                        <NoFound
                            title={'We’re not finding any competitor data!'}
                            description={
                                <Fragment>
                                    <p>
                                        We’re having trouble finding PPC competitor data for your
                                        business. This could result in your competitors not running
                                        Google Ads campaigns.
                                        <br />
                                        We see this as a missed opportunity for your competitors,
                                        but an actionable item for {details?.name} to take advantage
                                        of. Start running a Google Ads campaign to get customers
                                        faster.
                                    </p>
                                </Fragment>
                            }
                        />
                    )}
                    <Div className={'EPCUserComt'} style={{ marginTop: 15 }}>
                        <Div className={'EPCUCInner'}>
                            <Div className={'EPCUCInfo'}>
                                <Div className={'EPCUCIImg'}>
                                    <Image
                                        src={generateImage(user?.image, user?.name)}
                                        displayName={user?.name}
                                        alt={user?.name}
                                    />
                                </Div>
                                <Div className={'EPCUCICmt'}>
                                    Would you like to start running PPC campaigns for{' '}
                                    {details?.name} to get customers faster? We can help!
                                </Div>
                            </Div>
                            <Div className={'EPCUCBtn'}>
                                <Button buttonType={'BlueFillBtn'} onClick={handleGetInTouchOpen}>
                                    Get in touch
                                </Button>
                            </Div>
                        </Div>
                    </Div>
                </Div>
            </Div>
        </Fragment>
    );
};

export default GoogleAds;
