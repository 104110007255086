import React from 'react';

import { SVGIcon } from '../../UIKit/index';

export default function DCLineChart(props) {
    return (
        <SVGIcon {...props} viewBox="0 0 512 243">
            <path
                fill="none"
                stroke="#23D3D3"
                strokeWidth="10"
                d="M4.6,239.5c0,0,77.78-119.59,109.22-68.42s39.65,59.3,39.65,59.3s16.25,16.4,35.93-6.45  s50.37-107.32,50.37-107.32s18.98-57.07,43.12-22.58c11.93,17.7,25.2,34.46,39.69,50.13c0,0,23.38,24.23,32.32,6.31  c8.94-17.92,57.04-120.32,57.04-120.32s18.54-47.21,37.78-12.26s57.7,142.89,57.7,142.89"
            />
        </SVGIcon>
    );
}
