import React from 'react';

import { SVGIcon } from '../../UIKit/index';

export default function DCPhoneSites(props) {
    return (
        <SVGIcon {...props} viewBox="0 0 512 425">
            <g transform="translate(-131.109 -151.665)">
                <path
                    fill="#FF7878"
                    d="M229.79,224.15c0,0-28.23-39.71-53.36-7.45l-44.16,188.1c0,0-3.72,23.99,16.96,27.71   l29.37,1.66L229.79,224.15z"
                />
                <path
                    fill="#FF7878"
                    d="M335.37,208.33l-46.74-38.05c0,0-25.65-6.62-32.68,26.06   c-7.03,32.68-63.29,269.69-63.29,269.69s-4.54,23.16,21.51,31.44l51.7,1.66L335.37,208.33z"
                />
                <path
                    fill="#FF3C3C"
                    d="M421.83,154.97c0,0-57.08-18.44-62.67,49.1l-72.6,318.21c-3.59,25.5,13.25,49.4,38.47,54.6   h19.86l66.38-334.42l39.09,1.24l3.1,13.63c0,0,73.84-33.51,110.44-4.96c21.55,12.92,35.79,35.2,38.47,60.18   c0,0,16.15,93.69-62.05,137.74c0,0-63.28,29.78-117.88,5.58l-23.58,120.99h158.22c0,0,29.78-10.54,35.36-40.95l49.02-250.04   c0,0,9.31-35.99-23.58-52.12C585,217.62,421.83,154.97,421.83,154.97z"
                />
                <path
                    fill="#FF3C3C"
                    d="M454.71,287.75l-20.47,122.23c0,0,81.62,51.99,111.68-48.39c0,0,16.15-64.53-34.13-80.04   C492.56,278.17,472.76,280.32,454.71,287.75z"
                />
            </g>
        </SVGIcon>
    );
}
