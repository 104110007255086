export const TYPES = {
    MY_TEMPLATES: 'mytemplates',
    EMAIL: 'email',
    SMS: 'sms',
    EDOCS: 'edocs',
};

export const SOURCES = {
    TABLE: 'table',
    GRID: 'grid',
};

export const TABS = {
    ALL: {
        id: 0,
        title: 'All Template',
        name: 'all',
        link: '/templates/all',
    },

    EMAIL: {
        id: 1,
        title: 'Email',
        name: TYPES.EMAIL,
        link: '/templates/email',
    },
    SMS: {
        id: 2,
        title: 'SMS',
        name: TYPES.SMS,
        link: '/templates/sms',
    },
};
