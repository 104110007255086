const themeStore = {
    primaryColor: '',
    secondaryColor: '',
    textColor: '',
    notificationCount: 0,
    notify: {
        openNotificationbar: false,
        activeTab: 'upcoming',
    },
};

export default themeStore;
