import React from 'react';

import { Div } from '../../UIKit/index';
import PropTypes from 'prop-types';
import './style.scss';

const Skeleton = props => {
    const {
        className,
        width,
        height,
        marginTop,
        marginBottom,
        marginLeft,
        marginRight,
        circle,
        display,
        style,
    } = props;
    return (
        <Div
            className={`SkeletonMain ${className ? className : ''} ${
                circle ? 'SkeletonCircleMain' : ''
            }`}
            style={{
                width: width || '100%',
                height: height,
                display: display,
                marginTop: marginTop,
                marginBottom: marginBottom,
                marginLeft: marginLeft,
                marginRight: marginRight,
                ...style,
            }}
        />
    );
};

Skeleton.propTypes = {
    className: PropTypes.string,
    width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    marginTop: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    marginBottom: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    marginLeft: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    marginRight: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    circle: PropTypes.bool,
    display: PropTypes.string,
    style: PropTypes.object,
};
export default Skeleton;
