import React from 'react';
import { Page, Text, View, Font, Image } from '@react-pdf/renderer';
import generateImage from 'Utils/generateImage';
import ColorBox, { getInitialsListingSvg } from './downloadUtils/colorBox';
import config from 'config';
import { styles } from './style';
import { instareportFontFamily } from '../components/constant';

Font.register({ ...instareportFontFamily });

const ListingDetailsDownload = ({ listingData, details, listings, user, score }) => {
    return (
        <Page wrap>
            <View fixed style={styles.FixedTopHeight}>
                <Text />
            </View>
            <View style={styles.section}>
                <View style={styles.HeadingView}>
                    <View style={styles.HeadingLogo}>
                        {score?.listings ? <ColorBox score={score?.listings} /> : null}
                        <Text style={styles.heading}>Listings </Text>
                        <Text style={styles.HeadingCaption}>
                            Can potential customers find your business?
                        </Text>
                    </View>
                </View>
                <View style={{ display: 'flex', flexDirection: 'row', margin: 3, padding: 3 }}>
                    <View>
                        <Text style={styles.RTRSectionText}>
                            83% of searches for local businesses on a mobile device result in a call
                            or visit to the business within 24 hours. For the best visibility, it’s
                            important to make sure your listings are accurate across the web with
                            the correct business name, address and phone number.
                        </Text>
                        <Text style={styles.RTRSectionText}>
                            We've scanned {details?.name} to show you exactly how you’re listed in
                            these directories.
                        </Text>
                    </View>
                </View>
                <View>
                    <View style={styles.HeaderDiv} wrap={false}>
                        <Text>Listings Analyzed</Text>
                        <Text>Your Business’s Directory Listings Overview</Text>
                    </View>
                    <View>
                        <View style={styles.LSFieldOverallScore}>
                            <Text style={styles.LSFieldOverallScoreView}>Listings Accuracy</Text>
                            <Text style={styles.LSFieldOverallScoreViewText}>
                                {listingData?.listingsAccuracyTotalPercentage
                                    ? Math.round(listingData?.listingsAccuracyTotalPercentage)
                                    : '-'}
                                %
                            </Text>
                        </View>
                    </View>
                    <View>
                        <View style={styles.LSFieldFixture}>
                            <Text style={styles.LSSubHeading}>Listing Found Overview</Text>
                            <View>
                                <View style={styles.LSFieldFixtureText}>
                                    <Text>Available listings</Text>
                                    <Text>
                                        {listingData?.listingsFoundOverview?.availableListings}
                                    </Text>
                                </View>
                                <View style={styles.LSFieldFixtureCover}>
                                    <View
                                        style={{
                                            backgroundColor: '#d961ff',
                                            height: 5,
                                        }}
                                    />
                                </View>
                            </View>
                            <View>
                                <View style={styles.LSFieldFixtureText}>
                                    <Text>Found listings</Text>
                                    <Text>{listingData?.listingsFoundOverview?.foundListings}</Text>
                                </View>
                                <View style={styles.LSFieldFixtureCover}>
                                    <View
                                        style={{
                                            width:
                                                (parseInt(
                                                    listingData?.listingsFoundOverview
                                                        ?.foundListings,
                                                ) /
                                                    parseInt(
                                                        listingData?.listingsFoundOverview
                                                            ?.availableListings,
                                                    )) *
                                                520,
                                            backgroundColor: '#51c7ff',
                                            height: 5,
                                        }}
                                    />
                                </View>
                            </View>
                            <View>
                                <View style={styles.LSFieldFixtureText}>
                                    <Text>Not found listings</Text>
                                    <Text>
                                        {listingData?.listingsFoundOverview?.notFoundListings}
                                    </Text>
                                </View>
                                <View style={styles.LSFieldFixtureCover}>
                                    <View
                                        style={{
                                            width:
                                                (parseInt(
                                                    listingData?.listingsFoundOverview
                                                        ?.notFoundListings,
                                                ) /
                                                    parseInt(
                                                        listingData?.listingsFoundOverview
                                                            ?.availableListings,
                                                    )) *
                                                520,
                                            backgroundColor: '#f44336',
                                            height: 5,
                                        }}
                                    />
                                </View>
                            </View>
                            <View>
                                <View style={styles.LSFieldFixtureText}>
                                    <Text>Listing With Errors</Text>
                                    <Text>
                                        {listingData?.listingsFoundOverview?.listingsWithErrors}
                                    </Text>
                                </View>
                                <View style={styles.LSFieldFixtureCover}>
                                    <View
                                        style={{
                                            width:
                                                (parseInt(
                                                    listingData?.listingsFoundOverview
                                                        ?.listingsWithErrors,
                                                ) /
                                                    parseInt(
                                                        listingData?.listingsFoundOverview
                                                            ?.availableListings,
                                                    )) *
                                                500,
                                            backgroundColor: '#ffc13a',
                                            height: 5,
                                        }}
                                    />
                                </View>
                            </View>
                        </View>
                        <View style={styles.LSFieldFixture}>
                            <Text style={styles.LSSubHeading}>Listing Accuracy Overview</Text>
                            <View>
                                <View style={styles.LSFieldFixtureText}>
                                    <Text>Business Name</Text>
                                    <Text>
                                        {`${listingData?.listingsAccuracyOverview?.businessNamePercentage}%`}
                                    </Text>
                                </View>
                                <View style={styles.LSFieldFixtureCover}>
                                    <View
                                        style={{
                                            width:
                                                parseInt(
                                                    listingData?.listingsAccuracyOverview
                                                        ?.businessNamePercentage,
                                                ) * 5,
                                            backgroundColor: '#51c7ff',
                                            height: 5,
                                        }}
                                    />
                                </View>
                            </View>
                            <View>
                                <View style={styles.LSFieldFixtureText}>
                                    <Text>Address</Text>
                                    <Text>
                                        {`${listingData?.listingsAccuracyOverview?.addressPercentage}%`}
                                    </Text>
                                </View>
                                <View style={styles.LSFieldFixtureCover}>
                                    <View
                                        style={{
                                            width:
                                                parseInt(
                                                    listingData?.listingsAccuracyOverview
                                                        ?.addressPercentage,
                                                ) * 5,
                                            backgroundColor: '#d961ff',
                                            height: 5,
                                        }}
                                    />
                                </View>
                            </View>
                            <View>
                                <View style={styles.LSFieldFixtureText}>
                                    <Text>Phone Number</Text>
                                    <Text>
                                        {`${listingData?.listingsAccuracyOverview?.phoneNumberPercentage}%`}
                                    </Text>
                                </View>
                                <View style={styles.LSFieldFixtureCover}>
                                    <View
                                        style={{
                                            width:
                                                parseInt(
                                                    listingData?.listingsAccuracyOverview
                                                        ?.phoneNumberPercentage,
                                                ) * 5,
                                            backgroundColor: '#ffc13a',
                                            height: 5,
                                        }}
                                    />
                                </View>
                            </View>
                        </View>
                    </View>
                </View>
            </View>
            {!listings?.length ? (
                <View style={styles.GITSection}>
                    {!(typeof user?.image === 'string') ? (
                        <>
                            {Object.keys(user?.image || {})?.length > 0 ? (
                                <Image
                                    src={generateImage(user?.image, user?.name)}
                                    style={styles.GITUserImage}
                                    alt={'image'}
                                />
                            ) : (
                                <View style={styles.GITSvgImage}>
                                    {getInitialsListingSvg(user?.name)}
                                </View>
                            )}
                        </>
                    ) : (
                        <Image
                            src={{
                                uri: `${config.REACT_APP_API_BASE_URL}/v1/corsproxy/`,
                                method: 'GET',
                                body: '',
                                headers: { 'Target-URL': `${user?.image}` },
                            }}
                            cache={false}
                            style={styles.GITUserImage}
                            alt={'image'}
                        />
                    )}
                    <Text style={styles.GITContent}>
                        Would you like to be found in all these directory listings with accurate
                        business information and start generating more customers for {details?.name}
                        ? We can help!
                    </Text>
                </View>
            ) : null}
            <View fixed style={styles.FixedBottomHeight}>
                <Text />
            </View>
        </Page>
    );
};

export default ListingDetailsDownload;
