import React from 'react';

import { SVGIcon } from '../../UIKit/index';

export default function DCUploadFile(props) {
    return (
        <SVGIcon {...props} viewBox="0 0 512 383">
            <g>
                <path
                    d="M416.32,106.34c-2.81-0.07-5.62,0.07-8.41,0.4h-0.4c-0.8,0-1.6-0.4-1.6-1.2
                    c-17.63-44.63-68.09-66.51-112.72-48.89c-5.18,2.05-10.15,4.58-14.84,7.58c-0.32,0.31-0.76,0.45-1.2,0.4h-0.8
                    c-0.57-0.18-1.02-0.63-1.2-1.2C257.89,18.3,207.31-4.3,162.17,12.96c-33.88,12.96-56.26,45.48-56.24,81.75
                    c0.08,3.49,0.35,6.97,0.8,10.43c0,0.8,0,1.2-0.4,1.6c-0.32,0.31-0.76,0.45-1.2,0.4h-0.4c-3.46-0.45-6.94-0.72-10.43-0.8
                    c-48.17-0.01-87.24,39.03-87.25,87.2C7.04,240.96,44.9,279.7,92.3,280.77l0.4-0.4h50.52l28.47-32.08H93.5
                    c-28.87-4.01-52.13-25.24-52.13-54.14c0.1-29.48,23.85-53.42,53.33-53.73c2.15-0.05,4.29,0.08,6.42,0.4h4.41
                    c20.27-0.2,36.53-16.79,36.33-37.06c-0.01-1.28-0.09-2.57-0.24-3.84c-0.33-2.12-0.47-4.27-0.4-6.42
                    c0.01-29.45,23.88-53.32,53.33-53.31c22.02,0,41.77,13.54,49.72,34.07c3.98,10.14,12.16,18.04,22.43,21.66
                    c3.61,1.25,7.4,1.92,11.22,2c6.82-0.01,13.49-1.95,19.25-5.61c8.57-5.25,18.42-8.02,28.47-8.02c21.72-0.1,41.29,13.1,49.32,33.28
                    c5.57,13.73,18.87,22.75,33.69,22.86h3.61c1.6,0,3.21-0.4,4.81-0.4c29.62,0.37,53.41,24.52,53.33,54.14
                    c0,28.87-23.26,50.12-52.13,54.14h-74.15l24.06,32.08h51.33c47.47-0.79,85.52-39.55,85.43-87.02
                    C503.81,145.15,464.54,106.59,416.32,106.34z"
                    fill="#D9EAFF"
                />
                <path
                    d="M255.11,178.52L171.7,280.78h31.68c1.2,0,3.61,1.6,3.61,2.8v93.44h96.24v-93.43
                    c0-1.2,2-2.8,3.21-2.8h32.08L255.11,178.52z"
                    fill="#2B88F8"
                />
                <path
                    d="M418.31,286.79h-50.93c-2.21,0-4.01-1.8-4.01-4.01c0-2.21,1.8-4.01,4.01-4.01h50.93
                    c47.05-1.2,84.22-40.32,83.02-87.37c-1.18-46.13-38.86-82.95-85.01-83.05c-2.8,0-5.61,0.4-8.02,0.4c-1.75,0.25-3.43-0.76-4.02-2.42
                    c-12.97-32.47-44.42-53.75-79.38-53.71c-16.19-0.07-32.06,4.52-45.71,13.23c-0.92,0.7-2.14,0.85-3.21,0.4
                    c-1.15-0.35-2.05-1.25-2.41-2.41c-16.83-44.07-66.2-66.15-110.27-49.32c-33.09,12.64-54.95,44.38-54.94,79.8
                    c0.08,3.35,0.35,6.7,0.8,10.02c0.15,2.28-1.57,4.25-3.85,4.41c-0.19,0.01-0.37,0.01-0.56,0c-3.32-0.45-6.67-0.72-10.02-0.8
                    c-46.95-0.13-85.12,37.82-85.26,84.77c-0.13,46.52,37.14,84.5,83.66,85.25h46.51c2.21,0,4.01,1.8,4.01,4.01
                    c0,2.21-1.8,4.01-4.01,4.01H93.1c-51.38-0.79-92.38-43.08-91.59-94.46c0.78-50.89,42.31-91.7,93.2-91.6
                    c2.01-0.07,4.03,0.07,6.01,0.4c0-2-0.4-4.01-0.4-6.01c-0.03-51.57,41.76-93.41,93.34-93.43c37.03-0.02,70.58,21.85,85.51,55.74
                    c13.92-7.94,29.68-12.09,45.71-12.03c37.14-0.1,70.82,21.78,85.84,55.74c2,0,4.01-0.4,6.01-0.4c51.61,0.09,93.37,42,93.28,93.6
                    c-0.09,50.85-40.83,92.3-91.68,93.26L418.31,286.79z"
                    fill="none"
                />
                <path
                    d="M93.1,251.11L93.1,251.11c-31.78-0.44-57.18-26.57-56.74-58.34
                    c0.44-31.46,26.07-56.74,57.54-56.74c2.41,0,4.81,0.4,6.81,0.4c1.2,0,2.41,0.4,3.61,0.4c17.57,0.03,31.84-14.19,31.87-31.76
                    c0-1.18-0.06-2.36-0.19-3.53c-0.32-2.26-0.45-4.54-0.4-6.81c-0.23-31.67,25.26-57.52,56.93-57.75c0.01,0,0.01,0,0.02,0h0.4
                    c24.05-0.05,45.61,14.81,54.14,37.29c3.49,8.84,10.65,15.72,19.63,18.85c9.01,2.78,18.77,1.62,26.87-3.21
                    c9.28-5.74,19.97-8.8,30.88-8.82c8.27-0.15,16.48,1.49,24.06,4.81c1.99,0.89,2.89,3.22,2.01,5.21c-0.89,1.99-3.22,2.89-5.21,2.01
                    c-6.64-2.64-13.71-4-20.85-4.01c-9.35,0.11-18.49,2.74-26.47,7.62c-10.08,6.26-22.42,7.73-33.69,4.01
                    c-11.36-3.69-20.39-12.42-24.46-23.66c-7.38-19.28-25.87-32.04-46.51-32.08h-0.4c-27.33,0.17-49.37,22.4-49.32,49.72
                    c-0.05,2.01,0.08,4.03,0.4,6.01c1.43,12.09-2.69,24.19-11.22,32.88c-8.63,8.64-20.78,12.78-32.88,11.22c-1.99-0.31-4-0.44-6.01-0.4
                    c-27.35-0.03-49.55,22.11-49.59,49.46c-0.03,27.09,21.7,49.17,48.78,49.58l73.78-0.4l0,0c2.21,0,4.01,1.8,4.01,4.01
                    c0.13,2.09-1.46,3.88-3.55,4.01c-0.15,0.01-0.31,0.01-0.46,0L93.1,251.11z M416.72,251.11L416.72,251.11l-73.38-0.4
                    c-2.21,0-4.01-1.8-4.01-4.01c0.15-2.15,1.86-3.86,4.01-4.01l0,0l73.38,0.4c27.35-0.41,49.19-22.91,48.78-50.26
                    c-0.41-27.08-22.49-48.81-49.58-48.78h-8.82c-16.53,0.04-31.43-9.94-37.7-25.24c-0.7-2.13,0.46-4.43,2.59-5.13
                    c2.03-0.67,4.22,0.35,5.03,2.32c5.11,12.1,16.94,19.98,30.07,20.05h3.61c2,0,4.01-0.4,5.61-0.4c31.78-0.33,57.81,25.16,58.14,56.94
                    c0.33,31.78-25.16,57.81-56.94,58.14c-0.21,0.22-0.5,0.35-0.8,0.37H416.72z"
                    fill="none"
                />
                <path
                    d="M305.23,383.03H204.98c-2.09,0.13-3.88-1.46-4.01-3.55c-0.01-0.15-0.01-0.31,0-0.46v-92.23
                    h-33.69c-1.58,0.01-3.01-0.94-3.61-2.41c-0.69-1.43-0.54-3.13,0.4-4.41l87.82-107.47c0.83-0.92,1.98-1.49,3.21-1.6l0,0
                    c1.28-0.06,2.49,0.54,3.21,1.6l87.82,107.47c0.94,1.28,1.09,2.98,0.4,4.41c-0.73,1.35-2.08,2.25-3.61,2.41h-33.28v92.23
                    C309.46,381.31,307.53,383.06,305.23,383.03z M208.99,375.01h92.23v-92.23c-0.12-2.09,1.47-3.89,3.56-4.01
                    c0.15-0.01,0.3-0.01,0.45,0h28.87l-79.4-97.03l-79.4,97.03h29.27c2.09-0.12,3.89,1.47,4.01,3.56c0.01,0.15,0.01,0.3,0,0.45v92.23
                    L208.99,375.01z"
                    fill="none"
                />
            </g>
            <circle cx="363.77" cy="318.6" r="8.64" fill="#FFFFFF" stroke="#D9EAFF" />
        </SVGIcon>
    );
}
