import React from 'react';

import { SVGIcon } from '../../UIKit/index';

export default function DCHeartFill(props) {
    return (
        <SVGIcon {...props} viewBox="0 0 512 512">
            <path
                d="M364,32c-40.9,0-80,17-108,46.9c-55.9-59.6-149.5-62.7-209.2-6.8C17,100.1,0,139.1,0,180
	c0,147.4,237.7,292,248,297.7c5.1,3,11.4,3,16.5,0C274.3,472,512,327.4,512,180C511.9,98.3,445.7,32.1,364,32z"
            />
        </SVGIcon>
    );
}
