import React from 'react';

import { SVGIcon } from '../../UIKit/index';

export default function DCSVGFile(props) {
    return (
        <SVGIcon {...props} viewBox="0 0 512 512">
            <path
                d="m392 488h-272a48 48 0 0 1 -48-48v-368a48 48 0 0 1 48-48h224l96 96v320a48 48 0 0 1 -48 48z"
                fill="#005eff33"
            />
            <path
                d="m72 360h368a0 0 0 0 1 0 0v80a48 48 0 0 1 -48 48h-272a48 48 0 0 1 -48-48v-80a0 0 0 0 1 0 0z"
                fill="#005eff"
            />
            <path d="m440 120h-48a48 48 0 0 1 -48-48v-48z" fill="#005eff50" />
            <path
                d="m320 288h-128a8 8 0 0 1 -8-8v-128a8 8 0 0 1 8-8h128a8 8 0 0 1 8 8v128a8 8 0 0 1 -8 8zm-120-16h112v-112h-112z"
                fill="#005eff50"
            />
            <g fill="#005eff">
                <path d="m176 136h32v32h-32z" />
                <path d="m304 136h32v32h-32z" />
                <path d="m304 264h32v32h-32z" />
                <path d="m176 264h32v32h-32z" />
            </g>
            <path
                d="m184 400h16a8 8 0 0 0 0-16h-16a24 24 0 0 0 0 48 8 8 0 0 1 0 16h-16a8 8 0 0 0 0 16h16a24 24 0 0 0 0-48 8 8 0 0 1 0-16z"
                fill="#fff"
            />
            <path
                d="m265.94 384.239a8 8 0 0 0 -9.7 5.821l-8.24 32.955-8.239-32.955a8 8 0 1 0 -15.522 3.88l16 64a8 8 0 0 0 15.522 0l16-64a8 8 0 0 0 -5.821-9.701z"
                fill="#fff"
            />
            <path
                d="m344 416h-16a8 8 0 0 0 0 16h7.049c-2.252 9.217-8.235 16-15.049 16-8.673 0-16-10.991-16-24s7.327-24 16-24a10.71 10.71 0 0 1 4.589 1.057 8 8 0 0 0 6.822-14.473 26.6 26.6 0 0 0 -11.411-2.584c-17.645 0-32 17.944-32 40s14.355 40 32 40 32-17.944 32-40a8 8 0 0 0 -8-8z"
                fill="#fff"
            />
        </SVGIcon>
    );
}
