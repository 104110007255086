import React from 'react';

import { SVGIcon } from '../../UIKit/index';

export default function DCUserPlaceholder(props) {
    return (
        <SVGIcon {...props} viewBox="0 0 60.731 60.731">
            <path d="M2.804,57.284c1.739-0.933,3.466-1.896,5.248-2.742c1.729-0.827,3.516-1.535,5.284-2.283   c1.663-0.697,3.345-1.359,4.997-2.078c0.282-0.123,0.569-0.426,0.687-0.714c0.368-0.921,0.682-1.879,0.974-2.833   c0.221-0.721,0.364-1.435,1.293-1.626c0.233-0.048,0.521-0.449,0.556-0.724c0.189-1.387,0.332-2.782,0.428-4.184   c0.023-0.296-0.131-0.68-0.334-0.907c-1.224-1.369-1.897-2.947-2.18-4.763c-0.143-0.91-0.649-1.752-0.914-2.648   c-0.348-1.204-0.638-2.418-0.914-3.642c-0.1-0.439-0.042-0.915-0.114-1.364c-0.107-0.656-0.087-1.164,0.702-1.416   c0.218-0.07,0.4-0.591,0.407-0.911c0.04-1.948,0.014-3.898,0.025-5.847c0.01-1.195-0.002-2.396,0.084-3.59   c0.168-2.286,1.509-3.898,3.191-5.283c2.455-2.026,5.396-2.969,8.418-3.687c1.585-0.372,3.202-0.6,4.817-0.857   c0.429-0.07,0.876-0.012,1.316-0.012c0.07,0.113,0.144,0.223,0.219,0.334c-0.34,0.243-0.714,0.448-1.006,0.738   c-0.657,0.657-0.593,1.119,0.112,1.711c1.455,1.235,2.915,2.468,4.331,3.746c1.246,1.12,1.731,2.589,1.733,4.238   c0.003,2.838,0.005,5.675,0.003,8.516c-0.003,0.582-0.044,1.154,0.678,1.412c0.152,0.054,0.314,0.373,0.301,0.556   c-0.123,1.453-0.278,2.904-0.434,4.354c-0.005,0.055-0.019,0.122-0.05,0.162c-1.305,1.904-1.474,4.242-2.404,6.288   c-0.039,0.078-0.055,0.169-0.114,0.233c-1.729,2.039-1.19,4.488-1.223,6.829c-0.005,0.237,0.164,0.611,0.355,0.688   c0.71,0.312,0.881,0.92,1.077,1.561c0.308,1.011,0.613,2.025,1.045,2.983c0.157,0.356,0.634,0.655,1.028,0.804   c5.322,2.043,10.591,4.208,15.671,6.811c1.016,0.525,1.892,1.164,2.63,1.926V1.528c-20.251,0-40.493,0-60.731,0v57.675   C0.818,58.443,1.777,57.834,2.804,57.284z" />
        </SVGIcon>
    );
}
