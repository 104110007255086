import React from 'react';

import { SVGIcon } from '../../UIKit/index';

export default function DCGif(props) {
    return (
        <SVGIcon {...props} viewBox="0 0 512 512">
            <path d="M441.58,443.76H70.31C31.47,443.76-.1,412.19-.1,373.34V139.15C-.1,100.3,31.47,68.73,70.31,68.73H441.58c38.84,0,70.42,31.57,70.42,70.42v234.2c0,38.84-31.57,70.42-70.42,70.42ZM70.31,107.14c-17.64,0-32.01,14.37-32.01,32.01v234.2c0,17.64,14.37,32.01,32.01,32.01H441.58c17.64,0,32.01-14.37,32.01-32.01V139.15c0-17.64-14.37-32.01-32.01-32.01H70.31Z" />
            <g>
                <g>
                    <path d="M179.15,327.13h-35.44c-22.8,0-41.35-18.55-41.35-41.35v-59.07c0-22.8,18.55-41.35,41.35-41.35h35.44c9.78,0,17.72,7.94,17.72,17.72s-7.94,17.72-17.72,17.72h-35.44c-3.26,0-5.91,2.64-5.91,5.91v59.07c0,3.26,2.65,5.91,5.91,5.91h17.72v-6.9c-6.9-2.46-11.81-9-11.81-16.73,0-9.78,7.94-17.72,17.72-17.72h11.81c9.78,0,17.72,7.94,17.72,17.72v41.35c0,9.78-7.94,17.72-17.72,17.72Z" />
                    <path d="M255.95,327.13c-9.78,0-17.72-7.94-17.72-17.72v-106.33c0-9.78,7.94-17.72,17.72-17.72s17.72,7.94,17.72,17.72v106.33c0,9.78-7.94,17.72-17.72,17.72Z" />
                    <path d="M326.84,327.13c-9.78,0-17.72-7.94-17.72-17.72v-82.7c0-22.8,18.55-41.35,41.35-41.35h41.35c9.78,0,17.72,7.94,17.72,17.72s-7.94,17.72-17.72,17.72h-41.35c-3.26,0-5.91,2.64-5.91,5.91v82.7c0,9.78-7.94,17.72-17.72,17.72Z" />
                </g>
                <path d="M391.82,279.87h-64.98c-9.78,0-17.72-7.94-17.72-17.72s7.94-17.72,17.72-17.72h64.98c9.78,0,17.72,7.94,17.72,17.72s-7.94,17.72-17.72,17.72Z" />
            </g>
        </SVGIcon>
    );
}
