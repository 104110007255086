import React from 'react';

import { SVGIcon } from '../../UIKit/index';

export default function DCDownload(props) {
    return (
        <SVGIcon {...props} viewBox="0 0 512 512">
            <path d="m225.556 354.445 145-145-48.333-48.333-64.444 64.444v-225.556h-64.444v225.556l-64.444-64.444-48.333 48.333z" />
            <path d="m0 386.667h451.111v64.444h-451.111z" />
        </SVGIcon>
    );
}
