import { globalConversationsActions } from 'Contexts/Actions';
import { GlobalConversationsContext } from 'Contexts/Context';
import { globalConversationsReducer } from 'Contexts/Reducer';
import { globalConversationsStore } from 'Contexts/Store';
import React, { useReducer } from 'react';

const GlobalConversationsContextProvider = props => {
    const [state, dispatch] = useReducer(globalConversationsReducer, globalConversationsStore);

    const value = {
        conversationsSocket: state.conversationsSocket,

        setConversationsSocket: value => {
            dispatch({ type: globalConversationsActions.SET_SOCKET, value });
        },
    };

    return (
        <GlobalConversationsContext.Provider value={value}>
            {props.children}
        </GlobalConversationsContext.Provider>
    );
};

export default GlobalConversationsContextProvider;
