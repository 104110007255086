import React from 'react';

import { SVGIcon } from '../../UIKit/index';

export default function DCCallRail(props) {
    return (
        <SVGIcon {...props} viewBox="0 0 233 233">
            <g transform="translate(-194 -10659)">
                <circle
                    cx="116.5"
                    cy="116.5"
                    r="116.5"
                    transform="translate(194 10659)"
                    fill="#398bef"
                />
                <g transform="translate(-57 -10)">
                    <path
                        d="M489.963,10861.447s-3.375,3.832-6.933,0-34.372-70.545-34.372-70.545l-9.856-24.727s-3.732-12.684,0-21.94,13.639-37.122,44.83-38.188,43.762,21.348,43.762,21.348,8.183,7.828,8.42,28.819a99.863,99.863,0,0,1-6.879,25.38C522.769,10796.655,489.963,10861.447,489.963,10861.447Z"
                        transform="translate(-119.143 0.99)"
                        fill="#fff"
                    />
                    <path
                        d="M481.49,10722.763s-.274-4.926-7.025-2.463-13.867,7.115-14.688,18.884,12.681,52.364,34.12,57.931c3.1.777,6.032,1.861,8.877.528a6.333,6.333,0,0,0,4.625-4.725,30.692,30.692,0,0,0-10.035-24.723c.182.091-3.284-1-9.305,5.382.182.091-11.039-10.674-11.13-26.273,0,.091,10.4-.729,10.583-6.478S481.49,10722.763,481.49,10722.763Z"
                        transform="translate(-115.41 3.419)"
                        fill="#398bef"
                    />
                </g>
            </g>
        </SVGIcon>
    );
}
