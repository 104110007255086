import React, { useState, useEffect, useCallback, useRef } from 'react';

import { GridContextProvider, GridDropZone, GridItem } from 'react-grid-dnd';
import { Link } from 'react-router-dom';
import { Button, Dialog, Div, IconButton, Span, Strong, ToolTip, UserInfo } from 'UIKit/index';
import { getAccountType } from 'Utils/auth';
import areValidScopes from 'Utils/scopeUtils';
import AccessScopes from 'Constants/scopes';
import { useInfiniteQuery } from 'react-query';
import { getAccounts } from 'apis/subaccounts/index.api';
import useToast from 'Modules/Toasts';
import { AutoComplete } from 'Components';
import generateImage from 'Utils/generateImage';
import { ssoLogin } from 'apis/login/index.api';
import { getUserConfigs, getUserDetails } from 'Utils/localStorageUtils';
import DCStarFillIcon from 'Assets/icons/DCStarFill';
import DCStoreFill from 'Assets/icons/DCStoreFill';
import DCFillLock from 'Assets/icons/DCFillLock';
import 'Assets/styles/slick.scss';
import './style.scss';

const MenuList = ({
    open,
    handleClose,
    desktopMenu,
    handleFavoriteMenu,
    reorderItemState,
    handleReorderItems,
    handleReorderMenuApps,
}) => {
    const { showToast } = useToast();
    const [access, setAccess] = useState(false);
    const [loading, setLoading] = useState(false);
    const [ssoSearchText, setSsoSearchText] = useState('');
    const headerRef = useRef();
    const bodyRef = useRef();
    const footerRef = useRef();
    const [showSSO, setShowSSO] = useState(false);

    const {
        data: accounts,
        isLoading: isLoadingAccounts,
        isFetchingNextPage: isFetchingAccountNextPage,
        hasNextPage: hasAccountNextPage,
        fetchNextPage: fetchAccountNextPage,
    } = useInfiniteQuery(
        ['sso-accounts', ssoSearchText],
        ({ pageParam = 1 }) => getAccounts({
                limit: 25,
                page: pageParam,
                search: ssoSearchText || undefined,
                pending: false,
                all: true,
            }),
        {
            staleTime: 1000 * 60 * 10, // 10 minutes
            cacheTime: 1000 * 60 * 15, // 15 minutes
            retry: 2,
            getNextPageParam: lastPage => {
                if (lastPage?.pagination?.next_page) {
                    return lastPage.pagination.next_page;
                } else return undefined;
            },
            onError: err => {
                showToast({
                    type: 'error',
                    duration: 1500,
                    message: err?.message || err || 'Failed to load sub-accounts for sso',
                });
            },
            enabled: getUserDetails()?.is_owner,
        },
    );

    useEffect(() => {
        (() => {
            const templateAccess = areValidScopes(AccessScopes.root.STORE.text);
            setAccess(templateAccess);
            setLoading(false);

            const ssoConfig = getUserConfigs()?.configurations?.find(
                config => config.type === 'preferences',
            )?.data?.sso;

            setShowSSO(ssoConfig?.app_drawer);
        })();
    }, []);

    const handleSSO = useCallback(
        async accountId => {
            try {
                const response = await ssoLogin(accountId);
                if (response.success) {
                    window.open(response.data.url, '_blank');
                }
            } catch (err) {
                showToast({
                    type: 'error',
                    message: err || 'Could not login into sub-account',
                });
            }
        },
        [showToast],
    );

    const generateAccountOptions = useCallback(() => {
        let temp = [];
        accounts?.pages?.forEach(page => {
            page.data.forEach(account => {
                temp.push({
                    id: account.id,
                    label: (
                        <Button
                            buttonClass={'ACSingleOption DropdownOptionsBtn'}
                            onClick={() => {
                                handleSSO(account.id);
                            }}
                        >
                            <UserInfo
                                name={account.name || account?.business?.name}
                                image={generateImage(account.logo, account.name)}
                                email={account.email || account?.owner?.email}
                            />
                        </Button>
                    ),
                    value: account.name || account?.business?.name,
                });
            });
        });
        return temp;
    }, [accounts, handleSSO]);

    return (
        <Dialog
            dialogType={'rightSlide'}
            open={open}
            blur={false}
            onClose={handleClose}
            classes={{
                root: 'MenuListDialog',
                row: `MenuListRightSidebar`,
            }}
        >
            <Div className={'MenuList'}>
                {getAccountType() === 'main' && showSSO && getUserDetails().is_owner && (
                    <AutoComplete
                        generateRows={generateAccountOptions}
                        isAPICall
                        singleSelection
                        searchValue={ssoSearchText}
                        searchAPI={val => setSsoSearchText(val)}
                        hasNextPage={hasAccountNextPage}
                        loading={isLoadingAccounts || isFetchingAccountNextPage}
                        getMoreOptions={fetchAccountNextPage}
                        itemHeight={56}
                        placeholder={'Search sub-accounts'}
                        customMessage={'No Sub-Account Found'}
                        height={
                            (headerRef.current?.offsetHeight || 0) +
                            (bodyRef.current?.offsetHeight || 0) +
                            (footerRef.current?.offsetHeight || 0)
                        }
                        type="search"
                        searchBarClass={'MenuBarSearchBarClass'}
                        searchBoxPlaceholder={`Search Sub-Accounts`}
                        autoFocus={false}
                    />
                )}
                <Div className={'MenuListHeader'} reference={headerRef}>
                    <Div className={'MLHTitle'}>All Apps</Div>
                    <Button buttonClass={'MLHBtn'} onClick={handleReorderItems}>
                        {reorderItemState === false ? 'Edit' : 'Done'}
                    </Button>
                </Div>
                <Div className={'MenuListBody'} reference={bodyRef}>
                    <GridContextProvider onChange={handleReorderMenuApps}>
                        <GridDropZone
                            boxesPerRow={3}
                            rowHeight={121}
                            className="MLBInner"
                            id="left"
                            disableDrag={!reorderItemState}
                        >
                            {desktopMenu.map(item => (
                                <GridItem
                                    key={item.name}
                                    className={
                                        'MHIMLItem ' + (reorderItemState === true ? ' reorder' : '')
                                    }
                                    style={{
                                        width: 84,
                                    }}
                                >
                                    {reorderItemState === true ? (
                                        <IconButton
                                            customClass={
                                                'MHIMLIFav ' + (item.isFavorite ? 'selected' : '')
                                            }
                                            onClick={() => handleFavoriteMenu(item)}
                                        >
                                            <DCStarFillIcon fontSize="inherit" />
                                        </IconButton>
                                    ) : null}

                                    {item.isLock ? (
                                        <ToolTip
                                            content={
                                                'You do not have the permissions needed to access this app. Please reach out to the account owner for help.'
                                            }
                                            placement={'left-center'}
                                        >
                                            <Link
                                                to={item.link}
                                                className={
                                                    'MHIMLILink ' +
                                                    (reorderItemState === true ? ' reorder' : '')
                                                }
                                                disabled={reorderItemState}
                                                onClick={handleClose}
                                                style={{ pointerEvents: 'none' }}
                                            >
                                                <Span className={'MHIMLILIcon'}>
                                                    {item.icon}
                                                    <Strong className={'MHIMLILock'}>
                                                        <DCFillLock />
                                                    </Strong>
                                                </Span>
                                                <Span className={'MHIMLITitle'}>{item.label}</Span>
                                            </Link>
                                        </ToolTip>
                                    ) : (
                                        <Link
                                            to={item.link}
                                            className={
                                                'MHIMLILink ' +
                                                (reorderItemState === true ? ' reorder' : '')
                                            }
                                            disabled={reorderItemState}
                                            onClick={handleClose}
                                        >
                                            {item.name === 'conversations' ? (
                                                <Span className={'MHIMLILIcon'}>
                                                    {item.icon}
                                                    <div className="MHIMLwrapper">
                                                        <div className="MHIMLbadge">BETA</div>
                                                    </div>
                                                </Span>
                                            ) : (
                                                <Span className={'MHIMLILIcon'}>{item.icon}</Span>
                                            )}
                                            <Span className={'MHIMLITitle'}>{item.label}</Span>
                                        </Link>
                                    )}
                                </GridItem>
                            ))}
                        </GridDropZone>
                    </GridContextProvider>
                </Div>
                {getAccountType() === 'main' && access && !loading && (
                    <Div className={'MenuListFooter'} reference={footerRef}>
                        <Link to={'/store'} onClick={handleClose}>
                            <DCStoreFill />
                            <Span>Fulfillment Center</Span>
                        </Link>
                    </Div>
                )}
            </Div>
        </Dialog>
    );
};

export default MenuList;
