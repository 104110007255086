import React from 'react';

import { SVGIcon } from '../../UIKit/index';

export default function DCSMS(props) {
    return (
        <SVGIcon {...props} viewBox="0 0 512 512">
            <path d="M238.19,419.49c0,0-14.27,20.92-42.65,39.24c0,0-12.65,5.68-12,20.27c0,0,2.11,15.24,19.62,16.7  c0,0,32.92-0.81,66.49-11.03c0,0,79.3-19.05,115.62-94.3c0,0,59.03-31.22,82.38-73.38c0,0,34.59-45.51,28.54-111.68  c0,0,0.71-68.32-71.35-126.7c0,0-74.81-67.89-204.11-53.19c0,0-90.38,11.68-146.59,71.35c0,0-52.32,48.86-53.62,117.19  c0,0-13.41,70.92,64.43,143.57c0,0,56.68,55.22,153.16,62.68l7.7-36.95c0,0-39.24,0.22-93.08-24.11c0,0-54.38-27.57-74.7-65.62  c0,0-41.08-61.41-8.22-127.14c0,0,31.51-78.7,131.41-99.46c0,0,78.16-20.59,150.16,10.86c0,0,88.16,35.41,105.03,110.65  c0,0,33.62,98.38-83.78,166.32c0,0-14.59,6.81-17.19,13.3c0,0-24.97,59.19-90.81,78.97v-0.76c0,0,27.89-36.86,25.46-46.59  c0,0,0-13.62-16.22-16.86c0,0-22,1.41-28.05,0.43L238.19,419.49z" />
        </SVGIcon>
    );
}
