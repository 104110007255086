import React from 'react';

import { SVGIcon } from '../../UIKit/index';

export default function DCDashboard(props) {
    return (
        <SVGIcon {...props} viewBox="0 0 512 512">
            <path d="M452.72,467.62H60.67c-32.18-0.02-58.25-26.1-58.28-58.28V59.67      C2.42,27.5,28.5,1.42,60.67,1.39h392.05C484.9,1.42,510.98,27.5,511,59.67v349.67C510.98,441.52,484.9,467.59,452.72,467.62z       M60.67,33.14c-14.65,0-26.53,11.88-26.53,26.53v349.67c0.03,14.64,11.9,26.49,26.53,26.49h392.05      c14.62-0.02,26.46-11.87,26.49-26.49V59.67c0-14.64-11.85-26.51-26.49-26.53H60.67z" />
            <path d="M495.1,104.04H18.27c-8.77,0-15.87-7.11-15.87-15.87c0-8.77,7.11-15.87,15.87-15.87H495.1      c8.77,0,15.87,7.11,15.87,15.87C510.98,96.94,503.87,104.04,495.1,104.04z" />
            <path d="M252.04,322.17c7.4,0,13.4-6,13.4-13.4c0-7.4-6-13.4-13.4-13.4l-60.68-44.63l47.28,58.96      C239.14,316.73,244.99,322.17,252.04,322.17z" />
            <rect x="179.48" y="361.71" width="133.74" height="31.75" />
            <path d="M362.3,197.99c-63.99-64.11-167.84-64.21-231.96-0.22c-51.85,51.75-63.1,131.61-27.55,195.67      h30.75l22.4-12.95l-9.91-17.14l-28.82,16.64c-10.48-20.44-15.92-43.09-15.87-66.06c0-4.22,0.18-8.37,0.54-12.49l34.38,6.05      l3.45-19.5l-34.76-6.12c6.15-26.98,19.88-51.63,39.57-71.07l23.56,28.07l15.17-12.72l-23.76-28.28      c22.37-16.83,49.16-26.79,77.1-28.66v37.55h19.8v-37.55c27.94,1.87,54.72,11.83,77.1,28.66l-23.92,28.3l15.17,12.72l23.56-28.07      c19.69,19.43,33.42,44.09,39.57,71.07l-34.76,6.12l3.45,19.5l34.35-6.05c0.36,4.13,0.54,8.28,0.54,12.49      c0.05,22.97-5.4,45.62-15.87,66.06l-28.82-16.64l-9.91,17.14l22.4,12.95h30.66c35.39-64.01,24.16-143.71-27.53-195.45      L362.3,197.99z" />
        </SVGIcon>
    );
}
