import React from 'react';

import { SVGIcon } from '../../UIKit/index';

export default function DCNoOS(props) {
    return (
        <SVGIcon {...props} viewBox="0 0 512 512">
            <ellipse cx="258.5" cy="471.5" rx="133.5" ry="25.5" fill="#f5f6f7" />
            <circle
                cx="257.17"
                cy="254.5"
                r="213.5"
                fill="#fff"
                stroke="#707070"
                strokeMiterlimit="10"
                strokeWidth="3"
            />
            <rect
                x="89.61"
                y="304.65"
                width="110.97"
                height="4.49"
                transform="translate(-110.91 83.96) rotate(-23.65)"
                fill="#e8eef6"
                stroke="#e8eef6"
                strokeMiterlimit="10"
            />
            <path
                d="M118.77,215.31a34,34,0,0,1-5.43,10l.19.1h0A31.43,31.43,0,0,0,118.77,215.31Z"
                fill="#989faf"
            />
            <rect
                x="59.2"
                y="126.44"
                width="224.74"
                height="297.8"
                rx="4"
                ry="4"
                transform="translate(-91.04 84.85) rotate(-22.12)"
                fill="#fff"
                stroke="#707070"
                strokeMiterlimit="10"
                strokeWidth="3"
            />
            <rect
                x="69.66"
                y="257.34"
                width="110.97"
                height="4.49"
                transform="translate(-90.26 68.15) rotate(-22.63)"
                fill="#e3e3e4"
                stroke="#e3e3e4"
                strokeMiterlimit="10"
            />
            <rect
                x="76.12"
                y="273.19"
                width="110.97"
                height="4.49"
                transform="translate(-95.87 71.86) rotate(-22.63)"
                fill="#e3e3e4"
                stroke="#e3e3e4"
                strokeMiterlimit="10"
            />
            <rect
                x="82.59"
                y="289.04"
                width="110.97"
                height="4.49"
                transform="translate(-101.47 75.57) rotate(-22.63)"
                fill="#e3e3e4"
                stroke="#e3e3e4"
                strokeMiterlimit="10"
            />
            <rect
                x="89.59"
                y="305.04"
                width="110.97"
                height="4.49"
                transform="translate(-107.09 79.5) rotate(-22.63)"
                fill="#e3e3e4"
                stroke="#e3e3e4"
                strokeMiterlimit="10"
            />
            <path
                d="M131.05,202.82c-7-35.95-39.62-33.72-39.62-33.72h-.56v11.24c22.47,0,27.2,16.72,27.2,16.72a22.14,22.14,0,0,1,2.19,10.25h11.06a39.7,39.7,0,0,1-8,23.13l.11.06C133.6,217.73,131.05,202.82,131.05,202.82Z"
                fill="#e3e3e4"
            />
            <path
                d="M93.12,169.1a38.21,38.21,0,1,0,38.2,38.21A38.21,38.21,0,0,0,93.12,169.1Zm0,65.17a27,27,0,1,1,27-27A27,27,0,0,1,93.12,234.27Z"
                fill="none"
                stroke="#707070"
                strokeMiterlimit="10"
                strokeWidth="2"
            />
            <rect
                x="103.04"
                y="333.06"
                width="77.09"
                height="21.33"
                rx="1.01"
                ry="1.01"
                transform="translate(-121.31 80.89) rotate(-22.62)"
                fill="none"
                stroke="#707070"
                strokeMiterlimit="10"
                strokeWidth="2"
            />
            <rect
                x="105.17"
                y="343.7"
                width="21.74"
                height="21.33"
                rx="1"
                ry="1"
                transform="translate(-127.36 71.88) rotate(-22.62)"
                fill="#707070"
                stroke="#707070"
                strokeMiterlimit="10"
                strokeWidth="2"
            />
            <rect
                x="114.99"
                y="362.93"
                width="77.09"
                height="21.33"
                rx="1.01"
                ry="1.01"
                transform="translate(-131.88 87.79) rotate(-22.62)"
                fill="#707070"
                stroke="#707070"
                strokeMiterlimit="10"
                strokeWidth="2"
            />
            <rect
                x="126.44"
                y="379.17"
                width="148.6"
                height="21.33"
                rx="1.01"
                ry="1.01"
                transform="translate(-134.49 107.19) rotate(-22.62)"
                fill="none"
                stroke="#707070"
                strokeMiterlimit="10"
                strokeWidth="2"
            />
            <rect
                x="131.59"
                y="404.92"
                width="14.68"
                height="21.33"
                rx="1"
                ry="1"
                transform="translate(-149.15 85.4) rotate(-22.62)"
                fill="#707070"
                stroke="#707070"
                strokeMiterlimit="10"
                strokeWidth="2"
            />
            <rect
                x="262.8"
                y="119.03"
                width="193.27"
                height="299.11"
                rx="4.01"
                ry="4.01"
                transform="translate(112.42 -106) rotate(19.83)"
                fill="#fff"
                stroke="#707070"
                strokeMiterlimit="10"
                strokeWidth="3"
            />
            <rect
                x="348.83"
                y="246.53"
                width="91.48"
                height="4.49"
                transform="translate(112.31 -122.58) rotate(20.53)"
                fill="#e3e3e4"
                stroke="#e3e3e4"
                strokeMiterlimit="10"
            />
            <rect
                x="344.34"
                y="262.26"
                width="91.48"
                height="4.49"
                transform="translate(117.54 -120) rotate(20.53)"
                fill="#e3e3e4"
                stroke="#e3e3e4"
                strokeMiterlimit="10"
            />
            <rect
                x="339.85"
                y="277.99"
                width="91.48"
                height="4.49"
                transform="translate(122.77 -117.43) rotate(20.53)"
                fill="#e3e3e4"
                stroke="#e3e3e4"
                strokeMiterlimit="10"
            />
            <rect
                x="424.82"
                y="157.7"
                width="15.73"
                height="73.34"
                transform="translate(91.59 -135.25) rotate(19.83)"
                fill="#e3e3e4"
                stroke="#707070"
                strokeMiterlimit="10"
                strokeWidth="2"
            />
            <rect
                x="381.12"
                y="160.54"
                width="15.73"
                height="54.2"
                transform="translate(86.72 -120.83) rotate(19.83)"
                fill="none"
                stroke="#707070"
                strokeMiterlimit="10"
                strokeWidth="2"
            />
            <rect
                x="357.64"
                y="170.18"
                width="15.73"
                height="35"
                transform="translate(85.34 -112.86) rotate(19.83)"
                fill="#f5f6f7"
                stroke="#707070"
                strokeMiterlimit="10"
                strokeWidth="2"
            />
            <rect
                x="435.87"
                y="216.91"
                width="15.73"
                height="19.26"
                transform="translate(103.16 -137.09) rotate(19.83)"
                fill="#f5f6f7"
                stroke="#707070"
                strokeMiterlimit="10"
                strokeWidth="2"
            />
            <rect
                x="338.1"
                y="320.61"
                width="76.41"
                height="22.9"
                rx="1"
                ry="1"
                transform="translate(133.65 -107.25) rotate(19.66)"
                fill="none"
                stroke="#707070"
                strokeMiterlimit="10"
                strokeWidth="3"
            />
            <rect
                x="339.28"
                y="313.81"
                width="35.97"
                height="22.9"
                rx="1"
                ry="1"
                transform="translate(130.26 -101.24) rotate(19.66)"
                fill="#707070"
                stroke="#707070"
                strokeMiterlimit="10"
            />
            <rect
                x="327.35"
                y="352.24"
                width="76.41"
                height="22.9"
                rx="1"
                ry="1"
                transform="translate(143.67 -101.78) rotate(19.66)"
                fill="none"
                stroke="#707070"
                strokeMiterlimit="10"
                strokeWidth="3"
            />
            <rect
                x="327.84"
                y="349.38"
                width="59.38"
                height="22.9"
                rx="1"
                ry="1"
                transform="translate(142.24 -99.25) rotate(19.66)"
                fill="#707070"
                stroke="#707070"
                strokeMiterlimit="10"
            />
            <rect
                x="266.31"
                y="372.92"
                width="128.16"
                height="22.9"
                rx="1"
                ry="1"
                transform="translate(148.58 -88.75) rotate(19.66)"
                fill="none"
                stroke="#707070"
                strokeMiterlimit="10"
                strokeWidth="3"
            />
            <rect
                x="268.22"
                y="361.91"
                width="62.73"
                height="22.9"
                rx="1"
                ry="1"
                transform="translate(143.08 -79.03) rotate(19.66)"
                fill="#707070"
                stroke="#707070"
                strokeMiterlimit="10"
            />
            <rect
                x="151.55"
                y="80.96"
                width="211.25"
                height="296.65"
                rx="4"
                ry="4"
                fill="#fff"
                stroke="#707070"
                strokeMiterlimit="10"
                strokeWidth="3"
            />
            <path
                d="M342.57,116.92c-9,2.24-31.46,11.23-35.95,15.73,0,0-6.75,2.24-11.24,2.24h-18l-18,9H239.19l-13.48,9s-13.48,6.75-22.47,9l-28.87,6.75"
                fill="none"
                stroke="#e3e3e4"
                strokeMiterlimit="10"
                strokeWidth="2"
            />
            <path
                d="M342.57,139.39l-1,.18-2.65.51-4,.79-4.89,1q-2.73.6-5.45,1.24c-1.89.44-3.76.9-5.64,1.39s-3.64,1-5.44,1.5c-1.64.48-3.28,1-4.89,1.56l-1.06.39a3.6,3.6,0,0,1-1.27.39H279.65l-20.23,6.74H237s-52.25,13.49-56.47,13.49"
                fill="none"
                stroke="#f5f6f7"
                strokeMiterlimit="10"
                strokeWidth="2"
            />
            <path
                d="M208.29,263s32.62-2.22,39.63,33.72c0,0,2.55,14.92-7.58,27.68l-10.13-5.21s11.69-13.74,4.72-28.22c0,0-4.72-16.73-27.2-16.73V263"
                fill="#e3e3e4"
            />
            <rect
                x="281.89"
                y="278.16"
                width="60.68"
                height="4.49"
                fill="#e8eef6"
                stroke="#e8eef6"
                strokeMiterlimit="10"
            />
            <rect
                x="171.77"
                y="184.34"
                width="170.8"
                height="4.49"
                fill="#e3e3e4"
                stroke="#e3e3e4"
                strokeMiterlimit="10"
            />
            <rect
                x="171.77"
                y="200.07"
                width="170.8"
                height="4.49"
                fill="#e3e3e4"
                stroke="#e3e3e4"
                strokeMiterlimit="10"
            />
            <rect
                x="171.77"
                y="218.05"
                width="170.8"
                height="4.49"
                fill="#e3e3e4"
                stroke="#e3e3e4"
                strokeMiterlimit="10"
            />
            <circle
                cx="269.53"
                cy="280.41"
                r="4.09"
                fill="#f5f6f7"
                stroke="#f5f6f7"
                strokeMiterlimit="10"
            />
            <rect
                x="281.89"
                y="296.14"
                width="60.68"
                height="4.49"
                fill="#e8eef6"
                stroke="#e8eef6"
                strokeMiterlimit="10"
            />
            <circle
                cx="269.53"
                cy="298.39"
                r="5.06"
                fill="#e3e3e4"
                stroke="#e3e3e4"
                strokeMiterlimit="10"
            />
            <rect
                x="281.89"
                y="314.12"
                width="60.68"
                height="4.49"
                fill="#707070"
                stroke="#707070"
                strokeMiterlimit="10"
            />
            <circle
                cx="269.53"
                cy="316.37"
                r="5.06"
                fill="#707070"
                stroke="#707070"
                strokeMiterlimit="10"
            />
            <path
                d="M230.39,319.27l9.79,5.12a39.82,39.82,0,0,0,8-23.19H237A30.2,30.2,0,0,1,230.39,319.27Z"
                fill="#f5f6f7"
            />
            <path
                d="M210,263a38.21,38.21,0,1,0,38.2,38.21A38.21,38.21,0,0,0,210,263Zm0,65.18a27,27,0,1,1,27-27A27,27,0,0,1,210,328.17Z"
                fill="none"
                stroke="#707070"
                strokeMiterlimit="10"
                strokeWidth="2"
            />
            <path
                d="M172.9,168.61H342.57V98.94s-29,15.73-37.95,22.47h-29l-22.33,13.48c-6.7,2.25-15.63,2.25-19,3.39a14.59,14.59,0,0,0-3.43,2.83c-4.66,5.06-7.81,6.6-13.47,10.43-2.13,1.43-4.28,2.84-6.53,4.07-1.3.72-12.13,4-13.41,4C195.22,159.62,179.6,161.86,172.9,168.61Z"
                fill="none"
                stroke="#707070"
                strokeMiterlimit="10"
                strokeWidth="2"
            />
            <circle
                cx="69.03"
                cy="90.03"
                r="14.61"
                fill="#fff"
                stroke="#e3e3e4"
                strokeMiterlimit="10"
                strokeWidth="2"
            />
            <circle
                cx="454.83"
                cy="104.04"
                r="5.62"
                fill="#fff"
                stroke="#e3e3e4"
                strokeMiterlimit="10"
                strokeWidth="2"
            />
            <circle
                cx="85.25"
                cy="461.58"
                r="6.96"
                fill="#fff"
                stroke="#e3e3e4"
                strokeMiterlimit="10"
                strokeWidth="2"
            />
            <path
                d="M447.63,70.22h-7.32V62.91a.91.91,0,0,0-.91-.91.93.93,0,0,0-.93.91v7.31h-7.3a.93.93,0,0,0-.92.93.92.92,0,0,0,.92.91h7.3v7.32a.93.93,0,0,0,.93.91.91.91,0,0,0,.91-.91V72.06h7.32a.92.92,0,0,0,.92-.91A.93.93,0,0,0,447.63,70.22Z"
                fill="#e3e3e4"
                stroke="#e3e3e4"
                strokeMiterlimit="10"
                strokeWidth="0.75"
            />
            <path
                d="M77.23,426.15H69.38v-7.84a1,1,0,0,0-1-1,1,1,0,0,0-1,1v7.84H59.57a1,1,0,0,0-1,1,1,1,0,0,0,1,1H67.4V436a1,1,0,0,0,1,1,1,1,0,0,0,1-1v-7.85h7.85a1,1,0,0,0,1-1A1,1,0,0,0,77.23,426.15Z"
                fill="#e3e3e4"
                stroke="#e3e3e4"
                strokeMiterlimit="10"
                strokeWidth="0.75"
            />
            <path
                d="M51.63,134.22H44.31v-7.31a.91.91,0,0,0-.91-.91.93.93,0,0,0-.93.91v7.31h-7.3a.93.93,0,0,0-.92.93.92.92,0,0,0,.92.91h7.3v7.32a.93.93,0,0,0,.93.91.91.91,0,0,0,.91-.91v-7.32h7.32a.92.92,0,0,0,.92-.91A.93.93,0,0,0,51.63,134.22Z"
                fill="#e3e3e4"
                stroke="#e3e3e4"
                strokeMiterlimit="10"
                strokeWidth="0.75"
            />
        </SVGIcon>
    );
}
