import React from 'react';

import DCCrossClose from 'Assets/icons/DCCrossClose';
import {
    Dialog,
    Div,
    H1,
    IconButton,
    TopBar,
    Span
} from 'UIKit/index';

// addType - 'people' / 'businesses'
const HowToDialog = ({ open, onClose, title, description }) => {

    return (
        <Dialog dialogType={'centerZoom sizeSm'} classes={{ row: 'HowToDialogRow' }} open={open} onClose={onClose}>
            <Div className={'HowToDialog'}>
                <TopBar>
                    <Div className={'HeaderBar'}>
                        <H1 className={'HeaderBarTitle'}>
                            {title}
                        </H1>
                        <Div className={'HeaderBarClose'}>
                            <IconButton onClick={onClose}>
                                <DCCrossClose />
                            </IconButton>
                        </Div>
                    </Div>
                </TopBar>
                <Div className={'BodyBox'}>
                    <Div className={'BodyBoxInner'}>
                        <Div className={'HTDDes'}>
                            {description}
                        </Div>
                        <Div className={'HTDGrade'}>
                            <Div className={'HTDGItem'} style={{ backgroundColor: '#fe6786', width: '30%' }}>
                                F
                            </Div>
                            <Div className={'HTDGItem'} style={{ backgroundColor: '#ffaf3a', width: '25%' }}>
                                D
                                <Div className={'HTDGPer'}>
                                    30%
                                </Div>
                            </Div>
                            <Div className={'HTDGItem'} style={{ backgroundColor: '#4151f5', width: '20%' }}>
                                C
                                <Div className={'HTDGPer'}>
                                    50% <Span>Percentile</Span>
                                </Div>
                            </Div>
                            <Div className={'HTDGItem'} style={{ backgroundColor: '#ff3cf9', width: '17%' }}>
                                B
                                <Div className={'HTDGPer'}>
                                    75%
                                </Div>
                            </Div>
                            <Div className={'HTDGItem'} style={{ backgroundColor: '#08cdce', width: '13%' }}>
                                A
                                <Div className={'HTDGPer'}>
                                    90%
                                </Div>
                            </Div>
                        </Div>
                    </Div>
                </Div>
            </Div>
        </Dialog>
    );
};

export default HowToDialog;
