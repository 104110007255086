import React from 'react';

import { SVGIcon } from '../../UIKit/index';

export default function DCFacebook3(props) {
    return (
        <SVGIcon {...props} viewBox="0 0 512 512">
            <g xmlns="http://www.w3.org/2000/svg">
                <path d="M232.727,197.818v23.273h-23.273V256h23.273v104.727h46.545V256h30.953l3.956-34.909h-34.909v-20.364     c0-9.425,0.931-14.429,15.476-14.429h19.433v-35.026h-31.185C245.76,151.273,232.727,168.727,232.727,197.818z" />
                <path d="M256,0C114.615,0,0,114.615,0,256s114.615,256,256,256s256-114.615,256-256S397.385,0,256,0z M256,488.727     C127.468,488.727,23.273,384.532,23.273,256S127.468,23.273,256,23.273S488.727,127.468,488.727,256S384.532,488.727,256,488.727     z" />
            </g>
        </SVGIcon>
    );
}
