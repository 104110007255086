import React from 'react';

import { SVGIcon } from '../../UIKit/index';

export default function DCFoursquare(props) {
    return (
        <SVGIcon {...props} viewBox="0 0 24 24">
            <path d="m3.5 2.161v20.336c0 .952.559 1.298.841 1.411.287.117 1.053.216 1.52-.333 5.954-7.004 6.056-7.121 6.056-7.121.143-.159.157-.145.314-.145h3.852c1.621 0 1.875-1.181 2.047-1.861l2.245-11.392c.392-1.987-.102-3.056-1.95-3.056h-12.62c-1.732 0-2.305 1.327-2.305 2.161zm2.668 1.257c0-.361.314-.778.767-.778h10.117c.378 0 .72.361.624.82l-.003-.001-.526 2.798c-.055.305-.439.619-.78.619h-4.917c-.555 0-.952.389-.952.952v.609c0 .563.397.966.952.966h4.176c.383 0 .767.431.684.853-.083.417-.485 2.522-.526 2.756-.041.23-.314.633-.781.633h-3.395c-.624 0-.794.084-1.224.591 0 0-.411.534-4.134 5.078-.041.042-.083.028-.083-.014v-15.882z" />
        </SVGIcon>
    );
}
