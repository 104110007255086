import React from 'react';

import { SVGIcon } from '../../UIKit/index';

export default function DCQuestions(props) {
    return (
        <SVGIcon {...props} viewBox="0 0 512 512">
            <g>
                <path d="M508.1,462.9l-34.9-101.6C534,238.3,483.5,89.2,360.4,28.4S88.3,18.2,27.5,141.2   S17.2,413.4,140.3,474.1c34.2,16.9,71.8,25.7,109.9,25.7h0.4c38.1-0.1,75.7-8.9,109.9-25.8L462.2,509c3.9,1.3,7.9,2,12,2   c20-0.1,36.2-16.4,36.1-36.4c0-4-0.7-7.9-2-11.6H508.1z M247.8,392.8c-8.3,0-15-6.7-15-15s6.7-15,15-15s15,6.7,15,15   C262.5,386.1,255.9,392.7,247.8,392.8L247.8,392.8z M262.7,277.7v47.9c0,8.3-6.8,15.1-15.1,15.1c-8.3,0-15.1-6.8-15.1-15.1v-61.4   c0-8.3,6.7-15,15-15c24.8,0,44.9-20.1,44.9-44.9s-20.1-44.9-44.9-44.9s-44.9,20.1-44.9,44.9c0,8.3-6.8,15.1-15.1,15.1   c-8.3,0-15.1-6.8-15.1-15.1c-0.1-41.4,33.5-75.1,74.9-75.1c41.4-0.1,75.1,33.5,75.1,74.9C322.6,239.6,297.6,270.3,262.7,277.7   L262.7,277.7z" />
            </g>
        </SVGIcon>
    );
}
