import React from 'react';

import { SVGIcon } from '../../UIKit/index';

export default function DCChat1(props) {
    return (
        <SVGIcon {...props} viewBox="0 0 512 512">
            <path d="M153.3,272.2H66.5c0,0-12.5-2.4-15.3-17V82.8c0,0,2.5-11.8,14.6-14.9h243.4c0,0,13.6,3.3,14.5,14.5v173.9   c0,0,0.4,11.8-12.4,15.9H198.7l-47.2,35.2l0.4,42.8l57.9-43.3h104.3c0,0,38.7-6.1,44.5-44.1V80c0,0-6-41.8-45.6-45.5H60.5   c0,0-39.9,4.8-43.2,46.5l-0.7,176.9c0,0,4.6,42.9,45.5,48.6l56,0.3l0.9,68.2l32.8-24.8L153.3,272.2z" />
            <path d="M189,364.3l-27.9,20.4c0,0,13.9,21.4,35.9,23.9h104.8l90.7,68.9v-68.2h57.4c0,0,38.8-4.5,45.4-45.1V180.9   c0,0-7.2-42.8-47.9-45h-55.6v34.7h55.4c0,0,11.5,1.4,13.2,13.5V360c0,0,0.1,11.6-15,14.7h-86.6v34.3l-45.7-34H201   C201,375,196.3,375,189,364.3z" />
        </SVGIcon>
    );
}
