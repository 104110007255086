import React, { Fragment, useCallback, useState } from 'react';

import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import highchartsMore from 'highcharts/highcharts-more';
import solidGauge from 'highcharts/modules/solid-gauge';
import useMediaQuery from 'UIKit/useMediaQuery';
import { Div, Strong } from 'UIKit/index';
import HowToDialog from './howToDialog';
import { calculateGrade } from 'Utils/helper';

// grades: {listings: "A", reviews: "A", social: "A", website: "A", seo: "A", google_ads: "A"}
// percentage: 15

const OverallScore = ({ scores, details }) => {
    const matches1367 = useMediaQuery('(min-width: 1367px) and (max-width: 1600px)');
    highchartsMore(Highcharts);
    solidGauge(Highcharts);
    const gradeToColor = { A: '#08cdce', B: '#ff3cf9', C: '#4151f5', D: '#ffaf3a', F: '#fe6786' };
    const options = {
        chart: {
            type: 'solidgauge',
            width: matches1367 ? 200 : 234,
            height: matches1367 ? 200 : 234,
        },
        title: {
            text: null,
        },
        credits: {
            enabled: false,
        },
        tooltip: {
            enabled: false,
        },
        pane: {
            startAngle: 0,
            endAngle: 360,
            center: ['50%', '50%'],
            background: [
                {
                    outerRadius: '112%',
                    innerRadius: '80%',
                    backgroundColor: '#26A3F6',
                    borderWidth: 0,
                },
            ],
        },
        yAxis: {
            min: 0,
            max: 100,
            lineWidth: 0,
            tickPositions: [],
        },
        plotOptions: {
            solidgauge: {
                dataLabels: {
                    enabled: true,
                },
                linecap: 'round',
                stickyTracking: false,
                rounded: false,
            },
        },
        series: [
            {
                type: 'solidgauge',
                dataLabels: [
                    {
                        format: '{y}%',
                        y: matches1367 ? -25 : -30,
                        style: {
                            fontSize: matches1367 ? '32px' : '50px',
                            fontWeight: 500,
                            color: '#000000',
                            lineHeight: '66px',
                            fontFamily: 'Roboto',
                            textOutline: 0,
                            outline: 0,
                            border: 0,
                        },
                    },
                ],
                data: [
                    {
                        color: gradeToColor[calculateGrade(scores?.totalPercentage)],
                        fontSize: '50px',
                        radius: '112%',
                        innerRadius: '80%',
                        y: scores?.totalPercentage,
                    },
                ],
            },
        ],
    };

    const [howToDialog, setHowToDialog] = useState(false);
    const handleHowToDialogOpen = useCallback(() => {
        setHowToDialog(true);
    }, []);

    const handleHowToDialogClose = useCallback(() => {
        setHowToDialog(false);
    }, []);

    return (
        <Fragment>
            <HowToDialog
                open={howToDialog}
                onClose={handleHowToDialogClose}
                title={'How we calculate the Overall score'}
                description={
                    <Fragment>
                        Your <Strong>Overall score</Strong> is calculated by taking each reporting
                        module percentile, then calculating the average.
                    </Fragment>
                }
            />
            <Div className={'EPComponent'}>
                <Div className={'EPCHead'}>
                    <Div className={'EPCHTBox'}>
                        {/* <Div className={"EPCHTBIcon"}>D</Div> */}
                        <Div className={'EPCHTBTitle'}>Overall Score</Div>
                        {/* <Div className={"EPCHTBSTitle"}>Business Details</Div> */}
                    </Div>
                    <Div className={'EPCHSTitle'} onClick={handleHowToDialogOpen}>
                        How we calculated the overall score?
                    </Div>
                </Div>
                <Div className={'EPCBody'}>
                    <Div className={'EPCRow'}>
                        <Div className={'EPCRCol2'}>
                            <Div className={'EPCRCChart'}>
                                <HighchartsReact highcharts={Highcharts} options={options} />
                            </Div>
                        </Div>
                        <Div className={'EPCRCol8'}>
                            <Div className={'EPCRCScore'}>
                                <Div className={'EPCRCSBox'}>
                                    <Div className={'EPCRCSBTitle'}>Listings</Div>
                                    <Div
                                        className={
                                            'EPCRCSBType Grade' +
                                            (scores?.listings ? scores?.listings : '')
                                        }
                                    >
                                        {scores?.listings}
                                    </Div>
                                </Div>
                                <Div className={'EPCRCSBox'}>
                                    <Div className={'EPCRCSBTitle'}>Reviews</Div>
                                    <Div
                                        className={
                                            'EPCRCSBType Grade' +
                                            (scores?.reviews ? scores?.reviews : '')
                                        }
                                    >
                                        {scores?.reviews}
                                    </Div>
                                </Div>
                                <Div className={'EPCRCSBox'}>
                                    <Div className={'EPCRCSBTitle'}>Social</Div>
                                    <Div
                                        className={
                                            'EPCRCSBType Grade' +
                                            (scores?.social ? scores?.social : '')
                                        }
                                    >
                                        {scores?.social}
                                    </Div>
                                </Div>
                                {details?.website?.length > 0 && (
                                    <Fragment>
                                        <Div className={'EPCRCSBox'}>
                                            <Div className={'EPCRCSBTitle'}>Website</Div>
                                            <Div
                                                className={
                                                    'EPCRCSBType Grade' +
                                                    (scores?.website ? scores?.website : '')
                                                }
                                            >
                                                {scores?.website}
                                            </Div>
                                        </Div>
                                        <Div className={'EPCRCSBox'}>
                                            <Div className={'EPCRCSBTitle'}>SEO</Div>
                                            <Div
                                                className={
                                                    'EPCRCSBType Grade' +
                                                    (scores?.seo ? scores?.seo : '')
                                                }
                                            >
                                                {scores?.seo}
                                            </Div>
                                        </Div>
                                        <Div className={'EPCRCSBox'}>
                                            <Div className={'EPCRCSBTitle'}>Google Ads</Div>
                                            <Div
                                                className={
                                                    'EPCRCSBType Grade' +
                                                    (scores?.googleAds ? scores?.googleAds : '')
                                                }
                                            >
                                                {scores?.googleAds}
                                            </Div>
                                        </Div>
                                    </Fragment>
                                )}
                            </Div>
                        </Div>
                    </Div>
                </Div>
            </Div>
        </Fragment>
    );
};

export default OverallScore;
