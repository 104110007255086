import React from 'react';

import { SVGIcon } from '../../UIKit/index';

export default function DCGoogleAd1(props) {
    return (
        <SVGIcon {...props} viewBox="0 0 512 467">
            <g transform="translate(-694 -159.873)">
                <g transform="translate(694 159.873)">
                    <path
                        fill="#D9EAFF"
                        d="M301.29,14.61C256.73-11.14,199.74,4.11,174,48.67c-0.01,0.02-0.02,0.04-0.03,0.06L14.1,325.62    C-11.63,370.2,3.65,427.19,48.23,452.92c44.58,25.73,101.58,10.45,127.31-34.13l159.87-276.86    C361.08,97.34,345.82,40.39,301.29,14.61L301.29,14.61z"
                    />
                    <path
                        fill="#D7EDFF"
                        d="M184.84,348.12c-13.35-49.7-64.45-79.17-114.15-65.82S-8.48,346.75,4.87,396.45    c6.4,23.84,22,44.16,43.38,56.51c44.56,25.74,101.54,10.49,127.29-34.07C187.91,397.47,191.25,372.01,184.84,348.12z"
                    />
                    <path
                        fill="#D9EAFF"
                        d="M497.27,325.61L337.4,48.72c-25.73-44.58-82.73-59.86-127.31-34.13s-59.86,82.73-34.13,127.31    l159.88,276.92c25.73,44.58,82.73,59.86,127.31,34.13c44.58-25.73,59.86-82.73,34.13-127.31V325.61z"
                    />
                    <path
                        fill="#005EFF"
                        d="M497.27,325.64L337.4,48.73C310.83,3.6,252.71-11.44,207.58,15.12    c-13.87,8.17-25.44,19.73-33.61,33.61L14.09,325.64c-25.73,44.58-10.45,101.58,34.13,127.31    c44.58,25.73,101.58,10.45,127.31-34.13l0,0l80.16-138.83l80.16,138.83c25.73,44.58,82.73,59.86,127.31,34.13    C507.73,427.22,523.01,370.22,497.27,325.64L497.27,325.64L497.27,325.64z M147.2,402.53c-16.72,28.95-53.74,38.87-82.69,22.15    c-28.95-16.72-38.87-53.74-22.15-82.69c16.72-28.95,53.73-38.87,82.69-22.16C153.99,336.55,163.92,373.57,147.2,402.53    C147.2,402.53,147.2,402.53,147.2,402.53z M182.65,341.17c-15.25-43.26-59.75-68.95-104.84-60.53l90.27-156.35    c4.03,12.29,1.19,6.07,68.75,123.08L182.65,341.17z M446.79,424.68c-28.93,16.72-65.94,6.82-82.67-22.11    c-0.01-0.02-0.02-0.04-0.03-0.05L204.25,125.6c-30.51-52.85,28.3-112.96,80.71-82.7c19.61,11.33,14.21,4.97,184.02,299.07    c16.68,28.96,6.76,65.96-22.16,82.7H446.79z"
                    />
                </g>
                <g transform="translate(694.124 190.306)">
                    <path
                        fill="#FFFFFF"
                        d="M97.41,423.23c-44.89,0-81.41-36.52-81.41-81.41s36.52-81.41,81.41-81.41s81.41,36.52,81.41,81.41    S142.3,423.23,97.41,423.23z"
                    />
                    <path
                        fill="#6B8AAA"
                        d="M97.41,273.97c-37.41,0-67.84,30.43-67.84,67.84S60,409.66,97.41,409.66s67.84-30.43,67.84-67.84    S134.82,273.97,97.41,273.97 M97.41,246.83c52.46,0,94.98,42.52,94.98,94.98s-42.52,94.98-94.98,94.98S2.43,394.27,2.43,341.81    S44.96,246.83,97.41,246.83z"
                    />
                </g>
            </g>
        </SVGIcon>
    );
}
