import React from 'react';

import { SVGIcon } from '../../UIKit/index';

export default function DCListDoc(props) {
    return (
        <SVGIcon {...props} viewBox="0 0 512 512">
            <g>
                <path d="M204.93,143.3h94.9c8.27,0,14.97-6.7,14.97-14.97c0-8.27-6.7-14.97-14.97-14.97h-94.9   c-8.27,0-14.97,6.7-14.97,14.97C189.97,136.6,196.67,143.3,204.93,143.3z" />
                <path d="M365.42-0.1H15.06C6.79-0.1,0.09,6.6,0.09,14.87c0,0,0,0,0,0v481.08   c0,8.27,6.7,14.97,14.97,14.97h350.41c8.27,0,14.97-6.7,14.97-14.97V14.87c0-8.27-6.7-14.97-14.97-14.97   C365.45-0.1,365.43-0.1,365.42-0.1z M350.5,480.96H30.02V29.83H350.5V480.96z" />
                <path d="M511.1,362.69V50.71c-0.04-28.06-22.83-50.77-50.89-50.73c-28,0.04-50.69,22.73-50.73,50.73   v311.98c0.01,7.74,5.92,14.2,13.63,14.89l-12.73,35.16c-1.34,3.69-1.17,7.77,0.46,11.34l35.84,78.09   c3.45,7.51,12.33,10.81,19.84,7.36c3.26-1.49,5.87-4.11,7.36-7.36l35.84-78.09c1.64-3.57,1.8-7.65,0.46-11.34l-12.73-35.16   C505.19,376.89,511.1,370.44,511.1,362.69z M439.41,347.73V124.56h41.73v223.17C481.15,347.73,439.41,347.73,439.41,347.73z    M460.29,29.83c11.53,0.01,20.87,9.35,20.88,20.88v43.8h-41.73v-43.8C439.45,39.19,448.78,29.86,460.29,29.83L460.29,29.83z    M460.29,460.03l-19.69-42.88l14.31-39.52h10.76l14.31,39.52L460.29,460.03z" />
                <path d="M90.73,156.8c5.84,5.85,15.32,5.85,21.16,0.01c0,0,0,0,0.01-0.01l45.48-45.48   c5.85-5.85,5.85-15.32,0-21.17c-5.85-5.85-15.32-5.85-21.17,0l-35.02,34.87l-10.85-10.85c-5.85-5.85-15.32-5.85-21.17,0   c-5.85,5.85-5.85,15.32,0,21.17L90.73,156.8z" />
                <path d="M204.93,234.11h94.9c8.27-0.02,14.95-6.74,14.93-15c-0.02-8.23-6.69-14.9-14.93-14.93h-94.9   c-8.27,0.02-14.95,6.74-14.93,15C190.03,227.42,196.7,234.09,204.93,234.11z" />
                <path d="M90.73,247.64c5.84,5.85,15.32,5.85,21.16,0.01c0,0,0,0,0.01-0.01l45.48-45.48   c5.85-5.85,5.85-15.32,0-21.17c-5.85-5.85-15.32-5.85-21.17,0l-34.89,34.89l-10.85-10.85c-5.85-5.85-15.32-5.85-21.17,0   c-5.85,5.85-5.85,15.32,0,21.17L90.73,247.64z" />
                <path d="M204.93,324.95h94.9c8.27,0,14.97-6.7,14.97-14.97c0-8.26-6.7-14.97-14.97-14.97l0,0h-94.9   c-8.27,0-14.97,6.7-14.97,14.97C189.97,318.25,196.67,324.95,204.93,324.95z" />
                <path d="M204.93,419.27h94.9c8.27,0,14.97-6.7,14.97-14.97c0-8.27-6.7-14.97-14.97-14.97l0,0h-94.9   c-8.27,0-14.97,6.7-14.97,14.97C189.97,412.57,196.67,419.27,204.93,419.27z" />
                <path d="M90.73,338.46c5.84,5.85,15.32,5.85,21.16,0.01c0,0,0,0,0.01-0.01l45.48-45.48   c5.85-5.85,5.85-15.32,0-21.17c-5.85-5.85-15.32-5.85-21.17,0l-34.89,34.89l-10.85-10.85c-5.85-5.85-15.32-5.85-21.17,0   c-5.85,5.85-5.85,15.32,0,21.17L90.73,338.46z" />
            </g>
        </SVGIcon>
    );
}
