import React from 'react';

import { SVGIcon } from '../../UIKit/index';

export default function DCEmailOpen1(props) {
    return (
        <SVGIcon {...props} viewBox="0 0 13.422 11.737">
            <g>
                <path
                    d="M13.26,32.492l-1.929-1.363V29.139a.429.429,0,0,0-.367-.383H2.511a.429.429,0,0,0-.367.383v1.975L.138,32.492A.337.337,0,0,0,0,32.737V40.01a.444.444,0,0,0,.383.383H13.092c.168,0,.23-.2.23-.383V32.737C13.321,32.645,13.337,32.553,13.26,32.492Zm-1.929-.628,1.317.9-1.317,1Zm-8.575-2.5h7.962v4.869l-3.981,3-3.981-3V29.368Zm-.612,2.481v1.929L.827,32.768ZM.612,33.4,4.9,36.642.612,39.536Zm.735,6.385L5.42,37.04l1.087.827a.367.367,0,0,0,.214.077c.061,0,.092-.031.153-.077l1.133-.873,4.119,2.787Zm11.361-.352-4.2-2.817,4.2-3.216Z"
                    transform="translate(0.05 -28.706)"
                />
                <path
                    d="M136.143,97.286h1.378a.306.306,0,1,0,0-.612h-1.378a.306.306,0,0,0,0,.612Z"
                    transform="translate(-131.806 -94.633)"
                />
                <path
                    d="M136.143,144.307h4.9a.306.306,0,1,0,0-.612h-4.9a.306.306,0,0,0,0,.612Z"
                    transform="translate(-131.806 -140.276)"
                />
                <path
                    d="M141.349,191.021a.306.306,0,0,0-.306-.306h-4.9a.306.306,0,0,0,0,.612h4.9A.306.306,0,0,0,141.349,191.021Z"
                    transform="translate(-131.806 -185.918)"
                />
            </g>
        </SVGIcon>
    );
}
