import React, { Fragment, useState, useEffect, useRef, useCallback } from 'react';
import { Div, Button, Image, Strong } from 'UIKit/index';
import DCWarning from 'Assets/icons/DCWarning';
import DCSettings2 from 'Assets/icons/DCSettings2';
import DCSqHalfCheck from 'Assets/icons/DCSqHalfCheck';
import MobView from 'Assets/images/mobile.png';
import LaptopView from 'Assets/images/laptop.png';

import IssueRow from './issueRow';
import NoFound from './noFound';
import HowToDialog from './howToDialog';
import generateImage from 'Utils/generateImage';

const IssueRowData = ({ rowData, webDet }) => {
    const rowRef = useRef();
    const [rowDataItems, setRowDataItems] = useState(rowData?.slice(0, 2) || []);
    const [rowDataState, setRowDataState] = useState(false);

    const handleRowDataMore = useCallback(() => {
        setRowDataItems(rowData);
        setRowDataState(true);
    }, [rowData]);

    const handleRowDataLess = useCallback(() => {
        setRowDataItems(rowData.slice(0, 2));
        setRowDataState(false);
    }, [rowData]);

    useEffect(() => {
        if (webDet) {
            handleRowDataMore();
        } else {
            handleRowDataLess();
        }
    }, [webDet, handleRowDataMore, handleRowDataLess]);

    useEffect(() => {
        /**
         * Alert if clicked on outside of element
         */
        function handleClickOutside(event) {
            if (rowDataState && rowRef.current && !rowRef.current.contains(event.target)) {
                setRowDataItems(rowData.slice(0, 2));
                setRowDataState(false);
            }
        }
        document.addEventListener('click', handleClickOutside);
        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener('click', handleClickOutside);
        };
    }, [rowData, rowDataState]);

    return (
        <Div
            className={'EPCWTBFBody'}
            reference={rowRef}
            style={{
                marginBottom: rowDataItems?.length > 0 && rowData?.length > 2 ? '15px' : '25px',
            }}
        >
            {rowDataItems.map((item, index) => (
                <IssueRow key={index} data={item} />
            ))}
            {rowDataItems?.length > 0 && rowData?.length > 2 && (
                <Div className={'EPCWTBFBViewMore'}>
                    <Button
                        buttonType={'BlueFillBtn'}
                        onClick={rowDataState ? handleRowDataLess : handleRowDataMore}
                    >
                        {rowDataState ? 'View Less' : 'View More'}
                    </Button>
                </Div>
            )}
        </Div>
    );
};

const Website = ({ visible, data, details, user, handleGetInTouchOpen, webDe }) => {
    const [howToDialog, setHowToDialog] = useState(false);

    const handleHowToDialogOpen = useCallback(() => {
        setHowToDialog(true);
    }, []);

    const handleHowToDialogClose = useCallback(() => {
        setHowToDialog(false);
    }, []);

    if (!visible) {
        return null;
    }

    return (
        <Fragment>
            <HowToDialog
                open={howToDialog}
                onClose={handleHowToDialogClose}
                title={'How we calculate the Speed grade'}
                description={
                    <Fragment>
                        Your <Strong>Speed score</Strong> is calculated by comparing your metrics
                        versus the industry average. Our weigh algorithm adds all 4 metrics
                        together, divides it by 4, then compares it to the industry average within
                        that category. The metrics that are calculated are page likes, average posts
                        per month, average likes per post and average shares per post.
                    </Fragment>
                }
            />
            <Div className={'EPComponent'}>
                <Div className={'EPCHead'}>
                    <Div className={'EPCHTBox'}>
                        <Div className={'EPCHTBIcon Grade' + (data?.grade || 'F')}>
                            {data?.grade || 'F'}
                        </Div>
                        <Div className={'EPCHTBTitle'}>Website</Div>
                        <Div className={'EPCHTBSTitle'}>
                            Does your website turn clicks into customers?
                        </Div>
                    </Div>
                    <Div className={'EPCHSTitle'} onClick={handleHowToDialogOpen}>
                        How we calculated this grade?
                    </Div>
                </Div>
                <Div className={'EPCBody'}>
                    <Div className={'EPCVideoContent'}>
                        <Div className={'EPCVCVideo'}>
                            <iframe
                                src="https://player.vimeo.com/video/356237788"
                                title="video"
                                frameBorder="0"
                                webkitallowfullscreen=""
                                mozallowfullscreen=""
                            />
                        </Div>
                        <Div className={'EPCVCContent'}>
                            <Div className={'EPCVCCDes'}>
                                79% of consumers research a business online before visiting in
                                person or making a purchase. Having a website that’s mobile
                                responsive, optimized for speed and beautifully crafted for
                                conversions is the stepping stone for every business.
                            </Div>
                            <br />
                            <Div className={'EPCVCCDes'}>
                                Using Google’s Page Speed Test, we ran an audit on{' '}
                                {details?.website} to find any issues that can affect the
                                performance of your marketing efforts.
                            </Div>
                        </Div>
                    </Div>
                    {data?.mobile?.shouldFix?.length +
                        data?.mobile?.passed?.length +
                        data?.mobile?.considerFixing?.length >
                        0 ||
                    data?.desktop?.shouldFix?.length +
                        data?.desktop?.passed?.length +
                        data?.desktop?.considerFixing?.length >
                        0 ? (
                        <Div className={'EPCRow'} style={{ marginTop: '15px' }}>
                            <Div className={'EPCRCol5'}>
                                <Div className={'EPCWebType'}>
                                    <Div className={'EPCWTHead'}>
                                        <Div className={'EPCWTHGrade Grade' + data?.mobile?.grade}>
                                            {data?.mobile?.grade}
                                        </Div>
                                        <Div className={'EPCWTHTitle'}>Mobile</Div>
                                    </Div>
                                    <Div className={'EPCWTBody'}>
                                        <Div className={'EPCWTBView'}>
                                            <Div className={'EPCWTBVSummery'}>
                                                <Div className={'EPCWTBVSTitle'}>
                                                    Page Speed Summary
                                                </Div>
                                                <Div className={'EPCWTBVSITN'}>
                                                    <Div
                                                        className={'EPCWTBVSITNIcon'}
                                                        style={{ color: '#ff466f' }}
                                                    >
                                                        <DCWarning />
                                                    </Div>
                                                    <Div className={'EPCWTBVSITNTitle'}>
                                                        Should Fix
                                                    </Div>
                                                    <Div className={'EPCWTBVSITNNum'}>
                                                        {data?.mobile?.shouldFix?.length}
                                                    </Div>
                                                </Div>
                                                <Div className={'EPCWTBVSITN'}>
                                                    <Div
                                                        className={'EPCWTBVSITNIcon'}
                                                        style={{ color: '#ffc13a' }}
                                                    >
                                                        <DCSettings2 />
                                                    </Div>
                                                    <Div className={'EPCWTBVSITNTitle'}>
                                                        Consider Fixing
                                                    </Div>
                                                    <Div className={'EPCWTBVSITNNum'}>
                                                        {data?.mobile?.considerFixing?.length}
                                                    </Div>
                                                </Div>
                                                <Div className={'EPCWTBVSITN'}>
                                                    <Div
                                                        className={'EPCWTBVSITNIcon'}
                                                        style={{ color: '#00acfd' }}
                                                    >
                                                        <DCSqHalfCheck />
                                                    </Div>
                                                    <Div className={'EPCWTBVSITNTitle'}>Passed</Div>
                                                    <Div className={'EPCWTBVSITNNum'}>
                                                        {data?.mobile?.passed?.length}
                                                    </Div>
                                                </Div>
                                            </Div>
                                            <Div className={'EPCWTBVMob'}>
                                                <Div className={'EPCWTBVMobView'}>
                                                    <Image
                                                        src={MobView}
                                                        alt={''}
                                                        className="EPCWTBVMVImg"
                                                    />
                                                    <Div className={'EPCWTBVMVContent'}>
                                                        <Image
                                                            src={data?.mobile?.screenshot}
                                                            alt={''}
                                                        />
                                                    </Div>
                                                </Div>
                                            </Div>
                                        </Div>
                                        <Div className={'EPCWTBFix'}>
                                            <Div className={'EPCWTBFHead'}>
                                                <Div className={'EPCWTBFHIcon'}>
                                                    <DCWarning />
                                                </Div>
                                                <Div className={'EPCWTBFHTitle'}>Should Fix</Div>
                                            </Div>
                                            <IssueRowData
                                                webDet={webDe}
                                                rowData={data?.mobile?.shouldFix?.data}
                                            />
                                        </Div>
                                        <Div className={'EPCWTBFix'}>
                                            <Div className={'EPCWTBFHead'}>
                                                <Div
                                                    className={'EPCWTBFHIcon'}
                                                    style={{ color: '#ffc13a' }}
                                                >
                                                    <DCSettings2 />
                                                </Div>
                                                <Div className={'EPCWTBFHTitle'}>
                                                    Consider Fixing
                                                </Div>
                                            </Div>
                                            <IssueRowData
                                                webDet={webDe}
                                                rowData={data?.mobile?.considerFixing?.data}
                                            />
                                        </Div>
                                        <Div className={'EPCWTBFix'}>
                                            <Div className={'EPCWTBFHead'}>
                                                <Div
                                                    className={'EPCWTBFHIcon'}
                                                    style={{ color: '#00acfd' }}
                                                >
                                                    <DCSqHalfCheck />
                                                </Div>
                                                <Div className={'EPCWTBFHTitle'}>Passed</Div>
                                            </Div>

                                            <IssueRowData
                                                webDet={webDe}
                                                rowData={data?.mobile?.passed?.data}
                                            />
                                        </Div>
                                    </Div>
                                </Div>
                            </Div>
                            <Div className={'EPCRCol5'}>
                                <Div className={'EPCWebType'}>
                                    <Div className={'EPCWTHead'}>
                                        <Div className={'EPCWTHGrade Grade' + data?.desktop?.grade}>
                                            {data?.desktop?.grade}
                                        </Div>
                                        <Div className={'EPCWTHTitle'}>Desktop</Div>
                                    </Div>
                                    <Div className={'EPCWTBody'}>
                                        <Div className={'EPCWTBView EPCWTBDesktopView'}>
                                            <Div className={'EPCWTBVSummery'}>
                                                <Div className={'EPCWTBVSTitle'}>
                                                    Page Speed Summary
                                                </Div>
                                                <Div className={'EPCWTBVSITN'}>
                                                    <Div
                                                        className={'EPCWTBVSITNIcon'}
                                                        style={{ color: '#ff466f' }}
                                                    >
                                                        <DCWarning />
                                                    </Div>
                                                    <Div className={'EPCWTBVSITNTitle'}>
                                                        Should Fix
                                                    </Div>
                                                    <Div className={'EPCWTBVSITNNum'}>
                                                        {data?.desktop?.shouldFix?.length}
                                                    </Div>
                                                </Div>
                                                <Div className={'EPCWTBVSITN'}>
                                                    <Div
                                                        className={'EPCWTBVSITNIcon'}
                                                        style={{ color: '#ffc13a' }}
                                                    >
                                                        <DCSettings2 />
                                                    </Div>
                                                    <Div className={'EPCWTBVSITNTitle'}>
                                                        Consider Fixing
                                                    </Div>
                                                    <Div className={'EPCWTBVSITNNum'}>
                                                        {data?.desktop?.considerFixing?.length}
                                                    </Div>
                                                </Div>
                                                <Div className={'EPCWTBVSITN'}>
                                                    <Div
                                                        className={'EPCWTBVSITNIcon'}
                                                        style={{ color: '#00acfd' }}
                                                    >
                                                        <DCSqHalfCheck />
                                                    </Div>
                                                    <Div className={'EPCWTBVSITNTitle'}>Passed</Div>
                                                    <Div className={'EPCWTBVSITNNum'}>
                                                        {data?.desktop?.passed?.length}
                                                    </Div>
                                                </Div>
                                            </Div>
                                            <Div className={'EPCWTBVDesk'}>
                                                <Div className={'EPCWTBVDeskView'}>
                                                    <Image
                                                        src={LaptopView}
                                                        alt={''}
                                                        className="EPCWTBVDVImg"
                                                    />
                                                    <Div className={'EPCWTBVDVContent'}>
                                                        <Image
                                                            src={data?.desktop?.screenshot}
                                                            alt={''}
                                                        />
                                                    </Div>
                                                </Div>
                                            </Div>
                                        </Div>
                                        <Div className={'EPCWTBFix'}>
                                            <Div className={'EPCWTBFHead'}>
                                                <Div className={'EPCWTBFHIcon'}>
                                                    <DCWarning />
                                                </Div>
                                                <Div className={'EPCWTBFHTitle'}>Should Fix</Div>
                                            </Div>
                                            <IssueRowData
                                                webDet={webDe}
                                                rowData={data?.desktop?.shouldFix?.data}
                                            />
                                        </Div>
                                        <Div className={'EPCWTBFix'}>
                                            <Div className={'EPCWTBFHead'}>
                                                <Div
                                                    className={'EPCWTBFHIcon'}
                                                    style={{ color: '#ffc13a' }}
                                                >
                                                    <DCSettings2 />
                                                </Div>
                                                <Div className={'EPCWTBFHTitle'}>
                                                    Consider Fixing
                                                </Div>
                                            </Div>
                                            <IssueRowData
                                                webDet={webDe}
                                                rowData={data?.desktop?.considerFixing?.data}
                                            />
                                        </Div>
                                        <Div className={'EPCWTBFix'}>
                                            <Div className={'EPCWTBFHead'}>
                                                <Div
                                                    className={'EPCWTBFHIcon'}
                                                    style={{ color: '#00acfd' }}
                                                >
                                                    <DCSqHalfCheck />
                                                </Div>
                                                <Div className={'EPCWTBFHTitle'}>Passed</Div>
                                            </Div>

                                            <IssueRowData
                                                webDet={webDe}
                                                rowData={data?.desktop?.passed?.data}
                                            />
                                        </Div>
                                    </Div>
                                </Div>
                            </Div>
                        </Div>
                    ) : (
                        <NoFound
                            title={'Potential customers can’t find your website!'}
                            description={
                                <Fragment>
                                    <p>
                                        We’re having trouble finding your website. Either you don’t
                                        have a website setup at all, or the website is not optimized
                                        properly for potential customers to find.
                                        <br />
                                        We see this as a missed opportunity for you to take
                                        advantage of right away. Having a website is an important
                                        stepping stone for {details?.name}.
                                    </p>
                                </Fragment>
                            }
                        />
                    )}
                    <Div className={'EPCUserComt'}>
                        <Div className={'EPCUCInner'}>
                            <Div className={'EPCUCInfo'}>
                                <Div className={'EPCUCIImg'}>
                                    <Image
                                        src={generateImage(user?.image, user?.name)}
                                        displayName={user?.name}
                                        alt={user?.name}
                                    />
                                </Div>
                                <Div className={'EPCUCICmt'}>
                                    Would you like to create a brand new website that converts your
                                    clicks into customers? We can help!
                                </Div>
                            </Div>
                            <Div className={'EPCUCBtn'}>
                                <Button buttonType={'BlueFillBtn'} onClick={handleGetInTouchOpen}>
                                    Get in touch
                                </Button>
                            </Div>
                        </Div>
                    </Div>
                </Div>
            </Div>
        </Fragment>
    );
};

export default Website;
