import React from 'react';

import { SVGIcon } from '../../UIKit/index';

export default function DCVideoCall1(props) {
    return (
        <SVGIcon {...props} viewBox="0 0 512 512">
            <g transform="translate(0.15 0.158)">
                <path d="M295.27,220.35c0-21.94-17.79-39.73-39.73-39.73s-39.73,17.79-39.73,39.73   s17.79,39.73,39.73,39.73S295.27,242.29,295.27,220.35z M255.55,240.21c-10.97,0-19.86-8.89-19.86-19.86   c0-10.97,8.89-19.86,19.86-19.86c10.97,0,19.86,8.89,19.86,19.86C275.41,231.32,266.52,240.21,255.55,240.21z" />
                <path d="M70.81,438.85h140.04c5.49,0,9.93-4.45,9.93-9.93c0-5.49-4.45-9.93-9.93-9.93H70.81   c-23.6,0-43.95-16.6-48.67-39.73h466.81c-4.72,23.13-25.07,39.73-48.67,39.73H300.24c-5.49,0-9.93,4.45-9.93,9.93   c0,5.49,4.45,9.93,9.93,9.93h140.04c38.4,0,69.52-31.13,69.52-69.52c0-5.49-4.45-9.93-9.93-9.93h-34.76V101.16   c0-5.49-4.45-9.93-9.93-9.93h-70.52V11.77c0-5.49-4.45-9.93-9.94-9.93c-1.96,0-3.87,0.58-5.5,1.66l-73.95,49.3V11.77   c0-5.49-4.45-9.93-9.93-9.93H136.36c-5.49,0-9.93,4.45-9.93,9.93v79.46H55.91c-5.49,0-9.93,4.45-9.93,9.93V359.4H11.22   c-5.49,0-9.93,4.45-9.93,9.93C1.29,407.73,32.41,438.85,70.81,438.85z M305.21,359.4h-99.32v-29.8c0-27.43,22.23-49.66,49.66-49.66   s49.66,22.23,49.66,49.66V359.4z M364.8,30.33v82.08l-61.58-41.04L364.8,30.33z M146.29,21.71h129.12v99.32H146.29V21.71z    M65.84,111.09h60.59v19.86c0,5.49,4.45,9.93,9.93,9.93h148.98c5.49,0,9.93-4.45,9.93-9.93V89.92l73.95,49.3   c4.57,3.04,10.73,1.8,13.77-2.77c1.08-1.63,1.66-3.54,1.66-5.5v-19.86h60.59v248.3H325.07v-29.8c0-38.4-31.13-69.52-69.52-69.52   s-69.52,31.13-69.52,69.52v29.8H65.84V111.09z" />
                <ellipse cx="269.11" cy="421.43" rx="19.86" ry="9.93" />
            </g>
        </SVGIcon>
    );
}
