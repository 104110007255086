import React from 'react';

import { SVGIcon } from '../../UIKit/index';

export default function DCReportBug(props) {
    return (
        <SVGIcon {...props} viewBox="0 0 42 42" fill="none">
            <path
                d="M20 37C28.8366 37 36 29.8366 36 21C36 12.1634 28.8366 5 20 5C11.1634 5 4 12.1634 4 21C4 29.8366 11.1634 37 20 37Z"
                fill="#F58A97"
            />
            <path
                d="M20 5C19.3184 5 18.6468 5.04298 17.9876 5.12567C25.8741 6.11542 31.9753 12.8452 31.9753 21.0001C31.9753 29.1551 25.8743 35.8848 17.9876 36.8746C18.6468 36.9573 19.3184 37.0003 20 37.0003C28.8366 37.0003 36 29.8367 36 21.0003C36 12.1638 28.8366 5 20 5V5Z"
                fill="#F07281"
            />
            <path
                d="M29.058 30.5926C28.5023 30.5926 28.0519 30.1422 28.0519 29.5865C28.0519 27.5307 26.3793 25.8581 24.3234 25.8581H15.6768C13.6209 25.8581 11.9483 27.5307 11.9483 29.5865C11.9483 30.1422 11.4979 30.5926 10.9422 30.5926C10.3865 30.5926 9.93604 30.1422 9.93604 29.5865C9.93604 26.421 12.5113 23.8457 15.6768 23.8457H24.3234C27.4889 23.8457 30.0642 26.421 30.0642 29.5865C30.0642 30.1422 29.6137 30.5926 29.058 30.5926Z"
                fill="#3D6D93"
            />
            <path
                d="M21.2572 16.5753H18.7426C15.5772 16.5753 13.0017 14 13.0017 10.8345C13.0017 10.2788 13.4522 9.82837 14.0079 9.82837C14.5636 9.82837 15.014 10.2788 15.014 10.8345C15.014 12.8903 16.6866 14.5629 18.7425 14.5629H21.2571C23.3131 14.5629 24.9856 12.8903 24.9856 10.8345C24.9856 10.2788 25.4361 9.82837 25.9917 9.82837C26.5474 9.82837 26.9979 10.2788 26.9979 10.8345C26.9982 13.9999 24.4229 16.5753 21.2572 16.5753Z"
                fill="#3D6D93"
            />
            <path
                d="M24.3234 22.2269H15.6768C12.5113 22.2269 9.93604 19.6516 9.93604 16.4861C9.93604 15.9304 10.3865 15.48 10.9422 15.48C11.4979 15.48 11.9483 15.9304 11.9483 16.4861C11.9483 18.5421 13.6209 20.2146 15.6768 20.2146H24.3234C26.3793 20.2146 28.0519 18.5421 28.0519 16.4861C28.0519 15.9304 28.5023 15.48 29.058 15.48C29.6137 15.48 30.0642 15.9304 30.0642 16.4861C30.0642 19.6516 27.4889 22.2269 24.3234 22.2269Z"
                fill="#3D6D93"
            />
            <path
                d="M20.7226 28.6666H19.2775C16.3464 28.6666 13.9702 26.2904 13.9702 23.3593V19.8702C13.9702 16.9392 16.3464 14.563 19.2775 14.563H20.7226C23.6537 14.563 26.0299 16.9392 26.0299 19.8702V23.3593C26.03 26.2904 23.6538 28.6666 20.7226 28.6666Z"
                fill="#F6B2BB"
            />
            <path
                d="M20.7225 14.5627H19.2774C18.8324 14.5627 18.4005 14.6182 17.9876 14.7214C20.2952 15.2976 22.005 17.3838 22.005 19.87V23.3591C22.005 25.8453 20.2952 27.9315 17.9876 28.5077C18.4005 28.6109 18.8325 28.6663 19.2774 28.6663H20.7225C23.6536 28.6663 26.0298 26.2902 26.0298 23.3591V19.87C26.0299 16.9389 23.6537 14.5627 20.7225 14.5627Z"
                fill="#F8A6AF"
            />
            <path
                d="M28.1558 11.1501L32.4926 3.59103C32.9389 2.81308 34.0611 2.81308 34.5074 3.59103L38.8442 11.1501C39.2885 11.9244 38.7295 12.8895 37.8368 12.8895H29.1631C28.2705 12.8895 27.7115 11.9244 28.1558 11.1501Z"
                fill="#FFE179"
            />
            <path
                d="M38.8442 11.1501L34.5074 3.59103C34.0611 2.81308 32.9389 2.81308 32.4926 3.59103L32.4157 3.72508L36.6756 11.1501C37.1199 11.9244 36.5609 12.8895 35.6682 12.8895H37.8368C38.7295 12.8895 39.2885 11.9244 38.8442 11.1501Z"
                fill="#FBD268"
            />
            <path
                d="M33.5 9.98462C33.2005 9.98462 32.9578 9.74195 32.9578 9.44251V5.58589C32.9578 5.28644 33.2005 5.0437 33.5 5.0437C33.7994 5.0437 34.0421 5.28637 34.0421 5.58589V9.44251C34.0422 9.74195 33.7994 9.98462 33.5 9.98462Z"
                fill="white"
            />
            <path
                d="M33.5004 11.5609C33.8 11.5609 34.0429 11.318 34.0429 11.0184C34.0429 10.7187 33.8 10.4758 33.5004 10.4758C33.2008 10.4758 32.9579 10.7187 32.9579 11.0184C32.9579 11.318 33.2008 11.5609 33.5004 11.5609Z"
                fill="white"
            />
        </SVGIcon>
    );
}
