import { submitFormAPI, updateSubmission } from 'apis/Forms/index.api';
import React, { useEffect, Fragment, useCallback, useState } from 'react';
import { Div } from 'UIKit/index';
import './Assets/bootstrap.min.scss';
import './Assets/formio.full.min.scss';
import './style.scss';
import ReactPixel from 'react-facebook-pixel';
import useToast from 'Modules/Toasts';
import DCForm from 'Assets/icons/DCForm';
import { publicFileUpload } from '../PublicFileUpload';
import mime from 'mime-types';
import config from 'config';
import dayjs from 'dayjs';
import { isLocalStorageAvailable } from 'Utils/localStorageUtils';

const FormView = ({ formData, formInfo, readOnly, submissionData, inUserForm, internalApp }) => {
    const [onSubmitMessage, setSubmitMessage] = useState(false);

    const { showToast } = useToast();
    const [tmpFormValue, setTmpFormValue] = useState(null);
    const [submitClick, setSubmitClick] = useState();
    const [save, setSave] = useState(false);

    function loadScript(scriptURL) {
        const script = document.createElement('script');
        script.src = scriptURL;
        script.async = true;
        document.body.appendChild(script);
        return new Promise((resolve, reject) => {
            script.onload = () => {
                document.body.removeChild(script);
                resolve();
            };
            script.onerror = () => {
                document.body.removeChild(script);
                reject();
            };
        });
    }
    const pageRedirect = useCallback(url => {
        if (url.indexOf('http') === -1) {
            if (window.parent) {
                window.parent.location.href = `http://${url}`;
            } else {
                window.location.href = `http://${url}`;
            }
        } else {
            if (window.parent) {
                window.parent.location.href = url;
            } else {
                window.location.href = url;
            }
        }
    }, []);

    const handlePixelId = useCallback((pixel_id, data, event) => {
        const options = {
            autoConfig: true,
            debug: false,
        };
        ReactPixel.init(pixel_id, null, options);
        ReactPixel.trackSingle(pixel_id, event, data);
    }, []);

    function dataURLtoFile(dataurl, filename) {
        var arr = dataurl?.split(','),
            _mime = arr[0]?.match(/:(.*?);/)[1],
            bstr = atob(arr[1]),
            n = bstr.length,
            u8arr = new Uint8Array(n);

        while (n--) {
            u8arr[n] = bstr.charCodeAt(n);
        }

        return new File([u8arr], filename, { type: _mime });
    }

    const handleFiles = useCallback(
        async submission => {
            for (const item in submission?.data) {
                if (
                    (item.includes('file') ||
                        item.includes('image') ||
                        item.includes('signature')) &&
                    submission.data[item].length
                ) {
                    let file = '';
                    if (item.match(/^signature.*$/)) {
                        file = dataURLtoFile(submission.data[item], `${item}.jpg`);
                    } else {
                        file = dataURLtoFile(
                            submission.data[item][0]?.url,
                            submission.data[item][0]?.originalName,
                        );
                    }
                    let realType = file.type;
                    if (file.name.split('.').pop() === 'csv') {
                        realType = mime.lookup('csv');
                    }
                    const response = await publicFileUpload(
                        file,
                        {
                            type: realType,
                            name: file.name,
                            size: file.size,
                        },
                        formInfo?.id,
                    );

                    if (response.status === 'Success') {
                        submission.data[item] = [{ key: response.key, size: file.size }];
                    } else {
                        return { response: 'failed' };
                    }
                }
            }
            return { response: 'success', data: submission };
        },
        [formInfo?.id],
    );

    const getLocalStorageData = useCallback(n => {
        switch (n) {
            case 0: {
                let data = JSON.parse(localStorage.getItem('form-data') || '{}');
                return data;
            }
            case 1: {
                let formkey = '';
                if (window.location.href.includes('userform')) {
                    formkey = window.location.href.split('userform/')[1];
                } else {
                    let urlPart = window.location.href.split('/');
                    formkey = urlPart[urlPart?.length - 1];
                }
                return formkey;
            }
        }
    }, []);

    const handleFormSubmit = useCallback(
        async submission => {
            const modifiedData = await handleFiles(submission);

            if (modifiedData.response === 'success') {
                let data = {
                    data: modifiedData.data,
                    form_type:
                        formInfo?.form_type && formInfo?.iscampaignConnected
                            ? formInfo?.form_type
                            : null,
                    rid: formInfo?.rId,
                    rqid: formInfo?.rqId,
                };
                if (submissionData) {
                    updateSubmission(
                        { updateData: submission.data },
                        formInfo?.id,
                        submissionData.id,
                    )
                        .then(() => {
                            showToast({
                                type: 'success',
                                message: 'Submission updated successfully',
                            });
                        })
                        .catch(() => {
                            showToast({
                                type: 'error',
                                message: 'Failed to update submission',
                            });
                        });
                } else {
                    submitFormAPI(data, formInfo?.formId)
                        .then(() => {
                            setSubmitMessage(true);
                            setSubmitClick(true);
                            if (isLocalStorageAvailable()) {
                                let _formData = getLocalStorageData(0);
                                let formkey = getLocalStorageData(1);
                                delete _formData[formkey];
                                localStorage.setItem('form-data', JSON.stringify({ ..._formData }));
                            }
                            setTimeout(() => {
                                if (formInfo.redirect) {
                                    pageRedirect(formInfo.redirect);
                                }
                                if (formInfo.onSubmitOption) {
                                    handlePixelId(formInfo.pixelId, data, formInfo.onSubmitOption);
                                }
                            }, 2000);
                        })
                        .catch(err => {
                            showToast({
                                type: 'error',
                                message: 'Failed to submit form',
                            });
                            console.error(err);
                        });
                }
            } else {
                showToast({
                    type: 'error',
                    message: 'Failed to upload Files',
                });
            }
        },
        [
            formInfo,
            handleFiles,
            handlePixelId,
            pageRedirect,
            showToast,
            submissionData,
            getLocalStorageData,
        ],
    );

    const handleSubmitDisable = useCallback(() => {
        let labelForSubmit = formData.components.find(item => item.key === 'submit');
        let filteredComponets = formData.components.filter(el => el.type !== 'button');
        let data = [
            ...filteredComponets,
            {
                type: 'button',
                label: labelForSubmit.label,
                key: 'submit',
                disableOnInvalid: true,
                disabled: true,
                input: true,
            },
        ];
        return data;
    }, [formData?.components]);

    const handleAutoSave = useCallback(() => {
        setTimeout(() => {
            const _formData = getLocalStorageData(0);
            let formval = getLocalStorageData(1);

            if (Object.keys(tmpFormValue?.data)?.length) {
                localStorage.setItem(
                    'form-data',
                    JSON.stringify({
                        ..._formData,
                        [formval]: {
                            data: { ...tmpFormValue?.data },
                            last_updated: dayjs().format('h:mm a'),
                        },
                    }),
                );
            }
        }, 2000);
    }, [tmpFormValue, getLocalStorageData]);

    useEffect(() => {
        if (save && tmpFormValue && tmpFormValue?.data && !submitClick) {
            isLocalStorageAvailable() && handleAutoSave(true);
            setSave(false);
        }
    }, [handleAutoSave, tmpFormValue, save, submitClick]);

    useEffect(() => {
        if (isLocalStorageAvailable())
            window.addEventListener('beforeunload', () => {
                if (inUserForm && !submitClick) {
                    return false;
                } else {
                    let _formData = getLocalStorageData(0);
                    let formkey = getLocalStorageData(1);
                    delete _formData[formkey];
                    localStorage.setItem('form-data', JSON.stringify({ ..._formData }));
                }
                return undefined;
            });
        return () => {
            if (isLocalStorageAvailable()) window.removeEventListener('beforeunload', () => {});
        };
    }, [inUserForm, submitClick, getLocalStorageData]);

    const changeType = el => {
        el?.components?.forEach(els => {
            if (['date', 'datetime', 'password'].includes(els.type)) {
                els.type = 'textfield';
            }
            if (els.type === 'phoneNumber' && els.allowMultipleMasks) {
                if (submissionData.data.hasOwnProperty(els.key)) {
                    let fl = els.inputMasks.findIndex(
                        obj => obj.label === submissionData.data[els.key].maskName,
                    );
                    els.selectedIndex = fl;
                    let temp = els.inputMasks[fl];
                    els.inputMasks[fl] = els.inputMasks[0];
                    els.inputMasks[0] = temp;
                } else {
                    submissionData.data[els.key] = {
                        value: '',
                        maskName: '🏳️ +00',
                    };
                    submissionData[els.key] = {
                        value: '',
                        maskName: '🏳️ +00',
                    };
                    els.inputMasks = [{ label: '🏳️ +00', mask: '' }, ...els.inputMasks];
                }
            }
            if (el.components) {
                changeType(els);
            }
        });
    };

    useEffect(() => {
        (async () => {
            loadScript(`${config.REACT_APP_ASSETS_BASE_URL}/formio.full.min.js`)
                .then(() => {
                    if (readOnly) {
                        let subDataValue;
                        if (submissionData.hasOwnProperty('data')) {
                            subDataValue = submissionData[Object.keys(submissionData.data)[0]];
                        } else {
                            subDataValue = submissionData?.firstName;
                        }
                        if (formData.display === 'form') {
                            if (submissionData && (subDataValue || subDataValue === '')) {
                                let filteredComponets = formData.components.filter(
                                    el => el.type !== 'button',
                                );
                                filteredComponets.map(el => {
                                    if (['date', 'datetime', 'password'].includes(el.type)) {
                                        el.type = 'textfield';
                                    }
                                    if (el.type === 'phoneNumber' && el.allowMultipleMasks) {
                                        if (submissionData.hasOwnProperty(el.key)) {
                                            if (submissionData[el.key].value === '') {
                                                submissionData[el.key].maskName = '';
                                                el.inputMasks = [
                                                    ...el.inputMasks,
                                                    { label: '', mask: '' },
                                                ];
                                            }
                                            let fl = el.inputMasks.findIndex(
                                                obj => obj.label === submissionData[el.key].maskName,
                                            );
                                            el.selectedIndex = fl;
                                            let temp = el.inputMasks[fl];
                                            el.inputMasks[fl] = el.inputMasks[0];
                                            el.inputMasks[0] = temp;
                                        } else {
                                            submissionData.data[el.key] = {
                                                value: '',
                                                maskName: '🏳️ +00',
                                            };
                                            submissionData[el.key] = {
                                                value: '',
                                                maskName: '🏳️ +00',
                                            };
                                            el.inputMasks = [
                                                { label: '🏳️ +00', mask: '' },
                                                ...el.inputMasks,
                                            ];
                                        }
                                    }
                                    return el;
                                });

                                // eslint-disable-next-line no-undef
                                Formio.createForm(
                                    document.getElementById('dcFormBuilderView'),
                                    { components: filteredComponets, display: 'form' },
                                    {
                                        readOnly: true,
                                        saveDraft: true,
                                        // renderMode: 'html',
                                    },
                                ).then(instance => {
                                    // eslint-disable-next-line no-undef
                                    instance.submission = { data: submissionData };
                                });
                            }
                        } else {
                            if (submissionData && (subDataValue || subDataValue === '')) {
                                const filteredComponets = formData.components.filter(
                                    el => el.type !== 'button',
                                );
                                filteredComponets.forEach(el => {
                                    changeType(el);
                                });
                                // eslint-disable-next-line no-undef
                                Formio.createForm(
                                    document.getElementById('dcFormBuilderView'),
                                    { components: filteredComponets, display: 'wizard' },
                                    {
                                        readOnly: true,
                                        saveDraft: true,
                                        // renderMode: 'html',
                                    },
                                ).then(instance => {
                                    // eslint-disable-next-line no-undef
                                    instance.submission = { data: submissionData };
                                });
                            }
                        }
                    } else {
                        if (internalApp) {
                            let subDataValue;
                            if (submissionData.hasOwnProperty('data')) {
                                subDataValue = submissionData[Object.keys(submissionData.data)[0]];
                            } else {
                                subDataValue = submissionData.firstName;
                            }
                            if (formData.display === 'form') {
                                if (submissionData && (subDataValue || subDataValue === '')) {
                                    const filteredComponets = formData.components.filter(
                                        el => el.type !== 'button',
                                    );
                                    filteredComponets.map(el => {
                                        if (['date', 'datetime', 'password'].includes(el.type)) {
                                            el.type = 'textfield';
                                        }
                                        if (el.type === 'phoneNumber' && el.allowMultipleMasks) {
                                            if (submissionData.hasOwnProperty(el.key)) {
                                                let fl = el.inputMasks.findIndex(obj => {
                                                    return (
                                                        obj.label ===
                                                        submissionData[el.key].maskName
                                                    );
                                                });
                                                el.selectedIndex = fl;
                                                let temp = el.inputMasks[fl];
                                                el.inputMasks[fl] = el.inputMasks[0];
                                                el.inputMasks[0] = temp;
                                            } else {
                                                submissionData.data[el.key] = {
                                                    value: '',
                                                    maskName: '🏳️ +00',
                                                };
                                                submissionData[el.key] = {
                                                    value: '',
                                                    maskName: '🏳️ +00',
                                                };
                                                el.inputMasks = [
                                                    { label: '🏳️ +00', mask: '' },
                                                    ...el.inputMasks,
                                                ];
                                            }
                                        }
                                    });
                                }
                            } else {
                                if (submissionData) {
                                    const filteredComponets = formData.components.filter(
                                        el => el.type !== 'button',
                                    );
                                    filteredComponets.forEach(el => {
                                        changeType(el);
                                    });
                                }
                            }
                        }

                        let submitDisableData = formData.components;
                        // eslint-disable-next-line no-undef
                        Formio.createForm(
                            document.getElementById('dcFormBuilderView'),
                            {
                                ...formData,
                                components: !inUserForm ? submitDisableData : formData.components,
                            },
                            {
                                buttonSettings: {
                                    showCancel: false,
                                    showPrevious: true,
                                    showNext: true,
                                    showSubmit: true,
                                },
                                saveDraft: true,
                            },
                        ).then(form => {
                            if (submissionData) form.submission = { data: submissionData };
                            if (isLocalStorageAvailable()) {
                                if (
                                    !internalApp &&
                                    inUserForm &&
                                    JSON.parse(localStorage.getItem('form-data')) &&
                                    Object.keys(JSON.parse(localStorage.getItem('form-data')))
                                        ?.length
                                ) {
                                    let datas = JSON.parse(
                                        localStorage.getItem('form-data') || '{}',
                                    );
                                    let url = window.location.href.split('/');
                                    let formkey = url[url.length - 1];
                                    if (datas[formkey]?.data) {
                                        form.submission = { data: datas[formkey]?.data };
                                    }
                                }
                            }
                            if (inUserForm) {
                                form.on('change', e => {
                                    let res = { ...tmpFormValue };
                                    setTmpFormValue({ ...res, ...e });
                                });
                                let typingTimer;
                                let doneTypingInterval = 5000;
                                form.element.addEventListener('keyup', () => {
                                    clearTimeout(typingTimer);
                                    typingTimer = setTimeout(() => {
                                        setSave(true);
                                    }, doneTypingInterval);
                                });
                                form.element.addEventListener('keydown', () => {
                                    clearTimeout(typingTimer);
                                });
                            }

                            // Register for the submit event to get the completed submission.
                            form.on('submit', submission => {
                                if (!inUserForm) form.resetValue();
                                else handleFormSubmit(submission);
                            });
                        });
                    }
                })
                .catch(() => {});
        })();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        formData,
        handleFiles,
        handleFormSubmit,
        handleSubmitDisable,
        inUserForm,
        readOnly,
        submissionData,
    ]);

    return (
        <Fragment>
            <Div className="DCFormBuilder">
                {onSubmitMessage ? (
                    <Div className="FPDThanks">
                        <Div className="FPDTInner">
                            <Div className="FPDTIcon">
                                <DCForm />
                            </Div>
                            <Div className="FPDTTitle">
                                <center>{formInfo.message}</center>
                            </Div>
                        </Div>
                    </Div>
                ) : (
                    <Div className="DCFBInner">
                        <div id="dcFormBuilderView" className="DCFBVew" />
                    </Div>
                )}
            </Div>
        </Fragment>
    );
};

export default FormView;
