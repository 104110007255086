import React from 'react';

import { SVGIcon } from '../../UIKit/index';

export default function DCLink(props) {
    return (
        <SVGIcon {...props} viewBox="0 0 512 512">
            <path d="M163.88,431.2c-23.42,22.89-60.96,22.46-83.85-0.96c-22.09-22.6-22.57-58.57-1.09-81.75   L203.9,223.54c-39.91-10.77-82.55,0.04-112.51,28.52l-55.57,55.57c-45.7,46.94-44.7,122.04,2.24,167.74s122.04,44.7,167.74-2.24   l51.23-51.28c29.94-29.92,41.65-73.53,30.72-114.42L163.88,431.2z" />
            <path d="M474.46,36.74C429.01-8.73,355.58-9.68,308.96,34.6l-55.54,55.42   c-28.45,29.93-39.27,72.52-28.55,112.4L349.83,77.58c23.83-22.47,61.35-21.36,83.82,2.46c21.94,23.27,21.48,59.75-1.05,82.45   l-123.84,123.9c40.89,10.93,84.5-0.78,114.42-30.72l51.28-51.23c46.31-46.27,46.34-121.32,0.08-167.62   C474.51,36.79,474.49,36.77,474.46,36.74z" />
            <path d="M166.17,345.06c11.57,11.58,30.33,11.59,41.91,0.02c0.01-0.01,0.01-0.01,0.02-0.02   l138.33-138.33c11.72-11.42,11.97-30.19,0.54-41.91c-11.42-11.72-30.19-11.97-41.91-0.54L165.64,303.69   C154.57,315.34,154.81,333.7,166.17,345.06z" />
        </SVGIcon>
    );
}
