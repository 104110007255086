import React from 'react';

import { SVGIcon } from '../../UIKit/index';

export default function DCGroupForm1(props) {
    return (
        <SVGIcon {...props} viewBox="0 0 504.405 488.585">
            <g id="Group_Form" transform="translate(-2.975 -8.555)">
                <circle
                    id="Ellipse_1"
                    data-name="Ellipse 1"
                    cx="7.64"
                    cy="7.64"
                    r="7.64"
                    transform="translate(483.42 45.38)"
                    fill="#fff"
                    stroke="#e1e1e1"
                    strokeMiterlimit="10"
                    strokeWidth="2"
                />
                <circle
                    id="Ellipse_2"
                    data-name="Ellipse 2"
                    cx="12.44"
                    cy="12.44"
                    r="12.44"
                    transform="translate(411.71 17.21)"
                    fill="#fff"
                    stroke="#e1e1e1"
                    strokeMiterlimit="10"
                    strokeWidth="2"
                />
                <path
                    id="Path_1"
                    data-name="Path 1"
                    d="M23.54,56.08H15V47.59a1.06,1.06,0,0,0-1-1.05h0a1.08,1.08,0,0,0-1.08,1.05v8.49H4.42a1.07,1.07,0,1,0,0,2.14H12.9v8.5a1.072,1.072,0,0,0,2.14,0h0v-8.5h8.5a1.06,1.06,0,0,0,1.06-1.06,1.08,1.08,0,0,0-1.06-1.08Z"
                    fill="#e1e1e1"
                    stroke="#e1e1e1"
                    strokeMiterlimit="10"
                    strokeWidth="0.75"
                />
                <path
                    id="Path_2"
                    data-name="Path 2"
                    d="M84,16.57H77.2V9.77a.84.84,0,0,0-.85-.84h0a.86.86,0,0,0-.86.84v6.8H68.7a.86.86,0,0,0-.85.86.85.85,0,0,0,.85.85h6.79v6.8a.86.86,0,0,0,.86.85.85.85,0,0,0,.85-.85h0v-6.8H84a.86.86,0,1,0,0-1.71Z"
                    fill="#e1e1e1"
                    stroke="#e1e1e1"
                    strokeMiterlimit="10"
                    strokeWidth="0.75"
                />
                <rect
                    id="Rectangle_1"
                    data-name="Rectangle 1"
                    width="2.12"
                    height="165.59"
                    transform="translate(37.07 111.05)"
                    fill="#e3e2e2"
                />
                <rect
                    id="Rectangle_2"
                    data-name="Rectangle 2"
                    width="2.12"
                    height="165.59"
                    transform="translate(169.26 111.05)"
                    fill="#e3e2e2"
                />
                <path
                    id="Path_3"
                    data-name="Path 3"
                    d="M179.7,121.46H28.75l-3.18-7.43H182.88Z"
                    fill="#f3f4f5"
                />
                <path
                    id="Path_4"
                    data-name="Path 4"
                    d="M179.7,131.67H28.75l-3.18-7.44H182.88Z"
                    fill="#f3f4f5"
                />
                <path
                    id="Path_5"
                    data-name="Path 5"
                    d="M179.7,141.87H28.75l-3.18-7.43H182.88Z"
                    fill="#f3f4f5"
                />
                <path
                    id="Path_6"
                    data-name="Path 6"
                    d="M179.7,152.07H28.75l-3.18-7.43H182.88Z"
                    fill="#f3f4f5"
                />
                <path
                    id="Path_7"
                    data-name="Path 7"
                    d="M179.7,162.28H28.75l-3.18-7.43H182.88Z"
                    fill="#f3f4f5"
                />
                <path
                    id="Path_8"
                    data-name="Path 8"
                    d="M179.7,172.48H28.75l-3.18-7.43H182.88Z"
                    fill="#f3f4f5"
                />
                <path
                    id="Path_9"
                    data-name="Path 9"
                    d="M179.7,182.69H28.75l-3.18-7.44H182.88Z"
                    fill="#f3f4f5"
                />
                <path
                    id="Path_10"
                    data-name="Path 10"
                    d="M179.7,192.89H28.75l-3.18-7.43H182.88Z"
                    fill="#f3f4f5"
                />
                <path
                    id="Path_11"
                    data-name="Path 11"
                    d="M179.7,203.09H28.75l-3.18-7.43H182.88Z"
                    fill="#f3f4f5"
                />
                <path
                    id="Path_12"
                    data-name="Path 12"
                    d="M179.7,213.3H28.75l-3.18-7.43H182.88Z"
                    fill="#f3f4f5"
                />
                <path
                    id="Path_13"
                    data-name="Path 13"
                    d="M179.7,223.5H28.75l-3.18-7.43H182.88Z"
                    fill="#f3f4f5"
                />
                <path
                    id="Path_14"
                    data-name="Path 14"
                    d="M179.7,233.71H28.75l-3.18-7.44H182.88Z"
                    fill="#f3f4f5"
                />
                <path
                    id="Path_15"
                    data-name="Path 15"
                    d="M179.7,243.91H28.75l-3.18-7.43H182.88Z"
                    fill="#f3f4f5"
                />
                <path
                    id="Path_16"
                    data-name="Path 16"
                    d="M179.7,254.11H28.75l-3.18-7.43H182.88Z"
                    fill="#f3f4f5"
                />
                <path
                    id="Path_17"
                    data-name="Path 17"
                    d="M179.7,264.32H28.75l-3.18-7.44H182.88Z"
                    fill="#f3f4f5"
                />
                <path
                    id="Path_18"
                    data-name="Path 18"
                    d="M179.7,274.52H28.75l-3.18-7.43H182.88Z"
                    fill="#f3f4f5"
                />
                <rect
                    id="Rectangle_3"
                    data-name="Rectangle 3"
                    width="157.31"
                    height="4.25"
                    transform="translate(25.57 274.52)"
                    fill="#e3e2e2"
                />
                <rect
                    id="Rectangle_4"
                    data-name="Rectangle 4"
                    width="168.29"
                    height="9.91"
                    transform="translate(20.08 104.72)"
                    fill="#e3e2e2"
                />
                <path
                    id="Path_19"
                    data-name="Path 19"
                    d="M21.81,204.48h0a1.71,1.71,0,0,1-1.71-1.71V145a1.7,1.7,0,0,1,1.71-1.71h0A1.71,1.71,0,0,1,23.52,145v57.8a1.72,1.72,0,0,1-1.71,1.68Z"
                    fill="#e3e2e2"
                />
                <rect
                    id="Rectangle_5"
                    data-name="Rectangle 5"
                    width="1.24"
                    height="34.33"
                    transform="translate(21.19 112.65)"
                    fill="#e3e2e2"
                />
                <rect
                    id="Rectangle_6"
                    data-name="Rectangle 6"
                    width="2.12"
                    height="165.59"
                    transform="translate(347.4 111.05)"
                    fill="#e3e2e2"
                />
                <rect
                    id="Rectangle_7"
                    data-name="Rectangle 7"
                    width="2.12"
                    height="165.59"
                    transform="translate(479.59 111.05)"
                    fill="#e3e2e2"
                />
                <path
                    id="Path_20"
                    data-name="Path 20"
                    d="M490.03,121.46H339.08l-3.18-7.43H493.21Z"
                    fill="#f3f4f5"
                />
                <path
                    id="Path_21"
                    data-name="Path 21"
                    d="M490.03,131.67H339.08l-3.18-7.44H493.21Z"
                    fill="#f3f4f5"
                />
                <path
                    id="Path_22"
                    data-name="Path 22"
                    d="M490.03,141.87H339.08l-3.18-7.43H493.21Z"
                    fill="#f3f4f5"
                />
                <path
                    id="Path_23"
                    data-name="Path 23"
                    d="M490.03,152.07H339.08l-3.18-7.43H493.21Z"
                    fill="#f3f4f5"
                />
                <path
                    id="Path_24"
                    data-name="Path 24"
                    d="M490.03,162.28H339.08l-3.18-7.43H493.21Z"
                    fill="#f3f4f5"
                />
                <path
                    id="Path_25"
                    data-name="Path 25"
                    d="M490.03,172.48H339.08l-3.18-7.43H493.21Z"
                    fill="#f3f4f5"
                />
                <path
                    id="Path_26"
                    data-name="Path 26"
                    d="M490.03,182.69H339.08l-3.18-7.44H493.21Z"
                    fill="#f3f4f5"
                />
                <path
                    id="Path_27"
                    data-name="Path 27"
                    d="M490.03,192.89H339.08l-3.18-7.43H493.21Z"
                    fill="#f3f4f5"
                />
                <path
                    id="Path_28"
                    data-name="Path 28"
                    d="M490.03,203.09H339.08l-3.18-7.43H493.21Z"
                    fill="#f3f4f5"
                />
                <path
                    id="Path_29"
                    data-name="Path 29"
                    d="M490.03,213.3H339.08l-3.18-7.43H493.21Z"
                    fill="#f3f4f5"
                />
                <path
                    id="Path_30"
                    data-name="Path 30"
                    d="M490.03,223.5H339.08l-3.18-7.43H493.21Z"
                    fill="#f3f4f5"
                />
                <path
                    id="Path_31"
                    data-name="Path 31"
                    d="M490.03,233.71H339.08l-3.18-7.44H493.21Z"
                    fill="#f3f4f5"
                />
                <path
                    id="Path_32"
                    data-name="Path 32"
                    d="M490.03,243.91H339.08l-3.18-7.43H493.21Z"
                    fill="#f3f4f5"
                />
                <path
                    id="Path_33"
                    data-name="Path 33"
                    d="M490.03,254.11H339.08l-3.18-7.43H493.21Z"
                    fill="#f3f4f5"
                />
                <path
                    id="Path_34"
                    data-name="Path 34"
                    d="M490.03,264.32H339.08l-3.18-7.44H493.21Z"
                    fill="#f3f4f5"
                />
                <path
                    id="Path_35"
                    data-name="Path 35"
                    d="M490.03,274.52H339.08l-3.18-7.43H493.21Z"
                    fill="#f3f4f5"
                />
                <rect
                    id="Rectangle_8"
                    data-name="Rectangle 8"
                    width="157.31"
                    height="4.25"
                    transform="translate(335.9 274.52)"
                    fill="#e3e2e2"
                />
                <rect
                    id="Rectangle_9"
                    data-name="Rectangle 9"
                    width="168.29"
                    height="9.91"
                    transform="translate(330.41 104.72)"
                    fill="#e3e2e2"
                />
                <path
                    id="Path_36"
                    data-name="Path 36"
                    d="M332.14,204.48h0a1.72,1.72,0,0,1-1.71-1.71V145a1.71,1.71,0,0,1,1.71-1.71h0a1.7,1.7,0,0,1,1.71,1.71v57.8a1.71,1.71,0,0,1-1.71,1.68Z"
                    fill="#e1e1e1"
                />
                <rect
                    id="Rectangle_10"
                    data-name="Rectangle 10"
                    width="1.24"
                    height="34.33"
                    transform="translate(331.52 112.65)"
                    fill="#e1e1e1"
                />
                <rect
                    id="Rectangle_11"
                    data-name="Rectangle 11"
                    width="359.36"
                    height="203.27"
                    transform="translate(78.26 87.25)"
                    fill="#e3e2e2"
                />
                <rect
                    id="Rectangle_12"
                    data-name="Rectangle 12"
                    width="367.3"
                    height="8.5"
                    rx="1.64"
                    transform="translate(74.29 286.98)"
                    fill="#f3f4f5"
                />
                <rect
                    id="Rectangle_13"
                    data-name="Rectangle 13"
                    width="342.85"
                    height="195.49"
                    transform="translate(86.51 93.43)"
                    fill="#fafafa"
                />
                <path
                    id="Path_37"
                    data-name="Path 37"
                    d="M276.82,325.28l-16.95-18.65V295.48a1.93,1.93,0,0,0-3.86,0v11.15l-16.95,18.65c-1.76,1.94,1,4.94,2.73,3L256,312.64v14a1.93,1.93,0,1,0,3.86,0v-14l14.22,15.64C275.84,330.22,278.58,327.22,276.82,325.28Z"
                    fill="#e3e2e2"
                />
                <rect
                    id="Rectangle_14"
                    data-name="Rectangle 14"
                    width="386.31"
                    height="18.66"
                    rx="4.14"
                    transform="translate(64.79 77.92)"
                    fill="#f3f4f5"
                />
                <rect
                    id="Rectangle_15"
                    data-name="Rectangle 15"
                    width="151.88"
                    height="52.43"
                    transform="translate(24.81 329.56)"
                    fill="#e3e2e2"
                    opacity="0.7"
                />
                <rect
                    id="Rectangle_16"
                    data-name="Rectangle 16"
                    width="151.88"
                    height="52.43"
                    transform="translate(24.81 381.98)"
                    fill="#e3e2e2"
                    opacity="0.7"
                />
                <g id="Group_1" data-name="Group 1" opacity="0.4">
                    <path
                        id="Path_38"
                        data-name="Path 38"
                        d="M82.53,381.98l18.9-52.42H72.34L53.45,381.98Z"
                        fill="#fff"
                    />
                    <path
                        id="Path_39"
                        data-name="Path 39"
                        d="M115.5,329.56h-6.39L90.22,381.98h6.39Z"
                        fill="#fff"
                    />
                </g>
                <g id="Group_2" data-name="Group 2" opacity="0.4">
                    <path
                        id="Path_40"
                        data-name="Path 40"
                        d="M63.63,434.41l18.9-52.43H53.45l-18.9,52.43Z"
                        fill="#fff"
                    />
                    <path
                        id="Path_41"
                        data-name="Path 41"
                        d="M96.61,381.98H90.22l-18.9,52.43h6.39Z"
                        fill="#fff"
                    />
                </g>
                <rect
                    id="Rectangle_17"
                    data-name="Rectangle 17"
                    width="5.3"
                    height="151.88"
                    transform="translate(176.69 332.31) rotate(90)"
                    fill="#e3e2e2"
                />
                <rect
                    id="Rectangle_18"
                    data-name="Rectangle 18"
                    width="5.3"
                    height="151.88"
                    transform="translate(176.69 384.33) rotate(90)"
                    fill="#e3e2e2"
                />
                <path
                    id="Path_42"
                    data-name="Path 42"
                    d="M104.64,362.48a2.28,2.28,0,1,0,0-4.55H96.87a2.28,2.28,0,1,0,0,4.55Z"
                    fill="#e1e1e1"
                />
                <path
                    id="Path_43"
                    data-name="Path 43"
                    d="M104.64,361.68a2.28,2.28,0,1,0,0-4.56H96.87a2.28,2.28,0,0,0,0,4.56Z"
                    fill="#f5f5f5"
                />
                <path
                    id="Path_44"
                    data-name="Path 44"
                    d="M104.64,410.88a2.28,2.28,0,1,0,0-4.56H96.87a2.28,2.28,0,0,0,0,4.56Z"
                    fill="#e1e1e1"
                />
                <path
                    id="Path_45"
                    data-name="Path 45"
                    d="M104.64,410.07a2.28,2.28,0,1,0,0-4.55H96.87a2.28,2.28,0,1,0,0,4.55Z"
                    fill="#f5f5f5"
                />
                <path
                    id="Path_46"
                    data-name="Path 46"
                    d="M22.16,327.21V436.7H179.35V327.21ZM174,431.4H27.47V384.76H174Zm0-51.95H27.47V332.81H174Z"
                    fill="#f3f4f5"
                />
                <rect
                    id="Rectangle_19"
                    data-name="Rectangle 19"
                    width="151.88"
                    height="52.43"
                    transform="translate(182 329.56)"
                    fill="#e3e2e2"
                    opacity="0.7"
                />
                <rect
                    id="Rectangle_20"
                    data-name="Rectangle 20"
                    width="151.88"
                    height="52.43"
                    transform="translate(182 381.98)"
                    fill="#e3e2e2"
                    opacity="0.7"
                />
                <g id="Group_3" data-name="Group 3" opacity="0.4">
                    <path
                        id="Path_47"
                        data-name="Path 47"
                        d="M299.76,381.98l18.89-52.42H289.57l-18.9,52.42Z"
                        fill="#fff"
                    />
                    <path
                        id="Path_48"
                        data-name="Path 48"
                        d="M332.73,329.56h-6.39l-18.9,52.42h6.39Z"
                        fill="#fff"
                    />
                </g>
                <g id="Group_4" data-name="Group 4" opacity="0.4">
                    <path
                        id="Path_49"
                        data-name="Path 49"
                        d="M280.86,434.41l18.9-52.43H270.67l-18.89,52.43Z"
                        fill="#fff"
                    />
                    <path
                        id="Path_50"
                        data-name="Path 50"
                        d="M313.83,381.98h-6.39l-18.89,52.43h6.39Z"
                        fill="#fff"
                    />
                </g>
                <rect
                    id="Rectangle_21"
                    data-name="Rectangle 21"
                    width="5.3"
                    height="151.88"
                    transform="translate(333.88 332.32) rotate(90)"
                    fill="#e3e2e2"
                />
                <rect
                    id="Rectangle_22"
                    data-name="Rectangle 22"
                    width="5.3"
                    height="151.88"
                    transform="translate(333.88 384.34) rotate(90)"
                    fill="#e3e2e2"
                />
                <g id="Group_5" data-name="Group 5" opacity="0.4">
                    <path
                        id="Path_51"
                        data-name="Path 51"
                        d="M261.82,362.48a2.28,2.28,0,1,0,0-4.55h-7.77a2.28,2.28,0,0,0,0,4.55Z"
                        fill="#fff"
                    />
                    <path
                        id="Path_52"
                        data-name="Path 52"
                        d="M261.82,361.68a2.28,2.28,0,0,0,0-4.56h-7.77a2.28,2.28,0,1,0,0,4.56Z"
                        fill="#fff"
                    />
                </g>
                <g id="Group_6" data-name="Group 6" opacity="0.4">
                    <path
                        id="Path_53"
                        data-name="Path 53"
                        d="M261.82,410.88a2.28,2.28,0,1,0,0-4.56h-7.77a2.28,2.28,0,1,0,0,4.56Z"
                        fill="#fff"
                    />
                    <path
                        id="Path_54"
                        data-name="Path 54"
                        d="M261.82,410.07a2.28,2.28,0,1,0,0-4.55h-7.77a2.28,2.28,0,0,0,0,4.55Z"
                        fill="#fff"
                    />
                </g>
                <path
                    id="Path_55"
                    data-name="Path 55"
                    d="M179.35,327.21V436.7H336.53V327.21ZM331.23,431.4H184.65V384.76H331.23Zm0-51.95H184.65V332.81H331.23Z"
                    fill="#f3f4f5"
                />
                <rect
                    id="Rectangle_23"
                    data-name="Rectangle 23"
                    width="151.88"
                    height="52.43"
                    transform="translate(339.18 329.56)"
                    fill="#e3e2e2"
                    opacity="0.7"
                />
                <rect
                    id="Rectangle_24"
                    data-name="Rectangle 24"
                    width="151.88"
                    height="52.43"
                    transform="translate(339.18 381.98)"
                    fill="#e3e2e2"
                    opacity="0.7"
                />
                <g id="Group_7" data-name="Group 7" opacity="0.4">
                    <path
                        id="Path_56"
                        data-name="Path 56"
                        d="M435.36,381.98l18.9-52.42H425.18l-18.9,52.42Z"
                        fill="#fff"
                    />
                    <path
                        id="Path_57"
                        data-name="Path 57"
                        d="M468.33,329.56h-6.38l-18.9,52.42h6.39Z"
                        fill="#fff"
                    />
                </g>
                <g id="Group_8" data-name="Group 8" opacity="0.4">
                    <path
                        id="Path_58"
                        data-name="Path 58"
                        d="M416.46,434.41l18.9-52.43H406.28l-18.9,52.43Z"
                        fill="#fff"
                    />
                    <path
                        id="Path_59"
                        data-name="Path 59"
                        d="M449.44,381.98h-6.39l-18.9,52.43h6.39Z"
                        fill="#fff"
                    />
                </g>
                <rect
                    id="Rectangle_25"
                    data-name="Rectangle 25"
                    width="5.3"
                    height="151.88"
                    transform="translate(491.06 332.32) rotate(90)"
                    fill="#e3e2e2"
                />
                <rect
                    id="Rectangle_26"
                    data-name="Rectangle 26"
                    width="5.3"
                    height="151.88"
                    transform="translate(491.06 384.33) rotate(90)"
                    fill="#e3e2e2"
                />
                <g id="Group_9" data-name="Group 9" opacity="0.4">
                    <path
                        id="Path_60"
                        data-name="Path 60"
                        d="M419,362.48a2.28,2.28,0,1,0,0-4.55h-7.77a2.28,2.28,0,1,0,0,4.55Z"
                        fill="#fff"
                    />
                    <path
                        id="Path_61"
                        data-name="Path 61"
                        d="M419,361.68a2.28,2.28,0,0,0,0-4.56h-7.77a2.28,2.28,0,0,0,0,4.56Z"
                        fill="#fff"
                    />
                </g>
                <g id="Group_10" data-name="Group 10" opacity="0.4">
                    <path
                        id="Path_62"
                        data-name="Path 62"
                        d="M419,410.88a2.28,2.28,0,1,0,0-4.56h-7.77a2.28,2.28,0,0,0,0,4.56Z"
                        fill="#fff"
                    />
                    <path
                        id="Path_63"
                        data-name="Path 63"
                        d="M419,410.07a2.28,2.28,0,1,0,0-4.55h-7.77a2.28,2.28,0,1,0,0,4.55Z"
                        fill="#fff"
                    />
                </g>
                <path
                    id="Path_64"
                    data-name="Path 64"
                    d="M336.53,327.21V436.7H493.72V327.21ZM488.41,431.4H341.84V384.76H488.41Zm0-51.95H341.84V332.81H488.41Z"
                    fill="#f3f4f5"
                />
                <path
                    id="Path_65"
                    data-name="Path 65"
                    d="M478.52,444.59H37.36l-2.3-7.89H480.82Z"
                    fill="#e3e2e2"
                />
                <ellipse
                    id="_Path_"
                    data-name="&lt;Path&gt;"
                    cx="249.44"
                    cy="20.46"
                    rx="249.44"
                    ry="20.46"
                    transform="translate(8.5 456.22)"
                    fill="#f3f4f5"
                />
                <path
                    id="Path_66"
                    data-name="Path 66"
                    d="M107.09,260.79H392.67a12.71,12.71,0,0,0,12.86-14.1L390.18,71.25a15.69,15.69,0,0,0-15.33-14.09H89.27A12.7,12.7,0,0,0,76.41,71.25L91.75,246.69a15.71,15.71,0,0,0,15.34,14.1Z"
                    fill="#1a2e35"
                />
                <path
                    id="Path_67"
                    data-name="Path 67"
                    d="M107.09,260.79H392.67a12.71,12.71,0,0,0,12.86-14.1L390.18,71.25a15.69,15.69,0,0,0-15.33-14.09H89.27A12.7,12.7,0,0,0,76.41,71.25L91.75,246.69a15.71,15.71,0,0,0,15.34,14.1Z"
                    fill="#fff"
                    opacity="0.7"
                />
                <path
                    id="Path_68"
                    data-name="Path 68"
                    d="M107.39,242.84H385.88L370.44,66.29H91.94Z"
                    fill="#717272"
                />
                <path
                    id="Path_69"
                    data-name="Path 69"
                    d="M111.22,256.65H396.81a12.71,12.71,0,0,0,12.86-14.09L394.32,67.12A15.69,15.69,0,0,0,379,53H93.41A12.71,12.71,0,0,0,80.54,67.1L95.89,242.56a15.7,15.7,0,0,0,15.33,14.09Z"
                    fill="#717272"
                />
                <path
                    id="Path_70"
                    data-name="Path 70"
                    d="M107.39,242.84H385.88L370.44,66.29H91.94Z"
                    fill="#fff"
                />
                <path
                    id="Path_71"
                    data-name="Path 71"
                    d="M107.39,243.11H385.88L370.44,66.57H91.94Z"
                    fill="#fff"
                    opacity="0.4"
                />
                <path
                    id="Path_72"
                    data-name="Path 72"
                    d="M371.83,82.47H93.34l-1.4-15.9h278.5Z"
                    fill="#007cff"
                />
                <path
                    id="Path_73"
                    data-name="Path 73"
                    d="M371.83,82.47H93.34l-1.4-15.9h278.5Z"
                    fill="#e3e2e2"
                />
                <path
                    id="Path_74"
                    data-name="Path 74"
                    d="M111.58,95.07l.11,1.19h2.08l.08.84h-2.09l.14,1.64h-1l-.39-4.5h3.4l.08.83Z"
                    fill="#717272"
                />
                <path
                    id="Path_75"
                    data-name="Path 75"
                    d="M114.32,94.24a.52.52,0,0,1,.58-.56.63.63,0,0,1,.67.54.53.53,0,0,1-.58.58.64.64,0,0,1-.67-.56Zm.21,1h1l.3,3.46h-1Z"
                    fill="#717272"
                />
                <path
                    id="Path_76"
                    data-name="Path 76"
                    d="M118.62,95.23l.08.92h-.23c-.55,0-.89.3-.84,1l.15,1.63h-1l-.3-3.46h1v.46a1.27,1.27,0,0,1,1.14-.55Z"
                    fill="#717272"
                />
                <path
                    id="Path_77"
                    data-name="Path 77"
                    d="M119.17,98.42l.27-.72a2.54,2.54,0,0,0,1.23.33c.47,0,.63-.12.61-.31,0-.57-2.09,0-2.21-1.37-.06-.66.5-1.12,1.51-1.12a3.09,3.09,0,0,1,1.36.3l-.28.71a2.1,2.1,0,0,0-1-.25c-.45,0-.63.14-.61.32,0,.59,2.09,0,2.21,1.38.06.64-.5,1.1-1.54,1.1A3.2,3.2,0,0,1,119.17,98.42Z"
                    fill="#717272"
                />
                <path
                    id="Path_78"
                    data-name="Path 78"
                    d="M125.17,98.57a1.35,1.35,0,0,1-.8.22,1.26,1.26,0,0,1-1.4-1.24l-.13-1.42h-.53l-.07-.77h.53l-.07-.85h1l.08.85h.86l.07.77h-.86l.12,1.41a.45.45,0,0,0,.47.45.62.62,0,0,0,.39-.12Z"
                    fill="#717272"
                />
                <path
                    id="Path_79"
                    data-name="Path 79"
                    d="M131.38,94.24l.39,4.5h-.86L128.43,96l.24,2.73h-1l-.4-4.5h.87L130.59,97l-.24-2.73Z"
                    fill="#717272"
                />
                <path
                    id="Path_80"
                    data-name="Path 80"
                    d="M135.66,96.77l.17,2h-.94v-.43a1,1,0,0,1-1,.48,1.25,1.25,0,0,1-1.39-1.05c0-.61.34-1,1.4-1h.8c0-.43-.32-.69-.86-.69a1.53,1.53,0,0,0-1,.33l-.42-.7a2.46,2.46,0,0,1,1.45-.42,1.59,1.59,0,0,1,1.79,1.48Zm-.93.88v-.36H134c-.47,0-.6.18-.58.41s.25.42.6.42a.68.68,0,0,0,.71-.47Z"
                    fill="#717272"
                />
                <path
                    id="Path_81"
                    data-name="Path 81"
                    d="M142.41,96.76l.18,2h-1l-.16-1.83a.707.707,0,0,0-.73-.81c-.41,0-.74.29-.68.9l.15,1.74h-1L139,96.91c0-.56-.31-.81-.72-.81s-.74.29-.68.9l.15,1.74h-1l-.3-3.46h1v.4a1.29,1.29,0,0,1,1.05-.45,1.41,1.41,0,0,1,1.19.56,1.43,1.43,0,0,1,1.2-.56,1.48,1.48,0,0,1,1.52,1.53Z"
                    fill="#717272"
                />
                <path
                    id="Path_82"
                    data-name="Path 82"
                    d="M146.77,97.29h-2.62a1,1,0,0,0,1,.7,1.06,1.06,0,0,0,.84-.34l.58.58a1.65,1.65,0,0,1-1.38.56A2,2,0,0,1,143.11,97a1.59,1.59,0,0,1,1.69-1.78,1.89,1.89,0,0,1,2,1.79,2.439,2.439,0,0,1-.03.28Zm-2.69-.58h1.71a.91.91,0,0,0-.91-.72.74.74,0,0,0-.8.72Z"
                    fill="#717272"
                />
                <path
                    id="Path_83"
                    data-name="Path 83"
                    d="M121.8,183.91l.08.84h-3.49l-.4-4.51h3.41l.07.84H119.1l.09,1h2.09l.07.81h-2.09l.09,1Z"
                    fill="#717272"
                />
                <path
                    id="Path_84"
                    data-name="Path 84"
                    d="M128.31,182.77l.18,2h-1l-.16-1.83a.716.716,0,0,0-.73-.82c-.41,0-.74.29-.68.91l.15,1.74h-1l-.16-1.83c0-.56-.31-.82-.72-.82s-.74.29-.69.91l.16,1.74h-1l-.3-3.46h1v.39a1.32,1.32,0,0,1,1.05-.45,1.42,1.42,0,0,1,1.19.57,1.44,1.44,0,0,1,1.2-.57,1.48,1.48,0,0,1,1.51,1.52Z"
                    fill="#717272"
                />
                <path
                    id="Path_85"
                    data-name="Path 85"
                    d="M132.27,182.77l.18,2h-.94v-.43a1.05,1.05,0,0,1-1,.48,1.24,1.24,0,0,1-1.38-1.05c-.06-.61.34-1,1.39-1h.8c0-.43-.31-.68-.85-.68a1.53,1.53,0,0,0-1,.32l-.43-.7a2.52,2.52,0,0,1,1.46-.42,1.58,1.58,0,0,1,1.77,1.48Zm-.92.88v-.35h-.69c-.46,0-.6.17-.58.4s.25.42.61.42a.67.67,0,0,0,.66-.47Z"
                    fill="#717272"
                />
                <path
                    id="Path_86"
                    data-name="Path 86"
                    d="M132.83,180.24a.53.53,0,0,1,.58-.56.63.63,0,0,1,.67.54.52.52,0,0,1-.57.58.64.64,0,0,1-.68-.56Zm.22,1h1l.3,3.46h-1Z"
                    fill="#717272"
                />
                <path
                    id="Path_87"
                    data-name="Path 87"
                    d="M134.87,180h1l.42,4.78h-1Z"
                    fill="#717272"
                />
                <path
                    id="Path_88"
                    data-name="Path 88"
                    d="M116,123.86h-2.09l-.32,1h-1.07l1.62-4.51h1l2.41,4.51h-1.1Zm-.4-.79-.86-1.72-.57,1.72Z"
                    fill="#717272"
                />
                <path
                    id="Path_89"
                    data-name="Path 89"
                    d="M121.08,120.05l.42,4.78h-1v-.4a1.22,1.22,0,0,1-1,.45,1.88,1.88,0,0,1-1.89-1.78,1.53,1.53,0,0,1,1.58-1.79,1.45,1.45,0,0,1,1.07.42l-.14-1.68Zm-.72,3a1,1,0,0,0-1-1,.8.8,0,0,0-.81,1,1,1,0,0,0,1,1,.79.79,0,0,0,.81-.95Z"
                    fill="#717272"
                />
                <path
                    id="Path_90"
                    data-name="Path 90"
                    d="M125.53,120.05l.42,4.78h-1v-.4a1.21,1.21,0,0,1-1,.45A1.88,1.88,0,0,1,122,123.1a1.53,1.53,0,0,1,1.58-1.79,1.44,1.44,0,0,1,1.07.42l-.14-1.68Zm-.72,3a1,1,0,0,0-1-1,.8.8,0,0,0-.81,1,1,1,0,0,0,1,1,.79.79,0,0,0,.81-.95Z"
                    fill="#717272"
                />
                <path
                    id="Path_91"
                    data-name="Path 91"
                    d="M128.71,121.31l.09.93h-.23c-.55,0-.89.3-.84,1l.15,1.64h-1l-.3-3.46h1v.45a1.28,1.28,0,0,1,1.13-.56Z"
                    fill="#717272"
                />
                <path
                    id="Path_92"
                    data-name="Path 92"
                    d="M132.83,123.38h-2.62a1,1,0,0,0,1.06.69,1.07,1.07,0,0,0,.84-.33l.58.58a1.66,1.66,0,0,1-1.38.56,2,2,0,0,1-2.13-1.78,1.59,1.59,0,0,1,1.69-1.79,1.91,1.91,0,0,1,2,1.8A2.434,2.434,0,0,1,132.83,123.38Zm-2.68-.59h1.7a.91.91,0,0,0-.91-.72.73.73,0,0,0-.79.72Z"
                    fill="#717272"
                />
                <path
                    id="Path_93"
                    data-name="Path 93"
                    d="M133.28,124.51l.27-.72a2.66,2.66,0,0,0,1.24.33c.46,0,.62-.12.61-.32-.05-.56-2.09,0-2.22-1.37-.05-.65.5-1.12,1.51-1.12a2.93,2.93,0,0,1,1.36.31l-.27.71a2.17,2.17,0,0,0-1-.26c-.45,0-.64.15-.62.33.05.59,2.09,0,2.21,1.38.06.64-.5,1.1-1.54,1.1A3.2,3.2,0,0,1,133.28,124.51Z"
                    fill="#717272"
                />
                <path
                    id="Path_94"
                    data-name="Path 94"
                    d="M136.7,124.51l.27-.72a2.65,2.65,0,0,0,1.23.33c.47,0,.63-.12.61-.32-.05-.56-2.09,0-2.21-1.37-.06-.65.49-1.12,1.5-1.12a2.9,2.9,0,0,1,1.36.31l-.27.71a2.14,2.14,0,0,0-1-.26c-.45,0-.63.15-.61.33.05.59,2.09,0,2.21,1.38.06.64-.5,1.1-1.54,1.1A3.17,3.17,0,0,1,136.7,124.51Z"
                    fill="#717272"
                />
                <path
                    id="Path_95"
                    data-name="Path 95"
                    d="M124.52,210.21c.09,1-.61,1.62-1.81,1.62h-.91l.11,1.25h-1l-.4-4.51h2a1.87,1.87,0,0,1,2.01,1.64Zm-1,0c0-.5-.39-.79-1-.79h-.85l.14,1.56h.85c.55.02.85-.29.81-.77Z"
                    fill="#717272"
                />
                <path
                    id="Path_96"
                    data-name="Path 96"
                    d="M128.3,211.1l.17,2h-.94v-.44a1,1,0,0,1-1,.49,1.25,1.25,0,0,1-1.39-1c0-.61.34-1,1.4-1h.8c0-.43-.32-.68-.86-.68a1.53,1.53,0,0,0-1,.32L125,210a2.5,2.5,0,0,1,1.46-.42,1.58,1.58,0,0,1,1.84,1.52Zm-.93.88v-.35h-.69c-.47,0-.6.17-.58.4s.25.42.6.42a.66.66,0,0,0,.67-.45Z"
                    fill="#717272"
                />
                <path
                    id="Path_97"
                    data-name="Path 97"
                    d="M129,212.75l.27-.72a2.55,2.55,0,0,0,1.24.34c.46,0,.63-.12.61-.32,0-.56-2.09,0-2.22-1.37,0-.65.5-1.12,1.51-1.12a2.93,2.93,0,0,1,1.36.31l-.27.71a2.17,2.17,0,0,0-1-.26c-.45,0-.63.14-.62.32,0,.6,2.1,0,2.21,1.39.06.64-.5,1.1-1.53,1.1a3.09,3.09,0,0,1-1.56-.38Z"
                    fill="#717272"
                />
                <path
                    id="Path_98"
                    data-name="Path 98"
                    d="M132.43,212.75l.27-.72a2.54,2.54,0,0,0,1.23.34c.47,0,.63-.12.61-.32,0-.56-2.09,0-2.21-1.37-.06-.65.49-1.12,1.51-1.12a3,3,0,0,1,1.36.31l-.28.71a2.14,2.14,0,0,0-1-.26c-.45,0-.63.14-.61.32.05.6,2.09,0,2.21,1.39.06.64-.5,1.1-1.54,1.1a3.08,3.08,0,0,1-1.55-.38Z"
                    fill="#717272"
                />
                <path
                    id="Path_99"
                    data-name="Path 99"
                    d="M141.4,209.61l-1,3.47h-1l-1-2.15-.62,2.15h-1l-1.55-3.47h1l1,2.35.65-2.35h.85l1,2.35.63-2.35Z"
                    fill="#717272"
                />
                <path
                    id="Path_100"
                    data-name="Path 100"
                    d="M141.66,211.34a1.61,1.61,0,0,1,1.75-1.78,2,2,0,0,1,2.06,1.78,1.61,1.61,0,0,1-1.74,1.79A2,2,0,0,1,141.66,211.34Zm2.79,0a1,1,0,0,0-1-1,.79.79,0,0,0-.81,1,1,1,0,0,0,1,1,.79.79,0,0,0,.81-1Z"
                    fill="#717272"
                />
                <path
                    id="Path_101"
                    data-name="Path 101"
                    d="M148.13,209.56l.08.93H148c-.55,0-.89.3-.83,1l.14,1.64h-1l-.31-3.47h1v.46a1.31,1.31,0,0,1,1.13-.56Z"
                    fill="#717272"
                />
                <path
                    id="Path_102"
                    data-name="Path 102"
                    d="M152.11,208.3l.42,4.78h-1v-.4a1.24,1.24,0,0,1-1,.45,1.88,1.88,0,0,1-1.89-1.79,1.53,1.53,0,0,1,1.58-1.78,1.44,1.44,0,0,1,1.07.42l-.14-1.68Zm-.72,3a1,1,0,0,0-1-1,.8.8,0,0,0-.81,1,1,1,0,0,0,1,1,.79.79,0,0,0,.81-.96Z"
                    fill="#717272"
                />
                <path
                    id="Path_103"
                    data-name="Path 103"
                    d="M348.92,230.68H323.53a9.69,9.69,0,0,1-9.46-8.71h0a7.85,7.85,0,0,1,7.94-8.7H347.4a9.69,9.69,0,0,1,9.47,8.7h0a7.86,7.86,0,0,1-7.95,8.71Z"
                    fill="#717272"
                />
                <path
                    id="Path_104"
                    data-name="Path 104"
                    d="M247.71,183.39c-.1-1.13.88-1.93,2.28-1.93a2.75,2.75,0,0,1,1.91.66l-.62.51a1.72,1.72,0,0,0-1.17-.44c-.85,0-1.4.49-1.34,1.2a1.59,1.59,0,0,0,2.64.75l.71.51a2.3,2.3,0,0,1-1.8.67,2.39,2.39,0,0,1-2.61-1.93Z"
                    fill="#717272"
                />
                <path
                    id="Path_105"
                    data-name="Path 105"
                    d="M252.3,183.82c-.08-.86.67-1.48,1.77-1.48a1.84,1.84,0,0,1,2,1.48c.08.87-.67,1.48-1.77,1.48a1.84,1.84,0,0,1-2-1.48Zm2.79,0a.89.89,0,0,0-1-.79c-.5,0-.86.3-.81.79a.88.88,0,0,0,1,.8.73.73,0,0,0,.81-.8Z"
                    fill="#717272"
                />
                <path
                    id="Path_106"
                    data-name="Path 106"
                    d="M260.29,183.61l.15,1.65h-1l-.13-1.52a.68.68,0,0,0-.76-.68c-.48,0-.81.25-.76.78l.12,1.42h-1l-.26-2.88h1v.34a1.61,1.61,0,0,1,1.12-.38,1.38,1.38,0,0,1,1.52,1.27Z"
                    fill="#717272"
                />
                <path
                    id="Path_107"
                    data-name="Path 107"
                    d="M263.56,185.12a1.58,1.58,0,0,1-.8.18c-.82,0-1.32-.35-1.38-1l-.11-1.18h-.53l-.06-.64h.54l-.06-.7h1l.06.7h.86l.06.64h-.86l.1,1.17a.41.41,0,0,0,.46.38.72.72,0,0,0,.4-.11Z"
                    fill="#717272"
                />
                <path
                    id="Path_108"
                    data-name="Path 108"
                    d="M267.06,183.62l.14,1.64h-.94v-.36a1.13,1.13,0,0,1-1,.4c-.81,0-1.33-.37-1.37-.87s.36-.86,1.41-.86h.8c0-.36-.31-.57-.85-.57a1.82,1.82,0,0,0-1,.27l-.41-.58a3,3,0,0,1,1.46-.35C266.34,182.34,267,182.75,267.06,183.62Zm-.94.73v-.29h-.69c-.47,0-.6.14-.58.33s.24.35.59.35a.69.69,0,0,0,.68-.39Z"
                    fill="#717272"
                />
                <path
                    id="Path_109"
                    data-name="Path 109"
                    d="M267.76,183.82c-.07-.86.68-1.48,1.8-1.48a1.83,1.83,0,0,1,1.62.73l-.75.35a1,1,0,0,0-.81-.39c-.51,0-.88.29-.84.79a.91.91,0,0,0,1,.8.8.8,0,0,0,.74-.4l.81.35a1.53,1.53,0,0,1-1.49.73,1.87,1.87,0,0,1-2.08-1.48Z"
                    fill="#717272"
                />
                <path
                    id="Path_110"
                    data-name="Path 110"
                    d="M274.22,185.12a1.58,1.58,0,0,1-.8.18c-.82,0-1.33-.35-1.38-1l-.11-1.18h-.53l-.06-.64h.54l-.07-.7h1l.06.7h.86l.06.64h-.86l.1,1.17a.41.41,0,0,0,.46.38.72.72,0,0,0,.4-.11Z"
                    fill="#717272"
                />
                <path
                    id="Path_111"
                    data-name="Path 111"
                    d="M280.49,181.52l.33,3.74H280L277.52,183l.2,2.27h-1l-.33-3.74h.86l2.44,2.27-.2-2.27Z"
                    fill="#717272"
                />
                <path
                    id="Path_112"
                    data-name="Path 112"
                    d="M285.05,182.38l.25,2.88h-.95v-.34a1.43,1.43,0,0,1-1,.38,1.4,1.4,0,0,1-1.59-1.29l-.15-1.63h1l.13,1.51a.68.68,0,0,0,.76.69.65.65,0,0,0,.73-.77l-.12-1.43Z"
                    fill="#717272"
                />
                <path
                    id="Path_113"
                    data-name="Path 113"
                    d="M291.94,183.61l.15,1.65h-1l-.13-1.52a.67.67,0,0,0-.72-.68.63.63,0,0,0-.7.76l.13,1.44h-1l-.14-1.52a.66.66,0,0,0-.71-.68.63.63,0,0,0-.7.76l.13,1.44h-1l-.25-2.88h1v.34a1.43,1.43,0,0,1,1.06-.38,1.54,1.54,0,0,1,1.18.47,1.57,1.57,0,0,1,1.21-.47,1.35,1.35,0,0,1,1.49,1.27Z"
                    fill="#717272"
                />
                <path
                    id="Path_114"
                    data-name="Path 114"
                    d="M296.63,183.82c.08.9-.62,1.48-1.6,1.48a1.63,1.63,0,0,1-1.12-.37v.33h-1l-.34-4h1l.12,1.39a1.37,1.37,0,0,1,1-.35,1.72,1.72,0,0,1,1.94,1.52Zm-1,0a.88.88,0,0,0-.95-.79c-.5,0-.86.3-.81.79a.88.88,0,0,0,.95.8.72.72,0,0,0,.79-.8Z"
                    fill="#717272"
                />
                <path
                    id="Path_115"
                    data-name="Path 115"
                    d="M300.69,184.06h-2.62a1,1,0,0,0,1,.57,1.23,1.23,0,0,0,.84-.27l.58.48a1.87,1.87,0,0,1-1.39.46c-1.19,0-2-.62-2.1-1.48s.66-1.48,1.72-1.48a1.77,1.77,0,0,1,1.93,1.49Zm-2.68-.49h1.71a.88.88,0,0,0-.9-.6c-.46.03-.77.23-.82.6Z"
                    fill="#717272"
                />
                <path
                    id="Path_116"
                    data-name="Path 116"
                    d="M303.37,182.34l.07.77h-.22c-.56,0-.9.25-.86.8l.12,1.36h-1l-.25-2.88h1v.38A1.44,1.44,0,0,1,303.37,182.34Z"
                    fill="#717272"
                />
                <path
                    id="Path_117"
                    data-name="Path 117"
                    d="M115.21,152.45l.29-.65a3.27,3.27,0,0,0,1.53.39c.61,0,.85-.17.83-.4-.06-.7-2.64-.22-2.76-1.61-.06-.63.51-1.16,1.8-1.16a3.709,3.709,0,0,1,1.59.33l-.26.66a3.13,3.13,0,0,0-1.28-.3c-.62,0-.84.19-.82.43.06.69,2.64.21,2.76,1.59.06.62-.52,1.15-1.81,1.15a3.89,3.89,0,0,1-1.87-.43Z"
                    fill="#717272"
                />
                <path
                    id="Path_118"
                    data-name="Path 118"
                    d="M121.85,152.68a1.61,1.61,0,0,1-.8.18c-.82,0-1.33-.34-1.39-1l-.1-1.18H119v-.64h.53l-.06-.7h1l.06.7h.86l.06.64h-.87l.11,1.17a.4.4,0,0,0,.46.38.75.75,0,0,0,.4-.11Z"
                    fill="#717272"
                />
                <path
                    id="Path_119"
                    data-name="Path 119"
                    d="M125.35,151.18l.14,1.64h-.94v-.36a1.14,1.14,0,0,1-1,.4c-.81,0-1.33-.37-1.37-.87s.35-.86,1.41-.86h.8c0-.35-.31-.56-.85-.56a1.79,1.79,0,0,0-1,.26l-.42-.58a3,3,0,0,1,1.47-.35C124.62,149.9,125.27,150.31,125.35,151.18Zm-.94.73v-.29h-.69c-.47,0-.6.14-.59.33s.24.35.6.35A.71.71,0,0,0,124.41,151.91Z"
                    fill="#717272"
                />
                <path
                    id="Path_120"
                    data-name="Path 120"
                    d="M128.62,152.68a1.65,1.65,0,0,1-.81.18c-.81,0-1.32-.34-1.38-1l-.1-1.18h-.54l-.05-.64h.53l-.06-.7h1l.06.7h.87l.05.64h-.86l.1,1.17a.41.41,0,0,0,.47.38.69.69,0,0,0,.39-.11Z"
                    fill="#717272"
                />
                <path
                    id="Path_121"
                    data-name="Path 121"
                    d="M132.35,151.62h-2.62a1,1,0,0,0,1,.57,1.26,1.26,0,0,0,.85-.27l.57.48a1.87,1.87,0,0,1-1.38.46,1.9,1.9,0,0,1-2.11-1.48c-.11-.86.66-1.48,1.72-1.48a1.63,1.63,0,0,1,1.93,1.72Zm-2.67-.49h1.7a.88.88,0,0,0-.9-.6c-.48,0-.77.23-.8.6Z"
                    fill="#717272"
                />
                <path
                    id="Path_122"
                    data-name="Path 122"
                    d="M240.2,94.24h1l.32,3.65h2.26l.07.85h-3.3Z"
                    fill="#717272"
                />
                <path
                    id="Path_123"
                    data-name="Path 123"
                    d="M247.34,96.77l.17,2h-.94v-.43a1,1,0,0,1-1,.48,1.25,1.25,0,0,1-1.39-1.05c0-.61.34-1,1.4-1h.8c0-.43-.32-.69-.86-.69a1.53,1.53,0,0,0-1,.33l-.42-.7a2.46,2.46,0,0,1,1.45-.42,1.59,1.59,0,0,1,1.79,1.48Zm-.93.88v-.36h-.69c-.47,0-.61.18-.58.41s.24.42.6.42a.68.68,0,0,0,.67-.47Z"
                    fill="#717272"
                />
                <path
                    id="Path_124"
                    data-name="Path 124"
                    d="M248.05,98.42l.27-.72a2.54,2.54,0,0,0,1.23.33c.47,0,.63-.12.61-.31,0-.57-2.09,0-2.21-1.37-.06-.66.5-1.12,1.51-1.12a3.09,3.09,0,0,1,1.36.3l-.28.71a2.1,2.1,0,0,0-1-.25c-.45,0-.63.14-.61.32.05.59,2.09,0,2.21,1.38.06.64-.5,1.1-1.54,1.1a3.2,3.2,0,0,1-1.55-.37Z"
                    fill="#717272"
                />
                <path
                    id="Path_125"
                    data-name="Path 125"
                    d="M254.05,98.57a1.35,1.35,0,0,1-.8.22,1.26,1.26,0,0,1-1.4-1.24l-.13-1.42h-.53l-.07-.77h.53l-.07-.85h1l.08.85h.86l.07.77h-.86l.12,1.41a.45.45,0,0,0,.47.45.62.62,0,0,0,.39-.12Z"
                    fill="#717272"
                />
                <path
                    id="Path_126"
                    data-name="Path 126"
                    d="M260.26,94.24l.39,4.5h-.86L257.31,96l.24,2.73h-1l-.4-4.5H257L259.47,97l-.24-2.73Z"
                    fill="#717272"
                />
                <path
                    id="Path_127"
                    data-name="Path 127"
                    d="M264.54,96.77l.17,2h-.94v-.43a1,1,0,0,1-1,.48,1.25,1.25,0,0,1-1.39-1.05c-.05-.61.34-1,1.4-1h.8c0-.43-.32-.69-.86-.69a1.53,1.53,0,0,0-1,.33l-.42-.7a2.46,2.46,0,0,1,1.45-.42,1.59,1.59,0,0,1,1.79,1.48Zm-.93.88v-.36h-.69c-.47,0-.6.18-.58.41s.24.42.6.42a.68.68,0,0,0,.67-.47Z"
                    fill="#717272"
                />
                <path
                    id="Path_128"
                    data-name="Path 128"
                    d="M271.29,96.76l.18,2h-1l-.16-1.83c-.05-.56-.32-.81-.73-.81s-.74.29-.68.9l.15,1.74h-1l-.16-1.83c0-.56-.31-.81-.72-.81s-.74.29-.69.9l.16,1.74h-1l-.3-3.46h1v.4a1.29,1.29,0,0,1,1.05-.45,1.41,1.41,0,0,1,1.19.56,1.43,1.43,0,0,1,1.2-.56,1.48,1.48,0,0,1,1.51,1.51Z"
                    fill="#717272"
                />
                <path
                    id="Path_129"
                    data-name="Path 129"
                    d="M275.65,97.29H273a1,1,0,0,0,1.05.7,1.06,1.06,0,0,0,.84-.34l.58.58a1.65,1.65,0,0,1-1.38.56A2,2,0,0,1,272,97a1.59,1.59,0,0,1,1.69-1.78,1.89,1.89,0,0,1,2,1.79A2.445,2.445,0,0,1,275.65,97.29ZM273,96.71h1.71a.91.91,0,0,0-.91-.72.74.74,0,0,0-.8.72Z"
                    fill="#717272"
                />
                <path
                    id="Path_130"
                    data-name="Path 130"
                    d="M232.43,113.04H111.53l-.91-10.39h120.9Z"
                    fill="#f3f4f5"
                />
                <path
                    id="Path_131"
                    data-name="Path 131"
                    d="M237.14,166.88H116.24l-.91-10.39h120.9Z"
                    fill="#f3f4f5"
                />
                <path
                    id="Path_132"
                    data-name="Path 132"
                    d="M239.89,198.35H118.99l-.91-10.39h120.9Z"
                    fill="#f3f4f5"
                />
                <path
                    id="Path_133"
                    data-name="Path 133"
                    d="M242.41,227.09H121.51l-.91-10.4H241.5Z"
                    fill="#f3f4f5"
                />
                <path
                    id="Path_134"
                    data-name="Path 134"
                    d="M369.9,198.35H249l-.91-10.39h120.9Z"
                    fill="#f3f4f5"
                />
                <path
                    id="Path_135"
                    data-name="Path 135"
                    d="M364.68,138.72H113.78l-.91-10.39h250.9Z"
                    fill="#f3f4f5"
                />
                <path
                    id="Path_136"
                    data-name="Path 136"
                    d="M362.44,113.04H241.54l-.91-10.39h120.9Z"
                    fill="#f3f4f5"
                />
                <path
                    id="Path_137"
                    data-name="Path 137"
                    d="M276.55,197.25h-9.32l-.75-8.57h9.32Z"
                    fill="#e3e2e2"
                />
                <path
                    id="Path_138"
                    data-name="Path 138"
                    d="M274.38,191.5l-2.65,3.31-3.24-3.31Z"
                    fill="#717272"
                />
                <path
                    id="Path_139"
                    data-name="Path 139"
                    d="M254.52,193.15H253.4l.09,1.1h-.83l-.09-1.1h-1.12l-.06-.78h1.11l-.1-1.09h.83l.1,1.09h1.12Z"
                    fill="#717272"
                />
                <path
                    id="Path_140"
                    data-name="Path 140"
                    d="M258.4,193.64c.07.76-.46,1.45-1.74,1.45a3.29,3.29,0,0,1-1.76-.48l.33-.8a2.38,2.38,0,0,0,1.33.41c.52,0,.82-.22.79-.58s-.3-.56-.89-.56H256l-.06-.68.84-1.05h-1.92l-.07-.84h3.15l.06.68-.92,1.15a1.43,1.43,0,0,1,1.32,1.3Z"
                    fill="#717272"
                />
                <path
                    id="Path_141"
                    data-name="Path 141"
                    d="M262.26,194.16l.08.85h-3.41l-.06-.67,1.6-1.64c.36-.38.41-.61.39-.83a.739.739,0,0,0-.77-.56,1,1,0,0,0-.89.45l-.8-.49a1.89,1.89,0,0,1,1.71-.84c1,0,1.72.52,1.79,1.33a1.65,1.65,0,0,1-.63,1.43l-.95,1Z"
                    fill="#717272"
                />
                <path
                    id="Path_142"
                    data-name="Path 142"
                    d="M343.55,195.06H282.1l-.31-3.56h61.44Z"
                    fill="#717272"
                />
                <path
                    id="Path_143"
                    data-name="Path 143"
                    d="M183.35,195.06H121.91l-.32-3.56h61.45Z"
                    fill="#717272"
                />
                <path
                    id="Path_144"
                    data-name="Path 144"
                    d="M147.27,163.46H119.14l-.31-3.56h28.13Z"
                    fill="#717272"
                />
                <path
                    id="Path_145"
                    data-name="Path 145"
                    d="M142.56,109.62H114.43l-.31-3.56h28.13Z"
                    fill="#717272"
                />
                <path
                    id="Path_146"
                    data-name="Path 146"
                    d="M273.12,109.62H244.99l-.31-3.56H272.8Z"
                    fill="#717272"
                />
                <path
                    id="Path_147"
                    data-name="Path 147"
                    d="M338.32,135.3H116.68l-.31-3.56H338.01Z"
                    fill="#717272"
                />
                <path
                    id="Path_148"
                    data-name="Path 148"
                    d="M128.05,222.13l1,.54-.38.74-1-.59.11,1.09h-.84l-.09-1.1-.94.6-.5-.74.94-.54-1-.54.37-.74,1,.6-.1-1.1h.84l.08,1.1.94-.6.51.74Z"
                    fill="#717272"
                />
                <path
                    id="Path_149"
                    data-name="Path 149"
                    d="M134.51,222.13l1,.54-.38.74-1-.59.11,1.09h-.85l-.08-1.1-.94.6-.5-.74.94-.54-1-.54.36-.74,1.05.6-.11-1.1h.85l.08,1.1.94-.6.5.74Z"
                    fill="#717272"
                />
                <path
                    id="Path_150"
                    data-name="Path 150"
                    d="M141,222.13l1,.54-.37.74-1.05-.59.12,1.09h-.85l-.09-1.1-.94.6-.49-.74.93-.54-1-.54.37-.74,1,.6-.1-1.1h.85l.07,1.1.94-.6.51.74Z"
                    fill="#717272"
                />
                <path
                    id="Path_151"
                    data-name="Path 151"
                    d="M147.42,222.13l1,.54-.38.74-1-.59.12,1.09h-.85l-.09-1.1-.94.6-.5-.74.94-.54-1-.54.37-.74,1,.6-.1-1.1h.84l.08,1.1.94-.6.51.74Z"
                    fill="#717272"
                />
                <path
                    id="Path_152"
                    data-name="Path 152"
                    d="M153.88,222.13l1,.54-.38.74-1-.59.11,1.09h-.84l-.09-1.1-.94.6-.5-.74.94-.54-1-.54.37-.74,1,.6-.11-1.1h.85l.08,1.1.94-.6.5.74Z"
                    fill="#717272"
                />
                <path
                    id="Path_153"
                    data-name="Path 153"
                    d="M160.34,222.13l1,.54-.38.74-1-.59.11,1.09h-.85l-.08-1.1-.94.6-.5-.74.93-.54-1-.54.37-.74,1,.6-.11-1.1h.85l.08,1.1.94-.6.5.74Z"
                    fill="#717272"
                />
                <path
                    id="Path_154"
                    data-name="Path 154"
                    d="M166.79,222.13l1,.54-.37.74-1-.59.12,1.09h-.85l-.09-1.1-.94.6-.49-.74.93-.54-1-.54.37-.74,1,.6-.1-1.1h.85l.07,1.1.94-.6.51.74Z"
                    fill="#717272"
                />
                <path
                    id="Path_155"
                    data-name="Path 155"
                    d="M152.04,91.71l54.39,203.45h-18.3L134.34,91.71Z"
                    fill="#fff"
                    opacity="0.1"
                />
                <path
                    id="Path_156"
                    data-name="Path 156"
                    d="M123.8,91.71l54.39,203.45H141.9L88.1,91.71Z"
                    fill="#fff"
                    opacity="0.1"
                />
                <rect
                    id="Rectangle_27"
                    data-name="Rectangle 27"
                    width="7.93"
                    height="1.64"
                    transform="translate(115.155 47.663) rotate(-0.48)"
                    fill="#717272"
                />
                <rect
                    id="Rectangle_28"
                    data-name="Rectangle 28"
                    width="23"
                    height="1.64"
                    transform="translate(130.102 47.538) rotate(-0.48)"
                    fill="#717272"
                />
                <path
                    id="Path_157"
                    data-name="Path 157"
                    d="M399.24,158.63a7.65,7.65,0,0,1-7.74,8.49,9.45,9.45,0,0,1-9.23-8.49,7.65,7.65,0,0,1,7.74-8.48,9.45,9.45,0,0,1,9.23,8.48Z"
                    fill="#f3f4f5"
                />
                <path
                    id="Path_158"
                    data-name="Path 158"
                    d="M97.31,188.68h0a1.82,1.82,0,0,1-1.78-1.63l-5.64-64.43A1.47,1.47,0,0,1,91.38,121h0a1.82,1.82,0,0,1,1.78,1.63l5.64,64.43a1.47,1.47,0,0,1-1.49,1.62Z"
                    opacity="0.5"
                />
                <path
                    id="Path_159"
                    data-name="Path 159"
                    d="M348.76,288.68H384c1.15,0,1.66-.93,1.15-2.08l-8.85-19.89a3.64,3.64,0,0,0-3-2.08H338.07c-1.15,0-1.67.93-1.16,2.08l8.85,19.89a3.65,3.65,0,0,0,3,2.08Z"
                    fill="#007cff"
                />
                <path
                    id="Path_160"
                    data-name="Path 160"
                    d="M348.76,288.68H384c1.15,0,1.66-.93,1.15-2.08l-8.85-19.89a3.64,3.64,0,0,0-3-2.08H338.07c-1.15,0-1.67.93-1.16,2.08l8.85,19.89a3.65,3.65,0,0,0,3,2.08Z"
                    fill="#717272"
                />
                <path
                    id="Path_161"
                    data-name="Path 161"
                    d="M336.54,264.63h1.53c-1.15,0-1.67.93-1.16,2.08l8.85,19.89a3.65,3.65,0,0,0,3,2.08h-1.52a3.64,3.64,0,0,1-3-2.08l-8.85-19.89C334.88,265.56,335.39,264.63,336.54,264.63Z"
                    fill="#007cff"
                />
                <path
                    id="Path_162"
                    data-name="Path 162"
                    d="M336.54,264.63h1.53c-1.15,0-1.67.93-1.16,2.08l8.85,19.89a3.65,3.65,0,0,0,3,2.08h-1.52a3.64,3.64,0,0,1-3-2.08l-8.85-19.89C334.88,265.56,335.39,264.63,336.54,264.63Z"
                    fill="#595959"
                />
                <path
                    id="Path_163"
                    data-name="Path 163"
                    d="M349.42,286.39h31.92l-8.66-19.47H340.75Z"
                    fill="#fafafa"
                />
                <path
                    id="Path_164"
                    data-name="Path 164"
                    d="M349.42,286.39h31.92l-8.66-19.47H340.75Z"
                    fill="#fafafa"
                    opacity="0.8"
                />
                <path
                    id="Path_165"
                    data-name="Path 165"
                    d="M334.94,452.99l-2.16,10.18-11.06-1.45,1.61-11.3Z"
                    fill="#e3e2e2"
                />
                <path
                    id="Path_166"
                    data-name="Path 166"
                    d="M316.1,461.72c-1.44-.15-2.9-.56-3.32-1.22a1,1,0,0,1,.14-1.2,1.39,1.39,0,0,1,.93-.55c2-.35,5.8,2.36,6,2.48a.28.28,0,0,1,.11.3.29.29,0,0,1-.25.2A20.378,20.378,0,0,1,316.1,461.72Zm-1.62-2.44a1.88,1.88,0,0,0-.53,0,.77.77,0,0,0-.58.34c-.24.32-.18.49-.11.59.46.81,3.39,1.11,5.57,1.06a12.57,12.57,0,0,0-4.35-1.99Z"
                    fill="#717272"
                />
                <path
                    id="Path_167"
                    data-name="Path 167"
                    d="M319.64,461.73a.14.14,0,0,1-.11,0c-1.33-.75-3.87-3.53-3.49-4.81.06-.32.33-.68,1.09-.68a2.08,2.08,0,0,1,1.49.62c1.35,1.36,1.36,4.52,1.36,4.67a.25.25,0,0,1-.15.23.31.31,0,0,1-.19-.03Zm-2.37-5h-.16c-.5,0-.55.19-.58.26-.15.78,1.49,2.93,2.84,3.92a6.47,6.47,0,0,0-1.19-3.71,1.45,1.45,0,0,0-.91-.44Z"
                    fill="#717272"
                />
                <path
                    id="Path_168"
                    data-name="Path 168"
                    d="M321,460.23l12.66,1.24a1,1,0,0,1,.91.89l1.28,10.53a1.7,1.7,0,0,1-1.47,1.9,1.453,1.453,0,0,1-.36,0c-4.56-.53-8.59-1.17-14.27-1.72-6.71-.66-5.08.31-13-.35-4.75-.41-5.09-5.89-3.05-6,9.33-.25,10.56-2.94,14.44-5.89A4.06,4.06,0,0,1,321,460.23Z"
                    fill="#595959"
                />
                <path
                    id="Path_169"
                    data-name="Path 169"
                    d="M322.48,456.36l11.55.91.91-4.28-11.61-2.57Z"
                    opacity="0.2"
                />
                <path
                    id="Path_170"
                    data-name="Path 170"
                    d="M331.86,368.76l-10.91,80.16,16.95,1.33,19.73-79.47Z"
                    fill="#1a2e35"
                />
                <path
                    id="Path_171"
                    data-name="Path 171"
                    d="M331.86,368.76l-10.91,80.16,16.95,1.33,19.73-79.47Z"
                    fill="#595959"
                />
                <path
                    id="Path_172"
                    data-name="Path 172"
                    d="M339.45,449.07,319,447.45a4.43,4.43,0,0,0,.16,5.05l19.73,1.78S340.86,453.15,339.45,449.07Z"
                    fill="#717272"
                />
                <path
                    id="Path_173"
                    data-name="Path 173"
                    d="M351.35,289.54l-4.15-10a3,3,0,0,0-1.5-1.19l-2.08-.76a3,3,0,0,1-1.17-.79l-1-1a3,3,0,0,0-3.42-.72l-.9.4a3,3,0,0,0-1.75,2.18c-.55,3-1,9.43,4.38,13.78l6,2.46Z"
                    fill="#e3e2e2"
                />
                <path
                    id="Path_174"
                    data-name="Path 174"
                    d="M345.78,293.84l7.95,11.96,5.58-5.89-7.96-10.37Z"
                    fill="#e3e2e2"
                />
                <path
                    id="Path_175"
                    data-name="Path 175"
                    d="M393.9,265.39c-.41-.82-6.8,1.57-14.27,5.32s-13.2,7.46-12.79,8.28,6.8-1.57,14.27-5.32S394.31,266.21,393.9,265.39Z"
                    fill="#595959"
                />
                <path
                    id="Path_176"
                    data-name="Path 176"
                    d="M384.35,268.45c.7-.31,1.37-.61,2-.88l1.29,2.57c-.61.35-1.25.72-1.92,1.09Z"
                    fill="#e3e2e2"
                />
                <path
                    id="Path_177"
                    data-name="Path 177"
                    d="M384.35,268.45c.7-.31,1.37-.61,2-.88l1.29,2.57c-.61.35-1.25.72-1.92,1.09Z"
                    fill="#e3e2e2"
                    opacity="0.5"
                />
                <path
                    id="Path_178"
                    data-name="Path 178"
                    d="M391.62,265.14c.35-1.69-6.39,1.31-6.78,1.5s-.79-.52-.32-.75,8.63-4.14,7.9-.64C392.31,265.75,391.51,265.66,391.62,265.14Z"
                    fill="#007cff"
                />
                <g id="Group_11" data-name="Group 11" opacity="0.2">
                    <path
                        id="Path_179"
                        data-name="Path 179"
                        d="M391.62,265.14c.35-1.69-6.39,1.31-6.78,1.5s-.79-.52-.32-.75,8.63-4.14,7.9-.64C392.31,265.75,391.51,265.66,391.62,265.14Z"
                    />
                </g>
                <path
                    id="Path_180"
                    data-name="Path 180"
                    d="M392,292.35l-6.59-11.2a21.089,21.089,0,0,0-3.14-10.85,24.928,24.928,0,0,0-2.47-2.21,32.491,32.491,0,0,0-5.14,3.21c-2.39,1.83-3,3.16-.1,7.62a23.71,23.71,0,0,0,5.31,5.78l6.23,12.69Z"
                    fill="#e3e2e2"
                />
                <path
                    id="Path_181"
                    data-name="Path 181"
                    d="M393.45,291l-2.86-4.53s-5.25.76-8.72,6.75l2.42,5.22Z"
                    fill="#007cff"
                />
                <path
                    id="Path_182"
                    data-name="Path 182"
                    d="M426.51,266c-5,.34-9.32-.53-17.83,26.28-1.58,5-5.65,10-5.65,10l-9.37-12.79s-6.14.36-10.39,7.86c0,0,7.23,24.22,20.79,22.47s21.13-17.32,24.12-26.61S437.55,265.24,426.51,266Z"
                    fill="#1a2e35"
                />
                <path
                    id="Path_183"
                    data-name="Path 183"
                    d="M402.34,253.76l-.93,8.29,13.94.07.3-10.49Z"
                    fill="#e3e2e2"
                />
                <path
                    id="Path_184"
                    data-name="Path 184"
                    d="M402,257.77c3.51-.93,5.86-5.08,5.86-5.08l-3,.58-2.56.5Z"
                    opacity="0.2"
                />
                <path
                    id="Path_185"
                    data-name="Path 185"
                    d="M398.53,230.46c-.26.66-.13,1.33.31,1.5s1.06-.24,1.3-.89.13-1.32-.32-1.49S398.8,229.81,398.53,230.46Z"
                    fill="#595959"
                />
                <path
                    id="Path_186"
                    data-name="Path 186"
                    d="M400.25,232.22l-5.33,3.88a5,5,0,0,0,4,2Z"
                    fill="#595959"
                />
                <path
                    id="Path_187"
                    data-name="Path 187"
                    d="M401.09,226.48a.39.39,0,0,1,.3.2,2.88,2.88,0,0,0,2.15,1.57.37.37,0,0,1,.33.4.38.38,0,0,1-.41.34,3.6,3.6,0,0,1-2.75-2,.39.39,0,0,1,.16-.51h0A.54.54,0,0,1,401.09,226.48Z"
                    fill="#595959"
                />
                <path
                    id="Path_188"
                    data-name="Path 188"
                    d="M425.18,238.09c-3.13,8.89-4.26,12.73-10.25,16.11-8.5,2.8-19.55-.6-19.21-10.23.31-8.66,5.36-21.71,15.47-22.92a13.08,13.08,0,0,1,14.65,11.19,12.53,12.53,0,0,1-.66,5.85Z"
                    fill="#e3e2e2"
                />
                <path
                    id="Path_189"
                    data-name="Path 189"
                    d="M429.59,245.37a69.7,69.7,0,0,0-6.47,6.16,14.549,14.549,0,0,1-4.38,3.2,14.73,14.73,0,0,1-10.64.43h0s-9.27-8.16-3.1-23.41a8.89,8.89,0,0,1,.39-14.76c2.93-1.85,7.45-.68,10.15,1.49a11.38,11.38,0,0,1,10.23,3,23.34,23.34,0,0,1,5.69,9.53c1.07,3,1.84,6.27,1.19,9.39a8.94,8.94,0,0,1-3.06,4.97Z"
                    fill="#595959"
                />
                <path
                    id="Path_190"
                    data-name="Path 190"
                    d="M405.9,235a7.32,7.32,0,0,0-3.76,4.16c-.83,2.41,1.36,3.77,3.76,3.22,2.18-.49,5.21-2.35,5-4.84S408.08,234,405.9,235Z"
                    fill="#e3e2e2"
                />
                <path
                    id="Path_191"
                    data-name="Path 191"
                    d="M418.41,256.62c-3.09-1.34-8.66-1.47-16.72-.22a2.74,2.74,0,0,0-1.9,1.85,1.57,1.57,0,0,0,1,2.13c-.07,1.18-.36,2.47-.43,3.65,2.27-.24,4.77.27,7.05.38a88.008,88.008,0,0,1,10.83,1.45c-.22-1.71-.75-3.52-.35-5.19a1.88,1.88,0,0,0,1.88-1.46A2.81,2.81,0,0,0,418.41,256.62Z"
                    fill="#717272"
                />
                <path
                    id="Path_192"
                    data-name="Path 192"
                    d="M418.41,256.62c-3.09-1.34-8.66-1.47-16.72-.22a2.74,2.74,0,0,0-1.9,1.85,1.57,1.57,0,0,0,1,2.13c-.07,1.18-.36,2.47-.43,3.65,2.27-.24,4.77.27,7.05.38a88.008,88.008,0,0,1,10.83,1.45c-.22-1.71-.75-3.52-.35-5.19a1.88,1.88,0,0,0,1.88-1.46A2.81,2.81,0,0,0,418.41,256.62Z"
                    fill="#717272"
                />
                <path
                    id="Path_193"
                    data-name="Path 193"
                    d="M380.37,344.87s-2.86-38.21-2.69-65.78c.14-20.34,14.73-16.68,33.51-16.59,14.9.08,20,1.35,22,9.48s-8,73.12-8,73.12Z"
                    fill="#717272"
                />
                <path
                    id="Path_194"
                    data-name="Path 194"
                    d="M380.37,344.87s-2.86-38.21-2.69-65.78c.14-20.34,14.73-16.68,33.51-16.59,14.9.08,20,1.35,22,9.48s-8,73.12-8,73.12Z"
                    opacity="0.1"
                />
                <path
                    id="Path_195"
                    data-name="Path 195"
                    d="M352.83,290.92a5.73,5.73,0,0,0-3.44,1.33,11.12,11.12,0,0,0-3.54,4.75h0a15.71,15.71,0,0,0-5-5.15l-.12-.07c.66-9,8.07-8.35,8.07-8.35l.18.46a39.526,39.526,0,0,0,3.85,7.03Z"
                    fill="#007cff"
                />
                <path
                    id="Path_196"
                    data-name="Path 196"
                    d="M352.83,290.92a5.73,5.73,0,0,0-3.44,1.33,11.12,11.12,0,0,0-3.54,4.75h0a15.71,15.71,0,0,0-5-5.15l-.12-.07c.66-9,8.07-8.35,8.07-8.35l.18.46a39.526,39.526,0,0,0,3.85,7.03Z"
                    fill="#595959"
                />
                <path
                    id="Path_197"
                    data-name="Path 197"
                    d="M372.57,323c-13.67-.3-26.78-22.91-26.78-22.91a5.2,5.2,0,0,1,.06-3.13,11.12,11.12,0,0,1,3.54-4.75,5.73,5.73,0,0,1,3.44-1.33,6.62,6.62,0,0,1,2.5.35s10.08,12.44,12.54,11.32,3.41-5.24,4.3-10.4c4.79-27.56,10.06-28.39,14.68-28.51C396.45,263.43,384.68,323.3,372.57,323Z"
                    fill="#007cff"
                />
                <path
                    id="Path_198"
                    data-name="Path 198"
                    d="M372.57,323c-13.67-.3-26.78-22.91-26.78-22.91a5.2,5.2,0,0,1,.06-3.13,11.12,11.12,0,0,1,3.54-4.75,5.73,5.73,0,0,1,3.44-1.33,6.62,6.62,0,0,1,2.5.35s10.08,12.44,12.54,11.32,3.41-5.24,4.3-10.4c4.79-27.56,10.06-28.39,14.68-28.51C396.45,263.43,384.68,323.3,372.57,323Z"
                    fill="#717272"
                />
                <path
                    id="Path_199"
                    data-name="Path 199"
                    d="M425.19,345.1s1.17,11.63,1,15.72c-.88,21.71-21.43,29.37-37.82,26.36l-58.23-6.31c-2.36,7.89-29.79,51.32-29.79,51.32l-14.43-8.28s15-35.86,24.61-60.81c5.51-14.24,49.26-14.67,65.78-11.74,5,1.36,4.05-6.49,4.05-6.49Z"
                    fill="#595959"
                />
                <path
                    id="Path_200"
                    data-name="Path 200"
                    d="M296.67,432.99l-5.5,9.18-10.28-5.11,5.35-10.45Z"
                    fill="#e3e2e2"
                />
                <path
                    id="Path_201"
                    data-name="Path 201"
                    d="M275.43,435.18c-1.36-.62-2.65-1.51-2.83-2.3a1.06,1.06,0,0,1,.53-1.11,1.5,1.5,0,0,1,1.09-.24c2,.34,4.86,4.25,5,4.42a.29.29,0,0,1,0,.33.3.3,0,0,1-.31.11,21.279,21.279,0,0,1-3.48-1.21Zm-.77-2.92a1.851,1.851,0,0,0-.52-.16.8.8,0,0,0-.67.13c-.34.23-.35.42-.31.54.18.94,2.92,2.22,5.06,2.89a13.25,13.25,0,0,0-3.56-3.4Z"
                    fill="#717272"
                />
                <path
                    id="Path_202"
                    data-name="Path 202"
                    d="M278.86,436.37a.21.21,0,0,1-.1-.06c-1-1.17-2.58-4.74-1.78-5.86.16-.28.54-.54,1.29-.28a2.09,2.09,0,0,1,1.23,1.1c.87,1.77-.18,4.85-.23,5a.27.27,0,0,1-.22.18.26.26,0,0,1-.19-.08Zm-.64-5.63-.14-.09c-.49-.17-.6,0-.65.06-.41.71.46,3.35,1.45,4.76a6.66,6.66,0,0,0,.08-4,1.43,1.43,0,0,0-.74-.73Z"
                    fill="#717272"
                />
                <path
                    id="Path_203"
                    data-name="Path 203"
                    d="M280.71,435.38l11.91,5.44a1.06,1.06,0,0,1,.59,1.18l-2.28,10.66a1.76,1.76,0,0,1-2.07,1.37,2.06,2.06,0,0,1-.35-.12c-4.26-2-8-4-13.31-6.45-6.31-2.88-5.05-1.4-12.51-4.68-4.49-2-3-7.43-1-6.82,9.16,2.88,11.26.67,16-.9a4.16,4.16,0,0,1,3.02.32Z"
                    fill="#595959"
                />
                <path
                    id="Path_204"
                    data-name="Path 204"
                    d="M293.88,437.66l2.79-4.67-10.43-6.38-2.59,5.05Z"
                    opacity="0.2"
                />
                <path
                    id="Path_205"
                    data-name="Path 205"
                    d="M302.64,432.94l-17.88-11.16a4.56,4.56,0,0,0-2.25,4.69l17.18,11S302,437.34,302.64,432.94Z"
                    fill="#717272"
                />
                <path
                    id="Path_206"
                    data-name="Path 206"
                    d="M403.77,390.54h29.65a21.9,21.9,0,0,0,21.84-20.18l4.22-53.27a14.84,14.84,0,0,0-14.8-16H428.3A43.24,43.24,0,0,0,386.87,332L375.5,370a15,15,0,0,1-14.42,10.74H340.36a9.82,9.82,0,0,0-9.82,9.82h73.23Z"
                    fill="#007cff"
                />
                <path
                    id="Path_207"
                    data-name="Path 207"
                    d="M390.43,390.54c.81,12.68,3,58.84-.27,69-2.87,8.95-16,11.08-23.78,15.82-1.3.79-.85,3.32.59,3.32h60.48c1.44,0,1.89-2.53.58-3.32-7.76-4.74-20.9-6.87-23.77-15.82-3.27-10.16-1.08-56.32-.27-69Z"
                    fill="#595959"
                />
                <path
                    id="Path_208"
                    data-name="Path 208"
                    d="M391,400.12h12.44c.2-3.88.41-7.2.56-9.58H390.43C390.58,392.92,390.78,396.24,391,400.12Z"
                    opacity="0.2"
                />
                <path
                    id="Path_209"
                    data-name="Path 209"
                    d="M403.77,390.54h29.65a21.9,21.9,0,0,0,21.84-20.18l4.22-53.27a14.84,14.84,0,0,0-14.8-16H428.3A43.24,43.24,0,0,0,386.87,332L375.5,370a15,15,0,0,1-14.42,10.74H340.36a9.82,9.82,0,0,0-9.82,9.82h73.23Z"
                    fill="#717272"
                />
                <path
                    id="Path_210"
                    data-name="Path 210"
                    d="M327.07,224.43v-5.75h1.54c.72,1.29,2,3.62,2.31,4.25h0c-.06-.6-.06-1.6-.06-2.54v-1.71H332v5.75h-1.46c-.61-1.12-2.08-3.8-2.37-4.46h0c0,.5.05,1.68.05,2.71v1.75Z"
                    fill="#fff"
                />
                <path
                    id="Path_211"
                    data-name="Path 211"
                    d="M334.07,222.53c0,.54.27,1.06.87,1.06a.67.67,0,0,0,.75-.46h1.2a1.9,1.9,0,0,1-2,1.4A2.266,2.266,0,0,1,335,220a1.9,1.9,0,0,1,2,2.14v.37Zm1.67-.74c0-.5-.21-.92-.79-.92s-.8.39-.85.92Z"
                    fill="#fff"
                />
                <path
                    id="Path_212"
                    data-name="Path 212"
                    d="M338.52,220.12c.45.81.63,1.13.76,1.35h0c.15-.26.41-.74.76-1.35h1.33l-1.4,2,1.39,2.26H340c-.39-.73-.63-1.2-.75-1.45h0l-.76,1.45h-1.31l1.34-2.1-1.33-2.21Z"
                    fill="#fff"
                />
                <path
                    id="Path_213"
                    data-name="Path 213"
                    d="M341.64,220.12h.67V219h1.19v1.17h.84V221h-.84v2.13c0,.33.1.46.46.46a1.369,1.369,0,0,0,.27,0v.82a2.76,2.76,0,0,1-.81.09c-.84,0-1.11-.45-1.11-1.21V221h-.67Z"
                    fill="#fff"
                />
                <path
                    id="Path_214"
                    data-name="Path 214"
                    d="M108.06,76c.12.53.5.77,1.12.77s.87-.24.87-.63-.25-.62-1.16-.84c-1.45-.34-1.81-.86-1.81-1.58,0-.93.69-1.56,2-1.56,1.42,0,2,.76,2.06,1.54h-1.16c-.06-.33-.24-.69-.93-.69-.46,0-.75.2-.75.58s.23.52,1.09.72c1.55.36,1.89.94,1.89,1.69,0,1-.74,1.64-2.14,1.64S107,76.92,106.9,76Z"
                    fill="#717272"
                />
                <path
                    id="Path_215"
                    data-name="Path 215"
                    d="M111.56,73.51h.62V72.43h1.1v1.08h.78v.84h-.78v2c0,.3.09.42.42.42h.3v.76a2.689,2.689,0,0,1-.75.07c-.78,0-1-.41-1-1.11v-2.1h-.62Z"
                    fill="#717272"
                />
                <path
                    id="Path_216"
                    data-name="Path 216"
                    d="M115.55,75.75c0,.5.25,1,.8,1a.63.63,0,0,0,.7-.43h1.11a1.75,1.75,0,0,1-1.83,1.29,1.84,1.84,0,0,1-1.89-2,1.89,1.89,0,0,1,1.93-2.15,1.76,1.76,0,0,1,1.83,2v.34Zm1.55-.68c0-.47-.2-.86-.74-.86s-.74.37-.78.86Z"
                    fill="#717272"
                />
                <path
                    id="Path_217"
                    data-name="Path 217"
                    d="M120.08,77.16V79H119V73.48h1a3.622,3.622,0,0,1,0,.5,1.27,1.27,0,0,1,1.16-.59c.9,0,1.59.75,1.59,2,0,1.41-.77,2.19-1.74,2.19a1.11,1.11,0,0,1-.93-.42Zm1.63-1.71c0-.73-.3-1.14-.82-1.14s-.85.4-.85,1.21.2,1.2.8,1.2.87-.45.87-1.27Z"
                    fill="#717272"
                />
                <path
                    id="Path_218"
                    data-name="Path 218"
                    d="M126.22,77.51v-3.8a3.34,3.34,0,0,1-.92.22v-.82a2.46,2.46,0,0,0,1.26-.68h.78v5.08Z"
                    fill="#717272"
                />
                <g id="Group_12" data-name="Group 12" opacity="0.7">
                    <path
                        id="Path_219"
                        data-name="Path 219"
                        d="M139.1,76c.12.53.5.77,1.12.77s.88-.24.88-.63-.26-.62-1.17-.84c-1.45-.34-1.81-.86-1.81-1.58,0-.93.7-1.56,2-1.56s2,.76,2,1.54H141c-.06-.33-.24-.69-.92-.69-.47,0-.75.2-.75.58s.22.52,1.08.72c1.55.36,1.89.94,1.89,1.69,0,1-.74,1.64-2.14,1.64s-2.07-.67-2.19-1.64Z"
                        fill="#717272"
                    />
                    <path
                        id="Path_220"
                        data-name="Path 220"
                        d="M142.6,73.51h.62V72.43h1.1v1.08h.79v.84h-.79v2c0,.3.1.42.43.42H145v.76a2.69,2.69,0,0,1-.75.07c-.78,0-1-.41-1-1.11v-2.1h-.62Z"
                        fill="#717272"
                    />
                    <path
                        id="Path_221"
                        data-name="Path 221"
                        d="M146.59,75.75c0,.5.26,1,.81,1a.62.62,0,0,0,.69-.43h1.11a1.75,1.75,0,0,1-1.83,1.29,1.84,1.84,0,0,1-1.89-2,1.89,1.89,0,0,1,1.93-2.15,1.77,1.77,0,0,1,1.84,2,1.829,1.829,0,0,1,0,.34Zm1.55-.68c0-.47-.2-.86-.74-.86s-.74.37-.78.86Z"
                        fill="#717272"
                    />
                    <path
                        id="Path_222"
                        data-name="Path 222"
                        d="M151.12,77.16V79H150V73.48h1.07a3.623,3.623,0,0,1,0,.5,1.27,1.27,0,0,1,1.16-.59c.9,0,1.59.75,1.59,2,0,1.41-.77,2.19-1.74,2.19a1.11,1.11,0,0,1-.96-.42Zm1.63-1.71c0-.73-.29-1.14-.82-1.14s-.85.4-.85,1.21.2,1.2.8,1.2.87-.45.87-1.27Z"
                        fill="#717272"
                    />
                    <path
                        id="Path_223"
                        data-name="Path 223"
                        d="M156.33,77.51v-.07a2.89,2.89,0,0,1,1.5-2.41c.71-.52.93-.7.93-1.14s-.21-.69-.64-.69-.68.3-.75.74h-1.06a1.66,1.66,0,0,1,1.81-1.6c1.31,0,1.75.77,1.75,1.54s-.32,1.09-1.25,1.75a2.644,2.644,0,0,0-1,1h2.4l-.14.92Z"
                        fill="#717272"
                    />
                </g>
                <g id="Group_13" data-name="Group 13" opacity="0.7">
                    <path
                        id="Path_224"
                        data-name="Path 224"
                        d="M169.64,76a1,1,0,0,0,1.13.77c.61,0,.87-.24.87-.63s-.25-.62-1.16-.84c-1.45-.34-1.81-.86-1.81-1.58,0-.93.69-1.56,2-1.56,1.42,0,2,.76,2.06,1.54h-1.16c-.06-.33-.24-.69-.93-.69-.46,0-.75.2-.75.58s.23.52,1.09.72c1.55.36,1.89.94,1.89,1.69,0,1-.74,1.64-2.14,1.64s-2.07-.67-2.2-1.64Z"
                        fill="#717272"
                    />
                    <path
                        id="Path_225"
                        data-name="Path 225"
                        d="M173.15,73.51h.61V72.43h1.11v1.08h.78v.84h-.78v2c0,.3.09.42.42.42h.26v.76a2.73,2.73,0,0,1-.75.07c-.78,0-1-.41-1-1.11v-2.1h-.61Z"
                        fill="#717272"
                    />
                    <path
                        id="Path_226"
                        data-name="Path 226"
                        d="M177.14,75.75c0,.5.25,1,.8,1a.63.63,0,0,0,.7-.43h1.11a1.75,1.75,0,0,1-1.83,1.29,1.84,1.84,0,0,1-1.89-2c0-1.25.64-2.15,1.92-2.15a1.76,1.76,0,0,1,1.84,2v.34Zm1.55-.68c0-.47-.2-.86-.74-.86s-.74.37-.78.86Z"
                        fill="#717272"
                    />
                    <path
                        id="Path_227"
                        data-name="Path 227"
                        d="M181.67,77.16V79h-1.1V73.48h1.07a3.621,3.621,0,0,1,0,.5,1.27,1.27,0,0,1,1.16-.59c.9,0,1.59.75,1.59,2,0,1.41-.77,2.19-1.74,2.19A1.1,1.1,0,0,1,181.67,77.16Zm1.63-1.71c0-.73-.3-1.14-.82-1.14s-.86.4-.86,1.21.21,1.2.81,1.2.87-.45.87-1.27Z"
                        fill="#717272"
                    />
                    <path
                        id="Path_228"
                        data-name="Path 228"
                        d="M186.88,73.74a1.58,1.58,0,0,1,1.78-1.4c1.18,0,1.7.66,1.7,1.38a1.06,1.06,0,0,1-.63,1h0a1.23,1.23,0,0,1,.83,1.23c0,.73-.45,1.6-1.91,1.6a1.64,1.64,0,0,1-1.87-1.49h1.07a.77.77,0,0,0,.83.65.71.71,0,0,0,.77-.78c0-.47-.24-.75-.84-.75h-.45v-.86h.4c.51,0,.71-.16.71-.59s-.21-.58-.64-.58a.62.62,0,0,0-.7.54Z"
                        fill="#717272"
                    />
                </g>
            </g>
        </SVGIcon>
    );
}
