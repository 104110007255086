import React from 'react';
import DCPencil from 'Assets/icons/DCPencil';
import DCCrossClose from '../../Assets/icons/DCCrossClose';
import { Div } from '../index';
import PropTypes from 'prop-types';
import './style.scss';

const SingleTextChip = ({ icon, className, title, onDelete, onEdit }) => {
    const handleOuterClick = event => {
        event.stopPropagation();
    };
    return (
        <Div
            className={'SingleTextChip ' + (className ? className : '')}
            onClick={handleOuterClick}
        >
            <Div className={'STCInner'}>
                {icon && <Div className={'STCIIcon'}>{icon}</Div>}
                <Div className={'STCITitle'}>{title}</Div>
                {onEdit && (
                    <Div className={'STCITEdit'} onClick={onEdit}>
                        <DCPencil />
                    </Div>
                )}

                {onDelete && (
                    <Div className={'STCITRemove'} onClick={onDelete}>
                        <DCCrossClose />
                    </Div>
                )}
            </Div>
        </Div>
    );
};

SingleTextChip.propTypes = {
    icon: PropTypes.element,
    className: PropTypes.string,
    title: PropTypes.string.isRequired,
    onDelete: PropTypes.func,
    onEdit: PropTypes.func,
};

export default SingleTextChip;
