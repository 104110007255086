import React from 'react';

import { SVGIcon } from '../../UIKit/index';

export default function DCPencil(props) {
    return (
        <SVGIcon {...props} viewBox="0 0 512 512">
            <path d="M383,72.8L105,350l-28.1,84.6l0.8,0.6l83.7-27.3l222.2-222l52.2-52.7c0,0,10.7-8.8,6.5-18.7l-1.2-2l-3.9-4.5  l-24.5-25.4l-13.4-11.8c0,0-7.9-6.2-16.7,2.4l-18.4-18.4c0,0,20.2-24.9,49.9-6.1c15.1,9.6,48.6,47.5,48.6,47.5s17.1,22.8,0.8,46.1  L176.4,430.6L62,468.6l-6,1.8c0,0-13.5,0.9-14.2-12.7l1.5-6.7l36.3-109.5l1.4-5.2l145.2-145.3L339.4,78.1l25-23.7L383,72.8z" />
            <polygon points="344.5,108.1 403,166.4 396.2,181.5 383.6,185.9 324.7,127.7 333,113.8 " />
        </SVGIcon>
    );
}
