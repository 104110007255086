import './style.scss';

import React, {
    forwardRef,
    useCallback,
    useEffect,
    useLayoutEffect,
    useRef,
    useState,
} from 'react';

import { createPortal } from 'react-dom';
import PropTypes from 'prop-types';
import { Div } from '../index';

const PopoverWrapper = forwardRef((props, ref) => {
    return createPortal(
        <div
            ref={ref}
            style={props.style}
            className={props.className}
            data-popover-id={props.popoverId}
        >
            {props.children}
        </div>,
        document.body,
    );
});

const Popover = ({
    customClass,
    anchorEl,
    placement,
    onClose,
    open,
    children,
    width,
    minWidth,
    maxHeight,
    height,
    withOutPadding,
    animationPlacement,
    style,
    parentRef,
}) => {
    const popoverRef = useRef();
    const [defaultPlacement, setDefaultPlacement] = useState(placement || 'top-center');
    const [animationName, setAnimationName] = useState('');
    const firstRun = useRef(true);

    const [defaultWidth, setDefaultWidth] = useState(width || 300);
    const [topPosition, setTopPosition] = useState(0);
    const [leftPosition, setLeftPosition] = useState(0);

    const [popoverId] = useState(Date.now());

    useLayoutEffect(() => {
        if (open === true) {
            document.body.classList.add('popoverBodyOf');
            // setDefaultPlacement(placement || "top-start");
            setDefaultWidth(width || 300);
            let anchorElRect = anchorEl.current.getBoundingClientRect();
            let windowInnerWidth = document.body.scrollWidth;
            let windowInnerHeight = document.body.scrollHeight;
            let anchorElTop = anchorElRect.top;
            let anchorElLeft = anchorElRect.left;
            let anchorElWidth = anchorElRect.width;
            let anchorElHeight = anchorElRect.height;
            let popoverHeight = popoverRef.current?.offsetHeight;
            let popoverWidth = popoverRef.current?.offsetWidth;
            if (defaultPlacement === 'top-start') {
                if (anchorElTop > popoverHeight) {
                    setTopPosition(anchorElTop - popoverHeight);
                    setDefaultPlacement(placement || 'top-start');
                } else {
                    setTopPosition(anchorElTop + anchorElHeight);
                    setDefaultPlacement(placement || 'bottom-start');
                }
                if (windowInnerWidth - anchorElLeft > popoverWidth) {
                    setLeftPosition(anchorElLeft);
                    setDefaultPlacement(placement || 'top-start');
                } else {
                    setLeftPosition(anchorElLeft + anchorElWidth - popoverWidth);
                    setDefaultPlacement(placement || 'bottom-end');
                }
            } else if (defaultPlacement === 'top-center') {
                if (anchorElTop > popoverHeight) {
                    setTopPosition(anchorElTop - popoverHeight);
                    setDefaultPlacement(placement || 'top-center');
                } else {
                    setTopPosition(anchorElTop + anchorElHeight);
                    setDefaultPlacement(placement || 'bottom-center');
                }

                if (
                    anchorElLeft + anchorElWidth / 2 > popoverWidth / 2 &&
                    windowInnerWidth - anchorElLeft - anchorElWidth / 2 > popoverWidth / 2
                ) {
                    setLeftPosition(anchorElLeft + anchorElWidth / 2 - popoverWidth / 2);
                } else if (anchorElLeft + anchorElWidth / 2 < popoverWidth / 2) {
                    setLeftPosition(anchorElLeft);
                } else {
                    setLeftPosition(anchorElLeft + anchorElWidth - popoverWidth);
                }
            } else if (defaultPlacement === 'top-end') {
                if (anchorElTop > popoverHeight) {
                    setTopPosition(anchorElTop - popoverHeight);
                } else {
                    setTopPosition(anchorElTop + anchorElHeight);
                }

                if (anchorElLeft < popoverWidth) {
                    setLeftPosition(anchorElLeft);
                } else {
                    setLeftPosition(anchorElLeft + anchorElWidth - popoverWidth);
                }
            } else if (defaultPlacement === 'bottom-start') {
                if (windowInnerHeight - anchorElTop < popoverHeight) {
                    setTopPosition(anchorElTop - popoverHeight);
                } else {
                    setTopPosition(anchorElTop + anchorElHeight);
                }
                if (windowInnerWidth - anchorElLeft > popoverWidth) {
                    setLeftPosition(anchorElLeft);
                } else {
                    setLeftPosition(anchorElLeft + anchorElWidth - popoverWidth);
                }
            } else if (defaultPlacement === 'bottom-center') {
                if (windowInnerHeight - anchorElTop < popoverHeight) {
                    setTopPosition(anchorElTop - popoverHeight);
                } else {
                    setTopPosition(anchorElTop + anchorElHeight);
                }

                if (
                    anchorElLeft + anchorElWidth / 2 > popoverWidth / 2 &&
                    windowInnerWidth - anchorElLeft - anchorElWidth / 2 > popoverWidth / 2
                ) {
                    setLeftPosition(anchorElLeft + anchorElWidth / 2 - popoverWidth / 2);
                } else if (anchorElLeft + anchorElWidth / 2 < popoverWidth / 2) {
                    setLeftPosition(anchorElLeft);
                } else {
                    setLeftPosition(anchorElLeft + anchorElWidth - popoverWidth);
                }
            } else if (defaultPlacement === 'bottom-end') {
                if (windowInnerHeight - anchorElTop < popoverHeight) {
                    setTopPosition(anchorElTop - popoverHeight);
                } else {
                    setTopPosition(anchorElTop + anchorElHeight);
                }

                if (anchorElLeft < popoverWidth) {
                    setLeftPosition(anchorElLeft);
                } else {
                    setLeftPosition(anchorElLeft + anchorElWidth - popoverWidth);
                }
            } else if (defaultPlacement === 'left-start') {
                if (windowInnerHeight - anchorElTop > popoverHeight) {
                    setTopPosition(anchorElTop);
                } else {
                    setTopPosition(anchorElTop + anchorElHeight - popoverHeight);
                }

                if (anchorElLeft > popoverWidth) {
                    setLeftPosition(anchorElLeft - popoverWidth);
                } else {
                    setLeftPosition(anchorElLeft + anchorElWidth);
                }
            } else if (defaultPlacement === 'left-center') {
                if (
                    anchorElTop + anchorElHeight / 2 > popoverHeight / 2 &&
                    windowInnerHeight - anchorElTop - anchorElHeight / 2 > popoverHeight / 2
                ) {
                    setTopPosition(anchorElTop + anchorElHeight / 2 - popoverHeight / 2);
                } else if (anchorElTop + anchorElHeight / 2 < popoverHeight / 2) {
                    setTopPosition(anchorElTop);
                } else {
                    setTopPosition(anchorElTop + anchorElHeight - popoverHeight);
                }
                if (anchorElLeft > popoverWidth) {
                    setLeftPosition(anchorElLeft - popoverWidth);
                } else {
                    setLeftPosition(anchorElLeft + anchorElWidth);
                }
            } else if (defaultPlacement === 'left-end') {
                if (windowInnerHeight - anchorElTop > popoverHeight) {
                    setTopPosition(anchorElTop);
                } else {
                    setTopPosition(anchorElTop + anchorElHeight - popoverHeight);
                }
                if (anchorElLeft > popoverWidth) {
                    setLeftPosition(anchorElLeft - popoverWidth);
                } else {
                    setLeftPosition(anchorElLeft + anchorElWidth);
                }
            } else if (defaultPlacement === 'right-start') {
                if (windowInnerHeight - anchorElTop > popoverHeight) {
                    setTopPosition(anchorElTop);
                } else {
                    setTopPosition(anchorElTop + anchorElHeight - popoverHeight);
                }

                if (windowInnerWidth - anchorElLeft > popoverWidth) {
                    setLeftPosition(anchorElLeft + anchorElWidth);
                } else {
                    setLeftPosition(anchorElLeft - popoverWidth);
                }
            } else if (defaultPlacement === 'right-center') {
                if (
                    anchorElTop + anchorElHeight / 2 > popoverHeight / 2 &&
                    windowInnerHeight - anchorElTop - anchorElHeight / 2 > popoverHeight / 2
                ) {
                    setTopPosition(anchorElTop + anchorElHeight / 2 - popoverHeight / 2);
                } else if (anchorElTop + anchorElHeight / 2 < popoverHeight / 2) {
                    setTopPosition(anchorElTop);
                } else {
                    setTopPosition(anchorElTop + anchorElHeight - popoverHeight);
                }
                if (windowInnerWidth - anchorElLeft > popoverWidth) {
                    setLeftPosition(anchorElLeft + anchorElWidth);
                } else {
                    setLeftPosition(anchorElLeft - popoverWidth);
                }
            } else if (defaultPlacement === 'right-end') {
                if (anchorElTop > popoverHeight) {
                    setTopPosition(anchorElTop + anchorElHeight - popoverHeight);
                } else {
                    setTopPosition(anchorElTop);
                }

                if (windowInnerWidth - anchorElLeft > popoverWidth) {
                    setLeftPosition(anchorElLeft + anchorElWidth);
                } else {
                    setLeftPosition(anchorElLeft - popoverWidth);
                }
            } else if (defaultPlacement === 'no-placement-left') {
                if (windowInnerHeight - anchorElTop < popoverHeight) {
                    setTopPosition(anchorElTop - popoverHeight);
                } else {
                    setTopPosition(anchorElTop + anchorElHeight);
                }
                setLeftPosition(15);
            } else if (defaultPlacement === 'no-placement') {
                if (windowInnerHeight - anchorElTop < popoverHeight) {
                    setTopPosition(anchorElTop - popoverHeight);
                } else {
                    setTopPosition(anchorElTop + anchorElHeight);
                }
                setLeftPosition(windowInnerWidth - popoverWidth - 15);
            }
        } else {
            document.body.classList.remove('popoverBodyOf');
        }
    }, [anchorEl, defaultPlacement, open, placement, width]);

    useEffect(() => {
        /**
         * Alert if clicked on outside of element
         */
        function handleClickOutside(event) {
            if (open && popoverRef.current && !anchorEl.current.contains(event.target)) {
                const popovers = document.querySelectorAll('[data-popover-id]');

                let currentPopoverId;

                for (const popover of popovers) {
                    if (popover.contains(event.target)) {
                        currentPopoverId = parseInt(popover.dataset.popoverId);
                    }
                }

                if (!currentPopoverId || currentPopoverId < popoverId) onClose();
            }
        }
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener('click', handleClickOutside);
        };
    }, [anchorEl, onClose, open, popoverId, popoverRef]);

    useEffect(() => {
        if (placement) setDefaultPlacement(placement);
    }, [placement]);

    const addNewStyles = styles => {
        const newStyleSheet = document.createElement('style');
        document.head.appendChild(newStyleSheet);
        const styleSheet = newStyleSheet.sheet;
        styleSheet.insertRule(styles, 0);
    };

    const setSlideUpAnimation = useCallback(() => {
        setAnimationName('slideUp');

        let slideUp = `
        @-webkit-keyframes slideUp {
            0% {-webkit-transform: translate3d(${leftPosition}px,${
            topPosition + 40
        }px, 0); opacity: 0}
            100% {-webkit-transform: translate3d(${leftPosition}px,${topPosition}px, 0); opacity: 1}
        }
    `;
        addNewStyles(slideUp);
    }, [leftPosition, topPosition]);

    const setSlideDownAnimation = useCallback(() => {
        setAnimationName('slideDown');

        let slideDown = `
        @-webkit-keyframes slideDown {
            0% {-webkit-transform: translate3d(${leftPosition}px,${
            topPosition - 40
        }px, 0); opacity: 0}
            100% {-webkit-transform: translate3d(${leftPosition}px,${topPosition}px, 0); opacity: 1}
        }
    `;
        addNewStyles(slideDown);
    }, [leftPosition, topPosition]);

    const setSlideRightAnimation = useCallback(() => {
        setAnimationName('slideRight');

        let slideRight = `
        @-webkit-keyframes slideRight {
            0% {-webkit-transform: translate3d(${
                leftPosition - 30
            }px,${topPosition}px, 0); opacity: 0}
            100% {-webkit-transform: translate3d(${leftPosition}px,${topPosition}px, 0); opacity: 1}
        }
    `;
        addNewStyles(slideRight);
    }, [leftPosition, topPosition]);

    const setSlideLeftAnimation = useCallback(() => {
        setAnimationName('slideLeft');

        let slideLeft = `
        @-webkit-keyframes slideLeft {
            0% {-webkit-transform: translate3d(${
                leftPosition + 30
            }px,${topPosition}px, 0); opacity: 0}
            100% {-webkit-transform: translate3d(${leftPosition}px,${topPosition}px, 0); opacity: 1}
        }
    `;
        addNewStyles(slideLeft);
    }, [leftPosition, topPosition]);

    useLayoutEffect(() => {
        if (leftPosition && topPosition && true) {
            if (animationPlacement == 'slideUp') {
                setSlideUpAnimation();
            } else if (animationPlacement == 'slideDown') {
                setSlideDownAnimation();
            } else if (animationPlacement == 'slideRight') {
                setSlideRightAnimation();
            } else if (animationPlacement == 'slideLeft') {
                setSlideLeftAnimation();
            }
            firstRun.current = false;
        }
    }, [
        animationPlacement,
        leftPosition,
        setSlideDownAnimation,
        setSlideLeftAnimation,
        setSlideRightAnimation,
        setSlideUpAnimation,
        topPosition,
    ]);

    return (
        open && (
            <PopoverWrapper
                ref={popoverRef}
                style={{
                    position: 'absolute',
                    willChange: 'transform',
                    top: 0,
                    left: 0,
                    transform: `translate3d(${leftPosition}px,${topPosition}px, 0)`,
                    animationName: animationName,
                    animationDuration: '0.3s',
                    animationTimingFunction: 'ease-in-out',
                    animationDelay: '0s',
                    animationIterationCount: 1,
                    animationDirection: 'normal',
                    ...style,
                }}
                className={`PopoverMain ${customClass || ''} ${defaultPlacement || ''}`}
                popoverId={popoverId}
            >
                <Div
                    reference={parentRef}
                    className={`PopoverMainInner ${withOutPadding ? 'withOutPadding' : ''}`}
                    style={{
                        width: defaultWidth,
                        minWidth: minWidth,
                        height: height || 'auto',
                        maxHeight: maxHeight || 'unset',
                    }}
                >
                    <Div className="PMIContent">{children}</Div>
                </Div>
            </PopoverWrapper>
        )
    );
};

Popover.propTypes = {
    customClass: PropTypes.string,
    anchorEl: PropTypes.object,
    placement: PropTypes.string,
    onClose: PropTypes.func,
    open: PropTypes.bool,
    children: PropTypes.any,
    width: PropTypes.string | PropTypes.number,
    minWidth: PropTypes.number,
    maxHeight: PropTypes.string | PropTypes.number,
    height: PropTypes.string | PropTypes.number,
    withOutPadding: PropTypes.bool,
    animationPlacement: PropTypes.string,
    style: PropTypes.object,
    parentRef: PropTypes.any,
};

// animationPlacement can be
// slideUp
// slideDown
// slideLeft
// slideRight

export default Popover;
