import React from 'react';

import { SVGIcon } from '../../UIKit/index';

export default function DCOutreach(props) {
    return (
        <SVGIcon {...props} viewBox="0 0 2000 2000" fill="none">
            <g clipPath="url(#clip0)">
                <rect width="2000" height="2000" rx="400" fill="url(#paint0_linear)" />
                <g filter="url(#filter0_f)">
                    <path
                        d="M1486.56 1504.55L1744.15 1371.39C1783 1351.3 1779.16 1294.54 1737.96 1279.87L614.203 879.75C578.131 866.906 541.6 897.717 548.175 935.439L690.254 1750.6C695.747 1782.11 729.007 1800.43 758.578 1788.23L1245.3 1587.47C1264.88 1579.4 1287.41 1584.53 1301.56 1600.28L1360.19 1665.54C1388.67 1697.25 1441.23 1681.07 1446.93 1638.82L1459.98 1542.28C1462.16 1526.12 1472.08 1512.04 1486.56 1504.55Z"
                        fill="black"
                        fillOpacity="0.1"
                    />
                </g>
                <path
                    d="M472.628 392.732L1655.77 882.488C1744.24 919.107 1735.62 1047.13 1643.05 1071.57L1356.69 1147.17L1326.03 1391.72C1320.85 1432.99 1270.47 1450.27 1241.06 1420.85L1086.58 1266.37L620.987 1540.51C556.817 1578.29 475.34 1535.19 470.464 1460.89L403.612 442.204C401.186 405.238 438.399 378.563 472.628 392.732Z"
                    fill="#C4C4C4"
                />
                <path
                    d="M472.628 392.732L1655.77 882.488C1744.24 919.107 1735.62 1047.13 1643.05 1071.57L1356.69 1147.17L1326.03 1391.72C1320.85 1432.99 1270.47 1450.27 1241.06 1420.85L1086.58 1266.37L620.987 1540.51C556.817 1578.29 475.34 1535.19 470.464 1460.89L403.612 442.204C401.186 405.238 438.399 378.563 472.628 392.732Z"
                    fill="white"
                />
                <path
                    d="M1356.69 1147.17L1324.1 1404.47C1318.89 1445.64 1264.82 1457.37 1243.02 1422.07L1217.96 1381.5L1141.05 1238.52L455.196 429.073C448.899 421.642 458.835 411.54 466.369 417.713L1356.69 1147.17Z"
                    fill="url(#paint1_linear)"
                />
                <path
                    d="M1141.05 1238.52L1244.46 1424.5L1086.74 1266.59L1141.05 1238.52Z"
                    fill="#F9BAD1"
                />
            </g>
            <defs>
                <filter
                    id="filter0_f"
                    x="347.402"
                    y="676.785"
                    width="1623.79"
                    height="1315.25"
                    filterUnits="userSpaceOnUse"
                    colorInterpolationFilters="sRGB"
                >
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feBlend
                        mode="normal"
                        in="SourceGraphic"
                        in2="BackgroundImageFix"
                        result="shape"
                    />
                    <feGaussianBlur stdDeviation="100" result="effect1_foregroundBlur" />
                </filter>
                <linearGradient
                    id="paint0_linear"
                    x1="849"
                    y1="0"
                    x2="849"
                    y2="2000"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#ED5E83" />
                    <stop offset="1" stopColor="#FF3267" />
                </linearGradient>
                <linearGradient
                    id="paint1_linear"
                    x1="1090.38"
                    y1="354.18"
                    x2="1209.64"
                    y2="1537.11"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="white" />
                    <stop offset="1" stopColor="#F8A4BF" />
                </linearGradient>
                <clipPath id="clip0">
                    <rect width="2000" height="2000" fill="white" />
                </clipPath>
            </defs>
        </SVGIcon>
    );
}
