import React from 'react';

import { SVGIcon } from '../../UIKit/index';

export default function DCFlag(props) {
    return (
        <SVGIcon {...props} viewBox="-45 0 438 438">
            <g>
                <path d="m75.953125 0h-45.300781c-4.511719.0625-8.167969 3.671875-8.292969 8.179688v413.820312h-14.359375c-4.417969 0-8 3.582031-8 8s3.582031 8 8 8h90.601562c4.417969 0 8-3.582031 8-8s-3.582031-8-8-8h-14.242187v-211.992188l22.476563 12.34375c33.917968 18.421876 75.457031 15.398438 106.351562-7.742187 25.925781-19.386719 60.769531-21.921875 89.226562-6.484375l34.492188 18.777344c2.476562 1.347656 5.484375 1.289062 7.90625-.152344 2.425781-1.441406 3.910156-4.054688 3.910156-6.875v-166.789062c0-2.929688-1.605468-5.628907-4.175781-7.027344l-34.5-18.777344c-33.972656-18.417969-75.550781-15.394531-106.5 7.742188-25.863281 19.386718-60.652344 21.921874-89.050781 6.484374l-30.136719-16.507812v-16.820312c-.175781-4.53125-3.871094-8.1289068-8.40625-8.179688zm8.40625 43.21875 22.480469 12.34375c33.933594 18.417969 75.488281 15.394531 106.398437-7.742188 25.917969-19.386718 60.75-21.921874 89.203125-6.484374l30.296875 16.5-.015625 148.570312-22.675781-12.335938c-33.972656-18.417968-75.554687-15.394531-106.507813 7.742188-25.863281 19.386719-60.65625 21.921875-89.054687 6.488281l-30.125-16.507812zm-46 378.78125v-406h30v406zm0 0" />
            </g>
        </SVGIcon>
    );
}
