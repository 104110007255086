import React from 'react';

import { SVGIcon } from '../../UIKit/index';

export default function DCPhoneSupport(props) {
    return (
        <SVGIcon {...props} viewBox="0 0 42 42" fill="none">
            <g clip-path="url(#clip0_66_618)">
                <path
                    d="M19.4117 4.85283C19.5508 4.65174 19.5607 4.31119 19.3365 4.09096L17.493 2.24752C17.2573 2.01189 16.8752 2.01189 16.6393 2.24752C16.4036 2.4833 16.4036 2.86541 16.6393 3.10127L17.4523 3.91416H8.45774C5.95266 3.91416 3.91461 5.95221 3.91461 8.45729V18.5799C3.91461 18.9134 4.18485 19.1835 4.51821 19.1835C4.85157 19.1835 5.1218 18.9133 5.1218 18.5799V8.45729C5.1218 6.61791 6.61828 5.12143 8.45766 5.12143H17.4523L16.6393 5.93447C16.4036 6.17018 16.4036 6.55236 16.6394 6.78814C16.8752 7.02385 17.2573 7.02385 17.493 6.78807L19.336 4.94502C19.3448 4.9358 19.373 4.90775 19.4117 4.85283Z"
                    fill="#D8ECFE"
                />
                <path
                    d="M35.4818 20.8154C35.1484 20.8154 34.8782 21.0857 34.8782 21.419V31.5417C34.8782 33.3811 33.3817 34.8775 31.5423 34.8775H22.6853L23.4984 34.0645C23.7341 33.8288 23.7341 33.4466 23.4983 33.2108C23.2627 32.9752 22.8805 32.9751 22.6446 33.2109L20.8016 35.0539C20.5955 35.2521 20.5421 35.6561 20.8012 35.9081L22.6446 37.7515C22.8804 37.9871 23.2626 37.9873 23.4984 37.7515C23.7341 37.5157 23.7341 37.1336 23.4984 36.8978L22.6854 36.0849H31.5423C34.0474 36.0849 36.0855 34.0468 36.0855 31.5418V21.4191C36.0854 21.0856 35.8152 20.8154 35.4818 20.8154Z"
                    fill="#D8ECFE"
                />
                <path
                    d="M25.2 25.1801C24.2111 26.1234 22.742 26.3383 21.5416 25.7079C20.1925 24.9995 18.8673 23.7003 17.8881 21.9808C16.9088 20.2611 16.4618 18.4479 16.5313 16.9091C16.5931 15.5401 17.5113 14.3576 18.8116 13.9611C19.103 13.8722 19.4156 13.9968 19.5668 14.2624L20.6898 16.2344C20.9004 16.6043 20.7129 17.0781 20.3068 17.2019L19.572 17.4261C19.2635 17.5201 19.0664 17.8256 19.1072 18.1477C19.1958 18.8467 19.4273 19.8433 20.0427 20.8768C20.593 21.8014 21.2566 22.4297 21.7739 22.829C22.0279 23.025 22.3867 23.0031 22.6205 22.7801L23.1796 22.2468C23.4884 21.9522 23.9869 22.0244 24.1976 22.3943L25.3204 24.3661C25.4716 24.6318 25.4218 24.9686 25.2 25.1801Z"
                    fill="#FFE179"
                />
                <path
                    d="M40 8.95785C40 11.5609 38.8896 13.9045 37.1166 15.5411C35.5203 17.0146 33.5047 17.7588 31.1609 17.7588C28.8172 17.7588 26.5659 17.0145 24.9695 15.5411C23.1966 13.9046 22.0862 11.5609 22.0862 8.95785C22.0862 4.01113 26.0963 0.000976562 31.043 0.000976562C35.9898 0.000976562 40 4.01113 40 8.95785Z"
                    fill="#EAF6FF"
                />
                <path
                    d="M31.0431 0.000976562C30.6337 0.000976562 30.2308 0.0290234 29.8359 0.0822266C34.2116 0.671836 37.5855 4.42059 37.5855 8.95785C37.5855 13.4951 34.2116 17.2439 29.8359 17.8335C30.2308 17.8867 30.7515 17.7588 31.1609 17.7588C33.5047 17.7588 35.5203 17.0145 37.1166 15.5411C38.8896 13.9045 40 11.5608 40 8.95777C40 4.01105 35.9898 0.000976562 31.0431 0.000976562Z"
                    fill="#D8ECFE"
                />
                <path
                    d="M31.1609 17.7589C33.5047 17.7589 35.5199 17.0142 37.1163 15.5407C36.8217 12.4453 34.2156 10.0239 31.0431 10.0239C27.8706 10.0239 25.2645 12.4453 24.97 15.5407C26.2874 16.7568 27.9705 17.5827 29.8359 17.8341C30.2307 17.8873 30.7516 17.7589 31.1609 17.7589Z"
                    fill="#F58A97"
                />
                <path
                    d="M29.8362 17.8334C29.8377 17.8337 29.8391 17.8338 29.8405 17.8341C32.3067 18.165 35.0005 17.4466 36.9685 15.6741C37.0183 15.6302 37.0676 15.5857 37.1163 15.5407C37.0405 14.743 36.81 13.9907 36.457 13.3125C35.1127 15.7234 32.6873 17.4491 29.8362 17.8334Z"
                    fill="#F07281"
                />
                <path
                    d="M31.0431 11.3549C29.2196 11.3549 27.7413 9.87658 27.7413 8.05307V6.939C27.7413 5.11549 29.2196 3.63721 31.0431 3.63721C32.8666 3.63721 34.3449 5.11549 34.3449 6.939V8.05307C34.3449 9.87658 32.8666 11.3549 31.0431 11.3549Z"
                    fill="#FFCEBF"
                />
                <path
                    d="M31.0431 3.63721C30.6169 3.63721 30.2099 3.71885 29.8359 3.86596C31.062 4.34807 31.9304 5.54174 31.9304 6.939V8.05307C31.9304 9.45033 31.062 10.644 29.8359 11.1261C30.2099 11.2731 30.6169 11.3549 31.0431 11.3549C32.8666 11.3549 34.3449 9.87658 34.3449 8.05307V6.939C34.3449 5.11549 32.8666 3.63721 31.0431 3.63721Z"
                    fill="#FFB09E"
                />
                <path
                    d="M17.9137 31.0443C17.9137 33.6473 16.8034 35.9909 15.0304 37.6275C13.4341 39.101 11.4184 39.8452 9.07469 39.8452C6.73094 39.8452 4.47969 39.1009 2.88328 37.6275C1.11039 35.9909 0 33.6472 0 31.0443C0 26.0976 4.01016 22.0874 8.95687 22.0874C13.9036 22.0874 17.9137 26.0976 17.9137 31.0443Z"
                    fill="#EAF6FF"
                />
                <path
                    d="M8.95688 22.0874C8.54742 22.0874 8.14453 22.1154 7.74961 22.1687C12.1253 22.7583 15.4992 26.507 15.4992 31.0443C15.4992 35.5815 12.1253 39.3303 7.74961 39.9199C8.14453 39.9731 8.66524 39.8452 9.07469 39.8452C11.4184 39.8452 13.4341 39.1009 15.0304 37.6275C16.8034 35.991 17.9138 33.6473 17.9138 31.0443C17.9138 26.0976 13.9036 22.0874 8.95688 22.0874Z"
                    fill="#D8ECFE"
                />
                <path
                    d="M9.07469 39.8454C11.4184 39.8454 13.4337 39.1007 15.03 37.6272C14.7355 34.5318 12.1294 32.1104 8.95688 32.1104C5.78438 32.1104 3.1782 34.5318 2.88375 37.6272C4.20117 38.8432 5.8843 39.6691 7.74961 39.9205C8.14438 39.9738 8.66524 39.8454 9.07469 39.8454Z"
                    fill="#8FD8FA"
                />
                <path
                    d="M7.74985 39.9199C7.75133 39.9201 7.75282 39.9203 7.75422 39.9205C10.2204 40.2514 12.9142 39.533 14.8822 37.7605C14.932 37.7166 14.9813 37.6721 15.03 37.6271C14.9541 36.8295 14.7237 36.0771 14.3706 35.3989C13.0265 37.8098 10.601 39.5356 7.74985 39.9199Z"
                    fill="#68CBF2"
                />
                <path
                    d="M8.95687 33.4413C7.13336 33.4413 5.65508 31.963 5.65508 30.1395V29.0254C5.65508 27.2019 7.13336 25.7236 8.95687 25.7236C10.7804 25.7236 12.2587 27.2019 12.2587 29.0254V30.1395C12.2587 31.9631 10.7804 33.4413 8.95687 33.4413Z"
                    fill="#FFCEBF"
                />
                <path
                    d="M8.95688 25.7236C8.53063 25.7236 8.12367 25.8053 7.74961 25.9524C8.97578 26.4345 9.84414 27.6282 9.84414 29.0254V30.1395C9.84414 31.5368 8.97578 32.7304 7.74961 33.2125C8.12367 33.3596 8.53063 33.4413 8.95688 33.4413C10.7804 33.4413 12.2587 31.963 12.2587 30.1395V29.0254C12.2587 27.2018 10.7804 25.7236 8.95688 25.7236Z"
                    fill="#FFB09E"
                />
            </g>
            <defs>
                <clipPath id="clip0_66_618">
                    <rect width="40" height="40" fill="white" />
                </clipPath>
            </defs>
        </SVGIcon>
    );
}
