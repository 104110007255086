import React, { useEffect, useRef } from 'react';
import { isSafari } from 'Utils';
import { isAuthenticated } from 'Utils/auth';

export default function OauthLanding() {
    const firstTime = useRef(true);
    useEffect(() => {
        if (firstTime.current) {
            firstTime.current = false;
            if (window.location.hash && window.location.hash == '_=_') {
                window.location.hash = '';
            }
            if (isAuthenticated()) {
                const result = { type: 'oauth-results', search: window.location.search };
                if (!isSafari()) {
                    window?.opener?.postMessage(result, '*');
                } else {
                    localStorage.setItem('oauth-results', JSON.stringify({ data: result }));
                }
            }
        }
    }, []);
    useEffect(() => {
        function checkOauthData() {
            const item = localStorage.getItem('oauth-results');

            if (!item) {
                localStorage.removeItem('oauth-results');
                window.close();
            }
        }
        if (isSafari()) window.addEventListener('storage', checkOauthData);

        return () => {
            window.removeEventListener('storage', checkOauthData);
        };
    }, []);
    // window.opener.close();
    return <div>Successful...</div>;
}
