const instaSiteStore = {
    templates: new Map(),
    categories: [],
    mainCategory: [],
    initialTemplateLoad: true,
    activeCategory: 1, // Category All is 1
    templateSearchText: '',

    initialMyInstasiteLoad: true,
    status: [
        {
            id: 0,
            title: 'Built',
            type: 'built',
            value: 'NOT_PUBLISHED_YET',
            color: '#F4AD3B',
            isChecked: false,
            count: 0,
        },
        {
            id: 1,
            title: 'Queued',
            value: 'PENDING',
            type: 'queued',
            color: '#2269ec',
            isChecked: false,
            count: 0,
        },
        {
            id: 2,
            title: 'Purchased',
            value: 'PUBLISHED',
            type: 'purchased',
            color: '#3a703c',
            isChecked: false,
            count: 0,
        },
        {
            id: 3,
            title: 'Cancelled',
            value: 'UNPUBLISHED',
            type: 'cancelled',
            color: '#000000',
            isChecked: false,
            count: 0,
        },
        {
            id: 4,
            title: 'Failed',
            value: 'FAILED',
            type: 'failed',
            color: '#fa0915',
            isChecked: false,
            count: 0,
        },
        {
            id: 5,
            title: 'Retrying',
            value: 'RETRYING',
            type: 'retrying',
            color: '#651268',
            isChecked: false,
            count: 0,
        },
        {
            id: 6,
            title: 'Purged',
            value: 'PURGED',
            type: 'purged',
            color: '#646464',
            isChecked: false,
            count: 0,
        },
    ],
    fields: [],
    page: 1,
    totalResults: 1,
    totalPages: 1,
    pageLimit: 25,
    data: new Map(),
    sortString: '',
    myInstaSiteSearchText: '',

    selectedInstaSite: {},
    statusString: '',
    additionalInfo: {},
};

export default instaSiteStore;
