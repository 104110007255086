import React from 'react';

import { SVGIcon } from '../../UIKit/index';

export default function DCCampaigns1(props) {
    return (
        <SVGIcon {...props} viewBox="0 0 503.7 358.365">
            <defs>
                <clipPath id="clip-path-campaigns1">
                    <path
                        id="Path_40"
                        data-name="Path 40"
                        d="M278.63,327.57c-2-2-6.28-9.76-10-29.81-3.57-19.37-5.54-44.5-5.54-70.76s2-51.38,5.54-70.75c3.69-20.05,8-27.8,10-29.81,2,2,6.28,9.76,10,29.81,3.56,19.37,31.71,44.5,31.71,70.75s-28.15,51.39-31.71,70.76C284.91,317.81,280.6,325.56,278.63,327.57Z"
                        fill="none"
                    />
                </clipPath>
                <linearGradient
                    id="linear-gradient-campaigns1"
                    x1="0.944"
                    y1="-0.151"
                    x2="-0.806"
                    y2="2.994"
                    gradientUnits="objectBoundingBox"
                >
                    <stop offset="0" stopColor="#f7b777" />
                    <stop offset="1" stopColor="#f47960" />
                </linearGradient>
                <linearGradient
                    id="linear-gradient-campaigns1-2"
                    x1="0.206"
                    y1="0.018"
                    x2="1.192"
                    y2="2.09"
                    xlinkHref="#linear-gradient-campaigns1"
                />
            </defs>
            <g id="Campaigns" transform="translate(-3.91 -76.145)">
                <ellipse
                    id="_Path_"
                    data-name="&lt;Path&gt;"
                    cx="240.49"
                    cy="41.58"
                    rx="240.49"
                    ry="41.58"
                    transform="translate(14.68 351.35)"
                    fill="#f4f5f6"
                />
                <path
                    id="Path_1"
                    data-name="Path 1"
                    d="M49.62,143.72H31.72a2.23,2.23,0,0,1-2.23-2.23h0a2.23,2.23,0,0,1,2.23-2.22h17.9a2.23,2.23,0,0,1,2.23,2.22h0a2.23,2.23,0,0,1-2.23,2.23Z"
                    fill="#e4e3e3"
                />
                <path
                    id="Path_2"
                    data-name="Path 2"
                    d="M117.61,143.72H60.07a2.23,2.23,0,0,1-2.23-2.23h0a2.23,2.23,0,0,1,2.23-2.22h57.54a2.23,2.23,0,0,1,2.23,2.22h0a2.23,2.23,0,0,1-2.23,2.23Z"
                    fill="#e4e3e3"
                />
                <path
                    id="Path_3"
                    data-name="Path 3"
                    d="M124.53,152.44H67a2.22,2.22,0,0,1-2.23-2.22h0A2.23,2.23,0,0,1,67,148h57.55a2.22,2.22,0,0,1,2.22,2.23h0a2.21,2.21,0,0,1-2.24,2.21Z"
                    fill="#e4e3e3"
                />
                <path
                    id="Path_4"
                    data-name="Path 4"
                    d="M108.16,169.89H67a2.23,2.23,0,0,1-2.23-2.23h0A2.22,2.22,0,0,1,67,165.44h41.18a2.22,2.22,0,0,1,2.23,2.22h0a2.23,2.23,0,0,1-2.25,2.23Z"
                    fill="#e4e3e3"
                />
                <path
                    id="Path_5"
                    data-name="Path 5"
                    d="M131,161.17H73.43a2.22,2.22,0,0,1-2.22-2.23h0a2.22,2.22,0,0,1,2.22-2.23H131a2.23,2.23,0,0,1,2.23,2.23h0A2.23,2.23,0,0,1,131,161.17Z"
                    fill="#e4e3e3"
                />
                <path
                    id="Path_6"
                    data-name="Path 6"
                    d="M377.12,320.54H359.21a2.22,2.22,0,0,1-2.22-2.23h0a2.21,2.21,0,0,1,2.22-2.22h17.91a2.21,2.21,0,0,1,2.22,2.22h0a2.22,2.22,0,0,1-2.22,2.23Z"
                    fill="#e4e3e3"
                />
                <path
                    id="Path_7"
                    data-name="Path 7"
                    d="M445.11,320.54H387.56a2.22,2.22,0,0,1-2.22-2.23h0a2.21,2.21,0,0,1,2.22-2.22h57.55a2.22,2.22,0,0,1,2.23,2.22h0a2.23,2.23,0,0,1-2.23,2.23Z"
                    fill="#e4e3e3"
                />
                <path
                    id="Path_8"
                    data-name="Path 8"
                    d="M452,329.26H394.48a2.22,2.22,0,0,1-2.23-2.22h0a2.23,2.23,0,0,1,2.23-2.23H452a2.23,2.23,0,0,1,2.23,2.23h0a2.22,2.22,0,0,1-2.23,2.22Z"
                    fill="#e4e3e3"
                />
                <path
                    id="Path_9"
                    data-name="Path 9"
                    d="M435.66,346.71H394.48a2.23,2.23,0,0,1-2.23-2.23h0a2.23,2.23,0,0,1,2.23-2.23h41.18a2.23,2.23,0,0,1,2.23,2.23h0a2.23,2.23,0,0,1-2.23,2.23Z"
                    fill="#e4e3e3"
                />
                <path
                    id="Path_10"
                    data-name="Path 10"
                    d="M458.48,338H400.93a2.23,2.23,0,0,1-2.23-2.23h0a2.23,2.23,0,0,1,2.23-2.23h57.55a2.22,2.22,0,0,1,2.22,2.23h0a2.22,2.22,0,0,1-2.22,2.23Z"
                    fill="#e4e3e3"
                />
                <path
                    id="Path_11"
                    data-name="Path 11"
                    d="M322.72,146.74v8.44a1.88,1.88,0,0,0,1.79,1.87l10.55.5a1.89,1.89,0,0,0,2-1.88v-8.93a1.88,1.88,0,0,0-1.88-1.88H324.6A1.88,1.88,0,0,0,322.72,146.74Z"
                    fill="#e4e3e3"
                />
                <path
                    id="Path_12"
                    data-name="Path 12"
                    d="M394.33,219.29V226a1.49,1.49,0,0,0,1.42,1.49l8.39.39a1.49,1.49,0,0,0,1.56-1.49v-7.1a1.49,1.49,0,0,0-1.49-1.49h-8.39a1.49,1.49,0,0,0-1.49,1.49Z"
                    fill="#e4e3e3"
                />
                <path
                    id="Path_13"
                    data-name="Path 13"
                    d="M426.57,123.86v6a1.32,1.32,0,0,0,1.26,1.32l7.43.35a1.33,1.33,0,0,0,1.39-1.32v-6.3a1.33,1.33,0,0,0-1.33-1.32h-7.43a1.32,1.32,0,0,0-1.32,1.27Z"
                    fill="#e4e3e3"
                />
                <path
                    id="Path_14"
                    data-name="Path 14"
                    d="M410.7,267.2v3.86a.86.86,0,0,0,.82.86l4.82.23a.86.86,0,0,0,.9-.86V267.2a.85.85,0,0,0-.86-.85h-4.82a.85.85,0,0,0-.86.85Z"
                    fill="#e4e3e3"
                />
                <path
                    id="Path_15"
                    data-name="Path 15"
                    d="M424,220.15l-.43,26.19a3,3,0,0,0,3,3H456.7a3,3,0,0,0,3-3V220.2a3,3,0,0,0-3-3H427a3,3,0,0,0-3,2.95Z"
                    fill="#707272"
                />
                <path
                    id="Path_16"
                    data-name="Path 16"
                    d="M441.75,245.31h-.65c-7.44-.33-13.25-6.25-12.94-13.21a12.15,12.15,0,0,1,4.36-8.76,13.93,13.93,0,0,1,9.68-3.25c7.44.33,13.24,6.25,12.94,13.2h0a12.15,12.15,0,0,1-4.37,8.77,13.87,13.87,0,0,1-9.02,3.25Zm-.2-23.46a12,12,0,0,0-7.87,2.84,10.31,10.31,0,0,0-3.74,7.48c-.26,6,4.78,11.06,11.24,11.35a12.15,12.15,0,0,0,8.44-2.83,10.34,10.34,0,0,0,3.74-7.49c.26-6-4.78-11.06-11.24-11.34Z"
                    fill="#f4f5f6"
                />
                <path
                    id="Path_17"
                    data-name="Path 17"
                    d="M444.87,232.75a34.671,34.671,0,0,1-.51,6.18c-.09.51-.2,1-.32,1.52a14.916,14.916,0,0,1-.46,1.48,6.942,6.942,0,0,1-.67,1.4,3,3,0,0,1-.51.6,1.479,1.479,0,0,1-.34.21.891.891,0,0,1-.78.02,1.449,1.449,0,0,1-.34-.2,2.66,2.66,0,0,1-.52-.59,7.2,7.2,0,0,1-.69-1.39c-.18-.49-.33-1-.46-1.49s-.23-1-.33-1.51a29.425,29.425,0,0,1-.39-3.08c-.08-1.08-.13-2.07-.13-3.11v-1.55c0-.55.05-1,.09-1.55.08-1,.19-2.06.36-3.08.09-.51.18-1,.3-1.52a15.156,15.156,0,0,1,.43-1.5c.09-.24.18-.48.29-.72a6.416,6.416,0,0,1,.37-.68,2.41,2.41,0,0,1,.51-.59,1.05,1.05,0,0,1,.34-.2.83.83,0,0,1,.38-.07.86.86,0,0,1,.39.09,1.38,1.38,0,0,1,.33.2,3.18,3.18,0,0,1,.52.58,7.2,7.2,0,0,1,.71,1.38c.19.49.35,1,.49,1.48s.25,1,.35,1.51A32,32,0,0,1,444.87,232.75Zm-.15,0q0-1.56-.12-3.09c-.09-1-.22-2.06-.41-3.07-.09-.51-.2-1-.33-1.51a13.281,13.281,0,0,0-.47-1.47,6.767,6.767,0,0,0-.69-1.37,3.05,3.05,0,0,0-.5-.57,1.26,1.26,0,0,0-.32-.21,1.18,1.18,0,0,0-.37-.09.89.89,0,0,0-.36.07,1.159,1.159,0,0,0-.32.2,2.52,2.52,0,0,0-.5.58,6.247,6.247,0,0,0-.35.68,7.117,7.117,0,0,0-.28.72,13.166,13.166,0,0,0-.41,1.49c-.12.5-.21,1-.29,1.52a29.77,29.77,0,0,0-.32,3.07c-.06,1.07-.09,2.06-.09,3.09a28.919,28.919,0,0,0,.11,3.09,28.578,28.578,0,0,0,.37,3.07c.09.51.19,1,.31,1.51a14.876,14.876,0,0,0,.45,1.48,6.29,6.29,0,0,0,.67,1.38,2.679,2.679,0,0,0,.49.57,1.551,1.551,0,0,0,.32.2,1.15,1.15,0,0,0,.36.07,1.12,1.12,0,0,0,.36-.09,1.54,1.54,0,0,0,.31-.21,2.509,2.509,0,0,0,.49-.58,7,7,0,0,0,.65-1.38,13.175,13.175,0,0,0,.44-1.48c.13-.5.23-1,.32-1.52a29.767,29.767,0,0,0,.36-3.06C444.67,234.78,444.72,233.78,444.72,232.75Z"
                    fill="#f4f5f6"
                />
                <path
                    id="Path_18"
                    data-name="Path 18"
                    d="M441.64,244.54a1.14,1.14,0,0,1-.47-.1,1.5,1.5,0,0,1-.42-.24,3.212,3.212,0,0,1-.58-.66,7.24,7.24,0,0,1-.72-1.45c-.17-.46-.32-1-.47-1.52a15.582,15.582,0,0,1-.33-1.54,30.6,30.6,0,0,1-.4-3.11c-.09-1-.13-2.06-.14-3.12v-1.57c0-.46.05-1,.09-1.56.08-1.12.2-2.14.36-3.11.1-.55.2-1.06.31-1.54a15.772,15.772,0,0,1,.44-1.53c.1-.27.2-.52.3-.74A6.138,6.138,0,0,1,440,222a3.193,3.193,0,0,1,.57-.66,1.5,1.5,0,0,1,.42-.24,1.1,1.1,0,0,1,.51-.09,1.24,1.24,0,0,1,.49.11,2,2,0,0,1,.4.25,3.31,3.31,0,0,1,.57.64,7.361,7.361,0,0,1,.75,1.44c.18.46.34.95.5,1.51s.25,1,.36,1.53a32.769,32.769,0,0,1,.57,6.23h0a35.462,35.462,0,0,1-.51,6.23c-.1.55-.21,1.05-.33,1.54a14.491,14.491,0,0,1-.47,1.52,7.67,7.67,0,0,1-.7,1.46,3.189,3.189,0,0,1-.57.66,1.809,1.809,0,0,1-.4.26,1.19,1.19,0,0,1-.51.12Zm-.15-22.87a.59.59,0,0,0-.23.05.79.79,0,0,0-.24.15,2.06,2.06,0,0,0-.43.51,5.425,5.425,0,0,0-.34.64c-.09.21-.18.44-.27.7a12.93,12.93,0,0,0-.4,1.45c-.11.48-.2,1-.28,1.5-.15,1-.25,2-.32,3.05-.06.94-.09,1.94-.09,3.07a27.363,27.363,0,0,0,.11,3.07,29.411,29.411,0,0,0,.37,3c.09.53.19,1,.31,1.49a13.32,13.32,0,0,0,.43,1.45,6.25,6.25,0,0,0,.64,1.32,2,2,0,0,0,.43.5,1.142,1.142,0,0,0,.24.15.711.711,0,0,0,.24,0,.53.53,0,0,0,.24-.06.841.841,0,0,0,.24-.16,2.312,2.312,0,0,0,.44-.51,6.68,6.68,0,0,0,.62-1.33,13.107,13.107,0,0,0,.43-1.44c.12-.47.22-1,.31-1.5q.24-1.41.36-3c.08-1.06.12-2,.12-3.06h0a26.009,26.009,0,0,0-.12-3.06,28.934,28.934,0,0,0-.4-3c-.1-.53-.21-1-.33-1.49a13.6,13.6,0,0,0-.46-1.44,6.368,6.368,0,0,0-.66-1.31,2.1,2.1,0,0,0-.45-.51.879.879,0,0,0-.25-.16.8.8,0,0,0-.24-.07Z"
                    fill="#f4f5f6"
                />
                <path
                    id="Path_19"
                    data-name="Path 19"
                    d="M450.07,232.75a15.531,15.531,0,0,1-.53,3.95,12.749,12.749,0,0,1-1.65,3.62,10.332,10.332,0,0,1-1.26,1.54,8,8,0,0,1-1.54,1.25,7.229,7.229,0,0,1-1.81.83,6.57,6.57,0,0,1-2,.3,6.911,6.911,0,0,1-2-.29,7.94,7.94,0,0,1-1.8-.83,8.57,8.57,0,0,1-1.55-1.25,9.922,9.922,0,0,1-1.26-1.55,12.78,12.78,0,0,1-1.66-3.62,16.2,16.2,0,0,1-.4-2,16.613,16.613,0,0,1-.13-2,14.833,14.833,0,0,1,.14-2,13.36,13.36,0,0,1,.4-2,12.752,12.752,0,0,1,1.65-3.62,10.332,10.332,0,0,1,1.26-1.54,8.839,8.839,0,0,1,1.55-1.25,7.4,7.4,0,0,1,1.81-.82,6.469,6.469,0,0,1,2-.3,6.57,6.57,0,0,1,2,.3,7.5,7.5,0,0,1,1.8.82,9.33,9.33,0,0,1,1.55,1.25,9.92,9.92,0,0,1,1.26,1.55,12.479,12.479,0,0,1,1.64,3.62,15,15,0,0,1,.53,4.04Zm-.17,0a15.162,15.162,0,0,0-.52-3.91,12.481,12.481,0,0,0-1.59-3.6,10.128,10.128,0,0,0-1.23-1.54,8.64,8.64,0,0,0-1.52-1.25,7.22,7.22,0,0,0-1.78-.83,6.761,6.761,0,0,0-1.94-.29,6.651,6.651,0,0,0-1.94.29,7.459,7.459,0,0,0-1.79.83,8.649,8.649,0,0,0-1.51,1.25,10.652,10.652,0,0,0-1.23,1.54,12.88,12.88,0,0,0-1.59,3.6,14.15,14.15,0,0,0-.38,1.94,14.92,14.92,0,0,0,0,3.93,14.155,14.155,0,0,0,.38,1.94,12.7,12.7,0,0,0,1.59,3.59,10.13,10.13,0,0,0,1.23,1.54A8.29,8.29,0,0,0,437.6,243a7.222,7.222,0,0,0,1.78.83,6.389,6.389,0,0,0,3.87,0A7.428,7.428,0,0,0,445,243a9.11,9.11,0,0,0,1.52-1.25,10.721,10.721,0,0,0,1.22-1.54,12.511,12.511,0,0,0,1.6-3.6,15.14,15.14,0,0,0,.56-3.86Z"
                    fill="#f4f5f6"
                />
                <path
                    id="Path_20"
                    data-name="Path 20"
                    d="M441.32,244.54a6.84,6.84,0,0,1-2.05-.31,7.391,7.391,0,0,1-1.88-.86,8.85,8.85,0,0,1-1.61-1.29,10.66,10.66,0,0,1-1.29-1.59,12.8,12.8,0,0,1-1.69-3.71,13.384,13.384,0,0,1-.41-2,15.149,15.149,0,0,1,.41-6.07,12.8,12.8,0,0,1,1.69-3.71,11.252,11.252,0,0,1,1.3-1.59,9.229,9.229,0,0,1,1.6-1.29,7.719,7.719,0,0,1,1.88-.85,6.84,6.84,0,0,1,2.05-.31,7.06,7.06,0,0,1,2,.31,7.59,7.59,0,0,1,1.88.86,8.791,8.791,0,0,1,1.6,1.29,10.709,10.709,0,0,1,1.3,1.59,13,13,0,0,1,1.68,3.7,15.31,15.31,0,0,1,.55,4h0a15.742,15.742,0,0,1-.54,4,13.07,13.07,0,0,1-1.69,3.71,11.931,11.931,0,0,1-1.29,1.59,9.63,9.63,0,0,1-1.6,1.29,7.551,7.551,0,0,1-1.88.86,7.179,7.179,0,0,1-2.01.38Zm0-22.91a6.211,6.211,0,0,0-1.86.28,7.16,7.16,0,0,0-1.71.8,8.449,8.449,0,0,0-1.46,1.2,10.472,10.472,0,0,0-1.19,1.5,12.129,12.129,0,0,0-1.55,3.51,13.888,13.888,0,0,0-.37,1.9,14.391,14.391,0,0,0,0,3.86,14,14,0,0,0,.37,1.89,12.55,12.55,0,0,0,1.56,3.51,9.16,9.16,0,0,0,1.19,1.49,8.159,8.159,0,0,0,1.46,1.21,7.151,7.151,0,0,0,1.71.79,6.44,6.44,0,0,0,1.85.29,6.549,6.549,0,0,0,1.85-.29,6.939,6.939,0,0,0,1.71-.79,8.452,8.452,0,0,0,1.46-1.2,9.81,9.81,0,0,0,1.19-1.5,12,12,0,0,0,1.56-3.51,14.88,14.88,0,0,0,0-7.65,12,12,0,0,0-1.55-3.51,9.939,9.939,0,0,0-1.19-1.5,8.579,8.579,0,0,0-1.47-1.21,6.938,6.938,0,0,0-1.71-.79,6.2,6.2,0,0,0-1.85-.28Z"
                    fill="#f4f5f6"
                />
                <path id="Path_21" data-name="Path 21" d="M431.1,228.2h0Z" fill="#f4f5f6" />
                <path
                    id="Path_22"
                    data-name="Path 22"
                    d="M441.62,228.57l-7.89-.03-2.76-.02-.18-.18v-.14l.01-.29.3-.01,5.26-.04,5.26-.01,7.88.03,2.93.02.01.61-.31.01-5.25.04Z"
                    fill="#f4f5f6"
                />
                <path id="Path_23" data-name="Path 23" d="M434.15,223.78h-.02Z" fill="#f4f5f6" />
                <path
                    id="Path_24"
                    data-name="Path 24"
                    d="M441.62,224.15h-7.77v-.61H449.4v.6h-4.03Z"
                    fill="#f4f5f6"
                />
                <path id="Path_25" data-name="Path 25" d="M430.19,232.62h-.04Z" fill="#f4f5f6" />
                <path
                    id="Path_26"
                    data-name="Path 26"
                    d="M441.62,232.99l-8.58-.03-3.15-.02-.01-.61.31-.01,5.71-.04,5.72-.01,8.57.03,2.98.02.19.19-.02.42-.29.01-5.72.03Z"
                    fill="#f4f5f6"
                />
                <path id="Path_27" data-name="Path 27" d="M431.1,237h0Z" fill="#f4f5f6" />
                <path
                    id="Path_28"
                    data-name="Path 28"
                    d="M441.62,237.41l-7.89-.03-2.76-.02-.18-.18v-.14l.01-.29.3-.01,5.26-.04,5.26-.01,7.88.03,2.93.02.01.61-.31.01-5.25.04Z"
                    fill="#f4f5f6"
                />
                <path
                    id="Path_29"
                    data-name="Path 29"
                    d="M449.46,241.51H434.53v-.08h14.93Z"
                    fill="#f4f5f6"
                />
                <path
                    id="Path_30"
                    data-name="Path 30"
                    d="M449.46,241.81H434.53a.34.34,0,0,1,0-.68h14.93a.34.34,0,1,1,0,.68Z"
                    fill="#f4f5f6"
                />
                <path
                    id="Path_31"
                    data-name="Path 31"
                    d="M373.42,175.66l-18.1,1.91a3.8,3.8,0,0,1-4.19-3.77V152.91a3.8,3.8,0,0,1,3.25-3.75l18.1-2.61a3.8,3.8,0,0,1,4.33,3.76v21.58A3.78,3.78,0,0,1,373.42,175.66Z"
                    fill="#707272"
                />
                <path
                    id="Path_32"
                    data-name="Path 32"
                    d="M358.78,171.78a2.69,2.69,0,1,1,2.68-2.69,2.69,2.69,0,0,1-2.68,2.69Zm0-4.3a1.62,1.62,0,1,0,1.62,1.61A1.62,1.62,0,0,0,358.78,167.48Z"
                    fill="#f4f5f6"
                />
                <path
                    id="Path_33"
                    data-name="Path 33"
                    d="M358.78,163.37a5.73,5.73,0,0,1,5.72,5.72,5.488,5.488,0,0,1,0,.58.4.4,0,0,0,.45.44l.83-.13a.39.39,0,0,0,.33-.36v-.53a7.33,7.33,0,0,0-7.32-7.32,6.583,6.583,0,0,0-.9.06.38.38,0,0,0-.34.45l.13.81a.39.39,0,0,0,.43.32C358.33,163.39,358.55,163.37,358.78,163.37Z"
                    fill="#f4f5f6"
                />
                <path
                    id="Path_34"
                    data-name="Path 34"
                    d="M358.78,159.24a9.86,9.86,0,0,1,9.85,9.85h0a.39.39,0,0,0,.44.39l.82-.13.34-.39a11.47,11.47,0,0,0-11.45-11.32,12.094,12.094,0,0,0-1.61.11.39.39,0,0,0-.33.45l.14.81a.39.39,0,0,0,.43.32,10.4,10.4,0,0,1,1.37-.09Z"
                    fill="#f4f5f6"
                />
                <path
                    id="Path_35"
                    data-name="Path 35"
                    d="M358.78,155.12a14,14,0,0,1,14,13.38.39.39,0,0,0,.45.37l.81-.12a.39.39,0,0,0,.33-.4,15.59,15.59,0,0,0-15.55-14.83,15.857,15.857,0,0,0-2.3.17.4.4,0,0,0-.33.46l.14.8a.39.39,0,0,0,.44.32A13.84,13.84,0,0,1,358.78,155.12Z"
                    fill="#f4f5f6"
                />
                <path
                    id="Path_36"
                    data-name="Path 36"
                    d="M43.2,268.54S79,363,90.31,382.61c13.13,22.8,51.48,8.27,49.57-10.81s-46.71-98-46.71-98Z"
                    fill="#707272"
                />
                <path
                    id="Path_37"
                    data-name="Path 37"
                    d="M91.57,199.76l185.19-77.15L274.42,331.4,85.49,280.91Z"
                    fill="#e4e3e3"
                />
                <ellipse
                    id="Ellipse_1"
                    data-name="Ellipse 1"
                    cx="21.86"
                    cy="107.3"
                    rx="21.86"
                    ry="107.3"
                    transform="translate(255.11 120.31)"
                    fill="#707272"
                />
                <path
                    id="Path_38"
                    data-name="Path 38"
                    d="M278.63,327.57c-2-2-6.28-9.76-10-29.81-3.57-19.37-5.54-44.5-5.54-70.76s2-51.38,5.54-70.75c3.69-20.05,8-27.8,10-29.81,2,2,6.28,9.76,10,29.81,3.56,19.37,5.53,44.5,5.53,70.75s-2,51.39-5.53,70.76C284.91,317.81,280.6,325.56,278.63,327.57Z"
                    fill="#e4e3e3"
                />
                <g id="Group_1" data-name="Group 1" clipPath="url(#clip-path-campaigns1)">
                    <path
                        id="Path_39"
                        data-name="Path 39"
                        d="M306.65,226.21c0,18.23-9.82,33-29.26,33s-35.2-14.78-35.2-33,15.76-33,35.2-33S306.65,208,306.65,226.21Z"
                        fill="#707272"
                    />
                </g>
                <path
                    id="Path_41"
                    data-name="Path 41"
                    d="M55.68,195.28a124.459,124.459,0,0,1,41.71,2.06c-5.35,27.93-4.54,57,1.64,87.19H53.26A14.83,14.83,0,0,1,38.6,272c-2.28-14.45-5.07-38.74-2.1-58.24a21.94,21.94,0,0,1,19.18-18.48Z"
                    fill="#707272"
                />
                <path
                    id="Path_42"
                    data-name="Path 42"
                    d="M83.15,216.49H47.49a3.07,3.07,0,0,1-2.58-4.74.72.72,0,0,1,1-.22.74.74,0,0,1,.22,1A1.59,1.59,0,0,0,47.49,215H83.15a1.6,1.6,0,0,0,1.6-1.59v-2.54a1.6,1.6,0,0,0-1.6-1.6H47.22a.74.74,0,0,1,0-1.48H83.15a3.08,3.08,0,0,1,3.08,3.08v2.54A3.08,3.08,0,0,1,83.15,216.49Z"
                    fill="#e4e3e3"
                />
                <path
                    id="Path_43"
                    data-name="Path 43"
                    d="M80,232.29H44.31a3.08,3.08,0,0,1-2.58-4.75.74.74,0,1,1,1.24.8,1.57,1.57,0,0,0-.06,1.63,1.55,1.55,0,0,0,1.4.84H80a1.6,1.6,0,0,0,1.6-1.6v-2.54a1.6,1.6,0,0,0-1.6-1.59H44a.74.74,0,1,1,0-1.48H80a3.08,3.08,0,0,1,3.08,3.07v2.54A3.08,3.08,0,0,1,80,232.29Z"
                    fill="#e4e3e3"
                />
                <path
                    id="Path_44"
                    data-name="Path 44"
                    d="M123.91,193.93a.756.756,0,0,1-.28-1.46l131.2-53.63a.74.74,0,1,1,.55,1.37l-131.2,53.63A.65.65,0,0,1,123.91,193.93Z"
                    fill="#707272"
                />
                <path
                    id="Path_45"
                    data-name="Path 45"
                    d="M101.11,203.25a.73.73,0,0,1-.68-.46.74.74,0,0,1,.4-1l8.68-3.55a.74.74,0,0,1,1,.41.73.73,0,0,1-.4,1l-8.68,3.55A.87.87,0,0,1,101.11,203.25Z"
                    fill="#c0cfeb"
                />
                <path
                    id="Path_46"
                    data-name="Path 46"
                    d="M83.15,250.78H47.49A3.07,3.07,0,0,1,44.91,246a.74.74,0,0,1,1.24.8,1.6,1.6,0,0,0,1.34,2.47H83.15a1.6,1.6,0,0,0,1.6-1.6v-2.54a1.6,1.6,0,0,0-1.6-1.59H47.22a.74.74,0,1,1,0-1.48H83.15a3.08,3.08,0,0,1,3.08,3.07v2.54a3.08,3.08,0,0,1-3.08,3.11Z"
                    fill="#e4e3e3"
                />
                <path
                    id="Path_47"
                    data-name="Path 47"
                    d="M86.49,271.32H50.83a3.08,3.08,0,0,1-2.58-4.75.74.74,0,0,1,1-.22.75.75,0,0,1,.22,1,1.54,1.54,0,0,0-.06,1.63,1.56,1.56,0,0,0,1.4.83H86.49a1.6,1.6,0,0,0,1.6-1.6v-2.53a1.6,1.6,0,0,0-1.6-1.6H50.56a.74.74,0,0,1,0-1.48H86.49a3.08,3.08,0,0,1,3.08,3.08v2.53a3.08,3.08,0,0,1-3.08,3.11Z"
                    fill="#e4e3e3"
                />
                <path
                    id="Path_48"
                    data-name="Path 48"
                    d="M118.35,383a23.121,23.121,0,0,1-4.93-.54,22.79,22.79,0,0,1-16.07-13L68.86,306.1a.74.74,0,0,1,.37-1,.75.75,0,0,1,1,.37L98.7,368.8a21.33,21.33,0,0,0,15,12.18,20.87,20.87,0,0,0,18.37-4.7l.82-.73a.74.74,0,0,1,1,.05.72.72,0,0,1-.05,1c-.28.26-.55.51-.83.75A22.27,22.27,0,0,1,118.35,383Z"
                    fill="#e4e3e3"
                />
                <path
                    id="Path_49"
                    data-name="Path 49"
                    d="M65.32,297.18a.75.75,0,0,1-.68-.44l-3.17-7.06a.74.74,0,1,1,1.34-.61L66,296.13a.74.74,0,0,1-.37,1A.66.66,0,0,1,65.32,297.18Z"
                    fill="#fff"
                />
                <path
                    id="Path_50"
                    data-name="Path 50"
                    d="M301.44,225.08a.74.74,0,0,1-.74-.69c-.64-9-3.76-15.69-9.27-19.92-9.53-7.32-23.11-4.8-23.25-4.78a.73.73,0,0,1-.86-.58.74.74,0,0,1,.59-.87c.58-.11,14.37-2.66,24.42,5.06,5.86,4.5,9.18,11.56,9.85,21a.74.74,0,0,1-.69.78Z"
                    fill="#e4e3e3"
                />
                <path
                    id="Path_51"
                    data-name="Path 51"
                    d="M66.44,384.66s-.49,2.79-.73,5.55a1.9,1.9,0,0,0,1.82,2.07c3.68.15,11.56.45,14.38.32a.62.62,0,0,0,.55-.85h0a1.39,1.39,0,0,0-.71-.74l-13-6Z"
                    fill="#5c5c5c"
                />
                <path
                    id="Path_52"
                    data-name="Path 52"
                    d="M66,383l.39,3.35s2.28,1.06,3.84-.14l-1-3.72Z"
                    fill="#d3d3d3"
                />
                <path
                    id="Path_53"
                    data-name="Path 53"
                    d="M66.33,320.2c-.17.87-.91,43.54,3.65,62.66a.67.67,0,0,1-.38.76,11.4,11.4,0,0,1-3.72.75,1.12,1.12,0,0,1-1.11-.8c-1.3-4.44-7.55-26.66-8.36-47.9-.91-23.72,0-.81,0-.81l.53-14.66Z"
                    fill="#707272"
                />
                <path
                    id="Path_54"
                    data-name="Path 54"
                    d="M51.47,386.11a20.29,20.29,0,0,0-1.54,5.16,1.16,1.16,0,0,0,1,1.39c1.22.12,3.09.28,4.59.27a1.17,1.17,0,0,0,1.15-1.3,9.81,9.81,0,0,0-2.29-5.36Z"
                    fill="#5c5c5c"
                />
                <path
                    id="Path_55"
                    data-name="Path 55"
                    d="M50.78,381.82l.93,5.05a3.29,3.29,0,0,0,2.73.19L54,381.62Z"
                    fill="#d3d3d3"
                />
                <path
                    id="Path_56"
                    data-name="Path 56"
                    d="M48.28,321.51c-3.73,22.51.81,55.64,2.34,62.12a1,1,0,0,0,.82.77,6.71,6.71,0,0,0,2.92-.11.93.93,0,0,0,.66-.86c.21-5.24-.16-37.42,3.31-50.14a2.87,2.87,0,0,1,1.12-1.59l6.74-4.8.52-7.75Z"
                    fill="#707272"
                />
                <path
                    id="Path_57"
                    data-name="Path 57"
                    d="M72.05,278.85c-11.18-.18-23.51,2.94-23.51,2.94-3.33,19.44-.58,41.87-.58,41.87,8.65,2.27,18.41-1.39,18.41-1.39,1.44-8.13,8.15-31,8.15-31a36.8,36.8,0,0,0,13.67-4.34,23.91,23.91,0,0,1-4.74-9.9,37.631,37.631,0,0,1-11.4,1.82Z"
                    fill="#5c5c5c"
                />
                <path
                    id="Path_58"
                    data-name="Path 58"
                    d="M105.07,262.39a2.14,2.14,0,0,0-2.49-.7c-1.64,6.95-10.27,12.58-19.13,15.34a23.91,23.91,0,0,0,4.74,9.9C102.9,278.67,105.07,262.39,105.07,262.39Z"
                    fill="#d3d3d3"
                />
                <path
                    id="Path_59"
                    data-name="Path 59"
                    d="M104.8,262.72c.12.37-1.21.93-2-.57,0-.71-.47-3.78,0-4.36a57.25,57.25,0,0,1,6.7-7,1.06,1.06,0,0,1,.13,1.46l-2.14,2.24a10.8,10.8,0,0,1,1.25,2.47,3,3,0,0,1-.3,2.21,26.248,26.248,0,0,1-1.38,2.25,3,3,0,0,1-2.26,1.3Z"
                    fill="#d3d3d3"
                />
                <path
                    id="Path_60"
                    data-name="Path 60"
                    d="M106.52,255.93l-2,1.65-.62,1.65,1.74,1.93a23,23,0,0,1,1-4C107.15,256.48,106.92,256.13,106.52,255.93Z"
                    fill="#e4e3e3"
                />
                <path
                    id="Path_61"
                    data-name="Path 61"
                    d="M52.83,292.61l-4.29-10.82s-10.44,7.09-12.16,16.09a24.73,24.73,0,0,1,9.08,2.43C46.23,296.06,52.83,292.61,52.83,292.61Z"
                    fill="#5c5c5c"
                />
                <path
                    id="Path_62"
                    data-name="Path 62"
                    d="M62,302.8s-11.72,6.62-15.68,1a4.43,4.43,0,0,1-.81-3.46,24.73,24.73,0,0,0-9.08-2.43,12.37,12.37,0,0,0,1.35,8.51c6.93,12.67,25.77-.7,25.77-.7Z"
                    fill="#d3d3d3"
                />
                <path
                    id="Path_63"
                    data-name="Path 63"
                    d="M60.54,311.18l6.4-22.34a.64.64,0,0,1,.55-.47c1.16-.14,4.52-.52,8.4-.85a96.384,96.384,0,0,1,13.34-.44.65.65,0,0,1,.53.94c-1.84,3.73-9.43,18.9-13.22,23.87a.65.65,0,0,1-.53.26L61.16,312a.65.65,0,0,1-.62-.82Z"
                    fill="#e4e3e3"
                />
                <path
                    id="Path_64"
                    data-name="Path 64"
                    d="M76.55,285.59l4.64-.78a.34.34,0,0,1,.37.44l-.46,1.39a.31.31,0,0,1-.27.22l-4.46.6a.33.33,0,0,1-.37-.41l.28-1.21a.33.33,0,0,1,.27-.25Z"
                    fill="#707272"
                />
                <path
                    id="Path_65"
                    data-name="Path 65"
                    d="M73.08,289.51l.38-2a.46.46,0,0,1,.41-.37l9.36-.95a.47.47,0,0,1,.5.57l-.6,2.48a.46.46,0,0,1-.42.35l-9.16.46a.45.45,0,0,1-.47-.54Z"
                    fill="#707272"
                />
                <path
                    id="Path_66"
                    data-name="Path 66"
                    d="M62.09,305.78s-.89-3.66-.53-5,1-3,3.37-2.6A33.407,33.407,0,0,1,70,299.57a1.32,1.32,0,0,1-1.9,1.14l1.1.85s-.16,1.36-1.65.63l1,1.12a1.18,1.18,0,0,1-1.74.4C65.72,303,65.2,306.22,62.09,305.78Z"
                    fill="#d3d3d3"
                />
                <path
                    id="Path_67"
                    data-name="Path 67"
                    d="M60.73,269.78l-3.56,10.28a.37.37,0,0,0,.17.45,8.34,8.34,0,0,0,8.67-.58,1.76,1.76,0,0,0,.81-1.25l1.15-8.9Z"
                    fill="#d3d3d3"
                />
                <path
                    id="Path_68"
                    data-name="Path 68"
                    d="M68.47,260.25s3.9,7.35,3.4,10.32-5.34,2.76-9.57.35L59.35,263Z"
                    fill="#d3d3d3"
                />
                <path
                    id="Path_69"
                    data-name="Path 69"
                    d="M62.49,267a.3.3,0,0,0,.13-.44,7.9,7.9,0,0,1-1.08-2.31s-.71-.45,1.78-1,6.2-1.52,7-3.64-1.24-7.26-4.43-3.12a.31.31,0,0,1-.48,0,3.13,3.13,0,0,0-3.81-1.24,2.88,2.88,0,0,0-2,2.77.31.31,0,0,1-.36.31,3.5,3.5,0,0,0-4.08,1.9c-.83,1.83-.29,2.87.2,3.37a.31.31,0,0,1-.12.51A1.81,1.81,0,0,0,54,266.16c.11,1.92,2.26,1.75,2.87,1.67a.3.3,0,0,1,.27.09c.52.58,2.89,3.05,5.44,3.34a.3.3,0,0,0,.32-.4l-1.1-3.22a.31.31,0,0,1,.16-.38Z"
                    fill="#5c5c5c"
                />
                <path
                    id="Path_70"
                    data-name="Path 70"
                    d="M62.94,267.48s-2.79-1.91-3-.3,2.51,2.32,3.85,1.72Z"
                    fill="#d3d3d3"
                />
                <path
                    id="Path_71"
                    data-name="Path 71"
                    d="M368.12,202.58l-41.35-2.15a5.62,5.62,0,0,0-6,5.86l1.07,38a5.56,5.56,0,0,0,6,5.32l39.27-1.94a5.39,5.39,0,0,0,4.93-5.29l1-34.06A5.44,5.44,0,0,0,368.12,202.58Z"
                    fill="#e4e3e3"
                />
                <path
                    id="Path_72"
                    data-name="Path 72"
                    d="M346.2,240.48A15.58,15.58,0,1,1,361.77,224.9,15.58,15.58,0,0,1,346.2,240.48Zm0-28.54a13,13,0,1,0,13,13A13,13,0,0,0,346.2,211.94Z"
                    fill="#707272"
                />
                <path
                    id="Path_73"
                    data-name="Path 73"
                    d="M341.32,230.9V219.7a1.55,1.55,0,0,1,2.33-1.33l9.44,5.6a1.55,1.55,0,0,1,0,2.66l-9.44,5.6a1.54,1.54,0,0,1-2.33-1.33Z"
                    fill="#707272"
                    fillRule="evenodd"
                />
                <path
                    id="Path_74"
                    data-name="Path 74"
                    d="M444.3,148.39l-37.72-2a5.12,5.12,0,0,0-5.45,5.35l1,34.68a5.08,5.08,0,0,0,5.44,4.86l35.82-1.77a4.92,4.92,0,0,0,4.5-4.82l.88-31.08a5,5,0,0,0-4.47-5.22Z"
                    fill="#e4e3e3"
                />
                <path
                    id="Path_75"
                    data-name="Path 75"
                    d="M437.94,182.19H411.5a3.69,3.69,0,0,1-3.69-3.69V159.24a3.69,3.69,0,0,1,3.69-3.69h26.44a3.69,3.69,0,0,1,3.69,3.69V178.5a3.69,3.69,0,0,1-3.69,3.69ZM411.5,158.11a1.14,1.14,0,0,0-1.13,1.13V178.5a1.13,1.13,0,0,0,1.13,1.13h26.44a1.13,1.13,0,0,0,1.13-1.13V159.24a1.14,1.14,0,0,0-1.13-1.13Z"
                    fill="#707272"
                />
                <path
                    id="Path_76"
                    data-name="Path 76"
                    d="M424.73,169.6a1.25,1.25,0,0,1-.74-.23l-14.81-10.5a1.28,1.28,0,0,1,1.48-2.09l14.07,10,13.88-9.86a1.28,1.28,0,1,1,1.48,2.09L425.47,169.4a1.25,1.25,0,0,1-.74.2Z"
                    fill="#707272"
                />
                <path
                    id="Path_77"
                    data-name="Path 77"
                    d="M410.12,181.6l-.38-2.53.18,1.27-1.07-.7c.28-.45,5.61-9.78,9.13-15.95a1.283,1.283,0,0,1,2.23,1.27l-4.58,8-3.15,5.5C410.74,181.51,410.74,181.51,410.12,181.6Z"
                    fill="#707272"
                />
                <path
                    id="Path_78"
                    data-name="Path 78"
                    d="M439.25,181.6c-.62-.09-.62-.09-2.36-3.13l-3.15-5.5-4.58-8a1.283,1.283,0,1,1,2.23-1.27c3.52,6.17,8.85,15.5,9.13,15.95l-1.07.7Z"
                    fill="#707272"
                />
                <path
                    id="Path_79"
                    data-name="Path 79"
                    d="M437.35,261.92c-4.24,2.82-8.13,9.18-.36,19.43,7,9.18,14.13,15.18,13.07,20.84s7.21,19.89,24,10.59c16.6-9.18,13.42-26.84-6.71-33.55C455.59,275.3,452.87,251.57,437.35,261.92Z"
                    fill="#565656"
                />
                <path
                    id="Path_80"
                    data-name="Path 80"
                    d="M423.86,297.24s6.58,7.87,13.2,1.48l2.56,5.93s-9.67,7.49-17-4.29Z"
                    fill="#e4e3e3"
                />
                <path
                    id="Path_81"
                    data-name="Path 81"
                    d="M440.11,282.61a12.49,12.49,0,0,1,12.19,3.11,3.15,3.15,0,0,1,.91,3.12c-1.36,5-4.95,19.77-1.8,26.16,0,0-5.54,6.11-11.58,1.19,0,0-2.71-11.81-6.26-17-2.45-3.58,3.91-13.44,5.52-15.82A1.9,1.9,0,0,1,440.11,282.61Z"
                    fill="#707272"
                />
                <path
                    id="Path_82"
                    data-name="Path 82"
                    d="M442.93,273.08s3.69,7.22,4.33,10.53a5.75,5.75,0,0,1-5.86-.16s-1.3-7.52-1.91-8.82Z"
                    fill="#e4e3e3"
                />
                <path
                    id="Path_83"
                    data-name="Path 83"
                    d="M434.68,269s-.69,12.12,4.41,12.29,5.6-8.45,5.6-8.45-1.51-5.45-3.94-6.08S434.68,269,434.68,269Z"
                    fill="#e4e3e3"
                />
                <path
                    id="Path_84"
                    data-name="Path 84"
                    d="M436.18,265.27s-2.33,2.39-1.93,3.87,5.4-.07,6.63-.77l4.13,4.4s-.13-6.52-3-8.52S436.18,265.27,436.18,265.27Z"
                    fill="#565656"
                />
                <path
                    id="Path_85"
                    data-name="Path 85"
                    d="M443.14,273s.62-4.16,2.66-3-.15,4.39-1.74,4.19Z"
                    fill="#e4e3e3"
                />
                <path
                    id="Path_86"
                    data-name="Path 86"
                    d="M432.78,341c0,.53-.92,4.79-1.43,7.05a3.72,3.72,0,0,0,.4,2.69l17.74,29.86a4,4,0,0,1,.47,1.16,25.84,25.84,0,0,1,.65,8l-.92,1.91,4-.9.86-10.66-1.12-.69a5.33,5.33,0,0,1-2.16-2.53c-1.61-3.9-5.59-13.82-6.62-18.65-1.17-5.5-7.27-10-7.27-10l1.07-2.53Z"
                    fill="#d3d2d1"
                />
                <path
                    id="Path_87"
                    data-name="Path 87"
                    d="M458.07,387.05s-2.14-3.4-2.4-4.72-.95-3.66-2.27-3a76.342,76.342,0,0,0-.41,8.94c.21,2.51-2.84,2.43-2.84,2.43a2.9,2.9,0,0,0-1.46.73.72.72,0,0,0,.48,1.21,17.459,17.459,0,0,0,4.25-.24,1.3,1.3,0,0,0,1.05-1.21l.44-7.39,2.71,3.41Z"
                    fill="#707272"
                />
                <path
                    id="Path_88"
                    data-name="Path 88"
                    d="M443.38,345.74s.62,26.72.71,30.51-.6,12.2-4.4,14.72l1.65,1.22a22.151,22.151,0,0,0,4.09-5.14,30.511,30.511,0,0,1,3-4.49l-.76-1.55a3.39,3.39,0,0,1-.27-2.17c.82-4,3.56-17.21,4.31-20.06a12.83,12.83,0,0,0-2.13-11.62C446.47,343.4,443.38,345.74,443.38,345.74Z"
                    fill="#e4e3e3"
                />
                <path
                    id="Path_89"
                    data-name="Path 89"
                    d="M446.89,393.4a.25.25,0,0,0,.24-.21,33.729,33.729,0,0,1,1.08-5.38c.49-1.06,2.11-5.52,0-5.89,0,0-5.24,7.65-6.25,8.69a2,2,0,0,1-2.27.36A3.811,3.811,0,0,0,438,392.2a.7.7,0,0,0,.54,1.12h3.53a2.47,2.47,0,0,0,2.33-1.57c.62-1.55,1.69-3.82,2.94-4.83l-.65,6.19a.24.24,0,0,0,.23.26Z"
                    fill="#707272"
                />
                <path
                    id="Path_90"
                    data-name="Path 90"
                    d="M439,313.31s.8,2.53-.42,6.71L431,346.49a.82.82,0,0,0,.51,1,50.71,50.71,0,0,0,19.9,2.41,1.15,1.15,0,0,0,1.05-.9l3.43-15.43c1.79-8.06,3.53-15.73-5.28-21A17,17,0,0,1,439,313.31Z"
                    fill="#565656"
                />
                <path
                    id="Path_91"
                    data-name="Path 91"
                    d="M448.14,289.34s1.53,21.8-12.56,10.85c0,0-2.09-2-3.59-2.2-.81-.13-1.72-.22-2.39-.27a.3.3,0,0,0-.2.55,4.06,4.06,0,0,0,1.67.76c1.06.11-.07.89,1.09,2.92s2.81,1.27,2.81,1.27,16.14,16.54,19.37-12.3C454.45,285.56,449.29,285.84,448.14,289.34Z"
                    fill="#e4e3e3"
                />
                <path
                    id="Path_92"
                    data-name="Path 92"
                    d="M419.54,291.81l4.79-.63a1.54,1.54,0,0,1,1.52.86l3.15,7c.59,1,.17,2.42-.79,2.58l-3.85.5a.93.93,0,0,1-.95-.59L419.14,293C418.9,292.5,419.1,291.89,419.54,291.81Z"
                    fill="#707272"
                />
                <path
                    id="Path_93"
                    data-name="Path 93"
                    d="M422.81,301.22s-3.22-4.69-2.85-5.27,3.2-1.41,3.86-1.6S425.74,298.41,422.81,301.22Z"
                    fill="#e4e3e3"
                />
                <path
                    id="Path_94"
                    data-name="Path 94"
                    d="M471.92,309.13a.18.18,0,0,1-.16-.12.16.16,0,0,1,.1-.22,11.74,11.74,0,0,0,5.89-16.63c-2.3-4.08-6.69-6.37-10.94-8.57l-.51-.27c-4.6-2.39-9.07-5.24-10.72-9.86a.179.179,0,0,1,.34-.11c1.6,4.49,6,7.3,10.54,9.66l.51.26c4.3,2.23,8.74,4.54,11.08,8.72A12.07,12.07,0,0,1,472,309.11Z"
                    fill="#e4e3e3"
                    opacity="0.3"
                />
                <path
                    id="Path_95"
                    data-name="Path 95"
                    d="M467.18,307.5a.18.18,0,0,1-.05-.35,12.2,12.2,0,0,0,8-6,8.38,8.38,0,0,0-1.21-9.48.18.18,0,0,1,0-.25.17.17,0,0,1,.25,0,8.7,8.7,0,0,1,1.28,9.89,12.55,12.55,0,0,1-8.21,6.16Z"
                    fill="#e4e3e3"
                    opacity="0.3"
                />
                <path
                    id="Path_96"
                    data-name="Path 96"
                    d="M398.86,265l-22.23-1.15a3,3,0,0,0-3.21,3.15l.57,20.44a3,3,0,0,0,3.21,2.87l21.11-1.05a2.91,2.91,0,0,0,2.66-2.84l.51-18.32A2.92,2.92,0,0,0,398.86,265Z"
                    fill="#707272"
                />
                <path
                    id="Path_97"
                    data-name="Path 97"
                    d="M396,267.1H380.23c-1.45,0-3.39,1.24-3.39,2.75v8.74c0,1.44,2,2.3,3.39,2.3H391c.38,0,.58,1,.58,1.4v1.29c0,.53.53.73.79.81a1.07,1.07,0,0,0,.6.22.76.76,0,0,0,.57-.25l2.35-2.75c1.47,0,2.09-1.58,2.09-3v-8.74C398,268.42,397.38,267.1,396,267.1Zm.59,11.49c0,.67,0,1.31-.59,1.31h-.59l-2.76,2.89v-1.9c0-.37.21-1-.16-1H380.23c-.67,0-1.91-.64-1.91-1.31V270.2c0-1,1.24-1.14,1.91-1.14H396c.67,0,.59.11.59.78Z"
                    fill="#e4e3e3"
                />
                <path
                    id="Path_98"
                    data-name="Path 98"
                    d="M395.07,271.53a.49.49,0,0,1-.5.49H380.29a.49.49,0,0,1-.49-.49h0a.49.49,0,0,1,.49-.49h14.28a.49.49,0,0,1,.5.49Z"
                    fill="#fff"
                />
                <path
                    id="Path_99"
                    data-name="Path 99"
                    d="M395.07,274a.5.5,0,0,1-.5.5H380.29a.5.5,0,0,1-.49-.5h0a.49.49,0,0,1,.49-.49h14.28a.49.49,0,0,1,.5.49Z"
                    fill="#fff"
                />
                <path
                    id="Path_100"
                    data-name="Path 100"
                    d="M395.07,277.44a.49.49,0,0,1-.5.49H380.29a.49.49,0,0,1-.49-.49h0a.49.49,0,0,1,.49-.49h14.28a.49.49,0,0,1,.5.49Z"
                    fill="#fff"
                />
                <path
                    id="Path_101"
                    data-name="Path 101"
                    d="M320.09,252.64h1.47a1,1,0,0,1,0,2H320.1a1,1,0,0,1-.01-2Z"
                    fill="#c0cfeb"
                />
                <path
                    id="Path_102"
                    data-name="Path 102"
                    d="M354.31,250.23l1.45-.23a.987.987,0,0,1,.31,1.95l-1.47.22a1,1,0,1,1-.29-1.94Z"
                    fill="#d3d2d1"
                />
                <path
                    id="Path_103"
                    data-name="Path 103"
                    d="M359.14,249.44l1.45-.26a1,1,0,0,1,.86,1.67.9.9,0,0,1-.5.26l-1.47.27a1,1,0,0,1-.34-1.94Z"
                    fill="#d3d2d1"
                />
                <path
                    id="Path_104"
                    data-name="Path 104"
                    d="M364,248.52l1.44-.3a1,1,0,1,1,.42,1.92l-1.46.31a1,1,0,0,1-.4-1.93Z"
                    fill="#d3d2d1"
                />
                <path
                    id="Path_105"
                    data-name="Path 105"
                    d="M368.73,247.46l1.42-.35a1,1,0,0,1,.93,1.66,1.12,1.12,0,0,1-.44.25l-1.45.35a1,1,0,1,1-.46-1.91Z"
                    fill="#d3d2d1"
                />
                <path
                    id="Path_106"
                    data-name="Path 106"
                    d="M334.8,252.2l1.47-.09a1,1,0,0,1,.75,1.69.93.93,0,0,1-.62.27l-1.48.09a1,1,0,0,1-.12-2Z"
                    fill="#d3d2d1"
                />
                <path
                    id="Path_107"
                    data-name="Path 107"
                    d="M329.9,252.43l1.47-.06a.986.986,0,0,1,.09,1.97l-1.48.06a1,1,0,0,1-.08-2Z"
                    fill="#d3d2d1"
                />
                <path
                    id="Path_108"
                    data-name="Path 108"
                    d="M325,252.58h1.47a1.011,1.011,0,0,1,.7,1.73.93.93,0,0,1-.66.28h-1.48a1,1,0,0,1,0-2Z"
                    fill="#d3d2d1"
                />
                <path
                    id="Path_109"
                    data-name="Path 109"
                    d="M339.7,251.87l1.46-.12a.984.984,0,1,1,.17,1.96l-1.48.12a1,1,0,0,1-.15-2Z"
                    fill="#d3d2d1"
                />
                <path
                    id="Path_110"
                    data-name="Path 110"
                    d="M349.45,250.89l1.46-.19a.989.989,0,0,1,.26,1.96l-1.48.18a.982.982,0,1,1-.24-1.95Z"
                    fill="#d3d2d1"
                />
                <path
                    id="Path_111"
                    data-name="Path 111"
                    d="M344.58,251.43l1.46-.15a.984.984,0,0,1,.79,1.69.93.93,0,0,1-.58.27l-1.47.15a.985.985,0,1,1-.2-1.96Z"
                    fill="#d3d2d1"
                />
                <path
                    id="Path_112"
                    data-name="Path 112"
                    d="M300.44,252.24l1.48.06a.971.971,0,0,1-.08,1.94l-1.48-.06a1,1,0,1,1,.08-2Z"
                    fill="#d3d2d1"
                />
                <path
                    id="Path_113"
                    data-name="Path 113"
                    d="M305.35,252.43h1.48a.975.975,0,1,1-.06,1.95h-1.48a1,1,0,1,1,.06-2Z"
                    fill="#d3d2d1"
                />
                <path
                    id="Path_114"
                    data-name="Path 114"
                    d="M285.7,251.45l1.48.09a1,1,0,0,1,.92,1.05.93.93,0,0,1-.29.64,1,1,0,0,1-.75.28l-1.48-.09a1,1,0,1,1,.12-2Z"
                    fill="#d3d2d1"
                />
                <path
                    id="Path_115"
                    data-name="Path 115"
                    d="M290.62,251.75l1.47.08a1,1,0,0,1,.93,1,.93.93,0,0,1-.3.65,1,1,0,0,1-.74.28l-1.47-.09a1,1,0,0,1,.11-2Z"
                    fill="#d3d2d1"
                />
                <path
                    id="Path_116"
                    data-name="Path 116"
                    d="M295.53,252l1.47.08a.966.966,0,1,1-.09,1.93l-1.48-.07a1,1,0,0,1,.1-2Z"
                    fill="#d3d2d1"
                />
                <path
                    id="Path_117"
                    data-name="Path 117"
                    d="M315.18,252.63h1.47a1,1,0,0,1,.7,1.71.93.93,0,0,1-.69.27h-1.48a.99.99,0,1,1,0-1.98Z"
                    fill="#d3d2d1"
                />
                <path
                    id="Path_118"
                    data-name="Path 118"
                    d="M310.27,252.56h1.47a1,1,0,0,1,0,2h-1.48a1,1,0,0,1,0-2Z"
                    fill="#d3d2d1"
                />
                <path
                    id="Path_119"
                    data-name="Path 119"
                    d="M368.36,143.46l1.38.58a1,1,0,0,1,.51,1.3.87.87,0,0,1-.22.31,1,1,0,0,1-1.08.2l-1.34-.56a1,1,0,0,1,.75-1.83Z"
                    fill="#d3d2d1"
                />
                <path
                    id="Path_120"
                    data-name="Path 120"
                    d="M363.66,141.75l.18.06c.42.13.83.27,1.24.42a.982.982,0,0,1-.63,1.86l-1.2-.41-.18-.06a1,1,0,0,1,.61-1.87Z"
                    fill="#d3d2d1"
                />
                <path
                    id="Path_121"
                    data-name="Path 121"
                    d="M358.85,140.39l1.45.37a.982.982,0,1,1-.5,1.9l-1.41-.36a1,1,0,1,1,.46-1.91Z"
                    fill="#d3d2d1"
                />
                <path
                    id="Path_122"
                    data-name="Path 122"
                    d="M376,148.24a1,1,0,0,1,1.35-.35c.43.25.85.5,1.28.77a1,1,0,0,1,.32,1.35.7.7,0,0,1-.15.19,1,1,0,0,1-1.2.13l-1.25-.75A1,1,0,0,1,376,148.24Z"
                    fill="#d3d2d1"
                />
                <path
                    id="Path_123"
                    data-name="Path 123"
                    d="M378.1,244.74l1.37-.49a.966.966,0,0,1,1.03,1.59.91.91,0,0,1-.35.22l-1.4.5a1,1,0,1,1-.65-1.86Z"
                    fill="#d3d2d1"
                />
                <path
                    id="Path_124"
                    data-name="Path 124"
                    d="M372.91,145.51l1.34.68a1,1,0,0,1,.41,1.33.94.94,0,0,1-.18.25,1,1,0,0,1-1.14.17l-1.3-.66a1,1,0,0,1,.87-1.77Z"
                    fill="#d3d2d1"
                />
                <path
                    id="Path_125"
                    data-name="Path 125"
                    d="M373.45,246.21l1.41-.41a1,1,0,0,1,1,1.65,1.05,1.05,0,0,1-.4.23l-1.43.43a1,1,0,0,1-.55-1.9Z"
                    fill="#d3d2d1"
                />
                <path
                    id="Path_126"
                    data-name="Path 126"
                    d="M344,138.42l1.49.05a.971.971,0,1,1-.09,1.94l-1.46-.06a1,1,0,0,1,.06-2Z"
                    fill="#d3d2d1"
                />
                <path
                    id="Path_127"
                    data-name="Path 127"
                    d="M334.06,138.83l1.49-.15a.989.989,0,0,1,.17,1.97l-1.45.14a.986.986,0,0,1-.21-1.96Z"
                    fill="#d3d2d1"
                />
                <path
                    id="Path_128"
                    data-name="Path 128"
                    d="M339,138.45h1.5a1.005,1.005,0,0,1,.03,2.01h-1.45a1.007,1.007,0,0,1-.08-2.01Z"
                    fill="#d3d2d1"
                />
                <path
                    id="Path_129"
                    data-name="Path 129"
                    d="M329.12,139.56l1.47-.26a.988.988,0,0,1,.32,1.95l-1.44.25a.986.986,0,1,1-.35-1.94Z"
                    fill="#d3d2d1"
                />
                <path
                    id="Path_130"
                    data-name="Path 130"
                    d="M349,138.72l1.49.16a1,1,0,0,1,.86,1.1.93.93,0,0,1-.29.59,1,1,0,0,1-.8.27l-1.45-.16a.985.985,0,0,1,.19-1.96Z"
                    fill="#d3d2d1"
                />
                <path
                    id="Path_131"
                    data-name="Path 131"
                    d="M380.15,150.82a1,1,0,0,1,1.37-.26l1.23.86a1,1,0,0,1,.23,1.37l-.12.14a1,1,0,0,1-1.26.09l-1.19-.84A1,1,0,0,1,380.15,150.82Z"
                    fill="#d3d2d1"
                />
                <path
                    id="Path_132"
                    data-name="Path 132"
                    d="M354,139.38l1.48.26a.987.987,0,0,1-.37,1.94l-1.44-.26a1,1,0,0,1,.33-1.94Z"
                    fill="#d3d2d1"
                />
                <path
                    id="Path_133"
                    data-name="Path 133"
                    d="M399.76,231.57a1,1,0,1,1,1.45,1.33c-.34.37-.69.74-1,1.1h0a.979.979,0,0,1-1.38-1.39C399.11,232.27,399.44,231.92,399.76,231.57Z"
                    fill="#d3d2d1"
                />
                <path
                    id="Path_134"
                    data-name="Path 134"
                    d="M410.36,197.84a1,1,0,0,1-1.11-.84c-.07-.48-.14-1-.22-1.44a1.012,1.012,0,0,1,2-.31c.08.49.15,1,.22,1.48a.977.977,0,0,1-.89,1.11Z"
                    fill="#d3d2d1"
                />
                <path
                    id="Path_135"
                    data-name="Path 135"
                    d="M410.72,202.76a1,1,0,0,1-1-.94c0-.49-.05-1-.09-1.45a1,1,0,1,1,2-.15l.09,1.51a.991.991,0,0,1-1,1.03Z"
                    fill="#d3d2d1"
                />
                <path
                    id="Path_136"
                    data-name="Path 136"
                    d="M409.57,193a1,1,0,0,1-1.18-.75c-.1-.47-.22-.94-.33-1.41a.985.985,0,1,1,1.91-.48c.12.49.23,1,.34,1.46a.971.971,0,0,1-.74,1.18Z"
                    fill="#d3d2d1"
                />
                <path
                    id="Path_137"
                    data-name="Path 137"
                    d="M410.62,207.68a1,1,0,0,1-.94-1,13.767,13.767,0,0,1,.05-1.45,1,1,0,1,1,2,0c0,.5,0,1-.05,1.51a1,1,0,0,1-.3.65.94.94,0,0,1-.76.29Z"
                    fill="#d3d2d1"
                />
                <path
                    id="Path_138"
                    data-name="Path 138"
                    d="M410,212.57a1,1,0,0,1-.82-1.12c.07-.48.14-.95.2-1.44a1.007,1.007,0,1,1,2,.24c-.06.5-.13,1-.2,1.5a.993.993,0,0,1-1.18.82Z"
                    fill="#d3d2d1"
                />
                <path
                    id="Path_139"
                    data-name="Path 139"
                    d="M399.94,170.48a1,1,0,0,1-1.37-.25c-.27-.4-.55-.8-.84-1.19a1,1,0,1,1,1.6-1.16l.86,1.23a1,1,0,0,1-.12,1.26.69.69,0,0,1-.13.11Z"
                    fill="#d3d2d1"
                />
                <path
                    id="Path_140"
                    data-name="Path 140"
                    d="M402.54,174.66a1,1,0,0,1-1.35-.34c-.25-.42-.5-.84-.76-1.25a1,1,0,1,1,1.68-1l.78,1.28a1,1,0,0,1-.17,1.21.86.86,0,0,1-.18.1Z"
                    fill="#d3d2d1"
                />
                <path
                    id="Path_141"
                    data-name="Path 141"
                    d="M408.37,188.2a1,1,0,0,1-1.23-.65l-.45-1.38a1,1,0,1,1,1.86-.63c.16.47.32,1,.47,1.43a.986.986,0,0,1-.65,1.23Z"
                    fill="#d3d2d1"
                />
                <path
                    id="Path_142"
                    data-name="Path 142"
                    d="M382.64,243l1.34-.58a.985.985,0,0,1,.8,1.8l-1.38.59a1,1,0,1,1-.76-1.81Z"
                    fill="#d3d2d1"
                />
                <path
                    id="Path_143"
                    data-name="Path 143"
                    d="M404.83,179a1,1,0,0,1-1.32-.44l-.66-1.3a1,1,0,1,1,1.74-.91c.23.44.46.88.68,1.33a1,1,0,0,1-.19,1.15A.85.85,0,0,1,404.83,179Z"
                    fill="#d3d2d1"
                />
                <path
                    id="Path_144"
                    data-name="Path 144"
                    d="M406.79,183.54a1,1,0,0,1-1.29-.54c-.13-.34-.27-.67-.41-1l-.15-.34a1,1,0,0,1,1.81-.79l.15.36c.15.34.29.68.43,1a1.1,1.1,0,0,1-.54,1.31Z"
                    fill="#d3d2d1"
                />
                <path
                    id="Path_145"
                    data-name="Path 145"
                    d="M408.2,216.16c.13-.47.25-.93.36-1.4a.986.986,0,1,1,1.92.45c-.12.49-.24,1-.38,1.47a.94.94,0,0,1-.26.44,1,1,0,0,1-1.64-1Z"
                    fill="#d3d2d1"
                />
                <path
                    id="Path_146"
                    data-name="Path 146"
                    d="M391.24,238.57l1.21-.79a.985.985,0,0,1,1.24,1.52l-.14.11-1.26.83a1,1,0,1,1-1.05-1.67Z"
                    fill="#d3d2d1"
                />
                <path
                    id="Path_147"
                    data-name="Path 147"
                    d="M393.9,162.71a1,1,0,0,1-1.39-.06c-.33-.36-.66-.71-1-1.06a1,1,0,0,1,0-1.39h0a1,1,0,0,1,1.39,0l1,1.1a1,1,0,0,1,0,1.37Z"
                    fill="#d3d2d1"
                />
                <path
                    id="Path_148"
                    data-name="Path 148"
                    d="M388,158.2a.984.984,0,0,1,1.32-1.46l1.1,1a1,1,0,0,1,0,1.39h0a1,1,0,0,1-1.36,0Z"
                    fill="#d3d2d1"
                />
                <path
                    id="Path_149"
                    data-name="Path 149"
                    d="M384.16,153.68a1,1,0,0,1,1.38-.16c.4.31.78.62,1.17.94a1,1,0,0,1,.13,1.38l-.07.08a1,1,0,0,1-1.31.06l-1.14-.92A1,1,0,0,1,384.16,153.68Z"
                    fill="#d3d2d1"
                />
                <path
                    id="Path_150"
                    data-name="Path 150"
                    d="M387,241c.43-.22.86-.44,1.28-.68a.99.99,0,0,1,1.16,1.58,1.07,1.07,0,0,1-.22.15l-1.32.71A.988.988,0,0,1,387,241Z"
                    fill="#d3d2d1"
                />
                <path
                    id="Path_151"
                    data-name="Path 151"
                    d="M404.58,225c.23-.41.46-.83.68-1.26a1,1,0,0,1,1.75.91c-.23.45-.47.89-.72,1.33a1.28,1.28,0,0,1-.17.22,1,1,0,0,1-1.54-1.2Z"
                    fill="#d3d2d1"
                />
                <path
                    id="Path_152"
                    data-name="Path 152"
                    d="M406.67,220.71c.18-.45.35-.89.52-1.35A.96.96,0,1,1,409,220c-.17.47-.36.94-.55,1.41a1.109,1.109,0,0,1-.22.34.956.956,0,0,1-1.56-1.04Z"
                    fill="#d3d2d1"
                />
                <path
                    id="Path_153"
                    data-name="Path 153"
                    d="M396.26,163.94c.32.39.64.77.95,1.16a1,1,0,0,1-.08,1.33l-.07.06a1,1,0,0,1-1.39-.16l-.92-1.12a1,1,0,1,1,1.51-1.27Z"
                    fill="#d3d2d1"
                />
                <path
                    id="Path_154"
                    data-name="Path 154"
                    d="M402.2,228.65l.58-.81a1,1,0,0,1,1.61,1.14l-.62.85-.28.37-.09.11a.987.987,0,0,1-1.47-1.31Z"
                    fill="#d3d2d1"
                />
                <path
                    id="Path_155"
                    data-name="Path 155"
                    d="M395.17,235.79c.38-.29.75-.6,1.12-.91a1,1,0,0,1,1.32,1.46h-.05c-.38.32-.77.64-1.17,1a.986.986,0,0,1-1.22-1.55Z"
                    fill="#d3d2d1"
                />
                <path
                    id="Path_156"
                    data-name="Path 156"
                    d="M324.24,140.62c.48-.13,1-.24,1.46-.36a1,1,0,0,1,.9,1.67.94.94,0,0,1-.46.25l-1.42.35a.985.985,0,0,1-.48-1.91Z"
                    fill="#d3d2d1"
                />
                <path
                    id="Path_157"
                    data-name="Path 157"
                    d="M305.47,149.53a1,1,0,0,1,.35-1.34c.43-.26.86-.51,1.3-.75a.983.983,0,0,1,1.17,1.56.8.8,0,0,1-.21.15l-1.26.73A1,1,0,0,1,305.47,149.53Z"
                    fill="#d3d2d1"
                />
                <path
                    id="Path_158"
                    data-name="Path 158"
                    d="M319.44,142c.48-.15.95-.31,1.43-.45a.988.988,0,0,1,.98,1.65,1.129,1.129,0,0,1-.4.23l-1.39.44a1,1,0,0,1-.62-1.87Z"
                    fill="#d3d2d1"
                />
                <path
                    id="Path_159"
                    data-name="Path 159"
                    d="M314.76,143.74l1.39-.55a.98.98,0,0,1,.67,1.84l-1.35.53a1,1,0,1,1-.75-1.82Z"
                    fill="#d3d2d1"
                />
                <path
                    id="Path_160"
                    data-name="Path 160"
                    d="M312.4,146.93l-1.32.63a1,1,0,1,1-.87-1.76l1.35-.65a.984.984,0,0,1,.84,1.78Z"
                    fill="#d3d2d1"
                />
                <path
                    id="Path_161"
                    data-name="Path 161"
                    d="M280.79,251.13l1.48.1a.982.982,0,1,1-.13,1.96l-1.48-.09a.987.987,0,0,1,.13-1.97Z"
                    fill="#d3d2d1"
                />
                <path
                    id="Path_162"
                    data-name="Path 162"
                    d="M192.11,250.05l1.47-.18a.987.987,0,0,1,.23,1.96l-1.46.17a1,1,0,1,1-.24-1.95Z"
                    fill="#d3d2d1"
                />
                <path
                    id="Path_163"
                    data-name="Path 163"
                    d="M187.2,250.73l1.47-.22a.985.985,0,1,1,.28,1.95l-1.45.22a1.011,1.011,0,0,1-.3-2Z"
                    fill="#d3d2d1"
                />
                <path
                    id="Path_164"
                    data-name="Path 164"
                    d="M177.46,252.48l1.45-.29a.985.985,0,0,1,.39,1.93l-1.44.29a1,1,0,1,1-.4-1.93Z"
                    fill="#d3d2d1"
                />
                <path
                    id="Path_165"
                    data-name="Path 165"
                    d="M182.32,251.54l1.46-.26a.984.984,0,0,1,.85,1.68.92.92,0,0,1-.52.26l-1.44.26a.986.986,0,1,1-.35-1.94Z"
                    fill="#d3d2d1"
                />
                <path
                    id="Path_166"
                    data-name="Path 166"
                    d="M172.62,253.57l1.45-.34a.985.985,0,1,1,.44,1.92l-1.43.33a1,1,0,1,1-.46-1.91Z"
                    fill="#d3d2d1"
                />
                <path
                    id="Path_167"
                    data-name="Path 167"
                    d="M167.82,254.8l1.44-.39a.984.984,0,0,1,.93,1.66.93.93,0,0,1-.43.25l-1.42.38a1,1,0,1,1-.52-1.9Z"
                    fill="#d3d2d1"
                />
                <path
                    id="Path_168"
                    data-name="Path 168"
                    d="M211.82,248.48l1.48-.06a1.011,1.011,0,0,1,.05,2.02l-1.48.06a1,1,0,1,1-.07-2Z"
                    fill="#d3d2d1"
                />
                <path
                    id="Path_169"
                    data-name="Path 169"
                    d="M197,249.5l1.48-.15a.984.984,0,1,1,.18,1.96l-1.46.15a.985.985,0,0,1-.2-1.96Z"
                    fill="#d3d2d1"
                />
                <path
                    id="Path_170"
                    data-name="Path 170"
                    d="M216.76,248.33h1.48a1,1,0,0,1,.03,2H216.8a1,1,0,0,1-.04-2Z"
                    fill="#d3d2d1"
                />
                <path
                    id="Path_171"
                    data-name="Path 171"
                    d="M206.88,248.72l1.48-.09a1,1,0,0,1,1,.94,1.06,1.06,0,0,1-.3.76,1,1,0,0,1-.64.27l-1.47.08a1,1,0,1,1-.11-2Z"
                    fill="#d3d2d1"
                />
                <path
                    id="Path_172"
                    data-name="Path 172"
                    d="M202,249.05l1.47-.11a.988.988,0,0,1,.15,1.97l-1.47.11a1,1,0,1,1-.15-2Z"
                    fill="#d3d2d1"
                />
                <path
                    id="Path_173"
                    data-name="Path 173"
                    d="M127.34,273.19l1.23-.84a.984.984,0,0,1,1.24,1.52l-.14.11-1.2.83a1,1,0,0,1-1.13-1.62Z"
                    fill="#d3d2d1"
                />
                <path
                    id="Path_174"
                    data-name="Path 174"
                    d="M221.7,248.26h1.48a.99.99,0,1,1,.01,1.98h-1.47a1,1,0,0,1,0-2Z"
                    fill="#d3d2d1"
                />
                <path
                    id="Path_175"
                    data-name="Path 175"
                    d="M123.33,276.13l1.19-.9a1,1,0,0,1,1.28,1.49l-.1.09-1.17.88a1,1,0,0,1-1.2-1.56Z"
                    fill="#d3d2d1"
                />
                <path
                    id="Path_176"
                    data-name="Path 176"
                    d="M115.76,282.56l1.1-1a1,1,0,0,1,1.39,1.44h0l-1.08,1a1,1,0,0,1-1.35-1.44Z"
                    fill="#d3d2d1"
                />
                <path
                    id="Path_177"
                    data-name="Path 177"
                    d="M131.48,270.44l1.26-.79a.989.989,0,0,1,1.21,1.55,1.18,1.18,0,0,1-.18.13l-1.24.77a.982.982,0,1,1-1.05-1.66Z"
                    fill="#d3d2d1"
                />
                <path
                    id="Path_178"
                    data-name="Path 178"
                    d="M119.47,279.25l1.14-.95a1,1,0,0,1,1.32,1.46l-.06.06-1.12.93a1,1,0,1,1-1.28-1.5Z"
                    fill="#d3d2d1"
                />
                <path
                    id="Path_179"
                    data-name="Path 179"
                    d="M144.54,263.29l1.35-.63a.985.985,0,0,1,1.1,1.6.771.771,0,0,1-.28.19l-1.33.62a1,1,0,0,1-.84-1.78Z"
                    fill="#d3d2d1"
                />
                <path
                    id="Path_180"
                    data-name="Path 180"
                    d="M153.68,259.4l1.39-.52a1,1,0,0,1,1,1.63,1.081,1.081,0,0,1-.34.21l-1.38.52a1,1,0,0,1-.7-1.84Z"
                    fill="#d3d2d1"
                />
                <path
                    id="Path_181"
                    data-name="Path 181"
                    d="M149.07,261.26l1.38-.57a.984.984,0,0,1,.75,1.82l-1.36.56a.983.983,0,1,1-.77-1.81Z"
                    fill="#d3d2d1"
                />
                <path
                    id="Path_182"
                    data-name="Path 182"
                    d="M140.09,265.49l1.33-.68a.986.986,0,1,1,.89,1.76c-.44.22-.88.44-1.31.67a1,1,0,1,1-.91-1.75Z"
                    fill="#d3d2d1"
                />
                <path
                    id="Path_183"
                    data-name="Path 183"
                    d="M135.73,267.87l1.3-.73a1,1,0,0,1,1.17,1.57.8.8,0,0,1-.21.15l-1.28.72a.985.985,0,0,1-.98-1.71Z"
                    fill="#d3d2d1"
                />
                <path
                    id="Path_184"
                    data-name="Path 184"
                    d="M158.34,257.71l1.41-.48a.985.985,0,1,1,.62,1.87l-1.39.47a1,1,0,1,1-.64-1.86Z"
                    fill="#d3d2d1"
                />
                <path
                    id="Path_185"
                    data-name="Path 185"
                    d="M163.06,256.18l1.42-.43a.986.986,0,1,1,.56,1.89c-.47.13-.94.28-1.4.42a1,1,0,1,1-.58-1.88Z"
                    fill="#d3d2d1"
                />
                <path
                    id="Path_186"
                    data-name="Path 186"
                    d="M226.64,248.27h1.48a1,1,0,0,1,0,2h-1.48a1,1,0,0,1,0-2Z"
                    fill="#d3d2d1"
                />
                <path
                    id="Path_187"
                    data-name="Path 187"
                    d="M251.3,249.16l1.48.09a.961.961,0,0,1-.08,1.92l-1.47-.08a1,1,0,0,1,.11-2Z"
                    fill="#d3d2d1"
                />
                <path
                    id="Path_188"
                    data-name="Path 188"
                    d="M241.44,248.66l1.48.07a.971.971,0,1,1-.08,1.94l-1.48-.07a1,1,0,0,1,.08-2Z"
                    fill="#d3d2d1"
                />
                <path
                    id="Path_189"
                    data-name="Path 189"
                    d="M246.37,248.89l1.48.08a1,1,0,0,1,.93,1,.94.94,0,0,1-.3.65,1,1,0,0,1-.73.28l-1.48-.08a1,1,0,1,1,.1-2Z"
                    fill="#d3d2d1"
                />
                <path
                    id="Path_190"
                    data-name="Path 190"
                    d="M236.51,248.48l1.48.05a1,1,0,1,1-.07,2l-1.47-.05a1,1,0,0,1,.06-2Z"
                    fill="#d3d2d1"
                />
                <path
                    id="Path_191"
                    data-name="Path 191"
                    d="M231.58,248.34h1.48a.98.98,0,1,1-.01,1.96h-1.48a1,1,0,0,1,.05-2Z"
                    fill="#d3d2d1"
                />
                <path
                    id="Path_192"
                    data-name="Path 192"
                    d="M256.22,249.46l1.48.09a.987.987,0,0,1-.13,1.97l-1.47-.1a1,1,0,1,1,.12-2Z"
                    fill="#d3d2d1"
                />
                <path
                    id="Path_193"
                    data-name="Path 193"
                    d="M271,250.45l1.47.11a.982.982,0,1,1-.13,1.96l-1.48-.1a.988.988,0,0,1,.14-1.97Z"
                    fill="#d3d2d1"
                />
                <path
                    id="Path_194"
                    data-name="Path 194"
                    d="M275.88,250.8l1.48.1a.958.958,0,1,1-.14,1.91l-1.47-.1a1,1,0,0,1,.13-2Z"
                    fill="#d3d2d1"
                />
                <path
                    id="Path_195"
                    data-name="Path 195"
                    d="M261.14,249.78l1.47.1a.982.982,0,1,1-.13,1.96l-1.47-.1a1,1,0,0,1,.13-2Z"
                    fill="#d3d2d1"
                />
                <path
                    id="Path_196"
                    data-name="Path 196"
                    d="M266.06,250.11l1.47.1a1,1,0,0,1,.91,1,.91.91,0,0,1-.29.64,1,1,0,0,1-.76.28l-1.47-.1a.963.963,0,1,1,.14-1.92Z"
                    fill="#d3d2d1"
                />
                <path
                    id="Path_197"
                    data-name="Path 197"
                    d="M307.71,391.31a.832.832,0,0,1,0-.22c1.1-4.38,5.61-6.62,5.61-6.62l2.17.17c1.25,3.86,2.05,5.74,1.85,6.79a.291.291,0,0,0,0,.09,51.868,51.868,0,0,1-9.63-.21Z"
                    fill="#707272"
                />
                <path
                    id="Path_198"
                    data-name="Path 198"
                    d="M317.35,391.52a1,1,0,0,1-.69.69c-2.55.54-6.59.11-8.29-.11a.8.8,0,0,1-.66-.79,51.871,51.871,0,0,0,9.64.21Z"
                    fill="#565656"
                />
                <path
                    id="Path_199"
                    data-name="Path 199"
                    d="M311.56,381.1l.95,4.57a.74.74,0,0,0,.64.61,6.1,6.1,0,0,0,1.47,0,1,1,0,0,0,.75-1.1l-.66-4.06Z"
                    fill="#e4e3e3"
                />
                <path
                    id="Path_200"
                    data-name="Path 200"
                    d="M335.78,391.48a12.439,12.439,0,0,1-4.13-.59v.71a.45.45,0,0,0,.44.46l12.72-.08a.58.58,0,0,0,.47-.85,95.009,95.009,0,0,1-9.5.35Z"
                    fill="#565656"
                />
                <path
                    id="Path_201"
                    data-name="Path 201"
                    d="M345.28,391.13v-.09a16.22,16.22,0,0,0-9.64-6.05l-2.71-.09a13,13,0,0,0-1.23,6,12.441,12.441,0,0,0,4.13.59q4.731.055,9.45-.36Z"
                    fill="#707272"
                />
                <path
                    id="Path_202"
                    data-name="Path 202"
                    d="M332.37,380.79c0,.2.41,3.28.55,4.47a.59.59,0,0,0,.5.53,5.859,5.859,0,0,0,1.86,0,.8.8,0,0,0,.61-.8v-4.83Z"
                    fill="#e4e3e3"
                />
                <path
                    id="Path_203"
                    data-name="Path 203"
                    d="M313.37,321.05s-4.34,38.59-1.92,62.7a18.761,18.761,0,0,0,4.34-.48s4.51-37.73,6-48.52a.36.36,0,0,1,.71,0c1.8,9.08,8.87,44.69,9.71,48.06a6,6,0,0,0,4.34,0s-3.85-51.61-6.75-60.77Z"
                    fill="#707272"
                />
                <path
                    id="Path_204"
                    data-name="Path 204"
                    d="M304.69,276.2s18.33-2.9,32.8,1l-1,14.95s-3.85,20.74-6.75,29.91c0,0-11.09,1.93-16.4-1,0,0-4.34-26-6.75-29.9C306.62,291.15,304.21,281,304.69,276.2Z"
                    fill="#707272"
                />
                <path
                    id="Path_205"
                    data-name="Path 205"
                    d="M317.43,263l-1.83,12.35s5.1,2.34,8.67-.1l-.77-8.72Z"
                    fill="#e4e3e3"
                />
                <path
                    id="Path_206"
                    data-name="Path 206"
                    d="M304.69,276.2s-28.45,30.87,5.07,42.68v-2.41s-12.54-10.61-2.9-25.08C306.86,291.39,305.66,277.65,304.69,276.2Z"
                    fill="#707272"
                />
                <path
                    id="Path_207"
                    data-name="Path 207"
                    d="M310,316.71s4.34-2.89,6.27-1.93a3,3,0,0,1,1.93,2.41s-1,1.93-2.41,1.45-4.83.48-6.27,0C309.52,318.64,309,316.71,310,316.71Z"
                    fill="url(#linear-gradient-campaigns1)"
                />
                <path
                    id="Path_208"
                    data-name="Path 208"
                    d="M337.49,277.16s29.66,29.91-3.86,41.72v-2.41s12.54-10.61,2.89-25.08C336.52,291.39,335.56,279.57,337.49,277.16Z"
                    fill="#707272"
                />
                <path
                    id="Path_209"
                    data-name="Path 209"
                    d="M333.39,316.71s-4.34-2.89-6.27-1.93a3,3,0,0,0-1.93,2.41s1,1.93,2.41,1.45,4.83.48,6.27,0C333.87,318.64,334.35,316.71,333.39,316.71Z"
                    fill="url(#linear-gradient-campaigns1-2)"
                />
                <path
                    id="Path_210"
                    data-name="Path 210"
                    d="M322.76,254c.12.11,5.2,10.09,5.2,10.09l-5.76,2.79-6.2-8.8S320.69,252,322.76,254Z"
                    fill="#e4e3e3"
                />
                <path
                    id="Path_211"
                    data-name="Path 211"
                    d="M318.53,258.32a.92.92,0,0,0,.07.81l2.87,4.79a1.559,1.559,0,0,0,.16.2,1.52,1.52,0,0,0,2.42,0,7.909,7.909,0,0,1,2.27-2.45.92.92,0,0,1,1.24.4,33.928,33.928,0,0,1,2,4.67.91.91,0,0,1-.53,1.12,25.513,25.513,0,0,1-5.52,1.61.93.93,0,0,1-.87-.37c-.88-1.21-3.36-4.58-4.2-5.42s-2.24-.49-2.9-.3a.89.89,0,0,1-.73-.1,2,2,0,0,1-.76-3.08.92.92,0,0,0-.29-1.25,2,2,0,0,1-.78-2.88,2.87,2.87,0,0,1,3.11-1.52,1,1,0,0,0,1-.56.6.6,0,0,1,.47-.37.88.88,0,0,0,.72-1,2.35,2.35,0,0,1,1.32-2.69c2.48-1.37,4.66.86,5.21,2.74s-2.22,3.2-4,3.43C319.52,256.19,318.81,257.59,318.53,258.32Z"
                    fill="#707272"
                />
                <path
                    id="Path_212"
                    data-name="Path 212"
                    d="M319.47,265.42s-1.1-3.23-2.52-3.12-2.52,1.53-.77,3.12C318.36,267.39,319.47,265.42,319.47,265.42Z"
                    fill="#e4e3e3"
                />
                <path
                    id="Path_213"
                    data-name="Path 213"
                    d="M293.42,285.19l28.55,10.8.5,38.74L298.33,327Z"
                    fill="#e4e3e3"
                />
                <path
                    id="Path_214"
                    data-name="Path 214"
                    d="M351.04,285.19l-29.07,10.8.02,38.74L346.13,327Z"
                    fill="#e4e3e3"
                />
                <path
                    id="Path_215"
                    data-name="Path 215"
                    d="M323.65,298.02l25.96-9.7-1.26,7.88-24.7,7.26Z"
                    fill="#d3d2d1"
                />
                <path
                    id="Path_216"
                    data-name="Path 216"
                    d="M324.14,306.36l8.31-2.9-1.23,25.24-6.53,2.47Z"
                    fill="#d3d2d1"
                />
                <path
                    id="Path_217"
                    data-name="Path 217"
                    d="M334.27,303.46l13.08-4.56-2.59,19.88-11.13,2.27Z"
                    fill="#d3d2d1"
                />
                <path
                    id="Path_218"
                    data-name="Path 218"
                    d="M333.5,323.41l10.83-2.36v3.84l-10.83,3.03Z"
                    fill="#d3d2d1"
                />
                <path
                    id="Path_219"
                    data-name="Path 219"
                    d="M319.21,298.02l-23.18-8.92.43,4.43,22.75,7.22Z"
                    fill="#d3d2d1"
                />
                <path
                    id="Path_220"
                    data-name="Path 220"
                    d="M319.15,304.32l-22.29-6.3,2.96,27.36,20.12,5.79Z"
                    fill="#d3d2d1"
                />
                <path
                    id="Path_221"
                    data-name="Path 221"
                    d="M296.37,313.87c-.12-.29-2.44-4.37-.76-5.86s2.47.17,2.36,2S297.3,316,296.37,313.87Z"
                    fill="#e4e3e3"
                />
                <path
                    id="Path_222"
                    data-name="Path 222"
                    d="M348.33,313.87c.12-.29,2.44-4.37.76-5.86s-2.48.17-2.36,2S347.39,316,348.33,313.87Z"
                    fill="#e4e3e3"
                />
                <circle
                    id="Ellipse_2"
                    data-name="Ellipse 2"
                    cx="10.95"
                    cy="10.95"
                    r="10.95"
                    transform="translate(484.71 330.65)"
                    fill="#fff"
                    stroke="#e2e2e2"
                    strokeMiterlimit="10"
                    strokeWidth="2"
                />
                <circle
                    id="Ellipse_3"
                    data-name="Ellipse 3"
                    cx="7.62"
                    cy="7.62"
                    r="7.62"
                    transform="translate(471.96 178.94)"
                    fill="#fff"
                    stroke="#e2e2e2"
                    strokeMiterlimit="10"
                    strokeWidth="2"
                />
                <circle
                    id="Ellipse_4"
                    data-name="Ellipse 4"
                    cx="7.62"
                    cy="7.62"
                    r="7.62"
                    transform="translate(4.91 334.63)"
                    fill="#fff"
                    stroke="#e2e2e2"
                    strokeMiterlimit="10"
                    strokeWidth="2"
                />
                <path
                    id="Path_223"
                    data-name="Path 223"
                    d="M23.47,171.34H15.66v-7.81a1,1,0,0,0-1-1h0a1,1,0,0,0-1,1v7.81H5.9a1,1,0,0,0,0,2h7.79v7.82a1,1,0,0,0,2,0h0V173.3H23.5a.981.981,0,1,0-.03-1.96Z"
                    fill="#e2e2e2"
                    stroke="#e2e2e2"
                    strokeMiterlimit="10"
                    strokeWidth="0.75"
                />
                <path
                    id="Path_224"
                    data-name="Path 224"
                    d="M371.68,83.27h-6.05v-6a.75.75,0,0,0-.75-.75h0a.77.77,0,0,0-.77.75v6h-6a.76.76,0,1,0,0,1.52h6v6a.77.77,0,0,0,.77.75.76.76,0,0,0,.75-.75h0v-6h6.05a.76.76,0,1,0,0-1.52Z"
                    fill="#e2e2e2"
                    stroke="#e2e2e2"
                    strokeMiterlimit="10"
                    strokeWidth="0.75"
                />
                <path
                    id="Path_225"
                    data-name="Path 225"
                    d="M160.11,326.43h-4.79v-4.79a.6.6,0,0,0-.6-.6h0a.62.62,0,0,0-.61.6v4.79h-4.78a.61.61,0,0,0,0,1.21h4.78v4.79a.62.62,0,0,0,.61.6.6.6,0,0,0,.6-.6h0v-4.79h4.79a.61.61,0,1,0,0-1.21Z"
                    fill="#e2e2e2"
                    stroke="#e2e2e2"
                    strokeMiterlimit="10"
                    strokeWidth="0.75"
                />
            </g>
        </SVGIcon>
    );
}
