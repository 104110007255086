import { getReminders } from 'apis/root/index.api';
import React, { useEffect, useMemo, useState } from 'react';
import { useInfiniteQuery } from 'react-query';
import { Div } from 'UIKit';
import ReminderSkeleton from './reminderSkeleton';
import Completed from './completed';
import PastDue from './pastDue';
import './style.scss';
import Upcoming from './upcoming';
import InfiniteScroll from 'Modules/InfiniteScroll';

type Props = {
    handleClose: Function;
    active: string;
    notify: boolean;
};

const Reminder: React.FC<Props> = props => {
    const { handleClose, active, notify } = props;
    const [activeTab, setActiveTab] = useState<string>('upcoming');
    const [reminders, setReminders] = useState<any>([]);
    const [remindersCount, setRemindersCount] = useState<{
        completed: number;
        past_due: number;
        upcoming: number;
    }>({ completed: 0, past_due: 0, upcoming: 0 });

    const {
        data: remindersData,
        fetchNextPage: remindersFetchNextPage,
        hasNextPage: remindersHasNextPage,
        isLoading: remindersIsLoading,
        isFetchingNextPage: remindersIsFetchingNextPage,
    } = useInfiniteQuery(
        ['reminders-all', activeTab],
        async ({ pageParam = 1 }) => getReminders(`page=${pageParam}&limit=25&type=${activeTab}`),
        {
            staleTime: 1000 * 60 * 10, // 10 minutes
            cacheTime: 1000 * 60 * 15, // 15 minutes
            retry: 3,
            getNextPageParam: lastPage => {
                if (lastPage.pagination?.next_page) {
                    return lastPage.pagination?.next_page;
                } else return undefined;
            },
        },
    );

    useEffect(() => {
        let temp: any = [];
        remindersData?.pages.forEach(page => {
            temp = [...temp, ...page.data];
        });
        if (remindersData && remindersData.pages.length > 0) {
            const {
                pages: [
                    {
                        pagination: { completed, past_due, upcoming },
                    },
                ],
            } = remindersData || {};
            setRemindersCount({
                completed,
                past_due,
                upcoming,
            });
        }
        setReminders(temp);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [remindersData]);

    const infiniteScrollRef = InfiniteScroll({
        loading: remindersIsLoading || remindersIsFetchingNextPage,
        hasNextPage: remindersHasNextPage,
        onLoadMore: remindersFetchNextPage,
        threshold: 50,
    });

    useEffect(() => {
        if (active && notify) {
            setActiveTab(active);
        }
    }, [active, notify]);

    const generateView = useMemo(() => {
        if (remindersIsLoading) {
            return (
                <Div className="UpcomingSection">
                    <Div className="UpcomingBody">
                        <ReminderSkeleton />
                        <ReminderSkeleton />
                        <ReminderSkeleton />
                        <ReminderSkeleton />
                        <ReminderSkeleton />
                    </Div>
                </Div>
            );
        }
        switch (activeTab) {
            case 'upcoming':
                return (
                    <>
                        <Upcoming reminders={reminders} handleClose={handleClose} />
                        {remindersIsFetchingNextPage && <ReminderSkeleton />}
                    </>
                );
            case 'past_due':
                return (
                    <>
                        <PastDue reminders={reminders} handleClose={handleClose} />
                        {remindersIsFetchingNextPage && <ReminderSkeleton />}
                    </>
                );
            case 'completed':
                return (
                    <>
                        <Completed reminders={reminders} handleClose={handleClose} />
                        {remindersIsFetchingNextPage && <ReminderSkeleton />}
                    </>
                );
        }
    }, [activeTab, reminders, remindersIsFetchingNextPage, remindersIsLoading, handleClose]);

    const noDataCount = useMemo(() => {
        switch (activeTab) {
            case 'upcoming':
                return remindersCount.upcoming;
            case 'past_due':
                return remindersCount.past_due;
            case 'completed':
                return remindersCount.completed;
            default:
                return 0;
        }
    }, [activeTab, remindersCount.completed, remindersCount.past_due, remindersCount.upcoming]);

    return (
        <Div className={'NDHTPType1'}>
            <Div className={'NDHTPTHead'}>
                <Div className="Reminder PageUpperBox fullWidth">
                    <Div className="PageUpperHeader">
                        <Div
                            className={`PUHTab ${
                                activeTab === 'upcoming' ? 'active' : ''
                            } upcoming`}
                            onClick={() => setActiveTab('upcoming')}
                        >
                            {`Upcoming (${remindersCount.upcoming})`}
                        </Div>
                        <Div
                            className={`PUHTab ${activeTab === 'past_due' ? 'active' : ''} post`}
                            onClick={() => setActiveTab('past_due')}
                        >
                            {`Past Due (${remindersCount.past_due})`}
                        </Div>
                        <Div
                            className={`PUHTab ${
                                activeTab === 'completed' ? 'active' : ''
                            } completed`}
                            onClick={() => setActiveTab('completed')}
                        >
                            {`Completed (${remindersCount.completed})`}
                        </Div>
                    </Div>
                </Div>
            </Div>

            <Div className={'NDHTPT1Body'}>
                <Div
                    // Todo: Add the condition on next line, make it true when there is no data among the selected Tab to display NO DATA image, so that image will be placed in center
                    className={`NDNotificationList ${
                        noDataCount <= 0 ? 'NDNotificationList--noData' : ''
                    }`}
                    reference={infiniteScrollRef}
                >
                    <Div className="PageUpperBody">{generateView}</Div>
                </Div>
            </Div>
        </Div>
    );
};

export default Reminder;
