import React from 'react';

import { SVGIcon } from '../../UIKit/index';

export default function DCStatus(props) {
    return (
        <SVGIcon {...props} viewBox="0 0 24 24">
            <g>
                <path d="m23.707 22.293-2.54-2.54c.524-.791.833-1.736.833-2.753 0-2.757-2.243-5-5-5s-5 2.243-5 5 2.243 5 5 5c1.017 0 1.962-.309 2.753-.833l2.54 2.54c.391.391 1.023.391 1.414 0s.391-1.023 0-1.414zm-6.707-2.293c-1.654 0-3-1.346-3-3s1.346-3 3-3 3 1.346 3 3-1.346 3-3 3z" />
                <path d="m1 24c.553 0 1-.448 1-1v-7c0-.552-.447-1-1-1s-1 .448-1 1v7c0 .552.447 1 1 1z" />
                <path d="m8 23v-11c0-.552-.447-1-1-1s-1 .448-1 1v11c0 .552.447 1 1 1s1-.448 1-1z" />
                <path d="m14 6c0-.55-.45-1-1-1s-1 .45-1 1v6.11c.57-.59 1.25-1.08 2-1.43z" />
                <path d="m19 0c-.55 0-1 .45-1 1v9.08c.71.09 1.38.3 2 .6v-9.68c0-.55-.45-1-1-1z" />
            </g>
        </SVGIcon>
    );
}
