import React from 'react';

import { SVGIcon } from '../../UIKit/index';

export default function DCConversationMenu(props) {
    return (
        <SVGIcon {...props} viewBox="0 0 512 448">
            <g transform="translate(-0.947 -122.853)">
                <g transform="translate(0.021 144.999)">
                    <g transform="translate(0)">
                        <g transform="translate(-2 -21)">
                            <g>
                                <path
                                    fill="#2F8DFF"
                                    d="M381.93,228.75l7.3,1.38c25.97,2.79,49.28-16.01,52.07-41.98c0.26-2.39,0.33-4.8,0.22-7.21       V45.77c-0.07-25.38-20.68-45.92-46.06-45.91H202.51c-25.43,0-46.04,20.61-46.04,46.04v0v135.18       c0,25.43,20.61,46.04,46.04,46.04h0h172.58C377.47,227.11,379.82,227.67,381.93,228.75z"
                                />
                                <path
                                    fill="#2F8DFF"
                                    d="M467.67-0.13h-80.19c25.43,0,46.04,20.61,46.04,46.04V181.1       c0,25.43-20.61,46.04-46.04,46.04c-1.97,0-3.9,0.59-5.54,1.68c1.24,0.64,2.39,1.46,3.4,2.42l74.7,70.9       c1.99,1.89,5.14,1.82,7.03-0.17c1.1-1.15,1.58-2.76,1.29-4.33l-11.35-62.99c-0.65-3.47,1.64-6.81,5.11-7.46       c0.39-0.07,0.78-0.11,1.17-0.11h4.35c25.43,0,46.04-20.61,46.04-46.04V45.86C513.66,20.46,493.07-0.12,467.67-0.13z"
                                />
                            </g>
                            <g transform="translate(13.067 1.442)">
                                <g transform="translate(0 0)">
                                    <path
                                        fill="#F0F7FF"
                                        d="M452.12,66.48H191.88c-5.22,0-9.46-4.23-9.46-9.46s4.23-9.46,9.46-9.46h260.24        c5.22,0,9.46,4.23,9.46,9.46S457.35,66.48,452.12,66.48z"
                                    />
                                </g>
                                <g transform="translate(6.878 3.171)">
                                    <path
                                        fill="#F0F7FF"
                                        d="M445.22,123.19H315.11c-5.22,0-9.46-4.23-9.46-9.46c0-5.22,4.23-9.46,9.46-9.46h130.13        c5.22,0,9.46,4.23,9.46,9.46c0,5.22-4.23,9.46-9.46,9.46H445.22z"
                                    />
                                </g>
                            </g>
                        </g>
                        <g transform="translate(0.926 -20.918)">
                            <g transform="translate(9.933 4.874)">
                                <path
                                    fill="#E6EFFF"
                                    d="M255.46,110.33h-76.82c27.44,0,49.68,22.24,49.68,49.68v0v147.8       c0,27.44-22.24,49.68-49.68,49.68h0h76.82c27.44,0,49.68-22.24,49.68-49.68v0v-147.8C305.14,132.57,282.9,110.33,255.46,110.33       L255.46,110.33z"
                                />
                            </g>
                            <g transform="translate(0 4.874)">
                                <path
                                    fill="#E6EFFF"
                                    d="M231.74,110.33H43.78c-25.54,1.96-44.75,24.11-43.05,49.68v147.8       c-1.69,25.56,17.51,47.72,43.05,49.68h4.6c4.56,0.13,8.66,2.78,10.65,6.89l0.78,1.48h50.32l4.84-4.22       c2.96-2.63,6.77-4.11,10.73-4.14h105.93c25.54-1.96,44.75-24.11,43.05-49.68V160c1.69-25.55-17.5-47.71-43.04-49.68       L231.74,110.33z"
                                />
                                <g transform="translate(1.885 3.108)">
                                    <g transform="translate(0)">
                                        <path
                                            fill="#2F8DFF"
                                            d="M243.61,184.23H41.82c-5.03-1.06-8.25-5.99-7.2-11.02c0.76-3.61,3.58-6.44,7.2-7.2h201.79         c5.03,1.06,8.25,5.99,7.2,11.02C250.05,180.65,247.22,183.47,243.61,184.23z"
                                        />
                                    </g>
                                    <g transform="translate(0 3.038)">
                                        <path
                                            fill="#2F8DFF"
                                            d="M243.61,238.66H41.82c-5.03-1.06-8.25-5.99-7.2-11.02c0.76-3.61,3.58-6.44,7.2-7.2h201.79         c5.03,1.06,8.25,5.99,7.2,11.02C250.05,235.08,247.22,237.91,243.61,238.66z"
                                        />
                                    </g>
                                    <g transform="translate(0 6.077)">
                                        <path
                                            fill="#2F8DFF"
                                            d="M135.78,293.11H41.2c-5.03-1.41-7.97-6.63-6.56-11.66c0.89-3.18,3.38-5.67,6.56-6.56         h94.58c5.03,1.41,7.97,6.63,6.56,11.66C141.45,289.74,138.96,292.22,135.78,293.11z"
                                        />
                                    </g>
                                </g>
                            </g>
                            <path
                                fill="#2F8DFF"
                                d="M55.04,362.37c3.81,0,6.9,3.1,6.9,6.91c0,0.4-0.04,0.81-0.11,1.2l-12.24,67.97      c-0.52,2.92,1.42,5.71,4.34,6.23c1.68,0.3,3.4-0.22,4.64-1.39l80.61-76.52c2.99-2.84,6.96-4.43,11.09-4.43L55.04,362.37z"
                            />
                        </g>
                    </g>
                </g>
            </g>
        </SVGIcon>
    );
}
