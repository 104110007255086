import React from 'react';

import { SVGIcon } from '../../UIKit/index';

export default function DCCold(props) {
    return (
        <SVGIcon {...props} viewBox="0 0 22.856 25.426">
            <path
                d="M45.406,16.243l-.434-1.619-3.8,1.019-5.074-2.93,5.074-2.93,3.8,1.019.434-1.619L43.221,8.6l2.624-1.515-.838-1.452L42.383,7.146l.585-2.185-1.619-.434-1.019,3.8-5.074,2.93V5.4L38.04,2.616,36.855,1.431l-1.6,1.6V0H33.579V3.03l-1.6-1.6L30.794,2.616,33.579,5.4v5.86L28.5,8.331l-1.019-3.8-1.619.434.585,2.185L23.827,5.631l-.838,1.452L25.613,8.6l-2.185.586.434,1.619,3.8-1.019,5.074,2.93-5.074,2.93-3.8-1.019-.434,1.619,2.185.586-2.624,1.515.838,1.452,2.624-1.515-.585,2.185,1.619.434,1.019-3.8,5.074-2.93v5.86L30.794,22.81,31.98,24l1.6-1.6v3.03h1.676V22.4l1.6,1.6L38.04,22.81l-2.785-2.785v-5.86l5.074,2.93,1.019,3.8,1.619-.434-.585-2.185L45.007,19.8l.838-1.452-2.624-1.515Z"
                transform="translate(-22.989)"
            />
        </SVGIcon>
    );
}
