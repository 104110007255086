export const source = {
    PEOPLE: 'people',
    BUSINESSES: 'businesses',
    CONTACT: 'contact',
    DEALS: 'deals',
    DEAL: 'deal',
};
export const nameType = {
    CONTACTS: 'Contacts',
    CONTACT: 'Person',
    BUSINESS: 'Business',
};
export const newButtonType = {
    PERSON: 'Person',
    BUSINESS: 'Business',
};

export const leadStatus = [
    {
        id: 1,
        title: 'New',
        value: 'new',
    },
    {
        id: 2,
        title: 'Open',
        value: 'open',
    },
    {
        id: 3,
        title: 'In Progress',
        value: 'in_progress',
    },
    {
        id: 4,
        title: 'Open Deal',
        value: 'open_deal',
    },
    {
        id: 5,
        title: 'Unqualified',
        value: 'unqualified',
    },
    {
        id: 6,
        title: 'Attempted to contact',
        value: 'attempted_to_contact',
    },
    {
        id: 7,
        title: 'Connected',
        value: 'connected',
    },
    {
        id: 8,
        title: 'Bad timing',
        value: 'bad_timing',
    },
];

export const activityType = {
    CONTACT_CREATED: 'contact_created',
    DEAL_CREATED: 'deal_created',
    NOTE: 'note',
    EMAIL: 'email',
    SMS: 'sms',
    REMINDER: 'reminder',
    MEETING: 'meeting',
    LOGS: 'logs',
    CALL: 'call',
    FOLLOWER: 'follower',
    OWNER: 'owner',
    BUSINESS: 'business',
    PERSON: 'person',
    DEAL: 'deal',
    INSTASITE: 'instasite',
    INSTAREPORT: 'instareport',
};
