import React from 'react';

import { SVGIcon } from '../../UIKit/index';

export default function DCSms2(props) {
    return (
        <SVGIcon {...props} viewBox="0 0 512 450">
            <g transform="translate(0 0.301)">
                <g transform="translate(0 -0.301)">
                    <path
                        fill="#D9EAFF"
                        d="M473.9,339.29l33.05,99.07c1.55,4.63-0.95,9.63-5.58,11.18c-1.52,0.51-3.15,0.59-4.71,0.25    l-127.54-28.33c-35.87,15.23-74.47,23.02-113.44,22.88c-141.53,0-256.2-98.86-256.2-220.87S114.16,2.6,255.69,2.6    s256.2,98.82,256.2,220.87c-0.16,41.65-13.45,82.2-37.99,115.86V339.29z"
                    />
                    <path
                        fill="#005EFF"
                        d="M487.74,126.9c-43.55-24.86-92.89-37.81-143.04-37.54c-141.53,0-256.2,98.82-256.2,220.87    c0.06,33.25,8.6,65.94,24.82,94.97C45.03,365.51,0.16,298.2,0.16,221.87C0.16,99.82,114.83,1,256.36,1    C358.4,1,446.56,52.42,487.74,126.9z"
                    />
                    <g transform="translate(27.575 23.746)">
                        <path
                            fill="#6B8AAA"
                            d="M217.47,179.55h106.47c5.88,0,10.65,4.77,10.65,10.65l0,0     c0,5.88-4.77,10.65-10.65,10.65H217.47c-5.88,0-10.65-4.77-10.65-10.65l0,0C206.82,184.32,211.59,179.55,217.47,179.55z"
                        />
                        <path
                            fill="#6B8AAA"
                            d="M217.47,222.14h106.47c5.88,0,10.65,4.77,10.65,10.65l0,0     c0,5.88-4.77,10.65-10.65,10.65H217.47c-5.88,0-10.65-4.77-10.65-10.65l0,0C206.82,226.9,211.59,222.14,217.47,222.14z"
                        />
                        <path
                            fill="#6B8AAA"
                            d="M217.47,264.72h106.47c5.88,0,10.65,4.77,10.65,10.65l0,0     c0,5.88-4.77,10.65-10.65,10.65H217.47c-5.88,0-10.65-4.77-10.65-10.65l0,0C206.82,269.49,211.59,264.72,217.47,264.72z"
                        />
                    </g>
                </g>
            </g>
        </SVGIcon>
    );
}
