import React from 'react';
import { Text, Font, View, Svg, Rect } from '@react-pdf/renderer';
import { instareportFontFamily } from '../../components/constant';

Font.register({ ...instareportFontFamily });

const ColorBox = ({ score }) => {
    const gradeToColor = { A: '#08cdce', B: '#ff3cf9', C: '#4151f5', D: '#ffaf3a', F: '#fe6786' };
    const color = gradeToColor[score];
    return (
        <View
            style={{
                backgroundColor: `${color}`,
                height: 25,
                width: 25,
                alignItems: 'center',
                borderRadius: 15,
                marginRight: 5,
            }}
        >
            <Text
                style={{
                    textAlign: 'center',
                    marginTop: 6,
                    fontSize: 10,
                    fontFamily: 'Source Sans Pro',
                    fontWeight: 900,
                    color: 'white',
                }}
            >
                {score}
            </Text>
        </View>
    );
};

export const getInitialsListingSvg = name => {
    let text = ['Not', 'Available'];
    if (name && name.length) {
        text = name.split(' ');
    }

    const text1 = text[0].charAt(0);
    const text2 = text[1]?.charAt(0) || '';
    const res = text1.concat(text2);
    return (
        <Svg
            xmlns="http://www.w3.org/2000/svg"
            width={64}
            height={64}
            viewBox="0 0 64 64"
            version="1.1"
        >
            <Rect fill="#ddd" cx={32} width={64} height={64} cy={32} rx={16} ry={16} />
            <Text
                x={32}
                y={32}
                style={{
                    color: '#222',
                    lineHeight: 1,
                }}
                alignmentBaseline={'middle'}
                textAnchor={'middle'}
                fontSize={24}
                dy={0.1}
                dominantBaseline={'middle'}
                fill={'#222'}
            >
                {res.toUpperCase()}
            </Text>
        </Svg>
    );
};

export default ColorBox;
