import React from 'react';

import { SVGIcon } from '../../UIKit/index';

export default function DCGoogleAds(props) {
    return (
        <SVGIcon {...props} viewBox="0 0 497.874 497.874">
            <path
                d="m293.703 34.244c-43.695-25.228-99.479-10.286-124.711 33.417l-156.598 271.234c-40.412 69.995 24.347 154.827 102.692 133.831 23.555-6.312 43.242-21.418 55.435-42.537l156.597-271.235c25.171-43.594 10.18-99.54-33.415-124.71z"
                fill="#FFC107"
            />
            <path
                d="m485.661 338.895-156.598-271.234c-12.192-21.119-31.88-36.225-55.435-42.537-78.369-21.003-143.086 63.867-102.692 133.831l156.598 271.235c25.221 43.685 81 58.654 124.711 33.417 43.595-25.171 58.586-81.116 33.416-124.712z"
                fill="#2196F3"
            />
            <path
                d="m179.642 360.913c-13.058-48.734-63.067-77.617-111.813-64.555-78.373 21-91.981 126.853-22.018 167.248 69.801 40.3 154.944-23.895 133.831-102.693z"
                fill="#53C858"
            />
        </SVGIcon>
    );
}
