import { getUserDetails } from './localStorageUtils';

const areValidScopes = (masterScope, childScope) => {
    const data = getUserDetails().scope || [];

    if (masterScope === 'dashclicks' || childScope === 'dashclicks') {
        return !!getUserDetails()?.dashclicks?.general;
    }
    const isOwner = getUserDetails()?.is_owner || false;
    if (isOwner) {
        return true;
    }

    let scopeArray = data;
    scopeArray.push('dashboard');
    try {
        const masterIndex = scopeArray.indexOf(masterScope);
        if (masterIndex > -1) {
            return true;
        }

        if (!childScope) {
            for (const element of scopeArray) {
                const ele = element.split('.')[0];
                if (ele === masterScope) {
                    return true;
                }
            }
            return false;
        }

        const childIndex = scopeArray.indexOf(childScope);
        return childIndex > -1;
    } catch (e) {
        console.error('error', e);
    }
};

export default areValidScopes;
