import { getUserWorkspaceInfo } from 'Utils/localStorageUtils';
import { CallAPI } from '../../Utils/apiCall';
import config from 'config';
import { TYPES } from 'Pages/Templates/constants';

export const loadData = (page, limit, type, searchText, sortTemplate, category) => {
    const api = new CallAPI();
    let url = `${config.REACT_APP_API_BASE_URL}/${process.env.REACT_APP_API_VERSION}/${process.env.REACT_APP_API_TEMPLATES}`;
    let params = { page, limit };

    if (type === TYPES.MY_TEMPLATES) url = `${url}/${type}`;
    else if (Array.isArray(type)) {
        if (type.length === 1) params.type = type.join(',');
    } else {
        url = `${url}/${TYPES.MY_TEMPLATES}`;
        params.type = type;
    }

    if (category) params.category = category;

    if (searchText) {
        params.search = searchText;
    }

    if (sortTemplate) {
        const temp = sortTemplate.split('&');
        params.sortOrder = temp[0].split('=')[1];
        params.sortField = temp[1].split('=')[1];
    }

    return api.request('GET', url, null, null, null, params);
};

export const getTemplate = async id => {
    const api = new CallAPI();
    const url = `${config.REACT_APP_API_BASE_URL}/${process.env.REACT_APP_API_VERSION}/${process.env.REACT_APP_API_TEMPLATES}/mytemplates/${id}`;
    const res = await api.request('GET', url, null, null, null, null);

    return res;
};

export const editTemplate = async (id, body) => {
    const api = new CallAPI();
    const url = `${config.REACT_APP_API_BASE_URL}/${process.env.REACT_APP_API_VERSION}/${process.env.REACT_APP_API_TEMPLATES}/mytemplates/${id}`;
    const res = await api.request('PUT', url, body);
    return res;
};

export const createTemplate = async (body, type) => {
    const api = new CallAPI();
    const url = `${config.REACT_APP_API_BASE_URL}/${process.env.REACT_APP_API_VERSION}/${process.env.REACT_APP_API_TEMPLATES}/mytemplates/${type}`;
    const res = await api.request('POST', url, body);
    return res;
};

export const deleteTemplate = async id => {
    const api = new CallAPI();
    const url = `${config.REACT_APP_API_BASE_URL}/${process.env.REACT_APP_API_VERSION}/${process.env.REACT_APP_API_TEMPLATES}/mytemplates/${id}`;
    const res = await api.request('DELETE', url, null);
    return res;
};

const api2 = new CallAPI();
export const deleteFile = async file => {
    api2.cancelAllRequests();
    const mainURL = `${config.REACT_APP_API_BASE_URL}/${process.env.REACT_APP_API_VERSION}`;

    const url = `${mainURL}/${process.env.REACT_APP_API_INTEGRATIONS}/${process.env.REACT_APP_API_WASABI}/${process.env.REACT_APP_API_DELETE_BY_KEY}`;
    return api2.request('DELETE', url, {
        key: file.key,
        bucket: file.bucket,
    });
};

export const sendTestSMS = async (testPhoneNum, bodyText) => {
    const api = new CallAPI();
    const url = `${config.REACT_APP_API_BASE_URL}/${process.env.REACT_APP_API_VERSION}/${process.env.REACT_APP_API_INTEGRATIONS}/twilio/send`;
    const body = {
        origin: 'templates',
        type: 'SMS',
        recipients: [{ phone: testPhoneNum }],
        contents: bodyText,
        from: getUserWorkspaceInfo().phone,
    };
    return api.request('POST', url, body);
};
