import './style.scss';

import React, { Fragment, useCallback, useEffect, useLayoutEffect, useRef, useState } from 'react';

import _ from 'lodash';
import PropTypes from 'prop-types';

import DCAddIcon from 'Assets/icons/DCAddIcon';
import DCMinusIcon from 'Assets/icons/DCMinus';
import DCFillDownArray from 'Assets/icons/DCFillDownArray';
import DCFillUpArray from 'Assets/icons/DCFillUpArray';
import DCIndeterminateCheckBox from 'Assets/icons/DCIndeterminateCheckBox';
import DCNoDataCommon from 'Assets/icons/DCNoDataCommon';
import DCTrash from 'Assets/icons/DCTrash';
import { Button, Checkbox, Div, Span, NoData } from 'UIKit/index';
import { arrayContainsArray, formatNumberString } from 'Utils';
import useRefWithCallback from 'Utils/customHooks';
import TableSkeleton from './tableSkeleton';
import DCUserPlus from 'Assets/icons/DCUserPlus';
import BulkDialog from './bulkDialog';
import DCNoResultFound from 'Assets/icons/DCNoResultFound';

const DCTable = props => {
    const {
        customClass,
        loader,
        title,
        onWidthChange,
        columns,
        data,
        onSort,
        onSelectRow,
        onClickRow,
        hideSelectAll,
        selectAll,
        handlAllSelect,
        totalResults,
        handleOnDelete,
        checkboxes,
        selectedIds,
        selectOnRowClick,
        selectWholePage,
        onChangeSelectWholePage,
        onClearSelection,
        singleSelectedId,
        deleteButton,
        noData,
        disableSelectionDescription,
        disableIntermediateSelection,
        infinityScrollRef,
        openBulkDialog,
        setOpenBulkDialog,
        loadingBulkAction,
        bulkAssign = false,
        bulkFollower = false,
        bulkAddTag = false,
        bulkRemoveTag = false,
        handleBulkAction,
        disabledCheckboxes = [],
        paywallCheckTags,
    } = props;

    const [loading, setLoading] = useState(false);
    const [tableColumns, setTableColumns] = useState([]);
    const [tableData, setTableData] = useState([]);
    const [singleSelection, setSingleSelection] = useState(false);
    const [selectAllPage, setSelectAllPage] = useState(false);
    const [listHeight, setListHeight] = useState(0);
    const [tableTotalWidth, setTableTotalWidth] = useState(0);
    const [sortColumns, setSortColumns] = useState([]);
    const [initialSorting, setInitialSorting] = useState(false);
    const [defaultDelete, setDefaultDelete] = useState(deleteButton ? deleteButton : true);
    const [hoveredRow, setHoveredRow] = useState(null);
    const tableBoxRef = useRef();
    const hasInitialData = useRef(false);

    useEffect(() => {
        setLoading(loader);
    }, [loader]);

    useEffect(() => {
        if (!loading) {
            let pos = sessionStorage.getItem('scrolPosition');

            tableBoxRef.current?.scrollTo(parseInt(pos), 0);

            sessionStorage.removeItem('scrolPosition');
        }
    }, [loading]);

    useEffect(() => {
        if (deleteButton === false) {
            setDefaultDelete(deleteButton);
        } else {
            setDefaultDelete(true);
        }
    }, [deleteButton]);

    useEffect(() => {
        const tempSortColumns = _.cloneDeep(columns);
        tempSortColumns.forEach(col => {
            col.sort = null;
        });
        setSortColumns(tempSortColumns);
        return () => {
            setSortColumns([]);
        };
    }, [columns]);

    const handleSorting = useCallback(
        (column, pos, order) => {
            sessionStorage.setItem('scrolPosition', tableBoxRef.current?.scrollLeft);

            if (column.sortable && onSort) {
                setInitialSorting(true);

                onSort(column.accessor, order);

                const tempSortColumns = _.cloneDeep(sortColumns);
                tempSortColumns.forEach((col, i) => {
                    if (i === pos) {
                        col.sort = order;
                    } else {
                        col.sort = null;
                    }
                });
                setSortColumns(tempSortColumns);
            }
        },
        [onSort, sortColumns],
    );

    useEffect(() => {
        if (!hasInitialData?.current && tableData.length > 0) {
            hasInitialData.current = true;
        }
        const currentPageIds = tableData.map(row => {
            return row.id;
        });
        if (checkboxes) {
            if (selectedIds.length !== 0 && currentPageIds.length !== 0) {
                if (disableIntermediateSelection) {
                    setSelectAllPage(arrayContainsArray(selectedIds, currentPageIds));
                } else {
                    setSelectAllPage(true);
                }
            }
        }
    }, [checkboxes, disableIntermediateSelection, selectAllPage, selectedIds, tableData]);

    useEffect(() => {
        setSelectAllPage(selectWholePage);
    }, [selectWholePage]);

    function resizableGrid(table) {
        const headerRow = table.querySelector('thead tr');
        const cols = headerRow ? headerRow.children : undefined;
        if (!cols) return;

        const tableHeight = headerRow.offsetHeight;

        function createDiv() {
            const div = document.createElement('div');
            div.className = 'resizable-div-handler';
            div.style.top = '5px';
            div.style.bottom = '5px';
            div.style.right = '-3px';
            div.style.width = '5px';
            div.style.position = 'absolute';
            div.style.cursor = 'col-resize';
            div.style.userSelect = 'none';
            div.style.borderLeft = '1px solid #0000ff';
            div.style.borderRight = '1px solid #0000ff';
            return div;
        }

        function getStyleVal(elm, css) {
            return window.getComputedStyle(elm, null).getPropertyValue(css);
        }

        function paddingDiff(col) {
            if (getStyleVal(col, 'box-sizing') === 'border-box') {
                return 0;
            }

            const padLeft = getStyleVal(col, 'padding-left');
            const padRight = getStyleVal(col, 'padding-right');
            return parseInt(padLeft, 10) + parseInt(padRight, 10);
        }

        function setListeners(div) {
            let pageX;
            let curCol;
            let curColWidth;
            let tempCols;
            let totalWidth;

            div.addEventListener('mousedown', e => {
                curCol = e.target.parentElement;
                pageX = e.pageX;

                const padding = paddingDiff(curCol);

                curColWidth = parseInt(curCol.style.width, 10) - padding;

                tempCols = table.querySelectorAll('.TableCol' + curCol.dataset.colgroup);
            });

            document.addEventListener('mousemove', e => {
                if (curCol) {
                    totalWidth = 0;
                    const diffX = e.pageX - pageX;

                    if (curColWidth + diffX > 200) {
                        curCol.style.width = curColWidth + diffX + 'px';
                    } else {
                        curCol.style.width = 200 + 'px';
                    }

                    for (let i = 0; i < tempCols.length; i += 1) {
                        tempCols[i].style.width = curColWidth + diffX + 'px';
                    }

                    for (let i = 0; i < cols.length; i += 1) {
                        totalWidth = totalWidth + parseInt(cols[i].style.width, 10);
                    }
                    table.style.width = totalWidth + 'px';
                }
            });

            document.addEventListener('mouseup', () => {
                if (tableColumns && curCol) {
                    const tempColumns = [...tableColumns];
                    if (singleSelection) {
                        for (let i = 0; i < tempColumns.length; i += 1) {
                            if (parseInt(cols[i + 1].style.width, 10) > 200) {
                                tempColumns[i].width = parseInt(cols[i + 1].style.width, 10);
                            } else {
                                tempColumns[i].width = 200;
                            }
                        }
                    } else {
                        for (let i = 0; i < tempColumns.length; i += 1) {
                            if (parseInt(cols[i].style.width, 10) > 200) {
                                tempColumns[i].width = parseInt(cols[i].style.width, 10);
                            } else {
                                tempColumns[i].width = 200;
                            }
                        }
                    }
                    setTableColumns(tempColumns);
                    onWidthChange(tempColumns);
                }
                curCol = undefined;
                pageX = undefined;
                curColWidth = undefined;
                tempCols = undefined;
            });
        }

        if (singleSelection) {
            for (let i = 1; i < cols.length; i += 1) {
                const div = createDiv();
                cols[i].appendChild(div);
                cols[i].style.position = 'relative';
                cols[i].dataset.colgroup = i;
                setListeners(div);
            }
        } else {
            for (let i = 0; i < cols.length; i += 1) {
                const div = createDiv(tableHeight);
                cols[i].appendChild(div);
                cols[i].style.position = 'relative';
                cols[i].dataset.colgroup = i + 1;
                setListeners(div);
            }
        }
    }

    function unResizableGrid(table) {
        const handlers = table.querySelectorAll('.resizable-div-handler');
        for (let i = 0; i < handlers.length; i += 1) {
            handlers[i].remove();
        }
    }

    const tableRef = useRefWithCallback(resizableGrid, unResizableGrid);

    useEffect(() => {
        if (columns) {
            const filterColumns = columns.filter(col => col.active === true);
            setTableColumns(filterColumns);
        }
    }, [columns]);

    useEffect(() => {
        if (data) {
            setTableData(data);
        }
    }, [data]);

    useEffect(() => {
        setSingleSelection(checkboxes);
    }, [checkboxes, tableColumns]);

    const tableHeadRef = useRef();

    useLayoutEffect(() => {
        /**
         * To measure box height, Using ref [useRef hooks] object to measure height and
         * setting it into states which is being used to set height of infinite scroll bar
         */

        function updateSize() {
            if (tableBoxRef.current) {
                const height = tableBoxRef.current.getBoundingClientRect().height;
                if (selectAllPage === true) {
                    setListHeight(height - 80);
                } else {
                    setListHeight(height - 50);
                }
            }
        }
        document.addEventListener('resize', updateSize);
        updateSize();
        return () => document.removeEventListener('resize', updateSize);
    }, [listHeight, selectAllPage, tableBoxRef]);

    useEffect(() => {
        const total = tableColumns.reduce(
            (prevValue, currentValue) => prevValue + parseInt(currentValue.width),
            0,
        );
        setTableTotalWidth(total);
    }, [tableColumns]);

    const handleAllCheck = useCallback(
        e => {
            setSelectAllPage(e.target.checked);

            onChangeSelectWholePage(e.target.checked);

            handlAllSelect(false);
        },
        [handlAllSelect, onChangeSelectWholePage],
    );

    useEffect(() => {
        if (selectedIds?.length === 0) {
            setSelectAllPage(false);
        }
    }, [selectedIds]);

    const generateBulkDialogTitle = useCallback(() => {
        switch (openBulkDialog) {
            case 'owner':
                return `Assign Owner`;
            case 'followers':
                return 'Add Followers';
            case 'addTags':
                return 'Add Tags';
            case 'removeTags':
                return 'Remove Tags';
        }
    }, [openBulkDialog]);

    const generateBulkDialogLabel = useCallback(() => {
        switch (openBulkDialog) {
            case 'owner':
                return `Select new owner of these records`;
            case 'followers':
                return `Add followers to selected ${title}`;
            case 'addTags':
                return 'Select tags to add to these records';
            case 'removeTags':
                return 'Select tags to remove from these records';
        }
    }, [openBulkDialog, title]);

    return (
        <Fragment>
            {openBulkDialog && (
                <BulkDialog
                    open={!!openBulkDialog}
                    onClose={() => setOpenBulkDialog(false)}
                    title={generateBulkDialogTitle()}
                    label={generateBulkDialogLabel()}
                    fieldType={['addTags', 'removeTags'].includes(openBulkDialog) ? 'tags' : 'user'}
                    acceptButton={{
                        title:
                            openBulkDialog === 'owner'
                                ? 'Assign'
                                : openBulkDialog === 'removeTags'
                                ? 'Remove'
                                : 'Add',
                        onClick: _data => handleBulkAction(_data, selectedIds),
                    }}
                    loading={loadingBulkAction}
                />
            )}
            <Div
                className={
                    'PageTableBox ' +
                    (selectOnRowClick === true ? 'RowClick ' : '') +
                    (customClass ? customClass : '')
                }
                reference={tableBoxRef}
            >
                {loading === true ? <TableSkeleton /> : null}
                {loading === false && tableData.length > 0 && (
                    <table
                        className={'DCTable ' + (loading === true ? 'skeletonStart' : '')}
                        ref={tableRef}
                        style={{ width: tableTotalWidth }}
                    >
                        <thead className="PageTableHead" ref={tableHeadRef}>
                            <tr className="PTHRow">
                                {singleSelection && (
                                    <th
                                        className="PTHRHead"
                                        style={{
                                            width: `${48}px`,
                                            minWidth: '48px',
                                        }}
                                    >
                                        {!hideSelectAll && (
                                            <Div className="PTHSelectAll">
                                                <Checkbox
                                                    checkColor={
                                                        selectAllPage ? 'var(--dark-blue)' : ''
                                                    }
                                                    unCheckColor={
                                                        disableIntermediateSelection
                                                            ? ''
                                                            : selectedIds.length !== totalResults
                                                            ? 'var(--dark-blue)'
                                                            : ''
                                                    }
                                                    unCheckIcon={
                                                        disableIntermediateSelection ? (
                                                            ''
                                                        ) : selectedIds.length !== totalResults ? (
                                                            <DCIndeterminateCheckBox />
                                                        ) : (
                                                            ''
                                                        )
                                                    }
                                                    onChange={handleAllCheck}
                                                    checked={
                                                        disableIntermediateSelection
                                                            ? selectedIds.length > 0
                                                            : selectAllPage
                                                    }
                                                />
                                            </Div>
                                        )}
                                    </th>
                                )}
                                {tableColumns.map((column, i) => (
                                    <th
                                        className="PTHRHead"
                                        key={i}
                                        style={{ width: `${column.width}px` }}
                                    >
                                        <Div className="PTHRHTitle">
                                            <Div className="PTHRHText">{column.Header}</Div>
                                            {column.sortable && onSort && (
                                                <Div className="PTHRHSort">
                                                    <Div
                                                        className={
                                                            initialSorting &&
                                                            sortColumns[i].sort === 'asc'
                                                                ? 'active'
                                                                : ''
                                                        }
                                                        onClick={() => {
                                                            handleSorting(column, i, 'asc');
                                                        }}
                                                    >
                                                        <DCFillUpArray />
                                                    </Div>
                                                    <Div
                                                        className={
                                                            initialSorting &&
                                                            sortColumns[i].sort === 'desc'
                                                                ? 'active'
                                                                : ''
                                                        }
                                                        onClick={() => {
                                                            handleSorting(column, i, 'desc');
                                                        }}
                                                    >
                                                        <DCFillDownArray />
                                                    </Div>
                                                </Div>
                                            )}
                                        </Div>
                                    </th>
                                ))}
                            </tr>
                        </thead>

                        {selectAllPage && !disableSelectionDescription && (
                            <Div className={'PTSAllSelect'}>
                                <Span className={'PTSASText'}>
                                    {selectAll ? totalResults : selectedIds.length} {title}{' '}
                                    {selectedIds.length === 1 ? 'is' : 'are'} selected
                                </Span>
                                {selectAll ? (
                                    <Button
                                        buttonClass={'PTSASBtn'}
                                        onClick={() => {
                                            onClearSelection(!selectAll);
                                            setSelectAllPage(false);
                                        }}
                                    >
                                        Clear selection
                                    </Button>
                                ) : (
                                    <Button
                                        buttonClass={'PTSASBtn'}
                                        onClick={() => {
                                            handlAllSelect(!selectAll);
                                        }}
                                    >
                                        Select all {formatNumberString(totalResults)} {title}
                                    </Button>
                                )}
                                {defaultDelete === true ? (
                                    <Button
                                        iconName={<DCTrash />}
                                        buttonClass={'PTSBtn'}
                                        onClick={() => {
                                            handleOnDelete(selectedIds);
                                        }}
                                    >
                                        Delete
                                    </Button>
                                ) : null}
                                {bulkAssign && (
                                    <Button
                                        iconName={<DCUserPlus />}
                                        buttonClass={'PTSASBtn'}
                                        onClick={() => setOpenBulkDialog('owner')}
                                    >
                                        Assign Owner
                                    </Button>
                                )}
                                {bulkFollower && (
                                    <Button
                                        iconName={<DCAddIcon />}
                                        buttonClass={'PTSASBtn'}
                                        onClick={() => setOpenBulkDialog('followers')}
                                    >
                                        Add Followers
                                    </Button>
                                )}
                                {bulkAddTag && (
                                    <Button
                                        iconName={<DCAddIcon />}
                                        buttonClass={'PTSASBtn'}
                                        onClick={() => {
                                            if (!paywallCheckTags()) setOpenBulkDialog('addTags');
                                        }}
                                    >
                                        Add Tags
                                    </Button>
                                )}
                                {bulkRemoveTag && (
                                    <Button
                                        iconName={<DCMinusIcon />}
                                        buttonClass={'PTSASBtn'}
                                        onClick={() => {
                                            if (!paywallCheckTags())
                                                setOpenBulkDialog('removeTags');
                                        }}
                                    >
                                        Remove Tags
                                    </Button>
                                )}
                            </Div>
                        )}

                        <tbody className="PageTableBody" ref={infinityScrollRef}>
                            {tableData.map((item, index) => {
                                const ind = selectedIds?.indexOf(item.id);
                                return (
                                    <tr
                                        key={index}
                                        className={`${hoveredRow === index ? 'isHovered' : ''} ${
                                            selectOnRowClick === true
                                                ? singleSelectedId === item.id
                                                    ? 'selected'
                                                    : ''
                                                : ''
                                        } `}
                                        onMouseEnter={() => setHoveredRow(index)}
                                        onMouseLeave={() => setHoveredRow(null)}
                                        onClick={() => {
                                            if (onClickRow && !singleSelection) {
                                                onClickRow(item.id);
                                            } else if (selectOnRowClick) {
                                                onSelectRow(item.id);
                                            }
                                        }}
                                    >
                                        {singleSelection && (
                                            <td
                                                style={{
                                                    width: '48px',
                                                    minWidth: '48px',
                                                }}
                                            >
                                                <Div className="PTHSingleSelect">
                                                    <Checkbox
                                                        onChange={e => {
                                                            onSelectRow(item.id, e.target.checked);

                                                            handlAllSelect && handlAllSelect(false);
                                                        }}
                                                        checked={selectAll || ind !== -1}
                                                        disabled={disabledCheckboxes.includes(
                                                            item.id,
                                                        )}
                                                    />
                                                </Div>
                                            </td>
                                        )}
                                        {tableColumns.map((col, i) => (
                                            <td
                                                key={i}
                                                style={{ width: `${col.width}px` }}
                                                className={'TableCol' + (i + 1)}
                                            >
                                                {item[col.accessor]}
                                            </td>
                                        ))}
                                    </tr>
                                );
                            })}
                        </tbody>
                    </table>
                )}

                {loading === false && tableData.length === 0 && noData && (
                    <Div className={'PTBEmpty'}>
                        <NoData
                            icon={
                                hasInitialData ? (
                                    <DCNoResultFound />
                                ) : noData.icon ? (
                                    noData.icon
                                ) : (
                                    <DCNoDataCommon />
                                )
                            }
                            title={noData.title}
                            description={
                                hasInitialData
                                    ? noData.description?.replace(
                                          'have been added yet.',
                                          'were found for your search.',
                                      )
                                    : noData.description
                            }
                            button={
                                noData.buttonTitle && (
                                    <Button
                                        buttonType={'BlueFillBtn'}
                                        iconName={<DCAddIcon />}
                                        onClick={noData.buttonHandle}
                                    >
                                        {hasInitialData
                                            ? noData.buttonTitle?.replace('the first ', '')
                                            : noData.buttonTitle}
                                    </Button>
                                )
                            }
                        />
                    </Div>
                )}
            </Div>
        </Fragment>
    );
};

DCTable.propTypes = {
    customClass: PropTypes.string,
    title: PropTypes.string,
    columns: PropTypes.array.isRequired,
    data: PropTypes.func.isRequired,
    onSort: PropTypes.func,
    onClickRow: PropTypes.func,
    onSelectRow: PropTypes.func,
    selectedIds: PropTypes.array,
    checkboxes: PropTypes.bool.isRequired,
    selectOnRowClick: PropTypes.bool.isRequired,
    selectWholePage: PropTypes.bool,
    selectAll: PropTypes.bool,
    handlAllSelect: PropTypes.func,
    handleOnDelete: PropTypes.func,
    totalResults: PropTypes.number,
    onClearSelection: PropTypes.func,
    showAddBtn: PropTypes.bool,
    singleSelectedId: PropTypes.string,
    loader: PropTypes.bool.isRequired,
    openBulkDialog: PropTypes.bool,
    setOpenBulkDialog: PropTypes.func,
    loadingBulkAction: PropTypes.bool,
    bulkAssign: PropTypes.bool,
    bulkFollower: PropTypes.bool,
    bulkAddTag: PropTypes.bool,
    bulkRemoveTag: PropTypes.bool,
    handleBulkAction: PropTypes.func,
    noData: PropTypes.any,
    onChangeSelectWholePage: PropTypes.func,
    sortBy: PropTypes.any,
    deleteButton: PropTypes.bool,
    paywallCheckTags: PropTypes.func,
};

export default DCTable;
