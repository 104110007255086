import React from 'react';

import { SVGIcon } from '../../UIKit/index';

export default function DCInstaPage(props) {
    return (
        <SVGIcon {...props} viewBox="0 0 484 512">
            <path
                fill="#0D1A2B"
                d="M114.33,458V48.39c-0.03-3.85-2.9-7.08-6.72-7.56l-24.79-3.36  c-2.24-0.3-4.51,0.3-6.3,1.68c-1.7,1.51-2.76,3.62-2.94,5.88v416.33c0.01,2.22,0.91,4.33,2.5,5.88c1.77,1.42,4.06,2.03,6.3,1.68  l24.79-3.78c3.91,0.04,7.12-3.09,7.16-7C114.33,458.1,114.33,458.05,114.33,458z M41.23,419.32V84.91  c-0.07-3.99-3.17-7.26-7.14-7.56L9.3,74.86c-2.11-0.3-4.25,0.31-5.88,1.68c-1.72,1.45-2.65,3.64-2.5,5.88v339.88  c0.01,2.22,0.91,4.33,2.5,5.88c1.64,1.37,3.77,1.98,5.88,1.68l24.79-2.94c4.04-0.16,7.21-3.52,7.14-7.56V419.32z M478.18,51.75  L155.95,0.08c-2.24-0.3-4.51,0.3-6.3,1.68c-1.85,1.39-2.94,3.57-2.94,5.88v495.74c0.19,2.27,1.24,4.37,2.94,5.88  c1.79,1.37,4.06,1.98,6.3,1.68l322.23-55.87c3.73-0.53,6.45-3.8,6.3-7.56V58.9C484.48,55.27,481.78,52.21,478.18,51.75z   M443.73,412.22c-0.03,3.85-2.9,7.08-6.72,7.56l-227.29,31.93c-2.24,0.3-4.51-0.3-6.3-1.68c-1.72-1.45-2.65-3.64-2.5-5.88V71.92  c0.01-2.22,0.91-4.33,2.5-5.88c1.64-1.37,3.77-1.98,5.88-1.68l227.29,27.73c3.82,0.49,6.68,3.72,6.72,7.56L443.73,412.22z"
            />
        </SVGIcon>
    );
}
