import React from 'react';

import { SVGIcon } from '../../UIKit/index';

export default function DCNoCampaign(props) {
    return (
        <SVGIcon {...props} viewBox="0 0 305.918 220.912">
            <g transform="translate(-118.041 -637.55)">
                <g transform="translate(0 -18)">
                    <g>
                        <g
                            transform="translate(162 671)"
                            fill="#fff"
                            stroke="#405d63"
                            strokeWidth="2"
                        >
                            <rect width="233" height="53" rx="8" stroke="none" />
                            <rect x="1" y="1" width="231" height="51" rx="7" fill="none" />
                        </g>
                        <g transform="translate(1)">
                            <g transform="translate(0 11)">
                                <rect
                                    width="36"
                                    height="4"
                                    rx="2"
                                    transform="translate(188 680)"
                                    fill="#405d63"
                                />
                                <rect
                                    width="58"
                                    height="3"
                                    rx="1.5"
                                    transform="translate(188 691)"
                                    fill="#405d63"
                                />
                            </g>
                            <g transform="translate(118 11)">
                                <rect
                                    width="36"
                                    height="4"
                                    rx="2"
                                    transform="translate(188 680)"
                                    fill="#405d63"
                                />
                                <rect
                                    width="58"
                                    height="3"
                                    rx="1.5"
                                    transform="translate(188 691)"
                                    fill="#405d63"
                                />
                            </g>
                        </g>
                        <g transform="translate(8 -56)">
                            <circle
                                cx="2"
                                cy="2"
                                r="2"
                                transform="translate(269 735)"
                                fill="#d4d4d4"
                            />
                            <circle
                                cx="2"
                                cy="2"
                                r="2"
                                transform="translate(269 746)"
                                fill="#d4d4d4"
                            />
                            <circle
                                cx="2"
                                cy="2"
                                r="2"
                                transform="translate(269 757)"
                                fill="#d4d4d4"
                            />
                            <circle
                                cx="2"
                                cy="2"
                                r="2"
                                transform="translate(269 768)"
                                fill="#d4d4d4"
                            />
                        </g>
                    </g>
                </g>
                <g transform="translate(0 45)">
                    <g
                        transform="translate(162 671)"
                        fill="#f5f5f5"
                        stroke="#405d63"
                        strokeWidth="2"
                    >
                        <rect width="233" height="53" rx="8" stroke="none" />
                        <rect x="1" y="1" width="231" height="51" rx="7" fill="none" />
                    </g>
                    <g transform="translate(8 -56)">
                        <circle cx="2" cy="2" r="2" transform="translate(269 735)" fill="#d4d4d4" />
                        <circle cx="2" cy="2" r="2" transform="translate(269 746)" fill="#d4d4d4" />
                        <circle cx="2" cy="2" r="2" transform="translate(269 757)" fill="#d4d4d4" />
                        <circle cx="2" cy="2" r="2" transform="translate(269 768)" fill="#d4d4d4" />
                    </g>
                    <g transform="translate(1)">
                        <g transform="translate(0 11)">
                            <rect
                                width="36"
                                height="4"
                                rx="2"
                                transform="translate(188 680)"
                                fill="#405d63"
                            />
                            <rect
                                width="58"
                                height="3"
                                rx="1.5"
                                transform="translate(188 691)"
                                fill="#405d63"
                            />
                        </g>
                        <g transform="translate(118 11)">
                            <rect
                                width="36"
                                height="4"
                                rx="2"
                                transform="translate(188 680)"
                                fill="#405d63"
                            />
                            <rect
                                width="58"
                                height="3"
                                rx="1.5"
                                transform="translate(188 691)"
                                fill="#405d63"
                            />
                        </g>
                    </g>
                </g>
                <g transform="translate(0 108)">
                    <ellipse
                        cx="117.33"
                        cy="27.966"
                        rx="117.33"
                        ry="27.966"
                        transform="translate(159.983 686.199)"
                        fill="#f5f5f5"
                    />
                    <g transform="translate(162 671)" fill="#fff" stroke="#405d63" strokeWidth="2">
                        <rect width="233" height="53" rx="8" stroke="none" />
                        <rect x="1" y="1" width="231" height="51" rx="7" fill="none" />
                    </g>
                    <g transform="translate(8 -56)">
                        <circle cx="2" cy="2" r="2" transform="translate(269 735)" fill="#d4d4d4" />
                        <circle cx="2" cy="2" r="2" transform="translate(269 746)" fill="#d4d4d4" />
                        <circle cx="2" cy="2" r="2" transform="translate(269 757)" fill="#d4d4d4" />
                        <circle cx="2" cy="2" r="2" transform="translate(269 768)" fill="#d4d4d4" />
                    </g>
                    <g transform="translate(3)">
                        <g transform="translate(0 11)">
                            <rect
                                width="36"
                                height="4"
                                rx="2"
                                transform="translate(188 680)"
                                fill="#405d63"
                            />
                            <rect
                                width="58"
                                height="3"
                                rx="1.5"
                                transform="translate(188 691)"
                                fill="#405d63"
                            />
                        </g>
                        <g transform="translate(118 11)">
                            <rect
                                width="36"
                                height="4"
                                rx="2"
                                transform="translate(188 680)"
                                fill="#405d63"
                            />
                            <rect
                                width="58"
                                height="3"
                                rx="1.5"
                                transform="translate(188 691)"
                                fill="#405d63"
                            />
                        </g>
                    </g>
                </g>
                <g transform="translate(413.99 664.462) rotate(180)" opacity="0.5">
                    <rect
                        width="3.634"
                        height="21.949"
                        rx="1.817"
                        transform="translate(21.949 9.158) rotate(90)"
                        fill="#c3c5c4"
                    />
                    <rect
                        width="3.634"
                        height="21.949"
                        rx="1.817"
                        transform="translate(12.791 21.949) rotate(180)"
                        fill="#c3c5c4"
                    />
                </g>
                <g
                    transform="translate(423.959 699.488) rotate(180)"
                    fill="#fff"
                    stroke="#c3c5c4"
                    strokeWidth="1.6"
                    opacity="0.5"
                >
                    <circle cx="9.969" cy="9.969" r="9.969" stroke="none" />
                    <circle cx="9.969" cy="9.969" r="9.169" fill="none" />
                </g>
                <g transform="translate(-3 50)">
                    <g transform="translate(142.99 808.461) rotate(180)" opacity="0.5">
                        <rect
                            width="3.634"
                            height="21.949"
                            rx="1.817"
                            transform="translate(21.949 9.158) rotate(90)"
                            fill="#c3c5c4"
                        />
                        <rect
                            width="3.634"
                            height="21.949"
                            rx="1.817"
                            transform="translate(12.791 21.949) rotate(180)"
                            fill="#c3c5c4"
                        />
                    </g>
                    <g
                        transform="translate(152.959 776.488) rotate(180)"
                        fill="#fff"
                        stroke="#c3c5c4"
                        strokeWidth="1.6"
                        opacity="0.5"
                    >
                        <circle cx="9.969" cy="9.969" r="9.969" stroke="none" />
                        <circle cx="9.969" cy="9.969" r="9.169" fill="none" />
                    </g>
                </g>
                <g
                    transform="translate(177.938 653.487) rotate(180)"
                    fill="#fff"
                    stroke="#c3c5c4"
                    strokeWidth="1.6"
                    opacity="0.5"
                >
                    <circle cx="7.969" cy="7.969" r="7.969" stroke="none" />
                    <circle cx="7.969" cy="7.969" r="7.169" fill="none" />
                </g>
            </g>
        </SVGIcon>
    );
}
