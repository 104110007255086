import React from 'react';

import { SVGIcon } from '../../UIKit/index';

export default function DCEducation(props) {
    return (
        <SVGIcon {...props} viewBox="0 0 426 512">
            <g transform="translate(-49.7 -271.639)">
                <path fill="#D5E5FF" d="M258.4,384.2V540h-88.5V378.2L258.4,384.2z" />
                <g transform="translate(4.751 271.939)">
                    <g transform="translate(45.249)">
                        <g>
                            <path
                                fill="#005EFF"
                                stroke="#FFFFFF"
                                strokeWidth="7.8223"
                                d="M398.2,86.1h-30.1V24.8c0-10-8.1-18-18-18.1c0,0,0,0,0,0H64.9C33,6.7,7.2,32.6,7.1,64.5v381.5      c0,31.9,25.9,57.7,57.8,57.8h333.3c10,0,18.1-8.1,18.1-18.1l0,0V104.2C416.3,94.2,408.2,86.1,398.2,86.1      C398.2,86.1,398.2,86.1,398.2,86.1z M64.9,42.8H332v43.3H64.9c-11.7,0.3-21.4-9-21.7-20.7c0-0.3,0-0.6,0-0.9      C43.4,52.6,53,43,64.9,42.8z M380.2,467.6H64.9c-11.9-0.2-21.5-9.8-21.7-21.7V118.2c6.9,2.7,14.2,4.1,21.7,4.1h315.3V467.6z"
                            />
                        </g>
                    </g>
                    <g transform="translate(52.264 7.523)">
                        <g>
                            <path
                                fill="#005EFF"
                                d="M185.4,97.3v149.2l-19.6-11.7c-5.7-3.5-12.9-3.5-18.6,0l-19.6,11.7V97.3H91.6v181.1      c0,10,8.1,18.1,18.1,18.1c3.3,0,6.5-0.9,9.3-2.6l37.6-22.7l37.6,22.7c8.5,5.2,19.6,2.4,24.8-6.1c1.7-2.8,2.6-6.1,2.6-9.4V97.3      H185.4z"
                            />
                        </g>
                    </g>
                </g>
                <g transform="translate(60 281)">
                    <path fill="#FFFFFF" d="M175.8,122.2l-44.5-0.8v-5.6h44.7L175.8,122.2z" />
                    <path fill="#D5E5FF" d="M124.8,109.2h58.1l-0.8,19.6l-57.2-1V109.2z" />
                </g>
            </g>
        </SVGIcon>
    );
}
