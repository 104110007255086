import React from 'react';

import { SVGIcon } from '../../UIKit/index';

export default function DCManychat(props) {
    return (
        <SVGIcon {...props} viewBox="0 0 512 494">
            <g transform="translate(-117.587 -107.329)">
                <path
                    fill="#1E64F3"
                    d="M495.77,574.89c0,0-98.12-4.8-110.38-94.92c0,0-2.13-15.2-21.09-9.87c0,0-5.87,1.6-6.66,17.6   c0,0-9.06,75.81-111.45,87.19c0,0-19.91,12.44,0,25.6c0,0,75.01,7.46,124.18-68.81h2.02c0,0,43.02,68.81,123.35,68.98   c7.43-1,12.76-7.67,12.09-15.13C506.35,579.86,501.58,575.65,495.77,574.89z"
                />
                <path
                    fill="#1E64F3"
                    d="M235.91,431.27c0,0,12.44-54.75-52.62-47.28c0,0-34.84,7.46-38.39,51.9   c0,0-7.82,67.19,72.52,80.69c0,0,89.94,4.27,93.5-86.74c1.76-17.36-2.51-34.8-12.09-49.39c0,0-28.62-34.83-40.88-48.7   c-22.52-32.68-31.33-72.88-24.53-111.98c0,0,11.73-86.92,110.38-108.76c33.17-6.31,67.49-1.43,97.58,13.87l3.73,1.07l63.19-13.32   c0,0,14.93-3.2,13.06,19.73l-16.8,60.79c0,0,38.13,72.52-28.7,151.44c0,0-35.55,38.39-39.1,59.72   c-3.56,21.33-9.95,85.32,60.43,108.07c70.39,22.75,113.75-45.5,105.94-78.92c-7.82-33.42-25.24-45.15-44.44-50.5   c0,0-44.53-7.73-49.59,33.33c0,0-1.6,12,2.66,16.8c0,0,3.73,24.53-21.33,16.53c0,0-26.31-32.18,7.11-71.99   c25.78-26.07,66.54-29.87,96.69-9.01c24.78,18.96,37.97,49.41,34.84,80.46c0,0-4.8,83.72-98.12,93.85   c0,0-109.32,8.53-124.25-107.71c-1.22-9.38-1.22-18.88,0-28.26c2.01-15.79,11.2-44.26,28.79-55.46c0,0,83.05-72.44,42.48-150.55   c-1.18-5.14-1.3-10.46-0.36-15.64c1.66-7.77,10.67-39.46,10.67-39.46s-39.47,7-44.44,8.17c-2.67,0.74-5.5,0.74-8.17,0   c0,0-52.45-38.22-121.76-3.02c0,0-50.66,25.06-55.46,84.25c0,0-4.27,47.99,26.64,85.85c0,0,55.46,53.86,53.33,91.45   c0,0,1.99,6.75-0.27,34.93c-2.26,28.18-15.91,85.07-104.78,95.59c0,0-103.81,6.26-115.89-94.7c0,0-4.99-100.25,95.27-90.29   c0,0,63.26,9.95,48.35,79.63c0,0,1.78,13.15-15.64,13.51C245.5,451.18,232,451.89,235.91,431.27z"
                />
                <path
                    fill="#1E64F3"
                    d="M345.4,329.6h52.25c0,0,7.11,13.87-16,16.35h-25.24C356.41,345.95,340.77,341.33,345.4,329.6z"
                />
                <circle fill="#1E64F3" cx="325" cy="285.21" r="12.66" />
                <circle fill="#1E64F3" cx="420.27" cy="285.21" r="12.66" />
            </g>
        </SVGIcon>
    );
}
