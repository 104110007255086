import React from 'react';

import { SVGIcon } from '../../UIKit/index';

export default function DCGrayCommissions(props) {
    return (
        <SVGIcon {...props} viewBox="0 0 455.58 495.74">
            <defs>
                <clipPath id="clip-path">
                    <rect
                        id="Rectangle_113"
                        width="325.35"
                        height="321.3"
                        transform="translate(106.9 40.93)"
                        fill="none"
                    />
                </clipPath>
            </defs>
            <g id="Woman_investing" transform="translate(-30.07 -7.87)">
                <circle
                    id="Ellipse_9"
                    cx="226.29"
                    cy="226.29"
                    r="226.29"
                    transform="translate(31.57 9.37)"
                    fill="#fff"
                    stroke="#707171"
                    strokeMiterlimit="10"
                    strokeWidth="3"
                />
                <circle
                    id="Ellipse_10"
                    cx="8.89"
                    cy="8.89"
                    r="8.89"
                    transform="translate(427.22 149.81)"
                    fill="#fff"
                    stroke="#e2e2e2"
                    strokeMiterlimit="10"
                    strokeWidth="2"
                />
                <circle
                    id="Ellipse_11"
                    cx="13.7"
                    cy="13.7"
                    r="13.7"
                    transform="translate(434.66 269.85)"
                    fill="#fff"
                    stroke="#e2e2e2"
                    strokeMiterlimit="10"
                    strokeWidth="2"
                />
                <circle
                    id="Ellipse_12"
                    cx="18.26"
                    cy="18.26"
                    r="18.26"
                    transform="translate(186.97 388.12)"
                    fill="#f4f5f6"
                />
                <circle
                    id="Ellipse_13"
                    cx="11.65"
                    cy="11.65"
                    r="11.65"
                    transform="translate(214.97 407.01)"
                    fill="#e3e2e3"
                />
                <path
                    id="Path_120"
                    d="M104.66,129.88h-7v-6.94a.87.87,0,0,0-.86-.87h0a.89.89,0,0,0-.89.87v6.94H89a.89.89,0,0,0-.87.88.87.87,0,0,0,.87.87h7v7a.89.89,0,0,0,.89.86.86.86,0,0,0,.86-.86h0v-7h7a.88.88,0,1,0,0-1.75Z"
                    fill="#e2e2e2"
                    stroke="#e2e2e2"
                    strokeMiterlimit="10"
                    strokeWidth="0.75"
                />
                <path
                    id="Path_121"
                    d="M75.67,163.71H71.21v-4.46a.56.56,0,0,0-.56-.55h0a.56.56,0,0,0-.56.55v4.46H65.64a.56.56,0,0,0,0,1.12h4.45v4.47a.56.56,0,0,0,.56.55.55.55,0,0,0,.56-.55h0v-4.47h4.46a.56.56,0,0,0,0-1.12Z"
                    fill="#e2e2e2"
                    stroke="#e2e2e2"
                    strokeMiterlimit="10"
                    strokeWidth="0.75"
                />
                <ellipse
                    id="_Path_"
                    cx="153.22"
                    cy="15.04"
                    rx="153.22"
                    ry="15.04"
                    transform="translate(105.68 473.53)"
                    fill="#f4f5f6"
                />
                <path
                    id="Path_122"
                    d="M84.34,362.56S38.92,307.5,57.89,242.78c17-58.08,94-95.67,134.87-66.91,19.78,13.92,35.76-42,101.41-62.49,48.55-15.14,120.34,18.82,102.11,122.81-13.87,79.07,54.9,39.65,39.88,127Z"
                    fill="#f4f5f6"
                />
                <path
                    id="Path_123"
                    d="M426.38,362.33l-49.7.78c-1.79-1.51,8-6.22,20.79-6a27.431,27.431,0,0,1,5.08.6c.46-6.09,3.08-33.45,12.94-32.35,11.09,1.23,0,12.05-3.13,18.46a149.5,149.5,0,0,0-5.9,14.76c.9.19,1.84.38,2.79.56,1.47-4.52,6.94-19.7,13.89-18.81,8.17,1,4.89,7.12-.9,10.73-4.25,2.66-9.46,6.44-11.94,8.26a35.76,35.76,0,0,0,6.51.52C422.52,359.75,425.3,361.43,426.38,362.33Z"
                    fill="#e3e2e3"
                />
                <path
                    id="Path_124"
                    d="M96.23,361.17c-1.18-1.86-4.74-6.67-9.48-6-5.16.7,5.7,4.1,9,6.05a5.67,5.67,0,0,1-1.84-.21c-2.23-.79-6,.73-7.25,1.26h21.16a3.32,3.32,0,0,0-1.51-1.94,3.82,3.82,0,0,0-2-.17c1.26-1.48,2.14-6.22-1.37-5-2.56.89-2.55,3.78-2.3,5.5l-1.64.25c-1-5.37-4.78-21.11-12.34-21.53S93.24,355.66,98,361C97.35,361.09,96.77,361.14,96.23,361.17Z"
                    fill="#e3e2e3"
                />
                <path
                    id="Path_125"
                    d="M184.8,356.65v4a1.54,1.54,0,0,1-.26.87,1.68,1.68,0,0,1-.73.59,2,2,0,0,1-.23.06H118.55a1.54,1.54,0,0,1-1.28-1.45v-4.2a1.56,1.56,0,0,1,1.55-1.48h64.69l.29.08h.16l.07.05.08.06.06.05h0l.11.11h0l.06.08a1.63,1.63,0,0,1,.46,1.18Z"
                    fill="#f4f5f6"
                />
                <path
                    id="Path_126"
                    d="M153.16,362.25H118.49a1.49,1.49,0,0,1-1.22-1.47v-4.2a1.48,1.48,0,0,1,1.39-1.48h30.27a1.162,1.162,0,0,0,0,.18,12.74,12.74,0,0,0,4.13,6.93Z"
                    fill="#707171"
                />
                <rect
                    id="Rectangle_89"
                    width="0.73"
                    height="7.16"
                    transform="translate(176.22 355.09)"
                    fill="#ffa412"
                />
                <rect
                    id="Rectangle_90"
                    width="0.73"
                    height="7.16"
                    transform="translate(173.75 355.09)"
                    fill="#ffa412"
                />
                <rect
                    id="Rectangle_91"
                    width="0.73"
                    height="7.16"
                    transform="translate(170.77 355.09)"
                    fill="#ffa412"
                />
                <rect
                    id="Rectangle_92"
                    width="0.73"
                    height="7.16"
                    transform="translate(166.22 355.09)"
                    fill="#ffa412"
                />
                <rect
                    id="Rectangle_93"
                    width="0.73"
                    height="7.16"
                    transform="translate(161.4 355.09)"
                    fill="#ffa412"
                />
                <rect
                    id="Rectangle_94"
                    width="0.73"
                    height="7.16"
                    transform="translate(154.02 355.09)"
                    fill="#e3e2e3"
                />
                <rect
                    id="Rectangle_95"
                    width="0.73"
                    height="7.16"
                    transform="translate(147.11 355.08)"
                    fill="#e3e2e3"
                />
                <rect
                    id="Rectangle_96"
                    width="0.73"
                    height="7.16"
                    transform="translate(140.93 355.08)"
                    fill="#e3e2e3"
                />
                <rect
                    id="Rectangle_97"
                    width="0.73"
                    height="7.16"
                    transform="translate(178.58 355.09)"
                    fill="#ffa412"
                />
                <rect
                    id="Rectangle_98"
                    width="0.73"
                    height="7.16"
                    transform="translate(180.14 355.09)"
                    fill="#ffa412"
                />
                <rect
                    id="Rectangle_99"
                    width="0.73"
                    height="7.16"
                    transform="translate(181.65 355.09)"
                    fill="#ffa412"
                />
                <path id="Path_127" d="M183.51,355.11v7.12h-.73v-7.16h.46Z" fill="#ffa412" />
                <path
                    id="Path_128"
                    d="M184.53,355.78v5.78a1.68,1.68,0,0,1-.73.59v-7A1.63,1.63,0,0,1,184.53,355.78Z"
                    fill="#ffa412"
                />
                <path
                    id="Path_129"
                    d="M188.12,349.66v4a1.54,1.54,0,0,1-.26.87,1.57,1.57,0,0,1-.74.58,1.289,1.289,0,0,1-.28.08,1.269,1.269,0,0,1-.28,0H122.15a1.6,1.6,0,0,1-.72-.18,1.58,1.58,0,0,1-.83-1.23V349.5a1.55,1.55,0,0,1,1.55-1.47h64.42a1.231,1.231,0,0,1,.27,0,1.568,1.568,0,0,1,.29.08,1.66,1.66,0,0,1,.73.58,1.54,1.54,0,0,1,.26.97Z"
                    fill="#f4f5f6"
                />
                <path
                    id="Path_130"
                    d="M156.49,355.26H122a1.32,1.32,0,0,1-.65-.18,1.61,1.61,0,0,1-.74-1.23v-4.28A1.47,1.47,0,0,1,122,348.1h30.27a12.9,12.9,0,0,0,4,7Z"
                    fill="#707171"
                />
                <rect
                    id="Rectangle_100"
                    width="0.73"
                    height="7.16"
                    transform="translate(179.55 348.11)"
                    fill="#ffa412"
                />
                <rect
                    id="Rectangle_101"
                    width="0.73"
                    height="7.16"
                    transform="translate(177.08 348.1)"
                    fill="#ffa412"
                />
                <rect
                    id="Rectangle_102"
                    width="0.73"
                    height="7.16"
                    transform="translate(174.1 348.1)"
                    fill="#ffa412"
                />
                <rect
                    id="Rectangle_103"
                    width="0.73"
                    height="7.16"
                    transform="translate(169.55 348.1)"
                    fill="#ffa412"
                />
                <rect
                    id="Rectangle_104"
                    width="0.73"
                    height="7.16"
                    transform="translate(164.73 348.1)"
                    fill="#e3e2e3"
                />
                <rect
                    id="Rectangle_105"
                    width="0.73"
                    height="7.16"
                    transform="translate(157.35 348.1)"
                    fill="#e3e2e3"
                />
                <rect
                    id="Rectangle_106"
                    width="0.73"
                    height="7.16"
                    transform="translate(150.43 348.1)"
                    fill="#e3e2e3"
                />
                <rect
                    id="Rectangle_107"
                    width="0.73"
                    height="7.16"
                    transform="translate(144.26 348.1)"
                    fill="#e3e2e3"
                />
                <rect
                    id="Rectangle_108"
                    width="0.73"
                    height="7.16"
                    transform="translate(181.91 348.11)"
                    fill="#ffa412"
                />
                <rect
                    id="Rectangle_109"
                    width="0.73"
                    height="7.16"
                    transform="translate(183.47 348.11)"
                    fill="#ffa412"
                />
                <rect
                    id="Rectangle_110"
                    width="0.73"
                    height="7.16"
                    transform="translate(184.98 348.11)"
                    fill="#ffa412"
                />
                <path
                    id="Path_131"
                    d="M186.84,348.13v7.11a1.271,1.271,0,0,1-.28,0h-.45v-7.16h.46a1.231,1.231,0,0,1,.27.05Z"
                    fill="#ffa412"
                />
                <path
                    id="Path_132"
                    d="M187.86,348.79v5.79a1.57,1.57,0,0,1-.74.58v-7A1.66,1.66,0,0,1,187.86,348.79Z"
                    fill="#ffa412"
                />
                <g id="Group_2" clipPath="url(#clip-path)">
                    <path
                        id="Path_133"
                        d="M256.19,377.48l-1,.34c-4.12-8.62-10.32-14.95-16.33-20.24a48.2,48.2,0,0,0-34.92-11.31,60.288,60.288,0,0,0-13.68,2.64l-.51-1.15c30.54-12.69,48.54,1.14,57.94,13.87a62,62,0,0,1,8.5,15.85Z"
                        fill="#82b378"
                    />
                    <path
                        id="Path_134"
                        d="M290.85,358.79l-34.66,18.69a65.391,65.391,0,0,0-10-14.22,81.255,81.255,0,0,0-6-6.05c-21.23-18.7-50.46-9.45-50.46-9.45L215,321.32c15.41-5.55,29.08-3.06,40.41,2.66C277.58,335.18,290.85,358.79,290.85,358.79Z"
                        fill="#e3e2e3"
                    />
                    <path
                        id="Path_135"
                        d="M290.85,358.79l-34.66,18.69a65.391,65.391,0,0,0-10-14.22,81.255,81.255,0,0,0-6-6.05c-21.23-18.7-37.57-23-37.57-23l16,.3c6,.12,9.87-3.79,14.86-7.08,1.14-.75,2.29-3.75,3.28-4.46,5.72-4.09,11.42-2.63,18.57,1C277.58,335.18,290.85,358.79,290.85,358.79Z"
                        fill="#e3e2e3"
                    />
                    <path
                        id="Path_136"
                        d="M287.47,357.82h0c-3.34-8.32-16-20.4-16.55-20.86a67.74,67.74,0,0,0-20.7-13.25,50.719,50.719,0,0,0-17.49-3.58c-10-.22-16.38,2.75-16.65,2.88h-.09l-20.5,21.08,1.75-.29c11.37-1.91,21.58-1.41,30.35,1.48a43.93,43.93,0,0,1,18.13,11.36,51.8,51.8,0,0,1,11.52,18Zm-31.84,11.37a49.561,49.561,0,0,0-9-13.28A45.08,45.08,0,0,0,228,344.22c-7.67-2.53-16.4-3.27-26-2.22a1.13,1.13,0,0,1-.94-1.91l14.21-14.61a9,9,0,0,1,3.76-2.32,42.83,42.83,0,0,1,13.76-1.87,49.781,49.781,0,0,1,17.07,3.52,66.54,66.54,0,0,1,20.3,13s3.16,2.82,6.65,6.59c4.59,5,7.72,9.33,9.06,12.66l-28.33,16.27A36.319,36.319,0,0,0,255.63,369.19Z"
                        fill="#707171"
                    />
                    <path
                        id="Path_137"
                        d="M255.66,333.79c2.36,5.27.69,9.83-4.57,12.18s-12.13,1.61-14.49-3.65-.84-9.72,4.42-12.07S253.31,328.53,255.66,333.79Z"
                        fill="#707171"
                        opacity="0.3"
                    />
                    <path
                        id="Path_138"
                        d="M223.35,400l-1.11.36c-4.37-9.63-11.08-16.76-17.62-22.75a53.32,53.32,0,0,0-38.38-13.29A67.187,67.187,0,0,0,151.05,367l-.54-1.28c34.07-13.36,53.67,2.34,63.78,16.62A68.34,68.34,0,0,1,223.35,400Z"
                        fill="#82b378"
                    />
                    <path
                        id="Path_139"
                        d="M262.1,380.14,223.35,400a71.66,71.66,0,0,0-10.73-15.94,91.091,91.091,0,0,0-6.49-6.83c-23.08-21.15-55.62-11.57-55.62-11.57L179,337c17.17-5.79,32.22-2.74,44.64,3.83C247.94,353.72,262.1,380.14,262.1,380.14Z"
                        fill="#e3e2e3"
                    />
                    <path
                        id="Path_140"
                        d="M224.57,397c0-.11-3-10.07-12.35-20.15a48.54,48.54,0,0,0-19.8-13c-9.63-3.39-20.91-4.17-33.53-2.31l-1.95.28L180.08,339h.11c.3-.14,7.42-3.28,18.48-2.82a55.93,55.93,0,0,1,19.26,4.35,74.75,74.75,0,0,1,22.61,15.11c.56.51,14.34,14.16,17.84,23.44h0Zm.34-1.44,31.7-17.38c-1.41-3.7-4.78-8.62-9.75-14.21-3.78-4.24-7.17-7.4-7.2-7.43a73.71,73.71,0,0,0-22.17-14.85,55,55,0,0,0-18.81-4.26,47.249,47.249,0,0,0-15.26,1.76,10.13,10.13,0,0,0-4.21,2.48l-16,15.85a1.25,1.25,0,0,0,1,2.13c10.66-1,20.3.07,28.72,3A49.84,49.84,0,0,1,213.21,376a55.45,55.45,0,0,1,9.71,14.89,39.507,39.507,0,0,1,1.99,4.62Z"
                        fill="#707171"
                    />
                    <path
                        id="Path_141"
                        d="M223.73,351.71c2.49,5.87.55,10.88-5.32,13.37s-13.46,1.51-15.95-4.36-.72-10.77,5.15-13.26S221.24,345.83,223.73,351.71Z"
                        fill="#707171"
                        opacity="0.3"
                    />
                    <path
                        id="Path_142"
                        d="M248.59,332.69s-1.93,13.69.28,22.39H216.49s15.49-56.72,47.22-89.19c9.11-9.32,17.48-22.59,25.57-37.18,20.08-36.22,38.35-80.57,61.49-92.67,0,0-45.72,67.17-54.57,105.91S255.79,279.57,248.59,332.69Z"
                        fill="#5a5b5b"
                    />
                    <path
                        id="Path_143"
                        d="M285.29,242a116.663,116.663,0,0,1,4-13.24c-8.09,14.59-16.46,27.86-25.57,37.18a132.437,132.437,0,0,0-13,15.67,108.3,108.3,0,0,0-6.45-25.05c-5-12.45-13.53-25.09-27.8-26.18,0,0,34.54,26.46,17,80.73-11.39,23.45-19.88,44-19.88,44h24.92c-2.22-8.7-.28-22.39-.28-22.39C245.46,279.57,276.44,280.68,285.29,242Z"
                        fill="#707171"
                    />
                    <path
                        id="Path_144"
                        d="M250.74,281.57s0,.08-.13.22c-.9,1.51-6.11,10-5.65,4.8.51-5.75-6-27.4-6-27.4l5.28-2.68a108.351,108.351,0,0,1,6.5,25.06Z"
                        fill="#5a5b5b"
                    />
                    <path
                        id="Path_145"
                        d="M407.33,183.07h0l-1,1.46a83.418,83.418,0,0,0-18.16-6.66A67,67,0,0,0,347.05,181a59.9,59.9,0,0,0-9.23,4.67c-9.42,5.92-19.32,13.22-26.88,24.12l-1.29-.7-35.79-27.48-7.58-5.83a132.558,132.558,0,0,1,13.83-14.25c10.05-9,24.94-20,42.54-25.6a71.26,71.26,0,0,1,32.75-2.81,70.54,70.54,0,0,1,23.25,8.15l28.69,41.85Z"
                        fill="#e3e2e3"
                    />
                    <path
                        id="Path_146"
                        d="M238.44,398.76l1,.35c4.12-8.62,10.32-14.95,16.33-20.24a48.21,48.21,0,0,1,34.93-11.32,60.591,60.591,0,0,1,13.67,2.65l.52-1.15c-30.55-12.69-48.55,1.14-57.94,13.87a61.879,61.879,0,0,0-8.51,15.84Z"
                        fill="#82b378"
                    />
                    <path
                        id="Path_147"
                        d="M203.78,380.08l34.66,18.68a65.56,65.56,0,0,1,10-14.21,81.249,81.249,0,0,1,6-6.05c21.23-18.7,50.47-9.45,50.47-9.45l-25.22-26.44c-15.41-5.55-29.07-3.07-40.41,2.65C217.06,356.47,203.78,380.08,203.78,380.08Z"
                        fill="#e3e2e3"
                    />
                    <path
                        id="Path_148"
                        d="M207.16,379.11h0c3.34-8.32,16-20.4,16.55-20.86A67.859,67.859,0,0,1,244.41,345a50.771,50.771,0,0,1,17.49-3.58c10-.22,16.38,2.75,16.65,2.88h.1l20.5,21.08-1.76-.29c-11.37-1.91-21.58-1.41-30.34,1.48A43.8,43.8,0,0,0,248.92,378a51.441,51.441,0,0,0-11.52,18ZM239,390.47a49.93,49.93,0,0,1,9-13.27,45.07,45.07,0,0,1,18.61-11.69c7.66-2.53,16.39-3.27,26-2.22a1.13,1.13,0,0,0,.94-1.91l-14.21-14.61a9,9,0,0,0-3.76-2.32,42.831,42.831,0,0,0-13.76-1.87,49.49,49.49,0,0,0-17.06,3.52,66.85,66.85,0,0,0-20.31,13s-3.15,2.82-6.64,6.59c-4.6,5-7.73,9.34-9.07,12.67l28.33,16.27A38.131,38.131,0,0,1,239,390.47Z"
                        fill="#707171"
                    />
                    <path
                        id="Path_149"
                        d="M239,355.08c-2.35,5.26-.69,9.82,4.57,12.18s12.14,1.61,14.49-3.66.85-9.71-4.42-12.06S241.32,349.82,239,355.08Z"
                        fill="#707171"
                        opacity="0.3"
                    />
                    <path
                        id="Path_150"
                        d="M318.34,392.46l-1,.35c-4.12-8.62-10.32-14.95-16.33-20.24a48.21,48.21,0,0,0-34.93-11.32,60.588,60.588,0,0,0-13.67,2.65l-.52-1.15c30.55-12.69,48.55,1.14,57.94,13.87a61.88,61.88,0,0,1,8.51,15.84Z"
                        fill="#82b378"
                    />
                    <path
                        id="Path_151"
                        d="M353,373.78l-34.66,18.68a65.32,65.32,0,0,0-10-14.21,81.247,81.247,0,0,0-6-6.05c-21.23-18.7-50.46-9.45-50.46-9.45l25.21-26.44c15.41-5.55,29.07-3.07,40.41,2.65C339.73,350.17,353,373.78,353,373.78Z"
                        fill="#e3e2e3"
                    />
                    <path
                        id="Path_152"
                        d="M349.62,372.81h0c-3.34-8.32-16-20.41-16.55-20.86a67.74,67.74,0,0,0-20.7-13.25,50.78,50.78,0,0,0-17.49-3.59c-10-.21-16.38,2.76-16.65,2.89h-.1l-20.49,21.08,1.75-.3c11.37-1.9,21.58-1.4,30.34,1.49a43.8,43.8,0,0,1,18.13,11.36,51.34,51.34,0,0,1,11.52,18Zm-31.84,11.36a49.929,49.929,0,0,0-9-13.27,45.07,45.07,0,0,0-18.61-11.69c-7.66-2.53-16.39-3.27-26-2.22a1.13,1.13,0,0,1-.94-1.91l14.21-14.61a9,9,0,0,1,3.76-2.32,42.83,42.83,0,0,1,13.76-1.87A49.49,49.49,0,0,1,312,339.8a66.849,66.849,0,0,1,20.31,13s3.15,2.82,6.64,6.59c4.6,5,7.73,9.34,9.07,12.67l-28.33,16.27a38.123,38.123,0,0,0-1.91-4.16Z"
                        fill="#707171"
                    />
                    <path
                        id="Path_153"
                        d="M317.81,348.78c2.35,5.26.69,9.82-4.57,12.18s-12.14,1.61-14.49-3.66-.85-9.71,4.42-12.06S315.46,343.52,317.81,348.78Z"
                        fill="#707171"
                        opacity="0.3"
                    />
                    <path
                        id="Path_154"
                        d="M347.05,181a59.9,59.9,0,0,0-9.23,4.67c-9.42,5.92-19.32,13.22-26.88,24.12l-1.29-.7-35.79-27.48a174.53,174.53,0,0,1,6.25-20.08c10.05-9,24.94-20,42.54-25.6a71.26,71.26,0,0,1,32.75-2.81c.39.25.59.4.59.4S338,155.33,347.05,181Z"
                        fill="#707171"
                        opacity="0.3"
                    />
                    <path
                        id="Path_155"
                        d="M300.74,170.33l-54.34,6.22-1.46-.07a69.66,69.66,0,0,0-2.4-14A108.629,108.629,0,0,0,234.31,142a61.84,61.84,0,0,0-8.63-12.62,66.62,66.62,0,0,0-30.57-20,83.619,83.619,0,0,0-19-3.65L176,104h0l46.14-21.1a68.64,68.64,0,0,1,50.75,24.26c10.55,12,17.29,26.35,21.48,38.38A140.272,140.272,0,0,1,300.74,170.33Z"
                        fill="#e3e2e3"
                    />
                    <path
                        id="Path_156"
                        d="M294.42,145.51c-.78,10.09-5.92,18.33-16.54,21.33-11.6,3.28-24.4.68-35.34-4.4A108.63,108.63,0,0,0,234.31,142a61.84,61.84,0,0,0-8.63-12.62c24-5.61,19.79-41.81,19.79-41.81a70.93,70.93,0,0,1,27.47,19.6C283.49,119.09,290.23,133.48,294.42,145.51Z"
                        fill="#707171"
                        opacity="0.3"
                    />
                    <path
                        id="Path_157"
                        d="M230.5,139.27s-1-10.29-20.22-6.13a20.43,20.43,0,0,0,20.22,6.13Z"
                        fill="#e3e2e3"
                    />
                    <path
                        id="Path_158"
                        d="M378.92,238.77h0l-28.69-41.86c-19.9-11-39.18-10.66-56-5.33-32.94,10.42-56.38,39.85-56.38,39.85l43.37,33.31h0l1.29.7c7.56-10.91,17.46-18.2,26.88-24.12a67,67,0,0,1,50.4-7.76A83.619,83.619,0,0,1,378,240.24l1-1.46Z"
                        fill="#e3e2e3"
                    />
                    <path
                        id="Path_159"
                        d="M167.35,191.76h0L196,149.92c19.89-11,39.18-10.66,56-5.34,33,10.42,56.4,39.85,56.4,39.85L265,217.74h0l-1.29.7c-7.57-10.9-17.47-18.2-26.89-24.12a66.94,66.94,0,0,0-50.39-7.75,83.352,83.352,0,0,0-18.17,6.66l-.95-1.46Z"
                        fill="#e3e2e3"
                    />
                    <path
                        id="Path_160"
                        d="M78.8,347.42h.07C78.94,347.42,78.8,347.4,78.8,347.42Z"
                        fill="#ffb973"
                    />
                    <path
                        id="Path_161"
                        d="M260.87,169.85l-1.29-.68c4.82-12.37,5.34-24.66,5-35.78a67,67,0,0,0-21.71-46.13A84.229,84.229,0,0,0,227.2,75.91l.67-1.61c41.88,18.89,45,50.27,41,71.88A85.89,85.89,0,0,1,260.87,169.85Z"
                        fill="#5a5b5b"
                    />
                    <path
                        id="Path_162"
                        d="M312.73,187.22l-51.86-17.38c3-7.85,4.33-16.71,4.92-23.61a113.178,113.178,0,0,0,.44-11.82C265,95.13,227.86,74.3,227.86,74.3l50.74.43c20.26,10.34,30.73,26.55,35.7,43.46C324.05,151.33,312.73,187.22,312.73,187.22Z"
                        fill="#e3e2e3"
                    />
                    <path
                        id="Path_163"
                        d="M264.72,168.24c.08-.12,6.38-11.57,7.27-28.76a60.91,60.91,0,0,0-5.71-29.16C260.88,98.7,251.69,87.88,239,78.17l-2-1.5,40.83.75h.13c.39.15,9.45,3.79,18.74,14.13a70.71,70.71,0,0,1,13,21.12,94.13,94.13,0,0,1,6.24,33.56c0,.95-.16,25.3-5.42,36.59h0Zm1.6-1,43.39,13.24c2.1-4.51,3.56-11.85,4.22-21.21.51-7.12.37-12.94.37-13a92.64,92.64,0,0,0-6.1-32.93,68.94,68.94,0,0,0-12.65-20.63,59.64,59.64,0,0,0-15-12.17,12.67,12.67,0,0,0-5.92-1.6l-28.3-.52a1.57,1.57,0,0,0-1,2.77c10.19,8.74,17.73,18.29,22.45,28.45a62.74,62.74,0,0,1,5.84,30,69,69,0,0,1-4.87,21.76,55.383,55.383,0,0,1-2.43,5.88Z"
                        fill="#707171"
                    />
                    <path
                        id="Path_164"
                        d="M294.31,109.61c-7.38-3.09-13.33-.39-16.42,7s2.68,15.05,10.06,18.15,13.58.45,16.68-6.94S301.7,112.71,294.31,109.61Z"
                        fill="#707171"
                        opacity="0.3"
                    />
                    <path
                        id="Path_165"
                        d="M233.9,225.1l-1.39.46c-5.48-12.09-13.91-21-22.11-28.56a66.93,66.93,0,0,0-48.18-16.68,83.56,83.56,0,0,0-19.07,3.29l-.68-1.61c42.77-16.77,67.37,2.93,80.07,20.86A86.1,86.1,0,0,1,233.9,225.1Z"
                        fill="#5a5b5b"
                    />
                    <path
                        id="Path_166"
                        d="M282.56,200.12l-48.66,25c-3.53-7.62-8.94-14.77-13.46-20a113.422,113.422,0,0,0-8.16-8.57C183.32,170,142.47,182,142.47,182l35.74-36c21.56-7.27,40.46-3.44,56,4.81C264.78,167,282.56,200.12,282.56,200.12Z"
                        fill="#e3e2e3"
                    />
                    <path
                        id="Path_167"
                        d="M235.44,221.23c0-.14-3.82-12.65-15.5-25.29a61,61,0,0,0-24.86-16.28c-12.09-4.25-26.25-5.23-42.1-2.9l-2.44.36,29.05-28.7.13-.06c.38-.17,9.32-4.11,23.2-3.54a70.59,70.59,0,0,1,24.19,5.46,94.15,94.15,0,0,1,28.37,19c.7.65,18,17.78,22.4,29.43h0Zm.43-1.82,39.78-21.81c-1.76-4.65-6-10.82-12.23-17.83-4.74-5.33-9-9.3-9-9.34a92.63,92.63,0,0,0-27.84-18.63,68.85,68.85,0,0,0-23.6-5.36,59.43,59.43,0,0,0-19.16,2.22,12.5,12.5,0,0,0-5.28,3.11l-20.13,19.89a1.58,1.58,0,0,0,1.25,2.69c13.38-1.2,25.48.07,36,3.8a62.64,62.64,0,0,1,25.52,16.74,69.131,69.131,0,0,1,12.18,18.69c1.64,3.71,2.13,4.42,2.51,5.83Z"
                        fill="#707171"
                    />
                    <path
                        id="Path_168"
                        d="M234.38,164.43c-3.12-7.37-12.86-8.45-20.23-5.33s-9.59,9.27-6.47,16.64,12.65,8.6,20,5.47S237.51,171.81,234.38,164.43Z"
                        fill="#707171"
                        opacity="0.3"
                    />
                    <path
                        id="Path_169"
                        d="M314.6,187.2l1.47.09c2.23-13.08,8.12-23.89,14.15-33.24a66.9,66.9,0,0,1,42.37-28.35,83.809,83.809,0,0,1,19.27-1.7l.25-1.73c-45.62-5.37-64.42,19.93-72.15,40.5a85.89,85.89,0,0,0-5.36,24.43Z"
                        fill="#5a5b5b"
                    />
                    <path
                        id="Path_170"
                        d="M261.21,175.38,314.6,187.2c1.49-8.27,4.91-16.56,7.95-22.77a113.9,113.9,0,0,1,5.72-10.36c21.28-33,63.84-31.76,63.84-31.76L348.4,96.54c-22.69-1.57-40,6.93-53,18.87C270,138.79,261.21,175.38,261.21,175.38Z"
                        fill="#e3e2e3"
                    />
                    <path
                        id="Path_171"
                        d="M265.36,172.8h0c1.3-12.39,13.69-33.34,14.2-34.15A94.27,94.27,0,0,1,302.2,113.1a70.52,70.52,0,0,1,22-11.41c13.28-4.08,22.93-2.53,23.34-2.46h.14l35.38,20.39-2.46.27c-15.91,1.77-29.36,6.31-40,13.48a61,61,0,0,0-19.91,22.06c-8.1,15.19-8.59,28.25-8.58,28.39Zm46.85,3.11a69.159,69.159,0,0,1,7-21.16,62.691,62.691,0,0,1,20.43-22.67c9.28-6.28,20.66-10.58,33.91-12.82a1.57,1.57,0,0,0,.52-2.91L349.6,102.22a12.54,12.54,0,0,0-5.91-1.68,59.54,59.54,0,0,0-19.09,2.72,69,69,0,0,0-21.47,11.17,92.671,92.671,0,0,0-22.2,25.08c0,.05-3.14,5-6.38,11.33-4.26,8.37-6.78,15.41-7.3,20.35l44,11C311.28,180.75,311.56,179.92,312.21,175.91Z"
                        fill="#707171"
                    />
                    <path
                        id="Path_172"
                        d="M317,118.35c-7.92-1.15-17.07,2.36-18.22,10.29s2.8,13.38,10.72,14.53,16.83-2.45,18-10.37S324.89,119.5,317,118.35Z"
                        fill="#707171"
                        opacity="0.3"
                    />
                    <path
                        id="Path_173"
                        d="M264.18,171a36.83,36.83,0,1,1,27.09,44.48A36.83,36.83,0,0,1,264.18,171Z"
                        fill="#e3e2e3"
                    />
                    <circle
                        id="Ellipse_14"
                        cx="36.83"
                        cy="36.83"
                        r="36.83"
                        transform="translate(246.74 193.165) rotate(-60.83)"
                        fill="#707171"
                    />
                    <path
                        id="Path_174"
                        d="M296.8,212.94a34,34,0,0,1-8-1,33.39,33.39,0,1,1,8,1Zm-.11-65.14a31.63,31.63,0,1,0,7.5.91,31.63,31.63,0,0,0-7.5-.91Z"
                        fill="#e3e2e3"
                    />
                    <path
                        id="Path_175"
                        d="M298.93,189.92a5.38,5.38,0,0,0,.39-1.91,4,4,0,0,0-.42-1.76,7.629,7.629,0,0,0-1.26-1.81,21.7,21.7,0,0,0-2.14-2,52,52,0,0,1-4-3.63,16.511,16.511,0,0,1-2.75-3.62,9.74,9.74,0,0,1-1.2-3.89,10.21,10.21,0,0,1,.71-4.43,10,10,0,0,1,2.14-3.5,9.34,9.34,0,0,1,3.21-2.2,11.68,11.68,0,0,1,4-.84,15.39,15.39,0,0,1,4.64.57l1.94-5.22,4.2,1.57-2,5.29a14,14,0,0,1,3.66,2.72,10.84,10.84,0,0,1,2.25,3.51,10.67,10.67,0,0,1,.71,4.14,13.809,13.809,0,0,1-.94,4.66l-7.59-2.83a6.78,6.78,0,0,0,.33-4.86,4.7,4.7,0,0,0-2.94-2.74,5.78,5.78,0,0,0-2.15-.43,3.87,3.87,0,0,0-1.74.38,3.7,3.7,0,0,0-1.32,1.05,5.4,5.4,0,0,0-.89,1.58,5.079,5.079,0,0,0-.35,1.73,4,4,0,0,0,.4,1.7,8,8,0,0,0,1.27,1.8,27.752,27.752,0,0,0,2.22,2.12c1.46,1.26,2.77,2.49,3.91,3.67a17.39,17.39,0,0,1,2.73,3.64,9.51,9.51,0,0,1,.49,8.31,10.24,10.24,0,0,1-2.18,3.58,9.32,9.32,0,0,1-3.22,2.16,11.09,11.09,0,0,1-4,.77,16.561,16.561,0,0,1-4.65-.62l-1.81,4.85-4.17-1.55,1.79-4.83a19.418,19.418,0,0,1-3.86-2.52,12,12,0,0,1-2.82-3.45,10.65,10.65,0,0,1-1.23-4.42,12.9,12.9,0,0,1,.93-5.37l7.59,2.82a8,8,0,0,0-.59,3.07,5,5,0,0,0,2.09,4,8.789,8.789,0,0,0,2,1.06,7.149,7.149,0,0,0,2.29.49,4.94,4.94,0,0,0,1.89-.29,3.89,3.89,0,0,0,1.44-1,4.59,4.59,0,0,0,1-1.52Z"
                        fill="#e3e2e3"
                    />
                    <rect
                        id="Rectangle_111"
                        width="143.64"
                        height="60.24"
                        transform="translate(125.673 227.898) rotate(-15.77)"
                        fill="#e3e2e3"
                    />
                    <path
                        id="Path_176"
                        d="M135,230.92l13,46.23,122.1-34.48-13.05-46.22Zm130.9,3.38a7.39,7.39,0,0,1-5.1,9.13L155.66,273.11a6.62,6.62,0,0,1-8.17-4.57l-8.28-29.35a7.28,7.28,0,0,1,5-9L249,200.62a7.13,7.13,0,0,1,8.8,4.93Z"
                        fill="#707171"
                    />
                    <path
                        id="Path_177"
                        d="M217.17,232.67a14.95,14.95,0,1,0-1.339,11.371,15,15,0,0,0,1.339-11.371Z"
                        fill="#707171"
                        opacity="0.3"
                    />
                    <path
                        id="Path_178"
                        d="M263.9,188.88l16.36,58L172.19,277.36c5-3.13,50.79-18.21,60.21-39.1,11.44-25.36,2.47-39.25,2.72-41.26Z"
                        fill="#707171"
                        opacity="0.3"
                    />
                    <rect
                        id="Rectangle_112"
                        width="143.64"
                        height="60.24"
                        transform="translate(280.983 205.772) rotate(-0.25)"
                        fill="#e3e2e3"
                    />
                    <path
                        id="Path_179"
                        d="M289.17,211.16l.21,48,126.87-.55-.21-48ZM414.4,249.44a7.4,7.4,0,0,1-7.37,7.43l-109.23.47a6.62,6.62,0,0,1-6.65-6.59L291,220.26a7.28,7.28,0,0,1,7.26-7.32l108.82-.48a7.14,7.14,0,0,1,7.17,7.1Z"
                        fill="#707171"
                    />
                    <path
                        id="Path_180"
                        d="M352.87,220a15,15,0,1,0,15,14.88,15,15,0,0,0-15-14.88Z"
                        fill="#707171"
                        opacity="0.3"
                    />
                    <path
                        id="Path_181"
                        d="M424.63,205.13l.26,60.24-112.3.49c5.65-1.68,53.81-3.95,68.48-21.57,17.8-21.37,12.88-37.16,13.65-39Z"
                        fill="#707171"
                        opacity="0.3"
                    />
                    <path
                        id="Path_182"
                        d="M337.19,248.73a36.83,36.83,0,1,0-14.47,50,36.83,36.83,0,0,0,14.47-50Z"
                        fill="#e3e2e3"
                    />
                    <path
                        id="Path_183"
                        d="M334.38,250.29a36.83,36.83,0,1,0-14.47,50,36.83,36.83,0,0,0,14.47-50Z"
                        fill="#707171"
                    />
                    <path
                        id="Path_184"
                        d="M302.38,302a33.84,33.84,0,0,1-9.37-1.34,33.29,33.29,0,1,1,9.37,1.34Zm-.08-65.14a4.494,4.494,0,1,1,0,.02Z"
                        fill="#e3e2e3"
                    />
                    <path
                        id="Path_185"
                        d="M311.08,274.75a5.42,5.42,0,0,0-1-1.67,4,4,0,0,0-1.5-1,7.86,7.86,0,0,0-2.14-.48,20.071,20.071,0,0,0-2.93,0,53.709,53.709,0,0,1-5.38,0,16.769,16.769,0,0,1-4.47-.81,10.481,10.481,0,0,1-6.77-9.81,9.18,9.18,0,0,1,.88-3.78,11.66,11.66,0,0,1,2.4-3.35,15.84,15.84,0,0,1,3.82-2.72l-2.1-5.16,4.15-1.68,2.13,5.23a14,14,0,0,1,4.53-.46,10.49,10.49,0,0,1,7.35,3.63,13.8,13.8,0,0,1,2.46,4.07L305,259.75a6.79,6.79,0,0,0-3-3.81,4.74,4.74,0,0,0-4,0,6.14,6.14,0,0,0-1.88,1.14,4.16,4.16,0,0,0-1,1.46,3.871,3.871,0,0,0-.26,1.66,5.7,5.7,0,0,0,.42,1.77,5,5,0,0,0,.91,1.51,3.759,3.759,0,0,0,1.44,1,7.69,7.69,0,0,0,2.16.47,24.509,24.509,0,0,0,3.06.06c1.94-.05,3.73,0,5.37.07a16.9,16.9,0,0,1,4.47.84,9.92,9.92,0,0,1,3.51,2.07,11.05,11.05,0,0,1,3.27,7.83,9.239,9.239,0,0,1-.9,3.77,11.459,11.459,0,0,1-2.46,3.3,17,17,0,0,1-3.85,2.69l1.95,4.8L310,292l-2-4.78a18.58,18.58,0,0,1-4.55.75,11.86,11.86,0,0,1-4.41-.64,10.64,10.64,0,0,1-3.89-2.42,13,13,0,0,1-2.95-4.59l7.5-3.05a7.92,7.92,0,0,0,1.64,2.66,5.15,5.15,0,0,0,2,1.32,5.05,5.05,0,0,0,2.22.22,9.249,9.249,0,0,0,2.2-.57,7.09,7.09,0,0,0,2-1.19,4.58,4.58,0,0,0,1.19-1.49,3.73,3.73,0,0,0,.4-1.7,4.67,4.67,0,0,0-.27-1.77Z"
                        fill="#e3e2e3"
                    />
                    <path
                        id="Path_186"
                        d="M267,214.89a27.68,27.68,0,1,0-6.376,20.222A27.67,27.67,0,0,0,267,214.89Z"
                        fill="#e3e2e3"
                    />
                    <path
                        id="Path_187"
                        d="M264.63,215.1a27.67,27.67,0,1,0-6.369,20.222A27.67,27.67,0,0,0,264.63,215.1Z"
                        fill="#707171"
                    />
                    <path
                        id="Path_188"
                        d="M237.07,243A25.25,25.25,0,0,1,212,220h0a25.21,25.21,0,1,1,27.29,22.94C238.53,243,237.8,243,237.07,243Zm-23.6-23.17a23.71,23.71,0,1,0,21.58-25.67,23.71,23.71,0,0,0-21.58,25.67Z"
                        fill="#e3e2e3"
                    />
                    <path
                        id="Path_189"
                        d="M241.18,224.8a4.11,4.11,0,0,0-.19-1.45,3,3,0,0,0-.72-1.16,5.53,5.53,0,0,0-1.33-1,14.293,14.293,0,0,0-2-.9c-1.36-.53-2.59-1.08-3.7-1.64a12.461,12.461,0,0,1-2.82-1.92,7.39,7.39,0,0,1-1.79-2.48,7.65,7.65,0,0,1-.55-3.32,7.56,7.56,0,0,1,.68-3,7,7,0,0,1,1.76-2.33,8.781,8.781,0,0,1,2.67-1.56,11.48,11.48,0,0,1,3.45-.71l.13-4.18,3.37.11L240,203.5a10.35,10.35,0,0,1,3.25,1.06,8,8,0,0,1,2.45,2,8.14,8.14,0,0,1,1.5,2.77,10.54,10.54,0,0,1,.45,3.55l-6.08-.19a5.14,5.14,0,0,0-.93-3.55,3.6,3.6,0,0,0-2.76-1.25,4.51,4.51,0,0,0-1.63.21,3.18,3.18,0,0,0-1.15.69,2.75,2.75,0,0,0-.69,1.07,4.14,4.14,0,0,0-.25,1.34,3.65,3.65,0,0,0,.17,1.31,2.64,2.64,0,0,0,.69,1.11,5.6,5.6,0,0,0,1.33,1,18.3,18.3,0,0,0,2.09,1c1.34.55,2.57,1.11,3.66,1.68a12.84,12.84,0,0,1,2.82,1.94,7.36,7.36,0,0,1,1.78,2.49,7.461,7.461,0,0,1,.56,3.31,7.69,7.69,0,0,1-.7,3.07,6.929,6.929,0,0,1-1.77,2.31,8.38,8.38,0,0,1-2.69,1.52,12.729,12.729,0,0,1-3.46.67l-.13,3.89-3.34-.11.12-3.87a13.65,13.65,0,0,1-3.35-.87,8.871,8.871,0,0,1-2.84-1.78,7.94,7.94,0,0,1-1.93-2.85,9.75,9.75,0,0,1-.63-4.05l6.08.19a6,6,0,0,0,.31,2.33,3.88,3.88,0,0,0,1,1.52,3.73,3.73,0,0,0,1.46.83,6.418,6.418,0,0,0,1.68.28,5.58,5.58,0,0,0,1.75-.2,3.64,3.64,0,0,0,1.27-.66,2.81,2.81,0,0,0,.79-1.05,3.37,3.37,0,0,0,.3-1.41Z"
                        fill="#e3e2e3"
                    />
                    <path
                        id="Path_190"
                        d="M259,140.49a14.47,14.47,0,1,0-5,19.84A14.47,14.47,0,0,0,259,140.49Z"
                        fill="#e3e2e3"
                    />
                    <path
                        id="Path_191"
                        d="M257.87,141.14a14.46,14.46,0,1,0,1.612,10.956,14.46,14.46,0,0,0-1.612-10.956Z"
                        fill="#707171"
                    />
                    <path
                        id="Path_192"
                        d="M245.58,161.9a13.17,13.17,0,0,1-11.32-6.4h0a13.161,13.161,0,1,1,11.32,6.42Zm-10.65-6.8a12.39,12.39,0,1,0,4.25-17,12.39,12.39,0,0,0-4.25,17Z"
                        fill="#e3e2e3"
                    />
                    <path
                        id="Path_193"
                        d="M249.08,151.07a2.15,2.15,0,0,0-.42-.64,1.67,1.67,0,0,0-.6-.38,3,3,0,0,0-.85-.15,7.691,7.691,0,0,0-1.15,0,19.6,19.6,0,0,1-2.06.1,6.869,6.869,0,0,1-1.77-.25,3.74,3.74,0,0,1-1.4-.76,3.94,3.94,0,0,1-1-1.43,4,4,0,0,1-.37-1.58,3.66,3.66,0,0,1,.29-1.49,4.83,4.83,0,0,1,.9-1.35A5.82,5.82,0,0,1,242,142l-.9-2,1.61-.72.91,2a5.47,5.47,0,0,1,1.77-.24,4.2,4.2,0,0,1,1.59.36,4.14,4.14,0,0,1,1.34,1,5.36,5.36,0,0,1,1,1.56l-2.9,1.3a2.71,2.71,0,0,0-1.25-1.45,1.87,1.87,0,0,0-1.58.05,2.3,2.3,0,0,0-.72.47,1.53,1.53,0,0,0-.38.59,1.32,1.32,0,0,0-.08.65,2,2,0,0,0,.19.69,1.879,1.879,0,0,0,.38.58,1.37,1.37,0,0,0,.58.36,2.86,2.86,0,0,0,.85.16,10,10,0,0,0,1.2,0c.76,0,1.46-.07,2.11,0a6.38,6.38,0,0,1,1.76.26,4.15,4.15,0,0,1,2.81,3.79,3.78,3.78,0,0,1-.3,1.49,4.3,4.3,0,0,1-.92,1.33,6,6,0,0,1-1.47,1.11l.83,1.86-1.59.71-.83-1.84a7.708,7.708,0,0,1-1.78.36,4.62,4.62,0,0,1-1.74-.19,4.23,4.23,0,0,1-1.56-.89,5.14,5.14,0,0,1-1.22-1.76l2.9-1.3a3.12,3.12,0,0,0,.68,1,2,2,0,0,0,.81.48,1.87,1.87,0,0,0,.88.06,3.28,3.28,0,0,0,.86-.26,2.75,2.75,0,0,0,.77-.49,2,2,0,0,0,.45-.61,1.51,1.51,0,0,0,.13-.67,1.75,1.75,0,0,0-.11-.73Z"
                        fill="#e3e2e3"
                    />
                    <path
                        id="Path_194"
                        d="M311.08,89.51a14.45,14.45,0,1,0,8.843,6.658,14.46,14.46,0,0,0-8.843-6.658Z"
                        fill="#e3e2e3"
                    />
                    <path
                        id="Path_195"
                        d="M309.86,89.19a14.46,14.46,0,1,0,10.38,17.63,14.46,14.46,0,0,0-10.38-17.63Z"
                        fill="#707171"
                    />
                    <path
                        id="Path_196"
                        d="M306.19,116.53a12.93,12.93,0,0,1-3.3-.42,13.18,13.18,0,0,1-9.45-16.06h0a13.2,13.2,0,1,1,12.75,16.48Zm0-25.57a12.41,12.41,0,0,0-12,9.29h0a12.2,12.2,0,1,0,12-9.29Z"
                        fill="#e3e2e3"
                    />
                    <path
                        id="Path_197"
                        d="M307,107.51a2.11,2.11,0,0,0,.16-.75,1.721,1.721,0,0,0-.15-.69,3.17,3.17,0,0,0-.49-.72,7.178,7.178,0,0,0-.83-.79c-.57-.5-1.09-1-1.53-1.45a6.479,6.479,0,0,1-1.06-1.44,4.11,4.11,0,0,1,.72-4.63,3.61,3.61,0,0,1,1.27-.84,4.37,4.37,0,0,1,1.59-.31,6,6,0,0,1,1.82.25l.8-2,1.64.64-.81,2.07a5.41,5.41,0,0,1,1.42,1.09,4.18,4.18,0,0,1,1.12,3,5.44,5.44,0,0,1-.4,1.82l-3-1.15a2.73,2.73,0,0,0,.16-1.91,1.87,1.87,0,0,0-1.14-1.09,2.36,2.36,0,0,0-.85-.18,1.5,1.5,0,0,0-.68.13,1.61,1.61,0,0,0-.53.41,2.331,2.331,0,0,0-.35.62,1.9,1.9,0,0,0-.15.67,1.39,1.39,0,0,0,.15.67,2.631,2.631,0,0,0,.49.72,9.709,9.709,0,0,0,.85.84c.57.51,1.07,1,1.52,1.47a6.369,6.369,0,0,1,1,1.44,3.9,3.9,0,0,1,.45,1.54,4.36,4.36,0,0,1-1.18,3.12,3.68,3.68,0,0,1-1.27.83,4.5,4.5,0,0,1-1.59.28,6.76,6.76,0,0,1-1.83-.27l-.74,1.89-1.63-.63.74-1.89a7,7,0,0,1-1.5-1,4.74,4.74,0,0,1-1.09-1.37,4.31,4.31,0,0,1-.46-1.74,5.1,5.1,0,0,1,.4-2.11l3,1.15a3.269,3.269,0,0,0-.25,1.21,1.965,1.965,0,0,0,.8,1.58,3.3,3.3,0,0,0,.78.43,2.65,2.65,0,0,0,.9.21,2,2,0,0,0,.74-.11,1.56,1.56,0,0,0,.57-.37A1.8,1.8,0,0,0,307,107.51Z"
                        fill="#e3e2e3"
                    />
                    <path
                        id="Path_198"
                        d="M339.27,211.36a11.39,11.39,0,1,1,15.63,3.9,11.39,11.39,0,0,1-15.63-3.9Z"
                        fill="#e3e2e3"
                    />
                    <path
                        id="Path_199"
                        d="M338.42,211.87a11.39,11.39,0,1,1,15.63,3.91A11.39,11.39,0,0,1,338.42,211.87Z"
                        fill="#707171"
                    />
                    <path
                        id="Path_200"
                        d="M348.27,216.5a10.37,10.37,0,0,1-8.91-5h0a10.36,10.36,0,1,1,8.91,5Zm-8.38-5.36a9.76,9.76,0,1,0,3.34-13.38,9.76,9.76,0,0,0-3.34,13.38Z"
                        fill="#e3e2e3"
                    />
                    <path
                        id="Path_201"
                        d="M351,208a1.67,1.67,0,0,0-.33-.5,1.31,1.31,0,0,0-.48-.3,2.169,2.169,0,0,0-.67-.12,5.621,5.621,0,0,0-.9,0,16.37,16.37,0,0,1-1.66.07,5.35,5.35,0,0,1-1.39-.2,3.14,3.14,0,0,1-1.11-.6,3.19,3.19,0,0,1-.81-1.13,3.12,3.12,0,0,1-.29-1.24,3,3,0,0,1,.23-1.18,3.72,3.72,0,0,1,.71-1.06,4.921,4.921,0,0,1,1.14-.88l-.7-1.57,1.26-.57.72,1.59a4.27,4.27,0,0,1,1.39-.19,3.51,3.51,0,0,1,1.26.28,3.35,3.35,0,0,1,1.06.76,4.431,4.431,0,0,1,.8,1.23l-2.28,1a2.14,2.14,0,0,0-1-1.15,1.46,1.46,0,0,0-1.24,0,1.76,1.76,0,0,0-.57.37,1.3,1.3,0,0,0-.3.46,1.15,1.15,0,0,0-.06.52,1.818,1.818,0,0,0,.15.54,1.529,1.529,0,0,0,.3.46,1.15,1.15,0,0,0,.45.28,2.49,2.49,0,0,0,.68.13h2.6a5,5,0,0,1,1.39.21,3.22,3.22,0,0,1,2.21,3,2.82,2.82,0,0,1-.23,1.18,3.58,3.58,0,0,1-.73,1,4.85,4.85,0,0,1-1.16.88l.66,1.46-1.26.56-.65-1.45a5.611,5.611,0,0,1-1.4.28,3.7,3.7,0,0,1-1.37-.14,3.34,3.34,0,0,1-1.23-.71,3.94,3.94,0,0,1-1-1.38l2.28-1a2.589,2.589,0,0,0,.54.81,1.78,1.78,0,0,0,.64.38,1.61,1.61,0,0,0,.69,0,2.779,2.779,0,0,0,.68-.2,2.08,2.08,0,0,0,.6-.39,1.691,1.691,0,0,0,.36-.47,1.22,1.22,0,0,0,.1-.53A1.519,1.519,0,0,0,351,208Z"
                        fill="#e3e2e3"
                    />
                    <path
                        id="Path_202"
                        d="M209.26,200.3s-7.94-5.6-13.89-4.5S183,204.55,183,204.55s11.88,1.32,16.28-.35S209.26,200.3,209.26,200.3Z"
                        fill="#e3e2e3"
                    />
                    <path
                        id="Path_203"
                        d="M340.18,188.77s13.54-7.37,18.71-4.93,7.91,8.24,7.91,8.24a92.321,92.321,0,0,1-11.12.39C350.83,192.23,347.54,187.88,340.18,188.77Z"
                        fill="#e3e2e3"
                    />
                    <path
                        id="Path_204"
                        d="M361.79,132.27s9-4.48,13.11-3.43a37.6,37.6,0,0,1,8.17,3.43s-5.05,3-10,2.83S361.79,132.27,361.79,132.27Z"
                        fill="#e3e2e3"
                    />
                    <path
                        id="Path_205"
                        d="M302.75,86.14s-4.67-5.8-3-10a39.068,39.068,0,0,1,3-6.43s4.28,4.86,4.1,8.23S302.75,86.14,302.75,86.14Z"
                        fill="#e3e2e3"
                    />
                    <path
                        id="Path_206"
                        d="M321.92,96.35s-1.09-7.29,2.29-9.38S333,84.88,333,84.88s-.84,6.16-3.14,7.52S322.93,94.5,321.92,96.35Z"
                        fill="#e3e2e3"
                    />
                    <path
                        id="Path_207"
                        d="M269.78,290.66s4.57,6.47,4.78,8.77-6.46,8.14-6.46,8.14-2.15-5.64-1.21-8.46S269.78,290.66,269.78,290.66Z"
                        fill="#e3e2e3"
                    />
                    <path
                        id="Path_208"
                        d="M261.27,253.68s-6.8,2.3-7.74,6.06a19.82,19.82,0,0,0-.63,6.44S262.75,261.41,261.27,253.68Z"
                        fill="#e3e2e3"
                    />
                    <path
                        id="Path_209"
                        d="M263.34,253.68s2.1,2.4,2.15,4.07a5.65,5.65,0,0,1-1.07,3.13S261.94,258.88,263.34,253.68Z"
                        fill="#e3e2e3"
                    />
                </g>
                <path
                    id="Path_210"
                    d="M349.46,356.64v4a1.54,1.54,0,0,1-.27.87,1.5,1.5,0,0,1-.73.58.9.9,0,0,1-.28.08,1.269,1.269,0,0,1-.28,0H283.49a1.479,1.479,0,0,1-.72-.18,1.56,1.56,0,0,1-.83-1.23.17.17,0,0,0,0-.07v-4.12a.19.19,0,0,1,0-.08,1.56,1.56,0,0,1,1.55-1.48h64.69l.28.08a1.55,1.55,0,0,1,1,1.46Z"
                    fill="#f4f5f6"
                />
                <path
                    id="Path_211"
                    d="M317.83,362.23H283.32a1.21,1.21,0,0,1-.64-.18,1.59,1.59,0,0,1-.74-1.23.17.17,0,0,0,0-.07v-4.12a.19.19,0,0,1,0-.08,1.47,1.47,0,0,1,1.38-1.48H313.6a12.9,12.9,0,0,0,4,7Z"
                    fill="#707171"
                />
                <rect
                    id="Rectangle_114"
                    width="0.73"
                    height="7.16"
                    transform="translate(340.89 355.08)"
                    fill="#e3e2e3"
                />
                <rect
                    id="Rectangle_115"
                    width="0.73"
                    height="7.16"
                    transform="translate(338.41 355.08)"
                    fill="#e3e2e3"
                />
                <rect
                    id="Rectangle_116"
                    width="0.73"
                    height="7.16"
                    transform="translate(335.44 355.08)"
                    fill="#e3e2e3"
                />
                <rect
                    id="Rectangle_117"
                    width="0.73"
                    height="7.16"
                    transform="translate(330.89 355.08)"
                    fill="#e3e2e3"
                />
                <rect
                    id="Rectangle_118"
                    width="0.73"
                    height="7.16"
                    transform="translate(326.07 355.08)"
                    fill="#e3e2e3"
                />
                <rect
                    id="Rectangle_119"
                    width="0.73"
                    height="7.16"
                    transform="translate(318.69 355.07)"
                    fill="#e3e2e3"
                />
                <rect
                    id="Rectangle_120"
                    width="0.73"
                    height="7.16"
                    transform="translate(311.77 355.07)"
                    fill="#e3e2e3"
                />
                <rect
                    id="Rectangle_121"
                    width="0.73"
                    height="7.16"
                    transform="translate(305.6 355.07)"
                    fill="#e3e2e3"
                />
                <rect
                    id="Rectangle_122"
                    width="0.73"
                    height="7.16"
                    transform="translate(343.25 355.08)"
                    fill="#e3e2e3"
                />
                <rect
                    id="Rectangle_123"
                    width="0.73"
                    height="7.16"
                    transform="translate(344.81 355.08)"
                    fill="#e3e2e3"
                />
                <rect
                    id="Rectangle_124"
                    width="0.73"
                    height="7.16"
                    transform="translate(346.31 355.08)"
                    fill="#e3e2e3"
                />
                <path
                    id="Path_212"
                    d="M348.18,355.1v7.11a1.271,1.271,0,0,1-.28,0h-.45v-7.16h.45Z"
                    fill="#e3e2e3"
                />
                <path
                    id="Path_213"
                    d="M349.2,355.77v5.78a1.5,1.5,0,0,1-.73.58v-6.95a1.59,1.59,0,0,1,.73.59Z"
                    fill="#e3e2e3"
                />
                <path
                    id="Path_214"
                    d="M342.41,349.48v4a1.54,1.54,0,0,1-.26.87,1.59,1.59,0,0,1-.73.58,1,1,0,0,1-.29.08H276.44a1.56,1.56,0,0,1-1.55-1.48v-4.2a1.56,1.56,0,0,1,1.37-1.47h64.87l.29.08a1.56,1.56,0,0,1,.73.59A1.51,1.51,0,0,1,342.41,349.48Z"
                    fill="#f4f5f6"
                />
                <path
                    id="Path_215"
                    d="M310.78,355.07h-34.5a1.47,1.47,0,0,1-1.39-1.48v-4.2a1.5,1.5,0,0,1,1.22-1.47h30.44A12.78,12.78,0,0,0,310.78,355.07Z"
                    fill="#707171"
                />
                <rect
                    id="Rectangle_125"
                    width="0.73"
                    height="7.16"
                    transform="translate(333.84 347.92)"
                    fill="#e3e2e3"
                />
                <rect
                    id="Rectangle_126"
                    width="0.73"
                    height="7.16"
                    transform="translate(331.37 347.92)"
                    fill="#e3e2e3"
                />
                <rect
                    id="Rectangle_127"
                    width="0.73"
                    height="7.16"
                    transform="translate(328.39 347.92)"
                    fill="#e3e2e3"
                />
                <rect
                    id="Rectangle_128"
                    width="0.73"
                    height="7.16"
                    transform="translate(323.84 347.92)"
                    fill="#e3e2e3"
                />
                <rect
                    id="Rectangle_129"
                    width="0.73"
                    height="7.16"
                    transform="translate(319.02 347.91)"
                    fill="#e3e2e3"
                />
                <rect
                    id="Rectangle_130"
                    width="0.73"
                    height="7.16"
                    transform="translate(311.64 347.91)"
                    fill="#e3e2e3"
                />
                <rect
                    id="Rectangle_131"
                    width="0.73"
                    height="7.16"
                    transform="translate(304.72 347.91)"
                    fill="#ffa412"
                />
                <rect
                    id="Rectangle_132"
                    width="0.73"
                    height="7.16"
                    transform="translate(298.55 347.91)"
                    fill="#ffa412"
                />
                <rect
                    id="Rectangle_133"
                    width="0.73"
                    height="7.16"
                    transform="translate(336.2 347.92)"
                    fill="#e3e2e3"
                />
                <rect
                    id="Rectangle_134"
                    width="0.73"
                    height="7.16"
                    transform="translate(337.76 347.92)"
                    fill="#e3e2e3"
                />
                <rect
                    id="Rectangle_135"
                    width="0.73"
                    height="7.16"
                    transform="translate(339.27 347.92)"
                    fill="#e3e2e3"
                />
                <path id="Path_216" d="M341.13,347.94v7.11h-.73v-7.16h.46Z" fill="#e3e2e3" />
                <path
                    id="Path_217"
                    d="M342.15,348.61v5.78a1.59,1.59,0,0,1-.73.58v-7a1.56,1.56,0,0,1,.73.64Z"
                    fill="#e3e2e3"
                />
                <path
                    id="Path_218"
                    d="M349.14,342.33v4a1.56,1.56,0,0,1-1,1.45,1,1,0,0,1-.29.08H283.16a1.56,1.56,0,0,1-1.55-1.48v-4.27a1.57,1.57,0,0,1,1.17-1.36h.05a1.869,1.869,0,0,1,.34,0h64.68l.29.08a1.56,1.56,0,0,1,.73.59,1.52,1.52,0,0,1,.27.91Z"
                    fill="#f4f5f6"
                />
                <path
                    id="Path_219"
                    d="M317.51,347.92H283a1.48,1.48,0,0,1-1.39-1.48v-4.27a1.51,1.51,0,0,1,1.08-1.37h30.58a.427.427,0,0,0,0,.05,12.86,12.86,0,0,0,4.21,7.1Z"
                    fill="#707171"
                />
                <rect
                    id="Rectangle_136"
                    width="0.73"
                    height="7.16"
                    transform="translate(340.57 340.77)"
                    fill="#e3e2e3"
                />
                <rect
                    id="Rectangle_137"
                    width="0.73"
                    height="7.16"
                    transform="translate(338.09 340.77)"
                    fill="#e3e2e3"
                />
                <rect
                    id="Rectangle_138"
                    width="0.73"
                    height="7.16"
                    transform="translate(335.12 340.77)"
                    fill="#e3e2e3"
                />
                <rect
                    id="Rectangle_139"
                    width="0.73"
                    height="7.16"
                    transform="translate(330.56 340.77)"
                    fill="#e3e2e3"
                />
                <rect
                    id="Rectangle_140"
                    width="0.73"
                    height="7.16"
                    transform="translate(325.74 340.76)"
                    fill="#e3e2e3"
                />
                <rect
                    id="Rectangle_141"
                    width="0.73"
                    height="7.16"
                    transform="translate(318.36 340.76)"
                    fill="#e3e2e3"
                />
                <rect
                    id="Rectangle_142"
                    width="0.73"
                    height="7.16"
                    transform="translate(311.45 340.76)"
                    fill="#e3e2e3"
                />
                <rect
                    id="Rectangle_143"
                    width="0.73"
                    height="7.16"
                    transform="translate(305.27 340.76)"
                    fill="#e3e2e3"
                />
                <rect
                    id="Rectangle_144"
                    width="0.73"
                    height="7.16"
                    transform="translate(342.92 340.77)"
                    fill="#e3e2e3"
                />
                <rect
                    id="Rectangle_145"
                    width="0.73"
                    height="7.16"
                    transform="translate(344.48 340.77)"
                    fill="#e3e2e3"
                />
                <rect
                    id="Rectangle_146"
                    width="0.73"
                    height="7.16"
                    transform="translate(345.99 340.77)"
                    fill="#e3e2e3"
                />
                <path
                    id="Path_220"
                    d="M347.85,340.79v7.11a1.238,1.238,0,0,1-.27,0h-.46v-7.16h.46Z"
                    fill="#e3e2e3"
                />
                <path
                    id="Path_221"
                    d="M348.87,341.46v5.78a1.54,1.54,0,0,1-.73.58v-6.95A1.56,1.56,0,0,1,348.87,341.46Z"
                    fill="#e3e2e3"
                />
                <rect
                    id="Rectangle_147"
                    width="27.59"
                    height="2.34"
                    transform="translate(121.38 354.72)"
                    fill="#707171"
                />
                <rect
                    id="Rectangle_148"
                    width="23.53"
                    height="10.55"
                    transform="translate(283.01 347.73)"
                    fill="#707171"
                />
                <path
                    id="Path_222"
                    d="M310.11,354.47l1.54,1.42-.22,2.05-9.02-.26-.86-3.82Z"
                    fill="#707171"
                />
                <path
                    id="Path_223"
                    d="M161.2,170.08c0-.47-.38-5.54-8.11-5.8,0,0,2.85.42,4.22,1.95,0,0-2.35-1.89-4.46-1.23s-3.34,4.35-3.35,5.57,1.15,5.12,2.49,5.46a4.2,4.2,0,0,0,1.62,0,20.711,20.711,0,0,0-1,1.85c-3.08,6.33.1,12.51,2.71,11.83,1.67-.44,2.15,2.42,2.15,2.42L173.69,179S167.71,166.6,161.2,170.08Z"
                    fill="#707171"
                />
                <path
                    id="Path_224"
                    d="M238.89,267.56l.38,2.38c12.65,32.86,3.86,91.35,4.14,92.29l52.7.06C279.76,285.33,238.89,267.56,238.89,267.56Z"
                    fill="#fff"
                    opacity="0.4"
                />
                <path
                    id="Path_225"
                    d="M166.86,356.5l-3.46,1.28s-15-20.8-16.84-39.11a27.9,27.9,0,0,1,.49-9.36s-.15-.84-.4-2.32c-.12-.69-.27-1.52-.43-2.47-.49-2.8-1.16-6.62-1.93-11-1.77-10-4-22.83-5.83-32.24l.93-.3,10.07-3.25,2.08-6.61,7.5-2s1.67,3.27,3.17,18.75c.28,2,.53,4.27.72,6.7a132.789,132.789,0,0,1-.72,27.11c-.34,2.69-.77,5.47-1.31,8.32a36.57,36.57,0,0,0-.29,8.22C161.37,333.24,166.86,356.5,166.86,356.5Z"
                    fill="#707171"
                />
                <path
                    id="Path_226"
                    d="M162.93,274.63,146.65,307c-.12-.69-.27-1.52-.43-2.47-.49-2.8-1.16-6.62-1.93-11-1.77-10-4-22.83-5.83-32.24l.93-.3,10.07-3.25,2.08-6.61,7.5-2s1.67,3.27,3.17,18.75C162.49,270,162.74,272.2,162.93,274.63Z"
                    fill="#fff"
                    opacity="0.1"
                />
                <path
                    id="Path_227"
                    d="M155.77,253.33l-.38-3.23c-6.32,1.67-23.19-4.69-23.19-4.69s-6.11,5.38-5.31,18.27c.39,6.29,1.11,33,1.85,40.93.27,2.87.51,4.91.61,5.72h0a1.15,1.15,0,0,1-.18.7,36.681,36.681,0,0,0-2.76,5.81c-7.34,19.56,1.13,43.65,1.13,43.65l2.19-.89s4.83-26.81,8.74-38.65a23.242,23.242,0,0,1,1.89-4.53c1.7-2.75,3.28-7.24,4.72-12.58,2.71-10.1,4.89-23.24,6.34-33.38C151.62,270.48,155.77,253.33,155.77,253.33Z"
                    fill="#707171"
                />
                <path
                    id="Path_228"
                    d="M164.42,213.34a11,11,0,0,1,1.94-3.87,1.879,1.879,0,0,1,.36-.44,9.12,9.12,0,0,1,2.49-2.12c.62-.26,1.3.05,2,.81h0a11.7,11.7,0,0,1,1.8,2.66s.06.06.06.08c.13.24.26.5.41.79a.58.58,0,0,1,.11.18c.29.6.57,1.23.86,1.88,3.22,7,6.64,17.62,9.49,20.78,1.23,1.36,6.85,4,11.32,6,3.21,1.41,5.82,2.46,5.82,2.46s3.23-.63,4,0,4.23,4.18,3.49,5.34-4.34-1-5.08-1.16-2.84-1-2.84-1h-.57c-1.15-.07-4-.23-7.11-.57-4.76-.47-10.4-1.31-12.65-2.69-3.42-2.09-9-8.5-12.7-14.9a2.752,2.752,0,0,1-.26-.47c-1.91-3.4-3.25-6.77-3.35-9.46A13.49,13.49,0,0,1,164.42,213.34Z"
                    fill="#e3e2e3"
                />
                <path
                    id="Path_229"
                    d="M167.83,214.13a19.69,19.69,0,0,1-7.27,1.7c-10.86.59-13.32-8.92-13.77-15.24a9.05,9.05,0,0,1,3.72-.47,18.838,18.838,0,0,1,2.24.2c1.33-.69,3.71-4.06,5.09-9.7l.47.22,7.83,3.68s-.83,2.55-1.44,5c-.48,1.91-.83,3.75-.54,4.27l1,.41h0a53.912,53.912,0,0,1,2.67,9.93Z"
                    fill="#e3e2e3"
                />
                <path
                    id="Path_230"
                    d="M166.14,194.52s-.83,2.55-1.44,5c-4.58-1.27-6-6.09-6.39-8.67Z"
                    fill="#707171"
                    opacity="0.3"
                />
                <path
                    id="Path_231"
                    d="M167.11,197.22s-11-2.53-9.27-9.33,1.4-11.7,8.47-10.37,7.77,4.26,7.76,6.66S170,197.71,167.11,197.22Z"
                    fill="#e3e2e3"
                />
                <path
                    id="Path_232"
                    d="M174.69,180.32c-1.18-2.46-2.57-2.33-3.43-1.89-5.15-4.76-13.78-3.57-13.78-3.57a42.5,42.5,0,0,0-2.57,9.31s1.68,1.72,6.56,1.68a8,8,0,0,0,4.35-1.63,26.579,26.579,0,0,1-5.71,4.26c6.1-1.17,10.2-8.24,10.62-9a8.84,8.84,0,0,1,1.58,3.76c.12,1.87-.52,5.49.54,5.63C172.85,188.88,176.45,184,174.69,180.32Z"
                    fill="#707171"
                />
                <path
                    id="Path_233"
                    d="M174.49,213.33c-.31-.91-1.94-3.84-2.67-5.15a3.62,3.62,0,0,0-1.09-1.18,27.51,27.51,0,0,0-5.58-2.81h0c.07.27,2,7.43,1.33,9s-14.61-3.47-15.37-4.61c-.61-.92-.62-5.78-.61-7.67v-.76s-5.18-.7-8.09.48a22.371,22.371,0,0,1,1.57,5.25c0,.26.08.52.1.79a9.121,9.121,0,0,1,0,1.65,3.13,3.13,0,0,1-1,2,15,15,0,0,1-2.08,1.73l-1.72,1.24h0a139.859,139.859,0,0,1-.42,14.27,9.5,9.5,0,0,1-1.39,4.05,9.83,9.83,0,0,1-1,1.38c-3.06,3.44-8.49,10.28-7.3,14.53.09,4,34,9.29,33.23,4.88,0,0,5.09-24.52,7.84-30.45.1-.21.19-.42.27-.64a13.84,13.84,0,0,0,.44-6.89,6.28,6.28,0,0,1,3.54-1.09Z"
                    fill="#5a5b5b"
                />
                <path
                    id="Path_234"
                    d="M153.32,244.1c-6.68-.07-12.34-7-15.8-12.55a9.5,9.5,0,0,0,1.39-4.05,139.855,139.855,0,0,0,.42-14.27h0l1.72-1.24a15,15,0,0,0,2.08-1.73C148.6,219.51,162.37,244.2,153.32,244.1Z"
                    fill="#fff"
                    opacity="0.2"
                />
                <path
                    id="Path_235"
                    d="M140.32,362.29h-17.4a9.51,9.51,0,0,1,.49-4.66c.87-1.93,2.24-2.81,1.91-5.14a6.26,6.26,0,0,0,5.42,2.07,19.16,19.16,0,0,0,3,3.92C134.9,359.29,140,359,140.32,362.29Z"
                    fill="#5a5b5b"
                />
                <path
                    id="Path_236"
                    d="M177.67,362.29h-17.4s-.65-2.77.49-4.66c1-1.67.63-2.62-.12-4a6.87,6.87,0,0,0,2.93.55,6.161,6.161,0,0,0,2.59-.91,62,62,0,0,0,5,5.21C172.25,359.29,177.32,359,177.67,362.29Z"
                    fill="#5a5b5b"
                />
                <path
                    id="Path_237"
                    d="M179.6,263.24l4.13-10.09a2.81,2.81,0,0,1,3.33-1.63,17.16,17.16,0,0,1,5.39-6.13,12.84,12.84,0,0,1,7.28-2.4,10.44,10.44,0,0,1,3.88.77c5.88,2.41,8.51,9.84,6.36,17.13l.32.13s1-.07,2.69-.07c5.32,0,17.42.71,25.91,6.61a2.27,2.27,0,0,1,.38,2.38s-13.63-8.82-26.24.25c-.77.55-1.52,1.07-2.25,1.54l-1.13,2.76a9,9,0,0,1-11.73,4.91l-11.54-4.73-2.41-1A8,8,0,0,1,179.6,263.24Zm22.4-8.18,4.7,1.93a2.75,2.75,0,0,1,1.62,3.22l.51.21c1.91-6.65-.42-13.35-5.67-15.5a9.22,9.22,0,0,0-2.26-.6,10.9,10.9,0,0,0-7.2,1.75,15.78,15.78,0,0,0-5.45,5.91l.69.28a2.77,2.77,0,0,1,3.41-1.15L197,253a2.34,2.34,0,0,1,2.16-1.44,2.21,2.21,0,0,1,.87.17l.65.27a2.32,2.32,0,0,1,1.32,3.06Z"
                    fill="#707171"
                />
                <path
                    id="Path_238"
                    d="M210.78,271.73c.73-.47,1.48-1,2.25-1.54,12.61-9.07,26.24-.25,26.24-.25a2.28,2.28,0,0,0,.18-.95c-1.91-1.25-9.06-5.43-17.92-4C211.15,266.66,209.27,268.1,210.78,271.73Z"
                    fill="#5a5b5b"
                />
                <path
                    id="Path_239"
                    d="M184,273.68l2.41,1c-3.61-2,.86-10.29,3.47-15.78s6.56-3,6.56-3a3.15,3.15,0,0,1,2.85-1.74,2.18,2.18,0,0,1,1.45-2.13l-.65-.27a2.21,2.21,0,0,0-.87-.17A2.34,2.34,0,0,0,197,253l-4.7-1.93a2.77,2.77,0,0,0-3.41,1.15l-.69-.28a15.78,15.78,0,0,1,5.45-5.91,10.9,10.9,0,0,1,7.2-1.75,4.88,4.88,0,0,1-1.15-1.33,12.84,12.84,0,0,0-7.28,2.4,17.16,17.16,0,0,0-5.39,6.13,2.81,2.81,0,0,0-3.33,1.63l-4.13,10.09A8,8,0,0,0,184,273.68Z"
                    fill="#5a5b5b"
                />
                <path
                    id="Path_240"
                    d="M138.6,209.21c.05-7.58,3.82-8.62,3.82-8.62a1.151,1.151,0,0,1,.26-.08c.65-.11,1.33.34,2,1.2a23.28,23.28,0,0,1,2.79,4.94c1.49,3.37,3.06,7.74,4.53,12.07.81,2.38,1.59,4.76,2.37,7,.45,1.33.89,2.58,1.31,3.73a34.8,34.8,0,0,0,2.2,5.1,12.59,12.59,0,0,0,3.21,2.9,115.928,115.928,0,0,0,10.35,6.43c1.46.84,2.85,1.6,4,2.22,1,.21,5.88,1.26,8.18.74,2.59-.61,6.93,2.5,6.93,2.5s.47,1-1.2,1.68-4,3.16-7.79,1.67a57.906,57.906,0,0,0-6.93-2.12c-.55-.1-.86-.18-.86-.18-1.47-.29-3.22-.68-5.13-1.1h0c-3.27-.78-6.93-1.72-10-2.77a27.59,27.59,0,0,1-5.33-2.38,2.091,2.091,0,0,1-.37-.26c-3.06-2.22-6.69-8.75-9.51-15.76-.4-1-.76-1.93-1.13-2.87a73.218,73.218,0,0,1-3-9.94,27.771,27.771,0,0,1-.7-4.73A10,10,0,0,1,138.6,209.21Z"
                    fill="#e3e2e3"
                />
                <path
                    id="Path_241"
                    d="M148.36,207c-5-1.38-9.77,2.19-9.77,2.19.06-7.57,3.83-8.6,3.83-8.6C144.31,199.51,146.55,203,148.36,207Z"
                    fill="#5a5b5b"
                />
                <path
                    id="Path_242"
                    d="M261.26,356.63v4a1.56,1.56,0,0,1-1,1.45,1,1,0,0,1-.29.08,1.24,1.24,0,0,1-.27,0H195.29a1.57,1.57,0,0,1-1.56-1.48v-4.2a1.57,1.57,0,0,1,1.37-1.47h64.87l.29.08a1.56,1.56,0,0,1,.73.59A1.52,1.52,0,0,1,261.26,356.63Z"
                    fill="#f4f5f6"
                />
                <path
                    id="Path_243"
                    d="M229.63,362.22H195.12a1.48,1.48,0,0,1-1.39-1.48v-4.2a1.51,1.51,0,0,1,1.23-1.47h30.43A12.81,12.81,0,0,0,229.63,362.22Z"
                    fill="#707171"
                />
                <rect
                    id="Rectangle_149"
                    width="0.73"
                    height="7.16"
                    transform="translate(252.69 355.07)"
                    fill="#e3e2e3"
                />
                <rect
                    id="Rectangle_150"
                    width="0.73"
                    height="7.16"
                    transform="translate(250.21 355.07)"
                    fill="#e3e2e3"
                />
                <rect
                    id="Rectangle_151"
                    width="0.73"
                    height="7.16"
                    transform="translate(247.24 355.07)"
                    fill="#e3e2e3"
                />
                <rect
                    id="Rectangle_152"
                    width="0.73"
                    height="7.16"
                    transform="translate(242.68 355.07)"
                    fill="#e3e2e3"
                />
                <rect
                    id="Rectangle_153"
                    width="0.73"
                    height="7.16"
                    transform="translate(237.87 355.07)"
                    fill="#e3e2e3"
                />
                <rect
                    id="Rectangle_154"
                    width="0.73"
                    height="7.16"
                    transform="translate(230.48 355.06)"
                    fill="#e3e2e3"
                />
                <rect
                    id="Rectangle_155"
                    width="0.73"
                    height="7.16"
                    transform="translate(223.57 355.06)"
                    fill="#e3e2e3"
                />
                <rect
                    id="Rectangle_156"
                    width="0.73"
                    height="7.16"
                    transform="translate(217.4 355.06)"
                    fill="#e3e2e3"
                />
                <rect
                    id="Rectangle_157"
                    width="0.73"
                    height="7.16"
                    transform="translate(255.05 355.07)"
                    fill="#e3e2e3"
                />
                <rect
                    id="Rectangle_158"
                    width="0.73"
                    height="7.16"
                    transform="translate(256.61 355.07)"
                    fill="#e3e2e3"
                />
                <rect
                    id="Rectangle_159"
                    width="0.73"
                    height="7.16"
                    transform="translate(258.11 355.07)"
                    fill="#e3e2e3"
                />
                <path
                    id="Path_244"
                    d="M260,355.09v7.11a1.238,1.238,0,0,1-.27,0h-.46v-7.16h.46Z"
                    fill="#e3e2e3"
                />
                <path
                    id="Path_245"
                    d="M261,355.76v5.78a1.54,1.54,0,0,1-.73.58v-6.95A1.56,1.56,0,0,1,261,355.76Z"
                    fill="#e3e2e3"
                />
                <path
                    id="Path_246"
                    d="M249.94,349.48v4a1.56,1.56,0,0,1-1,1.45,1,1,0,0,1-.29.08H184a1.57,1.57,0,0,1-1.56-1.48v-4.2a1.57,1.57,0,0,1,1.56-1.48h64.69l.28.08.14.06.08.05h0l.08.05h0l.07.05.15.14h0l.05.06.06.09a1.5,1.5,0,0,1,.34,1.05Z"
                    fill="#f4f5f6"
                />
                <path
                    id="Path_247"
                    d="M218.31,355.07H183.8a1.48,1.48,0,0,1-1.39-1.48v-4.2a1.48,1.48,0,0,1,1.39-1.48h30.27v.17a12.73,12.73,0,0,0,4.18,7Z"
                    fill="#707171"
                />
                <rect
                    id="Rectangle_160"
                    width="0.73"
                    height="7.16"
                    transform="translate(241.37 347.92)"
                    fill="#e3e2e3"
                />
                <rect
                    id="Rectangle_161"
                    width="0.73"
                    height="7.16"
                    transform="translate(238.89 347.92)"
                    fill="#e3e2e3"
                />
                <rect
                    id="Rectangle_162"
                    width="0.73"
                    height="7.16"
                    transform="translate(235.92 347.92)"
                    fill="#e3e2e3"
                />
                <rect
                    id="Rectangle_163"
                    width="0.73"
                    height="7.16"
                    transform="translate(231.36 347.92)"
                    fill="#e3e2e3"
                />
                <rect
                    id="Rectangle_164"
                    width="0.73"
                    height="7.16"
                    transform="translate(226.55 347.91)"
                    fill="#e3e2e3"
                />
                <rect
                    id="Rectangle_165"
                    width="0.73"
                    height="7.16"
                    transform="translate(219.16 347.91)"
                    fill="#e3e2e3"
                />
                <rect
                    id="Rectangle_166"
                    width="0.73"
                    height="7.16"
                    transform="translate(212.25 347.91)"
                    fill="#ffa412"
                />
                <rect
                    id="Rectangle_167"
                    width="0.73"
                    height="7.16"
                    transform="translate(206.08 347.91)"
                    fill="#ffa412"
                />
                <rect
                    id="Rectangle_168"
                    width="0.73"
                    height="7.16"
                    transform="translate(243.73 347.92)"
                    fill="#e3e2e3"
                />
                <rect
                    id="Rectangle_169"
                    width="0.73"
                    height="7.16"
                    transform="translate(245.29 347.92)"
                    fill="#e3e2e3"
                />
                <rect
                    id="Rectangle_170"
                    width="0.73"
                    height="7.16"
                    transform="translate(246.79 347.92)"
                    fill="#e3e2e3"
                />
                <path id="Path_248" d="M248.66,347.94v7.11h-.73v-7.16h.46Z" fill="#e3e2e3" />
                <path
                    id="Path_249"
                    d="M249.67,348.61v5.78a1.54,1.54,0,0,1-.73.58v-7A1.56,1.56,0,0,1,249.67,348.61Z"
                    fill="#e3e2e3"
                />
                <path
                    id="Path_250"
                    d="M258.68,342.48v4a1.54,1.54,0,0,1-.27.87,1.61,1.61,0,0,1-.73.58l-.29.08H192.7a1.59,1.59,0,0,1-.69-.16,1.57,1.57,0,0,1-.86-1.25v-4.27a1.57,1.57,0,0,1,1.37-1.47h64.87a1,1,0,0,1,.29.08,1.56,1.56,0,0,1,1,1.45Z"
                    fill="#f4f5f6"
                />
                <path
                    id="Path_251"
                    d="M227.05,348.08H192.54a1.27,1.27,0,0,1-.62-.16,1.59,1.59,0,0,1-.77-1.25V342.4a1.5,1.5,0,0,1,1.22-1.47h30.44a12.89,12.89,0,0,0,4,7Z"
                    fill="#707171"
                />
                <rect
                    id="Rectangle_171"
                    width="0.73"
                    height="7.16"
                    transform="translate(250.11 340.92)"
                    fill="#e3e2e3"
                />
                <rect
                    id="Rectangle_172"
                    width="0.73"
                    height="7.16"
                    transform="translate(247.63 340.92)"
                    fill="#e3e2e3"
                />
                <rect
                    id="Rectangle_173"
                    width="0.73"
                    height="7.16"
                    transform="translate(244.66 340.92)"
                    fill="#e3e2e3"
                />
                <rect
                    id="Rectangle_174"
                    width="0.73"
                    height="7.16"
                    transform="translate(240.1 340.92)"
                    fill="#e3e2e3"
                />
                <rect
                    id="Rectangle_175"
                    width="0.73"
                    height="7.16"
                    transform="translate(235.28 340.92)"
                    fill="#e3e2e3"
                />
                <rect
                    id="Rectangle_176"
                    width="0.73"
                    height="7.16"
                    transform="translate(227.9 340.92)"
                    fill="#e3e2e3"
                />
                <rect
                    id="Rectangle_177"
                    width="0.73"
                    height="7.16"
                    transform="translate(220.99 340.92)"
                    fill="#e3e2e3"
                />
                <rect
                    id="Rectangle_178"
                    width="0.73"
                    height="7.16"
                    transform="translate(214.81 340.92)"
                    fill="#e3e2e3"
                />
                <rect
                    id="Rectangle_179"
                    width="0.73"
                    height="7.16"
                    transform="translate(252.46 340.92)"
                    fill="#e3e2e3"
                />
                <rect
                    id="Rectangle_180"
                    width="0.73"
                    height="7.16"
                    transform="translate(254.02 340.92)"
                    fill="#e3e2e3"
                />
                <rect
                    id="Rectangle_181"
                    width="0.73"
                    height="7.16"
                    transform="translate(255.53 340.92)"
                    fill="#e3e2e3"
                />
                <path id="Path_252" d="M257.39,341v7.11h-.73v-7.16h.46Z" fill="#e3e2e3" />
                <path
                    id="Path_253"
                    d="M258.41,341.61v5.79a1.61,1.61,0,0,1-.73.58v-7a1.54,1.54,0,0,1,.73.63Z"
                    fill="#e3e2e3"
                />
                <path
                    id="Path_254"
                    d="M253.94,335.33v4.05a1.56,1.56,0,0,1-1,1.45l-.28.08H188a1.57,1.57,0,0,1-1.56-1.47v-4.21a1.57,1.57,0,0,1,1.56-1.48h64.41a1.269,1.269,0,0,1,.28,0,.9.9,0,0,1,.28.08,1.5,1.5,0,0,1,.73.58A1.54,1.54,0,0,1,253.94,335.33Z"
                    fill="#f4f5f6"
                />
                <path
                    id="Path_255"
                    d="M222.31,340.93H187.8a1.47,1.47,0,0,1-1.39-1.47v-4.21a1.48,1.48,0,0,1,1.39-1.48h30.27a12.91,12.91,0,0,0,4.23,7.15Z"
                    fill="#707171"
                />
                <rect
                    id="Rectangle_182"
                    width="0.73"
                    height="7.16"
                    transform="translate(245.37 333.77)"
                    fill="#e3e2e3"
                />
                <rect
                    id="Rectangle_183"
                    width="0.73"
                    height="7.16"
                    transform="translate(242.89 333.77)"
                    fill="#e3e2e3"
                />
                <rect
                    id="Rectangle_184"
                    width="0.73"
                    height="7.16"
                    transform="translate(239.92 333.77)"
                    fill="#e3e2e3"
                />
                <rect
                    id="Rectangle_185"
                    width="0.73"
                    height="7.16"
                    transform="translate(235.37 333.77)"
                    fill="#e3e2e3"
                />
                <rect
                    id="Rectangle_186"
                    width="0.73"
                    height="7.16"
                    transform="translate(230.55 333.77)"
                    fill="#e3e2e3"
                />
                <rect
                    id="Rectangle_187"
                    width="0.73"
                    height="7.16"
                    transform="translate(223.17 333.77)"
                    fill="#e3e2e3"
                />
                <rect
                    id="Rectangle_188"
                    width="0.73"
                    height="7.16"
                    transform="translate(216.25 333.77)"
                    fill="#e3e2e3"
                />
                <rect
                    id="Rectangle_189"
                    width="0.73"
                    height="7.16"
                    transform="translate(210.08 333.77)"
                    fill="#e3e2e3"
                />
                <rect
                    id="Rectangle_190"
                    width="0.73"
                    height="7.16"
                    transform="translate(247.73 333.77)"
                    fill="#e3e2e3"
                />
                <rect
                    id="Rectangle_191"
                    width="0.73"
                    height="7.16"
                    transform="translate(249.29 333.77)"
                    fill="#e3e2e3"
                />
                <rect
                    id="Rectangle_192"
                    width="0.73"
                    height="7.16"
                    transform="translate(250.79 333.77)"
                    fill="#e3e2e3"
                />
                <path
                    id="Path_256"
                    d="M252.66,333.8v7.11h-.74v-7.16h.45a1.269,1.269,0,0,1,.29.05Z"
                    fill="#e3e2e3"
                />
                <path
                    id="Path_257"
                    d="M253.67,334.46v5.78a1.52,1.52,0,0,1-.73.59v-6.95A1.5,1.5,0,0,1,253.67,334.46Z"
                    fill="#e3e2e3"
                />
                <path
                    id="Path_258"
                    d="M214.3,347.26l5.12-1.54-.86-4.79s-3.85-3.12-4.49-7.16l-25.41,6.33v14.5h6.45v5.76h24.42v-5.28S216.05,354.39,214.3,347.26Z"
                    fill="#707171"
                />
                <path
                    id="Path_259"
                    d="M247.16,274.67s24.76,19.83,33.47,48.64C280.63,323.31,274.7,301.24,247.16,274.67Z"
                    fill="#fff"
                />
                <path
                    id="Path_260"
                    d="M257,297.7s12.35,32.81,12.6,56.16C269.57,353.86,273.13,337.91,257,297.7Z"
                    fill="#fff"
                />
                <path
                    id="Path_261"
                    d="M248.82,295.87s5.87,23.34,6.34,32.54C255.16,328.41,255.49,315.87,248.82,295.87Z"
                    fill="#fff"
                />
                <path
                    id="Path_262"
                    d="M165.87,192.72a10.16,10.16,0,0,1,3.62.87S167.71,194.94,165.87,192.72Z"
                    fill="#707171"
                    opacity="0.3"
                />
                <path
                    id="Path_263"
                    d="M445.09,364.14H74.43a.88.88,0,0,1-.79-.95h0a.89.89,0,0,1,.79-1H445.09a.89.89,0,0,1,.79,1h0a.89.89,0,0,1-.79.95Z"
                    fill="#707171"
                />
            </g>
        </SVGIcon>
    );
}
