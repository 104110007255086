import React, { Fragment, useRef, useEffect, useState, useCallback, useContext } from 'react';

import { Editor } from 'react-draft-wysiwyg';
import EditorContext from './editorContext/context';
import { Div, Popover, FieldControl, LabelControl, Label, Input, Button, ToolTip } from '../index';
import PersonaliseSpan from './Decorators/PersonaliseSpan';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import './style.scss';
import draftToHtml from 'draftjs-to-html';
import {
    convertToRaw,
    getDefaultKeyBinding,
    // SelectionState,
    // Modifier,
    EditorState,
    CompositeDecorator,
} from 'draft-js';
import useToast from 'Modules/Toasts';
import { convertHTMLToText } from 'Utils';
import PropTypes from 'prop-types';
import DCHelpOutline from 'Assets/icons/DCHelpOutline';

const styles = {
    root: {
        fontFamily: "'Helvetica', sans-serif",
        padding: 20,
        width: 600,
    },
    editor: {
        border: '1px solid #ddd',
        cursor: 'text',
        fontSize: 16,
        minHeight: 40,
        padding: 10,
    },
    button: {
        marginTop: 10,
        textAlign: 'center',
    },
    handle: {
        color: 'rgba(98, 177, 254, 1.0)',
        direction: 'ltr',
        unicodeBidi: 'bidi-override',
    },
    hashtag: {
        color: 'rgba(95, 184, 138, 1.0)',
        border: 'none',
        background: 'rgba(255,255,255,1)',
        display: 'inline-block',
    },
    personalize: {
        color: 'rgb(102 75 19)',
        backgroundColor: '#ffeaaa',
        display: 'inline-block',
        padding: '0 1px',
        width: 'fit-content',
        margin: '0 3px',
        border: '1px solid #ffc233',
        cursor: 'pointer',
        borderRadius: '5px',
        userSelect: 'none',
        outline: '0px',
        paddingLeft: 25,
        backgroundRepeatX: 'no-repeat',
        backgroundRepeatY: 'no-repeat',
        backgroundSize: 16,
        backgroundPositionX: 5,
        backgroundPositionY: 3,
        marginBottom: '8px',
        backgroundImage: `url("data:image/svg+xml;charset=UTF-8, <svg xmlns='http://www.w3.org/2000/svg' version='1.1' viewBox='0 0 32 32' ><path d='m29.764 23.453-11.188-17.933a3.027 3.027 0 0 0 -5.146-.009l-11.206 17.963a3.027 3.027 0 0 0 2.583 4.526h22.386a3.028 3.028 0 0 0 2.571-4.547zm-13.755.634a1.24 1.24 0 0 1 -.142-2.471 1.24 1.24 0 1 1 .141 2.471zm1.186-9.538-.356 4.093a3.221 3.221 0 0 1 -.249 1.12.708.708 0 0 1 -1.254.013 3.763 3.763 0 0 1 -.248-1.15l-.266-3.976c-.052-.8-.325-2.093.286-2.743.465-.5 1.566-.586 1.9.107a4.873 4.873 0 0 1 .186 2.536z' fill='rgb(102 75 19)' /></svg>")`,
    },
    personalizeSaved: {
        color: '#1f1f1f',
        backgroundColor: '#efefef',
        display: 'inline-block',
        padding: '0 1px',
        width: 'fit-content',
        margin: '0 3px',
        border: '1px solid #d3d2d2',
        cursor: 'pointer',
        borderRadius: 5,
        userSelect: 'none',
        outline: 0,
        paddingLeft: 25,
        backgroundRepeatX: 'no-repeat',
        backgroundRepeatY: 'no-repeat',
        backgroundSize: 16,
        backgroundPositionX: 5,
        backgroundPositionY: 3,
        marginBottom: '8px',
        backgroundImage: `url("data:image/svg+xml;charset=UTF-8, <svg xmlns='http://www.w3.org/2000/svg' version='1.1' viewBox='0 0 492.001 492.001' ><path d='M487.971,235.993l-85.468-85.464c-5.22-5.228-14.396-5.228-19.616,0l-7.452,7.448c-5.4,5.408-5.4,14.548,0.004,19.956    l48.456,48.792H67.911l48.696-49.02c5.408-5.412,5.408-14.384,0-19.796l-7.444-7.524c-5.232-5.232-14.404-5.272-19.624-0.044    L4.035,235.813c-2.672,2.676-4.1,6.24-4.032,9.916c-0.072,3.82,1.36,7.396,4.032,10.068l85.464,85.464    c5.228,5.232,14.396,5.228,19.62,0l7.444-7.448c5.416-5.416,5.416-13.784,0-19.192l-49.856-49.436h358.792l-50.096,49.668    c-2.612,2.604-4.052,5.884-4.052,9.592s1.436,7.088,4.052,9.7l7.444,7.396c2.616,2.612,6.1,4.02,9.812,4.02    c3.716,0,7.196-1.448,9.812-4.06l85.5-85.508c2.664-2.668,4.096-6.248,4.028-9.924    C492.075,242.245,490.639,238.665,487.971,235.993z' fill='rgb(31 31 31)'/></svg>")`,
    },
};
const WysiwygEditor = ({
    className,
    toolbar,
    error,
    errorMessage,
    placeholder,
    onEditorChange,
    readOnly,
    toolbarHidden,
    toolbarCustomButtons,
    contentState,
    setContentState,
    handleKeyCommand,
    stripPastedStyles,
    spellCheck,
    autoFocus,
    handleFocus,
    maxLength,
    currentEditor,
    setFallback,
    initialFallback,
    keyBindingFunction,
    handleReturn,
}) => {
    const editorReference = useRef();
    const [editorFocus, setEditorFocus] = useState(false);
    const { fallbackEntity, setfallbackEntity } = useContext(EditorContext || {});
    const [currentFallbackEntity, setCurrentFallbackEntity] = useState('');
    const [fallbackValue, setFallBackValue] = useState('');
    const [openModal, setOpenModal] = useState(false);
    const { showToast } = useToast();
    // const HANDLE_REGEX = /@[\w]+/g;
    // const HASHTAG_REGEX = /#[\w\u0590-\u05ff]+/g;
    // const PERSONALISE_REGEX = /{{[\w\u0590-\u05ff]+-[\w \/\u0590-\u05ff]+}}/g;
    const popoverRef = useRef();
    /**
     *
     * @param {EditorState} state -takes new state and update editor state
     */
    const handleEditorChange = state => {
        const temp = { ...fallbackEntity };
        const text = state.getCurrentContent().getPlainText('\u0001');
        const selState = state.getSelection();
        const nstate = EditorState.acceptSelection(
            EditorState.createWithContent(state.getCurrentContent(), newdecorator),
            selState,
        );
        var finalStateWithEntity = EditorState.set(nstate, {
            selection: state.getSelection(),
            undoStack: state.getUndoStack(),
            redoStack: state.getRedoStack(),
            lastChangeType: state.getLastChangeType(),
        });
        setContentState && setContentState(finalStateWithEntity);
        onEditorChange && onEditorChange(finalStateWithEntity);
        Object.keys(temp).forEach(item => {
            if (!new RegExp(item, 'g').test(text)) delete temp[item];
        });
        setfallbackEntity(temp);
    };

    const handleEditorFocus = () => {
        setEditorFocus(true);
        handleFocus && handleFocus(true);
    };

    const handleEditorBlur = () => {
        setEditorFocus(false);
        handleFocus && handleFocus(false);
    };

    const handleBeforeInput = useCallback(() => {
        if (maxLength) {
            if (
                convertHTMLToText(draftToHtml(convertToRaw(contentState.getCurrentContent())))
                    .length > maxLength
            ) {
                showToast({
                    type: 'warning',
                    message: 'Content cannot exceed 1600 charachters.',
                    noDuplicate: true,
                    duration: 3000,
                });
                return 'handled';
            }
        }
    }, [contentState, maxLength, showToast]);
    const handlePasted = useCallback(
        input => {
            if (maxLength) {
                const htmlText = convertHTMLToText(
                    draftToHtml(convertToRaw(contentState.getCurrentContent())),
                );
                if (htmlText.length + input?.length >= maxLength) {
                    if (
                        htmlText.length === 1 &&
                        input?.length <= maxLength &&
                        ['\n', '\r'].includes(htmlText)
                    )
                        return;
                    showToast({
                        type: 'warning',
                        message: 'Content cannot exceed 1600 charachters.',
                        noDuplicate: true,
                        duration: 3000,
                    });
                    return 'handled';
                }
            }
        },
        [contentState, maxLength, showToast],
    );

    useEffect(() => {
        if (autoFocus === true) {
            editorReference.current.focusEditor();
        }
    }, [autoFocus, editorReference]);

    const firstTime = useRef(true);
    useEffect(() => {
        setFallback && setFallback({ ...fallbackEntity });
    }, [fallbackEntity, setFallback]);

    useEffect(() => {
        if (firstTime.current && initialFallback) {
            setfallbackEntity({ ...initialFallback, ...fallbackEntity });
            firstTime.current = false;
        }
    }, [fallbackEntity, initialFallback, setfallbackEntity]);

    // function handleStrategy(contentBlock, callback) {
    //     findWithRegex(HANDLE_REGEX, contentBlock, callback);
    // }
    // function hashtagStrategy(contentBlock, callback) {
    //     findWithRegex(HASHTAG_REGEX, contentBlock, callback);
    // }
    // function personalizeStrategy1(contentBlock, callback) {
    //     findWithRegex(PERSONALISE_REGEX, contentBlock, callback);
    // }

    // function findWithRegex(regex, contentBlock, callback) {
    //     const text = contentBlock.getText();
    //     let matchArr, start;
    //     while ((matchArr = regex.exec(text)) !== null) {
    //         start = matchArr.index;
    //         callback(start, start + matchArr[0].length);
    //     }
    // }

    // const HandleSpan = props => {
    //     return (
    //         <span style={styles.handle} data-offset-key={props.offsetKey}>
    //             {props.children}
    //         </span>
    //     );
    // };
    // const HashtagSpan = props => {
    //     return (
    //         <span style={styles.hashtag} data-offset-key={props.offsetKey}>
    //             {props.children}
    //         </span>
    //     );
    // };

    const keyBindingFn = e => {
        if (e.key === 'Backspace') {
            setOpenModal(false);
            return getDefaultKeyBinding(e);
        } else if (e.key === 'Enter' && currentEditor) {
            return 'handled';
        }
        keyBindingFunction && keyBindingFunction(e);
        // This wasn't the delete key, so we return Draft's default command for this key
        return getDefaultKeyBinding(e);
    };

    function getEntityStrategy(mutability) {
        return function (contentBlock, callback, contentState) {
            contentBlock.findEntityRanges(character => {
                const entityKey = character.getEntity();
                if (entityKey === null) {
                    return false;
                }
                return contentState.getEntity(entityKey).getMutability() === mutability;
            }, callback);
        };
    }

    const decorator = [
        // {
        //     strategy: handleStrategy,
        //     component: HandleSpan,
        // },
        // {
        //     strategy: hashtagStrategy,
        //     component: HashtagSpan,
        // },
        {
            strategy: getEntityStrategy('IMMUTABLE'),
            component: props => (
                <PersonaliseSpan
                    {...props}
                    setCurrentFallbackEntity={setCurrentFallbackEntity}
                    popoverRef={popoverRef}
                    setOpenModal={setOpenModal}
                    setFallBackValue={setFallBackValue}
                    styles={styles}
                    handleEditorChange={handleEditorChange}
                    preview={readOnly}
                />
            ),
        },
    ];

    const newdecorator = new CompositeDecorator(decorator);

    const handleSaveFallbackValue = useCallback(() => {
        const temp = { ...fallbackEntity };
        temp[currentFallbackEntity] = fallbackValue;
        if (!temp[currentFallbackEntity]) {
            delete temp[currentFallbackEntity];
        }
        setfallbackEntity(temp);
        setOpenModal(false);
    }, [currentFallbackEntity, fallbackEntity, fallbackValue, setfallbackEntity]);

    return (
        <Fragment>
            <Popover
                customClass={'BuilderOptionPopover ArrowPopover'}
                open={openModal}
                anchorEl={popoverRef}
                onClose={() => {
                    setOpenModal(false);
                }}
                placement={'top-center'}
                width={300}
            >
                <FieldControl>
                    <LabelControl>
                        <Div className="BOPFallbackLabelContainer">
                            <Label
                                customClass={'BOPFLCLabel'}
                                props={{ htmlFor: 'Fallback' }}
                                required
                            >
                                Fallback
                            </Label>
                            <ToolTip
                                content={`A lot of the time it's a good 
                            idea to replace the fallback variable with 
                            another word, in case the information is not
                                available for a particular contact.
                                For example, if you add "Hi <first name>" 
                                at the beginning of your message and use 
                                the fallback as "there", it will say
                                "Hi John" if we know John's name but
                                it will say "Hi there" if we don't.`}
                                customPopoverClass={'ArrowPopover APToolTip'}
                                wrapperClass={'APTWrapper'}
                                placement={'top-center'}
                            >
                                <DCHelpOutline />
                            </ToolTip>
                        </Div>
                    </LabelControl>
                    <Input
                        type={'text'}
                        autoFocus
                        placeholder={'Enter fallback value'}
                        id={'Fallback'}
                        value={fallbackValue}
                        onChange={e => {
                            e.stopPropagation();
                            setFallBackValue(e.target.value);
                        }}
                        onKeyDown={e => {
                            e.stopPropagation();
                            if (e.keyCode == 13) {
                                setOpenModal(false);
                            }
                        }}
                    />
                </FieldControl>
                <Div className="BOPBtn">
                    <Button
                        buttonType={'BlueFillBtn'}
                        disabled={!fallbackValue}
                        onClick={() => handleSaveFallbackValue()}
                    >
                        Save
                    </Button>
                    <Button
                        buttonType={'RedFillBtn'}
                        onClick={e => {
                            // delete fallbackEntity[currentFallbackEntity];
                            e.stopPropagation();
                            setOpenModal(false);
                            // setFallBackValue('');
                            handleEditorChange(contentState);
                        }}
                    >
                        Cancel
                    </Button>
                </Div>
            </Popover>
            <Div
                className={
                    'WysiwygEditor ' +
                    (className ? className : '') +
                    (error === true ? ' errorState ' : '') +
                    (readOnly === true ? ' WysiwygEditorReadOnly ' : '') +
                    (editorFocus ? 'WysiwygEditorFocus' : '')
                }
            >
                <Editor
                    readOnly={readOnly}
                    toolbarHidden={toolbarHidden}
                    editorState={contentState}
                    wrapperClassName="WEMWrapper"
                    toolbarClassName="WEMToolbar"
                    editorClassName="WEMEditor"
                    toolbar={{ ...(toolbar || {}), link: { defaultTargetOption: '_blank' } }}
                    toolbarCustomButtons={toolbarCustomButtons}
                    placeholder={placeholder}
                    onEditorStateChange={handleEditorChange}
                    onFocus={handleEditorFocus}
                    onBlur={handleEditorBlur}
                    handleKeyCommand={handleKeyCommand}
                    stripPastedStyles={stripPastedStyles || false}
                    spellCheck={spellCheck || true}
                    ref={editorReference}
                    handleBeforeInput={handleBeforeInput}
                    handlePastedText={handlePasted}
                    customDecorators={decorator}
                    // preserveSelectionOnBlur={true}
                    handleReturn={handleReturn}
                    keyBindingFn={keyBindingFn}
                />
                {error && (
                    <Div className={'WysiwygEditorError'}>
                        {errorMessage || 'Please enter valid text'}
                    </Div>
                )}
            </Div>
        </Fragment>
    );
};

WysiwygEditor.propTypes = {
    className: PropTypes.string,
    toolbar: PropTypes.object,
    error: PropTypes.bool,
    errorMessage: PropTypes.string,
    placeholder: PropTypes.string,
    onEditorChange: PropTypes.func.isRequired,
    readOnly: PropTypes.bool,
    toolbarHidden: PropTypes.bool,
    toolbarCustomButtons: PropTypes.arrayOf(PropTypes.element),
    contentState: PropTypes.object.isRequired,
    setContentState: PropTypes.func.isRequired,
    handleKeyCommand: PropTypes.func,
    stripPastedStyles: PropTypes.bool,
    spellCheck: PropTypes.bool,
    autoFocus: PropTypes.bool,
    handleFocus: PropTypes.func,
    maxLength: PropTypes.number,
    keyBindingFunction: PropTypes.func,
    children: PropTypes.any,
    initialFallback: PropTypes.any,
    currentEditor: PropTypes.any,
    setFallback: PropTypes.any,
    handleReturn: PropTypes.func,
};

export default WysiwygEditor;

// contentState-EditorState
// setContentState- function
