import React from 'react';

import { SVGIcon } from '../../UIKit/index';

export default function DCAutomationMenu(props) {
    return (
        <SVGIcon {...props} viewBox="0 0 480.5 512">
            <g transform="translate(-24 -8)">
                <path
                    fill="#6B8AAA"
                    d="M365.5,389.7v-36l-32.3-11.5c-4.3-10.5-6.5-15.8-10.9-26.2l14.7-30.9l-25.5-25.5l-30.9,14.7   l-26.2-10.9L243,231.2h-36l-11.5,32.3l-26.2,10.9l-30.9-14.7l-25.5,25.5l14.7,30.9l-10.9,26.2l-32.3,11.5v36l32.3,11.5   c4.3,10.5,6.5,15.8,10.9,26.2l-14.7,30.9l25.5,25.5l30.9-14.7l26.2,10.9l11.5,32.3h36l11.5-32.3l26.2-10.9l30.9,14.7l25.5-25.5   l-14.7-30.9l10.9-26.2L365.5,389.7z M225,437.8c-36.5,0-66.1-29.6-66.1-66.1s29.6-66.1,66.1-66.1c36.5,0,66.1,29.6,66.1,66.1l0,0   C291.1,408.2,261.5,437.8,225,437.8L225,437.8z"
                />
                <path
                    fill="#005EFF"
                    d="M504.5,191.3v-36l-32.3-11.5l-10.9-26.2l14.7-30.9l-25.5-25.5l-30.9,14.7l-26.2-10.9L382,32.8   h-36l-11.5,32.3l-26.2,10.9l-30.9-14.7l-25.5,25.5l14.7,30.9l-10.9,26.2l-14.5,10.6c0,18-17.8,18.9-17.8,36.9l32.3,11.5l10.9,26.2   L251.9,260l25.5,25.5l30.9-14.7l26.2,10.9l11.5,32.3h36l11.5-32.3l26.2-10.9l30.9,14.7l25.5-25.5L461.4,229l10.9-26.2L504.5,191.3z    M364,239.4c-36.5,0-66.1-29.6-66.1-66.1c0-36.5,29.6-66.1,66.1-66.1c36.5,0,66.1,29.6,66.1,66.1c0,0,0,0,0,0   C430.1,209.8,400.5,239.4,364,239.4L364,239.4z"
                />
                <path
                    fill="#D9EAFF"
                    d="M306.2,166.5v-36L273.9,119L263,92.8l14.7-30.9l-25.5-25.5l-30.9,14.7l-26.2-10.9L183.6,8h-36   l-11.5,32.3l-26.2,10.9L79,36.4L53.5,61.9l14.7,30.9L57.4,119l-32.3,11.5v36L57.4,178l10.9,26.2l-14.7,30.9L79,260.6l30.9-14.7   l26.2,10.9l11.5,32.3h36l11.5-32.3l26.2-10.9l30.9,14.7l25.5-25.5L263,204.2l10.9-26.2L306.2,166.5z M165.6,214.7   c-36.5,0-66.1-29.6-66.1-66.1c0-36.5,29.6-66.1,66.1-66.1s66.1,29.6,66.1,66.1S202.2,214.7,165.6,214.7L165.6,214.7z"
                />
                <path
                    fill="#D9EAFF"
                    d="M388.8,470.9v-49.6l-44.5-44.5c-6.9-6.8-7-17.9-0.2-24.8c6.8-6.9,17.9-7,24.8-0.2   c0.1,0.1,0.1,0.1,0.2,0.2l19.7,19.7v-90.9c0-9.1,7.4-16.5,16.5-16.5s16.5,7.4,16.5,16.5v41.3h35.9c25.8,0,46.8,20.9,46.8,46.8   c0,12.4-4.9,24.3-13.7,33.1l-19.4,19.4v49.6L388.8,470.9z"
                />
                <path fill="#005EFF" d="M372.3,446.1H488v74.4H372.3V446.1z" />
                <path fill="#FFFFFF" d="M446.7,470.9h16.5v16.5h-16.5V470.9z" />
            </g>
        </SVGIcon>
    );
}
