import React from 'react';

import { SVGIcon } from '../../UIKit/index';

export default function DCTimeNotifications(props) {
    return (
        <SVGIcon {...props} viewBox="0 0 65.57 50.587">
            <g>
                <path
                    d="M54.92,107.573a1.972,1.972,0,0,1,1.97-1.97V89.7a2.979,2.979,0,0,0-2.979-2.979H11.205A2.979,2.979,0,0,0,8.226,89.7v33.776a2.979,2.979,0,0,0,2.979,2.979H53.911a2.979,2.979,0,0,0,2.979-2.979V109.543A1.972,1.972,0,0,1,54.92,107.573Z"
                    transform="translate(-7.237 -77.785)"
                    fill="#fff"
                />
                <path
                    d="M53.419,45.432H9.724a2.975,2.975,0,0,0-.993.169l-1.064,6.77L56.4,51.39V48.411A2.979,2.979,0,0,0,53.419,45.432Z"
                    transform="translate(-6.745 -41.46)"
                    fill="#7d81ff"
                />
                <path
                    d="M2.979,147.246a.993.993,0,0,1-.993-.993V111.5H0v34.757a2.979,2.979,0,0,0,2.979,2.979H46.674a2.971,2.971,0,0,0,2.8-1.986H2.979Z"
                    transform="translate(0 -99.579)"
                    fill="#e8e8e8"
                />
                <path
                    d="M1.986,50.639v-3.8A2.992,2.992,0,0,0,0,49.646v4.965H49.653V52.625H3.972a1.986,1.986,0,0,1-1.986-1.986Z"
                    transform="translate(0 -42.695)"
                    fill="#585df2"
                />
                <path
                    d="M43.276,18.358a1.986,1.986,0,0,1-1.986-1.986V14.386a1.986,1.986,0,1,1,3.972,0v1.986A1.986,1.986,0,0,1,43.276,18.358Z"
                    transform="translate(-36.325 -12.4)"
                    fill="#eceaec"
                />
                <path
                    d="M43.276,49.4a1.986,1.986,0,0,1-1.986-1.986V45.432h3.972v1.986A1.986,1.986,0,0,1,43.276,49.4Z"
                    transform="translate(-36.325 -41.46)"
                    fill="#dad8db"
                />
                <path
                    d="M340.567,18.358a1.986,1.986,0,0,1-1.986-1.986V14.386a1.986,1.986,0,1,1,3.972,0v1.986A1.986,1.986,0,0,1,340.567,18.358Z"
                    transform="translate(-297.866 -12.4)"
                    fill="#eceaec"
                />
                <path
                    d="M340.567,49.4a1.986,1.986,0,0,1-1.986-1.986V45.432h3.972v1.986A1.986,1.986,0,0,1,340.567,49.4Z"
                    transform="translate(-297.866 -41.46)"
                    fill="#dad8db"
                />
                <g transform="translate(41.737 26.754)">
                    <circle
                        cx="3.972"
                        cy="3.972"
                        r="3.972"
                        transform="translate(7.944 15.889)"
                        fill="#e8e8e8"
                    />
                    <path
                        d="M388.808,318.949a2.06,2.06,0,0,0-2.065-1.653,1.88,1.88,0,0,1-1.88-1.88v-6.065a7.947,7.947,0,0,0-11.917-6.882L372,305.506v13.228l1,1.744h13.847l1.986-1.195a2.07,2.07,0,0,0-.027-.334Z"
                        transform="translate(-365.002 -301.408)"
                        fill="#7d81ff"
                    />
                    <path
                        d="M321.751,323.086v-12.84a7.948,7.948,0,0,0-3.972,6.881v6.065a1.869,1.869,0,0,1-1.865,1.88,2.061,2.061,0,0,0-2.08,1.652,1.987,1.987,0,0,0,1.959,2.32h19.861a1.988,1.988,0,0,0,1.986-1.986H325.724A3.972,3.972,0,0,1,321.751,323.086Z"
                        transform="translate(-313.807 -309.183)"
                        fill="#585df2"
                    />
                </g>
                <path
                    d="M124.5,166.407a13.839,13.839,0,0,1-2.487,7.938c-2.512,3.606-6.688,5.355-11.416,5.355-7.678,0-13.094-5.614-13.094-13.293,0-4.726,1.55-8.9,5.154-11.414A13.9,13.9,0,0,1,124.5,166.407Z"
                    transform="translate(-85.775 -135.656)"
                    fill="#7d81ff"
                />
                <path
                    d="M108.152,195.041A13.905,13.905,0,0,1,96.736,173.2a13.9,13.9,0,1,0,19.355,19.352A13.845,13.845,0,0,1,108.152,195.041Z"
                    transform="translate(-79.857 -153.863)"
                    fill="#585df2"
                />
                <circle
                    cx="11.917"
                    cy="11.917"
                    r="11.917"
                    transform="translate(12.902 18.834)"
                    fill="#fff"
                />
                <path
                    d="M9.931,0A9.931,9.931,0,1,1,0,9.931,9.931,9.931,0,0,1,9.931,0Z"
                    transform="translate(14.888 20.82)"
                    fill="#fff"
                />
                <path
                    d="M161.086,211.527,153.673,214l-5.816-5.815a.993.993,0,0,0-1.4,1.4l6.249,6.249a.994.994,0,0,0,1.016.24l8-2.665a.993.993,0,0,0-.628-1.884Z"
                    transform="translate(-128.586 -184.384)"
                    fill="#585df2"
                />
                <circle
                    cx="1.986"
                    cy="1.986"
                    r="1.986"
                    transform="translate(22.833 28.765)"
                    fill="#585df2"
                />
            </g>
        </SVGIcon>
    );
}
