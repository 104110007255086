import React from 'react';

import { SVGIcon } from '../../UIKit/index';

export default function DCBoxViewType(props) {
    return (
        <SVGIcon {...props} viewBox="0 0 512 432">
            <path d="M112.8,4.8H43.1C22,8.1,5.4,24.4,1.8,45.4v378.3c2.9,22,20.4,39.2,42.4,41.7h423.9   c21.4-2.2,38.7-18.5,42.2-39.8V47.3c-2.5-23-21.1-40.9-44.2-42.5l-3.4-0.4H399v32.2h79.1v396.9H33.6V36.8h78.9L112.8,4.8z" />
            <rect x="51.9" y="4.5" width="367.7" height="31.9" />
            <rect x="113.3" y="166" width="63.2" height="48.7" />
            <rect x="113.3" y="85.7" width="63.2" height="48.7" />
            <rect x="224.5" y="166" width="63.2" height="48.7" />
            <rect x="224.5" y="85.7" width="63.2" height="48.7" />
            <rect x="335.4" y="166" width="63.2" height="48.7" />
            <rect x="335.4" y="85.7" width="63.2" height="48.7" />
            <rect x="113.4" y="245.3" width="63.2" height="48.7" />
            <rect x="224.6" y="245.3" width="63.2" height="48.7" />
            <rect x="335.5" y="245.3" width="63.2" height="48.7" />
            <rect x="113.4" y="324.4" width="63.2" height="48.7" />
            <rect x="224.6" y="324.4" width="63.2" height="48.7" />
            <rect x="335.5" y="324.4" width="63.2" height="48.7" />
        </SVGIcon>
    );
}
