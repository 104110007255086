import React, { useState, useCallback } from 'react';
import { Div } from 'UIKit/index';
import usePaymentInputs from './usePaymentInputs';
import images from './Images';
import './style.scss';

const CardInput = ({
    cardBrand,
    cartNumberValue,
    expiryDateValue,
    cvcValue,
    disabled,
    id,
    onChange,
}) => {
    const { meta, getCardImageProps, getCardNumberProps, getExpiryDateProps, getCVCProps } =
        usePaymentInputs();

    const [cardNumber, setCardNumber] = useState('');
    const [expiryDate, setExpiryDate] = useState('');
    const [cvc, setCVC] = useState('');

    const handleChangeCardNumber = useCallback(
        e => {
            const inputValue = e.target.value;
            setCardNumber(inputValue);
            onChange({ number: inputValue, exp_date: expiryDate, cvc });
        },
        [cvc, expiryDate, onChange],
    );

    const handleChangeExpiryDate = useCallback(
        e => {
            const inputValue = e.target.value;
            setExpiryDate(inputValue);
            onChange({ number: cardNumber, exp_date: inputValue, cvc });
        },
        [cardNumber, cvc, onChange],
    );

    const handleChangeCVC = useCallback(
        e => {
            const inputValue = e.target.value;
            setCVC(inputValue);
            onChange({
                number: cardNumber,
                exp_date: expiryDate,
                cvc: inputValue,
            });
        },
        [cardNumber, expiryDate, onChange],
    );

    return (
        <Div className="CardInputWarper">
            <Div
                className={
                    'CardInput ' +
                    (meta.isTouched && meta.error && cardNumber?.length > 0
                        ? 'CardInputError'
                        : '') +
                    (meta.focused ? ' CardInputFocused' : '')
                }
            >
                <Div className="CardInputIcon">
                    <svg {...getCardImageProps({ images, brand: cardBrand })} />
                </Div>
                <Div className="CardInputWrapper">
                    <input
                        type="text"
                        {...getCardNumberProps({ onChange: handleChangeCardNumber })}
                        value={cartNumberValue || cardNumber}
                        placeholder={'Card Number'}
                        className="CIWNumber"
                        disabled={disabled}
                        id={id}
                    />
                    <input
                        type="text"
                        {...getExpiryDateProps({ onChange: handleChangeExpiryDate })}
                        value={expiryDateValue || expiryDate}
                        placeholder={'MM / YY'}
                        className="CIWDate"
                        disabled={disabled}
                    />
                    <input
                        type="text"
                        {...getCVCProps({ onChange: handleChangeCVC })}
                        value={cvcValue || cvc}
                        placeholder={'CVC'}
                        className="CIWCVC"
                        disabled={disabled}
                    />
                </Div>
            </Div>
            {meta.isTouched && meta.error && cardNumber?.length && !meta.focused > 0 ? (
                <Div className="CardError">Error: {meta.error}</Div>
            ) : null}
        </Div>
    );
};

export default CardInput;
