import React from 'react';

import { SVGIcon } from '../../UIKit/index';

export default function DCStoreMenu(props) {
    return (
        <SVGIcon {...props} viewBox="0 0 512 492">
            <g transform="translate(0 -10.67)">
                <g transform="translate(6.698 15.442)">
                    <path
                        fill="#FAFAFA"
                        d="M182.85,263.19c-5.42,0-10.06-3.92-10.94-9.27l-22.16-133.02c-0.96-6.04,3.16-11.72,9.2-12.68    c5.98-0.95,11.61,3.08,12.65,9.04l22.16,133.02c1,6.04-3.07,11.74-9.11,12.75C184.06,263.13,183.46,263.19,182.85,263.19z"
                    />
                    <path
                        fill="#FAFAFA"
                        d="M238.27,252.09c-5.75,0.02-10.56-4.36-11.08-10.08l-11.08-121.94    c-0.58-6.09,3.89-11.5,9.98-12.08c0,0,0,0,0.01,0l0,0c6.09-0.55,11.48,3.94,12.03,10.04l0,0l11.08,121.94    c0.55,6.09-3.94,11.48-10.04,12.03l0,0L238.27,252.09z"
                    />
                    <path
                        fill="#FAFAFA"
                        d="M293.69,241.01c-6.12,0-11.08-4.96-11.08-11.08V119.06c0-6.13,4.97-11.09,11.09-11.09    c6.13,0,11.09,4.97,11.09,11.09v110.91C304.77,236.08,299.8,241.02,293.69,241.01z"
                    />
                    <path
                        fill="#FAFAFA"
                        d="M349.11,229.93h-1.25c-6.08-0.64-10.5-6.1-9.85-12.18c0,0,0-0.01,0-0.01l0,0l11.08-99.67    c0.72-6.1,6.2-10.5,12.31-9.87c6.08,0.67,10.47,6.14,9.81,12.22c0,0.01,0,0.01,0,0.02l0,0l-11.08,99.67    C359.49,225.7,354.75,229.93,349.11,229.93z"
                    />
                    <path
                        fill="#FAFAFA"
                        d="M404.53,218.82c-6.12,0-11.08-4.95-11.09-11.07c0-0.91,0.11-1.81,0.33-2.7l22.16-88.68    c1.34-5.97,7.28-9.72,13.25-8.38c5.97,1.34,9.72,7.28,8.38,13.25l-0.12,0.51l-22.18,88.68    C414.03,215.36,409.61,218.82,404.53,218.82z"
                    />
                </g>
                <g>
                    <g transform="translate(5.739 26.931)">
                        <circle fill="#2F8DFF" cx="405.49" cy="417.96" r="55.42" />
                        <circle fill="#2F8DFF" cx="183.79" cy="417.96" r="55.42" />
                    </g>
                    <path
                        fill="#E6EFFF"
                        d="M508.23,82.84c-2.11-2.39-5.14-3.76-8.32-3.76H100.84c-6.12-0.01-11.09,4.94-11.1,11.06    c0,0.75,0.07,1.5,0.23,2.24l44.34,221.71c1.48,5.51,6.63,9.23,12.33,8.88l332.57-44.34c4.99-0.67,8.91-4.62,9.53-9.62L510.9,91.65    C511.33,88.46,510.36,85.25,508.23,82.84z"
                    />
                    <path
                        fill="#2F8DFF"
                        d="M455.57,367.29H199.7c-36.96-0.08-68.76-26.15-76.1-62.37L69.6,34.74H12.16    c-6.13,0-11.09-4.97-11.09-11.09c0-6.13,4.97-11.09,11.09-11.09h66.52c5.27-0.01,9.82,3.71,10.87,8.88l55.81,279.23    c5.24,25.86,27.94,44.48,54.33,44.57h255.87c6.13,0,11.09,4.97,11.09,11.09s-4.97,11.09-11.09,11.09V367.29z"
                    />
                    <g transform="translate(7.465 16.06) rotate(-9)">
                        <path
                            fill="#ffffff"
                            d="M157.19,146.01L157.19,146.01c6.4,0,11.59,5.19,11.59,11.59l0,92.72c0,6.4-5.19,11.59-11.59,11.59l0,0     c-6.4,0-11.59-5.19-11.59-11.59l0-92.72C145.6,151.2,150.79,146.01,157.19,146.01z"
                        />

                        <rect
                            fill="none"
                            stroke="#2F8DFF"
                            x="157.19"
                            y="157.6"
                            transform="matrix(-0.9877 -0.1564 0.1564 -0.9877 280.5367 429.9891)"
                            width="0"
                            height="92.72"
                        />
                    </g>
                    <g transform="translate(10.465 16.06) rotate(-9)">
                        <path
                            fill="#ffffff"
                            d="M222.91,156.42L222.91,156.42c6.4,0,11.59,5.19,11.59,11.59l0,92.72c0,6.4-5.19,11.59-11.59,11.59l0,0     c-6.4,0-11.59-5.19-11.59-11.59l0-92.72C211.32,161.61,216.51,156.42,222.91,156.42z"
                        />

                        <rect
                            fill="none"
                            stroke="#2F8DFF"
                            x="222.91"
                            y="168.01"
                            transform="matrix(-0.9877 -0.1564 0.1564 -0.9877 409.5339 460.9583)"
                            width="0"
                            height="92.72"
                        />
                    </g>
                    <g transform="translate(13.465 16.06) rotate(-9)">
                        <path
                            fill="#ffffff"
                            d="M288.62,166.83L288.62,166.83c6.4,0,11.59,5.19,11.59,11.59l0,92.72c0,6.4-5.19,11.59-11.59,11.59l0,0     c-6.4,0-11.59-5.19-11.59-11.59l0-92.72C277.03,172.01,282.22,166.83,288.62,166.83z"
                        />

                        <rect
                            fill="none"
                            stroke="#2F8DFF"
                            x="288.62"
                            y="178.41"
                            transform="matrix(-0.9877 -0.1564 0.1564 -0.9877 538.5311 491.9276)"
                            width="0"
                            height="92.72"
                        />
                    </g>
                    <g transform="translate(16.465 16.06) rotate(-9)">
                        <path
                            fill="#ffffff"
                            d="M354.34,177.23L354.34,177.23c6.4,0,11.59,5.19,11.59,11.59l0,92.72c0,6.4-5.19,11.59-11.59,11.59l0,0     c-6.4,0-11.59-5.19-11.59-11.59l0-92.72C342.75,182.42,347.94,177.23,354.34,177.23z"
                        />

                        <rect
                            fill="none"
                            stroke="#2F8DFF"
                            x="354.34"
                            y="188.82"
                            transform="matrix(-0.9877 -0.1564 0.1564 -0.9877 667.5283 522.8968)"
                            width="0"
                            height="92.72"
                        />
                    </g>
                </g>
            </g>
        </SVGIcon>
    );
}
