import ExtraLight from './fonts/SourceSansPro-ExtraLight.ttf';
import Regular from './fonts/SourceSansPro-Regular.ttf';
import Light from './fonts/SourceSansPro-Light.ttf';
import Semibold from './fonts/SourceSansPro-Semibold.ttf';
import Bold from './fonts/SourceSansPro-Bold.ttf';
import Black from './fonts/SourceSansPro-Black.ttf';

export const instareportFontFamily = {
    family: 'Source Sans Pro',
    format: 'truetype',
    fonts: [
        {
            src: ExtraLight,
            fontWeight: 200,
        },
        { src: Regular, fontWeight: 400 },
        { src: Light, fontWeight: 300 },
        { src: Semibold, fontWeight: 600 },
        { src: Bold, fontWeight: 700 },
        { src: Black, fontWeight: 900 },
    ],
};
