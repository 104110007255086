import React from 'react';

import { SVGIcon } from '../../UIKit/index';

export default function DCSquareStar(props) {
    return (
        <SVGIcon {...props} viewBox="0 0 22 22">
            <g transform="translate(-25 -1034)">
                <g transform="translate(25 1034)" fill="#fff" stroke="#7b89a4" strokeWidth="1.4">
                    <rect width="22" height="22" rx="3" stroke="none" />
                    <rect x="0.7" y="0.7" width="20.6" height="20.6" rx="2.3" fill="none" />
                </g>
                <path
                    d="M3.347,14.822a.8.8,0,0,1-.774-.972l.962-4.237L.273,6.752A.8.8,0,0,1,.723,5.361l4.316-.392L6.746.975a.795.795,0,0,1,1.462,0L9.914,4.969l4.316.392a.8.8,0,0,1,.452,1.391l-3.262,2.86.962,4.237a.794.794,0,0,1-1.182.859L7.477,12.483,3.755,14.709a.8.8,0,0,1-.408.113Zm4.13-3.3a.8.8,0,0,1,.408.113l3.513,2.1-.908-4a.8.8,0,0,1,.252-.774l3.08-2.7-4.075-.37a.794.794,0,0,1-.66-.482L7.477,1.643,5.866,5.413a.792.792,0,0,1-.658.48l-4.076.37,3.08,2.7a.793.793,0,0,1,.252.775l-.907,4,3.513-2.1a.8.8,0,0,1,.408-.113ZM5.006,5.046h0Zm4.939,0h0Zm0,0"
                    transform="translate(29 1037.508)"
                    fill="#7b89a4"
                    stroke="#7b89a4"
                    strokeWidth="0.2"
                />
            </g>
        </SVGIcon>
    );
}
