import React from 'react';

import { SVGIcon } from '../../UIKit/index';

export default function DCPPTXFile(props) {
    return (
        <SVGIcon {...props} viewBox="0 0 368 464">
            <g transform="translate(-30 -463)">
                <g transform="translate(-42 439)">
                    <path
                        d="M392,488H120a48,48,0,0,1-48-48V72a48,48,0,0,1,48-48H344l96,96V440A48,48,0,0,1,392,488Z"
                        fill="#cfd2fc"
                    />
                    <path
                        d="M72,360H440v80a48,48,0,0,1-48,48H120a48,48,0,0,1-48-48Z"
                        fill="#5153ff"
                    />
                    <path d="M264,152a72,72,0,1,1-72,72h72Z" fill="#8690fa" />
                    <path d="M232,120v72H160a72,72,0,0,1,72-72Z" fill="#fff" />
                    <path d="M440,120H392a48,48,0,0,1-48-48V24Z" fill="#8690fa" />
                    <g transform="translate(42 -439)">
                        <path
                            d="M256,384H240a8,8,0,0,0-8,8v64a8,8,0,0,0,16,0V432h8a24,24,0,0,0,0-48Zm0,32h-8V400h8a8,8,0,0,1,0,16Z"
                            transform="translate(-72 439)"
                            fill="#fff"
                        />
                        <path
                            d="M192,384H176a8,8,0,0,0-8,8v64a8,8,0,0,0,16,0V432h8a24,24,0,0,0,0-48Zm0,32h-8V400h8a8,8,0,0,1,0,16Z"
                            transform="translate(-80 439)"
                            fill="#fff"
                        />
                        <path
                            d="M336,384H304a8,8,0,0,0,0,16h8v56a8,8,0,0,0,16,0V400h8a8,8,0,0,0,0-16Z"
                            transform="translate(-64 439)"
                            fill="#fff"
                        />
                        <path
                            d="M379.578,384.845a8,8,0,0,0-10.733,3.577L360,406.111l-8.845-17.689a8,8,0,1,0-14.31,7.156L351.056,424l-14.211,28.422a8,8,0,1,0,14.31,7.156L360,441.889l8.845,17.689a8,8,0,1,0,14.31-7.156L368.944,424l14.211-28.422a8,8,0,0,0-3.577-10.733Z"
                            transform="translate(-39 437)"
                            fill="#fff"
                        />
                    </g>
                </g>
            </g>
        </SVGIcon>
    );
}
