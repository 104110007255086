import React from 'react';

import { SVGIcon } from '../../UIKit/index';

export default function DCID(props) {
    return (
        <SVGIcon {...props} viewBox="0 0 512 512">
            <g>
                <path d="M454.613,0H57.387C25.707,0,0,25.707,0,57.387v397.227C0,486.293,25.707,512,57.387,512h397.227     c31.68,0,57.387-25.707,57.387-57.387V57.387C512,25.707,486.293,0,454.613,0z M490.667,454.613     c0,19.947-16.107,36.053-36.053,36.053H57.387c-19.947,0-36.053-16.107-36.053-36.053V57.387     c0-19.947,16.107-36.053,36.053-36.053h397.227c19.947,0,36.053,16.107,36.053,36.053V454.613z" />
                <rect x="160" y="148.267" width="26.667" height="211.2" />
                <path d="M350.827,138.667h-26.56v87.467h-0.64c-6.613-12.16-20.8-21.227-40.64-21.227c-33.067,0-61.76,29.013-61.76,80.107     c0,47.04,25.493,77.013,59.2,77.013c22.08,0,38.4-12.16,45.653-27.093h0.853l1.6,24.533h23.573     c-0.64-10.027-1.28-25.707-1.28-38.187V138.667z M324.267,298.773c0.107,3.84-0.32,7.573-1.28,11.307     c-5.333,21.013-20.267,31.04-35.413,31.04c-26.56,0-39.893-25.707-39.893-56.747c0-32.32,14.187-58.24,40.533-58.24     c18.347,0,31.68,14.08,35.093,31.04c0.64,3.733,0.96,7.467,0.96,11.307V298.773z" />
            </g>
        </SVGIcon>
    );
}
