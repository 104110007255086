import React, { useCallback, useEffect, useRef, useState } from 'react';

import { Button, Div, DropdownOptions } from '../../UIKit/index';

function FilterCondition({
    groupOperator,
    updateGroupOperator,
    activeClass,
    handleActionState,
    type,
}) {
    const [dropdownOptions] = useState([
        {
            id: 1,
            title: 'and',
            value: 'AND',
        },
        {
            id: 2,
            title: 'or',
            value: 'OR',
        },
    ]);
    const buttonRef = useRef();
    const [selectedOption, setSelectedOption] = useState(dropdownOptions[0]);
    const [dropdownOpen, setDropdownOpen] = useState(false);

    useEffect(() => {
        const operator = dropdownOptions.find(o => o.value === groupOperator);
        if (operator) {
            setSelectedOption(operator);
        }
    }, [dropdownOptions, groupOperator]);

    const handleDropdownToggle = () => {
        setDropdownOpen(prevOpen => !prevOpen);
    };

    const handleDropdownClose = () => {
        setDropdownOpen(false);
    };

    const handleSelectOption = useCallback(
        item => {
            setSelectedOption(item);
            setDropdownOpen(false);
            updateGroupOperator(item.value);
        },
        [updateGroupOperator],
    );

    const generateOptions = useCallback(() => {
        const tempOptionArray = [];
        dropdownOptions.map(item => tempOptionArray.push({
                label: (
                    <Button
                        buttonClass={
                            'DropdownOptionsBtn ' + (item.id === selectedOption.id ? 'active' : '')
                        }
                        iconName={item.icon}
                        onClick={() => {
                            handleSelectOption(item);
                        }}
                    >
                        {item.title}
                    </Button>
                ),
            }),
        );
        return tempOptionArray;
    }, [dropdownOptions, handleSelectOption, selectedOption.id]);

    return (
        <Div
            component="div"
            className={'FilterItemCondition  ' + (type === 'group' ? 'GroupCondition' : ' ')}
        >
            <Button
                reference={buttonRef}
                onClick={handleDropdownToggle}
                onMouseEnter={handleActionState}
                onMouseLeave={handleActionState}
                buttonClass={'FICBtn ' + (activeClass || dropdownOpen === true ? 'FICBActive' : '')}
            >
                {selectedOption.title}
            </Button>
            <DropdownOptions
                customClass={'FilterItemConditionDropdown'}
                options={generateOptions()}
                wrapperWidth={70}
                onClose={handleDropdownClose}
                open={dropdownOpen}
                reference={buttonRef}
                viewHeight={dropdownOptions.length * 29}
                itemSize={29}
            />
        </Div>
    );
}

export default FilterCondition;
