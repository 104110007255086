import React from 'react';

import { SVGIcon } from '../../UIKit/index';

export default function DCReset(props) {
    return (
        <SVGIcon {...props} viewBox="0 0 512 512">
            <g>
                <path d="M492.06,70.5c-10.94-1.03-20.64,7-21.67,17.94c0,0.04-0.01,0.09-0.01,0.13l-2.64,29.02    C393.52-0.1,237.96-35.35,120.27,38.85c-38.7,24.4-70.03,58.88-90.62,99.74c-5.01,9.82-1.12,21.84,8.7,26.86    c9.82,5.01,21.84,1.12,26.86-8.7C117.9,52.08,245.47,9.94,350.14,62.63c34.51,17.37,63.62,43.83,84.19,76.53l-28.42-20.32    c-8.97-6.4-21.43-4.32-27.84,4.65c-6.4,8.97-4.32,21.43,4.65,27.84l86.29,61.64c8.91,6.43,21.35,4.42,27.77-4.49    c2.11-2.92,3.38-6.36,3.69-9.94l9.69-106.32c1.04-10.94-6.97-20.65-17.91-21.7C492.18,70.51,492.12,70.51,492.06,70.5z" />
                <path d="M472.02,335.17c-9.77-5.03-21.76-1.19-26.8,8.58c-0.02,0.04-0.04,0.08-0.06,0.12    c-52.69,104.67-180.26,146.82-284.94,94.12c-34.51-17.37-63.62-43.83-84.19-76.53l28.42,20.32c8.97,6.4,21.43,4.32,27.84-4.65    c6.4-8.97,4.32-21.43-4.65-27.84l-86.29-61.64c-8.91-6.43-21.35-4.42-27.77,4.49c-2.11,2.92-3.38,6.36-3.69,9.94L0.23,408.41    c-1,10.99,7.09,20.71,18.08,21.71c10.99,1,20.71-7.09,21.71-18.08l2.84-28.99c74.21,117.69,229.77,152.95,347.46,78.74    c38.7-24.4,70.03-58.88,90.62-99.74c4.99-9.79,1.11-21.77-8.68-26.76C472.18,335.25,472.1,335.21,472.02,335.17z" />
            </g>
        </SVGIcon>
    );
}
