import React from 'react';

import { SVGIcon } from '../../UIKit/index';

export default function DCHalfCircleClose1(props) {
    return (
        <SVGIcon {...props} viewBox="0 0 512 512">
            <g>
                <g>
                    <path d="M488.39,234.92c-11.66,0.01-21.1,9.46-21.12,21.12c0,116.67-94.59,211.25-211.26,211.25    c-116.67,0-211.25-94.59-211.25-211.26c0-116.67,94.59-211.25,211.26-211.25c56.28,0,110.24,22.46,149.89,62.4    c8.22,8.28,21.6,8.32,29.88,0.1s8.32-21.6,0.1-29.88l0,0C337.25-21.95,176.74-22.51,77.4,76.14s-99.91,259.16-1.25,358.51    s259.16,99.91,358.51,1.25c47.93-47.59,74.88-112.34,74.88-179.89C509.51,244.36,500.05,234.92,488.39,234.92z" />
                </g>
            </g>
            <g transform="translate(7.779 7.779)">
                <g>
                    <path d="M278.09,272.19l48.45-48.45c8.25-8.25,8.25-21.62,0-29.87c-8.25-8.25-21.62-8.25-29.87,0    l-48.45,48.45l-48.45-48.45c-8.25-8.25-21.62-8.25-29.87,0c-8.25,8.25-8.25,21.62,0,29.87l48.45,48.45l-48.45,48.45    c-8.25,8.25-8.25,21.62,0,29.87c8.25,8.25,21.62,8.25,29.87,0l0,0l48.45-48.45l48.45,48.45c8.25,8.25,21.62,8.25,29.87,0    c8.25-8.25,8.25-21.62,0-29.87l0,0L278.09,272.19z" />
                </g>
            </g>
        </SVGIcon>
    );
}
