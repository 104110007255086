import React from 'react';

import { SVGIcon } from '../../UIKit/index';

export default function DCGender(props) {
    return (
        <SVGIcon {...props} viewBox="0 0 512 512">
            <path
                id="XMLID_484_"
                d="M447.2,23.7c0-0.7,0-1.5-0.1-2.2c0-0.4-0.1-0.7-0.2-1.1c-0.1-0.4-0.1-0.8-0.2-1.2c-0.1-0.4-0.2-0.8-0.3-1.2
	c-0.1-0.3-0.2-0.7-0.3-1c-0.1-0.4-0.3-0.8-0.4-1.2c-0.1-0.3-0.2-0.7-0.4-1c-0.2-0.4-0.3-0.7-0.5-1.1c-0.2-0.3-0.3-0.7-0.5-1
	c-0.2-0.4-0.4-0.7-0.6-1c-0.2-0.3-0.4-0.6-0.6-1c-0.3-0.4-0.6-0.8-0.8-1.2c-0.2-0.2-0.3-0.5-0.5-0.7c-1-1.2-2.1-2.3-3.3-3.3
	c-0.2-0.2-0.5-0.3-0.7-0.5c-0.4-0.3-0.8-0.6-1.2-0.9c-0.3-0.2-0.6-0.4-0.9-0.5c-0.4-0.2-0.7-0.4-1.1-0.6c-0.3-0.2-0.6-0.3-0.9-0.5
	c-0.4-0.2-0.8-0.4-1.2-0.5c-0.3-0.1-0.6-0.2-0.9-0.3c-0.4-0.2-0.8-0.3-1.3-0.4c-0.3-0.1-0.6-0.2-0.9-0.2c-0.5-0.1-0.9-0.2-1.4-0.3
	c-0.3-0.1-0.7-0.1-1-0.1c-0.4-0.1-0.9-0.1-1.3-0.2c-0.5,0-1-0.1-1.6-0.1c-0.3,0-0.5,0-0.8,0h-75.3c-13.2,0-23.8,10.7-23.8,23.8
	s10.7,23.8,23.8,23.8h17.7L330,83.6c-27.7-20.9-62.2-33.2-99.5-33.2c-91.4,0-165.8,74.4-165.8,165.8c0,83.3,61.8,152.4,141.9,164
	l0,31h-29.4c-13.2,0-23.8,10.7-23.8,23.8s10.7,23.8,23.8,23.8h29.4l0,29.4c0,13.2,10.7,23.8,23.8,23.8c13.2,0,23.8-10.7,23.8-23.8
	l0-29.4h29.4c13.2,0,23.8-10.7,23.8-23.8s-10.7-23.8-23.8-23.8h-29.4l0-31c80.1-11.6,141.9-80.7,141.9-164
	c0-36.9-12.2-71.1-32.7-98.7l36-36v17.7c0,13.2,10.7,23.8,23.8,23.8c13.2,0,23.8-10.7,23.8-23.8V23.8
	C447.3,23.8,447.2,23.8,447.2,23.7z M230.5,334.2c-65.1,0-118.1-53-118.1-118.1c0-65.1,53-118.1,118.1-118.1
	c65.1,0,118.1,53,118.1,118.1C348.6,281.2,295.6,334.2,230.5,334.2z"
            />
        </SVGIcon>
    );
}
