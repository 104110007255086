import React from 'react';

import { SVGIcon } from '../../UIKit/index';

export default function DCGrayReminder(props) {
    return (
        <SVGIcon {...props} viewBox="0 0 469.5 495.82">
            <g id="Reminder" transform="translate(-20.95 -8.5)">
                <circle
                    id="Ellipse_9"
                    cx="225.33"
                    cy="225.33"
                    r="225.33"
                    transform="translate(21.45 9)"
                    fill="#fff"
                    stroke="#000"
                    strokeMiterlimit="10"
                    strokeWidth="1"
                />
                <path
                    id="Path_120"
                    d="M361.52,406.5a15.95,15.95,0,1,1,15.94-15.947A15.95,15.95,0,0,1,361.52,406.5Zm0-30.33a14.38,14.38,0,1,0,14.38,14.38A14.38,14.38,0,0,0,361.52,376.17Z"
                    fill="#e3e2e3"
                />
                <path
                    id="Path_121"
                    d="M323.65,51.69c-34.93-6.51-72.53,2.11-95.85,29.5-17.44,20.49-31.62,47.24-52.3,65.75-22.4,20.06-51.39,31.15-74.34,50.57-27.54,23.29-45.76,58.19-46,94.26s18.17,71.42,49.41,89.45c22.76,13.14,52.63,16.93,78.88,15.54s41.42-1.8,76.15-9.4c38.47-8.4,78.67-2,118-.34,26.48,1.15,55,1.58,76.95-13.25,26.79-18.1,35.29-58.46,18.07-85.81C459.51,267.13,435,255,423.57,233.22c-13.27-25.36-5.32-56.16-8.26-84.64a110.23,110.23,0,0,0-40.84-74.27,117.13,117.13,0,0,0-50.82-22.62Z"
                    fill="#f4f5f6"
                />
                <path
                    id="Path_122"
                    d="M88.34,210a43.87,43.87,0,0,1,10.81-8.55,65.51,65.51,0,0,1,12.11-5.11,81.573,81.573,0,0,0-1.65,11.12c-.21,1.85-.35,3.72-.6,5.48-.13.87-.23,1.76-.39,2.61s-.35,1.65-.54,2.47a24.328,24.328,0,0,1-.71,2.39c-.27.79-.54,1.61-.87,2.4-.15.39-.32.79-.5,1.18s-.33.79-.5,1.19c-.35.79-.75,1.56-1.13,2.34a75.74,75.74,0,0,1-5.23,8.87,85.38,85.38,0,0,1-7.11,9c-1.16,1.31-2.25,2.56-3.33,3.75s-2,2.37-2.89,3.53c-.67-1.57-1.49-3.5-2.11-5.15l-1.26-3.47a37,37,0,0,1-2-17.37A31.35,31.35,0,0,1,88.34,210Z"
                    fill="#e3e2e3"
                />
                <path
                    id="Path_123"
                    d="M42.58,196.26c-.83-10.33,1.46-20.64,3.74-30.77,7,5.39,14,10.88,19.27,17.9s8.11,16.79,8.81,25.48a100.67,100.67,0,0,1-.92,26.56L64.11,229C52,220.71,43.78,211.23,42.58,196.26Z"
                    fill="#e3e2e3"
                />
                <path
                    id="Path_124"
                    d="M109.48,374a10.519,10.519,0,0,1-.43-2c-.24-1.33-.55-3.28-.94-5.81s-1-5.59-1.62-9.15c-.33-1.78-.66-3.69-1.06-5.7s-.88-4.14-1.35-6.37c-1.85-8.93-4.44-19.49-7.46-31.16-.79-2.91-1.6-5.9-2.43-8.94s-1.71-6.15-2.62-9.31l-1.37-4.77c-.46-1.6-.92-3.22-1.42-4.84q-1.47-4.87-3-9.87c-1-3.33-2.07-6.61-3.08-9.84l-1.52-4.81-1.55-4.72-3-9.18q-1.5-4.5-3-8.77c-1-2.85-2-5.63-2.9-8.33l-2.79-7.83c-.92-2.52-1.82-5-2.68-7.29-.42-1.17-.85-2.32-1.26-3.44s-.84-2.2-1.24-3.27c-1.64-4.26-3.07-8.13-4.43-11.49l-1.86-4.7c-.28-.73-.55-1.42-.81-2.08s-.52-1.28-.77-1.87c-1-2.36-1.71-4.2-2.19-5.45a10,10,0,0,1-.67-1.95,8.74,8.74,0,0,1,1,1.82c.58,1.21,1.41,3,2.47,5.34.27.58.55,1.19.84,1.84s.57,1.34.88,2l2,4.66c1.44,3.33,3,7.18,4.66,11.42l1.29,3.26c.43,1.11.87,2.25,1.31,3.42l2.78,7.26q1.38,3.8,2.86,7.82,1.5,4.035,3,8.32c1,2.86,1.88,5.78,2.96,8.78l3.06,9.18c.52,1.56,1,3.14,1.57,4.72s1,3.2,1.53,4.82c1,3.24,2.09,6.51,3.1,9.85s2,6.64,3,9.89c.5,1.62,1,3.25,1.42,4.85s.91,3.21,1.36,4.79c.9,3.16,1.79,6.27,2.58,9.33s1.61,6.05,2.38,9c3,11.72,5.43,22.33,7.14,31.31q.66,3.38,1.24,6.4c.37,2,.65,3.95,1,5.75.56,3.58,1.09,6.67,1.34,9.21s.51,4.52.64,5.86a9.06,9.06,0,0,1,.04,2.04Z"
                    fill="#fff"
                />
                <path
                    id="Path_125"
                    d="M92.36,225.48c.23.11-.88,2.75-2.31,7.12-.17.54-.35,1.12-.54,1.71s-.35,1.23-.54,1.88c-.39,1.29-.72,2.69-1.08,4.16a96,96,0,0,0-1.72,9.7c-.4,3.47-.58,6.79-.63,9.82,0,1.51,0,3,.07,4.3a15.418,15.418,0,0,0,.08,1.95c.08.62.09,1.23.13,1.8.35,4.58.81,7.4.55,7.46s-.34-.66-.67-1.92c-.08-.32-.17-.67-.26-1.06s-.16-.81-.25-1.26c-.17-.91-.38-1.94-.51-3.1-.08-.58-.16-1.18-.24-1.81s-.11-1.3-.17-2c-.14-1.37-.17-2.83-.22-4.37a80.681,80.681,0,0,1,.51-10A79,79,0,0,1,86.44,240c.41-1.48.79-2.9,1.24-4.19.22-.65.42-1.29.64-1.88l.66-1.71c.4-1.09.85-2.05,1.23-2.89.19-.42.36-.82.54-1.17l.5-1C91.87,226,92.26,225.44,92.36,225.48Z"
                    fill="#fff"
                />
                <path
                    id="Path_126"
                    d="M41.71,244.63c-2.74-2.77-5.24-5.79-7.79-8.93s-5.2-6.29-8.1-9.36a94.769,94.769,0,0,1,11.43-2.19,40.591,40.591,0,0,1,6.14-.25,23.384,23.384,0,0,1,3,.27,23.832,23.832,0,0,1,3,.58,34.45,34.45,0,0,1,11.26,5.51,43.35,43.35,0,0,1,9,8.8,40.56,40.56,0,0,1,6,11.64c.59,1.81,1,3.57,1.42,5.18s.66,3.2,1,4.63c-1.7-.54-3.67-1.18-5.35-1.64s-3-.8-3.46-.9a84.31,84.31,0,0,1-15.12-4.62,39,39,0,0,1-12.43-8.72Z"
                    fill="#e3e2e3"
                />
                <path
                    id="Path_127"
                    d="M57.66,239.82c.07-.1.71.24,1.81.93l.91.58a11.328,11.328,0,0,1,1,.75c.75.55,1.61,1.15,2.51,1.9l1.41,1.16c.49.41,1,.86,1.49,1.31,1,.91,2.06,2,3.16,3a79.429,79.429,0,0,1,6.51,7.64,78.089,78.089,0,0,1,5.42,8.45c.72,1.37,1.41,2.65,2,3.91.29.62.59,1.21.83,1.8s.48,1.15.7,1.69c.46,1.07.78,2.08,1.09,2.95.15.44.29.84.41,1.22s.2.73.29,1c.34,1.26.48,2,.37,2s-1.25-2.6-3.21-6.75l-.78-1.62c-.27-.57-.59-1.14-.9-1.74-.6-1.2-1.33-2.44-2.07-3.76-1.54-2.61-3.35-5.41-5.42-8.23a90.838,90.838,0,0,0-6.29-7.57c-1-1.09-2-2.15-3-3.08-.49-.47-.94-.94-1.4-1.36l-1.33-1.22C59.79,241.77,57.52,240,57.66,239.82Z"
                    fill="#fff"
                />
                <path
                    id="Path_128"
                    d="M52.6,283.78c-2.74-2.77-5.24-5.79-7.8-8.92s-5.19-6.3-8.09-9.37a96.579,96.579,0,0,1,11.42-2.19,40.7,40.7,0,0,1,6.14-.25,27.918,27.918,0,0,1,3,.28,20.521,20.521,0,0,1,2.95.58,34.37,34.37,0,0,1,11.27,5.5,43.3,43.3,0,0,1,9,8.8,40.18,40.18,0,0,1,6,11.64c.6,1.82,1,3.57,1.42,5.19s.66,3.19,1,4.62c-1.69-.54-3.66-1.17-5.34-1.64s-3-.8-3.46-.9A83.5,83.5,0,0,1,65,292.5a38.79,38.79,0,0,1-12.4-8.72Z"
                    fill="#e3e2e3"
                />
                <path
                    id="Path_129"
                    d="M68.55,279c.06-.09.7.24,1.81.93l.91.59a11.189,11.189,0,0,1,1,.74c.75.55,1.61,1.15,2.5,1.9l1.42,1.16c.49.41,1,.86,1.48,1.32,1,.9,2.07,1.94,3.17,3a73.721,73.721,0,0,1,11.92,16.09c.72,1.37,1.42,2.66,2,3.91.29.62.58,1.21.83,1.8s.47,1.15.7,1.69c.45,1.07.78,2.08,1.09,2.95.15.44.29.84.41,1.22s.2.73.29,1c.34,1.26.47,2,.37,2-.25.08-1.25-2.61-3.21-6.76L94.49,311c-.28-.56-.59-1.13-.9-1.73-.6-1.21-1.33-2.45-2.08-3.77-1.54-2.61-3.34-5.41-5.41-8.23a92.847,92.847,0,0,0-6.29-7.57c-1-1.09-2-2.15-3-3.08-.48-.46-.94-.93-1.4-1.36L74.08,284C70.68,280.92,68.4,279.18,68.55,279Z"
                    fill="#fff"
                />
                <path
                    id="Path_130"
                    d="M61.38,322.51c-2.75-2.77-5.25-5.79-7.8-8.92s-5.19-6.3-8.1-9.37A96.76,96.76,0,0,1,56.91,302a40.589,40.589,0,0,1,6.14-.25,27.507,27.507,0,0,1,3,.28,20.4,20.4,0,0,1,3,.58,34.57,34.57,0,0,1,11.27,5.5,43.551,43.551,0,0,1,9,8.8,40.44,40.44,0,0,1,6,11.64c.6,1.82,1,3.57,1.42,5.19s.66,3.19,1,4.62c-1.7-.54-3.66-1.17-5.34-1.64l-3.47-.9a83.5,83.5,0,0,1-15.12-4.62,38.859,38.859,0,0,1-12.43-8.69Z"
                    fill="#e3e2e3"
                />
                <path
                    id="Path_131"
                    d="M77.33,317.7c.06-.09.7.24,1.8.93l.92.59c.33.22.67.47,1,.74.74.55,1.61,1.15,2.5,1.9L85,323c.49.41,1,.86,1.48,1.32,1,.9,2.07,1.94,3.16,3A79.467,79.467,0,0,1,96.18,335a80.512,80.512,0,0,1,5.41,8.45c.72,1.37,1.42,2.66,2,3.91.28.62.58,1.21.83,1.8l.69,1.69c.46,1.07.79,2.08,1.09,2.95.15.44.3.84.41,1.22l.3,1c.34,1.26.47,2,.36,2s-1.24-2.61-3.21-6.76l-.78-1.62c-.27-.56-.59-1.13-.89-1.73-.61-1.21-1.34-2.45-2.08-3.77-1.54-2.61-3.34-5.41-5.41-8.23a90.868,90.868,0,0,0-6.3-7.57c-1-1.09-2-2.15-3-3.08-.49-.46-.94-.93-1.4-1.36l-1.32-1.22C79.45,319.65,77.18,317.91,77.33,317.7Z"
                    fill="#fff"
                />
                <path
                    id="Path_132"
                    d="M99.64,250.59A43.87,43.87,0,0,1,110.45,242a65.509,65.509,0,0,1,12.11-5.11,81.573,81.573,0,0,0-1.65,11.12c-.21,1.85-.35,3.73-.6,5.48-.13.88-.23,1.77-.39,2.61s-.35,1.65-.54,2.47a24.329,24.329,0,0,1-.71,2.39c-.27.79-.54,1.61-.86,2.4-.16.39-.33.79-.51,1.18s-.32.79-.5,1.19c-.35.79-.75,1.56-1.13,2.34a75.742,75.742,0,0,1-5.23,8.87,85.3,85.3,0,0,1-7.11,9c-1.16,1.3-2.25,2.55-3.33,3.75s-2,2.37-2.89,3.52c-.66-1.57-1.49-3.5-2.11-5.15l-1.26-3.47a36.88,36.88,0,0,1-2-17.37,31.26,31.26,0,0,1,7.9-16.63Z"
                    fill="#e3e2e3"
                />
                <path
                    id="Path_133"
                    d="M103.66,266.08c.23.11-.88,2.75-2.3,7.12-.18.54-.36,1.12-.55,1.71s-.35,1.23-.54,1.88c-.39,1.29-.72,2.69-1.08,4.16-.67,3-1.29,6.23-1.72,9.7a91.893,91.893,0,0,0-.63,9.82c0,1.52,0,3,.07,4.3a16.638,16.638,0,0,0,.08,2c.08.67.09,1.23.13,1.8.35,4.58.81,7.41.55,7.46-.11,0-.34-.66-.67-1.92-.08-.32-.16-.67-.26-1.06s-.16-.8-.25-1.26c-.17-.91-.38-1.94-.51-3.1-.08-.58-.15-1.18-.24-1.81s-.11-1.3-.17-2c-.13-1.36-.17-2.83-.22-4.37a80.68,80.68,0,0,1,.51-10,79,79,0,0,1,1.88-9.86c.41-1.48.79-2.9,1.24-4.19.22-.65.42-1.29.64-1.88l.66-1.71c.4-1.09.85-2.05,1.23-2.89.19-.42.36-.81.54-1.17s.35-.67.51-1C103.17,266.64,103.56,266,103.66,266.08Z"
                    fill="#fff"
                />
                <path
                    id="Path_134"
                    d="M108.81,286.92a43.63,43.63,0,0,1,10.82-8.55,64.358,64.358,0,0,1,12.1-5.11,83,83,0,0,0-1.65,11.11c-.21,1.85-.34,3.73-.6,5.48-.12.88-.22,1.77-.38,2.61s-.35,1.66-.54,2.47a22.775,22.775,0,0,1-.72,2.4c-.28.79-.54,1.61-.86,2.39-.16.4-.33.79-.5,1.18s-.33.8-.51,1.19c-.34.8-.74,1.56-1.12,2.34a74.177,74.177,0,0,1-5.24,8.87,85.551,85.551,0,0,1-7.1,9c-1.16,1.31-2.25,2.56-3.33,3.75s-2,2.37-2.89,3.52c-.67-1.56-1.5-3.49-2.12-5.15s-1.1-3-1.25-3.47a36.87,36.87,0,0,1-2-17.37A31.27,31.27,0,0,1,108.81,286.92Z"
                    fill="#e3e2e3"
                />
                <path
                    id="Path_135"
                    d="M112.84,302.41c.23.11-.88,2.75-2.31,7.11-.17.55-.36,1.12-.55,1.72s-.35,1.23-.54,1.87c-.39,1.29-.71,2.7-1.08,4.17-.67,3-1.28,6.22-1.71,9.69a97.618,97.618,0,0,0-.63,9.83c0,1.51,0,2.95.07,4.3a15.576,15.576,0,0,0,.08,1.95c.08.63.08,1.22.12,1.8.35,4.57.81,7.4.56,7.45s-.34-.66-.67-1.92l-.27-1.05c-.08-.39-.16-.81-.25-1.26-.16-.91-.37-1.95-.51-3.1-.07-.58-.15-1.19-.23-1.82s-.12-1.29-.18-2c-.13-1.37-.16-2.83-.21-4.38a78.741,78.741,0,0,1,.51-10,80.513,80.513,0,0,1,1.87-9.86c.42-1.49.8-2.9,1.25-4.2.22-.65.41-1.28.64-1.88s.44-1.16.65-1.7c.4-1.1.86-2.05,1.23-2.9.2-.42.37-.81.54-1.16l.51-1C112.35,303,112.74,302.36,112.84,302.41Z"
                    fill="#fff"
                />
                <path
                    id="Path_136"
                    d="M429.44,209.42a43.7,43.7,0,0,0-10.1-9.39A65.438,65.438,0,0,0,407.68,194a80.862,80.862,0,0,1,.76,11.21c.06,1.87,0,3.75.16,5.51a22.942,22.942,0,0,0,.18,2.64c.1.86.21,1.67.34,2.5s.31,1.64.52,2.45.41,1.64.67,2.45c.12.41.26.82.4,1.22s.26.82.41,1.23c.28.81.62,1.61.94,2.42a74.922,74.922,0,0,0,4.51,9.26,84.994,84.994,0,0,0,6.36,9.55c1.05,1.4,2,2.73,3,4s1.8,2.52,2.6,3.74c.79-1.51,1.76-3.37,2.52-5l1.53-3.35A37.07,37.07,0,0,0,436,226.68a31.32,31.32,0,0,0-6.56-17.26Z"
                    fill="#e3e2e3"
                />
                <path
                    id="Path_137"
                    d="M476.14,199.38c1.66-10.23.2-20.69-1.26-31-7.38,4.82-14.85,9.72-20.65,16.3s-9.42,16.08-10.81,24.69A101.091,101.091,0,0,0,442.21,236c3.73-2.15,8.9-5.16,9.86-5.71C464.79,223,473.75,214.22,476.14,199.38Z"
                    fill="#e3e2e3"
                />
                <path
                    id="Path_138"
                    d="M395.26,371.21a9,9,0,0,0,.6-2c.34-1.3.81-3.22,1.4-5.71s1.4-5.49,2.34-9c.48-1.75.95-3.63,1.52-5.6s1.2-4.05,1.85-6.24c2.55-8.76,6-19.07,9.92-30.47l3.14-8.72c1.05-3,2.19-6,3.35-9.06l1.75-4.65c.59-1.56,1.18-3.14,1.8-4.71l3.77-9.6c1.27-3.24,2.59-6.42,3.86-9.56l1.9-4.68q1-2.31,1.92-4.58,1.9-4.55,3.75-8.91l3.69-8.5c1.18-2.78,2.39-5.46,3.56-8.07s2.3-5.14,3.4-7.59,2.21-4.78,3.25-7l1.53-3.32,1.51-3.16c2-4.12,3.7-7.87,5.33-11.11.8-1.62,1.54-3.14,2.22-4.53l1-2,.91-1.8c1.16-2.27,2-4.05,2.63-5.26a11,11,0,0,0,.82-1.89,9.56,9.56,0,0,0-1.11,1.73c-.68,1.17-1.65,2.9-2.89,5.13l-1,1.77-1,2-2.35,4.49c-1.71,3.2-3.52,6.92-5.56,11l-1.55,3.14c-.51,1.08-1,2.19-1.58,3.31l-3.34,7c-1.12,2.44-2.28,5-3.48,7.57s-2.42,5.28-3.63,8-2.47,5.59-3.74,8.49-2.51,5.88-3.79,8.91L431,260.64q-.94,2.31-1.91,4.68c-1.28,3.14-2.61,6.32-3.88,9.57s-2.52,6.46-3.76,9.62c-.63,1.58-1.21,3.16-1.8,4.72l-1.73,4.66c-1.16,3.08-2.29,6.11-3.33,9.1s-2.08,5.9-3.09,8.75c-3.87,11.45-7.19,21.82-9.61,30.64q-.93,3.3-1.75,6.29-.795,3-1.4,5.64c-.85,3.53-1.63,6.56-2.08,9.08s-.87,4.46-1.1,5.79a9.858,9.858,0,0,0-.3,2.03Z"
                    fill="#fff"
                />
                <path
                    id="Path_139"
                    d="M424.19,224.54c-.24.09.66,2.81,1.73,7.27l.41,1.76c.14.61.25,1.25.39,1.91.28,1.32.49,2.74.74,4.24a96.259,96.259,0,0,1,.94,9.8c.12,3.49,0,6.82-.16,9.84-.13,1.51-.23,2.95-.41,4.28-.09.67-.14,1.32-.24,1.94s-.18,1.22-.27,1.78c-.71,4.54-1.39,7.32-1.15,7.39s.4-.63.83-1.86c.1-.31.22-.65.34-1s.23-.79.35-1.24c.24-.89.54-1.9.76-3.05.12-.57.25-1.16.38-1.79s.22-1.28.33-1.95c.24-1.35.39-2.81.57-4.34a73.429,73.429,0,0,0-.79-20c-.3-1.52-.56-3-.91-4.29-.17-.66-.31-1.31-.49-1.92s-.35-1.2-.51-1.76c-.32-1.12-.69-2.1-1-3-.16-.43-.3-.84-.45-1.2s-.3-.7-.42-1C424.63,225.14,424.29,224.5,424.19,224.54Z"
                    fill="#fff"
                />
                <path
                    id="Path_140"
                    d="M473.14,247.67a113.785,113.785,0,0,0,8.49-8.27c2.8-2.92,5.68-5.86,8.82-8.7a98.856,98.856,0,0,0-11.22-3.09,41.477,41.477,0,0,0-6.1-.74c-1-.05-2,0-3,0a21.188,21.188,0,0,0-3,.35,34.661,34.661,0,0,0-11.67,4.58,43.35,43.35,0,0,0-9.63,8.06A40,40,0,0,0,438.88,251c-.74,1.75-1.31,3.47-1.83,5s-.92,3.13-1.36,4.53c1.74-.4,3.75-.88,5.46-1.21s3.1-.55,3.52-.62a83.579,83.579,0,0,0,15.44-3.4,38.91,38.91,0,0,0,13.03-7.63Z"
                    fill="#e3e2e3"
                />
                <path
                    id="Path_141"
                    d="M457.63,241.6c-.06-.1-.72.19-1.87.79l-1,.5-1.11.66c-.78.49-1.7,1-2.64,1.7l-1.51,1c-.52.37-1,.78-1.58,1.19-1.11.82-2.22,1.77-3.4,2.77a79,79,0,0,0-7.1,7.09,78.2,78.2,0,0,0-6.07,8c-.83,1.3-1.63,2.53-2.28,3.74-.34.6-.68,1.16-1,1.73l-.83,1.63c-.55,1-.95,2-1.33,2.85-.18.42-.36.82-.5,1.18s-.26.72-.38,1c-.44,1.23-.63,1.92-.52,2S426,277,428.32,273l.9-1.55c.32-.54.68-1.09,1-1.66.69-1.15,1.52-2.34,2.37-3.59a96.239,96.239,0,0,1,6.05-7.77,86.627,86.627,0,0,1,6.88-7c1.13-1,2.19-2,3.24-2.83.52-.43,1-.86,1.5-1.25l1.42-1.1C455.36,243.37,457.76,241.82,457.63,241.6Z"
                    fill="#fff"
                />
                <path
                    id="Path_142"
                    d="M459.17,285.82c2.95-2.54,5.69-5.34,8.48-8.27s5.68-5.85,8.82-8.69a97.2,97.2,0,0,0-11.21-3.09,40.458,40.458,0,0,0-6.11-.74,28.011,28.011,0,0,0-3,0,22.6,22.6,0,0,0-3,.34,34.74,34.74,0,0,0-11.7,4.63,43.419,43.419,0,0,0-9.64,8.05,40.541,40.541,0,0,0-6.91,11.13c-.74,1.76-1.3,3.48-1.83,5.05s-.92,3.14-1.36,4.54c1.74-.4,3.75-.88,5.46-1.21s3.1-.55,3.53-.62a84.471,84.471,0,0,0,15.43-3.4A38.91,38.91,0,0,0,459.17,285.82Z"
                    fill="#e3e2e3"
                />
                <path
                    id="Path_143"
                    d="M443.65,279.76c0-.1-.72.18-1.87.78l-1,.51c-.35.19-.71.42-1.11.66-.78.49-1.69,1-2.64,1.7l-1.51,1c-.52.36-1,.78-1.58,1.19-1.11.82-2.22,1.77-3.4,2.77a73.609,73.609,0,0,0-13.17,15.08c-.83,1.3-1.62,2.53-2.28,3.74-.34.59-.68,1.16-1,1.73l-.83,1.63c-.54,1-.95,2-1.33,2.85-.18.42-.36.81-.5,1.18s-.26.71-.38,1c-.43,1.23-.63,1.92-.52,2s1.45-2.49,3.74-6.47l.91-1.56c.31-.54.67-1.08,1-1.65.7-1.16,1.52-2.34,2.37-3.59a96.248,96.248,0,0,1,6.05-7.77,86.632,86.632,0,0,1,6.88-7c1.13-1,2.19-2,3.24-2.83.52-.43,1-.86,1.5-1.25l1.42-1.11C441.38,281.53,443.78,280,443.65,279.76Z"
                    fill="#fff"
                />
                <path
                    id="Path_144"
                    d="M447.32,323.73a113.791,113.791,0,0,0,8.49-8.27c2.8-2.92,5.68-5.86,8.82-8.69a94.868,94.868,0,0,0-11.22-3.09,40.182,40.182,0,0,0-6.1-.75c-1-.05-2,0-3,0a21.18,21.18,0,0,0-3,.34,34.58,34.58,0,0,0-11.67,4.58A43.629,43.629,0,0,0,420,316a40.3,40.3,0,0,0-6.92,11.13c-.74,1.76-1.3,3.47-1.82,5.05s-.92,3.14-1.36,4.54c1.74-.41,3.74-.89,5.46-1.21s3.1-.56,3.52-.63a83.606,83.606,0,0,0,15.44-3.39,38.77,38.77,0,0,0,13-7.76Z"
                    fill="#e3e2e3"
                />
                <path
                    id="Path_145"
                    d="M431.81,317.66c-.06-.1-.72.19-1.88.79L429,319c-.35.19-.71.42-1.11.66-.78.48-1.7,1-2.64,1.69l-1.51,1c-.52.37-1,.78-1.58,1.2-1.11.81-2.22,1.77-3.4,2.76a80.357,80.357,0,0,0-7.1,7.09,79.309,79.309,0,0,0-6.07,8c-.83,1.31-1.63,2.54-2.29,3.74-.33.6-.67,1.17-1,1.73l-.83,1.63c-.55,1-.95,2-1.33,2.85-.18.43-.36.82-.5,1.19l-.38,1c-.44,1.23-.63,1.93-.53,2s1.46-2.49,3.75-6.48l.9-1.55c.32-.54.68-1.08,1-1.66.69-1.15,1.52-2.33,2.36-3.59,1.75-2.47,3.77-5.12,6.06-7.76a89.309,89.309,0,0,1,6.88-7.05c1.13-1,2.19-2,3.24-2.83.52-.42,1-.86,1.5-1.24l1.42-1.11C429.53,319.43,431.94,317.88,431.81,317.66Z"
                    fill="#fff"
                />
                <path
                    id="Path_146"
                    d="M414.93,249a43.7,43.7,0,0,0-10.1-9.39,64.789,64.789,0,0,0-11.66-6.06,80.862,80.862,0,0,1,.76,11.21c.06,1.86.05,3.75.16,5.51.06.89.09,1.78.18,2.63s.22,1.68.34,2.51a22.7,22.7,0,0,0,.52,2.45c.21.81.41,1.64.67,2.45.12.41.26.81.4,1.22s.27.82.41,1.23c.28.81.62,1.61.94,2.42a74.1,74.1,0,0,0,4.51,9.25,84.317,84.317,0,0,0,6.36,9.56c1.05,1.4,2,2.73,3,4s1.81,2.52,2.6,3.74c.79-1.51,1.77-3.37,2.52-5l1.53-3.35a37.07,37.07,0,0,0,3.38-17.16A31.19,31.19,0,0,0,414.93,249Z"
                    fill="#ababef"
                />
                <path
                    id="Path_147"
                    d="M409.68,264.11c-.24.09.66,2.81,1.73,7.27l.41,1.76c.14.61.25,1.25.39,1.91.29,1.32.5,2.74.75,4.24.43,3,.78,6.31.93,9.8a95.578,95.578,0,0,1-.16,9.84c-.13,1.51-.23,2.95-.41,4.28-.08.67-.14,1.32-.23,1.94s-.19,1.21-.27,1.78c-.72,4.54-1.4,7.32-1.15,7.39a5.618,5.618,0,0,0,.82-1.86l.35-1c.11-.38.22-.79.35-1.24.23-.89.53-1.9.75-3.05.12-.57.25-1.16.38-1.79s.22-1.28.33-2c.24-1.35.39-2.81.57-4.34a73.53,73.53,0,0,0-.79-20c-.3-1.51-.56-3-.91-4.28-.17-.66-.31-1.31-.48-1.92s-.36-1.2-.52-1.76c-.32-1.12-.69-2.11-1-3-.16-.43-.3-.84-.44-1.21l-.43-1C410.12,264.71,409.79,264.07,409.68,264.11Z"
                    fill="#fff"
                />
                <path
                    id="Path_148"
                    d="M402.89,284.47a43.9,43.9,0,0,0-10.1-9.39A64.16,64.16,0,0,0,381.13,269a82.342,82.342,0,0,1,.75,11.21c.07,1.86.05,3.74.16,5.51.06.88.09,1.78.18,2.63s.22,1.68.34,2.51a22.521,22.521,0,0,0,.52,2.44c.21.81.41,1.65.67,2.46.13.41.26.81.41,1.21s.26.82.4,1.23c.29.82.62,1.62.94,2.42a74.929,74.929,0,0,0,4.5,9.28,85.679,85.679,0,0,0,6.37,9.56c1.05,1.4,2,2.73,3,4s1.81,2.53,2.6,3.74c.79-1.5,1.77-3.36,2.52-5l1.53-3.36a36.891,36.891,0,0,0,3.39-17.15A31.34,31.34,0,0,0,402.89,284.47Z"
                    fill="#ababef"
                />
                <path
                    id="Path_149"
                    d="M397.64,299.58c-.25.1.65,2.81,1.73,7.28.13.56.26,1.14.41,1.75s.25,1.26.38,1.92c.29,1.31.5,2.74.75,4.23.43,3,.78,6.31.93,9.8a95.764,95.764,0,0,1-.16,9.85c-.13,1.51-.22,2.94-.41,4.28-.08.67-.14,1.32-.23,1.94s-.18,1.21-.27,1.78c-.72,4.53-1.4,7.32-1.15,7.39s.39-.63.82-1.86c.11-.31.22-.66.35-1s.22-.8.35-1.24c.24-.89.53-1.91.76-3.05.12-.57.24-1.17.37-1.79s.22-1.28.34-2c.24-1.35.39-2.8.56-4.34a79.136,79.136,0,0,0,.29-10,77.427,77.427,0,0,0-1.08-10c-.29-1.51-.56-2.95-.91-4.28-.16-.67-.31-1.31-.48-1.93s-.35-1.19-.52-1.75c-.31-1.12-.69-2.11-1-3-.16-.44-.3-.84-.45-1.21s-.3-.7-.43-1C398.08,300.18,397.74,299.55,397.64,299.58Z"
                    fill="#fff"
                />
                <path
                    id="Path_150"
                    d="M284.07,357.55c3.24,9.5,7.95,22.66-1.33,30.92a8.68,8.68,0,0,1-2.16,1.43,8.07,8.07,0,0,0,3.4-2C293.05,379.47,287.74,366.75,284.07,357.55Z"
                    fill="#ffb088"
                />
                <rect
                    id="Rectangle_89"
                    width="176.71"
                    height="149.5"
                    rx="14.12"
                    transform="translate(246.01 167.34)"
                    fill="#fcfcfc"
                    stroke="#e3e2e3"
                    strokeMiterlimit="10"
                    strokeWidth="2"
                />
                <rect
                    id="Rectangle_90"
                    width="18.58"
                    height="18.46"
                    rx="1.49"
                    transform="translate(346.39 279.91)"
                    fill="#e3e2e3"
                />
                <rect
                    id="Rectangle_91"
                    width="20.58"
                    height="18.86"
                    rx="1.49"
                    transform="translate(262.2 259.36)"
                    fill="#f4f5f6"
                />
                <rect
                    id="Rectangle_92"
                    width="20.58"
                    height="18.46"
                    rx="1.49"
                    transform="translate(262.2 279.91)"
                    fill="#f4f5f6"
                />
                <rect
                    id="Rectangle_93"
                    width="18.58"
                    height="18.46"
                    rx="1.49"
                    transform="translate(325.84 279.91)"
                    fill="#f4f5f6"
                />
                <rect
                    id="Rectangle_94"
                    width="18.58"
                    height="18.46"
                    rx="1.49"
                    transform="translate(284.75 279.91)"
                    fill="#f4f5f6"
                />
                <rect
                    id="Rectangle_95"
                    width="18.58"
                    height="18.46"
                    rx="1.49"
                    transform="translate(305.3 279.91)"
                    fill="#f4f5f6"
                />
                <rect
                    id="Rectangle_96"
                    width="20.58"
                    height="17.45"
                    rx="1.49"
                    transform="translate(262.2 240.22)"
                    fill="#f4f5f6"
                />
                <rect
                    id="Rectangle_97"
                    width="18.58"
                    height="19.66"
                    rx="1.49"
                    transform="translate(366.93 218.87)"
                    fill="#f4f5f6"
                />
                <rect
                    id="Rectangle_98"
                    width="20.56"
                    height="19.66"
                    rx="1.49"
                    transform="translate(387.48 218.87)"
                    fill="#f4f5f6"
                />
                <rect
                    id="Rectangle_99"
                    width="18.58"
                    height="19.66"
                    rx="1.49"
                    transform="translate(346.39 218.87)"
                    fill="#f4f5f6"
                />
                <rect
                    id="Rectangle_100"
                    width="18.58"
                    height="19.66"
                    rx="1.49"
                    transform="translate(305.3 218.87)"
                    fill="#f4f5f6"
                />
                <rect
                    id="Rectangle_101"
                    width="18.58"
                    height="19.66"
                    rx="1.49"
                    transform="translate(284.75 218.87)"
                    fill="#f4f5f6"
                />
                <rect
                    id="Rectangle_102"
                    width="18.58"
                    height="19.66"
                    rx="1.49"
                    transform="translate(325.84 218.87)"
                    fill="#f4f5f6"
                />
                <rect
                    id="Rectangle_103"
                    width="20.58"
                    height="19.66"
                    rx="1.49"
                    transform="translate(262.2 218.87)"
                    fill="#f4f5f6"
                />
                <rect
                    id="Rectangle_104"
                    width="18.58"
                    height="17.45"
                    rx="1.49"
                    transform="translate(305.3 240.22)"
                    fill="#f4f5f6"
                />
                <rect
                    id="Rectangle_105"
                    width="18.58"
                    height="17.45"
                    rx="1.49"
                    transform="translate(346.39 240.22)"
                    fill="#f4f5f6"
                />
                <rect
                    id="Rectangle_106"
                    width="18.58"
                    height="18.86"
                    rx="1.49"
                    transform="translate(366.93 259.36)"
                    fill="#f4f5f6"
                />
                <rect
                    id="Rectangle_107"
                    width="18.58"
                    height="17.45"
                    rx="1.49"
                    transform="translate(325.84 240.22)"
                    fill="#f4f5f6"
                />
                <rect
                    id="Rectangle_108"
                    width="18.58"
                    height="18.86"
                    rx="1.49"
                    transform="translate(346.39 259.36)"
                    fill="#f4f5f6"
                />
                <rect
                    id="Rectangle_109"
                    width="18.58"
                    height="17.45"
                    rx="1.49"
                    transform="translate(366.93 240.22)"
                    fill="#f4f5f6"
                />
                <rect
                    id="Rectangle_110"
                    width="18.58"
                    height="18.46"
                    rx="1.49"
                    transform="translate(366.93 279.91)"
                    fill="#f4f5f6"
                />
                <rect
                    id="Rectangle_111"
                    width="20.56"
                    height="17.45"
                    rx="1.49"
                    transform="translate(387.48 240.22)"
                    fill="#f4f5f6"
                />
                <rect
                    id="Rectangle_112"
                    width="20.56"
                    height="18.86"
                    rx="1.49"
                    transform="translate(387.48 259.36)"
                    fill="#f4f5f6"
                />
                <rect
                    id="Rectangle_113"
                    width="18.58"
                    height="18.86"
                    rx="1.49"
                    transform="translate(325.84 259.36)"
                    fill="#f4f5f6"
                />
                <rect
                    id="Rectangle_114"
                    width="20.56"
                    height="18.46"
                    rx="1.49"
                    transform="translate(387.48 279.91)"
                    fill="#f4f5f6"
                />
                <rect
                    id="Rectangle_115"
                    width="18.58"
                    height="18.86"
                    rx="1.49"
                    transform="translate(284.75 259.36)"
                    fill="#f4f5f6"
                />
                <rect
                    id="Rectangle_116"
                    width="18.58"
                    height="18.86"
                    rx="1.49"
                    transform="translate(305.3 259.36)"
                    fill="#f4f5f6"
                />
                <rect
                    id="Rectangle_117"
                    width="18.58"
                    height="17.45"
                    rx="1.49"
                    transform="translate(284.75 240.22)"
                    fill="#f4f5f6"
                />
                <text
                    id="_1"
                    transform="translate(286.89 225.1)"
                    fill="#707171"
                    fontSize="5.21"
                    fontFamily="MyriadPro-Regular, Myriad Pro"
                >
                    <tspan x="0" y="0">
                        1
                    </tspan>
                </text>
                <text
                    id="_2"
                    transform="translate(307.67 224.77)"
                    fill="#707171"
                    fontSize="5.21"
                    fontFamily="MyriadPro-Regular, Myriad Pro"
                >
                    <tspan x="0" y="0">
                        2
                    </tspan>
                </text>
                <text
                    id="_3"
                    transform="translate(327.96 225.1)"
                    fill="#707171"
                    fontSize="5.21"
                    fontFamily="MyriadPro-Regular, Myriad Pro"
                >
                    <tspan x="0" y="0">
                        3
                    </tspan>
                </text>
                <text
                    id="_4"
                    transform="translate(348.4 224.61)"
                    fill="#707171"
                    fontSize="5.21"
                    fontFamily="MyriadPro-Regular, Myriad Pro"
                >
                    <tspan x="0" y="0">
                        4
                    </tspan>
                </text>
                <text
                    id="_5"
                    transform="translate(369.01 225.1)"
                    fill="#707171"
                    fontSize="5.21"
                    fontFamily="MyriadPro-Regular, Myriad Pro"
                >
                    <tspan x="0" y="0">
                        5
                    </tspan>
                </text>
                <text
                    id="_6"
                    transform="translate(389.4 225.1)"
                    fill="#707171"
                    fontSize="5.21"
                    fontFamily="MyriadPro-Regular, Myriad Pro"
                >
                    <tspan x="0" y="0">
                        6
                    </tspan>
                </text>
                <text
                    id="_7"
                    transform="translate(263.11 246.56)"
                    fill="#707171"
                    fontSize="5.21"
                    fontFamily="MyriadPro-Regular, Myriad Pro"
                >
                    <tspan x="0" y="0">
                        7
                    </tspan>
                </text>
                <text
                    id="_8"
                    transform="translate(285.45 246.23)"
                    fill="#707171"
                    fontSize="5.21"
                    fontFamily="MyriadPro-Regular, Myriad Pro"
                >
                    <tspan x="0" y="0">
                        8
                    </tspan>
                </text>
                <text
                    id="_9"
                    transform="translate(305.73 246.56)"
                    fill="#707171"
                    fontSize="5.21"
                    fontFamily="MyriadPro-Regular, Myriad Pro"
                >
                    <tspan x="0" y="0">
                        9
                    </tspan>
                </text>
                <text
                    id="_10"
                    transform="translate(326.18 246.06)"
                    fill="#707171"
                    fontSize="5.21"
                    fontFamily="MyriadPro-Regular, Myriad Pro"
                >
                    <tspan x="0" y="0">
                        10
                    </tspan>
                </text>
                <text
                    id="_11"
                    transform="translate(346.79 246.56)"
                    fill="#707171"
                    fontSize="5.21"
                    fontFamily="MyriadPro-Regular, Myriad Pro"
                >
                    <tspan x="0" y="0">
                        11
                    </tspan>
                </text>
                <text
                    id="_12"
                    transform="translate(367.18 246.56)"
                    fill="#707171"
                    fontSize="5.21"
                    fontFamily="MyriadPro-Regular, Myriad Pro"
                >
                    <tspan x="0" y="0">
                        12
                    </tspan>
                </text>
                <text
                    id="_13"
                    transform="translate(387.93 246.79)"
                    fill="#707171"
                    fontSize="5.21"
                    fontFamily="MyriadPro-Regular, Myriad Pro"
                >
                    <tspan x="0" y="0">
                        13
                    </tspan>
                </text>
                <text
                    id="_14"
                    transform="translate(263.11 265.2)"
                    fill="#707171"
                    fontSize="5.21"
                    fontFamily="MyriadPro-Regular, Myriad Pro"
                >
                    <tspan x="0" y="0">
                        14
                    </tspan>
                </text>
                <text
                    id="_15"
                    transform="translate(285.45 264.87)"
                    fill="#707171"
                    fontSize="5.21"
                    fontFamily="MyriadPro-Regular, Myriad Pro"
                >
                    <tspan x="0" y="0">
                        15
                    </tspan>
                </text>
                <text
                    id="_16"
                    transform="translate(305.73 265.2)"
                    fill="#707171"
                    fontSize="5.21"
                    fontFamily="MyriadPro-Regular, Myriad Pro"
                >
                    <tspan x="0" y="0">
                        16
                    </tspan>
                </text>
                <text
                    id="_17"
                    transform="translate(326.18 264.71)"
                    fill="#707171"
                    fontSize="5.21"
                    fontFamily="MyriadPro-Regular, Myriad Pro"
                >
                    <tspan x="0" y="0">
                        17
                    </tspan>
                </text>
                <text
                    id="_18"
                    transform="translate(346.79 265.2)"
                    fill="#707171"
                    fontSize="5.21"
                    fontFamily="MyriadPro-Regular, Myriad Pro"
                >
                    <tspan x="0" y="0">
                        18
                    </tspan>
                </text>
                <text
                    id="_19"
                    transform="translate(367.18 265.2)"
                    fill="#707171"
                    fontSize="5.21"
                    fontFamily="MyriadPro-Regular, Myriad Pro"
                >
                    <tspan x="0" y="0">
                        19
                    </tspan>
                </text>
                <text
                    id="_20"
                    transform="translate(387.93 265.44)"
                    fill="#707171"
                    fontSize="5.21"
                    fontFamily="MyriadPro-Regular, Myriad Pro"
                >
                    <tspan x="0" y="0">
                        20
                    </tspan>
                </text>
                <text
                    id="_21"
                    transform="translate(263.11 285.17)"
                    fill="#707171"
                    fontSize="5.21"
                    fontFamily="MyriadPro-Regular, Myriad Pro"
                >
                    <tspan x="0" y="0">
                        21
                    </tspan>
                </text>
                <text
                    id="_22"
                    transform="translate(285.45 284.84)"
                    fill="#707171"
                    fontSize="5.21"
                    fontFamily="MyriadPro-Regular, Myriad Pro"
                >
                    <tspan x="0" y="0">
                        22
                    </tspan>
                </text>
                <text
                    id="_23"
                    transform="translate(305.73 285.17)"
                    fill="#707171"
                    fontSize="5.21"
                    fontFamily="MyriadPro-Regular, Myriad Pro"
                >
                    <tspan x="0" y="0">
                        23
                    </tspan>
                </text>
                <text
                    id="_24"
                    transform="translate(326.18 284.68)"
                    fill="#707171"
                    fontSize="5.21"
                    fontFamily="MyriadPro-Regular, Myriad Pro"
                >
                    <tspan x="0" y="0">
                        24
                    </tspan>
                </text>
                <text
                    id="_25"
                    transform="translate(346.79 285.17)"
                    fill="#707171"
                    fontSize="5.21"
                    fontFamily="MyriadPro-Regular, Myriad Pro"
                >
                    <tspan x="0" y="0">
                        25
                    </tspan>
                </text>
                <text
                    id="_26"
                    transform="translate(367.18 285.17)"
                    fill="#707171"
                    fontSize="5.21"
                    fontFamily="MyriadPro-Regular, Myriad Pro"
                >
                    <tspan x="0" y="0">
                        26
                    </tspan>
                </text>
                <text
                    id="_27"
                    transform="translate(387.93 285.4)"
                    fill="#707171"
                    fontSize="5.21"
                    fontFamily="MyriadPro-Regular, Myriad Pro"
                >
                    <tspan x="0" y="0">
                        27
                    </tspan>
                </text>
                <path
                    id="Path_151"
                    d="M419.68,199.84H250.14a1.18,1.18,0,0,1-1.19-1.18h0a1.18,1.18,0,0,1,1.19-1.18H419.68a1.18,1.18,0,0,1,1.18,1.18h0a1.18,1.18,0,0,1-1.18,1.18Z"
                    fill="#e3e2e3"
                />
                <text
                    id="S"
                    transform="translate(268.19 214.53)"
                    fill="#707171"
                    fontSize="13.72"
                    fontFamily="MyriadPro-Regular, Myriad Pro"
                >
                    <tspan x="0" y="0">
                        S
                    </tspan>
                </text>
                <text
                    id="M"
                    transform="translate(289.19 214.53)"
                    fill="#707171"
                    fontSize="13.72"
                    fontFamily="MyriadPro-Regular, Myriad Pro"
                >
                    <tspan x="0" y="0">
                        M
                    </tspan>
                </text>
                <text
                    id="T"
                    transform="translate(309.54 214.53)"
                    fill="#707171"
                    fontSize="13.72"
                    fontFamily="MyriadPro-Regular, Myriad Pro"
                >
                    <tspan x="0" y="0">
                        T
                    </tspan>
                </text>
                <text
                    id="W"
                    transform="translate(330.87 214.53)"
                    fill="#707171"
                    fontSize="13.72"
                    fontFamily="MyriadPro-Regular, Myriad Pro"
                >
                    <tspan x="0" y="0">
                        W
                    </tspan>
                </text>
                <text
                    id="DECEMBER"
                    transform="translate(303.67 193.22)"
                    fill="#707171"
                    fontSize="13.72"
                    fontFamily="MyriadPro-Regular, Myriad Pro"
                >
                    <tspan x="0" y="0">
                        DECEMBER
                    </tspan>
                </text>
                <text
                    id="T-2"
                    transform="translate(350.64 214.53)"
                    fill="#707171"
                    fontSize="13.72"
                    fontFamily="MyriadPro-Regular, Myriad Pro"
                >
                    <tspan x="0" y="0">
                        T
                    </tspan>
                </text>
                <text
                    id="F"
                    transform="translate(370.6 214.53)"
                    fill="#707171"
                    fontSize="13.72"
                    fontFamily="MyriadPro-Regular, Myriad Pro"
                >
                    <tspan x="0" y="0">
                        F
                    </tspan>
                </text>
                <text
                    id="S-2"
                    transform="translate(392.42 214.53)"
                    fill="#707171"
                    fontSize="13.72"
                    fontFamily="MyriadPro-Regular, Myriad Pro"
                >
                    <tspan x="0" y="0">
                        S
                    </tspan>
                </text>
                <path
                    id="Path_152"
                    d="M276.29,153.36a13.29,13.29,0,0,0,0,26.57h.14v-2.08h-.14a11.21,11.21,0,1,1,11.21-11.21v.6h2.1v-.6a13.3,13.3,0,0,0-13.31-13.28Z"
                    fill="#707171"
                />
                <path
                    id="Path_153"
                    d="M314.11,153.36a13.29,13.29,0,1,0,0,26.57h.14v-2.08h-.14a11.21,11.21,0,1,1,11.21-11.21v.6h2.09v-.6a13.3,13.3,0,0,0-13.3-13.28Z"
                    fill="#707171"
                />
                <path
                    id="Path_154"
                    d="M351.93,153.36a13.29,13.29,0,1,0,0,26.57h.14v-2.08h-.14a11.21,11.21,0,1,1,11.2-11.21q0,.3,0,.6h2.1v-.6a13.31,13.31,0,0,0-13.3-13.28Z"
                    fill="#707171"
                />
                <path
                    id="Path_155"
                    d="M389.75,153.36a13.29,13.29,0,1,0,0,26.57h.14v-2.08h-.14A11.21,11.21,0,1,1,401,166.64q0,.3,0,.6h2v-.6A13.3,13.3,0,0,0,389.75,153.36Z"
                    fill="#707171"
                />
                <path
                    id="Path_156"
                    d="M298.93,185.62l-3.32,2.7a.89.89,0,0,0,0,1.38l3.31,2.71"
                    fill="none"
                    stroke="#e3e2e3"
                    strokeLinecap="round"
                    strokeMiterlimit="10"
                    strokeWidth="2"
                />
                <path
                    id="Path_157"
                    d="M371.14,185.62l3.32,2.7a.89.89,0,0,1,0,1.38l-3.32,2.71"
                    fill="none"
                    stroke="#e3e2e3"
                    strokeLinecap="round"
                    strokeMiterlimit="10"
                    strokeWidth="2"
                />
                <path
                    id="Path_158"
                    d="M406.23,309.19H365.9a.88.88,0,0,1-.76-.45l-8.22-14.31a.88.88,0,0,0-1.53,0l-8.22,14.31a.88.88,0,0,1-.77.45H306.07a1.64,1.64,0,0,0-1.64,1.64V372.9a1.64,1.64,0,0,0,1.64,1.64H406.23a1.63,1.63,0,0,0,1.64-1.64V310.83a1.63,1.63,0,0,0-1.64-1.64Z"
                    fill="#e3e2e3"
                />
                <path
                    id="Path_159"
                    d="M331.85,322.86A8.14,8.14,0,1,0,318.42,329a11,11,0,0,1,2.28,2.8A28.741,28.741,0,0,1,323,337a.77.77,0,0,0,1.46,0,28.517,28.517,0,0,1,2.22-5,11.27,11.27,0,0,1,2.32-3,8.11,8.11,0,0,0,2.85-6.14Z"
                    fill="#707171"
                />
                <path
                    id="Path_160"
                    d="M328.09,322.72a4.37,4.37,0,1,1-1.283-3.094,4.38,4.38,0,0,1,1.283,3.094Z"
                    fill="#fcfcfc"
                />
                <path
                    id="Path_161"
                    d="M325.68,322.72a2,2,0,1,1-2-2A2,2,0,0,1,325.68,322.72Z"
                    fill="#707171"
                />
                <path
                    id="Path_162"
                    d="M399.79,345.16H310.51a.58.58,0,0,1-.57-.58h0a.58.58,0,0,1,.57-.58h89.28a.58.58,0,0,1,.58.58h0A.58.58,0,0,1,399.79,345.16Z"
                    fill="#707171"
                />
                <path
                    id="Path_163"
                    d="M393.21,327.51H344.88a.8.8,0,0,1-.8-.8h0a.79.79,0,0,1,.8-.79h48.33a.79.79,0,0,1,.79.79h0a.8.8,0,0,1-.79.8Z"
                    fill="#fff"
                />
                <path
                    id="Path_164"
                    d="M377.06,335.77H344.88a.8.8,0,0,1-.8-.8h0a.8.8,0,0,1,.8-.8h32.18a.8.8,0,0,1,.79.8h0a.8.8,0,0,1-.79.8Z"
                    fill="#fff"
                />
                <path
                    id="Path_165"
                    d="M350.16,353.47H318a.8.8,0,0,1-.79-.8h0a.8.8,0,0,1,.79-.79h32.18a.8.8,0,0,1,.8.79h0a.8.8,0,0,1-.82.8Z"
                    fill="#fff"
                />
                <path
                    id="Path_166"
                    d="M374.05,360.5H318a.8.8,0,0,1-.79-.8h0a.8.8,0,0,1,.79-.8h56.07a.8.8,0,0,1,.8.8h0a.8.8,0,0,1-.82.8Z"
                    fill="#fff"
                />
                <path
                    id="Path_167"
                    d="M374.05,368H318a.8.8,0,0,1-.79-.8h0a.8.8,0,0,1,.79-.79h56.07a.8.8,0,0,1,.8.79h0a.8.8,0,0,1-.82.8Z"
                    fill="#fff"
                />
                <circle
                    id="Ellipse_10"
                    cx="24.28"
                    cy="24.28"
                    r="24.28"
                    transform="translate(369.05 93.58)"
                    fill="#fff"
                />
                <path
                    id="Path_168"
                    d="M393.33,145.3a27.44,27.44,0,1,1,27.43-27.437A27.44,27.44,0,0,1,393.33,145.3Zm0-48.56a21.13,21.13,0,1,0,21.12,21.123A21.13,21.13,0,0,0,393.33,96.74Z"
                    fill="#e3e2e3"
                />
                <path id="Path_169" d="M384.32,127.29l9.02-7.69V101.28" fill="#f2d3e1" />
                <path
                    id="Path_170"
                    d="M384.33,129.92a2.63,2.63,0,0,1-1.71-4.63l8.09-6.9V101.28a2.63,2.63,0,1,1,5.26,0v19.53L386,129.29A2.62,2.62,0,0,1,384.33,129.92Z"
                    fill="#707171"
                />
                <rect
                    id="Rectangle_118"
                    width="106.05"
                    height="50.63"
                    rx="1.6"
                    transform="translate(246.73 94.45)"
                    fill="#fff"
                />
                <rect
                    id="Rectangle_119"
                    width="36.44"
                    height="25.55"
                    transform="translate(239.97 90.94)"
                    fill="#e3e2e3"
                />
                <path
                    id="Path_171"
                    d="M258.2,107,240.62,92.62a.785.785,0,0,1,1-1.21L258.2,105l16.57-13.55a.78.78,0,0,1,1.1.11.77.77,0,0,1-.11,1.1Z"
                    fill="#707171"
                />
                <path
                    id="Path_172"
                    d="M241.11,116a.72.72,0,0,1-.44-.14.79.79,0,0,1-.2-1.09l10.12-14.41a.78.78,0,1,1,1.28.89l-10.12,14.42a.76.76,0,0,1-.64.33Z"
                    fill="#707171"
                />
                <path
                    id="Path_173"
                    d="M275.59,116a.76.76,0,0,1-.64-.33l-10.12-14.42a.78.78,0,1,1,1.28-.89l10.12,14.41a.78.78,0,0,1-.19,1.09A.77.77,0,0,1,275.59,116Z"
                    fill="#707171"
                />
                <path
                    id="Path_174"
                    d="M344.63,111H283.57a2.07,2.07,0,0,1-2.07-2.07h0a2.07,2.07,0,0,1,2.07-2.06h61.06a2.07,2.07,0,0,1,2.06,2.06h0a2.07,2.07,0,0,1-2.06,2.07Z"
                    fill="#e3e2e3"
                />
                <path
                    id="Path_175"
                    d="M345.71,126.83H253.92a.76.76,0,0,1-.76-.76h0a.76.76,0,0,1,.76-.76h91.79a.77.77,0,0,1,.77.76h0a.77.77,0,0,1-.77.76Z"
                    fill="#e3e2e3"
                />
                <path
                    id="Path_176"
                    d="M345.71,134.15H253.92a.76.76,0,0,1-.76-.76h0a.76.76,0,0,1,.76-.76h91.79a.77.77,0,0,1,.77.76h0A.77.77,0,0,1,345.71,134.15Z"
                    fill="#e3e2e3"
                />
                <path
                    id="Path_177"
                    d="M453.41,352.08a6.28,6.28,0,1,1-6.28-6.27,6.28,6.28,0,0,1,6.28,6.27Z"
                    fill="#f4f5f6"
                />
                <path
                    id="Path_178"
                    d="M330.14,428.61a6.28,6.28,0,1,1-6.28-6.28,6.28,6.28,0,0,1,6.28,6.28Z"
                    fill="#e3e2e3"
                />
                <circle
                    id="Ellipse_11"
                    cx="4.5"
                    cy="4.5"
                    r="4.5"
                    transform="translate(159.79 85.17)"
                    fill="#e3e2e3"
                />
                <path
                    id="Path_179"
                    d="M204.12,444.35a15.95,15.95,0,1,1,15.95-15.94A15.95,15.95,0,0,1,204.12,444.35Zm0-30.33a14.386,14.386,0,1,0,0-.02Z"
                    fill="#e3e2e3"
                />
                <path
                    id="Path_180"
                    d="M200.62,56.32a12.45,12.45,0,1,1,12.45-12.45,12.45,12.45,0,0,1-12.45,12.45Zm0-23.68a11.23,11.23,0,1,0,11.23,11.23A11.23,11.23,0,0,0,200.62,32.64Z"
                    fill="#e3e2e3"
                />
                <rect
                    id="Rectangle_120"
                    width="4.21"
                    height="18.54"
                    transform="translate(105.836 153.935) rotate(-45)"
                    fill="#e3e2e3"
                />
                <rect
                    id="Rectangle_121"
                    width="18.54"
                    height="4.21"
                    transform="translate(105.836 164.075) rotate(-45)"
                    fill="#e3e2e3"
                />
                <rect
                    id="Rectangle_122"
                    width="2.96"
                    height="26.37"
                    transform="translate(263.892 408.581) rotate(-45)"
                    fill="#e3e2e3"
                />
                <rect
                    id="Rectangle_123"
                    width="26.37"
                    height="2.96"
                    transform="translate(263.892 425.117) rotate(-45)"
                    fill="#e3e2e3"
                />
                <rect
                    id="Rectangle_124"
                    width="3.4"
                    height="30.24"
                    transform="translate(79.213 110.648) rotate(-45)"
                    fill="#e3e2e3"
                />
                <rect
                    id="Rectangle_125"
                    width="30.24"
                    height="3.4"
                    transform="translate(79.213 129.627) rotate(-45)"
                    fill="#e3e2e3"
                />
                <path
                    id="Path_181"
                    d="M288.67,376.08c-.33-4.42-2.21-8.57-4.27-12.5-7.71-14.71-18.33-27.8-25.24-42.9-4.78-10.47-7.69-21.73-12.52-32.19-3.82-8.29-9.17-16.29-16.46-21.59-3.1-2.25-8-3.89-12-4.75l-2.57-.34c-1.6,16.3,5.49,33.35,10.4,49,7.93,25.3,16.47,51.56,34.91,70.6,3,3.14,6.42,6.11,10.51,7.67s9,1.49,12.52-1.12S289,380.51,288.67,376.08Z"
                    fill="#e3e2e3"
                />
                <path
                    id="Path_182"
                    d="M211.61,260c-3.3-.85-5.71-2-9.08-1.35l-30-3.29a244.136,244.136,0,0,0-34.7,11.32c4.8.92,8.17,5.9,10.16,10.36,2.26,5.08,1.79,10.05,1.6,15.78a65.47,65.47,0,0,1-11.32,34.1c-3.25,4.87-2.58,10-1.55,15.64a25,25,0,0,0,3.88,9.69c6.17,9,.51,21.06-3.64,30.6a24.09,24.09,0,0,0-1.88,5.45,5.57,5.57,0,0,0,3.45,6.09c13.51,4.2,59.08,5.8,84.27.94,3-.58,7.39-.6,8.18-1.39,2.78-2.78,1.3-12.43.64-16.31-3.29-19.4,2.06-26,7.19-39.05C251.23,306.8,244.57,268.48,211.61,260Z"
                    fill="#707171"
                />
                <path
                    id="Path_183"
                    d="M209.14,254.93a6.69,6.69,0,0,0-1.06-3.92c-1.12-1.4-3.13-1.65-4.93-1.74L177,245.43a3,3,0,0,0-3.29.48,7.54,7.54,0,0,0-1.93,3,33.671,33.671,0,0,0-2.52,9.87c5.17,5.09,11.81,9,19,10a34.44,34.44,0,0,0,21-3.83C209.13,261.52,209.34,258.29,209.14,254.93Z"
                    fill="#707171"
                />
                <path
                    id="Path_184"
                    d="M209.35,259.16s-3.79,5.67-13.62,5.67c0,0,43.89,19.87,51.45,36.52C247.18,301.35,243.21,267.48,209.35,259.16Z"
                    fill="#fff"
                    opacity="0.2"
                />
                <path
                    id="Path_185"
                    d="M226.17,209.54c-2.44,2-3.73,5.13-4.47,8.21s-1,6.27-1.87,9.32a25.64,25.64,0,0,1-15.66,16.87,107.421,107.421,0,0,0-.85,11.19,24.62,24.62,0,0,1-16.72,2.06c-4.12-.94-8.22-3.19-10.07-7l9.29-45.45s11.34-24.21,29.29-15.07S226.17,209.54,226.17,209.54Z"
                    fill="#e3e2e3"
                />
                <path
                    id="Path_186"
                    d="M202,194.83l-.88.84a73.06,73.06,0,0,1-16.46,11.76h0l-6.23-3.57c.26-3.59.51-7.18.77-10.77a19.78,19.78,0,0,1,3.38-9.72c.32-.47.67-.93,1-1.37,11.82-14.49,37.57-10.93,47.92,3.4,4.71,6.52,5.56,15.69.07,22-2.82,3.26-8.13,4.76-11.85,2.58,1.39-.05,2.42-1.34,3-2.61a17.55,17.55,0,0,1-12-.8,20.22,20.22,0,0,0,6.84-7.36c-6,4.76-13.25,6.26-20.4,3.52l.95-.69a9.4,9.4,0,0,0,3.87-7.26Z"
                    fill="#707171"
                />
                <path
                    id="Path_187"
                    d="M174.87,169.07a20.59,20.59,0,0,0-17.7,1.58A17.87,17.87,0,0,0,148.71,186c.1,2.88.94,5.67,1.22,8.53s-.11,6-2.09,8.09c-1.8,1.91-4.53,2.54-6.91,3.64a13.15,13.15,0,0,0-7,7,8.68,8.68,0,0,0,2,9.37c2.24,2,5.76,2.67,6.94,5.4.78,1.84.17,4-.67,5.77s-1.93,3.57-2.13,5.55c-.4,3.92,3.06,7.52,6.94,8.23s7.87-.89,11-3.27c4.18-3.15,8-7.29,8.42-12.51.21-2.76-.2-6,1.84-7.85,1.18-1.08,2.89-1.38,4.15-2.37,2.1-1.65,2.36-4.66,3.22-7.18a13.83,13.83,0,0,1,7.54-8.13l4.83-25a24.26,24.26,0,0,0-13.14-12.2Z"
                    fill="#707171"
                />
                <path
                    id="Path_188"
                    d="M137.81,266.66s-35.87,23.84-40,77.22S116,395.59,116,395.59s18.62.42,38.48-40.54l18.59-38,5.57-7.19s10.43,1.25,13.56-6.35c0,0,1.57.78,2.57.22a3.12,3.12,0,0,0,2.46,3.91c3.35,1,5.59-.56,6-1.45a9.59,9.59,0,0,0,.34-3.24s4.36-1,4.8-4l.34-1.68s4.13-2,3.8-5.25-3.69-5-6.71-6.15l14-3s6.26-.34,7-1.34-1.34-3.13-6.59-3.36-26.15,2.91-26.15,2.91-14.86,8.83-18.32,12.29-40.18,43.27-40.18,43.27,16.06-30.65,14.8-50S137.81,266.66,137.81,266.66Z"
                    fill="#e3e2e3"
                />
                <path
                    id="Path_189"
                    d="M209.12,287.35c-.32.41-2.69-1-5.89-1.52s-5.9-.17-6-.67c-.06-.23.56-.66,1.72-1a11.29,11.29,0,0,1,8.94,1.64C208.82,286.53,209.25,287.16,209.12,287.35Z"
                    fill="#707171"
                    opacity="0.3"
                />
                <path
                    id="Path_190"
                    d="M208.68,297.44c-.34.19-1.81-2-4.26-4.12s-4.84-3.23-4.7-3.6c.06-.17.77-.1,1.82.3a12.77,12.77,0,0,1,3.76,2.28,13,13,0,0,1,2.82,3.38C208.67,296.66,208.84,297.35,208.68,297.44Z"
                    fill="#707171"
                    opacity="0.3"
                />
                <path
                    id="Path_191"
                    d="M209.68,292.75c.08.24-.63.68-1.55,1.07s-1.73.6-1.85.38.53-.79,1.49-1.2S209.61,292.52,209.68,292.75Z"
                    fill="#707171"
                    opacity="0.3"
                />
                <path
                    id="Path_192"
                    d="M203.71,303.09c-.18.06-.5-.47-.92-1.32a24.649,24.649,0,0,0-1.76-3.11,9.711,9.711,0,0,0-2.45-2.42c-.74-.5-1.29-.73-1.26-.92s.69-.28,1.68.07a6.71,6.71,0,0,1,3.13,2.48,10.11,10.11,0,0,1,1.55,3.59C203.89,302.42,203.88,303,203.71,303.09Z"
                    fill="#707171"
                    opacity="0.3"
                />
                <path
                    id="Path_193"
                    d="M205.8,299.39c.08.23-.56.67-1.48.82s-1.66,0-1.66-.28.68-.46,1.51-.6S205.71,299.15,205.8,299.39Z"
                    fill="#707171"
                    opacity="0.3"
                />
                <path
                    id="Path_194"
                    d="M198.86,305.88c-.13,0-.23-.39-.46-.94a2.87,2.87,0,0,0-1.39-1.55,1.76,1.76,0,0,0-1-.12,1.66,1.66,0,0,0-.75.45c-.37.38-.4.77-.53.79s-.14-.09-.17-.3a1.38,1.38,0,0,1,.18-.86,2,2,0,0,1,1-.87,2.31,2.31,0,0,1,1.65.09,2.63,2.63,0,0,1,1.2,1,3.17,3.17,0,0,1,.44,1.16C199.11,305.46,199,305.88,198.86,305.88Z"
                    fill="#707171"
                    opacity="0.3"
                />
                <path
                    id="Path_195"
                    d="M238.29,266.78l-24,54.32a1.62,1.62,0,0,0,.51,2l2.14,1.6a1.66,1.66,0,0,0,1,.33l28.34.21a1.63,1.63,0,0,0,1.5-1l23.64-54.72a1.63,1.63,0,0,0-1.42-2.27l-30.09-1.41a1.62,1.62,0,0,0-1.62.94Z"
                    fill="#f4f5f6"
                />
                <path
                    id="Path_196"
                    d="M217.17,321.16c-.22-.12,1.09-3,3.25-7.62s5.13-11,8.23-18.12,5.74-13.64,7.64-18.37,3.1-7.66,3.35-7.58-.56,3.15-2.16,8-4.07,11.5-7.18,18.66-6.28,13.49-8.74,18S217.38,321.27,217.17,321.16Z"
                    fill="#e3e2e3"
                />
                <path
                    id="Path_197"
                    d="M245,273.22c-.41,1.64-1.71,3-2.9,3s-1.84-1.34-1.43-3,1.7-3,2.9-3S245.44,271.58,245,273.22Z"
                    fill="#707171"
                    opacity="0.5"
                />
                <path
                    id="Path_198"
                    d="M249.17,272.43a1.74,1.74,0,0,1-1.57,1.3,1,1,0,0,1-.92-1.3,1.73,1.73,0,0,1,1.57-1.3,1,1,0,0,1,.92,1.3Z"
                    fill="#707171"
                    opacity="0.5"
                />
                <path
                    id="Path_199"
                    d="M213,213.61a85.281,85.281,0,0,1,1.33,10.57c.09,1.39.06,2.89-1.28,3.68s-2.8.44-4.17.1a.324.324,0,0,1,.15-.63c1.28.32,3,.73,4-.29s.61-3,.51-4.31a87.01,87.01,0,0,0-1.2-8.93c-.08-.41.54-.6.62-.19Z"
                    fill="#707171"
                />
                <path
                    id="Path_200"
                    d="M208.88,210.63a14.78,14.78,0,0,0-9.79-3.4.33.33,0,0,1,0-.65,15.49,15.49,0,0,1,10.26,3.58c.32.27-.13.74-.45.47Z"
                    fill="#707171"
                />
                <path
                    id="Path_201"
                    d="M210.15,232.55a13.45,13.45,0,0,1-10.87-5c-.26-.33.29-.67.55-.35a12.77,12.77,0,0,0,10.29,4.71.33.33,0,0,1,0,.65Z"
                    fill="#707171"
                />
                <path
                    id="Path_202"
                    d="M207.33,234.79a9,9,0,0,1-5.4-2.84c-.28-.3.16-.78.45-.47a8.5,8.5,0,0,0,5.1,2.68c.41.06.26.69-.15.63Z"
                    fill="#707171"
                />
                <path
                    id="Path_203"
                    d="M205,211.84c.77.24,1.09,1.39.71,2.58s-1.29,2-2.06,1.71-1.08-1.4-.71-2.58S204.23,211.59,205,211.84Z"
                    fill="#707171"
                />
                <path
                    id="Path_204"
                    d="M221.88,211.05a10.61,10.61,0,0,0-7.44.53c-.38.17-.72-.38-.34-.56a11.3,11.3,0,0,1,7.93-.6C222.43,210.53,222.28,211.17,221.88,211.05Z"
                    fill="#707171"
                />
                <path
                    id="Path_205"
                    d="M218.23,214c.77.24,1.09,1.4.71,2.59s-1.29,2-2.06,1.71-1.08-1.4-.71-2.59S217.46,213.74,218.23,214Z"
                    fill="#707171"
                />
                <path
                    id="Path_206"
                    d="M184.55,216.62c-2.68,1.37-6,.06-8.17-2-3.53-3.34-2.8-8.92-.07-11.05,3.25-2.54,8.31.9,9.33,4S185.8,213.54,184.55,216.62Z"
                    fill="#e3e2e3"
                />
                <path
                    id="Path_207"
                    d="M191.22,239.56c-4.28-4.39-6-10.78-5.95-16.91a23.75,23.75,0,0,0,18.23,28.44c.13-2.37.33-4.74.66-7.07a16.36,16.36,0,0,1-12.94-4.46Z"
                    fill="#f4f5f6"
                />
                <path
                    id="Path_208"
                    d="M261.1,372.94A150.94,150.94,0,0,1,250,326.2c-.43-5.76-4.52-11.22-4-17-4.22-1.93-9.36-2.69-13.75-1.19a3.579,3.579,0,0,1-1.28.27,1.05,1.05,0,0,1-1-.72c-.11-.56.41-1,.88-1.34a26.45,26.45,0,0,1,11.11-4.19c-1.51-1.19-3.69-.93-5.56-.48s-3.8,1.1-5.68.69a1.335,1.335,0,0,1-1.11-.88c-.11-.5.36-.88.76-1.17a20.08,20.08,0,0,1,8-3.46c-1.36,0-3.24-.14-4.59-.14-.44,0-1-.09-1.1-.51s.35-.79.75-1a30.33,30.33,0,0,1,20.5-2.29,18.329,18.329,0,0,1-3-3.84,55.857,55.857,0,0,1-7.32-3.51c-.89-.49-1.91-1.35-1.57-2.3s1.72-1,2.74-.78c4,.79,8,1.75,11.49,3.9s6.26,5.69,6.55,9.74c.52,7.23,1.09,15.74,0,22.91,0,0,16.63,26,20,35.21s11.48,24.22,1.23,33.79S261.1,372.94,261.1,372.94Z"
                    fill="#e3e2e3"
                />
                <path
                    id="Path_209"
                    d="M261.1,372.94s.09.18.25.47c-.17-.33-.29-.6-.38-.78A2.043,2.043,0,0,0,261.1,372.94Z"
                    fill="#ffb088"
                />
                <path
                    id="Path_210"
                    d="M251.8,324.31A158.081,158.081,0,0,0,260.71,372,151,151,0,0,1,250,326.2c-.35-4.76-3.2-9.32-3.91-14l1.45,3.74a46.871,46.871,0,0,0,4.27,8.38Z"
                    fill="#707171"
                    opacity="0.3"
                />
                <path
                    id="Path_211"
                    d="M240.52,292.74a6.729,6.729,0,0,1,2.72-.77,20.623,20.623,0,0,1,3.08-.25,24.286,24.286,0,0,1,3.77.29,25.1,25.1,0,0,1,3.66.91,21.419,21.419,0,0,1,2.84,1.21,6.75,6.75,0,0,1,2.34,1.59,12.549,12.549,0,0,1-2.6-1c-.79-.32-1.75-.66-2.83-1a31.3,31.3,0,0,0-3.56-.81,30.78,30.78,0,0,0-3.63-.35c-1.13,0-2.14,0-3,.05a12.53,12.53,0,0,1-2.79.13Z"
                    fill="#707171"
                    opacity="0.3"
                />
                <path
                    id="Path_212"
                    d="M238.15,296.76a4.13,4.13,0,0,1,2-.64,15.68,15.68,0,0,1,9.93,1.76c1.14.65,1.73,1.21,1.67,1.3a31.552,31.552,0,0,0-6.65-2.12C241.3,296.41,238.2,297,238.15,296.76Z"
                    fill="#707171"
                    opacity="0.3"
                />
                <path
                    id="Path_213"
                    d="M240.54,301.32c0-.23,2.08,0,4.39.94s3.92,2.21,3.78,2.41-1.89-.67-4.12-1.58S240.48,301.57,240.54,301.32Z"
                    fill="#707171"
                    opacity="0.3"
                />
                <path
                    id="Path_214"
                    d="M169.65,256s3.32,5.47,13.5,8.25c0,0-9,2.25-21.22-6.1Z"
                    fill="#fff"
                    opacity="0.2"
                />
                <path
                    id="Path_215"
                    d="M110.77,362.61l25.87-27C127,344,116.77,350.74,110.77,362.61Z"
                    fill="#707171"
                    opacity="0.3"
                />
                <path
                    id="Path_216"
                    d="M220.07,142.15a24.89,24.89,0,1,0-19,24.16l10.37,10-.35-15a24.84,24.84,0,0,0,8.98-19.16Z"
                    fill="#e3e2e3"
                />
                <path
                    id="Path_217"
                    d="M194.94,159.21l-13.18-14.64a1.561,1.561,0,1,1,2.32-2.09l10.42,11.57,14.72-23.27a1.562,1.562,0,1,1,2.64,1.67Z"
                    fill="#fff"
                />
                <text
                    id="_10.vPM"
                    transform="translate(344.08 321.32)"
                    fill="#707171"
                    fontSize="8.61"
                    fontFamily="MyriadPro-Regular, Myriad Pro"
                >
                    <tspan x="0" y="0">
                        10.v
                    </tspan>
                    <tspan y="0" letterSpacing="-0.009em">
                        P
                    </tspan>
                    <tspan y="0">M</tspan>
                </text>
                <path
                    id="Path_218"
                    d="M272.5,35.57h-6.81v-6.8a.85.85,0,0,0-.84-.84h0a.86.86,0,0,0-.87.84v6.8h-6.79a.87.87,0,0,0-.85.87.85.85,0,0,0,.85.84H264v6.81a.87.87,0,0,0,.87.85.85.85,0,0,0,.84-.85h0V37.28h6.81a.84.84,0,0,0,.85-.84A.87.87,0,0,0,272.5,35.57Z"
                    fill="#e2e2e2"
                    stroke="#e2e2e2"
                    strokeMiterlimit="10"
                    strokeWidth="0.75"
                />
                <path
                    id="Path_219"
                    d="M443.24,179.36H439.5v-3.74a.47.47,0,0,0-.47-.47h0a.47.47,0,0,0-.47.47v3.74h-3.73a.47.47,0,0,0,0,.94h3.73V184a.46.46,0,0,0,.47.46.47.47,0,0,0,.47-.46h0v-3.7h3.74a.47.47,0,1,0,0-.94Z"
                    fill="#e2e2e2"
                    stroke="#e2e2e2"
                    strokeMiterlimit="10"
                    strokeWidth="0.75"
                />
                <path
                    id="Path_220"
                    d="M442.76,150h-7.57v-7.56a.94.94,0,0,0-.94-.94h0a1,1,0,0,0-1,.94V150H425.7a.97.97,0,1,0,0,1.94h7.55v7.58a1,1,0,0,0,1,.94.94.94,0,0,0,.94-.94h0V151.9h7.57a.95.95,0,1,0,0-1.9Z"
                    fill="#e2e2e2"
                    stroke="#e2e2e2"
                    strokeMiterlimit="10"
                    strokeWidth="0.75"
                />
                <ellipse
                    id="_Path_"
                    cx="160.79"
                    cy="15.79"
                    rx="160.79"
                    ry="15.79"
                    transform="translate(84.08 472.74)"
                    fill="#f4f5f6"
                />
            </g>
        </SVGIcon>
    );
}
