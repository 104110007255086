import React from 'react';

import { SVGIcon } from '../../UIKit/index';

export default function DCGroupForm(props) {
    return (
        <SVGIcon {...props} viewBox="0 0 512 479">
            <g transform="translate(-757 -206.653)">
                <path
                    fill="#D9EAFF"
                    d="M1260.99,221.1H764.4c-4.42,0-8.01,3.59-8.01,8.01c0,0,0,0,0,0v448.53   c0,4.42,3.59,8.01,8.01,8.01c0,0,0,0,0,0h496.59c4.42,0,8.01-3.59,8.01-8.01c0,0,0,0,0,0V229.11   C1269,224.69,1265.41,221.1,1260.99,221.1L1260.99,221.1z"
                />
                <g transform="translate(757 182.653)">
                    <path
                        fill="#005EFF"
                        d="M503.99,26.01H7.4c-4.42,0-8.01,3.59-8.01,8.01c0,0,0,0,0,0v64.06H512V34.02    C512,29.6,508.41,26.01,503.99,26.01z"
                    />
                    <g transform="translate(64.07 29.151)">
                        <path
                            fill="#FFFFFF"
                            d="M375.85,44.92h-1c-4.42,0-8.01-3.59-8.01-8.01c0-4.42,3.59-8.01,8.01-8.01h1     c4.42,0,8.01,3.59,8.01,8.01C383.86,41.33,380.27,44.92,375.85,44.92L375.85,44.92z"
                        />
                        <path
                            fill="#FFFFFF"
                            d="M407.89,44.92h-1c-4.42,0-8.01-3.59-8.01-8.01c0-4.42,3.59-8.01,8.01-8.01h1     c4.42,0,8.01,3.59,8.01,8.01C415.9,41.33,412.31,44.92,407.89,44.92L407.89,44.92z"
                        />
                        <path
                            fill="#FFFFFF"
                            d="M342.81,44.92h-1c-4.42,0-8.01-3.59-8.01-8.01c0-4.42,3.59-8.01,8.01-8.01h1     c4.42,0,8.01,3.59,8.01,8.01C350.82,41.33,347.23,44.92,342.81,44.92z"
                        />
                    </g>
                    <g transform="translate(33.99 -1.598)">
                        <path
                            fill="#005EFF"
                            d="M397.85,178.13h-160.7c-2.77,0-5.02,2.25-5.02,5.02l0,0v100.44c0,2.77,2.25,5.02,5.02,5.02h0     h160.7c2.77,0,5.02-2.25,5.02-5.02v0V183.15C402.87,180.38,400.62,178.13,397.85,178.13L397.85,178.13z"
                        />
                        <rect fill="#FFFFFF" x="243.42" y="188.24" width="148.18" height="89.68" />
                        <path
                            fill="#6B8AAA"
                            d="M451.62,311.03l-31.96-31.96l17.76-17.76c1.95-2.01,1.9-5.22-0.11-7.17     c-0.59-0.58-1.32-1-2.12-1.23l-78.12-21.31c-2.71-0.69-5.47,0.95-6.16,3.66c-0.21,0.82-0.21,1.68,0,2.5l21.31,78.12     c0.78,2.69,3.59,4.24,6.28,3.46c0.79-0.23,1.52-0.65,2.12-1.23l17.76-17.76l31.96,31.96c1.97,1.94,5.13,1.94,7.1,0l14.21-14.21     C453.57,316.15,453.57,312.99,451.62,311.03z"
                        />
                        <path
                            fill="#5B7691"
                            d="M437.85,324.8l-31.93-31.95l-17.76,17.76c-2.01,1.95-5.22,1.9-7.17-0.11     c-0.58-0.59-1-1.32-1.23-2.12l-20.79-76.24l-1.87-0.51c-2.71-0.69-5.47,0.95-6.16,3.66c-0.21,0.82-0.21,1.68,0,2.5l21.31,78.12     c0.78,2.69,3.59,4.24,6.28,3.46c0.8-0.23,1.52-0.65,2.12-1.23l17.76-17.76l31.96,31.96c1.97,1.94,5.13,1.94,7.1,0l7.53-7.53     C443.01,326.78,439.82,326.77,437.85,324.8z"
                        />
                    </g>
                    <g>
                        <g transform="translate(0.99 -1.598)">
                            <path
                                fill="#005EFF"
                                d="M225.61,178.13H64.91c-2.77,0-5.02,2.25-5.02,5.02l0,0v100.44c0,2.77,2.25,5.02,5.02,5.02      h0h160.7c2.77,0,5.02-2.25,5.02-5.02v0V183.15C230.63,180.38,228.38,178.13,225.61,178.13L225.61,178.13z"
                            />
                            <rect
                                fill="#FFFFFF"
                                x="71.18"
                                y="188.24"
                                width="148.18"
                                height="89.68"
                            />
                        </g>
                        <g transform="translate(14 53.347)">
                            <path
                                fill="none"
                                d="M78.68,155.18h93.29c3.43,0,6.22,2.78,6.22,6.22l0,0c0,3.43-2.78,6.22-6.22,6.22H78.68      c-3.43,0-6.22-2.78-6.22-6.22l0,0C72.46,157.97,75.25,155.18,78.68,155.18z"
                            />
                            <path
                                fill="none"
                                stroke="#005EFF"
                                d="M78.68,158.29h93.29c1.72,0,3.11,1.39,3.11,3.11l0,0c0,1.72-1.39,3.11-3.11,3.11H78.68      c-1.72,0-3.11-1.39-3.11-3.11l0,0C75.57,159.69,76.97,158.29,78.68,158.29z"
                            />
                        </g>
                        <g transform="translate(14 57.347)">
                            <path
                                fill="none"
                                d="M78.68,176.06h80.85c3.43,0,6.22,2.78,6.22,6.22l0,0c0,3.43-2.78,6.22-6.22,6.22H78.68      c-3.43,0-6.22-2.78-6.22-6.22l0,0C72.46,178.85,75.25,176.06,78.68,176.06z"
                            />
                            <path
                                fill="none"
                                stroke="#005EFF"
                                d="M78.68,179.17h80.85c1.72,0,3.11,1.39,3.11,3.11l0,0c0,1.72-1.39,3.11-3.11,3.11H78.68      c-1.72,0-3.11-1.39-3.11-3.11l0,0C75.57,180.56,76.97,179.17,78.68,179.17z"
                            />
                        </g>
                    </g>
                    <g transform="translate(33 23)">
                        <g transform="translate(0.99 -1.598)">
                            <path
                                fill="#005EFF"
                                d="M397.85,298.18h-160.7c-2.77,0-5.02,2.25-5.02,5.02l0,0v100.44c0,2.77,2.25,5.02,5.02,5.02      h0h160.7c2.77,0,5.02-2.25,5.02-5.02v0V303.2C402.87,300.42,400.62,298.18,397.85,298.18L397.85,298.18z"
                            />
                            <rect
                                fill="#FFFFFF"
                                x="243.42"
                                y="308.28"
                                width="148.18"
                                height="89.68"
                            />
                        </g>
                        <g transform="translate(14 53.347)">
                            <path
                                fill="none"
                                d="M250.92,275.23h93.29c3.43,0,6.22,2.78,6.22,6.22l0,0c0,3.43-2.78,6.22-6.22,6.22h-93.29      c-3.43,0-6.22-2.78-6.22-6.22l0,0C244.7,278.01,247.49,275.23,250.92,275.23z"
                            />
                            <path
                                fill="none"
                                stroke="#005EFF"
                                d="M250.92,278.34h93.29c1.72,0,3.11,1.39,3.11,3.11l0,0c0,1.72-1.39,3.11-3.11,3.11h-93.29      c-1.72,0-3.11-1.39-3.11-3.11l0,0C247.81,279.73,249.21,278.34,250.92,278.34z"
                            />
                        </g>
                        <g transform="translate(14 57.347)">
                            <path
                                fill="none"
                                d="M250.92,296.11h80.85c3.43,0,6.22,2.78,6.22,6.22l0,0c0,3.43-2.78,6.22-6.22,6.22h-80.85      c-3.43,0-6.22-2.78-6.22-6.22l0,0C244.7,298.89,247.49,296.11,250.92,296.11z"
                            />
                            <path
                                fill="none"
                                stroke="#005EFF"
                                d="M250.92,299.22h80.85c1.72,0,3.11,1.39,3.11,3.11l0,0c0,1.72-1.39,3.11-3.11,3.11h-80.85      c-1.72,0-3.11-1.39-3.11-3.11l0,0C247.81,300.61,249.21,299.22,250.92,299.22z"
                            />
                        </g>
                    </g>
                    <g transform="translate(0 23)">
                        <g transform="translate(0.99 -1.598)">
                            <path
                                fill="#005EFF"
                                d="M225.61,298.18H64.91c-2.77,0-5.02,2.25-5.02,5.02l0,0v100.44c0,2.77,2.25,5.02,5.02,5.02      h0h160.7c2.77,0,5.02-2.25,5.02-5.02v0V303.2C230.63,300.42,228.38,298.18,225.61,298.18L225.61,298.18z"
                            />
                            <rect
                                fill="#FFFFFF"
                                x="71.18"
                                y="308.28"
                                width="148.18"
                                height="89.68"
                            />
                        </g>
                        <g transform="translate(14 53.347)">
                            <path
                                fill="none"
                                d="M78.68,275.23h93.29c3.43,0,6.22,2.78,6.22,6.22l0,0c0,3.43-2.78,6.22-6.22,6.22H78.68      c-3.43,0-6.22-2.78-6.22-6.22l0,0C72.46,278.01,75.25,275.23,78.68,275.23z"
                            />
                            <path
                                fill="none"
                                stroke="#005EFF"
                                d="M78.68,278.34h93.29c1.72,0,3.11,1.39,3.11,3.11l0,0c0,1.72-1.39,3.11-3.11,3.11H78.68      c-1.72,0-3.11-1.39-3.11-3.11l0,0C75.57,279.73,76.97,278.34,78.68,278.34z"
                            />
                        </g>
                        <g transform="translate(14 57.347)">
                            <path
                                fill="none"
                                d="M78.68,296.11h80.85c3.43,0,6.22,2.78,6.22,6.22l0,0c0,3.43-2.78,6.22-6.22,6.22H78.68      c-3.43,0-6.22-2.78-6.22-6.22l0,0C72.46,298.89,75.25,296.11,78.68,296.11z"
                            />
                            <path
                                fill="none"
                                stroke="#005EFF"
                                d="M78.68,299.22h80.85c1.72,0,3.11,1.39,3.11,3.11l0,0c0,1.72-1.39,3.11-3.11,3.11H78.68      c-1.72,0-3.11-1.39-3.11-3.11l0,0C75.57,300.61,76.97,299.22,78.68,299.22z"
                            />
                        </g>
                    </g>
                </g>
            </g>
        </SVGIcon>
    );
}
