import dayjs from 'dayjs';
import validator from '../Modules/Validator';

/**
 * Validation
 *  @param {String} type
 *  @param {String} value
 * ************ */

export const Validation = (type, value, subType) => {
    switch (type) {
        case 'name':
        case 'longtext':
            if (value?.trim()?.length !== 0) {
                return true;
            }
            return false;

        case 'text':
            switch (subType) {
                case 'address.postal_code':
                    return value.length < 15;
                default:
                    if (/[\w[\]`!@#$%^&*()={}:;<>+'-]*/.test(value)) {
                        return true;
                    }
                    return false;
            }
        case 'phone': {
            return true;
        }
        case 'email':
            return validator.isEmail(value);
        case 'website':
            switch (subType) {
                case 'linkedin':
                    if (
                        /https?:\/\/(www\.)?linkedin.com(\w+:{0,1}\w*@)?(\S+)(:([0-9])+)?(\/|\/([\w#!:.?+=&%@\-/]))?/.test(
                            value,
                        )
                    ) {
                        return true;
                    }
                    return false;

                case `facebook`:
                    if (
                        /https?:\/\/(www\.)?(?:facebook|fb|m\.facebook)\.(?:com|me)\/(?:(?:\w)*#!\/)?(?:pages\/)?(?:[\w-]*\/)*([\w\-.]+)(?:\/)?/i.test(
                            value,
                        )
                    ) {
                        return true;
                    }
                    return false;

                case `twitter`:
                    if (/https?:\/\/(www\.)?twitter\.com\/([a-zA-Z0-9_]+)/.test(value)) {
                        return true;
                    }
                    return false;
                case 'yelp':
                    if (/https?:\/\/(www\.)?yelp\.com\/([a-zA-Z0-9_]+)/.test(value)) {
                        return true;
                    }
                    return false;
                case 'instagram':
                    if (/https?:\/\/(www\.)?instagram\.com\/([a-zA-Z0-9_]+)/.test(value)) {
                        return true;
                    }
                    return false;
                case 'youtube':
                    if (/https?:\/\/(www\.)?youtube\.com\/([a-zA-Z0-9_]+)/.test(value)) {
                        return true;
                    }
                    return false;
                default:
                    if (
                        /(http|https)?:\/\/(www\.)?[-a-zA-Z0-9@:%.+~#=]{1,256}\.[a-zA-Z0-9()]{1,10}\b([-a-zA-Z0-9()@:%+.~#?&/=]*)/.test(
                            value,
                        )
                    ) {
                        return true;
                    }
                    return false;
            }
        case `number`:
            if (/^[0-9]\d*(\.\d+)?$/.test(value)) {
                return true;
            }
            return false;

        case 'postalCode':
            return value.length < 15;
        case 'integer':
            return validator.isNumeric(value);
        case 'float':
            return validator.isFloat(value);
        case 'date':
            return validator.isDate(dayjs(value).format('YYYY/MM/DD')); // format to be used: YYYY/MM/DD
        case 'bname':
            return value.length >= 3;
        case 'address':
            return value.length > 0;
        case 'dropdown':
            return true;
        case 'password':
            return /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/.test(
                value,
            );

        default:
            return true;
    }
};
