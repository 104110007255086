import React from 'react';

import { SVGIcon } from '../../UIKit/index';

export default function DCDoc(props) {
    return (
        <SVGIcon {...props} viewBox="0 0 512 512">
            <g>
                <path d="M143.1,132.9H94.8v315.6h230.8c0,0,11.8-4.5,14.7-15.9v-25.1l1.1-1.3h27.2v33.1c0,0-7.5,35.4-44,36.5H77.8   c0,0-9.1-3.4-10.6-12.9v-343c0,0,0.7-14.9,13.2-14.9h63L143.1,132.9z" />
                <rect x="224.9" y="212.9" width="138.6" height="27.9" />
                <rect x="224.9" y="269.6" width="43" height="26.9" />
                <rect x="290.4" y="269.6" width="43" height="26.9" />
                <path d="M315.9,63.9H170.6v315.3v0.7h231.6c0,0,13.4-4.6,14-15.2V160.9h28.3v209.7c0,0-8.9,37.3-43.4,37.5H156.7   c0,0-12.5-2.3-13.8-14.9V49.3c0,0,2.8-11.3,11.4-13.2h179.7l5.9,4l102.9,99.4l1.8,6.4l0.3,0.7v13.4h-28.6l-72.6-69.7L315.9,63.9z" />
                <path d="M343.7,117.4c0,0,4.2,14.8,18.8,15.7l25.3-0.3l28.4,27.3l0,0.7v0.7h-62.6c0,0-37.1-9.3-38-44.9l0.4-52.8   l27.7,26.6L343.7,117.4z" />
            </g>
        </SVGIcon>
    );
}
