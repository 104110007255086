import React from 'react';

import { SVGIcon } from '../../UIKit/index';

export default function DCCheckout1(props) {
    return (
        <SVGIcon {...props} viewBox="0 0 512 512">
            <path d="M214.7,402.8c-24.8,0-45,20.2-45,45c0,24.8,20.2,45,45,45s45-20.2,45-45C259.7,423,239.5,402.8,214.7,402.8z     M214.7,467.7c-11,0-19.9-8.9-19.9-19.9c0-11,8.9-19.9,19.9-19.9s19.9,8.9,19.9,19.9C234.6,458.8,225.6,467.7,214.7,467.7z" />
            <path d="M372.6,402.8c-24.8,0-45,20.2-45,45c0,24.8,20.2,45,45,45s45-20.2,45-45C417.7,423,397.5,402.8,372.6,402.8z     M372.6,467.7c-11,0-19.9-8.9-19.9-19.9c0-11,8.9-19.9,19.9-19.9c11,0,19.9,8.9,19.9,19.9C392.5,458.8,383.6,467.7,372.6,467.7z" />
            <path d="M383.7,165.8H203.6c-6.9,0-12.6,5.6-12.6,12.6s5.6,12.6,12.6,12.6h180.1c6.9,0,12.6-5.6,12.6-12.6    C396.3,171.4,390.7,165.8,383.7,165.8z" />
            <path d="M373.9,231H213.4c-6.9,0-12.6,5.6-12.6,12.6s5.6,12.6,12.6,12.6h160.5c6.9,0,12.6-5.6,12.6-12.6    C386.5,236.7,380.9,231,373.9,231z" />
            <path d="M506.3,109.7c-4.8-5.9-11.9-9.3-19.5-9.3H95.3l-7.9-38.4c-1.7-8-7.1-14.7-14.6-18l-55.2-24    C11.2,17.4,3.8,20.3,1,26.7c-2.8,6.4,0.2,13.8,6.5,16.5l55.2,24l60.8,295.3c2.4,11.6,12.8,20.1,24.6,20.1h301.2    c6.9,0,12.6-5.6,12.6-12.6c0-6.9-5.6-12.6-12.6-12.6H148.2l-7.4-35.9h310.9c11.9,0,22.2-8.4,24.6-20.1l35.2-170.7    C513,123.3,511.1,115.6,506.3,109.7z M451.7,296.3H135.6l-35.2-170.7l386.4,0L451.7,296.3z" />
        </SVGIcon>
    );
}
