import React from 'react';

import { SVGIcon } from '../../UIKit/index';

export default function DCSmsCredits(props) {
    return (
        <SVGIcon {...props} viewBox="0 0 503.585 436.622">
            <defs>
                <clipPath id="clip-sms-credits">
                    <path
                        id="Path_10"
                        data-name="Path 10"
                        d="M445.53,216.53c-8.47-12.7-28.7-16.47-44.69-21.64s-25.4-15.52-21.17-40.93S406,87.16,405.54,79.64s-76.2-7.06-83.26-4.71-8.47,1.89-15,10.35a116.106,116.106,0,0,0-9.88,14.59l8.17-17.17h.59s.62-2.18,3.73-6.23,11.21-13.7,11.53-29.89S310.16,32,308,37s-15,22.11-18.69,26.78-11.52,17.44-11.52,17.44l-8.65,30s-1.88,8.93-12.7,42.8-6.11,49.4-6.11,49.4-2.83-1.41-9.41,18.81-3.77,46.11,8,58.34,27.29,11.76,44.69,4.7S319,262.63,319,262.63s18.34-4.7,32-15,24-21.17,43.75-20.23,42.34,12.23,49.87,11.76S454,229.23,445.53,216.53Z"
                        fill="#fff"
                        stroke="#707273"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="1"
                    />
                </clipPath>
            </defs>
            <g id="SMS_Credits" transform="translate(-4.26 -5.86)">
                <ellipse
                    id="_Path_"
                    data-name="&lt;Path&gt;"
                    cx="251.76"
                    cy="24.72"
                    rx="251.76"
                    ry="24.72"
                    transform="translate(4.26 457.2)"
                    fill="#f4f5f6"
                />
                <rect
                    id="Rectangle_1"
                    data-name="Rectangle 1"
                    width="259.62"
                    height="467.9"
                    rx="6.75"
                    transform="translate(121.19 14.02)"
                    fill="#707272"
                />
                <path
                    id="Path_1"
                    data-name="Path 1"
                    d="M269,42H236.63a3.31,3.31,0,0,1-3.31-3.32h0a3.31,3.31,0,0,1,3.31-3.31H269a3.32,3.32,0,0,1,3.32,3.31h0A3.32,3.32,0,0,1,269,42Z"
                    fill="#e4e3e3"
                />
                <path
                    id="Path_2"
                    data-name="Path 2"
                    d="M364.41,440.09H137.6V42.35h53.76l6,5a11.78,11.78,0,0,0,7.52,2.71h94.85a11.762,11.762,0,0,0,7.772-2.932l5.468-4.8h51.39Z"
                    fill="#fff"
                    stroke="#4f4f4f"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="0.94"
                />
                <path
                    id="Path_3"
                    data-name="Path 3"
                    d="M228.18,71.68l-3.1-10.4H147.9V429.37H354.81V71.68Z"
                    fill="#707272"
                />
                <rect
                    id="Rectangle_2"
                    data-name="Rectangle 2"
                    width="192.36"
                    height="327.54"
                    transform="translate(155.17 90.4)"
                    fill="#fff"
                />
                <path
                    id="Path_4"
                    data-name="Path 4"
                    d="M258.25,452.35a5.16,5.16,0,1,0-5.15,5.15A5.16,5.16,0,0,0,258.25,452.35Z"
                    fill="#f4f5f6"
                />
                <ellipse
                    id="Ellipse_1"
                    data-name="Ellipse 1"
                    cx="63.34"
                    cy="90.12"
                    rx="63.34"
                    ry="90.12"
                    transform="translate(173.57 223.39)"
                    fill="#e4e3e3"
                />
                <ellipse
                    id="Ellipse_2"
                    data-name="Ellipse 2"
                    cx="63.34"
                    cy="90.12"
                    rx="63.34"
                    ry="90.12"
                    transform="translate(173.57 223.39)"
                    fill="#e4e3e3"
                    opacity="0.5"
                />
                <path
                    id="Path_5"
                    data-name="Path 5"
                    d="M245.89,212.92s-4-6.6-7.6-6.93-46.9,26.75-53.5,40.95-12.89,42.5,4.67,69.79c17.1,26.55,43.88,25.67,57.75,24.35s23.17-5.85,37.28-17.14,27-27.91,28.85-39.2-5-17.56-9.41-19.13-29.79-1.25-48.29-20.07S245.89,212.92,245.89,212.92Z"
                    fill="#707272"
                />
                <path
                    id="Path_6"
                    data-name="Path 6"
                    d="M251.5,204.34s-11.23,4.29-18.16,11.89-11.23,19.48-11.56,37,5.28,37.32,27.08,45.91,41.29,2,47.56-4,13.88-22.79,13.88-22.79-34.68,9.24-51.2-10.24S251.5,204.34,251.5,204.34Z"
                    fill="#f4f5f6"
                />
                <path
                    id="Path_7"
                    data-name="Path 7"
                    d="M445.53,216.53c-8.47-12.7-28.7-16.47-44.69-21.64s-25.4-15.52-21.17-40.93S406,87.16,405.54,79.64s-76.2-7.06-83.26-4.71-8.47,1.89-15,10.35a116.106,116.106,0,0,0-9.88,14.59l8.17-17.17h.59s.62-2.18,3.73-6.23,11.21-13.7,11.53-29.89S310.16,32,308,37s-15,22.11-18.69,26.78-11.52,17.44-11.52,17.44l-8.65,30s-1.88,8.93-12.7,42.8-6.11,49.4-6.11,49.4-2.83-1.41-9.41,18.81-3.77,46.11,8,58.34,27.29,11.76,44.69,4.7S319,262.63,319,262.63s18.34-4.7,32-15,24-21.17,43.75-20.23,42.34,12.23,49.87,11.76S454,229.23,445.53,216.53Z"
                    fill="#fff"
                />
                <g id="Group_1" data-name="Group 1" clipPath="url(#clip-sms-credits)">
                    <path
                        id="Path_8"
                        data-name="Path 8"
                        d="M324.19,172.43c-52.34,7.81-68.8,20.31-63.56,37.49a26.28,26.28,0,0,0,5.17,9.22,7.9,7.9,0,0,0,.1,1.05s.93.57,2.32,1.54a42.46,42.46,0,0,0,5.92,4.8c3.43,3.27,6.51,7.35,5.88,11.14-1.35,8.06-20.17,22.18-29.58,22.85s-12.77-8.74-14.12,1.35,19.5,34.95,39.66,32.26,37-30.92,37-30.92-2.69-4.7,10.76-18.82c8.88-9.33,21.57-13.37,31.3-17.18A178.929,178.929,0,0,0,381.77,217c23.93-11.71,52.34-3.9,52.34-3.9S376.53,164.61,324.19,172.43Z"
                        fill="#c5d92d"
                    />
                    <path
                        id="Path_9"
                        data-name="Path 9"
                        d="M324.19,172.43c-52.34,7.81-68.8,20.31-63.56,37.49a26.28,26.28,0,0,0,5.17,9.22,7.9,7.9,0,0,0,.1,1.05s.93.57,2.32,1.54a42.46,42.46,0,0,0,5.92,4.8c3.43,3.27,6.51,7.35,5.88,11.14-1.35,8.06-20.17,22.18-29.58,22.85s-12.77-8.74-14.12,1.35,19.5,34.95,39.66,32.26,37-30.92,37-30.92-2.69-4.7,10.76-18.82c8.88-9.33,21.57-13.37,31.3-17.18A178.929,178.929,0,0,0,381.77,217c23.93-11.71,52.34-3.9,52.34-3.9S376.53,164.61,324.19,172.43Z"
                        fill="#e4e3e3"
                    />
                </g>
                <path
                    id="Path_11"
                    data-name="Path 11"
                    d="M445.53,216.53c-8.47-12.7-28.7-16.47-44.69-21.64s-25.4-15.52-21.17-40.93S406,87.16,405.54,79.64s-76.2-7.06-83.26-4.71-8.47,1.89-15,10.35a116.106,116.106,0,0,0-9.88,14.59l8.17-17.17h.59s.62-2.18,3.73-6.23,11.21-13.7,11.53-29.89S310.16,32,308,37s-15,22.11-18.69,26.78-11.52,17.44-11.52,17.44l-8.65,30s-1.88,8.93-12.7,42.8-6.11,49.4-6.11,49.4-2.83-1.41-9.41,18.81-3.77,46.11,8,58.34,27.29,11.76,44.69,4.7S319,262.63,319,262.63s18.34-4.7,32-15,24-21.17,43.75-20.23,42.34,12.23,49.87,11.76S454,229.23,445.53,216.53Z"
                    fill="none"
                    stroke="#707273"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="1"
                />
                <path
                    id="Path_12"
                    data-name="Path 12"
                    d="M445.72,239s1-4-10.24-9.25-15.86-1.65-15.86,1S444.07,239,445.72,239Z"
                    fill="#fff"
                    stroke="#707273"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="1"
                />
                <path
                    id="Path_13"
                    data-name="Path 13"
                    d="M339.43,57.83,311.09,79.62h38S349.08,52.84,339.43,57.83Z"
                    fill="#fff"
                    stroke="#707273"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="1"
                />
                <path
                    id="Path_14"
                    data-name="Path 14"
                    d="M303.89,215.07a39.73,39.73,0,0,1,14.66-10.4"
                    fill="none"
                    stroke="#707273"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="1"
                />
                <path
                    id="Path_15"
                    data-name="Path 15"
                    d="M298.74,223.49a19.531,19.531,0,0,1,1.69-3.61"
                    fill="none"
                    stroke="#707273"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="1"
                />
                <path
                    id="Path_16"
                    data-name="Path 16"
                    d="M287.45,252.91a40.434,40.434,0,0,1-4.24-4.32"
                    fill="none"
                    stroke="#707273"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="1"
                />
                <path
                    id="Path_17"
                    data-name="Path 17"
                    d="M319,262.63a41.61,41.61,0,0,1-25.22-5.17"
                    fill="none"
                    stroke="#707273"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="1"
                />
                <path
                    id="Path_18"
                    data-name="Path 18"
                    d="M424.91,77.76H282.16c-19.71,0-35.69,15.25-35.69,34.07v67.26c0,16.37,12.08,30,28.19,33.32l-7.53,22.28L302,213.17H424.91c19.71,0,35.69-15.26,35.69-34.08V111.83C460.6,93,444.62,77.76,424.91,77.76Z"
                    fill="#e4e3e3"
                    stroke="#707273"
                    strokeMiterlimit="10"
                    strokeWidth="1"
                />
                <line
                    id="Line_1"
                    data-name="Line 1"
                    x2="160.48"
                    transform="translate(269.71 123.05)"
                    fill="none"
                    stroke="#707273"
                    strokeLinecap="round"
                    strokeMiterlimit="10"
                    strokeWidth="2.06"
                />
                <line
                    id="Line_2"
                    data-name="Line 2"
                    x2="160.48"
                    transform="translate(269.71 139.57)"
                    fill="none"
                    stroke="#707273"
                    strokeLinecap="round"
                    strokeMiterlimit="10"
                    strokeWidth="2.06"
                />
                <line
                    id="Line_3"
                    data-name="Line 3"
                    x2="160.48"
                    transform="translate(269.71 156.08)"
                    fill="none"
                    stroke="#707273"
                    strokeLinecap="round"
                    strokeMiterlimit="10"
                    strokeWidth="2.06"
                />
                <line
                    id="Line_4"
                    data-name="Line 4"
                    x2="160.48"
                    transform="translate(269.71 172.6)"
                    fill="none"
                    stroke="#707273"
                    strokeLinecap="round"
                    strokeMiterlimit="10"
                    strokeWidth="2.06"
                />
                <path
                    id="Path_19"
                    data-name="Path 19"
                    d="M383,67.79s.62,12.46,11.83,18.37,25.53,4.67,25.84-2.8-8.4-15.26-14.32-25.22-19-4.36-24.6,2.49"
                    fill="#fff"
                    stroke="#707273"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="1"
                />
                <path
                    id="Path_20"
                    data-name="Path 20"
                    d="M402.26,77.76c-.93,5,2.49,7.78,5.61,9.34s9.34.62,12.76-3.74c.15-3.64-1.84-7.35-4.6-11.35C412.1,72.44,403,73.85,402.26,77.76Z"
                    fill="#fff"
                    stroke="#707273"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="1"
                />
                <path
                    id="Path_21"
                    data-name="Path 21"
                    d="M359.6,67.79s.62,12.46,11.83,18.37,25.54,4.67,25.85-2.8S388.87,68.1,383,58.14s-19-4.36-24.6,2.49"
                    fill="#fff"
                    stroke="#707273"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="1"
                />
                <path
                    id="Path_22"
                    data-name="Path 22"
                    d="M378.91,77.76c-.94,5,2.49,7.78,5.6,9.34s9.34.62,12.77-3.74c.15-3.64-1.84-7.35-4.61-11.35C388.74,72.44,379.64,73.85,378.91,77.76Z"
                    fill="#fff"
                    stroke="#707273"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="1"
                />
                <path
                    id="Path_23"
                    data-name="Path 23"
                    d="M337.87,67.79s.62,12.46,11.83,18.37,25.54,4.67,25.85-2.8-8.41-15.26-14.33-25.22-19-4.36-24.6,2.49"
                    fill="#fff"
                    stroke="#707273"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="1"
                />
                <path
                    id="Path_24"
                    data-name="Path 24"
                    d="M357.18,77.76c-.94,5,2.49,7.78,5.6,9.34s9.34.62,12.77-3.74c.15-3.64-1.85-7.35-4.61-11.35C367,72.44,357.91,73.85,357.18,77.76Z"
                    fill="#fff"
                    stroke="#707273"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="1"
                />
                <path
                    id="Path_25"
                    data-name="Path 25"
                    d="M250.31,203.36s.86,19.14,11.43,32"
                    fill="none"
                    stroke="#707273"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="1"
                />
                <path
                    id="Path_26"
                    data-name="Path 26"
                    d="M219.57,283.33c0,3.9-2.6,7.06-5.8,7.06s-5.8-3.16-5.8-7.06,2.6-7.06,5.8-7.06S219.57,279.43,219.57,283.33Z"
                    fill="#fff"
                />
                <path
                    id="Path_27"
                    data-name="Path 27"
                    d="M205.15,292.11c0,3.9-2.6,7.06-5.81,7.06s-5.8-3.16-5.8-7.06,2.6-7.06,5.8-7.06S205.15,288.21,205.15,292.11Z"
                    fill="none"
                    stroke="#e4e3e3"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="1"
                />
                <circle
                    id="Ellipse_3"
                    data-name="Ellipse 3"
                    cx="9.54"
                    cy="9.54"
                    r="9.54"
                    transform="translate(414.65 6.86)"
                    fill="#fff"
                    stroke="#e2e2e2"
                    strokeMiterlimit="10"
                    strokeWidth="2"
                />
                <circle
                    id="Ellipse_4"
                    data-name="Ellipse 4"
                    cx="9.54"
                    cy="9.54"
                    r="9.54"
                    transform="translate(77.05 418.09)"
                    fill="#fff"
                    stroke="#e2e2e2"
                    strokeMiterlimit="10"
                    strokeWidth="2"
                />
                <circle
                    id="Ellipse_5"
                    data-name="Ellipse 5"
                    cx="15.08"
                    cy="15.08"
                    r="15.08"
                    transform="translate(466.07 30.19)"
                    fill="#fff"
                    stroke="#e2e2e2"
                    strokeMiterlimit="10"
                    strokeWidth="2"
                />
                <path
                    id="Path_28"
                    data-name="Path 28"
                    d="M441.68,422.41H431.46v-10.2a1.26,1.26,0,0,0-1.27-1.27h0a1.29,1.29,0,0,0-1.29,1.27v10.2H418.71a1.29,1.29,0,0,0,0,2.57H428.9v10.21a1.29,1.29,0,0,0,1.29,1.27,1.27,1.27,0,0,0,1.27-1.27h0V425h10.22a1.29,1.29,0,0,0,0-2.57Z"
                    fill="#e2e2e2"
                    stroke="#e2e2e2"
                    strokeMiterlimit="10"
                    strokeWidth="0.75"
                />
                <path
                    id="Path_29"
                    data-name="Path 29"
                    d="M74.52,73.23H64.3V63A1.27,1.27,0,0,0,63,61.76h0A1.3,1.3,0,0,0,61.73,63V73.2H51.55a1.29,1.29,0,1,0,0,2.57H61.73V86A1.3,1.3,0,0,0,63,87.28,1.27,1.27,0,0,0,64.3,86h0V75.8H74.52a1.29,1.29,0,0,0,0-2.57Z"
                    fill="#e2e2e2"
                    stroke="#e2e2e2"
                    strokeMiterlimit="10"
                    strokeWidth="0.75"
                />
                <path
                    id="Path_30"
                    data-name="Path 30"
                    d="M84.2,125.37H76.73v-7.46a.93.93,0,0,0-.93-.93h0a1,1,0,0,0-1,.93v7.46H67.4a.94.94,0,1,0,0,1.88h7.45v7.47a1,1,0,0,0,1,.93.93.93,0,0,0,.93-.93h0v-7.47H84.2a.94.94,0,0,0,.94-.93,1,1,0,0,0-.94-.95Z"
                    fill="#e2e2e2"
                    stroke="#e2e2e2"
                    strokeMiterlimit="10"
                    strokeWidth="0.75"
                />
            </g>
        </SVGIcon>
    );
}
