const instaSiteActions = {
    SET_CATEGORIES: 'SET_CATEGORIES',
    SET_MAIN_CATEGORIES: 'SET_MAIN_CATEGORIES',
    SET_TEMPLATES_DATA: 'SET_TEMPLATES_DATA',
    SET_INITIAL_TEMPLATE_LOAD: 'SET_INITIAL_TEMPLATE_LOAD',
    SET_ACTIVE_CATEGORY: 'SET_ACTIVE_CATEGORY',
    SET_TEMPLATE_SEARCH_TEXT: 'SET_TEMPLATE_SEARCH_TEXT',

    SET_STATUS: 'SET_STATUS',
    SET_INITIAL_MY_INSTASITE_LOAD: 'SET_INITIAL_MY_INSTASITE_LOAD',
    SET_FIELDS: 'SET_FIELDS',
    SET_PAGE: 'SET_PAGE',
    SET_PAGE_LIMIT: 'SET_PAGE_LIMIT',
    SET_TOTAL_RESULTS: 'SET_TOTAL_RESULTS',
    SET_DATA: 'SET_DATA',
    SET_TOTAL_PAGES: 'SET_TOTAL_PAGES',
    SET_SORT_STRING: 'SET_SORT_STRING',
    SET_MY_INSTASITE_SEARCH_TEXT: 'SET_MY_INSTASITE_SEARCH_TEXT',

    SET_SELECTED_INSTASITE: 'SET_SELECTED_INSTASITE',
    SET_STATUS_STRING: 'SET_STATUS_STRING',
    SET_ADDITIONAL_INFO: 'SET_ADDITIONAL_INFO',
};

export default instaSiteActions;
