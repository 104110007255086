import React from 'react';

import { SVGIcon } from '../../UIKit/index';

export default function DCContactList(props) {
    return (
        <SVGIcon {...props} viewBox="0 0 509 452">
            <rect fill="#F3F9FF" x="0.37" y="65.4" width="508.63" height="386.93" />
            <path
                fill="#2B88F8"
                d="M0.37,71.91V45.42c0,0,4.57-41.48,34.28-44.74h443.12c0,0,28.34,7.82,31.23,45.17v27.36  L0.37,71.91z"
            />
            <g transform="translate(6.242 12.04)">
                <path
                    fill="#D9EAFF"
                    d="M444.13,178.48H55.31c-4.53-0.41-7.89-4.37-7.57-8.91v-68.63c-0.31-4.53,3.05-8.48,7.57-8.9   h388.82c4.53,0.41,7.89,4.37,7.57,8.91v68.84C452,174.28,448.62,178.17,444.13,178.48z"
                />
                <g transform="translate(2.781 1.561)">
                    <circle fill="#FFFFFF" cx="100.77" cy="135.82" r="31.93" />
                    <g transform="translate(1.239 0.977)">
                        <path
                            fill="#005EFF"
                            d="M115.6,142.66c0,0,2.17,7.82-13.03,6.73l-9.12-0.44c0,0-4.56,1.31-5.87,0.44     c0,0-9.12-3.69-9.34-10.64c0,0,12.81-0.86,8.68-16.32c0.47-6.08,5.42-10.84,11.51-11.08c0,0,19.75-2.17,15.2,16.32l-5,11.51     C108.64,139.18,115.18,139.41,115.6,142.66z"
                        />
                        <path
                            fill="#F9C284"
                            d="M106.05,120.3c0,0,1.52,8.68-10.2,11.72c0,0,2.58,7.16,1.52,9.12c0,0-3.91,10.43-8.04,12.16     c3.46,5.13,10.34,6.65,15.63,3.44l-1.72-11.72c0,0,8.91-0.21,10.2-7.39C113.44,137.89,116.25,122.87,106.05,120.3z"
                        />
                    </g>
                </g>
                <g transform="translate(11.717 2.601)">
                    <path
                        fill="#FFFFFF"
                        d="M143.36,111.79h98.29c3.7,0,6.7,3,6.7,6.7v0.01c0,3.7-3,6.7-6.7,6.7h-98.29    c-3.7,0-6.7-3-6.7-6.7v-0.01C136.66,114.79,139.66,111.79,143.36,111.79z"
                    />
                    <path
                        fill="#FFFFFF"
                        d="M143.36,138.6h223.39c3.7,0,6.7,3,6.7,6.7v0.01c0,3.7-3,6.7-6.7,6.7H143.36    c-3.7,0-6.7-3-6.7-6.7v-0.01C136.66,141.6,139.66,138.6,143.36,138.6z"
                    />
                    <path
                        fill="#FFFFFF"
                        d="M268.46,111.79h98.29c3.7,0,6.7,3,6.7,6.7v0.01c0,3.7-3,6.7-6.7,6.7h-98.29    c-3.7,0-6.7-3-6.7-6.7v-0.01C261.76,114.79,264.76,111.79,268.46,111.79z"
                    />
                </g>
            </g>
            <g transform="translate(6.242 25.044)">
                <path
                    fill="#D9EAFF"
                    d="M444.13,277.17H55.31c-4.53-0.41-7.89-4.37-7.57-8.91v-68.63c-0.31-4.53,3.05-8.48,7.57-8.9   h388.82c4.53,0.41,7.89,4.37,7.57,8.91v68.84C452,272.97,448.62,276.86,444.13,277.17z"
                />
                <g transform="translate(2.781 1.561)">
                    <circle fill="#FFFFFF" cx="100.77" cy="234.51" r="31.93" />
                    <g transform="translate(1.239 0.977)">
                        <path
                            fill="#005EFF"
                            d="M115.6,241.35c0,0,2.17,7.82-13.03,6.73l-9.12-0.44c0,0-4.56,1.31-5.87,0.44     c0,0-9.12-3.69-9.34-10.64c0,0,12.81-0.86,8.68-16.32c0.47-6.08,5.42-10.84,11.51-11.08c0,0,19.75-2.17,15.2,16.32l-5,11.51     C108.64,237.87,115.18,238.09,115.6,241.35z"
                        />
                        <path
                            fill="#F9C284"
                            d="M106.05,218.98c0,0,1.52,8.68-10.2,11.72c0,0,2.58,7.16,1.52,9.12     c0,0-3.91,10.43-8.04,12.16c3.46,5.13,10.34,6.65,15.63,3.44l-1.72-11.72c0,0,8.91-0.21,10.2-7.39     C113.44,236.57,116.25,221.56,106.05,218.98z"
                        />
                    </g>
                </g>
                <g transform="translate(11.717 2.601)">
                    <path
                        fill="#FFFFFF"
                        d="M143.36,210.48h98.29c3.7,0,6.7,3,6.7,6.7v0.01c0,3.7-3,6.7-6.7,6.7h-98.29    c-3.7,0-6.7-3-6.7-6.7v-0.01C136.66,213.48,139.66,210.48,143.36,210.48z"
                    />
                    <path
                        fill="#FFFFFF"
                        d="M143.36,237.28h223.39c3.7,0,6.7,3,6.7,6.7v0.01c0,3.7-3,6.7-6.7,6.7H143.36    c-3.7,0-6.7-3-6.7-6.7v-0.01C136.66,240.28,139.66,237.28,143.36,237.28z"
                    />
                    <path
                        fill="#FFFFFF"
                        d="M268.46,210.48h98.29c3.7,0,6.7,3,6.7,6.7v0.01c0,3.7-3,6.7-6.7,6.7h-98.29    c-3.7,0-6.7-3-6.7-6.7v-0.01C261.76,213.48,264.76,210.48,268.46,210.48z"
                    />
                </g>
            </g>
            <g transform="translate(6.242 38.568)">
                <path
                    fill="#D9EAFF"
                    d="M444.13,379.8H55.31c-4.53-0.41-7.89-4.37-7.57-8.91v-68.63c-0.31-4.53,3.05-8.48,7.57-8.9   h388.82c4.53,0.41,7.89,4.37,7.57,8.91v68.84C452,375.6,448.62,379.49,444.13,379.8z"
                />
                <g transform="translate(2.781 1.561)">
                    <circle fill="#FFFFFF" cx="100.77" cy="337.14" r="31.93" />
                    <g transform="translate(1.239 0.977)">
                        <path
                            fill="#005EFF"
                            d="M115.6,343.98c0,0,2.17,7.82-13.03,6.73l-9.12-0.44c0,0-4.56,1.31-5.87,0.44     c0,0-9.12-3.69-9.34-10.64c0,0,12.81-0.86,8.68-16.32c0.47-6.08,5.42-10.84,11.51-11.08c0,0,19.75-2.17,15.2,16.32l-5,11.51     C108.64,340.5,115.18,340.73,115.6,343.98z"
                        />
                        <path
                            fill="#F9C284"
                            d="M106.05,321.62c0,0,1.52,8.68-10.2,11.72c0,0,2.58,7.16,1.52,9.12     c0,0-3.91,10.43-8.04,12.16c3.46,5.13,10.34,6.65,15.63,3.44l-1.72-11.72c0,0,8.91-0.21,10.2-7.39     C113.44,339.21,116.25,324.19,106.05,321.62z"
                        />
                    </g>
                </g>
                <g transform="translate(11.717 2.601)">
                    <path
                        fill="#FFFFFF"
                        d="M143.36,313.11h98.29c3.7,0,6.7,3,6.7,6.7v0.01c0,3.7-3,6.7-6.7,6.7h-98.29    c-3.7,0-6.7-3-6.7-6.7v-0.01C136.66,316.11,139.66,313.11,143.36,313.11z"
                    />
                    <path
                        fill="#FFFFFF"
                        d="M143.36,339.92h223.39c3.7,0,6.7,3,6.7,6.7v0.01c0,3.7-3,6.7-6.7,6.7H143.36    c-3.7,0-6.7-3-6.7-6.7v-0.01C136.66,342.92,139.66,339.92,143.36,339.92z"
                    />
                    <path
                        fill="#FFFFFF"
                        d="M268.46,313.11h98.29c3.7,0,6.7,3,6.7,6.7v0.01c0,3.7-3,6.7-6.7,6.7h-98.29    c-3.7,0-6.7-3-6.7-6.7v-0.01C261.76,316.11,264.76,313.11,268.46,313.11z"
                    />
                </g>
            </g>
        </SVGIcon>
    );
}
