import React from 'react';

import { SVGIcon } from '../../UIKit/index';

export default function DCWebsite(props) {
    return (
        <SVGIcon {...props} viewBox="0 0 512 448">
            <g transform="translate(0 -0.5)">
                <path
                    fill="#D9EAFF"
                    d="M511,103.04v294.45c0,28.09-22.77,50.86-50.86,50.86H53.23c-28.09,0-50.86-22.77-50.86-50.85   V103.04H511z M232.55,376.81V183.76H71.04v193.06L232.55,376.81z"
                />
                <path
                    fill="#005EFF"
                    d="M511,52.52v50.52H2.37V52.52c0-28.09,22.77-50.86,50.86-50.86h406.91   C488.23,1.66,511,24.43,511,52.52z M204.72,52.52c0-10.38-8.42-18.79-18.8-18.78c-10.38,0-18.79,8.42-18.78,18.8   c0,10.37,8.42,18.78,18.79,18.78C196.3,71.32,204.72,62.91,204.72,52.52C204.72,52.53,204.72,52.52,204.72,52.52z M140.59,52.52   c0-10.38-8.42-18.8-18.8-18.8c-10.38,0-18.8,8.42-18.8,18.8s8.42,18.8,18.8,18.8c0.01,0,0.02,0,0.03,0   c10.38,0,18.8-8.41,18.81-18.79c0,0,0-0.01,0-0.01L140.59,52.52z M76.46,52.52c0-10.38-8.42-18.8-18.8-18.8s-18.8,8.42-18.8,18.8   s8.42,18.8,18.8,18.8c10.38,0,18.79-8.4,18.8-18.78C76.46,52.53,76.46,52.53,76.46,52.52z"
                />
                <path
                    fill="#FFFFFF"
                    d="M204.72,52.52c0,10.38-8.42,18.79-18.8,18.78c-10.38,0-18.79-8.42-18.78-18.8   c0-10.37,8.42-18.78,18.79-18.78C196.3,33.72,204.72,42.13,204.72,52.52C204.72,52.51,204.72,52.52,204.72,52.52z"
                />
                <path
                    fill="#FFFFFF"
                    d="M121.78,33.73c10.38,0,18.8,8.41,18.81,18.79c0,10.38-8.41,18.8-18.79,18.81   c-10.38,0-18.8-8.41-18.81-18.79c0-0.01,0-0.01,0-0.02C102.99,42.14,111.4,33.73,121.78,33.73L121.78,33.73z"
                />
                <path
                    fill="#FFFFFF"
                    d="M76.46,52.52c0,10.38-8.42,18.8-18.8,18.8c-10.38,0-18.8-8.42-18.8-18.8   c0-10.38,8.42-18.8,18.8-18.8c0,0,0,0,0,0c10.38,0,18.79,8.4,18.8,18.78C76.46,52.51,76.46,52.51,76.46,52.52z"
                />
                <g transform="translate(35.01 21.525)">
                    <g>
                        <path
                            fill="#6B8AAA"
                            d="M404.55,299.85H264.67c-4.92,0-8.9-3.99-8.9-8.9c0-4.92,3.99-8.9,8.9-8.9h139.87     c4.92,0,8.9,3.99,8.9,8.9C413.45,295.86,409.47,299.85,404.55,299.85z"
                        />
                        <path
                            fill="#6B8AAA"
                            d="M404.55,355.96H264.67c-4.92,0-8.9-3.99-8.9-8.9s3.99-8.9,8.9-8.9h139.87     c4.92,0,8.9,3.99,8.9,8.9S409.47,355.96,404.55,355.96z"
                        />
                        <path
                            fill="#6B8AAA"
                            d="M404.55,187.62H264.67c-4.92,0-8.9-3.99-8.9-8.9c0-4.92,3.99-8.9,8.9-8.9h139.87     c4.92,0,8.9,3.99,8.9,8.9C413.45,183.63,409.47,187.62,404.55,187.62z"
                        />
                        <path
                            fill="#6B8AAA"
                            d="M404.55,243.73H264.67c-4.92,0-8.9-3.99-8.9-8.9c0-4.92,3.99-8.9,8.9-8.9h139.87     c4.92,0,8.9,3.99,8.9,8.9C413.45,239.74,409.47,243.73,404.55,243.73z"
                        />
                    </g>
                </g>
                <path
                    fill="#6B8AAA"
                    d="M83.19,178.46h140.05c9.1,0,16.48,7.38,16.48,16.48v173c0,9.1-7.38,16.48-16.48,16.48   H83.19c-9.1,0-16.48-7.38-16.48-16.48v-173C66.72,185.84,74.1,178.46,83.19,178.46z"
                />
            </g>
        </SVGIcon>
    );
}
