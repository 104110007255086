import React from 'react';

import { SVGIcon } from '../../UIKit/index';

export default function DCTemplates1(props) {
    return (
        <SVGIcon {...props} viewBox="0 0 505.82 422.548">
            <defs>
                <linearGradient
                    id="linear-templates"
                    x1="0.505"
                    y1="-0.112"
                    x2="0.495"
                    y2="0.524"
                    gradientUnits="objectBoundingBox"
                >
                    <stop offset="0" stopColor="#ff9085" />
                    <stop offset="1" stopColor="#fb6fbb" />
                </linearGradient>
            </defs>
            <g id="Templates" transform="translate(-3.09 -43.462)">
                <g id="Layer_1" data-name="Layer 1">
                    <ellipse
                        id="_Path_"
                        data-name="&lt;Path&gt;"
                        cx="252.91"
                        cy="100.34"
                        rx="252.91"
                        ry="100.34"
                        transform="translate(3.09 265.33)"
                        fill="#f3f4f5"
                    />
                    <path
                        id="Path_1"
                        data-name="Path 1"
                        d="M332.72,305.35a15.4,15.4,0,0,1-10.21-11.59l-7.59-37.56L250,258.51l-8,39.13c-1.12,5.48,3.36,11,8.52,13l27.16,10.63a35.39,35.39,0,0,0,15.87,2.3l57.4-4.91a3.56,3.56,0,0,0,3.22-3.16c.19-1.69,1.37-3.59-.23-4.12Z"
                        fill="#717272"
                    />
                    <path
                        id="Path_2"
                        data-name="Path 2"
                        d="M333.36,303.07a15.27,15.27,0,0,1-10.2-11.45l-7.58-37.08-63.33,2.28-8,38.63a11.3,11.3,0,0,0,7,12.83l27.13,10.49a35.78,35.78,0,0,0,15.87,2.28l57.33-4.85a3.54,3.54,0,0,0,.81-6.89Z"
                        fill="#515151"
                    />
                    <path
                        id="Path_3"
                        data-name="Path 3"
                        d="M437,230.09V61.68a6.64,6.64,0,0,0-6.34-6.53L141.36,43.52c-4.4-.18-8,2.86-8,6.76V236.4Z"
                        fill="#717272"
                    />
                    <path
                        id="Path_4"
                        data-name="Path 4"
                        d="M142.3,76.18V221.56c0,3.63,3.35,6.51,7.44,6.39l274.59-7.81a6.14,6.14,0,0,0,5.95-6V84.42Z"
                        fill="#fff"
                    />
                    <path
                        id="Path_5"
                        data-name="Path 5"
                        d="M430.28,84.42V69.54a6.08,6.08,0,0,0-5.83-6L149.59,53.31c-4-.15-7.29,2.63-7.29,6.19V76.18Z"
                        fill="#515151"
                    />
                    <path
                        id="Path_6"
                        data-name="Path 6"
                        d="M162.39,64.86A2.32,2.32,0,0,1,159.84,67a2.51,2.51,0,0,1-2.55-2.34,2.34,2.34,0,0,1,2.55-2.19A2.52,2.52,0,0,1,162.39,64.86Z"
                        fill="#e3e2e2"
                    />
                    <path
                        id="Path_7"
                        data-name="Path 7"
                        d="M173.36,65.22a2.31,2.31,0,0,1-2.52,2.17,2.49,2.49,0,0,1-2.52-2.33,2.3,2.3,0,0,1,2.52-2.17,2.5,2.5,0,0,1,2.52,2.33Z"
                        fill="#e3e2e2"
                    />
                    <path
                        id="Path_8"
                        data-name="Path 8"
                        d="M184.2,65.58a2.29,2.29,0,0,1-2.48,2.16,2.48,2.48,0,0,1-2.5-2.32,2.29,2.29,0,0,1,2.5-2.16A2.47,2.47,0,0,1,184.2,65.58Z"
                        fill="#e3e2e2"
                    />
                    <path
                        id="Path_9"
                        data-name="Path 9"
                        d="M133.35,236.4v37.17c0,3.89,3.61,6.93,8,6.74l289.33-12.23a6.67,6.67,0,0,0,6.34-6.55V229Z"
                        fill="#515151"
                    />
                    <path
                        id="Path_10"
                        data-name="Path 10"
                        d="M322.92,157.66l-159.54.87V91.91l159.54,2.9Z"
                        fill="#e3e2e2"
                    />
                    <path
                        id="Path_11"
                        data-name="Path 11"
                        d="M413.01,157.17l-80.73.44V94.98l80.73,1.47Z"
                        fill="#e3e2e2"
                    />
                    <path
                        id="Path_12"
                        data-name="Path 12"
                        d="M413.01,202.25l-249.63,5.72V167.22l249.63-2.12Z"
                        fill="#e3e2e2"
                    />
                    <g id="Group_1" data-name="Group 1" opacity="0.48">
                        <path
                            id="Path_13"
                            data-name="Path 13"
                            d="M405.69,208.35c-23.76-8-43.42,8.34-60.51-4.55-19.25-14.53-3.69-42.45-21.5-58.9-24.75-22.84-74.22,12.59-95.7-9-14.77-14.81,2.65-38.52-9.86-63.46-6.36-12.68-18.28-21.33-32.74-27.2l-44-1.77c-4.4-.18-8,2.86-8,6.76V273.57c0,3.89,3.61,6.93,8,6.74l289.33-12.23a6.67,6.67,0,0,0,6.34-6.55v-24C426.84,219.49,415.59,211.69,405.69,208.35Z"
                            fill="#fff"
                        />
                    </g>
                    <path
                        id="Path_14"
                        data-name="Path 14"
                        d="M215.6,206.3V401.13c0,2.91-3.19,3.77-6.81,4.12l-125.07,14-1.92.19c-3.92.38-7.14-1.8-7.14-4.84V211c0-3,3.22-5.63,7.14-5.76l2.6-.08,125.52.41C213.54,205.45,215.6,203.39,215.6,206.3Z"
                        fill="#515151"
                    />
                    <path
                        id="Path_15"
                        data-name="Path 15"
                        d="M219.46,206.08V400.85c0,2.92-3,5.59-6.57,5.94L85.74,419.14c-3.93.38-7.14-1.8-7.14-4.84V210.77c0-3,3.21-5.64,7.14-5.76l127.15-4C216.51,200.88,219.46,203.17,219.46,206.08Z"
                        fill="#717272"
                    />
                    <path
                        id="Path_16"
                        data-name="Path 16"
                        d="M88.5,216.53l121.2-4.23a5.45,5.45,0,0,1,5.65,5.45l.23,178.83a6.51,6.51,0,0,1-5.88,6.49L88.84,414.8a5.26,5.26,0,0,1-5.76-5.23V222.18A5.65,5.65,0,0,1,88.5,216.53Z"
                        fill="#fff"
                    />
                    <path
                        id="Path_17"
                        data-name="Path 17"
                        d="M92.61,261.11l68.97-3.32.05,64.61-69,4.75Z"
                        fill="#e3e2e2"
                    />
                    <path
                        id="Path_18"
                        data-name="Path 18"
                        d="M92.63,335.46l114.04-8.14.04,38.82L92.64,375.73Z"
                        fill="#e3e2e2"
                    />
                    <path
                        id="Path_19"
                        data-name="Path 19"
                        d="M89,216.41l120.66-4.11a5.55,5.55,0,0,1,5.74,5.53v26.61L83,250.34V222.56A6.15,6.15,0,0,1,89,216.41Z"
                        fill="#e3e2e2"
                    />
                    <path
                        id="Path_20"
                        data-name="Path 20"
                        d="M92.65,384.62l114.07-9.91.01,17.51L92.65,402.78Z"
                        fill="#e3e2e2"
                    />
                    <path
                        id="Path_21"
                        data-name="Path 21"
                        d="M167.94,257.49l38.65-1.86.07,63.67-38.67,2.66Z"
                        fill="#e3e2e2"
                    />
                    <path
                        id="Path_22"
                        data-name="Path 22"
                        d="M152.94,208.89a2.25,2.25,0,0,1-2.36,2,2.08,2.08,0,0,1-2.37-1.79,2.26,2.26,0,0,1,2.37-2,2.08,2.08,0,0,1,2.36,1.79Z"
                        fill="#e3e2e2"
                    />
                    <g id="Group_2" data-name="Group 2" opacity="0.48">
                        <path
                            id="Path_23"
                            data-name="Path 23"
                            d="M178.35,318.16c-11.57-23.21-38.6-17.67-48.76-43-6.06-15.1,1.48-22.27-3.06-40.69-3.07-12.47-9.78-22.41-17.39-30.21l-23.4.74c-3.93.12-7.14,2.72-7.14,5.76V414.3c0,3,3.21,5.22,7.14,4.84l102.32-9.94c-7.57-10.53-10.82-20.43-12-29C172.45,353.52,188.63,338.78,178.35,318.16Z"
                            fill="#fff"
                        />
                    </g>
                    <path
                        id="Path_24"
                        data-name="Path 24"
                        d="M269.86,273.27V407.73c0,2-1.72,2.57-3.67,2.77l-67.12,8.31-1,.11a3.37,3.37,0,0,1-3.81-3.4V275.65a4,4,0,0,1,3.81-3.91h1.39l67.36,1C268.74,272.67,269.86,271.26,269.86,273.27Z"
                        fill="#515151"
                    />
                    <path
                        id="Path_25"
                        data-name="Path 25"
                        d="M271.94,273.14V407.58a4.12,4.12,0,0,1-3.54,4l-68.26,7.16a3.38,3.38,0,0,1-3.81-3.41V275.52a4,4,0,0,1,3.81-3.91l68.26-2a3.49,3.49,0,0,1,3.54,3.53Z"
                        fill="#717272"
                    />
                    <path
                        id="Path_26"
                        data-name="Path 26"
                        d="M202.41,279.53l59.7-2.18a7.37,7.37,0,0,1,7.64,7.36V405.16A3.86,3.86,0,0,1,266.3,409l-64,6.73a3.29,3.29,0,0,1-3.63-3.27V283.38a3.86,3.86,0,0,1,3.74-3.85Z"
                        fill="#fff"
                    />
                    <path
                        id="Path_27"
                        data-name="Path 27"
                        d="M206.13,311.61l35.18-1.86v44.78l-35.18,2.67Z"
                        fill="#e3e2e2"
                    />
                    <path
                        id="Path_28"
                        data-name="Path 28"
                        d="M206.13,362.93l56.66-4.46-.01,26.98-56.65,5.25Z"
                        fill="#e3e2e2"
                    />
                    <path
                        id="Path_29"
                        data-name="Path 29"
                        d="M206.13,395.6l56.65-5.4v12.16l-56.65,5.76Z"
                        fill="#e3e2e2"
                    />
                    <path
                        id="Path_30"
                        data-name="Path 30"
                        d="M245.09,309.55l17.7-.94V352.9l-17.7,1.34Z"
                        fill="#e3e2e2"
                    />
                    <path
                        id="Path_31"
                        data-name="Path 31"
                        d="M202.13,279.54l63.76-2.33a3.72,3.72,0,0,1,3.86,3.72v18.63l-71,3.44V283.1a3.56,3.56,0,0,1,3.38-3.56Z"
                        fill="#e3e2e2"
                    />
                    <path
                        id="Path_32"
                        data-name="Path 32"
                        d="M236.15,274.68a1.33,1.33,0,0,1-1.27,1.33,1.24,1.24,0,0,1-1.27-1.25,1.34,1.34,0,0,1,1.27-1.33,1.24,1.24,0,0,1,1.27,1.25Z"
                        fill="#e3e2e2"
                    />
                    <g id="Group_3" data-name="Group 3" opacity="0.48">
                        <path
                            id="Path_33"
                            data-name="Path 33"
                            d="M249.75,353.66c-6.23-15.66-23-12-28.25-27.78-4.27-12.92,5.23-20.49,1.51-35.44-2.08-8.4-7.22-14.63-12.46-19.14l-10.41.31a4,4,0,0,0-3.81,3.91V415.36a3.38,3.38,0,0,0,3.81,3.41l52.33-5.49a41.7,41.7,0,0,1-6.48-17.7C243.82,377.3,255.45,368,249.75,353.66Z"
                            fill="#fff"
                        />
                    </g>
                    <path
                        id="Path_34"
                        data-name="Path 34"
                        d="M389.63,375.5l76.65-19.17,3.84,18.62-70.17,32.39-55.56-13.73L310.87,360.7Z"
                        fill="url(#linear-templates)"
                    />
                    <path
                        id="Path_35"
                        data-name="Path 35"
                        d="M389.63,375.5l76.65-19.17,3.84,18.62-70.17,32.39-55.56-13.73L310.87,360.7Z"
                        fill="#515151"
                    />
                    <path
                        id="Path_36"
                        data-name="Path 36"
                        d="M387.22,401.28a208,208,0,0,0,34.13-22.78c15-12.37,21.92-21.7,37.13-25.39,2.72-.66,18.21-4.41,23.2,2.47,5.54,7.61-5.22,23.54-6.53,25.47-19.43,28.77-68.92,27.43-84.45,26.49Z"
                        fill="#717272"
                    />
                    <path
                        id="Path_37"
                        data-name="Path 37"
                        d="M481.68,355.58a10.13,10.13,0,0,0-6.49-3.63,12.32,12.32,0,0,1-.15,8.49c-3,6.9-12,4.77-18.34,12.87-6.21,7.95-1.91,15.61-7.4,18.89-6.28,3.76-14.07-5-20.81-.82-4.57,2.86-3.15,8.27-8.48,11.23-3.69,2-7.31,1.07-11.5.54a59.53,59.53,0,0,0-19.69,1l1.88,3.39c15.53.94,65,2.28,84.45-26.49C476.46,379.12,487.22,363.19,481.68,355.58Z"
                        fill="#020202"
                        opacity="0.2"
                    />
                    <path
                        id="Path_38"
                        data-name="Path 38"
                        d="M398.74,405.58a7.86,7.86,0,0,1,6.84-.64c4.05,1.36,10.49,2.46,10.48,4.44s-6.45,3.22-10.9,2.41C400.91,411,397.57,407.31,398.74,405.58Z"
                        fill="#515151"
                    />
                    <path
                        id="Path_39"
                        data-name="Path 39"
                        d="M394.65,402.2l7.46,2.39s1.21,2-.43,3.3-7.31-1.43-7.31-1.43Z"
                        fill="#e3e2e2"
                    />
                    <path
                        id="Path_40"
                        data-name="Path 40"
                        d="M291,367.83c-2.2,12.82,25.46,26.68,31.79,29.85,12.91,6.47,37.19,15.6,73.23,12.11.48-2.87.95-5.75,1.43-8.62a170.375,170.375,0,0,1-20-8.31c-14-6.87-19.27-11.78-38.66-23.5-21.45-13-27.7-14.64-34.09-13C299.26,357.7,292.06,361.88,291,367.83Z"
                        fill="#717272"
                    />
                    <path
                        id="Path_41"
                        data-name="Path 41"
                        d="M319.85,343.46c-4.67-16.91,3-39.72,19.31-50.73,13.27-9,28.83-8.39,33.68-8.21,5.31.2,4.76.89,21.49,4,23.21,4.3,25.36,3.26,32.11,6.49a46.45,46.45,0,0,1,20,19.3c5.91,11,10.71,31.48-.5,41.49-6.37,5.69-15.3,5.67-20.33,5.14-.34-2.26-.69-4.52-1-6.78A20.71,20.71,0,0,0,432.4,340c.85-9.82-6-16.15-6.78-16.84a66.64,66.64,0,0,0-5.19,48.1,79.54,79.54,0,0,1-29.52,9.4c-10,1.1-22.3,2.47-30.19-3.7-11.13-8.69-12.07-31.34-1.08-54.92-4,2.69-9.85,7.57-12.27,15.29-.36,1.17-1,3.46-1.23,12.32C345.7,365.55,324.7,361,319.85,343.46Z"
                        fill="#717272"
                    />
                    <path
                        id="Path_42"
                        data-name="Path 42"
                        d="M395.22,355.17c-5.6.68-7.12,6.5-12.32,6.16-11.31-.74-20.38-20.32-15.61-31,3.12-7,11.24-8.19,10.68-12.74-.44-3.63-6.07-6.43-10.68-6.57-7.16-.21-11.89,6-13.55,8.22a24.57,24.57,0,0,0-4.8,14.34A31.65,31.65,0,0,1,359.64,322c-11,23.58-10.05,46.23,1.08,54.92,7.89,6.17,20.23,4.8,30.19,3.7a79.1,79.1,0,0,0,25.63-7.4C410.53,363.45,402.88,354.23,395.22,355.17Z"
                        fill="#020202"
                        opacity="0.2"
                    />
                    <path
                        id="Path_43"
                        data-name="Path 43"
                        d="M414.74,371.12l-65.53-.98,17.42,8.41,48.28-3.16Z"
                        fill="#717272"
                    />
                    <path
                        id="Path_44"
                        data-name="Path 44"
                        d="M346.89,371.88h0a2.92,2.92,0,0,0,1.92,2.74l20.95,8.21.09-5.69-20.64-7a1.81,1.81,0,0,0-2.32,1.74Z"
                        fill="#f3f4f5"
                    />
                    <path
                        id="Path_45"
                        data-name="Path 45"
                        d="M323,332.19c5.06-4.78,17.47-1.77,22.09,5.11,3.41,5.06.59,9.23,3.09,15.61,3.05,7.81,14.57,14.88,21.65,17.23-1.11,2.38-.78,3-1.89,5.39-4.5-.56-37.06-5.55-45-24.88C322.52,349.64,317.6,337.33,323,332.19Z"
                        fill="#e3e2e2"
                    />
                    <path
                        id="Path_46"
                        data-name="Path 46"
                        d="M396.92,281.73s-5.3,5.73-9.7,6.32-10.75-.57-11.85,0,5.53,2.93,10.78,4.37,14.78,4.32,17.42,3.69-2.88-4-5.07-4.91.95-5.49.95-5.49Z"
                        fill="#e3e2e2"
                    />
                    <path
                        id="Path_47"
                        data-name="Path 47"
                        d="M404.79,285a5.34,5.34,0,0,0,3.64-4.91,5.78,5.78,0,0,0-2.17-4c-2.33-2.27-6.39-3.69-9.34-2.2-1.74.88-2.54,4.9-2.71,5.6a5.28,5.28,0,0,0,.19,2.12c.12,1.08.29,2.78.54,5.6a12.457,12.457,0,0,1,1.92-1.29,11.588,11.588,0,0,1,2.79-1.11C402.25,284.21,403,285.51,404.79,285Z"
                        fill="#515151"
                    />
                    <path
                        id="Path_48"
                        data-name="Path 48"
                        d="M393.84,284.15a7.21,7.21,0,0,0,3.08,6.43c3.15,2.12,8.78-4.28,8-7.18a4.84,4.84,0,0,0-2.67-2.67c-.75-.3-1.16-.21-2.37-.72s-1.31-.79-1.77-.77-1.4.56-2.33,3a2,2,0,0,0-1.31-1.92,1.36,1.36,0,0,0-.7.08C392.73,280.83,392.68,282.35,393.84,284.15Z"
                        fill="#e3e2e2"
                    />
                    <path
                        id="Path_49"
                        data-name="Path 49"
                        d="M398.26,278.73c.06.22-.8.42-1.43,1.16a4.67,4.67,0,0,0,.34,5.71c.84.79,1.51.44,3.13,1.43a8.54,8.54,0,0,1,2.09,1.81,4.56,4.56,0,0,1-4.15,3.19c-1.79-.09-2.91-1.74-3.38-2.42a5.45,5.45,0,0,1-1.07-3.71c.26-1.84,1.57-2.06,1.4-3.58a9,9,0,0,1-.38-2.64,6.563,6.563,0,0,1,.13-.81C396.35,278.65,398.17,278.42,398.26,278.73Z"
                        fill="#515151"
                    />
                    <path
                        id="Path_50"
                        data-name="Path 50"
                        d="M400.84,288.61a4.64,4.64,0,0,1-2-1.09c-.05,0-.12-.08-.15,0a1.938,1.938,0,0,0,1.05,1.44c.57.23,1.28.06,1.31-.1S401,288.67,400.84,288.61Z"
                        fill="#e3e2e2"
                    />
                    <path
                        id="Path_51"
                        data-name="Path 51"
                        d="M371.59,337.21,367,379.27c-.16,1.45,1.87,3.61,3.33,3.54l67.77-4.16a2.38,2.38,0,0,0,2.23-2l8.5-40.62c.26-1.48-2.4-3-3.9-3l-71,2a2.38,2.38,0,0,0-2.34,2.18Z"
                        fill="#e3e2e2"
                    />
                    <path
                        id="Path_52"
                        data-name="Path 52"
                        d="M373.14,338.43l-4.62,42.06a2.38,2.38,0,0,0,2.47,2.64l68.63-3.25a2.4,2.4,0,0,0,2.23-2l7-40.81a2.38,2.38,0,0,0-2.41-2.78l-71,2a2.37,2.37,0,0,0-2.3,2.14Z"
                        fill="#fff"
                    />
                    <path
                        id="Path_53"
                        data-name="Path 53"
                        d="M413.43,370c-6-2.54-3.87-7.38-10.95-13.42-9.33-8-18.85-4.51-22.18-10.68-1-1.92-1.53-4.91.28-9.75l-5.15.15a2.37,2.37,0,0,0-2.29,2.11l-4.62,42.06a2.38,2.38,0,0,0,2.47,2.64l68.63-3.25a2.4,2.4,0,0,0,2.23-2l1-5.64a26.2,26.2,0,0,0-7.47-2.8C425.27,367.43,420.33,372.94,413.43,370Z"
                        fill="#f3f4f5"
                    />
                    <circle
                        id="Ellipse_1"
                        data-name="Ellipse 1"
                        cx="9.39"
                        cy="9.39"
                        r="9.39"
                        transform="translate(469.49 260.2)"
                        fill="#fff"
                        stroke="#e1e1e1"
                        strokeMiterlimit="10"
                        strokeWidth="2"
                    />
                    <circle
                        id="Ellipse_2"
                        data-name="Ellipse 2"
                        cx="6.04"
                        cy="6.04"
                        r="6.04"
                        transform="translate(457.41 204.8)"
                        fill="#fff"
                        stroke="#e1e1e1"
                        strokeMiterlimit="10"
                        strokeWidth="2"
                    />
                    <path
                        id="Path_54"
                        data-name="Path 54"
                        d="M96.61,159.59h-6.7V152.9a.83.83,0,0,0-.83-.83h0a.85.85,0,0,0-.85.83v6.69H81.55a.86.86,0,0,0-.85.85.85.85,0,0,0,.85.84h6.68V168a.85.85,0,0,0,.85.83.83.83,0,0,0,.83-.83h0v-6.7h6.7a.855.855,0,0,0,0-1.71Z"
                        fill="#e1e1e1"
                        stroke="#e1e1e1"
                        strokeMiterlimit="10"
                        strokeWidth="0.75"
                    />
                    <path
                        id="Path_55"
                        data-name="Path 55"
                        d="M47.48,256.62H38.19v-9.29A1.16,1.16,0,0,0,37,246.18h0a1.18,1.18,0,0,0-1.18,1.15v9.29H26.58a1.17,1.17,0,1,0,0,2.33h9.27v9.3A1.18,1.18,0,0,0,37,269.41a1.16,1.16,0,0,0,1.16-1.16h0V259h9.29a1.17,1.17,0,1,0,0-2.33Z"
                        fill="#e1e1e1"
                        stroke="#e1e1e1"
                        strokeMiterlimit="10"
                        strokeWidth="0.75"
                    />
                </g>
            </g>
        </SVGIcon>
    );
}
