import React from 'react';

import { SVGIcon } from '../../UIKit/index';

export default function DCMission(props) {
    return (
        <SVGIcon {...props} viewBox="0 0 512 512">
            <g>
                <path d="M507.3,486.2L396.5,285.6c-2.8-5.1-8.2-8.3-14.1-8.2c-5.9,0-11.3,3.4-13.9,8.6l-17.9,35.1  l84.1,149.5c6.7,11.9,7.9,26.2,3.2,39.1h55.8c5.6,0,10.8-3,13.7-7.8C509.9,497,510,491.2,507.3,486.2L507.3,486.2z" />
                <path d="M406.8,486L305.7,305.8l-40.4,34.3c-8.6,7.2-19.4,11.2-30.6,11.3c-11.7,0.1-23-4.2-31.7-12  c-8.7,7.8-20,12-31.7,11.9c-11.3,0-22.2-4-30.8-11.3l-38.5-32.7l-97.8,179c-2.7,4.9-2.6,10.8,0.2,15.6c2.9,4.8,8.1,7.7,13.7,7.8  h374.9c5.6,0,10.8-3,13.7-7.8C409.6,497,409.6,491,406.8,486z" />
                <path d="M324.5,108.4H203c-8.7,0-15.8-7.1-15.8-15.8V18.7c0-8.7,7.1-15.8,15.8-15.8h121.4  c8.8,0,15.8,7.1,15.8,15.9c0,3.8-1.4,7.4-3.8,10.3l-22.8,26.6l22.8,26.6c5.7,6.6,4.9,16.6-1.7,22.3  C331.9,107.1,328.2,108.4,324.5,108.4L324.5,108.4z" />
                <path d="M203,171.8c-8.7,0-15.8-7.1-15.8-15.8V18.7c0-8.7,7.1-15.8,15.8-15.8c8.7,0,15.8,7.1,15.8,15.8  v137.3C218.9,164.7,211.8,171.8,203,171.8z" />
                <path d="M289.8,277.8l-45.1,38c-6.2,5.4-15.5,5-21.3-0.8L203,294.5L182.5,315c-5.8,5.8-15.1,6.2-21.3,0.8  l-43.7-36.6l15.6-28.2l56-102.7c2.8-5.1,8.1-8.3,13.9-8.2c5.7-0.2,11.1,2.9,13.7,8L274,249.7L289.8,277.8z" />
            </g>
        </SVGIcon>
    );
}
