import React from 'react';
import WysiwygEditor from './wysiwygEditor';
import EditorContextProvider from './editorContext/Provider';

const Editor = props => {
    return (
        <EditorContextProvider>
            <WysiwygEditor {...props} />
        </EditorContextProvider>
    );
};

export default Editor;
