import React from 'react';

import { SVGIcon } from '../../UIKit/index';

export default function DCSign(props) {
    return (
        <SVGIcon {...props} viewBox="0 0 512 512">
            <g>
                <g>
                    <path
                        d="M497,274.2l-160.7,0c-34.9,0-63.2,28.4-63.2,63.2c0,34.8,28.4,63.2,63.2,63.2c18.3,0,33.2,14.9,33.2,33.2
			c0,18.3-14.9,33.2-33.2,33.2H15c-8.3,0-15,6.7-15,15s6.7,15,15,15h321.3c34.8,0,63.2-28.4,63.2-63.2c0-34.8-28.4-63.2-63.2-63.2
			c-18.3,0-33.2-14.9-33.2-33.2c0-18.3,14.9-33.2,33.2-33.2l160.7,0c8.3,0,15-6.7,15-15S505.3,274.2,497,274.2z"
                    />
                </g>
            </g>
            <g>
                <g>
                    <path
                        d="M70.1,146.2L3.5,226.5c-3.2,3.8-4.2,8.9-2.9,13.7l41,144.4l57.3-138.4c3.2-7.7,11.9-11.3,19.6-8.1
			c7.7,3.2,11.3,11.9,8.1,19.6L69.3,396.1L200.4,323c4.3-2.4,7.2-6.8,7.6-11.7l9.7-103.9L70.1,146.2z"
                    />
                </g>
            </g>
            <g>
                <g>
                    <path
                        d="M256.1,61.8L146,16.2c-3.7-1.5-7.8-1.5-11.5,0c-3.7,1.5-6.6,4.4-8.1,8.1l-39.9,96.2l137.8,57.1l39.9-96.2
			C267.3,73.7,263.7,64.9,256.1,61.8z"
                    />
                </g>
            </g>
        </SVGIcon>
    );
}
