import React from 'react';

import { SVGIcon } from '../../UIKit/index';

export default function DCJPEGFile(props) {
    return (
        <SVGIcon {...props} viewBox="0 0 512 512">
            <path
                d="m392 488h-272a48 48 0 0 1 -48-48v-368a48 48 0 0 1 48-48h224l96 96v320a48 48 0 0 1 -48 48z"
                fill="#005eff33"
            />
            <path
                d="m72 360h368a0 0 0 0 1 0 0v80a48 48 0 0 1 -48 48h-272a48 48 0 0 1 -48-48v-80a0 0 0 0 1 0 0z"
                fill="#005eff"
            />
            <path d="m440 120h-48a48 48 0 0 1 -48-48v-48z" fill="#005eff50" />
            <circle cx="216" cy="152" fill="#fff" r="16" />
            <path d="m264 296h-112l56-80z" fill="#005eff" />
            <g fill="#fff">
                <path d="m216 384h-16a8 8 0 0 0 -8 8v64a8 8 0 0 0 16 0v-24h8a24 24 0 0 0 0-48zm0 32h-8v-16h8a8 8 0 0 1 0 16z" />
                <path d="m168 384a8 8 0 0 0 -8 8v48a8 8 0 0 1 -16 0 8 8 0 0 0 -16 0 24 24 0 0 0 48 0v-48a8 8 0 0 0 -8-8z" />
                <path d="m296 400a8 8 0 0 0 0-16h-32a8 8 0 0 0 -8 8v64a8 8 0 0 0 8 8h32a8 8 0 0 0 0-16h-24v-16h8a8 8 0 0 0 0-16h-8v-16z" />
                <path d="m376 416h-16a8 8 0 0 0 0 16h7.049c-2.252 9.217-8.236 16-15.049 16-8.673 0-16-10.99-16-24s7.327-24 16-24a10.675 10.675 0 0 1 4.588 1.058 8 8 0 0 0 6.824-14.473 26.587 26.587 0 0 0 -11.412-2.585c-17.645 0-32 17.944-32 40s14.355 40 32 40 32-17.944 32-40a8 8 0 0 0 -8-8z" />
            </g>
            <path d="m360 296h-144l72-128z" fill="#005eff50" />
        </SVGIcon>
    );
}
