import React from 'react';

import { SVGIcon } from '../../UIKit/index';

export default function DCDeal(props) {
    return (
        <SVGIcon {...props} viewBox="0 0 512 512">
            <g transform="translate(0 -0.063)">
                <g transform="translate(4.312 0.063)">
                    <path
                        fill="#2F8DFF"
                        d="M419.11,332.12c0.01-3.87-1.06-7.67-3.09-10.96l-19.72-31.72c-2.45-4.88-3.72-10.27-3.71-15.74    v-97.76c0-63.45-51.44-114.89-114.89-114.89l0,0h-53.02c-63.45,0-114.89,51.44-114.89,114.89l0,0v97.76    c0.02,5.47-1.25,10.86-3.71,15.74l-19.8,31.72c-2.03,3.3-3.1,7.09-3.09,10.96l0,0c0.1,11.28,9.22,20.39,20.49,20.49h294.66    C409.73,352.66,419.01,343.5,419.11,332.12z"
                    />
                    <path
                        fill="#2F8DFF"
                        d="M277.72,61.07L321.91-0.8H180.5l44.19,61.87v17.67h53.02L277.72,61.07z"
                    />
                </g>
                <path
                    fill="#FFFFFF"
                    d="M299.7,152.7v-5.66c-0.05-16.38-13.31-29.65-29.7-29.7h-5.66V99.68h-17.67v17.67h-5.66   c-16.4-0.04-29.73,13.22-29.77,29.62c-0.03,12.43,7.69,23.57,19.34,27.91l16.09,6v33.67h-5.66c-6.64,0-12.03-5.39-12.03-12.03   c0,0,0,0,0,0l0,0v-5.66h-17.67v5.66c0.05,16.38,13.31,29.65,29.7,29.7H270c16.4,0.04,29.73-13.22,29.77-29.62   c0.03-12.43-7.69-23.57-19.34-27.91l-16.09-6v-33.67H270c6.64,0,12.03,5.39,12.03,12.03c0,0,0,0,0,0l0,0v5.66H299.7z    M274.25,191.23c4.65,1.84,7.72,6.31,7.77,11.31c0,6.64-5.39,12.03-12.03,12.03c0,0,0,0,0,0h-5.66v-27.05L274.25,191.23z    M246.68,162.06l-9.9-3.71c-4.65-1.84-7.72-6.31-7.77-11.31c0.01-6.64,5.39-12.01,12.03-12.01h5.66L246.68,162.06z"
                />
                <path
                    fill="#E6EFFF"
                    d="M508.06,307.61c-7.25-12.21-22.84-16.54-35.35-9.82l-125.4,53.55   c-2.21,0.94-4.59,1.42-6.99,1.41H184.81c-9.76,0-17.68-7.91-17.68-17.68c0-9.76,7.91-17.68,17.68-17.68l0,0h70.7   c29.28,0,53.02-23.74,53.02-53.02l0,0H161.47c-24.37,0.03-47.42,11.12-62.65,30.15l-55.33,67.08l62.4,114.36l240.13-57.71   c10.38-2.39,20.42-6.08,29.88-10.96l123.72-63.37C511.91,336.19,515.68,319.99,508.06,307.61z"
                />
                <path
                    fill="#2F8DFF"
                    d="M-0.79,352.8v159.08H114.1l26.52-26.54L34.57,326.26L-0.79,352.8z"
                />
                <circle fill="#FFFFFF" cx="78.75" cy="467.69" r="17.67" />
            </g>
        </SVGIcon>
    );
}
