import React from 'react';

import { SVGIcon } from '../../UIKit/index';

export default function DCInstaSitesMenu(props) {
    return (
        <SVGIcon {...props} viewBox="0 0 497 512">
            <g transform="translate(-15.5 -109.849)">
                <g transform="translate(15.5 109.849)">
                    <path
                        fill="#E6EFFF"
                        d="M465.63,107.27v282.47c0.77,26.07-19.66,47.87-45.73,48.8H54.04    c-26.07-0.93-46.49-22.73-45.73-48.8V107.27H465.63z M215.25,369.9L46.7,400.84l23.35-216.12v185.2L215.25,369.9z"
                    />
                    <path
                        fill="#2F8DFF"
                        d="M465.63,58.82v48.47H8.31V58.82c-0.77-26.07,19.66-47.87,45.73-48.8H419.9    C445.97,10.95,466.39,32.75,465.63,58.82z M190.23,58.82c-0.6-9.33-8.66-16.41-17.99-15.81c-9.33,0.6-16.41,8.66-15.81,17.99    c0.58,8.9,7.96,15.83,16.88,15.84c9.64-0.33,17.19-8.39,16.89-18.03l0,0H190.23z M132.48,58.82c-0.6-9.33-8.66-16.41-17.99-15.81    c-9.33,0.6-16.41,8.66-15.81,17.99c0.58,8.9,7.96,15.83,16.88,15.84C125.21,76.53,132.78,68.47,132.48,58.82L132.48,58.82z     M74.73,58.82c-0.6-9.33-8.66-16.41-17.99-15.81c-9.33,0.6-16.41,8.66-15.81,17.99c0.58,8.91,7.97,15.84,16.9,15.84    C67.47,76.52,75.03,68.46,74.73,58.82L74.73,58.82z"
                    />
                    <path
                        fill="#ffffff"
                        d="M191.53,58.82c0,9.96-8.07,18.03-18.03,18.03c-9.96,0-18.03-8.07-18.03-18.03    s8.07-18.03,18.03-18.03l0,0C183.46,40.79,191.53,48.86,191.53,58.82z"
                    />
                    <path
                        fill="#ffffff"
                        d="M115.94,40.79c9.96,0,18.03,8.07,18.03,18.03s-8.07,18.03-18.03,18.03s-18.03-8.07-18.03-18.03    S105.98,40.79,115.94,40.79L115.94,40.79z"
                    />
                    <path
                        fill="#FFFBF9"
                        d="M79.37,58.82c0,9.96-8.07,18.03-18.03,18.03s-18.03-8.07-18.03-18.03s8.07-18.03,18.03-18.03    S79.37,48.86,79.37,58.82L79.37,58.82z"
                    />
                    <g>
                        <path
                            fill="none"
                            d="M420.31,447H53.63c-30.66-1-54.73-26.6-53.85-57.26V58.8C-1.14,28.12,22.95,2.48,53.63,1.48     h366.69c30.68,1,54.77,26.64,53.85,57.32v330.94C475.05,420.4,450.97,446,420.31,447z M53.63,18.56     c-21.52,0.75-38.4,18.72-37.81,40.24v330.94c-0.6,21.53,16.28,39.51,37.81,40.26h366.69c21.53-0.75,38.41-18.73,37.81-40.26V58.8     c0.59-21.52-16.29-39.49-37.81-40.24H53.63z"
                        />
                        <path
                            fill="none"
                            d="M116.33,85.41c-14.67,0-26.57-11.89-26.57-26.57s11.89-26.57,26.57-26.57l0,0     c14.66,0.02,26.54,11.9,26.57,26.57l0,0C142.87,73.5,131,85.37,116.35,85.41l0,0H116.33z M116.33,49.33c-5.25,0-9.5,4.25-9.5,9.5     c0,5.25,4.25,9.5,9.5,9.5l0,0l0,0c5.25,0,9.5-4.25,9.5-9.5C125.83,53.58,121.58,49.33,116.33,49.33z"
                        />
                        <path
                            fill="none"
                            d="M61.34,85.39c-14.67,0-26.57-11.89-26.57-26.57s11.89-26.57,26.57-26.57     c14.67,0,26.57,11.89,26.57,26.57C87.88,73.48,76,85.36,61.34,85.39z M61.34,49.33c-5.24,0-9.49,4.25-9.49,9.49     s4.25,9.49,9.49,9.49s9.49-4.25,9.49-9.49v0C70.82,53.58,66.58,49.34,61.34,49.33z"
                        />
                        <path
                            fill="none"
                            d="M173.69,85.39c-14.67,0-26.57-11.89-26.57-26.57s11.89-26.57,26.57-26.57     c14.67,0,26.57,11.89,26.57,26.57c0,0,0,0,0,0C200.23,73.48,188.35,85.36,173.69,85.39z M173.69,49.33     c-5.24,0-9.49,4.25-9.49,9.49s4.25,9.49,9.49,9.49c5.24,0,9.49-4.25,9.49-9.49C183.17,53.58,178.93,49.34,173.69,49.33z"
                        />
                    </g>
                    <g id="Group_2118" transform="translate(3.344 8.469)">
                        <path
                            fill="#2F8DFF"
                            d="M208.11,308.24H73.94c-4.72,0-8.54-3.82-8.54-8.54s3.82-8.54,8.54-8.54h134.17     c4.72,0,8.54,3.82,8.54,8.54S212.83,308.24,208.11,308.24z"
                        />
                        <path
                            fill="#2F8DFF"
                            d="M208.11,369.97H73.94c-4.72,0-8.54-3.82-8.54-8.54c0-4.72,3.82-8.54,8.54-8.54h134.17     c4.72,0,8.54,3.82,8.54,8.54C216.65,366.15,212.83,369.97,208.11,369.97z"
                        />
                        <path
                            fill="#2F8DFF"
                            d="M208.11,184.77H73.94c-4.72,0-8.54-3.82-8.54-8.54c0-4.72,3.82-8.54,8.54-8.54h134.17     c4.72,0,8.54,3.82,8.54,8.54C216.65,180.95,212.83,184.77,208.11,184.77z"
                        />
                        <path
                            fill="#2F8DFF"
                            d="M208.11,246.5H73.94c-4.72,0-8.54-3.82-8.54-8.54c0-4.72,3.82-8.54,8.54-8.54h134.17     c4.72,0,8.54,3.82,8.54,8.54C216.65,242.68,212.83,246.5,208.11,246.5z"
                        />
                    </g>
                </g>
                <g>
                    <path
                        fill="#2F8DFF"
                        d="M413.52,296.55c61.89-0.52,112.48,49.22,113.01,111.11S477.3,520.14,415.42,520.66    s-112.48-49.22-113.01-111.11c0-0.31,0-0.63,0-0.94C302.14,346.98,351.89,296.81,413.52,296.55    C413.52,296.55,413.52,296.55,413.52,296.55z"
                    />
                    <text
                        style={{ fontSize: 226.888 }}
                        fill="#ffffff"
                        transform="matrix(1 0 0 1 345.293 485.7084)"
                    >
                        +
                    </text>
                </g>
            </g>
        </SVGIcon>
    );
}
