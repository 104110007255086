import React from 'react';

import { SVGIcon } from 'UIKit/index';

export default function DCExternalIcon(props) {
    return (
        <SVGIcon {...props} xmlns="http://www.w3.org/2000/svg" id="a" viewBox="0 0 11.94 11.94">
            <circle style={{ fill: '#558dca' }} cx="5.97" cy="5.97" r="5.97" />
            <g>
                <path
                    style={{ fill: '#fff' }}
                    d="M8.96,2.66h-2.11c-.17,0-.31,.14-.31,.31s.14,.31,.31,.31h1.4l-3,3c-.12,.12-.12,.31,0,.43,.06,.06,.14,.09,.22,.09s.16-.03,.22-.09l2.97-2.97v1.34c0,.17,.14,.31,.31,.31s.31-.14,.31-.31V2.96c0-.17-.14-.31-.31-.31h0Z"
                />
                <path
                    style={{ fill: '#fff' }}
                    d="M7.9,5.74c-.17,0-.31,.14-.31,.31v2.41c0,.11-.09,.2-.2,.2H3.49c-.11,0-.2-.09-.2-.2v-3.9c0-.11,.09-.2,.2-.2h2.42c.17,0,.31-.14,.31-.31s-.14-.31-.31-.31H3.49c-.45,0-.82,.37-.82,.82v3.9c0,.45,.37,.82,.82,.82h3.9c.45,0,.82-.37,.82-.82v-2.41c0-.17-.14-.31-.31-.31h0Z"
                />
            </g>
        </SVGIcon>
    );
}
