import { CallAPI } from 'Utils/apiCall';
const api = new CallAPI();
import config from 'config';

const mainUrl = `${config.REACT_APP_API_BASE_URL}/${process.env.REACT_APP_API_VERSION}`;
const fieldsUrl = `${mainUrl}/${process.env.REACT_APP_API_CRM}/${process.env.REACT_APP_API_FIELDS}`;

export const createFieldApi = (fieldType, body) => {
    const url = `${fieldsUrl}/${fieldType}`;
    return api.request('POST', url, body);
};

export const updateFieldApi = (fieldType, accessor, body) => {
    const url = `${fieldsUrl}/${fieldType}/${accessor}`;
    return api.request('PUT', url, body);
};

export const deleteFieldApi = (fieldType, accessor) => {
    const url = `${fieldsUrl}/${fieldType}/${accessor}`;
    return api.request('DELETE', url);
};
