import { reviewActions } from '../Actions';

const instaSiteReducer = (state, action) => {
    switch (action.type) {
        case reviewActions.SET_NEED_RESPONSE_COUNT:
            return { ...state, need_response_count: action.value };
        default:
            return;
    }
};

export default instaSiteReducer;
