import React from 'react';

import { SVGIcon } from '../../UIKit/index';

export default function DCTag(props) {
    return (
        <SVGIcon {...props} viewBox="0 0 512 512">
            <path d="M136.7,512.5h240.2c24.9,0,45-20.2,45-45V175c0.1-19.9-7.9-39-22-53.1L288.6,13   c-8.4-8.4-19.9-13.1-31.8-13h-90.1c-41.4,0-75,33.6-75.1,75c0,18.4,6.8,36.3,19.1,50c-12.3,13.7-19.1,31.6-19.1,50v292.4   C91.7,492.3,111.9,512.5,136.7,512.5z M378.8,143.2c8.5,8.4,13.2,19.9,13.2,31.8v292.4c0,8.3-6.8,15.1-15.1,15.1l0,0H136.7   c-8.3,0-15.1-6.8-15.1-15.1v0V175c0-11.9,4.7-23.4,13.2-31.8l0.2-0.2c9.9,4.7,20.8,7.1,31.7,7.1h47.6c-8.3,23.4,4,49.2,27.4,57.5   c23.4,8.3,49.2-4,57.5-27.4c8.3-23.4-4-49.2-27.4-57.5c-4.8-1.7-9.9-2.6-15-2.6h-90.1c-2.5,0-5.1-0.2-7.6-0.7l87.1-85.2   c6-5.6,15.3-5.6,21.2,0L378.8,143.2z M271.8,165.2c0,8.3-6.8,15.1-15.1,15.1c-8.3,0-15.1-6.8-15.1-15.1c0-8.3,6.8-15.1,15.1-15.1   C265.1,150.1,271.8,156.8,271.8,165.2C271.8,165.2,271.8,165.2,271.8,165.2z M166.8,30h40.8l-75.5,73.8   c-15.9-19.1-13.3-47.5,5.8-63.4C146,33.7,156.2,30,166.8,30L166.8,30z" />
            <path d="M316.9,391.4h-120c-8.3,0.9-14.3,8.3-13.4,16.6c0.8,7.1,6.3,12.7,13.4,13.4H317   c8.3,0.9,15.7-5.1,16.6-13.4s-5.1-15.7-13.4-16.6C319.1,391.3,318.1,391.3,316.9,391.4L316.9,391.4z" />
            <path d="M181.8,346.4c0,8.3,6.8,15.1,15.1,15.1H317c8.3,0.9,15.7-5.1,16.6-13.4   c0.9-8.3-5.1-15.7-13.4-16.6c-1.1-0.1-2.1-0.1-3.2,0H196.9C188.6,331.5,181.9,338.1,181.8,346.4z" />
        </SVGIcon>
    );
}
