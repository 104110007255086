import React from 'react';

import { SVGIcon } from '../../UIKit/index';

export default function DCDoc1(props) {
    return (
        <SVGIcon {...props} viewBox="0 0 512 512">
            <g>
                <path d="M442.3,129.4L326.6,13.7c-2.7-2.7-6.4-4.2-10.2-4.2H114.1c-23.9,0-43.3,19.4-43.4,43.4v406.6   c0,23.9,19.4,43.3,43.4,43.4h289.3c23.9,0,43.3-19.4,43.4-43.4V139.6C446.7,135.7,445.1,132,442.3,129.4z M330.9,58.8l66.1,66.3   h-51.8c-8,0-14.5-6.5-14.5-14.5L330.9,58.8z M403.4,473.9H114.1c-8,0-14.5-6.5-14.5-14.5c0,0,0,0,0,0V52.8c0-8,6.5-14.5,14.5-14.5   l0,0H302v72.3c0,23.9,19.4,43.3,43.4,43.4h72.3v305.4C417.6,467.4,411.3,473.8,403.4,473.9z" />
                <path d="M345.3,242.7H200.8c-8,0-14.4,6.5-14.4,14.5c0,7.9,6.5,14.4,14.4,14.4h144.5   c8,0,14.4-6.5,14.4-14.5C359.6,249.1,353.2,242.7,345.3,242.7z" />
                <path d="M342,300.5H161.9c-8-1.7-15.8,3.4-17.5,11.4c-1.7,8,3.4,15.8,11.4,17.5c2,0.4,4.1,0.4,6.1,0H342   c8,1.7,15.8-3.4,17.5-11.4c1.7-8-3.4-15.8-11.4-17.5C346.1,300.1,344,300.1,342,300.5z" />
                <path d="M345.3,358.2H200.8c-8,0-14.5,6.4-14.5,14.4s6.4,14.5,14.4,14.5c0,0,0.1,0,0.1,0h144.5   c8,0,14.5-6.4,14.5-14.4c0-8-6.4-14.5-14.4-14.5C345.4,358.2,345.3,358.2,345.3,358.2z" />
            </g>
        </SVGIcon>
    );
}
