import React from 'react';

import { SVGIcon } from '../../UIKit/index';

export default function DCArrowNext(props) {
    return (
        <SVGIcon {...props} viewBox="0 0 442 482">
            <path d="M373.4,270.2c0,0,11-13.9,1.8-25.6L186.5,54.2c0,0-10.8-8.8-21.1-5l-5.3,1.8L136,67.9c0,0-4.5,4.6-2.3,9.5  L315,254L136.9,429.1c0,0-2.6,4.4,0,6.5l21.3,24.3c0,0,12.8,8.6,24.8,0L373.4,270.2z" />
        </SVGIcon>
    );
}
