import React from 'react';

import { Div } from '../../UIKit/index';
import PropTypes from 'prop-types';
import './style.scss';

const PageContainer = ({ classes, children, style = {} }) => (
    <Div
        style={{ ...style }}
        className={`pageContainer ${classes?.container ? classes.container : ''}`}
    >
        <Div className={`pageContainerRow ${classes?.row ? classes.row : ''}`}>{children}</Div>
    </Div>
);

PageContainer.propTypes = {
    classes: PropTypes.object,
    children: PropTypes.element,
};

export default PageContainer;
