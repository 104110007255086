import React from 'react';

import { SVGIcon } from '../../UIKit/index';

export default function DCUserStarOutline(props) {
    return (
        <SVGIcon {...props} viewBox="-18 -26 591.99999 591">
            <g>
                <path d="m544.503906 360.820312-84.210937-12.246093-37.609375-76.214844c-2.105469-4.285156-6.46875-7-11.242188-7-4.773437 0-9.136718 2.714844-11.246094 7l-37.609374 76.214844-7.867188 1.125c-38.820312-36.199219-89.988281-56.222657-143.0625-55.976563h-.375c-57.222656.125-110.324219 22.367188-149.683594 62.847656-39.855468 40.980469-61.71875 96.832032-61.597656 157.429688.0234375 3.3125 1.324219 6.488281 3.625 8.871094 2.347656 2.347656 5.546875 3.65625 8.871094 3.621094l305.484375-.625c-.628907 4.539062 1.292969 9.054687 5 11.75 3.847656 2.738281 8.894531 3.121093 13.117187.992187l75.214844-39.605469 75.21875 39.605469c4.257812 2.144531 9.351562 1.765625 13.242188-.992187 3.859374-2.804688 5.796874-7.546876 5-12.246094l-14.371094-83.839844 60.847656-59.347656c3.402344-3.347656 4.613281-8.332032 3.125-12.871094-1.402344-4.480469-5.230469-7.773438-9.871094-8.492188zm-222.273437 140.058594-296.992188.625c2.625-49.105468 21.617188-93.957031 54.226563-127.316406 34.609375-35.609375 81.464844-55.222656 131.816406-55.222656h.375c39.484375-.136719 77.972656 12.386718 109.824219 35.730468l-43.105469 6.246094c-4.71875.664063-8.648438 3.964844-10.121094 8.5-1.46875 4.53125-.265625 9.511719 3.121094 12.867188l60.851562 59.347656zm146.058593-72.714844c-2.957031 2.886719-4.308593 7.042969-3.621093 11.117188l11.246093 65.347656-58.726562-30.863281c-1.8125-.933594-3.828125-1.410156-5.875-1.375-2.035156.023437-4.035156.492187-5.871094 1.375l-58.722656 30.863281 11.246094-65.347656c.726562-4.074219-.632813-8.253906-3.621094-11.117188l-47.480469-46.230468 65.59375-9.496094c4.082031-.5625 7.605469-3.144531 9.371094-6.871094l29.363281-59.472656 29.359375 59.472656c1.824219 3.679688 5.316407 6.242188 9.371094 6.871094l65.597656 9.496094zm0 0" />
                <path d="m211.53125 264.113281c72.933594 0 132.0625-59.128906 132.0625-132.066406 0-72.933594-59.128906-132.0625-132.0625-132.0625-72.9375 0-132.066406 59.128906-132.066406 132.0625.070312 72.910156 59.15625 131.996094 132.066406 132.066406zm0-239.140625c59.136719 0 107.074219 47.9375 107.074219 107.074219 0 59.140625-47.9375 107.074219-107.074219 107.074219-59.140625 0-107.074219-47.933594-107.074219-107.074219.066407-59.109375 47.964844-107.007813 107.074219-107.074219zm0 0" />
            </g>
        </SVGIcon>
    );
}
