import { useCallback } from 'react';

import usePubSub from '../PubSub';

export default function useErrorContainer() {
    const { publish } = usePubSub();

    const showErrorDialog = useCallback(
        error => {
            if (['Session Expired. Please login again.', 'INVALID_ACCESS_TOKEN'].includes(error)) {
                publish('timeout_error');
            } else if (error === 'DOWNGRADE_IN_PROGRESS') {
                publish('fatal_error', {
                    message: 'Subscription downgrade in progress',
                    subTitle: 'Please wait, we are processing downgrade',
                });
            } else {
                publish('fatal_error');
            }
        },
        [publish],
    );

    return showErrorDialog;
}
// import useErrorDialog from "../../Hooks/ErrorDialog/useErrorDialog";

// const showErrorDialog = useErrorDialog();

// showErrorDialog();
