import React from 'react';

import { SVGIcon } from '../../UIKit/index';

export default function DCCSVFile(props) {
    return (
        <SVGIcon {...props} viewBox="0 0 512 512">
            <path
                d="m392 488h-272a48 48 0 0 1 -48-48v-368a48 48 0 0 1 48-48h224l96 96v320a48 48 0 0 1 -48 48z"
                fill="#cfd2fc"
            />
            <path
                d="m72 360h368a0 0 0 0 1 0 0v80a48 48 0 0 1 -48 48h-272a48 48 0 0 1 -48-48v-80a0 0 0 0 1 0 0z"
                fill="#5153ff"
            />
            <path d="m440 120h-48a48 48 0 0 1 -48-48v-48z" fill="#8690fa" />
            <path
                d="m352 224a8 8 0 0 0 0-16h-24v-32h24a8 8 0 0 0 0-16h-24v-24a8 8 0 0 0 -16 0v24h-48v-24a8 8 0 0 0 -16 0v24h-48v-24a8 8 0 0 0 -16 0v24h-24a8 8 0 0 0 0 16h24v32h-24a8 8 0 0 0 0 16h24v32h-24a8 8 0 0 0 0 16h24v24a8 8 0 0 0 16 0v-24h48v24a8 8 0 0 0 16 0v-24h48v24a8 8 0 0 0 16 0v-24h24a8 8 0 0 0 0-16h-24v-32zm-40-48v32h-48v-32zm-112 0h48v32h-48zm0 80v-32h48v32zm112 0h-48v-32h48z"
                fill="#8690fa"
            />
            <g fill="#fff">
                <path d="m256 400h16a8 8 0 0 0 0-16h-16a24 24 0 0 0 0 48 8 8 0 0 1 0 16h-16a8 8 0 0 0 0 16h16a24 24 0 0 0 0-48 8 8 0 0 1 0-16z" />
                <path d="m337.94 384.239a8 8 0 0 0 -9.7 5.821l-8.24 32.956-8.239-32.956a8 8 0 1 0 -15.522 3.88l16 64a8 8 0 0 0 15.522 0l16-64a8 8 0 0 0 -5.821-9.701z" />
                <path d="m200 400a10.675 10.675 0 0 1 4.588 1.058 8 8 0 0 0 6.824-14.473 26.587 26.587 0 0 0 -11.412-2.585c-17.645 0-32 17.944-32 40s14.355 40 32 40a26.587 26.587 0 0 0 11.412-2.585 8 8 0 0 0 -6.824-14.473 10.675 10.675 0 0 1 -4.588 1.058c-8.673 0-16-10.99-16-24s7.327-24 16-24z" />
            </g>
        </SVGIcon>
    );
}
