import React from 'react';

import { SVGIcon } from '../../UIKit/index';

export default function DCAutoCreateDeals(props) {
    return (
        <SVGIcon {...props} viewBox="0 0 450 492.99">
            <defs>
                <clipPath id="clip-path-auto-deals">
                    <circle
                        id="Ellipse_18"
                        data-name="Ellipse 18"
                        cx="116.62"
                        cy="116.62"
                        r="116.62"
                        transform="translate(90.424 233.5) rotate(-45)"
                        fill="none"
                    />
                </clipPath>
            </defs>
            <g id="Auto_Create_Deals" transform="translate(-30.35 -7.47)">
                <ellipse
                    id="_Path_"
                    data-name="&lt;Path&gt;"
                    cx="168.48"
                    cy="16.54"
                    rx="168.48"
                    ry="16.54"
                    transform="translate(90.67 467.38)"
                    fill="#f3f4f5"
                />
                <circle
                    id="Ellipse_1"
                    data-name="Ellipse 1"
                    cx="137.2"
                    cy="137.2"
                    r="137.2"
                    transform="translate(118.15 96.3)"
                    fill="none"
                    stroke="#e3e2e2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="5"
                    strokeDasharray="11.97 11.97"
                />
                <path
                    id="Path_1"
                    data-name="Path 1"
                    d="M205.39,272.52l-8.95-8.94-8.79,4.31a54.329,54.329,0,0,0-7.38-4.24l-.65-9.78-12.19-3.26L162,258.74h.05c-1.44-.13-2.88-.19-4.31-.18s-2.89.06-4.32.17h0L148,250.61l-12.2,3.26-.66,9.79a52.343,52.343,0,0,0-7.37,4.24L119,263.59l-8.93,8.93,4.31,8.79a55.191,55.191,0,0,0-4.26,7.39l-9.76.64-3.27,12.2,8.12,5.45c-.08,1.4-.16,2.84-.14,4.26s.06,2.89.18,4.32v-.05L97.07,321l3.26,12.2,9.77.66a55.079,55.079,0,0,0,4.26,7.38L110.05,350l8.93,8.94,8.78-4.31a55.794,55.794,0,0,0,7.38,4.25l.66,9.77L148,371.9l5.44-8.13c1.42.09,2.85.16,4.28.15s2.87-.06,4.3-.16l5.4,8.14,12.21-3.26.65-9.76a55.563,55.563,0,0,0,7.37-4.28l8.79,4.32,8.94-8.94-4.32-8.79a53.361,53.361,0,0,0,4.25-7.38l9.78-.64L218.36,321l-8.13-5.44h0c.11-1.43.2-2.87.18-4.32s-.07-2.85-.15-4.25l8.12-5.45-3.26-12.2-9.79-.66a50.907,50.907,0,0,0-4.25-7.37Zm-20,66.38a27.65,27.65,0,1,0-.03,0Z"
                    fill="#e3e2e2"
                />
                <path
                    id="Path_2"
                    data-name="Path 2"
                    d="M366.74,97.56l-7.33-7.33-7.19,3.53a44.54,44.54,0,0,0-6.05-3.47l-.53-8-10-2.67-4.47,6.66h0a34.728,34.728,0,0,0-3.53-.15,29.934,29.934,0,0,0-3.54.14h.05l-4.47-6.66-10,2.67-.53,8a41.479,41.479,0,0,0-6,3.47L296,90.24l-7.32,7.32,3.54,7.19a44.792,44.792,0,0,0-3.49,6.06l-8,.52-2.68,10,6.66,4.46c-.07,1.15-.13,2.33-.12,3.49a30.037,30.037,0,0,0,.15,3.54h0L278,137.24l2.67,10,8,.54a45.194,45.194,0,0,0,3.49,6l-3.54,7.2,7.32,7.32,7.2-3.54a44.742,44.742,0,0,0,6,3.49l.54,8,10,2.69,4.46-6.67c1.16.08,2.33.13,3.5.12a34.406,34.406,0,0,0,3.52-.13l4.43,6.68,10-2.68.54-8a46.377,46.377,0,0,0,6-3.5l7.19,3.54,7.33-7.32-3.54-7.21a43.472,43.472,0,0,0,3.48-6l8-.53,2.68-10-6.66-4.46h0c.09-1.17.16-2.35.14-3.54s-.05-2.34-.12-3.49l6.66-4.46-2.68-10-8-.54a42.091,42.091,0,0,0-3.48-6ZM345,146.63a24.53,24.53,0,1,1,0-34.69,24.53,24.53,0,0,1,0,34.69Z"
                    fill="#e3e2e2"
                />
                <rect
                    id="Rectangle_1"
                    data-name="Rectangle 1"
                    width="83.71"
                    height="48.67"
                    transform="matrix(0.794, 0.608, -0.608, 0.794, 316.159, 291.861)"
                    fill="#f3f4f5"
                />
                <path
                    id="Path_3"
                    data-name="Path 3"
                    d="M351.72,383.11l-66.49-50.86a2.17,2.17,0,0,1-.41-3.05l29.57-38.66a2.19,2.19,0,0,1,3.06-.41L383.93,341a2.18,2.18,0,0,1,.41,3.06L354.77,382.7a2.17,2.17,0,0,1-3.05.41Zm-62.12-53,63,48.22,26.93-35.2-63-48.21Z"
                    fill="#727373"
                />
                <path
                    id="Path_4"
                    data-name="Path 4"
                    d="M317.63,323.65a4.67,4.67,0,1,1,.918-3.457,4.68,4.68,0,0,1-.918,3.457Z"
                    fill="#727373"
                />
                <circle
                    id="Ellipse_2"
                    data-name="Ellipse 2"
                    cx="4.67"
                    cy="4.67"
                    r="4.67"
                    transform="translate(350.119 356.489) rotate(-81.54)"
                    fill="#727373"
                />
                <path
                    id="Path_5"
                    data-name="Path 5"
                    d="M320.65,299.32a5.23,5.23,0,1,1-1-7.33A5.23,5.23,0,0,1,320.65,299.32Z"
                    fill="#727373"
                />
                <path
                    id="Path_6"
                    data-name="Path 6"
                    d="M295,333.18a5.23,5.23,0,1,1-1-7.33A5.23,5.23,0,0,1,295,333.18Z"
                    fill="#727373"
                />
                <path
                    id="Path_7"
                    data-name="Path 7"
                    d="M382.19,346.39a5.23,5.23,0,1,1-1-7.32,5.23,5.23,0,0,1,1,7.32Z"
                    fill="#727373"
                />
                <path
                    id="Path_8"
                    data-name="Path 8"
                    d="M356.56,380.26a5.23,5.23,0,1,1-1-7.33,5.23,5.23,0,0,1,1,7.33Z"
                    fill="#727373"
                />
                <circle
                    id="Ellipse_3"
                    data-name="Ellipse 3"
                    cx="12.04"
                    cy="12.04"
                    r="12.04"
                    transform="matrix(0.991, -0.131, 0.131, 0.991, 320.799, 326.009)"
                    fill="#727373"
                />
                <path
                    id="Path_9"
                    data-name="Path 9"
                    d="M338,336.76l-.42-.81a3.37,3.37,0,0,0-.82-1.12.87.87,0,0,0-1.38.13c-.22.29-.19.77.07,1.46a18.185,18.185,0,0,1,.71,2,2.57,2.57,0,0,1-.45,2.14q-1.5,1.95-4.39.76l-.91,1.31h-.23a1.84,1.84,0,0,1-.31-.23l-.2-.2-.3-.18-.28-.18a.469.469,0,0,1-.1-.1,1.059,1.059,0,0,1,.23-.39c.12-.15.31-.38.58-.69-1.29-1-1.88-1.57-1.76-1.77l.11-.13a.789.789,0,0,0,.12-.14,5.782,5.782,0,0,0,.48-.73,4.454,4.454,0,0,0,.24-.53,1.889,1.889,0,0,1,.26-.52.18.18,0,0,1,.28,0,4.442,4.442,0,0,1,.56.6,4.238,4.238,0,0,0,.63.64c.84.65,1.46.71,1.86.19.21-.28.21-.77,0-1.48a18.837,18.837,0,0,1-.59-2,2.64,2.64,0,0,1,.45-2.11,3,3,0,0,1,2-1.16,3.75,3.75,0,0,1,2.3.36l.25-.36a4.116,4.116,0,0,1,.37-.48c.15-.15.25-.19.33-.14l1.24.95q.08.06-.06.3a3.541,3.541,0,0,1-.33.44l-.27.32a8.672,8.672,0,0,1,1.11,1.37c.55.77.76,1.24.63,1.4a.68.68,0,0,1-.19.16c-.17.09-.43.24-.77.46-.16.13-.4.33-.73.58C338.15,336.81,338.05,336.83,338,336.76Z"
                    fill="#f3f4f5"
                />
                <rect
                    id="Rectangle_2"
                    data-name="Rectangle 2"
                    width="83.551"
                    height="48.577"
                    transform="translate(333.026 283.912) rotate(37.674)"
                    fill="#f3f4f5"
                />
                <path
                    id="Path_10"
                    data-name="Path 10"
                    d="M370.69,375.7,304.2,324.84a2.18,2.18,0,0,1-.4-3l29.57-38.66a2.18,2.18,0,0,1,3.05-.4l66.49,50.86a2.18,2.18,0,0,1,.4,3.05l-29.57,38.65a2.17,2.17,0,0,1-3.05.36Zm-62.11-53,63,48.21,26.92-35.2-63-48.21Z"
                    fill="#727373"
                />
                <path
                    id="Path_11"
                    data-name="Path 11"
                    d="M336.6,316.24a4.67,4.67,0,1,1-.87-6.54A4.67,4.67,0,0,1,336.6,316.24Z"
                    fill="#727373"
                />
                <path
                    id="Path_12"
                    data-name="Path 12"
                    d="M378.11,348a4.66,4.66,0,1,1,.927-3.456A4.67,4.67,0,0,1,378.11,348Z"
                    fill="#727373"
                />
                <circle
                    id="Ellipse_4"
                    data-name="Ellipse 4"
                    cx="5.23"
                    cy="5.23"
                    r="5.23"
                    transform="translate(328.089 288.406) rotate(-42.17)"
                    fill="#727373"
                />
                <path
                    id="Path_13"
                    data-name="Path 13"
                    d="M314,325.77a5.23,5.23,0,1,1-1-7.32A5.23,5.23,0,0,1,314,325.77Z"
                    fill="#727373"
                />
                <path
                    id="Path_14"
                    data-name="Path 14"
                    d="M401.16,339a5.23,5.23,0,1,1-1-7.33A5.23,5.23,0,0,1,401.16,339Z"
                    fill="#727373"
                />
                <path
                    id="Path_15"
                    data-name="Path 15"
                    d="M375.53,372.85a5.23,5.23,0,1,1-1-7.33,5.23,5.23,0,0,1,1,7.33Z"
                    fill="#727373"
                />
                <circle
                    id="Ellipse_5"
                    data-name="Ellipse 5"
                    cx="12.04"
                    cy="12.04"
                    r="12.04"
                    transform="matrix(0.6, -0.8, 0.8, 0.6, 336.419, 331.426)"
                    fill="#727373"
                />
                <path
                    id="Path_16"
                    data-name="Path 16"
                    d="M357,329.36s-.15-.28-.42-.82a3.37,3.37,0,0,0-.83-1.12.86.86,0,0,0-1.37.14c-.22.28-.2.77.07,1.46a15.9,15.9,0,0,1,.7,2,2.53,2.53,0,0,1-.45,2.15q-1.48,1.94-4.39.75c-.54.79-.85,1.23-.91,1.31s-.11,0-.22,0a1.512,1.512,0,0,1-.31-.23l-.21-.19a2.954,2.954,0,0,0-.29-.19,1.5,1.5,0,0,1-.29-.18.28.28,0,0,1-.09-.1.93.93,0,0,1,.23-.39c.11-.15.31-.38.57-.69-1.29-1-1.87-1.57-1.75-1.76a.4.4,0,0,1,.11-.13l.11-.14a7.74,7.74,0,0,0,.48-.73,3,3,0,0,0,.24-.54,2.711,2.711,0,0,1,.26-.52.17.17,0,0,1,.28,0,3.671,3.671,0,0,1,.56.59,3.8,3.8,0,0,0,.63.64c.85.65,1.47.71,1.87.19.21-.27.2-.77,0-1.48q-.56-1.74-.6-2a2.66,2.66,0,0,1,.46-2.11,3,3,0,0,1,2-1.15,3.78,3.78,0,0,1,2.31.35,2.713,2.713,0,0,1,.24-.36,3.151,3.151,0,0,1,.38-.47c.14-.15.25-.2.32-.14l1.24.94q.09.07-.06.3a2.259,2.259,0,0,1-.33.45l-.26.32a8.549,8.549,0,0,1,1.11,1.36c.54.78.75,1.24.63,1.41l-.19.15c-.18.09-.44.25-.77.46-.16.14-.41.33-.74.58C357.13,329.41,357,329.42,357,329.36Z"
                    fill="#f3f4f5"
                />
                <rect
                    id="Rectangle_3"
                    data-name="Rectangle 3"
                    width="83.71"
                    height="48.67"
                    transform="matrix(0.794, 0.608, -0.608, 0.794, 332.439, 266.715)"
                    fill="#f3f4f5"
                />
                <path
                    id="Path_17"
                    data-name="Path 17"
                    d="M368,358l-66.49-50.86a2.17,2.17,0,0,1-.41-3.05l29.57-38.66a2.18,2.18,0,0,1,3.06-.41l66.48,50.86a2.17,2.17,0,0,1,.41,3l-29.57,38.66A2.18,2.18,0,0,1,368,358Zm-62.12-53,63,48.22L395.84,318l-63-48.22Z"
                    fill="#727373"
                />
                <path
                    id="Path_18"
                    data-name="Path 18"
                    d="M333.91,298.51A4.68,4.68,0,1,1,333,292a4.68,4.68,0,0,1,.91,6.51Z"
                    fill="#727373"
                />
                <path
                    id="Path_19"
                    data-name="Path 19"
                    d="M375.42,330.27a4.67,4.67,0,1,1-.87-6.55,4.67,4.67,0,0,1,.87,6.55Z"
                    fill="#727373"
                />
                <path
                    id="Path_20"
                    data-name="Path 20"
                    d="M336.93,274.18a5.23,5.23,0,1,1-1-7.33A5.23,5.23,0,0,1,336.93,274.18Z"
                    fill="#727373"
                />
                <circle
                    id="Ellipse_6"
                    data-name="Ellipse 6"
                    cx="5.23"
                    cy="5.23"
                    r="5.23"
                    transform="translate(300.391 307.864) rotate(-69.11)"
                    fill="#727373"
                />
                <path
                    id="Path_21"
                    data-name="Path 21"
                    d="M398.47,321.25a5.23,5.23,0,1,1-1-7.33,5.23,5.23,0,0,1,1,7.33Z"
                    fill="#727373"
                />
                <circle
                    id="Ellipse_7"
                    data-name="Ellipse 7"
                    cx="5.23"
                    cy="5.23"
                    r="5.23"
                    transform="translate(361.93 354.937) rotate(-69.11)"
                    fill="#727373"
                />
                <circle
                    id="Ellipse_8"
                    data-name="Ellipse 8"
                    cx="12.04"
                    cy="12.04"
                    r="12.04"
                    transform="matrix(0.195, -0.981, 0.981, 0.195, 336.436, 320.748)"
                    fill="#727373"
                />
                <path
                    id="Path_22"
                    data-name="Path 22"
                    d="M354.25,311.62l-.42-.81a3.26,3.26,0,0,0-.82-1.12.87.87,0,0,0-1.38.13c-.22.29-.19.77.07,1.46a18.185,18.185,0,0,1,.71,2,2.57,2.57,0,0,1-.45,2.14q-1.5,1.95-4.39.75l-.91,1.31c0,.05-.12,0-.23,0a2,2,0,0,1-.31-.24l-.2-.19a2.114,2.114,0,0,0-.3-.18l-.28-.18a.311.311,0,0,1-.09-.11,1,1,0,0,1,.22-.38c.12-.16.31-.39.58-.69-1.29-1-1.88-1.58-1.76-1.77a.579.579,0,0,1,.11-.13.788.788,0,0,0,.12-.14,5.779,5.779,0,0,0,.48-.73c.05-.09.13-.27.24-.53a1.889,1.889,0,0,1,.26-.52c.08-.11.17-.11.28,0a4.182,4.182,0,0,1,.56.59,4,4,0,0,0,.63.65c.84.65,1.46.71,1.87.18q.3-.4,0-1.47a18,18,0,0,1-.59-2.05,2.62,2.62,0,0,1,.45-2.1,3,3,0,0,1,2-1.16,3.68,3.68,0,0,1,2.3.36l.25-.36a3.391,3.391,0,0,1,.37-.48c.15-.15.25-.19.33-.14l1.24.95a.949.949,0,0,1-.06.3,4.5,4.5,0,0,1-.33.44,2.914,2.914,0,0,1-.27.32,8.211,8.211,0,0,1,1.11,1.37c.55.77.76,1.24.63,1.4a.68.68,0,0,1-.19.16c-.17.09-.43.24-.77.45-.16.14-.4.34-.73.59A.462.462,0,0,1,354.25,311.62Z"
                    fill="#f3f4f5"
                />
                <rect
                    id="Rectangle_4"
                    data-name="Rectangle 4"
                    width="83.71"
                    height="48.67"
                    transform="matrix(0.588, 0.809, -0.809, 0.588, 188.76, 80.22)"
                    fill="#f3f4f5"
                />
                <path
                    id="Path_23"
                    data-name="Path 23"
                    d="M196.85,177.83l-49.22-67.71a2.18,2.18,0,0,1,.48-3L187.48,78.5a2.18,2.18,0,0,1,3,.48l49.22,67.7a2.19,2.19,0,0,1-.48,3.05L199.9,178.31a2.2,2.2,0,0,1-3.05-.48Zm-44.42-68.51L199.1,173.5l35.84-26.06L188.28,83.26Z"
                    fill="#727373"
                />
                <path
                    id="Path_24"
                    data-name="Path 24"
                    d="M181.13,111.12a4.67,4.67,0,1,1,1-6.53,4.67,4.67,0,0,1-1,6.53Z"
                    fill="#727373"
                />
                <circle
                    id="Ellipse_9"
                    data-name="Ellipse 9"
                    cx="4.67"
                    cy="4.67"
                    r="4.67"
                    transform="translate(202.919 151.873) rotate(-64.97)"
                    fill="#727373"
                />
                <path
                    id="Path_25"
                    data-name="Path 25"
                    d="M191,88.66a5.22,5.22,0,1,1,2.089-3.411A5.23,5.23,0,0,1,191,88.66Z"
                    fill="#727373"
                />
                <path
                    id="Path_26"
                    data-name="Path 26"
                    d="M156.75,113.81a5.23,5.23,0,1,1,1.15-7.31,5.23,5.23,0,0,1-1.15,7.31Z"
                    fill="#727373"
                />
                <path
                    id="Path_27"
                    data-name="Path 27"
                    d="M236.53,151.32a5.23,5.23,0,1,1,1.16-7.3,5.23,5.23,0,0,1-1.16,7.3Z"
                    fill="#727373"
                />
                <path
                    id="Path_28"
                    data-name="Path 28"
                    d="M202.31,176.47a5.23,5.23,0,1,1,1.15-7.3,5.23,5.23,0,0,1-1.15,7.3Z"
                    fill="#727373"
                />
                <circle
                    id="Ellipse_10"
                    data-name="Ellipse 10"
                    cx="12.04"
                    cy="12.04"
                    r="12.04"
                    transform="translate(179.681 138.079) rotate(-80.94)"
                    fill="#727373"
                />
                <path
                    id="Path_29"
                    data-name="Path 29"
                    d="M196.89,129.48s-.06-.3-.16-.89a3.48,3.48,0,0,0-.47-1.31.87.87,0,0,0-1.36-.27c-.29.21-.41.69-.35,1.42.09,1.17.14,1.86.12,2.08a2.55,2.55,0,0,1-1,1.92c-1.32,1-2.79.79-4.42-.53-.75.61-1.16.94-1.25,1s-.11,0-.2-.1a2.06,2.06,0,0,1-.23-.31l-.14-.24a2.262,2.262,0,0,0-.23-.26,1.771,1.771,0,0,1-.22-.26.34.34,0,0,1-.06-.12,1,1,0,0,1,.33-.31l.74-.5c-.95-1.31-1.34-2-1.18-2.19a.49.49,0,0,1,.15-.09l.15-.1a7.09,7.09,0,0,0,.67-.57c.07-.07.2-.22.38-.44a2.16,2.16,0,0,1,.4-.43.18.18,0,0,1,.27.06,4.181,4.181,0,0,1,.37.73,3.78,3.78,0,0,0,.42.8c.62.86,1.2,1.09,1.74.71.27-.21.41-.68.4-1.43a20.2,20.2,0,0,1,0-2.12,2.6,2.6,0,0,1,1-1.89,3,3,0,0,1,2.24-.54,3.69,3.69,0,0,1,2.1,1l.34-.28a3.639,3.639,0,0,1,.5-.35c.18-.1.29-.12.35,0l.92,1.26q.06.09-.15.27a2.9,2.9,0,0,1-.44.33,2.13,2.13,0,0,1-.35.23,8.619,8.619,0,0,1,.68,1.63c.3.9.37,1.4.2,1.52a.771.771,0,0,1-.22.1,4.184,4.184,0,0,0-.87.22l-.87.35a.23.23,0,0,1-.3-.1Z"
                    fill="#f3f4f5"
                />
                <rect
                    id="Rectangle_5"
                    data-name="Rectangle 5"
                    width="83.71"
                    height="48.67"
                    transform="matrix(0.588, 0.809, -0.809, 0.588, 209.055, 78.535)"
                    fill="#f3f4f5"
                />
                <path
                    id="Path_30"
                    data-name="Path 30"
                    d="M217.15,176.14l-49.22-67.71a2.18,2.18,0,0,1,.48-3l39.36-28.62a2.19,2.19,0,0,1,3,.48L260,145a2.19,2.19,0,0,1-.48,3.05l-39.37,28.62A2.19,2.19,0,0,1,217.15,176.14Zm-44.42-68.51,46.66,64.18,35.85-26.06L208.57,81.57Z"
                    fill="#727373"
                />
                <path
                    id="Path_31"
                    data-name="Path 31"
                    d="M201.43,109.43a4.67,4.67,0,1,1,1-6.53A4.67,4.67,0,0,1,201.43,109.43Z"
                    fill="#727373"
                />
                <path
                    id="Path_32"
                    data-name="Path 32"
                    d="M232.17,151.7a4.67,4.67,0,1,1,1-6.52A4.67,4.67,0,0,1,232.17,151.7Z"
                    fill="#727373"
                />
                <circle
                    id="Ellipse_11"
                    data-name="Ellipse 11"
                    cx="5.23"
                    cy="5.23"
                    r="5.23"
                    transform="translate(201.214 80.278) rotate(-25.61)"
                    fill="#727373"
                />
                <path
                    id="Path_33"
                    data-name="Path 33"
                    d="M177.05,112.12a5.23,5.23,0,1,1,1.15-7.31,5.23,5.23,0,0,1-1.15,7.31Z"
                    fill="#727373"
                />
                <path
                    id="Path_34"
                    data-name="Path 34"
                    d="M256.83,149.63a5.22,5.22,0,1,1,2.089-3.411,5.23,5.23,0,0,1-2.089,3.411Z"
                    fill="#727373"
                />
                <path
                    id="Path_35"
                    data-name="Path 35"
                    d="M222.61,174.78a5.22,5.22,0,1,1,2.089-3.411,5.23,5.23,0,0,1-2.089,3.411Z"
                    fill="#727373"
                />
                <circle
                    id="Ellipse_12"
                    data-name="Ellipse 12"
                    cx="12.04"
                    cy="12.04"
                    r="12.04"
                    transform="translate(196.921 123.895) rotate(-36.59)"
                    fill="#727373"
                />
                <path
                    id="Path_36"
                    data-name="Path 36"
                    d="M217.19,127.79s-.06-.3-.17-.89a3.39,3.39,0,0,0-.46-1.31.87.87,0,0,0-1.36-.27c-.29.21-.41.69-.35,1.42.09,1.17.13,1.87.12,2.08a2.55,2.55,0,0,1-1.05,1.92c-1.32,1-2.79.79-4.42-.53-.75.61-1.16.94-1.25,1s-.11,0-.2-.1a2.06,2.06,0,0,1-.23-.31l-.14-.24a2.258,2.258,0,0,0-.23-.26,3.314,3.314,0,0,1-.23-.26.611.611,0,0,1-.05-.12,1,1,0,0,1,.32-.31l.75-.5c-1-1.31-1.34-2-1.18-2.19a.49.49,0,0,1,.15-.09l.15-.1a6.862,6.862,0,0,0,.67-.57,5,5,0,0,0,.38-.44,2.16,2.16,0,0,1,.4-.43.18.18,0,0,1,.27.06,4.611,4.611,0,0,1,.37.73,3.779,3.779,0,0,0,.42.8c.62.86,1.2,1.09,1.74.71.27-.21.41-.68.4-1.43a17.5,17.5,0,0,1,0-2.12,2.6,2.6,0,0,1,1-1.89,3,3,0,0,1,2.24-.54,3.69,3.69,0,0,1,2.1,1l.34-.28a3.639,3.639,0,0,1,.5-.35c.18-.1.29-.11.35,0l.91,1.26c.05.06,0,.15-.14.27a2.9,2.9,0,0,1-.44.33,2.129,2.129,0,0,1-.35.23,8.621,8.621,0,0,1,.68,1.63c.3.9.37,1.4.2,1.52a.77.77,0,0,1-.22.1,4.185,4.185,0,0,0-.87.22c-.19.09-.48.2-.87.35A.212.212,0,0,1,217.19,127.79Z"
                    fill="#f3f4f5"
                />
                <rect
                    id="Rectangle_6"
                    data-name="Rectangle 6"
                    width="83.71"
                    height="48.67"
                    transform="matrix(0.588, 0.809, -0.809, 0.588, 211.536, 60.761)"
                    fill="#f3f4f5"
                />
                <path
                    id="Path_37"
                    data-name="Path 37"
                    d="M219.63,158.37,170.4,90.66a2.19,2.19,0,0,1,.48-3L210.25,59a2.19,2.19,0,0,1,3,.48l49.22,67.71a2.17,2.17,0,0,1-.48,3l-39.36,28.62a2.17,2.17,0,0,1-3-.44ZM175.21,89.86l46.66,64.19L257.71,128,211.05,63.8Z"
                    fill="#727373"
                />
                <path
                    id="Path_38"
                    data-name="Path 38"
                    d="M203.91,91.66a4.67,4.67,0,1,1,1-6.53,4.67,4.67,0,0,1-1,6.53Z"
                    fill="#727373"
                />
                <path
                    id="Path_39"
                    data-name="Path 39"
                    d="M234.64,133.94a4.67,4.67,0,1,1,1-6.53,4.67,4.67,0,0,1-1,6.53Z"
                    fill="#727373"
                />
                <path
                    id="Path_40"
                    data-name="Path 40"
                    d="M213.74,69.2a5.22,5.22,0,1,1,1.16-7.3,5.22,5.22,0,0,1-1.16,7.3Z"
                    fill="#727373"
                />
                <circle
                    id="Ellipse_13"
                    data-name="Ellipse 13"
                    cx="5.23"
                    cy="5.23"
                    r="5.23"
                    transform="translate(169.122 91.09) rotate(-52.54)"
                    fill="#727373"
                />
                <path
                    id="Path_41"
                    data-name="Path 41"
                    d="M259.3,131.87a5.22,5.22,0,1,1,2.1-3.414,5.23,5.23,0,0,1-2.1,3.414Z"
                    fill="#727373"
                />
                <circle
                    id="Ellipse_14"
                    data-name="Ellipse 14"
                    cx="5.23"
                    cy="5.23"
                    r="5.23"
                    transform="translate(214.678 153.762) rotate(-52.54)"
                    fill="#727373"
                />
                <circle
                    id="Ellipse_15"
                    data-name="Ellipse 15"
                    cx="12.04"
                    cy="12.04"
                    r="12.04"
                    transform="translate(199.976 113.649) rotate(-62.19)"
                    fill="#727373"
                />
                <path
                    id="Path_42"
                    data-name="Path 42"
                    d="M219.67,110s-.06-.31-.17-.9a3.38,3.38,0,0,0-.47-1.31.87.87,0,0,0-1.36-.26c-.29.21-.4.68-.35,1.42a19.6,19.6,0,0,1,.12,2.07,2.53,2.53,0,0,1-1,1.93c-1.32,1-2.8.78-4.42-.53l-1.25,1a.325.325,0,0,1-.21-.09,2.269,2.269,0,0,1-.23-.32c-.1-.16-.14-.25-.14-.24a3.312,3.312,0,0,0-.23-.26l-.22-.25a.38.38,0,0,1-.06-.13c0-.06.13-.16.33-.31a8.268,8.268,0,0,1,.75-.49c-1-1.31-1.35-2-1.18-2.2a.56.56,0,0,1,.14-.09l.15-.1a6.833,6.833,0,0,0,.67-.56,4.068,4.068,0,0,0,.38-.45,2.36,2.36,0,0,1,.4-.42.18.18,0,0,1,.28,0,4.411,4.411,0,0,1,.36.73,5,5,0,0,0,.42.8c.63.86,1.21,1.1,1.74.71.28-.2.41-.68.4-1.42V106.2a2.65,2.65,0,0,1,1-1.89,3,3,0,0,1,2.24-.54,3.82,3.82,0,0,1,2.11,1,3.3,3.3,0,0,1,.33-.28,4.369,4.369,0,0,1,.5-.35c.18-.1.3-.11.35,0l.92,1.26a.313.313,0,0,1-.14.27,3.25,3.25,0,0,1-.44.34l-.35.23a8.83,8.83,0,0,1,.68,1.62c.3.9.36,1.41.2,1.53l-.23.09a4.512,4.512,0,0,0-.87.22c-.19.09-.48.2-.87.35A.23.23,0,0,1,219.67,110Z"
                    fill="#f3f4f5"
                />
                <g id="Group_1" data-name="Group 1" clipPath="url(#clip-path-auto-deals)">
                    <circle
                        id="Ellipse_16"
                        data-name="Ellipse 16"
                        cx="116.62"
                        cy="116.62"
                        r="116.62"
                        transform="translate(90.424 233.5) rotate(-45)"
                        fill="#f3f4f5"
                    />
                    <path
                        id="Path_43"
                        data-name="Path 43"
                        d="M126,197l19-49.88,59.3,41.66c3.77,2.26,16.19-1.1,23.05-1a63.2,63.2,0,0,1,12,1.29c-8.69,4.52-16.55,12-19,17.24a165.049,165.049,0,0,1-5.35,22.9c-1.19,3.64,1,7.19,4.41,8.87,6.57,1.36,13,6.3,21.65-20.92a48.549,48.549,0,0,1,7-8.43,7.86,7.86,0,0,1,10.07-.64L327.35,259c6.59,4.91,6.21,11.15,3.41,14.83A10.48,10.48,0,0,1,316.3,277h0c3.45,3.35,3.42,9.93.65,13.91-4,5.76-11.3,6.32-16.5,3-1.27-.82-.72-.57-.72-.57,4.53,3.16,4.66,11.21,1.46,15.43-6.16,8-14.2,5.15-16.44,3.72l-3.27-2.38c3.45,3.35,3.62,10.32-1.24,15.19-4.18,4.17-10.45,4.42-15.65,1.11-.92-.82-72.38-51.19-72.38-51.19s-8-5.52-11-13.83c0,0-54.21-37.28-65.42-40.28S126,197,126,197Z"
                        fill="#e3e2e2"
                    />
                    <path
                        id="Path_44"
                        data-name="Path 44"
                        d="M249.71,181.9c4.44-2.32,10-2.57,15-3a72.56,72.56,0,0,1,25.4,2.71,26.09,26.09,0,0,0,17.54-1.55L350,160.66,385.78,225l-40.21,15.66A35.35,35.35,0,0,0,330.7,256.3l-2,3.67-70.57-52a5.92,5.92,0,0,0-8.06,1c-3.61,4.32-8.8,11.38-10.68,18-3,10.61-14,15.87-21.12,11.74-6-3.44-3.26-9.54-3.26-9.54s2.72-16,5.35-22.9C223.81,197.15,237.92,188.05,249.71,181.9Z"
                        fill="#727373"
                    />
                    <path
                        id="Path_45"
                        data-name="Path 45"
                        d="M209.42,265.89c6.45,4.8,1.88,15.35,1.88,15.35s7.31-6,14-1.09c7.42,5.4,4.33,12.4,4.33,12.4a10.27,10.27,0,0,1,13.76-.34c7.07,5.45,4.16,12.15,4.16,12.15s5.24-2.92,11.6,1.76c8,5.91,2,15,.34,17l-3.7,4.53a10.44,10.44,0,0,1-14.32,2.49,10.64,10.64,0,0,1-3.61-12.31l-.32.48a11.38,11.38,0,0,1-13.67,4.33,11,11,0,0,1-6.26-13.28,11.18,11.18,0,0,1-14.6,1.77l-.24-.24a11,11,0,0,1-3.45-14,11.9,11.9,0,0,1-17.72-15.72l9.12-12.94C194.89,262.62,201.59,260.06,209.42,265.89Z"
                        fill="#727373"
                    />
                    <circle
                        id="Ellipse_17"
                        data-name="Ellipse 17"
                        cx="116.62"
                        cy="116.62"
                        r="116.62"
                        transform="translate(90.424 233.5) rotate(-45)"
                        fill="none"
                    />
                    <path
                        id="Path_46"
                        data-name="Path 46"
                        d="M281.45,310.12s-9-6.63-10-6.63c-1.73,0-2.6,1.95-.79,3.24s8.32,5.89,8.32,5.89l4.88,3.12A8.93,8.93,0,0,0,281.45,310.12Z"
                        fill="#1b1a1c"
                        opacity="0.2"
                    />
                    <path
                        id="Path_47"
                        data-name="Path 47"
                        d="M302.73,297.57l-14-9.13c-5-3.24-2-5.45-.59-4.89s18.11,11.57,19.38,12.4a13.92,13.92,0,0,1-7.12-2,8.75,8.75,0,0,1,2.33,3.62Z"
                        fill="#1b1a1c"
                        opacity="0.2"
                    />
                    <path
                        id="Path_48"
                        data-name="Path 48"
                        d="M321.45,278.18l-17.88-12.27c-2-1.17-3.2.72-1.62,2.42.21.23,16.29,11.75,16.29,11.75a8.07,8.07,0,0,0-2-3.08A9.59,9.59,0,0,0,321.45,278.18Z"
                        fill="#1b1a1c"
                        opacity="0.2"
                    />
                    <path
                        id="Path_49"
                        data-name="Path 49"
                        d="M199.3,296.9s3-8.19,12-15.63C211.28,281.27,206.85,292.58,199.3,296.9Z"
                        fill="#1b1a1c"
                        opacity="0.2"
                    />
                    <path
                        id="Path_50"
                        data-name="Path 50"
                        d="M217.51,309.61s4.08-10.56,12.1-17C229.61,292.58,224.52,305,217.51,309.61Z"
                        fill="#1b1a1c"
                        opacity="0.2"
                    />
                    <path
                        id="Path_51"
                        data-name="Path 51"
                        d="M237.65,318.13a48.049,48.049,0,0,1,9.87-13.74S244.32,313.26,237.65,318.13Z"
                        fill="#1b1a1c"
                        opacity="0.2"
                    />
                </g>
                <path
                    id="Path_52"
                    data-name="Path 52"
                    d="M332.66,162.75l29.95,75.91,22.01-7.2-29.21-77.24Z"
                    fill="#f3f4f5"
                />
                <path
                    id="Path_53"
                    data-name="Path 53"
                    d="M353.7,150.2l33,86.49,68.76-28.04,20.32-8.5-11.83-47.09L439.7,108.52Z"
                    fill="#727373"
                />
                <circle
                    id="Ellipse_19"
                    data-name="Ellipse 19"
                    cx="4.49"
                    cy="4.49"
                    r="4.49"
                    transform="translate(364.04 220.25)"
                    fill="#727373"
                />
                <path
                    id="Path_54"
                    data-name="Path 54"
                    d="M190.52,175.38l-45.69,67.61-19.93-11.8,45.26-69.08Z"
                    fill="#f3f4f5"
                />
                <path
                    id="Path_55"
                    data-name="Path 55"
                    d="M172.69,158.56l-50.95,77.29-66.5-41.02-16.73-10.9L53.26,138l24.06-37.02Z"
                    fill="#727373"
                />
                <path
                    id="Path_56"
                    data-name="Path 56"
                    d="M137.69,227.15a4.49,4.49,0,1,0,5.35-3.41A4.49,4.49,0,0,0,137.69,227.15Z"
                    fill="#727373"
                />
                <path
                    id="Path_57"
                    data-name="Path 57"
                    d="M177.4,58.41a1.1,1.1,0,0,1-1.1-1.1V47.42a1.1,1.1,0,1,1,2.2,0v9.89A1.1,1.1,0,0,1,177.4,58.41Z"
                    fill="#e3e2e2"
                />
                <path
                    id="Path_58"
                    data-name="Path 58"
                    d="M182.35,53.46h-9.9a1.1,1.1,0,1,1,0-2.2h9.9a1.1,1.1,0,1,1,0,2.2Z"
                    fill="#e3e2e2"
                />
                <path
                    id="Path_59"
                    data-name="Path 59"
                    d="M377.37,72.56a1.1,1.1,0,0,1-1.1-1.1v-9.9a1.1,1.1,0,0,1,2.2,0v9.9A1.1,1.1,0,0,1,377.37,72.56Z"
                    fill="#e3e2e2"
                />
                <path
                    id="Path_60"
                    data-name="Path 60"
                    d="M382.32,67.61h-9.9a1.1,1.1,0,1,1,0-2.2h9.9a1.1,1.1,0,1,1,0,2.2Z"
                    fill="#e3e2e2"
                />
                <path
                    id="Path_61"
                    data-name="Path 61"
                    d="M89.44,255.41a1.18,1.18,0,0,1-1.18-1.19V243.55a1.19,1.19,0,0,1,2.37,0v10.67a1.19,1.19,0,0,1-1.19,1.19Z"
                    fill="#e3e2e2"
                />
                <path
                    id="Path_62"
                    data-name="Path 62"
                    d="M94.78,250.07H84.11a1.19,1.19,0,1,1,0-2.37H94.78a1.19,1.19,0,0,1,0,2.37Z"
                    fill="#e3e2e2"
                />
                <path
                    id="Path_63"
                    data-name="Path 63"
                    d="M408.62,110.1a1.64,1.64,0,0,1-1.64-1.65v-7.39a1.64,1.64,0,1,1,3.28,0v7.39a1.64,1.64,0,0,1-1.64,1.65Z"
                    fill="#e3e2e2"
                />
                <path
                    id="Path_64"
                    data-name="Path 64"
                    d="M412.31,106.4h-7.39a1.64,1.64,0,1,1,0-3.28h7.39a1.64,1.64,0,1,1,0,3.28Z"
                    fill="#e3e2e2"
                />
                <path
                    id="Path_65"
                    data-name="Path 65"
                    d="M118.77,109a1.63,1.63,0,0,1-1.64-1.64V100a1.64,1.64,0,0,1,3.28,0v7.39a1.64,1.64,0,0,1-1.64,1.61Z"
                    fill="#e3e2e2"
                />
                <path
                    id="Path_66"
                    data-name="Path 66"
                    d="M122.46,105.34h-7.39a1.65,1.65,0,0,1,0-3.29h7.39a1.65,1.65,0,1,1,0,3.29Z"
                    fill="#e3e2e2"
                />
                <path
                    id="Path_67"
                    data-name="Path 67"
                    d="M322.09,393.86a1.63,1.63,0,0,1-1.64-1.64v-7.39a1.64,1.64,0,0,1,3.28,0v7.39a1.64,1.64,0,0,1-1.64,1.64Z"
                    fill="#e3e2e2"
                />
                <path
                    id="Path_68"
                    data-name="Path 68"
                    d="M325.78,390.17h-7.39a1.65,1.65,0,0,1,0-3.29h7.39a1.65,1.65,0,1,1,0,3.29Z"
                    fill="#e3e2e2"
                />
                <path
                    id="Path_69"
                    data-name="Path 69"
                    d="M426.3,305.59a7.72,7.72,0,1,1,7.72-7.72A7.72,7.72,0,0,1,426.3,305.59Zm0-12.15a4.44,4.44,0,1,0,4.43,4.43,4.44,4.44,0,0,0-4.43-4.43Z"
                    fill="#e3e2e2"
                />
                <path
                    id="Path_70"
                    data-name="Path 70"
                    d="M269.74,63.81a10.57,10.57,0,1,1,10.57-10.57,10.57,10.57,0,0,1-10.57,10.57Zm0-16.64a6.08,6.08,0,1,0,6.07,6.07,6.08,6.08,0,0,0-6.07-6.07Z"
                    fill="#e3e2e2"
                />
                <circle
                    id="Ellipse_20"
                    data-name="Ellipse 20"
                    cx="3.68"
                    cy="3.68"
                    r="3.68"
                    transform="translate(403.3 368.97)"
                    fill="#e3e2e2"
                />
                <circle
                    id="Ellipse_21"
                    data-name="Ellipse 21"
                    cx="3.68"
                    cy="3.68"
                    r="3.68"
                    transform="translate(95.72 381.17)"
                    fill="#e3e2e2"
                />
                <path
                    id="Path_71"
                    data-name="Path 71"
                    d="M255.35,7.47c-124.28,0-225,100.75-225,225s100.75,225,225,225,225-100.75,225-225S379.64,7.47,255.35,7.47Zm.17,443.9c-121.11,0-219.29-98.17-219.29-219.28S134.41,12.81,255.52,12.81,474.8,111,474.8,232.09,376.62,451.37,255.52,451.37Z"
                    fill="#727373"
                />
            </g>
        </SVGIcon>
    );
}
