import React, { useCallback, useEffect, useRef, useState } from 'react';
import { enGB } from 'date-fns/locale';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';

import DCCalendarDate from 'Assets/icons/DCCalendarDate';
import { Button, Div, DropdownOptions, Popover } from 'UIKit/index';
import { getUserTimeZone, timeInUserTimeZone } from 'Utils/dateUtils';

import 'react-nice-dates/build/style.css';

import './style.scss';
import useToast from 'Modules/Toasts';
import areValidScopes from 'Utils/scopeUtils';
import OnlyDatePickerCalendar from './OnlyDatePickerCalendar';
const toObject = require('dayjs/plugin/toObject');
dayjs.extend(toObject);
const months = [
    {
        id: 0,
        title: 'January',
        value: 0,
    },
    {
        id: 1,
        title: 'February',
        value: 1,
    },
    {
        id: 2,
        title: 'March',
        value: 2,
    },
    {
        id: 3,
        title: 'April',
        value: 3,
    },
    {
        id: 4,
        title: 'May',
        value: 4,
    },
    {
        id: 5,
        title: 'June',
        value: 5,
    },
    {
        id: 6,
        title: 'July',
        value: 6,
    },
    {
        id: 7,
        title: 'August',
        value: 7,
    },
    {
        id: 8,
        title: 'September',
        value: 8,
    },
    {
        id: 9,
        title: 'October',
        value: 9,
    },
    {
        id: 10,
        title: 'November',
        value: 10,
    },
    {
        id: 11,
        title: 'December',
        value: 11,
    },
];

function DatePicker(props) {
    const {
        value,
        onChange,
        disabled,
        readOnly,
        autoFocus,
        placeholder,
        width,
        height,
        placement,
        onlyFutureDates,
        showPlaceholder,
        scope,
        currentStatus,
        resetVal,
        onlyPastdates,
    } = props;
    const generateArrayOfYears = useCallback(() => {
        var currYear = dayjs().tz(getUserTimeZone()).year();
        var max = currYear + 100;
        var min = currYear - 100;
        var _years = [];
        if (onlyPastdates) {
            for (var i = currYear; i >= min; i--) {
                _years.push(i);
            }
        } else if (onlyFutureDates) {
            for (var i = currYear; i <= max; i++) {
                _years.push(i);
            }
        } else {
            for (var j = max; j >= min; j--) {
                _years.push(j);
            }
        }
        return _years;
    }, [onlyFutureDates, onlyPastdates]);

    const { showToast } = useToast();
    const firstRender = useRef(true);
    const [month, setMonth] = useState(
        currentStatus
            ? months[dayjs().tz(getUserTimeZone()).month()]
            : value
            ? months[dayjs(value).month()]
            : months[dayjs().tz(getUserTimeZone()).month()],
    );
    const years = generateArrayOfYears();
    const [year, setYear] = useState(
        currentStatus
            ? dayjs().tz(getUserTimeZone()).year()
            : value
            ? dayjs(value).tz(getUserTimeZone()).year()
            : dayjs().tz(getUserTimeZone()).year(),
    );
    const [date, setDate] = useState(timeInUserTimeZone(value));
    const [showCalendar, setShowCalendar] = useState(true);

    const monthButtonRef = useRef();
    const yearButtonRef = useRef();
    const inputRef = useRef();
    const [popoverWidth, setPopoverWidth] = useState(0);
    const [yearDropdownWidth, setYearDropdownWidth] = useState(100);
    const [datePickerCalendar, setDatePickerCalendar] = useState(false);
    const [access, setAccess] = useState(false);
    const [openMonthDropdown, setOpenMonthDropdown] = useState(false);
    const [openYearDropdown, setOpenYearDropdown] = useState(false);
    const handleMonthDropdownToggle = useCallback(() => {
        setOpenMonthDropdown(prev => !prev);
    }, []);

    const handleYearDropdownToggle = useCallback(() => {
        setOpenYearDropdown(prev => !prev);
        setYearDropdownWidth(yearButtonRef.current?.offsetWidth);
    }, []);

    useEffect(() => {
        if (resetVal) {
            setDate(timeInUserTimeZone(value));
        }
    }, [resetVal, value]);

    const getValue = useCallback(() => {
        if (showPlaceholder) {
            if (value?.length) {
                return dayjs(date).format('MMMM D, YYYY');
            } else return null;
        } else {
            return dayjs(date).format('MMMM D, YYYY');
        }
    }, [date, showPlaceholder, value]);

    const modifiersClassNames = {
        highlight: '-highlight',
    };

    const handleDatePickerCalendarToggle = async () => {
        if (scope && !datePickerCalendar) {
            if (!access) {
                showToast({
                    message: 'You do not have access to that page',
                });
                return;
            }
        }
        if (!readOnly) {
            setDatePickerCalendar(prevOpen => !prevOpen);
            setPopoverWidth(inputRef.current.offsetWidth);
        }
    };

    const handleDatePickerCalendarClose = () => {
        setOpenMonthDropdown(false);
        setOpenYearDropdown(false);
        setDatePickerCalendar(false);
    };

    const handleDatePicker = _date => {
        if (onlyFutureDates) {
            if (dayjs(_date).startOf('date') < dayjs(timeInUserTimeZone()).startOf('date')) {
                showToast({
                    type: 'warning',
                    message: 'Select only Present or Future Date',
                    duration: 2000,
                });
                return;
            }
        }
        if (onlyPastdates) {
            if (dayjs(_date).startOf('date') > dayjs(timeInUserTimeZone()).startOf('date')) {
                showToast({
                    type: 'warning',
                    message: 'Select only Present or Past Date',
                    duration: 2000,
                });
                return;
            }
        }
        let newDate = dayjs().tz(getUserTimeZone()).startOf('date');
        newDate = newDate.date(dayjs(_date).date());
        newDate = newDate.month(dayjs(_date).month());
        newDate = newDate.year(dayjs(_date).year());
        onChange && onChange(newDate.format());
        setDate(newDate.format());
        setMonth(months[newDate.month()]);
        setYear(newDate.year());
        setDatePickerCalendar(false);
    };

    useEffect(() => {
        (async () => {
            if (scope) {
                if (!areValidScopes(scope.masterScope, scope.childScope)) {
                    return;
                } else {
                    setAccess(true);
                }
            }
        })();
    }, [scope, showToast]);

    // Generate options for month dropdown
    const generateMonthOptions = useCallback(() => {
        let updatedMonths = [...months];
        let selectedFutureYear = year > dayjs().year();
        let selectedPastYear = year < dayjs().year();
        if (onlyFutureDates && !selectedFutureYear) {
            let currMonth = dayjs().month();
            updatedMonths = updatedMonths.filter(item => item.value >= currMonth);
        }
        if (onlyPastdates && !selectedPastYear) {
            let currMonth = dayjs().month();
            updatedMonths = updatedMonths.filter(item => item.value <= currMonth);
        }

        return updatedMonths.map(item => {
            return {
                label: (
                    <Button
                        buttonClass={`DropdownOptionsBtn ${month.id === item.id ? ' active' : ''}`}
                        onClick={() => {
                            setMonth(item);
                            setOpenMonthDropdown(false);
                        }}
                    >
                        {item.title}
                    </Button>
                ),
            };
        });
    }, [month, onlyFutureDates, onlyPastdates, year]);

    // Generate options for year dropdown

    const generateYearOptions = useCallback(() => {
        let updatedYears = [...years];

        return updatedYears.map(item => {
            return {
                label: (
                    <Button
                        buttonClass={`DropdownOptionsBtn ${year === item ? ' active' : ''}`}
                        onClick={() => {
                            setYear(item);
                            setOpenYearDropdown(false);
                        }}
                    >
                        {item}
                    </Button>
                ),
            };
        });
    }, [year, years]);

    useEffect(() => {
        if (!firstRender.current) {
            let newDate = dayjs(date);
            let selectedPresentYear = year === dayjs().year();
            if (month) newDate = newDate.month(month.value);
            if (year) newDate = newDate.year(year);

            if (selectedPresentYear && month.value < dayjs().month() && onlyFutureDates) {
                setMonth(months[dayjs().month()]);
                newDate = newDate.month(dayjs().month());
            }

            setDate(newDate.toDate());
            (currentStatus || !onlyFutureDates || !onlyPastdates) && onChange(newDate.format());
            setShowCalendar(false);
        } else firstRender.current = false;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [month, year]);

    useEffect(() => {
        if (!showCalendar) setShowCalendar(true);
    }, [showCalendar]);

    return (
        <Div className={'DatePicker'}>
            <Div className={'DatePickerInput'}>
                <input
                    type="text"
                    value={getValue() || ''}
                    ref={inputRef}
                    disabled={disabled}
                    readOnly={true}
                    autoFocus={autoFocus}
                    onClick={handleDatePickerCalendarToggle}
                    autoComplete="off"
                    placeholder={placeholder || 'Select Date'}
                />
                <Div className={'DPIIcon'}>
                    <DCCalendarDate />
                </Div>
            </Div>
            {datePickerCalendar && (
                <Popover
                    open={datePickerCalendar}
                    onClose={handleDatePickerCalendarClose}
                    width={width || popoverWidth}
                    height={height}
                    placement={placement || 'bottom-start'}
                    anchorEl={inputRef}
                    customClass={'DPPopover'}
                >
                    <Div className={'DPMonthYearContainer'}>
                        <Div className={'DPMYCMonth DCDropdownSelect fullWidth'}>
                            <Button
                                buttonClass={
                                    'DCDSMainBtn ' + (openMonthDropdown === true ? 'focus' : '')
                                }
                                reference={monthButtonRef}
                                onClick={handleMonthDropdownToggle}
                            >
                                {month?.title || 'Select Month'}
                            </Button>
                            <DropdownOptions
                                options={generateMonthOptions()}
                                open={openMonthDropdown}
                                wrapperWidth={monthButtonRef.current?.offsetWidth}
                                onClose={() => setOpenMonthDropdown(false)}
                                reference={monthButtonRef}
                                viewHeight={200}
                                itemSize={40}
                                defaultPlacement={'bottom-start'}
                            />
                        </Div>

                        <Div className={'DPMYCMonth DCDropdownSelect fullWidth'}>
                            <Button
                                buttonClass={
                                    'DCDSMainBtn ' + (openYearDropdown === true ? 'focus' : '')
                                }
                                reference={yearButtonRef}
                                onClick={handleYearDropdownToggle}
                            >
                                {year || 'Select Year'}
                            </Button>
                            <DropdownOptions
                                options={generateYearOptions()}
                                open={openYearDropdown}
                                wrapperWidth={yearDropdownWidth}
                                onClose={() => setOpenYearDropdown(false)}
                                reference={yearButtonRef}
                                viewHeight={200}
                                itemSize={40}
                                defaultPlacement={'bottom-start'}
                                selectedItemIndex={years.findIndex(item => item === year)}
                            />
                        </Div>
                    </Div>
                    {showCalendar && (
                        <OnlyDatePickerCalendar
                            locale={enGB}
                            date={dayjs(date).startOf('day').toDate()}
                            handleDatePicker={handleDatePicker}
                            modifiersClassNames={modifiersClassNames}
                            pastValueDate={date === timeInUserTimeZone(value) && onlyFutureDates}
                            minimumDate={
                                onlyFutureDates &&
                                new Date(
                                    dayjs().tz(getUserTimeZone()).year(),
                                    dayjs().tz(getUserTimeZone()).month(),
                                    dayjs().tz(getUserTimeZone()).date(),
                                )
                            }
                            maximumDate={
                                onlyPastdates &&
                                new Date(
                                    dayjs().tz(getUserTimeZone()).year(),
                                    dayjs().tz(getUserTimeZone()).month(),
                                    dayjs().tz(getUserTimeZone()).date(),
                                )
                            }
                        />
                    )}
                </Popover>
            )}
        </Div>
    );
}

DatePicker.propTypes = {
    value: PropTypes.string.isRequired, // utc string
    timezone: PropTypes.string,
    onChange: PropTypes.func.isRequired,
    disabled: PropTypes.bool,
    readOnly: PropTypes.bool,
    autoFocus: PropTypes.bool,
    height: PropTypes.number,
    width: PropTypes.number,
    placement: PropTypes.string,
    onlyFutureDates: PropTypes.bool,
};

export default DatePicker;
