import React, { useEffect, useState } from 'react';
import { Redirect, Route, withRouter } from 'react-router-dom';
import { getUserConfigs, getUsageData } from 'apis/root/index.api';
import Error404 from 'Pages/Error/404';
import { makeId } from 'Utils';
import { setupGoogleAnalytics, setUpLogRocket, setUpSendGrid, setupTwilio } from './initialSetup';
import lazyComponent from './lazyComponent';
import PrivateRoute from './PrivateRoute';
import Login from 'Pages/Login';
import ResetPassword from 'Pages/Login/ResetPassword';
import UserForm from 'Pages/Forms/UserForm/userForm';
import ViewInstasite from 'Pages/InstaSites/ViewInstasite';
import ViewInstaReport from 'Pages/InstaReports/ViewInstaReport';
import ExternalPage from 'Pages/InstaReports/externalPage/externalPage';
import Maintenance from 'Pages/Maintenance';
import Verification from 'Pages/Verification';
import AffiliateCenter from 'Pages/AffiliateCenter/index';
import Test from 'Pages/Test';
import PublicReputation from 'Pages/Reviews/reputation';
import SignUp from 'Pages/Signup/index';
import Sessions from 'Pages/Sessions';

import CustomSwitch from './CustomSwitch';
import DashClicks from 'Pages/DashClicks/index';
import { getAccountType, isAuthenticated } from 'Utils/auth';
import { useGetScopes } from './getRoutes';
import { Ripple } from 'react-preloaders2';
import usePubSub from 'Hooks/PubSub';
import MenuBar from 'Components/MenuBar';
import {
    getDefaultPage,
    getUserDetails,
    setUserConfigs,
    getSessionId,
    removeSession,
    clearAllAuthData,
    isLocalStorageAvailable,
} from 'Utils/localStorageUtils';
import OauthLanding from 'Pages/Auth';
import IconSearchPage from 'Pages/IconSearchPage';
import CreateProfile from 'Components/CreateProfile/CreateProfile';
import SignupNext from 'Pages/Signup/SignupNext';

const resources = [
    'USERS',
    'SMS',
    'EMAIL',
    // 'STORAGE',
    'CONTACT_RECORDS',
    'CONTACTS_CUSTOM_FILTERS',
    'CONTACTS_CUSTOM_PROPERTIES',
    'SALES_PIPELINE',
    'DEALS_CUSTOM_FILTERS',
    'DEALS_CUSTOM_PROPERTIES',
    'DEALS_AUTOMATIONS',
    'FORMS',
    'INBOUND_CAMPAIGNS',
    'TRACKED_SEO_KEYWORDS',
    'TEMPLATES',
    'INSTASITES_BUILDS',
    'INSTAREPORTS_BUILDS',
    'CRM_TAGS',
];

const publicPages = [
    'auth/login',
    'auth/reset',
    'forms/userform',
    'instasites/view',
    'oauth-landing',
    'instareports/view',
    'view-report/:dynamic',
    'auth/verification',
    'sessions',
    'sub-account/signup',
    'signup-url-not-active',
    'auth/create-profile',
    'sign-up-active',
    ':dynamic/review',
];

export const configUsageData = async () => {
    const res = await getUsageData(resources.join(','));
    if (res.success) {
        localStorage.setItem('usage_data', JSON.stringify(res.data));
    }
};
const Router = props => {
    const [loading, setLoading] = useState(true);
    const [routeLoad, setRouteLoad] = useState(false);
    const [authenticated, setAuthenticated] = useState(false);
    const { publish } = usePubSub();

    useEffect(() => {
        if (isLocalStorageAvailable()) localStorage.removeItem('underMaintenance');
    }, []);

    const getComponent = (route, index) => {
        if (route.isPrivate) {
            return (
                <PrivateRoute
                    key={makeId(index)}
                    path={route.routepath}
                    component={lazyComponent(route.componentName, route.componentPath)}
                />
            );
        }
        return (
            <Route
                key={makeId(index)}
                path={route.routepath}
                exact
                component={lazyComponent(route.componentName, route.componentPath)}
            />
        );
    };

    const fetchRoutes = _routes => {
        return _routes.map((route, index) => {
            if (route) {
                if (route.componentName === 'AffiliateCenter') {
                    if (getAccountType() === 'main' && getUserDetails()?.is_owner) {
                        return getComponent(route, index);
                    }
                } else {
                    return getComponent(route, index);
                }
            }
        });
    };
    const {
        routes,
        loading: routeLoading,
        setLoading: setRouteLoading,
    } = useGetScopes(fetchRoutes);

    const isMongoId = str => {
        return str?.match(/^[0-9a-fA-F]{24}$/);
    };

    const isContainPages = pages => {
        let route1 = location.pathname.split('/')[1];
        let route2 = location.pathname.split('/')[2];
        if (route1 && isMongoId(route1)) {
            route1 = ':dynamic';
        }
        if (route2 && isMongoId(route2)) {
            route2 = ':dynamic';
        }
        const currentPath = route1 + (route2 ? '/' + route2 : '');

        if (pages.indexOf(currentPath) > -1) {
            return true;
        }

        return false;
    };

    const setupAuthentication = async () => {
        try {
            const res = await getUserConfigs();
            setUserConfigs(res);
            setRouteLoad(false);
            setLoading(false);
            setAuthenticated(true);
            setUpLogRocket();
            setupGoogleAnalytics();
            setupTwilio();
            setUpSendGrid();
        } catch (err) {
            if (err === 'Session Expired. Please login again.') {
                const sessionId = getSessionId();
                removeSession(sessionId);
            }
            console.info('setupAuthentication error', err);
            // setUnAuthorized();
            // if (isContainPages(publicPages)) window.location.href = '/auth/login';
            // else window.location.reload();
            clearAllAuthData();
            window.location.href = `/auth/login?redirect=${window.location.pathname || '/'}`;
        }
    };

    useEffect(() => {
        if (!isContainPages(publicPages)) {
            if (isAuthenticated()) {
                try {
                    // window.sessionStorage.clear();
                    setRouteLoad(true);
                    setupAuthentication();
                } catch (Err) {
                    console.error('Error', Err);
                }
            } else {
                const sessionId = getSessionId();
                if (!sessionId && window.location.pathname !== '/sessions') {
                    window.location.href = `/sessions?redirect=${window.location.pathname}`;
                } else if (sessionId) {
                    removeSession(sessionId);
                    window.location.href = '/auth/login';
                }
            }
        } else {
            setRouteLoad(false);
            setLoading(false);
            setRouteLoading(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    usePubSub('login-success', () => {
        setRouteLoad(true);
        try {
            setupAuthentication();
        } catch (Err) {
            console.error('Error', Err);
        }
    });

    const isValid = () => {
        if (!authenticated) return false;
        const route1 = location.pathname.split('/')[1];
        const route2 = location.pathname.split('/')[2];
        const currentPath = route1 + (route2 ? '/' + route2 : '');
        if (publicPages.indexOf(currentPath) > 0) {
            return false;
        }
        return isAuthenticated();
    };

    publish('show-warning-seo', window.location.pathname.includes('seo'));

    if (isLocalStorageAvailable() && localStorage.getItem('underMaintenance')) {
        return <Maintenance />;
    }

    if (routeLoad || routeLoading) {
        return <Ripple loading={true} />;
    }

    return (
        <>
            {isValid() && !loading && !routeLoad && <MenuBar />}

            <CustomSwitch>
                <Route path="/sessions" component={Sessions} />
                <Route path="/auth/login" exact component={Login} />
                <Route path="/auth/reset" exact component={ResetPassword} />
                <Route path="/auth/sso" exact component={Verification} />
                <Route path="/forms/userform/:id" exact component={UserForm} />
                <Route path="/instasites/view/:iid/:rid" exact component={ViewInstasite} />
                <Route path="/view-report/:id" exact component={ExternalPage} />
                <Route path="/instareports/view/:iid/:rid" exact component={ViewInstaReport} />
                <Route path="/review" exact component={PublicReputation} />
                <Route path="/sub-account/signup/:id" component={SignUp} />
                <Route path="/signup-url-not-active" component={Error404} />
                <Route path="/sign-up-active" component={SignupNext} />
                <Route path="/auth/create-profile" component={CreateProfile} />
                <Route path="/:id/review" exact component={PublicReputation} />

                <Route path="/oauth-landing" component={OauthLanding} />

                {process.env.REACT_APP_ENV === 'dev' && (
                    <Route path="/test" exact component={Test} />
                )}
                {process.env.REACT_APP_ENV === 'dev' && (
                    <Route path="/icons" exact component={IconSearchPage} />
                )}
                {isLocalStorageAvailable() && !isAuthenticated() && (
                    <Redirect
                        to={{
                            pathname: '/sessions',
                            search: `?redirect=${props.location.pathname}`,
                            state: { from: props.location },
                        }}
                    />
                )}
                {routes}
                {isLocalStorageAvailable() &&
                    getAccountType() === 'main' &&
                    getUserDetails()?.is_owner && (
                        <Route path="/affiliate-center" exact component={AffiliateCenter} />
                    )}

                <Route path="/dashclicks" component={DashClicks} />
                <Route path="/" exact render={() => <Redirect to={getDefaultPage()} />} />
                <Route component={Error404} />
            </CustomSwitch>
            <Ripple customLoading={loading} />
        </>
    );
};

export default withRouter(Router);
