import React from 'react';

import { SVGIcon } from '../../UIKit/index';

export default function DCPhoneCall(props) {
    return (
        <SVGIcon {...props} viewBox="0 0 512 478">
            <g transform="translate(0 -17.35)">
                <path
                    fill={'#FEA733'}
                    d="M363.02,318.5l-72.99,24.33l-68.83-68.83l-50.78,18.82l-35.28,67.26   C253.46,478.4,350.17,493.68,354.23,494.27l7.44,1.09l89.07-89.11L363.02,318.5z"
                />
                <path
                    fill="#FFB95B"
                    d="M152.38,205.18l24.33-72.99L88.96,44.44l-89.11,89.11l1.09,7.45   c0.59,4.06,15.87,100.77,134.19,219.1l0,0l86.08-86.08L152.38,205.18z"
                />
                <path
                    fill="#FFB95B"
                    d="M511.54,173.86H355.52l-14.55,12.35l14.55,17.63h156.02L511.54,173.86z"
                />
                <path
                    fill={'#FEA733'}
                    d="M334.53,173.86l17.05-17.05l-21.2-21.2l-53.24,53.24l53.24,53.24l21.2-21.2l-17.05-17.05h20.99   v-29.98L334.53,173.86z"
                />
                <path
                    fill={'#FEA733'}
                    d="M379.4,86.58l-17.05,17.05l21.2,21.2l53.24-53.24l-53.24-53.24l-21.2,21.2L379.4,56.6h-23.88   l-14.55,12.36l14.55,17.63H379.4z"
                />
                <path fill="#FFB95B" d="M213.06,56.6h142.47v29.98H213.06V56.6z" />
            </g>
        </SVGIcon>
    );
}
