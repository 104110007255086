import React from 'react';

import { SVGIcon } from '../../UIKit/index';

export default function DCInstaSite(props) {
    return (
        <SVGIcon {...props} viewBox="0 0 512 512">
            <path d="M452,20H60C26.9,20,0,46.9,0,80v352c0,33.1,26.9,60,60,60h392c33.1,0,60-26.9,60-60V80C512,46.9,485.1,20,452,20z M60,60  h392c11,0,20,9,20,20v58.1c0,1.1-0.9,1.9-1.9,1.9H41.9c-1.1,0-1.9-0.9-1.9-1.9V80C40,69,49,60,60,60z M452,452H60c-11,0-20-9-20-20  V181.9c0-1.1,0.9-1.9,1.9-1.9h428.2c1.1,0,1.9,0.9,1.9,1.9V432C472,443,463,452,452,452z M330.1,102c-1.3-12.7,9.3-23.2,21.9-21.9  c9.4,0.9,16.9,8.5,17.9,17.9c1.3,12.7-9.3,23.2-21.9,21.9C338.6,119,331,111.4,330.1,102z M400.1,102c-1.3-12.7,9.3-23.2,21.9-21.9  c9.4,0.9,16.9,8.5,17.9,17.9c1.3,12.7-9.3,23.2-21.9,21.9C408.6,119,401,111.4,400.1,102z M132.3,412h51.4  c28.9,0,52.4-23.5,52.4-52.4v-86.8c0-29.2-23.6-52.8-52.8-52.8h-51.3c-28.7,0-51.9,23.2-51.9,51.9v87.8  C80,388.6,103.4,412,132.3,412z M152.2,260H166c16.6,0,30,13.4,30,30v49.3c0,18-14.6,32.7-32.7,32.7h-10.8  c-18,0-32.5-14.6-32.5-32.5v-47.2C120,274.4,134.4,260,152.2,260z M296,296h116c11,0,20,9,20,20v0c0,11-9,20-20,20H296  c-11,0-20-9-20-20v0C276,305,285,296,296,296z M296,372h116c11,0,20,9,20,20v0c0,11-9,20-20,20H296c-11,0-20-9-20-20v0  C276,381,285,372,296,372z M296,220h116c11,0,20,9,20,20v0c0,11-9,20-20,20H296c-11,0-20-9-20-20v0C276,229,285,220,296,220z   M102.3,79.9h116c11,0,20,9,20,20v0c0,11-9,20-20,20h-116c-11,0-20-9-20-20v0C82.3,88.8,91.3,79.9,102.3,79.9z" />
        </SVGIcon>
    );
}
