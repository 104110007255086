import React, { forwardRef, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { createPortal } from 'react-dom';

import { Div } from '../index';

import './style.scss';

const DialogWrapper = forwardRef((props, ref) => {
    return createPortal(
        <div ref={ref} style={props.style} className={props.className}>
            {props.children}
        </div>,
        document.body,
        props.blurState === true
            ? document.body.classList.add('activeBlur')
            : document.body.classList.remove('activeBlur'),
    );
});

const Dialog = ({ open, classes, children, onClose, dialogType, blur }) => {
    const [defaultBlur, setDefaultBlur] = useState(blur || true);
    useEffect(() => {
        if (blur === false) setDefaultBlur(false);
        return () => {
            if (open) document.body.classList.remove('activeBlur');
        };
    }, [blur, open]);
    return (
        open && (
            <DialogWrapper
                className={`MainDialog${classes?.root ? ` ${classes.root}` : ''}${
                    dialogType ? ` ${dialogType}` : ''
                }`}
                style={{
                    marginTop: `${JSON.parse(localStorage.getItem('backdropAdjustment'))?.top}px`,
                }}
                open={open}
                blurState={defaultBlur}
            >
                <Div
                    className={`MDBackdrop ${classes?.backdrop ? classes.backdrop : ''}`}
                    style={{
                        transform: `translateY(${
                            JSON.parse(localStorage.getItem('backdropAdjustment'))?.top
                        }px) `,
                        zIndex: `${
                            JSON.parse(localStorage.getItem('backdropAdjustment'))?.top
                                ? '-1'
                                : 'unset'
                        }`,
                    }}
                    onClick={onClose}
                />
                <Div className={`MDContainer ${classes?.container ? classes.container : ''}`}>
                    <Div className={`MDCRow ${classes?.row ? classes.row : ''}`}>{children}</Div>
                </Div>
            </DialogWrapper>
        )
    );
};

Dialog.propTypes = {
    open: PropTypes.bool.isRequired,
    classes: PropTypes.object,
    children: PropTypes.element,
    onClose: PropTypes.func.isRequired,
    dialogType: PropTypes.string,
    blur: PropTypes.bool,
};

export default Dialog;

// fullWidth
// leftSlide
// rightSlide
// centerZoom
// centerZoom sizeXs
// centerZoom sizeSm
// centerZoom sizeMd
// centerZoom sizeLg
// centerZoom sizeXl
// centerZoom sizeFull
