import React from 'react';

import { SVGIcon } from '../../UIKit/index';

export default function DCUserReview(props) {
    return (
        <SVGIcon {...props} viewBox="0 0 512 512">
            <g>
                <path d="M210.9,286.2c0,24.8,20.1,44.9,44.9,44.9s44.9-20.1,44.9-44.9c0-24.8-20.1-44.9-44.9-44.9  C231,241.3,210.9,261.4,210.9,286.2z" />
                <path d="M255.8,361.1c-49.6,0.1-89.8,40.3-89.9,89.9v60.9h179.7v-60.9  C345.6,401.3,305.4,361.1,255.8,361.1z" />
                <path d="M136,316.1c0-24.8-20.1-44.9-44.9-44.9s-44.9,20.1-44.9,44.9c0,24.8,20.1,44.9,44.9,44.9  C115.9,361.1,136,341,136,316.1z" />
                <path d="M465.5,316.1c0-24.8-20.1-44.9-44.9-44.9s-44.9,20.1-44.9,44.9c0,24.8,20.1,44.9,44.9,44.9  C445.4,361.1,465.5,341,465.5,316.1z" />
                <path d="M76.1,0.6L53.4,54.3L0.2,61.8l38.3,36.4l-8.8,53.2l46.4-24.3l46.4,24.3l-8.7-53.2L151,61.8  l-51.5-7.5L76.1,0.6z" />
                <path d="M279.2,54.3L255.8,0.6l-22.7,53.7l-52.2,7.5l37.3,36.4l-8.9,53.2l46.4-24.3l46.4,24.3l-8.8-53.2  l37.3-36.4L279.2,54.3z" />
                <path d="M435.6,0.6l-22.7,53.7l-52.2,7.5L398,98.2l-8.9,53.2l46.4-24.3l46.4,24.3l-8.8-53.2l38.3-36.4  l-52.5-7.5L435.6,0.6z" />
                <path d="M420.6,391c-19.1,0-37.7,6.2-53,17.6c5.3,13.5,8,27.8,8.1,42.3v60.9h135.8v-31  C511,431.1,470.4,391,420.6,391z" />
                <path d="M0.2,480.9v31H136v-60.9c0.1-14.5,2.8-28.8,8.1-42.3c-15.3-11.4-33.9-17.6-53-17.6  C41.3,390.9,0.7,431.1,0.2,480.9z" />
            </g>
        </SVGIcon>
    );
}
