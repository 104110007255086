import React, { useReducer } from 'react';

import { templateActions } from '../Actions/index';
import { templateContext } from '../Context/index';
import { templateReducer } from '../Reducer/index';
import { templateStore } from '../Store/index';

const TemplateContextProvider = props => {
    const [state, dispatch] = useReducer(templateReducer, templateStore);

    const value = {
        allTemplates: state.allTemplates,
        allTemplatePageLimit: state.allTemplatePageLimit,
        allTemplatePage: state.allTemplatePage,
        allTemplateResults: state.allTemplateResults,
        initialAllTemplateLoad: state.initialAllTemplateLoad,
        totalAllTemplatePages: state.totalAllTemplatePages,
        allTemplateTPage: state.allTemplateTPage,

        emailTemplates: state.emailTemplates,
        emailTemplatePageLimit: state.emailTemplatePageLimit,
        emailTemplatePage: state.emailTemplatePage,
        emailTemplateResults: state.emailTemplateResults,
        initialEmailTemplateLoad: state.initialEmailTemplateLoad,
        totalEmailTemplatePages: state.totalEmailTemplatePages,
        emailTemplateTPage: state.emailTemplateTPage,

        edocTemplates: state.edocTemplates,
        edocTemplatePageLimit: state.edocTemplatePageLimit,
        edocTemplatePage: state.edocTemplatePage,
        edocTemplateResults: state.edocTemplateResults,
        initialEdocTemplateLoad: state.initialEdocTemplateLoad,
        totalEdocTemplatePages: state.totalEdocTemplatePages,
        edocTemplateTPage: state.edocTemplateTPage,

        smsTemplates: state.smsTemplates,
        smsTemplatePageLimit: state.smsTemplatePageLimit,
        smsTemplatePage: state.smsTemplatePage,
        smsTemplateResults: state.smsTemplateResults,
        initialSmsTemplateLoad: state.initialSmsTemplateLoad,
        totalSmsTemplatePages: state.totalSmsTemplatePages,
        smsTemplateTPage: state.smsTemplateTPage,

        // columnData
        templateColumns: state.templateColumns,
        searchText: state.searchText,
        sortTemplate: state.sortTemplate,

        setAllTemplates: value => {
            dispatch({ type: templateActions.SET_ALL_TEMPLATES, value });
        },
        setEmailTemplates: value => {
            dispatch({ type: templateActions.SET_EMAIL_TEMPLATES, value });
        },
        setEdocTemplates: value => {
            dispatch({ type: templateActions.SET_EDOC_TEMPLATES, value });
        },
        setSmsTemplates: value => {
            dispatch({ type: templateActions.SET_SMS_TEMPLATES, value });
        },

        setInitialAllTemplateLoad: value => {
            dispatch({
                type: templateActions.SET_INITIAL_ALL_TEMPLATE_LOAD,
                value,
            });
        },
        setInitialEmailLoad: value => {
            dispatch({ type: templateActions.SET_INITIAL_EMAIL_LOAD, value });
        },
        setInitialEdocLoad: value => {
            dispatch({ type: templateActions.SET_INITIAL_EDOC_LOAD, value });
        },
        setInitialSmsLoad: value => {
            dispatch({ type: templateActions.SET_INITIAL_SMS_LOAD, value });
        },

        setTotalAllTemplatePages: value => {
            dispatch({
                type: templateActions.SET_TOTAL_ALL_TEMPLATE_PAGES,
                value,
            });
        },
        setTotalEmailTemplatePages: value => {
            dispatch({
                type: templateActions.SET_TOTAL_EMAIL_TEMPLATE_PAGES,
                value,
            });
        },
        setTotalEdocTemplatePages: value => {
            dispatch({
                type: templateActions.SET_TOTAL_EDOC_TEMPLATE_PAGES,
                value,
            });
        },
        setTotalSmsTemplatePages: value => {
            dispatch({
                type: templateActions.SET_TOTAL_SMS_TEMPLATE_PAGES,
                value,
            });
        },

        setAllTemplatePage: value => {
            dispatch({
                type: templateActions.SET_ALL_TEMPLATE_PAGE,
                value,
            });
        },
        setEmailTemplatePage: value => {
            dispatch({
                type: templateActions.SET_EMAIL_TEMPLATE_PAGE,
                value,
            });
        },
        setSmsTemplatePage: value => {
            dispatch({
                type: templateActions.SET_SMS_TEMPLATE_PAGE,
                value,
            });
        },
        setEdocTemplatePage: value => {
            dispatch({
                type: templateActions.SET_EDOC_TEMPLATE_PAGE,
                value,
            });
        },

        setAllTemplatesResults: value => {
            dispatch({
                type: templateActions.SET_ALL_TEMPLATES_RESULTS,
                value,
            });
        },
        setEmailTemplatesResults: value => {
            dispatch({
                type: templateActions.SET_EMAIL_TEMPLATES_RESULTS,
                value,
            });
        },
        setEdocTemplatesResults: value => {
            dispatch({
                type: templateActions.SET_EDOC_TEMPLATES_RESULTS,
                value,
            });
        },
        setSmsTemplatesResults: value => {
            dispatch({
                type: templateActions.SET_SMS_TEMPLATES_RESULTS,
                value,
            });
        },

        setAllTemplatePageLimit: value => {
            dispatch({
                type: templateActions.SET_ALL_TEMPLATE_PAGE_LIMIT,
                value,
            });
        },
        setEmailTemplatePageLimit: value => {
            dispatch({
                type: templateActions.SET_EMAIL_TEMPLATE_PAGE_LIMIT,
                value,
            });
        },
        setSmsTemplatePageLimit: value => {
            dispatch({
                type: templateActions.SET_SMS_TEMPLATE_PAGE_LIMIT,
                value,
            });
        },
        setEdocTemplatePageLimit: value => {
            dispatch({
                type: templateActions.SET_EDOC_TEMPLATE_PAGE_LIMIT,
                value,
            });
        },

        setAllTemplateTPage: value => {
            dispatch({
                type: templateActions.SET_ALL_TEMPLATE_T_PAGE,
                value,
            });
        },

        setEmailTemplateTPage: value => {
            dispatch({
                type: templateActions.SET_EMAIL_TEMPLATE_T_PAGE,
                value,
            });
        },
        setEdocTemplateTPage: value => {
            dispatch({
                type: templateActions.SET_EDOC_TEMPLATE_T_PAGE,
                value,
            });
        },
        setSmsTemplateTPage: value => {
            dispatch({
                type: templateActions.SET_SMS_TEMPLATE_T_PAGE,
                value,
            });
        },
        setTemplateColumns: value => {
            dispatch({
                type: templateActions.SET_TEMPLATE_COLUMNS,
                value,
            });
        },

        setSearchText: value => {
            dispatch({
                type: templateActions.SET_SEARCH_TEMPLATE,
                value,
            });
        },
        setSortTemplate: value => {
            dispatch({
                type: templateActions.SET_SORT_TEMPLATE,
                value,
            });
        },
    };

    return <templateContext.Provider value={value}>{props.children}</templateContext.Provider>;
};

export default TemplateContextProvider;
