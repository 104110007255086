import React from 'react';

import { SVGIcon } from '../../UIKit/index';

export default function DCWarning(props) {
    return (
        <SVGIcon {...props} viewBox="0 0 512 462">
            <g transform="translate(0.122 0.1)">
                <g transform="translate(0 0)">
                    <g>
                        <path d="M503.79,371.06L307.96,31.74c-16.65-28.83-53.52-38.7-82.35-22.04     c-9.15,5.29-16.75,12.89-22.04,22.04l-196,339.32c-16.63,28.84-6.74,65.7,22.1,82.34c9.16,5.28,19.54,8.06,30.11,8.06h391.83     c33.29-0.01,60.28-27,60.27-60.29c0-10.57-2.78-20.95-8.06-30.11H503.79z M477.8,416.33c-5.35,9.41-15.37,15.19-26.19,15.13     H59.75c-16.7,0.01-30.24-13.53-30.25-30.23c0-5.32,1.4-10.54,4.06-15.14L229.49,46.75c8.35-14.46,26.84-19.42,41.31-11.08     c4.6,2.66,8.42,6.48,11.08,11.08L477.8,386.09C483.26,395.43,483.26,406.99,477.8,416.33z" />
                    </g>
                </g>
                <g transform="translate(9.774 6.071)">
                    <g>
                        <rect x="230.9" y="145.39" width="30.02" height="150.16" />
                    </g>
                </g>
                <g transform="translate(9.571 13.37)">
                    <g transform="translate(0 0)">
                        <path d="M246.11,318.27c-11.06,0-20.02,8.96-20.02,20.02c0,11.06,8.96,20.02,20.02,20.02     c11.06,0,20.02-8.96,20.02-20.02C266.11,327.24,257.16,318.28,246.11,318.27z" />
                    </g>
                </g>
            </g>
        </SVGIcon>
    );
}
