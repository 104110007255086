import React from 'react';

import { SVGIcon } from 'UIKit/index';
import Contacts from 'Assets/images/AppLogo/contacts.svg';

const backgroundStyle = {
    backgroundImage: 'url(' + Contacts + ')',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
};
export default function DCContacts(props) {
    return <SVGIcon {...props} viewBox="0 0 2000 2000" style={backgroundStyle} />;
}
