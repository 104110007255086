import React from 'react';

import { SVGIcon } from '../../UIKit/index';

export default function DCHot(props) {
    return (
        <SVGIcon {...props} viewBox="0 0 19.266 29.082">
            <g transform="translate(-86.406 0)">
                <path
                    d="M104.823,15.34c-.345-.8-.818-1.715-1.318-2.685a34.391,34.391,0,0,1-2.663-6.1c-.63-2.235-.518-3.946.343-5.231a.852.852,0,0,0-.908-1.3A10.145,10.145,0,0,0,94.466,4.1,6.8,6.8,0,0,0,93.5,7.5a4.547,4.547,0,0,0-1.748-.886.852.852,0,0,0-1.008.947c.3,2.279-.725,3.791-1.912,5.542a21.383,21.383,0,0,0-1.612,2.655c-1.266,2.6-1.06,5.39.611,8.3a10.005,10.005,0,0,0,8.655,5.03,9.819,9.819,0,0,0,2.538-.334c.065-.017.13-.034.194-.054a8.885,8.885,0,0,0,5.512-4.849,10.573,10.573,0,0,0,.09-8.506ZM93.432,26.162a3.791,3.791,0,0,1-.918-2.959c.126-1.1,1.095-2.371,2.031-3.6a11.4,11.4,0,0,0,2.325-4.082c.907,1.165,1.732,3.237.6,6.537a.852.852,0,0,0,.9,1.123,2.754,2.754,0,0,0,1.163-.451,4.764,4.764,0,0,1-.581,3.19,3.081,3.081,0,0,1-2.631,1.456,8.161,8.161,0,0,1-.824-.058,3.6,3.6,0,0,1-2.069-1.156Zm9.756-3.032a7.342,7.342,0,0,1-2.377,2.906,6.954,6.954,0,0,0-.2-5.587.852.852,0,0,0-.881-.479l-.051.008a7.6,7.6,0,0,0-1.05-4.908,6.7,6.7,0,0,0-2.36-2.289.852.852,0,0,0-1.17,1.1c.606,1.4-.615,3-1.907,4.692-1.076,1.412-2.189,2.872-2.369,4.438a5.574,5.574,0,0,0,.218,2.308A8.332,8.332,0,0,1,89.312,23.2c-1.392-2.422-1.574-4.615-.557-6.7a20.061,20.061,0,0,1,1.491-2.445,10.428,10.428,0,0,0,2.241-5.193,10.519,10.519,0,0,1,1.5,1.438.852.852,0,0,0,1.475-.766,5.774,5.774,0,0,1,.459-4.556,7.206,7.206,0,0,1,2.985-2.606A9.372,9.372,0,0,0,99.2,7.02a35.649,35.649,0,0,0,2.789,6.417c.486.943.946,1.833,1.268,2.579a8.845,8.845,0,0,1-.072,7.114Z"
                    transform="translate(0 0)"
                />
            </g>
        </SVGIcon>
    );
}
