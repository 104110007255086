import React from 'react';
import PropTypes from 'prop-types';
import DCArrowNext from '../../Assets/icons/DCArrowNext';
import { Div } from '../index';

import './style.scss';

const TabWithIconTitle = ({ active, customClass, onClick, title, icon }) => {
    return (
        <Div
            className={
                'TabWithIconTitle ' +
                (active === true ? 'active ' : '') +
                (customClass ? customClass : '')
            }
            onClick={onClick}
        >
            <Div className="TWITIcon">{icon}</Div>
            <Div className="TWITTitle">{title}</Div>
            <Div className="TWITArw">
                <DCArrowNext />
            </Div>
        </Div>
    );
};

TabWithIconTitle.propTypes = {
    active: PropTypes.bool,
    customClass: PropTypes.string,
    onClick: PropTypes.func,
    title: PropTypes.string,
    icon: PropTypes.element,
};

export default TabWithIconTitle;
// <TabWithTitleNumber
//      title={"title"}
//      number={"10"}
//      active={true}
//      customClass={"any class name"}
//      onClick={handle onClick}
// />
