import React from 'react';
import { Div, Image } from 'UIKit/index';

import DCAgency from 'Assets/icons/DCAgency';
import DCAnalytics from 'Assets/icons/DCAnalytics';
import DCContacts from 'Assets/icons/DCContacts';
import DCDashboardMenu from 'Assets/icons/DCDashboardMenu';
import DCDealMenu from 'Assets/icons/DCDealMenu';
import DCForms from 'Assets/icons/DCForms';
import DCInbound from 'Assets/icons/DCInbound';

import DCTemplates from 'Assets/icons/DCTemplates';
import TestView from 'Assets/images/default.jpeg';

const RightSideLogin = ({ image, hideFloatingApps }) => {
    return (
        <Div className="LPIAppsIcons">
            <Div className="LPIAIInnner">
                {image ? (
                    <Div className="LPIAIIScreen LPIAIISFull">
                        <Image src={image} />
                    </Div>
                ) : (
                    <Div className="LPIAIIScreen LPIAIISFull">
                        <Image src={TestView} alt={''} className="LPIAIISWVImg" />
                    </Div>
                )}
                {!hideFloatingApps && (
                    <Div className="LPIAIIApps">
                        <Div className="LPIAIIAIcon LPIAIIAI1">
                            <DCAgency />
                        </Div>
                        <Div className="LPIAIIAIcon LPIAIIAI2">
                            <DCAnalytics />
                        </Div>
                        <Div className="LPIAIIAIcon LPIAIIAI3">
                            <DCContacts />
                        </Div>
                        <Div className="LPIAIIAIcon LPIAIIAI4">
                            <DCDashboardMenu />
                        </Div>
                        <Div className="LPIAIIAIcon LPIAIIAI5">
                            <DCDealMenu />
                        </Div>
                        <Div className="LPIAIIAIcon LPIAIIAI6">
                            <DCForms />
                        </Div>
                        <Div className="LPIAIIAIcon LPIAIIAI7">
                            <DCInbound />
                        </Div>

                        <Div className="LPIAIIAIcon LPIAIIAI12">
                            <DCTemplates />
                        </Div>
                    </Div>
                )}
            </Div>
        </Div>
    );
};

export default RightSideLogin;
