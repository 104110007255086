import React from 'react';

import { SVGIcon } from '../../UIKit/index';

export default function DCReminderBlue(props) {
    return (
        <SVGIcon {...props} viewBox="0 0 501 512">
            <circle cx="412" cy="202.9" r="75.3" fill="#D2E6FD" />
            <g>
                <path
                    d="M506.8,202.9c0-52.2-42.4-94.6-94.6-94.6c-11.6,0-22.7,2.2-32.9,6C352.7,82.4,316.7,60.9,277,52.4v-8.9
					c0-24-19.5-43.5-43.5-43.5s-43.5,19.5-43.5,43.5V55c-3,0.8-6,1.7-8.9,2.7c-73.2,24.1-126.3,93-126.3,174.2v144.9H50
					c-24,0-43.5,19.5-43.5,43.5c0,24,19.5,43.5,43.5,43.5h132.2c6.5,28.1,31.4,48.3,61.1,48.3s54.5-20.2,61.1-48.3h122.5
					c24,0,43.5-19.5,43.5-43.5c0-24-19.5-43.5-43.5-43.5h-4.8V297C469.5,292.1,506.8,251.8,506.8,202.9z M209.4,43.5
					c0-13.3,10.8-24.2,24.2-24.2c13.3,0,24.2,10.8,24.2,24.2v5.9c-6.4-0.7-12.8-1-19.3-1c-9.9,0-19.5,1-29,2.5V43.5z M243.2,492.7
					c-18.9,0-34.9-11.9-40.9-29h81.7C278.1,480.7,262.1,492.7,243.2,492.7z M412.3,278.1c-41.5,0-75.3-33.8-75.3-75.3
					c0-41.5,33.8-75.3,75.3-75.3s75.3,33.8,75.3,75.3C487.5,244.4,453.8,278.1,412.3,278.1z"
                    fill="#0054F8"
                />
                <path
                    d="M386.4,238.4c2.5,0,4.9-0.9,6.8-2.8l25.8-25.8c1.8-1.8,2.8-4.3,2.8-6.8v-36.9c0-5.3-4.3-9.7-9.7-9.7
					c-5.3,0-9.7,4.3-9.7,9.7v32.9l-23,23c-3.8,3.8-3.8,9.9,0,13.7C381.5,237.4,383.9,238.4,386.4,238.4z"
                    fill="#0054F8"
                />
                <path
                    d="M426.8,396.1c13.3,0,24.2,10.8,24.2,24.2s-10.8,24.2-24.2,24.2H50c-13.3,0-24.2-10.8-24.2-24.2
					s10.8-24.2,24.2-24.2h14.5c5.3,0,9.7-4.3,9.7-9.7V231.8c0-90.6,73.7-164.2,164.2-164.2c47.4,0,91.7,20.3,122.9,55.7
					c-26.2,16.8-43.6,46.2-43.6,79.6c0,48.9,37.3,89.2,84.9,94.1v89.5c0,5.3,4.3,9.7,9.7,9.7H426.8z"
                    fill="#1A76FA"
                />
                <path
                    d="M267.4,376.8H248c-5.3,0-9.7,4.3-9.7,9.7c0,5.3,4.3,9.7,9.7,9.7h19.3c5.3,0,9.7-4.3,9.7-9.7
					C277,381.1,272.7,376.8,267.4,376.8z"
                    fill="#D2E6FD"
                />
                <path
                    d="M212.6,376.8h-99.8c-5.3,0-9.7,4.3-9.7,9.7c0,5.3,4.3,9.7,9.7,9.7h99.8c5.3,0,9.7-4.3,9.7-9.7
					C222.3,381.1,217.9,376.8,212.6,376.8z"
                    fill="#D2E6FD"
                />
                <path
                    d="M238.4,156.3c0,62.7-50.8,113.5-113.5,113.5c-18.2,0-35.4-4.3-50.7-11.9v-26c0-77.7,54.2-142.9,126.7-159.9
					C223.9,92.7,238.4,122.8,238.4,156.3z"
                    fill="#D2E6FD"
                />
            </g>
        </SVGIcon>
    );
}
