import React, { useCallback, useState } from 'react';

import DCCrossClose from 'Assets/icons/DCCrossClose';
import {
    Dialog,
    Div,
    H1,
    IconButton,
    TopBar,
    FieldControl,
    UserInfo,
    LabelControl,
    Label,
    Input,
    Textarea,
    Button,
} from 'UIKit/index';
import { sendEmailToBusiness } from 'apis/instareports/index.api';
import { useRouteMatch } from 'react-router-dom/cjs/react-router-dom.min';
import { Validation } from 'Utils/validator';
import useToast from 'Modules/Toasts';
import generateImage from 'Utils/generateImage';

const GetInTouch = ({ open, onClose, user, preview }) => {
    const { showToast } = useToast();
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');
    const [loading, setLoading] = useState(false);
    const route = useRouteMatch();

    const handleClose = useCallback(() => {
        onClose();
        setEmail('');
        setFirstName('');
        setLastName('');
        setMessage('');
        setLoading(false);
    }, [onClose]);

    const handleSendBtn = useCallback(() => {
        if (firstName.length === 0) {
            showToast({ type: 'error', message: 'First name cannot be empty.' });
            return;
        }
        if (email.length === 0 || !Validation('email', email)) {
            showToast({ type: 'error', message: 'Invalid Email address.' });
            return;
        }
        if (message?.length === 0) {
            showToast({ type: 'error', message: 'Message cannot be empty.' });
            return;
        }
        if (preview) {
            showToast({ type: 'warning', message: 'This feature is disabled in preview mode.' });
            return;
        }
        setLoading(true);
        sendEmailToBusiness(route.params.id, firstName, lastName, email, message)
            .then(res => {
                if (res?.success) {
                    showToast({ type: 'success', message: res?.message });
                    handleClose();
                } else {
                    showToast({ type: 'error', message: res?.message || 'Something went wrong!' });
                }
            })
            .catch(err => {
                showToast({
                    type: 'error',
                    message: err.message || err || 'Something went wrong!',
                });
            })
            .finally(() => {
                setLoading(false);
            });
    }, [email, firstName, lastName, message, handleClose, preview, route, showToast]);

    return (
        <Dialog
            dialogType={'centerZoom sizeSm'}
            classes={{ row: 'GetInTouchRow' }}
            open={open}
            onClose={handleClose}
        >
            <Div className={'GetInTouch'}>
                <TopBar>
                    <Div className={'HeaderBar'}>
                        <H1 className={'HeaderBarTitle'}>Contact Us</H1>
                        <Div className={'HeaderBarClose'}>
                            <IconButton onClick={handleClose}>
                                <DCCrossClose />
                            </IconButton>
                        </Div>
                    </Div>
                </TopBar>
                <Div className={'BodyBox'}>
                    <Div className={'BodyBoxInner'}>
                        <FieldControl>
                            <UserInfo
                                name={`${user?.first_name} at ${user?.businessName}`}
                                email={user?.email}
                                image={generateImage(user?.image, user?.name, false, user?.email)}
                            />
                        </FieldControl>
                        <FieldControl>
                            <LabelControl>
                                <Label props={{ htmlFor: 'FirstName' }} required>
                                    First Name
                                </Label>
                            </LabelControl>
                            <Input
                                id={'FirstName'}
                                value={firstName}
                                onChange={e => setFirstName(e.target.value)}
                            />
                        </FieldControl>
                        <FieldControl>
                            <LabelControl>
                                <Label props={{ htmlFor: 'LastName' }}>Last Name</Label>
                            </LabelControl>
                            <Input
                                id={'LastName'}
                                value={lastName}
                                onChange={e => setLastName(e.target.value)}
                            />
                        </FieldControl>
                        <FieldControl>
                            <LabelControl>
                                <Label props={{ htmlFor: 'Email' }} required>
                                    Email
                                </Label>
                            </LabelControl>
                            <Input
                                id={'Email'}
                                value={email}
                                onChange={e => setEmail(e.target.value)}
                            />
                        </FieldControl>
                        <FieldControl>
                            <LabelControl>
                                <Label props={{ htmlFor: 'Message' }} required>
                                    Message
                                </Label>
                            </LabelControl>
                            <Textarea
                                id={'Message'}
                                value={message}
                                onChange={e => setMessage(e.target.value)}
                            />
                        </FieldControl>
                        <Div className={'GITBtn'}>
                            <Button
                                buttonType={'BlueFillBtn'}
                                onClick={handleSendBtn}
                                loading={loading}
                            >
                                Submit
                            </Button>
                            <Button
                                buttonType={'RedFillBtn'}
                                onClick={handleClose}
                                disabled={loading}
                            >
                                Cancel
                            </Button>
                        </Div>
                    </Div>
                </Div>
            </Div>
        </Dialog>
    );
};

export default GetInTouch;
