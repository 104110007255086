import React from 'react';

import { SVGIcon } from '../../UIKit/index';

export default function DCOrderConfirmation(props) {
    return (
        <SVGIcon {...props} viewBox="0 0 340.049 305.721">
            <g transform="translate(-1217.378 -266.41)">
                <path
                    d="M140.5,0c19.4,0,37.88.82,54.689,2.3a390.987,390.987,0,0,1,44.66,6.282c12.713,2.652,23,5.811,30.111,9.318S281,25.266,281,29.313s-3.932,7.9-11.041,11.41-17.4,6.665-30.111,9.318a390.987,390.987,0,0,1-44.66,6.282c-16.809,1.483-35.29,2.3-54.689,2.3s-37.88-.82-54.689-2.3a390.988,390.988,0,0,1-44.66-6.282c-12.713-2.652-23-5.811-30.11-9.318S0,33.361,0,29.313s3.932-7.9,11.041-11.41,17.4-6.665,30.11-9.318A390.988,390.988,0,0,1,85.812,2.3C102.621.82,121.1,0,140.5,0Z"
                    transform="translate(1231.426 513.505)"
                    fill="#f8f8f8"
                />
                <path
                    d="M20795.334,1495.9l-97,59.692h136.053Z"
                    transform="translate(-19350.975 -1200.277)"
                    fill="#f8f8f8"
                />
                <path
                    d="M20821.891,1537.75l-35.406,22.7h54.137l-16.828-24.289Z"
                    transform="translate(-19354.666 -1201.964)"
                    fill="#e8eaf0"
                />
                <path
                    d="M20713.984,1465.41l-70.328,89.219,60.012,1.746,46.354-60.962Z"
                    transform="translate(-19348.684 -1199)"
                    fill="#f1f7ff"
                />
                <path
                    d="M20754.246,1495.9l-46.963,64.06,67.791-46.589Z"
                    transform="translate(-19354.053 -1200.277)"
                    fill="#d9e3f0"
                />
                <rect
                    width="265"
                    height="179"
                    rx="10"
                    transform="translate(1247.428 354.318)"
                    fill="#b8e7ff"
                />
                <g transform="translate(1232.25 430.673)">
                    <g transform="translate(-0.456 0.012)">
                        <g transform="translate(-0.002)">
                            <circle
                                cx="60"
                                cy="60"
                                r="60"
                                transform="translate(-0.366 -0.367)"
                                fill="#32f576"
                            />
                        </g>
                        <path
                            d="M85.268,16.269a3.71,3.71,0,0,0-5.248,0l-33.3,33.307L31.378,34.235a3.711,3.711,0,1,0-5.248,5.247L44.1,57.449a3.711,3.711,0,0,0,5.249,0L85.269,21.517A3.711,3.711,0,0,0,85.268,16.269Z"
                            transform="translate(4.281 22.99)"
                            fill="#fff"
                        />
                    </g>
                </g>
                <rect
                    width="125"
                    height="60"
                    rx="30"
                    transform="translate(1432.428 406.318)"
                    fill="#e9f6ff"
                />
                <ellipse
                    cx="18.5"
                    cy="18"
                    rx="18.5"
                    ry="18"
                    transform="translate(1447.428 418.318)"
                    fill="#00acfd"
                />
                <path
                    d="M20867.824,1500.722l-.723,9.838-7.895,7.393,10.229,1.668,5.332,7.837,2.563-9.838,8.5-6.281-11.062-1.667Z"
                    transform="translate(-19357.713 -1200.479)"
                    fill="#e8eaf0"
                />
                <path
                    d="M20866.4,1500.722l-.6,8.213-6.588,6.171,8.539,1.392,4.451,6.543,2.137-8.213,7.1-5.243-9.234-1.392Z"
                    transform="translate(-19613.246 -1205.936)"
                    fill="#d9e3f0"
                />
                <path
                    d="M3.7,0l-.31,4.228L0,7.405l4.4.717L6.688,11.49l1.1-4.228,3.653-2.7L6.688,3.846Z"
                    transform="translate(1217.378 413.299) rotate(-50)"
                    fill="#f1f7ff"
                />
                <path
                    d="M3.7,0l-.31,4.228L0,7.405l4.4.717L6.688,11.49l1.1-4.228,3.653-2.7L6.688,3.846Z"
                    transform="translate(1523.972 485.338) rotate(-50)"
                    fill="#b8e7ff"
                />
                <rect
                    width="42.157"
                    height="21.078"
                    rx="10.539"
                    transform="translate(1402.081 336.001) rotate(-30)"
                    fill="#e8eaf0"
                />
                <rect
                    width="42.157"
                    height="21.078"
                    rx="10.539"
                    transform="matrix(0.695, -0.719, 0.719, 0.695, 1338.659, 318.724)"
                    fill="#d9e3f0"
                />
                <g transform="translate(-12.081 -21.824)">
                    <path
                        d="M20727.8,1691.5s3.2,15.557-13.707,14.168c0,.363-3.861-4.529-3.861-4.529l6.457-1.087S20715.1,1689.726,20727.8,1691.5Z"
                        transform="translate(-19351.441 -1208.124)"
                        fill="#f9ddc7"
                    />
                    <path
                        d="M20724.262,1731.983s6-1.511,18.555-.506c3.262-1.035,0-5.078,0-5.078s-10.385-3.391-20.17-1.481C20722.807,1725.236,20724.262,1731.983,20724.262,1731.983Z"
                        transform="translate(-19347.66 -1203.204)"
                        fill="#f4d2ba"
                    />
                    <path
                        d="M20727.283,1729.715c-4.217-5.554,3.9-14.485-11.7-19.658,0,0-.949-1.4-2.855-4.434-4.119-.378-13.619,2.921-13.619,2.921l-1.039,18.464,1.916,2.149,21.939,29.8,9.893-12.689,17.461-11.187-16.826-2.81A6.25,6.25,0,0,1,20727.283,1729.715Z"
                        transform="translate(-19350.203 -1205.155)"
                        fill="#00acfd"
                    />
                    <path
                        d="M20807.422,1750.229s-24.232-19.72-36.031-26.024c-2.613-1.412-5.377-3.094-11.162.807s-27.035,15.065-27.035,15.065-9.953,6.187-13.449,16.948c1.885,4.035,16.732,11.44,25.154,4.573,8.2-7.667,21.789-23.808,21.789-23.808l23.674,22.731,5.242-2.556-10.49-14.661,17.951,10.832Z"
                        transform="translate(-19348.102 -1203.452)"
                        fill="#575e60"
                    />
                    <path
                        d="M20708.086,1726.612s-5.281,1.867-4.41,6.324,11.141,14.924,11.141,14.924a3.912,3.912,0,0,0,4.361,0c7.779-3.2,26.291-12.895,26.291-12.895l1.3-4.525s-18.082,4.52-29.461,10.966C20717.469,1741.326,20708.086,1726.612,20708.086,1726.612Z"
                        transform="translate(-19349.66 -1203.004)"
                        fill="#f4d2ba"
                    />
                    <path
                        d="M20714.285,1709.887s-15.209-4.138-15.52,20.59c.311.414-8.379-6.311-5.582-19.451,4.547-11.9,6.928-10.657,6.928-10.657s-3-21.417,10.656-22.763,17.838,7.187,16.389,16.141c.41,0-3.352-.776-5.109,0s-.09,2.675-6.209,8.174c0,0-2.51-2.716-2.9-.724S20714.285,1709.887,20714.285,1709.887Z"
                        transform="translate(-19350.738 -1207.883)"
                        fill="#005a8c"
                    />
                    <path
                        d="M20788.738,1754.3l1.111,1.114,6.572.206s5.652,1.274,2.387,5.571c-4.459,1.512-13.836,0-13.836,0l-1.363-4.34Z"
                        transform="translate(-19341.896 -1200.414)"
                        fill="#414141"
                    />
                    <path
                        d="M20788.738,1754.3l1.111,1.114,6.572.206s5.652,1.274,2.387,5.571c-4.459,1.512-13.836,0-13.836,0l-1.363-4.34Z"
                        transform="matrix(0.927, -0.375, 0.375, 0.927, -18473.512, 6707.317)"
                        fill="#414141"
                    />
                    <path d="M1393.2,522.673l3.984,7.516-1.781,2.031-5.062-8.328Z" fill="#fff" />
                    <g transform="translate(1390.511 509.857)">
                        <path
                            d="M20.643,1.066,22.87.8,21.682,2.677l2.237-.262L22.728,4.3l2.247-.26L23.781,5.934l2.258-.258-1.2,1.9,2.268-.256-1.2,1.915L4.88,22.381l1.238-1.94-2.229.231,1.236-1.929-2.219.234,1.233-1.919-2.209.236,1.23-1.909-2.2.238,1.227-1.9L0,13.965Z"
                            fill="#fff"
                        />
                        <path
                            d="M1.722,13.02.489,14.939l2.219-.234L1.472,16.634,3.7,16.4l-1.238,1.94L23.495,5.2l1.2-1.915-2.268.256,1.2-1.9-2.258.258L22.558,0Z"
                            transform="translate(2.417 4.039)"
                            fill="#fff"
                        />
                        <path
                            d="M4.983,6.771a4.857,4.857,0,0,0,.86-.679,3.566,3.566,0,0,0,.584-.752,1.95,1.95,0,0,0,.257-.733.976.976,0,0,0-.121-.624l-.25-.413a.193.193,0,0,1-.024-.123A.385.385,0,0,1,6.339,3.3a.7.7,0,0,1,.115-.148.983.983,0,0,1,.364-.227A.687.687,0,0,1,7,2.889a.37.37,0,0,1,.15.019.188.188,0,0,1,.1.077l.938,1.542,1.9-1.187-.5-.812.822-.514L9.769.977l-.89.556a1.32,1.32,0,0,0-.55-.2,2.448,2.448,0,0,0-.705.012,3.834,3.834,0,0,0-.8.216A4.982,4.982,0,0,0,5.14,2.656a3.557,3.557,0,0,0-.581.746,1.947,1.947,0,0,0-.258.729.974.974,0,0,0,.118.622l.248.414a.194.194,0,0,1,.024.124.388.388,0,0,1-.051.145.708.708,0,0,1-.116.149.963.963,0,0,1-.17.134.951.951,0,0,1-.194.093.682.682,0,0,1-.182.037.367.367,0,0,1-.15-.02.187.187,0,0,1-.1-.078L2.809,4.21.921,5.391,1.4,6.2l-.82.512L1.2,7.769l.892-.558a1.3,1.3,0,0,0,.546.2A2.411,2.411,0,0,0,3.345,7.4a3.8,3.8,0,0,0,.8-.215A4.871,4.871,0,0,0,4.983,6.771Z"
                            transform="translate(4.435 9.074)"
                            fill="#00c8c8"
                        />
                        <g transform="translate(5.327 10.569)">
                            <path
                                d="M.813,3.033l1.9-1.187-.5-.812L3.036.52,2.715,0,0,1.7Z"
                                transform="translate(6.482)"
                                fill="#1cadb5"
                            />
                            <path
                                d="M5.671.206,5.546,0,3.652,1.184l.124.207a.194.194,0,0,1,.024.124.388.388,0,0,1-.051.145.708.708,0,0,1-.116.149.963.963,0,0,1-.17.134.951.951,0,0,1-.195.093.682.682,0,0,1-.182.037.367.367,0,0,1-.15-.02.187.187,0,0,1-.1-.078l-.123-.207L0,3.465l.31.526L1.2,3.434a1.3,1.3,0,0,0,.546.2,2.411,2.411,0,0,0,.705-.008,3.8,3.8,0,0,0,.8-.215,4.871,4.871,0,0,0,.836-.419,4.858,4.858,0,0,0,.86-.679,3.566,3.566,0,0,0,.584-.752A1.95,1.95,0,0,0,5.791.83.976.976,0,0,0,5.671.206Z"
                                transform="translate(0 2.282)"
                                fill="#1cadb5"
                            />
                        </g>
                        <path
                            d="M1.594,0,5.5,6.127,3.879,7.137,0,1Z"
                            transform="translate(17.249 3.618)"
                            fill="#cdd2e1"
                        />
                        <path
                            d="M1.605,0,3.569,3.084,1.953,4.094,0,1Z"
                            transform="translate(19.175 6.66)"
                            fill="#bec3d2"
                        />
                        <path
                            d="M1.594,0l3.86,6.152L3.837,7.163,0,1Z"
                            transform="translate(14.299 5.461)"
                            fill="#cdd2e1"
                        />
                        <path
                            d="M1.605,0,3.549,3.1,1.932,4.108,0,1Z"
                            transform="translate(16.204 8.516)"
                            fill="#bec3d2"
                        />
                    </g>
                </g>
            </g>
        </SVGIcon>
    );
}
