import React from 'react';

import { SVGIcon } from '../../UIKit/index';

export default function DCGrayNoFound(props) {
    return (
        <SVGIcon {...props} viewBox="0 0 468.73 498.6">
            <g id="Artboard_1" transform="translate(-22.65 -6.99)">
                <circle
                    id="Ellipse_1"
                    cx="227.6"
                    cy="227.6"
                    r="227.6"
                    transform="translate(24.15 8.49)"
                    fill="#fff"
                    stroke="#707171"
                    strokeMiterlimit="10"
                    strokeWidth="3"
                />
                <circle
                    id="Ellipse_2"
                    cx="11.8"
                    cy="11.8"
                    r="11.8"
                    transform="translate(171.93 57.01)"
                    fill="#fff"
                    stroke="#e2e2e2"
                    strokeMiterlimit="10"
                    strokeWidth="2"
                />
                <circle
                    id="Ellipse_3"
                    cx="5.38"
                    cy="5.38"
                    r="5.38"
                    transform="translate(124.81 90.07)"
                    fill="#fff"
                    stroke="#e2e2e2"
                    strokeMiterlimit="10"
                    strokeWidth="2"
                />
                <path
                    id="Path_1"
                    d="M76.71,290.65H68.29v-8.41a1,1,0,0,0-1.05-1.05h0a1.07,1.07,0,0,0-1.07,1.05v8.41h-8.4a1.06,1.06,0,1,0,0,2.12h8.4v8.42a1.07,1.07,0,0,0,1.07,1.05,1,1,0,0,0,1.05-1.05h0v-8.42h8.42a1.06,1.06,0,0,0,1.06-1,1.08,1.08,0,0,0-1.06-1.12Z"
                    fill="#e2e2e2"
                    stroke="#e2e2e2"
                    strokeMiterlimit="10"
                    strokeWidth="0.75"
                />
                <path
                    id="Path_2"
                    d="M376.9,89.22h-6.76V82.47a.84.84,0,0,0-.84-.84h0a.86.86,0,0,0-.86.84v6.75H361.7a.86.86,0,0,0-.85.86.84.84,0,0,0,.85.84h6.74v6.76a.86.86,0,0,0,.86.85.85.85,0,0,0,.84-.85h0V90.92h6.76a.84.84,0,0,0,.85-.84.86.86,0,0,0-.85-.86Z"
                    fill="#e2e2e2"
                    stroke="#e2e2e2"
                    strokeMiterlimit="10"
                    strokeWidth="0.75"
                />
                <path
                    id="Path_3"
                    d="M462,198h-6.64v-6.64a.83.83,0,0,0-.83-.82h0a.83.83,0,0,0-.84.82V198H447a.85.85,0,0,0-.84.85.84.84,0,0,0,.84.82h6.63v6.65a.835.835,0,0,0,1.67,0h0v-6.65H462a.84.84,0,1,0,0-1.67Z"
                    fill="#e2e2e2"
                    stroke="#e2e2e2"
                    strokeMiterlimit="10"
                    strokeWidth="0.75"
                />
                <ellipse
                    id="_Path_"
                    cx="154.22"
                    cy="15.14"
                    rx="154.22"
                    ry="15.14"
                    transform="translate(98.11 475.31)"
                    fill="#f4f5f6"
                />
                <path
                    id="Path_4"
                    d="M398.47,210.92A63.37,63.37,0,0,1,275.7,193.58a62.359,62.359,0,0,1,.08-9.66,63.06,63.06,0,0,1,3.6-16.29,64,64,0,0,1,5.95-12.18l.09-.13a63.7,63.7,0,0,1,36.11-27c1.55-.44,3.12-.82,4.7-1.15a63,63,0,0,1,34.34,2.54q2.28.83,4.44,1.8a62.9,62.9,0,0,1,25.68,21.2h0a63.259,63.259,0,0,1,7.68,14.63,61.648,61.648,0,0,1,2.45,8.36q.59,2.66.94,5.37a62.769,62.769,0,0,1,.33,13.23A63.639,63.639,0,0,1,398.47,210.92Z"
                    fill="#f4f5f6"
                />
                <path
                    id="Path_5"
                    d="M328.38,155.27c-3.74,2.9-2.75,10.65-9.23,6.28s-10.55-6.75-12.38-2.93,3.9,5.9,2.39,10.06-4.58,4.6-7.83,1.18-3.33-6.7-8.59-6.59-7.49-2.05-7.23-6a5.5,5.5,0,0,0-.18-1.83l.09-.13a63.7,63.7,0,0,1,36.11-27c1.55-.44,3.12-.82,4.7-1.15a8.37,8.37,0,0,1,4.84,5.29c2.28,6.66,6,5.09,2.84,10.67s-1.85,6.95,1.59,8,7.4,2.46,6.86,5.18S332.13,152.37,328.38,155.27Z"
                    fill="#e3e2e3"
                />
                <path
                    id="Path_6"
                    d="M398.47,210.92a63.06,63.06,0,0,1-22.56,29.81,7.31,7.31,0,0,1-4.72-1.86c-6.34-5.67,2.07-11.69,6.5-14.62s-3.8-3.91-7.94-9.62,5.56-13.44-.68-13.86-13.83-.82-15.1-7.51,5.3-5.3,4.15-10.93,4.28-6.69,10.08-5.08,6.52-2.67,13.72-.73,3.81,10.8,11.75,13,6.94,3.87,6.94,3.87a3.83,3.83,0,0,1,1.49.93,63.639,63.639,0,0,1-3.63,16.6Z"
                    fill="#e3e2e3"
                />
                <path
                    id="Path_7"
                    d="M401.77,181.1c-7.56.79-7-8.65-13.46-8.09s-3.71-4-6.91-8.79-14-2.17-11.81-7,8.23-1,11.05-4.5,8.81-.16,8.81-.16a4.43,4.43,0,0,1,1.24.15h0a63.259,63.259,0,0,1,7.68,14.63,61.648,61.648,0,0,1,2.45,8.36Q401.42,178.39,401.77,181.1Z"
                    fill="#e3e2e3"
                />
                <path
                    id="Path_8"
                    d="M353.87,144.35s5.14-3.63.31-7-5.41-1.46-9.41-3.41-6.8.66-5,5.31,1.92,12.31,6.05,9.75S350.43,143.49,353.87,144.35Z"
                    fill="#e3e2e3"
                />
                <path
                    id="Path_9"
                    d="M409.33,398.13H388.8c-1.1-.94.76-3.06,2.71-3.2a12.51,12.51,0,0,1,3.13.37c.28-3.75,1.9-20.61,8-19.94,6.83.76,0,7.43-1.94,11.38a93.709,93.709,0,0,0-3.63,9.1l1.72.34c.9-2.78,4.28-12.14,8.56-11.59,5,.64,3,4.39-.55,6.62-2.62,1.63-5.84,4-7.36,5.09a22,22,0,0,0,4,.32C407,396.53,408.66,397.57,409.33,398.13Z"
                    fill="#e3e2e3"
                />
                <path
                    id="Path_10"
                    d="M424,263.58s.49-4.86-2.05-4.69-3.22,1.33-2.93,2.26a26.09,26.09,0,0,0,2.75,4Z"
                    fill="#e3e2e3"
                />
                <path
                    id="Path_11"
                    d="M463.58,273.32a41.6,41.6,0,0,1-8.86,7.12c-.58.36-1.17.72-1.81,1.09a66.663,66.663,0,0,1-8.28,4.08l-1.23.47c-3.62,1.3-10.29,3.16-14.43,3.33h-1c-6.29-.25-7.06-24.67-7.06-24.67s.7-2.44,3.93-1.83c0,0,4.91,13.79,6.67,14.91,1.56,1,10.54-12.61,18.27-13.56C455,263.62,463.58,273.32,463.58,273.32Z"
                    fill="#707171"
                />
                <path
                    id="Path_12"
                    d="M446.68,392.39s.46,5.37-.67,5.93H431.47s0-1.68,2.33-2.05,6.44-1.74,7.13-4.79S446.68,392.39,446.68,392.39Z"
                    fill="#707171"
                />
                <path
                    id="Path_13"
                    d="M436,327.33c.08,3.52.24,7.14.58,10.4,1.57,15.46,2.12,34.14,2.34,37.74.39,6.25,1.63,16.24,1.63,16.24s2.45,2.33,7.22,1.22a162.281,162.281,0,0,0,4.84-29.54v-.74c.11-2,.21-4.3.32-6.76.73-16.54,1.52-41.11,1.52-41.11L436.2,310.5S435.82,318.57,436,327.33Z"
                    fill="#707171"
                />
                <path
                    id="Path_14"
                    d="M436,327.33c4.63,8,13.44,22.68,17,28.56.73-16.54,1.52-41.11,1.52-41.11l-18.25-4.28S435.82,318.57,436,327.33Z"
                    fill="#fff"
                    opacity="0.3"
                />
                <path
                    id="Path_15"
                    d="M443.4,286.08c-3.62,1.3-10.29,3.16-14.43,3.33.64-.12,5.24-1,6.94-3.09s4.91-8.21,7.36-5.76C444.42,281.71,444.08,284,443.4,286.08Z"
                    fill="#fff"
                    opacity="0.3"
                />
                <path
                    id="Path_16"
                    d="M474.06,324.73s1.13,4.44.95,5.43a19.521,19.521,0,0,1-2,5.16c-.69.83-.72-3.61-1.18-4.3s-.21,1.65-.57,1.85-1-1.79-1.26-2.48,1-3.14,1.1-4.89S474.06,324.73,474.06,324.73Z"
                    fill="#e3e2e3"
                />
                <path
                    id="Path_17"
                    d="M482.9,386.28s2.59,2.36,2.08,4-2.84,2.79-3.15,4.84-1.58,3.17-2.72,3.17H473.7s-.84-1.44,1.18-1.87,2.78-2.85,2.66-5.09a39.544,39.544,0,0,1,.18-5.22l4.3-1Z"
                    fill="#5d5e5e"
                />
                <path
                    id="Path_18"
                    d="M439.12,315.15s17.08,36.55,26.17,52.58,11.93,20.52,11.93,20.52,5.21-2.06,5.68-3.45c0,0-3.48-31-9.56-38.51-3.44-4.26-7.48-17.06-7.67-21.53s-4.18-15.16-4.18-15.16Z"
                    fill="#5d5e5e"
                />
                <path
                    id="Path_19"
                    d="M435.94,313.25a29.59,29.59,0,0,0,15,7.8c4.15.79,12.57,1.56,14.66-1.4.49-.69.49-1.72.38-3.32-.28-4.16-1.33-12.22,3.4-28.4a27.5,27.5,0,0,0,.91-4.33c1-8.28.92-14.53-6.26-17.87-10.76-5-15.18-.88-15.18-.88s-5.73,2.67-8.34,10.47C435.29,290.91,435.94,313.25,435.94,313.25Z"
                    fill="#707171"
                />
                <path
                    id="Path_20"
                    d="M457.58,264.36a8,8,0,0,1-8,.52c-.24-.11-.21-1.72-.15-3.3s.21-3.07.21-3.07l3.83-2.51,3.65-2.4C456.64,261.84,458.4,263.77,457.58,264.36Z"
                    fill="#e3e2e3"
                />
                <path
                    id="Path_21"
                    d="M456.44,257a11.8,11.8,0,0,1-7.05,4.58c.06-1.55.23-3.26.23-3.26l3.81-2.32Z"
                    fill="#707171"
                    opacity="0.3"
                />
                <path
                    id="Path_22"
                    d="M446.17,257.54s9.26,3.46,11.32-2.25,4.65-9.11-1.18-11.56-7.77-.68-8.93,1.06S443.81,256.49,446.17,257.54Z"
                    fill="#e3e2e3"
                />
                <path
                    id="Path_23"
                    d="M463.27,244.37c.1-.08.32-.12.77-.06a1.79,1.79,0,0,0-.79-.39,2.27,2.27,0,0,0-1.1-2.28,1.33,1.33,0,0,1,.46,1.09c-1-1.52-3.45-3-10.9-3.83-9.83-1.15-7.86,5.67-6.76,6.66s4.66-.42,7,.23,2.07,4.1,2.77,4.72.55-.26,1.72-.53.92,1.52.38,2.61.63,2.91.63,2.91l1.81-1.31c1.8-1.31,4.54-7.81,4.15-9.39A1.356,1.356,0,0,1,463.27,244.37Z"
                    fill="#707171"
                />
                <path
                    id="Path_24"
                    d="M466,316.33c-.28-4.16-1.33-12.22,3.4-28.4a27.5,27.5,0,0,0,.91-4.33h0c-3.51-1.16-9.92,1.42-9.92,1.42S458.88,306.41,466,316.33Z"
                    fill="#fff"
                    opacity="0.3"
                />
                <path
                    id="Path_25"
                    d="M466,266.81s4.32,1.58,7.08,13c1.82,7.56,5.18,18.68,5.56,22.18s-3.84,24-3.84,24a2.71,2.71,0,0,1-2,.15,3.39,3.39,0,0,1-2-1.23s-1-13.63-.75-20.07c.11-2.68-12.38-19.59-19.12-21.77C448.17,282.19,466,266.81,466,266.81Z"
                    fill="#707171"
                />
                <path
                    id="Path_26"
                    d="M405.48,252a144.46,144.46,0,0,1,26.93,2.3l1.51-3.8,1.65-4.16s-14.48-6.23-23.19-14.91Z"
                    fill="#e3e2e3"
                />
                <path
                    id="Path_27"
                    d="M412.58,231.21,405.4,252.4h0a1.92,1.92,0,0,1-1.26-2.47l6.32-17.8a1.59,1.59,0,0,1,2.12-.92Z"
                    fill="#707171"
                />
                <path
                    id="Path_28"
                    d="M419.43,267.76h0a2.58,2.58,0,0,0,3-2L425,253.37a2.61,2.61,0,0,0-2-3.05h0a2.6,2.6,0,0,0-3.05,2l-2.47,12.37a2.59,2.59,0,0,0,1.95,3.07Z"
                    fill="#707171"
                />
                <path
                    id="Path_29"
                    d="M405.48,252a144.46,144.46,0,0,1,26.93,2.3l1.51-3.8C420.36,247.73,405.48,252,405.48,252Z"
                    fill="#d3d1d3"
                />
                <path
                    id="Path_30"
                    d="M428.42,253.53l3.91.68c-.06,0,3.62.72,4.83-2.55a2.628,2.628,0,0,0,.24-.7c.93-4.08-1.3-4.83-1.3-4.83l-5-2-1.6,5.66h0Z"
                    fill="#707171"
                />
                <path
                    id="Path_31"
                    d="M428.42,253.53l3.91.68c-.06,0,3.62.72,4.83-2.55-2.21-1.6-7.69-1.83-7.69-1.83h0Z"
                    fill="#fff"
                    opacity="0.3"
                />
                <path
                    id="Path_32"
                    d="M419.76,255.79s1.91.76,1.56,1.6-2.78,2-3.16,1.33S418.56,255.08,419.76,255.79Z"
                    fill="#e3e2e3"
                />
                <path
                    id="Path_33"
                    d="M424,262.81a28.267,28.267,0,0,0,.39-2.85c0-.9.13-1.63-.09-1.66s-.36.14-.45.43-.41,2.08-.41,2.08l.16,1.73Z"
                    fill="#e3e2e3"
                />
                <path
                    id="Path_34"
                    d="M235,261.54,224.2,390.26s-1.13,7.8,4.67,7.82h47.5s-7.18-1.63-7.1-5.28c.11-4.31,12.66-132.48,12.66-132.48Z"
                    fill="#5d5e5e"
                />
                <path
                    id="Path_35"
                    d="M279.94,395.79c-1.88,0-38.62.07-47.92-.05-4.05-.05-4.76-3.6-4.79-5.9a11.218,11.218,0,0,1,.12-1.91l.29-3.35.71-8.53.64-7.53.81-9.68.69-8.17,7.69-91.47,46.9-1.22s-5.29,53.64-9,92.69c-.42,4.35-.82,8.52-1.19,12.41-.12,1.25-.23,2.46-.34,3.65-.35,3.69-.66,7.08-.94,10.08-.15,1.58-.28,3-.4,4.39-.48,5.26-.76,8.62-.76,9.26a5.787,5.787,0,0,0,.09,1.05C273.29,395.61,279.24,395.79,279.94,395.79Z"
                    fill="#5d5e5e"
                />
                <path
                    id="Path_36"
                    d="M121.13,191.49,147.5,297.42l3.6,14.5,12.29,49.34H391.56L346.3,191.49Z"
                    fill="#5d5e5e"
                />
                <path
                    id="Path_37"
                    d="M121.12,191.49l-3.65,1.22,41.06,172.23,4.86-3.68Z"
                    fill="#707171"
                />
                <path id="Path_38" d="M158.53,364.94H385.3l6.26-3.68H163.39Z" fill="#707171" />
                <rect
                    id="Rectangle_1"
                    width="49.1"
                    height="2.33"
                    transform="translate(287.27 398.13) rotate(180)"
                    fill="#5d5e5e"
                />
                <path
                    id="Path_39"
                    d="M274.78,364.94l-.25,1.79-46.18,9.32.64-7.53.3-3.58Z"
                    fill="#707171"
                />
                <path
                    id="Path_40"
                    d="M279.94,395.79c-1.88,0-38.62.07-47.92-.05-4.05-.05-4.76-3.6-4.79-5.9l46-8.64c-.48,5.26-.76,8.62-.76,9.26a5.787,5.787,0,0,0,.09,1.05C273.29,395.61,279.24,395.79,279.94,395.79Z"
                    fill="#707171"
                />
                <path
                    id="Path_41"
                    d="M121.12,191.49H346.3l36.53,136.44H155.09Z"
                    fill="#fff"
                    opacity="0.3"
                />
                <path
                    id="Path_42"
                    d="M372.21,322.02H161.38L153.85,292l-2.92-11.61-1.19-4.73L142,244.81l-.4-1.6-4.09-16.3-1.83-7.3-1.57-6.22-3.66-14.59H338.24l4.02,14.59,1.72,6.22,2.01,7.3,13.25,48.05h0l1.12,4.08.52,1.89,9.15,33.17Z"
                    fill="#fff"
                />
                <path id="Path_43" d="M342.26,213.39H134.11l-3.66-14.59H338.24Z" fill="#707171" />
                <path
                    id="Path_44"
                    d="M139.05,205.39a1.38,1.38,0,0,1,1.37-1.82,2.51,2.51,0,0,1,2.28,1.82,1.39,1.39,0,0,1-1.37,1.83,2.49,2.49,0,0,1-2.28-1.83Z"
                    fill="#f4f5f6"
                />
                <path
                    id="Path_45"
                    d="M145.11,205.39a1.38,1.38,0,0,1,1.37-1.82,2.49,2.49,0,0,1,2.27,1.82,1.38,1.38,0,0,1-1.36,1.83,2.49,2.49,0,0,1-2.28-1.83Z"
                    fill="#f4f5f6"
                />
                <path
                    id="Path_46"
                    d="M151.63,205.39a1.38,1.38,0,0,1,1.37-1.82,2.49,2.49,0,0,1,2.28,1.82,1.38,1.38,0,0,1-1.37,1.83,2.49,2.49,0,0,1-2.28-1.83Z"
                    fill="#f4f5f6"
                />
                <path
                    id="Path_47"
                    d="M296.91,114c8.52-10,21.66-30.1,30.5-40.39a3.26,3.26,0,0,0-2.48-5.39h-16.1L324,33.88a1.35,1.35,0,0,0-2.31-1.35L285,81.67a2,2,0,0,0,1.88,3.23l18.22-2.25-10.84,29.79a1.56,1.56,0,0,0,2.65,1.56Z"
                    fill="#e3e2e3"
                />
                <path
                    id="Path_48"
                    d="M221.11,174.93c1.92-9.83,3.13-28.09,5.16-38.24A2.49,2.49,0,0,0,222.6,134l-10.69,6-2.76-28.48a1,1,0,0,0-2,0l-6,46.37a1.54,1.54,0,0,0,2.45,1.44l11.27-8.3,3.94,23.83a1.19,1.19,0,0,0,2.3.07Z"
                    fill="#e3e2e3"
                />
                <path
                    id="Path_49"
                    d="M381.42,324.92,198.23,340.36a18.35,18.35,0,0,1-18.29-25.76l77.62-173.69a18.33,18.33,0,0,1,32-2.7L395.13,296.47a18.33,18.33,0,0,1-13.71,28.45Z"
                    fill="#f4f5f6"
                />
                <path
                    id="Path_50"
                    d="M382,331.85,198.81,347.28a25.28,25.28,0,0,1-25.21-35.51l77.61-173.7a25.29,25.29,0,0,1,44.13-3.72L400.92,292.61A25.3,25.3,0,0,1,382,331.85ZM273.34,137a11.24,11.24,0,0,0-9.43,6.7L186.29,317.4a11.39,11.39,0,0,0,11.35,16L380.83,318a11.39,11.39,0,0,0,8.52-17.66L283.77,142.07A11.25,11.25,0,0,0,273.34,137Z"
                    fill="#707171"
                />
                <path
                    id="Path_51"
                    d="M269.05,298.35a16.87,16.87,0,0,1,.71-6.66,15.43,15.43,0,0,1,3.14-5.5,16.269,16.269,0,0,1,5.26-3.9,20.231,20.231,0,0,1,7.21-1.85,20.879,20.879,0,0,1,7.47.61,16.549,16.549,0,0,1,5.9,3,15.34,15.34,0,0,1,4,4.91,16.76,16.76,0,0,1,1.81,6.44,16.42,16.42,0,0,1-.71,6.59,15.68,15.68,0,0,1-3.14,5.46,16.35,16.35,0,0,1-5.32,3.9,21,21,0,0,1-7.26,1.86,20.49,20.49,0,0,1-7.42-.62,16.37,16.37,0,0,1-5.84-3,15.441,15.441,0,0,1-4-4.85,16.31,16.31,0,0,1-1.81-6.39Zm28.32-33.14L271,267.43l-11.34-89.85,33.86-2.85Z"
                    fill="#707171"
                />
                <path
                    id="Path_52"
                    d="M155.29,270.72a16.263,16.263,0,0,1,1-1.91c.42-.61,2-2.4,2.23-2s1.36,3.14.78,3.91,0,2.09-2.82,2.9Z"
                    fill="#e3e2e3"
                />
                <path
                    id="Path_53"
                    d="M123.52,262.56s14.64,12.22,20.34,11.08c4.47-.88,11.52-3.12,11.52-3.12s1.86,1.77,1.34,3.9c0,0-9,8.45-15.39,8.51-10,.08-14.73-5-14.73-5Z"
                    fill="#5d5e5e"
                />
                <path
                    id="Path_54"
                    d="M97.85,391.42s.43,6.85,1.76,6.95h6.72l8.36-.05a2.7,2.7,0,0,0-2.5-2.44c-2.44-.43-6.53-2.15-8-5.38C102.62,387.13,97.85,391.42,97.85,391.42Z"
                    fill="#707171"
                />
                <path
                    id="Path_55"
                    d="M97.37,391.9a17.421,17.421,0,0,0,4.53.85,16.411,16.411,0,0,0,4.22-.61,116.371,116.371,0,0,1,3.11-14.82c1.39-5.34,3.36-12.21,6.16-20.75,7.76-23.59,10.11-37.4,10.33-38.71a.669.669,0,0,0,0-.1h0l-23.84-10.47s2.95,32.52-.81,45a52.862,52.862,0,0,0-1.37,6C97.32,371.82,97.37,391.9,97.37,391.9Z"
                    fill="#707171"
                />
                <path
                    id="Path_56"
                    d="M109.23,377.32c1.39-5.34,3.36-12.21,6.16-20.75,7.76-23.59,10.11-37.4,10.33-38.71v-.11l-23.84-10.47s2.95,32.52-.81,45C100.53,354,109.23,377.32,109.23,377.32Z"
                    fill="#fff"
                    opacity="0.3"
                />
                <path
                    id="Path_57"
                    d="M116.48,391.42s.42,6.85,1.75,6.95h6.72l8.36-.05a2.7,2.7,0,0,0-2.5-2.44c-2.44-.43-6.53-2.15-8-5.38C121.24,387.13,116.48,391.42,116.48,391.42Z"
                    fill="#5d5e5e"
                />
                <path
                    id="Path_58"
                    d="M130,308.05A339.941,339.941,0,0,1,126.66,355c-3.65,25.87-2.41,36.85-2.41,36.85a25.876,25.876,0,0,1-3.52.9,15.869,15.869,0,0,1-5.22-.61s-5.22-25.88-3.72-38.83-7.15-44.44-7.15-44.44Z"
                    fill="#5d5e5e"
                />
                <path
                    id="Path_59"
                    d="M113.09,258.74l4.65,3.05,4.24-1.2c.77-1.11,1-3.24,1-5.08a31.43,31.43,0,0,0-.17-3.39l-6.27-2.77-2.35-1s.19,1.92.21,2.2A16,16,0,0,1,113.09,258.74Z"
                    fill="#e3e2e3"
                />
                <path
                    id="Path_60"
                    d="M130.1,313.91c-5.79,5.59-27.72,4.65-29.07-1.51s1.48-52.46,11.37-55.36c2-.61,13,2.94,13,2.94a70,70,0,0,1,2.85,10.24c.22,1.12.86,12.95,1.34,24,.23,5.17.41,10.18.51,13.85a40.677,40.677,0,0,1,0,5.84Z"
                    fill="#707171"
                />
                <path
                    id="Path_61"
                    d="M114.86,250.6c1.27,2.16,5.74,4,8.11,4.91a31.431,31.431,0,0,0-.17-3.39l-6.27-2.77C115.14,249.33,114.29,249.64,114.86,250.6Z"
                    fill="#707171"
                    opacity="0.3"
                />
                <path
                    id="Path_62"
                    d="M125.17,251.74s-10.11,3.16-12-3.17-4.43-10.19,2-12.47,8.35-.24,9.49,1.72S127.76,250.76,125.17,251.74Z"
                    fill="#e3e2e3"
                />
                <path
                    id="Path_63"
                    d="M124.23,237.21s-.65,7.37-2.17,7-3.2-1-3,1.21-1.48,6-3.67,6c-4.71,0-9.83-12.11-8.54-14.4C110.59,230.48,129.61,229.14,124.23,237.21Z"
                    fill="#707171"
                />
                <path
                    id="Path_64"
                    d="M122.74,257.2c-.13-.06-7.53-3.38-8.39-3.09S112.4,257,112.4,257l13,2.94a6.43,6.43,0,0,0-2.66-2.74Z"
                    fill="#5d5e5e"
                />
                <path
                    id="Path_65"
                    d="M168.81,293.05,127.33,332.1c-.81.77-1.84,1-2.28.55l-1.4-1.49c-.44-.47-.13-1.48.68-2.24l41.48-39.06c.82-.77,1.84-1,2.28-.54l1.4,1.49C169.93,291.27,169.63,292.28,168.81,293.05Z"
                    fill="#707171"
                />
                <path
                    id="Path_66"
                    d="M145.53,315l-18.2,17.13c-.81.77-1.84,1-2.28.55l-1.4-1.49c-.44-.47-.13-1.48.68-2.24l18.2-17.14Z"
                    fill="#e3e2e3"
                />
                <path
                    id="Path_67"
                    d="M159.28,278.3a32.51,32.51,0,1,0,53.89-32.68l-.51-.49a32.52,32.52,0,0,0-53.38,33.17Zm9.29-30a32.293,32.293,0,1,1,0-.01Z"
                    fill="#707171"
                />
                <g id="Group_1" opacity="0.2">
                    <path
                        id="Path_68"
                        d="M212.19,289.33a29.71,29.71,0,1,1,8.221-21.435,29.94,29.94,0,0,1-8.221,21.435Z"
                        fill="#f4f5f6"
                    />
                </g>
                <path
                    id="Path_69"
                    d="M130.13,308.09c-22.86-16.29-21-38-21-38,8.36,4.71,16.65,15.13,20.45,24.17C129.85,299.41,130,304.42,130.13,308.09Z"
                    fill="#fff"
                    opacity="0.3"
                />
                <path
                    id="Path_70"
                    d="M152.69,300.67a6.23,6.23,0,0,0,2.92-1.8s.69.05-.11,1.51l-.33.66a.14.14,0,0,0,.12.21,14.92,14.92,0,0,1,3.66.08c.24.05.32.64.31.89a2.29,2.29,0,0,1-.49,1.56c-.28.38-1.09.79-2.88,1.52a8.81,8.81,0,0,1-4.47-.57l-1.47-.67.46-2.72.37.07C151.36,301.54,152.08,300.82,152.69,300.67Z"
                    fill="#e3e2e3"
                />
                <path
                    id="Path_71"
                    d="M116.27,260s-20.54,3.91,6,33.66c4.25,4.75,27.22,10.95,27.22,10.95s2.33-2,1.15-3.59c0,0-15.81-11.49-19.29-13.31-1.56-.82-6.07-18.33-8.63-22.55A11.53,11.53,0,0,0,116.27,260Z"
                    fill="#707171"
                />
                <path
                    id="Path_72"
                    d="M155.85,271.08a10.64,10.64,0,0,0,.8-2,10.92,10.92,0,0,1,1.33-3c.14-.14.6,0,.65.63a3.23,3.23,0,0,1-.33,1.3,15.507,15.507,0,0,0-.43,2.59C157.55,271.67,155.85,271.08,155.85,271.08Z"
                    fill="#e3e2e3"
                />
                <path
                    id="Path_73"
                    d="M164,220.12a1.24,1.24,0,0,0-.23,1.32h0a8.27,8.27,0,0,0,2.6,2.86l.42.34.09.09.07.06c1.14,1.06.71,1.3,2.77,3.44,2.34,2.44,4,2.48,4.41,2.26s-.4-1.48-1.27-2.94a2.4,2.4,0,0,0-.13-.21l-.35-.58c-1-1.75-.76-2.57-.51-5.17.2-2.12,3.29-11.82,3.29-11.82l-4.49-.77s-1.35,7.72-2.84,8.91c-.8.63-1.69.64-2.46,1.11l-.16.1a6.078,6.078,0,0,0-1.21,1Z"
                    fill="#e3e2e3"
                />
                <path
                    id="Path_74"
                    d="M164,220.12a1.24,1.24,0,0,0-.23,1.32h0a9.7,9.7,0,0,0,2.6,2.85l.42.35.09.09.07.06c1.14,1.06.71,1.3,2.77,3.44,2.34,2.44,4,2.48,4.41,2.26s-.4-1.48-1.27-2.94a9.84,9.84,0,0,1-4.18-3.07c-1.36-1.74-2.28-4-3.2-5.31l-.27-.05A6.079,6.079,0,0,0,164,220.12Z"
                    fill="#5d5e5e"
                />
                <path
                    id="Path_75"
                    d="M200.23,217.33c.28,1.38,2.25,1.15,4.46.61s1.68.11,4.82-.24,4.1-1.52,4.19-2-1.46-.57-3.13-.76l-.63-.07c-1.88-.24-3.25-1.45-5-2.45s-4-4.79-4-4.79l-4.31.34s2.26,3.43,3.14,4.93a5.27,5.27,0,0,1,.42,2.93,5.24,5.24,0,0,0,.04,1.5Z"
                    fill="#e3e2e3"
                />
                <path
                    id="Path_76"
                    d="M204.69,217.94c2.21-.53,1.68.11,4.82-.24s4.1-1.52,4.19-2-1.46-.57-3.13-.76h0a8.85,8.85,0,0,1-4.5,1.4c-2.13.05-4.39-.56-5.86-.54a5.24,5.24,0,0,0,0,1.48C200.51,218.71,202.48,218.48,204.69,217.94Z"
                    fill="#5d5e5e"
                />
                <path
                    id="Path_77"
                    d="M183.73,189.78l-4,10.83-5.26,14.15a8.42,8.42,0,0,1-2.8-.26,12.2,12.2,0,0,1-2.7-1.31s1.47-15.2,4.19-23.92a15.671,15.671,0,0,1,2.35-5.06C179.7,179.22,183.73,189.78,183.73,189.78Z"
                    fill="#5d5e5e"
                />
                <path
                    id="Path_78"
                    d="M179.64,172.56c-2.29-.74-8.27,0-14.76,1.16a4.38,4.38,0,0,1-4.54-2.05h0L144,170.39s-5.39,11.94-2.44,19.56a2.85,2.85,0,0,0,2.85,1.84h13c8.12,0,17.19-5.58,18.36-5.81,1.92-.39,2.68,3.45,4.6,7.48s18,17.86,18,17.86a13.87,13.87,0,0,0,3.5-.36,3.39,3.39,0,0,0,1.5-.95S185.59,174.49,179.64,172.56Z"
                    fill="#707171"
                />
                <path
                    id="Path_79"
                    d="M158.62,123.72a5.8,5.8,0,0,0-3.12-.81,8.342,8.342,0,0,1-1.5-.09c-6.24-.92-10,8.13-10.21,10.87-.41,5-3.06,5.78-6.8,8.93-7.5,6.3-5.31,13.63-1.21,16.35,2.46,1.63,6.8,0,6.8,0s11.85-6.59,17.67-9.79C169,148,162.12,125.81,158.62,123.72Z"
                    fill="#707171"
                />
                <path
                    id="Path_80"
                    d="M155.38,146.46s-2.86,1-4.79.14-3.19-3.79-3.17-3.79c.83,0,1.74-.76,2.44-5.5l.35.13,5.91,2.13s-.43,1.9-.71,3.7C155.18,144.79,155.05,146.24,155.38,146.46Z"
                    fill="#e3e2e3"
                />
                <path
                    id="Path_81"
                    d="M156.12,139.57s-.24,1-.58,2.94a6.68,6.68,0,0,1-5.33-5.07Z"
                    fill="#707171"
                    opacity="0.3"
                />
                <path
                    id="Path_82"
                    d="M157,141.45s-8.09-1.09-7.33-6.11.22-8.56,5.41-8.07,5.91,2.55,6.07,4.29S159.12,141.61,157,141.45Z"
                    fill="#e3e2e3"
                />
                <path
                    id="Path_83"
                    d="M160.86,128.8s-5.18,4.46-8.71,4.82-4.85-.77-4.85-.77a13.87,13.87,0,0,0,3.32-4.51,2.49,2.49,0,0,1,2-1.46C155.18,126.67,160,126.57,160.86,128.8Z"
                    fill="#707171"
                />
                <path
                    id="Path_84"
                    d="M157.62,128.4a13,13,0,0,1,2.28,2.92,9.83,9.83,0,0,1,.26,5.2s2.7-5.24,1.17-7.8S157.62,128.4,157.62,128.4Z"
                    fill="#707171"
                />
                <path
                    id="Path_85"
                    d="M162.19,158.38c-.25,16.37-.08,13-1.87,14.51a7,7,0,0,1-3.24.7c-5,.33-13.85-.5-14.18-1.43-1.16-3.21-.34-4.21-1.31-9.13-.1-.52-.22-1.08-.37-1.7-.8-3.38-1.59-4.53,1.27-10,2.6-4.91,5.29-8.9,5.53-8.76,6.84,4.07,7.39.65,7.39.65S162.31,150.39,162.19,158.38Z"
                    fill="#5d5e5e"
                />
                <path
                    id="Path_86"
                    d="M179.7,200.61l-5.26,14.15a8.42,8.42,0,0,1-2.8-.26,12.2,12.2,0,0,1-2.7-1.31s1.47-15.2,4.19-23.92Z"
                    fill="#fff"
                    opacity="0.3"
                />
                <path
                    id="Path_87"
                    d="M155.41,143.27s7,1.2,7.87,4.23,3.54,21.42,3.54,21.42-2,1.94-3.64.29S158,152,158,152Z"
                    fill="#5d5e5e"
                />
                <path
                    id="Path_88"
                    d="M157.08,173.59c-5,.33-13.85-.5-14.18-1.43-1.16-3.21-.34-4.21-1.31-9.13l2.43-6.81s-.41,7.59,1.83,9.59,9.17,3.86,10.26,5.53a6.14,6.14,0,0,1,.97,2.25Z"
                    fill="#fff"
                    opacity="0.3"
                />
                <path
                    id="Path_89"
                    d="M151.33,170.39s4.95-1.1,5.75-.66a11.69,11.69,0,0,1,3,2.6c.24.56-1.78-.43-2.44-.6a5.59,5.59,0,0,0-2.1.46,7,7,0,0,1-3.78.38Z"
                    fill="#e3e2e3"
                />
                <path
                    id="Path_90"
                    d="M148,142.62s-6.65.41-9,3.62c-3.24,4.52-9.68,23.79-6,26.36,4.15,2.89,18.89.76,18.89.76s.49-2.29-.2-3.4-11.77-2.91-12.28-3.42,6.4-10,6.4-11.51S148,142.62,148,142.62Z"
                    fill="#5d5e5e"
                />
                <path
                    id="Path_91"
                    d="M157.32,171.11a9.631,9.631,0,0,0,1.38,1.5,1,1,0,0,0,.56.12l-.7-1.26Z"
                    fill="#e3e2e3"
                />
                <path
                    id="Path_92"
                    d="M157.08,169.73l3,1.47a.54.54,0,0,1-.5.19,7.519,7.519,0,0,1-1.31-.36Z"
                    fill="#e3e2e3"
                />
                <path id="Path_93" d="M182.85,173.18l-31.52-.01v.7l31.24.01Z" fill="#5d5e5e" />
                <path
                    id="Path_94"
                    d="M158.7,173.87l23.87.01,7.19-17.86-23.84-.01Z"
                    fill="#5d5e5e"
                />
                <path
                    id="Path_95"
                    d="M158.7,173.87l23.87.01,7.19-17.86-23.84-.01Z"
                    fill="#fff"
                    opacity="0.2"
                />
                <path
                    id="Path_96"
                    d="M338.12,241.22a6.86,6.86,0,0,0-5.45.28c-9.26.38-9.6,9.87-8.51,13.4,1.52,5-3.88,8.87,2.11,10.88,7,2.33,12.79-.43,12.39.77C360.64,268.28,345.43,243.64,338.12,241.22Z"
                    fill="#707171"
                />
                <path
                    id="Path_97"
                    d="M328.51,287.38a24.472,24.472,0,0,1-2.47,3.74c-1.93,2.41-12-3.31-11.15-3.74s5.94-15.05,7.15-16.89,5.32-2.79,5.32-2.79Z"
                    fill="#707171"
                />
                <path
                    id="Path_98"
                    d="M362.58,386s2.79,2.54,2.25,4.35-3.06,3-3.4,5.22-1.7,3.41-2.93,3.41h-5.83s-.9-1.56,1.28-2,3-3.07,2.86-5.48a42.006,42.006,0,0,1,.2-5.62l4.62-1.07Z"
                    fill="#707171"
                />
                <path
                    id="Path_99"
                    d="M339,387s1,10.77-.22,11.37H323.12s0-1.81,2.51-2.21,6.94-1.87,7.68-5.15S339,387,339,387Z"
                    fill="#5d5e5e"
                />
                <path
                    id="Path_100"
                    d="M351,280.11a63.323,63.323,0,0,1,0,7.24c-.15,2.62-2.8,6.12-3.18,7-.94,2.17-2,2.53-2,2.53l-8.22,2-2.89.71-9.12-2.72s-4.15-11.59-2.48-18.11a50.409,50.409,0,0,1,4.21-11.06s6.2-1.39,17.12-.62Z"
                    fill="#707171"
                />
                <path
                    id="Path_101"
                    d="M351.82,287.44c-.15,2.62-3.58,6-4,6.9-.94,2.17-2,2.53-2,2.53l-8.22,2-1.49-4.25s12-3.16,10.08-14.28Z"
                    fill="#fff"
                    opacity="0.3"
                />
                <path
                    id="Path_102"
                    d="M326.21,298.62s-4.51,14.05-4.8,18.84,8.63,68,9,70.16,2.91,3.4,2.91,3.4a3.9,3.9,0,0,0,2.88.22,7.13,7.13,0,0,0,3.1-1.46s3.51-45,1.34-61.14a253.6,253.6,0,0,1-2.2-30Z"
                    fill="#5d5e5e"
                />
                <path
                    id="Path_103"
                    d="M345.34,298.21s6.88,9.23,7.52,15.48,1,28.18,2.38,34.27,6.39,17.8,6.39,36.79a3.78,3.78,0,0,1-2.13,1.67,16.113,16.113,0,0,1-1.88.52,3,3,0,0,1-3.3-1.57c-3.4-6.84-14.22-28.9-14.77-33.48-.4-3.24-3.81-24.68-11.27-37.27-1.79-3,1.1-15,1.1-15Z"
                    fill="#707171"
                />
                <path
                    id="Path_104"
                    d="M325.49,296.46a9.45,9.45,0,0,0,5.41,1.5c4.3.09,15-1.09,15-1.09a8.811,8.811,0,0,1,.45,2.71s-13,3.29-20.55.48a4.77,4.77,0,0,1-.31-3.6Z"
                    fill="#5d5e5e"
                />
                <path
                    id="Path_105"
                    d="M329.87,301.67a2.24,2.24,0,1,1,2-2.22,2.11,2.11,0,0,1-2,2.22Zm0-3.79a1.6,1.6,0,1,0,1.31,1.57,1.46,1.46,0,0,0-1.31-1.57Z"
                    fill="#fff"
                />
                <path
                    id="Path_106"
                    d="M339.27,305.71l-19.56.18-9.62-22.62,19.56-.18Z"
                    fill="#707171"
                />
                <path
                    id="Path_107"
                    d="M339.27,305.71l-19.56.18-9.62-22.62,19.56-.18Z"
                    fill="#f4f5f6"
                    opacity="0.2"
                />
                <path
                    id="Path_108"
                    d="M336.72,295.49l-1.44-1.77a5.11,5.11,0,0,0-1.67-1c-.71-.15-2.19-1-2.34-.76s1.14,2,1.14,2h.07c-1.48,1.11-5.2.13-5.66.59s.76,1.73,1.47,2.4,2.75,1.78,4.33,1.28c1.16-.36,3-.72,3-.72Z"
                    fill="#e3e2e3"
                />
                <path
                    id="Path_109"
                    d="M311.56,287.25s1.23-1.4,2-.73-.2,4-.95,3.64S311.13,287.66,311.56,287.25Z"
                    fill="#e3e2e3"
                />
                <path
                    id="Path_110"
                    d="M344.48,267.08s5.6.62,9.62,7.92c3.17,5.76,8.59,16.37,6.54,19.37-.89,1.32-9.5,6-26.09,3.32a5.06,5.06,0,0,1,1.6-3.06s14-4.49,13.94-5.38c0-1.68-1.89-3.14-9.68-10.94C338.29,276.19,344.48,267.08,344.48,267.08Z"
                    fill="#707171"
                />
                <path
                    id="Path_111"
                    d="M340.18,267.38c.19-.26-.06-1.08-.37-2.61a28.619,28.619,0,0,1-.74-5.95l-.41.07-6.94,1.17s.16,1.85.16,3.6h0c0,1.52-.41,3.82.1,4.12C334.61,269.36,339.44,268.37,340.18,267.38Z"
                    fill="#e3e2e3"
                />
                <path
                    id="Path_112"
                    d="M331.72,260.06s.14,2.61.14,4.36c3.89-.09,6-3.86,6.8-5.53Z"
                    fill="#707171"
                    opacity="0.3"
                />
                <path
                    id="Path_113"
                    d="M331.3,263.18s9.1-1.3,8.2-6.95-.32-9.63-6.16-9-6.63,2.92-6.8,4.88S328.91,263.37,331.3,263.18Z"
                    fill="#e3e2e3"
                />
                <path
                    id="Path_114"
                    d="M329.18,247.71s3.54,6.23,7.51,6.61,5.46-.91,5.46-.91a11,11,0,0,1-4.55-5.82S330.55,244.26,329.18,247.71Z"
                    fill="#707171"
                />
                <path
                    id="Path_115"
                    d="M329.88,248.15a5.66,5.66,0,0,0-2.27,2.45,9.14,9.14,0,0,0-.4,5.38s-2.59-4.21-.9-7.09C328.11,245.83,329.88,248.15,329.88,248.15Z"
                    fill="#707171"
                />
                <rect
                    id="Rectangle_2"
                    width="397.06"
                    height="1.62"
                    rx="0.64"
                    transform="translate(94.32 398.13)"
                    fill="#707171"
                />
                <path
                    id="Path_116"
                    d="M176.55,165a.83.83,0,0,0-.81-1.14,1.64,1.64,0,0,0-1.47,1.14.83.83,0,0,0,.81,1.13A1.63,1.63,0,0,0,176.55,165Z"
                    fill="#f4f5f6"
                />
            </g>
        </SVGIcon>
    );
}
