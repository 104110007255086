import React from 'react';

import { SVGIcon } from '../../UIKit/index';

export default function DCSnooze(props) {
    return (
        <SVGIcon {...props} viewBox="0 0 16 16">
            <path d="M1.339 7.982c0-3.661 3-6.661 6.661-6.661 1.839 0 3.411.643 4.732 1.946 1.304 1.321 1.946 2.893 1.946 4.714 0 3.696-2.982 6.696-6.679 6.696-1.839 0-3.411-.661-4.696-1.982-1.304-1.286-1.964-2.857-1.964-4.714zm1.661 0c0 2.732 2.268 5 5 5 2.786 0 5-2.214 5-5 0-2.804-2.196-5-5-5-2.768 0-5 2.232-5 5zm4.321.268V4.643c0-.143.125-.286.286-.286h.714c.161 0 .286.143.286.286v3.054l2.643 1.804c.089.054.143.107.143.196a.287.287 0 0 1-.054.161l-.393.625c-.054.054-.107.107-.196.125h-.054l-.143-.036-3.071-2.071-.071-.054a.285.285 0 0 1-.089-.196z" />
        </SVGIcon>
    );
}
