import React from 'react';

import { SVGIcon } from '../../UIKit/index';

export default function DCCrossClose(props) {
    return (
        <SVGIcon {...props} viewBox="0 0 512 512">
            <path d="M310.4,256.5L495.1,71.8c10.5-10.6,10.5-27.6,0-38.2l-16.2-16.2c-10.6-10.5-27.7-10.5-38.2,0    L256,202.1L71.3,17.4c-10.6-10.5-27.6-10.5-38.2,0L16.9,33.6c-10.5,10.6-10.5,27.7,0,38.2l184.7,184.7L16.9,441.2    c-10.5,10.6-10.5,27.7,0,38.2l16.2,16.2c10.6,10.5,27.6,10.5,38.2,0L256,310.9l184.7,184.7c5.1,5.1,11.9,7.9,19.1,7.9l0,0    c7.2,0,14.1-2.8,19.1-7.9l16.2-16.2c10.5-10.6,10.5-27.6,0-38.2L310.4,256.5z" />
        </SVGIcon>
    );
}
