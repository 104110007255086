import React from 'react';

import { SVGIcon } from '../../UIKit/index';

export default function DCGrowChart(props) {
    return (
        <SVGIcon {...props} viewBox="0 0 512 512">
            <g>
                <polygon points="352.1,109.3 323.7,82 418.8,60.6 402.3,156.1 371.3,126.4 272.6,229.5 231.9,192.2 130.9,297.4    112.3,279.7 230.7,158.5 271.7,195.5  " />
                <rect x="363.6" y="193.7" width="43.1" height="216.3" />
                <rect x="289" y="240.5" width="43.1" height="169.5" />
                <rect x="210" y="277.2" width="43.1" height="132.8" />
                <rect x="140.3" y="315.2" width="43.1" height="94.8" />
                <path d="M86,455.1H64.5c-4,0-7.3-3.3-7.3-7.3V64.3c0-4,3.3-7.3,7.3-7.3H86c4,0,7.3,3.3,7.3,7.3v383.5   C93.3,451.8,90,455.1,86,455.1z" />
                <path d="M455.1,426.3v21.5c0,4-3.3,7.3-7.3,7.3H64.3c-4,0-7.3-3.3-7.3-7.3v-21.5c0-4,3.3-7.3,7.3-7.3h383.5   C451.8,419,455.1,422.2,455.1,426.3z" />
            </g>
        </SVGIcon>
    );
}
