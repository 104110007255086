import React from 'react';

interface DivProps {
    id?: any;
    className?: string;
    children?: React.ReactNode;
    style?: Object;
    reference?: any;
    onAnimationEnd?: any;
    onClick?: any;
    onBlur?: any;
    draggable?: any;
    onMouseOver?: () => void;
    onMouseOut?: () => void;
    onDragOver?: any;
    onDragEnter?: any;
    onDragLeave?: any;
    onDragEnd?: any;
    onDrop?: () => void;
    onScroll?: () => void;
    onDragStart?: any;
    onDoubleClick?: () => void;
    dangerouslySetInnerHTML?: any;
    onHover?: () => void;
    dataId?: string;
}
const Div: React.FC<DivProps> = ({
    id,
    className,
    children,
    style,
    reference,
    onAnimationEnd,
    onClick,
    onMouseOver,
    onMouseOut,
    onDragOver,
    onDragEnter,
    onDragEnd,
    onDragLeave,
    onDrop,
    onScroll,
    onDragStart,
    onDoubleClick,
    dangerouslySetInnerHTML,
    onBlur,
    draggable,
    dataId,
    ...props
}) => {
    return (
        <div
            {...props}
            className={className}
            id={id}
            style={style}
            ref={reference}
            onAnimationEnd={onAnimationEnd}
            onClick={onClick}
            onDoubleClick={onDoubleClick}
            onMouseOver={onMouseOver}
            onMouseOut={onMouseOut}
            onDragOver={onDragOver}
            onDragEnter={onDragEnter}
            onDragLeave={onDragLeave}
            onDragStart={onDragStart}
            onDragEnd={onDragEnd}
            onDrop={onDrop}
            onScroll={onScroll}
            onBlur={onBlur}
            draggable={draggable}
            dangerouslySetInnerHTML={dangerouslySetInnerHTML}
            data-id={dataId}
        >
            {children}
        </div>
    );
};

export default Div;
