import React from 'react';

import { Div, Image, H1 } from 'UIKit/index';
import './style.scss';

import Plugin from 'Assets/images/Plugin.svg';

const Maintenance = () => {
    return (
        <Div class="under-maintenance">
            <Div class="um-inner">
                <Div class="umi-body">
                    <Div class="umib-content">
                        <H1>Planned Maintenance In Progress</H1>
                        <p>
                            Don't worry, we'll only be down for a couple minutes! Just doing some
                            routine maintenance to the dashboard to make things even more awesome.
                            Check back in a couple minutes!
                        </p>
                    </Div>
                    <Div class="umib-plug">
                        <Image src={Plugin} alt="" />
                    </Div>
                </Div>
            </Div>
        </Div>
    );
};

export default Maintenance;
