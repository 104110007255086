import React from 'react';

import { SVGIcon } from '../../UIKit/index';

export default function DCCalendar(props) {
    return (
        <SVGIcon {...props} viewBox="0 0 512 493">
            <g>
                <path
                    d="M459,54.8h-68.1V26.6c1-13.6-9.1-25.4-22.7-26.5c-13.5,1.1-23.7,12.9-22.7,26.5v28.1H169V26.6
		c1-13.6-9.1-25.4-22.7-26.5c-13.5,1.1-23.7,12.9-22.7,26.5v28.1H53c-28.3,0-51.3,23-51.3,51.3v335.4c0,28.3,23,51.3,51.3,51.3H459
		c28.3,0,51.3-23,51.3-51.3V106.1C510.3,77.8,487.3,54.8,459,54.8z M478.3,441.5c0,10.6-8.7,19.3-19.3,19.3H53
		c-10.6,0-19.3-8.7-19.3-19.3V106.1c0-10.6,8.7-19.3,19.3-19.3h70.6v28.1c-1,13.6,9.2,25.4,22.7,26.5c13.5-1.1,23.7-12.9,22.7-26.5
		V86.8h176.6v28.1c-1,13.6,9.2,25.4,22.7,26.5c13.5-1.1,23.7-12.9,22.7-26.5V86.8H459c10.6,0,19.3,8.7,19.3,19.3V441.5z"
                />
                <rect x="335.4" y="176.7" width="53" height="53" />
                <rect x="335.4" y="265" width="53" height="53" />
                <rect x="335.4" y="353.3" width="53" height="53" />
                <rect x="229.5" y="176.7" width="53" height="53" />
                <rect x="229.5" y="265" width="53" height="53" />
                <rect x="229.5" y="353.3" width="53" height="53" />
                <rect x="123.6" y="176.7" width="53" height="53" />
                <rect x="123.6" y="265" width="53" height="53" />
                <rect x="123.6" y="353.3" width="53" height="53" />
            </g>
        </SVGIcon>
    );
}
