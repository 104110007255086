import React, { useCallback, useEffect, useState } from 'react';
import { Dialog, Div, H1, IconButton, P, TopBar } from 'UIKit';
import DCCrossClose from 'Assets/icons/DCCrossClose';
import './style.scss';
import DCSchedule2Icon from 'Assets/icons/DCSchedule2';
import DCHelpCenter from 'Assets/icons/DCHelpCenter';
import DCFacebookGroup from 'Assets/icons/DCFacebookGroup';
import DCNewFeature from 'Assets/icons/DCNewFeature';
import DCReportBug from 'Assets/icons/DCReportBug';
import DCWhatsNew from 'Assets/icons/DCWhatsNew';
import DCLiveChat1 from 'Assets/icons/DCLiveChat1';
import DCPhoneSupport from 'Assets/icons/DCPhoneSupport';
import { getAuthDetails, getUserDetails } from 'Utils/localStorageUtils';
import usePubSub from 'Hooks/PubSub';

interface Props {
    open: boolean;
    onClose: () => void;
    publish: any;
}

const DashclicksSupportDialog = (props: Props) => {
    const { open, onClose /* publish */ } = props;
    const [isOpen, setIsOpen] = useState(false);
    const { publish } = usePubSub(null, null);

    useEffect(() => {
        setTimeout(() => {
            setIsOpen(open);
        }, 0);
        // The above settimeout is used to call the function at the end of callStack
    }, [open]);

    const closeDialog = useCallback(() => {
        setIsOpen(false);
        setTimeout(() => {
            onClose();
        }, 600);
    }, [onClose]);

    const handleHelpDialog = useCallback(
        name => {
            switch (name) {
                case 'schedule': {
                    publish('open-schedule');
                    break;
                }
                case 'helpCenter': {
                    document.open('https://help.dashclicks.com', '', '');
                    break;
                }
                case 'joinFacebook': {
                    document.open('https://www.facebook.com/groups/dashclicks', '', '');
                    break;
                }
                case 'whatsNew': {
                    document.open('https://feedback.dashclicks.com/changelog', '', '');
                    closeDialog();
                    break;
                }
                case 'reportBug': {
                    document.open('https://feedback.dashclicks.com/bugs', '', '');
                    break;
                }
                case 'newFeature': {
                    document.open('https://feedback.dashclicks.com/feature-requests', '', '');
                    break;
                }
                case 'livechat':
                    closeDialog();
                    break;

                case 'phoneSupport': {
                    document.open('tel:+18666003369', '', '');
                    break;
                }
                default:
                    break;
            }
        },
        [closeDialog, publish],
    );

    return (
        <Dialog
            open={open}
            classes={{ row: `DCSupportDialogContainer ${isOpen ? 'isOpen' : 'isClosed'}` }}
            dialogType={'rightSlide'}
            onClose={closeDialog}
        >
            <Div className={'DCSupportDialog'}>
                <TopBar>
                    <Div className={'HeaderBar'}>
                        <H1 className={'HeaderBarTitle'}>Get Help & Support</H1>
                        <Div className={'HeaderBarClose'}>
                            <IconButton onClick={closeDialog}>
                                <DCCrossClose />
                            </IconButton>
                        </Div>
                    </Div>
                </TopBar>
                <Div className="DCSDBody">
                    <P props={{ className: 'DCSDBHeading' }}>
                        <>{getUserDetails()?.first_name}, here’s all the ways we can support you.</>
                    </P>

                    <Div className="DCSDBHelplinks">
                        {[
                            {
                                icon: <DCSchedule2Icon />,
                                label: 'Schedule A Demo',
                                name: 'schedule',
                                description: 'Learn how we can help your agency.',
                            },
                            {
                                icon: <DCHelpCenter />,
                                label: 'Visit Our Help Center',
                                name: 'helpCenter',
                                description: 'Advice and answers from our team.',
                            },
                            {
                                icon: <DCFacebookGroup />,
                                label: 'Join Our Facebook Group',
                                name: 'joinFacebook',
                                description: 'Network with thousands of agencies.',
                            },
                            {
                                icon: <DCWhatsNew />,
                                label: 'See What’s New',
                                name: 'whatsNew',
                                description: 'Find out what’s new in the platform.',
                            },
                            {
                                icon: <DCReportBug />,
                                label: 'Report A Bug',
                                name: 'reportBug',
                                description: 'Found a bug? Report it to dev team.',
                            },
                            {
                                icon: <DCNewFeature />,
                                label: 'Request A New Feature',
                                name: 'newFeature',
                                description: 'Have a great idea? Request a feature!',
                            },
                            ...(!getAuthDetails()?.session_sso && getUserDetails()?.intercom_hash
                                ? [
                                      {
                                          icon: <DCLiveChat1 />,
                                          label: 'Live Chat',
                                          name: 'livechat',
                                          description: 'Let’s have a chat, ask us anything!',
                                      },
                                  ]
                                : []),
                            ...(getAuthDetails()?.account?.plan.tier &&
                            getAuthDetails()?.account?.plan.tier !== 'tier0'
                                ? [
                                      {
                                          icon: <DCPhoneSupport />,
                                          label: 'Phone Support',
                                          name: 'phoneSupport',
                                          description: 'Call us at  +1 (866) 600-3369.',
                                      },
                                  ]
                                : []),
                            ,
                        ].map((el: any, i: number) => (
                            <Div
                                key={i}
                                className={`DCSDBHItem ${el.name === 'livechat' && 'talk-to-us'}`}
                                onClick={() => handleHelpDialog(el.name)}
                            >
                                <Div className="DCSDBHIIcon">{el.icon}</Div>
                                <Div className="DCSDBHIInfo">
                                    <P props={{ className: 'DCSDBHIILabel' }}>
                                        <>{el.label}</>
                                    </P>
                                    <P props={{ className: 'DCSDBHIIDescription' }}>
                                        <>{el.description}</>
                                    </P>
                                </Div>
                            </Div>
                        ))}
                    </Div>
                </Div>
            </Div>
        </Dialog>
    );
};

export default DashclicksSupportDialog;
