import React from 'react';

import { SVGIcon } from '../../UIKit/index';

export default function DCTerms(props) {
    return (
        <SVGIcon {...props} viewBox="0 0 512 512">
            <g>
                <path d="M367,234h2.7c4.8,0,9.5,0.3,14.1,0.9V61.6c0-33.2-26.9-60.1-60.1-60.1H62.9  C29.7,1.5,2.8,28.4,2.7,61.6v345.8c0,33.2,26.9,60.1,60.1,60.1H237c-44.5-71.8-22.3-166.1,49.5-210.6C310.7,242,338.5,234.1,367,234  L367,234z M218.4,99.7h89.1c8.2,0,14.9,6.7,14.9,14.9c0,8.2-6.7,14.9-14.9,14.9h-89.1c-8.2,0-14.9-6.7-14.9-14.9  C203.4,106.3,210.1,99.7,218.4,99.7z M218.4,159.3h89.1c8.2,0,14.9,6.7,14.9,14.9c0,8.2-6.7,14.9-14.9,14.9c0,0,0,0,0,0h-89.1  c-8.2,0-14.9-6.7-14.9-14.9C203.4,166,210.1,159.3,218.4,159.3z M168.2,369.4h-89c-8.2,0-14.9-6.7-14.9-14.9  c0-8.2,6.7-14.9,14.9-14.9l0,0h89c8.2,0,14.9,6.7,14.9,14.9C183.1,362.7,176.4,369.4,168.2,369.4z M168.2,308.7h-89  c-8.2,0-14.9-6.7-14.9-14.9s6.7-14.9,14.9-14.9l0,0h89c8.2,0,14.9,6.7,14.9,14.9S176.4,308.7,168.2,308.7z M168.2,249h-89  c-8.2,0-14.9-6.7-14.9-14.9s6.7-14.9,14.9-14.9l0,0h89c8.2,0,14.9,6.7,14.9,14.9S176.4,249,168.2,249z M168.2,189.3h-89  c-8.2,0-14.9-6.7-14.9-14.9c0-8.2,6.7-14.9,14.9-14.9c0,0,0,0,0,0h89c8.2,0,14.9,6.7,14.9,14.9C183.1,182.6,176.4,189.3,168.2,189.3  z M168.2,129.7h-89c-8.2,0-14.9-6.7-14.9-14.9c0-8.2,6.7-14.9,14.9-14.9c0,0,0,0,0,0h89c8.2,0,14.9,6.7,14.9,14.9  C183.1,123,176.4,129.7,168.2,129.7z M203.4,234c0-8.2,6.7-14.9,14.9-14.9l0,0h42.9c8.2,0,14.9,6.7,14.9,14.9s-6.7,14.9-14.9,14.9  h-42.9C210.1,248.9,203.4,242.3,203.4,234C203.4,234,203.4,234,203.4,234z" />
                <path d="M455.4,300c-22.7-23.2-53.7-36.5-86.2-37H367c-68.5,0-124.1,55.5-124.1,124  c0,68.5,55.5,124.1,124,124.1c68.5,0,124.1-55.5,124.1-124C490.9,354.5,478.2,323.2,455.4,300L455.4,300z M419.9,366.8l-47.4,59  c-5,6.2-14,7.4-20.4,2.7L316.9,403c-6.7-4.8-8.2-14.2-3.3-20.8c4.8-6.7,14.2-8.2,20.8-3.3l0,0l23.8,17.2l38.5-47.9  c5.2-6.4,14.6-7.5,21-2.3C424.1,351,425.2,360.4,419.9,366.8L419.9,366.8z" />
            </g>
        </SVGIcon>
    );
}
