import React from 'react';

import { SVGIcon } from '../../UIKit/index';

export default function DCFormSubmission(props) {
    return (
        <SVGIcon {...props} viewBox="0 0 512 512">
            <g>
                <path d="m153.6 512h204.8c42.415 0 76.8-34.385 76.8-76.8s-34.385-76.8-76.8-76.8h-204.8c-42.415 0-76.8 34.385-76.8 76.8s34.385 76.8 76.8 76.8zm0-136.533h204.8c32.99 0 59.733 26.744 59.733 59.733s-26.744 59.733-59.733 59.733h-204.8c-32.99 0-59.733-26.743-59.733-59.733s26.743-59.733 59.733-59.733z" />
                <path d="m469.333 179.2h-426.666c-23.553.028-42.639 19.114-42.667 42.667v68.267c.028 23.552 19.114 42.638 42.667 42.667h426.667c23.552-.028 42.638-19.114 42.667-42.667v-68.267c-.029-23.553-19.115-42.639-42.668-42.667zm25.6 110.933c0 14.138-11.462 25.6-25.6 25.6h-426.666c-14.138 0-25.6-11.462-25.6-25.6v-68.267c0-14.139 11.462-25.6 25.6-25.6h426.667c14.138 0 25.6 11.462 25.6 25.6v68.267z" />
                <path d="m469.333 0h-426.666c-23.553.028-42.639 19.114-42.667 42.667v68.267c.028 23.552 19.114 42.638 42.667 42.667h426.667c23.552-.028 42.638-19.114 42.667-42.667v-68.267c-.029-23.553-19.115-42.639-42.668-42.667zm25.6 110.933c0 14.138-11.462 25.6-25.6 25.6h-426.666c-14.138 0-25.6-11.462-25.6-25.6v-68.266c0-14.138 11.462-25.6 25.6-25.6h426.667c14.138 0 25.6 11.462 25.6 25.6v68.266z" />
                <path d="m128 213.333h-68.267c-14.138 0-25.6 11.462-25.6 25.6v34.133c0 14.138 11.462 25.6 25.6 25.6h68.267c14.139 0 25.6-11.462 25.6-25.6v-34.133c0-14.138-11.461-25.6-25.6-25.6zm-8.533 17.067-20.369 15.36c-3.1 2.306-7.339 2.326-10.462.051l-20.369-15.411zm8.533 51.2h-68.267c-4.713 0-8.533-3.82-8.533-8.533v-34.133l27.307 20.48c9.161 6.8 21.701 6.78 30.839-.051l27.187-20.429v34.133c0 4.713-3.82 8.533-8.533 8.533z" />
                <path d="m114.185 75.136c3.406-4.414 5.262-9.827 5.282-15.403 0-14.138-11.462-25.6-25.6-25.6s-25.6 11.462-25.6 25.6c.029 5.561 1.885 10.958 5.282 15.36-8.675 6.396-13.802 16.528-13.815 27.307 0 9.426 7.641 17.067 17.067 17.067h34.133c9.426 0 17.067-7.641 17.067-17.067-.028-10.763-5.153-20.877-13.816-27.264zm-28.852-15.403c0-4.713 3.821-8.533 8.533-8.533 4.713 0 8.533 3.821 8.533 8.533s-3.821 8.533-8.533 8.533c-4.712.001-8.533-3.82-8.533-8.533zm-8.533 42.667c0-9.426 7.641-17.067 17.067-17.067s17.067 7.641 17.067 17.067z" />
                <g clipRule="evenodd" fillRule="evenodd">
                    <path d="m187.733 247.467h42.667c4.713 0 8.533-3.821 8.533-8.533 0-4.713-3.821-8.533-8.533-8.533h-42.667c-4.713 0-8.533 3.821-8.533 8.533s3.821 8.533 8.533 8.533z" />
                    <path d="m452.267 264.533h-264.534c-4.713 0-8.533 3.82-8.533 8.533s3.821 8.533 8.533 8.533h264.533c4.713 0 8.533-3.82 8.533-8.533s-3.819-8.533-8.532-8.533z" />
                    <path d="m187.733 68.267h42.667c4.713 0 8.533-3.821 8.533-8.533s-3.82-8.534-8.533-8.534h-42.667c-4.713 0-8.533 3.821-8.533 8.533s3.821 8.534 8.533 8.534z" />
                    <path d="m452.267 85.333h-264.534c-4.713 0-8.533 3.821-8.533 8.533 0 4.713 3.821 8.533 8.533 8.533h264.533c4.713 0 8.533-3.821 8.533-8.533.001-4.712-3.819-8.533-8.532-8.533z" />
                    <path d="m224.367 466.833c3.002 3.003 7.756 3.341 11.153.794l68.267-51.2c3.77-2.828 4.534-8.176 1.707-11.947-2.828-3.77-8.176-4.534-11.947-1.707l-62.345 46.763-11.836-11.836c-3.348-3.234-8.671-3.188-11.962.104-3.292 3.292-3.338 8.614-.104 11.962z" />
                </g>
            </g>
        </SVGIcon>
    );
}
