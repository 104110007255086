import React from 'react';

import { SVGIcon } from '../../UIKit/index';

export default function DCBookmark(props) {
    return (
        <SVGIcon {...props} viewBox="0 0 512 512">
            <path d="M135.6,41.9c0,0-34.5,2.4-38.2,34.7l-2,393.4l159.5-92.7l161.7,92.5V80.5c0,0,0.7-30.4-36.7-38.6H146v40.7  h231.7v317.7L256,329.5l-118.4,70.1V82.7h8.4l0.5-40.7H135.6z" />
        </SVGIcon>
    );
}
