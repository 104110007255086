import React from 'react';

import { SVGIcon } from '../../UIKit/index';

export default function DCWWW(props) {
    return (
        <SVGIcon {...props} viewBox="0 0 512 472">
            <g transform="translate(0.266 0.724)">
                <rect x="-0.11" y="67.6" width="477.39" height="396.22" fill="#FFFFFF" />
                <rect x="0.68" y="0.28" width="510.26" height="65.42" fill="#FF3CA6" />
                <rect x="478.24" y="65.69" width="32.71" height="405.59" fill="#FFE1F4" />
                <g transform="translate(16.501 25.216)">
                    <g>
                        <g>
                            <path
                                fill="#FF3CA6"
                                d="M105.63,148.29c-2.24-2.91-6.35-3.6-9.41-1.57c-2.13,1.35-3.34,3.76-3.14,6.27l17.26,56.48      c0,0,6.27,3.14,9.41,0l14.12-23.55l14.12,23.55c3.64,1.77,7.99,1.15,10.98-1.57l1.57-3.14l14.12-50.2      c0.93-4.23-1.74-8.42-5.98-9.35c-0.1-0.02-0.2-0.04-0.3-0.06c0,0-4.7-1.57-6.27,1.57c-1.57,3.14-10.98,39.25-10.98,39.25      l-12.55-18.83c0,0-4.7-3.14-7.85,0c-4.54,6.02-8.72,12.31-12.53,18.82L105.63,148.29z"
                            />
                        </g>
                        <g transform="translate(13.67)">
                            <path
                                fill="#FF3CA6"
                                d="M181.39,148.29c-2.24-2.91-6.35-3.6-9.41-1.57c-2.13,1.35-3.34,3.76-3.14,6.27l17.26,56.48      c0,0,6.27,3.14,9.41,0l14.12-23.55l14.12,23.55c3.64,1.77,7.99,1.15,10.98-1.57l1.57-3.14l14.12-50.2      c0.93-4.23-1.74-8.42-5.98-9.35c-0.1-0.02-0.2-0.04-0.3-0.06c0,0-4.7-1.57-6.27,1.57c-1.57,3.14-10.98,39.25-10.98,39.25      l-12.55-18.83c0,0-4.7-3.14-7.85,0c-4.54,6.02-8.72,12.31-12.53,18.82L181.39,148.29z"
                            />
                        </g>
                        <g transform="translate(27.1)">
                            <path
                                fill="#FF3CA6"
                                d="M255.82,148.29c-2.24-2.91-6.35-3.6-9.41-1.57c-2.13,1.35-3.34,3.76-3.14,6.27l17.26,56.48      c0,0,6.27,3.14,9.41,0l14.12-23.55l14.12,23.55c3.64,1.77,7.99,1.15,10.98-1.57l1.57-3.14l14.12-50.2      c0.93-4.23-1.74-8.42-5.98-9.35c-0.1-0.02-0.2-0.04-0.3-0.06c0,0-4.7-1.57-6.27,1.57c-1.57,3.14-10.98,39.25-10.98,39.25      l-12.55-18.83c0,0-4.7-3.14-7.85,0c-4.54,6.02-8.72,12.31-12.53,18.82L255.82,148.29z"
                            />
                        </g>
                        <g transform="translate(1.199 16.128)">
                            <rect
                                fill="#FF3CA6"
                                x="99.72"
                                y="234.13"
                                width="32.94"
                                height="32.94"
                            />
                        </g>
                        <path
                            fill="#FF3CA6"
                            d="M290.01,273.79H157.8c-1.95,0-3.53-1.57-3.53-3.52c0,0,0-0.01,0-0.01v-7.05     c0-1.95,1.57-3.53,3.52-3.53c0,0,0.01,0,0.01,0h132.21c1.95,0,3.53,1.57,3.53,3.52c0,0,0,0.01,0,0.01v7.06     C293.54,272.21,291.96,273.79,290.01,273.79C290.01,273.79,290.01,273.79,290.01,273.79z"
                        />
                        <path
                            fill="#FF3CA6"
                            d="M354.33,273.79h-44.71c-1.95,0-3.53-1.57-3.53-3.52c0,0,0-0.01,0-0.01v-7.05     c0-1.95,1.57-3.53,3.52-3.53c0,0,0.01,0,0.01,0h44.71c1.95,0,3.53,1.57,3.53,3.52c0,0,0,0.01,0,0.01v7.06     C357.86,272.21,356.29,273.79,354.33,273.79C354.34,273.79,354.33,273.79,354.33,273.79z"
                        />
                        <path
                            fill="#FF3CA6"
                            d="M352.76,334.98h-44.71c-1.95,0-3.53-1.57-3.53-3.52c0,0,0-0.01,0-0.01v-7.05     c0-1.95,1.57-3.53,3.52-3.53c0,0,0.01,0,0.01,0h44.71c1.95,0,3.53,1.57,3.53,3.52c0,0,0,0.01,0,0.01v7.06     C356.29,333.41,354.72,334.98,352.76,334.98C352.77,334.98,352.76,334.98,352.76,334.98z"
                        />
                        <g transform="translate(1.199 25.482)">
                            <rect
                                fill="#FF3CA6"
                                x="99.72"
                                y="285.97"
                                width="32.94"
                                height="32.94"
                            />
                        </g>
                        <path
                            fill="#FF3CA6"
                            d="M290.01,334.98H157.8c-1.95,0-3.53-1.57-3.53-3.52c0,0,0-0.01,0-0.01v-7.05     c0-1.95,1.57-3.53,3.52-3.53c0,0,0.01,0,0.01,0h132.21c1.95,0,3.53,1.57,3.53,3.52c0,0,0,0.01,0,0.01v7.06     C293.54,333.41,291.96,334.98,290.01,334.98C290.01,334.98,290.01,334.98,290.01,334.98z"
                        />
                    </g>
                </g>
                <g transform="translate(52.13 2.606)">
                    <circle fill="#FFFFFF" cx="305.08" cy="33.66" r="16.35" />
                    <circle fill="#FFFFFF" cx="350.88" cy="33.66" r="16.35" />
                    <circle fill="#FFFFFF" cx="396.67" cy="33.66" r="16.35" />
                </g>
            </g>
        </SVGIcon>
    );
}
