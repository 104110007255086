import React, { useReducer } from 'react';

import { contactActions } from '../Actions';
import { ContactContext } from '../Context';
import { contactReducer } from '../Reducer';
import { contactStore } from '../Store';

const ContactContextProvider = props => {
    const [state, dispatch] = useReducer(contactReducer, contactStore);

    const value = {
        contacts: state.contacts,
        start: value => {
            dispatch({ type: contactActions.START, value });
        },
        complete: value => {
            dispatch({ type: contactActions.COMPLETE, value });
        },
        addContact: value => {
            dispatch({ type: contactActions.ADD_CONTACT, value });
        },
        delContact: value => {
            dispatch({ type: contactActions.DEL_CONTACT, value });
        },
    };

    return <ContactContext.Provider value={value}>{props.children}</ContactContext.Provider>;
};

export default ContactContextProvider;
