import './style.scss';
import PropTypes from 'prop-types';
import React from 'react';

import { Div, H5, Image, TextOverflowTooltip } from '../index';

const IconTitleCard = ({ customClass, imageUrl, title, onClick }) => {
    return (
        <Div className={'STemplateView ' + (customClass ? customClass : '')} onClick={onClick}>
            <Div className={'STemplateViewInner'}>
                <Div className={'STVIImage'}>
                    <Image src={imageUrl} alt={title} displayName={title} />
                </Div>
                <Div className={'STVITitle'}>
                    <H5>
                        <TextOverflowTooltip tooltipContent={title}>{title}</TextOverflowTooltip>
                    </H5>
                </Div>
            </Div>
        </Div>
    );
};

IconTitleCard.propTypes = {
    customClass: PropTypes.string,
    imageUrl: PropTypes.string,
    title: PropTypes.string,
    onClick: PropTypes.func,
};

export default IconTitleCard;
