import React from 'react';

import { SVGIcon } from '../../UIKit/index';

export default function DCFileUpload(props) {
    return (
        <SVGIcon {...props} viewBox="0 0 512 512">
            <g transform="translate(0)">
                <path d="M496.23,67.45h-55.01V14.97c0-8.27-6.7-14.97-14.97-14.97H85.15c-8.27,0-14.97,6.7-14.97,14.97   v52.48h-20C22.6,67.44,0.23,89.78,0.19,117.35v358.73c0.02,19.28,15.65,34.9,34.93,34.93h441.16c19.28-0.02,34.9-15.65,34.93-34.93   V82.43C511.2,74.16,504.5,67.45,496.23,67.45z M100.17,29.95h311.05v127.33h-214.4v-39.99c-0.05-27.56-22.43-49.87-49.99-49.83   h-46.66V29.95z M481.32,476.08c0,2.76-2.24,5-5,5l0,0H35.12c-2.76,0-5-2.24-5-5l0,0V117.35c0-11.04,8.95-20,20-20c0,0,0,0,0,0   h96.71c11.04,0,20,8.95,20,20l0,0v54.9c0,8.27,6.7,14.97,14.97,14.97l0,0h279.5c11.04,0,20,8.95,20,20L481.32,476.08z    M481.32,161.46c-6.3-2.77-13.11-4.19-20-4.18h-20V97.29h39.99V161.46z" />
                <path d="M249.59,112.8h108.87c8.27-0.01,14.97-6.72,14.96-14.99c-0.01-8.26-6.7-14.96-14.96-14.96   H249.59c-8.27-0.01-14.98,6.69-14.99,14.96c-0.01,8.27,6.69,14.98,14.96,14.99C249.57,112.8,249.58,112.8,249.59,112.8z" />
                <path d="M268.67,224.69c-4.14-7.16-13.3-9.61-20.46-5.47c-2.27,1.31-4.16,3.2-5.47,5.47l-69.03,119.58   c-4.14,7.16-1.68,16.32,5.48,20.46c2.28,1.32,4.86,2.01,7.49,2.01h24.62v50.68c0.02,18.57,15.07,33.61,33.64,33.64h21.55   c18.57-0.02,33.61-15.07,33.64-33.64v-50.68h24.62c8.27,0,14.98-6.7,14.98-14.97c0-2.63-0.69-5.22-2.01-7.49L268.67,224.69z    M285.16,336.78c-8.27,0-14.97,6.7-14.97,14.97v65.65c-0.01,2.03-1.66,3.68-3.69,3.69h-21.55c-2.03-0.01-3.68-1.66-3.69-3.69   v-65.65c0-8.27-6.7-14.97-14.97-14.97h-13.66l43.1-74.67l43.1,74.67L285.16,336.78z" />
            </g>
        </SVGIcon>
    );
}
