import React from 'react';

import { SVGIcon } from '../../UIKit/index';

export default function DCUsersFill(props) {
    return (
        <SVGIcon {...props} viewBox="0 0 512 392">
            <g transform="translate(0 -56.685)">
                <g transform="translate(2.368 57.142)">
                    <g transform="translate(0 0)">
                        <path d="M152.55,12.46C97.74,9.23,50.7,51.05,47.48,105.86c-3.22,54.81,38.6,101.85,93.41,105.08     c54.81,3.22,101.85-38.6,105.08-93.41c0.11-1.96,0.17-3.91,0.17-5.87C247.59,58.46,205.75,14.11,152.55,12.46z" />
                    </g>
                </g>
                <g transform="translate(11.008 56.685)">
                    <g transform="translate(0 0)">
                        <path d="M356.5,0.96h-0.63c-21.75,0.22-42.41,9.56-56.95,25.74c-30.61,34.09-30.18,85.9,0.99,119.48     c14.65,15.77,35.19,24.75,56.72,24.8h0.65c21.75-0.22,42.41-9.56,56.95-25.74c14.9-16.37,23.01-37.8,22.68-59.94     C437.73,39.91,401.88,2.31,356.5,0.96z" />
                    </g>
                </g>
                <g transform="translate(10.332 64)">
                    <g>
                        <path d="M361.26,185.01h-7.85c-34.82-0.04-68.4,12.91-94.18,36.31     c42.05,32.17,67.87,81.16,70.64,134.02h171.8v-29.9C501.58,247.93,438.77,185.11,361.26,185.01z" />
                    </g>
                </g>
                <g transform="translate(0 65.108)">
                    <g>
                        <path d="M310.14,354.21c-5.04-79.43-70.94-141.28-150.53-141.27h-9.37     c-83.29,0-150.82,67.5-150.85,150.79v17.89h311.03v-17.89C310.43,360.53,310.33,357.35,310.14,354.21z" />
                    </g>
                </g>
            </g>
        </SVGIcon>
    );
}
