import React from 'react';

import { SVGIcon } from 'UIKit/index';
import StoreFront from 'Assets/images/AppLogo/storefront.svg';

export default function DCStorefront(props) {
    return (
        <SVGIcon {...props} viewBox="0 0 2000 2000">
            <image href={StoreFront} x="0" y="0" />
        </SVGIcon>
    );
}
