import React, { useCallback, useState } from 'react';
import DCCrossClose from 'Assets/icons/DCCrossClose';
import { Dialog, Div, Button, IconButton } from 'UIKit/index';
import usePubSub from '../PubSub';
import './style.scss';

export default function Error() {
    const [isError, setIsError] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');

    usePubSub(
        'fatal_error',
        useCallback(val => {
            setIsError(true);
            setErrorMessage(val);
        }, []),
    );

    const handleReloadClick = useCallback(() => {
        window.location.reload();
    }, []);

    return (
        <Dialog
            dialogType={'centerZoom sizeMd'}
            open={isError}
            classes={{ row: 'errorSWDialogMain' }}
        >
            <Div className="errorSWDialogInner">
                <IconButton
                    buttonType={'BlueFillBtn eSWIBtn'}
                    onClick={() => {
                        setIsError(false);
                    }}
                >
                    <DCCrossClose />
                </IconButton>
                <Div className="sSWDIIcon">
                    <Div className="sSWDIAnimation">
                        <Div className="sSWDIACloudWrap1">
                            <Div className="sSWDIACWCloud" />
                        </Div>
                        <Div className="sSWDIACloudWrap2">
                            <Div className="sSWDIACWCloud" />
                        </Div>
                        <Div className="sSWDIACloudWrap3">
                            <Div className="sSWDIACWCloud" />
                        </Div>
                        <Div className="sSWDIACloudWrap4">
                            <Div className="sSWDIACWCloud" />
                        </Div>
                        <Div className="sSWDIACloudWrap5">
                            <Div className="sSWDIACWCloud" />
                        </Div>
                    </Div>
                    <Div className="sSWDIIPeashooter">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 332.838 282">
                            <g transform="translate(-810.581 -736)">
                                <g
                                    transform="translate(910.531 919)"
                                    fill="#89b9f5"
                                    stroke="#1a3a5e"
                                    strokeWidth="2"
                                >
                                    <path
                                        d="M0,0H16a0,0,0,0,1,0,0V72a8,8,0,0,1-8,8H8a8,8,0,0,1-8-8V0A0,0,0,0,1,0,0Z"
                                        stroke="none"
                                    />
                                    <path
                                        d="M2,1H14a1,1,0,0,1,1,1V72a7,7,0,0,1-7,7H8a7,7,0,0,1-7-7V2A1,1,0,0,1,2,1Z"
                                        fill="none"
                                    />
                                </g>
                                <path
                                    d="M975.447,567.777h0s2.549-.989,2.059-9.118-8.824-12.844-14.216-13.236c.1.1-12.942,0-12.942,0l-6.9-7.422-7.01-14.275s-9.687-.765-7.647,5.481c.127.637-3.321.838-3.849-3.536s.3-9.8-4.525-9.654c-2.036.453-1.885,2.64-1.961,6.486.075.453-1.358-.679-4.073-.453.075,0-.83-4.45-5.43-2.79s-2.137,4.924-2.137,4.924-2.231,1.16-2.454.58-.4-9.148-5.578-3.793c-.045,0,0,11.067,0,11.067s-2.35,1.685-5.895-2.832c-3.243-.972-2.64,2.832-2.64,2.832V557.5s-.393,12.632,2.549,17.437,7.844,11.961,22.55,13.04a24.669,24.669,0,0,0,16.57-9.02c.2.2,8.236,4.8,13.824,2.157s8.432-7.451,8.334-10.981h20.039l1.093-1.863.984-1.25"
                                    transform="translate(18.531 424)"
                                    fill="#18395d"
                                />
                                <g
                                    transform="translate(952.531 919)"
                                    fill="#89b9f5"
                                    stroke="#1a3a5e"
                                    strokeWidth="2"
                                >
                                    <path
                                        d="M0,0H16a0,0,0,0,1,0,0V72a8,8,0,0,1-8,8H8a8,8,0,0,1-8-8V0A0,0,0,0,1,0,0Z"
                                        stroke="none"
                                    />
                                    <path
                                        d="M2,1H14a1,1,0,0,1,1,1V72a7,7,0,0,1-7,7H8a7,7,0,0,1-7-7V2A1,1,0,0,1,2,1Z"
                                        fill="none"
                                    />
                                </g>
                                <rect
                                    width="3"
                                    height="18"
                                    rx="1.5"
                                    transform="translate(1061.531 902)"
                                    fill="#18395d"
                                />
                                <rect
                                    width="3"
                                    height="17"
                                    rx="1.5"
                                    transform="translate(1050.531 907)"
                                    fill="#18395d"
                                />
                                <rect
                                    width="3"
                                    height="6"
                                    rx="1.5"
                                    transform="translate(1050.531 897)"
                                    fill="#18395d"
                                />
                                <rect
                                    width="3"
                                    height="12"
                                    rx="1.5"
                                    transform="translate(1061.531 882)"
                                    fill="#18395d"
                                />
                                <rect
                                    width="3"
                                    height="12"
                                    rx="1.5"
                                    transform="translate(985.531 772)"
                                    fill="#18395d"
                                />
                                <rect
                                    width="3"
                                    height="17"
                                    rx="1.5"
                                    transform="translate(970.531 757)"
                                    fill="#18395d"
                                />
                                <rect
                                    width="3"
                                    height="17"
                                    rx="1.5"
                                    transform="translate(937.531 762)"
                                    fill="#18395d"
                                />
                                <rect
                                    width="3"
                                    height="5"
                                    rx="1.5"
                                    transform="translate(937.531 752)"
                                    fill="#18395d"
                                />
                                <rect
                                    width="6"
                                    height="35"
                                    rx="3"
                                    transform="translate(951.531 957)"
                                    fill="#18395d"
                                />
                                <path
                                    d="M895.958,553.533s2.072-3.931,3.506-4.25,3.878-.584,5.153-2.444,13.387-1.487,15.937,2.444c.053-.053,2.284-1.169,4.09,2.656-.159-.053,7.771-.961,6.64,6.959.159-.053-2.178,2.815-.637,4.675.159,0,4.675-5.471,6.587-.691,0,.266.9,3.155-4.268,3.787-.106.425,1.452.516.921,3.066s-6.165,11.944-17.135,14.629-21.591-8.286-22.642-13.3c-1.239-2.505-3.342-5.225,1.848-8.987C896.094,562.422,894.188,555.964,895.958,553.533Z"
                                    transform="translate(18.531 424)"
                                    fill="#fff"
                                />
                                <rect
                                    width="3"
                                    height="13"
                                    rx="1.5"
                                    transform="translate(970.531 736)"
                                    fill="#18395d"
                                />
                                <rect
                                    width="3"
                                    height="33"
                                    rx="1.5"
                                    transform="translate(954.531 742)"
                                    fill="#18395d"
                                />
                                <rect
                                    width="3"
                                    height="6"
                                    rx="1.5"
                                    transform="translate(985.531 762)"
                                    fill="#18395d"
                                />
                                <circle
                                    cx="2.5"
                                    cy="2.5"
                                    r="2.5"
                                    transform="translate(917.531 986)"
                                    fill="#18395d"
                                />
                                <circle
                                    cx="2.5"
                                    cy="2.5"
                                    r="2.5"
                                    transform="translate(929.531 986)"
                                    fill="#18395d"
                                />
                                <path
                                    d="M0,0H10A18,18,0,0,1,28,18v2a4,4,0,0,1-4,4H0a0,0,0,0,1,0,0V0A0,0,0,0,1,0,0Z"
                                    transform="translate(967.531 969)"
                                    fill="#18395d"
                                />
                                <g
                                    transform="translate(1018.531 936)"
                                    fill="#2863a5"
                                    stroke="#193a5e"
                                    strokeWidth="2"
                                >
                                    <path
                                        d="M4,0H29a3,3,0,0,1,3,3V4A15,15,0,0,1,17,19H0a0,0,0,0,1,0,0V4A4,4,0,0,1,4,0Z"
                                        stroke="none"
                                    />
                                    <path
                                        d="M4,1H29a2,2,0,0,1,2,2V4A14,14,0,0,1,17,18H2a1,1,0,0,1-1-1V4A3,3,0,0,1,4,1Z"
                                        fill="none"
                                    />
                                </g>
                                <g
                                    transform="translate(1029.531 931)"
                                    fill="#2863a5"
                                    stroke="#193a5e"
                                    strokeWidth="2"
                                >
                                    <path
                                        d="M0,0H36a3,3,0,0,1,3,3V4A15,15,0,0,1,24,19H0a0,0,0,0,1,0,0V0A0,0,0,0,1,0,0Z"
                                        stroke="none"
                                    />
                                    <path
                                        d="M2,1H36a2,2,0,0,1,2,2V4A14,14,0,0,1,24,18H2a1,1,0,0,1-1-1V2A1,1,0,0,1,2,1Z"
                                        fill="none"
                                    />
                                </g>
                                <path
                                    d="M1008.652,434.382,952.211,545.594"
                                    transform="translate(18.531 424)"
                                    fill="none"
                                    stroke="#18395d"
                                    strokeWidth="1.6"
                                />
                                <path
                                    d="M975.576,434.382l-27.3,100.965"
                                    transform="translate(18.531 424)"
                                    fill="none"
                                    stroke="#18395d"
                                    strokeWidth="1.6"
                                />
                                <path
                                    d="M1013.061,526.051h16.927v37.291s-1.437,17.689-22.394,19.6c-.159.068-24.622,0-24.622,0V563.825h19.044s8.685-.819,10.757-9.354C1012.694,554.47,1013.061,526.051,1013.061,526.051Z"
                                    transform="translate(7.279 429)"
                                    fill="#89b9f5"
                                    stroke="#18395d"
                                    strokeWidth="3"
                                />
                                <path
                                    d="M943.411,434.382v31.951"
                                    transform="translate(18.531 424)"
                                    fill="none"
                                    stroke="#18395d"
                                    strokeWidth="1.6"
                                />
                                <rect
                                    width="5"
                                    height="25"
                                    transform="translate(995.531 993)"
                                    fill="#18395d"
                                />
                                <path
                                    d="M911.7,434.382l20.786,86.178"
                                    transform="translate(18.531 424)"
                                    fill="none"
                                    stroke="#193a5e"
                                    strokeWidth="1.6"
                                />
                                <path
                                    d="M879.081,434.382,929.453,534.67"
                                    transform="translate(18.531 424)"
                                    fill="none"
                                    stroke="#18395d"
                                    strokeWidth="1.6"
                                />
                                <path
                                    d="M895.721,424.568s.607-32.245,26.1-47.507c.539.718,1.194,4.6,1.194,4.6l2.207,1.716H928.9l2.207-1.062,1.144-2.942.327-11.36,2.288-2.7,3.514-1.062,1.88,1.062,2.043,2.288s-15.866,15.2-14.933,58.851c-.467-.233-3.685,1.709-3.685,1.709l-5.525,3.081-5.525,3.081-5.631-.637-4.675-4.993-4.037-2.24-3.081-1.888"
                                    transform="translate(18.531 424)"
                                    fill="#b2cff9"
                                />
                                <path
                                    d="M967.928,377.337s23.618,18.093,24.032,46.96a5.89,5.89,0,0,0,0,2.348l-3.591,1.381-3.453,2.624-5.8,3.315h-3.039l-4.42-1.1-4.42-4.144-4.7-2.072-2.21-2.348s-1.7-41.311-11.574-52.264c.18,0-1.077-6.464-1.077-6.464l1.077-5.877,3.911-1.789,2.515.967,2.37,2.515v5.755l.58,8.366.387,1.306,2.8,1.644,3.385.387,1.451-1.209Z"
                                    transform="translate(18.531 424)"
                                    fill="#b2cff9"
                                />
                                <path
                                    d="M918.311,374.714h-1S879.1,391.8,879.1,434.518c0,.592,10.466-8.761,16.185-8.878s10.621,8.4,14.705,8.286,14.705-6.769,14.705-6.769,6.419-3.735,15.056,5.952a10.565,10.565,0,0,0,8.286,0s9.22-8.637,14.822-6.536,8.053,6.536,10.737,7.353,8.4-1.4,8.4-1.4,8.17-8.637,14.589-5.952a84.093,84.093,0,0,1,13.072,7.353c.233.117-.936-39.843-36.063-58.186-.019.2,1.149-6.753-2.676-7.178s-3.453,1.859-3.453,1.859v5.319s-1.912,7.324-9.615.631c-.106-.106,0-13.015,0-13.015s.212-4.2-5.206-4.834-4.94,5.206-4.94,5.206v13.387s-1.753,3.878-5.047.372c-.053.053,0-8.925,0-8.925s-3.419-5.932-9.934-.744l.039,2.831v-1.7l-.039,11.56s-3.24,7.065-9.562,1.116c-1.009-.531-.584-5.418-.584-5.418v-9.668s-1.753-4.09-4.781-.531c-.053.106-.483,9.35-.483,9.35"
                                    transform="translate(18.531 424)"
                                    fill="none"
                                    stroke="#18395d"
                                    strokeWidth="3"
                                />
                                <path
                                    d="M943.735,480.406l-7.433.272V468.167s11.083-5.374,13.1,4.7v15.2s-.672,4.7-2.771,4.534c.006.059,0,4.313,0,4.313H934.1v-4.936a3.746,3.746,0,0,1-2.5-3.386c-.071-2.61,0-12.331,0-12.331s.694-2.368,3.841-2.123"
                                    transform="translate(20.293 422.598)"
                                    fill="#fff"
                                    stroke="#18395e"
                                    strokeWidth="3"
                                />
                                <path
                                    d="M937.145,480.692l7.319.277V468.2s-10.914-5.486-12.9,4.8v15.516s.661,4.8,2.728,4.629c-.006.06,0,4.4,0,4.4h12.336v-5.04a3.788,3.788,0,0,0,2.466-3.457c.069-2.665,0-12.589,0-12.589a3.374,3.374,0,0,0-3.783-2.168"
                                    transform="translate(-21.797 421.822)"
                                    fill="#fff"
                                    stroke="#18395e"
                                    strokeWidth="3"
                                />
                                <path
                                    d="M1013.061,526.051h16.927V569.57s-1.437,20.643-22.394,22.875c-.159.08-28.622,0-28.622,0V570.133h23.044s8.685-.956,10.757-10.917C1012.694,559.217,1013.061,526.051,1013.061,526.051Z"
                                    transform="translate(18.531 424)"
                                    fill="#89b9f5"
                                    stroke="#18395d"
                                    strokeWidth="3"
                                />
                                <path
                                    d="M954.366,570.611h21.355V592.48H927.672a14.575,14.575,0,0,1-6.606-1.629,9.448,9.448,0,0,1-3.355-4.349c.159.157,8.685-.551,14.1-7.316.239.079,7.65,3.933,12.988,2.439S953.648,573.994,954.366,570.611Z"
                                    transform="translate(18.531 424)"
                                    fill="#89b9f5"
                                    stroke="#18395d"
                                    strokeWidth="3"
                                />
                                <path
                                    d="M3180.293,558.572s-13.405-1.756-18.673,6.029c-2.576,3.981,3.453,7.024,5.561,6.2s9.659-3.863,11.766-2.868S3187.317,561.909,3180.293,558.572Z"
                                    transform="translate(-2240.232 434.329)"
                                    fill="#18395d"
                                />
                            </g>
                        </svg>
                    </Div>
                </Div>
                <Div className="sSWDIInfo">
                    <Div className="sSWDIITitle">
                        {errorMessage?.message ? errorMessage.message : 'Something went wrong'}
                    </Div>
                    <Div className="sSWDIISTitle">
                        {errorMessage?.subTitle
                            ? errorMessage.subTitle
                            : 'please try again or refresh the page'}
                    </Div>
                    <Div className="sSWDIIBtn" onClick={handleReloadClick}>
                        <Button buttonType={'BlueFillBtn'}>Reload</Button>
                    </Div>
                </Div>
            </Div>
        </Dialog>
    );
}
