import React from 'react';

import { SVGIcon } from '../../UIKit/index';

export default function DCHelp1(props) {
    return (
        <SVGIcon {...props} viewBox="0 0 512 512">
            <g transform="translate(0.3 0.3)">
                <g>
                    <g>
                        <circle
                            cx="255.7"
                            cy="373.9"
                            r="24"
                            fill="#005EFF"
                            stroke="#FFFFFF"
                            strokeWidth="7.577"
                        />
                        <path
                            d="M255.7,10.2c-135.9,0-246,110.1-246,246s110.1,246,246,246s246-110.1,246-246     c0.1-135.8-110-245.9-245.7-246C255.9,10.2,255.8,10.2,255.7,10.2z M255.7,463.8c-114.6,0-207.6-92.9-207.6-207.6     S141.1,48.6,255.7,48.6s207.6,92.9,207.6,207.6l0,0c0.1,114.6-92.8,207.5-207.3,207.6C255.9,463.8,255.8,463.8,255.7,463.8z"
                            fill="#005EFF"
                            stroke="#FFFFFF"
                            strokeWidth="7.577"
                        />
                        <path
                            d="M255.7,133.7c-42.4,0.1-76.8,34.5-76.9,76.9c0,10.6,8.6,19.2,19.2,19.2s19.2-8.6,19.2-19.2     c0-21.2,17.2-38.4,38.4-38.4s38.4,17.2,38.4,38.4S276.9,249,255.7,249c-10.6,0-19.2,8.6-19.2,19.2l0,0v48     c0,10.6,8.6,19.2,19.2,19.2c10.6,0,19.2-8.6,19.2-19.2V285c41.1-10.6,65.8-52.5,55.2-93.6C321.4,157.3,290.8,133.6,255.7,133.7     L255.7,133.7z"
                            fill="#005EFF"
                            stroke="#FFFFFF"
                            strokeWidth="7.577"
                        />
                    </g>
                </g>
            </g>
            <g transform="translate(2.99 3.044)">
                <g>
                    <path
                        d="M253.7,42.1c-117,0.5-211.4,95.8-210.8,212.7c0.5,117,95.8,211.4,212.7,210.8    c116.6-0.5,210.8-95.2,210.8-211.8C466,136.7,370.9,42,253.7,42.1z M253.7,426.6c-95.4-0.4-172.3-78.1-171.9-173.5    S159.9,80.8,255.3,81.2c95.1,0.4,171.9,77.6,171.9,172.7C426.8,349.4,349.2,426.7,253.7,426.6z"
                        fill="#D5E5FF"
                    />
                </g>
            </g>
        </SVGIcon>
    );
}
