import React from 'react';

import { SVGIcon } from '../../UIKit/index';

export default function DCUserReview2(props) {
    return (
        <SVGIcon {...props} viewBox="0 0 512 512">
            <g>
                <path
                    fill="#D9EAFF"
                    d="M405.4,391.25c-33.04-0.03-59.83-26.8-59.89-59.84c-0.71-33.07,25.52-60.46,58.6-61.17  c33.07-0.71,60.46,25.52,61.17,58.6c0.02,0.86,0.02,1.72,0,2.57C465.22,364.44,438.44,391.21,405.4,391.25z"
                />
                <path
                    fill="#2B88F8"
                    d="M405.4,391.25c-21.89,0.03-43.21,6.97-60.92,19.82l-21.99,49.66l37.99,50.29h150.71v-14.97  C510.71,437.97,463.47,391.17,405.4,391.25z"
                />
                <path
                    fill="#D9EAFF"
                    d="M76.03,126.46l-46.37,24.25l8.81-53.17L0.18,61.19l53.08-7.49L76.03,0l23.33,53.69l51.52,7.49  l-37.28,36.36l8.81,53.17L76.03,126.46z"
                />
                <path
                    fill="#6B8AAA"
                    d="M293.11,97.41l8.98,53.3l-46.4-24.25l-46.41,24.26l8.98-53.3l-37.43-36.24l52.1-7.49L255.69,0  l23.35,53.69l51.5,7.49L293.11,97.41z"
                />
                <path
                    fill="#D9EAFF"
                    d="M435.34,126.46l-46.37,24.24l8.81-53.17l-37.29-36.34l52.08-7.49L435.34,0l23.33,53.69  l52.52,7.49l-38.28,36.36l8.81,53.17L435.34,126.46z"
                />
                <path
                    fill="#D9EAFF"
                    d="M105.98,391.25c-33.04-0.03-59.83-26.8-59.89-59.84c-0.71-33.07,25.52-60.46,58.59-61.17  c33.07-0.71,60.46,25.52,61.17,58.59c0.02,0.86,0.02,1.72,0,2.58C165.8,364.44,139.02,391.21,105.98,391.25z"
                />
                <path
                    fill="#1876E8"
                    d="M105.98,391.25C47.9,391.16,0.65,437.97,0.18,496.04v14.97h150.71l37.14-44.92l-21.13-55.03  C149.18,398.21,127.86,391.27,105.98,391.25L105.98,391.25z"
                />
                <path
                    fill="#6B8AAA"
                    d="M302.1,150.71l-46.41-24.25V0l23.35,53.69l51.5,7.49l-37.43,36.23L302.1,150.71z"
                />
                <path
                    fill="#D9EAFF"
                    d="M255.69,240.54c-33.04,0.1-59.79,26.85-59.89,59.89c-0.71,33.07,25.52,60.46,58.6,61.17  c33.07,0.71,60.46-25.52,61.17-58.6c0.02-0.86,0.02-1.72,0-2.57C315.47,267.39,288.72,240.64,255.69,240.54z"
                />
                <path
                    fill="#2B88F8"
                    d="M360.48,466.1v44.92h-209.6V466.1c0-57.88,46.92-104.8,104.8-104.8S360.48,408.22,360.48,466.1z"
                />
                <path
                    fill="#C3DCF9"
                    d="M255.69,361.3V240.54c33.04,0.1,59.79,26.85,59.89,59.89  C315.55,333.65,288.91,360.74,255.69,361.3z"
                />
                <path
                    fill="#005EFF"
                    d="M360.48,466.1v44.92H255.69V361.3C313.45,361.58,360.2,408.33,360.48,466.1z"
                />
            </g>
        </SVGIcon>
    );
}
