import React from 'react';

import { SVGIcon } from '../../UIKit/index';

export default function DCDuplicate2(props) {
    return (
        <SVGIcon {...props} viewBox="0 0 64 64">
            <path d="m3.5 23.727v31.27343a5.50654 5.50654 0 0 0 5.5 5.5h31.273a5.50654 5.50654 0 0 0 5.5-5.5v-31.27343a5.50654 5.50654 0 0 0 -5.5-5.5h-31.273a5.50654 5.50654 0 0 0 -5.5 5.5zm39.273 0v31.27343a2.50263 2.50263 0 0 1 -2.5 2.5h-31.273a2.50263 2.50263 0 0 1 -2.5-2.5v-31.27343a2.50263 2.50263 0 0 1 2.5-2.5h31.273a2.50263 2.50263 0 0 1 2.5 2.5z" />
            <path d="m21.227 12.36371v-2.45508a3.41277 3.41277 0 0 1 3.40918-3.4082h3.68213a1.50016 1.50016 0 0 0 -.00006-3h-3.68202a6.416 6.416 0 0 0 -6.40923 6.4082v2.45508a1.50015 1.50015 0 0 0 3 0z" />
            <path d="m59 34.18207a1.50008 1.50008 0 0 0 -1.5 1.5v3.68164a3.413 3.413 0 0 1 -3.40918 3.40918h-2.45459a1.5 1.5 0 0 0 0 3h2.45459a6.41625 6.41625 0 0 0 6.40918-6.40918v-3.68164a1.50008 1.50008 0 0 0 -1.5-1.5z" />
            <path d="m54.09082 3.50043h-3.68164a1.50015 1.50015 0 0 0 .00006 3h3.68158a3.41277 3.41277 0 0 1 3.40918 3.4082v3.68262a1.5 1.5 0 1 0 3 0v-3.68262a6.416 6.416 0 0 0 -6.40918-6.4082z" />
            <path d="m43.04541 6.50043a1.50016 1.50016 0 0 0 -.00006-3h-7.36371a1.50016 1.50016 0 0 0 .00006 3z" />
            <path d="m59 19.45453a1.50008 1.50008 0 0 0 -1.5 1.5v7.36328a1.50016 1.50016 0 0 0 3-.00006v-7.36322a1.50008 1.50008 0 0 0 -1.5-1.5z" />
            <path d="m14.63623 40.86371h8.5v8.5a1.50016 1.50016 0 0 0 3-.00006v-8.49994h8.5a1.5 1.5 0 0 0 0-3h-8.5v-8.5a1.5 1.5 0 0 0 -3 0v8.5h-8.5a1.50016 1.50016 0 0 0 0 3z" />
        </SVGIcon>
    );
}
