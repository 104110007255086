import React from 'react';

import { SVGIcon } from '../../UIKit/index';

export default function DCMMS(props) {
    return (
        <SVGIcon {...props} viewBox="0 -23 512 512">
            <path d="m491.421875 115.359375c-13.007813-26.609375-32.128906-49.152344-56.832031-67-43.785156-31.636719-105.542969-48.359375-178.589844-48.359375s-134.804688 16.722656-178.589844 48.359375c-24.703125 17.847656-43.824218 40.390625-56.832031 67-13.652344 27.929687-20.578125 60.445313-20.578125 96.640625 0 78.285156 32.285156 138.171875 93.613281 174.195312-8.480469 29.929688-19.492187 48.070313-19.589843 48.230469l-18.984376 30.519531 35.957032.054688h.105468.316407c11.347656 0 63.40625-2.445312 105.734375-44.910156 18.660156 2.597656 38.40625 3.910156 58.847656 3.910156 73.046875 0 134.804688-16.722656 178.589844-48.359375 24.703125-17.847656 43.824218-40.390625 56.832031-67 13.652344-27.933594 20.578125-60.445313 20.578125-96.640625s-6.925781-68.710938-20.578125-96.640625zm-440.796875 161.78125 92.941406-104.863281 28.722656 31.453125-99.507812 112.9375c-9.402344-11.722657-16.792969-24.90625-22.15625-39.527344zm360.539062 66.074219c-36.925781 26.683594-90.582031 40.785156-155.164062 40.785156-22.191406 0-43.363281-1.6875-62.933594-5.007812l-11.136718-1.890626-7.367188 8.558594c-15.835938 18.390625-34.117188 28.207032-49.632812 33.4375 4.03125-10.871094 8.109374-23.917968 11.402343-38.785156l3.394531-15.320312-14.070312-6.941407c-8.519531-4.203125-16.398438-8.890625-23.65625-14.035156l124.226562-140.992187 102.664063 117.976562h106.707031c-7.113281 8.167969-15.253906 15.582031-24.433594 22.214844zm48.722657-62.214844h-112.777344l-120.699219-138.695312-27.523437 31.234374-55.761719-61.070312-102.550781 115.703125c-.367188-5.269531-.574219-10.648437-.574219-16.171875 0-57.902344 20.46875-102.050781 60.835938-131.21875 36.925781-26.679688 90.582031-40.78125 155.164062-40.78125s118.238281 14.101562 155.164062 40.78125c40.367188 29.167969 60.835938 73.316406 60.835938 131.21875 0 25.761719-4.0625 48.792969-12.113281 69zm-64.886719-142c0 16.570312-13.429688 30-30 30s-30-13.429688-30-30 13.429688-30 30-30 30 13.429688 30 30zm0 0" />
        </SVGIcon>
    );
}
