import React, { useCallback, useEffect, useRef, useState } from 'react';

import loadable from '@loadable/component';
import { Redirect, Route, Switch, useRouteMatch, useLocation } from 'react-router-dom';

const Affiliates = loadable(() => import('./Affiliates/Affiliates'));
const Billing = loadable(() => import('./Billing/Billing'));
const Orders = loadable(() => import('./Orders/Orders'));
const Accounts = loadable(() => import('./Accounts/Accounts'));
const Analytics = loadable(() => import('./Analytics/analytics'));
const Announcements = loadable(() => import('./Announcements'));

import './style.scss';

const DashClicks = () => {
    const match = useRouteMatch();
    const location = useLocation();

    const [pages] = useState([
        {
            id: 0,
            title: 'Affiliates',
            name: 'affiliates',
            link: '/dashclicks/affiliates',
            access: true,
        },
        {
            id: 1,
            title: 'Billing',
            name: 'billing',
            link: '/dashclicks/billing',
            access: true,
        },
        {
            id: 2,
            title: 'Orders',
            name: 'orders',
            link: '/dashclicks/orders',
            access: true,
        },
        {
            id: 4,
            title: 'Accounts',
            name: 'accounts',
            link: '/dashclicks/accounts',
            access: true,
        },
        {
            id: 5,
            title: 'Analytics',
            name: 'analytics',
            link: '/dashclicks/analytics',
            access: true,
        },
        {
            id: 6,
            title: 'Announcements',
            name: 'announcements',
            link: '/dashclicks/announcements',
            access: true,
        },
    ]);

    const [activeTab, setActiveTab] = useState({
        id: 0,
        title: 'Billing',
        name: 'billing',
        link: '/dashclicks/billing',
        access: true,
    });
    const firstRender = useRef(true);

    const handleActiveTab = useCallback(tab => {
        setActiveTab(tab);
    }, []);

    useEffect(() => {
        if (firstRender.current) {
            switch (location.pathname) {
                case '/dashclicks/affiliates':
                    setActiveTab(pages[0]);
                    break;
                case '/dashclicks/billing':
                    setActiveTab(pages[1]);
                    break;
                case '/dashclicks/orders':
                    setActiveTab(pages[2]);
                    break;
                case '/dashclicks/accounts':
                    setActiveTab(pages[3]);
                    break;
                case '/dashclicks/analytics':
                    setActiveTab(pages[4]);
                    break;
                case '/dashclicks/announcements':
                    setActiveTab(pages[5]);
                    break;
                default:
            }
            firstRender.current = false;
        }
    }, [location.pathname, pages]);

    return (
        <Switch>
            <Route path={`${match.path}/affiliates`}>
                <Affiliates pages={pages} activeTab={activeTab} handleActiveTab={handleActiveTab} />
            </Route>
            <Route path={`${match.path}/billing`}>
                <Billing pages={pages} activeTab={activeTab} handleActiveTab={handleActiveTab} />
            </Route>
            <Route path={`${match.path}/orders`}>
                <Orders pages={pages} activeTab={activeTab} handleActiveTab={handleActiveTab} />
            </Route>
            <Route path={`${match.path}/accounts`}>
                <Accounts pages={pages} activeTab={activeTab} handleActiveTab={handleActiveTab} />
            </Route>
            <Route path={`${match.path}/analytics`}>
                <Analytics pages={pages} activeTab={activeTab} handleActiveTab={handleActiveTab} />
            </Route>
            <Route path={`${match.path}/announcements`}>
                <Announcements
                    pages={pages}
                    activeTab={activeTab}
                    handleActiveTab={handleActiveTab}
                />
            </Route>
            <Route path={match.path}>
                <Redirect to={`${match.url}/affiliates`} />
            </Route>
        </Switch>
    );
};
export default DashClicks;
