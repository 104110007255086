import React, { Fragment, useCallback, useEffect, useRef, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import _ from 'lodash';

import DCSave from '../../Assets/icons/DCSave';
import {
    Button,
    Div,
    FieldControl,
    Input,
    Label,
    LabelControl,
    Popover,
    RadioButton,
} from '../../UIKit/index';
import debounce from '../../Utils/debounce.js';

import CreateFilterDropdown from './createFilterDropdown';
import FilterCondition from './filterCondition';
import FilterGroup from './filterGroup';
import industryOptions from 'Constants/industry.json';

import './style.scss';
import { getUserDetails } from 'Utils/localStorageUtils';

function FilterBar(props) {
    const {
        fields,
        onFilterChange,
        filterObj,
        isDefault,
        isNewFilter,
        currentFilterTitle,
        filterIsSaving,
    } = props;

    const [groupConditionActive, setGroupConditionActive] = useState(false);
    const [baseGroupOperator, setBaseGroupOperator] = useState('AND');
    const [hasError, setHasError] = useState({});
    const [closeOptions, setCloseOptions] = useState(false);
    const hasCleared = useRef(false);
    const hasSaved = useRef(false);
    const [baseGroup, setBaseGroup] = useState([]);
    let [preventAdding, setPreventAdding] = useState(false);
    const [timeOut, setTimeOut] = useState(1000);
    const [saveSegmentAs, setSaveSegmentAs] = useState('update');
    const [filterName, setFilterName] = useState('');
    const [shareWith, setShareWith] = useState('private');
    const [isValidToShow, setIsValidToShow] = useState(false);
    const preventOnce = useRef(false);
    const filterObjRef = useRef({});

    useEffect(() => {
        if (filterObj && filterObj.baseOperator) {
            preventOnce.current = true;
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (isNewFilter) {
            setSaveSegmentAs('create');
        } else {
            setSaveSegmentAs('update');
        }
    }, [isNewFilter]);

    const handleGroupConditionActiveState = () => {
        setGroupConditionActive(prevOpen => !prevOpen);
    };

    const addToGroup = itemToAdd => {
        setBaseGroup(_baseGroup => {
            return [
                ..._baseGroup,
                {
                    data: [{ id: uuidv4(), ...itemToAdd, isNew: true }],
                    id: uuidv4(),
                    operator: 'AND',
                },
            ];
        });
    };

    const updateBaseGroupOperator = operator => {
        setBaseGroupOperator(operator);
    };

    const removeBaseGroup = id => {
        let newBaseGroup = [...baseGroup];
        newBaseGroup = newBaseGroup.filter(g => g.id !== id);
        setBaseGroup(newBaseGroup);
    };

    const updateChildGroupOperator = (id, operator) => {
        let newBaseGroup = [...baseGroup];
        const foundGroup = newBaseGroup.find(g => g.id === id);
        if (foundGroup) {
            foundGroup.operator = operator;
        }
        setBaseGroup(newBaseGroup);
    };

    const updateBaseGroup = (id, groupData) => {
        setBaseGroup(_baseGroup => {
            let newBaseGroup = [..._baseGroup];
            const foundIndex = newBaseGroup.findIndex(g => g.id === id);
            if (foundIndex !== -1) {
                newBaseGroup[foundIndex] = {
                    ...newBaseGroup[foundIndex],
                    data: [...groupData],
                };
            }
            return newBaseGroup;
        });
    };

    useEffect(() => {
        if (Object.values(hasError).includes(true)) {
            setPreventAdding(true);
        } else {
            setPreventAdding(false);
        }
    }, [hasError]);

    // base group can be empty array for new filter but if it is present it should have value property inside data array.
    const checkValueIsPresent = useCallback(_base => {
        let arrOfValuePresent = [];
        for (const filter of _base) {
            if (filter.data[0]?.value || filter.data[0]?.value === '') {
                arrOfValuePresent.push(true);
            } else arrOfValuePresent.push(false);
        }

        return arrOfValuePresent.some(element => !element);
    }, []);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const generateFilterObj = useCallback(
        debounce(
            (
                _preventAdding,
                _baseGroup,
                _baseGroupOperator,
                _onFilterChange,
                _hasCleared,
                _hasSaved,
                _saveSegmentAs,
                _title,
                _shareWith,
            ) => {
                if (
                    _preventAdding === false &&
                    (_baseGroup.length === 0 || !checkValueIsPresent(_baseGroup))
                ) {
                    const data = [];
                    for (const group of _baseGroup) {
                        const filters = [];
                        for (const childGroup of group.data || []) {
                            // let valueToSend = childGroup.value;
                            // if (
                            //     childGroup.type === 'dropdown' ||
                            //     childGroup.type === 'multipleoptions'
                            // ) {
                            //     valueToSend = valueToSend?.value || '';
                            // }

                            const _filter = {
                                field: childGroup.name,
                                type: childGroup.type,
                                operator: childGroup.operator,
                                value: childGroup.value,
                            };

                            if (childGroup.contactData)
                                _filter.contactData = childGroup.contactData;
                            filters.push(_filter);
                        }
                        data.push({ operator: group.operator, filters });
                    }
                    const dataToSend = {
                        baseOperator: _baseGroupOperator,
                        data: data,
                        hasCleared: _hasCleared,
                        hasSaved: _hasSaved,
                        operationType: _saveSegmentAs,
                        title: _title,
                        isPublic: _shareWith === 'team',
                    };
                    if (dataToSend['data'].length) {
                        setIsValidToShow(true);
                    } else {
                        setIsValidToShow(false);
                    }
                    let setFilter = true;
                    for (const f of data) {
                        if (!f.filters || !f.filters.length) {
                            setFilter = false;
                            break;
                        }
                    }
                    if (setFilter) {
                        _onFilterChange(dataToSend);
                        setLoading(false);
                        setCloseOptions(true);
                    }
                }
            },
            timeOut,
        ),
        [timeOut],
    );

    const callbackRef = useCallback(() => {
        let title = currentFilterTitle;
        if (hasSaved.current) {
            if (saveSegmentAs !== 'update') {
                if (filterName) {
                    title = filterName;
                } else {
                    return;
                }
            }
        }

        generateFilterObj(
            preventAdding,
            baseGroup,
            baseGroupOperator,
            onFilterChange,
            hasCleared.current,
            hasSaved.current,
            saveSegmentAs,
            title,
            shareWith,
        );
        if (hasCleared.current) {
            hasCleared.current = false;
        }
        if (hasSaved.current) {
            hasSaved.current = false;
        }
        setTimeOut(1000);
    }, [
        currentFilterTitle,
        generateFilterObj,
        preventAdding,
        baseGroup,
        baseGroupOperator,
        onFilterChange,
        saveSegmentAs,
        shareWith,
        filterName,
    ]);

    useEffect(() => {
        if (filterObj && filterObj.baseOperator && !preventOnce.current) {
            let flag = false;
            filterObj.data.forEach(group => {
                group.filters.forEach(filter => {
                    if (
                        !['is_unknown', 'has_any_value'].includes(filter.operator) &&
                        filter.value === ''
                    ) {
                        flag = true;
                    }
                    if (
                        ['people', 'person', 'businesses', 'business', 'tags'].includes(
                            filter.name || filter.field,
                        ) &&
                        !filter.contactData
                    ) {
                        flag = true;
                    }
                });
            });
            if (!flag) callbackRef();
        } else {
            preventOnce.current = false;
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [preventAdding, baseGroup, baseGroupOperator]);

    useEffect(() => {
        if (!fields || !fields.length) {
            return;
        }
        setTimeOut(0);
        if (!_.isEqual(filterObjRef.current, filterObj)) {
            filterObjRef.current = filterObj;
            const { baseOperator, data } = filterObj || {};
            if (baseOperator && data.length) {
                setBaseGroupOperator(baseOperator);
                setPreventAdding(false);
                setFilterName('');
                setShareWith('private');
                const filterArr = [];
                for (const filterData of data) {
                    const childGroup = {
                        operator: filterData.operator,
                        id: uuidv4(),
                        data: [],
                    };

                    for (const filter of filterData.filters || []) {
                        const { field, operator, value } = filter;
                        const _filterData = { id: uuidv4() };
                        const fieldData = fields.find(f => f.name === field);
                        if (!fieldData) {
                            continue;
                        }
                        _filterData.name = fieldData.name;
                        _filterData.operator = operator;
                        _filterData.options = fieldData.options;
                        _filterData.title = fieldData.title;
                        _filterData.type = fieldData.type;
                        _filterData.value = value;
                        if (fieldData.type === 'dropdown' || fieldData.type === 'multipleoptions') {
                            let foundValue;
                            if (fieldData.name === 'industry') {
                                foundValue = industryOptions.find(o => o.value === value);
                            } else {
                                if (fieldData.contactData) {
                                    foundValue = fieldData.contactData;
                                } else
                                    foundValue = fieldData.options?.length
                                        ? fieldData.options.find(o => o.value === value)?.value
                                        : value;
                            }
                            if (!foundValue) {
                                foundValue = '';
                            }
                            _filterData.value = foundValue;
                        }
                        childGroup.data.push(_filterData);
                    }
                    filterArr.push(childGroup);
                }
                setBaseGroup(filterArr);
            } else {
                setFilterName('');
                setShareWith('private');
                setPreventAdding(false);
                setBaseGroupOperator('AND');
                setBaseGroup([]);
            }
        } else {
            const filterArr = [];
            for (const filterData of baseGroup) {
                const childGroup = {
                    ...filterData,
                    data: [],
                };

                for (const filter of filterData.data || []) {
                    const { name, value } = filter;
                    const fieldData = fields.find(f => f.name === name);
                    if (!fieldData) {
                        continue;
                    }
                    if (fieldData.type === 'dropdown' || fieldData.type === 'multipleoptions') {
                        const foundValue = (fieldData.options || []).find(o => o.value === value);
                        if (!foundValue) {
                            continue;
                        }
                    }
                    childGroup.data.push(filter);
                }
                filterArr.push(childGroup);
            }
            // setBaseGroup(filterArr);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filterObj, fields]);

    const onClearFilter = () => {
        setTimeOut(0);
        setBaseGroup([]);
        setGroupConditionActive(false);
        setBaseGroupOperator('AND');
        setHasError({});
        setPreventAdding(false);
        hasCleared.current = true;
    };
    const [loading, setLoading] = useState(false);
    const onSaveFilter = () => {
        setLoading(true);
        setTimeOut(0);
        hasSaved.current = true;
        callbackRef();
        setLoading(false);
    };

    useEffect(() => {
        if (!filterIsSaving) {
            setSaveSegmentPoppar(false);
        }
    }, [filterIsSaving]);

    // Save Segment

    const [saveSegmentPoppar, setSaveSegmentPoppar] = useState(false);

    const ssBtnRef = useRef(null);

    const handleSaveSegmentPopparToggle = useCallback(() => {
        setSaveSegmentPoppar(prevOpen => !prevOpen);
    }, []);

    const handleSaveSegmentPopparClose = useCallback(event => {
        if (ssBtnRef.current && ssBtnRef.current.contains(event.target)) {
            return;
        }
        setSaveSegmentPoppar(false);
    }, []);

    const handleSaveSegmentAs = useCallback(event => {
        setSaveSegmentAs(event.target.value);
    }, []);

    const updateShareWith = event => {
        setShareWith(event.target.value);
    };

    const updateFilterName = event => {
        setFilterName(event.target.value);
    };

    const saveBtnRef = useRef(null);
    return !isDefault ? (
        <Div className={'FilterBarMain '}>
            <Div className={'FBMInner '}>
                {baseGroup.map((f, i) => (
                    <Fragment key={f.id}>
                        <FilterGroup
                            preventAdding={preventAdding}
                            updateChildGroupOperator={updateChildGroupOperator}
                            fields={fields}
                            groupFields={f.data}
                            groupID={f.id}
                            operator={f.operator}
                            onRemoveFromBaseGroup={() => removeBaseGroup(f.id)}
                            updateBaseGroup={updateBaseGroup}
                            setHasError={setHasError}
                            closeOptions={closeOptions}
                            setCloseOptions={setCloseOptions}
                        />
                        {baseGroup.length > i + 1 ? (
                            <FilterCondition
                                type={'group'}
                                activeClass={groupConditionActive}
                                handleActionState={handleGroupConditionActiveState}
                                groupOperator={baseGroupOperator}
                                updateGroupOperator={updateBaseGroupOperator}
                            />
                        ) : null}
                    </Fragment>
                ))}

                <CreateFilterDropdown
                    preventAdding={preventAdding}
                    addToGroup={addToGroup}
                    fields={fields}
                />

                {isValidToShow ? (
                    <Button
                        buttonClass={'FBMISaveSegBtn'}
                        iconName={<DCSave />}
                        reference={saveBtnRef}
                        onClick={handleSaveSegmentPopparToggle}
                        disabled={preventAdding}
                    >
                        Save
                    </Button>
                ) : null}

                {isValidToShow ? (
                    <Button buttonClass={'FBMIClearBtn'} onClick={onClearFilter}>
                        Clear
                    </Button>
                ) : null}

                <Popover
                    open={saveSegmentPoppar}
                    anchorEl={saveBtnRef}
                    onClose={handleSaveSegmentPopparClose}
                    placement={'bottom-center'}
                    disablePortal={false}
                    withOutPadding
                >
                    <Div className={'FBMISavePopover'}>
                        <FieldControl>
                            <LabelControl>
                                <Label>Save segment</Label>
                            </LabelControl>
                            <Div className="FBFIFLItem">
                                <RadioButton
                                    checkColor={'var(--dark-blue)'}
                                    label="Create new segment"
                                    id={'CreateNew'}
                                    name="saveas"
                                    value="create"
                                    fullWidth={true}
                                    onChange={handleSaveSegmentAs}
                                    checked={saveSegmentAs === 'create'}
                                />
                                {!isNewFilter ? (
                                    <RadioButton
                                        checkColor={'var(--dark-blue)'}
                                        label={'Save changes to the segment ' + currentFilterTitle}
                                        id={'SaveChanges'}
                                        name="saveas"
                                        value="update"
                                        fullWidth={true}
                                        onChange={handleSaveSegmentAs}
                                        checked={saveSegmentAs === 'update'}
                                    />
                                ) : null}
                            </Div>
                        </FieldControl>

                        {saveSegmentAs === 'create' ? (
                            <Fragment>
                                <FieldControl>
                                    <LabelControl>
                                        <Label props={{ htmlFor: 'FilterName' }} required>
                                            Smart List Name
                                        </Label>
                                    </LabelControl>
                                    <Input
                                        id={'FilterName'}
                                        type="text"
                                        required={true}
                                        value={filterName}
                                        onChange={updateFilterName}
                                        autoFocus
                                    />
                                </FieldControl>

                                <FieldControl>
                                    <LabelControl>
                                        <Label>Share With</Label>
                                    </LabelControl>
                                    <Div className="FBFIFLShare">
                                        {(getUserDetails()?.is_owner ||
                                            getUserDetails()?.role === 'admin') && (
                                            <RadioButton
                                                checkColor={'var(--dark-blue)'}
                                                label="Team"
                                                id={'Team'}
                                                name="share"
                                                value="team"
                                                fullWidth={true}
                                                onChange={updateShareWith}
                                                checked={shareWith === 'team'}
                                            />
                                        )}

                                        <RadioButton
                                            checkColor={'var(--dark-blue)'}
                                            label="Private"
                                            id={'Private'}
                                            name="private"
                                            value="private"
                                            fullWidth={true}
                                            onChange={updateShareWith}
                                            checked={shareWith === 'private'}
                                        />
                                    </Div>
                                </FieldControl>
                            </Fragment>
                        ) : null}

                        <Div className="popoverBtns">
                            <Button
                                loading={filterIsSaving || loading}
                                buttonType={'BlueFillBtn'}
                                onClick={onSaveFilter}
                                disabled={
                                    saveSegmentAs === 'create' ? !filterName?.trim()?.length : false
                                }
                            >
                                Save
                            </Button>
                            <Button
                                buttonType={'RedOutlineBtn'}
                                onClick={handleSaveSegmentPopparClose}
                            >
                                Cancel
                            </Button>
                        </Div>
                    </Div>
                </Popover>
            </Div>
        </Div>
    ) : null;
}

export default FilterBar;
