import React from 'react';

import { SVGIcon } from '../../UIKit/index';

export default function DCAutomation(props) {
    return (
        <SVGIcon {...props} viewBox="0 0 2000 2000" fill="none">
            <g filter="url(#filter0_i)">
                <rect width="2000" height="2000" rx="400" fill="url(#paint0_linear)" />
                <mask
                    id="mask0"
                    mask-type="alpha"
                    maskUnits="userSpaceOnUse"
                    x="0"
                    y="0"
                    width="2000"
                    height="2000"
                >
                    <rect width="2000" height="2000" rx="400" fill="#0083FF" />
                    <rect width="2000" height="2000" rx="400" fill="#15E6CD" />
                </mask>
                <g mask="url(#mask0)">
                    <circle
                        cx="1000"
                        cy="1000"
                        r="388"
                        stroke="#15E6CD"
                        strokeWidth="61"
                        strokeLinecap="round"
                    />
                    <path
                        d="M559.999 697.5C657.092 554.185 821.238 460 1007.38 460C1305.62 460 1547.38 701.766 1547.38 1000C1547.38 1298.23 1305.62 1540 1007.38 1540C838.237 1540 687.256 1462.23 588.243 1340.5"
                        stroke="white"
                        strokeWidth="61"
                        strokeLinecap="round"
                    />
                    <path
                        d="M660.001 411.102C985.24 223.325 1401.12 334.76 1588.9 659.999C1776.67 985.238 1665.24 1401.12 1340 1588.9C1014.76 1776.67 598.88 1665.24 411.104 1340C352.348 1238.23 322.887 1127.59 320.105 1017.84"
                        stroke="#00B6A0"
                        strokeWidth="61"
                        strokeLinecap="round"
                    />
                </g>
            </g>
            <defs>
                <filter
                    id="filter0_i"
                    x="0"
                    y="-26"
                    width="2000"
                    height="2026"
                    filterUnits="userSpaceOnUse"
                    colorInterpolationFilters="sRGB"
                >
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feBlend
                        mode="normal"
                        in="SourceGraphic"
                        in2="BackgroundImageFix"
                        result="shape"
                    />
                    <feColorMatrix
                        in="SourceAlpha"
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                        result="hardAlpha"
                    />
                    <feOffset dy="-26" />
                    <feGaussianBlur stdDeviation="125" />
                    <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
                    <feColorMatrix
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"
                    />
                    <feBlend mode="normal" in2="shape" result="effect1_innerShadow" />
                </filter>
                <linearGradient
                    id="paint0_linear"
                    x1="1000"
                    y1="0"
                    x2="1000"
                    y2="2000"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#5A5A5E" />
                    <stop offset="1" stopColor="#1D1E21" />
                </linearGradient>
            </defs>
        </SVGIcon>
    );
}
