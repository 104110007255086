import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { getInitialsSvg } from 'Utils/generateImage';

export default function Image(prop) {
    const {
        src,
        style,
        alt,
        title,
        className,
        onClick,
        displayName,
        showColoredSvg = false,
        hideNotAvailableSvg = false,
        ...rest
    } = prop;
    const [error, setError] = useState(false);

    if (error) {
        return getInitialsSvg(displayName, showColoredSvg, hideNotAvailableSvg);
    }

    if (src && src !== '404 Not Found') {
        return (
            <img
                {...props}
                src={src}
                alt={alt}
                title={title}
                style={style}
                className={className}
                onClick={onClick}
                onError={() => {
                    setError(true);
                }}
                {...rest}
            />
        );
    } else {
        return getInitialsSvg(displayName, showColoredSvg, hideNotAvailableSvg);
    }
}

Image.propTypes = {
    src: PropTypes.any.isRequired,
    style: PropTypes.object,
    alt: PropTypes.string,
    title: PropTypes.string,
    className: PropTypes.string,
    onClick: PropTypes.func,
    displayName: PropTypes.string,
    showColoredSvg: PropTypes.bool,
    hideNotAvailableSvg: PropTypes.bool,
};
