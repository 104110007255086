import React from 'react';

import { SVGIcon } from '../../UIKit/index';

export default function DCUserCredential(props) {
    return (
        <SVGIcon {...props} viewBox="0 0 512 512">
            <g>
                <g>
                    <path d="M131.46,471.8H60.68c-6.21,0.04-12.1-2.76-15.98-7.61c-3.98-4.9-5.54-11.34-4.23-17.51    c21.23-99.93,108.22-172.26,210.34-174.89c1.7,0,3.4,0,5.09,0c1.7,0,3.44,0,5.14,0c24.65,0.56,49.03,5.28,72.11,13.96    c10.45,3.54,21.8-2.05,25.34-12.5c3.4-10.01-1.59-20.93-11.38-24.92c-3.3-1.24-6.63-2.41-9.98-3.51    c60.12-44.96,72.41-130.15,27.45-190.27C319.61-5.59,234.43-17.88,174.31,27.08s-72.41,130.15-27.45,190.27    c7.79,10.41,17.03,19.66,27.45,27.45C86.2,273.88,20.28,347.71,1.32,438.53c-6.91,32.82,14.09,65.03,46.92,71.94    c4.09,0.86,8.26,1.3,12.44,1.3h70.78c11.04,0,19.99-8.95,19.99-19.99C151.45,480.75,142.5,471.8,131.46,471.8z M159.93,135.99    c0-52.99,42.95-95.95,95.94-95.95c52.99,0,95.95,42.95,95.95,95.95c0,51.18-40.17,93.36-91.3,95.84h-4.65h-4.65    C200.13,229.28,159.99,187.14,159.93,135.99z" />
                    <path d="M496.44,344.47c-8.75-19.27-27.98-31.63-49.15-31.59h-62.48    c-22.09-0.28-42.03,13.2-50.01,33.8c-1.05,2.72-2.33,6.16-3.68,10.19H200.24c-5.41,0-10.59,2.19-14.35,6.07l-34.33,35.38    c-7.58,7.76-7.58,20.15,0,27.91l34.89,35.59c3.76,3.83,8.9,5.98,14.26,5.98h64.97c11.04,0,19.99-8.95,19.99-19.99    s-8.95-19.99-19.99-19.99h-56.59l-15.42-15.68l14.84-15.3h137.42c9.21,0.01,17.23-6.29,19.42-15.24c1.74-7,3.94-13.87,6.58-20.59    c2.08-5.14,7.18-8.41,12.72-8.16h62.38c5.44-0.13,10.43,2.99,12.7,7.93c7.38,16,11.4,33.35,11.82,50.96    c-0.34,17.9-4.3,35.54-11.63,51.87c-2.18,4.99-7.12,8.21-12.56,8.19h-63.13c-5.68-0.12-10.74-3.6-12.89-8.86    c-2.57-6.43-4.64-13.05-6.19-19.79c-2.57-10.74-13.36-17.36-24.1-14.79c-10.74,2.57-17.36,13.36-14.79,24.1    c2.03,8.77,4.74,17.38,8.12,25.73c8.25,20.26,27.92,33.53,49.8,33.59h63.22c21.34,0.03,40.65-12.62,49.15-32.19    c9.57-21.39,14.7-44.51,15.07-67.94C511.22,388.43,506.05,365.57,496.44,344.47z" />
                    <circle cx="424.84" cy="403.76" r="21.12" />
                </g>
            </g>
        </SVGIcon>
    );
}
