import React from 'react';

import { SVGIcon } from '../../UIKit/index';

export default function DCNoContact(props) {
    return (
        <SVGIcon {...props} viewBox="0 0 512 375">
            <g transform="translate(-1404 -379.9)">
                <g transform="translate(1404.194 381.141)">
                    <path
                        fill="#F2F9FC"
                        d="M17,3.2h435.2c9.5,0,17.2,7.7,17.2,17.2v336.1c0,9.5-7.7,17.2-17.2,17.2H17    c-9.5,0-17.2-7.7-17.2-17.2V20.5C-0.2,11,7.5,3.2,17,3.2z"
                    />
                    <g transform="translate(15.096 24.856)">
                        <ellipse fill="#A7BAC2" cx="137.7" cy="129.2" rx="45.2" ry="43.1" />
                        <path
                            fill="#A7BAC2"
                            d="M50.6,271.6h170.2c0,0,1.6-86.7-81.8-88.4c-0.7,0.3-72.8-6-88.4,78.9     C50.8,263.4,50.6,271.6,50.6,271.6z"
                        />
                    </g>
                    <path
                        fill="#A7BAC2"
                        d="M25.7-1.1h417.9c14.3,0,25.9,11.6,25.9,25.9v30.2l0,0H-0.2l0,0V24.8    C-0.2,10.5,11.4-1.1,25.7-1.1z"
                    />
                    <path
                        fill="#A7BAC2"
                        d="M282,128.2h112c5.9,0,10.8,4.8,10.8,10.8l0,0c0,5.9-4.8,10.8-10.8,10.8H282    c-5.9,0-10.8-4.8-10.8-10.8l0,0C271.2,133,276.1,128.2,282,128.2z"
                    />
                    <path
                        fill="#A7BAC2"
                        d="M279.9,171.3h116.3c4.8,0,8.6,3.9,8.6,8.6l0,0c0,4.8-3.9,8.6-8.6,8.6H279.9    c-4.8,0-8.6-3.9-8.6-8.6l0,0C271.2,175.1,275.1,171.3,279.9,171.3z"
                    />
                    <path
                        fill="#A7BAC2"
                        d="M277.6,210.2h117.5c4.4,0,7.9,3.5,7.9,7.9c0,4.4-3.5,7.9-7.9,7.9H277.6c-4.4,0-7.9-3.5-7.9-7.9    C269.7,213.7,273.2,210.2,277.6,210.2z"
                    />
                </g>
                <g transform="translate(1489.224 433.005)">
                    <path
                        fill="#A7BAC2"
                        d="M419.9,218.9c-16-36.4-58.5-53-94.9-37c-23.9,10.5-40.3,33.1-42.8,59.1    c-0.2,2.3-0.3,4.6-0.3,6.9c0,1.1,0,2.3,0.1,3.4c1.9,39.7,35.6,70.4,75.3,68.5c39.7-1.9,70.4-35.6,68.5-75.3    C425.5,235.7,423.5,227,419.9,218.9L419.9,218.9z M375.8,255.2c4.1,4,4.1,10.5,0.1,14.5c-4,4.1-10.5,4.1-14.5,0.1    c0,0-0.1-0.1-0.1-0.1l-7.3-7.3l-7.3,7.3c-4,4.1-10.5,4.1-14.5,0.1c-4.1-4-4.1-10.5-0.1-14.5c0,0,0.1-0.1,0.1-0.1l7.3-7.3l-7.3-7.3    c-4-4.1-3.9-10.6,0.1-14.5c4-3.9,10.4-3.9,14.4,0l3.7,3.7l3.5,3.5l5.2-5.2l2-2c4.1-4,10.6-3.9,14.5,0.1c3.9,4,3.9,10.4,0,14.4    l-7.3,7.3L375.8,255.2z"
                    />
                    <path
                        fill="#F2F9FC"
                        stroke="#A7BAC2"
                        strokeWidth="4"
                        d="M388.3,215.5c-6.2-6.1-16.1-6.1-22.2,0l-3.1,3.1l-8,8l-5.4-5.4l-5.7-5.7    c-6.2-6.1-16.1-6-22.2,0.2c-6,6.1-6,15.9,0,22.1l11.1,11.1L321.6,260c-6.2,6.1-6.3,16-0.2,22.2c6.1,6.2,16,6.3,22.2,0.2    c0.1-0.1,0.1-0.1,0.2-0.2l11.1-11.1l11.1,11.1c6.1,6.1,16.1,6.1,22.2,0s6.1-16.1,0-22.2l0,0l-11.1-11.1l11.1-11.1    C394.4,231.6,394.4,221.7,388.3,215.5z"
                    />
                </g>
            </g>
        </SVGIcon>
    );
}
