import React from 'react';

import { SVGIcon } from '../../UIKit/index';

export default function DCNotification2(props) {
    return (
        <SVGIcon {...props} viewBox="0 0 726 726">
            <g>
                <g>
                    <rect width="726" height="726" rx="6" fill="#f6f8fc" />
                </g>
                <g transform="translate(177.919 152.464)">
                    <path
                        d="M272.675,76.679a12.343,12.343,0,0,1-12.343-12.343V37.009a12.343,12.343,0,0,0-24.666,0V64.336a12.342,12.342,0,0,1-24.665,0V37.009a37.008,37.008,0,1,1,74.017,0V64.336a12.342,12.342,0,0,1-12.342,12.343Z"
                        transform="translate(-62.968)"
                        fill="#7faeff"
                    />
                    <path
                        d="M268.343,37.009V64.336a12.343,12.343,0,0,0,24.666,0V37.009A37.048,37.048,0,0,0,256,0V24.666A12.343,12.343,0,0,1,268.343,37.009Z"
                        transform="translate(-70.959)"
                        fill="#7faeff"
                    />
                    <path
                        d="M242.675,496.017A61.753,61.753,0,0,1,181,434.343,12.343,12.343,0,0,1,193.344,422h98.682a12.344,12.344,0,0,1,12.343,12.343A61.753,61.753,0,0,1,242.675,496.017Z"
                        transform="translate(-57.633 -74.944)"
                        fill="#7faeff"
                    />
                    <path
                        d="M305.292,422H256v74.017a61.753,61.753,0,0,0,61.674-61.673A12.344,12.344,0,0,0,305.292,422Z"
                        transform="translate(-70.959 -74.945)"
                        fill="#7faeff"
                    />
                    <path
                        d="M388.741,382.475H43.341a12.343,12.343,0,0,1-8.912-20.88A193.718,193.718,0,0,0,88.572,226.909V187.475a127.479,127.479,0,1,1,254.959,0v39.434a193.7,193.7,0,0,0,54.2,134.686,12.343,12.343,0,0,1-8.912,20.88Z"
                        transform="translate(-31 -10.733)"
                        fill="#005eff"
                    />
                    <path
                        d="M428.7,382.39a12.343,12.343,0,0,0,8.912-20.88,193.7,193.7,0,0,1-54.143-134.686V187.391A127.627,127.627,0,0,0,256,60V382.39Z"
                        transform="translate(-70.959 -10.649)"
                        fill="#005eff"
                    />
                </g>
            </g>
        </SVGIcon>
    );
}
