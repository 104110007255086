import React from 'react';

import { SVGIcon } from '../../UIKit/index';

export default function DCInstaReportsMenu(props) {
    return (
        <SVGIcon {...props} viewBox="0 0 455 512">
            <g transform="translate(0.393 67.074)">
                <path
                    fill="#E6EFFF"
                    d="M56.97-67.07h340.67c31.36,0,56.78,25.42,56.78,56.78v397.45   c0,31.36-25.42,56.78-56.78,56.78H56.97c-31.36,0-56.78-25.42-56.78-56.78V-10.29C0.19-41.65,25.61-67.07,56.97-67.07z"
                />
                <path
                    fill="#54A1FF"
                    d="M89.22,404.63H41.13c-1.98,0-3.58-1.6-3.58-3.58V259.1c0-1.98,1.6-3.58,3.58-3.58h48.11   c1.98,0,3.58,1.6,3.58,3.58v141.95c0,1.98-1.6,3.58-3.58,3.58C89.23,404.63,89.22,404.63,89.22,404.63z"
                />
                <path
                    fill="#54A1FF"
                    d="M175.79,404.65h-48.11c-1.98,0-3.58-1.6-3.58-3.58V203.78c0-1.98,1.6-3.58,3.58-3.58h48.11   c1.98,0,3.58,1.6,3.58,3.58v197.29C179.36,403.04,177.76,404.65,175.79,404.65z"
                />
                <path
                    fill="#54A1FF"
                    d="M264.19,404.65h-48.17c-1.98,0-3.58-1.6-3.58-3.58V224.05c0-1.98,1.6-3.58,3.58-3.58h48.11   c1.98,0,3.58,1.6,3.58,3.58v177.02C267.71,403.02,266.14,404.62,264.19,404.65z"
                />
                <path
                    fill="#2F8DFF"
                    d="M65.18,255.45v149.18h24.06c1.98,0,3.58-1.6,3.58-3.58V259.1c0-1.98-1.6-3.58-3.58-3.58   L65.18,255.45z"
                />
                <path
                    fill="#2F8DFF"
                    d="M151.75,200.2v204.4h24.06c1.98,0,3.58-1.6,3.58-3.58V203.78c0-1.98-1.6-3.58-3.58-3.58H151.75z   "
                />
                <path
                    fill="#2F8DFF"
                    d="M240.15,220.47v184.17h24.06c1.98,0,3.58-1.6,3.58-3.58V224.05c0-1.98-1.6-3.58-3.58-3.58   H240.15z"
                />
                <g transform="translate(1.811 -60.361)">
                    <path
                        fill="#2F8DFF"
                        d="M46.85,133.74h202.51c3.7,0,6.7-3,6.7-6.7c0-3.7-3-6.7-6.7-6.7H46.85c-3.7,0-6.7,3-6.7,6.7    C40.15,130.74,43.15,133.74,46.85,133.74z"
                    />
                    <path
                        fill="#2F8DFF"
                        d="M249.36,151.04H46.85c-3.7,0-6.7,3-6.7,6.7c0,3.7,3,6.7,6.7,6.7h202.51c3.7,0,6.7-3,6.7-6.7    C256.06,154.04,253.06,151.04,249.36,151.04z"
                    />
                    <path
                        fill="#2F8DFF"
                        d="M249.36,181.74H46.85c-3.7-0.02-6.71,2.97-6.73,6.67c-0.02,3.7,2.97,6.71,6.67,6.73    c0.02,0,0.04,0,0.06,0h202.51c3.7,0.02,6.71-2.97,6.73-6.67c0.02-3.7-2.97-6.71-6.67-6.73    C249.4,181.74,249.38,181.74,249.36,181.74z"
                    />
                    <path
                        fill="#2F8DFF"
                        d="M98.44,212.44H46.85c-3.7-0.02-6.71,2.97-6.73,6.67c-0.02,3.7,2.97,6.71,6.67,6.73    c0.02,0,0.04,0,0.06,0H98.5c3.7,0.02,6.71-2.97,6.73-6.67c0.02-3.7-2.97-6.71-6.67-6.73c-0.02,0-0.04,0-0.06,0H98.44z"
                    />
                </g>
                <g transform="translate(3.031 1.031)">
                    <path
                        fill="#2F8DFF"
                        d="M406.68,239.18L406.68,239.18c-4.22,4.22-11.06,4.23-15.28,0.01c0,0-0.01-0.01-0.01-0.01    l-70.86-70.86l15.29-15.29l70.86,70.86C410.91,228.11,410.91,234.95,406.68,239.18C406.69,239.18,406.69,239.18,406.68,239.18z"
                    />
                    <circle fill="#2F8DFF" cx="289.13" cy="105.45" r="98" />
                    <path
                        fill="#ffffff"
                        d="M345.33,161.66c-31.05,31.04-81.38,31.03-112.42-0.02c-31.04-31.05-31.03-81.38,0.02-112.42    c31.04-31.03,81.35-31.03,112.39,0C376.33,80.29,376.33,130.59,345.33,161.66z"
                    />
                </g>
            </g>
        </SVGIcon>
    );
}
