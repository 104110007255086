import React from 'react';

import { SVGIcon } from '../../UIKit/index';

export default function DCMouseClick(props) {
    return (
        <SVGIcon {...props} viewBox="0 0 512 512">
            <path d="M506.72,421.79l-86.32-86.32l42.34-42.34c5.86-5.78,5.92-15.21,0.14-21.07  c-1.67-1.7-3.73-2.97-6-3.71l-254-84.58c-7.79-2.67-16.26,1.48-18.92,9.27c-1.07,3.13-1.07,6.53,0,9.65l84.66,254  c2.55,7.83,10.95,12.11,18.78,9.56c2.27-0.74,4.32-2.01,6-3.71l42.34-42.34l86.32,86.32c5.8,5.84,15.23,5.88,21.07,0.09  c0.03-0.03,0.06-0.06,0.09-0.09l63.5-63.5c5.87-5.77,5.94-15.21,0.17-21.07C506.83,421.9,506.77,421.85,506.72,421.79z   M432.63,474.71l-86.32-86.32c-5.8-5.84-15.23-5.88-21.07-0.09c-0.03,0.03-0.06,0.06-0.09,0.09l-35.78,35.78l-67.55-202.64  l202.64,67.55l-35.78,35.78c-5.84,5.8-5.88,15.23-0.09,21.07c0.03,0.03,0.06,0.06,0.09,0.09L475,432.35L432.63,474.71z" />
            <path d="M194.73,119.7c8.23,0,14.9-6.67,14.9-14.9V14.86C209.6,6.63,202.91-0.02,194.68,0  c-8.2,0.02-14.83,6.66-14.86,14.86v89.88c-0.04,8.23,6.61,14.93,14.84,14.96C194.68,119.7,194.7,119.7,194.73,119.7z" />
            <path d="M78.39,56.94c-5.84-5.84-15.32-5.84-21.16,0s-5.84,15.32,0,21.16l63.48,63.44  c5.84,5.84,15.32,5.84,21.16,0s5.84-15.32,0-21.16L78.39,56.94z" />
            <path d="M120.71,247.45l-63.5,63.5c-5.84,5.84-5.84,15.32,0,21.16c5.84,5.84,15.32,5.84,21.16,0  l63.44-63.48c5.84-5.84,5.84-15.32,0-21.16c-5.84-5.84-15.32-5.84-21.16,0L120.71,247.45z" />
            <path d="M268.89,141.54l63.5-63.5c5.84-5.84,5.84-15.32,0-21.16c-5.84-5.84-15.32-5.84-21.16,0l0,0  l-63.5,63.5c-5.84,5.84-5.84,15.32,0,21.16C253.57,147.38,263.05,147.38,268.89,141.54L268.89,141.54z" />
            <path d="M119.96,194.46c0-8.23-6.67-14.9-14.9-14.9H15.12c-8.27,0-14.97,6.7-14.97,14.97  s6.7,14.97,14.97,14.97H105c8.23,0.04,14.93-6.61,14.97-14.84C119.96,194.59,119.96,194.52,119.96,194.46z" />
        </SVGIcon>
    );
}
