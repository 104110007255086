import React from 'react';
import { Div, Button } from 'UIKit/index';
import DCRightArrow2 from 'Assets/icons/DCRightArrow2';
import { Link } from 'react-router-dom';

const Checkout = ({ onClick }) => (
    <Div className="CartPopoverFooter">
        <Link to={'/store/cart'}>
            <Button
                buttonType={'BlueFillBtn fullWidth'}
                iconPlacement={'right'}
                iconName={<DCRightArrow2 />}
                onClick={onClick}
            >
                Check Out
            </Button>
        </Link>
    </Div>
);

export default Checkout;
