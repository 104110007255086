import React from 'react';

import { SVGIcon } from '../../UIKit/index';

export default function DCFacebookAds(props) {
    return (
        <SVGIcon {...props} viewBox="0 0 512 460.2">
            <g transform="translate(0 -2.9)">
                <path
                    fill="#D1D4D2"
                    d="M36.1,245.6H20.7c-10.5,0-19-8.5-19.1-19v-48.2c0-10.5,8.5-19.1,19.1-19.1h15.4V245.6z"
                />
                <path
                    fill="#0468FA"
                    d="M174.7,281.4l-115.7-7c-12.6-0.9-22.4-11.4-22.4-24.1v-96.1c0-11,8.5-20.1,19.5-20.9   l118.6-7.2L174.7,281.4L174.7,281.4z"
                />
                <path
                    fill="#ECF0F1"
                    d="M364.1,393.4l-169.4-84.7c-12.2-6.1-20-18.6-20-32.3v-146c0-13.3,7.3-25.5,19.1-31.8   L363.2,7.9c17.6-9.4,39.4-2.8,48.9,14.7c2.8,5.2,4.3,11.1,4.3,17.1v321.4c0,19.9-16.2,36.1-36.1,36.1   C374.7,397.2,369.1,395.9,364.1,393.4"
                />
                <path
                    fill="#0468FA"
                    d="M424.5,159.3h-8.6v86.3h8.6c19-0.1,34.4-15.5,34.5-34.5v-17.3   C458.9,174.7,443.5,159.3,424.5,159.3"
                />
                <path
                    fill="#D1D4D2"
                    d="M484.9,262.9c-4.8,0-8.6-3.9-8.6-8.6c0-1.8,0.6-3.6,1.6-5c9.9-13.5,15.4-29.8,15.7-46.6   c-0.4-16.9-5.8-33.3-15.7-47c-2.6-4-1.5-9.3,2.5-12c3.8-2.5,8.9-1.6,11.7,2c11.8,16.5,18.4,36.2,18.8,56.5   c-0.2,20.5-6.8,40.5-18.9,57C490.4,261.5,487.7,262.8,484.9,262.9"
                />
                <path
                    fill="#0468FA"
                    d="M71.1,275.2V306H97c4.8,0,8.7,3.9,8.7,8.7c0,4.8-3.9,8.7-8.7,8.7l0,0H71.1v17.3H97   c4.8,0,8.7,3.9,8.7,8.7c0,4.8-3.9,8.7-8.7,8.7l0,0H71.1v17.3H97c4.8,0,8.7,3.9,8.7,8.7c0,4.8-3.9,8.7-8.7,8.7l0,0H71.1V410H97   c4.8,0,8.7,3.9,8.7,8.7c0,4.8-3.9,8.7-8.7,8.7l0,0H71.1v5.6c0,16.7,13.5,30.2,30.2,30.2s30.2-13.5,30.2-30.2l0,0v-154L71.1,275.2z"
                />
                <g>
                    <path
                        fill="#4E96FF"
                        d="M105.6,418.2c0-4.8-3.8-8.6-8.6-8.6c0,0,0,0,0,0H71.1v17.3H97    C101.8,426.9,105.6,423.1,105.6,418.2C105.6,418.3,105.6,418.3,105.6,418.2"
                    />
                    <path
                        fill="#4E96FF"
                        d="M105.6,383.7c0-4.8-3.8-8.6-8.6-8.6c0,0,0,0,0,0H71.1v17.3H97    C101.8,392.4,105.6,388.5,105.6,383.7C105.6,383.8,105.6,383.8,105.6,383.7"
                    />
                    <path
                        fill="#4E96FF"
                        d="M105.6,349.2c0-4.8-3.8-8.6-8.6-8.6c0,0,0,0,0,0H71.1v17.3H97    C101.8,357.9,105.6,354,105.6,349.2C105.6,349.2,105.6,349.2,105.6,349.2"
                    />
                    <path
                        fill="#4E96FF"
                        d="M105.6,314.7c0-4.8-3.8-8.6-8.6-8.6c0,0,0,0,0,0H71.1v17.3H97    C101.8,323.4,105.6,319.5,105.6,314.7C105.6,314.7,105.6,314.7,105.6,314.7"
                    />
                </g>
                <path
                    fill="#B6CDEF"
                    d="M362.7,7.9l-15.9,8.5V385l16.8,8.4c17.8,8.9,39.5,1.7,48.4-16.1c2.5-5,3.8-10.5,3.8-16.2V39.7   c0-19.9-16.2-36.1-36.1-36.1C373.8,3.6,368,5.1,362.7,7.9"
                />
                <path
                    fill="#fff"
                    d="M268.3,103.7c-2.1-4.3-7.3-6-11.5-3.9c0,0,0,0,0,0l-38.4,19c-11.2,6-18.2,17.6-18.2,30.3v18.8   c0,4.8,3.9,8.7,8.7,8.7s8.7-3.9,8.7-8.7v-18.8c0-6.2,3.3-12,8.8-15l38.1-18.9C268.6,113.2,270.4,108,268.3,103.7   C268.3,103.7,268.3,103.7,268.3,103.7"
                />
                <path
                    fill="#fff"
                    d="M208.7,185.2c-4.8,0-8.6,3.8-8.6,8.6c0,0,0,0,0,0v17.3c0,4.8,3.9,8.7,8.7,8.7s8.7-3.9,8.7-8.7   l0,0v-17.3C217.4,189.1,213.6,185.2,208.7,185.2C208.8,185.2,208.8,185.2,208.7,185.2"
                />
            </g>
        </SVGIcon>
    );
}
