import React from 'react';

import { SVGIcon } from '../../UIKit/index';

export default function DCUpDownArrow(props) {
    return (
        <SVGIcon {...props} viewBox="0 0 8 15">
            <g transform="translate(-461 -915)">
                <path d="M4,0,8,5H0Z" transform="translate(461 915)" />
                <path d="M4,0,8,5H0Z" transform="translate(469 930) rotate(180)" />
            </g>
        </SVGIcon>
    );
}
