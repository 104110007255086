import React from 'react';

import { SVGIcon } from '../../UIKit/index';

export default function DCGeoLocation2(props) {
    return (
        <SVGIcon {...props} viewBox="0 0 453 512">
            <g transform="translate(-13.151 -6.306)">
                <path
                    fill="#005EFF"
                    d="M240.83,6.31c-75.12,0-136.02,60.9-136.02,136.02l0,0c-0.75,129.78,136.02,290.71,136.02,290.71   s136.81-160.93,136.02-290.71C376.85,67.2,315.95,6.31,240.83,6.31z M240.83,200.03c-31.86,0-57.69-25.83-57.69-57.69   c0-31.86,25.83-57.69,57.69-57.69s57.69,25.83,57.69,57.69l0,0C298.52,174.2,272.69,200.03,240.83,200.03   C240.83,200.03,240.83,200.03,240.83,200.03z"
                />
                <path
                    fill="#D9EAFF"
                    d="M299.62,351.67c-17.9,28.31-37.53,55.49-58.79,81.37c-21.26-25.88-40.89-53.06-58.79-81.37   c-95.86,9.67-166.41,42.44-166.41,81.37c0,46.55,100.83,84.28,225.21,84.28s225.21-37.73,225.21-84.28   C466.03,394.11,395.46,361.34,299.62,351.67z"
                />
                <g transform="translate(21.46 56.343)">
                    <path
                        fill="#6B8AAA"
                        d="M155.03,423.78c-0.33,0-0.66-0.02-0.99-0.06c-36.55-4.67-66.32-13.19-83.87-23.98    c-3.72-2.12-5.02-6.85-2.91-10.57c2.12-3.72,6.85-5.02,10.57-2.91c0.15,0.09,0.31,0.18,0.45,0.28    C94,396.2,121.6,403.94,156,408.35c4.24,0.57,7.22,4.48,6.65,8.72C162.13,420.9,158.88,423.76,155.03,423.78L155.03,423.78z"
                    />
                    <path
                        fill="#6B8AAA"
                        d="M219.37,427.7c-6.85,0-13.78-0.13-20.59-0.37c-4.28-0.16-7.62-3.76-7.46-8.04    c0.16-4.28,3.76-7.62,8.04-7.46c10.98,0.4,22.22,0.44,33.07,0.21c4.23-0.15,7.78,3.15,7.93,7.37c0,0.07,0,0.13,0,0.2    c0.1,4.28-3.29,7.83-7.57,7.93C228.35,427.64,223.88,427.7,219.37,427.7z"
                    />
                </g>
            </g>
        </SVGIcon>
    );
}
