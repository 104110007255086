import { themeActions } from '../Actions';

const templateReducer = (state, action) => {
    switch (action.type) {
        case themeActions.SET_PRIMARY_COLOR:
            return {
                ...state,
                primaryColor: action.value,
            };
        case themeActions.SET_SECONDARY_COLOR:
            return {
                ...state,
                secondaryColor: action.value,
            };
        case themeActions.SET_TEXT_COLOR:
            return {
                ...state,
                textColor: action.value,
            };
        case themeActions.SET_NOTIFICATIONS_COUNT:
            return {
                ...state,
                notificationCount: action.value,
            };
        case themeActions.SET_NOTIFICATION: {
            return {
                ...state,
                notify: {
                    openNotificationbar: action.value.openNotificationbar,
                    activeTab: action.value.activeTab,
                },
            };
        }
        default:
            throw new Error(action.type);
    }
};

export default templateReducer;
