import React from 'react';

import { SVGIcon } from '../../UIKit/index';

export default function DCSMS1(props) {
    return (
        <SVGIcon {...props} viewBox="0 0 512 501">
            <g transform="translate(-922.487 -492.498)">
                <g transform="translate(922.001 492)">
                    <ellipse fill="#D9EAFF" cx="256.17" cy="252" rx="253.81" ry="249.51" />
                    <path
                        fill="#2B88F8"
                        d="M445.93,24.76H81.7c-33.4,0-60.48,27.08-60.48,60.48l0,0v206.55    c0,33.4,27.08,60.48,60.48,60.48c0,0,0,0,0,0c20.52,0,37.16,16.64,37.16,37.16l0,0v45.28c0,7.37,5.98,13.33,13.34,13.33    c4.58,0,8.83-2.35,11.27-6.22l28.09-44.58c17.63-27.99,48.39-44.99,81.48-45.01h192.89c33.4,0,60.48-27.08,60.48-60.48V85.24    C506.42,51.84,479.34,24.76,445.93,24.76z"
                    />
                    <path
                        fill="#1371E2"
                        d="M147.9,352.24c-33.4,0-60.48-27.08-60.48-60.48V85.24c0-33.4,27.08-60.48,60.48-60.48l0,0    H81.66c-33.4,0-60.48,27.08-60.48,60.48l0,0v206.55c0,33.4,27.08,60.48,60.48,60.48c0,0,0,0,0,0c20.52,0,37.16,16.64,37.16,37.16    l0,0v45.28c0,7.37,5.98,13.33,13.34,13.33c4.58,0,8.83-2.35,11.27-6.22l28.09-44.58c3.63-5.73,7.85-11.06,12.6-15.9    C180.35,364.36,165.3,352.26,147.9,352.24z"
                    />
                </g>
                <text
                    fill="#FFFFFF"
                    fontSize="120.451px"
                    fontFamily="Source Sans Pro"
                    transform="matrix(1 0 0 1 1062.03 735.412)"
                >
                    SMS
                </text>
            </g>
        </SVGIcon>
    );
}
