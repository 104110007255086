import React, { useEffect, useState, useCallback, useRef } from 'react';

import PhoneInput, {
    isValidPhoneNumber,
    formatPhoneNumberIntl,
    parsePhoneNumber,
} from 'react-phone-number-input';

import en from 'react-phone-number-input/locale/en.json';
import { Div } from 'UIKit/index';
import CountrySelect from './countrySelect';
import countryDialCode from './countryDialCode.json';
import 'react-phone-number-input/style.css';
import './style.scss';
import PropTypes from 'prop-types';

export default function PhoneNumber(props) {
    const {
        value,
        country = 'US',
        name,
        placeholder,
        autoFocus,
        error,
        id,
        onChange,
        readOnly,
        required,
        inTop,
        disabled,
        disableError,
        resetVal,
        setError = () => {},
        onBlur,
        onClick = () => {},
        onMouseOut,
        parentRef,
        updateValue,
        ifEmpty = false,
        ...rest
    } = props;
    const wrapperRef = useRef();
    const phoneNumber = parsePhoneNumber(value || '');
    const [errorState, setErrorState] = useState(error || false);
    const [phoneVal, setPhoneVal] = useState(value || '');
    const [countryVal, setCountryVal] = useState(phoneNumber?.country || country);
    const [pastCountry, setPastCountry] = useState(null);
    const [pastPhone, setPastPhone] = useState('');
    const fieldId = useRef(id);

    const handleCountryDropdown = useCallback(e => {
        if (e.target.className === 'selected-flag') {
            const size = e.target.getBoundingClientRect();
            const windowInnerHeight = document.body.scrollHeight;

            setTimeout(() => {
                let countryList = wrapperRef.current.querySelector('.country-list');
                if (windowInnerHeight > size.top + 400) {
                    countryList.style.top = `${size.height + size.top + 2}px`;
                    countryList.classList.add('open');
                } else {
                    countryList.style.top = `${size.top - 200 - 2}px`;
                    countryList.classList.add('open');
                }
            }, 0.1);
        }
    }, []);

    let firstRun = useRef(true);
    useEffect(() => {
        if (firstRun.current && phoneNumber) {
            if (value && !isValidPhoneNumber(value)) {
                setErrorState(true);
                setError(true);
                setPhoneVal(value);
                firstRun.current = false;
            } else {
                setPhoneVal(value);
                setCountryVal(phoneNumber?.country || country);
                firstRun.current = false;
            }
        }
    }, [error, phoneNumber, setError, value, country]);

    useEffect(() => {
        if (updateValue && !firstRun.current) {
            if (value && !isValidPhoneNumber(value)) {
                setErrorState(true);
                setError(true);
                setPhoneVal(value);
            } else {
                setPhoneVal(value);
            }
        }
    }, [setError, updateValue, value]);

    useEffect(() => {
        if (resetVal) {
            const tempNumber = parsePhoneNumber(!value.length ? phoneVal.split(' ')[0] : value);
            if (tempNumber) {
                setPhoneVal(tempNumber.number);
                setCountryVal(tempNumber.country || country);

                if (!isValidPhoneNumber(tempNumber.number || '') || !errorState || error) {
                    setErrorState(true);
                    setError(true);
                } else {
                    setErrorState(false);
                    setError(false);
                }
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [resetVal]);

    useEffect(() => {
        if (error) {
            setErrorState(error);
            setError(true);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [error]);

    const handleCountryChange = useCallback(
        val => {
            if (val) {
                if (val !== countryVal) {
                    setPastCountry(countryVal);
                    setPastPhone(phoneVal);
                    setCountryVal(val);
                }
            }
        },
        [countryVal, phoneVal],
    );

    useEffect(() => {
        if (pastCountry && pastPhone) {
            const pastCountryCode = countryDialCode[pastCountry];
            const newCountryCode = countryDialCode[countryVal];
            const splitPhone = pastPhone.split('+' + pastCountryCode);
            const newPhoneVal = `+${newCountryCode}${
                splitPhone[0].trim().length
                    ? splitPhone[0].replace(`+${pastCountryCode.replace(/ /g, '')}`, '')
                    : splitPhone[1]
            }`;

            setPastCountry(null);
            setPhoneVal(formatPhoneNumberIntl(newPhoneVal));
            setErrorState(!isValidPhoneNumber(newPhoneVal));
            setError(!isValidPhoneNumber(newPhoneVal));
            onChange(
                newPhoneVal,
                { dialcode: '+' + countryDialCode[countryVal], name: countryVal },
                null,
                formatPhoneNumberIntl(newPhoneVal.trim()),
                !isValidPhoneNumber(newPhoneVal),
            );
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pastCountry]);

    const handleCountrySelectComponent = useCallback(() => {
        return (
            <CountrySelect
                value={countryVal}
                onChange={handleCountryChange}
                labels={en}
                popoverWidth={360}
                parentRef={parentRef}
            />
        );
    }, [countryVal, handleCountryChange, parentRef]);

    return (
        <Div
            className={
                'DCPhoneNumberWrapper ' +
                (!disableError && errorState === true && value?.length ? 'phoneNumberError' : '') +
                (inTop ? ' DCPNWinTop' : '')
            }
            reference={wrapperRef}
            onClick={handleCountryDropdown}
            onBlur={onBlur}
            onMouseOut={onMouseOut}
            {...rest}
        >
            <form onSubmit={e => e.preventDefault()}>
                <PhoneInput
                    value={phoneVal}
                    placeholder={placeholder}
                    onClick={onClick}
                    numberInputProps={{
                        id: fieldId,
                        name: name,
                        autoFocus: autoFocus,
                        autoComplete: 'off',
                        required: required,
                    }}
                    countrySelectComponent={handleCountrySelectComponent}
                    onChange={_value => {
                        setPhoneVal(_value);
                        setErrorState(!isValidPhoneNumber(_value || ''));
                        setError(!isValidPhoneNumber(_value || ''));
                        onChange(
                            _value || '',
                            { dialcode: '+' + countryDialCode[countryVal], name: countryVal },
                            null,
                            _value?.trim() || '',
                            !isValidPhoneNumber(_value || ''),
                        );
                    }}
                    readOnly={readOnly}
                    disabled={disabled}
                    limitMaxLength={true}
                    addInternationalOption={false}
                    defaultCountry={countryVal}
                    international={true}
                    countryCallingCodeEditable={false}
                    flagUrl={'https://assets.mydashmetrics.com/flag/{XX}.png'}
                />
            </form>
            {!disableError && errorState === true && value?.length === 0 && ifEmpty ? (
                <Div className={'DCPhoneNumberError'}>Please enter valid number</Div>
            ) : null}
            {!disableError && errorState === true && value?.length !== 0 ? (
                <Div className={'DCPhoneNumberError'}>Please enter valid number</Div>
            ) : null}
            {!disableError && errorState === true && value?.length === 0 && ifEmpty ? (
                <Div className={'DCPhoneNumberError'}>Please enter valid number</Div>
            ) : null}
        </Div>
    );
}

PhoneNumber.propTypes = {
    value: PropTypes.any,
    country: PropTypes.any,
    onClick: PropTypes.any,
    name: PropTypes.any,
    placeholder: PropTypes.any,
    autoFocus: PropTypes.any,
    error: PropTypes.any,
    id: PropTypes.any,
    onChange: PropTypes.any,
    readOnly: PropTypes.any,
    required: PropTypes.any,
    inTop: PropTypes.any,
    disabled: PropTypes.any,
    disableError: PropTypes.any,
    resetVal: PropTypes.any,
    setError: PropTypes.any,
    onBlur: PropTypes.any,
    onMouseOut: PropTypes.any,
    parentRef: PropTypes.any,
    ifEmpty: PropTypes.bool,
    updateValue: PropTypes.any,
};
