import React from 'react';

import { SVGIcon } from '../../UIKit/index';

export default function DCKartra(props) {
    return (
        <SVGIcon {...props} viewBox="0 0 512 512">
            <g transform="translate(-70.046 -29.204)">
                <g transform="translate(46.148 -101.627)">
                    <path
                        fill="#696B6C"
                        d="M137.03,130.83H58.02c0,0-28.22,10.26-34.64,35.91v439.18c0,0,9,30.79,37.71,35.91h350.93    L259.13,407.86l-36.94,49.25v64.66H136L137.03,130.83z"
                    />
                    <path
                        fill="#22BCD4"
                        d="M217.7,353.5V130.83h281.54c18.76,2.8,33.17,18.06,34.89,36.94c3.08,32.83,0,443.28,0,443.28    c-4.51,13.37-14.1,24.42-26.69,30.79L315.57,353.5l100.54-96.47l-113.9-2.05L217.7,353.5z"
                    />
                </g>
            </g>
        </SVGIcon>
    );
}
