import React from 'react';

import { SVGIcon } from '../../UIKit/index';

export default function DCPPTFile(props) {
    return (
        <SVGIcon {...props} viewBox="0 0 512 512">
            <path
                d="m392 488h-272a48 48 0 0 1 -48-48v-368a48 48 0 0 1 48-48h224l96 96v320a48 48 0 0 1 -48 48z"
                fill="#005eff33"
            />
            <path
                d="m72 360h368a0 0 0 0 1 0 0v80a48 48 0 0 1 -48 48h-272a48 48 0 0 1 -48-48v-80a0 0 0 0 1 0 0z"
                fill="#005eff"
            />
            <path d="m264 152a72 72 0 1 1 -72 72h72z" fill="#005eff50" />
            <path d="m232 120v72h-72a72 72 0 0 1 72-72z" fill="#fff" />
            <path d="m440 120h-48a48 48 0 0 1 -48-48v-48z" fill="#005eff50" />
            <g fill="#fff">
                <path d="m256 384h-16a8 8 0 0 0 -8 8v64a8 8 0 0 0 16 0v-24h8a24 24 0 0 0 0-48zm0 32h-8v-16h8a8 8 0 0 1 0 16z" />
                <path d="m192 384h-16a8 8 0 0 0 -8 8v64a8 8 0 0 0 16 0v-24h8a24 24 0 0 0 0-48zm0 32h-8v-16h8a8 8 0 0 1 0 16z" />
                <path d="m336 384h-32a8 8 0 0 0 0 16h8v56a8 8 0 0 0 16 0v-56h8a8 8 0 0 0 0-16z" />
            </g>
        </SVGIcon>
    );
}
