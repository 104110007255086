import React from 'react';

import { SVGIcon } from '../../UIKit/index';

export default function DCDropdown(props) {
    return (
        <SVGIcon {...props} viewBox="0 0 512 512">
            <path d="M495.23,356.64H16.17c-8.81,0-15.96-7.14-15.97-15.95v-111.8  c0.04-32.32,26.23-58.51,58.54-58.54h393.92c32.32,0.04,58.51,26.23,58.54,58.54v111.78C511.2,349.49,504.05,356.64,495.23,356.64z   M32.14,324.71h447.13v-95.83c0-14.72-11.94-26.66-26.66-26.66l0,0H58.74c-14.72,0-26.66,11.94-26.66,26.66L32.14,324.71z" />
            <path d="M452.66,511.01H58.74c-32.32-0.04-58.51-26.23-58.54-58.54V340.69  c0-8.82,7.15-15.97,15.97-15.97h479.06c8.82,0,15.97,7.15,15.97,15.97v111.78C511.17,484.78,484.98,510.97,452.66,511.01z   M32.14,356.64v95.83c0,14.72,11.94,26.66,26.66,26.66c0,0,0,0,0,0h393.86c14.72,0,26.66-11.94,26.66-26.66v0v-95.83H32.14z" />
            <path d="M372.81,127.75c-3.16,0-6.26-0.93-8.89-2.69l-47.88-31.93c-7.36-4.91-9.35-14.86-4.44-22.22  c4.91-7.36,14.86-9.35,22.22-4.44l39.06,26.04l39.06-26.04c7.36-4.91,17.31-2.92,22.22,4.44c4.91,7.36,2.92,17.31-4.44,22.22  l-48.04,31.93c-2.63,1.76-5.72,2.69-8.89,2.69H372.81z" />
            <path d="M452.66,202.18H58.74c-32.28-0.04-58.46-26.17-58.54-58.46V58.54C0.23,26.23,26.42,0.04,58.74,0  h393.92c32.32,0.04,58.51,26.23,58.54,58.54v85.18C511.12,176.01,484.94,202.15,452.66,202.18z M58.74,31.95  c-14.72,0-26.66,11.94-26.66,26.66l0,0v85.12c0,14.72,11.94,26.66,26.66,26.66h393.92c14.72,0,26.66-11.94,26.66-26.66v0V58.54  c0-14.72-11.94-26.66-26.66-26.66l0,0L58.74,31.95z" />
            <path d="M261.03,202.18c-8.82,0-15.97-7.15-15.97-15.97V15.97c0.01-8.82,7.16-15.97,15.98-15.97  C269.85,0.01,277,7.16,277,15.97V186.3C276.95,195.08,269.82,202.18,261.03,202.18z" />
            <path d="M404.73,276.81c-11.76,0-21.28-9.53-21.28-21.28c0-11.76,9.53-21.28,21.28-21.28  c11.76,0,21.28,9.53,21.28,21.28C425.99,267.27,416.48,276.79,404.73,276.81z" />
            <path d="M404.73,447.13c-11.76,0-21.28-9.53-21.28-21.28c0-11.76,9.53-21.28,21.28-21.28  c11.76,0,21.28,9.53,21.28,21.28C425.99,437.6,416.48,447.11,404.73,447.13z" />
        </SVGIcon>
    );
}
