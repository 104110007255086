import React from 'react';

import { SVGIcon } from '../../UIKit/index';

export default function DCWebAnalytics(props) {
    return (
        <SVGIcon {...props} viewBox="0 0 512 512">
            <g>
                <path d="M493.63,35.201H18.371C8.241,35.201,0,43.442,0,53.572v294.383c0,10.13,8.241,18.371,18.371,18.371h8.71L4.661,388.747    c-5.857,5.857-5.857,15.352,0,21.209c2.928,2.928,6.767,4.393,10.604,4.393c3.837,0,7.677-1.464,10.604-4.393l43.63-43.63h138.736    v60.769c-24.729,3.929-48.878,10.91-72.184,20.923c-7.611,3.269-11.129,12.089-7.859,19.699    c3.269,7.609,12.091,11.129,19.699,7.858c34.354-14.759,70.733-22.25,108.124-22.264c0.034,0,0.064,0,0.099,0    c37.355,0,73.708,7.466,108.031,22.184l0.188,0.081c1.931,0.83,3.937,1.222,5.914,1.222c5.812,0,11.346-3.4,13.785-9.08    c3.27-7.609-0.248-16.43-7.857-19.7l-0.198-0.085c-23.318-9.998-47.475-16.964-72.21-20.875v-60.733H493.63    c10.13,0,18.37-8.241,18.37-18.371V53.572C512,43.442,503.759,35.201,493.63,35.201z M273.772,423.845h-0.001    c-5.862-0.336-11.747-0.524-17.657-0.524c-0.036,0-0.075,0-0.11,0c-5.95,0.002-11.874,0.195-17.775,0.538v-57.532h35.542V423.845z     M482.006,336.332H99.494l37.637-37.637c11.938,6.089,25.444,9.527,39.738,9.527c48.385,0,87.748-39.363,87.748-87.748    s-39.364-87.748-87.748-87.748c-48.384,0-87.748,39.363-87.748,87.748c0,23.193,9.049,44.31,23.797,60.015l-55.842,55.842H29.994    V65.195h452.012V336.332z M119.115,220.475c0-31.846,25.908-57.754,57.754-57.754c31.846,0,57.754,25.908,57.754,57.754    s-25.908,57.754-57.754,57.754C145.023,278.229,119.115,252.32,119.115,220.475z" />
                <path d="M303.224,222.709c-8.282,0-14.997,6.715-14.997,14.997v57.882c0,8.282,6.715,14.997,14.997,14.997    s14.997-6.715,14.997-14.997v-57.882C318.221,229.424,311.507,222.709,303.224,222.709z" />
                <path d="M350.215,122.729c-8.282,0-14.997,6.715-14.997,14.997v157.863c0,8.282,6.715,14.997,14.997,14.997    s14.997-6.715,14.997-14.997V137.726C365.212,129.444,358.498,122.729,350.215,122.729z" />
                <path d="M397.983,162.721c-8.282,0-14.997,6.715-14.997,14.997v117.87c0,8.282,6.714,14.997,14.997,14.997    c8.282,0,14.997-6.715,14.997-14.997v-117.87C412.98,169.436,406.265,162.721,397.983,162.721z" />
                <path d="M445.783,202.713c-8.282,0-14.997,6.715-14.997,14.997v77.878c0,8.282,6.715,14.997,14.997,14.997    c8.282,0,14.997-6.715,14.997-14.997V217.71C460.78,209.428,454.065,202.713,445.783,202.713z" />
            </g>
        </SVGIcon>
    );
}
