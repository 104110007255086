import React from 'react';

import { SVGIcon } from '../../UIKit/index';

export default function DCPDFFile(props) {
    return (
        <SVGIcon
            {...props}
            width="512"
            height="512"
            viewBox="0 0 512 512"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g clipPath="url(#clip0_1419_824)">
                <path
                    d="M392 488H120C107.27 488 95.0606 482.943 86.0589 473.941C77.0571 464.939 72 452.73 72 440V72C72 59.2696 77.0571 47.0606 86.0589 38.0589C95.0606 29.0571 107.27 24 120 24H344L440 120V440C440 452.73 434.943 464.939 425.941 473.941C416.939 482.943 404.73 488 392 488Z"
                    fill="#005EFF"
                    fillOpacity="0.2"
                />
                <path d="M136 136H216V232H136V136Z" fill="#005EFF" />
                <path
                    d="M72 360H440V440C440 452.73 434.943 464.939 425.941 473.941C416.939 482.943 404.73 488 392 488H120C107.27 488 95.0606 482.943 86.0589 473.941C77.0571 464.939 72 452.73 72 440V360Z"
                    fill="#005EFF"
                />
                <path
                    d="M440 120H392C379.27 120 367.061 114.943 358.059 105.941C349.057 96.9394 344 84.7304 344 72V24L440 120Z"
                    fill="#005EFF"
                    fillOpacity="0.313726"
                />
                <path
                    d="M376 176H248C245.878 176 243.843 175.157 242.343 173.657C240.843 172.157 240 170.122 240 168C240 165.878 240.843 163.843 242.343 162.343C243.843 160.843 245.878 160 248 160H376C378.122 160 380.157 160.843 381.657 162.343C383.157 163.843 384 165.878 384 168C384 170.122 383.157 172.157 381.657 173.657C380.157 175.157 378.122 176 376 176Z"
                    fill="#005EFF"
                    fillOpacity="0.313726"
                />
                <path
                    d="M280 208H248C245.878 208 243.843 207.157 242.343 205.657C240.843 204.157 240 202.122 240 200C240 197.878 240.843 195.843 242.343 194.343C243.843 192.843 245.878 192 248 192H280C282.122 192 284.157 192.843 285.657 194.343C287.157 195.843 288 197.878 288 200C288 202.122 287.157 204.157 285.657 205.657C284.157 207.157 282.122 208 280 208Z"
                    fill="#005EFF"
                    fillOpacity="0.313726"
                />
                <path
                    d="M376 240H248C245.878 240 243.843 239.157 242.343 237.657C240.843 236.157 240 234.122 240 232C240 229.878 240.843 227.843 242.343 226.343C243.843 224.843 245.878 224 248 224H376C378.122 224 380.157 224.843 381.657 226.343C383.157 227.843 384 229.878 384 232C384 234.122 383.157 236.157 381.657 237.657C380.157 239.157 378.122 240 376 240Z"
                    fill="#005EFF"
                    fillOpacity="0.313726"
                />
                <path
                    d="M320 272H136C133.878 272 131.843 271.157 130.343 269.657C128.843 268.157 128 266.122 128 264C128 261.878 128.843 259.843 130.343 258.343C131.843 256.843 133.878 256 136 256H320C322.122 256 324.157 256.843 325.657 258.343C327.157 259.843 328 261.878 328 264C328 266.122 327.157 268.157 325.657 269.657C324.157 271.157 322.122 272 320 272Z"
                    fill="#005EFF"
                    fillOpacity="0.313726"
                />
                <path
                    d="M376 208H312C309.878 208 307.843 207.157 306.343 205.657C304.843 204.157 304 202.122 304 200C304 197.878 304.843 195.843 306.343 194.343C307.843 192.843 309.878 192 312 192H376C378.122 192 380.157 192.843 381.657 194.343C383.157 195.843 384 197.878 384 200C384 202.122 383.157 204.157 381.657 205.657C380.157 207.157 378.122 208 376 208Z"
                    fill="#005EFF"
                    fillOpacity="0.313726"
                />
                <path
                    d="M376 272H352C349.878 272 347.843 271.157 346.343 269.657C344.843 268.157 344 266.122 344 264C344 261.878 344.843 259.843 346.343 258.343C347.843 256.843 349.878 256 352 256H376C378.122 256 380.157 256.843 381.657 258.343C383.157 259.843 384 261.878 384 264C384 266.122 383.157 268.157 381.657 269.657C380.157 271.157 378.122 272 376 272Z"
                    fill="#005EFF"
                    fillOpacity="0.313726"
                />
                <path
                    d="M184 304H136C133.878 304 131.843 303.157 130.343 301.657C128.843 300.157 128 298.122 128 296C128 293.878 128.843 291.843 130.343 290.343C131.843 288.843 133.878 288 136 288H184C186.122 288 188.157 288.843 189.657 290.343C191.157 291.843 192 293.878 192 296C192 298.122 191.157 300.157 189.657 301.657C188.157 303.157 186.122 304 184 304Z"
                    fill="#005EFF"
                    fillOpacity="0.313726"
                />
                <path
                    d="M296 304H216C213.878 304 211.843 303.157 210.343 301.657C208.843 300.157 208 298.122 208 296C208 293.878 208.843 291.843 210.343 290.343C211.843 288.843 213.878 288 216 288H296C298.122 288 300.157 288.843 301.657 290.343C303.157 291.843 304 293.878 304 296C304 298.122 303.157 300.157 301.657 301.657C300.157 303.157 298.122 304 296 304Z"
                    fill="#005EFF"
                    fillOpacity="0.313726"
                />
                <path
                    d="M376 304H328C325.878 304 323.843 303.157 322.343 301.657C320.843 300.157 320 298.122 320 296C320 293.878 320.843 291.843 322.343 290.343C323.843 288.843 325.878 288 328 288H376C378.122 288 380.157 288.843 381.657 290.343C383.157 291.843 384 293.878 384 296C384 298.122 383.157 300.157 381.657 301.657C380.157 303.157 378.122 304 376 304Z"
                    fill="#005EFF"
                    fillOpacity="0.313726"
                />
                <path
                    d="M312 144H248C245.878 144 243.843 143.157 242.343 141.657C240.843 140.157 240 138.122 240 136C240 133.878 240.843 131.843 242.343 130.343C243.843 128.843 245.878 128 248 128H312C314.122 128 316.157 128.843 317.657 130.343C319.157 131.843 320 133.878 320 136C320 138.122 319.157 140.157 317.657 141.657C316.157 143.157 314.122 144 312 144Z"
                    fill="#005EFF"
                    fillOpacity="0.313726"
                />
                <path
                    d="M192 384H160C157.878 384 155.843 384.843 154.343 386.343C152.843 387.843 152 389.878 152 392V456C152 458.122 152.843 460.157 154.343 461.657C155.843 463.157 157.878 464 160 464C162.122 464 164.157 463.157 165.657 461.657C167.157 460.157 168 458.122 168 456V432H176C178.122 432 180.157 431.157 181.657 429.657C183.157 428.157 184 426.122 184 424C184 421.878 183.157 419.843 181.657 418.343C180.157 416.843 178.122 416 176 416H168V400H192C194.122 400 196.157 399.157 197.657 397.657C199.157 396.157 200 394.122 200 392C200 389.878 199.157 387.843 197.657 386.343C196.157 384.843 194.122 384 192 384Z"
                    fill="white"
                />
                <path
                    d="M230 384H224C221.878 384 219.843 384.843 218.343 386.343C216.843 387.843 216 389.878 216 392V456C216 458.122 216.843 460.157 218.343 461.657C219.843 463.157 221.878 464 224 464C226.122 464 228.157 463.157 229.657 461.657C231.157 460.157 232 458.122 232 456V432C232 430 232 431.5 232 429.5C232 428 232 430.122 232 428C232 425.878 232 427 232 425C232 423 232 423 232 421.5V416V400V393.5C232 391 232 393 232 391C232 389 232 391.622 232 389.5C232 387.378 232 390 232 388C232 386 232.122 384 230 384Z"
                    fill="white"
                />
                <path
                    d="M288 464H256C253.878 464 251.843 463.157 250.343 461.657C248.843 460.157 248 458.122 248 456V392C248 389.878 248.843 387.843 250.343 386.343C251.843 384.843 253.878 384 256 384C258.122 384 260.157 384.843 261.657 386.343C263.157 387.843 264 389.878 264 392V416V417.5C264 419.5 264 417 264 419C264 421 264 423.378 264 425.5C264 427.622 264 427.5 264 429.5C264 431 264 430 264 431V432V448H288C290.122 448 292.157 448.843 293.657 450.343C295.157 451.843 296 453.878 296 456C296 458.122 295.157 460.157 293.657 461.657C292.157 463.157 290.122 464 288 464Z"
                    fill="white"
                />
                <path
                    d="M352 384H320C317.878 384 315.843 384.843 314.343 386.343C312.843 387.843 312 389.878 312 392V456C312 458.122 312.843 460.157 314.343 461.657C315.843 463.157 317.878 464 320 464C322.122 464 324.157 463.157 325.657 461.657C327.157 460.157 328 458.122 328 456V432H336C338.122 432 340.157 431.157 341.657 429.657C343.157 428.157 344 426.122 344 424C344 421.878 343.157 419.843 341.657 418.343C340.157 416.843 338.122 416 336 416H328V400H352C354.122 400 356.157 399.157 357.657 397.657C359.157 396.157 360 394.122 360 392C360 389.878 359.157 387.843 357.657 386.343C356.157 384.843 354.122 384 352 384Z"
                    fill="white"
                />
                <path
                    d="M352 464H320C317.878 464 315.843 463.157 314.343 461.657C312.843 460.157 312 458.122 312 456V392C312 389.878 312.843 387.843 314.343 386.343C315.843 384.843 317.878 384 320 384C322.122 384 324.157 384.843 325.657 386.343C327.157 387.843 328 389.878 328 392V416H336C338.122 416 340.157 416.843 341.657 418.343C343.157 419.843 344 421.878 344 424C344 426.122 343.157 428.157 341.657 429.657C340.157 431.157 338.122 432 336 432H328V448H352C354.122 448 356.157 448.843 357.657 450.343C359.157 451.843 360 453.878 360 456C360 458.122 359.157 460.157 357.657 461.657C356.157 463.157 354.122 464 352 464Z"
                    fill="white"
                />
            </g>
            <defs>
                <clipPath id="clip0_1419_824">
                    <rect width="512" height="512" fill="white" />
                </clipPath>
            </defs>
        </SVGIcon>
    );
}
