import React from 'react';

import { SVGIcon } from '../../UIKit/index';

export default function DCGeoLocation1(props) {
    return (
        <SVGIcon {...props} viewBox="0 0 392 512">
            <g>
                <g transform="translate(60.971 0)">
                    <g transform="translate(0 0)">
                        <path d="M380.83,134.31C362.34,75.14,316.23,28.64,257.2,9.67C154.27-23.82,43.68,32.48,10.2,135.42     c-6.37,19.57-9.61,40.02-9.61,60.6c-0.13,42.14,13.52,83.17,38.88,116.84l155.98,198.98l155.8-198.72l0.2-0.26     C389.61,261.62,400.56,195.07,380.83,134.31z M327.55,294.73l-132.11,168.4L63.33,294.73c-21.41-28.45-32.95-63.11-32.87-98.72     c0.04-91.69,74.4-166,166.09-165.96c17.55,0.01,35,2.8,51.68,8.27c49.61,16,88.35,55.11,103.86,104.88     C368.92,194.7,359.78,251.17,327.55,294.73z" />
                    </g>
                </g>
                <g transform="translate(64.069 3.127)">
                    <g transform="translate(0)">
                        <path d="M192.37,87.95c-57.95,0-104.93,46.98-104.93,104.93s46.98,104.93,104.93,104.93     c57.95,0,104.93-46.98,104.93-104.93l0,0C297.24,134.96,250.3,88.02,192.37,87.95z M192.37,267.97     c-41.39,0-74.94-33.55-74.94-74.94s33.55-74.94,74.94-74.94c41.39,0,74.94,33.55,74.94,74.94c0,0,0,0,0,0     c-0.11,41.32-33.62,74.78-74.94,74.82V267.97z" />
                    </g>
                </g>
            </g>
        </SVGIcon>
    );
}
