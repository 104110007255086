import React from 'react';

import { SVGIcon } from '../../UIKit/index';

export default function DCCamera(props) {
    return (
        <SVGIcon {...props} viewBox="0 0 36.174 36.174">
            <path
                xmlns="http://www.w3.org/2000/svg"
                d="M23.921,20.528c0,3.217-2.617,5.834-5.834,5.834s-5.833-2.617-5.833-5.834s2.616-5.834,5.833-5.834   S23.921,17.312,23.921,20.528z M36.174,12.244v16.57c0,2.209-1.791,4-4,4H4c-2.209,0-4-1.791-4-4v-16.57c0-2.209,1.791-4,4-4h4.92   V6.86c0-1.933,1.566-3.5,3.5-3.5h11.334c1.934,0,3.5,1.567,3.5,3.5v1.383h4.92C34.383,8.244,36.174,10.035,36.174,12.244z    M26.921,20.528c0-4.871-3.963-8.834-8.834-8.834c-4.87,0-8.833,3.963-8.833,8.834s3.963,8.834,8.833,8.834   C22.958,29.362,26.921,25.399,26.921,20.528z"
            />
        </SVGIcon>
    );
}
