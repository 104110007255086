import React from 'react';
import PropTypes from 'prop-types';
import './style.scss';

export default function H1({ props, children, className }) {
    return (
        <h1 className={className} {...props}>
            {children}
        </h1>
    );
}
H1.propTypes = {
    children: PropTypes.any,
    className: PropTypes.string,
    props: PropTypes.any,
};
