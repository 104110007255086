import DC3DAddIcon from '../DC3DAdd.js';
import DC4BoxesIcon from '../DC4Boxes.js';
import DCAccountBillingIcon from '../DCAccountBilling.js';
import DCActivateSiteIcon from '../DCActivateSite.js';
import DCActiveCampaignIcon from '../DCActiveCampaign.js';
import DCAddCircleIcon from '../DCAddCircle.js';
import DCAddColumnIcon from '../DCAddColumn.js';
import DCAddDealTagIcon from '../DCAddDealTag.js';
import DCAddFollowerIcon from '../DCAddFollower.js';
import DCAddIconIcon from '../DCAddIcon.js';
import DCAddNewStageIcon from '../DCAddNewStage.js';
import DCAddToMyLeadsIcon from '../DCAddToMyLeads.js';
import DCAddUserOutlineIcon from '../DCAddUserOutline.js';
import DCAdGroupsIcon from '../DCAdGroups.js';
import DCAdsIcon from '../DCAds.js';
import DCAdvancedSearchIcon from '../DCAdvancedSearch.js';
import DCAffiliateIcon from '../DCAffiliate.js';
import DCAffiliateCenterIcon from '../DCAffiliateCenter.js';
import DCAgencyIcon from '../DCAgency.js';
import DCAgencyWebsiteMenuIcon from '../DCAgencyWebsiteMenu.js';
import DCAlarmIcon from '../DCAlarm.js';
import DCAllCallIcon from '../DCAllCall.js';
import DCAmexCardIcon from '../DCAmexCard.js';
import DCAnalyticsIcon from '../DCAnalytics.js';
import DCAppMarketplaceIcon from '../DCAppMarketplace.js';
import DCAppStoreIcon from '../DCAppStore.js';
import DCAppTypeIcon from '../DCAppType.js';
import DCArrowBackIcon from '../DCArrowBack.js';
import DCArrowDownIcon from '../DCArrowDown.js';
import DCArrowNextIcon from '../DCArrowNext.js';
import DCArrowUpIcon from '../DCArrowUp.js';
import DCAttachmentIcon from '../DCAttachment.js';
import DCAttachment1Icon from '../DCAttachment1.js';
import DCAtTheRateIcon from '../DCAtTheRate.js';
import DCAutoCreateContactsIcon from '../DCAutoCreateContacts.js';
import DCAutoCreateDealsIcon from '../DCAutoCreateDeals.js';
import DCAutomationIcon from '../DCAutomation.js';
import DCAutomation1Icon from '../DCAutomation1.js';
import DCAutomationMenuIcon from '../DCAutomationMenu.js';
import DCAutomationsLogIcon from '../DCAutomationsLog.js';
import DCAverageIcon from '../DCAverage.js';
import DCAwardTagIcon from '../DCAwardTag.js';
import DCBarChartIcon from '../DCBarChart.js';
import DCBarChart1Icon from '../DCBarChart1.js';
import DCBarChart2Icon from '../DCBarChart2.js';
import DCBarsIcon from '../DCBars.js';
import DCBatteryIcon from '../DCBattery.js';
import DCTiktokIcon from '../DCTiktok.js';
import DCBellIcon from '../DCBell.js';
import DCBillingIcon from '../DCBilling.js';
import DCBingIcon from '../DCBing.js';
import DCBing1Icon from '../DCBing1.js';
import DCBlogPostIcon from '../DCBlogPost.js';
import DCBlueStarIcon from '../DCBlueStar.js';
import DCBookmarkIcon from '../DCBookmark.js';
import DCBookSearchIcon from '../DCBookSearch.js';
import DCBoxChangeIcon from '../DCBoxChange.js';
import DCBoxCheckIcon from '../DCBoxCheck.js';
import DCBoxControlIcon from '../DCBoxControl.js';
import DCBoxNumbersIcon from '../DCBoxNumbers.js';
import DCBoxViewTypeIcon from '../DCBoxViewType.js';
import DCBrandingIcon from '../DCBranding.js';
import DCBrowserNotificationIcon from '../DCBrowserNotification.js';
import DCBuildingIcon from '../DCBuilding.js';
import DCBuilding1Icon from '../DCBuilding1.js';
import DCBuildingsOutlineIcon from '../DCBuildingsOutline.js';
import DCBuildInstaReport1Icon from '../DCBuildInstaReport1.js';
import DCBuildInstaReport2Icon from '../DCBuildInstaReport2.js';
import DCBuiltCrainIcon from '../DCBuiltCrain.js';
import DCBusinessProfileIcon from '../DCBusinessProfile.js';
import DCCalendarIcon from '../DCCalendar.js';
import DCCalendar1Icon from '../DCCalendar1.js';
import DCCalendarDateIcon from '../DCCalendarDate.js';
import DCCallCheckIcon from '../DCCallCheck.js';
import DCCallCloseIcon from '../DCCallClose.js';
import DCCallRailIcon from '../DCCallRail.js';
import DCCallTrackingIcon from '../DCCallTracking.js';
import DCCameraIcon from '../DCCamera.js';
import DCCampaignsIcon from '../DCCampaigns.js';
import DCCampaigns1Icon from '../DCCampaigns1.js';
import DCCapterraIcon from '../DCCapterra.js';
import DCCardBgIcon from '../DCCardBg.js';
import DCCardFormIcon from '../DCCardForm.js';
import DCCardForm1Icon from '../DCCardForm1.js';
import DCCardPayIcon from '../DCCardPay.js';
import DCChatIcon from '../DCChat.js';
import DCChat1Icon from '../DCChat1.js';
import DCChat1FilledIcon from '../DCChat1Filled.js';
import DCChatReviewIcon from '../DCChatReview.js';
import DCChatStarIcon from '../DCChatStar.js';
import DCCheckIcon from '../DCCheck.js';
import DCCheckCircleIcon from '../DCCheckCircle.js';
import DCCheckedBoxIcon from '../DCCheckedBox.js';
import DCCheckListIcon from '../DCCheckList.js';
import DCCheckoutIcon from '../DCCheckout.js';
import DCCheckout1Icon from '../DCCheckout1.js';
import DCCheckSuitIcon from '../DCCheckSuit.js';
import DCCheckSuitCloseIcon from '../DCCheckSuitClose.js';
import DCClassicFormIcon from '../DCClassicForm.js';
import DCClickFunnelsIcon from '../DCClickFunnels.js';
import DCCoffeeCupIcon from '../DCCoffeeCup.js';
import DCColdIcon from '../DCCold.js';
import DCCompaniesIcon from '../DCCompanies.js';
import DCCompareIcon from '../DCCompare.js';
import DCConnectionsIcon from '../DCConnections.js';
import DCConstantContactIcon from '../DCConstantContact.js';
import DCContactInfoIcon from '../DCContactInfo.js';
import DCContactListIcon from '../DCContactList.js';
import DCContactMenuIcon from '../DCContactMenu.js';
import DCContactRecordsIcon from '../DCContactRecords.js';
import DCContactsIcon from '../DCContacts.js';
import DCContentIcon from '../DCContent.js';
import DCControlIcon from '../DCControl.js';
import DCConversationMenuIcon from '../DCConversationMenu.js';
import DCConversationsIcon from '../DCConversations.js';
import DCConversations1Icon from '../DCConversations1.js';
import DCCopyIcon from '../DCCopy.js';
import DCCopy1Icon from '../DCCopy1.js';
import DCCopyLinkIcon from '../DCCopyLink.js';
import DCCredentialsIcon from '../DCCredentials.js';
import DCCreditIcon from '../DCCredit.js';
import DCCrossCloseIcon from '../DCCrossClose.js';
import DCCSVFileIcon from '../DCCSVFile.js';
import DCCupIcon from '../DCCup.js';
import DCCursorIcon from '../DCCursor.js';
import DCCustomDomainIcon from '../DCCustomDomain.js';
import DCCustomEmailIcon from '../DCCustomEmail.js';
import DCCustomFiltersIcon from '../DCCustomFilters.js';
import DCCustomPropertiesIcon from '../DCCustomProperties.js';
import DCDashboardIcon from '../DCDashboard.js';
import DCDashboardMenuIcon from '../DCDashboardMenu.js';
import DCDataTransferIcon from '../DCDataTransfer.js';
import DCDateCalIcon from '../DCDateCal.js';
import DCDateCheckIcon from '../DCDateCheck.js';
import DCDateTimeIcon from '../DCDateTime.js';
import DCDealIcon from '../DCDeal.js';
import DCDealAutomationsIcon from '../DCDealAutomations.js';
import DCDealCaseIcon from '../DCDealCase.js';
import DCDealCRMIcon from '../DCDealCRM.js';
import DCDealMenuIcon from '../DCDealMenu.js';
import DCDealsIcon from '../DCDeals.js';
import DCDealsMenuIcon from '../DCDealsMenu.js';
import DCDesktopIcon from '../DCDesktop.js';
import DCDesktopEyeIcon from '../DCDesktopEye.js';
import DCDetailsCardIcon from '../DCDetailsCard.js';
import DCDetailsCard1Icon from '../DCDetailsCard1.js';
import DCDiamondIcon from '../DCDiamond.js';
import DCDinersClubCardIcon from '../DCDinersClubCard.js';
import DCDirectionsIcon from '../DCDirections.js';
import DCDiscoverCardIcon from '../DCDiscoverCard.js';
import DCDisputeUsersIcon from '../DCDisputeUsers.js';
import DCDocIcon from '../DCDoc.js';
import DCDoc1Icon from '../DCDoc1.js';
import DCDocFileIcon from '../DCDocFile.js';
import DCDocTextIcon from '../DCDocText.js';
import DCDocXFileIcon from '../DCDocXFile.js';
import DCDollarIcon from '../DCDollar.js';
import DCDollarSignIcon from '../DCDollarSign.js';
import DCDownArrowIcon from '../DCDownArrow.js';
import DCDownloadIcon from '../DCDownload.js';
import DCDownload1Icon from '../DCDownload1.js';
import DCDropdownIcon from '../DCDropdown.js';
import DCDuplicateIcon from '../DCDuplicate.js';
import DCDuplicate2Icon from '../DCDuplicate2.js';
import DCEcommerceTemplatesIcon from '../DCEcommerceTemplates.js';
import DCEditIcon from '../DCEdit.js';
import DCEditFormIcon from '../DCEditForm.js';
import DCEdocsIcon from '../DCEdocs.js';
import DCeDocsMenuIcon from '../DCeDocsMenu.js';
import DCEducationIcon from '../DCEducation.js';
import DCEducationCapIcon from '../DCEducationCap.js';
import DCEmailCreditsIcon from '../DCEmailCredits.js';
import DCEmailMicIcon from '../DCEmailMic.js';
import DCEmailOpenIcon from '../DCEmailOpen.js';
import DCEmailOpen1Icon from '../DCEmailOpen1.js';
import DCEmailOpen2Icon from '../DCEmailOpen2.js';
import DCEmailSmsIcon from '../DCEmailSms.js';
import DCEmptyBoxIcon from '../DCEmptyBox.js';
import DCEmptyCircleIcon from '../DCEmptyCircle.js';
import DCEmptySectionsIcon from '../DCEmptySections.js';
import DCEnvelopeIcon from '../DCEnvelope.js';
import DCEnvelope1Icon from '../DCEnvelope1.js';
import DCEnvelopeOpenIcon from '../DCEnvelopeOpen.js';
import DCErrorOutlineIcon from '../DCErrorOutline.js';
import DCExclamationIcon from '../DCExclamation.js';
import DCExportIcon from '../DCExport.js';
import DCExternalWindowIcon from '../DCExternalWindow.js';
import DCEyeIcon from '../DCEye.js';
import DCFacebookIcon from '../DCFacebook.js';
import DCFacebook1Icon from '../DCFacebook1.js';
import DCFacebook2Icon from '../DCFacebook2.js';
import DCFacebook3Icon from '../DCFacebook3.js';
import DCFacebookAdIcon from '../DCFacebookAd.js';
import DCFacebookAd1Icon from '../DCFacebookAd1.js';
import DCFacebookAdsIcon from '../DCFacebookAds.js';
import DCFacebookMessengerIcon from '../DCFacebookMessenger.js';
import DCFileIcon from '../DCFile.js';
import DCFileDownloadIcon from '../DCFileDownload.js';
import DCFileHeaderIcon from '../DCFileHeader.js';
import DCFileLinkIcon from '../DCFileLink.js';
import DCFilesIcon from '../DCFiles.js';
import DCFileStorageIcon from '../DCFileStorage.js';
import DCFileUploadIcon from '../DCFileUpload.js';
import DCFileUpload1Icon from '../DCFileUpload1.js';
import DCFillChatIcon from '../DCFillChat.js';
import DCFillCheckIcon from '../DCFillCheck.js';
import DCFillCloseIcon from '../DCFillClose.js';
import DCCloseIcon from '../DCClose.js';
import DCFillDownArrayIcon from '../DCFillDownArray.js';
import DCFillEnvelopIcon from '../DCFillEnvelop.js';
import DCFillEnvelopeIcon from '../DCFillEnvelope.js';
import DCFilleTransferIcon from '../DCFilleTransfer.js';
import DCFillEyeIcon from '../DCFillEye.js';
import DCFillGearIcon from '../DCFillGear.js';
import DCFillImageIcon from '../DCFillImage.js';
import DCFillInstagramIcon from '../DCFillInstagram.js';
import DCFillLinkedInIcon from '../DCFillLinkedIn.js';
import DCFillLockIcon from '../DCFillLock.js';
import DCFillMessageIcon from '../DCFillMessage.js';
import DCFillPencilIcon from '../DCFillPencil.js';
import DCFillPhoneIcon from '../DCFillPhone.js';
import DCFillQuestionIcon from '../DCFillQuestion.js';
import DCFillSMSIcon from '../DCFillSMS.js';
import DCFillTwitterIcon from '../DCFillTwitter.js';
import DCFillUpArrayIcon from '../DCFillUpArray.js';
import DCFillWebIcon from '../DCFillWeb.js';
import DCFillYoutubeIcon from '../DCFillYoutube.js';
import DCFilterIcon from '../DCFilter.js';
import DCFilter1Icon from '../DCFilter1.js';
import DCClipboardIcon from '../DCClipboard.js';
import DCAnniversaryIcon from '../DCAnniversary.js';
import DCFirstCallIcon from '../DCFirstCall.js';
import DCFixingToolIcon from '../DCFixingTool.js';
import DCFlagIcon from '../DCFlag.js';
import DCFlyArrowIcon from '../DCFlyArrow.js';
import DCFlyArrow1Icon from '../DCFlyArrow1.js';
import DCFormIcon from '../DCForm.js';
import DCFormattingIcon from '../DCFormatting.js';
import DCFormPendingIcon from '../DCFormPending.js';
import DCFormsIcon from '../DCForms.js';
import DCForms1Icon from '../DCForms1.js';
import DCFormSubmissionIcon from '../DCFormSubmission.js';
import DCForwardIcon from '../DCForward.js';
import DCFourBoxIcon from '../DCFourBox.js';
import DCFoursquareIcon from '../DCFoursquare.js';
import DCFreshnessIcon from '../DCFreshness.js';
import DCFunnelIcon from '../DCFunnel.js';
import DCG2Icon from '../DCG2.js';
import DCGearToolIcon from '../DCGearTool.js';
import DCGeoLocationIcon from '../DCGeoLocation.js';
import DCGeoLocation1Icon from '../DCGeoLocation1.js';
import DCGeoLocation2Icon from '../DCGeoLocation2.js';
import DCGeoLocation3Icon from '../DCGeoLocation3.js';
import DCGeoLocationsIcon from '../DCGeoLocations.js';
import DCGifIcon from '../DCGif.js';
import DCGIFFileIcon from '../DCGIFFile.js';
import DCGlobeIcon from '../DCGlobe.js';
import DCGoalIcon from '../DCGoal.js';
import DCGoogleIcon from '../DCGoogle.js';
import DCGoogleAdIcon from '../DCGoogleAd.js';
import DCGoogleAd1Icon from '../DCGoogleAd1.js';
import DCGoogleAdsIcon from '../DCGoogleAds.js';
import DCGoogleAnalyticsIcon from '../DCGoogleAnalytics.js';
import DCGrayActivityIcon from '../DCGrayActivity.js';
import DCGrayAnalyticsIcon from '../DCGrayAnalytics.js';
import DCGrayAttachmentIcon from '../DCGrayAttachment.js';
import DCGrayAutomatedIcon from '../DCGrayAutomated.js';
import DCGrayBusinessIcon from '../DCGrayBusiness.js';
import DCGrayCommissionsIcon from '../DCGrayCommissions.js';
import DCGrayConversationsIcon from '../DCGrayConversations.js';
import DCGrayDealsIcon from '../DCGrayDeals.js';
import DCGrayEmailTemplateIcon from '../DCGrayEmailTemplate.js';
import DCGrayFileIcon from '../DCGrayFile.js';
import DCGrayFollowerIcon from '../DCGrayFollower.js';
import DCGrayFormIcon from '../DCGrayForm.js';
import DCGrayGraphIcon from '../DCGrayGraph.js';
import DCGrayInstaReportIcon from '../DCGrayInstaReport.js';
import DCGrayInstaSiteIcon from '../DCGrayInstaSite.js';
import DCGrayLeaderBoardIcon from '../DCGrayLeaderBoard.js';
import DCGrayNoFoundIcon from '../DCGrayNoFound.js';
import DCGrayNotebookIcon from '../DCGrayNotebook.js';
import DCGrayNotesIcon from '../DCGrayNotes.js';
import DCGrayNotificationIcon from '../DCGrayNotification.js';
import DCGrayPeoplesIcon from '../DCGrayPeoples.js';
import DCGrayProjectIcon from '../DCGrayProject.js';
import DCGrayPurchaseHistoryIcon from '../DCGrayPurchaseHistory.js';
import DCGrayReminderIcon from '../DCGrayReminder.js';
import DCGraySEOIcon from '../DCGraySEO.js';
import DCGraySPFormIcon from '../DCGraySPForm.js';
import DCGrayStageIcon from '../DCGrayStage.js';
import DCGraySubAccountIcon from '../DCGraySubAccount.js';
import DCGraySubscriptionIcon from '../DCGraySubscription.js';
import DCGraySuccessfulIcon from '../DCGraySuccessful.js';
import DCGrayUserIcon from '../DCGrayUser.js';
import DCGrayVideoIcon from '../DCGrayVideo.js';
import DCGroupFormIcon from '../DCGroupForm.js';
import DCGroupForm1Icon from '../DCGroupForm1.js';
import DCGrowChartIcon from '../DCGrowChart.js';
import DCGrowChart1Icon from '../DCGrowChart1.js';
import DCHalfCheckIcon from '../DCHalfCheck.js';
import DCHalfCircleCloseIcon from '../DCHalfCircleClose.js';
import DCHalfCircleClose1Icon from '../DCHalfCircleClose1.js';
import DCHandShakeFilledIcon from '../DCHandShakeFilled.js';
import DCHandShakeIcon from '../DCHandShake.js';
import DCHeartFilledIcon from '../DCHeartFilled.js';
import DCHelpIcon from '../DCHelp.js';
import DCHelp1Icon from '../DCHelp1.js';
import DCHelpOutlineIcon from '../DCHelpOutline.js';
import DCHiperCardIcon from '../DCHiperCard.js';
import DCHorizontal3DotsIcon from '../DCHorizontal3Dots.js';
import DCHorizontal3LineIcon from '../DCHorizontal3Line.js';
import DCHorizontalLineIcon from '../DCHorizontalLine.js';
import DCHotIcon from '../DCHot.js';
import DCHtmlCodeIcon from '../DCHtmlCode.js';
import DCHubspotIcon from '../DCHubspot.js';
import DCIceIcon from '../DCIce.js';
import DCIDIcon from '../DCID.js';
import DCImageIcon from '../DCImage.js';
import DCImagePlaceholderIcon from '../DCImagePlaceholder.js';
import DCImportIcon from '../DCImport.js';
import DCInboundIcon from '../DCInbound.js';
import DCIndeterminateCheckBoxIcon from '../DCIndeterminateCheckBox.js';
import DCIndeterminateCheckBox1Icon from '../DCIndeterminateCheckBox1.js';
import DCInfoIcon from '../DCInfo.js';
import DCInfoCircleIcon from '../DCInfoCircle.js';
import DCInsightsIcon from '../DCInsights.js';
import DCInstagramIcon from '../DCInstagram.js';
import DCInstaPageIcon from '../DCInstaPage.js';
import DCInstaReportIcon from '../DCInstaReport.js';
import DCInstareportsIcon from '../DCInstareports.js';
import DCInstaReports1Icon from '../DCInstaReports1.js';
import DCInstaReportsMenuIcon from '../DCInstaReportsMenu.js';
import DCInstaSiteIcon from '../DCInstaSite.js';
import DCInstasitesIcon from '../DCInstasites.js';
import DCInstaSites1Icon from '../DCInstaSites1.js';
import DCInstaSitesMenuIcon from '../DCInstaSitesMenu.js';
import DCIntegrationIcon from '../DCIntegration.js';
import DCIPsIcon from '../DCIPs.js';
import DCJcbCardIcon from '../DCJcbCard.js';
import DCJPEGFileIcon from '../DCJPEGFile.js';
import DCJPGFileIcon from '../DCJPGFile.js';
import DCKartraIcon from '../DCKartra.js';
import DCKeepIcon from '../DCKeep.js';
import DCKeywordsIcon from '../DCKeywords.js';
import DCLaptopIcon from '../DCLaptop.js';
import DCLaptopUserIcon from '../DCLaptopUser.js';
import DCLayoutIcon from '../DCLayout.js';
import DCLeadAutomationsIcon from '../DCLeadAutomations.js';
import DCLeadFinderMenuIcon from '../DCLeadFinderMenu.js';
import DCLeadFlowMenuIcon from '../DCLeadFlowMenu.js';
import DCLeadsIcon from '../DCLeads.js';
import DCLeadTypeIcon from '../DCLeadType.js';
import DCLegendIcon from '../DCLegend.js';
import DCLikeIcon from '../DCLike.js';
import DCLineBreakIcon from '../DCLineBreak.js';
import DCLineChartIcon from '../DCLineChart.js';
import DCLinkIcon from '../DCLink.js';
import DCLink1Icon from '../DCLink1.js';
import DCLink2Icon from '../DCLink2.js';
import DCLinkedinIcon from '../DCLinkedin.js';
import DCListIcon from '../DCList.js';
import DCListDocIcon from '../DCListDoc.js';
import DCListingManagerIcon from '../DCListingManager.js';
import DCListingsManagerIcon from '../DCListingsManager.js';
import DCLiveChatIcon from '../DCLiveChat.js';
import DCLiveChatFilledIcon from '../DCLiveChatFilled.js';
import DCLockIcon from '../DCLock.js';
import DCLogIcon from '../DCLog.js';
import DCLoginCredentialIcon from '../DCLoginCredential.js';
import DCLogoIcon from '../DCLogo.js';
import DCLongTextIcon from '../DCLongText.js';
import DCMailChimpIcon from '../DCMailChimp.js';
import DCMailResendIcon from '../DCMailResend.js';
import DCManychatIcon from '../DCManychat.js';
import DCMapLocationIcon from '../DCMapLocation.js';
import DCMarketplaceIcon from '../DCMarketplace.js';
import DCMasterCardIcon from '../DCMasterCard.js';
import DCMasterCardPIcon from '../DCMasterCardP.js';
import DCMenuIconIcon from '../DCMenuIcon.js';
import DCMerchantCenterMenuIcon from '../DCMerchantCenterMenu.js';
import DCMessageIcon from '../DCMessage.js';
import DCMinusIcon from '../DCMinus.js';
import DCMissionIcon from '../DCMission.js';
import DCMMSIcon from '../DCMMS.js';
import DCMobileIcon from '../DCMobile.js';
import DCMobile1Icon from '../DCMobile1.js';
import DCMouseClickIcon from '../DCMouseClick.js';
import DCMoveIcon from '../DCMove.js';
import DCMoveStageIcon from '../DCMoveStage.js';
import DCMultiOptionIcon from '../DCMultiOption.js';
import DCMyProjectsMenuIcon from '../DCMyProjectsMenu.js';
import DCMyWebIcon from '../DCMyWeb.js';
import DCNoPropertyIcon from '../DCNoProperty.js';
import DCNoProperty1Icon from '../DCNoProperty1.js';
import DCNoAnalyticsIcon from '../DCNoAnalytics.js';
import DCNoBrowserDataIcon from '../DCNoBrowserData.js';
import DCNoCampaignIcon from '../DCNoCampaign.js';
import DCNoContactIcon from '../DCNoContact.js';
import DCNoConversationIcon from '../DCNoConversation.js';
import DCNoDataIcon from '../DCNoData.js';
import DCNoDataCommonIcon from '../DCNoDataCommon.js';
import DCNoDataMachineIcon from '../DCNoDataMachine.js';
import DCNoDragIcon from '../DCNoDrag.js';
import DCNoFileIcon from '../DCNoFile.js';
import DCNoFormIcon from '../DCNoForm.js';
import DCNoGeolocationIcon from '../DCNoGeolocation.js';
import DCNoGroupIcon from '../DCNoGroup.js';
import DCNoLeadIcon from '../DCNoLead.js';
import DCNoNoteIcon from '../DCNoNote.js';
import DCNoNotificationIcon from '../DCNoNotification.js';
import DCNoOSIcon from '../DCNoOS.js';
import DCNoResultFoundIcon from '../DCNoResultFound.js';
import DCNoteIcon from '../DCNote.js';
import DCNotebookIcon from '../DCNotebook.js';
import DCNoTemplateIcon from '../DCNoTemplate.js';
import DCNoTemplateYetIcon from '../DCNoTemplateYet.js';
import DCNotesIcon from '../DCNotes.js';
import DCNotificationIcon from '../DCNotification.js';
import DCNotification1Icon from '../DCNotification1.js';
import DCNotification2Icon from '../DCNotification2.js';
import DCNotificationBellIcon from '../DCNotificationBell.js';
import DCNotificationBell1Icon from '../DCNotificationBell1.js';
import DCNotificationBell2Icon from '../DCNotificationBell2.js';
import DCNotificationBell3Icon from '../DCNotificationBell3.js';
import DCNoUserIcon from '../DCNoUser.js';
import DCNoUserFoundIcon from '../DCNoUserFound.js';
import DCNumberIcon from '../DCNumber.js';
import DCOpenInboxIcon from '../DCOpenInbox.js';
import DCOrderConfirmationIcon from '../DCOrderConfirmation.js';
import DCOutlineCallIcon from '../DCOutlineCall.js';
import DCOutlineGearIcon from '../DCOutlineGear.js';
import DCOutlineStartUpIcon from '../DCOutlineStartUp.js';
import DCOutreachIcon from '../DCOutreach.js';
import DCOutreachMenuIcon from '../DCOutreachMenu.js';
import DCOverviewIcon from '../DCOverview.js';
import DCOverviewChartIcon from '../DCOverviewChart.js';
import DCOverviewMenuIcon from '../DCOverviewMenu.js';
import DCOverviewReportIcon from '../DCOverviewReport.js';
import DCOwnerIcon from '../DCOwner.js';
import DCParagraphIcon from '../DCParagraph.js';
import DCPauseIcon from '../DCPause.js';
import DCPayIcon from '../DCPay.js';
import DCPayMoneyIcon from '../DCPayMoney.js';
import DCPDFIcon from '../DCPDF.js';
import DCPdf1Icon from '../DCPdf1.js';
import DCPdf2Icon from '../DCPdf2.js';
import DCPDFDownloadIcon from '../DCPDFDownload.js';
import DCPDFFileIcon from '../DCPDFFile.js';
import DCPencilIcon from '../DCPencil.js';
import DCPhoneIcon from '../DCPhone.js';
import DCPhone1Icon from '../DCPhone1.js';
import DCPhoneCallIcon from '../DCPhoneCall.js';
import DCPhoneFillIcon from '../DCPhoneFill.js';
import DCPhoneMessageIcon from '../DCPhoneMessage.js';
import DCPhoneNumberIcon from '../DCPhoneNumber.js';
import DCPhoneSitesIcon from '../DCPhoneSites.js';
import DCPinIcon from '../DCPin.js';
import DCPinterestIcon from '../DCPinterest.js';
import DCPipeDriveIcon from '../DCPipeDrive.js';
import DCPlaceholderCardIcon from '../DCPlaceholderCard.js';
import DCPlansIcon from '../DCPlans.js';
import DCPlayIcon from '../DCPlay.js';
import DCPNGFileIcon from '../DCPNGFile.js';
import DCPPTFileIcon from '../DCPPTFile.js';
import DCPPTXFileIcon from '../DCPPTXFile.js';
import DCPrinterIcon from '../DCPrinter.js';
import DCPriorityIcon from '../DCPriority.js';
import DCProductLaterIcon from '../DCProductLater.js';
import DCProductsIcon from '../DCProducts.js';
import DCProjectIcon from '../DCProject.js';
import DCPSDFileIcon from '../DCPSDFile.js';
import DCPurchaseIcon from '../DCPurchase.js';
import DCPurchasedIcon from '../DCPurchased.js';
import DCPurchaseHistoryIcon from '../DCPurchaseHistory.js';
import DCQuestionIcon from '../DCQuestion.js';
import DCQuestionsIcon from '../DCQuestions.js';
import DCQuoteIcon from '../DCQuote.js';
import DCRadioButtonCheckedIcon from '../DCRadioButtonChecked.js';
import DCRadioButtonUncheckedIcon from '../DCRadioButtonUnchecked.js';
import DCRecipientIcon from '../DCRecipient.js';
import DCRedditIcon from '../DCReddit.js';
import DCRedoIcon from '../DCRedo.js';
import DCReminderIcon from '../DCReminder.js';
import DCReminderBlueIcon from '../DCReminderBlue';
import DCRemoveDealTagIcon from '../DCRemoveDealTag.js';
import DCReorderIcon from '../DCReorder.js';
import DCReplyIcon from '../DCReply.js';
import DCReportBuilderIcon from '../DCReportBuilder.js';
import DCResetIcon from '../DCReset.js';
import DCReset2Icon from '../DCReset2.js';
import DCResetPasswordIcon from '../DCResetPassword.js';
import DCResizeIcon from '../DCResize.js';
import DCRestartIcon from '../DCRestart.js';
import DCReverseBarIcon from '../DCReverseBar.js';
import DCRightArrowIcon from '../DCRightArrow.js';
import DCRightArrow1Icon from '../DCRightArrow1.js';
import DCRightArrow2Icon from '../DCRightArrow2.js';
import DCRoundRobinLeadsIcon from '../DCRoundRobinLeads.js';
import DCRssFeedIcon from '../DCRssFeed.js';
import DCSalesIcon from '../DCSales.js';
import DCSalesforceIcon from '../DCSalesforce.js';
import DCSalesPipelinesIcon from '../DCSalesPipelines.js';
import DCSaveIcon from '../DCSave.js';
import DCScheduleIcon from '../DCSchedule.js';
import DCSchedule1Icon from '../DCSchedule1.js';
import DCHelpCenterIcon from '../DCHelpCenter.js';
import DCFacebookGroupIcon from '../DCFacebookGroup.js';
import DCReportBugIcon from '../DCReportBug.js';
import DCWhatsNewIcon from '../DCWhatsNew.js';
import DCNewFeatureIcon from '../DCNewFeature.js';
import DCLiveChat1Icon from '../DCLiveChat1.js';
import DCPhoneSupportIcon from '../DCPhoneSupport.js';
import DCSchedullingIcon from '../DCSchedulling.js';
import DCScoresIcon from '../DCScores.js';
import DCSearchIcon from '../DCSearch.js';
import DCSearch1Icon from '../DCSearch1.js';
import DCSearchesPerDayIcon from '../DCSearchesPerDay.js';
import DCSearchReportIcon from '../DCSearchReport.js';
import DCSelectProductIcon from '../DCSelectProduct.js';
import DCSendEmailIcon from '../DCSendEmail.js';
import DCSeoIcon from '../DCSeo.js';
import DCSEOSearchIcon from '../DCSEOSearch.js';
import DCSettingMenuIcon from '../DCSettingMenu.js';
import DCSettingsIcon from '../DCSettings.js';
import DCSettings1Icon from '../DCSettings1.js';
import DCSettings2Icon from '../DCSettings2.js';
import DCSignIcon from '../DCSign.js';
import DCSignatureIcon from '../DCSignature.js';
import DCSignature1Icon from '../DCSignature1.js';
import DCSignOrderIcon from '../DCSignOrder.js';
import DCSignPayIcon from '../DCSignPay.js';
import DCSingleChoiceIcon from '../DCSingleChoice.js';
import DCSingleOptionIcon from '../DCSingleOption.js';
import DCSMSIcon from '../DCSMS.js';
import DCSMS1Icon from '../DCSMS1.js';
import DCSms2Icon from '../DCSms2.js';
import DCSmsCreditsIcon from '../DCSmsCredits.js';
import DCSnapchatIcon from '../DCSnapchat.js';
import DCSnoozeIcon from '../DCSnooze.js';
import DCSocialMediaIcon from '../DCSocialMedia.js';
import DCSocialMedia1Icon from '../DCSocialMedia1.js';
import DCSocialMediaPageIcon from '../DCSocialMediaPage.js';
import DCSocialShareIcon from '../DCSocialShare.js';
import DCSoundMessageIcon from '../DCSoundMessage.js';
import DCSourceReviewIcon from '../DCSourceReview.js';
import DCSpeedMeterIcon from '../DCSpeedMeter.js';
import DCSqHalfCheckIcon from '../DCSqHalfCheck.js';
import DCSquareStarIcon from '../DCSquareStar.js';
import DCSSLIcon from '../DCSSL.js';
import DCStarIcon from '../DCStar.js';
import DCStarFillIcon from '../DCStarFill.js';
import DCStartUpIcon from '../DCStartUp.js';
import DCStatusIcon from '../DCStatus.js';
import DCStepIcon from '../DCStep.js';
import DCStopIcon from '../DCStop.js';
import DCStoreIcon from '../DCStore.js';
import DCStoreFillIcon from '../DCStoreFill.js';
import DCStorefrontIcon from '../DCStorefront.js';
import DCStoreMenuIcon from '../DCStoreMenu.js';
import DCSubmissionIcon from '../DCSubmission.js';
import DCSubmission1Icon from '../DCSubmission1.js';
import DCSuitCaseIcon from '../DCSuitCase.js';
import DCSupportIcon from '../DCSupport.js';
import DCSVGFileIcon from '../DCSVGFile.js';
import DCSwitchIcon from '../DCSwitch.js';
import DCSwitchOffIcon from '../DCSwitchOff.js';
import DCSwitchOnIcon from '../DCSwitchOn.js';
import DCTabletIcon from '../DCTablet.js';
import DCTagIcon from '../DCTag.js';
import DCTag1Icon from '../DCTag1.js';
import DCTag2Icon from '../DCTag2.js';
import DCTag3Icon from '../DCTag3.js';
import DCTagsIcon from '../DCTags.js';
import DCThunderboltIcon from '../DCThunderbolt.js';
import DCTaskListIcon from '../DCTaskList.js';
import DCTeamMemberSeatsIcon from '../DCTeamMemberSeats.js';
import DCTemplateIcon from '../DCTemplate.js';
import DCTemplateLibraryIcon from '../DCTemplateLibrary.js';
import DCTemplatesIcon from '../DCTemplates.js';
import DCTemplates1Icon from '../DCTemplates1.js';
import DCTermsIcon from '../DCTerms.js';
import DCTextareaIcon from '../DCTextarea.js';
import DCTextFieldIcon from '../DCTextField.js';
import DCTextFolderIcon from '../DCTextFolder.js';
import DCThankYouIcon from '../DCThankYou.js';
import DCTicketsIcon from '../DCTickets.js';
import DCTIFFFileIcon from '../DCTIFFFile.js';
import DCTimeNotificationIcon from '../DCTimeNotification.js';
import DCTotalLeadIcon from '../DCTotalLead.js';
import DCTotalLeadsIcon from '../DCTotalLeads.js';
import DCTrackedSeoKeywordsIcon from '../DCTrackedSeoKeywords.js';
import DCTrashIcon from '../DCTrash.js';
import DCTrash1Icon from '../DCTrash1.js';
import DCTripadvisorIcon from '../DCTripadvisor.js';
import DCTwitterIcon from '../DCTwitter.js';
import DCTwoStarIcon from '../DCTwoStar.js';
import DCTXTFileIcon from '../DCTXTFile.js';
import DCUnbounceIcon from '../DCUnbounce.js';
import DCUndoIcon from '../DCUndo.js';
import DCUnionpayCardIcon from '../DCUnionpayCard.js';
import DCUpArrowIcon from '../DCUpArrow.js';
import DCUpDownArrowIcon from '../DCUpDownArrow.js';
import DCUploadCSVIcon from '../DCUploadCSV.js';
import DCUploadFileIcon from '../DCUploadFile.js';
import DCUsageIcon from '../DCUsage.js';
import DCUserIcon from '../DCUser.js';
import DCUser1Icon from '../DCUser1.js';
import DCUser2Icon from '../DCUser2.js';
import DCUserBookIcon from '../DCUserBook.js';
import DCUserCommentIcon from '../DCUserComment.js';
import DCUserCredentialIcon from '../DCUserCredential.js';
import DCUserFormIcon from '../DCUserForm.js';
import DCUserInfoIcon from '../DCUserInfo.js';
import DCUserInfo1Icon from '../DCUserInfo1.js';
import DCUserOutlineIcon from '../DCUserOutline.js';
import DCUserPlaceholderIcon from '../DCUserPlaceholder.js';
import DCUserPlusIcon from '../DCUserPlus.js';
import DCUserPortfolioIcon from '../DCUserPortfolio.js';
import DCUserReviewIcon from '../DCUserReview.js';
import DCUserReview1Icon from '../DCUserReview1.js';
import DCUserReview2Icon from '../DCUserReview2.js';
import DCUserReview3Icon from '../DCUserReview3.js';
import DCUsersIcon from '../DCUsers.js';
import DCUsers2Icon from '../DCUsers2.js';
import DCUsersFillIcon from '../DCUsersFill.js';
import DCUserStarOutlineIcon from '../DCUserStarOutline.js';
import DCVertical3DotsIcon from '../DCVertical3Dots.js';
import DCVertical8DotsIcon from '../DCVertical8Dots.js';
import DCVideoCallIcon from '../DCVideoCall.js';
import DCVideoCall1Icon from '../DCVideoCall1.js';
import DCVimeoIcon from '../DCVimeo.js';
import DCVisaCardIcon from '../DCVisaCard.js';
import DCVisibilityEyeIcon from '../DCVisibilityEye.js';
import DCVoiceIcon from '../DCVoice.js';
import DCVoiceDropIcon from '../DCVoiceDrop.js';
import DCVoiceMessageIcon from '../DCVoiceMessage.js';
import DCWarmIcon from '../DCWarm.js';
import DCWarningIcon from '../DCWarning.js';
import DCFilledWatchIcon from '../DCFilledWatch.js';
import DCWatchIcon from '../DCWatch.js';
import DCWatch1Icon from '../DCWatch1.js';
import DCWatch2Icon from '../DCWatch2.js';
import DCWatch3Icon from '../DCWatch3.js';
import DCWebAnalyticsIcon from '../DCWebAnalytics.js';
import DCWebDevicesIcon from '../DCWebDevices.js';
import DCWebGlobeIcon from '../DCWebGlobe.js';
import DCWebmFileIcon from '../DCWebmFile.js';
import DCWebSectionIcon from '../DCWebSection.js';
import DCWebsiteIcon from '../DCWebsite.js';
import DCWebsiteAnalyticsIcon from '../DCWebsiteAnalytics.js';
import DCWebViewIcon from '../DCWebView.js';
import DCWWWIcon from '../DCWWW.js';
import DCXLSFileIcon from '../DCXLSFile.js';
import DCXLSXFileIcon from '../DCXLSXFile.js';
import DCYahooIcon from '../DCYahoo.js';
import DCYelpIcon from '../DCYelp.js';
import DCYesNoIcon from '../DCYesNo.js';
import DCYoutubeIcon from '../DCYoutube.js';
import DCZohoIcon from '../DCZoho.js';
import DCMicFilledIcon from '../DCMicFilled.js';
import DCGenderIcon from '../DCGender.js';
import DCFilledGenderIcon from '../DCFilledGender.js';
import DCSendIcon from '../DCSend.js';
import DCCheckedBoxFilledIcon from '../DCCheckedBoxFilled.js';
import DCSchedule2Icon from '../DCSchedule2.js';
import DCExternalIcon from '../DCExternalIcon.js';

const icons = [
    { component: DCCheckedBoxFilledIcon, name: 'DCCheckedBoxFilledIcon' },
    { component: DCExternalIcon, name: 'DCExternalIcon' },
    { component: DC3DAddIcon, name: 'DC3DAddIcon' },
    { component: DCSchedule2Icon, name: 'DCSchedule2Icon' },
    { component: DC4BoxesIcon, name: 'DC4BoxesIcon' },
    { component: DCAccountBillingIcon, name: 'DCAccountBillingIcon' },
    { component: DCSendIcon, name: 'DCSend' },
    { component: DCActivateSiteIcon, name: 'DCActivateSiteIcon' },
    { component: DCActiveCampaignIcon, name: 'DCActiveCampaignIcon' },
    { component: DCAddCircleIcon, name: 'DCAddCircleIcon' },
    { component: DCAddColumnIcon, name: 'DCAddColumnIcon' },
    { component: DCAddDealTagIcon, name: 'DCAddDealTagIcon' },
    { component: DCAddFollowerIcon, name: 'DCAddFollowerIcon' },
    { component: DCAddIconIcon, name: 'DCAddIconIcon' },
    { component: DCAddNewStageIcon, name: 'DCAddNewStageIcon' },
    { component: DCAddToMyLeadsIcon, name: 'DCAddToMyLeadsIcon' },
    { component: DCAddUserOutlineIcon, name: 'DCAddUserOutlineIcon' },
    { component: DCAdGroupsIcon, name: 'DCAdGroupsIcon' },
    { component: DCAdsIcon, name: 'DCAdsIcon' },
    { component: DCAdvancedSearchIcon, name: 'DCAdvancedSearchIcon' },
    { component: DCAffiliateIcon, name: 'DCAffiliateIcon' },
    { component: DCAffiliateCenterIcon, name: 'DCAffiliateCenterIcon' },
    { component: DCAgencyIcon, name: 'DCAgencyIcon' },
    { component: DCAgencyWebsiteMenuIcon, name: 'DCAgencyWebsiteMenuIcon' },
    { component: DCAlarmIcon, name: 'DCAlarmIcon' },
    { component: DCAllCallIcon, name: 'DCAllCallIcon' },
    { component: DCAmexCardIcon, name: 'DCAmexCardIcon' },
    { component: DCAnalyticsIcon, name: 'DCAnalyticsIcon' },
    { component: DCAppMarketplaceIcon, name: 'DCAppMarketplaceIcon' },
    { component: DCAppStoreIcon, name: 'DCAppStoreIcon' },
    { component: DCAppTypeIcon, name: 'DCAppTypeIcon' },
    { component: DCArrowBackIcon, name: 'DCArrowBackIcon' },
    { component: DCArrowDownIcon, name: 'DCArrowDownIcon' },
    { component: DCArrowNextIcon, name: 'DCArrowNextIcon' },
    { component: DCArrowUpIcon, name: 'DCArrowUpIcon' },
    { component: DCAttachmentIcon, name: 'DCAttachmentIcon' },
    { component: DCAttachment1Icon, name: 'DCAttachment1Icon' },
    { component: DCAtTheRateIcon, name: 'DCAtTheRateIcon' },
    { component: DCAutoCreateContactsIcon, name: 'DCAutoCreateContactsIcon' },
    { component: DCAutoCreateDealsIcon, name: 'DCAutoCreateDealsIcon' },
    { component: DCAutomationIcon, name: 'DCAutomationIcon' },
    { component: DCAutomation1Icon, name: 'DCAutomation1Icon' },
    { component: DCAutomationMenuIcon, name: 'DCAutomationMenuIcon' },
    { component: DCAutomationsLogIcon, name: 'DCAutomationsLogIcon' },
    { component: DCAverageIcon, name: 'DCAverageIcon' },
    { component: DCAwardTagIcon, name: 'DCAwardTagIcon' },
    { component: DCBarChartIcon, name: 'DCBarChartIcon' },
    { component: DCBarChart1Icon, name: 'DCBarChart1Icon' },
    { component: DCBarChart2Icon, name: 'DCBarChart2Icon' },
    { component: DCBarsIcon, name: 'DCBarsIcon' },
    { component: DCBatteryIcon, name: 'DCBatteryIcon' },
    { component: DCBellIcon, name: 'DCBellIcon' },
    { component: DCBillingIcon, name: 'DCBillingIcon' },
    { component: DCBingIcon, name: 'DCBingIcon' },
    { component: DCBing1Icon, name: 'DCBing1Icon' },
    { component: DCBlogPostIcon, name: 'DCBlogPostIcon' },
    { component: DCBlueStarIcon, name: 'DCBlueStarIcon' },
    { component: DCBookmarkIcon, name: 'DCBookmarkIcon' },
    { component: DCBookSearchIcon, name: 'DCBookSearchIcon' },
    { component: DCBoxChangeIcon, name: 'DCBoxChangeIcon' },
    { component: DCBoxCheckIcon, name: 'DCBoxCheckIcon' },
    { component: DCBoxControlIcon, name: 'DCBoxControlIcon' },
    { component: DCBoxNumbersIcon, name: 'DCBoxNumbersIcon' },
    { component: DCBoxViewTypeIcon, name: 'DCBoxViewTypeIcon' },
    { component: DCBrandingIcon, name: 'DCBrandingIcon' },
    { component: DCBrowserNotificationIcon, name: 'DCBrowserNotificationIcon' },
    { component: DCBuildingIcon, name: 'DCBuildingIcon' },
    { component: DCBuilding1Icon, name: 'DCBuilding1Icon' },
    { component: DCBuildingsOutlineIcon, name: 'DCBuildingsOutlineIcon' },
    { component: DCBuildInstaReport1Icon, name: 'DCBuildInstaReport1Icon' },
    { component: DCBuildInstaReport2Icon, name: 'DCBuildInstaReport2Icon' },
    { component: DCBuiltCrainIcon, name: 'DCBuiltCrainIcon' },
    { component: DCBusinessProfileIcon, name: 'DCBusinessProfileIcon' },
    { component: DCCalendarIcon, name: 'DCCalendarIcon' },
    { component: DCCalendar1Icon, name: 'DCCalendar1Icon' },
    { component: DCCalendarDateIcon, name: 'DCCalendarDateIcon' },
    { component: DCCallCheckIcon, name: 'DCCallCheckIcon' },
    { component: DCCallCloseIcon, name: 'DCCallCloseIcon' },
    { component: DCCallRailIcon, name: 'DCCallRailIcon' },
    { component: DCCallTrackingIcon, name: 'DCCallTrackingIcon' },
    { component: DCCameraIcon, name: 'DCCameraIcon' },
    { component: DCCampaignsIcon, name: 'DCCampaignsIcon' },
    { component: DCCampaigns1Icon, name: 'DCCampaigns1Icon' },
    { component: DCCapterraIcon, name: 'DCCapterraIcon' },
    { component: DCCardBgIcon, name: 'DCCardBgIcon' },
    { component: DCCardFormIcon, name: 'DCCardFormIcon' },
    { component: DCCardForm1Icon, name: 'DCCardForm1Icon' },
    { component: DCCardPayIcon, name: 'DCCardPayIcon' },
    { component: DCChatIcon, name: 'DCChatIcon' },
    { component: DCChat1Icon, name: 'DCChat1Icon' },
    { component: DCChat1FilledIcon, name: 'DCChat1FilledIcon' },
    { component: DCChatReviewIcon, name: 'DCChatReviewIcon' },
    { component: DCChatStarIcon, name: 'DCChatStarIcon' },
    { component: DCCheckIcon, name: 'DCCheckIcon' },
    { component: DCCheckCircleIcon, name: 'DCCheckCircleIcon' },
    { component: DCCheckedBoxIcon, name: 'DCCheckedBoxIcon' },
    { component: DCCheckListIcon, name: 'DCCheckListIcon' },
    { component: DCCheckoutIcon, name: 'DCCheckoutIcon' },
    { component: DCCheckout1Icon, name: 'DCCheckout1Icon' },
    { component: DCCheckSuitIcon, name: 'DCCheckSuitIcon' },
    { component: DCCheckSuitCloseIcon, name: 'DCCheckSuitCloseIcon' },
    { component: DCClassicFormIcon, name: 'DCClassicFormIcon' },
    { component: DCClickFunnelsIcon, name: 'DCClickFunnelsIcon' },
    { component: DCCoffeeCupIcon, name: 'DCCoffeeCupIcon' },
    { component: DCColdIcon, name: 'DCColdIcon' },
    { component: DCCompaniesIcon, name: 'DCCompaniesIcon' },
    { component: DCCompareIcon, name: 'DCCompareIcon' },
    { component: DCConnectionsIcon, name: 'DCConnectionsIcon' },
    { component: DCConstantContactIcon, name: 'DCConstantContactIcon' },
    { component: DCContactInfoIcon, name: 'DCContactInfoIcon' },
    { component: DCContactListIcon, name: 'DCContactListIcon' },
    { component: DCContactMenuIcon, name: 'DCContactMenuIcon' },
    { component: DCContactRecordsIcon, name: 'DCContactRecordsIcon' },
    { component: DCContactsIcon, name: 'DCContactsIcon' },
    { component: DCContentIcon, name: 'DCContentIcon' },
    { component: DCControlIcon, name: 'DCControlIcon' },
    { component: DCConversationMenuIcon, name: 'DCConversationMenuIcon' },
    { component: DCConversationsIcon, name: 'DCConversationsIcon' },
    { component: DCConversations1Icon, name: 'DCConversations1Icon' },
    { component: DCCopyIcon, name: 'DCCopyIcon' },
    { component: DCCopy1Icon, name: 'DCCopy1Icon' },
    { component: DCCopyLinkIcon, name: 'DCCopyLinkIcon' },
    { component: DCCredentialsIcon, name: 'DCCredentialsIcon' },
    { component: DCCreditIcon, name: 'DCCreditIcon' },
    { component: DCCrossCloseIcon, name: 'DCCrossCloseIcon' },
    { component: DCCSVFileIcon, name: 'DCCSVFileIcon' },
    { component: DCCupIcon, name: 'DCCupIcon' },
    { component: DCCursorIcon, name: 'DCCursorIcon' },
    { component: DCCustomDomainIcon, name: 'DCCustomDomainIcon' },
    { component: DCCustomEmailIcon, name: 'DCCustomEmailIcon' },
    { component: DCCustomFiltersIcon, name: 'DCCustomFiltersIcon' },
    { component: DCCustomPropertiesIcon, name: 'DCCustomPropertiesIcon' },
    { component: DCDashboardIcon, name: 'DCDashboardIcon' },
    { component: DCDashboardMenuIcon, name: 'DCDashboardMenuIcon' },
    { component: DCDataTransferIcon, name: 'DCDataTransferIcon' },
    { component: DCDateCalIcon, name: 'DCDateCalIcon' },
    { component: DCDateCheckIcon, name: 'DCDateCheckIcon' },
    { component: DCDateTimeIcon, name: 'DCDateTimeIcon' },
    { component: DCDealIcon, name: 'DCDealIcon' },
    { component: DCDealAutomationsIcon, name: 'DCDealAutomationsIcon' },
    { component: DCDealCaseIcon, name: 'DCDealCaseIcon' },
    { component: DCDealCRMIcon, name: 'DCDealCRMIcon' },
    { component: DCDealMenuIcon, name: 'DCDealMenuIcon' },
    { component: DCDealsIcon, name: 'DCDealsIcon' },
    { component: DCDealsMenuIcon, name: 'DCDealsMenuIcon' },
    { component: DCDesktopIcon, name: 'DCDesktopIcon' },
    { component: DCDesktopEyeIcon, name: 'DCDesktopEyeIcon' },
    { component: DCDetailsCardIcon, name: 'DCDetailsCardIcon' },
    { component: DCDetailsCard1Icon, name: 'DCDetailsCard1Icon' },
    { component: DCDiamondIcon, name: 'DCDiamondIcon' },
    { component: DCDinersClubCardIcon, name: 'DCDinersClubCardIcon' },
    { component: DCDirectionsIcon, name: 'DCDirectionsIcon' },
    { component: DCDiscoverCardIcon, name: 'DCDiscoverCardIcon' },
    { component: DCDisputeUsersIcon, name: 'DCDisputeUsersIcon' },
    { component: DCDocIcon, name: 'DCDocIcon' },
    { component: DCDoc1Icon, name: 'DCDoc1Icon' },
    { component: DCDocFileIcon, name: 'DCDocFileIcon' },
    { component: DCDocTextIcon, name: 'DCDocTextIcon' },
    { component: DCDocXFileIcon, name: 'DCDocXFileIcon' },
    { component: DCDollarIcon, name: 'DCDollarIcon' },
    { component: DCDollarSignIcon, name: 'DCDollarSignIcon' },
    { component: DCDownArrowIcon, name: 'DCDownArrowIcon' },
    { component: DCDownloadIcon, name: 'DCDownloadIcon' },
    { component: DCDownload1Icon, name: 'DCDownload1Icon' },
    { component: DCDropdownIcon, name: 'DCDropdownIcon' },
    { component: DCDuplicateIcon, name: 'DCDuplicateIcon' },
    { component: DCDuplicate2Icon, name: 'DCDuplicate2Icon' },
    { component: DCEcommerceTemplatesIcon, name: 'DCEcommerceTemplatesIcon' },
    { component: DCEditIcon, name: 'DCEditIcon' },
    { component: DCEditFormIcon, name: 'DCEditFormIcon' },
    { component: DCEdocsIcon, name: 'DCEdocsIcon' },
    { component: DCeDocsMenuIcon, name: 'DCeDocsMenuIcon' },
    { component: DCEducationIcon, name: 'DCEducationIcon' },
    { component: DCEducationCapIcon, name: 'DCEducationCapIcon' },
    { component: DCEmailCreditsIcon, name: 'DCEmailCreditsIcon' },
    { component: DCEmailMicIcon, name: 'DCEmailMicIcon' },
    { component: DCEmailOpenIcon, name: 'DCEmailOpenIcon' },
    { component: DCEmailOpen1Icon, name: 'DCEmailOpen1Icon' },
    { component: DCEmailOpen2Icon, name: 'DCEmailOpen2Icon' },
    { component: DCEmailSmsIcon, name: 'DCEmailSmsIcon' },
    { component: DCEmptyBoxIcon, name: 'DCEmptyBoxIcon' },
    { component: DCEmptyCircleIcon, name: 'DCEmptyCircleIcon' },
    { component: DCEmptySectionsIcon, name: 'DCEmptySectionsIcon' },
    { component: DCEnvelopeIcon, name: 'DCEnvelopeIcon' },
    { component: DCEnvelope1Icon, name: 'DCEnvelope1Icon' },
    { component: DCEnvelopeOpenIcon, name: 'DCEnvelopeOpenIcon' },
    { component: DCErrorOutlineIcon, name: 'DCErrorOutlineIcon' },
    { component: DCExclamationIcon, name: 'DCExclamationIcon' },
    { component: DCExportIcon, name: 'DCExportIcon' },
    { component: DCExternalWindowIcon, name: 'DCExternalWindowIcon' },
    { component: DCEyeIcon, name: 'DCEyeIcon' },
    { component: DCFacebookIcon, name: 'DCFacebookIcon' },
    { component: DCFacebook1Icon, name: 'DCFacebook1Icon' },
    { component: DCFacebook2Icon, name: 'DCFacebook2Icon' },
    { component: DCFacebook3Icon, name: 'DCFacebook3Icon' },
    { component: DCFacebookAdIcon, name: 'DCFacebookAdIcon' },
    { component: DCFacebookAd1Icon, name: 'DCFacebookAd1Icon' },
    { component: DCFacebookAdsIcon, name: 'DCFacebookAdsIcon' },
    { component: DCFacebookMessengerIcon, name: 'DCFacebookMessengerIcon' },
    { component: DCFileIcon, name: 'DCFileIcon' },
    { component: DCFileDownloadIcon, name: 'DCFileDownloadIcon' },
    { component: DCFileHeaderIcon, name: 'DCFileHeaderIcon' },
    { component: DCFileLinkIcon, name: 'DCFileLinkIcon' },
    { component: DCFilesIcon, name: 'DCFilesIcon' },
    { component: DCFileStorageIcon, name: 'DCFileStorageIcon' },
    { component: DCFileUploadIcon, name: 'DCFileUploadIcon' },
    { component: DCFileUpload1Icon, name: 'DCFileUpload1Icon' },
    { component: DCFillChatIcon, name: 'DCFillChatIcon' },
    { component: DCFillCheckIcon, name: 'DCFillCheckIcon' },
    { component: DCFillCloseIcon, name: 'DCFillCloseIcon' },
    { component: DCCloseIcon, name: 'DCCloseIcon' },
    { component: DCFillDownArrayIcon, name: 'DCFillDownArrayIcon' },
    { component: DCFillEnvelopIcon, name: 'DCFillEnvelopIcon' },
    { component: DCFillEnvelopeIcon, name: 'DCFillEnvelopeIcon' },
    { component: DCFilleTransferIcon, name: 'DCFilleTransferIcon' },
    { component: DCFillEyeIcon, name: 'DCFillEyeIcon' },
    { component: DCFillGearIcon, name: 'DCFillGearIcon' },
    { component: DCFillImageIcon, name: 'DCFillImageIcon' },
    { component: DCFillInstagramIcon, name: 'DCFillInstagramIcon' },
    { component: DCFillLinkedInIcon, name: 'DCFillLinkedInIcon' },
    { component: DCFillLockIcon, name: 'DCFillLockIcon' },
    { component: DCFillMessageIcon, name: 'DCFillMessageIcon' },
    { component: DCFillPencilIcon, name: 'DCFillPencilIcon' },
    { component: DCFillPhoneIcon, name: 'DCFillPhoneIcon' },
    { component: DCFillQuestionIcon, name: 'DCFillQuestionIcon' },
    { component: DCFillSMSIcon, name: 'DCFillSMSIcon' },
    { component: DCFillTwitterIcon, name: 'DCFillTwitterIcon' },
    { component: DCFillUpArrayIcon, name: 'DCFillUpArrayIcon' },
    { component: DCFillWebIcon, name: 'DCFillWebIcon' },
    { component: DCFillYoutubeIcon, name: 'DCFillYoutubeIcon' },
    { component: DCFilterIcon, name: 'DCFilterIcon' },
    { component: DCFilter1Icon, name: 'DCFilter1Icon' },
    { component: DCClipboardIcon, name: 'DCClipboardIcon' },
    { component: DCAnniversaryIcon, name: 'DCAnniversary' },
    { component: DCFirstCallIcon, name: 'DCFirstCallIcon' },
    { component: DCFixingToolIcon, name: 'DCFixingToolIcon' },
    { component: DCFlagIcon, name: 'DCFlagIcon' },
    { component: DCFlyArrowIcon, name: 'DCFlyArrowIcon' },
    { component: DCFlyArrow1Icon, name: 'DCFlyArrow1Icon' },
    { component: DCFormIcon, name: 'DCFormIcon' },
    { component: DCFormattingIcon, name: 'DCFormattingIcon' },
    { component: DCFormPendingIcon, name: 'DCFormPendingIcon' },
    { component: DCFormsIcon, name: 'DCFormsIcon' },
    { component: DCForms1Icon, name: 'DCForms1Icon' },
    { component: DCFormSubmissionIcon, name: 'DCFormSubmissionIcon' },
    { component: DCForwardIcon, name: 'DCForwardIcon' },
    { component: DCFourBoxIcon, name: 'DCFourBoxIcon' },
    { component: DCFoursquareIcon, name: 'DCFoursquareIcon' },
    { component: DCFreshnessIcon, name: 'DCFreshnessIcon' },
    { component: DCFunnelIcon, name: 'DCFunnelIcon' },
    { component: DCG2Icon, name: 'DCG2Icon' },
    { component: DCGearToolIcon, name: 'DCGearToolIcon' },
    { component: DCGeoLocationIcon, name: 'DCGeoLocationIcon' },
    { component: DCGeoLocation1Icon, name: 'DCGeoLocation1Icon' },
    { component: DCGeoLocation2Icon, name: 'DCGeoLocation2Icon' },
    { component: DCGeoLocation3Icon, name: 'DCGeoLocation3Icon' },
    { component: DCGeoLocationsIcon, name: 'DCGeoLocationsIcon' },
    { component: DCGifIcon, name: 'DCGifIcon' },
    { component: DCGIFFileIcon, name: 'DCGIFFileIcon' },
    { component: DCGlobeIcon, name: 'DCGlobeIcon' },
    { component: DCGoalIcon, name: 'DCGoalIcon' },
    { component: DCGoogleIcon, name: 'DCGoogleIcon' },
    { component: DCGoogleAdIcon, name: 'DCGoogleAdIcon' },
    { component: DCGoogleAd1Icon, name: 'DCGoogleAd1Icon' },
    { component: DCGoogleAdsIcon, name: 'DCGoogleAdsIcon' },
    { component: DCGoogleAnalyticsIcon, name: 'DCGoogleAnalyticsIcon' },
    { component: DCGrayActivityIcon, name: 'DCGrayActivityIcon' },
    { component: DCGrayAnalyticsIcon, name: 'DCGrayAnalyticsIcon' },
    { component: DCGrayAttachmentIcon, name: 'DCGrayAttachmentIcon' },
    { component: DCGrayAutomatedIcon, name: 'DCGrayAutomatedIcon' },
    { component: DCGrayBusinessIcon, name: 'DCGrayBusinessIcon' },
    { component: DCGrayCommissionsIcon, name: 'DCGrayCommissionsIcon' },
    { component: DCGrayConversationsIcon, name: 'DCGrayConversationsIcon' },
    { component: DCGrayDealsIcon, name: 'DCGrayDealsIcon' },
    { component: DCGrayEmailTemplateIcon, name: 'DCGrayEmailTemplateIcon' },
    { component: DCGrayFileIcon, name: 'DCGrayFileIcon' },
    { component: DCGrayFollowerIcon, name: 'DCGrayFollowerIcon' },
    { component: DCGrayFormIcon, name: 'DCGrayFormIcon' },
    { component: DCGrayGraphIcon, name: 'DCGrayGraphIcon' },
    { component: DCGrayInstaReportIcon, name: 'DCGrayInstaReportIcon' },
    { component: DCGrayInstaSiteIcon, name: 'DCGrayInstaSiteIcon' },
    { component: DCGrayLeaderBoardIcon, name: 'DCGrayLeaderBoardIcon' },
    { component: DCGrayNoFoundIcon, name: 'DCGrayNoFoundIcon' },
    { component: DCGrayNotebookIcon, name: 'DCGrayNotebookIcon' },
    { component: DCGrayNotesIcon, name: 'DCGrayNotesIcon' },
    { component: DCGrayNotificationIcon, name: 'DCGrayNotificationIcon' },
    { component: DCGrayPeoplesIcon, name: 'DCGrayPeoplesIcon' },
    { component: DCGrayProjectIcon, name: 'DCGrayProjectIcon' },
    { component: DCGrayPurchaseHistoryIcon, name: 'DCGrayPurchaseHistoryIcon' },
    { component: DCGrayReminderIcon, name: 'DCGrayReminderIcon' },
    { component: DCGraySEOIcon, name: 'DCGraySEOIcon' },
    { component: DCGraySPFormIcon, name: 'DCGraySPFormIcon' },
    { component: DCGrayStageIcon, name: 'DCGrayStageIcon' },
    { component: DCGraySubAccountIcon, name: 'DCGraySubAccountIcon' },
    { component: DCGraySubscriptionIcon, name: 'DCGraySubscriptionIcon' },
    { component: DCGraySuccessfulIcon, name: 'DCGraySuccessfulIcon' },
    { component: DCGrayUserIcon, name: 'DCGrayUserIcon' },
    { component: DCGrayVideoIcon, name: 'DCGrayVideoIcon' },
    { component: DCGroupFormIcon, name: 'DCGroupFormIcon' },
    { component: DCGroupForm1Icon, name: 'DCGroupForm1Icon' },
    { component: DCGrowChartIcon, name: 'DCGrowChartIcon' },
    { component: DCGrowChart1Icon, name: 'DCGrowChart1Icon' },
    { component: DCHalfCheckIcon, name: 'DCHalfCheckIcon' },
    { component: DCHalfCircleCloseIcon, name: 'DCHalfCircleCloseIcon' },
    { component: DCHalfCircleClose1Icon, name: 'DCHalfCircleClose1Icon' },
    { component: DCHandShakeFilledIcon, name: 'DCHandShakeFilledIcon' },
    { component: DCHandShakeIcon, name: 'DCHandShakeIcon' },
    { component: DCHeartFilledIcon, name: 'DCHeartFillIcon' },
    { component: DCHelpIcon, name: 'DCHelpIcon' },
    { component: DCHelp1Icon, name: 'DCHelp1Icon' },
    { component: DCHelpOutlineIcon, name: 'DCHelpOutlineIcon' },
    { component: DCHiperCardIcon, name: 'DCHiperCardIcon' },
    { component: DCHorizontal3DotsIcon, name: 'DCHorizontal3DotsIcon' },
    { component: DCHorizontal3LineIcon, name: 'DCHorizontal3LineIcon' },
    { component: DCHorizontalLineIcon, name: 'DCHorizontalLineIcon' },
    { component: DCHotIcon, name: 'DCHotIcon' },
    { component: DCHtmlCodeIcon, name: 'DCHtmlCodeIcon' },
    { component: DCHubspotIcon, name: 'DCHubspotIcon' },
    { component: DCIceIcon, name: 'DCIceIcon' },
    { component: DCIDIcon, name: 'DCIDIcon' },
    { component: DCImageIcon, name: 'DCImageIcon' },
    { component: DCImagePlaceholderIcon, name: 'DCImagePlaceholderIcon' },
    { component: DCImportIcon, name: 'DCImportIcon' },
    { component: DCInboundIcon, name: 'DCInboundIcon' },
    { component: DCIndeterminateCheckBoxIcon, name: 'DCIndeterminateCheckBoxIcon' },
    { component: DCIndeterminateCheckBox1Icon, name: 'DCIndeterminateCheckBox1Icon' },
    { component: DCInfoIcon, name: 'DCInfoIcon' },
    { component: DCInfoCircleIcon, name: 'DCInfoCircleIcon' },
    { component: DCInsightsIcon, name: 'DCInsightsIcon' },
    { component: DCInstagramIcon, name: 'DCInstagramIcon' },
    { component: DCInstaPageIcon, name: 'DCInstaPageIcon' },
    { component: DCInstaReportIcon, name: 'DCInstaReportIcon' },
    { component: DCInstareportsIcon, name: 'DCInstareportsIcon' },
    { component: DCInstaReports1Icon, name: 'DCInstaReports1Icon' },
    { component: DCInstaReportsMenuIcon, name: 'DCInstaReportsMenuIcon' },
    { component: DCInstaSiteIcon, name: 'DCInstaSiteIcon' },
    { component: DCInstasitesIcon, name: 'DCInstasitesIcon' },
    { component: DCInstaSites1Icon, name: 'DCInstaSites1Icon' },
    { component: DCInstaSitesMenuIcon, name: 'DCInstaSitesMenuIcon' },
    { component: DCIntegrationIcon, name: 'DCIntegrationIcon' },
    { component: DCIPsIcon, name: 'DCIPsIcon' },
    { component: DCJcbCardIcon, name: 'DCJcbCardIcon' },
    { component: DCJPEGFileIcon, name: 'DCJPEGFileIcon' },
    { component: DCJPGFileIcon, name: 'DCJPGFileIcon' },
    { component: DCKartraIcon, name: 'DCKartraIcon' },
    { component: DCKeepIcon, name: 'DCKeepIcon' },
    { component: DCKeywordsIcon, name: 'DCKeywordsIcon' },
    { component: DCLaptopIcon, name: 'DCLaptopIcon' },
    { component: DCLaptopUserIcon, name: 'DCLaptopUserIcon' },
    { component: DCLayoutIcon, name: 'DCLayoutIcon' },
    { component: DCLeadAutomationsIcon, name: 'DCLeadAutomationsIcon' },
    { component: DCLeadFinderMenuIcon, name: 'DCLeadFinderMenuIcon' },
    { component: DCLeadFlowMenuIcon, name: 'DCLeadFlowMenuIcon' },
    { component: DCLeadsIcon, name: 'DCLeadsIcon' },
    { component: DCLeadTypeIcon, name: 'DCLeadTypeIcon' },
    { component: DCLegendIcon, name: 'DCLegendIcon' },
    { component: DCLikeIcon, name: 'DCLikeIcon' },
    { component: DCLineBreakIcon, name: 'DCLineBreakIcon' },
    { component: DCLineChartIcon, name: 'DCLineChartIcon' },
    { component: DCLinkIcon, name: 'DCLinkIcon' },
    { component: DCLink1Icon, name: 'DCLink1Icon' },
    { component: DCLink2Icon, name: 'DCLink2Icon' },
    { component: DCLinkedinIcon, name: 'DCLinkedinIcon' },
    { component: DCListIcon, name: 'DCListIcon' },
    { component: DCListDocIcon, name: 'DCListDocIcon' },
    { component: DCListingManagerIcon, name: 'DCListingManagerIcon' },
    { component: DCListingsManagerIcon, name: 'DCListingsManagerIcon' },
    { component: DCLiveChatIcon, name: 'DCLiveChatIcon' },
    { component: DCLiveChatFilledIcon, name: 'DCLiveChatFilledIcon' },
    { component: DCLockIcon, name: 'DCLockIcon' },
    { component: DCLogIcon, name: 'DCLogIcon' },
    { component: DCLoginCredentialIcon, name: 'DCLoginCredentialIcon' },
    { component: DCLogoIcon, name: 'DCLogoIcon' },
    { component: DCLongTextIcon, name: 'DCLongTextIcon' },
    { component: DCMailChimpIcon, name: 'DCMailChimpIcon' },
    { component: DCMailResendIcon, name: 'DCMailResendIcon' },
    { component: DCManychatIcon, name: 'DCManychatIcon' },
    { component: DCMapLocationIcon, name: 'DCMapLocationIcon' },
    { component: DCMarketplaceIcon, name: 'DCMarketplaceIcon' },
    { component: DCMasterCardIcon, name: 'DCMasterCardIcon' },
    { component: DCMasterCardPIcon, name: 'DCMasterCardPIcon' },
    { component: DCMenuIconIcon, name: 'DCMenuIconIcon' },
    { component: DCMerchantCenterMenuIcon, name: 'DCMerchantCenterMenuIcon' },
    { component: DCMessageIcon, name: 'DCMessageIcon' },
    { component: DCMinusIcon, name: 'DCMinusIcon' },
    { component: DCMissionIcon, name: 'DCMissionIcon' },
    { component: DCMMSIcon, name: 'DCMMSIcon' },
    { component: DCMobileIcon, name: 'DCMobileIcon' },
    { component: DCMobile1Icon, name: 'DCMobile1Icon' },
    { component: DCMouseClickIcon, name: 'DCMouseClickIcon' },
    { component: DCMoveIcon, name: 'DCMoveIcon' },
    { component: DCMoveStageIcon, name: 'DCMoveStageIcon' },
    { component: DCMultiOptionIcon, name: 'DCMultiOptionIcon' },
    { component: DCMyProjectsMenuIcon, name: 'DCMyProjectsMenuIcon' },
    { component: DCMyWebIcon, name: 'DCMyWebIcon' },
    { component: DCNoPropertyIcon, name: 'DCNoPropertyIcon' },
    { component: DCNoProperty1Icon, name: 'DCNoProperty1Icon' },
    { component: DCNoAnalyticsIcon, name: 'DCNoAnalyticsIcon' },
    { component: DCNoBrowserDataIcon, name: 'DCNoBrowserDataIcon' },
    { component: DCNoCampaignIcon, name: 'DCNoCampaignIcon' },
    { component: DCNoContactIcon, name: 'DCNoContactIcon' },
    { component: DCNoConversationIcon, name: 'DCNoConversationIcon' },
    { component: DCNoDataIcon, name: 'DCNoDataIcon' },
    { component: DCNoDataCommonIcon, name: 'DCNoDataCommonIcon' },
    { component: DCNoDataMachineIcon, name: 'DCNoDataMachineIcon' },
    { component: DCNoDragIcon, name: 'DCNoDragIcon' },
    { component: DCNoFileIcon, name: 'DCNoFileIcon' },
    { component: DCNoFormIcon, name: 'DCNoFormIcon' },
    { component: DCNoGeolocationIcon, name: 'DCNoGeolocationIcon' },
    { component: DCNoGroupIcon, name: 'DCNoGroupIcon' },
    { component: DCNoLeadIcon, name: 'DCNoLeadIcon' },
    { component: DCNoNoteIcon, name: 'DCNoNoteIcon' },
    { component: DCNoNotificationIcon, name: 'DCNoNotificationIcon' },
    { component: DCNoOSIcon, name: 'DCNoOSIcon' },
    { component: DCNoResultFoundIcon, name: 'DCNoResultFoundIcon' },
    { component: DCNoteIcon, name: 'DCNoteIcon' },
    { component: DCNotebookIcon, name: 'DCNotebookIcon' },
    { component: DCNoTemplateIcon, name: 'DCNoTemplateIcon' },
    { component: DCNoTemplateYetIcon, name: 'DCNoTemplateYetIcon' },
    { component: DCNotesIcon, name: 'DCNotesIcon' },
    { component: DCNotificationIcon, name: 'DCNotificationIcon' },
    { component: DCNotification1Icon, name: 'DCNotification1Icon' },
    { component: DCNotification2Icon, name: 'DCNotification2Icon' },
    { component: DCNotificationBellIcon, name: 'DCNotificationBellIcon' },
    { component: DCNotificationBell1Icon, name: 'DCNotificationBell1Icon' },
    { component: DCNotificationBell2Icon, name: 'DCNotificationBell2Icon' },
    { component: DCNotificationBell3Icon, name: 'DCNotificationBell3Icon' },
    { component: DCNoUserIcon, name: 'DCNoUserIcon' },
    { component: DCNoUserFoundIcon, name: 'DCNoUserFoundIcon' },
    { component: DCNumberIcon, name: 'DCNumberIcon' },
    { component: DCOpenInboxIcon, name: 'DCOpenInboxIcon' },
    { component: DCOrderConfirmationIcon, name: 'DCOrderConfirmationIcon' },
    { component: DCOutlineCallIcon, name: 'DCOutlineCallIcon' },
    { component: DCOutlineGearIcon, name: 'DCOutlineGearIcon' },
    { component: DCOutlineStartUpIcon, name: 'DCOutlineStartUpIcon' },
    { component: DCOutreachIcon, name: 'DCOutreachIcon' },
    { component: DCOutreachMenuIcon, name: 'DCOutreachMenuIcon' },
    { component: DCOverviewIcon, name: 'DCOverviewIcon' },
    { component: DCOverviewChartIcon, name: 'DCOverviewChartIcon' },
    { component: DCOverviewMenuIcon, name: 'DCOverviewMenuIcon' },
    { component: DCOverviewReportIcon, name: 'DCOverviewReportIcon' },
    { component: DCOwnerIcon, name: 'DCOwnerIcon' },
    { component: DCParagraphIcon, name: 'DCParagraphIcon' },
    { component: DCPauseIcon, name: 'DCPauseIcon' },
    { component: DCPayIcon, name: 'DCPayIcon' },
    { component: DCPayMoneyIcon, name: 'DCPayMoneyIcon' },
    { component: DCPDFIcon, name: 'DCPDFIcon' },
    { component: DCPdf1Icon, name: 'DCPdf1Icon' },
    { component: DCPdf2Icon, name: 'DCPdf2Icon' },
    { component: DCPDFDownloadIcon, name: 'DCPDFDownloadIcon' },
    { component: DCPDFFileIcon, name: 'DCPDFFileIcon' },
    { component: DCPencilIcon, name: 'DCPencilIcon' },
    { component: DCPhoneIcon, name: 'DCPhoneIcon' },
    { component: DCPhone1Icon, name: 'DCPhone1Icon' },
    { component: DCPhoneCallIcon, name: 'DCPhoneCallIcon' },
    { component: DCPhoneFillIcon, name: 'DCPhoneFillIcon' },
    { component: DCPhoneMessageIcon, name: 'DCPhoneMessageIcon' },
    { component: DCPhoneNumberIcon, name: 'DCPhoneNumberIcon' },
    { component: DCPhoneSitesIcon, name: 'DCPhoneSitesIcon' },
    { component: DCPinIcon, name: 'DCPinIcon' },
    { component: DCPinterestIcon, name: 'DCPinterestIcon' },
    { component: DCPipeDriveIcon, name: 'DCPipeDriveIcon' },
    { component: DCPlaceholderCardIcon, name: 'DCPlaceholderCardIcon' },
    { component: DCPlansIcon, name: 'DCPlansIcon' },
    { component: DCPlayIcon, name: 'DCPlayIcon' },
    { component: DCPNGFileIcon, name: 'DCPNGFileIcon' },
    { component: DCPPTFileIcon, name: 'DCPPTFileIcon' },
    { component: DCPPTXFileIcon, name: 'DCPPTXFileIcon' },
    { component: DCPrinterIcon, name: 'DCPrinterIcon' },
    { component: DCPriorityIcon, name: 'DCPriorityIcon' },
    { component: DCProductLaterIcon, name: 'DCProductLaterIcon' },
    { component: DCProductsIcon, name: 'DCProductsIcon' },
    { component: DCProjectIcon, name: 'DCProjectIcon' },
    { component: DCPSDFileIcon, name: 'DCPSDFileIcon' },
    { component: DCPurchaseIcon, name: 'DCPurchaseIcon' },
    { component: DCPurchasedIcon, name: 'DCPurchasedIcon' },
    { component: DCPurchaseHistoryIcon, name: 'DCPurchaseHistoryIcon' },
    { component: DCQuestionIcon, name: 'DCQuestionIcon' },
    { component: DCQuestionsIcon, name: 'DCQuestionsIcon' },
    { component: DCQuoteIcon, name: 'DCQuoteIcon' },
    { component: DCRadioButtonCheckedIcon, name: 'DCRadioButtonCheckedIcon' },
    { component: DCRadioButtonUncheckedIcon, name: 'DCRadioButtonUncheckedIcon' },
    { component: DCRecipientIcon, name: 'DCRecipientIcon' },
    { component: DCRedditIcon, name: 'DCRedditIcon' },
    { component: DCRedoIcon, name: 'DCRedoIcon' },
    { component: DCReminderIcon, name: 'DCReminderIcon' },
    { component: DCReminderBlueIcon, name: 'DCReminderBlueIcon' },
    { component: DCRemoveDealTagIcon, name: 'DCRemoveDealTagIcon' },
    { component: DCReorderIcon, name: 'DCReorderIcon' },
    { component: DCReplyIcon, name: 'DCReplyIcon' },
    { component: DCReportBuilderIcon, name: 'DCReportBuilderIcon' },
    { component: DCResetIcon, name: 'DCResetIcon' },
    { component: DCReset2Icon, name: 'DCReset2Icon' },
    { component: DCResetPasswordIcon, name: 'DCResetPasswordIcon' },
    { component: DCResizeIcon, name: 'DCResizeIcon' },
    { component: DCRestartIcon, name: 'DCRestartIcon' },
    { component: DCReverseBarIcon, name: 'DCReverseBarIcon' },
    { component: DCRightArrowIcon, name: 'DCRightArrowIcon' },
    { component: DCRightArrow1Icon, name: 'DCRightArrow1Icon' },
    { component: DCRightArrow2Icon, name: 'DCRightArrow2Icon' },
    { component: DCRoundRobinLeadsIcon, name: 'DCRoundRobinLeadsIcon' },
    { component: DCRssFeedIcon, name: 'DCRssFeedIcon' },
    { component: DCSalesIcon, name: 'DCSalesIcon' },
    { component: DCSalesforceIcon, name: 'DCSalesforceIcon' },
    { component: DCSalesPipelinesIcon, name: 'DCSalesPipelinesIcon' },
    { component: DCSaveIcon, name: 'DCSaveIcon' },
    { component: DCScheduleIcon, name: 'DCScheduleIcon' },
    { component: DCSchedule1Icon, name: 'DCSchedule1Icon' },
    { component: DCHelpCenterIcon, name: 'DCHelpCenterIcon' },
    { component: DCFacebookGroupIcon, name: 'DCFacebookGroupIcon' },
    { component: DCReportBugIcon, name: 'DCReportBugIcon' },
    { component: DCWhatsNewIcon, name: 'DCWhatsNewIcon' },
    { component: DCNewFeatureIcon, name: 'DCNewFeatureIcon' },
    { component: DCLiveChat1Icon, name: 'DCLiveChat1Icon' },
    { component: DCPhoneSupportIcon, name: 'DCPhoneSupportIcon' },
    { component: DCSchedullingIcon, name: 'DCSchedullingIcon' },
    { component: DCScoresIcon, name: 'DCScoresIcon' },
    { component: DCSearchIcon, name: 'DCSearchIcon' },
    { component: DCSearch1Icon, name: 'DCSearch1Icon' },
    { component: DCSearchesPerDayIcon, name: 'DCSearchesPerDayIcon' },
    { component: DCSearchReportIcon, name: 'DCSearchReportIcon' },
    { component: DCSelectProductIcon, name: 'DCSelectProductIcon' },
    { component: DCSendEmailIcon, name: 'DCSendEmailIcon' },
    { component: DCSeoIcon, name: 'DCSeoIcon' },
    { component: DCSEOSearchIcon, name: 'DCSEOSearchIcon' },
    { component: DCSettingMenuIcon, name: 'DCSettingMenuIcon' },
    { component: DCSettingsIcon, name: 'DCSettingsIcon' },
    { component: DCSettings1Icon, name: 'DCSettings1Icon' },
    { component: DCSettings2Icon, name: 'DCSettings2Icon' },
    { component: DCSignIcon, name: 'DCSignIcon' },
    { component: DCSignatureIcon, name: 'DCSignatureIcon' },
    { component: DCSignature1Icon, name: 'DCSignature1Icon' },
    { component: DCSignOrderIcon, name: 'DCSignOrderIcon' },
    { component: DCSignPayIcon, name: 'DCSignPayIcon' },
    { component: DCSingleChoiceIcon, name: 'DCSingleChoiceIcon' },
    { component: DCSingleOptionIcon, name: 'DCSingleOptionIcon' },
    { component: DCSMSIcon, name: 'DCSMSIcon' },
    { component: DCSMS1Icon, name: 'DCSMS1Icon' },
    { component: DCSms2Icon, name: 'DCSms2Icon' },
    { component: DCSmsCreditsIcon, name: 'DCSmsCreditsIcon' },
    { component: DCSnapchatIcon, name: 'DCSnapchatIcon' },
    { component: DCSnoozeIcon, name: 'DCSnoozeIcon' },
    { component: DCSocialMediaIcon, name: 'DCSocialMediaIcon' },
    { component: DCSocialMedia1Icon, name: 'DCSocialMedia1Icon' },
    { component: DCSocialMediaPageIcon, name: 'DCSocialMediaPageIcon' },
    { component: DCSocialShareIcon, name: 'DCSocialShareIcon' },
    { component: DCSoundMessageIcon, name: 'DCSoundMessageIcon' },
    { component: DCSourceReviewIcon, name: 'DCSourceReviewIcon' },
    { component: DCSpeedMeterIcon, name: 'DCSpeedMeterIcon' },
    { component: DCSqHalfCheckIcon, name: 'DCSqHalfCheckIcon' },
    { component: DCSquareStarIcon, name: 'DCSquareStarIcon' },
    { component: DCSSLIcon, name: 'DCSSLIcon' },
    { component: DCStarIcon, name: 'DCStarIcon' },
    { component: DCStarFillIcon, name: 'DCStarFillIcon' },
    { component: DCStartUpIcon, name: 'DCStartUpIcon' },
    { component: DCStatusIcon, name: 'DCStatusIcon' },
    { component: DCStepIcon, name: 'DCStepIcon' },
    { component: DCStopIcon, name: 'DCStopIcon' },
    { component: DCStoreIcon, name: 'DCStoreIcon' },
    { component: DCStoreFillIcon, name: 'DCStoreFillIcon' },
    { component: DCStorefrontIcon, name: 'DCStorefrontIcon' },
    { component: DCStoreMenuIcon, name: 'DCStoreMenuIcon' },
    { component: DCSubmissionIcon, name: 'DCSubmissionIcon' },
    { component: DCSubmission1Icon, name: 'DCSubmission1Icon' },
    { component: DCSuitCaseIcon, name: 'DCSuitCaseIcon' },
    { component: DCSupportIcon, name: 'DCSupportIcon' },
    { component: DCSVGFileIcon, name: 'DCSVGFileIcon' },
    { component: DCSwitchIcon, name: 'DCSwitchIcon' },
    { component: DCSwitchOffIcon, name: 'DCSwitchOffIcon' },
    { component: DCSwitchOnIcon, name: 'DCSwitchOnIcon' },
    { component: DCTabletIcon, name: 'DCTabletIcon' },
    { component: DCTagIcon, name: 'DCTagIcon' },
    { component: DCTag1Icon, name: 'DCTag1Icon' },
    { component: DCTag2Icon, name: 'DCTag2Icon' },
    { component: DCTag3Icon, name: 'DCTag3Icon' },
    { component: DCTagsIcon, name: 'DCTagsIcon' },
    { component: DCThunderboltIcon, name: 'DCThunderboltIcon' },
    { component: DCTaskListIcon, name: 'DCTaskListIcon' },
    { component: DCTeamMemberSeatsIcon, name: 'DCTeamMemberSeatsIcon' },
    { component: DCTemplateIcon, name: 'DCTemplateIcon' },
    { component: DCTemplateLibraryIcon, name: 'DCTemplateLibraryIcon' },
    { component: DCTemplatesIcon, name: 'DCTemplatesIcon' },
    { component: DCTemplates1Icon, name: 'DCTemplates1Icon' },
    { component: DCTermsIcon, name: 'DCTermsIcon' },
    { component: DCTextareaIcon, name: 'DCTextareaIcon' },
    { component: DCTextFieldIcon, name: 'DCTextFieldIcon' },
    { component: DCTextFolderIcon, name: 'DCTextFolderIcon' },
    { component: DCThankYouIcon, name: 'DCThankYouIcon' },
    { component: DCTicketsIcon, name: 'DCTicketsIcon' },
    { component: DCTIFFFileIcon, name: 'DCTIFFFileIcon' },
    { component: DCTimeNotificationIcon, name: 'DCTimeNotificationIcon' },
    { component: DCTotalLeadIcon, name: 'DCTotalLeadIcon' },
    { component: DCTotalLeadsIcon, name: 'DCTotalLeadsIcon' },
    { component: DCTrackedSeoKeywordsIcon, name: 'DCTrackedSeoKeywordsIcon' },
    { component: DCTrashIcon, name: 'DCTrashIcon' },
    { component: DCTrash1Icon, name: 'DCTrash1Icon' },
    { component: DCTripadvisorIcon, name: 'DCTripadvisorIcon' },
    { component: DCTwitterIcon, name: 'DCTwitterIcon' },
    { component: DCTwoStarIcon, name: 'DCTwoStarIcon' },
    { component: DCTXTFileIcon, name: 'DCTXTFileIcon' },
    { component: DCUnbounceIcon, name: 'DCUnbounceIcon' },
    { component: DCUndoIcon, name: 'DCUndoIcon' },
    { component: DCUnionpayCardIcon, name: 'DCUnionpayCardIcon' },
    { component: DCUpArrowIcon, name: 'DCUpArrowIcon' },
    { component: DCUpDownArrowIcon, name: 'DCUpDownArrowIcon' },
    { component: DCUploadCSVIcon, name: 'DCUploadCSVIcon' },
    { component: DCUploadFileIcon, name: 'DCUploadFileIcon' },
    { component: DCUsageIcon, name: 'DCUsageIcon' },
    { component: DCUserIcon, name: 'DCUserIcon' },
    { component: DCUser1Icon, name: 'DCUser1Icon' },
    { component: DCUser2Icon, name: 'DCUser2Icon' },
    { component: DCUserBookIcon, name: 'DCUserBookIcon' },
    { component: DCUserCommentIcon, name: 'DCUserCommentIcon' },
    { component: DCUserCredentialIcon, name: 'DCUserCredentialIcon' },
    { component: DCUserFormIcon, name: 'DCUserFormIcon' },
    { component: DCUserInfoIcon, name: 'DCUserInfoIcon' },
    { component: DCUserInfo1Icon, name: 'DCUserInfo1Icon' },
    { component: DCUserOutlineIcon, name: 'DCUserOutlineIcon' },
    { component: DCUserPlaceholderIcon, name: 'DCUserPlaceholderIcon' },
    { component: DCUserPlusIcon, name: 'DCUserPlusIcon' },
    { component: DCUserPortfolioIcon, name: 'DCUserPortfolioIcon' },
    { component: DCUserReviewIcon, name: 'DCUserReviewIcon' },
    { component: DCUserReview1Icon, name: 'DCUserReview1Icon' },
    { component: DCUserReview2Icon, name: 'DCUserReview2Icon' },
    { component: DCUserReview3Icon, name: 'DCUserReview3Icon' },
    { component: DCUsersIcon, name: 'DCUsersIcon' },
    { component: DCUsers2Icon, name: 'DCUsers2Icon' },
    { component: DCUsersFillIcon, name: 'DCUsersFillIcon' },
    { component: DCUserStarOutlineIcon, name: 'DCUserStarOutlineIcon' },
    { component: DCVertical3DotsIcon, name: 'DCVertical3DotsIcon' },
    { component: DCVertical8DotsIcon, name: 'DCVertical8DotsIcon' },
    { component: DCVideoCallIcon, name: 'DCVideoCallIcon' },
    { component: DCVideoCall1Icon, name: 'DCVideoCall1Icon' },
    { component: DCVimeoIcon, name: 'DCVimeoIcon' },
    { component: DCVisaCardIcon, name: 'DCVisaCardIcon' },
    { component: DCVisibilityEyeIcon, name: 'DCVisibilityEyeIcon' },
    { component: DCVoiceIcon, name: 'DCVoiceIcon' },
    { component: DCVoiceDropIcon, name: 'DCVoiceDropIcon' },
    { component: DCVoiceMessageIcon, name: 'DCVoiceMessageIcon' },
    { component: DCWarmIcon, name: 'DCWarmIcon' },
    { component: DCWarningIcon, name: 'DCWarningIcon' },
    { component: DCFilledWatchIcon, name: 'DCFilledWatchIcon' },
    { component: DCWatchIcon, name: 'DCWatchIcon' },
    { component: DCWatch1Icon, name: 'DCWatch1Icon' },
    { component: DCWatch2Icon, name: 'DCWatch2Icon' },
    { component: DCWatch3Icon, name: 'DCWatch3Icon' },
    { component: DCWebAnalyticsIcon, name: 'DCWebAnalyticsIcon' },
    { component: DCWebDevicesIcon, name: 'DCWebDevicesIcon' },
    { component: DCWebGlobeIcon, name: 'DCWebGlobeIcon' },
    { component: DCWebmFileIcon, name: 'DCWebmFileIcon' },
    { component: DCWebSectionIcon, name: 'DCWebSectionIcon' },
    { component: DCWebsiteIcon, name: 'DCWebsiteIcon' },
    { component: DCWebsiteAnalyticsIcon, name: 'DCWebsiteAnalyticsIcon' },
    { component: DCWebViewIcon, name: 'DCWebViewIcon' },
    { component: DCWWWIcon, name: 'DCWWWIcon' },
    { component: DCXLSFileIcon, name: 'DCXLSFileIcon' },
    { component: DCXLSXFileIcon, name: 'DCXLSXFileIcon' },
    { component: DCYahooIcon, name: 'DCYahooIcon' },
    { component: DCYelpIcon, name: 'DCYelpIcon' },
    { component: DCYesNoIcon, name: 'DCYesNoIcon' },
    { component: DCYoutubeIcon, name: 'DCYoutubeIcon' },
    { component: DCZohoIcon, name: 'DCZohoIcon' },
    { component: DCTiktokIcon, name: 'DCTiktokIcon' },
    { component: DCMicFilledIcon, name: 'DCMicFilledIcon' },
    { component: DCGenderIcon, name: 'DCGenderIcon' },
    { component: DCFilledGenderIcon, name: 'DCFilledGenderIcon' },
];
export default {};
export { icons };
