import React from 'react';

import { SVGIcon } from '../../UIKit/index';

export default function DCFacebookAd(props) {
    return (
        <SVGIcon {...props} viewBox="0 0 504 512">
            <g transform="translate(-3 -1.651)">
                <path fill="#2B88F8" d="M29.86,272.26h241.37v189.65H29.86V272.26z" />
                <path
                    fill="#D9EAFF"
                    d="M4,461.93H297.1v17.24c0,19.04-15.44,34.48-34.48,34.48c0,0,0,0,0,0H38.48   C19.44,513.65,4,498.21,4,479.17c0,0,0,0,0,0V461.93z"
                />
                <path
                    fill="#005EFF"
                    d="M64.35,341.22v-68.96H29.86v189.65h241.37v-34.48H150.55   C102.94,427.43,64.35,388.83,64.35,341.22z"
                />
                <path
                    fill="#005EFF"
                    d="M55.73,487.79c-14.28,0-25.86-11.58-25.86-25.86l0,0H4v17.24c0,19.04,15.44,34.48,34.48,34.48   h224.13c15.68-0.05,29.35-10.68,33.27-25.86L55.73,487.79z"
                />
                <path
                    fill="#005EFF"
                    d="M386.18,280.86l107.84,1.79c7.36,0,13.33,5.97,13.33,13.33c0,0,0,0,0,0v106.66   c0,7.36-5.97,13.33-13.33,13.33l0,0H380.7c-7.36,0-13.33-5.97-13.33-13.33c0,0,0,0,0,0V295.99   C367.36,288.63,378.86,280.86,386.18,280.86z"
                />
                <circle fill="#FFFFFF" cx="434.03" cy="349.33" r="26.67" />
                <path
                    fill="#005EFF"
                    d="M391.97,323.98v-43.1h-8.62c-9.52,0-17.24,7.72-17.24,17.24v103.44   c0,9.52,7.72,17.24,17.24,17.24h103.44c9.52,0,17.24-7.72,17.24-17.24v-8.62h-43.1C422.85,392.95,391.97,362.07,391.97,323.98z"
                />
                <circle fill="#AAE1F9" cx="444.03" cy="339.33" r="10" />
                <circle fill="##005EFF" cx="210.69" cy="72.66" r="70" />
                <path fill="#FFFFFF" d="M193.68,99.82V48.1l51.72,25.86L193.68,99.82z" />
                <path
                    fill="#005EFF"
                    d="M236.78,117.06c-38.09,0-68.96-30.88-68.96-68.96c0,0,0,0,0,0c-0.02-11.95,3.1-23.69,9.05-34.05   c-32.83,19.3-43.8,61.57-24.49,94.4s61.57,43.8,94.4,24.49c10.12-5.95,18.55-14.38,24.49-24.49   C260.75,114.33,248.85,117.3,236.78,117.06L236.78,117.06z"
                />
                <path
                    fill="#005EFF"
                    d="M390.59,13.71L494.03,16c7.36,0,13.33,5.97,13.33,13.33c0,0,0,0,0,0V136   c0,7.36-5.97,13.33-13.33,13.33l0,0H380.7c-7.36,0-13.33-5.97-13.33-13.33c0,0,0,0,0,0V29.34   C367.36,21.97,383.22,13.71,390.59,13.71z"
                />
                <path
                    fill="#005EFF"
                    d="M391.97,56.71v-43.1h-8.62c-9.52,0-17.24,7.72-17.24,17.24V134.3c0,9.52,7.72,17.24,17.24,17.24   h103.44c9.52,0,17.24-7.72,17.24-17.24v-8.62h-43.1C422.85,125.68,391.97,94.8,391.97,56.71   C391.97,56.71,391.97,56.71,391.97,56.71z"
                />
                <path
                    fill="#1876E8"
                    d="M142.49,359.9l29.86-17.24l34.48,59.72c4.76,8.25,1.94,18.79-6.31,23.55   c-8.25,4.76-18.79,1.94-23.55-6.31L142.49,359.9z"
                />
                <path
                    fill="#1876E8"
                    d="M194.2,380.47l-21.82-37.85l-29.91,17.24l20.43,35.34l14.91-8.62L194.2,380.47z"
                />
                <path
                    fill="#D9EAFF"
                    d="M325.16,314.17l-147.34,55.17l-43.1-74.71l121.47-99.96L325.16,314.17z"
                />
                <path
                    fill="#6B8AAA"
                    d="M97.41,316.24l37.33-21.55l43.1,74.65l-37.33,21.55c-12.37,7.14-28.19,2.9-35.33-9.47   l-17.24-29.86C80.81,339.2,85.04,323.39,97.41,316.24z"
                />
                <path
                    fill="#D9EAFF"
                    d="M72.95,360.18l14.93-8.62l17.24,29.86l-14.93,8.62c-8.25,4.76-18.79,1.94-23.55-6.31   S64.7,364.94,72.95,360.18z"
                />
                <path
                    fill="#1876E8"
                    d="M187.82,342.96c-0.89,0.33-1.76,0.74-2.59,1.21l-16.46,9.48l9.05,15.69l147.34-55.18   l-10.79-18.62L187.82,342.96z"
                />
                <path
                    fill="#6B8AAA"
                    d="M164.02,356.41c-20.66,11.83-46.99,4.67-58.82-15.99c-4.44-7.75-6.36-16.68-5.5-25.57   l-2.33,1.38c-12.38,7.13-16.63,22.94-9.5,35.32c0,0.01,0.01,0.02,0.01,0.02l17.24,29.83c7.13,12.38,22.94,16.63,35.32,9.5   c0.01,0,0.02-0.01,0.02-0.01l37.36-21.55l-9.05-15.69L164.02,356.41z"
                />
                <path
                    fill="#FFFFFF"
                    d="M460.94,39.48H443.7c-14.28,0-25.86,11.58-25.86,25.86l0,0v8.62h-8.62   c-4.76,0-8.62,3.86-8.62,8.62s3.86,8.62,8.62,8.62h8.62v25.86c0,4.76,3.85,8.62,8.61,8.63c4.76,0,8.62-3.85,8.63-8.61   c0-0.01,0-0.01,0-0.02V91.2h8.62c4.76,0,8.62-3.86,8.62-8.62s-3.86-8.62-8.62-8.62h-8.62v-8.62c0-4.76,3.86-8.62,8.62-8.62l0,0   h17.24c4.76,0,8.62-3.86,8.62-8.62C469.56,43.34,465.7,39.48,460.94,39.48z"
                />
                <g transform="translate(32.704 15.396)">
                    <path
                        fill="#D9EAFF"
                        d="M264.43,170.64c-4.76,0-8.62-3.86-8.62-8.62c0-1.87,0.6-3.68,1.72-5.17l25.86-34.48    c2.85-3.81,8.25-4.59,12.07-1.74c3.81,2.85,4.59,8.25,1.74,12.07c0,0.01-0.01,0.01-0.01,0.02l-25.86,34.48    C269.7,169.36,267.14,170.64,264.43,170.64z"
                    />
                    <path
                        fill="#D9EAFF"
                        d="M290.29,205.13c-4.76,0-8.62-3.86-8.62-8.62c0-2.71,1.28-5.27,3.45-6.9l34.48-25.86    c3.81-2.86,9.21-2.08,12.07,1.72c2.86,3.81,2.08,9.21-1.72,12.07l0,0l-34.48,25.86C293.97,204.53,292.16,205.13,290.29,205.13z"
                    />
                    <path
                        fill="#D9EAFF"
                        d="M316.16,239.63c-4.76,0.48-9.01-3-9.48-7.76c-0.48-4.76,3-9.01,7.76-9.48l43.1-8.62    c4.74-1.05,9.43,1.95,10.48,6.69c1.05,4.74-1.95,9.43-6.69,10.48c-0.11,0.02-0.23,0.05-0.34,0.07l-43.1,8.62H316.16z"
                    />
                </g>
                <path
                    fill="#FFFFFF"
                    d="M426.46,125.68c3.48-0.02,6.61-2.13,7.93-5.34c-5.95-2.48-11.53-5.79-16.55-9.83v6.55   C417.84,121.83,421.7,125.68,426.46,125.68C426.46,125.68,426.46,125.68,426.46,125.68z"
                />
            </g>
        </SVGIcon>
    );
}
