import React from 'react';
import { Div } from 'UIKit';
import Card from './card';
import './style.scss';
type Props = {
    data: any;
    origin: string;
    handleClose: Function;
    type: string;
};

const CardContainer: React.FC<Props> = props => {
    const { data, origin, handleClose, type } = props;
    return (
        <Div className="CardContainer BodyBoxInner">
            {data.map((data: any, index: number) => (
                <Card
                    cardType={type}
                    key={index}
                    reminder={data}
                    origin={origin}
                    handleClose={handleClose}
                />
            ))}
        </Div>
    );
};

export default CardContainer;
