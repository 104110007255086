import React from 'react';

import { SVGIcon } from 'UIKit/index';
import Site from 'Assets/images/AppLogo/site.svg';

const backgroundStyle = {
    backgroundImage: 'url(' + Site + ')',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
};

export default function DCAgency(props) {
    return <SVGIcon {...props} viewBox="0 0 2000 2000" style={backgroundStyle} />;
}
