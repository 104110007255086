import React from 'react';

import { SVGIcon } from '../../UIKit/index';

export default function DCLog(props) {
    return (
        <SVGIcon {...props} height="512" viewBox="0 0 56 60" width="512">
            <g>
                <path
                    id="Shape"
                    d="m12 60h40c2.209139 0 4-1.790861 4-4v-45.17c-.0009703-.4133441-.0878051-.8219787-.255-1.2-.0094003-.02147917-.0204326-.0422065-.033-.062-.144993-.31967777-.345417-.61117277-.592-.861l-7.826-7.827c-.2498272-.24658304-.5413222-.44700702-.861-.592-.0197935-.01256736-.0405208-.02359965-.062-.033-.3783297-.16733042-.7873188-.25416826-1.201-.255h-33.17c-2.209139 0-4 1.790861-4 4v28h-6c-1.1045695 0-2 .8954305-2 2v18c0 1.1045695.8954305 2 2 2h6v2c0 2.209139 1.790861 4 4 4zm40.586-51h-2.706c-1.5898946-.00165356-2.8783464-1.29010535-2.88-2.88v-2.706zm-42.586-5c0-1.1045695.8954305-2 2-2h33v4.12c.002756 2.69400717 2.1859928 4.877244 4.88 4.88h4.12v45c0 1.1045695-.8954305 2-2 2h-40c-1.1045695 0-2-.8954305-2-2v-2h24c1.1045695 0 2-.8954305 2-2v-18c0-1.1045695-.8954305-2-2-2h-24zm-8 30h32v18h-32z"
                />
                <path
                    id="Path"
                    d="m15 9h24c.5522847 0 1-.44771525 1-1s-.4477153-1-1-1h-24c-.5522847 0-1 .44771525-1 1s.4477153 1 1 1z"
                />
                <path
                    id="Path"
                    d="m15 14h24c.5522847 0 1-.4477153 1-1s-.4477153-1-1-1h-24c-.5522847 0-1 .4477153-1 1s.4477153 1 1 1z"
                />
                <path
                    id="Path"
                    d="m15 19h34c.5522847 0 1-.4477153 1-1s-.4477153-1-1-1h-34c-.5522847 0-1 .4477153-1 1s.4477153 1 1 1z"
                />
                <path
                    id="Path"
                    d="m15 24h34c.5522847 0 1-.4477153 1-1s-.4477153-1-1-1h-34c-.5522847 0-1 .4477153-1 1s.4477153 1 1 1z"
                />
                <path
                    id="Path"
                    d="m50 28c0-.5522847-.4477153-1-1-1h-34c-.5522847 0-1 .4477153-1 1s.4477153 1 1 1h34c.5522847 0 1-.4477153 1-1z"
                />
                <path
                    id="Path"
                    d="m49 32h-9c-.5522847 0-1 .4477153-1 1s.4477153 1 1 1h9c.5522847 0 1-.4477153 1-1s-.4477153-1-1-1z"
                />
                <path
                    id="Path"
                    d="m49 37h-9c-.5522847 0-1 .4477153-1 1s.4477153 1 1 1h9c.5522847 0 1-.4477153 1-1s-.4477153-1-1-1z"
                />
                <path
                    id="Path"
                    d="m49 42h-9c-.5522847 0-1 .4477153-1 1s.4477153 1 1 1h9c.5522847 0 1-.4477153 1-1s-.4477153-1-1-1z"
                />
                <path
                    id="Path"
                    d="m49 47h-9c-.5522847 0-1 .4477153-1 1s.4477153 1 1 1h9c.5522847 0 1-.4477153 1-1s-.4477153-1-1-1z"
                />
                <path
                    id="Path"
                    d="m49 52h-9c-.5522847 0-1 .4477153-1 1s.4477153 1 1 1h9c.5522847 0 1-.4477153 1-1s-.4477153-1-1-1z"
                />
                <path
                    id="Shape"
                    d="m17 49c2.209139 0 4-1.790861 4-4v-4c0-2.209139-1.790861-4-4-4s-4 1.790861-4 4v4c0 2.209139 1.790861 4 4 4zm-2-8c0-1.1045695.8954305-2 2-2s2 .8954305 2 2v4c0 1.1045695-.8954305 2-2 2s-2-.8954305-2-2z"
                />
                <path
                    id="Path"
                    d="m12 48c0-.5522847-.4477153-1-1-1h-4v-9c0-.5522847-.44771525-1-1-1s-1 .4477153-1 1v9c0 1.1045695.8954305 2 2 2h4c.5522847 0 1-.4477153 1-1z"
                />
                <path
                    id="Path"
                    d="m27 49c2.209139 0 4-1.790861 4-4v-1c0-.5522847-.4477153-1-1-1h-2c-.5522847 0-1 .4477153-1 1s.4477153 1 1 1h1c0 1.1045695-.8954305 2-2 2s-2-.8954305-2-2v-4c-.002082-.975665.7001319-1.8104465 1.6617441-1.9754569s1.9019529.3879127 2.2252559 1.3084569c.1996204.4974702.7559816.7490697 1.2613606.5704166.5053791-.1786531.780013-.724012.6226394-1.2364166-.6495992-1.8363097-2.5274807-2.9377286-4.4472637-2.6084182-1.9197831.3293103-3.323249 1.9935958-3.3237363 3.9414182v4c0 2.209139 1.790861 4 4 4z"
                />
            </g>
        </SVGIcon>
    );
}
