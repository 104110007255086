import React from 'react';

import { SVGIcon } from '../../UIKit/index';

export default function DCUnbounce(props) {
    return (
        <SVGIcon {...props} viewBox="0 0 512 512">
            <g transform="translate(-27 -256.6)">
                <g transform="translate(27 256.6)">
                    <path
                        fill="#288CC0"
                        d="M256.69,2.2C116.24,2.2,2.39,116.06,2.39,256.51s113.86,254.31,254.31,254.31    S511,396.96,511,256.51l0,0C511.04,116.1,397.25,2.24,256.84,2.2C256.79,2.2,256.74,2.2,256.69,2.2z M186.44,435.44    c2.91-17.93,5.49-34.76,8.05-50.12c-55.98-28.17-91.5-84.51-82.33-150.02c12.44-88.55,80.5-134.3,166.49-126.61    c6.67-9.42,14.01-18.34,21.96-26.71c14.63-16.1,35.86-16.83,26.34,2.56c-19.39,36.96-27.08,64.76-43.91,109.77    c-16.83,47.57-42.45,117.46-76.84,240.4C203.27,446.04,185.34,444.21,186.44,435.44z M401.59,272.24    c-11.34,84.51-90.38,132.82-169.02,124.41c4.1-14.69,9.11-29.11,15-43.18c5.12-9.14,23.79-12.82,38.05-18.65    c30.59-10.83,52.42-38.04,56.35-70.25c2.72-22.66-3.45-45.49-17.22-63.69c-9.51-12.08-18.3-19.39-15.37-30.37    c4.36-15.46,9.74-30.62,16.1-45.37C378.93,153.61,409.22,212.16,401.59,272.24L401.59,272.24z"
                    />
                    <path
                        fill="#288CC0"
                        d="M171.44,242.97c-2.82,21.75,2.81,43.75,15.74,61.47c8.42,10.97,14.63,17.56,17.56,22.32    c6.63-39.1,15.8-77.72,27.45-115.63c4.39-13.54,11.71-32.2,16.83-45.01c-6.22,2.2-15.37,4.39-22.32,7.32    C196.58,184.28,175.19,211.19,171.44,242.97"
                    />
                </g>
            </g>
        </SVGIcon>
    );
}
