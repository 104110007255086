import React from 'react';

import { Redirect, Route } from 'react-router-dom';
import { isAuthenticated } from 'Utils/auth';
import PasswordDialog from 'Hooks/PasswordDialog';
import PurchaseDialog from 'Hooks/PurchaseDialog';
import PayWallDialog from 'Hooks/PayWallDialog';
import ComparisonDialog from 'Hooks/ComparisonDialog';

const PrivateRoute = props => {
    const { component: Component, ...others } = props;
    return (
        <Route
            {...others}
            render={_props => isAuthenticated() ? (
                    <>
                        <Component {..._props} />
                        <PasswordDialog />
                        <PurchaseDialog />
                        <PayWallDialog />
                        <ComparisonDialog />
                    </>
                ) : (
                    <Redirect
                        to={{
                            pathname: '/auth/login',
                            search: `?redirect=${props.location.pathname}`,
                            state: { from: props.location },
                        }}
                    />
                )
            }
        />
    );
};

export default PrivateRoute;
