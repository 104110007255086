import React from 'react';

import { SVGIcon } from '../../UIKit/index';

export default function DCGrayPurchaseHistory(props) {
    return (
        <SVGIcon {...props} viewBox="0 0 496.123 479.19">
            <defs>
                <linearGradient
                    id="linear-gradient"
                    y1="0.5"
                    x2="1"
                    y2="0.5"
                    gradientUnits="objectBoundingBox"
                >
                    <stop offset="0" stopColor="#fff" stopOpacity="0.102" />
                    <stop offset="1" stopColor="#e3e2e3" stopOpacity="0.502" />
                </linearGradient>
                <linearGradient
                    id="linear-gradient-2"
                    x1="3262.971"
                    y1="0.432"
                    x2="3262.835"
                    y2="2.305"
                    gradientUnits="objectBoundingBox"
                >
                    <stop offset="0" stopColor="#a737d5" />
                    <stop offset="1" stopColor="#ef497a" />
                </linearGradient>
                <linearGradient
                    id="linear-gradient-3"
                    x1="3077.124"
                    y1="0.436"
                    x2="3076.997"
                    y2="2.309"
                />
            </defs>
            <g id="Artboard_3" transform="translate(-8.14 -16.5)">
                <ellipse
                    id="_Path_"
                    cx="147.58"
                    cy="14.49"
                    rx="147.58"
                    ry="14.49"
                    transform="translate(80.76 466.71)"
                    fill="#f4f5f6"
                />
                <circle
                    id="Ellipse_9"
                    cx="218.65"
                    cy="218.65"
                    r="218.65"
                    transform="translate(9.64 18)"
                    fill="#fff"
                    stroke="#707171"
                    strokeMiterlimit="10"
                    strokeWidth="3"
                />
                <circle
                    id="Ellipse_10"
                    cx="12.47"
                    cy="12.47"
                    r="12.47"
                    transform="translate(298.67 62.54)"
                    fill="#fff"
                    stroke="#e2e2e2"
                    strokeMiterlimit="10"
                    strokeWidth="2"
                />
                <circle
                    id="Ellipse_11"
                    cx="8.15"
                    cy="8.15"
                    r="8.15"
                    transform="translate(266.74 48.49)"
                    fill="#fff"
                    stroke="#e2e2e2"
                    strokeMiterlimit="10"
                    strokeWidth="2"
                />
                <circle
                    id="Ellipse_12"
                    cx="12.47"
                    cy="12.47"
                    r="12.47"
                    transform="translate(147.04 389.85)"
                    fill="#fff"
                    stroke="#e2e2e2"
                    strokeMiterlimit="10"
                    strokeWidth="2"
                />
                <circle
                    id="Ellipse_13"
                    cx="8.15"
                    cy="8.15"
                    r="8.15"
                    transform="translate(194.17 409.94)"
                    fill="#fff"
                    stroke="#e2e2e2"
                    strokeMiterlimit="10"
                    strokeWidth="2"
                />
                <path
                    id="Path_120"
                    d="M43.29,218.17h-8.9v-8.88a1.1,1.1,0,0,0-1.1-1.11h0a1.12,1.12,0,0,0-1.13,1.11v8.88H23.29a1.12,1.12,0,1,0,0,2.23h8.87v8.9a1.12,1.12,0,0,0,1.13,1.1,1.1,1.1,0,0,0,1.1-1.1h0v-8.9h8.9a1.12,1.12,0,1,0,0-2.23Z"
                    fill="#e2e2e2"
                    stroke="#e2e2e2"
                    strokeMiterlimit="10"
                    strokeWidth="0.75"
                />
                <path
                    id="Path_121"
                    d="M320.47,405.86h-8.89V397a1.11,1.11,0,0,0-1.11-1.11h0a1.12,1.12,0,0,0-1.13,1.11v8.88h-8.87a1.12,1.12,0,1,0,0,2.23h8.87V417a1.12,1.12,0,0,0,2.24,0h0v-8.9h8.89a1.12,1.12,0,1,0,0-2.23Z"
                    fill="#e2e2e2"
                    stroke="#e2e2e2"
                    strokeMiterlimit="10"
                    strokeWidth="0.75"
                />
                <path
                    id="Path_122"
                    d="M155.35,90.4C129.08,120,37.94,87.12,38.9,141.65c1.12,63.85,90.7,82.64,61.69,127.51s3,95.18,119.58,94.23S524.24,306.12,479.06,245,464,180.64,466.19,153.61s-20.65-86.34-110.92-36.89S221.85,15.5,155.35,90.4Z"
                    fill="#f4f5f6"
                />
                <path
                    id="Path_123"
                    d="M412,356.32s76.23-20.26,74-74.36-9.92-75.39,10.2-93.85.34-48.35-34.52-46.11-55.16,48.14-44.92,90.36,28.83,73,4.91,93-.21-11.47-.56-35.5-4.22-58.92-31.94-68.56-19.71,25.16-12.51,60.7,2.19,60.61,2.19,60.61Z"
                    fill="#f4f5f6"
                />
                <path
                    id="Path_124"
                    d="M468,172.74a34.608,34.608,0,0,0-4.8,3.61,45.59,45.59,0,0,0-4.17,4.17,53.42,53.42,0,0,0-6.71,9.46,62.241,62.241,0,0,0-7.23,21.85,78.669,78.669,0,0,0-.74,11.72,112.842,112.842,0,0,0,.9,11.88c1,7.91,2.3,15.81,3.53,23.67.63,3.93,1.21,7.85,1.69,11.77s.86,7.82,1,11.7a92.232,92.232,0,0,1-.18,11.53,62.784,62.784,0,0,1-2,11.14L449,306.6l-.19.68-.23.66-.94,2.65-1.11,2.58c-.18.44-.39.85-.61,1.27l-.64,1.25c-.22.42-.42.84-.65,1.25l-.73,1.21-.73,1.21-.37.61-.4.58-1.62,2.34c-.58.75-1.19,1.49-1.78,2.24-.3.36-.58.75-.91,1.1l-1,1.07-1,1.06a14.126,14.126,0,0,1-1,1.05l-2.09,2-.52.51-.55.48-1.11,1-1.11,1-.56.48-.58.46-2.35,1.81c-.76.63-1.6,1.18-2.41,1.76l-2.46,1.71h0a.16.16,0,0,0,0,.25.25.25,0,0,0,.28,0,91.2,91.2,0,0,0,9.93-6.82l1.17-.93.59-.46.55-.49,2.21-2q.555-.473,1.06-1l1-1.05,1-1.05c.35-.35.64-.73,1-1.1.63-.74,1.28-1.47,1.9-2.22l1.74-2.32.44-.58.4-.61.78-1.22.79-1.21c.24-.42.47-.84.7-1.26l.7-1.27c.23-.42.47-.84.66-1.28l1.22-2.62,1-2.68.26-.68.22-.69.43-1.37a63.129,63.129,0,0,0,2.3-11.37,91.76,91.76,0,0,0,.41-11.74c-.1-3.95-.43-7.9-.87-11.85s-1-7.91-1.56-11.85c-1.2-7.89-2.5-15.75-3.42-23.59-.44-3.92-.83-7.79-.92-11.65a88.645,88.645,0,0,1,.57-11.44,66.43,66.43,0,0,1,6.29-21.55c.42-.84.87-1.67,1.31-2.5s.93-1.64,1.44-2.43a55,55,0,0,1,7-9A36.74,36.74,0,0,1,468.3,173h0a.17.17,0,0,0,0-.25A.25.25,0,0,0,468,172.74Z"
                    fill="#e3e2e3"
                />
                <path
                    id="Path_125"
                    d="M387.11,235.77a254.134,254.134,0,0,1,8,24.38,195.715,195.715,0,0,1,5.14,24.48c.58,4.08.89,8.15,1.12,12.2l.11,3v4.51l-.06,1.5-.06,1.5a9.432,9.432,0,0,1-.08,1.5,91.5,91.5,0,0,1-1.6,11.82,84.249,84.249,0,0,1-3.32,11.39h0a.218.218,0,0,0,.38.17,56.921,56.921,0,0,0,2.59-5.4l.58-1.39.28-.69.24-.71.95-2.85c.3-1,.52-1.94.78-2.91l.38-1.46.3-1.49.29-1.48c.1-.5.22-1,.27-1.49l.42-3a99.6,99.6,0,0,0-.34-24.68c-.29-2.07-.57-4.15-1-6.23s-.81-4.15-1.27-6.22l-.76-3.1-.84-3.1c-.27-1-.59-2.07-.89-3.1s-.63-2.05-1-3.08-.68-2.05-1-3.08-.72-2-1.09-3.06c-.75-2-1.57-4.07-2.41-6.09a119.408,119.408,0,0,0-5.76-12,.24.24,0,0,0-.27-.11.15.15,0,0,0-.1.2Z"
                    fill="#fff"
                    opacity="0.69"
                />
                <path
                    id="Path_126"
                    d="M173.89,255.93c-5.37-20.16-16.81-36.59-30.81-46.6a59.19,59.19,0,0,1-24-37.49c-.29-1.59-.65-3.18-1.08-4.79C111.6,142.8,91.5,127.1,73.18,132s-28,28.5-21.5,52.75a58.74,58.74,0,0,0,5.54,13.7,59.47,59.47,0,0,1,6.21,43.45,87.27,87.27,0,0,0,.77,43.26c6,22.54,19.61,40.42,35.89,49.88a58.36,58.36,0,0,1,16.1,13.79c7.42,9.21,18.26,13.91,28.55,11.17,11.1-3,18.45-13.74,19.55-26.68a62.16,62.16,0,0,1,5.8-21.1C177.46,296.47,179.32,276.32,173.89,255.93Z"
                    fill="#f4f5f6"
                />
                <path
                    id="Path_127"
                    d="M86.46,156.07c6.67,15.39,12.83,31,18.83,46.64,1.5,3.91,2.94,7.85,4.39,11.78l2.17,5.9L114,226.3c1.4,3.95,2.86,7.88,4.22,11.85l4.07,11.9c2.61,8,5.3,15.91,7.76,23.92,1.24,4,2.56,8,3.72,12l3.52,12.07c4.54,16.14,8.68,32.41,12,48.85l-.7.15-2.94-12.21-.73-3.05-.81-3-1.62-6.06-1.61-6.07c-.53-2-1.14-4-1.7-6-1.16-4-2.29-8-3.48-12.06-4.85-16-9.91-32-15.3-47.84-2.75-7.9-5.44-15.83-8.25-23.72L110,221.05l-2.14-5.9c-1.43-3.94-2.85-7.88-4.32-11.8q-4.29-11.81-8.77-23.54t-9-23.46Z"
                    fill="#e3e2e3"
                />
                <path
                    id="Path_128"
                    d="M117.68,296c-8.42-8.9-18.92-14.16-28.94-15.41A34.84,34.84,0,0,1,65.9,268c-.59-.74-1.22-1.46-1.89-2.17-10.13-10.69-24.87-13.17-32.93-5.54s-6.39,22.49,3.73,33.19a34.731,34.731,0,0,0,6.7,5.51A35,35,0,0,1,56.89,319.6a51.36,51.36,0,0,0,12.53,22.08c9.42,9.94,21.43,15.35,32.46,15.68A34.12,34.12,0,0,1,114,360c6.4,2.68,13.29,2.07,17.82-2.22,4.88-4.62,5.64-12.23,2.58-19.21a36.6,36.6,0,0,1-2.94-12.49C130.88,315.88,126.19,305,117.68,296Z"
                    fill="#f4f5f6"
                />
                <path
                    id="Path_129"
                    d="M44.67,268.94C52.42,275,60,281.33,67.44,287.72c1.87,1.6,3.72,3.22,5.57,4.84L75.78,295l2.75,2.45c1.83,1.65,3.69,3.26,5.5,4.93l5.43,5c3.58,3.38,7.19,6.72,10.71,10.16,1.76,1.72,3.55,3.4,5.28,5.16l5.2,5.24c6.86,7.05,13.56,14.27,19.87,21.82l-.32.28-4.94-5.47L124,343.19l-1.27-1.34-2.53-2.67-2.53-2.68c-.84-.89-1.72-1.76-2.58-2.64-1.72-1.74-3.43-3.51-5.17-5.24q-10.49-10.37-21.29-20.38c-3.64-3.31-7.24-6.64-10.9-9.92L75,295.85l-2.76-2.44c-1.84-1.63-3.67-3.26-5.53-4.88q-5.52-4.87-11.11-9.68c-3.72-3.22-7.46-6.4-11.21-9.58Z"
                    fill="#e3e2e3"
                />
                <path
                    id="Path_130"
                    d="M408.21,261.89c1.29,1,36.61-1.56,51.44,1.51,0,0,11.5-50.18-21.52-52.15s-44.47,18.4-44.47,18.4Z"
                    fill="#e3e2e3"
                />
                <path
                    id="Path_131"
                    d="M373.34,208.88l64.79,2.37s-23.61.32-21,30.18,15.91,117.06-17.88,117.15-34.33-15.35-34.33-15.35Z"
                    fill="#f4f5f6"
                />
                <path
                    id="Path_132"
                    d="M343.08,194.87l25-5.83s16.75-1.5,19,13.38,1.61,103.07.9,121.25,1,32.18,11.23,34.91l-74.45,1.28Z"
                    fill="#e3e2e3"
                />
                <path
                    id="Path_133"
                    d="M292.16,196.08s45.6,2.51,76-7c0,0-20.18-1.38-20.18,45.22,0,35.62,5.28,103.62.67,116.53S326.75,381,302,366.13,292.16,196.08,292.16,196.08Z"
                    fill="#f4f5f6"
                />
                <path
                    id="Path_134"
                    d="M280,126.23s50.32,5.48,56.8-3c0,0-5.15-15.35-20.46-14.33S280,126.23,280,126.23Z"
                    fill="#5e5e5e"
                />
                <path
                    id="Path_135"
                    d="M116.38,143.5C120.77,192.81,130,297.11,134,347.92a22.84,22.84,0,0,0,21.31,21c40.39,2.59,131.29,7.62,166.17,2.94h0a23.22,23.22,0,0,1-13-21l1.85-228c.07-8.06,8.09-13.85,15.63-11,4.76,1.78,8.36,5.58,10.82,11.34-3.29-9.59-8.47-16.62-20.46-14.33,0,0-117.33,1.18-174.27,4.71a27.55,27.55,0,0,0-25.67,29.92Z"
                    fill="#707171"
                />
                <g id="Group_2">
                    <path
                        id="Path_136"
                        d="M145.41,120.08,303.06,114c-6.5,48.14-3.76,146.17-1.28,242.78a15.17,15.17,0,0,0,8.69,13.33h0s-105.15,1.22-150.34-2a22.76,22.76,0,0,1-20.89-19.35c-6.21-41.39-21.05-148.32-18-204.93a25.14,25.14,0,0,1,24.17-23.75Z"
                        fill="url(#linear-gradient)"
                    />
                </g>
                <path
                    id="Path_137"
                    d="M143.67,188.76l1.84,17.66a6.54,6.54,0,0,0,6.26,5.86l10.28.4a6.54,6.54,0,0,0,6.79-6.54v-19a6.54,6.54,0,0,0-7.08-6.52l-12.12,1a6.54,6.54,0,0,0-5.97,7.14Z"
                    fill="#f4f5f6"
                />
                <path
                    id="Path_138"
                    d="M179.83,183.32c17.11-.53,82.8-2.67,99.39-3.21a3.71,3.71,0,0,1,3.82,3.71h0a3.71,3.71,0,0,1-3.57,3.7l-99.32,3.69a3.71,3.71,0,0,1-3.82-3.23l-.06-.48a3.71,3.71,0,0,1,3.56-4.18Z"
                    fill="#e3e2e3"
                />
                <path
                    id="Path_139"
                    d="M178.75,206.6h0a1.58,1.58,0,0,0,1.39.77l68.2-1.48a1.58,1.58,0,0,0,1.54-1.58v-4.49a1.58,1.58,0,0,0-1.63-1.58L182,200.72a3.89,3.89,0,0,0-3.25,5.88Z"
                    fill="#e3e2e3"
                />
                <path
                    id="Path_140"
                    d="M143.67,237.77l1.84,17.66a6.54,6.54,0,0,0,6.26,5.86l10.28.4a6.54,6.54,0,0,0,6.79-6.54V236.1a6.54,6.54,0,0,0-7.08-6.52l-12.12,1a6.54,6.54,0,0,0-5.97,7.19Z"
                    fill="#f4f5f6"
                />
                <path
                    id="Path_141"
                    d="M179.83,232.33c17.11-.53,82.8-2.67,99.39-3.21a3.71,3.71,0,0,1,3.82,3.71h0a3.71,3.71,0,0,1-3.57,3.7l-99.32,3.69a3.71,3.71,0,0,1-3.82-3.23l-.06-.48a3.71,3.71,0,0,1,3.56-4.18Z"
                    fill="#e3e2e3"
                />
                <path
                    id="Path_142"
                    d="M178.75,255.61h0a1.58,1.58,0,0,0,1.39.77l68.2-1.48a1.58,1.58,0,0,0,1.54-1.58v-4.49a1.58,1.58,0,0,0-1.63-1.58L182,249.73a3.89,3.89,0,0,0-3.25,5.88Z"
                    fill="#e3e2e3"
                />
                <path
                    id="Path_143"
                    d="M143.67,286.78l1.84,17.66a6.54,6.54,0,0,0,6.26,5.86l10.28.4a6.54,6.54,0,0,0,6.79-6.54V285.11a6.54,6.54,0,0,0-7.08-6.52l-12.12,1a6.54,6.54,0,0,0-5.97,7.19Z"
                    fill="#f4f5f6"
                />
                <path
                    id="Path_144"
                    d="M179.83,281.34c17.11-.53,82.8-2.67,99.39-3.21a3.71,3.71,0,0,1,3.82,3.71h0a3.71,3.71,0,0,1-3.57,3.7l-99.32,3.69a3.71,3.71,0,0,1-3.82-3.23l-.06-.48a3.71,3.71,0,0,1,3.56-4.18Z"
                    fill="#e3e2e3"
                />
                <path
                    id="Path_145"
                    d="M178.75,304.62h0a1.58,1.58,0,0,0,1.39.77l68.2-1.48a1.58,1.58,0,0,0,1.54-1.58v-4.49a1.58,1.58,0,0,0-1.63-1.58L182,298.74a3.89,3.89,0,0,0-3.25,5.88Z"
                    fill="#e3e2e3"
                />
                <path
                    id="Path_146"
                    d="M414.46,275.2a.77.77,0,0,1-.77-.72c0-.2-1.23-20.42-3.42-37.24C408,219.78,417.56,216.1,418,216a.78.78,0,0,1,1,.46.76.76,0,0,1-.46,1h0c-.36.14-8.8,3.49-6.69,19.65,2.19,16.88,3.41,37.14,3.43,37.35a.78.78,0,0,1-.72.81Z"
                    fill="#707171"
                />
                <path
                    id="Path_147"
                    d="M230.24,360.22a3.35,3.35,0,0,1-2.24-1.07c-3.1-2.85-3.8-7.3-4-11.47-1.5,1.69-3,3.34-4.56,5l-.48-.46c1.67-1.78,3.37-3.59,5-5.46a120.279,120.279,0,0,1,.76-16.76,34.39,34.39,0,0,1,2.41-10.19,8.11,8.11,0,0,1,2.51-3.38,3.82,3.82,0,0,1,4.22-.26c1.31.88,1.66,2.57,1.82,3.77.92,7.21-1.89,13.83-4.42,18.11a61.848,61.848,0,0,1-6.63,8.95c.12,4.22.69,8.86,3.78,11.7a2.73,2.73,0,0,0,1.85.89c.94,0,1.67-.9,2.26-1.74a45.461,45.461,0,0,0,5.95-11.88,12.32,12.32,0,0,1,1-3.62l.62.22c-.29,1.2-.63,2.38-1,3.55a11.55,11.55,0,0,0,.14,3.66c.13.65.69,2.82,2.34,3.13,1.24.24,2.46-.62,3.83-2.72l4.8-7.19-.42,1.8c-.25,1.09-.67,3.67.92,4.32.9.37,1.85-.16,2.86-.73a5.1,5.1,0,0,1,3.07-1l.34.05-.06.34c-.26,1.5-.13,3.35,1.1,3.62.8.18,1.46-.42,2.21-1.12a5.06,5.06,0,0,1,2.11-1.39l.16.65a4.5,4.5,0,0,0-1.82,1.23c-.82.75-1.66,1.53-2.8,1.28-1.58-.35-1.9-2.24-1.67-4a5.48,5.48,0,0,0-2.32.9c-1.09.62-2.23,1.26-3.44.76s-1.68-1.73-1.55-3.54L246,350.52c-1,1.47-2.5,3.39-4.52,3-1.38-.27-2.46-1.64-2.86-3.66a11.209,11.209,0,0,1-.22-1.73,46.539,46.539,0,0,1-5.32,10.05c-.69,1-1.55,2-2.78,2Zm1.84-44a3.58,3.58,0,0,0-2,.66A7.42,7.42,0,0,0,227.8,320a33.37,33.37,0,0,0-2.36,10,119.363,119.363,0,0,0-.77,15.93,60,60,0,0,0,6.08-8.29c2.47-4.2,5.23-10.67,4.33-17.7-.22-1.7-.71-2.75-1.53-3.3a2.53,2.53,0,0,0-1.47-.37Z"
                    fill="#f4f5f6"
                />
                <path
                    id="Path_148"
                    d="M268.58,154.46l-105.67,3.48a8.85,8.85,0,0,1-9.13-8.42v-.61a8.84,8.84,0,0,1,8.42-9.25l105.7-5a8.83,8.83,0,0,1,9.26,8.83v2.1a8.84,8.84,0,0,1-8.58,8.87Z"
                    fill="#f4f5f6"
                />
                <path
                    id="Path_149"
                    d="M335.35,100h86.86v57a16.76,16.76,0,0,1-16.76,16.76H352.11A16.76,16.76,0,0,1,335.35,157Z"
                    fill="#707171"
                />
                <path
                    id="Path_150"
                    d="M335.35,99.81h86.86V147.9a16.76,16.76,0,0,1-16.76,16.76H352.11a16.76,16.76,0,0,1-16.76-16.76V99.81Z"
                    fill="#fff"
                    opacity="0.3"
                />
                <rect
                    id="Rectangle_89"
                    width="2.99"
                    height="5.08"
                    transform="translate(340.35 102.64)"
                    fill="#5e5e5e"
                />
                <rect
                    id="Rectangle_90"
                    width="2.99"
                    height="5.08"
                    transform="translate(347.08 102.64)"
                    fill="#5e5e5e"
                />
                <rect
                    id="Rectangle_91"
                    width="2.99"
                    height="5.08"
                    transform="translate(353.02 102.64)"
                    fill="#5e5e5e"
                />
                <rect
                    id="Rectangle_92"
                    width="2.99"
                    height="5.08"
                    transform="translate(359.75 102.64)"
                    fill="#5e5e5e"
                />
                <rect
                    id="Rectangle_93"
                    width="2.99"
                    height="5.08"
                    transform="translate(365.69 102.64)"
                    fill="#5e5e5e"
                />
                <rect
                    id="Rectangle_94"
                    width="2.99"
                    height="5.08"
                    transform="translate(372.42 102.64)"
                    fill="#5e5e5e"
                />
                <rect
                    id="Rectangle_95"
                    width="2.99"
                    height="5.08"
                    transform="translate(378.37 102.64)"
                    fill="#5e5e5e"
                />
                <rect
                    id="Rectangle_96"
                    width="2.99"
                    height="5.08"
                    transform="translate(385.1 102.64)"
                    fill="#5e5e5e"
                />
                <rect
                    id="Rectangle_97"
                    width="2.99"
                    height="5.08"
                    transform="translate(391.04 102.64)"
                    fill="#5e5e5e"
                />
                <rect
                    id="Rectangle_98"
                    width="2.99"
                    height="5.08"
                    transform="translate(397.77 102.64)"
                    fill="#5e5e5e"
                />
                <rect
                    id="Rectangle_99"
                    width="2.99"
                    height="5.08"
                    transform="translate(403.71 102.64)"
                    fill="#5e5e5e"
                />
                <rect
                    id="Rectangle_100"
                    width="2.99"
                    height="5.08"
                    transform="translate(410.44 102.64)"
                    fill="#5e5e5e"
                />
                <g id="Group_3" opacity="0.77">
                    <rect
                        id="Rectangle_101"
                        width="9.03"
                        height="9.03"
                        transform="translate(374.01 110.36)"
                        fill="#fff"
                    />
                    <rect
                        id="Rectangle_102"
                        width="9.03"
                        height="9.03"
                        transform="translate(384.98 110.36)"
                        fill="#fff"
                    />
                    <rect
                        id="Rectangle_103"
                        width="9.03"
                        height="9.03"
                        transform="translate(395.84 110.36)"
                        fill="#fff"
                    />
                    <rect
                        id="Rectangle_104"
                        width="9.03"
                        height="9.03"
                        transform="translate(406.61 110.36)"
                        fill="#fff"
                    />
                </g>
                <g id="Group_4" opacity="0.77">
                    <rect
                        id="Rectangle_105"
                        width="9.03"
                        height="9.03"
                        transform="translate(341.42 121.71)"
                        fill="#fff"
                    />
                    <rect
                        id="Rectangle_106"
                        width="9.03"
                        height="9.03"
                        transform="translate(352.28 121.71)"
                        fill="#fff"
                    />
                    <rect
                        id="Rectangle_107"
                        width="9.03"
                        height="9.03"
                        transform="translate(363.05 121.71)"
                        fill="#fff"
                    />
                    <rect
                        id="Rectangle_108"
                        width="9.03"
                        height="9.03"
                        transform="translate(374 121.71)"
                        fill="#fff"
                    />
                    <rect
                        id="Rectangle_109"
                        width="9.03"
                        height="9.03"
                        transform="translate(384.97 121.71)"
                        fill="#fff"
                    />
                    <rect
                        id="Rectangle_110"
                        width="9.03"
                        height="9.03"
                        transform="translate(395.83 121.71)"
                        fill="#fff"
                    />
                    <rect
                        id="Rectangle_111"
                        width="9.03"
                        height="9.03"
                        transform="translate(406.59 121.71)"
                        fill="#fff"
                    />
                </g>
                <rect
                    id="Rectangle_112"
                    width="9.03"
                    height="9.03"
                    transform="translate(341.69 132.52)"
                    fill="#fff"
                    opacity="0.77"
                />
                <rect
                    id="Rectangle_113"
                    width="9.03"
                    height="9.03"
                    transform="translate(352.55 132.52)"
                    fill="#fff"
                    opacity="0.77"
                />
                <rect
                    id="Rectangle_114"
                    width="9.03"
                    height="9.03"
                    transform="translate(363.32 132.52)"
                    fill="#707171"
                />
                <rect
                    id="Rectangle_115"
                    width="9.03"
                    height="9.03"
                    transform="translate(374.26 132.52)"
                    fill="#fff"
                    opacity="0.77"
                />
                <rect
                    id="Rectangle_116"
                    width="9.03"
                    height="9.03"
                    transform="translate(385.23 132.52)"
                    fill="#fff"
                    opacity="0.77"
                />
                <rect
                    id="Rectangle_117"
                    width="9.03"
                    height="9.03"
                    transform="translate(396.09 132.52)"
                    fill="#fff"
                    opacity="0.77"
                />
                <rect
                    id="Rectangle_118"
                    width="9.03"
                    height="9.03"
                    transform="translate(406.86 132.52)"
                    fill="#fff"
                    opacity="0.77"
                />
                <g id="Group_5" opacity="0.77">
                    <rect
                        id="Rectangle_119"
                        width="9.03"
                        height="9.03"
                        transform="translate(341.96 143.2)"
                        fill="#fff"
                    />
                    <rect
                        id="Rectangle_120"
                        width="9.03"
                        height="9.03"
                        transform="translate(352.82 143.2)"
                        fill="#fff"
                    />
                    <rect
                        id="Rectangle_121"
                        width="9.03"
                        height="9.03"
                        transform="translate(363.59 143.2)"
                        fill="#fff"
                    />
                    <rect
                        id="Rectangle_122"
                        width="9.03"
                        height="9.03"
                        transform="translate(374.53 143.2)"
                        fill="#fff"
                    />
                    <rect
                        id="Rectangle_123"
                        width="9.03"
                        height="9.03"
                        transform="translate(385.5 143.2)"
                        fill="#fff"
                    />
                    <rect
                        id="Rectangle_124"
                        width="9.03"
                        height="9.03"
                        transform="translate(396.36 143.2)"
                        fill="#fff"
                    />
                    <rect
                        id="Rectangle_125"
                        width="9.03"
                        height="9.03"
                        transform="translate(407.13 143.2)"
                        fill="#fff"
                    />
                </g>
                <g id="Group_6" opacity="0.77">
                    <rect
                        id="Rectangle_126"
                        width="9.03"
                        height="9.03"
                        transform="translate(341.96 153.85)"
                        fill="#fff"
                    />
                    <rect
                        id="Rectangle_127"
                        width="9.03"
                        height="9.03"
                        transform="translate(352.82 153.85)"
                        fill="#fff"
                    />
                    <rect
                        id="Rectangle_128"
                        width="9.03"
                        height="9.03"
                        transform="translate(363.59 153.85)"
                        fill="#fff"
                    />
                </g>
                <path
                    id="Path_151"
                    d="M346.25,98.59h-3.84a1.61,1.61,0,0,0-1.6,1.61v3.57H342v-2.32a1.61,1.61,0,0,1,1.6-1.61h3.84a1.85,1.85,0,0,1,.42.06,1.61,1.61,0,0,0-1.61-1.31Z"
                    fill="#5e5e5e"
                />
                <path
                    id="Path_152"
                    d="M353.71,98.59h-3.84a1.61,1.61,0,0,0-1.6,1.61v3.57h1.16v-2.32a1.61,1.61,0,0,1,1.6-1.61h3.84a1.85,1.85,0,0,1,.42.06,1.62,1.62,0,0,0-1.58-1.31Z"
                    fill="#5e5e5e"
                />
                <path
                    id="Path_153"
                    d="M359.91,98.59h-3.84a1.61,1.61,0,0,0-1.6,1.61v3.57h1.16v-2.32a1.61,1.61,0,0,1,1.6-1.61h3.84a1.85,1.85,0,0,1,.42.06,1.62,1.62,0,0,0-1.58-1.31Z"
                    fill="#5e5e5e"
                />
                <path
                    id="Path_154"
                    d="M366.3,98.59h-3.84a1.62,1.62,0,0,0-1.61,1.61v3.57H362v-2.32a1.62,1.62,0,0,1,1.61-1.61h3.84a1.93,1.93,0,0,1,.42.06A1.62,1.62,0,0,0,366.3,98.59Z"
                    fill="#5e5e5e"
                />
                <path
                    id="Path_155"
                    d="M372.2,98.59h-3.83a1.62,1.62,0,0,0-1.61,1.61v3.57h1.16v-2.32a1.62,1.62,0,0,1,1.61-1.61h3.84a1.84,1.84,0,0,1,.41.06A1.61,1.61,0,0,0,372.2,98.59Z"
                    fill="#5e5e5e"
                />
                <path
                    id="Path_156"
                    d="M378.59,98.59h-3.84a1.62,1.62,0,0,0-1.61,1.61v3.57h1.17v-2.32a1.61,1.61,0,0,1,1.6-1.61h3.84a1.85,1.85,0,0,1,.42.06,1.62,1.62,0,0,0-1.58-1.31Z"
                    fill="#5e5e5e"
                />
                <path
                    id="Path_157"
                    d="M384.79,98.59H381a1.62,1.62,0,0,0-1.61,1.61v3.57h1.17v-2.32a1.61,1.61,0,0,1,1.6-1.61H386a1.85,1.85,0,0,1,.42.06A1.62,1.62,0,0,0,384.79,98.59Z"
                    fill="#5e5e5e"
                />
                <path
                    id="Path_158"
                    d="M391.72,98.59h-3.84a1.62,1.62,0,0,0-1.61,1.61v3.57h1.16v-2.32A1.62,1.62,0,0,1,389,99.84h3.84a1.84,1.84,0,0,1,.41.06,1.61,1.61,0,0,0-1.53-1.31Z"
                    fill="#5e5e5e"
                />
                <path
                    id="Path_159"
                    d="M397.69,98.54h-3.84a1.61,1.61,0,0,0-1.61,1.6v3.58h1.16v-2.33a1.61,1.61,0,0,1,1.61-1.6h3.84a1.84,1.84,0,0,1,.41,0A1.6,1.6,0,0,0,397.69,98.54Z"
                    fill="#5e5e5e"
                />
                <path
                    id="Path_160"
                    d="M403.89,98.54h-3.84a1.61,1.61,0,0,0-1.61,1.6v3.58h1.16v-2.33a1.61,1.61,0,0,1,1.61-1.6h3.84a1.84,1.84,0,0,1,.41,0A1.6,1.6,0,0,0,403.89,98.54Z"
                    fill="#5e5e5e"
                />
                <path
                    id="Path_161"
                    d="M416.16,98.68h-3.84a1.61,1.61,0,0,0-1.6,1.61v3.57h1.16v-2.32a1.62,1.62,0,0,1,1.61-1.61h3.83a1.42,1.42,0,0,1,.42.06A1.61,1.61,0,0,0,416.16,98.68Z"
                    fill="#5e5e5e"
                />
                <path
                    id="Path_162"
                    d="M410.81,98.54H407a1.61,1.61,0,0,0-1.61,1.6v3.58h1.16v-2.33a1.61,1.61,0,0,1,1.61-1.6H412a2,2,0,0,1,.42,0A1.62,1.62,0,0,0,410.81,98.54Z"
                    fill="#5e5e5e"
                />
                <path
                    id="Path_163"
                    d="M288.56,186.83a.56.56,0,0,1-.55-.54,47.9,47.9,0,0,1,1.61-12.71c2.38-8.64,7.15-13.76,13.79-14.81A10.45,10.45,0,0,1,312,161c3.4,2.8,5.92,7.9,7.5,15.17a66.064,66.064,0,0,1,1.36,10,.56.56,0,0,1-1.11,0c0-.18-.78-18.09-8.45-24.4a9.39,9.39,0,0,0-7.71-2c-6.27,1-10.61,5.7-12.9,14a47.429,47.429,0,0,0-1.56,12.39.55.55,0,0,1-.54.57Z"
                    fill="#5e5e5e"
                />
                <path
                    id="Path_164"
                    d="M259.09,263.59l12-82.91,45.17-.76,13,88.73L263,268.2a4,4,0,0,1-3.91-4.61Z"
                    fill="#707171"
                />
                <path
                    id="Path_165"
                    d="M325.05,180.68l-8.77-.76,11.37,88.71,10-.36a2.37,2.37,0,0,0,2.25-2.78Z"
                    fill="#5e5e5e"
                />
                <path
                    id="Path_166"
                    d="M278.86,186.64a.55.55,0,0,1-.55-.54,47.649,47.649,0,0,1,1.6-12.71c2.39-8.64,7.15-13.76,13.8-14.81a10.43,10.43,0,0,1,8.58,2.28c3.4,2.79,5.92,7.89,7.5,15.16a65.25,65.25,0,0,1,1.35,10,.56.56,0,0,1-.53.57.55.55,0,0,1-.57-.53c0-.18-.78-18.09-8.46-24.4a9.38,9.38,0,0,0-7.7-2c-6.27,1-10.61,5.7-12.9,14a47.69,47.69,0,0,0-1.57,12.39.55.55,0,0,1-.53.57Z"
                    fill="#5e5e5e"
                />
                <path
                    id="Path_167"
                    d="M404,380.17s1.26,2,1.57,7.5a.89.89,0,0,1-.89.95h-9.8a.57.57,0,0,1-.54-.75,15.62,15.62,0,0,1,6.18-8.21Z"
                    fill="#5e5e5e"
                />
                <path
                    id="Path_168"
                    d="M404.47,376.43l-.55,4.64a.89.89,0,0,1-.77.77,8,8,0,0,1-2.07,0,1.43,1.43,0,0,1-1.18-1.42v-4.49Z"
                    fill="#e3e2e3"
                />
                <path
                    id="Path_169"
                    d="M379.82,379.66a13.68,13.68,0,0,1,1.83,6.91,1.73,1.73,0,0,1-1.74,1.69H364.7a.73.73,0,0,1-.42-1.32c2.88-2,8.54-5.8,12.63-7.28Z"
                    fill="#5e5e5e"
                />
                <path
                    id="Path_170"
                    d="M381.38,374.64l-1.18,5.74a1.19,1.19,0,0,1-1.4.93,4.269,4.269,0,0,1-1.34-.56,1.35,1.35,0,0,1-.6-1.38l1.22-6.95Z"
                    fill="#e3e2e3"
                />
                <path
                    id="Path_171"
                    d="M402.3,294.9s4.11,3.62,4.88,8S405,378.05,405,378.05s-4.11.68-6.22-.6c0,0-2.52-46-4.43-56.58,0,0-10,47.61-12.78,56.21,0,0-4,.48-5.23-.27,0,0,2.62-61.71,5.91-72.68Z"
                    fill="#5e5e5e"
                />
                <path
                    id="Path_172"
                    d="M394.46,319.37a.45.45,0,0,0,.43-.33l1.7-6.53,3.62-2.32a.43.43,0,0,0,.14-.6.44.44,0,0,0-.61-.14L396,311.87a.4.4,0,0,0-.18.26L394,318.82a.43.43,0,0,0,.31.53Z"
                    fill="#fff"
                    opacity="0.2"
                />
                <path
                    id="Path_173"
                    d="M361.76,254c17.3-1.43,21.82,2.14,21.82,2.14l-8.31,13.12s-17-.33-29.17-9.72a29.779,29.779,0,0,0,4.88-7.27A22.86,22.86,0,0,0,361.76,254Z"
                    fill="#707171"
                />
                <path
                    id="Path_174"
                    d="M334.69,228.6a.53.53,0,0,1,1-.2l2.2,4.71a4.86,4.86,0,0,1,.39,2.91l-.77,2.74a4.77,4.77,0,0,0,1,3.77,33.05,33.05,0,0,0,12.5,9.72,29.78,29.78,0,0,1-4.88,7.27,29.77,29.77,0,0,1-10.38-14.41,2.31,2.31,0,0,1-1.63-2.3Z"
                    fill="#e3e2e3"
                />
                <path
                    id="Path_175"
                    d="M356.07,261.59c6.09-6.56,17.68-9.52,24.4-10.74a6.15,6.15,0,0,1,6.88,3.9c3,8.12,11.24,29.25,12.83,32.88,1.31,3,2.67,5.89,3.54,7.71a2.18,2.18,0,0,1-1,2.91c-5.65,2.72-15.81,5.8-19.93,7a1.41,1.41,0,0,1-1.81-1.36,4.44,4.44,0,0,0-.63-2.27l-15.61-26c-9.74,10.78-18.26,11.77-24.8,9.38a48.908,48.908,0,0,0,2.16-10.22C351.77,273.15,356.07,261.59,356.07,261.59Z"
                    fill="#707171"
                />
                <path
                    id="Path_176"
                    d="M326.13,273l1.74-2.33c5.65,3.88,10.37,4.8,14.23,4.14A48.907,48.907,0,0,1,339.94,285C331.32,281.92,326.13,273,326.13,273Z"
                    fill="#e3e2e3"
                />
                <path
                    id="Path_177"
                    d="M327.36,273l-9.83.38-9-5.05c1.3-.81,8.64.85,8.64.85s7.68-1.16,9.76-1.16c1.73,0,1.48,2.48,1.35,3.32l-.05.27Z"
                    fill="#e3e2e3"
                />
                <path
                    id="Path_178"
                    d="M371.23,238.8l5.19,12.65s-6,4.13-10.75,3.74l-2.59-11.38Z"
                    fill="#e3e2e3"
                />
                <path
                    id="Path_179"
                    d="M362.47,230.18c-.11.16-3.75,13.29-3.75,13.29l7.54,1.91,5.29-11.93S364.46,227.36,362.47,230.18Z"
                    fill="#e3e2e3"
                />
                <path
                    id="Path_180"
                    d="M368.71,235.27l-2.29,6.49a1.08,1.08,0,0,1-.12.24c-.31.44-1.49,1.88-2.9.58s-2.45-2.24-3.54-2.43a1,1,0,0,0-1.09.69,42.606,42.606,0,0,0-1.27,6.33,1,1,0,0,0,.78,1,31.21,31.21,0,0,0,7.2.6,1,1,0,0,0,.81-.56c.72-1.57,2.92-6.33,3.74-7.56s2.65-1.13,3.44-1.06a.93.93,0,0,0,.71-.23,2.49,2.49,0,0,0,.15-4,1,1,0,0,1,0-1.39,2.5,2.5,0,0,0,.32-3.71,3.53,3.53,0,0,0-4.21-1,1,1,0,0,1-1.16-.43.75.75,0,0,0-.79-.36,1,1,0,0,1-1-1,2.86,2.86,0,0,0-2.22-3c-3.29-1-5.34,2.15-5.54,4.53s3.42,3.28,5.56,3.12c1.63-.12,2.83,1.48,3.32,2.26A1,1,0,0,1,368.71,235.27Z"
                    fill="#707171"
                />
                <path
                    id="Path_181"
                    d="M369.17,243s.53-4.12,2.25-4.32,3.38,1.21,1.67,3.52C371,245.12,369.17,243,369.17,243Z"
                    fill="#e3e2e3"
                />
                <path
                    id="Path_182"
                    d="M138.47,304.07l16.36-3a.42.42,0,0,1,.49.34L161,326.2a.44.44,0,0,1-.07.35.46.46,0,0,1-.31.2L138.5,330a.252.252,0,0,1-.25-.1c-.1-.1,0-.21,0-.34V304.51C138.21,304.29,138.25,304.11,138.47,304.07Z"
                    fill="#5e5e5e"
                />
                <path
                    id="Path_183"
                    d="M150.51,303.08a.59.59,0,0,0,.59.59.6.6,0,0,0,.59-.59.59.59,0,0,0-.59-.59.58.58,0,0,0-.59.59Z"
                    fill="url(#linear-gradient-2)"
                />
                <path
                    id="Path_184"
                    d="M142.41,304.81a.58.58,0,0,0,.59.59.59.59,0,1,0,0-1.17.58.58,0,0,0-.59.58Z"
                    fill="url(#linear-gradient-3)"
                />
                <path
                    id="Path_185"
                    d="M143,304.9a.08.08,0,0,1-.08-.09c0-.43,0-10.55,2.72-10.84a1.83,1.83,0,0,1,1.39.58c2.2,2,4.15,8.32,4.15,8.53a.09.09,0,0,1-.08.08.07.07,0,0,1-.08-.08,38,38,0,0,0-1.75-4.69c-1.32-2.9-2.57-4.37-3.61-4.25s-1.82,2-2.25,5.41a47.936,47.936,0,0,0-.33,5.26A.09.09,0,0,1,143,304.9Z"
                    fill="#fff"
                />
                <path
                    id="Path_186"
                    d="M120.62,264s9,20.52,21.77,23.69c2.47.48,5.38.27,6.68,1.08,1.47.92-.32,3.39-1,5.27s-1,1-1.32.4,1-2.49-.73-3.24-1.22.4-.4,1.39a3.8,3.8,0,0,1,.75,2.58,20.11,20.11,0,0,1-2.64-1.17,4.43,4.43,0,0,1-1.82-2.27c-2.53-.29-18.37-2.78-25.1-19.44C116.77,272.26,116.12,255.72,120.62,264Z"
                    fill="#e3e2e3"
                />
                <path
                    id="Path_187"
                    d="M83.24,312.44h0a.08.08,0,0,1-.07-.09c0-.28,1.1-6.87,3.27-8.1a1.38,1.38,0,0,1,1.25-.09c1.24.49,1.89,2,1.89,4.35a18.441,18.441,0,0,1-.38,3.41.082.082,0,1,1-.16-.04c0-.06,1.43-6.43-1.41-7.57a1.21,1.21,0,0,0-1.11.09c-2.1,1.19-3.18,7.9-3.19,8a.08.08,0,0,1-.09.04Z"
                    fill="#707171"
                />
                <path
                    id="Path_188"
                    d="M80.41,311.89l10.61-.98,2.37,1.31-2.03,2.53-9.69-.98Z"
                    fill="#5e5e5e"
                />
                <path
                    id="Path_189"
                    d="M81.94,376.52l-.17,4.06a1.68,1.68,0,0,1-1.35,1.57,25.418,25.418,0,0,1-5.38.5,1.42,1.42,0,0,1-1.33-1.75A14.68,14.68,0,0,1,76,375.44l4.47-.35a1.37,1.37,0,0,1,1.47,1.43Z"
                    fill="#5e5e5e"
                />
                <path
                    id="Path_190"
                    d="M113.22,326.67l1.1,4.38s-.53,25.74-.19,36a8.12,8.12,0,0,0,1.54,4.45l5,6.78L109,372.58l-.33-1.35,1.63-2.65-4.62-28.43a8.72,8.72,0,0,1,.62-4.94l2-4.51Z"
                    fill="#e3e2e3"
                />
                <path
                    id="Path_191"
                    d="M121.43,379.26l-3.56.28a2.17,2.17,0,0,1-1.76-.7c-1.22-1.36-3.74-4-4.84-4-1.49,0-.89,5.26-.89,5.26l-.83.06L108,373.73a3.89,3.89,0,0,1,1.71-4.2h0a5.5,5.5,0,0,0,2.46,3.44c1.92,1,7.85,4.48,7.85,4.48a5.09,5.09,0,0,1,1.45-.07.94.94,0,0,1-.04,1.88Z"
                    fill="#5e5e5e"
                />
                <path
                    id="Path_192"
                    d="M98.12,330s-12.6,29.18-14.45,35.76a61.111,61.111,0,0,0-2.24,12.5c0,.79-.92,1.62-3.62,1.83a2.61,2.61,0,0,1-2.51-.91,1.59,1.59,0,0,1-.18-1.52c.72-1.8,3-7.54,3.81-10.13,1-3.08,2.49-16.85,3.54-21.68s2.25-10.07,8-15.26C90.51,330.56,94.42,326.09,98.12,330Z"
                    fill="#e3e2e3"
                />
                <path
                    id="Path_193"
                    d="M103.87,283.31c2.55-16.44-7.82-24.83-7.82-24.83,3.1-3.47,7.79-6.1,11.38-7.77a9.13,9.13,0,0,1,9.78,1.37c3,2.58,6.22,6.22,5.78,9.63-.45,3.61-5.78,21.86-5.78,21.86a14.78,14.78,0,0,1-13.34-.26Z"
                    fill="#707171"
                />
                <path
                    id="Path_194"
                    d="M96,292c1.19-4.8,7.83-8.66,7.83-8.66a14.78,14.78,0,0,0,13.34.26,26.76,26.76,0,0,1,1.23,10.23c-.58,5-4.12,37.25-4.12,37.25C105,333.71,90.07,331,90.07,331,95.28,319.78,94.85,296.76,96,292Z"
                    fill="#5e5e5e"
                />
                <path
                    id="Path_195"
                    d="M108,240.55l-2,14.16c-.2,1.25.9,1,2.12.63,2-.54,4.56-1.53,5.31-3.18a1.42,1.42,0,0,0,.12-.54l.58-9.75a1.76,1.76,0,0,0-1.16-1.75l-2.66-.94a1.74,1.74,0,0,0-2.31,1.37Z"
                    fill="#e3e2e3"
                />
                <path
                    id="Path_196"
                    d="M75.28,337.27l-3.64-2.09,8.77-23.29,2.1,1.42Z"
                    fill="#5e5e5e"
                />
                <path
                    id="Path_197"
                    d="M85.16,315a.08.08,0,0,1-.08-.07c0-.07-.51-6.91,1.51-9.22a1.962,1.962,0,0,1,2.93-.18c.91.84,1.57,2.6,2,5.21a31.31,31.31,0,0,1,.32,3.67.08.08,0,0,1-.08.08.078.078,0,0,1-.08-.08c0-.08-.13-6.8-2.23-8.76a1.81,1.81,0,0,0-1.36-.53,1.87,1.87,0,0,0-1.33.7c-2,2.22-1.48,9-1.47,9.1a.08.08,0,0,1-.08.08Z"
                    fill="#707171"
                />
                <path
                    id="Path_198"
                    d="M101.13,264.89c-.52.69-8.41,10.07-10.67,20.54S89,302.9,89,302.9s2.3,1.71,1.47,2.92-.6,2-1.66,2.28-2.36.56-2.2-1.52-2.33-2.5-1.91-3.76-2.41-35,11.2-44.21l.19-.13C101.8,260.56,102,263.78,101.13,264.89Z"
                    fill="#e3e2e3"
                />
                <path
                    id="Path_199"
                    d="M93.39,312.3l-10.88,1.01-7.23,23.96,15.63.41Z"
                    fill="#707171"
                />
                <path
                    id="Path_200"
                    d="M75.07,336.9h0a.14.14,0,0,0,.11-.17l-.7-2.67L80,317a.16.16,0,0,0-.1-.19.15.15,0,0,0-.18.1L74.22,334a.11.11,0,0,0,0,.08l.71,2.7a.13.13,0,0,0,.14.12Z"
                    fill="#e3e2e3"
                />
                <path
                    id="Path_201"
                    d="M71.91,335.23H72l1.88-.91a.13.13,0,0,0,.07-.19.15.15,0,0,0-.19-.07l-1.88.9a.14.14,0,0,0-.07.2.13.13,0,0,0,.1.07Z"
                    fill="#e3e2e3"
                />
                <path
                    id="Path_202"
                    d="M110.2,229.32s4.31,1.17,7.88,6.68,1.86,8.52-.69,9.24a12.26,12.26,0,0,1-11.07-2.06C101.7,239.93,104.63,228,110.2,229.32Z"
                    fill="#e3e2e3"
                />
                <path
                    id="Path_203"
                    d="M108.66,239.67s-4.24-8.21,3.39-9.34l2,1s2.16-5.16-2.75-6.82a9.87,9.87,0,0,0-12.62,7.76c-1.12,6.46.56,10.31,4.22,12.84s4.55-1,4.55-1-2.15-2.54-1.63-4.12,2.43,0,2.43,0Z"
                    fill="#707171"
                />
                <path
                    id="Path_204"
                    d="M107.94,240.74s-2.16-4.23-4-2.18,2.24,3.87,3.81,4.43Z"
                    fill="#e3e2e3"
                />
                <path
                    id="Path_205"
                    d="M102.31,231s-14.81-4.56-17.25,10.89-4.12,20.4-7.79,17.74c0,0,.6,5.77,7.65,3.19C103.22,256.16,92.08,234.72,102.31,231Z"
                    fill="#707171"
                />
                <rect
                    id="Rectangle_129"
                    width="66.14"
                    height="30.84"
                    transform="translate(395.208 187.391) rotate(-68.9)"
                    fill="#707171"
                />
                <path
                    id="Path_206"
                    d="M423.76,131.42l16.46,6.35a2.77,2.77,0,0,0,1.74,3.47l.16.06L422.9,191.11l-.16-.07a2.74,2.74,0,0,0-3.6,1.39h0L403,186.27c.88-2.27-.55-3.07-2-3.63h-.07l19.22-49.81h0a2.71,2.71,0,0,0,3.61-1.41Z"
                    fill="#fff"
                    opacity="0.5"
                />
                <circle
                    id="Ellipse_14"
                    cx="9.7"
                    cy="9.7"
                    r="9.7"
                    transform="translate(409.484 168.788) rotate(-74.61)"
                    fill="#707171"
                />
                <path
                    id="Path_207"
                    d="M427.17,165.29l-.95-.37a8.61,8.61,0,0,1-1.62,2.46.58.58,0,0,1-.77.05l-.8-.59A.59.59,0,0,1,423,166a7,7,0,0,0,1.48-2.28c.37-1,.33-1.8-.34-2.06s-1.18.18-2,1.38c-1.26,1.7-2.62,2.75-4.29,2.1-1.52-.58-2.25-2.14-1.82-4.18l-.95-.37a.6.6,0,0,1-.35-.77l.22-.56a.59.59,0,0,1,.77-.34l.86.33a7.579,7.579,0,0,1,1.21-2,.58.58,0,0,1,.8-.09l.74.54a.6.6,0,0,1,.09.88,6.39,6.39,0,0,0-1.18,1.94c-.44,1.14-.1,1.65.33,1.81s1.14-.34,2.17-1.65c1.45-1.87,2.67-2.41,4.25-1.8s2.34,2.14,1.86,4.35l1.07.41a.59.59,0,0,1,.34.77l-.21.56a.61.61,0,0,1-.88.32Z"
                    fill="#fff"
                />
                <path
                    id="Path_208"
                    d="M403,188.7h0l-5.4-2.08a.15.15,0,0,1-.08-.08.17.17,0,0,1,0-.12l5.88-15.24a.16.16,0,0,1,.29.11l-5.83,15.1,5.26,2a.16.16,0,0,1,.08.2.15.15,0,0,1-.2.11Z"
                    fill="#f4f5f6"
                />
                <path
                    id="Path_209"
                    d="M439.67,153.15h-.06a.15.15,0,0,1-.08-.2l5.75-14.9-5.63-2.17a.16.16,0,0,1,.11-.29l5.77,2.23a.11.11,0,0,1,.08.08.17.17,0,0,1,0,.12l-5.81,15A.15.15,0,0,1,439.67,153.15Z"
                    fill="#f4f5f6"
                />
                <rect
                    id="Rectangle_130"
                    width="66.14"
                    height="31.04"
                    transform="translate(393.734 184.428) rotate(-68.9)"
                    fill="#5e5e5e"
                />
                <path
                    id="Path_210"
                    d="M422.34,128.48l16.47,6.35a2.83,2.83,0,0,0,1.8,3.49l.23.08-19.21,49.81-.23-.09a2.77,2.77,0,0,0-3.63,1.38h-.07l-16.09-6.21c.88-2.27-.57-3.08-2-3.65h-.09l19.22-49.81.08.05A2.72,2.72,0,0,0,422.34,128.48Z"
                    fill="#fff"
                    opacity="0.5"
                />
                <circle
                    id="Ellipse_15"
                    cx="9.7"
                    cy="9.7"
                    r="9.7"
                    transform="translate(406.942 154.868) rotate(-27.31)"
                    fill="#707171"
                />
                <path
                    id="Path_211"
                    d="M425.77,162.33l-1-.36a8.341,8.341,0,0,1-1.62,2.46.6.6,0,0,1-.77,0l-.8-.59a.6.6,0,0,1-.07-.9,7,7,0,0,0,1.48-2.27c.37-1,.32-1.81-.35-2.06s-1.17.18-2.05,1.38c-1.25,1.7-2.61,2.75-4.29,2.1-1.51-.58-2.24-2.14-1.81-4.18l-1-.37a.59.59,0,0,1-.34-.77l.22-.56a.6.6,0,0,1,.77-.35l.86.34a7.85,7.85,0,0,1,1.2-2,.6.6,0,0,1,.81-.08l.73.54a.6.6,0,0,1,.1.88,6.191,6.191,0,0,0-1.19,1.94c-.44,1.14-.09,1.65.33,1.81s1.15-.34,2.17-1.65c1.46-1.87,2.68-2.41,4.26-1.8s2.33,2.13,1.85,4.34l1.08.42a.6.6,0,0,1,.34.77l-.22.56a.59.59,0,0,1-.69.4Z"
                    fill="#fff"
                />
                <path
                    id="Path_212"
                    d="M401.8,185.62h-.05l-5.58-2.15a.24.24,0,0,1-.09-.08.17.17,0,0,1,0-.12l5.8-15a.15.15,0,0,1,.2-.08.14.14,0,0,1,.09.19l-5.75,14.91,5.44,2.1a.15.15,0,0,1-.06.29Z"
                    fill="#f4f5f6"
                />
                <path
                    id="Path_213"
                    d="M438.2,150.19h-.05a.15.15,0,0,1-.09-.19l5.75-14.91-5.44-2.1a.16.16,0,0,1-.09-.2.14.14,0,0,1,.2-.08l5.58,2.15a.14.14,0,0,1,.09.08.24.24,0,0,1,0,.12l-5.81,15A.14.14,0,0,1,438.2,150.19Z"
                    fill="#f4f5f6"
                />
                <rect
                    id="Rectangle_131"
                    width="66.14"
                    height="31.04"
                    transform="translate(386.181 172.675) rotate(-51.35)"
                    fill="#707171"
                />
                <path
                    id="Path_214"
                    d="M430.35,128l13.78,11a2.82,2.82,0,0,0,.65,3.87l.18.15-33.35,41.69-.17-.14a2.74,2.74,0,0,0-3.87.22h-.06L394,174c1.52-1.9.36-3.11-.86-4.09,0,0-.1-.05-.11,0l33.35-41.7.08.07a2.75,2.75,0,0,0,3.89-.28Z"
                    fill="#fff"
                    opacity="0.5"
                />
                <circle
                    id="Ellipse_16"
                    cx="9.7"
                    cy="9.7"
                    r="9.7"
                    transform="translate(408.247 147.826) rotate(-5.8)"
                    fill="#707171"
                />
                <path
                    id="Path_215"
                    d="M423.39,161.31l-.8-.64a8.259,8.259,0,0,1-2.29,1.86.6.6,0,0,1-.75-.18l-.58-.8a.59.59,0,0,1,.2-.88,6.94,6.94,0,0,0,2.1-1.73c.64-.8.85-1.62.29-2.07s-1.18-.18-2.37.7c-1.71,1.25-3.33,1.84-4.73.71s-1.5-2.71-.47-4.53l-.8-.64a.59.59,0,0,1-.09-.84l.37-.47a.59.59,0,0,1,.84-.09l.72.57a7.411,7.411,0,0,1,1.75-1.52.58.58,0,0,1,.79.15l.54.74a.59.59,0,0,1-.18.87,6.34,6.34,0,0,0-1.71,1.49c-.76,1-.59,1.55-.23,1.83s1.19,0,2.57-.92c1.95-1.35,3.27-1.49,4.59-.44s1.59,2.75.47,4.71l.89.72a.6.6,0,0,1,.1.84l-.38.47a.59.59,0,0,1-.84.09Z"
                    fill="#fff"
                />
                <path
                    id="Path_216"
                    d="M393.43,176.41a.16.16,0,0,1-.09,0l-4.68-3.74a.12.12,0,0,1-.05-.1.11.11,0,0,1,0-.11l10.2-12.76a.16.16,0,0,1,.21,0,.15.15,0,0,1,0,.21L389,172.5l4.56,3.64a.15.15,0,0,1-.1.27Z"
                    fill="#f4f5f6"
                />
                <path
                    id="Path_217"
                    d="M438.94,153.45a.12.12,0,0,1-.09,0,.15.15,0,0,1,0-.21l10-12.48-4.55-3.64a.16.16,0,0,1,0-.22.17.17,0,0,1,.22,0l4.67,3.74a.15.15,0,0,1,0,.21l-10.08,12.6a.14.14,0,0,1-.17,0Z"
                    fill="#f4f5f6"
                />
                <path
                    id="Path_218"
                    d="M408.93,170.35a2.74,2.74,0,1,1-2.019-.587,2.75,2.75,0,0,1,2.019.587Z"
                    fill="#fff"
                />
                <path
                    id="Path_219"
                    d="M434.75,138.06a2.74,2.74,0,1,1-2.019-.587A2.75,2.75,0,0,1,434.75,138.06Z"
                    fill="#fff"
                />
                <circle
                    id="Ellipse_17"
                    cx="8.27"
                    cy="8.27"
                    r="8.27"
                    transform="translate(406.878 184.81) rotate(-62.4)"
                    fill="#5e5e5e"
                />
                <circle
                    id="Ellipse_18"
                    cx="8.007"
                    cy="8.007"
                    r="8.007"
                    transform="translate(408.181 181.165) rotate(-36.069)"
                    fill="#707171"
                />
                <path
                    id="Path_220"
                    d="M410.86,180.07a.24.24,0,0,1-.12,0,.26.26,0,0,1-.21-.31,7.61,7.61,0,0,1,5.49-5.85,7.91,7.91,0,0,1,1.36-.24.27.27,0,0,1,.29.24.26.26,0,0,1-.24.29,7.683,7.683,0,0,0-1.27.23,7.08,7.08,0,0,0-5.11,5.44A.27.27,0,0,1,410.86,180.07Z"
                    fill="#e3e2e3"
                />
                <circle
                    id="Ellipse_19"
                    cx="6.76"
                    cy="6.76"
                    r="6.76"
                    transform="translate(411.47 174.65)"
                    fill="#e3e2e3"
                />
                <path
                    id="Path_221"
                    d="M418.91,186.44l-.19-.71a6,6,0,0,1-2.11.11.42.42,0,0,1-.36-.42v-.72a.43.43,0,0,1,.49-.42,4.82,4.82,0,0,0,1.94-.11c.72-.19,1.18-.58,1.05-1.08s-.61-.6-1.67-.59c-1.52,0-2.7-.35-3-1.59a2.41,2.41,0,0,1,1.65-2.83l-.19-.71a.41.41,0,0,1,.3-.52l.42-.12a.43.43,0,0,1,.52.31l.17.63a5.84,5.84,0,0,1,1.66-.14.42.42,0,0,1,.39.43v.65a.43.43,0,0,1-.47.43,4.46,4.46,0,0,0-1.62.14c-.85.23-1,.64-.91,1s.69.51,1.88.55c1.7.05,2.53.52,2.84,1.7a2.5,2.5,0,0,1-1.72,2.92l.22.79a.44.44,0,0,1-.31.53l-.41.11a.43.43,0,0,1-.57-.34Z"
                    fill="#707171"
                />
                <circle
                    id="Ellipse_20"
                    cx="14.39"
                    cy="14.39"
                    r="14.39"
                    transform="matrix(0.964, -0.266, 0.266, 0.964, 420.478, 155.585)"
                    fill="#5e5e5e"
                />
                <circle
                    id="Ellipse_21"
                    cx="13.972"
                    cy="13.972"
                    r="13.972"
                    transform="translate(423.977 155.318) rotate(-9.744)"
                    fill="#707171"
                />
                <path
                    id="Path_222"
                    d="M425.65,163.35a.24.24,0,0,1-.12,0,.26.26,0,0,1-.21-.31,13.07,13.07,0,0,1,9.41-10,13.5,13.5,0,0,1,2.34-.41.27.27,0,1,1,.05.53,13.257,13.257,0,0,0-2.25.4,12.51,12.51,0,0,0-9,9.62A.27.27,0,0,1,425.65,163.35Z"
                    fill="#e3e2e3"
                />
                <circle
                    id="Ellipse_22"
                    cx="11.736"
                    cy="11.736"
                    r="11.736"
                    transform="translate(423.183 175.278) rotate(-72.474)"
                    fill="#e3e2e3"
                />
                <path
                    id="Path_223"
                    d="M439.72,174.63l-.34-1.23a10.62,10.62,0,0,1-3.67.18.73.73,0,0,1-.63-.73v-1.24a.75.75,0,0,1,.85-.74,8.64,8.64,0,0,0,3.39-.19c1.24-.34,2.05-1,1.82-1.87s-1.05-1.05-2.9-1.05c-2.65,0-4.71-.6-5.3-2.76-.53-2,.5-3.84,2.87-4.92l-.33-1.23a.75.75,0,0,1,.52-.92l.73-.2a.76.76,0,0,1,.92.53l.3,1.11a9.21,9.21,0,0,1,2.88-.25.73.73,0,0,1,.68.74V161a.74.74,0,0,1-.81.74,7.63,7.63,0,0,0-2.83.24c-1.48.4-1.73,1.12-1.58,1.67s1.19.9,3.26,1c3,.08,4.41.9,5,3,.51,1.87-.42,3.93-3,5.08l.37,1.38a.74.74,0,0,1-.52.92l-.73.2a.74.74,0,0,1-.95-.6Z"
                    fill="#707171"
                />
                <circle
                    id="Ellipse_23"
                    cx="14.81"
                    cy="14.81"
                    r="14.81"
                    transform="matrix(0.022, -1, 1, 0.022, 441.005, 208.573)"
                    fill="#5e5e5e"
                />
                <circle
                    id="Ellipse_24"
                    cx="14.32"
                    cy="14.32"
                    r="14.32"
                    transform="matrix(0.999, -0.047, 0.047, 0.999, 441.191, 180.447)"
                    fill="#707171"
                />
                <path
                    id="Path_224"
                    d="M443.28,191.7h-.12a.27.27,0,0,1-.21-.32,13.42,13.42,0,0,1,9.68-10.31,14.16,14.16,0,0,1,2.41-.43.27.27,0,1,1,0,.54,12.31,12.31,0,0,0-2.31.41,12.89,12.89,0,0,0-9.3,9.9A.25.25,0,0,1,443.28,191.7Z"
                    fill="#e3e2e3"
                />
                <circle
                    id="Ellipse_25"
                    cx="12.12"
                    cy="12.12"
                    r="12.12"
                    transform="translate(441.104 186.889) rotate(-19.33)"
                    fill="#e3e2e3"
                />
                <path
                    id="Path_225"
                    d="M457.76,203.32l-.35-1.27a10.79,10.79,0,0,1-3.78.19.76.76,0,0,1-.64-.76v-1.27a.77.77,0,0,1,.88-.76,8.92,8.92,0,0,0,3.48-.2c1.28-.35,2.11-1,1.87-1.93s-1.08-1.07-3-1.07c-2.72,0-4.84-.62-5.44-2.85-.55-2,.51-4,2.95-5.05l-.34-1.27a.78.78,0,0,1,.54-1l.74-.2a.78.78,0,0,1,.95.54l.31,1.14a10.131,10.131,0,0,1,3-.26.77.77,0,0,1,.71.77v1.18a.77.77,0,0,1-.84.76,7.94,7.94,0,0,0-2.92.25c-1.51.41-1.77,1.16-1.62,1.72s1.23.92,3.36,1c3,.08,4.53.94,5.1,3,.52,1.92-.44,4-3.09,5.23l.39,1.42a.78.78,0,0,1-.54.95l-.75.2a.77.77,0,0,1-.97-.46Z"
                    fill="#707171"
                />
                <circle
                    id="Ellipse_26"
                    cx="10.85"
                    cy="10.85"
                    r="10.85"
                    transform="translate(458.253 180.4) rotate(-89.55)"
                    fill="#5e5e5e"
                />
                <circle
                    id="Ellipse_27"
                    cx="10.49"
                    cy="10.49"
                    r="10.49"
                    transform="translate(457.818 179.143) rotate(-84.86)"
                    fill="#707171"
                />
                <path
                    id="Path_226"
                    d="M459.8,168h-.13a.27.27,0,0,1-.2-.32,9.9,9.9,0,0,1,7.14-7.61,10.692,10.692,0,0,1,1.78-.31.28.28,0,0,1,.29.24.27.27,0,0,1-.25.29,9.709,9.709,0,0,0-1.68.3,9.39,9.39,0,0,0-6.76,7.2.29.29,0,0,1-.19.21Z"
                    fill="#e3e2e3"
                />
                <circle
                    id="Ellipse_28"
                    cx="8.88"
                    cy="8.88"
                    r="8.88"
                    transform="matrix(0.086, -0.996, 0.996, 0.086, 459.904, 177.894)"
                    fill="#e3e2e3"
                />
                <path
                    id="Path_227"
                    d="M470.38,176.41l-.25-.93a8.07,8.07,0,0,1-2.77.14.56.56,0,0,1-.47-.56v-.93a.57.57,0,0,1,.64-.56,6.65,6.65,0,0,0,2.56-.14c.93-.26,1.54-.76,1.37-1.41s-.79-.79-2.19-.79c-2,0-3.55-.45-4-2.09-.4-1.47.37-2.89,2.16-3.7l-.25-.93a.57.57,0,0,1,.4-.69l.54-.15a.56.56,0,0,1,.69.39l.23.84a7.2,7.2,0,0,1,2.18-.19.56.56,0,0,1,.51.56v.86a.55.55,0,0,1-.62.56,5.88,5.88,0,0,0-2.13.19c-1.11.3-1.3.84-1.19,1.26s.9.67,2.46.73c2.23,0,3.32.68,3.74,2.22s-.32,3-2.26,3.83l.28,1a.58.58,0,0,1-.4.7l-.54.14a.57.57,0,0,1-.69-.35Z"
                    fill="#707171"
                />
            </g>
        </SVGIcon>
    );
}
