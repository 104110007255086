import React from 'react';
import { Page, Text, View, Image, Font } from '@react-pdf/renderer';
import { abbreviateNumber } from 'Utils/stringUtils';
import { IThumbsUp, IPost, IShare } from './svgDownloadIcons/svgDownloadIcons';
import generateImage from 'Utils/generateImage';
import ColorBox, { getInitialsListingSvg } from './downloadUtils/colorBox';
import config from 'config';
import { styles } from './style';
import { instareportFontFamily } from '../components/constant';

Font.register({ ...instareportFontFamily });

const SocialDownload = ({ facebook, twitter, details, user, score }) => {
    return (
        <Page>
            <View fixed style={styles.FixedTopHeight}>
                <Text />
            </View>
            <View style={styles.section}>
                <View style={styles.HeadingView}>
                    <View style={styles.HeadingLogo}>
                        {score?.social ? <ColorBox score={score?.social} /> : null}
                        <Text style={styles.heading}>Social</Text>
                        <Text style={styles.HeadingCaption}>
                            Are you engaging with your customers?
                        </Text>
                    </View>
                </View>
                <View style={styles.RSubSection}>
                    <View>
                        <Text style={styles.RSubSectionText}>
                            In this day and age, we’re all slowly becoming social media addicts. An
                            average of 2 hours and 22 minutes per day, per person are spent browsing
                            through social media.
                        </Text>
                        <Text style={styles.RSubSectionText}>
                            We dissected your Facebook business profile to see how up to date and
                            relevant {details?.name} social media pages are, compared to your
                            competitors.
                        </Text>
                    </View>
                </View>
                {Object.keys(facebook || {}).length !== 0 ? (
                    <View>
                        <View>
                            <View style={styles.HeaderDiv}>
                                <Text>Facebook</Text>
                                <Text>Your Business’s Information on Facebook</Text>
                            </View>
                            <View style={styles.SMLsection}>
                                <View style={styles.SMLBox}>
                                    <View style={styles.SMLSecionRow}>
                                        <View style={styles.MP3}>
                                            <Text style={styles.GreyText}>Likes</Text>
                                            <Text style={styles.SMLTextCenter}>
                                                {abbreviateNumber(facebook?.likes?.totalLikes)}
                                            </Text>
                                        </View>
                                        <View style={styles.SMLBoxRight}>
                                            <View style={styles.SMLBoxIcon}>
                                                <IThumbsUp color={'white'} />
                                            </View>
                                        </View>
                                    </View>
                                    <View style={styles.SMLSecionRowBottom}>
                                        <View style={styles.MP2}>
                                            <Text style={styles.GreyText}>Industry Avg.</Text>
                                            <Text style={styles.SMLTextCenter}>
                                                {abbreviateNumber(facebook?.likes?.industryAverage)}
                                            </Text>
                                        </View>
                                        <View style={styles.MP2}>
                                            <Text style={styles.GreyText}>Industry Leaders</Text>
                                            <Text style={styles.SMLTextCenter}>
                                                {abbreviateNumber(facebook?.likes?.industryLeaders)}
                                            </Text>
                                        </View>
                                    </View>
                                </View>
                                <View style={styles.SMLBox}>
                                    <View style={styles.SMLSecionRow}>
                                        <View style={styles.MP3}>
                                            <Text style={styles.GreyText}>
                                                Avg. Posts Per Month
                                            </Text>
                                            <Text style={styles.SMLTextCenter}>
                                                {abbreviateNumber(
                                                    facebook?.averagePostsPerMonth?.avgposts,
                                                )}
                                            </Text>
                                        </View>
                                        <View style={styles.SMLBoxRight1}>
                                            <View style={styles.SMLBoxIcon}>
                                                <IPost color={'white'} />
                                            </View>
                                        </View>
                                    </View>
                                    <View style={styles.SMLSecionRowBottom}>
                                        <View style={styles.MP2}>
                                            <Text style={styles.GreyText}>Industry Avg.</Text>
                                            <Text style={styles.SMLTextCenter}>
                                                {abbreviateNumber(
                                                    facebook?.averagePostsPerMonth?.industryAverage,
                                                )}
                                            </Text>
                                        </View>
                                        <View style={{ margin: 2, padding: 2 }}>
                                            <Text style={styles.GreyText}>Industry Leaders</Text>
                                            <Text style={styles.SMLTextCenter}>
                                                {abbreviateNumber(
                                                    facebook?.averagePostsPerMonth?.industryLeaders,
                                                )}
                                            </Text>
                                        </View>
                                    </View>
                                </View>
                                <View style={styles.SMLBox}>
                                    <View style={styles.SMLSecionRow}>
                                        <View style={styles.MP3}>
                                            <Text style={styles.GreyText}>Avg. Likes Per Post</Text>
                                            <Text style={styles.SMLTextCenter}>
                                                {abbreviateNumber(
                                                    facebook?.averageLikesPerPost?.averageLikes,
                                                )}
                                            </Text>
                                        </View>
                                        <View style={styles.SMLBoxRight2}>
                                            <View style={styles.SMLBoxIcon}>
                                                <IPost color={'white'} />
                                            </View>
                                        </View>
                                    </View>
                                    <View style={styles.SMLSecionRowBottom}>
                                        <View style={styles.MP2}>
                                            <Text style={styles.GreyText}>Industry Avg.</Text>
                                            <Text style={styles.SMLTextCenter}>
                                                {abbreviateNumber(
                                                    facebook?.averageLikesPerPost?.industryAverage,
                                                )}
                                            </Text>
                                        </View>
                                        <View style={styles.MP2}>
                                            <Text style={styles.GreyText}>Industry Leaders</Text>
                                            <Text style={styles.SMLTextCenter}>
                                                {abbreviateNumber(
                                                    facebook?.averageLikesPerPost?.industryLeaders,
                                                )}
                                            </Text>
                                        </View>
                                    </View>
                                </View>
                                <View style={styles.SMLBox}>
                                    <View style={styles.SMLSecionRow}>
                                        <View style={styles.MP3}>
                                            <Text style={styles.GreyText}>
                                                Avg. Shares Per Post
                                            </Text>
                                            <Text style={styles.SMLTextCenter}>
                                                {abbreviateNumber(
                                                    facebook?.averageSharesPerPost?.averageShare,
                                                )}
                                            </Text>
                                        </View>
                                        <View style={styles.SMLBoxRight3}>
                                            <View style={styles.SMLBoxIcon}>
                                                <IShare color={'white'} />
                                            </View>
                                        </View>
                                    </View>
                                    <View style={styles.SMLSecionRowBottom}>
                                        <View style={styles.MP2}>
                                            <Text style={styles.GreyText}>Industry Avg.</Text>
                                            <Text style={styles.SMLTextCenter}>
                                                {abbreviateNumber(
                                                    facebook?.averageSharesPerPost?.industryAverage,
                                                )}
                                            </Text>
                                        </View>
                                        <View style={styles.MP2}>
                                            <Text style={styles.GreyText}>Industry Leaders</Text>
                                            <Text style={styles.SMLTextCenter}>
                                                {abbreviateNumber(
                                                    facebook?.averageSharesPerPost?.industryLeaders,
                                                )}
                                            </Text>
                                        </View>
                                    </View>
                                </View>
                            </View>
                        </View>
                    </View>
                ) : (
                    <View style={styles.AlertInfo}>
                        <View>
                            <Text>
                                We’re having trouble finding your Facebook business page. Either you
                                don’t have a page setup at all, or the page is not optimized
                                properly for potential customers to find.
                            </Text>
                        </View>
                    </View>
                )}
                {Object.keys(twitter || {}).length !== 0 ? (
                    <View>
                        <View>
                            <View style={styles.HeaderDiv}>
                                <Text>Twitter</Text>
                                <Text>Your Business’s Information on Twitter</Text>
                            </View>
                            <View style={styles.SMLsection}>
                                <View style={styles.TBox1}>
                                    <View style={styles.SMLSecionRow}>
                                        <View style={styles.MP3}>
                                            <Text style={styles.GreyText}>Likes</Text>
                                            <Text style={styles.SMLTextCenter}>
                                                {abbreviateNumber(twitter?.likes?.totalLikes)}
                                            </Text>
                                        </View>
                                        <View style={styles.WF20}>
                                            <IThumbsUp color={'#7242db'} />
                                        </View>
                                    </View>
                                    <View style={styles.SMLSecionRowBottom}>
                                        <View style={styles.MP3}>
                                            <Text style={styles.GreyText}>Industry Avg.</Text>
                                            <Text style={styles.SMLTextCenter}>
                                                {abbreviateNumber(twitter?.likes?.industryAverage)}
                                            </Text>
                                        </View>
                                        <View style={styles.MP3}>
                                            <Text style={styles.GreyText}>Industry Leaders</Text>
                                            <Text style={styles.SMLTextCenter}>
                                                {abbreviateNumber(twitter?.likes?.industryLeaders)}
                                            </Text>
                                        </View>
                                    </View>
                                </View>
                                <View style={styles.TBox2}>
                                    <View style={styles.SMLSecionRow}>
                                        <View style={styles.MP3}>
                                            <Text style={styles.GreyText}>
                                                Avg. Posts Per Month
                                            </Text>
                                            <Text style={styles.SMLTextCenter}>
                                                {abbreviateNumber(
                                                    twitter?.averagePostsPerMonth?.avgposts,
                                                )}
                                            </Text>
                                        </View>
                                        <View style={styles.WF20}>
                                            <IPost />
                                        </View>
                                    </View>
                                    <View style={styles.SMLSecionRowBottom}>
                                        <View style={styles.MP3}>
                                            <Text style={styles.GreyText}>Industry Avg.</Text>
                                            <Text style={styles.SMLTextCenter}>
                                                {abbreviateNumber(
                                                    twitter?.averagePostsPerMonth?.industryAverage,
                                                )}
                                            </Text>
                                        </View>
                                        <View style={styles.MP3}>
                                            <Text style={styles.GreyText}>Industry Leaders</Text>
                                            <Text style={styles.SMLTextCenter}>
                                                {abbreviateNumber(
                                                    twitter?.averagePostsPerMonth?.industryLeaders,
                                                )}
                                            </Text>
                                        </View>
                                    </View>
                                </View>
                                <View style={styles.TBox3}>
                                    <View style={styles.SMLSecionRow}>
                                        <View style={styles.MP3}>
                                            <Text style={styles.GreyText}>Avg. Likes Per Post</Text>
                                            <Text style={styles.SMLTextCenter}>
                                                {abbreviateNumber(
                                                    twitter?.averagePostsPerPost?.averageLikes,
                                                )}
                                            </Text>
                                        </View>
                                        <View style={styles.WF20}>
                                            <IPost />
                                        </View>
                                    </View>
                                    <View style={styles.SMLSecionRowBottom}>
                                        <View style={styles.MP3}>
                                            <Text style={styles.GreyText}>Industry Avg.</Text>
                                            <Text style={styles.SMLTextCenter}>
                                                {abbreviateNumber(
                                                    twitter?.averagePostsPerPost?.industryAverage,
                                                )}
                                            </Text>
                                        </View>
                                        <View style={styles.MP3}>
                                            <Text style={styles.GreyText}>Industry Leaders</Text>
                                            <Text style={styles.SMLTextCenter}>
                                                {abbreviateNumber(
                                                    twitter?.averagePostsPerPost?.industryLeaders,
                                                )}
                                            </Text>
                                        </View>
                                    </View>
                                </View>
                                <View style={styles.TBox4}>
                                    <View style={styles.TBoxArea}>
                                        <View style={styles.MP3}>
                                            <Text style={styles.GreyText}>
                                                Avg. Shares Per Post
                                            </Text>
                                            <Text style={styles.SMLTextCenter}>
                                                {abbreviateNumber(
                                                    twitter?.averageSharesPerPost?.averageShare,
                                                )}
                                            </Text>
                                        </View>
                                        <View style={styles.WF20}>
                                            <IShare />
                                        </View>
                                    </View>
                                    <View style={styles.SMLSecionRowBottom}>
                                        <View style={styles.MP3}>
                                            <Text style={styles.GreyText}>Industry Avg.</Text>
                                            <Text style={styles.SMLTextCenter}>
                                                {abbreviateNumber(
                                                    twitter?.averageSharesPerPost?.industryAverage,
                                                )}
                                            </Text>
                                        </View>
                                        <View style={styles.MP3}>
                                            <Text style={styles.GreyText}>Industry Leaders</Text>
                                            <Text style={styles.SMLTextCenter}>
                                                {abbreviateNumber(
                                                    twitter?.averageSharesPerPost?.industryLeaders,
                                                )}
                                            </Text>
                                        </View>
                                    </View>
                                </View>
                            </View>
                        </View>
                    </View>
                ) : null}
            </View>
            <View style={styles.GITSection}>
                {!(typeof user?.image === 'string') ? (
                    <>
                        {Object.keys(user?.image || {})?.length > 0 ? (
                            <Image
                                src={generateImage(user?.image, user?.name)}
                                style={styles.GITUserImage}
                                alt={'image'}
                            />
                        ) : (
                            <View style={styles.GITSvgImage}>
                                {getInitialsListingSvg(user?.name)}
                            </View>
                        )}
                    </>
                ) : (
                    <Image
                        src={{
                            uri: `${config.REACT_APP_API_BASE_URL}/v1/corsproxy/`,
                            method: 'GET',
                            body: '',
                            headers: { 'Target-URL': `${user?.image}` },
                        }}
                        cache={false}
                        style={styles.GITUserImage}
                        alt={'image'}
                    />
                )}
                <Text style={styles.GITContent}>
                    Would you like to create more engaging content on social media to build
                    awareness and attract more customers for {details?.name}? We can help!
                </Text>
            </View>
            <View fixed style={styles.FixedBottomHeight}>
                <Text />
            </View>
        </Page>
    );
};

export default SocialDownload;
