import { useCallback, useState } from 'react';
import { CallAPI } from 'Utils/apiCall';
import { messaging } from './init-fcm';
import usePubSub from 'Hooks/PubSub';
import useToast from 'Modules/Toasts';
import { getToken, onMessage } from 'firebase/messaging';
import config from 'config';

const useFCMSetup = () => {
    const [api] = useState(new CallAPI());
    const { publish } = usePubSub();
    const { showToast } = useToast();
    return useCallback(() => {
        try {
            Notification.requestPermission()
                .then(async () => {
                    const token = await getToken(messaging, {
                        vapidKey: process.env.REACT_APP_FCM_VAPID_KEY,
                    });

                    api.request(
                        'POST',
                        `${config.REACT_APP_API_BASE_URL}/${process.env.REACT_APP_API_VERSION}/${process.env.REACT_APP_API_INTEGRATIONS_FCM_WEB}`,
                        {
                            token,
                        },
                    );
                })
                .catch(err => {
                    console.error('err', err);
                });

            // We'll receive messages here while our page is focused
            onMessage(messaging, payload => {
                const { title, body, click_action } = payload.data;
                const data = JSON.parse(payload.data.data || '{}');
                publish('new_fcm_notification', {
                    module: data.module,
                    type: data.type,
                    title,
                    body,
                    data,
                    click_action,
                    createdAt: new Date(),
                });

                showToast({
                    message: title,
                    position: 'top-right',
                });
            });

            // Notification data will be received here from the firebase-messaging-sw.js file
            // This will occur when the page is not focused
            navigator.serviceWorker.addEventListener('message', event => {
                if (event.data.type === 'new_fcm_notification') {
                    publish('new_fcm_notification', event.data.data);
                }
            });
        } catch (e) {
            console.error('error messaging', e);
        }
    }, [api, publish, showToast]);
};

export default useFCMSetup;
