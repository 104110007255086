import React, { Fragment } from 'react';

import { Div, Skeleton } from '../index';

import './style.scss';

const UserDetailEditSkeleton = ({ customClass, hideImage, circularImage }) => {
    return (
        <Fragment>
            <Div className={'UserDetailEdit UDECenter ' + (customClass ? customClass : '')}>
                <Div className="UDEInner">
                    {!hideImage && (
                        <Div className={`UDEIImg  ${circularImage ? '' : 'UDEIImgSquare'}`}>
                            <Div className={`UDEIImgInner`}>
                                <Skeleton height={80} width={circularImage ? 80 : 180} />
                            </Div>
                        </Div>
                    )}
                    <Div className="UDEIInfoEdit">
                        <Div className="UDEIInfo">
                            <Div className="UDEIIName">
                                <Skeleton height={36} width={250} />
                            </Div>
                            <Div className="UDEIIJob">
                                <Skeleton height={24} width={250} />
                            </Div>
                        </Div>
                    </Div>
                </Div>
            </Div>
        </Fragment>
    );
};

export default UserDetailEditSkeleton;
