import React from 'react';
import { Page, Text, View, Image, Font, Link } from '@react-pdf/renderer';
import {
    IChatUser,
    IUserReview,
    ITwoStar,
    ISourceReview,
    IFacebook,
    IYelp,
    IGoogle,
    IStarFill,
} from './svgDownloadIcons/svgDownloadIcons';
import generateImage from 'Utils/generateImage';
import ColorBox, { getInitialsListingSvg } from './downloadUtils/colorBox';
import config from 'config';
import { styles } from './style';
import { instareportFontFamily } from '../components/constant';

Font.register({ ...instareportFontFamily });

const RatingStar = ({ value }) => {
    return (
        <View style={styles.UserRating}>
            {Array.from(Array(5).keys()).map(item => (
                <View key={item} style={{ height: 10 }}>
                    {value >= item + 1 ? (
                        <IStarFill color={'#ffb400'} fill={'#ffb400'} />
                    ) : (
                        <IStarFill color={'#e6e6e6'} fill={'#e6e6e6'} />
                    )}
                </View>
            ))}
        </View>
    );
};

const ReviewDownload = ({ reviews, details, user, score }) => {
    return (
        <Page wrap>
            <View fixed style={styles.FixedTopHeight}>
                <Text />
            </View>
            <View style={styles.section}>
                <View style={styles.HeadingView}>
                    <View style={styles.HeadingLogo}>
                        {score?.reviews ? <ColorBox score={score?.reviews} /> : null}
                        <Text style={styles.heading}>Reviews</Text>
                        <Text style={styles.HeadingCaption}>
                            What are customers saying about your business?
                        </Text>
                    </View>
                </View>
                <View style={styles.RSubSection}>
                    <View>
                        <Text style={styles.RSubSectionText}>
                            88% of consumers trust online reviews as much as personal
                            recommendations. It’s important to know what people are saying about{' '}
                            {details.name}. Good reviews can help skyrocket your business, whereas
                            bad reviews can severely damage it.
                        </Text>
                        <Text style={styles.RSubSectionText}>
                            We scanned the 3 major review platforms, Google, Facebook and Yelp to
                            see what people are saying about {details.name} online.
                        </Text>
                    </View>
                </View>
                <View>
                    <View>
                        <View style={styles.HeaderDiv} wrap={false}>
                            <Text>Total Online Reviews Found</Text>
                        </View>
                        <View style={styles.RTRsection}>
                            <View style={styles.GRBox1}>
                                <View style={styles.RTRSectionRow}>
                                    <View style={{ margin: 1, padding: 2 }}>
                                        <Text
                                            style={{
                                                fontFamily: 'Source Sans Pro',
                                                fontWeight: 600,
                                            }}
                                        >
                                            Reviews Found
                                        </Text>
                                        <Text style={styles.RTRSectionRowText}>
                                            {reviews?.totalOnlineReviews?.reviewsFound?.text}
                                        </Text>
                                    </View>
                                    <View style={styles.WH20}>
                                        <ITwoStar />
                                    </View>
                                </View>
                                <View style={styles.RTRSecionRowBottom}>
                                    <View style={styles.MP3}>
                                        <Text style={styles.GreyText}>Total Reviews</Text>
                                        <Text style={styles.TextCenter}>
                                            {
                                                reviews?.totalOnlineReviews?.reviewsFound
                                                    ?.totalReviews
                                            }
                                        </Text>
                                    </View>
                                    <View style={styles.MP3}>
                                        <Text style={styles.GreyText}>Industry Avg.</Text>
                                        <Text style={styles.TextCenter}>
                                            {reviews?.totalOnlineReviews?.reviewsFound?.industryAvg}
                                        </Text>
                                    </View>
                                </View>
                            </View>
                            <View style={styles.GRBox2}>
                                <View style={styles.RTRSectionRow}>
                                    <View style={styles.MP3}>
                                        <Text>Reviews Found</Text>
                                        <Text style={styles.RTRSectionRowText}>
                                            (in last 6 months)
                                        </Text>
                                    </View>
                                    <View style={styles.WH25}>
                                        <IChatUser />
                                    </View>
                                </View>
                                <View style={styles.RTRSecionRowBottom}>
                                    <View style={styles.MP3}>
                                        <Text style={styles.GreyText}>Total Reviews</Text>
                                        <Text style={styles.TextCenter}>
                                            {
                                                reviews?.totalOnlineReviews?.reviewsFoundLastSix
                                                    ?.totalLastSixMonths
                                            }
                                        </Text>
                                    </View>
                                    <View style={styles.MP3}>
                                        <Text style={styles.GreyText}>Industry Avg.</Text>
                                        <Text style={styles.TextCenter}>
                                            {
                                                reviews?.totalOnlineReviews?.reviewsFoundLastSix
                                                    ?.industryAvg
                                            }
                                        </Text>
                                    </View>
                                </View>
                            </View>
                            <View style={styles.GRBox3}>
                                <View style={styles.RTRSectionRow}>
                                    <View style={styles.MP3}>
                                        <Text>Review Score</Text>
                                    </View>
                                    <View style={styles.WH25}>
                                        <IUserReview />
                                    </View>
                                </View>
                                <View style={styles.RTRSecionRowBottom}>
                                    <View style={styles.MP3}>
                                        <Text style={styles.GreyText}>Your Rating</Text>
                                        <Text style={styles.TextCenter}>
                                            {
                                                reviews?.totalOnlineReviews?.averageReviewScore
                                                    ?.yourRating
                                            }
                                        </Text>
                                    </View>
                                    <View style={styles.MP3}>
                                        <Text style={styles.GreyText}>Industry Avg.</Text>
                                        <Text style={styles.TextCenter}>
                                            {
                                                reviews?.totalOnlineReviews?.averageReviewScore
                                                    ?.industryAvg
                                            }
                                        </Text>
                                    </View>
                                </View>
                            </View>
                            <View style={styles.GRBox4}>
                                <View style={styles.RTRSectionRow}>
                                    <View style={styles.MP3}>
                                        <Text>Sources For Reviews</Text>
                                    </View>
                                    <View style={styles.WH25}>
                                        <ISourceReview />
                                    </View>
                                </View>
                                <View style={styles.RTRSecionRowBottom}>
                                    <View style={styles.MP3}>
                                        <Text style={styles.GreyText}>Your Rating</Text>
                                        <Text style={styles.TextCenter}>
                                            {
                                                reviews?.totalOnlineReviews?.sourcesForReviews
                                                    ?.yourRating
                                            }
                                        </Text>
                                    </View>
                                    <View style={styles.MP3}>
                                        <Text style={styles.GreyText}>Industry Avg.</Text>
                                        <Text style={styles.TextCenter}>
                                            {
                                                reviews?.totalOnlineReviews?.sourcesForReviews
                                                    ?.industryAvg
                                            }
                                        </Text>
                                    </View>
                                </View>
                            </View>
                        </View>
                    </View>
                    {(reviews?.totalOnlineReviews?.reviews || {}).length ? (
                        <View style={styles.HeaderDiv}>
                            <Text>Your Business’s Online Review Overview</Text>
                        </View>
                    ) : null}
                    <View style={styles.RTUSection}>
                        {reviews?.totalOnlineReviews?.reviews.map((item, index) => (
                            <View key={index} style={styles.RTUSectionBox} wrap={false}>
                                <View style={styles.RTUSectionBoxHeader}>
                                    <View style={styles.BORSection}>
                                        {item?.image ? (
                                            <Image
                                                src={generateImage(item?.image)}
                                                alt={''}
                                                style={styles.BORSectionImage}
                                            />
                                        ) : (
                                            <Text />
                                        )}
                                        <View style={styles.RColumnView}>
                                            <Text style={styles.FSStandardGrey}>{item?.name}</Text>
                                            <Text>{item?.date}</Text>
                                        </View>
                                    </View>
                                    <View style={styles.CenterContent}>
                                        <View style={styles.TRView}>
                                            <View style={styles.RatingViewBox}>
                                                <View>
                                                    {item?.stars && (
                                                        <RatingStar
                                                            disabled={true}
                                                            value={item?.stars}
                                                        />
                                                    )}
                                                </View>
                                                <View style={{ height: 10 }}>
                                                    {item?.type === 'facebook' ? (
                                                        <IFacebook color={'#e6e6e6'} />
                                                    ) : item?.type === 'yelp' ? (
                                                        <IYelp color={'#e6e6e6'} />
                                                    ) : (
                                                        <IGoogle color={'#e6e6e6'} />
                                                    )}
                                                </View>
                                            </View>
                                        </View>
                                    </View>
                                </View>
                                <View style={styles.MP2}>
                                    <View>
                                        {item?.content ? (
                                            <Text style={styles.FSStandardGrey}>
                                                {item?.content.includes('See more')
                                                    ? item?.content?.split('See more')[0]
                                                    : item?.content}{' '}
                                                <Link
                                                    src={item?.reviewPageURL || '#'}
                                                    style={{ textDecoration: 'none' }}
                                                    target={'_blank'}
                                                >
                                                    See more
                                                </Link>
                                            </Text>
                                        ) : (
                                            <Text style={styles.FSStandardGrey}>
                                                {item?.content}
                                            </Text>
                                        )}
                                    </View>
                                    {/* </Text> */}
                                </View>
                            </View>
                        ))}
                    </View>
                    {Object.keys(reviews?.googleReviews || {}).length ? (
                        <View wrap={false}>
                            <View style={styles.HeaderDiv}>
                                <Text>Google Reviews Found</Text>
                            </View>
                            <View style={styles.RTRsection}>
                                <View style={styles.FBox1}>
                                    <View style={styles.RTRSecionRow}>
                                        <View style={styles.MP2}>
                                            <Text>Total Reviews Found</Text>
                                        </View>
                                        <View style={styles.WH20}>
                                            <ITwoStar />
                                        </View>
                                    </View>
                                    <View style={styles.RTRSecionRowBottom}>
                                        <View style={{ margin: 2, padding: 3 }}>
                                            <Text style={{ marginLeft: 30 }}>
                                                {reviews?.googleReviews?.totalReview}
                                            </Text>
                                        </View>
                                    </View>
                                </View>
                                <View style={styles.FBox2}>
                                    <View style={styles.RTRSecionRow}>
                                        <View style={styles.MP3}>
                                            <Text>Reviews Found</Text>
                                        </View>
                                        <View style={styles.WH20}>
                                            <IChatUser />
                                        </View>
                                    </View>
                                    <View style={styles.RTRSecionRowBottom}>
                                        <View style={{ margin: 2, padding: 3 }}>
                                            <Text style={{ marginLeft: 30 }}>
                                                {reviews?.googleReviews?.lastSixMonthReviewsCount}
                                            </Text>
                                        </View>
                                    </View>
                                </View>
                                <View style={styles.FBox3}>
                                    <View style={styles.RTRSecionRow}>
                                        <View style={styles.MP3}>
                                            <Text>Reviews Score</Text>
                                        </View>
                                        <View style={styles.WH20}>
                                            <IUserReview />
                                        </View>
                                    </View>
                                    <View style={styles.RTRSecionRowBottom}>
                                        <View style={{ margin: 2, padding: 3 }}>
                                            <Text style={{ marginLeft: 30 }}>
                                                {reviews?.googleReviews?.avgReviewScore}
                                            </Text>
                                        </View>
                                    </View>
                                </View>
                            </View>
                        </View>
                    ) : (
                        <View />
                    )}
                    <View>
                        {Object.keys(reviews?.googleReviews?.lastreview || {}).length ? (
                            <View style={styles.HeaderDiv} wrap={false}>
                                <Text>Your Business’s Google Reviews Overview</Text>
                            </View>
                        ) : null}
                        {Object.keys(reviews?.googleReviews?.lastreview || {}).length ? (
                            <View style={styles.RTUSection} wrap={false}>
                                <View style={styles.RTUSectionBox}>
                                    <View style={styles.RTUSectionBoxHeader}>
                                        <View style={styles.BORSectionWithoutAlign}>
                                            {reviews?.googleReviews?.lastreview?.image ? (
                                                <Image
                                                    src={generateImage(
                                                        reviews?.googleReviews?.lastreview?.image,
                                                    )}
                                                    alt={''}
                                                    style={styles.BORSectionImage}
                                                />
                                            ) : (
                                                <Text />
                                            )}
                                            <View style={styles.RColumnView}>
                                                <Text style={styles.FSStandardGreyWithPadding}>
                                                    {reviews?.googleReviews?.lastreview?.name}
                                                </Text>
                                                <Text>
                                                    {reviews?.googleReviews?.lastreview?.date}
                                                </Text>
                                            </View>
                                        </View>
                                        <View
                                            style={{
                                                fontSize: 14,
                                                height: 40,
                                                padding: 2,
                                            }}
                                        >
                                            <View style={styles.GRView}>
                                                <View>
                                                    {reviews?.googleReviews?.lastreview?.stars && (
                                                        <RatingStar
                                                            disabled={true}
                                                            value={
                                                                reviews?.googleReviews?.lastreview
                                                                    ?.stars
                                                            }
                                                        />
                                                    )}
                                                </View>
                                                <View style={{ height: 10 }}>
                                                    <IGoogle color={'#e6e6e6'} />
                                                </View>
                                            </View>
                                        </View>
                                        <View style={styles.BORSectionWithoutAlign} />
                                    </View>
                                    <View style={styles.MP3}>
                                        <Text style={styles.FSStandardGrey}>
                                            {reviews?.googleReviews?.lastreview?.content}
                                        </Text>
                                    </View>
                                </View>
                            </View>
                        ) : (
                            <View />
                        )}
                    </View>
                    {Object.keys(reviews?.yelpReviews || {}).length ? (
                        <View wrap={false}>
                            <View style={styles.HeaderDiv}>
                                <Text>Yelp Reviews Found</Text>
                            </View>
                            <View style={styles.RTRsection}>
                                <View style={styles.YBox1}>
                                    <View style={styles.RTRSecionRow}>
                                        <View style={styles.MP3}>
                                            <Text>Total Reviews Found</Text>
                                        </View>
                                        <View style={styles.WH20}>
                                            <ITwoStar />
                                        </View>
                                    </View>
                                    <View style={styles.RTRSecionRowBottom}>
                                        <View style={styles.MP3}>
                                            <Text style={{ marginLeft: 30 }}>
                                                {reviews?.yelpReviews?.totalReview}
                                            </Text>
                                        </View>
                                    </View>
                                </View>
                                <View style={styles.YBox2}>
                                    <View style={styles.RTRSecionRow}>
                                        <View style={styles.MP3}>
                                            <Text>Reviews Found</Text>
                                        </View>
                                        <View style={styles.WH20}>
                                            <IChatUser />
                                        </View>
                                    </View>
                                    <View style={styles.RTRSecionRowBottom}>
                                        <View style={styles.MP3}>
                                            <Text style={{ marginLeft: 30 }}>
                                                {reviews?.yelpReviews?.lastSixMonthReviewsCount}
                                            </Text>
                                        </View>
                                    </View>
                                </View>
                                <View style={styles.YBox3}>
                                    <View style={styles.RTRSecionRow}>
                                        <View style={styles.MP3}>
                                            <Text>Reviews Score</Text>
                                        </View>
                                        <View style={styles.WH20}>
                                            <IUserReview />
                                        </View>
                                    </View>
                                    <View style={styles.RTRSecionRowBottom}>
                                        <View style={styles.MP3}>
                                            <Text style={{ marginLeft: 30 }}>
                                                {reviews?.yelpReviews?.avgReviewScore}
                                            </Text>
                                        </View>
                                    </View>
                                </View>
                            </View>
                        </View>
                    ) : (
                        <View />
                    )}
                    <View>
                        {Object.keys(reviews?.yelpReviews?.lastreview || {}).length ? (
                            <View style={styles.HeaderDiv} wrap={false}>
                                <Text>Your Business’s Yelp Reviews Overview</Text>
                            </View>
                        ) : null}
                        {Object.keys(reviews?.yelpReviews?.lastreview || {}).length ? (
                            <View style={styles.RTUSection} wrap={false}>
                                <View style={styles.RTUSectionBox}>
                                    <View style={styles.RTUSectionBoxHeader}>
                                        <View style={styles.BORSectionWithoutAlign}>
                                            {reviews?.yelpReviews?.lastreview?.image ? (
                                                <Image
                                                    src={generateImage(
                                                        reviews?.yelpReviews?.lastreview?.image,
                                                    )}
                                                    alt={''}
                                                    style={styles.BORSectionImage}
                                                />
                                            ) : (
                                                <Text />
                                            )}
                                            <View style={styles.RColumnView}>
                                                <Text style={styles.FSStandardGreyWithPadding}>
                                                    {reviews?.yelpReviews?.lastreview?.name}
                                                </Text>
                                                <Text>
                                                    {reviews?.yelpReviews?.lastreview?.date}
                                                </Text>
                                            </View>
                                        </View>
                                        <View style={styles.YRView}>
                                            <View style={styles.RYRRatingBox}>
                                                <View>
                                                    {reviews?.yelpReviews?.lastreview?.stars && (
                                                        <RatingStar
                                                            disabled={true}
                                                            value={
                                                                reviews?.yelpReviews?.lastreview
                                                                    ?.stars
                                                            }
                                                        />
                                                    )}
                                                </View>
                                                <View style={{ height: 20 }}>
                                                    <IYelp color={'#e6e6e6'} />
                                                </View>
                                            </View>
                                        </View>
                                        <View style={styles.YRLRArea} />
                                    </View>
                                    <View style={styles.MP3}>
                                        <Text style={styles.FSStandardGrey}>
                                            {reviews?.yelpReviews?.lastreview?.content}
                                        </Text>
                                    </View>
                                </View>
                            </View>
                        ) : (
                            <View />
                        )}
                    </View>
                    {Object.keys(reviews?.facebookReviews || {}).length ? (
                        <View wrap={false}>
                            <View style={styles.HeaderDiv}>
                                <Text>Facebook Reviews Found</Text>
                            </View>
                            <View style={styles.RTRsection}>
                                <View style={styles.FBBox1}>
                                    <View style={styles.RTRSecionRow}>
                                        <View style={styles.MP3}>
                                            <Text>Total Reviews Found</Text>
                                        </View>
                                        <View style={styles.WH20}>
                                            <ITwoStar />
                                        </View>
                                    </View>
                                    <View style={styles.RTRSecionRowBottom}>
                                        <View style={{ margin: 2, padding: 3 }}>
                                            <Text style={{ marginLeft: 30 }}>
                                                {reviews?.facebookReviews?.totalReview}
                                            </Text>
                                        </View>
                                    </View>
                                </View>
                                <View style={styles.FBBox2}>
                                    <View style={styles.RTRSecionRow}>
                                        <View style={styles.MP3}>
                                            <Text>Reviews Score</Text>
                                        </View>
                                        <View style={styles.WH20}>
                                            <IUserReview />
                                        </View>
                                    </View>
                                    <View style={styles.RTRSecionRowBottom}>
                                        <View style={{ margin: 2, padding: 3 }}>
                                            <Text style={{ marginLeft: 30 }}>
                                                {reviews?.facebookReviews?.avgReviewScore}
                                            </Text>
                                        </View>
                                    </View>
                                </View>
                            </View>
                        </View>
                    ) : (
                        <View />
                    )}
                    <View wrap={false}>
                        {Object.keys(reviews?.facebookReviews?.lastreview || {}).length ? (
                            <View style={styles.HeaderDiv} wrap={false}>
                                <Text>Your Business’s Facebook Reviews Overview</Text>
                            </View>
                        ) : null}
                        {Object.keys(reviews?.facebookReviews?.lastreview || {}).length ? (
                            <View style={styles.RTUSection} wrap={false}>
                                <View style={styles.RTUSectionBox}>
                                    <View style={styles.RTUSectionBoxHeader}>
                                        <View style={styles.BORSectionWithoutAlign}>
                                            {reviews?.facebookReviews?.lastreview?.image ? (
                                                <Image
                                                    src={generateImage(
                                                        reviews?.facebookReviews?.lastreview?.image,
                                                    )}
                                                    alt={''}
                                                    style={styles.BORSectionImage}
                                                />
                                            ) : (
                                                <Text />
                                            )}
                                            <View style={styles.RColumnView}>
                                                <Text style={styles.FSStandardGreyWithPadding}>
                                                    {reviews?.facebookReviews?.lastreview?.name ||
                                                        '------'}
                                                </Text>
                                                <Text>
                                                    {reviews?.facebookReviews?.lastreview?.date ||
                                                        '------'}
                                                </Text>
                                            </View>
                                        </View>
                                        <View style={styles.BORSectionWithoutAlign} />
                                    </View>
                                    <View style={styles.FRViewArea}>
                                        {reviews?.facebookReviews?.lastreview?.stars && (
                                            <View>
                                                <View style={styles.FRView}>
                                                    <View>
                                                        {reviews?.facebookReviews?.lastreview
                                                            ?.stars && (
                                                            <RatingStar
                                                                disabled={true}
                                                                value={
                                                                    reviews?.facebookReviews
                                                                        ?.lastreview?.stars
                                                                }
                                                            />
                                                        )}
                                                    </View>
                                                    <View style={{ height: 20 }}>
                                                        <IFacebook color={'#e6e6e6'} />
                                                    </View>
                                                </View>
                                            </View>
                                        )}
                                    </View>
                                    <View style={styles.MP3}>
                                        <Text style={styles.FSStandardGrey}>
                                            {reviews?.facebookReviews?.lastreview?.content}
                                        </Text>
                                    </View>
                                </View>
                            </View>
                        ) : (
                            <View />
                        )}
                    </View>
                </View>
            </View>
            <View style={styles.GITSection}>
                {!(typeof user?.image === 'string') ? (
                    <>
                        {Object.keys(user?.image || {})?.length > 0 ? (
                            <Image
                                src={generateImage(user?.image, user?.name)}
                                style={styles.GITUserImage}
                                alt={'image'}
                            />
                        ) : (
                            <View style={styles.GITSvgImage}>
                                {getInitialsListingSvg(user?.name)}
                            </View>
                        )}
                    </>
                ) : (
                    <Image
                        src={{
                            uri: `${config.REACT_APP_API_BASE_URL}/v1/corsproxy/`,
                            method: 'GET',
                            body: '',
                            headers: { 'Target-URL': `${user?.image}` },
                        }}
                        cache={false}
                        style={styles.GITUserImage}
                        alt={'image'}
                    />
                )}
                <Text style={styles.GITContent}>
                    Would you like to start generating more potential customers to these review
                    platforms for {details?.name}? We can help!
                </Text>
            </View>
            <View fixed style={styles.FixedBottomHeight}>
                <Text />
            </View>
        </Page>
    );
};

export default ReviewDownload;
