import React, { Fragment, useRef, useState } from 'react';
import { Div, IconButton, ToolTip } from 'UIKit/index';
import DCFillQuestion from 'Assets/icons/DCFillQuestion';
import DashclicksSupportDialog from 'Components/DashclicksSupportDialog';
import { getAccountType } from 'Utils/auth';

const HelpDialog = () => {
    const helpBtnRef = useRef();

    const [helpDialogState, setHelpDialog] = useState(false);

    const handleUserDropdownOpen = () => {
        if (getAccountType() !== 'main') {
            document.open('https://help.dashboardhelp.com', '', '');
        } else setHelpDialog(prev => !prev);
    };

    const handleHelpDialogClose = () => {
        setHelpDialog(false);
    };

    return (
        <Fragment>
            <ToolTip
                content={'In App Help'}
                customPopoverClass={'ArrowPopover APToolTip'}
                wrapperClass={'APTWrapper'}
                placement={'bottom-center'}
            >
                <IconButton
                    customClass="HelpIconBtn"
                    onClick={handleUserDropdownOpen}
                    reference={helpBtnRef}
                >
                    <Div id="CartAnimation" />
                    <DCFillQuestion />
                </IconButton>
            </ToolTip>
            <DashclicksSupportDialog open={helpDialogState} onClose={handleHelpDialogClose} />
        </Fragment>
    );
};

export default HelpDialog;
