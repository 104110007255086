import React from 'react';
import { getCountries } from 'react-phone-number-input/input';
import { Button, Image } from 'UIKit';
import { AutoComplete } from 'Components';

function CountrySelect({ value, onChange, labels, popoverWidth, parentRef }) {
    const generateOptions = () => {
        return getCountries()
            .filter(el => el !== 'AC')
            .map((country, index) => {
                return {
                    id: index,
                    label: (
                        <Button
                            buttonClass={'ACSingleOption ' + (value === country ? 'active' : '')}
                            onClick={() => {
                                onChange(country);
                            }}
                        >
                            {`${labels[country]} `}
                        </Button>
                    ),
                    value: labels[country],
                };
            });
    };

    return (
        <AutoComplete
            parentRef={parentRef}
            generateRows={generateOptions}
            isAPICall={false}
            singleSelection={true}
            loading={false}
            value={<Image src={`https://assets.mydashmetrics.com/flag/${value}.png`} />}
            customWidth={popoverWidth}
        />
    );
}

export default CountrySelect;
