import React from 'react';

import { SVGIcon } from '../../UIKit/index';

export default function DCReminder(props) {
    return (
        <SVGIcon {...props} viewBox="0 0 501 512">
            <g transform="translate(0.1 0.1)">
                <path d="M500.16,202.29c0.15-51.96-41.86-94.21-93.82-94.36c-11.41-0.03-22.73,2.01-33.41,6.03   c-26.14-31.44-62.08-53.19-102.06-61.75v-8.94c0-23.95-19.42-43.37-43.37-43.37s-43.37,19.42-43.37,43.37v11.48   C104.57,76.5,49.35,148.72,49.2,231.2v144.57h-4.82c-23.95,0-43.37,19.42-43.37,43.37c0,23.95,19.42,43.37,43.37,43.37h131.86   c7.96,33.64,41.68,54.46,75.32,46.5c23.05-5.45,41.05-23.45,46.5-46.5h122.21c23.95,0,43.37-19.42,43.37-43.37   c0-23.95-19.42-43.37-43.37-43.37h-4.82v-79.62c48.1-5,84.66-45.51,84.73-93.87H500.16z M480.9,202.29   c0,41.47-33.62,75.09-75.09,75.09s-75.09-33.62-75.09-75.09s33.62-75.09,75.09-75.09l0,0C447.26,127.24,480.85,160.83,480.9,202.29   z M203.42,43.27c0.39-13.31,11.49-23.78,24.79-23.39c12.76,0.37,23.02,10.63,23.39,23.39v5.82c-16.05-1.77-32.27-1.27-48.18,1.48   V43.27z M237.15,491.43c-18.37,0.05-34.74-11.57-40.76-28.92h81.53C271.89,479.86,255.52,491.47,237.15,491.43z M420.27,395.1   c13.31-0.39,24.41,10.08,24.79,23.39c0.39,13.31-10.08,24.41-23.39,24.79c-0.47,0.01-0.94,0.01-1.41,0H44.38   c-13.31-0.39-23.78-11.49-23.39-24.79c0.37-12.76,10.63-23.02,23.39-23.39h14.47c5.32,0,9.63-4.31,9.63-9.63V231.2   C68.35,140.82,141.51,67.45,231.9,67.32c47.12-0.07,91.98,20.18,123.1,55.55c-43.88,28.03-56.73,86.32-28.71,130.2   c15.5,24.27,41.24,40.12,69.88,43.04v89.3c0,5.32,4.31,9.63,9.63,9.63L420.27,395.1z" />
                <path d="M376.89,243.75c2.85,0,5.59-1.14,7.6-3.16l28.69-28.69c2.02-2.02,3.15-4.75,3.16-7.6v-41.11   c-0.03-5.94-4.87-10.74-10.81-10.71c-5.9,0.03-10.68,4.81-10.71,10.71v36.67l-25.64,25.62c-4.24,4.16-4.31,10.97-0.16,15.22   c2.04,2.08,4.84,3.25,7.76,3.23L376.89,243.75z" />
                <path d="M262.34,374.07h-21.52c-5.94-0.03-10.78,4.77-10.81,10.71c-0.03,5.94,4.77,10.78,10.71,10.81   c0.03,0,0.07,0,0.1,0h21.52c5.94,0.03,10.78-4.77,10.81-10.71c0.03-5.94-4.77-10.78-10.71-10.81   C262.41,374.07,262.37,374.07,262.34,374.07z" />
                <path d="M215.8,374.07H104.72c-5.94-0.03-10.78,4.77-10.81,10.71c-0.03,5.94,4.77,10.78,10.71,10.81   c0.03,0,0.07,0,0.1,0H215.8c5.94,0.03,10.78-4.77,10.81-10.71c0.03-5.94-4.77-10.78-10.71-10.81   C215.87,374.07,215.83,374.07,215.8,374.07z" />
            </g>
        </SVGIcon>
    );
}
