import React, { useLayoutEffect, useRef, useState, useEffect } from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { Div } from '../../UIKit/index';
import { formatNumberString } from '../../Utils/index';
import './style.scss';

const ColumnChart = ({
    className,
    title,
    headerOther,
    subHeader,
    xAxisData,
    xAxisLabel,
    yAxisLabel,
    chartData,
    seriesLabels,
    scrollbarYAxis,
    scrollbarXAxis,
    height,
    pointWidth,
    columnWidth,
    tooltips,
    toplable,
}) => {
    const boxWidthRef = useRef();
    const [boxWidth, setWidth] = useState(null);
    const [allData, setAllData] = useState([]);

    useEffect(() => {
        let tempData = [];
        if (chartData?.length) {
            chartData.forEach(item => {
                if (item.data.length === tempData.length) {
                    var arr3 = item.data.map((v, i) => v + tempData[i]);
                    tempData = arr3;
                } else tempData.push(...item.data);
            });
        }
        setAllData(tempData);
    }, [chartData]);

    const ChartOptions = {
        chart: {
            height: height || 500,
            width: boxWidth,
            type: 'column',
        },
        title: {
            text: '',
        },
        subtitle: {
            text: '',
        },
        caption: {
            text: '',
        },
        tooltip: {
            backgroundColor: {
                linearGradient: [0, 0, 0, 60],
                stops: [
                    [0, '#FFFFFF'],
                    [1, '#E0E0E0'],
                ],
            },
            opacity: 1,
            borderWidth: 1,
            borderColor: '#AAA',
            formatter: function () {
                if (tooltips && tooltips.length > 0) {
                    return tooltips[this.point.index];
                }
                return formatNumberString(this.y) + '<strong> ' + this.series.name + '</strong>';
            },
        },
        xAxis: {
            categories: xAxisData,
            crosshair: true,
            labels: {
                enabled: xAxisLabel,
                useHTML: true,
                format: '{value}',
            },
            scrollbar: {
                enabled: scrollbarXAxis || false,
            },
        },
        yAxis: {
            min: 0,
            max:
                _.max(_.uniq(allData || []).length ? _.uniq(allData || []) : [0]) === 0
                    ? 200
                    : _.max(_.uniq(allData || [])) + 5,
            labels: {
                enabled: yAxisLabel,
                useHTML: true,
                format: '{value}',
                formatter: function () {
                    return formatNumberString(this.value);
                },
            },
            stackLabels: { enabled: toplable },
            title: {
                text: null,
            },
            scrollbar: {
                enabled: scrollbarYAxis || false,
            },
            opposite: true,
        },
        plotOptions: {
            column: {
                stacking: 'normal',
                minPointLength: 3,
                dataLabels: {
                    enabled: true,
                },
            },
            series: {
                pointWidth: pointWidth || 50,
                dataLabels: {
                    enabled: true,
                    format: '{value}',
                },
            },
        },
        legend: {
            enabled: false,
        },
        credits: {
            enabled: false,
        },
        series: chartData,
    };

    useLayoutEffect(() => {
        /**
         * To measure box height, Using ref [useRef hooks] object to measure height and
         * setting it into states which is being used to set height of infinite scroll bar
         */
        function updateSize() {
            if (boxWidthRef.current) {
                const tempWidth = xAxisData?.length * columnWidth || 150;
                if (tempWidth > boxWidthRef.current.clientWidth) {
                    setWidth(tempWidth);
                } else {
                    setWidth(boxWidthRef.current.clientWidth);
                }
            }
        }
        window.addEventListener('resize', updateSize);
        updateSize();
        return () => window.removeEventListener('resize', updateSize);
    }, [xAxisData, chartData, columnWidth]);

    return (
        <Div className={'ColumnChart ' + (className ? className : '')} reference={boxWidthRef}>
            {title && (
                <Div className={'ColumnChartHead'}>
                    <Div className={'CCHTitle'}>{title}</Div>
                    {headerOther && <Div className={'CCHOptions'}>{headerOther}</Div>}
                </Div>
            )}
            {subHeader && subHeader}
            <Div className={'ColumnChartGraph'}>
                <HighchartsReact highcharts={Highcharts} options={ChartOptions} />
            </Div>
            {seriesLabels && (
                <Div className={'ChartSeries'}>
                    {chartData.map((item, index) => (
                        <Div className={'CSItem'} key={index}>
                            {item.color && (
                                <Div
                                    className={'CSIDot'}
                                    style={{
                                        backgroundColor: item.color,
                                        boxShadow: `0 0 5px  ${item.color}`,
                                    }}
                                />
                            )}
                            <Div className={'CSITitle'}>{item.name}</Div>
                        </Div>
                    ))}
                </Div>
            )}
        </Div>
    );
};

ColumnChart.propTypes = {
    className: PropTypes.string,
    title: PropTypes.element,
    headerOther: PropTypes.string,
    subHeader: PropTypes.element,
    xAxisData: PropTypes.object,
    xAxisLabel: PropTypes.bool,
    yAxisLabel: PropTypes.bool,
    chartData: PropTypes.arrayOf(PropTypes.object).isRequired,
    seriesLabels: PropTypes.bool,
    scrollbarYAxis: PropTypes.bool,
    scrollbarXAxis: PropTypes.bool,
    height: PropTypes.number,
    pointWidth: PropTypes.number,
    columnWidth: PropTypes.number,
    tooltips: PropTypes.any,
    toplable: PropTypes.bool,
};

export default ColumnChart;
