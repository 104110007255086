import React from 'react';

import { SVGIcon } from '../../UIKit/index';

export default function DCTrash(props) {
    return (
        <SVGIcon {...props} viewBox="0 0 400 512">
            <path d="M377.3,63.87h-87.81V47.89C289.45,21.45,268.02,0.03,241.58,0h-63.89  c-26.45,0.03-47.88,21.46-47.91,47.91v15.98H41.96c-22.03,0.03-39.88,17.87-39.92,39.9v56.01c0,8.83,7.15,15.98,15.98,15.98l0,0  h8.72l13.81,289.73c1.26,25.54,22.37,45.58,47.94,45.5h242.5c25.59,0.03,46.67-20.07,47.86-45.63l13.81-289.73h8.72  c8.83,0,15.98-7.15,15.98-15.98l0,0v-55.88c-0.03-22.08-17.95-39.95-40.03-39.92C377.32,63.87,377.31,63.87,377.3,63.87z   M161.84,47.91c0-8.83,7.15-15.98,15.98-15.98l0,0h63.87c8.83,0,15.98,7.15,15.98,15.98c0,0,0,0,0,0v15.98h-95.83V47.91z   M33.97,103.79c0.07-4.31,3.55-7.79,7.86-7.86h335.31c4.31,0.07,7.79,3.55,7.86,7.86v39.92H33.97V103.79z M346.83,463.86  c-0.39,8.52-7.42,15.23-15.95,15.22H88.49c-8.53,0.01-15.56-6.7-15.95-15.22L58.81,175.7h301.75L346.83,463.86z" />
            <path d="M209.64,447.14c8.83,0,15.98-7.15,15.98-15.98l0,0V223.58c-0.36-8.82-7.8-15.68-16.62-15.32  c-8.32,0.34-14.98,7-15.32,15.32v207.6C193.71,439.99,200.84,447.13,209.64,447.14z" />
            <path d="M289.49,447.14c8.83,0,15.98-7.15,15.98-15.98V223.58c0.36-8.82-6.5-16.26-15.32-16.62  c-8.82-0.36-16.26,6.5-16.62,15.32c-0.02,0.43-0.02,0.86,0,1.3v207.6C273.55,439.99,280.68,447.13,289.49,447.14z" />
            <path d="M129.8,447.14c8.83,0,15.98-7.15,15.98-15.98l0,0V223.58c-0.36-8.82-7.8-15.68-16.62-15.32  c-8.32,0.34-14.98,7-15.32,15.32v207.6C113.86,439.99,120.99,447.13,129.8,447.14z" />
        </SVGIcon>
    );
}
