import React from 'react';
import DCTeamMemberSeats from 'Assets/icons/DCTeamMemberSeats';
import DCCustomDomain from 'Assets/icons/DCCustomDomain';
import DCEmailCredits from 'Assets/icons/DCEmailCredits';
import DCSmsCredits from 'Assets/icons/DCSmsCredits';
import DCFileStorage from 'Assets/icons/DCFileStorage';
import DCContactRecords from 'Assets/icons/DCContactRecords';
import DCConversations from 'Assets/icons/DCConversations1';
import DCCustomFilters from 'Assets/icons/DCCustomFilters';
import DCCustomProperties from 'Assets/icons/DCCustomProperties';
import DCTags from 'Assets/icons/DCTags';
import DCInsights from 'Assets/icons/DCInsights';
import DCTrackedSeoKeywords from 'Assets/icons/DCTrackedSeoKeywords';
import DCListingsManager from 'Assets/icons/DCListingsManager';
import DCTemplates from 'Assets/icons/DCTemplates';
import DCActivateSite from 'Assets/icons/DCActivateSite';
import DCWebsiteAnalytics from 'Assets/icons/DCWebsiteAnalytics';
import DCInstaSites from 'Assets/icons/DCInstaSites1';
import DCEcommerceTemplates from 'Assets/icons/DCEcommerceTemplates';
import DCInstaReports from 'Assets/icons/DCInstaReports1';
import DCSalesPipelines from 'Assets/icons/DCSalesPipelines';
import DCDealAutomations from 'Assets/icons/DCDealAutomations';
import DCForms from 'Assets/icons/DCForms1';
import DCGroupForm from 'Assets/icons/DCGroupForm1';
import DCCardForm from 'Assets/icons/DCCardForm1';
import DCCampaigns from 'Assets/icons/DCCampaigns1';
import DCRoundRobinLeads from 'Assets/icons/DCRoundRobinLeads';
import DCAutoCreateContacts from 'Assets/icons/DCAutoCreateContacts';
import DCAutoCreateDeals from 'Assets/icons/DCAutoCreateDeals';
import DCReviewRequest from 'Assets/icons/DCReviewRequest';
import DCReviewReply from 'Assets/icons/DCReviewReply';
import DCReviewIntegrations from 'Assets/icons/DCReviewIntegrations';
import DCCustomEmail from 'Assets/icons/DCCustomEmail';

export const getContent = app => {
    switch (app) {
        // General
        case 'team_member_seats':
            return {
                app: 'Settings',
                title: `Looks like you need more team seats to make it a party`,
                description: `Add additional seats to your account so you can bring in your entire team and work more efficiently as a group.`,
                icon: <DCTeamMemberSeats />,
            };
        case 'custom_domain':
            return {
                app: 'Settings',
                title: 'Make the dashboard yours with a custom domain',
                description:
                    'Upgrade your plan so you can setup your own custom domain and make the platform fully branded.',
                icon: <DCCustomDomain />,
            };
        case 'custom_email':
            return {
                app: 'Settings',
                title: 'Make the dashboard yours with a custom email domain',
                description:
                    'Upgrade your plan so you can setup your own custom email domain for branded communications.',
                icon: <DCCustomEmail />,
            };
        case 'email_credits':
            return {
                app: 'Settings',
                title: `Looks like you need more email credits to keep the conversations going`,
                description: `Upgrade your plan so you can send more emails, start additional conversations and increase revenue for your business.`,
                icon: <DCEmailCredits />,
            };
        case 'sms_credits':
            return {
                app: 'Settings',
                title: `Looks like you need more SMS credits to keep the conversations going.`,
                description: `Upgrade your plan so you can send more SMS messages, start additional conversations and increase revenue for your business.`,
                icon: <DCSmsCredits />,
            };
        case 'file_storage':
            return {
                app: 'Settings',
                title: `Looks like you need more storage space to load up more files`,
                description: `Upgrade your plan so you store additional files and collaborate with your team better.`,
                icon: <DCFileStorage />,
            };

        // Contacts
        case 'contact_records':
            return {
                app: 'Contacts',
                title: `Let's get this party started. More contacts, more fun.`,
                description: `Upgrade your plan so you can add additional contact records to your account.`,
                icon: <DCContactRecords />,
            };
        case 'contact_conversations':
            return {
                app: 'Contacts',
                title: `Send emails and SMS messages to your contacts`,
                description: `Send emails and SMS messages to your contacts`,
                icon: <DCConversations />,
            };
        case 'custom_filters':
            return {
                app: 'Contacts',
                title: `Create custom filters & segment your contacts into lists`,
                description: `Organize contact records and create custom segments for your sales team that update in real-time.`,
                icon: <DCCustomFilters />,
            };
        case 'custom_properties':
            return {
                app: 'Contacts',
                title: `Create custom properties and add life to your contacts`,
                description: `Create unlimited custom properties to store the data you need about your contact records.`,
                icon: <DCCustomProperties />,
            };
        case 'contact_tags':
            return {
                app: 'Contacts',
                title: `Create custom tags to segment your data better`,
                description: `Create unlimited custom tags to segment your contacts better. `,
                icon: <DCTags />,
            };

        // Analytics
        case 'insights':
            return {
                app: 'Analytics',
                title: `Get intelligent insights on how your campaigns are performing.`,
                description: `View granular data on your campaign reporting so you can make smarter marketing decisions moving forward.`,
                icon: <DCInsights />,
            };
        case 'tracked_seo_keywords':
            return {
                app: 'Analytics',
                title: `Start tracking additional keywords for your SEO campaign`,
                description: `Upgrade your plan so you can track additional keywords for your SEO campaign.`,
                icon: <DCTrackedSeoKeywords />,
            };
        case 'listing_manager':
            return {
                app: 'Analytics',
                title: `List your business in 70+ directories, from one profile`,
                description: `Manage, maintain and sync all your business listings from one profile for complete unity.`,
                icon: <DCListingsManager />,
            };

        //  Deals
        case 'sales_pipelines':
            return {
                app: 'Deals',
                title: `Add additional pipelines & segment your deals`,
                description: `Organize your sales process and empower your sales team to manage multiple deals, across multiple sales pipelines.`,
                icon: <DCSalesPipelines />,
            };
        case 'deal_conversations':
            return {
                app: 'Deals',
                title: `Send emails and SMS messages to your contacts`,
                description: `Deepen conversations in your sales cycle by sending email and SMS messages from inside your deal card to associated contacts.`,
                icon: <DCConversations />,
            };
        case 'deal_custom_properties':
            return {
                app: 'Deals',
                title: `Create custom properties and add life to your deals`,
                description: `Create unlimited custom properties to store the data you need about your deals.`,
                icon: <DCCustomProperties />,
            };
        case 'deal_custom_filters':
            return {
                app: 'Deals',
                title: `Create custom filters & segment your deals into lists`,
                description: `Organize deals and create custom segments for your sales team that update in real-time.`,
                icon: <DCCustomFilters />,
            };
        case 'deal_automations':
            return {
                app: 'Deals',
                title: `Automate your workflow with deal automations`,
                description: `Trigger custom emails, SMS messages and more when a deal moves from one stage to another.`,
                activateTier: 'tier2',
                icon: <DCDealAutomations />,
            };
        case 'deal_tags':
            return {
                app: 'Deals',
                title: `Create custom tags to segment your data better`,
                description: `Create unlimited custom tags to segment your deals better. `,
                icon: <DCTags />,
            };

        // Forms
        case 'forms':
            return {
                app: 'Forms',
                title: `Looks like you need more Form credits to collect more data`,
                description: `Upgrade your plan so you can add additional forms and collect data faster.`,
                icon: <DCForms />,
            };
        case 'group_form':
            return {
                app: 'Forms',
                title: `Collect grouped questions for easy segmentation`,
                description: `Customize the way you collect data by using grouped forms for a more strategic approach.`,
                icon: <DCGroupForm />,
            };
        case 'card_form':
            return {
                app: 'Forms',
                title: `Collect one question at a time, just like a conversation`,
                description: `Customize the way you collect data by using card forms for a more strategic approach.`,
                icon: <DCCardForm />,
            };

        // Inbound
        case 'campaigns':
            return {
                app: 'Inbound',
                title: `Add more campaigns and keep your leads unified`,
                description: `Upgrade your plan so you can add additional campaigns and bring more leads into Inbound.`,
                icon: <DCCampaigns />,
            };
        case 'round_robin_leads':
            return {
                app: 'Inbound',
                title: `Bring the entire sales team to the party and round robin`,
                description: `Round robin your inbound leads across a group of sales reps for equal lead distribution.`,
                icon: <DCRoundRobinLeads />,
            };
        case 'auto_create_contacts':
            return {
                app: 'Inbound',
                title: `Automatically create contacts and save time`,
                description: `Automatically create contact records everytime a new lead enters Inbound.`,
                icon: <DCAutoCreateContacts />,
            };
        case 'auto_create_deals':
            return {
                app: 'Inbound',
                title: `Automatically create deals and save time`,
                description: `Automatically create Deals everytime a new lead enters Inbound.`,
                icon: <DCAutoCreateDeals />,
            };
        // InstaReports

        case 'instareports':
            return {
                app: 'Inbound',
                title: `Looks like you need more InstaReports credits to run a new report`,
                description: `Create in-depth performance reports in seconds to help close deals faster.`,
                icon: <DCInstaReports />,
            };

        // InstaSites
        case 'instasites':
            return {
                app: 'InstaSites',
                title: `Looks like you need more InstaSites credits to build more sites`,
                description: `Create a custom website in seconds from hundreds of niche templates.`,
                icon: <DCInstaSites />,
            };
        case 'ecommerce_templates':
            return {
                app: 'InstaSites',
                title: `Build an entire eCommerce store with a single click`,
                description: `Create custom eCommerce stores in seconds from hundreds of niche templates.`,
                icon: <DCEcommerceTemplates />,
            };

        // Sites
        case 'activate_sites':
            return {
                app: 'Sites',
                title: `Looks like you haven't activated your website yet`,
                description: `Activate your new website now and start converting clicks into customers.`,
                icon: <DCActivateSite />,
            };
        case 'website_analytics':
            return {
                app: 'Sites',
                title: `Looks like you haven't activated your website yet`,
                description: `Activate your new website now and get in-depth analytics to see how your site is performing.`,
                icon: <DCWebsiteAnalytics />,
            };

        // Templates
        case 'templates':
            return {
                app: 'Templates',
                title: `Add additional templates to speed up your workflow`,
                description: `Upgrade your plan so you can add additional templates and start unifying your messaging across your entire organization.`,
                icon: <DCTemplates />,
            };

        // Reviews
        case 'review_integrations':
            return {
                app: 'Reputation',
                title: `Looks like you need more review sources`,
                description: `Access all your customer reviews, from every source, in one dashboard.`,
                icon: <DCReviewIntegrations />,
            };
        case 'review_requests':
            return {
                app: 'Reputation',
                title: `Looks like you need more review request credits`,
                description: `Collecting new reviews has never been easier. Send customers review requests via email or SMS to share their feedback.`,
                icon: <DCReviewRequest />,
            };
        case 'review_reply':
            return {
                app: 'Reputation',
                title: `Reply to all your reviews from one place`,
                description: `Take control of your brand and solve customer issues by managing all your reviews in one place.`,
                icon: <DCReviewReply />,
            };

        // Conversations
        case 'two_way_texting':
            return {
                app: 'Conversations',
                title: `Two-Way Texting`,
                description: `Our two-way SMS lets you have back-and-forth customer conversations via SMS`,
                icon: <DCConversations />,
            };
        case 'two_way_phone_calls':
            return {
                app: 'Conversations',
                title: `Two-Way Phone Calls`,
                description: `Our two-way phone calls lets you have back-and-forth customer conversations via our built in phone system.`,
                icon: <DCConversations />,
            };
        case 'voice_notes':
            return {
                app: 'Conversations',
                title: `Voice Notes`,
                description: `Easily record voice messages and send them to your customers or team for faster communication.`,
                icon: <DCConversations />,
            };
        case 'branded_live_chat':
            return {
                app: 'Conversations',
                title: `Branded Live Chat`,
                description: `Upload your logo and brand your live chat bubble for an exceptional customer experience.`,
                icon: <DCConversations />,
            };
        case 'inbox_round_robin':
            return {
                app: 'Conversations',
                title: `Inbox Round Robin Assignments`,
                description: `Automatically round-robin live chat tickets to specific team members with our inbox assignments.`,
                icon: <DCConversations />,
            };
        case 'custom_support_inboxes':
            return {
                app: 'Conversations',
                title: `Custom Support Inboxes`,
                description: `Create and segment custom support inboxes to automate your live chat tickets to the right teammates.`,
                icon: <DCConversations />,
            };
    }
};
