import React, { useEffect, useState } from 'react';
import './style.scss';
import { getUserDetails } from 'Utils/localStorageUtils';
import AccessDenied from 'Components/AccessDenied';
import { Ripple } from 'react-preloaders2';
import AffiliateCenter from './AffiliateCenter';
import { getAccountType } from 'Utils/auth';

const AffiliateCenterPage = () => {
    const [access, setAccess] = useState(true);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        (async () => {
            if (!getUserDetails().is_owner && getAccountType() !== 'main') {
                setAccess(false);
                setLoading(false);
                return;
            }
            setAccess(true);
            setLoading(false);
        })();
    }, []);

    if (loading) {
        return <Ripple customLoading={loading} />;
    }

    if (!access) {
        return <AccessDenied />;
    }

    return <AffiliateCenter />;
};

export default AffiliateCenterPage;
