import React from 'react';

import { SVGIcon } from '../../UIKit/index';

export default function DCSave(props) {
    return (
        <SVGIcon {...props} viewBox="0 0 482 512">
            <path d="M469.4,144c-3.3-9.1-8.3-17.6-14.7-24.8l-85.9-91.6c-6.6-6.7-14.6-12.1-23.3-15.7  c-8.4-4-17.6-6.3-27-6.5H34c-7.9-0.1-15.5,3.2-20.8,9.2c-5.7,6-8.8,14-8.7,22.2v439.5c-0.2,8.3,2.9,16.3,8.6,22.3  c5.3,5.9,12.9,9.3,20.8,9.2h412.2c7.9,0.1,15.5-3.2,20.8-9.2c5.7-6,8.8-14,8.6-22.3V172.8C475.3,162.9,473.2,153.1,469.4,144z   M122.9,57.7c0-2.7,1.1-5.4,3-7.3c1.8-1.9,4.3-3,6.9-3h136.8c2.6,0,5.1,1,6.9,3c1.9,2,3,4.6,3,7.3v104.6c0,2.7-1.1,5.4-3,7.3  c-1.8,1.9-4.3,3-6.9,3H132.7c-2.6,0-5.1-1-6.9-3c-1.9-2-3-4.6-3-7.3L122.9,57.7z M357.9,465.8H122.3V340.2h235.5L357.9,465.8z   M436.4,465.8h-39.3V329.6c0.2-8.3-2.9-16.3-8.6-22.3c-5.3-5.9-12.9-9.3-20.8-9.2H112.5c-7.9-0.1-15.5,3.2-20.8,9.2  c-5.7,6-8.8,14-8.6,22.3v136H43.8V47.2h39.2v136c-0.2,8.2,2.9,16.2,8.6,22.2c5.3,5.9,12.9,9.3,20.8,9.2h176.7  c7.9,0.1,15.5-3.2,20.8-9.2c5.7-6,8.8-14,8.6-22.2v-136c4.1,0.3,8.2,1.4,11.9,3.3c3.9,1.5,7.4,3.7,10.4,6.5l86.3,91.9  c2.7,3.4,4.8,7.2,6.1,11.3c1.7,4,2.7,8.3,3,12.6v293L436.4,465.8z" />
        </SVGIcon>
    );
}
