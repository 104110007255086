import React from 'react';

import { SVGIcon } from '../../UIKit/index';

export default function DCProductLater(props) {
    return (
        <SVGIcon {...props} viewBox="0 0 82 82">
            <g transform="translate(-518.805 -156.805)">
                <g transform="translate(518.805 156.805)" fill="#fff">
                    <path
                        d="M 41 80.5 C 35.6668701171875 80.5 30.49407005310059 79.45583343505859 25.62528228759766 77.39650726318359 C 20.92182350158691 75.40711975097656 16.6973762512207 72.55881500244141 13.06928253173828 68.93071746826172 C 9.441187858581543 65.30261993408203 6.59288215637207 61.07817459106445 4.603494167327881 56.37471771240234 C 2.544164657592773 51.50592803955078 1.5 46.3331298828125 1.5 41 C 1.5 35.6668701171875 2.544164657592773 30.49407005310059 4.603494167327881 25.62528228759766 C 6.59288215637207 20.92182350158691 9.441187858581543 16.6973762512207 13.06928253173828 13.06928253173828 C 16.6973762512207 9.441187858581543 20.92182350158691 6.59288215637207 25.62528228759766 4.603494167327881 C 30.49407005310059 2.544164657592773 35.6668701171875 1.5 41 1.5 C 46.3331298828125 1.5 51.50592803955078 2.544164657592773 56.37471771240234 4.603494167327881 C 61.07817459106445 6.59288215637207 65.30261993408203 9.441187858581543 68.93071746826172 13.06928253173828 C 72.55881500244141 16.6973762512207 75.40711975097656 20.92182350158691 77.39650726318359 25.62528228759766 C 79.45583343505859 30.49407005310059 80.5 35.6668701171875 80.5 41 C 80.5 46.3331298828125 79.45583343505859 51.50592803955078 77.39650726318359 56.37471771240234 C 75.40711975097656 61.07817459106445 72.55881500244141 65.30261993408203 68.93071746826172 68.93071746826172 C 65.30261993408203 72.55881500244141 61.07817459106445 75.40711975097656 56.37471771240234 77.39650726318359 C 51.50592803955078 79.45583343505859 46.3331298828125 80.5 41 80.5 Z"
                        stroke="none"
                    />
                    <path
                        d="M 41 3 C 35.86870574951172 3 30.89250564575195 4.004295349121094 26.20959854125977 5.985000610351563 C 21.6849479675293 7.898757934570313 17.62075805664063 10.63912200927734 14.12994384765625 14.12994384765625 C 10.63912200927734 17.62075805664063 7.898757934570313 21.6849479675293 5.985000610351563 26.20959854125977 C 4.004295349121094 30.89250564575195 3 35.86870574951172 3 41 C 3 46.13129425048828 4.004295349121094 51.10749053955078 5.985000610351563 55.79038619995117 C 7.898757934570313 60.31504058837891 10.63912200927734 64.37922668457031 14.12994384765625 67.87005615234375 C 17.62075805664063 71.36087799072266 21.6849479675293 74.10124206542969 26.20959854125977 76.01499938964844 C 30.89250564575195 77.99570465087891 35.86870574951172 79 41 79 C 46.13129425048828 79 51.10749053955078 77.99570465087891 55.79038619995117 76.01499938964844 C 60.31504058837891 74.10124206542969 64.37922668457031 71.36087799072266 67.87005615234375 67.87005615234375 C 71.36087799072266 64.37922668457031 74.10124206542969 60.31504058837891 76.01499938964844 55.79038619995117 C 77.99570465087891 51.10749053955078 79 46.13129425048828 79 41 C 79 35.86870574951172 77.99570465087891 30.89250564575195 76.01499938964844 26.20959854125977 C 74.10124206542969 21.6849479675293 71.36087799072266 17.62075805664063 67.87005615234375 14.12994384765625 C 64.37922668457031 10.63912200927734 60.31504058837891 7.898757934570313 55.79038619995117 5.985000610351563 C 51.10749053955078 4.004295349121094 46.13129425048828 3 41 3 M 41 0 C 63.64366912841797 0 82 18.35631942749023 82 41 C 82 63.64366912841797 63.64366912841797 82 41 82 C 18.35631942749023 82 0 63.64366912841797 0 41 C 0 18.35631942749023 18.35631942749023 0 41 0 Z"
                        stroke="none"
                        fill="#006df0"
                    />
                </g>
                <g transform="translate(528.085 166.085)">
                    <g transform="translate(0 0)">
                        <path
                            d="M28.732,11.656V7.78a1.251,1.251,0,0,0-2.5,0v3.876a1.251,1.251,0,1,0,2.5,0Z"
                            transform="translate(3.846 -6.529)"
                            fill="#d9eaff"
                        />
                        <path
                            d="M26.23,44.643v3.876a1.251,1.251,0,1,0,2.5,0V44.643a1.251,1.251,0,1,0-2.5,0Z"
                            transform="translate(3.846 12.884)"
                            fill="#d9eaff"
                        />
                        <path
                            d="M48.519,28.732a1.251,1.251,0,0,0,0-2.5H44.643a1.251,1.251,0,0,0,0,2.5Z"
                            transform="translate(12.884 3.846)"
                            fill="#d9eaff"
                        />
                        <path
                            d="M7.78,26.23a1.251,1.251,0,1,0,0,2.5h3.876a1.251,1.251,0,0,0,0-2.5Z"
                            transform="translate(-6.529 3.846)"
                            fill="#d9eaff"
                        />
                    </g>
                    <ellipse
                        cx="16.5"
                        cy="17"
                        rx="16.5"
                        ry="17"
                        transform="translate(13.719 14.719)"
                        fill="none"
                    />
                    <g transform="translate(11.895 13.877)">
                        <g isolation="isolate">
                            <path
                                d="M39.5,17.223a.833.833,0,0,0-.755-.479H36.281A18.282,18.282,0,1,0,34.14,29.222a1.663,1.663,0,0,0-2.879-1.667,14.984,14.984,0,1,1,1.617-10.811H30.433a.832.832,0,0,0-.64,1.364L33.951,23.1a.832.832,0,0,0,1.277,0l4.158-4.989A.831.831,0,0,0,39.5,17.223Z"
                                transform="translate(0 -1.775)"
                                fill="#6b8aaa"
                            />
                            <path
                                d="M21.18,6.713a1.663,1.663,0,0,0-1.663,1.663V18.468a2.908,2.908,0,0,0,1.664,5.294,2.874,2.874,0,0,0,1.228-.281l5.785,3.339a1.645,1.645,0,0,0,.83.224,1.663,1.663,0,0,0,.832-3.1L24.065,20.6a2.9,2.9,0,0,0-1.22-2.127V8.376A1.666,1.666,0,0,0,21.18,6.713Zm0,15.384a1.247,1.247,0,1,1,1.247-1.246A1.249,1.249,0,0,1,21.18,22.1Z"
                                transform="translate(-2.885 -2.555)"
                                fill="#6b8aaa"
                            />
                        </g>
                    </g>
                </g>
            </g>
        </SVGIcon>
    );
}
