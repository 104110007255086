import React, { forwardRef, useCallback, useMemo, useRef, useState } from 'react';

import { createPortal } from 'react-dom';

import DCCrossClose from 'Assets/icons/DCCrossClose';
import DCErrorOutline from 'Assets/icons/DCErrorOutline';
import DCHalfCheck from 'Assets/icons/DCHalfCheck';
import DCInfo from 'Assets/icons/DCInfo';
import DCWarning from 'Assets/icons/DCWarning';
import usePubSub from 'Hooks/PubSub';

import './style.scss';

const ToastWrapper = forwardRef((props, ref) => createPortal(
        <div ref={ref} className={props.className}>
            {props.children}
        </div>,
        document.body,
    ),
);

const ToastContainer = ({ position, toasts, removeToast }) => {
    return (
        toasts?.length > 0 && (
            <ToastWrapper
                className={`notification-container toast-container-${position || 'bottom-left'}`}
            >
                {toasts.slice(-4).map(toast => {
                    return <ToastItem toast={toast} key={toast.id} deleteT={removeToast} />;
                })}
            </ToastWrapper>
        )
    );
};

const ToastItem = ({ toast, deleteT }) => {
    return (
        <div
            className={`DCToast notification toast ${toast.position || 'bottom-left'} ${
                toast.type
            } ${toast.removed ? 'removed' : ''}`}
        >
            <div className="notification-icon">{getIcon(toast.type)}</div>
            <div className="notification-message">{toast.message}</div>
            <button className="notification-remove" onClick={() => deleteT(toast)}>
                <DCCrossClose />
            </button>
        </div>
    );
};

function getIcon(type) {
    switch (type) {
        case 'error':
            return <DCErrorOutline />;
        case 'warning':
            return <DCWarning />;
        case 'info':
            return <DCInfo />;
        case 'success':
            return <DCHalfCheck />;
        default:
            return <DCHalfCheck />;
    }
}

const Toast = () => {
    const [toastList, setToastList] = useState([]);
    const _toastList = useRef();
    _toastList.current = toastList;

    const removeToastActual = useCallback(toast => {
        setToastList(() => _toastList.current.filter(t => t.id !== toast.id));
    }, []);

    const removeToast = useCallback(
        toast => {
            setToastList(() => _toastList.current.map(t => {
                    if (t.id === toast.id) t.removed = true;
                    return t;
                }),
            );
            setTimeout(removeToastActual, 550, toast);
        },
        [removeToastActual],
    );

    usePubSub(
        'show-toast',
        useCallback(
            data => {
                console.error('Error in toast message', data);
                const toast = {
                    ...data,
                    id: Date.now(),
                    icon: getIcon(data.type || 'default'),
                    type: data.type || 'default',
                    position: data.position || 'bottom-left',
                    duration: 5000,
                    message:
                        (typeof data.message === 'string'
                            ? data.message
                            : JSON.stringify(data.message)) || 'No message.',
                };
                try {
                    if (data.noDuplicate) {
                        if (toastList.findIndex(el => el.message === data.message) !== -1) return;
                    }
                    setToastList(list => [...list, toast]);
                    setTimeout(removeToast, toast.duration, toast);
                } catch (e) {
                    console.error(e);
                }
            },
            [removeToast, toastList],
        ),
    );

    // Toasts grouped into positions such as top-left, bottom-right, etc.
    const toasts = useMemo(() => {
        const _toasts = {};
        for (const toast of toastList) {
            if (!_toasts[toast.position]) _toasts[toast.position] = [];
            _toasts[toast.position].push(toast);
        }
        return _toasts || {};
    }, [toastList]);

    return (
        <>
            <ToastContainer
                toasts={toasts['top-left'] || []}
                position={'top-left'}
                removeToast={removeToast}
            />
            <ToastContainer
                toasts={toasts['top-right'] || []}
                position={'top-right'}
                removeToast={removeToast}
            />
            <ToastContainer
                toasts={toasts['bottom-left'] || []}
                position={'bottom-left'}
                removeToast={removeToast}
            />
            <ToastContainer
                toasts={toasts['bottom-right'] || []}
                position={'bottom-right'}
                removeToast={removeToast}
            />
        </>
    );
};

Toast.propTypes = {};

export default Toast;
