import React from 'react';

import { SVGIcon } from '../../UIKit/index';

export default function DCBuilding(props) {
    return (
        <SVGIcon {...props} viewBox="0 0 512 512">
            <path d="M237.7,152.2h48.5v54.6h32V23c0-12.7-10.3-23-23-23H68.6c-12.7,0-23,10.3-23,23v466.5   c0,12.7,10.3,23,23,23c0,0,0,0,0,0h81.6V363h63.2V238c0.7-14.1,10.6-26,24.3-29.3L237.7,152.2L237.7,152.2L237.7,152.2z M237.7,46   h48.5v68.9h-48.5V46z M127,433.4H78.4v-68.9H127C127,364.5,127,433.4,127,433.4z M127,332.5H78.4v-68.8H127L127,332.5L127,332.5z    M127,221.1H78.4v-68.9H127L127,221.1L127,221.1z M127,114.9H78.4V46H127L127,114.9L127,114.9z M207.9,332.5h-48.5v-68.9h48.5   V332.5z M207.9,221.2h-48.5v-69h48.5V221.2z M207.9,114.9h-48.5V46h48.5V114.9z M447.3,225.2h-192c-10.7,0-19.5,5.8-19.5,12.9   v261.5c0,7.1,8.7,12.9,19.5,12.9h69.2v-83.8H380v83.7h67.4c10.7,0,19.5-5.7,19.5-12.9V238C466.8,230.9,458.1,225.2,447.3,225.2z    M304.7,468.2h-41.1v-38.6h41.1V468.2z M304.7,411.6h-41.1V373h41.1V411.6z M304.7,349.1h-41.1v-38.6h41.1V349.1z M304.7,289.6   h-41.1v-38.6h41.1V289.6z M373.3,411.6h-41.1V373h41.1L373.3,411.6z M373.3,349.2h-41.1v-38.6h41.1L373.3,349.2z M373.3,289.6   h-41.1v-38.7h41.1L373.3,289.6z M439.5,469.1h-41v-38.7h41.1L439.5,469.1z M439.5,411.6h-41V373h41.1L439.5,411.6z M439.5,349.2   h-41v-38.7h41.1L439.5,349.2z M439.5,289.6h-41v-38.7h41.1L439.5,289.6z" />
        </SVGIcon>
    );
}
