import React from 'react';

import { SVGIcon } from '../../UIKit/index';

export default function DCGrayInstaSite(props) {
    return (
        <SVGIcon {...props} viewBox="0 0 500.25 498.61">
            <defs>
                <linearGradient
                    id="linear-gradient"
                    x1="0.5"
                    x2="0.5"
                    y2="0.999"
                    gradientUnits="objectBoundingBox"
                >
                    <stop offset="0" stopColor="#f5f6f7" />
                    <stop offset="0.64" stopColor="#e4e4e4" />
                    <stop offset="0.98" stopColor="#e5e5e5" />
                </linearGradient>
                <linearGradient
                    id="linear-gradient-2"
                    x1="0.5"
                    y1="1"
                    x2="0.5"
                    y2="0.213"
                    gradientUnits="objectBoundingBox"
                >
                    <stop offset="0" stopColor="#f5f6f7" />
                    <stop offset="1" stopColor="#e4e4e4" />
                </linearGradient>
            </defs>
            <g id="Artboard_2" transform="translate(-8.01 -11.83)">
                <ellipse
                    id="Ellipse_6"
                    cx="160.7"
                    cy="34.45"
                    rx="160.7"
                    ry="34.45"
                    transform="translate(96.87 441.04)"
                    fill="#f5f6f7"
                    stroke="#f5f6f7"
                    strokeMiterlimit="10"
                    strokeWidth="1"
                />
                <circle
                    id="Ellipse_7"
                    cx="237.42"
                    cy="237.42"
                    r="237.42"
                    transform="translate(24.69 12.83)"
                    fill="none"
                    stroke="#717171"
                    strokeMiterlimit="10"
                    strokeWidth="2"
                />
                <rect
                    id="Rectangle_1"
                    width="111.15"
                    height="70.91"
                    transform="translate(104.14 23.74)"
                    fill="#fff"
                    stroke="#dbd9ed"
                    strokeMiterlimit="10"
                    strokeWidth="1"
                />
                <rect
                    id="Rectangle_2"
                    width="111.15"
                    height="13.41"
                    transform="translate(104.14 23.74)"
                    fill="none"
                    stroke="#dbd9ed"
                    strokeMiterlimit="10"
                    strokeWidth="1"
                />
                <path
                    id="Path_65"
                    d="M116.73,30.54a3.37,3.37,0,1,1-.987-2.393A3.38,3.38,0,0,1,116.73,30.54Z"
                    fill="#717171"
                />
                <path
                    id="Path_66"
                    d="M130.51,30.54a3.37,3.37,0,1,1-.987-2.393,3.38,3.38,0,0,1,.987,2.393Z"
                    fill="#717171"
                />
                <path
                    id="Path_67"
                    d="M144.29,30.54a3.37,3.37,0,1,1-.983-2.389A3.38,3.38,0,0,1,144.29,30.54Z"
                    fill="#717171"
                />
                <rect
                    id="Rectangle_3"
                    width="392.31"
                    height="291.95"
                    rx="18.27"
                    transform="translate(65.96 105.55)"
                    fill="#717171"
                />
                <rect
                    id="Rectangle_4"
                    width="352.68"
                    height="209.65"
                    transform="translate(85.77 124.54)"
                    fill="#fff"
                />
                <path
                    id="Path_68"
                    d="M458.27,354.44v24.8A18.26,18.26,0,0,1,440,397.5H84.22A18.25,18.25,0,0,1,66,379.24v-24.8Z"
                    fill="#f5f6f7"
                />
                <path
                    id="Path_69"
                    d="M186.41,450.13h151.4a8,8,0,0,1,8,8v6H178.45v-6a8,8,0,0,1,8-8Z"
                    fill="url(#linear-gradient)"
                />
                <path
                    id="Path_70"
                    d="M325.85,450.14H198.37l3.24-24.26.16-1.15.3-2.28.15-1.14.31-2.28.04-.35.11-.79.31-2.28.15-1.14,2.27-16.96h113.4l2.14,16,.11.77.35,2.65.1.77.14.98.22,1.67.1.77.35,2.66.11.76Z"
                    fill="url(#linear-gradient-2)"
                />
                <rect
                    id="Rectangle_5"
                    width="352.68"
                    height="11.34"
                    transform="translate(85.77 124.54)"
                    fill="#e4e4e4"
                />
                <rect
                    id="Rectangle_6"
                    width="95.29"
                    height="68.44"
                    transform="translate(107.44 169.53)"
                    fill="#e4e4e4"
                />
                <path
                    id="Path_71"
                    d="M194.5,205.27a19.38,19.38,0,0,1-34.9,11.6H168V203.16H155.87a19.37,19.37,0,0,1,38.63,2.11Z"
                    fill="#fff"
                />
                <path
                    id="Path_72"
                    d="M159.6,216.87H118.25V203.16h37.62a21,21,0,0,0-.11,2.11,19.448,19.448,0,0,0,3.838,11.6Z"
                    fill="#fff"
                />
                <rect
                    id="Rectangle_7"
                    width="200.14"
                    height="65.19"
                    transform="translate(215.36 249.62)"
                    fill="none"
                    stroke="#e4e4e4"
                    strokeMiterlimit="10"
                    strokeWidth="1"
                />
                <line
                    id="Line_1"
                    data-name="Line 1"
                    x2="200.14"
                    y2="65.19"
                    transform="translate(215.36 249.62)"
                    fill="none"
                    stroke="#e4e4e4"
                    strokeMiterlimit="10"
                    strokeWidth="1"
                />
                <line
                    id="Line_2"
                    data-name="Line 2"
                    x1="200.14"
                    y2="65.19"
                    transform="translate(215.36 249.62)"
                    fill="none"
                    stroke="#e4e4e4"
                    strokeMiterlimit="10"
                    strokeWidth="1"
                />
                <rect
                    id="Rectangle_8"
                    width="92.22"
                    height="3.35"
                    transform="translate(215.36 169.53)"
                    fill="#e4e4e4"
                />
                <rect
                    id="Rectangle_9"
                    width="92.22"
                    height="3.35"
                    transform="translate(215.36 177.46)"
                    fill="#e4e4e4"
                />
                <rect
                    id="Rectangle_10"
                    width="92.22"
                    height="3.35"
                    transform="translate(215.36 185.38)"
                    fill="#e4e4e4"
                />
                <rect
                    id="Rectangle_11"
                    width="92.22"
                    height="3.35"
                    transform="translate(215.36 193.31)"
                    fill="#e4e4e4"
                />
                <rect
                    id="Rectangle_12"
                    width="53.54"
                    height="3.35"
                    transform="translate(215.36 201.23)"
                    fill="#e4e4e4"
                />
                <rect
                    id="Rectangle_13"
                    width="92.22"
                    height="3.35"
                    transform="translate(215.36 209.16)"
                    fill="#e4e4e4"
                />
                <rect
                    id="Rectangle_14"
                    width="92.22"
                    height="3.35"
                    transform="translate(215.36 217.08)"
                    fill="#e4e4e4"
                />
                <rect
                    id="Rectangle_15"
                    width="92.22"
                    height="3.35"
                    transform="translate(215.36 225.01)"
                    fill="#e4e4e4"
                />
                <rect
                    id="Rectangle_16"
                    width="69.69"
                    height="3.35"
                    transform="translate(215.36 232.93)"
                    fill="#e4e4e4"
                />
                <rect
                    id="Rectangle_17"
                    width="95.18"
                    height="65.19"
                    transform="translate(107.54 249.62)"
                    fill="#e4e4e4"
                />
                <rect
                    id="Rectangle_18"
                    width="5.76"
                    height="5.76"
                    transform="translate(115.68 309.06)"
                    fill="#717171"
                />
                <rect
                    id="Rectangle_19"
                    width="5.76"
                    height="22.24"
                    transform="translate(127.87 292.57)"
                    fill="#717171"
                />
                <rect
                    id="Rectangle_20"
                    width="5.76"
                    height="32.6"
                    transform="translate(140.06 282.22)"
                    fill="#717171"
                />
                <rect
                    id="Rectangle_21"
                    width="5.76"
                    height="47.55"
                    transform="translate(152.26 267.26)"
                    fill="#717171"
                />
                <rect
                    id="Rectangle_22"
                    width="5.76"
                    height="59.27"
                    transform="translate(164.45 255.54)"
                    fill="#717171"
                />
                <rect
                    id="Rectangle_23"
                    width="5.76"
                    height="15.07"
                    transform="translate(176.64 299.74)"
                    fill="#717171"
                />
                <rect
                    id="Rectangle_24"
                    width="5.76"
                    height="34.37"
                    transform="translate(188.84 280.44)"
                    fill="#717171"
                />
                <path
                    id="Path_73"
                    d="M414.37,243.15l-94.16-12.78,10.9-67.71,94.16,12.79Z"
                    fill="#f5f6f7"
                />
                <path id="Path_74" d="M406.92,234.21l-33.11-4.5,23.54-45.61Z" fill="#717171" />
                <path id="Path_75" d="M368.67,219.4l-33.11-4.5,20.74-25.13Z" fill="#717171" />
                <path
                    id="Path_76"
                    d="M94.78,130.21A2.81,2.81,0,1,1,92,127.39a2.81,2.81,0,0,1,2.78,2.82Z"
                    fill="#717171"
                />
                <path
                    id="Path_77"
                    d="M106.26,130.21a2.81,2.81,0,1,1-.819-1.993,2.81,2.81,0,0,1,.819,1.993Z"
                    fill="#717171"
                />
                <path
                    id="Path_78"
                    d="M117.75,130.21a2.81,2.81,0,1,1-.819-1.993A2.82,2.82,0,0,1,117.75,130.21Z"
                    fill="#717171"
                />
                <rect
                    id="Rectangle_25"
                    width="126.77"
                    height="11.31"
                    transform="translate(288.73 147.32)"
                    fill="#e4e4e4"
                />
                <rect
                    id="Rectangle_26"
                    width="11.31"
                    height="11.31"
                    transform="translate(107.44 147.32)"
                    fill="#e4e4e4"
                />
                <rect
                    id="Rectangle_27"
                    width="11.31"
                    height="11.31"
                    transform="translate(122.94 147.32)"
                    fill="#e4e4e4"
                />
                <rect
                    id="Rectangle_28"
                    width="11.31"
                    height="11.31"
                    transform="translate(138.44 147.32)"
                    fill="#e4e4e4"
                />
                <rect
                    id="Rectangle_29"
                    width="55.32"
                    height="11.31"
                    transform="translate(169.91 147.32)"
                    fill="#e4e4e4"
                />
                <path
                    id="Path_79"
                    d="M269.1,373.08A7.12,7.12,0,1,1,262,366,7.12,7.12,0,0,1,269.1,373.08Z"
                    fill="#aba7d3"
                />
                <rect
                    id="Rectangle_30"
                    width="169.98"
                    height="55.37"
                    transform="matrix(0.886, -0.463, 0.463, 0.886, 8.01, 395.841)"
                    fill="#f5f6f7"
                />
                <path
                    id="Path_80"
                    d="M61.12,395.52a17.47,17.47,0,1,1-10.3-8.666,17.55,17.55,0,0,1,10.3,8.666Z"
                    fill="#717171"
                />
                <path
                    id="Path_81"
                    d="M49.1,396.41a6.48,6.48,0,1,1-3.809-3.194A6.49,6.49,0,0,1,49.1,396.41Z"
                    fill="#fff"
                />
                <path
                    id="Path_82"
                    d="M61.3,409.86c.13.26.25.52.36.79a17.52,17.52,0,0,1-19.53,10.21,7,7,0,0,1-.44-.75A11.064,11.064,0,0,1,61.3,409.86Z"
                    fill="#fff"
                />
                <rect
                    id="Rectangle_31"
                    width="95.23"
                    height="1.58"
                    transform="matrix(0.886, -0.463, 0.463, 0.886, 68.897, 386.517)"
                    fill="#717171"
                />
                <rect
                    id="Rectangle_32"
                    width="33.29"
                    height="1.58"
                    transform="matrix(0.886, -0.463, 0.463, 0.886, 71.261, 391.028)"
                    fill="#e4e4e4"
                />
                <rect
                    id="Rectangle_33"
                    width="95.23"
                    height="1.58"
                    transform="matrix(0.886, -0.463, 0.463, 0.886, 73.62, 395.532)"
                    fill="#e4e4e4"
                />
                <rect
                    id="Rectangle_34"
                    width="95.23"
                    height="1.58"
                    transform="matrix(0.886, -0.463, 0.463, 0.886, 75.972, 400.049)"
                    fill="#e4e4e4"
                />
                <rect
                    id="Rectangle_35"
                    width="58.22"
                    height="1.58"
                    transform="matrix(0.886, -0.463, 0.463, 0.886, 78.335, 404.548)"
                    fill="#e4e4e4"
                />
                <path
                    id="Path_83"
                    d="M66.54,372.85l2.39,1.74,2.79-.98-.92,2.81,1.79,2.35H69.64l-1.69,2.43-.9-2.81-2.84-.85,2.4-1.73Z"
                    fill="#717171"
                />
                <path
                    id="Path_84"
                    d="M77.12,367.31l2.39,1.75,2.79-.98-.92,2.81,1.8,2.35-2.96-.01-1.68,2.44-.91-2.82L74.8,372l2.39-1.73Z"
                    fill="#717171"
                />
                <path
                    id="Path_85"
                    d="M87.71,361.78l2.38,1.74,2.8-.98-.92,2.81,1.79,2.35H90.8l-1.68,2.43-.91-2.81-2.83-.85,2.4-1.73Z"
                    fill="#717171"
                />
                <path
                    id="Path_86"
                    d="M98.29,356.24l2.39,1.75,2.79-.98-.92,2.81,1.79,2.35-2.96-.01L99.7,364.6l-.9-2.82-2.84-.85,2.4-1.73Z"
                    fill="#717171"
                />
                <path
                    id="Path_87"
                    d="M108.87,350.71l2.39,1.74,2.79-.98-.92,2.81,1.79,2.35h-2.95l-1.69,2.43-.9-2.81-2.84-.85,2.4-1.73Z"
                    fill="#717171"
                />
                <circle
                    id="Ellipse_8"
                    cx="17.55"
                    cy="17.55"
                    r="17.55"
                    transform="translate(415.83 12.99)"
                    fill="#fff"
                    stroke="#e3e3e3"
                    strokeMiterlimit="10"
                    strokeWidth="2"
                />
                <path
                    id="Path_88"
                    d="M485.61,83.83h-8.25V75.59a1,1,0,0,0-1-1h0a1,1,0,0,0-1,1v8.24h-8.23a1.05,1.05,0,0,0-1,1,1,1,0,0,0,1,1h8.23v8.25a1,1,0,0,0,2,0h0V85.91h8.25a1,1,0,0,0,1-1,1.05,1.05,0,0,0-1-1.08Z"
                    fill="#e3e3e3"
                    stroke="#e3e3e3"
                    strokeMiterlimit="10"
                    strokeWidth="0.75"
                />
                <path
                    id="Path_89"
                    d="M92.45,447.73H84.2v-8.24a1,1,0,0,0-1-1h0a1,1,0,0,0-1.05,1v8.24H73.9a1.05,1.05,0,0,0-1,1,1,1,0,0,0,1,1h8.23v8.26a1.025,1.025,0,0,0,2.05,0h0V449.8h8.25a1.035,1.035,0,0,0,.02-2.07Z"
                    fill="#e3e3e3"
                    stroke="#e3e3e3"
                    strokeMiterlimit="10"
                    strokeWidth="0.75"
                />
                <rect
                    id="Rectangle_36"
                    width="110.38"
                    height="70.91"
                    transform="translate(397.38 367.56)"
                    fill="#fff"
                    stroke="#dbd9ed"
                    strokeMiterlimit="10"
                    strokeWidth="1"
                />
                <rect
                    id="Rectangle_37"
                    width="110.38"
                    height="13.41"
                    transform="translate(397.38 367.56)"
                    fill="#fff"
                    stroke="#dbd9ed"
                    strokeMiterlimit="10"
                    strokeWidth="1"
                />
                <path
                    id="Path_90"
                    d="M435.18,424.81,418.89,411a1.81,1.81,0,0,1,0-2.76l16.29-13.78a1.6,1.6,0,0,1,2.25.19h0a1.6,1.6,0,0,1-.18,2.26l-13.4,11.33a1.8,1.8,0,0,0,0,2.76l13.4,11.34a1.59,1.59,0,0,1,.18,2.25h0a1.59,1.59,0,0,1-2.25.22Z"
                    fill="#e4e4e4"
                />
                <path
                    id="Path_91"
                    d="M466.93,424.62h0a1.6,1.6,0,0,1,.19-2.25L480.52,411a1.8,1.8,0,0,0,0-2.76l-13.4-11.33a1.61,1.61,0,0,1-.19-2.26h0a1.6,1.6,0,0,1,2.26-.19l16.29,13.78a1.81,1.81,0,0,1,0,2.76l-16.29,13.78a1.6,1.6,0,0,1-2.26-.16Z"
                    fill="#e4e4e4"
                />
                <path
                    id="Path_92"
                    d="M442.42,432h0a1.6,1.6,0,0,1-.89-2.08l17.06-42.67a1.6,1.6,0,0,1,2.08-.9h0a1.6,1.6,0,0,1,.9,2.09L444.5,431.13a1.6,1.6,0,0,1-2.08.87Z"
                    fill="#e4e4e4"
                />
                <path
                    id="Path_93"
                    d="M409.2,374.36a3.38,3.38,0,1,1-3.38-3.37,3.38,3.38,0,0,1,3.38,3.37Z"
                    fill="#717171"
                />
                <path
                    id="Path_94"
                    d="M423,374.36a3.38,3.38,0,1,1-3.4-3.36,3.38,3.38,0,0,1,3.4,3.36Z"
                    fill="#717171"
                />
                <path
                    id="Path_95"
                    d="M436.76,374.36a3.38,3.38,0,1,1-3.38-3.37,3.38,3.38,0,0,1,3.38,3.37Z"
                    fill="#717171"
                />
                <path
                    id="Path_96"
                    d="M304,94a2.61,2.61,0,0,1,0-2.71c.14-.23.27-.46.4-.7s.25-.47.36-.71A2.57,2.57,0,0,1,307,88.39l3.33-.11L310,77.43l-3.33.11a2.59,2.59,0,0,1-2.34-1.37,13,13,0,0,0-.85-1.37,2.6,2.6,0,0,1-.17-2.7l1.57-2.93L295.3,64l-1.57,3a2.57,2.57,0,0,1-2.34,1.35,14.361,14.361,0,0,0-1.61.05,2.55,2.55,0,0,1-2.43-1.2l-1.76-2.83-9.23,5.73,1.77,2.84a2.6,2.6,0,0,1,0,2.7c-.14.22-.27.46-.4.69s-.25.48-.36.72a2.59,2.59,0,0,1-2.24,1.51l-3.34.1.34,10.85,3.32-.11a2.59,2.59,0,0,1,2.34,1.36,13.88,13.88,0,0,0,.86,1.38,2.6,2.6,0,0,1,.17,2.7l-1.57,2.93,9.57,5.13,1.57-2.93a2.57,2.57,0,0,1,2.34-1.34h1.63a2.55,2.55,0,0,1,2.42,1.19l1.75,2.83,9.23-5.72Zm-17.15-2.7a8.89,8.89,0,1,1,12-3.64,8.89,8.89,0,0,1-12,3.64Z"
                    fill="#f5f6f7"
                />
                <path
                    id="Path_97"
                    d="M274.35,47.54a2.3,2.3,0,0,1-1.12-2.12c0-.24,0-.47,0-.71V44a2.32,2.32,0,0,1,1.11-2.12l2.56-1.47-4.8-8.33-2.56,1.48a2.28,2.28,0,0,1-2.4-.09,11.54,11.54,0,0,0-1.23-.71,2.3,2.3,0,0,1-1.27-2V27.78H255v2.94a2.31,2.31,0,0,1-1.26,2,10.77,10.77,0,0,0-1.24.71,2.28,2.28,0,0,1-2.4.09l-2.56-1.48-4.8,8.33,2.57,1.48A2.29,2.29,0,0,1,246.45,44c0,.24,0,.48,0,.71v.71a2.3,2.3,0,0,1-1.12,2.12L242.77,49l4.8,8.33,2.55-1.47a2.28,2.28,0,0,1,2.4.08,13.81,13.81,0,0,0,1.24.72,2.29,2.29,0,0,1,1.27,2V61.6h9.61V58.7a2.29,2.29,0,0,1,1.27-2,13.812,13.812,0,0,0,1.24-.72,2.28,2.28,0,0,1,2.4-.08l2.55,1.47L276.9,49Zm-14.51,5.05a7.88,7.88,0,1,1,7.87-7.88A7.88,7.88,0,0,1,259.84,52.59Z"
                    fill="#f5f6f7"
                />
                <path
                    id="Path_98"
                    d="M309.59,92.73a2.61,2.61,0,0,1,0-2.71c.14-.22.27-.46.4-.69s.25-.48.36-.72a2.58,2.58,0,0,1,2.24-1.51l3.33-.1-.34-10.85-3.33.1a2.57,2.57,0,0,1-2.34-1.36,15,15,0,0,0-.85-1.38,2.58,2.58,0,0,1-.17-2.69l1.57-2.93-9.57-5.13-1.57,2.92A2.58,2.58,0,0,1,297,67h-1.62A2.57,2.57,0,0,1,293,65.89l-1.75-2.83L282,68.78l1.76,2.85a2.54,2.54,0,0,1,0,2.69l-.39.7c-.13.23-.25.47-.36.71a2.6,2.6,0,0,1-2.25,1.51l-3.33.1.34,10.85,3.32-.1a2.58,2.58,0,0,1,2.34,1.36c.26.47.54.93.85,1.38a2.58,2.58,0,0,1,.18,2.7l-1.57,2.92,9.57,5.14L294,98.66a2.58,2.58,0,0,1,2.34-1.35,14.544,14.544,0,0,0,1.62,0,2.58,2.58,0,0,1,2.43,1.2l1.75,2.82,9.23-5.72ZM292.46,90a8.89,8.89,0,1,1,12-3.64,8.89,8.89,0,0,1-12,3.64Z"
                    fill="#e4e4e4"
                />
                <path
                    id="Path_99"
                    d="M280,46.26a2.3,2.3,0,0,1-1.12-2.13V42.71A2.26,2.26,0,0,1,280,40.6l2.56-1.48L277.7,30.8l-2.55,1.47a2.3,2.3,0,0,1-2.4-.08,13.806,13.806,0,0,0-1.24-.72,2.28,2.28,0,0,1-1.26-2V26.5h-9.62v2.94a2.28,2.28,0,0,1-1.26,2c-.42.22-.84.46-1.24.72a2.29,2.29,0,0,1-2.39.08l-2.56-1.47-4.8,8.32,2.56,1.49a2.28,2.28,0,0,1,1.12,2.11c0,.23,0,.47,0,.71v.7a2.29,2.29,0,0,1-1.12,2.12l-2.56,1.48,4.8,8.32,2.55-1.47a2.3,2.3,0,0,1,2.4.08,10.252,10.252,0,0,0,1.24.72,2.28,2.28,0,0,1,1.26,2v3h9.62v-3a2.3,2.3,0,0,1,1.26-2,10.37,10.37,0,0,0,1.25-.72,2.3,2.3,0,0,1,2.4-.08l2.54,1.47,4.81-8.32Zm-14.51,5a7.807,7.807,0,1,1-.04.04Z"
                    fill="#e4e4e4"
                />
            </g>
        </SVGIcon>
    );
}
