import React from 'react';

import { SVGIcon } from '../../UIKit/index';

export default function DCWebGlobe(props) {
    return (
        <SVGIcon {...props} viewBox="0 0 512 512">
            <g transform="translate(1.289 9.066)">
                <g>
                    <path d="M469.13,203.72c-7.25-3.73-16.16-0.89-19.91,6.35l-17.21,33.31l-16.56-33.13    c-3.66-7.3-12.56-10.25-19.86-6.59c-2.85,1.43-5.16,3.74-6.59,6.59l-16.36,32.69l-16.36-32.69c-2.65-5.3-8.21-8.51-14.13-8.14    h-0.89c-6.42,0.4-11.85,4.91-13.42,11.15c-1.98-7.91-10.01-12.72-17.92-10.74c-4.17,1.04-7.68,3.85-9.61,7.69L284,242.95    l-16.36-32.69c-3.66-7.3-12.56-10.25-19.86-6.59c-2.85,1.43-5.16,3.74-6.59,6.59l-16.36,32.69l-16.36-32.69    c-3.67-7.29-12.55-10.22-19.84-6.56c-3.84,1.93-6.65,5.44-7.69,9.61c-1.57-6.24-7-10.75-13.42-11.15h-0.89    c-5.92-0.37-11.48,2.84-14.13,8.14L136.15,243l-16.36-32.69c-3.66-7.3-12.56-10.25-19.86-6.59c-2.85,1.43-5.16,3.74-6.59,6.59    l-16.56,33.13l-17.21-33.31c-4.04-7.1-13.07-9.59-20.18-5.55c-6.71,3.81-9.36,12.14-6.09,19.13l30.56,59.14    c3.77,7.25,12.7,10.08,19.95,6.32c2.77-1.44,5.01-3.71,6.41-6.5l16.36-32.69l16.36,32.69c3.66,7.3,12.56,10.25,19.86,6.59    c2.85-1.43,5.16-3.74,6.59-6.59l27.53-55.06c1.98-1.92,3.38-4.36,4.04-7.04c0.26,1.03,0.63,2.03,1.1,2.98l29.57,59.14    c3.66,7.3,12.56,10.25,19.86,6.59c2.85-1.43,5.16-3.74,6.59-6.59L254.43,250l16.36,32.69c3.66,7.3,12.56,10.25,19.86,6.59    c2.85-1.43,5.16-3.74,6.59-6.59l29.57-59.14c0.47-0.95,0.84-1.95,1.1-2.98c0.66,2.68,2.06,5.12,4.04,7.04l27.53,55.06    c3.66,7.3,12.56,10.25,19.86,6.59c2.85-1.43,5.16-3.74,6.59-6.59l16.36-32.69l16.36,32.69c3.66,7.3,12.56,10.26,19.86,6.59    c2.79-1.4,5.06-3.64,6.5-6.41l30.56-59.14c3.76-7.24,0.94-16.16-6.3-19.92C469.21,203.76,469.17,203.74,469.13,203.72z" />
                </g>
            </g>
            <g>
                <g>
                    <path d="M489.3,160.21C436.66,31.17,289.37-30.76,160.33,21.88C97.49,47.52,47.63,97.38,22,160.21    c-11.72,8.31-18.69,21.78-18.7,36.15v118.28c0,14.37,6.97,27.85,18.7,36.15c52.65,129.04,199.93,190.97,328.98,138.33    c62.83-25.63,112.69-75.49,138.33-138.33c11.72-8.31,18.69-21.78,18.7-36.15V196.36C508,181.99,501.03,168.52,489.3,160.21z     M452.94,152h-75.7c-7.56-36.05-22.07-70.29-42.72-100.8c-1.3-1.8-2.62-3.56-3.97-5.28C383.08,64.9,426.69,102.7,452.94,152z     M270.45,35.27c40.47,13.58,65.75,74.83,76.37,116.79h-76.37V35.27z M240.88,35.27V152h-76.39c6.83-29.84,19.13-58.15,36.27-83.5    c9.73-14.85,23.7-26.42,40.1-33.22H240.88z M180.75,46.01c-1.33,1.71-2.65,3.47-3.97,5.28c-20.63,30.48-35.14,64.69-42.72,100.71    h-75.7c26.25-49.28,69.85-87.08,122.37-106.06L180.75,46.01z M58.36,359.01h75.7c7.94,38.24,23.85,74.39,46.68,106.08    C128.22,446.11,84.61,408.31,58.36,359.01z M240.85,475.74c-40.47-13.58-65.77-74.83-76.39-116.79h76.39V475.74z M270.43,475.74    v-117h76.39c-10.64,42.1-36.11,103.51-76.37,117H270.43z M330.55,465c22.84-31.76,38.74-67.96,46.68-106.26h75.7    c-26.22,49.37-69.82,87.25-122.37,106.33L330.55,465z M478.41,314.58c0,8.17-6.62,14.8-14.8,14.8H47.67    c-8.17,0-14.8-6.62-14.8-14.8l0,0V196.36c0-8.17,6.62-14.8,14.8-14.8h415.96c8.17,0,14.8,6.62,14.8,14.8l0,0L478.41,314.58z" />
                </g>
            </g>
        </SVGIcon>
    );
}
