export const serviceReasons = [
    { id: 1, value: 'client_cancelled', title: 'Client cancelled' },
    { id: 2, value: 'not_satisfied_with_the_results', title: 'Not satisfied with the results' },
    {
        id: 3,
        value: 'order_was_not_completed_on_time',
        title: 'Order was not completed on time',
    },
    {
        id: 4,
        value: 'lack_of_communication_with_team',
        title: 'Lack of communication with team',
    },
    { id: 5, value: 'service_fees_are_too_expensive', title: 'Service fees are too expensive' },
    {
        id: 6,
        value: 'service_wasn_t_what_i_was_expecting',
        title: 'Service wasn’t what I was expecting',
    },
    { id: 7, value: 'continuing_this_in_house', title: 'Continuing this in-house' },
    {
        id: 8,
        value: 'cancelling_temporarily_will_continue_later',
        title: 'Cancelling temporarily, will continue later',
    },
    { id: 9, value: 'expectations_not_met', title: 'Expectations not met' },
    { id: 10, value: 'cannot_afford_to_continue', title: 'Cannot afford to continue' },
    { id: 11, value: 'switching_to_another_provider', title: 'Switching to another provider' },
    { id: 12, value: 'closing_my_business', title: 'Closing my business' },
    { id: 13, value: 'other', title: 'Other' },
];

export const softwareReasons = [
    { id: 0, title: 'Missing features we needed', value: 'missing_features_we_needed' },

    { id: 1, title: 'Switching to another platform', value: 'switching_to_another_platform' },
    { id: 2, title: 'Not using it enough', value: 'not_using_it_enough' },
    { id: 3, title: 'Closing my business', value: 'closing_my_business' },
    { id: 4, title: 'Too expensive', value: 'too_expensive' },
    {
        id: 5,
        title: 'Too many bugs or support issues',
        value: 'too_many_bugs_or_support_issues',
    },

    { id: 6, value: 'other', title: 'Other' },
];

export const detailedInfo = [
    {
        title: 'Listings',
        value: 'listings',
        description: [
            'This listings subscription will be deactivated on ',
            'The listings profile will no longer be active in our network and some directories will be removed over time as they regularly pull data from our Listings profile network',
            'Search engine rankings will be negatively impacted over time as directory listings will not have synchronized data, a crucial factor for SEO rankings',
            'You won’t be able to manage all active listings in one profile from the dashboard and will have to do this separately through each listing source website',
            'Overall online business presence may be reduced over time as your listing network will no longer be in sync',
            'Overall website traffic may be reduced over time since some listings that are sending website traffic will be taken down from the network',
        ],
    },
    {
        title: 'SEO',
        value: 'seo',
        description: [
            'This SEO subscription will be deactivated on ',
            'All SEO services will stop',
            'Search engine rankings will be negatively impacted over time when the SEO maintenance stops',
            'Keywords tracked in the dashboard will be reduced to 10 (or higher depending the software plan)',
            "Overall online business presence may be reduced over time since the website's SEO will not be maintained on a monthly basis",
            "Overall website traffic may be reduced over time once our team stops optimizing the website's pages, negatively impacting keyword rankings",
            'Reactivating this subscription would require paying another setup fee',
        ],
    },
    {
        title: 'Google Ads',
        value: 'google_ads',
        description: [
            'This google ads subscription will be deactivated on ',
            'All google ad campaigns that our team is working on will be turned off',
            'Overall online business presence may be reduced over time due to no new Google ads sending traffic, generating leads, or generating sales',
            'Overall website traffic may be reduced over time since traffic from active Google Ads campaigns is not being sent to the site anymore',
            'Overall metrics (CPC, CPA, etc.) may suffer over time since all campaigns will be turned off',
            'Reduced visibility on the Google Search Network will occur since all campaigns will be turned off',
            'Reactivating this subscription would require paying another setup fee',
        ],
    },
    {
        title: 'Facebook Ads',
        value: 'facebook_ads',
        description: [
            'This facebook ads subscription will be deactivated on ',
            'All facebook ad campaigns that our team is working on will be turned off',
            'Overall online business presence may be reduced over time due to no new Facebooks ads sending traffic, generating leads, or generating sales',
            'Overall website traffic may be reduced over time since traffic from active Facebook Ads campaigns is not being sent to the site anymore',
            'Overall metrics (CPC, CPA, etc.) may suffer over time since all campaigns will be turned off',
            'Reduced visibility on Facebook and/or Instagram will occur since all campaigns will be turned off',
            'Reactivating this subscription would require paying another setup fee',
        ],
    },
    {
        title: 'Social Posts',
        value: 'social_posting',
        description: [
            'This social posts subscription will be deactivated on ',
            'All social media posting services will stop and no new posts will be published on social media channels',
            'Reduced visibility on social platforms will result due to lack of social media posts',
            'Overall online business presence may be reduced over time due to no new posts being added to social media channels monthly',
            'Overall website traffic may be reduced overtime since quality posts are no longer being added to social channels with website links',
            'Overall social media engagement may be reduced over time since we’re no longer posting on a regular schedule',
        ],
    },
    {
        title: 'Content',
        value: 'content',
        description: [
            'This content subscription will be deactivated on ',
            'All content services will stop and no new blogs will be posted on the website',
            'Overall online business presence may be reduced over time due to no new content being added to the website monthly',
            'Overall website traffic may be reduced over time since we’re no longer adding quality blogs to the website every month',
            'Search engine rankings will be negatively impacted over time if posts are not added to the site regularly',
            'Reduced visibility online will occur when content is not being posted to the site regularly',
        ],
    },
    {
        title: 'Phone Number',
        value: 'phone_number',
        description: [
            'This phone number subscription will be deactivated on ',
            'You can no longer use this phone number to send/receive SMS messages or phone calls from the dashboard',
            'If you’ve been using this phone number to send outbound SMS messages or place phone calls, you will no longer receive any replies',
            'This phone number will be available for purchase by other users',
        ],
    },
    {
        title: 'Site',
        value: 'site',
        description: [
            'This sites subscription will be deactivated on ',
            'The website will be unpublished and will not be accessible online.',
            'If the website had a custom domain setup, this will no longer work anymore.',
            'The SSL certificate will be disabled.',
            'The analytics for the website will no longer be accessible from the dashboard.',
            'Any changes or updates made to the website will not be published online.',
        ],
    },
    {
        title: 'Tik Tok',
        value: 'tiktok_ads',
        description: [
            'This TikTok Ads subscription will be deactivated on ',
            'All TikTok Ad campaigns that our team is working on will be turned off.',
            'Overall online business presence may be reduced over time due to no new TikTok ads sending traffic, generating leads, or generating sales.',
            'Overall website traffic may be reduced over time since traffic from active TikTok Ads campaigns is not being sent to the site anymore.',
            'Overall metrics (CPC, CPA, etc.) may suffer over time since all campaigns will be turned off.',
            'Reduced visibility on TikTok Ads will occur since all campaigns will be turned off.',
            'Reactivating this subscription would require paying another setup fee.',
        ],
    },
];
