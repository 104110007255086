import React, { useContext } from 'react';
import EditorContext from '../editorContext/context';

const PersonaliseSpan = props => {
    const {
        children,
        styles,
        offsetKey,
        popoverRef,
        setOpenModal,
        setFallBackValue,
        setCurrentFallbackEntity,
        preview,
    } = props;
    const { fallbackEntity } = useContext(EditorContext);
    const newChildren = [
        {
            ...children[0],
            props: {
                ...children[0].props,
                text: children[0].props.text.replace(/{{|}}/g, '\u0020\u0020'),
            },
        },
    ];
    return (
        <span
            style={
                fallbackEntity?.[children[0].props.text.trim()]
                    ? styles.personalizeSaved
                    : styles.personalize
            }
            data-check={
                fallbackEntity?.[children[0].props.text] ? 'personalizeSaved' : 'personalize'
            }
            data-offset-key={offsetKey}
            ref={popoverRef}
            onClick={e => {
                if (!preview) {
                    popoverRef.current = e.target;
                    e.stopPropagation();
                    setOpenModal(true);
                    setFallBackValue(fallbackEntity?.[children[0].props.text.trim()] || '');
                    setCurrentFallbackEntity(children[0].props.text.trim());
                }
            }}
            contentEditable={false}
            // readOnly
        >
            {newChildren}
            {/* {children.decoratedText.replace(/{{|}}/g,"")} */}
        </span>
    );
};

export default PersonaliseSpan;
