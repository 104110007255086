import React, { Fragment, useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import countryCodes from 'Constants/countryCodes.json';

import { Button } from 'UIKit';
import AutoComplete from '../AutoComplete';

function CountryList({ onChange, value, readOnly }) {
    const [country, setCountry] = useState(null);

    useEffect(() => {
        if (value?.length) {
            let temp = countryCodes.find(el => el.code === value.toUpperCase());
            if (temp) {
                setCountry(temp.name);
            }
        } else {
            setCountry(null);
        }
    }, [value]);

    const generateAutoCompleteSingle = useCallback(() => {
        let temp = [];
        countryCodes.forEach(item => {
            temp.push({
                id: item._id,
                label: (
                    <Button
                        buttonClass={'ACSingleOption'}
                        onClick={() => {
                            setCountry(item.name);
                            onChange(item);
                        }}
                    >
                        {item.name}
                    </Button>
                ),
                value: item.name,
            });
        });
        return temp;
    }, [onChange]);

    return (
        <Fragment>
            <AutoComplete
                isAPICall={false}
                generateRows={generateAutoCompleteSingle}
                singleSelection
                itemHeight={38}
                height={190}
                value={country}
                readOnly={readOnly}
                placeholder={'Select Country'}
            />
        </Fragment>
    );
}

CountryList.propTypes = {
    value: PropTypes.string, // country code
    onChange: PropTypes.func,
    readOnly: PropTypes.bool,
};

export default CountryList;
