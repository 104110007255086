import React from 'react';

import { Div } from '../../UIKit/index';
import PropTypes, { any } from 'prop-types';
import './style.scss';

const Sidebar = ({ classes, children, toggleView }) => (
    <Div
        className={`PCRSidebar ${classes?.container ? classes.container : ''} ${
            toggleView ? 'sidebarView' : ''
        }`}
    >
        <Div className={`PCRSInner ${classes?.row ? classes.row : ''}`}>{children}</Div>
    </Div>
);

Sidebar.propTypes = {
    classes: PropTypes.object,
    children: PropTypes.element || any,
    toggleView: PropTypes.bool,
};
export default Sidebar;
