import { CallAPI } from 'Utils/apiCall';
import { facebookFields, FACEBOOK_STATUS_VALUES } from 'Components/Analytics/constants';
import { getCurrentDomain } from 'Utils/localStorageUtils';
import config from 'config';
const baseUrl = `${config.REACT_APP_API_BASE_URL}/${process.env.REACT_APP_API_VERSION}`;
const callrailUrl = `${baseUrl}/${process.env.REACT_APP_API_INTEGRATIONS}/${process.env.REACT_APP_API_CALLRAIL}`;
const facebookUrl = `${baseUrl}/${process.env.REACT_APP_API_INTEGRATIONS}/facebook`;
const googleAnalyticsUrl = `${baseUrl}/${process.env.REACT_APP_API_INTEGRATIONS}/google/analytics`;
const tiktokUrl = `${baseUrl}/${process.env.REACT_APP_API_INTEGRATIONS}/tiktok`;
const callTrackingMetricUrl = `${baseUrl}/${process.env.REACT_APP_API_INTEGRATIONS}/calltrackingmetrics`;
const api = new CallAPI();
import dayjs from 'dayjs';
export const getGoogleAnalytics = async (
    id,
    dimension,
    date,
    metrics,
    subAccount,
    limit = 900,
    page = 1,
    sort = '',
) => {
    let url = `${googleAnalyticsUrl}/report/${id}?dimensions=${dimension}&metrics=${metrics}&start=${encodeURIComponent(
        date.startDate,
    )}&end=${encodeURIComponent(date.endDate)}&page=${page}&limit=${limit}`;
    if (subAccount) {
        url += `&subaccountid=${subAccount}`;
    }
    if (sort) {
        url += `&sort=${sort}&order=desc`;
    }
    return api.request('GET', url);
};

export const editBusinessAddress = (id, type, body) => {
    let url = baseUrl;
    if (type === 'account') url += `/accounts/${id}`;
    else
        url += `/${process.env.REACT_APP_API_CRM}/${process.env.REACT_APP_API_CONTACTS}/${process.env.REACT_APP_CONTACTS_BUSINESSES}/${id}`;
    return api.request('PUT', url, body);
};

export const callrailUserConfig = async (data, subAccount) => {
    let url = `${callrailUrl}/auth/useranalyticsconfig`;
    if (subAccount) {
        url += `?subaccountid=${subAccount}`;
    }
    return api.request('POST', url, data);
};

export const googleAnalyticsUserConfig = async (data, subAccount) => {
    let url = `${googleAnalyticsUrl}/auth/useranalyticsconfig`;
    if (subAccount) {
        url += `?subaccountid=${subAccount}`;
    }
    return api.request('POST', url, data);
};

export const fetchGoogleAccounts = async (version, page, subAccount) => {
    let url = `${googleAnalyticsUrl}/accounts?limit=25&version=${version}`;
    if (version !== 'v4' || page !== 1) {
        url += `&${version === 'v3' ? 'page' : 'nextPageToken'}=${page}`;
    }
    if (subAccount) {
        url += `&subaccountid=${subAccount}`;
    }
    return api.request('GET', url);
};

export const getGoogleProperties = async (id, version, page, subAccount) => {
    let url = `${googleAnalyticsUrl}/${id}/properties?version=${version}&limit=25`;
    if (version !== 'v4' || page !== 1) {
        url += `&page=${page}`;
    }
    if (subAccount) {
        url += `&subaccountid=${subAccount}`;
    }
    return api.request('GET', url);
};

export const getGooglePropertiesViews = async (accountId, propertyId, page, subAccount) => {
    let url = `${googleAnalyticsUrl}/${accountId}/properties/${propertyId}/views?page=${page}&limit=25`;
    if (subAccount) {
        url += `&subaccountid=${subAccount}`;
    }
    return api.request('GET', url);
};

export const fetchFacebookLeadPagesData = async subAccount => {
    let url = `${facebookUrl}/accounts/adaccounts?fields=name,id`;
    if (subAccount) {
        url += `&subaccountid=${subAccount}`;
    }
    return api.request('GET', url);
};

export const facebookUserConfig = async (data, subAccount) => {
    let url = `${facebookUrl}/auth/useranalyticsconfig`;
    if (subAccount) {
        url += `?subaccountid=${subAccount}`;
    }
    return api.request('POST', url, data);
};

export const createEntityProfileData = async (body, params) => {
    const entity_profile_uri = `${baseUrl}/e/${process.env.REACT_APP_API_YEXT}/${process.env.REACT_APP_API_ENTITIES}`;
    return api.request(
        'POST',
        `${entity_profile_uri}?entityType=location`,
        body,
        null,
        null,
        params,
    );
};

export const getEntityProfileData = async (entityId, params) => {
    const entity_profile_uri = `${baseUrl}/e/${process.env.REACT_APP_API_YEXT}/${process.env.REACT_APP_API_ENTITIES}`;
    return api.request('GET', `${entity_profile_uri}/${entityId}`, null, null, null, params, true);
};

export const getPublisherData = async params => {
    const entity_profile_uri = `${baseUrl}/e/${process.env.REACT_APP_API_YEXT}/publishers`;
    return api.request('GET', `${entity_profile_uri}`, null, null, null, params, true);
};

export const getEntityCategories = async params => {
    const entity_category_uri = `${baseUrl}/e/${process.env.REACT_APP_API_YEXT}/${process.env.REACT_APP_API_CATEGORIES}`;
    return api.request('GET', `${entity_category_uri}`, null, null, null, params, true);
};

export const updateEntityProfileData = async (entityId, body, params) => {
    const entity_profile_uri = `${baseUrl}/e/${process.env.REACT_APP_API_YEXT}/${process.env.REACT_APP_API_ENTITIES}`;
    return api.request('PUT', `${entity_profile_uri}/${entityId}`, body, null, null, params, true);
};

export const getListingChartData = async (entityId, params) => {
    const entity_report_uri = `${baseUrl}/e/${process.env.REACT_APP_API_YEXT}/${process.env.REACT_APP_API_ENTITIES}`;
    return api.request(
        'GET',
        `${entity_report_uri}/${entityId}/report`,
        null,
        null,
        null,
        params,
        true,
    );
};

export const getAllListingData = async (entityId, params) => {
    const entity_report_uri = `${baseUrl}/e/${process.env.REACT_APP_API_YEXT}/${process.env.REACT_APP_API_ENTITIES}`;
    return api.request(
        'GET',
        `${entity_report_uri}/${entityId}/${process.env.REACT_APP_API_LISTINGS}`,
        null,
        null,
        null,
        params,
        true,
    );
};

export const createScan = body => {
    const url = `${baseUrl}/e/${process.env.REACT_APP_API_YEXT}/scan`;
    return api.request('POST', url, body);
};

// main page
export const callIntegrationStatus = async entityId => {
    let params = {};
    if (entityId) {
        params.account = entityId;
    }
    return api.request('GET', `${baseUrl}/accounts/integrations`, null, null, null, params);
};

// main page
export const callIntegrationGlobal = async () => {
    let params = { global: true };

    return api.request('GET', `${baseUrl}/accounts/integrations`, null, null, null, params);
};

// Facebook Ads

export const fetchFacebookAdsStats = async (
    accountId,
    date,
    entityId,
    campaignsIds = [],
    adsetsIds = [],
    status,
    statusTab,
    statusTabValue,
) => {
    try {
        let url = `${facebookUrl}/${
            process.env.REACT_APP_API_FACEBOOK_INSIGHTS
        }/${accountId}?level=account&fields=actions,purchase_roas,cpm,impressions,clicks,account_currency,cpc,spend,objective&sort=cpm&order=descending&since=${encodeURIComponent(
            date.startDate,
        )}&until=${encodeURIComponent(date.endDate)}`;
        if (entityId) url += `&subaccountid=${entityId}`;
        if (campaignsIds?.length) url += `&campaignids=${campaignsIds.join(',')}`;
        if (adsetsIds?.length) url += `&adsetids=${adsetsIds.join(',')}`;
        if (status && status !== 'all')
            url += `&${statusTab}=${FACEBOOK_STATUS_VALUES[statusTabValue][status].join(',')}`;
        return api.request('GET', url);
    } catch (error) {}
};

export const getFacebookAdsCampaigns = async (
    level,
    fields,
    accountId,
    date,
    after,
    limit,
    view,
    tab,
    order,
    entityId,
    status,
    campaignsIds,
    adsetsIds,
) => {
    let url = `${facebookUrl}/${
        process.env.REACT_APP_API_FACEBOOK_INSIGHTS
    }/${accountId}/${level}?since=${encodeURIComponent(date.startDate)}&until=${encodeURIComponent(
        date.endDate,
    )}&limit=${limit}`;
    if (view === 'campaigns') url += `&fields=${facebookFields.join(',')},${fields}`;
    else if (view === 'analyze') url += `&fields=${fields}`;
    if (after) url += `&after=${after}`;
    if (tab !== 'cpa') url += `&sort=${tab}&order=${order}`;
    if (entityId) url += `&subaccountid=${entityId}`;
    if (status !== 'all') url += `&status=${FACEBOOK_STATUS_VALUES[level][status].join(',')}`;
    if (campaignsIds?.length) url += `&campaignids=${campaignsIds.join(',')}`;
    if (adsetsIds?.length) url += `&adsetids=${adsetsIds.join(',')}`;
    return api.request('GET', url);
};

export const getFacebookAdsBreakdown = async (
    accountId,
    tab,
    campaignsIds = [],
    adsetsIds = [],
    date,
    breakdownFields,
    entityId,
    status,
) => {
    let url = `${facebookUrl}/breakdowns/${accountId}/${tab}?since=${dayjs(date.startDate).format(
        'YYYY-MM-DD',
    )}&until=${dayjs(date.endDate).format(
        'YYYY-MM-DD',
    )}&fields=${breakdownFields},cpm,cpc,reach,account_currency,impressions,clicks,spend,actions`;
    if (campaignsIds.length) url += `&campaignids=${campaignsIds.join(',')}`;
    if (adsetsIds.length) url += `&adsetids=${adsetsIds.join(',')}`;
    if (entityId) url += `&subaccountid=${entityId}`;
    if (status && status !== 'all')
        url += `&status=${FACEBOOK_STATUS_VALUES[`${tab}s`][status].join(',')}`;
    return api.request('GET', url);
};

const googleAdsUrl = `${baseUrl}/${process.env.REACT_APP_API_INTEGRATIONS}/google/ads`;
export const getGoogleAdsCampaignData = async (type, params) => {
    let res = await api.request('GET', `${googleAdsUrl}/${type}/metrics`, null, null, null, params);
    return { res: res.data?.results, nextPageToken: res.data?.nextPageToken };
};
export const getGoogleAdsStatsData = async (params, activeType) => {
    let type;
    switch (activeType) {
        case 'campaign':
            type = 'campaigns';
            break;
        case 'adsets':
            type = 'adgroups';
            break;
        case 'ads':
            type = 'ads';
            break;
        default:
            type = 'keywords';
            break;
    }
    let url = `${googleAdsUrl}/${type}/analyze`;
    return api.request('GET', url, null, null, null, params);
};

export const getGoogleAdsConfigData = async params => {
    let url = `${googleAdsUrl}/auth/config`;
    return api.request('GET', url, null, null, null, params);
};
export const setGoogleAdsConfigData = async (body, params) => {
    let url = `${googleAdsUrl}/auth/config`;
    return api.request('PUT', url, body, null, null, params);
};

export const getGoogleAdsCustomerData = async params => {
    let url = `${googleAdsUrl}/mcc/customers`;
    return api.request('GET', url, null, null, null, params);
};
export const getGoogleAdsManagerData = async params => {
    let url = `${googleAdsUrl}/mcc/managers`;
    return api.request('GET', url, null, null, null, params);
};

// CallRail

// Analyze Source data
export const fetchSource = async (accountId, companyId, tokenId, date, tab, clientID) => {
    let url = `${callrailUrl}/${tokenId}/accounts/${accountId}/companies/${companyId}/calls/summary?fields=total_calls,missed_calls,answered_calls,abandoned_calls,first_time_callers,average_duration,formatted_average_duration,leads`;
    url += `&start_date=${encodeURIComponent(date.startDate)}&end_date=${encodeURIComponent(
        date.endDate,
    )}`;
    if (tab && tab === 'answerscalls')
        url += '&group_by=source&sort=total_calls&order=desc&answer_status=answered';
    else if (tab && tab === 'missedcalls')
        url += '&group_by=source&sort=total_calls&order=desc&answer_status=missed';
    else if (tab && tab === 'firsttimecallers')
        url += '&group_by=source&sort=total_calls&order=desc&first_time_callers=true';
    else if (tab && tab === 'voicemails')
        url += '&group_by=source&sort=total_calls&order=desc&answer_status=voicemail';
    else if (tab && tab === 'allcalls') url += `&group_by=source&sort=total_calls&order=desc`;
    if (clientID) {
        url += `&subaccountid=${clientID}`;
    }
    return api.request('GET', url);
};

// Analyze calls volume
export const fetchCallsVolumne = async (accountId, companyId, tokenId, tab, date, clientID) => {
    let url = `${callrailUrl}/${tokenId}/accounts/${accountId}/companies/${companyId}/calls/timeline?fields=total_calls,missed_calls,answered_calls,first_time_callers,formatted_average_duration,leads`;
    url += `&start_date=${encodeURIComponent(date.startDate)}&end_date=${encodeURIComponent(
        date.endDate,
    )}`;
    if (tab === 'answerscalls') url += '&answer_status=answered';
    else if (tab === 'missedcalls') url += '&answer_status=missed';
    else if (tab === 'firsttimecallers') url += '&first_time_callers=true';
    if (clientID) {
        url += `&subaccountid=${clientID}`;
    }
    return api.request('GET', url);
};

// Calls Table
export const callsData = async (
    accountId,
    companyId,
    tokenID,
    date,
    tab,
    page,
    limit,
    search,
    sorting,
    clientID,
) => {
    let url = `${callrailUrl}/${tokenID}/accounts/${accountId}/companies/${companyId}/calls?fields=source_name,formatted_duration`;
    url += `&start_date=${encodeURIComponent(date.startDate)}&end_date=${encodeURIComponent(
        date.endDate,
    )}`;
    if (tab === 'answerscalls') url += '&answer_status=answered';
    else if (tab === 'missedcalls') url += '&answer_status=missed';
    else if (tab === 'firsttimecallers') url += '&call_type=first_call';
    if (search) url += `&search=${search}`;
    if (sorting?.name) url += `&sort=${sorting.name}&order=${sorting.order}`;
    if (clientID) {
        url += `&subaccountid=${clientID}`;
    }
    return api.request('GET', url, null, limit, page);
};

// fetch Call recording
export const fetchAudio = async (accountId, companyId, tokenId, id, clientID) => {
    let url = `${callrailUrl}/${tokenId}/accounts/${accountId}/companies/${companyId}/calls/${id}/recording`;
    if (clientID) {
        url += `?subaccountid=${clientID}`;
    }
    return api.request('GET', url);
};

// SEO

export const addConfig = async (body, params) => {
    const url = `${baseUrl}/${process.env.REACT_APP_API_INTEGRATIONS}/${process.env.REACT_APP_API_SEO}/${process.env.REACT_APP_API_RAPID}/${process.env.REACT_APP_API_CONFIG}`;
    return api.request('POST', url, body, null, null, params);
};
export const updateConfig = async (body, id, params) => {
    const url = `${baseUrl}/${process.env.REACT_APP_API_INTEGRATIONS}/${process.env.REACT_APP_API_SEO}/${process.env.REACT_APP_API_RAPID}/${process.env.REACT_APP_API_CONFIG}/${id}`;
    return api.request('PUT', url, body, null, null, params);
};
export const getConfig = async params => {
    const url = `${baseUrl}/${process.env.REACT_APP_API_INTEGRATIONS}/${process.env.REACT_APP_API_SEO}/${process.env.REACT_APP_API_RAPID}/${process.env.REACT_APP_API_CONFIG}`;
    return api.request('GET', url, null, null, null, params);
};

export const getTableData = async (id, params) => {
    const url = `${baseUrl}/${process.env.REACT_APP_API_INTEGRATIONS}/${process.env.REACT_APP_API_SEO}/${process.env.REACT_APP_API_RAPID}/${id}`;
    return api.request('GET', url, null, null, null, params);
};
export const getGraphData = async (id, params) => {
    const url = `${baseUrl}/${process.env.REACT_APP_API_INTEGRATIONS}/${process.env.REACT_APP_API_SEO}/${process.env.REACT_APP_API_RAPID}/${id}/${process.env.REACT_APP_API_GRAPH}`;
    return api.request('GET', url, null, null, null, params);
};

export const callRailTrackingAuth = async (body, subAccountId) => {
    let url = `${callTrackingMetricUrl}/auth`;
    if (subAccountId) url += `?subaccountid=${subAccountId}`;
    return api.request('POST', url, body);
};

export const callTrackingAccounts = async (tokenId, page, subAccountId) => {
    let url = `${callTrackingMetricUrl}/${tokenId}/accounts`;
    if (subAccountId) url += `?subaccountid=${subAccountId}`;
    return api.request('GET', url, null, 25, page);
};

export const callTrackingTrackers = async (tokenId, accountId, page = 1) => {
    const url = `${callTrackingMetricUrl}/${tokenId}/accounts/${accountId}/trackers`;
    return api.request('GET', url, null, 25, page);
};

export const callTrackingUserConfig = async (body, subAccount) => {
    let url = `${callTrackingMetricUrl}/auth/useranalyticsconfig`;
    if (subAccount) {
        url += `?subaccountid=${subAccount}`;
    }
    return api.request('POST', url, body);
};

export const getAllCallTracking = async (tokenID, accountId, page, limit, filter, subAccountId) => {
    let url = `${callTrackingMetricUrl}/${tokenID}/accounts/${accountId}/calls?page=${page}&limit=${limit}&${filter}`;
    if (subAccountId) {
        url += `&subaccountid=${subAccountId}`;
    }
    return api.request('GET', url);
};

export const getCallTrackStats = async (tokenID, accountId, params, subAccountId) => {
    let url = `${callTrackingMetricUrl}/${tokenID}/accounts/${accountId}/calls/summary?${params}`;
    if (subAccountId) {
        url += `&subaccountid=${subAccountId}`;
    }
    return api.request('GET', url);
};

export const getCalltrackingVolume = async (tokenID, accountId, params, subAccountId) => {
    let url = `${callTrackingMetricUrl}/${tokenID}/accounts/${accountId}/calls/timeline?${params}`;
    if (subAccountId) {
        url += `&subaccountid=${subAccountId}`;
    }
    return api.request('GET', url);
};

export const createCallTrackingWebhook = async (tokenID, accountID, campaignId) => {
    try {
        const url = `${callTrackingMetricUrl}/${tokenID}/webhook`;
        const response = await api.request('POST', url, {
            campaign_id: campaignId,
            calltracking_account_id: accountID,
        });
        if (response.success) {
            return 'success';
        }
    } catch (error) {
        return 'error';
    }
};

export const optimizationListingTasks = async (name, subaccount, taskid) => {
    let entity_profile_uri = `${baseUrl}/e/${process.env.REACT_APP_API_YEXT}/${name}`;
    let params = {};
    if (name === 'optimizationlink') {
        params = { redirect_url: `${getCurrentDomain()}/dashboard` };
    }
    if (subaccount) params = { ...params, account: subaccount };
    if (taskid) params = { ...params, taskIds: 29 };
    return api.request('GET', `${entity_profile_uri}`, null, null, null, params);
};

// tiktok

export const getAdvertisers = async subAccount => {
    let url = `${tiktokUrl}/accounts/advertisers`;
    if (subAccount) {
        url += `?subaccountid=${subAccount}`;
    }
    return api.request('GET', url);
};

export const tiktokUserConfig = async (data, subAccount) => {
    let url = `${tiktokUrl}/auth/useranalyticsconfig`;
    if (subAccount) {
        url += `?subaccountid=${subAccount}`;
    }
    return api.request('POST', url, data);
};

export const tiktokCampaigns = async (advertiser, params, paramstring) => {
    let url = `${tiktokUrl}/report?advertiser_id=${advertiser}`;
    if (paramstring) url += `&${paramstring}`;
    return api.request('GET', url, null, null, null, params);
};
