import React from 'react';

import { SVGIcon } from '../../UIKit/index';

export default function DCTotalLead(props) {
    return (
        <SVGIcon {...props} viewBox="0 0 512 426">
            <g transform="translate(0 -42.659)">
                <path
                    fill={'#4ae87e'}
                    d="M478.81,43.66H32.58c-5.87,0-10.62,4.76-10.62,10.63v276.22c-0.01,5.87,4.74,10.64,10.61,10.65   c0,0,0.01,0,0.01,0h446.23c5.87,0,10.62-4.76,10.62-10.63c0-0.01,0-0.01,0-0.02V54.29C489.42,48.42,484.67,43.66,478.81,43.66   L478.81,43.66z"
                />
                <path
                    fill={'#B8FECF'}
                    d="M500.06,341.15H11.33c-5.87,0-10.63-4.76-10.63-10.63c0-5.87,4.76-10.63,10.63-10.63h488.73   c5.87,0,10.63,4.76,10.63,10.63C510.69,336.4,505.93,341.15,500.06,341.15z"
                />
                <g transform="translate(13.212 52.539)">
                    <path
                        fill="#FFFFFF"
                        d="M157.48,224.87c-46.95,0.01-85.02-38.04-85.03-85c-0.01-46.95,38.04-85.02,85-85.03    c46.95-0.01,85.02,38.04,85.03,85c0.01,22.55-8.95,44.18-24.89,60.12C201.69,215.96,180.04,224.93,157.48,224.87z M157.48,76.12    c-35.21,0-63.76,28.54-63.76,63.76s28.54,63.76,63.76,63.76s63.76-28.54,63.76-63.76c0-16.91-6.72-33.13-18.68-45.09    C190.63,82.79,174.4,76.07,157.48,76.12L157.48,76.12z"
                    />
                    <path
                        fill="#F5F7FA"
                        d="M240.12,57.22c-4.15-4.15-10.88-4.14-15.02,0.01c-4.15,4.15-4.14,10.88,0.01,15.02    c4.15,4.14,10.87,4.14,15.02,0C244.27,68.1,244.27,61.37,240.12,57.22z"
                    />
                    <path
                        fill="#F5F7FA"
                        d="M210.07,87.27c-4.15-4.15-10.88-4.14-15.02,0.01c-4.15,4.15-4.14,10.88,0.01,15.02    c4.15,4.14,10.87,4.14,15.02,0C214.22,98.15,214.22,91.43,210.07,87.27C210.07,87.28,210.07,87.28,210.07,87.27z"
                    />
                    <path
                        fill="#F5F7FA"
                        d="M180.03,117.33c-4.15-4.15-10.88-4.14-15.02,0.01c-4.15,4.15-4.14,10.88,0.01,15.02    c4.15,4.14,10.87,4.14,15.02,0C184.18,128.21,184.18,121.48,180.03,117.33z"
                    />
                    <path
                        fill="#F5F7FA"
                        d="M149.98,147.39c-4.15-4.15-10.88-4.14-15.02,0.01c-4.15,4.15-4.14,10.88,0.01,15.02    c4.15,4.14,10.87,4.14,15.02,0C154.13,158.27,154.13,151.54,149.98,147.39z"
                    />
                    <path
                        fill="#F5F7FA"
                        d="M119.92,177.43c-4.15-4.15-10.88-4.14-15.02,0.01c-4.15,4.15-4.14,10.88,0.01,15.02    c4.15,4.14,10.87,4.14,15.02,0C124.07,188.3,124.07,181.58,119.92,177.43z"
                    />
                    <path
                        fill="#F5F7FA"
                        d="M89.87,207.48c-4.15-4.15-10.88-4.14-15.02,0.01c-4.15,4.15-4.14,10.88,0.01,15.02    c4.15,4.14,10.87,4.14,15.02,0c4.15-4.14,4.16-10.86,0.01-15.01C89.88,207.49,89.87,207.48,89.87,207.48z"
                    />
                    <path
                        fill="#FFFFFF"
                        d="M402.03,118.61H295.8c-5.87,0-10.62-4.76-10.62-10.62s4.76-10.62,10.62-10.62h106.23    c5.87,0,10.62,4.76,10.62,10.62S407.9,118.61,402.03,118.61L402.03,118.61z"
                    />
                    <path
                        fill="#FFFFFF"
                        d="M401.84,161.11H295.61c-5.87,0.14-10.74-4.5-10.89-10.36c-0.14-5.87,4.5-10.74,10.36-10.89    c0.17,0,0.35,0,0.52,0h106.23c5.87,0.14,10.51,5.02,10.36,10.89C412.07,156.41,407.51,160.97,401.84,161.11L401.84,161.11z"
                    />
                    <path
                        fill="#FFFFFF"
                        d="M348.91,203.61H295.4c-5.87,0.14-10.74-4.5-10.89-10.36c-0.14-5.87,4.5-10.74,10.36-10.89    c0.17,0,0.35,0,0.52,0h53.51c5.87-0.14,10.74,4.5,10.89,10.36c0.14,5.87-4.5,10.74-10.36,10.89    C349.26,203.61,349.08,203.61,348.91,203.61z"
                    />
                </g>
                <path
                    fill="#4AE87E"
                    d="M96.33,355.1c-41.06,0.04-74.34,33.32-74.37,74.39v28.54c0,5.86,4.75,10.61,10.62,10.62h127.52   c5.87,0,10.63-4.76,10.62-10.63c0,0,0-0.01,0-0.01v-28.51C170.68,388.42,137.4,355.13,96.33,355.1z"
                />
                <path
                    fill="#4AE87E"
                    d="M255.71,355.1c-41.07,0.03-74.35,33.32-74.39,74.39v28.54c-0.01,5.87,4.74,10.64,10.61,10.65   c0,0,0.01,0,0.01,0h127.52c5.87,0,10.62-4.76,10.62-10.63c0-0.01,0-0.01,0-0.02v-28.54C330.05,388.42,296.77,355.14,255.71,355.1z"
                />
                <path
                    fill="#4AE87E"
                    d="M415.05,355.1c-41.06,0.05-74.33,33.33-74.35,74.39v28.54c-0.01,5.87,4.73,10.63,10.6,10.65   c0.01,0,0.01,0,0.02,0h127.52c5.87,0,10.62-4.76,10.62-10.63c0-0.01,0-0.01,0-0.02v-28.54   C489.42,388.41,456.13,355.12,415.05,355.1z"
                />
                <g transform="translate(6.547 82.65)">
                    <path
                        fill="#FFFFFF"
                        stroke="#C6FCD8"
                        d="M89.03,215.98c-29.34,0-53.13,23.78-53.14,53.12c0,29.34,23.78,53.13,53.12,53.14    c29.34,0,53.13-23.78,53.14-53.12c0,0,0,0,0-0.01C142.12,239.79,118.35,216.02,89.03,215.98z"
                    />
                    <path
                        fill="#FFFFFF"
                        stroke="#C6FCD8"
                        d="M248.39,215.98c-29.34,0-53.13,23.78-53.14,53.12c0,29.34,23.78,53.13,53.12,53.14    c29.34,0,53.13-23.78,53.14-53.12c0,0,0,0,0-0.01C301.48,239.79,277.72,216.02,248.39,215.98z"
                    />
                    <path
                        fill="#FFFFFF"
                        stroke="#C6FCD8"
                        d="M407.76,215.98c-29.34-0.01-53.14,23.77-53.14,53.12c-0.01,29.34,23.77,53.14,53.12,53.14    c29.34,0.01,53.14-23.77,53.14-53.12c0,0,0-0.01,0-0.01C460.84,239.79,437.08,216.03,407.76,215.98z"
                    />
                </g>
            </g>
        </SVGIcon>
    );
}
