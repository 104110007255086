import { dealsActions } from '../Actions';

function pinnedSort(x, y) {
    if (x.pinned) return x.pinned === y.pinned ? 0 : x ? -1 : 1;
}

function createdSort(x, y) {
    if (x.created) return x.created > y.created ? -1 : x.created < y.created ? 1 : 0;
    else return x.createdAt > y.createdAt ? -1 : x.createdAt < y.createdAt ? 1 : 0;
}

const dealsReducer = (state, action) => {
    switch (action.type) {
        case dealsActions.SET_INITIAL_LOAD:
            return { ...state, initialLoad: action.value };
        case dealsActions.SET_PIPELINE_DATA:
            return {
                ...state,
                pipelines: action.value,
                pipelinesLoaded: true,
                pipelineUpdateStatus: action.status,
            };
        case dealsActions.SET_RESET_PIPELINES:
            return { ...state, pipelinesReset: action.value };
        case dealsActions.SET_PIPELINE_DATA_STATUS:
            return { ...state, pipelineUpdateStatus: false };
        case dealsActions.SET_PIPELINE_PAGINATION:
            return { ...state, pipelinePagination: action.value };

        case dealsActions.SET_TABLE_PIPELINE_DATA:
            return { ...state, pipelineTableData: action.value };
        case dealsActions.SET_TABLE_PIPELINE_PAGINATION:
            return { ...state, pipelineTablePagination: action.value };
        case dealsActions.SET_TABLE_INITIAL_LOAD:
            return { ...state, initialTableLoad: action.value };
        case dealsActions.SET_PIPELINES_LOADED:
            return { ...state, pipelinesLoaded: action.value };
        // case dealsActions.CLEAR_PAGINATION:
        //     return { ...state, pipelinesLoaded: false };
        case dealsActions.SET_COLUMNS:
            return { ...state, columns: action.value };
        case dealsActions.SET_CARD_CONFIG:
            return { ...state, cardConfig: action.value };
        case dealsActions.SET_PEOPLE_COLUMNS:
            return { ...state, peopleColumns: action.value };
        case dealsActions.SET_BUSINESSES_COLUMNS:
            return { ...state, businessColumns: action.value };
        case dealsActions.SET_PEOPLE_VISIBLE_FIELDS:
            return { ...state, peopleVisibleFields: action.value };
        case dealsActions.SET_BUSINESS_VISIBLE_FIELDS:
            return { ...state, businessVisibleFields: action.value };
        case dealsActions.SET_ACTIVE_PIPELINE_DATA:
            let total_deal_value = 0;
            if (action.value.data.stages?.length) {
                action.value.data.stages.forEach(stage => {
                    total_deal_value += Number(stage.total_deal_value);
                });
            }
            return {
                ...state,
                activePipelineData: {
                    data: { ...action.value.data, total_deal_value },
                    pipelineChanged: action.value.pipelineChanged,
                },
            };

        case dealsActions.SET_TABLE_LIMIT:
            return { ...state, tableLimit: action.value };
        case dealsActions.SET_CURR_TABLE_PAGE:
            return { ...state, tableCurrPage: action.value };

        case dealsActions.SET_ORDER:
            return { ...state, order: action.value };

        case dealsActions.SET_FIELD:
            return { ...state, field: action.value };
        case dealsActions.SET_SEARCH:
            return { ...state, search: action.value };
        case dealsActions.SET_DEALS_DATA:
            return {
                ...state,
                dealsData: { ...state.dealsData, ...action.value },
            };
        case dealsActions.SET_BOARD_PAGINATION:
            return {
                ...state,
                boardPagination: { ...state.boardPagination, ...action.value },
            };
        case dealsActions.CLEAR_DEALS_DATA:
            return {
                ...state,
                dealsData: {},
            };
        case dealsActions.CLEAR_BOARD_PAGINATION:
            return {
                ...state,
                boardPagination: {},
            };
        case dealsActions.SET_LISTVIEW_DATA:
            return {
                ...state,
                listViewData: { ...state.listViewData, ...action.value },
            };
        case dealsActions.SET_LISTVIEW_PAGINATION:
            return {
                ...state,
                listViewPagination: { ...state.listViewPagination, ...action.value },
            };
        case dealsActions.SET_LISTVIEW_LOADING:
            return {
                ...state,
                listViewLoader: { ...state.listViewLoader, ...action.value },
            };
        case dealsActions.CLEAR_LISTVIEW_DATA:
            return {
                ...state,
                listViewData: {},
            };
        case dealsActions.CLEAR_LISTVIEW_PAGINATION:
            return {
                ...state,
                listViewPagination: {},
            };
        case dealsActions.SET_NOTES:
            return {
                ...state,
                notes: {
                    data: action.value.data.sort(createdSort).sort(pinnedSort),
                    loaded: action.value.loaded,
                },
            };
        case dealsActions.SET_FILTER_NOTES:
            return {
                ...state,
                filterNotes: action.value,
            };
        case dealsActions.CLEAR_NOTES:
            return {
                ...state,
                notes: {
                    data: [],
                    loaded: false,
                },
                filterNotes: [],
            };

        case dealsActions.ADD_CONVERSATIONS: {
            return {
                ...state,
                conversations: {
                    data: action.value.data.sort(createdSort).sort(pinnedSort),
                    loaded: action.value.loaded,
                },
            };
        }
        case dealsActions.CLEAR_CONVERSATIONS:
            return {
                ...state,
                conversations: {
                    data: [],
                    loaded: false,
                },
                filterConversations: [],
            };

        case dealsActions.ADD_FILTER_CONVERSATIONS: {
            return {
                ...state,
                filterConversations: action.value,
            };
        }

        case dealsActions.SET_ACTIVITIES: {
            return {
                ...state,
                activities: {
                    data: [...state.activities.data, ...action.value.data]
                        .sort(createdSort)
                        .sort(pinnedSort),
                    nextPage: action.value.nextPage,
                    loaded: action.value.loaded ? action.value.loaded : true,
                },
            };
        }
        case dealsActions.ADD_ACTIVITY: {
            return {
                ...state,
                activities: {
                    ...state.activities,
                    data: [action.value, ...state.activities.data]
                        .sort(createdSort)
                        .sort(pinnedSort),
                },
            };
        }
        case dealsActions.UPDATE_ACTIVITY: {
            let temp = [...state.activities.data];
            const index = temp.findIndex(item => item.id === action.value.id);
            temp[index] = action.value;
            temp.sort(createdSort).sort(pinnedSort);
            return {
                ...state,
                activities: {
                    ...state.activities,
                    data: [...temp],
                },
            };
        }
        case dealsActions.DELETE_ACTIVITY: {
            let temp = state.activities.data.filter(data => data.id !== action.value);
            return {
                ...state,
                activities: {
                    ...state.activities,
                    data: [...temp],
                },
            };
        }
        case dealsActions.CLEAR_ACTIVITIES: {
            return {
                ...state,
                activities: {
                    data: [],
                    nextPage: 1,
                    loaded: false,
                },
            };
        }
        case dealsActions.SET_REMINDERS: {
            return {
                ...state,
                reminders: {
                    data: [...state.reminders.data, ...action.value.data]
                        .sort(createdSort)
                        .sort(pinnedSort),
                    nextPage: action.value.nextPage,
                    loaded: true,
                },
            };
        }
        case dealsActions.ADD_REMINDER: {
            return {
                ...state,
                reminders: {
                    ...state.reminders,
                    data: [action.value, ...state.reminders.data]
                        .sort(createdSort)
                        .sort(pinnedSort),
                },
            };
        }
        case dealsActions.UPDATE_REMINDER: {
            let temp = [...state.reminders.data];
            const index = temp.findIndex(item => item.id === action.value.id);
            temp[index] = action.value;
            temp.sort(createdSort).sort(pinnedSort);
            return {
                ...state,
                reminders: {
                    ...state.reminders,
                    data: [...temp],
                },
            };
        }
        case dealsActions.DELETE_REMINDER: {
            let temp = state.reminders.data.filter(data => data.id !== action.value);
            return {
                ...state,
                reminders: {
                    ...state.reminders,
                    data: [...temp],
                },
            };
        }
        case dealsActions.CLEAR_REMINDERS: {
            return {
                ...state,
                reminders: {
                    data: [],
                    nextPage: 1,
                    loaded: false,
                },
            };
        }
        case dealsActions.SET_FILTER: {
            return { ...state, filter: action.value };
        }
        case dealsActions.ADD_LOGS: {
            return {
                ...state,
                logs: {
                    data: action.value.data.sort(createdSort).sort(pinnedSort),
                    loaded: action.value.loaded,
                },
            };
        }
        case dealsActions.ADD_FILTER_LOGS: {
            return {
                ...state,
                filterLogs: action.value,
            };
        }
        case dealsActions.CLEAR_LOGS: {
            return {
                ...state,
                logs: {
                    data: [],
                    loaded: false,
                },
                filterLogs: [],
            };
        }

        case dealsActions.SET_SELECTED_DEAL_LOG_DATA: {
            return {
                ...state,
                selectedDealLogData: action.value,
            };
        }
        default:
            return;
    }
};

export default dealsReducer;
