import React from 'react';

import { SVGIcon } from '../../UIKit/index';

export default function DCSearch1(props) {
    return (
        <SVGIcon {...props} viewBox="0 0 512 512">
            <g transform="translate(0.3 0.3)">
                <path
                    fill="#005EFF"
                    stroke="#FFFFFF"
                    strokeWidth="7.314"
                    d="M467.6,471.7L344.7,360.6c-37.3,33.6-85.8,52.1-136,52.1c-112.6-0.1-203.9-91.4-204-204   C4.8,96.1,96.1,4.8,208.7,4.7c112.6,0.1,203.9,91.4,204,204c0.1,42.2-13.1,83.4-37.5,117.8l124.1,112.2c9.1,8.1,9.9,22,1.8,31.1   c-0.2,0.2-0.4,0.5-0.7,0.7c-4.3,4.4-10.1,7-16.2,7.2h-0.8C477.6,477.7,471.9,475.6,467.6,471.7z M42.4,208.7   c0.1,91.8,74.5,166.2,166.3,166.3c42.3,0,83-16.1,113.8-45.1c0.2-5.5,2.4-10.7,6.3-14.6c3.5-3.6,8-6,13-6.9   c21.7-28.7,33.4-63.8,33.3-99.8c-0.1-91.8-74.5-166.2-166.3-166.3C116.9,42.4,42.4,116.8,42.4,208.7L42.4,208.7z"
                />
                <g transform="translate(2.756 2.756)">
                    <g>
                        <path
                            fill="#D5E5FF"
                            d="M205.9,35.5c-94.1,0-170.4,76.3-170.4,170.4S111.9,376.3,206,376.3     c94.1,0,170.4-76.3,170.4-170.4C376.2,111.8,300,35.6,205.9,35.5z M205.9,344.8c-76.7,0-139-62.2-139-139s62.2-139,139-139     s139,62.2,139,139v0C344.8,282.6,282.6,344.8,205.9,344.8z"
                        />
                    </g>
                </g>
            </g>
        </SVGIcon>
    );
}
