import React from 'react';

import { SVGIcon } from '../../UIKit/index';

export default function DCYesNo(props) {
    return (
        <SVGIcon {...props} viewBox="0 0 512 512">
            <g transform="translate(0.199 0.2)">
                <g transform="translate(0.001)">
                    <path d="M477.14,270.72c-5.61,0-11.14,1.4-16.06,4.09c-27.44-2.34-54.22-9.66-79.03-21.6    c-4.76,0-9.46,1-13.8,2.95v-28.88h0.93h82.85c17.09-0.01,30.94-13.86,30.95-30.95V153.5c0-5.52-4.48-10-10-10    c-5.52,0-10,4.48-10,10v42.92c-0.07,6.03-4.94,10.9-10.98,10.98h-82.85h-0.93v-21.33c0-18.64-15.11-33.76-33.76-33.76    s-33.76,15.11-33.76,33.76v21.33h-14.29c-6.03-0.07-10.9-4.94-10.98-10.98V30.75c0.08-6.02,4.95-10.88,10.98-10.95H452.1    c6.03,0.07,10.9,4.94,10.98,10.98v42.88c0,5.52,4.48,10,10,10c5.52,0,10-4.48,10-10v-42.9c-0.01-17.11-13.89-30.96-30.99-30.95    c-0.02,0-0.04,0-0.07,0H286.42c-17.1-0.07-31.03,13.73-31.1,30.84c0,0.04,0,0.07,0,0.11v165.68    c0.01,17.09,13.86,30.94,30.95,30.95h14.4v84.65l-34.24,50.41c-11.54,17.01-13.29,38.84-4.6,57.48l39.99,85.16    c2.34,4.99,8.29,7.13,13.28,4.79c4.99-2.34,7.13-8.29,4.79-13.27l-39.99-85.16c-5.71-12.24-4.57-26.59,3.02-37.77l17.91-26.24    v18.97c0,5.52,4.48,10,10,10c5.52,0,10-4.48,10-10V186.07c0-7.62,6.18-13.8,13.8-13.8c7.62,0,13.8,6.18,13.8,13.8v147.41    c0,5.52,4.48,10,10,10c5.52,0,10-4.48,10-10v-46.55c0-7.62,6.18-13.8,13.8-13.8c7.62,0,13.8,6.18,13.8,13.8v46.55    c0,5.52,4.48,10,10,10c5.52,0,10-4.48,10-10v-46.55c0-7.62,6.18-13.8,13.8-13.8s13.8,6.18,13.8,13.8v46.55c0,5.52,4.48,10,10,10    c5.52,0,10-4.48,10-10v-29.02c0-7.62,6.18-13.8,13.8-13.8s13.8,6.18,13.8,13.8v83.43c0,22.2-3.37,44.27-10.02,65.45l-13.95,44.44    c-1.65,5.26,1.27,10.86,6.53,12.51c5.26,1.65,10.86-1.27,12.51-6.53l0,0l13.95-44.44c7.25-23.12,10.94-47.2,10.93-71.43v-83.43    c-0.02-18.66-15.17-33.77-33.84-33.75C477.24,270.72,477.19,270.72,477.14,270.72z" />
                    <path d="M196.51-0.2H30.83c-17.1,0-30.96,13.85-30.97,30.95v165.68    c0.01,17.09,13.86,30.94,30.95,30.95h42.92c5.52,0,10-4.48,10-10s-4.48-10-10-10h-42.9c-6.02-0.07-10.89-4.93-10.98-10.95V30.75    c0.08-6.02,4.95-10.88,10.98-10.95h165.68c6.03,0.07,10.9,4.94,10.98,10.98v165.65c-0.07,6.03-4.94,10.9-10.98,10.98h-42.92    c-5.52,0-10,4.48-10,10c0,5.52,4.48,10,10,10l0,0h42.92c17.09-0.02,30.93-13.88,30.93-30.97V30.75    C227.42,13.67,213.59-0.18,196.51-0.2z" />
                    <path d="M155.7,155.61c3.9-3.9,3.9-10.21,0-14.11c0,0,0,0,0,0l-27.99-27.99l27.99-27.99    c3.9-3.9,3.9-10.21,0-14.11c-3.9-3.9-10.21-3.9-14.11,0L113.59,99.4L85.6,71.41c-3.9-3.9-10.21-3.9-14.11,0    c-3.9,3.9-3.9,10.21,0,14.11l27.99,27.99L71.49,141.5c-3.9,3.9-3.9,10.21,0,14.11c3.9,3.9,10.21,3.9,14.11,0l27.99-27.99    l27.99,27.99C145.48,159.51,151.8,159.51,155.7,155.61C155.69,155.61,155.7,155.61,155.7,155.61z" />
                    <path d="M413.53,64.94l-49.75,49.75L342.9,93.8c-3.9-3.9-10.21-3.9-14.11,0    c-3.9,3.9-3.9,10.21,0,14.11l27.97,27.93c3.9,3.9,10.21,3.9,14.11,0c0,0,0,0,0,0l56.79-56.79c3.9-3.9,3.9-10.21,0-14.11    c-3.9-3.9-10.21-3.9-14.11,0l0,0H413.53z" />
                    <path d="M113.66,207.49c-5.52,0-10,4.47-10.01,9.99c0,5.52,4.47,10,9.99,10.01    c5.52,0.01,10-4.47,10.01-9.99c0-2.65-1.05-5.2-2.92-7.07C118.85,208.55,116.31,207.5,113.66,207.49z" />
                    <path d="M472.96,103.69c-5.52,0-10,4.47-10.01,9.99c0,5.52,4.47,10,9.99,10.01    c5.52,0,10-4.47,10.01-9.99c0-2.65-1.05-5.2-2.92-7.07C478.15,104.75,475.61,103.7,472.96,103.69z" />
                </g>
            </g>
        </SVGIcon>
    );
}
