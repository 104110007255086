import React from 'react';

import { SVGIcon } from '../../UIKit/index';

export default function DCFillEye(props) {
    return (
        <SVGIcon {...props} viewBox="0 0 512 349">
            <g>
                <path d="M255.68,105.29c-38.39,0-69.51,31.12-69.51,69.51s31.12,69.51,69.51,69.51     c38.39,0,69.51-31.12,69.51-69.51l0,0C325.17,136.42,294.06,105.31,255.68,105.29z" />
                <path d="M255.68,1C143.08,1.06,41.97,70,0.77,174.8c55.25,140.79,214.18,210.14,354.97,154.88     c70.93-27.83,127.05-83.96,154.88-154.88C469.45,69.97,368.31,1.02,255.68,1z M255.68,290.68c-64,0-115.88-51.88-115.88-115.88     c0-64,51.88-115.88,115.88-115.88S371.57,110.8,371.57,174.8C371.55,238.79,319.68,290.67,255.68,290.68z" />
            </g>
        </SVGIcon>
    );
}
