import React from 'react';

import { SVGIcon } from '../../UIKit/index';

export default function DCSwitch(props) {
    return (
        <SVGIcon {...props} viewBox="0 0 512 512">
            <g>
                <path d="M395.13,0c-31.721,0-60.453,12.801-81.532,33.391H83.478C37.435,33.391,0,70.826,0,116.87s37.435,83.478,83.478,83.478    h230.121c21.08,20.59,49.812,33.391,81.532,33.391c64.435,0,116.87-52.434,116.87-116.87S459.566,0,395.13,0z M395.13,200.348    c-46.043,0-83.478-37.435-83.478-83.478s37.435-83.478,83.478-83.478s83.478,37.435,83.478,83.478S441.174,200.348,395.13,200.348    z" />
            </g>
            <g>
                <g>
                    <path d="M428.522,311.652H198.401c-21.08-20.59-49.812-33.391-81.532-33.391C52.434,278.261,0,330.695,0,395.13    S52.434,512,116.87,512c31.721,0,60.453-12.801,81.532-33.391h230.121c46.043,0,83.478-37.435,83.478-83.478    S474.565,311.652,428.522,311.652z M116.87,478.609c-46.043,0-83.478-37.435-83.478-83.478s37.435-83.478,83.478-83.478    s83.478,37.435,83.478,83.478S162.913,478.609,116.87,478.609z" />
                </g>
            </g>
        </SVGIcon>
    );
}
