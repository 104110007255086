import React from 'react';

import { Span } from '../index';

import './style.scss';

const CircularLoader = ({}) => (
    <Span className="CircularLoader">
        <svg className="circular-loader" viewBox="25 25 50 50">
            <circle className="loader-path" cx="50" cy="50" r="20" fill="none" strokeWidth="5" />
        </svg>
    </Span>
);

export default CircularLoader;
