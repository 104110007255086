import axios from 'axios';
import { setUnAuthorized, setUnderMaintenance } from './auth';
import { getAuthDetails, updateAppVersion } from './localStorageUtils';

const errMessages = [
    'INVALID_SESSION',
    'INVALID_ACCESS_TOKEN',
    'ACCESS_TOKEN_REQUIRED',
    'INCORRECT_AUTH_HEADER',
    'REFRESH_TOKEN_NOT_ALLOWED_AS_ACCESS_TOKEN',
    'INVALID_TOKEN_TYPE',
    'SESSION_EXPIRED',
];
export class CallAPI {
    constructor() {
        this.CancelSources = [];
    }

    getLocalToken() {
        this.accessToken = getAuthDetails();
    }

    async request(method, url, body, limit, page, query, config) {
        try {
            this.getLocalToken();
            const CancelSource = axios.CancelToken.source();
            this.CancelSources.push(CancelSource);
            let params = {};
            if (limit && page) {
                params = {
                    page: page,
                    limit: limit,
                };
            }
            if (query) {
                params = { ...params, ...query };
            }
            let options = {
                headers: {
                    'x-session-id': this.accessToken.session_id,
                    'Content-Type': 'application/json',
                },
                params: params,
                cancelToken: CancelSource.token,
            };
            if (config) {
                options = { ...options, ...config };
            }
            let headers = {
                'Content-Type': 'application/json',
                'x-session-id': this.accessToken.session_id,
            };
            let response;

            if (this.accessToken.authenticated) {
                switch (method) {
                    case 'GET':
                        response = await axios.get(url, options);
                        break;
                    case 'POST':
                        response = await axios.post(url, body, options);
                        break;
                    case 'PUT':
                        response = await axios.put(url, body, options);
                        break;
                    case 'DELETE':
                        response = await axios.delete(url, {
                            headers,
                            data: body ? body : undefined,
                            params: params,
                        });
                        break;
                    case 'PATCH':
                        response = await axios.patch(url, body, {
                            headers,
                            cancelToken: CancelSource.token,
                        });
                        break;
                    default:
                        return null;
                }
                if (response.headers['x-dashboard-version']) {
                    updateAppVersion(response.headers['x-dashboard-version']);
                }
                if (response.headers['x-maintenance']) {
                    setUnderMaintenance();
                }
                return response['data'] || response;
            } else {
                return { data: [] };
            }
        } catch (error) {
            let message = error.response?.data?.message;

            if (axios.isCancel(error)) {
                message = error.message;
            }

            if (error.response?.status === 401) {
                if (errMessages.includes(message)) {
                    setUnAuthorized();
                }
            }
            if (error.response?.status === 403) {
                // setUnAuthorized();
            }
            if (error.response?.status >= 500) {
                return Promise.reject(new Error('Server Error: Please try again in some time.'));
            }

            if (error.isAxiosError) {
                if (error.response) {
                    if (error.response.data) {
                        if (error.response.data.message) {
                            message = error.response.data.message;
                        } else if (error.response.data.additional_info) {
                            message = error.response.data.additional_info;
                        } else {
                            message = error.response.data;
                        }
                    } else if (error.response.message) {
                        message = error.response.message;
                    }
                }
            }
            if (errMessages.includes(message)) {
                return Promise.reject('Session Expired. Please login again.');
            }
            return Promise.reject(message);
        }
    }

    async requestPublic(method, url, body, limit, page) {
        try {
            const CancelSource = axios.CancelToken.source();
            this.CancelSources.push(CancelSource);
            let params;
            if (limit && page) {
                params = {
                    page: page,
                    limit: limit,
                };
            }
            let options = {
                headers: {
                    'Content-Type': 'application/json',
                },
                params: params,
                cancelToken: CancelSource.token,
            };
            let headers = {
                'Content-Type': 'application/json',
            };
            let response;
            switch (method) {
                case 'GET':
                    response = await axios.get(url, options);
                    break;
                case 'POST':
                    response = await axios.post(url, body, options);
                    break;
                case 'PUT':
                    response = await axios.put(url, body, {
                        headers,
                        cancelToken: CancelSource.token,
                        params,
                    });
                    break;
                case 'DELETE':
                    response = await axios.delete(url, options);
                    break;
                case 'PATCH':
                    response = await axios.patch(url, body, {
                        headers,
                        cancelToken: CancelSource.token,
                    });
                    break;
                default:
                    return null;
            }
            if (response.headers['x-maintenance']) {
                setUnderMaintenance();
            }
            return response['data'] || response;
        } catch (error) {
            let message = error.message;
            if (error.isAxiosError) {
                if (error.response) {
                    if (error.response.data) {
                        if (error.response.data.message) {
                            message = error.response.data.message;
                        } else {
                            message = error.response.data;
                        }
                    } else if (error.response.message) {
                        message = error.response.message;
                    }
                }
            }
            return Promise.reject(message);
        }
    }

    cancelAllRequests() {
        this.CancelSources.forEach(source => {
            source.cancel('Cancelled');
        });
        this.CancelSources = [];
    }
}
