import React from 'react';

import { SVGIcon } from '../../UIKit/index';

export default function DCSend(props) {
    return (
        <SVGIcon {...props} viewBox="0 0 512 512">
            <g>
                <g>
                    <path
                        d="M507.6,4.4c-4.2-4.2-10.6-5.5-16.2-3.3L9.4,193.9c-5.5,2.2-9.2,7.5-9.4,13.4c-0.2,5.9,3.1,11.4,8.4,14
			l190.1,92.2l92.2,190.1c2.5,5.2,7.8,8.5,13.5,8.5c0.2,0,0.4,0,0.5,0c5.9-0.2,11.2-3.9,13.4-9.4l192.8-482
			C513.2,15,511.9,8.6,507.6,4.4z M52.1,209.1l382.6-153l-228,228L52.1,209.1z M302.9,459.9l-75-154.6l228-228L302.9,459.9z"
                    />
                </g>
            </g>
        </SVGIcon>
    );
}
