import React from 'react';
import PropTypes from 'prop-types';
import { Div, CircularLoader } from '../index';
// import DCCheck from '../../Assets/icons/DCCheck';

import './style.scss';

import dayjs from 'dayjs';

const UpdateViewText = ({ customClass, time, loading, loadingText, clicked }) => {
    return (
        <Div className={'UpdateViewText' + (customClass ? customClass : '')}>
            {loading ? (
                <Div className={'UVTLoading'}>
                    <CircularLoader />
                    <Div className={'UVTLText'}>{loadingText || 'Saving changes...'}</Div>
                </Div>
            ) : null}
            {!loading && time !== '' ? (
                <Div className={'UVTAfterLoading'}>
                    {/* <Div className={'UVTALIcon'}>
                        <DCCheck />s
                    </Div> */}
                    <Div className={'UVTALText'}>
                        {clicked ? 'Saved' : 'Auto Saved'} at {dayjs(time).format('h:mm a')}
                    </Div>
                </Div>
            ) : null}
        </Div>
    );
};

UpdateViewText.propTypes = {
    customClass: PropTypes.string,
    time: PropTypes.string || PropTypes.number,
    loading: PropTypes.bool,
    loadingText: PropTypes.string,
};

export default UpdateViewText;
