import React, { Fragment, useState, useCallback, useEffect, useRef } from 'react';
import { Div, Image, Input, IconButton, Link, Button, Span } from '../../UIKit/index';
import DCRightArrow from '../../Assets/icons/DCRightArrow';
import DCAgency from '../../Assets/icons/DCAgency';
import DCAnalytics from '../../Assets/icons/DCAnalytics';
import DCContacts from '../../Assets/icons/DCContacts';
import DCDashboardMenu from '../../Assets/icons/DCDashboardMenu';
import DCDealMenu from '../../Assets/icons/DCDealMenu';
import DCForms from '../../Assets/icons/DCForms';
import DCInbound from '../../Assets/icons/DCInbound';
import DCProject from '../../Assets/icons/DCProject';
import DCTemplates from '../../Assets/icons/DCTemplates';
import MacView from '../../Assets/images/macView.png';
import { useQuery } from 'react-query';
import { getBrandingData, sendSubAccountEmail } from 'apis/login/index.api';
import generateImage from 'Utils/generateImage';
import DCArrowBack from 'Assets/icons/DCArrowBack';
import { Redirect, useHistory } from 'react-router-dom';
import useToast from 'Modules/Toasts';
import DCCrossClose from 'Assets/icons/DCCrossClose';
import Slider from 'react-slick';
import UseMediaQuery from 'UIKit/useMediaQuery';
import './style.scss';
import DCReputation from 'Assets/icons/DCReputation';
import { Validation } from 'Utils/validator';
import { SliderImages } from './constant';

const Signup = () => {
    const history = useHistory();

    const { showToast } = useToast();

    const [subAccountMail, setSubAccountMail] = useState('');
    const [err, setErr] = useState(false);
    const [loading, setLoading] = useState(false);
    const matches860 = UseMediaQuery('(min-width:860px)');

    const [errorStatus, setErrorStatus] = useState(false);
    const [primaryColor, setPrimaryColor] = useState('#fff');
    const [secondaryColor, setSecondaryColor] = useState('#fff');
    const [textColor, setTextColor] = useState('#fff');
    const [companyLogo, setCompanyLogo] = useState();
    const { data } = useQuery(
        ['signup-sub'],
        async () => {
            return getBrandingData(window.location.href.split('signup/')[1]).then(res => {
                if (!res?.data?.hasOwnProperty('allow_public_sub_account')) {
                    res.data['allow_public_sub_account'] = false;
                }
                if (!res?.data?.hasOwnProperty('allow_public_sub_account_auto_approve')) {
                    res.data['allow_public_sub_account_auto_approve'] = false;
                }
                return res;
            });
        },
        {
            staleTime: 1000 * 60 * 10, // 10 minutes
            cacheTime: 1000 * 60 * 15, // 15 minutes
            keepPreviousData: true,
            onError: err => {
                setErrorStatus(true);
                showToast({
                    type: 'error',
                    message: err || err?.message || 'Something went wrong',
                });
            },
        },
    );

    const handleSubAccountEmail = useCallback(e => {
        const validation = Validation('email', e.target.value);
        if (!validation) {
            setErr(true);
        } else {
            setErr(false);
        }
        setSubAccountMail(e.target.value);
    }, []);

    const firstTimeRef = useRef(false);
    const [animationTrue, setAnimationTrue] = useState(false);

    useEffect(() => {
        if (!firstTimeRef.current) {
            setTimeout(() => {
                setAnimationTrue(true);
            }, 1500);
            firstTimeRef.current = true;
        }
    }, []);

    const [brandingData, setBarandingData] = useState({});

    useEffect(() => {
        if (data?.data) {
            setBarandingData({ ...data?.data });
            const {
                colors: { primary, secondary, font_primary },
                images: { favicon },
            } = data?.data;
            setPrimaryColor(primary);
            setSecondaryColor(secondary);
            setTextColor(font_primary);
            setCompanyLogo(favicon);
        }
    }, [data?.data]);

    const isValidEmail = email => {
        return /\S+@\S+\.\S+/.test(email);
    };

    const handleSendSubAccountCreationRequest = useCallback(async () => {
        try {
            let accountId = window.location.href.split('signup/')[1];
            let body = {
                email: subAccountMail,
                parent_account: accountId,
            };
            if (!isValidEmail(body?.email)) {
                setErr(true);
                return;
            }
            setLoading(true);
            const res = await sendSubAccountEmail(body);
            if (res.success) {
                showToast({
                    type: 'success',
                    message:
                        'Email is sent to your mail-id for email verification and sub-account creation',
                });
                setLoading(false);
                setSubAccountMail('');
                history.push({
                    pathname: '/sign-up-active',
                    state: {
                        emailVerified: true,
                        email: subAccountMail,
                        parent_account: accountId,
                    },
                });
            }
            setLoading(false);
        } catch (err) {
            showToast({
                type: 'error',
                message: err || err?.message || 'Something went wrong!',
            });
            setLoading(false);
        }
    }, [subAccountMail, showToast, history]);

    return (
        <Fragment>
            {(brandingData &&
                brandingData?.hasOwnProperty('allow_public_sub_account') &&
                !brandingData?.allow_public_sub_account) ||
            errorStatus ? (
                <Redirect to={errorStatus ? '/' : '/signup-url-not-active'} />
            ) : (
                <Div className="SignUpPage" style={{ color: textColor }}>
                    <Div className="SUPInner" style={{ backgroundColor: primaryColor }}>
                        <Div className="SUPICol" style={{ backgroundColor: primaryColor }}>
                            <Div className="SUPIFormSection">
                                <Div className="SUPIFSBack">
                                    <Button
                                        iconName={<DCArrowBack />}
                                        onClick={() => {
                                            window.location.href =
                                                window.location.href.split('/sub-account')[0];
                                        }}
                                        style={{
                                            margin: `${matches860 ? '15px' : '0'}`,
                                            alignSelf: 'flex-start',
                                            backgroundColor: secondaryColor,
                                            color: textColor,
                                            display: 'flex',
                                            lineheight: 'normal',
                                            borderRadius: '50px',
                                            padding: '10px 15px',
                                            fontSize: '14px',
                                            fontWeight: '500',
                                            border: `1px solid ${secondaryColor}`,
                                        }}
                                    >
                                        Back to Home
                                    </Button>
                                </Div>
                                <Div className="SUPIFSScreen">
                                    <Image
                                        src={MacView}
                                        alt={''}
                                        className="SUPIFSSVImg"
                                        displayName={''}
                                    />
                                    <Div className="SUPIFSSWeb">
                                        <Div className="SUPIFSSWVImg">
                                            <Slider
                                                dots={true}
                                                autoplay={true}
                                                autoplaySpeed={3000}
                                                arrows={false}
                                                infinite={true}
                                                adaptiveHeight={true}
                                                speed={500}
                                                slidesToShow={1}
                                                slidesToScroll={1}
                                                slidesPerRow={1}
                                                rows={1}
                                                dotsClass={'slick-dots slider-dot25'}
                                            >
                                                {SliderImages.map((item, index) => (
                                                    <Div
                                                        className={'SSPTGSlide'}
                                                        key={index}
                                                        onClick={() => {}}
                                                    >
                                                        <Div className={'SSPTGSImg'}>
                                                            <Image
                                                                className={'SUPIFSSWVImg'}
                                                                src={item?.image}
                                                                alt={''}
                                                                displayName={''}
                                                            />
                                                        </Div>
                                                    </Div>
                                                ))}
                                            </Slider>
                                        </Div>
                                    </Div>
                                </Div>
                                <Div className="SUPIFSInner">
                                    <Div className="SUPIFSIIcon">
                                        <Image
                                            src={generateImage(companyLogo)}
                                            alt={''}
                                            displayName={''}
                                        />
                                    </Div>
                                    <Div className="SUPIFSITitle" style={{ color: textColor }}>
                                        Create your Dashboard account
                                    </Div>
                                    <Div className="SUPIFSISTitle" style={{ color: textColor }}>
                                        Forever free. No credit card required.
                                    </Div>
                                    <Div className="SUPIFSISForm">
                                        <Input
                                            placeholder={'Email address'}
                                            onChange={handleSubAccountEmail}
                                            value={subAccountMail}
                                        />
                                        <IconButton
                                            disabled={loading}
                                            onClick={() => {
                                                handleSendSubAccountCreationRequest();
                                            }}
                                            style={{
                                                backgroundColor: secondaryColor,
                                                border: `1px solid ${secondaryColor}`,
                                            }}
                                        >
                                            <DCRightArrow style={{ color: textColor }} />
                                        </IconButton>
                                    </Div>
                                    {err ? (
                                        <Div className="LPIFSIFBFError">
                                            <Span>Enter a valid email</Span>
                                            <IconButton onClick={() => setErr(false)}>
                                                <DCCrossClose />
                                            </IconButton>
                                        </Div>
                                    ) : (
                                        ''
                                    )}
                                    <Div className="SUPIFSISIn" style={{ color: textColor }}>
                                        Already have an account?{' '}
                                        <Link href={'/login'} style={{ color: secondaryColor }}>
                                            Sign in
                                        </Link>
                                    </Div>
                                </Div>
                            </Div>
                        </Div>
                        <Div className="SUPICols">
                            {animationTrue && (
                                <Div className="SUPIAppSection">
                                    <Div
                                        className={`SUPIASInner ${animationTrue ? 'animate' : ''}`}
                                    >
                                        <Div className="SUPIASIIcon SUPIASII1">
                                            <Div className="SUPIASIInner SUPIASII1">
                                                <DCAgency />
                                                <Div>Sites</Div>
                                            </Div>
                                        </Div>
                                        <Div className="SUPIASIIcon SUPIASII2">
                                            <Div className="SUPIASIInner SUPIASII2">
                                                <DCAnalytics />
                                                <Div>Analytics</Div>
                                            </Div>
                                        </Div>
                                        <Div className="SUPIASIIcon SUPIASII3">
                                            <Div className="SUPIASIInner SUPIASII3">
                                                <DCContacts />
                                                <Div>Contacts</Div>
                                            </Div>
                                        </Div>
                                        <Div className="SUPIASIIcon SUPIASII4">
                                            <Div className="SUPIASIInner SUPIASII4">
                                                <DCDashboardMenu />
                                                <Div>Dashboard</Div>
                                            </Div>
                                        </Div>
                                        <Div className="SUPIASIIcon SUPIASII5">
                                            <Div className="SUPIASIInner SUPIASII5">
                                                <DCDealMenu />
                                                <Div>Deals</Div>
                                            </Div>
                                        </Div>
                                        <Div className="SUPIASIIcon SUPIASII6">
                                            <Div className="SUPIASIInner SUPIASII6">
                                                <DCForms />
                                                <Div>Forms</Div>
                                            </Div>
                                        </Div>
                                        <Div className="SUPIASIIcon SUPIASII7">
                                            <Div className="SUPIASIInner SUPIASII7">
                                                <DCInbound />
                                                <Div>Inbound</Div>
                                            </Div>
                                        </Div>
                                        <Div className="SUPIASIIcon SUPIASII8">
                                            <Div className="SUPIASIInner SUPIASII8">
                                                <DCProject />
                                                <Div>Projects</Div>
                                            </Div>
                                        </Div>
                                        <Div className="SUPIASIIcon SUPIASII11">
                                            <Div className="SUPIASIInner SUPIASII9">
                                                <DCTemplates />
                                                <Div>Templates</Div>
                                            </Div>
                                        </Div>
                                        <Div className="SUPIASIIcon SUPIASII12">
                                            <Div className="SUPIASIInner SUPIASII2">
                                                <DCReputation />
                                                <Div>Reputation</Div>
                                            </Div>
                                        </Div>
                                    </Div>
                                </Div>
                            )}
                        </Div>
                    </Div>
                </Div>
            )}
        </Fragment>
    );
};

export default Signup;
