import React from 'react';

import { SVGIcon } from '../../UIKit/index';

export default function DCAlarm(props) {
    return (
        <SVGIcon {...props} viewBox="0 0 31.183 31.183">
            <g>
                <path d="M15.591,2.359C8.046,2.359,1.93,8.476,1.93,16.02c0,3.743,1.507,7.132,3.945,9.6c-0.434,0.556-1.101,1.408-2.016,2.583    c-0.5,0.642-0.384,1.565,0.257,2.066c0.269,0.209,0.587,0.311,0.904,0.311c0.438,0,0.873-0.194,1.163-0.566    c0,0,1.232-1.582,1.979-2.536c2.14,1.392,4.688,2.207,7.43,2.207s5.291-0.815,7.432-2.207c0.746,0.954,1.978,2.536,1.978,2.536    c0.291,0.372,0.726,0.566,1.163,0.566c0.316,0,0.636-0.102,0.903-0.311c0.642-0.501,0.757-1.426,0.257-2.066    c-0.915-1.175-1.582-2.028-2.016-2.584c2.437-2.467,3.943-5.855,3.943-9.599C29.252,8.476,23.136,2.359,15.591,2.359z     M4.562,16.02c0-6.081,4.949-11.029,11.03-11.029c6.083,0,11.03,4.948,11.03,11.029c0,6.084-4.946,11.033-11.03,11.033    C9.509,27.053,4.562,22.104,4.562,16.02z" />
                <path d="M16.492,14.167l0.007-3.83c0-0.485-0.391-0.878-0.876-0.879c-0.484-0.001-0.877,0.391-0.878,0.877l-0.007,3.806    c-0.472,0.215-0.851,0.598-1.053,1.076l-6.351-0.012c-0.485-0.001-0.877,0.393-0.878,0.878c-0.001,0.48,0.39,0.875,0.875,0.876    l6.433,0.011c0.344,0.663,1.029,1.12,1.828,1.12c1.145,0,2.068-0.927,2.068-2.069C17.659,15.203,17.183,14.502,16.492,14.167z" />
                <path d="M1.583,2.154c-2.013,1.999-2.105,5.191-0.277,7.303l7.583-7.521C6.791,0.092,3.594,0.158,1.583,2.154z" />
                <path d="M29.603,2.154c-2.016-1.995-5.208-2.062-7.307-0.217l7.581,7.521C31.705,7.345,31.614,4.153,29.603,2.154z" />
            </g>
        </SVGIcon>
    );
}
