import React from 'react';

import { SVGIcon } from '../../UIKit/index';

export default function DCGraySubAccount(props) {
    return (
        <SVGIcon {...props} viewBox="0 0 460.86 498.61">
            <defs>
                <clipPath id="clip-path">
                    <path
                        id="Path_163"
                        d="M448.79,259.86a36.56,36.56,0,1,1-36.56-36.56A36.56,36.56,0,0,1,448.79,259.86Z"
                        fill="#f4f5f6"
                    />
                </clipPath>
                <clipPath id="clip-path-2">
                    <path
                        id="Path_178"
                        d="M155,259.86a36.56,36.56,0,1,1-36.56-36.56A36.56,36.56,0,0,1,155,259.86Z"
                        fill="#f4f5f6"
                    />
                </clipPath>
                <clipPath id="clip-path-3">
                    <path
                        id="Path_193"
                        d="M177.18,153.88a36.56,36.56,0,1,1-36.56-36.56A36.56,36.56,0,0,1,177.18,153.88Z"
                        fill="#f4f5f6"
                    />
                </clipPath>
                <clipPath id="clip-path-4">
                    <path
                        id="Path_208"
                        d="M426.14,153.88a36.731,36.731,0,1,0,0,.007Z"
                        fill="#f4f5f6"
                    />
                </clipPath>
                <clipPath id="clip-path-5">
                    <circle
                        id="Ellipse_14"
                        cx="36.56"
                        cy="36.56"
                        r="36.56"
                        transform="translate(228.54 49.02)"
                        fill="#f4f5f6"
                    />
                </clipPath>
            </defs>
            <g id="Online_community_" transform="translate(-26.52 -5.71)">
                <g id="Layer_1">
                    <circle
                        id="Ellipse_9"
                        cx="228.93"
                        cy="228.93"
                        r="228.93"
                        transform="translate(28.02 7.21)"
                        fill="#fff"
                        stroke="#707171"
                        strokeMiterlimit="10"
                        strokeWidth="3"
                    />
                    <path
                        id="Path_120"
                        d="M396.26,402.31c0,11.58-62.37,21-139.3,21s-139.31-9.38-139.31-21,62.37-21,139.31-21S396.26,390.73,396.26,402.31Z"
                        fill="#f4f5f6"
                    />
                    <rect
                        id="Rectangle_89"
                        width="252.23"
                        height="11.72"
                        transform="translate(133.01 388.92)"
                        fill="#585959"
                    />
                    <rect
                        id="Rectangle_90"
                        width="201.05"
                        height="144.92"
                        rx="10.32"
                        transform="translate(156.93 213.65)"
                        fill="#e3e2e3"
                    />
                    <rect
                        id="Rectangle_91"
                        width="197.14"
                        height="141.03"
                        rx="9.96"
                        transform="translate(158.88 215.6)"
                        fill="#707171"
                    />
                    <path
                        id="Path_121"
                        d="M139.22,392c-10,0-4.94-5.77-4.94-7l24.61-23.24c2.89-2.31,4.46-2.31,10-2.31H346.07c5.5,0,7.05.68,10,2.31l27.26,23.91c2.53,1.72,4.28,6.37-5.89,6.37Z"
                        fill="#707171"
                    />
                    <path
                        id="Path_122"
                        d="M165.61,362.75H350.54l17.19,14.45H150.53Z"
                        fill="#f4f5f6"
                    />
                    <path id="Path_123" d="M237.51,381.35h42.75l3.97,4.97H234.02Z" fill="#e3e2e3" />
                    <rect
                        id="Rectangle_92"
                        width="179.55"
                        height="122.41"
                        transform="translate(167.68 224.91)"
                        fill="#fff"
                    />
                    <path id="Path_124" d="M167.68,224.91V347.32H347.23Z" fill="#f4f5f6" />
                    <rect
                        id="Rectangle_93"
                        width="17.88"
                        height="10.64"
                        transform="translate(248.51 299.51)"
                        fill="#e3e2e3"
                    />
                    <path
                        id="Path_125"
                        d="M259.84,308.72c-3.75-1.19-6.89-3.54-7.37-7.66a3.73,3.73,0,0,1,.15-1.55h-4.11v10.64H266.4v-1.34a12.07,12.07,0,0,1-6.56-.09Z"
                        fill="#d9d9d9"
                    />
                    <path
                        id="Path_126"
                        d="M302.22,265.38a8.25,8.25,0,1,1-8.24-8,8.11,8.11,0,0,1,8.24,8Z"
                        fill="#e3e2e3"
                    />
                    <g id="Group_2">
                        <path
                            id="Path_127"
                            d="M295,261a2.46,2.46,0,0,1,.56,0,4.36,4.36,0,0,1,1.46.29,3.54,3.54,0,0,1,1.75,1.34c.12.18.2.38.31.58a2.7,2.7,0,0,1,.19.63l.07.66v.66a6,6,0,0,1-.26,1.24,5.413,5.413,0,0,1-.51,1.12A4.17,4.17,0,0,1,297,269a3.089,3.089,0,0,1-.8.33,5.073,5.073,0,0,1-.64.11H295l.51-.21c.16-.06.33-.18.53-.28a3.8,3.8,0,0,0,.61-.45,4.522,4.522,0,0,0,.58-.64,6,6,0,0,0,.49-.83,7.57,7.57,0,0,0,.35-1,4.611,4.611,0,0,0,.18-1.06v-1.02a4.518,4.518,0,0,1-.11-.47c-.07-.15-.11-.3-.18-.44a3.69,3.69,0,0,0-1.23-1.28,7.21,7.21,0,0,0-1.23-.63A4.674,4.674,0,0,0,295,261Z"
                            fill="#d9d9d9"
                        />
                    </g>
                    <path
                        id="Path_128"
                        d="M296.11,265.77a1.35,1.35,0,1,1-1.34-1.54A1.45,1.45,0,0,1,296.11,265.77Z"
                        fill="#d9d9d9"
                    />
                    <ellipse
                        id="Ellipse_10"
                        cx="8.24"
                        cy="7.96"
                        rx="8.24"
                        ry="7.96"
                        transform="translate(213.82 257.42)"
                        fill="#e3e2e3"
                    />
                    <g id="Group_3">
                        <path
                            id="Path_129"
                            d="M221,261a2.46,2.46,0,0,0-.56,0,4.32,4.32,0,0,0-1.46.29,3.54,3.54,0,0,0-1.75,1.34,5.327,5.327,0,0,0-.31.58c-.07.2-.13.41-.19.63l-.07.66v.66a6,6,0,0,0,.26,1.24,5.41,5.41,0,0,0,.51,1.12A4.17,4.17,0,0,0,219,269a3.089,3.089,0,0,0,.8.33,5.071,5.071,0,0,0,.64.11H221l-.51-.21c-.16-.06-.33-.18-.53-.28a3.8,3.8,0,0,1-.61-.45,4,4,0,0,1-.58-.64,5.18,5.18,0,0,1-.49-.83,7.569,7.569,0,0,1-.35-1,4.609,4.609,0,0,1-.18-1.06v-1.02a1.892,1.892,0,0,1,.11-.47,2.394,2.394,0,0,1,.18-.44,3.66,3.66,0,0,1,1.22-1.28,8,8,0,0,1,1.23-.63A4.328,4.328,0,0,1,221,261Z"
                            fill="#d9d9d9"
                        />
                    </g>
                    <path
                        id="Path_130"
                        d="M219.93,265.77a1.35,1.35,0,1,0,1.34-1.54,1.45,1.45,0,0,0-1.34,1.54Z"
                        fill="#d9d9d9"
                    />
                    <path
                        id="Path_131"
                        d="M294,254.88c0,27.05-12.69,46.05-36.52,46.05s-36.53-19-36.53-46.05,13.18-49,37-49S294,227.83,294,254.88Z"
                        fill="#e3e2e3"
                    />
                    <path
                        id="Path_132"
                        d="M248.87,309.7s-22.68,3-30.84,10.06c-8.31,7.19-10.77,13.41-13.76,27.62H310.64c-3-14.21-5.45-20.43-13.76-27.62C288.72,312.7,266,309.7,266,309.7Z"
                        fill="#f7ba93"
                    />
                    <path
                        id="Path_133"
                        d="M242,310.87s-15.83,1.83-24,8.89c-8.31,7.19-10.77,13.41-13.76,27.62h106.4c-3-14.21-5.41-20.48-13.76-27.62-6.89-5.89-22.38-8.56-22.38-8.56l-8.5-1.5H248.87Z"
                        fill="#fff"
                    />
                    <path
                        id="Path_134"
                        d="M253.21,323.52a1.3,1.3,0,0,1,.3-.3l-.3.3-2.92,23.86h14.34l-2.92-23.86,2-8.2a3.68,3.68,0,0,0,.2-3.88,4.33,4.33,0,0,0-2.68-2c-1.67-.46-3.66-.19-5.45-.19s-3,.16-4.21,1.56a3.57,3.57,0,0,0-.3,4.51Z"
                        fill="#707171"
                    />
                    <path
                        id="Path_135"
                        d="M252.88,322.25s5.94-2.78,8.8,1.27a9.46,9.46,0,0,0-8.49,0Z"
                        opacity="0.2"
                    />
                    <path
                        id="Path_136"
                        d="M242,310.87s-15.83,1.83-24,8.89c-8.31,7.19-10.77,13.41-13.76,27.62h106.4c-3-14.21-5.41-20.48-13.76-27.62-6.89-5.89-22.38-8.56-22.38-8.56l-17,35.2Z"
                        fill="#707171"
                    />
                    <path
                        id="Path_137"
                        d="M242.23,311.12l15.23,35.28-16.19-10.46,3.64-6.25-18.11-15S237.07,310.76,242.23,311.12Z"
                        opacity="0.2"
                    />
                    <path
                        id="Path_138"
                        d="M274.5,311.2l-17,35.2,16.18-10.46L270,329.69l18.11-15S279.65,310.84,274.5,311.2Z"
                        opacity="0.2"
                    />
                    <path
                        id="Path_139"
                        d="M238.84,311.18l13.7,9.55-1.15-4.78Z"
                        fill="#707171"
                        opacity="0.5"
                    />
                    <path
                        id="Path_140"
                        d="M277.37,311.35l-15.01,9.57,1.31-5.56Z"
                        fill="#707171"
                        opacity="0.5"
                    />
                    <path
                        id="Path_141"
                        d="M248.51,306.56l-9.67,4.62,11.45,6.83,8-8.83Z"
                        fill="#fff"
                    />
                    <path
                        id="Path_142"
                        d="M248.37,310a7,7,0,0,1,5.06-2.08l-4.92-1.32-9.67,4.63,8.64,5.16a5.15,5.15,0,0,1,.89-6.39Z"
                        fill="#e3e2e3"
                    />
                    <path
                        id="Path_143"
                        d="M267.89,306.56l9.67,4.62-11.45,6.83-8-8.83Z"
                        fill="#fff"
                    />
                    <path
                        id="Path_144"
                        d="M268,310a7,7,0,0,0-5.06-2.08l4.92-1.32,9.67,4.63-8.64,5.16A5.15,5.15,0,0,0,268,310Z"
                        fill="#e3e2e3"
                    />
                    <path
                        id="Path_145"
                        d="M221,261.47l3.7,3.7.71-29.79s.25-15.47,16.68-15.47a41.91,41.91,0,0,0,30.66,1.22s15.36-2.42,17.16,13.52l-.12,31.12,3.93-4.85.22-4s2.33-13.13,0-32.41c0,0-3.1-21.35-22.39-25,0,0-31.5-8.11-44.79,11.72,0,0-10.14,11.84-7.46,30.36Z"
                        fill="#707171"
                    />
                    <path
                        id="Path_146"
                        d="M448.79,259.86a36.56,36.56,0,1,1-36.56-36.56A36.56,36.56,0,0,1,448.79,259.86Z"
                        fill="#f4f5f6"
                    />
                    <g id="Group_6" clipPath="url(#clip-path)">
                        <rect
                            id="Rectangle_94"
                            width="7.19"
                            height="4.28"
                            transform="translate(408.63 277.17)"
                            fill="#e3e2e3"
                        />
                        <path
                            id="Path_147"
                            d="M409,280.37a4.91,4.91,0,0,1,3.15-1.42c1-.08,2.78.14,3.33-.93a1,1,0,0,1,.31-.39v-.46h-7.2v3.7a3.312,3.312,0,0,1,.41-.5Z"
                            fill="#c6c6c5"
                        />
                        <path
                            id="Path_148"
                            d="M430.23,263.45a3.32,3.32,0,1,1-3.31-3.21A3.26,3.26,0,0,1,430.23,263.45Z"
                            fill="#e3e2e3"
                        />
                        <g id="Group_4">
                            <path
                                id="Path_149"
                                d="M427.35,261.66a1.249,1.249,0,0,1,.22,0,1.611,1.611,0,0,1,.58.11,1.45,1.45,0,0,1,.71.55,1.239,1.239,0,0,1,.12.23,1.832,1.832,0,0,1,.08.25v.53a3,3,0,0,1-.11.5,1.88,1.88,0,0,1-.82,1.07,1.352,1.352,0,0,1-.32.13,1.662,1.662,0,0,1-.26,0h-.22l.2-.08.21-.11a2.072,2.072,0,0,0,.25-.19,1.84,1.84,0,0,0,.23-.25,1.9,1.9,0,0,0,.2-.34,3.541,3.541,0,0,0,.14-.39,1.931,1.931,0,0,0,.07-.43v-.6a.169.169,0,0,0-.07-.17,1.54,1.54,0,0,0-.49-.52,2.88,2.88,0,0,0-.5-.25Z"
                                fill="#d9d9d9"
                            />
                        </g>
                        <path
                            id="Path_150"
                            d="M427.77,263.6a.54.54,0,1,1-.244-.528.54.54,0,0,1,.244.528Z"
                            fill="#d9d9d9"
                        />
                        <path
                            id="Path_151"
                            d="M394.68,263.45a3.32,3.32,0,1,0,3.32-3.21,3.27,3.27,0,0,0-3.32,3.21Z"
                            fill="#e3e2e3"
                        />
                        <g id="Group_5">
                            <path
                                id="Path_152"
                                d="M397.57,261.66a1.249,1.249,0,0,0-.22,0,1.649,1.649,0,0,0-.59.11,1.5,1.5,0,0,0-.71.55,2.411,2.411,0,0,0-.12.23.9.9,0,0,1-.08.25v.27a2.353,2.353,0,0,0,0,.26,2.216,2.216,0,0,0,.1.5,2.278,2.278,0,0,0,.2.45,1.77,1.77,0,0,0,.63.62,1.081,1.081,0,0,0,.32.13,1.381,1.381,0,0,0,.26,0h.22l-.2-.08-.22-.11c-.07-.06-.16-.11-.24-.19a1.9,1.9,0,0,1-.24-.25,2.741,2.741,0,0,1-.19-.34,2.239,2.239,0,0,1-.14-.39,1.929,1.929,0,0,1-.08-.43v-.41a1.057,1.057,0,0,0,0-.19.438.438,0,0,1,.08-.17,1.37,1.37,0,0,1,.49-.52,3.253,3.253,0,0,1,.49-.25Z"
                                fill="#d9d9d9"
                            />
                        </g>
                        <path
                            id="Path_153"
                            d="M397.14,263.6a.55.55,0,1,0,.235-.562.55.55,0,0,0-.235.562Z"
                            fill="#d9d9d9"
                        />
                        <path
                            id="Path_154"
                            d="M426.92,259.23c0,10.87-5.11,18.51-14.69,18.51s-14.69-7.64-14.69-18.51,5.3-19.7,14.89-19.7S426.92,248.35,426.92,259.23Z"
                            fill="#e3e2e3"
                        />
                        <path
                            id="Path_155"
                            d="M408.78,281.27s-9.12,1.21-12.4,4.05-4.33,5.39-5.54,11.1h42.78c-1.21-5.71-2.2-8.21-5.54-11.1s-12.4-4.05-12.4-4.05Z"
                            fill="#e3e2e3"
                        />
                        <path
                            id="Path_156"
                            d="M405.27,281.9s-5.61.58-8.89,3.42-4.33,5.39-5.54,11.1h42.78c-1.21-5.71-2.2-8.21-5.54-11.1s-8.87-3.42-8.87-3.42l-7,7Z"
                            fill="#363c47"
                        />
                        <path
                            id="Path_157"
                            d="M397.14,253.24l.53,8.88,1.3.37c-1.14-14.34,6.41-17.62,6.41-17.62l2.11-9a10.53,10.53,0,0,0-9.27,5.12S395.83,243.45,397.14,253.24Z"
                            fill="#363c47"
                        />
                        <path
                            id="Path_158"
                            d="M397.21,254.39s-.27-1.12-.5-2.81c-.12-.84-.23-1.82-.33-2.88,0-.53-.1-1.07-.13-1.63a4.739,4.739,0,0,0-.05-.84V246a2.1,2.1,0,0,1,0-.26v-.43a8.589,8.589,0,0,1,.3-1.79,4.9,4.9,0,0,1,.29-.84,6,6,0,0,1,.35-.77,8.521,8.521,0,0,1,.85-1.36,7.832,7.832,0,0,1,.94-1.08,8.531,8.531,0,0,1,2.31-1.71,17.736,17.736,0,0,0-1.75,2.18,13.009,13.009,0,0,0-.69,1.13,12.21,12.21,0,0,0-.63,1.31,9.828,9.828,0,0,0-.47,1.45,5.446,5.446,0,0,0-.14.75,4.692,4.692,0,0,0-.08.79v.42a1.188,1.188,0,0,1,0,.15v2.69c0,1.06,0,2-.06,2.9C397.37,253.25,397.21,254.39,397.21,254.39Z"
                            fill="#363c47"
                        />
                        <path
                            id="Path_159"
                            d="M404.5,245.4s4.54,7.83,19.1,7.42c0,0-2.72-3.22-1.37-4.75C422.23,248.07,408.13,241.37,404.5,245.4Z"
                            fill="#c6c6c5"
                        />
                        <path
                            id="Path_160"
                            d="M427.21,255l-.53,8.88-1.3.36c1.14-14.33-6.41-17.61-6.41-17.61l-2.11-9a10.53,10.53,0,0,1,9.27,5.12S428.52,245.19,427.21,255Z"
                            fill="#363c47"
                        />
                        <path
                            id="Path_161"
                            d="M402.12,237.22s2.09-4.26,9.6-5.43c0,0,12.51-1.5,16.5,7.17,0,0,2,4.18,1.29,11.53,0,0-.76-3.31-2.81-4.41,0,0-.34,3.91-5.74,1.51,0,0-.86,1.6,1,4.45C422,252,404.5,252,402.12,237.22Z"
                            fill="#363c47"
                        />
                        <path
                            id="Path_162"
                            d="M402.12,237.22a1.611,1.611,0,0,1,.14-.31,4.882,4.882,0,0,1,.53-.83,9.248,9.248,0,0,1,1-1.17,13.388,13.388,0,0,1,1.54-1.28l1-.59.53-.31.57-.24a13.488,13.488,0,0,1,2.51-.88l1.36-.33.34-.08h.39a3.587,3.587,0,0,0,.73-.05,23.558,23.558,0,0,1,2.91,0,23.235,23.235,0,0,1,2.9.39c.47.1.94.22,1.4.34l1.33.45a13.641,13.641,0,0,1,2.39,1.18,5.912,5.912,0,0,1,1,.7,11.262,11.262,0,0,1,.89.74,9.691,9.691,0,0,1,1.31,1.52,8.259,8.259,0,0,1,.79,1.31c.36.77.54,1.21.54,1.21s-.29-.39-.75-1.08a7.912,7.912,0,0,0-1-1.13,12,12,0,0,0-1.41-1.27c-.27-.22-.6-.39-.91-.6l-.47-.3-.53-.27a17.09,17.09,0,0,0-2.29-.94l-1.27-.37-1.33-.28a24.694,24.694,0,0,0-2.74-.32,25.87,25.87,0,0,0-2.78-.07h-.98l-.33.06-1.3.26a15.732,15.732,0,0,0-2.41.66l-.56.19-.51.24-1,.46c-.59.35-1.15.68-1.61,1a9.9,9.9,0,0,0-1.12,1,5.883,5.883,0,0,0-.8.99Z"
                            fill="#2a1706"
                        />
                    </g>
                    <path
                        id="Path_164"
                        d="M155,259.86a36.56,36.56,0,1,1-36.56-36.56A36.56,36.56,0,0,1,155,259.86Z"
                        fill="#f4f5f6"
                    />
                    <g id="Group_9" clipPath="url(#clip-path-2)">
                        <rect
                            id="Rectangle_95"
                            width="7.19"
                            height="4.28"
                            transform="translate(114.83 277.17)"
                            fill="#e3e2e3"
                        />
                        <path
                            id="Path_165"
                            d="M115.24,280.37a4.89,4.89,0,0,1,3.15-1.42c1-.08,2.78.14,3.32-.93a1.24,1.24,0,0,1,.31-.39v-.46h-7.19v3.7a2.8,2.8,0,0,1,.41-.5Z"
                            fill="#c6c6c5"
                        />
                        <path
                            id="Path_166"
                            d="M136.43,263.45a3.32,3.32,0,1,1-3.32-3.2,3.26,3.26,0,0,1,3.32,3.2Z"
                            fill="#f7ba93"
                        />
                        <g id="Group_7">
                            <path
                                id="Path_167"
                                d="M133.54,261.67a.769.769,0,0,1,.22,0,1.65,1.65,0,0,1,.59.11,1.5,1.5,0,0,1,.71.55,2.419,2.419,0,0,1,.12.23.325.325,0,0,0,.08.25v.27a2.353,2.353,0,0,1,0,.26,2.11,2.11,0,0,1-.1.5,2.311,2.311,0,0,1-.21.45,1.6,1.6,0,0,1-.62.62,1.2,1.2,0,0,1-.32.13,1.407,1.407,0,0,1-.25,0h-.23l.2-.08.22-.11a2,2,0,0,0,.24-.19,1.431,1.431,0,0,0,.24-.25,2.739,2.739,0,0,0,.19-.34,2.24,2.24,0,0,0,.14-.39,2,2,0,0,0,.08-.42v-.42a1.061,1.061,0,0,1,0-.19.437.437,0,0,0-.08-.17,1.44,1.44,0,0,0-.49-.52,3.253,3.253,0,0,0-.49-.25Z"
                                fill="#d9d9d9"
                            />
                        </g>
                        <path
                            id="Path_168"
                            d="M134,263.6a.55.55,0,1,1-.235-.562A.55.55,0,0,1,134,263.6Z"
                            fill="#d9d9d9"
                        />
                        <path
                            id="Path_169"
                            d="M100.88,263.45a3.32,3.32,0,1,0,3.31-3.2,3.25,3.25,0,0,0-3.31,3.2Z"
                            fill="#f7ba93"
                        />
                        <g id="Group_8">
                            <path
                                id="Path_170"
                                d="M103.77,261.67a.82.82,0,0,0-.23,0,1.609,1.609,0,0,0-.58.11,1.45,1.45,0,0,0-.71.55,1.24,1.24,0,0,0-.12.23,1.829,1.829,0,0,0-.08.25v.53a2.13,2.13,0,0,0,.11.5,2.28,2.28,0,0,0,.2.45,1.75,1.75,0,0,0,.62.62,1.35,1.35,0,0,0,.32.13,1.658,1.658,0,0,0,.26,0h.23l-.21-.08-.21-.11a2.073,2.073,0,0,1-.25-.19,1.389,1.389,0,0,1-.23-.25,1.9,1.9,0,0,1-.2-.34,3.538,3.538,0,0,1-.14-.39,2.829,2.829,0,0,1-.07-.42v-.61a1.222,1.222,0,0,1,.07-.17,1.63,1.63,0,0,1,.49-.52,2.881,2.881,0,0,1,.5-.25Z"
                                fill="#d9d9d9"
                            />
                        </g>
                        <path
                            id="Path_171"
                            d="M103.34,263.6a.54.54,0,1,0,.244-.528.54.54,0,0,0-.244.528Z"
                            fill="#d9d9d9"
                        />
                        <path
                            id="Path_172"
                            d="M133.11,259.23c0,10.88-5.1,18.51-14.68,18.51s-14.69-7.63-14.69-18.51,5.3-19.7,14.88-19.7S133.11,248.35,133.11,259.23Z"
                            fill="#e3e2e3"
                        />
                        <path
                            id="Path_173"
                            d="M115,281.27s-9.12,1.21-12.4,4-4.33,5.4-5.53,11.11h42.77c-1.2-5.71-2.19-8.21-5.53-11.11s-12.4-4-12.4-4Z"
                            fill="#e3e2e3"
                        />
                        <path
                            id="Path_174"
                            d="M110.12,282.2s1-.42-2.61.72c1,9-.63,13.5-.63,13.5h23.56a21.08,21.08,0,0,1,0-13.13l-2.17-.79s-.21,10.92-10,10.17C118.28,292.67,109.81,293.5,110.12,282.2Z"
                            fill="#707171"
                        />
                        <path
                            id="Path_175"
                            d="M113.42,239.17s-10.53.36-12.54,17c0,0-2,13.71,4.68,25.39a43.943,43.943,0,0,1,9.27-.26v-4s-10-1.75-9.82-14.76c0,0,.06-15,7.7-17.88Z"
                            fill="#707171"
                        />
                        <path
                            id="Path_176"
                            d="M123.44,239.17s11.93-.19,13.94,16.45c0,0,1,11.13-6.08,25.93a44.058,44.058,0,0,0-9.28-.26v-4s10-1.75,9.82-14.76c0,0-.06-15-7.7-17.88Z"
                            fill="#707171"
                        />
                        <path
                            id="Path_177"
                            d="M109.19,243.38a23.28,23.28,0,0,0,20.92,9.78l2.78,7.09,2.76,1.14s4.16-1.14-.17-18c0,0-4.48-12.32-20.65-8.66C114.83,234.72,106.77,236.2,109.19,243.38Z"
                            fill="#707171"
                        />
                    </g>
                    <path
                        id="Path_179"
                        d="M177.18,153.88a36.56,36.56,0,1,1-36.56-36.56A36.56,36.56,0,0,1,177.18,153.88Z"
                        fill="#f4f5f6"
                    />
                    <g id="Group_12" clipPath="url(#clip-path-3)">
                        <path
                            id="Path_180"
                            d="M157.15,158.3s-4.72,16-13.08,17v-3.65S152.63,155.67,157.15,158.3Z"
                            fill="#707171"
                        />
                        <rect
                            id="Rectangle_96"
                            width="7.19"
                            height="4.28"
                            transform="translate(137.02 171.19)"
                            fill="#e3e2e3"
                        />
                        <path
                            id="Path_181"
                            d="M137.43,174.38a4.94,4.94,0,0,1,3.14-1.42c1-.07,2.79.15,3.33-.92a1.46,1.46,0,0,1,.31-.4v-.45H137v3.69a4.72,4.72,0,0,1,.43-.5Z"
                            fill="#c6c6c5"
                        />
                        <ellipse
                            id="Ellipse_11"
                            cx="3.32"
                            cy="3.2"
                            rx="3.32"
                            ry="3.2"
                            transform="translate(151.98 154.26)"
                            fill="#e3e2e3"
                        />
                        <g id="Group_10">
                            <path
                                id="Path_182"
                                d="M155.73,155.68H156a2,2,0,0,1,.59.12,1.52,1.52,0,0,1,.71.54l.12.23a.347.347,0,0,0,.08.26v.26a1.219,1.219,0,0,1,0,.27,2,2,0,0,1-.1.49,2.149,2.149,0,0,1-.21.46,1.64,1.64,0,0,1-.62.61,1.2,1.2,0,0,1-.32.13l-.26.05h-.22l.2-.09a1.5,1.5,0,0,0,.22-.11,1.66,1.66,0,0,0,.24-.18,1.51,1.51,0,0,0,.24-.26,2.24,2.24,0,0,0,.33-.72,2.171,2.171,0,0,0,.08-.43v-.41a1.29,1.29,0,0,1,0-.19l-.08-.18a1.34,1.34,0,0,0-.49-.51,3.25,3.25,0,0,0-.49-.25Z"
                                fill="#d9d9d9"
                            />
                        </g>
                        <path
                            id="Path_183"
                            d="M156.16,157.62a.58.58,0,0,1-.54.62.63.63,0,0,1,0-1.24.58.58,0,0,1,.54.62Z"
                            fill="#d9d9d9"
                        />
                        <path
                            id="Path_184"
                            d="M123.07,157.46a3.32,3.32,0,1,0,3.31-3.2,3.25,3.25,0,0,0-3.31,3.2Z"
                            fill="#f7ba93"
                        />
                        <g id="Group_11">
                            <path
                                id="Path_185"
                                d="M126,155.68h-.23a2,2,0,0,0-.59.12,1.45,1.45,0,0,0-.7.54,1.67,1.67,0,0,0-.12.23,2,2,0,0,0-.08.26v.53a2,2,0,0,0,.11.49,1.8,1.8,0,0,0,.82,1.07,1.351,1.351,0,0,0,.32.13l.26.05H126l-.21-.09a2.208,2.208,0,0,1-.21-.11,1.3,1.3,0,0,1-.25-.18,1.47,1.47,0,0,1-.23-.26,2.63,2.63,0,0,1-.2-.33,2.579,2.579,0,0,1-.14-.39,1.61,1.61,0,0,1-.07-.43v-.41a1.289,1.289,0,0,0,0-.19.2.2,0,0,1,.07-.18,1.44,1.44,0,0,1,.5-.51,2.529,2.529,0,0,1,.49-.25Z"
                                fill="#d9d9d9"
                            />
                        </g>
                        <path
                            id="Path_186"
                            d="M125.53,157.62a.54.54,0,1,0,.53-.62.58.58,0,0,0-.53.62Z"
                            fill="#d9d9d9"
                        />
                        <path
                            id="Path_187"
                            d="M155.3,153.24c0,10.88-5.1,18.52-14.68,18.52s-14.69-7.64-14.69-18.52,5.3-19.7,14.88-19.7S155.3,142.36,155.3,153.24Z"
                            fill="#e3e2e3"
                        />
                        <path
                            id="Path_188"
                            d="M137.16,175.29s-9.12,1.2-12.4,4-4.33,5.39-5.53,11.11H162c-1.2-5.72-2.19-8.22-5.53-11.11s-12.4-4-12.4-4Z"
                            fill="#e3e2e3"
                        />
                        <path
                            id="Path_189"
                            d="M144.07,175.29a47.728,47.728,0,0,1,8.56,2,21.08,21.08,0,0,0,0,13.13H129.08s1.62-4.64.62-13.5a50.152,50.152,0,0,1,7.46-1.65l3.31,6.43Z"
                            fill="#707171"
                        />
                        <path
                            id="Path_190"
                            d="M155.84,154.31c-5-1.21-7.35-7.37-7.35-7.37a7,7,0,0,0,.16,3,45.13,45.13,0,0,1-14.39-10.75c-4.56-5.63,2.76-9.32,2.76-9.32,11.74-4.15,16.8,3.69,16.8,3.69s8.34,7.55,4.23,22.13a3.58,3.58,0,0,0-2.21-1.38Z"
                            fill="#707171"
                        />
                        <path
                            id="Path_191"
                            d="M135.61,133.19s-10.53.35-12.54,17c0,0-2.91,30.44,10.74,35.62,0,0-.89-8.61,3.21-10.49v-3.95s-10-1.75-9.82-14.77c0,0,.06-15,7.7-17.87Z"
                            fill="#707171"
                        />
                        <path
                            id="Path_192"
                            d="M156.41,160.54a1.11,1.11,0,1,1-1.11-1.11,1.11,1.11,0,0,1,1.11,1.11Z"
                            fill="#fff"
                        />
                    </g>
                    <path
                        id="Path_194"
                        d="M426.14,153.88a36.731,36.731,0,1,0,0,.007Z"
                        fill="#f4f5f6"
                    />
                    <g id="Group_15" clipPath="url(#clip-path-4)">
                        <rect
                            id="Rectangle_97"
                            width="7.19"
                            height="4.28"
                            transform="translate(385.98 171.19)"
                            fill="#e3e2e3"
                        />
                        <path
                            id="Path_195"
                            d="M386.39,174.38a4.94,4.94,0,0,1,3.14-1.42c1-.07,2.78.15,3.33-.92a1.29,1.29,0,0,1,.31-.4v-.45H386v3.69A3.736,3.736,0,0,1,386.39,174.38Z"
                            fill="#c6c6c5"
                        />
                        <path
                            id="Path_196"
                            d="M407.58,157.46a3.32,3.32,0,1,1-3.32-3.2,3.26,3.26,0,0,1,3.32,3.2Z"
                            fill="#f7ba93"
                        />
                        <g id="Group_13">
                            <path
                                id="Path_197"
                                d="M404.69,155.68h.22a2,2,0,0,1,.59.12,1.45,1.45,0,0,1,.7.54,1.709,1.709,0,0,1,.13.23,2,2,0,0,1,.07.26v.26a2.437,2.437,0,0,1,0,.27,2,2,0,0,1-.1.49,2.149,2.149,0,0,1-.21.46,1.64,1.64,0,0,1-.62.61,1.2,1.2,0,0,1-.32.13l-.26.05h-.22l.2-.09a1.5,1.5,0,0,0,.22-.11,1.659,1.659,0,0,0,.24-.18,1.47,1.47,0,0,0,.23-.26,1.812,1.812,0,0,0,.2-.33,2.581,2.581,0,0,0,.14-.39,2.168,2.168,0,0,0,.08-.43v-.41a.275.275,0,0,0-.05-.19,1.16,1.16,0,0,0-.56-.69,2.881,2.881,0,0,0-.5-.25Z"
                                fill="#d9d9d9"
                            />
                        </g>
                        <path
                            id="Path_198"
                            d="M405.12,157.62a.55.55,0,1,1-.54-.62.58.58,0,0,1,.54.62Z"
                            fill="#d9d9d9"
                        />
                        <path
                            id="Path_199"
                            d="M372,157.46a3.32,3.32,0,1,0,3.31-3.2,3.25,3.25,0,0,0-3.31,3.2Z"
                            fill="#f7ba93"
                        />
                        <g id="Group_14">
                            <path
                                id="Path_200"
                                d="M374.91,155.68h-.22a2,2,0,0,0-.59.12,1.45,1.45,0,0,0-.7.54c0,.07-.08.15-.13.23s0,.17-.07.26v.53a2,2,0,0,0,.11.49,1.732,1.732,0,0,0,.2.46,1.64,1.64,0,0,0,.62.61,1.35,1.35,0,0,0,.32.13l.26.05h.22l-.2-.09-.22-.11a1.66,1.66,0,0,1-.24-.18,1.47,1.47,0,0,1-.23-.26,1.81,1.81,0,0,1-.2-.33,2.577,2.577,0,0,1-.14-.39,1.61,1.61,0,0,1-.07-.43v-.41a1.29,1.29,0,0,1,0-.19.2.2,0,0,1,.07-.18,1.5,1.5,0,0,1,.49-.51,2.879,2.879,0,0,1,.5-.25Z"
                                fill="#d9d9d9"
                            />
                        </g>
                        <path
                            id="Path_201"
                            d="M374.49,157.62A.54.54,0,1,0,375,157a.58.58,0,0,0-.51.62Z"
                            fill="#d9d9d9"
                        />
                        <path
                            id="Path_202"
                            d="M404.26,153.24c0,10.88-5.1,18.52-14.69,18.52s-14.68-7.64-14.68-18.52,5.3-19.7,14.88-19.7S404.26,142.36,404.26,153.24Z"
                            fill="#e3e2e3"
                        />
                        <path
                            id="Path_203"
                            d="M386.12,175.29s-9.12,1.2-12.4,4-4.33,5.39-5.53,11.11H411c-1.2-5.72-2.19-8.22-5.53-11.11s-12.4-4-12.4-4Z"
                            fill="#e3e2e3"
                        />
                        <path
                            id="Path_204"
                            d="M397.45,176.12c2.81.65,6.23,1.7,8,3.21,3.34,2.89,4.33,5.39,5.53,11.11H368.19c1.2-5.72,2.19-8.22,5.53-11.11,1.8-1.56,5.37-2.62,8.21-3.27l7.64,8.24Z"
                            fill="#707171"
                        />
                        <path
                            id="Path_205"
                            d="M384.57,133.19s-10.53.35-12.54,17c0,0-.4,14.29-.4,31.19,0,0,2.41-4.22,14.35-6.06v-3.95s-10-1.75-9.82-14.77c0,0,.06-15,7.7-17.87Z"
                            fill="#707171"
                        />
                        <path
                            id="Path_206"
                            d="M394.58,133.19s12-.61,14,16c0,0,.24,14.76-1,32.32,0,0-3.32-4.73-14.41-6.23v-3.95s9.95-1.75,9.82-14.77c0,0-.06-15-7.7-17.87Z"
                            fill="#707171"
                        />
                        <path
                            id="Path_207"
                            d="M388,149.22l1.48-7.45v7.45h7.38L398,144l1.27,5.25h9.25a48.127,48.127,0,0,0-1-9.58s-2.46-11.6-19.83-10.57c0,0-14.63,0-15.68,20.15Z"
                            fill="#707171"
                        />
                    </g>
                    <circle
                        id="Ellipse_12"
                        cx="36.56"
                        cy="36.56"
                        r="36.56"
                        transform="translate(228.54 49.02)"
                        fill="#fdfdfd"
                    />
                    <circle
                        id="Ellipse_13"
                        cx="36.56"
                        cy="36.56"
                        r="36.56"
                        transform="translate(228.54 49.02)"
                        fill="#f4f5f6"
                    />
                    <g id="Group_19" clipPath="url(#clip-path-5)">
                        <rect
                            id="Rectangle_98"
                            width="7.19"
                            height="4.28"
                            transform="translate(261.5 102.89)"
                            fill="#e3e2e3"
                        />
                        <path
                            id="Path_209"
                            d="M261.91,106.08a4.88,4.88,0,0,1,3.15-1.41c1-.08,2.78.14,3.32-.93a1.14,1.14,0,0,1,.31-.39v-.46H261.5v3.69a3.739,3.739,0,0,1,.41-.5Z"
                            fill="#c6c6c5"
                        />
                        <path
                            id="Path_210"
                            d="M283.1,89.16a3.32,3.32,0,1,1-3.32-3.2,3.27,3.27,0,0,1,3.32,3.2Z"
                            fill="#e3e2e3"
                        />
                        <g id="Group_16">
                            <path
                                id="Path_211"
                                d="M280.21,87.38h.22a2,2,0,0,1,.59.12,1.47,1.47,0,0,1,.71.54l.12.23.08.26v.26a2.429,2.429,0,0,1,0,.27,2.221,2.221,0,0,1-.1.5,2,2,0,0,1-.21.44,1.54,1.54,0,0,1-.62.62,1.729,1.729,0,0,1-.32.14h-.48l.2-.08.22-.12a1.66,1.66,0,0,0,.24-.18,1.509,1.509,0,0,0,.24-.26,2.241,2.241,0,0,0,.33-.72,2,2,0,0,0,.08-.43v-.6a1.16,1.16,0,0,0-.56-.69,3.247,3.247,0,0,0-.49-.25,1.331,1.331,0,0,0-.25-.05Z"
                                fill="#d9d9d9"
                            />
                        </g>
                        <path
                            id="Path_212"
                            d="M280.64,89.32a.55.55,0,1,1-.54-.62.58.58,0,0,1,.54.62Z"
                            fill="#d9d9d9"
                        />
                        <path
                            id="Path_213"
                            d="M247.55,89.16a3.32,3.32,0,1,0,3.31-3.2,3.26,3.26,0,0,0-3.31,3.2Z"
                            fill="#e3e2e3"
                        />
                        <g id="Group_17">
                            <path
                                id="Path_214"
                                d="M250.43,87.38h-.22a2,2,0,0,0-.59.12,1.45,1.45,0,0,0-.7.54,1.67,1.67,0,0,0-.12.23,2.585,2.585,0,0,0-.08.26v.53a2.241,2.241,0,0,0,.11.5,1.6,1.6,0,0,0,.2.44,1.67,1.67,0,0,0,.62.62,2.079,2.079,0,0,0,.32.14h.48l-.2-.08-.21-.12a1.3,1.3,0,0,1-.25-.18,1.47,1.47,0,0,1-.23-.26,1.81,1.81,0,0,1-.2-.33c-.05-.12-.1-.26-.14-.39a3,3,0,0,1-.07-.43v-.6a1.343,1.343,0,0,1,.07-.18,1.5,1.5,0,0,1,.49-.51,2.879,2.879,0,0,1,.5-.25Z"
                                fill="#d9d9d9"
                            />
                        </g>
                        <path
                            id="Path_215"
                            d="M250,89.32a.54.54,0,1,0,.53-.62A.58.58,0,0,0,250,89.32Z"
                            fill="#d9d9d9"
                        />
                        <path
                            id="Path_216"
                            d="M279.78,84.94c0,10.88-5.1,18.52-14.68,18.52s-14.69-7.64-14.69-18.52,5.3-19.7,14.88-19.7S279.78,74.06,279.78,84.94Z"
                            fill="#e3e2e3"
                        />
                        <path
                            id="Path_217"
                            d="M261.64,107s-9.12,1.21-12.4,4-4.33,5.39-5.53,11.11h42.77c-1.2-5.72-2.19-8.22-5.53-11.11s-12.4-4-12.4-4Z"
                            fill="#e3e2e3"
                        />
                        <path
                            id="Path_218"
                            d="M272.08,107.62s5.59.58,8.87,3.41,4.33,5.39,5.53,11.11H243.71c1.2-5.72,2.19-8.22,5.53-11.11s8.89-3.42,8.89-3.42-1.21,7.8,7.27,7.74C265.4,115.35,272.41,115.62,272.08,107.62Z"
                            fill="#707171"
                        />
                        <g id="Group_18">
                            <path
                                id="Path_219"
                                d="M259.29,107.38a1.13,1.13,0,0,1,0,.41,6.47,6.47,0,0,0,1.2,4.66c1,1.17,2.67,1.75,4.91,1.73h0a5.89,5.89,0,0,0,4-1.54,7.15,7.15,0,0,0,1.51-5.26l2.33.42a8.5,8.5,0,0,1-2.21,6.5,8.21,8.21,0,0,1-5.56,2.22h-.18c-2.91,0-5.12-.85-6.58-2.55a8.59,8.59,0,0,1-1.77-6.17Z"
                                fill="#c6c6c5"
                            />
                        </g>
                        <path
                            id="Path_220"
                            d="M250,79l.53,8.87,1.29.37c-1.14-14.33,6.41-17.61,6.41-17.61l2.12-9a10.54,10.54,0,0,0-9.28,5.12S248.69,69.16,250,79Z"
                            fill="#707171"
                        />
                        <path
                            id="Path_221"
                            d="M250.08,80.11s-.07-.28-.15-.77-.21-1.2-.32-2-.22-1.83-.31-2.88c0-.53-.1-1.08-.13-1.63V71.78a2.142,2.142,0,0,1,0-.26v-.43a8.388,8.388,0,0,1,.3-1.78,5.78,5.78,0,0,1,.28-.83,5.509,5.509,0,0,1,.36-.78,8.129,8.129,0,0,1,.84-1.35,8.382,8.382,0,0,1,.92-1.08,10.151,10.151,0,0,1,1.61-1.33,5.142,5.142,0,0,1,.68-.41s-.21.21-.53.58a15.316,15.316,0,0,0-1.25,1.59,13,13,0,0,0-.7,1.13A9.382,9.382,0,0,0,251,68.1a9.6,9.6,0,0,0-.48,1.46,7.109,7.109,0,0,0-.15.75,6.109,6.109,0,0,0-.07.79v6.17c0,.9-.1,1.56-.13,2.06A5.774,5.774,0,0,1,250.08,80.11Z"
                            fill="#707171"
                        />
                        <path
                            id="Path_222"
                            d="M280.07,80.7l-.53,8.88-1.29.36c1.31-19.11-5.81-19.43-5.81-19.43l-2.71-7.15A10.53,10.53,0,0,1,279,68.48S281.39,70.9,280.07,80.7Z"
                            fill="#707171"
                        />
                        <path
                            id="Path_223"
                            d="M258.34,70.53a22,22,0,0,1,16.76,1.1l.65-6.55s-1.67-12.81-18.38-8.73a12.39,12.39,0,0,1,3,1.94s-6.44-.44-9.28,5.42c0,0,4.35-1.46,7.85-.52C258.93,63.19,253.68,66.65,258.34,70.53Z"
                            fill="#707171"
                        />
                        <path
                            id="Path_224"
                            d="M275.75,65.08s-.1-.36-.33-.94a13.18,13.18,0,0,0-1.15-2.31A13.2,13.2,0,0,0,272,59.1a11.921,11.921,0,0,0-1.59-1.18,7,7,0,0,0-.9-.48,6.108,6.108,0,0,0-.94-.42,12.669,12.669,0,0,0-2-.58,14.139,14.139,0,0,0-2-.31c-.66,0-1.3-.13-1.91-.12s-1.18,0-1.72.05c-1.08,0-2,.17-2.6.21l-1,.08s.34-.12.95-.3l1.11-.32a8.741,8.741,0,0,1,1.46-.34l1.77-.25a19,19,0,0,1,2,0,10.61,10.61,0,0,1,2.14.23,12.071,12.071,0,0,1,2.17.6,6.429,6.429,0,0,1,1,.48,7.351,7.351,0,0,1,1,.55,10,10,0,0,1,1.69,1.39A10.491,10.491,0,0,1,274,59.93a10.889,10.889,0,0,1,.86,1.59,9.612,9.612,0,0,1,.52,1.42c.11.44.21.82.26,1.14a6.458,6.458,0,0,1,.11,1Z"
                            fill="#707171"
                        />
                    </g>
                    <path
                        id="Path_225"
                        d="M314.11,55.24a6.23,6.23,0,1,1-1.821-4.412,6.23,6.23,0,0,1,1.821,4.412Z"
                        fill="#e3e2e3"
                    />
                    <path
                        id="Path_226"
                        d="M323.86,69.39a4.62,4.62,0,1,1-4.62-4.62A4.62,4.62,0,0,1,323.86,69.39Z"
                        fill="#e3e2e3"
                    />
                    <path
                        id="Path_227"
                        d="M327.58,55.24a4.17,4.17,0,1,1-4.17-4.16,4.17,4.17,0,0,1,4.17,4.16Z"
                        fill="#e3e2e3"
                    />
                    <path
                        id="Path_228"
                        d="M144.69,97a6.24,6.24,0,1,1-4-2.636,6.23,6.23,0,0,1,4,2.636Z"
                        fill="#e3e2e3"
                    />
                    <path
                        id="Path_229"
                        d="M160.73,103.1a4.61,4.61,0,1,1-6.41-1.2,4.61,4.61,0,0,1,6.41,1.2Z"
                        fill="#e3e2e3"
                    />
                    <path
                        id="Path_230"
                        d="M155.8,89.33a4.17,4.17,0,1,1-5.8-1.08,4.17,4.17,0,0,1,5.8,1.08Z"
                        fill="#e3e2e3"
                    />
                    <path
                        id="Path_231"
                        d="M72.48,226.82a6.23,6.23,0,1,1-4.666,1A6.23,6.23,0,0,1,72.48,226.82Z"
                        fill="#e3e2e3"
                    />
                    <path
                        id="Path_232"
                        d="M88.18,219.82a4.62,4.62,0,1,1-5.39,3.69A4.62,4.62,0,0,1,88.18,219.82Z"
                        fill="#e3e2e3"
                    />
                    <path
                        id="Path_233"
                        d="M75,213.58a4.15,4.15,0,1,1-3.123.653A4.17,4.17,0,0,1,75,213.58Z"
                        fill="#e3e2e3"
                    />
                    <path
                        id="Path_234"
                        d="M428.08,179.82a6.23,6.23,0,1,1-8.56,2.05,6.23,6.23,0,0,1,8.56-2.05Z"
                        fill="#e3e2e3"
                    />
                    <path
                        id="Path_235"
                        d="M445.24,178.89a4.62,4.62,0,1,1-6.35,1.52,4.62,4.62,0,0,1,6.35-1.52Z"
                        fill="#e3e2e3"
                    />
                    <path
                        id="Path_236"
                        d="M435.11,168.33a4.16,4.16,0,1,1-3.152-.5,4.17,4.17,0,0,1,3.152.5Z"
                        fill="#e3e2e3"
                    />
                    <path
                        id="Path_237"
                        d="M424,314.87a6.23,6.23,0,1,1-3.5,8.08,6.23,6.23,0,0,1,3.5-8.08Z"
                        fill="#e3e2e3"
                    />
                    <path
                        id="Path_238"
                        d="M433.53,300.6a4.62,4.62,0,1,1-2.59,6,4.62,4.62,0,0,1,2.59-6Z"
                        fill="#e3e2e3"
                    />
                    <path
                        id="Path_239"
                        d="M419,302.34a4.17,4.17,0,1,1-2.34,5.41,4.17,4.17,0,0,1,2.34-5.41Z"
                        fill="#e3e2e3"
                    />
                    <path
                        id="Path_240"
                        d="M102.54,316.65a6.23,6.23,0,1,1-3.5,8.08,6.23,6.23,0,0,1,3.5-8.08Z"
                        fill="#e3e2e3"
                    />
                    <path
                        id="Path_241"
                        d="M112.11,302.38a4.61,4.61,0,1,1-2.548,2.459,4.61,4.61,0,0,1,2.548-2.459Z"
                        fill="#e3e2e3"
                    />
                    <path
                        id="Path_242"
                        d="M97.58,304.12a4.16,4.16,0,1,1-2.293,2.219,4.17,4.17,0,0,1,2.293-2.219Z"
                        fill="#e3e2e3"
                    />
                    <circle
                        id="Ellipse_15"
                        cx="14.47"
                        cy="14.47"
                        r="14.47"
                        transform="translate(205.09 148.19)"
                        fill="#f4f5f6"
                    />
                    <circle
                        id="Ellipse_16"
                        cx="10.08"
                        cy="10.08"
                        r="10.08"
                        transform="translate(228.36 156.97)"
                        fill="#e3e2e3"
                    />
                    <circle
                        id="Ellipse_17"
                        cx="11.33"
                        cy="11.33"
                        r="11.33"
                        transform="translate(306.86 173.8)"
                        fill="#f4f5f6"
                    />
                    <circle
                        id="Ellipse_18"
                        cx="7.9"
                        cy="7.9"
                        r="7.9"
                        transform="translate(325.08 180.66)"
                        fill="#e3e2e3"
                    />
                    <path
                        id="Path_243"
                        d="M105.21,193.53H99.79v-5.41a.68.68,0,0,0-.67-.68h0a.69.69,0,0,0-.69.68v5.41H93a.7.7,0,0,0-.68.69.68.68,0,0,0,.68.68h5.41v5.42a.69.69,0,0,0,.69.67.67.67,0,0,0,.67-.67h0V194.9h5.42a.69.69,0,0,0,.69-.68A.7.7,0,0,0,105.21,193.53Z"
                        fill="#e2e2e2"
                        stroke="#e2e2e2"
                        strokeMiterlimit="10"
                        strokeWidth="0.75"
                    />
                    <path
                        id="Path_244"
                        d="M210.37,95.17h-5.43V89.76a.67.67,0,0,0-.67-.68h0a.69.69,0,0,0-.69.68v5.41h-5.41a.68.68,0,0,0,0,1.36h5.41V102a.69.69,0,0,0,.69.67.67.67,0,0,0,.67-.67h0V96.53h5.43a.68.68,0,0,0,0-1.36Z"
                        fill="#e2e2e2"
                        stroke="#e2e2e2"
                        strokeMiterlimit="10"
                        strokeWidth="0.75"
                    />
                    <path
                        id="Path_245"
                        d="M359.11,102h-5.42V96.53a.67.67,0,0,0-.68-.67h0a.69.69,0,0,0-.69.67V102h-5.4a.68.68,0,0,0,0,1.36h5.4v5.42a.7.7,0,0,0,.69.68.68.68,0,0,0,.68-.68h0v-5.42h5.42a.68.68,0,0,0,0-1.36Z"
                        fill="#e2e2e2"
                        stroke="#e2e2e2"
                        strokeMiterlimit="10"
                        strokeWidth="0.75"
                    />
                    <path
                        id="Path_246"
                        d="M443.2,212h-5.42v-5.41a.68.68,0,0,0-.67-.68h0a.68.68,0,0,0-.69.68V212H431a.7.7,0,0,0-.68.69.67.67,0,0,0,.68.68h5.41v5.42a.68.68,0,0,0,.69.67.67.67,0,0,0,.67-.67h0v-5.42h5.42a.67.67,0,0,0,.68-.68A.7.7,0,0,0,443.2,212Z"
                        fill="#e2e2e2"
                        stroke="#e2e2e2"
                        strokeMiterlimit="10"
                        strokeWidth="0.75"
                    />
                    <ellipse
                        id="_Path_"
                        cx="134.76"
                        cy="13.23"
                        rx="134.76"
                        ry="13.23"
                        transform="translate(123.04 477.86)"
                        fill="#f4f5f6"
                    />
                </g>
            </g>
        </SVGIcon>
    );
}
