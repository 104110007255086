import React, { useCallback, useEffect } from 'react';
// @ts-ignore
import { Ripple } from 'react-preloaders2';
import { checkSSO } from 'apis/root/index.api';
import { getDefaultPage } from 'Utils/localStorageUtils';
import usePubSub from 'Hooks/PubSub';
import { afterLogin } from 'Hooks/useLogin';
import useToast from 'Modules/Toasts';

const Verification = (): React.ReactElement => {
    const [loading, setLoading] = React.useState<boolean>(true);
    const { publish } = usePubSub('', null);
    const { showToast } = useToast();

    const handleSSO = useCallback(async () => {
        const qUrl = window.location.search?.replace('?', '');
        const arr = qUrl?.split('&') || [];
        const obj: any = {};
        arr.forEach(a => {
            const [key, value] = a.split('=');
            obj[key] = value;
        });

        try {
            if (obj.token) {
                const res = await checkSSO(obj.token);
                if (res.message === 'VALID_SESSION') {
                    const lRes = JSON.parse(localStorage.getItem('auth-user') || '{}') || {};
                    const newSessionId = res.data.session_id;
                    lRes[newSessionId] = { auth: { ...res.data, authenticated: true } };
                    sessionStorage.setItem('active-session', newSessionId);
                    localStorage.setItem('auth-user', JSON.stringify(lRes));
                    publish('login-success', { ...res.data, authenticated: true });
                    afterLogin(res.data);
                    const url = res.data.user.preferences?.home_page || 'dashboard';
                    setTimeout(() => {
                        window.location.replace('/' + url);
                    }, 320);
                }
                setLoading(false);
            } else {
                window.location.href = `/${getDefaultPage()}`;
            }
        } catch (e) {
            showToast({
                type: 'error',
                message: e || 'Something went wrong',
            });
        }
    }, [publish, showToast]);

    useEffect(() => {
        handleSSO();
    }, [handleSSO]);

    return (
        <div>
            <Ripple customLoading={loading} />
        </div>
    );
};

export default Verification;
