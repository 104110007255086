import isDate from './isDate';
import isEmail from './isEmail';
import isFloat from './isFloat';
import isMobilePhone from './isMobilePhone';
import isNumeric from './isNumeric';

const validator = {
    isMobilePhone,
    isEmail,
    isNumeric,
    isFloat,
    isDate,
};

export default validator;
