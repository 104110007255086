import React from 'react';

import { SVGIcon } from '../../UIKit/index';

export default function DCNoProperty1(props) {
    return (
        <SVGIcon {...props} viewBox="0 0 512 512">
            <g clip-path="url(#clip0_18_85)">
                <path
                    d="M478.609 125.048H33.391C14.2 130.362 0 148.018 0 168.841V433.608C0 458.598 20.446 479.044 45.436 479.044H213.35C219.023 479.044 223.936 482.708 225.878 488.039C230.348 500.315 242.119 509.081 255.94 509.081C269.762 509.081 281.532 500.315 286.003 488.039C287.944 482.708 292.858 479.044 298.531 479.044H466.564C491.554 479.044 512.001 458.597 512.001 433.608C512.001 428.324 512.001 177.363 512.001 168.841C512 148.018 497.8 130.362 478.609 125.048Z"
                    fill="#A7BAC2"
                />
                <path
                    d="M34.637 433.608V168.841C34.637 148.018 48.837 130.362 68.028 125.048H33.391C14.2 130.362 0 148.018 0 168.841V433.608C0 458.598 20.446 479.044 45.436 479.044H80.073C55.083 479.045 34.637 458.598 34.637 433.608V433.608Z"
                    fill="#94A4AB"
                />
                <path
                    d="M260.515 488.039C258.573 482.708 253.66 479.044 247.987 479.044H213.35C219.023 479.044 223.936 482.708 225.878 488.039C230.348 500.315 242.119 509.081 255.94 509.081C262.328 509.081 268.265 507.191 273.259 503.965C267.448 500.211 262.919 494.641 260.515 488.039V488.039Z"
                    fill="#94A4AB"
                />
                <path
                    d="M458.998 93.1899H445.297V221.815C445.297 257.743 427.403 283.409 393.09 289.104C388.228 289.911 383.827 286.074 383.827 281.146V224.693C383.827 220.249 380.225 216.646 375.78 216.646H254.907V425.488C254.907 436.319 263.687 445.099 274.518 445.099H458.998C469.829 445.099 478.609 436.319 478.609 425.488V112.801C478.609 101.97 469.829 93.1899 458.998 93.1899V93.1899Z"
                    fill="#F1F8FC"
                />
                <path
                    d="M289.544 425.487V216.645H254.907V425.487C254.907 436.318 263.687 445.098 274.518 445.098H309.155C298.324 445.098 289.544 436.318 289.544 425.487Z"
                    fill="#A7BAC2"
                />
                <path
                    d="M354.653 290.51H300.755C296.472 290.51 293 287.038 293 282.755C293 278.472 296.472 275 300.755 275H354.653C358.936 275 362.408 278.472 362.408 282.755C362.408 287.038 358.936 290.51 354.653 290.51Z"
                    fill="#A7BAC2"
                />
                <path
                    d="M198.519 218.713C184.261 218.713 172.701 207.154 172.701 192.895V93.189H53.0021C42.1711 93.189 33.3911 101.97 33.3911 112.801V425.487C33.3911 436.318 42.1711 445.098 53.0021 445.098H236.329C247.16 445.098 255.94 436.318 255.94 425.487V218.713H198.519Z"
                    fill="#F1F8FC"
                />
                <path
                    d="M68.0281 425.487V112.801C68.0281 101.97 76.8081 93.189 87.6391 93.189H53.0021C42.1711 93.189 33.3911 101.97 33.3911 112.801V425.487C33.3911 436.318 42.1711 445.098 53.0021 445.098H87.6401C76.8081 445.098 68.0281 436.318 68.0281 425.487V425.487Z"
                    fill="#A7BAC2"
                />
                <path
                    d="M442.695 367.51H303.755C299.472 367.51 296 364.038 296 359.755C296 355.472 299.472 352 303.755 352H442.695C446.978 352 450.45 355.472 450.45 359.755C450.45 364.038 446.978 367.51 442.695 367.51Z"
                    fill="#A7BAC2"
                />
                <path
                    d="M442.695 330.51H303.755C299.472 330.51 296 327.038 296 322.755C296 318.472 299.472 315 303.755 315H442.695C446.978 315 450.45 318.472 450.45 322.755C450.45 327.038 446.978 330.51 442.695 330.51Z"
                    fill="#A7BAC2"
                />
                <path
                    d="M442.695 404.51H303.755C299.472 404.51 296 401.038 296 396.755C296 392.472 299.472 389 303.755 389H442.695C446.978 389 450.45 392.472 450.45 396.755C450.45 401.038 446.978 404.51 442.695 404.51Z"
                    fill="#A7BAC2"
                />
                <path
                    d="M424.649 2.91895H195.417C181.158 2.91895 169.599 14.4779 169.599 28.7369V195.997C169.599 210.256 181.158 221.815 195.417 221.815H372.677C377.122 221.815 380.724 225.418 380.724 229.862V285.281C380.724 290.209 385.125 294.046 389.987 293.239C424.3 287.545 450.466 257.743 450.466 221.814V28.7359C450.467 14.4779 438.908 2.91895 424.649 2.91895V2.91895Z"
                    fill="#BDCDD3"
                />
                <path
                    d="M200.618 195.997V28.736C200.618 14.477 212.177 2.91797 226.436 2.91797H195.418C181.16 2.91797 169.6 14.477 169.6 28.736V195.996C169.6 210.255 181.159 221.814 195.418 221.814H226.436C212.177 221.814 200.618 210.256 200.618 195.997Z"
                    fill="#A7BAC2"
                />
                <path
                    d="M411.743 285.281V229.862C411.743 225.418 408.141 221.815 403.696 221.815H372.678C377.122 221.815 380.725 225.418 380.725 229.862V285.281C380.725 290.209 385.127 294.046 389.988 293.239C397.738 291.953 405.066 289.427 411.777 285.888C411.762 285.687 411.743 285.486 411.743 285.281Z"
                    fill="#A7BAC2"
                />
                <path
                    d="M310.033 64.804C319.356 64.804 326.816 56.725 326.816 47.695C326.816 38.665 319.357 30.824 310.033 30.824C300.502 30.824 293.043 38.666 293.043 47.695C293.043 56.724 300.502 64.804 310.033 64.804Z"
                    fill="white"
                />
                <path
                    d="M340.714 164.945H329.339C327.603 164.945 326.195 163.537 326.195 161.801V97.833C326.195 91.18 318.943 86.665 310.034 86.665H284.524C278.887 86.665 274.318 91.234 274.318 96.871V98.897C274.318 104.534 278.887 109.103 284.524 109.103H291.048C292.723 109.103 294.08 110.461 294.08 112.135V161.8C294.08 163.536 292.672 164.944 290.936 164.944H279.354C273.717 164.944 269.148 169.513 269.148 175.15V177.176C269.148 182.813 273.717 187.382 279.354 187.382H340.715C346.352 187.382 350.921 182.813 350.921 177.176V175.15C350.92 169.514 346.35 164.945 340.714 164.945V164.945Z"
                    fill="white"
                />
                <path
                    d="M98.8974 403.752L75.5647 439.811C69.1208 449.767 55.136 451.251 46.7469 442.862C38.3579 434.473 39.8421 420.488 49.7983 414.044L85.8575 390.712L98.8974 403.752Z"
                    fill="#94A4AB"
                />
                <path
                    d="M118.45 384.194C123.853 389.596 123.853 398.35 118.45 403.752C113.059 409.143 104.299 409.153 98.8974 403.752L85.8575 390.712C80.4599 385.314 80.4599 376.556 85.8575 371.159C91.2592 365.757 100.013 365.757 105.415 371.159L118.45 384.194Z"
                    fill="#94A4AB"
                />
                <path
                    d="M233.06 322.267C233.06 358.562 203.637 387.985 167.342 387.985C131.047 387.985 101.624 358.562 101.624 322.267C101.624 285.972 131.047 256.549 167.342 256.549C203.637 256.549 233.06 285.972 233.06 322.267Z"
                    stroke="#94A4AB"
                    stroke-width="25.2857"
                    fill="transparent"
                />
                <path
                    d="M174.567 321.562L196.659 299.47C198.459 297.67 198.459 294.751 196.659 292.951C194.858 291.151 191.941 291.151 190.14 292.951L168.047 315.043L145.955 292.951C144.155 291.151 141.237 291.151 139.436 292.951C137.636 294.751 137.636 297.669 139.436 299.47L161.528 321.562L139.436 343.654C137.636 345.454 137.636 348.373 139.436 350.173C140.336 351.073 141.516 351.523 142.695 351.523C143.875 351.523 145.055 351.073 145.955 350.173L168.047 328.081L190.139 350.173C191.039 351.073 192.219 351.523 193.399 351.523C194.578 351.523 195.758 351.073 196.658 350.173C198.458 348.373 198.458 345.455 196.658 343.654L174.567 321.562Z"
                    fill="#BEBCBF"
                />
                <path
                    d="M141.653 163.51H87.755C83.472 163.51 80 160.038 80 155.755C80 151.472 83.472 148 87.755 148H141.653C145.936 148 149.408 151.472 149.408 155.755C149.408 160.038 145.936 163.51 141.653 163.51Z"
                    fill="#A7BAC2"
                />
                <path
                    d="M141.653 200.51H87.755C83.472 200.51 80 197.038 80 192.755C80 188.472 83.472 185 87.755 185H141.653C145.936 185 149.408 188.472 149.408 192.755C149.408 197.038 145.936 200.51 141.653 200.51Z"
                    fill="#A7BAC2"
                />
            </g>
            <defs>
                <clipPath id="clip0_18_85">
                    <rect width="512" height="512" />
                </clipPath>
            </defs>
        </SVGIcon>
    );
}
