import { templateActions } from '../Actions';

const initialPage = {
    allTemplatePage: 1,
    emailTemplatePage: 1,
    smsTemplatePage: 1,
    edocTemplatePage: 1,
};

const templateReducer = (state, action) => {
    switch (action.type) {
        case templateActions.SET_ALL_TEMPLATES:
            return {
                ...state,
                allTemplates: action.value,
            };
        case templateActions.SET_EMAIL_TEMPLATES:
            return { ...state, emailTemplates: action.value };
        case templateActions.SET_EDOC_TEMPLATES:
            return { ...state, edocTemplates: action.value };
        case templateActions.SET_SMS_TEMPLATES:
            return { ...state, smsTemplates: action.value };

        case templateActions.SET_INITIAL_ALL_TEMPLATE_LOAD:
            return {
                ...state,
                initialAllTemplateLoad: action.value,
            };
        case templateActions.SET_INITIAL_EMAIL_LOAD:
            return {
                ...state,
                initialEmailTemplateLoad: action.value,
            };
        case templateActions.SET_INITIAL_EDOC_LOAD:
            return {
                ...state,
                initialEdocTemplateLoad: action.value,
            };
        case templateActions.SET_INITIAL_SMS_LOAD:
            return {
                ...state,
                initialSmsTemplateLoad: action.value,
            };

        case templateActions.SET_TOTAL_ALL_TEMPLATE_PAGES:
            return { ...state, totalAllTemplatePages: action.value };
        case templateActions.SET_TOTAL_EMAIL_TEMPLATE_PAGES:
            return { ...state, totalEmailTemplatePages: action.value };
        case templateActions.SET_TOTAL_EDOC_TEMPLATE_PAGES:
            return { ...state, totalEdocTemplatePages: action.value };
        case templateActions.SET_TOTAL_SMS_TEMPLATE_PAGES:
            return { ...state, totalSmsTemplatePages: action.value };

        case templateActions.SET_ALL_TEMPLATES_RESULTS:
            return { ...state, allTemplateResults: action.value };
        case templateActions.SET_EMAIL_TEMPLATES_RESULTS:
            return { ...state, emailTemplateResults: action.value };
        case templateActions.SET_EDOC_TEMPLATES_RESULTS:
            return { ...state, edocTemplateResults: action.value };
        case templateActions.SET_SMS_TEMPLATES_RESULTS:
            return { ...state, smsTemplateResults: action.value };

        case templateActions.SET_ALL_TEMPLATE_PAGE:
            return { ...state, ...initialPage, allTemplatePage: action.value };
        case templateActions.SET_EMAIL_TEMPLATE_PAGE:
            return {
                ...state,
                ...initialPage,
                emailTemplatePage: action.value,
            };
        case templateActions.SET_SMS_TEMPLATE_PAGE:
            return { ...state, ...initialPage, smsTemplatePage: action.value };
        case templateActions.SET_EDOC_TEMPLATE_PAGE:
            return { ...state, ...initialPage, edocTemplatePage: action.value };

        case templateActions.SET_ALL_TEMPLATE_PAGE_LIMIT:
            return { ...state, allTemplatePageLimit: action.value };
        case templateActions.SET_EMAIL_TEMPLATE_PAGE_LIMIT:
            return { ...state, emailTemplatePageLimit: action.value };
        case templateActions.SET_SMS_TEMPLATE_PAGE_LIMIT:
            return { ...state, smsTemplatePageLimit: action.value };
        case templateActions.SET_EDOC_TEMPLATE_PAGE_LIMIT:
            return { ...state, edocTemplatePageLimit: action.value };

        case templateActions.SET_ALL_TEMPLATE_T_PAGE:
            return { ...state, allTemplateTPage: action.value };
        case templateActions.SET_EMAIL_TEMPLATE_T_PAGE:
            return { ...state, emailTemplateTPage: action.value };
        case templateActions.SET_SMS_TEMPLATE_T_PAGE:
            return { ...state, smsTemplateTPage: action.value };
        case templateActions.SET_EDOC_TEMPLATE_T_PAGE:
            return { ...state, edocTemplateTPage: action.value };
        case templateActions.SET_TEMPLATE_COLUMNS:
            return { ...state, tamplateColumns: action.value };

        case templateActions.SET_SEARCH_TEMPLATE:
            return { ...state, searchText: action.value };
        case templateActions.SET_SORT_TEMPLATE:
            return { ...state, sortTemplate: action.value };

        default:
            throw new Error(action.type);
    }
};

export default templateReducer;
