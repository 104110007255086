import React from 'react';

import { SVGIcon } from '../../UIKit/index';

export default function DCSingleChoice(props) {
    return (
        <SVGIcon {...props} viewBox="0 0 512 512">
            <path d="M332.11,510.97c-11.67-0.02-22.13-7.2-26.37-18.07l-92.63-243.4c-1.31-3.2-1.95-6.64-1.9-10.1  c0.01-15.6,12.65-28.24,28.24-28.26c3.83-0.06,7.63,0.71,11.14,2.26l242.36,92.32c14.58,5.55,21.89,21.87,16.34,36.45  c-3.04,7.99-9.54,14.17-17.67,16.81l-99.84,32.69l-32.71,99.91c-3.8,11.62-14.64,19.47-26.86,19.45L332.11,510.97z M248.18,248.12  l83.59,219.38l30.88-94.26c1.65-5.04,5.6-8.99,10.64-10.64l94.26-30.88L248.18,248.12z M481.07,336.84L481.07,336.84z M237.65,244.1  h0.29H237.65z M243.84,236.73l0.34,0.84C244,237.32,244,237.02,243.84,236.73z" />
            <path d="M222.24,444.32c-122.69,0-222.16-99.46-222.16-222.16S99.55,0,222.24,0  S444.4,99.46,444.4,222.16c0,3.25,0,6.46-0.27,9.74c-0.98,9.16-9.2,15.78-18.35,14.8c-8.77-0.94-15.29-8.55-14.88-17.36  c0.2-2.39,0.18-4.79,0.18-7.18c0-104.29-84.54-188.83-188.83-188.83c-104.29,0-188.83,84.54-188.83,188.83  s84.54,188.83,188.83,188.83l0,0c2.46,0,5.02,0,7.37-0.16c9.17-0.71,17.18,6.15,17.89,15.32c0.71,9.17-6.15,17.18-15.32,17.89  c-3.23,0.25-6.78,0.27-9.96,0.27L222.24,444.32z" />
        </SVGIcon>
    );
}
