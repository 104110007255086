import React from 'react';

import { SVGIcon } from '../../UIKit/index';

export default function DCBoxControl(props) {
    return (
        <SVGIcon {...props} viewBox="0 0 512 512">
            <g transform="translate(0.1 0.101)">
                <path d="M139.4,487.78H12.65c-5.83,0-10.56-4.73-10.56-10.56c0-5.83,4.73-10.56,10.56-10.56H139.4   c5.83,0,10.56,4.73,10.56,10.56C149.97,483.06,145.24,487.78,139.4,487.78z" />
                <path d="M223.91,487.78h-42.25c-5.83,0-10.56-4.73-10.56-10.56c0-5.83,4.73-10.56,10.56-10.56h42.25   c5.83,0,10.56,4.73,10.56,10.56C234.47,483.06,229.74,487.78,223.91,487.78z" />
                <path d="M139.4,508.91c-5.83,0-10.56-4.73-10.56-10.56V456.1c0-5.83,4.73-10.56,10.56-10.56   c5.83,0,10.56,4.73,10.56,10.56v42.25C149.97,504.18,145.24,508.91,139.4,508.91z" />
                <path d="M54.9,403.28H12.65c-5.83,0-10.56-4.73-10.56-10.56s4.73-10.56,10.56-10.56H54.9   c5.83,0,10.56,4.73,10.56,10.56S60.74,403.28,54.9,403.28z" />
                <path d="M223.91,403.28H97.15c-5.83,0-10.56-4.73-10.56-10.56s4.73-10.56,10.56-10.56h126.75   c5.83,0,10.56,4.73,10.56,10.56S229.74,403.28,223.91,403.28z" />
                <path d="M54.9,424.41c-5.83,0-10.56-4.73-10.56-10.56v-42.25c0-5.83,4.73-10.56,10.56-10.56   s10.56,4.73,10.56,10.56v42.25C65.46,419.68,60.74,424.41,54.9,424.41z" />
                <path d="M329.54,487.78h-42.25c-5.83,0-10.56-4.73-10.56-10.56c0-5.83,4.73-10.56,10.56-10.56h42.25   c5.83,0,10.56,4.73,10.56,10.56C340.1,483.06,335.37,487.78,329.54,487.78z" />
                <path d="M498.54,487.78H371.79c-5.83,0-10.56-4.73-10.56-10.56c0-5.83,4.73-10.56,10.56-10.56h126.75   c5.83,0,10.56,4.73,10.56,10.56C509.11,483.06,504.38,487.78,498.54,487.78z" />
                <path d="M329.54,508.91c-5.83,0-10.56-4.73-10.56-10.56V456.1c0-5.83,4.73-10.56,10.56-10.56   c5.83,0,10.56,4.73,10.56,10.56v42.25C340.1,504.18,335.37,508.91,329.54,508.91z" />
                <path d="M392.91,403.28H287.29c-5.83,0-10.56-4.73-10.56-10.56s4.73-10.56,10.56-10.56h105.63   c5.83,0,10.56,4.73,10.56,10.56S398.75,403.28,392.91,403.28z" />
                <path d="M498.54,403.28h-63.38c-5.83,0-10.56-4.73-10.56-10.56s4.73-10.56,10.56-10.56h63.38   c5.83,0,10.56,4.73,10.56,10.56S504.38,403.28,498.54,403.28z" />
                <path d="M392.91,424.41c-5.83,0-10.56-4.73-10.56-10.56v-42.25c0-5.83,4.73-10.56,10.56-10.56   c5.83,0,10.56,4.73,10.56,10.56v42.25C403.48,419.68,398.75,424.41,392.91,424.41z" />
                <path d="M255.6,128.65c-1.13,0-2.26-0.19-3.34-0.55L93.82,75.28c-5.53-1.86-8.51-7.85-6.65-13.38   c1.05-3.14,3.52-5.6,6.65-6.65L252.26,2.44c2.17-0.72,4.51-0.72,6.68,0l158.44,52.81c5.53,1.86,8.51,7.85,6.65,13.38   c-1.05,3.14-3.52,5.6-6.65,6.65L258.93,128.1C257.86,128.46,256.73,128.64,255.6,128.65z M130.55,65.27l125.04,41.68l125.04-41.68   L255.6,23.59L130.55,65.27z" />
                <path d="M255.6,339.9c-1.13,0-2.26-0.19-3.34-0.55L93.82,286.54c-4.31-1.44-7.22-5.47-7.23-10.01V65.27   c0-5.83,4.73-10.56,10.56-10.56c5.83,0,10.56,4.73,10.56,10.56v203.65l147.88,49.29l147.88-49.29V65.27   c0-5.83,4.73-10.56,10.56-10.56c5.83,0,10.56,4.73,10.56,10.56v211.26c0,4.54-2.91,8.58-7.23,10.01l-158.44,52.81   C257.86,339.72,256.73,339.9,255.6,339.9z" />
                <path d="M255.6,339.9c-5.83,0-10.56-4.73-10.56-10.56V118.08c0-5.83,4.73-10.56,10.56-10.56   s10.56,4.73,10.56,10.56v211.26C266.16,335.17,261.43,339.9,255.6,339.9z" />
            </g>
        </SVGIcon>
    );
}
