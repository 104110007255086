import dayjs from 'dayjs';
import { handleShortenURL } from 'apis/root/index.api';
import { colors } from 'Constants/colors';
import CountryCode from 'Constants/countryCodes.json';
import { getUserTimeZone } from './dateUtils';

/**
 * Find Index FROM the ARRAY
 * @param {Array} data
 * @param {String} key
 * @param {String || Integer} item
 *
 * ******* */
export const findItemIndex = (data, key, item) => {
    return data.findIndex(_data => _data[key] === item);
};

/**
 * Create Unique key of length
 * @param {Number} length
 *
 * ******* */
export const makeId = (length = 8) => {
    let result = '';
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const charactersLength = characters.length;
    for (let i = 0; i < length; i += 1) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
};

/**
 * Returns TRUE if the first specified array contains all elements
 * from the second one. FALSE otherwise.
 *
 * @param {array} superset
 * @param {array} subset
 *
 * @returns {boolean}
 */
export const arrayContainsArray = (superset, subset) => {
    return subset.every(value => {
        return superset.indexOf(value) >= 0;
    });
};

/**
 * Checks if two objects are equal
 * @param {object} object1
 * @param {object} object2
 * @returns {boolean}  - true if they both are equal
 */
export const shallowEqual = (object1, object2) => {
    const keys1 = Object.keys(object1 || {});
    const keys2 = Object.keys(object2 || {});

    if (keys1.length !== keys2.length) {
        return false;
    }

    for (let key of keys1) {
        if (object1[key] !== object2[key]) {
            return false;
        }
    }

    return true;
};

/**
 * @param {String} sentence
 * @returns String
 */

export const toUpperCaseFirstLetter = sentence => {
    if (!sentence) {
        return '';
    }
    let words = sentence.split(' ');
    words = words.filter(el => el?.length);

    return words
        .map(word => {
            return word[0].toUpperCase() + word.substring(1);
        })
        .join(' ');
};

/**
 *
 * @param {Date} time - the time stamp which you want to compare with current time
 * @param {Boolean} isMonth -
 * @returns - String
 */

export const updatedTime = time => {
    const userTimeZone = getUserTimeZone();
    let currentTime = dayjs(dayjs()).tz(userTimeZone);
    let _updatedTime = dayjs(time).tz(userTimeZone);
    let difInHours = currentTime.add(1, 'seconds').diff(_updatedTime, 'hours');
    let difInMinutes = currentTime.add(1, 'seconds').diff(_updatedTime, 'minutes');
    let difDays = currentTime.add(1, 'seconds').diff(_updatedTime, 'days');
    let difMonths = currentTime.add(1, 'seconds').diff(_updatedTime, 'months');
    let difYears = currentTime.add(1, 'seconds').diff(_updatedTime, 'years');
    if (difYears === 1) {
        return `${difYears} year ago`;
    }
    if (difYears > 1) {
        return `${difYears} years ago`;
    }
    if (difMonths === 1) {
        return `${difMonths} month ago`;
    }
    if (difMonths > 1) {
        return `${difMonths} months ago`;
    }
    if (difDays === 1) {
        return `${difDays} day ago`;
    }
    if (difDays > 1) {
        return `${difDays} days ago`;
    }
    if (difInHours === 1) {
        return `${difInHours} hour ago`;
    }
    if (difInHours > 1) {
        return `${difInHours} hours ago`;
    }
    if (difInMinutes === 1) {
        return `${difInMinutes} Minute ago`;
    }
    if (difInMinutes > 1) {
        return `${difInMinutes} Minutes ago`;
    }
    return `just now`;
};

// compare function to sort according to the order
/**
 *
 * @param {object} a
 * @param {object} b
 * @returns
 */

export const compare = (a, b) => {
    if (a.order < b.order) {
        return -1;
    }
    if (a.order > b.order) {
        return 1;
    }
    return 0;
};

/**
 * @param {string} text
 * @param {boolean} removeAll
 */

export const convertHTMLToText = (text, removeAll) => {
    text = text
        // Remove style tags and content
        .replace(/<style[^>]*>.*<\/style>/gm, '')
        // Remove script tags and content
        .replace(/<script[^>]*>.*<\/script>/gm, '')
        // Remove all opening, closing and orphan HTML tags
        .replace(/<[^>]+>/gm, '')
        // Remove leading spaces and repeated CR/LF
        .replace(/([\r\n]+ +)+/gm, '')
        // Remove &nbsp; from the script
        .replace(/&nbsp;/gm, '');

    if (removeAll) {
        return (
            text
                // Remove all { open curely braces
                .replace(/{/gm, '')
                // Remove all } closed curely braces
                .replace(/}/gm, '')
        );
    }

    return text;
};

/**
 *
 * @param {string} title
 * @param {string} subTitle
 * @description function to set the title of the page
 */

export const setTitle = (title = 'Dashboard', subTitle) => {
    if (typeof title !== 'string') {
        throw new Error('Title should be an string');
    }
    if (subTitle) {
        if (typeof subTitle !== 'string') {
            throw new Error('Title should be an string');
        }
        document.title = `${title} > ${subTitle}`;
        return;
    }
    document.title = `${title}`;
};

/**
 *
 * @param {number} num
 * @param {String} locale // https://gist.github.com/jacobbubu/1836273
 * @returns a formated string
 */

const isFloat = num => num % 1 !== 0;

export const formatNumberString = (num, needDecimal = false, locale = 'en-US') => {
    if (num === null || num === undefined || num === 'undefined' || isNaN(num)) {
        return needDecimal ? '0.00' : '0';
    }
    if (isFloat(num) || needDecimal)
        return new Intl.NumberFormat(locale, { minimumFractionDigits: 2 }).format(num);
    const res = new Intl.NumberFormat(locale).format(num);
    if (typeof res === 'string') {
        return res;
    }
    if (isNaN(res)) {
        return '0';
    }
    return res;
};

/**
 *
 * @param {string} phoneNum
 * @returns remove all highfens and space between number and if + is not attached at the beginning then attach it
 */

export const sanitizePhoneNumber = phoneNum => {
    if (phoneNum) {
        const temp = phoneNum.replace(/\D/g, '');
        if (phoneNum[0] !== '+') return `+1${temp}`;
        else return `+${temp}`;
    } else {
        return phoneNum;
    }
};

/**
 * @param {timestamp} createdAt
 */

export const getColor = createdAt => {
    const userTimeZone = getUserTimeZone();
    let created = dayjs(createdAt).tz(userTimeZone);
    let currentTime = dayjs(dayjs()).tz(userTimeZone);
    var duration = dayjs.duration(currentTime.diff(created));
    const { minutes, hours, days, months, years } = duration.$d;
    if (hours > 24 || days >= 1 || months > 0 || years > 0) {
        return colors.PURPLE_COLOR;
    }
    if (hours >= 1) {
        return colors.BLUE_COLOR;
    }
    if (hours === 0 && minutes >= 15) {
        return colors.ORANGE_COLOR;
    }
    if (hours === 0 && minutes < 1) {
        return colors.RED_CHECK;
    }
    return colors.RED_COLOR;
};

/**
 *
 * @param {string} urltoShort
 * @returns string
 */

export const shortenUrl = async urltoShort => {
    if (urltoShort.search(/^http[s]?\:\/\//) == -1) {
        urltoShort = 'http://' + urltoShort;
    }
    const res = await handleShortenURL({ url: urltoShort.toString() });
    if (res.success) {
        return `https://urlme.app/` + res.data.code;
    }
    return 'something went wrong';
};

/**
 * @param {number} seconds
 */

export const convertSecsToHoursMinsSecs = sec => {
    let secNum = parseInt(sec, 10);
    let hours = ~~(secNum / 3600);
    let minutes = ~~((secNum - hours * 3600) / 60);
    let seconds = secNum - hours * 3600 - minutes * 60;

    if (hours) {
        return (
            hours +
            (minutes ? `:${('0' + minutes).slice(-2)}` : seconds ? `:00` : '') +
            (seconds ? `:${('0' + seconds).slice(-2)}` : '') +
            (hours === 1 ? ' hour' : ' hours')
        );
    }

    if (minutes) {
        return (
            minutes +
            (seconds ? `:${('0' + seconds).slice(-2)}` : '') +
            (minutes === 1 ? ' minute' : ' minutes')
        );
    }

    return seconds + (seconds === 1 ? ' sec' : ' secs');
};

/**
 *
 * @param {string} code
 * @returns
 */

export const getCountryNameByCode = code => {
    const ind = CountryCode.findIndex(country => country.code === code);
    if (ind !== -1) {
        return CountryCode[ind].name;
    }
    return 'Wrong Country Code';
};

export const formatUrl = url => {
    if (url.match(/^http.*$/)) return url;
    return `http://${url}`;
};

export const getInterval = interval => {
    switch (interval) {
        case 'month':
        case 'monthly':
            return 'Monthly Subscription';
        case 'day':
            return 'Daily Subscription';
        case 'week':
            return 'Weekly Subscription';
        case 'year':
        case 'yearly':
            return 'Yearly Subscription';
        default:
            return '';
    }
};

/**
 *
 * @return color hexcode
 */

export const getRandomColorCode = () => {
    return '#xxxxxx'.replace(/x/g, () => ((Math.random() * 16) | 0).toString(16));
};

/**
 *
 * @return true if current browser is safari
 */
export const isSafari = () => {
    return (
        navigator.userAgent.indexOf('Safari') != -1 && navigator.userAgent.indexOf('Chrome') == -1
    );
};

export const getBase64FromUrl = async url => {
    const data = await fetch(url);
    const blob = await data.blob();
    return new Promise(resolve => {
        const reader = new FileReader();
        reader.readAsDataURL(blob);
        reader.onloadend = () => {
            const base64data = reader.result;
            resolve(base64data);
        };
    });
};
