import { useEffect, useState, useCallback } from 'react';
import { getComponents } from './routes';
import Routes from 'Constants/routes.json';
import SubRoutes from 'Constants/subroutes.json';
import { menuData, useUsersData } from 'apis/root/index.api';
import { getAccountType, isAuthenticated } from 'Utils/auth';
import areValidScopes from 'Utils/scopeUtils';
import { getAccountDetails, getSessionId, getUserDetails } from 'Utils/localStorageUtils';
import usePubSub from 'Hooks/PubSub';
import { useQuery } from 'react-query';
import AccessScopes from 'Constants/scopes';

let globalMenuData;

export const useGetScopes = fetchRoutes => {
    let { data: userAuthData } = useUsersData();
    const [userScope, setUserScope] = useState([]);
    const [routes, setRoutes] = useState([]);
    const [loading, setLoading] = useState(true);

    const checkUserData = useCallback(async authData => {
        if (authData) {
            let res, data, scopes;
            const sessionId = getSessionId();
            const _authenticated = isAuthenticated();

            let customRoute = [];

            if (sessionId && _authenticated) {
                if (globalMenuData && globalMenuData.config.length) {
                    res = authData;
                    data = globalMenuData;
                    scopes = res.user.scope;
                } else {
                    res = await menuData();
                    data = res;
                    scopes = authData.user.scope;
                    globalMenuData = res;
                }
                if (!getAccountType()) {
                    setUserScope([]);
                    setLoading(false);
                    return;
                } else if (getAccountType() === 'main') {
                    let _routes = [];
                    if (data.config) {
                        _routes = [...customRoute, ...Routes.data, ...data.config];
                    }
                    const _d = _routes.map(route => {
                        return getComponents(route.name, scopes);
                    });
                    setUserScope(_d);
                    setLoading(false);
                    return;
                } else if (getAccountType() === 'sub-account') {
                    const _routes = [...SubRoutes.data, ...data.config, ...customRoute];
                    const _d = _routes.map(route => {
                        return getComponents(route.name, scopes);
                    });
                    setUserScope(_d);
                    setLoading(false);
                    return;
                }
                setUserScope([]);
                setLoading(false);
            }
        }
    }, []);

    useQuery(['user-data'], () => checkUserData(userAuthData), {
        keepPreviousData: true,
        staleTime: 1000 * 60 * 10, // 10 minutes
        cacheTime: 1000 * 60 * 15, // 15 minutes
        enabled: isAuthenticated(),
    });

    useEffect(() => {
        if (userAuthData) checkUserData(userAuthData);
    }, [checkUserData, userAuthData]);

    useEffect(() => {
        setRoutes(fetchRoutes(userScope));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userScope]);

    usePubSub('login-success', authData => {
        try {
            setLoading(true);
            checkUserData(authData?.auth);
        } catch (Err) {
            console.error('Error', Err);
        }
    });
    return {
        routes,
        userScope,
        userAuthData,
        loading,
        setLoading,
    };
};

export const getMenuData = async () => {
    let data;

    if (globalMenuData && globalMenuData.config && globalMenuData.config.length) {
        data = globalMenuData.config;
    } else {
        const res = await menuData();
        globalMenuData = res;
        data = res.config;
    }
    if (data) {
        for (const route of data) {
            if (route.name === 'conversations')
                route.isLock = !areValidScopes('conversation', 'conversation.support');
            else if (route.name === AccessScopes.root.DEALS.text)
                route.isLock = !areValidScopes(
                    AccessScopes.root.DEALS.text,
                    AccessScopes.deals.DEALS_READ.text,
                );
            else route.isLock = !areValidScopes(route.name);
            route.hide = route.isLock && getAccountDetails()?.hide_app;
        }
        const general = getUserDetails()?.dashclicks?.general;
        if (general && data.find(route => route.name === 'dashclicks') === undefined) {
            data.push({
                fav_order: 20,
                hide: false,
                id: 20,
                isFavorite: false,
                isLock: false,
                label: 'DashClicks',
                menu_order: 20,
                name: 'dashclicks',
            });
        }
        if (data.find(route => route.name === 'dashboard') === undefined) {
            data.push({
                fav_order: 20,
                hide: false,
                id: 20,
                isFavorite: false,
                isLock: false,
                label: 'Dashboard',
                menu_order: 20,
                name: 'dashboard',
            });
        }
    }

    return data;
};
