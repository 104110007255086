import React from 'react';

import { SVGIcon } from '../../UIKit/index';

export default function DCLink2(props) {
    return (
        <SVGIcon {...props} viewBox="0 0 512 400">
            <g transform="translate(-434.49 -12006.368)">
                <g transform="translate(434.018 12006.721)">
                    <path
                        fill="#FFFFFF"
                        d="M0.08,66.16h512.16l0,0v297.77c0,19.73-16,35.73-35.73,35.73H35.81    c-19.73,0-35.73-16-35.73-35.73V66.16L0.08,66.16z"
                    />
                    <g transform="translate(21.736 24.997)">
                        <path
                            fill="#3F50F6"
                            d="M145.01,215.29c0,0-17.19,5.7-19.46,21c0,0-2.27,15.29,8.34,23.2     c7.4,5.74,16.86,8.1,26.08,6.52c0,0,31.36-10.09,45.26-23.31c8.12-9.85,7.28-24.29-1.92-33.14c-7.33-8.58-19.44-11.31-29.75-6.71     L145.01,215.29l-7.86-18.03c12.68-6.5,25.99-11.7,39.72-15.53c17.2-2.57,34.47,4.21,45.31,17.81c0,0,21.44,24.63-3.08,58.57     c0,0-13.26,14.72-57.95,27.04c0,0-41.5,7.35-54.87-31.52c0,0-11.02-41.69,30.87-56.37L145.01,215.29z"
                        />
                        <path
                            fill="#3F50F6"
                            d="M278.55,160.66c0,0-17.19,5.7-19.46,21c0,0-2.27,15.3,8.34,23.2     c7.4,5.74,16.86,8.1,26.08,6.52c0,0,31.36-10.09,45.26-23.31c8.12-9.85,7.28-24.29-1.92-33.14c-7.33-8.58-19.44-11.31-29.75-6.71     L278.55,160.66l-7.86-18.03c12.69-6.5,26-11.7,39.73-15.53c17.2-2.57,34.46,4.21,45.31,17.81c0,0,21.44,24.62-3.08,58.57     c0,0-13.26,14.72-57.95,27.04c0,0-41.5,7.35-54.87-31.52c0,0-11.02-41.69,30.87-56.37L278.55,160.66z"
                        />
                        <path
                            fill="#B2B9FF"
                            d="M182.26,218.71l97.67-42.57c4.98-2.17,10.78,0.11,12.95,5.09l0,0     c2.17,4.98-0.11,10.78-5.09,12.95l-97.67,42.57c-4.98,2.17-10.78-0.11-12.95-5.09l0,0C175,226.68,177.28,220.88,182.26,218.71z"
                        />
                    </g>
                    <path
                        fill="#3F50F6"
                        d="M35.81,0.65h440.7c19.73,0,35.73,16,35.73,35.73v41.69l0,0H0.08l0,0V36.38    C0.08,16.65,16.07,0.65,35.81,0.65z"
                    />
                    <path
                        fill="#C3CAFD"
                        d="M425.12,30.2c6.15,0,11.14,4.99,11.14,11.14s-4.99,11.14-11.14,11.14    c-6.15,0-11.14-4.99-11.14-11.14S418.97,30.2,425.12,30.2C425.12,30.2,425.12,30.2,425.12,30.2z"
                    />
                    <path
                        fill="#FFFFFF"
                        d="M463.96,30.2c6.15,0,11.14,4.99,11.14,11.14s-4.99,11.14-11.14,11.14    c-6.15,0-11.14-4.99-11.14-11.14S457.81,30.2,463.96,30.2C463.96,30.2,463.96,30.2,463.96,30.2z"
                    />
                    <path fill="#FFFFFF" d="M172.96,81.03l17.24-25.2h93.85l17.24,25.2H172.96z" />
                    <path fill="#C3CAFD" d="M44.63,81.03l17.24-27.12h93.85l17.24,27.12H44.63z" />
                </g>
            </g>
        </SVGIcon>
    );
}
