import React from 'react';

import { SVGIcon } from '../../UIKit/index';

export default function DCContactMenu(props) {
    return (
        <SVGIcon {...props} viewBox="0 0 512 470.1">
            <g transform="translate(-46.518 0.002)">
                <g transform="translate(46.518 -0.002)">
                    <g transform="translate(20.993)">
                        <g>
                            <g>
                                <path
                                    fill="#E6EFFF"
                                    d="M489.2,449V22.4c-2.1-12.4-16-21.2-31.4-19.9H131.4L103.3,17v437.5l28.1,14.5h326.3       C473.2,470.3,487.1,461.4,489.2,449z"
                                />
                                <path
                                    fill="#2F8DFF"
                                    d="M128.3,2.5H55.5c-14.5-0.7-27,8.2-27.9,19.9V449c1,11.7,13.4,20.6,27.9,19.9h72.8V2.5z"
                                />
                            </g>
                        </g>
                    </g>
                    <g transform="translate(94.521 38)">
                        <g transform="translate(0 94.277)">
                            <g transform="translate(0.054)">
                                <path
                                    fill="#2F8DFF"
                                    d="M328,195H133.6c-5.5-0.1-9.8-4.6-9.7-10c0.1-5.3,4.4-9.7,9.7-9.7h194.5       c5.5,0.1,9.8,4.6,9.7,10C337.7,190.6,333.4,194.9,328,195L328,195z"
                                />
                            </g>
                            <g transform="translate(0 23.44)">
                                <path
                                    fill="#2F8DFF"
                                    d="M328.1,225.6H133.6c-5.5,0-9.9-4.5-9.8-10c0-5.4,4.3-9.7,9.7-9.8h194.6       c5.5,0.1,9.8,4.6,9.7,10C337.8,221.2,333.5,225.5,328.1,225.6L328.1,225.6z"
                                />
                            </g>
                        </g>
                        <g transform="translate(4.743 0)">
                            <path
                                fill="#2F8DFF"
                                d="M226.1,129c-43.1,0-81.6,27-96.2,67.6c3.4,11.8,14.2,19.9,26.4,19.9H296      c12.3,0,23.1-8.1,26.4-19.9C307.8,156,269.3,128.9,226.1,129z"
                            />
                            <circle fill="#2F8DFF" cx="226.1" cy="95.7" r="43.6" />
                        </g>
                    </g>
                    <g transform="translate(0 40)">
                        <path
                            fill="#E4F6FF"
                            d="M73.5,101.6H23.7c-13,0-23.5-10.5-23.5-23.5c0-13,10.5-23.5,23.5-23.5h49.8     c13,0,23.5,10.5,23.5,23.5C97,91.1,86.5,101.6,73.5,101.6z"
                        />
                        <path
                            fill="#E4F6FF"
                            d="M73.5,218.5H23.7c-13,0-23.5-10.5-23.5-23.5c0-13,10.5-23.5,23.5-23.5c0,0,0,0,0,0h49.8     c13,0,23.5,10.5,23.5,23.5C97,208,86.5,218.5,73.5,218.5C73.5,218.5,73.5,218.5,73.5,218.5z"
                        />
                        <path
                            fill="#E4F6FF"
                            d="M73.5,335.4H23.7c-13,0-23.5-10.5-23.5-23.5c0-13,10.5-23.5,23.5-23.5h49.8     c13,0,23.5,10.5,23.5,23.5C97,324.9,86.5,335.4,73.5,335.4z"
                        />
                    </g>
                </g>
            </g>
        </SVGIcon>
    );
}
