import React from 'react';

import DCCrossClose from 'Assets/icons/DCCrossClose';
import { Dialog, Div, H1, IconButton, TopBar, Image, Rating } from 'UIKit/index';
import generateImage from 'Utils/generateImage';

// addType - 'people' / 'businesses'
const ReviewDialog = ({
    open,
    onClose,
    title,
    profileImg,
    name,
    createdAt,
    rating,
    body,
    reviewPageURL,
}) => {
    return (
        <Dialog
            dialogType={'centerZoom sizeSm'}
            classes={{ row: 'ReviewDialogRow' }}
            open={open}
            onClose={onClose}
        >
            <Div className={'ReviewDialog'}>
                <TopBar>
                    <Div className={'HeaderBar'}>
                        <H1 className={'HeaderBarTitle'}>{title}</H1>
                        <Div className={'HeaderBarClose'}>
                            <IconButton onClick={onClose}>
                                <DCCrossClose />
                            </IconButton>
                        </Div>
                    </Div>
                </TopBar>
                <Div className={'BodyBox'}>
                    <Div className={'BodyBoxInner'}>
                        <Div className={'RDInfoBox'}>
                            <Div className={'RDIHead'}>
                                <Div className={'RDIHUser'}>
                                    <Div className={'RDIHUImg'}>
                                        <Image src={generateImage(profileImg, name)} alt="" />
                                    </Div>
                                    <Div className={'RDIHUInfo'}>
                                        {reviewPageURL ? (
                                            <a
                                                href={reviewPageURL}
                                                target="_blank"
                                                rel="noreferrer"
                                            >
                                                {name}
                                            </a>
                                        ) : (
                                            <Div className={'RDIHUIName'}>{name}</Div>
                                        )}
                                        <Div className={'RDIHUIDate'}>{createdAt}</Div>
                                    </Div>
                                </Div>
                                <Div className={'RDIHReview'}>
                                    {rating && <Rating value={rating} />}
                                </Div>
                            </Div>
                            <Div className={'RDIBody'}>{body}</Div>
                        </Div>
                    </Div>
                </Div>
            </Div>
        </Dialog>
    );
};

export default ReviewDialog;
