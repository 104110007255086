import React from 'react';

import generateImage from '../../Utils/generateImage';
import { Div, Image, TextOverflowTooltip } from '../index';
import PropTypes from 'prop-types';
// import { generateFilePath } from 'Utils/downloadFile';
import './style.scss';

const UserInfo = ({ name, email, image, className, hideImage = false }) => {
    return (
        <Div className={'UserInfo ' + (className ? className : '')}>
            {!hideImage && (
                <Div className="UIImg">
                    <Image
                        src={
                            typeof image === 'string'
                                ? image
                                : generateImage(image, name, null, email)
                        }
                        displayName={name || email}
                        alt=""
                    />
                </Div>
            )}
            <Div className="UIDetail">
                {name ? (
                    <Div className="UIDetailName">
                        <TextOverflowTooltip tooltipContent={name}>{name}</TextOverflowTooltip>
                    </Div>
                ) : null}
                {email ? (
                    <Div className={`UIDetailEmail ${!name && 'ColorBlue'}`}>
                        <TextOverflowTooltip tooltipContent={email}>{email}</TextOverflowTooltip>
                    </Div>
                ) : null}
            </Div>
        </Div>
    );
};

UserInfo.propTypes = {
    name: PropTypes.string,
    email: PropTypes.string,
    image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    className: PropTypes.string,
    hideImage: PropTypes.bool,
};

export default UserInfo;
