import React from 'react';

import { SVGIcon } from '../../UIKit/index';

export default function DCSourceReview(props) {
    return (
        <SVGIcon {...props} viewBox="0 0 512 489">
            <g transform="translate(0 -1)">
                <path d="M308.62,490h-21.17c-17.53-0.02-31.75-14.23-31.76-31.76V352.37   c0.02-17.53,14.23-31.75,31.76-31.76h21.18c17.53,0.02,31.75,14.23,31.76,31.76v105.87C340.37,475.78,326.16,489.99,308.62,490z    M287.44,341.74c-5.84,0.02-10.57,4.75-10.58,10.58v105.87c0.02,5.84,4.75,10.57,10.58,10.58h21.18   c5.84-0.02,10.57-4.75,10.58-10.58V352.37c-0.02-5.84-4.75-10.57-10.58-10.58L287.44,341.74z" />
                <path d="M446.26,490h-16.09c-57.17,0-90.71-14.27-105.61-22.73c-4.93-3.17-6.35-9.73-3.19-14.66   c2.93-4.56,8.84-6.18,13.68-3.74c10.52,6.04,40.89,20.01,95.12,20.01h16.09c14.89,0.02,27.72-10.47,30.66-25.06   c0.04-0.31,0.11-0.63,0.19-0.93c1.31-6.37,11.22-65.37,11.51-71.43c-0.11-16.41-13.44-29.66-29.86-29.66h-58.22   c-5.85,0.01-10.6-4.72-10.62-10.57c0-2.12,0.63-4.19,1.81-5.94c5.63-10.33,8.44-21.95,8.15-33.71c0-21.14-8.47-28.8-14.19-28.8   c-1.97,0.27-4.21,3.25-4.21,19.06c0,32.85-32.67,56.76-46.71,65.51c-4.9,3.18-11.45,1.79-14.63-3.1c-3.18-4.9-1.79-11.45,3.1-14.63   c0.11-0.07,0.21-0.14,0.32-0.2c8.66-5.41,36.74-24.71,36.74-47.55c0-13.06,0-40.23,25.39-40.23c17.05,0,35.36,15.63,35.36,49.97   c0.03,9.84-1.44,19.62-4.38,29h42.08c28.17,0.03,51,22.86,51.03,51.03c0,0.68-2.63,53.81-12.91,75.44l0.93,0.17   C493.25,472.1,471.53,490.11,446.26,490z" />
                <path d="M393.37,151.26H75.72c-40.94,0-74.13-33.19-74.13-74.13S34.77,3,75.72,3h317.65   c40.94,0,74.13,33.19,74.13,74.13S434.31,151.26,393.37,151.26z M75.72,24.18c-29.24,0-52.94,23.7-52.94,52.94   s23.7,52.94,52.94,52.94h317.65c29.24,0,52.94-23.7,52.94-52.94s-23.7-52.94-52.94-52.94L75.72,24.18z" />
                <path d="M96.9,108.87c-17.54,0-31.76-14.22-31.76-31.76S79.35,45.35,96.9,45.35s31.76,14.22,31.76,31.76   C128.64,94.65,114.43,108.86,96.9,108.87z M96.9,66.53c-5.85,0-10.58,4.74-10.58,10.58s4.74,10.58,10.58,10.58   c5.85,0,10.58-4.74,10.58-10.58C107.46,71.27,102.73,66.54,96.9,66.53z" />
                <path d="M202.76,108.87c-17.54,0-31.76-14.22-31.76-31.76s14.22-31.76,31.76-31.76   s31.76,14.22,31.76,31.76v0C234.5,94.65,220.29,108.86,202.76,108.87z M202.76,66.53c-5.85,0-10.58,4.74-10.58,10.58   c0,5.85,4.74,10.58,10.58,10.58s10.58-4.74,10.58-10.58l0,0C213.33,71.27,208.6,66.54,202.76,66.53z" />
                <path d="M308.63,278.31H75.72c-40.94,0-74.13-33.19-74.13-74.13s33.19-74.13,74.13-74.13h317.65   c40.91,0.04,74.07,33.2,74.11,74.11c0.07,8.11-1.3,16.17-4.05,23.81c-2.08,5.46-8.19,8.21-13.66,6.13   c-5.39-2.05-8.14-8.03-6.2-13.46c1.86-5.28,2.79-10.85,2.73-16.45c-0.04-29.22-23.71-52.91-52.93-52.96H75.72   c-29.24,0-52.94,23.7-52.94,52.94s23.7,52.94,52.94,52.94h232.92c5.85,0,10.59,4.74,10.59,10.59s-4.74,10.59-10.59,10.59V278.31z" />
                <path d="M96.9,235.92c-17.54,0-31.76-14.22-31.76-31.76s14.22-31.76,31.76-31.76   s31.76,14.22,31.76,31.76l0,0C128.64,221.69,114.43,235.9,96.9,235.92z M96.9,193.57c-5.85,0-10.58,4.74-10.58,10.58   c0,5.85,4.74,10.58,10.58,10.58c5.85,0,10.58-4.74,10.58-10.58l0,0C107.46,198.31,102.73,193.59,96.9,193.57z" />
                <path d="M202.76,235.92c-17.54,0-31.76-14.22-31.76-31.76s14.22-31.76,31.76-31.76   s31.76,14.22,31.76,31.76C234.5,221.69,220.29,235.9,202.76,235.92z M202.76,193.57c-5.85,0-10.58,4.74-10.58,10.58   c0,5.85,4.74,10.58,10.58,10.58s10.58-4.74,10.58-10.58c0,0,0,0,0,0C213.33,198.31,208.6,193.59,202.76,193.57z" />
                <path d="M202.76,405.35H75.72c-40.94,0-74.13-33.19-74.13-74.13s33.19-74.13,74.13-74.13h232.92   c5.85,0,10.59,4.74,10.59,10.59c0,5.85-4.74,10.59-10.59,10.59H75.72c-29.24,0-52.94,23.7-52.94,52.94   c0,29.24,23.7,52.94,52.94,52.94h127.04c5.85,0,10.59,4.74,10.59,10.59c0,5.85-4.74,10.59-10.59,10.59V405.35z" />
                <path d="M96.9,362.96c-17.54,0-31.76-14.22-31.76-31.76s14.22-31.76,31.76-31.76   s31.76,14.22,31.76,31.76C128.64,348.73,114.43,362.94,96.9,362.96z M96.9,320.61c-5.85,0-10.58,4.74-10.58,10.58   s4.74,10.58,10.58,10.58c5.85,0,10.58-4.74,10.58-10.58C107.46,325.36,102.73,320.63,96.9,320.61z" />
                <path d="M202.76,362.96c-17.54,0-31.76-14.22-31.76-31.76s14.22-31.76,31.76-31.76   s31.76,14.22,31.76,31.76C234.5,348.73,220.29,362.94,202.76,362.96z M202.76,320.61c-5.85,0-10.58,4.74-10.58,10.58   s4.74,10.58,10.58,10.58s10.58-4.74,10.58-10.58C213.33,325.36,208.6,320.63,202.76,320.61z" />
            </g>
        </SVGIcon>
    );
}
