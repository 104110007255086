import React from 'react';

import { SVGIcon } from '../../UIKit/index';

export default function DCClickFunnels(props) {
    return (
        <SVGIcon {...props} viewBox="0 0 512 426">
            <path
                fill="#394C60"
                d="M482.34,1H31.06C15,1.08,2,14.08,1.92,30.13v366.75C2,412.94,15,425.93,31.06,426.01h451.28  c16.06-0.08,29.05-13.08,29.13-29.13V30.13C511.39,14.08,498.39,1.08,482.34,1z M454.76,29c10.27,0,18.59,8.32,18.59,18.59  c0,10.27-8.32,18.59-18.59,18.59c-10.27,0-18.59-8.32-18.59-18.59C436.22,37.35,444.51,29.05,454.76,29z M388.45,29  c10.27,0,18.59,8.32,18.59,18.59c0,10.27-8.32,18.59-18.59,18.59c-10.27,0-18.59-8.32-18.59-18.59  C369.9,37.35,378.2,29.05,388.45,29z M322.13,29c10.27,0,18.59,8.32,18.59,18.59s-8.32,18.59-18.59,18.59  c-10.27,0-18.59-8.32-18.59-18.59c0,0,0,0,0,0C303.59,37.35,311.88,29.05,322.13,29L322.13,29z M474.07,388.3H39.11V91.27h434.96  V388.3z"
            />
            <path
                fill="#3C9BD6"
                d="M174.72,356.72c6.14-10.2,18.31-15.07,29.79-11.92c10.54,3.66,22.04-1.92,25.7-12.46  c0.08-0.22,0.15-0.43,0.21-0.65c4.04-10.85-1.22-22.96-11.92-27.41c-10.4-4.74-16.28-15.91-14.3-27.17  c1.84-11.78,11.85-20.56,23.78-20.85c11.94-0.26,21.42-10.15,21.16-22.09c-0.25-11.72-9.79-21.11-21.52-21.17h-0.36  c-11.95-0.35-22.07-8.9-24.43-20.61c-0.65-3.45-0.65-6.98,0-10.43c1.24-7.72,6.11-14.36,13.1-17.86  c10.21-5.52,14.28-18.09,9.24-28.54c-3.83-11.09-15.92-16.99-27.01-13.17c-5.39,2.16-11.37,2.33-16.86,0.48  c-4.73-2.13-8.83-5.43-11.92-9.59c-4.73-6.3-6.55-14.32-5.01-22.05H65.14c46.08,24.73,89.91,74.42,90.99,140.36  c1.19,71.51-36.64,129.24-92.3,156.65h112.14C168.29,379.29,167.77,366.24,174.72,356.72z"
            />
            <path
                fill="#E74F38"
                d="M314.92,119.87c9.23,7.62,11.87,20.67,6.31,31.28c-4.54,11.02-15.87,17.67-27.71,16.27  c-10.99-2.1-21.6,5.11-23.7,16.11c-0.05,0.27-0.1,0.54-0.14,0.82c-2.38,11.27,4.53,22.41,15.67,25.3  c11.02,3.08,18.55,13.23,18.29,24.67c-0.16,11.61-8.4,21.54-19.78,23.84c-11.78,2.02-19.69,13.22-17.66,25s13.22,19.69,25,17.66  c11.86-1.48,23.17,5.41,27.29,16.63c1.15,3.31,1.67,6.81,1.55,10.31c0.02,7.88-3.78,15.28-10.2,19.86  c-9.36,7.03-11.46,20.2-4.77,29.79c2.94,4.58,7.06,8.28,11.92,10.73h132.64c-42.18-20.91-81.39-60.24-90.99-116.19  c-13.59-79.31,23.84-148.43,86.7-180.72H310.15C303.91,100.52,306,113.08,314.92,119.87z"
            />
        </SVGIcon>
    );
}
