import React from 'react';

import { SVGIcon } from '../../UIKit/index';

export default function DCFillTwitter(props) {
    return (
        <SVGIcon {...props} viewBox="0 0 512 512.00038">
            <linearGradient
                id="DCFillTwitter1"
                gradientUnits="userSpaceOnUse"
                x1="169.9940010834"
                x2="299.4934135904"
                y1="71.98622701"
                y2="349.0548448864"
            >
                <stop offset="0" stopColor="#00c3ff" />
                <stop offset="1" stopColor="#00b0ed" />
            </linearGradient>
            <linearGradient id="DCFillTwitter2">
                <stop offset="0" stopColor="#00b0ed" stopOpacity="0" />
                <stop offset="1" stopColor="#00a2ce" />
            </linearGradient>
            <linearGradient
                id="c"
                gradientUnits="userSpaceOnUse"
                x1="364.2825237824"
                x2="-379.5864327114"
                y1="314.0868361356"
                y2="-84.9521808166"
            />
            <linearGradient
                id="DCFillTwitter3"
                gradientUnits="userSpaceOnUse"
                x1="256.0003"
                x2="256.0003"
                y1="437.383215389"
                y2="504.4720097064"
            />
            <linearGradient
                id="DCFillTwitter4"
                gradientUnits="userSpaceOnUse"
                x1="489.4209995136"
                x2="239.2937328214"
                y1="482.0724016502"
                y2="231.945134958"
            />
            <path
                d="m420.421875 503.234375c-109.503906 11.6875-219.339844 11.6875-328.84375 0-43.664063-4.660156-78.152344-39.148437-82.8125-82.816406-11.6875-109.503907-11.6875-219.335938 0-328.839844 4.660156-43.664063 39.148437-78.152344 82.8125-82.8125 109.503906-11.6875 219.335937-11.6875 328.839844 0 43.667969 4.660156 78.15625 39.148437 82.816406 82.8125 11.6875 109.503906 11.6875 219.335937 0 328.839844-4.660156 43.667969-39.144531 78.15625-82.8125 82.816406zm0 0"
                fill="url(#DCFillTwitter1)"
            />
            <path
                d="m475.386719 110.097656c-4.132813-38.746094-34.734375-69.351562-73.484375-73.488281-97.171875-10.367187-194.632813-10.367187-291.804688 0-38.746094 4.136719-69.351562 34.742187-73.488281 73.488281-10.367187 97.171875-10.367187 194.632813 0 291.800782 4.136719 38.75 34.742187 69.355468 73.488281 73.488281 97.171875 10.371093 194.632813 10.371093 291.800782 0 38.75-4.132813 69.355468-34.738281 73.488281-73.488281 10.371093-97.167969 10.371093-194.628907 0-291.800782zm0 0"
                fill="url(#c)"
            />
            <path
                d="m7.671875 409.804688c.351563 3.539062.714844 7.078124 1.09375 10.617187 4.660156 43.664063 39.148437 78.152344 82.816406 82.8125 109.503907 11.6875 219.335938 11.6875 328.839844 0 43.667969-4.660156 78.152344-39.148437 82.8125-82.8125.378906-3.539063.742187-7.078125 1.097656-10.617187zm0 0"
                fill="url(#DCFillTwitter3)"
            />
            <path
                d="m511.890625 238.007812-106.277344-106.273437c-11.910156 6.808594-22.40625 10.585937-30.3125 12.679687-4.445312 1.191407-8.074219 1.839844-10.683593 2.195313-11.984376-12.285156-28.71875-19.914063-47.242188-19.914063-36.445312 0-65.996094 29.546876-65.996094 66 0 1.316407.035156 2.617188.121094 3.914063.175781 3.160156.578125 6.265625 1.199219 9.285156-15.214844-.226562-64.667969-4.289062-111.832031-44.71875-.003907 0-.003907-.003906-.003907-.003906h-.003906l-22.242187-22.246094s-26.09375 45.542969 12.269531 80.101563c1.683593 1.878906 3.535156 3.734375 5.5625 5.558594.160156.179687.328125.355468.488281.535156-5.035156-.339844-20.179688-1.847656-28.945312-8.039063 0 0-1.761719 27.835938 21.429687 48.328125l18.726563 18.886719c-5.703126.472656-12.011719.359375-16.398438-1.5625 0 0 3.882812 16.488281 18.890625 29.683594l26.234375 26.371093c-15.738281 9.167969-43.289062 20.640626-79.554688 17.710938l159.144532 155.5c54.679687-.015625 109.359375-2.9375 163.957031-8.765625 43.667969-4.660156 78.15625-39.148437 82.816406-82.8125 6.480469-60.742187 9.359375-121.585937 8.652344-182.414063zm0 0"
                fill="url(#DCFillTwitter4)"
            />
            <path
                d="m414.683594 157.367188c-11.328125 5.925781-28.390625 8.664062-34.046875 9.453124-.046875-.273437-.09375-.535156-.140625-.796874 19.230468-11.894532 25.117187-34.289063 25.117187-34.289063-11.910156 6.808594-22.40625 10.585937-30.3125 12.679687-4.445312 1.191407-8.074219 1.835938-10.683593 2.195313-11.984376-12.289063-28.71875-19.914063-47.242188-19.914063-36.445312 0-65.996094 29.542969-65.996094 66 0 1.3125.035156 2.617188.121094 3.910157.175781 3.160156.578125 6.265625 1.199219 9.285156-17.640625-.261719-81.316407-5.671875-134.082031-66.964844 0 0-29.382813 51.265625 20.015624 86.285157 0 0-20.015624-.625-30.640624-8.128907 0 0-3.125 49.398438 53.769531 65.023438 0 0-20.003907 5.007812-30.015625.628906 0 0 10.007812 42.515625 60.027344 45.648437 0 0-35.875 32.84375-94.457032 28.117188 28.8125 18.242188 62.972656 28.800781 99.597656 28.800781 102.980469 0 186.464844-83.480469 186.464844-186.460937 0-1.285156-.011718-2.570313-.039062-3.855469.027344-.761719.039062-1.519531.039062-2.289063 0-.535156-.011718-1.070312-.019531-1.605468 19.039063-12.6875 31.324219-33.722656 31.324219-33.722656zm0 0"
                fill="#fff"
            />
        </SVGIcon>
    );
}
