import React from 'react';

import { SVGIcon } from '../../UIKit/index';

export default function DCPrinter(props) {
    return (
        <SVGIcon {...props} viewBox="0 0 486 512">
            <path d="M410.72,105.06h-13.1V75.89c-0.05-41.6-33.76-75.32-75.37-75.37H164.02  C122.43,0.59,88.73,34.3,88.69,75.89v29.17h-11.8c-41.67-0.03-75.48,33.72-75.52,75.4c0,0,0,0,0,0V302  c0.08,41.52,33.7,75.18,75.22,75.31h11.8v95.55c0.03,20.77,16.87,37.61,37.64,37.64h233.75c20.77-0.03,37.61-16.87,37.64-37.64  V377.3h13.22c41.6-0.05,75.32-33.76,75.37-75.37V180.46C485.97,138.88,452.3,105.16,410.72,105.06z M117.09,75.89  c0.03-25.86,20.99-46.81,46.84-46.84h158.11c25.83,0.05,46.77,20.98,46.81,46.81v29.17H117.09L117.09,75.89z M368.98,473.09  c-0.03,5-4.08,9.05-9.09,9.09H126.21c-5-0.03-9.05-4.08-9.09-9.09V315.48h251.86L368.98,473.09z M457.48,302  c-0.05,25.83-20.98,46.77-46.81,46.81h-13.1v-33.33h18.82c7.88-0.36,13.97-7.03,13.61-14.91c-0.34-7.38-6.24-13.28-13.61-13.61  H67.74c-7.88-0.36-14.55,5.74-14.91,13.61c-0.36,7.88,5.74,14.55,13.61,14.91c0.43,0.02,0.87,0.02,1.3,0h21.03v33.42h-11.8  c-25.83-0.05-46.77-20.98-46.81-46.81V180.46c0.08-25.66,20.77-46.5,46.43-46.75h334.13c25.81,0.05,46.73,20.94,46.81,46.75  L457.48,302z" />
            <path d="M151.67,383.06h181.68c7.88,0.36,14.55-5.74,14.91-13.62c0.36-7.88-5.74-14.55-13.62-14.91  c-0.43-0.02-0.86-0.02-1.29,0H151.67c-7.88-0.36-14.55,5.74-14.91,13.62c-0.36,7.88,5.74,14.55,13.62,14.91  C150.8,383.08,151.24,383.08,151.67,383.06z" />
            <path d="M334.61,412.88h-181.8c-7.88-0.36-14.55,5.74-14.91,13.62c-0.36,7.88,5.74,14.55,13.62,14.91  c0.43,0.02,0.86,0.02,1.29,0H334.5c7.88-0.36,13.97-7.03,13.62-14.91c-0.33-7.38-6.24-13.28-13.62-13.62H334.61z" />
            <path d="M410.43,158.46h-28.97c-7.88-0.36-14.55,5.74-14.91,13.61s5.74,14.55,13.61,14.91  c0.43,0.02,0.87,0.02,1.3,0h28.97c7.88,0.36,14.55-5.74,14.91-13.61c0.36-7.88-5.74-14.55-13.61-14.91  C411.29,158.44,410.86,158.44,410.43,158.46z" />
        </SVGIcon>
    );
}
