import React, { Fragment, useCallback, useRef, useEffect, useState } from 'react';
import _ from 'lodash';
import { Div, Checkbox } from 'UIKit/index';
import DCFillClose from 'Assets/icons/DCFillClose';
import './style.scss';
import { detailedInfo } from './constants';
import { formateDate } from 'Utils/dateUtils';
import { cancelSubscription } from 'apis/store/index.api';
import useToast from 'Modules/Toasts';
interface ConfirmationProps {
    type: { service: boolean; serviceName: string; plan: any };
    setCanContinue: any;
    setStepsData?: any;
    stepsData: { current: any };
    onContinue: { current: any };
    doContinue: () => void;
}
const SCStep2: React.FC<ConfirmationProps> = ({
    type,
    stepsData,
    setCanContinue,
    onContinue,
    doContinue,
}) => {
    const { showToast } = useToast();
    const [termsAndConditions, setTermsAndConditions] = useState<{
        title: string;
        value: string;
        description: Array<string>;
    } | null>();

    const [acknowledged, setAcknowledged] = useState<boolean>(false);
    const firstRun = useRef<boolean>(true);
    useEffect(() => {
        if (firstRun.current) {
            setCanContinue(false);
            firstRun.current = false;
        }
        if (type.service) {
            const termsAndConditions = _.cloneDeep(detailedInfo);
            let description = termsAndConditions.find(item => item.title === type.serviceName);
            if (description) {
                description.description[0] = `${description.description[0]} ${formateDate(
                    new Date(type.plan.current_period_end * 1000),
                )}`;
                setTermsAndConditions(description);
            }
        }
    }, [setCanContinue, type]);

    const handleAcknowledged = useCallback(
        (e: any) => {
            setAcknowledged(e.target.checked);
            if (e.target.checked) {
                setCanContinue(true);
            } else {
                setCanContinue(false);
            }
        },
        [setCanContinue],
    );

    useEffect(() => {
        onContinue.current = () => {
            return new Promise((resolve, reject) => {
                setCanContinue(false);
                const body = {
                    product_type:
                        type.plan?.price?.product?.metadata?.product_type ||
                        type.plan?.price?.product?.metadata?.v1_product_type,
                    reason: stepsData.current.cancellationReason?.reason,
                    feedback: stepsData.current.cancellationReason?.description,
                    is_confirmed: true,
                };
                cancelSubscription(type.plan?.id, body)
                    .then(res => {
                        if (res.success) {
                            doContinue();
                            resolve(true);
                        }
                    })
                    .catch(err => {
                        showToast({
                            type: 'error',
                            message: err.message || 'Something went wrong',
                            duration: 2000,
                        });
                        setCanContinue(true);
                        reject();
                    });
            });
        };
        onContinue.current.funcType = 'promise';
        onContinue.current.shouldContinue = false;
        return () => {};
    }, [doContinue, onContinue, setCanContinue, showToast, stepsData, type]);

    return (
        <Fragment>
            <Div className={'SimpleInputViewMain'}>
                <Div className={'SimpleInputView'}>
                    <Div className={'SCStep2'}>
                        <Div className={'SDStepHead2'}>
                            <Div className={'SDSH2Title'}>
                                <b>
                                    This subscription is <u>NOT</u> canceled yet...
                                </b>
                            </Div>
                            <Div className={'SDSH2STitle'}>
                                Please confirm that you would like to cancel this subscription and
                                that you are aware of all the items listed below.
                            </Div>
                        </Div>
                        <Div className={'SDSHBody'}>
                            <Div className={'SDSHBInner'}>
                                {termsAndConditions?.description.map(
                                    (condition: string, index: number) => (
                                        <Div key={index} className={'SDSHBIItem'}>
                                            <Div className={'SDSHBIIIcon'}>
                                                <DCFillClose />
                                            </Div>
                                            <Div className={'SDSHBIIText'}>{condition}</Div>
                                        </Div>
                                    ),
                                )}
                            </Div>
                        </Div>
                        <Div className={'SDSHFooter'}>
                            <Checkbox
                                unCheckColor={'#eaeaea'}
                                checkColor={'var(--dark-blue)'}
                                fullWidth={true}
                                label={
                                    <>
                                        I acknowledge and confirm that I am aware of the items
                                        listed above and would still like to cancel this
                                        subscription.
                                    </>
                                }
                                checked={acknowledged}
                                onChange={handleAcknowledged}
                            />
                        </Div>
                    </Div>
                </Div>
            </Div>
        </Fragment>
    );
};

export default SCStep2;
