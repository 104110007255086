import React from 'react';

import { SVGIcon } from '../../UIKit/index';

export default function DCUser2(props) {
    return (
        <SVGIcon {...props} viewBox="0 0 512 512">
            <path d="M437.02,74.981C388.667,26.629,324.38,0,256,0S123.333,26.629,74.98,74.981C26.629,123.333,0,187.62,0,256    s26.629,132.667,74.98,181.019C123.333,485.371,187.62,512,256,512s132.667-26.629,181.02-74.981    C485.371,388.667,512,324.38,512,256S485.371,123.333,437.02,74.981z M256,482c-66.869,0-127.037-29.202-168.452-75.511    C113.223,338.422,178.948,290,256,290c-49.706,0-90-40.294-90-90s40.294-90,90-90s90,40.294,90,90s-40.294,90-90,90    c77.052,0,142.777,48.422,168.452,116.489C383.037,452.798,322.869,482,256,482z" />
        </SVGIcon>
    );
}
