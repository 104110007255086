import React, { Fragment, useCallback, useEffect, useRef, useState } from 'react';

import imageCompression from 'browser-image-compression';

import DCCrossClose from '../../Assets/icons/DCCrossClose';
import DCFileUpload1 from '../../Assets/icons/DCFileUpload1';
import DCFillPencil from '../../Assets/icons/DCFillPencil';
import useMediaQuery from 'UIKit/useMediaQuery';
import PropTypes from 'prop-types';
import { upperCaseFirstLetter } from 'Utils/helper';
import FilePreviewDialog from '../../Components/FilePreviewDialog';
import { newButtonType } from '../../Constants/contacts';
import useToast from '../../Modules/Toasts';
import { downloadFilesFromWasabi, generateFilePath } from '../../Utils/downloadFile';
import generateImage from '../../Utils/generateImage';
import { fileUploadSTS } from '../../Utils/uploadFunc';
import { Validation } from '../../Utils/validator';
import DropdownOptions from '../FormElement/dropdownOptions';
import {
    Button,
    CropImage,
    Dialog,
    Div,
    FieldControl,
    H1,
    IconButton,
    Image as Imagea,
    Input,
    Label,
    LabelControl,
    Link,
    Popover,
    TextOverflowTooltip,
    TopBar,
} from '../index';

import './style.scss';

const UserDetailEdit = ({
    customClass,
    image,
    status,
    name,
    jobTitle,
    website,
    userType,
    saveInformation,
    closePopOver,
    loading,
    showEdit,
    showImageEdit,
    email,
}) => {
    const matche575 = useMediaQuery('(max-width: 575.98px)');
    const { showToast } = useToast();
    const editButtonRef = useRef();
    const [editPopoverOpen, setEditPopoverOpen] = useState(false);
    const [contactName, setContactName] = useState(name);
    const [contactJobTitle, setContactJobTitle] = useState(jobTitle);
    const [contactWebsite, setContactWebsite] = useState(website);
    const [disableSave, setDisableSave] = useState(true);
    const [loadingCrop, setLoadingCrop] = useState(false);
    const [errorState, setErrorState] = useState(false);

    const handleEditPopoverToggle = () => {
        setEditPopoverOpen(prevOpen => !prevOpen);
    };
    const handleEditPopoverClose = useCallback(() => {
        setEditPopoverOpen(false);
        setContactName(name);
        setContactJobTitle(jobTitle);
        setContactWebsite(website);
        setErrorState(false);
    }, [jobTitle, name, website]);

    const handleSave = useCallback(() => {
        switch (userType) {
            case newButtonType.PERSON.toLowerCase():
                if (email.length === 0 && contactName.length === 0) {
                    return showToast({
                        type: 'warning',
                        message: 'Both email and name cannot be empty',
                    });
                } else {
                    return saveInformation({
                        name: contactName,
                        job_title: contactJobTitle,
                        email: email?.length ? email : '',
                    });
                }
            case newButtonType.BUSINESS.toLowerCase():
                return saveInformation({
                    name: contactName,
                    website: contactWebsite,
                    email: email?.length ? email : '',
                });
            default:
        }
    }, [contactJobTitle, contactName, contactWebsite, email, saveInformation, showToast, userType]);

    const generatePopOverFields = useCallback(() => {
        switch (userType) {
            case 'person':
                return (
                    <Fragment>
                        <FieldControl>
                            <LabelControl>
                                <Label props={{ htmlFor: 'FullName' }}>Name</Label>
                            </LabelControl>
                            <Input
                                id={'Name'}
                                autoFocus={true}
                                value={contactName}
                                onChange={e => {
                                    setContactName(e.target.value);
                                    if (disableSave) setDisableSave(false);
                                    if (name === e.target.value) setDisableSave(true);
                                }}
                            />
                        </FieldControl>
                        <FieldControl>
                            <LabelControl>
                                <Label props={{ htmlFor: 'JobTitle' }}>Job Title</Label>
                            </LabelControl>
                            <Input
                                id={'JobTitle'}
                                value={contactJobTitle}
                                onChange={e => {
                                    setContactJobTitle(e.target.value);
                                    if (disableSave) setDisableSave(false);
                                    if (jobTitle === e.target.value) setDisableSave(true);
                                }}
                            />
                        </FieldControl>
                    </Fragment>
                );
            case 'business':
                return (
                    <Fragment>
                        <FieldControl>
                            <LabelControl>
                                <Label props={{ htmlFor: 'BusinessName ' }} required>
                                    Business Name
                                </Label>
                            </LabelControl>
                            <Input
                                id={'BusinessName'}
                                autoFocus={true}
                                value={contactName}
                                onChange={e => {
                                    setContactName(e.target.value);
                                    if (disableSave) setDisableSave(false);
                                    if (name === e.target.value || e.target.value.length === 0)
                                        setDisableSave(true);
                                }}
                            />
                        </FieldControl>
                        <FieldControl>
                            <LabelControl>
                                <Label props={{ htmlFor: 'Domain' }}>Website</Label>
                            </LabelControl>
                            <Input
                                id={'JobTitle'}
                                value={contactWebsite}
                                onChange={e => {
                                    setContactWebsite(e.target.value);
                                    if (disableSave) setDisableSave(false);
                                    if (website === e.target.value) setDisableSave(true);
                                    if (
                                        Validation('website', e.target.value, 'default') ===
                                            false &&
                                        e.target.value?.length > 0
                                    )
                                        setErrorState(true);
                                    else setErrorState(false);
                                }}
                                error={errorState}
                                errorMessage="Not a valid url"
                            />
                        </FieldControl>
                    </Fragment>
                );
            default:
                return null;
        }
    }, [
        contactJobTitle,
        contactName,
        contactWebsite,
        disableSave,
        errorState,
        jobTitle,
        name,
        userType,
        website,
    ]);

    // User Image Crop
    const cropButtonRef = useRef();
    const imageUploadRef = useRef();
    const cropRef = useRef();

    const [dropdownOpen, setDropdownOpen] = useState(false);
    const [cropPopoverOpen, setCropPopoverOpen] = useState(false);
    const [fileUrl, setFileUrl] = useState(null);
    const [fileDetails, setFileDetails] = useState(null);

    const handleCropPopoverClose = useCallback(() => {
        setCropPopoverOpen(false);
        setFileUrl(null);
        setFileDetails(null);
    }, []);

    const handleCropPopoverToggle = useCallback(() => {
        if (!cropPopoverOpen) {
            if (image?.key) {
                setFileDetails({
                    name: image.file_name,
                    type: image.type,
                });
                setFileUrl(generateImage(image));
            } else imageUploadRef.current.click();
            setCropPopoverOpen(true);
        } else {
            handleCropPopoverClose();
        }
    }, [cropPopoverOpen, handleCropPopoverClose, image]);

    const generateDropdownOptions = useCallback(() => {
        let temp = [];
        temp.push({
            label: (
                <Button
                    buttonClass={'DropdownOptionsBtn'}
                    onClick={() => {
                        setDropdownOpen(false);
                        handleCropPopoverToggle();
                    }}
                >
                    Edit Image
                </Button>
            ),
        });
        temp.push({
            label: (
                <Button
                    buttonClass={'DropdownOptionsBtn'}
                    onClick={() => {
                        saveInformation({ name: contactName, image: {} });
                        setDropdownOpen(false);
                    }}
                >
                    Remove Image
                </Button>
            ),
        });
        return temp;
    }, [contactName, handleCropPopoverToggle, saveInformation]);

    const handleFileUpload = useCallback(event => {
        const file = event.target.files[0];
        let url;
        if (file) {
            url = URL.createObjectURL(file);
            setFileDetails(file);
            setFileUrl(url);
            setCropPopoverOpen(true);
        }
    }, []);

    const handleSaveImage = useCallback(
        async image => {
            if (image) {
                setLoadingCrop(true);
                const options = {
                    maxSizeMB: 1,
                    maxWidthOrHeight: 512,
                    useWebWorker: true,
                };
                const file = await imageCompression(image, options);
                fileUploadSTS(file, file)
                    .then(response => {
                        if (response.status === 'Success') {
                            let body = {
                                bucket: response.bucket,
                                file_name: file.name,
                                key: response.key,
                                type: file.type,
                                size: file.size.toString(),
                            };
                            saveInformation({ name: contactName, image: body });
                            setCropPopoverOpen(false);
                        } else {
                            showToast({
                                type: 'error',
                                message: 'Something went wrong',
                                duration: 1500,
                            });
                        }
                    })
                    .catch(() => {
                        showToast({
                            type: 'error',
                            message: 'Something went wrong',
                            duration: 1500,
                        });
                    })
                    .finally(() => {
                        setLoadingCrop(false);
                    });
            } else {
                showToast({
                    type: 'error',
                    message: 'Something went wrong',
                    duration: 1500,
                });
            }
        },
        [contactName, saveInformation, showToast],
    );

    const handleImageCrop = useCallback(
        async croppedCanvas => {
            try {
                // As a blob
                if (!croppedCanvas) {
                    showToast({
                        type: 'error',
                        message: 'Invalid file format. Please select a valid image format.',
                        duration: 1500,
                    });
                }
                const imageWidth = croppedCanvas?.width;
                const imageHeight = croppedCanvas?.height;
                if (imageHeight === 0 || imageWidth === 0) {
                    showToast({
                        type: 'error',
                        message: 'Please select a suitable image dimension.',
                    });
                    return;
                }
                croppedCanvas?.toBlob(
                    fileBlob => {
                        const reader = new FileReader();
                        reader.addEventListener('loadend', () => {
                            const arrayBuffer = reader.result;
                            var file = new File([arrayBuffer], fileDetails.name, {
                                type: fileDetails.type,
                            });
                            handleSaveImage(file);
                        });
                        reader.readAsArrayBuffer(fileBlob);
                    },
                    fileDetails.type,
                    1,
                );
            } catch (err) {
                showToast({
                    type: 'error',
                    message: err || 'Something went wrong!',
                });
            }
        },
        [fileDetails, handleSaveImage, showToast],
    );

    useEffect(() => {
        if (closePopOver !== undefined) {
            handleEditPopoverClose();
            handleCropPopoverClose();
        }
    }, [closePopOver, handleCropPopoverClose, handleEditPopoverClose]);

    const [imagePreviewOpen, setImagePreviewOpen] = useState(false);

    const handleImagePreview = useCallback(() => {
        if (image) {
            setImagePreviewOpen(true);
        }
    }, [image]);

    return (
        <Fragment>
            {/* Image Preview */}
            {imagePreviewOpen && image?.key && (
                <FilePreviewDialog
                    open={imagePreviewOpen}
                    fileName={image.file_name}
                    fileUrl={generateFilePath(image.key)}
                    fileType={image.file_name?.split('.').pop().toLowerCase()}
                    onClose={() => setImagePreviewOpen(false)}
                    handleDownload={() => {
                        downloadFilesFromWasabi(image.key, image.type, image.file_name);
                    }}
                />
            )}
            {/* User Details  */}
            <DropdownOptions
                customClass={'ArrowPopover imageDropdownPopover'}
                open={dropdownOpen}
                onClose={() => {
                    setDropdownOpen(false);
                }}
                reference={cropButtonRef}
                defaultPlacement={'bottom-center'}
                options={generateDropdownOptions()}
                itemSize={40}
                viewHeight={80}
                wrapperWidth={140}
            />
            {editPopoverOpen ? (
                <Popover
                    customClass={'UserDetailEditPopover'}
                    open={editPopoverOpen}
                    anchorEl={editButtonRef}
                    onClose={handleEditPopoverClose}
                    width={350}
                    placement={matche575 ? 'no-placement-left' : 'bottom-start'}
                >
                    <Fragment>
                        {generatePopOverFields()}
                        <Div className="UDEPBtns">
                            <Button
                                buttonType={'BlueFillBtn'}
                                onClick={handleSave}
                                loading={loading}
                                disabled={disableSave || errorState}
                            >
                                Save
                            </Button>
                            <Button
                                buttonType={'RedOutlineBtn'}
                                onClick={handleEditPopoverClose}
                                disabled={loading}
                            >
                                Cancel
                            </Button>
                        </Div>
                    </Fragment>
                </Popover>
            ) : null}
            {/* User Image Crop  */}
            {showImageEdit && (
                <>
                    {(image && image.key) || fileUrl ? (
                        <Dialog
                            open={cropPopoverOpen}
                            onClose={handleCropPopoverClose}
                            dialogType={'centerZoom sizeXs'}
                        >
                            <TopBar DialogTopBar>
                                <Div className={'HeaderBar'}>
                                    <H1 className={'HeaderBarTitle'}>Edit Image</H1>
                                    <Div className={'HeaderBarClose'}>
                                        <IconButton onClick={handleCropPopoverClose}>
                                            <DCCrossClose />
                                        </IconButton>
                                    </Div>
                                </Div>
                            </TopBar>
                            <Div className={'CropImageDialog'}>
                                <CropImage
                                    uploadImg={fileUrl}
                                    onChange={handleImageCrop}
                                    roundCrop={userType === newButtonType.PERSON.toLowerCase()}
                                    cropRef={cropRef}
                                    maintainRatio={userType == 'person'}
                                />
                                <Div className="UICPBtns">
                                    {image && (
                                        <Button
                                            buttonType={'BlueFillBtn'}
                                            iconName={<DCFileUpload1 />}
                                            iconPlacement={'left'}
                                            disabled={loadingCrop || loading}
                                            onClick={() => {
                                                imageUploadRef.current.click();
                                            }}
                                        >
                                            Upload New
                                        </Button>
                                    )}
                                    <Button
                                        buttonType={'BlueFillBtn'}
                                        onClick={() => cropRef.current?.click()}
                                        loading={loadingCrop || loading}
                                        disabled={loadingCrop || loading}
                                    >
                                        Save
                                    </Button>
                                    <Button
                                        buttonType={'RedOutlineBtn'}
                                        onClick={handleCropPopoverClose}
                                        disabled={loadingCrop || loading}
                                    >
                                        Cancel
                                    </Button>
                                </Div>
                            </Div>
                        </Dialog>
                    ) : null}
                </>
            )}
            <Div className={'UserDetailEdit UDECenter ' + (customClass ? customClass : '')}>
                <Div className="UDEInner">
                    <Div
                        className={`UDEIImg ${
                            userType === newButtonType.BUSINESS.toLowerCase() ? 'UDEIImgSquare' : ''
                        }`}
                    >
                        <input
                            type="file"
                            hidden
                            accept="image/*"
                            ref={imageUploadRef}
                            onChange={e => {
                                handleFileUpload(e);
                                e.target.value = '';
                            }}
                        />
                        <Div className={'UDEIImgInner ' + (image?.key ? '' : 'UDEIIISvg')}>
                            {showImageEdit && (
                                <Div
                                    className={
                                        'UserImageEdit ' +
                                        (cropPopoverOpen === true ? 'active' : '')
                                    }
                                    reference={cropButtonRef}
                                    onClick={
                                        showImageEdit
                                            ? () => {
                                                  if (image?.key) {
                                                      setDropdownOpen(prev => !prev);
                                                  } else {
                                                      imageUploadRef.current.click();
                                                  }
                                              }
                                            : null
                                    }
                                >
                                    <DCFillPencil />
                                </Div>
                            )}

                            <Imagea
                                key={image?.key}
                                src={generateImage(image, name, false, email)}
                                alt=""
                                onClick={showImageEdit ? null : handleImagePreview}
                                displayName={name || email}
                            />
                        </Div>
                    </Div>
                    <Div className="UDEIInfoEdit">
                        <Div className="UDEIInfo">
                            <Div className="UDEIIName">
                                <TextOverflowTooltip tooltipContent={name}>
                                    {name}
                                </TextOverflowTooltip>
                            </Div>
                            {jobTitle && (
                                <Div className="UDEIIJob">
                                    <TextOverflowTooltip tooltipContent={jobTitle}>
                                        {jobTitle}
                                    </TextOverflowTooltip>
                                </Div>
                            )}
                            {website && (
                                <Link className="UDEIWebsiteLink" href={website} target={'_blank'}>
                                    <TextOverflowTooltip tooltipContent={website}>
                                        {website}
                                    </TextOverflowTooltip>
                                </Link>
                            )}
                        </Div>
                        {showEdit && (
                            <Div className="UDEIIBtn">
                                <IconButton
                                    reference={editButtonRef}
                                    onClick={handleEditPopoverToggle}
                                >
                                    <DCFillPencil />
                                </IconButton>
                            </Div>
                        )}
                    </Div>
                    {!showEdit && status && (
                        <Div className={`UDEIIStatus ${status}`}>
                            {upperCaseFirstLetter(status?.replace('_', ' '))}
                        </Div>
                    )}
                </Div>
            </Div>
        </Fragment>
    );
};
UserDetailEdit.propTypes = {
    customClass: PropTypes.string,
    image: PropTypes.object,
    name: PropTypes.string,
    jobTitle: PropTypes.string,
    website: PropTypes.string,
    userType: PropTypes.string.isRequired,
    saveInformation: PropTypes.func,
    closePopOver: PropTypes.func,
    loading: PropTypes.bool,
    showEdit: PropTypes.bool,
    showImageEdit: PropTypes.bool,
    email: PropTypes.string,
};
export default UserDetailEdit;
