import React from 'react';
import PropTypes from 'prop-types';
export default function H3({ props, children, className }) {
    return (
        <h3 {...props} className={className}>
            {children}
        </h3>
    );
}

H3.propTypes = {
    children: PropTypes.element,
    className: PropTypes.string,
};
