import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Div, Image } from 'UIKit/index';
import generateImage from 'Utils/generateImage';
import { getAccountDetails, getDefaultPage } from 'Utils/localStorageUtils';

import './style.scss';

const Logo = ({ className }) => {
    const [imageURL, setImage] = useState('');
    const [imageAlt, setImageAlt] = useState('');

    useEffect(() => {
        const account = getAccountDetails();
        if (account?.branding) {
            setImage(generateImage(account.branding.image));
            setImageAlt(account.name);
        }
    }, []);

    return (
        <Div className={'WebLogo ' + (className ? className : '')}>
            <Link to={'/' + getDefaultPage()}>
                <Image src={imageURL} alt={imageAlt} displayName={''} />
            </Link>
        </Div>
    );
};
Logo.propTypes = {
    className: PropTypes.string,
};

export default Logo;
