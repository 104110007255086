import React from 'react';
import { Page, Text, View, Link, Image, Font } from '@react-pdf/renderer';
import { IPAddPin, ILink, ITraffic, IDomain } from './svgDownloadIcons/svgDownloadIcons';
import { abbreviateNumber } from 'Utils/stringUtils';
import generateImage from 'Utils/generateImage';
import ColorBox, { getInitialsListingSvg } from './downloadUtils/colorBox';
import config from 'config';
import { styles } from './style';
import { instareportFontFamily } from '../components/constant';

Font.register({ ...instareportFontFamily });

const SeoDownload = ({ keywords, backlinks, competitors, details, user, score }) => {
    return (
        <Page>
            <View fixed style={styles.FixedTopHeight}>
                <Text />
            </View>
            <View style={styles.section}>
                <View style={styles.HeadingTitle}>
                    <View style={styles.HeadingLogo}>
                        {score?.seo ? <ColorBox score={score?.seo || 0} /> : null}
                        <Text style={styles.heading}>SEO</Text>
                        <Text style={styles.HeadingCaption}>
                            Are potential customers finding you when searching?
                        </Text>
                    </View>
                </View>
                <View style={styles.RSubSectionView}>
                    <View>
                        <Text style={styles.RSubSectionText}>
                            91% of online experiences begin with a Google search, which is why it’s
                            critical that your website is listed at the top of search results. It’s
                            no secret that search engine optimization is one of the leading
                            marketing tactics to get a continuous flow of new customers.
                        </Text>
                        <Text style={styles.RSubSectionText}>
                            We scanned {details?.website || 'website'} to find it’s positioning
                            within Google’s search results for industry specific keywords, then
                            stacked it up against your competitors to see how you’re competing.
                        </Text>
                    </View>
                </View>
                <View wrap={false}>
                    <View style={styles.HeaderDiv}>
                        <Text>Organic Competitor Analysis</Text>
                        <Text>Here's your SEO compared to competitors</Text>
                    </View>
                    <View style={styles.STableView} wrap={false}>
                        <View style={styles.STableViewHeader}>
                            <View style={styles.TableViewHeaderBColumn}>
                                <Text>Domain</Text>
                            </View>
                            <View style={styles.TableViewHeaderBColumn}>
                                <Text style={styles.STextCenter}>Overlap</Text>
                            </View>
                            <View style={styles.SColumnView}>
                                <Text style={styles.STextCenter}>Keywords</Text>
                            </View>
                            <View style={styles.SColumnView}>
                                <Text style={styles.STextCenter}>Clicks</Text>
                            </View>
                            <View style={styles.SColumnView}>
                                <Text style={styles.STextCenter}>Value</Text>
                            </View>
                        </View>
                        {competitors?.map((item, index) => (
                            <View style={styles.TTtableView} key={index}>
                                <Link
                                    style={styles.TableLinkText}
                                    src={`https://${item?.domain}`}
                                    target={'_blank'}
                                >
                                    {item.domain}
                                </Link>
                                <View style={styles.TableBigColumnCell}>
                                    <View style={styles.TCProgressCover}>
                                        <View
                                            style={{
                                                backgroundColor: 'blue',
                                                width: parseInt(item?.overlap) || 0,
                                                height: 7,
                                                borderRadius: 4,
                                            }}
                                        />
                                    </View>
                                    <Text style={styles.TCOverlapPercent}>
                                        {item?.overlap || 0}%
                                    </Text>
                                </View>
                                <View style={styles.TableColumn}>
                                    <Text style={styles.TableColumnText}>
                                        {abbreviateNumber(item?.keywords)}
                                    </Text>
                                </View>
                                <View style={styles.TableColumn}>
                                    <Text style={styles.TableColumnText}>
                                        {abbreviateNumber(item?.clicks)}
                                    </Text>
                                </View>
                                <View style={styles.TableColumn}>
                                    <Text style={styles.TableColumnText}>
                                        {abbreviateNumber(item?.value)}
                                    </Text>
                                </View>
                            </View>
                        ))}
                    </View>
                </View>
                <View wrap={false}>
                    <View style={styles.HeaderDiv}>
                        <Text>Organic Keyword Ranking</Text>
                        <Text>Here's how your business ranks in searches</Text>
                    </View>
                    <View style={styles.STableView}>
                        <View style={styles.STableViewHeader}>
                            <View style={styles.STableViewHeaderCell1}>
                                <Text>Keyword</Text>
                            </View>
                            <View style={styles.STableViewHeaderCell2}>
                                <Text style={styles.STableViewHeaderCell2Text}>
                                    Keyword Difficulty
                                </Text>
                            </View>
                            <View style={styles.TTtableColumn}>
                                <Text style={styles.STextCenter}>Position</Text>
                            </View>
                            <View style={styles.TTtableColumn}>
                                <Text style={styles.STextCenter}>Search Volume</Text>
                            </View>
                            <View style={styles.TTtableColumn}>
                                <Text style={styles.STextCenter}>Results</Text>
                            </View>
                        </View>
                        {keywords?.map((item, index) => (
                            <View style={styles.TTtableView} key={index}>
                                <View style={styles.TTtableViewKeywordCell}>
                                    <Text>{item.keyword}</Text>
                                </View>
                                <View style={styles.TTtableViewKDCell}>
                                    <View style={styles.TTtableViewKDCellCover}>
                                        <View
                                            style={{
                                                backgroundColor: 'blue',
                                                width: parseInt(item?.keywordDifficulty) || 0,
                                                height: 7,
                                                borderRadius: 4,
                                            }}
                                        />
                                    </View>
                                    <Text style={styles.TTtableViewKD}>
                                        {item.keywordDifficulty || 0}%
                                    </Text>
                                </View>
                                <View style={styles.TableColumn}>
                                    <Text style={styles.TableColumnCenterText}>
                                        {abbreviateNumber(item?.position)}
                                    </Text>
                                </View>
                                <View style={styles.TableColumn}>
                                    <Text style={styles.TableColumnCenterText}>
                                        {abbreviateNumber(item?.searchVolume)}
                                    </Text>
                                </View>
                                <View style={styles.TableColumn}>
                                    <Text style={styles.TableColumnCenterText}>
                                        {abbreviateNumber(item?.results)}
                                    </Text>
                                </View>
                            </View>
                        ))}
                    </View>
                </View>
                <View style={styles.HeaderDiv}>
                    <Text>Backlinks</Text>
                    <Text>Here’s the links pointing to your website</Text>
                </View>
                <View style={styles.BottomSection}>
                    <View style={styles.BottomSectionBox1}>
                        <View style={styles.BottomSectionBoxContainer}>
                            <View style={styles.BSContainerRow}>
                                <Text style={styles.BSContainerRowText1}>Total Backlinks</Text>
                                <Text style={styles.BSContainerRowBText}>
                                    {abbreviateNumber(backlinks?.totalBacklinks)}
                                </Text>
                            </View>
                            <View style={styles.WH30}>
                                <ILink />
                            </View>
                        </View>
                    </View>
                    <View style={styles.BottomSectionBox2}>
                        <View style={styles.BottomSectionBoxContainer}>
                            <View style={styles.BSContainerRow}>
                                <Text style={styles.BSContainerRowText}>Referring Domains</Text>
                                <Text style={styles.BSContainerRowBText}>
                                    {abbreviateNumber(backlinks?.referringDomains)}
                                </Text>
                            </View>
                            <View style={styles.WH30}>
                                <IDomain />
                            </View>
                        </View>
                    </View>
                    <View style={styles.BottomSectionBox3}>
                        <View style={styles.BottomSectionBoxContainer}>
                            <View style={styles.BSContainerRow}>
                                <Text style={styles.BSContainerRowText}>Referring IPs</Text>
                                <Text style={styles.BSContainerRowBText}>
                                    {abbreviateNumber(backlinks?.referringsIPs)}
                                </Text>
                            </View>
                            <View style={styles.WH30}>
                                <IPAddPin />
                            </View>
                        </View>
                    </View>
                    <View style={styles.BottomSectionBox4}>
                        <View style={styles.BottomSectionBoxContainer}>
                            <View style={styles.BSContainerRow}>
                                <Text style={styles.BSContainerRowText}>Authority Score</Text>
                                <Text style={styles.BSContainerRowBText}>
                                    {backlinks?.authorityScore}
                                </Text>
                            </View>
                            <View style={styles.WH30}>
                                <ITraffic color={'#02cccd'} />
                            </View>
                        </View>
                    </View>
                </View>
                <View style={styles.BSStatsSection}>
                    <View>
                        <View style={styles.StatsSection} wrap={false}>
                            <Text style={styles.BarGraphSectionArea}>Backlinks Types</Text>
                            <View style={styles.BarGraphSection}>
                                <View style={styles.SeoAnalyticsBox}>
                                    <View style={styles.BarGraph}>
                                        <View
                                            style={{
                                                backgroundColor: '#37c1ff',
                                                width: 50,
                                                height: parseInt(
                                                    backlinks?.backlinksTypes?.text?.percentage,
                                                ),
                                                position: 'absolute',
                                                bottom: 0,
                                                borderRadius: 4,
                                            }}
                                        />
                                    </View>
                                    <Text style={styles.BSContainerRowBText}>
                                        {backlinks?.backlinksTypes?.text?.percentage}%
                                    </Text>
                                    <Text style={styles.BarGraphText}>
                                        {abbreviateNumber(backlinks?.backlinksTypes?.text?.number)}{' '}
                                        Text
                                    </Text>
                                </View>
                                <View style={styles.SeoAnalyticsBox}>
                                    <View style={styles.BarGraph}>
                                        <View
                                            style={{
                                                backgroundColor: '#37c1ff',
                                                width: 50,
                                                height: parseInt(
                                                    backlinks?.backlinksTypes?.image?.percentage,
                                                ),
                                                position: 'absolute',
                                                bottom: 0,
                                                borderRadius: 4,
                                            }}
                                        />
                                    </View>
                                    <Text style={styles.BSContainerRowBText}>
                                        {backlinks?.backlinksTypes?.image?.percentage}%
                                    </Text>
                                    <Text style={styles.BarGraphText}>
                                        {abbreviateNumber(backlinks?.backlinksTypes?.image?.number)}{' '}
                                        Image
                                    </Text>
                                </View>
                                <View style={styles.SeoAnalyticsBox}>
                                    <View style={styles.BarGraph}>
                                        <View
                                            style={{
                                                backgroundColor: '#37c1ff',
                                                width: 50,
                                                height: parseInt(
                                                    backlinks?.backlinksTypes?.form?.percentage,
                                                ),
                                                position: 'absolute',
                                                bottom: 0,
                                                borderRadius: 4,
                                            }}
                                        />
                                    </View>
                                    <Text style={styles.BSContainerRowBText}>
                                        {backlinks?.backlinksTypes?.form?.percentage}%
                                    </Text>
                                    <Text style={styles.BarGraphText}>
                                        {abbreviateNumber(backlinks?.backlinksTypes?.form?.number)}{' '}
                                        Form
                                    </Text>
                                </View>
                                <View style={styles.SeoAnalyticsBox}>
                                    <View style={styles.BarGraph}>
                                        <View
                                            style={{
                                                backgroundColor: '#37c1ff',
                                                width: 50,
                                                height: parseInt(
                                                    backlinks?.backlinksTypes?.frame?.percentage,
                                                ),
                                                position: 'absolute',
                                                bottom: 0,
                                                borderRadius: 4,
                                            }}
                                        />
                                    </View>
                                    <Text style={styles.BacklinkText}>
                                        {backlinks?.backlinksTypes?.frame?.percentage}%
                                    </Text>
                                    <Text style={styles.SText}>
                                        {abbreviateNumber(backlinks?.backlinksTypes?.frame?.number)}{' '}
                                        Frame
                                    </Text>
                                </View>
                            </View>
                        </View>
                    </View>
                    <View style={styles.FollowNFollowSection} wrap={false}>
                        <Text style={styles.FollowMainView}>Follow Vs Non-Follow</Text>
                        <View style={styles.FNFCover}>
                            <View
                                style={{
                                    backgroundColor: '#807ff0',
                                    height: 30,
                                    width: parseInt(backlinks?.follow?.percentage) * 2.1,
                                    borderRadius: 4,
                                }}
                            />
                        </View>
                        <View style={styles.BacklinkView}>
                            <View style={styles.BacklinkSection}>
                                <Text>{backlinks?.follow?.percentage}%</Text>
                                <Text>{backlinks?.noFollow?.percentage}%</Text>
                            </View>
                            <View style={styles.FollowSection}>
                                <Text>{abbreviateNumber(backlinks?.follow?.number)} Follow</Text>
                                <Text>
                                    {' '}
                                    {abbreviateNumber(backlinks?.noFollow?.number)} Non Follow
                                </Text>
                            </View>
                        </View>
                    </View>
                </View>
            </View>
            <View style={styles.GITSection} wrap={false}>
                <View style={styles.HeadingLogo}>
                    {!(typeof user?.image === 'string') ? (
                        <>
                            {Object.keys(user?.image || {})?.length > 0 ? (
                                <Image
                                    src={generateImage(user?.image, user?.name)}
                                    style={styles.GITUserImage}
                                    alt={'image'}
                                />
                            ) : (
                                <View style={styles.GITSvgImage}>
                                    {getInitialsListingSvg(user?.name)}
                                </View>
                            )}
                        </>
                    ) : (
                        <Image
                            src={{
                                uri: `${config.REACT_APP_API_BASE_URL}/v1/corsproxy/`,
                                method: 'GET',
                                body: '',
                                headers: { 'Target-URL': `${user?.image}` },
                            }}
                            cache={false}
                            style={styles.GITUserImage}
                            alt={'image'}
                        />
                    )}
                    <Text style={styles.GITContent}>
                        Would you like to get {details?.website} ranked at the top of search engine
                        results and get more customers? We can help!
                    </Text>
                </View>
            </View>
            <View fixed style={styles.FixedBottomHeight}>
                <Text />
            </View>
        </Page>
    );
};

export default SeoDownload;
