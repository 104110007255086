import React, { Fragment } from 'react';
import Slider from 'react-slick';
import { Button, Div, Link, Image } from 'UIKit/index';
import PhoneNumberView from 'Modules/PhoneNumber/phoneNumberView';
import useMediaQuery from 'UIKit/useMediaQuery';
import 'Assets/styles/slick.scss';

const BusinessDetails = ({ details, handleGetInTouchOpen }) => {
    const matches1367 = useMediaQuery('(min-width: 1280px) and (max-width: 1600px)');
    const matches992 = useMediaQuery('(min-width: 992px) and (max-width: 1023.98px)');
    const matches767 = useMediaQuery('(min-width: 576px) and (max-width: 767.98px)');
    const matches576 = useMediaQuery('(max-width: 576.98px)');

    return (
        <Fragment>
            <Div className={'EPComponent'}>
                <Div className={'EPCHead'}>
                    <Div className={'EPCHTBox'}>
                        <Div className={'EPCHTBTitle'}>Business Details</Div>
                    </Div>
                </Div>
                <Div className={'EPCBody'}>
                    <Div className={'EPCVideoContent'}>
                        <Div className={'EPCVCVideo'}>
                            <iframe
                                src="https://player.vimeo.com/video/360270097"
                                title="video"
                                frameBorder="0"
                                webkitallowfullscreen=""
                                mozallowfullscreen=""
                            />
                        </Div>
                        <Div className={'EPCVCContent'}>
                            <Div className={'EPCVCCTitle'}>Report Created for {details.name}</Div>
                            <Div className={'EPCVCCDes'}>
                                Marketing is the force behind every business which allows them to
                                propel and dominate in their local market. With our extensive
                                marketing background, accompanied with our proprietary technology,
                                we’re able to pinpoint exactly where {details.name} needs the most
                                help.
                            </Div>
                            <Div className={'EPCVCCBtn'}>
                                <Button buttonClass={'BlueFillBtn'} onClick={handleGetInTouchOpen}>
                                    Get Started
                                </Button>
                            </Div>
                        </Div>
                    </Div>
                </Div>
            </Div>
            <Div className={'EPComponent'}>
                <Div className={'EPCBody'}>
                    <Div className={'EPCRow'}>
                        <Div className={'EPCRCol4'}>
                            <Div className={'EPCRCDetail'}>
                                <Div className={'EPCRCDRow'}>
                                    <Div className={'EPCRCDRLabel'}>Name</Div>
                                    <Div className={'EPCRCDRTitle'}>{details.name}</Div>
                                </Div>
                                <Div className={'EPCRCDRow'}>
                                    <Div className={'EPCRCDRLabel'}>Address</Div>
                                    <Div className={'EPCRCDRTitle'}>
                                        {details?.address?.street || details?.address?.unit
                                            ? `
                                        ${details?.address?.street || ' '}
                                        ${details?.address?.unit || ''}`
                                            : '---'}
                                    </Div>
                                </Div>
                                <Div className={'EPCRCDRow'}>
                                    <Div className={'EPCRCDRLabel'}>City, State</Div>
                                    <Div className={'EPCRCDRTitle'}>
                                        {details?.address?.city && details?.address?.state_province
                                            ? `${details?.address?.city || ''} ${
                                                  details?.address?.state_province ? ',' : ''
                                              } ${details?.address?.state_province || ''}`
                                            : '---'}
                                    </Div>
                                </Div>
                                <Div className={'EPCRCDRow'}>
                                    <Div className={'EPCRCDRLabel'}>Zip Code</Div>
                                    <Div className={'EPCRCDRTitle'}>
                                        {details?.address?.postal_code ?? '---'}
                                    </Div>
                                </Div>
                                <Div className={'EPCRCDRow'}>
                                    <Div className={'EPCRCDRLabel'}>Country</Div>
                                    <Div className={'EPCRCDRTitle'}>
                                        {details?.address?.country ?? '---'}
                                    </Div>
                                </Div>
                                <Div className={'EPCRCDRow EPCRCDRPhone'}>
                                    <Div className={'EPCRCDRLabel'}>Phone Number</Div>
                                    <Div className={'EPCRCDRTitle'}>
                                        {details?.phoneNumber ? (
                                            <PhoneNumberView value={details?.phoneNumber} />
                                        ) : (
                                            '---'
                                        )}
                                    </Div>
                                </Div>
                                <Div className={'EPCRCDRow'}>
                                    <Div className={'EPCRCDRLabel'}>Website</Div>
                                    <Div className={'EPCRCDRTitle'}>
                                        {details?.website ? (
                                            <Link target="_blank" href={details?.website}>
                                                {details.website}
                                            </Link>
                                        ) : (
                                            '---'
                                        )}
                                    </Div>
                                </Div>
                                <Div className={'EPCRCDRow'}>
                                    <Div className={'EPCRCDRLabel'}>Business Category</Div>
                                    <Div className={'EPCRCDRTitle'}>
                                        {details.businessCategory ?? '---'}
                                    </Div>
                                </Div>
                            </Div>
                        </Div>
                        <Div className={'EPCRCol6'}>
                            <Div className={'EPCRCBPhotos'}>
                                <Div className={'EPCRCBPHead'}>Business Photos</Div>
                                <Div className={'EPCRCBPBody'}>
                                    {details?.photos?.length ? (
                                        <Slider
                                            dots={true}
                                            arrows={false}
                                            infinite={true}
                                            adaptiveHeight={true}
                                            autoplay={false}
                                            speed={500}
                                            slidesToShow={
                                                matches1367 || matches992 || matches767
                                                    ? 2
                                                    : matches576
                                                    ? 1
                                                    : 4
                                            }
                                            slidesToScroll={4}
                                            slidesPerRow={1}
                                            rows={1}
                                        >
                                            {details?.photos?.map((url, i) => (
                                                <Div key={i} className={'EPCRCBPBSlide'}>
                                                    <Image src={url} alt={'image' + i} />
                                                </Div>
                                            ))}
                                        </Slider>
                                    ) : (
                                        <Div>No Photos Found</Div>
                                    )}
                                </Div>
                            </Div>
                        </Div>
                    </Div>
                </Div>
            </Div>
        </Fragment>
    );
};

export default BusinessDetails;
