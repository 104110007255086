import React, { useContext } from 'react';
import { Div } from '../index';
import './style.scss';
import { themeContext } from 'Contexts/Context';
import PropTypes from 'prop-types';
const TopBar = ({ customClass, children, DialogTopBar }) => {
    const { primaryColor } = useContext(themeContext);
    return (
        <Div
            className={
                'topBar ' + (customClass ? customClass : '') + (DialogTopBar ? ' DialogTopBar' : '')
            }
            style={{ backgroundColor: primaryColor }}
        >
            <Div className={'topBarInner'}>{children}</Div>
        </Div>
    );
};

TopBar.propTypes = {
    customClass: PropTypes.string,
    children: PropTypes.element,
    DialogTopBar: PropTypes.bool,
};

export default TopBar;
