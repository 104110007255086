import React from 'react';

import { SVGIcon } from '../../UIKit/index';

export default function DCGraySPForm(props) {
    return (
        <SVGIcon {...props} viewBox="0 0 424.2 456.67">
            <g id="form_Submissions" data-name="form Submissions" transform="translate(1.5 1.5)">
                <circle
                    id="Ellipse_6"
                    data-name="Ellipse 6"
                    cx="210.6"
                    cy="210.6"
                    r="210.6"
                    fill="#fff"
                    stroke="#707171"
                    strokeMiterlimit="10"
                    strokeWidth="3"
                />
                <rect
                    id="Rectangle_58"
                    data-name="Rectangle 58"
                    width="33.12"
                    height="0.25"
                    transform="translate(38.88 362.85)"
                    fill="#ececec"
                />
                <rect
                    id="Rectangle_59"
                    data-name="Rectangle 59"
                    width="30.17"
                    height="0.25"
                    transform="translate(93.67 370.06)"
                    fill="#ececec"
                />
                <rect
                    id="Rectangle_60"
                    data-name="Rectangle 60"
                    width="15.9"
                    height="0.25"
                    transform="translate(394.34 371.59)"
                    fill="#ececec"
                />
                <rect
                    id="Rectangle_61"
                    data-name="Rectangle 61"
                    width="30.33"
                    height="0.25"
                    transform="translate(210.34 362.73)"
                    fill="#ececec"
                />
                <rect
                    id="Rectangle_62"
                    data-name="Rectangle 62"
                    width="72.1"
                    height="0.25"
                    transform="translate(245 362.73)"
                    fill="#ececec"
                />
                <rect
                    id="Rectangle_63"
                    data-name="Rectangle 63"
                    width="28.77"
                    height="116.19"
                    transform="translate(224.96 238.28)"
                    fill="#e3e2e3"
                />
                <path
                    id="Path_53"
                    data-name="Path 53"
                    d="M287.49,396.65h11.96V376.61h-24.5Z"
                    transform="translate(-45.72 -42.19)"
                    fill="#f4f5f6"
                />
                <rect
                    id="Rectangle_64"
                    data-name="Rectangle 64"
                    width="218.2"
                    height="109.29"
                    rx="51.11"
                    transform="translate(35.52 238.28)"
                    fill="#f4f5f6"
                />
                <path
                    id="Path_54"
                    data-name="Path 54"
                    d="M290.67,314H90V290.1H290.67ZM91,313H289.67V291.1H91Z"
                    transform="translate(-45.72 -42.19)"
                    fill="#ececec"
                />
                <rect
                    id="Rectangle_65"
                    data-name="Rectangle 65"
                    width="227.38"
                    height="157.46"
                    transform="translate(140.83 50.59)"
                    fill="#e3e2e3"
                />
                <rect
                    id="Rectangle_66"
                    data-name="Rectangle 66"
                    width="227.38"
                    height="157.46"
                    transform="translate(145.01 50.59)"
                    fill="#f4f5f6"
                />
                <rect
                    id="Rectangle_67"
                    data-name="Rectangle 67"
                    width="141.56"
                    height="212.37"
                    transform="translate(364.88 58.54) rotate(90)"
                    fill="#fff"
                />
                <rect
                    id="Rectangle_68"
                    data-name="Rectangle 68"
                    width="141.56"
                    height="3.16"
                    transform="translate(364.88 58.54) rotate(90)"
                    fill="#e7e7e7"
                />
                <rect
                    id="Rectangle_69"
                    data-name="Rectangle 69"
                    width="221.17"
                    height="5.34"
                    transform="translate(148.11 71.22)"
                    fill="#f5f5f5"
                />
                <rect
                    id="Rectangle_70"
                    data-name="Rectangle 70"
                    width="8.98"
                    height="174.89"
                    transform="translate(359.69 45.33)"
                    fill="#f4f5f6"
                />
                <rect
                    id="Rectangle_71"
                    data-name="Rectangle 71"
                    width="8.98"
                    height="174.89"
                    transform="translate(348.28 45.33)"
                    fill="#f4f5f6"
                />
                <rect
                    id="Rectangle_72"
                    data-name="Rectangle 72"
                    width="8.98"
                    height="174.89"
                    transform="translate(336.87 45.33)"
                    fill="#f4f5f6"
                />
                <rect
                    id="Rectangle_73"
                    data-name="Rectangle 73"
                    width="8.98"
                    height="174.89"
                    transform="translate(325.47 45.33)"
                    fill="#f4f5f6"
                />
                <rect
                    id="Rectangle_74"
                    data-name="Rectangle 74"
                    width="8.98"
                    height="174.89"
                    transform="translate(314.06 45.33)"
                    fill="#f4f5f6"
                />
                <rect
                    id="Rectangle_75"
                    data-name="Rectangle 75"
                    width="8.98"
                    height="174.89"
                    transform="translate(302.65 45.33)"
                    fill="#f4f5f6"
                />
                <rect
                    id="Rectangle_76"
                    data-name="Rectangle 76"
                    width="8.98"
                    height="174.89"
                    transform="translate(291.24 45.33)"
                    fill="#f4f5f6"
                />
                <rect
                    id="Rectangle_77"
                    data-name="Rectangle 77"
                    width="8.98"
                    height="174.89"
                    transform="translate(279.84 45.33)"
                    fill="#f4f5f6"
                />
                <rect
                    id="Rectangle_78"
                    data-name="Rectangle 78"
                    width="8.98"
                    height="174.89"
                    transform="translate(268.43 45.33)"
                    fill="#f4f5f6"
                />
                <rect
                    id="Rectangle_79"
                    data-name="Rectangle 79"
                    width="8.98"
                    height="174.89"
                    transform="translate(257.02 45.33)"
                    fill="#f4f5f6"
                />
                <rect
                    id="Rectangle_80"
                    data-name="Rectangle 80"
                    width="8.98"
                    height="174.89"
                    transform="translate(245.61 45.33)"
                    fill="#f4f5f6"
                />
                <rect
                    id="Rectangle_81"
                    data-name="Rectangle 81"
                    width="8.98"
                    height="174.89"
                    transform="translate(234.21 45.33)"
                    fill="#f4f5f6"
                />
                <rect
                    id="Rectangle_82"
                    data-name="Rectangle 82"
                    width="8.98"
                    height="174.89"
                    transform="translate(222.8 45.33)"
                    fill="#f4f5f6"
                />
                <rect
                    id="Rectangle_83"
                    data-name="Rectangle 83"
                    width="8.98"
                    height="174.89"
                    transform="translate(211.39 45.33)"
                    fill="#f4f5f6"
                />
                <rect
                    id="Rectangle_84"
                    data-name="Rectangle 84"
                    width="8.98"
                    height="174.89"
                    transform="translate(199.99 45.33)"
                    fill="#f4f5f6"
                />
                <rect
                    id="Rectangle_85"
                    data-name="Rectangle 85"
                    width="65.9"
                    height="119.84"
                    transform="translate(22.69 69.4)"
                    fill="#e3e2e3"
                />
                <rect
                    id="Rectangle_86"
                    data-name="Rectangle 86"
                    width="75.8"
                    height="119.84"
                    transform="translate(26.94 69.4)"
                    fill="#f4f5f6"
                />
                <rect
                    id="Rectangle_87"
                    data-name="Rectangle 87"
                    width="114.16"
                    height="69.62"
                    transform="translate(30.03 186.4) rotate(-90)"
                    fill="#fff"
                />
                <rect
                    id="Rectangle_88"
                    data-name="Rectangle 88"
                    width="74.24"
                    height="32.51"
                    rx="2.76"
                    transform="translate(48.58 166.44) rotate(-90)"
                    fill="#f4f5f6"
                />
                <path
                    id="Path_55"
                    data-name="Path 55"
                    d="M390,211.54l13.48,33.23c-.39,96.09,3.95,101.62,4,118.15h-34C372.19,282.49,384.11,300.58,390,211.54Z"
                    transform="translate(-45.72 -42.19)"
                    fill="#ececec"
                />
                <path
                    id="Path_56"
                    data-name="Path 56"
                    d="M409,309.67c-27.38-75.41-90.44-52.05-87.62-24.57S424.72,353,409,309.67Z"
                    transform="translate(-45.72 -42.19)"
                    fill="#f4f5f6"
                />
                <path
                    id="Path_57"
                    data-name="Path 57"
                    d="M398.61,353.16c-14.94-48.6-54.68-40.67-54.68-18.33S407.21,381.11,398.61,353.16Z"
                    transform="translate(-45.72 -42.19)"
                    fill="#f4f5f6"
                />
                <path
                    id="Path_58"
                    data-name="Path 58"
                    d="M397.54,265.44c-23.45-65-81.88-56-77.18-28.62S411,302.8,397.54,265.44Z"
                    transform="translate(-45.72 -42.19)"
                    fill="#f4f5f6"
                />
                <path
                    id="Path_59"
                    data-name="Path 59"
                    d="M397.71,236.3c2.17-44.26-41.66-62.47-38.62-36S396.46,261.76,397.71,236.3Z"
                    transform="translate(-45.72 -42.19)"
                    fill="#f4f5f6"
                />
                <path
                    id="Path_60"
                    data-name="Path 60"
                    d="M394.43,281.61c9.26-54.53,65.43-75.28,59.15-44.41S389.11,313,394.43,281.61Z"
                    transform="translate(-45.72 -42.19)"
                    fill="#f4f5f6"
                />
                <path
                    id="Path_61"
                    data-name="Path 61"
                    d="M389.7,250.78c-10.9-57.18,39.56-82.6,41-55S396,283.67,389.7,250.78Z"
                    transform="translate(-45.72 -42.19)"
                    fill="#f4f5f6"
                />
                <path
                    id="Path_62"
                    data-name="Path 62"
                    d="M385.33,315.38c22.29-55.11,70-41.43,65.21-16.35S372.5,347.08,385.33,315.38Z"
                    transform="translate(-45.72 -42.19)"
                    fill="#f4f5f6"
                />
                <path
                    id="Path_63"
                    data-name="Path 63"
                    d="M396.33,353.87c-.5-35.56,31.77-44.75,32.16-24.15S396.61,374.32,396.33,353.87Z"
                    transform="translate(-45.72 -42.19)"
                    fill="#f4f5f6"
                />
                <path
                    id="Path_64"
                    data-name="Path 64"
                    d="M386.47,396.9h0a37.13,37.13,0,0,1-36.35-29,76,76,0,0,0-3.79-13l-.28-.67h80.84l-.28.67a76.9,76.9,0,0,0-3.79,13,37.13,37.13,0,0,1-36.35,29Z"
                    transform="translate(-45.72 -42.19)"
                    fill="#e3e2e3"
                />
                <ellipse
                    id="_Path_"
                    data-name="&lt;Path&gt;"
                    cx="144.63"
                    cy="10.87"
                    rx="144.63"
                    ry="10.87"
                    transform="translate(65.97 433.43)"
                    fill="#f5f5f5"
                />
                <path
                    id="Path_65"
                    data-name="Path 65"
                    d="M135.42,228.2l-.63-.78a68.718,68.718,0,0,1,8.21-5.71l.51.86a65.647,65.647,0,0,0-8.09,5.63Z"
                    transform="translate(-45.72 -42.19)"
                    fill="#e3e2e3"
                />
                <path
                    id="Path_66"
                    data-name="Path 66"
                    d="M114.89,256.78l-.93-.35a68.709,68.709,0,0,1,15.67-24.35l.71.7a67.579,67.579,0,0,0-15.45,24Z"
                    transform="translate(-45.72 -42.19)"
                    fill="#e3e2e3"
                />
                <path
                    id="Path_67"
                    data-name="Path 67"
                    d="M308.63,300.85a53.26,53.26,0,0,0-73.21-49,64.38,64.38,0,0,0-120.7,14.24,55.41,55.41,0,0,0,3.6,110.7H294.64a39.28,39.28,0,0,0,14-76Z"
                    transform="translate(-45.72 -42.19)"
                    fill="#e3e2e3"
                />
                <path
                    id="Path_68"
                    data-name="Path 68"
                    d="M269,282.94H127.87a4.34,4.34,0,0,1-4.33-4.33h0a4.34,4.34,0,0,1,4.33-4.33H269a4.34,4.34,0,0,1,4.33,4.33h0A4.34,4.34,0,0,1,269,282.94Z"
                    transform="translate(-45.72 -42.19)"
                    fill="#707171"
                />
                <path
                    id="Path_69"
                    data-name="Path 69"
                    d="M247.68,341.54H149.15a14.56,14.56,0,0,1,0-29.12h98.53a14.56,14.56,0,1,1,0,29.12Zm-98.53-28.12a13.56,13.56,0,0,0,0,27.12h98.53a13.56,13.56,0,1,0,0-27.12Z"
                    transform="translate(-45.72 -42.19)"
                    fill="#fff"
                />
                <path
                    id="Path_70"
                    data-name="Path 70"
                    d="M172.16,338h-23a11.1,11.1,0,0,1-11.07-11h0a11.1,11.1,0,0,1,11.06-11.06h23A11.09,11.09,0,0,1,183.22,327h0a11.09,11.09,0,0,1-11.06,11Z"
                    transform="translate(-45.72 -42.19)"
                    fill="#fff"
                />
                <path
                    id="Path_71"
                    data-name="Path 71"
                    d="M273,198.88l-18.71,79.73H142.67l31.22-133.06,77.29,18.14Z"
                    transform="translate(-45.72 -42.19)"
                    fill="#007cff"
                />
                <path
                    id="Path_72"
                    data-name="Path 72"
                    d="M273,198.88l-18.71,79.73H142.67l31.22-133.06,77.29,18.14Z"
                    transform="translate(-45.72 -42.19)"
                    fill="#f4f5f6"
                />
                <path
                    id="Path_73"
                    data-name="Path 73"
                    d="M258.44,213.7l-84.78-19.89a2.28,2.28,0,0,1-1.7-2.73h0a2.28,2.28,0,0,1,2.73-1.69l84.79,19.89a2.29,2.29,0,0,1,1.69,2.73h0a2.27,2.27,0,0,1-2.73,1.69Z"
                    transform="translate(-45.72 -42.19)"
                    fill="#707171"
                />
                <path
                    id="Path_74"
                    data-name="Path 74"
                    d="M255.2,227.5l-84.78-19.89a2.27,2.27,0,0,1-1.69-2.73h0a2.26,2.26,0,0,1,2.72-1.69l84.79,19.89a2.29,2.29,0,0,1,1.69,2.73h0A2.28,2.28,0,0,1,255.2,227.5Z"
                    transform="translate(-45.72 -42.19)"
                    fill="#707171"
                />
                <path
                    id="Path_75"
                    data-name="Path 75"
                    d="M252,241.3l-84.78-19.89a2.27,2.27,0,0,1-1.69-2.73h0a2.27,2.27,0,0,1,2.73-1.69L253,236.88a2.27,2.27,0,0,1,1.69,2.73h0A2.28,2.28,0,0,1,252,241.3Z"
                    transform="translate(-45.72 -42.19)"
                    fill="#707171"
                />
                <path
                    id="Path_76"
                    data-name="Path 76"
                    d="M248.73,255.1l-84.79-19.9a2.28,2.28,0,0,1-1.69-2.72h0a2.28,2.28,0,0,1,2.73-1.7l84.78,19.9a2.27,2.27,0,0,1,1.69,2.73h0a2.28,2.28,0,0,1-2.72,1.69Z"
                    transform="translate(-45.72 -42.19)"
                    fill="#707171"
                />
                <path
                    id="Path_77"
                    data-name="Path 77"
                    d="M211.78,261,160.7,249a2.28,2.28,0,0,1-1.69-2.73h0a2.29,2.29,0,0,1,2.73-1.69l51.08,12a2.27,2.27,0,0,1,1.69,2.73h0A2.29,2.29,0,0,1,211.78,261Z"
                    transform="translate(-45.72 -42.19)"
                    fill="#707171"
                />
                <path
                    id="Path_78"
                    data-name="Path 78"
                    d="M273,198.88l-28.51-6.69,6.69-28.5Z"
                    transform="translate(-45.72 -42.19)"
                    fill="#707171"
                />
                <path
                    id="Path_79"
                    data-name="Path 79"
                    d="M292.15,274.59l-23.7,155.9h4.68l3.13-20.63h60.38l-3.13,20.63h4.68l23.7-155.9Zm45.12,131.14H276.89l3.36-22.12h60.38Zm4-26.25H280.88l3.36-22.12h60.38Zm4-26.25h-60.4l3.37-22.12h60.38Zm4-26.25H288.86l3.37-22.12h60.38Zm-56.39-26.25,3.37-22.12H356.6l-3.37,22.12Z"
                    transform="translate(-45.72 -42.19)"
                    fill="#707171"
                />
                <path
                    id="Path_80"
                    data-name="Path 80"
                    d="M371.63,274.59H301.89l23.71,155.9h4.68l-3.14-20.63h60.38l3.14,20.63h4.68Zm-64.44,4h60.38l3.36,22.12H310.55Zm4,26.25h60.38L374.92,327H314.54Zm4,26.25h60.38l3.36,22.12h-60.4Zm4,26.25h60.38l3.36,22.12H322.52Zm7.35,48.37-3.36-22.12h60.38l3.36,22.12Z"
                    transform="translate(-45.72 -42.19)"
                    fill="#f4f5f6"
                />
                <path
                    id="Path_81"
                    data-name="Path 81"
                    d="M371.63,274.59H301.89l23.71,155.9h4.68l-3.14-20.63h60.38l3.14,20.63h4.68Zm-64.44,4h60.38l3.36,22.12H310.55Zm4,26.25h60.38L374.92,327H314.54Zm4,26.25h60.38l3.36,22.12h-60.4Zm4,26.25h60.38l3.36,22.12H322.52Zm7.35,48.37-3.36-22.12h60.38l3.36,22.12Z"
                    transform="translate(-45.72 -42.19)"
                    fill="#f4f5f6"
                    opacity="0.4"
                />
                <path
                    id="Path_82"
                    data-name="Path 82"
                    d="M341.75,122.83c2,1.54,3.76,3.09,5.59,4.68s3.56,3.25,5.28,4.94a109.76,109.76,0,0,1,9.76,10.91l1.12,1.51.55.76a9.655,9.655,0,0,1,.86,1.39,13.17,13.17,0,0,1,1.37,5.17,27,27,0,0,1-.84,8.26,56.141,56.141,0,0,1-5.54,13.8l-5.11-2.08A97.006,97.006,0,0,0,357,159.05a21.89,21.89,0,0,0-.18-5.71,3.87,3.87,0,0,0-.59-1.5c-.05-.06-.07-.05-.1-.08l-.54-.62-1.08-1.23q-4.5-4.86-9.5-9.44c-3.34-3.05-6.8-6.1-10.16-8.93Z"
                    transform="translate(-45.72 -42.19)"
                    fill="#e3e2e3"
                />
                <path
                    id="Path_83"
                    data-name="Path 83"
                    d="M356.81,170.1l-5.74,4,7,4.24s3.61-3.46,2.06-6.66Z"
                    transform="translate(-45.72 -42.19)"
                    fill="#e3e2e3"
                />
                <path
                    id="Path_84"
                    data-name="Path 84"
                    d="M348.21,180.67l5.3,2.98,4.6-5.35-7.04-4.24Z"
                    transform="translate(-45.72 -42.19)"
                    fill="#e3e2e3"
                />
                <path
                    id="Path_85"
                    data-name="Path 85"
                    d="M302.6,101.94c.17.64,0,1.26-.46,1.36s-.9-.32-1.07-1,.05-1.26.47-1.37S302.44,101.29,302.6,101.94Z"
                    transform="translate(-45.72 -42.19)"
                    fill="#707171"
                />
                <path
                    id="Path_86"
                    data-name="Path 86"
                    d="M302.62,103.18A23.709,23.709,0,0,1,301,109.5a3.83,3.83,0,0,0,3.24-.21Z"
                    transform="translate(-45.72 -42.19)"
                    fill="#e3e2e3"
                />
                <path
                    id="Path_87"
                    data-name="Path 87"
                    d="M304,97.77a.4.4,0,0,1-.24,0,3.11,3.11,0,0,0-2.83.2.391.391,0,0,1-.45-.64A3.88,3.88,0,0,1,304,97a.41.41,0,0,1,.23.51.4.4,0,0,1-.23.26Z"
                    transform="translate(-45.72 -42.19)"
                    fill="#707171"
                />
                <path
                    id="Path_88"
                    data-name="Path 88"
                    d="M323.11,103.75c.93,6.1,2.24,15.45,7.6,17.82,0,0,.52,6.34-10.07,10.15-11.64,4.19-7.64-3.78-7.64-3.78,5.81-3.8,3.95-8.46,1.25-12.49Z"
                    transform="translate(-45.72 -42.19)"
                    fill="#e3e2e3"
                />
                <path
                    id="Path_89"
                    data-name="Path 89"
                    d="M331.71,120.3c0-1.92-1.95-5.22-3.92-5-2.17.24-10.45,3.29-13.47,6.08s-.88,7.69-.88,7.69Z"
                    transform="translate(-45.72 -42.19)"
                    fill="#707171"
                />
                <path
                    id="Path_90"
                    data-name="Path 90"
                    d="M395.56,351.45c-.52.55-1.11,1-1.58.94a.72.72,0,0,1-.58-.58.78.78,0,0,1,.08-.68c.57-.95,3-1.43,3.08-1.45a.162.162,0,0,1,.18.25,8.311,8.311,0,0,1-1.18,1.52Zm-1.64-.31a1,1,0,0,0-.14.17.47.47,0,0,0,0,.4c.09.31.25.33.29.33.52.06,1.57-1,2.2-1.93a5.63,5.63,0,0,0-2.35,1.03Z"
                    transform="translate(-45.72 -42.19)"
                    opacity="0.2"
                />
                <path
                    id="Path_91"
                    data-name="Path 91"
                    d="M396.72,350h-.07c-.83.26-3,.2-3.49-.48a.59.59,0,0,1,.06-.72,1,1,0,0,1,.68-.38c1.09-.12,2.74,1.22,2.81,1.28a.21.21,0,0,1,.06.17.409.409,0,0,1-.05.13Zm-3.17-1-.05.06c-.14.18-.08.27-.05.31.28.4,1.81.57,2.74.43a4.17,4.17,0,0,0-2.25-1,.65.65,0,0,0-.39.2Z"
                    transform="translate(-45.72 -42.19)"
                    opacity="0.2"
                />
                <path
                    id="Path_92"
                    data-name="Path 92"
                    d="M335.07,372.36c-.94,0-1.85-.13-2.16-.56a.65.65,0,0,1,0-.76.86.86,0,0,1,.55-.4c1.22-.34,3.79,1.13,3.9,1.19a.18.18,0,0,1,.08.18.17.17,0,0,1-.14.14,13.122,13.122,0,0,1-2.23.21Zm-1.17-1.43a1.661,1.661,0,0,0-.33,0,.56.56,0,0,0-.34.25c-.13.21-.08.32,0,.38.35.48,2.2.49,3.57.29a7.87,7.87,0,0,0-2.9-.92Z"
                    transform="translate(-45.72 -42.19)"
                    opacity="0.2"
                />
                <path
                    id="Path_93"
                    data-name="Path 93"
                    d="M337.29,372.15h-.07c-.89-.4-2.65-2-2.51-2.82,0-.19.17-.44.65-.48a1.25,1.25,0,0,1,1,.29c.94.77,1.13,2.75,1.14,2.83a.16.16,0,0,1-.07.16A.19.19,0,0,1,337.29,372.15Zm-1.79-3h-.11c-.31,0-.33.16-.34.2-.08.5,1.12,1.74,2,2.29a4.1,4.1,0,0,0-1-2.27.91.91,0,0,0-.55-.19Z"
                    transform="translate(-45.72 -42.19)"
                    opacity="0.2"
                />
                <path
                    id="Path_94"
                    data-name="Path 94"
                    d="M345.37,371.98h-7.35l-.73-17.01h7.34Z"
                    transform="translate(-45.72 -42.19)"
                    fill="#e3e2e3"
                />
                <path
                    id="Path_95"
                    data-name="Path 95"
                    d="M404.23,347.06l-6.8,2.47-8.63-15.24,6.8-2.47Z"
                    transform="translate(-45.72 -42.19)"
                    fill="#e3e2e3"
                />
                <path
                    id="Path_96"
                    data-name="Path 96"
                    d="M396.36,348.86l5.74-5.93a.64.64,0,0,1,.8-.1l5.7,3.5a1.08,1.08,0,0,1,.19,1.68c-2,2-3.13,2.92-5.66,5.53-1.55,1.61-6.17,7-8.31,9.26s-4.62.39-3.94-.71c3.05-4.93,4.89-9.62,5-12.06a1.83,1.83,0,0,1,.48-1.17Z"
                    transform="translate(-45.72 -42.19)"
                    fill="#707171"
                />
                <path
                    id="Path_97"
                    data-name="Path 97"
                    d="M338.07,371.13h8a.65.65,0,0,1,.63.5l1.45,6.53a1.07,1.07,0,0,1-1.06,1.31c-2.89-.05-7.07-.22-10.71-.22-4.26,0-6.11.23-11.1.23-3,0-3.85-3.05-2.59-3.32,5.75-1.26,8.62-1.4,13.59-4.46a3.41,3.41,0,0,1,1.79-.57Z"
                    transform="translate(-45.72 -42.19)"
                    fill="#707171"
                />
                <path
                    id="Path_98"
                    data-name="Path 98"
                    d="M318.11,137.42c-1.13,2.85-2.33,5.51-3.57,8.22s-2.56,5.37-3.92,8a124.572,124.572,0,0,1-9.45,15.57q-1.4,1.89-3,3.74c-.55.63-1.11,1.22-1.77,1.87a16.388,16.388,0,0,1-2.49,1.92,29.071,29.071,0,0,1-4.62,2.29c-1.5.61-3,1.1-4.47,1.54A91.918,91.918,0,0,1,267,183.9l-1.12-5.4c5.35-1.94,10.8-3.94,15.81-6.19,1.24-.57,2.46-1.16,3.6-1.77a20.791,20.791,0,0,0,3-1.92,5.792,5.792,0,0,0,.89-.83c.35-.4.77-.88,1.15-1.38.77-1,1.53-2.06,2.26-3.18,1.48-2.23,2.87-4.64,4.2-7.09s2.62-5,3.87-7.5c2.49-5.07,4.9-10.32,7.12-15.44Z"
                    transform="translate(-45.72 -42.19)"
                    fill="#e3e2e3"
                />
                <path
                    id="Path_99"
                    data-name="Path 99"
                    d="M334.88,118.45c10.54-.83,20,14.8,20,14.8l-7.58,12.3a54.47,54.47,0,0,1-15-8.93C325.14,130.39,327.68,119,334.88,118.45Z"
                    transform="translate(-45.72 -42.19)"
                    fill="#707171"
                />
                <path
                    id="Path_100"
                    data-name="Path 100"
                    d="M334.22,131.62l11.09,14.93-.5.61a110.089,110.089,0,0,1-8.24-7.35Z"
                    transform="translate(-45.72 -42.19)"
                    opacity="0.3"
                />
                <path
                    id="Path_101"
                    data-name="Path 101"
                    d="M305.64,130.1c-.58,5.14,8,27.92,10,55l39.1-3.85c-.27-5.19-3.14-27-8.31-51.64-1.43-6.8-6.7-12-13.51-10.66h-.19a144.454,144.454,0,0,0-16.18,4.14,73.11,73.11,0,0,0-8.76,3.88,4,4,0,0,0-2.15,3.13Z"
                    transform="translate(-45.72 -42.19)"
                    fill="#707171"
                />
                <path
                    id="Path_102"
                    data-name="Path 102"
                    d="M309.61,149c.66,2.75,1.35,5.73,2,8.9,5.95-1.41,4.8-14.6,4.92-14.77Z"
                    transform="translate(-45.72 -42.19)"
                    opacity="0.3"
                />
                <path
                    id="Path_103"
                    data-name="Path 103"
                    d="M318.39,132.64c3,10.29-8.11,24-8.11,24l-11.18-7.2s-.07-9.42,3.5-16.79C307.13,123.34,316.25,125.31,318.39,132.64Z"
                    transform="translate(-45.72 -42.19)"
                    fill="#707171"
                />
                <path
                    id="Path_104"
                    data-name="Path 104"
                    d="M266.1,177.55l-4.49-.62.07,9.39s6.05.61,9.41-3.55l-.66-1.77A5.4,5.4,0,0,0,266.1,177.55Z"
                    transform="translate(-45.72 -42.19)"
                    fill="#e3e2e3"
                />
                <path
                    id="Path_105"
                    data-name="Path 105"
                    d="M253.89,179.1l2.88,7.64,4.91-.42-.07-9.39Z"
                    transform="translate(-45.72 -42.19)"
                    fill="#e3e2e3"
                />
                <path
                    id="Path_106"
                    data-name="Path 106"
                    d="M337.29,354.97l.41,9.57h7.35l-.41-9.57Z"
                    transform="translate(-45.72 -42.19)"
                    opacity="0.2"
                />
                <path
                    id="Path_107"
                    data-name="Path 107"
                    d="M395.6,331.82l-6.8,2.48,4.78,8.43,6.48-3.05Z"
                    transform="translate(-45.72 -42.19)"
                    opacity="0.2"
                />
                <path
                    id="Path_108"
                    data-name="Path 108"
                    d="M323.82,95c1.55,8.07,2.87,12.71-.1,17.81-4.46,7.68-15.2,6.56-19.13-.88-3.53-6.7-5.47-18.77,1.5-23.92A11.2,11.2,0,0,1,323.82,95Z"
                    transform="translate(-45.72 -42.19)"
                    fill="#e3e2e3"
                />
                <path
                    id="Path_109"
                    data-name="Path 109"
                    d="M310.22,100.42c-4.91,2.46-5-5.4-4.6-7.06-1.85,1.77-4.35,3.56-8.9,1.6-5.58-2.4-4-8.71,0-8.62,0,0-3-5.71,3.57-6.31a21.92,21.92,0,0,1,7.93,1.22s2.44-6.36,8.36-3.28c3.13,1.64,5.62,6.45,5.62,6.45S335,86.31,328,93c1.57,3.41.73,14.54-3.32,19.3C323,114.36,307.81,113.75,310.22,100.42Z"
                    transform="translate(-45.72 -42.19)"
                    fill="#707171"
                />
                <path
                    id="Path_110"
                    data-name="Path 110"
                    d="M320,87.67s2.31-3.18,2.49-10.54C327.89,83,320,87.67,320,87.67Z"
                    transform="translate(-45.72 -42.19)"
                    fill="#707171"
                />
                <path
                    id="Path_111"
                    data-name="Path 111"
                    d="M312.62,100a9.29,9.29,0,0,1-1.52,5.77c-1.5,2.22-3.54,1.27-4.2-1.08-.6-2.12-.54-5.83,1.49-7.23S312.41,97.52,312.62,100Z"
                    transform="translate(-45.72 -42.19)"
                    fill="#e3e2e3"
                />
                <path
                    id="Path_112"
                    data-name="Path 112"
                    d="M329.54,183.68s13.8,55.06,20.41,75.54c7.25,22.43,38.67,81.26,38.67,81.26L401,335.12s-23.78-53.57-28.86-76c-5.51-24.33-17.32-77.94-17.32-77.94Z"
                    transform="translate(-45.72 -42.19)"
                    fill="#e3e2e3"
                />
                <path
                    id="Path_113"
                    data-name="Path 113"
                    d="M385,336.43c-.05,0,4,5.78,4,5.78l13.84-6.12-2.79-5.72Z"
                    transform="translate(-45.72 -42.19)"
                    fill="#707171"
                />
                <path
                    id="Path_114"
                    data-name="Path 114"
                    d="M336.75,203.07c9.77.94,12.28,36.82,12.94,55.33-4.05-12.74-10.56-37.37-15.08-54.86a3.31,3.31,0,0,1,2.14-.47Z"
                    transform="translate(-45.72 -42.19)"
                    opacity="0.3"
                />
                <path
                    id="Path_115"
                    data-name="Path 115"
                    d="M315.67,185.05s1.59,56.52,4.58,78.73c3.11,23.11,14.61,97,14.61,97H347.3s-2.87-74.63-4.56-97.46c-1.84-24.88-1.37-80.83-1.37-80.83Z"
                    transform="translate(-45.72 -42.19)"
                    fill="#e3e2e3"
                />
                <path
                    id="Path_116"
                    data-name="Path 116"
                    d="M332.38,355c-.05,0,1,7.54,1,7.54L348.5,362l.14-7Z"
                    transform="translate(-45.72 -42.19)"
                    fill="#707171"
                />
                <circle
                    id="Ellipse_7"
                    data-name="Ellipse 7"
                    cx="10.38"
                    cy="10.38"
                    r="10.38"
                    transform="translate(107.42 40.21)"
                    fill="#fff"
                    stroke="#e2e2e2"
                    strokeMiterlimit="10"
                    strokeWidth="2"
                />
                <circle
                    id="Ellipse_8"
                    data-name="Ellipse 8"
                    cx="7.81"
                    cy="7.81"
                    r="7.81"
                    transform="translate(94.93 357.28)"
                    fill="#fff"
                    stroke="#e2e2e2"
                    strokeMiterlimit="10"
                    strokeWidth="2"
                />
                <path
                    id="Path_117"
                    data-name="Path 117"
                    d="M451.65,198.16h-5.71v-5.71a.71.71,0,0,0-.71-.71h0a.73.73,0,0,0-.73.71v5.71h-5.7a.72.72,0,0,0,0,1.44h5.7v5.71a.72.72,0,0,0,.73.71.71.71,0,0,0,.71-.71h0V199.6h5.71a.72.72,0,1,0,0-1.44Z"
                    transform="translate(-45.72 -42.19)"
                    fill="#e2e2e2"
                    stroke="#e2e2e2"
                    strokeMiterlimit="10"
                    strokeWidth="0.75"
                />
                <path
                    id="Path_118"
                    data-name="Path 118"
                    d="M257.19,405h-7v-7a.86.86,0,0,0-.86-.87h0a.89.89,0,0,0-.89.87v7h-7a.89.89,0,0,0-.88.89.88.88,0,0,0,.88.87h7v7a.89.89,0,0,0,.89.87.86.86,0,0,0,.86-.87h0v-7h7a.87.87,0,0,0,.87-.87.89.89,0,0,0-.87-.89Z"
                    transform="translate(-45.72 -42.19)"
                    fill="#e2e2e2"
                    stroke="#e2e2e2"
                    strokeMiterlimit="10"
                    strokeWidth="0.75"
                />
                <path
                    id="Path_119"
                    data-name="Path 119"
                    d="M78.76,257.52h-7v-7a.87.87,0,0,0-.87-.86h0a.89.89,0,0,0-.89.86v7H63a.88.88,0,1,0,0,1.75h7v7a.89.89,0,0,0,.89.86.87.87,0,0,0,.87-.86h0v-7h7a.88.88,0,1,0,0-1.75Z"
                    transform="translate(-45.72 -42.19)"
                    fill="#e2e2e2"
                    stroke="#e2e2e2"
                    strokeMiterlimit="10"
                    strokeWidth="0.75"
                />
            </g>
        </SVGIcon>
    );
}
