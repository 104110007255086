import React from 'react';

import { SVGIcon } from '../../UIKit/index';

export default function DCAffiliateCenter(props) {
    return (
        <SVGIcon {...props} viewBox="0 0 512 512">
            <g transform="translate(3.42 -22.58)">
                <g transform="translate(-2.97 23.03)">
                    <g transform="translate(0 0)">
                        <path
                            fill="#005EFF"
                            stroke="#FFFFFF"
                            strokeWidth="11.8421"
                            d="M338.3,249.4c59.3-45.7,70.3-130.8,24.7-190.1C317.2,0,232.1-11,172.8,34.6     s-70.3,130.8-24.7,190.1c7.1,9.2,15.4,17.5,24.7,24.7C72.6,284.5,5.5,379.2,5.6,485.5c0,11.6,9.4,21.1,21.1,21.1h457.9     c11.6,0,21.1-9.4,21.1-21.1C505.6,379.2,438.5,284.5,338.3,249.4z M162.2,142c0-51.6,41.9-93.3,93.4-93.3     c51.6,0,93.3,41.9,93.3,93.4c0,51.5-41.8,93.3-93.4,93.3C204,235.4,162.2,193.6,162.2,142z M48.8,464.3     C60.5,350.1,162.5,267,276.6,278.7c98.1,10,175.7,87.6,185.7,185.7H48.8z"
                        />
                    </g>
                </g>
                <g transform="translate(8.549 25.655)">
                    <path
                        fill="none"
                        d="M243.9,38.4c54.8,0.6,98.8,45.5,98.3,100.3c0.5,54.8-43.4,99.7-98.3,100.3c-54.8-0.6-98.8-45.5-98.3-100.3    C145,83.9,189,39,243.9,38.4z"
                    />
                    <path
                        fill="#D5E5FF"
                        d="M243.9,64.7c-39.7,0-72,33.2-72,74s32.3,74,72,74s72-33.2,72-74S283.6,64.7,243.9,64.7 M243.9,38.4    c54.3,0,98.3,44.9,98.3,100.3S298.2,239,243.9,239s-98.3-44.9-98.3-100.3S189.6,38.4,243.9,38.4z"
                    />
                </g>
            </g>
        </SVGIcon>
    );
}
