import React from 'react';

import { SVGIcon } from '../../UIKit/index';

export default function DCDollarSign(props) {
    return (
        <SVGIcon {...props} viewBox="0 0 294.7 512">
            <path d="M170.8,224.3V110.4c24.4,4.1,47.4,14.1,67,29.1c3.9,2.7,8.6,4.1,13.3,4.2    c15.3,0,27.7-12.3,27.8-27.5c0-6.5-2.5-12.8-7.1-17.5c-28.4-24.3-64-38.6-101.3-40.8V19.8c0-10.9-8.8-19.7-19.7-19.8    c0,0-0.1,0-0.1,0h-0.7c-11-0.2-20.1,8.5-20.4,19.6c0,0.1,0,0.1,0,0.2v36.8C56.9,61.7,7.4,106.7,7.4,166.6    c0,73.5,62.4,93.8,122.3,110V406c-31.6-4.2-61.3-17.4-85.8-37.9c-4.6-3.7-10.3-5.7-16.2-5.8c-14.9,1-26.4,13.5-26.2,28.5    c0,6.5,2.5,12.8,7.1,17.5c33.4,30.1,76.4,47.4,121.4,48.9v35.6c0,0.2,0,0.5,0,0.7c0.5,11,9.8,19.6,20.8,19.1c0.1,0,0.1,0,0.2,0    c10.9,0,19.7-8.8,19.7-19.7c0,0,0,0,0,0v-36.3c88.4-5.8,123.9-59.5,123.9-116.4C294.7,263.8,230.7,240.5,170.8,224.3z M130,213.9    c-35.3-10.3-62.8-21-62.8-51.1S92.2,111,130,108.1V213.9z M170.8,406.9V289c36.6,10.3,65,24.3,64.7,58.2    C235.6,371.8,218.7,401,170.8,406.9L170.8,406.9z" />
        </SVGIcon>
    );
}
