import React from 'react';

import { SVGIcon } from '../../UIKit/index';

export default function DCSingleOption(props) {
    return (
        <SVGIcon {...props} viewBox="0 0 512 512">
            <g>
                <path d="M216.1-0.5H18.7c-10.4,0-19,8.5-19,19v197.4c0,10.4,8.5,19,19,19h197.4c10.4,0,19-8.5,19-19V18.5    C235.1,8,226.6-0.5,216.1-0.5z" />
                <path d="M493.3-0.5H295.8c-10.4,0-19,8.5-19,19v197.4c0,10.4,8.5,19,19,19h197.4c10.4,0,19-8.5,19-19V18.5    C512.2,8,503.7-0.5,493.3-0.5z M474.3,196.9H314.8V37.4h159.4V196.9z" />
                <path d="M216.1,276.6H18.7c-10.4,0-19,8.5-19,19V493c0,10.4,8.5,19,19,19h197.4c10.4,0,19-8.5,19-19V295.6    C235.1,285.2,226.6,276.6,216.1,276.6z M197.1,474H37.7V314.6h159.4V474z" />
                <path d="M493.3,276.6H295.8c-10.4,0-19,8.5-19,19V493c0,10.4,8.5,19,19,19h197.4c10.4,0,19-8.5,19-19V295.6    C512.2,285.2,503.7,276.6,493.3,276.6z M474.3,474H314.8V314.6h159.4V474z" />
            </g>
        </SVGIcon>
    );
}
