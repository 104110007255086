import React, { Fragment } from 'react';

import { Div, Skeleton } from '../index';

import './style.scss';

const DealDetailEditSkeleton = ({ customClass }) => {
    return (
        <Fragment>
            <Div className={'DealDetailEdit' + (customClass ? customClass : '')}>
                <Div className="DDEInner">
                    <Div className="DDEIInfoEdit">
                        <Div className="DDEIInfo">
                            <Div className={'DDEIIStatus'}>
                                <Skeleton width={40} height={24} />
                            </Div>
                            <Div className="DDEIISkeleton">
                                <Skeleton height={30} width={280} />
                            </Div>
                            <Div className="DDEIISkeleton">
                                <Skeleton height={34} width={280} />
                            </Div>
                            <Div className="DDEIISkeleton">
                                <Skeleton height={26} width={280} />
                            </Div>
                            <Div className="DDEIISkeleton">
                                <Skeleton height={26} width={280} />
                            </Div>
                        </Div>
                    </Div>
                </Div>
            </Div>
        </Fragment>
    );
};

export default DealDetailEditSkeleton;
