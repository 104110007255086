import React from 'react';

import { SVGIcon } from '../../UIKit/index';

export default function DCList(props) {
    return (
        <SVGIcon {...props} viewBox="0 0 612 612">
            <path d="M63.311,73.862C28.342,73.862,0,102.204,0,137.172s28.342,63.311,63.311,63.311c34.968,0,63.31-28.342,63.31-63.311     S98.279,73.862,63.311,73.862z M63.311,242.689C28.342,242.689,0,271.032,0,306c0,34.969,28.342,63.311,63.311,63.311     c34.968,0,63.31-28.342,63.31-63.311C126.621,271.032,98.279,242.689,63.311,242.689z M63.311,411.518     C28.342,411.518,0,439.859,0,474.827c0,34.969,28.342,63.311,63.311,63.311c34.968,0,63.31-28.342,63.31-63.311     C126.621,439.859,98.279,411.518,63.311,411.518z M232.138,179.379h337.655c23.319,0,42.207-18.888,42.207-42.207     s-18.888-42.207-42.207-42.207H232.138c-23.319,0-42.207,18.888-42.207,42.207S208.819,179.379,232.138,179.379z      M569.793,263.793H232.138c-23.319,0-42.207,18.888-42.207,42.207s18.888,42.207,42.207,42.207h337.655     C593.112,348.207,612,329.319,612,306S593.112,263.793,569.793,263.793z M569.793,432.621H232.138     c-23.319,0-42.207,18.887-42.207,42.206s18.888,42.207,42.207,42.207h337.655c23.319,0,42.207-18.888,42.207-42.207     S593.112,432.621,569.793,432.621z" />
        </SVGIcon>
    );
}
