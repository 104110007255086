import actions from '../Actions/actions';

const reducer = (state, action) => {
    switch (action.type) {
        case actions.SET_FORMS_COLUMNS:
            return {
                ...state,
                formsColumns: action.value,
            };

        case actions.SET_FORMS: {
            let temp = { ...state.forms };
            if (action.payload.mode === 'replace') {
                temp.data = {};
            }
            temp.data[action.payload.page] = [...action.payload.data];
            temp.nextPage = action.payload.next_page;
            temp.total = action.payload.total;
            temp.totalPages = action.payload.total_pages;
            temp.loaded = true;
            return {
                ...state,
                forms: temp,
            };
        }

        case actions.SET_FORMS_TOTAL:
            return {
                ...state,
                forms: {
                    ...state.forms,
                    total: action.value,
                },
            };

        case actions.CLEAR_FORMS:
            return {
                ...state,
                forms: {
                    data: {},
                    nextPage: 1,
                    total: 0,
                    totalPages: 0,
                    loaded: false,
                },
                formsCurrentPage: 1,
            };

        case actions.SET_FORMS_CURRENT_PAGE:
            return {
                ...state,
                formsCurrentPage: action.payload,
            };

        case actions.SET_FORMS_SEARCH_TERM:
            return {
                ...state,
                searchTerm: action.payload,
            };

        case actions.SET_FORMS_CATEGORIES:
            return {
                ...state,
                formsCategories: [...action.payload],
            };

        case actions.SET_FORMS_TAGS:
            return {
                ...state,
                formsTags: action.payload,
            };

        case actions.SET_FORMS_ACTIVE_CATEGORIES:
            return {
                ...state,
                filters: {
                    ...state.filters,
                    categories: action.payload,
                },
            };

        case actions.SET_FORMS_ACTIVE_TAGS:
            return {
                ...state,
                filters: {
                    ...state.filters,
                    tags: action.payload,
                },
            };

        // Templates
        case actions.SET_INITIAL_TEMPLATE_LOAD:
            return { ...state, initialTemplateLoad: action.value };
        case actions.SET_ACTIVE_CATEGORY:
            return { ...state, activeCategory: action.value };
        case actions.SET_CATEGORY_ALL:
            return { ...state, categoryAll: action.value };
        case actions.SET_CATEGORIES:
            return { ...state, categories: action.value };
        case actions.SET_TEMPLATES_DATA:
            return { ...state, templates: action.value };
        case actions.SET_TEMPLATE_SEARCH_TEXT:
            return { ...state, templateSearchText: action.value };

        // Builder Reducer
        case actions.RESET_STYLES:
            localStorage.removeItem('createTemplate');
            return {
                ...state,
                style: {
                    pageBackground: '#ffffff',
                    backgroundColor: '#ffffff',
                    fontColor: '#000000',
                    borderColor: '#000000',
                    borderWidth: 0,
                    cornerRadius: 0,
                    width: 100,
                    btnBackgroundColor: 'var(--dark-blue)',
                    btnFontColor: '#ffffff',
                },
                id: '',
                time: '',
                formComponents: {},
                options: {
                    formName: '',
                    category: '',
                    tag: '',
                    message: '',
                    redirect: '',
                    fbPixelId: '',
                    fbPixelEvents: {
                        pageView: '',
                        onSubmit: '',
                    },
                    categories: [],
                    tags: [],
                },
                domain: '',
            };
        case actions.UPDATE_STYLE:
            return {
                ...state,
                style: action.value,
            };
        case actions.ADD_ID:
            return {
                ...state,
                id: action.value,
            };
        case actions.LOADING:
            return {
                ...state,
                loading: action.value,
            };
        case actions.AUTO_SAVE:
            return {
                ...state,
                time: action.value,
            };
        case actions.UPDATE_OPTIONS:
            return {
                ...state,
                options: action.value,
            };
        case actions.ADD_DOMAIN:
            return {
                ...state,
                domain: action.value,
            };
        case actions.ADD_FORM_COMPONENTS:
            return {
                ...state,
                formComponents: action.value,
            };
        case actions.SET_CATEGORIES_LOADED:
            return {
                ...state,
                categoriesLoaded: action.value,
            };
        case actions.SET_TAGS_LOADED:
            return {
                ...state,
                tagsLoaded: action.value,
            };

        default:
            throw new Error('Unsupported Action');
    }
};

export default reducer;
