import { callInstasiteView } from 'apis/instasites/index.api';
import useToast from 'Modules/Toasts';
import React, { useEffect, useRef } from 'react';
import { useRouteMatch } from 'react-router';
import { CircularLoader, Div } from '../../UIKit';

const ViewInstasite = () => {
    const route = useRouteMatch();
    const { showToast } = useToast();
    const firstRef = useRef(true);
    useEffect(() => {
        if (firstRef.current) {
            let params = route.params;
            callInstasiteView(params).catch(err => {
                showToast({ type: 'error', message: err });
            });
        }
    }, [route, showToast]);

    return (
        <Div>
            <CircularLoader />
        </Div>
    );
};

export default ViewInstasite;
