import React, { Fragment, useCallback, useState } from 'react';

import DCBlogPost from 'Assets/icons/DCBlogPost';
import DCLike from 'Assets/icons/DCLike';
import DCSocialShare from 'Assets/icons/DCSocialShare';
import { Button, Div, Image, Strong } from 'UIKit/index';
import HowToDialog from './howToDialog';
import NoFound from './noFound';
import generateImage from 'Utils/generateImage';
import { abbreviateNumber } from 'Utils/stringUtils';

const Social = ({ grade, facebook, twitter, details, user, handleGetInTouchOpen }) => {
    const [howToDialog, setHowToDialog] = useState(false);
    const handleHowToDialogOpen = useCallback(() => {
        setHowToDialog(true);
    }, []);

    const handleHowToDialogClose = useCallback(() => {
        setHowToDialog(false);
    }, []);

    return (
        <Fragment>
            <HowToDialog
                open={howToDialog}
                onClose={handleHowToDialogClose}
                title={'How we calculate the Social grade'}
                description={
                    <Fragment>
                        Your <Strong>Social score</Strong> is calculated by comparing your metrics
                        versus the industry average. Our weigh algorithm adds all 4 metrics
                        together, divides it by 4, then compares it to the industry average within
                        that category. The metrics that are calculated are page likes, average posts
                        per month, average likes per post and average shares per post.
                    </Fragment>
                }
            />
            <Div className={'EPComponent'}>
                <Div className={'EPCHead'}>
                    <Div className={'EPCHTBox'}>
                        <Div className={'EPCHTBIcon Grade' + grade}>{grade}</Div>
                        <Div className={'EPCHTBTitle'}>Social</Div>
                        <Div className={'EPCHTBSTitle'}>Are you engaging with your customers?</Div>
                    </Div>
                    <Div className={'EPCHSTitle'} onClick={handleHowToDialogOpen}>
                        How we calculated this grade?
                    </Div>
                </Div>
                <Div className={'EPCBody'}>
                    <Div className={'EPCVideoContent'}>
                        <Div className={'EPCVCVideo'}>
                            <iframe
                                src="https://player.vimeo.com/video/356237821"
                                title="video"
                                frameBorder="0"
                                webkitallowfullscreen=""
                                mozallowfullscreen=""
                            />
                        </Div>
                        <Div className={'EPCVCContent'}>
                            <Div className={'EPCVCCDes'}>
                                In this day and age, we’re all slowly becoming social media addicts.
                                An average of 2 hours and 22 minutes per day, per person are spent
                                browsing through social media.
                            </Div>
                            <br />
                            <Div className={'EPCVCCDes'}>
                                We dissected your Facebook business profile to see how up to date
                                and relevant {details?.name} social media pages are, compared to
                                your competitors.
                            </Div>
                        </Div>
                    </Div>
                    {facebook || twitter ? (
                        <Fragment>
                            {facebook && (
                                <React.Fragment>
                                    <Div className={'EPCInnerHeading'}>
                                        <Div className={'EPCIHLTitle'}>Facebook</Div>
                                        <Div className={'EPCIHRTitle'}>
                                            Your Business’s Information on Facebook
                                        </Div>
                                    </Div>
                                    <Div className={'EPCRow'}>
                                        <Div className={'EPCRCol25'}>
                                            <Div className={'EPCPageInfo'}>
                                                <Div className={'EPCPIHead'}>
                                                    <Div className={'EPCPIHTitleBox'}>
                                                        <Div className={'EPCPIHTBTitle'}>Likes</Div>
                                                        <Div className={'EPCPIHTBSTitle'}>
                                                            {abbreviateNumber(
                                                                facebook?.likes?.totalLikes,
                                                            )}
                                                        </Div>
                                                    </Div>
                                                    <Div
                                                        className={'EPCPIHIcon'}
                                                        style={{
                                                            backgroundColor: '#713BDB',
                                                        }}
                                                    >
                                                        <DCLike />
                                                    </Div>
                                                </Div>
                                                <Div className={'EPCPIBody'}>
                                                    <Div className={'EPCPIBRow'}>
                                                        <Div className={'EPCPIBRCol'}>
                                                            <Div className={'EPCPIBRCTitle'}>
                                                                Industry Average
                                                            </Div>
                                                            <Div className={'EPCPIBRCNum'}>
                                                                {abbreviateNumber(
                                                                    facebook?.likes
                                                                        ?.industryAverage,
                                                                )}
                                                            </Div>
                                                        </Div>
                                                        <Div className={'EPCPIBRCol'}>
                                                            <Div className={'EPCPIBRCTitle'}>
                                                                Industry Leaders
                                                            </Div>
                                                            <Div className={'EPCPIBRCNum'}>
                                                                {abbreviateNumber(
                                                                    facebook?.likes
                                                                        ?.industryLeaders,
                                                                )}
                                                            </Div>
                                                        </Div>
                                                    </Div>
                                                </Div>
                                            </Div>
                                        </Div>
                                        <Div className={'EPCRCol25'}>
                                            <Div className={'EPCPageInfo'}>
                                                <Div className={'EPCPIHead'}>
                                                    <Div className={'EPCPIHTitleBox'}>
                                                        <Div className={'EPCPIHTBTitle'}>
                                                            Average Posts Per Month
                                                        </Div>
                                                        <Div className={'EPCPIHTBSTitle'}>
                                                            {abbreviateNumber(
                                                                facebook?.averagePostsPerMonth
                                                                    ?.avgposts,
                                                            )}
                                                        </Div>
                                                    </Div>
                                                    <Div
                                                        className={'EPCPIHIcon'}
                                                        style={{
                                                            backgroundColor: '#4ab8ff',
                                                        }}
                                                    >
                                                        <DCBlogPost />
                                                    </Div>
                                                </Div>
                                                <Div className={'EPCPIBody'}>
                                                    <Div className={'EPCPIBRow'}>
                                                        <Div className={'EPCPIBRCol'}>
                                                            <Div className={'EPCPIBRCTitle'}>
                                                                Industry Average
                                                            </Div>
                                                            <Div className={'EPCPIBRCNum'}>
                                                                {abbreviateNumber(
                                                                    facebook?.averagePostsPerMonth
                                                                        ?.industryAverage,
                                                                )}
                                                            </Div>
                                                        </Div>
                                                        <Div className={'EPCPIBRCol'}>
                                                            <Div className={'EPCPIBRCTitle'}>
                                                                Industry Leaders
                                                            </Div>
                                                            <Div className={'EPCPIBRCNum'}>
                                                                {abbreviateNumber(
                                                                    facebook?.averagePostsPerMonth
                                                                        ?.industryLeaders,
                                                                )}
                                                            </Div>
                                                        </Div>
                                                    </Div>
                                                </Div>
                                            </Div>
                                        </Div>
                                        <Div className={'EPCRCol25'}>
                                            <Div className={'EPCPageInfo'}>
                                                <Div className={'EPCPIHead'}>
                                                    <Div className={'EPCPIHTitleBox'}>
                                                        <Div className={'EPCPIHTBTitle'}>
                                                            Average Likes Per Post
                                                        </Div>
                                                        <Div className={'EPCPIHTBSTitle'}>
                                                            {abbreviateNumber(
                                                                facebook?.averageLikesPerPost
                                                                    ?.averageLikes,
                                                            )}
                                                        </Div>
                                                    </Div>
                                                    <Div
                                                        className={'EPCPIHIcon'}
                                                        style={{
                                                            backgroundColor: '#ff8a56',
                                                        }}
                                                    >
                                                        <DCBlogPost />
                                                    </Div>
                                                </Div>
                                                <Div className={'EPCPIBody'}>
                                                    <Div className={'EPCPIBRow'}>
                                                        <Div className={'EPCPIBRCol'}>
                                                            <Div className={'EPCPIBRCTitle'}>
                                                                Industry Average
                                                            </Div>
                                                            <Div className={'EPCPIBRCNum'}>
                                                                {abbreviateNumber(
                                                                    facebook?.averageLikesPerPost
                                                                        ?.industryAverage,
                                                                )}
                                                            </Div>
                                                        </Div>
                                                        <Div className={'EPCPIBRCol'}>
                                                            <Div className={'EPCPIBRCTitle'}>
                                                                Industry Leaders
                                                            </Div>
                                                            <Div className={'EPCPIBRCNum'}>
                                                                {abbreviateNumber(
                                                                    facebook?.averageLikesPerPost
                                                                        ?.industryLeaders,
                                                                )}
                                                            </Div>
                                                        </Div>
                                                    </Div>
                                                </Div>
                                            </Div>
                                        </Div>
                                        <Div className={'EPCRCol25'}>
                                            <Div className={'EPCPageInfo'}>
                                                <Div className={'EPCPIHead'}>
                                                    <Div className={'EPCPIHTitleBox'}>
                                                        <Div className={'EPCPIHTBTitle'}>
                                                            Average Shares Per Post
                                                        </Div>
                                                        <Div className={'EPCPIHTBSTitle'}>
                                                            {abbreviateNumber(
                                                                facebook?.averageSharesPerPost
                                                                    ?.averageShare,
                                                            )}
                                                        </Div>
                                                    </Div>
                                                    <Div
                                                        className={'EPCPIHIcon'}
                                                        style={{
                                                            backgroundColor: '#fb5080',
                                                        }}
                                                    >
                                                        <DCSocialShare />
                                                    </Div>
                                                </Div>
                                                <Div className={'EPCPIBody'}>
                                                    <Div className={'EPCPIBRow'}>
                                                        <Div className={'EPCPIBRCol'}>
                                                            <Div className={'EPCPIBRCTitle'}>
                                                                Industry Average
                                                            </Div>
                                                            <Div className={'EPCPIBRCNum'}>
                                                                {abbreviateNumber(
                                                                    facebook?.averageSharesPerPost
                                                                        ?.industryAverage,
                                                                )}
                                                            </Div>
                                                        </Div>
                                                        <Div className={'EPCPIBRCol'}>
                                                            <Div className={'EPCPIBRCTitle'}>
                                                                Industry Leaders
                                                            </Div>
                                                            <Div className={'EPCPIBRCNum'}>
                                                                {abbreviateNumber(
                                                                    facebook?.averageSharesPerPost
                                                                        ?.industryLeaders,
                                                                )}
                                                            </Div>
                                                        </Div>
                                                    </Div>
                                                </Div>
                                            </Div>
                                        </Div>
                                    </Div>
                                </React.Fragment>
                            )}

                            {/* Twitter */}
                            {twitter && (
                                <React.Fragment>
                                    <Div className={'EPCInnerHeading'}>
                                        <Div className={'EPCIHLTitle'}>Twitter</Div>
                                        <Div className={'EPCIHRTitle'}>
                                            Your Business’s Information on Twitter
                                        </Div>
                                    </Div>
                                    <Div className={'EPCRow'}>
                                        <Div className={'EPCRCol25'}>
                                            <Div className={'EPCPageInfo'}>
                                                <Div className={'EPCPIHead'}>
                                                    <Div className={'EPCPIHTitleBox'}>
                                                        <Div className={'EPCPIHTBTitle'}>Likes</Div>
                                                        <Div className={'EPCPIHTBSTitle'}>
                                                            {twitter.likes_count}
                                                        </Div>
                                                    </Div>
                                                    <Div
                                                        className={'EPCPIHIcon'}
                                                        style={{
                                                            backgroundColor: '#713BDB',
                                                        }}
                                                    >
                                                        <DCLike />
                                                    </Div>
                                                </Div>
                                                <Div className={'EPCPIBody'}>
                                                    <Div className={'EPCPIBRow'}>
                                                        <Div className={'EPCPIBRCol'}>
                                                            <Div className={'EPCPIBRCTitle'}>
                                                                Industry Average
                                                            </Div>
                                                            <Div className={'EPCPIBRCNum'}>35K</Div>
                                                        </Div>
                                                        <Div className={'EPCPIBRCol'}>
                                                            <Div className={'EPCPIBRCTitle'}>
                                                                Industry Leaders
                                                            </Div>
                                                            <Div className={'EPCPIBRCNum'}>4K</Div>
                                                        </Div>
                                                    </Div>
                                                </Div>
                                            </Div>
                                        </Div>
                                        <Div className={'EPCRCol25'}>
                                            <Div className={'EPCPageInfo'}>
                                                <Div className={'EPCPIHead'}>
                                                    <Div className={'EPCPIHTitleBox'}>
                                                        <Div className={'EPCPIHTBTitle'}>
                                                            Average Posts Per Month
                                                        </Div>
                                                        <Div className={'EPCPIHTBSTitle'}>
                                                            {twitter.average_posts_per_month}
                                                        </Div>
                                                    </Div>
                                                    <Div
                                                        className={'EPCPIHIcon'}
                                                        style={{
                                                            backgroundColor: '#4ab8ff',
                                                        }}
                                                    >
                                                        <DCBlogPost />
                                                    </Div>
                                                </Div>
                                                <Div className={'EPCPIBody'}>
                                                    <Div className={'EPCPIBRow'}>
                                                        <Div className={'EPCPIBRCol'}>
                                                            <Div className={'EPCPIBRCTitle'}>
                                                                Industry Average
                                                            </Div>
                                                            <Div className={'EPCPIBRCNum'}>35K</Div>
                                                        </Div>
                                                        <Div className={'EPCPIBRCol'}>
                                                            <Div className={'EPCPIBRCTitle'}>
                                                                Industry Leaders
                                                            </Div>
                                                            <Div className={'EPCPIBRCNum'}>4K</Div>
                                                        </Div>
                                                    </Div>
                                                </Div>
                                            </Div>
                                        </Div>
                                        <Div className={'EPCRCol25'}>
                                            <Div className={'EPCPageInfo'}>
                                                <Div className={'EPCPIHead'}>
                                                    <Div className={'EPCPIHTitleBox'}>
                                                        <Div className={'EPCPIHTBTitle'}>
                                                            Average Likes Per Post
                                                        </Div>
                                                        <Div className={'EPCPIHTBSTitle'}>
                                                            {twitter.average_likes_per_post}
                                                        </Div>
                                                    </Div>
                                                    <Div
                                                        className={'EPCPIHIcon'}
                                                        style={{
                                                            backgroundColor: '#ff8a56',
                                                        }}
                                                    >
                                                        <DCBlogPost />
                                                    </Div>
                                                </Div>
                                                <Div className={'EPCPIBody'}>
                                                    <Div className={'EPCPIBRow'}>
                                                        <Div className={'EPCPIBRCol'}>
                                                            <Div className={'EPCPIBRCTitle'}>
                                                                Industry Average
                                                            </Div>
                                                            <Div className={'EPCPIBRCNum'}>35K</Div>
                                                        </Div>
                                                        <Div className={'EPCPIBRCol'}>
                                                            <Div className={'EPCPIBRCTitle'}>
                                                                Industry Leaders
                                                            </Div>
                                                            <Div className={'EPCPIBRCNum'}>4K</Div>
                                                        </Div>
                                                    </Div>
                                                </Div>
                                            </Div>
                                        </Div>
                                        <Div className={'EPCRCol25'}>
                                            <Div className={'EPCPageInfo'}>
                                                <Div className={'EPCPIHead'}>
                                                    <Div className={'EPCPIHTitleBox'}>
                                                        <Div className={'EPCPIHTBTitle'}>
                                                            Average Shares Per Post
                                                        </Div>
                                                        <Div className={'EPCPIHTBSTitle'}>
                                                            {twitter.average_shares_per_post}
                                                        </Div>
                                                    </Div>
                                                    <Div
                                                        className={'EPCPIHIcon'}
                                                        style={{
                                                            backgroundColor: '#fb5080',
                                                        }}
                                                    >
                                                        <DCSocialShare />
                                                    </Div>
                                                </Div>
                                                <Div className={'EPCPIBody'}>
                                                    <Div className={'EPCPIBRow'}>
                                                        <Div className={'EPCPIBRCol'}>
                                                            <Div className={'EPCPIBRCTitle'}>
                                                                Industry Average
                                                            </Div>
                                                            <Div className={'EPCPIBRCNum'}>35K</Div>
                                                        </Div>
                                                        <Div className={'EPCPIBRCol'}>
                                                            <Div className={'EPCPIBRCTitle'}>
                                                                Industry Leaders
                                                            </Div>
                                                            <Div className={'EPCPIBRCNum'}>4K</Div>
                                                        </Div>
                                                    </Div>
                                                </Div>
                                            </Div>
                                        </Div>
                                    </Div>
                                </React.Fragment>
                            )}
                        </Fragment>
                    ) : (
                        <NoFound
                            title={'Potential customers can’t find you on Facebook!!'}
                            description={
                                <Fragment>
                                    <p>
                                        We’re having trouble finding your Facebook business page.
                                        Either you don’t have a page setup at all, or the page is
                                        not optimized properly for potential customers to find.
                                    </p>
                                </Fragment>
                            }
                        />
                    )}
                    <Div className={'EPCUserComt'} style={{ marginTop: 15 }}>
                        <Div className={'EPCUCInner'}>
                            <Div className={'EPCUCInfo'}>
                                <Div className={'EPCUCIImg'}>
                                    <Image
                                        src={generateImage(user?.image, user?.name)}
                                        displayName={user?.name}
                                        alt={user?.name}
                                    />
                                </Div>
                                <Div className={'EPCUCICmt'}>
                                    Would you like to create more engaging content on social media
                                    to build awareness and attract more customers for{' '}
                                    {details?.name}? We can help!
                                </Div>
                            </Div>
                            <Div className={'EPCUCBtn'}>
                                <Button buttonType={'BlueFillBtn'} onClick={handleGetInTouchOpen}>
                                    Get in touch
                                </Button>
                            </Div>
                        </Div>
                    </Div>
                </Div>
            </Div>
        </Fragment>
    );
};

export default Social;
