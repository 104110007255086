import React from 'react';

import { SVGIcon } from '../../UIKit/index';

export default function DCLaptopUser(props) {
    return (
        <SVGIcon {...props} viewBox="0 0 512 512">
            <g>
                <path d="M301,166.5c0-24.8-20.1-45-45-45s-45,20.1-45,45c0,24.8,20.1,45,45,45  C280.8,211.5,301,191.4,301,166.5L301,166.5z M256,181.5c-8.3,0-15-6.7-15-15c0-8.3,6.7-15,15-15c8.3,0,15,6.7,15,15l0,0  C271,174.8,264.3,181.5,256,181.5L256,181.5z" />
                <path d="M256,61.6c-57.9,0.1-104.9,47-105,105v30c0,58,47,105,105,105c58,0,105-47,105-105l0,0v-30  C360.8,108.6,313.9,61.6,256,61.6z M211,256.5c26.6-20,63.3-20,90,0C274.3,276.6,237.6,276.6,211,256.5z M330.9,196.5  c0,13.2-3.5,26.1-10.1,37.5c-38.1-30-91.7-30-129.8,0c-6.6-11.4-10.1-24.3-10.1-37.5v-30c0-41.4,33.6-75,75-75s75,33.6,75,75  L330.9,196.5z" />
                <path d="M497,331.5h-15V46.5c0-24.8-20.1-45-45-45H75c-24.8,0-45,20.1-45,45v284.9H15  c-8.3,0-15,6.7-15,15c0,0,0,0,0,0c0,41.4,33.6,75,75,75c0,0,0,0,0,0h362c41.4,0,75-33.6,75-75C512,338.2,505.3,331.5,497,331.5  C497,331.5,497,331.5,497,331.5z M60,46.5c0-8.3,6.7-15,15-15c0,0,0,0,0,0h362c8.3,0,15,6.7,15,15l0,0v284.9H60V46.5z M437,391.4H75  c-19.1,0-36.1-12-42.4-30h446.8C473,379.4,456.1,391.4,437,391.4z" />
            </g>
        </SVGIcon>
    );
}
