import { globalConversationsActions } from '../Actions';

const globalConversationsReducer = (state, action) => {
    switch (action.type) {
        case globalConversationsActions.SET_SOCKET:
            return {
                ...state,
                conversationsSocket: action.value,
            };
        default:
            throw new Error(JSON.stringify(action));
    }
};

export default globalConversationsReducer;
