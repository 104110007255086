import React from 'react';

import { SVGIcon } from '../../UIKit/index';

export default function DCConversations1(props) {
    return (
        <SVGIcon {...props} viewBox="0 0 502.235 325.435">
            <g id="Conversations" transform="translate(-5.005 -94.015)">
                <ellipse
                    id="_Path_"
                    data-name="&lt;Path&gt;"
                    cx="243.81"
                    cy="89.16"
                    rx="243.81"
                    ry="89.16"
                    transform="translate(19.62 241.13)"
                    fill="#f4f5f6"
                />
                <path
                    id="Path_1"
                    data-name="Path 1"
                    d="M360.73,101.77h96.55L438.39,115.2v11.21a20.89,20.89,0,0,1-21,20.73H356.77a20.89,20.89,0,0,1-21-20.73h0a24.83,24.83,0,0,1,24.96-24.64Z"
                    fill="#f4f5f6"
                />
                <path
                    id="Path_2"
                    data-name="Path 2"
                    d="M422.1,116.76c0,.11-16.12.2-36,.2s-36-.09-36-.2,16.12-.2,36-.2S422.1,116.65,422.1,116.76Z"
                    fill="#707272"
                />
                <path
                    id="Path_3"
                    data-name="Path 3"
                    d="M422.1,124.22c0,.11-16.12.2-36,.2s-36-.09-36-.2,16.12-.2,36-.2S422.1,124.11,422.1,124.22Z"
                    fill="#707272"
                />
                <path
                    id="Path_4"
                    data-name="Path 4"
                    d="M214.26,331.56H100L118.62,345v26.42a5.52,5.52,0,0,0,5.52,5.51h90.12a5.52,5.52,0,0,0,5.52-5.51V337.07A5.52,5.52,0,0,0,214.26,331.56Z"
                    fill="#e4e3e3"
                />
                <path
                    id="Path_5"
                    data-name="Path 5"
                    d="M209.49,350.23c0,.11-17.23.2-38.48.2s-38.48-.09-38.48-.2,17.23-.2,38.48-.2S209.49,350.12,209.49,350.23Z"
                    fill="#707272"
                />
                <path
                    id="Path_6"
                    data-name="Path 6"
                    d="M209.49,360.37c0,.11-17.23.2-38.48.2s-38.48-.09-38.48-.2,17.23-.2,38.48-.2S209.49,360.26,209.49,360.37Z"
                    fill="#707272"
                />
                <rect
                    id="Rectangle_1"
                    data-name="Rectangle 1"
                    width="193.11"
                    height="168.23"
                    rx="38.79"
                    transform="translate(284.1 201.35)"
                    fill="#f4f5f6"
                />
                <g id="Group_1" data-name="Group 1" opacity="0.15">
                    <rect
                        id="Rectangle_2"
                        data-name="Rectangle 2"
                        width="193.11"
                        height="168.23"
                        rx="38.79"
                        transform="translate(284.1 201.35)"
                    />
                </g>
                <path
                    id="Path_7"
                    data-name="Path 7"
                    d="M477.17,260h28.92l-29.58,29.58Z"
                    fill="#f4f5f6"
                />
                <g id="Group_2" data-name="Group 2" opacity="0.4">
                    <path
                        id="Path_8"
                        data-name="Path 8"
                        d="M477.17,260h28.92l-29.58,29.58Z"
                        fill="#f4f5f6"
                    />
                </g>
                <path
                    id="Path_9"
                    data-name="Path 9"
                    d="M279.48,278.19l-12.12-13.76c-3.28-3.27-4.4-4.86-3.75-5.43.87-.77.5-.51,7.31,4.6,0,0-9.65-7.38-6.31-7.42.59,0,2.23.94,4,2.1-2.39-2-3.05-3.4-2.6-4.06.78-1.13,6.68,3,9.4,4.94-2.22-1.66-6.46-4.87-7.2-5.88-1-1.37,1.05-1.26,2.73-.69,1.89.65,12.35,6,15.44,7.36a2,2,0,0,0,2.88-1c1.14-4.79,4.58-7.91,5.92-8.32a1.5,1.5,0,0,1,1.39,2.5,18.311,18.311,0,0,0-2,4.55,12,12,0,0,0-.62,3.93c0,1.54.11,2.85.21,4l11.08,15.21-18.53,10.74Z"
                    fill="#e4e3e3"
                />
                <g id="Group_3" data-name="Group 3" opacity="0.5">
                    <path
                        id="Path_10"
                        data-name="Path 10"
                        d="M275.13,265.71c-.13.17-2.28-1.31-4.81-3.31s-4.47-3.75-4.33-3.92,2.29,1.32,4.81,3.31S275.25,265.54,275.13,265.71Z"
                        fill="#707272"
                    />
                </g>
                <g id="Group_4" data-name="Group 4" opacity="0.5">
                    <path
                        id="Path_11"
                        data-name="Path 11"
                        d="M277.49,263.29c-.12.18-2.51-1.27-5.33-3.24s-5-3.69-4.88-3.87,2.51,1.28,5.33,3.24S277.61,263.12,277.49,263.29Z"
                        fill="#707272"
                    />
                </g>
                <g id="Group_5" data-name="Group 5" opacity="0.5">
                    <path
                        id="Path_12"
                        data-name="Path 12"
                        d="M279.2,260.72c-.12.18-2.4-1.16-5.09-3s-4.77-3.44-4.65-3.62,2.4,1.16,5.09,3S279.32,260.54,279.2,260.72Z"
                        fill="#707272"
                    />
                </g>
                <path
                    id="Path_13"
                    data-name="Path 13"
                    d="M295.14,267.7l44.17,56L329.2,347.26s-12.12-2.68-15.79-7.79-33.93-61.28-33.93-61.28Z"
                    fill="#e4e3e3"
                />
                <path
                    id="Path_14"
                    data-name="Path 14"
                    d="M407.27,189.32c3.18,2.75,3.17,7.61,2.8,11.8s-.64,9,2.26,12c2.39,2.51,6.73,3.61,7.29,7a3.94,3.94,0,0,1-6.52,3.58c.94,2.7.29,6.08-2.06,7.72s-6.26.63-7-2.14a3.47,3.47,0,1,1-6.81,1.24"
                    fill="#707272"
                />
                <path
                    id="Path_15"
                    data-name="Path 15"
                    d="M361.76,217.59a4.18,4.18,0,1,1-7.71-3.22c.73-1.6,2.46-2.75,2.62-4.49.2-2.11-1.95-3.62-3.86-4.54s-4.15-2.11-4.36-4.22,1.63-3.63,3.15-5,3-3.47,2.09-5.29c-.48-.93-1.48-1.47-2.13-2.29-1.43-1.82-.68-4.72,1.14-6.15a8.39,8.39,0,0,1,6.64-1.22,62.9,62.9,0,0,1,6.66,2,27.69,27.69,0,0,0,22.08-2.89"
                    fill="#707272"
                />
                <path
                    id="Path_16"
                    data-name="Path 16"
                    d="M375.32,267a15.37,15.37,0,0,0,18.07-11.79l10.86-49.82a22.11,22.11,0,0,0-17.05-26.73l-1.14-.17a22.27,22.27,0,0,0-24.92,17c-2.42,10.52-4.95,22.17-5.72,27.89-1.58,11.84,10,15.41,10,15.41l-2.07,10a15.36,15.36,0,0,0,12,18.19Z"
                    fill="#e4e3e3"
                />
                <path
                    id="Path_17"
                    data-name="Path 17"
                    d="M363.27,203.31a1.7,1.7,0,0,0,1.38,1.94,1.62,1.62,0,0,0,2-1.27,1.72,1.72,0,0,0-1.38-1.94,1.63,1.63,0,0,0-2,1.27Z"
                    fill="#707272"
                />
                <path
                    id="Path_18"
                    data-name="Path 18"
                    d="M362.83,201.66c.18.25,1.6-.51,3.41-.24s3,1.38,3.25,1.18-.05-.51-.56-1a4.8,4.8,0,0,0-2.56-1.22,4.48,4.48,0,0,0-2.76.47C363,201.18,362.74,201.55,362.83,201.66Z"
                    fill="#707272"
                />
                <path
                    id="Path_19"
                    data-name="Path 19"
                    d="M381,206.26a1.7,1.7,0,0,0,1.37,1.94,1.65,1.65,0,0,0,2-1.27A1.7,1.7,0,0,0,383,205a1.62,1.62,0,0,0-2,1.26Z"
                    fill="#707272"
                />
                <path
                    id="Path_20"
                    data-name="Path 20"
                    d="M380.64,204.61c.18.25,1.6-.51,3.41-.23s3,1.37,3.25,1.18-.05-.52-.57-1a4.62,4.62,0,0,0-2.55-1.22,4.55,4.55,0,0,0-2.76.46C380.8,204.13,380.55,204.5,380.64,204.61Z"
                    fill="#707272"
                />
                <path
                    id="Path_21"
                    data-name="Path 21"
                    d="M372.07,217.12a12,12,0,0,0-2.87-1c-.46-.12-.88-.28-.91-.6a2.3,2.3,0,0,1,.51-1.33l1.89-3.34c2.64-4.75,4.62-8.68,4.43-8.78s-2.49,3.66-5.13,8.41l-1.83,3.36a2.63,2.63,0,0,0-.51,1.78,1.16,1.16,0,0,0,.66.79,3.24,3.24,0,0,0,.77.23,12,12,0,0,0,2.99.48Z"
                    fill="#707272"
                />
                <path
                    id="Path_22"
                    data-name="Path 22"
                    d="M381.21,197.43c.11.52,2,.55,4.05,1.12s3.73,1.4,4.08,1c.16-.2-.07-.71-.7-1.29a7.079,7.079,0,0,0-2.91-1.52,7,7,0,0,0-3.28-.09C381.62,196.85,381.17,197.18,381.21,197.43Z"
                    fill="#707272"
                />
                <path
                    id="Path_23"
                    data-name="Path 23"
                    d="M364.27,196.5c.26.46,1.57.21,3.08.41s2.74.69,3.1.3c.16-.19,0-.63-.48-1.11a4.51,4.51,0,0,0-5-.57C364.4,195.88,364.16,196.28,364.27,196.5Z"
                    fill="#707272"
                />
                <path
                    id="Path_24"
                    data-name="Path 24"
                    d="M393.31,190.51l.06.06a9.39,9.39,0,0,1,2.58,6.52v.25c0,3.44-.62,6.85-.62,10.29s.67,7,2.82,9.72c.88,1.11,2.6,2,3.6,1a2.919,2.919,0,0,0,.65-1.49c1.82-8.05,5.1-14.94,6.33-23.46a7.23,7.23,0,0,0-2.12-6.28c-1.59-1.54-3.58-3.18-5.69-2.53s-5.23,4-7,5.33"
                    fill="#707272"
                />
                <path
                    id="Path_25"
                    data-name="Path 25"
                    d="M399.48,210.12c.22-.06,8.48-1.21,7,6.83s-9.22,4.94-9.19,4.71S399.48,210.12,399.48,210.12Z"
                    fill="#e4e3e3"
                />
                <g id="Group_6" data-name="Group 6" opacity="0.5">
                    <path
                        id="Path_26"
                        data-name="Path 26"
                        d="M400.33,219.05a2.57,2.57,0,0,0,.34.27,1.4,1.4,0,0,0,1,.21c.9-.16,1.86-1.38,2.16-2.82a4.84,4.84,0,0,0,0-2.08,1.64,1.64,0,0,0-.86-1.26.73.73,0,0,0-.9.23c-.15.21-.13.38-.17.39s-.15-.17,0-.49a.93.93,0,0,1,.4-.45,1.06,1.06,0,0,1,.81-.06,2,2,0,0,1,1.24,1.51,4.74,4.74,0,0,1,.09,2.32c-.34,1.62-1.45,3-2.67,3.11a1.52,1.52,0,0,1-1.24-.44C400.3,219.25,400.29,219.06,400.33,219.05Z"
                        fill="#707272"
                    />
                </g>
                <path
                    id="Path_27"
                    data-name="Path 27"
                    d="M401,185.92c2.46-2.94-2.31-8.38-6.12-10.23s-9.34-.66-13.5.07c-2.63.47-5.3,1.26-7.92.77a18.48,18.48,0,0,1-4.93-2.06,13.32,13.32,0,0,0-5-1.78,4.93,4.93,0,0,0-4.65,2.08c-.91,1.54-.28,3.92,1.44,4.42a4,4,0,1,0-2.13,7.2c-1-1-3,.14-3.07,1.6s1.08,2.69,2.32,3.46c3.15,2,7.15,2.08,10.81,1.42s7.16-2,10.81-2.78,9.76-2.17,13.06-.43c2.42,1.27,3.75,4.61,5.29,4.14a5,5,0,0,0,3.09-3.26,14.59,14.59,0,0,0,.52-4.62"
                    fill="#707272"
                />
                <path
                    id="Path_28"
                    data-name="Path 28"
                    d="M416.76,223.27a1.19,1.19,0,0,0,.42-.18,2.33,2.33,0,0,0,.87-1,3.49,3.49,0,0,0,.27-2.08,4.2,4.2,0,0,0-1.45-2.36A10.35,10.35,0,0,0,414,216a19.142,19.142,0,0,1-3.39-1.64,6.83,6.83,0,0,1-2.46-3.19,11.23,11.23,0,0,1-.71-4.36c.08-3.06.93-5.93,1.14-8.63a11.24,11.24,0,0,0-1.29-7,6.55,6.55,0,0,0-3.82-2.87,5.05,5.05,0,0,0-1.32-.13,2,2,0,0,0-.45.07,6.709,6.709,0,0,1,1.73.21A6.53,6.53,0,0,1,407,191.3a11,11,0,0,1,1.16,6.8c-.22,2.66-1.08,5.52-1.17,8.66a11.479,11.479,0,0,0,.75,4.51,7.07,7.07,0,0,0,2.62,3.36,18.649,18.649,0,0,0,3.47,1.64,10.26,10.26,0,0,1,2.84,1.59,4,4,0,0,1,1.41,2.19,3.37,3.37,0,0,1-.18,2,2.67,2.67,0,0,1-1.14,1.22Z"
                    fill="#4e4f4f"
                />
                <path
                    id="Path_29"
                    data-name="Path 29"
                    d="M360.05,179a1.511,1.511,0,0,0-.35-.18,1.61,1.61,0,0,0-.45-.18,3,3,0,0,0-.65-.17,4.85,4.85,0,0,0-4.05,1.24h0a6.47,6.47,0,0,0-1.44,4.8,7.94,7.94,0,0,0,2.95,5.45,12.34,12.34,0,0,0,6.7,2.7,27.21,27.21,0,0,0,7.94-.49,35.82,35.82,0,0,0,11.72-4.53,36.187,36.187,0,0,1,4.84-2.51,13.16,13.16,0,0,1,4.6-.94,13.65,13.65,0,0,1,6.6,1.56,25,25,0,0,1,2.13,1.35,9.56,9.56,0,0,0-2.06-1.49,13.48,13.48,0,0,0-6.68-1.71,13.07,13.07,0,0,0-4.71.92,34.39,34.39,0,0,0-4.9,2.5,36.24,36.24,0,0,1-11.61,4.45,27.2,27.2,0,0,1-7.83.49,12,12,0,0,1-6.51-2.57,7.69,7.69,0,0,1-2.88-5.21,6.26,6.26,0,0,1,1.32-4.62h0a4.79,4.79,0,0,1,3.88-1.28,8.33,8.33,0,0,1,1.44.42Z"
                    fill="#4e4f4f"
                />
                <path
                    id="Path_30"
                    data-name="Path 30"
                    d="M364.53,172.32a4.69,4.69,0,0,0,.29,1.81A7.59,7.59,0,0,0,368,178a9.43,9.43,0,0,0,3.21,1.33,13.629,13.629,0,0,0,4,.2,53,53,0,0,0,8.85-2c3.07-.8,6.17-1.21,8.83-.5a11.57,11.57,0,0,1,6,3.89,11.84,11.84,0,0,1,2.3,4.33c.31,1.12.35,1.78.4,1.77a3.314,3.314,0,0,0,0-.47,11,11,0,0,0-.22-1.35,12,12,0,0,0-8.36-8.54c-2.76-.75-5.94-.33-9,.48a56.461,56.461,0,0,1-8.78,2,13.68,13.68,0,0,1-3.87-.16,9.37,9.37,0,0,1-3.13-1.25,7.63,7.63,0,0,1-3.17-3.62A12,12,0,0,1,364.53,172.32Z"
                    fill="#4e4f4f"
                />
                <g id="Group_7" data-name="Group 7" opacity="0.2">
                    <path
                        id="Path_31"
                        data-name="Path 31"
                        d="M368.44,223.14a8.451,8.451,0,0,0,.63,2.29,2.81,2.81,0,0,0,1.71,1.55,2.54,2.54,0,0,0,2.33-.7,4,4,0,0,0,1.08-2.25c.12-.76,0-1.72-.76-2a2,2,0,0,0-.94,0l-4.25.77"
                        fill="#707272"
                    />
                </g>
                <path
                    id="Path_32"
                    data-name="Path 32"
                    d="M369.19,226.36a6.06,6.06,0,0,0,2.11.4,3,3,0,0,0,2-.91,3.2,3.2,0,0,0,.85-2.47,3.454,3.454,0,0,0-.15-.67c-.1-.21-.08-.19-.25-.22a5.273,5.273,0,0,0-.69,0l-.7.06c-.92.06-1.75.12-2.45.15a7.07,7.07,0,0,1-2.27,0,7.05,7.05,0,0,1,2.22-.46c.69-.1,1.52-.2,2.44-.31l.7-.08a7.764,7.764,0,0,1,.79-.06.9.9,0,0,1,.56.2,1.13,1.13,0,0,1,.31.46,3.589,3.589,0,0,1,.2.86,3.8,3.8,0,0,1-1.09,3,3.43,3.43,0,0,1-2.44.94,3.26,3.26,0,0,1-1.66-.46C369.3,226.58,369.16,226.39,369.19,226.36Z"
                    fill="#263238"
                />
                <g id="Group_8" data-name="Group 8" opacity="0.3">
                    <path
                        id="Path_33"
                        data-name="Path 33"
                        d="M365.39,238.8s7.72,2.93,15.23.8c0,0-5.66,4.75-16,2.93Z"
                        fill="#707272"
                    />
                </g>
                <path
                    id="Path_34"
                    data-name="Path 34"
                    d="M377.17,348.59a7.4,7.4,0,0,1-1.06-.8c-.33-.28-.74-.6-1.23-1a12.846,12.846,0,0,0-1.68-1.19c-.68-.4-1.34-.89-2.12-1.32s-1.62-.9-2.52-1.36a39.391,39.391,0,0,0-12.5-3.74c-1-.1-1.95-.22-2.85-.25s-1.73-.06-2.49-.05-1.45,0-2.06.07-1.12.07-1.55.12a8.658,8.658,0,0,1-1.33.09,9.294,9.294,0,0,1,1.31-.25c.43-.07,1-.14,1.56-.18s1.3-.13,2.06-.13h2.52c.91,0,1.86.12,2.88.22a41.182,41.182,0,0,1,6.47,1.33,41.692,41.692,0,0,1,6.15,2.44c.89.48,1.76.92,2.52,1.4s1.48.93,2.1,1.38,1.18.87,1.66,1.24.88.72,1.2,1a9.991,9.991,0,0,1,.96.98Z"
                    fill="#fafbfb"
                />
                <path
                    id="Path_35"
                    data-name="Path 35"
                    d="M332.16,309.26l6.28-27.21c2.4-10.23,5.25-20.06,10-21.23V264l-2.05,77.74s-12.8,14.58-29.13,6.47c-5.1-2.53-14.4-20.33-14.4-20.33s-21.62-38.64-20.73-40.39,14.13-18.8,15.48-19.65Z"
                    fill="#707272"
                />
                <path
                    id="Path_36"
                    data-name="Path 36"
                    d="M396.78,253.15c12.48,2.4,26.54,9.68,33,28.33,2.36,6.81,19.55,57,19.55,57L420,337.92l-4.77-10.36,2.33,16.8,2,24-74,1.24.52-36.11.71-71.83s.83-1.09,13.59-8.13C368.3,249.14,396.78,253.15,396.78,253.15Z"
                    fill="#707272"
                />
                <g id="Group_9" data-name="Group 9" opacity="0.2">
                    <path
                        id="Path_37"
                        data-name="Path 37"
                        d="M404.16,301.17c1.22,15.16,2.62,19.85,3.83,35a13.72,13.72,0,0,0,.8,4.22,4.53,4.53,0,0,0,3.06,2.79c2.16.44,5,2,5.57-.12l-2.05-14.6a28.751,28.751,0,0,0-2-6.17c-4.54-10.08-6.22-10.67-9.19-21.13"
                    />
                </g>
                <path
                    id="Path_38"
                    data-name="Path 38"
                    d="M360.38,261.28l.24-1.57L363,244.58a1.77,1.77,0,0,1,1.89-1.49L394,245.52a3.36,3.36,0,0,1,3.06,3.59l-.65,8.79-9.1,11.87-13.1,2.05"
                    fill="#707272"
                />
                <path
                    id="Path_39"
                    data-name="Path 39"
                    d="M345.75,289.73c.11,0,.2,11.86.2,26.48s-.09,26.47-.2,26.47-.2-11.85-.2-26.47S345.64,289.73,345.75,289.73Z"
                    fill="#263238"
                />
                <path
                    id="Path_40"
                    data-name="Path 40"
                    d="M420.64,340.34a.53.53,0,0,1-.09-.17c-.07-.14-.14-.31-.24-.52l-.89-2-3.2-7.44c-2.67-6.32-6.35-15-10.41-24.59-2-4.81-3.94-9.39-5.69-13.56a26,26,0,0,1-1.88-6.15,34.638,34.638,0,0,1-.51-5.59,22.669,22.669,0,0,1,1.17-8c.09-.23.16-.44.24-.64a5.432,5.432,0,0,1,.27-.54,4,4,0,0,1,.46-.81l.32-.47.12-.15a15.528,15.528,0,0,0-1.26,2.66,23.189,23.189,0,0,0-1,7.95,29.2,29.2,0,0,0,2.44,11.6l5.72,13.54,10.3,24.64,3.08,7.49c.35.86.62,1.54.82,2l.21.54A1.858,1.858,0,0,1,420.64,340.34Z"
                    fill="#263238"
                />
                <path
                    id="Path_41"
                    data-name="Path 41"
                    d="M405.86,255.79a9.485,9.485,0,0,1-.85.74,13,13,0,0,1-2.79,1.63,20.911,20.911,0,0,1-4.92,1.38,58.285,58.285,0,0,1-6.74.66c-2.51.12-5.26.07-8.19.12a38.107,38.107,0,0,0-9.27,1.13,17.11,17.11,0,0,0-8.71,5.14,12,12,0,0,0-2.42,4.78,10.11,10.11,0,0,0,.09,5.48,8.55,8.55,0,0,0,1.28,2.45,6.42,6.42,0,0,0,2.07,1.74,4.43,4.43,0,0,0,2.55.54,3.45,3.45,0,0,0,2.25-1.15,6,6,0,0,0,1.13-4.85,8.169,8.169,0,0,0-.72-2.39,12,12,0,0,0-1.37-2.05,14.45,14.45,0,0,0-7.85-4.66,16.69,16.69,0,0,0-8,.1,22,22,0,0,0-6.16,2.63,27.718,27.718,0,0,0-4.07,3c-1.06.92-1.8,1.71-2.32,2.23l-.57.62-.2.2a25.159,25.159,0,0,1,3-3.17,28.183,28.183,0,0,1,4.06-3.1,22,22,0,0,1,6.21-2.72,17,17,0,0,1,8.18-.14,14.74,14.74,0,0,1,8.06,4.74A12.812,12.812,0,0,1,371,273a8.32,8.32,0,0,1,.77,2.51,7.8,7.8,0,0,1-.05,2.68,5.22,5.22,0,0,1-1.17,2.48A3.85,3.85,0,0,1,368,282a4.85,4.85,0,0,1-2.79-.58,6.86,6.86,0,0,1-2.2-1.85,9,9,0,0,1-1.34-2.57,10.58,10.58,0,0,1-.1-5.69,12.32,12.32,0,0,1,2.51-4.94A17.45,17.45,0,0,1,373,261.1a38.07,38.07,0,0,1,9.36-1.11c2.95,0,5.69,0,8.19-.07a61.252,61.252,0,0,0,6.71-.6,21.647,21.647,0,0,0,4.9-1.3,14.579,14.579,0,0,0,2.8-1.55c.31-.21.52-.4.67-.51Z"
                    fill="#fafbfb"
                />
                <path
                    id="Path_42"
                    data-name="Path 42"
                    d="M431.19,287.67a1.232,1.232,0,0,1-.33.05c-.21,0-.53.06-.94.07a29.55,29.55,0,0,1-3.47-.09c-1.46-.1-3.2-.32-5.11-.59s-4-.55-6.23-.67a24.191,24.191,0,0,0-6.2.47,15.42,15.42,0,0,0-4.73,1.8,13.67,13.67,0,0,0-3.49,3.09,1.089,1.089,0,0,1,.16-.28,6.6,6.6,0,0,1,.56-.77,10.261,10.261,0,0,1,2.62-2.28,15.272,15.272,0,0,1,4.8-1.92,24,24,0,0,1,6.3-.51c2.24.12,4.35.43,6.26.71s3.63.53,5.08.67,2.63.21,3.45.21a7.6,7.6,0,0,1,1.27.04Z"
                    fill="#fafbfb"
                />
                <path
                    id="Path_43"
                    data-name="Path 43"
                    d="M412.78,322a15.359,15.359,0,0,0-4.39-.38,23.631,23.631,0,0,0-5.11.92c-1.95.52-4.13,1.3-6.58,2a27,27,0,0,1-8.26,1.44,23.86,23.86,0,0,1-9.4-1.95,23.42,23.42,0,0,1-8.58-6.24,18.06,18.06,0,0,1-3-4.76,10.92,10.92,0,0,1-.87-5.75,8.33,8.33,0,0,1,2.72-5.13,5.73,5.73,0,0,1,5.42-1.23,5.88,5.88,0,0,1,2.46,1.35,4.62,4.62,0,0,1,1.38,2.38,4.74,4.74,0,0,1-.21,2.69,6.48,6.48,0,0,1-1.43,2.2,11.59,11.59,0,0,1-4.21,2.67,12.113,12.113,0,0,1-2.3.63l-1.15.21-1.14.13a24.62,24.62,0,0,1-8.35-.58,26.468,26.468,0,0,1-6.46-2.48,23.452,23.452,0,0,1-4.29-3l-.76-.67-.63-.63a11.466,11.466,0,0,1-1-1l-.55-.66a1.3,1.3,0,0,1-.17-.23,1.25,1.25,0,0,1,.2.21c.15.15.34.36.58.63a12.378,12.378,0,0,0,1,1l.64.62.77.65a24,24,0,0,0,4.3,2.89,26,26,0,0,0,6.41,2.4,24.441,24.441,0,0,0,8.26.54l1.12-.14c.38-.06.75-.14,1.13-.21a12.171,12.171,0,0,0,2.25-.63,11.07,11.07,0,0,0,4.06-2.6,6.08,6.08,0,0,0,1.35-2.07,4.37,4.37,0,0,0,.19-2.48,4.81,4.81,0,0,0-3.57-3.43,5.37,5.37,0,0,0-5.07,1.16,7.91,7.91,0,0,0-2.58,4.87,10.5,10.5,0,0,0,.85,5.54,17.58,17.58,0,0,0,3,4.67,23.17,23.17,0,0,0,8.42,6.15,23.54,23.54,0,0,0,9.26,2,27,27,0,0,0,8.18-1.38c2.44-.73,4.63-1.49,6.61-2a23.861,23.861,0,0,1,5.15-.85,14.7,14.7,0,0,1,1.91,0c.54.08,1,.12,1.38.21l.83.2a1.091,1.091,0,0,1,.23.12Z"
                    fill="#fafbfb"
                />
                <path
                    id="Path_44"
                    data-name="Path 44"
                    d="M345.49,313.83c-.06,0-.41-.81-1.16-2.06a20,20,0,0,0-8.17-7.62c-1.3-.65-2.16-.94-2.14-1s.23,0,.63.14a13.339,13.339,0,0,1,1.63.62,17.88,17.88,0,0,1,8.3,7.73,13.682,13.682,0,0,1,.73,1.59C345.45,313.6,345.52,313.82,345.49,313.83Z"
                    fill="#fafbfb"
                />
                <path
                    id="Path_45"
                    data-name="Path 45"
                    d="M315.78,346.61a.7.7,0,0,1-.12-.19l-.32-.58c-.27-.51-.67-1.27-1.16-2.25a52.176,52.176,0,0,1-3.51-8.62,28,28,0,0,1-1.09-6.35,20,20,0,0,1,.14-3.6,21.23,21.23,0,0,1,.91-3.72,16.27,16.27,0,0,1,4.49-6.8,11,11,0,0,1,8.18-2.87,6,6,0,0,1,4,2,5,5,0,0,1,1.14,4.19,3.7,3.7,0,0,1-1.09,1.89,4.35,4.35,0,0,1-1.87,1,5.53,5.53,0,0,1-4-.49,9.17,9.17,0,0,1-2.92-2.49,13.19,13.19,0,0,1-1.84-3.11,13.71,13.71,0,0,1-1-6.35,12.86,12.86,0,0,1,1.52-5.09,13.07,13.07,0,0,1,2.4-3.22,12.332,12.332,0,0,1,2-1.59,6,6,0,0,1,.56-.34c.13-.08.2-.11.21-.1l-.73.5a13.589,13.589,0,0,0-1.9,1.64,13.8,13.8,0,0,0-2.32,3.21,12.929,12.929,0,0,0-1.43,5,13.5,13.5,0,0,0,1.05,6.2,13.091,13.091,0,0,0,1.8,3,8.93,8.93,0,0,0,2.82,2.38,5.12,5.12,0,0,0,3.73.44,3.6,3.6,0,0,0,2.68-2.63,4.59,4.59,0,0,0-1.06-3.84,5.65,5.65,0,0,0-3.77-1.82,10.6,10.6,0,0,0-7.87,2.76,15.869,15.869,0,0,0-4.4,6.64,20.2,20.2,0,0,0-.91,3.64,19.211,19.211,0,0,0-.16,3.53,28.122,28.122,0,0,0,1,6.29,55.832,55.832,0,0,0,3.38,8.63l1.09,2.28.28.6a1.781,1.781,0,0,1,.09.23Z"
                    fill="#fafbfb"
                />
                <path
                    id="Path_46"
                    data-name="Path 46"
                    d="M306.32,279a1.217,1.217,0,0,0,.07.32,4.481,4.481,0,0,1,.12.92,20.592,20.592,0,0,1,0,3.44,21.89,21.89,0,0,1-12.34,17.95,19.431,19.431,0,0,1-3.21,1.23c-.39.12-.7.18-.91.23a1,1,0,0,1-.32.05,9.7,9.7,0,0,1,1.18-.42,24.656,24.656,0,0,0,3.13-1.34,22.44,22.44,0,0,0,12.19-17.72,24.408,24.408,0,0,0,.13-3.41A9.846,9.846,0,0,1,306.32,279Z"
                    fill="#fafbfb"
                />
                <path
                    id="Path_47"
                    data-name="Path 47"
                    d="M437.89,312.2a11.15,11.15,0,0,0-3,2.7c-.76,1-1.52,2.26-2.4,3.68a28,28,0,0,1-3.27,4.33,27.68,27.68,0,0,1-7.85,5.9,23.643,23.643,0,0,1-2.74,1.17,5.179,5.179,0,0,1-1.05.3,6.757,6.757,0,0,1,1-.44c.64-.29,1.58-.69,2.67-1.28a29.69,29.69,0,0,0,7.69-5.92,29.381,29.381,0,0,0,3.25-4.26,41.378,41.378,0,0,1,2.49-3.65,10,10,0,0,1,2.2-2,5,5,0,0,1,.71-.39.75.75,0,0,1,.3-.14Z"
                    fill="#fafbfb"
                />
                <path
                    id="Path_48"
                    data-name="Path 48"
                    d="M396.5,254a1.805,1.805,0,0,1-.35.09l-1,.18c-.88.15-2.17.35-3.76.56-3.18.43-7.6.93-12.48,1.29a61.849,61.849,0,0,1-6.92.25,19.62,19.62,0,0,1-5.59-1,12.531,12.531,0,0,1-3.41-1.72,8,8,0,0,1-.8-.66c-.17-.16-.26-.25-.25-.26s.4.3,1.14.79a13.9,13.9,0,0,0,3.41,1.59A20.509,20.509,0,0,0,372,256a66.925,66.925,0,0,0,6.88-.28c4.88-.36,9.29-.81,12.48-1.17l3.77-.43A11.582,11.582,0,0,1,396.5,254Z"
                    fill="#263238"
                />
                <path
                    id="Path_49"
                    data-name="Path 49"
                    d="M449,343.94a20.58,20.58,0,0,1-20,24.46l-58-.64L372.47,349l49.74-7.42"
                    fill="#e4e3e3"
                />
                <path
                    id="Path_50"
                    data-name="Path 50"
                    d="M376.06,349.83a52.367,52.367,0,0,1-6.61-2.8,21.7,21.7,0,0,0-10.19-2l-13.83.7a6.74,6.74,0,0,0,2.32,3.09c1.47.81,10.06,1,10.06,1l-.44.45a9.89,9.89,0,0,1-6.88,3h-1.32a13,13,0,0,1-2.89-.57l-3.41-1.38c-1-.46-2.12-.39-2.57.65s-.11,2.24,2.71,3.66c1.57.79,7.41,2.81,7.41,2.81a13.569,13.569,0,0,0,.34,2.46c.25.85,2.55,1.7,2.55,1.7a29.623,29.623,0,0,0,2,3.65,21.088,21.088,0,0,0,4.55,1.57,19.58,19.58,0,0,0,10.89-.08l5.27-1.58V350.18"
                    fill="#e4e3e3"
                />
                <path
                    id="Path_51"
                    data-name="Path 51"
                    d="M357.15,360.32a11.43,11.43,0,0,1-3.6-.51,10.869,10.869,0,0,1-3.38-1.33c0-.09,1.55.49,3.47,1S357.16,360.22,357.15,360.32Z"
                    fill="#e4e3e3"
                />
                <path
                    id="Path_52"
                    data-name="Path 52"
                    d="M356.66,363.56a5,5,0,0,1-3.69-1,14.565,14.565,0,0,1,1.84.53,12.875,12.875,0,0,1,1.85.47Z"
                    fill="#e4e3e3"
                />
                <path
                    id="Path_53"
                    data-name="Path 53"
                    d="M359.19,348.59a6.38,6.38,0,0,1-1.07,1.14,17.32,17.32,0,0,1-3,2.28,12,12,0,0,1-3.53,1.36,4.8,4.8,0,0,1-1.56.17,19.789,19.789,0,0,0,4.91-1.83,23.348,23.348,0,0,0,3-2.16,7.881,7.881,0,0,1,1.25-.96Z"
                    fill="#aa6551"
                />
                <path
                    id="Path_54"
                    data-name="Path 54"
                    d="M377.47,347.3l-1.41,21.24H430.7a20.449,20.449,0,0,0,10.529-2.919h0a20.43,20.43,0,0,0,9.53-21.46,63.52,63.52,0,0,0-3.52-11.78l-27.48,4.52.88,3.42Z"
                    fill="#707272"
                />
                <path
                    id="Path_55"
                    data-name="Path 55"
                    d="M433.3,338.57l-.55.12-1.6.29-5.88,1-19.42,3.09-19.44,3-5.9.86-1.6.21a4.761,4.761,0,0,1-.57,0,2.407,2.407,0,0,1,.55-.13l1.6-.29,5.88-1,19.42-3.09,19.44-3,5.9-.86,1.6-.21a2.229,2.229,0,0,1,.57.01Z"
                    fill="#263238"
                />
                <path
                    id="Path_56"
                    data-name="Path 56"
                    d="M405.34,368.58c-.09,0,.33-1.54.38-4a16.216,16.216,0,0,0-.08-2,20.7,20.7,0,0,0-.33-2.31,22.1,22.1,0,0,0-1.78-5,23.25,23.25,0,0,0-3-4.44l-.8-.9c-.27-.29-.55-.54-.81-.8a19.518,19.518,0,0,0-1.53-1.35c-1.95-1.57-3.34-2.3-3.3-2.37a4.931,4.931,0,0,1,1,.49,10.884,10.884,0,0,1,1.1.67,17,17,0,0,1,1.39,1,17.731,17.731,0,0,1,1.58,1.33c.27.26.57.51.84.8l.83.9a22.911,22.911,0,0,1,3.07,4.5,21.77,21.77,0,0,1,1.78,5.15,19.052,19.052,0,0,1,.3,2.36,14.513,14.513,0,0,1,0,2.07,13.609,13.609,0,0,1-.15,1.7,8.358,8.358,0,0,1-.23,1.27A5.16,5.16,0,0,1,405.34,368.58Z"
                    fill="#fafbfb"
                />
                <path
                    id="Path_57"
                    data-name="Path 57"
                    d="M438.6,367a11.107,11.107,0,0,1-2.54-.31,12.6,12.6,0,0,1-2.83-1A11,11,0,0,1,430,363.3a10,10,0,0,1-2.35-4.22,11.554,11.554,0,0,1-.41-2.67,10.34,10.34,0,0,1,.23-2.86,8.6,8.6,0,0,1,1.13-2.81,6.37,6.37,0,0,1,2.3-2.19,5.57,5.57,0,0,1,3.2-.73,4.28,4.28,0,0,1,3,1.55,4.2,4.2,0,0,1,1,3.23,3.89,3.89,0,0,1-1.88,2.69,9,9,0,0,1-6,.82,12.59,12.59,0,0,1-10.65-12.06,8.75,8.75,0,0,1,.22-2.55,19.663,19.663,0,0,0-.07,2.54,13,13,0,0,0,2.18,6.45,12.53,12.53,0,0,0,8.38,5.26,8.63,8.63,0,0,0,5.77-.8,3.41,3.41,0,0,0,1.69-2.41,3.83,3.83,0,0,0-.92-2.92,3.92,3.92,0,0,0-2.72-1.41,5.35,5.35,0,0,0-3,.68,7,7,0,0,0-3.25,4.74,10,10,0,0,0-.24,2.77A11.1,11.1,0,0,0,428,359a10,10,0,0,0,2.23,4.11,11.26,11.26,0,0,0,3.09,2.37,13.29,13.29,0,0,0,2.77,1C437.69,366.91,438.6,366.92,438.6,367Z"
                    fill="#fafbfb"
                />
                <path
                    id="Path_58"
                    data-name="Path 58"
                    d="M221.85,135.55H85A30.15,30.15,0,0,0,54.86,165.7v48.77H25.33L54.86,244v33.15A30.15,30.15,0,0,0,85,307.3H221.85A30.15,30.15,0,0,0,252,277.15V165.7a30.15,30.15,0,0,0-30.15-30.15Z"
                    fill="#f4f5f6"
                />
                <path
                    id="Path_59"
                    data-name="Path 59"
                    d="M221.86,135.56H85a30.16,30.16,0,0,0-30.16,30.16v48.76H25.34L54.86,244v33.15A30.15,30.15,0,0,0,85,307.31H221.86A30.16,30.16,0,0,0,252,277.16V165.72a30.16,30.16,0,0,0-30.14-30.16Z"
                    opacity="0.15"
                />
                <path
                    id="Path_60"
                    data-name="Path 60"
                    d="M166,140.7c-.86,2.24.62,4.69,2.29,6.42s3.68,3.37,4.21,5.71c.44,2-.29,4-.11,6s1.28,3.9,1.91,5.86c1.14,3.55.68,7.44-.33,11a20.43,20.43,0,0,1-4.11,8.12,10.82,10.82,0,0,1-8,3.85,3.19,3.19,0,0,1-2.37-.86,3.76,3.76,0,0,1-.68-2.11,205.121,205.121,0,0,1,0-46.4"
                    fill="#707272"
                />
                <path
                    id="Path_61"
                    data-name="Path 61"
                    d="M164.34,190.4c6.66-.29,12.81,6.09,12.28,12.73A14.2,14.2,0,0,1,186.82,219c-.44,2.25-1.44,4.4-1.58,6.68-.15,2.5.74,4.92,1.22,7.38a23.25,23.25,0,0,1-1.33,13.29c-1.06,2.53-3,5.15-5.76,5.23a5.65,5.65,0,0,1-4.62-2.7,14.53,14.53,0,0,1-1.86-5.24c-1.18-6-1.54-12.13-3.18-18S164.88,214,159.56,211c-2.93-1.65-6.46-2.43-8.79-4.87a9.55,9.55,0,0,1-1.72-9.88,12.55,12.55,0,0,1,7.51-7"
                    fill="#707272"
                />
                <path
                    id="Path_62"
                    data-name="Path 62"
                    d="M118.22,146.19c-.08-2.85,2.55-5,5.2-6.09s5.6-1.43,8-3c2.09-1.41,3.5-3.61,5.27-5.4a17.53,17.53,0,0,1,29.48,8c1,4.05.32,8.85-2.88,11.54-3.71,3.13-9.2,2.43-14,1.46s-10.13-1.93-14.09.86c-2.4,1.68-3.77,4.42-5.23,6.95s-3.3,5.12-6.07,6.08-6.52-.7-6.63-3.62"
                    fill="#707272"
                />
                <path
                    id="Path_63"
                    data-name="Path 63"
                    d="M129.44,146.78a4.76,4.76,0,0,0-4.25,4.84h0L128,216c.11,7.59,11.22,11.86,19.35,11.67h0c8.22-.19,8.48-4.22,8.59-11.44.17-12.08.15-16.61.16-16.54s10.37-1.75,12.49-15.3c1.06-6.74.7-17.78.11-26.8-.53-8.13-5.94-18.08-14-17.21Z"
                    fill="#e4e3e3"
                />
                <path
                    id="Path_64"
                    data-name="Path 64"
                    d="M166.4,166a1.74,1.74,0,0,1-1.67,1.76,1.66,1.66,0,0,1-1.79-1.57,1.73,1.73,0,0,1,1.67-1.75A1.65,1.65,0,0,1,166.4,166Z"
                    fill="#263238"
                />
                <path
                    id="Path_65"
                    data-name="Path 65"
                    d="M166.36,163.29c-.22.22-1.54-.75-3.41-.74s-3.23.95-3.44.72.12-.51.72-1a4.8,4.8,0,0,1,2.75-.85,4.52,4.52,0,0,1,2.71.87C166.27,162.78,166.46,163.18,166.36,163.29Z"
                    fill="#263238"
                />
                <path
                    id="Path_66"
                    data-name="Path 66"
                    d="M148.44,166a1.74,1.74,0,0,1-1.67,1.76,1.66,1.66,0,0,1-1.79-1.57,1.73,1.73,0,0,1,1.67-1.75,1.64,1.64,0,0,1,1.79,1.56Z"
                    fill="#263238"
                />
                <path
                    id="Path_67"
                    data-name="Path 67"
                    d="M148,163.68c-.21.22-1.53-.75-3.4-.74s-3.23.95-3.44.72.12-.51.72-.95a4.8,4.8,0,0,1,2.75-.85,4.56,4.56,0,0,1,2.71.87C147.89,163.16,148.09,163.57,148,163.68Z"
                    fill="#263238"
                />
                <path
                    id="Path_68"
                    data-name="Path 68"
                    d="M154.8,177.52a12.55,12.55,0,0,1,3-.55c.48-.05.93-.15,1-.47a2.48,2.48,0,0,0-.32-1.42l-1.42-3.63c-2-5.17-3.4-9.42-3.19-9.5s2,4.05,4,9.22c.47,1.28.92,2.49,1.36,3.65a2.81,2.81,0,0,1,.25,1.88,1.25,1.25,0,0,1-.78.7,3.259,3.259,0,0,1-.82.11,11.651,11.651,0,0,1-3.08.01Z"
                    fill="#263238"
                />
                <g id="Group_10" data-name="Group 10" opacity="0.5">
                    <path
                        id="Path_69"
                        data-name="Path 69"
                        d="M156.14,199.64a34.54,34.54,0,0,1-18.15-4.8s4.42,9.29,18,8Z"
                        fill="#707272"
                    />
                </g>
                <g id="Group_11" data-name="Group 11" opacity="0.5">
                    <path
                        id="Path_70"
                        data-name="Path 70"
                        d="M154,182a3.37,3.37,0,0,0-3-1.25,3.11,3.11,0,0,0-2.13,1.15,2,2,0,0,0-.14,2.23,2.28,2.28,0,0,0,2.45.61,7.31,7.31,0,0,0,2.39-1.43,1.89,1.89,0,0,0,.53-.56.62.62,0,0,0,0-.69"
                        fill="#707272"
                    />
                </g>
                <path
                    id="Path_71"
                    data-name="Path 71"
                    d="M149.3,178.83c.3,0,.31,2,2,3.44s3.9,1.21,3.92,1.49c0,.13-.48.4-1.4.43a5.1,5.1,0,0,1-3.26-1.13,4.36,4.36,0,0,1-1.59-2.89C148.94,179.32,149.16,178.82,149.3,178.83Z"
                    fill="#263238"
                />
                <path
                    id="Path_72"
                    data-name="Path 72"
                    d="M148.82,160.4c-.19.51-2,.27-4.24.54s-4,.87-4.26.42c-.13-.22.18-.7.89-1.19a7.17,7.17,0,0,1,3.15-1.11,7.08,7.08,0,0,1,3.32.38C148.49,159.76,148.9,160.16,148.82,160.4Z"
                    fill="#263238"
                />
                <path
                    id="Path_73"
                    data-name="Path 73"
                    d="M165.66,157.88c-.33.42-1.62,0-3.17,0s-2.86.29-3.16-.15c-.13-.22.07-.64.64-1a4.58,4.58,0,0,1,5.1.15C165.62,157.23,165.8,157.67,165.66,157.88Z"
                    fill="#263238"
                />
                <path
                    id="Path_74"
                    data-name="Path 74"
                    d="M120,191.33a12,12,0,0,0,14.3-13.3c-.42-3.32-2.21-6.71-1.06-9.85,1.53-4.17,7.36-5.45,8.91-9.6.9-2.42.09-5.24,1.11-7.61,1-2.22,3.3-3.5,5.58-4.32s4.72-1.4,6.63-2.88,3.11-4.3,1.88-6.38a128.362,128.362,0,0,0-29.6,7.19c-2.21.82-4.53,1.82-5.84,3.78s-1.31,4.77-1.2,7.26c.57,12.16-1.28,23.55-.71,35.71"
                    fill="#707272"
                />
                <path
                    id="Path_75"
                    data-name="Path 75"
                    d="M116.76,187.52l-.22-.08a2.57,2.57,0,0,1-.62-.3,4.52,4.52,0,0,1-1.69-2,6.71,6.71,0,0,1-.44-4.12,13.37,13.37,0,0,1,2.46-4.92,36.181,36.181,0,0,0,3.72-5.46,8.63,8.63,0,0,0,.9-3.49,11.69,11.69,0,0,0-.8-3.77,11.55,11.55,0,0,1-.8-4.1,9.09,9.09,0,0,1,4.4-7.39,10.52,10.52,0,0,1,4.46-1.27,19.528,19.528,0,0,0,4.47-.78,5.85,5.85,0,0,0,3.14-2.83,22.408,22.408,0,0,0,1.49-4,21.38,21.38,0,0,1,1.52-3.86,14.52,14.52,0,0,1,5.08-5.71,11,11,0,0,1,6.43-1.67,8.27,8.27,0,0,1,5.06,2.08,8,8,0,0,1,2.26,3.47,6.57,6.57,0,0,1,.32,2.61,4.6,4.6,0,0,1-.16.9,7.729,7.729,0,0,1,.08-.91,6.93,6.93,0,0,0-.38-2.55,7.95,7.95,0,0,0-7.2-5.32,10.73,10.73,0,0,0-6.23,1.67,14.08,14.08,0,0,0-4.93,5.6,21,21,0,0,0-1.49,3.81,21.75,21.75,0,0,1-1.51,4.06,6.66,6.66,0,0,1-1.37,1.81,6,6,0,0,1-2,1.21,19.13,19.13,0,0,1-4.56.8,10.1,10.1,0,0,0-4.31,1.22,8.75,8.75,0,0,0-4.22,7.07,11.11,11.11,0,0,0,.77,4,11.74,11.74,0,0,1,.8,3.89,9,9,0,0,1-1,3.63,35.589,35.589,0,0,1-3.79,5.49,12.88,12.88,0,0,0-2.47,4.8,6.62,6.62,0,0,0,.36,4,4.52,4.52,0,0,0,1.6,2C116.46,187.41,116.77,187.49,116.76,187.52Z"
                    fill="#4e4f4f"
                />
                <path
                    id="Path_76"
                    data-name="Path 76"
                    d="M156.12,128.47a1.431,1.431,0,0,1,.53.15,6.15,6.15,0,0,1,1.28.78,6.57,6.57,0,0,1,1.33,9,5.48,5.48,0,0,1-1,1.11c-.28.23-.45.32-.46.3a16.712,16.712,0,0,0,1.22-1.57,6.62,6.62,0,0,0-1.28-8.6A14.92,14.92,0,0,0,156.12,128.47Z"
                    fill="#4e4f4f"
                />
                <path
                    id="Path_77"
                    data-name="Path 77"
                    d="M186.12,221.41a9.622,9.622,0,0,0,.39-1.44,6.66,6.66,0,0,0-.66-3.9,9,9,0,0,0-4.28-4,17.189,17.189,0,0,0-3.33-1.18c-1.19-.31-2.46-.57-3.72-1a8.41,8.41,0,0,1-3.42-1.87,5.7,5.7,0,0,1-1.62-3.25c-.21-1.13-.27-2.22-.53-3.18a6.38,6.38,0,0,0-1.23-2.44,7,7,0,0,0-3.28-2.23c-.91-.27-1.45-.27-1.45-.31s.13,0,.39,0a6.271,6.271,0,0,1,1.11.17,7,7,0,0,1,3.45,2.2,6.54,6.54,0,0,1,1.33,2.54c.28,1,.36,2.09.57,3.19a5.31,5.31,0,0,0,1.53,3,8,8,0,0,0,3.27,1.77c1.24.4,2.5.66,3.7,1a16.79,16.79,0,0,1,3.39,1.23A9.15,9.15,0,0,1,186.1,216a6.58,6.58,0,0,1,.56,4.05,5.421,5.421,0,0,1-.34,1.07,1.833,1.833,0,0,1-.2.29Z"
                    fill="#4e4f4f"
                />
                <path
                    id="Path_78"
                    data-name="Path 78"
                    d="M127.22,197.42c-6.86-1.83-12.79-8.55-15.19-15.23s-2.41-14-1.79-21.06c.44-5.13,1.34-10.55,4.75-14.41,5.13-5.82,10.68-8.79,18.44-8.55"
                    fill="#707272"
                />
                <path
                    id="Path_79"
                    data-name="Path 79"
                    d="M167.27,212.36l-6.4-1.61a3.63,3.63,0,0,0,1.27-4.46,5.84,5.84,0,0,0-4.88-3.62l-29.17-3.29a2.33,2.33,0,0,0-2.44,1.44c-1.3,3.28-1.34,6-.14,8.11h-.14l-5.61,1.12-9.5,84,62.39-2.92Z"
                    fill="#f4f5f6"
                />
                <path
                    id="Path_80"
                    data-name="Path 80"
                    d="M158.09,211.07a6,6,0,0,1-1.17.23c-.76.12-1.86.26-3.22.39a75.5,75.5,0,0,1-21.36-1.06c-1.35-.27-2.43-.51-3.17-.71a7,7,0,0,1-1.14-.34,6.241,6.241,0,0,1,1.17.19c.75.15,1.84.35,3.19.58A91.6,91.6,0,0,0,143,211.52a90.607,90.607,0,0,0,10.67-.11c1.37-.1,2.47-.19,3.23-.27A8.072,8.072,0,0,1,158.09,211.07Z"
                    fill="#707272"
                />
                <path
                    id="Path_81"
                    data-name="Path 81"
                    d="M129.89,224.43a22.228,22.228,0,0,1,2.18-3.63,22.5,22.5,0,0,1,2.52-3.41A23.293,23.293,0,0,1,132.4,221a22.709,22.709,0,0,1-2.51,3.43Z"
                    fill="#e4e3e3"
                />
                <path
                    id="Path_82"
                    data-name="Path 82"
                    d="M150,225.85a21.24,21.24,0,0,1-2.77-3.13,20.819,20.819,0,0,1-2.46-3.38,22.6,22.6,0,0,1,2.77,3.13A22.13,22.13,0,0,1,150,225.85Z"
                    fill="#e4e3e3"
                />
                <path
                    id="Path_83"
                    data-name="Path 83"
                    d="M140.66,236.53a22.829,22.829,0,0,1-.94-4.18,22.22,22.22,0,0,1-.55-4.25,23.861,23.861,0,0,1,.94,4.18,23.231,23.231,0,0,1,.55,4.25Z"
                    fill="#e4e3e3"
                />
                <path
                    id="Path_84"
                    data-name="Path 84"
                    d="M156,237.19a33.1,33.1,0,0,1-3.54,3.77,33.523,33.523,0,0,1-3.82,3.49,33.1,33.1,0,0,1,3.54-3.77A34.884,34.884,0,0,1,156,237.19Z"
                    fill="#e4e3e3"
                />
                <path
                    id="Path_85"
                    data-name="Path 85"
                    d="M135.42,251.51a17.64,17.64,0,0,1-6.64-7c.1-.07,1.28,1.77,3.12,3.71S135.49,251.42,135.42,251.51Z"
                    fill="#e4e3e3"
                />
                <path
                    id="Path_86"
                    data-name="Path 86"
                    d="M142,262.57c0,.12-1.74.68-3.76,1.75s-3.5,2.22-3.58,2.12a11.24,11.24,0,0,1,3.39-2.47A11,11,0,0,1,142,262.57Z"
                    fill="#e4e3e3"
                />
                <path
                    id="Path_87"
                    data-name="Path 87"
                    d="M152.8,263.29a8.249,8.249,0,0,1-1.22-1.31c-.71-.85-1.65-2.05-2.63-3.42s-1.81-2.65-2.38-3.6a7.39,7.39,0,0,1-.84-1.57,9.768,9.768,0,0,1,1.08,1.42l2.47,3.52,2.52,3.47a8.6,8.6,0,0,1,1,1.49Z"
                    fill="#e4e3e3"
                />
                <path
                    id="Path_88"
                    data-name="Path 88"
                    d="M153.87,272.72a22.288,22.288,0,0,1-4.61,1.07,22.749,22.749,0,0,1-4.7.47,34.814,34.814,0,0,1,4.64-.86,39.248,39.248,0,0,1,4.67-.68Z"
                    fill="#e4e3e3"
                />
                <path
                    id="Path_89"
                    data-name="Path 89"
                    d="M136.45,209.89a6.792,6.792,0,0,0,.63-1.28,6.69,6.69,0,0,0,.33-3.32,13.91,13.91,0,0,0-1-3.27,6,6,0,0,1-.52-1.35,4.82,4.82,0,0,1,.77,1.23,10.57,10.57,0,0,1,1.13,3.33,6.18,6.18,0,0,1-.47,3.5,2.57,2.57,0,0,1-.87,1.16Z"
                    fill="#e4e3e3"
                />
                <path
                    id="Path_90"
                    data-name="Path 90"
                    d="M148.11,203.59a6.94,6.94,0,0,1,1.23,5.27c-.12,0-.13-1.22-.47-2.67S148,203.65,148.11,203.59Z"
                    fill="#e4e3e3"
                />
                <path
                    id="Path_91"
                    data-name="Path 91"
                    d="M200.68,263.47l29-51,3.49-8.84s9.05-19.46,9.68-21.44c.73-2.29,2-2.49,2.84-2.16,1.28.51,1.69,2.43,1.13,4.41-.2.68-3.72,11.37-3.72,11.37a27.245,27.245,0,0,1,3-2.23c2.22-1.5,6.31-1.45,10.09-.1s4.72,2.05,4.26,3.55a2,2,0,0,1-1.21,1.2,3.94,3.94,0,0,1-2.79.06c-6.09-2.07-8.78.67-8.78.67l-.46,1.15s4.57-1.46,7.17.61a3.61,3.61,0,0,1,1,1.11,5,5,0,0,1,1.86,8.46,2.1,2.1,0,0,1-2.31.59,2.68,2.68,0,0,1-.87-1,3.93,3.93,0,0,0-.41-.57l-1.74,3.06s-4.26,6.67-5.79,9.78l-20,53.24a14.11,14.11,0,0,1-18.39,8.16h0a14.1,14.1,0,0,1-7.05-20.08Z"
                    fill="#e4e3e3"
                />
                <path
                    id="Path_92"
                    data-name="Path 92"
                    d="M231.7,212.32a3,3,0,0,0,3.37-.59c.92-.8,1.55-1.89,2.41-2.75,2.59-2.59,6.91-2.83,9.37-5.53.35-.38.65-.95.35-1.37s-.85-.33-1.31-.24L234,204.19"
                    fill="#e4e3e3"
                />
                <path
                    id="Path_93"
                    data-name="Path 93"
                    d="M234,204.19a5.051,5.051,0,0,1,1.26-.39c.82-.2,2-.47,3.48-.8l5.18-1.1,1.5-.31a2.46,2.46,0,0,1,1.82.06,1,1,0,0,1,.38,1.08,2.12,2.12,0,0,1-.46.88,7.921,7.921,0,0,1-1.34,1.18c-2,1.37-4.14,1.93-5.87,2.83a9.26,9.26,0,0,0-2.26,1.54c-.61.59-1.09,1.27-1.6,1.84a4.23,4.23,0,0,1-3.14,1.77,1.6,1.6,0,0,1-1.26-.45,3.81,3.81,0,0,0,1.25.18,4.14,4.14,0,0,0,2.78-1.81,24.849,24.849,0,0,1,1.57-1.93,9.371,9.371,0,0,1,2.36-1.7c1.79-1,4-1.56,5.77-2.84a7.371,7.371,0,0,0,1.22-1.06c.33-.38.44-.83.2-.95a2.745,2.745,0,0,0-1.29,0l-1.5.29-5.21.95c-1.49.26-2.69.46-3.53.59A4.759,4.759,0,0,1,234,204.19Z"
                    fill="#707272"
                />
                <path
                    id="Path_94"
                    data-name="Path 94"
                    d="M247.09,200.14a5.11,5.11,0,0,1,1.36-1.83,3.85,3.85,0,0,1,2.3-.76,10.07,10.07,0,0,1,3,.51,16.111,16.111,0,0,0,3.11.71,5.13,5.13,0,0,0,2.61-.4,2,2,0,0,0,1.11-1.39,1.11,1.11,0,0,0,0-.66,1.852,1.852,0,0,1-.15.61,1.88,1.88,0,0,1-1.08,1.15,5,5,0,0,1-2.44.28,16.879,16.879,0,0,1-3-.73,10,10,0,0,0-3.17-.49,4.08,4.08,0,0,0-2.47.92,3.6,3.6,0,0,0-1,1.45,1.38,1.38,0,0,0-.18.63Z"
                    fill="#707272"
                />
                <path
                    id="Path_95"
                    data-name="Path 95"
                    d="M247.05,183.69a13.181,13.181,0,0,0-.71,2.12c-.45,1.47-1,3.21-1.59,5.14s-1.19,3.67-1.66,5.12a12.742,12.742,0,0,0-.64,2.15,9.539,9.539,0,0,0,.94-2c.51-1.28,1.15-3.08,1.79-5.09s1.14-3.85,1.46-5.2a9.44,9.44,0,0,0,.41-2.24Z"
                    fill="#707272"
                />
                <path
                    id="Path_96"
                    data-name="Path 96"
                    d="M248.74,203.33h.69a2.08,2.08,0,0,1,1.68.45,1.13,1.13,0,0,1,.27,1.07,6.091,6.091,0,0,1-.55,1.35,2.47,2.47,0,0,0-.28,1.76,2.16,2.16,0,0,0,1.36,1.37,2.42,2.42,0,0,0,1.91-.19,3.1,3.1,0,0,0,1.27-1.24,10.16,10.16,0,0,0,.83-2.75,5.73,5.73,0,0,0,0-1.94,1.27,1.27,0,0,0-.27-.66,5.337,5.337,0,0,1,.1.68,6.7,6.7,0,0,1-.16,1.86,10.68,10.68,0,0,1-.89,2.62,2.42,2.42,0,0,1-2.67,1.2,1.73,1.73,0,0,1-1.08-1.08,2.1,2.1,0,0,1,.23-1.46,5.731,5.731,0,0,0,.54-1.46,1.44,1.44,0,0,0-.42-1.36,2.14,2.14,0,0,0-1.91-.39A1.78,1.78,0,0,0,248.74,203.33Z"
                    fill="#707272"
                />
                <path
                    id="Path_97"
                    data-name="Path 97"
                    d="M253.75,208.72a.96.96,0,0,0-.14.62,2,2,0,0,0,.89,1.5,2.64,2.64,0,0,0,2.58,0,4.3,4.3,0,0,0,1.95-2.49,5.13,5.13,0,0,0,0-3.15,5,5,0,0,0-1.42-2.15,4.82,4.82,0,0,0-1.48-.89,1.5,1.5,0,0,0-.62-.13,8,8,0,0,1,1.88,1.25,4.89,4.89,0,0,1,1.25,2,5,5,0,0,1,0,2.9,3.87,3.87,0,0,1-1.72,2.25,2.29,2.29,0,0,1-2.22.07,1.88,1.88,0,0,1-.88-1.25A2.588,2.588,0,0,1,253.75,208.72Z"
                    fill="#707272"
                />
                <path
                    id="Path_98"
                    data-name="Path 98"
                    d="M244.08,213.51a1.92,1.92,0,0,0-1-.31,4.21,4.21,0,0,0-2.66.64l.32.22a11.418,11.418,0,0,0,.22-1.51,9.65,9.65,0,0,0-.77-5.18,24.678,24.678,0,0,1,.33,5.14,12.258,12.258,0,0,1-.2,1.46l-.09.5.42-.28a4.43,4.43,0,0,1,2.42-.72C243.68,213.46,244.06,213.57,244.08,213.51Z"
                    fill="#707272"
                />
                <path
                    id="Path_99"
                    data-name="Path 99"
                    d="M250.12,195.35c-.12,0,0,.92.86,1.62s1.72.76,1.72.64-.73-.35-1.43-1S250.24,195.31,250.12,195.35Z"
                    fill="#707272"
                />
                <path
                    id="Path_100"
                    data-name="Path 100"
                    d="M159.49,305.77l30.36.3-5.11-22,1.71,4.15a20.91,20.91,0,0,0,19.36,13h0c9.6,0,16.23-7.14,20.29-15.84,9.5-20.34,18.66-53.09,18.66-53.09L226.24,216c-13.4,25.35-26,47.36-26,47.36s-3.64-11-6.44-20.91-2.8-15-7.11-20c-6.63-7.56-16.09-9.45-24.87-11.71l-.33,40.93-2,54.09"
                    fill="#707272"
                />
                <path
                    id="Path_101"
                    data-name="Path 101"
                    d="M124.76,275.29l55.29,10.77,2.7,21.24H98a16.7,16.7,0,0,1-16.87-18c1.22-15.48,2.54-34.55,3.55-42.61a55.23,55.23,0,0,1,6.26-18.39c4.28-8.05,18.54-18.4,31.05-18.91L124.33,262"
                    fill="#707272"
                />
                <g id="Group_12" data-name="Group 12" opacity="0.2">
                    <path
                        id="Path_102"
                        data-name="Path 102"
                        d="M159.49,305.77l30.36.3-5.11-22,1.71,4.15a20.91,20.91,0,0,0,19.36,13h0c9.6,0,16.23-7.14,20.29-15.84,9.5-20.34,18.66-53.09,18.66-53.09L226.24,216c-13.4,25.35-26,47.36-26,47.36s-3.64-11-6.44-20.91-2.8-15-7.11-20c-6.63-7.56-16.09-9.45-24.87-11.71l-.33,40.93-2,54.09"
                    />
                    <path
                        id="Path_103"
                        data-name="Path 103"
                        d="M124.76,275.29l49.87,9.15a5.21,5.21,0,0,1,4.16,4.05l4,18.81H98a16.7,16.7,0,0,1-16.87-18c1.22-15.48,2.54-34.55,3.55-42.61a55.23,55.23,0,0,1,6.26-18.39c4.28-8.05,18.54-18.4,31.05-18.91L124.33,262"
                    />
                </g>
                <path
                    id="Path_104"
                    data-name="Path 104"
                    d="M114.24,273.75a2.14,2.14,0,0,1,0-.5c0-.38,0-.86,0-1.45v-5.33c0-4.5-.08-10.71-.43-17.56-.17-3.43-.4-6.69-.63-9.65a40.05,40.05,0,0,0-1.12-7.78,11,11,0,0,0-2.43-4.62,14.352,14.352,0,0,0-1.46-1.28,5.82,5.82,0,0,1,1.57,1.17,10.93,10.93,0,0,1,2.6,4.65,38.331,38.331,0,0,1,1.2,7.83c.26,3,.5,6.23.67,9.66.35,6.86.39,13.08.31,17.58,0,2.25-.1,4.07-.16,5.33l-.08,1.45A2.8,2.8,0,0,1,114.24,273.75Z"
                    fill="#263238"
                />
                <path
                    id="Path_105"
                    data-name="Path 105"
                    d="M179,287.56a8.5,8.5,0,0,0-2-1.63,12.5,12.5,0,0,0-2.82-1.13c-1.15-.32-2.49-.58-4-.88-5.9-1.2-14.06-2.88-23.1-4.57s-17.26-3.05-23.21-4l-7.05-1.13-1.92-.32a3.538,3.538,0,0,1-.66-.14,5.561,5.561,0,0,1,.67.06l1.93.25c1.67.22,4.09.56,7.07,1,6,.89,14.19,2.2,23.24,3.9s17.2,3.41,23.09,4.68c1.47.32,2.81.6,4,.95a11.921,11.921,0,0,1,2.84,1.21,6.769,6.769,0,0,1,1.52,1.22,4.556,4.556,0,0,1,.32.4C179,287.51,179,287.56,179,287.56Z"
                    fill="#263238"
                />
                <path
                    id="Path_106"
                    data-name="Path 106"
                    d="M114.4,273.75c0,.07-1,.31-2.59.95a23.375,23.375,0,0,0-2.68,1.32,23.686,23.686,0,0,0-3,2.1,23.248,23.248,0,0,0-2.58,2.58,19.8,19.8,0,0,0-1.76,2.41c-.91,1.44-1.32,2.42-1.39,2.39a3,3,0,0,1,.24-.71,14.8,14.8,0,0,1,.9-1.83,19.08,19.08,0,0,1,4.33-5.14A21.688,21.688,0,0,1,109,275.7a19.332,19.332,0,0,1,2.75-1.26,14.441,14.441,0,0,1,2-.57A2.71,2.71,0,0,1,114.4,273.75Z"
                    fill="#263238"
                />
                <path
                    id="Path_107"
                    data-name="Path 107"
                    d="M185.8,287a3.359,3.359,0,0,1-.11-.53l-.25-1.52c-.21-1.32-.49-3.24-.82-5.61-.64-4.74-1.42-11.31-2.08-18.59s-1.06-13.88-1.27-18.66c-.1-2.39-.17-4.33-.2-5.66v-1.55a3.062,3.062,0,0,1,0-.53,3.69,3.69,0,0,1,.06.53l.1,1.54c.08,1.38.19,3.3.33,5.66.28,4.77.72,11.37,1.38,18.64s1.39,13.83,2,18.58c.29,2.34.52,4.25.69,5.63q.1.94.18,1.53a2.441,2.441,0,0,1-.01.54Z"
                    fill="#263238"
                />
                <path
                    id="Path_108"
                    data-name="Path 108"
                    d="M204.14,278.75a4.33,4.33,0,0,1-.45-.5,15.582,15.582,0,0,1-1-1.52A20.13,20.13,0,0,1,200,265.11a16.553,16.553,0,0,1,.24-1.81,2.2,2.2,0,0,1,.18-.65c.07,0-.08,1-.13,2.48a23.18,23.18,0,0,0,.58,5.91,23.619,23.619,0,0,0,2.1,5.55C203.64,277.93,204.19,278.71,204.14,278.75Z"
                    fill="#263238"
                />
                <path
                    id="Path_109"
                    data-name="Path 109"
                    d="M114,245.3a1,1,0,0,1-.28.06,6.268,6.268,0,0,1-.84.08,12.7,12.7,0,0,1-3.07-.24,22.5,22.5,0,0,1-4.35-1.37c-1.59-.65-3.27-1.5-5-2.38a38.3,38.3,0,0,0-5.09-2.13,17.33,17.33,0,0,0-4.4-.86,14.831,14.831,0,0,0-4.11.46.529.529,0,0,0,.26-.12,6.005,6.005,0,0,1,.8-.27,10.42,10.42,0,0,1,3.06-.35,16.81,16.81,0,0,1,4.5.79,35.082,35.082,0,0,1,5.16,2.13c1.77.88,3.44,1.73,5,2.4a24.159,24.159,0,0,0,4.27,1.42,19.1,19.1,0,0,0,4.09.38Z"
                    fill="#fafbfb"
                />
                <path
                    id="Path_110"
                    data-name="Path 110"
                    d="M123.48,243.43a26.91,26.91,0,0,1-4.51,1,26.35,26.35,0,0,1-4.57.58,26.912,26.912,0,0,1,4.51-1,26.353,26.353,0,0,1,4.57-.58Z"
                    fill="#fafbfb"
                />
                <path
                    id="Path_111"
                    data-name="Path 111"
                    d="M113.67,259.87a6.261,6.261,0,0,1-1.11.48c-.73.28-1.79.67-3.12,1.1a80.41,80.41,0,0,1-10.58,2.61c-2.1.37-4.11.64-6,.82A23.86,23.86,0,0,1,88,265a8.53,8.53,0,0,1-3.19-.91,5.53,5.53,0,0,1-.76-.5c-.16-.13-.24-.2-.23-.21s.37.24,1.06.57a8.81,8.81,0,0,0,3.15.76,25.251,25.251,0,0,0,4.85-.21c1.83-.21,3.83-.48,5.92-.85a96.237,96.237,0,0,0,10.57-2.49l3.15-1a8.5,8.5,0,0,1,1.15-.29Z"
                    fill="#fafbfb"
                />
                <path
                    id="Path_112"
                    data-name="Path 112"
                    d="M123.26,263.9a22,22,0,0,1-4.17.27,21.79,21.79,0,0,1-4.17-.13,22.091,22.091,0,0,1,4.17-.27,22.811,22.811,0,0,1,4.17.13Z"
                    fill="#fafbfb"
                />
                <path
                    id="Path_113"
                    data-name="Path 113"
                    d="M83.86,298.79a1.628,1.628,0,0,1,.08-.41c.08-.27.18-.68.31-1.14.07-.26.14-.53.23-.83s.22-.62.35-1a21.056,21.056,0,0,1,1-2.31c.2-.42.41-.86.62-1.32s.51-.89.77-1.37a32.339,32.339,0,0,1,1.88-2.92A35,35,0,0,1,101,277.15c1.1-.54,2.13-1.08,3.16-1.46.5-.19,1-.41,1.46-.57l1.39-.43a21.64,21.64,0,0,1,2.42-.62c.36-.08.69-.17,1-.22l.85-.11,1.18-.15a1.239,1.239,0,0,1,.42,0,1.82,1.82,0,0,1-.41.1l-1.16.22-.84.15c-.31.06-.64.16-1,.24a22.6,22.6,0,0,0-2.39.68l-1.36.45c-.47.16-.95.39-1.45.59a31.908,31.908,0,0,0-3.1,1.48,35.57,35.57,0,0,0-11.81,10.27,32.815,32.815,0,0,0-1.89,2.87c-.27.47-.56.9-.79,1.35L86,293.26c-.43.81-.71,1.59-1,2.26a9.323,9.323,0,0,0-.38,1l-.27.81L84,298.41A3.09,3.09,0,0,1,83.86,298.79Z"
                    fill="#fafbfb"
                />
                <path
                    id="Path_114"
                    data-name="Path 114"
                    d="M122.26,275.27a14.12,14.12,0,0,0-3.14,4,19.289,19.289,0,0,0-1.76,5.2,53.142,53.142,0,0,0-.73,6.73,45.94,45.94,0,0,1-.78,6.78,18.47,18.47,0,0,1-1.92,5.24,11.88,11.88,0,0,1-2.34,3,7.48,7.48,0,0,1-.8.63,1.37,1.37,0,0,1-.31.19,13.55,13.55,0,0,0,3.2-3.91,18.76,18.76,0,0,0,1.81-5.18,51.227,51.227,0,0,0,.75-6.73,47.43,47.43,0,0,1,.76-6.78,18.34,18.34,0,0,1,1.87-5.26,11.92,11.92,0,0,1,2.3-3,9.292,9.292,0,0,1,.79-.64,1.25,1.25,0,0,1,.3-.27Z"
                    fill="#fafbfb"
                />
                <path
                    id="Path_115"
                    data-name="Path 115"
                    d="M135.33,307.43c-.11,0,0-6.6.29-14.74s.57-14.73.68-14.72,0,6.6-.28,14.73S135.44,307.43,135.33,307.43Z"
                    fill="#fafbfb"
                />
                <path
                    id="Path_116"
                    data-name="Path 116"
                    d="M156.12,306.29a4.249,4.249,0,0,1-.34-1,10.2,10.2,0,0,1-.31-1.15c-.1-.45-.24-1-.33-1.55a28.42,28.42,0,0,1,1.12-14,27.308,27.308,0,0,1,1.61-3.78,14.914,14.914,0,0,1,.77-1.38c.23-.38.45-.73.64-1a5.289,5.289,0,0,1,.61-.81c.07,0-.75,1.23-1.77,3.32a29.17,29.17,0,0,0-1.51,3.77,29.751,29.751,0,0,0-1.5,9.79,30.693,30.693,0,0,0,.31,4.05C155.76,304.89,156.19,306.27,156.12,306.29Z"
                    fill="#fafbfb"
                />
                <path
                    id="Path_117"
                    data-name="Path 117"
                    d="M120.82,221.65c0,.07-1.21-.46-3.2-1.11a36.53,36.53,0,0,0-16.09-1.42c-2.06.29-3.32.6-3.34.53a4.789,4.789,0,0,1,.87-.28c.56-.16,1.4-.35,2.43-.53a32.57,32.57,0,0,1,16.22,1.44,22.6,22.6,0,0,1,2.31.94,4.749,4.749,0,0,1,.8.43Z"
                    fill="#fafbfb"
                />
                <path
                    id="Path_118"
                    data-name="Path 118"
                    d="M181.32,253.85a22.233,22.233,0,0,1-2.84,0c-1.76-.07-4.18-.16-6.85-.23s-5.09-.09-6.84-.11a20.759,20.759,0,0,1-2.84-.1,2.63,2.63,0,0,1,.76-.09c.49,0,1.2-.07,2.07-.1,1.76-.05,4.18-.07,6.86,0s5.1.2,6.85.34c.88.07,1.58.14,2.07.2A2.909,2.909,0,0,1,181.32,253.85Z"
                    fill="#fafbfb"
                />
                <path
                    id="Path_119"
                    data-name="Path 119"
                    d="M196.44,251c0,.07-1,.4-2.46,1.13a24.49,24.49,0,0,0-5.36,3.62,24.211,24.211,0,0,0-4.2,4.91c-.91,1.4-1.35,2.34-1.41,2.31a2.19,2.19,0,0,1,.25-.69,15.581,15.581,0,0,1,.92-1.77,21.62,21.62,0,0,1,9.68-8.63,15.179,15.179,0,0,1,1.87-.71,2.19,2.19,0,0,1,.71-.17Z"
                    fill="#fafbfb"
                />
                <path
                    id="Path_120"
                    data-name="Path 120"
                    d="M189,226.44a11.62,11.62,0,0,0-3.89.54,26.363,26.363,0,0,0-3.93,1.75,31.68,31.68,0,0,1-4.85,2.18,19.78,19.78,0,0,1-9.6.63,17.677,17.677,0,0,1-2.83-.77,5.338,5.338,0,0,1-1-.43,39.627,39.627,0,0,0,3.86.92,20.64,20.64,0,0,0,9.42-.73A33.588,33.588,0,0,0,181,228.4a24.811,24.811,0,0,1,4-1.69,9.3,9.3,0,0,1,2.91-.41,6,6,0,0,1,.8.07C188.89,226.4,189,226.42,189,226.44Z"
                    fill="#fafbfb"
                />
                <path
                    id="Path_121"
                    data-name="Path 121"
                    d="M185.16,277.77a5.249,5.249,0,0,1-.89.41,11,11,0,0,1-1.08.41c-.43.14-.91.32-1.46.46a27.879,27.879,0,0,1-3.9.81,32.7,32.7,0,0,1-4.87.26,32,32,0,0,1-4.85-.55,28.5,28.5,0,0,1-3.84-1c-.55-.17-1-.38-1.43-.55s-.77-.33-1.06-.47a4.611,4.611,0,0,1-.86-.47c0-.07,1.29.54,3.43,1.22a32,32,0,0,0,8.62,1.46,35,35,0,0,0,4.81-.22,32.764,32.764,0,0,0,3.88-.72C183.84,278.23,185.13,277.7,185.16,277.77Z"
                    fill="#fafbfb"
                />
                <path
                    id="Path_122"
                    data-name="Path 122"
                    d="M202.75,300.43a2.214,2.214,0,0,1-.29-.24c-.18-.17-.47-.42-.79-.77a18.1,18.1,0,0,1-2.42-3.25,26.5,26.5,0,0,1-3.42-12.83,51.8,51.8,0,0,1,1.74-13.22c.41-1.66.78-3,1.05-3.9.13-.42.24-.77.32-1.06a1.49,1.49,0,0,1,.14-.35,1.83,1.83,0,0,1-.06.38l-.25,1.07-.92,3.92a55.249,55.249,0,0,0-1.62,13.15A27.18,27.18,0,0,0,199.49,296a20.291,20.291,0,0,0,2.29,3.29,11.907,11.907,0,0,1,.97,1.14Z"
                    fill="#fafbfb"
                />
                <path
                    id="Path_123"
                    data-name="Path 123"
                    d="M230.62,274.43a15.26,15.26,0,0,0-4.05-2.28,31.7,31.7,0,0,0-4.9-1.27,55.864,55.864,0,0,1-6.1-1.41,25,25,0,0,1-9.7-5.83,22.748,22.748,0,0,1-2.3-2.58c-.25-.32-.42-.59-.54-.77s-.17-.27-.16-.28a8.549,8.549,0,0,1,.82.95,28.8,28.8,0,0,0,2.37,2.47,26.06,26.06,0,0,0,9.63,5.66,61.363,61.363,0,0,0,6.05,1.43,29.8,29.8,0,0,1,4.94,1.37,11.539,11.539,0,0,1,3,1.68,5.72,5.72,0,0,1,.71.62C230.56,274.34,230.63,274.42,230.62,274.43Z"
                    fill="#fafbfb"
                />
                <path
                    id="Path_124"
                    data-name="Path 124"
                    d="M239.38,248.87a5.27,5.27,0,0,1-.82-.71c-.25-.24-.56-.53-.94-.85s-.79-.7-1.28-1.08a37.4,37.4,0,0,0-3.56-2.51,38.567,38.567,0,0,0-4.73-2.46,35.89,35.89,0,0,0-9.32-2.56c-.61-.1-1.17-.13-1.66-.18s-.92-.08-1.27-.1a5.672,5.672,0,0,1-1.08-.1,4.939,4.939,0,0,1,1.09,0h1.27a13.4,13.4,0,0,1,1.68.13,31.627,31.627,0,0,1,4.34.76,37.371,37.371,0,0,1,5.11,1.71,36,36,0,0,1,4.77,2.52,32.655,32.655,0,0,1,3.54,2.61c.49.39.89.79,1.25,1.13a10.84,10.84,0,0,1,.9.9,5.269,5.269,0,0,1,.71.79Z"
                    fill="#fafbfb"
                />
                <path
                    id="Path_125"
                    data-name="Path 125"
                    d="M172.38,213.72a5.67,5.67,0,0,1-1.46.7,27,27,0,0,1-3.71,1.15,27.868,27.868,0,0,1-3.85.61,5.811,5.811,0,0,1-1.61,0c0-.12,2.43-.33,5.37-1S172.33,213.61,172.38,213.72Z"
                    fill="#fafbfb"
                />
                <path
                    id="Path_126"
                    data-name="Path 126"
                    d="M199.54,321.58a2.13,2.13,0,0,1-2.34,2.7h0a2.12,2.12,0,0,1-1.75-1.5l-.65-2.41h.09a2.22,2.22,0,0,1-2.48,2.63h0c-1,0-1.87-1.46-2.16-2.23h0a4.19,4.19,0,0,1-3.89-1.87l-4.49-7-8.33-6.08,5-18,17.91,11.59c.71.55.81.78,1.93,1.82L210,311.88c-1.32.79-2.43.73-4.25,0s-9.8-6.12-9.8-6.12l3.6,15.82"
                    fill="#e4e3e3"
                />
                <path
                    id="Path_127"
                    data-name="Path 127"
                    d="M184.21,306.78a1.89,1.89,0,0,1,.54.31l.59.42a3.739,3.739,0,0,1,.74.64,3.381,3.381,0,0,1,.6,1l.44,1.25c.31.9.64,1.87,1,2.88.69,2.05,1.28,3.91,1.7,5.26a13.821,13.821,0,0,1,.59,2.22,13.528,13.528,0,0,1-.86-2.13c-.48-1.33-1.12-3.18-1.81-5.22l-.94-2.89-.41-1.26a3.08,3.08,0,0,0-.51-1,4,4,0,0,0-.66-.65l-.55-.46A2,2,0,0,1,184.21,306.78Z"
                    fill="#707272"
                />
                <path
                    id="Path_128"
                    data-name="Path 128"
                    d="M195,320.76a3.21,3.21,0,0,1-.25-.75c-.16-.57-.36-1.25-.58-2.05-.48-1.73-1.14-4.12-2-6.73a24.35,24.35,0,0,0-2.76-6.38,5.3,5.3,0,0,0-1.47-1.47c-.41-.26-.69-.34-.67-.38s.07,0,.2,0a2.459,2.459,0,0,1,.55.22,4.88,4.88,0,0,1,1.62,1.43,22.16,22.16,0,0,1,2.91,6.43c.83,2.62,1.45,5,1.86,6.78.21.88.36,1.59.46,2.09a2.791,2.791,0,0,1,.13.81Z"
                    fill="#707272"
                />
                <path
                    id="Path_129"
                    data-name="Path 129"
                    d="M199.61,321.84a4.459,4.459,0,0,1-.31-.8c-.19-.61-.43-1.36-.71-2.23-.58-1.89-1.36-4.51-2.19-7.41s-1.54-5.44-2.11-7.43a3.59,3.59,0,0,0-1.22-1.87,3.862,3.862,0,0,0-.74-.39h.22a2,2,0,0,1,.61.25,3.64,3.64,0,0,1,1.4,1.91c.63,1.89,1.37,4.51,2.22,7.41s1.57,5.53,2.08,7.44l.59,2.27A3.239,3.239,0,0,1,199.61,321.84Z"
                    fill="#707272"
                />
                <path
                    id="Path_130"
                    data-name="Path 130"
                    d="M308.44,155.78a2.68,2.68,0,1,0,2.68-2.68A2.68,2.68,0,0,0,308.44,155.78Z"
                    fill="#707272"
                />
                <circle
                    id="Ellipse_1"
                    data-name="Ellipse 1"
                    cx="2.68"
                    cy="2.68"
                    r="2.68"
                    transform="translate(297.71 153.1)"
                    fill="#707272"
                />
                <path
                    id="Path_131"
                    data-name="Path 131"
                    d="M287,154.44a2.68,2.68,0,1,0,1.623-1.259A2.68,2.68,0,0,0,287,154.44Z"
                    fill="#707272"
                />
                <path
                    id="Path_132"
                    data-name="Path 132"
                    d="M300.22,124.47a4.382,4.382,0,0,0-.54,0l-1.59.05h-1.18l-1.4.2-1.64.23-1.83.46a29.429,29.429,0,0,0-8.88,3.87,31.86,31.86,0,0,0-9.19,8.94,30.83,30.83,0,0,0-5.29,18.9,18.053,18.053,0,0,0,.14,2.2l.33,2.21a31.22,31.22,0,0,0,3,8.74,33.74,33.74,0,0,0,5.7,7.85,32.93,32.93,0,0,0,8.23,5.91,30,30,0,0,0,10,3.08,19.762,19.762,0,0,0,2.67.22,24.325,24.325,0,0,0,2.7,0,32.262,32.262,0,0,0,5.42-.68,31.7,31.7,0,0,0,10.77-4.54h-.33l11,4.4.65.26-.18-.67-3.23-11.74-.06.29a31.42,31.42,0,0,0,5.86-13.4,28.638,28.638,0,0,0,.43-6.87,22.362,22.362,0,0,0-.31-3.3c-.09-.54-.17-1.08-.25-1.61s-.26-1-.39-1.55a31.42,31.42,0,0,0-12.24-17.86,30.18,30.18,0,0,0-7.29-3.84l-1.64-.59-1.56-.39-1.43-.36c-.45-.08-.9-.13-1.31-.2-.83-.1-1.56-.24-2.2-.28h-2.61a1.719,1.719,0,0,0-.34,0,1.23,1.23,0,0,0,.33,0l1,.07,1.6.11a18.183,18.183,0,0,1,2.18.33,31.2,31.2,0,0,1,24.95,23.16c.12.5.28,1,.37,1.52l.24,1.58a21.56,21.56,0,0,1,.29,3.24,28.511,28.511,0,0,1-.44,6.73A31.06,31.06,0,0,1,325,174.38l-.1.13v.16q1.56,5.69,3.21,11.74l.46-.41-11-4.42-.17-.07-.16.1a30.91,30.91,0,0,1-15.82,5.1,23.4,23.4,0,0,1-2.65-.05,19,19,0,0,1-2.61-.22,29.25,29.25,0,0,1-9.8-3,32.311,32.311,0,0,1-8.06-5.77,32.92,32.92,0,0,1-5.6-7.67,31,31,0,0,1-3-8.55c-.11-.73-.23-1.45-.34-2.17s-.09-1.44-.14-2.15a30.675,30.675,0,0,1,14.06-27.49,30.13,30.13,0,0,1,8.74-3.94l1.81-.48,1.62-.26,1.39-.23c.43,0,.82-.06,1.17-.09l1.58-.12a3.731,3.731,0,0,0,.63-.05Z"
                    fill="#707272"
                />
                <circle
                    id="Ellipse_2"
                    data-name="Ellipse 2"
                    cx="8.81"
                    cy="8.81"
                    r="8.81"
                    transform="translate(482.49 135.55)"
                    fill="#fff"
                    stroke="#e2e2e2"
                    strokeMiterlimit="10"
                    strokeWidth="2"
                />
                <circle
                    id="Ellipse_3"
                    data-name="Ellipse 3"
                    cx="5.43"
                    cy="5.43"
                    r="5.43"
                    transform="translate(456.95 166.89)"
                    fill="#fff"
                    stroke="#e2e2e2"
                    strokeMiterlimit="10"
                    strokeWidth="2"
                />
                <path
                    id="Path_133"
                    data-name="Path 133"
                    d="M23.05,264.78H15.61v-7.43a.93.93,0,0,0-.93-.92h0a.94.94,0,0,0-.94.92v7.43H6.32a1,1,0,0,0-.94,1,.94.94,0,0,0,.94.92h7.42v7.44a.94.94,0,0,0,1.87,0h0V266.7h7.44a.93.93,0,0,0,.93-.92,1,1,0,0,0-.93-1Z"
                    fill="#e2e2e2"
                    stroke="#e2e2e2"
                    strokeMiterlimit="10"
                    strokeWidth="0.75"
                />
                <path
                    id="Path_134"
                    data-name="Path 134"
                    d="M233.9,101.23h-6.09V95.15a.76.76,0,0,0-.76-.76h0a.77.77,0,0,0-.77.76v6.08h-6.07a.77.77,0,1,0,0,1.53h6.07v6.09a.77.77,0,0,0,.77.76.76.76,0,0,0,.76-.76h0v-6.09h6.09a.76.76,0,0,0,.76-.76.77.77,0,0,0-.76-.77Z"
                    fill="#e2e2e2"
                    stroke="#e2e2e2"
                    strokeMiterlimit="10"
                    strokeWidth="0.75"
                />
                <g id="Group_14" data-name="Group 14" opacity="0.15">
                    <path id="Path_135" data-name="Path 135" d="M477.17,260h28.92l-29.58,29.58Z" />
                    <g id="Group_13" data-name="Group 13" opacity="0.4">
                        <path
                            id="Path_136"
                            data-name="Path 136"
                            d="M477.17,260h28.92l-29.58,29.58Z"
                        />
                    </g>
                </g>
            </g>
        </SVGIcon>
    );
}
