import React, {
    useCallback,
    Fragment,
    useState,
    useRef,
    useEffect,
    useContext,
    useMemo,
} from 'react';

import _ from 'lodash';
import { swap } from 'react-grid-dnd';
import DCConversations from 'Assets/icons/DCConversations';
import DCAgency from 'Assets/icons/DCAgency';
import DCAnalytics from 'Assets/icons/DCAnalytics';
import DCContacts from 'Assets/icons/DCContacts';
import DCDashboardMenu from 'Assets/icons/DCDashboardMenu';
import DCDealMenu from 'Assets/icons/DCDealMenu';
import DCForms from 'Assets/icons/DCForms';
import DCInbound from 'Assets/icons/DCInbound';
import DCInstareports from 'Assets/icons/DCInstareports';
import DCInstasites from 'Assets/icons/DCInstasites';
import DCBell from 'Assets/icons/DCBell';
import DCProject from 'Assets/icons/DCProject';
import DCTemplates from 'Assets/icons/DCTemplates';
import DCDashClicks from 'Assets/icons/DCDashClicks';
import DCReputation from 'Assets/icons/DCReputation';
import {
    Badge,
    Div,
    IconButton,
    Button,
    Image,
    Logo,
    Span,
    TopBar,
    ToolTip,
    UserInfo,
    Popover,
} from 'UIKit/index';
import NotificationsDialog from '../NotificationsDialog/notificationsDialog';
import Favorites from './Favorites/Favorites';
import MenuList from './menuList';
import UserDropdown from './userDropdown';
import CartPopover from './cartPopover';
import HelpDialog from './helpDialog';
import { getMenuData } from 'Routes/getRoutes';
import { updateMenuData } from 'apis/root/index.api';
import {
    getAccountDetails,
    getAppVersion,
    getUserConfigs,
    getUserDetails,
} from 'Utils/localStorageUtils';
import { themeContext } from 'Contexts/Context';
import usePubSub from 'Hooks/PubSub';
import AccessScopes from 'Constants/scopes';
import areValidScopes from 'Utils/scopeUtils';
import generateImage, { generateUserImage } from 'Utils/generateImage';
import { useHistory } from 'react-router-dom';
import useToast from 'Modules/Toasts';
import DCCrossClose from 'Assets/icons/DCCrossClose';
import {
    updateAccount,
    clearSubscriptionNotification,
    getSubscription,
} from 'apis/settings/index.api';
import { getAccountType, isAuthenticated } from 'Utils/auth';
import CancelSubscription from 'Components/CancelSubscription/cancelSubscription';
import { useInfiniteQuery, useQuery } from 'react-query';
import NewsHeadline from './NewsHeadline';
import { getAccounts } from 'apis/subaccounts/index.api';
import { AutoComplete } from 'Components';
import { ssoLogin } from 'apis/login/index.api';
import DCSearch from 'Assets/icons/DCSearch';
import UseMediaQuery from 'UIKit/useMediaQuery';
import './style.scss';

const MenuBar = ({ customClass }) => {
    const matche550 = UseMediaQuery('(max-width: 550px)');
    const matches850 = UseMediaQuery('(min-width: 850px)');
    const matches767 = UseMediaQuery('(max-width: 767px)');
    const matches600 = UseMediaQuery('(min-width: 600px)');
    const matches460Above = UseMediaQuery('(min-width:460px) and (max-width:550px)');
    const matches460Below = UseMediaQuery('(max-width:460px)');
    const matches1260 = UseMediaQuery('(min-width:1260px)');
    const matches1040 = UseMediaQuery('(min-width:1040px)');
    const history = useHistory();
    const { showToast } = useToast();
    const { publish } = usePubSub();
    const { notificationCount, notify, setNotify } = useContext(themeContext);
    const [menuOpen, setMenuOpen] = useState(false);
    const [userDropdownState, setUserDropdownState] = useState(false);
    const [notificationsDialog, setNotificationsDialog] = useState(false);
    const [reorderItemState, setReorderItemState] = useState(false);
    const [storeAccess, setStoreAccess] = useState(false);
    const [loading, setLoading] = useState(false);
    const [refresh, setRefresh] = useState(false);
    const [planFailed, setPlanFailed] = useState(false);
    const [currentValue, setCurrentValue] = useState(getAppVersion());
    const [settingsUpdated, setSettingsUpdated] = useState(false);
    const [tempReOder, setTemReOder] = useState([]);
    const [desktopMenu, setDesktopMenu] = useState([]);
    const [favoriteMenus, setFavoriteMenus] = useState({ favorite: [] });
    const [softwarePLanData, setSoftwarePLanData] = useState({});
    const [activeDataHigh, setActiveDataHigh] = useState([]);
    const [cancellationStepperOpen, setCancellationStepperOpen] = useState(false);
    const currentInterval = getAccountDetails()?.plan?.recurring?.interval || 'month';
    const [notificationType, setNotificationType] = useState(null);
    const [showSSO, setShowSSO] = useState(false);
    const [ssoSearchText, setSsoSearchText] = useState('');
    const searchBtnRef = useRef(null);
    const [searchPopover, setSearchPopover] = useState(false);
    const [timerRefreshBar, setTimerRefreshBar] = useState(false);
    const [counter, setCounter] = useState(5);
    const [showWarningSeo, setShowWarningSeo] = useState(false);

    const handleSearchPopoverClose = useCallback(() => {
        setSearchPopover(false);
    }, []);

    usePubSub('app-version', _data => {
        setCurrentValue(_data);
        if (currentValue && currentValue !== _data) {
            setRefresh(true);
        }
    });

    usePubSub('show-warning-seo', _data => {
        setShowWarningSeo(_data);
    });

    useEffect(() => {
        const account = getAccountDetails();
        if (account?.plan?.show_notification) {
            setPlanFailed(true);
        } else {
            setPlanFailed(false);
        }
    }, []);

    const { data } = useQuery(
        ['paywalls_plans', `sub=${getAccountType() !== 'main'}`],
        () => getSubscription(`sub=${getAccountType() !== 'main'}`),
        {
            staleTime: 1000 * 60 * 10, // 10 minutes
            cacheTime: 1000 * 60 * 15, // 15 minutes
            retry: 1,
            keepPreviousData: true,
            enabled: isAuthenticated() && getAccountDetails()?.downgrade?.prompt,
        },
    );

    const {
        data: accounts,
        isLoading: isLoadingAccounts,
        isFetchingNextPage: isFetchingAccountNextPage,
        hasNextPage: hasAccountNextPage,
        fetchNextPage: fetchAccountNextPage,
    } = useInfiniteQuery(
        ['sso-accounts', ssoSearchText],
        ({ pageParam = 1 }) => getAccounts({
                limit: 25,
                page: pageParam,
                search: ssoSearchText || undefined,
                pending: false,
                all: true,
            }),
        {
            staleTime: 1000 * 60 * 10, // 10 minutes
            cacheTime: 1000 * 60 * 15, // 15 minutes
            retry: 2,
            getNextPageParam: lastPage => {
                if (lastPage?.pagination?.next_page) {
                    return lastPage.pagination.next_page;
                } else return undefined;
            },
            onError: err => {
                showToast({
                    type: 'error',
                    duration: 1500,
                    message: err?.message || err || 'Failed to load sub-accounts for sso',
                });
            },
            enabled: getUserDetails()?.is_owner,
        },
    );

    const getTierObject = item => {
        return {
            id: item.id,
            highlights: item.additional_info?.highlights,
            color: item.additional_info?.color,
            priceId: item.id,
            nickname: item.nickname,
            unit_amount: item.unit_amount,
            type: item.type,
            details: item.additional_info?.details,
            order: item.additional_info?.order,
            profit: item.additional_info?.profit,
            wholeSaleAmount: item.additional_info?.wholesale_unit_amount,
            tier: item.metadata.tier,
        };
    };

    useEffect(() => {
        if (data) {
            const body = data.data[0]?.prices || [];
            const monthlyHighlighted = body
                .filter(item => item.recurring.interval === 'month')
                .map(item => getTierObject(item));
            const yearlyHighlighted = body
                .filter(item => item.recurring.interval === 'year')
                .map(item => getTierObject(item));
            const tempMonthHighlighted = _.sortBy(monthlyHighlighted, 'order');
            const tempYearHighlighted = _.sortBy(yearlyHighlighted, 'order');

            if (currentInterval === 'month') setActiveDataHigh(tempMonthHighlighted);
            else setActiveDataHigh(tempYearHighlighted);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data]);

    useEffect(() => {
        if (activeDataHigh.length) {
            setSoftwarePLanData(activeDataHigh[0]);
        }
    }, [activeDataHigh, setSoftwarePLanData]);

    useEffect(() => {
        const account = getAccountDetails();
        if (account?.downgrade?.prompt) {
            history.push('settings/general?step=cancellation-confirmation');

            setCancellationStepperOpen(true);
        }
    }, [history, publish, softwarePLanData]);

    usePubSub('settings-updated', () => {
        setSettingsUpdated(true);
        setRefresh(true);
    });

    usePubSub('count-down-refreshBar', () => {
        setTimerRefreshBar(true);
    });
    useEffect(() => {
        (async () => {
            const templateAccess = areValidScopes(AccessScopes.root.STORE.text);
            setStoreAccess(templateAccess);
            setLoading(false);
        })();
    }, []);

    const handleMenuClose = useCallback(() => {
        setMenuOpen(false);
        setReorderItemState(false);
        if (!_.isEqual(tempReOder, desktopMenu)) {
            setDesktopMenu(tempReOder);
        }
    }, [desktopMenu, tempReOder]);

    usePubSub('open-notifications-dialog', () => {
        setNotificationsDialog(true);
        setNotificationType('type1');
    });

    const handleNotificationsDialogOpen = () => {
        setMenuOpen(false);
        setNotificationsDialog(true);
    };

    const handleNotificationsDialogClose = () => {
        setNotificationsDialog(false);
    };

    useEffect(() => {
        if (notify && notify?.openNotificationbar) {
            handleNotificationsDialogOpen();
            setNotify({
                openNotificationbar: false,
                activeTab: 'upcoming',
            });
        }
    }, [notify, setNotify]);

    const handleReorderMenuApps = (sourceId, sourceIndex, targetIndex) => {
        const result = swap(desktopMenu, sourceIndex, targetIndex);

        setDesktopMenu(result);
    };
    const handleMenuOpen = useCallback(() => {
        setMenuOpen(prev => !prev);
        setReorderItemState(false);
        setUserDropdownState(false);

        setTemReOder([...desktopMenu]);
    }, [desktopMenu]);

    const handleFavoriteChange = (items, menuItems = desktopMenu) => {
        const body = menuItems.map((menuItem, index) => {
            return {
                id: menuItem.id,
                name: menuItem.name,
                isFavorite: menuItem.isFavorite,
                menu_order: index,
                fav_order: menuItem.fav_order,
                label: menuItem.label,
            };
        });

        items.forEach((item, index) => {
            const ind = body.findIndex(obj => obj.id === item.id);
            body[ind].isFavorite = true;
            body[ind].fav_order = index;
        });

        menuItems.map(item => {
            const ind = body.findIndex(obj => obj.id === item.id);
            if (ind !== -1) {
                item.fav_order = body[ind].fav_order || -1;
                item.isFavorite = body[ind].isFavorite || false;
            }
        });

        setDesktopMenu(menuItems);
        updateMenuData({ data: body });
    };

    const handleFavoriteMenu = item => {
        const tempArr = _.cloneDeep(desktopMenu);
        const ind = tempArr.findIndex(obj => obj.id === item.id);
        tempArr[ind].isFavorite = !item.isFavorite;
        if (favoriteMenus.favorite.length < 10 || !tempArr[ind].isFavorite) {
            const result = tempArr.filter(_item => _item.isFavorite === true);
            setDesktopMenu(tempArr);
            setFavoriteMenus({ favorite: result });
            handleFavoriteChange(result, tempArr);
        } else {
            showToast({
                type: 'warning',
                message: 'You can only add up to 10 apps in the favorites bar',
            });
        }
    };

    const handleReorderItems = async () => {
        if (reorderItemState) {
            const body = desktopMenu.map((menuItem, index) => {
                return {
                    id: menuItem.id,
                    name: menuItem.name,
                    isFavorite: menuItem.isFavorite,
                    menu_order: index,
                    fav_order: menuItem.fav_order,
                    label: menuItem.label,
                };
            });

            updateMenuData({ data: body });
            setTemReOder([...desktopMenu]);
        }

        setReorderItemState(prev => !prev);
    };

    const handleAddFavorite = () => {
        setMenuOpen(true);
        setReorderItemState(true);
    };

    const reOrderArray = (list, startIndex, endIndex) => {
        const result = list;
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);
        return result;
    };

    const onDragEnd = result => {
        if (!result.destination) {
            return;
        }
        const items = reOrderArray(
            favoriteMenus.favorite,
            result.source.index,
            result.destination.index,
        );
        setFavoriteMenus({ favorite: items });
        handleFavoriteChange(items);
    };

    // User Menu

    const userDropdownRef = useRef();
    const handleUserDropdownOpen = () => {
        setUserDropdownState(prev => !prev);
        setMenuOpen(false);
    };

    const handleUserDropdownClose = () => {
        setUserDropdownState(false);
    };

    const getIcon = app => {
        switch (app) {
            case 'projects':
                return <DCProject />;
            case 'inbound':
                return <DCInbound />;
            case 'contacts':
                return <DCContacts />;
            case 'templates':
                return <DCTemplates />;
            case 'instasites':
                return <DCInstasites />;
            case 'instareports':
                return <DCInstareports />;
            case 'forms':
                return <DCForms />;
            case 'sites':
                return <DCAgency />;
            case 'deals':
                return <DCDealMenu />;
            case 'analytics':
                return <DCAnalytics />;
            case 'dashboard':
                return <DCDashboardMenu />;
            case 'conversations':
                return <DCConversations />;
            case 'dashclicks':
                return <DCDashClicks />;
            case 'reputation':
                return <DCReputation />;
        }
    };

    const handleScheduleButton = () => {
        publish('open-schedule');
    };

    useEffect(() => {
        (async () => {
            let menuData = await getMenuData();
            menuData = menuData.filter(item => !item.hide);
            const initialData = [];
            [
                'projects',
                'inbound',
                'contacts',
                'templates',
                'instasites',
                'instareports',
                'forms',
                'sites',
                'deals',
                'analytics',
                'dashboard',
                'dashclicks',
                'reputation',
                'conversations',
            ].forEach(app => {
                const _ind = menuData?.findIndex(_menu => _menu.name === app);

                if (_ind !== -1) {
                    initialData.push({
                        id: menuData[_ind].id,
                        label: menuData[_ind].label,
                        link: '/' + app.charAt(0).toLowerCase() + app.slice(1),
                        name: app,
                        isFavorite: menuData[_ind].isFavorite,
                        menu_order: menuData[_ind].menu_order,
                        fav_order: menuData[_ind].fav_order,
                        icon: getIcon(app),
                        isLock: menuData[_ind].isLock,
                    });
                }
            });
            const _data = _.sortBy(initialData, ['menu_order']);
            setTemReOder(_data);
            setDesktopMenu(_data);
            setFavoriteMenus({
                favorite: _.sortBy(
                    initialData.filter(_menu => _menu.isFavorite),
                    ['fav_order'],
                ),
            });

            const ssoConfig = getUserConfigs()?.configurations?.find(
                config => config.type === 'preferences',
            )?.data?.sso;
            setShowSSO(ssoConfig?.main_menu);
        })();
    }, []);

    const [show, setShow] = useState(true);

    const handleClose = async () => {
        setMenuOpen(false);
        await updateAccount(getAccountDetails().id, { scheduled_demo: true });
        setShow(false);
    };
    const handleSubscriptionBtn = useCallback(async () => {
        if (planFailed) {
            setPlanFailed(false);
            await clearSubscriptionNotification();
        }
    }, [planFailed]);

    const handleSSO = useCallback(
        async accountId => {
            try {
                const response = await ssoLogin(accountId);
                if (response.success) {
                    window.open(response.data.url, '_blank');
                }
            } catch (err) {
                showToast({
                    type: 'error',
                    message: err || 'Could not login into sub-account',
                });
            }
        },
        [showToast],
    );

    const generateAccountOptions = useCallback(() => {
        let temp = [];
        accounts?.pages?.forEach(page => {
            page.data.forEach(account => {
                temp.push({
                    id: account.id,
                    label: (
                        <Button
                            buttonClass={'ACSingleOption DropdownOptionsBtn'}
                            onClick={() => {
                                handleSSO(account.id);
                            }}
                        >
                            <UserInfo
                                name={account.name || account?.business?.name}
                                image={generateImage(account.logo, account.name)}
                                email={account.email || account?.owner?.email}
                            />
                        </Button>
                    ),
                    value: account.name || account?.business?.name,
                });
            });
        });
        return temp;
    }, [accounts, handleSSO]);

    const handleSearchPopoverToggle = useCallback(() => {
        setSearchPopover(prevOpen => !prevOpen);
    }, []);

    useEffect(() => {
        if (timerRefreshBar) {
            counter > 0 && setTimeout(() => setCounter(counter - 1), 1000);
            if (counter === 0) {
                location.reload(true);
            }
        }
    }, [counter, timerRefreshBar]);

    const getSearchResultsPopover = useMemo(() => {
        let placement = 'bottom-start';
        if (matches850) {
            placement = 'bottom-end';
        } else if (matches460Below) {
            placement = 'bottom-center';
        } else if (matches460Above || !matches850) {
            placement = 'bottom-start';
        }

        return (
            <Popover
                open={searchPopover}
                anchorEl={searchBtnRef}
                onClose={handleSearchPopoverClose}
                placement={placement}
                customClass={'ArrowPopover PopoverSearchBox HeaderSearchPopoverBox'}
            >
                <AutoComplete
                    generateRows={generateAccountOptions}
                    isAPICall
                    singleSelection
                    searchValue={ssoSearchText}
                    searchAPI={val => setSsoSearchText(val)}
                    hasNextPage={hasAccountNextPage}
                    loading={isLoadingAccounts || isFetchingAccountNextPage}
                    getMoreOptions={fetchAccountNextPage}
                    itemHeight={56}
                    placeholder={'Search sub-accounts'}
                    customMessage={'No Sub-Account Found'}
                    type="search"
                    searchBoxPlaceholder={`Search Sub-Accounts`}
                    searchbarExpandBreakpoint={matches1260}
                />
            </Popover>
        );
    }, [
        fetchAccountNextPage,
        generateAccountOptions,
        handleSearchPopoverClose,
        hasAccountNextPage,
        isFetchingAccountNextPage,
        isLoadingAccounts,
        matches1260,
        matches460Above,
        matches460Below,
        matches850,
        searchPopover,
        ssoSearchText,
    ]);

    return (
        <Fragment>
            {notificationsDialog && (
                <NotificationsDialog
                    open={notificationsDialog}
                    handleClose={handleNotificationsDialogClose}
                    type={notificationType}
                    setType={setNotificationType}
                />
            )}
            {cancellationStepperOpen && (
                <CancelSubscription
                    open={cancellationStepperOpen}
                    close={() => setCancellationStepperOpen(false)}
                    type={{ service: false, serviceName: '', plan: softwarePLanData }}
                    downGradeMessage={
                        'Your subscription has reached to its limit. please downgrade the subscription.'
                    }
                    downGradePromt={true}
                />
            )}
            <MenuList
                open={menuOpen}
                handleClose={handleMenuClose}
                desktopMenu={desktopMenu}
                handleReorderMenuApps={handleReorderMenuApps}
                handleFavoriteMenu={handleFavoriteMenu}
                reorderItemState={reorderItemState}
                handleReorderItems={handleReorderItems}
            />

            {timerRefreshBar && (
                <Div className="RefreshBar">Reloading the page in {counter} seconds</Div>
            )}
            {refresh && (
                <Div className="RefreshBar">
                    {settingsUpdated
                        ? 'Settings has been updated. Please '
                        : 'A new version of the dashboard is available. Please '}
                    <Button
                        buttonType={'SimpleLink'}
                        onClick={() => {
                            location.reload(true);
                        }}
                    >
                        refresh
                    </Button>{' '}
                    {settingsUpdated ? 'to see the change.' : 'to see new version.'}
                </Div>
            )}
            {showWarningSeo && (
                <Div className="WarningBar WarningBarYellowColor">
                    Our SEO ranking tool is undergoing maintenance and is currently not tracking
                    keywords. We’re rebuilding portions of the tool to make it more reliable and get
                    better ranking data. This should be back up and running in the next short couple
                    of weeks.
                </Div>
            )}
            <NewsHeadline />
            {planFailed && (
                <Div className="WarningBar">
                    Your platform subscription has failed. Please visit your{' '}
                    <Button
                        buttonType={'SimpleLink'}
                        onClick={() => {
                            history.push('/settings/general?tab=subscriptions');
                        }}
                    >
                        subscription page
                    </Button>{' '}
                    and fix to avoid any disruptions in your account.
                    <IconButton
                        buttonType="circleBtn"
                        customClass={'WBSubscription'}
                        onClick={handleSubscriptionBtn}
                    >
                        <DCCrossClose />
                    </IconButton>
                </Div>
            )}
            <TopBar customClass={customClass}>
                <Logo />
                <Div className="MenuBarRight">
                    {!matche550 &&
                        !getAccountDetails().scheduled_demo &&
                        getAccountType() === 'main' &&
                        show && (
                            <>
                                {matches600 && (matches850 || matches767) ? (
                                    <Button buttonClass={'MRDemo'} buttonType={'headerBtn'}>
                                        <Span className={'BtnText'} onClick={handleScheduleButton}>
                                            Schedule A Demo
                                        </Span>

                                        <IconButton
                                            customClass={'MRDIcon'}
                                            buttonType="circleBtn"
                                            onClick={handleClose}
                                        >
                                            <DCCrossClose />
                                        </IconButton>
                                    </Button>
                                ) : (
                                    <ToolTip
                                        content={'Schedule A Demo'}
                                        placement={'bottom-center'}
                                        customPopoverClass={'MRDScheduleDemoToolTip'}
                                    >
                                        <Button buttonClass={'MRDemo'} buttonType={'headerBtn'}>
                                            <Span
                                                className={'BtnText'}
                                                onClick={handleScheduleButton}
                                            >
                                                Demo
                                            </Span>

                                            <IconButton
                                                customClass={'MRDIcon'}
                                                buttonType="circleBtn"
                                                onClick={handleClose}
                                            >
                                                <DCCrossClose />
                                            </IconButton>
                                        </Button>
                                    </ToolTip>
                                )}
                            </>
                        )}
                    {getAccountType() === 'main' && showSSO && getUserDetails().is_owner && (
                        <>
                            {matches1040 ? (
                                <AutoComplete
                                    generateRows={generateAccountOptions}
                                    isAPICall
                                    singleSelection
                                    searchValue={ssoSearchText}
                                    searchAPI={val => setSsoSearchText(val)}
                                    hasNextPage={hasAccountNextPage}
                                    loading={isLoadingAccounts || isFetchingAccountNextPage}
                                    getMoreOptions={fetchAccountNextPage}
                                    itemHeight={56}
                                    placeholder={'Search sub-accounts'}
                                    customMessage={'No Sub-Account Found'}
                                    type="search"
                                    searchBoxPlaceholder={`Search Sub-Accounts`}
                                    searchbarExpandBreakpoint={matches1260}
                                />
                            ) : (
                                <>
                                    <IconButton
                                        buttonType={'headerIconBtn'}
                                        reference={searchBtnRef}
                                        onClick={handleSearchPopoverToggle}
                                        customClass={'HeaderSearchIcon '}
                                    >
                                        <DCSearch />
                                    </IconButton>

                                    {getSearchResultsPopover}
                                </>
                            )}
                        </>
                    )}
                    {/* setIsSearchAnimationDone(true); */}
                    <Favorites
                        favoriteMenus={favoriteMenus.favorite}
                        onDragEnd={onDragEnd}
                        handleAddFavorite={handleAddFavorite}
                        closeDrawer={handleMenuClose}
                    />
                    {storeAccess && !loading && (
                        <ToolTip
                            content={'Your Cart'}
                            customPopoverClass={'ArrowPopover APToolTip'}
                            wrapperClass={'APTWrapper'}
                            placement={'bottom-center'}
                        >
                            {' '}
                            <CartPopover />
                        </ToolTip>
                    )}

                    <HelpDialog />

                    <ToolTip
                        content={'Notification Center'}
                        customPopoverClass={'ArrowPopover APToolTip'}
                        wrapperClass={'APTWrapper'}
                        placement={'bottom-center'}
                    >
                        <IconButton
                            customClass="NotificationIconButton"
                            onClick={handleNotificationsDialogOpen}
                        >
                            {notificationCount ? (
                                <Badge
                                    badgeContent={notificationCount}
                                    customClass={'NotificationBadge'}
                                >
                                    <DCBell />
                                </Badge>
                            ) : (
                                <DCBell />
                            )}
                        </IconButton>
                    </ToolTip>
                    <Div className="MenuToggleProfile">
                        <IconButton
                            customClass={`MenuToggleBtn ${menuOpen === true ? 'MenuOpen' : ''}`}
                            onClick={handleMenuOpen}
                        >
                            <Span />
                            <Span />
                            <Span />
                            <Span />
                            <Span />
                            <Span />
                            <Span />
                            <Span />
                            <Span />
                        </IconButton>

                        <IconButton
                            customClass="ProfileMenuBtn"
                            reference={userDropdownRef}
                            onClick={handleUserDropdownOpen}
                        >
                            <Image src={generateUserImage()} alt={''} displayName={''} />
                        </IconButton>
                        <UserDropdown
                            open={userDropdownState}
                            onClose={handleUserDropdownClose}
                            anchorEl={userDropdownRef}
                            userImage={generateUserImage()}
                        />
                    </Div>
                </Div>
            </TopBar>
        </Fragment>
    );
};

export default React.memo(MenuBar);
