import React, { useEffect, useMemo, useState } from 'react';
import { Dialog, Div, TopBar, H1, IconButton } from 'UIKit';
import DCCrossClose from 'Assets/icons/DCCrossClose';
import DCSchedule2Icon from 'Assets/icons/DCSchedule2';
import DCHalfCheck from 'Assets/icons/DCHalfCheck';
import DCDownArrow from 'Assets/icons/DCDownArrow';
import './style.scss';
import UseMediaQuery from 'UIKit/useMediaQuery';
import { setupScheduleCall } from 'Routes/initialSetup';

const ScheduleDemo = ({ open, handleClose, firstTimeRef }) => {
    const [showIframeOnly] = useState(false);

    const matches750 = UseMediaQuery('(max-width:750px)');
    const matches575 = UseMediaQuery('(max-width:575px)');

    const onClose = () => {
        handleClose();
    };

    const getFeatures = useMemo(() => {
        const features = [
            {
                icon: <DCHalfCheck />,
                title: 'DashClicks Software Platform Tour',
                subTitle: `Learn how our DashClicks software can help you generate more leads, close more deals, manage clients at scale and predictably grow your agency.`,
            },
            {
                icon: <DCHalfCheck />,
                title: 'DashClicks Fulfillment Overview',
                subTitle: `Learn how to efficiently utilize our white-label fulfillment services with insanely high margins. Leverage our team of expert marketers to help scale your fulfillment.`,
            },
            {
                icon: <DCHalfCheck />,
                title: 'DashClicks SaaS Reselling',
                subTitle: `Learn how you can resell the entire DashClicks platform all under your own brand to build an automated stream of recurring revenue.`,
            },
            {
                icon: <DCHalfCheck />,
                title: 'Agency Scaling Strategies',
                subTitle: `Learn the exact strategies on how agencies are using the DashClicks ecosystem to hit 5, 6 and even 7 figures in monthly recurring revenue.`,
            },
        ];

        const featuresJsx = (
            <Div className={'PDBIVCFeatures'}>
                {features.map((feature, _index) => {
                    return (
                        <Div className={'PDBIVCFBox'} key={`feature-${_index}`}>
                            <Div className={'PDBIVCFBTitle'}>
                                <Div className={'PDBIVCFBTIcon'}>{feature.icon}</Div>

                                <Div className={'PDBIVCFBTText'}>{feature.title}</Div>
                            </Div>
                            <Div className={'PDBIVCFBDes'}>{feature.subTitle}</Div>
                        </Div>
                    );
                })}
            </Div>
        );

        if (matches750) {
            return <>{showIframeOnly ? null : featuresJsx}</>;
        } else {
            return featuresJsx;
        }
    }, [matches750, showIframeOnly]);

    useEffect(() => {
        if (!firstTimeRef.current.current && firstTimeRef.current.count < 3) {
            firstTimeRef.current.count = firstTimeRef.current.count + 1;
            setupScheduleCall();
        }
        setupScheduleCall();
    }, [firstTimeRef]);

    return (
        <Dialog
            dialogType={'centerZoom sizeLg'}
            classes={{ row: 'PCDRow' }}
            open={open}
            onClose={onClose}
        >
            <Div className={'PurchaseDialog ScheduleDemo'}>
                <TopBar>
                    <Div className={'HeaderBar'}>
                        <Div className={'PDHLeft'}>
                            <Div className={'PDHIcon'}>
                                <DCSchedule2Icon />
                            </Div>
                            <H1 className={'HeaderBarTitle'}>Schedule A Demo</H1>
                        </Div>

                        <Div className={'PDHRight'}>
                            <Div className={'PDHRTitle'}>
                                <DCDownArrow /> Start Here
                            </Div>
                            <Div className={'HeaderBarClose'}>
                                <IconButton onClick={onClose}>
                                    <DCCrossClose />
                                </IconButton>
                            </Div>
                        </Div>
                    </Div>
                </TopBar>
                <Div className={'PDBody'}>
                    <Div className={'PDBInner '}>
                        <Div className={'PDBIVideoContent  PDBIVideoContent__ScheduleDemo '}>
                            <Div className={'PDBIVCVideo  PDBIVCVideoAvailable'}>
                                <iframe
                                    title="Schedule Demo"
                                    src="https://player.vimeo.com/video/757695043"
                                    frameBorder="0"
                                    webkitallowfullscreen=""
                                    mozallowfullscreen=""
                                />
                            </Div>
                            {getFeatures}
                            {matches575 ? (
                                <Div className={'PDBOrderDetail'}>
                                    <div className="wf_target wf_target_u4RpWadUbPPVP57u5ZnNcezf" />
                                </Div>
                            ) : null}
                        </Div>
                        {!matches575 ? (
                            <Div className={'PDBOrderDetail'}>
                                <div className="wf_target wf_target_u4RpWadUbPPVP57u5ZnNcezf" />
                            </Div>
                        ) : null}
                    </Div>
                </Div>
            </Div>
        </Dialog>
    );
};

export default ScheduleDemo;
