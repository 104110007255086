import React from 'react';

import { SVGIcon } from '../../UIKit/index';

export default function DCPNGFile(props) {
    return (
        <SVGIcon {...props} viewBox="0 0 512 512">
            <path
                d="m392 488h-272a48 48 0 0 1 -48-48v-368a48 48 0 0 1 48-48h224l96 96v320a48 48 0 0 1 -48 48z"
                fill="#005eff33"
            />
            <path d="m176 136h160v160h-160z" fill="#005eff50" />
            <path
                d="m72 360h368a0 0 0 0 1 0 0v80a48 48 0 0 1 -48 48h-272a48 48 0 0 1 -48-48v-80a0 0 0 0 1 0 0z"
                fill="#005eff"
            />
            <path d="m440 120h-48a48 48 0 0 1 -48-48v-48z" fill="#005eff50" />
            <g fill="#fff">
                <path d="m176 136h32v32h-32z" />
                <path d="m240 136h32v32h-32z" />
                <path d="m304 136h32v32h-32z" />
                <path d="m176 200h32v32h-32z" />
                <path d="m240 200h32v32h-32z" />
                <path d="m304 200h32v32h-32z" />
                <path d="m176 264h32v32h-32z" />
                <path d="m240 264h32v32h-32z" />
                <path d="m304 264h32v32h-32z" />
                <path d="m208 232h32v32h-32z" />
                <path d="m272 232h32v32h-32z" />
                <path d="m208 168h32v32h-32z" />
                <path d="m272 168h32v32h-32z" />
                <path d="m184 384h-16a8 8 0 0 0 -8 8v64a8 8 0 0 0 16 0v-24h8a24 24 0 0 0 0-48zm0 32h-8v-16h8a8 8 0 0 1 0 16z" />
                <path d="m344 416h-16a8 8 0 0 0 0 16h7.049c-2.252 9.217-8.236 16-15.049 16-8.673 0-16-10.991-16-24s7.327-24 16-24a10.71 10.71 0 0 1 4.589 1.057 8 8 0 0 0 6.822-14.473 26.6 26.6 0 0 0 -11.411-2.584c-17.645 0-32 17.944-32 40s14.355 40 32 40 32-17.944 32-40a8 8 0 0 0 -8-8z" />
                <path d="m264 384a8 8 0 0 0 -8 8v30.111l-16.845-33.689a8 8 0 0 0 -15.155 3.578v64a8 8 0 0 0 16 0v-30.111l16.845 33.689a8 8 0 0 0 15.155-3.578v-64a8 8 0 0 0 -8-8z" />
            </g>
        </SVGIcon>
    );
}
