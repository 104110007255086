import React from 'react';

import { SVGIcon } from '../../UIKit/index';

export default function DCPhoneFill(props) {
    return (
        <SVGIcon {...props} viewBox="0 0 512 512">
            <path d="M471.2,337.4c-31.1,0.1-62.1-4.9-91.7-14.6c-13.8-4.8-29.2-1.6-39.9,8.5l-58.1,43.9  c-62.3-31.3-112.9-81.9-144.2-144.2l42.7-56.6c10.8-10.8,14.6-26.7,10-41.2c-9.8-29.6-14.7-60.6-14.7-91.8C175.3,19,157.3,1,135,1  c0,0,0,0,0,0H40.8C18.6,1,0.5,19.1,0.5,41.3C0.8,301.2,211.3,511.7,471.2,512c22.3,0,40.3-18.1,40.3-40.3l0,0v-93.9  C511.5,355.5,493.5,337.4,471.2,337.4C471.2,337.4,471.2,337.4,471.2,337.4z" />
            <g>
                <path d="M480,216.2c-7.4,0-13.4-6-13.4-13.4C466.5,106.2,388.3,28,291.8,27.9c-7.4,0-13.4-6-13.4-13.4   S284.3,1,291.8,1l0,0c111.4,0.1,201.6,90.3,201.7,201.7C493.5,210.1,487.5,216.2,480,216.2L480,216.2z" />
                <path d="M372.4,216.2c-7.4,0-13.4-6-13.4-13.4c0-37.2-30.1-67.3-67.3-67.3l0,0c-7.4,0-13.4-6-13.4-13.4   c0-7.4,6-13.4,13.4-13.4c52,0,94.1,42.1,94.1,94.1C385.8,210.1,379.9,216.1,372.4,216.2C372.4,216.2,372.4,216.2,372.4,216.2z" />
            </g>
        </SVGIcon>
    );
}
