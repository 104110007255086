import React, { useLayoutEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';

import { Div } from '../../UIKit/index';

import './style.scss';

const AreaSplineChart = ({
    height,
    chartData,
    backgroundColor,
    lineColor,
    xAxis,
    yAxis,
    columnWidth,
}) => {
    const boxWidthRef = useRef();
    const [boxWidth, setWidth] = useState(null);
    const ChartOptions = {
        chart: {
            height: height || 200,
            type: 'areaspline',
            width: boxWidth,
            spacingTop: 15,
            spacingRight: 0,
            spacingBottom: 0,
            spacingLeft: 0,
            plotBorderWidth: 0,
            backgroundColor: backgroundColor || '#fff',
        },
        tooltip: {
            backgroundColor: 'rgb(255 255 255)',
            opacity: 1,
            useHTML: true,
            zIndex: 2,
        },
        title: {
            text: null,
        },
        subtitle: {
            text: null,
        },
        caption: {
            text: null,
        },
        plotOptions: {
            series: {
                shadow: {
                    enabled: true,
                },
                fillOpacity: 0.1,
                pointPlacement: 'on',
                lineWidth: 3,
                color: lineColor,
                marker: {
                    enabled: false,
                },
            },
        },
        navigator: {
            enabled: false,
        },
        yAxis: yAxis
            ? yAxis
            : {
                  visible: false,
              },
        xAxis: xAxis
            ? xAxis
            : {
                  visible: false,
              },
        credits: {
            enabled: false,
        },
        series: chartData || [],
    };
    useLayoutEffect(() => {
        /**
         * To measure box height, Using ref [useRef hooks] object to measure height and
         * setting it into states which is being used to set height of infinite scroll bar
         */
        function updateSize() {
            if (boxWidthRef.current) {
                const tempWidth = xAxis?.categories?.length * columnWidth || 150;
                if (tempWidth > boxWidthRef.current.clientWidth) {
                    setWidth(tempWidth);
                } else {
                    setWidth(boxWidthRef.current.clientWidth);
                }
            }
        }
        window.addEventListener('resize', updateSize);
        updateSize();
        return () => window.removeEventListener('resize', updateSize);
    }, [xAxis, chartData, columnWidth]);
    return (
        <Div className={'AreaSplineChart'} reference={boxWidthRef}>
            <Div className={'ASCInner'} style={{ width: boxWidth }}>
                <HighchartsReact highcharts={Highcharts} options={ChartOptions} />
            </Div>
        </Div>
    );
};

AreaSplineChart.propTypes = {
    height: PropTypes.number,
    chartData: PropTypes.arrayOf(PropTypes.object).isRequired,
    backgroundColor: PropTypes.string,
    lineColor: PropTypes.string,
    xAxis: PropTypes.object,
    yAxis: PropTypes.object,
    columnWidth: PropTypes.number,
};

export default AreaSplineChart;
