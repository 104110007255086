import React, { useState, useCallback, useEffect, Fragment, useRef } from 'react';
import DCCrossClose from 'Assets/icons/DCCrossClose';
import DCFillCheck from 'Assets/icons/DCFillCheck';
import DCDownload1Icon from 'Assets/icons/DCDownload1';
import { TopBar, Dialog, Div, IconButton, H1, Image, Button, ToolTip } from 'UIKit/index';
import './style.scss';
import usePubSub from '../PubSub';
import { formatNumberString } from 'Utils';
import { downloadPublicFilesFromWasabi } from 'Utils/downloadFile';

export default function ComparisonModal() {
    const [comparisonModal, setComparisonModal] = useState(false);
    const [data, setData] = useState([]);
    const [info, setInfo] = useState({});
    const [formattedData, setFormattedData] = useState([]);
    useEffect(() => {
        const arr = data;
        if (arr && arr.length) {
            const sections = arr[0]?.data?.map(obj => {
                return obj.title;
            });

            const output = [];
            sections?.forEach(section => {
                let tempFeatures = [];
                arr.forEach(obj => {
                    const sec = obj.data.findIndex(c => c.title === section);
                    if (sec !== -1) tempFeatures = obj.data[sec]?.features;
                });
                const labels = tempFeatures.map(obj => {
                    return { label: obj.label, tooltip: obj.Tooltips };
                });
                const tempArr = [];
                labels.forEach(objL => {
                    const { label, tooltip } = objL;
                    const newRes = [];
                    arr.forEach(objM => {
                        objM.data.forEach(obj => {
                            if (obj.title === section) {
                                const ind = obj.features.findIndex(f => f.label === label);
                                newRes.push(obj.features[ind].value);
                            }
                        });
                    });

                    tempArr.push({ label: label, values: newRes, tooltip });
                });

                output.push({
                    section: section,
                    features: tempArr,
                });
            });
            setFormattedData(output);
        }
    }, [data]);

    const handleSelect = useRef(null);
    const [currPrice, setCurrPrice] = useState(null);
    const [tempcurrPrice, setTempCurrPrice] = useState(null);
    const [textVis, setTextVis] = useState(false);
    const [setupFee, setSetupFee] = useState(false);
    const [loading, setLoading] = useState(true);
    const handleClose = () => {
        setComparisonModal(false);
    };
    usePubSub(
        'comparison_modal',
        useCallback(_data => {
            const {
                comparisonData,
                name,
                image,
                subscription,
                onSelect,
                currentPrice,
                textVisible,
                upgrade,
                isLoading,
            } = _data;
            handleSelect.current = onSelect;
            setLoading(isLoading);
            setCurrPrice(currentPrice);
            setInfo({ name, image, subscription });
            setData(comparisonData);
            setTextVis(textVisible || false);
            upgrade && setSetupFee(upgrade || false);
            setComparisonModal(true);
        }, []),
    );
    usePubSub('comparison_modal_close', handleClose);
    usePubSub(
        'comparison_modal_loading',
        useCallback(isLoading => {
            setLoading(isLoading);
        }, []),
    );

    const [isDownloading, setIsDownloading] = useState(false);

    const comparePlanDownloadLink = {
        SEO: 'https://open.mydashmetrics.com/store/case-studies/seo/SEO%20(Plan%20PDF).pdf',
        Google: 'https://open.mydashmetrics.com/store/case-studies/google-ads/Google%20Ads%20(Plan%20PDF).pdf',
        Facebook:
            'https://open.mydashmetrics.com/store/case-studies/facebook-ads/Facebook%20Ads%20(Plan%20PDF).pdf',
        Content:
            'https://open.mydashmetrics.com/store/case-studies/content/Content%20(Plan%20PDF).pdf',
        Social: 'https://open.mydashmetrics.com/store/case-studies/social-posts/Social%20Posts%20(Plan%20PDF).pdf',
        Listings:
            'https://open.mydashmetrics.com/store/case-studies/listings/Listings%20(Plan%20PDF).pdf',
        TikTok: 'https://open.mydashmetrics.com/store/case-studies/tiktok-ads/TikTok%20Ads%20(Plan%20PDF).pdf',
        Backlinks: 'https://open.mydashmetrics.com/store/case-studies/back-links/document.pdf',
    };

    const handleDownload = async () => {
        try {
            setIsDownloading(true);
            let filename = info.name.split(' ')[0];
            let url = comparePlanDownloadLink[filename];
            let pdfname = info.name;
            await downloadPublicFilesFromWasabi(url, 'application/pdf', pdfname);
            setIsDownloading(false);
        } catch (error) {}
    };

    return (
        <Dialog dialogType={'fullWidth'} open={comparisonModal} onClose={handleClose}>
            <Div className={'ComparisonDialog'}>
                <TopBar>
                    <Div className={'HeaderBar'}>
                        <H1 className={'HeaderBarTitle'}>Compare plans</H1>
                        <Div className={'HeaderBarClose'}>
                            <ToolTip content={'Download White Labeled PDF'}>
                                <IconButton onClick={handleDownload} loading={isDownloading}>
                                    <DCDownload1Icon />
                                </IconButton>
                            </ToolTip>
                            <IconButton onClick={handleClose}>
                                <DCCrossClose />
                            </IconButton>
                        </Div>
                    </Div>
                </TopBar>
                <Div className={'CDBody'}>
                    <Div className={'CDBInner'}>
                        <Div className={'CDBPlans'}>
                            <Div className={'CDBPCol'}>
                                <Div className={'CDBPApp'}>
                                    <Div className={'CDBPAIcon'}>
                                        <Image src={info.image} displayName={''} />
                                    </Div>
                                    <Div className={'CDBPAInfo'}>
                                        <Div className={'CDBPAITitle'}>{info.name}</Div>
                                        <Div className={'CDBPAISTitle'}>{info.subscription}</Div>
                                    </Div>
                                </Div>
                            </Div>

                            {data?.map((sec, index) => {
                                return (
                                    <Div
                                        className={'CDBPCol'}
                                        style={{ borderColor: sec.color }}
                                        key={`header-${index + 1}`}
                                    >
                                        <Div className={'CDBPCPlanInfo'}>
                                            <Div
                                                className={'CDBPCPlanName'}
                                                style={{ color: sec.color }}
                                            >
                                                {sec.nickname}
                                            </Div>
                                            <Div className={'CDBPCPlanPrice'}>
                                                ${formatNumberString(sec.unit_amount / 100)}/
                                                {sec.interval === 'month' ? 'mo' : 'yr'}
                                            </Div>
                                        </Div>
                                        {!setupFee ? (
                                            <Div className={'CDBPCPlanInfo CDBPCSetup'}>
                                                <Div className={'CDBPCPlanName'}>Setup Fee</Div>
                                                <Div className={'CDBPCPlanPrice'}>
                                                    ${formatNumberString(sec.setup_fee / 100)}/setup
                                                </Div>
                                            </Div>
                                        ) : (
                                            ''
                                        )}
                                        <Button
                                            buttonType="BlueFillBtn"
                                            disabled={currPrice === sec.id}
                                            loading={tempcurrPrice === sec.id && loading}
                                            onClick={() => {
                                                if (handleSelect.current) {
                                                    handleSelect.current(sec.id);
                                                    setTempCurrPrice(sec.id);
                                                }
                                            }}
                                        >
                                            {currPrice === sec.id
                                                ? 'Active Plan'
                                                : textVis
                                                ? 'Change Plan'
                                                : 'Add to Cart'}
                                        </Button>
                                    </Div>
                                );
                            })}
                        </Div>
                        <Div className={'CDBPInfo'}>
                            <Div className={'CDBPIGroup'}>
                                {formattedData?.map((sec, mIndex) => {
                                    return (
                                        <Fragment key={`sec-${mIndex + 1}`}>
                                            <Div className={'CDBPIRow'}>
                                                <Div className={'CDBPIRCol CDBPIRTitle'}>
                                                    {sec.section}
                                                </Div>
                                                <Div className={'CDBPIRCol'} />
                                                <Div className={'CDBPIRCol'} />
                                                <Div className={'CDBPIRCol'} />
                                                <Div className={'CDBPIRCol'} />
                                            </Div>
                                            {sec.features.map((feature, ind) => {
                                                return (
                                                    <Div
                                                        className={'CDBPIRow'}
                                                        key={`feature-${ind + 1}`}
                                                    >
                                                        <Div className={'CDBPIRCol'}>
                                                            <ToolTip content={feature.tooltip}>
                                                                {feature.label}
                                                            </ToolTip>
                                                        </Div>
                                                        {feature.values.map((val, _ind) => {
                                                            return (
                                                                <Fragment key={`val-${_ind + 1}`}>
                                                                    {typeof val === 'boolean' &&
                                                                        val === true && (
                                                                            <Div
                                                                                className={
                                                                                    'CDBPIRCol CDBPIRCIcon'
                                                                                }
                                                                            >
                                                                                <DCFillCheck
                                                                                    style={{
                                                                                        color: data[
                                                                                            mIndex
                                                                                        ]?.color,
                                                                                    }}
                                                                                />
                                                                            </Div>
                                                                        )}
                                                                    {typeof val === 'boolean' &&
                                                                        val === false && (
                                                                            <Div
                                                                                className={
                                                                                    'CDBPIRCol'
                                                                                }
                                                                            >
                                                                                ---
                                                                            </Div>
                                                                        )}
                                                                    {typeof val === 'string' && (
                                                                        <Div
                                                                            className={'CDBPIRCol'}
                                                                        >
                                                                            {val}
                                                                        </Div>
                                                                    )}
                                                                </Fragment>
                                                            );
                                                        })}
                                                    </Div>
                                                );
                                            })}
                                        </Fragment>
                                    );
                                })}
                            </Div>
                        </Div>
                    </Div>
                </Div>
            </Div>
        </Dialog>
    );
}
