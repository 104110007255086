import React from 'react';

import { SVGIcon } from '../../UIKit/index';

export default function DCPhoneNumber(props) {
    return (
        <SVGIcon {...props} viewBox="0 0 512.00068 512">
            <path d="m291.441406 0c-5.523437 0-10 4.476562-10 10s4.476563 10 10 10c110.589844 0 200.558594 89.972656 200.558594 200.558594 0 5.523437 4.480469 10 10 10 5.523438 0 10-4.476563 10-10 0-121.613282-98.941406-220.558594-220.558594-220.558594zm0 0" />
            <path d="m441.84375 230.5625c5.523438 0 10-4.480469 10-10 0-88.449219-71.957031-160.40625-160.402344-160.40625-5.523437 0-10 4.480469-10 10 0 5.523438 4.476563 10 10 10 77.417969 0 140.402344 62.984375 140.402344 140.40625 0 5.519531 4.476562 10 10 10zm0 0" />
            <path d="m371.675781 220.5625c0 5.519531 4.476563 10 10 10 5.523438 0 10-4.480469 10-10 0-55.269531-44.964843-100.234375-100.234375-100.234375-5.523437 0-10 4.476563-10 9.996094 0 5.523437 4.476563 10 10 10 44.242188 0 80.234375 35.992187 80.234375 80.238281zm0 0" />
            <path d="m281.441406 190.484375c0 5.519531 4.476563 9.996094 10 9.996094 11.070313 0 20.078125 9.007812 20.078125 20.082031 0 5.519531 4.476563 10 10 10 5.519531 0 10-4.480469 10-10 0-22.101562-17.980469-40.078125-40.078125-40.078125-5.523437 0-10 4.476563-10 10zm0 0" />
            <path d="m187.433594 324.679688c0 5.523437-4.476563 10-10 10-5.523438 0-10-4.476563-10-10 0-5.519532 4.476562-10 10-10 5.523437 0 10 4.480468 10 10zm0 0" />
            <path d="m274.03125 499.859375c45.535156 22.316406 100.316406 13.03125 136.324219-22.976563l20.675781-20.675781c11.226562-11.214843 11.230469-29.382812.003906-40.605469l-79.890625-79.882812c-11.207031-11.222656-29.378906-11.238281-40.597656-.003906l-33.945313 33.949218c-6.648437 6.648438-16.910156 8.003907-24.394531 3.226563-12.203125-7.800781-24.074219-16.402344-35.28125-25.566406-4.277343-3.496094-10.574219-2.863281-14.070312 1.410156-3.496094 4.277344-2.863281 10.578125 1.410156 14.070313 11.808594 9.65625 24.3125 18.71875 37.175781 26.9375 15.332032 9.792968 36.066406 7.296874 49.300782-5.9375l33.953124-33.953126c3.394532-3.398437 8.894532-3.40625 12.304688.003907l79.894531 79.886719c3.402344 3.40625 3.410157 8.914062 0 12.316406l-20.679687 20.683594c-29.996094 29.996093-75.558594 37.695312-113.382813 19.160156-138.566406-67.949219-213.957031-175.691406-252.800781-254.113282-18.582031-37.503906-10.867188-82.894531 19.199219-112.953124l20.226562-20.222657c3.390625-3.398437 8.894531-3.410156 12.300781.003907l79.902344 79.898437c3.382813 3.378906 3.417969 8.886719-.007812 12.304687l-33.945313 33.949219c-13.234375 13.234375-15.730469 33.96875-5.9375 49.304688 5.769531 9.027343 11.988281 17.933593 18.488281 26.46875 3.34375 4.394531 9.617188 5.242187 14.011719 1.898437s5.246094-9.617187 1.898438-14.015625c-6.164063-8.097656-12.070313-16.550781-17.546875-25.117187-4.777344-7.488282-3.421875-17.75 3.226562-24.398438l33.941406-33.941406c11.222657-11.207031 11.234376-29.382812.007813-40.597656l-79.894531-79.890625c-11.203125-11.222657-29.378906-11.238281-40.59375-.003907l-20.21875 20.21875c-36.140625 36.128907-45.375 90.773438-22.980469 135.972657 40.222656 81.199219 118.308594 192.769531 261.921875 263.191406zm0 0" />
        </SVGIcon>
    );
}
