import React from 'react';

import { SVGIcon } from '../../UIKit/index';

export default function DCChatStar(props) {
    return (
        <SVGIcon {...props} viewBox="0 0 512 460">
            <g>
                <path d="M469.4,189.5h-75.2v-144c0-22-17.9-39.9-39.9-39.9H52.3c-22,0-39.9,17.9-39.9,39.9v220.2   c0,22,17.9,39.9,39.9,39.9h29.3l21.3,85.2c2,7.8,9.9,12.6,17.7,10.7c3-0.8,5.8-2.5,7.7-4.9l75-90.9h39.3v57.6c0,17.1,13.9,31,31,31   h98.7l47.1,57c5.1,6.2,14.4,7.1,20.6,2c2.4-2,4.1-4.7,4.9-7.7l12.8-51.3h11.6c17.1,0,31-13.9,31-31V220.5   C500.4,203.4,486.5,189.5,469.4,189.5L469.4,189.5z M196.5,276.3c-4.4,0-8.5,2-11.3,5.3l-61,73.9l-17-68.1   c-1.6-6.5-7.5-11.1-14.2-11.1H52.3c-5.9,0-10.6-4.8-10.6-10.6V45.5c0-5.9,4.8-10.6,10.6-10.6h301.9c5.9,0,10.6,4.8,10.6,10.6v220.2   c0,5.9-4.8,10.6-10.6,10.6H196.5z M471.1,363.2c0,1-0.8,1.7-1.7,1.7h0h-23c-6.7,0-12.6,4.6-14.2,11.1l-8.6,34.2l-33-40   c-2.8-3.4-6.9-5.3-11.3-5.3H273.7c-1,0-1.7-0.8-1.7-1.7v-57.6h82.2c22,0,39.9-17.9,39.9-39.9v-46.9h75.2c1,0,1.7,0.8,1.7,1.7   c0,0,0,0,0,0L471.1,363.2z" />
                <path d="M250.1,238.7c-2.4,0-4.7-0.6-6.8-1.7l-40-21l-40,21c-7.2,3.8-16,1-19.8-6.2   c-1.5-2.8-2-6.1-1.5-9.3l7.6-44.6l-32.4-31.5c-5.8-5.6-5.9-14.9-0.3-20.7c2.3-2.3,5.2-3.8,8.4-4.3l44.7-6.5l20-40.5   c3.6-7.3,12.4-10.2,19.6-6.6c2.9,1.4,5.2,3.8,6.6,6.6l20,40.5l44.7,6.5c8,1.2,13.5,8.6,12.4,16.6c-0.5,3.2-2,6.1-4.3,8.4L256.9,177   l7.6,44.6c1.4,8-4,15.5-12,16.9C251.7,238.6,250.9,238.7,250.1,238.7L250.1,238.7z M159,145.2l16.6,16.2c3.5,3.4,5,8.2,4.2,13   l-3.9,22.9l20.6-10.8c4.3-2.2,9.4-2.2,13.6,0l20.6,10.8l-3.9-22.9c-0.8-4.8,0.8-9.6,4.2-13l16.6-16.2l-23-3.3   c-4.8-0.7-8.9-3.7-11-8L203.3,113L193,133.8c-2.1,4.3-6.3,7.3-11,8L159,145.2z" />
            </g>
        </SVGIcon>
    );
}
