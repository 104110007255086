import React from 'react';

import { SVGIcon } from '../../UIKit/index';

export default function DCBoxNumbers(props) {
    return (
        <SVGIcon {...props} viewBox="0 0 512 512">
            <g>
                <g>
                    <g>
                        <path d="M262.83,158.64h-5.78V88.86c0.01-5.51-4.44-9.98-9.95-9.99c-3.54-0.01-6.82,1.86-8.62,4.92     l-13.98,23.68c-2.8,4.76-1.21,10.88,3.55,13.68c2.76,1.62,6.12,1.82,9.06,0.55v36.95h-5.78c-5.51-0.37-10.27,3.8-10.63,9.3     c-0.37,5.51,3.8,10.27,9.3,10.63c0.44,0.03,0.89,0.03,1.33,0h31.5c5.51,0.37,10.27-3.8,10.63-9.3c0.37-5.51-3.8-10.27-9.3-10.63     C263.72,158.61,263.28,158.61,262.83,158.64z" />
                    </g>
                </g>
                <g>
                    <g>
                        <path d="M152.33,393.09h-21.62c14.23-12.57,23.37-29.9,25.72-48.74     c0.92-16.21-11.48-30.1-27.69-31.01c-1.11-0.06-2.22-0.06-3.33,0c-17.13,0-31.01,13.89-31.01,31.01     c-0.37,5.51,3.8,10.27,9.3,10.63c5.51,0.37,10.27-3.8,10.63-9.3c0.03-0.44,0.03-0.89,0-1.33c0-6.12,4.96-11.08,11.08-11.08     c9.95,0,11.08,6.34,11.08,11.08c0,18.5-28.2,43.46-38.06,50.73c-4.44,3.27-5.39,9.53-2.11,13.97c1.88,2.55,4.86,4.06,8.03,4.06     h48.01c5.51-0.37,9.67-5.13,9.3-10.64c-0.33-4.99-4.31-8.97-9.3-9.3L152.33,393.09z" />
                    </g>
                </g>
                <g>
                    <g>
                        <path d="M391.07,343.39c-0.02-16.49-13.39-29.86-29.88-29.88h-17.28c-5.51,0.37-9.67,5.13-9.3,10.64     c0.33,4.99,4.31,8.97,9.3,9.3h17.28c5.48,0.01,9.92,4.46,9.91,9.94c-0.01,4.9-3.6,9.07-8.44,9.8h-1.46h-17.28     c-5.51,0.37-9.67,5.13-9.3,10.64c0.33,4.99,4.31,8.97,9.3,9.3h6.96c0.56,0.1,1.13,0.15,1.71,0.15h8.62h1.46     c5.42,0.82,9.15,5.87,8.33,11.29c-0.73,4.85-4.89,8.44-9.8,8.44h-17.28c-5.51,0.37-9.67,5.13-9.3,10.64     c0.33,4.99,4.31,8.97,9.3,9.3h17.28c11.31-22.58,21.29-45.8,29.88-69.54V343.39z" />
                    </g>
                </g>
                <g>
                    <g>
                        <path d="M461.14,245.33h-82.85c6.51-8.63,10.02-19.14,10.01-29.95V170.8     c-0.37-5.51-5.13-9.67-10.63-9.3c-4.99,0.33-8.97,4.31-9.3,9.3v44.57c-0.01,16.54-13.41,29.94-29.95,29.95H172.7     c-16.54-0.01-29.94-13.41-29.95-29.95V49.71c0-16.55,13.41-29.96,29.95-29.97h165.68c16.54,0.01,29.94,13.41,29.95,29.95v37.95     c-0.37,5.51,3.8,10.27,9.3,10.63c5.51,0.37,10.27-3.8,10.63-9.3c0.03-0.44,0.03-0.89,0-1.33V49.71     c-0.02-27.56-22.36-49.89-49.91-49.91H172.7c-27.56,0.02-49.89,22.36-49.91,49.91v165.66c-0.01,10.81,3.5,21.32,10.01,29.95     H49.95c-27.55,0.02-49.87,22.34-49.91,49.89V460.9c0.02,27.56,22.36,49.89,49.91,49.91h165.66c15.69,0,30.46-7.39,39.88-19.94     c9.41,12.55,24.19,19.94,39.88,19.94h165.68c27.56-0.02,49.89-22.36,49.91-49.91V295.21C510.92,267.7,488.65,245.4,461.14,245.33     z M245.56,460.9c-0.01,16.54-13.41,29.94-29.95,29.95H49.95c-16.55,0-29.96-13.41-29.97-29.95V295.21     c0.01-16.54,13.41-29.94,29.95-29.95h165.68c16.54,0.01,29.94,13.41,29.95,29.95L245.56,460.9z M255.55,265.31L255.55,265.31     L255.55,265.31z M491.09,460.9c-0.01,16.54-13.41,29.94-29.95,29.95H295.45c-16.54-0.01-29.94-13.41-29.95-29.95V295.21     c0.01-16.54,13.41-29.94,29.95-29.95h165.68c16.54,0.01,29.94,13.41,29.95,29.95L491.09,460.9z" />
                    </g>
                </g>
                <g>
                    <g>
                        <path d="M368.72,119.94c-3.9-3.89-10.21-3.89-14.1,0.01c-3.89,3.9-3.89,10.21,0.01,14.1     c3.9,3.89,10.21,3.89,14.1-0.01c1.86-1.87,2.91-4.39,2.92-7.03C371.64,124.35,370.59,121.81,368.72,119.94z" />
                    </g>
                </g>
            </g>
        </SVGIcon>
    );
}
