const templateActions = {
    SET_TEMPLATE: 'SET_TEMPLATE',

    SET_ALL_TEMPLATES: 'SET_ALL_TEMPLATES',
    SET_EMAIL_TEMPLATES: 'SET_EMAIL_TEMPLATES',
    SET_EDOC_TEMPLATES: 'SET_EDOC_TEMPLATES',
    SET_SMS_TEMPLATES: 'SET_SMS_TEMPLATES',

    SET_INITIAL_ALL_TEMPLATE_LOAD: 'SET_INITIAL_ALL_TEMPLATE_LOAD',
    SET_INITIAL_EMAIL_LOAD: 'SET_INITIAL_EMAIL_LOAD',
    SET_INITIAL_SMS_LOAD: 'SET_INITIAL_SMS_LOAD',
    SET_INITIAL_EDOC_LOAD: 'SET_INITIAL_EDOC_LOAD',

    SET_TOTAL_ALL_TEMPLATE_PAGES: 'SET_TOTAL_ALL_TEMPLATE_PAGES',
    SET_TOTAL_EMAIL_TEMPLATE_PAGES: 'SET_TOTAL_EMAIL_TEMPLATE_PAGES',
    SET_TOTAL_SMS_TEMPLATE_PAGES: 'SET_TOTAL_SMS_TEMPLATE_PAGES',
    SET_TOTAL_EDOC_TEMPLATE_PAGES: 'SET_TOTAL_EDOC_TEMPLATE_PAGES',

    SET_ALL_TEMPLATES_RESULTS: 'SET_ALL_TEMPLATES_RESULTS',
    SET_EMAIL_TEMPLATES_RESULTS: 'SET_EMAIL_TEMPLATES_RESULTS',
    SET_EDOC_TEMPLATES_RESULTS: 'SET_EDOC_TEMPLATES_RESULTS',
    SET_SMS_TEMPLATES_RESULTS: 'SET_SMS_TEMPLATES_RESULTS',

    SET_ALL_TEMPLATE_PAGE: 'SET_ALL_TEMPLATE_PAGE',
    SET_EMAIL_TEMPLATE_PAGE: 'SET_EMAIL_TEMPLATE_PAGE',
    SET_SMS_TEMPLATE_PAGE: 'SET_SMS_TEMPLATE_PAGE',
    SET_EDOC_TEMPLATE_PAGE: 'SET_EDOC_TEMPLATE_PAGE',

    SET_ALL_TEMPLATE_PAGE_LIMIT: 'SET_ALL_TEMPLATE_PAGE_LIMIT',
    SET_EMAIL_TEMPLATE_PAGE_LIMIT: 'SET_EMAIL_TEMPLATE_PAGE_LIMIT',
    SET_SMS_TEMPLATE_PAGE_LIMIT: 'SET_SMS_TEMPLATE_PAGE_LIMIT',
    SET_EDOC_TEMPLATE_PAGE_LIMIT: 'SET_EDOC_TEMPLATE_PAGE_LIMIT',

    SET_ALL_TEMPLATE_T_PAGE: 'SET_ALL_TEMPLATE_T_PAGE',
    SET_EMAIL_TEMPLATE_T_PAGE: 'SET_EMAIL_TEMPLATE_T_PAGE',
    SET_SMS_TEMPLATE_T_PAGE: 'SET_SMS_TEMPLATE_T_PAGE',
    SET_EDOC_TEMPLATE_T_PAGE: 'SET_EDOC_TEMPLATE_T_PAGE',

    SET_SEARCH_TEMPLATE: 'SET_SEARCH_TEMPLATE',
    SET_TEMPLATE_COLUMNS: 'SET_TEMPLATE_COLUMNS',

    SET_SORT_TEMPLATE: 'SET_SORT_TEMPLATE',
};

export default templateActions;
