import React from 'react';
import PropTypes from 'prop-types';
export default function List({ props, children, component }) {
    if (component === 'ol') {
        return <ol {...props}>{children}</ol>;
    }
    return <ul {...props}>{children}</ul>;
}

List.propTypes = {
    children: PropTypes.element,
    component: PropTypes.string,
};
