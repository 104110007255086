import React from 'react';

import { SVGIcon } from '../../UIKit/index';

export default function DCWebView(props) {
    return (
        <SVGIcon {...props} viewBox="0 0 512 512">
            <g>
                <path d="M467,0.5H45c-24.8,0-45,20.2-45,45v422c0,24.8,20.2,45,45,45h422c24.8,0,45-20.2,45-45v-422   C512,20.7,491.8,0.5,467,0.5z M45,30.5h422c8.3,0,15,6.7,15,15v75H30v-75C30,37.2,36.7,30.5,45,30.5z M467,482.5H45   c-8.3,0-15-6.7-15-15v-317h452v317C482,475.8,475.3,482.5,467,482.5z" />
                <circle cx="106" cy="75.5" r="15" />
                <circle cx="166" cy="75.5" r="15" />
                <circle cx="226" cy="75.5" r="15" />
                <path d="M407,180.5H106c-8.3,0-15,6.7-15,15v91c0,8.3,6.7,15,15,15h301c8.3,0,15-6.7,15-15v-91   C422,187.2,415.3,180.5,407,180.5z M392,271.5H121v-61h271V271.5z" />
                <path d="M256,331.5H106c-8.3,0-15,6.7-15,15v91c0,8.3,6.7,15,15,15h150c8.3,0,15-6.7,15-15v-91   C271,338.2,264.3,331.5,256,331.5z M241,422.5H121v-61h120V422.5z" />
                <path d="M407,331.5h-91c-8.3,0-15,6.7-15,15v91c0,8.3,6.7,15,15,15h91c8.3,0,15-6.7,15-15v-91   C422,338.2,415.3,331.5,407,331.5z M392,422.5h-61v-61h61V422.5z" />
            </g>
        </SVGIcon>
    );
}
