import React from 'react';

import { SVGIcon } from '../../UIKit/index';

export default function DCWarm(props) {
    return (
        <SVGIcon {...props} viewBox="0 0 22.297 21.629">
            <g transform="translate(0)">
                <path
                    d="M17.838,265.449a3.357,3.357,0,0,0-2.605,1.353,2.223,2.223,0,0,1-3.708,0,3.356,3.356,0,0,0-2.605-1.353A3.357,3.357,0,0,0,6.314,266.8a2.328,2.328,0,0,1-1.854,1,2.328,2.328,0,0,1-1.854-1A3.358,3.358,0,0,0,0,265.449v1.958a2.327,2.327,0,0,1,1.854,1,3.357,3.357,0,0,0,2.605,1.353A3.357,3.357,0,0,0,7.065,268.4a2.327,2.327,0,0,1,1.854-1,2.328,2.328,0,0,1,1.854,1,3.357,3.357,0,0,0,2.605,1.353,3.358,3.358,0,0,0,2.606-1.353,2.223,2.223,0,0,1,3.708,0,3.357,3.357,0,0,0,2.605,1.353V267.8a2.328,2.328,0,0,1-1.854-1A3.358,3.358,0,0,0,17.838,265.449Zm0,0"
                    transform="translate(0 -248.127)"
                />
                <path
                    d="M17.838,88.484a3.357,3.357,0,0,0-2.605,1.353,2.223,2.223,0,0,1-3.708,0,3.357,3.357,0,0,0-2.605-1.353,3.357,3.357,0,0,0-2.605,1.353,2.328,2.328,0,0,1-1.854,1,2.328,2.328,0,0,1-1.854-1A3.358,3.358,0,0,0,0,88.484v1.958a2.328,2.328,0,0,1,1.854,1A3.357,3.357,0,0,0,4.46,92.791a3.357,3.357,0,0,0,2.605-1.353,2.328,2.328,0,0,1,1.854-1,2.328,2.328,0,0,1,1.854,1,3.356,3.356,0,0,0,2.605,1.353,3.358,3.358,0,0,0,2.606-1.353,2.223,2.223,0,0,1,3.708,0A3.356,3.356,0,0,0,22.3,92.791V90.834a2.328,2.328,0,0,1-1.854-1A3.358,3.358,0,0,0,17.838,88.484Zm0,0"
                    transform="translate(0 -82.71)"
                />
                <path
                    d="M17.838,176.969a3.357,3.357,0,0,0-2.605,1.353,2.223,2.223,0,0,1-3.708,0,3.357,3.357,0,0,0-2.605-1.353,3.357,3.357,0,0,0-2.605,1.353,2.328,2.328,0,0,1-1.854,1,2.328,2.328,0,0,1-1.854-1A3.358,3.358,0,0,0,0,176.969v1.958a2.328,2.328,0,0,1,1.854,1,3.357,3.357,0,0,0,2.605,1.353,3.357,3.357,0,0,0,2.605-1.353,2.328,2.328,0,0,1,1.854-1,2.328,2.328,0,0,1,1.854,1,3.357,3.357,0,0,0,2.605,1.353,3.358,3.358,0,0,0,2.606-1.353,2.223,2.223,0,0,1,3.708,0,3.357,3.357,0,0,0,2.605,1.353v-1.958a2.328,2.328,0,0,1-1.854-1A3.358,3.358,0,0,0,17.838,176.969Zm0,0"
                    transform="translate(0 -165.421)"
                />
                <path d="M20.444,1.353a3.185,3.185,0,0,0-5.211,0,2.329,2.329,0,0,1-1.854,1,2.328,2.328,0,0,1-1.854-1A3.356,3.356,0,0,0,8.919,0,3.356,3.356,0,0,0,6.314,1.353a2.328,2.328,0,0,1-1.854,1,2.328,2.328,0,0,1-1.854-1A3.357,3.357,0,0,0,0,0V1.958a2.328,2.328,0,0,1,1.854,1A3.357,3.357,0,0,0,4.46,4.307,3.356,3.356,0,0,0,7.065,2.954a2.328,2.328,0,0,1,1.854-1,2.328,2.328,0,0,1,1.854,1,3.356,3.356,0,0,0,2.605,1.353,3.357,3.357,0,0,0,2.606-1.353,2.223,2.223,0,0,1,3.708,0A3.356,3.356,0,0,0,22.3,4.307V2.349A2.328,2.328,0,0,1,20.444,1.353Zm0,0" />
            </g>
        </SVGIcon>
    );
}
