import { StepperDialog } from 'Components';
import Congratulations from 'Components/StepperDialog/Steps/congratulations';
import React, { Fragment, useState, useCallback, useEffect, useRef } from 'react';
import CancellationReason from 'Components/CancelSubscription/steps/cancellationReason';
import SCStep2 from './SCStep2';
import SCStep22 from './SCStep22';
import usePubSub from 'Hooks/PubSub';
import { useQueryClient } from 'react-query';
import { getAccountDetails } from 'Utils/localStorageUtils';
import useErrorDialog from 'Hooks/ErrorDialog/useErrorDialog';

import { getAccount } from 'apis/settings/index.api';
import { configUsageData } from 'Routes';
// interface CancellationProps {
//     open: boolean;
//     close: () => void;
//     type: { service: boolean, serviceName: string, plan: any };
// }
const CancelSubscription = ({
    open,
    close,
    type,
    downGradeMessage,
    setIsDownGraded,
    downGradePromt,
    setCurrentTier,
}) => {
    const queryClient = useQueryClient();
    const { publish } = usePubSub();
    const firstRender = useRef(true);
    const showErrorDialog = useErrorDialog();
    const [dataDomain, setDataDomain] = useState({});
    const getData = useCallback(async () => {
        try {
            const response = await getAccount(getAccountDetails()?.id);
            if (response?.success) {
                setDataDomain({ ...response.data.branding, domain: response.data.domain });
            }
        } catch (err) {
            showErrorDialog(err);
        }
    }, [showErrorDialog]);

    useEffect(() => {
        if (firstRender.current) {
            getData();
            configUsageData();
            firstRender.current = false;
        }
    }, [getData]);

    const [mainSteps] = useState([
        {
            id: 1,
            component: CancellationReason,
            props: {
                parent: 1,
            },
            title: `Cancel Subscription`,
            description: `Select the reasons for cancelling your subscription.`,
            path: 'cancellation-reason',
        },
        {
            id: 1,
            component: type.service && type.serviceName !== 'Software' ? SCStep2 : SCStep22,
            props: {
                parent: 1,
            },
            title: `Cancellation Review & Confirmation`,
            description: `Review your cancellation and make any final changes needed to move forward.`,
            path: 'cancellation-confirmation',
        },
        {
            id: 100,
            component: Congratulations,
            props: {
                parent: 100,
                onFinish: type.service
                    ? () => {
                          queryClient.invalidateQueries(['subscriptions']),
                              queryClient.invalidateQueries(['dashclicks-myorders']);
                      }
                    : () => {
                          publish('settings-updated'), setIsDownGraded && setIsDownGraded(true);
                      },
                message: `${
                    type.service
                        ? 'Your service has deactivated successfully'
                        : 'We are processing your downgrade and will notify once its done.'
                }`,
                title: 'Downgrade In Progress',
            },
            title: 'Completed',
            description: `Your  ${
                type.service
                    ? 'service has deactivated successfully'
                    : 'subscription has been downgraded successfully.'
            }`,
            path: 'finish',
            hideContinue: true,
            hideBack: true,
        },
    ]);
    const [mainSteps2] = useState([
        {
            id: 1,
            component: type.service && type.serviceName !== 'Software' ? SCStep2 : SCStep22,
            props: {
                parent: 1,
            },
            title: `Cancellation Review & Confirmation`,
            description: `Review your cancellation and make any final changes needed to move forward.`,
            path: 'cancellation-confirmation',
        },
        {
            id: 100,
            component: Congratulations,
            props: {
                parent: 100,
                onFinish: type.service
                    ? () => queryClient.invalidateQueries(['subscriptions'])
                    : () => {
                          publish('settings-updated'), setIsDownGraded && setIsDownGraded(true);
                      },
                message: `${
                    type.service
                        ? 'Your service has disconnected successfully'
                        : 'We are processing your downgrade and will notify once its done.'
                }`,
                title: 'Downgrade In Progress',
            },
            title: 'Completed',
            description: `Your ${
                type.service
                    ? 'service has disconnected successfully'
                    : 'subscription has been downgraded successfully.'
            }`,
            path: 'finish',
            hideContinue: true,
            hideBack: true,
        },
    ]);

    return (
        <Fragment>
            <StepperDialog
                steps={downGradePromt ? mainSteps2 : mainSteps}
                open={open}
                onClose={close}
                childProps={{ type, dataDomain, downGradeMessage, downGradePromt, setCurrentTier }}
            />
        </Fragment>
    );
};

export default CancelSubscription;
