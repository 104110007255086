import React, { Fragment, useCallback, useRef, useState, useEffect } from 'react';

import DCSettings from '../../Assets/icons/DCSettings';
import { DropdownOptions, IconButton } from '../../UIKit/index';

import './style.scss';

const TableAction = ({
    generateOptions,
    width,
    viewHeight,
    hideDropdown,
    setHideDropdown,
    buttonClass = '',
    dropdownOptionsClass = '',
}) => {
    const tableActionButtonRef = useRef();
    const [tableActionDropdown, setTableActionDropdown] = useState(false);

    const handleTableActionDropdownToggle = useCallback(event => {
        setTableActionDropdown(prevOpen => !prevOpen);
        event.stopPropagation();
    }, []);

    const handleTableActionDropdownClose = useCallback(() => {
        setTableActionDropdown(false);
    }, []);

    useEffect(() => {
        if (hideDropdown) {
            setTableActionDropdown(false);
            setHideDropdown(false);
        }
    }, [setHideDropdown, hideDropdown]);

    return (
        <Fragment>
            <IconButton
                buttonType={
                    'circleBtn ' + (tableActionDropdown === true ? 'open' : '') + buttonClass
                }
                reference={tableActionButtonRef}
                onClick={handleTableActionDropdownToggle}
            >
                <DCSettings />
            </IconButton>
            <DropdownOptions
                customClass={`TableActionDropdown ${dropdownOptionsClass}`}
                options={generateOptions}
                onClose={handleTableActionDropdownClose}
                wrapperWidth={width || 100}
                open={tableActionDropdown}
                reference={tableActionButtonRef}
                viewHeight={viewHeight || 160}
                itemSize={40}
                placement={'bottom-center'}
            />
        </Fragment>
    );
};

export default TableAction;
