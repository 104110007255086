import './style.scss';

import React from 'react';

import { Span } from '../index';
import PropTypes from 'prop-types';
const LinkButton = ({
    iconName,
    iconColor,
    children,
    buttonClass,
    buttonType,
    iconClass,
    titleClass,
    iconPlacement,
    disabled,
    onClick,
    reference,
    style,
    href,
    target,
}) => {
    return (
        <a
            href={href}
            target={target}
            className={
                'mainButton ' +
                (buttonClass ? buttonClass : '') +
                (iconName ? '' : ' withOutIconBtn') +
                ' ' +
                (buttonType ? buttonType : '') +
                ' ' +
                (iconPlacement ? iconPlacement : 'left') +
                ' ' +
                (disabled ? 'disabled' : ' ')
            }
            disabled={disabled}
            onClick={onClick}
            ref={reference}
            style={style}
        >
            {iconName ? (
                <Span
                    className={'buttonIcon ' + (iconClass ? iconClass : '')}
                    style={{ color: iconColor }}
                >
                    {iconName}
                </Span>
            ) : (
                ''
            )}
            {children ? (
                <Span className={'buttonTitle ' + (titleClass ? titleClass : '')}>{children}</Span>
            ) : (
                ''
            )}
        </a>
    );
};

LinkButton.propTypes = {
    iconName: PropTypes.element,
    iconColor: PropTypes.string,
    children: PropTypes.element,
    buttonClass: PropTypes.string,
    buttonType: PropTypes.string,
    iconClass: PropTypes.string,
    titleClass: PropTypes.string,
    iconPlacement: PropTypes.string,
    disabled: PropTypes.bool,
    onClick: PropTypes.func,
    reference: PropTypes.object,
    style: PropTypes.object,
    href: PropTypes.string,
    target: PropTypes.string,
    children: PropTypes.any,
};

export default LinkButton;
// Button Types

// RedFillBtn
// RedOutlineBtn
// BlueFillBtn
// BlueOutlineBtn

// iconPlacement
// right
