import React, { useRef, useState, Fragment, useCallback, useLayoutEffect } from 'react';
import { Link } from 'react-router-dom';
import useMediaQuery from 'UIKit/useMediaQuery';
import { Div, IconButton, Popover, Span } from 'UIKit/index';
import DCArrowDown from 'Assets/icons/DCArrowDown';
import DCMenuIcon from 'Assets/icons/DCMenuIcon';
import './style.scss';

function HeaderTabs(props) {
    const {
        pages,
        activeTab,
        handleActiveTab,
        handleMobileSettings,
        handleMobileSettingsClose,
        otherComponent,
    } = props;
    const matches575 = useMediaQuery('(max-width: 767.98px)');
    const menuBoxRef = useRef(null);
    const [headPageNum, setHeadPageNum] = useState(0);

    useLayoutEffect(() => {
        function updateSize() {
            if (menuBoxRef.current) {
                const menuBoxWidth = menuBoxRef.current.getBoundingClientRect().width;
                const menuNum = parseInt(menuBoxWidth) / 120;
                setHeadPageNum(parseInt(menuNum));
            }
        }
        window.addEventListener('resize', updateSize);
        updateSize();
        return () => window.removeEventListener('resize', updateSize);
    }, []);

    const menuBtnRef = useRef();

    const [menuDropdownOpen, setMenuDropdownOpen] = useState(false);
    const [menuDropdownWidth, setMenuDropdownWidth] = useState(0);
    const menuDropdownToggle = () => {
        setMenuDropdownWidth(menuBtnRef.current.offsetWidth);
        setMenuDropdownOpen(props => !props);
        handleMobileSettingsClose();
    };

    const handlePopoverClose = useCallback(() => {
        setMenuDropdownOpen(false);
    }, []);

    // More menu

    const moreBtnRef = useRef();

    const [moreDropdownOpen, setMoreDropdownOpen] = useState(false);
    const moreDropdownToggle = useCallback(() => {
        setMoreDropdownOpen(props => !props);
    }, []);

    const handleMorePopoverClose = useCallback(() => {
        setMoreDropdownOpen(false);
    }, []);
    return (
        <Fragment>
            {matches575 ? (
                <Fragment>
                    <Div className="sMBIRMenuBox">
                        {pages.length > 0 ? (
                            <Div
                                className={'sMBIRMBtn'}
                                reference={menuBtnRef}
                                onClick={menuDropdownToggle}
                            >
                                <Div className="sMBIRMBText">{activeTab.title}</Div>
                                <Div className="sMBIRMBIcon">
                                    <DCArrowDown />
                                </Div>
                            </Div>
                        ) : null}
                        <Div className={'MobFilter'}>
                            {otherComponent}
                            <IconButton customClass={'MobFilterBtn'} onClick={handleMobileSettings}>
                                <DCMenuIcon />
                            </IconButton>
                        </Div>
                    </Div>
                    <Popover
                        customClass={'HeaderDropdownMenu ArrowPopover'}
                        open={menuDropdownOpen}
                        anchorEl={menuBtnRef}
                        onClose={handlePopoverClose}
                        width={'auto'}
                        placement={'bottom-end'}
                        withOutPadding
                        minWidth={menuDropdownWidth}
                        maxHeight={200}
                    >
                        <Div className="HDMList">
                            {pages.map((item, index) => (
                                <Div
                                    className={
                                        'HDMLItem ' + (item.id === activeTab.id ? 'active' : '')
                                    }
                                    key={index}
                                    onClick={() => {
                                        handleActiveTab(item);
                                        handlePopoverClose();
                                    }}
                                >
                                    {item.access ? (
                                        <Link
                                            to={item.link}
                                            style={{ pointerEvents: !item.access && 'none' }}
                                        >
                                            {item.title}
                                        </Link>
                                    ) : (
                                        <Span>{item.title}</Span>
                                    )}
                                </Div>
                            ))}
                        </Div>
                    </Popover>
                </Fragment>
            ) : (
                <Div className="sMBIRMenuBox" reference={menuBoxRef}>
                    {pages.slice(0, headPageNum).map((item, index) => (
                        <Div className="sMBIRMBMenuItems" key={index}>
                            <Div
                                className={
                                    item.id +
                                    ' ' +
                                    activeTab?.id +
                                    ' sMBIRMBMIMenu  ' +
                                    (item.id === activeTab?.id ? 'active' : '')
                                }
                                onClick={() => {
                                    handleActiveTab(item);
                                }}
                            >
                                {!item.access ? (
                                    <Span>{item.title}</Span>
                                ) : (
                                    <Link to={item.link}>{item.title}</Link>
                                )}
                            </Div>
                        </Div>
                    ))}
                    {pages?.length > headPageNum ? (
                        <Div className="sMBIRMBMenuItems">
                            <Div
                                className={'sMBIRMBMIMenu sMBIRMBMIMore'}
                                reference={moreBtnRef}
                                onClick={moreDropdownToggle}
                            >
                                <Div className={'sMBIRMBMIMTitle'}>More</Div>
                                <Div className={'sMBIRMBMIMIcon'}>
                                    <DCArrowDown />
                                </Div>
                            </Div>
                            <Popover
                                customClass={'MoreDropdownMenu ArrowPopover'}
                                open={moreDropdownOpen}
                                anchorEl={moreBtnRef}
                                onClose={handleMorePopoverClose}
                                width={'auto'}
                                placement={'bottom-start'}
                                withOutPadding
                                minWidth={menuDropdownWidth}
                                maxHeight={240}
                            >
                                <Div className="MDMList">
                                    {pages.slice(headPageNum, pages.length).map((item, index) => (
                                        <Div
                                            className={
                                                'MDMLItem ' +
                                                (item.id === activeTab?.id ? 'active' : '')
                                            }
                                            key={index}
                                            onClick={() => {
                                                handleActiveTab(item);
                                                handleMorePopoverClose();
                                            }}
                                        >
                                            {item.access ? (
                                                <Link
                                                    to={item.link}
                                                    style={{
                                                        pointerEvents: !item.access && 'none',
                                                    }}
                                                >
                                                    {item.title}
                                                </Link>
                                            ) : (
                                                <Span>{item.title}</Span>
                                            )}
                                        </Div>
                                    ))}
                                </Div>
                            </Popover>
                        </Div>
                    ) : null}
                    <Div className={'MobFilter'}>
                        {otherComponent}
                        <IconButton customClass={'MobFilterBtn'} onClick={handleMobileSettings}>
                            <DCMenuIcon />
                        </IconButton>
                    </Div>
                </Div>
            )}
        </Fragment>
    );
}

export default HeaderTabs;
