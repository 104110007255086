import React from 'react';

import { SVGIcon } from '../../UIKit/index';

export default function DCMasterCard(props) {
    return (
        <SVGIcon {...props} viewBox="0 0 42 42">
            <rect width="42" height="42" rx="4" />
            <g transform="translate(6 12)">
                <circle
                    cx="9.5"
                    cy="9.5"
                    r="9.5"
                    transform="translate(12 0)"
                    fill="#f79e1b"
                    opacity="0.94"
                />
                <circle cx="9.5" cy="9.5" r="9.5" transform="translate(0 0)" fill="#ec001b" />
                <circle
                    cx="9.5"
                    cy="9.5"
                    r="9.5"
                    transform="translate(12 0)"
                    fill="#f79e1b"
                    opacity="0.7"
                />
            </g>
        </SVGIcon>
    );
}
