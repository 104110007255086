import React from 'react';

import { SVGIcon } from '../../UIKit/index';

export default function DCAdGroups(props) {
    return (
        <SVGIcon {...props} viewBox="0 0 512 512">
            <g transform="translate(-75.7 -21.736)">
                <g transform="translate(76 22.036)">
                    <g>
                        <g>
                            <path
                                stroke="#FFFFFF"
                                strokeWidth="12.0229"
                                d="M217.3,7.4H25.8c-10.2,0-18.4,8.3-18.4,18.4v191.5c0,10.2,8.3,18.4,18.4,18.4h191.5      c10.2,0,18.4-8.3,18.4-18.4V25.8C235.7,15.6,227.4,7.4,217.3,7.4z M198.9,198.9H44.2V44.2h154.7V198.9z"
                            />
                        </g>
                    </g>
                    <g transform="translate(13.415)">
                        <g>
                            <path
                                stroke="#FFFFFF"
                                strokeWidth="12.0229"
                                d="M472.7,7.4H281.2c-10.2,0-18.4,8.3-18.4,18.4v191.5c0,10.2,8.3,18.4,18.4,18.4h191.5      c10.2,0,18.4-8.3,18.4-18.4V25.8C491.1,15.6,482.8,7.4,472.7,7.4z M454.3,198.9H299.6V44.2h154.7V198.9z"
                            />
                        </g>
                    </g>
                    <g transform="translate(0 13.415)">
                        <g>
                            <path
                                stroke="#FFFFFF"
                                strokeWidth="12.0229"
                                d="M217.3,262.8H25.8c-10.2,0-18.4,8.3-18.4,18.4v191.5c0,10.2,8.3,18.4,18.4,18.4h191.5      c10.2,0,18.4-8.3,18.4-18.4V281.2C235.7,271,227.4,262.8,217.3,262.8z M198.9,454.3H44.2V299.6h154.7L198.9,454.3z"
                            />
                        </g>
                    </g>
                    <g transform="translate(13.415 13.415)">
                        <g>
                            <path
                                stroke="#FFFFFF"
                                strokeWidth="12.0229"
                                d="M472.7,262.8H281.2c-10.2,0-18.4,8.3-18.4,18.4v191.5c0,10.2,8.3,18.4,18.4,18.4h191.5      c10.2,0,18.4-8.3,18.4-18.4V281.2C491.1,271,482.8,262.8,472.7,262.8z M454.3,454.3H299.6V299.6h154.7V454.3z"
                            />
                        </g>
                    </g>
                </g>
                <rect x="106" y="312.6" width="180.3" height="200.4" />
                <rect x="366.5" y="52.1" width="200.4" height="180.3" />
            </g>
        </SVGIcon>
    );
}
