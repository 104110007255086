import React from 'react';

import { SVGIcon } from '../../UIKit/index';

export default function DCSuitCase(props) {
    return (
        <SVGIcon {...props} viewBox="0 0 512 512">
            <g>
                <path d="M319.2,97.1c-11.7,0-21.1-9.5-21.1-21.1V44.3h-84.5V76c0,11.7-9.5,21.1-21.1,21.1  c-11.7,0-21.1-9.5-21.1-21.1V44.3c0-23.3,18.9-42.3,42.3-42.3h84.5c23.3,0,42.3,18.9,42.3,42.3V76C340.4,87.6,330.9,97.1,319.2,97.1  z" />
                <path d="M270.8,284.7c-4.8,1.7-9.9,2.6-15,2.5c-5.6,0-11.1-0.9-16.3-2.8L2.3,205.3v161.2  c0,32.1,26.1,58,58.1,58h390.9c32,0,58.1-25.9,58.1-58V205.3L270.8,284.7z" />
                <path d="M509.4,123.5v48.4l-248.5,82.8c-3.3,1.1-6.9,1.1-10.1,0L2.3,171.9v-48.4  c0-32.1,25.9-58.1,58-58.1c0,0,0.1,0,0.1,0h390.9C483.4,65.4,509.4,91.4,509.4,123.5C509.4,123.5,509.4,123.5,509.4,123.5z" />{' '}
            </g>
        </SVGIcon>
    );
}
