import { sanitizePhoneNumber } from 'Utils';
import { CallAPI } from '../../Utils/apiCall';
import config from 'config';

const baseUrl = `${config.REACT_APP_API_BASE_URL}/${process.env.REACT_APP_API_VERSION}`;
// const baseUrl = `https://rare-elephant-94.loca.lt/${process.env.REACT_APP_API_VERSION}`;

let configURL = `${baseUrl}/${process.env.REACT_APP_API_USERS}/${process.env.REACT_APP_API_ME}/${process.env.REACT_APP_API_CONFIG}`;
let fieldsURL = `${baseUrl}/${process.env.REACT_APP_API_CRM}/${process.env.REACT_APP_API_FIELDS}`;
const api = new CallAPI();

export const getStatus = async () => {
    const url = `${baseUrl}/instareports/statuses`;

    return api.request('GET', url, null);
};

export const addQueueCSV = async body => {
    await api.request('POST', baseUrl + '/instareports/queue/csv', body);
};

export const addQueue = async body => {
    await api.request('POST', baseUrl + '/instareports/queue', body);
};

export const getAdditionInformation = async id => {
    const url = `${baseUrl}/instareports/${id}/additional-information`;
    return api.request('GET', url, null);
};

export const getMyInstaReports = async (page, limit, sort, search, statusInstaSites) => {
    const url = `${baseUrl}/instareports`;
    let params = { page, limit };

    if (sort) {
        const temp = sort.split('&');
        params.order = temp[0]?.split('=')[1];
        params.sort_by = temp[1]?.split('=')[1];
    }
    if (search) {
        params.search = search;
    }
    if (statusInstaSites) {
        params.status = statusInstaSites;
    }

    return api.request('GET', url, null, null, null, params);
};

export const resendSMS = (instareportId, recipientId, phone) => {
    const url = `${baseUrl}/instareports/resend-sms`;
    const body = {
        instareport_id: instareportId,
        recipient_id: recipientId,
        phone: sanitizePhoneNumber(phone),
    };
    return api.request('POST', url, body);
};
export const resendEmailApi = (instareportId, recipientId, email) => {
    const url = `${baseUrl}/instareports/resend-email`;
    const body = {
        instareport_id: instareportId,
        recipient_id: recipientId,
        email: email,
    };
    return api.request('POST', url, body);
};

export const getInstaReportsBusinessColumn = async () => {
    const instareportContacts = await api.request('GET', configURL, null, null, null, {
        type: 'contacts_instareport',
    });
    let businessCols = await api.request('GET', fieldsURL + '/businesses');
    if (instareportContacts.success && businessCols.success) {
        let mandatory = instareportContacts.data.fields.businesses[0].fields;
        for (let i = 0; i < businessCols.data.length; i++) {
            if (mandatory.includes(businessCols.data[i].field)) {
                businessCols.data[i].default = true;
            }

            businessCols.data[i] = {
                Header: businessCols.data[i].title,
                accessor: businessCols.data[i].field,
                type: businessCols.data[i].type,
                default: businessCols.data[i].default || false,
                subType: businessCols.data[i].sub_type,
            };
        }
    }
    return {
        colData: businessCols.data,
        visibleFields: instareportContacts.data.fields.businesses[1].fields,
    };
};

// reporting page
export const getInstareportsStats = async (startTime, endTime) => {
    const url = `${baseUrl}/instareports/reporting/status?startDate=${encodeURIComponent(
        startTime,
    )}&endDate=${encodeURIComponent(endTime)}`;
    return api.request('GET', url, null);
};

export const getInstareportsIndStats = async (startTime, endTime) => {
    const url = `${baseUrl}/instareports/reporting/industry?startDate=${encodeURIComponent(
        startTime,
    )}&endDate=${encodeURIComponent(endTime)}`;
    return api.request('GET', url, null);
};

// Public routes
export const sendEmailToBusiness = async (id, first_name, last_name, email, message) => {
    const url = `${baseUrl}/instareports/${id}/submit-request`;
    return api.requestPublic('POST', url, { first_name, last_name, email, message });
};

export const getExternalPage = async id => {
    const url = `${baseUrl}/instareports/${id}`;
    return api.requestPublic('GET', url);
};
export const InstareportView = async params => {
    return await new CallAPI().requestPublic(
        'POST',
        `${baseUrl}/instareports/${params.iid}/${params.rid}/view`,
    );
    // .then(res => {
    //     if (!res?.success) throw new Error('Something went wrong!');
    // });
};
