import React from 'react';
import PropTypes from 'prop-types';
import { Div } from '../index';

export default function FieldControl({ props, children, customClass, reference }) {
    return (
        <Div
            className={'fieldControl ' + (customClass ? customClass : '')}
            ref={reference}
            {...props}
        >
            {children}
        </Div>
    );
}

FieldControl.propTypes = {
    children: PropTypes.node,
    customClass: PropTypes.string,
    reference: PropTypes.object,
    props: PropTypes.object,
};
