import React from 'react';

import { SVGIcon } from '../../UIKit/index';

export default function DCReportBuilder(props) {
    return (
        <SVGIcon {...props} viewBox="0 0 428 512">
            <g>
                <path d="M376.19,510.81H56.81c-29.38-0.04-53.19-23.85-53.23-53.23V53.03   C3.62,23.65,27.43-0.16,56.81-0.2h319.38c29.38,0.04,53.19,23.85,53.23,53.23v404.55C429.37,486.96,405.57,510.77,376.19,510.81z    M56.81,21.09C39.18,21.11,24.89,35.4,24.87,53.03v404.55c0.02,17.63,14.31,31.92,31.94,31.94h319.38   c17.63-0.02,31.92-14.31,31.94-31.94V53.03c-0.02-17.63-14.31-31.92-31.94-31.94H56.81z" />
                <path d="M354.9,255.31h-42.58c-5.88,0-10.65-4.77-10.65-10.65c0-5.88,4.77-10.65,10.65-10.65h42.58   c5.88,0,10.65,4.77,10.65,10.65C365.54,250.54,360.78,255.31,354.9,255.31z" />
                <path d="M354.9,191.43h-42.58c-5.88,0-10.65-4.77-10.65-10.65c0-5.88,4.77-10.65,10.65-10.65h42.58   c5.88,0,10.65,4.77,10.65,10.65C365.54,186.66,360.78,191.43,354.9,191.43z" />
                <path d="M354.9,319.18h-42.58c-5.88,0-10.65-4.77-10.65-10.65s4.77-10.65,10.65-10.65h42.58   c5.88,0,10.65,4.77,10.65,10.65S360.78,319.18,354.9,319.18z" />
                <path d="M354.9,383.06h-42.58c-5.88,0.01-10.65-4.75-10.66-10.63c-0.01-5.88,4.75-10.65,10.63-10.66   c0.01,0,0.02,0,0.02,0h42.58c5.88,0.01,10.64,4.78,10.63,10.66C365.52,378.29,360.77,383.05,354.9,383.06z" />
                <path d="M354.9,446.93H78.1c-5.88-0.01-10.64-4.78-10.63-10.66c0.01-5.87,4.76-10.63,10.63-10.63h276.8   c5.88,0.01,10.64,4.78,10.63,10.66C365.52,442.17,360.77,446.93,354.9,446.93z" />
                <path d="M173.91,383.06c-58.8,0-106.46-47.66-106.46-106.46s47.66-106.46,106.46-106.46   S280.37,217.8,280.37,276.6l0,0C280.31,335.37,232.68,382.99,173.91,383.06z M173.91,191.43c-47.04,0-85.17,38.13-85.17,85.17   s38.13,85.17,85.17,85.17s85.17-38.13,85.17-85.17S220.95,191.43,173.91,191.43L173.91,191.43z" />
                <path d="M106.16,354.99c-5.87,0.08-10.69-4.61-10.77-10.48c-0.04-2.92,1.13-5.73,3.22-7.77l64.65-64.65   v-91.31c0-5.88,4.77-10.65,10.65-10.65c5.88,0,10.65,4.77,10.65,10.65v95.81c0,2.82-1.12,5.54-3.1,7.54l-67.75,67.75   C111.7,353.88,108.99,355,106.16,354.99z" />
            </g>
        </SVGIcon>
    );
}
