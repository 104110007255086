import React from 'react';

import { SVGIcon } from '../../UIKit/index';

export default function DCDirections(props) {
    return (
        <SVGIcon {...props} viewBox="0 0 512 512">
            <path d="m393.72 202.051c4.071 0 7.968-1.655 10.795-4.585l54.445-56.429c5.606-5.812 5.606-15.019 0-20.83l-54.445-56.429c-2.827-2.93-6.724-4.585-10.795-4.585h-92.933v-44.193c0-8.284-6.716-15-15-15h-59.574c-8.284 0-15 6.716-15 15v44.192h-122.127c-16.681 0-30.251 13.57-30.251 30.25v82.358c0 16.681 13.57 30.251 30.251 30.251h122.127v29.999h-92.933c-4.071 0-7.968 1.655-10.795 4.585l-54.445 56.429c-5.606 5.812-5.606 15.019 0 20.83l54.445 56.429c2.827 2.93 6.724 4.585 10.795 4.585h92.933v107.092h-15c-8.284 0-15 6.716-15 15s6.716 15 15 15h119.574c8.284 0 15-6.716 15-15s-6.716-15-15-15h-15v-107.092h122.127c16.681 0 30.251-13.57 30.251-30.25v-82.358c0-16.68-13.57-30.25-30.251-30.25h-122.127v-29.999zm-152.507-172.051h29.574v29.192h-29.574zm-152.378 141.8v-82.357c0-.138.112-.25.251-.25h298.263l39.973 41.429-39.973 41.429h-298.263c-.139 0-.251-.113-.251-.251zm181.952 310.2h-29.574v-107.092h29.574zm152.378-219.7v82.357c0 .138-.112.25-.251.25h-298.263l-39.973-41.429 39.973-41.429h298.263c.139.001.251.113.251.251zm-152.378-30.25h-29.574v-29.999h29.574z" />
        </SVGIcon>
    );
}
