import React from 'react';

// import { enGB } from 'date-fns/locale';
import { DatePickerCalendar } from 'react-nice-dates';

import { Div } from 'UIKit/index';

import 'react-nice-dates/build/style.css';

import './style.scss';

function OnlyDatePickerCalendar({
    date,
    handleDatePicker,
    modifiersClassNames,
    locale,
    minimumDate,
    pastValueDate, // if there is any value coming in from outside and it is in past,
    //  date picker will shoaw the current month calender
    maximumDate,
}) {
    return (
        <Div className={'DatePickerCalendar'}>
            <DatePickerCalendar
                locale={locale}
                date={!pastValueDate ? date : null}
                onDateChange={handleDatePicker}
                modifiersClassNames={modifiersClassNames}
                minimumDate={minimumDate}
                maximumDate={maximumDate}
            />
        </Div>
    );
}

export default OnlyDatePickerCalendar;
