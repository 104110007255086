import React from 'react';

import { SVGIcon } from '../../UIKit/index';

export default function DCOutreachMenu(props) {
    return (
        <SVGIcon {...props} viewBox="0 0 512 512">
            <g transform="translate(-15.281 -14.607)">
                <g transform="translate(15.437 18.241)">
                    <g transform="translate(0)">
                        <path
                            fill="#E6EFFF"
                            d="M147.24,432.93c-3.5-7.42-4.85-15.68-3.88-23.84l2.58-21.32     c3.35-27.92-1.24-56.22-13.26-81.65l-55.3-116.75l3.51-91.86c0.15-4.16-0.79-8.3-2.73-11.98c-5.14-9.41-16.79-13.1-26.41-8.36     c-4.46,2.04-7.6,6.17-8.39,11.01L4.9,265.64c-2.45,14.67-0.85,29.73,4.62,43.56l24.38,55.95l13.72,31.44l19.5,44.9h83.99     L147.24,432.93z"
                        />
                    </g>
                </g>
                <g transform="translate(15.281 18.083)">
                    <g transform="translate(0 0)">
                        <path
                            fill="none"
                            d="M154.4,440.11l-4.01-8.45c-3.24-6.85-4.48-14.49-3.58-22.02l2.58-21.34     c3.44-28.56-1.26-57.51-13.56-83.51L80.98,188.83l3.49-91.01c0.17-4.75-0.91-9.46-3.12-13.67C75.43,73,61.7,68.61,50.41,74.25     c-5.37,2.49-9.17,7.47-10.16,13.3L1.74,265.15c-2.56,15.33-0.88,31.06,4.85,45.51l24.38,55.95l0,0l33.28,76.34     c0.54,1.24,1.77,2.04,3.12,2.04h83.99c1.88,0,3.4-1.52,3.4-3.4c0-0.5-0.11-1-0.33-1.45L154.4,440.11z M69.57,438.16L12.83,308.01     c-5.23-13.2-6.78-27.57-4.46-41.58L46.88,88.92c0.59-3.76,3.03-6.97,6.5-8.54c7.97-3.87,17.57-0.84,21.86,6.91     c1.66,3.16,2.47,6.7,2.34,10.27l-3.53,91.86c-0.02,0.55,0.09,1.09,0.33,1.58l55.19,116.69c11.77,24.84,16.28,52.51,13,79.81     l-2.58,21.34c-1.06,8.8,0.39,17.73,4.18,25.74l1.71,3.6H69.57V438.16z"
                        />
                    </g>
                </g>
                <g transform="translate(17.626 23.411)">
                    <g transform="translate(0 0)">
                        <path
                            fill="#A7C0DF"
                            d="M80.11,333.33c-3.81-21.17-7.93-37.86-11.92-54c-4.12-16.71-8.02-32.5-10.83-50.62     c-1.86-8.31-2.01-16.9-0.43-25.27c1.63-7.55,7.69-13.35,15.3-14.65c1.87-0.1,3.31-1.7,3.21-3.58c-0.13-1.86-1.71-3.28-3.58-3.21     c-10.57,1.41-19.14,9.25-21.5,19.65c-1.89,9.3-1.8,18.9,0.26,28.17c2.82,18.44,6.74,34.37,10.83,51.23     c3.94,16.04,8.04,32.5,11.83,53.57c0.29,1.61,1.7,2.79,3.34,2.8h0.61c1.86-0.24,3.18-1.95,2.93-3.82     C80.15,333.51,80.13,333.42,80.11,333.33z"
                        />
                    </g>
                </g>
                <g transform="translate(16.801 35.144)">
                    <g transform="translate(0 0)">
                        <path
                            fill="#2F8DFF"
                            d="M170.37,424.48H50.06c-9.98,0-18.07,8.09-18.07,18.07v45.9h156.45v-45.9     C188.44,432.57,180.35,424.48,170.37,424.48z"
                        />
                    </g>
                </g>
                <g transform="translate(16.645 34.988)">
                    <g transform="translate(0 0)">
                        <path
                            fill="none"
                            d="M170.52,421.25H50.24c-11.85,0.01-21.46,9.62-21.47,21.47v45.9c0,1.88,1.52,3.4,3.4,3.4h89.13     c1.87,0.15,3.51-1.25,3.66-3.12c0.15-1.87-1.25-3.51-3.12-3.66c-0.18-0.01-0.36-0.01-0.54,0H35.55v-42.51     c0.01-8.11,6.58-14.68,14.69-14.69h120.29c8.11,0.01,14.68,6.58,14.69,14.69v42.52h-29.75c-1.87-0.15-3.51,1.25-3.66,3.12     c-0.15,1.87,1.25,3.51,3.12,3.66c0.18,0.01,0.36,0.01,0.54,0h33.15c1.88,0,3.4-1.52,3.4-3.4v-45.9     C192.01,430.87,182.39,421.25,170.52,421.25z"
                        />
                    </g>
                </g>
                <g transform="translate(17.623 35.945)">
                    <g>
                        <path
                            fill="#FFFFFF"
                            d="M63.63,441.03c-8.09,0-14.65,6.56-14.65,14.65c0,8.09,6.56,14.65,14.65,14.65     s14.65-6.56,14.65-14.65l0,0C78.27,447.59,71.72,441.03,63.63,441.03z"
                        />
                    </g>
                </g>
                <g transform="translate(17.466 35.788)">
                    <g transform="translate(0)">
                        <path
                            fill="none"
                            d="M63.76,437.79c-9.97-0.01-18.06,8.06-18.07,18.03c-0.01,9.97,8.06,18.06,18.03,18.07     c9.97,0.01,18.06-8.06,18.07-18.03c0-0.01,0-0.03,0-0.04C81.78,445.87,73.71,437.8,63.76,437.79z M63.76,467.09     c-6.21,0-11.25-5.04-11.25-11.25c0-6.21,5.04-11.25,11.25-11.25c6.21,0,11.25,5.04,11.25,11.25l0,0     c0.04,6.22-4.98,11.3-11.2,11.33c-0.01,0-0.03,0-0.04,0V467.09z"
                        />
                    </g>
                </g>
                <g transform="translate(31.871 18.241)">
                    <g transform="translate(0)">
                        <path
                            fill="#E6EFFF"
                            d="M489.67,265.54L451.19,88.17c-0.78-4.84-3.93-8.97-8.39-11.01     c-9.62-4.74-21.27-1.05-26.41,8.36c-1.94,3.69-2.88,7.82-2.73,11.98l3.53,91.86L362,306.06c-12.02,25.43-16.62,53.73-13.26,81.65     l2.58,21.32c0.97,8.15-0.38,16.41-3.88,23.84l-4.01,8.45h83.99l19.5-44.9l13.72-31.44L485.12,309     C490.56,295.2,492.13,280.17,489.67,265.54z"
                        />
                    </g>
                </g>
                <g transform="translate(31.714 18.083)">
                    <g transform="translate(0 0)">
                        <path
                            fill="none"
                            d="M493.19,264.94L454.73,87.72c-0.96-5.9-4.79-10.95-10.21-13.48     c-11.27-5.65-24.98-1.31-30.94,9.79c-2.2,4.22-3.27,8.94-3.1,13.69l3.49,91.01L359.12,304.7c-12.3,26-17.01,54.95-13.57,83.51     l2.58,21.32c0.91,7.53-0.33,15.16-3.58,22.02l-4.01,8.45c-0.8,1.7-0.07,3.73,1.62,4.53c0.45,0.21,0.95,0.33,1.45,0.33h83.99     c1.35,0,2.58-0.8,3.12-2.04l57.71-132.4C494.14,295.97,495.78,280.25,493.19,264.94z M482.14,307.86l-56.77,130.25h-76.41     l1.71-3.6c3.79-8.02,5.24-16.94,4.18-25.74l-2.58-21.32c-3.28-27.3,1.23-54.96,13-79.81l55.19-116.69     c0.23-0.49,0.35-1.04,0.33-1.58l-3.53-91.86c-0.13-3.57,0.67-7.11,2.32-10.27c4.29-7.76,13.91-10.8,21.89-6.91     c3.49,1.6,5.94,4.86,6.5,8.67l38.46,177.19c2.38,14.05,0.88,28.48-4.33,41.73L482.14,307.86z"
                        />
                    </g>
                </g>
                <g transform="translate(35.047 23.411)">
                    <g transform="translate(0)">
                        <path
                            fill="#A7C0DF"
                            d="M438.85,201.62c-2.35-10.4-10.93-18.24-21.5-19.65c-1.88,0.02-3.39,1.56-3.37,3.44     c0.02,1.72,1.31,3.15,3.02,3.35c7.61,1.3,13.67,7.1,15.3,14.65c1.58,8.36,1.43,16.96-0.43,25.27     c-2.77,18.14-6.67,33.91-10.83,50.62c-3.99,16.14-8.08,32.81-11.92,53.98c-0.33,1.84,0.89,3.6,2.73,3.94h0.61     c1.64,0,3.04-1.18,3.34-2.8c3.77-20.95,7.87-37.53,11.83-53.57c4.16-16.86,8.08-32.79,10.83-51.23     C440.54,220.41,440.67,210.88,438.85,201.62z"
                        />
                    </g>
                </g>
                <g transform="translate(30.088 35.144)">
                    <g transform="translate(0 0)">
                        <path
                            fill="#2F8DFF"
                            d="M443.59,424.48H325.97c-10.77,0-19.5,8.73-19.5,19.5v44.57h156.45v-44.57     C462.92,433.28,454.29,424.57,443.59,424.48z"
                        />
                    </g>
                </g>
                <g transform="translate(29.931 34.988)">
                    <g transform="translate(0 0)">
                        <path
                            fill="none"
                            d="M443.81,421.25H326.19c-12.6,0.01-22.81,10.22-22.82,22.82v44.55c0,1.88,1.52,3.4,3.4,3.4     h156.45c1.88,0,3.4-1.52,3.4-3.4v-44.55C466.62,431.47,456.41,421.27,443.81,421.25z M459.83,485.24H310.16v-41.17     c0.01-8.84,7.17-16,16.01-16.01h117.62c8.84,0.01,16,7.17,16.01,16.01L459.83,485.24z"
                        />
                    </g>
                </g>
                <g transform="translate(35.135 35.945)">
                    <g>
                        <path
                            fill="#FFFFFF"
                            d="M425.59,441.03c-8.09,0-14.65,6.56-14.65,14.65c0,8.09,6.56,14.65,14.65,14.65     c8.09,0,14.65-6.56,14.65-14.65c0,0,0,0,0,0C440.23,447.59,433.68,441.03,425.59,441.03z"
                        />
                    </g>
                </g>
                <g transform="translate(34.978 35.788)">
                    <g transform="translate(0)">
                        <path
                            fill="none"
                            d="M425.72,437.79c-9.97-0.01-18.06,8.06-18.07,18.03c-0.01,9.97,8.06,18.06,18.03,18.07     s18.06-8.06,18.07-18.03c0-0.01,0-0.03,0-0.04C443.74,445.87,435.67,437.8,425.72,437.79z M425.72,467.09     c-6.21,0-11.25-5.04-11.25-11.25c0-6.21,5.04-11.25,11.25-11.25c6.21,0,11.25,5.04,11.25,11.25l0,0     c0.04,6.22-4.98,11.3-11.2,11.33c-0.01,0-0.03,0-0.04,0V467.09z"
                        />
                    </g>
                </g>
                <g transform="translate(19.839 14.764)">
                    <g transform="translate(0 0)">
                        <path
                            fill="#2F8DFF"
                            d="M251.12,25.23c-89.86-56.01-156.34,4.33-156.34,75.84c0,38.2,24.88,79.01,58.7,114.28     l25.4,24.07c19.16,16.65,39.87,31.43,61.84,44.14c6.44,3.68,14.36,3.68,20.8,0c67.67-38.74,145.94-114.74,145.94-182.48     C407.47,29.63,340.99-30.78,251.12,25.23z"
                        />
                    </g>
                </g>
                <g transform="translate(19.682 14.607)">
                    <g transform="translate(0 0)">
                        <path
                            fill="none"
                            d="M361.79,10.81C330.35-6.64,291.17-2.87,251.28,21.4c-39.89-24.27-79.07-28.04-110.51-10.6     c-31.57,19.03-50.39,53.63-49.21,90.47c0,24.83,10.36,65.22,59.63,116.62c1.21,1.44,3.35,1.62,4.79,0.42     c1.44-1.21,1.62-3.35,0.42-4.79c-0.1-0.11-0.2-0.22-0.31-0.32c-37.25-38.83-57.75-78.59-57.75-111.92     c-1.16-34.36,16.32-66.68,45.72-84.51C173.73,0.3,211.24,4.39,249.5,28.29c1.1,0.69,2.5,0.69,3.6,0     c38.33-23.84,75.84-28,105.42-11.53c29.4,17.83,46.88,50.15,45.72,84.51c0,48.17-49.54,125.31-144.23,179.53     c-5.38,3.04-11.96,3.04-17.34,0c-21.78-12.6-42.3-27.25-61.3-43.75c-1.34-1.32-3.49-1.3-4.81,0.04c-1.32,1.34-1.3,3.49,0.04,4.81     c0.1,0.1,0.21,0.2,0.33,0.28c19.34,16.78,40.22,31.69,62.39,44.51c7.5,4.27,16.69,4.27,24.18,0     c73.37-42.02,147.65-119.18,147.65-185.42C412.3,64.41,393.42,29.8,361.79,10.81z"
                        />
                    </g>
                </g>
            </g>
        </SVGIcon>
    );
}
