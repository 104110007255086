import React, { Fragment, useCallback, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import DCArrowDown from '../../Assets/icons/DCArrowDown';
import { Button, Div, DropdownOptions, TextOverflowTooltip } from '../index';
import PropTypes from 'prop-types';
import './style.scss';

const DealInfo = ({
    className,
    deal,
    dealName,
    dealPrice,
    dealCurrency,
    selectStage,
    defaultSelectedStage,
    link,
    displayKey,
}) => {
    const buttonRef = useRef();
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const [, setDropdownWidth] = useState(0);
    const [selectedStage, setSelectedStage] = useState(
        defaultSelectedStage || (deal?.stages ? deal.stages[0] : null),
    );

    const handleDropdownToggle = () => {
        setDropdownOpen(prevOpen => !prevOpen);
        setDropdownWidth(buttonRef.current.offsetWidth);
    };

    const handleDropdownClose = () => {
        setDropdownOpen(false);
    };

    const handleSelectStage = useCallback(
        item => {
            setSelectedStage(item);
            selectStage && selectStage(deal.dealsId, deal.pipelineId, item.id);
            setDropdownOpen(false);
        },
        [deal, selectStage],
    );

    const generateOptions = useCallback(() => {
        const tempOptionArray = [];
        deal?.stages?.map(item => tempOptionArray.push({
                label: (
                    <Button
                        buttonClass={
                            'DropdownOptionsBtn ' +
                            (selectedStage && selectedStage.id === item.id ? 'active' : '')
                        }
                        onClick={() => {
                            handleSelectStage(item);
                        }}
                    >
                        <TextOverflowTooltip tooltipContent={item[displayKey]}>
                            {item[displayKey]}
                        </TextOverflowTooltip>
                    </Button>
                ),
            }),
        );
        return tempOptionArray;
    }, [deal, displayKey, handleSelectStage, selectedStage]);

    const getTopLineBar = useCallback(length => {
        if (!length) {
            return null;
        } else {
            let barArr = [];
            for (let i = 1; i <= length; i++) {
                barArr.push(
                    <Div
                        key={i + 'bar'}
                        className={'DIMStem '}
                        style={{
                            width: 'calc(96% / ' + length + ')',
                        }}
                    />,
                );
            }
            return barArr;
        }
    }, []);

    return (
        <Fragment>
            {deal?.stages && (
                <DropdownOptions
                    options={generateOptions()}
                    wrapperWidth={'auto'}
                    minWidth={150}
                    onClose={handleDropdownClose}
                    open={dropdownOpen}
                    reference={buttonRef}
                    viewHeight={deal?.stages?.length * 40 || 200}
                    itemSize={40}
                    defaultPlacement={'bottom-end'}
                />
            )}
            <Div className={'DealInfoMain'}>
                <Div className={'DealInfo ' + (className ? className : '')}>
                    <Div className={'DealInfoInner'}>
                        <Div className={'DIIHead'}>
                            <Div className={'DIIHTitle'}>
                                <Link
                                    to={{
                                        pathname: link.pathname,
                                        search: link.search,
                                        state: {
                                            returnPath: link.state?.returnPath,
                                            backButtonTitle: link.state?.backButtonTitle,
                                        },
                                    }}
                                >
                                    <TextOverflowTooltip tooltipContent={dealName}>
                                        {dealName}
                                    </TextOverflowTooltip>
                                </Link>
                            </Div>

                            <Div className={'DIIHPrice'}>
                                {`${dealCurrency?.sign}${dealPrice ? dealPrice : '0.00'} ${
                                    dealCurrency?.name
                                }`}
                            </Div>
                        </Div>
                        <Div className={'DIIBody'}>
                            {deal?.stages ? (
                                <Button
                                    buttonClass={'DIIBBtn'}
                                    reference={buttonRef}
                                    onClick={handleDropdownToggle}
                                    iconName={<DCArrowDown />}
                                    iconPlacement={'right'}
                                >
                                    {selectedStage?.[displayKey]}
                                </Button>
                            ) : (
                                <Div>{selectedStage?.name}</Div>
                            )}
                        </Div>
                    </Div>
                </Div>

                <Div className={'DIMStages'}>{getTopLineBar(deal?.stages?.length)}</Div>
            </Div>
        </Fragment>
    );
};

DealInfo.propTypes = {
    className: PropTypes.string,
    deal: PropTypes.object.isRequired,
    dealName: PropTypes.string.isRequired,
    dealPrice: PropTypes.string.isRequired,
    dealCurrency: PropTypes.object.isRequired,
    selectStage: PropTypes.func.isRequired,
    defaultSelectedStage: PropTypes.object.isRequired,
    link: PropTypes.object.isRequired,
    displayKey: PropTypes.string.isRequired,
};

export default DealInfo;

//  <DealInfo
//    dealName={"Deal Name"}
//    dealPrice={"Deal Price"}
//    stages={stagesList}  * Stage List *
//    displayKey={"title"} * Option Display key *
//    link={{ href: "#", target: "_blank" }} * Link *
//    selectStage={} * handleSelectOption *
//    defaultSelectedStage={} * selected Option *
//  />
