import React from 'react';

import { SVGIcon } from '../../UIKit/index';

export default function DCDiamond(props) {
    return (
        <SVGIcon {...props} viewBox="0 0 512 512">
            <defs>
                <linearGradient
                    id="linear-gradient"
                    x1="0.816"
                    y1="0.131"
                    x2="0.182"
                    y2="0.896"
                    gradientUnits="objectBoundingBox"
                >
                    <stop offset="0" stopColor="#15e6cd" />
                    <stop offset="0.004" stopColor="#15e5cc" />
                    <stop offset="1" stopColor="#149a8a" />
                </linearGradient>
                <clipPath id="clip-Web_1920_1">
                    <rect width="512" height="512" />
                </clipPath>
            </defs>
            <g id="Web_1920_1" clipPath="url(#clip-Web_1920_1)">
                <rect width="512" height="512" fill="#fff" />
                <path
                    id="Path_9"
                    d="M256,0C397.385,0,512,114.615,512,256S397.385,512,256,512,0,397.385,0,256,114.615,0,256,0Z"
                    fill="url(#linear-gradient)"
                />
                <g id="diamond" transform="translate(124.166 105.115)">
                    <g id="Group_2" transform="translate(144.915 97.917)">
                        <g id="Group_1">
                            <path
                                id="Path_1"
                                d="M330.541,153.145,281.4,308.752,400.154,153.145Z"
                                transform="translate(-281.402 -153.145)"
                                fill="#fff"
                            />
                        </g>
                    </g>
                    <g id="Group_4" transform="translate(0.557 42.876)">
                        <g id="Group_3" transform="translate(0 0)">
                            <path
                                id="Path_2"
                                d="M38.806,46.263,1.082,91.532H65.213Z"
                                transform="translate(-1.082 -46.263)"
                                fill="#fff"
                            />
                        </g>
                    </g>
                    <g id="Group_6" transform="translate(198.979 42.876)">
                        <g id="Group_5" transform="translate(0 0)">
                            <path
                                id="Path_3"
                                d="M412.793,46.263,386.386,91.532h64.131Z"
                                transform="translate(-386.386 -46.263)"
                                fill="#fff"
                            />
                        </g>
                    </g>
                    <g id="Group_8" transform="translate(0 97.917)">
                        <g id="Group_7">
                            <path
                                id="Path_4"
                                d="M69.613,153.145H0L118.752,308.752Z"
                                transform="translate(0 -153.145)"
                                fill="#fff"
                            />
                        </g>
                    </g>
                    <g id="Group_10" transform="translate(143.63 39.279)">
                        <g id="Group_9">
                            <path
                                id="Path_5"
                                d="M278.906,39.279,324.722,85.1l26.724-45.816Z"
                                transform="translate(-278.906 -39.279)"
                                fill="#fff"
                            />
                        </g>
                    </g>
                    <g id="Group_12" transform="translate(47.497 39.279)">
                        <g id="Group_11">
                            <path
                                id="Path_6"
                                d="M92.232,39.279,118.956,85.1l45.816-45.816Z"
                                transform="translate(-92.232 -39.279)"
                                fill="#fff"
                            />
                        </g>
                    </g>
                    <g id="Group_14" transform="translate(79.86 97.917)">
                        <g id="Group_13">
                            <path
                                id="Path_7"
                                d="M155.076,153.145l51.973,164.574,51.973-164.574Z"
                                transform="translate(-155.076 -153.145)"
                                fill="#fff"
                            />
                        </g>
                    </g>
                    <g id="Group_16" transform="translate(84.991 41.302)">
                        <g id="Group_15">
                            <path
                                id="Path_8"
                                d="M211.882,43.208,165.04,90.05h93.684Z"
                                transform="translate(-165.04 -43.208)"
                                fill="#fff"
                            />
                        </g>
                    </g>
                </g>
            </g>
        </SVGIcon>
    );
}
