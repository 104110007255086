const instaReportStore = {
    initialMyInstareportLoad: true,
    status: [
        {
            id: 0,
            title: 'Built',
            type: 'built',
            value: 'GENERATED',
            color: '#F4AD3B',
            isChecked: false,
            count: 0,
        },
        {
            id: 1,
            title: 'Queued',
            value: 'QUEUED',
            type: 'queued',
            color: '#2269ec',
            isChecked: false,
            count: 0,
        },
        {
            id: 3,
            title: 'Retrying',
            value: 'RETRYING',
            type: 'retrying',
            color: '#651268',
            isChecked: false,
            count: 0,
        },
        {
            id: 2,
            title: 'Failed',
            value: 'FAILED',
            type: 'failed',
            color: '#fa0915',
            isChecked: false,
            count: 0,
        },
    ],
    fields: [],
    page: 1,
    totalResults: 1,
    totalPages: 1,
    pageLimit: 25,
    data: new Map(),
    sortString: '',
    myInstaReportSearchText: '',

    selectedInstaReport: -1,
    statusString: '',
    additionalInfo: {},
};

export default instaReportStore;
