import React from 'react';

import { SVGIcon } from '../../UIKit/index';

export default function DCFileHeader(props) {
    return (
        <SVGIcon {...props} viewBox="0 0 408 512">
            <g transform="translate(-61.38)">
                <path d="M373.38,0.02H96.48C78.54-0.59,63.47,13.41,62.75,31.35v448.24   c0.67,17.97,15.76,32.02,33.73,31.4h339.19c17.95,0.63,33.03-13.38,33.73-31.33V96.89L373.38,0.02z M381.34,46.91l42.08,42.43   h-42.06L381.34,46.91z M441.06,479.59c-0.15,2.86-2.55,5.08-5.41,5.02H96.48c-2.86,0.06-5.26-2.16-5.41-5.02V31.35   c0.16-2.85,2.55-5.07,5.41-5h256.58v76.22c0.31,7.52,6.62,13.39,14.14,13.14h73.89v363.89H441.06z" />
                <path d="M133.47,74.7H292.6c9.76,0,17.68,7.92,17.68,17.68l0,0c0,9.76-7.92,17.68-17.68,17.68   H133.47c-9.76,0-17.68-7.92-17.68-17.68l0,0C115.79,82.62,123.71,74.7,133.47,74.7z" />
            </g>
        </SVGIcon>
    );
}
