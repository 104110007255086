import React from 'react';

import { SVGIcon } from '../../UIKit/index';

export default function DCTripadvisor(props) {
    return (
        <SVGIcon {...props} viewBox="0 0 98.566 98.566">
            <g>
                <path
                    d="M73.822,41.311c6.735-0.011,12.19,5.415,12.19,12.143c0,6.754-5.362,11.975-11.688,
        12.216c-7.065,0.271-12.718-5.358-12.716-12.186C61.614,46.578,67.307,41.197,73.822,41.311z
        M81.766,53.484c-0.003-4.39-3.552-7.958-7.912-7.958c-4.41,0-8,3.549-8.018,7.926c-0.016,4.358,
        3.592,7.984,7.961,7.996C78.173,61.465,81.768,57.866,81.766,53.484z"
                />
                <path
                    d="M24.417,41.311c6.731-0.007,12.194,5.429,12.177,12.156c-0.019,6.809-5.
        386,11.916-11.601,12.199    c-7.093,0.324-12.816-5.33-12.803-12.195C12.204,46.
        516,17.936,41.195,24.417,41.311z M32.352,53.457    c-0.013-4.417-3.585-7.938-8.
        044-7.93c-4.301,0.008-7.905,3.638-7.895,7.955c0.011,4.367,3.611,7.958,7.992,7.97
        C28.759,61.462,32.363,57.836,32.352,53.457z"
                />
                <path
                    d="M73.828,49.443c2.232-0.002,4.062,1.829,4.041,4.048c-0.022,2.224-1.821,
        4.026-4.016,4.027    c-2.26,0-4.077-1.786-4.072-3.999C69.785,51.223,71.55,49.445,
        73.828,49.443z"
                />
                <path
                    d="M24.396,49.407c2.266-0.002,4.087,1.808,4.096,4.067c0.009,2.24-1.829,
        4.079-4.084,4.084    c-2.309,0.005-4.09-1.779-4.088-4.1C20.32,51.179,22.097,
        49.41,24.396,49.407z"
                />
            </g>
        </SVGIcon>
    );
}
