import React from 'react';

import { SVGIcon } from '../../UIKit/index';

export default function DCCardForm(props) {
    return (
        <SVGIcon {...props} viewBox="0 0 512 462">
            <g transform="translate(-769 -273.653)">
                <g transform="translate(769 249.653)">
                    <path
                        fill="#D9EAFF"
                        d="M503.1,25H10.3c-4.39,0-7.95,3.56-7.95,7.95c0,0,0,0,0,0v445.11c0,4.39,3.56,7.95,7.95,7.95    c0,0,0,0,0,0h492.8c4.39,0,7.95-3.56,7.95-7.95c0,0,0,0,0,0V32.95C511.05,28.56,507.49,25,503.1,25L503.1,25z"
                    />
                    <path
                        fill="#005EFF"
                        d="M503.1,25H10.3c-4.39,0-7.95,3.56-7.95,7.95c0,0,0,0,0,0v63.57h508.7V32.95    C511.05,28.56,507.49,25,503.1,25z"
                    />
                    <path
                        fill="#005EFF"
                        d="M383.88,183.97H129.52c-4.39,0-7.95,3.56-7.95,7.95v158.97c0,4.39,3.56,7.95,7.95,7.95h254.36    c4.39,0,7.95-3.56,7.95-7.95V191.92C391.83,187.53,388.27,183.97,383.88,183.97z"
                    />
                    <rect fill="#FFFFFF" x="139.43" y="199.96" width="234.54" height="141.96" />
                    <g transform="translate(64.07 29.151)">
                        <path
                            fill="#FFFFFF"
                            d="M375.44,43.54h-0.99c-4.39,0-7.95-3.56-7.95-7.95c0-4.39,3.56-7.95,7.95-7.95h0.99     c4.39,0,7.95,3.56,7.95,7.95C383.39,39.98,379.83,43.54,375.44,43.54L375.44,43.54z"
                        />
                        <path
                            fill="#FFFFFF"
                            d="M407.24,43.54h-0.99c-4.39,0-7.95-3.56-7.95-7.95c0-4.39,3.56-7.95,7.95-7.95h0.99     c4.39,0,7.95,3.56,7.95,7.95C415.19,39.98,411.63,43.54,407.24,43.54L407.24,43.54z"
                        />
                        <path
                            fill="#FFFFFF"
                            d="M342.66,43.54h-0.99c-4.39,0-7.95-3.56-7.95-7.95c0-4.39,3.56-7.95,7.95-7.95h0.99     c4.39,0,7.95,3.56,7.95,7.95C350.61,39.98,347.05,43.54,342.66,43.54z"
                        />
                    </g>
                    <g transform="translate(-8.688 4.311)">
                        <path
                            fill="#6B8AAA"
                            d="M359.41,365.64l-30.11-30.11l16.73-16.73c1.84-1.89,1.79-4.92-0.11-6.76     c-0.56-0.54-1.24-0.94-1.99-1.16l-73.61-20.07c-2.56-0.65-5.16,0.89-5.81,3.45c-0.2,0.77-0.2,1.58,0,2.36l20.07,73.61     c0.74,2.53,3.39,3.99,5.92,3.26c0.75-0.22,1.43-0.61,1.99-1.16l16.73-16.73l30.11,30.11c1.86,1.82,4.83,1.82,6.69,0l13.38-13.38     C361.26,370.49,361.26,367.49,359.41,365.64C359.41,365.64,359.41,365.64,359.41,365.64z"
                        />
                        <path
                            fill="#5B7691"
                            d="M346.44,378.61l-30.11-30.11l-16.73,16.73c-1.89,1.84-4.92,1.79-6.76-0.11     c-0.54-0.56-0.94-1.24-1.16-1.99l-19.59-71.84l-1.77-0.48c-2.56-0.65-5.16,0.89-5.81,3.45c-0.2,0.77-0.2,1.58,0,2.36l20.04,73.61     c0.74,2.53,3.39,3.99,5.92,3.26c0.75-0.22,1.43-0.61,1.99-1.16l16.73-16.73l30.11,30.11c1.86,1.82,4.83,1.82,6.69,0l7.1-7.1     C351.24,380.42,348.29,380.41,346.44,378.61z"
                        />
                    </g>
                </g>
                <path
                    fill="#6B8AAA"
                    d="M1212.35,522.83l-25.67,25.67c-1.24,1.24-3.24,1.24-4.48,0l-2.99-2.99   c-1.24-1.24-1.24-3.24,0-4.48l20.71-18.2l-20.69-22.68c-1.24-1.24-1.24-3.24,0-4.48l2.99-2.99c1.24-1.24,3.24-1.24,4.48,0   l25.67,25.67C1213.6,519.58,1213.6,521.59,1212.35,522.83C1212.35,522.83,1212.35,522.83,1212.35,522.83z"
                />
                <path
                    fill="#6B8AAA"
                    d="M839.75,518.35l25.67-25.67c1.24-1.24,3.24-1.24,4.48,0l2.99,2.99   c1.24,1.24,1.24,3.24,0,4.48l-20.71,18.2l20.69,22.68c1.24,1.24,1.24,3.24,0,4.48l-2.99,2.99c-1.24,1.24-3.24,1.24-4.48,0   l-25.67-25.67C838.5,521.59,838.51,519.58,839.75,518.35C839.75,518.35,839.75,518.35,839.75,518.35z"
                />
            </g>
        </SVGIcon>
    );
}
