/**
 * @returns  the user info
 */
export const getUserDetails = () => {
    const authData = JSON.parse(localStorage.getItem('auth-user') || '{}');
    const activeSession = sessionStorage.getItem('active-session') || '';
    return authData[activeSession]?.auth?.user;
};

/**
 * @returns  the account info
 */
export const getAccountDetails = () => {
    const authData = JSON.parse(localStorage.getItem('auth-user') || '{}');
    const activeSession = sessionStorage.getItem('active-session') || '';
    return authData[activeSession]?.auth?.account;
};

/**
 * @returns  the user info
 */
export const getAuthDetails = () => {
    const authData = JSON.parse(localStorage.getItem('auth-user') || '{}');
    const activeSession = sessionStorage.getItem('active-session') || '';
    if (authData.auth) localStorage.removeItem('auth-user');
    return authData[activeSession]?.auth;
};

/**
 * @returns  the user workspace info.
 */
export const getUserWorkspaceInfo = () => {
    const numbers = getUserDetails().sms_numbers
        ? getUserDetails().sms_numbers.map((num: { phoneNumber: String }) => num.phoneNumber)
        : [];
    return {
        type: getAccountDetails().type,
        phone: numbers,
        email: 'no-reply@dashboardnotifications.com',
    };
};

/**
 * @returns {[Object]}
 * @description returns the email from where user can send emails
 */

export const getFromEmails = () => {
    const authData = getUserDetails();
    if (authData?.from_emails) {
        return authData.from_emails.map((email: String, index: Number) => {
            return { id: index, title: email };
        });
    }
    return [{ id: 1, title: 'no-reply@agencynotification.com' }];
};

/**
 * @returns {[Object]}
 * @description returns the sms from where user can send sms
 */

export const getFromSms = () => {
    const authData = getUserDetails();
    if (authData.sms_numbers) {
        return authData.sms_numbers.map((number: { phoneNumber: String }, index: Number) => {
            return { id: index, title: number.phoneNumber };
        });
    }
    return [];
};

/**
 * @returns String
 * @description String returns the user current domain
 */

export const getCurrentDomain = () => {
    return document.location.origin;
};

export const getScopes = () => {
    try {
        const authData = getUserDetails();
        return authData.scope;
    } catch (error) {
        return [];
    }
};

export const getDefaultPage = () => {
    try {
        const userObject = getUserDetails();
        if (userObject.preferences.home_page) {
            return userObject.preferences.home_page;
        }
        return 'dashboard';
    } catch (error) {
        return 'dashboard';
    }
};

export const getDefaultUserImage = () => {
    if (getUserDetails().gender === 'male') {
        return 'https://s3.wasabisys.com/open/general/branding/man.svg';
    } else if (getUserDetails().gender === 'female') {
        return 'https://s3.wasabisys.com/open/general/branding/woman.svg';
    } else {
        return 'https://s3.wasabisys.com/open/general/branding/other.svg';
    }
};

export const updateAppVersion = (val: String) => {
    localStorage.setItem('app-version', JSON.stringify(val));
};

export const getAppVersion = () => {
    return localStorage.getItem('app-version');
};

export const getSessionId = () => {
    const activeSession = sessionStorage.getItem('active-session') || '';
    return activeSession;
};

export const setUserConfigs = (userConfigs: any) => {
    const extVal = JSON.parse(localStorage.getItem('user_configs') || '{}');
    extVal[getSessionId()] = userConfigs;
    localStorage.setItem('user_configs', JSON.stringify(extVal));
};

export const setAuthConfigData = (authConfigsData: any) => {
    const activeSession = sessionStorage.getItem('active-session');
    if (!activeSession) return;
    let sessionData = JSON.parse(localStorage.getItem('auth-user') || '{}');
    sessionData[activeSession] = { auth: authConfigsData };
    localStorage.setItem('auth-user', JSON.stringify(sessionData));
};

export const getUserConfigs = () => {
    const extVal = JSON.parse(localStorage.getItem('user_configs') || '{}');
    return extVal[getSessionId()];
};

export const removeSession = (sessionId: any) => {
    if (!sessionId) sessionId = sessionStorage.getItem('active-session');
    const authData = JSON.parse(localStorage.getItem('auth-user') || '{}');
    if (sessionId) delete authData[sessionId];
    localStorage.setItem('auth-user', JSON.stringify(authData));
    sessionStorage.removeItem('active-session');
};

export const getAllSessions = () => {
    const authData = JSON.parse(localStorage.getItem('auth-user') || '{}');
    return authData;
};

export const clearAllAuthData = () => {
    localStorage.clear();
    sessionStorage.clear();
    return true;
};

export const isLocalStorageAvailable = () => {
    try {
        localStorage.setItem('test', 'test');
        localStorage.removeItem('test');
        return true;
    } catch (e) {
        return false;
    }
};
