import React from 'react';
import { Button, Div, P, Strong } from 'UIKit/index';

class ErrorBoundary extends React.Component {
    constructor(props) {
        super(props);
        this.state = { hasError: false };
    }

    static getDerivedStateFromError(error) {
        // eslint-disable-next-line no-console
        if (error) console.log('error caught in error boundary', error);
        return { hasError: true };
    }

    componentDidCatch() {
        console.error(this.error, this.errorInfo);
    }

    render() {
        const { error, hasError } = this.state;
        if (hasError) {
            return (
                <Div className={'ErrorBoundary'}>
                    <Div className={'EBInner'}>
                        <Div className={'EBIContent'}>
                            {error?.name === 'ChunkLoadError' ? (
                                <Div className={'EBICDes'}>
                                    <P>This application has been updated,</P>
                                    <P>
                                        Please{' '}
                                        <Strong
                                            onClick={() => {
                                                window.location.reload();
                                            }}
                                        >
                                            refresh
                                        </Strong>{' '}
                                        your browser to see the latest content.
                                    </P>
                                </Div>
                            ) : (
                                <Div className={'EBICDes'}>
                                    <P>
                                        Oops! Looks like somethings not working right.
                                        <br />
                                        Please contact your support provider for assistance.
                                    </P>
                                    <Button
                                        onClick={() => {
                                            window.location.href = '/dashboard';
                                        }}
                                    >
                                        Go Back Home
                                    </Button>
                                </Div>
                            )}
                        </Div>
                        <Div className={'EBICTir1'} />
                        <Div className={'EBICTir2'} />
                        <Div className={'EBICTir3'} />
                        <Div className={'EBICTir4'} />
                    </Div>
                </Div>
            );
        }

        return this.props.children;
    }
}
export default ErrorBoundary;
