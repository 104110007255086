import React from 'react';

import { SVGIcon } from '../../UIKit/index';

export default function DCSupport(props) {
    return (
        <SVGIcon {...props} viewBox="0 0 512.005 512.005">
            <g>
                <g transform="translate(-1)">
                    <g transform="translate(1 1)">
                        <path
                            d="M401.119,101.4v349.867c-0.028,9.414-7.652,17.039-17.067,17.067H56.97    c-4.527,0.001-8.868-1.797-12.069-4.997c-3.201-3.201-4.999-7.543-4.997-12.069V24.6c-0.001-4.527,1.796-8.868,4.997-12.069    s7.543-4.999,12.069-4.997h250.283L401.119,101.4z"
                            fill="#a4c2f7"
                        />
                        <path
                            d="M375.519,101.4v349.867c-0.028,9.414-7.653,17.039-17.067,17.067H56.97    c-4.527,0.001-8.868-1.797-12.069-4.997c-3.201-3.201-4.999-7.543-4.997-12.069V24.6c-0.001-4.527,1.796-8.868,4.997-12.069    s7.543-4.999,12.069-4.997h224.683L375.519,101.4z"
                            fill="#e3e7f2"
                        />
                        <path
                            d="M401.119,101.4h-76.8c-9.414-0.028-17.039-7.653-17.067-17.067v-76.8L401.119,101.4z"
                            fill="#a4c2f7"
                        />
                        <path
                            d="M102.452,67.267h17.067c9.426,0,17.067,7.641,17.067,17.067V101.4    c0,9.426-7.641,17.067-17.067,17.067h-17.067c-9.426,0-17.067-7.641-17.067-17.067V84.333    C85.386,74.908,93.027,67.267,102.452,67.267z"
                            fill="#d4e1f4"
                        />
                        <path
                            d="M187.786,67.267h68.267c9.426,0,17.067,7.641,17.067,17.067l0,0    c0,9.426-7.641,17.067-17.067,17.067h-68.267c-9.426,0-17.067-7.641-17.067-17.067l0,0    C170.719,74.908,178.36,67.267,187.786,67.267z"
                            fill="#e3e7f2"
                        />
                        <path
                            d="M102.452,152.6h17.067c9.426,0,17.067,7.641,17.067,17.067v17.067    c0,9.426-7.641,17.067-17.067,17.067h-17.067c-9.426,0-17.067-7.641-17.067-17.067v-17.067    C85.386,160.241,93.027,152.6,102.452,152.6z"
                            fill="#d4e1f4"
                        />
                        <path
                            d="M187.786,152.6h162.133c9.426,0,17.067,7.641,17.067,17.067l0,0    c0,9.426-7.641,17.067-17.067,17.067H187.786c-9.426,0-17.067-7.641-17.067-17.067l0,0    C170.719,160.241,178.36,152.6,187.786,152.6z"
                            fill="#e3e7f2"
                        />
                        <path
                            d="M102.452,237.933h17.067c9.426,0,17.067,7.641,17.067,17.067v17.067    c0,9.426-7.641,17.067-17.067,17.067h-17.067c-9.426,0-17.067-7.641-17.067-17.067V255    C85.386,245.574,93.027,237.933,102.452,237.933z"
                            fill="#d4e1f4"
                        />
                        <path
                            d="M285.236,237.933h-97.451c-9.397,0.068-16.999,7.669-17.067,17.067    c0.028,9.414,7.652,17.039,17.067,17.067h45.909"
                            fill="#e3e7f2"
                        />
                        <path
                            d="M102.452,323.267h17.067c9.426,0,17.067,7.641,17.067,17.067V357.4    c0,9.426-7.641,17.067-17.067,17.067h-17.067c-9.426,0-17.067-7.641-17.067-17.067v-17.067    C85.386,330.908,93.027,323.267,102.452,323.267z"
                            fill="#d4e1f4"
                        />
                        <path
                            d="M203.146,323.267h-15.36c-9.397,0.068-16.999,7.669-17.067,17.067    c0.028,9.414,7.652,17.039,17.067,17.067h8.789"
                            fill="#e3e7f2"
                        />
                        <path
                            d="M469.386,365.933c0.01,36.214-14.372,70.948-39.979,96.555s-60.341,39.989-96.555,39.979    c-4.274,0.018-8.546-0.182-12.8-0.597c-70.137-6.606-123.734-65.488-123.734-135.936s53.597-129.33,123.734-135.936    c4.254-0.416,8.526-0.615,12.8-0.597c36.214-0.01,70.948,14.371,96.555,39.979S469.396,329.719,469.386,365.933z"
                            fill="#a4c2f7"
                        />

                        <linearGradient
                            id="DCSupportID"
                            gradientUnits="userSpaceOnUse"
                            x1="-48.6139"
                            y1="651.3204"
                            x2="-47.8852"
                            y2="650.6571"
                            gradientTransform="matrix(247.4667 0 0 -271.872 12260.2061 177351.5469)"
                        >
                            <stop offset="0" stopColor="#D4E1F4" />
                            <stop offset="0.1717" stopColor="#D4E1F4" />
                            <stop offset="0.2" stopColor="#D4E1F4" />
                            <stop offset="0.2001" stopColor="#DAE4F4" />
                            <stop offset="0.2007" stopColor="#EBEBF4" />
                            <stop offset="0.2014" stopColor="#F6F1F4" />
                            <stop offset="0.2023" stopColor="#FDF4F4" />
                            <stop offset="0.205" stopColor="#FFF5F4" />
                            <stop offset="0.2522" stopColor="#FFF5F4" />
                            <stop offset="0.26" stopColor="#FFF5F4" />
                            <stop offset="0.26" stopColor="#D4E1F4" />
                            <stop offset="0.3974" stopColor="#D4E1F4" />
                            <stop offset="0.42" stopColor="#D4E1F4" />
                            <stop offset="0.4201" stopColor="#DAE4F4" />
                            <stop offset="0.4207" stopColor="#EBEBF4" />
                            <stop offset="0.4214" stopColor="#F6F1F4" />
                            <stop offset="0.4223" stopColor="#FDF4F4" />
                            <stop offset="0.425" stopColor="#FFF5F4" />
                            <stop offset="0.4894" stopColor="#FFF5F4" />
                            <stop offset="0.5" stopColor="#FFF5F4" />
                            <stop offset="0.5" stopColor="#F9F2F4" />
                            <stop offset="0.5001" stopColor="#E8EBF4" />
                            <stop offset="0.5003" stopColor="#DDE5F4" />
                            <stop offset="0.5005" stopColor="#D6E2F4" />
                            <stop offset="0.501" stopColor="#D4E1F4" />
                            <stop offset="0.7062" stopColor="#D4E1F4" />
                            <stop offset="0.74" stopColor="#D4E1F4" />
                            <stop offset="0.741" stopColor="#FFF5F4" />
                            <stop offset="0.8346" stopColor="#FFF5F4" />
                            <stop offset="0.85" stopColor="#FFF5F4" />
                            <stop offset="0.851" stopColor="#D4E1F4" />
                        </linearGradient>
                        <ellipse
                            cx="320.052"
                            cy="365.933"
                            rx="123.733"
                            ry="135.936"
                            fill="url(#DCSupportID)"
                        />
                        <g>
                            <path
                                d="M56.97,468.333h25.6c-4.527,0.001-8.868-1.797-12.069-4.997     c-3.201-3.201-4.999-7.543-4.997-12.069V24.6c-0.001-4.527,1.796-8.868,4.997-12.069s7.543-4.999,12.069-4.997l-25.6,0     C52.443,7.532,48.101,9.33,44.9,12.531s-4.999,7.543-4.997,12.069v426.667c-0.001,4.527,1.797,8.868,4.997,12.069     C48.101,466.537,52.443,468.335,56.97,468.333z"
                                fill="#ffffff"
                            />
                            <path
                                d="M332.852,502.467c4.274,0.018,8.546-0.182,12.8-0.597c-28.706-2.676-55.814-14.422-77.397-33.536     h-25.6c21.562,19.148,48.682,30.899,77.397,33.536C324.306,502.285,328.578,502.485,332.852,502.467z"
                                fill="#ffffff"
                            />
                        </g>
                    </g>
                    <g>
                        <polygon
                            points="52.252,25.6 52.252,136.533 52.252,452.267 35.186,452.267 35.186,136.533 35.186,25.6       "
                            fill="#ffffff"
                        />
                        <g>
                            <path
                                d="M43.719,102.4c3.45-0.014,6.556-2.096,7.879-5.282c1.323-3.187,0.606-6.856-1.82-9.31     l-1.279-1.025c-0.476-0.324-0.993-0.583-1.538-0.771c-0.484-0.246-1.003-0.417-1.538-0.508c-0.563-0.096-1.133-0.153-1.704-0.171     c-2.259,0.031-4.423,0.915-6.059,2.475c-2.07,1.994-2.979,4.908-2.409,7.726c0.57,2.818,2.539,5.149,5.222,6.183     C41.5,102.154,42.603,102.387,43.719,102.4z"
                                fill="#005eff"
                            />
                            <path
                                d="M60.786,477.867h179.78c41.477,34.978,99.017,43.859,149.109,23.013s84.345-67.925,88.763-122.001     c4.418-54.076-21.74-106.091-67.785-134.79V102.4c0-0.183-0.093-0.335-0.104-0.516c-0.107-2.052-0.947-3.997-2.369-5.481     l-0.033-0.043L314.293,2.507l-0.038-0.03c-1.494-1.378-3.41-2.211-5.438-2.364C308.621,0.101,308.453,0,308.252,0H60.786     c-14.132,0.015-25.585,11.468-25.6,25.6v34.133c0,4.713,3.821,8.533,8.533,8.533s8.533-3.821,8.533-8.533V25.6     c0.005-4.711,3.822-8.529,8.533-8.533h238.933v68.267c0.015,14.132,11.468,25.585,25.6,25.6h68.267v123.953     c-34.107-15.643-72.996-17.254-108.281-4.486h-96.519c-14.109,0.07-25.53,11.491-25.6,25.6c0.015,14.132,11.468,25.585,25.6,25.6     h28.011c-7.674,10.495-13.898,21.976-18.505,34.133h-9.506c-14.109,0.07-25.53,11.491-25.6,25.6     c0.015,14.132,11.468,25.585,25.6,25.6c-0.009,34.421,12.284,67.712,34.661,93.867H60.786c-4.711-0.005-8.529-3.822-8.533-8.533     V128c0-4.713-3.821-8.533-8.533-8.533s-8.533,3.821-8.533,8.533v324.267C35.201,466.399,46.654,477.851,60.786,477.867z      M325.319,93.867c-4.711-0.005-8.529-3.822-8.533-8.533v-56.2l64.733,64.733H325.319z M188.786,264.533     c-4.711-0.005-8.529-3.822-8.533-8.533c-0.005-2.298,0.938-4.496,2.608-6.075c1.559-1.591,3.698-2.479,5.925-2.458h62.956     c-7.352,5.05-14.227,10.763-20.538,17.067H188.786z M188.786,349.867c-4.711-0.005-8.529-3.822-8.533-8.533     c-0.005-2.298,0.938-4.496,2.608-6.075c1.559-1.591,3.698-2.479,5.925-2.458h4.224c-1.386,5.621-2.43,11.32-3.128,17.067H188.786     z M333.852,238.933c70.692,0,128,57.308,128,128s-57.308,128-128,128s-128-57.308-128-128     C205.931,296.273,263.193,239.012,333.852,238.933z"
                                fill="#005eff"
                            />
                            <path
                                d="M103.452,128h17.067c14.132-0.015,25.585-11.468,25.6-25.6V85.333     c-0.015-14.132-11.468-25.585-25.6-25.6h-17.067c-14.132,0.015-25.585,11.468-25.6,25.6V102.4     C77.868,116.532,89.32,127.985,103.452,128z M94.919,85.333c0.005-4.711,3.822-8.529,8.533-8.533h17.067     c4.711,0.005,8.529,3.822,8.533,8.533V102.4c-0.005,4.711-3.822,8.529-8.533,8.533h-17.067c-4.711-0.005-8.529-3.822-8.533-8.533     V85.333z"
                                fill="#005eff"
                            />
                            <path
                                d="M188.786,110.933h68.267c14.138,0,25.6-11.462,25.6-25.6s-11.461-25.6-25.6-25.6h-68.267     c-14.139,0-25.6,11.462-25.6,25.6S174.647,110.933,188.786,110.933z M188.786,76.8h68.267c4.713,0,8.533,3.821,8.533,8.533     s-3.821,8.533-8.533,8.533h-68.267c-4.713,0-8.533-3.821-8.533-8.533S184.073,76.8,188.786,76.8z"
                                fill="#005eff"
                            />
                            <path
                                d="M77.852,187.733c0.015,14.132,11.468,25.585,25.6,25.6h17.067     c14.132-0.015,25.585-11.468,25.6-25.6v-17.067c-0.015-14.132-11.468-25.585-25.6-25.6h-17.067     c-14.132,0.015-25.585,11.468-25.6,25.6V187.733z M94.919,170.667c0.005-4.711,3.822-8.529,8.533-8.533h17.067     c4.711,0.005,8.529,3.822,8.533,8.533v17.067c-0.005,4.711-3.822,8.529-8.533,8.533h-17.067     c-4.711-0.005-8.529-3.822-8.533-8.533V170.667z"
                                fill="#005eff"
                            />
                            <path
                                d="M188.786,196.267h162.133c14.138,0,25.6-11.462,25.6-25.6c0-14.138-11.462-25.6-25.6-25.6H188.786     c-14.139,0-25.6,11.462-25.6,25.6C163.186,184.805,174.647,196.267,188.786,196.267z M188.786,162.133h162.133     c4.713,0,8.533,3.821,8.533,8.533c0,4.713-3.82,8.533-8.533,8.533H188.786c-4.713,0-8.533-3.821-8.533-8.533     C180.252,165.954,184.073,162.133,188.786,162.133z"
                                fill="#005eff"
                            />
                            <path
                                d="M77.852,273.067c0.015,14.132,11.468,25.585,25.6,25.6h17.067     c14.132-0.015,25.585-11.468,25.6-25.6V256c-0.015-14.132-11.468-25.585-25.6-25.6h-17.067     c-14.132,0.015-25.585,11.468-25.6,25.6V273.067z M94.919,256c0.005-4.711,3.822-8.529,8.533-8.533h17.067     c4.711,0.005,8.529,3.822,8.533,8.533v17.067c-0.005,4.711-3.822,8.529-8.533,8.533h-17.067     c-4.711-0.005-8.529-3.822-8.533-8.533V256z"
                                fill="#005eff"
                            />
                            <path
                                d="M77.852,358.4c0.015,14.132,11.468,25.585,25.6,25.6h17.067c14.132-0.015,25.585-11.468,25.6-25.6     v-17.067c-0.015-14.132-11.468-25.585-25.6-25.6h-17.067c-14.132,0.015-25.585,11.468-25.6,25.6V358.4z M94.919,341.333     c0.005-4.711,3.822-8.529,8.533-8.533h17.067c4.711,0.005,8.529,3.822,8.533,8.533V358.4c-0.005,4.711-3.822,8.529-8.533,8.533     h-17.067c-4.711-0.005-8.529-3.822-8.533-8.533V341.333z"
                                fill="#005eff"
                            />
                            <path
                                d="M274.119,341.333c2.263,0.001,4.434-0.898,6.035-2.499c1.6-1.6,2.499-3.771,2.499-6.035     c-0.04-13.711,5.459-26.857,15.25-36.454c9.66-9.78,22.942-15.118,36.683-14.742c27.493,0.891,49.572,22.974,50.458,50.467     c0.282,24.216-16.384,45.341-40,50.704c-11.549,2.701-19.719,12.998-19.725,24.858v10.5c0,4.713,3.82,8.533,8.533,8.533     s8.533-3.82,8.533-8.533v-10.5c-0.037-3.882,2.604-7.278,6.375-8.2c28.294-6.329,49.505-29.836,52.901-58.629     c3.397-28.793-11.762-56.591-37.806-69.33c-26.044-12.739-57.293-7.64-77.937,12.717c-13.054,12.799-20.386,30.327-20.333,48.608     c-0.001,2.263,0.898,4.434,2.499,6.035C269.685,340.435,271.856,341.334,274.119,341.333z"
                                fill="#005eff"
                            />
                            <path
                                d="M333.852,443.733c-2.263-0.001-4.434,0.898-6.035,2.499c-1.6,1.6-2.499,3.771-2.499,6.035v8.533     c0,4.713,3.82,8.533,8.533,8.533s8.533-3.82,8.533-8.533v-8.533c0.001-2.263-0.898-4.434-2.499-6.035     C338.287,444.632,336.116,443.733,333.852,443.733z"
                                fill="#005eff"
                            />
                        </g>
                    </g>
                </g>
            </g>
        </SVGIcon>
    );
}
