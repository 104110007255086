import React, { Fragment, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { Div, Popover } from '../index';

import './style.scss';

const ToolTip = ({
    defaultOpen = true,
    customPopoverClass,
    tooltipPopoverClass,
    wrapperClass,
    content,
    children,
    placement,
    withBackground,
    style,
}) => {
    const buttonRef = useRef();
    const [toolTipOpen, setToolTipOpen] = useState(false);

    const handleTooltipOpen = () => {
        setToolTipOpen(true);
    };

    const handleTooltipClose = () => {
        setToolTipOpen(false);
    };

    return (
        <Fragment>
            <Popover
                customClass={
                    'ToolTipPopover ' +
                    (customPopoverClass ? customPopoverClass : '') +
                    (withBackground ? ' withBackgroundTooltip' : '')
                }
                open={toolTipOpen && defaultOpen}
                anchorEl={buttonRef}
                onClose={handleTooltipClose}
                withOutPadding
                placement={placement}
                width={'auto'}
            >
                <Div className={'ToolTip ' + (tooltipPopoverClass ? tooltipPopoverClass : '')}>
                    <Div className={'ToolTipInner'}>{content}</Div>
                </Div>
            </Popover>
            <Div
                className={'ToolTipWrapper ' + (wrapperClass ? wrapperClass : '')}
                reference={buttonRef}
                onMouseOver={handleTooltipOpen}
                onMouseOut={handleTooltipClose}
                style={style}
            >
                {children}
            </Div>
        </Fragment>
    );
};

ToolTip.propTypes = {
    customPopoverClass: PropTypes.string,
    tooltipPopoverClass: PropTypes.string,
    wrapperClass: PropTypes.string,
    content: React.Reactnode,
    children: PropTypes.any,
    placement: PropTypes.string,
    withBackground: PropTypes.bool,
    defaultOpen: PropTypes.bool,
    style: PropTypes.object,
};

export default ToolTip;
