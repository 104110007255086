import React from 'react';

import { SVGIcon } from '../../UIKit/index';

export default function DCTextFolder(props) {
    return (
        <SVGIcon {...props} viewBox="0 0 512 450">
            <g transform="translate(-331.1 -547.037)">
                <g transform="translate(330.153 516.091)">
                    <path
                        d="M498.4,465.4H15.9V47h168.6l64.1,96.1h249.8L498.4,465.4z"
                        fill="none"
                        stroke="#A2A2A2"
                        strokeWidth="31.2197"
                    />
                </g>
                <text
                    transform="matrix(1 0 0 1 432.8422 876.3339)"
                    fontSize="190.7869px"
                    fill="#A2A2A2"
                >
                    A
                </text>
            </g>
        </SVGIcon>
    );
}
