import React from 'react';

import { SVGIcon } from '../../UIKit/index';

export default function DCEducationCap(props) {
    return (
        <SVGIcon {...props} viewBox="0 0 512 512">
            <g>
                <path d="M259.6,69.4c0,0-5.1-1.8-8.3,0L17.8,183.6c0,0-9.9,4-10.1,11c0,0-1.9,9.1,0,16.8c0,0,1.2,4.9,5.6,8.2   L250,335.3c0,0,5,2,9.5,0l233.9-114.1c0,0,8.5-2.5,10.5-10c0,0,2.5-10.4,0-17.8c0,0-0.2-4.8-11.9-10.3L259.3,69.3l-4,28.1l214,105   l-0.6,1.4L257.2,307.2h-3.4L41,203.3l0.9-1.2L255.5,97.6l4.1-28" />
                <path d="M88.5,253.9l0.2,63.9c0,0,0.6,7.8,7,11l143.9,69.9c0,0,18.2,5.2,31.5,0l139.9-68.6c0,0,10.1-2.7,10.9-12.8   v-66.9l-26.5,17.7l-0.1,39.6l-129.8,64.5c0,0-12.4,2.1-21-0.2L115,308l0.1-39.8l-11.7-13L88.5,253.9z" />
                <path d="M441.5,243.4v84.2c0,0-6.8,4-9.1,12l-12,41.4c0,0-2,5.2,0,11.6l10.1,33.1c0,0,5.4,15.1,18.6,17.3   c0,0,9.7,1.9,15.3-1.8c0,0,8.1-5,10.2-11.2l14.2-36.3c0,0,1-6,0-12.6l-12.2-42.7c0,0-3.5-6.9-9.2-10.6V227.3l-16.7,11.9l3.5,118.3   l8.2,27.2l0.1,3.1l-7.6,20.4h-1.2l-7-21.7l-0.3-2.9l7.9-26.2v-18.7l-1.8-64.8l-0.9-36.1L441.5,243.4z" />
            </g>
        </SVGIcon>
    );
}
