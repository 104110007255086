import React from 'react';

import { SVGIcon } from '../../UIKit/index';

export default function DCFacebook1(props) {
    return (
        <SVGIcon {...props} viewBox="0 0 512 512">
            <g transform="translate(-329 -2600)">
                <path
                    fill="#4C7CE2"
                    d="M584.69,2603c140.01,0,253.51,113.5,253.51,253.51s-113.5,253.51-253.51,253.51   s-253.51-113.5-253.51-253.51c0,0,0,0,0,0C331.18,2716.5,444.68,2603,584.69,2603z"
                />
                <path
                    fill="#FFFFFF"
                    d="M635.81,2762.44h26.9v-46.86c-13.01-1.43-26.1-2.12-39.19-2.07   c-85.11,0-61.95,96.38-65.39,110.51h-42.77v52.37h42.8v131.81h52.49V2876.4h41.06l6.51-52.37h-47.6   C612.94,2789.35,601.28,2762.44,635.81,2762.44z"
                />
            </g>
        </SVGIcon>
    );
}
