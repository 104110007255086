import React from 'react';

import { SVGIcon } from '../../UIKit/index';

export default function DCEditForm(props) {
    return (
        <SVGIcon {...props} viewBox="0 0 512 512">
            <g>
                <path d="M352.5,220c0-11-9-20-20-20h-206c-11,0-20,9-20,20s9,20,20,20h206C343.5,240,352.5,231,352.5,220z" />
                <path d="M126.5,280c-11,0-20,9-20,20s9,20,20,20h125.1c11,0,20-9,20-20s-9-20-20-20H126.5z" />
                <path d="M173.5,472h-66.9c-22.1,0-40-17.9-40-40V80c0-22.1,17.9-40,40-40h245.9c22.1,0,40,17.9,40,40v123     c0,11,9,20,20,20s20-9,20-20V80c0-44.1-35.9-80-80-80H106.6c-44.1,0-80,35.9-80,80v352c0,44.1,35.9,80,80,80h66.9     c11,0,20-9,20-20S184.5,472,173.5,472z" />
                <path d="M467.9,289.6c-23.4-23.4-61.5-23.4-84.8,0L273.2,399.1c-2.3,2.3-4.1,5.2-5,8.3l-23.9,78.7     c-2.1,7-0.3,14.6,4.8,19.8c3.8,3.9,9,6,14.3,6c1.8,0,3.6-0.2,5.3-0.7l80.7-22.4c3.3-0.9,6.4-2.7,8.8-5.1l109.6-109.4     C491.3,351,491.3,313,467.9,289.6z M333.8,451.8L293.2,463l11.9-39.1l74.1-73.9l28.3,28.3L333.8,451.8z M439.6,346.1l-3.9,3.9     l-28.3-28.3l3.9-3.9c7.8-7.8,20.5-7.8,28.3,0S447.4,338.3,439.6,346.1z" />
                <path d="M332.5,120h-206c-11,0-20,9-20,20s9,20,20,20h206c11,0,20-9,20-20S343.5,120,332.5,120z" />
            </g>
        </SVGIcon>
    );
}
