import React from 'react';

import { SVGIcon } from '../../UIKit/index';

export default function DCLiveChatFilled(props) {
    return (
        <SVGIcon {...props} viewBox="0 0 612 612">
            <g>
                <g>
                    <path
                        d="M612,251.176c0-117.278-113.775-212.689-253.617-212.689c-139.846,0-253.62,95.412-253.62,212.689    s113.774,212.689,253.62,212.689c11.758,0,23.557-0.681,35.157-2.025c53.864,34.218,93.627,52.947,118.272,55.685    c0.857,0.093,1.72,0.141,2.565,0.141c7.916,0,15.243-3.945,19.606-10.56c4.877-7.4,5.195-16.788,0.851-24.453    c-0.202-0.365-19.221-35.065-13.734-68.211C578.962,373.95,612,314.776,612,251.176z M289.505,283.298    c-20.751,0-37.568-16.821-37.568-37.569c0-20.749,16.817-37.569,37.568-37.569c20.747,0,37.571,16.821,37.571,37.569    C327.077,266.477,310.253,283.298,289.505,283.298z M427.266,283.298c-20.754,0-37.575-16.821-37.575-37.569    c0-20.749,16.821-37.569,37.575-37.569c20.747,0,37.568,16.821,37.568,37.569C464.834,266.477,448.012,283.298,427.266,283.298z"
                        data-original="#000000"
                        class=""
                    />
                    <path
                        d="M158.343,421.342c-25.165-20.837-45.002-45.228-58.952-72.492c-14.709-28.745-22.166-59.323-22.166-90.883    c0-31.561,7.458-62.138,22.166-90.883c1.126-2.2,2.305-4.373,3.506-6.536C40.78,196.136,0,255.532,0,322.675    c0,60.323,32.495,116.253,89.264,153.875c10.485,41.219-13.846,84.828-14.103,85.274c-1.45,2.557-1.343,5.713,0.275,8.167    c1.458,2.212,3.92,3.521,6.533,3.521c0.289,0,0.577-0.017,0.869-0.049c28.402-3.154,78.417-32.021,116.307-56.361    c12.761,1.732,25.806,2.608,38.821,2.608c49.776,0,96.022-12.729,134.265-34.449c-8.544,0.684-17.15,1.049-25.73,1.049    C275.766,486.31,208.944,463.237,158.343,421.342z"
                        data-original="#000000"
                        class=""
                    />
                </g>
            </g>
        </SVGIcon>
    );
}
