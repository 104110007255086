import React from 'react';

import { SVGIcon } from '../../UIKit/index';

export default function DCBlogPost(props) {
    return (
        <SVGIcon {...props} viewBox="0 0 64 64">
            <g>
                <path d="m8 10h2v2h-2z" />
                <path d="m12 10h2v2h-2z" />
                <path d="m16 10h2v2h-2z" />
                <path d="m21 20h-12a1 1 0 0 0 -1 1v10a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-10a1 1 0 0 0 -1-1zm-1 10h-10v-8h10z" />
                <path d="m26 20h4v2h-4z" />
                <path d="m32 20h16v2h-16z" />
                <path d="m26 24h22v2h-22z" />
                <path d="m26 28h10v2h-10z" />
                <path d="m38 28h6v2h-6z" />
                <path d="m8 36h14v2h-14z" />
                <path d="m8 40h14v2h-14z" />
                <path d="m8 44h14v2h-14z" />
                <path d="m8 48h14v2h-14z" />
                <path d="m59 19.091a4.866 4.866 0 0 0 -5 .022v-10.113a3 3 0 0 0 -3-3h-46a3 3 0 0 0 -3 3v46a3 3 0 0 0 3 3h46a3 3 0 0 0 3-3v-17.249l6.83-11.83a5.005 5.005 0 0 0 -1.83-6.83zm-20.155 28.909h-10.845v-10h14.309l-2.639 4.572a.994.994 0 0 0 -.132.564l.212 3.3zm1.289 1.768 1-1.732 1.732 1-1 1.732a1 1 0 0 1 -1.732-1zm4.135-2.232-2.538-1.465-.154-2.4 4.846 2.8zm3.329-2.7-1.732-1 7.5-12.99-1.732-1-7.5 12.99-1.732-1 8.5-14.722 5.2 3zm4.3-19.454.5-.865 5.2 3-.5.866zm-46.898-17.382h46a1 1 0 0 1 1 1v5.032h-48v-5.032a1 1 0 0 1 1-1zm47 47a1 1 0 0 1 -1 1h-46a1 1 0 0 1 -1-1v-38.968h48v5.184l-8.536 14.784h-16.464a1 1 0 0 0 -1 1v12a1 1 0 0 0 1 1h11.02a3 3 0 0 0 5.58 1.768l1.348-2.336 2.96-1.464a1 1 0 0 0 .423-.4l3.669-6.353zm7.1-30.079-.5.866-5.2-3 .5-.866a3 3 0 0 1 5.2 3z" />
            </g>
        </SVGIcon>
    );
}
