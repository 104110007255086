import React from 'react';

import { SVGIcon } from '../../UIKit/index';

export default function DCLogo(props) {
    return (
        <SVGIcon {...props} viewBox="0 0 892 171">
            <g>
                <path d="M313.68,28.93v93.13h-15.19l-1.07-9.06c-5.06,7.86-13.19,10.52-21.18,10.52c-19.32,0-33.84-12.79-33.84-34.37   c0-22.65,14.26-34.37,33.44-34.37c6.93,0,17.72,3.73,21.58,10.53V28.93H313.68z M258.66,89.15c0,11.46,8.26,19.32,18.65,19.32   c10.26,0,19.05-7.46,19.05-19.32c0-11.46-8.79-19.18-19.05-19.18C266.92,69.97,258.66,77.3,258.66,89.15z" />
                <path d="M379.89,56.38h15.59v65.68h-15.32l-0.8-9.59c-3.73,7.73-13.99,11.46-21.32,11.59   c-19.45,0.13-33.84-11.86-33.84-34.91c0-22.65,15.05-34.51,34.24-34.37c8.79,0,17.19,4.13,20.92,10.66L379.89,56.38z M340.45,89.15   c0,12.52,8.66,19.99,19.45,19.99c25.58,0,25.58-39.84,0-39.84C349.12,69.3,340.45,76.63,340.45,89.15z" />
                <path d="M452.1,73.83c-4.66-4.4-9.99-5.86-16.25-5.86c-7.73,0-11.99,2.4-11.99,6.53c0,4.26,3.86,6.66,12.26,7.19   c12.39,0.8,28.11,3.6,28.11,21.05c0,11.59-9.46,21.58-28.25,21.58c-10.39,0-20.78-1.73-30.38-11.73l7.99-11.59   c4.66,5.2,15.32,9.06,22.65,9.19c6.13,0.13,11.86-3.06,11.86-7.86c0-4.53-3.73-6.4-13.06-6.93c-12.39-0.93-27.18-5.46-27.18-20.38   c0-15.19,15.72-20.52,27.71-20.52c10.26,0,17.99,2,25.58,8.66L452.1,73.83z" />
                <path d="M490.86,28.8v36.5c5.86-7.59,13.06-9.86,20.52-9.86c18.65,0,26.91,12.66,26.91,31.98v34.64h-16.25V87.55   c0-11.99-6.26-17.05-14.92-17.05c-9.59,0-16.25,8.13-16.25,17.99v33.57h-16.25V28.8H490.86z" />
            </g>
            <g>
                <path d="M635.41,110.34c-9.59,9.46-21.98,13.86-35.44,13.86c-34.64,0-49.29-23.85-49.43-48.1   c-0.13-24.38,15.72-49.16,49.43-49.16c12.66,0,24.65,4.8,34.24,14.25l-11.73,11.32c-6.13-6-14.39-8.79-22.51-8.79   c-22.52,0-32.24,16.79-32.11,32.37c0.13,15.45,9.06,31.57,32.11,31.57c8.13,0,17.32-3.33,23.45-9.46L635.41,110.34z" />
                <path d="M658.86,28.93v93.13h-16.12V28.93H658.86z" />
                <path d="M690.83,37.86c0,12.66-19.19,12.66-19.19,0C671.65,25.2,690.83,25.2,690.83,37.86z M673.11,56.11v65.95h16.25   V56.11H673.11z" />
                <path d="M760.37,113.67c-7.59,7.46-15.59,10.39-25.31,10.39c-19.05,0-34.91-11.46-34.91-34.77   c0-23.32,15.85-34.77,34.91-34.77c9.33,0,16.52,2.66,23.71,9.73l-10.26,10.79c-3.86-3.46-8.66-5.2-13.19-5.2   c-10.92,0-18.92,7.99-18.92,19.45c0,12.52,8.53,19.19,18.65,19.19c5.2,0,10.39-1.47,14.39-5.46L760.37,113.67z" />
                <path d="M783.42,28.93v53.82l22.12-26.38h19.45v0.93l-26.78,29.98l30.51,33.57v1.2h-19.58l-25.71-29.71v29.71h-16.25   V28.93H783.42z" />
                <path d="M879.07,73.83c-4.66-4.4-9.99-5.86-16.25-5.86c-7.73,0-11.99,2.4-11.99,6.53c0,4.26,3.86,6.66,12.26,7.19   c12.39,0.8,28.11,3.6,28.11,21.05c0,11.59-9.46,21.58-28.24,21.58c-10.39,0-20.78-1.73-30.38-11.73l7.99-11.59   c4.66,5.2,15.32,9.06,22.65,9.19c6.13,0.13,11.86-3.06,11.86-7.86c0-4.53-3.73-6.4-13.06-6.93c-12.39-0.93-27.18-5.46-27.18-20.38   c0-15.19,15.72-20.52,27.71-20.52c10.26,0,17.99,2,25.58,8.66L879.07,73.83z" />
            </g>
            <g>
                <path d="M220.98,90c0.02-34.61-18.63-61.77-47.23-72.73c-28.42-10.89-60.67-3-81.08,19.83   c-0.4,0.44-0.77,0.91-1.19,1.33c-2.59,2.59-5.6,3.1-8.34,0.66c-2.78-2.46-2.57-5.29-0.06-8.19c13.47-15.58,30.37-25.22,50.6-28.6   c43.85-7.31,85.94,20.67,96.45,63.8c11.94,49.02-21.69,97.82-71.94,103.96c-29.6,3.61-54.4-6.22-74.43-28.2   c-3.14-3.45-3.29-6.57-0.71-8.9c2.65-2.4,6.19-1.78,9.29,1.63c27.24,29.92,70.54,33.66,101.69,8.38   C211.94,128.42,220.61,109.12,220.98,90" />
                <path d="M0.51,91.31V80.75c2.14,0,4.02,0,5.9,0c51.49,0,102.98-0.02,154.47,0.04c3.27,0,5.58-0.39,7.77-3.54   c4.77-6.88,14.75-7.31,20.81-1.5c5.94,5.69,5.88,15.07-0.15,20.69c-6.13,5.72-16,5.1-20.82-1.77c-1.91-2.73-3.85-3.42-6.92-3.41   c-51.64,0.08-103.28,0.06-154.92,0.06C4.75,91.31,2.84,91.31,0.51,91.31" />
                <path d="M41.26,61.57V51.21c1.77,0,3.48,0,5.18,0c24.1,0,48.19-0.03,72.29,0.04c2.57,0.01,4.22-0.51,5.79-2.9   c4.67-7.1,14.23-8.27,20.7-2.91c6.34,5.25,6.74,15.2,0.85,20.98c-6.04,5.92-15.71,5.65-20.79-1.22c-2.33-3.16-4.76-3.7-8.2-3.69   c-23.35,0.09-46.71,0.05-70.06,0.05H41.26z" />
                <path d="M41.27,120.85v-10.03c1.77-0.11,3.47-0.31,5.17-0.31c23.8-0.02,47.6-0.04,71.4,0.02   c2.96,0.01,5.12-0.39,7.06-3.24c3.72-5.46,10.22-7.09,16.23-4.88c5.57,2.05,9.21,7.45,9.09,13.52c-0.12,6.33-3.92,11.44-9.93,13.35   c-5.97,1.91-12.4-0.2-15.92-5.83c-1.48-2.37-3.16-2.63-5.49-2.62c-23.95,0.04-47.9,0.03-71.85,0.03   C45.28,120.85,43.52,120.85,41.27,120.85" />
            </g>
        </SVGIcon>
    );
}
