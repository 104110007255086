import React from 'react';

import { SVGIcon } from '../../UIKit/index';

export default function DCFacebookMessenger(props) {
    return (
        <SVGIcon {...props} viewBox="-21 -28 682.66669 682">
            <g>
                <path d="m545.601562 84.628906c-60.359374-54.628906-140.476562-84.7109372-225.601562-84.7109372s-165.242188 30.0820312-225.601562 84.7109372c-60.875 55.097656-94.398438 128.503906-94.398438 206.703125 0 58.578125 18.863281 114.742188 54.632812 162.941407l-27.492187 171.914062 173.917969-64.246094c37.828125 13.808594 77.804687 20.808594 118.941406 20.808594 85.125 0 165.242188-30.085938 225.601562-84.714844 60.875-55.097656 94.398438-128.503906 94.398438-206.703125s-33.523438-151.605469-94.398438-206.703125zm-197.554687 290.398438-70.738281-55.34375-169.203125 66.964844 193.605469-192.5625 71.59375 57.167968 154.875-60.046875zm0 0" />
            </g>
        </SVGIcon>
    );
}
