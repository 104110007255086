import React from 'react';

import { SVGIcon } from '../../UIKit/index';

export default function DCSignOrder(props) {
    return (
        <SVGIcon {...props} viewBox="0 0 512 512">
            <path d="M512,341.2V83.7c0-46.4-37.8-84.2-84.2-84.2s-84.2,37.8-84.2,84.2v257.5c0,2.1,0.4,4.4,1.3,6.4l59.8,133.9H45.1  c-8,0-15.2-6.4-15.2-15.4c0-8.4,6.8-15.2,15.2-15.2h176.5c24.9,0,45.2-20.3,45.2-45.2s-20.3-45.2-45.2-45.2h-94.1  c-8.3,0-15,6.7-15,15s6.7,15,15,15h94.1c8.4,0,15.2,6.8,15.2,15.2s-6.8,15.2-15.2,15.2H45.1C20.6,420.9,0,440.7,0,466.3  c0,24.9,20.3,45.2,45.2,45.2h382.6c6.2,0,11.4-3.7,13.8-9.2l69.1-154.7C511.5,345.7,512,343.4,512,341.2L512,341.2z M459.3,389  h-63.2l-14.5-32.5h92.2L459.3,389z M427.7,459.7L409.6,419h36.4L427.7,459.7z M412.7,110.8v215.7h-39.2V110.8L412.7,110.8z   M482,110.8v215.7h-39.2V110.8L482,110.8z M427.7,29.5c28.9,0,52.6,22.8,54.1,51.3H373.6C375.1,52.3,398.8,29.5,427.7,29.5z" />
        </SVGIcon>
    );
}
