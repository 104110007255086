/* eslint-disable   */
import React, { useRef, useState, useEffect } from 'react';
import { Div, Button, IconButton, Popover, Strong, Skeleton } from '../../UIKit/index';
import { makeId, formatNumberString } from '../../Utils';
import DCArrowBack from '../../Assets/icons/DCArrowBack';
import DCArrowNext from '../../Assets/icons/DCArrowNext';
import DCFillDownArray from '../../Assets/icons/DCFillDownArray';
import './style.scss';
import PropTypes from 'prop-types';

const Pagination = props => {
    const {
        classes,
        page,
        totalPages,
        handlePageNumber,
        limit,
        handleChangeLimit,
        totalResults,
        loader,
        limits = [25, 50, 100],
    } = props;

    const [displayPages, setDisplayPages] = useState([]);

    const buttonRef = useRef();
    const [popoverOpen, setPopoverOpen] = useState(false);
    const [loading, setLoading] = useState(true);

    const handlePopoverToggle = () => {
        setPopoverOpen(prevOpen => !prevOpen);
    };

    const handlePopoverClose = () => {
        setPopoverOpen(false);
    };
    useEffect(() => {
        setLoading(loader);
    }, [loader]); //totalPages,

    useEffect(() => {
        let pager = [];
        if (totalPages > 0) {
            pager = renderPages(totalPages, 1);
        }
        setDisplayPages(pager);
    }, [limit, totalPages]);

    const [currentPage, setCurrentPage] = useState(1);

    useEffect(() => {
        if (page) {
            const pager = renderPages(totalPages, page);
            setDisplayPages([...pager]);
            setCurrentPage(page);
        }
    }, [page, totalPages]);

    if (totalPages === 0) {
        return <Div></Div>;
    }

    return (
        <Div className={`PaginationMain ${classes?.main ? classes.main : ''}`}>
            {loading === true ? (
                <Skeleton height={38} />
            ) : (
                <Div className={`PMInner ${classes?.inner ? classes.inner : ''}`}>
                    <Div className={`PMTotal ${classes?.total ? classes.total : ''}`}>
                        Showing {formatNumberString((currentPage - 1) * limit + 1)} to{' '}
                        {currentPage === totalPages
                            ? formatNumberString(totalResults)
                            : formatNumberString(currentPage * limit)}{' '}
                        of <Strong>{formatNumberString(totalResults)} entries</Strong>
                    </Div>
                    <Div className={`PMPages ${classes?.pages ? classes.pages : ''}`}>
                        <IconButton
                            customClass="PaginationPNBtn"
                            disabled={currentPage === 1 ? true : false}
                            onClick={() => {
                                setCurrentPage(currentPage - 1);
                                handlePageNumber(currentPage - 1);
                            }}
                        >
                            <DCArrowBack />
                        </IconButton>
                        {displayPages.length > 0 ? (
                            displayPages.map((page, i) => (
                                <Button
                                    buttonClass={`PaginationBtn ${
                                        page === currentPage ? ' active ' : ''
                                    } ${page === '...' ? ' PaginationBtnDisabled' : ''}`}
                                    key={makeId(i)}
                                    onClick={() => {
                                        handlePageNumber(page);
                                        const pager = renderPages(totalPages, page);
                                        setDisplayPages([...pager]);
                                        setCurrentPage(page);
                                    }}
                                >
                                    {page !== '...' ? formatNumberString(page) : '...'}
                                </Button>
                            ))
                        ) : (
                            <Skeleton width={30} height={36} />
                        )}
                        <IconButton
                            customClass="PaginationPNBtn"
                            disabled={currentPage === totalPages ? true : false}
                            onClick={() => {
                                setCurrentPage(currentPage + 1);
                                handlePageNumber(currentPage + 1);
                            }}
                        >
                            <DCArrowNext />
                        </IconButton>
                    </Div>
                    <Div className={`PMRowView ${classes?.rowView ? classes.rowView : ''}`}>
                        <Button
                            buttonClass="PMRowViewBtn"
                            iconName={<DCFillDownArray />}
                            iconPlacement="right"
                            reference={buttonRef}
                            onClick={handlePopoverToggle}
                        >
                            Show {formatNumberString(limit)} Per Page
                        </Button>
                        <Popover
                            open={popoverOpen}
                            anchorEl={buttonRef}
                            onClose={handlePopoverClose}
                            width={100}
                            withOutPadding
                            placement="top-end"
                        >
                            <Div className="PaginationSelect">
                                {limits.map(el => (
                                    <Button
                                        buttonClass={limit === el ? 'active' : ''}
                                        onClick={() => {
                                            setCurrentPage(1);
                                            handleChangeLimit(el);
                                            handlePopoverClose();
                                        }}
                                    >
                                        {el}
                                    </Button>
                                ))}
                            </Div>
                        </Popover>
                    </Div>
                </Div>
            )}
        </Div>
    );
};

const renderPages = (n, c) => {
    const showSDots = c > 4;
    const showEDots = c < n - 3;

    let r = [];
    r.push(1);
    if (showSDots) {
        r.push('...');
    } else {
        for (let i = 2; i < c - 1; i++) {
            r.push(i);
        }
    }
    if (c > 2) r.push(c - 1);

    if (c !== 1 && c !== n) r.push(c);

    if (c < n - 1) r.push(c + 1);
    if (showEDots) {
        r.push('...');
    } else {
        for (let i = c + 2; i < n; i++) {
            r.push(i);
        }
    }
    r.push(n);
    if (r.length === 2 && r[0] === r[1]) {
        return [r[0]];
    }

    return r;
};

Pagination.defaultProps = {
    initialPage: 1,
};

Pagination.propTypes = {
    classes: PropTypes.object,
    page: PropTypes.number,
    totalPages: PropTypes.number,
    handlePageNumber: PropTypes.func,
    limit: PropTypes.number,
    handleChangeLimit: PropTypes.func,
    totalResults: PropTypes.number,
    loader: PropTypes.bool,
};

export default Pagination;
