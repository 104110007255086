import React from 'react';

import { SVGIcon } from '../../UIKit/index';

export default function DCTrackedSeoKeywords(props) {
    return (
        <SVGIcon {...props} viewBox="0 0 504.009 364.695">
            <g id="Tracked_SEO_Keywords" transform="translate(-4.141 -69.205)">
                <ellipse
                    id="_Path_"
                    data-name="&lt;Path&gt;"
                    cx="235.54"
                    cy="23.77"
                    rx="235.54"
                    ry="23.77"
                    transform="translate(7.66 386.36)"
                    fill="#f4f5f6"
                />
                <path
                    id="Path_1"
                    data-name="Path 1"
                    d="M440.59,239.33a16.85,16.85,0,1,1-14.1-19.21,16.85,16.85,0,0,1,14.1,19.21Z"
                    fill="#f2f2f2"
                />
                <path
                    id="Path_2"
                    data-name="Path 2"
                    d="M478.55,142.11a16.84,16.84,0,1,1-3.068-12.528,16.85,16.85,0,0,1,3.068,12.528Z"
                    fill="#f4f5f6"
                />
                <path
                    id="Path_3"
                    data-name="Path 3"
                    d="M30.27,380l-8.35-2.55L87.59,201.28a8.78,8.78,0,0,1,16.41-1l36.81,82L195.16,120.8a8.78,8.78,0,0,1,16.32-.83L287,286.41,349.8,87.13a8.78,8.78,0,0,1,16.6-.44l53.05,141.42a3.29,3.29,0,0,0,6.15,0L460,137.26l8.16,3.09L430.7,239.23a8.78,8.78,0,0,1-16.43,0L361.52,98.53a3.28,3.28,0,0,0-6.21.16L288.48,310.78,206.92,131a3.29,3.29,0,0,0-6.11.31L142,306.15,99.56,211.67a3.28,3.28,0,0,0-6.14.39Z"
                    fill="#f4f5f6"
                />
                <path
                    id="Path_4"
                    data-name="Path 4"
                    d="M81.6,371.6s2.92,35.74,20.64,37.91H75.69V371.86Z"
                    fill="#f79225"
                />
                <path
                    id="Path_5"
                    data-name="Path 5"
                    d="M81.6,371.6s2.92,35.74,20.64,37.91H75.69V371.86Z"
                    fill="#e4e3e3"
                />
                <path
                    id="Path_6"
                    data-name="Path 6"
                    d="M69.73,371.6s-2.92,35.74-20.65,37.91H75.64a54.72,54.72,0,0,0,3.09-24.71,53.35,53.35,0,0,0-3.09-12.94Z"
                    fill="#e4e3e3"
                />
                <path
                    id="Path_7"
                    data-name="Path 7"
                    d="M108.28,409.51H43a9.41,9.41,0,0,1,9.41-9.41H98.87a9.41,9.41,0,0,1,9.41,9.41Z"
                    fill="#707272"
                />
                <path
                    id="Path_8"
                    data-name="Path 8"
                    d="M125.28,285.47H26.1s-1.17,87.63,48.25,90H77C126.44,373.1,125.28,285.47,125.28,285.47Z"
                    fill="#545554"
                />
                <path
                    id="Path_9"
                    data-name="Path 9"
                    d="M104.4,357.16l-2.16-4.85c.45-.2,44.39-20,40.46-42.25-.82-4.65-2.57-7.47-5.18-8.38-6.61-2.29-18.61,6.6-22.29,9.9l-3.54-4c1.69-1.51,16.82-14.67,27.57-10.95,4.57,1.58,7.48,5.77,8.67,12.47C152.58,335.5,106.36,356.29,104.4,357.16Z"
                    fill="#545554"
                />
                <path
                    id="Path_10"
                    data-name="Path 10"
                    d="M48,357.16c-2-.87-48.18-21.66-43.53-48,1.18-6.7,4.1-10.89,8.67-12.47C23.89,293,39,306.11,40.72,307.62l-3.54,4,1.77-2-1.77,2c-4-3.53-15.67-12.19-22.3-9.9-2.61.91-4.35,3.73-5.17,8.38-3.94,22.29,40,42.05,40.45,42.25Z"
                    fill="#545554"
                />
                <path
                    id="Path_11"
                    data-name="Path 11"
                    d="M124.07,286H27.3a5.55,5.55,0,0,1-5.55-5.55h0a5.56,5.56,0,0,1,5.55-5.55h96.77a5.56,5.56,0,0,1,5.56,5.55h0a5.55,5.55,0,0,1-5.56,5.55Z"
                    fill="#e4e3e3"
                />
                <path
                    id="Path_12"
                    data-name="Path 12"
                    d="M75.8,298.88l5.09,10.32,11.4,1.66a.12.12,0,0,1,.06.2l-8.24,8,1.94,11.34a.12.12,0,0,1-.17.13l-10.19-5.36-10.19,5.4a.12.12,0,0,1-.18-.13l2-11.34-8.24-8a.11.11,0,0,1,.06-.2l11.4-1.66,5.09-10.32a.12.12,0,0,1,.17-.04Z"
                    fill="#e4e3e3"
                />
                <path
                    id="Path_13"
                    data-name="Path 13"
                    d="M437.45,228.14a70.67,70.67,0,1,0,70.7,70.68,70.67,70.67,0,0,0-70.7-70.68Zm0,118.31a47.64,47.64,0,1,1,36.69-78c5.07,1.61,8.27,7.31,8.09,12.44a8.954,8.954,0,0,1-.12,1.28,47.64,47.64,0,0,1-44.66,64.31Z"
                    fill="#e4e3e3"
                />
                <path
                    id="Path_14"
                    data-name="Path 14"
                    d="M437.45,240.08a58.73,58.73,0,1,0,58.76,58.74,58.73,58.73,0,0,0-58.76-58.74Zm0,94.43a35.71,35.71,0,1,1,9.79-70,14.32,14.32,0,0,1,8.48-2.77c6.7,0,11.2,6.83,11,12.89a10.629,10.629,0,0,1-.54,3,35.68,35.68,0,0,1-28.71,56.93Z"
                    fill="#fffefe"
                />
                <path
                    id="Path_15"
                    data-name="Path 15"
                    d="M437.45,252a46.83,46.83,0,1,0,46.85,46.837A46.83,46.83,0,0,0,437.45,252Zm25.7,42.24a10.22,10.22,0,0,1-1.9,5.65,23.84,23.84,0,1,1-7.51-18.41c5.83.94,9.62,7.16,9.41,12.74Z"
                    fill="#e4e3e3"
                />
                <path
                    id="Path_16"
                    data-name="Path 16"
                    d="M437.45,264.58a34.253,34.253,0,1,1-.007,0Zm12.62,35.09c-.24,6.82-6.64,11-12.88,11a10.63,10.63,0,0,1-9.1-5.68,11,11,0,0,1-1.83-5.71,10.742,10.742,0,0,1-.05-1.5c.25-6.82,6.65-11,12.89-11,6.7,0,11.19,6.83,10.97,12.89Z"
                    fill="#fffefe"
                />
                <path
                    id="Path_17"
                    data-name="Path 17"
                    d="M437.45,277.36a21.45,21.45,0,1,0,21.47,21.45A21.45,21.45,0,0,0,437.45,277.36Z"
                    fill="#e4e3e3"
                />
                <circle
                    id="Ellipse_1"
                    data-name="Ellipse 1"
                    cx="33.85"
                    cy="33.85"
                    r="33.85"
                    transform="translate(84.65 117.75)"
                    fill="#e4e3e3"
                />
                <path
                    id="Path_18"
                    data-name="Path 18"
                    d="M109.34,151.6V137.04l12.61,7.28,12.61,7.28-12.61,7.28-12.61,7.28Z"
                    fill="#707272"
                />
                <rect
                    id="Rectangle_1"
                    data-name="Rectangle 1"
                    width="71.53"
                    height="71.53"
                    transform="translate(223.81 319.7)"
                    fill="#e4e3e3"
                />
                <path
                    id="Path_19"
                    data-name="Path 19"
                    d="M295.33,391.23H223.81l-13.82,13.09h99.16Z"
                    fill="#707272"
                />
                <path
                    id="Path_20"
                    data-name="Path 20"
                    d="M130.57,270h258v80.5a15.37,15.37,0,0,1-15.37,15.37H145.94a15.37,15.37,0,0,1-15.37-15.37Z"
                    fill="#e4e3e3"
                />
                <path
                    id="Path_21"
                    data-name="Path 21"
                    d="M148.94,186.63H370.2A18.37,18.37,0,0,1,388.57,205V344.14h-258V205a18.37,18.37,0,0,1,18.37-18.37Z"
                    fill="#545554"
                />
                <rect
                    id="Rectangle_2"
                    data-name="Rectangle 2"
                    width="236.17"
                    height="139.91"
                    rx="7.43"
                    transform="translate(141.49 195.43)"
                    fill="#e4e3e3"
                />
                <path
                    id="Path_22"
                    data-name="Path 22"
                    d="M265.25,354.94a6.86,6.86,0,1,1-6.85-6.85,6.86,6.86,0,0,1,6.85,6.85Z"
                    fill="#707272"
                />
                <rect
                    id="Rectangle_3"
                    data-name="Rectangle 3"
                    width="99.16"
                    height="5.81"
                    transform="translate(209.99 404.32)"
                    fill="#e4e3e3"
                />
                <path
                    id="Path_23"
                    data-name="Path 23"
                    d="M159.14,220.77H391.4A21.58,21.58,0,0,1,413,242.36v3A21.58,21.58,0,0,1,391.4,267H159.14Z"
                    fill="#fff"
                />
                <path
                    id="Path_24"
                    data-name="Path 24"
                    d="M366.77,220.77H391.4A21.58,21.58,0,0,1,413,242.36v3A21.58,21.58,0,0,1,391.4,267H366.77Z"
                    fill="#707272"
                />
                <path
                    id="Path_25"
                    data-name="Path 25"
                    d="M170.83,249.22l1.27-.75c.88,1.63,1.91,2.45,3.07,2.45a2.94,2.94,0,0,0,1.4-.35,2.46,2.46,0,0,0,1.35-2.18,2.51,2.51,0,0,0-.51-1.46,12,12,0,0,0-2.54-2.38,12.47,12.47,0,0,1-2.31-2,3.73,3.73,0,0,1-.79-2.27,3.43,3.43,0,0,1,.47-1.76,3.35,3.35,0,0,1,1.3-1.26,3.82,3.82,0,0,1,1.83-.46,4,4,0,0,1,2,.52,6.87,6.87,0,0,1,1.93,1.91l-1.21.92a6,6,0,0,0-1.43-1.46,2.41,2.41,0,0,0-1.29-.35,2,2,0,0,0-1.46.54,1.78,1.78,0,0,0-.57,1.34,2.18,2.18,0,0,0,.2.94,3.55,3.55,0,0,0,.73,1c.2.19.84.68,1.92,1.48a10.56,10.56,0,0,1,2.64,2.54,4.06,4.06,0,0,1,.72,2.23,3.77,3.77,0,0,1-1.23,2.8,4.12,4.12,0,0,1-3,1.19,4.39,4.39,0,0,1-2.45-.72,6.65,6.65,0,0,1-2.04-2.46Z"
                    fill="#707272"
                />
                <path
                    id="Path_26"
                    data-name="Path 26"
                    d="M191.67,248.34l1.19.63a6.31,6.31,0,0,1-1.36,1.86,5.23,5.23,0,0,1-1.72,1.07,6,6,0,0,1-2.17.37,5.26,5.26,0,0,1-4.2-1.76,6,6,0,0,1-.24-7.7,5.25,5.25,0,0,1,4.36-2.08,5.41,5.41,0,0,1,4.48,2.13,5.93,5.93,0,0,1,1.21,3.75h-9.88a4.47,4.47,0,0,0,1.23,3.13,3.87,3.87,0,0,0,2.92,1.23,4.83,4.83,0,0,0,1.63-.29,4.5,4.5,0,0,0,1.34-.78A6.25,6.25,0,0,0,191.67,248.34Zm0-3a4.75,4.75,0,0,0-.83-1.81,3.89,3.89,0,0,0-1.43-1.09,4.42,4.42,0,0,0-1.86-.41,4,4,0,0,0-2.78,1,4.56,4.56,0,0,0-1.28,2.27Z"
                    fill="#707272"
                />
                <path
                    id="Path_27"
                    data-name="Path 27"
                    d="M206.79,241v11H205.4v-1.9a6,6,0,0,1-2,1.62,5.27,5.27,0,0,1-2.41.55,5.34,5.34,0,0,1-4-1.69,5.67,5.67,0,0,1-1.65-4.11,5.58,5.58,0,0,1,1.66-4.06,5.45,5.45,0,0,1,4-1.68,5.24,5.24,0,0,1,2.45.57,5.69,5.69,0,0,1,1.95,1.7v-2Zm-5.65,1.08a4.27,4.27,0,0,0-3.76,2.21,4.46,4.46,0,0,0-.59,2.21,4.409,4.409,0,0,0,.6,2.219,4.28,4.28,0,0,0,1.59,1.651,4.22,4.22,0,0,0,2.15.59,4.4,4.4,0,0,0,2.2-.59,4.05,4.05,0,0,0,1.6-1.58,4.52,4.52,0,0,0,.56-2.25,4.34,4.34,0,0,0-1.26-3.18,4.15,4.15,0,0,0-3.09-1.27Z"
                    fill="#707272"
                />
                <path
                    id="Path_28"
                    data-name="Path 28"
                    d="M209.43,241h1.45v1.6a5,5,0,0,1,1.36-1.41,2.6,2.6,0,0,1,1.49-.47,2.54,2.54,0,0,1,1.25.37l-.74,1.19a1.87,1.87,0,0,0-.74-.19,2,2,0,0,0-1.36.58,3.69,3.69,0,0,0-1,1.8,17,17,0,0,0-.26,3.79V252h-1.45Z"
                    fill="#707272"
                />
                <path
                    id="Path_29"
                    data-name="Path 29"
                    d="M226.83,243.29l-1.12.7a4.66,4.66,0,0,0-4-1.93,4.6,4.6,0,0,0-3.33,1.29,4.21,4.21,0,0,0-1.32,3.13,4.41,4.41,0,0,0,.61,2.26,4.25,4.25,0,0,0,1.67,1.64,4.85,4.85,0,0,0,2.38.59,4.8,4.8,0,0,0,4-1.93l1.12.74a5.25,5.25,0,0,1-2.11,1.84,6.8,6.8,0,0,1-3,.65,6,6,0,0,1-4.32-1.66,5.37,5.37,0,0,1-1.71-4,5.8,5.8,0,0,1,3-5.1,6.45,6.45,0,0,1,3.14-.76,6.689,6.689,0,0,1,2.1.33,5.66,5.66,0,0,1,1.72.87,4.39,4.39,0,0,1,1.17,1.34Z"
                    fill="#707272"
                />
                <path
                    id="Path_30"
                    data-name="Path 30"
                    d="M229.55,236.77H231V243a5.84,5.84,0,0,1,1.89-1.69,4.5,4.5,0,0,1,2.23-.56,3.92,3.92,0,0,1,2.18.62,3.72,3.72,0,0,1,1.41,1.68,9,9,0,0,1,.45,3.31V252h-1.41v-5.24a13.479,13.479,0,0,0-.15-2.53,2.9,2.9,0,0,0-.95-1.64,2.84,2.84,0,0,0-1.82-.55,3.46,3.46,0,0,0-2.29.85,3.94,3.94,0,0,0-1.34,2.1,14.77,14.77,0,0,0-.2,3v4h-1.41Z"
                    fill="#707272"
                />
                <path
                    id="Path_31"
                    data-name="Path 31"
                    d="M241.42,235.53h.85v18.9h-.85Z"
                    fill="#707272"
                />
                <path
                    id="Path_32"
                    data-name="Path 32"
                    d="M244.13,287.39a53.89,53.89,0,1,0-80.68-5.1l-16.16,16.16-2.55-2.54-63,63L96.4,373.57l63-63L156.86,308,173,291.86a53.88,53.88,0,0,0,71.13-4.47Zm-69-69a43.69,43.69,0,1,1,0,61.79,43.69,43.69,0,0,1,0-61.79Z"
                    fill="#707272"
                />
                <path
                    id="Path_33"
                    data-name="Path 33"
                    d="M363.89,114c24.09,0,43.61,14.23,43.61,31.78,0,7.11-3.21,13.68-8.63,19l7.68,12.79-15-7.18a54.22,54.22,0,0,1-27.63,7.18c-24.08,0-43.61-14.22-43.61-31.77S339.81,114,363.89,114Z"
                    fill="#cdcccc"
                />
                <path
                    id="Path_34"
                    data-name="Path 34"
                    d="M352.76,105.37c-24.08,0-43.61,14.23-43.61,31.77,0,7.12,3.21,13.69,8.63,19l-7.68,12.79,15-7.19a54.18,54.18,0,0,0,27.62,7.19c24.09,0,43.61-14.23,43.61-31.78S376.85,105.37,352.76,105.37Z"
                    fill="#707272"
                />
                <circle
                    id="Ellipse_2"
                    data-name="Ellipse 2"
                    cx="6.19"
                    cy="6.19"
                    r="6.19"
                    transform="translate(325.85 130.95)"
                    fill="#fff"
                />
                <circle
                    id="Ellipse_3"
                    data-name="Ellipse 3"
                    cx="6.19"
                    cy="6.19"
                    r="6.19"
                    transform="translate(346.57 130.95)"
                    fill="#fff"
                />
                <path
                    id="Path_35"
                    data-name="Path 35"
                    d="M379.67,137.14a6.19,6.19,0,1,1-6.19-6.18,6.19,6.19,0,0,1,6.19,6.18Z"
                    fill="#fff"
                />
                <rect
                    id="Rectangle_4"
                    data-name="Rectangle 4"
                    width="92.55"
                    height="62.59"
                    transform="translate(331.38 347.4)"
                    fill="#707272"
                />
                <path
                    id="Path_36"
                    data-name="Path 36"
                    d="M423.93,347.4l-46.27,31.29L331.38,347.4Z"
                    fill="#e4e3e3"
                />
                <circle
                    id="Ellipse_4"
                    data-name="Ellipse 4"
                    cx="16.85"
                    cy="16.85"
                    r="16.85"
                    transform="translate(180.566 122.699) rotate(-45)"
                    fill="#f4f5f6"
                />
                <circle
                    id="Ellipse_5"
                    data-name="Ellipse 5"
                    cx="16.85"
                    cy="16.85"
                    r="16.85"
                    transform="translate(72.896 202.508) rotate(-45)"
                    fill="#f4f5f6"
                />
                <circle
                    id="Ellipse_6"
                    data-name="Ellipse 6"
                    cx="16.85"
                    cy="16.85"
                    r="16.85"
                    transform="matrix(0.926, -0.379, 0.379, 0.926, 6.917, 364.337)"
                    fill="#f4f5f6"
                />
                <circle
                    id="Ellipse_7"
                    data-name="Ellipse 7"
                    cx="16.85"
                    cy="16.85"
                    r="16.85"
                    transform="translate(337.333 102.474) rotate(-80.83)"
                    fill="#f4f5f6"
                />
                <circle
                    id="Ellipse_8"
                    data-name="Ellipse 8"
                    cx="9.91"
                    cy="9.91"
                    r="9.91"
                    transform="translate(259.57 133.42)"
                    fill="#fff"
                    stroke="#e2e2e2"
                    strokeMiterlimit="10"
                    strokeWidth="2"
                />
                <circle
                    id="Ellipse_9"
                    data-name="Ellipse 9"
                    cx="6.41"
                    cy="6.41"
                    r="6.41"
                    transform="translate(151.76 95.92)"
                    fill="#fff"
                    stroke="#e2e2e2"
                    strokeMiterlimit="10"
                    strokeWidth="2"
                />
                <path
                    id="Path_37"
                    data-name="Path 37"
                    d="M57,180.43H50v-7.06a.87.87,0,0,0-.88-.88h0a.91.91,0,0,0-.9.88v7.06h-7a.91.91,0,0,0-.89.9.89.89,0,0,0,.89.88h7v7.07a.91.91,0,0,0,.9.88.87.87,0,0,0,.88-.88h0v-7.07h7a.88.88,0,0,0,.88-.88.9.9,0,0,0-.88-.9Z"
                    fill="#e2e2e2"
                    stroke="#e2e2e2"
                    strokeMiterlimit="10"
                    strokeWidth="0.75"
                />
                <path
                    id="Path_38"
                    data-name="Path 38"
                    d="M496.4,382.29h-6.06v-6a.75.75,0,0,0-.75-.75h0a.77.77,0,0,0-.77.75v6h-6a.77.77,0,0,0,0,1.53h6v6.05a.77.77,0,0,0,.77.75.76.76,0,0,0,.75-.75h0v-6.05h6.06a.77.77,0,0,0,0-1.53Z"
                    fill="#e2e2e2"
                    stroke="#e2e2e2"
                    strokeMiterlimit="10"
                    strokeWidth="0.75"
                />
                <path
                    id="Path_39"
                    data-name="Path 39"
                    d="M481.13,204.7h-7.07v-7.07a.88.88,0,0,0-.88-.88h0a.91.91,0,0,0-.9.88v7.07h-7a.89.89,0,1,0,0,1.78h7v7.07a.9.9,0,0,0,.9.88.88.88,0,0,0,.88-.88h0v-7.07h7.07a.89.89,0,1,0,0-1.78Z"
                    fill="#e2e2e2"
                    stroke="#e2e2e2"
                    strokeMiterlimit="10"
                    strokeWidth="0.75"
                />
            </g>
        </SVGIcon>
    );
}
