const instaReportActions = {
    SET_STATUS: 'SET_STATUS',
    SET_INITIAL_MY_INSTAREPORT_LOAD: 'SET_INITIAL_MY_INSTAREPORT_LOAD',
    SET_FIELDS: 'SET_FIELDS',
    SET_PAGE: 'SET_PAGE',
    SET_PAGE_LIMIT: 'SET_PAGE_LIMIT',
    SET_TOTAL_RESULTS: 'SET_TOTAL_RESULTS',
    SET_DATA: 'SET_DATA',
    SET_TOTAL_PAGES: 'SET_TOTAL_PAGES',
    SET_SORT_STRING: 'SET_SORT_STRING',
    SET_MY_INSTAREPORT_SEARCH_TEXT: 'SET_MY_INSTAREPORT_SEARCH_TEXT',

    SET_SELECTED_INSTAREPORT: 'SET_SELECTED_INSTAREPORT',
    SET_STATUS_STRING: 'SET_STATUS_STRING',
    SET_ADDITIONAL_INFO: 'SET_ADDITIONAL_INFO',
};

export default instaReportActions;
