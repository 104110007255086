import React from 'react';

import PropTypes from 'prop-types';

import DCEdit from '../../Assets/icons/DCEdit';
import DCTrash1 from '../../Assets/icons/DCTrash1';
import { Button, Dialog, Div } from '../../UIKit/index';

import './style.scss';

const Confirmation = ({
    open,
    onClose,
    title,
    message,
    declinedButton,
    acceptButton,
    confirmationType,
    type,
    loading,
}) => {
    return (
        <Dialog
            dialogType={'centerZoom sizeSm'}
            open={open}
            onClose={onClose}
            classes={{
                root: 'ConfirmationDialog ',
            }}
        >
            <Div className={'ConfirmationMain ' + (confirmationType ? confirmationType : '')}>
                <Div className={'CMIHead'}>
                    <Div className={'CMIHIcon'}>
                        {type === 'delete' && <DCTrash1 />}
                        {type === 'confirm' && <DCEdit />}
                    </Div>
                    <Div className={'CMIHTitle'}>{title || 'Confirmation'}</Div>
                </Div>
                <Div className={'CMIBody'}>
                    <Div className={'CMIBInner'}>
                        <Div className={'CMIBIDes'}>
                            {message || 'Are you sure you want to delete?'}
                        </Div>
                        <Div className={'CMIBIBtns'}>
                            {declinedButton && (
                                <Button
                                    buttonClass={'RedOutlineBtn'}
                                    onClick={declinedButton.onClick}
                                    disabled={loading}
                                >
                                    {declinedButton.title || 'No'}
                                </Button>
                            )}
                            <Button
                                buttonClass={'BlueFillBtn'}
                                onClick={acceptButton?.onClick}
                                loading={loading}
                            >
                                {acceptButton?.title || 'Yes'}
                            </Button>
                        </Div>
                    </Div>
                </Div>
            </Div>
        </Dialog>
    );
};

Confirmation.propTypes = {
    open: PropTypes.bool,
    onClose: PropTypes.func,
    title: PropTypes.string,
    message: PropTypes.any,
    declinedButton: PropTypes.object.isRequired,
    acceptButton: PropTypes.object.isRequired,
    confirmationType: PropTypes.string,
    type: PropTypes.string,
    loading: PropTypes.bool,
};

export default Confirmation;

// <Confirmation  open={true}
// onClose={onClose}
// title={'Delete Contact'}
// message={'Are you sure you want to delete'}
// declinedButton={{title: "Delete", onClick: onClick}}
// acceptButton={{title: "Delete", onClick: onClick}}
// confirmationType={'delete'}
// delete
// />
