import React from 'react';

import { SVGIcon } from '../../UIKit/index';

export default function DCBing(props) {
    return (
        <SVGIcon {...props} viewBox="0 0 512 512">
            <circle cx="256" cy="256" r="256" fill="#009688" />
            <g>
                <polygon
                    fill="#FFFFFF"
                    points="197.976,121.624 129.403,100.263 129.403,364.271 197.976,300.97  "
                />
                <polygon
                    fill="#FFFFFF"
                    points="227.867,186.681 254.12,249.979 309.104,275.677 129.403,364.271 197.976,409.737    382.597,306.245 382.597,232.267  "
                />
            </g>
        </SVGIcon>
    );
}
