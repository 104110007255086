import React from 'react';

import { SVGIcon } from '../../UIKit/index';

export default function DCDuplicate(props) {
    return (
        <SVGIcon {...props} viewBox="0 0 512 512">
            <path d="M394.3,115.6L288.1,9.5c-2.9-2.9-6.8-4.5-10.8-4.5H123.6c-8.5,0-15.3,6.9-15.3,15.3l0,0v87.9  H20.6c-8.4,0-15.3,6.8-15.3,15.2v369.3c0,8.5,6.9,15.3,15.3,15.3h259.7c8.5,0,15.3-6.8,15.4-15.2c0,0,0-0.1,0-0.1v-88h87.8  c8.5,0,15.3-6.9,15.3-15.3c0,0,0,0,0,0v-263C398.8,122.4,397.1,118.5,394.3,115.6z M368.1,374.2h-72.5V229.6c0-4.1-1.6-8-4.5-10.8  L184.9,112.6c-2.9-2.9-6.8-4.5-10.8-4.5H139V35.6h122.9v90.8c0,8.5,6.9,15.3,15.3,15.3c0,0,0,0,0,0h90.8L368.1,374.2z M264.9,477.3  H35.8V138.8h123v90.8c0,8.5,6.9,15.3,15.3,15.3h90.8V477.3z M189.4,160.4l53.8,53.8h-53.8V160.4z M346.3,111.1h-53.8V57.3  L346.3,111.1z" />
        </SVGIcon>
    );
}
