import React from 'react';

import { SVGIcon } from 'UIKit/index';
import Project from 'Assets/images/AppLogo/project.svg';

const backgroundStyle = {
    backgroundImage: 'url(' + Project + ')',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
};
export default function DCProject(props) {
    return <SVGIcon {...props} viewBox="0 0 2000 2000" style={backgroundStyle} />;
}
