import React from 'react';

import { SVGIcon } from '../../UIKit/index';

export default function DCUserReview1(props) {
    return (
        <SVGIcon {...props} viewBox="0 0 512 512">
            <g transform="translate(0.205 -0.504)">
                <g transform="translate(3.816 8.718)">
                    <g>
                        <path d="M312.49,337.86c20.67-17.72,32.56-43.59,32.55-70.82v-4.92c0-51.58-41.82-93.4-93.4-93.4     c-51.58,0-93.4,41.82-93.4,93.4v4.92c-0.01,27.22,11.88,53.08,32.55,70.8c-66.03,20.33-111.12,81.31-111.21,150.41     c0,8.15,6.6,14.75,14.75,14.75h314.61c8.15,0,14.75-6.6,14.75-14.75C423.61,419.16,378.52,358.19,312.49,337.86z M187.75,267.04     v-4.92c-0.66-35.29,27.42-64.43,62.71-65.08c35.29-0.66,64.43,27.42,65.08,62.71c0.01,0.79,0.01,1.58,0,2.37v4.92     c0.66,35.29-27.42,64.43-62.71,65.08c-35.29,0.66-64.43-27.42-65.08-62.71C187.73,268.62,187.73,267.83,187.75,267.04z      M109.95,473.49c7.6-64.39,62.12-112.94,126.95-113.06h29.5c64.84,0.12,119.35,48.68,126.95,113.06H109.95z" />
                    </g>
                </g>
                <g transform="translate(8.013 0.704)">
                    <g>
                        <path d="M326.1,56.92c-1.47-4.53-5.04-8.08-9.58-9.52l-35.47-11.17L259.47,5.98     c-4.74-6.63-13.95-8.16-20.58-3.43c-1.32,0.95-2.48,2.1-3.43,3.43l-21.58,30.28l-35.47,11.17c-7.77,2.45-12.08,10.74-9.62,18.51     c0.49,1.55,1.23,3.01,2.2,4.32l22.08,29.88l-0.33,37.17c-0.07,8.15,6.48,14.81,14.63,14.87c1.63,0.01,3.26-0.24,4.8-0.76     l35.33-11.79l35.33,11.79c7.73,2.59,16.08-1.58,18.67-9.31c0.52-1.55,0.78-3.17,0.76-4.8l-0.33-37.17l22.08-29.88     C326.84,66.41,327.6,61.44,326.1,56.92z M275.15,86.56c-1.89,2.56-2.91,5.65-2.89,8.83l0.2,21.42l-20.32-6.8     c-3.04-1.02-6.32-1.02-9.36,0l-20.32,6.8l0.2-21.42c0.01-3.18-1-6.28-2.89-8.83l-12.74-17.22l20.45-6.45     c3.06-0.96,5.71-2.89,7.57-5.5l12.43-17.45l12.43,17.45c1.86,2.61,4.52,4.54,7.57,5.5l20.45,6.45L275.15,86.56z" />
                    </g>
                </g>
                <g transform="translate(16.026 2.708)">
                    <g>
                        <path d="M495.03,99.17c-1.47-4.53-5.04-8.08-9.58-9.52l-35.44-11.17L428.43,48.2     c-4.74-6.63-13.95-8.16-20.58-3.43c-1.32,0.95-2.48,2.1-3.43,3.43l-21.57,30.28l-35.44,11.17c-7.77,2.45-12.08,10.74-9.62,18.51     c0.49,1.55,1.24,3.01,2.2,4.32l22.08,29.88l-0.33,37.17c-0.07,8.15,6.48,14.81,14.63,14.87c1.63,0.01,3.26-0.24,4.8-0.76     l35.33-11.79l35.33,11.79c7.73,2.59,16.09-1.58,18.67-9.31c0.52-1.55,0.78-3.17,0.76-4.8l-0.33-37.17l22.08-29.88     C495.8,108.63,496.55,103.67,495.03,99.17z M444.11,128.81c-1.89,2.56-2.91,5.65-2.89,8.83l0.2,21.42l-20.32-6.8     c-3.04-1.02-6.32-1.02-9.36,0l-20.32,6.8l0.2-21.42c0.01-3.18-1-6.28-2.89-8.83l-12.74-17.22l20.45-6.45     c3.06-0.96,5.71-2.89,7.57-5.5l12.43-17.45l12.43,17.45c1.86,2.61,4.52,4.54,7.57,5.5l20.45,6.45L444.11,128.81z" />
                    </g>
                </g>
                <g transform="translate(0 2.708)">
                    <g transform="translate(0)">
                        <path d="M157.14,99.17c-1.47-4.53-5.04-8.08-9.58-9.52l-35.47-11.17L90.51,48.2     c-4.74-6.63-13.95-8.16-20.58-3.43c-1.32,0.95-2.48,2.1-3.43,3.43L44.93,78.48L9.47,89.65c-7.77,2.45-12.08,10.74-9.62,18.51     c0.49,1.55,1.24,3.01,2.2,4.32l22.08,29.88l-0.33,37.17c-0.07,8.15,6.48,14.81,14.63,14.87c1.63,0.01,3.26-0.24,4.8-0.76     l35.33-11.79l35.33,11.79c7.73,2.59,16.08-1.58,18.67-9.31c0.52-1.55,0.78-3.17,0.76-4.8l-0.49-37.14l22.08-29.88     C157.78,108.68,158.6,103.71,157.14,99.17z M106.21,128.81c-1.89,2.56-2.91,5.65-2.89,8.83l0.2,21.44l-20.32-6.8     c-3.04-1.02-6.32-1.02-9.36,0l-20.32,6.8l0.2-21.44c0.01-3.18-1-6.28-2.89-8.83l-12.74-17.22l20.45-6.45     c3.05-0.99,5.7-2.95,7.53-5.59L78.5,82.1l12.43,17.45c1.86,2.61,4.52,4.54,7.57,5.5l20.45,6.45L106.21,128.81z" />
                    </g>
                </g>
            </g>
        </SVGIcon>
    );
}
