import React from 'react';

import { GlobalActions } from '../Actions';
import { apiCalls, GlobalReducer as reduce } from '../Reducer';
import { GlobalStore } from '../Store';
const listeners = new Map();
let state = GlobalStore;

function wrapperDispatch(dispatch) {
    return function (action) {
        if (action.type.includes('CALL')) {
            dispatch({ type: GlobalActions.LOADING, payload: true });
            try {
                apiCalls[action.type](action.payload)
                    .then(v => {
                        dispatch({ type: action.type, payload: v.data });
                        dispatch({
                            type: GlobalActions.LOADING,
                            payload: false,
                        });
                    })
                    .catch(err => {
                        dispatch({ type: GlobalActions.ERROR, payload: err });
                        dispatch({
                            type: GlobalActions.LOADING,
                            payload: false,
                        });
                    });
            } catch (err) {
                dispatch({ type: GlobalActions.ERROR, payload: err });
                dispatch({ type: GlobalActions.LOADING, payload: false });
            }
        } else {
            dispatch(action);
        }
    };
}

const globalDispatch = wrapperDispatch(action => {
    let i = 0;
    const prevValues = Array.from(listeners, ([getValue]) => getValue(state));

    // Gets the new state
    state = reduce(state, action);

    // Notify subscribed components
    listeners.forEach((setValue, getValue) => {
        const value = getValue(state);

        if (value !== prevValues[i++]) setValue(value);
    });
});

function useGlobalState(getValue) {
    const [value, setValue] = React.useState(getValue(state));

    React.useEffect(() => {
        listeners.set(getValue, setValue);
        return () => listeners.delete(getValue);
    }, [getValue]);

    return value;
}
export { globalDispatch, useGlobalState };
