import React from 'react';

import { SVGIcon } from '../../UIKit/index';

export default function DCDocFile(props) {
    return (
        <SVGIcon {...props} viewBox="0 0 512 512">
            <path
                d="m392 488h-272a48 48 0 0 1 -48-48v-368a48 48 0 0 1 48-48h224l96 96v320a48 48 0 0 1 -48 48z"
                fill="#005eff33"
            />
            <path
                d="m72 360h368a0 0 0 0 1 0 0v80a48 48 0 0 1 -48 48h-272a48 48 0 0 1 -48-48v-80a0 0 0 0 1 0 0z"
                fill="#005eff"
            />
            <g fill="#005eff50">
                <path d="m440 120h-48a48 48 0 0 1 -48-48v-48z" />
                <path d="m376 176h-176a8 8 0 0 1 0-16h176a8 8 0 0 1 0 16z" />
                <path d="m280 208h-144a8 8 0 0 1 0-16h144a8 8 0 0 1 0 16z" />
                <path d="m376 240h-128a8 8 0 0 1 0-16h128a8 8 0 0 1 0 16z" />
                <path d="m320 272h-184a8 8 0 0 1 0-16h184a8 8 0 0 1 0 16z" />
                <path d="m168 176h-32a8 8 0 0 1 0-16h32a8 8 0 0 1 0 16z" />
                <path d="m376 208h-64a8 8 0 0 1 0-16h64a8 8 0 0 1 0 16z" />
                <path d="m216 240h-80a8 8 0 0 1 0-16h80a8 8 0 0 1 0 16z" />
                <path d="m376 272h-24a8 8 0 0 1 0-16h24a8 8 0 0 1 0 16z" />
                <path d="m184 304h-48a8 8 0 0 1 0-16h48a8 8 0 0 1 0 16z" />
                <path d="m216 144h-80a8 8 0 0 1 0-16h80a8 8 0 0 1 0 16z" />
                <path d="m296 304h-80a8 8 0 0 1 0-16h80a8 8 0 0 1 0 16z" />
                <path d="m376 304h-48a8 8 0 0 1 0-16h48a8 8 0 0 1 0 16z" />
            </g>
            <path
                d="m168 384a8 8 0 0 0 -8 8v64a8 8 0 0 0 8 8 40 40 0 0 0 0-80zm8 62.629v-45.258a24 24 0 0 1 0 45.258z"
                fill="#fff"
            />
            <path
                d="m256 384c-17.645 0-32 17.944-32 40s14.355 40 32 40 32-17.944 32-40-14.355-40-32-40zm0 64c-8.673 0-16-10.991-16-24s7.327-24 16-24 16 10.991 16 24-7.327 24-16 24z"
                fill="#fff"
            />
            <path
                d="m336 400a10.708 10.708 0 0 1 4.59 1.058 8 8 0 1 0 6.82-14.474 26.581 26.581 0 0 0 -11.41-2.584c-17.645 0-32 17.944-32 40s14.355 40 32 40a26.6 26.6 0 0 0 11.411-2.584 8 8 0 0 0 -6.822-14.473 10.71 10.71 0 0 1 -4.589 1.057c-8.673 0-16-10.991-16-24s7.327-24 16-24z"
                fill="#fff"
            />
        </SVGIcon>
    );
}
