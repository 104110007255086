import React from 'react';

import { SVGIcon } from '../../UIKit/index';

export default function DCAppType(props) {
    return (
        <SVGIcon {...props} viewBox="0 0 512 512">
            <path d="M358.62,471.49c0,0,7.46-7.46,5.51-21.08c0,0-2.27-20.43-29.19-12.65c0,0-87.89,41.95-174.81-15.57h-19.46  l-39.78,9.51l-2.59-3.03l6.05-42.38v-9.08c0,0-36.97-56.86-36.97-92.54v-44.76c0,0,6.49-76.54,59.03-121.95  c0,0,37.62-43.46,108.32-53.84c0,0,82.38-12.97,145.3,42.16c0,0,56.43,30.49,72.65,123.89c0,0,9.08,75.89-22.7,97.95  c0,0-15.57,9.51-30.49-8.86v-15.46c0,0,20.97-69.3-6.27-108.65c0,0-35.89-90.16-151.35-83.68c0,0-114.61,14.4-128.43,136.22  c0,0-10.38,125.19,127.14,157.62c0,0,80.64,10.16,131.24-50.17l-30.91-28.1c0,0-10.81,19.46-60.54,33.3  c0,0-66.59,13.41-102.49-39.35c0,0-27.68-31.57-20.76-81.73c0,0,11.68-65.3,82.16-83.89c0,0,100.32-17.3,121.95,81.73  c0,0,12.54,60.54-20.32,89.95l33.3,30.27c0,0,40.89,35.03,79.69,5.62c0,0,46.8-22.81,42.91-125.95c0,0-8.43-121.62-112.22-179.35  c0,0-119.35-86.49-258.16,4.97c0,0-107.03,65.08-101.84,211.68c0,0,3.68,73.51,36.11,108.97L53.32,465c0,0,5.62,17.73,27.68,14.7  l62.7-13.84l5.19,0.43c0,0,46.27,24.65,92.97,28.11C241.86,494.41,321,497.86,358.62,471.49z" />{' '}
            <circle cx="403.95" cy="417.7" r="22.49" />
        </SVGIcon>
    );
}
