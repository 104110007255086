import React from 'react';

import { SVGIcon } from '../../UIKit/index';

export default function DCNoUserFound(props) {
    return (
        <SVGIcon {...props} viewBox="0 0 215 182">
            <g transform="translate(-709 -582)">
                <ellipse
                    cx="71.5"
                    cy="17"
                    rx="71.5"
                    ry="17"
                    transform="translate(748 730)"
                    fill="#f5f5f5"
                />
                <g transform="translate(764 612)" fill="#f5f5f5" stroke="#405d63" strokeWidth="1.6">
                    <rect width="110" height="136" rx="10" stroke="none" />
                    <rect x="0.8" y="0.8" width="108.4" height="134.4" rx="9.2" fill="none" />
                </g>
                <g transform="translate(774 625)" fill="#fff" stroke="#405d63" strokeWidth="1.6">
                    <rect width="90" height="113" rx="6" stroke="none" />
                    <rect x="0.8" y="0.8" width="88.4" height="111.4" rx="5.2" fill="none" />
                </g>
                <g transform="translate(809 596)" fill="#fff" stroke="#405d63" strokeWidth="1.6">
                    <rect width="20" height="16" rx="6" stroke="none" />
                    <rect x="0.8" y="0.8" width="18.4" height="14.4" rx="5.2" fill="none" />
                </g>
                <g transform="translate(783 607)" fill="#fff" stroke="#405d63" strokeWidth="1.6">
                    <rect width="72" height="13" rx="6" stroke="none" />
                    <rect x="0.8" y="0.8" width="70.4" height="11.4" rx="5.2" fill="none" />
                </g>
                <rect width="16" height="9" transform="translate(811 603)" fill="#fff" />
                <g>
                    <circle
                        cx="1.5"
                        cy="1.5"
                        r="1.5"
                        transform="translate(796 691)"
                        fill="#405d63"
                    />
                    <rect width="40" height="3" transform="translate(802 691)" fill="#405d63" />
                </g>
                <g transform="translate(0 13)">
                    <circle
                        cx="1.5"
                        cy="1.5"
                        r="1.5"
                        transform="translate(796 691)"
                        fill="#405d63"
                    />
                    <rect width="40" height="3" transform="translate(802 691)" fill="#405d63" />
                </g>
                <g transform="translate(0 26)">
                    <circle
                        cx="1.5"
                        cy="1.5"
                        r="1.5"
                        transform="translate(796 691)"
                        fill="#405d63"
                    />
                    <rect width="40" height="3" transform="translate(802 691)" fill="#405d63" />
                </g>
                <path
                    d="M39.107,6.71a22.909,22.909,0,0,0-32.4,32.4,22.909,22.909,0,0,0,32.4-32.4ZM11.484,39.587a11.6,11.6,0,0,1,22.848,0,20.18,20.18,0,0,1-22.848,0Zm4.14-19.554a7.284,7.284,0,1,1,7.284,7.284,7.292,7.292,0,0,1-7.284-7.284ZM36.657,37.725a14.307,14.307,0,0,0-8.317-9.338,9.969,9.969,0,1,0-10.863,0,14.3,14.3,0,0,0-8.317,9.337,20.224,20.224,0,1,1,27.5,0Zm0,0"
                    transform="translate(796.093 635.28)"
                    fill="#405d63"
                    stroke="#fff"
                    strokeWidth="0.6"
                />
                <g transform="translate(815 600)" fill="#fff" stroke="#405d63" strokeWidth="1.6">
                    <circle cx="4.5" cy="4.5" r="4.5" stroke="none" />
                    <circle cx="4.5" cy="4.5" r="3.7" fill="none" />
                </g>
                <g opacity="0.5">
                    <rect
                        width="3"
                        height="23"
                        rx="1.5"
                        transform="translate(884 582)"
                        fill="#c3c5c4"
                    />
                    <rect
                        width="3"
                        height="23"
                        rx="1.5"
                        transform="translate(897 592) rotate(90)"
                        fill="#c3c5c4"
                    />
                </g>
                <g opacity="0.5">
                    <g transform="translate(902 615)" fill="#fff" stroke="#c3c5c4" strokeWidth="1">
                        <rect width="2" height="10" rx="1" stroke="none" />
                        <rect x="0.5" y="0.5" width="1" height="9" rx="0.5" fill="none" />
                    </g>
                    <g
                        transform="translate(908 619) rotate(90)"
                        fill="#fff"
                        stroke="#c3c5c4"
                        strokeWidth="1"
                    >
                        <rect width="2" height="10" rx="1" stroke="none" />
                        <rect x="0.5" y="0.5" width="1" height="9" rx="0.5" fill="none" />
                    </g>
                </g>
                <g transform="translate(-146 -19)" opacity="0.5">
                    <g transform="translate(902 615)" fill="#fff" stroke="#c3c5c4" strokeWidth="1">
                        <rect width="2" height="10" rx="1" stroke="none" />
                        <rect x="0.5" y="0.5" width="1" height="9" rx="0.5" fill="none" />
                    </g>
                    <g
                        transform="translate(908 619) rotate(90)"
                        fill="#fff"
                        stroke="#c3c5c4"
                        strokeWidth="1"
                    >
                        <rect width="2" height="10" rx="1" stroke="none" />
                        <rect x="0.5" y="0.5" width="1" height="9" rx="0.5" fill="none" />
                    </g>
                </g>
                <g transform="translate(-160 108)" opacity="0.5">
                    <g transform="translate(0 -11)">
                        <g
                            transform="translate(902 613)"
                            fill="#fff"
                            stroke="#c3c5c4"
                            strokeWidth="1"
                        >
                            <rect width="2" height="12" rx="1" stroke="none" />
                            <rect x="0.5" y="0.5" width="1" height="11" rx="0.5" fill="none" />
                        </g>
                        <g
                            transform="translate(909 618) rotate(90)"
                            fill="#fff"
                            stroke="#c3c5c4"
                            strokeWidth="1"
                        >
                            <rect width="2" height="12" rx="1" stroke="none" />
                            <rect x="0.5" y="0.5" width="1" height="11" rx="0.5" fill="none" />
                        </g>
                    </g>
                </g>
                <g
                    transform="translate(709 688)"
                    fill="#fff"
                    stroke="#c3c5c4"
                    strokeWidth="1.6"
                    opacity="0.5"
                >
                    <circle cx="4.5" cy="4.5" r="4.5" stroke="none" />
                    <circle cx="4.5" cy="4.5" r="3.7" fill="none" />
                </g>
                <g transform="translate(27 -11)">
                    <g transform="translate(696 656)" fill="#f5f5f5">
                        <path
                            d="M 13.5 26.20000076293945 C 10.10770988464355 26.20000076293945 6.918459892272949 24.87896919250488 4.519750118255615 22.48024940490723 C 2.12103009223938 20.08153915405273 0.800000011920929 16.89229011535645 0.800000011920929 13.5 C 0.800000011920929 10.10770988464355 2.12103009223938 6.918459892272949 4.519750118255615 4.519750118255615 C 6.918459892272949 2.12103009223938 10.10770988464355 0.800000011920929 13.5 0.800000011920929 C 16.89229011535645 0.800000011920929 20.08153915405273 2.12103009223938 22.48024940490723 4.519750118255615 C 24.87896919250488 6.918459892272949 26.20000076293945 10.10770988464355 26.20000076293945 13.5 C 26.20000076293945 15.13111972808838 25.1413402557373 16.01712989807129 23.80102920532227 17.13886070251465 C 22.06814002990723 18.58914375305176 19.92794036865234 20.3805103302002 20.1724967956543 24.3189525604248 C 18.29693603515625 25.51850700378418 15.89268398284912 26.20000076293945 13.5 26.20000076293945 Z"
                            stroke="none"
                        />
                        <path
                            d="M 13.5 1.600000381469727 C 10.3213996887207 1.600000381469727 7.333049774169922 2.837820053100586 5.085430145263672 5.085430145263672 C 2.837820053100586 7.333049774169922 1.600000381469727 10.3213996887207 1.600000381469727 13.5 C 1.600000381469727 16.6786003112793 2.837820053100586 19.66695022583008 5.085430145263672 21.91456985473633 C 7.333049774169922 24.16217994689941 10.3213996887207 25.39999961853027 13.5 25.39999961853027 C 15.58132076263428 25.39999961853027 17.66764450073242 24.85269927978516 19.35457038879395 23.880126953125 C 19.28730583190918 19.87352561950684 21.57949829101563 17.95490264892578 23.28759002685547 16.52536964416504 C 24.6756591796875 15.36365985870361 25.39999961853027 14.70149993896484 25.39999961853027 13.5 C 25.39999961853027 10.3213996887207 24.16217994689941 7.333049774169922 21.91456985473633 5.085430145263672 C 19.66695022583008 2.837820053100586 16.6786003112793 1.600000381469727 13.5 1.600000381469727 M 13.5 0 C 20.95584106445313 0 27 6.044160842895508 27 13.5 C 27 18.07043075561523 20.2724609375 17.85181045532227 21.00931930541992 24.72043037414551 C 19.05939102172852 26.09477043151855 16.38541030883789 27 13.5 27 C 6.044160842895508 27 0 20.95584106445313 0 13.5 C 0 6.044160842895508 6.044160842895508 0 13.5 0 Z"
                            stroke="none"
                            fill="#405d63"
                        />
                    </g>
                    <text
                        transform="translate(705 677)"
                        fill="#405d63"
                        fontSize="20"
                        fontFamily="Roboto-Regular, Roboto"
                    >
                        <tspan x="0" y="0">
                            ?
                        </tspan>
                    </text>
                </g>
                <g transform="translate(-47 -9)">
                    <g transform="translate(194 55)">
                        <g transform="translate(740.857 623.429)" fill="#f5f5f5">
                            <path
                                d="M 18.0712890625 35.34257888793945 C 14.76025772094727 35.34257888793945 11.43100357055664 34.38163375854492 8.858241081237793 32.6943244934082 C 9.242401123046875 27.44672012329102 6.40300178527832 25.07051277160645 4.108319282531738 23.15005874633789 C 2.33311915397644 21.66435813903809 0.7999990582466125 20.38125991821289 0.7999990582466125 18.0712890625 C 0.7999990582466125 13.45795917510986 2.596529006958008 9.120759010314941 5.858639240264893 5.858639240264893 C 9.120759010314941 2.596529006958008 13.45795917510986 0.7999990582466125 18.0712890625 0.7999990582466125 C 22.68461990356445 0.7999990582466125 27.02181816101074 2.596529006958008 30.28393936157227 5.858639240264893 C 33.54604721069336 9.120759010314941 35.34257888793945 13.45795917510986 35.34257888793945 18.0712890625 C 35.34257888793945 22.68461990356445 33.54604721069336 27.02181816101074 30.28393936157227 30.28393936157227 C 27.02181816101074 33.54604721069336 22.68461990356445 35.34257888793945 18.0712890625 35.34257888793945 Z"
                                stroke="none"
                            />
                            <path
                                d="M 18.0712890625 1.599998474121094 C 13.67164993286133 1.599998474121094 9.53533935546875 3.313308715820313 6.42432975769043 6.42432975769043 C 3.313308715820313 9.53533935546875 1.599998474121094 13.67164993286133 1.599998474121094 18.0712890625 C 1.599998474121094 19.91766929626465 2.691879272460938 20.92140960693359 4.621768951416016 22.53656005859375 C 6.989021301269531 24.51776313781738 9.902317047119141 26.95545196533203 9.684282302856445 32.27004623413086 C 12.07135391235352 33.72163391113281 15.07683753967285 34.54257965087891 18.0712890625 34.54257965087891 C 22.47092819213867 34.54257965087891 26.60723876953125 32.82926940917969 29.71824836730957 29.71824836730957 C 32.82926940917969 26.60723876953125 34.54257965087891 22.47092819213867 34.54257965087891 18.0712890625 C 34.54257965087891 13.67164993286133 32.82926940917969 9.53533935546875 29.71824836730957 6.42432975769043 C 26.60723876953125 3.313308715820313 22.47092819213867 1.599998474121094 18.0712890625 1.599998474121094 M 18.0712890625 0 C 28.05178833007813 0 36.142578125 8.090789794921875 36.142578125 18.0712890625 C 36.142578125 28.05178833007813 28.05178833007813 36.142578125 18.0712890625 36.142578125 C 14.20883941650391 36.142578125 10.62940979003906 34.93082809448242 8.019208908081055 33.09111022949219 C 9.005578994750977 23.89667892456055 0 24.18932914733887 0 18.0712890625 C 0 8.090789794921875 8.090789794921875 0 18.0712890625 0 Z"
                                stroke="none"
                                fill="#405d63"
                            />
                        </g>
                    </g>
                    <g transform="translate(-1)">
                        <g
                            transform="translate(950 686)"
                            fill="none"
                            stroke="#405d63"
                            strokeWidth="1.6"
                        >
                            <circle cx="5" cy="5" r="5" stroke="none" />
                            <circle cx="5" cy="5" r="4.2" fill="none" />
                        </g>
                        <line
                            x1="2"
                            y2="10"
                            transform="translate(951.5 694.5)"
                            fill="none"
                            stroke="#405d63"
                            strokeWidth="1.6"
                        />
                        <line
                            x2="5"
                            y2="1"
                            transform="translate(952.5 700.5)"
                            fill="none"
                            stroke="#405d63"
                            strokeWidth="1.6"
                        />
                    </g>
                </g>
            </g>
        </SVGIcon>
    );
}
