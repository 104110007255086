import React from 'react';

import { SVGIcon } from '../../UIKit/index';

export default function DCAddColumn(props) {
    return (
        <SVGIcon {...props} viewBox="0 0 512 512">
            <path d="M135.9,269.5h-9.8c-5.3,0-9.6-4.3-9.6-9.6v-96.8c0-5.3,4.3-9.6,9.6-9.6h9.8c5.3,0,9.6,4.3,9.6,9.6v96.8  C145.5,265.2,141.2,269.5,135.9,269.5z" />
            <path d="M189.4,205.8v11.4c0,4.8-3.9,8.8-8.8,8.8H82.2c-4.8,0-8.8-3.9-8.8-8.8v-11.4c0-4.8,3.9-8.8,8.8-8.8h98.4  C185.5,197,189.4,200.9,189.4,205.8z" />
            <path d="M423.5,172.1v9c0,8-6.5,14.6-14.6,14.6H240.1c-8,0-14.6-6.5-14.6-14.6v-9c0-8,6.5-14.6,14.6-14.6h168.9  C417,157.5,423.5,164,423.5,172.1z" />
            <path d="M423.5,248.6v9c0,8-6.5,14.6-14.6,14.6H240.1c-8,0-14.6-6.5-14.6-14.6v-9c0-8,6.5-14.6,14.6-14.6h168.9  C417,234,423.5,240.5,423.5,248.6z" />
            <path d="M423.5,330v9c0,8-6.5,14.6-14.6,14.6H240.1c-8,0-14.6-6.5-14.6-14.6v-9c0-8,6.5-14.6,14.6-14.6h168.9  C417,315.5,423.5,322,423.5,330z" />
            <path d="M512,37.1H0V20.7C0,9.4,9.2,0.2,20.5,0.2h470.6C502.7,0.2,512,9.5,512,21V37.1z" />
            <path d="M0,475.1h512v16.4c0,11.3-9.2,20.5-20.5,20.5H20.8C9.3,512,0,502.6,0,491.1V475.1z" />
            <path d="M36.8,0.5v511.3H20.4c-11.3,0-20.5-9.2-20.5-20.5V21.3c0-11.5,9.3-20.8,20.8-20.8H36.8z" />
            <path d="M475.2,511.8V0.5h16.4c11.3,0,20.5,9.2,20.5,20.5V491c0,11.5-9.3,20.8-20.8,20.8H475.2z" />
        </SVGIcon>
    );
}
