import React from 'react';

import { SVGIcon } from '../../UIKit/index';

export default function DCNoNotification(props) {
    return (
        <SVGIcon {...props} viewBox="0 0 93.246 92.34">
            <g transform="translate(-974 -143)">
                <g transform="translate(974 146.66)">
                    <path
                        d="M221,6.062V16.974h12.124V6.062a6.062,6.062,0,0,0-12.124,0Z"
                        transform="translate(-182.722)"
                        fill="#809bb8"
                    />
                    <path
                        d="M262.062,6.062A6.062,6.062,0,0,0,256,0V16.974h6.062Z"
                        transform="translate(-211.66)"
                        fill="#6b8aaa"
                    />
                    <path
                        d="M185.722,429.32A14.739,14.739,0,0,1,171,414.6a2.6,2.6,0,0,1,2.6-2.6h24.248a2.6,2.6,0,0,1,2.6,2.6A14.739,14.739,0,0,1,185.722,429.32Z"
                        transform="translate(-141.382 -340.641)"
                        fill="#809bb8"
                    />
                    <g transform="translate(44.34 71.359)">
                        <g>
                            <path
                                d="M268.124,412H256v17.32A14.739,14.739,0,0,0,270.722,414.6,2.6,2.6,0,0,0,268.124,412Z"
                                transform="translate(-256 -412)"
                                fill="#6b8aaa"
                            />
                        </g>
                    </g>
                    <g transform="translate(14.029 12.471)">
                        <path
                            d="M158.242,107.4V95.382a23.382,23.382,0,0,0-46.765,0V107.4a50.019,50.019,0,0,1-1.995,13.964l16.918,5.2h16.918l16.918-5.2A50.017,50.017,0,0,1,158.242,107.4Z"
                            transform="translate(-104.55 -72)"
                            fill="#2b88f8"
                        />
                        <path
                            d="M281.377,121.363a50.018,50.018,0,0,1-1.995-13.964V95.382A23.409,23.409,0,0,0,256,72v54.559h8.459Z"
                            transform="translate(-225.689 -72)"
                            fill="#1371e2"
                        />
                        <path
                            d="M141.278,367.835A49.984,49.984,0,0,1,136.687,357H85.933a49.967,49.967,0,0,1-4.591,10.835,2.6,2.6,0,0,0,.009,2.593H141.27A2.6,2.6,0,0,0,141.278,367.835Z"
                            transform="translate(-81 -307.637)"
                            fill="#2b88f8"
                        />
                        <g transform="translate(30.311 49.363)">
                            <path
                                d="M285.968,367.835A49.985,49.985,0,0,1,281.377,357H256v13.428h29.96A2.6,2.6,0,0,0,285.968,367.835Z"
                                transform="translate(-256 -357)"
                                fill="#1371e2"
                            />
                        </g>
                    </g>
                    <g transform="translate(71.577 30.719)">
                        <path
                            d="M458.278,204.6a2.6,2.6,0,0,1-1.837-4.435,13.007,13.007,0,0,0,0-18.371,2.6,2.6,0,1,1,3.674-3.674,18.2,18.2,0,0,1,0,25.719A2.59,2.59,0,0,1,458.278,204.6Z"
                            transform="translate(-448.332 -177.361)"
                            fill="#d9eaff"
                        />
                        <path
                            d="M415.852,232.33a2.6,2.6,0,0,1-1.837-4.435,2.6,2.6,0,0,0,0-3.674,2.6,2.6,0,0,1,3.674-3.674,7.8,7.8,0,0,1,0,11.023A2.589,2.589,0,0,1,415.852,232.33Z"
                            transform="translate(-413.255 -212.437)"
                            fill="#d9eaff"
                        />
                    </g>
                    <g transform="translate(0 30.719)">
                        <path
                            d="M7.157,204.6a2.589,2.589,0,0,1-1.837-.761,18.2,18.2,0,0,1,0-25.719A2.6,2.6,0,0,1,8.994,181.8a13.007,13.007,0,0,0,0,18.371A2.6,2.6,0,0,1,7.157,204.6Z"
                            transform="translate(0 -177.361)"
                            fill="#d9eaff"
                        />
                        <path
                            d="M64.1,232.33a2.588,2.588,0,0,1-1.837-.761,7.8,7.8,0,0,1,0-11.023,2.6,2.6,0,0,1,3.674,3.674,2.6,2.6,0,0,0,0,3.674A2.6,2.6,0,0,1,64.1,232.33Z"
                            transform="translate(-49.592 -212.437)"
                            fill="#d9eaff"
                        />
                    </g>
                    <path
                        d="M125.951,417.2H63.6a2.6,2.6,0,1,1,0-5.2h62.353a2.6,2.6,0,0,1,0,5.2Z"
                        transform="translate(-50.435 -340.641)"
                        fill="#2b88f8"
                    />
                    <g transform="translate(44.34 71.359)">
                        <g>
                            <path
                                d="M287.176,412H256v5.2h31.176a2.6,2.6,0,1,0,0-5.2Z"
                                transform="translate(-256 -412)"
                                fill="#1371e2"
                            />
                        </g>
                    </g>
                </g>
                <g transform="translate(964 171.447)">
                    <path
                        d="M20.123,0A20.123,20.123,0,1,1,0,20.123,20.123,20.123,0,0,1,20.123,0Z"
                        transform="translate(63 -28.448)"
                        fill="red"
                    />
                    <g transform="translate(35.103 -24.695)">
                        <path
                            d="M33.324,22.314l-3.092-3.271L42.983,6.293l3.181,3.181Z"
                            transform="translate(9.708 1.954)"
                            fill="#fff"
                        />
                        <path
                            d="M3.092,16.021,0,12.751,12.751,0l3.181,3.181Z"
                            transform="translate(55.918 8.292) rotate(90)"
                            fill="#fff"
                        />
                    </g>
                </g>
            </g>
        </SVGIcon>
    );
}
