const actions = {
    SET_FORMS_COLUMNS: 'SET_FORMS_COLUMNS',
    SET_FORMS: 'SET_FORMS',
    SET_FORMS_TOTAL: 'SET_FORMS_TOTAL',
    CLEAR_FORMS: 'CLEAR_FORMS',
    SET_FORMS_CURRENT_PAGE: 'SET_FORMS_CURRENT_PAGE',
    SET_FORMS_SEARCH_TERM: 'SET_FORMS_SEARCH_TERM',
    SET_FORMS_CATEGORIES: 'SET_FORMS_CATEGORIES',
    SET_FORMS_ACTIVE_CATEGORIES: 'SET_FORMS_ACTIVE_CATEGORIES',
    SET_FORMS_TAGS: 'SET_FORMS_TAGS',
    SET_FORMS_ACTIVE_TAGS: 'SET_FORMS_ACTIVE_TAGS',

    // Templates
    SET_CATEGORIES: 'SET_CATEGORIES',
    SET_ACTIVE_CATEGORY: 'SET_ACTIVE_CATEGORY',
    SET_TEMPLATES_DATA: 'SET_TEMPLATES_DATA',
    SET_INITIAL_TEMPLATE_LOAD: 'SET_INITIAL_TEMPLATE_LOAD',
    SET_TEMPLATE_SEARCH_TEXT: 'SET_TEMPLATE_SEARCH_TEXT',
    SET_CATEGORY_ALL: 'SET_CATEGORY_ALL',

    // Builder
    UPDATE_STYLE: 'UPDATE_STYLE',
    RESET_STYLES: 'RESET_STYLES',
    AUTO_SAVE: 'AUTO_SAVE',
    LOADING: 'LOADING',
    ADD_ID: 'ADD_ID',
    UPDATE_OPTIONS: 'UPDATE_OPTIONS',
    ADD_DOMAIN: 'ADD_DOMAIN',
    ADD_FORM_COMPONENTS: 'ADD_FORM_COMPONENTS',
    SET_CATEGORIES_LOADED: 'SET_CATEGORIES_LOADED',
    SET_TAGS_LOADED: 'SET_TAGS_LOADED',
};

export default actions;
