import React from 'react';

import { Div } from '../index';
import PropTypes from 'prop-types';
import './style.scss';

const GridType = ({ gridNumber, customClass, children }) => {
    return (
        <Div
            className={
                'GCRCol ' +
                (customClass ? customClass : '') +
                (gridNumber ? ` GCRCol${gridNumber}` : ' GCRCol12')
            }
        >
            {children}
        </Div>
    );
};

GridType.propTypes = {
    gridNumber: PropTypes.number,
    customClass: PropTypes.string,
    children: PropTypes.element,
};

export default GridType;
