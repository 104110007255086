import React from 'react';

import { SVGIcon } from '../../UIKit/index';

export default function DCGrayLeaderBoard(props) {
    return (
        <SVGIcon {...props} viewBox="0 0 456.9 499.19">
            <g id="Leaderboard" transform="translate(-28.73 -5.96)">
                <circle
                    id="Ellipse_9"
                    cx="226.95"
                    cy="226.95"
                    r="226.95"
                    transform="translate(30.23 7.46)"
                    fill="#fff"
                    stroke="#707171"
                    strokeMiterlimit="10"
                    strokeWidth="3"
                />
                <path
                    id="Path_120"
                    d="M409.56,267.21A156.789,156.789,0,0,0,425,244a111.34,111.34,0,0,0,13.32-63.66c-2.55-27.52-19.34-54.84-47.13-62.86-24.64-7.1-46.17,6.79-63.58,22.9-13,12-25.53,25.18-41.77,32.91-17.31,8.23-39.84,8.06-58.61,6.54-25.38-2.07-49.75-9.62-75.08-11.32-26-1.75-55.43,4.73-73.8,24.42C56.56,216.3,59.41,252.24,75.5,277.9a88.272,88.272,0,0,0,5.63,7.94c19.08,23.89,48.29,38.42,77.68,45.12,66,15.06,140.85,13,200.7-21.45a177,177,0,0,0,50.05-42.3Z"
                    fill="#f4f5f6"
                />
                <path
                    id="Path_121"
                    d="M407.71,289.22a162.859,162.859,0,0,0,19.36-21.64,116.07,116.07,0,0,0,23.2-63.75c1.44-28.8-11.85-59.51-39.35-71.9-24.39-11-48.68.16-69.05,14.21-15.22,10.51-30.1,22.22-48,27.79-19.08,5.93-42.33,2.42-61.48-1.94-25.9-5.9-49.94-17.31-75.86-22.82-26.59-5.66-57.93-3.34-79.82,14.27-25.94,20.87-28.33,58.4-15.51,87.28a90.378,90.378,0,0,0,4.63,9C82,287.25,110,306.59,139.33,317.87c65.9,25.33,143.52,34.27,210.42,7.61a184.57,184.57,0,0,0,57.96-36.26Z"
                    fill="none"
                    stroke="#f4f5f6"
                    strokeMiterlimit="10"
                    strokeWidth="10"
                />
                <path
                    id="Path_122"
                    d="M164.7,227.57h3s-.07.87-.05,2a3.71,3.71,0,0,0,.6,2,4.639,4.639,0,0,0,.53,1.07.25.25,0,0,1-.13.38c-.46.07-.87.15-1.23.22-.78.34-1.07.62-1.27,0a3,3,0,0,1-.37-.82,8.543,8.543,0,0,1-.24-.83,11,11,0,0,1-.62-2C164.77,228.44,164.7,227.57,164.7,227.57Z"
                    fill="#e3e2e3"
                />
                <path
                    id="Path_123"
                    d="M162.22,204.05l.66,1.25.25.47.14.27s.72-.47,1.76-1a1.19,1.19,0,0,1,1.1.09,4.73,4.73,0,0,1,1.18,0,.25.25,0,0,0,.28-.3c-.16-.44-.28-.83-.39-1.18.14-.46.33-.69-.25-.48a3,3,0,0,0-.9.06,6.942,6.942,0,0,0-.84.19,3.64,3.64,0,0,0-1.11-.11C163,203.7,162.22,204.05,162.22,204.05Z"
                    fill="#e3e2e3"
                />
                <path
                    id="Path_124"
                    d="M77,247.73l1.33.48,1.94.69.28.1s-.34.8-.69,1.9a8.34,8.34,0,0,0-.44,3.18,4.5,4.5,0,0,0,.16,1.191.24.24,0,0,1-.25.309q-.69-.14-1.23-.21c-1.3-.11-1.82,0-1.93-.7a3.11,3.11,0,0,1-.09-.9,5.439,5.439,0,0,1,.05-.87,25.21,25.21,0,0,1,.42-3.18C76.78,248.58,77,247.73,77,247.73Z"
                    fill="#e3e2e3"
                />
                <path
                    id="Path_125"
                    d="M63.2,197.12l-.87,1.11-1,1.34-.18.24s.7.49,1.58,1.26a5.11,5.11,0,0,1,1.47,2,4.49,4.49,0,0,1,.49,1.11.25.25,0,0,0,.38.13c.34-.32.64-.59.92-.83.9-.62,1.35-.74,1-1.34a3,3,0,0,0-.4-.82,7.36,7.36,0,0,0-.5-.72,14.83,14.83,0,0,0-1.45-2C63.82,197.74,63.2,197.12,63.2,197.12Z"
                    fill="#e3e2e3"
                />
                <path
                    id="Path_126"
                    d="M116.51,127.73l1.39.23,1,.17.3.05s-.21.84-.37,2a2.61,2.61,0,0,0,.34,1.69,4.34,4.34,0,0,0,.35,1.14.25.25,0,0,1-.19.36h-1.25c-.66.23-.92.47-1-.17a2.88,2.88,0,0,1-.24-.87,6.538,6.538,0,0,1-.1-.87,7.061,7.061,0,0,1-.35-1.69C116.44,128.6,116.51,127.73,116.51,127.73Z"
                    fill="#e3e2e3"
                />
                <path
                    id="Path_127"
                    d="M102.51,147.59l-1.39.23-1,.17h-.3a20.159,20.159,0,0,1,.37,2,2.71,2.71,0,0,1-.33,1.7,4.91,4.91,0,0,1-.36,1.14.24.24,0,0,0,.19.35h1.25c.66.23.93.47,1-.16a3.14,3.14,0,0,0,.25-.88c0-.24.07-.54.09-.86a7.731,7.731,0,0,0,.36-1.69C102.58,148.46,102.51,147.59,102.51,147.59Z"
                    fill="#e3e2e3"
                />
                <path
                    id="Path_128"
                    d="M195.06,113.25l-1.38.25-.52.09-.3.06a19.934,19.934,0,0,1,.39,2,1.15,1.15,0,0,1-.43,1,4.411,4.411,0,0,1-.34,1.15.24.24,0,0,0,.2.35h1.24c.38.27.54.53.52-.1a3.141,3.141,0,0,0,.23-.87,6.818,6.818,0,0,0,.08-.87,3.44,3.44,0,0,0,.46-1C195.14,114.12,195.06,113.25,195.06,113.25Z"
                    fill="#e3e2e3"
                />
                <path
                    id="Path_129"
                    d="M244.67,113.12H247s-.1.86-.12,2a1.4,1.4,0,0,0,.57,1.08,4.741,4.741,0,0,0,.49,1.09.24.24,0,0,1-.14.37l-1.24.18c-.4.32-.54.59-.62,0a2.77,2.77,0,0,1-.35-.83,8.557,8.557,0,0,1-.21-.84,4.55,4.55,0,0,1-.58-1.08C244.71,114,244.67,113.12,244.67,113.12Z"
                    fill="#e3e2e3"
                />
                <path
                    id="Path_130"
                    d="M212.39,137.38l.26-1.39.15-.78.06-.3s.83.23,2,.43a1.9,1.9,0,0,0,1.41-.34,4.05,4.05,0,0,0,1.15-.32.25.25,0,0,1,.35.21v1.25c.24.55.48.77-.15.78a3.279,3.279,0,0,1-.88.22c-.24,0-.54.05-.86.06a5.591,5.591,0,0,1-1.42.36C213.26,137.47,212.39,137.38,212.39,137.38Z"
                    fill="#e3e2e3"
                />
                <path
                    id="Path_131"
                    d="M145.61,152.93l-1.36-.35-1.14-.29-.29-.08s-.16.85-.49,2a3.28,3.28,0,0,1-1.06,1.64,4.711,4.711,0,0,1-.8.9.25.25,0,0,0,0,.4c.43.18.8.35,1.13.51.62.51.8.84,1.14.29a3,3,0,0,0,.58-.7,7.631,7.631,0,0,0,.44-.74,8.5,8.5,0,0,0,1.09-1.64C145.31,153.75,145.61,152.93,145.61,152.93Z"
                    fill="#e3e2e3"
                />
                <path
                    id="Path_132"
                    d="M289.47,126.48l-.47,1.33-.49,1.37-.1.28s-.78-.37-1.87-.77a3.4,3.4,0,0,0-2-.12,4.08,4.08,0,0,0-1.18.12.26.26,0,0,1-.31-.27c.09-.46.16-.87.22-1.23,0-1-.17-1.37.48-1.37a3.29,3.29,0,0,1,.9-.06,3.361,3.361,0,0,1,.85.08,9.56,9.56,0,0,1,2,.11C288.63,126.24,289.47,126.48,289.47,126.48Z"
                    fill="#e3e2e3"
                />
                <path
                    id="Path_133"
                    d="M198.87,173.81l-.47,1.33-.48,1.36-.11.29s-.78-.38-1.87-.77a3.4,3.4,0,0,0-2-.13,4.921,4.921,0,0,0-1.18.12.25.25,0,0,1-.31-.26c.09-.46.16-.87.22-1.24,0-1-.17-1.37.48-1.36a2.94,2.94,0,0,1,.9-.06,3.361,3.361,0,0,1,.85.08,10.421,10.421,0,0,1,2,.1C198,173.56,198.87,173.81,198.87,173.81Z"
                    fill="#e3e2e3"
                />
                <path
                    id="Path_134"
                    d="M341.39,160.6l-.47,1.33-.49,1.36-.1.29s-.78-.38-1.87-.77a3.43,3.43,0,0,0-2-.13,4.78,4.78,0,0,0-1.17.12.26.26,0,0,1-.32-.26c.1-.46.17-.87.22-1.24,0-1-.16-1.37.49-1.36a2.869,2.869,0,0,1,.89-.06,3.4,3.4,0,0,1,.86.08,10.319,10.319,0,0,1,2,.1C340.55,160.35,341.39,160.6,341.39,160.6Z"
                    fill="#e3e2e3"
                />
                <path
                    id="Path_135"
                    d="M266.2,170.17l1.1-.88,1.16-.93.24-.19a19.162,19.162,0,0,0,1.17,1.66,4.13,4.13,0,0,0,1.77,1.38,4.27,4.27,0,0,0,1.05.52.25.25,0,0,1,.12.39c-.32.35-.59.66-.83.94-.52.85-.61,1.28-1.17.93a3.62,3.62,0,0,1-.78-.42c-.2-.14-.43-.32-.68-.53a12.312,12.312,0,0,1-1.79-1.37C266.77,170.83,266.2,170.17,266.2,170.17Z"
                    fill="#d3d3d3"
                />
                <path
                    id="Path_136"
                    d="M265.39,115.93,264,116l-.85.06h-.31a19,19,0,0,1,.19,2,2.05,2.05,0,0,1-.5,1.45,4.18,4.18,0,0,1-.46,1.1.25.25,0,0,0,.17.37l1.24.12c.55.3.76.57.85-.06a3.209,3.209,0,0,0,.32-.84c.07-.25.12-.53.18-.85a6.151,6.151,0,0,0,.52-1.45C265.38,116.81,265.39,115.93,265.39,115.93Z"
                    fill="#e3e2e3"
                />
                <path
                    id="Path_137"
                    d="M242.6,86l1.25-1.22.09-.09.26-.25s.7.86,1.74,1.93c.06,0,.22,0,.65-.38a6.23,6.23,0,0,0,1.42.54.32.32,0,0,1,.21.47c-.35.46-.65.88-.9,1.25l-.09.08a3.79,3.79,0,0,1-1.06-.44c-.28-.15-.6-.35-.94-.58a5.23,5.23,0,0,1-.67.4C243.43,86.74,242.6,86,242.6,86Z"
                    fill="#e3e2e3"
                />
                <path
                    id="Path_138"
                    d="M291,100.48l-.72,1.21-.54.92-.16.26s-.69-.52-1.68-1.13a2,2,0,0,0-1.52-.26,4.39,4.39,0,0,0-1.17-.12.25.25,0,0,1-.25-.32c.18-.44.32-.83.45-1.17.05-.75-.06-1.11.54-.92a3,3,0,0,1,.89.12,8.05,8.05,0,0,1,.82.25,6,6,0,0,1,1.53.24C290.19,100.07,291,100.48,291,100.48Z"
                    fill="#e3e2e3"
                />
                <path
                    id="Path_139"
                    d="M392.67,115.6l-1.61-1.16-.31-.22-.35-.25s-.59,1.06-1.53,2.41c-.13.13-.41.23-1,0a6,6,0,0,1-1.45.81.36.36,0,0,0-.14.56c.47.46.88.88,1.24,1.26-.08.53-.23.91.31.22a4.279,4.279,0,0,0,1.07-.65,11.284,11.284,0,0,0,.92-.79,3.33,3.33,0,0,0,1.05,0C391.9,116.56,392.67,115.6,392.67,115.6Z"
                    fill="#e3e2e3"
                />
                <path
                    id="Path_140"
                    d="M357.14,128.84l1.28-.58.65-.3.28-.12s.27.82.74,1.88a1.47,1.47,0,0,0,1,.81,4.5,4.5,0,0,0,.9.77.26.26,0,0,1,0,.41,10.393,10.393,0,0,0-1,.7c-.28.49-.31.81-.66.29a3,3,0,0,1-.66-.6c-.16-.18-.34-.41-.53-.67a4.49,4.49,0,0,1-1-.81C357.54,129.61,357.14,128.84,357.14,128.84Z"
                    fill="#e3e2e3"
                />
                <path
                    id="Path_141"
                    d="M357.07,159.63l-1.4-.13-.81-.08h-.31s0,.87-.12,2a1.91,1.91,0,0,1-.7,1.29,4.54,4.54,0,0,1-.62,1,.26.26,0,0,0,.1.4c.46.1.86.21,1.21.32.47.38.63.67.81.07a3,3,0,0,0,.44-.78c.1-.23.2-.51.3-.81a5.69,5.69,0,0,0,.73-1.28C356.93,160.49,357.07,159.63,357.07,159.63Z"
                    fill="#e3e2e3"
                />
                <path
                    id="Path_142"
                    d="M326.55,191.37l1.35.4.8.24.3.08a19.723,19.723,0,0,0-.6,1.94,2,2,0,0,0,.21,1.47,4.838,4.838,0,0,0,.22,1.18.25.25,0,0,1-.23.33c-.47-.07-.88-.11-1.24-.15-.59.18-.84.39-.8-.24a3.2,3.2,0,0,1-.14-.9q0-.37,0-.87a5.83,5.83,0,0,1-.22-1.48C326.38,192.22,326.55,191.37,326.55,191.37Z"
                    fill="#e3e2e3"
                />
                <path
                    id="Path_143"
                    d="M302.57,208.1l1.36-.37,1.2-.33.29-.08s.14.86.45,2a3.44,3.44,0,0,0,1.05,1.75,4.22,4.22,0,0,0,.77.89.26.26,0,0,1,0,.41l-1.13.52c-.67.53-.87.88-1.21.33a3.249,3.249,0,0,1-.56-.7,6.294,6.294,0,0,1-.42-.75,9.728,9.728,0,0,1-1.08-1.74C302.84,208.92,302.57,208.1,302.57,208.1Z"
                    fill="#e3e2e3"
                />
                <path
                    id="Path_144"
                    d="M432.44,179.25l-1.22-.72-1.46-.85-.26-.15s-.35.79-.92,1.8a5.519,5.519,0,0,1-1.73,1.92,4,4,0,0,1-1,.67.25.25,0,0,0-.06.4c.37.3.68.57,1,.81.74.82.93,1.26,1.46.85a3.211,3.211,0,0,0,.71-.53,7.221,7.221,0,0,0,.6-.62,16,16,0,0,0,1.75-1.91A19.832,19.832,0,0,0,432.44,179.25Z"
                    fill="#e3e2e3"
                />
                <path
                    id="Path_145"
                    d="M436,230.59l1.17-.79,1.59-1.08.25-.17s.4.77,1,1.74a6.85,6.85,0,0,0,2,2.08,4.32,4.32,0,0,0,1,.61.26.26,0,0,1,.09.4c-.35.31-.65.6-.91.86-.81.95-1,1.43-1.59,1.08a2.83,2.83,0,0,1-.75-.49c-.19-.15-.41-.35-.64-.57a19.9,19.9,0,0,1-2-2.07C436.51,231.29,436,230.59,436,230.59Z"
                    fill="#e3e2e3"
                />
                <path
                    id="Path_146"
                    d="M405.33,199.86l1.14-.84,1.46-1.07.24-.18s.43.75,1.1,1.7a6,6,0,0,0,2,1.85,4.219,4.219,0,0,0,1,.57.26.26,0,0,1,.11.39c-.34.33-.63.63-.88.91-.71.94-.88,1.41-1.46,1.07a3.39,3.39,0,0,1-.76-.46c-.2-.15-.42-.34-.66-.55a18.167,18.167,0,0,1-2-1.84C405.88,200.53,405.33,199.86,405.33,199.86Z"
                    fill="#e3e2e3"
                />
                <path
                    id="Path_147"
                    d="M145.85,95.12l1.66.71.26.11.36.16a24.729,24.729,0,0,0-1,2.38c0,.16,0,.42.49.8a5.87,5.87,0,0,0,.12,1.54c0,.22-.16.44-.34.39-.58-.16-1.1-.28-1.55-.38-.33.33-.52.64-.27-.11a4.12,4.12,0,0,1-.05-1.17,8.444,8.444,0,0,1,.13-1.11,3.14,3.14,0,0,1-.52-.82C145.51,96.19,145.85,95.12,145.85,95.12Z"
                    fill="#e3e2e3"
                />
                <path
                    id="Path_148"
                    d="M158.35,113.25l1.56.88.49.28.34.19a24.412,24.412,0,0,0-1.26,2.25,1.19,1.19,0,0,0,.22,1.18,6.11,6.11,0,0,0,0,1.53c0,.22-.2.42-.38.35-.55-.22-1-.39-1.48-.54-.51.21-.78.46-.49-.28a4.12,4.12,0,0,1,.07-1.16,10.11,10.11,0,0,1,.25-1.08,3.56,3.56,0,0,1-.26-1.2C157.91,114.27,158.35,113.25,158.35,113.25Z"
                    fill="#e3e2e3"
                />
                <path
                    id="Path_149"
                    d="M401.87,143l1.11-.86.52-.39.24-.19s.44.75,1.13,1.68a1.19,1.19,0,0,0,1,.43,4.18,4.18,0,0,0,1,.55.26.26,0,0,1,.12.39c-.33.34-.61.65-.86.92-.12.52-.07.83-.51.39a2.919,2.919,0,0,1-.78-.44,8.374,8.374,0,0,1-.67-.54,3.49,3.49,0,0,1-1.05-.41C402.43,143.69,401.87,143,401.87,143Z"
                    fill="#e3e2e3"
                />
                <path
                    id="Path_150"
                    d="M224.62,98.62l2,.11h.74s-.17,1.21-.22,2.85c0,.2.19.47.8.7a6.42,6.42,0,0,0,.67,1.55.35.35,0,0,1-.22.53c-.65.06-.36.3-.87.37-.22.46-1.18.69-1.19-.19a4.23,4.23,0,0,1-.47-1.19,9.345,9.345,0,0,1-.26-1.19,3.09,3.09,0,0,1-.84-.71C224.66,99.86,224.62,98.62,224.62,98.62Z"
                    fill="#e3e2e3"
                />
                <path
                    id="Path_151"
                    d="M259.59,98.39l-2-.25h-.69a19.887,19.887,0,0,1-.26,2.84c-.05.18-.26.38-.9.49a6.171,6.171,0,0,1-.92,1.4.36.36,0,0,0,.13.56c.64.17.93.26,1.42.42l.54.12a4.651,4.651,0,0,0,.66-1.08c.15-.32.3-.7.46-1.13a3.47,3.47,0,0,0,.93-.49C259.35,99.59,259.59,98.39,259.59,98.39Z"
                    fill="#e3e2e3"
                />
                <path
                    id="Path_152"
                    d="M187.08,138.56l-.53,1.3-.3.74-.11.28s.82.26,1.88.73a1.87,1.87,0,0,1,1,1.06,4.62,4.62,0,0,1,.78.9.25.25,0,0,0,.41,0c.23-.4.45-.75.65-1.05.5-.33.82-.38.3-.74a3.43,3.43,0,0,0-.61-.66,7.6,7.6,0,0,0-.69-.54,5,5,0,0,0-1-1.07C187.86,139,187.08,138.56,187.08,138.56Z"
                    fill="#e3e2e3"
                />
                <path
                    id="Path_153"
                    d="M284.58,147.92l-.54,1.3-.3.74-.11.28s.82.26,1.88.73a1.87,1.87,0,0,1,1,1.06,4.621,4.621,0,0,1,.78.9.25.25,0,0,0,.41,0,9.3,9.3,0,0,1,.66-1c.49-.33.81-.38.29-.74a3.43,3.43,0,0,0-.61-.66c-.19-.17-.42-.35-.68-.54a5.55,5.55,0,0,0-1-1.07C285.35,148.32,284.58,147.92,284.58,147.92Z"
                    fill="#e3e2e3"
                />
                <path
                    id="Path_154"
                    d="M79.21,138.62l1.31-.51,1.52-.59.29-.11a19.241,19.241,0,0,0,.69,1.9,5.83,5.83,0,0,0,1.5,2.16,5.07,5.07,0,0,0,.89.8.24.24,0,0,1,0,.4c-.41.23-.76.44-1.07.64-.82.68-1.06,1.07-1.52.59a3.36,3.36,0,0,1-.66-.63c-.16-.19-.34-.43-.52-.69a16.609,16.609,0,0,1-1.52-2.15C79.59,139.41,79.21,138.62,79.21,138.62Z"
                    fill="#e3e2e3"
                />
                <path
                    id="Path_155"
                    d="M382.76,137.4l1.07-.91.33-.28.23-.2s.48.72,1.22,1.62a.79.79,0,0,0,.85.11,4.41,4.41,0,0,0,1.07.49.26.26,0,0,1,.13.39,10.934,10.934,0,0,0-.8,1c0,.44.11.72-.33.28a3.06,3.06,0,0,1-.79-.4,7.288,7.288,0,0,1-.7-.5,2.51,2.51,0,0,1-.86-.1C383.36,138,382.76,137.4,382.76,137.4Z"
                    fill="#e3e2e3"
                />
                <path
                    id="Path_156"
                    d="M46.87,223.24l1.18.79,2.1,1.39.25.17a19.9,19.9,0,0,0-1.11,1.69,11.7,11.7,0,0,0-1.48,3.49,4.65,4.65,0,0,0-.12,1.2c0,.18-.18.32-.32.25-.42-.2-.8-.36-1.13-.5-1.48-.58-2.1-.66-2.11-1.4a3.63,3.63,0,0,1,.13-.91,8.169,8.169,0,0,1,.25-.83A34.8,34.8,0,0,1,46,225.07C46.47,224,46.87,223.24,46.87,223.24Z"
                    fill="#e3e2e3"
                />
                <path
                    id="Path_157"
                    d="M140.92,198.54c-6.16,5-32,14.52-32.28,14.73-1.25.82-2,2.22-3.22,3.06a10.41,10.41,0,0,1-4.75,1.68c-5.92.54-11.07-3.81-13.07-9.14-2.09-5.54-.4-13.28,5.47-15.82a19.13,19.13,0,0,1,8-1.83c-3.35-26.33,9.61-31,11.7-31.13,6-7.84,15.48-2.86,16.39-1.29,5.1-1.25,9.41,9.65,3.77,12.77l-.12.07c.23,6.11,1,10.59,6.6,12.79C145.52,186.84,147.09,193.53,140.92,198.54Z"
                    fill="#707171"
                />
                <path
                    id="Path_158"
                    d="M81.57,158.1l-8.09,2.33c-3-2.65-3.84-4.86-5.1-8.57-.49-1.45-2.45-7.29-.87-7.76.53-.16,1.5.47,2,1.42a16.72,16.72,0,0,1-.78-4.33c0-.75.17-1.27.69-1.47s.87.21,1.29.78c0-.13.28-1.59.84-1.8s1.24.49,1.86,1.71l.24.5a2.71,2.71,0,0,1,0-.41,1,1,0,0,1,.64-.78c1.06-.4,1.91,1.78,2.19,2.43a66,66,0,0,1,3.12,7.77c.37,1.27.91,2.53,1.2,3.83C81,154.18,81.79,158,81.57,158.1Z"
                    fill="#e3e2e3"
                />
                <path
                    id="Path_159"
                    d="M81,149.3c.44,3.19.16,5.87-.62,6s-1.78-2.39-2.22-5.58-.74-5.73.61-6C79.86,143.51,80.55,146.11,81,149.3Z"
                    fill="#e3e2e3"
                />
                <path
                    id="Path_160"
                    d="M96.69,182.48l-1.43,7.8H86.07l-1.31-1.69-7-9a16.44,16.44,0,0,1-3-6.17l-3.51-16.1,9.8-2.55,4.39,13.67A16.28,16.28,0,0,0,89.38,175Z"
                    fill="#e3e2e3"
                />
                <path
                    id="Path_161"
                    d="M158.5,149.88l7.46,2.37c2.21-3.09,3.1-7.25,3.49-11,.15-1.46.74-7.3-.83-7.4-.53,0-1.3.75-1.61,1.75a15.68,15.68,0,0,0-.19-4.19c-.13-.71-.42-1.14-.95-1.22s-.76.37-1,1c0-.12-.59-1.42-1.16-1.49s-1,.71-1.36,2a3.812,3.812,0,0,0-.12.51,1.234,1.234,0,0,0-.12-.37,1,1,0,0,0-.76-.59c-1.08-.15-1.41,2-1.53,2.72a61.836,61.836,0,0,0-1.26,7.88c-.07,1.27-.1,2.54-.1,3.8C158.42,146.1,158.26,149.8,158.5,149.88Z"
                    fill="#e3e2e3"
                />
                <path
                    id="Path_162"
                    d="M157.22,141.79c.73,3,1.92,5.26,2.65,5.08s.74-2.74,0-5.73-1.36-5.33-2.65-5.08C156.19,136.27,156.49,138.81,157.22,141.79Z"
                    fill="#e3e2e3"
                />
                <path
                    id="Path_163"
                    d="M147.2,175.36l5-7.67a12.11,12.11,0,0,0,1.45-3.14l4.77-18.88,10.11.83-4.28,20.25a11.921,11.921,0,0,1-.76,2.62l-4.83,11.55-6.55,2.88Z"
                    fill="#e3e2e3"
                />
                <path
                    id="Path_164"
                    d="M148.42,329.77l-.41,5.35-3.25,42.06-.15,2-10-1.56v-2h0V332.79a34.28,34.28,0,0,0-.92-7.88c-2.89-12.09-11.51-48.42-10.92-49.35l19.08-1.95,6.2,45.9.15,1.05a43.75,43.75,0,0,1,.22,9.21Z"
                    fill="#e3e2e3"
                />
                <path
                    id="Path_165"
                    d="M134.2,384.4a2.22,2.22,0,0,0,2.53,2.34c1.9,0,19.39.06,19.58,0a5.08,5.08,0,0,0-1.37-3.25c-1-1-9.47-5.87-10.17-6.31-5.48,2.86-10.18-1.58-10.18-1.58a76.872,76.872,0,0,0-.39,8.8Z"
                    fill="#707171"
                />
                <path
                    id="Path_166"
                    d="M130.73,276.48S122.56,309.6,113.26,322a15.141,15.141,0,0,1-3.62,3.6c-2.7,1.76-6.17,1-10.3-1.73-7-4.62-16-14.92-26.49-28.12L71.47,294l3.9-6.89,5.21,1.78,20.91,18.07,8-32.82Z"
                    fill="#e3e2e3"
                />
                <path
                    id="Path_167"
                    d="M74.05,283a2.22,2.22,0,0,0-3.42.46c-1.2,1.46-12.39,14.91-12.47,15.1a5.09,5.09,0,0,0,3.39,1C63,299.4,72.1,296,72.89,295.7c1.28-6.05,7.69-6.85,7.69-6.85A77.257,77.257,0,0,0,74.05,283Z"
                    fill="#707171"
                />
                <path
                    id="Path_168"
                    d="M121.08,306.39c.61-2.2,1.27-4.39,1.94-6.57s1.37-4.35,2.06-6.52l2.16-6.5c.75-2.15,1.5-4.31,2.31-6.44l.31.1c-.6,2.2-1.26,4.39-1.93,6.57l-2.07,6.52c-.73,2.16-1.42,4.34-2.18,6.49s-1.49,4.31-2.29,6.45Z"
                    fill="#707171"
                    opacity="0.3"
                />
                <path
                    id="Path_169"
                    d="M151.12,279.28c-34.27,17.79-55-1-60.19-8.9,7.4-8.13,12-19.44,14.41-26.65,1.39-4.19,2-7,2-7l29-2.32,3.21-.25.12.46c.23.87.77,2.92,1.49,5.69C144.21,251.88,150.49,276,151.12,279.28Z"
                    fill="#707171"
                />
                <path
                    id="Path_170"
                    d="M141.18,240.31c-5.69,4.45-19.27,6.7-35.84,3.42,1.39-4.19,2-7,2-7l29-2.32,3.33.21C139.92,235.49,140.46,237.54,141.18,240.31Z"
                    fill="#fff"
                    opacity="0.2"
                />
                <path
                    id="Path_171"
                    d="M158.66,180.92c-1.26,2.64-4.54,8.86-8.33,15.26a50.32,50.32,0,0,0-5.88,15.92c-1.73,8.26-4.39,17.68-1.82,22.77,2.21,4.35-7.24,5.43-17.69,6-8.31.44-22.15.4-19.65-4.18,4.66-8.53-.43-19.66-1.81-24.11-1.84-6-9.44-14.32-14-18.92-2-2.06-3.44-3.37-3.44-3.37s7.94-1.41,10.62-7.8c1.78,1.73,7.92,7.19,11.34,10.21a6.45,6.45,0,0,0,5.39,1.51l6.46-1.15h6.88c2.48.34,4.32.56,5.68.71A5.72,5.72,0,0,0,138,191l9.24-15.61S153.43,181.41,158.66,180.92Z"
                    fill="#535454"
                />
                <path
                    id="Path_172"
                    d="M116.37,183.8H128.6v12a4.71,4.71,0,0,1-4.71,4.71h-2.81a4.71,4.71,0,0,1-4.71-4.71v-12Z"
                    fill="#e3e2e3"
                />
                <path
                    id="Path_173"
                    d="M116.37,187.44s7.38,10.51,12.23-1.05C128.78,186.42,116.37,187.44,116.37,187.44Z"
                    fill="#707171"
                    opacity="0.3"
                />
                <path
                    id="Path_174"
                    d="M130.5,175.7a32.12,32.12,0,0,1,.1,5.67,10.32,10.32,0,0,1-1,3.32c-3.19,6.66-12.46,7.13-17.14-2.22a26.72,26.72,0,0,1-2.13-16.1l10.43-4.53,10.06,4.93A37.179,37.179,0,0,0,130.5,175.7Z"
                    fill="#f4f5f6"
                />
                <path
                    id="Path_175"
                    d="M132.76,171.64c-8.2,1.4-12.52-.79-16.39-5.29-2.3,1.88-1.71,3.36-1.57,6.17.11,2.08-.41,4.28-5.64,4.28L107,169.28l2.4-5.54,10.47-4.34,12.31,2Z"
                    fill="#707171"
                />
                <path
                    id="Path_176"
                    d="M120.18,176c0,.6-.27,1.1-.67,1.12s-.76-.45-.79-1,.27-1.1.68-1.12S120.15,175.42,120.18,176Z"
                    fill="#707171"
                />
                <path
                    id="Path_177"
                    d="M120.56,174.23a1.77,1.77,0,0,0-.9-.48,2,2,0,0,0-1,.05,3.45,3.45,0,0,0-.92.46,6.38,6.38,0,0,0-.83.74l-.23-.13a2.88,2.88,0,0,1,.68-1.05,2.53,2.53,0,0,1,1.13-.66,2.24,2.24,0,0,1,1.33.05,1.65,1.65,0,0,1,1,.87Z"
                    fill="#707171"
                />
                <path
                    id="Path_178"
                    d="M125.38,175.48c0,.6.39,1.07.79,1.05s.71-.53.68-1.13-.39-1.07-.79-1.05S125.35,174.88,125.38,175.48Z"
                    fill="#707171"
                />
                <path
                    id="Path_179"
                    d="M124.47,173.83a2.34,2.34,0,0,1,.64-.88,1.88,1.88,0,0,1,1.08-.45,2.46,2.46,0,0,1,2,.91l-.15.21c-.32-.11-.62-.26-.92-.33a2.35,2.35,0,0,0-.85-.13,1.7,1.7,0,0,0-.8.25,4.8,4.8,0,0,0-.76.58Z"
                    fill="#707171"
                />
                <path
                    id="Path_180"
                    d="M121.38,182.78l4.41-.61h0c-.14,2.12-3.09,3.29-4.41.61Z"
                    fill="#707171"
                    opacity="0.3"
                />
                <path
                    id="Path_181"
                    d="M123.36,175.69a5.11,5.11,0,0,0,.29,1.37,1.2,1.2,0,0,0,.37.46l.3.2.15.1.18.16a1.23,1.23,0,0,1-1.66,1.8l.13-.22a1,1,0,0,0,1.1-.21.69.69,0,0,0,0-.93,1.07,1.07,0,0,0-.24-.21l-.31-.23a1.51,1.51,0,0,1-.48-.72,3.23,3.23,0,0,1,0-1.57Z"
                    fill="#707171"
                />
                <circle
                    id="Ellipse_10"
                    cx="2.85"
                    cy="2.85"
                    r="2.85"
                    transform="translate(114.81 178.11)"
                    fill="#707171"
                    opacity="0.3"
                />
                <path
                    id="Path_182"
                    d="M130.6,181.37a2.058,2.058,0,0,1-.35,0,2.86,2.86,0,0,1-2.72-3,2.83,2.83,0,0,1,3-2.71A32.12,32.12,0,0,1,130.6,181.37Z"
                    fill="#707171"
                    opacity="0.3"
                />
                <path
                    id="Path_183"
                    d="M113.1,177c-1.71-1.41-3.58-1.61-4.4.09-1,2,1.94,6.48,4.79,5C113.75,181.75,113.1,177,113.1,177Z"
                    fill="#fff"
                />
                <path
                    id="Path_184"
                    d="M111.88,178.71a2.24,2.24,0,0,0-.95-1.21,1,1,0,0,0-1.32.17l-.15-.08a1,1,0,0,1,.72-.63,1.36,1.36,0,0,1,.95.18,1.87,1.87,0,0,1,.91,1.55Z"
                    fill="#707171"
                    opacity="0.3"
                />
                <path
                    id="Path_185"
                    d="M116.37,202.72a11.06,11.06,0,0,0,6.5,2,9.2,9.2,0,0,0,3.22-.65,13.14,13.14,0,0,0,3-1.85,6.24,6.24,0,0,1-2.4,3,7.12,7.12,0,0,1-3.81,1.17,7.86,7.86,0,0,1-3.84-.92,5.91,5.91,0,0,1-2.67-2.75Z"
                    fill="#fff"
                    opacity="0.2"
                />
                <path
                    id="Path_186"
                    d="M129.11,234.41c1.3-.45,2.58-.91,3.81-1.45a12.531,12.531,0,0,0,3.33-2.1,9,9,0,0,0,2.18-3.21,23.53,23.53,0,0,0,1.16-3.88,12.49,12.49,0,0,1-.55,4.1,8.05,8.05,0,0,1-2.23,3.59,10.38,10.38,0,0,1-3.65,2.1,17.71,17.71,0,0,1-4.05.85Z"
                    fill="#fff"
                    opacity="0.2"
                />
                <path
                    id="Path_187"
                    d="M77.22,148.23a7,7,0,0,0-.94,2.72,3.52,3.52,0,0,0,.79,2.61l-.15.19a2.57,2.57,0,0,1-1-1.25,3.54,3.54,0,0,1-.24-1.61A4.77,4.77,0,0,1,77,148.08Z"
                    fill="#707171"
                    opacity="0.3"
                />
                <path
                    id="Path_188"
                    d="M70.25,149a4.36,4.36,0,0,1,1.31-1,10.229,10.229,0,0,1,1.48-.65,15.192,15.192,0,0,1,1.54-.44,10.711,10.711,0,0,1,1.59-.24v.24c-1,.38-2,.67-3,1-.5.16-1,.35-1.46.55a8,8,0,0,0-1.37.69Z"
                    fill="#707171"
                    opacity="0.3"
                />
                <path
                    id="Path_189"
                    d="M160.25,139.15a4.75,4.75,0,0,1,2,2.39,3.55,3.55,0,0,1,.17,1.62,2.58,2.58,0,0,1-.68,1.45l-.2-.14a3.55,3.55,0,0,0,.13-2.73,7,7,0,0,0-1.59-2.4Z"
                    fill="#707171"
                    opacity="0.3"
                />
                <path
                    id="Path_190"
                    d="M167.06,139.35a8.09,8.09,0,0,0-1.48-.41c-.5-.1-1-.19-1.53-.25-1-.16-2.08-.24-3.12-.43V138a10.77,10.77,0,0,1,1.6-.07,14.184,14.184,0,0,1,1.6.13,10.61,10.61,0,0,1,1.58.35,4.6,4.6,0,0,1,1.48.71Z"
                    fill="#707171"
                    opacity="0.3"
                />
                <path
                    id="Path_191"
                    d="M148.42,329.77l-.41,5.35c-6.51-7.47-4.72-12.32,0-15.62l.15,1.05a43.75,43.75,0,0,1,.26,9.22Z"
                    fill="#707171"
                    opacity="0.3"
                />
                <path
                    id="Path_192"
                    d="M113.26,322a15.141,15.141,0,0,1-3.62,3.6c-2.7,1.76-6.17,1-10.3-1.73C103.91,318.86,108.57,318.3,113.26,322Z"
                    fill="#707171"
                    opacity="0.3"
                />
                <path
                    id="Path_193"
                    d="M430.28,354.34a3.37,3.37,0,0,1,1.29,4.25c-.9,1.93-7,17.16-12.43,18.39-1,.22-1.78-1.12-2.29-3.29-.38-1.56.1-12.63.1-13.58,6.06-3.52,4.71-10.42,4.65-10.74h0a88.157,88.157,0,0,1,8.68,4.97Z"
                    fill="#707171"
                />
                <path
                    id="Path_194"
                    d="M414.75,344.48l6.84,4.88h0c.06.32,1.41,7.22-4.65,10.74l-8.25-7.05Z"
                    fill="#e3e2e3"
                />
                <path
                    id="Path_195"
                    d="M392.07,384.05c-.22,2.7-2.12,2.66-2.87,2.66-2.15,0-22,.07-22.22,0a5.76,5.76,0,0,1,1.56-3.69c1.12-1.15,10.74-6.66,11.54-7.16,6.21,3.25,11.32-1.57,11.56-1.8h0A88.792,88.792,0,0,1,392.07,384.05Z"
                    fill="#707171"
                />
                <path
                    id="Path_196"
                    d="M392.12,365.66l-.47,8.39h0c-.24.23-5.35,5.05-11.56,1.8l1.57-10.74Z"
                    fill="#e3e2e3"
                />
                <path
                    id="Path_197"
                    d="M398.5,280.5l3.67,34.5a9.45,9.45,0,0,0,1.32,3.93l14.35,23.69-11,16.23-18.69-27.08Z"
                    fill="#707171"
                />
                <path
                    id="Path_198"
                    d="M404,266.36A71.5,71.5,0,0,0,399.91,286l-5.51,82.24H376l2.15-107.76.24-12,27.35-2.39a19.4,19.4,0,0,1,.95,4.12C407.24,255.2,406.06,260.66,404,266.36Z"
                    fill="#707171"
                />
                <path
                    id="Path_199"
                    d="M365.46,164.08a6,6,0,0,1-1.71-6.93,2.61,2.61,0,0,1,2.25-1.49c-2-2-.86-4-.32-5a4,4,0,0,1,1.64-1.74,6.14,6.14,0,0,1,4.14-.17,2.76,2.76,0,0,1,1-2.6,5.31,5.31,0,0,1,2.6-1.57c1.43-.36,3.48-.15,4.5,1,.18.21.43,1,.7,1s.35-.17.46-.34a3.66,3.66,0,0,1,3-1.38,3.93,3.93,0,0,1,2.73.82,2,2,0,0,1,.38,2.63,5.11,5.11,0,0,1,5.28.09,3.57,3.57,0,0,1,.89,4.89,3,3,0,0,1,2.36,1.21,5.68,5.68,0,0,1,1,2.54,9.91,9.91,0,0,1-3.6,9.35,9.21,9.21,0,0,1-5,1.84,18.7,18.7,0,0,1-4.28-.45,40.293,40.293,0,0,1-7.36-1.86,20,20,0,0,1-4.78-2.7c-.58-.45-.85,2-1.63,2.08a5.4,5.4,0,0,1-4.25-1.22Z"
                    fill="#707171"
                />
                <path
                    id="Path_200"
                    d="M357.08,212.08l-6.48,7.31s-8.61-7.4-12.74-14c-.07-.14-.18-.27-.26-.41a19.56,19.56,0,0,1-1.31-2.64s0,0,0-.06a1.29,1.29,0,0,1,.39-1.35c.51-.43.64-.11,1.62.76-.9-1.89-1.51-3-1.92-4.09-.3-.84-.27-1.5.24-1.91s1.06-.09,1.75.4c0-.14-.26-1.9.3-2.34s1.58.09,2.73,1.25a6.247,6.247,0,0,1,.45.48,3.171,3.171,0,0,1-.1-.48,1.25,1.25,0,0,1,.43-1.12c1.06-.85,2.82,1.31,3.38,1.94a77.763,77.763,0,0,1,6.39,7.65c.89,1.31,1.73,2.65,2.54,4C354.75,208,357.28,211.85,357.08,212.08Z"
                    fill="#707171"
                    opacity="0.3"
                />
                <path
                    id="Path_201"
                    d="M350.6,219.39s18.31,15.79,24.66,15.13,8.17-14,8.17-14l-26.35-8.46L350,214.63Z"
                    fill="#707171"
                    opacity="0.3"
                />
                <path id="Path_202" d="M390.27,168.06v22.25H377.79V170.23Z" fill="#e3e2e3" />
                <path
                    id="Path_203"
                    d="M386.14,176.18a10.06,10.06,0,0,1-8.35,7.07v-6.51Z"
                    fill="#707171"
                    opacity="0.3"
                />
                <path
                    id="Path_204"
                    d="M388.1,173.24c-2.31,4.48-6.8,7.95-11.86,6.36-2.61-.82-4.12-2.82-5-5.64a28,28,0,0,1-1-5.56c-.31-3.13-.36-6.71-.46-10.5l9.7-6.36,9.55,5.35S390.42,168.75,388.1,173.24Z"
                    fill="#e3e2e3"
                />
                <path
                    id="Path_205"
                    d="M406.64,250.2c-4.55,6.45-17.51,10-28.54,10.31l.24-12,27.35-2.39A19.4,19.4,0,0,1,406.64,250.2Z"
                    fill="#fff"
                    opacity="0.3"
                />
                <path
                    id="Path_206"
                    d="M405.47,249.63c-8,4.25-18.4,4.9-29.61,4.28a2.28,2.28,0,0,1-2.7-2.21q-.14-6.76-.26-13.52-.16-7.92-.31-15.83c0-1,0-2-.07-3-.22-6.62-.68-13.32-.48-19.9.21-6.83,3.66-11.32,10-13.66,4.52-1.66,10.2-2.72,13.65,1.65,5.9,7.49,6.49,17.51,8.52,26.43a252.679,252.679,0,0,1,4.64,29.2C409.17,245.68,408.65,247.6,405.47,249.63Z"
                    fill="#707171"
                />
                <path
                    id="Path_207"
                    d="M393.25,220.33c-1,6.72-5.39,18.83-20.35,17.85q-.16-7.92-.31-15.83c0-1,0-2-.07-3Z"
                    fill="#1f3c88"
                    opacity="0.3"
                />
                <path
                    id="Path_208"
                    d="M394.18,219.39s-1.64,5.55-4.95,9.1a9.43,9.43,0,0,1-3.44,2.48c-1.49.58-3.84.46-6.73-.25-6.66-1.64-16.23-6.42-24.92-13.12l-2.63-12.15c8.37-1.15,19.34,6.57,28.35,10.18Z"
                    fill="#e3e2e3"
                />
                <path
                    id="Path_209"
                    d="M390,159.55a3.35,3.35,0,0,0-.31-2.78,3.41,3.41,0,0,0-2.32-1.58,2,2,0,0,0-1.12-1.29,6.78,6.78,0,0,0-1.72-.49,2.679,2.679,0,0,1-.82-.25,4.61,4.61,0,0,1-.94-.72,5.49,5.49,0,0,0-4.43-1.16c-.86.17-1.69.54-2.56.66a10.913,10.913,0,0,0-3.2.47c-.79.41-1.28,1.24-2,1.81s-1.86,1.13-2,2.12a1.61,1.61,0,0,1-.18.73,1.35,1.35,0,0,1-.56.32,1.65,1.65,0,0,0-.88.84,3.34,3.34,0,0,0,2.55,4.27,3.46,3.46,0,0,0,1.68.23,3.31,3.31,0,0,0,2.85-2.61,2,2,0,0,0,.62,1.38,3.07,3.07,0,0,0,1.71.76c1.31.15,2.88-.1,3.44-1.47-.07.18.92,1.07,1.07,1.21a6.876,6.876,0,0,0,.67.54,8.522,8.522,0,0,0,.8.53c.2.12.42.24.63.34a6.5,6.5,0,0,0,3.53.66,6.21,6.21,0,0,0,4.26-2.48,3,3,0,0,0-.77-2.04Z"
                    fill="#707171"
                />
                <path
                    id="Path_210"
                    d="M360.12,209.85l-6,7.73s-9.09-6.8-13.65-13.07c-.09-.14-.2-.26-.28-.4a17.647,17.647,0,0,1-1.49-2.55v-.05a1.28,1.28,0,0,1,.3-1.37c.49-.46,1.23.32,2.26,1.13a20.09,20.09,0,0,1-2.78-4.44c-.36-.82-.37-1.48.11-1.92s1-.16,1.77.28c-.05-.13-.39-1.87.14-2.36s1.58,0,2.81,1.07q.24.21.48.45a4.789,4.789,0,0,1-.13-.47,1.26,1.26,0,0,1,.36-1.15c1-.92,2.9,1.11,3.5,1.71a78.007,78.007,0,0,1,6.88,7.2c1,1.25,1.91,2.53,2.81,3.83C357.52,205.91,360.31,209.6,360.12,209.85Z"
                    fill="#e3e2e3"
                />
                <path
                    id="Path_211"
                    d="M356.12,201.47c1.6,4.2,2.19,7.88,1.31,8.22s-2.9-2.8-4.5-7-2.81-7.54-1.31-8.21C352.83,193.94,354.52,197.28,356.12,201.47Z"
                    fill="#e3e2e3"
                />
                <path
                    id="Path_212"
                    d="M394.18,195.07c4.75,8.44,5.52,22.71,3.4,28.41l-1.52-.47Z"
                    fill="#fff"
                    opacity="0.2"
                />
                <path
                    id="Path_213"
                    d="M379.86,215.63s.29-21.27,8.25-22.72c7.7-1.4,10,3.77,7.95,30.1C395.77,223,379.86,215.63,379.86,215.63Z"
                    fill="#707171"
                />
                <path id="Path_214" d="M367.31,157.54a2.429,2.429,0,0,0-.33.69" fill="none" />
                <path
                    id="Path_215"
                    d="M396,223.16c-1.4-.53-2.77-1.1-4.13-1.68s-2.72-1.19-4.08-1.79-2.69-1.24-4-1.89-2.66-1.31-4-2l.14-.3c1.39.52,2.76,1.09,4.13,1.66s2.72,1.19,4.07,1.8,2.7,1.24,4,1.9,2.66,1.31,4,2Z"
                    fill="#fff"
                    opacity="0.2"
                />
                <path
                    id="Path_216"
                    d="M385.92,194.12c-.38.38-.76.64-1.1,1a6,6,0,0,0-.5.51,4.7,4.7,0,0,0-.44.54,10.759,10.759,0,0,0-1.39,2.45,25.37,25.37,0,0,0-1.5,5.51c-.15,1-.29,1.9-.41,2.85s-.19,1.92-.25,2.88c-.15,1.93-.2,3.86-.31,5.8h-.32c-.06-1-.08-1.94-.12-2.92s0-1.94,0-2.92a45.839,45.839,0,0,1,.57-5.83,23.5,23.5,0,0,1,1.67-5.65,11.11,11.11,0,0,1,1.59-2.53,11.58,11.58,0,0,1,2.25-1.88Z"
                    fill="#fff"
                    opacity="0.2"
                />
                <path
                    id="Path_217"
                    d="M389.23,228.49a9.55,9.55,0,0,1-3.44,2.48c-1.56.62-4.66.42-7.82-.54C379.85,225.87,387.43,226.13,389.23,228.49Z"
                    fill="#707171"
                    opacity="0.3"
                />
                <path
                    id="Path_218"
                    d="M343.41,193.82a39.328,39.328,0,0,1,2.88,2.75c.46.48.91,1,1.35,1.46a16.187,16.187,0,0,1,1.26,1.54l-.18.16c-.52-.42-1-.87-1.49-1.33l-1.39-1.4c-.9-1-1.8-1.94-2.61-3Z"
                    fill="#707171"
                    opacity="0.3"
                />
                <path
                    id="Path_219"
                    d="M340.4,195c1.11,1,2.12,2.1,3.14,3.19.5.56,1,1.12,1.47,1.69s.95,1.15,1.39,1.76l-.19.16c-.56-.5-1.08-1-1.61-1.56s-1-1.08-1.52-1.63a40.544,40.544,0,0,1-2.86-3.45Z"
                    fill="#707171"
                    opacity="0.3"
                />
                <path
                    id="Path_220"
                    d="M340.93,200.65a16.63,16.63,0,0,1,1.81,1.64c.28.3.55.6.82.91a11.929,11.929,0,0,1,.72,1l-.18.16a10.378,10.378,0,0,1-1-.78c-.29-.28-.58-.57-.86-.86a15.672,15.672,0,0,1-1.54-1.89Z"
                    fill="#707171"
                    opacity="0.3"
                />
                <path
                    id="Path_221"
                    d="M379.51,166.46c0,.62.42,1.1.84,1.07s.72-.56.67-1.18-.42-1.1-.84-1.06S379.46,165.84,379.51,166.46Z"
                    fill="#2d1510"
                />
                <path
                    id="Path_222"
                    d="M378.67,164.54a2,2,0,0,1,1.95-1,2.28,2.28,0,0,1,1.08.43,2.66,2.66,0,0,1,.7.87l-.2.15a5,5,0,0,0-.8-.56,2.15,2.15,0,0,0-1.72-.18,2.731,2.731,0,0,0-.81.46Z"
                    fill="#2d1510"
                />
                <path
                    id="Path_223"
                    d="M374.09,166.58c.05.62-.25,1.15-.67,1.18s-.79-.45-.84-1.07.25-1.15.67-1.18S374.05,166,374.09,166.58Z"
                    fill="#2d1510"
                />
                <path
                    id="Path_224"
                    d="M374.78,164.7a2.81,2.81,0,0,0-1.8-.46,2,2,0,0,0-.83.32,4.891,4.891,0,0,0-.73.66l-.21-.12a2.32,2.32,0,0,1,.6-1,2.08,2.08,0,0,1,1.09-.51,2.24,2.24,0,0,1,2.05.9Z"
                    fill="#2d1510"
                />
                <path
                    id="Path_225"
                    d="M379.17,172.88l-4.77-.16h0C375.4,174.96,377.13,175.62,379.17,172.88Z"
                    fill="#fff"
                />
                <path
                    id="Path_226"
                    d="M376.68,166a4,4,0,0,1,.1,1.82,1.68,1.68,0,0,1-.5.84,5.847,5.847,0,0,0-.61.58.85.85,0,0,0,.16,1.21,1.39,1.39,0,0,0,1.46,0l.12.16a1.57,1.57,0,0,1-1.84.21,1.25,1.25,0,0,1-.57-.88,1.39,1.39,0,0,1,.26-1,4.632,4.632,0,0,1,.67-.62,1.37,1.37,0,0,0,.43-.63,5.049,5.049,0,0,0,.12-1.67Z"
                    fill="#707171"
                    opacity="0.3"
                />
                <circle
                    id="Ellipse_11"
                    cx="2.87"
                    cy="2.87"
                    r="2.87"
                    transform="translate(379.953 168.956) rotate(-13.1)"
                    fill="#707171"
                    opacity="0.3"
                />
                <path
                    id="Path_227"
                    d="M371.21,174a28,28,0,0,1-1-5.56,2.87,2.87,0,0,1,1,5.56Z"
                    fill="#707171"
                    opacity="0.3"
                />
                <path
                    id="Path_228"
                    d="M387.8,165.6a3.79,3.79,0,0,1,3.56-1.45c2.12.31,1.78,8.48-3.34,6.92C387,171.07,387.8,165.6,387.8,165.6Z"
                    fill="#e3e2e3"
                />
                <path
                    id="Path_229"
                    d="M389.48,167a1.63,1.63,0,0,1,.39-1.64,1,1,0,0,1,.94-.22,1.21,1.21,0,0,1,.68.54l-.14.15a1.689,1.689,0,0,0-.63-.25.7.7,0,0,0-.51.17,2.06,2.06,0,0,0-.53,1.24Z"
                    fill="#707171"
                    opacity="0.3"
                />
                <path
                    id="Path_230"
                    d="M222.77,365.18l-2.29,8.15c2.33,4.95,7.83,6.69,13,2l1.86-8.75Z"
                    fill="#e3e2e3"
                />
                <path
                    id="Path_231"
                    d="M220.48,373.33,216.07,389c-.78,3.39,3,9,4.72,8.87h0c2.75.15,8.31-2.26,9.1-5.65l3.58-16.91C227,379.18,223.68,377.05,220.48,373.33Z"
                    fill="#707171"
                />
                <path
                    id="Path_232"
                    d="M255.31,363.13l.54,9.43h0c2,4.19,10.47,7.82,13,2l-1.76-12.06Z"
                    fill="#e3e2e3"
                />
                <path
                    id="Path_233"
                    d="M255.37,383.79c.25,3,2.39,3,3.22,3,2.42,0,24.72.08,25,0a6.46,6.46,0,0,0-1.75-4.14c-1.26-1.3-12.06-7.48-13-8-2.1,2.81-8.33,2.81-13-2h0A98.9,98.9,0,0,0,255.37,383.79Z"
                    fill="#707171"
                />
                <path
                    id="Path_234"
                    d="M209.33,233.54c2.9,2.46,11.71,7.48,15.73,6.25s2.12-10.48,2.12-10.48l-12.94-3.8Z"
                    fill="#e3e2e3"
                />
                <path
                    id="Path_235"
                    d="M261.78,148.52a.727.727,0,0,1-.09.08c-5.8,4.31-10-3.79-13-4.53s-7,2.13-9.56.34c0,0-1.62,10.83-.5,14.4-16.67-6.59-7.59-23.27-3-21.36,3.2-11.16,14.13-6.2,18.93-4.41C265.54,128.22,267.47,144.1,261.78,148.52Z"
                    fill="#707171"
                />
                <path
                    id="Path_236"
                    d="M314.34,141.3c-.34-.11,1.1-5.41,1.32-6a6.34,6.34,0,0,1,3-3.65c4-2.16,8.93-2.56,13.54-2.75,2.9-.11,9.52-.49,11.05,1.73s-2.2,3.53-3.86,3.92c-3.2.75-6.81.81-9.54,2.62-2.52,1.67-4.86,5.07-4.08,7.91Z"
                    fill="#e3e2e3"
                />
                <path
                    id="Path_237"
                    d="M277.97,226.67l-6.84,140.39-17.55.59.59-79.71-16.66,80.61-18.44-.6,15.17-145.74Z"
                    fill="#535454"
                />
                <path
                    id="Path_238"
                    d="M277.82,235.55a20.574,20.574,0,0,1-2,1.15,52.55,52.55,0,0,1-15.18,4.51,61.911,61.911,0,0,1-13.44.69,36.17,36.17,0,0,1-14.47-3.56l.31-4.2,4.15-56.92,30.11-1.34Z"
                    fill="#fff"
                />
                <path
                    id="Path_239"
                    d="M250.38,180.9h0a6.66,6.66,0,0,1-6.66-6.71l-.63-13.56H256.4l.63,13.66a6.65,6.65,0,0,1-6.65,6.61Z"
                    fill="#e3e2e3"
                />
                <path
                    id="Path_240"
                    d="M243.29,165.63c2.79,4.37,9.09,7.53,13.29-.42Z"
                    fill="#707171"
                    opacity="0.3"
                />
                <path
                    id="Path_241"
                    d="M259.58,158.06c-.32,3.19-1.28,5.86-3.29,7.38a11.65,11.65,0,0,1-16.62-3.56c-4.24-6.81-3.91-18.75-3.91-18.75l10.15-4.57,10.49.56,1.9,4.69a71,71,0,0,1,1.28,8.7A34.251,34.251,0,0,1,259.58,158.06Z"
                    fill="#e3e2e3"
                />
                <rect
                    id="Rectangle_89"
                    width="5.22"
                    height="38.62"
                    transform="translate(326.47 83.7)"
                    fill="#707171"
                />
                <path
                    id="Path_242"
                    d="M339.85,91.38l2.54,14.83L329.07,99.2l-13.32,7.01,1.72-9.99.83-4.84L307.52,80.87l14.89-2.17,6.66-13.49,6.66,13.49,3.37.49,11.53,1.68Z"
                    fill="#e3e2e3"
                />
                <path
                    id="Path_243"
                    d="M342,126.07H316.13l.33-1.5,1.94-8.67a2.47,2.47,0,0,1,2.41-1.93h16.53a2.46,2.46,0,0,1,2.4,1.93Z"
                    fill="#e3e2e3"
                />
                <path
                    id="Path_244"
                    d="M339.85,91.38l2.54,14.83-13.32-7-13.32,7,1.72-10c11.85-2.24,18.3-10.43,21.63-17l11.53,1.68Z"
                    fill="#707171"
                    opacity="0.3"
                />
                <path
                    id="Path_245"
                    d="M323,82.34c.2.92-1.16,2-3,2.4s-3.55,0-3.75-.94,1.16-2,3-2.41S322.83,81.42,323,82.34Z"
                    fill="#fff"
                    opacity="0.9"
                />
                <path
                    id="Path_246"
                    d="M342,126.07H316.13l.33-1.5c6.82-.46,13.28-5.74,16.34-10.6h4.54a2.46,2.46,0,0,1,2.4,1.93Z"
                    fill="#707171"
                    opacity="0.3"
                />
                <path
                    id="Path_247"
                    d="M344.29,129.14H313.86a2.31,2.31,0,0,1-2.31-2.31h0a2.32,2.32,0,0,1,2.31-2.31h30.43a2.32,2.32,0,0,1,2.31,2.31h0a2.31,2.31,0,0,1-2.31,2.31Z"
                    fill="#707171"
                />
                <path
                    id="Path_248"
                    d="M307.18,62.12a29.8,29.8,0,0,1,4.46,3.48c.69.64,1.36,1.3,2,2a17.483,17.483,0,0,1,1.78,2.22l-.44.47a19.31,19.31,0,0,1-2.33-1.62c-.74-.59-1.44-1.21-2.13-1.85a30.292,30.292,0,0,1-3.77-4.21Z"
                    fill="#e3e2e3"
                />
                <path
                    id="Path_249"
                    d="M351.41,62.6a29.662,29.662,0,0,1-3.77,4.21c-.68.64-1.39,1.26-2.12,1.85a19.432,19.432,0,0,1-2.34,1.62l-.44-.47a18.415,18.415,0,0,1,1.78-2.22c.63-.69,1.3-1.35,2-2A29.8,29.8,0,0,1,351,62.12Z"
                    fill="#e3e2e3"
                />
                <path
                    id="Path_250"
                    d="M345.45,72.66a9.9,9.9,0,0,1,2.56-1.9,12.51,12.51,0,0,1,1.45-.64,6.18,6.18,0,0,1,1.58-.36l.3.57a6.19,6.19,0,0,1-1.21,1.09,10.268,10.268,0,0,1-1.35.81,9.67,9.67,0,0,1-3,1Z"
                    fill="#e3e2e3"
                />
                <path
                    id="Path_251"
                    d="M312.4,73.24a9.52,9.52,0,0,1-3-1,10.379,10.379,0,0,1-1.36-.81,6.491,6.491,0,0,1-1.21-1.09l.3-.57a6.25,6.25,0,0,1,1.59.36,12.36,12.36,0,0,1,1.44.64,9.74,9.74,0,0,1,2.56,1.9Z"
                    fill="#e3e2e3"
                />
                <path
                    id="Path_252"
                    d="M246.71,183.19a11.871,11.871,0,0,0,2.47,1.2,5.21,5.21,0,0,0,2.36.25,6.09,6.09,0,0,0,2.27-.81,20.58,20.58,0,0,0,2.24-1.63,5.36,5.36,0,0,1-1.43,2.65,5,5,0,0,1-2.87,1.44,4.76,4.76,0,0,1-5-3.1Z"
                    fill="#707171"
                />
                <path
                    id="Path_253"
                    d="M275.85,236.7a52.551,52.551,0,0,1-15.18,4.51c-3.43-10.49-5-31.76-2.76-53.63Z"
                    fill="#e3e2e3"
                />
                <path
                    id="Path_254"
                    d="M247.23,241.9a36.17,36.17,0,0,1-14.47-3.56l.31-4.2,12.57-48C249.38,206.14,249.3,230,247.23,241.9Z"
                    fill="#e3e2e3"
                />
                <path
                    id="Path_255"
                    d="M257,172.61c12.79-1.66,25.38-3.08,37.7-4.57a6.39,6.39,0,0,0,5-3.5l13.18-26.29,15.25,3.64L316.9,171.17a25.74,25.74,0,0,1-16.69,15.47l-21.51,6.42s-1.7,17.06,10.8,51.41c-.44,2.68-11.15,6.1-21.86,5.65C262.58,245.51,260.72,235.59,257,172.61Z"
                    fill="#707171"
                />
                <path
                    id="Path_256"
                    d="M243.54,174.07a159.629,159.629,0,0,0-26.44,2.34,21.6,21.6,0,0,0-12.64,7c-6.1,6.91-13.35,17.7-17.6,24.29a8.37,8.37,0,0,0,1.1,10.47l18.8,20.21,11.55-12.32-13.68-14.81,15.24-18.36s2.67-1.2,4.61.28c.61,1.93,1,24.82-7.36,58,3.43,2.67,20.84,4.22,24.69.64C242.7,250.48,251.1,209,243.54,174.07Z"
                    fill="#707171"
                />
                <path
                    id="Path_257"
                    d="M246.25,152.52c.07.75-.28,1.39-.78,1.43s-1-.51-1-1.25.28-1.39.77-1.43S246.18,151.78,246.25,152.52Z"
                    fill="#707171"
                />
                <path
                    id="Path_258"
                    d="M246.63,150.3a3.58,3.58,0,0,0-2.43-.2,4.05,4.05,0,0,0-2.07,1.35l-.29-.16a3,3,0,0,1,.79-1.27,3.13,3.13,0,0,1,1.38-.73,3.44,3.44,0,0,1,1.53,0,2.28,2.28,0,0,1,1.32.78Z"
                    fill="#707171"
                />
                <path
                    id="Path_259"
                    d="M252.67,151.57c.07.74.53,1.3,1,1.25s.85-.68.78-1.43-.54-1.3-1-1.25S252.6,150.83,252.67,151.57Z"
                    fill="#707171"
                />
                <path
                    id="Path_260"
                    d="M251.22,149.31a2.69,2.69,0,0,1,1.06-1.08,3.34,3.34,0,0,1,1.45-.49,2.66,2.66,0,0,1,1.54.3,2.48,2.48,0,0,1,1.07,1.08l-.25.2a3.21,3.21,0,0,0-2.29-.76,4.34,4.34,0,0,0-2.31.94Z"
                    fill="#707171"
                />
                <path
                    id="Path_261"
                    d="M247.17,159.86l7-1.19h0C254.05,162.05,249.43,164.06,247.17,159.86Z"
                    fill="#707171"
                    opacity="0.3"
                />
                <path
                    id="Path_262"
                    d="M261.78,148.52a.727.727,0,0,1-.09.08c-5.8,4.31-10-3.79-13-4.53s-7,2.13-9.56.34c0,0-3.35,7.39-.5,14.4l-5.07-7.42s-.61-8.26-.78-8.26c0-.13,5.24-4.18,5.24-4.18l10.32-3.85,10.54,1.78,1.67,6Z"
                    fill="#707171"
                />
                <path
                    id="Path_263"
                    d="M238,152.91c-2.82,0-4.58.49-4.31,3.48.22,2.45,2.9,6.83,6.62,3.61Z"
                    fill="#e3e2e3"
                />
                <path
                    id="Path_264"
                    d="M252.16,296.87c.42-2.54.86-5.06,1.33-7.58s1-5,1.45-7.56l1.56-7.53c.55-2.51,1.1-5,1.71-7.5l.32.06c-.4,2.54-.86,5.06-1.32,7.58l-1.46,7.55c-.53,2.51-1,5-1.57,7.53s-1.11,5-1.7,7.51Z"
                    fill="#fff"
                    opacity="0.3"
                />
                <path
                    id="Path_265"
                    d="M237.66,156.76a3.781,3.781,0,0,0-.37-1.12,1.14,1.14,0,0,0-.78-.59,1,1,0,0,0-.92.27,2.29,2.29,0,0,0-.63.94h-.24a2,2,0,0,1,.49-1.3,1.57,1.57,0,0,1,1.42-.48,1.64,1.64,0,0,1,1.13,1,2.66,2.66,0,0,1,.14,1.36Z"
                    fill="#707171"
                    opacity="0.3"
                />
                <path
                    id="Path_266"
                    d="M249.73,151.37a6,6,0,0,0,.48,1.95,1.71,1.71,0,0,0,.63.65,6.05,6.05,0,0,1,.92.59,1.67,1.67,0,0,1,.51,1.15,1.48,1.48,0,0,1-.5,1.15,1.88,1.88,0,0,1-2.22.13l.11-.22a1.65,1.65,0,0,0,1.73-.3,1,1,0,0,0-.06-1.46,5.812,5.812,0,0,0-.84-.56,2.06,2.06,0,0,1-.75-.9,4.68,4.68,0,0,1-.25-2.17Z"
                    fill="#707171"
                    opacity="0.3"
                />
                <path
                    id="Path_267"
                    d="M259.58,158.06a2.93,2.93,0,1,1-.93-5.71,2.68,2.68,0,0,1,.93.16A34.248,34.248,0,0,1,259.58,158.06Z"
                    fill="#707171"
                    opacity="0.3"
                />
                <circle
                    id="Ellipse_12"
                    cx="2.93"
                    cy="2.93"
                    r="2.93"
                    transform="translate(239.823 155.29) rotate(-0.26)"
                    fill="#707171"
                    opacity="0.3"
                />
                <circle
                    id="Ellipse_13"
                    cx="12.03"
                    cy="12.03"
                    r="12.03"
                    transform="translate(426.74 314.34)"
                    fill="#fff"
                    stroke="#e2e2e2"
                    strokeMiterlimit="10"
                    strokeWidth="2"
                />
                <circle
                    id="Ellipse_14"
                    cx="8.54"
                    cy="8.54"
                    r="8.54"
                    transform="translate(323.14 360.01)"
                    fill="#fff"
                    stroke="#e2e2e2"
                    strokeMiterlimit="10"
                    strokeWidth="2"
                />
                <circle
                    id="Ellipse_15"
                    cx="8.54"
                    cy="8.54"
                    r="8.54"
                    transform="translate(388.22 84.16)"
                    fill="#fff"
                    stroke="#e2e2e2"
                    strokeMiterlimit="10"
                    strokeWidth="2"
                />
                <path
                    id="Path_268"
                    d="M115,99.8h-8.58V91.23a1.07,1.07,0,0,0-1.07-1.07h0a1.1,1.1,0,0,0-1.09,1.07V99.8H95.69a1.09,1.09,0,0,0-1.07,1.09A1.07,1.07,0,0,0,95.69,102h8.56v8.58a1.1,1.1,0,0,0,1.09,1.07,1.07,1.07,0,0,0,1.07-1.07h0V102H115a1.08,1.08,0,0,0,0-2.16Z"
                    fill="#e2e2e2"
                    stroke="#e2e2e2"
                    strokeMiterlimit="10"
                    strokeWidth="0.75"
                />
                <path
                    id="Path_269"
                    d="M110.87,366.23h-7.61v-7.6a.94.94,0,0,0-.94-.94h0a1,1,0,0,0-1,.94v7.6H93.76a.97.97,0,1,0,0,1.94h7.59v7.61a.97.97,0,0,0,1.94,0h0v-7.61h7.61a.97.97,0,1,0-.03-1.94Z"
                    fill="#e2e2e2"
                    stroke="#e2e2e2"
                    strokeMiterlimit="10"
                    strokeWidth="0.75"
                />
                <path
                    id="Path_270"
                    d="M311.12,411.7h-7.61v-7.6a.94.94,0,0,0-.94-.94h0a1,1,0,0,0-1,.94v7.6H294a.975.975,0,0,0,0,1.95h7.59v7.6a1,1,0,0,0,1,.95.94.94,0,0,0,.94-.95h0v-7.6h7.61a1,1,0,1,0,0-1.92Z"
                    fill="#e2e2e2"
                    stroke="#e2e2e2"
                    strokeMiterlimit="10"
                    strokeWidth="0.75"
                />
                <path
                    id="Path_271"
                    d="M197.18,417.55h-9.86v-9.84a1.22,1.22,0,0,0-1.22-1.23h0a1.25,1.25,0,0,0-1.25,1.23v9.84H175a1.26,1.26,0,0,0-1.24,1.26A1.23,1.23,0,0,0,175,420h9.83v9.86a1.25,1.25,0,0,0,1.25,1.23,1.22,1.22,0,0,0,1.22-1.23h0V420h9.86a1.24,1.24,0,0,0,1.24-1.22,1.26,1.26,0,0,0-1.22-1.23Z"
                    fill="#e2e2e2"
                    stroke="#e2e2e2"
                    strokeMiterlimit="10"
                    strokeWidth="0.75"
                />
                <ellipse
                    id="_Path_"
                    data-name="&lt;Path&gt;"
                    cx="154.87"
                    cy="15.21"
                    rx="154.87"
                    ry="15.21"
                    transform="translate(102.32 474.73)"
                    fill="#f4f5f6"
                />
                <circle
                    id="Ellipse_16"
                    cx="13.27"
                    cy="13.27"
                    r="13.27"
                    transform="translate(172.62 50.76)"
                    fill="#f4f5f6"
                />
                <circle
                    id="Ellipse_17"
                    cx="8.54"
                    cy="8.54"
                    r="8.54"
                    transform="translate(192.24 46.95)"
                    fill="#e2e2e2"
                    stroke="#e2e2e2"
                    strokeMiterlimit="10"
                    strokeWidth="0.75"
                />
                <circle
                    id="Ellipse_18"
                    cx="13.27"
                    cy="13.27"
                    r="13.27"
                    transform="translate(235.43 419.75)"
                    fill="#f4f5f6"
                />
                <circle
                    id="Ellipse_19"
                    cx="8.54"
                    cy="8.54"
                    r="8.54"
                    transform="translate(255.05 415.94)"
                    fill="#e2e2e2"
                    stroke="#e2e2e2"
                    strokeMiterlimit="10"
                    strokeWidth="0.75"
                />
            </g>
        </SVGIcon>
    );
}
