import React, { useReducer } from 'react';

import dealsActions from '../Actions/dealsActions';
import DealsContext from '../Context/dealsContext';
import dealsReducer from '../Reducer/dealsReducer';
import dealsStore from '../Store/dealsStore';

const DealsContextProvider = props => {
    const [state, dispatch] = useReducer(dealsReducer, dealsStore);

    const value = {
        initialLoad: state.initialLoad,
        pipelines: state.pipelines,
        pipelinesLoaded: state.pipelinesLoaded,
        pipelineUpdateStatus: state.pipelineUpdateStatus,
        pipelinePagination: state.pipelinePagination,
        pipelinesReset: state.pipelinesReset,
        pipelineTableData: state.pipelineTableData,
        pipelineTablePagination: state.pipelineTablePagination,

        initialTableLoad: state.initialTableLoad,
        columns: state.columns,
        cardConfig: state.cardConfig,

        peopleColumns: state.peopleColumns,
        businessColumns: state.businessColumns,
        peopleVisibleFields: state.peopleVisibleFields,
        businessVisibleFields: state.businessVisibleFields,

        tableCurrPage: state.tableCurrPage,
        tableLimit: state.tableLimit,

        activePipelineData: state.activePipelineData,
        filter: state.filter,

        order: state.order,
        field: state.field,
        search: state.search,

        notes: state.notes,
        filterNotes: state.filterNotes,

        conversations: state.conversations,
        filterConversations: state.filterConversations,

        activities: state.activities,
        reminders: state.reminders,

        dealsData: state.dealsData,
        boardPagination: state.boardPagination,

        logs: state.logs,
        filterLogs: state.filterLogs,
        listViewData: state.listViewData,
        listViewPagination: state.listViewPagination,
        listViewLoader: state.listViewLoader,
        selectedDealLogData: state.selectedDealLogData,

        setInitialLoad: value => {
            dispatch({ type: dealsActions.SET_INITIAL_LOAD, value });
        },
        setPipelineData: (value, status) => {
            dispatch({ type: dealsActions.SET_PIPELINE_DATA, value, status });
        },
        setPipelineDataStatus: () => {
            dispatch({ type: dealsActions.SET_PIPELINE_DATA_STATUS });
        },
        setPipelinesReset: value => {
            dispatch({ type: dealsActions.SET_RESET_PIPELINES, value });
        },
        setPipelinePagination: value => {
            dispatch({ type: dealsActions.SET_PIPELINE_PAGINATION, value });
        },
        setPipelinesLoaded: value => {
            dispatch({ type: dealsActions.SET_PIPELINES_LOADED, value });
        },
        clearPipelinesData: () => {
            dispatch({ type: dealsActions.CLEAR_PIPELINES_DATA });
        },

        setTablePipelineData: value => {
            dispatch({ type: dealsActions.SET_TABLE_PIPELINE_DATA, value });
        },
        setTablePaginationData: value => {
            dispatch({
                type: dealsActions.SET_TABLE_PIPELINE_PAGINATION,
                value,
            });
        },
        setTableInitialLoadData: value => {
            dispatch({
                type: dealsActions.SET_TABLE_INITIAL_LOAD,
                value,
            });
        },
        setColumns: value => {
            dispatch({ type: dealsActions.SET_COLUMNS, value });
        },

        setBoardPagination: value => {
            dispatch({ type: dealsActions.SET_BOARD_PAGINATION, value });
        },
        setListViewData: value => {
            dispatch({ type: dealsActions.SET_LISTVIEW_DATA, value });
        },
        setListViewPagination: value => {
            dispatch({ type: dealsActions.SET_LISTVIEW_PAGINATION, value });
        },
        setListViewLoader: value => {
            dispatch({ type: dealsActions.SET_LISTVIEW_LOADING, value });
        },
        clearListViewData: value => {
            dispatch({ type: dealsActions.CLEAR_LISTVIEW_DATA, value });
        },
        clearListViewPagination: value => {
            dispatch({ type: dealsActions.CLEAR_LISTVIEW_PAGINATION, value });
        },
        setCardConfig: value => {
            dispatch({ type: dealsActions.SET_CARD_CONFIG, value });
        },
        setPeopleColumns: value => {
            dispatch({ type: dealsActions.SET_PEOPLE_COLUMNS, value });
        },
        setBusinessColumns: value => {
            dispatch({ type: dealsActions.SET_BUSINESSES_COLUMNS, value });
        },
        setPeopleVisibleFields: value => {
            dispatch({ type: dealsActions.SET_PEOPLE_VISIBLE_FIELDS, value });
        },
        setBusinessVisibleFields: value => {
            dispatch({ type: dealsActions.SET_BUSINESS_VISIBLE_FIELDS, value });
        },
        setActivePipelineData: value => {
            dispatch({ type: dealsActions.SET_ACTIVE_PIPELINE_DATA, value });
        },
        clearDealsData: value => {
            dispatch({ type: dealsActions.CLEAR_DEALS_DATA, value });
        },
        clearBoardPagination: value => {
            dispatch({ type: dealsActions.CLEAR_BOARD_PAGINATION, value });
        },
        setTableCurrPage: value => {
            dispatch({ type: dealsActions.SET_CURR_TABLE_PAGE, value });
        },
        setTableLimit: value => {
            dispatch({ type: dealsActions.SET_TABLE_LIMIT, value });
        },

        setOrder: value => {
            dispatch({ type: dealsActions.SET_ORDER, value });
        },
        setField: value => {
            dispatch({ type: dealsActions.SET_FIELD, value });
        },
        setSearch: value => {
            dispatch({ type: dealsActions.SET_SEARCH, value });
        },

        setNotes: value => {
            dispatch({ type: dealsActions.SET_NOTES, value });
        },
        setFilterNotes: value => {
            dispatch({ type: dealsActions.SET_FILTER_NOTES, value });
        },
        clearNotes: () => {
            dispatch({ type: dealsActions.CLEAR_NOTES });
        },

        addConversations: value => {
            dispatch({ type: dealsActions.ADD_CONVERSATIONS, value });
        },
        addFilterConversations: value => {
            dispatch({ type: dealsActions.ADD_FILTER_CONVERSATIONS, value });
        },

        setDealsData: value => {
            dispatch({ type: dealsActions.SET_DEALS_DATA, value });
        },
        clearConversations: () => {
            dispatch({ type: dealsActions.CLEAR_CONVERSATIONS });
        },
        /**
         *
         * @param {Object} value - { data: array of activities, nextPage: next_page from pagination }
         */
        setActivities: value => {
            dispatch({ type: dealsActions.SET_ACTIVITIES, value });
        },
        /**
         *
         * @param {Object} value - Activity object
         */
        addActivity: value => {
            dispatch({ type: dealsActions.ADD_ACTIVITY, value });
        },
        /**
         *
         * @param {Object} value - updated activity object
         */
        updateActivity: value => {
            dispatch({
                type: dealsActions.UPDATE_ACTIVITY,
                value,
            });
        },
        /**
         *
         * @param {ObjectID} value - id of activity to be deleted
         */
        deleteActivity: value => {
            dispatch({ type: dealsActions.DELETE_ACTIVITY, value });
        },
        /**
         * clears all activities
         */
        clearActivities: () => {
            dispatch({ type: dealsActions.CLEAR_ACTIVITIES });
        },
        /**
         *
         * @param {Object} value - { data: array of reminders, nextPage: next_page from pagination }
         */
        setReminders: value => {
            dispatch({ type: dealsActions.SET_REMINDERS, value });
        },
        /**
         *
         * @param {Object} value - Reminder object
         */
        addReminder: value => {
            dispatch({ type: dealsActions.ADD_REMINDER, value });
        },
        /**
         *
         * @param {Object} value - updated reminder object
         */
        updateReminder: value => {
            dispatch({ type: dealsActions.UPDATE_REMINDER, value });
        },
        /**
         *
         * @param {ObjectID} value - id of reminder to be deleted
         */
        deleteReminder: value => {
            dispatch({ type: dealsActions.DELETE_REMINDER, value });
        },
        /**
         * clears all activities
         */
        clearReminders: () => {
            dispatch({ type: dealsActions.CLEAR_REMINDERS });
        },
        setFilter: value => {
            dispatch({
                type: dealsActions.SET_FILTER,
                value,
            });
        },
        addLogs: value => {
            dispatch({ type: dealsActions.ADD_LOGS, value });
        },
        addFilterLogs: value => {
            dispatch({ type: dealsActions.ADD_FILTER_LOGS, value });
        },
        clearLogs: () => {
            dispatch({ type: dealsActions.CLEAR_LOGS });
        },
        setSelectedDealLogData: value => {
            dispatch({ type: dealsActions.SET_SELECTED_DEAL_LOG_DATA, value });
        },
    };

    return <DealsContext.Provider value={value}>{props.children}</DealsContext.Provider>;
};

export default DealsContextProvider;
