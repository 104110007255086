import React from 'react';

import { SVGIcon } from '../../UIKit/index';

export default function DCSwitchOn(props) {
    return (
        <SVGIcon {...props} viewBox="0 0 512 512">
            <path
                fill="#90A4AE"
                d="M256,181.333H74.667C33.429,181.333,0,214.763,0,256s33.429,74.667,74.667,74.667H256  c5.891,0,10.667-4.776,10.667-10.667V192C266.667,186.109,261.891,181.333,256,181.333z"
            />
            <circle fill="#CFD8DC" cx="373.333" cy="256" r="138.667" />
            <circle fill="#4CAF50" cx="373.333" cy="256" r="74.667" />
        </SVGIcon>
    );
}
