import React from 'react';

import { SVGIcon } from '../../UIKit/index';

export default function DCEmailOpen2(props) {
    return (
        <SVGIcon {...props} viewBox="0 0 512 512">
            <g transform="translate(-0.003 0)">
                <path
                    fill="#D9EAFF"
                    d="M505.24,183.69L264.66,3.01C259.34-1,252-1,246.68,3.01L6.13,183.69
		c-1.86,1.39-3.37,3.2-4.42,5.28l28.84,51.62l185.86,161.43h78.54l185.87-161.43l28.83-51.63
		C508.61,186.88,507.1,185.07,505.24,183.69L505.24,183.69z"
                />
                <path
                    fill="#D9EAFF"
                    d="M480.83,240.59l28.84-51.62c-1.04-2.08-2.55-3.89-4.42-5.28L264.66,3.01
		c-2.6-1.96-5.76-3.02-9.01-3.01v402.02h39.28L480.83,240.59z"
                />
                <path
                    fill="#FFFFFF"
                    d="M405.43,60.91H105.95c-8.26-0.03-14.98,6.65-15.01,14.91c0,0.02,0,0.04,0,0.06v344.4h329.42
		V75.88C420.37,67.62,413.68,60.92,405.43,60.91z"
                />
                <path
                    fill="#FFFFFF"
                    d="M420.4,75.88c0.01-8.26-6.69-14.97-14.95-14.97c-0.01,0-0.01,0-0.02,0H255.65v359.37h164.71
		L420.4,75.88z"
                />
                <path
                    fill="#005EFF"
                    d="M509.65,188.97L265.01,372.1c-5.46,4.35-13.2,4.35-18.65,0L1.72,188.97
		c-1.04,2.08-1.58,4.37-1.58,6.69v300.47c-0.01,8.26,6.69,14.97,14.95,14.97c0.01,0,0.01,0,0.02,0h481.16
		c8.26,0.01,14.97-6.69,14.97-14.95c0-0.01,0-0.01,0-0.02V195.66C511.23,193.34,510.69,191.05,509.65,188.97z"
                />
                <path
                    fill="#0372F8"
                    d="M511.24,496.14V195.66c0-2.32-0.54-4.61-1.58-6.69L265.02,372.1c-2.65,2.11-5.94,3.26-9.32,3.26
		v135.76h240.58C504.54,511.12,511.24,504.42,511.24,496.14C511.24,496.15,511.24,496.15,511.24,496.14z"
                />
                <g transform="translate(18.745 15.636)">
                    <path
                        fill="#6B8AAA"
                        d="M311.81,150.08c0.08,8.19-6.49,14.89-14.68,14.97c-0.1,0-0.19,0-0.29,0H177.05
			c-8.27,0-14.97-6.7-14.97-14.97c0-8.27,6.7-14.97,14.97-14.97l0,0h119.79c8.19-0.08,14.89,6.49,14.97,14.68
			C311.81,149.89,311.81,149.99,311.81,150.08z"
                    />
                </g>
                <g transform="translate(18.745 21.849)">
                    <path
                        fill="#6B8AAA"
                        d="M311.81,203.77c0.08,8.19-6.49,14.89-14.68,14.97c-0.1,0-0.19,0-0.29,0H177.05
			c-8.27,0-14.97-6.7-14.97-14.97c0-8.27,6.7-14.97,14.97-14.97l0,0h119.79c8.19-0.08,14.89,6.49,14.97,14.68
			C311.81,203.57,311.81,203.67,311.81,203.77z"
                    />
                </g>
                <g transform="translate(26.511 15.636)">
                    <path
                        fill="#6B8AAA"
                        d="M304.05,209.98c0.08,8.19-6.49,14.89-14.68,14.97c-0.1,0-0.19,0-0.29,0h-59.94v-29.94h59.9
			c8.19-0.1,14.91,6.45,15.01,14.64C304.05,209.76,304.05,209.87,304.05,209.98z"
                    />
                    <path
                        fill="#6B8AAA"
                        d="M289.08,165.06h-59.94v-29.94h59.9c8.27,0,14.97,6.7,14.97,14.97c0,8.27-6.7,14.97-14.97,14.97
			H289.08z"
                    />
                </g>
            </g>
        </SVGIcon>
    );
}
