import React from 'react';

import { SVGIcon } from '../../UIKit/index';

export default function DCDealCase(props) {
    return (
        <SVGIcon {...props} viewBox="0 0 512 480">
            <g transform="translate(-1036.443 -445)">
                <g transform="translate(1037.943 445)">
                    <path
                        fill="#2B88F8"
                        d="M124.62,67.29V49.32c1.23-24.93,21.01-44.95,45.93-46.48h102.51c0,0,41.14,4.98,43.97,45.82    v27.2l-27.06,2V50.44c0,0-0.59-18.4-14.48-20.12H167.11c-7.96,3.44-13.68,10.62-15.25,19.15v29.69h-27.24L124.62,67.29z"
                    />
                    <path
                        fill="#F3F9FF"
                        d="M116.64,141.15h352.96c21.48,0,38.89,17.41,38.89,38.89v261.24    c0,21.48-17.41,38.89-38.89,38.89H116.64c-21.48,0-38.89-17.41-38.89-38.89V180.04C77.75,158.56,95.16,141.15,116.64,141.15z"
                    />
                    <path
                        fill="#FFFFFF"
                        stroke="#2B88F8"
                        strokeWidth="20"
                        strokeMiterlimit="10"
                        d="M44.17,82.93h365.14c18.12,0,32.8,14.69,32.8,32.8v273.41c0,18.12-14.69,32.8-32.8,32.8    H44.17c-18.12,0-32.8-14.69-32.8-32.8V115.73C11.37,97.62,26.06,82.93,44.17,82.93z"
                    />
                    <path
                        fill="#D9EAFF"
                        d="M105.79,122.71h241.03c31.71,0,57.41,25.7,57.41,57.41l0,0v116.45l0,0H48.38l0,0V180.12    C48.38,148.42,74.08,122.71,105.79,122.71z"
                    />
                    <rect fill="#D9EAFF" x="26.21" y="323.04" width="401.39" height="31.48" />
                    <g transform="translate(22.564 22.949)">
                        <path
                            fill="#FFFFFF"
                            d="M189.99,168.1h32.25c8.91,0,16.14,7.23,16.14,16.14l0,0c0,8.91-7.23,16.14-16.14,16.14     h-32.25c-8.91,0-16.14-7.23-16.14-16.14l0,0C173.85,175.32,181.08,168.1,189.99,168.1z"
                        />
                    </g>
                    <circle fill="#D9EAFF" cx="475.43" cy="435.59" r="21.39" />
                </g>
            </g>
        </SVGIcon>
    );
}
