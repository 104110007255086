import React from 'react';

import { SVGIcon } from '../../UIKit/index';

export default function DCCopyLink(props) {
    return (
        <SVGIcon {...props} viewBox="0 0 473 512">
            <g>
                <path d="M80.7,401.1c-22-6.1-45.2-29.4-45.2-48.6V80.4c0-22.2,30-50.1,54.7-50.1h213.9c24.2,0,53.5,26.8,54.6,48.8h24.5   c3.5,0,7,0.2,10.5,0.6C393.3,35.7,353.3,0,304.1,0H90.2C40.8,0,0.6,36,0.6,80.3v272.2c0,41.1,35.6,76.1,80.1,80.7   c0-0.2,1-1.4,1-1.6L80.7,401.1z" />
                <path d="M393.6,111.7c13,3.7,32.9,2.9,44.3,47.8v272.1c0,22.2-30,50.1-54.7,50.1H169.3c-21.7,0-47.6-21.6-53.5-42   c-1.2-4.1-4.8-6.9-9.1-6.9H90.2c0,0-0.1,0-0.1,0c-5.8,0-10.3,0.1-9.4,5.8c6.5,38.6,43.7,73.3,88.6,73.3h213.9   c49.4,0,89.6-36,89.6-80.3V159.4c0-41.1-34.6-75.1-79.1-79.7c0,0.2,0,0.4,0,0.6" />
                <path d="M393.7,79.1H169.3c-49.4,0-89.6,36-89.6,80.3l1,277.7c0,0,6.1,0.5,9.5,0.5l24.4-1V159.5c0-22.2,30-50.1,54.7-50.1l225.5,2" />
            </g>
            <g transform="translate(431 585)">
                <g transform="translate(7.822 0)">
                    <g transform="translate(0 0)">
                        <path d="M-65.9-391.2c-21-21-55.2-21-76.2,0l-45,45c-20.8,21.2-20.8,55.1,0,76.2c3.2,3.2,6.9,6,10.9,8.2l8.2-8.2     c3.6-4.6,4.9-10.6,3.4-16.2c-1.2-0.8-2.3-1.8-3.4-2.8c-10.4-10.6-10.4-27.5,0-38.1l45-45c10.4-10.6,27.5-10.8,38.1-0.3     c10.6,10.4,10.8,27.5,0.3,38.1c-0.1,0.1-0.2,0.2-0.3,0.3l-29.8,29.8c3.7,11.1,4.8,23,3.3,34.6c0.1-0.1,0.3-0.2,0.4-0.3l45.1-45.1     C-44.9-336.1-44.9-370.2-65.9-391.2z" />
                    </g>
                </g>
                <g transform="translate(0 8.213)">
                    <g transform="translate(0)">
                        <path d="M-131.7-325.8c-3.2-3.2-6.9-6-10.9-8.2l-8.2,8.2c-3.6,4.6-4.9,10.6-3.4,16.2c1.2,0.8,2.3,1.8,3.4,2.8     c10.4,10.6,10.4,27.5,0,38.1l-48.5,48.5c-10.6,10.4-27.7,10.3-38.1-0.3c-10.3-10.5-10.3-27.3,0-37.8l33.3-33.3     c-3.7-11.1-4.9-23-3.4-34.7c-0.1,0.1-0.3,0.2-0.4,0.3l-48.5,48.5c-20.9,21.2-20.6,55.4,0.7,76.2c21,20.6,54.6,20.6,75.6,0     l48.5-48.5C-110.9-270.8-110.9-304.7-131.7-325.8z" />
                    </g>
                </g>
            </g>
        </SVGIcon>
    );
}
