import React from 'react';

import { SVGIcon } from '../../UIKit/index';

export default function DCAutoCreateContacts(props) {
    return (
        <SVGIcon {...props} viewBox="0 0 456.501 499.735">
            <g id="Auto_Create_Contacts" transform="translate(-28.25 -8.155)">
                <circle
                    id="Ellipse_1"
                    data-name="Ellipse 1"
                    cx="9.28"
                    cy="9.28"
                    r="9.28"
                    transform="translate(31.44 421.85)"
                    fill="#fff"
                    stroke="#e1e1e1"
                    strokeMiterlimit="10"
                    strokeWidth="2"
                />
                <circle
                    id="Ellipse_2"
                    data-name="Ellipse 2"
                    cx="14.5"
                    cy="14.5"
                    r="14.5"
                    transform="translate(66.62 339.89)"
                    fill="#fff"
                    stroke="#e1e1e1"
                    strokeMiterlimit="10"
                    strokeWidth="2"
                />
                <circle
                    id="Ellipse_3"
                    data-name="Ellipse 3"
                    cx="11.96"
                    cy="11.96"
                    r="11.96"
                    transform="translate(449.83 133.33)"
                    fill="#fff"
                    stroke="#e1e1e1"
                    strokeMiterlimit="10"
                    strokeWidth="2"
                />
                <path
                    id="Path_1"
                    data-name="Path 1"
                    d="M461.14,399.24H453v-8.19a1,1,0,0,0-1-1h0a1,1,0,0,0-1,1v8.19h-8.17a1.03,1.03,0,0,0,0,2.06H451v8.19a1,1,0,0,0,2,0h0V401.3h8.19a1.03,1.03,0,1,0,0-2.06Z"
                    fill="#e1e1e1"
                    stroke="#e1e1e1"
                    strokeMiterlimit="10"
                    strokeWidth="0.75"
                />
                <path
                    id="Path_2"
                    data-name="Path 2"
                    d="M64.55,97.16H53.86V86.49a1.34,1.34,0,0,0-1.33-1.33h0a1.36,1.36,0,0,0-1.36,1.33V97.16H40.52a1.35,1.35,0,1,0,0,2.69H51.17v10.68a1.36,1.36,0,0,0,1.36,1.33,1.33,1.33,0,0,0,1.33-1.33h0V99.85H64.55a1.35,1.35,0,0,0,0-2.69Z"
                    fill="#e1e1e1"
                    stroke="#e1e1e1"
                    strokeMiterlimit="10"
                    strokeWidth="0.75"
                />
                <path
                    id="Path_3"
                    data-name="Path 3"
                    d="M170.43,16.41h-7.05v-7a.88.88,0,0,0-.88-.88h0a.9.9,0,0,0-.9.88v7h-7a.89.89,0,0,0,0,1.77h7v7.05a.9.9,0,0,0,.9.88.88.88,0,0,0,.88-.88h0v-7h7.05a.89.89,0,0,0,0-1.77Z"
                    fill="#e1e1e1"
                    stroke="#e1e1e1"
                    strokeMiterlimit="10"
                    strokeWidth="0.75"
                />
                <ellipse
                    id="_Path_"
                    data-name="&lt;Path&gt;"
                    cx="217.66"
                    cy="34.81"
                    rx="217.66"
                    ry="34.81"
                    transform="translate(44.18 438.27)"
                    fill="#f3f4f5"
                />
                <path
                    id="Path_4"
                    data-name="Path 4"
                    d="M142.05,61.92a21.18,21.18,0,0,0-19.32,21c-.19,67-.51,298.19,3.86,380a16.44,16.44,0,0,0,16.25,15.57c42.93.47,166.42,1.32,236.53-3.17a27.69,27.69,0,0,0,25.95-27.66c-.09-70.34-1.21-268.16-10.85-358.58A22.58,22.58,0,0,0,373.75,69C326.92,65.36,202.58,56.66,142.05,61.92Z"
                    fill="#5a5b5b"
                />
                <path
                    id="Path_5"
                    data-name="Path 5"
                    d="M140,100.54c-.84,57.33-3.24,248,.95,318.43.61,10.28,8.6,18.41,18.47,18.75,40.63,1.42,144.81,4.16,204.18-2.38,10.69-1.17,18.86-10.44,19.14-21.66,1.49-58.5,5.5-236.13,1.43-305.73-.65-11.1-9.13-20-19.77-20.68-44-3-157-9.8-207.44-5.59C147.45,82.47,140.11,90.61,140,100.54Z"
                    fill="#f3f4f5"
                />
                <path
                    id="Path_6"
                    data-name="Path 6"
                    d="M238.12,71.89A3,3,0,0,0,241,74.8c7.81.24,29.84.91,39.68,1.27a3.145,3.145,0,0,0,.23-6.27c-6.66-.47-26.75-1.71-40-1A3.1,3.1,0,0,0,238.12,71.89Z"
                    fill="#f3f4f5"
                />
                <circle
                    id="Ellipse_4"
                    data-name="Ellipse 4"
                    cx="58.73"
                    cy="58.73"
                    r="58.73"
                    transform="translate(156.08 103.88)"
                    fill="#e3e2e2"
                />
                <g id="Group_1" data-name="Group 1" clipPath="url(#clip-path)">
                    <circle
                        id="Ellipse_5"
                        data-name="Ellipse 5"
                        cx="22.92"
                        cy="22.92"
                        r="22.92"
                        transform="translate(186.476 164.557) rotate(-73.15)"
                        fill="#717272"
                    />
                    <path
                        id="Path_7"
                        data-name="Path 7"
                        d="M180.22,218.09h73c4.47,0,7.16-5.79,4.75-10.21-6.21-11.35-19.19-28-41.66-27.29-21.67.73-34.51,15.8-41,26.61C172.64,211.79,175.43,218.09,180.22,218.09Z"
                        fill="#717272"
                    />
                </g>
                <rect
                    id="Rectangle_1"
                    data-name="Rectangle 1"
                    width="71.37"
                    height="14.3"
                    rx="4.7"
                    transform="translate(294.396 127.822) rotate(0.87)"
                    fill="#e3e2e2"
                />
                <rect
                    id="Rectangle_2"
                    data-name="Rectangle 2"
                    width="14.3"
                    height="71.37"
                    rx="2.11"
                    transform="translate(293.708 171.419) rotate(-89.13)"
                    fill="#e3e2e2"
                />
                <rect
                    id="Rectangle_3"
                    data-name="Rectangle 3"
                    width="71.37"
                    height="14.3"
                    rx="4.7"
                    transform="translate(293.497 186.391) rotate(0.87)"
                    fill="#e3e2e2"
                />
                <path
                    id="Path_8"
                    data-name="Path 8"
                    d="M304.42,98.47a.65.65,0,0,1-.42-.55c-.06-.84-.11-1.7-.14-2.55a.63.63,0,0,1,.62-.64c.86,0,.63.84.8,3.11a.63.63,0,0,1-.59.67.59.59,0,0,1-.27-.04Z"
                    fill="#e3e2e2"
                />
                <path
                    id="Path_9"
                    data-name="Path 9"
                    d="M304.12,87.45c.15-1.61.38-3.24.66-4.83a.64.64,0,0,1,.75-.5c1,.18.33.72-.14,5.47a.62.62,0,0,1-.7.55.63.63,0,0,1-.57-.69Zm2.55-12.51c.49-1.55,1.05-3.11,1.65-4.59a.642.642,0,0,1,1.19.48c-.59,1.47-1.13,3-1.61,4.5a.63.63,0,0,1-.8.4A.64.64,0,0,1,306.67,74.94Zm5.08-11.71c.8-1.41,1.66-2.8,2.57-4.14a.64.64,0,0,1,1.07.7c-.89,1.32-1.73,2.68-2.51,4.06a.644.644,0,0,1-1.13-.62Zm7.5-9.52a.62.62,0,0,1-.07-.89c1.07-1.21,2.21-2.4,3.38-3.53.6-.58,1.51.33.91.91-1.15,1.1-2.27,2.27-3.32,3.46a.64.64,0,0,1-.9.05ZM328.52,45a.61.61,0,0,1,.11-.88c1.31-1,2.68-1.9,4.06-2.77a.64.64,0,0,1,.7,1.07c-1.36.85-2.69,1.76-4,2.71a.65.65,0,0,1-.87-.13Zm11.25-6.29a.7.7,0,0,1-.36-.32.62.62,0,0,1,.3-.84q2.22-1.035,4.55-1.89a.65.65,0,0,1,.83.37.62.62,0,0,1-.36.81q-2.265.84-4.46,1.85a.63.63,0,0,1-.5.06Zm12.1-4.16a.67.67,0,0,1-.4-.45.62.62,0,0,1,.47-.76c1.59-.37,3.23-.69,4.85-.93a.64.64,0,0,1,.74.52.61.61,0,0,1-.52.72c-1.59.25-3.19.56-4.76.92a.7.7,0,0,1-.38.02Zm12.33-2.22a.63.63,0,0,1,.62-.64c1.64-.05,3.31,0,5,.06a.63.63,0,1,1,0,1.26c-1.62-.08-3.26-.1-4.87-.06a.66.66,0,0,1-.75-.58Zm41.08,13.7c-3.84-2.94-4.7-2.89-4.17-3.68a.64.64,0,0,1,.88-.18c1.39.9,2.76,1.86,4.07,2.87a.646.646,0,1,1-.78,1.03Zm-28.2-12.86a.62.62,0,0,1,.73-.51c1.63.29,3.26.65,4.87,1.06a.63.63,0,1,1-.31,1.22c-1.57-.4-3.17-.75-4.77-1a.63.63,0,0,1-.52-.73Zm17.31,6.16c-4.34-2.1-5.19-1.88-4.84-2.77a.66.66,0,0,1,.83-.35c1.54.61,3.08,1.28,4.56,2a.63.63,0,1,1-.55,1.13Z"
                    fill="#e3e2e2"
                />
                <path
                    id="Path_10"
                    data-name="Path 10"
                    d="M413.05,53.09c-1.47-1.57-2.38-2.1-1.77-2.7a.63.63,0,0,1,.9,0c1.59,1.57,2.39,2.19,1.78,2.75a.74.74,0,0,1-.91-.05Z"
                    fill="#e3e2e2"
                />
                <path
                    id="Path_11"
                    data-name="Path 11"
                    d="M430.2,99a.63.63,0,0,1-.41-.63c0-.83.07-1.66.08-2.49a.62.62,0,0,1,.65-.61.65.65,0,0,1,.63.64c0,.85,0,1.7-.08,2.55a.62.62,0,0,1-.68.58.43.43,0,0,1-.19-.04Z"
                    fill="#e3e2e2"
                />
                <path
                    id="Path_12"
                    data-name="Path 12"
                    d="M343.72,152c-1.65-.68-3.29-1.43-4.87-2.24a.63.63,0,0,1-.29-.85c.45-.87.81,0,5.64,1.92a.65.65,0,0,1,.36.83A.67.67,0,0,1,343.72,152Zm7.8,2c.24-1,.8-.23,5.91.67a.65.65,0,0,1,.53.74.64.64,0,0,1-.74.51c-1.75-.31-3.51-.7-5.23-1.15a.64.64,0,0,1-.47-.77Zm-20.15-8.69c-1.46-1-2.9-2.09-4.27-3.22a.62.62,0,0,1-.09-.89c.63-.75.8.12,5.09,3.09a.63.63,0,0,1,.16.88.65.65,0,0,1-.89.1Zm34.29,11.5a.63.63,0,0,1,.24-1.22c1.74.07,3.5.07,5.24,0a.65.65,0,0,1,.68.6.63.63,0,0,1-.61.66c-1.77.07-3.57.07-5.34,0a.49.49,0,0,1-.21-.08Zm13.82-1a.63.63,0,0,1-.41-.47.62.62,0,0,1,.5-.74c1.71-.3,3.42-.69,5.09-1.14a.66.66,0,0,1,.8.45.62.62,0,0,1-.44.77c-1.71.46-3.46.85-5.2,1.16a.64.64,0,0,1-.34-.02Zm13.22-3.91a.63.63,0,0,1-.36-.34.62.62,0,0,1,.32-.83c1.59-.67,3.18-1.41,4.7-2.2a.64.64,0,0,1,.87.26.61.61,0,0,1-.25.85c-1.56.81-3.17,1.57-4.8,2.25a.62.62,0,0,1-.48.02Zm12-6.66a.65.65,0,0,1-.29-.23.61.61,0,0,1,.13-.87c1.4-1,2.78-2.06,4.09-3.17a.64.64,0,0,1,.9.07.62.62,0,0,1-.06.89c-1.33,1.13-2.74,2.22-4.17,3.23a.65.65,0,0,1-.6.09Zm10-9.22c-.75-.66.13-.79,3.2-4.87a.64.64,0,0,1,1,.75,47.335,47.335,0,0,1-3.33,4.07.64.64,0,0,1-.87.03Zm8-11c-.88-.47-.05-.79,2-5.46a.65.65,0,0,1,1.18.52c-.71,1.6-1.49,3.18-2.33,4.7a.64.64,0,0,1-.86.22Zm5.3-12.56a.65.65,0,0,1-.4-.77c.47-1.65.88-3.33,1.2-5a.64.64,0,0,1,.75-.49.65.65,0,0,1,.51.75c-.33,1.7-.74,3.42-1.22,5.1a.63.63,0,0,1-.79.42Z"
                    fill="#e3e2e2"
                />
                <path
                    id="Path_13"
                    data-name="Path 13"
                    d="M320.75,136.06c-1.52-1.64-2.28-2.29-1.64-2.82s1,.3,2.58,2a.61.61,0,0,1,0,.89.64.64,0,0,1-.94-.07Z"
                    fill="#e3e2e2"
                />
                <path
                    id="Path_14"
                    data-name="Path 14"
                    d="M327.76,120.13l9.11-1.13a40.88,40.88,0,0,0,9.89,8.43c.35,8.79-.25,9.43.69,9.74l10.51,3.5a.75.75,0,0,0,.83-.25l5.53-7.14a40.57,40.57,0,0,0,12.95-.82l6.63,6.37a.78.78,0,0,0,.87.14l9.83-4.79a.74.74,0,0,0,.41-.76l-1.29-9a38.93,38.93,0,0,0,8.43-9.58h9.2a.73.73,0,0,0,.7-.5l3.4-10.27a.74.74,0,0,0-.26-.83l-7.35-5.55a38.85,38.85,0,0,0-1-12.74c6.1-6.4,7-6.41,6.52-7.27-5.1-9.47-4.81-10.27-5.8-10.15l-9.12,1.13a40.421,40.421,0,0,0-9.88-8.43c-.36-8.79.24-9.43-.7-9.75-10.49-3.29-10.74-4-11.34-3.25L371,54.35a40.87,40.87,0,0,0-12.95.83c-6.52-6-6.61-6.94-7.5-6.51-9.64,4.91-10.37,4.58-10.23,5.55l1.28,9a38.79,38.79,0,0,0-8.43,9.59c-8.77.21-9.59-.4-9.9.54-3.18,10.22-3.93,10.5-3.13,11.1l7.3,5.55a38.88,38.88,0,0,0,1,12.75c-6.08,6.38-7,6.4-6.51,7.27C327.05,119.44,326.76,120.25,327.76,120.13Z"
                    fill="#e3e2e2"
                />
                <path
                    id="Path_15"
                    data-name="Path 15"
                    d="M356.9,93a10.53,10.53,0,0,1,11.45-9.69,10.86,10.86,0,0,1,10,11.41,10.53,10.53,0,0,1-11.45,9.69h0A10.86,10.86,0,0,1,356.9,93ZM367,103.42Zm1.23-18.26a8.62,8.62,0,0,0-9.4,8,8.91,8.91,0,0,0,8.24,9.36,8.64,8.64,0,0,0,9.4-8,8.9,8.9,0,0,0-8.25-9.36Z"
                    fill="#717272"
                />
                <path
                    id="Path_16"
                    data-name="Path 16"
                    d="M358.27,119h0a27.8,27.8,0,0,1-4.3-2,1,1,0,0,1-.42-1.15.37.37,0,0,1,.07-.14,1,1,0,0,1,1.31-.33,26.211,26.211,0,0,0,5,2.26h0c13.13,4.38,27.25-2.23,32-14.78.12-.32.24-.64.35-1,4.38-13.26-3.08-27.55-16.52-32s-28,2.53-32.34,15.75c-2.61,7.87-.33,13.15-.64,14a1,1,0,0,1-1.84-.14,26.7,26.7,0,0,1,1-15.47c8.3-22,39.57-23.32,50.47-2.18a26.64,26.64,0,0,1,1.68,20.72c-.12.35-.25.7-.38,1a26.53,26.53,0,0,1-13.33,14.55,27.79,27.79,0,0,1-21.06,1.33C359,119.26,358.62,119.14,358.27,119Z"
                    fill="#717272"
                />
                <path
                    id="Path_17"
                    data-name="Path 17"
                    d="M435.09,73.37a22.08,22.08,0,0,0,5.83,4.3l.47,5.1c.05.57.52.31,6.29,1.84.53.13.44-.35,3.24-4.48a22.51,22.51,0,0,0,7.15-1.1L462,82.31c.43.37.59-.14,5.75-3.14a.43.43,0,0,0,.19-.45l-1.08-5a21.831,21.831,0,0,0,4.29-5.83c4.88-.55,5.35-.24,5.48-.78,1.35-6,1.76-6.13,1.29-6.43l-4.31-2.79a22.269,22.269,0,0,0-1.09-7.15l3.28-3.95c.37-.43-.14-.58-3.14-5.75-.29-.47-.54-.07-5.47.89a22.051,22.051,0,0,0-5.83-4.29l-.47-5.11c-.05-.57-.52-.31-6.29-1.84-.53-.13-.43.33-3.24,4.49a22.22,22.22,0,0,0-7.15,1.09l-4-3.28a.41.41,0,0,0-.48,0l-5.27,3.18a.43.43,0,0,0-.19.45l1.08,5a22.05,22.05,0,0,0-4.29,5.83c-4.87.55-5.35.24-5.48.79-1.35,5.94-1.76,6.12-1.29,6.43l4.31,2.78a22.221,22.221,0,0,0,1.09,7.15c-3.06,3.83-3.61,4-3.32,4.43l3.18,5.27C429.91,74.73,430.19,74.32,435.09,73.37Z"
                    fill="#e3e2e2"
                />
                <path
                    id="Path_18"
                    data-name="Path 18"
                    d="M444.73,57.68a6.42,6.42,0,1,1,6.46,6.38h0A6.43,6.43,0,0,1,444.73,57.68Zm6.45,5.42Zm-4.54-5.43a4.52,4.52,0,0,0,4.54,4.48h0a4.51,4.51,0,1,0-4.54-4.48Z"
                    fill="#717272"
                />
                <path
                    id="Path_19"
                    data-name="Path 19"
                    d="M419.68,69.5c-.31-.81-.58-1.64-.82-2.47a.63.63,0,0,1,.43-.79c.85-.25.8.73,1.58,2.81a.63.63,0,0,1-.37.82.64.64,0,0,1-.82-.37Z"
                    fill="#e3e2e2"
                />
                <path
                    id="Path_20"
                    data-name="Path 20"
                    d="M417.54,58.6a33.379,33.379,0,0,1,.26-5.24.64.64,0,0,1,1.26.16c-.59,4.62.2,5.67-.86,5.7a.64.64,0,0,1-.66-.62Zm2.75-12.67a.64.64,0,0,1-.35-.83,33.842,33.842,0,0,1,2.34-4.71.64.64,0,0,1,1.09.66,32.931,32.931,0,0,0-2.25,4.53.65.65,0,0,1-.83.35Zm7.48-11.19a.51.51,0,0,1-.23-.15.63.63,0,0,1,0-.9,34.172,34.172,0,0,1,4-3.39.64.64,0,0,1,.75,1,32.557,32.557,0,0,0-3.87,3.26.62.62,0,0,1-.65.18Zm11.29-7.3a.64.64,0,0,1,0-1.19,32.779,32.779,0,0,1,5-1.5.64.64,0,0,1,.26,1.25,31.631,31.631,0,0,0-4.84,1.44.66.66,0,0,1-.42,0Zm12.87-2.79a.63.63,0,0,1,.66-.61,32.5,32.5,0,0,1,5.22.63.64.64,0,0,1-.25,1.25,32.47,32.47,0,0,0-5-.61.63.63,0,0,1-.63-.66Z"
                    fill="#e3e2e2"
                />
                <path
                    id="Path_21"
                    data-name="Path 21"
                    d="M467.56,29.75c-2-1.19-2.87-1.27-2.49-2a.63.63,0,0,1,.85-.3c.77.38,1.54.8,2.29,1.24a.64.64,0,0,1-.65,1.1Z"
                    fill="#e3e2e2"
                />
                <path
                    id="Path_22"
                    data-name="Path 22"
                    d="M482.88,51.25c-.46-2.3-1-3-.15-3.22a.65.65,0,0,1,.79.44c.23.83.44,1.68.61,2.53a.64.64,0,0,1-.5.75.63.63,0,0,1-.75-.5Z"
                    fill="#e3e2e2"
                />
                <path
                    id="Path_23"
                    data-name="Path 23"
                    d="M442.38,89.45c.26-1,1.08,0,5.67.39a.64.64,0,0,1-.12,1.27,33.651,33.651,0,0,1-5.09-.88.64.64,0,0,1-.46-.78Zm13.2.89a.64.64,0,0,1,.54-.73c4.59-.71,5.32-1.73,5.64-.72a.63.63,0,0,1-.42.8,33.111,33.111,0,0,1-5,1.18.65.65,0,0,1-.76-.53ZM468.07,86c-.56-.9.71-.81,4.21-3.83a.65.65,0,0,1,.83,1,32.808,32.808,0,0,1-4.11,3,.64.64,0,0,1-.93-.17Zm9.77-9c-.87-.61.34-1,2.37-5.17a.64.64,0,0,1,1.15.56,33.769,33.769,0,0,1-2.63,4.45A.64.64,0,0,1,477.84,77Zm5-12.8c.95-4.55.24-5.59,1.3-5.54a.64.64,0,0,1,.61.67,34,34,0,0,1-.66,5.13.64.64,0,0,1-1.25-.26Z"
                    fill="#e3e2e2"
                />
                <path
                    id="Path_24"
                    data-name="Path 24"
                    d="M435,87.14c-.76-.42-1.51-.87-2.23-1.34a.63.63,0,0,1-.19-.88c.49-.74,1.08,0,3,1.1a.64.64,0,0,1,.25.87A.63.63,0,0,1,435,87.14Z"
                    fill="#e3e2e2"
                />
                <path
                    id="Path_25"
                    data-name="Path 25"
                    d="M444.35,71.78a1,1,0,1,1,.8-1.73,13.639,13.639,0,0,0,2.79,1,13.83,13.83,0,0,0,10.51-1.6c10.54-6.38,8.1-22.43-3.87-25.38a13.93,13.93,0,0,0-16.32,8.58c-2.46,6.52,1.06,9.58-.92,10.19a1,1,0,0,1-1.2-.64,15.81,15.81,0,1,1,11.34,10.71h0a15,15,0,0,1-3.13-1.13Z"
                    fill="#717272"
                />
                <rect
                    id="Rectangle_4"
                    data-name="Rectangle 4"
                    width="81.74"
                    height="29.46"
                    rx="9.69"
                    transform="translate(226.25 393.18)"
                    fill="#717272"
                />
                <rect
                    id="Rectangle_5"
                    data-name="Rectangle 5"
                    width="169.58"
                    height="16.22"
                    rx="5.34"
                    transform="translate(191.13 270.28)"
                    fill="#e3e2e2"
                />
                <circle
                    id="Ellipse_7"
                    data-name="Ellipse 7"
                    cx="9.83"
                    cy="9.83"
                    r="9.83"
                    transform="translate(159.55 268.51)"
                    fill="#e3e2e2"
                />
                <rect
                    id="Rectangle_6"
                    data-name="Rectangle 6"
                    width="169.58"
                    height="16.22"
                    rx="5.34"
                    transform="translate(191.13 300.72)"
                    fill="#e3e2e2"
                />
                <circle
                    id="Ellipse_8"
                    data-name="Ellipse 8"
                    cx="9.83"
                    cy="9.83"
                    r="9.83"
                    transform="translate(159.55 298.95)"
                    fill="#e3e2e2"
                />
                <rect
                    id="Rectangle_7"
                    data-name="Rectangle 7"
                    width="169.58"
                    height="16.22"
                    rx="5.34"
                    transform="translate(191.13 331.16)"
                    fill="#e3e2e2"
                />
                <circle
                    id="Ellipse_9"
                    data-name="Ellipse 9"
                    cx="9.83"
                    cy="9.83"
                    r="9.83"
                    transform="translate(159.55 329.39)"
                    fill="#e3e2e2"
                />
                <rect
                    id="Rectangle_8"
                    data-name="Rectangle 8"
                    width="169.58"
                    height="16.22"
                    rx="5.34"
                    transform="translate(191.13 361.6)"
                    fill="#e3e2e2"
                />
                <circle
                    id="Ellipse_10"
                    data-name="Ellipse 10"
                    cx="9.83"
                    cy="9.83"
                    r="9.83"
                    transform="translate(159.55 359.83)"
                    fill="#e3e2e2"
                />
                <path
                    id="Path_26"
                    data-name="Path 26"
                    d="M115,128.24l-3.31,2.55h0a.54.54,0,0,1-.76-.1.53.53,0,0,1,.1-.75h0l3.36-2.5a.5.5,0,1,1,.6.79Z"
                    fill="#e3e2e2"
                />
                <path
                    id="Path_27"
                    data-name="Path 27"
                    d="M103.44,137.62q-3.14,2.74-6.16,5.59a.71.71,0,0,1-1,0,.72.72,0,0,1,0-1h0q3.09-2.82,6.29-5.51a.65.65,0,0,1,.83,1Z"
                    fill="#e3e2e2"
                />
                <path
                    id="Path_28"
                    data-name="Path 28"
                    d="M89.67,150.7c-1.92,2-3.82,4-5.67,6.07a.83.83,0,0,1-1.18.07.84.84,0,0,1-.07-1.19h0c1.89-2,3.83-4.06,5.79-6a.79.79,0,0,1,1.11,0A.78.78,0,0,1,89.67,150.7Z"
                    fill="#e3e2e2"
                />
                <path
                    id="Path_29"
                    data-name="Path 29"
                    d="M77.05,164.88c-1.75,2.14-3.46,4.32-5.13,6.52a.971.971,0,0,1-1.55-1.17h0q2.58-3.3,5.26-6.51a.92.92,0,0,1,1.41,1.17Z"
                    fill="#e3e2e2"
                />
                <path
                    id="Path_30"
                    data-name="Path 30"
                    d="M65.71,180.07c-1.56,2.29-3.07,4.6-4.53,7a1.1,1.1,0,0,1-1.87-1.17h0q2.26-3.53,4.67-7a1.046,1.046,0,0,1,1.72,1.19Z"
                    fill="#e3e2e2"
                />
                <path
                    id="Path_31"
                    data-name="Path 31"
                    d="M55.77,196.2q-2,3.62-3.88,7.32a1.2,1.2,0,1,1-2.14-1.08h0q1.92-3.72,4-7.37a1.16,1.16,0,0,1,2,1.14Z"
                    fill="#e3e2e2"
                />
                <path
                    id="Path_32"
                    data-name="Path 32"
                    d="M47.36,213.16c-1.1,2.53-2.16,5.08-3.15,7.66a1.28,1.28,0,0,1-2.39-.92h0q1.55-3.9,3.25-7.73a1.245,1.245,0,0,1,2.28,1Z"
                    fill="#e3e2e2"
                />
                <path
                    id="Path_33"
                    data-name="Path 33"
                    d="M40.65,230.85q-1.26,3.94-2.37,7.93a1.383,1.383,0,0,1-2.67-.72h0c.76-2.69,1.59-5.37,2.47-8a1.32,1.32,0,1,1,2.51.82Z"
                    fill="#e3e2e2"
                />
                <path
                    id="Path_34"
                    data-name="Path 34"
                    d="M35.76,249.11q-.87,4-1.56,8.13a1.44,1.44,0,1,1-2.84-.48h0c.49-2.76,1-5.5,1.65-8.24a1.41,1.41,0,0,1,2.75.6Z"
                    fill="#e3e2e2"
                />
                <path
                    id="Path_35"
                    data-name="Path 35"
                    d="M32.79,267.77q-.42,4.13-.71,8.25a1.52,1.52,0,0,1-3-.21h0q.33-4.19.79-8.35a1.484,1.484,0,1,1,2.95.32Z"
                    fill="#e3e2e2"
                />
                <path
                    id="Path_36"
                    data-name="Path 36"
                    d="M31.55,286.66c-.08,2.76-.11,5.53-.11,8.29a1.6,1.6,0,0,1-3.19,0h0c0-2.79.08-5.59.18-8.38a1.561,1.561,0,0,1,3.12.1Z"
                    fill="#e3e2e2"
                />
                <path
                    id="Path_37"
                    data-name="Path 37"
                    d="M31.66,305.6c.12,2.75.28,5.51.49,8.26a1.62,1.62,0,1,1-3.23.25h0c-.21-2.79-.36-5.58-.47-8.37a1.61,1.61,0,1,1,3.21-.13Z"
                    fill="#e3e2e2"
                />
                <path
                    id="Path_38"
                    data-name="Path 38"
                    d="M33.2,324.45c.34,2.74.72,5.47,1.17,8.19a1.65,1.65,0,0,1-3.25.53h0c-.45-2.76-.83-5.53-1.17-8.31a1.64,1.64,0,0,1,3.25-.4Z"
                    fill="#e3e2e2"
                />
                <path
                    id="Path_39"
                    data-name="Path 39"
                    d="M36.35,343.08q.88,4,1.95,8a1.671,1.671,0,0,1-3.23.86h0c-.72-2.72-1.37-5.44-2-8.18a1.66,1.66,0,1,1,3.24-.71Z"
                    fill="#e3e2e2"
                />
                <path
                    id="Path_40"
                    data-name="Path 40"
                    d="M41.36,361.27c.87,2.6,1.82,5.18,2.83,7.74A1.713,1.713,0,0,1,41,370.26h0c-1-2.62-2-5.26-2.87-7.92a1.69,1.69,0,1,1,3.2-1.07Z"
                    fill="#e3e2e2"
                />
                <path
                    id="Path_41"
                    data-name="Path 41"
                    d="M48.47,378.69q1.8,3.71,3.83,7.28a1.724,1.724,0,0,1-3,1.7h0c-1.38-2.45-2.69-4.95-3.91-7.48a1.713,1.713,0,1,1,3.08-1.5Z"
                    fill="#e3e2e2"
                />
                <path
                    id="Path_42"
                    data-name="Path 42"
                    d="M57.92,394.92c1.55,2.25,3.19,4.45,4.9,6.59a1.754,1.754,0,0,1-2.74,2.19h0q-2.64-3.3-5-6.8a1.749,1.749,0,0,1,2.87-2Z"
                    fill="#e3e2e2"
                />
                <path
                    id="Path_43"
                    data-name="Path 43"
                    d="M69.8,409.41q2.85,2.94,5.93,5.66a1.775,1.775,0,0,1-2.35,2.66h0q-3.18-2.82-6.12-5.86a1.77,1.77,0,0,1,2.54-2.46Z"
                    fill="#e3e2e2"
                />
                <path
                    id="Path_44"
                    data-name="Path 44"
                    d="M84,421.65q1.65,1.2,3.36,2.34c1.13.77,2.3,1.48,3.45,2.22h0a1.778,1.778,0,1,1-1.91,3c-1.19-.76-2.4-1.49-3.57-2.28s-2.34-1.59-3.48-2.41A1.8,1.8,0,1,1,84,421.64Z"
                    fill="#e3e2e2"
                />
                <path
                    id="Path_45"
                    data-name="Path 45"
                    d="M100,431.31q3.69,1.81,7.5,3.34a1.74,1.74,0,1,1-1.29,3.23c-2.62-1-5.21-2.18-7.75-3.41a1.76,1.76,0,0,1,1.54-3.16Z"
                    fill="#e3e2e2"
                />
                <path
                    id="Path_46"
                    data-name="Path 46"
                    d="M117.47,438.11c2.62.78,5.28,1.46,8,2.05a1.72,1.72,0,0,1,1.3,2,1.7,1.7,0,0,1-2,1.29c-2.75-.59-5.49-1.29-8.19-2.08a1.724,1.724,0,0,1,1-3.3Z"
                    fill="#e3e2e2"
                />
                <path
                    id="Path_47"
                    data-name="Path 47"
                    d="M135.83,442q4.08.54,8.19.83a1.67,1.67,0,1,1-.23,3.33c-2.8-.19-5.61-.46-8.4-.82a1.684,1.684,0,0,1,.44-3.34Z"
                    fill="#e3e2e2"
                />
                <path
                    id="Path_48"
                    data-name="Path 48"
                    d="M154.61,443.18q4.13,0,8.24-.24a1.633,1.633,0,0,1,.19,3.26c-2.8.17-5.61.26-8.41.28a1.65,1.65,0,1,1,0-3.3Z"
                    fill="#e3e2e2"
                />
                <path
                    id="Path_49"
                    data-name="Path 49"
                    d="M173.43,442q4.09-.48,8.18-1.16a1.61,1.61,0,0,1,1.84,1.32,1.59,1.59,0,0,1-1.31,1.83q-4.15.71-8.32,1.21a1.612,1.612,0,1,1-.4-3.2Z"
                    fill="#e3e2e2"
                />
                <path
                    id="Path_50"
                    data-name="Path 50"
                    d="M192.06,438.87c2.69-.58,5.38-1.22,8.05-1.9a1.5,1.5,0,1,1,.77,2.9q-4,1.1-8.15,2a1.55,1.55,0,1,1-.69-3Z"
                    fill="#e3e2e2"
                />
                <path
                    id="Path_51"
                    data-name="Path 51"
                    d="M210.36,434.08c2.65-.81,5.27-1.66,7.89-2.56a1.393,1.393,0,1,1,.92,2.63q-3.94,1.41-7.95,2.68a1.44,1.44,0,0,1-1.81-.93,1.46,1.46,0,0,1,.94-1.82Z"
                    fill="#e3e2e2"
                />
                <path
                    id="Path_52"
                    data-name="Path 52"
                    d="M228.25,427.84q3.85-1.51,7.67-3.15a1.29,1.29,0,0,1,1,2.36q-3.82,1.69-7.71,3.26a1.332,1.332,0,1,1-1-2.47Z"
                    fill="#e3e2e2"
                />
                <path
                    id="Path_53"
                    data-name="Path 53"
                    d="M245.63,420.28c2.5-1.2,5-2.44,7.43-3.72a1.18,1.18,0,0,1,1.09,2.09c-2.46,1.31-4.94,2.6-7.44,3.83a1.23,1.23,0,0,1-1.09-2.2Z"
                    fill="#e3e2e2"
                />
                <path
                    id="Path_54"
                    data-name="Path 54"
                    d="M262.39,411.42c2.38-1.4,4.74-2.84,7.06-4.34a1.029,1.029,0,0,1,1.13,1.72c-2.32,1.55-4.68,3.06-7.06,4.51a1.11,1.11,0,0,1-1.52-.37,1.09,1.09,0,0,1,.37-1.51Z"
                    fill="#e3e2e2"
                />
                <path
                    id="Path_55"
                    data-name="Path 55"
                    d="M278.26,401.07q3.38-2.43,6.63-5a.853.853,0,0,1,1.07,1.33q-3.24,2.65-6.59,5.16a.932.932,0,1,1-1.12-1.49Z"
                    fill="#e3e2e2"
                />
                <path
                    id="Path_56"
                    data-name="Path 56"
                    d="M293.1,389.26c2.09-1.82,4.13-3.7,6.15-5.59a.686.686,0,1,1,.94,1c-2,2-4,3.88-6.07,5.75a.76.76,0,1,1-1-1.12Z"
                    fill="#e3e2e2"
                />
                <path
                    id="Path_57"
                    data-name="Path 57"
                    d="M306.84,376.14l2.85-3h0a.5.5,0,1,1,.74.68l-2.8,3.12a.552.552,0,0,1-.82-.74h0Z"
                    fill="#e3e2e2"
                />
                <rect
                    id="Rectangle_9"
                    data-name="Rectangle 9"
                    width="133.5"
                    height="16.22"
                    rx="5.34"
                    transform="translate(159.93 238.58)"
                    fill="#e3e2e2"
                />
                <path
                    id="Path_58"
                    data-name="Path 58"
                    d="M296.27,325.04l25.68,37.23,14.17-33.4Z"
                    fill="#717272"
                />
                <path
                    id="Path_59"
                    data-name="Path 59"
                    d="M449,188.21,409.23,345.74a11.45,11.45,0,0,1-14.31,8.19L238.26,308.09a4.59,4.59,0,0,1-.85-8.47Z"
                    fill="#717272"
                />
                <path
                    id="Path_60"
                    data-name="Path 60"
                    d="M296.29,325.03,448.95,188.21l-127.4,144.3,6.68,10.63Z"
                    fill="#5a5b5b"
                />
                <path
                    id="Path_61"
                    data-name="Path 61"
                    d="M336.56,336.83l-14.61,25.44-.4-29.76Z"
                    fill="#5a5b5b"
                />
            </g>
        </SVGIcon>
    );
}
