import React from 'react';

import { SVGIcon } from '../../UIKit/index';

export default function DCBattery(props) {
    return (
        <SVGIcon {...props} viewBox="0 0 471.829 471.829">
            <g>
                <path d="M319.089,27.221h-36.482V0H187.34v27.221h-34.603c-22.517,0-40.829,18.317-40.829,40.832v362.946   c0,22.51,18.317,40.83,40.829,40.83h166.352c22.524,0,40.832-18.32,40.832-40.83V68.052   C359.921,45.538,341.613,27.221,319.089,27.221z M332.705,431.002c0,7.501-6.108,13.607-13.616,13.607H152.737   c-7.508,0-13.615-6.095-13.615-13.607V68.052c0-7.501,6.107-13.611,13.615-13.611h166.352c7.508,0,13.616,6.109,13.616,13.611   V431.002z M317.862,428.5H153.966v-59.719h163.892V428.5H317.862z M317.862,354.071H153.966v-59.716h163.892v59.716H317.862z    M317.862,279.64H153.966v-59.72h163.892v59.72H317.862z M317.862,205.378H153.966v-59.719h163.892v59.719H317.862z" />
            </g>
        </SVGIcon>
    );
}
