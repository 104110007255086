import React from 'react';
import PropTypes from 'prop-types';
export default function P({ props, children }) {
    return <p {...props}>{children}</p>;
}

P.propTypes = {
    children: PropTypes.any,
    props: PropTypes.any,
};
