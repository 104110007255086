import React, { useState, useEffect } from 'react';
import { Div, SingleTextChip } from '../index';
import _ from 'lodash';
import DCTag3 from 'Assets/icons/DCTag3';
import PropTypes from 'prop-types';
import './style.scss';
import ToolTip from 'UIKit/ToolTip/toolTip';
import IconButton from 'UIKit/IconButton/IconButton';

const Tags = ({ className, tags, displayKey, handleClick, hideMore, showChip }) => {
    const [tagsView, setTagsView] = useState([]);
    let temp = tags.slice(1);

    useEffect(() => {
        if (hideMore) {
            setTagsView([...tags]);
        } else {
            const tempTags = _.cloneDeep(tags).slice(0, 1);
            setTagsView(tempTags);
        }
    }, [tags, hideMore]);

    return (
        <Div className={'TagsContainer ' + (className ? className : '')}>
            {tagsView.map((tag, index) => showChip ? (
                    <SingleTextChip key={index} icon={<DCTag3 />} title={tag?.[displayKey]} />
                ) : (
                    <Div
                        className={`TCTag ${className ? className : ''} ${
                            handleClick ? 'clickable' : ''
                        }`}
                        key={index}
                        onClick={() => handleClick && handleClick(tag)}
                    >
                        {tag?.[displayKey]}
                    </Div>
                ),
            )}
            {tags.length > 1 && !hideMore ? (
                <ToolTip
                    content={
                        temp?.length && (
                            <Div className={'TagsContainer '}>
                                {temp.map((tag, index) => showChip ? (
                                        <SingleTextChip
                                            key={index}
                                            icon={<DCTag3 />}
                                            title={tag?.[displayKey]}
                                        />
                                    ) : (
                                        <Div
                                            className={`TCTag ${className ? className : ''} ${
                                                handleClick ? 'clickable' : ''
                                            }`}
                                            key={index}
                                            onClick={() => handleClick && handleClick(tag)}
                                        >
                                            {tag?.[displayKey]}
                                        </Div>
                                    ),
                                )}
                            </Div>
                        )
                    }
                >
                    <IconButton buttonType={'circleBtn'}>+{temp?.length}</IconButton>
                </ToolTip>
            ) : null}
        </Div>
    );
};

Tags.propTypes = {
    className: PropTypes.string,
    tags: PropTypes.arrayOf(PropTypes.object),
    displayKey: PropTypes.string,
    handleClick: PropTypes.func,
    hideMore: PropTypes.bool,
    showChip: PropTypes.bool,
};

export default Tags;
