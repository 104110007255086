import { getLocalStorageAuthenticateObject } from 'Utils/auth';

export const getIntercomAttributeConfigs = () => {
    const authObj = getLocalStorageAuthenticateObject();

    const {
        old_id,
        id,
        name,
        email,
        created,
        number_of_users,
        revenue,
        utm,
        partnership,
        domain,
        address,
        phone,
        website,
        active_site,
        owner,
        sub_accounts,
        plan,
        branding,
        stripe_connected,
        listings,
        purchased_fulfillment,
    } = authObj.auth.account;
    const affiliateTiers = {
        1: 'Starter',
        2: 'Diamond',
        3: 'Legend',
    };
    return {
        custom_launcher_selector: '.talk-to-us',
        hide_default_launcher: true,
        user_hash: authObj.auth.user?.intercom_hash,
        user_id: authObj.auth.user?.old_id || authObj.auth.user?.id,
        name: authObj.auth.user?.name,
        email: authObj.auth.user?.email,
        timezone: authObj.auth.user?.preferences?.timezone,
        created_at: authObj.auth.user?.created,
        phone: authObj.auth.user?.phone,
        'V2 - User ID': authObj.auth.user?.id,
        'V2 - First Name': authObj.auth.user?.first_name,
        'V2 - Last Name': authObj.auth.user?.last_name,
        'V2 - User Role': authObj.auth.user?.role,
        'V2 - Profile Image Uploaded': !!authObj.auth.user?.image,
        'V2 - Gender': authObj.auth.user?.gender,
        'V2 - Account Owner': authObj.auth.user?.is_owner,
        'V2 - Upcoming Birthday': authObj.auth.user?.dob?.next,
        'V2 - Language': authObj.auth.user?.preferences?.preferred_lang,
        company: {
            id: old_id || id,
            created_at: created,
            size: number_of_users,
            website,
            name,
            'V2 - Account ID': id,
            'V2 - Lifetime Revenue': revenue?.lifetime,
            'V2 - Monthly Revenue': revenue?.monthly,
            'V2 - UTM Term': utm?.term,
            'V2 - UTM Source': utm?.source,
            'V2 - UTM Medium': utm?.medium,
            'V2 - UTM Campaign': utm?.campaign,
            'V2 - UTM Content': utm?.content,
            'V2 - Affiliate Payouts Active': partnership?.stats?.tipalti?.payable || false,
            'V2 - Affiliate Referrals': partnership?.stats?.active_referrals,
            'V2 - Affiliate Referrals Total': partnership?.stats?.all_referrals,
            'V2 - Pending Affiliate Commission': (partnership?.stats?.to_be_paid?.value || 0) / 100,
            'V2 - Paid Affiliate Commission':
                (partnership?.stats?.lifetime_earning?.value || 0) / 100,
            'V2 - Affiliate Tier': affiliateTiers[partnership?.level || 1],
            'V2 - Affiliate Link': partnership?.referral_link,
            'V2 - Has Purchased Anything': !!revenue?.lifetime,
            'V2 - Custom Domain Configured': !!(domain.indexOf('.dashclicks.com') === -1),
            'V2 - Address': address?.street,
            'V2 - Address 2': address?.unit,
            'V2 - City': address?.city,
            'V2 - State': address?.state_province,
            'V2 - Zip Code': address?.postal_code,
            'V2 - Country': address?.country,
            'V2 - Phone Number': phone,
            'V2 - Website URL': website,
            'V2 - Sites Activated': active_site,
            'V2 - Account Owners First Name': owner?.first_name,
            'V2 - Account Owners Last Name': owner?.last_name,
            'V2 - Sub-Accounts': sub_accounts,
            'V2 - Active Domain': domain,
            'V2 - Active Domain without HTTPS': domain.replace('https://', ''),
            'V2 - Email Address': email,
            'V2 - Software Plan': plan.display_name,
            'V2 - Phone Number Activated': !!authObj.auth.user?.sms_numbers.length,
            'V2 - Logo Uploaded': !!branding?.image,
            'V2 - Favicon Uploaded': !!branding?.images?.favicon,
            'V2 - Login Image Uploaded': !!branding?.images?.login_image,
            'V2 - Payments Activated': !!stripe_connected,
            'V2 - Listings Activated': !!listings?.active,
            'V2 - First Time Fulfillment Purchase': !!purchased_fulfillment,
            'V2 - Software Subscription Type': plan.tier,
        },
    };
};

export const getCannyAttributeConfigs = () => {
    const authObj = getLocalStorageAuthenticateObject();

    const {
        old_id,
        id,
        name,
        email,
        created,
        number_of_users,
        revenue,
        utm,
        partnership,
        domain,
        address,
        phone,
        website,
        active_site,
        owner,
        sub_accounts,
        plan,
        branding,
        stripe_connected,
        listings,
        purchased_fulfillment,
    } = authObj.auth.account;

    const affiliateTiers = {
        1: 'Starter',
        2: 'Diamond',
        3: 'Legend',
    };
    return {
        appID: '6117ff7bd8898772af0eb2c4',
        position: 'top',
        align: 'right',
        user: {
            id: authObj.auth.user?.old_id || authObj.auth.user?.id,
            name: authObj.auth.user?.name,
            email: authObj.auth.user?.email,
            created: authObj.auth.user?.created,
            customFields: {
                timezone: authObj.auth.user?.preferences?.timezone,
                phone: authObj.auth.user?.phone,
                'V2 - User ID': authObj.auth.user?.id,
                'V2 - First Name': authObj.auth.user?.first_name,
                'V2 - Last Name': authObj.auth.user?.last_name,
                'V2 - User Role': authObj.auth.user?.role,
                'V2 - Profile Image Uploaded': !!authObj.auth.user?.image,
                'V2 - Gender': authObj.auth.user?.gender,
                'V2 - Account Owner': authObj.auth.user?.is_owner,
                'V2 - Upcoming Birthday': authObj.auth.user?.dob?.next,
                'V2 - Language': authObj.auth.user?.preferences?.preferred_lang,
            },
            companies: [
                {
                    id: old_id || id,
                    created: created,
                    name,
                    customFields: {
                        size: number_of_users,
                        website,
                        'V2 - Account ID': id,
                        'V2 - Lifetime Revenue': revenue?.lifetime,
                        'V2 - Monthly Revenue': revenue?.monthly,
                        'V2 - UTM Term': utm?.term,
                        'V2 - UTM Source': utm?.source,
                        'V2 - UTM Medium': utm?.medium,
                        'V2 - UTM Campaign': utm?.campaign,
                        'V2 - UTM Content': utm?.content,
                        'V2 - Affiliate Referrals': partnership?.stats?.active_referrals,
                        'V2 - Affiliate Referrals Total': partnership?.stats?.all_referrals,
                        'V2 - Pending Affiliate Commission':
                            (partnership?.stats?.to_be_paid?.value || 0) / 100,
                        'V2 - Paid Affiliate Commission':
                            (partnership?.stats?.lifetime_earning?.value || 0) / 100,
                        'V2 - Affiliate Tier': affiliateTiers[partnership?.level || 1],
                        'V2 - Affiliate Link': partnership?.referral_link,
                        'V2 - Has Purchased Anything': !!revenue?.lifetime,
                        'V2 - Custom Domain Configured': !!(
                            domain.indexOf('.dashclicks.com') === -1
                        ),
                        'V2 - Address': address?.street,
                        'V2 - Address 2': address?.unit,
                        'V2 - City': address?.city,
                        'V2 - State': address?.state_province,
                        'V2 - Zip Code': address?.postal_code,
                        'V2 - Country': address?.country,
                        'V2 - Phone Number': phone,
                        'V2 - Website URL': website,
                        'V2 - Sites Activated': active_site,
                        'V2 - Account Owners First Name': owner?.first_name,
                        'V2 - Account Owners Last Name': owner?.last_name,
                        'V2 - Sub-Accounts': sub_accounts,
                        'V2 - Active Domain': domain,
                        'V2 - Active Domain without HTTPS': domain.replace('https://', ''),
                        'V2 - Email Address': email,
                        'V2 - Software Plan': plan.display_name,
                        'V2 - Phone Number Activated': !!authObj.auth.user?.sms_numbers.length,
                        'V2 - Logo Uploaded': !!branding?.image,
                        'V2 - Favicon Uploaded': !!branding?.images?.favicon,
                        'V2 - Login Image Uploaded': !!branding?.images?.login_image,
                        'V2 - Payments Activated': !!stripe_connected,
                        'V2 - Listings Activated': !!listings?.active,
                        'V2 - First Time Fulfillment Purchase': !!purchased_fulfillment,
                        'V2 - Software Subscription Type': plan.tier,
                    },
                },
            ],
        },
    };
};
