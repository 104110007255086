import React from 'react';

import { SVGIcon } from '../../UIKit/index';

export default function DCDollar(props) {
    return (
        <SVGIcon {...props} viewBox="0 0 399 512">
            <g transform="translate(-100 309)">
                <path d="M298.96-309c0,0-38.3,92.57-196.99,50.24c-2.65,0,0,224.81,0,224.81s42.3,174.47,200.99,235.39   c2.65,5.29,163.96-55.54,195.72-235.39v-211.6c0,0-169.35-3.72-195.72-53.59" />
                <path
                    fill="#FFFFFF"
                    d="M319.35-61.9v-67.18c15.14,3.43,26.21,16.42,27.21,31.91c0,7.51,6.09,13.6,13.6,13.6   c7.51,0,13.6-6.09,13.6-13.6c-1.32-30.5-24.19-55.72-54.42-59.99v-1.23c0-7.51-6.09-13.6-13.6-13.6c-7.51,0-13.6,6.09-13.6,13.6   v1.23c-30.24,4.26-53.13,29.5-54.44,60c1.32,30.5,24.19,55.72,54.42,59.99v67.08C276.98,26.48,265.91,13.5,264.91-2   c0-7.51-6.09-13.6-13.6-13.6c-7.51,0-13.6,6.09-13.6,13.6c1.32,30.5,24.19,55.72,54.42,59.99v1.33c0,7.51,6.09,13.6,13.6,13.6   c7.51,0,13.6-6.09,13.6-13.6l0,0v-1.23c30.22-4.27,53.1-29.49,54.42-59.99C372.44-32.4,349.57-57.62,319.35-61.9z M264.93-97.19   c1-15.49,12.07-28.48,27.21-31.91v63.87c-15.14-3.43-26.21-16.42-27.21-31.91V-97.19z M319.35,29.92v-63.75   c15.14,3.43,26.21,16.42,27.21,31.91C345.53,13.54,334.46,26.49,319.35,29.92z"
                />
            </g>
        </SVGIcon>
    );
}
