import React from 'react';

import { SVGIcon } from '../../UIKit/index';

export default function DCPhone(props) {
    return (
        <SVGIcon {...props} viewBox="0 0 512 512">
            <path d="M135.19,28.51c0,0-17.84-6.65-34.86-0.81l-9.84,5.57L47.3,75.86c0,0-15.41,17.19-17.14,46.38v27.24l2.16,19.03  l6.05,27.24l19.03,46.05l33.95,56l25.08,33.95l32.22,34.59l31.57,29.19l34.16,26.81l44.76,28.76l33.95,16.86l28.76,10.59l38.49,8.5  c0,0,30.92,2.74,47.14-3.1c0,0,19.46-2.81,32.22-14.92l45.41-45.41c0,0,13.19-22.7,2.59-41.95l-5.62-11.03l-31.78-32.65L407.25,295  c0,0-25.08-22.92-51.89-6.49l-4.65,2.49l-23.03,22.05l-21.84,22.7l-2.92,0.22l-22.27-17.19l-14.92-12.16L247,289.76l-18.97-18.41  l-21.89-24.24l-15.49-20.19l-10.31-13.19c-0.38-0.48-0.48-1.13-0.26-1.71l0,0c0.09-0.24,0.24-0.47,0.42-0.65l20.9-20.26l19.08-19.14  l7.08-8.7l2.65-4.81l2.43-7.35l1.62-7.68l-0.7-10.16l-3.78-12.05c0,0-2.54-7.03-7.41-11.3l-36.59-37.3l-24.81-25.14l-16.7-14.76  l-7.68-3.84l-12.55,26.93l8.5,7.18l8.76,9.08l33.95,33.73l20.97,21.62l3.46,3.46l1.73,4.11c0,0,2.92,6.38,0.11,12.11l-47.46,49.3  c0,0-8.22,11.46-3.03,24.87c0,0,9.51,16.86,9.51,16.86l32.43,39.78L220,306.24l16,14.7l13.41,11.89l20.11,16.43l18.38,13.19  c0,0,14.05,8.43,27.46,2.59l9.95-6.92l21.41-20.97l20.54-21.62c0,0,10.81-3.89,17.08,0.22l75.03,73.95c0,0,4.76,6.27,1.51,14.27  l-3.03,5.19c0,0,0,0,0,0l-34.38,34.38c0,0-7.78,11.46-37.84,14.05c0,0-44.32,0.65-68-12.97c0,0,0,0,0,0l-15.24-6.16L256,413.81  l-40.86-28.86l-24.32-20.43L172,346.68l-33.73-37.62l-31.14-42.49l-12.65-20.43l-21.73-44.11c0,0-27.57-57.73-4.86-103.14  l39.57-41.84c0,0,9.16-4.38,14.92-2.03l1.7,0.81l12.56-26.92L135.19,28.51z" />
        </SVGIcon>
    );
}
