import React, { Fragment, useCallback, useState } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import { CardElement, Elements, useElements, useStripe } from '@stripe/react-stripe-js';

import {
    Div,
    Dialog,
    TopBar,
    H1,
    Button,
    IconButton,
    FieldControl,
    LabelControl,
    Label,
    Input,
    Checkbox,
    Span,
} from 'UIKit/index';
import DCCrossClose from 'Assets/icons/DCCrossClose';
import PhoneNumber from 'Modules/PhoneNumber/phoneNumber';
import './style.scss';
import { addPaymentCard } from 'apis/store/index.api';
import { getUserDetails } from 'Utils/localStorageUtils';
import useToast from 'Modules/Toasts';
import CountryList from 'Components/CountryList/countryList';
import { Validation } from 'Utils/validator';
import { toUpperCaseFirstLetter } from 'Utils';
import TermsModal from './TermsModal';
import PropTypes from 'prop-types';
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY);

const CARD_OPTIONS = {
    iconStyle: 'default',
    hidePostalCode: true,
    style: {
        base: {
            iconColor: '#000',
            color: '#000',
            fontWeight: 500,
            fontFamily: 'Roboto, Open Sans, Segoe UI, sans-serif',
            fontSize: '16px',
            fontSmoothing: 'antialiased',
            ':-webkit-autofill': {
                color: '#7b7b7b',
            },
            '::placeholder': {
                color: '#7b7b7b',
            },
        },
        invalid: {
            iconColor: '#f02424',
            color: '#f02424',
        },
    },
};

const CardField = ({ onChange }) => (
    <div className="FormRow">
        <CardElement options={CARD_OPTIONS} onChange={onChange} />
    </div>
);

const AddNewCardDialog = ({ open, handleClose }) => {
    const { showToast } = useToast();
    const stripe = useStripe();
    const elements = useElements();
    const [error, setError] = useState(null);
    const [cardComplete, setCardComplete] = useState(false);
    const [processing, setProcessing] = useState(false);
    const [billingDetails, setBillingDetails] = useState({
        address: {
            line1: '',
            line2: '',
            city: '',
            state: '',
            country: '',
            postal_code: '',
        },
        name: '',
        email: getUserDetails().email,
        phone: '',
    });
    const [agreed, setAgreed] = useState(false);
    const [errors, setErrors] = useState({ empty: [], invalid: [] });
    const [openTermsModal, setOpenTermsModal] = useState(false);

    const checkValidation = useCallback(() => {
        let _errors = { empty: [], invalid: [] };
        if (billingDetails.name?.trim().length === 0) {
            _errors.empty.push('name');
        } else if (!Validation('name', billingDetails.name)) {
            _errors.invalid.push('name');
        }

        if (billingDetails.address.line1?.trim().length === 0) {
            _errors.empty.push('street address');
        }

        if (billingDetails.address.postal_code?.trim().length === 0) {
            _errors.empty.push('zip/Postal');
        }

        if (billingDetails.address.city?.trim().length === 0) {
            _errors.empty.push('city');
        }

        if (billingDetails.address.state?.trim().length === 0) {
            _errors.empty.push('state');
        }

        if (billingDetails.phone?.trim().length === 0) {
            _errors.empty.push('phone number');
        }

        if (_errors.empty.length === 0 && _errors.invalid.length === 0) return true;
        else {
            setErrors(_errors);
            return false;
        }
    }, [billingDetails]);

    const handleSubmit = useCallback(async () => {
        if (!stripe) {
            // Stripe.js has not loaded yet. Make sure to disable
            // form submission until Stripe.js has loaded.
            return;
        }

        if (error) {
            elements.getElement('card').focus();
            return;
        }

        if (cardComplete && checkValidation()) {
            setProcessing(true);
        } else {
            showToast({ type: 'warning', message: 'Please fill all fields' });
            return;
        }

        const billingBody = {
            name: billingDetails.name,
            address_line1: billingDetails.address.line1,
            address_line2: billingDetails.address.line2,
            address_city: billingDetails.address.city,
            address_state: billingDetails.address.state,
            address_zip: billingDetails.address.postal_code,
            address_country: billingDetails.address.country,
        };
        const payload = await stripe.createToken(elements.getElement(CardElement), billingBody);

        if (payload.error) {
            showToast({ type: 'error', message: payload.error.message || 'Something went wrong' });
            setProcessing(false);
        } else {
            try {
                const res = await addPaymentCard({ token: payload.token.id });
                if (res.success) {
                    showToast({ type: 'success', message: 'Card added successfully' });
                    handleClose();
                } else {
                    showToast({ message: res.message, type: 'error' });
                    setProcessing(false);
                }
            } catch (err) {
                showToast({ message: err, type: 'error' });
                setProcessing(false);
            }
        }
    }, [
        stripe,
        error,
        cardComplete,
        checkValidation,
        elements,
        billingDetails,
        showToast,
        handleClose,
    ]);

    const getErrorMessage = useCallback(
        elName => {
            if (errors.empty.includes(elName)) {
                return `${toUpperCaseFirstLetter(elName)} can not be empty`;
            }
            if (errors.invalid.includes(elName)) {
                return `Invalid ${toUpperCaseFirstLetter(elName)}`;
            }
            return null;
        },
        [errors],
    );

    const removeError = useCallback(
        elName => {
            let _empty = errors.empty.filter(el => el !== elName);
            let _invalid = errors.invalid.filter(el => el !== elName);
            setErrors({ empty: _empty, invalid: _invalid });
        },
        [errors],
    );

    return (
        <Fragment>
            {openTermsModal && (
                <TermsModal open={openTermsModal} onClose={() => setOpenTermsModal(false)} />
            )}
            <Dialog dialogType={'centerZoom sizeSm'} open={open} onClose={handleClose}>
                <Div className="AddNewCardDialog">
                    <TopBar>
                        <Div className={'HeaderBar'}>
                            <H1 className={'HeaderBarTitle'}>Add New Card</H1>
                            <Div className={'HeaderBarClose'}>
                                <IconButton onClick={handleClose} disabled={processing}>
                                    <DCCrossClose />
                                </IconButton>
                            </Div>
                        </Div>
                    </TopBar>
                    <Div className={'BodyBox '}>
                        <Div className={'BodyBoxInner'}>
                            <FieldControl>
                                <LabelControl>
                                    <Label props={{ htmlFor: 'NameOnCard' }} required>
                                        Name on card
                                    </Label>
                                </LabelControl>
                                <Input
                                    id="NameOnCard"
                                    value={billingDetails.name}
                                    onChange={e => {
                                        if (getErrorMessage('name')) {
                                            removeError('name');
                                        }
                                        setBillingDetails(prev => {
                                            return { ...prev, name: e.target.value };
                                        });
                                    }}
                                    required
                                    error={!!getErrorMessage('name')}
                                    errorMessage={getErrorMessage('name')}
                                />
                            </FieldControl>
                            <FieldControl>
                                <LabelControl>
                                    <Label props={{ htmlFor: 'CardNumber' }} required>
                                        Card number
                                    </Label>
                                </LabelControl>
                                <CardField
                                    onChange={e => {
                                        setError(e.error);
                                        setCardComplete(e.complete);
                                    }}
                                />
                            </FieldControl>
                            <FieldControl>
                                <LabelControl>
                                    <Label props={{ htmlFor: 'ACountry' }} required>
                                        Country
                                    </Label>
                                </LabelControl>
                                <CountryList
                                    value={billingDetails.address?.country}
                                    onChange={val => setBillingDetails(prev => {
                                            return {
                                                ...prev,
                                                address: {
                                                    ...prev.address,
                                                    country: val.code,
                                                },
                                            };
                                        })
                                    }
                                />
                            </FieldControl>
                            <FieldControl>
                                <LabelControl>
                                    <Label props={{ htmlFor: 'StreetAddress' }} required>
                                        Street address
                                    </Label>
                                </LabelControl>
                                <Input
                                    id="StreetAddress"
                                    value={billingDetails.address?.line1}
                                    onChange={e => setBillingDetails(prev => {
                                            if (getErrorMessage('street address')) {
                                                removeError('street address');
                                            }
                                            return {
                                                ...prev,
                                                address: { ...prev.address, line1: e.target.value },
                                            };
                                        })
                                    }
                                    required
                                    error={!!getErrorMessage('street address')}
                                    errorMessage={getErrorMessage('street address')}
                                />
                            </FieldControl>
                            <FieldControl>
                                <LabelControl>
                                    <Label props={{ htmlFor: 'OptionalAddress' }}>
                                        Apt, Suite, Building (optional)
                                    </Label>
                                </LabelControl>
                                <Input
                                    id="OptionalAddress"
                                    value={billingDetails.address?.line2}
                                    onChange={e => setBillingDetails(prev => {
                                            return {
                                                ...prev,
                                                address: { ...prev.address, line2: e.target.value },
                                            };
                                        })
                                    }
                                    required
                                />
                            </FieldControl>
                            <Div className={'ANCDZCS'}>
                                <FieldControl>
                                    <LabelControl>
                                        <Label props={{ htmlFor: 'ZipPostal' }} required>
                                            Zip/Postal
                                        </Label>
                                    </LabelControl>
                                    <Input
                                        id="ZipPostal"
                                        value={billingDetails.address?.postal_code}
                                        onChange={e => {
                                            if (getErrorMessage('zip/Postal')) {
                                                removeError('zip/Postal');
                                            }
                                            setBillingDetails(prev => {
                                                return {
                                                    ...prev,
                                                    address: {
                                                        ...prev.address,
                                                        postal_code: e.target.value,
                                                    },
                                                };
                                            });
                                        }}
                                        required
                                        error={!!getErrorMessage('zip/Postal')}
                                        errorMessage={getErrorMessage('zip/Postal')}
                                    />
                                </FieldControl>
                                <FieldControl>
                                    <LabelControl>
                                        <Label props={{ htmlFor: 'City' }} required>
                                            City
                                        </Label>
                                    </LabelControl>
                                    <Input
                                        id="City"
                                        value={billingDetails.address?.city}
                                        onChange={e => {
                                            if (getErrorMessage('city')) {
                                                removeError('city');
                                            }
                                            setBillingDetails(prev => {
                                                return {
                                                    ...prev,
                                                    address: {
                                                        ...prev.address,
                                                        city: e.target.value,
                                                    },
                                                };
                                            });
                                        }}
                                        required
                                        error={!!getErrorMessage('city')}
                                        errorMessage={getErrorMessage('city')}
                                    />
                                </FieldControl>
                                <FieldControl>
                                    <LabelControl>
                                        <Label props={{ htmlFor: 'State' }} required>
                                            State
                                        </Label>
                                    </LabelControl>
                                    <Input
                                        id="State"
                                        value={billingDetails.address?.state}
                                        onChange={e => {
                                            if (getErrorMessage('state')) {
                                                removeError('state');
                                            }
                                            setBillingDetails(prev => {
                                                return {
                                                    ...prev,
                                                    address: {
                                                        ...prev.address,
                                                        state: e.target.value,
                                                    },
                                                };
                                            });
                                        }}
                                        required
                                        error={!!getErrorMessage('state')}
                                        errorMessage={getErrorMessage('state')}
                                    />
                                </FieldControl>
                            </Div>
                            <FieldControl>
                                <LabelControl>
                                    <Label props={{ htmlFor: 'PhoneNumber' }} required>
                                        Phone number
                                    </Label>
                                </LabelControl>
                                <PhoneNumber
                                    id="PhoneNumber"
                                    value={billingDetails.address?.country}
                                    onChange={val => setBillingDetails(prev => {
                                            return {
                                                ...prev,
                                                phone: val,
                                            };
                                        })
                                    }
                                    required
                                />
                            </FieldControl>
                            <FieldControl>
                                <Checkbox
                                    unCheckColor={'#eaeaea'}
                                    checkColor={'var(--dark-blue)'}
                                    fullWidth={true}
                                    label={
                                        <Fragment>
                                            Agree to{' '}
                                            <Span
                                                className="ANCGTC"
                                                onClick={() => setOpenTermsModal(true)}
                                            >
                                                Terms and Conditions
                                            </Span>
                                        </Fragment>
                                    }
                                    onChange={e => setAgreed(e.target.checked)}
                                    checked={agreed}
                                />
                            </FieldControl>
                            <Div className={'ANCBtn'}>
                                <Button
                                    buttonType={'BlueFillBtn'}
                                    fullWidth
                                    loading={processing}
                                    disabled={!agreed}
                                    onClick={handleSubmit}
                                >
                                    Add Card
                                </Button>
                            </Div>
                        </Div>
                    </Div>
                </Div>
            </Dialog>
        </Fragment>
    );
};

function App(props) {
    return (
        <Elements stripe={stripePromise}>
            <AddNewCardDialog {...props} />
        </Elements>
    );
}
AddNewCardDialog.propTypes = {
    open: PropTypes.bool.isRequired,
    handleClose: PropTypes.func.isRequired,
};

export default App;
