import React from 'react';

import { SVGIcon } from '../../UIKit/index';

export default function DCPay(props) {
    return (
        <SVGIcon {...props} viewBox="0 0 455.4 512">
            <path
                d="M285.5,270c74.4,0,135-60.6,135-135S359.9,0,285.5,0c-74.4,0-135,60.6-135,135S211.1,270,285.5,270z M285.5,150
	c-24.8,0-45-20.2-45-45c0-19.5,12.6-36,30-42.2V45c0-8.3,6.7-15,15-15c8.3,0,15,6.7,15,15v17.8c17.4,6.2,30,22.7,30,42.2
	c0,8.3-6.7,15-15,15c-8.3,0-15-6.7-15-15c0-8.3-6.7-15-15-15c-8.3,0-15,6.7-15,15s6.7,15,15,15c24.8,0,45,20.2,45,45
	c0,19.5-12.6,36-30,42.2V225c0,8.3-6.7,15-15,15c-8.3,0-15-6.7-15-15v-17.8c-17.4-6.2-30-22.7-30-42.2c0-8.3,6.7-15,15-15
	c8.3,0,15,6.7,15,15c0,8.3,6.7,15,15,15c8.3,0,15-6.7,15-15S293.8,150,285.5,150z"
            />
            <path
                d="M446.9,325.1c-11.7-11.7-30.9-11.4-42.3,0.6l-44.1,47.4C331.7,404,322.2,422,295.7,422H194c-8.4,0-15-6.6-15-15
	c0-8.4,6.6-15,15-15h76.5c16.6,0,30-13.4,30-30c0-16.6-13.4-30-30-30H230c-2-0.8-3.7-1.6-5.7-2.4c-39.6-39-102.3-40.2-143.1-4.2
	L39.5,362h-24c-8.4,0-15,6.6-15,15v120c0,8.3,6.7,15,15,15h270c33.1,0,64.2-15.6,84-42l80.1-106.8
	C458.3,351.8,457.1,335.3,446.9,325.1z"
            />
        </SVGIcon>
    );
}
