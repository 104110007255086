import React from 'react';

import { SVGIcon } from '../../UIKit/index';

export default function DCMapLocation(props) {
    return (
        <SVGIcon {...props} viewBox="0 0 512 477">
            <path d="M499.31,96.8C326.52,62.45,337.7,64.24,333.79,64.91c-1.26,0.21-16.59,3.29-52.32,10.43  C261.53,17.71,198.64-12.84,141.02,7.1c-32.05,11.09-57.23,36.3-68.28,68.36L19.9,64.97c-8.11-1.58-15.97,3.71-17.55,11.82  c-0.18,0.92-0.27,1.86-0.28,2.8v350.1c0.01,7.12,5.04,13.24,12.01,14.64c171.33,34.05,160.11,31.85,161.24,32.06  c3.61,0.45-7.52,2.29,160.94-31.42l157.22,31.25c8.11,1.58,15.97-3.71,17.55-11.82c0.18-0.93,0.27-1.87,0.28-2.82V111.42  c-0.01-7.12-5.04-13.24-12.01-14.64V96.8z M177.12,30.86c44.47,0.06,80.5,36.09,80.56,80.56c0,37.49-59.97,132.52-80.56,164.11  c-20.56-31.44-80.56-126.62-80.56-164.11C96.63,66.95,132.66,30.92,177.12,30.86z M32,97.69l35.03,6.97  c-0.13,2.14-0.21,4.45-0.21,6.69c0,24.88,16.27,65.04,49.76,122.75c19.56,33.71,38.81,62.62,45.72,72.82v136.36L32,417.46V97.69z   M192.13,306.92c6.9-10.2,26.18-39.13,45.72-72.82c33.47-57.71,49.76-97.87,49.76-122.75c0-2.29,0-4.57-0.21-6.82l34.03-6.82v319.75  l-129.31,25.86L192.13,306.92z M481.57,443.28l-130.38-25.9V97.69l130.38,25.9V443.28z" />
        </SVGIcon>
    );
}
