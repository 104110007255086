import { getAuthDetails, getCurrentDomain } from 'Utils/localStorageUtils';
import config from 'config';

export function upperCaseFirstLetter(string) {
    if (!string) {
        return '';
    }
    return string.charAt(0).toUpperCase() + string.slice(1);
}
export const addForwardSlash = url => {
    return (url += url.endsWith('/') ? '' : '/');
};

export const guessOauthUri = (type, baseURL, apiKey, subAccountId) => {
    try {
        const provider = type.toLowerCase().toString();
        const obj = getForwardUrlAndToken();
        let token = obj.token;
        let accountId = obj.accountId;
        const baseUri = `${config.REACT_APP_API_BASE_URL}/${process.env.REACT_APP_API_VERSION}`;
        const authLoginUri = process.env.REACT_APP_API_AUTH_LOGIN;
        const forwardUrl = getCurrentDomain() + '/oauth-landing';
        switch (provider) {
            case 'facebookads':
                let facebookUrl = `${baseUri}/${process.env.REACT_APP_API_INTEGRATIONS}/facebook/${authLoginUri}?account_id=${accountId}&token=${token}&forward_url=${forwardUrl}`;
                if (subAccountId) {
                    facebookUrl += `&subaccountid=${subAccountId}`;
                }
                return facebookUrl;

            case 'google_analytics':
                let googleUrl = `${baseUri}/e/google/analytics/${authLoginUri}?account_id=${accountId}&token=${token}&forward_url=${forwardUrl}`;
                if (subAccountId) {
                    googleUrl += `&subaccountid=${subAccountId}`;
                }
                return googleUrl;

            case 'google_ads':
                let googleAdUrl = `${baseUri}/e/google/ads/${authLoginUri}?account_id=${accountId}&token=${token}&forward_url=${forwardUrl}`;
                if (subAccountId) {
                    googleAdUrl += `&account=${subAccountId}`;
                }
                return googleAdUrl;

            case 'google_business':
                return `${baseUri}/e/google/business/auth/login?account_id=${accountId}&token=${token}&forward_url=${forwardUrl}`;

            case 'google':
                const googleCalendarApiUri = addForwardSlash(
                    process.env.REACT_APP_GOOGLE_CALENDAR_API_URI,
                );
                return `${googleCalendarApiUri}auth/login?account_id=${accountId}&token=${token}&forward_url=${forwardUrl}`;

            case 'outlook':
                const outlookCalendarApiUri = addForwardSlash(
                    process.env.REACT_APP_OUTLOOK_CALENDAR_API_URI,
                );
                return `${outlookCalendarApiUri}auth/login?account_id=${accountId}&token=${token}&forward_url=${forwardUrl}`;

            case 'pipedrive':
                const pipeDriveApiUri = addForwardSlash(
                    `${baseUri}/e/${process.env.REACT_APP_API_PIPEDRIVE}`,
                );

                return `${pipeDriveApiUri}auth/login?account_id=${accountId}&token=${token}&forward_url=${forwardUrl}`;
            case 'salesforce':
                const salesforceApiUri = addForwardSlash(
                    `${baseUri}/e/${process.env.REACT_APP_API_SALESFORCE}`,
                );
                return `${salesforceApiUri}${authLoginUri}?account_id=${accountId}&token=${token}&forward_url=${forwardUrl}`;
            case 'hubspot':
                const hubspotApiUri = addForwardSlash(
                    `${baseUri}/e/${process.env.REACT_APP_API_HUBSPOT}`,
                );
                return `${hubspotApiUri}${authLoginUri}?account_id=${accountId}&token=${token}&forward_url=${forwardUrl}`;
            case 'zoho':
                const zohoApiUri = addForwardSlash(
                    `${baseUri}/e/${process.env.REACT_APP_API_ZOHO}`,
                );
                return `${zohoApiUri}${authLoginUri}?account_id=${accountId}&token=${token}&forward_url=${forwardUrl}`;

            case 'keap':
                const keapApiUri = addForwardSlash(
                    `${baseUri}/e/${process.env.REACT_APP_API_KEAP}`,
                );
                return `${keapApiUri}${authLoginUri}?account_id=${accountId}&token=${token}&forward_url=${forwardUrl}`;
            case 'active_campaign':
                const activeCampaignApiUri = addForwardSlash(
                    `${baseUri}/e/${process.env.REACT_APP_API_ACTIVE_CAMPAIGN}`,
                );
                return `${activeCampaignApiUri}${authLoginUri}?base_url=${baseURL}&api_key=${apiKey}&account_id=${accountId}&token=${token}&forward_url=${forwardUrl}`;
            case 'mailchimp':
                const mailchimpApiUri = addForwardSlash(
                    `${baseUri}/e/${process.env.REACT_APP_API_MAILCHIMP}`,
                );
                return `${mailchimpApiUri}${authLoginUri}?account_id=${accountId}&token=${token}&forward_url=${forwardUrl}`;

            case 'constant_contact':
                const constantcontactApiUrl = addForwardSlash(
                    `${baseUri}/e/${process.env.REACT_APP_API_CONSTANT_CONTACT}`,
                );
                return `${constantcontactApiUrl}${authLoginUri}?account_id=${accountId}&token=${token}&forward_url=${forwardUrl}`;

            case 'tiktok':
                let tiktokUrl = `${baseUri}/e/tiktok/${authLoginUri}?account_id=${accountId}&token=${token}&forward_url=${forwardUrl}`;
                if (subAccountId) {
                    tiktokUrl += `&subaccountid=${subAccountId}`;
                }

                return tiktokUrl;

            default:
                return null;
        }
    } catch (error) {
        // eslint-disable-next-line no-console
        console.log(error);
    }
};

/**
 * Generate Api uri to import CRM data based on CRM data [like hubSpot, Salesforce... ] and selected data type [contacts, deals...]
 * @param {string} crmApiProvider - Source provider type [hubSpot, Salesforce... ]
 * @param {string} exportApiDataType - Data selected type  [contacts. deals...]
 * @returns {URI}  - url to send axios get request
 */
export const getProviderURIByType = (crmApiProvider, exportApiDataType = 'contacts') => {
    if (crmApiProvider) {
        const crmProvider = crmApiProvider.toLowerCase().toString();
        const exportDataType = exportApiDataType.toLowerCase().toString();
        let apiProviderOptions = null;
        const baseUri = `${config.REACT_APP_API_BASE_URL}/${process.env.REACT_APP_API_VERSION}`;

        switch (crmProvider) {
            /** Configure object to post request */
            case 'pipedrive':
                const basePipedriveUri = addForwardSlash(
                    `${baseUri}/e/${process.env.REACT_APP_API_PIPEDRIVE}`,
                );
                switch (exportDataType) {
                    case 'contacts':
                        return `${basePipedriveUri}export/persons`;
                    case 'companies':
                        return `${basePipedriveUri}export/organizations`;
                    case 'deals':
                        return `${basePipedriveUri}export/deals`;
                    case 'notes':
                        return `${basePipedriveUri}export/notes`;
                    default:
                        apiProviderOptions = null;
                }
                return apiProviderOptions;

            /** Configure object to post request */
            case 'salesforce':
                const baseSalesforceUri = addForwardSlash(
                    `${baseUri}/e/${process.env.REACT_APP_API_SALESFORCE}`,
                );
                switch (exportDataType) {
                    /** Creating contacts uri of salesforce */
                    case 'contacts':
                        return `${baseSalesforceUri}export/contacts`;
                    /** Creating deals uri of salesforce */
                    case 'deals':
                        return `${baseSalesforceUri}export/deals`;
                    /** Creating notes uri of salesforce */
                    case 'notes':
                        return `${baseSalesforceUri}export/notes`;
                    default:
                        apiProviderOptions = null;
                }
                break;
            case 'hubspot':
                const baseHubSpotUri = addForwardSlash(
                    `${baseUri}/e/${process.env.REACT_APP_API_HUBSPOT}`,
                );
                switch (exportDataType) {
                    /** Creating contacts uri of salesforce */
                    case 'contacts':
                        return `${baseHubSpotUri}export/contacts`;
                    /** Creating company uri of salesforce */
                    case 'companies':
                        return `${baseHubSpotUri}export/companies`;
                    /** Creating deals uri of salesforce */
                    case 'deals':
                        return `${baseHubSpotUri}export/deals`;
                    /** Creating notes uri of salesforce */
                    case 'notes':
                        return `${baseHubSpotUri}export/notes`;
                    default:
                        apiProviderOptions = null;
                }
                break;
            case 'zoho':
                const baseZohoUri = addForwardSlash(
                    `${baseUri}/e/${process.env.REACT_APP_API_ZOHO}`,
                );
                switch (exportDataType) {
                    /** Creating contacts uri of salesforce */
                    case 'contacts':
                        return `${baseZohoUri}export/contacts`;
                    /** Creating company uri of salesforce */
                    case 'companies':
                        return `${baseZohoUri}export/accounts`;
                    /** Creating deals uri of salesforce */
                    case 'deals':
                        return `${baseZohoUri}export/deals`;
                    // /** Creating notes uri of salesforce */
                    case 'notes':
                        return `${baseZohoUri}export/notes`;
                    default:
                        apiProviderOptions = null;
                }
                break;
            case 'keap':
                const baseKeapUri = addForwardSlash(
                    `${baseUri}/e/${process.env.REACT_APP_API_KEAP}`,
                );
                switch (exportDataType) {
                    /** Creating contacts uri of salesforce */
                    case 'contacts':
                        // TODO: use nv variables after
                        return `${baseKeapUri}export/contacts`;
                    /** Creating company uri of salesforce */
                    case 'companies':
                        return `${baseKeapUri}export/companies`;
                    /** Creating deals uri of salesforce */
                    case 'deals':
                        return `${baseKeapUri}export/opportunities`;
                    /** Creating notes uri of salesforce */
                    case 'notes':
                        return `${baseKeapUri}export/notes`;

                    default:
                        apiProviderOptions = null;
                }
                break;
            case 'active_campaign':
                const baseActiveCampaignUri = `${baseUri}/e/${process.env.REACT_APP_API_ACTIVE_CAMPAIGN}`;
                switch (exportDataType) {
                    /** Creating contacts uri of salesforce */
                    case 'contacts':
                        return `${baseActiveCampaignUri}/export/contacts`;

                    /** Creating company uri of salesforce */
                    case 'deals':
                        return `${baseActiveCampaignUri}/export/deals`;

                    /** Creating deals uri of salesforce */

                    default:
                        apiProviderOptions = null;
                }
                break;
            case 'mailchimp':
                const baseMailchimpUri = addForwardSlash(
                    `${baseUri}/e/${process.env.REACT_APP_API_MAILCHIMP}`,
                );
                switch (exportDataType) {
                    /** Creating contacts uri of salesforce */
                    case 'contacts':
                        return `${baseMailchimpUri}export`;
                    /** Creating company uri of salesforce */

                    default:
                        apiProviderOptions = null;
                }
                break;
            case 'constant_contact':
                const baseContantContact = addForwardSlash(
                    `${baseUri}/e/${process.env.REACT_APP_API_CONSTANT_CONTACT}`,
                );
                switch (exportDataType) {
                    /** Creating contacts uri of salesforce */
                    case 'contacts':
                        return `${baseContantContact}lists`;
                    /** Creating company uri of salesforce */
                    // case 'deals':
                    //     return `${baseMailchimpUri}export/deals`;
                    /** Creating deals uri of salesforce */

                    default:
                        apiProviderOptions = null;
                }
                break;
            default:
                return null;
        }
    }
};
/**
 * Get Forward Url and token from local storage
 */
export const getForwardUrlAndToken = () => {
    let userData = getAuthDetails();
    if (userData) {
        const token = userData.session_id;
        const accountId = userData.account.id;
        let forwardUrl = getCurrentDomain();
        return {
            token,
            forwardUrl,
            accountId,
        };
    } else {
        return {
            token: null,
            forwardUrl: null,
            accountId: null,
        };
    }
};

/**
 * Checks if two objects are equal
 * @param {object} object1
 * @param {object} object2
 * @returns {boolean}  - true if they both are equal
 */
export const shallowEqual = (object1, object2) => {
    const keys1 = Object.keys(object1 || {});
    const keys2 = Object.keys(object2 || {});

    if (keys1.length !== keys2.length) {
        return false;
    }

    for (let key of keys1) {
        if (object1[key] !== object2[key]) {
            return false;
        }
    }

    return true;
};

/*
 * @method readThreeKeysValues - This method returns 3 properties of same keys
 * @param {Array} data - Array of objects
 * @returns {Object} objects - Merged Object
 */
const readThreeKeysValues = data => {
    let object = {};
    for (let i = 0; i < data.length; i++) {
        for (let property in data[i]) {
            if (object[property] && object[property].length === 2) {
                return object;
            }
            if (object[property]) {
                object[property].push(data[i][property]);
            } else {
                object[property] = [data[i][property]];
            }
        }
    }
    return object;
};

/**
 *
 * @param {string} sourceProvider -integration service
 * @param {any} response - api response
 * @returns
 */
export const providerDataMap = (sourceProvider, response, type) => {
    if (response.data) {
        // Modify response objects like one key with three value
        let readThreeKeysValue = {},
            keyMap = {},
            dataPath = 'data';
        if (Array.isArray(response.data)) {
            readThreeKeysValue = readThreeKeysValues(response.data);
            switch (sourceProvider) {
                case 'pipedrive': {
                    if (type === 'contacts') {
                        readThreeKeysValue['email'] = readThreeKeysValue['email'].map(
                            x => x[0].value,
                        );
                        keyMap['email'] = 'email/0/value';
                        readThreeKeysValue['phone'] = readThreeKeysValue['phone'].map(
                            x => x[0].value,
                        );
                        keyMap['phone'] = 'phone/0/value';
                    }
                    break;
                }
                case 'constant_contact': {
                    readThreeKeysValue['email_address'] = readThreeKeysValue['email_address'].map(
                        x => x.address,
                    );
                    keyMap['email_address'] = 'email_address/address';
                    break;
                }
                case 'mailchimp': {
                    Object.entries(readThreeKeysValue['merge_fields'][0]).forEach(
                        ([key, value]) => {
                            if (typeof value === 'object') {
                                Object.entries(value).forEach(([key1, value1]) => {
                                    readThreeKeysValue[key1] = [value1];
                                    keyMap[key1] = `merge_fields/${key}/${key1}`;
                                });
                            } else {
                                readThreeKeysValue[key] = [value];
                                keyMap[key] = `merge_fields/${key}`;
                            }
                        },
                    );
                    delete readThreeKeysValue['merge_fields'];
                    break;
                }
                default:
            }
        } else {
            Object.keys(response.data).forEach(key => {
                if (key === 'contacts' && response.data.hasOwnProperty('contacts')) {
                    dataPath = "data['contacts']";
                    readThreeKeysValue = readThreeKeysValues(response.data.contacts);
                    switch (sourceProvider) {
                        case 'hubspot': {
                            readThreeKeysValue['email'] = readThreeKeysValue[
                                'identity-profiles'
                            ].map(x => {
                                return x[0]['identities'][0]['value'];
                            });
                            keyMap['email'] = 'identity-profiles/0/identities/0/value';
                            delete readThreeKeysValues['identity-profiles'];
                            let obj = {};
                            readThreeKeysValue['properties'].forEach(x => {
                                Object.keys(x).forEach(el => {
                                    if (obj[el]) {
                                        obj[el].push(x[el].value);
                                    } else {
                                        obj[el] = [x[el].value];
                                        keyMap[el] = `properties/${el}/value`;
                                    }
                                });
                            });
                            readThreeKeysValue = {
                                ...obj,
                                ...readThreeKeysValue,
                            };
                            break;
                        }
                        case 'keap': {
                            readThreeKeysValue['email'] = readThreeKeysValue['email_addresses'].map(
                                x => x[0].email,
                            );
                            readThreeKeysValue['phone'] = readThreeKeysValue['phone_numbers'].map(
                                x => x[0]?.number,
                            );
                            keyMap['email'] = 'email_addresses/0/email';
                            keyMap['phone'] = 'phone_numbers/0/number';
                            break;
                        }
                        case 'pipedrive': {
                            readThreeKeysValue['email'] = readThreeKeysValue['email'].map(
                                x => x[0].value,
                            );
                            keyMap['email'] = 'email/0/value';
                            break;
                        }
                        default:
                    }
                }
                if (
                    key === 'deals' ||
                    (key === 'opportunities' && response.data.hasOwnProperty('deals')) ||
                    response.data.hasOwnProperty('opportunities')
                ) {
                    switch (sourceProvider) {
                        case 'keap':
                            dataPath = "data['opportunities']";
                            readThreeKeysValue = readThreeKeysValues(response.data.opportunities);
                            keyMap['stage_id'] = 'stage/id';
                            readThreeKeysValue['stage_id'] = readThreeKeysValue['stage'].map(
                                el => el.id,
                            );
                            break;
                        case 'hubspot':
                            dataPath = "data['deals']";
                            readThreeKeysValue = readThreeKeysValues(response.data.deals);
                            let obj = {};
                            readThreeKeysValue['properties']?.forEach(x => {
                                Object.keys(x).forEach(el => {
                                    if (obj[el]) {
                                        obj[el].push(x[el].value);
                                    } else {
                                        obj[el] = [x[el].value];
                                        keyMap[el] = `properties/${el}/value`;
                                    }
                                });
                            });

                            readThreeKeysValue = { ...obj, ...readThreeKeysValue };
                            break;
                        default:
                            dataPath = "data['deals']";
                            readThreeKeysValue = readThreeKeysValues(response.data.deals);
                    }
                }
                if (key === 'companies' && response.data.hasOwnProperty('companies')) {
                    dataPath = "data['companies']";
                    readThreeKeysValue = readThreeKeysValues(response.data.companies);
                    switch (sourceProvider) {
                        case 'hubspot':
                            let obj = {};
                            readThreeKeysValue['properties']?.forEach(x => {
                                Object.keys(x).forEach(el => {
                                    if (obj[el]) {
                                        obj[el].push(x[el].value);
                                    } else {
                                        obj[el] = [x[el].value];
                                        keyMap[el] = `properties/${el}/value`;
                                    }
                                });
                            });
                            readThreeKeysValue = { ...obj, ...readThreeKeysValue };
                            break;
                        default:
                    }
                }
                if (key === 'notes' && response.data.hasOwnProperty('notes')) {
                    dataPath = "data['notes']";
                    readThreeKeysValue = readThreeKeysValues(response.data.notes);
                }
                if (key === 'results' && response.data.hasOwnProperty('results')) {
                    readThreeKeysValue = readThreeKeysValues(response.data.results);
                    let data = readThreeKeysValue;

                    readThreeKeysValue = readThreeKeysValues(data.engagement);
                    let notes = [];
                    let type = [];
                    let source = [];
                    let sourceId = [];
                    let typeOfData = readThreeKeysValue.type;
                    typeOfData.forEach((item, index) => {
                        if (item === 'NOTE') {
                            notes.push(readThreeKeysValue.bodyPreview[index]);
                            type.push(readThreeKeysValue.type[index]);
                            sourceId.push(readThreeKeysValue.sourceId[index]);
                            source.push(readThreeKeysValue.source[index]);
                        }
                    });
                    readThreeKeysValue = {
                        Notes: notes,
                        sourceId: sourceId,
                        source: source,
                        type: type,
                    };
                }
            });
        }
        // Set api response

        return { readThreeKeysValue, _keyMap: keyMap, dataPath };
    }
};

/**
 *
 * @param {number} percentage -percentage
 * @returns {string} grade for percentage
 */
export const calculateGrade = percentage => {
    if (percentage < 30) return 'F';
    if (percentage < 50) return 'D';
    if (percentage < 75) return 'C';
    if (percentage < 90) return 'B';
    return 'A';
};
/**
 *
 * @param {array} columns = all the columns which are present on the section
 * @param {array} error = error return by api
 * @returns {string} = Error message containing fiedl name
 */
export const getErrorField = (columns, error, defaultMessage) => {
    let message = defaultMessage || 'Failed to update details';

    if (Array.isArray(error)) {
        let field;
        const validation_results = error[0].validation_results;
        if (Array.isArray(validation_results)) {
            field = columns.find(el => el.accessor === validation_results[0].field);
        } else field = columns.find(el => el.accessor === error[0]?.field);

        if (field) {
            message = `Invalid value provided in ${field.Header} field`;
        }
    } else if (typeof error === 'string') {
        message = error;
    }
    return message;
};
