import React from 'react';

import { SVGIcon } from '../../UIKit/index';

export default function DCDeals(props) {
    return (
        <SVGIcon {...props} viewBox="0 0 512 512">
            <path d="M342,328.4c-12.1,0-22-8.5-22-19c0-10.5,9.9-19,22-19s22,8.5,22,19c0,5.5,4.5,10,10,10s10-4.5,10-10    c0-18.3-13.7-33.7-32-37.9v-5.2c0-5.5-4.5-10-10-10s-10,4.5-10,10v5.2c-18.3,4.2-32,19.6-32,37.9c0,21.5,18.8,39,42,39    c12.1,0,22,8.5,22,19s-9.9,19-22,19s-22-8.5-22-19c0-5.5-4.5-10-10-10s-10,4.5-10,10c0,18.3,13.7,33.7,32,37.9v4.3    c0,5.5,4.5,10,10,10s10-4.5,10-10v-4.3c18.3-4.2,32-19.6,32-37.9C384,345.9,365.1,328.4,342,328.4z" />
            <path d="M451.5,268.9c-13.1-20.4-31.5-36.6-53.2-47.1c-5-2.4-11-0.3-13.3,4.7c-2.4,5-0.3,11,4.7,13.3    C427.5,258,452,297,452,339c0,18-4.4,35.8-12.8,51.6c-2.6,4.9-0.7,10.9,4.1,13.5c1.5,0.8,3.1,1.2,4.7,1.2c3.6,0,7-1.9,8.8-5.3    c9.9-18.6,15.2-39.7,15.2-61C472,314,464.9,289.8,451.5,268.9z" />
            <path d="M357.7,209l-0.7,0c-5.5,0-10,4.5-10,10s4.5,10,10,10l0.6,0c0,0,0,0,0,0c5.5,0,10-4.4,10-10    C367.7,213.5,363.2,209,357.7,209z" />
            <path d="M294.4,438.2C256.5,420,232,381,232,339c0-18,4.4-35.8,12.8-51.6c2.6-4.9,0.7-10.9-4.1-13.5    c-4.9-2.6-10.9-0.7-13.5,4.1c-9.9,18.6-15.2,39.7-15.2,61c0,25,7.1,49.2,20.5,70.1c13.1,20.4,31.5,36.6,53.2,47.1    c1.4,0.7,2.9,1,4.3,1c3.7,0,7.3-2.1,9-5.7C301.5,446.6,299.4,440.6,294.4,438.2z" />
            <path d="M327,449l-0.6,0c-5.5,0-10,4.4-10,10c0,5.5,4.4,10,10,10l0.7,0c5.5,0,10-4.5,10-10S332.5,449,327,449z" />
            <path d="M387.4,175.2c-1.2-2.9-2.7-5.6-4.4-8.2h26c25.4,0,46-20.6,46-46s-20.6-46-46-46h-80.1    c5.1-7.4,8.1-16.4,8.1-26c0-25.4-20.6-46-46-46H46C20.6,3,0,23.6,0,49s20.6,46,46,46h80.1c-5.1,7.4-8.1,16.4-8.1,26s3,18.6,8.1,26    H100c-25.4,0-46,20.6-46,46c0,14.6,6.8,27.6,17.4,36C60.8,237.4,54,250.4,54,265c0,14.6,6.8,27.6,17.4,36    C60.8,309.4,54,322.4,54,337s6.8,27.6,17.4,36C60.8,381.4,54,394.4,54,409c0,25.4,20.6,46,46,46h117.9c31,33.2,75.2,54,124.2,54    c93.7,0,170-76.3,170-170C512,261,459.2,195.1,387.4,175.2z M409,95c14.3,0,26,11.7,26,26s-11.7,26-26,26h-64H164    c-14.3,0-26-11.7-26-26s11.7-26,26-26H409z M46,75c-14.3,0-26-11.7-26-26s11.7-26,26-26h245c14.3,0,26,11.7,26,26s-11.7,26-26,26    H46z M100,167h245c4,0,7.9,0.9,11.3,2.6c-4.7-0.4-9.5-0.6-14.3-0.6c-46.9,0-89.5,19.1-120.3,50H100c-14.3,0-26-11.7-26-26    S85.7,167,100,167z M100,239h104.6c-11.4,15.6-20.1,33.1-25.7,52H100c-14.3,0-26-11.7-26-26C74,250.7,85.7,239,100,239z M100,311    h74.3c-1.5,9.1-2.3,18.5-2.3,28c0,8.1,0.6,16.2,1.7,24H100c-14.3,0-26-11.7-26-26S85.7,311,100,311z M100,435    c-14.3,0-26-11.7-26-26s11.7-26,26-26h77.8c5,18.8,13.2,36.3,24,52H100z M342,489c-82.7,0-150-67.3-150-150s67.3-150,150-150    s150,67.3,150,150S424.7,489,342,489z" />
        </SVGIcon>
    );
}
