import React from 'react';

import { SVGIcon } from '../../UIKit/index';

export default function DCTaskList(props) {
    return (
        <SVGIcon {...props} viewBox="0 0 404 512">
            <g transform="translate(-46.937 0.101)">
                <g transform="translate(47.037 0)">
                    <g transform="translate(0 0)">
                        <path d="M352.18,66.92h-46.22V46.22c0-6.69-7.31-9.72-13.98-9.72h-35.82     c-7.92-22.86-29.98-37.75-54.14-36.53c-24.04-0.91-45.92,13.82-54.12,36.44h-35.27c-6.69,0-13.42,3.05-13.42,9.72v20.79h-46.2     C25.64,67.25,3.25,88.84,1.92,116.18v348.46c1.46,26.82,24.26,47.45,51.09,46.22h299.17c26.83,1.23,49.63-19.4,51.09-46.22     V116.16C401.93,88.83,379.55,67.25,352.18,66.92z M123.57,60.82h33.45c5.82-0.75,10.47-5.18,11.51-10.95     c3.61-15.7,17.4-26.95,33.5-27.35c15.92,0.48,29.47,11.74,32.85,27.31c1.1,5.98,6.09,10.48,12.16,10.95h34.67v48.69H123.57V60.82     z M379.03,464.66c-1.45,13.42-13.41,23.18-26.85,21.9H53.01c-13.43,1.27-25.4-8.48-26.85-21.9V116.16     C27.41,102.21,39,91.44,53.01,91.23h46.22v31.01c0.67,6.81,6.59,11.89,13.42,11.51h179.41c6.93,0.36,13-4.63,13.98-11.51V91.23     h46.14c14.01,0.21,25.6,10.97,26.85,24.93C379.03,116.16,379.03,464.66,379.03,464.66z" />
                        <path d="M138.77,271.25l-38.93,37.09l-16.42-17.03c-4.56-4.81-12.14-5.09-17.03-0.61     c-4.71,4.94-4.71,12.71,0,17.64l24.93,25.54c2.16,2.41,5.28,3.75,8.51,3.64c3.21-0.04,6.27-1.35,8.51-3.64l47.45-45.01     c0.25-0.23,0.48-0.46,0.71-0.71c4.31-4.67,4.02-11.95-0.64-16.26c-0.02-0.02-0.04-0.04-0.07-0.06     C151.24,267.04,143.66,266.78,138.77,271.25z" />
                        <path d="M330.36,298.01H190.48c-6.71,0-12.16,5.44-12.16,12.16s5.44,12.16,12.16,12.16h139.88     c6.71,0,12.16-5.44,12.16-12.16S337.07,298.01,330.36,298.01z" />
                        <path d="M330.36,200.7H190.48c-6.72,0-12.17,5.45-12.17,12.17c0,6.72,5.45,12.17,12.17,12.17h139.88     c6.72,0,12.17-5.45,12.17-12.17C342.53,206.15,337.08,200.7,330.36,200.7z" />
                        <path d="M138.77,368.56l-38.93,37.03l-16.42-17.03c-4.56-4.81-12.14-5.09-17.03-0.61     c-4.71,4.94-4.71,12.71,0,17.64l24.93,25.54c2.16,2.41,5.28,3.75,8.51,3.64c3.21-0.04,6.27-1.35,8.51-3.64l47.45-45.01     c0.25-0.23,0.48-0.46,0.71-0.71c4.31-4.67,4.02-11.95-0.64-16.26c-0.02-0.02-0.04-0.04-0.07-0.06     C151.22,364.31,143.65,364.07,138.77,368.56z" />
                        <path d="M330.36,395.31H190.48c-6.72,0-12.17,5.45-12.17,12.17c0,6.72,5.45,12.17,12.17,12.17h139.88     c6.72,0,12.17-5.45,12.17-12.17C342.53,400.76,337.08,395.31,330.36,395.31z" />
                        <path d="M72.5,256.64h72.38c0.42,0.02,0.83,0.02,1.25,0c6.35-0.32,11.23-5.72,10.92-12.07     c0-0.03,0-0.06,0-0.09V178.8c0-6.71-5.44-12.16-12.16-12.16c0,0,0,0,0,0H72.5c-6.72,0-12.16,5.44-12.16,12.16l0,0v65.68     c-0.02,0.42-0.02,0.83,0,1.25c0.32,6.35,5.72,11.23,12.07,10.92C72.44,256.64,72.47,256.64,72.5,256.64z M84.65,190.96h48.04     v41.35H84.65L84.65,190.96z" />
                    </g>
                </g>
            </g>
        </SVGIcon>
    );
}
