import React from 'react';

import { SVGIcon } from '../../UIKit/index';

export default function DCBookSearch(props) {
    return (
        <SVGIcon {...props} viewBox="0 0 512 512">
            <path d="M15,1.8c-8.3,0-15,6.7-15,15v344.8h195.9c16.2,0,32,5.3,45,15V71.7c0-38.6-31.3-70-70-70H15z   M180.9,316.5H60c-8.3,0-15-6.7-15-15c0-8.3,6.7-15,15-15h120.9c8.3,0,15,6.7,15,15C195.9,309.8,189.2,316.5,180.9,316.5z   M180.9,256.6H60c-8.3,0-15-6.7-15-15s6.7-15,15-15h120.9c8.3,0,15,6.7,15,15S189.2,256.6,180.9,256.6z M180.9,196.6H60  c-8.3,0-15-6.7-15-15c0-8.3,6.7-15,15-15h120.9c8.3,0,15,6.7,15,15C195.9,189.9,189.2,196.6,180.9,196.6z M180.9,136.7H60  c-8.3,0-15-6.7-15-15c0-8.3,6.7-15,15-15h120.9c8.3,0,15,6.7,15,15C195.9,130,189.2,136.7,180.9,136.7z M180.9,76.7H60  c-8.3,0-15-6.7-15-15s6.7-15,15-15h120.9c8.3,0,15,6.7,15,15S189.2,76.7,180.9,76.7z" />
            <path d="M195.9,391.5H0v45c0,8.3,6.7,15,15,15c0,0,0,0,0,0h225.9v-15  C240.8,411.6,220.7,391.5,195.9,391.5z" />
            <g transform="translate(9.527)">
                <path d="M261.3,335.2v41.3c9.7-7.3,20.9-12.1,32.9-14C281.7,355.4,270.6,346.1,261.3,335.2z" />
                <path d="M356.2,349.1c-52.4,0-94.9-42.5-94.9-94.9s42.5-94.9,94.9-94.9c52.4,0,94.9,42.5,94.9,94.9l0,0   C451.1,306.5,408.6,349,356.2,349.1z" />
                <g>
                    <path d="M306.3,391.5c-24.8,0-44.9,20.1-45,45v15h103l-21.8-60H306.3z" />
                    <path d="M502.1,436.5v-45H470l21.6,59.3C497.9,448.8,502.1,443,502.1,436.5z" />
                    <g>
                        <path d="M487.1,1.8H331.2c-38.6,0-70,31.3-70,70V173c45.1-52.1,124-57.8,176.1-12.6     s57.8,124,12.6,176.1l0,0l9.1,25h43V16.7C502.1,8.5,495.4,1.8,487.1,1.8C487.1,1.8,487.1,1.8,487.1,1.8z M442.2,76.7H321.2     c-8.3,0-15-6.7-15-15s6.7-15,15-15h120.9c8.3,0,15,6.7,15,15S450.4,76.7,442.2,76.7z" />
                    </g>
                </g>
                <g transform="translate(3.808 12.525)">
                    <path d="M365.7,365.8L407,479.2c6.1,15.4,23.6,22.9,39,16.8c14.7-5.9,22.4-22.2,17.4-37.3L422,345.3    C405.2,356.6,385.9,363.7,365.7,365.8z" />
                </g>
            </g>
        </SVGIcon>
    );
}
