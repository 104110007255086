import React from 'react';

import { SVGIcon } from '../../UIKit/index';

export default function DCBarChart2(props) {
    return (
        <SVGIcon {...props} viewBox="0 0 512 319">
            <g transform="translate(-693 -12291)">
                <rect x="694.37" y="12604.67" width="23.12" height="5.78" />
                <rect x="763.73" y="12413.94" width="23.12" height="196.52" />
                <rect x="1041.16" y="12419.72" width="23.12" height="190.74" />
                <rect x="1179.88" y="12546.87" width="23.12" height="63.58" />
                <rect x="833.09" y="12483.29" width="23.12" height="127.16" />
                <rect x="971.8" y="12483.29" width="23.12" height="127.16" />
                <rect x="902.45" y="12356.14" width="23.12" height="254.31" />
                <rect x="1110.52" y="12292.56" width="23.12" height="317.89" />
            </g>
        </SVGIcon>
    );
}
