import React from 'react';

import DCStarFill from '../../Assets/icons/DCStarFill';
import { Div } from '../../UIKit/index';
import PropTypes from 'prop-types';
import './style.scss';

const Rating = ({ max, value, emptyIcon, icon, onRatingChange, disabled }) => {
    return (
        <Div className={'RatingMain ' + (disabled ? 'disabled' : '')}>
            {Array.from(Array(max || 5).keys()).map(item => (
                <Div
                    key={item}
                    className={'ReviewsIcon ' + (value >= item + 1 ? 'selected' : '')}
                    onClick={() => {
                        return disabled ? () => {} : onRatingChange(item + 1);
                    }}
                >
                    {value >= item + 1 ? emptyIcon || <DCStarFill /> : icon || <DCStarFill />}
                </Div>
            ))}
        </Div>
    );
};

Rating.propTypes = {
    max: PropTypes.number,
    value: PropTypes.number,
    emptyIcon: PropTypes.element,
    icon: PropTypes.element,
    onRatingChange: PropTypes.func,
    disabled: PropTypes.bool,
};

export default Rating;
