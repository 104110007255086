import React from 'react';

import { SVGIcon } from '../../UIKit/index';

export default function DCSqHalfCheck(props) {
    return (
        <SVGIcon {...props} viewBox="0 0 512 448">
            <g transform="translate(0.1 0.1)">
                <path d="M261.03,266.96c-4.23,0-8.29-1.69-11.27-4.71l-95.78-95.78c-6.1-6.38-5.87-16.49,0.51-22.59   c6.18-5.9,15.9-5.9,22.07,0l84.51,84.51L483.88,5.59c6.38-6.1,16.49-5.87,22.59,0.51c5.9,6.17,5.9,15.9,0,22.07L272.32,262.33   c-2.99,3-7.05,4.7-11.29,4.71L261.03,266.96z" />
                <path d="M388.74,447.91H58.81c-32.32-0.03-58.51-26.22-58.54-58.54V59.54   C0.25,27.19,26.46,0.94,58.81,0.91c0,0,0,0,0,0h266.14c8.82-0.34,16.24,6.53,16.58,15.35c0.34,8.82-6.53,16.24-15.35,16.58   c-0.41,0.02-0.82,0.02-1.23,0H58.81c-14.72,0.03-26.64,11.98-26.61,26.7c0,0,0,0,0,0v329.83c0.03,14.69,11.92,26.58,26.61,26.61   h329.93c14.69-0.03,26.58-11.92,26.61-26.61V208.45c0.34-8.82,7.76-15.69,16.58-15.35c8.34,0.32,15.03,7.01,15.35,15.35v180.93   C447.25,421.69,421.06,447.89,388.74,447.91z" />
            </g>
        </SVGIcon>
    );
}
