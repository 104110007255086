import React from 'react';
import PropTypes from 'prop-types';
import { Div } from '../index';

import './style.scss';

const Badge = ({ customClass, children, badgeContent, onClick }) => (
    <Div className={`badgeRoot ${customClass || ''}`} onClick={onClick}>
        {children}
        {badgeContent ? <Div className="badgeContent">{badgeContent}</Div> : null}
    </Div>
);

Badge.propTypes = {
    customClass: PropTypes.string,
    children: PropTypes.element.isRequired,
    badgeContent: PropTypes.number,
    onClick: PropTypes.func,
};

export default Badge;
