import React from 'react';

import { SVGIcon } from '../../UIKit/index';

export default function DCAddDealTag(props) {
    return (
        <SVGIcon {...props} viewBox="0 0 507.52 507.439">
            <g transform="translate(-2.24 -2.28)">
                <path
                    d="M57,2.31H242.37a14.828,14.828,0,0,1,10.5,4.358L493.79,247.55a54.78,54.78,0,0,1,0,77.32L324.91,493.75a54.78,54.78,0,0,1-77.32,0L6.71,252.87a14.71,14.71,0,0,1-4.36-10.5V57A54.712,54.712,0,0,1,57.01,2.28ZM106.52,153a32.89,32.89,0,1,0,.09-46.51l-.09.08a32.69,32.69,0,0,0-.21,46.22Z"
                    fill="#005eff"
                />
                <path
                    d="M18.38,18.35l88.14,88.14h0A32.89,32.89,0,1,0,153,152.91h0L409.22,409.22,324.8,493.65a54.78,54.78,0,0,1-77.32,0L6.6,252.77a14.74,14.74,0,0,1-4.36-10.5V57a54.38,54.38,0,0,1,16-38.64Z"
                    fill="#3781ff"
                />
                <circle
                    cx="116.5"
                    cy="116.5"
                    r="116.5"
                    transform="translate(30.63 273.65)"
                    fill="#fff"
                />
                <circle
                    cx="99.22"
                    cy="99.22"
                    r="99.22"
                    transform="translate(47.91 290.93)"
                    fill="#d9eaff"
                />
                <path
                    d="M138.78,381.84H92.15c-3.42,0-6.17,4.92-6.17,8.34s2.8,8.2,6.17,8.2h110c3.37,0,6.13-4.83,6.15-8.2s-2.74-8.31-6.15-8.34H155.33"
                    fill="#005eff"
                />
                <circle
                    cx="116.47"
                    cy="116.47"
                    r="116.47"
                    transform="translate(30.69 273.64)"
                    fill="#fff"
                />
                <circle
                    cx="99.1"
                    cy="99.1"
                    r="99.1"
                    transform="translate(48.06 291.01)"
                    fill="#d9eaff"
                />
                <path
                    d="M202.22,381.85h-46.8V335.06c0-3.39-4.87-6.15-8.26-6.15h0c-3.39,0-8.26,2.76-8.26,6.15v46.79H92.32c-3.39,0-6.14,4.87-6.14,8.26s2.75,8.26,6.14,8.26H138.9v46.8c0,3.39,4.87,6.14,8.26,6.14s8.26-2.75,8.26-6.14h0v-46.8h46.8c3.39,0,6.14-4.87,6.14-8.26S205.61,381.85,202.22,381.85Z"
                    fill="#005eff"
                />
            </g>
        </SVGIcon>
    );
}
