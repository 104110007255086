import React from 'react';
import { UserInfo } from 'UIKit';

const Test = () => {
    return (
        <div>
            <UserInfo name={'Adhikansh Mittal'} image={undefined} />

            <UserInfo
                name={'Adhikansh Mittal'}
                image={'https://avatars.githubusercontent.com/u/42075023?v=4'}
            />
            <UserInfo
                name={'Adhikansh Mittal'}
                image={'https://avatars.githubusercontent.com/u/42075023?v=4'}
                email={'adhikasnhmittal@gmail.com'}
            />
            <UserInfo name={'Adhikansh Mittal'} email={'adhikanshmitta@gmail.com'} />
            <UserInfo email={'adhikanshmittal@gmail.com'} />
        </div>
    );
};

export default Test;
