import React, { useEffect, useState, useCallback, Fragment } from 'react';

import { Redirect, useHistory } from 'react-router-dom';
import { useQuery } from 'react-query';

import {
    Div,
    Image,
    Input,
    Checkbox,
    Button,
    Strong,
    Span,
    IconButton,
    ToolTip,
    CircularLoader,
} from 'UIKit/index';

import DCEnvelope from 'Assets/icons/DCEnvelope';
import DCLock from 'Assets/icons/DCLock';
import DCFlyArrow1 from 'Assets/icons/DCFlyArrow1';
import DCCrossClose from 'Assets/icons/DCCrossClose';
import DCBuilding from 'Assets/icons/DCBuilding';
import DCRightArrow from 'Assets/icons/DCRightArrow';
import DCEye from 'Assets/icons/DCEye';
import DCInfoCircle from 'Assets/icons/DCInfoCircle';
import DCVisibilityEye from 'Assets/icons/DCVisibilityEye';
import LoginDialog from './loginDialog/loginDialog';
import validator from 'Modules/Validator/index';

import useDebounce from '../../Hooks/useDebounce';

import { afterLogin } from 'Hooks/useLogin';
import { isAuthenticated, getCookie } from 'Utils/auth';
import { getBrandingData, getAccounts, login, sendForgetPasswordMail } from 'apis/login/index.api';
import { getDefaultPage } from 'Utils/localStorageUtils';

import log from 'Utils/logger';
import RightSideLogin from './RightSideLogin.jsx';
import usePubSub from 'Hooks/PubSub';
import './style.scss';
import { unregister } from 'serviceWorker';
import generateImage from 'Utils/generateImage';
import UseMediaQuery from 'UIKit/useMediaQuery';
import ForgetUsername from './ForgetUsername';
import config from 'config';

const getFaviconEl = () => {
    return document.getElementById('favicon');
};

const Login = props => {
    const matche575 = UseMediaQuery('(max-width: 575.98px)');
    const history = useHistory();
    const [loginDialog, setLoginDialog] = useState(false);
    const handleLoginDialog = useCallback(() => {
        setLoginDialog(prev => !prev);
    }, []);

    const [isLoading, setIsLoading] = useState(false); // Used for loading state throughout the login journey

    const [account, setAccount] = useState(null); // Used for storing the branding data.
    const [error, setError] = useState({
        email: null,
        authentication: null,
        phone: null,
    });

    const [credentials, setCredentials] = useState({
        account: null,
        accountName: null,
        email: null,
        password: null,
        remember: false,
    }); // Stores the info we'll need to send in the api request
    const [users, setUsers] = useState([]); // Stores found accounts associated with the user provided email address

    const [redirect, setRedirect] = useState(false);
    const [geoLocation, setGeoLocation] = useState({ lat: '', long: '' });
    const [forgetPassword, setForgetPassword] = useState(false);
    const [emailStatus, setEmailStatus] = useState(false);
    const [title, setTitle] = useState('Login');
    const [passwordVisible, setPasswordVisible] = useState(false);
    const [forgetPasswordEmail, setForgetPasswordEmail] = useState(false);

    const handlePasswordVisibilityToggle = () => {
        setPasswordVisible(prev => !prev);
    };

    const { publish } = usePubSub();

    /**
     * Redirect if already authenticated
     */

    useEffect(() => {
        if (isAuthenticated()) {
            setRedirect(true);
        }
    }, []);

    /**
     * Grab Users Location using Geolocation API
     */
    useEffect(() => {
        if ('geolocation' in navigator) {
            navigator.geolocation.getCurrentPosition(position => {
                setGeoLocation({
                    lat: position.coords.latitude,
                    long: position.coords.longitude,
                });
            });
        }
    }, []);

    /**
     * Get Account Branding
     */
    const { isError, data: brandingData } = useQuery(
        ['login', 'branding_data'],
        () => getBrandingData(getCookie('aid') ? getCookie('aid') : config.REACT_APP_TEST_AID),
        {
            onSuccess: data => {
                document.title = `Sign in | ${data.data?.name}`;
                const favicon = getFaviconEl();
                document.documentElement.style.setProperty(
                    '--dark-blue',
                    data.data?.colors?.secondary,
                );
                document.documentElement.style.setProperty(
                    '--blue-invert',
                    data.data?.colors?.font_primary,
                );

                unregister();
                favicon.setAttribute(
                    'href',
                    data.data?.images?.favicon?.key
                        ? generateImage(data.data?.images.favicon, null, true)
                        : 'https://s3.wasabisys.com/open/general/branding/dc_dashboard.ico',
                );
                setAccount(data.data);
                if (credentials.email) {
                    handleChange({
                        target: { name: 'email', value: credentials.email },
                        origin: 'custom',
                        accountId: data.data?.id,
                    });
                }
            },
        },
    );

    if (isError) {
        log.error('Account Branding API is not working!');
    }

    const doSearch = useCallback(async (val, accountId) => {
        try {
            setEmailTyping(true);
            let valid = validator.isEmail(val);

            if (!valid) {
                setError(e_valid => ({
                    ...e_valid,
                    email: 'Please enter a valid email.',
                }));
                setUsers([]);
                setEmailTyping(false);
                return;
            }
            setError(er => ({
                ...er,
                email: null,
            }));
            const res = await getAccounts(val.toLowerCase(), accountId);
            if (res.success) {
                setEmailTyping(false);
                if (res.data.length == 1) {
                    setCredentials(c => ({
                        ...c,
                        account: res.data[0].id,
                        accountName: res.data[0].name,
                    }));
                    setUsers([]);
                } else if (res.data.length == 0) {
                    setUsers([]);
                    setError({ email: 'No accounts found with the given email!' });
                } else {
                    setUsers(res.data);
                }
            }
        } catch (err) {
            setError(_e => ({
                ..._e,
                email: err.message,
            }));
            console.error(err);
        }
    }, []);

    const handleChange = async e => {
        const tab = e.target.name;
        const val = e.target.value;

        if (tab === 'remember') {
            setCredentials(p => ({
                ...p,
                remember: e.target.checked,
            }));
        }
        if (tab === 'password') {
            setCredentials(p => ({
                ...p,
                password: val,
            }));
        }
        if (tab === 'email') {
            if (credentials?.email?.toLowerCase() === val.toLowerCase()) {
                setCredentials(p => ({
                    ...p,
                    email: val,
                }));
            } else {
                setEmailTyping(true);
                setCredentials(p => ({
                    ...p,
                    email: val,
                    account: null,
                    accountName: null,
                }));
                setError(er => ({
                    ...er,
                    email: null,
                }));
                let accountId = account?.id;
                if (e.origin && e.origin === 'custom') {
                    accountId = e.accountId;
                }
                if (accountId) {
                    try {
                        setEmailTyping(false);
                        doSave(val, accountId);
                    } catch (err) {
                        setError(_e => ({
                            ..._e,
                            email: err.message,
                        }));
                        console.error(err);
                    }
                }
            }
        }
    };

    const doSave = useDebounce(doSearch, 1000);

    const [emailTyping, setEmailTyping] = useState(false);
    /**
     * Handles typing changes in the login form
     * @param {HTMLElement} e DOM element on which the change event was fired
     */

    /**
     * Handle the user selection of an account to log into.
     * @param {String} id The objectid string of the selected account.
     */
    const handleAccountSelection = _account => {
        setCredentials(c => ({
            ...c,
            account: _account.id,
            accountName: _account.name,
        }));
    };

    /**
     * Generate JSX for the account list. This list is used if the provided email yields more than one account that can be logged into.
     * @returns {JSX} JSX element
     */
    const generateUsersList = useCallback(() => {
        return users.map(u => {
            return (
                <Div onClick={() => handleAccountSelection(u)} className={'DCLASItem'} key={u.id}>
                    <Div className={'DCLASIIcon'}>
                        <DCBuilding />
                    </Div>
                    <Div className={'DCLASITitle'}>
                        {u.name} <Strong>({u.id.slice(-8)})</Strong>
                    </Div>
                    <Div className={'DCLASITitleBtn'}>
                        <Div className={'DCLASITBText'}>Open</Div>
                        <Div className={'DCLASITBIcon'}>
                            <DCRightArrow />
                        </Div>
                    </Div>
                </Div>
            );
        });
    }, [users]);

    /**
     * This method handles the login api request
     */
    const handleLogin = async () => {
        setIsLoading(true);
        try {
            const res = await login({
                account: credentials.account,
                accountName: credentials.accountName,
                password: credentials.password,
                remember: credentials.remember,
                email: credentials.email?.toLowerCase(),
                geo: geoLocation,
                timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
                lang: navigator.language,
            });

            if (res.success) {
                const lRes = JSON.parse(localStorage.getItem('auth-user')) || {};
                const newSessionId = res.data.session_id;
                lRes[newSessionId] = { auth: { ...res.data, authenticated: true } };
                sessionStorage.setItem('active-session', newSessionId);
                localStorage.setItem('auth-user', JSON.stringify(lRes));
                publish('login-success', { ...res.data, authenticated: true });
                afterLogin(res.data);

                let url = props.location.search.substr(1).split('=');
                if (url.length) {
                    url = url[1];
                }
                history.push(url ? url : '/' + getDefaultPage());
            }
        } catch (err) {
            setError(e => ({
                ...e,
                authentication:
                    err?.response?.data?.additional_info ||
                    'Wrong password. Try again or click Forgot password to reset it.',
            }));
            console.error(err);
        }
        setIsLoading(false);
    };

    /**
     * Handle a press of the "Enter" key
     * @param {HTMLElement} e DOM element on which the keyup event was fired on
     */
    const handleEnter = e => {
        if (e.keyCode === 13 && credentials.account && credentials.password) {
            handleLogin();
        }
    };

    const handleSwitchAccount = () => {
        setCredentials(c => ({
            ...c,
            account: null,
            accountName: null,
        }));
    };

    const handleForgetPassword = () => {
        setTitle('Forget Password');
        setForgetPassword(true);
    };

    const handleReset = async () => {
        setIsLoading(true);
        try {
            await sendForgetPasswordMail({
                account: credentials.account,
                email: credentials.email,
                type: 'email',
            });
            setEmailStatus(true);
        } catch (err) {
            if (err?.response.data.message == 'USER_NOT_FOUND') {
                setEmailStatus(true); // Set this to true eventhough it's an error. This is to avoid volunteering information. Will treat an invalid email as a valid one to hinder some attacks.
            } else {
                setEmailStatus(false);
            }
            console.error(err);
        }
        setIsLoading(false);
    };

    if (redirect) {
        let url = props.location.search.substr(1).split('=');
        if (url.length) {
            url = url[1];
        }
        return <Redirect to={url ? url : '/' + getDefaultPage()} />;
    }

    const handleInputUI = () => {
        if (credentials.account && !emailStatus) {
            if (forgetPassword) {
                return (
                    <Div className="LPIFSIFBFSBtn">
                        <Button
                            buttonClass={isLoading && 'LPIFSIFBFSBtnLoad'}
                            fullWidth
                            buttonType={'BlueFillBtn'}
                            onClick={handleReset}
                            loading={isLoading}
                            disabled={isLoading || credentials.password?.length <= 0}
                        >
                            {`${!isLoading && 'Send Mail'}`}
                        </Button>
                    </Div>
                );
            } else {
                return (
                    <Fragment>
                        <Div className="LPIFSIFBPassword">
                            <Div className={'LPIFSIFBPIcon'}>
                                <DCLock />
                            </Div>
                            <Input
                                name="password"
                                type={passwordVisible ? 'text' : 'password'}
                                placeholder="Password"
                                required={true}
                                id="password"
                                onChange={handleChange}
                                value={credentials.password}
                                onKeyUp={handleEnter}
                                error={error.authentication}
                                autoFocus
                            />
                            <Div
                                className={'LPIFSIFBPRightIcon'}
                                onClick={handlePasswordVisibilityToggle}
                            >
                                {passwordVisible ? <DCEye /> : <DCVisibilityEye />}
                            </Div>
                        </Div>
                        <Div className="LPIFSIFBFRF">
                            <Div className="LPIFSIFBFRFRM">
                                {matche575 ? (
                                    <Div className="LPIMobTooltip">
                                        <Checkbox
                                            name="remember"
                                            unCheckColor={'#f6f8fc'}
                                            checkColor={'#f6f8fc'}
                                            label={'Remember me'}
                                            onChange={handleChange}
                                            checked={credentials.remember}
                                            checkboxStyle={{
                                                backgroundColor:
                                                    brandingData?.data.colors.font_primary,
                                            }}
                                            style={{
                                                color: brandingData?.data.colors.font_primary,
                                            }}
                                        />
                                        <ToolTip
                                            content={'You will be remembered for 1 week.'}
                                            placement={'right-center'}
                                        >
                                            <DCInfoCircle />
                                        </ToolTip>
                                    </Div>
                                ) : (
                                    <ToolTip
                                        content={'You will be remembered for 1 week.'}
                                        placement={'right-center'}
                                    >
                                        <Checkbox
                                            name="remember"
                                            unCheckColor={'#f6f8fc'}
                                            checkColor={'#f6f8fc'}
                                            label={'Remember me'}
                                            onChange={handleChange}
                                            checked={credentials.remember}
                                            checkboxStyle={{
                                                backgroundColor:
                                                    brandingData?.data.colors.font_primary,
                                            }}
                                            style={{
                                                color: brandingData?.data.colors.font_primary,
                                            }}
                                        />
                                    </ToolTip>
                                )}
                            </Div>
                            {!forgetPasswordEmail && (
                                <Div
                                    className="LPIFSIFBFRFFP"
                                    onClick={handleForgetPassword}
                                    style={{
                                        color: brandingData?.data.colors.font_primary,
                                    }}
                                >
                                    Forgot Password?
                                </Div>
                            )}
                        </Div>
                        <Div className="LPIFSIFBFSBtn">
                            <Button
                                buttonClass={isLoading && 'LPIFSIFBFSBtnLoad'}
                                fullWidth
                                buttonType={'BlueFillBtn'}
                                onClick={handleLogin}
                                loading={isLoading}
                                disabled={isLoading || credentials.password?.length <= 0}
                                btnColor={brandingData?.data?.colors?.secondary}
                                style={{
                                    backgroundColor: brandingData?.data?.colors?.secondary,
                                    borderColor: brandingData?.data?.colors?.secondary,
                                }}
                            >
                                {!isLoading ? 'Log in' : ''}
                            </Button>
                        </Div>
                        {error.authentication && (
                            <Div className="LPIForgetBtn">
                                <Span>{error.authentication}</Span>
                                <IconButton
                                    onClick={() => {
                                        setError({ authentication: null });
                                    }}
                                >
                                    <DCCrossClose />
                                </IconButton>
                            </Div>
                        )}
                    </Fragment>
                );
            }
        }
    };

    return (
        <Fragment>
            <LoginDialog open={loginDialog} handleClose={handleLoginDialog} />
            <Div className="LoginPage">
                <Div className="LoginPageInner">
                    <Div
                        className="LPIFormSection"
                        style={{ backgroundColor: brandingData?.data?.colors?.primary }}
                    >
                        <Div className="LPIFSInner">
                            <Div className="LPIFSIHeader">
                                <Div className="LPIFSIHLogo">
                                    <Image
                                        src={generateImage(brandingData?.data?.image, null, true)}
                                        alt={brandingData?.data?.name}
                                    />
                                </Div>
                            </Div>
                            <Div className="LPIFSIBody">
                                {emailStatus ? (
                                    <Div className="LPIMailSent">
                                        <Div className="LPIMailSIcon">
                                            <DCFlyArrow1 />
                                        </Div>
                                        <Div className="LPIMailSTitle">An email has been sent.</Div>
                                        <Div className="LPIMailSSubTitle">
                                            Please check your inbox.
                                        </Div>

                                        <Button
                                            buttonClass={'LPIFSIFBFSBtnLoad'}
                                            fullWidth
                                            buttonType={'BlueFillBtn'}
                                            onClick={() => {
                                                setForgetPassword(false);
                                                setEmailStatus(false);
                                            }}
                                        >
                                            Return to login.
                                        </Button>

                                        <Div className="LPIMailSSubTitle" />
                                    </Div>
                                ) : (
                                    <Div className="LPIFSIFormBox">
                                        <Div className="LPIFSIFBTitle">
                                            <Div
                                                className="LPIFSIFBTText"
                                                style={{
                                                    color: brandingData?.data?.colors?.font_primary,
                                                }}
                                            >
                                                {title}
                                            </Div>
                                        </Div>
                                        <Div className="LPIFSIFBForm">
                                            <Div className="LPIFSIFBFMail">
                                                {forgetPasswordEmail ? (
                                                    <ForgetUsername
                                                        account={account}
                                                        setUsers={setUsers}
                                                        setError={setError}
                                                        setForgetPasswordEmail={
                                                            setForgetPasswordEmail
                                                        }
                                                        setCredentials={setCredentials}
                                                        handleChangeEvent={handleChange}
                                                    />
                                                ) : (
                                                    <>
                                                        <Div className="LPIFSIFBFMIcon">
                                                            <DCEnvelope />
                                                        </Div>

                                                        <Input
                                                            name="email"
                                                            type="email"
                                                            placeholder={'Email address'}
                                                            autoFocus={true}
                                                            required={true}
                                                            id="email"
                                                            onChange={handleChange}
                                                            errorMessage={error.email}
                                                            value={credentials?.email}
                                                            onKeyUp={handleEnter}
                                                            error={error.email}
                                                        />
                                                    </>
                                                )}

                                                {emailTyping && (
                                                    <Div className="LPIFSIFBFMLoader">
                                                        <CircularLoader />
                                                    </Div>
                                                )}
                                            </Div>
                                            {!credentials?.accountName && (
                                                <Div className={'LPIFSIFBSelected'}>
                                                    <Div className="LPIFSIFBFRF" />
                                                    {!forgetPasswordEmail ? (
                                                        <Div
                                                            className="LPIFSIFBFRFFP ForgetClass"
                                                            onClick={() => {
                                                                setForgetPasswordEmail(true);
                                                                setError({
                                                                    email: null,
                                                                });
                                                            }}
                                                            style={{
                                                                color: brandingData?.data.colors
                                                                    .font_primary,
                                                            }}
                                                        >
                                                            Forgot email?
                                                        </Div>
                                                    ) : (
                                                        <Div
                                                            className="LPIFSIFBFRFFP ForgetClass"
                                                            onClick={() => {
                                                                setForgetPasswordEmail(false);
                                                                setError({
                                                                    phone: null,
                                                                    email: null,
                                                                });
                                                            }}
                                                            style={{
                                                                color: brandingData?.data.colors
                                                                    .font_primary,
                                                            }}
                                                        >
                                                            Login with email
                                                        </Div>
                                                    )}
                                                </Div>
                                            )}
                                            {error.email && (
                                                <Div className="LPIFSIFBFError">
                                                    <Span>{error.email}</Span>
                                                    <IconButton
                                                        onClick={() => {
                                                            setError({ email: null });
                                                        }}
                                                    >
                                                        <DCCrossClose />
                                                    </IconButton>
                                                </Div>
                                            )}
                                            {error.phone && (
                                                <Div className="LPIFSIFBFError">
                                                    <Span>{error.phone}</Span>
                                                    <IconButton
                                                        onClick={() => {
                                                            setError({ phone: null });
                                                        }}
                                                    >
                                                        <DCCrossClose />
                                                    </IconButton>
                                                </Div>
                                            )}
                                            {credentials?.accountName ? (
                                                <Div className={'LPIFSIFBSelected'}>
                                                    <Div
                                                        className={'LPIFSIFBSA'}
                                                        style={{
                                                            color: brandingData?.data.colors
                                                                .font_primary,
                                                        }}
                                                    >
                                                        {credentials.accountName}
                                                    </Div>

                                                    {users?.length > 1 && credentials.account && (
                                                        <Div
                                                            className={'LPIFSIFBSBtn'}
                                                            onClick={handleSwitchAccount}
                                                            style={{
                                                                color: brandingData?.data.colors
                                                                    .font_primary,
                                                            }}
                                                        >
                                                            Switch Account
                                                        </Div>
                                                    )}
                                                </Div>
                                            ) : null}
                                            {!credentials?.account && users?.length > 0 ? (
                                                <Div className={'LPIFSIFBAccounts'}>
                                                    <Div className={'LPIFSIFBEmail'}>
                                                        Logins for{' '}
                                                        <Strong>{credentials.email}</Strong>
                                                    </Div>
                                                    <Div className={'LPIFSIFBAList'}>
                                                        {generateUsersList()}
                                                    </Div>
                                                </Div>
                                            ) : (
                                                <Fragment>{handleInputUI()}</Fragment>
                                            )}
                                        </Div>
                                    </Div>
                                )}
                            </Div>
                        </Div>
                    </Div>
                    <RightSideLogin
                        image={
                            brandingData?.data?.images?.login_image?.key
                                ? generateImage(brandingData?.data?.images?.login_image, null, true)
                                : null
                        }
                        hideFloatingApps={brandingData?.data?.hide_floating_apps}
                    />
                </Div>
            </Div>
        </Fragment>
    );
};

export default Login;
