import React from 'react';

import { SVGIcon } from '../../UIKit/index';

export default function DCIce(props) {
    return (
        <SVGIcon {...props} viewBox="0 0 512 512">
            <g>
                <path d="m364.619 420.589h42.09c12.354 0 22.406-10.051 22.406-22.405v-49.232c0-4.142-3.358-7.5-7.5-7.5-4.143 0-7.5 3.358-7.5 7.5v49.232c0 4.083-3.322 7.405-7.406 7.405h-42.09c-4.143 0-7.5 3.358-7.5 7.5s3.357 7.5 7.5 7.5z" />
                <path d="m111.042 351.022c-4.142 0-7.5 3.358-7.5 7.5v42.09c0 12.354 10.051 22.405 22.405 22.405h49.232c4.142 0 7.5-3.358 7.5-7.5s-3.358-7.5-7.5-7.5h-49.232c-4.084 0-7.405-3.322-7.405-7.405v-42.09c0-4.142-3.358-7.5-7.5-7.5z" />
                <path d="m222.72 97.122c-12.354 0-22.405 10.051-22.405 22.406v49.232c0 4.142 3.358 7.5 7.5 7.5s7.5-3.358 7.5-7.5v-49.232c0-4.084 3.322-7.406 7.405-7.406h42.09c4.143 0 7.5-3.358 7.5-7.5s-3.357-7.5-7.5-7.5z" />
                <path d="m504.5 438.574h-46.289c3.749-5.346 5.958-11.847 5.958-18.857v-138.753c0-18.157-14.771-32.928-32.928-32.928h-66.709c2.776-4.624 4.376-10.03 4.376-15.804v-37.963c0-4.142-3.357-7.5-7.5-7.5s-7.5 3.358-7.5 7.5v37.963c0 8.714-7.09 15.804-15.804 15.804h-12.316-45.125c-4.143 0-7.5 3.358-7.5 7.5v58.57c0 4.065-3.307 7.371-7.371 7.371-4.065 0-7.372-3.307-7.372-7.371v-58.57c0-4.142-3.357-7.5-7.5-7.5h-21.997c-4.142 0-7.5 3.358-7.5 7.5v26.084c0 4.065-3.307 7.372-7.371 7.372-4.065 0-7.372-3.307-7.372-7.372v-26.084c0-4.142-3.358-7.5-7.5-7.5h-4.076c-8.714 0-15.804-7.09-15.804-15.804v-143.001c0-8.714 7.09-15.804 15.804-15.804h143.001c8.714 0 15.804 7.09 15.804 15.804v75.038c0 4.142 3.357 7.5 7.5 7.5s7.5-3.358 7.5-7.5v-75.038c0-16.985-13.818-30.804-30.804-30.804h-143.001c-16.985 0-30.804 13.819-30.804 30.804v143.001c0 5.775 1.603 11.18 4.379 15.804h-66.255c-18.157 0-32.928 14.771-32.928 32.928v39.376c0 4.142 3.358 7.5 7.5 7.5s7.5-3.358 7.5-7.5v-39.376c0-9.886 8.043-17.928 17.928-17.928h89.256v18.584c0 12.336 10.036 22.372 22.372 22.372s22.371-10.036 22.371-22.372v-18.584h6.997v51.07c0 10.173 6.828 18.776 16.141 21.482v81.57c-.29.799-.457 1.658-.457 2.558 0 9.886-8.043 17.928-17.928 17.928h-138.752c-9.886 0-17.928-8.042-17.928-17.928v-69.376c0-4.142-3.358-7.5-7.5-7.5s-7.5 3.358-7.5 7.5v69.376c0 7.011 2.209 13.512 5.959 18.857h-67.955c-4.142 0-7.5 3.358-7.5 7.5s3.358 7.5 7.5 7.5h497c4.143 0 7.5-3.358 7.5-7.5s-3.357-7.499-7.5-7.499zm-216.336-124.467v-51.07h143.078c9.886 0 17.928 8.043 17.928 17.928v138.752c0 9.886-8.042 17.928-17.928 17.928h-138.752c-9.886 0-17.929-8.042-17.929-17.928v-85.03c7.99-3.418 13.603-11.355 13.603-20.58z" />
            </g>
        </SVGIcon>
    );
}
