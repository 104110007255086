import React from 'react';

import { SVGIcon } from '../../UIKit/index';

export default function DCClassicForm(props) {
    return (
        <SVGIcon {...props} viewBox="0 0 512 463">
            <g transform="translate(-769 -273.653)">
                <g transform="translate(769 249.653)">
                    <path
                        fill="#D9EAFF"
                        d="M502.1,26H9.3c-4.39,0-7.95,3.56-7.95,7.95c0,0,0,0,0,0v445.11c0,4.39,3.56,7.95,7.95,7.95    c0,0,0,0,0,0h492.8c4.39,0,7.95-3.56,7.95-7.95c0,0,0,0,0,0V33.95C510.05,29.56,506.49,26,502.1,26L502.1,26z"
                    />
                    <path
                        fill="#C2D6EF"
                        d="M25.19,479.06V33.95c0-4.39,3.56-7.95,7.95-7.95l0,0H9.3c-4.39,0-7.95,3.56-7.95,7.95    c0,0,0,0,0,0v445.11c0,4.39,3.56,7.95,7.95,7.95c0,0,0,0,0,0h23.84C28.75,487.01,25.19,483.45,25.19,479.06L25.19,479.06z"
                    />
                    <path
                        fill="#005EFF"
                        d="M502.1,26H9.3c-4.39,0-7.95,3.56-7.95,7.95c0,0,0,0,0,0v63.57h508.7V33.95    C510.05,29.56,506.49,26,502.1,26z"
                    />
                    <path
                        fill="#005EFF"
                        d="M33.14,26H9.3c-4.39,0-7.95,3.56-7.95,7.95c0,0,0,0,0,0v63.57h23.84V33.95    C25.19,29.56,28.75,26,33.14,26C33.14,26,33.14,26,33.14,26z"
                    />
                    <path
                        fill="#005EFF"
                        d="M382.88,184.97H128.52c-4.39,0-7.95,3.56-7.95,7.95v158.97c0,4.39,3.56,7.95,7.95,7.95h254.36    c4.39,0,7.95-3.56,7.95-7.95V192.92C390.83,188.53,387.27,184.97,382.88,184.97z"
                    />
                    <rect fill="#FFFFFF" x="138.43" y="200.96" width="234.54" height="141.96" />
                    <g transform="translate(64.07 29.151)">
                        <path
                            fill="#FFFFFF"
                            d="M374.44,44.54h-0.99c-4.39,0-7.95-3.56-7.95-7.95c0-4.39,3.56-7.95,7.95-7.95h0.99     c4.39,0,7.95,3.56,7.95,7.95C382.39,40.98,378.83,44.54,374.44,44.54L374.44,44.54z"
                        />
                        <path
                            fill="#FFFFFF"
                            d="M406.24,44.54h-0.99c-4.39,0-7.95-3.56-7.95-7.95c0-4.39,3.56-7.95,7.95-7.95h0.99     c4.39,0,7.95,3.56,7.95,7.95C414.19,40.98,410.63,44.54,406.24,44.54L406.24,44.54z"
                        />
                        <path
                            fill="#FFFFFF"
                            d="M341.66,44.54h-0.99c-4.39,0-7.95-3.56-7.95-7.95c0-4.39,3.56-7.95,7.95-7.95h0.99     c4.39,0,7.95,3.56,7.95,7.95C349.61,40.98,346.05,44.54,341.66,44.54z"
                        />
                    </g>
                </g>
            </g>
        </SVGIcon>
    );
}
