import React from 'react';

import { SVGIcon } from '../../UIKit/index';

export default function DCPlans(props) {
    return (
        <SVGIcon {...props} viewBox="0 0 512 512">
            <g>
                <path d="M137.5,271.1H316c8.2,0,14.9-6.7,14.9-14.9V76.8c0-8.2-6.7-14.9-14.9-14.9h-44.6V47  c0-24.7-19.9-44.7-44.6-44.7s-44.7,19.9-44.7,44.6c0,0,0,0.1,0,0.1v14.9h-44.6c-8.2,0-14.9,6.7-14.9,14.9v179.5  C122.6,264.5,129.3,271.1,137.5,271.1z M211.9,47c0-8.2,6.7-14.9,14.9-14.9c8.2,0,14.9,6.7,14.9,14.9v14.9h-29.8V47z" />
                <path d="M443.4,248.5c-2.7-4.5-7.5-7.2-12.8-7.2c-26.2,0-50.2,14.5-62.4,37.8l-45.6,87.2  c-7.7,14.7-22.9,23.9-39.5,23.9H182c-8.2,0-14.9-6.7-14.8-14.9c0-8.2,6.7-14.8,14.8-14.8h101c5.5,0,10.6-3.1,13.2-8l17-32.5  c3.3-6.3,0.9-14.2-5.5-17.5c-1.9-1-3.9-1.5-6-1.5H147.2c-19.7-0.1-38.7,7.8-52.6,21.8l-88,87.9c-5.8,5.8-5.8,15.2,0,21l74.4,74.1  c5.8,5.8,15.2,5.8,21,0l56.5-56h124.6c38.7,0.1,74.3-21.5,92.2-55.8l68.5-130.6c2.4-4.6,2.3-10.1-0.4-14.6V248.5z" />
            </g>
        </SVGIcon>
    );
}
