import React from 'react';

import { SVGIcon } from '../../UIKit/index';

export default function DCUpArrow(props) {
    return (
        <SVGIcon {...props} viewBox="0 0 511.996 511.996">
            <g>
                <path d="M441.154,176.9L261.954,2.419c-3.311-3.226-8.593-3.226-11.904,0L70.85,176.9c-1.655,1.604-2.586,3.806-2.586,6.11    c0,2.304,0.939,4.506,2.586,6.11l48.666,47.386c3.319,3.243,8.61,3.234,11.921-0.017l81.894-80.299v347.273    c0,4.71,3.823,8.533,8.533,8.533h68.267c4.71,0,8.533-3.823,8.533-8.533V156.19l81.894,80.299c3.311,3.251,8.61,3.243,11.93,0.017    l48.666-47.386c1.647-1.604,2.577-3.806,2.577-6.11C443.731,180.706,442.801,178.505,441.154,176.9z" />
            </g>
        </SVGIcon>
    );
}
