import React from 'react';

import { SVGIcon } from '../../UIKit/index';

export default function DCAllCall(props) {
    return (
        <SVGIcon {...props} viewBox="0 0 30.197 30.694">
            <g>
                <path
                    d="M25.764,45.487a3.166,3.166,0,0,0-2.3-1.053,3.274,3.274,0,0,0-2.324,1.046L19,47.62c-.177-.1-.353-.183-.523-.272-.245-.122-.476-.238-.673-.36a23.336,23.336,0,0,1-5.592-5.1A13.768,13.768,0,0,1,10.374,39c.557-.51,1.074-1.04,1.576-1.549.19-.19.381-.387.571-.578a3.117,3.117,0,0,0,0-4.7l-1.855-1.855c-.211-.211-.428-.428-.632-.645-.408-.421-.836-.856-1.277-1.264a3.216,3.216,0,0,0-2.276-1,3.328,3.328,0,0,0-2.31,1l-.014.014-2.31,2.331a4.971,4.971,0,0,0-1.474,3.16,11.911,11.911,0,0,0,.87,5.042,29.256,29.256,0,0,0,5.2,8.67,31.975,31.975,0,0,0,10.647,8.337,16.589,16.589,0,0,0,5.979,1.767c.143.007.292.014.428.014a5.12,5.12,0,0,0,3.921-1.685c.007-.014.02-.02.027-.034a15.446,15.446,0,0,1,1.189-1.23c.292-.279.591-.571.883-.877a3.39,3.39,0,0,0,1.026-2.351,3.265,3.265,0,0,0-1.046-2.331ZM28.2,52.642c-.007,0-.007.007,0,0-.265.285-.537.544-.829.829a17.867,17.867,0,0,0-1.311,1.359A3.276,3.276,0,0,1,23.5,55.91c-.1,0-.211,0-.313-.007a14.737,14.737,0,0,1-5.3-1.59A30.183,30.183,0,0,1,7.86,46.458a27.585,27.585,0,0,1-4.892-8.147,9.7,9.7,0,0,1-.761-4.253,3.119,3.119,0,0,1,.938-2.018l2.317-2.317a1.545,1.545,0,0,1,1.033-.482,1.453,1.453,0,0,1,.992.476l.02.02c.414.387.809.788,1.223,1.216.211.217.428.435.645.659l1.855,1.855a1.3,1.3,0,0,1,0,2.106c-.2.2-.387.394-.584.584-.571.584-1.114,1.128-1.705,1.658-.014.014-.027.02-.034.034a1.386,1.386,0,0,0-.353,1.542l.02.061a14.892,14.892,0,0,0,2.195,3.581l.007.007a24.939,24.939,0,0,0,6.034,5.49,9.278,9.278,0,0,0,.836.455c.245.122.476.238.673.36.027.014.054.034.082.048a1.473,1.473,0,0,0,.673.17,1.453,1.453,0,0,0,1.033-.469l2.324-2.324a1.537,1.537,0,0,1,1.026-.51,1.384,1.384,0,0,1,.978.5l.014.014,3.744,3.744A1.343,1.343,0,0,1,28.2,52.642Z"
                    transform="translate(-0.344 -27.043)"
                />
                <g transform="translate(16.367)">
                    <g strokeWidth="1">
                        <rect width="11.267" height="1.878" rx="0.939" stroke="none" />
                        <rect
                            x="0.5"
                            y="0.5"
                            width="10.267"
                            height="0.878"
                            rx="0.439"
                            fill="none"
                        />
                    </g>
                    <g transform="translate(0 3.756)" strokeWidth="1">
                        <rect width="11.267" height="1.878" rx="0.939" stroke="none" />
                        <rect
                            x="0.5"
                            y="0.5"
                            width="10.267"
                            height="0.878"
                            rx="0.439"
                            fill="none"
                        />
                    </g>
                    <g transform="translate(0 7.511)" strokeWidth="1">
                        <rect width="11.267" height="1.878" rx="0.939" stroke="none" />
                        <rect
                            x="0.5"
                            y="0.5"
                            width="10.267"
                            height="0.878"
                            rx="0.439"
                            fill="none"
                        />
                    </g>
                </g>
            </g>
        </SVGIcon>
    );
}
