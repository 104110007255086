import React from 'react';

import { SVGIcon } from '../../UIKit/index';

export default function DCFiles(props) {
    return (
        <SVGIcon {...props} viewBox="0 0 2000 2000" fill="none">
            <rect width="2000" height="2000" rx="400" fill="#1EACFC" />
            <rect x="380" y="365" width="1245" height="765" rx="60" fill="#48FCF7" />
            <rect x="337" y="480" width="1331" height="765" rx="60" fill="#15E6CD" />
            <rect x="315" y="595" width="1375" height="765" rx="60" fill="#2162EB" />
            <path
                d="M310 703C310 669.863 336.863 643 370 643H649.361C671.186 643 692.412 650.14 709.8 663.331L772.2 710.669C789.588 723.86 810.814 731 832.639 731H1630C1663.14 731 1690 757.863 1690 791V1533C1690 1588.23 1645.23 1633 1590 1633H410C354.772 1633 310 1588.23 310 1533V703Z"
                fill="url(#paint0_linear)"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M1172.21 1149.32C1168.71 1083.98 1106.87 1039.64 1045.03 1057.14C986.69 969.63 850.175 1005.8 844.341 1111.98C802.337 1123.65 772 1162.15 772 1207.66C772 1262.5 816.338 1308 872.344 1308H1144.21C1189.71 1308 1227.05 1270.66 1227.05 1226.32C1227.05 1191.32 1203.71 1159.82 1172.21 1149.32ZM1047.36 1197.32L1008.86 1158.81V1263.83H990.19V1158.81L951.686 1197.32L938.852 1184.48L999.525 1123.81L1060.2 1184.48L1047.36 1197.32Z"
                fill="#2162EB"
            />
            <defs>
                <linearGradient
                    id="paint0_linear"
                    x1="1000"
                    y1="643"
                    x2="1000"
                    y2="1633"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#FEFEFE" />
                    <stop offset="1" stopColor="#DAD8E2" />
                </linearGradient>
            </defs>
        </SVGIcon>
    );
}
