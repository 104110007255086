import React from 'react';

import { SVGIcon } from '../../UIKit/index';

export default function DCStoreFill(props) {
    return (
        <SVGIcon {...props} viewBox="0 0 96 96">
            <g>
                <g>
                    <path d="m95.983 32h-95.983v4a8 8 0 0 0 8 8h12a8 8 0 0 0 8-8 8 8 0 0 0 8 8h24a8 8 0 0 0 8-8 8 8 0 0 0 8 8h12a8 8 0 0 0 8-8v-4z" />
                    <path d="m95.017 28-7.171-25.1a4 4 0 0 0 -3.846-2.9h-18.321l5.6 28z" />
                    <path d="m30.321 0h-18.321a4 4 0 0 0 -3.846 2.9l-7.171 25.1h23.738z" />
                    <path d="m57.521 0h-19.042l-5.6 28h30.242z" />
                    <path d="m76 48a11.953 11.953 0 0 1 -8-3.063 11.953 11.953 0 0 1 -8 3.063h-24a11.953 11.953 0 0 1 -8-3.063 11.953 11.953 0 0 1 -8 3.063h-12a11.922 11.922 0 0 1 -4-.7v44.7a4 4 0 0 0 4 4h8v-36a4 4 0 0 1 4-4h20a4 4 0 0 1 4 4v36h44a4 4 0 0 0 4-4v-44.7a11.922 11.922 0 0 1 -4 .7zm4 24a4 4 0 0 1 -4 4h-20a4 4 0 0 1 -4-4v-12a4 4 0 0 1 4-4h20a4 4 0 0 1 4 4z" />
                </g>
            </g>
        </SVGIcon>
    );
}
