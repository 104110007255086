import React from 'react';

import { SVGIcon } from '../../UIKit/index';

export default function DCSchedule(props) {
    return (
        <SVGIcon {...props} viewBox="0 0 512 512">
            <g>
                <path d="M480.46,291.18V45.84c0-8.25-6.69-14.94-14.94-14.94h-75.74V14.94  c-0.01-8.25-6.7-14.93-14.95-14.93c-8.24,0.01-14.92,6.69-14.93,14.93v15.93h-59.78V14.94c0-8.25-6.69-14.94-14.94-14.94  c-8.25,0-14.94,6.69-14.94,14.94v15.93h-59.8V14.94C210.44,6.69,203.75,0,195.5,0s-14.94,6.69-14.94,14.94v15.93h-59.78V14.94  c0-8.25-6.69-14.94-14.94-14.94S90.9,6.69,90.9,14.94v15.93H15.16c-8.25,0-14.94,6.69-14.94,14.94c0,0.01,0,0.02,0,0.02v389.56  c0,8.25,6.69,14.94,14.94,14.94h248.89c41.17,61.79,124.65,78.51,186.44,37.33S529,363.02,487.83,301.23  c-2.31-3.46-4.77-6.82-7.39-10.05H480.46z M90.88,60.73v14.94c0,8.25,6.68,14.94,14.93,14.95c8.25,0,14.94-6.68,14.95-14.93  c0-0.01,0-0.01,0-0.02V60.73h59.8v14.94c-0.01,8.25,6.68,14.94,14.93,14.95c8.25,0.01,14.94-6.68,14.95-14.93c0-0.01,0-0.02,0-0.03  V60.73h59.78v14.94c-0.01,8.25,6.68,14.94,14.93,14.95c8.25,0.01,14.94-6.68,14.95-14.93c0-0.01,0-0.02,0-0.03V60.73h59.78v14.94  c-0.01,8.25,6.68,14.94,14.93,14.95c8.25,0.01,14.94-6.68,14.95-14.93c0-0.01,0-0.02,0-0.03V60.73h60.73v59.83H30.1V60.73H90.88z   M30.1,420.46V150.43h420.46v113.39c-61.65-41.29-145.1-24.78-186.39,36.87c-0.4,0.6-0.79,1.2-1.18,1.8  c-2.09-1.06-4.39-1.61-6.73-1.6H226.4c-8.25,0-14.94,6.69-14.94,14.94s6.69,14.94,14.94,14.94h22.64  c-3.42,9.66-5.72,19.69-6.85,29.88H226.4c-8.25-0.01-14.94,6.68-14.95,14.93c-0.01,8.25,6.68,14.94,14.93,14.95  c0.01,0,0.02,0,0.03,0h15.79c1.13,10.19,3.42,20.22,6.85,29.88L30.1,420.46z M375.84,480.24c-57.78,0-104.62-46.84-104.62-104.62  s46.84-104.62,104.62-104.62s104.62,46.84,104.62,104.62l0,0C480.41,433.37,433.6,480.18,375.84,480.24z" />
                <path d="M435.62,360.67h-44.84v-44.84c0-8.25-6.69-14.94-14.94-14.94c-8.25,0-14.94,6.69-14.94,14.94  v59.78c0,8.25,6.69,14.94,14.94,14.94h59.78c8.25,0,14.94-6.69,14.94-14.94C450.56,367.36,443.87,360.67,435.62,360.67  L435.62,360.67L435.62,360.67z" />
                <path d="M135.72,181.33h-29.88c-8.25,0.02-14.92,6.73-14.9,14.98c0.02,8.22,6.68,14.88,14.9,14.9h29.88  c8.25-0.02,14.92-6.73,14.9-14.98C150.6,188.01,143.94,181.35,135.72,181.33z" />
                <path d="M135.72,241.11h-29.88c-8.25,0.02-14.92,6.73-14.9,14.98c0.02,8.22,6.68,14.88,14.9,14.9h29.88  c8.25-0.02,14.92-6.73,14.9-14.98C150.6,247.79,143.94,241.14,135.72,241.11z" />
                <path d="M135.72,300.89h-29.88c-8.25,0-14.94,6.69-14.94,14.94c0,8.25,6.69,14.94,14.94,14.94h29.88  c8.25,0,14.94-6.69,14.94-14.94C150.66,307.58,143.97,300.89,135.72,300.89z" />
                <path d="M226.37,211.21h29.88c8.25-0.02,14.92-6.73,14.9-14.98c-0.02-8.22-6.68-14.88-14.9-14.9h-29.88  c-8.25,0.02-14.92,6.73-14.9,14.98C211.5,204.53,218.15,211.19,226.37,211.21z" />
                <path d="M226.37,270.99h29.88c8.25-0.02,14.92-6.73,14.9-14.98c-0.02-8.22-6.68-14.88-14.9-14.9h-29.88  c-8.25,0.02-14.92,6.73-14.9,14.98C211.5,264.31,218.15,270.97,226.37,270.99z" />
                <path d="M135.72,360.67h-29.88c-8.25,0-14.94,6.69-14.94,14.94c0,8.25,6.69,14.94,14.94,14.94h29.88  c8.25,0,14.94-6.69,14.94-14.94C150.66,367.36,143.97,360.67,135.72,360.67z" />
                <path d="M345.94,211.21h29.88c8.25-0.02,14.92-6.73,14.9-14.98c-0.02-8.22-6.68-14.88-14.9-14.9h-29.88  c-8.25,0.02-14.92,6.73-14.9,14.98C331.06,204.53,337.72,211.19,345.94,211.21z" />
            </g>
        </SVGIcon>
    );
}
