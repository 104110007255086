import React from 'react';

import { SVGIcon } from '../../UIKit/index';

export default function DCNoFile(props) {
    return (
        <SVGIcon {...props} viewBox="0 0 512 512">
            <g>
                <rect width="512" height="512" fill="#fff" />
                <g>
                    <g
                        transform="matrix(0.891, -0.454, 0.454, 0.891, 22.611, 171.025)"
                        fill="#fff"
                        stroke="#707070"
                        strokeWidth="4"
                    >
                        <rect width="141.982" height="256.659" rx="9" stroke="none" />
                        <rect x="2" y="2" width="137.982" height="252.659" rx="7" fill="none" />
                    </g>
                    <g
                        transform="translate(160.094 106.567)"
                        fill="#f5f6f7"
                        stroke="#707070"
                        strokeWidth="4"
                    >
                        <rect width="141.982" height="256.659" rx="9" stroke="none" />
                        <rect x="2" y="2" width="137.982" height="252.659" rx="7" fill="none" />
                    </g>
                    <g transform="translate(306.853 100.372) rotate(22)" fill="#fff">
                        <path
                            d="M 174.7465667724609 256.4793090820313 L 16.38250350952148 256.4793090820313 C 12.54080390930176 256.4793090820313 8.929037094116211 254.9832916259766 6.212536811828613 252.2667846679688 C 3.496037006378174 249.55029296875 2.000003814697266 245.9385223388672 2.000003814697266 242.0968170166016 L 2.000003814697266 16.38248825073242 C 2.000003814697266 12.54078769683838 3.496037006378174 8.929020881652832 6.212536811828613 6.212521076202393 C 8.929037094116211 3.496021270751953 12.54080390930176 1.999987840652466 16.38250350952148 1.999987840652466 L 134.2966766357422 1.999987840652466 L 189.1290740966797 67.33159637451172 L 189.1290740966797 242.0968170166016 C 189.1290740966797 245.9385223388672 187.6330413818359 249.55029296875 184.9165344238281 252.2667846679688 C 182.2000427246094 254.9832916259766 178.5882720947266 256.4793090820313 174.7465667724609 256.4793090820313 Z"
                            stroke="none"
                        />
                        <path
                            d="M 16.38250732421875 4.000015258789063 C 13.07499694824219 4.000015258789063 9.965499877929688 5.288009643554688 7.626754760742188 7.626754760742188 C 5.287994384765625 9.965469360351563 4 13.07501220703125 4 16.38247680664063 L 4 242.0968017578125 C 4 245.404296875 5.287994384765625 248.5137939453125 7.626754760742188 250.8525543212891 C 9.965499877929688 253.1912994384766 13.07499694824219 254.4792938232422 16.38250732421875 254.4792938232422 L 174.7465667724609 254.4792938232422 C 178.0540771484375 254.4792938232422 181.16357421875 253.1912994384766 183.5023193359375 250.8525543212891 C 185.8410797119141 248.5137939453125 187.1290740966797 245.404296875 187.1290740966797 242.0968017578125 L 187.1290740966797 68.0596923828125 L 133.3641815185547 4.000015258789063 L 16.38250732421875 4.000015258789063 M 16.38250732421875 0 L 135.2291412353516 0 L 191.1290740966797 66.60354614257813 L 191.1290740966797 242.0968017578125 C 191.1290740966797 251.1446228027344 183.7943878173828 258.4793090820313 174.7465667724609 258.4793090820313 L 16.38250732421875 258.4793090820313 C 7.334686279296875 258.4793090820313 0 251.1446228027344 0 242.0968017578125 L 0 16.38247680664063 C 0 7.334686279296875 7.334686279296875 0 16.38250732421875 0 Z"
                            stroke="none"
                            fill="#707070"
                        />
                    </g>
                    <g
                        transform="translate(304.422 200.408) rotate(24)"
                        fill="none"
                        stroke="#707070"
                        strokeWidth="4"
                    >
                        <rect width="100.115" height="11.92" stroke="none" />
                        <rect x="2" y="2" width="96.115" height="7.92" fill="none" />
                    </g>
                    <g
                        transform="translate(293.5 226.513) rotate(24)"
                        fill="none"
                        stroke="#707070"
                        strokeWidth="4"
                    >
                        <rect width="100.115" height="11.92" stroke="none" />
                        <rect x="2" y="2" width="96.115" height="7.92" fill="none" />
                    </g>
                    <g
                        transform="translate(280.759 255.637) rotate(24)"
                        fill="none"
                        stroke="#707070"
                        strokeWidth="4"
                    >
                        <rect width="100.115" height="11.92" stroke="none" />
                        <rect x="2" y="2" width="96.115" height="7.92" fill="none" />
                    </g>
                    <g
                        transform="translate(269.837 281.742) rotate(24)"
                        fill="none"
                        stroke="#707070"
                        strokeWidth="4"
                    >
                        <rect width="100.115" height="11.92" stroke="none" />
                        <rect x="2" y="2" width="96.115" height="7.92" fill="none" />
                    </g>
                    <g
                        transform="translate(465.901 106.567)"
                        fill="#fff"
                        stroke="#e3e3e4"
                        strokeWidth="2"
                    >
                        <circle cx="16.382" cy="16.382" r="16.382" stroke="none" />
                        <circle cx="16.382" cy="16.382" r="15.382" fill="none" />
                    </g>
                    <g
                        transform="translate(65.44 346.843)"
                        fill="#fff"
                        stroke="#e3e3e4"
                        strokeWidth="2"
                    >
                        <circle cx="10.922" cy="10.922" r="10.922" stroke="none" />
                        <circle cx="10.922" cy="10.922" r="9.922" fill="none" />
                    </g>
                    <g
                        transform="translate(14.472 114.934)"
                        fill="#fff"
                        stroke="#e3e3e4"
                        strokeWidth="2"
                    >
                        <circle cx="6.371" cy="6.371" r="6.371" stroke="none" />
                        <circle cx="6.371" cy="6.371" r="5.371" fill="none" />
                    </g>
                    <path
                        d="M19.469,8.78h-6.49A.382.382,0,0,1,12.6,8.4V1.909a1.909,1.909,0,1,0-3.817,0V8.4A.382.382,0,0,1,8.4,8.78H1.909a1.909,1.909,0,1,0,0,3.817H8.4a.382.382,0,0,1,.382.382v6.49a1.909,1.909,0,0,0,3.817,0v-6.49a.382.382,0,0,1,.382-.382h6.49a1.909,1.909,0,0,0,0-3.817Zm0,0"
                        transform="translate(458.689 153.359)"
                        fill="#e3e3e4"
                    />
                    <path
                        d="M15.965,7.2H10.644a.313.313,0,0,1-.313-.313V1.565a1.565,1.565,0,0,0-3.13,0V6.887a.313.313,0,0,1-.313.313H1.565a1.565,1.565,0,0,0,0,3.13H6.887a.313.313,0,0,1,.313.313v5.322a1.565,1.565,0,0,0,3.13,0V10.644a.313.313,0,0,1,.313-.313h5.322a1.565,1.565,0,0,0,0-3.13Zm0,0"
                        transform="translate(31.027 377.935)"
                        fill="#e3e3e4"
                    />
                    <path
                        d="M15.037,6.781H10.025a.3.3,0,0,1-.295-.295V1.474a1.474,1.474,0,0,0-2.948,0V6.487a.3.3,0,0,1-.295.295H1.474a1.474,1.474,0,0,0,0,2.948H6.487a.3.3,0,0,1,.295.295v5.012a1.474,1.474,0,0,0,2.948,0V10.025a.3.3,0,0,1,.295-.295h5.012a1.474,1.474,0,0,0,0-2.948Zm0,0"
                        transform="translate(13.334 142.316)"
                        fill="#e3e3e4"
                    />
                </g>
            </g>
        </SVGIcon>
    );
}
