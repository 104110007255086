import React from 'react';
import PropTypes from 'prop-types';
import './style.scss';

const SVGIcon = ({ props, viewBox, children, style }) => {
    return (
        <svg {...props} viewBox={viewBox} style={style}>
            {children}
        </svg>
    );
};

SVGIcon.propTypes = {
    viewBox: PropTypes.string,
    children: PropTypes.element,
    style: PropTypes.object,
};

export default SVGIcon;
