import React from 'react';

import { SVGIcon } from '../../UIKit/index';

export default function DCGoogleAnalytics(props) {
    return (
        <SVGIcon {...props} viewBox="0 0 512 512">
            <g transform="translate(0 24.375)">
                <path
                    fill="#FFC107"
                    d="M336.3,136.1H176.7c-8.8,0-16,7.1-16,16v319.4c0,8.8,7.1,16,16,16h159.7c8.8,0,16-7.2,16-16   l0,0V152C352.3,143.2,345.2,136.1,336.3,136.1z"
                />
                <path
                    fill="#FFC107"
                    d="M176.7,295.7H17c-8.8,0-16,7.2-16,16v159.7c0,8.8,7.2,16,16,16h159.7c8.8,0,16-7.1,16-16V311.7   C192.6,302.9,185.5,295.7,176.7,295.7z"
                />
            </g>
            <path
                fill="#FFA000"
                d="M496,0.8H336.3c-8.8,0-16,7.2-16,16v479.1c0,8.8,7.2,16,16,16H496c8.8,0,16-7.1,16-16V16.7  C512,7.9,504.8,0.8,496,0.8z"
            />
        </SVGIcon>
    );
}
