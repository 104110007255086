import React from 'react';

import { SVGIcon } from '../../UIKit/index';

export default function DCFunnel(props) {
    return (
        <SVGIcon {...props} viewBox="0 0 2000 2000" fill="none">
            <g filter="url(#filter0_i)">
                <rect width="2000" height="2000" rx="400" fill="url(#paint0_linear)" />
                <mask
                    id="mask0"
                    mask-type="alpha"
                    maskUnits="userSpaceOnUse"
                    x="0"
                    y="0"
                    width="2000"
                    height="2000"
                >
                    <rect width="2000" height="2000" rx="400" fill="#0083FF" />
                    <rect width="2000" height="2000" rx="400" fill="#15E6CD" />
                </mask>
                <g mask="url(#mask0)">
                    <path
                        d="M1668 533.25C1668 375.174 1248.43 333 1000.5 333C752.567 333 333 375.174 333 533.25C333.697 556.768 343.238 579.158 359.718 595.95L800.25 1178.59V1567.88C800.25 1636.65 904.058 1668 1000.5 1668C1096.94 1668 1200.75 1636.65 1200.75 1567.88V1178.59L1641.28 595.95C1657.76 579.158 1667.3 556.768 1668 533.25ZM1000.5 433.125C1331.81 433.125 1565.92 499.875 1565.92 533.25C1565.92 572.187 1331.81 633.375 1000.5 633.375C669.195 633.375 435.081 572.187 435.081 533.25C435.081 498.485 669.195 433.125 1000.5 433.125Z"
                        fill="url(#paint1_linear)"
                    />
                    <path
                        d="M1565.92 533.25C1565.92 499.875 1331.8 433.125 1000.5 433.125C669.194 433.125 435.08 498.485 435.08 533.25C435.08 572.187 669.194 633.375 1000.5 633.375C1331.8 633.375 1565.92 572.187 1565.92 533.25Z"
                        fill="white"
                    />
                </g>
            </g>
            <defs>
                <filter
                    id="filter0_i"
                    x="0"
                    y="-26"
                    width="2000"
                    height="2026"
                    filterUnits="userSpaceOnUse"
                    colorInterpolationFilters="sRGB"
                >
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feBlend
                        mode="normal"
                        in="SourceGraphic"
                        in2="BackgroundImageFix"
                        result="shape"
                    />
                    <feColorMatrix
                        in="SourceAlpha"
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                        result="hardAlpha"
                    />
                    <feOffset dy="-26" />
                    <feGaussianBlur stdDeviation="125" />
                    <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
                    <feColorMatrix
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"
                    />
                    <feBlend mode="normal" in2="shape" result="effect1_innerShadow" />
                </filter>
                <linearGradient
                    id="paint0_linear"
                    x1="1000"
                    y1="0"
                    x2="1000"
                    y2="2000"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#414141" />
                    <stop offset="1" />
                </linearGradient>
                <linearGradient
                    id="paint1_linear"
                    x1="1001"
                    y1="176.5"
                    x2="1000.5"
                    y2="1668"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#35E3C7" />
                    <stop offset="0.24984" stopColor="#F6B72B" />
                    <stop offset="0.578125" stopColor="#FDCEC9" />
                    <stop offset="1" stopColor="#B56ED1" />
                </linearGradient>
            </defs>
        </SVGIcon>
    );
}
