import React from 'react';

import { SVGIcon } from '../../UIKit/index';

export default function DCAgencyWebsiteMenu(props) {
    return (
        <SVGIcon {...props} viewBox="0 0 512 439">
            <g transform="translate(-1.8 -10.7)">
                <path
                    fill="#E8EFFF"
                    d="M18.71,12.7h477.59c9.05,0.02,16.38,7.35,16.4,16.4v404.21c-0.02,9.05-7.35,16.38-16.4,16.4   H18.71c-9.05-0.02-16.38-7.35-16.4-16.4V29.1c-0.12-8.93,7.02-16.28,15.95-16.4C18.41,12.7,18.56,12.7,18.71,12.7z"
                />
                <path
                    fill="#2F8DFF"
                    d="M18.71,12.7h477.59c9.05,0.02,16.38,7.35,16.4,16.4v65.6H2.31V29.1   c-0.12-8.93,7.02-16.28,15.95-16.4C18.41,12.7,18.56,12.7,18.71,12.7z"
                />
                <path
                    fill="none"
                    stroke="#2F8DFF"
                    strokeWidth="20"
                    d="M244.84,233.07c0,0-26.24-51.11-68.53-14.22l-3.95,5.16l-4.95,8.2l-23.31,53.33l-7.76,19.84   l-4.31,9.93c-3.03,11.39-0.49,23.54,6.85,32.75c6.78,9.29,17.4,15.02,28.89,15.56H372.9c21.76-3.81,36.31-24.54,32.49-46.3   c-0.1-0.6-0.22-1.21-0.36-1.81c-0.88-4.52-2.53-8.86-4.87-12.82c-2.94-4.98-30.51-50.4-30.51-50.4   c-9.5-13.59-26.39-19.93-42.49-15.96c-4.69,1.33-9.12,3.44-13.09,6.27c-5.64,4.64-28.04,25.07-28.04,25.07   c-7.16,4.94-16.98,3.14-21.92-4.02c-0.43-0.62-0.81-1.28-1.15-1.96C252.72,246.38,244.84,233.07,244.84,233.07z"
                />
                <circle fill="#ffffff" cx="121.68" cy="57.88" r="11.11" />
                <circle fill="#ffffff" cx="77.24" cy="57.88" r="11.11" />
                <circle fill="#ffffff" cx="32.8" cy="57.88" r="11.11" />
            </g>
        </SVGIcon>
    );
}
