import React, { Fragment, useCallback, useEffect, useMemo, useRef, useState } from 'react';

import PropTypes from 'prop-types';

import industry from 'Constants/industry.json';
import { Button, Checkbox, Div, SingleTextChip } from 'UIKit';
import AutoComplete from '../AutoComplete';

function IndustryList({ singleSelect, value, onChange, resetVal, fromFilter = false }) {
    const [selected, setSelected] = useState([]);
    const firstRun = useRef(false);

    useEffect(() => {
        if (resetVal && value) {
            if (singleSelect === true) {
                const _selected = industry.find(i => i.title === value);
                if (_selected) setSelected([_selected]);
                else setSelected([industry.find(el => el.title === 'Other')]);
            } else {
                const _selected = industry.filter(i => value.includes(i.value));
                setSelected(_selected);
            }
        } else if (resetVal && !value) {
            setSelected([]);
        }
    }, [resetVal, singleSelect, value]);

    const generateAutoCompleteSingle = useCallback(() => {
        return industry.map(item => {
            return {
                id: item._id,
                label: (
                    <Button
                        buttonClass={`ACSingleOption ${
                            item.title === selected?.[0]?.title ? 'active' : ''
                        }`}
                        onClick={() => {
                            setSelected([item]);
                            onChange(fromFilter ? item : item.title);
                        }}
                    >
                        {item.title}
                    </Button>
                ),
                value: item.value,
            };
        });
    }, [fromFilter, onChange, selected]);

    useEffect(() => {
        if (firstRun.current === false) {
            firstRun.current = true;
            if (value) {
                if (singleSelect === true) {
                    const selected = industry.find(i => i.title === value);
                    if (selected) setSelected([selected]);
                    else setSelected([industry.find(el => el.title === 'Other')]);
                } else {
                    const selected = industry.filter(i => (value || []).includes(i.value));
                    setSelected(selected);
                }
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [value]);

    const handleSelection = useCallback(
        item => {
            if (selected.includes(item)) {
                let temp = [...selected];
                temp = temp.filter(data => data._id !== item._id);
                setSelected([...temp]);
                if (singleSelect === true) {
                    onChange(fromFilter ? temp[0] : temp[0].value);
                } else {
                    onchange((temp || []).map(s => s.value));
                }
            } else {
                setSelected(prev => {
                    const selectedOpt = [...prev, item];
                    if (singleSelect === true) {
                        onChange(fromFilter ? selectedOpt[0] : selectedOpt[0].value);
                    } else {
                        onchange((selectedOpt || []).map(s => s.value));
                    }
                    return selectedOpt;
                });
            }
        },
        [fromFilter, onChange, selected, singleSelect],
    );

    const generateAutoCompleteMultiple = useCallback(() => {
        let temp = [];
        industry.map((item, index) => temp.push({
                id: index,
                label: (
                    <Div className={`ACMultiOption ${selected?.includes(item) ? 'active' : ''}`}>
                        <Checkbox
                            key={index}
                            unCheckColor={'#eaeaea'}
                            checkColor={'var(--dark-blue)'}
                            classes={{ main: 'ACMOICheckbox' }}
                            fullWidth={true}
                            label={item.title}
                            onChange={() => handleSelection(item)}
                            checked={selected.includes(item)}
                        />
                    </Div>
                ),
                value: item.value,
            }),
        );
        return temp;
    }, [handleSelection, selected]);

    const generateChips = useMemo(() => {
        return (selected || []).map((item, index) => {
            return {
                id: index,
                chip: <SingleTextChip title={item?.title} onDelete={() => handleSelection(item)} />,
            };
        });
    }, [handleSelection, selected]);

    const generateValue = useCallback(() => {
        if (singleSelect) {
            return selected.length ? selected[0].title : 'Select Industry';
        } else {
            if (selected.length) {
                return generateChips.map(item => item.chip);
            } else return 'Select Industries';
        }
    }, [generateChips, selected, singleSelect]);

    return (
        <Fragment>
            <AutoComplete
                isAPICall={false}
                generateRows={
                    singleSelect ? generateAutoCompleteSingle : generateAutoCompleteMultiple
                }
                singleSelection={singleSelect}
                value={generateValue()}
                itemHeight={45}
                // customWidth={250}
            />
        </Fragment>
    );
}

IndustryList.propTypes = {
    singleSelect: PropTypes.bool,
};

export default IndustryList;
