import React from 'react';

import { SVGIcon } from '../../UIKit/index';

export default function DCResetPassword(props) {
    return (
        <SVGIcon {...props} viewBox="0 0 511.99 511.99">
            <path
                d="M320,207.995h-16v-32c0-35.296-28.704-64-64-64s-64,28.704-64,64v32h-16c-8.832,0-16,7.168-16,16v128
			c0,8.832,7.168,16,16,16h160c8.832,0,16-7.168,16-16v-128C336,215.163,328.832,207.995,320,207.995z M272,207.995h-64v-32
			c0-17.632,14.368-32,32-32s32,14.368,32,32V207.995z"
            />
            <path
                d="M501.952,161.147c-8.16-3.264-17.504,0.704-20.768,8.928l-9.568,23.936C444.16,91.643,350.88,15.995,240,15.995
			c-132.352,0-240,107.648-240,240s107.648,240,240,240c64.096,0,124.384-24.96,169.728-70.272c6.24-6.24,6.24-16.384,0-22.624
			s-16.384-6.24-22.624,0c-39.328,39.264-91.552,60.896-147.104,60.896c-114.688,0-208-93.312-208-208s93.312-208,208-208
			c99.872,0,183.488,70.784,203.424,164.8l-32.096-32.128c-6.24-6.24-16.384-6.24-22.624,0s-6.24,16.384,0,22.624l64,64
			c3.008,3.072,7.104,4.704,11.296,4.704c1.056,0,2.144-0.096,3.232-0.32c5.28-1.088,9.632-4.736,11.616-9.728l32-80
			C514.112,173.723,510.144,164.411,501.952,161.147z"
            />
        </SVGIcon>
    );
}
