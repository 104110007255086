import React from 'react';

import { SVGIcon } from '../../UIKit/index';

export default function DCEmailOpen(props) {
    return (
        <SVGIcon {...props} viewBox="0 0 512 512">
            <g transform="translate(-0.261 0)">
                <path
                    fill="#2B88F8"
                    d="M511.28,489.9c0.16,5.98-2.17,11.76-6.42,15.96c-4.18,3.47-9.46,5.36-14.9,5.32H21.92   c-5.41-0.14-10.62-2.01-14.9-5.32c-4.25-4.21-6.58-9.98-6.42-15.96V178.19h510.66L511.28,489.9z"
                />
                <path
                    fill="#1371E2"
                    d="M504.9,505.86c-4.18,3.47-9.46,5.36-14.9,5.32H21.92c-5.4-0.14-10.62-2.01-14.9-5.32   l248.94-213.83L504.9,505.86z"
                />
                <path
                    fill="#D9EAFF"
                    d="M511.28,178.2l-72.34,52.13L269.8,351.6c-8.51,6.38-20.21,6.38-28.72,0L72.99,230.32L0.65,178.2   l72.34-52.13L230.44,13.3l11.68-8.52c8.51-6.38,20.21-6.38,28.72,0l11.7,8.51l75.55,54.27l37.23,26.59l43.62,31.91L511.28,178.2z"
                />
                <path
                    fill="#FFFFFF"
                    d="M438.95,79.26v151.07L269.8,351.6c-8.51,6.38-20.21,6.38-28.72,0L72.99,230.33V34.58   c0.04-11.74,9.54-21.24,21.28-21.28h277.66L438.95,79.26z"
                />
                <path
                    fill="#D9EAFF"
                    d="M372.98,62.25c-0.12,9.28,7.3,16.9,16.58,17.02c0.15,0,0.3,0,0.44,0h48.94l-65.95-65.95   L372.98,62.25L372.98,62.25z"
                />
                <path
                    fill="#6B8AAA"
                    d="M318.73,227.13h17.02c-5.28,11.48-14.28,20.85-25.53,26.59c-14.73,7.73-31.26,11.4-47.88,10.63   c-16.13,0.45-32.15-2.82-46.81-9.57c-12.72-5.66-23.2-15.39-29.79-27.66c-6.4-12.13-9.69-25.65-9.57-39.36   c-0.27-15.21,3.39-30.24,10.63-43.62c6.48-12.87,16.92-23.31,29.79-29.79c13.6-6.53,28.54-9.81,43.62-9.57   c12.9-0.08,25.65,2.83,37.23,8.51c10.53,4.87,19.14,13.1,24.47,23.41c5.55,10.12,8.47,21.47,8.51,33   c0.01,13.81-4.47,27.25-12.76,38.29c-9.21,13.81-24.91,21.86-41.49,21.28c-3.65,0.03-7.27-0.7-10.63-2.13   c-2.39-1.79-3.93-4.48-4.25-7.44c-5.81,6.14-13.89,9.6-22.34,9.57c-8.53,0.45-16.79-3.08-22.34-9.57   c-6.56-6.84-10.02-16.07-9.57-25.53c-0.1-12.84,3.6-25.43,10.63-36.17c7.19-11.93,20.11-19.2,34.04-19.15   c8.95-0.15,17.37,4.26,22.34,11.7l2.13-9.57h22.34l-12.76,59.59c-0.69,2.42-1.04,4.93-1.06,7.44c-0.14,1.17,0.25,2.34,1.06,3.19   c1.06,1.06,1.06,1.06,2.13,1.06c3.93-0.86,7.59-2.68,10.63-5.32c5.96-4.43,10.72-10.28,13.83-17.02   c3.27-7.01,5.07-14.61,5.32-22.34c0.27-12.98-5.18-25.43-14.9-34.04c-11.38-10.05-26.35-15.04-41.49-13.83   c-13.4-0.38-26.65,2.93-38.29,9.57c-10.14,6.03-18.28,14.91-23.41,25.53c-5.21,10.95-7.76,22.98-7.44,35.1   c0.04,11.19,2.97,22.19,8.51,31.91c5.72,9.49,14.28,16.93,24.47,21.28c11.53,4.46,23.81,6.64,36.17,6.42   c11.56,0.05,23.05-1.75,34.04-5.32C304.34,240.4,312.35,234.55,318.73,227.13z M228.31,193.09c-0.54,5.66,0.97,11.32,4.25,15.96   c2.64,3.18,6.5,5.11,10.63,5.32c2.96-0.07,5.86-0.8,8.51-2.13c2.39-1.44,4.55-3.24,6.42-5.32c3.53-4.37,6.07-9.45,7.44-14.9   c2-5.83,3.08-11.93,3.19-18.09c0.39-5.31-1.12-10.59-4.25-14.9c-2.64-3.18-6.5-5.11-10.63-5.32c-4.76,0.16-9.3,2.05-12.77,5.32   c-4.84,4.14-8.21,9.74-9.57,15.96C229.51,180.82,228.43,186.93,228.31,193.09z"
                />
            </g>
        </SVGIcon>
    );
}
