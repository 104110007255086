import React from 'react';
import { getDefaultUserImage, getUserDetails } from './localStorageUtils';
import { getLocalStorageAuthenticateObject } from './auth';
import MD5 from 'Utils/hash';
import config from 'config';

export const getInitialsSvg = (name, showColor, hideNotAvailableSvg) => {
    let text = hideNotAvailableSvg ? ['-'] : ['Not', 'Available'];
    if (name && name.length) {
        text = name.split(' ');
    }

    const text1 = text[0].charAt(0);
    const text2 = text[1]?.charAt(0) || '';
    const res = text1.concat(text2);

    const generateFillColor = () => {
        let hash = 0;
        const _name = name || '';
        for (let i = 0; i < _name.length; i++) {
            hash = _name.charCodeAt(i) + ((hash << 5) - hash);
        }
        const h = hash % 360;
        return `hsl(${h}, 70%, 80%)`;
    };

    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="64px"
            height="64px"
            viewBox="0 0 64 64"
            version="1.1"
        >
            <rect
                fill={showColor ? generateFillColor() : '#ddd'}
                cx="32"
                width="64"
                height="64"
                cy="32"
                r="32"
            />
            <text
                x="50%"
                y="50%"
                style={{
                    color: '#222',
                    lineHeight: 1,
                    fontFamily: 'inherit',
                }}
                alignmentBaseline="middle"
                textAnchor="middle"
                fontSize="28"
                fontWeight="400"
                dy=".1em"
                dominantBaseline="middle"
                fill="#222"
            >
                {res.toUpperCase()}
            </text>
        </svg>
    );
};

export default function generateImage(image, name, publicImage, email) {
    if (image !== null && typeof image === 'object' && image.key) {
        return `${config.REACT_APP_ASSETS_BASE_URL}/${image.key}`;
    } else if (typeof image === 'string') {
        return image;
    } else if (email) {
        return `${process.env.REACT_APP_API_GRAVATAR}/${process.env.REACT_APP_API_AVATAR}/${MD5(
            email,
        )}?d=404`;
    } else {
        if (name) return `404 Not Found`;
        else return null;
    }
}

export function generatePrivateImage(image, name, publicImage, email) {
    if (image !== null && typeof image === 'object' && image.key) {
        let session_id = getLocalStorageAuthenticateObject().auth?.session_id;
        let accountId = getLocalStorageAuthenticateObject().auth?.account?.id;
        return `${config.REACT_APP_API_BASE_URL}/${process.env.REACT_APP_API_VERSION}/${process.env.REACT_APP_API_INTEGRATIONS}/${process.env.REACT_APP_API_WASABI}/download_by_key/${image.key}?token=${session_id}&account_id=${accountId}`;
    } else if (typeof image === 'string') {
        return image;
    } else if (email) {
        return `${process.env.REACT_APP_API_GRAVATAR}/${process.env.REACT_APP_API_AVATAR}/${MD5(
            email,
        )}?d=404`;
    } else {
        if (name) return `404 Not Found`;
        else return null;
    }
}

export function generateUserImage(image) {
    if (image?.key) return generateImage(image);
    else if (getUserDetails()?.image?.key) return generateImage(getUserDetails().image);
    else return getDefaultUserImage();
}
