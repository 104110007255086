import React from 'react';
import { SVGIcon } from 'UIKit/index';

const DCSchedule2 = props => {
    return (
        <SVGIcon
            {...props}
            viewBox="0 0 2000 2000"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            id="Layer_1"
        >
            <defs xmlns="http://www.w3.org/2000/svg">
                <linearGradient
                    id="linear-gradient"
                    x1="1000"
                    y1="2002"
                    x2="1000"
                    y2="2"
                    gradientTransform="translate(0 2002) scale(1 -1)"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset="0" stopColor="#00a8ff" />
                    <stop offset="1" stopColor="#007cf2" />
                </linearGradient>
            </defs>
            <rect
                xmlns="http://www.w3.org/2000/svg"
                x="0"
                y="0"
                width="2000"
                height="2000"
                rx="400"
                ry="400"
                style={{ fill: 'rgb(0, 124, 242)' }}
            />
            <g xmlns="http://www.w3.org/2000/svg" id="_15">
                <g>
                    <path
                        d="M666.51,651.82h18.66c28.36,0,51.36-23.41,51.36-52.28v-198.24c0-28.87-22.99-52.28-51.36-52.28h-18.66c-28.36,0-51.35,23.41-51.35,52.28v198.24c0,28.87,22.99,52.28,51.35,52.28Z"
                        style={{ fill: '#fff' }}
                    />
                    <path
                        d="M1325.22,650.52h18.66c28.36,0,51.35-23.41,51.35-52.28v-198.24c0-28.86-22.99-52.28-51.35-52.28h-18.66c-28.37,0-51.36,23.41-51.36,52.28v198.24c0,28.87,23,52.28,51.36,52.28Z"
                        style={{ fill: '#fff' }}
                    />
                    <path
                        d="M1568.79,455.27h-131.8v156.67c0,52.26-41.76,81.09-93.09,81.09h-18.66c-51.34,0-93.1-42.52-93.1-94.78v-142.97h-453.85v144.27c0,52.26-41.76,94.78-93.1,94.78h-18.66c-51.33,0-93.1-42.52-93.1-94.78v-144.27h-142.2c-46.03,0-83.49,38.13-83.49,84.99V1567.28c0,46.87,37.45,84.99,83.49,84.99H1568.79c46.03,0,83.49-38.13,83.49-84.99V540.26c0-46.86-37.45-84.99-83.49-84.99Zm0,1112.01H431.22V791.7s1137.58,0,1137.58,0l.04,775.57s-.02,0-.06,0Z"
                        style={{ fill: '#fff' }}
                    />
                    <path
                        d="M1043.1,1051.85h149.91c5.94,0,10.76-4.9,10.76-10.95v-132.15c0-6.05-4.82-10.95-10.76-10.95h-149.91c-5.94,0-10.76,4.9-10.76,10.95v132.15c0,6.05,4.82,10.95,10.76,10.95Z"
                        style={{ fill: '#fff' }}
                    />
                    <path
                        d="M1287.75,1051.85h149.91c5.94,0,10.76-4.9,10.76-10.95v-132.15c0-6.05-4.82-10.95-10.76-10.95h-149.91c-5.94,0-10.76,4.9-10.76,10.95v132.15c0,6.05,4.82,10.95,10.76,10.95Z"
                        style={{ fill: '#fff' }}
                    />
                    <path
                        d="M553.82,1268.19h149.91c5.94,0,10.76-4.9,10.76-10.95v-132.15c0-6.05-4.82-10.95-10.76-10.95h-149.91c-5.94,0-10.76,4.9-10.76,10.95v132.15c0,6.05,4.82,10.95,10.76,10.95Z"
                        style={{ fill: '#fff' }}
                    />
                    <path
                        d="M798.46,1268.19h149.91c5.94,0,10.76-4.9,10.76-10.95v-132.15c0-6.05-4.82-10.95-10.76-10.95h-149.91c-5.94,0-10.76,4.9-10.76,10.95v132.15c0,6.05,4.82,10.95,10.76,10.95Z"
                        style={{ fill: '#fff' }}
                    />
                    <path
                        d="M1043.1,1268.19h149.91c5.94,0,10.76-4.9,10.76-10.95v-132.15c0-6.05-4.82-10.95-10.76-10.95h-149.91c-5.94,0-10.76,4.9-10.76,10.95v132.15c0,6.05,4.82,10.95,10.76,10.95Z"
                        style={{ fill: '#fff' }}
                    />
                    <path
                        d="M1287.75,1268.19h149.91c5.94,0,10.76-4.9,10.76-10.95v-132.15c0-6.05-4.82-10.95-10.76-10.95h-149.91c-5.94,0-10.76,4.9-10.76,10.95v132.15c0,6.05,4.82,10.95,10.76,10.95Z"
                        style={{ fill: '#fff' }}
                    />
                    <path
                        d="M703.72,1330.48h-149.9c-5.94,0-10.76,4.9-10.76,10.95v132.15c0,6.05,4.82,10.95,10.76,10.95h149.91c5.94,0,10.76-4.9,10.76-10.95v-132.15c0-6.05-4.82-10.95-10.76-10.95Z"
                        style={{ fill: '#fff' }}
                    />
                    <path
                        d="M948.37,1330.48h-149.91c-5.94,0-10.76,4.9-10.76,10.95v132.15c0,6.05,4.82,10.95,10.76,10.95h149.91c5.94,0,10.76-4.9,10.76-10.95v-132.15c0-6.05-4.82-10.95-10.76-10.95Z"
                        style={{ fill: '#fff' }}
                    />
                    <path
                        d="M1193.01,1330.48h-149.91c-5.94,0-10.76,4.9-10.76,10.95v132.15c0,6.05,4.82,10.95,10.76,10.95h149.91c5.94,0,10.76-4.9,10.76-10.95v-132.15c0-6.05-4.82-10.95-10.76-10.95Z"
                        style={{ fill: '#fff' }}
                    />
                    <path
                        d="M1437.66,1330.48h-149.91c-5.94,0-10.76,4.9-10.76,10.95v132.15c0,6.05,4.82,10.95,10.76,10.95h149.91c5.94,0,10.76-4.9,10.76-10.95v-132.15c0-6.05-4.82-10.95-10.76-10.95Z"
                        style={{ fill: '#fff' }}
                    />
                </g>
            </g>
        </SVGIcon>
    );
};

export default DCSchedule2;
