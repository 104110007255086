import React from 'react';

import { SVGIcon } from '../../UIKit/index';

export default function DCBuildingsOutline(props) {
    return (
        <SVGIcon {...props} viewBox="0 0 512 512">
            <g>
                <path d="M108.242,338.019H71.494c-5.522,0-10,4.478-10,10v36.748c0,5.522,4.478,10,10,10h36.748c5.522,0,10-4.478,10-10v-36.748    C118.242,342.496,113.765,338.019,108.242,338.019z M98.242,374.767H81.494v-16.748h16.748V374.767z" />
                <path d="M189.7,338.019h-36.748c-5.522,0-10,4.478-10,10v36.748c0,5.522,4.478,10,10,10H189.7c5.522,0,10-4.478,10-10v-36.748    C199.7,342.496,195.223,338.019,189.7,338.019z M179.7,374.767h-16.748v-16.748H179.7V374.767z" />
                <path d="M108.242,415.19H71.494c-5.522,0-10,4.478-10,10v36.748c0,5.522,4.478,10,10,10h36.748c5.522,0,10-4.478,10-10V425.19    C118.242,419.667,113.765,415.19,108.242,415.19z M98.242,451.938H81.494V435.19h16.748V451.938z" />
                <path d="M189.7,415.19h-36.748c-5.522,0-10,4.478-10,10v36.748c0,5.522,4.478,10,10,10H189.7c5.522,0,10-4.478,10-10V425.19    C199.7,419.667,195.223,415.19,189.7,415.19z M179.7,451.938h-16.748V435.19H179.7V451.938z" />
                <path d="M152,258.43c-1.87-1.86-4.44-2.93-7.07-2.93c-2.64,0-5.21,1.069-7.07,2.93c-1.869,1.86-2.93,4.44-2.93,7.07    s1.061,5.21,2.93,7.069c1.86,1.86,4.44,2.931,7.07,2.931s5.2-1.07,7.07-2.931c1.859-1.859,2.93-4.439,2.93-7.069    S153.86,260.29,152,258.43z" />
                <path d="M502,492h-10V237.938c0-5.522-4.478-10-10-10h-47.873V99.114c0-3.701-2.044-7.1-5.314-8.834l-168-89.114    c-3.1-1.644-6.834-1.545-9.84,0.265c-3.007,1.809-4.846,5.061-4.846,8.569v102h-101.2c-5.522,0-10,4.478-10,10v103.722    c0,5.522,4.478,10,10,10c5.522,0,10-4.478,10-10V132h136v39.938h-34.8c-5.522,0-10,4.478-10,10c0,5.522,4.478,10,10,10h34.8v36    h-34.8c-5.522,0-10,4.478-10,10c0,5.522,4.478,10,10,10h34.8v36h-34.8c-5.522,0-10,4.478-10,10c0,5.522,4.478,10,10,10h34.8v36    h-34.8c-5.522,0-10,4.478-10,10c0,5.522,4.478,10,10,10h34.8v36h-34.8c-5.522,0-10,4.478-10,10c0,5.522,4.478,10,10,10h34.8v36    h-34.8c-5.522,0-10,4.478-10,10c0,5.522,4.478,10,10,10h34.8V492H240V308c0-5.522-4.478-10-10-10H30c-5.522,0-10,4.478-10,10v184    H10c-5.522,0-10,4.478-10,10c0,5.522,4.478,10,10,10h492c5.522,0,10-4.478,10-10C512,496.478,507.523,492,502,492z M220,492H40    V318h180V492z M344,237.938V492h-33.073V122c0-5.522-4.478-10-10-10h-34.8V26.624l148,78.506v122.808H354    C348.478,227.938,344,232.416,344,237.938z M472,283.938h-54c-5.522,0-10,4.478-10,10c0,5.522,4.478,10,10,10h54v36h-54    c-5.522,0-10,4.478-10,10c0,5.522,4.478,10,10,10h54v36h-54c-5.522,0-10,4.478-10,10c0,5.522,4.478,10,10,10h54v36h-30.333    c-5.522,0-10,4.478-10,10c0,5.522,4.478,10,10,10H472V492H364V247.938h108V283.938z" />
                <path d="M403.399,454.871c-1.859-1.861-4.429-2.931-7.069-2.931c-2.63,0-5.21,1.07-7.07,2.931c-1.86,1.859-2.93,4.439-2.93,7.069    s1.069,5.21,2.93,7.07c1.861,1.86,4.44,2.93,7.07,2.93c2.64,0,5.21-1.069,7.069-2.93c1.87-1.86,2.931-4.44,2.931-7.07    C406.33,459.3,405.269,456.73,403.399,454.871z" />
                <path d="M344,113c-5.522,0-10,4.478-10,10v18.667c0,5.523,4.478,10,10,10c5.522,0,10-4.478,10-10V123    C354,117.478,349.522,113,344,113z" />
                <path d="M344,171.937c-5.522,0-10,4.478-10,10v20.592c0,5.523,4.478,10,10,10c5.522,0,10-4.478,10-10v-20.592    C354,176.415,349.522,171.937,344,171.937z" />
                <path d="M384,113c-5.522,0-10,4.478-10,10v18.667c0,5.523,4.478,10,10,10c5.522,0,10-4.478,10-10V123    C394,117.478,389.522,113,384,113z" />
                <path d="M384,171.937c-5.522,0-10,4.478-10,10v20.592c0,5.523,4.478,10,10,10c5.522,0,10-4.478,10-10v-20.592    C394,176.415,389.522,171.937,384,171.937z" />
            </g>
        </SVGIcon>
    );
}
