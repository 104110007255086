import React, {
    useCallback,
    useMemo,
    useRef,
    useState,
    useEffect,
    useContext,
    Fragment,
} from 'react';
import dayjs from 'dayjs';

import DCAgency from 'Assets/icons/DCAgency';
import DCAnalytics from 'Assets/icons/DCAnalytics';
import DCContacts from 'Assets/icons/DCContacts';
import DCDashboardMenu from 'Assets/icons/DCDashboardMenu';
import DCDealMenu from 'Assets/icons/DCDealMenu';
import DCForms from 'Assets/icons/DCForms';
import DCInbound from 'Assets/icons/DCInbound';
import DCInstareports from 'Assets/icons/DCInstareports';
import DCInstasites from 'Assets/icons/DCInstasites';
import DCProject from 'Assets/icons/DCProject';
import DCTemplates from 'Assets/icons/DCTemplates';
import DCGrayNotification from 'Assets/icons/DCGrayNotification';
import DCAppType from 'Assets/icons/DCAppType';
import DCCrossClose from 'Assets/icons/DCCrossClose';
import usePubSub from 'Hooks/PubSub';
import InfiniteScroll from 'Modules/InfiniteScroll';
import { Button, Dialog, Div, H1, IconButton, NoData, TopBar } from 'UIKit/index';
import './style.scss';
const relativeTime = require('dayjs/plugin/relativeTime');
import { updateNotification, getNotifications } from 'apis/root/index.api';
import { useInfiniteQuery, useQueryClient } from 'react-query';
import { themeContext } from 'Contexts/Context';
import useToast from 'Modules/Toasts';
import areValidScopes from 'Utils/scopeUtils';
import Reminder from './Reminder';
dayjs.extend(relativeTime);

const getIcon = app => {
    switch (app) {
        case 'projects':
            return <DCProject />;
        case 'inbound':
            return <DCInbound />;
        case 'contacts':
        case 'reminder':
            return <DCContacts />;
        case 'templates':
            return <DCTemplates />;
        case 'instasites':
            return <DCInstasites />;
        case 'instareports':
            return <DCInstareports />;
        case 'forms':
            return <DCForms />;
        case 'sites':
            return <DCAgency />;
        case 'deals':
            return <DCDealMenu />;
        case 'analytics':
            return <DCAnalytics />;
        case 'dashboard':
            return <DCDashboardMenu />;
    }
};

class NotificationItem {
    constructor(id, isRead, module, type, title, body, data, click_action, timestamp) {
        this.id = id;
        this.isRead = isRead;
        this.module = module;
        this.type = type;
        this.title = title;
        this.body = body;
        this.data = data;
        this.click_action = click_action;
        this.timestamp = new Date(timestamp);
    }
}

const DEFAULT_LIMIT = 20;

const NotificationsDialog = props => {
    const { setNotificationCount, notificationCount, notify } = useContext(themeContext);
    const { showToast } = useToast();
    const { open, handleClose, type, setType } = props;
    const queryClient = useQueryClient();
    const [notificationType, setNotificationType] = useState('type1');
    const [newNotificationItems, setNewNotificationItems] = useState([]);
    const [notificationItems, setNotificationItems] = useState([]);
    const notificationItemId = useRef(0);
    const [, setAnnouncementAccess] = useState({
        access: false,
        loading: true,
    });

    useEffect(() => {
        (async () => {
            const val = areValidScopes('announcements');
            setAnnouncementAccess({
                access: val,
                loading: false,
            });
        })();
    }, []);

    const handleData = useCallback(
        (_data, _setData, type = notificationType) => {
            const notification = [];
            _data?.pages.forEach(page => {
                page?.data?.forEach(item => {
                    notificationItemId.current += 1;
                    if (type === 'type1') {
                        notification.push(
                            new NotificationItem(
                                notificationItemId.current,
                                item.is_read,
                                item.module,
                                item.type,
                                item.message?.title,
                                item.message?.body,
                                item.data,
                                item.message?.click_action,
                                item.createdAt,
                            ),
                        );
                    } else {
                        notification.push(
                            new NotificationItem(
                                notificationItemId.current,
                                item?.is_read,
                                item?.module,
                                item?.type,
                                item?.translations[0]?.title,
                                item?.translations[0]?.contentHtml,
                                item?.translations[0]?.content,
                                item?.message?.click_action,
                                item?.date,
                            ),
                        );
                    }
                });
            });
            _setData(notification);
        },
        [notificationType],
    );

    const {
        data: notificationsData,
        fetchNextPage: notificationsFetchNextPage,
        hasNextPage: notificationsHasNextPage,
        isLoading: notificationsIsLoading,
        isFetchingNextPage: notificationsIsFetchingNextPage,
    } = useInfiniteQuery(['notifications'], async ({ pageParam = 1 }) => {
        getNotifications(`page=${pageParam}&limit=${DEFAULT_LIMIT}&type`),
            {
                staleTime: 1000 * 60 * 10, // 10 minutes
                cacheTime: 1000 * 60 * 15, // 15 minutes
                retry: 3,
                getNextPageParam: lastPage => {
                    // lastPage.pagination.unread_count
                    if (lastPage.pagination?.next_page) {
                        return lastPage.pagination?.next_page;
                    } else return undefined;
                },
            };
    });

    const handleNotificationType = useCallback(
        type => {
            setNotificationType(type);
            handleData(notificationsData, setNotificationItems, type);
        },
        [handleData, notificationsData],
    );

    useEffect(() => {
        if (type === 'type1' && type !== notificationType) {
            handleNotificationType(type);
        }
    }, [handleNotificationType, notificationType, setType, type]);

    useEffect(() => {
        if (notificationsData) {
            if (notificationsData.pages[0]?.pagination) {
                setNotificationCount(notificationsData.pages[0].pagination.unread_count);
            }
            handleData(notificationsData, setNotificationItems);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [notificationsData]);

    usePubSub(
        'new_fcm_notification',
        useCallback(
            data => {
                if (data?.data.type === 'reminder_due' && data?.data?.subType === 'bell') {
                    queryClient.invalidateQueries(['reminders-all']);
                }
                notificationItemId.current += 1;
                setNotificationCount(notificationCount + 1);
                const notificationItem = new NotificationItem(
                    notificationItemId.current,
                    false,
                    data.module,
                    data.type,
                    data.title,
                    data.body,
                    data.data,
                    data.click_action,
                    data.createdAt,
                );
                setNewNotificationItems([notificationItem, ...newNotificationItems]);
            },
            [newNotificationItems, notificationCount, setNotificationCount, queryClient],
        ),
    );

    const infiniteScrollRef = InfiniteScroll({
        loading: notificationsIsLoading || notificationsIsFetchingNextPage,
        hasNextPage: notificationsHasNextPage,
        onLoadMore: notificationsFetchNextPage,
        threshold: 100,
        scrollContainer: 'parent',
    });

    const handleNotificationClick = useCallback(clickAction => {
        if (clickAction) document.open(clickAction, '', '');
    }, []);

    const handleMarkRead = useCallback(
        async body => {
            try {
                await updateNotification(body);
            } catch (err) {
                showToast({ message: err, type: 'error' });
            }
        },
        [showToast],
    );

    const notificationItemViews = useMemo(() => {
        const views = [];
        let prevDay;
        let yesterdaySectionAdded = false;
        let olderSectionAdded = false;

        const today = new Date();
        const yesterday = new Date();
        yesterday.setDate(yesterday.getDate() - 1);

        for (const item of notificationItems) {
            // START: Add sections like Today, Yesterday, Older
            const day = item.timestamp;
            if (!prevDay) {
                if (
                    day.getFullYear() === today.getFullYear() &&
                    day.getMonth() === today.getMonth() &&
                    day.getDate() === today.getDate()
                ) {
                    notificationItemId.current += 1;
                    views.push(
                        <Div className={'NDNLTitle'} key={notificationItemId.current}>
                            Today
                        </Div>,
                    );
                } else {
                    const yesterday = new Date();
                    yesterday.setDate(yesterday.getDate() - 1);

                    if (
                        !yesterdaySectionAdded &&
                        day.getFullYear() === yesterday.getFullYear() &&
                        day.getMonth() === yesterday.getMonth() &&
                        day.getDate() === yesterday.getDate()
                    ) {
                        notificationItemId.current += 1;
                        views.push(
                            <Div className={'NDNLTitle'} key={notificationItemId.current}>
                                Yesterday
                            </Div>,
                        );
                        yesterdaySectionAdded = true;
                    }
                }
            } else {
                if (
                    !yesterdaySectionAdded &&
                    yesterday.getFullYear() === day.getFullYear() &&
                    yesterday.getMonth() === day.getMonth() &&
                    yesterday.getDate() === day.getDate()
                ) {
                    notificationItemId.current += 1;
                    views.push(
                        <Div className={'NDNLTitle'} key={notificationItemId.current}>
                            Yesterday
                        </Div>,
                    );
                    yesterdaySectionAdded = true;
                } else if (!olderSectionAdded && day < yesterday) {
                    notificationItemId.current += 1;
                    views.push(
                        <Div className={'NDNLTitle'} key={notificationItemId.current}>
                            Older
                        </Div>,
                    );
                    olderSectionAdded = true;
                }
            }
            prevDay = day;
            // END: Add sections like Today, Yesterday, Older

            views.push(
                <Div
                    className={'NDNLBox'}
                    key={item.id}
                    onClick={() => {
                        if (notificationType === 'type1' && item.is_read === false) {
                            handleMarkRead({ id: item.id });
                            setNotificationCount(notificationCount - 1);
                        }
                        handleNotificationClick(item.click_action);
                    }}
                >
                    <Div className={'NDNLBTime'}>{dayjs(item.timestamp).fromNow()}</Div>
                    <Div className={'NDNLBInfo'}>
                        <Div className={'NDNLBIIcon'}>
                            {notificationType === 'type1' ? getIcon(item.module) : <DCAppType />}
                        </Div>
                        <Div className={'NDNLBIDetail'}>
                            <Div className={'NDNLBIDTitle'}>{item.title}</Div>
                            <Div
                                className={'NDNLBIDSTitle'}
                                dangerouslySetInnerHTML={{ __html: item.body }}
                            />
                        </Div>
                    </Div>
                </Div>,
            );
        }
        return views;
    }, [
        handleMarkRead,
        handleNotificationClick,
        notificationCount,
        notificationItems,
        notificationType,
        setNotificationCount,
    ]);

    const newNotificationItemViews = useMemo(() => {
        const views = [];
        for (const item of newNotificationItems) {
            views.push(
                <Div
                    className={'NDNLBox'}
                    key={item.id}
                    onClick={() => {
                        if (notificationType === 'type1' && item.is_read === false) {
                            handleMarkRead({ id: item.id });
                            setNotificationCount(notificationCount - 1);
                        }
                        handleNotificationClick(item.click_action);
                    }}
                >
                    <Div className={'NDNLBTime'}>{dayjs(item.timestamp).fromNow()}</Div>
                    <Div className={'NDNLBInfo'}>
                        <Div className={'NDNLBIIcon'}>
                            {notificationType === 'type1' ? getIcon(item.module) : <DCAppType />}
                        </Div>
                        <Div className={'NDNLBIDetail'}>
                            <Div className={'NDNLBIDTitle'}>{item.title}</Div>
                            <Div className={'NDNLBIDSTitle'}>{item.body}</Div>
                        </Div>
                    </Div>
                </Div>,
            );
        }
        return views;
    }, [
        handleMarkRead,
        handleNotificationClick,
        newNotificationItems,
        notificationCount,
        notificationType,
        setNotificationCount,
    ]);

    const generateView = useCallback(() => {
        if (newNotificationItems.length || notificationItems.length) {
            return (
                <Fragment>
                    {newNotificationItems.length > 0 ? (
                        <Fragment>
                            <Div className={'NDNLTitle'}>New</Div>
                            {newNotificationItemViews}
                        </Fragment>
                    ) : null}
                    {notificationItemViews}
                </Fragment>
            );
        } else
            return (
                <Div className={'NDNLNoData'}>
                    <NoData
                        icon={<DCGrayNotification />}
                        title={'No Notifications'}
                        description={'There are no notifications at this time.'}
                    />
                </Div>
            );
    }, [newNotificationItemViews, newNotificationItems, notificationItemViews, notificationItems]);

    return (
        <Dialog
            dialogType={'rightSlide'}
            open={open || notify?.openNotificationbar}
            onClose={handleClose}
        >
            <Div className={'NotificationsDialog'}>
                <TopBar>
                    <Div className={'HeaderBar'}>
                        <H1 className={'HeaderBarTitle'}>Notification Center</H1>
                        <Div className={'HeaderBarClose'}>
                            <IconButton onClick={handleClose}>
                                <DCCrossClose />
                            </IconButton>
                        </Div>
                    </Div>
                </TopBar>
                <Div className={'BodyBox'}>
                    <Div className={'NDHTabs'}>
                        <Button
                            buttonClass={notificationType === 'type1' ? 'active' : ''}
                            onClick={() => {
                                handleNotificationType('type1');
                            }}
                        >
                            Notifications
                        </Button>
                        <Button
                            buttonClass={notificationType === 'type2' ? 'active' : ''}
                            onClick={() => {
                                setNotificationType('type2');
                                setType('type2');
                            }}
                        >
                            Reminders
                        </Button>
                    </Div>
                    <Div className={'NDHTabPanel'}>
                        {notificationType === 'type1' ? (
                            <Div className={'NDHTPType1'}>
                                {notificationCount > 0 && (
                                    <Div className={'NDHTPTHead'}>
                                        <Button
                                            buttonType={'BlueOutlineBtn fullWidth'}
                                            onClick={() => {
                                                handleMarkRead();
                                                setNotificationCount(0);
                                            }}
                                        >
                                            Mark all as read
                                        </Button>
                                    </Div>
                                )}
                                <Div className={'NDHTPT1Body'}>
                                    <Div
                                        className={
                                            'NDNotificationList ' +
                                            (notificationType ? notificationType : '')
                                        }
                                        reference={infiniteScrollRef}
                                    >
                                        {generateView()}
                                    </Div>
                                </Div>
                            </Div>
                        ) : (
                            <Reminder
                                handleClose={handleClose}
                                active={notify?.activeTab}
                                notify={notify?.openNotificationbar}
                            />
                        )}
                    </Div>
                </Div>
            </Div>
        </Dialog>
    );
};

export default NotificationsDialog;
