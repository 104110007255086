import React from 'react';

import { SVGIcon } from '../../UIKit/index';

export default function DCNoDataCommon(props) {
    return (
        <SVGIcon {...props} viewBox="0 0 500 372.609">
            <g transform="translate(0 -33.286)">
                <g>
                    <g id="Group_5668" data-name="Group 5668">
                        <rect
                            width="500"
                            height="0.25"
                            transform="translate(0 360.732)"
                            fill="#ebebeb"
                        />
                        <rect
                            width="33.122"
                            height="0.25"
                            transform="translate(416.779 376.828)"
                            fill="#ebebeb"
                        />
                        <rect
                            width="8.693"
                            height="0.25"
                            transform="translate(322.527 379.541)"
                            fill="#ebebeb"
                        />
                        <rect
                            width="19.192"
                            height="0.25"
                            transform="translate(396.586 367.542)"
                            fill="#ebebeb"
                        />
                        <rect
                            width="43.193"
                            height="0.25"
                            transform="translate(52.459 369.222)"
                            fill="#ebebeb"
                        />
                        <rect
                            width="6.333"
                            height="0.25"
                            transform="translate(104.556 369.222)"
                            fill="#ebebeb"
                        />
                        <rect
                            width="93.676"
                            height="0.25"
                            transform="translate(131.471 373.444)"
                            fill="#ebebeb"
                        />
                        <path
                            d="M237.014,316.133H43.915a5.714,5.714,0,0,1-5.708-5.708V38.994a5.715,5.715,0,0,1,5.708-5.708h193.1a5.715,5.715,0,0,1,5.707,5.708V310.425A5.714,5.714,0,0,1,237.014,316.133Zm-193.1-282.6a5.464,5.464,0,0,0-5.458,5.458V310.425a5.464,5.464,0,0,0,5.458,5.458h193.1a5.463,5.463,0,0,0,5.457-5.458V38.994a5.463,5.463,0,0,0-5.457-5.458Z"
                            fill="#ebebeb"
                        />
                        <path
                            d="M453.31,316.133h-193.1a5.713,5.713,0,0,1-5.707-5.708V38.994a5.714,5.714,0,0,1,5.707-5.708h193.1a5.715,5.715,0,0,1,5.709,5.708V310.425A5.715,5.715,0,0,1,453.31,316.133Zm-193.1-282.6a5.463,5.463,0,0,0-5.457,5.458V310.425a5.463,5.463,0,0,0,5.457,5.458h193.1a5.464,5.464,0,0,0,5.459-5.458V38.994a5.464,5.464,0,0,0-5.459-5.458Z"
                            fill="#ebebeb"
                        />
                    </g>
                    <g>
                        <g>
                            <rect
                                width="54.578"
                                height="105.892"
                                transform="translate(119.952 360.733) rotate(180)"
                                fill="#e6e6e6"
                            />
                            <path d="M79.948,360.733H65.374V346.115H95.228Z" fill="#fafafa" />
                            <rect
                                width="54.578"
                                height="105.892"
                                transform="translate(268.761 360.733) rotate(180)"
                                fill="#e6e6e6"
                            />
                            <rect
                                width="161.529"
                                height="100.861"
                                transform="translate(226.903 355.701) rotate(180)"
                                fill="#fafafa"
                            />
                            <path d="M212.329,360.733H226.9V346.115H197.048Z" fill="#fafafa" />
                            <rect
                                width="138.917"
                                height="25.241"
                                transform="translate(215.597 317.665) rotate(180)"
                                fill="#f0f0f0"
                            />
                            <rect
                                width="138.917"
                                height="25.241"
                                transform="translate(215.597 348.301) rotate(180)"
                                fill="#f0f0f0"
                            />
                            <path
                                d="M4.576,0H90.685a4.576,4.576,0,0,1,4.576,4.576v.308a0,0,0,0,1,0,0H0a0,0,0,0,1,0,0V4.576A4.576,4.576,0,0,1,4.576,0Z"
                                transform="translate(193.769 295.123) rotate(180)"
                                fill="#fafafa"
                            />
                            <rect
                                width="138.917"
                                height="25.241"
                                transform="translate(215.597 287.029) rotate(180)"
                                fill="#f0f0f0"
                            />
                            <path
                                d="M4.576,0H90.685a4.576,4.576,0,0,1,4.576,4.576v.308a0,0,0,0,1,0,0H0a0,0,0,0,1,0,0V4.576A4.576,4.576,0,0,1,4.576,0Z"
                                transform="translate(193.769 264.487) rotate(180)"
                                fill="#fafafa"
                            />
                            <path
                                d="M4.576,0H90.685a4.576,4.576,0,0,1,4.576,4.576v.308a0,0,0,0,1,0,0H0a0,0,0,0,1,0,0V4.576A4.576,4.576,0,0,1,4.576,0Z"
                                transform="translate(193.769 325.759) rotate(180)"
                                fill="#fafafa"
                            />
                        </g>
                    </g>
                    <g>
                        <rect
                            width="137.781"
                            height="90.227"
                            transform="translate(211.849 152.392) rotate(180)"
                            fill="#e6e6e6"
                        />
                        <rect
                            width="140.02"
                            height="90.227"
                            transform="translate(209.885 152.392) rotate(180)"
                            fill="#f0f0f0"
                        />
                        <rect
                            width="137.781"
                            height="17.708"
                            transform="translate(211.849 170.099) rotate(180)"
                            fill="#e6e6e6"
                        />
                        <rect
                            width="140.02"
                            height="17.708"
                            transform="translate(202.881 170.099) rotate(180)"
                            fill="#f0f0f0"
                        />
                        <rect
                            width="78.456"
                            height="128.249"
                            transform="translate(203.999 68.051) rotate(90)"
                            fill="#fafafa"
                        />
                        <path d="M175.08,146.507,158.7,68.051H133.138l16.381,78.456Z" fill="#fff" />
                        <path
                            d="M201.274,140.649a.421.421,0,0,0,.421-.421V71.88a.421.421,0,1,0-.841,0v68.348A.421.421,0,0,0,201.274,140.649Z"
                            fill="#f0f0f0"
                        />
                        <path
                            d="M144.028,146.507,127.647,68.051h-9.965l16.381,78.456Z"
                            fill="#fff"
                        />
                        <rect
                            width="78.456"
                            height="0.747"
                            transform="translate(76.497 68.051) rotate(90)"
                            fill="#e6e6e6"
                        />
                        <path
                            d="M68.472,76.925H206.253l.542-6.586H69.014Z"
                            fill="#ebebeb"
                            opacity="0.6"
                        />
                        <path
                            d="M68.472,80.64H206.253l.542-6.586H69.014Z"
                            fill="#ebebeb"
                            opacity="0.6"
                        />
                        <path
                            d="M68.472,84.355H206.253l.542-6.586H69.014Z"
                            fill="#ebebeb"
                            opacity="0.6"
                        />
                        <path
                            d="M68.472,88.071H206.253l.542-6.586H69.014Z"
                            fill="#ebebeb"
                            opacity="0.6"
                        />
                        <path
                            d="M68.472,91.786H206.253l.542-6.586H69.014Z"
                            fill="#ebebeb"
                            opacity="0.6"
                        />
                        <path
                            d="M68.472,95.5H206.253l.542-6.586H69.014Z"
                            fill="#ebebeb"
                            opacity="0.6"
                        />
                    </g>
                    <g>
                        <rect
                            width="4.763"
                            height="53.092"
                            transform="translate(100.777 251.604) rotate(180)"
                            fill="#f5f5f5"
                        />
                        <rect
                            width="1.345"
                            height="53.092"
                            transform="translate(97.972 251.568) rotate(180)"
                            fill="#fafafa"
                        />
                        <rect
                            width="0.527"
                            height="53.092"
                            transform="translate(99.063 251.568) rotate(180)"
                            fill="#fafafa"
                        />
                        <path
                            d="M0,0H35.264a0,0,0,0,1,0,0V0a4.443,4.443,0,0,1-4.443,4.443H4.443A4.443,4.443,0,0,1,0,0V0A0,0,0,0,1,0,0Z"
                            transform="translate(116.027 254.84) rotate(180)"
                            fill="#f0f0f0"
                        />
                        <path
                            d="M89.835,231.472h0a1.374,1.374,0,0,0,1.37-1.37v-34.81a1.373,1.373,0,0,0-1.37-1.37h0a1.374,1.374,0,0,0-1.37,1.37V230.1A1.375,1.375,0,0,0,89.835,231.472Z"
                            fill="#f0f0f0"
                        />
                        <path d="M77.074,211.132h42.643l-4.906-29.8H81.979Z" fill="#e0e0e0" />
                    </g>
                    <g transform="translate(110.26 214.372)">
                        <path
                            d="M119.43,279.69l9.95,20-12.22-.137,2.27-19.862"
                            transform="translate(-117.16 -279.69)"
                            fill="#ebebeb"
                        />
                        <path
                            d="M387.71,382.3c-.284.861,9.784,39.137,9.784,39.137L404.4,420l-9.5-37.7Z"
                            transform="translate(-122.999 -281.905)"
                            fill="#ebebeb"
                        />
                        <path
                            d="M442.115,376.71,428.3,421.316h8.062L449.6,379.88Z"
                            transform="translate(-123.875 -281.784)"
                            fill="#f5f5f5"
                        />
                        <path
                            d="M455.011,369.65,439.18,421.164h8.062L462.5,372.82Z"
                            transform="translate(-124.11 -281.632)"
                            fill="#ebebeb"
                        />
                        <path
                            d="M392.71,419.07l8.062,27.914h40.575l7.485-27.914Z"
                            transform="translate(-123.107 -282.698)"
                            fill="#e0e0e0"
                        />
                        <path
                            d="M413.655,417.8a2.536,2.536,0,0,1-.088-.294l-.215-.851c-.186-.783-.44-1.888-.773-3.3l-2.788-12.191-9.109-40.653.245.2h-7.1l.245-.313c3.61,16,6.849,30.331,9.168,40.683,1.135,5.156,2.055,9.315,2.7,12.23.294,1.419.538,2.524.7,3.317.069.362.127.646.166.861a.978.978,0,0,1,0,.3,2.538,2.538,0,0,1-.088-.293c-.059-.215-.127-.5-.215-.851-.2-.783-.46-1.888-.8-3.3-.675-2.935-1.644-7.054-2.847-12.191-2.378-10.352-5.655-24.666-9.315-40.653l-.078-.3h7.632l.049.2c3.513,16,6.673,30.331,8.953,40.682,1.106,5.156,2.006,9.315,2.632,12.23.294,1.419.519,2.524.685,3.317l.166.861a.979.979,0,0,1,.075.3Z"
                            transform="translate(-123.124 -281.428)"
                            fill="#e0e0e0"
                        />
                    </g>
                    <g transform="translate(345.742 112.404)">
                        <path
                            d="M375.8,348.619c-16.985-13.7-19.451-37.111-17.318-57.864.362-3.464.225-7.152,2.084-10.087s5.87-4.745,8.874-3.072c2.495,1.389,3.4,4.471,4.677,7.045a20.391,20.391,0,0,0,7.612,8.336c2.133,1.3,4.824,2.192,7.084,1.135,3.111-1.448,3.747-5.557,3.963-9.011l1.2-19.314c.215-3.464.45-6.986,1.751-10.185s3.914-6.086,7.23-6.79,7.279,1.487,7.739,4.892a31.761,31.761,0,0,1-.2,4.3c0,1.438.558,3.072,1.888,3.561s2.8-.382,3.914-1.321c3.806-3.239,6.526-7.563,9.1-11.878s5.068-8.737,8.571-12.318,8.17-6.291,13.14-6.233,10.019,3.473,10.939,8.414-2.26,9.706-5.626,13.434a77.224,77.224,0,0,1-17.142,14.216,6.34,6.34,0,0,0-2.935,2.7c-.91,2.25,1.115,4.687,3.336,5.606,2.515,1.027,5.332.978,8,1.458s5.508,1.888,6.35,4.5c1.174,3.63-2.035,7.123-5.107,9.354a66.287,66.287,0,0,1-20.224,9.96c-2.612.753-5.342,1.38-7.514,3.023s-3.62,4.667-2.544,7.182,4.2,3.522,6.849,3.209,5.176-1.6,7.778-2.378c4.892-1.468,10.978-.714,13.7,3.591,1.81,2.837,1.732,6.6.5,9.784a25.32,25.32,0,0,1-5.822,8.18,66.532,66.532,0,0,1-29.724,17.983c-11.271,3.062-21.525,3.4-32.21-1.36"
                            transform="translate(-357.836 -176.645)"
                            fill="#00acfd"
                        />
                        <path
                            d="M374.84,364.114a152.371,152.371,0,0,1,7.593-28.071A311.012,311.012,0,0,1,397,303.569l4.256-8.317c1.409-2.73,2.8-5.43,4.325-7.994a156.957,156.957,0,0,1,9.6-14.207c3.278-4.373,6.536-8.4,9.618-12.152s6.037-7.172,8.855-10.185A123.633,123.633,0,0,1,448,237.5c1.81-1.419,3.248-2.475,4.237-3.18l1.125-.793a3.14,3.14,0,0,1,.391-.254,1.674,1.674,0,0,1-.352.3l-1.086.851c-.978.734-2.378,1.82-4.158,3.278a129.588,129.588,0,0,0-14.167,13.326c-2.788,3.023-5.714,6.467-8.806,10.215s-6.311,7.827-9.559,12.172a153.3,153.3,0,0,0-9.53,14.167c-1.517,2.554-2.935,5.235-4.3,7.964l-4.256,8.317a320.4,320.4,0,0,0-14.588,32.385,155.562,155.562,0,0,0-7.7,27.944"
                            transform="translate(-358.203 -176.719)"
                            fill="#018ed1"
                        />
                        <path
                            d="M406.4,287.574a6.7,6.7,0,0,1-.245-1.5c-.108-.978-.235-2.368-.362-4.109-.245-3.464-.431-8.277-.616-13.571s-.44-10.1-.744-13.561c-.147-1.722-.274-3.121-.382-4.09a6.566,6.566,0,0,1-.088-1.517,7.686,7.686,0,0,1,.333,1.477c.176.978.382,2.358.587,4.09.421,3.454.744,8.258.978,13.571s.323,9.96.431,13.58c.068,1.634.117,3,.166,4.109a7.929,7.929,0,0,1-.059,1.517Z"
                            transform="translate(-358.831 -177.064)"
                            fill="#018ed1"
                        />
                        <path
                            d="M451.417,283.046a8.944,8.944,0,0,1-1.79.1c-1.145,0-2.8,0-4.892.137-4.09.166-9.784.577-15.958,1.321s-11.809,1.654-15.841,2.436c-2.016.372-3.64.724-4.765.978a10.162,10.162,0,0,1-1.761.3,10.156,10.156,0,0,1,1.7-.548c1.106-.313,2.72-.724,4.736-1.164,4.012-.89,9.618-1.879,15.85-2.632a156.546,156.546,0,0,1,16.026-1.125c2.055,0,3.718,0,4.892.059a10.184,10.184,0,0,1,1.8.138Z"
                            transform="translate(-358.884 -177.79)"
                            fill="#018ed1"
                        />
                        <path
                            d="M382.813,336.2a2.015,2.015,0,0,1-.264-.558l-.656-1.663c-.558-1.438-1.311-3.552-2.2-6.164-1.79-5.235-4.041-12.543-6.418-20.635s-4.55-15.42-6.233-20.684c-.822-2.563-1.507-4.667-2.006-6.233l-.538-1.693a2.3,2.3,0,0,1-.147-.607,2.448,2.448,0,0,1,.264.558c.176.45.391.978.646,1.663.558,1.438,1.311,3.552,2.211,6.164,1.79,5.234,4.041,12.543,6.409,20.645s4.559,15.41,6.242,20.674c.822,2.563,1.5,4.667,1.957,6.233l.538,1.693a2.3,2.3,0,0,1,.2.607Z"
                            transform="translate(-357.976 -177.684)"
                            fill="#018ed1"
                        />
                        <path
                            d="M443.53,322.19a1.959,1.959,0,0,1-.607.2l-1.751.46-6.458,1.614c-5.46,1.36-12.964,3.327-21.261,5.518s-15.831,4.09-21.32,5.342c-2.74.626-4.961,1.115-6.506,1.419l-1.781.342a2.314,2.314,0,0,1-.626.068,2.433,2.433,0,0,1,.6-.2l1.761-.46,6.458-1.614c5.45-1.36,12.964-3.336,21.261-5.528s15.831-4.09,21.31-5.342c2.74-.626,4.97-1.106,6.506-1.409l1.781-.342a2.756,2.756,0,0,1,.633-.073Z"
                            transform="translate(-358.384 -178.639)"
                            fill="#018ed1"
                        />
                    </g>
                </g>
                <g>
                    <ellipse
                        cx="193.889"
                        cy="11.323"
                        rx="193.889"
                        ry="11.323"
                        transform="translate(56.111 383.248)"
                        fill="#f5f5f5"
                    />
                </g>
                <g>
                    <g>
                        <g>
                            <path
                                d="M337.711,293.495H123.553a9.648,9.648,0,0,1-9.441-8.807L103.59,138.558a8.107,8.107,0,0,1,8.172-8.807H325.92a9.647,9.647,0,0,1,9.441,8.807l10.523,146.13A8.109,8.109,0,0,1,337.711,293.495Z"
                                fill="#00acfd"
                            />
                            <g>
                                <g>
                                    <path
                                        d="M338.532,293.495H124.374a9.648,9.648,0,0,1-9.441-8.807l-10.522-146.13a8.107,8.107,0,0,1,8.172-8.807H326.741a9.648,9.648,0,0,1,9.441,8.807L346.7,284.688A8.108,8.108,0,0,1,338.532,293.495Z"
                                        fill="#407bff"
                                    />
                                    <path
                                        d="M338.532,293.495H124.374a9.648,9.648,0,0,1-9.441-8.807l-10.522-146.13a8.107,8.107,0,0,1,8.172-8.807H326.741a9.648,9.648,0,0,1,9.441,8.807L346.7,284.688A8.108,8.108,0,0,1,338.532,293.495Z"
                                        fill="#85d8ff"
                                    />
                                </g>
                                <path
                                    d="M327.058,134.154H112.9q-.363,0-.717.03c-5.475.45-4.356,8.777,1.169,8.777H327.875c5.525,0,5.444-8.327-.1-8.777Q327.421,134.154,327.058,134.154Z"
                                    fill="#00acfd"
                                />
                                <path
                                    d="M118.478,138.558a1.854,1.854,0,0,1-1.874,2.019,2.2,2.2,0,0,1-2.164-2.019,1.854,1.854,0,0,1,1.874-2.019A2.2,2.2,0,0,1,118.478,138.558Z"
                                    fill="#fafafa"
                                />
                                <path
                                    d="M125.346,138.558a1.854,1.854,0,0,1-1.874,2.019,2.2,2.2,0,0,1-2.164-2.019,1.853,1.853,0,0,1,1.873-2.019A2.2,2.2,0,0,1,125.346,138.558Z"
                                    fill="#fafafa"
                                />
                                <path
                                    d="M132.214,138.558a1.854,1.854,0,0,1-1.874,2.019,2.2,2.2,0,0,1-2.164-2.019,1.853,1.853,0,0,1,1.873-2.019A2.206,2.206,0,0,1,132.214,138.558Z"
                                    fill="#fafafa"
                                />
                            </g>
                            <path
                                d="M332.854,278.91h-204.9a3.487,3.487,0,0,1-3.422-3.193l-8.654-120.173a2.931,2.931,0,0,1,2.963-3.192h204.9a3.487,3.487,0,0,1,3.423,3.192l8.653,120.173A2.931,2.931,0,0,1,332.854,278.91Z"
                                fill="#fff"
                            />
                        </g>
                        <g>
                            <g>
                                <g>
                                    <g>
                                        <path
                                            d="M246.53,233.132l-3.335-46.319-10.107-6.083h-27.8l3.773,52.4Z"
                                            fill="#fff"
                                        />
                                        <path
                                            id="Path_1754"
                                            data-name="Path 1754"
                                            d="M246.53,233.616H209.057a.485.485,0,0,1-.484-.45l-3.773-52.4a.486.486,0,0,1,.483-.52h27.8a.477.477,0,0,1,.25.07l10.107,6.083a.486.486,0,0,1,.234.38l3.335,46.32a.483.483,0,0,1-.483.519Zm-37.022-.969h36.5l-3.28-45.549-9.776-5.885H205.8Z"
                                            fill="#00acfd"
                                        />
                                    </g>
                                    <g id="Group_5681" data-name="Group 5681">
                                        <path
                                            id="Path_1756"
                                            data-name="Path 1756"
                                            d="M236.8,189.273a.486.486,0,0,1-.44-.282l-3.711-8.06a.484.484,0,0,1,.69-.617l10.107,6.083a.484.484,0,0,1-.107.878l-6.4,1.976A.469.469,0,0,1,236.8,189.273Zm-2.613-7.318,2.876,6.245,4.956-1.531Z"
                                            fill="#00acfd"
                                        />
                                    </g>
                                </g>
                                <g id="Group_5683" data-name="Group 5683">
                                    <path
                                        id="Path_1757"
                                        data-name="Path 1757"
                                        d="M220.969,204.339a1.943,1.943,0,0,1-1.964,2.117,2.312,2.312,0,0,1-2.27-2.117,1.944,1.944,0,0,1,1.965-2.117A2.312,2.312,0,0,1,220.969,204.339Z"
                                        fill="#00acfd"
                                    />
                                    <path
                                        id="Path_1758"
                                        data-name="Path 1758"
                                        d="M234.705,204.339a1.944,1.944,0,0,1-1.965,2.117,2.312,2.312,0,0,1-2.269-2.117,1.943,1.943,0,0,1,1.964-2.117A2.312,2.312,0,0,1,234.705,204.339Z"
                                        fill="#00acfd"
                                    />
                                    <path
                                        id="Path_1759"
                                        data-name="Path 1759"
                                        d="M238.58,218.068a.484.484,0,0,1-.483-.449c-.245-3.4-5.584-6.173-11.9-6.173-4.185,0-7.943,1.244-9.806,3.247a3.738,3.738,0,0,0-1.14,2.856.485.485,0,0,1-.967.07,4.731,4.731,0,0,1,1.4-3.586c2.041-2.194,6.071-3.556,10.516-3.556,6.929,0,12.581,3.106,12.867,7.072a.485.485,0,0,1-.449.518Z"
                                        fill="#00acfd"
                                    />
                                </g>
                                <path
                                    id="Path_1760"
                                    data-name="Path 1760"
                                    d="M213.167,200.05a.484.484,0,0,1-.355-.814l2.123-2.288a.485.485,0,0,1,.711.659l-2.123,2.288A.485.485,0,0,1,213.167,200.05Z"
                                    fill="#00acfd"
                                />
                                <path
                                    id="Path_1761"
                                    data-name="Path 1761"
                                    d="M237.584,200.05a.482.482,0,0,1-.33-.13l-2.453-2.289a.484.484,0,1,1,.661-.708l2.453,2.288a.485.485,0,0,1-.331.839Z"
                                    fill="#00acfd"
                                />
                            </g>
                            <g id="Group_5685" data-name="Group 5685">
                                <path
                                    id="Path_1762"
                                    data-name="Path 1762"
                                    d="M202.257,243.486h2.26l3.262,4.334-.313-4.334h2.282l.564,7.833H208.03l-3.242-4.3.309,4.3h-2.276Z"
                                    fill="#00acfd"
                                />
                                <path
                                    id="Path_1763"
                                    data-name="Path 1763"
                                    d="M211.334,247.408a3.423,3.423,0,0,1,3.753-4.056,4.37,4.37,0,0,1,3.089,1.05,4.256,4.256,0,0,1,1.269,2.942,4.649,4.649,0,0,1-.3,2.252,2.961,2.961,0,0,1-1.237,1.367,4.208,4.208,0,0,1-2.142.489,5.206,5.206,0,0,1-2.224-.422,3.665,3.665,0,0,1-1.5-1.335A4.7,4.7,0,0,1,211.334,247.408Zm2.421.01a2.787,2.787,0,0,0,.564,1.705,1.606,1.606,0,0,0,1.236.518,1.386,1.386,0,0,0,1.171-.507,2.773,2.773,0,0,0,.3-1.822,2.581,2.581,0,0,0-.563-1.617,1.641,1.641,0,0,0-1.247-.51,1.365,1.365,0,0,0-1.138.518A2.516,2.516,0,0,0,213.755,247.418Z"
                                    fill="#00acfd"
                                />
                                <path
                                    id="Path_1764"
                                    data-name="Path 1764"
                                    d="M224.152,243.486h3.6a4.453,4.453,0,0,1,1.739.289,3.085,3.085,0,0,1,1.141.828,3.725,3.725,0,0,1,.71,1.255,6,6,0,0,1,.3,1.518,5.126,5.126,0,0,1-.146,1.947,2.928,2.928,0,0,1-.71,1.16,2.358,2.358,0,0,1-1.045.622,5.42,5.42,0,0,1-1.427.214h-3.6Zm2.548,1.774.309,4.28h.593a2.409,2.409,0,0,0,1.067-.169,1.033,1.033,0,0,0,.46-.587,3.593,3.593,0,0,0,.083-1.36,2.8,2.8,0,0,0-.528-1.705,1.834,1.834,0,0,0-1.38-.459Z"
                                    fill="#00acfd"
                                />
                                <path
                                    id="Path_1765"
                                    data-name="Path 1765"
                                    d="M237.448,250.026H234.7l-.289,1.293H231.94l2.38-7.833h2.64l3.507,7.833h-2.534Zm-.624-1.694-1.068-2.816-.652,2.816Z"
                                    fill="#00acfd"
                                />
                                <path
                                    id="Path_1766"
                                    data-name="Path 1766"
                                    d="M239.369,243.486h7.357l.139,1.934H244.4l.425,5.9H242.4l-.424-5.9h-2.469Z"
                                    fill="#00acfd"
                                />
                                <path
                                    id="Path_1767"
                                    data-name="Path 1767"
                                    d="M252.365,250.026h-2.747l-.289,1.293h-2.472l2.38-7.833h2.64l3.508,7.833H252.85Zm-.624-1.694-1.067-2.816-.653,2.816Z"
                                    fill="#00acfd"
                                />
                            </g>
                        </g>
                    </g>
                </g>
                <g id="Character">
                    <g id="Group_5696" data-name="Group 5696">
                        <path
                            id="Path_1768"
                            data-name="Path 1768"
                            d="M353.688,151.413c.894-.447,1.959-1.027,2.944-1.567,1-.552,2-1.126,2.984-1.725,1.959-1.205,3.933-2.4,5.808-3.722a76.9,76.9,0,0,0,10.512-8.653c.418-.382.773-.805,1.16-1.209l.57-.612.286-.306.143-.153c.037-.041.029-.032.01-.009l-.031.049c-.143.267-.024.186.031-.072a5.459,5.459,0,0,0,.141-1.09,30.024,30.024,0,0,0-.729-6.319c-.89-4.485-2.238-9.1-3.571-13.617l3.886-1.7a82.01,82.01,0,0,1,6.112,13.6,31.88,31.88,0,0,1,1.719,7.894,12.209,12.209,0,0,1-.083,2.51,7.606,7.606,0,0,1-1.223,3.284l-.166.231-.129.168-.154.187-.308.375-.618.749c-.417.494-.812,1.015-1.255,1.478a71.094,71.094,0,0,1-11.38,10.151c-2.037,1.489-4.12,2.9-6.283,4.19q-1.615.978-3.277,1.886c-1.123.624-2.191,1.167-3.467,1.765Z"
                            fill="#ffbe9d"
                        />
                        <path
                            id="Path_1769"
                            data-name="Path 1769"
                            d="M344.794,386.512a10.171,10.171,0,0,0,2.211-.3.2.2,0,0,0,.064-.359c-.287-.189-2.831-1.83-3.8-1.389a.672.672,0,0,0-.4.557,1.127,1.127,0,0,0,.329,1.054A2.4,2.4,0,0,0,344.794,386.512Zm1.65-.584c-1.458.291-2.555.241-2.979-.146a.743.743,0,0,1-.2-.705.282.282,0,0,1,.165-.252C343.94,384.593,345.407,385.3,346.444,385.928Z"
                            fill="#407bff"
                        />
                        <path
                            id="Path_1770"
                            data-name="Path 1770"
                            d="M346.96,386.217a.2.2,0,0,0,.1-.027.2.2,0,0,0,.1-.171c0-.1-.011-2.514-.922-3.321a1.033,1.033,0,0,0-.84-.265.692.692,0,0,0-.667.554c-.189.944,1.334,2.751,2.133,3.2A.2.2,0,0,0,346.96,386.217Zm-1.425-3.4a.638.638,0,0,1,.438.174,4.529,4.529,0,0,1,.776,2.637c-.8-.636-1.746-1.994-1.631-2.568.019-.1.073-.207.326-.238C345.475,382.824,345.505,382.822,345.535,382.822Z"
                            fill="#407bff"
                        />
                        <path
                            id="Path_1771"
                            data-name="Path 1771"
                            d="M346.223,126.4c-.974,4.97-3.028,15.037.45,18.354,0,0-1.359,5.042-10.594,5.042-10.156,0-4.854-5.041-4.854-5.041,5.543-1.324,5.4-5.436,4.434-9.3Z"
                            fill="#ffbe9d"
                        />
                        <path
                            id="Path_1772"
                            data-name="Path 1772"
                            d="M329.284,146.758c-1.594.216-.238-3.914.41-4.342,1.5-.988,20.851-2.389,20.725-.04-.08,1.01-.555,2.983-1.4,3.654S343.2,144.675,329.284,146.758Z"
                            fill="#263238"
                        />
                        <path
                            id="Path_1773"
                            data-name="Path 1773"
                            d="M332.437,145.367c-1.266.429-1.146-3.732-.714-4.234,1-1.158,16.663-5.183,17.129-2.9.179.983.265,2.947-.272,3.709S343.439,141.439,332.437,145.367Z"
                            fill="#263238"
                        />
                        <path
                            id="Path_1774"
                            data-name="Path 1774"
                            d="M326.61,117.441a.4.4,0,0,1-.331-.145,3.158,3.158,0,0,0-2.589-1.228.4.4,0,1,1-.086-.789,3.936,3.936,0,0,1,3.29,1.515.4.4,0,0,1-.284.647Z"
                            fill="#263238"
                        />
                        <path
                            id="Path_1775"
                            data-name="Path 1775"
                            d="M324.672,122.334a18.054,18.054,0,0,1-1.974,4.527,2.9,2.9,0,0,0,2.441.214Z"
                            fill="#f6aa84"
                        />
                        <path
                            id="Path_1776"
                            data-name="Path 1776"
                            d="M325.16,121.12c.066.672-.236,1.252-.676,1.3s-.849-.467-.915-1.14.236-1.252.676-1.295S325.094,120.447,325.16,121.12Z"
                            fill="#263238"
                        />
                        <path
                            id="Path_1777"
                            data-name="Path 1777"
                            d="M324.443,120l-1.661-.307S323.735,120.872,324.443,120Z"
                            fill="#263238"
                        />
                        <path
                            id="Path_1778"
                            data-name="Path 1778"
                            d="M356.7,386.019h-8.376l.657-19.4h8.376Z"
                            fill="#ffb573"
                        />
                        <path
                            id="Path_1779"
                            data-name="Path 1779"
                            d="M347.661,385.049h9.41a.664.664,0,0,1,.669.572l1.072,7.445a1.346,1.346,0,0,1-1.343,1.49c-3.281-.057-4.862-.249-9-.249-2.546,0-6.266.264-9.782.264-3.439,0-3.7-3.476-2.239-3.791,6.564-1.412,7.6-3.359,9.813-5.216A2.185,2.185,0,0,1,347.661,385.049Z"
                            fill="#263238"
                        />
                        <g id="Group_5688" data-name="Group 5688" opacity="0.2">
                            <path
                                id="Path_1780"
                                data-name="Path 1780"
                                d="M357.359,366.626h-8.38l-.335,10h8.38Z"
                            />
                        </g>
                        <path
                            id="Path_1781"
                            data-name="Path 1781"
                            d="M323.373,156.377a164.354,164.354,0,0,1-15.049-6.754,90.713,90.713,0,0,1-14.4-8.774,32.984,32.984,0,0,1-3.421-3.07c-.279-.3-.558-.577-.832-.922a13.074,13.074,0,0,1-.879-1.131,7.963,7.963,0,0,1-1.338-3.608,8.129,8.129,0,0,1,.491-3.621,10.923,10.923,0,0,1,1.407-2.607,18.633,18.633,0,0,1,3.389-3.521,41.952,41.952,0,0,1,7.3-4.666c1.24-.658,2.5-1.251,3.784-1.8s2.541-1.071,3.906-1.541l1.775,3.853c-4.361,2.749-8.88,5.771-12.2,9.159a13.713,13.713,0,0,0-1.958,2.491c-.475.787-.469,1.376-.388,1.33.041,0,.024-.018.14.1l.4.427c.149.179.361.362.549.545a25.382,25.382,0,0,0,2.7,2.169,64.3,64.3,0,0,0,6.433,3.97c2.255,1.252,4.573,2.45,6.935,3.587,4.713,2.256,9.6,4.428,14.392,6.412Z"
                            fill="#ffbe9d"
                        />
                        <path
                            id="Path_1782"
                            data-name="Path 1782"
                            d="M311.969,115.487l1.385-3-6.3-2.186s-1.961,5.9.377,8.626h0A6.04,6.04,0,0,0,311.969,115.487Z"
                            fill="#f6aa84"
                        />
                        <path
                            id="Path_1783"
                            data-name="Path 1783"
                            d="M313.354,105.989l-5.033-1.451-1.263,5.758,6.3,2.186Z"
                            fill="#f6aa84"
                        />
                        <path
                            id="Path_1784"
                            data-name="Path 1784"
                            d="M378.35,112.774l.733-7.02-6.488,1.525s-.1,6.581,3.337,7.629Z"
                            fill="#f6aa84"
                        />
                        <path
                            id="Path_1785"
                            data-name="Path 1785"
                            d="M375.958,100.84l-4.227,1.974.865,4.465,6.488-1.525Z"
                            fill="#f6aa84"
                        />
                        <path
                            id="Path_1786"
                            data-name="Path 1786"
                            d="M347.541,116.588c.305,8.308.611,11.817-3.143,16.466-5.646,6.993-15.917,5.55-18.7-2.5-2.5-7.245-2.582-19.614,5.178-23.695A11.339,11.339,0,0,1,347.541,116.588Z"
                            fill="#ffbe9d"
                        />
                        <g id="Group_5689" data-name="Group 5689">
                            <path
                                id="Path_1787"
                                data-name="Path 1787"
                                d="M343.921,133.039c8.156-3.525,13.52-10.955,11.255-22.575-2.166-11.116-9.671-12.214-12.771-9.908s-10.827-1.131-15.464,2.772c-8.034,6.761-.438,14.049,3.518,18.369C332.823,126.555,335.919,136.5,343.921,133.039Z"
                                fill="#263238"
                            />
                            <path
                                id="Path_1788"
                                data-name="Path 1788"
                                d="M340.535,108.47a8.287,8.287,0,1,0,1.824-11.916A8.531,8.531,0,0,0,340.535,108.47Z"
                                fill="#00acfd"
                            />
                            <path
                                id="Path_1789"
                                data-name="Path 1789"
                                d="M342.652,101.335c-1.177-6.7,5.161-11.863,13.636-9.367s3.992,10.009,2.254,16.009,2.823,11.715,4.73,7.145-1.3-5.98-1.3-5.98,9,2.333.639,12.478-15.928-1.057-13.882-7.668C350.385,108.609,343.765,107.667,342.652,101.335Z"
                                fill="#263238"
                            />
                            <path
                                id="Path_1790"
                                data-name="Path 1790"
                                d="M334.182,103.866c-3.869-2.018-10.421-3.65-14.15,2.624-1.763,2.966-1.08,6.989-1.08,6.989l11.389.758Z"
                                fill="#263238"
                            />
                            <path
                                id="Path_1791"
                                data-name="Path 1791"
                                d="M317.612,111.511H317.6a.25.25,0,0,1-.234-.265c.011-.17.286-4.193,2.714-6.716,5.869-6.1,12.75-1.04,14.719.656a.25.25,0,0,1-.326.379c-1.885-1.623-8.467-6.47-14.032-.689-2.3,2.39-2.573,6.361-2.576,6.4A.25.25,0,0,1,317.612,111.511Z"
                                fill="#263238"
                            />
                        </g>
                        <path
                            id="Path_1792"
                            data-name="Path 1792"
                            d="M331.852,121.367a6.919,6.919,0,0,1-1.655,4.277c-1.382,1.616-2.966.82-3.329-.972-.327-1.613,0-4.4,1.744-5.373C330.333,118.337,331.867,119.516,331.852,121.367Z"
                            fill="#f6aa84"
                        />
                        <g id="Group_5690" data-name="Group 5690">
                            <path
                                id="Path_1793"
                                data-name="Path 1793"
                                d="M330.053,197.455s.534,58.144,5.578,90.545c4.074,26.17,11.613,86.689,11.613,86.689h11.423s1.116-58.424-.952-84.308c-5.233-65.5,8.271-77.743-2.626-92.926Z"
                                fill="#263238"
                            />
                            <path
                                id="Path_1794"
                                data-name="Path 1794"
                                d="M330.053,197.455s.534,58.144,5.578,90.545c4.074,26.17,11.613,86.689,11.613,86.689h11.423s1.116-58.424-.952-84.308c-5.233-65.5,8.271-77.743-2.626-92.926Z"
                                fill="#fff"
                                opacity="0.1"
                            />
                        </g>
                        <path
                            id="Path_1795"
                            data-name="Path 1795"
                            d="M336.057,223.833c4.01,17.55.81,45.19-1.38,57.36-2.32-18.48-3.49-42.429-4.07-59.98C332.677,217.853,334.637,217.633,336.057,223.833Z"
                            opacity="0.3"
                        />
                        <path
                            id="Path_1796"
                            data-name="Path 1796"
                            d="M345.681,374.915H360.23l.764-5.1-15.4-.521Z"
                            fill="#407bff"
                        />
                        <g id="Group_5691" data-name="Group 5691">
                            <path
                                id="Path_1797"
                                data-name="Path 1797"
                                d="M349.833,148.375c1.369-2.721,8.725-4.433,12.746-4.427l3.01,13.365s-8.011,11.89-11.343,10.629C350.31,166.455,346.9,154.205,349.833,148.375Z"
                                fill="#407bff"
                            />
                            <path
                                id="Path_1798"
                                data-name="Path 1798"
                                d="M349.833,148.375c1.369-2.721,8.725-4.433,12.746-4.427l3.01,13.365s-8.011,11.89-11.343,10.629C350.31,166.455,346.9,154.205,349.833,148.375Z"
                                fill="#31bdff"
                            />
                        </g>
                        <path
                            id="Path_1799"
                            data-name="Path 1799"
                            d="M341.353,371.646a.2.2,0,0,0-.2-.3c-.42.064-4.122.651-4.622,1.708a.639.639,0,0,0,.038.625,1.1,1.1,0,0,0,.858.57c1.213.125,2.965-1.557,3.9-2.572A.232.232,0,0,0,341.353,371.646Zm-4.441,1.537c.343-.56,2.256-1.078,3.718-1.346-1.32,1.341-2.481,2.088-3.161,2.018a.714.714,0,0,1-.559-.38.248.248,0,0,1-.02-.251Z"
                            fill="#407bff"
                        />
                        <path
                            id="Path_1800"
                            data-name="Path 1800"
                            d="M341.353,371.646a.173.173,0,0,0,.018-.039.2.2,0,0,0-.037-.194c-.063-.072-1.546-1.781-2.913-1.871a1.437,1.437,0,0,0-1.09.37c-.411.368-.376.688-.273.891.433.858,3.093,1.157,4.166.934A.2.2,0,0,0,341.353,371.646Zm-3.915-1.259a.832.832,0,0,1,.158-.18,1.038,1.038,0,0,1,.8-.269,4.594,4.594,0,0,1,2.383,1.461c-1.185.1-3.106-.26-3.366-.775A.22.22,0,0,1,337.438,370.387Z"
                            fill="#407bff"
                        />
                        <path
                            id="Path_1801"
                            data-name="Path 1801"
                            d="M350.083,368.1l-7.661,3.4-3.414-7.1-4.461-9.3-.523-1.082,7.652-3.408.592,1.218,4.325,9.01Z"
                            fill="#ffb573"
                        />
                        <path
                            id="Path_1802"
                            data-name="Path 1802"
                            d="M346.593,360.83l-7.585,3.567-4.461-9.3,7.721-3.272Z"
                            opacity="0.2"
                        />
                        <g id="Group_5692" data-name="Group 5692">
                            <path
                                id="Path_1803"
                                data-name="Path 1803"
                                d="M321.069,197.455s-24.473,60.835-17.84,89.482c5.995,25.89,32.5,74.921,32.5,74.921l10.25-5.145s-16.388-59.188-18.166-72.046c-3.459-25.019,18.776-60.334,18.776-87.212Z"
                                fill="#263238"
                            />
                            <path
                                id="Path_1804"
                                data-name="Path 1804"
                                d="M321.069,197.455s-24.473,60.835-17.84,89.482c5.995,25.89,32.5,74.921,32.5,74.921l10.25-5.145s-16.388-59.188-18.166-72.046c-3.459-25.019,18.776-60.334,18.776-87.212Z"
                                fill="#fff"
                                opacity="0.1"
                            />
                        </g>
                        <path
                            id="Path_1805"
                            data-name="Path 1805"
                            d="M341.055,370.437l7.609-5.538a.662.662,0,0,1,.877.069l5.248,5.389a1.346,1.346,0,0,1-.209,1.994c-2.686,1.885-4.078,2.66-7.424,5.1-2.058,1.5-6.16,4.811-9,6.88-2.78,2.024-5.04-.631-4.041-1.747,4.476-5.005,5.419-8.1,6.114-10.9A2.181,2.181,0,0,1,341.055,370.437Z"
                            fill="#263238"
                        />
                        <path
                            id="Path_1806"
                            data-name="Path 1806"
                            d="M335.073,364.027l13.23-6.051-1.7-5.392-14.168,6.462Z"
                            fill="#407bff"
                        />
                        <g id="Group_5693" data-name="Group 5693">
                            <path
                                id="Path_1807"
                                data-name="Path 1807"
                                d="M327.564,147.693c-1.1-2.84-10.657-5.956-15.453-7.044L310,156.417s7.831,11.255,11.269,10.323C325.335,165.642,329.916,153.78,327.564,147.693Z"
                                fill="#4ba2ff"
                            />
                            <path
                                id="Path_1808"
                                data-name="Path 1808"
                                d="M327.564,147.693c-1.1-2.84-10.657-5.956-15.453-7.044L310,156.417s7.831,11.255,11.269,10.323C325.335,165.642,329.916,153.78,327.564,147.693Z"
                                fill="#31bdff"
                            />
                        </g>
                        <g id="Group_5694" data-name="Group 5694">
                            <path
                                id="Path_1809"
                                data-name="Path 1809"
                                d="M317.066,146.9s-3.99,1.406,4,50.555h34.02c-.571-13.845-.587-22.383,6.03-50.8a100.473,100.473,0,0,0-14.446-1.9,108.089,108.089,0,0,0-15.448,0C324.591,145.36,317.066,146.9,317.066,146.9Z"
                                fill="#407bff"
                            />
                            <path
                                id="Path_1810"
                                data-name="Path 1810"
                                d="M317.066,146.9s-3.99,1.406,4,50.555h34.02c-.571-13.845-.587-22.383,6.03-50.8a100.473,100.473,0,0,0-14.446-1.9,108.089,108.089,0,0,0-15.448,0C324.591,145.36,317.066,146.9,317.066,146.9Z"
                                fill="#00acfd"
                            />
                        </g>
                        <g id="Group_5695" data-name="Group 5695">
                            <path
                                id="Path_1811"
                                data-name="Path 1811"
                                d="M355.6,195.459l1.534,3.045c.119.236-.161.477-.554.477H320.9c-.306,0-.56-.15-.58-.343l-.307-3.045c-.021-.211.245-.39.58-.39h34.45A.619.619,0,0,1,355.6,195.459Z"
                                fill="#407bff"
                            />
                            <path
                                id="Path_1812"
                                data-name="Path 1812"
                                d="M355.6,195.459l1.534,3.045c.119.236-.161.477-.554.477H320.9c-.306,0-.56-.15-.58-.343l-.307-3.045c-.021-.211.245-.39.58-.39h34.45A.619.619,0,0,1,355.6,195.459Z"
                                fill="#2a3235"
                            />
                        </g>
                        <path
                            id="Path_1813"
                            data-name="Path 1813"
                            d="M350.971,199.311h.923c.184,0,.324-.1.311-.211l-.433-3.956c-.012-.116-.173-.211-.357-.211h-.922c-.184,0-.324.1-.312.211l.433,3.956C350.627,199.216,350.788,199.311,350.971,199.311Z"
                            fill="#5b7b8b"
                        />
                        <path
                            id="Path_1814"
                            data-name="Path 1814"
                            d="M328.608,199.311h.922c.184,0,.324-.1.311-.211l-.433-3.956c-.012-.116-.173-.211-.357-.211h-.922c-.184,0-.324.1-.311.211l.432,3.956C328.263,199.216,328.424,199.311,328.608,199.311Z"
                            fill="#5b7b8b"
                        />
                    </g>
                </g>
            </g>
        </SVGIcon>
    );
}
