import React from 'react';

import { SVGIcon } from '../../UIKit/index';

export default function DCStore(props) {
    return (
        <SVGIcon {...props} viewBox="0 0 512 512">
            <g>
                <path d="M36.84,253.81l30.46,8.24v187.54h46.95V319.22c0,0,2.11-9.57,10.54-10.05h261.32c0,0,9.57,0.73,10.14,10.7   v130.73h-31.57V340.46H145.86v109.73H365h31.24h47.35V261.54l30.27-7.05c0,0,0.81,1.16,0.92,2.68v212.91c0,0-0.22,9-9.5,11.15   H45.27c0,0-8.97-2.11-9.3-10.38V257.16C35.97,257.16,36.03,255.16,36.84,253.81z" />
                <path d="M55.3,27.09c0,0-9.08-0.49-11.19,9.56L5.97,189.97l-1.32,9.57c0,0-2.07,50.46,47.32,59.19l15.32,3.32   c0,0,25.22,2.68,46.95-21.32c0,0,16.05,21.48,47.84,21.41c0,0,24.97,2.1,45.89-21.41c0,0,14.76,18.44,41.68,21.46   c0,0,29.84,3.67,52.22-21.3c0,0,18.32,21.41,46.54,21.49c0,0,27.73,1.05,47.84-21.33c0,0,20.19,22.95,47.76,20.92l30.14-7.02   c0,0,31.86-17.3,31.86-55.57c0,0-0.24-5.84-1.22-11.35L467.38,42.3c0,0,1.51-13.35-10.05-15.21h-17.95l1.14,31.21l33.08,131.84   l0.97,8.76c0,0,1.14,26.27-27.89,31.78c0,0-26.76,3.73-34.22-26.43c0,0,2.7-16.54-13.51-20.76c0,0-15.57-1.62-18.05,14.05   c0,0,1.08,26.97-27.62,33.14c0,0-28.38,3.3-34.97-26.86l-0.11-6.76c0,0-1.65-11.62-13.57-13.57c0,0-14.68-2.19-18.16,14.19   l-0.16,6.08c0,0-3.14,23.41-27.46,26.65h-6.59c0,0-22.05-0.86-28.32-27.03l0.11-6.84c0,0-1.7-11.11-13.7-12.89   c0,0-14.3-1.78-17.22,12.16l-1.08,9.08c0,0-3.03,22.16-27.57,25.62h-6.7c0,0-12.22,0-22.16-13.08c0,0-4.32-6.7-5.51-15.14   l-0.22-5.73c0,0-1.27-10-12.62-12.84l-5.59-0.16c0,0-11.03,1.54-12.81,12.73l-0.49,9.16c0,0-5.19,22.3-27.73,24.89   c0,0-30.38,4.38-34.65-30.43l0.7-9.08L70.62,58.51h371.32l-0.35-31.43H55.3z" />
            </g>
        </SVGIcon>
    );
}
