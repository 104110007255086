import React from 'react';

import { SVGIcon } from '../../UIKit/index';

export default function DCGoal(props) {
    return (
        <SVGIcon {...props} viewBox="0 0 512 512">
            <g>
                <path d="M471.2,125.1l24.5-24.5c5.8-5.8,5.8-15.1,0-20.9c-2.5-2.5-5.7-4-9.2-4.3l-45.4-3.8l-3.8-45.4  c-0.7-8.1-7.8-14.2-16-13.5c-3.5,0.3-6.7,1.8-9.2,4.3l-24.5,24.5C268.6-31.3,113.2,6,40.5,124.9c-60.9,99.5-45.7,227.8,36.9,310.3  c98.5,98.6,258.3,98.7,357,0.2C516.9,353,532.1,224.6,471.2,125.1z M412.6,86.5c0.6,7.2,6.3,12.9,13.5,13.5l26.1,2.2l-35.4,35.4  l-38.6-3.3L375,95.8l35.4-35.4L412.6,86.5z M306.1,256.8c0,27.8-22.5,50.4-50.3,50.4c-27.8,0-50.4-22.5-50.4-50.3  c0-27.8,22.5-50.4,50.3-50.4c8.2,0,16.3,2,23.6,5.9l-34,34c-5.9,5.7-6,15-0.3,20.9s15,6,20.9,0.3c0.1-0.1,0.2-0.2,0.3-0.3l34-34  C304.1,240.5,306.1,248.6,306.1,256.8z M300.9,190.9c-36.5-24.9-86.2-15.5-111.1,21s-15.5,86.2,21,111.1  c36.5,24.9,86.2,15.5,111.1-21c18.6-27.2,18.5-63,0-90.2l42.2-42.2c48.3,59.8,38.9,147.5-20.9,195.7  c-59.8,48.3-147.5,38.9-195.7-20.9s-38.9-147.5,20.9-195.7c51-41.2,123.8-41.2,174.8,0L300.9,190.9z M413.4,414.4  c-87,87.1-228.1,87.2-315.1,0.2S11.1,186.5,98.1,99.4c0.1-0.1,0.1-0.1,0.2-0.2C169.2,28.3,278.7,13.5,366,63l-16.7,16.7  c-2.7,2.7-4.2,6.3-4.3,10.1c0,0.5,0,1.1,0,1.6l2,23.6c-78.4-50.4-182.8-27.7-233.2,50.7S86,348.4,164.4,398.8s182.8,27.7,233.2-50.7  c35.8-55.6,35.8-127,0-182.6l23.6,2c0.4,0,0.8,0,1.2,0h0.2h0.6c0.3,0,0.6,0,1-0.1l0.6-0.1c0.3,0,0.6-0.1,0.9-0.2l0.6-0.1  c0.3-0.1,0.6-0.2,0.9-0.3l0.6-0.2c0.3-0.1,0.6-0.2,0.9-0.4l0.5-0.3c0.3-0.1,0.6-0.3,0.8-0.5l0.5-0.3c0.3-0.2,0.6-0.4,0.9-0.6  l0.4-0.3c0.4-0.3,0.8-0.7,1.2-1l16.7-16.7C499.1,233.9,484.3,343.5,413.4,414.4L413.4,414.4z" />
            </g>
        </SVGIcon>
    );
}
