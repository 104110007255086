import React from 'react';

import { SVGIcon } from '../../UIKit/index';

export default function DCSearchesPerDay(props) {
    return (
        <SVGIcon {...props} viewBox="0 0 503.585 436.622">
            <g id="Searches_Per_Day" transform="translate(-4.91 -35.168)">
                <ellipse
                    id="_Path_"
                    data-name="&lt;Path&gt;"
                    cx="248.22"
                    cy="71.58"
                    rx="248.22"
                    ry="71.58"
                    transform="translate(4.91 328.63)"
                    fill="#f4f5f6"
                />
                <g id="Group_1" data-name="Group 1" opacity="0.4">
                    <path
                        id="Path_1"
                        data-name="Path 1"
                        d="M486,145.29a73.95,73.95,0,0,1-141.56,29.93,71.677,71.677,0,0,1-3.77-10.63,74.43,74.43,0,0,1-.87-35,1.362,1.362,0,0,1,0-.17,74.08,74.08,0,0,1,28.86-44c1.52-1.11,3.08-2.15,4.69-3.14A73.55,73.55,0,0,1,412,71.35c1.88,0,3.74.07,5.59.21a71.611,71.611,0,0,1,7.68,1,73.45,73.45,0,0,1,28.94,12h0a74.49,74.49,0,0,1,20.28,21.18c1.14,1.79,2.19,3.63,3.17,5.51a73.079,73.079,0,0,1,5.64,14.38A74.081,74.081,0,0,1,486,145.29Z"
                        fill="#f4f5f6"
                    />
                    <path
                        id="Path_2"
                        data-name="Path 2"
                        d="M386.92,112.19c-2.95,4.67,1.23,12.79-7.62,10.57s-14.26-3.19-14.75,1.72,6.64,4.92,6.64,10.09-3.2,6.87-8.12,4.42-6.33-6-12.05-3.81-9,.74-10.32-3.69a6.6,6.6,0,0,0-.92-1.94,1.356,1.356,0,0,1,0-.17,74.08,74.08,0,0,1,28.86-44c1.52-1.11,3.08-2.15,4.69-3.14a9.75,9.75,0,0,1,7.41,3.87c5.16,6.39,8.6,3.19,7.37,10.57s.74,8.36,4.92,8.11,9.1-.24,9.59,3S389.87,107.52,386.92,112.19Z"
                        fill="#e4e3e3"
                    />
                    <path
                        id="Path_3"
                        data-name="Path 3"
                        d="M417.53,156.45S411,158.54,409.3,154s.24-5.53-5.54-6.89-6.27-5.28-9.95-4.79-1.85,2-6.15,5.16-8.24,1.72-8.11,7.63,6.51,6.82,7.8,10.69-.92,5.53,1.85,8.48,7.93,1.66,5.53,5.72-6.09,7.19-4.24,13.46.55,6.64,3.69,11.07,9.22,5.9,10.14,2.95-4.24-6.83-2.58-9.78,4.42-.92,5.53-5.34,5.59-4.43,6-7.5-5.41-7.75-3.2-9.72,6.64,3.57,10.09-1.35,1.1-8.85,4.67-13.52S420,153.74,417.53,156.45Z"
                        fill="#e4e3e3"
                    />
                    <path
                        id="Path_4"
                        data-name="Path 4"
                        d="M486,145.29A73.68,73.68,0,0,1,473.11,187a8.42,8.42,0,0,1-5.91-.16c-9.22-3.68-2.4-13.64,1.29-18.62s-5.71-2.77-12.54-7.38.74-17-6.27-14.94-15.49,4.61-19.54-2.21,3.68-7.93.18-13.64,2-9,9-9.59,6.08-5.54,14.75-6.27,8.48,10.32,18.07,9.59,9.16,1.47,9.16,1.47a4.5,4.5,0,0,1,2,.42,74.081,74.081,0,0,1,2.7,19.62Z"
                        fill="#e4e3e3"
                    />
                    <path
                        id="Path_5"
                        data-name="Path 5"
                        d="M477.7,111.26c-8,3.88-11.17-6.67-18-3.5s-5.66-3-11.07-6.88-16.23,3.2-15.74-2.95,8.61-4.43,10.33-9.34,9.59-3.69,9.59-3.69a5.43,5.43,0,0,1,1.43-.33h0a74.49,74.49,0,0,1,20.28,21.18C475.67,107.54,476.72,109.38,477.7,111.26Z"
                        fill="#e4e3e3"
                    />
                    <path
                        id="Path_6"
                        data-name="Path 6"
                        d="M410.53,90.06s4.18-6-2.46-7.81-6.52.55-11.68,0-7.19,3.45-3.32,7.81,7,12.74,10.51,8.28S406.41,90.49,410.53,90.06Z"
                        fill="#e4e3e3"
                    />
                </g>
                <path
                    id="Path_7"
                    data-name="Path 7"
                    d="M438.56,134.07V364.22c0,4-2.91,7.29-6.49,7.29H100c-3.59,0-6.49-3.26-6.49-7.29V134.07c0-4,2.9-7.29,6.49-7.29H432.07C435.65,126.78,438.56,130,438.56,134.07Z"
                    fill="#f0efef"
                />
                <path
                    id="Path_8"
                    data-name="Path 8"
                    d="M438.56,133.27v17.38h-345V133.27a6.48,6.48,0,0,1,6.49-6.49H432.07a6.49,6.49,0,0,1,6.49,6.49Z"
                    fill="#565656"
                />
                <path
                    id="Path_9"
                    data-name="Path 9"
                    d="M104.08,138.71a3,3,0,1,1,3,3,3,3,0,0,1-3-3Z"
                    fill="#f4f5f6"
                />
                <path
                    id="Path_10"
                    data-name="Path 10"
                    d="M116,138.71a3,3,0,1,1,3,3,3,3,0,0,1-3-3Z"
                    fill="#f4f5f6"
                />
                <path
                    id="Path_11"
                    data-name="Path 11"
                    d="M128.19,138.71a3,3,0,1,1,3,3,3,3,0,0,1-3-3Z"
                    fill="#f4f5f6"
                />
                <rect
                    id="Rectangle_1"
                    data-name="Rectangle 1"
                    width="36.39"
                    height="36.39"
                    transform="translate(279.18 299.86)"
                    fill="#707272"
                    opacity="0.1"
                />
                <path
                    id="Path_12"
                    data-name="Path 12"
                    d="M281.91,332.1l10.75-19.38,7.91,10.78,3.94-4.32,8.37,12.92Z"
                    fill="#565656"
                />
                <path
                    id="Path_13"
                    data-name="Path 13"
                    d="M297.78,309.93a2.79,2.79,0,1,1,2.79,2.79A2.79,2.79,0,0,1,297.78,309.93Z"
                    fill="#565656"
                />
                <rect
                    id="Rectangle_2"
                    data-name="Rectangle 2"
                    width="90.73"
                    height="52.59"
                    transform="translate(279.18 233.25)"
                    fill="#707272"
                    opacity="0.1"
                />
                <path
                    id="Path_14"
                    data-name="Path 14"
                    d="M314.31,246.07v26.95l20.47-13.48Z"
                    fill="#565656"
                />
                <path
                    id="Path_15"
                    data-name="Path 15"
                    d="M411.42,194.09a21.72,21.72,0,0,1-6.19,15.24,20.72,20.72,0,0,1-14.88,6.34H144.82c-12.54,0-22.79-9.71-22.79-21.58a21,21,0,0,1,6.69-15.24,23.38,23.38,0,0,1,16.1-6.33H390.35C401.94,172.52,411.42,182.22,411.42,194.09Z"
                    fill="#fff"
                />
                <path
                    id="Path_16"
                    data-name="Path 16"
                    d="M411.42,194.09a21.72,21.72,0,0,1-6.19,15.24,20.72,20.72,0,0,1-14.88,6.34H345.92V172.52h44.43C401.94,172.52,411.42,182.22,411.42,194.09Z"
                    fill="#565656"
                />
                <path
                    id="Path_17"
                    data-name="Path 17"
                    d="M383.3,201.94a10.162,10.162,0,0,1-1.64-.13,11,11,0,0,1-4.92-19.71h0a11,11,0,1,1,6.56,19.84Zm-5.49-18.39a9.19,9.19,0,1,0,12.855,1.915A9.19,9.19,0,0,0,377.81,183.55Z"
                    fill="#fff"
                />
                <rect
                    id="Rectangle_3"
                    data-name="Rectangle 3"
                    width="1.81"
                    height="15.01"
                    transform="translate(374.645 196.208) rotate(53.49)"
                    fill="#fff"
                />
                <path
                    id="Path_18"
                    data-name="Path 18"
                    d="M358.91,209h0a1.62,1.62,0,0,1,.34-2.26l7.11-5.26a1.62,1.62,0,0,1,2.26.34h0a1.61,1.61,0,0,1-.34,2.26l-7.1,5.26a1.63,1.63,0,0,1-2.27-.34Z"
                    fill="#fff"
                />
                <path
                    id="Path_19"
                    data-name="Path 19"
                    d="M194.65,164.36a1.64,1.64,0,0,0-.29,1.75h0c.43,1.17,1.83,2.48,3.46,3.79l.55.46.12.11.1.09c1.52,1.4.95,1.72,3.67,4.56,3.11,3.23,5.25,3.29,5.86,3s-.53-2-1.69-3.9l-.17-.28c-.16-.25-.31-.51-.46-.77-1.36-2.32-1-3.41-.69-6.86.26-2.81,4.37-15.68,4.37-15.68l-5.95-1s-1.8,10.25-3.78,11.83c-1.06.84-2.24.85-3.25,1.47l-.22.14a7.809,7.809,0,0,0-1.63,1.29Z"
                    fill="#e4e3e3"
                />
                <path
                    id="Path_20"
                    data-name="Path 20"
                    d="M194.65,164.36a1.64,1.64,0,0,0-.29,1.75h0a12.7,12.7,0,0,0,3.46,3.78l.55.47.12.11.1.09c1.52,1.4.95,1.72,3.67,4.56,3.11,3.23,5.25,3.29,5.86,3s-.53-2-1.69-3.9a13.15,13.15,0,0,1-5.54-4.08c-1.81-2.3-3-5.24-4.26-7l-.35-.06a7.81,7.81,0,0,0-1.63,1.28Z"
                    fill="#565656"
                />
                <path
                    id="Path_21"
                    data-name="Path 21"
                    d="M242.69,160.65c.37,1.84,3,1.53,5.92.82s2.23.14,6.4-.32,5.43-2,5.56-2.64-1.95-.74-4.16-1l-.83-.1c-2.5-.31-4.32-1.92-6.59-3.24s-5.34-6.36-5.34-6.36l-5.72.45s3,4.56,4.16,6.54a7,7,0,0,1,.56,3.9A7.09,7.09,0,0,0,242.69,160.65Z"
                    fill="#e4e3e3"
                />
                <path
                    id="Path_22"
                    data-name="Path 22"
                    d="M248.61,161.47c2.93-.72,2.23.14,6.4-.32s5.43-2,5.56-2.64-1.95-.74-4.16-1h0a11.7,11.7,0,0,1-6,1.86c-2.83.07-5.82-.75-7.77-.71a7.089,7.089,0,0,0,0,2C243.06,162.49,245.68,162.18,248.61,161.47Z"
                    fill="#565656"
                />
                <path
                    id="Path_23"
                    data-name="Path 23"
                    d="M220.8,124.1l-5.35,14.37-7,18.77a11.39,11.39,0,0,1-3.71-.34,15.929,15.929,0,0,1-3.58-1.74s1.94-20.16,5.56-31.73a20.74,20.74,0,0,1,3.11-6.72C215.46,110.09,220.8,124.1,220.8,124.1Z"
                    fill="#565656"
                />
                <path
                    id="Path_24"
                    data-name="Path 24"
                    d="M215.37,101.26c-3-1-11,0-19.58,1.53a5.83,5.83,0,0,1-6-2.72h0l-21.66-1.7s-7.16,15.85-3.25,25.95a3.81,3.81,0,0,0,3.79,2.45h17.28c10.77,0,22.8-7.4,24.35-7.71,2.55-.51,3.57,4.59,6.11,9.94s23.93,23.69,23.93,23.69a18.88,18.88,0,0,0,4.65-.48,4.45,4.45,0,0,0,2-1.26S223.27,103.81,215.37,101.26Z"
                    fill="#636464"
                />
                <path
                    id="Path_25"
                    data-name="Path 25"
                    d="M187.48,36.46a7.66,7.66,0,0,0-4.14-1.08,12,12,0,0,1-2-.12C173.08,34,168.09,46.05,167.8,49.68c-.54,6.66-4.06,7.67-9,11.84-9.95,8.37-7,18.09-1.6,21.7,3.26,2.17,9,0,9,0s15.74-8.75,23.45-13C201.21,68.6,192.13,39.22,187.48,36.46Z"
                    fill="#565656"
                />
                <path
                    id="Path_26"
                    data-name="Path 26"
                    d="M183.18,66.62s-3.8,1.33-6.36.19c-2.41-1.06-4.23-5-4.19-5,1.09-.05,2.3-1,3.22-7.29l.47.17,7.85,2.82s-.57,2.52-.94,4.92C182.92,64.41,182.75,66.34,183.18,66.62Z"
                    fill="#e4e3e3"
                />
                <path
                    id="Path_27"
                    data-name="Path 27"
                    d="M184.17,57.48s-.32,1.36-.78,3.9a8.87,8.87,0,0,1-7.07-6.72Z"
                    fill="#707272"
                    opacity="0.2"
                />
                <path
                    id="Path_28"
                    data-name="Path 28"
                    d="M185.33,60s-10.73-1.44-9.73-8.11.29-11.35,7.19-10.71,7.84,3.38,8.05,5.69S188.15,60.18,185.33,60Z"
                    fill="#e4e3e3"
                />
                <path
                    id="Path_29"
                    data-name="Path 29"
                    d="M190.45,43.19s-6.88,5.92-11.55,6.4-6.44-1-6.44-1a18.2,18.2,0,0,0,4.41-6,3.29,3.29,0,0,1,2.71-1.93C182.92,40.36,189.25,40.23,190.45,43.19Z"
                    fill="#565656"
                />
                <path
                    id="Path_30"
                    data-name="Path 30"
                    d="M186.15,42.66a16.77,16.77,0,0,1,3,3.87,13,13,0,0,1,.35,6.91s3.59-7,1.56-10.35C188.93,39.5,186.15,42.66,186.15,42.66Z"
                    fill="#565656"
                />
                <path
                    id="Path_31"
                    data-name="Path 31"
                    d="M192.21,82.44c-.33,21.72-.09,17.18-2.47,19.24-.57.5-2.18.8-4.3.94-6.67.44-18.38-.67-18.82-1.9-1.54-4.26-.45-5.58-1.73-12.11-.14-.69-.3-1.44-.49-2.26-1.07-4.48-2.11-6,1.68-13.2,3.45-6.52,7-11.81,7.34-11.62,9.07,5.39,9.81.87,9.81.87S192.38,71.84,192.21,82.44Z"
                    fill="#707272"
                />
                <path
                    id="Path_32"
                    data-name="Path 32"
                    d="M215.45,138.47l-7,18.77a11.39,11.39,0,0,1-3.71-.34,15.929,15.929,0,0,1-3.58-1.74s1.94-20.16,5.56-31.73Z"
                    fill="#636464"
                />
                <path
                    id="Path_33"
                    data-name="Path 33"
                    d="M183.23,62.4s9.33,1.6,10.43,5.6,4.7,28.43,4.7,28.43-2.64,2.57-4.83.37S186.64,74,186.64,74Z"
                    fill="#707272"
                />
                <path
                    id="Path_34"
                    data-name="Path 34"
                    d="M185.44,102.62c-6.67.44-18.38-.67-18.82-1.9-1.54-4.26-.45-5.58-1.73-12.11l3.21-9s-.53,10.07,2.44,12.72,12.17,5.13,13.61,7.34a8.14,8.14,0,0,1,1.29,2.95Z"
                    fill="#565656"
                />
                <path
                    id="Path_35"
                    data-name="Path 35"
                    d="M177.81,98.37s6.57-1.46,7.63-.87a15.49,15.49,0,0,1,4,3.45c.32.73-2.37-.57-3.24-.8a7.75,7.75,0,0,0-2.79.61,9.3,9.3,0,0,1-5,.5Z"
                    fill="#e4e3e3"
                />
                <path
                    id="Path_36"
                    data-name="Path 36"
                    d="M173.42,61.53s-8.83.54-11.89,4.8c-4.3,6-12.84,31.56-7.95,35,5.5,3.84,25.06,1,25.06,1s.65-3-.25-4.51-15.62-3.85-16.3-4.53S170.58,80,170.58,78,173.42,61.53,173.42,61.53Z"
                    fill="#707272"
                />
                <path
                    id="Path_37"
                    data-name="Path 37"
                    d="M185.76,99.33a12.661,12.661,0,0,0,1.83,2,1.37,1.37,0,0,0,.74.16l-.93-1.68Z"
                    fill="#e4e3e3"
                />
                <path
                    id="Path_38"
                    data-name="Path 38"
                    d="M185.44,97.5l4,1.94a.71.71,0,0,1-.66.26,9.42,9.42,0,0,1-1.74-.48Z"
                    fill="#e4e3e3"
                />
                <path
                    id="Path_39"
                    data-name="Path 39"
                    d="M219.63,102.08l-41.83-.01v.92l41.46.01Z"
                    fill="#565656"
                />
                <path
                    id="Path_40"
                    data-name="Path 40"
                    d="M187.59,102.99l31.67.01,9.54-23.69-31.63-.01Z"
                    fill="#565656"
                />
                <path
                    id="Path_41"
                    data-name="Path 41"
                    d="M211.27,91.15a1.1,1.1,0,0,0-1.08-1.51,2.17,2.17,0,0,0-1.94,1.51,1.1,1.1,0,0,0,1.08,1.51,2.16,2.16,0,0,0,1.94-1.51Z"
                    fill="#fff"
                />
                <rect
                    id="Rectangle_4"
                    data-name="Rectangle 4"
                    width="61.5"
                    height="5.22"
                    rx="1.6"
                    transform="translate(130.28 238.16)"
                    fill="#565656"
                />
                <g id="Group_2" data-name="Group 2" opacity="0.2">
                    <path
                        id="Path_42"
                        data-name="Path 42"
                        d="M160.28,261.32H132.06a1.78,1.78,0,0,1-1.78-1.78h0a1.78,1.78,0,0,1,1.78-1.78h28.22a1.78,1.78,0,0,1,1.78,1.78h0a1.78,1.78,0,0,1-1.78,1.78Z"
                        fill="#707272"
                    />
                    <rect
                        id="Rectangle_5"
                        data-name="Rectangle 5"
                        width="53.26"
                        height="3.56"
                        rx="1.09"
                        transform="translate(130.28 250.29)"
                        fill="#707272"
                    />
                    <rect
                        id="Rectangle_6"
                        data-name="Rectangle 6"
                        width="49.74"
                        height="3.56"
                        rx="1.09"
                        transform="translate(188.4 250.29)"
                        fill="#707272"
                    />
                    <path
                        id="Path_43"
                        data-name="Path 43"
                        d="M195.47,261.32H167.26a1.77,1.77,0,0,1-1.78-1.78h0a1.78,1.78,0,0,1,1.78-1.78h28.21a1.79,1.79,0,0,1,1.79,1.78h0a1.78,1.78,0,0,1-1.79,1.78Z"
                        fill="#707272"
                    />
                </g>
                <rect
                    id="Rectangle_7"
                    data-name="Rectangle 7"
                    width="61.5"
                    height="5.22"
                    rx="1.6"
                    transform="translate(199.92 238.16)"
                    fill="#565656"
                />
                <rect
                    id="Rectangle_8"
                    data-name="Rectangle 8"
                    width="61.5"
                    height="5.22"
                    rx="1.6"
                    transform="translate(130.28 331.59)"
                    fill="#565656"
                />
                <g id="Group_3" data-name="Group 3" opacity="0.2">
                    <path
                        id="Path_44"
                        data-name="Path 44"
                        d="M160.28,354.75H132.06a1.78,1.78,0,0,1-1.78-1.78h0a1.78,1.78,0,0,1,1.78-1.78h28.22a1.78,1.78,0,0,1,1.78,1.78h0a1.78,1.78,0,0,1-1.78,1.78Z"
                        fill="#707272"
                    />
                    <rect
                        id="Rectangle_9"
                        data-name="Rectangle 9"
                        width="53.26"
                        height="3.56"
                        rx="1.09"
                        transform="translate(130.28 343.72)"
                        fill="#707272"
                    />
                    <rect
                        id="Rectangle_10"
                        data-name="Rectangle 10"
                        width="49.74"
                        height="3.56"
                        rx="1.09"
                        transform="translate(188.4 343.72)"
                        fill="#707272"
                    />
                    <path
                        id="Path_45"
                        data-name="Path 45"
                        d="M195.47,354.75H167.26a1.78,1.78,0,0,1-1.78-1.78h0a1.78,1.78,0,0,1,1.78-1.78h28.21a1.79,1.79,0,0,1,1.79,1.78h0a1.79,1.79,0,0,1-1.79,1.78Z"
                        fill="#707272"
                    />
                </g>
                <rect
                    id="Rectangle_11"
                    data-name="Rectangle 11"
                    width="61.5"
                    height="5.22"
                    rx="1.6"
                    transform="translate(199.92 331.59)"
                    fill="#565656"
                />
                <rect
                    id="Rectangle_12"
                    data-name="Rectangle 12"
                    width="61.5"
                    height="5.22"
                    rx="1.6"
                    transform="translate(130.28 284.88)"
                    fill="#565656"
                />
                <g id="Group_4" data-name="Group 4" opacity="0.2">
                    <path
                        id="Path_46"
                        data-name="Path 46"
                        d="M160.28,308H132.06a1.78,1.78,0,0,1-1.78-1.78h0a1.78,1.78,0,0,1,1.78-1.78h28.22a1.78,1.78,0,0,1,1.78,1.78h0a1.78,1.78,0,0,1-1.78,1.78Z"
                        fill="#707272"
                    />
                    <rect
                        id="Rectangle_13"
                        data-name="Rectangle 13"
                        width="53.26"
                        height="3.56"
                        rx="1.09"
                        transform="translate(130.28 297.01)"
                        fill="#707272"
                    />
                    <rect
                        id="Rectangle_14"
                        data-name="Rectangle 14"
                        width="49.74"
                        height="3.56"
                        rx="1.09"
                        transform="translate(188.4 297.01)"
                        fill="#707272"
                    />
                    <path
                        id="Path_47"
                        data-name="Path 47"
                        d="M195.47,308H167.26a1.78,1.78,0,0,1-1.78-1.78h0a1.77,1.77,0,0,1,1.78-1.78h28.21a1.78,1.78,0,0,1,1.79,1.78h0a1.79,1.79,0,0,1-1.79,1.78Z"
                        fill="#707272"
                    />
                </g>
                <rect
                    id="Rectangle_15"
                    data-name="Rectangle 15"
                    width="61.5"
                    height="5.22"
                    rx="1.6"
                    transform="translate(199.92 284.88)"
                    fill="#565656"
                />
                <path
                    id="Path_48"
                    data-name="Path 48"
                    d="M98.65,265s.64-1.45,1.06-2.14,2.2-2.7,2.5-2.24,1.52,3.52.88,4.38,0,2.35-3.17,3.26Z"
                    fill="#e4e3e3"
                />
                <path
                    id="Path_49"
                    data-name="Path 49"
                    d="M63,255.81s16.43,13.72,22.84,12.45a128.388,128.388,0,0,0,12.7-3.63l1.54,3.93S93.15,277.92,86,278a41,41,0,0,1-19.54-4.87Z"
                    fill="#565656"
                />
                <path
                    id="Path_50"
                    data-name="Path 50"
                    d="M34.15,400.5s.48,7.69,2,7.8H43.7l9.39-.06a3.06,3.06,0,0,0-2.81-2.74c-2.74-.48-7.34-2.4-9-6C39.5,395.67,34.15,400.5,34.15,400.5Z"
                    fill="#565656"
                />
                <path
                    id="Path_51"
                    data-name="Path 51"
                    d="M33.61,401a19.44,19.44,0,0,0,5.08,1,18.609,18.609,0,0,0,4.75-.68s.53-5.2,3.49-16.64c1.55-6,3.76-13.72,6.91-23.3,8.71-26.5,11.36-42,11.6-43.46a1.128,1.128,0,0,1,0-.12h0L38.66,306s3.31,36.51-.91,50.52a58.711,58.711,0,0,0-1.53,6.72C33.55,378.49,33.61,401,33.61,401Z"
                    fill="#707272"
                />
                <path
                    id="Path_52"
                    data-name="Path 52"
                    d="M46.93,384.67c1.55-6,3.76-13.72,6.91-23.3,8.71-26.5,11.36-42,11.6-43.46v-.13L38.66,306s3.31,36.51-.91,50.52C37.16,358.51,46.93,384.67,46.93,384.67Z"
                    fill="#565656"
                />
                <path
                    id="Path_53"
                    data-name="Path 53"
                    d="M55.06,400.5s.48,7.69,2,7.8h7.55l9.38-.06a3,3,0,0,0-2.8-2.74c-2.75-.48-7.34-2.4-9-6C60.41,395.67,55.06,400.5,55.06,400.5Z"
                    fill="#565656"
                />
                <path
                    id="Path_54"
                    data-name="Path 54"
                    d="M70.24,306.89a382.161,382.161,0,0,1-3.75,52.71c-4.09,29-2.71,41.38-2.71,41.38a28.765,28.765,0,0,1-3.94,1,18,18,0,0,1-5.86-.68s-5.86-29.06-4.18-43.61-8-49.89-8-49.89Z"
                    fill="#707272"
                />
                <path
                    id="Path_55"
                    data-name="Path 55"
                    d="M51.26,251.52l5.22,3.42,4.76-1.34c.87-1.24,1.1-3.64,1.11-5.71a34.35,34.35,0,0,0-.18-3.8L55.12,241l-2.63-1.16s.2,2.16.23,2.47a18.06,18.06,0,0,1-1.46,9.21Z"
                    fill="#e4e3e3"
                />
                <path
                    id="Path_56"
                    data-name="Path 56"
                    d="M70.36,313.46c-6.51,6.28-31.12,5.23-32.64-1.69s1.66-58.9,12.77-62.16c2.3-.68,14.63,3.31,14.63,3.31a78.15,78.15,0,0,1,3.2,11.5c.24,1.25,1,14.53,1.5,27,.25,5.81.46,11.44.57,15.55A45.21,45.21,0,0,1,70.36,313.46Z"
                    fill="#707272"
                />
                <path
                    id="Path_57"
                    data-name="Path 57"
                    d="M53.25,242.39c1.43,2.42,6.44,4.53,9.1,5.5a34.35,34.35,0,0,0-.18-3.8L55.12,241C53.56,241,52.61,241.31,53.25,242.39Z"
                    fill="#707272"
                    opacity="0.15"
                />
                <path
                    id="Path_58"
                    data-name="Path 58"
                    d="M64.83,243.66s-11.36,3.55-13.46-3.56-5-11.44,2.19-14,9.38-.27,10.65,1.93S67.73,242.56,64.83,243.66Z"
                    fill="#e4e3e3"
                />
                <path
                    id="Path_59"
                    data-name="Path 59"
                    d="M63.77,227.35s-.73,8.27-2.44,7.91-3.59-1.13-3.34,1.36-1.66,6.77-4.13,6.76c-5.29,0-11-13.59-9.59-16.17C48.45,219.79,69.81,218.29,63.77,227.35Z"
                    fill="#707272"
                />
                <path
                    id="Path_60"
                    data-name="Path 60"
                    d="M62.1,249.79c-.15-.06-8.46-3.79-9.43-3.47s-2.19,3.29-2.19,3.29l14.64,3.31A7.34,7.34,0,0,0,62.1,249.79Z"
                    fill="#565656"
                />
                <path
                    id="Path_61"
                    data-name="Path 61"
                    d="M113.83,290,67.25,333.9c-.92.86-2.07,1.13-2.56.6l-1.57-1.67c-.5-.52-.15-1.65.76-2.51l46.58-43.86c.91-.86,2.06-1.13,2.55-.61l1.58,1.67C115.08,288.05,114.74,289.18,113.83,290Z"
                    fill="#707272"
                />
                <path
                    id="Path_62"
                    data-name="Path 62"
                    d="M87.69,314.65,67.25,333.9c-.92.86-2.07,1.13-2.56.6l-1.57-1.67c-.5-.52-.15-1.65.76-2.51l20.44-19.25Z"
                    fill="#565656"
                />
                <path
                    id="Path_63"
                    data-name="Path 63"
                    d="M103.12,273.48a36.51,36.51,0,1,0,60.51-36.68l-.57-.56a36.51,36.51,0,0,0-59.94,37.24Zm10.43-33.69a33.6,33.6,0,1,1-9.129,24.066,33.63,33.63,0,0,1,9.129-24.066Z"
                    fill="#707272"
                />
                <g id="Group_5" data-name="Group 5" opacity="0.1">
                    <path
                        id="Path_64"
                        data-name="Path 64"
                        d="M162.53,285.87a33.47,33.47,0,1,1,9.183-24.067A33.63,33.63,0,0,1,162.53,285.87Z"
                        fill="#707272"
                    />
                </g>
                <path
                    id="Path_65"
                    data-name="Path 65"
                    d="M70.39,306.93c-25.66-18.28-23.53-42.69-23.53-42.69,9.38,5.29,18.69,17,23,27.14C70.07,297.19,70.28,302.82,70.39,306.93Z"
                    fill="#565656"
                />
                <path
                    id="Path_66"
                    data-name="Path 66"
                    d="M95.72,298.61a7,7,0,0,0,3.28-2s.77.05-.13,1.7l-.36.74a.16.16,0,0,0,.13.23,17.179,17.179,0,0,1,4.11.09c.27.05.35.72.34,1a2.53,2.53,0,0,1-.54,1.76c-.32.42-1.23.88-3.24,1.69-.77.31-3,.08-5-.63l-1.64-.76.51-3,.41.09C94.23,299.57,95,298.77,95.72,298.61Z"
                    fill="#e4e3e3"
                />
                <path
                    id="Path_67"
                    data-name="Path 67"
                    d="M54.83,252.92s-23.06,4.39,6.78,37.78C66.38,296,92.17,303,92.17,303s2.62-2.23,1.3-4c0,0-17.76-12.89-21.66-14.94-1.76-.93-6.82-20.58-9.69-25.32a12.9,12.9,0,0,0-7.29-5.82Z"
                    fill="#707272"
                />
                <path
                    id="Path_68"
                    data-name="Path 68"
                    d="M99.27,265.37a11.26,11.26,0,0,0,.9-2.2,12.3,12.3,0,0,1,1.49-3.37c.16-.15.67.05.74.72A4,4,0,0,1,102,262a16.12,16.12,0,0,0-.48,2.9C101.18,266,99.27,265.37,99.27,265.37Z"
                    fill="#e4e3e3"
                />
                <path
                    id="Path_69"
                    data-name="Path 69"
                    d="M70.42,313.09a2.151,2.151,0,0,1-.06.37l-.18.16s-22,8.21-31.49-.1l-.31-.36A29.87,29.87,0,0,0,55,316.47C64.82,315.48,70.42,313.09,70.42,313.09Z"
                    fill="#565656"
                />
                <g id="Group_6" data-name="Group 6" opacity="0.1">
                    <path
                        id="Path_70"
                        data-name="Path 70"
                        d="M407.6,221.36l-13.05,3.04-6.46,11.74-4.93-26.77Z"
                        fill="#707272"
                    />
                    <rect
                        id="Rectangle_16"
                        data-name="Rectangle 16"
                        width="2.39"
                        height="13.81"
                        transform="translate(391.941 223.272) rotate(-37.15)"
                        fill="#707272"
                    />
                </g>
                <path
                    id="Path_71"
                    data-name="Path 71"
                    d="M470.42,408.89H354c-16.23-18.57-11.95-43.65,9.6-64.81,2.71-2.66,7.91-4.54,13.51-5.87a149,149,0,0,1,22.13-3.07,81,81,0,0,0,9.89-9.49c11.51-13.93,21.3-12.73,21.3-12.73,11.73-2.29,21.73-3,29.15,1.17h0c6.87,3.83,11.52,11.78,13.27,26.39q.495,4.155.67,9c.42,11.86,2.76,22.41,4.15,31.34h0C479.67,393.8,481.09,403.64,470.42,408.89Z"
                    fill="#707272"
                />
                <path
                    id="Path_72"
                    data-name="Path 72"
                    d="M453.76,329c-7.12,3.88-7.91,16.65-19,19.95s-30.05,3-40.78,14.63c-10.34,11.22-21.75-17.33-16.84-25.36a149.008,149.008,0,0,1,22.13-3.07,81,81,0,0,0,9.89-9.49c11.51-13.93,21.3-12.73,21.3-12.73,11.73-2.29,21.73-3,29.15,1.17h0C467.74,322.54,460.88,325.12,453.76,329Z"
                    fill="#565656"
                />
                <path
                    id="Path_73"
                    data-name="Path 73"
                    d="M376.73,384.21s2,5.43,1.62,7.45.29,5.94-.53,7.69-2.29,4.3-.87,4.94c.53.24,6.77-4.63,7.13-6.06s.62-5.66,1.8-8a3.4,3.4,0,0,0-1.55-5c-1.73-.84-4.16-5.45-4.16-5.45Z"
                    fill="#e4e3e3"
                />
                <path
                    id="Path_74"
                    data-name="Path 74"
                    d="M410.07,332.67s-34.06-2.11-38.45,2.41c-4.63,4.76,2.91,51.59,2.91,51.59s9.24.26,10-2.19c0,0-1.73-22.67-.29-26.59s.53-9.35.53-9.35,18.07,2.7,23.81,1.33S410.07,332.67,410.07,332.67Z"
                    fill="#565656"
                />
                <path
                    id="Path_75"
                    data-name="Path 75"
                    d="M418.33,307.66s-5,11.72-7.47,13-11.32,11.44-13.22,11.26,2,2.08,2,2.08l16.67-8.13c.9-.45,5.64-8.26,6.29-12.23S418.33,307.66,418.33,307.66Z"
                    fill="#e4e3e3"
                />
                <path
                    id="Path_76"
                    data-name="Path 76"
                    d="M370.44,393.18s-1.94,5.48-3.57,6.74-3.61,4.72-5.37,5.53-4.52,1.81-3.85,3.21c.25.54,8.16.84,9.36,0s4.12-3.92,6.51-4.91a3.4,3.4,0,0,0,2-4.8c-.79-1.76.33-6.85.33-6.85Z"
                    fill="#e4e3e3"
                />
                <path
                    id="Path_77"
                    data-name="Path 77"
                    d="M428.59,302.47c-.73-.84,17.72,1.23,20.17,10.66,0,0,14.17-11.17,1.17-20.58-4.5-3.26-4.95-6.74-5.6-9.92s-5.15-12.33-12.94-7.79a10.16,10.16,0,0,0-5.81,2C420.46,281.25,414.88,309.46,428.59,302.47Z"
                    fill="#565656"
                />
                <path
                    id="Path_78"
                    data-name="Path 78"
                    d="M425,334.32s-37,3.78-40.29,7.56-16,52.94-16,52.94,3.78,2.11,7.21.14c0,0,9.79-16.68,14.13-21.92s6-21.4,6.66-21.56,23.93,3.23,31.92-2.91S425,334.32,425,334.32Z"
                    fill="#636464"
                />
                <path
                    id="Path_79"
                    data-name="Path 79"
                    d="M445.61,300.7a38.62,38.62,0,0,0-20-2.13,7.67,7.67,0,0,0-6,3.82c-2.31,3.81-3.39,5.85-3.39,5.85s.22,1.77,3.38,3.17c-.86,3.16.79,8.68-.72,10.82s-10.93,7.45-11.4,10.4c-.85,5.43,16.09,15.25,21.15,14.2C443.13,343.8,453,304.85,445.61,300.7Z"
                    fill="#707272"
                />
                <path
                    id="Path_80"
                    data-name="Path 80"
                    d="M440.61,289.53a20.659,20.659,0,0,0,2-6.24c.16-2-1.54-9.23-8.79-7.48a9.92,9.92,0,0,0-4,2c-1.5,1.23-3.58,3.77-.4,9.92l.87,2.09Z"
                    fill="#565656"
                />
                <path
                    id="Path_81"
                    data-name="Path 81"
                    d="M430.67,301.09c-.74.78-.45,3.35,3,3.15,2.26-.12,7.09-2.91,6.63-4.84-.21-.91-1.94-3.27-2.35-8.7l-.41.11-6.88,1.94s.33,2.19.5,4.26a8.931,8.931,0,0,1-.49,4.08Z"
                    fill="#e4e3e3"
                />
                <path
                    id="Path_82"
                    data-name="Path 82"
                    d="M430.64,292.75s.33,2.19.5,4.26c3.9-.42,5.69-4.15,6.38-6.2Z"
                    fill="#707272"
                    opacity="0.3"
                />
                <path
                    id="Path_83"
                    data-name="Path 83"
                    d="M430.49,295.93s9-2.27,7.51-7.85-1.35-9.64-7.16-8.43-6.35,3.64-6.3,5.63S428.11,296.37,430.49,295.93Z"
                    fill="#e4e3e3"
                />
                <path
                    id="Path_84"
                    data-name="Path 84"
                    d="M426.72,280.62s3,5.75,8.73,6.37c3.33.36,4.25,2.56,4.25,2.56a52.331,52.331,0,0,0-2.7-10.13S427.73,277,426.72,280.62Z"
                    fill="#565656"
                />
                <path
                    id="Path_85"
                    data-name="Path 85"
                    d="M427.75,281.38a15.7,15.7,0,0,0-2.58,3.43c-.9,2.1.86,6.52,1.59,7.48,0,0-4.19-7.11-2.79-10.19C425.45,278.84,427.75,281.38,427.75,281.38Z"
                    fill="#565656"
                />
                <path
                    id="Path_86"
                    data-name="Path 86"
                    d="M400.32,333.23s-1.8,1.89-2,2c-1.19.55-2.69.23-3.41.41s-.6-.27-.07-.63,1.85-.23,1.31-.36a14.711,14.711,0,0,0-4.52-.38,4.13,4.13,0,0,1-3.2-.25c-.11-.18,3.94-2.16,5.1-2.1s4.49-.09,4.49-.09Z"
                    fill="#e4e3e3"
                />
                <path
                    id="Path_87"
                    data-name="Path 87"
                    d="M448.66,304.2s11.17,25.22,8.06,28.92-24.57-.79-24.57-.79l.66-2.34s15.81-1,16.93-1.29c1.62-.45-8-18.14-8-18.14Z"
                    fill="#e4e3e3"
                />
                <path
                    id="Path_88"
                    data-name="Path 88"
                    d="M444.49,300.29c.27,0,3.61,1.1,6,5.84s3.22,6.93,3.22,6.93-10.44,8.09-14.6,5.11S440.66,300.19,444.49,300.29Z"
                    fill="#707272"
                />
                <path
                    id="Path_89"
                    data-name="Path 89"
                    d="M434.66,329.87a29.583,29.583,0,0,1-2.52-1.8c-.73-.63-4.94-1.15-4.93-.72s2.45,1,2.71,1.42,1.77,2.18,3,2.17a1.82,1.82,0,0,0,1.74-1.07Z"
                    fill="#e4e3e3"
                />
                <path
                    id="Path_90"
                    data-name="Path 90"
                    d="M429.75,333.67l-4.81-.69a2.09,2.09,0,0,1-1.63-1.34l-2.66-9.41c-.19-.64.25-1.07,1-1l4.82.68a2.12,2.12,0,0,1,1.63,1.35l2.66,9.4C430.91,333.33,430.47,333.77,429.75,333.67Z"
                    fill="#636464"
                />
                <path
                    id="Path_91"
                    data-name="Path 91"
                    d="M428.75,333.52l-4.82-.68a2.11,2.11,0,0,1-1.63-1.35l-2.66-9.4c-.18-.64.26-1.08,1-1l4.82.68a2.09,2.09,0,0,1,1.63,1.34l2.66,9.41C429.91,333.19,429.47,333.63,428.75,333.52Z"
                    fill="#565656"
                />
                <path
                    id="Path_92"
                    data-name="Path 92"
                    d="M420.47,322.32c-.08-.27.1-.45.4-.41a.91.91,0,0,1,.68.56c.07.27-.11.45-.41.4A.85.85,0,0,1,420.47,322.32Z"
                    fill="#e4e3e3"
                />
                <path
                    id="Path_93"
                    data-name="Path 93"
                    d="M432.15,332.33a32,32,0,0,1-4.08-.15c-.82-.19-2.9-2.78-2.35-3.43.35-.41,2.3.32,3.23.32s3.34-1.34,5,1.4C434.12,330.78,432.15,332.33,432.15,332.33Z"
                    fill="#e4e3e3"
                />
                <path
                    id="Path_94"
                    data-name="Path 94"
                    d="M407.3,332.49s-.91,8.87,23.21,14l.26-.43a68.812,68.812,0,0,1-14.32-4.86c-6.83-3.27-9.35-8.81-8.9-9Z"
                    fill="#565656"
                />
                <path
                    id="Path_95"
                    data-name="Path 95"
                    d="M446.14,338.53s-3.51,3.29-18.19,3.76-33.83,16.7-34,21.28,29.57,11.54,30,10.88S442.83,358,442.83,358Z"
                    fill="#565656"
                />
                <path
                    id="Path_96"
                    data-name="Path 96"
                    d="M477.66,380.86c-16.33-20.39-49.89,7.41-58.57,6.62-8-.72,15.57-28.59,44.77-32.73,11.81-1.68,9-13.88,9-14.26q.495,4.155.67,9C473.93,361.38,476.27,371.93,477.66,380.86Z"
                    fill="#565656"
                />
                <path
                    id="Path_97"
                    data-name="Path 97"
                    d="M290.29,100.13l31.81,6.58L301.71,90.87l-1.37,4.58-7.9.93Z"
                    fill="#707272"
                />
                <path
                    id="Path_98"
                    data-name="Path 98"
                    d="M322.1,106.71,296.25,91.28l-26.09-2.95Z"
                    fill="#e4e3e3"
                />
                <path
                    id="Path_99"
                    data-name="Path 99"
                    d="M301.71,90.87l20.39,15.84L307.03,83Z"
                    fill="#e4e3e3"
                />
                <path
                    id="Path_100"
                    data-name="Path 100"
                    d="M90.41,189.65,58.6,196.23,79,180.39l1.37,4.58,7.89.93Z"
                    fill="#707272"
                />
                <path
                    id="Path_101"
                    data-name="Path 101"
                    d="M58.6,196.23,84.45,180.8l26.1-2.95Z"
                    fill="#e4e3e3"
                />
                <path
                    id="Path_102"
                    data-name="Path 102"
                    d="M79,180.39,58.6,196.23l15.08-23.71Z"
                    fill="#e4e3e3"
                />
                <path
                    id="Path_103"
                    data-name="Path 103"
                    d="M142.53,198.84l.93-1.1a4.05,4.05,0,0,0,2.78,1.14c.95,0,1.56-.44,1.56-1.13s-.35-1-2-1.36c-1.87-.44-2.93-1-2.93-2.64s1.26-2.55,3-2.55a4.85,4.85,0,0,1,3.19,1.11l-.83,1.16a4,4,0,0,0-2.39-.9c-.9,0-1.43.46-1.43,1.07s.4,1,2.09,1.38c1.86.45,2.82,1.12,2.82,2.6,0,1.65-1.29,2.63-3.14,2.63A5.4,5.4,0,0,1,142.53,198.84Z"
                    fill="#707272"
                />
                <path
                    id="Path_104"
                    data-name="Path 104"
                    d="M150.63,196.81a3.29,3.29,0,0,1,3.24-3.46c2.11,0,3.18,1.66,3.18,3.57v.43h-4.88a1.85,1.85,0,0,0,1.91,1.68,2.44,2.44,0,0,0,1.8-.79l.89.79a3.36,3.36,0,0,1-2.72,1.25,3.32,3.32,0,0,1-3.42-3.47Zm4.91-.47a1.73,1.73,0,0,0-1.68-1.75,1.79,1.79,0,0,0-1.72,1.75Z"
                    fill="#707272"
                />
                <path
                    id="Path_105"
                    data-name="Path 105"
                    d="M162.65,200.12v-.81a2.7,2.7,0,0,1-2.15.95,2.13,2.13,0,0,1-2.37-2.07c0-1.47,1.15-2.18,2.69-2.18a5.7,5.7,0,0,1,1.84.28v-.13c0-.91-.58-1.42-1.64-1.42a4.6,4.6,0,0,0-1.89.42l-.42-1.22a5.67,5.67,0,0,1,2.53-.55c2,0,2.91,1,2.91,2.8v3.93Zm0-2.77a4.35,4.35,0,0,0-1.52-.27c-.95,0-1.52.39-1.52,1.06s.57,1,1.28,1c1,0,1.76-.57,1.76-1.4Z"
                    fill="#707272"
                />
                <path
                    id="Path_106"
                    data-name="Path 106"
                    d="M166,193.49h1.53V195a2.35,2.35,0,0,1,2.35-1.62V195h-.09c-1.33,0-2.26.87-2.26,2.63v2.52H166Z"
                    fill="#707272"
                />
                <path
                    id="Path_107"
                    data-name="Path 107"
                    d="M170.57,196.82a3.41,3.41,0,0,1,3.44-3.47,3.29,3.29,0,0,1,2.65,1.14l-.94,1a2.22,2.22,0,0,0-1.72-.83,2.15,2.15,0,0,0,.07,4.28,2.35,2.35,0,0,0,1.73-.83l.91.9a3.35,3.35,0,0,1-2.71,1.25,3.39,3.39,0,0,1-3.43-3.44Z"
                    fill="#707272"
                />
                <path
                    id="Path_108"
                    data-name="Path 108"
                    d="M178.11,191h1.52v3.57a2.39,2.39,0,0,1,2.08-1.17,2.28,2.28,0,0,1,2.36,2.55v4.22h-1.52V196.4c0-1-.52-1.62-1.42-1.62a1.48,1.48,0,0,0-1.5,1.65v3.74h-1.52Z"
                    fill="#707272"
                />
                <path
                    id="Path_109"
                    data-name="Path 109"
                    d="M185.8,198.4h1.68v1.72H185.8Z"
                    fill="#707272"
                />
                <path
                    id="Path_110"
                    data-name="Path 110"
                    d="M189.19,198.4h1.67v1.72h-1.67Z"
                    fill="#707272"
                />
                <path
                    id="Path_111"
                    data-name="Path 111"
                    d="M192.57,198.4h1.67v1.72h-1.67Z"
                    fill="#707272"
                />
                <path
                    id="Path_112"
                    data-name="Path 112"
                    d="M196,198.4h1.67v1.72H196Z"
                    fill="#707272"
                />
                <path
                    id="Path_113"
                    data-name="Path 113"
                    d="M283.9,281.09a1.63,1.63,0,1,1,1.62,1.63A1.63,1.63,0,0,1,283.9,281.09Z"
                    fill="#565656"
                />
                <path
                    id="Path_114"
                    data-name="Path 114"
                    d="M359.61,281.53H286a.44.44,0,0,1-.44-.44h0a.44.44,0,0,1,.44-.43h73.65a.43.43,0,0,1,.44.43h0a.43.43,0,0,1-.48.44Z"
                    fill="#565656"
                />
                <circle
                    id="Ellipse_1"
                    data-name="Ellipse 1"
                    cx="9.19"
                    cy="9.19"
                    r="9.19"
                    transform="translate(476.79 238.7)"
                    fill="#fff"
                    stroke="#e2e2e2"
                    strokeMiterlimit="10"
                    strokeWidth="2"
                />
                <circle
                    id="Ellipse_2"
                    data-name="Ellipse 2"
                    cx="10.29"
                    cy="10.29"
                    r="10.29"
                    transform="translate(23.32 130.06)"
                    fill="#fff"
                    stroke="#e2e2e2"
                    strokeMiterlimit="10"
                    strokeWidth="2"
                />
                <path
                    id="Path_115"
                    data-name="Path 115"
                    d="M99.58,86.26H91.43V78.12a1,1,0,0,0-1-1h0a1,1,0,0,0-1,1v8.14H81.25a1,1,0,0,0,0,2h8.13v8.16a1,1,0,0,0,2,0h0V88.31h8.15a1.026,1.026,0,0,0,.05-2.05Z"
                    fill="#e2e2e2"
                    stroke="#e2e2e2"
                    strokeMiterlimit="10"
                    strokeWidth="0.75"
                />
                <path
                    id="Path_116"
                    data-name="Path 116"
                    d="M267.55,60.19H262.1V54.75a.67.67,0,0,0-.68-.67h0a.69.69,0,0,0-.69.67v5.44H255.3a.7.7,0,0,0-.68.7.68.68,0,0,0,.68.67h5.43V67a.7.7,0,0,0,.69.68.68.68,0,0,0,.68-.68h0V61.56h5.45a.68.68,0,0,0,.68-.67A.7.7,0,0,0,267.55,60.19Z"
                    fill="#e2e2e2"
                    stroke="#e2e2e2"
                    strokeMiterlimit="10"
                    strokeWidth="0.75"
                />
                <path
                    id="Path_117"
                    data-name="Path 117"
                    d="M507.46,314.56H502v-5.44a.68.68,0,0,0-.68-.68h0a.69.69,0,0,0-.69.68v5.44H495.2a.68.68,0,0,0,0,1.36h5.43v5.45a.69.69,0,0,0,1.37,0h0v-5.45h5.44a.68.68,0,1,0,0-1.36Z"
                    fill="#e2e2e2"
                    stroke="#e2e2e2"
                    strokeMiterlimit="10"
                    strokeWidth="0.75"
                />
            </g>
        </SVGIcon>
    );
}
