import React, { Fragment, useCallback, useState } from 'react';

import DCFacebook from 'Assets/icons/DCFacebook';
import DCGoogle from 'Assets/icons/DCGoogle';
import DCYelp from 'Assets/icons/DCYelp';
import DCAddIcon from 'Assets/icons/DCAddIcon';
import { Div, Image, Rating, IconButton, TextOverflowTooltip } from 'UIKit';
import ReviewDialog from './reviewDialog';
import generateImage from 'Utils/generateImage';

const UserReviewBox = ({
    companyName,
    type,
    profileImg,
    name,
    rating,
    body,
    createdAt,
    reviewPageURL,
}) => {
    const [reviewDialog, setReviewDialog] = useState(false);
    const handleReviewDialogOpen = useCallback(() => {
        setReviewDialog(true);
    }, []);

    const handleReviewDialogClose = useCallback(() => {
        setReviewDialog(false);
    }, []);
    return (
        <Fragment>
            <ReviewDialog
                open={reviewDialog}
                onClose={handleReviewDialogClose}
                title={`Review for ${companyName}`}
                profileImg={profileImg}
                name={name}
                createdAt={createdAt}
                rating={rating}
                body={body}
                reviewPageURL={reviewPageURL}
            />
            <Div className={'EPCRCBox'}>
                <Div className={'EPCRCBHead'}>
                    <Div className={'EPCRCBUser'}>
                        <Div className={'EPCRCBUImg'}>
                            <Image
                                src={
                                    generateImage(profileImg, name) ||
                                    'https://material-ui.com/static/images/avatar/3.jpg'
                                }
                                alt=""
                            />
                        </Div>
                        <Div className={'EPCRCBUIInfo'}>
                            <Div className={'EPCRCBUIIName'}>{name}</Div>
                            <Div className={'EPCRCBUIIDate'}>{createdAt}</Div>
                        </Div>
                    </Div>
                    <Div className={'EPCRCBUReview'}>
                        {rating && <Rating disabled={true} value={rating} />}
                        <Div className={'EPCRCBURIcon'}>
                            {type === 'facebook' && <DCFacebook />}
                            {type === 'google' && <DCGoogle />}
                            {type === 'yelp' && <DCYelp />}
                        </Div>
                    </Div>
                    <IconButton buttonType={'BlueFillBtn'} onClick={handleReviewDialogOpen}>
                        <DCAddIcon />
                    </IconButton>
                </Div>
                <Div className={'EPCRCBBody'}>
                    <TextOverflowTooltip tooltipContent={body || '-'}>{body}</TextOverflowTooltip>
                </Div>
            </Div>
        </Fragment>
    );
};

export default UserReviewBox;
