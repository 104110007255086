import React from 'react';

import { SVGIcon } from '../../UIKit/index';

export default function DCCardForm1(props) {
    return (
        <SVGIcon {...props} viewBox="0 0 503.885 291.82">
            <g id="Card_Form" data-name="Card Form" transform="translate(-3.735 -139)">
                <circle
                    id="Ellipse_1"
                    data-name="Ellipse 1"
                    cx="7.22"
                    cy="7.22"
                    r="7.22"
                    transform="translate(473.01 348.44)"
                    fill="#fff"
                    stroke="#e1e1e1"
                    strokeMiterlimit="10"
                    strokeWidth="2"
                />
                <circle
                    id="Ellipse_2"
                    data-name="Ellipse 2"
                    cx="10.64"
                    cy="10.64"
                    r="10.64"
                    transform="translate(485.34 217.61)"
                    fill="#fff"
                    stroke="#e1e1e1"
                    strokeMiterlimit="10"
                    strokeWidth="2"
                />
                <path
                    id="Path_1"
                    data-name="Path 1"
                    d="M21,176.11H13.89V169a.88.88,0,0,0-.88-.88h0a.9.9,0,0,0-.9.88v7.08H5a.89.89,0,0,0-.89.9.88.88,0,0,0,.89.88h7.07V185a.91.91,0,0,0,.9.89.89.89,0,0,0,.88-.89h0v-7.08H21a.89.89,0,0,0,0-1.78Z"
                    fill="#e1e1e1"
                    stroke="#e1e1e1"
                    strokeMiterlimit="10"
                    strokeWidth="0.75"
                />
                <path
                    id="Path_2"
                    data-name="Path 2"
                    d="M34.63,359.73H28.74v-5.89a.73.73,0,0,0-.73-.73h0a.76.76,0,0,0-.75.73v5.89H21.39a.74.74,0,0,0,0,1.48h5.87v5.89a.76.76,0,0,0,.75.73.73.73,0,0,0,.73-.73h0v-5.89h5.89a.74.74,0,1,0,0-1.48Z"
                    fill="#e1e1e1"
                    stroke="#e1e1e1"
                    strokeMiterlimit="10"
                    strokeWidth="0.75"
                />
                <ellipse
                    id="_Path_"
                    data-name="&lt;Path&gt;"
                    cx="251.23"
                    cy="33.29"
                    rx="251.23"
                    ry="33.29"
                    transform="translate(4.15 364.24)"
                    fill="#f3f4f5"
                />
                <path
                    id="Path_3"
                    data-name="Path 3"
                    d="M450.53,381.78c0,10.47-9.51,19-21.24,19H72.49c-11.73,0-21.24-8.5-21.24-19V158c0-10.47,9.51-19,21.24-19h356.8c11.73,0,21.24,8.5,21.24,19Z"
                    fill="#717272"
                />
                <path
                    id="Path_4"
                    data-name="Path 4"
                    d="M450.53,381.78c0,10.47-9.3,19-20.76,19H81c-11.47,0-20.76-8.5-20.76-19V158c0-10.47,9.29-19,20.76-19H429.77c11.46,0,20.76,8.5,20.76,19Z"
                    opacity="0.2"
                />
                <text
                    id="Username"
                    transform="translate(81.16 249.02)"
                    fill="#fff"
                    fontSize="15.02"
                    fontFamily="MyriadPro-Bold, Myriad Pro"
                    fontWeight="700"
                >
                    <tspan x="0" y="0" letterSpacing="-0.01em">
                        U
                    </tspan>
                    <tspan y="0">sername</tspan>
                </text>
                <rect
                    id="Rectangle_1"
                    data-name="Rectangle 1"
                    width="339.47"
                    height="36.08"
                    rx="3.91"
                    transform="translate(81.16 263.66)"
                    fill="#f3f4f5"
                />
                <rect
                    id="Rectangle_2"
                    data-name="Rectangle 2"
                    width="130.9"
                    height="15.88"
                    transform="translate(90.33 273.76)"
                    fill="#717272"
                    opacity="0.5"
                />
                <rect
                    id="Rectangle_3"
                    data-name="Rectangle 3"
                    width="92.56"
                    height="36.08"
                    rx="3.91"
                    transform="translate(221.23 333.05)"
                    fill="#f3f4f5"
                />
                <text
                    id="Previous"
                    transform="translate(238.5 355.5)"
                    fill="#717272"
                    fontSize="15.02"
                    fontFamily="MyriadPro-Bold, Myriad Pro"
                    fontWeight="700"
                >
                    <tspan x="0" y="0" letterSpacing="-0.01em">
                        Pre
                    </tspan>
                    <tspan y="0">vious</tspan>
                </text>
                <rect
                    id="Rectangle_4"
                    data-name="Rectangle 4"
                    width="92.56"
                    height="36.08"
                    rx="3.91"
                    transform="translate(328.06 333.05)"
                    fill="#f3f4f5"
                />
                <text
                    id="Next"
                    transform="translate(358.56 355.5)"
                    fill="#717272"
                    fontSize="15.02"
                    fontFamily="MyriadPro-Bold, Myriad Pro"
                    fontWeight="700"
                >
                    <tspan x="0" y="0">
                        N
                    </tspan>
                    <tspan y="0" letterSpacing="-0.01em">
                        e
                    </tspan>
                    <tspan y="0" letterSpacing="0.01em">
                        x
                    </tspan>
                    <tspan y="0">t</tspan>
                </text>
                <path
                    id="Path_5"
                    data-name="Path 5"
                    d="M191.25,184.25c-.72,3.33-3.23,5.75-7.7,5.75-5.66,0-8.34-4-8.34-9.08,0-4.9,2.76-9.28,8.52-9.28,4.76,0,7.06,2.79,7.5,5.77h-3.86a3.51,3.51,0,0,0-3.76-2.74c-3.18,0-4.41,2.86-4.41,6.11,0,3,1.06,6.2,4.51,6.2a3.54,3.54,0,0,0,3.65-2.73Z"
                    fill="#fff"
                />
                <path
                    id="Path_6"
                    data-name="Path 6"
                    d="M197.56,185.63l-1.39,4.12h-3.8l6.07-17.85h4.84l6.33,17.85h-4.06l-1.47-4.12Zm5.69-3.17c-1.27-3.77-2.07-6.17-2.5-7.78h0c-.44,1.76-1.33,4.56-2.35,7.78Z"
                    fill="#fff"
                />
                <path
                    id="Path_7"
                    data-name="Path 7"
                    d="M215.67,182.6v7.15H211.9V171.9h7.84c3.89,0,6,2.14,6,4.9a4.28,4.28,0,0,1-2.6,4.22c.89.33,2.22,1.25,2.22,4.44v.89a14,14,0,0,0,.26,3.4H222a12.679,12.679,0,0,1-.37-4v-.26c0-1.95-.5-2.88-3.37-2.88Zm0-3.07h3.23c2.12,0,2.93-.8,2.93-2.3s-1-2.26-2.83-2.26h-3.33Z"
                    fill="#fff"
                />
                <path
                    id="Path_8"
                    data-name="Path 8"
                    d="M229.16,171.9h7.08c5.2,0,8.6,3.42,8.6,8.7s-3.3,9.15-8.76,9.15h-6.92ZM233,186.63h2.9c3.42,0,4.93-2.54,4.93-6,0-2.92-1.41-5.59-4.91-5.59H233Z"
                    fill="#fff"
                />
                <path
                    id="Path_9"
                    data-name="Path 9"
                    d="M254.48,171.9h12.46v3.16h-8.69v4.38h8.14v3.16h-8.14v7.15h-3.77Z"
                    fill="#fff"
                />
                <path
                    id="Path_10"
                    data-name="Path 10"
                    d="M286.47,180.76c0,5-3,9.25-8.78,9.25-5.6,0-8.53-4.07-8.53-9.19s3.23-9.18,8.78-9.18C283.16,171.64,286.47,175.3,286.47,180.76Zm-13.33,0c0,3.48,1.53,6.12,4.7,6.12,3.43,0,4.64-2.88,4.64-6.06,0-3.38-1.38-6-4.71-6s-4.63,2.45-4.63,5.93Z"
                    fill="#fff"
                />
                <path
                    id="Path_11"
                    data-name="Path 11"
                    d="M293.61,182.6v7.15h-3.77V171.9h7.85c3.89,0,6,2.14,6,4.9a4.27,4.27,0,0,1-2.59,4.22c.88.33,2.22,1.25,2.22,4.44v.89a14.551,14.551,0,0,0,.25,3.4H299.9a12.31,12.31,0,0,1-.38-4v-.26c0-1.95-.49-2.88-3.37-2.88Zm0-3.07h3.24c2.11,0,2.93-.8,2.93-2.3s-.95-2.26-2.84-2.26h-3.33Z"
                    fill="#fff"
                />
                <path
                    id="Path_12"
                    data-name="Path 12"
                    d="M322,183.05c0-3,0-6.46.12-8.48H322c-.84,3.65-2.62,9.59-4.34,15.18h-3.28c-1.31-4.88-3.15-11.66-3.93-15.22h-.12c.15,2.09.22,5.76.22,8.82v6.4H307V171.9h5.75c1.39,4.66,2.95,10.51,3.49,13.17h0c.43-2.34,2.4-8.6,3.88-13.17h5.54v17.85H322Z"
                    fill="#fff"
                />
            </g>
        </SVGIcon>
    );
}
