import editorActions from './Action';

const editorReducer = (state, action) => {
    switch (action.type) {
        case editorActions.SET_FALLBACK_ENTITY: {
            return {
                ...state,
                fallbackEntity: action.value,
            };
        }

        case editorActions.SET_EDITOR_STATE: {
            return {
                ...state,
                editorState: action.value,
            };
        }

        default:
            return state;
    }
};

export default editorReducer;
