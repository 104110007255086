import React from 'react';

import { SVGIcon } from '../../UIKit/index';

export default function DCMerchantCenterMenu(props) {
    return (
        <SVGIcon {...props} viewBox="0 0 512 371">
            <g transform="translate(0 -8)">
                <path
                    fill="#E6EFFF"
                    d="M483.68,378.44H29.81c-15.63,0-28.31-12.67-28.32-28.3V37.09c0-15.64,12.68-28.32,28.32-28.32   c0,0,0,0,0,0h453.87c15.64,0,28.32,12.68,28.32,28.32v313.04c0,15.64-12.68,28.32-28.32,28.32"
                />
                <path fill="#2F8DFF" d="M1.51,56.42L512,56.63v77.83L1.51,133.44V56.42z" />
                <g>
                    <path
                        fill="#2F8DFF"
                        d="M133.5,184.8H54.3c-4.86-0.25-8.6-4.39-8.35-9.25c0.23-4.51,3.84-8.12,8.35-8.35h79.2    c4.86,0.25,8.6,4.39,8.35,9.25C141.62,180.97,138.01,184.57,133.5,184.8"
                    />
                    <path
                        fill="#2F8DFF"
                        d="M247.93,184.8h-79.2c-4.86-0.25-8.6-4.39-8.35-9.25c0.23-4.51,3.84-8.12,8.35-8.35h79.23    c4.86,0.25,8.6,4.39,8.35,9.25c-0.23,4.51-3.84,8.12-8.35,8.35"
                    />
                    <path
                        fill="#2F8DFF"
                        d="M63.09,228.82H54.3c-4.86,0.25-9-3.49-9.25-8.35c-0.25-4.86,3.49-9,8.35-9.25    c0.3-0.02,0.6-0.02,0.9,0h8.81c4.86-0.25,9,3.49,9.25,8.35s-3.49,9-8.35,9.25c-0.3,0.02-0.6,0.02-0.9,0"
                    />
                    <path
                        fill="#2F8DFF"
                        d="M115.92,228.82h-17.6c-4.86,0.25-9-3.49-9.25-8.35c-0.25-4.86,3.49-9,8.35-9.25    c0.3-0.02,0.6-0.02,0.9,0h17.6c4.86-0.25,9,3.49,9.25,8.35c0.25,4.86-3.49,9-8.35,9.25C116.52,228.84,116.22,228.84,115.92,228.82    "
                    />
                    <path
                        fill="#2F8DFF"
                        d="M159.92,228.82h-8.81c-4.86,0.25-9-3.49-9.25-8.35c-0.25-4.86,3.49-9,8.35-9.25    c0.3-0.02,0.6-0.02,0.9,0h8.81c4.86-0.25,9,3.49,9.25,8.35s-3.49,9-8.35,9.25C160.52,228.84,160.22,228.84,159.92,228.82"
                    />
                    <path
                        fill="#2F8DFF"
                        d="M212.75,228.82h-17.6c-4.86,0.25-9-3.49-9.25-8.35c-0.25-4.86,3.49-9,8.35-9.25    c0.3-0.02,0.6-0.02,0.9,0h17.6c4.86-0.25,9,3.49,9.25,8.35s-3.49,9-8.35,9.25C213.35,228.84,213.05,228.84,212.75,228.82"
                    />
                    <path
                        fill="#2F8DFF"
                        d="M247.93,228.82c-2.33-0.03-4.56-0.94-6.24-2.55c-1.62-1.68-2.54-3.93-2.57-6.27    c-0.01-4.88,3.93-8.85,8.81-8.86s8.85,3.93,8.86,8.81c0.01,4.88-3.93,8.85-8.81,8.86C247.96,228.82,247.95,228.82,247.93,228.82"
                    />
                </g>
                <path
                    fill="#ffffff"
                    d="M406.31,281.61c0,29.15-23.63,52.79-52.79,52.79c-29.15,0-52.79-23.63-52.79-52.79   c0-29.15,23.63-52.79,52.79-52.79l0,0C382.68,228.82,406.31,252.46,406.31,281.61"
                />
                <path
                    fill="#2F8DFF"
                    d="M467.94,281.61c0,29.15-23.63,52.79-52.79,52.79s-52.79-23.63-52.79-52.79   c0-29.15,23.63-52.79,52.79-52.79l0,0C444.3,228.82,467.94,252.46,467.94,281.61"
                />
            </g>
        </SVGIcon>
    );
}
