import React from 'react';

import { SVGIcon } from '../../UIKit/index';

export default function DCEmailSms(props) {
    return (
        <SVGIcon {...props} viewBox="0 0 512 243">
            <g transform="translate(-60.716 -54.973)">
                <g transform="translate(112.657 55.237)">
                    <g>
                        <path
                            fill="#2B88F8"
                            d="M430.07,2.91H267.47c-14.91,0-27,12.09-27,27v92.21c0,14.91,12.09,27,27,27     c9.16,0,16.58,7.42,16.58,16.58l0,0v20.21c0,3.29,2.67,5.95,5.95,5.95c2.04,0,3.95-1.05,5.04-2.78l12.54-19.9     c7.87-12.5,21.61-20.07,36.38-20.07h86.1c14.91,0,27-12.09,27-27V29.91C457.06,15,444.98,2.91,430.07,2.91z"
                        />
                        <path
                            fill="#D9EAFF"
                            stroke="#D9EAFF"
                            strokeWidth="0.2"
                            d="M297.03,149.11c-14.91,0-27-12.09-27-27V29.91c0-14.91,12.09-27,27-27h-29.56     c-14.91,0-27,12.09-27,27v92.21c0,14.91,12.09,27,27,27c9.16,0,16.58,7.42,16.58,16.58l0,0v20.21c0,3.29,2.67,5.95,5.95,5.95     c2.04,0,3.95-1.05,5.04-2.78l12.54-19.9c1.62-2.56,3.5-4.94,5.62-7.1C311.51,154.51,304.79,149.11,297.03,149.11z"
                        />
                    </g>
                    <text
                        fill="#FFFFFF"
                        fontSize="50.2541px"
                        fontFamily="Source Sans Pro"
                        transform="matrix(1 0 0 1 312.7152 88.9118)"
                    >
                        SMS
                    </text>
                </g>
                <g transform="translate(60.716 54.973)">
                    <path
                        fill="#2B88F8"
                        d="M243.88,233.38c0.08,2.83-1.02,5.56-3.02,7.55c-1.98,1.64-4.47,2.53-7.04,2.51H12.45    c-2.55-0.07-5.02-0.95-7.04-2.51c-2.01-1.99-3.1-4.72-3.02-7.55V85.97h241.49V233.38z"
                    />
                    <path
                        fill="#1371E2"
                        d="M240.88,240.93c-1.98,1.64-4.47,2.53-7.04,2.52H12.45c-2.56-0.07-5.02-0.95-7.04-2.52    L123.14,139.8L240.88,240.93z"
                    />
                    <path
                        fill="#D9EAFF"
                        d="M243.88,85.97l-34.21,24.65l-79.99,57.36c-4.02,3.02-9.56,3.02-13.59,0L36.6,110.62L2.39,85.97    L36.6,61.32l74.46-53.33l5.53-4.03c4.02-3.02,9.56-3.02,13.59,0l5.53,4.03l35.74,25.69l17.61,12.58l20.63,15.08L243.88,85.97z"
                    />
                    <path
                        fill="#FFFFFF"
                        d="M209.67,39.18v71.44l-79.99,57.36c-4.02,3.02-9.56,3.02-13.59,0L36.6,110.62V18.05    C36.62,12.51,41.11,8.02,46.65,8h131.33L209.67,39.18z"
                    />
                    <path
                        fill="#D9EAFF"
                        d="M178.48,31.13c-0.06,4.39,3.45,7.99,7.84,8.05c0.07,0,0.14,0,0.21,0h23.14L178.48,7.99    L178.48,31.13L178.48,31.13z"
                    />
                    <path
                        fill="#6B8AAA"
                        d="M152.82,109.11h8.05c-2.5,5.43-6.75,9.86-12.08,12.58c-6.97,3.65-14.78,5.39-22.64,5.03    c-7.63,0.21-15.21-1.34-22.14-4.53c-6.02-2.68-10.97-7.28-14.09-13.08c-3.03-5.74-4.58-12.13-4.53-18.62    c-0.13-7.19,1.6-14.3,5.03-20.63c3.06-6.09,8-11.02,14.09-14.09c6.43-3.09,13.49-4.64,20.63-4.53c6.1-0.04,12.13,1.34,17.61,4.03    c4.98,2.3,9.05,6.2,11.57,11.07c2.62,4.78,4.01,10.14,4.03,15.6c0,6.53-2.12,12.89-6.04,18.11c-4.36,6.52-11.78,10.33-19.62,10.05    c-1.73,0.01-3.44-0.33-5.03-1.01c-1.13-0.85-1.86-2.12-2.01-3.52c-2.75,2.9-6.57,4.54-10.56,4.53c-4.03,0.21-7.94-1.46-10.56-4.53    c-3.1-3.23-4.74-7.6-4.53-12.08c-0.05-6.07,1.7-12.03,5.03-17.11c3.4-5.64,9.51-9.08,16.1-9.06c4.23-0.07,8.21,2.01,10.56,5.53    l1.01-4.53h10.56l-6.04,28.18c-0.32,1.15-0.49,2.33-0.5,3.52c-0.07,0.55,0.12,1.11,0.5,1.51c0.5,0.5,0.5,0.5,1.01,0.5    c1.86-0.4,3.59-1.27,5.03-2.51c2.82-2.09,5.07-4.86,6.54-8.05c1.54-3.32,2.4-6.91,2.51-10.56c0.13-6.14-2.45-12.03-7.04-16.1    c-5.38-4.75-12.46-7.11-19.62-6.54c-6.34-0.18-12.61,1.39-18.11,4.53c-4.79,2.85-8.64,7.05-11.07,12.07    c-2.46,5.18-3.67,10.87-3.52,16.6c0.02,5.29,1.41,10.48,4.03,15.08c2.71,4.48,6.75,8,11.57,10.05c5.45,2.11,11.26,3.13,17.11,3.02    c5.47,0.02,10.9-0.83,16.1-2.51C145.99,115.34,149.79,112.6,152.82,109.11z M110.05,93.02c-0.25,2.68,0.46,5.35,2.01,7.55    c1.25,1.5,3.07,2.42,5.03,2.51c1.4-0.03,2.77-0.38,4.03-1.01c1.13-0.68,2.14-1.53,3.02-2.51c1.67-2.06,2.87-4.47,3.52-7.04    c0.95-2.76,1.45-5.64,1.51-8.55c0.18-2.51-0.53-5.01-2.01-7.04c-1.25-1.5-3.07-2.42-5.03-2.51c-2.25,0.08-4.4,0.97-6.04,2.51    c-2.29,1.96-3.88,4.61-4.53,7.55c-0.95,2.75-1.46,5.63-1.51,8.53V93.02z"
                    />
                </g>
                <text
                    fill="#6B8AAA"
                    fontSize="89.3407px"
                    fontFamily="Source Sans Pro"
                    transform="matrix(1 0 0 1 318.7082 267.6644)"
                >
                    +
                </text>
            </g>
        </SVGIcon>
    );
}
