import React from 'react';

import { SVGIcon } from '../../UIKit/index';

export default function DCGrayFollower(props) {
    return (
        <SVGIcon {...props} viewBox="0 0 545.152 469.41">
            <g id="Artboard_3" transform="translate(-5.23 -11.94)">
                <circle
                    id="Ellipse_1"
                    cx="215.55"
                    cy="215.55"
                    r="215.55"
                    transform="translate(31.51 13.44)"
                    fill="#fff"
                    stroke="#717272"
                    strokeMiterlimit="10"
                    strokeWidth="3"
                />
                <ellipse
                    id="_Path_"
                    cx="123.64"
                    cy="11.94"
                    rx="123.64"
                    ry="11.94"
                    transform="translate(123.42 457.47)"
                    fill="#f5f6f7"
                />
                <path
                    id="Path_1"
                    d="M5.23,93.3a8,8,0,0,1,8-8H367.9a8,8,0,0,1,8,8v25.58H5.23Z"
                    fill="#717171"
                />
                <path
                    id="Path_2"
                    d="M69.49,108.83a6.72,6.72,0,1,1,6.72-6.72A6.72,6.72,0,0,1,69.49,108.83Zm0-12a2.2,2.2,0,1,0,0-.02Z"
                    fill="#f5f6f7"
                />
                <path
                    id="Path_3"
                    d="M48.2,108.83a6.72,6.72,0,1,1,6.71-6.72,6.72,6.72,0,0,1-6.71,6.72Zm0-12a2.2,2.2,0,1,0,0-.02Z"
                    fill="#f5f6f7"
                />
                <path
                    id="Path_4"
                    d="M26.91,108.83a6.72,6.72,0,1,1,6.71-6.72,6.72,6.72,0,0,1-6.71,6.72Zm0-12a5.31,5.31,0,1,0,0-.02Z"
                    fill="#f5f6f7"
                />
                <path
                    id="Path_5"
                    d="M367.9,363H13.18a8,8,0,0,1-7.95-8V118.88H375.85V355a8,8,0,0,1-7.95,8Z"
                    fill="#e4e4e4"
                />
                <rect
                    id="Rectangle_1"
                    data-name="Rectangle 1"
                    width="366.11"
                    height="209.29"
                    transform="translate(7.48 125.68)"
                    fill="#fff"
                />
                <rect
                    id="Rectangle_2"
                    data-name="Rectangle 2"
                    width="149.1"
                    height="28.27"
                    rx="4.82"
                    transform="translate(25.42 143.62)"
                    fill="#e4e4e4"
                />
                <path
                    id="Path_6"
                    d="M350.83,144.88a3.57,3.57,0,0,1,3.56,3.56v18.63a3.56,3.56,0,0,1-3.56,3.55H211.38a3.56,3.56,0,0,1-3.56-3.55V148.44a3.57,3.57,0,0,1,3.56-3.56H350.83m0-1.26H211.38a4.82,4.82,0,0,0-4.83,4.82v18.63a4.82,4.82,0,0,0,4.83,4.82H350.83a4.82,4.82,0,0,0,4.83-4.82V148.44a4.82,4.82,0,0,0-4.83-4.82Z"
                    fill="#717171"
                />
                <path
                    id="Path_7"
                    d="M50.21,184.55a23.52,23.52,0,1,1-23.52,23.52,23.52,23.52,0,0,1,23.52-23.52m0-1.27a24.823,24.823,0,1,0,.01,0Z"
                    fill="#717171"
                />
                <path
                    id="Path_8"
                    d="M68.19,217.81a20.45,20.45,0,0,1-36,0,32.38,32.38,0,0,1,36,0Z"
                    fill="#717171"
                />
                <circle
                    id="Ellipse_2"
                    cx="9.11"
                    cy="9.11"
                    r="9.11"
                    transform="translate(41.1 191.96)"
                    fill="#717171"
                />
                <path
                    id="Path_9"
                    d="M342.91,220.81H101.66a12.74,12.74,0,0,1-12.74-12.74h0a12.74,12.74,0,0,1,12.74-12.74H342.91a12.74,12.74,0,0,1,12.75,12.74h0A12.74,12.74,0,0,1,342.91,220.81Z"
                    fill="#e4e4e4"
                />
                <path
                    id="Path_10"
                    d="M50.21,252.06a23.52,23.52,0,1,1-23.52,23.52,23.52,23.52,0,0,1,23.52-23.52m0-1.26A24.79,24.79,0,1,0,75,275.58,24.79,24.79,0,0,0,50.21,250.8Z"
                    fill="#717171"
                />
                <path
                    id="Path_11"
                    d="M68.19,285.32a20.45,20.45,0,0,1-36,0,32.38,32.38,0,0,1,36,0Z"
                    fill="#717171"
                    opacity="0.4"
                />
                <circle
                    id="Ellipse_3"
                    cx="9.11"
                    cy="9.11"
                    r="9.11"
                    transform="translate(41.1 259.47)"
                    fill="#717171"
                    opacity="0.4"
                />
                <rect
                    id="Rectangle_3"
                    data-name="Rectangle 3"
                    width="266.74"
                    height="25.48"
                    rx="10.04"
                    transform="translate(88.92 262.84)"
                    fill="#e4e4e4"
                />
                <path
                    id="Path_12"
                    d="M283.49,288a118.184,118.184,0,0,0,2.27,23,47.57,47.57,0,0,0-23.28,40.8,46.711,46.711,0,0,0,1.33,11.2H367.9a8,8,0,0,0,8-8V172.8A118.14,118.14,0,0,0,283.49,288Z"
                    opacity="0.1"
                />
                <circle
                    id="Ellipse_4"
                    cx="105.31"
                    cy="105.31"
                    r="105.31"
                    transform="translate(252.519 292.941) rotate(-45)"
                    fill="#e4e4e4"
                />
                <circle
                    id="Ellipse_5"
                    cx="96"
                    cy="96"
                    r="96"
                    transform="translate(266.18 292.799) rotate(-45)"
                    fill="#fff"
                />
                <path
                    id="Path_13"
                    d="M369.56,228.39l-6.13,7.85s-5.38,4.08-7.41,7.57c-2.38,2.36-4.57,4.77-6.72,7.31a105.733,105.733,0,0,0-6.88,9.13,54.1,54.1,0,0,0-5.73,10.88,23.328,23.328,0,0,0-1.53,7.58,14.39,14.39,0,0,0,1,5.38,12.65,12.65,0,0,0,4,5.31l1.34,1,2,.38A68.137,68.137,0,0,0,355.55,292l1.6-.14-1.61-16c-1.36.05-4.27.26-5.61.23.05-.24.1-.5.17-.75a47.862,47.862,0,0,1,3.3-8.85c1.41-3.07,3.05-6.15,4.74-9.22,1.49-2.7,3.1-5.43,4.66-8.08l7.58-4.78,4.45-7.9Zm-19.76,50a1.84,1.84,0,0,1-.05-.37,1.189,1.189,0,0,1,.11.29c.14.31,0,.33-.06.09Z"
                    fill="#e4e4e4"
                />
                <path
                    id="Path_14"
                    d="M412.7,245.35c.19-1-.12-1-.37-1a1.6,1.6,0,0,0-.94,1.06,17.48,17.48,0,0,1-4.55,4.79c-.25.18-.49.36-.76.54a14.57,14.57,0,0,1-7.46,2.45c.05.27.1.53.14.8q.14.93.21,1.86c.28,4-.77,7.79-5.32,9.93,0,.25-.62.52-.82.7-1.27,1.13-3,3.61-3,5.75,0,1.66,1.54,2.95,3.36,4.52l2.56,1.72,3.47-.33c10.65-3.13,15.61-12.86,15.38-13.58C411.84,260,411.87,252.17,412.7,245.35Z"
                    fill="#e4e4e4"
                />
                <path
                    id="Path_15"
                    d="M435.72,268.42l-1.94-.33c-2.52-.4-13.81-3-19.27-3.45h0c-1.29,2.69-5.42,9.27-16.06,12.4l-2.64.62-2.48-1.31c-1.82-1.57-2.75-3.21-2.76-4.86,0-2.15,1.49-3.94,2.76-5.07a1.289,1.289,0,0,0,.22-.3,1.349,1.349,0,0,1,0-.53c-.79.1-10.89,1.63-11.74,1.77-13.14,2.09-27.94,7.76-27.94,7.76l.11.91L355.81,292l.69,6s5.07-.57,11.71-1.74c-1.82,16.13-2.17,39.5,3.36,69.54,5.83-.11,21.61-.42,21.61-.41l37.54-.7c.16-2.53-.12-7.77-.39-14.93L430,338.72c-.2-9.77-.16-21.5.71-34.09q.21-3,.49-6h0c7.67,8.3,15.36,16.23,15.36,16.23l3.63-3.5c4.64-2.25,8.3-6.25,11.49-11.08l4.27-4.11S446.09,270.16,435.72,268.42Z"
                    fill="#717171"
                />
                <path
                    id="Path_16"
                    d="M425.32,292.19a18.75,18.75,0,0,0,2.47,7.46l2.91,5q.21-3,.49-6C429.18,296.46,427.18,294.27,425.32,292.19Z"
                    opacity="0.3"
                />
                <path
                    id="Path_17"
                    d="M431.19,298.63h0q-.29,3-.49,6h0C430.84,302.66,431,300.65,431.19,298.63Z"
                    opacity="0.3"
                />
                <path
                    id="Path_18"
                    d="M398.62,253.16c.05.27.1.54.14.8A4.472,4.472,0,0,0,398.62,253.16Z"
                    fill="#803f3c"
                />
                <path
                    id="Path_19"
                    d="M399,255.82q-.08-.93-.21-1.86C398.85,254.58,398.93,255.2,399,255.82Z"
                    opacity="0.2"
                />
                <path
                    id="Path_20"
                    d="M406.84,250.17c-.25.18-.49.36-.76.54a14.57,14.57,0,0,1-7.46,2.45c.05.27.1.53.14.8q.14.93.21,1.86v.11a12.78,12.78,0,0,0,7.87-5.76Z"
                    fill="#803f3c"
                />
                <path
                    id="Path_21"
                    d="M421.05,266.57a2.83,2.83,0,0,0-2.1-2.15l-2.63-.63a1.59,1.59,0,0,0-1.73.74,1.187,1.187,0,0,0-.08.12c-1.29,2.69-5.42,9.27-16.06,12.4l-.83.2h0l-1.81.42-2.48-1.31c-1.82-1.57-2.75-3.21-2.76-4.86,0-2.15,1.49-3.94,2.76-5.07a3.086,3.086,0,0,0,.36-.47c.12-.3.09-.44-.11-.57-2.79-2-9.05,1.09-9.3,1.63a9.485,9.485,0,0,0-.79.86,8.7,8.7,0,0,0-2,6.71,13.53,13.53,0,0,0,6.41,9.82.32.32,0,0,0,.19,0,.39.39,0,0,0,.2-.06.37.37,0,0,0,.17-.34c0-.19-.25-4.58,4.68-7l2.22,1.17c-9.52,39.08-3.06,85.89-2.88,87.16h.73c-.07-.48-6.71-47.71,2.88-87l2.33-.55c2.69,3.29,1.41,8.18,1.4,8.23a.38.38,0,0,0,.12.38.36.36,0,0,0,.24.09h.15c.51-.22,12.48-5.48,16.66-10.47a19.86,19.86,0,0,0,4.08-8.23,2.69,2.69,0,0,0-.02-1.22Z"
                    fill="#f5f6f7"
                />
                <path
                    id="Path_22"
                    d="M406.84,250.17c-.25.18-.49.36-.76.54a14.57,14.57,0,0,1-7.46,2.45c.05.27.1.53.14.8q.14.93.21,1.86v.11a12.78,12.78,0,0,0,7.87-5.76Z"
                    fill="#e4e4e4"
                />
                <path
                    id="Path_23"
                    d="M388.42,220.64a23.279,23.279,0,0,1,4.65-6,9,9,0,0,1-2.15-2.21c-2,.94-3.88,3.59-4.86,9.51a15.179,15.179,0,0,0-.1,4.23c.38-1.11.82-2.21,1.31-3.28Z"
                    fill="#717171"
                />
                <path
                    id="Path_24"
                    d="M412,236.18c5.71-6.62,2.08-15-1.27-16.93.62-8.84-12.09-10-17.61-4.6a23.278,23.278,0,0,0-4.65,6l-1.15,2.26c-.49,1.07-.93,2.17-1.31,3.28a38.87,38.87,0,0,0-2.15,12.44,14.46,14.46,0,0,0,14.81,14.54h0a14.57,14.57,0,0,0,7.46-2.45c.27-.18.51-.36.76-.54a17.48,17.48,0,0,0,4.55-4.79C414.23,240.83,413.49,238.54,412,236.18Z"
                    fill="#e4e4e4"
                />
                <path
                    id="Path_25"
                    d="M386.57,222.15a.52.52,0,0,0,.09.73.478.478,0,0,0,.61.02.38.38,0,0,0,.12-.1,4.43,4.43,0,0,1,3.46-1.74.52.52,0,1,0,.07-1,5.11,5.11,0,0,0-2.5.62,5.65,5.65,0,0,0-1.85,1.47Z"
                    fill="#717171"
                />
                <path
                    id="Path_26"
                    d="M400.27,237.32a.27.27,0,0,0-.37,0,7.43,7.43,0,0,1-6.3,1.57.26.26,0,0,0-.31.2.27.27,0,0,0,.21.31,8.538,8.538,0,0,0,1.83.17,7.59,7.59,0,0,0,4.93-1.88.27.27,0,0,0,.01-.37Z"
                    fill="#717171"
                />
                <path
                    id="Path_27"
                    d="M393.44,228.28s-3.17,4.62-5.93,6.6c1.21,1.74,4,1.61,4,1.61Z"
                    fill="#717171"
                />
                <path
                    id="Path_28"
                    d="M389,228.59c.57.11,1.2-.5,1.41-1.37s-.1-1.66-.67-1.78-1.2.5-1.4,1.37S388.42,228.48,389,228.59Z"
                    fill="#717171"
                />
                <path
                    id="Path_29"
                    d="M398.88,230.56c.57.11,1.2-.5,1.41-1.37s-.09-1.66-.67-1.78-1.2.5-1.4,1.37S398.31,230.45,398.88,230.56Z"
                    fill="#717171"
                />
                <path
                    id="Path_30"
                    d="M411.84,219.22h-1.16a23.941,23.941,0,0,0-1.5,5.5A14.64,14.64,0,0,0,412,236.18c2.59,4.4,5.92,4.28,8.8.92,4.63-4.45,9.1-13.32-.12-20.29C419.18,218.05,416.45,219,411.84,219.22Z"
                    fill="#717171"
                />
                <path
                    id="Path_31"
                    d="M418.29,234.5c-2.09-.86-4.46.15-6.34,1.68a12.14,12.14,0,0,0-2.61,2.87c-1.79,2.8-1.16,6,2.05,6.33a3.288,3.288,0,0,0,.55,0,6.6,6.6,0,0,0,.76-.05,10.94,10.94,0,0,0,6.67-3.16,5.14,5.14,0,0,0,1.38-5.09,4.14,4.14,0,0,0-2.46-2.58Z"
                    fill="#e4e4e4"
                />
                <path
                    id="Path_32"
                    d="M393.06,214.65c4.63,3.49,13.06,4.62,17.62,4.6h1.16c4.61-.27,7.34-1.17,8.79-2.41,5.35-4.58-6.85-13.88-6.85-13.88s1.36,3.34-1.48,4.26-22.82-6.52-22.48,1.92a6.39,6.39,0,0,0,1.1,3.33,9.129,9.129,0,0,0,2.14,2.18Z"
                    fill="#717171"
                />
                <path
                    id="Path_33"
                    d="M371.57,365.82s-.34,2.95-.87,7.93a96.18,96.18,0,0,0,59.86.56c.13-6,.21-9.6.21-9.6l-37.59.7h-.73Z"
                    fill="#e4e4e4"
                />
                <path
                    id="Path_34"
                    d="M469.45,314.85a13.3,13.3,0,0,0-.73-3.16,13.924,13.924,0,0,0-.65-1.47l-.38-.69-.2-.34a101.874,101.874,0,0,0-5.82-8.91l-11.49,11.08c1.53,1.89,3,3.8,4.37,5.75a9.462,9.462,0,0,1-1,1.83,42.2,42.2,0,0,1-6.27,7,181.256,181.256,0,0,1-15.57,12.57l-1.75.24-7.07,1L416,344.37l4.56,9.3,5.75-2.94a22.372,22.372,0,0,0,4-1c1.95-.67,4.05-1.77,5-3.52a107.584,107.584,0,0,0,9.87-4.51,96.732,96.732,0,0,0,10-5.93c1.64-1.12,3.24-2.33,4.84-3.67a36.69,36.69,0,0,0,4.69-4.59,21.23,21.23,0,0,0,4.13-7.05,13.84,13.84,0,0,0,.61-5.61Zm-14.8,2v-.19c0-.31.1-.42.1-.24a1.159,1.159,0,0,1-.1.42Z"
                    fill="#e4e4e4"
                />
                <circle
                    id="Ellipse_6"
                    cx="34.75"
                    cy="34.75"
                    r="34.75"
                    transform="translate(270.026 375.424) rotate(-80.78)"
                    fill="#717171"
                />
                <path
                    id="Path_35"
                    d="M329,343.42H313.14V327.57a3.25,3.25,0,1,0-6.5,0v15.85H290.79a3.26,3.26,0,1,0,0,6.51h15.85v15.85a3.25,3.25,0,1,0,6.5,0V349.93H329a3.26,3.26,0,1,0,0-6.51Z"
                    fill="#fff"
                />
                <path
                    id="Path_36"
                    d="M400.78,224a4,4,0,0,1,2.88,2.35.51.51,0,0,0,.48.3.49.49,0,0,0,.21,0,.53.53,0,0,0,.26-.7,5,5,0,0,0-3.7-2.95.52.52,0,0,0-.13,1Z"
                    fill="#263238"
                />
                <circle
                    id="Ellipse_7"
                    cx="9.27"
                    cy="9.27"
                    r="9.27"
                    transform="translate(48.07 378.82)"
                    fill="#fff"
                    stroke="#e3e3e3"
                    strokeMiterlimit="10"
                    strokeWidth="2"
                />
                <path
                    id="Path_37"
                    d="M410.39,163.08h-9.8v-9.79a1.22,1.22,0,0,0-1.22-1.22h0a1.24,1.24,0,0,0-1.24,1.22v9.79h-9.78a1.23,1.23,0,1,0,0,2.46h9.78v9.8a1.24,1.24,0,0,0,1.24,1.22,1.21,1.21,0,0,0,1.22-1.22h0v-9.8h9.8a1.23,1.23,0,1,0,0-2.46Z"
                    fill="#e3e3e3"
                    stroke="#e3e3e3"
                    strokeMiterlimit="10"
                    strokeWidth="0.75"
                />
                <path
                    id="Path_38"
                    d="M448.78,181.56h-5.37v-5.37a.67.67,0,0,0-.66-.66h0a.68.68,0,0,0-.69.66v5.37h-5.35a.68.68,0,1,0,0,1.35h5.35v5.37a.69.69,0,0,0,.69.67.67.67,0,0,0,.66-.67h0v-5.37h5.37a.68.68,0,1,0,0-1.35Z"
                    fill="#e3e3e3"
                    stroke="#e3e3e3"
                    strokeMiterlimit="10"
                    strokeWidth="0.75"
                />
                <path
                    id="Path_39"
                    d="M99.08,411.76H92v-7.09a.89.89,0,0,0-.89-.88h0a.9.9,0,0,0-.9.88v7.09H83.13a.89.89,0,1,0,0,1.78H90.2v7.1a.9.9,0,0,0,.9.88.88.88,0,0,0,.89-.88h0v-7.1h7.09a.89.89,0,0,0,0-1.78Z"
                    fill="#e3e3e3"
                    stroke="#e3e3e3"
                    strokeMiterlimit="10"
                    strokeWidth="0.75"
                />
            </g>
        </SVGIcon>
    );
}
