const actions = {
    LOADING: 'LOADING',

    CALL_GET_CARD_API: 'CALL_GET_CARD_API',
    CALL_ADD_CONTACT_API: 'CALL_ADD_CONTACT_API',

    CALL_GET_DEAL_API: 'CALL_GET_DEAL_API',
    CALL_ADD_DEAL_API: 'CALL_ADD_DEAL_API',

    ADD_CONTACT_API: 'ADD_CONTACT_API',

    CLEANUP: 'CLEANUP',
    ERROR: 'ERROR',
};

export default actions;
