import React from 'react';
import PropTypes from 'prop-types';
import generateImage from '../../Utils/generateImage';
import { Div, Image } from '../index';
import './style.scss';

const SingleAvatar = props => {
    const { customClass, image, name, onClick, zIndex, showColoredSvg, hideNotAvailableSvg } =
        props;
    return (
        <Div
            className={`SingleAvatar ${customClass || ''}`}
            onClick={onClick}
            style={{ zIndex: zIndex }}
        >
            <Div className="SingleAvatarInner">
                {image ? (
                    typeof image === 'string' ? (
                        <Image
                            src={image}
                            alt={''}
                            displayName={name}
                            showColoredSvg={showColoredSvg}
                            hideNotAvailableSvg={hideNotAvailableSvg}
                        />
                    ) : (
                        image
                    )
                ) : (
                    <Image
                        src={generateImage(image, name)}
                        showColoredSvg={showColoredSvg}
                        hideNotAvailableSvg={hideNotAvailableSvg}
                    />
                )}
            </Div>
        </Div>
    );
};

SingleAvatar.propTypes = {
    customClass: PropTypes.string,
    image: PropTypes.string | PropTypes.element,
    name: PropTypes.string,
    onClick: PropTypes.func,
    zIndex: PropTypes.number,
    showColoredSvg: PropTypes.bool,
    hideNotAvailableSvg: PropTypes.bool,
};

export default SingleAvatar;
