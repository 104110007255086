import { CallAPI } from 'Utils/apiCall';
import config from 'config';
import { getUserDetails } from 'Utils/localStorageUtils';

const api = new CallAPI();
const mainURL = `${config.REACT_APP_API_BASE_URL}/${process.env.REACT_APP_API_VERSION}`;

export const getNewsHeadline = async (page, limit) => {
    return api.request('GET', `${mainURL}/admin/announcements?page=${page}&limit=${limit}`);
};

export const deleteNewsHeadline = async id => {
    return api.request('DELETE', `${mainURL}/admin/announcements/${id}`);
};

export const disableNewsHeadline = async id => {
    return api.request('PUT', `${mainURL}/users/${getUserDetails().id}/announcement/${id}`, {
        is_dismissed: true,
    });
};

export const addNewsHeadline = async body => {
    return api.request('POST', `${mainURL}/admin/announcements`, body);
};

export const editNewsHeadline = async (id, body) => {
    return api.request('PUT', `${mainURL}/admin/announcements/${id}`, body);
};
