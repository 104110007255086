import React from 'react';

import { SVGIcon } from '../../UIKit/index';

export default function DCWebSection(props) {
    return (
        <SVGIcon {...props} viewBox="0 0 469.333 469.333">
            <path d="M448,0H21.333C9.552,0,0,9.552,0,21.333V448c0,11.781,9.552,21.333,21.333,21.333H448    c11.781,0,21.333-9.552,21.333-21.333V21.333C469.333,9.552,459.781,0,448,0z M138.667,426.667h-96v-384h96V426.667z     M426.667,426.667H181.333V256h245.333V426.667z M426.667,213.333H181.333V42.667h245.333V213.333z" />
        </SVGIcon>
    );
}
