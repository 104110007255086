import React from 'react';

import { SVGIcon } from '../../UIKit/index';

export default function DCGrayEmailTemplate(props) {
    return (
        <SVGIcon {...props} viewBox="0 0 455.36 499.37">
            <g id="Email_Template" data-name="Email Template" transform="translate(-31 -5.71)">
                <circle
                    id="Ellipse_9"
                    data-name="Ellipse 9"
                    cx="226.18"
                    cy="226.18"
                    r="226.18"
                    transform="translate(32.5 7.21)"
                    fill="#fff"
                    stroke="#707171"
                    strokeMiterlimit="10"
                    strokeWidth="3"
                />
                <path
                    id="Path_120"
                    data-name="Path 120"
                    d="M249,433.94c-1.3-1.11-4.19-4.63-2-8.72.82-1.5,2.55-2.24,4.12-2.9A16.87,16.87,0,0,0,255.7,420c2.45-2.09,3.12-5.55,4.75-8.33,1.86-3.19,5.35-5.58,9-5.41,4.21.19,7.73,3.63,9.32,7.56s1.65,8.28,1.68,12.52c0,2.34-.08,5-1.8,6.57s-4,1.47-6.13,1.41l-23-.71"
                    fill="#f5f5f5"
                />
                <path
                    id="Path_121"
                    data-name="Path 121"
                    d="M293.07,370.61H232.5l10.91,65.05a7.29,7.29,0,0,0,5.17,5.8l.21.05a5.362,5.362,0,0,0,.54.14,6.261,6.261,0,0,0,1.23.14v.07l.3-.05h27a7.73,7.73,0,0,0,7.61-6.51l5.26-44.82.24-1.78h0L293,370.94Zm-11.43,41.5h-6.22l.63-18.89h7.2Zm2.22-18.89h5.95l-2.22,18.89h-5.35ZM266.1,433l-.38-16.41h8.95L274.13,433Zm-9.68-16.41h8.7L265.5,433h-7.8Zm.67,16.41H249.8c-.69-4.43-1.59-10.19-2.57-16.41h8.59Zm-1-20.93-1.47-18.89h10l.43,18.89Zm9.55,0-.44-18.89h10.27l-.63,18.89Zm-.54-23.41-.41-17.49H276.2l-.58,17.49Zm-.6,0H254.25l-1.36-17.49h11.18Zm-10.83,0H242.83c-.38-2.42-.75-4.7-1.08-6.76-1.12-7.07-1.55-9.73-1.78-10.73h12.32Zm.35,4.52,1.47,18.89h-8.95c-.3-1.92-.61-3.86-.91-5.8-.7-4.45-1.4-8.91-2.06-13.09Zm-8.09,18.89h-5.84l-3.17-18.89h6c.94,5.88,1.96,12.37,2.99,18.93Zm.71,4.52c.89,5.68,1.77,11.28,2.57,16.41H243.6l-2.75-16.41ZM274.73,433l.54-16.41h6L279.85,433Zm1.47-44.34.58-17.49h8.35l-1.49,17.49Zm-36.81-17.49c.24,1.27,1.35,8.15,2.83,17.49h-6.08l-2.93-17.49ZM285.14,433h-4.69l1.41-16.41h5.2Zm5.2-44.34h-6.1l1.5-17.49h6.65Z"
                    fill="#e3e2e3"
                />
                <path
                    id="Path_122"
                    data-name="Path 122"
                    d="M304.19,322.29l-32.67,10.18a4.28,4.28,0,0,1-5.37-2.81l-14.09-45.22a4.3,4.3,0,0,1,2.81-5.37l20.72-6.46,21.2,11.54L307,316.93a4.27,4.27,0,0,1-2.81,5.36Z"
                    fill="#f4f5f6"
                />
                <path
                    id="Path_123"
                    data-name="Path 123"
                    d="M275.59,272.61l4,12.73a4,4,0,0,0,5,2.62l12.24-3.81Z"
                    fill="#e3e2e3"
                />
                <path
                    id="Path_124"
                    data-name="Path 124"
                    d="M276.66,285.68a54.058,54.058,0,0,1-7.47,2.65c-4.18,1.3-7.6,2.22-7.65,2.06s3.29-1.35,7.47-2.65S276.61,285.51,276.66,285.68Z"
                    fill="#707171"
                />
                <path
                    id="Path_125"
                    data-name="Path 125"
                    d="M288.66,289.83c.06.16-5.47,2-12.34,4.17s-12.48,3.75-12.53,3.58,5.47-2,12.34-4.17S288.61,289.67,288.66,289.83Z"
                    fill="#707171"
                />
                <path
                    id="Path_126"
                    data-name="Path 126"
                    d="M292.78,295.14c.05.16-6,2.19-13.47,4.52s-13.6,4.1-13.65,3.93,6-2.18,13.47-4.52S292.73,295,292.78,295.14Z"
                    fill="#707171"
                />
                <path
                    id="Path_127"
                    data-name="Path 127"
                    d="M294.55,300.81c.05.16-6,2.19-13.47,4.52s-13.6,4.1-13.65,3.93,6-2.19,13.46-4.52S294.49,300.65,294.55,300.81Z"
                    fill="#707171"
                />
                <path
                    id="Path_128"
                    data-name="Path 128"
                    d="M296.31,306.48c.05.16-6,2.19-13.46,4.52s-13.61,4.1-13.66,3.93,6-2.19,13.47-4.52S296.26,306.32,296.31,306.48Z"
                    fill="#707171"
                />
                <path
                    id="Path_129"
                    data-name="Path 129"
                    d="M298.08,312.15c.05.16-6,2.19-13.47,4.52S271,320.77,271,320.6s6-2.19,13.47-4.52S298,312,298.08,312.15Z"
                    fill="#707171"
                />
                <path
                    id="Path_130"
                    data-name="Path 130"
                    d="M103.39,191.47l-31.9-12.41a4.29,4.29,0,0,1-2.44-5.54l17.18-44.15a4.28,4.28,0,0,1,5.54-2.44L112,134.8l9.38,22.2-12.45,32a4.29,4.29,0,0,1-5.54,2.47Z"
                    fill="#f4f5f6"
                />
                <path
                    id="Path_131"
                    data-name="Path 131"
                    d="M112,134.8l-4.84,12.43a4,4,0,0,0,2.27,5.15L121.38,157Z"
                    fill="#e3e2e3"
                />
                <path
                    id="Path_132"
                    data-name="Path 132"
                    d="M104.69,145.68c-.07.16-3.42-1-7.49-2.58s-7.33-3-7.27-3.16,3.42,1,7.49,2.58S104.75,145.52,104.69,145.68Z"
                    fill="#707171"
                />
                <path
                    id="Path_133"
                    data-name="Path 133"
                    d="M111.48,156.41c-.06.16-5.55-1.83-12.25-4.43s-12.09-4.86-12-5,5.55,1.83,12.25,4.44S111.55,156.25,111.48,156.41Z"
                    fill="#707171"
                />
                <path
                    id="Path_134"
                    data-name="Path 134"
                    d="M111.39,163.13c-.06.16-6-2-13.35-4.86S84.85,153,84.92,152.83s6,2,13.35,4.86S111.45,163,111.39,163.13Z"
                    fill="#707171"
                />
                <path
                    id="Path_135"
                    data-name="Path 135"
                    d="M109.24,168.66c-.07.16-6-2-13.35-4.86s-13.19-5.28-13.13-5.44,6,2,13.35,4.86S109.3,168.5,109.24,168.66Z"
                    fill="#707171"
                />
                <path
                    id="Path_136"
                    data-name="Path 136"
                    d="M107.08,174.2c-.06.16-6-2-13.35-4.86s-13.18-5.28-13.12-5.44,6,2,13.35,4.86S107.15,174,107.08,174.2Z"
                    fill="#707171"
                />
                <path
                    id="Path_137"
                    data-name="Path 137"
                    d="M104.93,179.73c-.06.16-6-2-13.35-4.86s-13.18-5.28-13.12-5.44,6,2,13.35,4.86S105,179.57,104.93,179.73Z"
                    fill="#707171"
                />
                <path
                    id="Path_138"
                    data-name="Path 138"
                    d="M119.45,243.82a2.55,2.55,0,0,1,2.15,2.9c-.13.89-.26,1.76-.32,2.15l-2.93,19.3a1.69,1.69,0,0,1-1.91,1.42l-44.18-6.72a1.68,1.68,0,0,1-1.42-1.82l2.26-25a1.68,1.68,0,0,1,1.9-1.54L85,236a4,4,0,0,1,2.44,1.34l1.79,2.06Z"
                    fill="#e3e2e3"
                />
                <rect
                    id="Rectangle_89"
                    data-name="Rectangle 89"
                    width="48.83"
                    height="29.47"
                    transform="translate(74.065 239.592) rotate(8.65)"
                    fill="#fff"
                />
                <path
                    id="Path_139"
                    data-name="Path 139"
                    d="M69.84,241.61l-.94,30.94a1.09,1.09,0,0,0,.92,1.11l47,7.15a1.08,1.08,0,0,0,1.22-.83l7-30a1.08,1.08,0,0,0-.88-1.32l-53.1-8.08a1.09,1.09,0,0,0-1.22,1.03Z"
                    fill="#e3e2e3"
                />
                <path
                    id="Path_140"
                    data-name="Path 140"
                    d="M209,244.65c-9.54,4.94-16.86,13.45-22.12,22.83s-8.65,19.65-12,29.86L172,350c11.1-18.89,20-41.1,22.42-62.88l-7.39-.66c5-.75,9.12-4.34,11.76-8.6s4-9.16,5.34-14l-9,1.34A33.38,33.38,0,0,0,209,244.65Z"
                    fill="#375a64"
                />
                <path
                    id="Path_141"
                    data-name="Path 141"
                    d="M209,244.65c-9.54,4.94-16.86,13.45-22.12,22.83s-8.65,19.65-12,29.86L172,350c11.1-18.89,20-41.1,22.42-62.88l-7.39-.66c5-.75,9.12-4.34,11.76-8.6s4-9.16,5.34-14l-9,1.34A33.38,33.38,0,0,0,209,244.65Z"
                    fill="#e3e2e3"
                />
                <path
                    id="Path_142"
                    data-name="Path 142"
                    d="M170.32,362.32s-24.1-28.84-23.83-56.38h9.74s-10.58-7-9.74-10.85,1.12-18.64,1.12-18.64l8.9,2.23s-6.68-3.34-7.51-8.62,1.94-29.77,1.94-29.77,10.85,11.41,14.19,23.09,12.1,25.31,9.53,60.92S170.32,362.32,170.32,362.32Z"
                    fill="#f4f5f6"
                />
                <path
                    id="Path_143"
                    data-name="Path 143"
                    d="M182.53,365.05s26.12-17.4,31.92-39.6l-7.83-2.13s10-3.28,10.2-6.59S220,301.5,220,301.5l-7.64-.16s6.1-1.22,7.93-5.29,4.94-24.35,4.94-24.35-11.22,6.8-16.46,15.46-15.26,17.7-21,46.89S182.53,365.05,182.53,365.05Z"
                    fill="#f4f5f6"
                />
                <path
                    id="Path_144"
                    data-name="Path 144"
                    d="M157.53,265.44a1.9,1.9,0,0,1,.08.3c.06.22.13.52.21.89l.77,3.42c.33,1.49.72,3.31,1.14,5.43s.93,4.54,1.42,7.22c1,5.35,2.12,11.74,3.24,18.85s2.17,15,3.14,23.22,1.68,16.15,2.24,23.33.93,13.66,1.15,19.1c.13,2.72.2,5.19.26,7.35s.12,4,.13,5.55,0,2.67,0,3.5v.92a1.5,1.5,0,0,1,0,.31,1.3,1.3,0,0,1,0-.31c0-.23,0-.53,0-.92,0-.82-.07-2-.13-3.5s-.1-3.38-.2-5.54-.17-4.62-.33-7.34c-.26-5.44-.69-11.91-1.24-19.09s-1.33-15.05-2.28-23.3-2-16.09-3.1-23.21-2.15-13.51-3.14-18.87c-.47-2.68-1-5.09-1.35-7.22s-.77-3.95-1.07-5.44l-.68-3.44c-.07-.38-.13-.67-.17-.9A1,1,0,0,1,157.53,265.44Z"
                    fill="#e3e2e3"
                />
                <path
                    id="Path_145"
                    data-name="Path 145"
                    d="M167,380s-22.05-25-28.9-40.15l5.17-2.21c-3.75.62-7.59-1-10.49-3.44s-5-5.66-7.13-8.83l6.81-1.15a25.32,25.32,0,0,1-14.88-11.53c8,1.32,15.3,5.74,21.29,11.25a106,106,0,0,1,12.91,15,50.19,50.19,0,0,1,5,8.24C160.47,354.79,167,370.8,167,380Z"
                    fill="#f4f5f6"
                />
                <path
                    id="Path_146"
                    data-name="Path 146"
                    d="M214.79,286.27a.75.75,0,0,1-.1.25c-.08.19-.2.43-.34.73L213,290c-1.18,2.41-2.86,5.9-4.88,10.24s-4.38,9.53-6.89,15.34-5.15,12.25-7.79,19.06-5,13.36-7.06,19.34-3.81,11.41-5.23,16-2.53,8.28-3.27,10.86c-.37,1.26-.66,2.25-.87,2.95l-.24.77-.09.26a1,1,0,0,1,.05-.27l.19-.78.8-3c.68-2.59,1.75-6.32,3.13-10.9s3.11-10,5.14-16,4.4-12.55,7-19.38,5.31-13.24,7.83-19.06,4.91-11,7-15.31,3.78-7.79,5-10.17l1.42-2.74.38-.71a1.5,1.5,0,0,1,.17-.23Z"
                    fill="#e3e2e3"
                />
                <path
                    id="Path_147"
                    data-name="Path 147"
                    d="M168.84,390.71a2.09,2.09,0,0,1-.1-.46c-.06-.35-.13-.79-.22-1.33-.16-1.15-.45-2.82-.86-4.86s-1-4.47-1.68-7.13a83.062,83.062,0,0,0-2.78-8.52c-1.12-2.97-2.35-5.75-3.55-8.24s-2.39-4.66-3.44-6.46-1.94-3.24-2.58-4.22l-.71-1.14a2.059,2.059,0,0,1-.23-.42,1.8,1.8,0,0,1,.3.37l.79,1.09c.68.95,1.61,2.37,2.7,4.16s2.3,4,3.54,6.44a86.777,86.777,0,0,1,3.61,8.26c1.14,2.99,2,5.9,2.75,8.57a72.435,72.435,0,0,1,1.6,7.18c.36,2.06.6,3.74.72,4.9.05.54.09,1,.13,1.34a1.669,1.669,0,0,1,.01.47Z"
                    fill="#e3e2e3"
                />
                <rect
                    id="Rectangle_90"
                    data-name="Rectangle 90"
                    width="48.27"
                    height="9.85"
                    transform="translate(149.68 384.83)"
                    fill="#707171"
                />
                <path
                    id="Path_148"
                    data-name="Path 148"
                    d="M154.28,394.67l5.22,33.12h29.16l4.87-33.12Z"
                    fill="#707171"
                />
                <g id="Group_2" data-name="Group 2" opacity="0.5">
                    <path
                        id="Path_149"
                        data-name="Path 149"
                        d="M195,394.67c0,.13-9.14.23-20.4.23s-20.39-.1-20.39-.23,9.13-.22,20.39-.22S195,394.55,195,394.67Z"
                        fill="#fff"
                    />
                </g>
                <rect
                    id="Rectangle_91"
                    data-name="Rectangle 91"
                    width="215.56"
                    height="135.02"
                    transform="translate(140.14 63.78)"
                    fill="#fff"
                />
                <rect
                    id="Rectangle_92"
                    data-name="Rectangle 92"
                    width="215.56"
                    height="12.37"
                    transform="translate(140.14 63.78)"
                    fill="#707171"
                />
                <rect
                    id="Rectangle_93"
                    data-name="Rectangle 93"
                    width="215.64"
                    height="24.34"
                    transform="translate(140.06 196.12)"
                    fill="#f4f5f6"
                />
                <path
                    id="Path_150"
                    data-name="Path 150"
                    d="M168.39,207.05l-1.58-.24-.71-1.43-.71,1.43-1.58.24,1.15,1.11-.02.08-.26,1.5,1.42-.75,1.42.75-.27-1.58.05-.05Z"
                    fill="none"
                />
                <path
                    id="Path_151"
                    data-name="Path 151"
                    d="M155.86,205.75a.95.95,0,1,0,.95.95A1,1,0,0,0,155.86,205.75Z"
                    fill="none"
                />
                <path
                    id="Path_152"
                    data-name="Path 152"
                    d="M184.39,207.9h1.93l.61,1.26h.41l-1.93-4h-.11l-1.93,4h.42Zm1-2,.78,1.63H184.6Z"
                    fill="#707171"
                />
                <rect
                    id="Rectangle_94"
                    data-name="Rectangle 94"
                    width="4.57"
                    height="0.32"
                    transform="translate(183.06 210.02)"
                    fill="#1a2e35"
                />
                <path
                    id="Path_153"
                    data-name="Path 153"
                    d="M156,204.91h-.31a1.69,1.69,0,0,0-1.6,1.73,7.19,7.19,0,0,0,1.6,3.43s.12.21.31,0a7.19,7.19,0,0,0,1.6-3.43,1.7,1.7,0,0,0-1.6-1.73Zm-.16,2.74a.943.943,0,1,1,.02,0Z"
                    fill="#707171"
                />
                <path
                    id="Path_154"
                    data-name="Path 154"
                    d="M177.4,208.93a1,1,0,0,1-2,0v-3.15a.63.63,0,0,1,1.26,0v2.67h0a.35.35,0,0,1-.35.28.36.36,0,0,1-.35-.28v-2.06h-.24v2h0v.09h0a.6.6,0,0,0,.58.51.59.59,0,0,0,.58-.51h0v-.09h0v-2.61h0a.86.86,0,0,0-.86-.84.87.87,0,0,0-.87.84h0V209h0a1.21,1.21,0,0,0,2.42,0h0v-2.65h-.24Z"
                    fill="#707171"
                />
                <path
                    id="Path_155"
                    data-name="Path 155"
                    d="M167,206.54l-.91-1.83-.9,1.83-2,.3,1.47,1.43-.35,2,1.81-.95,1.81.95-.35-2,1.47-1.43Zm.51,3.2L166.1,209l-1.42.75.26-1.5v-.08l-1.15-1.11,1.58-.24.71-1.43.71,1.43,1.58.24-1.09,1.06h-.05Z"
                    fill="#707171"
                />
                <path
                    id="Path_156"
                    data-name="Path 156"
                    d="M202.6,202.46h-.29v9.39h.29Zm-.14,9.39Z"
                    fill="#707171"
                />
                <rect
                    id="Rectangle_95"
                    data-name="Rectangle 95"
                    width="3.74"
                    height="3.98"
                    transform="translate(193.13 205.78)"
                    fill="#707171"
                />
                <path
                    id="Path_157"
                    data-name="Path 157"
                    d="M194.31,204.85v.31h1.37v-.31h1.18v.61h-3.73v-.61Z"
                    fill="#1a2e35"
                />
                <rect
                    id="Rectangle_96"
                    data-name="Rectangle 96"
                    width="1.38"
                    height="0.31"
                    transform="translate(194.31 204.54)"
                    fill="#1a2e35"
                />
                <rect
                    id="Rectangle_97"
                    data-name="Rectangle 97"
                    width="1.38"
                    height="0.31"
                    transform="translate(194.31 204.85)"
                    fill="#1a2e35"
                />
                <path
                    id="Path_158"
                    data-name="Path 158"
                    d="M211.06,207.92l1.15-1.15.14.14-1.29,1.28-1.28-1.28.13-.14Z"
                    fill="#707171"
                />
                <path
                    id="Path_159"
                    data-name="Path 159"
                    d="M343.82,111.21a1.27,1.27,0,0,0-1.27,1.23h0v4.83h0a1.78,1.78,0,0,0,3.54,0h0v-3.89h-.35v3.72a1.43,1.43,0,1,1-2.86,0v-4.62a.93.93,0,0,1,1.85,0v3.92h0a.53.53,0,0,1-.52.41.54.54,0,0,1-.51-.42v-3h-.35v2.88h0v.14h0a.86.86,0,0,0,.84.74.87.87,0,0,0,.85-.74h0v-.14h0v-3.82h0A1.28,1.28,0,0,0,343.82,111.21Z"
                    fill="#707171"
                />
                <path
                    id="Path_160"
                    data-name="Path 160"
                    d="M147.24,83h-1.48v-.53h3.56V83h-1.48v3.8h-.6Z"
                    fill="#707171"
                />
                <path
                    id="Path_161"
                    data-name="Path 161"
                    d="M149.26,85.18A1.69,1.69,0,1,1,151,86.84a1.62,1.62,0,0,1-1.74-1.66Zm2.78,0a1.074,1.074,0,1,0-2.14-.04,1.09,1.09,0,0,0,1.1,1.15,1.07,1.07,0,0,0,1-1.11Z"
                    fill="#707171"
                />
                <path
                    id="Path_162"
                    data-name="Path 162"
                    d="M154.83,83.94a.41.41,0,1,1,.41.41.41.41,0,0,1-.41-.41Zm0,2.48a.41.41,0,1,1,.41.41A.41.41,0,0,1,154.83,86.42Z"
                    fill="#707171"
                />
                <path
                    id="Path_163"
                    data-name="Path 163"
                    d="M146.64,99.11a2.292,2.292,0,0,1,3.91-1.53l-.4.39a1.6,1.6,0,0,0-1.21-.51,1.65,1.65,0,1,0,0,3.3,1.6,1.6,0,0,0,1.21-.51l.4.38a2.29,2.29,0,0,1-3.91-1.52Z"
                    fill="#707171"
                />
                <path
                    id="Path_164"
                    data-name="Path 164"
                    d="M150.9,99.63A1.63,1.63,0,0,1,152.62,98a1.43,1.43,0,0,1,1.32.7l-.44.29a1,1,0,0,0-.89-.47,1.15,1.15,0,0,0,0,2.29,1,1,0,0,0,.89-.47l.44.29a1.44,1.44,0,0,1-1.32.69,1.63,1.63,0,0,1-1.72-1.69Z"
                    fill="#707171"
                />
                <path
                    id="Path_165"
                    data-name="Path 165"
                    d="M156.07,98.39a.42.42,0,1,1,.119.3A.42.42,0,0,1,156.07,98.39Zm0,2.48a.42.42,0,1,1,.187.417.42.42,0,0,1-.187-.417Z"
                    fill="#707171"
                />
                <path
                    id="Path_166"
                    data-name="Path 166"
                    d="M146,115.41l.23-.48a2.19,2.19,0,0,0,1.41.5c.75,0,1.07-.29,1.07-.67,0-1.05-2.61-.38-2.61-2,0-.66.52-1.23,1.65-1.23a2.59,2.59,0,0,1,1.38.38l-.2.5a2.23,2.23,0,0,0-1.18-.35c-.73,0-1,.3-1,.68,0,1,2.6.39,2.6,2,0,.65-.53,1.22-1.67,1.22a2.54,2.54,0,0,1-1.68-.55Z"
                    fill="#707171"
                />
                <path
                    id="Path_167"
                    data-name="Path 167"
                    d="M153.14,112.65v3.25h-.56v-.49a1.33,1.33,0,0,1-1.11.53,1.28,1.28,0,0,1-1.4-1.42v-1.87h.59v1.8c0,.64.32,1,.88,1a1,1,0,0,0,1-1.08v-1.68Z"
                    fill="#707171"
                />
                <path
                    id="Path_168"
                    data-name="Path 168"
                    d="M157.63,114.27a1.59,1.59,0,0,1-1.65,1.67,1.37,1.37,0,0,1-1.16-.56v.52h-.56v-4.55h.58v1.79a1.39,1.39,0,0,1,1.14-.52,1.58,1.58,0,0,1,1.65,1.65Zm-.6,0a1.1,1.1,0,1,0-1.1,1.15,1.07,1.07,0,0,0,1.07-1.15Z"
                    fill="#707171"
                />
                <path
                    id="Path_169"
                    data-name="Path 169"
                    d="M157.31,116.93l.19-.45a.74.74,0,0,0,.48.15c.32,0,.49-.2.49-.57v-3.41h.59v3.4a1,1,0,0,1-1,1.08,1.15,1.15,0,0,1-.75-.2Zm1.05-5.29a.4.4,0,0,1,.4-.39.39.39,0,1,1-.4.39Z"
                    fill="#707171"
                />
                <path
                    id="Path_170"
                    data-name="Path 170"
                    d="M163.11,114.47h-2.64a1.1,1.1,0,0,0,1.18,1,1.19,1.19,0,0,0,.93-.39l.33.38a1.77,1.77,0,0,1-3-1.14,1.59,1.59,0,0,1,1.63-1.65,1.57,1.57,0,0,1,1.61,1.67A.319.319,0,0,0,163.11,114.47Zm-2.64-.42h2.08a1.04,1.04,0,1,0-2.08,0Z"
                    fill="#707171"
                />
                <path
                    id="Path_171"
                    data-name="Path 171"
                    d="M163.63,114.27a1.62,1.62,0,0,1,1.72-1.65,1.47,1.47,0,0,1,1.33.69l-.45.29a1,1,0,0,0-.89-.47,1.15,1.15,0,0,0,0,2.29,1,1,0,0,0,.89-.47l.45.29a1.48,1.48,0,0,1-1.33.7,1.64,1.64,0,0,1-1.72-1.67Z"
                    fill="#707171"
                />
                <path
                    id="Path_172"
                    data-name="Path 172"
                    d="M169.3,115.71a1.1,1.1,0,0,1-.71.23.93.93,0,0,1-1-1v-1.79H167v-.48h.55v-.72h.59v.72h.93v.48h-.93v1.76a.48.48,0,0,0,.51.55.79.79,0,0,0,.46-.15Z"
                    fill="#707171"
                />
                <path
                    id="Path_173"
                    data-name="Path 173"
                    d="M171.39,113a.41.41,0,0,1,.41-.41.4.4,0,0,1,.41.41.41.41,0,0,1-.41.43.42.42,0,0,1-.41-.43Zm0,2.48a.41.41,0,0,1,.41-.41.4.4,0,0,1,.41.41.41.41,0,0,1-.41.43.42.42,0,0,1-.41-.4Z"
                    fill="#707171"
                />
                <path
                    id="Path_174"
                    data-name="Path 174"
                    d="M225.25,86c0,.13-13.77.23-30.76.23s-30.77-.1-30.77-.23,13.77-.22,30.77-.22S225.25,85.91,225.25,86Z"
                    fill="#707171"
                />
                <path
                    id="Path_175"
                    data-name="Path 175"
                    d="M252.73,100.07c0,.13-19.93.23-44.5.23s-44.51-.1-44.51-.23,19.92-.22,44.51-.22S252.73,100,252.73,100.07Z"
                    fill="#707171"
                />
                <path
                    id="Path_176"
                    data-name="Path 176"
                    d="M219.82,114a1.34,1.34,0,0,1-.41,0h-1.17l-4.31.07c-3.63,0-8.65.07-14.2.07s-10.57,0-14.21-.07l-4.3-.07h-1.17a1.34,1.34,0,0,1-.41,0,1.65,1.65,0,0,1,.41,0h1.17l4.3-.07c3.64,0,8.66-.06,14.21-.06s10.57,0,14.2.06l4.31.07h1.17a1.65,1.65,0,0,1,.41,0Z"
                    fill="#707171"
                />
                <path
                    id="Path_177"
                    data-name="Path 177"
                    d="M353.37,121.58c0,.13-47.73.23-106.61.23s-106.62-.1-106.62-.23,47.73-.22,106.62-.22S353.37,121.46,353.37,121.58Z"
                    fill="#e1e1e1"
                />
                <path
                    id="Path_178"
                    data-name="Path 178"
                    d="M353.37,106.39c0,.12-47.75.22-106.65.22s-106.66-.1-106.66-.22,47.75-.23,106.66-.23S353.37,106.26,353.37,106.39Z"
                    fill="#e1e1e1"
                />
                <path
                    id="Path_179"
                    data-name="Path 179"
                    d="M353.37,93.05c0,.13-47.73.23-106.61.23s-106.62-.1-106.62-.23,47.73-.22,106.62-.22S353.37,92.93,353.37,93.05Z"
                    fill="#e1e1e1"
                />
                <path
                    id="Path_180"
                    data-name="Path 180"
                    d="M168.77,140.12a61.6,61.6,0,0,1-7.33.22,61.6,61.6,0,0,1-7.33-.22,57.9,57.9,0,0,1,7.33-.23,57.9,57.9,0,0,1,7.33.23Z"
                    fill="#707171"
                />
                <path
                    id="Path_181"
                    data-name="Path 181"
                    d="M252.26,148.6c0,.13-22.19.23-49.57.23s-49.56-.1-49.56-.23,22.18-.22,49.56-.22S252.26,148.48,252.26,148.6Z"
                    fill="#707171"
                />
                <path
                    id="Path_182"
                    data-name="Path 182"
                    d="M235.82,154.53c0,.11-18.52.19-41.35.19s-41.34-.08-41.34-.19,18.5-.19,41.34-.19S235.82,154.43,235.82,154.53Z"
                    fill="#707171"
                />
                <circle
                    id="Ellipse_10"
                    data-name="Ellipse 10"
                    cx="1.34"
                    cy="1.34"
                    r="1.34"
                    transform="translate(144.84 68.62)"
                    fill="#fff"
                />
                <circle
                    id="Ellipse_11"
                    data-name="Ellipse 11"
                    cx="1.34"
                    cy="1.34"
                    r="1.34"
                    transform="translate(149.61 68.62)"
                    fill="#fff"
                />
                <circle
                    id="Ellipse_12"
                    data-name="Ellipse 12"
                    cx="1.34"
                    cy="1.34"
                    r="1.34"
                    transform="translate(154.38 68.62)"
                    fill="#fff"
                />
                <path
                    id="Path_183"
                    data-name="Path 183"
                    d="M356.57,220.46c0-1.51-.08-61.67-.19-156.68l.19.18L140.16,64h0l.23-.22V220.46l-.21-.2,216.43.2-216.43.21h-.2V63.55H356.81v.19C356.64,158.79,356.57,219,356.57,220.46Z"
                    fill="#707171"
                />
                <rect
                    id="Rectangle_98"
                    data-name="Rectangle 98"
                    width="5"
                    height="2.69"
                    transform="translate(417.84 377.76)"
                    fill="#e3e2e3"
                />
                <rect
                    id="Rectangle_99"
                    data-name="Rectangle 99"
                    width="11.55"
                    height="57.28"
                    transform="translate(409.58 309.95)"
                    fill="#f4f5f6"
                />
                <path
                    id="Path_184"
                    data-name="Path 184"
                    d="M441.57,313H387.36a10.44,10.44,0,0,1-10.45-10.44h0a10.44,10.44,0,0,1,10.45-10.44h54.21A10.43,10.43,0,0,1,452,302.53h0A10.43,10.43,0,0,1,441.57,313Z"
                    fill="#707171"
                />
                <rect
                    id="Rectangle_100"
                    data-name="Rectangle 100"
                    width="7.32"
                    height="40.66"
                    transform="translate(411.74 367.24)"
                    fill="#e3e2e3"
                />
                <path
                    id="Path_185"
                    data-name="Path 185"
                    d="M386,427.71c-1.44.19,61.67,0,61.67,0s-26.92-3-28.66-19.82H411.7S409.9,424.68,386,427.71Z"
                    fill="#e3e2e3"
                />
                <path
                    id="Path_186"
                    data-name="Path 186"
                    d="M443.71,313c0,.13-12.53.23-28,.23s-28-.1-28-.23,12.53-.22,28-.22S443.71,312.85,443.71,313Z"
                    fill="#1a2e35"
                />
                <path
                    id="Path_187"
                    data-name="Path 187"
                    d="M421.13,367.24a77.357,77.357,0,0,1-11.66,0,74.007,74.007,0,0,1,11.66,0Z"
                    fill="#707171"
                />
                <path
                    id="Path_188"
                    data-name="Path 188"
                    d="M422.84,383.47h0a1.47,1.47,0,0,1-1.47-1.48v-5.77a1.47,1.47,0,0,1,1.47-1.47h0a1.47,1.47,0,0,1,1.48,1.47V382a1.48,1.48,0,0,1-1.48,1.47Z"
                    fill="#e3e2e3"
                />
                <path
                    id="Path_189"
                    data-name="Path 189"
                    d="M307.5,146.4s2.53-2.77,3.3-2.83,8.68,2.3,8.68,2.3a5.38,5.38,0,0,1,2.47,1.18c.71.83,8.51,9.31,8.51,9.31L321.79,164s-10.16-1.82-11.05-2.88-1.59-2.78-.94-3.31a4.39,4.39,0,0,1-.59-2.48,15.827,15.827,0,0,1,.53-1.8s-1.53-1.09-1.59-1.86a3,3,0,0,0-.3-1.18l-.35-.59Z"
                    fill="#e3e2e3"
                />
                <path
                    id="Path_190"
                    data-name="Path 190"
                    d="M311,148.06c.05,0,0,.27-.13.65a3.1,3.1,0,0,1-1,1.33,2,2,0,0,1-1.62.38c-.43-.11-.6-.35-.57-.37a5.013,5.013,0,0,0,.62.11,2,2,0,0,0,1.35-.41,3.94,3.94,0,0,0,.94-1.15C310.86,148.26,311,148,311,148.06Z"
                    fill="#ff9a6c"
                />
                <path
                    id="Path_191"
                    data-name="Path 191"
                    d="M276.34,128.25a4.72,4.72,0,0,0-4.76,4.68l-.41,52.17a4.72,4.72,0,0,0,4.68,4.76l37.7.29a4.71,4.71,0,0,0,4.75-4.68l.26-52.2a4.84,4.84,0,0,0-4.82-4.86Z"
                    fill="#f4f5f6"
                />
                <path
                    id="Path_192"
                    data-name="Path 192"
                    d="M297,144.1c0,.18-3.91.31-8.72.27s-8.72-.22-8.72-.41,3.91-.31,8.72-.28S297,143.91,297,144.1Z"
                    fill="#e1e1e1"
                />
                <path
                    id="Path_193"
                    data-name="Path 193"
                    d="M308.17,150.89c0,.19-6.43.29-14.35.22s-14.36-.26-14.35-.45,6.42-.29,14.35-.23S308.17,150.7,308.17,150.89Z"
                    fill="#e1e1e1"
                />
                <path
                    id="Path_194"
                    data-name="Path 194"
                    d="M310.7,157.84c0,.19-7,.29-15.65.22s-15.64-.28-15.64-.47,7-.28,15.65-.21S310.7,157.65,310.7,157.84Z"
                    fill="#e1e1e1"
                />
                <path
                    id="Path_195"
                    data-name="Path 195"
                    d="M310.65,164.38c0,.19-7,.29-15.65.22s-15.64-.28-15.64-.47,7-.28,15.65-.21S310.65,164.19,310.65,164.38Z"
                    fill="#e1e1e1"
                />
                <path
                    id="Path_196"
                    data-name="Path 196"
                    d="M310.6,170.92c0,.19-7,.29-15.65.22s-15.64-.28-15.64-.46,7-.29,15.65-.22S310.6,170.73,310.6,170.92Z"
                    fill="#e1e1e1"
                />
                <path
                    id="Path_197"
                    data-name="Path 197"
                    d="M310.54,177.46c0,.19-7,.29-15.64.22s-15.64-.27-15.64-.46,7-.29,15.64-.22S310.55,177.27,310.54,177.46Z"
                    fill="#e1e1e1"
                />
                <path
                    id="Path_198"
                    data-name="Path 198"
                    d="M274.59,126.69a4.71,4.71,0,0,0-4.75,4.68l-.41,52.17a4.72,4.72,0,0,0,4.68,4.76l37.69.3a4.72,4.72,0,0,0,4.76-4.68l.25-52.21a4.84,4.84,0,0,0-4.81-4.86Z"
                    fill="#fff"
                />
                <path
                    id="Path_199"
                    data-name="Path 199"
                    d="M274.59,126.69h36.17a16,16,0,0,1,1.94.06,5.012,5.012,0,0,1,4.21,4.01,11.423,11.423,0,0,1,.08,2.1c0,5.57,0,11.52-.06,17.78s-.06,12.83-.08,19.64c0,3.4,0,6.87-.05,10.39v2.65a5.64,5.64,0,0,1-.49,2.69,5,5,0,0,1-4.53,2.82l-21.09-.17-10-.08h-4.87a19.834,19.834,0,0,1-2.42-.08,4.81,4.81,0,0,1-2.23-1,5,5,0,0,1-1.9-4.23v-4.51c0-3,.05-5.85.08-8.63,0-5.57.1-10.76.15-15.49.1-9.46.19-17.12.25-22.43a7.68,7.68,0,0,1,.14-1.88,4.78,4.78,0,0,1,4.16-3.61,3.651,3.651,0,0,1,.54,0,5.13,5.13,0,0,0-2,.5,4.64,4.64,0,0,0-1.89,1.66,4.561,4.561,0,0,0-.63,1.49,8.331,8.331,0,0,0-.12,1.84c0,5.32-.06,13-.1,22.43,0,4.74-.07,9.92-.1,15.49q0,4.17-.06,8.63v4.51a4.58,4.58,0,0,0,1.75,3.9,4.5,4.5,0,0,0,2.05.89,22.648,22.648,0,0,0,2.36.07h4.86l10,.08,21.09.16a4.5,4.5,0,0,0,4.12-2.56,5.18,5.18,0,0,0,.45-2.51q0-1.32,0-2.64V170.27c0-6.82.08-13.39.11-19.65s.08-12.2.11-17.77a12.29,12.29,0,0,0-.07-2,4.591,4.591,0,0,0-.75-1.79,4.66,4.66,0,0,0-3.15-2,16.889,16.889,0,0,0-1.89-.06h-5.51l-22.43-.17-6.1-.07H275.1Z"
                    fill="#707171"
                />
                <path
                    id="Path_200"
                    data-name="Path 200"
                    d="M295.23,142.54c0,.19-3.91.31-8.72.27s-8.72-.22-8.72-.41,3.9-.31,8.72-.27S295.23,142.35,295.23,142.54Z"
                    fill="#707171"
                />
                <path
                    id="Path_201"
                    data-name="Path 201"
                    d="M306.43,149.33c0,.19-6.43.29-14.36.23s-14.35-.27-14.35-.46,6.43-.29,14.36-.23S306.43,149.14,306.43,149.33Z"
                    fill="#707171"
                />
                <path
                    id="Path_202"
                    data-name="Path 202"
                    d="M309,156.28c0,.19-7,.29-15.65.22s-15.64-.28-15.64-.46,7-.29,15.65-.22S309,156.09,309,156.28Z"
                    fill="#707171"
                />
                <path
                    id="Path_203"
                    data-name="Path 203"
                    d="M308.9,162.82c0,.19-7,.29-15.64.22s-15.64-.27-15.64-.46,7-.29,15.64-.22S308.91,162.63,308.9,162.82Z"
                    fill="#707171"
                />
                <path
                    id="Path_204"
                    data-name="Path 204"
                    d="M308.85,169.36c0,.19-7,.29-15.64.22s-15.65-.27-15.65-.46,7-.29,15.65-.22S308.85,169.18,308.85,169.36Z"
                    fill="#707171"
                />
                <path
                    id="Path_205"
                    data-name="Path 205"
                    d="M308.8,175.91c0,.18-7,.28-15.64.21s-15.65-.27-15.65-.46,7-.29,15.65-.22S308.8,175.72,308.8,175.91Z"
                    fill="#707171"
                />
                <path
                    id="Path_206"
                    data-name="Path 206"
                    d="M279.85,132.25v-4.34a1.31,1.31,0,0,0-1.32-1.31h0a1.31,1.31,0,0,0-1.31,1.31V134a2.1,2.1,0,0,0,2.11,2.11h0a2.1,2.1,0,0,0,2.1-2.11v-7a2.93,2.93,0,0,0-2.93-2.93h0a2.94,2.94,0,0,0-2.76,1.94v.35"
                    fill="none"
                    stroke="#707171"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="0.75"
                />
                <path
                    id="Path_207"
                    data-name="Path 207"
                    d="M325.37,152.39l-4.13-2.57-10.59-5.59s-2.1,2.05,1.45,5l4.38,3.46s3.89,7.14,4.72,7.67a50.308,50.308,0,0,0,4.66,1.9Z"
                    fill="#e3e2e3"
                />
                <path
                    id="Path_208"
                    data-name="Path 208"
                    d="M326.26,162.42a2.32,2.32,0,0,1-.43-.09c-.27-.08-.68-.2-1.19-.38s-1.14-.4-1.85-.71c-.36-.16-.73-.35-1.12-.57a3.57,3.57,0,0,1-1.06-.93A48.532,48.532,0,0,1,317,153.9q-.24-.41-.48-.84a2.72,2.72,0,0,0-.62-.7c-.5-.43-1.05-.85-1.6-1.27a41.1,41.1,0,0,1-3.37-2.75,3.51,3.51,0,0,1-.6-4.32l.09-.13.15.08c4.23,2.39,7.88,4.58,10.64,5.85,1.33.73,2.36,1.39,3.06,1.84l.8.53c.18.12.28.19.27.2a1.079,1.079,0,0,1-.3-.15l-.83-.47c-.73-.42-1.77-1-3.11-1.74-2.79-1.22-6.47-3.38-10.71-5.75l.23-.05a2.9,2.9,0,0,0-.39,2,3.51,3.51,0,0,0,1,1.88,41.719,41.719,0,0,0,3.33,2.72c.55.43,1.1.85,1.61,1.3a4.17,4.17,0,0,1,.37.36,1.85,1.85,0,0,1,.32.43l.48.85a51.837,51.837,0,0,0,3.48,5.83,5.18,5.18,0,0,0,2.06,1.48c.69.33,1.31.57,1.82.77l1.17.43a1.759,1.759,0,0,1,.39.14Z"
                    fill="#707171"
                    opacity="0.3"
                />
                <path
                    id="Path_209"
                    data-name="Path 209"
                    d="M440.48,132.25c-.15-2.57-.47-15.07-.61-17a10.51,10.51,0,0,0-3.15-6.87,6.57,6.57,0,0,0-7.13-1.38h0a4.39,4.39,0,0,0-3.15,3.09c-5.26,18.71-4.43,38.62-6.76,58,6.87,1.12,16.11.66,23.06.3C442.74,152.94,441.43,148.93,440.48,132.25Z"
                    fill="#707171"
                />
                <path
                    id="Path_210"
                    data-name="Path 210"
                    d="M437.59,158.62a1.541,1.541,0,0,1-.06-.49,11.389,11.389,0,0,0-.07-1.44c0-1.31-.09-3.09-.16-5.28-.09-4.51-.22-10.64-.36-17.41-.05-3.4-.1-6.63-.15-9.59,0-1.47,0-2.87-.05-4.18a20.154,20.154,0,0,0-.25-3.6,5.85,5.85,0,0,0-2.68-4.23,4.42,4.42,0,0,0-1.81-.55h.13a1.569,1.569,0,0,1,.38,0,3.49,3.49,0,0,1,1.39.42,5.12,5.12,0,0,1,1.73,1.51,7,7,0,0,1,1.17,2.81,19,19,0,0,1,.31,3.65c0,1.32.06,2.72.1,4.19q.075,4.5.18,9.58c.09,6.78.17,12.91.23,17.42v6.71a3.209,3.209,0,0,1-.03.48Z"
                    opacity="0.5"
                />
                <path
                    id="Path_211"
                    data-name="Path 211"
                    d="M431.34,151.53a5.3,5.3,0,0,1-.08-1.12c0-.73,0-1.78,0-3.08,0-2.59,0-6.18.15-10.13s.2-7.45.28-10.14c0-1.22.07-2.25.1-3.07a5.643,5.643,0,0,1,.09-1.12,5.641,5.641,0,0,1,.09,1.12c0,.73,0,1.78,0,3.08,0,2.59-.05,6.18-.15,10.14s-.21,7.44-.28,10.13c0,1.22-.08,2.25-.11,3.07a5.634,5.634,0,0,1-.09,1.12Z"
                    opacity="0.5"
                />
                <path
                    id="Path_212"
                    data-name="Path 212"
                    d="M408.4,92.64c5.15-.53,9.5,1.73,13.62,4.88a25.53,25.53,0,0,1,8.61,12.65,30.53,30.53,0,0,1-.2,17.71A15.85,15.85,0,0,1,419.76,138c-7.17,2-15.26,1.2-20.89-3.67s-8.55-12.51-8.35-20A24.61,24.61,0,0,1,395.37,100a18.76,18.76,0,0,1,13-7.36"
                    fill="#707171"
                />
                <path
                    id="Path_213"
                    data-name="Path 213"
                    d="M421.76,110.92c-.62-2-.19-4.15-.35-6.24-.35-4.59-3.78-8.48-7.9-10.53-3.84-1.91-8.68-1.87-13.53-.64a18.1,18.1,0,0,0-8.86,5,14.34,14.34,0,0,0-3.47,12.66l23.51-1.86a26.11,26.11,0,0,0,6.75,5.1,4.87,4.87,0,0,0,2.41.55,2.16,2.16,0,0,0,1.9-1.38,4.6,4.6,0,0,0-.46-2.66Z"
                    fill="#707171"
                />
                <path
                    id="Path_214"
                    data-name="Path 214"
                    d="M399.06,99.9l20.05,5.64a3.82,3.82,0,0,1,3.3,3.95l-3.13,44a13.68,13.68,0,0,1-3.41,8.11l-2.1,2.37a10.05,10.05,0,0,1-16.67-2.35,4.73,4.73,0,0,1-.39-1.18c0-.4-.13-.67-.13-1a32.13,32.13,0,0,1,.09-6.69c.05-3.93.1-5.54.09-5.5s-8.25-1.6-9.68-12.46c-.71-5.4-.2-14.21.44-21.4C388.1,106.89,392.61,99.05,399.06,99.9Z"
                    fill="#f4f5f6"
                />
                <path
                    id="Path_215"
                    data-name="Path 215"
                    d="M390.08,121.83a1.38,1.38,0,0,0,1.3,1.43,1.33,1.33,0,0,0,1.46-1.21,1.4,1.4,0,0,0-1.3-1.44,1.33,1.33,0,0,0-1.46,1.22Z"
                    fill="#707171"
                />
                <path
                    id="Path_216"
                    data-name="Path 216"
                    d="M389.31,118c.17.18,1.24-.57,2.73-.52s2.57.81,2.74.64-.09-.41-.56-.77a3.78,3.78,0,0,0-2.18-.74,3.65,3.65,0,0,0-2.18.64C389.4,117.6,389.23,117.92,389.31,118Z"
                    fill="#707171"
                />
                <path
                    id="Path_217"
                    data-name="Path 217"
                    d="M403.44,122.37a1.38,1.38,0,0,0,1.3,1.43,1.33,1.33,0,0,0,1.46-1.21,1.39,1.39,0,0,0-1.3-1.44,1.32,1.32,0,0,0-1.46,1.22Z"
                    fill="#707171"
                />
                <path
                    id="Path_218"
                    data-name="Path 218"
                    d="M403.47,118.57c.17.18,1.24-.57,2.73-.52s2.57.82,2.73.64-.08-.41-.55-.77a3.78,3.78,0,0,0-2.18-.74,3.65,3.65,0,0,0-2.18.64C403.55,118.16,403.39,118.49,403.47,118.57Z"
                    fill="#707171"
                />
                <path
                    id="Path_219"
                    data-name="Path 219"
                    d="M398.26,129.6a9.67,9.67,0,0,0-2.41-.51c-.38,0-.74-.13-.79-.39a1.83,1.83,0,0,1,.28-1.12c.38-.92.78-1.87,1.21-2.88,1.67-4.09,2.89-7.45,2.73-7.52s-1.66,3.2-3.33,7.29c-.41,1-.79,2-1.16,2.89a2.17,2.17,0,0,0-.24,1.49,1,1,0,0,0,.61.57,2.941,2.941,0,0,0,.65.11,10.07,10.07,0,0,0,2.45.07Z"
                    fill="#707171"
                />
                <g id="Group_3" data-name="Group 3" opacity="0.3">
                    <path
                        id="Path_220"
                        data-name="Path 220"
                        d="M396.76,147.23a27.55,27.55,0,0,0,14.59-3.48s-3.84,7.39-14.64,6.09Z"
                        fill="#707171"
                    />
                </g>
                <path
                    id="Path_221"
                    data-name="Path 221"
                    d="M398.8,133.21a2.71,2.71,0,0,1,2.44-.94,2.48,2.48,0,0,1,1.67,1A1.55,1.55,0,0,1,403,135a1.8,1.8,0,0,1-2,.44,5.5,5.5,0,0,1-1.88-1.19,1.6,1.6,0,0,1-.41-.45.48.48,0,0,1,0-.55"
                    fill="#e3e2e3"
                />
                <path
                    id="Path_222"
                    data-name="Path 222"
                    d="M402.63,130.75c-.24,0-.29,1.59-1.71,2.71s-3.13.89-3.15,1.11.37.33,1.1.37a4,4,0,0,0,2.63-.84,3.52,3.52,0,0,0,1.33-2.28C402.91,131.14,402.74,130.74,402.63,130.75Z"
                    fill="#707171"
                />
                <path
                    id="Path_223"
                    data-name="Path 223"
                    d="M403.84,114.16c.14.41,1.64.25,3.38.51s3.15.77,3.39.41c.11-.17-.13-.55-.69-1a5.57,5.57,0,0,0-2.49-1,5.74,5.74,0,0,0-2.66.24C404.12,113.65,403.79,114,403.84,114.16Z"
                    fill="#707171"
                />
                <path
                    id="Path_224"
                    data-name="Path 224"
                    d="M389.92,111.49c.25.35,1.29,0,2.53,0s2.27.29,2.52-.06c.11-.17,0-.51-.49-.85a3.53,3.53,0,0,0-2-.61,3.57,3.57,0,0,0-2,.63C390,111,389.81,111.32,389.92,111.49Z"
                    fill="#707171"
                />
                <path
                    id="Path_225"
                    data-name="Path 225"
                    d="M425.69,108c-1.7-6.33-10.35-15.37-16.9-15.21L398.08,98.6a10.07,10.07,0,0,0,6.47,8c1.82.65,4,.9,5.06,2.54s.28,4,1.25,5.71c1.34,2.38,5.29,2.45,6.26,5,.43,1.11.12,2.41.56,3.52.59,1.47,4.17,3.47,5.71,3.06s1.65-3,2.21-4.32a7.15,7.15,0,0,0,.44-4.78C425.52,114.27,426.5,111.05,425.69,108Z"
                    fill="#707171"
                />
                <path
                    id="Path_226"
                    data-name="Path 226"
                    d="M418.86,127.52c0-.8,1-2.68,1.8-2.78,2.14-.27,6,0,5.85,5.39-.15,7.38-7.52,5.71-7.53,5.5S418.78,130.3,418.86,127.52Z"
                    fill="#f4f5f6"
                />
                <path
                    id="Path_227"
                    data-name="Path 227"
                    d="M421.29,132.88a2.677,2.677,0,0,0,.34.19,1.27,1.27,0,0,0,1,0,3.16,3.16,0,0,0,1.53-2.82,4.419,4.419,0,0,0-.32-1.85,1.51,1.51,0,0,0-.95-1,.64.64,0,0,0-.76.33c-.11.21-.06.36-.1.37s-.16-.13-.09-.43a.78.78,0,0,1,.3-.46.94.94,0,0,1,.71-.17,1.79,1.79,0,0,1,1.32,1.17,4.32,4.32,0,0,1,.4,2.06c-.07,1.49-.86,2.86-1.93,3.15a1.35,1.35,0,0,1-1.17-.22C421.3,133.06,421.27,132.89,421.29,132.88Z"
                    fill="#707171"
                />
                <g id="Group_4" data-name="Group 4" opacity="0.5">
                    <path
                        id="Path_228"
                        data-name="Path 228"
                        d="M431.73,118.25a6.8,6.8,0,0,0,.42-2.19,16.19,16.19,0,0,0-2.73-10.26,6.33,6.33,0,0,0-1.45-1.69,24.279,24.279,0,0,1,3.21,6.72,24.88,24.88,0,0,1,.55,7.42Z"
                    />
                </g>
                <path
                    id="Path_229"
                    data-name="Path 229"
                    d="M400.36,96.52c-.88,3-5.68,6.49-7.83,8.69-4.1,4.2-4.86,4.8-5.69,18.2a31.81,31.81,0,0,1-2.21-13.5,16.07,16.07,0,0,1,4.95-10.27,12.51,12.51,0,0,1,10.78-3.12"
                    fill="#707171"
                />
                <path
                    id="Path_230"
                    data-name="Path 230"
                    d="M429.45,108.18a7.461,7.461,0,0,1-1.27.23c-.41.06-.92.13-1.51.16s-1.26.11-2,.1a35.811,35.811,0,0,1-5.22-.29A39.73,39.73,0,0,1,413.2,107a39,39,0,0,1-5.92-2.45,34.339,34.339,0,0,1-4.44-2.74c-.62-.42-1.13-.86-1.59-1.23a12.886,12.886,0,0,1-1.14-1,7.469,7.469,0,0,1-.91-.91,8.109,8.109,0,0,1,1,.78c.32.27.71.59,1.18.93s1,.77,1.62,1.17a39.144,39.144,0,0,0,4.45,2.63,42,42,0,0,0,5.86,2.39,42.78,42.78,0,0,0,6.18,1.39,39.3,39.3,0,0,0,5.16.38h2a8.3,8.3,0,0,0,1.5-.08,7,7,0,0,1,1.3-.08Z"
                    opacity="0.5"
                />
                <path
                    id="Path_231"
                    data-name="Path 231"
                    d="M429.87,113.5a3.63,3.63,0,0,1-.87.15,21.3,21.3,0,0,1-2.39.15,29.46,29.46,0,0,1-15.13-4,18.375,18.375,0,0,1-2-1.31c-.46-.33-.7-.53-.68-.56s1.07.65,2.83,1.6a33.16,33.16,0,0,0,7.26,2.87,33.63,33.63,0,0,0,7.72,1.1C428.62,113.54,429.86,113.42,429.87,113.5Z"
                    opacity="0.5"
                />
                <path
                    id="Path_232"
                    data-name="Path 232"
                    d="M431.71,113.8a4.5,4.5,0,0,1,.18,1.13A28.174,28.174,0,0,1,432,118a45.759,45.759,0,0,1-.3,4.58,30.078,30.078,0,0,1-1.11,5.53,14.85,14.85,0,0,1-6.25,8,14.51,14.51,0,0,1-2.82,1.29,4.29,4.29,0,0,1-1.1.29,8.909,8.909,0,0,1,1-.46,16.921,16.921,0,0,0,2.72-1.4,13.58,13.58,0,0,0,3.33-3,14.869,14.869,0,0,0,2.65-4.81,31.429,31.429,0,0,0,1.13-5.44c.22-1.7.33-3.24.39-4.54a25.673,25.673,0,0,0,0-3.07A7.7,7.7,0,0,1,431.71,113.8Z"
                    opacity="0.5"
                />
                <path
                    id="Path_233"
                    data-name="Path 233"
                    d="M330.46,156.36,351.84,178c2.82-.65,37.33-21.28,37.33-21.28l5.45,26.54s-35.32,16.24-45,14.27c-1.58-.32-4.19-.52-5.83-2.63-7.77-10-22.55-31-22.55-31Z"
                    fill="#e3e2e3"
                />
                <path
                    id="Path_234"
                    data-name="Path 234"
                    d="M358.5,354.81l10.56,22.88,17.03-7.88L375.18,341.5Z"
                    fill="#e3e2e3"
                />
                <path
                    id="Path_235"
                    data-name="Path 235"
                    d="M367.06,376.16l6.17,11.76s-15.79,16.32-14.3,20L396,390.1l-10.2-23.59Z"
                    fill="#707171"
                />
                <path
                    id="Path_236"
                    data-name="Path 236"
                    d="M383.94,380.81a1.67,1.67,0,0,1,1.87,1.23,1.61,1.61,0,0,1-1.2,1.87,1.77,1.77,0,0,1-2-1.31,1.68,1.68,0,0,1,1.47-1.81"
                    fill="#e1e1e1"
                />
                <path
                    id="Path_237"
                    data-name="Path 237"
                    d="M396,390.1l-1.54-3-35,18.17s-1.31,1.51-.56,2.58Z"
                    fill="#e3e2e3"
                />
                <path
                    id="Path_238"
                    data-name="Path 238"
                    d="M372.54,388c.09.18,1-.17,2.21,0s2,.69,2.14.54-.64-1.07-2.06-1.23S372.42,387.87,372.54,388Z"
                    fill="#1a2e35"
                />
                <path
                    id="Path_239"
                    data-name="Path 239"
                    d="M369.42,391.5c0,.19,1,.12,2,.59s1.55,1.2,1.72,1.11-.2-1.18-1.43-1.74S369.34,391.32,369.42,391.5Z"
                    fill="#1a2e35"
                />
                <path
                    id="Path_240"
                    data-name="Path 240"
                    d="M369.44,397.21c.19,0,0-1.06-.94-1.83s-2-.76-2-.57.77.47,1.54,1.11S369.25,397.28,369.44,397.21Z"
                    fill="#1a2e35"
                />
                <path
                    id="Path_241"
                    data-name="Path 241"
                    d="M370.77,383.49c.15.13.87-.52,1.92-.94s2-.48,2-.69-1.06-.47-2.3,0S370.61,383.39,370.77,383.49Z"
                    fill="#1a2e35"
                />
                <path
                    id="Path_242"
                    data-name="Path 242"
                    d="M357.66,367.24l-26.29-71.49s.63-11.88,2.82-15.63,68.47-25.64,68.47-25.64,16.89-4.07,28.14,0,22.7,9.08,21.57,17a21.459,21.459,0,0,1-.86,3.55A28.7,28.7,0,0,1,425,294.46l-8.76,1L371.56,304a3.43,3.43,0,0,0-2.64,4.35l14.44,48.09S367.59,364.1,357.66,367.24Z"
                    fill="#707171"
                />
                <path
                    id="Path_243"
                    data-name="Path 243"
                    d="M334.45,317.8l-.63,25.19,18.76.46,2.7-30.23Z"
                    fill="#e3e2e3"
                />
                <path
                    id="Path_244"
                    data-name="Path 244"
                    d="M333.82,339.64,333.05,354s-21.37,7.67-21.65,11.6l41.15.42,1.27-25.67Z"
                    fill="#707171"
                />
                <path
                    id="Path_245"
                    data-name="Path 245"
                    d="M332.85,345.91a1.761,1.761,0,0,1,0-.74,4.65,4.65,0,0,1,.83-1.87,2.66,2.66,0,0,1,.49-.54,1,1,0,0,1,.93-.19.66.66,0,0,1,.41.37.71.71,0,0,1,0,.5,4.474,4.474,0,0,1-.43.75c-.32.49-.59,1-1,1.52a1.26,1.26,0,0,1-1.1.45,2.4,2.4,0,0,1-1.06-.43,8.378,8.378,0,0,1-1.57-1.29,6.835,6.835,0,0,1-.61-.72,2.249,2.249,0,0,1-.47-.83.76.76,0,0,1,.47-.9,1.17,1.17,0,0,1,1,.2,5.82,5.82,0,0,1,1.05,1.07,6.57,6.57,0,0,1,1,1.78c.17.45.21.71.18.71a14.232,14.232,0,0,0-1.48-2.31,6,6,0,0,0-1-.95.7.7,0,0,0-.59-.14.36.36,0,0,0-.19.46,1.85,1.85,0,0,0,.4.67,7.5,7.5,0,0,0,.6.68,9,9,0,0,0,1.48,1.22,2.069,2.069,0,0,0,.84.34.72.72,0,0,0,.66-.28c.35-.44.67-1,1-1.45a5.276,5.276,0,0,0,.41-.67.277.277,0,0,0-.12-.4c-.38-.14-.74.24-1,.56a6.891,6.891,0,0,0-1.13,2.43Z"
                    fill="#1a2e35"
                />
                <path
                    id="Path_246"
                    data-name="Path 246"
                    d="M345.79,352.36a1.69,1.69,0,0,1,1.15,1.93,1.62,1.62,0,0,1-1.91,1.15,1.77,1.77,0,0,1-1.2-2.05,1.68,1.68,0,0,1,2.12-1"
                    fill="#e1e1e1"
                />
                <g id="Group_5" data-name="Group 5" opacity="0.6">
                    <path
                        id="Path_247"
                        data-name="Path 247"
                        d="M352.55,366l-.08-3.34-39.43.85s-1.84.78-1.64,2.07Z"
                        fill="#e3e2e3"
                    />
                </g>
                <path
                    id="Path_248"
                    data-name="Path 248"
                    d="M332.38,353.8c0,.2,1,.3,2,1s1.5,1.51,1.69,1.43-.1-1.24-1.3-2S332.34,353.62,332.38,353.8Z"
                    fill="#1a2e35"
                />
                <path
                    id="Path_249"
                    data-name="Path 249"
                    d="M328.05,355.54c-.05.2.82.55,1.49,1.4s.86,1.76,1.06,1.76.34-1.15-.52-2.2S328.05,355.35,328.05,355.54Z"
                    fill="#1a2e35"
                />
                <path
                    id="Path_250"
                    data-name="Path 250"
                    d="M325.54,360.68c.19.05.48-.95,0-2.06s-1.44-1.55-1.53-1.39.49.77.89,1.68S325.34,360.66,325.54,360.68Z"
                    fill="#1a2e35"
                />
                <path
                    id="Path_251"
                    data-name="Path 251"
                    d="M332.8,349c.08.19,1-.08,2.14,0s2,.45,2.12.28-.74-.89-2.08-1S332.7,348.79,332.8,349Z"
                    fill="#1a2e35"
                />
                <path
                    id="Path_252"
                    data-name="Path 252"
                    d="M345.4,247.87s-11.23-.9-12.49,11.3-7.12,68.71-7.12,68.71l30.91,2.58,4.06-58.16,41.9-17.82S352.91,246.31,345.4,247.87Z"
                    fill="#707171"
                />
                <path
                    id="Path_253"
                    data-name="Path 253"
                    d="M391.73,231.42s-6.82,19.12-5.52,21.25,18.84,10.9,18.84,10.9l43.89,16.86a36.77,36.77,0,0,0,4.51-18.19c0-11-15.27-30.82-15.27-30.82Z"
                    fill="#707171"
                />
                <g id="Group_6" data-name="Group 6" opacity="0.3">
                    <path
                        id="Path_254"
                        data-name="Path 254"
                        d="M404.74,253.5a.72.72,0,0,1-.21.11l-.62.29-2.43,1.1-9.12,4-31.5,13.56.14-.2c-.4,5.74-.83,11.95-1.27,18.36-1.07,15-2.06,28.84-2.84,39.79v.2h-.2L337.55,329l-5.27-.49-1.38-.14a2.873,2.873,0,0,1-.46-.07,2.381,2.381,0,0,1,.47,0l1.38.09,5.28.38,19.15,1.51-.21.18,2.73-39.79c.45-6.42.89-12.62,1.29-18.37v-.14l.13-.06,31.57-13.4,9.18-3.84,2.46-1,.64-.26Z"
                    />
                </g>
                <path
                    id="Path_255"
                    data-name="Path 255"
                    d="M439.65,255.42a6.429,6.429,0,0,1-1.24-.34l-3.3-1.07a73.163,73.163,0,0,0-11.06-2.79,32.54,32.54,0,0,0-11.32.14,47.11,47.11,0,0,0-4.54,1.32,5.48,5.48,0,0,1,1.14-.58,20.339,20.339,0,0,1,3.32-1.07,30.41,30.41,0,0,1,11.47-.3,61.8,61.8,0,0,1,11.1,3c1.38.49,2.49.91,3.26,1.21a5.66,5.66,0,0,1,1.17.48Z"
                    fill="#375a64"
                />
                <path
                    id="Path_256"
                    data-name="Path 256"
                    d="M433.44,245.33a34.511,34.511,0,0,0-3.84,0c-1.18.09-2.58.27-4.15.45s-3.28.39-5.06.73a23.72,23.72,0,0,0-8.6,3.19,27.8,27.8,0,0,0-3,2.4,3.339,3.339,0,0,1,.64-.82,13.069,13.069,0,0,1,2.15-1.87,22,22,0,0,1,8.7-3.38c1.8-.34,3.53-.54,5.1-.7s3-.3,4.19-.34a15.369,15.369,0,0,1,2.84.12,3.59,3.59,0,0,1,1.03.22Z"
                    fill="#375a64"
                />
                <path
                    id="Path_257"
                    data-name="Path 257"
                    d="M369.68,381.14a1.83,1.83,0,0,1-.34-.75,5.44,5.44,0,0,1-.18-2.24,3.377,3.377,0,0,1,.18-.75.93.93,0,0,1,.74-.6.65.65,0,0,1,.55.19.91.91,0,0,1,.23.5,5.92,5.92,0,0,1,0,.95,8.995,8.995,0,0,1-.15,1.95,1.19,1.19,0,0,1-.77.94,2.41,2.41,0,0,1-1.16,0,8.93,8.93,0,0,1-2-.6c-.31-.14-.62-.29-.91-.45a2.729,2.729,0,0,1-.82-.62.87.87,0,0,1,0-1.11,1.07,1.07,0,0,1,1-.24,6.17,6.17,0,0,1,1.46.61,7.769,7.769,0,0,1,1.79,1.33c.37.37.54.61.51.63a16,16,0,0,0-2.46-1.66,6,6,0,0,0-1.37-.5c-.24,0-.5,0-.6.12a.43.43,0,0,0,.06.54,2.22,2.22,0,0,0,.69.49q.42.22.87.42a9.17,9.17,0,0,0,1.93.56,2,2,0,0,0,.92,0,.7.7,0,0,0,.46-.58c.09-.59.11-1.29.17-1.89a5.123,5.123,0,0,0,0-.85c0-.25-.14-.36-.31-.35-.41,0-.55.57-.61,1a7.891,7.891,0,0,0,.12,2.96Z"
                    fill="#1a2e35"
                />
                <path
                    id="Path_258"
                    data-name="Path 258"
                    d="M390.65,156.07a14.21,14.21,0,0,0-7.29,6.33c-2.74,4.85-2,7.24-1.29,10.11s2,8.55,2,8.18S390.65,156.07,390.65,156.07Z"
                    fill="#ffbf9d"
                />
                <path
                    id="Path_259"
                    data-name="Path 259"
                    d="M436.91,164.71s-3.58-8.64-4.3-9.45l-13.22-3.34L411.13,156a14.56,14.56,0,0,1-7.71,1.53c-3-.28-5.56-1.47-6.87-3.65h0l-5.16,1.39s-8.2,24.76-8,31.61,3,26.32,2.68,30.15,1,14.42,5.69,14.42h46.45s10.49-2.91,10.95-9.21S436.91,164.71,436.91,164.71Z"
                    fill="#707171"
                />
                <g id="Group_7" data-name="Group 7" opacity="0.5">
                    <path
                        id="Path_260"
                        data-name="Path 260"
                        d="M422.11,202.27a3.361,3.361,0,0,1-.78.66,14.451,14.451,0,0,1-2.41,1.4,14.27,14.27,0,0,1-4,1.19,13.52,13.52,0,0,1-5.07-.22,16.76,16.76,0,0,1-7.88-4.57,19.439,19.439,0,0,1-1.82-2.11,3.5,3.5,0,0,1-.54-.86c.07-.06.91,1.13,2.6,2.72a17.3,17.3,0,0,0,7.75,4.34,14.22,14.22,0,0,0,8.79-.81C420.91,203.11,422.05,202.19,422.11,202.27Z"
                        fill="#1a2e35"
                    />
                </g>
                <g id="Group_8" data-name="Group 8" opacity="0.3">
                    <path
                        id="Path_261"
                        data-name="Path 261"
                        d="M400.43,198.83A13.53,13.53,0,0,0,405.2,208a11.24,11.24,0,0,0,9.94,2.16,9.62,9.62,0,0,0,6.79-7.37,29.059,29.059,0,0,1-7.42,2.58,14.56,14.56,0,0,1-14.31-6.76l.38.4"
                    />
                </g>
                <path
                    id="Path_262"
                    data-name="Path 262"
                    d="M439.52,206.36c-4.91-9.52-10.1-19.37-18.68-25.78h0c1.9,6.51,3.6,12.63,5.71,19.08.6,1.82,1.53,3.94,3.43,4.25.7.11,1.42-.06,2.12.07a4.54,4.54,0,0,1,2.64,2.34A6.65,6.65,0,0,0,437.1,209c1.09.53,2.75,0,2.85-1.19a2.78,2.78,0,0,0-.43-1.45Z"
                    opacity="0.3"
                />
                <path
                    id="Path_263"
                    data-name="Path 263"
                    d="M453.45,188.22,430,199.2s-10.36-20.76-11.18-23.65-2.58-7.8.06-13.82A11.78,11.78,0,0,1,433,155.38C443.29,159,453.45,188.22,453.45,188.22Z"
                    fill="#707171"
                />
                <path
                    id="Path_264"
                    data-name="Path 264"
                    d="M385.58,188.15c-1.09,0-13.64,6.56-13.64,6.56l-8.34-24.07c10.78-5.85,22.77-14.1,29-15.78l-5.42,18.83Z"
                    fill="#707171"
                />
                <path
                    id="Path_265"
                    data-name="Path 265"
                    d="M432.33,197.71c4.44,7.28,7.53,11.47,7.53,11.47l-39.75,24.21,4.63,10.76,48.32-19.89a11,11,0,0,0,6.64-7.56,9.15,9.15,0,0,0-.11-4.83l-8.22-22.69Z"
                    fill="#e3e2e3"
                />
                <g id="Group_9" data-name="Group 9" opacity="0.3">
                    <path
                        id="Path_266"
                        data-name="Path 266"
                        d="M449.53,212.88a21.75,21.75,0,0,0-10-3.7,10.68,10.68,0,0,1,5.39.77,10.8,10.8,0,0,1,4.61,2.93Z"
                        fill="#707171"
                    />
                </g>
                <path
                    id="Path_267"
                    data-name="Path 267"
                    d="M387,166.74a5.288,5.288,0,0,0-.67,1.11,21.311,21.311,0,0,0-1.3,3.21,48.3,48.3,0,0,0-2,11,63.245,63.245,0,0,0,.13,11.13,32.309,32.309,0,0,0,.52,3.35,5.831,5.831,0,0,0,.33,1.21,5.419,5.419,0,0,0-.12-1.24c-.1-.78-.24-1.93-.34-3.34a77.107,77.107,0,0,1,0-11.08,56.139,56.139,0,0,1,1.79-11,27.289,27.289,0,0,1,1.12-3.23,6.577,6.577,0,0,0,.54-1.12Z"
                    fill="#1a2e35"
                    opacity="0.5"
                />
                <path
                    id="Path_268"
                    data-name="Path 268"
                    d="M369.26,240.76l9.92-4.74,19.35-1.53,1.58-1.1,4.63,10.76s-5.07,2.43-7.73,2.64l-16.27-.49-8.5.89Z"
                    fill="#e3e2e3"
                />
                <path
                    id="Path_269"
                    data-name="Path 269"
                    d="M453.45,188.22a2.74,2.74,0,0,0-.47.17l-1.29.55L447,191l-15.54,7.08-1.89.87.29.11c-2.7-5.57-5.07-10.55-6.74-14.14l-2-4.26-.53-1.15a1.461,1.461,0,0,0-.21-.39,1.9,1.9,0,0,0,.13.43c.1.27.25.67.46,1.18.4,1,1,2.5,1.83,4.31,1.6,3.63,3.92,8.64,6.62,14.22l.09.19.2-.09,1.9-.87,15.48-7.2,4.67-2.23,1.26-.63a1.918,1.918,0,0,0,.43-.21Z"
                    fill="#1a2e35"
                    opacity="0.5"
                />
                <path
                    id="Path_270"
                    data-name="Path 270"
                    d="M431.91,155.38c0,.08-1.1.05-2.79.46a13.2,13.2,0,0,0-9.56,8.74c-.55,1.66-.62,2.75-.7,2.74a2.169,2.169,0,0,1,0-.77,10.493,10.493,0,0,1,.4-2.06,12.7,12.7,0,0,1,9.79-9,11.109,11.109,0,0,1,2.09-.22,2.37,2.37,0,0,1,.77.11Z"
                    fill="#1a2e35"
                    opacity="0.5"
                />
                <path
                    id="Path_271"
                    data-name="Path 271"
                    d="M445.36,170.46a5,5,0,0,1-.31,1,10.249,10.249,0,0,1-1.33,2.64c-.2.25-.4.51-.62.77l-.33.41a4.738,4.738,0,0,1-.4.37l-.85.77c-.32.23-.66.45-1,.69a18.64,18.64,0,0,1-4.94,2.12c-3.67,1-7.07,1.52-9.42,2.25a38,38,0,0,0-3.74,1.41,4.643,4.643,0,0,1,.92-.58,7.182,7.182,0,0,1,1.14-.55l.74-.3.84-.28c2.37-.83,5.78-1.37,9.4-2.38a19.58,19.58,0,0,0,4.84-2l1-.65.84-.73c.13-.12.27-.23.39-.35l.33-.38.62-.73a12.377,12.377,0,0,0,1.43-2.52,5.1,5.1,0,0,1,.45-.98Z"
                    fill="#fff"
                />
                <path
                    id="Path_272"
                    data-name="Path 272"
                    d="M442.28,164.94a5.339,5.339,0,0,0-1,.23,4.46,4.46,0,0,0-1.95,1.84c-.32.49-.63,1.06-1,1.64a8.6,8.6,0,0,1-1.58,1.62,13.88,13.88,0,0,1-4.6,2.23c-1.74.53-3.44.84-4.94,1.21a22.059,22.059,0,0,0-3.9,1.27,14,14,0,0,0-3.16,2.05s0-.08.15-.22a4.2,4.2,0,0,1,.53-.55,10.159,10.159,0,0,1,2.34-1.57,20,20,0,0,1,3.94-1.38c1.51-.4,3.2-.72,4.91-1.24a14,14,0,0,0,4.48-2.13,8.473,8.473,0,0,0,1.53-1.52c.41-.54.74-1.1,1.08-1.58a4.42,4.42,0,0,1,2.14-1.83,2.11,2.11,0,0,1,.77-.12.6.6,0,0,1,.26.05Z"
                    fill="#fff"
                />
                <path
                    id="Path_273"
                    data-name="Path 273"
                    d="M423.41,185.75a2.42,2.42,0,0,1-.37.21c-.3.13-.66.3-1.09.51-1,.43-2.35,1-4.1,1.72a43.157,43.157,0,0,1-6.3,1.95,23.52,23.52,0,0,1-8.08.48,35.37,35.37,0,0,1-7.78-2.22,29.4,29.4,0,0,0-6.29-1.74,12.609,12.609,0,0,0-4.37.1c-1,.21-1.55.45-1.57.41a1.45,1.45,0,0,1,.38-.2,7.439,7.439,0,0,1,1.15-.39,12.45,12.45,0,0,1,4.45-.24,28.73,28.73,0,0,1,6.39,1.67,36.17,36.17,0,0,0,7.69,2.16,24.06,24.06,0,0,0,7.94-.43,46.181,46.181,0,0,0,6.28-1.85c1.76-.63,3.16-1.2,4.14-1.58l1.12-.44C423.26,185.78,423.4,185.73,423.41,185.75Z"
                    fill="#fff"
                />
                <path
                    id="Path_274"
                    data-name="Path 274"
                    d="M385,177.79a2.209,2.209,0,0,1,.31-.24,5.281,5.281,0,0,1,1-.51,8.16,8.16,0,0,1,4-.41,15.54,15.54,0,0,1,5.67,2.05,51.35,51.35,0,0,0,6.46,3.4,18.92,18.92,0,0,0,7.13,1.08,37.241,37.241,0,0,0,5.91-.71c1.67-.33,3-.65,3.95-.86a10.211,10.211,0,0,1,1.45-.29,8.078,8.078,0,0,1-1.41.46c-.92.27-2.25.63-3.92,1a34.64,34.64,0,0,1-6,.81,18.7,18.7,0,0,1-7.3-1.07,49.107,49.107,0,0,1-6.5-3.46,15.58,15.58,0,0,0-5.51-2.09,8.21,8.21,0,0,0-3.94.26C385.46,177.52,385,177.83,385,177.79Z"
                    fill="#fff"
                />
                <path
                    id="Path_275"
                    data-name="Path 275"
                    d="M380.55,190.11h-.27a3.471,3.471,0,0,1-.74-.22,6,6,0,0,1-2.27-1.68,9.87,9.87,0,0,1-1.94-3.73,22.361,22.361,0,0,1-.62-5.08,34.871,34.871,0,0,0-.4-5,11.4,11.4,0,0,0-1.41-3.81,7.76,7.76,0,0,0-2.69-2.59.61.61,0,0,1,.26.08,4.23,4.23,0,0,1,.69.34,6.8,6.8,0,0,1,2,2,10.92,10.92,0,0,1,1.56,3.89,32.4,32.4,0,0,1,.44,5.1,23.1,23.1,0,0,0,.57,5,9.78,9.78,0,0,0,1.78,3.63,6.49,6.49,0,0,0,2.1,1.73C380.2,190,380.56,190.07,380.55,190.11Z"
                    fill="#fff"
                />
                <path
                    id="Path_276"
                    data-name="Path 276"
                    d="M382.92,184a26.167,26.167,0,0,1-1.25-2.82l-2.9-6.83c-1.15-2.66-2.22-5-3-6.76s-1.39-2.73-1.34-2.77a3.09,3.09,0,0,1,.5.67c.29.45.68,1.12,1.13,2,.9,1.68,2,4.06,3.18,6.72s2.12,5.11,2.78,6.89c.33.9.58,1.62.74,2.13A3.1,3.1,0,0,1,382.92,184Z"
                    fill="#fff"
                />
                <path
                    id="Path_277"
                    data-name="Path 277"
                    d="M313.48,202.57l55.79.45c1.81,0,3.53.87,3.81,2.66l6.09,40.66H407.9a1.68,1.68,0,1,1,0,3.35H316.14l-6.27-42.91a3.65,3.65,0,0,1,3.61-4.21Z"
                    fill="#e1e1e1"
                />
                <path
                    id="Path_278"
                    data-name="Path 278"
                    d="M313.48,202.57a2.87,2.87,0,0,0-.87.08,3.67,3.67,0,0,0-2.82,3.2,9.82,9.82,0,0,0,.2,2.29c.23,1.64.5,3.54.8,5.66,1.23,8.53,3,20.81,5.18,35.91v.15h91.54a2.24,2.24,0,0,0,1.27-.24,2,2,0,0,0,.86-1,1.92,1.92,0,0,0-.87-2.32,2.39,2.39,0,0,0-1.26-.24H379.17l.22.19c-2.2-14.56-4.24-28.15-6.08-40.33a3.16,3.16,0,0,0-1-2.13,4.26,4.26,0,0,0-2.14-.94,21.428,21.428,0,0,0-2.25-.08H365.7l-8.39-.06-14.93-.09-21.25-.11h-5.68l5.68.09,21.25.23,14.93.15,8.39.08h2.19a20,20,0,0,1,2.2.08,3.73,3.73,0,0,1,1.94.86,2.77,2.77,0,0,1,.91,1.9c1.82,12.19,3.84,25.78,6,40.34v.19h28.58a1.92,1.92,0,0,1,1,.18,1.46,1.46,0,0,1,0,2.54,1.79,1.79,0,0,1-1.05.18h-2.39l-89,.06.17.14c-2.24-15.09-4.06-27.37-5.32-35.89-.32-2.12-.61-4-.85-5.65a10.431,10.431,0,0,1-.23-2.26,3.6,3.6,0,0,1,.65-1.74,3.67,3.67,0,0,1,2.06-1.42A8,8,0,0,1,313.48,202.57Z"
                    fill="#1a2e35"
                />
                <circle
                    id="Ellipse_13"
                    data-name="Ellipse 13"
                    cx="3.49"
                    cy="3.49"
                    r="3.49"
                    transform="translate(337.78 222.12)"
                    fill="#fff"
                />
                <path
                    id="Path_279"
                    data-name="Path 279"
                    d="M376.91,249.31c-.08,0-1.85-10.34-4-23.12S369.19,203,369.27,203s1.85,10.34,4,23.13S377,249.3,376.91,249.31Z"
                    fill="#1a2e35"
                />
                <path
                    id="Path_280"
                    data-name="Path 280"
                    d="M441.57,313H387.36a10.44,10.44,0,0,1-10.45-10.44h0a10.44,10.44,0,0,1,10.45-10.44h54.21A10.43,10.43,0,0,1,452,302.53h0A10.43,10.43,0,0,1,441.57,313Z"
                    fill="#fff"
                    opacity="0.2"
                />
                <circle
                    id="Ellipse_14"
                    data-name="Ellipse 14"
                    cx="11.44"
                    cy="11.44"
                    r="11.44"
                    transform="translate(309.76 394.79)"
                    fill="#fff"
                    stroke="#e2e2e2"
                    strokeMiterlimit="10"
                    strokeWidth="2"
                />
                <path
                    id="Path_281"
                    data-name="Path 281"
                    d="M124.48,369.83h-6.17v-6.16a.77.77,0,0,0-.76-.77h0a.79.79,0,0,0-.79.77v6.16h-6.15a.78.78,0,0,0,0,1.55h6.15v6.17a.79.79,0,0,0,.79.76.76.76,0,0,0,.76-.76h0v-6.17h6.17a.78.78,0,1,0,0-1.55Z"
                    fill="#e2e2e2"
                    stroke="#e2e2e2"
                    strokeMiterlimit="10"
                    strokeWidth="0.75"
                />
                <path
                    id="Path_282"
                    data-name="Path 282"
                    d="M104.48,338.44H95V329a1.17,1.17,0,0,0-1.17-1.18h0A1.21,1.21,0,0,0,92.64,329v9.44H83.21a1.21,1.21,0,0,0-1.19,1.2,1.19,1.19,0,0,0,1.19,1.18h9.43v9.46a1.21,1.21,0,0,0,1.21,1.17A1.17,1.17,0,0,0,95,350.28h0v-9.46h9.46a1.19,1.19,0,0,0,1.19-1.18,1.21,1.21,0,0,0-1.17-1.2Z"
                    fill="#e2e2e2"
                    stroke="#e2e2e2"
                    strokeMiterlimit="10"
                    strokeWidth="0.75"
                />
                <ellipse
                    id="_Path_"
                    data-name="&lt;Path&gt;"
                    cx="164.73"
                    cy="16.17"
                    rx="164.73"
                    ry="16.17"
                    transform="translate(93.95 472.74)"
                    fill="#f4f5f6"
                />
            </g>
        </SVGIcon>
    );
}
