import React from 'react';

import { SVGIcon } from '../../UIKit/index';

export default function DCFile(props) {
    return (
        <SVGIcon {...props} viewBox="0 0 512 512">
            <g>
                <rect x="215.92" y="217.65" width="155.68" height="40.16" />
                <rect x="215.92" y="294.62" width="155.68" height="40.16" />
                <rect x="140.24" y="294.62" width="37.62" height="40.16" />
                <rect x="215.32" y="371.59" width="155.68" height="40.16" />
                <rect x="139.64" y="371.59" width="37.62" height="40.16" />
                <rect x="140.24" y="217.65" width="37.62" height="40.16" />
                <path d="M256,102.84v42.81c0,0,1.86,11.46,5.11,14.7H154.51c0,0-11.89-1.08-15.57-15.78v-26.59   c0,0,2.59-14.49,17.51-15.14H256z" />
                <path d="M410.41,178.95v251.46c0,0,0.97,37.62-38.81,40.65H132.03c0,0-30.27-6.49-32-40.22V77.97   c0,0,7.57-30.38,33.19-33.3H280.3c0,0,12.81,0,13.3,20.11v75.68c0,0,0.68,17.81,16.95,19.89l81.38,0.86h56.22   c0,0,3.89-12.11-8.11-26.38l-20.43-24.65l-47.57-47.14l-36.11-36.32l-16-12.76c0,0-12.54-9.08-23.57-8.43H129.43   c0,0-62.05,7.35-67.89,72.22l-0.65,357.73c0,0,5.41,67.57,72,72.97h248.22c0,0,57.42-6.27,67.63-64.65V157.57l-55.81,3.61   l3.23,0.79C410.43,166.83,410.16,175.44,410.41,178.95L410.41,178.95z" />
            </g>
        </SVGIcon>
    );
}
