import React from 'react';

import { SVGIcon } from '../../UIKit/index';

export default function DCBilling(props) {
    return (
        <SVGIcon {...props} viewBox="0 0 2000 2000" fill="none">
            <g filter="url(#DCBillingFilter0_i)">
                <rect width="2000" height="2000" rx="400" fill="url(#DCBillingPaint0_linear)" />
                <mask
                    id="DCBillingMask0"
                    maskUnits="userSpaceOnUse"
                    x="0"
                    y="0"
                    width="2000"
                    height="2000"
                >
                    <rect width="2000" height="2000" rx="400" fill="url(#DCBillingPaint1_linear)" />
                </mask>
                <g mask="url(#DCBillingMask0)">
                    <rect
                        x="458.512"
                        y="426"
                        width="1300"
                        height="823"
                        rx="80"
                        transform="rotate(12.2405 458.512 426)"
                        fill="url(#DCBillingPaint2_linear)"
                    />
                    <rect
                        x="370.289"
                        y="579.773"
                        width="1300"
                        height="823"
                        rx="80"
                        transform="rotate(6.85907 370.289 579.773)"
                        fill="url(#DCBillingPaint3_linear)"
                    />
                    <rect
                        x="276.492"
                        y="751.457"
                        width="1300"
                        height="823"
                        rx="80"
                        fill="url(#DCBillingPaint4_linear)"
                    />
                    <circle cx="1179.49" cy="968.457" r="97" fill="white" fillOpacity="0.3" />
                    <circle cx="1276.49" cy="968.457" r="97" fill="white" fillOpacity="0.3" />
                    <rect x="411.492" y="909.457" width="228" height="34" rx="17" fill="white" />
                    <rect x="411.492" y="983.457" width="228" height="34" rx="17" fill="white" />
                    <rect x="411.492" y="1057.46" width="228" height="34" rx="17" fill="white" />
                    <rect x="411.492" y="1386.46" width="228" height="52" rx="20" fill="white" />
                    <rect x="687.492" y="1386.46" width="228" height="52" rx="20" fill="white" />
                    <rect x="966.492" y="1386.46" width="228" height="52" rx="20" fill="white" />
                    <rect x="1245.49" y="1386.46" width="179" height="52" rx="20" fill="white" />
                    <rect y="1264" width="2134" height="910" fill="#C9DCE7" fillOpacity="0.5" />
                </g>
            </g>
            <defs>
                <filter
                    id="DCBillingFilter0_i"
                    x="0"
                    y="-96"
                    width="2000"
                    height="2096"
                    filterUnits="userSpaceOnUse"
                    colorInterpolationFilters="sRGB"
                >
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feBlend
                        mode="normal"
                        in="SourceGraphic"
                        in2="BackgroundImageFix"
                        result="shape"
                    />
                    <feColorMatrix
                        in="SourceAlpha"
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                        result="hardAlpha"
                    />
                    <feOffset dy="-96" />
                    <feGaussianBlur stdDeviation="72" />
                    <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
                    <feColorMatrix
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.05 0"
                    />
                    <feBlend mode="normal" in2="shape" result="effect1_innerShadow" />
                </filter>
                <linearGradient
                    id="DCBillingPaint0_linear"
                    x1="1000"
                    y1="0"
                    x2="1000"
                    y2="2000"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="white" />
                    <stop offset="1" stopColor="#F1F3F3" />
                </linearGradient>
                <linearGradient
                    id="DCBillingPaint1_linear"
                    x1="849"
                    y1="0"
                    x2="849"
                    y2="2000"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#ED5E83" />
                    <stop offset="1" stopColor="#D82459" />
                </linearGradient>
                <linearGradient
                    id="DCBillingPaint2_linear"
                    x1="502.081"
                    y1="761.343"
                    x2="1758.29"
                    y2="696.022"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#00CDB5" />
                    <stop offset="1" stopColor="#00B6A0" />
                </linearGradient>
                <linearGradient
                    id="DCBillingPaint3_linear"
                    x1="374.145"
                    y1="852.952"
                    x2="1602.53"
                    y2="920.734"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#FFD0D3" />
                    <stop offset="1" stopColor="#F59198" />
                </linearGradient>
                <linearGradient
                    id="DCBillingPaint4_linear"
                    x1="276.492"
                    y1="1090.46"
                    x2="1642.49"
                    y2="1163.46"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#00A8FF" />
                    <stop offset="1" stopColor="#0083FF" />
                </linearGradient>
            </defs>
        </SVGIcon>
    );
}
