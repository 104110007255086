export const INTG_TYPES = {
    FACEBOOK_ADS: 'facebook_ads',
    GOOGLE_ADS: 'google_ads',
    BING_ADS: 'bing_ads',
    LISTING_MANAGER: 'listings',
    CALLRAIL: 'callrail',
    SEO: 'seo',
    TIKTOK: 'tiktok',
    GOOGLE_ANALYTICS: 'google_analytics',
    CALLTRACKING_METRICS: 'calltracking_metrics',
};

export const FACEBOOK_SUM_COLUMNS = [
    'video_avg_time_watched_actions',
    'video_play_actions',
    'cost_per_thruplay',
    'video_thruplay_watched_actions',
    'video_p25_watched_actions',
    'video_p50_watched_actions',
    'video_p75_watched_actions',
    'video_p95_watched_actions',
    'video_p100_watched_actions',
];

export const facebookFields = [
    'actions',
    'unique_inline_link_clicks',
    'cpp',
    'inline_link_click_ctr',
    'video_thruplay_watched_actions',
    'video_p25_watched_actions',
    'video_p50_watched_actions',
    'video_p75_watched_actions',
    'video_p95_watched_actions',
    'video_p100_watched_actions',
    'video_avg_time_watched_actions',
    'video_play_actions',
    'created_time',
    'updated_time',
    'unique_ctr',
    'unique_clicks',
    'converted_product_value',
    'instant_experience_outbound_clicks',
    'cost_per_unique_action_type',
    'cost_per_unique_click',
    'cost_per_thruplay',
    'objective',
    'cpm',
    'cpc',
    'ctr',
    'reach',
    'frequency',
    'impressions',
    'clicks',
    'attribution_setting',
    'spend',
    'cost_per_action_type',
    'purchase_roas',
    'website_purchase_roas',
    'mobile_app_purchase_roas',
    'conversions',
    'conversion_values',
];

export const MONTHS = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
];

export const CAMPAIGN = 'campaign';
export const ADSETS = 'adsets';
export const ADS = 'ads';
export const KEYWORDS = 'keywords';

export const GOOGLE_ADS_STATUS = {
    ALL: 'ALL',
    ENABLED: 'ENABLED',
    PAUSED: 'PAUSED',
    REMOVED: 'REMOVED',
};

export const FACEBOOK_ADS_STATUS = {
    ALL: 'all',
    ACTIVE: 'ACTIVE',
    PAUSED: 'PAUSED',
    REMOVED: 'REMOVED',
};

export const FACEBOOK_STATUS_VALUES = {
    campaigns: {
        ACTIVE: ['ACTIVE'],
        PAUSED: ['PAUSED'],
        REMOVED: ['DISAPPROVED', 'WITH_ISSUES'],
    },
    adsets: {
        ACTIVE: ['ACTIVE'],
        PAUSED: ['PAUSED', 'CAMPAIGN_PAUSED'],
        REMOVED: ['DISAPPROVED', 'WITH_ISSUES'],
    },
    ads: {
        ACTIVE: ['ACTIVE'],
        PAUSED: ['PAUSED', 'CAMPAIGN_PAUSED', 'ADSET_PAUSED'],
        REMOVED: ['DISAPPROVED', 'WITH_ISSUES'],
    },
};

export const TIKTOK_STATUS_VALUES = {
    campaigns: {
        ACTIVE: ['CAMPAIGN_STATUS_ENABLE'],
        PAUSED: ['CAMPAIGN_STATUS_DISABLE'],
        REMOVED: ['CAMPAIGN_STATUS_DELETE'],
    },
    adsets: {
        ACTIVE: ['ADGROUP_STATUS_DELIVERY_OK'],
        PAUSED: ['ADGROUP_STATUS_FROZEN'],
        REMOVED: ['ADGROUP_STATUS_DELETE'],
    },
    ads: {
        ACTIVE: ['AD_STATUS_DELIVERY_OK'],
        PAUSED: ['AD_STATUS_FROZEN'],
        REMOVED: ['AD_STATUS_DELETE'],
    },
};

export const GOOGLE_ADS_BADGE_COLORS = {
    ENABLED: {
        borderColor: '#3a703c',
        color: '#3a703c',
    },
    PAUSED: {
        borderColor: '#F4AD3B',
        color: '#F4AD3B',
    },
    REMOVED: {
        borderColor: '#ea4335',
        color: '#ea4335',
    },
};

export const FACEBOOK_ADS_BADGE_COLORS = {
    ACTIVE: {
        borderColor: '#3a703c',
        color: '#3a703c',
    },
    PAUSED: {
        borderColor: '#F4AD3B',
        color: '#F4AD3B',
    },
    REMOVED: {
        borderColor: '#ea4335',
        color: '#ea4335',
    },
};

export const listingTooltips = {
    'All Listings': 'An aggregated total of all listings in all statuses.',
    'Live Listings':
        'We have connected to the listing and is successfully syncing your information.',
    Processing:
        "We're in the initial process of syncing the information from our database to the publisher site.",
    Unavailable:
        "We're unable to sync or submit information to the publisher site. This could be due to many different reasons, but usually falls on the directory listing themselves.",
    'Opted Out': 'There is currently no connection between our database and the publisher.',
};

export const GOOGLE_ADS_COMPARISON = [
    {
        Header: 'Dates',
        type: 'text',
        subType: null,
        custom: false,
        options: [],
        accessor: 'dates',
        order: 1,
        width: 50,
        active: true,
        default: false,
        visibleInCard: false,
        allow_update: true,
        orderInCard: 9999999,
        sortable: true,
        draggable: true,
    },
    {
        Header: 'Impressions',
        custom: false,
        accessor: 'impressions',
        order: 2,
        width: 47,
        active: true,
        default: false,
        visibleInCard: false,
        allow_update: true,
        orderInCard: 9999999,
        sortable: true,
        draggable: true,
    },
    {
        Header: 'CMP',
        type: 'text',
        subType: null,
        custom: false,
        options: [],
        accessor: 'cpm',
        order: 3,
        width: 297,
        active: true,
        default: true,
        visibleInCard: false,
        allow_update: true,
        orderInCard: 9999999,
        sortable: true,
        draggable: true,
    },
    {
        Header: 'Clicks',
        custom: false,
        accessor: 'clicks',
        order: 4,
        width: 250,
        active: true,
        default: false,
        visibleInCard: false,
        allow_update: true,
        orderInCard: 9999999,
        sortable: true,
        draggable: true,
    },
    {
        Header: 'Conversions',
        type: 'text',
        subType: null,
        custom: false,
        options: [],
        accessor: 'conversions',
        order: 5,
        width: 250,
        active: true,
        default: false,
        visibleInCard: false,
        allow_update: true,
        orderInCard: 9999999,
        sortable: true,
        draggable: true,
    },
    {
        Header: 'CPA',
        type: 'text',
        subType: null,
        custom: false,
        options: [],
        accessor: 'cpa',
        active: true,
        visibleInCard: false,
        allow_update: true,
        orderInCard: 9999999,
        sortable: true,
        draggable: true,
    },
];
