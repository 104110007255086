import React from 'react';

import { SVGIcon } from 'UIKit/index';

export default function DCGrayNotification(props) {
    return (
        <SVGIcon {...props} viewBox="0 0 455.62 498.31">
            <defs>
                <linearGradient
                    id="linear-gradient"
                    x1="0.5"
                    y1="0.714"
                    x2="0.5"
                    y2="0.084"
                    gradientUnits="objectBoundingBox"
                >
                    <stop offset="0" />
                    <stop offset="0.06" />
                    <stop offset="0.91" />
                </linearGradient>
                <linearGradient
                    id="linear-gradient-2"
                    x1="-0.319"
                    y1="-0.14"
                    x2="0.467"
                    y2="0.772"
                    gradientUnits="objectBoundingBox"
                >
                    <stop offset="0" />
                    <stop offset="0.91" />
                </linearGradient>
                <linearGradient
                    id="linear-gradient-3"
                    x1="-0.001"
                    y1="0.5"
                    x2="1"
                    y2="0.5"
                    gradientUnits="objectBoundingBox"
                >
                    <stop offset="0.01" stopColor="#707171" />
                    <stop offset="1" stopColor="#707171" />
                </linearGradient>
                <linearGradient id="linear-gradient-4" x1="0" y1="0.5" x2="1" y2="0.5" />
                <linearGradient id="linear-gradient-5" x1="0" y1="0.498" x2="1" y2="0.498" />
                <linearGradient id="linear-gradient-7" x1="0" y1="0.499" x2="1" y2="0.499" />
                <linearGradient id="linear-gradient-9" x1="0" y1="0.5" x2="1" y2="0.5" />
                <linearGradient id="linear-gradient-10" x1="-0.001" y1="0.5" x2="0.999" y2="0.5" />
                <linearGradient id="linear-gradient-11" x1="0" y1="0.496" x2="1.005" y2="0.496" />
                <linearGradient
                    id="linear-gradient-12"
                    x1="-0.004"
                    y1="0.505"
                    x2="1.001"
                    y2="0.505"
                />
                <linearGradient
                    id="linear-gradient-13"
                    x1="11.108"
                    y1="0.497"
                    x2="12.102"
                    y2="0.497"
                    gradientUnits="objectBoundingBox"
                >
                    <stop offset="0.02" stopColor="#ffefe4" />
                    <stop offset="0.51" stopColor="#fff8f4" />
                    <stop offset="1" stopColor="#fff" />
                </linearGradient>
                <linearGradient
                    id="linear-gradient-14"
                    x1="11.722"
                    y1="0.499"
                    x2="12.71"
                    y2="0.499"
                />
            </defs>
            <g id="Artboard_2" transform="translate(-29.35 -6.77)">
                <g id="Layer_1">
                    <ellipse
                        id="_Path_"
                        cx="142.63"
                        cy="14"
                        rx="142.63"
                        ry="14"
                        transform="translate(114.53 477.08)"
                        fill="#f4f5f6"
                    />
                    <circle
                        id="Ellipse_1"
                        cx="226.31"
                        cy="226.31"
                        r="226.31"
                        transform="translate(30.85 8.27)"
                        fill="#fff"
                        stroke="#707171"
                        strokeMiterlimit="10"
                        strokeWidth="3"
                    />
                    <circle
                        id="Ellipse_2"
                        cx="10.05"
                        cy="10.05"
                        r="10.05"
                        transform="translate(55.69 202.22)"
                        fill="#fff"
                        stroke="#e2e2e2"
                        strokeMiterlimit="10"
                        strokeWidth="2"
                    />
                    <circle
                        id="Ellipse_3"
                        cx="17.72"
                        cy="17.72"
                        r="17.72"
                        transform="translate(80.74 241.57)"
                        fill="#fff"
                        stroke="#e2e2e2"
                        strokeMiterlimit="10"
                        strokeWidth="2"
                    />
                    <circle
                        id="Ellipse_4"
                        cx="23.05"
                        cy="23.05"
                        r="23.05"
                        transform="translate(330.75 61.5)"
                        fill="#f4f5f6"
                    />
                    <circle
                        id="Ellipse_5"
                        cx="13.92"
                        cy="13.92"
                        r="13.92"
                        transform="translate(362.52 86.89)"
                        fill="#e3e2e3"
                    />
                    <path
                        id="Path_1"
                        d="M438.46,262.63h-6.7v-6.69a.84.84,0,0,0-.83-.84h0a.86.86,0,0,0-.86.84v6.69h-6.68a.84.84,0,1,0,0,1.68h6.68V271a.85.85,0,0,0,.86.83.83.83,0,0,0,.83-.83h0v-6.71h6.7a.84.84,0,1,0,0-1.68Z"
                        fill="#e2e2e2"
                        stroke="#e2e2e2"
                        strokeMiterlimit="10"
                        strokeWidth="0.75"
                    />
                    <path
                        id="Path_2"
                        d="M434.17,209.59H423.23V198.67a1.36,1.36,0,0,0-1.36-1.36h0a1.38,1.38,0,0,0-1.39,1.36v10.92H409.56a1.39,1.39,0,0,0-1.37,1.39,1.37,1.37,0,0,0,1.37,1.36h10.92v10.95a1.39,1.39,0,0,0,1.39,1.36,1.36,1.36,0,0,0,1.36-1.36h0V212.34h10.94a1.36,1.36,0,0,0,1.37-1.36,1.39,1.39,0,0,0-1.37-1.39Z"
                        fill="#e2e2e2"
                        stroke="#e2e2e2"
                        strokeMiterlimit="10"
                        strokeWidth="0.75"
                    />
                    <ellipse
                        id="Ellipse_6"
                        cx="24.89"
                        cy="15.01"
                        rx="24.89"
                        ry="15.01"
                        transform="translate(228.63 362.83)"
                        fill="#e3e2e3"
                    />
                    <g id="Group_1">
                        <path
                            id="Path_3"
                            d="M239.55,374.69c7.2,1,14.44,1.81,21.72,2.24,5.71.33,11.42.53,17.13.67-.22-8.18-11.27-14.77-24.88-14.77-11.23,0-20.72,4.49-23.82,10.65C233.06,374,236.51,374.24,239.55,374.69Z"
                            opacity="0.16"
                            fill="url(#linear-gradient)"
                        />
                    </g>
                    <path
                        id="Path_4"
                        d="M377.4,339c-1.4-1.55-2.91-3-4.29-4.58-19.23-21.65-23.81-54.82-25.82-82.58-2.13-29.46-1.14-59.41-7.9-88.34a88.14,88.14,0,0,0-57.58-63.15,39.68,39.68,0,0,0-1.69-8.2A24.42,24.42,0,0,0,268,77.76,23.65,23.65,0,0,0,235.32,88a29.55,29.55,0,0,0-3,11.08,86.76,86.76,0,0,0-35.43,19.43c-19.9,18.19-27.58,47-30.24,72.87-2.28,22-.27,43-1.3,65.17a211.861,211.861,0,0,1-7.26,46.86,76.561,76.561,0,0,1-9.64,21.42c-3.75,5.65-9.45,9.74-12.87,15.47-1.29,2.18-2.48,4-1.87,6.64,1.32,5.78,9.7,8.67,14.48,10.38,10.48,3.76,21.69,5.6,32.67,7.13,30.61,4.28,61.79,5.14,92.65,4.5,27.78-.58,56.54-1.81,83.63-8.53,6.92-1.72,16.25-3.86,21.27-9.31C382.53,346.6,380.69,342.63,377.4,339ZM243.74,96.7c1.56-6,5.47-11.64,12.22-12.22,9.26-.8,12.42,5.49,13.93,12.75a83.751,83.751,0,0,0-26.15-.53Z"
                        fill="#707171"
                    />
                    <g id="Group_2">
                        <path
                            id="Path_5"
                            d="M377.4,339.27c-1.4-1.55-2.9-3-4.29-4.57-12.41-14-17.15-28.26-21-47-.45-2.15-1.52-7.17-1.52-8.08,0-3.28-7.89-3.9-10.88-5.25-29.12-13.12-59.44-24.22-87.58-39.32a283,283,0,0,1-42.42-28q-9.94-7.92-19.15-16.73c-6.31-6-8.83-9.85-11.43-18-.86-2.71-1.6-5.46-2.29-8.22-.58-2.29-.74-6-2-7.91-2.14.77-3.47,7.37-4.08,9.42a91.242,91.242,0,0,0-2.47,10.83A209.848,209.848,0,0,0,166,197.87c-1.11,16.84,0,22.27.06,30.42.1,10-.28,18.51-.74,28.52a212,212,0,0,1-7.26,46.86,76.69,76.69,0,0,1-9.64,21.42c-3.75,5.65-9.45,9.73-12.87,15.47-1.29,2.18-2.48,4-1.87,6.64,1.32,5.78,9.7,8.67,14.48,10.38,10.48,3.76,21.69,5.6,32.67,7.13,30.61,4.27,61.79,5.13,92.65,4.49,27.78-.57,56.54-1.8,83.63-8.52,6.92-1.72,16.25-3.86,21.27-9.31C382.53,346.89,380.69,342.92,377.4,339.27Z"
                            opacity="0.2"
                            fill="url(#linear-gradient-2)"
                        />
                    </g>
                    <path
                        id="Path_6"
                        d="M164.88,263.86s108,25.65,183.62,1.58c0,0-.72-6.53-1.21-13.62,0,0-77.16,22.74-181.71-2.88,0,0-.47,11.89-.59,13.4Z"
                        fill="#e3e2e3"
                    />
                    <path
                        id="Path_7"
                        d="M163.64,275.74S262,306.14,350,277a158.086,158.086,0,0,0,3.83,18.7c2.29,7.73-103.8,38.82-193.76,0C160.06,295.7,163.4,277.46,163.64,275.74Z"
                        fill="#e3e2e3"
                    />
                    <g id="Group_3">
                        <path
                            id="Path_8"
                            d="M353.82,295.7A158.09,158.09,0,0,1,350,277c-2.82.94-5.65,1.81-8.49,2.63l4.61,21.62C351.43,299,354.21,297,353.82,295.7Z"
                            fill="url(#linear-gradient-3)"
                        />
                    </g>
                    <g id="Group_4">
                        <path
                            id="Path_9"
                            d="M315.91,285.45l4.94,23.14c4.57-1,8.76-2,12.5-3.06l-4.82-22.59Q322.23,284.37,315.91,285.45Z"
                            fill="url(#linear-gradient-4)"
                        />
                    </g>
                    <g id="Group_5">
                        <path
                            id="Path_10"
                            d="M290.57,288.62,293.36,313c5-.55,9.8-1.2,14.34-1.93l-5-23.63Q296.57,288.15,290.57,288.62Z"
                            fill="url(#linear-gradient-5)"
                        />
                    </g>
                    <g id="Group_6">
                        <path
                            id="Path_11"
                            d="M238.3,288.64l-2.87,25.16c4.94.41,10.24.69,14.65.85V289.31C246.41,289.15,242.13,288.92,238.3,288.64Z"
                            fill="url(#linear-gradient-4)"
                        />
                    </g>
                    <g id="Group_7">
                        <path
                            id="Path_12"
                            d="M163.64,275.74c-.24,1.72-3.58,20-3.58,20,3.12,1.35,6.26,2.6,9.42,3.78l4.47-21C167.35,276.89,163.64,275.74,163.64,275.74Z"
                            fill="url(#linear-gradient-7)"
                        />
                    </g>
                    <g id="Group_8">
                        <path
                            id="Path_13"
                            d="M212.87,285.93l-5.15,24.15c4.81.91,9.61,1.67,14.37,2.3l2.87-25C220.77,287,216.74,286.46,212.87,285.93Z"
                            fill="url(#linear-gradient-4)"
                        />
                    </g>
                    <g id="Group_9">
                        <path
                            id="Path_14"
                            d="M263.29,289.63v25.15c5.87-.07,11.32-.3,16.69-.65l-2.86-24.73C272.45,289.58,267.69,289.65,263.29,289.63Z"
                            fill="url(#linear-gradient-9)"
                        />
                    </g>
                    <g id="Group_10">
                        <path
                            id="Path_15"
                            d="M187,281.47l-4.76,22.35q6.2,1.89,12.42,3.42l5-23.38C195.06,283.07,190.82,282.26,187,281.47Z"
                            fill="url(#linear-gradient-10)"
                        />
                    </g>
                    <path
                        id="Path_16"
                        d="M274,381.48a3.39,3.39,0,0,1-3.57-2.09c-1.3-3.21,2.37-6.52,5.09-3.8C277.25,377.33,276.62,381.05,274,381.48Z"
                        fill="#fff"
                    />
                    <path
                        id="Path_17"
                        d="M271.59,376.86a.84.84,0,0,1,.39-.48,1.36,1.36,0,0,1,1.13-.12.85.85,0,0,1,.5,1.14,1.17,1.17,0,0,1-.79.55,1.15,1.15,0,0,1-1.15-.52.81.81,0,0,1-.08-.57Z"
                        opacity="0.2"
                        fill="url(#linear-gradient-11)"
                    />
                    <path
                        id="Path_18"
                        d="M237.07,381.37a3.42,3.42,0,0,0,3.58-2.09c1.29-3.21-2.38-6.52-5.1-3.8C233.81,377.22,234.44,380.93,237.07,381.37Z"
                        fill="#fff"
                    />
                    <path
                        id="Path_19"
                        d="M239.46,376.75a.82.82,0,0,0-.38-.48,1.31,1.31,0,0,0-1.14-.12.85.85,0,0,0-.49,1.14,1.13,1.13,0,0,0,1.93,0,.76.76,0,0,0,.08-.54Z"
                        opacity="0.2"
                        fill="url(#linear-gradient-12)"
                    />
                    <path
                        id="Path_20"
                        d="M227.15,393.23c-3.52-2.19-5.83-5.4-5.55-9.71,0-.28-.45-.35-.49-.07-.67,4.29,1.41,9.13,5.59,10.84C227.33,394.55,227.69,393.56,227.15,393.23Z"
                        fill="#e3e2e3"
                    />
                    <path
                        id="Path_21"
                        d="M216.8,392.6a11,11,0,0,1-2.27-11.52c.25-.69-.89-1.18-1.19-.5a10.56,10.56,0,0,0,3,12.45C216.67,393.27,217.07,392.89,216.8,392.6Z"
                        fill="#e3e2e3"
                    />
                    <path
                        id="Path_22"
                        d="M286.55,384.94a4.46,4.46,0,0,0,2.39-4.17.105.105,0,0,1,.21,0,4.33,4.33,0,0,1-2.4,4.66C286.48,385.51,286.32,385.09,286.55,384.94Z"
                        fill="url(#linear-gradient-13)"
                    />
                    <path
                        id="Path_23"
                        d="M291,384.67a4.74,4.74,0,0,0,1-4.95c-.11-.3.38-.51.51-.22a4.54,4.54,0,0,1-1.3,5.36.142.142,0,0,1-.21-.19Z"
                        fill="url(#linear-gradient-14)"
                    />
                    <path
                        id="Path_24"
                        d="M144.93,129.72l-8.43-6a.28.28,0,0,0-.39.06l-10.66,14.84a.28.28,0,0,1-.31.1L111.46,134a.28.28,0,0,1-.18-.34L114.6,123a.28.28,0,0,1,.44-.13l8.67,7a.28.28,0,0,0,.37,0l11.84-12.34a.28.28,0,0,1,.42,0l9,11.82a.28.28,0,0,1-.41.37Z"
                        fill="#e3e2e3"
                    />
                    <path
                        id="Path_25"
                        d="M158.74,110.5,142,96.44a.31.31,0,0,1-.09-.33l4.14-12.44a.31.31,0,0,0-.34-.4l-14,2.14a.31.31,0,0,1-.34-.4l3.32-10a.31.31,0,0,0-.33-.4L124.92,76a.31.31,0,0,0-.26.28l-1.4,18.25a.31.31,0,0,0,.39.32l15-4.11a.32.32,0,0,1,.38.4l-2.65,7.94a.31.31,0,0,0,.15.37L158.4,111a.31.31,0,0,0,.34-.5Z"
                        fill="#e3e2e3"
                    />
                    <path
                        id="Path_26"
                        d="M178.4,100.91l-7.36-18,7.57-9.68v-.06l-8.58-13V60.1l6.42-4.25a.05.05,0,0,1,.08,0l7.49,18.36h0l-7.58,9.69h0l2.06,16.93a.064.064,0,0,1-.1.08Z"
                        fill="#e3e2e3"
                    />
                </g>
            </g>
        </SVGIcon>
    );
}
