import React from 'react';

import { SVGIcon } from '../../UIKit/index';

export default function DCDetailsCard(props) {
    return (
        <SVGIcon {...props} viewBox="0 0 512 512">
            <path d="M407.38,148.32c5.49,0,9.93-4.45,9.93-9.93V29.8c0.08-16.45-13.18-29.85-29.62-29.95H29.49     C13.04-0.05-0.22,13.35-0.13,29.8v451.12c-0.08,16.45,13.18,29.85,29.62,29.95h358.2c16.41-0.15,29.61-13.54,29.52-29.95v-73.53     c0.72-5.44-3.1-10.43-8.53-11.16c-5.44-0.72-10.43,3.1-11.16,8.53c-0.12,0.87-0.12,1.75,0,2.62v73.53c0,5.49-4.45,9.93-9.93,9.93     H29.49c-5.49,0-9.93-4.45-9.93-9.93V29.8c-0.08-5.49,4.3-10,9.78-10.08c0.05,0,0.1,0,0.15,0h358.2c5.49,0,9.93,4.45,9.93,9.93     v108.69C397.59,143.77,401.94,148.22,407.38,148.32z" />
            <path d="M204.46,51.56c-50.74,0.12-91.78,41.35-91.66,92.09c0.06,25.14,10.42,49.16,28.66,66.46     c0.33,0.36,0.69,0.69,1.07,0.99c35.04,32.1,88.8,32.1,123.84,0c0.38-0.3,0.74-0.64,1.07-0.99     c36.82-34.91,38.37-93.07,3.46-129.89C253.6,61.99,229.59,51.63,204.46,51.56z M204.46,215.26     c-15.68,0.01-30.94-5.14-43.41-14.65c13.56-24,44.01-32.46,68.01-18.9c7.9,4.47,14.44,11,18.9,18.9     C235.47,210.14,220.18,215.29,204.46,215.26z M188.49,139.39v-6.48c0-8.82,7.15-15.97,15.97-15.97c8.82,0,15.97,7.15,15.97,15.97     v6.48c0,8.82-7.15,15.97-15.97,15.97C195.64,155.36,188.49,148.21,188.49,139.39z M262.29,185.98     c-7.28-10.83-17.47-19.37-29.4-24.66c4.85-6.29,7.47-14.01,7.45-21.95v-6.48c0.03-19.84-16.04-35.95-35.88-35.98     c-19.84-0.03-35.95,16.04-35.98,35.88c0,0.03,0,0.07,0,0.1v6.48c-0.03,7.94,2.6,15.67,7.45,21.95     c-11.93,5.28-22.12,13.83-29.4,24.66c-23.56-31.94-16.76-76.93,15.18-100.49c31.94-23.56,76.93-16.76,100.49,15.18     c18.71,25.36,18.71,59.95,0,85.31H262.29z" />
            <path d="M254.65,419.38h-53.88c-5.49,0.03-9.91,4.5-9.88,9.99c0.03,5.44,4.44,9.85,9.88,9.88h53.88     c5.49,0.03,9.96-4.39,9.99-9.88c0.03-5.48-4.39-9.96-9.88-9.99C254.72,419.38,254.68,419.38,254.65,419.38z" />
            <path d="M258.95,365.76H117.23c-5.49,0.03-9.91,4.5-9.88,9.99c0.03,5.44,4.43,9.85,9.88,9.88h141.71     c5.49-0.03,9.91-4.5,9.88-9.99C268.8,370.2,264.39,365.79,258.95,365.76z" />
            <path d="M258.95,312.12H117.23c-5.49-0.03-9.96,4.39-9.99,9.88c-0.03,5.48,4.39,9.96,9.88,9.99     c0.04,0,0.07,0,0.11,0h141.71c5.49,0.03,9.96-4.39,9.99-9.88c0.03-5.48-4.39-9.96-9.88-9.99     C259.02,312.12,258.98,312.12,258.95,312.12z" />
            <path d="M85.18,261.4c-3.89-3.87-10.18-3.85-14.05,0.04s-3.85,10.18,0.04,14.05     c3.89,3.87,10.18,3.85,14.05-0.04c1.85-1.86,2.89-4.37,2.89-6.99C88.1,265.81,87.04,263.28,85.18,261.4z" />
            <path d="M85.18,315.05c-3.89-3.87-10.18-3.85-14.05,0.04s-3.85,10.18,0.04,14.05     c3.89,3.87,10.18,3.85,14.05-0.04c1.85-1.86,2.89-4.37,2.89-6.99C88.09,319.46,87.04,316.92,85.18,315.05z" />
            <path d="M85.18,368.69c-3.89-3.87-10.18-3.85-14.05,0.04c-3.87,3.89-3.85,10.18,0.04,14.05     c3.89,3.87,10.18,3.85,14.05-0.04c1.85-1.86,2.89-4.37,2.89-6.99C88.09,373.1,87.04,370.57,85.18,368.69z" />
            <path d="M258.95,258.47H117.23c-5.49,0.03-9.91,4.5-9.88,9.99c0.03,5.44,4.43,9.85,9.88,9.88h141.71     c5.49-0.03,9.91-4.5,9.88-9.99C268.8,262.91,264.39,258.5,258.95,258.47z" />
            <path d="M472.36,124.86c-20.05-11.58-45.68-4.71-57.26,15.34c0,0,0,0,0,0.01L289.52,357.78     c-0.69,1.19-1.12,2.51-1.27,3.87l-8.84,79.19c-0.67,5.44,3.2,10.4,8.65,11.07c2.56,0.31,5.14-0.38,7.19-1.93l64.17-47.18     c1.11-0.82,2.04-1.86,2.73-3.05l125.6-217.53c11.59-20.07,4.72-45.74-15.34-57.33C472.39,124.88,472.38,124.87,472.36,124.86z      M301.76,420.35l4.62-41.34l28.88,16.69L301.76,420.35z M349.81,381.05l-38.04-21.95l100.79-174.57l38.04,21.95L349.81,381.05z      M470.42,172.16l-9.93,17.03l-38.04-21.95l9.93-17.03c6.18-10.43,19.64-13.88,30.07-7.7c10.27,6.08,13.8,19.25,7.94,29.65H470.42     z" />
        </SVGIcon>
    );
}
