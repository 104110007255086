import React from 'react';

import { SVGIcon } from '../../UIKit/index';

export default function DCBuildInstaReport2(props) {
    return (
        <SVGIcon {...props} viewBox="0 0 192.3 192.3">
            <g>
                <path d="M122.9,0H19.7v192.3h152.9V49.7L122.9,0z M147,45.3h-19.7V25.6L147,45.3z M34.7,177.3V15h77.6v37.8   c0,4.1,3.4,7.5,7.5,7.5h37.8v117H34.7z" />
                <rect x="53.1" y="149" width="86" height="10" />
                <rect x="53.1" y="55.1" width="51.1" height="10" />
                <polygon points="121.2,86.9 126.8,86.9 105.4,108.4 88.6,91.6 51.6,128.6 58.7,135.7 88.6,105.7 105.4,122.5 133.9,94    133.9,99.5 143.9,99.5 143.9,76.9 121.2,76.9  " />
                <rect x="53.1" y="33.3" width="51.1" height="10" />
            </g>
        </SVGIcon>
    );
}
