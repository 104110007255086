import React from 'react';

import { SVGIcon } from '../../UIKit/index';

export default function DCNoUser(props) {
    return (
        <SVGIcon {...props} viewBox="0 0 76 78">
            <g transform="translate(-874 -266)">
                <g transform="translate(1)">
                    <g transform="translate(-232 -426)">
                        <g>
                            <rect
                                width="36"
                                height="5"
                                rx="2.5"
                                transform="translate(1145 698)"
                                fill="#405d63"
                            />
                            <rect
                                width="36"
                                height="5"
                                rx="2.5"
                                transform="translate(1145 709)"
                                fill="#d8e1e4"
                            />
                            <rect
                                width="23"
                                height="23"
                                rx="1"
                                transform="translate(1117 703)"
                                fill="#d8e1e4"
                            />
                            <g
                                transform="translate(1105 692)"
                                fill="#fff"
                                stroke="#405d63"
                                strokeWidth="3"
                            >
                                <rect width="29" height="29" rx="1" stroke="none" />
                                <rect x="1.5" y="1.5" width="26" height="26" rx="0.5" fill="none" />
                            </g>
                            <g transform="translate(-0.056 1)">
                                <circle
                                    cx="3"
                                    cy="3"
                                    r="3"
                                    transform="translate(1116 697)"
                                    fill="#d8e1e4"
                                />
                                <path
                                    d="M398.485,133.433h7.881a2.377,2.377,0,0,0,2.328-2.306v-.494a6.216,6.216,0,0,0-6.239-6.135H402.4a6.147,6.147,0,0,0-6.147,6.147v.553A2.235,2.235,0,0,0,398.485,133.433Z"
                                    transform="translate(716.806 580.428)"
                                    fill="#405d63"
                                />
                            </g>
                        </g>
                    </g>
                </g>
                <g transform="translate(1 44)">
                    <g transform="translate(-232 -426)">
                        <g id="Group_6381-2" data-name="Group 6381">
                            <rect
                                width="36"
                                height="5"
                                rx="2.5"
                                transform="translate(1145 698)"
                                fill="#405d63"
                            />
                            <rect
                                width="36"
                                height="5"
                                rx="2.5"
                                transform="translate(1145 709)"
                                fill="#d8e1e4"
                            />
                            <rect
                                width="23"
                                height="23"
                                rx="1"
                                transform="translate(1117 703)"
                                fill="#d8e1e4"
                            />
                            <g
                                transform="translate(1105 692)"
                                fill="#fff"
                                stroke="#405d63"
                                strokeWidth="3"
                            >
                                <rect width="29" height="29" rx="1" stroke="none" />
                                <rect x="1.5" y="1.5" width="26" height="26" rx="0.5" fill="none" />
                            </g>
                            <g transform="translate(-0.056 1)">
                                <circle
                                    cx="3"
                                    cy="3"
                                    r="3"
                                    transform="translate(1116 697)"
                                    fill="#d8e1e4"
                                />
                                <path
                                    d="M398.485,133.433h7.881a2.377,2.377,0,0,0,2.328-2.306v-.494a6.216,6.216,0,0,0-6.239-6.135H402.4a6.147,6.147,0,0,0-6.147,6.147v.553A2.235,2.235,0,0,0,398.485,133.433Z"
                                    transform="translate(716.806 580.428)"
                                    fill="#405d63"
                                />
                            </g>
                        </g>
                    </g>
                </g>
            </g>
        </SVGIcon>
    );
}
