import React, { useCallback, useEffect, useState } from 'react';

import DCRadioButtonChecked from '../../Assets/icons/DCRadioButtonChecked';
import DCRadioButtonUnchecked from '../../Assets/icons/DCRadioButtonUnchecked';
import { Span } from '../index';
import PropTypes from 'prop-types';
import './style.scss';

const RadioButton = ({
    unCheckIcon,
    unCheckColor,
    checkIcon,
    checkColor,
    classes,
    label,
    value,
    name,
    onChange,
    // onClick,
    checked,
    disabled,
    id,
    inputRef,
    fullWidth,
}) => {
    const [checkedState, setCheckedState] = useState(false);

    const defaultOnChange = useCallback(
        e => {
            setCheckedState(e.target.checked);
            onChange && onChange(e);
        },
        [onChange],
    );

    useEffect(() => {
        setCheckedState(checked || false);
    }, [checked]);

    return (
        <label
            className={
                'radioButton ' +
                (classes?.main ? classes.main : '') +
                (disabled ? ' radioButtonDisabled' : '') +
                (fullWidth === true ? ' fullWidth' : '')
            }
            htmlFor={id}
        >
            <input
                type="radio"
                className="radioButtonInput"
                value={value}
                name={name}
                onChange={defaultOnChange}
                // onClick={onClick}
                checked={checkedState}
                disabled={disabled}
                id={id}
                ref={inputRef}
            />
            <Span
                className={`radioButtonIcon ${classes?.iconClass ? classes.iconClass : ''}`}
                style={{
                    color: checkedState === false ? unCheckColor : checkColor,
                }}
            >
                {unCheckIcon && checkedState === false ? (
                    unCheckIcon
                ) : checkedState === false ? (
                    <DCRadioButtonUnchecked />
                ) : (
                    ''
                )}
                {checkIcon && checkedState === true ? (
                    checkIcon
                ) : checkedState === true ? (
                    <DCRadioButtonChecked />
                ) : (
                    ''
                )}
            </Span>
            {label ? (
                <Span className={`radioButtonLabel ${classes?.label ? classes.label : ''}`}>
                    {label}
                </Span>
            ) : null}
        </label>
    );
};

RadioButton.propTypes = {
    unCheckIcon: PropTypes.element,
    unCheckColor: PropTypes.string,
    checkIcon: PropTypes.element,
    checkColor: PropTypes.string,
    classes: PropTypes.object,
    label: PropTypes.string,
    value: PropTypes.string,
    name: PropTypes.string,
    onChange: PropTypes.func,
    checked: PropTypes.bool,
    disabled: PropTypes.bool,
    id: PropTypes.string,
    inputRef: PropTypes.object,
    fullWidth: PropTypes.bool,
};

export default RadioButton;
