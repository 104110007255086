import React from 'react';

import { SVGIcon } from '../../UIKit/index';

export default function DCBing1(props) {
    return (
        <SVGIcon {...props} viewBox="0 0 297.161 297.161">
            <g>
                <path d="M268.595,127.69c0-2.75-2.151-5.66-4.78-6.467L122.483,77.936c-2.63-0.805-3.838,0.58-2.684,3.076   l26.297,57.1c1.151,2.496,4.144,5.467,6.649,6.6l36.055,16.332c2.506,1.131,2.566,3.105,0.133,4.387L35.085,246.372   c-2.434,1.281-2.747,0.828-0.695-1.002l59.108-52.648c2.053-1.83,3.732-5.576,3.732-8.326l0.042-159.609   c-0.002-2.75-2.154-5.662-4.783-6.469L33.341,0.208c-2.63-0.803-4.782,0.791-4.782,3.541v242.139c0,2.75,1.843,6.291,4.095,7.869   L92.76,295.88c2.252,1.578,6.027,1.719,8.39,0.311l163.156-97.199c2.362-1.408,4.296-4.809,4.296-7.559L268.595,127.69z" />
            </g>
        </SVGIcon>
    );
}
