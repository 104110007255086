import React from 'react';

import { SVGIcon } from '../../UIKit/index';

export default function DCNoGroup(props) {
    return (
        <SVGIcon {...props} viewBox="0 0 218.022 200.533">
            <g transform="translate(-764.5 -27.854)">
                <ellipse
                    cx="87.5"
                    cy="17.5"
                    rx="87.5"
                    ry="17.5"
                    transform="translate(784.981 193.387)"
                    fill="#f5f5f5"
                />
                <g
                    transform="translate(821.511 140.884)"
                    fill="#fff"
                    stroke="#405d63"
                    strokeWidth="2"
                >
                    <rect width="103" height="69" rx="7" stroke="none" />
                    <rect x="1" y="1" width="101" height="67" rx="6" fill="none" />
                </g>
                <g
                    transform="translate(865.511 166.884)"
                    fill="#fff"
                    stroke="#405d63"
                    strokeWidth="2"
                >
                    <ellipse cx="7.5" cy="8" rx="7.5" ry="8" stroke="none" />
                    <ellipse cx="7.5" cy="8" rx="6.5" ry="7" fill="none" />
                </g>
                <path
                    d="M10805.072,289.549s-28.584-3.4-23.82,28.812c.454-.454-11.343,16.788,7.486,23.821.227.227,4.991,27,32.216,14.52.454-.681,17.468,9.755,22.006-8.621,0-.454,29.493-9.755,11.57-36.3-.672.194,2.722-23.367-24.5-24.955C10830.028,286.373,10815.055,272.08,10805.072,289.549Z"
                    transform="translate(-10011.472 -252)"
                    fill="#fff"
                    stroke="#405d63"
                    strokeWidth="2"
                />
                <g
                    transform="translate(808.511 108.884)"
                    fill="#fff"
                    stroke="#405d63"
                    strokeWidth="2"
                >
                    <circle cx="6" cy="6" r="6" stroke="none" />
                    <circle cx="6" cy="6" r="5" fill="none" />
                </g>
                <g
                    transform="translate(822.511 116.884)"
                    fill="#fff"
                    stroke="#405d63"
                    strokeWidth="2"
                >
                    <circle cx="3.5" cy="3.5" r="3.5" stroke="none" />
                    <circle cx="3.5" cy="3.5" r="2.5" fill="none" />
                </g>
                <g
                    transform="translate(853.511 107.884)"
                    fill="#fff"
                    stroke="#405d63"
                    strokeWidth="2"
                >
                    <circle cx="3" cy="3" r="3" stroke="none" />
                    <circle cx="3" cy="3" r="2" fill="none" />
                </g>
                <g
                    transform="translate(886.511 107.884)"
                    fill="#fff"
                    stroke="#405d63"
                    strokeWidth="2"
                >
                    <circle cx="3" cy="3" r="3" stroke="none" />
                    <circle cx="3" cy="3" r="2" fill="none" />
                </g>
                <path
                    d="M11050,534.2s7.965,9.837,8.148,12.282-.473,5.071-8.148,6.782"
                    transform="translate(-10125.326 -358.039)"
                    fill="none"
                    stroke="#405d63"
                    strokeWidth="2"
                />
                <path
                    d="M10900.615,388.854s-7.6-29.4-1.959-43.894c-.27.134,3.758,12.753,20.807,7.652s33.023-15.438,39.2,2.416c-.27.4,11.007-4.161,11.813,7.249s-1.879,26.577-1.879,26.577"
                    transform="translate(-10061.101 -278.819)"
                    fill="#fff"
                    stroke="#405d63"
                    strokeWidth="2"
                />
                <path
                    d="M11049.336,504.147l5.854,6.275c.016-.017.949,1.562-1.106,2.622a38.611,38.611,0,0,1-4.747,2.962"
                    transform="translate(-10125.049 -345.457)"
                    fill="none"
                    stroke="#405d63"
                    strokeWidth="2"
                />
                <path
                    d="M11049.336,557.475s17.764-4.845,17.764-13.106c1.748-10.485-12.788-24.783-12.788-24.783"
                    transform="translate(-10125.049 -351.919)"
                    fill="none"
                    stroke="#405d63"
                    strokeWidth="2"
                />
                <path
                    d="M10948.442,464.289s2.351-6.674.8-8.883-1.881-3.948.281-4.324,1.316,2.585,2.539,2.726,3.523-1.645,3.523-1.645,5.688-4.23,7.146,3.854c-.095,3.9-4.841,4.606-4.841,4.606v3.431"
                    transform="translate(-10082.624 -323.229)"
                    fill="none"
                    stroke="#405d63"
                    strokeWidth="2"
                />
                <path
                    d="M10957.338,443.523s.188-3.055,2.773-3.008,2.49,3.29,2.49,3.29"
                    transform="translate(-10086.537 -318.82)"
                    fill="none"
                    stroke="#405d63"
                    strokeWidth="2"
                />
                <path
                    d="M10970.73,437.034v-44.2a5.857,5.857,0,0,0-7.229-4.44c-6.093,1.136-27.984,9.4-49.67,0-4.232-1.446-6.814,2.685-6.814,3.3v45.952"
                    transform="translate(-10065.473 -296.875)"
                    fill="none"
                    stroke="#405d63"
                    strokeWidth="2"
                />
                <path
                    d="M11016.6,420.244s8.672-.715,7.48,6.2-6.831,5.243-6.831,5.243"
                    transform="translate(-10111.347 -310.325)"
                    fill="none"
                    stroke="#405d63"
                    strokeWidth="2"
                />
                <path
                    d="M10901.466,420.462s-8.159-.376-7.69,5.734,7.379,5.875,7.379,5.875"
                    transform="translate(-10059.921 -310.424)"
                    fill="none"
                    stroke="#405d63"
                    strokeWidth="2"
                />
                <line
                    x1="168.022"
                    transform="translate(789.5 208.895)"
                    fill="none"
                    stroke="#405d63"
                    strokeWidth="2"
                />
                <line
                    x2="19.186"
                    transform="translate(963.336 208.895)"
                    fill="none"
                    stroke="#405d63"
                    strokeWidth="2"
                />
                <line
                    x2="19.186"
                    transform="translate(764.5 208.895)"
                    fill="none"
                    stroke="#405d63"
                    strokeWidth="2"
                />
                <g
                    transform="translate(788.511 185.884)"
                    fill="#fff"
                    stroke="#405d63"
                    strokeWidth="2"
                >
                    <rect width="19" height="23" rx="4" stroke="none" />
                    <rect x="1" y="1" width="17" height="21" rx="3" fill="none" />
                </g>
                <path
                    d="M10813.414,563.578s-7.387,3.574,0,8.261"
                    transform="translate(-10024.915 -370.335)"
                    fill="none"
                    stroke="#405d63"
                    strokeWidth="2"
                />
                <g transform="translate(795.805 50.197)">
                    <g
                        transform="translate(5.705 37.686)"
                        fill="#fff"
                        stroke="#405d63"
                        strokeWidth="2"
                    >
                        <circle cx="3.5" cy="3.5" r="3.5" stroke="none" />
                        <circle cx="3.5" cy="3.5" r="2.5" fill="none" />
                    </g>
                    <path
                        d="M10828.346,328.648s.537-12.35,13.155-11.008a10.058,10.058,0,0,1,6.444,12.619,12.181,12.181,0,0,1-7.787,6.981c-.8,1.074-2.818,2.551-1.879,12.887"
                        transform="translate(-10828.346 -317.538)"
                        fill="none"
                        stroke="#405d63"
                        strokeWidth="2"
                    />
                </g>
                <path
                    d="M10810.277,556.337s-8.023,1.271-7.626,7.546,3.893,8.023,7.149,8.42"
                    transform="translate(-10021.777 -367.304)"
                    fill="none"
                    stroke="#405d63"
                    strokeWidth="2"
                />
                <g transform="translate(796.063 134.752) rotate(180)" opacity="0.5">
                    <rect
                        width="2.113"
                        height="12.761"
                        rx="1.056"
                        transform="translate(12.761 5.324) rotate(90)"
                        fill="#c3c5c4"
                    />
                    <rect
                        width="2.113"
                        height="12.761"
                        rx="1.056"
                        transform="translate(7.437 12.761) rotate(180)"
                        fill="#c3c5c4"
                    />
                </g>
                <g transform="translate(10 -10)">
                    <g transform="translate(923.97 59.171) rotate(180)" opacity="0.5">
                        <rect
                            width="2.113"
                            height="12.761"
                            rx="1.056"
                            transform="translate(12.761 5.324) rotate(90)"
                            fill="#c3c5c4"
                        />
                        <rect
                            width="2.113"
                            height="12.761"
                            rx="1.056"
                            transform="translate(7.437 12.761) rotate(180)"
                            fill="#c3c5c4"
                        />
                    </g>
                    <g
                        transform="translate(929.765 79.535) rotate(180)"
                        fill="#fff"
                        stroke="#c3c5c4"
                        strokeWidth="1.6"
                        opacity="0.5"
                    >
                        <circle cx="5.796" cy="5.796" r="5.796" stroke="none" />
                        <circle cx="5.796" cy="5.796" r="4.996" fill="none" />
                    </g>
                </g>
                <g
                    transform="translate(954.834 154.326) rotate(180)"
                    fill="#fff"
                    stroke="#c3c5c4"
                    strokeWidth="1.6"
                    opacity="0.5"
                >
                    <circle cx="5.796" cy="5.796" r="5.796" stroke="none" />
                    <circle cx="5.796" cy="5.796" r="4.996" fill="none" />
                </g>
            </g>
        </SVGIcon>
    );
}
