import React from 'react';
import DCFilter from 'Assets/icons/DCFilter';
import { IconButton } from 'UIKit/index';
import PropTypes from 'prop-types';
import './style.scss';

const SidebarToggle = ({ className, view, handleClick }) => (
    <IconButton
        onClick={handleClick}
        customClass={'headerIconBtn SideBarToggleBtn ' + (className ? className : '')}
        buttonType={view ? 'RedFillBtn' : 'BlueFillBtn'}
    >
        {view ? <DCFilter /> : <DCFilter />}
    </IconButton>
);

SidebarToggle.propTypes = {
    className: PropTypes.string,
    view: PropTypes.bool,
    handleClick: PropTypes.func,
};

export default SidebarToggle;
