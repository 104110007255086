import React from 'react';

import { SVGIcon } from '../../UIKit/index';

export default function DCUserBook(props) {
    return (
        <SVGIcon {...props} viewBox="0 0 512 512">
            <path d="M285.6,109.9c-37.7,0-68.4,30.7-68.4,68.4s30.7,68.4,68.4,68.4c37.7,0,68.4-30.7,68.4-68.4     C354,140.6,323.3,109.9,285.6,109.9z M285.6,209.8c-17.3,0-31.4-14.1-31.4-31.4s14.1-31.4,31.4-31.4s31.5,14.1,31.5,31.4     S302.9,209.8,285.6,209.8z" />
            <path d="M308.4,256h-46.2c-51.3,0-93.1,41.8-93.1,93.1V383c0,10.2,8.3,18.5,18.5,18.5H383c10.2,0,18.5-8.3,18.5-18.5     v-33.9C401.5,297.8,359.8,256,308.4,256z M364.5,364.5H206.1v-15.4c0-30.9,25.2-56.1,56.1-56.1h46.2c30.9,0,56.1,25.2,56.1,56.1     L364.5,364.5L364.5,364.5z" />
            <path d="M82.1,200.5c-10.2,0-18.5,8.3-18.5,18.5v74c0,10.2,8.3,18.5,18.5,18.5s18.5-8.3,18.5-18.5v-74     C100.6,208.8,92.3,200.5,82.1,200.5z" />
            <path d="M452.1,9.4H100.6c-20.7,0-37,16.3-37,37v43.2c0,10.2,8.3,18.5,18.5,18.5s18.5-8.3,18.5-18.5V46.4h351.5     v419.3H100.6v-43.2c0-10.2-8.3-18.5-18.5-18.5s-18.5,8.3-18.5,18.5v43.2c0,20.7,16.3,37,37,37h351.5c20.7,0,37-16.3,37-37V46.4     C489.1,25.6,472.8,9.4,452.1,9.4z" />
            <path d="M124.7,338H41.4c-10.2,0-18.5,8.3-18.5,18.5S31.2,375,41.4,375h83.2c10.2,0,18.5-8.3,18.5-18.5     S134.9,338,124.7,338z" />
            <path d="M118.5,137H41.4c-10.2,0-18.5,8.3-18.5,18.5S31.2,174,41.4,174h77.1c10.2,0,18.5-8.3,18.5-18.5     S128.7,137,118.5,137z" />
        </SVGIcon>
    );
}
