import React from 'react';

import { SVGIcon } from '../../UIKit/index';

export default function DCCoffeeCup(props) {
    return (
        <SVGIcon {...props} viewBox="0 0 512 512">
            <g>
                <path
                    d="m420.802 132.164h-26.252v38.986h26.252c28.789 0 52.212 23.422 52.212 52.211v50.394c0 28.789-23.422 52.211-52.212 52.211h-35.066c-5.591 14.409-13.664 27.578-23.693 38.986h58.76c50.287 0 91.198-40.911 91.198-91.198v-50.394c-.001-50.285-40.912-91.196-91.199-91.196z"
                    fill="#ce775f"
                />
                <path
                    d="m428.604 325.382c-2.546.383-5.15.585-7.802.585h-35.066c-5.591 14.409-13.664 27.578-23.693 38.986h43.921c9.52-11.167 17.222-25.719 22.64-39.571z"
                    fill="#c0694e"
                />
                <path
                    d="m437.594 173.937v-40.217c-5.445-1.018-11.057-1.556-16.792-1.556h-26.252v38.986h26.252c5.874 0 11.518.99 16.792 2.787z"
                    fill="#c0694e"
                />
                <path
                    d="m381.019 97.583h-364.398c-9.18 0-16.621 7.442-16.621 16.621v40.077h397.64v-40.077c0-9.179-7.441-16.621-16.621-16.621z"
                    fill="#ce775f"
                />
                <path
                    d="m81.866 97.583h-65.245c-9.18 0-16.621 7.442-16.621 16.621v40.077h65.245v-40.077c0-9.179 7.442-16.621 16.621-16.621z"
                    fill="#c0694e"
                />
                <g>
                    <path
                        d="m138.255 107.678c-4.267 0-7.726-3.459-7.726-7.726v-20.95c0-7.389-6.012-13.4-13.401-13.4-4.267 0-7.726-3.459-7.726-7.726s3.459-7.726 7.726-7.726c15.91 0 28.854 12.944 28.854 28.853v20.95c-.001 4.266-3.46 7.725-7.727 7.725z"
                        fill="#dbedff"
                    />
                </g>
                <path
                    d="m0 152.221v127.518c0 72.176 58.511 130.687 130.688 130.687h136.265c72.177 0 130.688-58.511 130.688-130.688v-127.517z"
                    fill="#e58e7a"
                />
                <path
                    d="m65.245 287.209v-134.988h-65.245v127.518c0 72.176 58.511 130.687 130.688 130.687h5.765c-42.274-22.913-71.208-69.488-71.208-123.217z"
                    fill="#ce775f"
                />
                <path
                    d="m133.745 216.209-22.302-28.912c-7.808-10.122-23.08-10.122-30.889 0l-22.302 28.912c-2.907 3.768-4.484 8.394-4.484 13.153v77.925c0 5.592 4.533 10.125 10.124 10.125h64.212c5.592 0 10.125-4.533 10.125-10.125v-77.925c0-4.759-1.576-9.384-4.484-13.153zm-37.746 29.077c-8.852 0-16.028-7.176-16.028-16.028s7.176-16.028 16.028-16.028 16.028 7.176 16.028 16.028-7.176 16.028-16.028 16.028z"
                    fill="#f4dd45"
                />
                <g>
                    <path
                        d="m91.199 307.287v-62.735c-6.507-2.04-11.228-8.115-11.228-15.295 0-8.852 7.176-16.028 16.028-16.028.64 0 1.268.048 1.889.121l16.827-21.813-3.271-4.24c-7.808-10.122-23.08-10.122-30.889 0l-22.302 28.912c-2.907 3.768-4.484 8.394-4.484 13.153v77.925c0 5.592 4.533 10.125 10.125 10.125h37.43c-5.592 0-10.125-4.533-10.125-10.125z"
                        fill="#fcc101"
                    />
                </g>
                <g>
                    <path
                        d="m95.999 219.235c-4.267 0-7.726-3.459-7.726-7.726v-132.507c0-15.91 12.944-28.853 28.854-28.853 4.267 0 7.727 3.459 7.727 7.726s-3.459 7.726-7.726 7.726c-7.39 0-13.402 6.011-13.402 13.4v132.507c-.001 4.268-3.46 7.727-7.727 7.727z"
                        fill="#dbedff"
                    />
                </g>
                <path
                    d="m384.808 409.396h-371.976c-4.623 0-7.744 4.811-5.764 8.988 5.445 11.49 12.516 22.059 20.917 31.41 6.872 7.649 16.643 12.057 26.925 12.057h287.82c10.282 0 20.053-4.409 26.925-12.057 8.401-9.351 15.472-19.92 20.917-31.41 1.98-4.177-1.141-8.988-5.764-8.988z"
                    fill="#ce775f"
                />
                <path
                    d="m94.055 450.337c-8.723-9.709-16.065-20.683-21.718-32.614-1.496-3.157-.18-6.653 2.495-8.327h-62c-4.623 0-7.744 4.811-5.764 8.988 5.445 11.49 12.516 22.059 20.917 31.41 6.872 7.649 16.643 12.057 26.925 12.057h58.551c-7.424-1.748-14.214-5.735-19.406-11.514z"
                    fill="#c0694e"
                />
            </g>
        </SVGIcon>
    );
}
