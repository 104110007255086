import React from 'react';

import { SVGIcon } from '../../UIKit/index';

export default function DCLayout(props) {
    return (
        <SVGIcon {...props} viewBox="0 0 512 512">
            <path d="M471.34,2.43H40.06C17.36,2.62-0.9,21.16-0.72,43.86v414.22   c-0.19,22.66,18.03,41.18,40.69,41.36c0.03,0,0.06,0,0.09,0h431.28c22.68-0.17,40.93-18.68,40.78-41.36V43.86   C512.27,21.17,494.03,2.64,471.34,2.43z M481.46,68.59v157.22H176.03V32.18h269.49C465.48,32.34,481.56,48.62,481.46,68.59z    M29.96,433.24V68.59c-0.12-19.97,15.95-36.26,35.92-36.41h70.39v437.54H65.88c-19.99-0.15-36.07-16.47-35.92-36.45   C29.96,433.26,29.96,433.25,29.96,433.24z M445.52,469.72H176.03V267.04H481.5V433.2c0.17,20-15.9,36.35-35.9,36.52   C445.58,469.72,445.55,469.72,445.52,469.72z" />
        </SVGIcon>
    );
}
