import React, { useState } from 'react';
import PhoneNumberInput from 'Modules/PhoneNumber/phoneNumber';
import { sendOTPForPhone } from 'apis/login/index.api';
import { Div, Input, Button } from 'UIKit';
import DCResetPassword from 'Assets/icons/DCResetPassword';
import './style.scss';

const ForgetUsername = ({
    account,
    setError,
    setForgetPasswordEmail,
    setCredentials,
    handleChangeEvent,
}) => {
    const [phoneNumber, setPhoneNumber] = useState('');
    const [otp, setOTP] = useState(null);
    const [showOTP, setShowOTP] = useState(false);
    const [loading, setLoading] = useState(false);
    const [isDisabled, setIsDisabled] = useState(false);

    const handleSubmit = async body => {
        try {
            setLoading(true);
            const res = await sendOTPForPhone({
                ...body,
                account_id: account.id,
            });
            setLoading(false);
            if (res.success) {
                if (body.phone_number) {
                    setShowOTP(true);
                } else {
                    setForgetPasswordEmail(false);
                    setCredentials({ email: res.data.email });
                    handleChangeEvent({
                        target: { name: 'email', value: res.data.email },
                    });
                }
            } else {
                setError({ phone: res.message });
            }
        } catch (err) {
            console.error('error', err);
        }
    };

    const handleChange = async val => {
        setPhoneNumber(val);
        setError({ phone: null });
    };

    const handleOTPChange = async e => {
        const val = e.target.value;
        setError({ phone: null });
        setIsDisabled(val.length !== 6);
        setOTP(val);
    };

    return (
        <Div className="ResetPassword">
            <PhoneNumberInput
                value={phoneNumber}
                required={true}
                onChange={val => {
                    handleChange(val);
                }}
                disabled={showOTP}
                setError={setIsDisabled}
            />
            {showOTP && (
                <Div className={'DCPhoneNumberWrapper--OTPField'}>
                    <Input
                        name="number"
                        type="number"
                        placeholder={'Your OTP'}
                        required={true}
                        value={otp}
                        onChange={handleOTPChange}
                        min={'000000'}
                        max={'999999'}
                    />
                    <Div className="LPIFSIFBFMIcon">
                        <DCResetPassword />
                    </Div>
                </Div>
            )}
            <Button
                buttonClass={'LPIFSIFBFSBtnLoad  LPIFSIFBFSBtnLoad--OTPField'}
                fullWidth
                buttonType={'BlueFillBtn'}
                onClick={() => {
                    if (showOTP) {
                        handleSubmit({ code: otp });
                    } else {
                        handleSubmit({ phone_number: phoneNumber });
                    }
                }}
                disabled={isDisabled}
                loading={loading}
            >
                {showOTP ? `Submit OTP` : `Send OTP`}
            </Button>
        </Div>
    );
};
export default ForgetUsername;
