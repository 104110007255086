import React from 'react';

import { SVGIcon } from '../../UIKit/index';

export default function DCFilledWatch(props) {
    return (
        <SVGIcon {...props} viewBox="0 0 512 512">
            <g>
                <circle cx="256" cy="256.3" r="253.5" />
                <path
                    style={{ fill: '#FFFFFF' }}
                    d="M332.2,250.7H252v-111c0-10.2-8.3-18.5-18.5-18.5s-18.5,8.3-18.5,18.5v129.6c0,10.2,8.3,18.5,18.5,18.5h98.7
		            c10.2,0,18.5-8.3,18.5-18.5S342.4,250.7,332.2,250.7L332.2,250.7L332.2,250.7z"
                />
            </g>
        </SVGIcon>
    );
}
