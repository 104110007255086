import React from 'react';
import PropTypes from 'prop-types';
import DCNoDataIcon from '../../Assets/icons/DCNoData';
import { Div } from '../index';

import './style.scss';

const NoData = ({
    className,
    icon,
    title,
    description,
    button,
    type,
    noIcon = false,
    style,
    center = false,
    dataId,
}) => (
    <Div
        style={{ ...style }}
        className={'NoData ' + (className ? className : '') + `${center ? 'center' : ''}`}
        dataId={dataId}
    >
        <Div className="NoDataInner">
            {!noIcon && (
                <Div className={'NDIIcon ' + (type ? 'NDIISize' + type : '')}>
                    {icon || <DCNoDataIcon />}
                </Div>
            )}
            <Div className={'NDITitle ' + (type ? 'NDIISize' + type : '')}>
                {title || 'No Data'}
            </Div>
            {description && (
                <Div className={'NDIDes ' + (type ? 'NDIISize' + type : '')}>{description}</Div>
            )}
            {button && <Div className="NDIButton">{button}</Div>}
        </Div>
    </Div>
);

NoData.propTypes = {
    className: PropTypes.string,
    icon: PropTypes.element,
    title: PropTypes.string,
    description: PropTypes.string,
    button: PropTypes.element || null,
    type: PropTypes.string,
    style: PropTypes.any,
    center: PropTypes.bool,
    dataId: PropTypes.string || null,
};

export default NoData;

// type
// small
