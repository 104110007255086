import React from 'react';

import { SVGIcon } from '../../UIKit/index';

export default function DCDateCal(props) {
    return (
        <SVGIcon {...props} viewBox="0 0 464 512">
            <g transform="translate(-25)">
                <path
                    fill={'#FD646F'}
                    d="M488.5,82.26v79.15H28.89V82.26c0-17.03,13.81-30.84,30.84-30.84h397.91   C474.68,51.42,488.49,65.22,488.5,82.26L488.5,82.26z"
                />
                <path
                    fill="#FC4755"
                    d="M152.26,51.41v57.58c-0.01,5.66-4.6,10.25-10.26,10.26h-47.3c-5.67,0-10.27-4.59-10.28-10.26   V51.41H152.26z"
                />
                <path
                    fill="#FC4755"
                    d="M416.51,51.41v57.58c-0.01,5.66-4.6,10.25-10.26,10.26h-47.3c-5.67,0-10.27-4.59-10.28-10.26   V51.41H416.51z"
                />
                <path
                    fill="#FC4755"
                    d="M45.34,54.98v106.45H28.89V82.28C28.88,70.83,35.21,60.32,45.34,54.98z"
                />
                <g transform="translate(34.102 25.631)">
                    <path
                        fill="#E6E6E6"
                        d="M79.1,197.31H132c2.84,0,5.14,2.3,5.14,5.14l0,0v52.9c0,2.84-2.3,5.14-5.14,5.14h0H79.1    c-2.84,0-5.14-2.3-5.14-5.14l0,0v-52.9C73.96,199.61,76.26,197.31,79.1,197.31L79.1,197.31z"
                    />
                    <path
                        fill="#E6E6E6"
                        d="M79.1,275.91H132c2.84,0,5.14,2.3,5.14,5.14l0,0v52.91c0,2.84-2.3,5.14-5.14,5.14h0H79.1    c-2.84,0-5.14-2.3-5.14-5.14l0,0v-52.91C73.96,278.21,76.26,275.91,79.1,275.91L79.1,275.91z"
                    />
                    <path
                        fill="#E6E6E6"
                        d="M79.1,354.53H132c2.84,0,5.14,2.3,5.14,5.14l0,0v52.87c0,2.84-2.3,5.14-5.14,5.14h0H79.1    c-2.84,0-5.14-2.3-5.14-5.14l0,0v-52.9C73.98,356.81,76.27,354.53,79.1,354.53z"
                    />
                    <path
                        fill="#E6E6E6"
                        d="M157.7,197.31h54.03c2.84,0,5.14,2.3,5.14,5.14l0,0v52.9c0,2.84-2.3,5.14-5.14,5.14l0,0H157.7    c-2.84,0-5.14-2.3-5.14-5.14l0,0v-52.9C152.56,199.61,154.87,197.31,157.7,197.31L157.7,197.31z"
                    />
                    <path
                        fill="#E6E6E6"
                        d="M157.7,275.91h54.03c2.84,0,5.14,2.3,5.14,5.14l0,0v52.91c0,2.84-2.3,5.14-5.14,5.14l0,0H157.7    c-2.84,0-5.14-2.3-5.14-5.14l0,0v-52.91C152.56,278.21,154.87,275.91,157.7,275.91L157.7,275.91z"
                    />
                    <path
                        fill="#E6E6E6"
                        d="M157.7,354.53h54.03c2.84,0,5.14,2.3,5.14,5.14l0,0v52.87c0,2.84-2.3,5.14-5.14,5.14l0,0H157.7    c-2.84,0-5.14-2.3-5.14-5.14l0,0v-52.9C152.58,356.81,154.88,354.53,157.7,354.53z"
                    />
                    <path
                        fill="#E6E6E6"
                        d="M237.45,197.31h54.01c2.84,0,5.14,2.3,5.14,5.14l0,0v52.9c0,2.84-2.3,5.14-5.14,5.14l0,0    h-54.03c-2.84,0-5.14-2.3-5.14-5.14l0,0v-52.9c0-2.84,2.3-5.14,5.14-5.14C237.43,197.31,237.44,197.31,237.45,197.31z"
                    />
                    <path
                        fill="#E6E6E6"
                        d="M237.45,275.91h54.01c2.84,0,5.14,2.3,5.14,5.14l0,0v52.91c0,2.84-2.3,5.14-5.14,5.14l0,0    h-54.03c-2.84,0-5.14-2.3-5.14-5.14l0,0v-52.91c0-2.84,2.3-5.14,5.14-5.14C237.43,275.91,237.44,275.91,237.45,275.91z"
                    />
                    <path
                        fill="#E6E6E6"
                        d="M237.45,354.53h54.01c2.84,0,5.14,2.3,5.14,5.14l0,0v52.87c0,2.84-2.3,5.14-5.14,5.14l0,0    h-54.03c-2.83-0.01-5.11-2.31-5.11-5.14v-52.9C232.32,356.81,234.62,354.53,237.45,354.53z"
                    />
                    <path
                        fill="#E6E6E6"
                        d="M375.22,202.45v52.9c0,2.84-2.3,5.14-5.14,5.14l0,0h-52.9c-2.84,0-5.14-2.3-5.14-5.14l0,0    v-52.9c0-2.84,2.3-5.14,5.14-5.14l0,0h52.9C372.92,197.31,375.22,199.61,375.22,202.45L375.22,202.45z"
                    />
                    <path
                        fill="#E6E6E6"
                        d="M317.18,275.91h52.9c2.84,0,5.14,2.3,5.14,5.14l0,0v52.91c0,2.84-2.3,5.14-5.14,5.14l0,0h-52.9    c-2.84,0-5.14-2.3-5.14-5.14l0,0v-52.91C312.04,278.21,314.34,275.91,317.18,275.91L317.18,275.91z"
                    />
                    <path
                        fill="#E6E6E6"
                        d="M317.18,354.53h52.9c2.84,0,5.14,2.3,5.14,5.14l0,0v52.87c0,2.84-2.3,5.14-5.14,5.14l0,0h-52.9    c-2.84,0-5.14-2.3-5.14-5.14l0,0v-52.9C312.05,356.81,314.35,354.53,317.18,354.53z"
                    />
                </g>
                <path
                    fill="#FFFFFF"
                    d="M28.89,154.23v325.94c0,17.03,13.81,30.84,30.84,30.84c0,0,0,0,0,0h397.91   c17.03,0,30.84-13.81,30.84-30.84l0,0V154.23H28.89z"
                />
                <path
                    fill="#50758D"
                    d="M152.28,10.28v82.26c0,5.68-4.6,10.28-10.28,10.28h-30.85c-5.68,0-10.28-4.6-10.28-10.28V10.28   c0-5.68,4.6-10.28,10.28-10.28h30.84C147.67,0,152.28,4.6,152.28,10.28z"
                />
                <path fill="#FFAC5C" d="M108.06,222.94h63.18v63.18h-63.18V222.94z" />
                <path fill="#FFAC5C" d="M346.14,222.94h63.18v63.18h-63.18V222.94z" />
                <path fill="#FFAC5C" d="M186.67,222.94h64.31v63.18h-64.31V222.94z" />
                <path fill="#FFAC5C" d="M108.06,301.54h63.18v63.19h-63.18V301.54z" />
                <path fill="#E6E6E6" d="M346.14,301.54h63.18v63.19h-63.18V301.54z" />
                <path fill="#FFAC5C" d="M186.67,301.54h64.31v63.19h-64.31V301.54z" />
                <path fill="#E6E6E6" d="M108.06,380.16h63.18v63.18h-63.18V380.16z" />
                <path fill="#E6E6E6" d="M346.14,380.16h63.18v63.18h-63.18V380.16z" />
                <path fill="#E6E6E6" d="M186.67,380.16h64.31v63.18h-64.31V380.16z" />
                <path fill="#FFAC5C" d="M266.41,222.94h64.31v63.18h-64.31V222.94z" />
                <path fill="#FFAC5C" d="M266.41,301.54h64.31v63.19h-64.31V301.54z" />
                <path fill="#E6E6E6" d="M266.41,380.16h64.31v63.18h-64.31V380.16z" />
                <path
                    fill="#50758D"
                    d="M416.53,10.28v82.26c0,5.68-4.6,10.28-10.28,10.28H375.4c-5.68,0-10.29-4.6-10.29-10.28V10.28   c0-5.68,4.6-10.28,10.28-10.28h30.84C411.92,0,416.52,4.6,416.53,10.28z"
                />
                <path
                    fill="#E6E6E6"
                    d="M28.89,154.23v325.94c0,17.03,13.81,30.84,30.84,30.84c0,0,0,0,0,0h11.39   c-14.64-2.68-25.28-15.44-25.28-30.33V176.05c1.19-3.23,4.27-5.37,7.72-5.37h434.9v-16.45H28.89z"
                />
                <path
                    fill="#2B597F"
                    d="M127.61,102.82h-16.46c-5.68,0-10.28-4.6-10.28-10.28V10.28c0-5.68,4.6-10.28,10.28-10.28h6.17   v92.54C117.32,98.22,121.93,102.82,127.61,102.82z"
                />
                <path
                    fill="#2B597F"
                    d="M391.84,102.82h-16.45c-5.68,0-10.28-4.6-10.28-10.28V10.28c0-5.68,4.6-10.28,10.28-10.28h6.17   v92.54C381.57,98.21,386.17,102.81,391.84,102.82z"
                />
            </g>
        </SVGIcon>
    );
}
