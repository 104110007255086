import React, { useLayoutEffect, useRef, useState } from 'react';
import _ from 'lodash';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { formatNumberString } from '../../Utils/index';
import { Div } from '../../UIKit/index';
import PropTypes from 'prop-types';
import './style.scss';

const HorizontalColumnChart = ({
    className,
    title,
    headerOther,
    subHeader,
    xAxisData,
    yAxisLabel,
    chartData,
    seriesLabels,
    columnWidth,
    height,
    tooltipContent,
}) => {
    const boxWidthRef = useRef();
    const [boxWidth, setWidth] = useState(null);

    const ChartOptions = {
        chart: {
            type: 'bar',
            height: height || 500,
            width: boxWidth,
        },
        title: {
            text: '',
        },
        subtitle: {
            text: '',
        },
        caption: {
            text: '',
        },
        tooltip: {
            backgroundColor: {
                linearGradient: [0, 0, 0, 60],
                stops: [
                    [0, '#FFFFFF'],
                    [1, '#E0E0E0'],
                ],
            },
            borderWidth: 1,
            borderColor: '#AAA',
            formatter: function () {
                switch (tooltipContent) {
                    case 'reviewSummery':
                        return 'Total of <b>' + formatNumberString(this.y) + '</b> reviews';

                    default:
                        return (
                            '<b>' +
                            formatNumberString(this.y) +
                            '</b> visitors have used <b>' +
                            this.x +
                            '</b> to view your site'
                        );
                }
            },
        },
        xAxis: {
            categories: xAxisData,
            labels: {
                enabled: yAxisLabel,
                useHTML: true,
                format: '{value}',
            },
            title: {
                text: null,
            },
        },
        yAxis: {
            min: 0,
            max: _.uniq(_.map(chartData[0].data, 'y')).length === 1 ? 200 : null,
            labels: {
                enabled: yAxisLabel,
                useHTML: true,
                rotation: -40,
                format: '{value}',
                formatter: function () {
                    return formatNumberString(this.value);
                },
            },
            title: {
                text: null,
            },
        },
        plotOptions: {
            series: {
                stacking: 'normal',
                minPointLength: 3,
            },
            bar: {
                dataLabels: {
                    enabled: false,
                },
            },
        },
        legend: {
            enabled: false,
        },
        credits: {
            enabled: false,
        },
        series: chartData,
    };

    useLayoutEffect(() => {
        /**
         * To measure box height, Using ref [useRef hooks] object to measure height and
         * setting it into states which is being used to set height of infinite scroll bar
         */
        function updateSize() {
            if (boxWidthRef.current) {
                const tempWidth = xAxisData?.length * columnWidth || 150;
                if (tempWidth > boxWidthRef.current.clientWidth) {
                    setWidth(tempWidth);
                } else {
                    setWidth(boxWidthRef.current.clientWidth);
                }
            }
        }
        window.addEventListener('resize', updateSize);
        updateSize();
        return () => window.removeEventListener('resize', updateSize);
    }, [xAxisData, chartData, columnWidth]);

    return (
        <Div
            className={'ColumnChart HorizontalColumnChart' + (className ? className : '')}
            reference={boxWidthRef}
        >
            {title ||
                (headerOther && (
                    <Div className={'ColumnChartHead'}>
                        <Div className={'CCHTitle'}>{title}</Div>
                        {headerOther && <Div className={'CCHOptions'}>{headerOther}</Div>}
                    </Div>
                ))}
            {subHeader && subHeader}
            <Div className={'ColumnChartGraph'} style={{ width: boxWidth }}>
                <HighchartsReact highcharts={Highcharts} options={ChartOptions} />
            </Div>
            {seriesLabels === true ? (
                <Div className={'ChartSeries'}>
                    {chartData.map((item, index) => (
                        <Div className={'CSItem'} key={index}>
                            {item.color && (
                                <Div
                                    className={'CSIDot'}
                                    style={{
                                        backgroundColor: item.color,
                                        boxShadow: `0 0 5px  ${item.color}`,
                                    }}
                                />
                            )}
                            <Div className={'CSITitle'}>{item.name}</Div>
                        </Div>
                    ))}
                </Div>
            ) : null}
        </Div>
    );
};

HorizontalColumnChart.propTypes = {
    className: PropTypes.string,
    title: PropTypes.string,
    headerOther: PropTypes.string,
    subHeader: PropTypes.string,
    xAxisData: PropTypes.arrayOf(PropTypes.string),
    yAxisLabel: PropTypes.bool,
    chartData: PropTypes.arrayOf(PropTypes.object).isRequired,
    seriesLabels: PropTypes.bool,
    columnWidth: PropTypes.number,
    height: PropTypes.number,
    tooltipContent: PropTypes.string,
};

export default HorizontalColumnChart;
