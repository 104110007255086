import React from 'react';

import { SVGIcon } from '../../UIKit/index';

export default function DCDateCheck(props) {
    return (
        <SVGIcon {...props} viewBox="0 0 512 512">
            <path d="M456.45,54.75h-54.77v-36.5C401.69,8.17,393.52,0,383.44,0s-18.25,8.17-18.25,18.25v36.5h-219    v-36.5C146.19,8.17,138.02,0,127.94,0s-18.25,8.17-18.25,18.25v36.5H54.94C24.71,54.75,0.2,79.26,0.2,109.5c0,0.01,0,0.01,0,0.02    v346.75c0,30.23,24.51,54.74,54.74,54.74c0,0,0,0,0,0h401.51c30.23,0,54.74-24.51,54.74-54.74v0V109.52    c0.01-30.23-24.49-54.75-54.72-54.77C456.47,54.75,456.46,54.75,456.45,54.75z M474.69,456.25c0,10.07-8.17,18.24-18.24,18.24    H54.94c-10.07,0-18.24-8.17-18.24-18.24V219.01h437.99L474.69,456.25z M474.69,182.5H36.7v-72.98c0-10.07,8.17-18.24,18.24-18.24    l0,0h54.77v36.48c0,10.08,8.17,18.25,18.25,18.25s18.25-8.17,18.25-18.25v-36.5h219v36.5c0,10.08,8.17,18.25,18.25,18.25    c10.08,0,18.25-8.17,18.25-18.25v-36.5h54.74c10.07,0,18.24,8.17,18.24,18.24C474.69,109.5,474.69,182.5,474.69,182.5z" />
            <path d="M371.89,249.36c-7.07-6.83-18.28-6.83-25.35,0l-133.1,133.1l-59.99-59.99    c-7.2-7.04-18.75-6.92-25.79,0.28c-6.94,7.09-6.94,18.42,0,25.51l73.01,73.01c7.12,7.12,18.67,7.12,25.79,0l145.99-145.99    c7.01-7.24,6.82-18.78-0.41-25.79C371.98,249.45,371.93,249.4,371.89,249.36z" />
        </SVGIcon>
    );
}
