import React from 'react';

import { SVGIcon } from '../../UIKit/index';

export default function DCExport(props) {
    return (
        <SVGIcon {...props} viewBox="0 0 24 24">
            <path d="m12 20c-.068 0-.136-.014-.199-.042l-11.5-5c-.183-.079-.301-.259-.301-.458s.118-.379.3-.458l6.5-2.83c.253-.111.548.006.658.259s-.006.548-.259.658l-5.446 2.371 10.247 4.455 10.247-4.455-5.447-2.372c-.253-.11-.369-.405-.259-.658s.403-.371.658-.259l6.5 2.83c.182.08.3.26.3.458 0 .199-.118.379-.301.458l-11.5 5c-.062.029-.13.043-.198.043z" />
            <path d="m12 24c-.068 0-.136-.014-.199-.042l-11.5-5c-.183-.079-.301-.259-.301-.458v-4c0-.276.224-.5.5-.5s.5.224.5.5v3.672l11 4.782 11-4.782v-3.672c0-.276.224-.5.5-.5s.5.224.5.5v4c0 .199-.118.379-.301.458l-11.5 5c-.063.028-.131.042-.199.042z" />
            <path d="m12 24c-.276 0-.5-.224-.5-.5v-4c0-.276.224-.5.5-.5s.5.224.5.5v4c0 .276-.224.5-.5.5z" />
            <path d="m13.5 14h-3c-.551 0-1-.449-1-1v-6.5h-3c-.202 0-.385-.122-.462-.309-.078-.187-.035-.401.108-.545l5.5-5.5c.195-.195.512-.195.707 0l5.5 5.5c.143.143.186.358.108.545-.076.187-.259.309-.461.309h-3v6.5c0 .551-.449 1-1 1zm-5.793-8.5h2.293c.276 0 .5.224.5.5v7h3v-7c0-.276.224-.5.5-.5h2.293l-4.293-4.293z" />
        </SVGIcon>
    );
}
