import React, { Fragment, useRef, useState } from 'react';
import _ from 'lodash';
import { Popover, IconButton, Badge, Div, Image } from 'UIKit/index';
import DCCheckout from 'Assets/icons/DCCheckout';
import DCAddIcon from 'Assets/icons/DCAddIcon';
import DCMinus from 'Assets/icons/DCMinus';
import DCCrossClose from 'Assets/icons/DCCrossClose';
import './style.scss';
import { useQuery } from 'react-query';
import { getCart } from 'apis/store/index.api';
import { formatNumberString } from 'Utils';
import usePubSub from 'Hooks/PubSub';
import Checkout from './Checkout';
import useToast from 'Modules/Toasts/useToast';
import { getAccountType, isAuthenticated } from 'Utils/auth';
import useMediaQuery from 'UIKit/useMediaQuery';
import areValidScopes from 'Utils/scopeUtils';
import AccessScopes from 'Constants/scopes';

const CartPopover = () => {
    const matche550 = useMediaQuery('(max-width: 550px)');
    const { publish } = usePubSub();
    const cartBtnRef = useRef();
    const { showToast } = useToast();

    const [cartPopoverState, setCartPopoverState] = useState(false);

    const { data } = useQuery(
        ['cart'],
        () => getCart().then(res => {
                return {
                    all: res.data.cart,
                    cart: _.groupBy(
                        _.sortBy(res.data.cart, ['buyer.name', 'item.name']),
                        'buyer.id',
                    ),
                    totals: res.data.totals,
                };
            }),
        {
            retry: getAccountType() === 'main' ? 3 : false,
            staleTime: 1000 * 60 * 5, // 10 minutes
            cacheTime: 1000 * 60 * 5, // 15 minutes
            keepPreviousData: true,
            disabled: async () => areValidScopes(AccessScopes.root.STORE.text),
            enabled: isAuthenticated(),
        },
    );

    const handleCartPopoverOpen = () => {
        const sum = _.sumBy(data?.all, 'item.qty');
        if (sum > 0) {
            setCartPopoverState(prev => !prev);
        } else {
            showToast({ message: 'Cart is empty!', type: 'warning' });
        }
    };

    const handleCartPopoverClose = () => {
        setCartPopoverState(false);
    };

    if (_.sumBy(data?.all, 'item.qty') > 0) {
        return (
            <Fragment>
                <IconButton
                    customClass="CartIconButton"
                    onClick={handleCartPopoverOpen}
                    reference={cartBtnRef}
                >
                    <Badge
                        badgeContent={_.sumBy(data?.all, 'item.qty') || 0}
                        customClass="CartBadge"
                    >
                        <DCCheckout />
                    </Badge>
                </IconButton>
                {!document.querySelector('.HeaderBar') && (
                    <Popover
                        open={cartPopoverState}
                        anchorEl={cartBtnRef}
                        onClose={handleCartPopoverClose}
                        placement={matche550 ? 'no-placement' : 'bottom-end'}
                        width={matche550 ? 345 : 350}
                        maxHeight={350}
                        withOutPadding={true}
                        customClass={'CartPopover ArrowPopover'}
                    >
                        <Div className="CartPopoverTitle">Your Cart</Div>
                        <Div className="CartItemGroupMain">
                            {data &&
                                data.cart &&
                                Object.keys(data.cart).map((key, index) => {
                                    return (
                                        <Div
                                            className="CartItemGroup"
                                            key={`cart-${index + 1}-${key}`}
                                        >
                                            <Div className="CIGTitle">
                                                {data.cart[key][0].buyer.name}
                                            </Div>
                                            {data.cart[key].map((product, _index) => {
                                                return (
                                                    <Div className="CIGItem" key={_index + 1}>
                                                        <Div className="CIGIIcon">
                                                            <Image
                                                                src={product.item.image}
                                                                displayName={''}
                                                            />
                                                        </Div>
                                                        <Div className="CIGITitle">
                                                            {product.item.name}
                                                        </Div>

                                                        {product.item.transaction_type ===
                                                            'downgrade' && (
                                                            <Div
                                                                className="CIGIDownUp"
                                                                style={{
                                                                    backgroundColor: '#f44336',
                                                                }}
                                                            >
                                                                Downgrade
                                                            </Div>
                                                        )}
                                                        {product.item.transaction_type ===
                                                            'upgrade' && (
                                                            <Div
                                                                className="CIGIDownUp"
                                                                style={{
                                                                    backgroundColor: '#4caf50',
                                                                }}
                                                            >
                                                                Upgrade
                                                            </Div>
                                                        )}
                                                        {product.item.locked ||
                                                        product.item.phone ? (
                                                            <Div
                                                                className="CIGIDownUp"
                                                                style={{
                                                                    backgroundColor: '#4caf50',
                                                                }}
                                                            >
                                                                {product.item.phone
                                                                    ? `Phone Number`
                                                                    : `One per line item`}
                                                            </Div>
                                                        ) : (
                                                            product.item.transaction_type ===
                                                                'new' && (
                                                                <Div className="CIGIQun">
                                                                    <IconButton
                                                                        customClass={'CIGIQBtn'}
                                                                        onClick={() => {
                                                                            publish('update-cart', {
                                                                                business:
                                                                                    product.buyer
                                                                                        .id,
                                                                                price: product.item
                                                                                    .price_id,
                                                                                qty:
                                                                                    product.item
                                                                                        .qty - 1,
                                                                                increment: false,
                                                                            });
                                                                        }}
                                                                    >
                                                                        <DCMinus />
                                                                    </IconButton>
                                                                    <Div className="CIGIQunNum">
                                                                        {product.item.qty}
                                                                    </Div>
                                                                    <IconButton
                                                                        customClass={'CIGIQBtn'}
                                                                        onClick={() => {
                                                                            publish('update-cart', {
                                                                                business:
                                                                                    product.buyer
                                                                                        .id,
                                                                                price: product.item
                                                                                    .price_id,
                                                                                qty:
                                                                                    product.item
                                                                                        .qty + 1,
                                                                                increment: true,
                                                                            });
                                                                        }}
                                                                    >
                                                                        <DCAddIcon />
                                                                    </IconButton>
                                                                </Div>
                                                            )
                                                        )}

                                                        <IconButton
                                                            buttonType={'circleBtn'}
                                                            customClass="CIGIRemove"
                                                            onClick={() => {
                                                                publish('update-cart', {
                                                                    business: product.buyer.id,
                                                                    price: product.item.price_id,
                                                                    subscription:
                                                                        product.item
                                                                            .subscription_id,
                                                                    qty: 0,
                                                                    increment: false,
                                                                });
                                                            }}
                                                        >
                                                            <DCCrossClose />
                                                        </IconButton>

                                                        <Div className="CIGIPrice">
                                                            $
                                                            {formatNumberString(
                                                                ((product.item.amount +
                                                                    (product.item.setup_amount
                                                                        ? product.item.setup_amount
                                                                        : 0)) *
                                                                    product.item.qty) /
                                                                    100,
                                                            )}
                                                        </Div>
                                                    </Div>
                                                );
                                            })}
                                        </Div>
                                    );
                                })}
                        </Div>
                        <Checkout onClick={handleCartPopoverClose} />
                    </Popover>
                )}
            </Fragment>
        );
    } else {
        return <></>;
    }
};

export default CartPopover;
