import React from 'react';

import { SVGIcon } from '../../UIKit/index';

export default function DCNoResultFound(props) {
    return (
        <SVGIcon {...props} viewBox="0 0 180.858 173.954">
            <g transform="translate(-279.862 -399.3)">
                <ellipse
                    cx="78.324"
                    cy="14.521"
                    rx="78.324"
                    ry="14.521"
                    transform="translate(304.071 544.213)"
                    fill="#f5f5f5"
                />
                <g transform="translate(294.469 399.3)">
                    <g
                        transform="matrix(0.839, -0.545, 0.545, 0.839, 78.335, 110.535)"
                        fill="#fff"
                        stroke="#405d63"
                        strokeWidth="2"
                    >
                        <rect width="27.155" height="67.48" rx="13.578" stroke="none" />
                        <rect x="1" y="1" width="25.155" height="65.48" rx="12.578" fill="none" />
                    </g>
                    <g transform="translate(0 0)" fill="#fff" stroke="#405d63" strokeWidth="2">
                        <circle cx="62.289" cy="62.289" r="62.289" stroke="none" />
                        <circle cx="62.289" cy="62.289" r="61.289" fill="none" />
                    </g>
                    <g
                        transform="translate(15.194 15.976)"
                        fill="#fff"
                        stroke="#405d63"
                        strokeWidth="2"
                    >
                        <circle cx="46.717" cy="46.717" r="46.717" stroke="none" />
                        <circle cx="46.717" cy="46.717" r="45.717" fill="none" />
                    </g>
                </g>
                <g transform="translate(450.453 421.25) rotate(180)" opacity="0.5">
                    <rect
                        width="3.634"
                        height="21.949"
                        rx="1.817"
                        transform="translate(21.949 9.158) rotate(90)"
                        fill="#c3c5c4"
                    />
                    <rect
                        width="3.634"
                        height="21.949"
                        rx="1.817"
                        transform="translate(12.791 21.949) rotate(180)"
                        fill="#c3c5c4"
                    />
                </g>
                <g transform="translate(340.825 532.108)">
                    <g
                        transform="translate(24.209 24.209) rotate(180)"
                        fill="#fff"
                        stroke="#c3c5c4"
                        strokeWidth="1"
                    >
                        <circle cx="12.105" cy="12.105" r="12.105" stroke="none" />
                        <circle cx="12.105" cy="12.105" r="11.605" fill="none" />
                    </g>
                    <g transform="translate(17.083 17.095) rotate(180)">
                        <g
                            transform="translate(3.821 0)"
                            fill="#fff"
                            stroke="#c3c5c4"
                            strokeWidth="1"
                        >
                            <rect width="1.91" height="9.552" rx="0.955" stroke="none" />
                            <rect
                                x="0.5"
                                y="0.5"
                                width="0.91"
                                height="8.552"
                                rx="0.455"
                                fill="none"
                            />
                        </g>
                        <g
                            transform="translate(9.552 3.821) rotate(90)"
                            fill="#fff"
                            stroke="#c3c5c4"
                            strokeWidth="1"
                        >
                            <rect width="1.91" height="9.552" rx="0.955" stroke="none" />
                            <rect
                                x="0.5"
                                y="0.5"
                                width="0.91"
                                height="8.552"
                                rx="0.455"
                                fill="none"
                            />
                        </g>
                    </g>
                </g>
                <g
                    transform="translate(460.422 456.276) rotate(180)"
                    fill="#fff"
                    stroke="#c3c5c4"
                    strokeWidth="1.6"
                    opacity="0.5"
                >
                    <circle cx="9.969" cy="9.969" r="9.969" stroke="none" />
                    <circle cx="9.969" cy="9.969" r="9.169" fill="none" />
                </g>
                <g
                    transform="translate(299.799 533.412) rotate(180)"
                    fill="#fff"
                    stroke="#c3c5c4"
                    strokeWidth="1.6"
                    opacity="0.5"
                >
                    <circle cx="9.969" cy="9.969" r="9.969" stroke="none" />
                    <circle cx="9.969" cy="9.969" r="9.169" fill="none" />
                </g>
                <g transform="translate(421.253 489.265)">
                    <g
                        transform="translate(10.838 0) rotate(20)"
                        fill="#f5f5f5"
                        stroke="#707070"
                        strokeWidth="1.6"
                    >
                        <rect width="26.412" height="29.366" rx="3" stroke="none" />
                        <rect x="0.8" y="0.8" width="24.812" height="27.766" rx="2.2" fill="none" />
                    </g>
                    <g
                        transform="translate(13.369 10.348) rotate(20)"
                        fill="#707070"
                        stroke="#f5f5f5"
                        strokeWidth="0.3"
                    >
                        <rect width="13.763" height="2.39" rx="1" stroke="none" />
                        <rect
                            x="0.15"
                            y="0.15"
                            width="13.463"
                            height="2.09"
                            rx="0.85"
                            fill="none"
                        />
                    </g>
                    <g
                        transform="translate(11.174 19.128) rotate(20)"
                        fill="#707070"
                        stroke="#f5f5f5"
                        strokeWidth="0.3"
                    >
                        <rect width="13.365" height="2.39" rx="1" stroke="none" />
                        <rect
                            x="0.15"
                            y="0.15"
                            width="13.065"
                            height="2.09"
                            rx="0.85"
                            fill="none"
                        />
                    </g>
                </g>
                <g transform="translate(2.733 -3.158)">
                    <path
                        d="M10397.417,1063.546l9.988,6.8-9.988,5.832"
                        transform="translate(-10068.486 -605.111)"
                        fill="none"
                        stroke="#405d63"
                        strokeWidth="2"
                    />
                    <path
                        d="M0,0,9.988,6.8,0,12.636"
                        transform="translate(379.791 471.072) rotate(180)"
                        fill="none"
                        stroke="#405d63"
                        strokeWidth="2"
                    />
                </g>
            </g>
        </SVGIcon>
    );
}
