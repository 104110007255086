import React from 'react';

import { SVGIcon } from '../../UIKit/index';

export default function DCCompanies(props) {
    return (
        <SVGIcon {...props} viewBox="0 0 95.033 108.799">
            <g transform="translate(-667 -300)">
                <g transform="translate(667 300)">
                    <path
                        d="M110.375,91.49,82,108.018l30.307,17.511,28.41-16.4Z"
                        transform="translate(-82 -75.568)"
                        fill="#f9f9f9"
                    />
                    <path
                        d="M105.369,123.481V94.113l30.307,17.5v75.651l-27.245-15.73h0L82,156.271V109.989Z"
                        transform="translate(-82 -77.572)"
                        fill="#f2f2f2"
                    />
                    <path
                        d="M337.968,98.757l-28.41,16.4V190.81l28.41-16.4Z"
                        transform="translate(-255.881 -81.121)"
                        fill="#e6e7e8"
                    />
                    <path
                        d="M209.447,23.989,181.072,40.53l30.307,17.5,28.41-16.4Z"
                        transform="translate(-157.703 -23.989)"
                        fill="#f9f4ef"
                    />
                    <g transform="translate(25.861 1.409)">
                        <path
                            d="M243.643,47.207l1.852-1.069L217.664,29.961,191.638,45.133l1.849,1.067,24.177-14.094Z"
                            transform="translate(-191.638 -29.961)"
                            fill="#a3bad5"
                        />
                        <path
                            d="M249.633,54.156l-25.98-15.1L199.477,53.15l25.95,14.982Z"
                            transform="translate(-197.627 -36.911)"
                            fill="#b9d1ee"
                        />
                    </g>
                    <g transform="translate(58.681 19.093)">
                        <path
                            d="M390.208,104.932l-8.81,5.086v75.651l8.81-5.086Z"
                            transform="translate(-369.457 -104.932)"
                            fill="#d9eaff"
                        />
                        <path
                            d="M339.582,134.515l-8.81,5.086v75.651l8.81-5.086Z"
                            transform="translate(-330.772 -127.537)"
                            fill="#d9eaff"
                        />
                        <g transform="translate(1.717 5.184)">
                            <g>
                                <g transform="translate(0 6.946)">
                                    <path
                                        d="M343.427,156.354l-5.376,3.1v9.15l5.376-3.1Z"
                                        transform="translate(-338.051 -156.354)"
                                        fill="#fff"
                                    />
                                    <g transform="translate(0.537 0.827)">
                                        <path
                                            d="M344.358,160.016l.27-.156v8.116l-4.3,2.484v-.252l4.033-2.328Z"
                                            transform="translate(-340.326 -159.86)"
                                            fill="#e5dbcf"
                                        />
                                        <path
                                            d="M344.358,160.521v7.864l-4.033,2.328v-7.864Z"
                                            transform="translate(-340.326 -160.365)"
                                            fill="#2b88f8"
                                        />
                                    </g>
                                </g>
                                <g transform="translate(12.077)">
                                    <path
                                        d="M394.625,126.909l-5.376,3.1v9.15l5.376-3.1Z"
                                        transform="translate(-389.249 -126.909)"
                                        fill="#fff"
                                    />
                                    <g transform="translate(0.537 0.827)">
                                        <path
                                            d="M395.557,130.571l.27-.156v8.116l-4.3,2.484v-.252l4.033-2.328Z"
                                            transform="translate(-391.524 -130.415)"
                                            fill="#e5dbcf"
                                        />
                                        <path
                                            d="M395.557,131.076v7.864l-4.033,2.328V133.4Z"
                                            transform="translate(-391.524 -130.92)"
                                            fill="#2b88f8"
                                        />
                                    </g>
                                </g>
                            </g>
                            <g transform="translate(0 14.72)">
                                <g transform="translate(0 6.945)">
                                    <path
                                        d="M343.427,218.76l-5.376,3.1v9.15l5.376-3.1Z"
                                        transform="translate(-338.051 -218.76)"
                                        fill="#fff"
                                    />
                                    <g transform="translate(0.537 0.827)">
                                        <path
                                            d="M344.358,222.422l.27-.156v8.116l-4.3,2.484v-.252l4.033-2.328Z"
                                            transform="translate(-340.326 -222.266)"
                                            fill="#e5dbcf"
                                        />
                                        <path
                                            d="M344.358,222.927v7.864l-4.033,2.328v-7.864Z"
                                            transform="translate(-340.326 -222.771)"
                                            fill="#2b88f8"
                                        />
                                    </g>
                                </g>
                                <g transform="translate(12.077)">
                                    <path
                                        d="M394.625,189.315l-5.376,3.1v9.15l5.376-3.1Z"
                                        transform="translate(-389.249 -189.315)"
                                        fill="#fff"
                                    />
                                    <g transform="translate(0.537 0.827)">
                                        <path
                                            d="M395.557,192.977l.27-.156v8.116l-4.3,2.484v-.252l4.033-2.328Z"
                                            transform="translate(-391.524 -192.821)"
                                            fill="#e5dbcf"
                                        />
                                        <path
                                            d="M395.557,193.482v7.864l-4.033,2.328V195.81Z"
                                            transform="translate(-391.524 -193.326)"
                                            fill="#2b88f8"
                                        />
                                    </g>
                                </g>
                            </g>
                            <g transform="translate(0 29.394)">
                                <g transform="translate(0 6.945)">
                                    <path
                                        d="M343.427,280.969l-5.376,3.1v9.15l5.376-3.1Z"
                                        transform="translate(-338.051 -280.969)"
                                        fill="#fff"
                                    />
                                    <g transform="translate(0.537 0.827)">
                                        <path
                                            d="M344.358,284.63l.27-.156v8.116l-4.3,2.484v-.253l4.033-2.328Z"
                                            transform="translate(-340.326 -284.475)"
                                            fill="#e5dbcf"
                                        />
                                        <path
                                            d="M344.358,285.135V293l-4.033,2.328v-7.864Z"
                                            transform="translate(-340.326 -284.98)"
                                            fill="#2b88f8"
                                        />
                                    </g>
                                </g>
                                <g transform="translate(12.077)">
                                    <path
                                        d="M394.625,251.524l-5.376,3.1v9.15l5.376-3.1Z"
                                        transform="translate(-389.249 -251.524)"
                                        fill="#fff"
                                    />
                                    <g transform="translate(0.537 0.827)">
                                        <path
                                            d="M395.557,255.185l.27-.156v8.116l-4.3,2.484v-.253l4.033-2.328Z"
                                            transform="translate(-391.524 -255.03)"
                                            fill="#e5dbcf"
                                        />
                                        <path
                                            d="M395.557,255.691v7.864l-4.033,2.328v-7.864Z"
                                            transform="translate(-391.524 -255.535)"
                                            fill="#2b88f8"
                                        />
                                    </g>
                                </g>
                            </g>
                            <g transform="translate(0 44.093)">
                                <g transform="translate(0 6.945)">
                                    <path
                                        d="M343.427,343.282l-5.376,3.1v9.15l5.376-3.1Z"
                                        transform="translate(-338.051 -343.282)"
                                        fill="#fff"
                                    />
                                    <g transform="translate(0.537 0.827)">
                                        <path
                                            d="M344.358,346.944l.27-.156V354.9l-4.3,2.484v-.252l4.033-2.328Z"
                                            transform="translate(-340.326 -346.788)"
                                            fill="#e5dbcf"
                                        />
                                        <path
                                            d="M344.358,347.45v7.864l-4.033,2.328v-7.864Z"
                                            transform="translate(-340.326 -347.294)"
                                            fill="#2b88f8"
                                        />
                                    </g>
                                </g>
                                <g transform="translate(12.077)">
                                    <path
                                        d="M394.625,313.838l-5.376,3.1v9.15l5.376-3.1Z"
                                        transform="translate(-389.249 -313.838)"
                                        fill="#fff"
                                    />
                                    <g transform="translate(0.537 0.827)">
                                        <path
                                            d="M395.557,317.5l.27-.156v8.116l-4.3,2.484v-.252l4.033-2.328Z"
                                            transform="translate(-391.524 -317.343)"
                                            fill="#e5dbcf"
                                        />
                                        <path
                                            d="M395.557,318v7.864l-4.033,2.328v-7.864Z"
                                            transform="translate(-391.524 -317.849)"
                                            fill="#2b88f8"
                                        />
                                    </g>
                                </g>
                            </g>
                            <g transform="translate(0 58.691)">
                                <g transform="translate(0 6.945)">
                                    <path
                                        d="M343.427,405.171l-5.376,3.1v9.15l5.376-3.1Z"
                                        transform="translate(-338.051 -405.171)"
                                        fill="#fff"
                                    />
                                    <g transform="translate(0.537 0.827)">
                                        <path
                                            d="M344.358,408.833l.27-.156v8.116l-4.3,2.484v-.252l4.033-2.328Z"
                                            transform="translate(-340.326 -408.677)"
                                            fill="#e5dbcf"
                                        />
                                        <path
                                            d="M344.358,409.338V417.2l-4.033,2.328v-7.864Z"
                                            transform="translate(-340.326 -409.182)"
                                            fill="#2b88f8"
                                        />
                                    </g>
                                </g>
                                <g transform="translate(12.077)">
                                    <path
                                        d="M394.625,375.726l-5.376,3.1v9.15l5.376-3.1Z"
                                        transform="translate(-389.249 -375.726)"
                                        fill="#fff"
                                    />
                                    <g transform="translate(0.537 0.827)">
                                        <path
                                            d="M395.557,379.388l.27-.156v8.116l-4.3,2.484v-.252l4.033-2.328Z"
                                            transform="translate(-391.524 -379.232)"
                                            fill="#e5dbcf"
                                        />
                                        <path
                                            d="M395.557,379.893v7.864l-4.033,2.328v-7.864Z"
                                            transform="translate(-391.524 -379.737)"
                                            fill="#2b88f8"
                                        />
                                    </g>
                                </g>
                            </g>
                        </g>
                    </g>
                    <g transform="translate(27.71 18.232)">
                        <path
                            d="M199.741,104.527l8.512,4.867.033,60.636-8.81-4.1Z"
                            transform="translate(-199.477 -104.932)"
                            fill="#d9eaff"
                        />
                        <path
                            d="M250.351,133.959l8.575,4.951-.014,60.7-8.81-4.1Z"
                            transform="translate(-238.16 -127.537)"
                            fill="#d9eaff"
                        />
                        <g transform="translate(1.582 5.184)">
                            <g>
                                <g transform="translate(12.077 6.946)">
                                    <path
                                        d="M257.38,156.354l5.376,3.1v9.15l-5.376-3.1Z"
                                        transform="translate(-257.38 -156.354)"
                                        fill="#2b88f8"
                                    />
                                    <g transform="translate(0.537 0.827)">
                                        <path
                                            d="M259.926,160.016l-.27-.156v8.116l4.3,2.484v-.252l-4.033-2.328Z"
                                            transform="translate(-259.656 -159.86)"
                                            fill="#2b88f8"
                                        />
                                        <path
                                            d="M260.8,160.521v7.864l4.033,2.328v-7.864Z"
                                            transform="translate(-260.531 -160.365)"
                                            fill="#2b88f8"
                                        />
                                    </g>
                                </g>
                                <g>
                                    <path
                                        d="M206.182,126.909l5.376,3.1v9.15l-5.376-3.1Z"
                                        transform="translate(-206.182 -126.909)"
                                        fill="#2b88f8"
                                    />
                                    <g transform="translate(0.537 0.827)">
                                        <path
                                            d="M208.727,130.571l-.27-.156v8.116l4.3,2.484v-.252l-4.033-2.328Z"
                                            transform="translate(-208.457 -130.415)"
                                            fill="#2b88f8"
                                        />
                                        <path
                                            d="M209.6,131.076v7.864l4.033,2.328V133.4Z"
                                            transform="translate(-209.332 -130.92)"
                                            fill="#2b88f8"
                                        />
                                    </g>
                                </g>
                            </g>
                            <g transform="translate(0 15.008)">
                                <g transform="translate(12.077 6.945)">
                                    <path
                                        d="M257.38,218.76l5.376,3.1v9.15l-5.376-3.1Z"
                                        transform="translate(-257.38 -218.76)"
                                        fill="#2b88f8"
                                    />
                                    <g transform="translate(0.537 0.827)">
                                        <path
                                            d="M259.926,222.422l-.27-.156v8.116l4.3,2.484v-.252l-4.033-2.328Z"
                                            transform="translate(-259.656 -222.266)"
                                            fill="#2b88f8"
                                        />
                                        <path
                                            d="M260.8,222.927v7.864l4.033,2.328v-7.864Z"
                                            transform="translate(-260.531 -222.771)"
                                            fill="#2b88f8"
                                        />
                                    </g>
                                </g>
                                <g>
                                    <path
                                        d="M206.182,189.315l5.376,3.1v9.15l-5.376-3.1Z"
                                        transform="translate(-206.182 -189.315)"
                                        fill="#2b88f8"
                                    />
                                    <g transform="translate(0.537 0.827)">
                                        <path
                                            d="M208.727,192.977l-.27-.156v8.116l4.3,2.484v-.252l-4.033-2.328Z"
                                            transform="translate(-208.457 -192.821)"
                                            fill="#2b88f8"
                                        />
                                        <path
                                            d="M209.6,193.482v7.864l4.033,2.328V195.81Z"
                                            transform="translate(-209.332 -193.326)"
                                            fill="#2b88f8"
                                        />
                                    </g>
                                </g>
                            </g>
                            <g transform="translate(0 29.968)">
                                <g transform="translate(12.077 6.945)">
                                    <path
                                        d="M257.38,280.969l5.376,3.1v9.15l-5.376-3.1Z"
                                        transform="translate(-257.38 -280.969)"
                                        fill="#2b88f8"
                                    />
                                    <g transform="translate(0.537 0.827)">
                                        <path
                                            d="M259.926,284.63l-.27-.156v8.116l4.3,2.484v-.253l-4.033-2.328Z"
                                            transform="translate(-259.656 -284.475)"
                                            fill="#2b88f8"
                                        />
                                        <path
                                            d="M260.8,285.135V293l4.033,2.328v-7.864Z"
                                            transform="translate(-260.531 -284.98)"
                                            fill="#2b88f8"
                                        />
                                    </g>
                                </g>
                                <g>
                                    <path
                                        d="M206.182,251.524l5.376,3.1v9.15l-5.376-3.1Z"
                                        transform="translate(-206.182 -251.524)"
                                        fill="#2b88f8"
                                    />
                                    <g transform="translate(0.537 0.827)">
                                        <path
                                            d="M208.727,255.185l-.27-.156v8.116l4.3,2.484v-.253l-4.033-2.328Z"
                                            transform="translate(-208.457 -255.03)"
                                            fill="#2b88f8"
                                        />
                                        <path
                                            d="M209.6,255.691v7.864l4.033,2.328v-7.864Z"
                                            transform="translate(-209.332 -255.535)"
                                            fill="#2b88f8"
                                        />
                                    </g>
                                </g>
                            </g>
                            <g transform="translate(0 44.954)">
                                <g transform="translate(12.077 6.945)">
                                    <path
                                        d="M257.38,343.282l5.376,3.1v9.15l-5.376-3.1Z"
                                        transform="translate(-257.38 -343.282)"
                                        fill="#2b88f8"
                                    />
                                    <g transform="translate(0.537 0.827)">
                                        <path
                                            d="M259.926,346.944l-.27-.156V354.9l4.3,2.484v-.252l-4.033-2.328Z"
                                            transform="translate(-259.656 -346.788)"
                                            fill="#2b88f8"
                                        />
                                        <path
                                            d="M260.8,347.45v7.864l4.033,2.328v-7.864Z"
                                            transform="translate(-260.531 -347.294)"
                                            fill="#2b88f8"
                                        />
                                    </g>
                                </g>
                                <g>
                                    <path
                                        d="M206.182,313.838l5.376,3.1v9.15l-5.376-3.1Z"
                                        transform="translate(-206.182 -313.838)"
                                        fill="#2b88f8"
                                    />
                                    <g transform="translate(0.537 0.827)">
                                        <path
                                            d="M208.727,317.5l-.27-.156v8.116l4.3,2.484v-.252l-4.033-2.328Z"
                                            transform="translate(-208.457 -317.343)"
                                            fill="#2b88f8"
                                        />
                                        <path
                                            d="M209.6,318v7.864l4.033,2.328v-7.864Z"
                                            transform="translate(-209.332 -317.849)"
                                            fill="#2b88f8"
                                        />
                                    </g>
                                </g>
                            </g>
                        </g>
                    </g>
                    <g transform="translate(2.618 33.929)">
                        <path
                            d="M93.1,167.828l8.81,5.086v30.224l-8.81-5.086Z"
                            transform="translate(-93.098 -167.828)"
                            fill="#d9eaff"
                        />
                        <path
                            d="M143.724,197.056l8.81,5.086v30.233l-8.81-5.267Z"
                            transform="translate(-131.783 -190.162)"
                            fill="#d9eaff"
                        />
                        <g transform="translate(1.582 5.235)">
                            <g>
                                <g transform="translate(12.077 6.945)">
                                    <path
                                        d="M151,219.465l5.376,3.1v9.15l-5.376-3.1Z"
                                        transform="translate(-151.003 -219.465)"
                                        fill="#2b88f8"
                                    />
                                    <g transform="translate(0.537 0.827)">
                                        <path
                                            d="M153.548,223.126l-.27-.156v8.116l4.3,2.484v-.252l-4.033-2.328Z"
                                            transform="translate(-153.277 -222.97)"
                                            fill="#2b88f8"
                                        />
                                        <path
                                            d="M154.423,223.631v7.864l4.033,2.328v-7.864Z"
                                            transform="translate(-154.153 -223.475)"
                                            fill="#2b88f8"
                                        />
                                    </g>
                                </g>
                                <g>
                                    <path
                                        d="M99.8,190.02l5.376,3.1v9.15l-5.376-3.1Z"
                                        transform="translate(-99.804 -190.02)"
                                        fill="#2b88f8"
                                    />
                                    <g transform="translate(0.537 0.827)">
                                        <path
                                            d="M102.349,193.681l-.27-.156v8.116l4.3,2.484v-.252l-4.033-2.328Z"
                                            transform="translate(-102.079 -193.525)"
                                            fill="#2b88f8"
                                        />
                                        <path
                                            d="M103.224,194.186v7.864l4.033,2.328v-7.864Z"
                                            transform="translate(-102.954 -194.03)"
                                            fill="#2b88f8"
                                        />
                                    </g>
                                </g>
                            </g>
                            <g transform="translate(0 14.699)">
                                <g transform="translate(12.077 6.945)">
                                    <path
                                        d="M151,281.778l5.376,3.1v9.15l-5.376-3.1Z"
                                        transform="translate(-151.003 -281.778)"
                                        fill="#2b88f8"
                                    />
                                    <g transform="translate(0.537 0.827)">
                                        <path
                                            d="M153.548,285.44l-.27-.156V293.4l4.3,2.484v-.252l-4.033-2.328Z"
                                            transform="translate(-153.277 -285.284)"
                                            fill="#2b88f8"
                                        />
                                        <path
                                            d="M154.423,285.945v7.864l4.033,2.328v-7.864Z"
                                            transform="translate(-154.153 -285.789)"
                                            fill="#2b88f8"
                                        />
                                    </g>
                                </g>
                                <g>
                                    <path
                                        d="M99.8,252.333l5.376,3.1v9.15l-5.376-3.1Z"
                                        transform="translate(-99.804 -252.333)"
                                        fill="#2b88f8"
                                    />
                                    <g transform="translate(0.537 0.827)">
                                        <path
                                            d="M102.349,255.995l-.27-.156v8.116l4.3,2.484v-.252l-4.033-2.328Z"
                                            transform="translate(-102.079 -255.839)"
                                            fill="#2b88f8"
                                        />
                                        <path
                                            d="M103.224,256.5v7.864l4.033,2.328v-7.864Z"
                                            transform="translate(-102.954 -256.344)"
                                            fill="#2b88f8"
                                        />
                                    </g>
                                </g>
                            </g>
                        </g>
                    </g>
                    <g transform="translate(6.796 69.196)">
                        <g>
                            <path
                                d="M121.663,323.584l-10.852-6.241V330.82l10.852,6.269Z"
                                transform="translate(-110.811 -317.343)"
                                fill="#d9eaff"
                            />
                        </g>
                        <path
                            d="M117.617,324.405l-.3-.177-.8-.469-.464-.271-2.406-1.4V334.8l4.4,2.571V324.654Z"
                            transform="translate(-112.973 -320.965)"
                            fill="#2b88f8"
                        />
                        <path
                            d="M138.908,336.893l-.293-.177-.774-.469-.448-.271-2.32-1.4v12.721l4.245,2.571v-12.72Z"
                            transform="translate(-129.133 -330.507)"
                            fill="#2b88f8"
                        />
                    </g>
                    <g transform="translate(30.796 83.196)">
                        <g>
                            <path
                                d="M121.663,323.584l-10.852-6.241V330.82l10.852,6.269Z"
                                transform="translate(-110.811 -317.343)"
                                fill="#d9eaff"
                            />
                        </g>
                        <path
                            d="M117.617,324.405l-.3-.177-.8-.469-.464-.271-2.406-1.4V334.8l4.4,2.571V324.654Z"
                            transform="translate(-112.973 -320.965)"
                            fill="#2b88f8"
                        />
                        <path
                            d="M138.908,336.893l-.293-.177-.774-.469-.448-.271-2.32-1.4v12.721l4.245,2.571v-12.72Z"
                            transform="translate(-129.133 -330.507)"
                            fill="#2b88f8"
                        />
                    </g>
                    <path
                        d="M303.339,78.462,295.3,83.108l12.13,7.089,8.037-4.64Z"
                        transform="translate(-244.988 -65.613)"
                        fill="#a3bad5"
                    />
                    <path
                        d="M354.032,96.935l-7.1,4.1V97.62l7.1-4.1Z"
                        transform="translate(-284.437 -77.118)"
                        fill="#e2e2e2"
                    />
                    <path
                        d="M300.257,87.272l11.009,6.381V90.236l-11.009-6.381Z"
                        transform="translate(-248.774 -69.734)"
                        fill="#ededed"
                    />
                    <path
                        d="M307.36,66.447l-7.1,4.106,11.009,6.381,7.1-4.1Z"
                        transform="translate(-248.774 -56.432)"
                        fill="#fff"
                    />
                    <path
                        d="M355.7,89.682l-5.193,3V91.57l5.193-3Z"
                        transform="translate(-287.173 -73.338)"
                        fill="#e2e2e2"
                    />
                    <path
                        d="M308.258,77.911l9.966,5.775v-1.11L308.258,76.8Z"
                        transform="translate(-254.888 -64.343)"
                        fill="#ededed"
                    />
                    <path
                        d="M313.4,64.075l-5.141,3,9.966,5.775,5.193-3Z"
                        transform="translate(-254.888 -54.619)"
                        fill="#fff"
                    />
                </g>
            </g>
        </SVGIcon>
    );
}
