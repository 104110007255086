import React from 'react';

import { SVGIcon } from 'UIKit/index';

export default function DCBrowserNotification(props) {
    return (
        <SVGIcon {...props} viewBox="0 0 512 512">
            <g>
                <path
                    id="Shape_242_"
                    d="m228.571 64v-18.286c0-5.049-4.093-9.143-9.143-9.143h-219.428v73.143h512v-18.285h-256c-15.148 0-27.429-12.281-27.429-27.429zm-91.428 18.286h-100.572c-5.049 0-9.143-4.093-9.143-9.143s4.094-9.143 9.143-9.143h100.571c5.049 0 9.143 4.093 9.143 9.143s-4.093 9.143-9.142 9.143zm70.464-6.464c2.377 2.296 3.33 5.695 2.493 8.892s-3.333 5.693-6.53 6.53-6.596-.116-8.891-2.493l-2.679-2.68-2.679 2.679c-3.587 3.465-9.29 3.415-12.817-.111-3.527-3.527-3.576-9.229-.111-12.817l2.679-2.679-2.679-2.679c-3.465-3.587-3.415-9.29.111-12.817 3.527-3.527 9.229-3.576 12.817-.111l2.679 2.679 2.679-2.679c3.587-3.465 9.29-3.415 12.817.111 3.527 3.527 3.576 9.229.111 12.817l-2.679 2.679z"
                />
                <path
                    id="Shape_241_"
                    d="m265.143 183.333v-9.618c-.188-4.97-4.173-8.955-9.143-9.143-5.049 0-9.143 4.093-9.143 9.143v9.609c3.026-.238 6.053-.466 9.143-.466 3.09-.001 6.117.237 9.143.475z"
                />
                <path
                    id="Shape_240_"
                    d="m0 411.429c0 15.148 12.28 27.429 27.429 27.429h64.402c.965-8.792 6.057-16.601 13.714-21.029 11.813-6.83 31.598-26.185 31.598-79.543v-36.571c.098-55.019 37.905-102.796 91.429-115.538v-12.462c0-15.148 12.28-27.429 27.429-27.429 15.066.197 27.232 12.363 27.429 27.429v12.617c53.121 13.268 90.655 60.635 91.429 115.383v36.571c0 53.358 19.785 72.713 31.579 79.543 7.657 4.428 12.749 12.236 13.714 21.029h64.421c15.148 0 27.429-12.28 27.429-27.429v-283.429h-512.002zm424.064-166.528c-3.976-3.113-4.676-8.86-1.563-12.837 3.113-3.976 8.86-4.676 12.837-1.563 20.287 18.031 31.608 44.079 30.949 71.214.659 27.134-10.661 53.182-30.949 71.214-3.976 3.113-9.724 2.413-12.837-1.563s-2.413-9.724 1.563-12.837c15.857-14.559 24.594-35.297 23.936-56.814.658-21.517-8.079-42.255-23.936-56.814zm-28.343 35.739c-3.423-3.652-3.301-9.37.274-12.873 3.558-3.499 9.272-3.475 12.8.055 8.105 9.382 12.318 21.507 11.776 33.893.532 12.391-3.694 24.516-11.813 33.893-3.583 3.31-9.132 3.224-12.612-.194s-3.663-8.966-.417-12.606c4.64-6.019 6.966-13.504 6.555-21.093.4-7.569-1.929-15.031-6.565-21.029v-.046zm-292.48-12.8c2.309-2.313 5.677-3.218 8.835-2.374 3.157.844 5.625 3.308 6.473 6.464s-.052 6.525-2.361 8.838c-4.579 5.991-6.874 13.417-6.473 20.946-.395 7.569 1.934 15.029 6.565 21.029 2.592 2.644 3.334 6.591 1.878 9.996s-4.822 5.595-8.525 5.547c-2.401.002-4.704-.952-6.4-2.651-8.118-9.387-12.34-21.522-11.803-33.92-.527-12.39 3.698-24.514 11.813-33.893v.018zm-26.569-37.321c3.989-2.966 9.618-2.201 12.671 1.722s2.412 9.567-1.443 12.705c-15.831 14.555-24.554 35.273-23.9 56.768-.658 21.517 8.079 42.255 23.936 56.814 3.063 2.409 4.262 6.494 2.988 10.176s-4.742 6.152-8.638 6.153c-2.025 0-3.992-.679-5.586-1.929-20.302-18.024-31.636-44.073-30.985-71.214-.658-27.136 10.666-53.185 30.958-71.214v.019z"
                />
                <path
                    id="Shape_239_"
                    d="m484.571 0h-457.142c-11.582.048-21.886 7.366-25.747 18.286h217.746c15.148 0 27.429 12.28 27.429 27.429v18.285c0 5.049 4.093 9.143 9.143 9.143h256v-45.714c0-15.149-12.28-27.429-27.429-27.429zm-91.428 45.714c-5.049 0-9.143-4.093-9.143-9.143s4.093-9.143 9.143-9.143c5.049 0 9.143 4.093 9.143 9.143s-4.094 9.143-9.143 9.143zm36.571 0c-5.049 0-9.143-4.093-9.143-9.143s4.093-9.143 9.143-9.143c5.049 0 9.143 4.093 9.143 9.143s-4.093 9.143-9.143 9.143zm36.572 0c-5.049 0-9.143-4.093-9.143-9.143s4.093-9.143 9.143-9.143 9.143 4.093 9.143 9.143-4.094 9.143-9.143 9.143z"
                />
                <path
                    id="Shape_238_"
                    d="m256 512c21.715-.027 40.426-15.301 44.8-36.571h-89.6c4.374 21.27 23.085 36.544 44.8 36.571z"
                />
                <path
                    id="Shape_237_"
                    d="m397.321 433.627c-15.223-8.75-40.75-32.777-40.75-95.342v-36.571c-.729-55.239-45.332-99.843-100.571-100.571-55.517.065-100.506 45.055-100.571 100.571v36.571c0 62.565-25.527 86.592-40.75 95.342-3 1.615-4.897 4.721-4.965 8.128v6.245c0 5.049 4.093 9.143 9.143 9.143h274.286c5.049 0 9.143-4.093 9.143-9.143v-6.245c-.068-3.406-1.965-6.512-4.965-8.128zm-141.321-195.913c-35.33.04-63.96 28.67-64 64 0 5.049-4.093 9.143-9.143 9.143s-9.143-4.093-9.143-9.143c.055-45.422 36.864-82.23 82.286-82.286 5.049 0 9.143 4.093 9.143 9.143s-4.094 9.143-9.143 9.143z"
                />
            </g>
        </SVGIcon>
    );
}
