/* eslint-disable no-undef */
import React, { Fragment, useCallback, useEffect, useMemo, useState } from 'react';
import {
    FieldControl,
    Label,
    LabelControl,
    Textarea,
    Div,
    Checkbox,
    SingleTextChip,
    // Span,
} from 'UIKit';
import AutoComplete from 'Components/AutoComplete';

import { getAccountDetails } from 'Utils/localStorageUtils';
import { serviceReasons, softwareReasons } from '../constants';
import '../style.scss';

interface ReasonsProps {
    type: { service: boolean; serviceName: string; plan: any };
    setCanContinue: any;
    setStepsData: any;
    onContinue: { current: any };
    stepsData: { current: any };
}
const CancellationReason: React.FC<ReasonsProps> = ({
    type,
    setCanContinue,
    setStepsData,
    onContinue,
    stepsData,
}) => {
    const [selectedOption, setSelectedOption] = useState<Array<String>>(
        stepsData.current.cancellationReason?.reason || [],
    );
    const [description, setDescription] = useState(
        stepsData.current.cancellationReason?.description || '',
    );
    const [error, setError] = useState(false);
    const handleSelectOption = useCallback(
        (item: any) => {
            if (selectedOption.includes(item.value || item)) {
                const temp = selectedOption.filter(ele => ele !== (item.value || item));
                setSelectedOption(temp);
            } else {
                let temp = [...selectedOption, item.value || item];
                setSelectedOption([...temp]);
            }
        },
        [selectedOption],
    );

    const handleFeedbackDescription = useCallback((e: any) => {
        setDescription(e.target.value);
    }, []);

    useEffect(() => {
        if (selectedOption.length > 0 && description.trim().length > 19) {
            setStepsData({
                cancellationReason: {
                    reason: [...selectedOption],
                    description,
                },
            });
            onContinue.current = () => {};

            setCanContinue(true);
        } else setCanContinue(false);
    }, [description, onContinue, selectedOption, setCanContinue, setStepsData]);

    const generateOptions = useCallback(() => {
        const tempOptionArray: Array<Object> = [];
        const reasons: Array<any> =
            type.service && !['Phone Number', 'Software', 'Site'].includes(type.serviceName)
                ? [...serviceReasons]
                : [...softwareReasons];
        reasons.forEach(item => tempOptionArray.push({
                label: (
                    <Div className={'ACMultiOption '}>
                        <Checkbox
                            unCheckColor={'#eaeaea'}
                            checkColor={'var(--dark-blue)'}
                            classes={{ main: 'ACMOICheckbox' }}
                            fullWidth={true}
                            label={item.title}
                            onChange={() => handleSelectOption(item)}
                            checked={selectedOption.includes(item.value)}
                        />
                    </Div>
                ),
                value: item.value,
            }),
        );
        return tempOptionArray;
    }, [handleSelectOption, selectedOption, type]);

    const getReasonTitle = useCallback(
        (val: any) => {
            const reasons: Array<any> =
                type.service && !['Phone Number', 'Software', 'Site'].includes(type.serviceName)
                    ? [...serviceReasons]
                    : [...softwareReasons];

            return reasons.find(item => item.value === val)?.title;
        },
        [type.service, type.serviceName],
    );

    const generateStatusChips = useMemo(() => {
        return selectedOption?.map(item => {
            return {
                ...item,
                chip: (
                    <SingleTextChip
                        title={getReasonTitle(item)}
                        onDelete={() => {
                            handleSelectOption(item);
                        }}
                    />
                ),
            };
        });
    }, [getReasonTitle, handleSelectOption, selectedOption]);

    return (
        <Fragment>
            <Div className={'SimpleInputViewMain'}>
                <Div className={'SimpleInputView'}>
                    <Div className={'SIVInner'}>
                        <Div className={'SDStepHead'}>
                            {`In order to cancel ${
                                type.service
                                    ? `${type?.serviceName?.toLowerCase()}`
                                    : `${getAccountDetails()?.plan?.display_name?.toLowerCase()} subscription`
                            } for ${getAccountDetails()?.name?.toLowerCase()}, please provide us with some quick feedback below.`}
                        </Div>
                        <FieldControl>
                            <>
                                <LabelControl props={undefined}>
                                    <Label required>
                                        <>Reason for cancellation</>
                                    </Label>
                                </LabelControl>
                                <AutoComplete
                                    generateRows={generateOptions}
                                    isAPICall={false}
                                    singleSelection={false}
                                    itemHeight={45}
                                    height={300}
                                    value={
                                        selectedOption.length > 0
                                            ? generateStatusChips.map(item => item.chip)
                                            : null
                                    }
                                    placeholder={'Select reasons'}
                                    isSearch={false}
                                />
                            </>
                        </FieldControl>
                        <FieldControl>
                            <>
                                <LabelControl props={undefined}>
                                    <Label props={{ htmlFor: 'ProvideDetailedFeedback' }} required>
                                        <>Provide detailed feedback </>
                                    </Label>
                                </LabelControl>
                                <Textarea
                                    id={'ProvideDetailedFeedback'}
                                    rows={5}
                                    value={description}
                                    placeholder="Feedback (Min. 20 charcters are required)"
                                    onChange={handleFeedbackDescription}
                                    onBlur={() => {
                                        if (description.length < 20) {
                                            setError(true);
                                        }
                                    }}
                                    onFocus={() => {
                                        setError(false);
                                    }}
                                    error={error}
                                    errorMessage={'You need to type at least 20 characters'}
                                />
                            </>
                        </FieldControl>
                    </Div>
                </Div>
            </Div>
        </Fragment>
    );
};

export default CancellationReason;
