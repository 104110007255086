import React from 'react';

import { SVGIcon } from '../../UIKit/index';

export default function DCEnvelope1(props) {
    return (
        <SVGIcon {...props} viewBox="0 0 512 312">
            <g>
                <path d="M484.3,3.7H28.1c-9.7,0-17.5,8.1-17.5,18.1v326c0,10,7.9,18.1,17.5,18.1h456.2c9.7,0,17.5-8.1,17.5-18.1v-326    C501.8,11.8,494,3.7,484.3,3.7z M455.5,39.9L256.2,198.1L56.8,39.9H455.5z M466.8,329.7H45.6v-253l199.9,158.7    c6.3,5,15.1,5,21.4,0L466.8,76.7V329.7z" />
            </g>
        </SVGIcon>
    );
}
