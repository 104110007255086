import React from 'react';

import { SVGIcon } from '../../UIKit/index';

export default function DCGrayPeoples(props) {
    return (
        <SVGIcon {...props} viewBox="0 0 404.597 402.826">
            <g id="Artboard_9" transform="translate(-54 -52.764)">
                <g id="Layer_1">
                    <rect
                        id="Rectangle_1"
                        width="10.75"
                        height="17.92"
                        transform="translate(394.363 324.481) rotate(-65.76)"
                        fill="#e3e2e3"
                    />
                    <path
                        id="Path_1"
                        d="M412.46,342.91c-8.94-4-13.42-12.39-11.68-16.26,2.92-6.47,11.7-11.83,22.24-7.17,9,4,13.15,17.21,10.24,23.68S421.41,346.94,412.46,342.91Z"
                        fill="#f4f5f6"
                    />
                    <path
                        id="Path_2"
                        d="M416.45,317.77S437.69,312,441.33,329c6.66,3.27-.46,25.09-11.13,22.27-8.32,1.45-13.85-5-16.1-7.63a30.46,30.46,0,0,0,11.16,2.8c0-1,.7-4.24,3.76-8.63,7.73-11.08-4.41-5.17-8.32-14.14C428.77,324.51,425.79,319.76,416.45,317.77Z"
                        fill="#707171"
                    />
                    <path
                        id="Path_3"
                        d="M394.15,330.14c.62,2.47-2.57,4.26-5,4.88l-25.75,7.53-10.9-4.9a4.6,4.6,0,0,1,3.12-2.69l33-8.2A4.63,4.63,0,0,1,394.15,330.14Z"
                        fill="#707171"
                    />
                    <path
                        id="Path_4"
                        d="M372.59,284.34l-8.37-1.93,4.11-9.14c1.87.15,3.15.24,3.22.22a8.9,8.9,0,0,1,5.48,2.33l23.45,22.1a4.623,4.623,0,1,1-6.34,6.73Z"
                        fill="#707171"
                    />
                    <path
                        id="Path_5"
                        d="M349.4,315.32l12.41-27.57c16.85,8.92,40,12.39,40,12.39s1.5,7.85.54,12.7c-.26,1.29-.12,2-.46,3.24-2.75-.66-5.71,4.48-5.95,7.66-.12,1.63,2.05,2.33,2.05,2.33-1.66,4.28-5.05,8.84-9.2,9l-12.66-5.25S349.54,315,349.4,315.32Z"
                        fill="#707171"
                    />
                    <path
                        id="Path_6"
                        d="M374.14,292.83c.31-1,.27-1.55-.38-1.69h0c-.46-.1-1,0-1.34,1.41l-.24,2a.68.68,0,0,0,.43.77h0a.59.59,0,0,0,.73-.42Z"
                        fill="#e3e2e3"
                    />
                    <path
                        id="Path_7"
                        d="M372.79,295.66a.46.46,0,0,1-.26,0,.5.5,0,0,1-.3-.56l.14-.89c.05-.27.15-.31.22-.34a.5.5,0,0,1,.34,0c.21.06.51.16.37.61l-.26.89a.43.43,0,0,1-.25.29Z"
                        fill="#f4f5f6"
                    />
                    <path
                        id="Path_8"
                        d="M376.19,292.8c.24-.84,0-1.59-.58-1.69h-.12c-.76-.08-1,.65-1.21,1.48l-.64,2.67a.87.87,0,0,0,.57,1.05h0a.85.85,0,0,0,1-.6Z"
                        fill="#e3e2e3"
                    />
                    <path
                        id="Path_9"
                        d="M378.25,292.87c.24-.83-.08-1.59-.71-1.67h0c-.63-.09-1.08.54-1.28,1.36l-1,3.69a.83.83,0,0,0,.58,1h0a.83.83,0,0,0,1-.58Z"
                        fill="#e3e2e3"
                    />
                    <path
                        id="Path_10"
                        d="M379.89,293.85c.26-.93-.06-1.74-.7-1.78h0c-.64,0-1.05.68-1.25,1.58l-.74,3.06a.75.75,0,0,0,.54.9h0a.8.8,0,0,0,.93-.57Z"
                        fill="#e3e2e3"
                    />
                    <path
                        id="Path_11"
                        d="M374.41,296.63a.47.47,0,0,1-.31,0,.61.61,0,0,1-.32-.72l.24-1.08c.09-.32.21-.35.3-.39a.67.67,0,0,1,.42,0c.26.1.6.25.4.79l-.4,1.07A.54.54,0,0,1,374.41,296.63Z"
                        fill="#f4f5f6"
                    />
                    <path
                        id="Path_12"
                        d="M376.06,297.6a.44.44,0,0,1-.32,0,.59.59,0,0,1-.34-.7l.22-1.09c.08-.33.2-.36.29-.4a.61.61,0,0,1,.42,0c.26.1.61.24.42.78l-.37,1.08a.55.55,0,0,1-.32.33Z"
                        fill="#f4f5f6"
                    />
                    <path
                        id="Path_13"
                        d="M378,298.09a.44.44,0,0,1-.32,0,.59.59,0,0,1-.34-.7l.21-1.09c.07-.32.19-.36.29-.4a.58.58,0,0,1,.41,0c.26.09.61.23.43.77l-.36,1.09a.55.55,0,0,1-.32.33Z"
                        fill="#f4f5f6"
                    />
                    <rect
                        id="Rectangle_2"
                        width="10.75"
                        height="17.92"
                        transform="matrix(0.857, -0.516, 0.516, 0.857, 332.984, 389.892)"
                        fill="#e3e2e3"
                    />
                    <path
                        id="Path_14"
                        d="M362.64,400.11c-5.06-8.4-13.89-11.86-17.53-9.67-6.08,3.66-10.36,13-4.49,22.93,5,8.43,18.65,11,24.73,7.35S367.7,408.51,362.64,400.11Z"
                        fill="#f4f5f6"
                    />
                    <path
                        id="Path_15"
                        d="M338.15,407.05s-2.54,19,14.08,23.37c8.36,1,17.42-4.41,19.45-12.73.46-8.44-5.28-14.12-8.14-16,1.14,4.49,7.06,11.8.83,12.29-1,.08-1.07-.58-4.85,3.22-4.78,4.79-8.36,4.74-15-6.59C346.3,418.48,341.24,416.09,338.15,407.05Z"
                        fill="#707171"
                    />
                    <path
                        id="Path_16"
                        d="M330.69,339l-26.16,15.75a132.441,132.441,0,0,1,14.37,39.83s8,.57,12.68-1c1.25-.41,2-.36,3.16-.83-1-2.66,3.78-6.21,6.9-6.82,1.6-.32,2.56,1.75,2.56,1.75,4.05-2.15,9.51-6.86,8.66-9.09,0,0-4.26-11-7.49-13.06C345.37,365.56,337.34,349.51,330.69,339Z"
                        fill="#707171"
                    />
                    <path
                        id="Path_17"
                        d="M347.78,383.44a4.61,4.61,0,0,0,5.16-4l3.52-28-6.17-10.25a4.61,4.61,0,0,0-2.29,3.42l-4.23,33.72a4.62,4.62,0,0,0,4.01,5.11Z"
                        fill="#707171"
                    />
                    <path
                        id="Path_18"
                        d="M299.75,367.47l-2.91-8.09-8.58,5.17c.36,1.84.6,3.09.59,3.17a9,9,0,0,0,3,5.17l24.73,20.66a4.63,4.63,0,0,0,5.93-7.1Z"
                        fill="#707171"
                    />
                    <path
                        id="Path_19"
                        d="M310.79,366.39c-1,.43-1.51.45-1.73-.17h0c-.15-.45-.15-1,1.24-1.5l2-.48a.68.68,0,0,1,.81.33h0a.6.6,0,0,1-.32.79Z"
                        fill="#f4f5f6"
                    />
                    <path
                        id="Path_20"
                        d="M313.43,364.72a.39.39,0,0,0,0-.26.48.48,0,0,0-.59-.23l-.87.24c-.26.08-.28.18-.3.26a.44.44,0,0,0,0,.34c.09.2.23.48.66.29l.85-.36A.43.43,0,0,0,313.43,364.72Z"
                        fill="#e3e2e3"
                    />
                    <path
                        id="Path_21"
                        d="M311,368.43c-.8.34-1.58.23-1.75-.38v-.11c-.16-.74.53-1.08,1.33-1.38l2.58-.95a.87.87,0,0,1,1.11.44h0a.84.84,0,0,1-.48,1.09Z"
                        fill="#f4f5f6"
                    />
                    <path
                        id="Path_22"
                        d="M311.31,370.47c-.79.33-1.58.11-1.75-.51h0c-.16-.62.42-1.13,1.21-1.43l3.54-1.43a.82.82,0,0,1,1.06.46h0a.84.84,0,0,1-.45,1.08Z"
                        fill="#f4f5f6"
                    />
                    <path
                        id="Path_23"
                        d="M312.47,372c-.88.37-1.72.15-1.84-.49h0c-.12-.63.55-1.11,1.42-1.42L315,369a.74.74,0,0,1,.95.43h0a.78.78,0,0,1-.44,1Z"
                        fill="#f4f5f6"
                    />
                    <path
                        id="Path_24"
                        d="M314.59,366.21a.49.49,0,0,0,0-.31.6.6,0,0,0-.75-.23l-1,.37c-.31.12-.33.25-.35.35a.59.59,0,0,0,.06.41c.13.24.32.57.83.3l1-.52a.54.54,0,0,0,.21-.37Z"
                        fill="#e3e2e3"
                    />
                    <path
                        id="Path_25"
                        d="M315.75,367.73a.41.41,0,0,0,0-.31.6.6,0,0,0-.74-.26l-1.05.35c-.32.12-.34.24-.36.34a.58.58,0,0,0,.05.41c.12.24.3.58.82.32l1-.49a.54.54,0,0,0,.28-.36Z"
                        fill="#e3e2e3"
                    />
                    <path
                        id="Path_26"
                        d="M316.46,369.58a.41.41,0,0,0,0-.31.6.6,0,0,0-.74-.26l-1.06.33c-.31.12-.33.24-.36.34a.58.58,0,0,0,.05.41c.12.25.3.58.82.33l1-.49a.51.51,0,0,0,.29-.35Z"
                        fill="#e3e2e3"
                    />
                    <rect
                        id="Rectangle_3"
                        width="8.82"
                        height="18.65"
                        transform="matrix(0.637, -0.771, 0.771, 0.637, 369.073, 357.11)"
                        fill="#e3e2e3"
                    />
                    <ellipse
                        id="Ellipse_1"
                        data-name="Ellipse 1"
                        cx="13.37"
                        cy="18.47"
                        rx="13.37"
                        ry="18.47"
                        transform="matrix(0.637, -0.771, 0.771, 0.637, 368.421, 368.207)"
                        fill="#f4f5f6"
                    />
                    <path
                        id="Path_27"
                        d="M350.65,347.07l14.29,16.13c1.51.94,3.07,0,4.48-1.74L379,349.87c1.41-1.71,2.36-3.44,1.15-4.76l-17.91-11.66Z"
                        fill="#f4f5f6"
                    />
                    <path
                        id="Path_28"
                        d="M341.38,357.84l4,7.72,15.62-.18a3.46,3.46,0,0,0,3.32-3.24c0-1.68-4.28-4.15-6-4.16Z"
                        fill="#f4f5f6"
                    />
                    <path
                        id="Path_29"
                        d="M365.39,324.18l10.23,5.4,5.6,11.33a3.48,3.48,0,0,1-2.57,3.88c-1.64.33-4.87-3.42-5.2-5.07Z"
                        fill="#f4f5f6"
                    />
                    <path
                        id="Path_30"
                        d="M360.5,365.55l2.81.44a3.1,3.1,0,0,0,2.8-1.33,8.59,8.59,0,0,0,2-1.92L362,345.85l18.46,1.82a4.5,4.5,0,0,0,1.15-1.86c1.14-1.47,2-4,.67-5.44l-1.59-1.74-7.67.14-7.65-5.33L343.81,342,357,357l.42.49Z"
                        fill="#707171"
                    />
                    <path
                        id="Path_31"
                        d="M372.64,338.5,347,320.74l-12.43,15,22.69,21.29s-7.84-9.55,4.41-11.49C360.44,335.69,372.64,338.5,372.64,338.5Z"
                        fill="#707171"
                    />
                    <path
                        id="Path_32"
                        d="M360.5,365.55l2.81.44a3.1,3.1,0,0,0,2.8-1.33,8.59,8.59,0,0,0,2-1.92L362,345.85l18.46,1.82a4.5,4.5,0,0,0,1.15-1.86c1.14-1.47,2-4,.67-5.44l-1.59-1.74-7.67.14-7.65-5.33L343.81,342,357,357l.42.49Z"
                        fill="#fff"
                        opacity="0.1"
                    />
                    <path
                        id="Path_33"
                        d="M351.85,326.17a1.22,1.22,0,0,1-.55-1.65l1.05-2.08a1.77,1.77,0,0,1,2.08-.48h0a1.23,1.23,0,0,1,.55,1.66l-1.47,2a1.23,1.23,0,0,1-1.66.55Z"
                        fill="#f4f5f6"
                    />
                    <path
                        id="Path_34"
                        d="M348.85,328.3a1.25,1.25,0,0,1,.11-1.75l2.65-2.35a1.23,1.23,0,0,1,1.74.11h0a1.25,1.25,0,0,1-.11,1.75l-2.65,2.35a1.23,1.23,0,0,1-1.74-.11Z"
                        fill="#f4f5f6"
                    />
                    <path
                        id="Path_35"
                        d="M354.59,327.72a1.23,1.23,0,0,1-.9-1.5l-.56-2.46c.16-.66,1.74-1.17,2.4-1h0a1.23,1.23,0,0,1,.89,1.5l-.33,2.56a1.23,1.23,0,0,1-1.5.9Z"
                        fill="#f4f5f6"
                    />
                    <path
                        id="Path_36"
                        d="M351,329.43a1.24,1.24,0,0,1,.25-1.73l2.83-2.14a1.24,1.24,0,0,1,1.73.26h0a1.23,1.23,0,0,1-.26,1.73l-2.83,2.13a1.22,1.22,0,0,1-1.72-.25Z"
                        fill="#f4f5f6"
                    />
                    <path
                        id="Path_37"
                        d="M347.37,326.51a1.06,1.06,0,0,1,0-1.5l2.12-2.19a1.06,1.06,0,0,1,1.5,0h0a1.06,1.06,0,0,1,0,1.5l-2.12,2.19a1.06,1.06,0,0,1-1.5,0Z"
                        fill="#f4f5f6"
                    />
                    <path
                        id="Path_38"
                        d="M349.35,324.32a1.06,1.06,0,0,1,.14-1.49l1.86-1.61a1.07,1.07,0,0,1,1.5.14h0a1.07,1.07,0,0,1-.14,1.5l-1.86,1.6a1.06,1.06,0,0,1-1.5-.14Z"
                        fill="#f4f5f6"
                    />
                    <path
                        id="Path_39"
                        d="M346.47,324.43a.93.93,0,0,1,0-1.32l1.9-1.9a.93.93,0,0,1,1.32,0h0a.93.93,0,0,1,0,1.32l-1.9,1.89a.92.92,0,0,1-1.32,0Z"
                        fill="#f4f5f6"
                    />
                    <path
                        id="Path_40"
                        d="M348.34,322.21a.93.93,0,0,1,.45-1.24l1.2-.58a.93.93,0,0,1,1.24.45h0a.93.93,0,0,1-.45,1.24l-1.2.58a.93.93,0,0,1-1.24-.45Z"
                        fill="#f4f5f6"
                    />
                    <path
                        id="Path_41"
                        d="M340.76,339.58a1.24,1.24,0,0,0-1.73-.23l-1.84,1.43a1.76,1.76,0,0,0-.08,2.13h0a1.23,1.23,0,0,0,1.73.23l1.69-1.83a1.23,1.23,0,0,0,.23-1.73Z"
                        fill="#f4f5f6"
                    />
                    <path
                        id="Path_42"
                        d="M342.28,336.23a1.24,1.24,0,0,0-1.69.45l-1.81,3a1.23,1.23,0,0,0,.44,1.69h0a1.23,1.23,0,0,0,1.69-.44l1.81-3.05a1.23,1.23,0,0,0-.44-1.69Z"
                        fill="#f4f5f6"
                    />
                    <path
                        id="Path_43"
                        d="M342.79,342a1.22,1.22,0,0,0-1.64-.59l-2.52-.1c-.61.29-.82,1.93-.53,2.55h0a1.23,1.23,0,0,0,1.64.59l2.46-.81a1.23,1.23,0,0,0,.59-1.64Z"
                        fill="#f4f5f6"
                    />
                    <path
                        id="Path_44"
                        d="M343.8,338.16a1.25,1.25,0,0,0-1.65.58l-1.56,3.18a1.23,1.23,0,0,0,.58,1.65h0a1.22,1.22,0,0,0,1.64-.58l1.57-3.18a1.23,1.23,0,0,0-.58-1.65Z"
                        fill="#f4f5f6"
                    />
                    <path
                        id="Path_45"
                        d="M340.25,335.12a1.07,1.07,0,0,0-1.48.27l-1.75,2.5a1.05,1.05,0,0,0,.28,1.47h0a1,1,0,0,0,1.47-.27l1.76-2.49A1.07,1.07,0,0,0,340.25,335.12Z"
                        fill="#f4f5f6"
                    />
                    <path
                        id="Path_46"
                        d="M338.48,337.48a1.08,1.08,0,0,0-1.45.42l-1.22,2.1a1.06,1.06,0,0,0,.41,1.44h0a1.08,1.08,0,0,0,1.45-.42l1.22-2.13a1.05,1.05,0,0,0-.41-1.44Z"
                        fill="#f4f5f6"
                    />
                    <path
                        id="Path_47"
                        d="M338,334.63a1,1,0,0,0-1.3.27l-1.5,2.22a.92.92,0,0,0,.26,1.29h0a.94.94,0,0,0,1.3-.26l1.5-2.23a.92.92,0,0,0-.26-1.29Z"
                        fill="#f4f5f6"
                    />
                    <path
                        id="Path_48"
                        d="M336.21,336.88a.94.94,0,0,0-1.14.68l-.34,1.28a.94.94,0,0,0,.68,1.14h0a.92.92,0,0,0,1.13-.67l.34-1.29a.93.93,0,0,0-.67-1.14Z"
                        fill="#f4f5f6"
                    />
                    <path
                        id="Path_49"
                        d="M410.46,382.77c8.15-4.76,1.92-33.17-22.65-28.54,4.52,1.37,8.77,6.07,11.19,9.19-3.82,1.48-9.49,4.4-13.39,9.74,1.3-.89,7-2.05,7-2.05a29.63,29.63,0,0,0-11.1,6,4.75,4.75,0,0,1,3.41,2s-7.44-.82-9.5-9.53c-.82,2.69-.27,8.47,5.15,13.78S401.92,397.85,410.46,382.77Z"
                        fill="#707171"
                    />
                    <path
                        id="Path_50"
                        d="M62.11,207.65c-4.85,19.18,28,34.4,71.76,27.39,2-14.58-23.23-38-47.55-39.69C69,194.16,64.39,198.66,62.11,207.65Z"
                        fill="#707171"
                    />
                    <path
                        id="Path_51"
                        d="M87.12,226.56s11.4,6.21,21.1,6.65c11.42.52,21.68-4.32,25.65,1.83,2-14.58-31.72-37.56-47.55-39.69C69,194.16,80.52,215.67,87.12,226.56Z"
                        fill="#707171"
                    />
                    <rect
                        id="Rectangle_4"
                        width="9.3"
                        height="18.25"
                        transform="matrix(0.206, -0.979, 0.979, 0.206, 95.297, 217.627)"
                        fill="#e3e2e3"
                    />
                    <path
                        id="Path_52"
                        d="M90.71,196.42c9.05,2.45,15.26,7.4,14.57,11.43-2,11.93-10.59,17.66-19.89,15.7S70.81,215.46,72,204.23C73,195.1,81.53,193.94,90.71,196.42Z"
                        fill="#f4f5f6"
                    />
                    <path
                        id="Path_53"
                        d="M89.13,218.84a3.86,3.86,0,1,1-4.57,3A3.86,3.86,0,0,1,89.13,218.84Z"
                        fill="#f4f5f6"
                    />
                    <path
                        id="Path_54"
                        d="M154.84,240.67l6-28.63c-8.46-1.09-22.8-1.95-24.17-4.11-4.13-6.51-14.82-4.25-14.82-4.25-.73,0-8.23-1.9-8.23-1.9-1.86-.39-4.6,6.95-4.7,8.87l-1.8,9.47c-.1,1.93,3.07,13,4.93,13.36C112,233.48,141.07,220.72,154.84,240.67Z"
                        fill="#f4f5f6"
                    />
                    <path
                        id="Path_55"
                        d="M154.5,180.93l-11.1,20.35-26.49-1.78a4.144,4.144,0,1,0-.56,8.27l30.41,2a4.13,4.13,0,0,0,3.77-1.95,4.3,4.3,0,0,0,.72-1.25L158.32,184C157.07,182.92,155.81,181.89,154.5,180.93Z"
                        fill="#f4f5f6"
                    />
                    <path
                        id="Path_56"
                        d="M156.58,223l-14.72,13.81-24.79-9.51a4.151,4.151,0,1,0-3,7.74l28.45,10.92a4.14,4.14,0,0,0,4.18-.74,4.511,4.511,0,0,0,1.06-1l10.31-15.9Z"
                        fill="#f4f5f6"
                    />
                    <path
                        id="Path_57"
                        d="M154.84,240.67,161,211.53c-8.59-.45-24.48-2.16-25.95-4.26-5.73-8.2-15.15-4-15.15-4-.72,0-6.59-1.87-6.59-1.87-1.86-.39-4.29,7.35-4.39,9.27,0,0,7.68.09,16.6,19.23C135,228.6,147.23,229.64,154.84,240.67Z"
                        fill="#828282"
                    />
                    <path
                        id="Path_58"
                        d="M87.58,227s-6.33-3.11.15-3.8c-2.59-4.88-8.06-.92-12.22-11.54,2.53-15.77,10.26-16.46,10.26-16.46-5.13-4.87-13.11-5.52-19.23,1.43C54.54,209.36,64.71,234.52,87.58,227Z"
                        fill="#707171"
                    />
                    <path
                        id="Path_59"
                        d="M145.14,146.63a3.38,3.38,0,0,0-4.07,2.53l-4.9,21.15-12.95.14a3.76,3.76,0,1,0,.37,7.51l14.89-.25a3.64,3.64,0,0,0,1.15-.26,3.38,3.38,0,0,0,2.42-2.49l5.62-24.27a3.38,3.38,0,0,0-2.53-4.06Z"
                        fill="#e3e2e3"
                    />
                    <rect
                        id="Rectangle_5"
                        width="9.62"
                        height="20.33"
                        transform="matrix(0.801, -0.599, 0.599, 0.801, 137.549, 129.038)"
                        fill="#e3e2e3"
                    />
                    <path
                        id="Path_60"
                        d="M148.54,112c6.12,9.09,5.75,20.46-.83,25.38s-16.88,1.53-23-7.56-5.75-20.46.84-25.38S142.42,102.88,148.54,112Z"
                        fill="#f4f5f6"
                    />
                    <path
                        id="Path_61"
                        d="M189.9,172l-26.53-37.8c-1.36-1.39-3.27-.75-5.2.69L145,144.72c-1.93,1.44-3.38,3-2.44,4.74l29.57,35.83Z"
                        fill="#e3e2e3"
                    />
                    <path
                        id="Path_62"
                        d="M190.05,171.91l-21.6-30.64c-11.51,5.47-16.19,8.84-21.39,13.68l25.05,30.38Z"
                        fill="#828282"
                    />
                    <path
                        id="Path_63"
                        d="M183.29,144.41,191.22,171l4-3-4.39-25.65a3.72,3.72,0,0,0-.21-1.16,3.36,3.36,0,0,0-2.38-2.52l-24-6.68a3.39,3.39,0,1,0-1.82,6.531Z"
                        fill="#e3e2e3"
                    />
                    <path
                        id="Path_64"
                        d="M190.05,171.91l-21.6-30.64-1.06.52c.79,2,3.15,8.57-5.7,12.53a5.88,5.88,0,0,1-3.05.29l-.67-.08-.12.67a7.27,7.27,0,0,1-1.14,2.84c-1.67,2.42-7.58-.82-9.65-3.09l25.05,30.38Z"
                        fill="#707171"
                    />
                    <path
                        id="Path_65"
                        d="M102.74,182.81C74.28,170.18,74.13,157,80.4,149.26c3.09-7.44,12.95-14.12,35.63.77,13.66,9,25.59,33.47,15.41,35.7C120.93,188.85,108.68,185.45,102.74,182.81Z"
                        fill="#707171"
                    />
                    <path
                        id="Path_66"
                        d="M126.11,180.58c.64,1.92,1.19,3.6,1.66,5.1h-.11c-4,.18-20.64-2.29-36.27-12.5-9.79-6.39-9.19-15-5-22.26s15.79-8.37,25.2-1.45C125.56,159.74,123.44,172.57,126.11,180.58Z"
                        fill="#707171"
                    />
                    <path
                        id="Path_67"
                        d="M130.11,200.39,123,182.5a3.392,3.392,0,1,0-6.35,2.39l4.89,14.92Z"
                        fill="#e3e2e3"
                    />
                    <rect
                        id="Rectangle_6"
                        width="9.62"
                        height="20.33"
                        transform="translate(104.632 168.694) rotate(-60.02)"
                        fill="#e3e2e3"
                    />
                    <ellipse
                        id="Ellipse_2"
                        data-name="Ellipse 2"
                        cx="14.58"
                        cy="20.15"
                        rx="14.58"
                        ry="20.15"
                        transform="translate(76.267 163.602) rotate(-60.02)"
                        fill="#f4f5f6"
                    />
                    <path
                        id="Path_68"
                        d="M167.11,192.1c-2.75-2.49-25.26-22.76-34.84-28.36-1.68-1-5.16.12-6.37,2.21l-8.2,14.21c-1.2,2.09,1.11,6.11,2.65,7.3L159.08,206Z"
                        fill="#e3e2e3"
                    />
                    <path
                        id="Path_69"
                        d="M148.3,156.46l-19.2,6.24a3.39,3.39,0,0,0,2.39,6.34l22.25-6.16Z"
                        fill="#e3e2e3"
                    />
                    <path
                        id="Path_70"
                        d="M113.66,150.66c-4.55-4.62-13,21.38-27.24,13.15,1.68,5.89,6.27,11,15,12.71-11.39.74-28.54-16-20.19-27C81.5,146.06,96.13,132.89,113.66,150.66Z"
                        fill="#707171"
                    />
                    <path
                        id="Path_71"
                        d="M169.18,188.51l-30.57-21a21.23,21.23,0,0,1-12.42-2c-.14.23-.15.23-.29.47,0,0,9.89,7.21,6.31,13.41-3.69,6.4-14.51.8-14.51.8-.11.19-.21.39-.31.58,2.78,2.23,7.88,7.4,9.13,10.57l32.53,14.77Z"
                        fill="#828282"
                    />
                    <path
                        id="Path_72"
                        d="M169.18,188.51l-30.57-21s5.43,6.41-.11,11.5a6.83,6.83,0,0,1-2.68,1.48l-.66.19.16.66a6.13,6.13,0,0,1,.07,3.07,9.17,9.17,0,0,1-8.87,6.89L159,206.14Z"
                        fill="#707171"
                    />
                    <path
                        id="Path_73"
                        d="M162.32,184.05c.69-1,1.15-1.26,1.71-.8h0c.39.33.71.86-.32,2.09l-1.57,1.54a.72.72,0,0,1-.94.14h0a.63.63,0,0,1-.14-.9Z"
                        fill="#f4f5f6"
                    />
                    <path
                        id="Path_74"
                        d="M160.79,187.08a.4.4,0,0,0,.17.23.53.53,0,0,0,.68-.11l.67-.71c.2-.22.16-.33.14-.41a.62.62,0,0,0-.21-.31c-.2-.13-.48-.32-.78.1l-.59.81a.53.53,0,0,0-.08.4Z"
                        fill="#e3e2e3"
                    />
                    <path
                        id="Path_75"
                        d="M161,182.27c.56-.76,1.34-1.1,1.84-.62l.1.08c.56.6.1,1.3-.48,2l-1.86,2.32a.94.94,0,0,1-1.28.2h0a.91.91,0,0,1-.17-1.28Z"
                        fill="#f4f5f6"
                    />
                    <path
                        id="Path_76"
                        d="M159.56,180.55c.55-.76,1.42-1,1.91-.5h0c.5.48.25,1.28-.32,2l-2.51,3.3a.89.89,0,0,1-1.24.17h0a.91.91,0,0,1-.18-1.26Z"
                        fill="#f4f5f6"
                    />
                    <path
                        id="Path_77"
                        d="M157.64,179.79c.62-.84,1.52-1.11,2-.58h0c.45.52.11,1.35-.53,2.12L157,184a.81.81,0,0,1-1.13.14h0a.86.86,0,0,1-.13-1.17Z"
                        fill="#f4f5f6"
                    />
                    <path
                        id="Path_78"
                        d="M158.88,186.34a.51.51,0,0,0,.22.26.66.66,0,0,0,.83-.2l.76-.93c.22-.29.17-.41.13-.51a.67.67,0,0,0-.28-.36c-.25-.15-.62-.35-.94.19l-.66,1.05a.63.63,0,0,0-.06.5Z"
                        fill="#e3e2e3"
                    />
                    <path
                        id="Path_79"
                        d="M157,185.57a.48.48,0,0,0,.21.27.65.65,0,0,0,.83-.17l.79-.91c.23-.29.18-.41.15-.52a.71.71,0,0,0-.28-.36c-.25-.15-.6-.36-.94.16l-.69,1a.61.61,0,0,0-.07.53Z"
                        fill="#e3e2e3"
                    />
                    <path
                        id="Path_80"
                        d="M155.26,184.25a.47.47,0,0,0,.21.27.65.65,0,0,0,.83-.17l.79-.9c.23-.29.19-.41.15-.52a.65.65,0,0,0-.27-.36c-.25-.16-.6-.37-.94.15l-.7,1a.6.6,0,0,0-.07.53Z"
                        fill="#e3e2e3"
                    />
                    <path
                        id="Path_81"
                        d="M154.84,240.67,161,211.53c-8.59-.45-24.48-2.16-25.95-4.26a11.73,11.73,0,0,0-4.65-4c2.66,2,1.44,6.44-.82,8.05,2.87,2.4,2.37,12.36-5.41,15.82q.67,1.31,1.35,2.76C135,228.6,147.23,229.64,154.84,240.67Z"
                        fill="#707171"
                    />
                    <path
                        id="Path_82"
                        d="M109.82,229.42c-1.08.45-1.49.84-1.16,1.48h0c.24.46.68.88,2.11.16l1.85-1.19A.72.72,0,0,0,113,229h0a.64.64,0,0,0-.85-.34Z"
                        fill="#e3e2e3"
                    />
                    <path
                        id="Path_83"
                        d="M113.12,228.61a.37.37,0,0,1,.18.21.52.52,0,0,1-.26.63l-.84.5c-.26.14-.36.09-.43,0a.51.51,0,0,1-.25-.27c-.09-.22-.2-.54.27-.73l.92-.4A.49.49,0,0,1,113.12,228.61Z"
                        fill="#f4f5f6"
                    />
                    <path
                        id="Path_84"
                        d="M108.39,227.72c-.87.38-1.37,1.06-1,1.65v.12c.46.69,1.25.39,2.08,0l2.69-1.3a.93.93,0,0,0,.48-1.2h0a.91.91,0,0,0-1.21-.45Z"
                        fill="#e3e2e3"
                    />
                    <path
                        id="Path_85"
                        d="M107,225.94c-.85.37-1.27,1.16-.91,1.76h0c.36.58,1.2.52,2,.13l3.79-1.71a.89.89,0,0,0,.43-1.17h0a.9.9,0,0,0-1.18-.46Z"
                        fill="#e3e2e3"
                    />
                    <path
                        id="Path_86"
                        d="M106.72,223.9c-1,.41-1.42,1.23-1,1.81h0c.41.56,1.28.41,2.18,0l3.08-1.49a.81.81,0,0,0,.38-1.07h0a.85.85,0,0,0-1.11-.39Z"
                        fill="#e3e2e3"
                    />
                    <path
                        id="Path_87"
                        d="M112.82,226.58a.51.51,0,0,1,.21.27.66.66,0,0,1-.38.76l-1.08.54c-.33.15-.44.07-.53,0a.63.63,0,0,1-.28-.36c-.09-.28-.21-.67.39-.87l1.17-.41a.62.62,0,0,1,.5.07Z"
                        fill="#f4f5f6"
                    />
                    <path
                        id="Path_88"
                        d="M112.51,224.53a.49.49,0,0,1,.21.26.64.64,0,0,1-.36.77l-1.06.57c-.33.16-.44.08-.53,0a.63.63,0,0,1-.29-.35c-.1-.27-.22-.67.37-.88l1.16-.44A.58.58,0,0,1,112.51,224.53Z"
                        fill="#f4f5f6"
                    />
                    <path
                        id="Path_89"
                        d="M111.6,222.58a.49.49,0,0,1,.21.26.64.64,0,0,1-.35.77l-1.06.58c-.32.16-.44.08-.53,0a.63.63,0,0,1-.29-.35c-.1-.28-.23-.67.36-.89l1.16-.44A.58.58,0,0,1,111.6,222.58Z"
                        fill="#f4f5f6"
                    />
                    <path
                        id="Path_90"
                        d="M118.48,189.92c-1.13.31-1.58.64-1.34,1.32h0c.17.48.56,1,2.07.43l2-.93a.72.72,0,0,0,.46-.83h0a.64.64,0,0,0-.8-.45Z"
                        fill="#e3e2e3"
                    />
                    <path
                        id="Path_91"
                        d="M121.86,189.55a.46.46,0,0,1,.16.23.53.53,0,0,1-.35.6l-.9.38a.518.518,0,0,1-.43,0,.51.51,0,0,1-.21-.31c-.06-.23-.13-.56.36-.69l1-.27a.51.51,0,0,1,.37.06Z"
                        fill="#f4f5f6"
                    />
                    <path
                        id="Path_92"
                        d="M117.29,188.05c-.92.26-1.5.87-1.23,1.5v.12c.37.75,1.18.56,2.06.27L121,189a1,1,0,0,0,.63-1.13h0a.91.91,0,0,0-1.14-.6Z"
                        fill="#e3e2e3"
                    />
                    <path
                        id="Path_93"
                        d="M116.17,186.11c-.9.25-1.42,1-1.14,1.61h0c.28.63,1.12.68,2,.4l4-1.19a.9.9,0,0,0,.58-1.11h0a.91.91,0,0,0-1.11-.61Z"
                        fill="#e3e2e3"
                    />
                    <path
                        id="Path_94"
                        d="M116.13,184c-1,.29-1.57,1-1.23,1.66h0c.33.61,1.22.58,2.17.25l3.24-1.07a.8.8,0,0,0,.52-1h0a.87.87,0,0,0-1-.54Z"
                        fill="#e3e2e3"
                    />
                    <path
                        id="Path_95"
                        d="M121.83,187.5a.51.51,0,0,1,.17.3.65.65,0,0,1-.47.7l-1.14.39c-.35.11-.45,0-.53-.06a.65.65,0,0,1-.23-.39c-.06-.29-.12-.69.5-.81l1.21-.25a.6.6,0,0,1,.49.12Z"
                        fill="#f4f5f6"
                    />
                    <path
                        id="Path_96"
                        d="M121.79,185.42a.47.47,0,0,1,.18.3.65.65,0,0,1-.46.71l-1.12.42a.733.733,0,0,1-.54,0,.72.72,0,0,1-.24-.39c-.06-.28-.13-.69.48-.82l1.21-.28a.59.59,0,0,1,.49.06Z"
                        fill="#f4f5f6"
                    />
                    <path
                        id="Path_97"
                        d="M121.14,183.37a.48.48,0,0,1,.18.29.64.64,0,0,1-.45.72l-1.12.43a.7.7,0,0,1-.53,0,.66.66,0,0,1-.25-.38c-.06-.29-.13-.7.48-.83l1.2-.29a.59.59,0,0,1,.49.06Z"
                        fill="#f4f5f6"
                    />
                    <path
                        id="Path_98"
                        d="M143.77,94c-19.45-8.21-31.77,7.65-29.72,19-2.27,14.59,12.82,19.11,12.82,19.11a13.93,13.93,0,0,1-2.94-6.29c8.15,3.86,20.22-3.11,20.22-3.11s-3-1.27-5.81-2.68c6.28-.6,10.07,4.47,10.36,3.67a30.87,30.87,0,0,0,1.12-5.78,30.859,30.859,0,0,1,3,6.54C153.55,118.07,156,99.21,143.77,94Z"
                        fill="#707171"
                    />
                    <path
                        id="Path_99"
                        d="M142.84,146.75l4.71,8.19,1.13-1.13-5.3-7.66Z"
                        fill="#707171"
                    />
                    <path
                        id="Path_100"
                        d="M159.44,134.13l7.59,8.08-1.52.99-6.8-8.72Z"
                        fill="#707171"
                    />
                    <path
                        id="Path_101"
                        d="M165.28,133.09c-.05-1.18.13-1.7.85-1.68h0c.52,0,1.09.25,1,1.85l-.29,2.18a.74.74,0,0,1-.66.69h0a.65.65,0,0,1-.66-.64Z"
                        fill="#e3e2e3"
                    />
                    <path
                        id="Path_102"
                        d="M165.93,136.42a.38.38,0,0,0,.27.08.51.51,0,0,0,.46-.5l.1-1c0-.29-.07-.36-.14-.41a.56.56,0,0,0-.35-.11c-.24,0-.58,0-.56.55v1a.44.44,0,0,0,.22.39Z"
                        fill="#f4f5f6"
                    />
                    <path
                        id="Path_103"
                        d="M163.13,132.5c0-.94.39-1.69,1.07-1.62h.13c.82.12.88,1,.87,1.89v3a.94.94,0,0,1-.89.94h0a.91.91,0,0,1-.91-.91Z"
                        fill="#e3e2e3"
                    />
                    <path
                        id="Path_104"
                        d="M161,132c0-.93.51-1.64,1.2-1.56h0c.68.08,1,.86,1,1.78v4.15a.9.9,0,0,1-.88.89h0a.92.92,0,0,1-.92-.89Z"
                        fill="#e3e2e3"
                    />
                    <path
                        id="Path_105"
                        d="M159,132.6c0-1,.53-1.81,1.22-1.68h0c.68.13.91,1,.88,2l-.05,3.41a.81.81,0,0,1-.82.8h0a.85.85,0,0,1-.82-.84Z"
                        fill="#e3e2e3"
                    />
                    <path
                        id="Path_106"
                        d="M164,137a.51.51,0,0,0,.34.08.66.66,0,0,0,.53-.66v-1.21c0-.36-.12-.43-.21-.49a.68.68,0,0,0-.44-.1c-.3,0-.7.1-.63.72l.12,1.23A.57.57,0,0,0,164,137Z"
                        fill="#f4f5f6"
                    />
                    <path
                        id="Path_107"
                        d="M162,137.59a.54.54,0,0,0,.33.08.65.65,0,0,0,.55-.65l.06-1.2c0-.37-.11-.44-.2-.5a.61.61,0,0,0-.43-.11c-.3,0-.71.08-.65.7l.09,1.24A.62.62,0,0,0,162,137.59Z"
                        fill="#f4f5f6"
                    />
                    <path
                        id="Path_108"
                        d="M159.82,137.58a.43.43,0,0,0,.33.08.63.63,0,0,0,.55-.64l.08-1.2c0-.37-.11-.44-.2-.5a.68.68,0,0,0-.44-.12c-.29,0-.7.08-.65.7l.08,1.24A.62.62,0,0,0,159.82,137.58Z"
                        fill="#f4f5f6"
                    />
                    <path
                        id="Path_109"
                        d="M376.29,160.63l.45-6.24c.1-1.63-1.15-4.75-2.68-5.34-4.59-1.77-15-4.23-10.34.58,0,0,.49,4.75,1.95,16.2a7.9,7.9,0,0,1-.32,2Z"
                        fill="#707171"
                    />
                    <rect
                        id="Rectangle_7"
                        width="2.53"
                        height="17.15"
                        transform="translate(350.131 133.105) rotate(-54.26)"
                        fill="#707171"
                    />
                    <path
                        id="Path_110"
                        d="M348.26,140.6l9.37,6.74,14.67-20.63-9.24-6.65Z"
                        fill="#e3e2e3"
                    />
                    <path
                        id="Path_111"
                        d="M361.41,110.44c-6.37,8.86-6.35,19.78.06,24.39s16.77,1.17,23.14-7.69,6.35-19.77-.06-24.38S367.78,101.59,361.41,110.44Z"
                        fill="#f4f5f6"
                    />
                    <path
                        id="Path_112"
                        d="M365.63,142.57l-3-2.16-6.79-.89-2.67-5.91-2.76-2c-2.8-2-7.47,2.44-8.88,4.65l-16.67,28.49,14.53,10.46,25-22.69C366.1,150.5,368.43,144.59,365.63,142.57Z"
                        fill="#fff"
                    />
                    <path
                        id="Path_113"
                        d="M344.26,178.73l24.56-30c1.69-2-.33-4.2-3.13-6.22l-.11-.08-31.67,28.83Z"
                        fill="#707171"
                    />
                    <path
                        id="Path_114"
                        d="M333.14,170.72l17.8-38.81-2.37-1.7c-2.8-2-7.29-4.14-8.7-1.93L318,159.85Z"
                        fill="#707171"
                    />
                    <path
                        id="Path_115"
                        d="M337.51,173.87l21.1-22.9-.14-1.92,2.44-.06,5.4-6-.78-1.92-31.66,30.19Z"
                        fill="#e3e2e3"
                    />
                    <path
                        id="Path_116"
                        d="M346.26,140.27l-1.85.48L329.4,168l3.64,2.62L351.6,131l-2.06-.13-4,7Z"
                        fill="#e3e2e3"
                    />
                    <path
                        id="Path_117"
                        d="M314.35,157.2l26.5-26.52a4.22,4.22,0,0,0,0-6l-.06-.06a4.23,4.23,0,0,0-6,0l-27.45,27.49Z"
                        fill="#707171"
                    />
                    <path
                        id="Path_118"
                        d="M363.92,138.75l.48,3.18-4.4,2.73.5-3.65-4.63-1.44Z"
                        fill="#f4f5f6"
                    />
                    <path
                        id="Path_119"
                        d="M354.38,131.88l-3.2.35-1.3,4.26,3.51-.6,2.54,3.69-.69-3.2Z"
                        fill="#f4f5f6"
                    />
                    <path
                        id="Path_120"
                        d="M357.71,117.31s-.27-20.61,13.93-22.82c11.93-6.67,25.15,6.44,24.31,15.4-1.49,9.76-8.5,14.59-11.34,17.25,3.67-5.74,3.91-8.45,3.62-9.6-.74-2-.22,1-12.22-9.29-2.83-2.43-8.2,2.37-10.6,5.77C368.13,105.42,362,107.57,357.71,117.31Z"
                        fill="#707171"
                    />
                    <path
                        id="Path_121"
                        d="M390.15,179.95l-10.41.08,4.48-9.98,19.11-11.45,5.93,9.9Z"
                        fill="#e3e2e3"
                    />
                    <ellipse
                        id="Ellipse_3"
                        data-name="Ellipse 3"
                        cx="19.75"
                        cy="14.95"
                        rx="19.75"
                        ry="14.95"
                        transform="translate(387.534 157.333) rotate(-30.93)"
                        fill="#e3e2e3"
                    />
                    <path
                        id="Path_122"
                        d="M362.61,209.21l33.3-19.1c2.32-1.23,2.55-4.34.78-7.3l-3-4.93-10.2-.44,4.21-9.55-3.09-5.17c-1.78-3-4.54-4.69-6.71-3.18l-32.28,21.38Z"
                        fill="#707171"
                    />
                    <path
                        id="Path_123"
                        d="M387.38,195,402,204.31c1.34,1.18,3.11,4.86,2.72,6.81-1.17,5.91-2.07,5.45-3.42,4.27,0,0-6.48-3.55-14.5-9.17-3.17-2.22-6.28-4.37-5.2-7.91Z"
                        fill="#707171"
                    />
                    <path
                        id="Path_124"
                        d="M380.44,162.69v-.09c.92-2.14.71-5.08-1.43-6l-15.18-1.8-6.25,7.63,17.28,2.47a4.22,4.22,0,0,0,5.58-2.21Z"
                        fill="#e3e2e3"
                    />
                    <path
                        id="Path_125"
                        d="M394.43,186h.1c2.32.19,4.81,1.77,4.61,4.09l-4.47,16.93L386,208.6l3.9-18.72a4.21,4.21,0,0,1,4.53-3.88Z"
                        fill="#e3e2e3"
                    />
                    <path
                        id="Path_126"
                        d="M380.44,162.69v-.09c.92-2.14,1.69-5.41-.45-6.33l-8.85-1c-1.43,2.59-3.09,6.57-4.57,9.13l9,1.77C377.72,167.1,379.52,164.83,380.44,162.69Z"
                        fill="#707171"
                    />
                    <path
                        id="Path_127"
                        d="M398,197.68l1.11-7.54c.2-2.32-2.29-3.9-4.61-4.09h-.1c-2.32-.2-5.4-.44-5.6,1.88l-1.51,10C390.1,197.81,395.25,197.78,398,197.68Z"
                        fill="#707171"
                    />
                    <path
                        id="Path_128"
                        d="M405.62,211.72l-11.1,5.77,10.15,5.75,17.5-3.01-1.8-10.49Z"
                        fill="#e3e2e3"
                    />
                    <path
                        id="Path_129"
                        d="M424.8,199.56c-9.58,1.64-15.95,8.55-15.24,12.7,1.19,6.93,10,15.09,21.26,13.23,9.59-1.58,15.17-14.08,14-21S434.37,197.91,424.8,199.56Z"
                        fill="#f4f5f6"
                    />
                    <path
                        id="Path_130"
                        d="M422.6,224.93c3.55,2.95,24.76,8.43,26.74-4.65,6-4.17,4.38-21.85-5.76-23.09-5.05-1.49-13.42.8-16.66,2,4.56.56,14-4.22,14.27,11.91.18,11.78-5.77,3.73-12.77,10.71C436.37,223,433.38,226.7,422.6,224.93Z"
                        fill="#707171"
                    />
                    <path
                        id="Path_131"
                        d="M368.74,240.68l38.16-9.41c1.62-.54,1.61-2.68,1.2-5.06l-.59-3.46a6.89,6.89,0,0,1-5.05-5.88c-.66-3.85,3.15-5.15,3.15-5.15l-.28-1.67c-.41-2.38-2.38-4.16-4.08-4.13l-37.35,6.54Z"
                        fill="#707171"
                    />
                    <path
                        id="Path_132"
                        d="M369.76,246.59l1.29,7.55,37.05-17.46c1.38-.83,1.45-2.47,1.18-4.08-.84-4.86-1.74-4.57-3.12-3.74Z"
                        fill="#707171"
                    />
                    <path
                        id="Path_133"
                        d="M395.5,185.32a1.55,1.55,0,0,0,1.28,1.77l2.86.43c.84.14,1.87-.92,2-1.76h0a1.54,1.54,0,0,0-1.28-1.76l-3.1.05a1.53,1.53,0,0,0-1.76,1.27Z"
                        fill="#e3e2e3"
                    />
                    <path
                        id="Path_134"
                        d="M391.17,186.83A1.54,1.54,0,0,0,393,188l4.31-1a1.54,1.54,0,0,0,1.15-1.85h0a1.54,1.54,0,0,0-1.85-1.15l-4.31,1a1.54,1.54,0,0,0-1.15,1.85Z"
                        fill="#e3e2e3"
                    />
                    <path
                        id="Path_135"
                        d="M395.92,181.43a1.54,1.54,0,0,0,.87,2l2.08,2.36c.79.31,2.45-.91,2.76-1.7h0a1.55,1.55,0,0,0-.87-2l-2.84-1.52a1.55,1.55,0,0,0-2,.87Z"
                        fill="#e3e2e3"
                    />
                    <path
                        id="Path_136"
                        d="M391.61,183.79a1.54,1.54,0,0,0,1.93,1l4.22-1.32a1.53,1.53,0,0,0,1-1.93h0a1.55,1.55,0,0,0-1.94-1l-4.22,1.32a1.53,1.53,0,0,0-1,1.93Z"
                        fill="#e3e2e3"
                    />
                    <path
                        id="Path_137"
                        d="M391.91,189.62a1.32,1.32,0,0,0,1.51,1.1l3.76-.56a1.33,1.33,0,0,0,1.1-1.52h0a1.33,1.33,0,0,0-1.52-1.1l-3.76.56a1.32,1.32,0,0,0-1.09,1.52Z"
                        fill="#e3e2e3"
                    />
                    <path
                        id="Path_138"
                        d="M395.56,189.2a1.33,1.33,0,0,0,1.62.94l3-.72a1.32,1.32,0,0,0,1-1.61h0a1.33,1.33,0,0,0-1.62-.95l-3,.72a1.33,1.33,0,0,0-.95,1.62Z"
                        fill="#e3e2e3"
                    />
                    <path
                        id="Path_139"
                        d="M393.36,192a1.17,1.17,0,0,0,1.35.94l3.31-.55a1.17,1.17,0,0,0,.94-1.35h0a1.17,1.17,0,0,0-1.35-.94l-3.3.55a1.16,1.16,0,0,0-.95,1.35Z"
                        fill="#e3e2e3"
                    />
                    <path
                        id="Path_140"
                        d="M397,191.76a1.16,1.16,0,0,0,1.58.45l1.46-.8a1.17,1.17,0,0,0,.45-1.59h0a1.17,1.17,0,0,0-1.59-.44l-1.45.79a1.17,1.17,0,0,0-.45,1.59Z"
                        fill="#e3e2e3"
                    />
                    <path
                        id="Path_141"
                        d="M406.28,234.88a1.52,1.52,0,0,0-.91,2l1,2.71a2.2,2.2,0,0,0,2.51.89h0a1.53,1.53,0,0,0,.91-2l-1.55-2.69a1.52,1.52,0,0,0-2-.91Z"
                        fill="#e3e2e3"
                    />
                    <path
                        id="Path_142"
                        d="M402.86,231.82a1.55,1.55,0,0,0-.1,2.18l2.95,3.29a1.53,1.53,0,0,0,2.17.1h0a1.53,1.53,0,0,0,.1-2.17l-3-3.3a1.54,1.54,0,0,0-2.17-.1Z"
                        fill="#e3e2e3"
                    />
                    <path
                        id="Path_143"
                        d="M409.89,233.35a1.54,1.54,0,0,0-1.32,1.73l-1,3c.11.84,2,1.7,2.83,1.58h0a1.53,1.53,0,0,0,1.31-1.73l-.05-3.23a1.53,1.53,0,0,0-1.73-1.31Z"
                        fill="#e3e2e3"
                    />
                    <path
                        id="Path_144"
                        d="M405.72,230.73a1.53,1.53,0,0,0,.07,2.17l3.21,3a1.53,1.53,0,0,0,2.17-.08h0a1.53,1.53,0,0,0-.07-2.17l-3.21-3a1.53,1.53,0,0,0-2.17.08Z"
                        fill="#e3e2e3"
                    />
                    <path
                        id="Path_145"
                        d="M400.78,233.83a1.32,1.32,0,0,0-.23,1.86l2.32,3a1.33,1.33,0,0,0,1.86.22h0a1.32,1.32,0,0,0,.23-1.86l-2.32-3a1.31,1.31,0,0,0-1.86-.22Z"
                        fill="#e3e2e3"
                    />
                    <path
                        id="Path_146"
                        d="M402.93,236.82a1.32,1.32,0,0,0,0,1.87l2.08,2.25a1.32,1.32,0,0,0,1.87,0h0a1.32,1.32,0,0,0,0-1.87l-2.08-2.25a1.32,1.32,0,0,0-1.87,0Z"
                        fill="#e3e2e3"
                    />
                    <path
                        id="Path_147"
                        d="M399.38,236.28a1.17,1.17,0,0,0-.17,1.64l2.09,2.61a1.16,1.16,0,0,0,1.64.17h0a1.16,1.16,0,0,0,.16-1.64l-2.1-2.61a1.15,1.15,0,0,0-1.63-.17Z"
                        fill="#e3e2e3"
                    />
                    <path
                        id="Path_148"
                        d="M401.38,239.29a1.16,1.16,0,0,0,.38,1.6l1.4.88a1.17,1.17,0,0,0,1.61-.38h0a1.17,1.17,0,0,0-.39-1.6l-1.4-.88a1.16,1.16,0,0,0-1.6.38Z"
                        fill="#e3e2e3"
                    />
                    <path
                        id="Path_149"
                        d="M381.49,161.93a1.53,1.53,0,0,1-.95-2l1-2.74a2.21,2.21,0,0,1,2.5-.93h0a1.54,1.54,0,0,1,.95,2l-1.5,2.72a1.54,1.54,0,0,1-2,.94Z"
                        fill="#e3e2e3"
                    />
                    <path
                        id="Path_150"
                        d="M378.12,165.05a1.54,1.54,0,0,1-.13-2.18l2.89-3.34a1.54,1.54,0,0,1,2.17-.14h0a1.54,1.54,0,0,1,.14,2.17l-2.9,3.35a1.54,1.54,0,0,1-2.17.14Z"
                        fill="#e3e2e3"
                    />
                    <path
                        id="Path_151"
                        d="M385.12,163.4a1.55,1.55,0,0,1-1.35-1.71l-1.09-2.94c.1-.85,2-1.73,2.8-1.63h0a1.54,1.54,0,0,1,1.35,1.7v3.23a1.53,1.53,0,0,1-1.71,1.35Z"
                        fill="#e3e2e3"
                    />
                    <path
                        id="Path_152"
                        d="M381,166.09a1.55,1.55,0,0,1,0-2.18l3.15-3.1a1.55,1.55,0,0,1,2.18,0h0a1.55,1.55,0,0,1,0,2.18l-3.15,3.1a1.55,1.55,0,0,1-2.18,0Z"
                        fill="#e3e2e3"
                    />
                    <path
                        id="Path_153"
                        d="M376,163.08a1.31,1.31,0,0,1-.26-1.85l2.27-3.06a1.32,1.32,0,0,1,1.85-.25h0a1.32,1.32,0,0,1,.26,1.86l-2.27,3.05A1.32,1.32,0,0,1,376,163.08Z"
                        fill="#e3e2e3"
                    />
                    <path
                        id="Path_154"
                        d="M378.1,160.05a1.33,1.33,0,0,1-.07-1.87l2-2.28a1.32,1.32,0,0,1,1.87-.07h0a1.33,1.33,0,0,1,.07,1.87L380,160a1.32,1.32,0,0,1-1.87.07Z"
                        fill="#e3e2e3"
                    />
                    <path
                        id="Path_155"
                        d="M374.56,160.65a1.16,1.16,0,0,1-.19-1.63l2-2.65a1.15,1.15,0,0,1,1.63-.2h0a1.17,1.17,0,0,1,.2,1.64l-2,2.65a1.17,1.17,0,0,1-1.64.19Z"
                        fill="#e3e2e3"
                    />
                    <path
                        id="Path_156"
                        d="M376.51,157.61a1.16,1.16,0,0,1,.35-1.61l1.39-.91a1.16,1.16,0,0,1,1.61.35h0a1.16,1.16,0,0,1-.35,1.61l-1.39.91a1.16,1.16,0,0,1-1.61-.35Z"
                        fill="#e3e2e3"
                    />
                    <path
                        id="Path_157"
                        d="M337.7,126.85a1.54,1.54,0,0,1-2.16-.27l-1.76-2.3a2.19,2.19,0,0,1,.57-2.6h0a1.54,1.54,0,0,1,2.16.27l1.46,2.74a1.54,1.54,0,0,1-.27,2.16Z"
                        fill="#e3e2e3"
                    />
                    <path
                        id="Path_158"
                        d="M338.47,131.37a1.54,1.54,0,0,1-1.89-1.07l-1.23-4.25a1.53,1.53,0,0,1,1.07-1.89h0a1.53,1.53,0,0,1,1.89,1.06l1.23,4.25a1.54,1.54,0,0,1-1.07,1.9Z"
                        fill="#e3e2e3"
                    />
                    <path
                        id="Path_159"
                        d="M340.91,124.6a1.55,1.55,0,0,1-2.17.2l-3.06-.68c-.66-.55-.39-2.59.15-3.24h0a1.53,1.53,0,0,1,2.17-.2l2.71,1.75a1.55,1.55,0,0,1,.2,2.17Z"
                        fill="#e3e2e3"
                    />
                    <path
                        id="Path_160"
                        d="M340.92,129.52a1.55,1.55,0,0,1-1.81-1.21l-.88-4.34a1.54,1.54,0,0,1,1.22-1.8h0a1.54,1.54,0,0,1,1.8,1.22l.89,4.33A1.54,1.54,0,0,1,340.92,129.52Z"
                        fill="#e3e2e3"
                    />
                    <path
                        id="Path_161"
                        d="M335.67,132.07a1.32,1.32,0,0,1-1.69-.79l-1.32-3.57a1.31,1.31,0,0,1,.79-1.69h0a1.33,1.33,0,0,1,1.7.79l1.32,3.57a1.32,1.32,0,0,1-.8,1.69Z"
                        fill="#e3e2e3"
                    />
                    <path
                        id="Path_162"
                        d="M334.28,128.66a1.32,1.32,0,0,1-1.61-1l-.81-3a1.34,1.34,0,0,1,1-1.61h0a1.34,1.34,0,0,1,1.6,1l.81,3a1.32,1.32,0,0,1-1,1.6Z"
                        fill="#e3e2e3"
                    />
                    <path
                        id="Path_163"
                        d="M332.85,132a1.17,1.17,0,0,1-1.48-.73l-1.11-3.16a1.16,1.16,0,0,1,.73-1.47h0a1.17,1.17,0,0,1,1.48.73l1.11,3.15a1.17,1.17,0,0,1-.73,1.48Z"
                        fill="#e3e2e3"
                    />
                    <path
                        id="Path_164"
                        d="M331.36,128.67a1.17,1.17,0,0,1-1.16-1.18v-1.65a1.16,1.16,0,0,1,1.17-1.16h0a1.17,1.17,0,0,1,1.16,1.17v1.66a1.17,1.17,0,0,1-1.17,1.16Z"
                        fill="#e3e2e3"
                    />
                    <path
                        id="Path_165"
                        d="M416.81,174.4c-5.4,1-10.23.75-11.89-1.88a5.23,5.23,0,0,0,.74-6.31l-2-3.37a5.25,5.25,0,0,0-5.63-2.4l.08-.11c-1.7-2.83,0-6.68,3.4-11-7.31,4.37-10.71,10.4-10.95,15.79a5.23,5.23,0,0,0-.22,5.71l2,3.37a5.21,5.21,0,0,0,5.58,2.41C403.11,178.69,410.35,178.27,416.81,174.4Zm-19.25-11.51a3.08,3.08,0,0,1,4.23,1.06l2,3.37a3.08,3.08,0,0,1-1.06,4.23,11.248,11.248,0,0,1-1.77,1.1,8.654,8.654,0,0,1-.89-.08c-2.14-1-4.55-5.19-4.63-7.67a9.472,9.472,0,0,1,.4-1Z"
                        fill="#707171"
                    />
                    <path id="Path_166" d="M353.39,135.89l2.13,4.74-5.16-.52Z" fill="#707171" />
                    <path id="Path_167" d="M360.5,141.01l-5.16-.52,2.13,4.73Z" fill="#707171" />
                    <rect
                        id="Rectangle_8"
                        width="2.1"
                        height="1.93"
                        rx="0.54"
                        transform="matrix(0.584, -0.812, 0.812, 0.584, 353.914, 140.865)"
                        fill="#707171"
                    />
                    <path
                        id="Path_168"
                        d="M196.25,115.76l.87,10.9-11.72-6.07-8.06-18.08,10.85-4.83Z"
                        fill="#e3e2e3"
                    />
                    <path
                        id="Path_169"
                        d="M198.17,179.76l-21-56.54c-.49-1.83,1.5-3.15,4-4.25l2.83-1.26,8.39.09,2.5-5,3-1.32c2.46-1.1,4.65-1.4,5.68.19l28.09,53.13Z"
                        fill="#707171"
                    />
                    <path
                        id="Path_170"
                        d="M195.18,181.1l-7.42,3.3L171.6,125.49c-.21-1.81,1.24-2.9,2.91-3.65,5-2.23,5.39-1.2,5.59.61Z"
                        fill="#707171"
                    />
                    <path
                        id="Path_171"
                        d="M259.52,106.64l-5.17-6.21-52.12,9.71c-1.81.6-2.06,2.32-1.86,4.07.6,5.3,1.76,5.18,3.56,4.58Z"
                        fill="#707171"
                    />
                    <path
                        id="Path_172"
                        d="M167.47,98.35c4.41,9.89,13.79,15.32,20.95,12.13s9.39-13.8,5-23.69-13.79-15.33-21-12.13S163.06,88.45,167.47,98.35Z"
                        fill="#f4f5f6"
                    />
                    <path
                        id="Path_173"
                        d="M229.96,104.77l5.91,12.62,6.88-11.03-2.52-19.63-11.78,1.51Z"
                        fill="#e3e2e3"
                    />
                    <path
                        id="Path_174"
                        d="M217.32,82.82c1.38,10.75,8.78,18.17,13.43,17.58,7.78-1,17.28-10.43,15.74-23.06-1.31-10.75-15-17.57-22.75-16.57S215.94,72.08,217.32,82.82Z"
                        fill="#e3e2e3"
                    />
                    <path
                        id="Path_175"
                        d="M245.56,87.09c3.45-3.81,10.55-27.18-3.92-30-4.36-6.86-24.13-5.9-26,5.32-1.9,5.56.26,15,1.47,18.66.85-5-6.26-16,13.95-15.33,13.12.42,3.88,6.6,11.32,14.73C244.07,71.67,248,75.17,245.56,87.09Z"
                        fill="#707171"
                    />
                    <path
                        id="Path_176"
                        d="M226.73,166.05l-3.43-56.7c0-1.89,2.12-4,4.79-4.34l1.87-.24s1.27,4.3,5.59,3.75a7.68,7.68,0,0,0,6.79-5.34l3.88-.5c2.67-.35,5.06-.25,5.59,1.57L263,161.39Z"
                        fill="#707171"
                    />
                    <path
                        id="Path_177"
                        d="M264.72,161.17,248.93,105.1c-.77-1.65-1-2.73,4.46-3.43,1.81-.23,3.62,0,4.38,1.6l15.28,56.83Z"
                        fill="#707171"
                    />
                    <path
                        id="Path_178"
                        d="M180.32,69.78c13.17,1.6,13.08,17,13.08,17l-2.1-3.13c-2.8-1-4.23-2.41-6.58-6.51,0,0-.14,6.53-8.62,10.46a8.1,8.1,0,0,0,2-3.27l-.27.15c-2.25,1.82-6,3.64-5.93,7.12a10.27,10.27,0,0,1-1.33-4s-.05,2.49-3.21,6.3a15.84,15.84,0,0,1,.11,4.68C152.27,85.07,164.42,65.28,180.32,69.78Z"
                        fill="#707171"
                    />
                    <path
                        id="Path_179"
                        d="M254.13,105.14a1.71,1.71,0,0,0,2.37.54l2.57-2.32a1.7,1.7,0,0,0,.53-2.36h0a1.71,1.71,0,0,0-2.36-.54l-2.57,2.32a1.71,1.71,0,0,0-.54,2.36Z"
                        fill="#e3e2e3"
                    />
                    <path
                        id="Path_180"
                        d="M252.56,110.42a1.72,1.72,0,0,0,2.29-.82l2.14-4.44a1.71,1.71,0,0,0-.81-2.28h0a1.72,1.72,0,0,0-2.29.82l-2.14,4.43a1.72,1.72,0,0,0,.81,2.29Z"
                        fill="#e3e2e3"
                    />
                    <path
                        id="Path_181"
                        d="M251.79,101.36a1.72,1.72,0,0,0,2.2,1l3.5-.81a1.72,1.72,0,0,0,1-2.2h0a1.71,1.71,0,0,0-2.19-1l-3.51.81a1.72,1.72,0,0,0-1,2.2Z"
                        fill="#e3e2e3"
                    />
                    <path
                        id="Path_182"
                        d="M249.91,106.51a1.71,1.71,0,0,0,2.36-.59l2.57-4.2a1.73,1.73,0,0,0-.6-2.36h0a1.72,1.72,0,0,0-2.35.59l-2.57,4.21a1.71,1.71,0,0,0,.59,2.35Z"
                        fill="#e3e2e3"
                    />
                    <path
                        id="Path_183"
                        d="M256.33,112.78a1.48,1.48,0,0,0,1.79-1.08l1.05-4.1a1.49,1.49,0,0,0-1.08-1.79h0a1.49,1.49,0,0,0-1.79,1.08L255.25,111a1.47,1.47,0,0,0,1.08,1.78Z"
                        fill="#e3e2e3"
                    />
                    <path
                        id="Path_184"
                        d="M257,108.28a1.47,1.47,0,0,0,2.05-.4l2-2.78a1.48,1.48,0,0,0-.4-2h0a1.47,1.47,0,0,0-2.05.4l-2,2.78a1.48,1.48,0,0,0,.39,2Z"
                        fill="#e3e2e3"
                    />
                    <path
                        id="Path_185"
                        d="M259.78,113.74a1.3,1.3,0,0,0,1.35-1.24l.2-3.73a1.29,1.29,0,0,0-1.24-1.35h0a1.3,1.3,0,0,0-1.35,1.24l-.2,3.73a1.29,1.29,0,0,0,1.24,1.35Z"
                        fill="#e3e2e3"
                    />
                    <path
                        id="Path_186"
                        d="M259.55,109.55a1.3,1.3,0,0,0,1.73-.61l.81-1.65a1.31,1.31,0,0,0-.6-1.74h0a1.3,1.3,0,0,0-1.74.61l-.81,1.66A1.3,1.3,0,0,0,259.55,109.55Z"
                        fill="#e3e2e3"
                    />
                    <path
                        id="Path_187"
                        d="M176.25,125.41a1.71,1.71,0,0,1-1.82,1.6l-3.36-.8a1.71,1.71,0,0,1-1.6-1.82h0a1.71,1.71,0,0,1,1.82-1.61l3.36.81a1.71,1.71,0,0,1,1.6,1.82Z"
                        fill="#e3e2e3"
                    />
                    <path
                        id="Path_188"
                        d="M180.15,129.29a1.71,1.71,0,0,1-2.39.38l-4-2.87a1.71,1.71,0,0,1-.37-2.4h0a1.71,1.71,0,0,1,2.39-.38l4,2.87a1.71,1.71,0,0,1,.37,2.4Z"
                        fill="#e3e2e3"
                    />
                    <path
                        id="Path_189"
                        d="M176.5,121a1.72,1.72,0,0,1-1.44,2l-3.46,1a1.71,1.71,0,0,1-2-1.43h0a1.72,1.72,0,0,1,1.44-2l3.46-1a1.72,1.72,0,0,1,2,1.43Z"
                        fill="#e3e2e3"
                    />
                    <path
                        id="Path_190"
                        d="M180.62,124.59a1.72,1.72,0,0,1-2.35.61L174,122.74a1.72,1.72,0,0,1-.61-2.35h0a1.71,1.71,0,0,1,2.35-.61l4.27,2.46a1.73,1.73,0,0,1,.61,2.35Z"
                        fill="#e3e2e3"
                    />
                    <path
                        id="Path_191"
                        d="M178.34,132.3a1.47,1.47,0,0,1-2.07.24l-3.34-2.6a1.47,1.47,0,0,1-.24-2.07h0a1.47,1.47,0,0,1,2.07-.24l3.34,2.6a1.47,1.47,0,0,1,.24,2.07Z"
                        fill="#e3e2e3"
                    />
                    <path
                        id="Path_192"
                        d="M175.23,129.54a1.47,1.47,0,0,1-2,.63l-3.06-1.5a1.48,1.48,0,0,1-.64-2h0a1.48,1.48,0,0,1,2-.63l3.06,1.51a1.47,1.47,0,0,1,.64,2Z"
                        fill="#e3e2e3"
                    />
                    <path
                        id="Path_193"
                        d="M175.61,133.78a1.29,1.29,0,0,1-1.81.28l-3-2.18a1.3,1.3,0,0,1-.28-1.81h0a1.31,1.31,0,0,1,1.82-.29l3,2.18a1.3,1.3,0,0,1,.28,1.82Z"
                        fill="#e3e2e3"
                    />
                    <path
                        id="Path_194"
                        d="M173.2,132a1.31,1.31,0,0,1-1.82.3l-1.5-1.07a1.29,1.29,0,0,1-.3-1.81h0a1.29,1.29,0,0,1,1.81-.3l1.51,1.07a1.3,1.3,0,0,1,.3,1.81Z"
                        fill="#e3e2e3"
                    />
                    <path
                        id="Path_195"
                        d="M207.67,119.54l13-12.13c1.44-1.12,5.09-2,6.59-.92,4.54,3.09-1.48,9.22-2.91,10.35L211.82,127.4Z"
                        fill="#707171"
                    />
                    <path
                        id="Path_196"
                        d="M189.81,80.92a27.941,27.941,0,0,1,2.38,4.28c4.41,9.9,2.08,19.79-4.85,22.87s-15.84-1.79-20.25-11.68a28.2,28.2,0,0,1-1.59-4.63,26.06,26.06,0,0,0,2,6.59c4.41,9.89,13.79,15.32,20.95,12.13s9.39-13.8,5-23.69A26.39,26.39,0,0,0,189.81,80.92Z"
                        fill="#707171"
                    />
                    <path
                        id="Path_197"
                        d="M185.53,99.8l-2.79,1.2a4.07,4.07,0,0,0-2.06,5.38l1.51,3.41,5.52-.23,4.72-4.33-1.52-3.41A4.08,4.08,0,0,0,185.53,99.8Zm4.6,4.43c-.51,1.89-5.34,3.9-7.17,3.19-.36-.79-.75-1.68-.75-1.68a2.4,2.4,0,0,1,1.21-3.17l2.79-1.24a2.41,2.41,0,0,1,3.17,1.21,8.511,8.511,0,0,1,.75,1.69Z"
                        fill="#707171"
                    />
                    <rect
                        id="Rectangle_9"
                        width="17.08"
                        height="10.25"
                        transform="translate(240.266 425.959) rotate(-84.54)"
                        fill="#e3e2e3"
                    />
                    <path
                        id="Path_198"
                        d="M258.79,433.61c.89-9.3-3.91-17-8-17.35-6.73-.64-15.32,4.1-16.43,15-1,9.3,8,19,14.77,19.66S257.9,442.92,258.79,433.61Z"
                        fill="#f4f5f6"
                    />
                    <path
                        id="Path_199"
                        d="M236.09,425s-12.72,13.13-2.46,25.89c5.84,5.49,15.84,6.5,22.11,1.27,5.14-6.2,4-13.81,2.87-16.89-1.68,4.08-1.29,13-6.34,9.88-.82-.52-.49-1.05-5.54-.29-6.38,1-9.09-1.11-7.78-13.57C235.86,438.42,233.33,433.71,236.09,425Z"
                        fill="#707171"
                    />
                    <path
                        id="Path_200"
                        d="M256.76,445.93c0-.18-.92-4.84-9.7-7.3.79.55,2.27,3.94,2.27,3.94s-4.69-3.15-7.45-3.84c1.83,1.62-.22,6.77-.22,6.77Z"
                        fill="#707171"
                    />
                    <path
                        id="Path_201"
                        d="M269,368.68l-29-2.77a126.239,126.239,0,0,1-11.58,38.66s5.79,5,10.26,6.45c1.19.39,1.75.86,2.9,1.15.76-2.59,6.41-2.61,9.15-1.31,1.41.67,1,2.8,1,2.8,4.32.65,11.18.14,11.8-2.06,0,0,3-10.85,1.66-14.25C265.15,397.35,268.1,380.5,269,368.68Z"
                        fill="#707171"
                    />
                    <path
                        id="Path_202"
                        d="M256.86,412.41a4.41,4.41,0,0,0,6.23-.14l18.6-19.49,1.08-11.34a4.35,4.35,0,0,0-3.69,1.31l-22.36,23.43a4.41,4.41,0,0,0,.14,6.23Z"
                        fill="#707171"
                    />
                    <path
                        id="Path_203"
                        d="M229.12,372.94l2.36-7.84-9.5-.91c-.77,1.61-1.3,2.71-1.35,2.77a8.55,8.55,0,0,0-.66,5.63l7.23,29.85a4.41,4.41,0,1,0,8.57-2.07Z"
                        fill="#707171"
                    />
                    <path
                        id="Path_204"
                        d="M279,425.15c.85,23.7,19.11,25.35,22.87,20.87,5.45,2.09,20.61-8.23,9.83-29.36-2.59-5.06-1.41-14.35,4.42-13.55-7.63-5-19.7-5.33-25.38,0-7.57-1.93-18,4.25-22.19,12.31C273.24,411.92,278.79,419.47,279,425.15Z"
                        fill="#707171"
                    />
                    <path
                        id="Path_205"
                        d="M284.33,431.7c-1.39-7.74-2.6-19.58-9.92-19.42-.91,0-.05-6.32,12.68-9.62,7.14-1.85,18.68,7.83,21.92,17.51s.06,19.05-7.08,20.9S286.13,441.76,284.33,431.7Z"
                        fill="#707171"
                    />
                    <path
                        id="Path_206"
                        d="M284,407.81l14.88-3.87.66,2.56-15.15,3.93Z"
                        fill="#f4f5f6"
                    />
                    <rect
                        id="Rectangle_10"
                        width="8.41"
                        height="17.77"
                        transform="matrix(0.968, -0.251, 0.251, 0.968, 286.727, 404.35)"
                        fill="#e3e2e3"
                    />
                    <path
                        id="Path_207"
                        d="M284.45,429.62c-2.45-9.41,1.09-18.48,7.91-20.25s14.31,4.43,16.75,13.85-1.1,18.47-7.91,20.24S286.89,439,284.45,429.62Z"
                        fill="#f4f5f6"
                    />
                    <path
                        id="Path_208"
                        d="M278.11,386.31l2,20.44c.65,1.57,2.4,1.69,4.44,1.16l13.88-3.61c2-.53,3.74-1.34,3.54-3l-7.32-19Z"
                        fill="#f4f5f6"
                    />
                    <path
                        id="Path_209"
                        d="M311.2,421.69a14,14,0,0,0-6.9,7.63c-3.25-2-10.32-3.26-18.08,1.4a11.67,11.67,0,0,1,6.58,1.1s-5.22-1-8.47-.12,6.16,18.78,17.45,14.33C304.73,446.59,318.35,439.28,311.2,421.69Z"
                        fill="#707171"
                    />
                    <path
                        id="Path_210"
                        d="M266.54,388.77l-1.15,8.33,7.73,6.25a3.31,3.31,0,0,0,4.38-.65c.95-1.29,1.75-2.71.46-3.66Z"
                        fill="#f4f5f6"
                    />
                    <path
                        id="Path_211"
                        d="M296,362.38l3.77,30.67a2.9,2.9,0,1,0,5.8-.1l-3.76-32.07Z"
                        fill="#f4f5f6"
                    />
                    <path
                        id="Path_212"
                        d="M277.49,397.49l2.64,9.26c.65,1.57,2.4,1.69,4.44,1.16l3.35-14.72,10.53,11.11c2-.53,4.74-1.7,4.54-3.39L297.07,384l-21.44-5.39Z"
                        fill="#828282"
                    />
                    <path
                        id="Path_213"
                        d="M301.16,402.76c1.6.32,5.33-1.48,5.1-4l-.68-5.83-5.88-.79C299.37,393.76,299.56,402.43,301.16,402.76Z"
                        fill="#828282"
                    />
                    <path
                        id="Path_214"
                        d="M272.91,403.61l4.41,4.38a3,3,0,0,0,3.87-4.49l-3.55-5.76Z"
                        fill="#828282"
                    />
                    <path
                        id="Path_215"
                        d="M299.88,392l-9.87-28-18,4.67,5.63,29.13s0-11.24,9.82-6.42C292,383,299.88,392,299.88,392Z"
                        fill="#707171"
                    />
                    <path
                        id="Path_216"
                        d="M296.34,395.75l3.91,8.08-.71,2.67-7.52-7.64Z"
                        fill="#e3e2e3"
                    />
                    <path
                        id="Path_217"
                        d="M281.96,399.48l.51,8.97,1.92,1.98,2.86-10.33Z"
                        fill="#e3e2e3"
                    />
                    <path
                        id="Path_218"
                        d="M290.7,370.84a1.18,1.18,0,0,1,.5-1.59l2-1a1.68,1.68,0,0,1,1.88.79h0a1.18,1.18,0,0,1-.5,1.59l-2.26.72a1.18,1.18,0,0,1-1.59-.5Z"
                        fill="#e3e2e3"
                    />
                    <path
                        id="Path_219"
                        d="M287.2,370.8a1.17,1.17,0,0,1,1.06-1.28l3.37-.34a1.17,1.17,0,0,1,1.27,1.06h0a1.17,1.17,0,0,1-1.06,1.28l-3.36.34a1.17,1.17,0,0,1-1.28-1.06Z"
                        fill="#e3e2e3"
                    />
                    <path
                        id="Path_220"
                        d="M292,373.56a1.18,1.18,0,0,1,.15-1.66l.94-2.21c.5-.42,2,.07,2.41.56h0a1.18,1.18,0,0,1-.15,1.66l-1.69,1.8a1.18,1.18,0,0,1-1.66-.15Z"
                        fill="#e3e2e3"
                    />
                    <path
                        id="Path_221"
                        d="M288.25,372.89a1.17,1.17,0,0,1,1.16-1.19l3.38-.07a1.17,1.17,0,0,1,1.19,1.16h0a1.17,1.17,0,0,1-1.16,1.19l-3.38.07a1.17,1.17,0,0,1-1.19-1.16Z"
                        fill="#e3e2e3"
                    />
                    <path
                        id="Path_222"
                        d="M287.05,368.6a1,1,0,0,1,.83-1.17l2.87-.51a1,1,0,0,1,1.16.84h0a1,1,0,0,1-.83,1.16l-2.86.51a1,1,0,0,1-1.17-.83Z"
                        fill="#e3e2e3"
                    />
                    <path
                        id="Path_223"
                        d="M289.81,368a1,1,0,0,1,.94-1.07l2.33-.2a1,1,0,0,1,1.08.94h0a1,1,0,0,1-.94,1.07l-2.34.21a1,1,0,0,1-1.07-.95Z"
                        fill="#e3e2e3"
                    />
                    <path
                        id="Path_224"
                        d="M287.52,366.49a.89.89,0,0,1,.76-1l2.52-.4a.88.88,0,0,1,1,.74h0a.88.88,0,0,1-.75,1l-2.52.41a.91.91,0,0,1-1-.75Z"
                        fill="#e3e2e3"
                    />
                    <path
                        id="Path_225"
                        d="M290.21,365.82a.88.88,0,0,1,1-.71l1.24.22a.88.88,0,0,1,.71,1h0a.88.88,0,0,1-1,.71l-1.24-.22a.88.88,0,0,1-.71-1Z"
                        fill="#e3e2e3"
                    />
                    <path
                        id="Path_226"
                        d="M238.19,378.37c-1-.23-1.41-.5-1.22-1.11h0c.13-.43.45-.86,1.8-.44l1.78.75a.65.65,0,0,1,.43.71h0a.57.57,0,0,1-.69.42Z"
                        fill="#f4f5f6"
                    />
                    <path
                        id="Path_227"
                        d="M241.16,378.59a.3.3,0,0,0,.13-.21.45.45,0,0,0-.32-.51l-.8-.31a.537.537,0,0,0-.38,0,.44.44,0,0,0-.17.27c-.05.21-.1.5.33.6l.86.2A.39.39,0,0,0,241.16,378.59Z"
                        fill="#e3e2e3"
                    />
                    <path
                        id="Path_228"
                        d="M237.2,380.06c-.81-.2-1.34-.72-1.13-1.28v-.11c.3-.66,1-.53,1.81-.3l2.51.73a.83.83,0,0,1,.6,1h0a.81.81,0,0,1-1,.56Z"
                        fill="#f4f5f6"
                    />
                    <path
                        id="Path_229"
                        d="M236.28,381.8c-.8-.2-1.28-.82-1.05-1.39h0c.22-.56,1-.63,1.73-.41l3.53.92a.79.79,0,0,1,.55.95h0a.8.8,0,0,1-1,.57Z"
                        fill="#f4f5f6"
                    />
                    <path
                        id="Path_230"
                        d="M236.31,383.61c-.88-.22-1.41-.86-1.14-1.42h0c.27-.55,1.06-.54,1.9-.28l2.88.83a.71.71,0,0,1,.49.87h0a.74.74,0,0,1-.9.5Z"
                        fill="#f4f5f6"
                    />
                    <path
                        id="Path_231"
                        d="M241.2,380.39a.4.4,0,0,0,.14-.26.56.56,0,0,0-.44-.6l-1-.31c-.31-.08-.39,0-.46.06a.58.58,0,0,0-.19.36c0,.25-.08.61.46.69l1.08.18a.48.48,0,0,0,.41-.12Z"
                        fill="#e3e2e3"
                    />
                    <path
                        id="Path_232"
                        d="M241.23,382.22a.46.46,0,0,0,.15-.27.57.57,0,0,0-.42-.61l-1-.33c-.31-.09-.4,0-.47.05a.59.59,0,0,0-.2.35c0,.25-.09.61.45.71l1.07.2A.51.51,0,0,0,241.23,382.22Z"
                        fill="#e3e2e3"
                    />
                    <path
                        id="Path_233"
                        d="M240.73,384a.46.46,0,0,0,.15-.26.59.59,0,0,0-.42-.62l-1-.34c-.31-.09-.39,0-.47.05a.66.66,0,0,0-.2.34c0,.26-.09.62.45.72l1.06.21A.53.53,0,0,0,240.73,384Z"
                        fill="#e3e2e3"
                    />
                    <path
                        id="Path_234"
                        d="M92.43,362c-9.61-14.87,13.89-37.19,53-43.79,5.9,11.78-8.8,38.9-28.95,47.29C102.15,371.42,96.94,368.93,92.43,362Z"
                        fill="#707171"
                    />
                    <path
                        id="Path_235"
                        d="M108.22,338.76s7.88-8.53,16-11.68c9.54-3.72,19.62-2.56,21.23-8.91,5.9,11.78-16.13,40.94-28.95,47.29C102.15,371.42,105.74,349.88,108.22,338.76Z"
                        fill="#707171"
                    />
                    <rect
                        id="Rectangle_11"
                        width="16.34"
                        height="8.32"
                        transform="matrix(0.861, -0.509, 0.509, 0.861, 117.711, 343.988)"
                        fill="#e3e2e3"
                    />
                    <path
                        id="Path_236"
                        d="M119.9,363.29c7-4.67,10.81-10.65,9.07-13.87-5.13-9.53-14-11.93-21.36-7.61s-10,11-5.81,20.22C105.23,369.49,112.83,368,119.9,363.29Z"
                        fill="#f4f5f6"
                    />
                    <path
                        id="Path_237"
                        d="M112.14,344.73a3.45,3.45,0,1,0-2.622.38,3.46,3.46,0,0,0,2.622-.38Z"
                        fill="#f4f5f6"
                    />
                    <path
                        id="Path_238"
                        d="M160.62,310.09l12.45,21.07c-7.14,2.87-17,7.18-17.52,9.2-1.63,6.7-11.35,7.85-11.35,7.85-.62.19-6.43,4-6.43,4-1.46.86-5.89-4.57-6.53-6.17L127,338.49c-.63-1.6-1.11-11.87.35-12.73C127.35,325.76,153.09,328,160.62,310.09Z"
                        fill="#f4f5f6"
                    />
                    <path
                        id="Path_239"
                        d="M167.83,335.82l13.29,17.76-.54,4.84-17.82-15.66-21.63,10.09a3.48,3.48,0,0,1-4.76-1.73,3.83,3.83,0,0,1,2-4.88l24.83-11.58a3.55,3.55,0,0,1,3.69.36,4,4,0,0,1,.94.8Z"
                        fill="#f4f5f6"
                    />
                    <path
                        id="Path_240"
                        d="M141.63,298.75l13.8,2.55,2.92,4.95-15.55.91-9.09,22a3.712,3.712,0,0,1-6.86-2.84l10.43-25.2a4.49,4.49,0,0,1,4.35-2.33Z"
                        fill="#f4f5f6"
                    />
                    <path
                        id="Path_241"
                        d="M160.63,310.11l12.45,21.07c-7.33,3.05-18.13,8.2-18.71,10.21-1.71,5.86-6.53,7.33-9.42,7.67-1.35.16-10.25-11.75-5.14-24.12C147.5,323.46,156.63,319.62,160.63,310.11Z"
                        fill="#828282"
                    />
                    <path
                        id="Path_242"
                        d="M108.48,338.24s-4.48,4.45,1.21,3.18c-.8,4.88-6.57,3.09-7,13.29,6.66,12.64,13.42,11,13.42,11-3,5.6-9.54,8.43-16.72,4.3C85.53,362.68,86.94,338.44,108.48,338.24Z"
                        fill="#707171"
                    />
                    <path
                        id="Path_243"
                        d="M160.63,310.11l12.86,21.77c-7.39,2.94-18.54,7.5-19.12,9.51a10.47,10.47,0,0,1-2.79,4.74c1.67-2.47-.63-5.88-3-6.59,1.75-2.86-1.53-11.16-9.12-11.86.13-.88.25-1.78.36-2.74C147.5,323.46,156.63,319.62,160.63,310.11Z"
                        fill="#707171"
                    />
                    <path
                        id="Path_244"
                        d="M203.46,385.74l-9.72-10.09-14.15,9.14-6.1-5.81L193,367.58a3.63,3.63,0,0,1,3.75.05,4.1,4.1,0,0,1,1.06.71l11.24,11.54a4.063,4.063,0,0,1-5.63,5.86Z"
                        fill="#e3e2e3"
                    />
                    <path
                        id="Path_245"
                        d="M184.79,395.26a3,3,0,0,1-4-1.49l-8.1-17.65-11.38,2.2a3.362,3.362,0,0,1-1-6.65l13.1-2.46a3.138,3.138,0,0,1,1.06,0A3,3,0,0,1,177,371l9.3,20.26a3,3,0,0,1-1.51,4Z"
                        fill="#f4f5f6"
                    />
                    <rect
                        id="Rectangle_12"
                        width="18.2"
                        height="8.61"
                        transform="matrix(0.426, -0.905, 0.905, 0.426, 181.302, 412.09)"
                        fill="#e3e2e3"
                    />
                    <ellipse
                        id="Ellipse_4"
                        data-name="Ellipse 4"
                        cx="17.79"
                        cy="13.28"
                        rx="17.79"
                        ry="13.28"
                        transform="translate(163.671 431.941) rotate(-71.27)"
                        fill="#f4f5f6"
                    />
                    <path
                        id="Path_246"
                        d="M219.35,365.24,203,402.88c-1,1.46-2.73,1.25-4.69.33L185.05,397c-2-.92-3.52-2.06-3-3.72l19.32-36.45Z"
                        fill="#f4f5f6"
                    />
                    <path
                        id="Path_247"
                        d="M219.5,365.31l-13.3,30.46c-3.79-4.22-12.7-7.48-18.52,2.43-4-1.18-2.7-10.58-2.7-10.58l16.35-30.85Z"
                        fill="#828282"
                    />
                    <path
                        id="Path_248"
                        d="M224,394.44l-19.84,10.17a3.035,3.035,0,0,1-2.77-5.4l17.28-8.86,2.14-24.43,4.05,1.9.75,22.92a3.431,3.431,0,0,1,0,1.06,3,3,0,0,1-1.61,2.64Z"
                        fill="#f4f5f6"
                    />
                    <path
                        id="Path_249"
                        d="M219.5,365.31l-13.3,30.46-.91-1c.32-1.92,1.1-7.33-7.36-9.21a5.17,5.17,0,0,0-2.73.29l-.58.19-.22-.57a6.44,6.44,0,0,0-1.51-2.29c-1.9-1.81-6.5,2.09-7.91,4.45l16.35-30.85Z"
                        fill="#707171"
                    />
                    <path
                        id="Path_250"
                        d="M164.74,377.3l1.65-9.98-10.57,1.78-14.24,15.49,8.91,8.2Z"
                        fill="#e3e2e3"
                    />
                    <path
                        id="Path_251"
                        d="M152.06,403c7-7.59,8.88-19,3.14-24.28s-17-2.43-23.93,5.16-10.56,15.65-2.24,23.3C137.83,415.28,145.08,410.59,152.06,403Z"
                        fill="#f4f5f6"
                    />
                    <path
                        id="Path_252"
                        d="M198.33,355.2l-25.74,29c-1.58,1.91-4.5,1.53-6.9-.67l-3.5-3.22,1.05-10-10.11,1.65-3.73-3.43c-2.4-2.21-3.47-5.09-1.66-6.81L175.31,334Z"
                        fill="#707171"
                    />
                    <path
                        id="Path_253"
                        d="M183.07,382.54l-5.36,3.55c-4.12,2.92-10.38-1.45-10.38-1.45l2.06-2.19c-1.1-1.91-.26-2.91,1.65-4l9.43-3.66C181.48,377.2,182,380.13,183.07,382.54Z"
                        fill="#707171"
                    />
                    <path
                        id="Path_254"
                        d="M145.44,356.41c-2.57,4.35,2.31,10.22,2.31,10.22l2-2.24c2,.94,2.92,0,3.85-2l2.86-9.7C154,351.91,145.44,356.41,145.44,356.41Z"
                        fill="#707171"
                    />
                    <path
                        id="Path_255"
                        d="M145.14,360.94c-1,.39-1.48.39-1.67-.23h0c-.13-.44-.11-1,1.26-1.41l1.93-.39a.65.65,0,0,1,.77.35h0a.57.57,0,0,1-.34.75Z"
                        fill="#e3e2e3"
                    />
                    <path
                        id="Path_256"
                        d="M147.76,359.41a.31.31,0,0,0,0-.25.48.48,0,0,0-.56-.25l-.85.21c-.26.07-.28.17-.31.24a.53.53,0,0,0,0,.33c.08.2.21.47.63.3l.84-.32A.38.38,0,0,0,147.76,359.41Z"
                        fill="#f4f5f6"
                    />
                    <path
                        id="Path_257"
                        d="M145.28,362.93c-.8.3-1.54.17-1.69-.43v-.11c-.14-.73.55-1,1.34-1.29l2.53-.84a.85.85,0,0,1,1.06.47h0a.82.82,0,0,1-.5,1Z"
                        fill="#e3e2e3"
                    />
                    <path
                        id="Path_258"
                        d="M145.51,364.92c-.78.3-1.54.05-1.68-.56h0c-.13-.6.44-1.08,1.22-1.34l3.49-1.26a.79.79,0,0,1,1,.48h0a.81.81,0,0,1-.47,1Z"
                        fill="#e3e2e3"
                    />
                    <path
                        id="Path_259"
                        d="M146.59,366.43c-.88.32-1.69.09-1.78-.54h0c-.09-.62.57-1.06,1.43-1.33l2.9-1a.71.71,0,0,1,.91.45h0a.76.76,0,0,1-.47.94Z"
                        fill="#e3e2e3"
                    />
                    <path
                        id="Path_260"
                        d="M148.84,360.9a.45.45,0,0,0,0-.3.59.59,0,0,0-.72-.26l-1,.33c-.31.11-.33.23-.35.32a.58.58,0,0,0,0,.41c.12.23.29.56.79.32l1-.47a.56.56,0,0,0,.28-.35Z"
                        fill="#f4f5f6"
                    />
                    <path
                        id="Path_261"
                        d="M149.92,362.41a.51.51,0,0,0,0-.3.59.59,0,0,0-.71-.27l-1,.3c-.31.1-.33.22-.36.31a.64.64,0,0,0,0,.41c.11.23.27.57.78.33l1-.44A.53.53,0,0,0,149.92,362.41Z"
                        fill="#f4f5f6"
                    />
                    <path
                        id="Path_262"
                        d="M150.54,364.23a.44.44,0,0,0,0-.3.58.58,0,0,0-.71-.28l-1,.29c-.31.1-.33.22-.36.32a.56.56,0,0,0,0,.4c.11.24.27.57.78.34l1-.43a.52.52,0,0,0,.29-.34Z"
                        fill="#f4f5f6"
                    />
                    <path
                        id="Path_263"
                        d="M191.42,362.61c.73.75,1.19.95,1.62.47h0c.3-.35.5-.86-.58-1.8l-1.62-1.14a.64.64,0,0,0-.84,0h0a.56.56,0,0,0,0,.82Z"
                        fill="#e3e2e3"
                    />
                    <path
                        id="Path_264"
                        d="M189.63,360.15a.39.39,0,0,1,.11-.22.48.48,0,0,1,.62,0l.69.53c.21.17.19.27.18.35a.47.47,0,0,1-.14.29c-.15.15-.38.35-.69,0l-.65-.64a.44.44,0,0,1-.12-.31Z"
                        fill="#f4f5f6"
                    />
                    <path
                        id="Path_265"
                        d="M190.49,364.37c.6.6,1.34.78,1.71.29l.08-.08c.41-.62-.09-1.17-.71-1.72l-2-1.79a.84.84,0,0,0-1.16,0h0a.82.82,0,0,0,0,1.16Z"
                        fill="#e3e2e3"
                    />
                    <path
                        id="Path_266"
                        d="M189.47,366.1c.59.59,1.39.67,1.76.17h0c.37-.49,0-1.17-.57-1.72L188,362a.8.8,0,0,0-1.12,0h0a.82.82,0,0,0,0,1.14Z"
                        fill="#e3e2e3"
                    />
                    <path
                        id="Path_267"
                        d="M187.88,367.05c.67.65,1.51.76,1.84.22h0c.33-.52-.09-1.2-.77-1.79l-2.26-2.05a.72.72,0,0,0-1,.05h0a.75.75,0,0,0,.05,1Z"
                        fill="#e3e2e3"
                    />
                    <path
                        id="Path_268"
                        d="M188.05,361.09a.46.46,0,0,1,.15-.27.58.58,0,0,1,.76.06l.81.71c.24.22.21.34.19.44a.55.55,0,0,1-.2.35c-.2.17-.49.4-.86,0l-.73-.83A.53.53,0,0,1,188.05,361.09Z"
                        fill="#f4f5f6"
                    />
                    <path
                        id="Path_269"
                        d="M186.45,362a.46.46,0,0,1,.15-.27.58.58,0,0,1,.76,0l.82.68c.25.22.22.34.21.44a.59.59,0,0,1-.2.36c-.19.17-.48.41-.85,0l-.75-.82A.52.52,0,0,1,186.45,362Z"
                        fill="#f4f5f6"
                    />
                    <path
                        id="Path_270"
                        d="M185.14,363.45a.46.46,0,0,1,.15-.27.58.58,0,0,1,.76,0l.83.68c.24.22.22.34.21.44a.63.63,0,0,1-.19.36c-.2.17-.48.41-.86,0l-.76-.81A.55.55,0,0,1,185.14,363.45Z"
                        fill="#f4f5f6"
                    />
                    <path
                        id="Path_271"
                        d="M158.76,344.63l-5.53,15.75-7.79-3.97,1.07-6.07Z"
                        fill="#e3e2e3"
                    />
                    <path
                        id="Path_272"
                        d="M133.9,381.22c-3.93,2.09-17.9,10.13-13.53,18.71-2.83,3.39,5.78,24.42,24.74,12.48a21.156,21.156,0,0,1,1.71-2.37s5.15-4,7.15-9.63c-5.64,4.78-9.56,5.94-9.56,5.94s-9.47-9.85-17.62-9.36c2.93-4.43.88-7.24.88-7.24Z"
                        fill="#707171"
                    />
                    <path
                        id="Path_273"
                        d="M156.77,318.69A1.19,1.19,0,0,0,157,317l-1.41-1.77a1.71,1.71,0,0,0-2.07-.05h0a1.19,1.19,0,0,0-.2,1.68l1.79,1.62a1.21,1.21,0,0,0,1.69.2Z"
                        fill="#e3e2e3"
                    />
                    <path
                        id="Path_274"
                        d="M160,320.13a1.2,1.2,0,0,0-.45-1.63l-3-1.73a1.2,1.2,0,0,0-1.64.45h0a1.2,1.2,0,0,0,.45,1.63l3,1.73a1.2,1.2,0,0,0,1.64-.45Z"
                        fill="#e3e2e3"
                    />
                    <path
                        id="Path_275"
                        d="M154.46,320.7a1.2,1.2,0,0,0,.56-1.6l.06-2.45c-.29-.6-1.88-.78-2.48-.49h0a1.2,1.2,0,0,0-.56,1.6l.82,2.38a1.21,1.21,0,0,0,1.6.56Z"
                        fill="#e3e2e3"
                    />
                    <path
                        id="Path_276"
                        d="M158.18,321.63a1.2,1.2,0,0,0-.58-1.59l-3.11-1.49a1.2,1.2,0,0,0-1.59.58h0a1.21,1.21,0,0,0,.58,1.6l3.11,1.48a1.2,1.2,0,0,0,1.59-.58Z"
                        fill="#e3e2e3"
                    />
                    <path
                        id="Path_277"
                        d="M161.1,318.15a1.05,1.05,0,0,0-.28-1.44L158.37,315a1,1,0,0,0-1.43.28h0a1,1,0,0,0,.28,1.44l2.45,1.67a1,1,0,0,0,1.43-.28Z"
                        fill="#e3e2e3"
                    />
                    <path
                        id="Path_278"
                        d="M158.78,316.45a1,1,0,0,0-.42-1.4l-2.08-1.17a1,1,0,0,0-1.4.43h0a1,1,0,0,0,.43,1.39l2.08,1.17a1,1,0,0,0,1.39-.42Z"
                        fill="#e3e2e3"
                    />
                    <path
                        id="Path_279"
                        d="M161.55,316a.91.91,0,0,0-.28-1.25l-2.18-1.44a.9.9,0,0,0-1.25.27h0a.9.9,0,0,0,.27,1.25l2.18,1.44a.92.92,0,0,0,1.26-.27Z"
                        fill="#e3e2e3"
                    />
                    <path
                        id="Path_280"
                        d="M159.34,314.24a.91.91,0,0,0-.67-1.1l-1.25-.31a.91.91,0,0,0-1.1.67h0a.9.9,0,0,0,.67,1.09l1.25.32a.91.91,0,0,0,1.1-.67Z"
                        fill="#e3e2e3"
                    />
                    <path
                        id="Path_281"
                        d="M214.25,371.35a1.2,1.2,0,0,1,1.66-.36l1.89,1.23a1.71,1.71,0,0,1,.25,2.06h0a1.2,1.2,0,0,1-1.66.36L214.61,373a1.22,1.22,0,0,1-.36-1.66Z"
                        fill="#f4f5f6"
                    />
                    <path
                        id="Path_282"
                        d="M212.52,368.23a1.2,1.2,0,0,1,1.67.29l2,2.81a1.19,1.19,0,0,1-.3,1.67h0a1.19,1.19,0,0,1-1.67-.3l-2-2.8a1.19,1.19,0,0,1,.3-1.67Z"
                        fill="#f4f5f6"
                    />
                    <path
                        id="Path_283"
                        d="M212.48,373.83a1.2,1.2,0,0,1,1.54-.7l2.43-.29c.62.23,1,1.8.72,2.42h0a1.2,1.2,0,0,1-1.54.71l-2.45-.6a1.2,1.2,0,0,1-.7-1.54Z"
                        fill="#f4f5f6"
                    />
                    <path
                        id="Path_284"
                        d="M211.2,370.22a1.19,1.19,0,0,1,1.64.42l1.77,3a1.2,1.2,0,0,1-.43,1.64h0a1.19,1.19,0,0,1-1.64-.43l-1.77-2.95a1.19,1.19,0,0,1,.43-1.64Z"
                        fill="#f4f5f6"
                    />
                    <path
                        id="Path_285"
                        d="M214.39,367a1,1,0,0,1,1.46.14l1.89,2.28a1,1,0,0,1-.15,1.45h0a1,1,0,0,1-1.45-.14l-1.89-2.28a1,1,0,0,1,.14-1.45Z"
                        fill="#f4f5f6"
                    />
                    <path
                        id="Path_286"
                        d="M216.3,369.13a1,1,0,0,1,1.43.29l1.36,2a1,1,0,0,1-.29,1.43h0a1,1,0,0,1-1.43-.29l-1.36-2a1,1,0,0,1,.29-1.43Z"
                        fill="#f4f5f6"
                    />
                    <path
                        id="Path_287"
                        d="M216.5,366.34a.91.91,0,0,1,1.27.15l1.64,2a.9.9,0,0,1-.16,1.27h0a.91.91,0,0,1-1.27-.15l-1.63-2a.9.9,0,0,1,.15-1.27Z"
                        fill="#f4f5f6"
                    />
                    <path
                        id="Path_288"
                        d="M218.45,368.37a.91.91,0,0,1,1.15.57l.43,1.21a.91.91,0,0,1-.56,1.16h0a.92.92,0,0,1-1.16-.56l-.43-1.22A.92.92,0,0,1,218.45,368.37Z"
                        fill="#f4f5f6"
                    />
                    <path
                        id="Path_289"
                        d="M163.58,430.31c.25,10.32,14.53,22.88,29.47,11.31,14.53-11.25,8.61-38.07,18.29-34.81a13.25,13.25,0,0,0-2-4.89l-5.24,2.69a3,3,0,0,1-3.67-.71,7.629,7.629,0,0,1-2.13-.69l-2.77-1.31a13.31,13.31,0,0,0-2.28,5.13c4,7.71,1.62,10.57.78,12.53a26.858,26.858,0,0,0-2-4.86c-.39-.65-2.8,4.48-8.42,5.08,2.21-1.74,4.61-3.39,4.61-3.39s-11.83-3.95-18.28.9a12.39,12.39,0,0,1,1.45-6S159,417.93,163.58,430.31Z"
                        fill="#707171"
                    />
                    <path
                        id="Path_290"
                        d="M387.6,245.5l23.67,12.33a4.29,4.29,0,0,1,1.9,5.45,3.63,3.63,0,0,1-5.08,1.78l-22.33-11.72-10.15,12.79,1.94-13.31,5.12-6.16a4.169,4.169,0,0,1,1-.86,3.61,3.61,0,0,1,3.93-.3Z"
                        fill="#e3e2e3"
                    />
                    <path
                        id="Path_291"
                        d="M394,245.37c-.16,1.69-.33,4.21-.33,4.21s-9.11,4.08-9.82,5.89c-5.87,9.27,11.08,12.37,13.24,5.28.91-3,1.56-4.59,2.94-5.19s2.93,1,5.85-1.62c-1.24-.78-2.59-1.55-2.59-1.55l.46-1.57a8,8,0,0,0,3.65-2.22,5.14,5.14,0,0,0-4.51-.76c-1.12-1.4-1.92-2.9-4.25-2.44l-1.42-2S394.16,243.69,394,245.37Z"
                        fill="#707171"
                    />
                    <path
                        id="Path_292"
                        d="M397.36,243.86v-.21c.23-.84-1.16-.25-1.16-.25s-.43.31.29,1.17S397.36,243.86,397.36,243.86Z"
                        fill="#494949"
                    />
                    <path
                        id="Path_293"
                        d="M403.76,250.82a8,8,0,0,0,3.65-2.22,5.14,5.14,0,0,0-4.51-.76,5.1,5.1,0,0,1,.86,2.98Z"
                        fill="#e3e2e3"
                    />
                    <path
                        id="Path_294"
                        d="M400.91,255.18c1.36-.21,2.25.89,4.58-1.11-.5-.31-3.06-1.2-3.21-1.29a1.52,1.52,0,0,0-1.37,2.4Z"
                        fill="#494949"
                    />
                    <path
                        id="Path_295"
                        d="M420.93,293.91c29.25,7.47,37.31-2.51,37.15-12.13,2.12-7.52-1.38-18.53-27.58-20.81-15.79-1.37-39.58,10.12-33.17,17.93C403.46,287.59,414.82,292.35,420.93,293.91Z"
                        fill="#707171"
                    />
                    <path
                        id="Path_296"
                        d="M404.48,278.18c-1.64,1.08-3.07,2-4.32,2.89l.07.06c3,2.57,17.1,10.65,35.13,12.26,11.29,1,16-5.87,17.15-14s-7-15.85-18.32-16.22C417.41,262.64,411.32,273.69,404.48,278.18Z"
                        fill="#707171"
                    />
                    <rect
                        id="Rectangle_13"
                        width="9.32"
                        height="19.71"
                        transform="matrix(0.144, -0.99, 0.99, 0.144, 408.432, 279.169)"
                        fill="#e3e2e3"
                    />
                    <ellipse
                        id="Ellipse_5"
                        data-name="Ellipse 5"
                        cx="14.14"
                        cy="19.53"
                        rx="14.14"
                        ry="19.53"
                        transform="matrix(0.144, -0.99, 0.99, 0.144, 413.939, 289.565)"
                        fill="#f4f5f6"
                    />
                    <path
                        id="Path_297"
                        d="M373.89,278,377,256.4l34.82,5.84c1.81.52,1.91,2.61,1.57,4.93l-2.29,15.74c-.33,2.31-2.16,5.67-3.93,5A339.467,339.467,0,0,0,373.89,278Z"
                        fill="#e3e2e3"
                    />
                    <path
                        id="Path_298"
                        d="M432.12,263.13c6.23-.78-3,24.07,12.83,26.37-4.81,3.47-11.37,4.6-19,.68,8.22,7.4,31.29,5,31.6-8.47C459.36,278.93,456.12,260.12,432.12,263.13Z"
                        fill="#707171"
                    />
                    <path
                        id="Path_299"
                        d="M377.21,255.13c16.78,4,20.6-.64,28.33,5.76a19,19,0,0,1,8.06,5c0,.26-.14,1-.18,1.28,0,0-11.1-5.53-12.1,1.34-1,7.08,10,12.9,10,12.9,0,.22-.29,1.92-.33,2.13-3.45,0-8.77.81-11.63,2.48a139.281,139.281,0,0,0-25.66-6.93Z"
                        fill="#fff"
                    />
                    <path
                        id="Path_300"
                        d="M372.35,293.17l.36-7.1,2.6-17.87,4.29,19.31,25.52-3.42a4.035,4.035,0,0,1,1.06,8L376.89,296a4,4,0,0,1-3.77-1.64,4.1,4.1,0,0,1-.77-1.19Z"
                        fill="#e3e2e3"
                    />
                    <path
                        id="Path_301"
                        d="M387.6,245.5l1.21.63-3.18,7.32-10.27,14.42,2.19-15.05,5-5.84a2.9,2.9,0,0,1,1.11-1.18,3.61,3.61,0,0,1,3.94-.3Z"
                        fill="#e3e2e3"
                    />
                    <path
                        id="Path_302"
                        d="M113,281.44l-.07.07c-1.87,1.57-3.06,4.43-1.48,6.31l5.22,4.39c2.57-1.76,12.06-5.18,14.65-6.88l-12.11-4.88C117.58,278.58,114.82,279.87,113,281.44Z"
                        fill="#707171"
                    />
                    <path
                        id="Path_303"
                        d="M130.49,321.09c-1.42-3.3-3-8.25-2.44-9.11,1.88,1.62,4.06,4.19,4.32,6s2.51,4.6,2.51,4.6l.9.31a2.76,2.76,0,0,0,1.49-3.05c-.4-2.2-3-3.63-3.48-5.4-.59-2.12-1.6-3.34-.9-3.81,2.34,0,17.32-4.42,22.76-7.14l-2.41-32.25c-7.37-3.87-21.14-5.71-21.14-5.71s-1.26,17.94-1.88,18.16c-4.68,1.6-7.64,4.18-10.44,6s-7.3,6.82-9.42,9.8c-4.36,3.15-5.2,4.65-8.17,5.15-2.79.48-6,.93-6,1.75s3.47,3.95,6.67,4a8.28,8.28,0,0,1-3.75,1.17c-1,.08-1.14.68-.3,1.2,3,1.88,5.21,2.16,9.42,1a54.193,54.193,0,0,1,4.05,5c.91,1.22,2.64,2.89,3.34,2.84s3.37.33,4,1.22,3.73,7,6,8.91c.75.64,2.31-.82,3-2.57C130.49,328.23,131.92,324.39,130.49,321.09Z"
                        fill="#707171"
                    />
                    <path
                        id="Path_304"
                        d="M130.55,322.79a29.439,29.439,0,0,0,3.93,2c.87.2,3.9-.16,3.92-.76s-2.61-1.13-3.22-1.41-2.37-4.14-2.56-4.92-.94-2.72-4.85-5.94c0,0-.77.79.81,5a53.866,53.866,0,0,1,1.97,6.03Z"
                        fill="#f4f5f6"
                    />
                    <path
                        id="Path_305"
                        d="M127.8,314.64a17.781,17.781,0,0,1,5.1,3.81,8.2,8.2,0,0,1-.28-.79c-.19-.78-.94-2.72-4.85-5.94C127.77,311.72,127.15,312.22,127.8,314.64Z"
                        fill="#e3e2e3"
                    />
                    <path
                        id="Path_306"
                        d="M105.8,310.05c-1,.56-1.83.44-3.22.4a10.66,10.66,0,0,1-3.75,1.17c-1,.08-1.15.68-.3,1.21,3,1.87,5.2,2.15,9.42.95C108,313.78,105.89,311.29,105.8,310.05Z"
                        fill="#494949"
                    />
                    <path
                        id="Path_307"
                        d="M128.37,329.17c.14-.08-3-3.69-4.81.48a7.13,7.13,0,0,0,1.82,2.09C126.13,332.38,127.68,330.92,128.37,329.17Z"
                        fill="#494949"
                    />
                    <path
                        id="Path_308"
                        d="M155.69,304l-.35-4.6c-3.84,2.18-16.9,7.07-19.45,5.88-6.86-3.19-10-2.48-14-1.47,3.8,1.38,8.2-2.76,10.9,7a.651.651,0,0,1,.12-.11C135.21,310.67,150.26,306.66,155.69,304Z"
                        fill="#494949"
                    />
                    <path
                        id="Path_309"
                        d="M148.8,303.87a1.37,1.37,0,0,1,1.92.3l1.24,2.49a1.37,1.37,0,0,1-.29,1.92h0a1.38,1.38,0,0,1-1.93-.29l-1.24-2.49a1.38,1.38,0,0,1,.3-1.93Z"
                        fill="#e3e2e3"
                    />
                    <path
                        id="Path_310"
                        d="M149.17,299.46a1.39,1.39,0,0,1,1.47,1.29l.3,3.95a1.38,1.38,0,0,1-1.28,1.47h0a1.39,1.39,0,0,1-1.47-1.29l-.3-3.95A1.38,1.38,0,0,1,149.17,299.46Z"
                        fill="#e3e2e3"
                    />
                    <path
                        id="Path_311"
                        d="M145.93,306a1.37,1.37,0,0,1,1.94-.13l2.39,1.64a1.39,1.39,0,0,1,.12,2h0a1.38,1.38,0,0,1-1.94.12L146.05,308a1.37,1.37,0,0,1-.12-1.94Z"
                        fill="#e3e2e3"
                    />
                    <path
                        id="Path_312"
                        d="M146,301.61a1.38,1.38,0,0,1,1.59,1.13l.68,3.9a1.37,1.37,0,0,1-1.13,1.59h0a1.38,1.38,0,0,1-1.59-1.13l-.69-3.91a1.38,1.38,0,0,1,1.14-1.58Z"
                        fill="#e3e2e3"
                    />
                    <path
                        id="Path_313"
                        d="M152,299a1.18,1.18,0,0,1,1.22,1.15l.12,3.4a1.18,1.18,0,0,1-1.15,1.22h0a1.18,1.18,0,0,1-1.22-1.15l-.12-3.41A1.18,1.18,0,0,1,152,299Z"
                        fill="#e3e2e3"
                    />
                    <path
                        id="Path_314"
                        d="M151.87,302.37a1.2,1.2,0,0,1,1.42.9l.65,2.67a1.18,1.18,0,0,1-.9,1.41h0a1.2,1.2,0,0,1-1.42-.9l-.65-2.66a1.19,1.19,0,0,1,.9-1.42Z"
                        fill="#e3e2e3"
                    />
                    <path
                        id="Path_315"
                        d="M154.28,300a1,1,0,0,1,1.11,1l.23,3a1.06,1.06,0,0,1-1,1.12h0a1.06,1.06,0,0,1-1.11-1l-.22-3a1,1,0,0,1,1-1.11Z"
                        fill="#e3e2e3"
                    />
                    <path
                        id="Path_316"
                        d="M154.45,302.34a1,1,0,0,1,1.11,1l.13,1.48a1,1,0,0,1-1,1.12h0a1,1,0,0,1-1.12-1l-.12-1.48a1.06,1.06,0,0,1,1-1.12Z"
                        fill="#e3e2e3"
                    />
                    <path
                        id="Path_317"
                        d="M111.87,262.14l9.81,4.82-8.9,7.26-23.32,1.75-.91-12.08Z"
                        fill="#e3e2e3"
                    />
                    <ellipse
                        id="Ellipse_6"
                        data-name="Ellipse 6"
                        cx="20.74"
                        cy="15.69"
                        rx="20.74"
                        ry="15.69"
                        transform="translate(59.996 256.366) rotate(-4.29)"
                        fill="#f4f5f6"
                    />
                    <path
                        id="Path_318"
                        d="M151.49,247.64l-40.24,2.26c-2.75,0-4.44,2.86-4.16,6.47l.45,6,9.37,5.21-8.46,7,.47,6.31c.27,3.61,2.06,6.54,4.8,6.14l40.36-4.87Z"
                        fill="#707171"
                    />
                    <path
                        id="Path_319"
                        d="M151.09,241.64l-.53-7.08-34.36,7.25c-2.14.62-2.7,3.39-2.08,5.53v.08a4,4,0,0,0,5,2.76Z"
                        fill="#e3e2e3"
                    />
                    <path
                        id="Path_320"
                        d="M120.07,239.55l-10.68,3.15c-2.9,1.2-3.25,5.17-2,8.07v.12c1.2,2.9,2.83,6.74,5.73,5.54l11.55-7.3S121.92,242.82,120.07,239.55Z"
                        fill="#707171"
                    />
                    <path
                        id="Path_321"
                        d="M84.24,254.8c5.54,1.6,10,4.11,10.27,7.35a5.5,5.5,0,0,0-3.66,5.58l.31,4.11a5.5,5.5,0,0,0,4.15,4.91l-.12.06c.26,3.46-3.16,6.27-8.38,8.74,8.91-.67,15-4.73,17.71-9.67a5.5,5.5,0,0,0,2.9-5.25l-.31-4.12a5.49,5.49,0,0,0-4.1-4.89C99.12,257.22,92.12,254.21,84.24,254.8Zm12.65,19.86a3.24,3.24,0,0,1-3.48-3l-.3-4.12a3.23,3.23,0,0,1,3-3.47,11.841,11.841,0,0,1,2.19-.2,8.083,8.083,0,0,1,.79.5c1.54,1.92,1.83,7,.73,9.37a8.626,8.626,0,0,1-.84.75Z"
                        fill="#707171"
                    />
                    <path
                        id="Path_322"
                        d="M68.23,284.18l1.22-12.77-2.94-13.05A18.8,18.8,0,0,0,54,272.63c.64-.19,3.47.76,3.47.76l-1.66,2.08s2.56.24,4.89,3.4A15.46,15.46,0,0,0,68.23,284.18Z"
                        fill="#707171"
                    />
                    <path
                        id="Path_323"
                        d="M289.11,99.93a3.16,3.16,0,0,1,4.46.11l13.91,14.64h0l2.1,6.77-.48.27a3.34,3.34,0,0,1-1,.34,3.14,3.14,0,0,1-3.11-.86l-16-16.79a3.16,3.16,0,0,1,.12-4.48Z"
                        fill="#e3e2e3"
                    />
                    <path
                        id="Path_324"
                        d="M305.42,107.53a3.15,3.15,0,0,0-4.16-1.6l-18.46,8.18-7.35-9.54a3.493,3.493,0,0,0-5.37,4.47L278.59,120a3.311,3.311,0,0,0,.84.71,3.15,3.15,0,0,0,3.2.41l21.19-9.4a3.16,3.16,0,0,0,1.6-4.19Z"
                        fill="#e3e2e3"
                    />
                    <path
                        id="Path_325"
                        d="M336.63,96.82c10.22-21.27-3.43-31.41-8.92-29.49-3.6-4.56-21.92-3.46-23.36,20.09-2.25,36.79,32.78,32.36,34.74,19.9C335.3,108.2,332.84,104.72,336.63,96.82Z"
                        fill="#707171"
                    />
                    <path
                        id="Path_326"
                        d="M323.24,111.37a3.36,3.36,0,0,1-.54-1.21c-.34-1.64-.29-4.91,3.75-9.41S316.36,92.4,311,90.37c-11.12-4.23-2.12,16.63-2.12,16.63,3.29,8.36,7.38,8.64,13.77,9.22a13.88,13.88,0,0,0,.59-4.85Z"
                        fill="#707171"
                    />
                    <rect
                        id="Rectangle_14"
                        width="18.91"
                        height="8.95"
                        transform="translate(308.962 110.208) rotate(-73.76)"
                        fill="#e3e2e3"
                    />
                    <ellipse
                        id="Ellipse_7"
                        data-name="Ellipse 7"
                        cx="18.49"
                        cy="13.8"
                        rx="18.49"
                        ry="13.8"
                        transform="translate(299.896 98.529) rotate(-67.3)"
                        fill="#f4f5f6"
                    />
                    <path
                        id="Path_327"
                        d="M289.45,147.64l12.44-40.42c.74-1.65,2.73-1.47,4.89-.84l14.65,4.27c2.16.63,3.7,1.69,3.43,3.48l-12.61,40.15Z"
                        fill="#e3e2e3"
                    />
                    <path
                        id="Path_328"
                        d="M289.39,147.63l10.21-33.52a64.33,64.33,0,0,1,22.46,7.5l-9.65,32.72Z"
                        fill="#707171"
                    />
                    <path
                        id="Path_329"
                        d="M311.94,71.4S307.82,84,327.43,88.61c-3,13.68-.72,11.88,1.16,14.72l1.88,2.84s8.16-21.78,5.23-28.69C327.75,58.73,311.94,71.4,311.94,71.4Z"
                        fill="#707171"
                    />
                    <path
                        id="Path_330"
                        d="M334.09,139.1,322.5,157.27l-8.5-2.49,14.44-20.14L321,115.85a3.154,3.154,0,1,1,5.87-2.31l8.46,21.58a3.13,3.13,0,0,1-.54,3.18A3.49,3.49,0,0,1,334.09,139.1Z"
                        fill="#e3e2e3"
                    />
                    <path
                        id="Path_331"
                        d="M289.39,147.63l8.89-29.6c-.4,2.19,2.4,4.6,5.13,4.48a6.73,6.73,0,0,0,2.75-.73l.57-.3.31.55a5.41,5.41,0,0,0,1.93,2.1c7.89,4.36,11.93-.82,13-2.54,0,0,.51.7.54.7l-10.1,32Z"
                        fill="#fff"
                        opacity="0.12"
                    />
                    <path
                        id="Path_332"
                        d="M266.78,84.74c.51-29,11.79-33.88,20.65-31.3,7.5,0,16.79,6,12.25,30.81-2.73,15-19.4,34.06-25,26.14C268.2,102.51,266.67,90.79,266.78,84.74Z"
                        fill="#707171"
                    />
                    <path
                        id="Path_333"
                        d="M277.16,104c-1.41,1.24-2.65,2.32-3.77,3.26a.593.593,0,0,0,0-.08c-1.62-3.42-5.51-18.52-2.43-35.62,1.93-10.7,9.48-13.29,17.28-12.32s12.89,10.5,10.36,21.07C294.82,95.93,283.05,98.76,277.16,104Z"
                        fill="#707171"
                    />
                    <rect
                        id="Rectangle_15"
                        width="18.91"
                        height="8.95"
                        transform="translate(277.238 99.386) rotate(-84.75)"
                        fill="#f4f5f6"
                    />
                    <path
                        id="Path_334"
                        d="M271.34,73.71C270.06,84,275.06,93.05,282.49,94S297,87.31,298.25,77s-3.72-19.3-11.15-20.22S272.61,63.43,271.34,73.71Z"
                        fill="#f4f5f6"
                    />
                    <path
                        id="Path_335"
                        d="M262.78,138.33,270,98.44c.46-1.75,2.3-2.12,4.53-1.84l15.14,1.88c2.23.27,4.13.89,4.16,2.7l-5.25,40.34Z"
                        fill="#e3e2e3"
                    />
                    <path
                        id="Path_336"
                        d="M298.1,82.2c2.3-5.57-23-3.37-21.14-18.57-4.44,3.58-7.15,9.36-5.46,17.45-4.76-9.49,3.33-30.23,15.85-27.1C290.39,53,307,60.75,298.1,82.2Z"
                        fill="#707171"
                    />
                    <path
                        id="Path_337"
                        d="M262.72,138.32,269,104.59a18,18,0,0,0,4.91-8h.62s-.88,11.3,5.93,12.14c6.61.82,9.21-10.26,9.21-10.26a1.117,1.117,0,0,1,.51.08,19.63,19.63,0,0,0,2.74,11.37l-4.39,31.58Z"
                        fill="#707171"
                    />
                    <path
                        id="Path_338"
                        d="M262.66,138.31l4.21-28.4,3-7.44-.71,3.63a7.408,7.408,0,0,0,.1,1.75,6.7,6.7,0,0,0,10.59,4.28l.51-.38.4.49A7.66,7.66,0,0,0,283,114c3.68,2,9-.32,9.61-4.56a2.871,2.871,0,0,0,.28.48L288.5,141.5Z"
                        fill="#fcfbfa"
                        opacity="0.13"
                    />
                    <path
                        id="Path_339"
                        d="M262.44,138.29l2.14-39.85A3.38,3.38,0,0,1,268.42,96c1.08.3,3.13.76,3.13.76L269,139.09Z"
                        fill="#e3e2e3"
                    />
                    <path
                        id="Path_340"
                        d="M264.54,100.82c-1.06-.27-1.58-.21-1.7.45h0c-.08.47,0,1,1.48,1.3l2.05.15a.68.68,0,0,0,.75-.46h0a.6.6,0,0,0-.45-.73Z"
                        fill="#e3e2e3"
                    />
                    <path
                        id="Path_341"
                        d="M267.44,102.06a.33.33,0,0,1,0,.26.48.48,0,0,1-.55.32l-.9-.1c-.27,0-.31-.13-.35-.21a.5.5,0,0,1,0-.34c.06-.21.15-.51.61-.39l.91.22a.42.42,0,0,1,.28.24Z"
                        fill="#707171"
                    />
                    <path
                        id="Path_342"
                        d="M264.42,98.75c-.86-.2-1.61,0-1.68.66v.12c-.05.77.7,1,1.55,1.16l2.72.53a.88.88,0,0,0,1-.62h0a.84.84,0,0,0-.65-1Z"
                        fill="#e3e2e3"
                    />
                    <path
                        id="Path_343"
                        d="M264.4,96.67c-.84-.21-1.6.15-1.66.79h0c-.06.64.6,1.06,1.43,1.23l3.77.84a.82.82,0,0,0,1-.62h0a.85.85,0,0,0-.63-1Z"
                        fill="#e3e2e3"
                    />
                    <path
                        id="Path_344"
                        d="M265.31,95c-.94-.22-1.75.13-1.76.79h0c0,.65.73,1,1.65,1.19l3.11.61a.75.75,0,0,0,.89-.58h0a.79.79,0,0,0-.61-.91Z"
                        fill="#e3e2e3"
                    />
                    <path
                        id="Path_345"
                        d="M268.35,100.38a.5.5,0,0,1,0,.32.61.61,0,0,1-.71.35l-1.1-.2c-.33-.07-.37-.19-.4-.29a.56.56,0,0,1,0-.42c.09-.26.22-.62.78-.43l1.09.35a.54.54,0,0,1,.34.32Z"
                        fill="#707171"
                    />
                    <path
                        id="Path_346"
                        d="M269.27,98.68a.44.44,0,0,1,0,.32.61.61,0,0,1-.7.37l-1.1-.17c-.33-.07-.38-.19-.41-.28a.56.56,0,0,1,0-.42c.08-.26.21-.63.76-.46l1.11.33a.54.54,0,0,1,.34.31Z"
                        fill="#707171"
                    />
                    <path
                        id="Path_347"
                        d="M269.68,96.72a.44.44,0,0,1,0,.32.6.6,0,0,1-.69.37l-1.11-.16c-.33-.06-.37-.18-.41-.27a.58.58,0,0,1,0-.43c.08-.26.2-.62.76-.45l1.11.31A.54.54,0,0,1,269.68,96.72Z"
                        fill="#707171"
                    />
                    <path
                        id="Path_348"
                        d="M327.09,114.48c.85-.68,1.34-.84,1.73-.29h0c.27.4.41,1-.81,1.79l-1.8,1a.69.69,0,0,1-.88-.11h0a.61.61,0,0,1,.11-.85Z"
                        fill="#e3e2e3"
                    />
                    <path
                        id="Path_349"
                        d="M325,116.81a.44.44,0,0,0,.08.25.51.51,0,0,0,.64.07l.78-.47c.24-.15.23-.25.23-.33a.55.55,0,0,0-.11-.33c-.14-.17-.35-.41-.72-.11l-.73.58A.44.44,0,0,0,325,116.81Z"
                        fill="#f4f5f6"
                    />
                    <path
                        id="Path_350"
                        d="M326.34,112.55c.69-.54,1.48-.64,1.8-.09l.07.09c.36.69-.23,1.2-.93,1.7L325,115.86a.86.86,0,0,1-1.19-.14h0a.85.85,0,0,1,.17-1.19Z"
                        fill="#e3e2e3"
                    />
                    <path
                        id="Path_351"
                        d="M325.5,110.65c.68-.54,1.51-.53,1.83,0h0c.32.56-.1,1.21-.79,1.71l-3.08,2.33a.83.83,0,0,1-1.15-.17h0a.85.85,0,0,1,.15-1.17Z"
                        fill="#e3e2e3"
                    />
                    <path
                        id="Path_352"
                        d="M324,109.48c.77-.59,1.65-.6,1.93,0h0c.28.58-.24,1.23-1,1.76l-2.58,1.85a.75.75,0,0,1-1.05-.17h0a.81.81,0,0,1,.18-1.08Z"
                        fill="#e3e2e3"
                    />
                    <path
                        id="Path_353"
                        d="M323.43,115.66a.51.51,0,0,0,.13.29.6.6,0,0,0,.79,0l.92-.64c.27-.2.26-.33.25-.43a.6.6,0,0,0-.17-.38c-.19-.2-.46-.47-.89-.08l-.85.78A.56.56,0,0,0,323.43,115.66Z"
                        fill="#f4f5f6"
                    />
                    <path
                        id="Path_354"
                        d="M321.89,114.49a.48.48,0,0,0,.13.3.6.6,0,0,0,.78,0l.94-.62c.28-.19.26-.32.26-.42a.62.62,0,0,0-.16-.39c-.18-.21-.45-.48-.88-.1l-.88.76a.59.59,0,0,0-.19.47Z"
                        fill="#f4f5f6"
                    />
                    <path
                        id="Path_355"
                        d="M320.71,112.88a.49.49,0,0,0,.12.3.62.62,0,0,0,.79,0l.94-.61c.27-.19.26-.32.26-.42a.61.61,0,0,0-.15-.39c-.19-.21-.45-.49-.89-.1l-.88.75a.51.51,0,0,0-.19.47Z"
                        fill="#f4f5f6"
                    />
                    <g id="Group_1" data-name="Group 1">
                        <circle
                            id="Ellipse_8"
                            data-name="Ellipse 8"
                            cx="131.35"
                            cy="131.35"
                            r="131.35"
                            transform="translate(125.37 128.99)"
                            fill="#fff"
                        />
                        <circle
                            id="Ellipse_9"
                            data-name="Ellipse 9"
                            cx="131.23"
                            cy="131.23"
                            r="131.23"
                            transform="translate(125.51 129.05)"
                            fill="#fefefe"
                        />
                        <circle
                            id="Ellipse_10"
                            data-name="Ellipse 10"
                            cx="131.1"
                            cy="131.1"
                            r="131.1"
                            transform="translate(125.66 129.11)"
                            fill="#fdfdfd"
                        />
                        <circle
                            id="Ellipse_11"
                            data-name="Ellipse 11"
                            cx="130.98"
                            cy="130.98"
                            r="130.98"
                            transform="translate(125.81 129.17)"
                            fill="#fcfcfc"
                        />
                        <circle
                            id="Ellipse_12"
                            data-name="Ellipse 12"
                            cx="130.86"
                            cy="130.86"
                            r="130.86"
                            transform="translate(125.95 129.23)"
                            fill="#fbfbfb"
                        />
                        <circle
                            id="Ellipse_13"
                            data-name="Ellipse 13"
                            cx="130.74"
                            cy="130.74"
                            r="130.74"
                            transform="translate(126.09 129.28)"
                            fill="#fafafa"
                        />
                        <circle
                            id="Ellipse_14"
                            data-name="Ellipse 14"
                            cx="130.62"
                            cy="130.62"
                            r="130.62"
                            transform="translate(126.24 129.34)"
                            fill="#f9f9f9"
                        />
                        <circle
                            id="Ellipse_15"
                            data-name="Ellipse 15"
                            cx="130.49"
                            cy="130.49"
                            r="130.49"
                            transform="translate(126.39 129.41)"
                            fill="#f8f8f8"
                        />
                        <circle
                            id="Ellipse_16"
                            data-name="Ellipse 16"
                            cx="130.37"
                            cy="130.37"
                            r="130.37"
                            transform="translate(126.53 129.46)"
                            fill="#f7f7f7"
                        />
                        <circle
                            id="Ellipse_17"
                            data-name="Ellipse 17"
                            cx="130.25"
                            cy="130.25"
                            r="130.25"
                            transform="translate(126.68 129.52)"
                            fill="#f6f6f6"
                        />
                        <circle
                            id="Ellipse_18"
                            data-name="Ellipse 18"
                            cx="130.13"
                            cy="130.13"
                            r="130.13"
                            transform="translate(126.82 129.58)"
                            fill="#f5f5f5"
                        />
                        <circle
                            id="Ellipse_19"
                            data-name="Ellipse 19"
                            cx="130.01"
                            cy="130.01"
                            r="130.01"
                            transform="translate(126.96 129.64)"
                            fill="#f4f4f4"
                        />
                        <circle
                            id="Ellipse_20"
                            data-name="Ellipse 20"
                            cx="129.88"
                            cy="129.88"
                            r="129.88"
                            transform="translate(127.12 129.7)"
                            fill="#f3f3f3"
                        />
                        <circle
                            id="Ellipse_21"
                            data-name="Ellipse 21"
                            cx="129.76"
                            cy="129.76"
                            r="129.76"
                            transform="translate(127.26 129.76)"
                            fill="#f2f2f2"
                        />
                        <circle
                            id="Ellipse_22"
                            data-name="Ellipse 22"
                            cx="129.64"
                            cy="129.64"
                            r="129.64"
                            transform="translate(127.4 129.82)"
                            fill="#f1f1f1"
                        />
                        <circle
                            id="Ellipse_23"
                            data-name="Ellipse 23"
                            cx="129.52"
                            cy="129.52"
                            r="129.52"
                            transform="translate(127.55 129.87)"
                            fill="#f0f0f0"
                        />
                        <circle
                            id="Ellipse_24"
                            data-name="Ellipse 24"
                            cx="129.4"
                            cy="129.4"
                            r="129.4"
                            transform="translate(127.69 129.93)"
                            fill="#efefef"
                        />
                        <circle
                            id="Ellipse_25"
                            data-name="Ellipse 25"
                            cx="129.28"
                            cy="129.28"
                            r="129.28"
                            transform="translate(127.83 129.99)"
                            fill="#eee"
                        />
                        <circle
                            id="Ellipse_26"
                            data-name="Ellipse 26"
                            cx="129.15"
                            cy="129.15"
                            r="129.15"
                            transform="translate(127.98 130.05)"
                            fill="#ededed"
                        />
                        <circle
                            id="Ellipse_27"
                            data-name="Ellipse 27"
                            cx="129.03"
                            cy="129.03"
                            r="129.03"
                            transform="translate(128.13 130.11)"
                            fill="#ececec"
                        />
                        <circle
                            id="Ellipse_28"
                            data-name="Ellipse 28"
                            cx="128.91"
                            cy="128.91"
                            r="128.91"
                            transform="translate(128.27 130.17)"
                            fill="#ebebeb"
                        />
                        <circle
                            id="Ellipse_29"
                            data-name="Ellipse 29"
                            cx="128.79"
                            cy="128.79"
                            r="128.79"
                            transform="translate(128.41 130.22)"
                            fill="#eaeaea"
                        />
                        <circle
                            id="Ellipse_30"
                            data-name="Ellipse 30"
                            cx="128.67"
                            cy="128.67"
                            r="128.67"
                            transform="translate(128.56 130.28)"
                            fill="#e9e9e9"
                        />
                        <circle
                            id="Ellipse_31"
                            data-name="Ellipse 31"
                            cx="128.54"
                            cy="128.54"
                            r="128.54"
                            transform="translate(128.71 130.35)"
                            fill="#e8e8e8"
                        />
                        <circle
                            id="Ellipse_32"
                            data-name="Ellipse 32"
                            cx="128.42"
                            cy="128.42"
                            r="128.42"
                            transform="translate(128.85 130.4)"
                            fill="#e7e7e7"
                        />
                        <circle
                            id="Ellipse_33"
                            data-name="Ellipse 33"
                            cx="128.3"
                            cy="128.3"
                            r="128.3"
                            transform="translate(129 130.46)"
                            fill="#e6e6e6"
                        />
                        <circle
                            id="Ellipse_34"
                            data-name="Ellipse 34"
                            cx="128.18"
                            cy="128.18"
                            r="128.18"
                            transform="translate(129.14 130.52)"
                            fill="#e5e5e5"
                        />
                        <circle
                            id="Ellipse_35"
                            data-name="Ellipse 35"
                            cx="128.06"
                            cy="128.06"
                            r="128.06"
                            transform="translate(129.28 130.57)"
                            fill="#e4e4e4"
                        />
                        <circle
                            id="Ellipse_36"
                            data-name="Ellipse 36"
                            cx="127.93"
                            cy="127.93"
                            r="127.93"
                            transform="translate(129.44 130.64)"
                            fill="#e3e3e3"
                        />
                        <circle
                            id="Ellipse_37"
                            data-name="Ellipse 37"
                            cx="127.81"
                            cy="127.81"
                            r="127.81"
                            transform="translate(129.58 130.7)"
                            fill="#e2e2e2"
                        />
                        <circle
                            id="Ellipse_38"
                            data-name="Ellipse 38"
                            cx="127.69"
                            cy="127.69"
                            r="127.69"
                            transform="translate(129.72 130.75)"
                            fill="#e1e1e1"
                        />
                        <circle
                            id="Ellipse_39"
                            data-name="Ellipse 39"
                            cx="127.57"
                            cy="127.57"
                            r="127.57"
                            transform="translate(129.87 130.81)"
                            fill="#e0e0e0"
                        />
                        <circle
                            id="Ellipse_40"
                            data-name="Ellipse 40"
                            cx="127.45"
                            cy="127.45"
                            r="127.45"
                            transform="translate(130.01 130.87)"
                            fill="#dfdfdf"
                        />
                        <circle
                            id="Ellipse_41"
                            data-name="Ellipse 41"
                            cx="127.33"
                            cy="127.33"
                            r="127.33"
                            transform="translate(130.15 130.92)"
                            fill="#dedede"
                        />
                        <circle
                            id="Ellipse_42"
                            data-name="Ellipse 42"
                            cx="127.2"
                            cy="127.2"
                            r="127.2"
                            transform="translate(130.31 130.99)"
                            fill="#ddd"
                        />
                        <circle
                            id="Ellipse_43"
                            data-name="Ellipse 43"
                            cx="127.08"
                            cy="127.08"
                            r="127.08"
                            transform="translate(130.45 131.05)"
                            fill="#dcdcdc"
                        />
                        <circle
                            id="Ellipse_44"
                            data-name="Ellipse 44"
                            cx="126.96"
                            cy="126.96"
                            r="126.96"
                            transform="translate(130.59 131.1)"
                            fill="#dbdbdb"
                        />
                        <circle
                            id="Ellipse_45"
                            data-name="Ellipse 45"
                            cx="126.84"
                            cy="126.84"
                            r="126.84"
                            transform="translate(130.73 131.16)"
                            fill="#dadada"
                        />
                        <circle
                            id="Ellipse_46"
                            data-name="Ellipse 46"
                            cx="126.72"
                            cy="126.72"
                            r="126.72"
                            transform="translate(130.88 131.22)"
                            fill="#d9d9d9"
                        />
                        <circle
                            id="Ellipse_47"
                            data-name="Ellipse 47"
                            cx="126.59"
                            cy="126.59"
                            r="126.59"
                            transform="translate(131.03 131.28)"
                            fill="#d8d8d8"
                        />
                        <circle
                            id="Ellipse_48"
                            data-name="Ellipse 48"
                            cx="126.47"
                            cy="126.47"
                            r="126.47"
                            transform="translate(131.17 131.34)"
                            fill="#d7d7d7"
                        />
                        <circle
                            id="Ellipse_49"
                            data-name="Ellipse 49"
                            cx="126.35"
                            cy="126.35"
                            r="126.35"
                            transform="translate(131.32 131.4)"
                            fill="#d6d6d6"
                        />
                        <circle
                            id="Ellipse_50"
                            data-name="Ellipse 50"
                            cx="126.23"
                            cy="126.23"
                            r="126.23"
                            transform="translate(131.46 131.45)"
                            fill="#d5d5d5"
                        />
                        <circle
                            id="Ellipse_51"
                            data-name="Ellipse 51"
                            cx="126.11"
                            cy="126.11"
                            r="126.11"
                            transform="translate(131.6 131.51)"
                            fill="#d4d4d4"
                        />
                        <circle
                            id="Ellipse_52"
                            data-name="Ellipse 52"
                            cx="125.98"
                            cy="125.98"
                            r="125.98"
                            transform="translate(131.76 131.58)"
                            fill="#d3d3d3"
                        />
                        <circle
                            id="Ellipse_53"
                            data-name="Ellipse 53"
                            cx="125.86"
                            cy="125.86"
                            r="125.86"
                            transform="translate(131.9 131.63)"
                            fill="#d2d2d2"
                        />
                        <circle
                            id="Ellipse_54"
                            data-name="Ellipse 54"
                            cx="125.74"
                            cy="125.74"
                            r="125.74"
                            transform="translate(132.04 131.69)"
                            fill="#d1d1d1"
                        />
                        <circle
                            id="Ellipse_55"
                            data-name="Ellipse 55"
                            cx="125.62"
                            cy="125.62"
                            r="125.62"
                            transform="translate(132.19 131.75)"
                            fill="#d0d0d0"
                        />
                        <circle
                            id="Ellipse_56"
                            data-name="Ellipse 56"
                            cx="125.5"
                            cy="125.5"
                            r="125.5"
                            transform="translate(132.33 131.8)"
                            fill="#cfcfcf"
                        />
                        <circle
                            id="Ellipse_57"
                            data-name="Ellipse 57"
                            cx="125.38"
                            cy="125.38"
                            r="125.38"
                            transform="translate(132.47 131.86)"
                            fill="#cecece"
                        />
                        <circle
                            id="Ellipse_58"
                            data-name="Ellipse 58"
                            cx="125.25"
                            cy="125.25"
                            r="125.25"
                            transform="translate(132.63 131.93)"
                            fill="#cdcdcd"
                        />
                        <circle
                            id="Ellipse_59"
                            data-name="Ellipse 59"
                            cx="125.13"
                            cy="125.13"
                            r="125.13"
                            transform="translate(132.77 131.99)"
                            fill="#cdcdcd"
                        />
                        <circle
                            id="Ellipse_60"
                            data-name="Ellipse 60"
                            cx="125.01"
                            cy="125.01"
                            r="125.01"
                            transform="translate(132.91 132.04)"
                            fill="#ccc"
                        />
                        <circle
                            id="Ellipse_61"
                            data-name="Ellipse 61"
                            cx="124.89"
                            cy="124.89"
                            r="124.89"
                            transform="translate(133.05 132.1)"
                            fill="#cbcbcb"
                        />
                        <circle
                            id="Ellipse_62"
                            data-name="Ellipse 62"
                            cx="124.77"
                            cy="124.77"
                            r="124.77"
                            transform="translate(133.2 132.16)"
                            fill="#cacaca"
                        />
                        <circle
                            id="Ellipse_63"
                            data-name="Ellipse 63"
                            cx="124.64"
                            cy="124.64"
                            r="124.64"
                            transform="translate(133.35 132.22)"
                            fill="#c9c9c9"
                        />
                        <circle
                            id="Ellipse_64"
                            data-name="Ellipse 64"
                            cx="124.52"
                            cy="124.52"
                            r="124.52"
                            transform="translate(133.49 132.28)"
                            fill="#c8c8c8"
                        />
                        <circle
                            id="Ellipse_65"
                            data-name="Ellipse 65"
                            cx="124.4"
                            cy="124.4"
                            r="124.4"
                            transform="translate(133.64 132.34)"
                            fill="#c7c7c7"
                        />
                        <circle
                            id="Ellipse_66"
                            data-name="Ellipse 66"
                            cx="124.28"
                            cy="124.28"
                            r="124.28"
                            transform="translate(133.78 132.39)"
                            fill="#c6c6c6"
                        />
                        <circle
                            id="Ellipse_67"
                            data-name="Ellipse 67"
                            cx="124.16"
                            cy="124.16"
                            r="124.16"
                            transform="translate(133.92 132.45)"
                            fill="#c5c5c5"
                        />
                        <circle
                            id="Ellipse_68"
                            data-name="Ellipse 68"
                            cx="124.04"
                            cy="124.04"
                            r="124.04"
                            transform="translate(134.07 132.51)"
                            fill="#c4c4c4"
                        />
                        <circle
                            id="Ellipse_69"
                            data-name="Ellipse 69"
                            cx="123.91"
                            cy="123.91"
                            r="123.91"
                            transform="translate(134.22 132.57)"
                            fill="#c3c3c3"
                        />
                        <circle
                            id="Ellipse_70"
                            data-name="Ellipse 70"
                            cx="123.79"
                            cy="123.79"
                            r="123.79"
                            transform="translate(134.36 132.63)"
                            fill="#c2c2c2"
                        />
                        <circle
                            id="Ellipse_71"
                            data-name="Ellipse 71"
                            cx="123.67"
                            cy="123.67"
                            r="123.67"
                            transform="translate(134.51 132.69)"
                            fill="#c1c1c1"
                        />
                        <circle
                            id="Ellipse_72"
                            data-name="Ellipse 72"
                            cx="123.55"
                            cy="123.55"
                            r="123.55"
                            transform="translate(134.65 132.74)"
                            fill="silver"
                        />
                        <circle
                            id="Ellipse_73"
                            data-name="Ellipse 73"
                            cx="123.43"
                            cy="123.43"
                            r="123.43"
                            transform="translate(134.79 132.8)"
                            fill="#bfbfbf"
                        />
                        <circle
                            id="Ellipse_74"
                            data-name="Ellipse 74"
                            cx="123.3"
                            cy="123.3"
                            r="123.3"
                            transform="translate(134.95 132.87)"
                            fill="#bebebe"
                        />
                        <circle
                            id="Ellipse_75"
                            data-name="Ellipse 75"
                            cx="123.18"
                            cy="123.18"
                            r="123.18"
                            transform="translate(135.09 132.92)"
                            fill="#bdbdbd"
                        />
                        <circle
                            id="Ellipse_76"
                            data-name="Ellipse 76"
                            cx="123.06"
                            cy="123.06"
                            r="123.06"
                            transform="translate(135.23 132.98)"
                            fill="#bcbcbc"
                        />
                        <circle
                            id="Ellipse_77"
                            data-name="Ellipse 77"
                            cx="122.94"
                            cy="122.94"
                            r="122.94"
                            transform="translate(135.38 133.04)"
                            fill="#bbb"
                        />
                        <circle
                            id="Ellipse_78"
                            data-name="Ellipse 78"
                            cx="122.82"
                            cy="122.82"
                            r="122.82"
                            transform="translate(135.52 133.09)"
                            fill="#bababa"
                        />
                        <circle
                            id="Ellipse_79"
                            data-name="Ellipse 79"
                            cx="122.69"
                            cy="122.69"
                            r="122.69"
                            transform="translate(135.67 133.16)"
                            fill="#b9b9b9"
                        />
                        <circle
                            id="Ellipse_80"
                            data-name="Ellipse 80"
                            cx="122.57"
                            cy="122.57"
                            r="122.57"
                            transform="translate(135.81 133.22)"
                            fill="#b8b8b8"
                        />
                        <circle
                            id="Ellipse_81"
                            data-name="Ellipse 81"
                            cx="122.45"
                            cy="122.45"
                            r="122.45"
                            transform="translate(135.96 133.27)"
                            fill="#b7b7b7"
                        />
                        <circle
                            id="Ellipse_82"
                            data-name="Ellipse 82"
                            cx="122.33"
                            cy="122.33"
                            r="122.33"
                            transform="translate(136.1 133.33)"
                            fill="#b6b6b6"
                        />
                        <circle
                            id="Ellipse_83"
                            data-name="Ellipse 83"
                            cx="122.21"
                            cy="122.21"
                            r="122.21"
                            transform="translate(136.24 133.39)"
                            fill="#b5b5b5"
                        />
                        <circle
                            id="Ellipse_84"
                            data-name="Ellipse 84"
                            cx="122.09"
                            cy="122.09"
                            r="122.09"
                            transform="translate(136.39 133.44)"
                            fill="#b4b4b4"
                        />
                        <circle
                            id="Ellipse_85"
                            data-name="Ellipse 85"
                            cx="121.96"
                            cy="121.96"
                            r="121.96"
                            transform="translate(136.54 133.51)"
                            fill="#b3b3b3"
                        />
                        <circle
                            id="Ellipse_86"
                            data-name="Ellipse 86"
                            cx="121.84"
                            cy="121.84"
                            r="121.84"
                            transform="translate(136.68 133.57)"
                            fill="#b2b2b2"
                        />
                        <circle
                            id="Ellipse_87"
                            data-name="Ellipse 87"
                            cx="121.72"
                            cy="121.72"
                            r="121.72"
                            transform="translate(136.83 133.62)"
                            fill="#b1b1b1"
                        />
                        <circle
                            id="Ellipse_88"
                            data-name="Ellipse 88"
                            cx="121.6"
                            cy="121.6"
                            r="121.6"
                            transform="translate(136.97 133.68)"
                            fill="#b0b0b0"
                        />
                        <circle
                            id="Ellipse_89"
                            data-name="Ellipse 89"
                            cx="121.48"
                            cy="121.48"
                            r="121.48"
                            transform="translate(137.11 133.74)"
                            fill="#afafaf"
                        />
                        <circle
                            id="Ellipse_90"
                            data-name="Ellipse 90"
                            cx="121.35"
                            cy="121.35"
                            r="121.35"
                            transform="translate(137.27 133.8)"
                            fill="#aeaeae"
                        />
                        <circle
                            id="Ellipse_91"
                            data-name="Ellipse 91"
                            cx="121.23"
                            cy="121.23"
                            r="121.23"
                            transform="translate(137.41 133.86)"
                            fill="#adadad"
                        />
                        <circle
                            id="Ellipse_92"
                            data-name="Ellipse 92"
                            cx="121.11"
                            cy="121.11"
                            r="121.11"
                            transform="translate(137.55 133.92)"
                            fill="#acacac"
                        />
                        <circle
                            id="Ellipse_93"
                            data-name="Ellipse 93"
                            cx="120.99"
                            cy="120.99"
                            r="120.99"
                            transform="translate(137.7 133.97)"
                            fill="#ababab"
                        />
                        <circle
                            id="Ellipse_94"
                            data-name="Ellipse 94"
                            cx="120.87"
                            cy="120.87"
                            r="120.87"
                            transform="translate(137.84 134.03)"
                            fill="#aaa"
                        />
                        <circle
                            id="Ellipse_95"
                            data-name="Ellipse 95"
                            cx="120.74"
                            cy="120.74"
                            r="120.74"
                            transform="translate(137.99 134.1)"
                            fill="#a9a9a9"
                        />
                        <circle
                            id="Ellipse_96"
                            data-name="Ellipse 96"
                            cx="120.62"
                            cy="120.62"
                            r="120.62"
                            transform="translate(138.13 134.15)"
                            fill="#a8a8a8"
                        />
                        <circle
                            id="Ellipse_97"
                            data-name="Ellipse 97"
                            cx="120.5"
                            cy="120.5"
                            r="120.5"
                            transform="translate(138.28 134.21)"
                            fill="#a7a7a7"
                        />
                        <circle
                            id="Ellipse_98"
                            data-name="Ellipse 98"
                            cx="120.38"
                            cy="120.38"
                            r="120.38"
                            transform="translate(138.42 134.27)"
                            fill="#a6a6a6"
                        />
                        <circle
                            id="Ellipse_99"
                            data-name="Ellipse 99"
                            cx="120.26"
                            cy="120.26"
                            r="120.26"
                            transform="translate(138.56 134.33)"
                            fill="#a5a5a5"
                        />
                        <circle
                            id="Ellipse_100"
                            data-name="Ellipse 100"
                            cx="120.14"
                            cy="120.14"
                            r="120.14"
                            transform="translate(138.71 134.38)"
                            fill="#a4a4a4"
                        />
                        <circle
                            id="Ellipse_101"
                            data-name="Ellipse 101"
                            cx="120.01"
                            cy="120.01"
                            r="120.01"
                            transform="translate(138.86 134.45)"
                            fill="#a3a3a3"
                        />
                        <circle
                            id="Ellipse_102"
                            data-name="Ellipse 102"
                            cx="119.89"
                            cy="119.89"
                            r="119.89"
                            transform="translate(139 134.51)"
                            fill="#a2a2a2"
                        />
                        <circle
                            id="Ellipse_103"
                            data-name="Ellipse 103"
                            cx="119.77"
                            cy="119.77"
                            r="119.77"
                            transform="translate(139.15 134.56)"
                            fill="#a1a1a1"
                        />
                        <circle
                            id="Ellipse_104"
                            data-name="Ellipse 104"
                            cx="119.65"
                            cy="119.65"
                            r="119.65"
                            transform="translate(139.29 134.62)"
                            fill="#a0a0a0"
                        />
                        <circle
                            id="Ellipse_105"
                            data-name="Ellipse 105"
                            cx="119.53"
                            cy="119.53"
                            r="119.53"
                            transform="translate(139.43 134.68)"
                            fill="#9f9f9f"
                        />
                        <circle
                            id="Ellipse_106"
                            data-name="Ellipse 106"
                            cx="119.4"
                            cy="119.4"
                            r="119.4"
                            transform="translate(139.59 134.74)"
                            fill="#9e9e9e"
                        />
                        <circle
                            id="Ellipse_107"
                            data-name="Ellipse 107"
                            cx="119.28"
                            cy="119.28"
                            r="119.28"
                            transform="translate(139.73 134.8)"
                            fill="#9d9d9d"
                        />
                        <circle
                            id="Ellipse_108"
                            data-name="Ellipse 108"
                            cx="119.16"
                            cy="119.16"
                            r="119.16"
                            transform="translate(139.87 134.86)"
                            fill="#9c9c9c"
                        />
                        <circle
                            id="Ellipse_109"
                            data-name="Ellipse 109"
                            cx="119.04"
                            cy="119.04"
                            r="119.04"
                            transform="translate(140.02 134.91)"
                            fill="#9b9b9b"
                        />
                    </g>
                    <circle
                        id="Ellipse_110"
                        data-name="Ellipse 110"
                        cx="119.04"
                        cy="119.04"
                        r="119.04"
                        transform="translate(140.02 134.91)"
                        fill="#e3e2e3"
                    />
                    <circle
                        id="Ellipse_111"
                        data-name="Ellipse 111"
                        cx="100.34"
                        cy="100.34"
                        r="100.34"
                        transform="translate(158.72 154.86)"
                        fill="#707171"
                    />
                    <path
                        id="Path_356"
                        d="M202.31,187.59l1.77,2.29h81.15l1.78-2.29Z"
                        fill="#707171"
                    />
                    <g id="Group_2" data-name="Group 2" opacity="0.4">
                        <path
                            id="Path_357"
                            d="M202.31,187.59l1.77,2.29h81.15l1.78-2.29Z"
                            fill="#707171"
                        />
                    </g>
                    <rect
                        id="Rectangle_16"
                        width="81.34"
                        height="144.19"
                        transform="translate(203.91 184.64)"
                        fill="#f4f5f6"
                    />
                    <g id="Group_3" data-name="Group 3" opacity="0.4">
                        <rect
                            id="Rectangle_17"
                            width="81.34"
                            height="144.19"
                            transform="translate(203.91 184.64)"
                            fill="#f4f5f6"
                        />
                    </g>
                    <path
                        id="Path_358"
                        d="M207.21,188.19a1.48,1.48,0,0,1-1.46,1.69,1.9,1.9,0,0,1-1.81-1.69,1.48,1.48,0,0,1,1.46-1.71A1.89,1.89,0,0,1,207.21,188.19Z"
                        fill="#e3e2e3"
                    />
                    <path
                        id="Path_359"
                        d="M210.2,188.19a1.49,1.49,0,0,1-1.47,1.69,1.87,1.87,0,0,1-1.79-1.69,1.48,1.48,0,0,1,1.47-1.71,1.86,1.86,0,0,1,1.79,1.71Z"
                        fill="#e3e2e3"
                    />
                    <path
                        id="Path_360"
                        d="M213.2,188.19a1.51,1.51,0,0,1-1.49,1.69,1.87,1.87,0,0,1-1.78-1.69,1.5,1.5,0,0,1,1.48-1.71,1.86,1.86,0,0,1,1.79,1.71Z"
                        fill="#e3e2e3"
                    />
                    <path
                        id="Path_361"
                        d="M216.19,188.19a1.52,1.52,0,0,1-1.5,1.69,1.85,1.85,0,0,1-1.77-1.69,1.51,1.51,0,0,1,1.5-1.71,1.83,1.83,0,0,1,1.77,1.71Z"
                        fill="#e3e2e3"
                    />
                    <path
                        id="Path_362"
                        d="M219.19,188.19a1.54,1.54,0,0,1-1.52,1.69,1.82,1.82,0,0,1-1.75-1.69,1.53,1.53,0,0,1,1.51-1.71,1.83,1.83,0,0,1,1.76,1.71Z"
                        fill="#e3e2e3"
                    />
                    <path
                        id="Path_363"
                        d="M222.18,188.19a1.55,1.55,0,0,1-1.53,1.69,1.82,1.82,0,0,1-1.74-1.69,1.55,1.55,0,0,1,1.53-1.71A1.8,1.8,0,0,1,222.18,188.19Z"
                        fill="#e3e2e3"
                    />
                    <path
                        id="Path_364"
                        d="M225.17,188.19a1.56,1.56,0,0,1-1.54,1.69,1.78,1.78,0,0,1-1.72-1.69,1.55,1.55,0,0,1,1.53-1.71,1.78,1.78,0,0,1,1.73,1.71Z"
                        fill="#e3e2e3"
                    />
                    <path
                        id="Path_365"
                        d="M228.17,188.19a1.58,1.58,0,0,1-1.55,1.69,1.78,1.78,0,0,1-1.72-1.69,1.57,1.57,0,0,1,1.55-1.71A1.76,1.76,0,0,1,228.17,188.19Z"
                        fill="#e3e2e3"
                    />
                    <path
                        id="Path_366"
                        d="M231.16,188.19a1.59,1.59,0,0,1-1.56,1.69,1.77,1.77,0,0,1-1.71-1.69,1.59,1.59,0,0,1,1.57-1.71,1.75,1.75,0,0,1,1.7,1.71Z"
                        fill="#e3e2e3"
                    />
                    <path
                        id="Path_367"
                        d="M234.15,188.19a1.6,1.6,0,0,1-1.57,1.69,1.73,1.73,0,0,1-1.69-1.69,1.6,1.6,0,0,1,1.58-1.71A1.72,1.72,0,0,1,234.15,188.19Z"
                        fill="#e3e2e3"
                    />
                    <path
                        id="Path_368"
                        d="M237.15,188.19a1.62,1.62,0,0,1-1.59,1.69,1.73,1.73,0,0,1-1.68-1.69,1.62,1.62,0,0,1,1.59-1.71A1.72,1.72,0,0,1,237.15,188.19Z"
                        fill="#e3e2e3"
                    />
                    <path
                        id="Path_369"
                        d="M240.14,188.19a1.63,1.63,0,0,1-1.6,1.69,1.69,1.69,0,0,1-1.66-1.69,1.63,1.63,0,0,1,1.6-1.71A1.69,1.69,0,0,1,240.14,188.19Z"
                        fill="#e3e2e3"
                    />
                    <path
                        id="Path_370"
                        d="M243.14,188.19a1.65,1.65,0,0,1-1.62,1.69,1.69,1.69,0,0,1-1.65-1.69,1.65,1.65,0,0,1,1.62-1.71A1.69,1.69,0,0,1,243.14,188.19Z"
                        fill="#e3e2e3"
                    />
                    <path
                        id="Path_371"
                        d="M246.13,188.19a1.64,1.64,0,1,1-1.63-1.71,1.66,1.66,0,0,1,1.63,1.71Z"
                        fill="#e3e2e3"
                    />
                    <path
                        id="Path_372"
                        d="M249.12,188.19a1.68,1.68,0,0,1-1.64,1.69,1.65,1.65,0,0,1-1.62-1.69,1.68,1.68,0,0,1,1.64-1.71,1.64,1.64,0,0,1,1.62,1.71Z"
                        fill="#e3e2e3"
                    />
                    <path
                        id="Path_373"
                        d="M252.12,188.19a1.7,1.7,0,0,1-1.66,1.69,1.64,1.64,0,0,1-1.61-1.69,1.69,1.69,0,0,1,1.66-1.71,1.63,1.63,0,0,1,1.61,1.71Z"
                        fill="#e3e2e3"
                    />
                    <path
                        id="Path_374"
                        d="M255.11,188.19a1.71,1.71,0,0,1-1.67,1.69,1.63,1.63,0,0,1-1.6-1.69,1.72,1.72,0,0,1,1.68-1.71,1.62,1.62,0,0,1,1.59,1.71Z"
                        fill="#e3e2e3"
                    />
                    <path
                        id="Path_375"
                        d="M258.11,188.19a1.74,1.74,0,0,1-1.69,1.69,1.6,1.6,0,0,1-1.58-1.69,1.72,1.72,0,0,1,1.69-1.71,1.61,1.61,0,0,1,1.58,1.71Z"
                        fill="#e3e2e3"
                    />
                    <path
                        id="Path_376"
                        d="M261.1,188.19a1.75,1.75,0,0,1-1.7,1.69,1.6,1.6,0,0,1-1.57-1.69,1.75,1.75,0,0,1,1.7-1.71,1.59,1.59,0,0,1,1.57,1.71Z"
                        fill="#e3e2e3"
                    />
                    <path
                        id="Path_377"
                        d="M264.09,188.19a1.76,1.76,0,0,1-1.71,1.69,1.57,1.57,0,0,1-1.55-1.69,1.76,1.76,0,0,1,1.71-1.71,1.57,1.57,0,0,1,1.55,1.71Z"
                        fill="#e3e2e3"
                    />
                    <path
                        id="Path_378"
                        d="M267.09,188.19a1.79,1.79,0,0,1-1.73,1.69,1.56,1.56,0,0,1-1.54-1.69,1.78,1.78,0,0,1,1.73-1.71,1.57,1.57,0,0,1,1.54,1.71Z"
                        fill="#e3e2e3"
                    />
                    <path
                        id="Path_379"
                        d="M270.08,188.19a1.8,1.8,0,0,1-1.74,1.69,1.55,1.55,0,0,1-1.53-1.69,1.81,1.81,0,0,1,1.75-1.71,1.54,1.54,0,0,1,1.52,1.71Z"
                        fill="#e3e2e3"
                    />
                    <path
                        id="Path_380"
                        d="M273.08,188.19a1.83,1.83,0,0,1-1.76,1.69,1.53,1.53,0,0,1-1.51-1.69,1.81,1.81,0,0,1,1.75-1.71,1.53,1.53,0,0,1,1.52,1.71Z"
                        fill="#e3e2e3"
                    />
                    <path
                        id="Path_381"
                        d="M276.07,188.19a1.84,1.84,0,0,1-1.77,1.69,1.52,1.52,0,0,1-1.5-1.69,1.83,1.83,0,0,1,1.77-1.71,1.51,1.51,0,0,1,1.5,1.71Z"
                        fill="#e3e2e3"
                    />
                    <path
                        id="Path_382"
                        d="M279.06,188.19a1.85,1.85,0,0,1-1.78,1.69,1.5,1.5,0,0,1-1.48-1.69,1.84,1.84,0,0,1,1.78-1.71,1.5,1.5,0,0,1,1.48,1.71Z"
                        fill="#e3e2e3"
                    />
                    <path
                        id="Path_383"
                        d="M282.06,188.19a1.89,1.89,0,0,1-1.8,1.69,1.49,1.49,0,0,1-1.47-1.69,1.87,1.87,0,0,1,1.8-1.71,1.49,1.49,0,0,1,1.47,1.71Z"
                        fill="#e3e2e3"
                    />
                    <path
                        id="Path_384"
                        d="M285.05,188.19a1.9,1.9,0,0,1-1.81,1.69,1.48,1.48,0,0,1-1.46-1.69,1.9,1.9,0,0,1,1.81-1.71,1.48,1.48,0,0,1,1.46,1.71Z"
                        fill="#e3e2e3"
                    />
                    <path id="Path_385" d="M202.3,185.27h84.72l-.01,2.32h-84.7Z" fill="#707171" />
                    <path
                        id="Path_386"
                        d="M202.29,182.95l1.77,2.32h81.18l1.78-2.32Z"
                        fill="#707171"
                    />
                    <g id="Group_4" data-name="Group 4" opacity="0.4">
                        <path
                            id="Path_387"
                            d="M202.29,182.95l1.77,2.32h81.18l1.78-2.32Z"
                            fill="#707171"
                        />
                    </g>
                    <path id="Path_388" d="M202.29,180.63h84.74l-.01,2.32H202.29Z" fill="#707171" />
                    <g id="Group_5" data-name="Group 5">
                        <path id="Path_389" d="M204.06,182.95h81.18v2.32H204.06Z" fill="#007cff" />
                    </g>
                    <path id="Path_390" d="M204.06,182.95h81.18v2.32H204.06Z" fill="#f4f5f6" />
                    <g id="Group_6" data-name="Group 6" opacity="0.7">
                        <rect
                            id="Rectangle_18"
                            width="81.33"
                            height="1.02"
                            transform="translate(203.93 230.9)"
                            fill="#fff"
                        />
                    </g>
                    <g id="Group_7" data-name="Group 7" opacity="0.8">
                        <rect
                            id="Rectangle_19"
                            width="81.16"
                            height="1.02"
                            transform="translate(204.1 281.58)"
                            fill="#fff"
                        />
                    </g>
                    <rect
                        id="Rectangle_20"
                        width="10.05"
                        height="10.86"
                        transform="translate(220.79 194.31)"
                        fill="#e3e2e3"
                    />
                    <rect
                        id="Rectangle_21"
                        width="10.05"
                        height="10.86"
                        transform="translate(240.1 194.31)"
                        fill="#e3e2e3"
                    />
                    <rect
                        id="Rectangle_22"
                        width="10.05"
                        height="10.86"
                        transform="translate(259.41 194.31)"
                        fill="#e3e2e3"
                    />
                    <rect
                        id="Rectangle_23"
                        width="10.05"
                        height="10.86"
                        transform="translate(220.79 210.58)"
                        fill="#e3e2e3"
                    />
                    <rect
                        id="Rectangle_24"
                        width="10.05"
                        height="10.86"
                        transform="translate(240.1 210.58)"
                        fill="#e3e2e3"
                    />
                    <rect
                        id="Rectangle_25"
                        width="10.05"
                        height="10.86"
                        transform="translate(259.41 210.58)"
                        fill="#e3e2e3"
                    />
                    <rect
                        id="Rectangle_26"
                        width="10.05"
                        height="10.86"
                        transform="translate(220.79 289.4)"
                        fill="#e3e2e3"
                    />
                    <rect
                        id="Rectangle_27"
                        width="10.05"
                        height="10.86"
                        transform="translate(240.1 289.4)"
                        fill="#e3e2e3"
                    />
                    <rect
                        id="Rectangle_28"
                        width="10.05"
                        height="10.86"
                        transform="translate(259.41 289.4)"
                        fill="#e3e2e3"
                    />
                    <rect
                        id="Rectangle_29"
                        width="10.05"
                        height="10.86"
                        transform="translate(210.02 312.27)"
                        fill="#e3e2e3"
                    />
                    <rect
                        id="Rectangle_30"
                        width="10.05"
                        height="10.86"
                        transform="translate(269.1 312.27)"
                        fill="#e3e2e3"
                    />
                    <rect
                        id="Rectangle_31"
                        width="10.05"
                        height="10.86"
                        transform="translate(220.79 240.48)"
                        fill="#e3e2e3"
                    />
                    <rect
                        id="Rectangle_32"
                        width="10.05"
                        height="10.86"
                        transform="translate(240.1 240.48)"
                        fill="#e3e2e3"
                    />
                    <rect
                        id="Rectangle_33"
                        width="10.05"
                        height="10.86"
                        transform="translate(259.41 240.48)"
                        fill="#e3e2e3"
                    />
                    <rect
                        id="Rectangle_34"
                        width="10.05"
                        height="10.86"
                        transform="translate(220.79 256.74)"
                        fill="#e3e2e3"
                    />
                    <rect
                        id="Rectangle_35"
                        width="10.05"
                        height="10.86"
                        transform="translate(240.1 256.74)"
                        fill="#e3e2e3"
                    />
                    <rect
                        id="Rectangle_36"
                        width="10.05"
                        height="10.86"
                        transform="translate(259.41 256.74)"
                        fill="#e3e2e3"
                    />
                    <path id="Path_391" d="M232.95,313.54h10.4l-.04,10.81H232.88Z" fill="#e3e2e3" />
                    <path
                        id="Path_392"
                        d="M245.36,313.54H256.2l-.51,10.81H245.25Z"
                        fill="#e3e2e3"
                    />
                    <path id="Path_393" d="M257.86,326.52H230.4l-.11-2.17h27.67Z" fill="#707171" />
                    <g id="Group_8" data-name="Group 8" opacity="0.1">
                        <path
                            id="Path_394"
                            d="M257.86,326.52H230.4l-.11-2.17h27.67Z"
                            fill="#707171"
                        />
                    </g>
                    <path id="Path_395" d="M258.96,328.82H229.43l-.12-2.14h29.76Z" fill="#707171" />
                    <g id="Group_9" data-name="Group 9" opacity="0.4">
                        <path
                            id="Path_396"
                            d="M259.94,309.71H228.37l-.13-2.44h31.83Z"
                            fill="#fff"
                        />
                    </g>
                    <path
                        id="Path_397"
                        d="M228.37,309.71l4.58,3.83H256.2l3.74-3.83Z"
                        fill="#707171"
                    />
                    <path
                        id="Path_398"
                        d="M230.52,311.51l1.61,12.84h1.28l-.52-12.84h0A2.55,2.55,0,0,0,230.52,311.51Z"
                        fill="#707171"
                    />
                    <path
                        id="Path_399"
                        d="M258.25,311.51l-1.58,12.84h-1.28l.48-12.84h0a2.15,2.15,0,0,1,2.38,0Z"
                        fill="#707171"
                    />
                    <path
                        id="Path_400"
                        d="M320.05,278.3a19.82,19.82,0,1,1-19.813-19.81A19.82,19.82,0,0,1,320.05,278.3ZM313,274.06h-9.56V264.5h-7.07v9.56h-9.56v7.07h9.56v9.56h7.07v-9.56H313Z"
                        fill="#e3e2e3"
                    />
                </g>
            </g>
        </SVGIcon>
    );
}
