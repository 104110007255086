import React, { Fragment, useState, useCallback, useEffect } from 'react';

import DCSqHalfCheck from 'Assets/icons/DCSqHalfCheck';
import DCWarning from 'Assets/icons/DCWarning';
import DCGrayNoFound from 'Assets/icons/DCGrayNoFound';
import DCExternalWindow from 'Assets/icons/DCExternalWindow';
import HowToDialog from './howToDialog';
import { Button, Div, Image, Link, Strong } from 'UIKit/index';
import NoFound from './noFound';
import generateImage from 'Utils/generateImage';

const Listings = ({ data, details, user, handleGetInTouchOpen, webDe }) => {
    const [listingViewState, setListingViewState] = useState(true);
    const [listings, setListings] = useState(data?.data?.slice(0, 6));
    const handleMoreListings = useCallback(() => {
        setListings(data?.data);
        setListingViewState(false);
    }, [data]);
    const handleLessListings = useCallback(() => {
        setListings(data?.data.slice(0, 6));
        setListingViewState(true);
    }, [data]);

    const [howToDialog, setHowToDialog] = useState(false);
    const handleHowToDialogOpen = useCallback(() => {
        setHowToDialog(true);
    }, []);

    const handleHowToDialogClose = useCallback(() => {
        setHowToDialog(false);
    }, []);

    useEffect(() => {
        if (webDe) {
            handleMoreListings();
        } else {
            handleLessListings();
        }
    }, [webDe, handleMoreListings, handleLessListings]);

    return (
        <Fragment>
            <HowToDialog
                open={howToDialog}
                onClose={handleHowToDialogClose}
                title={'How we calculate the Listings grade'}
                description={
                    <Fragment>
                        Your <Strong>Listings score</Strong> is calculated based on your business
                        name, address and phone number’s total accuracy percentile, then divided by
                        3. This gives an average listing accuracy score.
                    </Fragment>
                }
            />
            <Div className={'EPComponent'}>
                <Div className={'EPCHead'}>
                    <Div className={'EPCHTBox'}>
                        <Div className={'EPCHTBIcon Grade' + (data?.grade || 'F')}>
                            {data?.grade || 'F'}
                        </Div>
                        <Div className={'EPCHTBTitle'}>Listings</Div>
                        <Div className={'EPCHTBSTitle'}>
                            Can potential customers find your business?
                        </Div>
                    </Div>
                    <Div className={'EPCHSTitle'} onClick={handleHowToDialogOpen}>
                        How we calculated this grade?
                    </Div>
                </Div>
                <Div className={'EPCBody'}>
                    <Div className={'EPCVideoContent'}>
                        <Div className={'EPCVCVideo'}>
                            <iframe
                                src="https://player.vimeo.com/video/356237760"
                                title="video"
                                frameBorder="0"
                                webkitallowfullscreen=""
                                mozallowfullscreen=""
                            />
                        </Div>
                        <Div className={'EPCVCContent'}>
                            <Div className={'EPCVCCDes'}>
                                83% of searches for local businesses on a mobile device result in a
                                call or visit to the business within 24 hours. For the best
                                visibility, it’s important to make sure your listings are accurate
                                across the web with the correct business name, address and phone
                                number.
                            </Div>
                            <br />
                            <Div className={'EPCVCCDes'}>
                                We've scanned {details?.name} to show you exactly how you’re listed
                                in these directories.
                            </Div>
                        </Div>
                    </Div>
                    {data?.data ? (
                        <Fragment>
                            <Div className={'EPCInnerHeading'}>
                                <Div className={'EPCIHLTitle'}>Listings analyzed</Div>
                                <Div className={'EPCIHRTitle'}>
                                    Your Business’s Directory Listings Overview
                                </Div>
                            </Div>
                            <Div className={'EPCRow'}>
                                <Div className={'EPCRCol2'}>
                                    <Div className={'EPCASLA'}>
                                        <Div className={'EPCASCHead'}>
                                            <Div className={'EPCASCHTitle'}>Listings Accuracy</Div>
                                            <Div className={'EPCASCHNum'}>
                                                {data.listingsAccuracyTotalPercentage
                                                    ? Math.round(
                                                          data.listingsAccuracyTotalPercentage,
                                                      )
                                                    : '-'}
                                                %
                                            </Div>
                                        </Div>
                                    </Div>
                                </Div>
                                <Div className={'EPCRCol4'}>
                                    <Div className={'EPCProgressMain'}>
                                        <Div className={'EPCPMTitle'}>Listings Found Overview</Div>
                                        <Div className={'EPCPMProgress'}>
                                            <Div className={'EPCPMPTitle'}>
                                                <Div className={'EPCPMPText'}>
                                                    Available listings
                                                </Div>
                                                <Div className={'EPCPMPText'}>
                                                    {data?.listingsFoundOverview?.availableListings}
                                                </Div>
                                            </Div>
                                            <Div className={'EPCPMPBar'}>
                                                <Div
                                                    className={'EPCPMPBInner'}
                                                    style={{
                                                        backgroundColor: '#d961ff',
                                                        width: `${parseInt(
                                                            100,
                                                            // data?.listingsFoundOverview
                                                            //     ?.availableListings,
                                                        )}%`,
                                                    }}
                                                />
                                            </Div>
                                        </Div>
                                        <Div className={'EPCPMProgress'}>
                                            <Div className={'EPCPMPTitle'}>
                                                <Div className={'EPCPMPText'}>Found listings</Div>
                                                <Div className={'EPCPMPText'}>
                                                    {data?.listingsFoundOverview?.foundListings}
                                                </Div>
                                            </Div>
                                            <Div className={'EPCPMPBar'}>
                                                <Div
                                                    className={'EPCPMPBInner'}
                                                    style={{
                                                        backgroundColor: '#51c7ff',
                                                        width: `${parseInt(
                                                            (data?.listingsFoundOverview
                                                                ?.foundListings /
                                                                data?.listingsFoundOverview
                                                                    ?.availableListings) *
                                                                100,
                                                        )}%`,
                                                    }}
                                                />
                                            </Div>
                                        </Div>
                                        <Div className={'EPCPMProgress'}>
                                            <Div className={'EPCPMPTitle'}>
                                                <Div className={'EPCPMPText'}>
                                                    Not found listings
                                                </Div>
                                                <Div className={'EPCPMPText'}>
                                                    {data?.listingsFoundOverview?.notFoundListings}
                                                </Div>
                                            </Div>
                                            <Div className={'EPCPMPBar'}>
                                                <Div
                                                    className={'EPCPMPBInner'}
                                                    style={{
                                                        backgroundColor: '#f44336',
                                                        width: `${parseInt(
                                                            (data?.listingsFoundOverview
                                                                ?.notFoundListings /
                                                                data?.listingsFoundOverview
                                                                    ?.availableListings) *
                                                                100,
                                                        )}%`,
                                                    }}
                                                />
                                            </Div>
                                        </Div>
                                        <Div className={'EPCPMProgress'}>
                                            <Div className={'EPCPMPTitle'}>
                                                <Div className={'EPCPMPText'}>
                                                    Listings With Errors
                                                </Div>
                                                <Div className={'EPCPMPText'}>
                                                    {
                                                        data?.listingsFoundOverview
                                                            ?.listingsWithErrors
                                                    }
                                                </Div>
                                            </Div>
                                            <Div className={'EPCPMPBar'}>
                                                <Div
                                                    className={'EPCPMPBInner'}
                                                    style={{
                                                        backgroundColor: '#ffc13a',
                                                        width: `${parseInt(
                                                            (data?.listingsFoundOverview
                                                                ?.listingsWithErrors /
                                                                data?.listingsFoundOverview
                                                                    ?.availableListings) *
                                                                100,
                                                        )}%`,
                                                    }}
                                                />
                                            </Div>
                                        </Div>
                                    </Div>
                                </Div>
                                <Div className={'EPCRCol4'}>
                                    <Div className={'EPCProgressMain'}>
                                        <Div className={'EPCPMTitle'}>
                                            Listings Accuracy Overview
                                        </Div>
                                        <Div className={'EPCPMProgress'}>
                                            <Div className={'EPCPMPTitle'}>
                                                <Div className={'EPCPMPText'}>Business Name</Div>
                                                <Div className={'EPCPMPText'}>
                                                    {
                                                        data?.listingsAccuracyOverview
                                                            ?.businessNamePercentage
                                                    }
                                                    %
                                                </Div>
                                            </Div>
                                            <Div className={'EPCPMPBar'}>
                                                <Div
                                                    className={'EPCPMPBInner'}
                                                    style={{
                                                        backgroundColor: '#51c7ff',
                                                        width: `${data?.listingsAccuracyOverview?.businessNamePercentage}%`,
                                                    }}
                                                />
                                            </Div>
                                        </Div>
                                        <Div className={'EPCPMProgress'}>
                                            <Div className={'EPCPMPTitle'}>
                                                <Div className={'EPCPMPText'}>Address</Div>
                                                <Div className={'EPCPMPText'}>
                                                    {
                                                        data?.listingsAccuracyOverview
                                                            ?.addressPercentage
                                                    }
                                                    %
                                                </Div>
                                            </Div>
                                            <Div className={'EPCPMPBar'}>
                                                <Div
                                                    className={'EPCPMPBInner'}
                                                    style={{
                                                        backgroundColor: '#d961ff',
                                                        width: `${data?.listingsAccuracyOverview?.addressPercentage}%`,
                                                    }}
                                                />
                                            </Div>
                                        </Div>
                                        <Div className={'EPCPMProgress'}>
                                            <Div className={'EPCPMPTitle'}>
                                                <Div className={'EPCPMPText'}>Phone Number</Div>
                                                <Div className={'EPCPMPText'}>
                                                    {
                                                        data?.listingsAccuracyOverview
                                                            ?.phoneNumberPercentage
                                                    }
                                                    %
                                                </Div>
                                            </Div>
                                            <Div className={'EPCPMPBar'}>
                                                <Div
                                                    className={'EPCPMPBInner'}
                                                    style={{
                                                        backgroundColor: '#ffc13a',
                                                        width: `${data?.listingsAccuracyOverview?.phoneNumberPercentage}%`,
                                                    }}
                                                />
                                            </Div>
                                        </Div>
                                    </Div>
                                </Div>
                            </Div>
                            <Div className={'EPCListing'}>
                                {listings.map((listing, index) => {
                                    return (
                                        <Div
                                            className={'EPCLCol'}
                                            key={`listings-${index + 1}-${listing.siteName}`}
                                            data-check={
                                                listing?.status === 'LISTING_FOUND'
                                                    ? 'true'
                                                    : 'false'
                                            }
                                        >
                                            <Div className={'EPCLCHead'}>
                                                <Div className={'EPCLCHTitleBox'}>
                                                    <Div
                                                        className={'EPCLCHIcon'}
                                                        style={{ backgroundColor: '#f5f7fb' }}
                                                    >
                                                        <Image src={listing.logoURL} alt="" />
                                                    </Div>
                                                    <Div className={'EPCLCHTitle'}>
                                                        {listing.siteName}
                                                    </Div>
                                                    <Link href={listing.url} target="_blank">
                                                        <DCExternalWindow />
                                                    </Link>
                                                </Div>
                                            </Div>
                                            <Div className={'EPCLCBody'}>
                                                {listing.status === 'LISTING_NOT_FOUND' ? (
                                                    <Div className={'EPCLCBNo'}>
                                                        <Div className={'EPCLCBNIcon'}>
                                                            <DCGrayNoFound />
                                                        </Div>
                                                        <Div className={'EPCLCBNTitle'}>
                                                            Listing not found
                                                        </Div>
                                                    </Div>
                                                ) : (
                                                    <Fragment>
                                                        <Div className={'EPCLCBRow'}>
                                                            {listing.name.score < 0.9 ? (
                                                                <Div
                                                                    className={'EPCLCBRIcon'}
                                                                    style={{ color: '#f44336' }}
                                                                >
                                                                    <DCWarning />
                                                                </Div>
                                                            ) : (
                                                                <Div
                                                                    className={'EPCLCBRIcon'}
                                                                    style={{
                                                                        color: 'var(--dark-blue)',
                                                                    }}
                                                                >
                                                                    <DCSqHalfCheck />
                                                                </Div>
                                                            )}
                                                            <Div className={'EPCLCBRTitle'}>
                                                                {listing.name.value}
                                                            </Div>
                                                        </Div>
                                                        <Div className={'EPCLCBRow'}>
                                                            {listing.address.score < 0.9 ? (
                                                                <Div
                                                                    className={'EPCLCBRIcon'}
                                                                    style={{ color: '#f44336' }}
                                                                >
                                                                    <DCWarning />
                                                                </Div>
                                                            ) : (
                                                                <Div
                                                                    className={'EPCLCBRIcon'}
                                                                    style={{
                                                                        color: 'var(--dark-blue)',
                                                                    }}
                                                                >
                                                                    <DCSqHalfCheck />
                                                                </Div>
                                                            )}
                                                            <Div className={'EPCLCBRTitle'}>
                                                                {listing.address.value}{' '}
                                                                {listing.city} {listing.state}{' '}
                                                                {listing.zip}
                                                            </Div>
                                                        </Div>
                                                        <Div className={'EPCLCBRow'}>
                                                            {listing.phone.score < 0.9 ? (
                                                                <Div
                                                                    className={'EPCLCBRIcon'}
                                                                    style={{ color: '#f44336' }}
                                                                >
                                                                    <DCWarning />
                                                                </Div>
                                                            ) : (
                                                                <Div
                                                                    className={'EPCLCBRIcon'}
                                                                    style={{
                                                                        color: 'var(--dark-blue)',
                                                                    }}
                                                                >
                                                                    <DCSqHalfCheck />
                                                                </Div>
                                                            )}
                                                            <Div className={'EPCLCBRTitle'}>
                                                                {listing.phone.value}
                                                            </Div>
                                                        </Div>
                                                    </Fragment>
                                                )}
                                            </Div>
                                        </Div>
                                    );
                                })}
                            </Div>
                            <Div className={'EPCViewMore'}>
                                <Button
                                    buttonType={'BlueFillBtn'}
                                    onClick={
                                        listingViewState ? handleMoreListings : handleLessListings
                                    }
                                >
                                    {listingViewState ? 'View More' : 'View Less'}
                                </Button>
                            </Div>
                        </Fragment>
                    ) : (
                        <NoFound
                            title={'We’re not finding any Listings!'}
                            description={
                                <Fragment>
                                    <p>
                                        We couldn't find any active ads for your Facebook business
                                        page. Creating a professional Facebooks ads campaign will
                                        allow your business to create predictable revenue.
                                    </p>
                                    <p>
                                        With Facebook, you’re able to target specific sets of
                                        audiences such as age, location, gender, people who match an
                                        array of custom criteria and so much more. Get started today
                                        to maximize your marketing efforts.
                                    </p>
                                </Fragment>
                            }
                        />
                    )}
                    <Div className={'EPCUserComt'}>
                        <Div className={'EPCUCInner'}>
                            <Div className={'EPCUCInfo'}>
                                <Div className={'EPCUCIImg'}>
                                    <Image
                                        src={generateImage(user?.image, user?.name)}
                                        displayName={user?.name}
                                        alt={user?.name}
                                    />
                                </Div>
                                <Div className={'EPCUCICmt'}>
                                    Would you like to be found in all these directory listings with
                                    accurate business information and start generating more
                                    customers for {details?.name}? We can help!
                                </Div>
                            </Div>
                            <Div className={'EPCUCBtn'}>
                                <Button buttonType={'BlueFillBtn'} onClick={handleGetInTouchOpen}>
                                    Get in touch
                                </Button>
                            </Div>
                        </Div>
                    </Div>
                </Div>
            </Div>
        </Fragment>
    );
};

export default Listings;
