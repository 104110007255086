import React from 'react';

import { SVGIcon } from '../../UIKit/index';

export default function DCClipboard(props) {
    return (
        <SVGIcon {...props} viewBox="0 0 512 512">
            <g>
                <path d="m125.394 236.607c2.928 2.929 6.767 4.393 10.606 4.393s7.678-1.464 10.606-4.393l60-60c5.858-5.858 5.858-15.355 0-21.213-5.857-5.858-15.355-5.858-21.213 0l-49.393 49.393-19.394-19.394c-5.857-5.858-15.355-5.858-21.213 0s-5.858 15.355 0 21.213z" />
                <path d="m206.606 245.393c-5.857-5.858-15.355-5.858-21.213 0l-49.393 49.394-19.394-19.394c-5.857-5.858-15.355-5.858-21.213 0s-5.858 15.355 0 21.213l30 30c2.929 2.93 6.768 4.394 10.607 4.394s7.678-1.464 10.606-4.393l60-60c5.859-5.858 5.859-15.356 0-21.214z" />
                <path d="m436 512c8.284 0 15-6.716 15-15v-25.034c19.39-21.572 30-49.165 30-78.392v-257.574c0-19.555-12.541-36.228-30-42.42v-17.58c0-8.284-6.716-15-15-15s-15 6.716-15 15v17.58c-17.459 6.192-30 22.865-30 42.42v257.574c0 29.227 10.61 56.82 30 78.392v25.034c0 8.284 6.716 15 15 15zm0-69.413c-9.755-14.35-15-31.279-15-49.013v-122.574h30v122.574c0 17.734-5.245 34.662-15 49.013zm0-321.587c8.271 0 15 6.729 15 15v105h-30v-105c0-8.271 6.729-15 15-15z" />
                <path d="m167.819 374.18c-5.857-5.858-15.355-5.858-21.213 0l-10.606 10.607-10.606-10.607c-5.857-5.858-15.355-5.858-21.213 0s-5.858 15.355 0 21.213l10.606 10.607-10.606 10.607c-5.858 5.858-5.858 15.355 0 21.213 5.857 5.858 15.355 5.858 21.213 0l10.606-10.607 10.606 10.607c5.856 5.858 15.354 5.858 21.213 0 5.858-5.858 5.858-15.355 0-21.213l-10.606-10.607 10.606-10.607c5.859-5.858 5.859-15.355 0-21.213z" />
                <path d="m256 211c-8.284 0-15 6.716-15 15s6.716 15 15 15h30c8.284 0 15-6.716 15-15s-6.716-15-15-15z" />
                <path d="m286 301h-60c-8.284 0-15 6.716-15 15s6.716 15 15 15h60c8.284 0 15-6.716 15-15s-6.716-15-15-15z" />
                <path d="m286 391h-60c-8.284 0-15 6.716-15 15s6.716 15 15 15h60c8.284 0 15-6.716 15-15s-6.716-15-15-15z" />
                <path d="m196 0c-8.284 0-15 6.716-15 15v15h-15c-19.555 0-36.228 12.541-42.42 30h-77.58c-8.284 0-15 6.716-15 15v422c0 8.284 6.716 15 15 15h300c8.284 0 15-6.716 15-15v-422c0-8.284-6.716-15-15-15h-77.58c-6.192-17.459-22.865-30-42.42-30h-15v-15c0-8.284-6.716-15-15-15zm135 482h-270v-392h60v15c0 8.284 6.716 15 15 15h120c8.284 0 15-6.716 15-15v-15h60zm-90-407v15h-90v-15c0-8.271 6.729-15 15-15h60c8.271 0 15 6.729 15 15z" />
            </g>
        </SVGIcon>
    );
}
