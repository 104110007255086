import React from 'react';

import { SVGIcon } from '../../UIKit/index';

export default function DCGrayNotebook(props) {
    return (
        <SVGIcon {...props} viewBox="0 0 463.16 497.98">
            <g id="Notebooks" transform="translate(-26.32 -6.48)">
                <circle
                    id="Ellipse_13"
                    cx="230.08"
                    cy="230.08"
                    r="230.08"
                    transform="translate(27.82 7.98)"
                    fill="#fff"
                    stroke="#707171"
                    strokeMiterlimit="10"
                    strokeWidth="3"
                />
                <path
                    id="Path_349"
                    d="M444.92,117.72H384.38a2.72,2.72,0,0,0-2.71,2.72v10.05l-23.14,4.87a2.44,2.44,0,0,0-1.88,2.88l11.23,53.35a2.44,2.44,0,0,0,2.88,1.88L415.53,184h29.39a2.71,2.71,0,0,0,2.71-2.71V120.44a2.71,2.71,0,0,0-2.71-2.72Z"
                    fill="#ededed"
                />
                <path
                    id="Path_350"
                    d="M423.23,176.45a2.41,2.41,0,0,1-1.88,2.87l-53,11.16a2.43,2.43,0,0,1-2.88-1.87l-11.22-53.35a2.43,2.43,0,0,1,1.87-2.88l53-11.16A2.43,2.43,0,0,1,412,123.1Z"
                    fill="#e3e2e3"
                />
                <rect
                    id="Rectangle_117"
                    width="65.96"
                    height="66.32"
                    rx="2.71"
                    transform="translate(379.23 114.74)"
                    fill="#707171"
                />
                <path
                    id="Path_351"
                    d="M440.62,47.27l-329,39.19a2.66,2.66,0,0,0-2.32,3l3.48,29.18L114.16,130a2.64,2.64,0,0,0,3,2.32l329-39.19a2.66,2.66,0,0,0,2.33-2.95l-1.36-11.4-3.48-29.19a2.64,2.64,0,0,0-3.03-2.32Z"
                    fill="#ededed"
                />
                <path
                    id="Path_352"
                    d="M440,46.1a2.65,2.65,0,0,0-3-2.32L108,83a2.64,2.64,0,0,0-2.32,3l3.47,29.18L443.42,75.29Z"
                    fill="#e3e2e3"
                />
                <path
                    id="Path_353"
                    d="M109.17,115.1l1.36,11.41a2.65,2.65,0,0,0,3,2.32l329-39.18a2.66,2.66,0,0,0,2.32-3l-1.36-11.4Z"
                    fill="#707171"
                />
                <rect
                    id="Rectangle_118"
                    width="0.72"
                    height="13.19"
                    transform="translate(121.547 81.362) rotate(-6.64)"
                    fill="#707171"
                />
                <rect
                    id="Rectangle_119"
                    width="0.72"
                    height="8.81"
                    transform="translate(125.213 80.928) rotate(-6.62)"
                    fill="#707171"
                />
                <rect
                    id="Rectangle_120"
                    width="0.72"
                    height="8.81"
                    transform="matrix(0.993, -0.116, 0.116, 0.993, 129.054, 80.484)"
                    fill="#707171"
                />
                <rect
                    id="Rectangle_121"
                    width="0.72"
                    height="8.81"
                    transform="matrix(0.993, -0.115, 0.115, 0.993, 132.721, 80.057)"
                    fill="#707171"
                />
                <rect
                    id="Rectangle_122"
                    width="0.72"
                    height="8.81"
                    transform="matrix(0.993, -0.115, 0.115, 0.993, 136.464, 79.626)"
                    fill="#707171"
                />
                <rect
                    id="Rectangle_123"
                    width="0.72"
                    height="13.19"
                    transform="matrix(0.993, -0.115, 0.115, 0.993, 140.219, 79.196)"
                    fill="#707171"
                />
                <rect
                    id="Rectangle_124"
                    width="0.72"
                    height="8.81"
                    transform="translate(143.891 78.752) rotate(-6.63)"
                    fill="#707171"
                />
                <rect
                    id="Rectangle_125"
                    width="0.72"
                    height="8.81"
                    transform="translate(147.723 78.315) rotate(-6.64)"
                    fill="#707171"
                />
                <rect
                    id="Rectangle_126"
                    width="0.72"
                    height="8.81"
                    transform="matrix(0.993, -0.115, 0.115, 0.993, 151.388, 77.905)"
                    fill="#707171"
                />
                <rect
                    id="Rectangle_127"
                    width="0.72"
                    height="8.81"
                    transform="translate(155.142 77.445) rotate(-6.58)"
                    fill="#707171"
                />
                <rect
                    id="Rectangle_128"
                    width="0.72"
                    height="13.19"
                    transform="matrix(0.993, -0.115, 0.115, 0.993, 159.062, 77.02)"
                    fill="#707171"
                />
                <rect
                    id="Rectangle_129"
                    width="0.72"
                    height="8.81"
                    transform="translate(162.721 76.573) rotate(-6.65)"
                    fill="#707171"
                />
                <rect
                    id="Rectangle_130"
                    width="0.72"
                    height="8.81"
                    transform="matrix(0.993, -0.115, 0.115, 0.993, 166.565, 76.133)"
                    fill="#707171"
                />
                <rect
                    id="Rectangle_131"
                    width="0.72"
                    height="8.81"
                    transform="translate(170.221 75.711) rotate(-6.64)"
                    fill="#707171"
                />
                <rect
                    id="Rectangle_132"
                    width="0.72"
                    height="8.81"
                    transform="matrix(0.993, -0.115, 0.115, 0.993, 173.977, 75.285)"
                    fill="#707171"
                />
                <rect
                    id="Rectangle_133"
                    width="0.72"
                    height="13.19"
                    transform="matrix(0.993, -0.115, 0.115, 0.993, 177.916, 74.832)"
                    fill="#707171"
                />
                <rect
                    id="Rectangle_134"
                    width="0.72"
                    height="8.81"
                    transform="translate(181.573 74.387) rotate(-6.64)"
                    fill="#707171"
                />
                <rect
                    id="Rectangle_135"
                    width="0.72"
                    height="8.81"
                    transform="translate(185.416 73.939) rotate(-6.63)"
                    fill="#707171"
                />
                <rect
                    id="Rectangle_136"
                    width="0.72"
                    height="8.81"
                    transform="translate(189.078 73.532) rotate(-6.58)"
                    fill="#707171"
                />
                <rect
                    id="Rectangle_137"
                    width="0.72"
                    height="8.81"
                    transform="matrix(0.993, -0.115, 0.115, 0.993, 192.822, 73.101)"
                    fill="#707171"
                />
                <rect
                    id="Rectangle_138"
                    width="0.72"
                    height="13.19"
                    transform="translate(196.676 72.631) rotate(-6.56)"
                    fill="#707171"
                />
                <rect
                    id="Rectangle_139"
                    width="0.72"
                    height="8.81"
                    transform="translate(200.325 72.224) rotate(-6.64)"
                    fill="#707171"
                />
                <rect
                    id="Rectangle_140"
                    width="0.72"
                    height="8.81"
                    transform="translate(204.17 71.769) rotate(-6.63)"
                    fill="#707171"
                />
                <rect
                    id="Rectangle_141"
                    width="0.72"
                    height="8.81"
                    transform="matrix(0.993, -0.116, 0.116, 0.993, 207.827, 71.333)"
                    fill="#707171"
                />
                <rect
                    id="Rectangle_142"
                    width="0.72"
                    height="8.81"
                    transform="matrix(0.993, -0.115, 0.115, 0.993, 211.569, 70.943)"
                    fill="#707171"
                />
                <rect
                    id="Rectangle_143"
                    width="0.72"
                    height="13.19"
                    transform="translate(215.608 70.454) rotate(-6.58)"
                    fill="#707171"
                />
                <rect
                    id="Rectangle_144"
                    width="0.72"
                    height="13.19"
                    transform="translate(234.072 68.328) rotate(-6.64)"
                    fill="#707171"
                />
                <rect
                    id="Rectangle_145"
                    width="0.72"
                    height="8.81"
                    transform="translate(219.248 70.047) rotate(-6.58)"
                    fill="#707171"
                />
                <rect
                    id="Rectangle_146"
                    width="0.72"
                    height="8.81"
                    transform="translate(223.094 69.589) rotate(-6.57)"
                    fill="#707171"
                />
                <rect
                    id="Rectangle_147"
                    width="0.72"
                    height="8.81"
                    transform="translate(226.762 69.182) rotate(-6.57)"
                    fill="#707171"
                />
                <rect
                    id="Rectangle_148"
                    width="0.72"
                    height="8.81"
                    transform="translate(230.511 68.707) rotate(-6.63)"
                    fill="#707171"
                />
                <rect
                    id="Rectangle_149"
                    width="0.72"
                    height="8.81"
                    transform="matrix(0.993, -0.115, 0.115, 0.993, 238.336, 67.833)"
                    fill="#707171"
                />
                <rect
                    id="Rectangle_150"
                    width="0.72"
                    height="8.81"
                    transform="translate(242.17 67.375) rotate(-6.62)"
                    fill="#707171"
                />
                <rect
                    id="Rectangle_151"
                    width="0.72"
                    height="8.81"
                    transform="translate(245.84 66.951) rotate(-6.58)"
                    fill="#707171"
                />
                <rect
                    id="Rectangle_152"
                    width="0.72"
                    height="8.81"
                    transform="matrix(0.993, -0.115, 0.115, 0.993, 249.585, 66.51)"
                    fill="#707171"
                />
                <rect
                    id="Rectangle_153"
                    width="0.72"
                    height="13.19"
                    transform="translate(253.329 66.091) rotate(-6.63)"
                    fill="#707171"
                />
                <rect
                    id="Rectangle_154"
                    width="0.72"
                    height="8.81"
                    transform="translate(256.999 65.669) rotate(-6.55)"
                    fill="#707171"
                />
                <rect
                    id="Rectangle_155"
                    width="0.72"
                    height="8.81"
                    transform="matrix(0.993, -0.115, 0.115, 0.993, 260.838, 65.198)"
                    fill="#707171"
                />
                <rect
                    id="Rectangle_156"
                    width="0.72"
                    height="8.81"
                    transform="translate(264.503 64.781) rotate(-6.52)"
                    fill="#707171"
                />
                <rect
                    id="Rectangle_157"
                    width="0.72"
                    height="8.81"
                    transform="translate(268.255 64.359) rotate(-6.58)"
                    fill="#707171"
                />
                <rect
                    id="Rectangle_158"
                    width="0.72"
                    height="13.19"
                    transform="translate(272.182 63.894) rotate(-6.65)"
                    fill="#707171"
                />
                <rect
                    id="Rectangle_159"
                    width="0.72"
                    height="8.81"
                    transform="translate(275.847 63.48) rotate(-6.64)"
                    fill="#707171"
                />
                <rect
                    id="Rectangle_160"
                    width="0.72"
                    height="8.81"
                    transform="matrix(0.993, -0.116, 0.116, 0.993, 279.68, 63.021)"
                    fill="#707171"
                />
                <rect
                    id="Rectangle_161"
                    width="0.72"
                    height="8.81"
                    transform="translate(283.341 62.598) rotate(-6.63)"
                    fill="#707171"
                />
                <rect
                    id="Rectangle_162"
                    width="0.72"
                    height="8.81"
                    transform="translate(287.094 62.158) rotate(-6.65)"
                    fill="#707171"
                />
                <rect
                    id="Rectangle_163"
                    width="0.72"
                    height="13.19"
                    transform="translate(291.034 61.69) rotate(-6.57)"
                    fill="#707171"
                />
                <rect
                    id="Rectangle_164"
                    width="0.72"
                    height="8.81"
                    transform="translate(294.681 61.289) rotate(-6.67)"
                    fill="#707171"
                />
                <rect
                    id="Rectangle_165"
                    width="0.72"
                    height="8.81"
                    transform="translate(298.52 60.825) rotate(-6.64)"
                    fill="#707171"
                />
                <rect
                    id="Rectangle_166"
                    width="0.72"
                    height="8.81"
                    transform="translate(302.2 60.406) rotate(-6.58)"
                    fill="#707171"
                />
                <rect
                    id="Rectangle_167"
                    width="0.72"
                    height="8.81"
                    transform="translate(305.949 59.955) rotate(-6.54)"
                    fill="#707171"
                />
                <rect
                    id="Rectangle_168"
                    width="0.72"
                    height="13.19"
                    transform="translate(309.782 59.514) rotate(-6.62)"
                    fill="#707171"
                />
                <rect
                    id="Rectangle_169"
                    width="0.72"
                    height="8.81"
                    transform="translate(313.436 59.111) rotate(-6.73)"
                    fill="#707171"
                />
                <rect
                    id="Rectangle_170"
                    width="0.72"
                    height="8.81"
                    transform="translate(317.28 58.688) rotate(-6.58)"
                    fill="#707171"
                />
                <rect
                    id="Rectangle_171"
                    width="0.72"
                    height="8.81"
                    transform="translate(320.95 58.257) rotate(-6.56)"
                    fill="#707171"
                />
                <rect
                    id="Rectangle_172"
                    width="0.72"
                    height="8.81"
                    transform="translate(324.693 57.8) rotate(-6.64)"
                    fill="#707171"
                />
                <rect
                    id="Rectangle_173"
                    width="0.72"
                    height="13.19"
                    transform="translate(328.709 57.359) rotate(-6.58)"
                    fill="#707171"
                />
                <rect
                    id="Rectangle_174"
                    width="0.72"
                    height="13.19"
                    transform="translate(347.198 55.176) rotate(-6.64)"
                    fill="#707171"
                />
                <rect
                    id="Rectangle_175"
                    width="0.72"
                    height="8.81"
                    transform="matrix(0.993, -0.116, 0.116, 0.993, 332.371, 56.913)"
                    fill="#707171"
                />
                <rect
                    id="Rectangle_176"
                    width="0.72"
                    height="8.81"
                    transform="translate(336.219 56.455) rotate(-6.63)"
                    fill="#707171"
                />
                <rect
                    id="Rectangle_177"
                    width="0.72"
                    height="8.81"
                    transform="translate(339.868 56.026) rotate(-6.64)"
                    fill="#707171"
                />
                <rect
                    id="Rectangle_178"
                    width="0.72"
                    height="8.81"
                    transform="matrix(0.993, -0.115, 0.115, 0.993, 343.639, 55.639)"
                    fill="#707171"
                />
                <rect
                    id="Rectangle_179"
                    width="0.72"
                    height="8.81"
                    transform="translate(351.453 54.677) rotate(-6.63)"
                    fill="#707171"
                />
                <rect
                    id="Rectangle_180"
                    width="0.72"
                    height="8.81"
                    transform="translate(355.293 54.273) rotate(-6.57)"
                    fill="#707171"
                />
                <rect
                    id="Rectangle_181"
                    width="0.72"
                    height="8.81"
                    transform="translate(358.954 53.815) rotate(-6.64)"
                    fill="#707171"
                />
                <rect
                    id="Rectangle_182"
                    width="0.72"
                    height="8.81"
                    transform="translate(362.699 53.384) rotate(-6.64)"
                    fill="#707171"
                />
                <rect
                    id="Rectangle_183"
                    width="0.72"
                    height="13.19"
                    transform="translate(366.457 52.945) rotate(-6.62)"
                    fill="#707171"
                />
                <rect
                    id="Rectangle_184"
                    width="0.72"
                    height="8.81"
                    transform="translate(370.114 52.531) rotate(-6.66)"
                    fill="#707171"
                />
                <rect
                    id="Rectangle_185"
                    width="0.72"
                    height="8.81"
                    transform="matrix(0.993, -0.115, 0.115, 0.993, 373.957, 52.115)"
                    fill="#707171"
                />
                <rect
                    id="Rectangle_186"
                    width="0.72"
                    height="8.81"
                    transform="translate(377.62 51.676) rotate(-6.57)"
                    fill="#707171"
                />
                <rect
                    id="Rectangle_187"
                    width="0.72"
                    height="8.81"
                    transform="matrix(0.993, -0.115, 0.115, 0.993, 381.36, 51.223)"
                    fill="#707171"
                />
                <rect
                    id="Rectangle_188"
                    width="0.72"
                    height="13.19"
                    transform="translate(385.297 50.762) rotate(-6.7)"
                    fill="#707171"
                />
                <rect
                    id="Rectangle_189"
                    width="0.72"
                    height="8.81"
                    transform="translate(388.959 50.357) rotate(-6.62)"
                    fill="#707171"
                />
                <rect
                    id="Rectangle_190"
                    width="0.72"
                    height="8.81"
                    transform="translate(392.808 49.882) rotate(-6.56)"
                    fill="#707171"
                />
                <rect
                    id="Rectangle_191"
                    width="0.72"
                    height="8.81"
                    transform="matrix(0.993, -0.115, 0.115, 0.993, 396.467, 49.505)"
                    fill="#707171"
                />
                <rect
                    id="Rectangle_192"
                    width="0.72"
                    height="8.81"
                    transform="translate(400.215 49.036) rotate(-6.64)"
                    fill="#707171"
                />
                <rect
                    id="Rectangle_193"
                    width="0.72"
                    height="13.19"
                    transform="translate(404.142 48.576) rotate(-6.64)"
                    fill="#707171"
                />
                <rect
                    id="Rectangle_194"
                    width="0.72"
                    height="8.81"
                    transform="translate(407.811 48.2) rotate(-6.56)"
                    fill="#707171"
                />
                <rect
                    id="Rectangle_195"
                    width="0.72"
                    height="8.81"
                    transform="translate(411.644 47.74) rotate(-6.62)"
                    fill="#707171"
                />
                <rect
                    id="Rectangle_196"
                    width="0.72"
                    height="8.81"
                    transform="translate(415.312 47.27) rotate(-6.55)"
                    fill="#707171"
                />
                <rect
                    id="Rectangle_197"
                    width="0.72"
                    height="8.81"
                    transform="translate(419.057 46.827) rotate(-6.57)"
                    fill="#707171"
                />
                <rect
                    id="Rectangle_198"
                    width="0.72"
                    height="13.19"
                    transform="translate(422.905 46.391) rotate(-6.62)"
                    fill="#707171"
                />
                <path
                    id="Path_354"
                    d="M114.26,395.35l.06.85-3.47,1.91v.38l-2.18.15a1.26,1.26,0,0,0,.19,2.51l2.18-.16v.28l3.71,1.46v.61l11.47,4.52L125,389.47Z"
                    fill="#ededed"
                />
                <path
                    id="Path_355"
                    d="M280.39,388.21l-.14-1.84a13,13,0,0,0-14.11-11.9l-62.7,4.6.15,2.09-3.34.25-72.94,5.36a2.52,2.52,0,0,0-2.33,2.69l1.36,18.4a2.51,2.51,0,0,0,2.69,2.32l73.28-5.39,3-.22.16,2.09,58.27-4.28-3.29,3.05-48,3.53a1.57,1.57,0,1,0,.23,3.13l48.59-3.57a1.55,1.55,0,0,0,1-.41l6.6-6.13a13,13,0,0,0,11.52-13.77Z"
                    fill="#e3e2e3"
                />
                <path
                    id="Path_356"
                    d="M109.2,397.45a1.23,1.23,0,0,1,1.13-1.32l3.17-.23a1.23,1.23,0,0,1,.18,2.45l-3.17.23A1.23,1.23,0,0,1,109.2,397.45Z"
                    fill="#707171"
                />
                <path
                    id="Path_357"
                    d="M112.65,398.69l-.22-3.08,4.11-2.26.52,7.08Z"
                    fill="#707171"
                />
                <path
                    id="Path_358"
                    d="M116.31,400.71l-.57-7.79,10.43-5.73,1.32,17.92Z"
                    fill="#e3e2e3"
                />
                <path
                    id="Path_359"
                    d="M236.44,399.56a2.45,2.45,0,0,0,2.27-2.62L237.39,379a2.44,2.44,0,0,0-2.62-2.26l-35.23,2.59,2,22.78Z"
                    fill="#e3e2e3"
                />
                <path
                    id="Path_360"
                    d="M130.14,407.37l71.4-5.25-2-22.78-71.08,5.22a2.45,2.45,0,0,0-2.26,2.63l1.31,17.92a2.45,2.45,0,0,0,2.63,2.26Z"
                    fill="#f4f5f6"
                />
                <path
                    id="Path_361"
                    d="M259.89,405.35l6.44-6A12.7,12.7,0,0,0,277.62,386l-.13-1.8a12.73,12.73,0,0,0-13.75-11.6l-61.1,4.49,2,26.89,56.78-4.18-3.2,3-46.81,3.45a1.53,1.53,0,1,0,.22,3.05L259,405.76A1.55,1.55,0,0,0,259.89,405.35Z"
                    fill="#707171"
                />
                <path
                    id="Path_362"
                    d="M453.54,243.7l-4.85-1.92-3.62,9.13h0l-1.51,3.82-8.7-3.45,3.45-8.7h.07l1.68-4.25-5.32-2.11-3.62,9.13h0l-8.63-3.42,1.93-4.88,1.68-4.25-5.31-2.1-3.62,9.13h0l-1.52,3.82-8.7-3.44,3.45-8.7h.07l1.68-4.25-5.32-2.1-3.62,9.13h0l-1.51,3.83-8.7-3.45,3.44-8.7h.07l1.69-4.25-5.39-2.13L391,223.85h0l-3.44,8.7-8.71-3.45,1.52-3.82h0l3.61-9.13-5.32-2.11L377,218.31h0L373.6,227l-8.7-3.45,1.52-3.82h0l3.62-9.13-5.32-2.11L363,212.77h0l-3.45,8.7-8.7-3.44,1.51-3.83h0l3.62-9.13L350.72,203,349,207.23h0l-3.44,8.7-8.7-3.44,1.51-3.82h0l3.62-9.13-5.32-2.1-1.68,4.25h0l-3.45,8.71L323,207l1.52-3.82h0l3.62-9.13-5.32-2.11-1.69,4.25h.05l-3.45,8.7-8.7-3.45,1.51-3.82h0l3.61-9.13-4.88-1.94a10.939,10.939,0,0,0-2.65-.66V171.31a16.26,16.26,0,0,0-16.24-16.24H126.18a16.25,16.25,0,0,0-16.24,16.24V335.45a16.26,16.26,0,0,0,16.24,16.24H247.25l141.35,56a10.57,10.57,0,0,0,13.71-5.93l57.16-144.33a10.57,10.57,0,0,0-5.93-13.73Z"
                    fill="#f4f5f6"
                />
                <path
                    id="Path_363"
                    d="M450.49,237.11l-4.85-1.93L442,244.32h0l-1.52,3.82-8.7-3.44,3.45-8.71h.07l1.68-4.25-5.32-2.11L428,238.78h0l-1.51,3.82-8.7-3.44,3.45-8.7h.06l1.69-4.25-5.32-2.1-3.61,9.13h0l-1.51,3.83-8.7-3.45,3.44-8.7h.08l1.68-4.25-5.32-2.11-3.62,9.13h0l-1.51,3.82-8.71-3.45,3.45-8.7h.07l1.68-4.25L389.63,213,388,217.25h0l-3.45,8.7-8.7-3.45,1.51-3.82h0l3.62-9.13-5.33-2.11L374,211.71h.05l-3.45,8.7-8.7-3.44,1.51-3.83h0L367,204l-5.31-2.1L360,206.18h0l-3.44,8.7-8.71-3.44,1.52-3.82h0l3.62-9.14-5.32-2.1L346,200.64h0l-3.45,8.71-8.7-3.45,1.51-3.82h0L339,193l-5.31-2.11L332,195.1h0l-3.45,8.7-8.7-3.45,1.51-3.82h0l3.61-9.13-5.32-2.11L318,189.56h0l-3.44,8.7-8.71-3.45,1.52-3.82h0l3.62-9.13-4.89-1.94a10.56,10.56,0,0,0-13.7,5.93L235.3,330.2a10.55,10.55,0,0,0,5.93,13.71l144.33,57.16a10.54,10.54,0,0,0,13.7-5.93l57.16-144.33a10.54,10.54,0,0,0-5.93-13.7Z"
                    fill="#fff"
                />
                <line
                    id="Line_22"
                    x2="142.81"
                    y2="56.55"
                    transform="translate(287.08 230.14)"
                    fill="#303030"
                />
                <rect
                    id="Rectangle_199"
                    width="0.48"
                    height="153.6"
                    transform="translate(287.02 230.39) rotate(-68.39)"
                    fill="#707171"
                />
                <line
                    id="Line_23"
                    x2="142.8"
                    y2="56.56"
                    transform="translate(279.73 248.7)"
                    fill="#303030"
                />
                <rect
                    id="Rectangle_200"
                    width="0.48"
                    height="153.6"
                    transform="translate(279.659 248.948) rotate(-68.39)"
                    fill="#707171"
                />
                <line
                    id="Line_24"
                    x2="142.81"
                    y2="56.56"
                    transform="translate(272.37 267.26)"
                    fill="#303030"
                />
                <path
                    id="Path_364"
                    d="M415.09,324.05l-142.8-56.56.17-.45L415.27,323.6Z"
                    fill="#707171"
                />
                <line
                    id="Line_25"
                    x2="142.81"
                    y2="56.56"
                    transform="translate(265.02 285.83)"
                    fill="#303030"
                />
                <rect
                    id="Rectangle_201"
                    width="0.48"
                    height="153.6"
                    transform="translate(264.951 286.089) rotate(-68.39)"
                    fill="#707171"
                />
                <line
                    id="Line_26"
                    x2="142.81"
                    y2="56.56"
                    transform="translate(257.67 304.39)"
                    fill="#303030"
                />
                <rect
                    id="Rectangle_202"
                    width="0.48"
                    height="153.6"
                    transform="translate(257.59 304.648) rotate(-68.39)"
                    fill="#707171"
                />
                <line
                    id="Line_27"
                    x2="142.8"
                    y2="56.56"
                    transform="translate(250.32 322.96)"
                    fill="#303030"
                />
                <rect
                    id="Rectangle_203"
                    width="0.48"
                    height="153.6"
                    transform="translate(250.247 323.21) rotate(-68.39)"
                    fill="#707171"
                />
                <path
                    id="Path_365"
                    d="M421.31,230.46l-.07-.02-3.45,8.7,8.7,3.45,1.51-3.83-8.63-3.42Z"
                    fill="none"
                />
                <path
                    id="Path_366"
                    d="M294.71,151.35H130.58a16.25,16.25,0,0,0-16.24,16.24V331.72A16.25,16.25,0,0,0,130.58,348H294.71A16.25,16.25,0,0,0,311,331.72V167.59a16.25,16.25,0,0,0-16.29-16.24Zm11.17,180.37a11.17,11.17,0,0,1-11.17,11.17H130.58a11.17,11.17,0,0,1-11.17-11.17V167.59a11.17,11.17,0,0,1,11.17-11.17H294.71a11.17,11.17,0,0,1,11.17,11.17Z"
                    fill="#e3e2e3"
                />
                <path
                    id="Path_367"
                    d="M294.71,156.42H130.58a11.17,11.17,0,0,0-11.17,11.17V331.72a11.17,11.17,0,0,0,11.17,11.17H294.71a11.17,11.17,0,0,0,11.17-11.17V167.59A11.17,11.17,0,0,0,294.71,156.42ZM146,171.15h-9.9v-9.89H146Zm15.9,0H152v-9.89h9.9Zm15.9,0h-9.9v-9.89h9.9Zm15.9,0h-9.89v-9.89h9.89Zm15.9,0h-9.89v-9.89h9.89Zm15.91,0h-9.9v-9.89h9.9Zm15.9,0h-9.9v-9.89h9.9Zm15.9,0h-9.9v-9.89h9.9Zm15.9,0h-9.89v-9.89h9.89Zm15.9,0h-9.89v-9.89h9.89Z"
                    fill="#fff"
                />
                <rect
                    id="Rectangle_204"
                    width="162.4"
                    height="0.51"
                    transform="translate(131.36 212.94)"
                    fill="#707171"
                />
                <rect
                    id="Rectangle_205"
                    width="162.4"
                    height="0.51"
                    transform="translate(131.36 234.05)"
                    fill="#707171"
                />
                <rect
                    id="Rectangle_206"
                    width="162.4"
                    height="0.51"
                    transform="translate(131.36 255.16)"
                    fill="#707171"
                />
                <rect
                    id="Rectangle_207"
                    width="162.4"
                    height="0.51"
                    transform="translate(131.36 276.27)"
                    fill="#707171"
                />
                <rect
                    id="Rectangle_208"
                    width="162.4"
                    height="0.51"
                    transform="translate(131.36 297.39)"
                    fill="#707171"
                />
                <rect
                    id="Rectangle_209"
                    width="162.4"
                    height="0.51"
                    transform="translate(131.36 318.5)"
                    fill="#707171"
                />
                <rect
                    id="Rectangle_210"
                    width="18.99"
                    height="18.31"
                    transform="translate(305.81 232.54)"
                    fill="#e3e2e3"
                />
                <rect
                    id="Rectangle_211"
                    width="18.99"
                    height="18.31"
                    transform="translate(305.81 279.23)"
                    fill="#e3e2e3"
                />
                <rect
                    id="Rectangle_212"
                    width="21.7"
                    height="21.86"
                    transform="translate(305.81 252.33)"
                    fill="#707171"
                />
                <path
                    id="Path_368"
                    d="M294.71,343.9H130.58a12.19,12.19,0,0,1-12.18-12.18V167.59a12.19,12.19,0,0,1,12.18-12.18H294.71a12.19,12.19,0,0,1,12.18,12.18V331.72A12.19,12.19,0,0,1,294.71,343.9ZM130.58,157.44a10.16,10.16,0,0,0-10.15,10.15V331.72a10.17,10.17,0,0,0,10.15,10.16H294.71a10.17,10.17,0,0,0,10.15-10.16V167.59a10.16,10.16,0,0,0-10.15-10.15Z"
                    fill="#707171"
                />
                <rect
                    id="Rectangle_213"
                    width="5.48"
                    height="18.52"
                    transform="translate(138.25 148.15)"
                    fill="#707171"
                />
                <rect
                    id="Rectangle_214"
                    width="5.48"
                    height="18.52"
                    transform="translate(154.3 148.15)"
                    fill="#707171"
                />
                <rect
                    id="Rectangle_215"
                    width="5.48"
                    height="18.52"
                    transform="translate(170.23 148.15)"
                    fill="#707171"
                />
                <rect
                    id="Rectangle_216"
                    width="5.48"
                    height="18.52"
                    transform="translate(186.28 148.15)"
                    fill="#707171"
                />
                <rect
                    id="Rectangle_217"
                    width="5.48"
                    height="18.52"
                    transform="translate(201.86 148.15)"
                    fill="#707171"
                />
                <rect
                    id="Rectangle_218"
                    width="5.48"
                    height="18.52"
                    transform="translate(217.91 148.15)"
                    fill="#707171"
                />
                <rect
                    id="Rectangle_219"
                    width="5.48"
                    height="18.52"
                    transform="translate(233.83 148.15)"
                    fill="#707171"
                />
                <rect
                    id="Rectangle_220"
                    width="5.48"
                    height="18.52"
                    transform="translate(249.88 148.15)"
                    fill="#707171"
                />
                <rect
                    id="Rectangle_221"
                    width="5.48"
                    height="18.52"
                    transform="translate(265.47 148.15)"
                    fill="#707171"
                />
                <rect
                    id="Rectangle_222"
                    width="5.48"
                    height="18.52"
                    transform="translate(281.52 148.15)"
                    fill="#707171"
                />
                <rect
                    id="Rectangle_223"
                    width="51.23"
                    height="0.7"
                    transform="translate(388.18 138.08)"
                    fill="#e3e2e3"
                />
                <rect
                    id="Rectangle_224"
                    width="18.43"
                    height="0.7"
                    transform="translate(388.18 128.18)"
                    fill="#e3e2e3"
                />
                <rect
                    id="Rectangle_225"
                    width="10.3"
                    height="0.7"
                    transform="translate(388.18 172.23)"
                    fill="#e3e2e3"
                />
                <rect
                    id="Rectangle_226"
                    width="51.23"
                    height="0.7"
                    transform="translate(388.18 142.69)"
                    fill="#e3e2e3"
                />
                <rect
                    id="Rectangle_227"
                    width="51.23"
                    height="0.7"
                    transform="translate(388.18 147.84)"
                    fill="#e3e2e3"
                />
                <rect
                    id="Rectangle_228"
                    width="51.23"
                    height="0.7"
                    transform="translate(388.18 153.08)"
                    fill="#e3e2e3"
                />
                <path
                    id="Path_369"
                    d="M436.83,127.74c-.05-.19-4.63-18.66-9.58-18.66h-.19a2.72,2.72,0,0,0-2.34,1.39c-.66,1.24-.29,3,.21,4.46h1.16c-.51-1.35-.92-3-.42-4a1.71,1.71,0,0,1,1.49-.81h.1c3,0,7.31,12.42,8.51,17.81a3.42,3.42,0,0,1-1.79,4c-1.43.55-3-.41-4.26-2.59a65.779,65.779,0,0,1-5.66-14,.542.542,0,0,0-1.05.27,67.27,67.27,0,0,0,5.77,14.29c1.54,2.71,3.23,3.28,4.39,3.28a3.4,3.4,0,0,0,1.2-.22,4.47,4.47,0,0,0,2.46-5.22Z"
                    fill="#e3e2e3"
                />
                <path
                    id="Path_370"
                    d="M428.68,113a.44.44,0,0,0-.57-.23.43.43,0,0,0-.23.57l.66,1.58h.94Z"
                    fill="#515151"
                />
                <ellipse
                    id="_Path_"
                    data-name="&lt;Path&gt;"
                    cx="151.98"
                    cy="11.42"
                    rx="151.98"
                    ry="11.42"
                    transform="translate(106.75 481.62)"
                    fill="#f5f5f5"
                />
                <circle
                    id="Ellipse_14"
                    cx="8.21"
                    cy="8.21"
                    r="8.21"
                    transform="translate(446.36 310.29)"
                    fill="#fff"
                    stroke="#e2e2e2"
                    strokeMiterlimit="10"
                    strokeWidth="2"
                />
                <circle
                    id="Ellipse_15"
                    cx="8.21"
                    cy="8.21"
                    r="8.21"
                    transform="translate(51.44 218.08)"
                    fill="#fff"
                    stroke="#e2e2e2"
                    strokeMiterlimit="10"
                    strokeWidth="2"
                />
                <circle
                    id="Ellipse_16"
                    cx="12.74"
                    cy="12.74"
                    r="12.74"
                    transform="translate(74.32 269)"
                    fill="#fff"
                    stroke="#e2e2e2"
                    strokeMiterlimit="10"
                    strokeWidth="2"
                />
                <circle
                    id="Ellipse_17"
                    cx="5.74"
                    cy="5.74"
                    r="5.74"
                    transform="translate(336.47 113.15)"
                    fill="#fff"
                    stroke="#e2e2e2"
                    strokeMiterlimit="10"
                    strokeWidth="2"
                />
                <circle
                    id="Ellipse_18"
                    cx="8.41"
                    cy="8.41"
                    r="8.41"
                    transform="translate(303.6 128.09)"
                    fill="#fff"
                    stroke="#e2e2e2"
                    strokeMiterlimit="10"
                    strokeWidth="2"
                />
                <path
                    id="Path_371"
                    d="M472.43,187.65H464.9v-7.51a.93.93,0,0,0-.93-.94h0a1,1,0,0,0-1,.94v7.51h-7.5a.95.95,0,0,0,0,1.89H463v7.52a1,1,0,0,0,1,.94.93.93,0,0,0,.93-.94h0v-7.52h7.53a.95.95,0,0,0,0-1.89Z"
                    fill="#e2e2e2"
                    stroke="#e2e2e2"
                    strokeMiterlimit="10"
                    strokeWidth="0.75"
                />
                <path
                    id="Path_372"
                    d="M83,139.69H76.47v-6.53a.82.82,0,0,0-.81-.82h0a.83.83,0,0,0-.83.82v6.53H68.3a.83.83,0,0,0-.82.83.82.82,0,0,0,.82.81h6.53v6.55a.83.83,0,0,0,.83.81.81.81,0,0,0,.81-.81h0v-6.55H83a.82.82,0,0,0,.82-.81.83.83,0,0,0-.82-.83Z"
                    fill="#e2e2e2"
                    stroke="#e2e2e2"
                    strokeMiterlimit="10"
                    strokeWidth="0.75"
                />
                <path
                    id="Path_373"
                    d="M313.17,411.27h-6.54v-6.53a.82.82,0,0,0-.82-.82h0a.84.84,0,0,0-.83.82v6.53h-6.52a.83.83,0,0,0,0,1.65H305v6.54a.83.83,0,0,0,.83.81.82.82,0,0,0,.82-.81h0v-6.54h6.54a.82.82,0,0,0,.82-.82.83.83,0,0,0-.84-.83Z"
                    fill="#e2e2e2"
                    stroke="#e2e2e2"
                    strokeMiterlimit="10"
                    strokeWidth="0.75"
                />
                <path
                    id="Path_374"
                    d="M104.1,173.38H96.8v-7.29a.9.9,0,0,0-.91-.9h0a.94.94,0,0,0-.93.9v7.29H87.68a.93.93,0,0,0-.91.93.91.91,0,0,0,.91.91H95v7.3a.94.94,0,0,0,.93.91.91.91,0,0,0,.91-.91h0v-7.3h7.3a.92.92,0,0,0,0-1.84Z"
                    fill="#e2e2e2"
                    stroke="#e2e2e2"
                    strokeMiterlimit="10"
                    strokeWidth="0.75"
                />
                <path
                    id="Path_375"
                    d="M255,130.3h-4.51v-4.5a.56.56,0,0,0-.56-.56h0a.57.57,0,0,0-.57.56v4.5h-4.49a.57.57,0,1,0,0,1.13h4.49v4.51a.57.57,0,0,0,.57.56.56.56,0,0,0,.56-.56h0v-4.51H255a.57.57,0,1,0,0-1.13Z"
                    fill="#e2e2e2"
                    stroke="#e2e2e2"
                    strokeMiterlimit="10"
                    strokeWidth="0.75"
                />
            </g>
        </SVGIcon>
    );
}
