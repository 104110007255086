import { CallAPI } from 'Utils/apiCall';
import config from 'config';

const api = new CallAPI();
const mainUrl = `${config.REACT_APP_API_BASE_URL}/${process.env.REACT_APP_API_VERSION}`;
const baseUrl = `${mainUrl}/${process.env.REACT_APP_API_FORMS}`;
const deleteFromWassabiUrl = `${mainUrl}/${process.env.REACT_APP_API_INTEGRATIONS}/${process.env.REACT_APP_API_WASABI}/${process.env.REACT_APP_API_DELETE_BY_KEY}`;

// My Forms
export const getAllForms = async (limit, page, query) => {
    return api.request('GET', baseUrl, null, limit, page, query);
};

export const getUserCategories = async params => {
    return api.request(
        'GET',
        `${baseUrl}/${process.env.REACT_APP_API_USERCATEGORIES}`,
        null,
        99,
        1,
        params,
    );
};

export const editUserCategory = (id, body) => {
    return api.request('PUT', `${baseUrl}/${process.env.REACT_APP_API_USERCATEGORIES}/${id}`, body);
};

export const deleteUserCategory = id => {
    return api.request('DELETE', `${baseUrl}/${process.env.REACT_APP_API_USERCATEGORIES}/${id}`);
};

export const getUserTags = async params => {
    return api.request(
        'GET',
        `${baseUrl}/${process.env.REACT_APP_API_USERTAGS}`,
        null,
        99,
        1,
        params,
    );
};

export const editUserTag = (id, body) => {
    return api.request('PUT', `${baseUrl}/${process.env.REACT_APP_API_USERTAGS}/${id}`, body);
};

export const deleteUserTag = id => {
    return api.request('DELETE', `${baseUrl}/${process.env.REACT_APP_API_USERTAGS}/${id}`);
};

export const getSubmissions = async (formId, limit, page, type, params) => {
    let url;
    switch (type) {
        case 'all':
            url = `${baseUrl}/${formId}/${process.env.REACT_APP_API_USERRESPONSE}`;
            break;
        case 'requests':
            url = `${baseUrl}/${formId}/${process.env.REACT_APP_API_USERRESPONSE}?type=requests`;
            break;
        case 'pending':
            url = `${baseUrl}/${formId}/${process.env.REACT_APP_API_GET_PENDING_USERRESPONSE}`;
            break;
        default:
            url = `${baseUrl}/${formId}/${process.env.REACT_APP_API_USERRESPONSE}`;
    }
    return api.request('GET', url, null, limit, page, params);
};

export const getSubmissionById = async (formId, submissionId) => {
    let url = `${baseUrl}/${formId}/${process.env.REACT_APP_API_USERRESPONSE}/${submissionId}`;
    return api.request('GET', url);
};

export const deleteForms = async (ids, query, deleteAll) => {
    let params = {};
    if (deleteAll) params = query;
    else params = { forms: ids.join(','), total: ids.length };

    return api.request('DELETE', baseUrl, null, null, null, params);
};

export const deleteSubmissions = async (formId, submissionIds) => {
    let url;
    if (submissionIds.length > 1) {
        let queryString = submissionIds.join(',');
        url = `${baseUrl}/${formId}/${process.env.REACT_APP_API_USERRESPONSE}?responses=${queryString}`;
    } else {
        url = `${baseUrl}/${formId}/${process.env.REACT_APP_API_USERRESPONSE}/${submissionIds[0]}`;
    }

    return api.request('DELETE', url);
};

export const sendRequest = async (formSecretId, body) => {
    let url = `${baseUrl}/${formSecretId}/${process.env.REACT_APP_API_SENDREQUEST}`;

    return api.request('POST', url, body);
};

export const resendRequest = async body => {
    const url = `${baseUrl}/${process.env.REACT_APP_API_RESEND_REQUEST}`;
    return api.request('POST', url, body);
};

export const cancelRequest = async (formId, requestId) => {
    const url = `${baseUrl}/${formId}/${process.env.REACT_APP_API_FORM_REQUEST}/${requestId}`;
    const body = { updateData: { status: 'cancelled' } };
    return api.request('PUT', url, body);
};

// Builder
export const createForm = async (body, clone = false, templates) => {
    let url = clone ? `${baseUrl}?isclone=true` : baseUrl;
    if (templates) url = `${url}/${process.env.REACT_APP_API_TEMPLATES}`;
    return api.request('POST', url, body);
};

export const getForm = async (id, template) => {
    let url;
    if (template === 'true') url = `${baseUrl}/${process.env.REACT_APP_API_TEMPLATES}/${id}`;
    else {
        url = `${baseUrl}/${id}`;
    }

    return api.request('GET', url);
};

export const getPublicForm = async (id, rId = null) => {
    let url = `${baseUrl}/${id}`;
    url = rId === null ? url : `${url}?rid=${rId}`;
    return api.requestPublic('GET', url);
};

export const getBuilderCategories = async params => {
    let url = `${baseUrl}/${process.env.REACT_APP_API_USERCATEGORIES}`;

    return api.request('GET', url, null, null, null, params);
};

export const getBuilderTags = async params => {
    let url = `${baseUrl}/${process.env.REACT_APP_API_USERTAGS}`;

    return api.request('GET', url, null, null, null, params);
};

export const addNewCategory = async body => {
    let url = `${baseUrl}/${process.env.REACT_APP_API_USERCATEGORIES}`;
    return api.request('POST', url, body);
};

export const addNewTag = async body => {
    let url = `${baseUrl}/${process.env.REACT_APP_API_USERTAGS}`;
    return api.request('POST', url, body);
};

export const submitFormAPI = async (body, id) => {
    let url = `${baseUrl}/${id}/${process.env.REACT_APP_API_USERRESPONSE}`;

    return api.requestPublic('POST', url, body);
};

export const updateSubmission = (body, formId, submissionId) => {
    let url = `${baseUrl}/${formId}/${process.env.REACT_APP_API_USERRESPONSE}/${submissionId}`;

    return api.request('PUT', url, body);
};

// autosaves
export const autoSaveAPI = async (body, id, createTemplate) => {
    let url;
    url =
        createTemplate === 'true'
            ? `${baseUrl}/${process.env.REACT_APP_API_TEMPLATES}/${id}`
            : `${baseUrl}/${id}`;

    return api.request('PUT', url, body);
};

// Templates
export const getTemplateCategories = async params => {
    let url = `${baseUrl}/${process.env.REACT_APP_API_TEMPLATES}/${process.env.REACT_APP_API_CATEGORIES}`;

    return api.request('GET', url, null, null, null, params);
};

export const getFormTemplates = async params => {
    let url = `${baseUrl}/${process.env.REACT_APP_API_TEMPLATES}`;
    return api.request('GET', url, null, null, null, params);
};

export const deleteFileFromWassabi = async (key, bucket) => {
    return api.request('DELETE', deleteFromWassabiUrl, {
        key,
        bucket,
    });
};
