import React from 'react';

import { Skeleton } from '../../UIKit/index';

import './style.scss';

const TableSkeleton = ({}) => {
    return (
        <table className={'DCTable TableSkeleton'}>
            <thead className="PageTableHead">
                <tr className="PTHRow">
                    <th className="PTHRHead">
                        <Skeleton />
                    </th>
                    <th className="PTHRHead">
                        <Skeleton />
                    </th>
                    <th className="PTHRHead">
                        <Skeleton />
                    </th>
                    <th className="PTHRHead">
                        <Skeleton />
                    </th>
                    <th className="PTHRHead">
                        <Skeleton />
                    </th>
                    <th className="PTHRHead">
                        <Skeleton />
                    </th>
                    <th className="PTHRHead">
                        <Skeleton />
                    </th>
                </tr>
            </thead>
            <tbody className="PageTableBody">
                <tr className="PTHRow">
                    <td className="PTHRHead">
                        <Skeleton />
                    </td>
                    <td className="PTHRHead">
                        <Skeleton />
                    </td>
                    <td className="PTHRHead">
                        <Skeleton />
                    </td>
                    <td className="PTHRHead">
                        <Skeleton />
                    </td>
                    <td className="PTHRHead">
                        <Skeleton />
                    </td>
                    <td className="PTHRHead">
                        <Skeleton />
                    </td>
                    <td className="PTHRHead">
                        <Skeleton />
                    </td>
                </tr>
                <tr className="PTHRow">
                    <td className="PTHRHead">
                        <Skeleton />
                    </td>
                    <td className="PTHRHead">
                        <Skeleton />
                    </td>
                    <td className="PTHRHead">
                        <Skeleton />
                    </td>
                    <td className="PTHRHead">
                        <Skeleton />
                    </td>
                    <td className="PTHRHead">
                        <Skeleton />
                    </td>
                    <td className="PTHRHead">
                        <Skeleton />
                    </td>
                    <td className="PTHRHead">
                        <Skeleton />
                    </td>
                </tr>
                <tr className="PTHRow">
                    <td className="PTHRHead">
                        <Skeleton />
                    </td>
                    <td className="PTHRHead">
                        <Skeleton />
                    </td>
                    <td className="PTHRHead">
                        <Skeleton />
                    </td>
                    <td className="PTHRHead">
                        <Skeleton />
                    </td>
                    <td className="PTHRHead">
                        <Skeleton />
                    </td>
                    <td className="PTHRHead">
                        <Skeleton />
                    </td>
                    <td className="PTHRHead">
                        <Skeleton />
                    </td>
                </tr>
                <tr className="PTHRow">
                    <td className="PTHRHead">
                        <Skeleton />
                    </td>
                    <td className="PTHRHead">
                        <Skeleton />
                    </td>
                    <td className="PTHRHead">
                        <Skeleton />
                    </td>
                    <td className="PTHRHead">
                        <Skeleton />
                    </td>
                    <td className="PTHRHead">
                        <Skeleton />
                    </td>
                    <td className="PTHRHead">
                        <Skeleton />
                    </td>
                    <td className="PTHRHead">
                        <Skeleton />
                    </td>
                </tr>
                <tr className="PTHRow">
                    <td className="PTHRHead">
                        <Skeleton />
                    </td>
                    <td className="PTHRHead">
                        <Skeleton />
                    </td>
                    <td className="PTHRHead">
                        <Skeleton />
                    </td>
                    <td className="PTHRHead">
                        <Skeleton />
                    </td>
                    <td className="PTHRHead">
                        <Skeleton />
                    </td>
                    <td className="PTHRHead">
                        <Skeleton />
                    </td>
                    <td className="PTHRHead">
                        <Skeleton />
                    </td>
                </tr>
                <tr className="PTHRow">
                    <td className="PTHRHead">
                        <Skeleton />
                    </td>
                    <td className="PTHRHead">
                        <Skeleton />
                    </td>
                    <td className="PTHRHead">
                        <Skeleton />
                    </td>
                    <td className="PTHRHead">
                        <Skeleton />
                    </td>
                    <td className="PTHRHead">
                        <Skeleton />
                    </td>
                    <td className="PTHRHead">
                        <Skeleton />
                    </td>
                    <td className="PTHRHead">
                        <Skeleton />
                    </td>
                </tr>
                <tr className="PTHRow">
                    <td className="PTHRHead">
                        <Skeleton />
                    </td>
                    <td className="PTHRHead">
                        <Skeleton />
                    </td>
                    <td className="PTHRHead">
                        <Skeleton />
                    </td>
                    <td className="PTHRHead">
                        <Skeleton />
                    </td>
                    <td className="PTHRHead">
                        <Skeleton />
                    </td>
                    <td className="PTHRHead">
                        <Skeleton />
                    </td>
                    <td className="PTHRHead">
                        <Skeleton />
                    </td>
                </tr>
                <tr className="PTHRow">
                    <td className="PTHRHead">
                        <Skeleton />
                    </td>
                    <td className="PTHRHead">
                        <Skeleton />
                    </td>
                    <td className="PTHRHead">
                        <Skeleton />
                    </td>
                    <td className="PTHRHead">
                        <Skeleton />
                    </td>
                    <td className="PTHRHead">
                        <Skeleton />
                    </td>
                    <td className="PTHRHead">
                        <Skeleton />
                    </td>
                    <td className="PTHRHead">
                        <Skeleton />
                    </td>
                </tr>
                <tr className="PTHRow">
                    <td className="PTHRHead">
                        <Skeleton />
                    </td>
                    <td className="PTHRHead">
                        <Skeleton />
                    </td>
                    <td className="PTHRHead">
                        <Skeleton />
                    </td>
                    <td className="PTHRHead">
                        <Skeleton />
                    </td>
                    <td className="PTHRHead">
                        <Skeleton />
                    </td>
                    <td className="PTHRHead">
                        <Skeleton />
                    </td>
                    <td className="PTHRHead">
                        <Skeleton />
                    </td>
                </tr>
                <tr className="PTHRow">
                    <td className="PTHRHead">
                        <Skeleton />
                    </td>
                    <td className="PTHRHead">
                        <Skeleton />
                    </td>
                    <td className="PTHRHead">
                        <Skeleton />
                    </td>
                    <td className="PTHRHead">
                        <Skeleton />
                    </td>
                    <td className="PTHRHead">
                        <Skeleton />
                    </td>
                    <td className="PTHRHead">
                        <Skeleton />
                    </td>
                    <td className="PTHRHead">
                        <Skeleton />
                    </td>
                </tr>
                <tr className="PTHRow">
                    <td className="PTHRHead">
                        <Skeleton />
                    </td>
                    <td className="PTHRHead">
                        <Skeleton />
                    </td>
                    <td className="PTHRHead">
                        <Skeleton />
                    </td>
                    <td className="PTHRHead">
                        <Skeleton />
                    </td>
                    <td className="PTHRHead">
                        <Skeleton />
                    </td>
                    <td className="PTHRHead">
                        <Skeleton />
                    </td>
                    <td className="PTHRHead">
                        <Skeleton />
                    </td>
                </tr>
                <tr className="PTHRow">
                    <td className="PTHRHead">
                        <Skeleton />
                    </td>
                    <td className="PTHRHead">
                        <Skeleton />
                    </td>
                    <td className="PTHRHead">
                        <Skeleton />
                    </td>
                    <td className="PTHRHead">
                        <Skeleton />
                    </td>
                    <td className="PTHRHead">
                        <Skeleton />
                    </td>
                    <td className="PTHRHead">
                        <Skeleton />
                    </td>
                    <td className="PTHRHead">
                        <Skeleton />
                    </td>
                </tr>
                <tr className="PTHRow">
                    <td className="PTHRHead">
                        <Skeleton />
                    </td>
                    <td className="PTHRHead">
                        <Skeleton />
                    </td>
                    <td className="PTHRHead">
                        <Skeleton />
                    </td>
                    <td className="PTHRHead">
                        <Skeleton />
                    </td>
                    <td className="PTHRHead">
                        <Skeleton />
                    </td>
                    <td className="PTHRHead">
                        <Skeleton />
                    </td>
                    <td className="PTHRHead">
                        <Skeleton />
                    </td>
                </tr>
                <tr className="PTHRow">
                    <td className="PTHRHead">
                        <Skeleton />
                    </td>
                    <td className="PTHRHead">
                        <Skeleton />
                    </td>
                    <td className="PTHRHead">
                        <Skeleton />
                    </td>
                    <td className="PTHRHead">
                        <Skeleton />
                    </td>
                    <td className="PTHRHead">
                        <Skeleton />
                    </td>
                    <td className="PTHRHead">
                        <Skeleton />
                    </td>
                    <td className="PTHRHead">
                        <Skeleton />
                    </td>
                </tr>
                <tr className="PTHRow">
                    <td className="PTHRHead">
                        <Skeleton />
                    </td>
                    <td className="PTHRHead">
                        <Skeleton />
                    </td>
                    <td className="PTHRHead">
                        <Skeleton />
                    </td>
                    <td className="PTHRHead">
                        <Skeleton />
                    </td>
                    <td className="PTHRHead">
                        <Skeleton />
                    </td>
                    <td className="PTHRHead">
                        <Skeleton />
                    </td>
                    <td className="PTHRHead">
                        <Skeleton />
                    </td>
                </tr>
                <tr className="PTHRow">
                    <td className="PTHRHead">
                        <Skeleton />
                    </td>
                    <td className="PTHRHead">
                        <Skeleton />
                    </td>
                    <td className="PTHRHead">
                        <Skeleton />
                    </td>
                    <td className="PTHRHead">
                        <Skeleton />
                    </td>
                    <td className="PTHRHead">
                        <Skeleton />
                    </td>
                    <td className="PTHRHead">
                        <Skeleton />
                    </td>
                    <td className="PTHRHead">
                        <Skeleton />
                    </td>
                </tr>
                <tr className="PTHRow">
                    <td className="PTHRHead">
                        <Skeleton />
                    </td>
                    <td className="PTHRHead">
                        <Skeleton />
                    </td>
                    <td className="PTHRHead">
                        <Skeleton />
                    </td>
                    <td className="PTHRHead">
                        <Skeleton />
                    </td>
                    <td className="PTHRHead">
                        <Skeleton />
                    </td>
                    <td className="PTHRHead">
                        <Skeleton />
                    </td>
                    <td className="PTHRHead">
                        <Skeleton />
                    </td>
                </tr>
                <tr className="PTHRow">
                    <td className="PTHRHead">
                        <Skeleton />
                    </td>
                    <td className="PTHRHead">
                        <Skeleton />
                    </td>
                    <td className="PTHRHead">
                        <Skeleton />
                    </td>
                    <td className="PTHRHead">
                        <Skeleton />
                    </td>
                    <td className="PTHRHead">
                        <Skeleton />
                    </td>
                    <td className="PTHRHead">
                        <Skeleton />
                    </td>
                    <td className="PTHRHead">
                        <Skeleton />
                    </td>
                </tr>
                <tr className="PTHRow">
                    <td className="PTHRHead">
                        <Skeleton />
                    </td>
                    <td className="PTHRHead">
                        <Skeleton />
                    </td>
                    <td className="PTHRHead">
                        <Skeleton />
                    </td>
                    <td className="PTHRHead">
                        <Skeleton />
                    </td>
                    <td className="PTHRHead">
                        <Skeleton />
                    </td>
                    <td className="PTHRHead">
                        <Skeleton />
                    </td>
                    <td className="PTHRHead">
                        <Skeleton />
                    </td>
                </tr>
                <tr className="PTHRow">
                    <td className="PTHRHead">
                        <Skeleton />
                    </td>
                    <td className="PTHRHead">
                        <Skeleton />
                    </td>
                    <td className="PTHRHead">
                        <Skeleton />
                    </td>
                    <td className="PTHRHead">
                        <Skeleton />
                    </td>
                    <td className="PTHRHead">
                        <Skeleton />
                    </td>
                    <td className="PTHRHead">
                        <Skeleton />
                    </td>
                    <td className="PTHRHead">
                        <Skeleton />
                    </td>
                </tr>
                <tr className="PTHRow">
                    <td className="PTHRHead">
                        <Skeleton />
                    </td>
                    <td className="PTHRHead">
                        <Skeleton />
                    </td>
                    <td className="PTHRHead">
                        <Skeleton />
                    </td>
                    <td className="PTHRHead">
                        <Skeleton />
                    </td>
                    <td className="PTHRHead">
                        <Skeleton />
                    </td>
                    <td className="PTHRHead">
                        <Skeleton />
                    </td>
                    <td className="PTHRHead">
                        <Skeleton />
                    </td>
                </tr>
                <tr className="PTHRow">
                    <td className="PTHRHead">
                        <Skeleton />
                    </td>
                    <td className="PTHRHead">
                        <Skeleton />
                    </td>
                    <td className="PTHRHead">
                        <Skeleton />
                    </td>
                    <td className="PTHRHead">
                        <Skeleton />
                    </td>
                    <td className="PTHRHead">
                        <Skeleton />
                    </td>
                    <td className="PTHRHead">
                        <Skeleton />
                    </td>
                    <td className="PTHRHead">
                        <Skeleton />
                    </td>
                </tr>
            </tbody>
        </table>
    );
};

export default TableSkeleton;
