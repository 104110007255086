import React from 'react';

import { SVGIcon } from '../../UIKit/index';

export default function DCGraySubscription(props) {
    return (
        <SVGIcon {...props} viewBox="0 0 476.75 500.66">
            <g id="Subscribe_" data-name="Subscribe " transform="translate(-16 -5.96)">
                <g id="Layer_1" data-name="Layer 1">
                    <circle
                        id="Ellipse_9"
                        data-name="Ellipse 9"
                        cx="230.03"
                        cy="230.03"
                        r="230.03"
                        transform="translate(24.34 7.46)"
                        fill="#fff"
                        stroke="#707171"
                        strokeMiterlimit="10"
                        strokeWidth="3"
                    />
                    <ellipse
                        id="_Path_"
                        data-name="&lt;Path&gt;"
                        cx="142.38"
                        cy="13.98"
                        rx="142.38"
                        ry="13.98"
                        transform="translate(112.26 478.66)"
                        fill="#f4f5f6"
                    />
                    <rect
                        id="Rectangle_89"
                        data-name="Rectangle 89"
                        width="89.78"
                        height="22.43"
                        rx="6.53"
                        transform="translate(67 204.46)"
                        fill="#e3e2e3"
                    />
                    <rect
                        id="Rectangle_90"
                        data-name="Rectangle 90"
                        width="34.2"
                        height="22.43"
                        rx="6.53"
                        transform="translate(100.71 188.7)"
                        fill="#e3e2e3"
                    />
                    <rect
                        id="Rectangle_91"
                        data-name="Rectangle 91"
                        width="318.82"
                        height="18.66"
                        rx="5.43"
                        transform="translate(95.04 383.67)"
                        fill="#e3e2e3"
                    />
                    <path
                        id="Path_120"
                        data-name="Path 120"
                        d="M131.53,232.11H373.88v139.8a16.48,16.48,0,0,1-16.48,16.48H148a16.48,16.48,0,0,1-16.48-16.48V232.11Z"
                        fill="#e3e2e3"
                    />
                    <path
                        id="Path_121"
                        data-name="Path 121"
                        d="M373.88,232.11H131.53L240.17,117.37a17.25,17.25,0,0,1,25.07,0Z"
                        fill="#707171"
                    />
                    <rect
                        id="Rectangle_92"
                        data-name="Rectangle 92"
                        width="206.52"
                        height="151.35"
                        rx="6.83"
                        transform="translate(139.07 139.67)"
                        fill="#f4f5f6"
                    />
                    <rect
                        id="Rectangle_93"
                        data-name="Rectangle 93"
                        width="95.04"
                        height="31.26"
                        rx="9.09"
                        transform="translate(198.48 156.13) rotate(180)"
                        fill="#e3e2e3"
                    />
                    <rect
                        id="Rectangle_94"
                        data-name="Rectangle 94"
                        width="47.65"
                        height="31.26"
                        rx="9.09"
                        transform="translate(181.55 134.18) rotate(180)"
                        fill="#e3e2e3"
                    />
                    <rect
                        id="Rectangle_95"
                        data-name="Rectangle 95"
                        width="206.52"
                        height="151.35"
                        rx="6.83"
                        transform="translate(163.71 143.73)"
                        fill="#707171"
                        opacity="0.2"
                    />
                    <rect
                        id="Rectangle_96"
                        data-name="Rectangle 96"
                        width="206.52"
                        height="151.35"
                        rx="6.83"
                        transform="translate(158.4 149.57)"
                        fill="#f7f7f7"
                    />
                    <rect
                        id="Rectangle_97"
                        data-name="Rectangle 97"
                        width="206.52"
                        height="151.35"
                        rx="6.83"
                        transform="translate(149.44 170.31)"
                        fill="#707171"
                        opacity="0.2"
                    />
                    <rect
                        id="Rectangle_98"
                        data-name="Rectangle 98"
                        width="206.52"
                        height="151.35"
                        rx="6.83"
                        transform="translate(144.49 176.68)"
                        fill="#fff"
                    />
                    <rect
                        id="Rectangle_99"
                        data-name="Rectangle 99"
                        width="151.59"
                        height="4.24"
                        rx="1.23"
                        transform="translate(171.96 241.27)"
                        fill="#b5bbc1"
                    />
                    <rect
                        id="Rectangle_100"
                        data-name="Rectangle 100"
                        width="151.59"
                        height="4.24"
                        rx="1.23"
                        transform="translate(171.96 255.42)"
                        fill="#b5bbc1"
                    />
                    <rect
                        id="Rectangle_101"
                        data-name="Rectangle 101"
                        width="151.59"
                        height="4.24"
                        rx="1.23"
                        transform="translate(171.96 269.56)"
                        fill="#b5bbc1"
                    />
                    <rect
                        id="Rectangle_102"
                        data-name="Rectangle 102"
                        width="151.59"
                        height="4.24"
                        rx="1.23"
                        transform="translate(171.96 283.71)"
                        fill="#b5bbc1"
                    />
                    <rect
                        id="Rectangle_103"
                        data-name="Rectangle 103"
                        width="151.59"
                        height="4.24"
                        rx="1.23"
                        transform="translate(171.96 297.85)"
                        fill="#b5bbc1"
                    />
                    <path
                        id="Path_122"
                        data-name="Path 122"
                        d="M373.88,232.11v133a23.31,23.31,0,0,1-23.31,23.31H154.83a23.31,23.31,0,0,1-23.31-23.31v-133L252.7,310.52Z"
                        fill="#707171"
                    />
                    <path
                        id="Path_123"
                        data-name="Path 123"
                        d="M340.87,253.47v78.61a23.29,23.29,0,0,1-23.3,23.29h-186v9.71a23.31,23.31,0,0,0,23.31,23.31H350.57a23.31,23.31,0,0,0,23.31-23.31v-133Z"
                        opacity="0.2"
                    />
                    <rect
                        id="Rectangle_104"
                        data-name="Rectangle 104"
                        width="14.85"
                        height="29.25"
                        rx="4.32"
                        transform="translate(145.91 312.19)"
                        fill="#f4f5f6"
                    />
                    <rect
                        id="Rectangle_105"
                        data-name="Rectangle 105"
                        width="14.85"
                        height="16.8"
                        rx="4.32"
                        transform="translate(145.91 290.06)"
                        fill="#f4f5f6"
                    />
                    <text
                        id="_"
                        data-name="@"
                        transform="translate(170.19 224.5)"
                        fill="#b5bbc1"
                        fontSize="36.84"
                        fontFamily="ScriptMTBold, Script MT"
                    >
                        <tspan x="0" y="0">
                            @
                        </tspan>
                    </text>
                    <rect
                        id="Rectangle_106"
                        data-name="Rectangle 106"
                        width="74.03"
                        height="4.24"
                        rx="1.23"
                        transform="translate(211.09 211.04)"
                        fill="#b5bbc1"
                    />
                    <rect
                        id="Rectangle_107"
                        data-name="Rectangle 107"
                        width="111.17"
                        height="4.24"
                        rx="1.23"
                        transform="translate(211.09 225.18)"
                        fill="#b5bbc1"
                    />
                    <circle
                        id="Ellipse_10"
                        data-name="Ellipse 10"
                        cx="37.72"
                        cy="37.72"
                        r="37.72"
                        transform="translate(323.602 321.901) rotate(-45)"
                        fill="#707171"
                        opacity="0.3"
                    />
                    <circle
                        id="Ellipse_11"
                        data-name="Ellipse 11"
                        cx="37.72"
                        cy="37.72"
                        r="37.72"
                        transform="translate(318.648 315.766) rotate(-45)"
                        fill="#707171"
                    />
                    <path
                        id="Path_124"
                        data-name="Path 124"
                        d="M409.71,315.77a37.7,37.7,0,0,1-68.8,21.34,37.71,37.71,0,0,0,52.42-52.42,37.61,37.61,0,0,1,16.38,31.08Z"
                        opacity="0.2"
                    />
                    <path
                        id="Path_125"
                        data-name="Path 125"
                        d="M386.37,303.15a9.89,9.89,0,0,0-12.72-1.06,10.373,10.373,0,0,0-1.24,1.06l-.42.44-.42-.44a10.365,10.365,0,0,0-1.24-1.06,9.87,9.87,0,0,0-13.76,13.77l2.28,2.28L372,331.28l13.15-13.14,2.27-2.28a9.84,9.84,0,0,0-1-12.71Z"
                        fill="#f4f5f6"
                    />
                    <path
                        id="Path_126"
                        data-name="Path 126"
                        d="M342.73,318h-.06a3.54,3.54,0,0,1-3.48-3.58c.14-10.2,6.59-29.58,30-29.89h.05a3.54,3.54,0,0,1,.05,7.07c-22.34.3-23,22-23,22.92a3.54,3.54,0,0,1-3.56,3.48Z"
                        fill="#050100"
                        opacity="0.2"
                    />
                    <rect
                        id="Rectangle_108"
                        data-name="Rectangle 108"
                        width="85.47"
                        height="28.11"
                        rx="8.18"
                        transform="translate(105.98 333.22) rotate(180)"
                        fill="#f4f5f6"
                    />
                    <rect
                        id="Rectangle_109"
                        data-name="Rectangle 109"
                        width="42.85"
                        height="28.11"
                        rx="8.18"
                        transform="translate(79.97 313.47) rotate(180)"
                        fill="#ceeded"
                    />
                    <rect
                        id="Rectangle_110"
                        data-name="Rectangle 110"
                        width="85.47"
                        height="28.11"
                        rx="8.18"
                        transform="translate(101.47 329.21) rotate(180)"
                        fill="#e3e2e3"
                    />
                    <rect
                        id="Rectangle_111"
                        data-name="Rectangle 111"
                        width="42.85"
                        height="28.11"
                        rx="8.18"
                        transform="translate(75.45 309.46) rotate(180)"
                        fill="#e3e2e3"
                    />
                    <path
                        id="Path_127"
                        data-name="Path 127"
                        d="M386.4,159.29,413,134.41l-14.98-4.16Z"
                        opacity="0.4"
                    />
                    <path
                        id="Path_128"
                        data-name="Path 128"
                        d="M386.57,122.78l-.17,36.51,12.42-28.4,73.91-58.23-86.79,47.97Z"
                        fill="#707171"
                    />
                    <path
                        id="Path_129"
                        data-name="Path 129"
                        d="M365,112.51l21.9,10,85.88-49.9L365.08,109.9a1.41,1.41,0,0,0-.08,2.61Z"
                        fill="#e3e2e3"
                    />
                    <path
                        id="Path_130"
                        data-name="Path 130"
                        d="M472.73,72.66l-73.91,58.23,28.36,13.73a2,2,0,0,0,2.58-.77Z"
                        fill="#e3e2e3"
                    />
                    <path
                        id="Path_131"
                        data-name="Path 131"
                        d="M89.87,201.79a6.55,6.55,0,1,1-1.915-4.638A6.56,6.56,0,0,1,89.87,201.79Z"
                        fill="#f4f5f6"
                    />
                    <path
                        id="Path_132"
                        data-name="Path 132"
                        d="M220.12,113a6.55,6.55,0,1,1-1.915-4.638A6.56,6.56,0,0,1,220.12,113Z"
                        fill="#fff"
                        opacity="0.5"
                    />
                    <path
                        id="Path_133"
                        data-name="Path 133"
                        d="M116.39,349.72a6.55,6.55,0,1,1-1.915-4.638,6.56,6.56,0,0,1,1.915,4.638Z"
                        fill="#fff"
                        opacity="0.5"
                    />
                    <circle
                        id="Ellipse_12"
                        data-name="Ellipse 12"
                        cx="12.76"
                        cy="12.76"
                        r="12.76"
                        transform="translate(239.94 350.96)"
                        fill="#f4f5f6"
                    />
                    <circle
                        id="Ellipse_13"
                        data-name="Ellipse 13"
                        cx="10.29"
                        cy="10.29"
                        r="10.29"
                        transform="translate(416.488 289.975) rotate(-45)"
                        fill="#f4f5f6"
                    />
                    <path
                        id="Path_134"
                        data-name="Path 134"
                        d="M438.42,315.91a7.15,7.15,0,1,1-7.15-7.15,7.15,7.15,0,0,1,7.15,7.15Z"
                        fill="#fff"
                        opacity="0.5"
                    />
                    <rect
                        id="Rectangle_112"
                        data-name="Rectangle 112"
                        width="102.52"
                        height="33.72"
                        rx="9.81"
                        transform="translate(390.23 227.6)"
                        fill="#f4f5f6"
                    />
                    <rect
                        id="Rectangle_113"
                        data-name="Rectangle 113"
                        width="51.4"
                        height="33.72"
                        rx="9.81"
                        transform="translate(408.49 203.92)"
                        fill="#f4f5f6"
                    />
                    <rect
                        id="Rectangle_114"
                        data-name="Rectangle 114"
                        width="102.52"
                        height="33.72"
                        rx="9.81"
                        transform="translate(382.6 221.58)"
                        fill="#e3e2e3"
                    />
                    <rect
                        id="Rectangle_115"
                        data-name="Rectangle 115"
                        width="51.4"
                        height="33.72"
                        rx="9.81"
                        transform="translate(400.86 197.9)"
                        fill="#e3e2e3"
                    />
                    <path
                        id="Path_135"
                        data-name="Path 135"
                        d="M408.49,220.77s-5.5-15.09,10.77-17.57"
                        fill="none"
                        stroke="#fff"
                        strokeLinecap="round"
                        strokeMiterlimit="10"
                        strokeWidth="4"
                    />
                    <path
                        id="Path_136"
                        data-name="Path 136"
                        d="M141.86,126.71s-5.5-15.1,10.77-17.57"
                        fill="none"
                        stroke="#fff"
                        strokeLinecap="round"
                        strokeMiterlimit="10"
                        strokeWidth="4"
                    />
                    <path
                        id="Path_137"
                        data-name="Path 137"
                        d="M41.9,303.52S36.4,288.42,52.67,286"
                        fill="none"
                        stroke="#fff"
                        strokeLinecap="round"
                        strokeMiterlimit="10"
                        strokeWidth="4"
                    />
                    <path
                        id="Path_138"
                        data-name="Path 138"
                        d="M136.24,246.45,252.7,324.26l110.8-71.91"
                        fill="none"
                        stroke="#e3e2e3"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                    />
                    <path
                        id="Path_139"
                        data-name="Path 139"
                        d="M136.74,186c-1.11.74-2.21,1.49-3.31,2.24"
                        fill="none"
                        stroke="#707171"
                        strokeMiterlimit="10"
                        strokeWidth="1"
                    />
                    <path
                        id="Path_140"
                        data-name="Path 140"
                        d="M126.87,192.79c-9.51,6.82-18.49,14.36-24.79,24.12-8.46,13.11-10.92,31.4-1.66,44,7.91,10.73,22,14.71,35.3,16.23,30.73,3.52,62-2.52,90.84-13.81s55.33-27.69,80.71-45.38c11.68-8.14,23.29-16.65,33.09-27.06,11-11.66,16-26.56,22.49-41.05,2.19-4.87,4.15-11.24.36-15-2.07-2-5.28-2.5-8.16-2.08A16.59,16.59,0,0,0,344.4,159.4c4.91,6.26,13.9,8.2,21.68,6.56s14.61-6.24,20.86-11.17a179,179,0,0,0,35.55-37.51"
                        fill="none"
                        stroke="#707171"
                        strokeMiterlimit="10"
                        strokeWidth="1"
                        strokeDasharray="7.99 7.99"
                    />
                    <path
                        id="Path_141"
                        data-name="Path 141"
                        d="M424.76,114c.74-1.11,1.47-2.22,2.19-3.34"
                        fill="none"
                        stroke="#707171"
                        strokeMiterlimit="10"
                        strokeWidth="1"
                    />
                    <line
                        id="Line_22"
                        data-name="Line 22"
                        y1="20.13"
                        x2="52.14"
                        transform="translate(380.6 90.42)"
                        fill="none"
                        stroke="#707171"
                        strokeLinecap="round"
                        strokeMiterlimit="10"
                        strokeWidth="2"
                        opacity="0.5"
                    />
                </g>
            </g>
        </SVGIcon>
    );
}
