import { CallAPI } from 'Utils/apiCall';
import config from 'config';
const baseUrl = `${config.REACT_APP_API_BASE_URL}/${process.env.REACT_APP_API_VERSION}`;

const api = new CallAPI();

export const getPartnerLeaders = async type => {
    const res = await api.request('GET', `${baseUrl}/affiliates/leaderboard?type=${type}`);
    return res.data;
};

export const getAffiliateStats = async () => {
    const res = await api.request('GET', `${baseUrl}/affiliates/stats`);
    return res.data;
};

export const getAffiliateRecord = async (page, limit) => {
    const res = await api.request(
        'GET',
        `${baseUrl}/affiliates/referrals?page=${page}&limit=${limit}`,
    );

    return res;
};

export const getIframe = async type => {
    const res = await api.request('GET', `${baseUrl}/e/tipalti/iframe`, null, null, null, {
        type: type.name,
    });

    return res;
};
