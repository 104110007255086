import React from 'react';

import { SVGIcon } from '../../UIKit/index';

export default function DCLoginCredential(props) {
    return (
        <SVGIcon {...props} viewBox="0 0 512 512">
            <g transform="translate(0.2 0.199)">
                <g transform="translate(0 0.001)">
                    <g transform="translate(0 0)">
                        <circle cx="333.04" cy="352.27" r="19.4" />
                        <path d="M237.5,470.81H79.83c-22.03-0.02-39.89-17.88-39.91-39.91V267.26     c0.02-22.03,17.88-39.89,39.91-39.91h287.39c22.03,0.02,39.89,17.88,39.91,39.91v39.91c-0.01,11.02,8.92,19.96,19.94,19.97     c11.02,0.01,19.96-8.92,19.97-19.94c0-0.01,0-0.02,0-0.03v-39.91c-0.05-44.07-35.76-79.78-79.82-79.82h-23.99v-70.39     C342.47,51.64,288.9-0.81,223.49-0.19C158.04-0.87,104.41,51.6,103.65,117.05v70.36H79.83c-44.07,0.05-79.79,35.77-79.82,79.85     V430.9c0.05,44.07,35.76,79.78,79.82,79.82H237.5c11.02-0.01,19.95-8.95,19.94-19.97C257.43,479.74,248.51,470.82,237.5,470.81z      M143.67,117.05c0.76-43.36,36.46-77.93,79.82-77.29c43.36-0.64,79.06,33.93,79.82,77.29v70.36H143.67V117.05z" />
                        <path d="M502.57,324.86c-8.87-6.43-21.28-4.45-27.72,4.42c-0.05,0.06-0.09,0.13-0.13,0.19     l-98.37,137.4c-4.12,4.76-11.32,5.27-16.08,1.15c-0.11-0.1-0.22-0.19-0.32-0.29l-63.57-62.03c-7.92-7.7-20.57-7.52-28.27,0.4     c-7.7,7.92-7.52,20.57,0.4,28.27l0,0l63.68,62.14l0.18,0.18c9.55,9.07,22.22,14.13,35.39,14.13c1.13,0,2.26-0.04,3.4-0.11     c14.32-0.97,27.58-7.88,36.58-19.05l0.66-0.88l98.77-137.97c6.47-8.85,4.54-21.26-4.3-27.73     C502.76,324.99,502.66,324.92,502.57,324.86z" />
                        <circle cx="119.66" cy="352.27" r="19.4" />
                        <circle cx="177.85" cy="352.27" r="19.4" />
                        <circle cx="270.85" cy="351.27" r="19.4" />
                    </g>
                </g>
            </g>
        </SVGIcon>
    );
}
