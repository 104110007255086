import React, { useEffect, useState, useContext, useRef, Suspense, useCallback } from 'react';
import _ from 'lodash';
import { useQueryClient } from 'react-query';
import ErrorDialog from 'Hooks/ErrorDialog';
import usePubSub from 'Hooks/PubSub';
import Toasts from 'Modules/Toasts/Toasts';
import useToast from 'Modules/Toasts/useToast';
import ErrorBoundary from './ErrorBoundary';
import Routes from 'Routes';
import { getAccountType, getLocalStorageAuthenticateObject, isAuthenticated } from 'Utils/auth';
import { setTitle } from 'Utils';
import 'Assets/styles/style.scss';
import { updateCart } from 'apis/store/index.api';
import { themeContext } from 'Contexts/Context';
import generateImage from 'Utils/generateImage';
import ScheduleDemo from 'Components/ScheduleDemo';
import useSocketSetup from 'Modules/SocketSetup/useSocketSetup';
import useFCMSetup from 'Modules/FCMSetup/useFCMSetup';
import { useIntercom } from 'react-use-intercom';
import ReactPixel from 'react-facebook-pixel';
import { Ripple } from 'react-preloaders2';
import DetectAdBlock from 'Utils/Adblocker';
import log from 'Utils/logger';
import useConversation from 'Hooks/useConversation';
import { getCannyAttributeConfigs, getIntercomAttributeConfigs } from 'Hooks/attributeConfigs';
import { getUserDetails, isLocalStorageAvailable } from 'Utils/localStorageUtils';
const getFaviconEl = () => {
    return document.getElementById('favicon');
};

const App = () => {
    const { setPrimaryColor, setSecondaryColor, setTextColor } = useContext(themeContext);
    const [showScheduleDialog, setShowScheduleDialog] = useState(false);
    const socketSetup = useSocketSetup();
    const fcmSetup = useFCMSetup();
    const queryClient = useQueryClient();
    const { showToast } = useToast();
    const { boot } = useIntercom();
    useConversation();

    usePubSub('login-success', () => {
        socketSetup();
        isLocalStorageAvailable() && fcmSetup();
        const authObj = getLocalStorageAuthenticateObject();
        if (authObj) {
            const colors = authObj.auth?.account?.branding?.colors;
            if (colors) {
                setPrimaryColor(colors.primary);
                setSecondaryColor(colors.secondary);
                setTextColor(colors.font_primary);
            }
            setTitle(authObj.auth?.account.name);
        }
    });
    usePubSub(
        'subscription_downgrade',
        useCallback(
            newData => {
                try {
                    if (newData) {
                        showToast({
                            type: newData?.type,
                            message: newData?.message,
                            noDuplicate: true,
                            duration: 3000,
                        });
                    }
                } catch (err) {
                    log.error(err || 'Failed to apply socket data');
                }
            },
            [showToast],
        ),
    );

    useEffect(() => {
        if (isAuthenticated() && getAccountType() === 'main') {
            ReactPixel.init('2008005809310841');
            ReactPixel.pageView();
        }
    }, []);

    useEffect(() => {
        if (isAuthenticated() && getAccountType() === 'main' && getUserDetails().intercom_hash) {
            boot({
                customAttributes: getIntercomAttributeConfigs(),
            });
        }
    });

    usePubSub('update-cart', async _data => {
        const { business, price, qty, subscription } = _data;
        const res = await updateCart({ business, price, qty, subscription });
        if (res.success) {
            if (_data.increment) {
                showToast({
                    type: 'success',
                    message: 'Product Added Successfully',
                });
            } else {
                showToast({
                    type: 'success',
                    message: 'Product Removed Successfully',
                });
            }
            queryClient.setQueryData('cart', {
                all: res.data.cart,
                cart: _.groupBy(_.sortBy(res.data.cart, ['buyer.name', 'item.name']), 'buyer.id'),
                totals: res.data.totals,
            });
        }
    });

    useEffect(() => {
        const authObj = getLocalStorageAuthenticateObject();
        if (authObj) {
            const authenticated = authObj.auth?.authenticated;
            if (authenticated) {
                const colors = authObj.auth?.account?.branding?.colors;
                if (colors) {
                    document.documentElement.style.setProperty('--primary-main', colors.primary);
                    document.documentElement.style.setProperty('--dark-blue', colors.secondary);
                    document.documentElement.style.setProperty(
                        '--blue-invert',
                        colors.font_primary,
                    );
                    setPrimaryColor(colors.primary);
                    setSecondaryColor(colors.secondary);
                    setTextColor(colors.font_primary);
                }
                const favicon = getFaviconEl();

                favicon.setAttribute(
                    'href',
                    authObj.auth?.account?.branding?.images?.favicon?.key
                        ? generateImage(authObj.auth?.account?.branding.images.favicon)
                        : 'https://s3.wasabisys.com/open/general/branding/dc_dashboard.ico',
                );
                setTitle(authObj.auth?.account?.name);
                socketSetup();
                fcmSetup();
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    usePubSub('open-schedule', () => {
        setShowScheduleDialog(true);
    });

    useEffect(() => {
        if (isAuthenticated() && getAccountType() === 'main') {
            if (typeof window !== 'undefined' && window.Canny) {
                window.Canny('initChangelog', getCannyAttributeConfigs());
            }
        }
    }, []);

    const btnRef = useRef();

    usePubSub('open-canny', () => {
        btnRef.current.click();
    });

    const firstTimeRef = useRef({ current: false, count: 1 });

    return (
        <ErrorBoundary>
            <DetectAdBlock />
            <Suspense fallback={<Ripple />}>
                <Routes />
                <Toasts />
                <ErrorDialog />
                {isLocalStorageAvailable() && isAuthenticated() && getAccountType() === 'main' && (
                    <>
                        {showScheduleDialog && (
                            <ScheduleDemo
                                open={showScheduleDialog}
                                handleClose={() => {
                                    setShowScheduleDialog(false);
                                }}
                                firstTimeRef={firstTimeRef}
                            />
                        )}
                        <button
                            style={{
                                bottom: '10px',
                                right: '10px',
                                position: 'absolute',
                                zIndex: '-1000',
                                backgroundColor: 'transparent',
                                borderColor: 'transparent',
                            }}
                            id="test"
                            data-canny-changelog
                            ref={btnRef}
                        />
                    </>
                )}
            </Suspense>
        </ErrorBoundary>
    );
};

export default App;
