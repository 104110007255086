import React from 'react';

import { SVGIcon } from '../../UIKit/index';

export default function DCSocialMediaPage(props) {
    return (
        <SVGIcon {...props} viewBox="0 0 512 512">
            <path d="M511.2,432.66V0H0.19v432.66h196.3v48.41h-61.58v29.94h241.57v-29.94h-61.55v-48.41H511.2z   M481.26,29.94v312.9H30.13V29.94H481.26z M30.13,402.72v-29.94h451.13v29.94H30.13z M284.96,481.07h-58.53v-48.41h58.53V481.07z" />
            <g transform="translate(5.779 1.942)">
                <g transform="translate(0 0.456)">
                    <g>
                        <path d="M359.3,154.62l-94.05-94.05c-9.77-9.71-25.57-9.66-35.28,0.12     c-8.38,8.43-9.64,21.6-3.02,31.47l-0.14,0.65c-6.73,33.8-23.34,64.85-47.73,89.19l-43.11,43.23c-9.74,9.73-9.74,25.51-0.01,35.25     c0,0,0.01,0.01,0.01,0.01L159.47,284c9.73,9.74,25.51,9.74,35.25,0.01c0,0,0.01-0.01,0.01-0.01l5.88-5.88l41.14,41.14     c10.07,9.41,25.85,8.88,35.26-1.19c8.96-9.59,8.96-24.48,0-34.07l-17.63-17.63l5.88-5.88c9.74-9.73,9.74-25.51,0.01-35.25     c0,0-0.01-0.01-0.01-0.01l-4-4c19.75-13.83,42.16-23.4,65.8-28.11l0.67-0.14c9.9,6.66,23.12,5.38,31.57-3.04l0,0     c9.76-9.75,9.76-25.56,0.02-35.31C359.31,154.63,359.3,154.63,359.3,154.62z M182.98,272.18c-3.25,3.24-8.51,3.24-11.75,0     l-23.51-23.51c-3.24-3.25-3.24-8.51,0-11.75l41.14-41.14l35.28,35.26L182.98,272.18z M265.25,295.68     c3.36,3.14,3.53,8.4,0.4,11.75s-8.4,3.53-11.75,0.4c-0.14-0.13-0.27-0.26-0.4-0.4l-41.14-41.14l11.75-11.75L265.25,295.68z      M235.86,242.79c2.51-2.51,6.25-6.32,12.15-11.36l5.48,5.48c3.24,3.25,3.24,8.51,0,11.75l-5.88,5.88L235.86,242.79z      M236.21,219.57l-35.96-35.91c19.26-22.22,33.1-48.6,40.46-77.07l72.61,72.56c-28.48,7.36-54.87,21.21-77.09,40.49L236.21,219.57     z M347.52,178.08c-3.25,3.24-8.51,3.24-11.75,0l-94.05-94.05c-3.14-3.36-2.96-8.62,0.4-11.75c3.2-2.99,8.16-2.99,11.36,0     l94.05,94.05c3.26,3.23,3.29,8.5,0.06,11.76c-0.01,0.01-0.03,0.03-0.04,0.04L347.52,178.08z" />
                    </g>
                </g>
                <g transform="translate(1.728 7.75)">
                    <g>
                        <path d="M193,217.87c-3.25-3.24-8.51-3.24-11.75,0l-11.75,11.75c-3.14,3.35-2.96,8.62,0.39,11.75     c3.2,2.99,8.16,2.99,11.36,0L193,229.62C196.24,226.38,196.24,221.12,193,217.87z" />
                    </g>
                </g>
                <g transform="translate(7.167)">
                    <g>
                        <path d="M296.27,43.17c-4.59,0-8.32,3.72-8.32,8.32c0,0,0,0,0,0v16.63c0,4.59,3.72,8.32,8.32,8.32     c4.59,0,8.32-3.72,8.32-8.32V51.48C304.58,46.89,300.86,43.17,296.27,43.17C296.27,43.17,296.27,43.17,296.27,43.17z" />
                    </g>
                </g>
                <g transform="translate(9.314 2.863)">
                    <g>
                        <path d="M360.62,106.8h-16.63c-4.59,0-8.32,3.72-8.32,8.32s3.72,8.32,8.32,8.32h16.63     c4.59,0,8.32-3.72,8.32-8.32S365.21,106.8,360.62,106.8z" />
                    </g>
                </g>
                <g transform="translate(8.599 0.716)">
                    <g>
                        <path d="M350.49,61.61c-3.25-3.24-8.51-3.24-11.75,0L322.1,78.24c-3.35,3.14-3.53,8.4-0.39,11.75     c3.14,3.35,8.4,3.53,11.75,0.39c0.14-0.13,0.27-0.26,0.39-0.39l16.63-16.63C353.73,70.12,353.73,64.86,350.49,61.61z" />
                    </g>
                </g>
            </g>
        </SVGIcon>
    );
}
