import React from 'react';

import { SVGIcon } from '../../UIKit/index';

export default function DCGrowChart1(props) {
    return (
        <SVGIcon {...props} viewBox="0 0 512 512">
            <path d="M75.9,240.3c11.7,0,22.8-4.5,31.2-12.6l44.1,22.1c-3,24.6,14.6,47,39.3,49.9  c24.6,2.9,47-14.6,50-39.2c1-8.4-0.4-16.9-4-24.5l59.9-59.9c22.4,10.8,49.2,1.4,60-21c5-10.3,5.8-22.1,2.3-33.1L410.9,83  c20.7,13.7,48.6,8.1,62.3-12.6c13.7-20.7,8.1-48.5-12.6-62.2C440-5.5,412.1,0.1,398.4,20.8c-7.5,11.3-9.5,25.3-5.4,38.3L341,98  c-20.6-13.8-48.5-8.4-62.4,12.2c-8.9,13.3-10.1,30.2-3.2,44.6l-59.9,59.9c-16.9-8.1-37.1-4.8-50.6,8.2l-44.3-22  c3-24.6-14.5-47-39.1-50c-24.6-3-47.1,14.5-50.1,39.1c-3,24.6,14.5,47,39.1,50C72.3,240.2,74.1,240.3,75.9,240.3z M436.1,30.4  c8.3,0,15,6.7,15,15c0,8.3-6.7,15-15,15c-8.3,0-15-6.7-15-15S427.8,30.4,436.1,30.4L436.1,30.4z M316,120.4c8.3,0,15,6.7,15,15  c0,8.3-6.7,15-15,15c-8.3,0-15-6.7-15-15C301,127.1,307.8,120.4,316,120.4L316,120.4z M196,240.3c8.3,0,15,6.7,15,15  c0,8.3-6.7,15-15,15c-8.3,0-15-6.7-15-15S187.7,240.3,196,240.3C196,240.3,196,240.3,196,240.3z M75.9,180.4c8.3,0,15,6.7,15,15  c0,8.3-6.7,15-15,15c-8.3,0-15-6.7-15-15S67.6,180.4,75.9,180.4L75.9,180.4z" />
            <path d="M497.1,482.1h-16V165.4c0-8.3-6.7-15-15-15h-59.9c-8.3,0-15,6.7-15,15v316.7h-30V255.3  c0-8.3-6.7-15-15-15h-59.9c-8.3,0-15,6.7-15,15v226.8h-30V375.2c0-8.3-6.7-15-15-15h-59.9c-8.3,0-15,6.7-15,15v106.9h-30V315.2  c0-8.3-6.7-15-15-15H46c-8.3,0-15,6.7-15,15v166.9h-16c-8.3,0-15,6.7-14.9,15c0,8.2,6.7,14.9,14.9,14.9h482.1c8.3,0,15-6.7,14.9-15  C512,488.8,505.3,482.1,497.1,482.1L497.1,482.1z M421.1,180.3h30v301.7h-30V180.3z M301,270.2h30v211.8h-30V270.2z M181,390.2h30  v91.9h-30V390.2z M61,330.3h30v151.8H61V330.3z" />
        </SVGIcon>
    );
}
