import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import {
    Button,
    Dialog,
    Div,
    FieldControl,
    FooterBar,
    H1,
    IconButton,
    Label,
    LabelControl,
    TopBar,
} from 'UIKit';
import DCCrossClose from 'Assets/icons/DCCrossClose';
import OwnerAutocomplete from 'Components/UsersList/UserAutocomplete';
import { AutoComplete } from 'Components';
import { getTags } from 'apis/contacts/index.api';
import useToast from 'Modules/Toasts';
import DCTag3 from 'Assets/icons/DCTag3';

function BulkDialog({ open, onClose, title, label, fieldType, acceptButton, loading }) {
    const { showToast } = useToast();
    const [user, setUser] = useState(null);
    const [tags, setTags] = useState([]);
    const [loadingTags, setLoadingTags] = useState(false);
    const [selectedTag, setSelectedTag] = useState(null);

    const getAllTags = useCallback(() => {
        setLoadingTags(true);
        getTags()
            .then(res => {
                if (res.data) setTags(res.data);
            })
            .catch(err => {
                showToast({
                    type: 'error',
                    message: err.additional_info || 'Something went wrong',
                    duration: 2500,
                });
            })
            .finally(() => {
                setLoadingTags(false);
            });
    }, [showToast]);

    useEffect(() => {
        if (fieldType === 'tags') getAllTags();
    }, [fieldType, getAllTags]);

    const generateTagsOptions = useCallback(() => {
        return tags.map((item, index) => {
            return {
                id: index,
                label: (
                    <Button
                        buttonClass={'ACSingleOption'}
                        iconName={<DCTag3 />}
                        onClick={() => {
                            setSelectedTag(item);
                        }}
                    >
                        {item.tag}
                    </Button>
                ),
                value: item.tag,
            };
        });
    }, [tags]);

    return (
        <Dialog dialogType={'centerZoom sizeXs'} open={open} onClose={onClose}>
            <TopBar DialogTopBar>
                <Div className={'HeaderBar'}>
                    <H1 className={'HeaderBarTitle'}>{title}</H1>
                    <Div className={'HeaderBarClose'}>
                        <IconButton onClick={onClose}>
                            <DCCrossClose />
                        </IconButton>
                    </Div>
                </Div>
            </TopBar>
            <Div className={'BodyBox'}>
                <Div className={'BodyBoxInner'}>
                    <FieldControl>
                        <LabelControl>
                            <Label props={{ htmlFor: 'user' }}>{label}</Label>
                        </LabelControl>
                        {fieldType === 'user' ? (
                            <OwnerAutocomplete
                                singleSelect={true}
                                placeholder={'Select User'}
                                onChange={val => setUser(val.id)}
                                customWidth={400}
                            />
                        ) : (
                            <AutoComplete
                                generateRows={generateTagsOptions}
                                singleSelection={true}
                                itemHeight={38}
                                loading={loadingTags}
                                value={selectedTag ? selectedTag.tag : 'Select Tag'}
                            />
                        )}
                    </FieldControl>
                </Div>
            </Div>

            <FooterBar DialogFooterBar>
                <Div className={'ButtonWrapper'}>
                    <Button buttonClass={'RedOutlineBtn'} onClick={onClose} disabled={loading}>
                        Cancel
                    </Button>
                    <Button
                        disabled={fieldType === 'user' ? !user : !selectedTag}
                        loading={loading}
                        buttonClass={'BlueFillBtn'}
                        onClick={() => acceptButton.onClick(fieldType === 'user' ? user : selectedTag.id)
                        }
                    >
                        {acceptButton.title}
                    </Button>
                </Div>
            </FooterBar>
        </Dialog>
    );
}

BulkDialog.propTypes = {
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    title: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    fieldType: PropTypes.string.isRequired,
    acceptButton: PropTypes.object.isRequired,
    loading: PropTypes.bool,
};

export default BulkDialog;
