import React, { useLayoutEffect, useRef, useCallback, useState } from 'react';
import { disableNewsHeadline } from 'apis/news-headline/index.api';
import DCCrossClose from 'Assets/icons/DCCrossClose';
import useToast from 'Modules/Toasts/useToast';
import { getUserDetails } from 'Utils/localStorageUtils';
import { Div } from 'UIKit';
import './style.scss';

const NewsHeadline = () => {
    const [animationName, setAnimationName] = useState('');
    const parentRef = useRef(null);
    const { showToast } = useToast();
    const data = getUserDetails();

    const handleDelete = async id => {
        try {
            await disableNewsHeadline(id);
            showToast({
                type: 'success',
                message: 'Successfully deleted',
            });
        } catch (err) {
            showToast({
                type: 'error',
                message: err.message || 'Something went wrong',
            });
        }
    };

    const addNewStyles = styles => {
        const newStyleSheet = document.createElement('style');
        document.head.appendChild(newStyleSheet);
        const styleSheet = newStyleSheet.sheet;
        styleSheet.insertRule(styles, 0);
    };

    const generateKeyframes = useCallback((low, high, child) => {
        let firstFrame = 80 / child + 1;
        const animationName = `animation${Math.round(Math.random() * 100)}`;

        let frame = firstFrame;

        let keyframes = `
        @-webkit-keyframes ${animationName} {
            0% {-webkit-transform:translateY(${high}px)}
            `;

        for (let i = 1; frame < 98; i++) {
            frame = firstFrame * i;
            if (frame > 98) break;
            keyframes = keyframes.concat(`${frame}% {-webkit-transform:translateY(${high}px)}
            `);
            high = high - 31; // max it can go - height of item
        }

        keyframes = keyframes.concat(
            `100% {-webkit-transform:translateY(${-low}px)}
            }`,
        );

        addNewStyles(keyframes);
        setAnimationName(animationName);
    }, []);

    useLayoutEffect(() => {
        if (parentRef.current) {
            const low = ((parentRef.current.children.length - 1) / 2) * -31;
            const high = ((parentRef.current.children.length - 1) / 2) * 31;

            generateKeyframes(low, high, parentRef.current.children.length);
        }
    }, [generateKeyframes, parentRef]);

    let style = {
        animationName: animationName,
        animationTimingFunction: 'cubic-bezier(0.77, 0.01, 0.25, 0.99)',
        animationDuration: `${parentRef?.current?.children.length * 2}s `,
        animationDelay: '0.0s',
        animationIterationCount: 'infinite',
        animationDirection: 'normal',
        animationFillMode: 'forwards',
    };

    localStorage.removeItem('backdropAdjustment');

    if (data?.announcements?.length < 1 || !data?.announcements) {
        return <></>;
    } else {
        localStorage.setItem(
            'backdropAdjustment',
            JSON.stringify({ top: '25', right: '0', bottom: '0', left: '0' }),
        );
    }

    return (
        <Div className="newsHeadline">
            <Div className="newsHeadlineContent" reference={parentRef} style={style}>
                {data?.announcements?.map(_item => {
                    return (
                        <Div key={_item.id} className={`newsHeadlineItem`}>
                            {_item.message}
                            <Div
                                className={`newsHeadlineItemCloseIcon `}
                                onClick={() => handleDelete(_item.id)}
                            >
                                <DCCrossClose />
                            </Div>
                        </Div>
                    );
                })}
            </Div>
        </Div>
    );
};

export default NewsHeadline;
