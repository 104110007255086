const mime = require('mime-types');
import config from 'config';

const generateFilePath = fileKey => {
    return `${config.REACT_APP_ASSETS_BASE_URL}/${fileKey}`;
};

const generatePublicFilePath = fileKey => {
    return `${config.REACT_APP_ASSETS_BASE_URL}/${fileKey}`;
};

const downloadFilesFromWasabi = async (key, type, fileName) => {
    let fileUrl = generateFilePath(key);
    let fileType = mime.extension(type);

    const a = document.createElement('a');
    a.style.display = 'none';
    document.body.appendChild(a);
    let blob = await fetch(fileUrl).then(r => r.blob());
    a.href = window.URL.createObjectURL(new Blob([blob], { fileType }));
    a.setAttribute('download', fileName);
    a.click();
    window.URL.revokeObjectURL(a.href);
    document.body.removeChild(a);
};

const downloadPublicFilesFromWasabi = async (fileUrl, type, fileName) => {
    let fileType;
    if (type === 'application/pdf') {
        fileType = 'application/pdf';
    } else {
        fileType = mime.extension(type);
    }
    const a = document.createElement('a');
    a.style.display = 'none';
    document.body.appendChild(a);
    let blob = await fetch(fileUrl).then(r => r.blob());
    a.href = window.URL.createObjectURL(new Blob([blob], { type: fileType }));
    a.setAttribute('download', fileName);
    a.click();
    window.URL.revokeObjectURL(a.href);
    document.body.removeChild(a);
};
export {
    downloadFilesFromWasabi,
    generateFilePath,
    generatePublicFilePath,
    downloadPublicFilesFromWasabi,
};
