import DCGrayReminder from 'Assets/icons/DCGrayReminder';
import dayjs from 'dayjs';
import React, { Fragment, useEffect, useState } from 'react';
import { Div, NoData } from 'UIKit';
import CardContainer from './cardContainer';
import { calenderConfig, getSegmentedReminders } from './helpers';

var calendar = require('dayjs/plugin/calendar');
dayjs.extend(calendar);
import './style.scss';

const PastDue = props => {
    const { reminders, handleClose } = props;
    const [segmentedReminders, setSegmentedReminders] = useState({});

    useEffect(() => {
        setSegmentedReminders(getSegmentedReminders(reminders));
    }, [reminders]);
    return (
        <Div className="UpcomingSection">
            {Object.keys(segmentedReminders).length > 0 ? (
                Object.keys(segmentedReminders)
                    .sort((a, b) => new Date(b) - new Date(a))
                    .map((date, index) => (
                        <Fragment key={index}>
                            <Div className="UBTitle">
                                {dayjs(date).calendar(null, calenderConfig)}
                            </Div>
                            <Div className="UpcomingBody">
                                <CardContainer
                                    type="pastdue"
                                    data={segmentedReminders[date]}
                                    origin={'pastDue'}
                                    handleClose={handleClose}
                                />
                            </Div>
                        </Fragment>
                    ))
            ) : (
                <NoData
                    icon={<DCGrayReminder />}
                    title={'No Past Due Reminders'}
                    description={'There are no past due reminders at this time.'}
                />
            )}
        </Div>
    );
};

export default PastDue;
