import React from 'react';

import { SVGIcon } from '../../UIKit/index';

export default function DCCompare(props) {
    return (
        <SVGIcon {...props} viewBox="0 0 21.005 14.703">
            <g transform="translate(2.789 1.952)">
                <path
                    d="M7.362,233.551H0v2.1H7.362V238.8l4.191-4.2-4.191-4.2Z"
                    transform="translate(-2.789 -226.05)"
                />
                <path
                    data-name="Path 1848"
                    d="M234.591,79.951V76.8L230.4,81l4.191,4.2V82.051h7.362v-2.1Z"
                    transform="translate(-223.736 -78.752)"
                />
            </g>
        </SVGIcon>
    );
}
