const dealsStore = {
    initialLoad: true,
    pipelines: {},
    pipelinesLoaded: false,
    pipelineUpdateStatus: false,
    pipelinePagination: {},
    pipelinesReset: false,
    pipelineTableData: new Map(),
    pipelineTablePagination: {},

    initialTableLoad: true,
    columns: [],
    cardConfig: null,
    tableCurrPage: 1,
    tableLimit: 25,

    peopleColumns: [],
    businessColumns: [],
    peopleVisibleFields: [],
    businessVisibleFields: [],

    activePipelineData: {
        data: {},
        pipelineChanged: false,
    },
    filter: '',

    order: '',
    field: '',
    search: '',

    dealsData: {},
    boardPagination: {},

    listViewData: {},
    listViewPagination: {},
    listViewLoader: {},

    notes: {
        data: [],
        loaded: false,
    },
    filterNotes: [],

    conversations: {
        data: [],
        loaded: false,
    },
    filterConversations: [],

    activities: {
        data: [],
        nextPage: null,
        loaded: false,
    },
    reminders: {
        data: [],
        nextPage: null,
        loaded: false,
    },

    logs: {
        data: [],
        loaded: false,
    },
    filterLogs: [],

    selectedDealLogData: {},
};

export default dealsStore;
