export const getComponents = (route, scopes) => {
    const isLock = scopes.indexOf(route) <= -1;
    switch (route) {
        case 'dashboard':
            return {
                componentName: 'Dashboard',
                componentPath: 'Dashboard',
                routepath: '/dashboard',
                isPrivate: true,
                isLock: isLock,
            };
        case 'contacts':
            return {
                componentName: 'Contacts',
                componentPath: 'Contacts',
                routepath: '/contacts',
                isPrivate: true,
                isLock: isLock,
            };
        case 'deals':
            return {
                componentName: 'Deals',
                componentPath: 'Deals',
                routepath: '/deals',
                isPrivate: true,
                isLock: isLock,
            };
        case 'inbound':
            return {
                componentName: 'InboundPage',
                componentPath: 'Inbound',
                routepath: '/inbound',
                isPrivate: true,
                isLock: isLock,
            };
        case 'templates':
            return {
                componentName: 'Templates',
                componentPath: 'Templates',
                routepath: '/templates',
                isPrivate: true,
                isLock: isLock,
            };
        case 'instasites':
            return {
                componentName: 'InstaSites',
                componentPath: 'InstaSites',
                routepath: '/instasites',
                isPrivate: true,
                isLock: isLock,
            };
        case 'instareports':
            return {
                componentName: 'InstaReports',
                componentPath: 'InstaReports',
                routepath: '/instareports',
                isPrivate: true,
                isLock: isLock,
            };
        case 'sites':
            return {
                componentName: 'AgencySite',
                componentPath: 'AgencySite',
                routepath: '/sites',
                isPrivate: true,
                isLock: isLock,
            };
        case 'forms':
            return {
                componentName: 'FormsPage',
                componentPath: 'Forms',
                routepath: '/forms',
                isPrivate: true,
                isLock: isLock,
            };
        case 'projects':
            return {
                componentName: 'Projects',
                componentPath: 'Projects',
                routepath: '/projects',
                isPrivate: true,
                isLock: isLock,
            };
        case 'analytics':
            return {
                componentName: 'Analytics',
                componentPath: 'Analytics',
                routepath: '/analytics',
                isPrivate: true,
                isLock: isLock,
            };
        case 'settings':
            return {
                componentName: 'Setting',
                componentPath: 'Setting',
                routepath: '/settings',
                isPrivate: true,
                isLock: isLock,
            };
        case 'store':
            return {
                componentName: 'Store',
                componentPath: 'Store',
                routepath: '/store',
                isPrivate: true,
                isLock: isLock,
            };
        case 'affiliate-center':
            return {
                componentName: 'AffiliateCenter',
                componentPath: 'AffiliateCenter',
                routepath: '/affiliate-center',
                isPrivate: true,
                isLock: isLock,
            };
        case 'subaccounts':
            return {
                componentName: 'ManageSubAccounts',
                componentPath: 'ManageSubAccounts',
                routepath: '/subaccounts',
                isPrivate: true,
                isLock: isLock,
            };
        case 'oauth-landing':
            return {
                componentName: 'OauthLanding',
                componentPath: 'Auth',
                routepath: '/oauth-landing',
                isPrivate: true,
                isLock: isLock,
            };
        case 'dashclicks':
            return {
                componentName: 'DashClicks',
                componentPath: 'DashClicks',
                routepath: '/dashclicks',
                isPrivate: true,
                isLock: isLock,
            };
        case 'reputation':
        case 'review':
            return {
                componentName: 'Reviews',
                componentPath: 'Reviews',
                routepath: '/reputation',
                isPrivate: true,
                isLock: isLock,
            };
        case 'conversations':
            return {
                componentName: 'Conversations',
                componentPath: 'Conversations',
                routepath: '/conversations',
                isPrivate: true,
                isLock: isLock,
            };
    }
};

export const publicRoutes = [
    // temp routes
    {
        componentName: 'IconSearchPage',
        componentPath: 'IconSearchPage',
        routepath: '/icons',
        isPrivate: false,
    },
];
