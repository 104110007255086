import React from 'react';

import { SVGIcon } from '../../UIKit/index';

export default function DCAttachment(props) {
    return (
        <SVGIcon {...props} viewBox="0 0 512 512">
            <path d="M249.1,62.8c0,0,11.9-6.4,23.2,1.8l7.6,8.1c0,0,7.2,8.1,0.8,21.4L77.5,298.5c0,0-31.3,27.7-18.7,74.8  c0,0,6.8,48.1,68,61c0,0,26,2.7,49-9.4l22.3-18.9l243.2-241.7c0,0,25.5-30.3,5.2-63.2c0,0-11-20.2-34-23.5c0,0-18.6-3.4-32.2,4.6  L127,330.1c0,0-9.7,10-5.3,25.6c0,0,5.3,12.2,18,13.9c0,0,8.7,2.3,14.3-2.3L389,133.1l16.8-16.6c0,0,11.7-7.1,22.9-0.5  c0,0,16.5,5.2,11.8,25.3c0,0-2.2,5.8-7.3,12.6L189.4,397.4c0,0-25.2,25.6-60.6,16.3c0,0-38.1-2.3-52.9-52.9c0,0-6.9-34.3,9-48.8  l203.7-205.3l38.7-39.2c0,0,48.4-59.5,118.5-25.6c0,0,59.7,23.2,54.2,93.5c0,0-0.3,33.2-27.4,60.3L237.3,431.2  c0,0-31.9,42.6-89.5,49.3c0,0-122.4,5.3-135.9-116.6c0,0-4.7-63.9,29.2-92.9l202-203.4L249.1,62.8z" />
        </SVGIcon>
    );
}
