import React from 'react';

import { SVGIcon } from '../../UIKit/index';

export default function DCEye(props) {
    return (
        <SVGIcon {...props} viewBox="0 0 491.3 491.3">
            <g>
                <path d="M490.2,239.7C454.4,150.4,356.1,90.4,245.7,90.4c-110.4,0-208.7,60-244.5,149.3c-1.5,3.8-1.5,8.1,0,11.9    C37,340.9,135.3,400.9,245.7,400.9c110.4,0,208.6-60,244.5-149.2C491.7,247.8,491.7,243.5,490.2,239.7z M245.7,368.9    c-94.9,0-179.3-49.3-212.3-123.2c33-74,117.4-123.2,212.3-123.2c95,0,179.3,49.3,212.3,123.2C424.9,319.6,340.6,368.9,245.7,368.9    z" />
                <path d="M244.8,152.3c-51.5,0-93.3,41.9-93.3,93.3s41.9,93.3,93.3,93.3s93.3-41.9,93.3-93.3S296.2,152.3,244.8,152.3z     M244.8,307c-33.8,0-61.3-27.5-61.3-61.3s27.5-61.3,61.3-61.3s61.3,27.5,61.3,61.3S278.6,307,244.8,307z" />
            </g>
        </SVGIcon>
    );
}
