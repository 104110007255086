import React from 'react';

import { SVGIcon } from '../../UIKit/index';

export default function DCDownload1(props) {
    return (
        <SVGIcon {...props} viewBox="0 0 512 512">
            <g>
                <path d="M396.19,249.8c-2.78-6.13-8.9-10.06-15.64-10.04h-68.48V16.38c0-9.46-7.66-17.12-17.12-17.12     c0,0,0,0,0,0h-68.48c-9.46,0-17.12,7.67-17.12,17.12c0,0,0,0,0,0v223.37h-68.48c-9.46-0.08-17.18,7.53-17.26,16.98     c-0.03,4.26,1.52,8.38,4.36,11.55l120.25,137.42c6.2,7.14,17.01,7.9,24.15,1.7c0.61-0.53,1.17-1.09,1.7-1.7l120.25-137.42     C398.52,263.03,399.25,255.8,396.19,249.8z" />
                <path d="M64.51,444.4h382.37v59.41H64.51V444.4z" />
            </g>
        </SVGIcon>
    );
}
