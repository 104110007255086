import React from 'react';

import { SVGIcon } from '../../UIKit/index';

export default function DCCalendar1(props) {
    return (
        <SVGIcon {...props} viewBox="0 0 500 512">
            <g>
                <g transform="translate(-168.7 -155.657)">
                    <g transform="translate(162.831 155.657)">
                        <g transform="translate(6.169 3.729)">
                            <g>
                                <path
                                    fill="#005EFF"
                                    stroke="#FFFFFF"
                                    strokeWidth="7.7155"
                                    d="M460.5,50.8H34.9c-16.5,0-29.9,13.4-30,30v389.6c0,16.5,13.4,29.9,30,30h425.5      c16.5,0,29.9-13.4,30-30V80.8C490.4,64.2,477,50.8,460.5,50.8z M454.5,464.4H40.9V86.8h413.6V464.4z"
                                />
                            </g>
                        </g>
                        <g transform="translate(7.567 13.051)">
                            <g>
                                <rect
                                    fill="#005EFF"
                                    x="21.5"
                                    y="161.4"
                                    width="449.5"
                                    height="31.1"
                                />
                            </g>
                        </g>
                        <g transform="translate(14.559)">
                            <g>
                                <path
                                    fill="#005EFF"
                                    d="M122.4,6.6c-9.9,0-18,8.1-18,18c0,0,0,0,0,0v95.9c0,9.9,8.1,18,18,18s18-8.1,18-18V24.6      C140.4,14.7,132.3,6.6,122.4,6.6C122.4,6.6,122.4,6.6,122.4,6.6z"
                                />
                            </g>
                        </g>
                        <g transform="translate(33.202)">
                            <g>
                                <path
                                    fill="#005EFF"
                                    d="M343.5,6.6c-9.9,0-18,8.1-18,18v95.9c0,9.9,8.1,18,18.1,17.9c9.9,0,17.9-8,17.9-17.9V24.6      C361.5,14.7,353.5,6.6,343.5,6.6z"
                                />
                            </g>
                        </g>
                    </g>
                    <g transform="translate(179 174.926)" fill="#005EFF">
                        <rect x="123.5" y="235.1" width="39.8" height="30.8" />
                        <rect x="221.1" y="235.1" width="39.8" height="30.8" />
                        <rect x="318.3" y="235.1" width="39.8" height="30.8" />
                        <rect x="123.6" y="285.1" width="39.8" height="30.8" />
                        <rect x="221.2" y="285.1" width="39.8" height="30.8" />
                        <rect x="318.3" y="285.1" width="39.8" height="30.8" />
                        <rect x="123.6" y="335" width="39.8" height="30.8" />
                        <rect x="221.2" y="335" width="39.8" height="30.8" />
                        <rect x="318.3" y="335" width="39.8" height="30.8" />
                    </g>
                    <path fill="#D5E5FF" d="M206.7,590.6l420.7,0.4v37l-420.7-0.8V590.6z" />
                </g>
            </g>
        </SVGIcon>
    );
}
