import React from 'react';

import { SVGIcon } from '../../UIKit/index';

export default function DCBuiltCrain(props) {
    return (
        <SVGIcon {...props} viewBox="0 0 498 512">
            <path d="M257,238h187v76.3h-19.6V349H444v33.6l9.9,4.7c7.5,3.5,10.7,12.5,7.2,20  c-3.5,7.5-12.5,10.7-20,7.2c-5.3-2.5-8.6-7.8-8.6-13.7h-34.7c0,27.4,22.2,49.7,49.6,49.8s49.7-22.2,49.8-49.6  c0-15-6.8-29.3-18.5-38.7v-13.3h19.8v-34.6h-19.7V110.9h-32L257,24.7V0H111.4v10.1L10.7,110.9H0.5V238h110.9v242.6H65.1V512h238.1  v-31.4H257V238z M146.1,347.9l76.2-76.2v98.9L146,446.8L146.1,347.9z M222.3,110.9h-51.7l51.7-51.7V110.9z M222.3,145.6v57.8h-76.2  v-57.8H222.3z M161.3,480.6l60.9-61v61L161.3,480.6z M257,145.5h76.2v57.8H257V145.5z M444.1,145.5v57.8h-76.2v-57.8L444.1,145.5z   M363,110.9H257V62.7L363,110.9z M197.8,34.7l-51.8,51.7V34.7H197.8z M111.4,59.2v51.7H59.7L111.4,59.2z M35.2,203.3v-57.8h76.2  v57.8H35.2z M207.1,238l-61,60.9V238L207.1,238z" />
        </SVGIcon>
    );
}
