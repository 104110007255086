import dayjs from 'dayjs';
import { timeInUserTimeZone } from './dateUtils';
import { getAuthDetails, getAllSessions, isLocalStorageAvailable } from './localStorageUtils';

export const isAuthenticated = () => {
    if (typeof window == 'undefined' || !isLocalStorageAvailable()) {
        return false;
    }
    const localStore = getAuthDetails();
    if (localStore) {
        try {
            const expiration_time = localStore.session_exp;
            const authenticated = localStore.authenticated;
            const time_in_user_timezone = timeInUserTimeZone(expiration_time);
            const diffTime = dayjs(time_in_user_timezone).diff(new Date());
            if (diffTime > 0) {
                return authenticated;
            }
            return false;
        } catch (err) {
            return false;
        }
    } else {
        return false;
    }
};

export const getLocalStorageAuthenticateObject = () => {
    if (typeof window == 'undefined' || !isLocalStorageAvailable()) {
        return false;
    }
    const authData = JSON.parse(localStorage.getItem('auth-user') || '{}');
    const activeSession = sessionStorage.getItem('active-session') || '';
    const localStore = authData[activeSession];
    if (localStore) {
        try {
            const expiration_time = localStore.auth.session_exp;
            const time_in_user_timezone = timeInUserTimeZone(expiration_time);
            const diffTime = dayjs(time_in_user_timezone).diff(new Date());
            if (diffTime > 0) {
                return localStore;
            }
            return false;
        } catch (err) {
            return false;
        }
    } else {
        return false;
    }
};

export const setUnAuthorized = () => {
    if (typeof window == 'undefined') {
        return false;
    }
    const localStore = getAuthDetails();
    if (localStore) {
        try {
            localStore.authenticated = false;
            const allSessions = JSON.parse(localStorage.getItem('auth-user') || {});
            const activeSession = sessionStorage.getItem('active-session') || '';
            if (Object.keys(allSessions).length) {
                Object.keys(allSessions).forEach(session => {
                    if (session.session_id === activeSession) {
                        allSessions[session].authenticated = false;
                    }
                });
            }
            // sessionStorage.removeItem('active-session');
            localStorage.setItem('auth-user', JSON.stringify(allSessions));
            return true;
        } catch (err) {
            return false;
        }
    } else {
        return false;
    }
};

export const getAccountType = () => {
    if (typeof window == 'undefined') {
        return false;
    }
    const currentAccount = getAuthDetails();
    if (currentAccount) {
        try {
            return currentAccount.account?.type;
        } catch (err) {
            return false;
        }
    } else {
        return false;
    }
};

export const getCookie = name => {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) return parts.pop().split(';').shift();
};

export const setUnderMaintenance = () => {
    if (typeof window == 'undefined') {
        return false;
    }
    localStorage.setItem('underMaintenance', true);
};

export const isValidSession = sessionId => {
    if (typeof window == 'undefined') {
        return false;
    }
    const localStore = getAllSessions();
    if (localStore) {
        try {
            const session = localStore[sessionId];
            const expiration_time = session.session_exp;
            const authenticated = session.authenticated;
            const exp_time = new Date(expiration_time);
            const diffTime = dayjs(exp_time).diff(new Date());
            if (diffTime > 0) {
                return authenticated;
            }

            return false;
        } catch (err) {
            return false;
        }
    } else {
        return false;
    }
};
