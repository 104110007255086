import React, { memo, useState, useEffect } from 'react';
import { Confirmation } from 'Components';

const DetectAdBlock = () => {
    const [adBlockDetected, setAdBlockDetected] = useState(false);

    useEffect(() => {
        const head = document.getElementsByTagName('head')[0];
        const noAdBlockDetected = () => {
            setAdBlockDetected(false);
        };
        const onAdBlockDetected = () => {
            if (localStorage.getItem('adBlockDetected')) {
                setAdBlockDetected(false);
            } else {
                setAdBlockDetected(true);
            }
        };
        const oldScript = document.getElementById('adblock-detection');

        if (oldScript && typeof head === 'object') {
            head.removeChild(oldScript);
        }
        setTimeout(() => {
            const script = document.createElement('script');
            script.id = 'adblock-detection';
            script.type = 'text/javascript';
            script.src = '/pagead.js';
            script.onload = noAdBlockDetected;
            script.onerror = onAdBlockDetected;
            head.appendChild(script);
        }, 2500);
    }, []);
    return (
        <div id="adblock-wrapper">
            {adBlockDetected && (
                <Confirmation
                    title={'(Warning) AdBlocker Found'}
                    open={adBlockDetected}
                    close={() => {
                        setAdBlockDetected(false);
                    }}
                    message={
                        'We found an AdBocker in your browser. If you leave this on the dashboard will not work 100% properly. For the best experience please turn it off and reload the page.'
                    }
                    acceptButton={{
                        title: 'Reload Page ',
                        onClick: () => {
                            setAdBlockDetected(false);
                            localStorage.removeItem('adBlockDetected');
                            location.reload();
                        },
                    }}
                    declinedButton={{
                        title: 'Continue with AdBlocker On',
                        onClick: () => {
                            setAdBlockDetected(false);
                            localStorage.setItem('adBlockDetected', 'true');
                        },
                    }}
                />
            )}
        </div>
    );
};
export default memo(DetectAdBlock);
