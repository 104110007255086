import React from 'react';

import { SVGIcon } from '../../UIKit/index';

export default function DCSignature(props) {
    return (
        <SVGIcon {...props} viewBox="0 0 512 454">
            <g transform="translate(0.1 -28.837)">
                <g transform="translate(0 28.985)">
                    <path d="M506.74,32.87l-27.7-27.7c-5.76-5.75-15.08-5.75-20.84,0L440.72,22.4l-3.47-3.47    c-5.76-5.75-15.08-5.75-20.84,0l-90.97,90.97H14.87c-8.14,0-14.75,6.6-14.75,14.75c0,0.01,0,0.03,0,0.04v314.43    c0,8.14,6.6,14.75,14.75,14.75c0,0,0,0,0,0H334.2c8.14,0,14.75-6.6,14.75-14.75l0,0V239.31L492.87,95.39    c5.75-5.76,5.75-15.08,0-20.84l-3.47-3.47l17.37-17.37c5.75-5.75,5.75-15.07,0-20.82C506.75,32.88,506.74,32.87,506.74,32.87z     M29.59,139.41h266.36l-101.92,101.9c-2.05,2.06-3.46,4.67-4.03,7.52l-10.19,50.91l-25.04-11.95c-5.26-2.49-11.5-1.66-15.92,2.13    l-42.17,35.93c-0.83,0.69-2.07,0.58-2.76-0.25c-0.29-0.35-0.45-0.79-0.46-1.24v-12.57c0-17.36-14.08-31.43-31.44-31.43    c-5.81,0-11.51,1.61-16.47,4.66l-15.96,9.82L29.59,139.41z M319.39,424.35H29.59v-94.91L61,310.07c0.9-0.6,2.12-0.36,2.72,0.54    c0.23,0.34,0.34,0.75,0.33,1.16v12.57c-0.07,12.28,7.08,23.46,18.26,28.53c4.16,1.93,8.69,2.94,13.28,2.94    c7.45-0.02,14.64-2.7,20.29-7.56l34.97-29.83l33.5,16h0.13l0.49,0.21l0.28,0.11l0.43,0.17h0.28l0.51,0.17l0,0l0.47,0.13h0.32h0.43    h0.28h0.43h0.45h0.36h0.23h0.49h1.7h0.43h0.28h0.49h0.26h0.55l69.49-13.89c2.85-0.57,5.47-1.97,7.52-4.03l49.01-49.01v155.55    L319.39,424.35z M252.76,293.71l-43.43,8.69l8.69-43.43l153.17-153.17l34.73,34.73L252.76,293.71z M426.77,119.7l-34.73-34.73    l34.73-34.73l34.73,34.73L426.77,119.7z M468.45,50.21l-6.95-6.95l6.95-6.95l6.95,6.95L468.45,50.21z" />
                </g>
            </g>
        </SVGIcon>
    );
}
