import React from 'react';

import { SVGIcon } from '../../UIKit/index';

export default function DCTag2(props) {
    return (
        <SVGIcon {...props} viewBox="0 0 362.6 512">
            <g transform="translate(-90.9 0.1)">
                <path d="M143.4,506.8h261.1c25.7,1.3,47.6-18.5,49-44.2V175.7c-0.1-20-8.8-38.9-23.9-52.1L308.4,16.7   C298.8,8.3,286.5,3.8,273.8,4h-97.7c-42.9-2.1-79.4,30.8-81.7,73.6c0.1,18.5,7.6,36.1,20.8,49c-13.2,12.9-20.7,30.6-20.8,49v286.9   C95.7,488.4,117.7,508.1,143.4,506.8z M406.4,144.6c9,7.9,14.2,19.2,14.3,31.2v286.9c-0.5,8.6-7.8,15.2-16.3,14.7h-261   c-8.6,0.4-15.9-6.2-16.3-14.7v-287c0.1-12,5.3-23.3,14.3-31.2l0,0c10.9,4.6,22.7,7,34.5,6.9h51.7c-1.9,4.7-2.8,9.7-2.8,14.7   c2.7,27,26.8,46.8,53.9,44c23.3-2.3,41.7-20.8,44-44c-1.4-25.7-23.3-45.5-49-44.2h-97.6c-2.8,0-5.5-0.2-8.3-0.7l94.6-83.6   c6.7-5.5,16.4-5.5,23.1,0L406.4,144.6z M290.1,166.1c0,9-7.3,16.3-16.3,16.3c-9,0-16.3-7.3-16.3-16.3s7.3-16.3,16.3-16.3   C282.8,149.7,290.1,157,290.1,166.1z M176.1,33.4h44.4l-82.1,72.5c-7.2-7.7-11.2-17.8-11.3-28.3C128.4,51.9,150.3,32.2,176.1,33.4z   " />
                <path d="M332.7,388H214.9c-8.1-0.5-15.1,5.6-15.7,13.7c-0.5,8.1,5.6,15.2,13.7,15.7c0.7,0,1.3,0,2,0   h117.8c8.1-0.5,14.3-7.6,13.7-15.7C346,394.4,340.1,388.5,332.7,388z" />
                <path d="M200.2,343.8c0,8.1,6.6,14.7,14.7,14.7h117.8c8.1-0.5,14.3-7.6,13.7-15.7   c-0.5-7.4-6.4-13.2-13.7-13.7H214.9C206.8,329.1,200.2,335.7,200.2,343.8z" />
            </g>
        </SVGIcon>
    );
}
