import React from 'react';

import { SVGIcon } from '../../UIKit/index';

export default function DCFacebookAd1(props) {
    return (
        <SVGIcon {...props} viewBox="0 0 512 400">
            <g transform="translate(0.2 -60.8)">
                <g transform="translate(58.566 85.124)">
                    <g>
                        <path
                            stroke="#FFFFFF"
                            strokeWidth="2.8914"
                            d="M431.2,161h-43.6c-8,0-14.5,6.5-14.5,14.5s6.5,14.5,14.5,14.5h43.6c8,0,14.5-6.5,14.5-14.5     S439.2,161,431.2,161L431.2,161z"
                        />
                    </g>
                </g>
                <g transform="translate(56.556 93.165)">
                    <g transform="translate(0 0)">
                        <path
                            stroke="#FFFFFF"
                            strokeWidth="2.8914"
                            d="M414.4,244.4l-29.1-29.1c-5.7-5.7-14.9-5.7-20.6,0c-5.7,5.7-5.7,14.9,0,20.6l29.1,29.1     c5.7,5.7,14.9,5.7,20.6,0C420.1,259.2,420.1,250,414.4,244.4z"
                        />
                    </g>
                </g>
                <g transform="translate(56.556 73.062)">
                    <g transform="translate(0 0)">
                        <path
                            stroke="#FFFFFF"
                            strokeWidth="2.8914"
                            d="M414.4,90.1c-5.7-5.7-14.9-5.7-20.6,0l-29.1,29.1c-5.7,5.7-5.7,14.9,0,20.6     c5.7,5.7,14.9,5.7,20.6,0l29.1-29.1C420.1,105,420.1,95.8,414.4,90.1C414.4,90.1,414.4,90.1,414.4,90.1z"
                        />
                    </g>
                </g>
                <g transform="translate(0 61)">
                    <g transform="translate(0 0)">
                        <path
                            stroke="#FFFFFF"
                            strokeWidth="2.8914"
                            d="M344.4,10.7c-21.6,0-39.9,15.8-43.1,37.1L288,61.1c-24.4,23.6-57,36.8-90.9,36.9H110     c-18.5,0-34.9,11.7-41.1,29.1h-2.5c-32.1,0-58.1,26-58.1,58.1s26,58.1,58.1,58.1h2.5c4.4,12.4,14.2,22.1,26.6,26.6V345     c0,24.1,19.5,43.6,43.6,43.6c24.1,0,43.6-19.5,43.6-43.6v-72.7h14.5c33.9,0,66.5,13.3,90.9,36.9l13.3,13.3     c3.6,23.8,25.8,40.2,49.6,36.6c21.3-3.2,37.1-21.5,37.1-43.1V54.3C388,30.2,368.5,10.7,344.4,10.7z M66.4,214.1     c-16.1-0.3-28.9-13.5-28.6-29.5c0.2-15.7,12.9-28.4,28.6-28.6V214.1z M153.5,344.9c0,8-6.5,14.5-14.5,14.5     c-8,0-14.5-6.5-14.5-14.5v-72.7h29.1V344.9z M182.6,243.2H110c-8,0-14.5-6.5-14.5-14.5v-87.2c0-8,6.5-14.5,14.5-14.5h72.7     L182.6,243.2z M300.8,281.3c-25.1-21.5-56.3-34.6-89.2-37.5V126.3c32.9-2.9,64.1-16,89.2-37.5V281.3z M358.9,315.9     c0,8-6.5,14.5-14.5,14.5l0,0c-8,0-14.5-6.5-14.5-14.5V54.3c0-8,6.5-14.5,14.5-14.5s14.5,6.5,14.5,14.5L358.9,315.9z"
                        />
                    </g>
                </g>
            </g>
        </SVGIcon>
    );
}
