import AWS, { Credentials } from 'aws-sdk';
import configs from 'config';

import { CallAPI } from './apiCall';

const axiosCall = new CallAPI();

export const fileUploadSTS = async (file, fileDetails, onProgressChange, publicUpload, accept) => {
    try {
        let dataToSend;
        if (accept?.startsWith('image')) {
            dataToSend = {
                file_type: fileDetails.type,
                file_name: fileDetails.name.replaceAll(' ', '_'),
                file_size: fileDetails.size,
                type: 'image',
            };
        } else {
            dataToSend = {
                file_type: fileDetails.type,
                file_name: fileDetails.name.replaceAll(' ', '_'),
                file_size: fileDetails.size,
            };
        }
        let data;
        if (publicUpload)
            data = await axiosCall.request(
                'POST',
                `${configs.REACT_APP_API_BASE_URL}/${process.env.REACT_APP_API_VERSION}/${process.env.REACT_APP_API_INTEGRATIONS}/${process.env.REACT_APP_API_WASABI}/sts/${process.env.REACT_APP_API_PUBLIC}`,
                dataToSend,
            );
        else
            data = await axiosCall.request(
                'POST',
                `${configs.REACT_APP_API_BASE_URL}/${process.env.REACT_APP_API_VERSION}/${process.env.REACT_APP_API_INTEGRATIONS}/${process.env.REACT_APP_API_WASABI}/sts`,
                dataToSend,
            );
        let creds = new Credentials({
            accessKeyId: data.data.credentials.AccessKeyId,
            secretAccessKey: data.data.credentials.SecretAccessKey,
            sessionToken: data.data.credentials.SessionToken,
        });
        let config = {
            apiVersion: '2006-03-01',
            credentials: creds,
            bucketName: data.data.additionalInfo.bucket,
            region: 'us-east-1',
            endpoint: 'https://s3.wasabisys.com',
            ACL: 'private',
        };
        var myBucket = data.data.additionalInfo.bucket;
        var myKey = data.data.key;

        try {
            AWS.config.update(config);

            let fileUpload = new AWS.S3.ManagedUpload({
                params: {
                    Bucket: myBucket,
                    Key: myKey,
                    Body: new Uint8Array(await file.arrayBuffer()),
                },
            });
            return new Promise((resolve, reject) => {
                fileUpload.send(async (err, resfile) => {
                    if (err) {
                        reject({ status: 'Failed', error: err });
                    } else {
                        resolve({
                            status: 'Success',
                            key: myKey,
                            bucket: resfile.Bucket,
                        });
                    }
                });
                fileUpload.on('httpUploadProgress', progressEvent => {
                    onProgressChange(progressEvent, fileDetails.id);
                });
            });
        } catch (err) {
            return { status: 'Failed', error: err };
        }
    } catch (err) {
        return { status: 'Failed', error: err };
    }
};

export const deleteFile = async (filekey, bucket) => {
    const url = `${configs.REACT_APP_API_BASE_URL}/${process.env.REACT_APP_API_VERSION}/${process.env.REACT_APP_API_INTEGRATIONS}/${process.env.REACT_APP_API_WASABI}/${process.env.REACT_APP_API_DELETE_BY_KEY}`;

    return new Promise((resolve, reject) => {
        axiosCall
            .request('DELETE', url, {
                key: filekey,
                bucket,
            })
            .then(() => {
                resolve('Deleted Successfully');
            })
            .catch(err => {
                reject(err);
            });
    });
};
