import React, { Fragment, useEffect, useState } from 'react';

import DCAddIcon from '../../Assets/icons/DCAddIcon';
import DCUser from '../../Assets/icons/DCUser';
import { AutoComplete } from '../../Components/index';
import { Button, IconButton, ToolTip } from '../../UIKit/index';
import DCCompare from 'Assets/icons/DCCompare';

function CreateFilterDropdown({ type, preventAdding, addToGroup, fields }) {
    const [options, setOptions] = useState([]);

    useEffect(() => {
        setOptions(
            (fields || []).map((f, i) => {
                return {
                    id: i + f.name,
                    show_in_filter: f.show_in_filter,
                    title: f.title,
                    field: f,
                };
            }),
        );
    }, [fields]);

    const generateAutoCompleteOption = () => {
        const tempRowArray = [];
        const optionsNew = options.filter(f => f.show_in_filter !== false);

        optionsNew.map(item => tempRowArray.push({
                id: item.id,
                label: (
                    <Fragment>
                        <Button
                            buttonClass={'ACSingleOption '}
                            iconName={
                                item.field?.custom ? (
                                    <ToolTip content={'Custom Property'}>
                                        <DCCompare />
                                    </ToolTip>
                                ) : (
                                    <DCUser />
                                )
                            }
                            onClick={() => {
                                addToGroup(item.field);
                            }}
                        >
                            {item.title}
                        </Button>
                    </Fragment>
                ),
                value: item.title || '',
            }),
        );
        return tempRowArray;
    };

    return (
        <AutoComplete
            customClass={'CreateFilterDropdown'}
            customWidth={300}
            value={
                <Fragment>
                    {type === 'iconButton' ? (
                        <ToolTip content={'Create a filter group'} wrapperClass={'FBFIATooltip'}>
                            <IconButton
                                disabled={preventAdding}
                                customClass="FBFIAddBtn"
                                // onClick={!preventAdding ? ()=>{} : null}
                            >
                                <DCAddIcon />
                            </IconButton>
                        </ToolTip>
                    ) : (
                        <Button
                            disabled={preventAdding}
                            buttonClass={'FBFIAddFilterBtn'}
                            iconName={<DCAddIcon />}
                            // onClick={handleToggle}
                        >
                            Add filter
                        </Button>
                    )}
                </Fragment>
            }
            generateRows={generateAutoCompleteOption}
            isAPICall={false}
            singleSelection={true}
            itemHeight={36}
            disabled={preventAdding}
        />
    );
}

export default CreateFilterDropdown;
