import React from 'react';

import { icons } from '../../Assets/icons/utils/icons';

export default function IconSearchPage() {
    return (
        <div style={{ padding: 30 }}>
            <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                {icons.map((item, index) => {
                    const Component = item.component;

                    return (
                        <div key={index} style={{ margin: 30 }}>
                            <div
                                style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    fontSize: '34px',
                                }}
                            >
                                <Component props={{ width: 25 }} />
                            </div>
                            <div>{item.name}</div>
                        </div>
                    );
                })}
            </div>
        </div>
    );
}
