import React, { useState, useCallback, Fragment, useEffect } from 'react';
import DCAddIcon from 'Assets/icons/DCAddIcon';
import DCCrossClose from 'Assets/icons/DCCrossClose';
import DCRightArrow2 from 'Assets/icons/DCRightArrow2';
import DCFillCheck from 'Assets/icons/DCFillCheck';
import DCFillClose from 'Assets/icons/DCFillClose';
import DCInfo from 'Assets/icons/DCInfo';
import { TopBar, Dialog, Div, IconButton, H1, Button } from 'UIKit/index';
import './style.scss';
import usePubSub from '../PubSub';
import { useQuery } from 'react-query';
import { getSubscription } from 'apis/settings/index.api';
import { formatNumberString } from 'Utils';
import _ from 'lodash';
import { getAccountDetails } from 'Utils/localStorageUtils';
import GetIcon from './Utils';
import { getContent } from './docs';
import { getAccountType, isAuthenticated } from 'Utils/auth';
import { singleItemCheckout } from 'apis/store/index.api';
import Checkout from 'Pages/Store/Checkout/checkout';
import useToast from 'Modules/Toasts';

const ComparePlan = ({ activeDataHigh, formattedData }) => {
    return (
        <Div className={'PWDBIRight'}>
            <Div className={'PWDBIRHead'}>
                <Div className={'PWDBIRCol1'}>Features</Div>
                <Div className={'PWDBIRCol2'}>
                    <Div className={'PWDBIRCCol1'}>
                        {activeDataHigh.length > 0 && activeDataHigh[0]?.nickname}
                    </Div>
                    <Div className={'PWDBIRCCol2'}>
                        <DCRightArrow2 />
                    </Div>
                    <Div className={'PWDBIRCCol1'}>
                        {activeDataHigh.length > 1 && activeDataHigh[1]?.nickname}
                    </Div>
                </Div>
            </Div>
            <Div className={'PWDBIRBody'}>
                {formattedData?.map((item, index) => {
                    return (
                        <Div className={'PWDBIRBGroup'} key={`plans-${index + 1}`}>
                            <Div className={'PWDBIRBGRow'}>
                                <Div className={'PWDBIRGCol1 PWDBIRGCTitle'}>
                                    <Div className={'PWDBIRGCIcon'}>{GetIcon(item.section)}</Div>
                                    <Div className={'PWDBIRGCTitle'}>{item.section}</Div>
                                </Div>
                                <Div className={'PWDBIRGCol2'}>
                                    <Div className={'PWDBIRCGCol1'} />
                                    <Div className={'PWDBIRCGCol2'} />
                                    <Div className={'PWDBIRCGCol1'} />
                                </Div>
                            </Div>
                            {item.features.map((feature, index) => {
                                return (
                                    <Div className={'PWDBIRBGRow'} key={`feature-${index + 1}`}>
                                        <Div className={'PWDBIRGCol1'}>{feature.label}</Div>
                                        <Div className={'PWDBIRGCol2'}>
                                            <Fragment>
                                                {typeof feature.values[0] === 'boolean' ? (
                                                    feature.values[0] ? (
                                                        <Div
                                                            className={
                                                                'PWDBIRCGCol1 PWDBIRCGCNYIcon'
                                                            }
                                                            style={{
                                                                color: '#4caf50',
                                                            }}
                                                        >
                                                            <DCFillCheck />
                                                        </Div>
                                                    ) : (
                                                        <Div
                                                            className={
                                                                'PWDBIRCGCol1 PWDBIRCGCNYIcon'
                                                            }
                                                            style={{ color: '#f44336' }}
                                                        >
                                                            <DCFillClose />
                                                        </Div>
                                                    )
                                                ) : (
                                                    <Div
                                                        className={'PWDBIRCGCol1'}
                                                        key={`value-highlight-${index + 1}`}
                                                    >
                                                        {Number.isInteger(feature.values[0])
                                                            ? formatNumberString(feature.values[0])
                                                            : feature.values[0]}
                                                    </Div>
                                                )}
                                            </Fragment>
                                            <Div className={'PWDBIRCGCol2'}>
                                                <DCRightArrow2 />
                                            </Div>
                                            <Fragment>
                                                {typeof feature.values[1] === 'boolean' ? (
                                                    feature.values[1] ? (
                                                        <Div
                                                            className={
                                                                'PWDBIRCGCol1 PWDBIRCGCNYIcon'
                                                            }
                                                            style={{
                                                                color: '#4caf50',
                                                            }}
                                                        >
                                                            <DCFillCheck />
                                                        </Div>
                                                    ) : (
                                                        <Div
                                                            className={
                                                                'PWDBIRCGCol1 PWDBIRCGCNYIcon'
                                                            }
                                                            style={{ color: '#f44336' }}
                                                        >
                                                            <DCFillClose />
                                                        </Div>
                                                    )
                                                ) : (
                                                    <Div
                                                        className={'PWDBIRCGCol1'}
                                                        key={`value-highlight-${index + 1}`}
                                                    >
                                                        {Number.isInteger(feature.values[1])
                                                            ? formatNumberString(feature.values[1])
                                                            : feature.values[1]}
                                                    </Div>
                                                )}
                                            </Fragment>
                                        </Div>
                                    </Div>
                                );
                            })}
                        </Div>
                    );
                })}
            </Div>
        </Div>
    );
};

const titleMap = {
    contact_conversations: 'Conversations',
    custom_filters: 'Custom Filters',
    custom_properties: 'Custom Properties',
    deal_automations: 'Deal Automations',
    group_form: 'Group Form',
    card_form: 'Card Form',
    round_robin_leads: 'Round Robin Leads',
    auto_create_contacts: 'Auto Create Contacts',
    auto_create_deals: 'Auto Create Deals',
    insights: 'Insights',
    ecommerce_templates: 'eCommerce Templates',
    advanced_search: 'Advanced Search',
    lead_automations: 'Lead Automations',
    custom_domain: 'Custom Domain',
    custom_email: 'Custom Email',
    phone_support: 'Phone Support',
    partner_onboarding: 'Partner Onboarding (via Zoom)',
};

export default function PayWallDialog() {
    const currentTier = getAccountDetails().plan.tier || 'tier0';
    const interval = getAccountDetails().plan?.recurring?.interval || 'month';
    const { showToast } = useToast();
    const { publish } = usePubSub();
    const [showPayWallModal, setShowPayWallModal] = useState(false);
    const [contactSupport, setContactSupport] = useState(false);

    const [activeDataHigh, setActiveDataHigh] = useState([]);
    const [rawData, setRawData] = useState({});

    const [type, setType] = useState('');
    const [formattedData, setFormattedData] = useState([]);
    const [contentData, setContentData] = useState({});

    const [success, setSuccess] = useState(false);

    const [openCheckout, setOpenCheckout] = useState(false);
    const [checkoutData, setCheckoutData] = useState(null);

    const handleClose = () => {
        setShowPayWallModal(false);
        setSuccess(false);
    };

    usePubSub(
        'paywall_modal',
        useCallback(_data => {
            if (_data.type) {
                setContentData(getContent(_data.type));
                setType(_data.type);
            }

            setShowPayWallModal(true);
        }, []),
    );

    const { data } = useQuery(
        ['paywalls_plans', `sub=${getAccountType() !== 'main'}`],
        () => getSubscription(`sub=${getAccountType() !== 'main'}`),
        {
            staleTime: 1000 * 60 * 10, // 10 minutes
            cacheTime: 1000 * 60 * 15, // 15 minutes
            retry: 1,
            keepPreviousData: true,
            enabled: !!type && isAuthenticated(),
        },
    );

    const getTierObject = item => {
        return {
            id: item.id,
            highlights: item.additional_info?.highlights,
            color: item.additional_info?.color,
            priceId: item.id,
            nickname: item.nickname,
            unit_amount: item.unit_amount,
            type: item.type,
            details: item.additional_info?.details,
            order: item.additional_info?.order,
            profit: item.additional_info?.profit,
            wholeSaleAmount: item.additional_info?.wholesale_unit_amount,
            tier: item.metadata.tier,
        };
    };

    useEffect(() => {
        if (data) {
            if (currentTier === 'tier3') {
                setContactSupport(true);
                return;
            }
            const body = data.data[0]?.prices;
            if (body) {
                const highlighted = body
                    .filter(item => item.recurring.interval === interval)
                    .map(item => getTierObject(item));

                const tempHighlighted = _.sortBy(highlighted, 'order');

                const currentIndex = tempHighlighted.findIndex(
                    highlight => highlight.tier === currentTier,
                );

                const res = [];
                tempHighlighted.forEach(obj => {
                    obj.details.forEach(mObj => {
                        const flt = mObj.data.filter(obj => !obj.value);
                        res.push({
                            label: mObj.label,
                            data: flt,
                            tier: obj.tier,
                        });
                    });
                });
                if (contentData?.activateTier) {
                    let endInd = tempHighlighted.findIndex(
                        item => item.tier === contentData.activateTier,
                    );
                    let valid = {};
                    const validation = () => {
                        const check1 = res.filter(
                            val => val.tier ===
                                tempHighlighted[
                                    tempHighlighted.length <= endInd
                                        ? tempHighlighted.length - 1
                                        : endInd
                                ].tier,
                        );
                        check1.forEach(obj => {
                            obj.data.forEach(mObj => {
                                if (mObj.label === titleMap[type]) {
                                    valid = mObj;
                                }
                            });
                        });
                    };
                    validation();
                    if (Object.keys(valid).length > 0 && !valid.value) {
                        endInd = endInd + 1;
                        validation();
                    }
                    setActiveDataHigh([tempHighlighted[currentIndex], tempHighlighted[endInd]]);
                } else {
                    let startInd = currentIndex;
                    let endInd = currentIndex + 1;
                    let valid = {};
                    const validation = () => {
                        const check1 = res.filter(
                            val => val.tier ===
                                tempHighlighted[
                                    tempHighlighted.length <= endInd
                                        ? tempHighlighted.length - 1
                                        : endInd
                                ].tier,
                        );
                        check1.forEach(obj => {
                            obj.data.forEach(mObj => {
                                if (mObj.label === titleMap[type]) {
                                    valid = mObj;
                                }
                            });
                        });
                    };
                    validation();
                    if (Object.keys(valid).length > 0 && !valid.value) {
                        endInd = endInd + 1;
                        validation();
                    }
                    setActiveDataHigh([tempHighlighted[startInd], tempHighlighted[endInd]]);
                }
            }
        }
    }, [contentData, currentTier, data, interval, type]);

    useEffect(() => {
        const arr = activeDataHigh;
        if (arr && arr.length) {
            setRawData(arr);
            const sections = arr[0]?.details?.map(obj => {
                return obj.label;
            });

            const output = [];
            sections?.forEach(section => {
                let tempFeatures = [];
                arr.forEach(obj => {
                    const sec = obj?.details.findIndex(c => c.label === section);
                    tempFeatures = obj?.details[sec].data;
                });
                const labels = tempFeatures?.map(obj => {
                    return obj.label;
                });
                const tempArr = [];
                labels?.forEach(label => {
                    const newRes = [];
                    arr.forEach(objM => {
                        objM.details.forEach(obj => {
                            if (obj.label === section) {
                                const ind = obj.data.findIndex(f => f.label === label);
                                if (ind !== -1) {
                                    newRes.push(obj.data[ind].value);
                                }
                            }
                        });
                    });

                    tempArr.push({ label: label, values: newRes });
                });

                output.push({
                    section: section,
                    features: tempArr,
                });
            });
            setFormattedData(output);
        }
    }, [activeDataHigh]);
    const [singleItemBody, setSingleItemBody] = useState(null);

    const upgradePlan = async () => {
        const res = await singleItemCheckout(
            {
                business: getAccountDetails().business_id,
                price: rawData[1].id,
                subscription: getAccountDetails().plan.id,
            },
            {
                type: 'preview',
            },
        );

        if (res.success) {
            setSuccess(true);
            setCheckoutData(res.data);
            setOpenCheckout(true);
            setSingleItemBody({
                business: getAccountDetails().business_id,
                price: rawData[1].id,
                subscription: getAccountDetails().plan.id,
            });
        }
    };

    const pageView = () => {
        if (contactSupport) {
            return (
                <Div className={'PayWallDialog'}>
                    <Div className={'PWDBody'}>
                        <Div className={'PWDBISuccess'} style={{ borderColor: '#f44336' }}>
                            <Div className={'PWDBISIcon'} style={{ color: '#f44336' }}>
                                <DCInfo />
                            </Div>
                            <Div className={'PWDBISTitle'}>
                                You are currently on the highest subscription, but have hit some
                                limits. Reach out to customer support so we can help.
                            </Div>
                        </Div>
                    </Div>
                </Div>
            );
        }
        if (success) {
            return (
                <Div className={'PayWallDialog'}>
                    <Div className={'PWDBody'}>
                        <Div className={'PWDBISuccess'}>
                            <Div className={'PWDBISIcon'}>
                                <svg viewBox="0 0 26 26" xmlns="http://www.w3.org/2000/svg">
                                    <g
                                        stroke="currentColor"
                                        strokeWidth="2"
                                        fill="none"
                                        fillRule="evenodd"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                    >
                                        <path
                                            className="circle"
                                            d="M13 1C6.372583 1 1 6.372583 1 13s5.372583 12 12 12 12-5.372583 12-12S19.627417 1 13 1z"
                                        />
                                        <path
                                            className="tick"
                                            d="M6.5 13.5L10 17 l8.808621-8.308621"
                                        />
                                    </g>
                                </svg>
                            </Div>
                            <Div className={'PWDBISTitle'}>
                                Your plan added successfully please checkout
                            </Div>
                        </Div>
                    </Div>
                </Div>
            );
        } else {
            return (
                <Div className={'PayWallDialog'}>
                    <TopBar>
                        <Div className={'HeaderBar'}>
                            <Div className={'PWDHLeft'}>
                                <Div className={'PWDHIcon'}>{GetIcon(contentData.app)}</Div>
                                <H1 className={'HeaderBarTitle'}>{contentData.app}</H1>
                            </Div>
                            <Div className={'PWDHRight'}>
                                <Div className={'PWDHRIcon'}>
                                    <DCAddIcon />
                                </Div>
                                <Div className={'PWDHRTitle'}>
                                    Unlock All These Additional Features
                                </Div>
                                <Div className={'HeaderBarClose'}>
                                    <IconButton onClick={handleClose}>
                                        <DCCrossClose />
                                    </IconButton>
                                </Div>
                            </Div>
                        </Div>
                    </TopBar>
                    <Div className={'PWDBody'}>
                        <Div className={'PWDBInner'}>
                            <Div className={'PWDBILeft'}>
                                <Div className={'PWDBILTop'}>
                                    <Div className={'PWDBILTMainTitle'}>{contentData.title}</Div>
                                    <Div className={'PWDBILTIcon'}>{contentData.icon}</Div>
                                    <Div className={'PWDBILTDes'}>{contentData.description}</Div>
                                </Div>
                                {rawData && rawData.length > 0 && (
                                    <Div className={'PWDBILFooter'}>
                                        <Button buttonType={'BlueFillBtn'} onClick={upgradePlan}>
                                            Upgrade to {rawData[1]?.nickname} $
                                            {formatNumberString(rawData[1]?.unit_amount / 100)}/
                                            {interval === 'month' ? 'mo' : 'yr'}
                                        </Button>
                                    </Div>
                                )}
                            </Div>
                            <ComparePlan
                                activeDataHigh={activeDataHigh}
                                formattedData={formattedData}
                            />
                        </Div>
                    </Div>
                </Div>
            );
        }
    };

    return (
        <Fragment>
            <Dialog
                classes={{ row: 'PWDRow ' + (!success || !contactSupport ? 'PWDSCRow' : '') }}
                dialogType={'centerZoom ' + (success || contactSupport ? 'sizeXs' : 'sizeMd')}
                open={showPayWallModal}
                onClose={handleClose}
            >
                {openCheckout && checkoutData && (
                    <Checkout
                        data={{ [checkoutData.buyer?.id]: [checkoutData] }}
                        actualCost={checkoutData.item?.actual_cost}
                        handleCongrats={() => {
                            setOpenCheckout(false);
                            setShowPayWallModal(false);
                            setSuccess(false);
                            let message = `Your plan has been successfully updated.`;
                            publish('settings-updated');
                            showToast({
                                message,
                                type: 'success',
                                duration: 3000,
                            });
                        }}
                        dialogMode={true}
                        handleDialogClose={() => {
                            setOpenCheckout(false);
                            setSuccess(false);
                        }}
                        singleItem={true}
                        fetchSingleItemData={() => {
                            upgradePlan();
                            return singleItemCheckout(singleItemBody, { type: 'preview' });
                        }}
                        isSubscription={true}
                        totals={{
                            promocode: checkoutData.promocode,
                            total_discount_amounts:
                                checkoutData.item.setup_amount -
                                    checkoutData.item.actual_cost.setup_fee ||
                                checkoutData.item.amount -
                                    checkoutData.item.actual_cost.recurring_fee,
                            total: 0,
                        }}
                    />
                )}
                {pageView()}
            </Dialog>
        </Fragment>
    );
}
