import React from 'react';

import { SVGIcon } from '../../UIKit/index';

export default function DCVertical8Dots(props) {
    return (
        <SVGIcon {...props} viewBox="0 0 5 24">
            <path d="M0 0h2v2H0V0zm0 8h2v2H0V8zm0 8h2v2H0v-2zM0 4h2v2H0V4zm0 8h2v2H0v-2zm0 8h2v2H0v-2zM3 0h2v2H3V0zm0 8h2v2H3V8zm0 8h2v2H3v-2zM3 4h2v2H3V4zm0 8h2v2H3v-2zm0 8h2v2H3v-2z" />
        </SVGIcon>
    );
}
