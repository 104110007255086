import { CallAPI } from './apiCall';
import config from 'config';

let mainURL = `${config.REACT_APP_API_BASE_URL}/${process.env.REACT_APP_API_VERSION}`;

let configURL = `${mainURL}/${process.env.REACT_APP_API_USERS}/${process.env.REACT_APP_API_ME}/${process.env.REACT_APP_API_CONFIG}`;
let fieldsURL = `${mainURL}/${process.env.REACT_APP_API_CRM}/${process.env.REACT_APP_API_FIELDS}`;

let api = new CallAPI();

const handleApiCall = async (url, method) => {
    try {
        const response = await api.request(method, url);
        return Promise.resolve(response.data);
    } catch (err) {
        return Promise.reject(err);
    }
};

const getColumns = async (configType: string, fieldType: string): Promise<any> => {
    let url = [
        {
            url: `${configURL}?type=${configType}`,
            method: 'GET',
        },
        {
            url: `${fieldsURL}/${fieldType}`,
            method: 'GET',
        },
    ];
    const requests = url.map(data => {
        return handleApiCall(data.url, data.method);
    });

    try {
        const responses = await Promise.all(requests);
        const fields = responses[1];
        const temp = responses[0]?.fields?.[fieldType];
        const cardConfig = responses[0]?.card;
        const visibleOnAddFields = temp?.find(item => item.type === 'visibleOnAdd');
        const mandatoryConfig = {
            fields: temp?.filter(item => item.is_disallowed === true)?.map(item => item?.field),
        };
        let columns: any[] = [];
        fields.forEach(item => {
            let field = temp?.find(data => data.field === item.field);
            let newItem;
            if (item.show_in_form === undefined) {
                newItem = {
                    Header: item.title,
                    type: item.type,
                    description: item?.description ? item.description : null,
                    subType: item.sub_type ? item.sub_type : null,
                    custom: item.custom ? item.custom : false,
                    options: item.options ? item.options : [],
                    accessor: field ? field.field : item.field,
                    order: field ? field.order : 9999999,
                    width: field ? field.width : 250,
                    default: false,
                    draggable: item ? item.is_draggable : false,
                    sortable: item ? item.is_sortable : false,
                    visibleInCard: field ? field.in_dialog_card : false,
                    show_in_table: item ? item.show_in_table : false,
                    show_in_create: item ? item.show_in_create : false,
                    show_in_export: item ? item.show_in_export : false,
                    show_in_filter: item ? item.show_in_filter : false,
                    show_in_personalization: item ? item.show_in_personalization : false,
                    active: field ? field.is_visible : true,
                    show_in_update: item ? item.show_in_update : false,
                    hide_in_card: item ? !!item['hide_in_card'] : false,
                    allow_update: item.is_allow_update !== undefined ? item.is_allow_update : true,
                    orderInCard:
                        field && field.in_dialog_card
                            ? field.is_disallowed
                                ? 0
                                : field.order_in_card
                            : 9999999,
                };
                if (mandatoryConfig) {
                    const mandatory = mandatoryConfig.fields.findIndex(
                        data => (data.field || data) === field?.field,
                    );
                    if (mandatory !== -1) {
                        newItem.default = true;
                        newItem.draggable = false;
                        newItem.order = 0;
                    }
                }
                columns.push(newItem);
            }
        });
        return { columns, visibleOnAddFields, cardConfig };
    } catch (err: any) {
        return new Error(err);
    }
};

const updateColumns = async (columns, configType, fieldType, cardConfig) => {
    try {
        let body: any = {};
        body.type = configType;
        body.data = {};
        if (columns && fieldType) {
            let tempArray: any = [];
            columns.forEach(item => {
                let obj = {
                    field: item.accessor,
                    width: item.width || 250,
                    order: item.order !== undefined ? item.order : columns.length,
                    is_visible: item.active,
                    is_disallowed: item.default,
                    in_dialog_card: item.visibleInCard || false,
                    order_in_card: item.orderInCard,
                };
                tempArray.push(obj);
            });
            body.data.fields = { [fieldType]: tempArray };
        }

        if (cardConfig) {
            body.data.card = cardConfig;
        }
        await api.request('PUT', configURL, body);
    } catch (err) {
        // snackbar(err.message || 'Something went wrong')
    }
};

export { getColumns, updateColumns };
