import React from 'react';

import { SVGIcon } from '../../UIKit/index';

export default function DCBlueStar(props) {
    return (
        <SVGIcon {...props} viewBox="0 0 512 512">
            <g transform="translate(-563.754 -527.754)">
                <circle fill="#2E90F4" cx="819.45" cy="783.26" r="255.5" />
                <path
                    id="Path_1122"
                    fill={'#FFFFFF'}
                    d="M817.81,667.72l-36.93,73.26l-76.95,14.78l52.94,51.71l-6.16,84.95l67.1-44.94l65.87,41.24   l-7.39-81.26l61.56-51.71l-80.64-14.78L817.81,667.72z"
                />
            </g>
        </SVGIcon>
    );
}
