const dealsAction = {
    SET_INITIAL_LOAD: 'SET_INITIAL_LOAD',
    SET_PIPELINE_DATA: 'SET_PIPELINE_DATA',
    SET_PIPELINE_DATA_STATUS: 'SET_PIPELINE_DATA_STATUS',
    SET_PIPELINE_PAGINATION: 'SET_PIPELINE_PAGINATION',
    SET_PIPELINES_LOADED: 'SET_PIPELINES_LOADED',
    SET_RESET_PIPELINES: 'SET_RESET_PIPELINES',
    CLEAR_PIPELINE_DATA: 'CLEAR_PIPELINE_DATA',

    SET_TABLE_PIPELINE_DATA: 'SET_TABLE_PIPELINE_DATA',
    SET_TABLE_PIPELINE_PAGINATION: 'SET_TABLE_PIPELINE_PAGINATION',
    SET_TABLE_INITIAL_LOAD: 'SET_TABLE_INITIAL_LOAD',
    SET_COLUMNS: 'SET_COLUMNS',
    SET_CARD_CONFIG: 'SET_CARD_CONFIG',

    SET_PEOPLE_COLUMNS: 'SET_PEOPLE_COLUMNS',
    SET_BUSINESSES_COLUMNS: 'SET_BUSINESSES_COLUMNS',
    SET_PEOPLE_VISIBLE_FIELDS: 'SET_PEOPLE_VISIBLE_FIELDS',
    SET_BUSINESS_VISIBLE_FIELDS: 'SET_BUSINESS_VISIBLE_FIELDS',

    SET_ACTIVE_PIPELINE_DATA: 'SET_ACTIVE_PIPELINE_DATA',
    SET_CURR_TABLE_PAGE: 'SET_CURR_TABLE_PAGE',
    SET_TABLE_LIMIT: 'SET_TABLE_LIMIT',

    SET_ORDER: 'SET_ORDER',
    SET_FIELD: 'SET_FIELD',

    SET_SEARCH: 'SET_SEARCH',

    SET_NOTES: 'SET_NOTES',
    SET_FILTER_NOTES: 'SET_FILTER_NOTES',
    CLEAR_NOTES: 'CLEAR_NOTES',

    ADD_CONVERSATIONS: 'ADD_CONVERSATIONS',
    ADD_FILTER_CONVERSATIONS: 'ADD_FILTER_CONVERSATIONS',
    CLEAR_CONVERSATIONS: 'CLEAR_CONVERSATIONS',

    SET_ACTIVITIES: 'SET_ACTIVITIES',
    ADD_ACTIVITY: 'ADD_ACTIVITY',
    UPDATE_ACTIVITY: 'UPDATE_ACTIVITY',
    DELETE_ACTIVITY: 'DELETE_ACTIVITY',
    CLEAR_ACTIVITIES: 'CLEAR_ACTIVITIES',

    SET_REMINDERS: 'SET_REMINDERS',
    ADD_REMINDER: 'ADD_REMINDER',
    UPDATE_REMINDER: 'UPDATE_REMINDER',
    DELETE_REMINDER: 'DELETE_REMINDER',
    CLEAR_REMINDERS: 'CLEAR_REMINDERS',

    SET_DEALS_DATA: 'SET_DEALS_DATA',

    SET_BOARD_PAGINATION: 'SET_BOARD_PAGINATION',

    SET_LISTVIEW_DATA: 'SET_LISTVIEW_DATA',
    SET_LISTVIEW_PAGINATION: 'SET_LISTVIEW_PAGINATION',
    SET_LISTVIEW_LOADING: 'SET_LISTVIEW_LOADING',
    CLEAR_LISTVIEW_DATA: 'CLEAR_LISTVIEW_DATA',
    CLEAR_LISTVIEW_PAGINATION: 'CLEAR_LISTVIEW_PAGINATION',

    CLEAR_DEALS_DATA: 'CLEAR_DEALS_DATA',
    CLEAR_BOARD_PAGIANTION: 'CLEAR_BOARD_PAGINATION',
    SET_FILTER: 'SET_FILTER',

    ADD_LOGS: 'ADD_LOGS',
    ADD_FILTER_LOGS: 'ADD_FILTER_LOGS',
    CLEAR_LOGS: 'CLEAR_LOGS',

    SET_SELECTED_DEAL_LOG_DATA: 'SET_SELECTED_DEAL_LOG_DATA',
};

export default dealsAction;
