import React from 'react';

import { SVGIcon } from '../../UIKit/index';

export default function DCHorizontal3Dots(props) {
    return (
        <SVGIcon {...props} viewBox="0 0 426.667 426.667">
            <g>
                <circle cx="42.667" cy="213.333" r="42.667" />
                <circle cx="213.333" cy="213.333" r="42.667" />
                <circle cx="384" cy="213.333" r="42.667" />
            </g>
        </SVGIcon>
    );
}
