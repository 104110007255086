import React from 'react';

import { SVGIcon } from '../../UIKit/index';

export default function DCTag1(props) {
    return (
        <SVGIcon {...props} viewBox="0 0 512 512">
            <g transform="translate(0.304 0.201)">
                <path d="M450.7,7.8H262.3c-3.9,0-7.5,1.5-10.3,4.3L23.2,240.8c-20.8,20.9-20.8,54.7,0,75.6l172.4,172.4   c10,10,23.6,15.7,37.8,15.6l0,0c14.2,0.1,27.8-5.6,37.8-15.6l228.8-228.6c2.7-2.7,4.3-6.4,4.3-10.3V61.1   C504.1,31.6,480.2,7.8,450.7,7.8z M475.1,243.7L250.5,468.2c-9.5,9.4-24.9,9.4-34.4,0L43.7,295.6c-9.5-9.5-9.5-24.9,0-34.4   L268.2,36.8h182.5c13.5,0,24.4,10.9,24.4,24.3L475.1,243.7z" />
                <path d="M375.9,72.1c-35.3,0-64,28.6-64,64c0,35.3,28.6,64,64,64s64-28.6,64-64   c0-17-6.7-33.3-18.7-45.3C409.2,78.8,392.9,72,375.9,72.1z M392.4,152.4c-9.1,9-23.8,9-32.8-0.1c-9-9.1-9-23.8,0.1-32.8   c9.1-9,23.8-9,32.8,0.1c4.3,4.4,6.8,10.3,6.8,16.4C399.2,142.2,396.7,148.1,392.4,152.4z" />
            </g>
        </SVGIcon>
    );
}
