import React, { useCallback, useState } from 'react';

import { Dialog, Div, Button, Input } from 'UIKit/index';
import { login } from 'apis/login/index.api';
import { getAccountDetails, getSessionId, getUserDetails } from 'Utils/localStorageUtils';
import usePubSub from '../PubSub';
import DCWatch from 'Assets/icons/DCWatch';
import DCLock from 'Assets/icons/DCLock';
import DCRightArrow2 from 'Assets/icons/DCRightArrow2';
import './style.scss';
import DCEye from 'Assets/icons/DCEye';
import DCVisibilityEye from 'Assets/icons/DCVisibilityEye';

export default function Password() {
    const [isPassword, setIsPassword] = useState(false);
    const [password, setPassword] = useState('');
    const [error, setError] = useState(null);
    const [passwordVisible, setPasswordVisible] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const handlePasswordVisibilityToggle = () => {
        setPasswordVisible(prev => !prev);
    };

    usePubSub(
        'timeout_error',
        useCallback(() => {
            setIsPassword(true);
        }, []),
    );

    const handleLogin = useCallback(async () => {
        try {
            setIsLoading(true);
            const res = await login({
                account: getAccountDetails()?.id,
                email: getUserDetails()?.email,
                password: password,
                remember: false,
            });
            if (res.success) {
                const authObject = JSON.parse(localStorage.getItem('auth-user') || '{}');
                authObject[getSessionId()] = { auth: { ...res.data, authenticated: true } };
                localStorage.setItem('auth-user', JSON.stringify(authObject));
                setIsPassword(false);
                setError(null);
                location.reload();
            } else {
                console.error('error:', res);
                setError(res.additional_info);
            }
        } catch (err) {
            console.error('error:', err);
            setError(err || 'Wrong password please try again');
        } finally {
            setIsLoading(false);
        }
    }, [password]);

    /**
     * Handle a press of the "Enter" key
     * @param {HTMLElement} e DOM element on which the keyup event was fired on
     */
    const handleEnter = e => {
        if (e.key === 'Enter') {
            handleLogin();
        }
    };

    return (
        <Dialog
            dialogType={'centerZoom sizeXs'}
            open={isPassword}
            classes={{ root: 'passwordDialogMain' }}
        >
            <Div className="pDMInner">
                <Div className="pDMIIcon">
                    <Div className="pDMIIInner">
                        <DCWatch />
                    </Div>
                </Div>
                <Div className="pDMITitle">Your session has expired due to inactivity</Div>
                <Div className={'pDMIInput ' + (error ? 'pDMIIErr' : '')}>
                    <Div className="pDMIIIcon">
                        <DCLock />
                    </Div>
                    <Input
                        type={passwordVisible ? 'text' : 'password'}
                        placeholder={'Password'}
                        value={password}
                        onChange={e => {
                            setPassword(e.target.value);
                            setError(null);
                        }}
                        autoFocus={true}
                        onKeyUp={handleEnter}
                        autoComplete="none"
                    />
                    <Div className={'pDMIIRightIcon'} onClick={handlePasswordVisibilityToggle}>
                        {passwordVisible ? <DCEye /> : <DCVisibilityEye />}
                    </Div>
                </Div>
                {error ? (
                    <Div className="pDMIError">
                        {error === 'INVALID_CREDENTIALS'
                            ? 'Wrong password please try again'
                            : error}
                    </Div>
                ) : null}
                <Div className="pDMIBtn">
                    <Button
                        iconName={<DCRightArrow2 />}
                        iconPlacement={'right'}
                        buttonType={'BlueFillBtn fullWidth'}
                        onClick={handleLogin}
                        disabled={!password}
                        loading={isLoading}
                    >
                        LOGIN
                    </Button>
                </Div>
            </Div>
        </Dialog>
    );
}
