import React from 'react';

import { SVGIcon } from 'UIKit/index';
import Templates from 'Assets/images/AppLogo/templates.svg';

const backgroundStyle = {
    backgroundImage: 'url(' + Templates + ')',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
};
export default function DCTemplates(props) {
    return <SVGIcon {...props} viewBox="0 0 2000 2000" style={backgroundStyle} />;
}
