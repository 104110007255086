import React from 'react';

import { SVGIcon } from '../../UIKit/index';

export default function DCArrowUp(props) {
    return (
        <SVGIcon {...props} viewBox="0 0 240.835 240.835">
            <path d="M129.007,57.819c-4.68-4.68-12.499-4.68-17.191,0L3.555,165.803c-4.74,4.74-4.74,12.427,0,17.155   c4.74,4.74,12.439,4.74,17.179,0l99.683-99.406l99.671,99.418c4.752,4.74,12.439,4.74,17.191,0c4.74-4.74,4.74-12.427,0-17.155   L129.007,57.819z" />
        </SVGIcon>
    );
}
