import React from 'react';

import { SVGIcon } from '../../UIKit/index';

export default function DCArrowBack(props) {
    return (
        <SVGIcon {...props} viewBox="0 0 512 512">
            <path d="M138.6,241.8c0,0-11,13.9-1.8,25.6l188.6,190.4c0,0,10.8,8.8,21.1,5l5.3-1.8l24.1-16.9c0,0,4.5-4.6,2.3-9.5  L197,258L375.1,82.9c0,0,2.6-4.4,0-6.5l-21.3-24.3c0,0-12.8-8.6-24.8,0L138.6,241.8z" />
        </SVGIcon>
    );
}
