import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Div } from '../index';

import './style.scss';

const Accordion = props => {
    const { customClass, reference, header, children, open, otherTags, onChange, iconPlacement } =
        props;
    const [accordionState, setAccordionState] = useState(false);
    const [mouseDownTime, setMouseDownTime] = useState(null);

    const handleAccordionToggle = () => {
        if (new Date().getTime() - mouseDownTime?.getTime() < 250) {
            if (onChange) {
                onChange();
            } else {
                setAccordionState(prevOpen => !prevOpen);
            }
        }
    };

    useEffect(() => {
        setAccordionState(open);
    }, [open]);

    return (
        <Div
            className={
                'accordionRoot ' +
                (customClass ? customClass : '') +
                (accordionState === false ? ' collapsed' : '')
            }
            ref={reference}
        >
            <Div className={'accordionRootInner'}>
                <Div className={'arIHeader ' + (accordionState === true ? 'active' : '')}>
                    <Div
                        className={`arIHInner ${iconPlacement == 'right' && 'right'}`}
                        onClick={handleAccordionToggle}
                        onMouseDown={() => {
                            setMouseDownTime(new Date());
                        }}
                    >
                        <Div className={'arIHITitle'}>{header}</Div>
                    </Div>
                    {otherTags}
                </Div>
                <Div className={'arIBody ' + (accordionState === true ? '' : 'active')}>
                    <Div className={'arIBInner'}>{children}</Div>
                </Div>
            </Div>
        </Div>
    );
};

Accordion.propTypes = {
    customClass: PropTypes.string,
    reference: PropTypes.object,
    header: PropTypes.any,
    children: PropTypes.element,
    open: PropTypes.bool,
    otherTags: PropTypes.element,
    onChange: PropTypes.func,
    iconPlacement: PropTypes.string,
};

// iconPlacement: right

export default Accordion;
