import React from 'react';

import { SVGIcon } from '../../UIKit/index';

export default function DCGraySuccessful(props) {
    return (
        <SVGIcon {...props} viewBox="0 0 463.06 499.36">
            <defs>
                <clipPath id="clip-path">
                    <rect
                        id="Rectangle_89"
                        width="344.63"
                        height="233.1"
                        transform="translate(86.62 81.39)"
                        fill="none"
                    />
                </clipPath>
                <clipPath id="clip-path-2">
                    <rect
                        id="Rectangle_90"
                        width="314.17"
                        height="244.06"
                        transform="translate(86.62 152.13)"
                        fill="none"
                    />
                </clipPath>
            </defs>
            <g id="Indian_rupee" transform="translate(-27.4 -7.26)">
                <g id="Layer_1">
                    <circle
                        id="Ellipse_9"
                        cx="230.03"
                        cy="230.03"
                        r="230.03"
                        transform="translate(28.9 8.76)"
                        fill="#fff"
                        stroke="#707171"
                        strokeMiterlimit="10"
                        strokeWidth="3"
                    />
                    <circle
                        id="Ellipse_10"
                        cx="7.62"
                        cy="7.62"
                        r="7.62"
                        transform="translate(50.02 231.17)"
                        fill="#fff"
                        stroke="#e2e2e2"
                        strokeMiterlimit="10"
                        strokeWidth="2"
                    />
                    <circle
                        id="Ellipse_11"
                        cx="14.48"
                        cy="14.48"
                        r="14.48"
                        transform="translate(53.64 177.21)"
                        fill="#fff"
                        stroke="#e2e2e2"
                        strokeMiterlimit="10"
                        strokeWidth="2"
                    />
                    <path
                        id="Path_120"
                        d="M466.58,226.39H461V220.8a.7.7,0,0,0-.7-.7h0a.72.72,0,0,0-.71.7v5.59H454a.7.7,0,0,0,0,1.4h5.58v5.6a.72.72,0,0,0,.71.7.7.7,0,0,0,.7-.7h0v-5.6h5.6a.7.7,0,1,0,0-1.4Z"
                        fill="#e2e2e2"
                        stroke="#e2e2e2"
                        strokeMiterlimit="10"
                        strokeWidth="0.75"
                    />
                    <path
                        id="Path_121"
                        d="M459.11,274h-9.32v-9.31a1.15,1.15,0,0,0-1.16-1.15h0a1.18,1.18,0,0,0-1.19,1.15V274h-9.29a1.2,1.2,0,0,0-1.18,1.19,1.18,1.18,0,0,0,1.18,1.16h9.29v9.32a1.19,1.19,0,0,0,1.19,1.16,1.16,1.16,0,0,0,1.16-1.16h0v-9.32h9.32a1.17,1.17,0,0,0,1.17-1.16,1.19,1.19,0,0,0-1.17-1.19Z"
                        fill="#e2e2e2"
                        stroke="#e2e2e2"
                        strokeMiterlimit="10"
                        strokeWidth="0.75"
                    />
                    <ellipse
                        id="_Path_"
                        cx="134.74"
                        cy="13.23"
                        rx="134.74"
                        ry="13.23"
                        transform="translate(124.19 480.16)"
                        fill="#f4f5f6"
                    />
                    <circle
                        id="Ellipse_12"
                        cx="129.7"
                        cy="129.7"
                        r="129.7"
                        transform="translate(129.23 111.98)"
                        fill="#f4f5f6"
                    />
                    <g id="Group_2" clipPath="url(#clip-path)">
                        <path
                            id="Path_122"
                            d="M267.69,165.72l23.58,5.13,1.77.21c4.63-6.63,9.46-12.18,19.39-13.95,14.31-2.58,46.12-14.32,63.67,27,0,0-19.46,5.25-29.82,8.76a48,48,0,0,1,.28,10.6c17.33-6.66,40.67-13.48,47.26-21.55s18.1-14.86,27.77-17.32a194.825,194.825,0,0,0,19-6s-.73-26.25-17.63-48.85c0,0-32.19,12.92-41.56,20.22-8.86,6.91-22.62,7.64-36.92,13.83a20.439,20.439,0,0,1-5.64,1.54c-20.41,2.54-22.79,0-30.42,1-8,1.13-17.31,7.15-24.27,9C281.9,156,275.15,159.79,267.69,165.72Z"
                            fill="#e3e2e3"
                        />
                        <path
                            id="Path_123"
                            d="M326.67,154.32s-30.67,1-38,16C304.3,174.88,321.4,161,326.67,154.32Z"
                            fill="#707171"
                            opacity="0.3"
                        />
                        <path
                            id="Path_124"
                            d="M320.37,194a.5.5,0,0,0,.13,0v-.38a2.634,2.634,0,0,1-.13.38Z"
                            fill="#ffd2a8"
                        />
                        <path
                            id="Path_125"
                            d="M247.52,221.12c5.93,6.41,14.75,13.76,18.32,9.28,2.1-2.35,3.2-5.44,2.07-8.54a8,8,0,0,0-1.4-2.44l-.64.06C259.94,220.1,253.76,220.64,247.52,221.12Z"
                            fill="#e3e2e3"
                        />
                        <path
                            id="Path_126"
                            d="M320.37,194a.5.5,0,0,0,.13,0v-.38a2.634,2.634,0,0,1-.13.38Z"
                            fill="#ffd2a8"
                        />
                        <path
                            id="Path_127"
                            d="M315.1,227.71c.15.13.32.28.51.43,2,1.62,5.84,4.26,9.65,3.88,3.61-.37,7.45-3.25,11.36-7.78,2.14-2,2.86-2.76,3.72-3.41,1.13-.87,4-4.69,5.53-12.73h0c.26-1.39.48-2.92.63-4.57h.06a48,48,0,0,0-.28-10.6c-.84.29-1.63.56-2.33.82a24.92,24.92,0,0,1-5.77,1.27c-1.68,5.46-6.25,19.64-9.42,22.86A85.423,85.423,0,0,1,315.1,227.71Z"
                            fill="#e3e2e3"
                        />
                        <path
                            id="Path_128"
                            d="M346.5,203.49c-.15,1.65-.37,3.18-.63,4.57a34.554,34.554,0,0,0,.69-4.58A.354.354,0,0,1,346.5,203.49Z"
                            fill="#ffd2a8"
                        />
                        <path
                            id="Path_129"
                            d="M315.1,227.71c.15.13.32.28.51.43,2,1.62,5.84,4.26,9.65,3.88,3.61-.37,7.45-3.25,11.36-7.78,2.14-2,2.86-2.76,3.72-3.41,1.13-.87,4-4.69,5.53-12.73h0a34.559,34.559,0,0,0,.69-4.58c17.33-6.66,40.67-13.48,47.26-21.55s18.1-14.86,27.77-17.32a194.823,194.823,0,0,0,19-6s-.05-2-.49-5.4c-5.18,2.22-13.32,5.52-19.44,7.07-9.67,2.46-21.16,9.24-27.77,17.31s-29.93,14.9-47.27,21.55a36.363,36.363,0,0,1-.68,4.59h0c-1.49,8.05-4.4,11.87-5.54,12.74-.85.65-1.57,1.42-3.71,3.4-3.91,4.53-7.76,7.42-11.37,7.78a11.33,11.33,0,0,1-6.44-1.67C317,226.58,316.05,227.15,315.1,227.71Z"
                            fill="#707171"
                            opacity="0.3"
                        />
                        <path
                            id="Path_130"
                            d="M295.08,234.27a2,2,0,0,0,.62.41,3.83,3.83,0,0,0,1.75.19,33.19,33.19,0,0,0,10.42-3.29h0c1.53-.71,3.07-1.5,4.58-2.34.88-.49,1.77-1,2.62-1.52a99.612,99.612,0,0,0,9.17-6.13c.31-.24.61-.47.92-.69l.58-.47a35.084,35.084,0,0,0,3-2.59c3.17-3.22,7.74-17.4,9.42-22.86-.55.07-1.11.12-1.68.17l-1.28.07A67.328,67.328,0,0,1,320.5,194a26.67,26.67,0,0,1-4.94,14.05c-.85,1.14-2.58,2.21-5.06,3.22,0,2.64-2.46,5.07-5.79,8.1-2.89,2.64-5.63,7.11-9.47,10.1a16.525,16.525,0,0,1-1.7,1.17c-.22.13-.33.2-.27.7C293.29,232.67,294.45,233.61,295.08,234.27Z"
                            fill="#e3e2e3"
                        />
                        <path
                            id="Path_131"
                            d="M320.37,194a.5.5,0,0,0,.13,0v-.38a2.634,2.634,0,0,1-.13.38Z"
                            fill="#ffd2a8"
                        />
                        <path
                            id="Path_132"
                            d="M320.37,194a.5.5,0,0,0,.13,0v-.38a2.634,2.634,0,0,1-.13.38Z"
                            fill="#ffd2a8"
                        />
                        <path
                            id="Path_133"
                            d="M267.91,221.86c3.64,4,9.73,9.52,16.44,10.57a14.1,14.1,0,0,0,4.14.06h0a13.281,13.281,0,0,0,1.45-.28h0a12.71,12.71,0,0,0,3.54-1.52,16.522,16.522,0,0,0,1.7-1.17c3.84-3,6.58-7.46,9.47-10.1,3.33-3,5.79-5.46,5.79-8.1a4.689,4.689,0,0,0-.28-1.57,8.229,8.229,0,0,0-1.91-2.84c-4.52-4.73-12.46-.15-12.46-.15-3.74,1.5-11.4,9.36-12.63,10.48a1.7,1.7,0,0,1-.66.26.13.13,0,0,0-.09,0c-.26.05-.55.08-.9.12h-.12c-4.68.65-9.66,1.23-14.83,1.77h-.11A8,8,0,0,1,267.91,221.86Z"
                            fill="#e3e2e3"
                        />
                        <path
                            id="Path_134"
                            d="M310.22,209.74a4.34,4.34,0,0,1,.28,1.57c0,2.63-2.45,5.06-5.78,8.09-2.9,2.64-5.63,7.11-9.48,10.11a19.441,19.441,0,0,1-1.69,1.16A13.561,13.561,0,0,1,290,232.2h0a11.4,11.4,0,0,1-1.46.27h0a13.771,13.771,0,0,1-4.14-.06c-6.71-1-12.8-6.56-16.44-10.57a8,8,0,0,0-.89-1.75c3.7,3.92,9.46,8.87,15.79,9.85A13.77,13.77,0,0,0,287,230h0a10.8,10.8,0,0,0,1.46-.27h0A13.361,13.361,0,0,0,292,228.2a18.184,18.184,0,0,0,1.7-1.16c3.85-3,6.58-7.46,9.47-10.11,3.34-3,5.8-5.46,5.79-8.1a4.269,4.269,0,0,0-.28-1.55A7.9,7.9,0,0,1,310.22,209.74Z"
                            fill="#707171"
                            opacity="0.3"
                        />
                        <path
                            id="Path_135"
                            d="M174.61,220.16a2.27,2.27,0,0,0,2,3.12c15.79.63,44-.12,70.92-2.16,6.24-.48,12.42-1,18.35-1.64l.64-.06h.11c5.17-.54,10.15-1.12,14.83-1.77h.12c.3-.06.6-.09.9-.12a.13.13,0,0,1,.09,0,1.7,1.7,0,0,0,.66-.26c1.23-1.12,8.89-9,12.63-10.48,0,0,7.94-4.58,12.46.15a8.23,8.23,0,0,1,1.91,2.84,4.69,4.69,0,0,1,.28,1.57c2.48-1,4.21-2.08,5.06-3.22A26.67,26.67,0,0,0,320.5,194a.5.5,0,0,1-.13,0,46.249,46.249,0,0,1-7.51-1.95c-5.39-2.07-9.93-1.43-14.68,3.72s-19.81,17-34,16.17S248.87,202.32,250.7,200s3-5,15.59-7.23c7.07-1.93,12.58-2.43,20.36-12.6C289,177.1,291,174,293,171.06l-1.77-.21-23.58-5.13-83.3-18.14a1.24,1.24,0,0,0-1.52,1.23C183,157.35,183,198.88,174.61,220.16Z"
                            fill="#707171"
                        />
                        <path
                            id="Path_136"
                            d="M344.53,194.73s1.24,5.65-1.58,6.16-5.42-1.21-5.11-5.61a16.93,16.93,0,0,1,6.69-.55Z"
                            fill="#fff"
                        />
                        <path
                            id="Path_137"
                            d="M339,199.72a4.17,4.17,0,0,0,4,1.18c2.82-.52,1.57-6.17,1.57-6.17a5.331,5.331,0,0,1-.72-.07c.23,1.47.57,5.14-1.68,5.54a4.39,4.39,0,0,1-3.17-.48Z"
                            fill="#fcecd2"
                            opacity="0.6"
                        />
                        <path
                            id="Path_138"
                            d="M310.13,211.88s-7.62-2.79-9.05-2.11-6.33,6.33-4.06,8.92C300,222.06,305.49,219.19,310.13,211.88Z"
                            fill="#fff"
                        />
                        <path
                            id="Path_139"
                            d="M297,218.69c3,3.37,8.47.5,13.11-6.8l-.56-.2c-4.44,6.34-9.48,8.69-12.25,5.53a2.52,2.52,0,0,1-.59-1.8,3.21,3.21,0,0,0,.29,3.27Z"
                            fill="#fcecd2"
                            opacity="0.6"
                        />
                        <path
                            id="Path_140"
                            d="M295.08,234.27a2,2,0,0,0,.62.41,3.83,3.83,0,0,0,1.75.19,33.19,33.19,0,0,0,10.42-3.29h0a114.479,114.479,0,0,0,16.37-10c.31-.24.61-.47.92-.69l.58-.47c8.88-7.41,10.67-24.58,10.73-25.28h0l-1.28.09C335.34,224.31,295.08,234.27,295.08,234.27Z"
                            fill="#707171"
                            opacity="0.3"
                        />
                        <path
                            id="Path_141"
                            d="M335,196.18s-.12,9.13-4.87,9.77a4.78,4.78,0,0,1-5.67-5.09c.38-1.16,1-5.86,1-5.86S334.23,194.64,335,196.18Z"
                            fill="#fff"
                        />
                        <path
                            id="Path_142"
                            d="M330.15,206c4.75-.65,4.87-9.77,4.87-9.77a1.54,1.54,0,0,0-.89-.59c-.09,1.73-.67,8.84-4.85,9.41a5,5,0,0,1-4-1,4.75,4.75,0,0,0,4.87,1.95Z"
                            fill="#fcecd2"
                            opacity="0.6"
                        />
                        <path
                            id="Path_143"
                            d="M286.43,214.13s-5.08,4.8-4.55,7.33l-2.44-3.56,3.78-.68Z"
                            fill="#707171"
                            opacity="0.3"
                        />
                        <path
                            id="Path_144"
                            d="M263.2,219.75a7.69,7.69,0,0,1,4.58,7.58c2-3.56-1.27-7.91-1.27-7.91Z"
                            fill="#707171"
                            opacity="0.3"
                        />
                        <path
                            id="Path_145"
                            d="M182.84,216.36c38.56.22,80.89-.65,104.06-2.67,2.85-2.69,6.65-6,9-7,0,0,7.94-4.57,12.46.15l.08.09c3.49-3.91,6.36-9.45,6.81-14.16-.91-.26-1.7-.52-2.34-.77-5.39-2.07-9.93-1.43-14.68,3.73s-19.81,17-34,16.17-15.35-9.59-13.53-11.9,3-5,15.6-7.23c7.07-1.92,12.58-2.43,20.35-12.59q1.62-2.14,3.07-4.28L188.65,155.13S190.27,188.76,182.84,216.36Z"
                            fill="#f4f5f6"
                        />
                        <path
                            id="Path_146"
                            d="M264.22,211.94c14.15.87,29.21-11,34-16.17s9.29-5.79,14.68-3.72a46.25,46.25,0,0,0,7.51,1.95,2.63,2.63,0,0,0,.15-.35v.38a67.329,67.329,0,0,0,14.72,1.18,25.261,25.261,0,0,0,3-.24A24.921,24.921,0,0,0,344,193.7c.7-.26,1.49-.53,2.33-.82,10.36-3.51,29.82-8.76,29.82-8.76-17.55-41.33-49.36-29.59-63.67-27-9.93,1.77-14.76,7.32-19.39,13.95-2,2.91-4,6-6.39,9.14-7.78,10.17-13.29,10.67-20.36,12.6-12.57,2.22-13.77,5-15.59,7.23S250.08,211.07,264.22,211.94Z"
                            fill="#e3e2e3"
                        />
                        <path
                            id="Path_147"
                            d="M266.05,195.53s2.12,9.5,1.36,10.2-10.31,3.79-12.78,1.85-4.57-9.37,1.24-10.93A30.86,30.86,0,0,1,266.05,195.53Z"
                            fill="#fff"
                        />
                        <path
                            id="Path_148"
                            d="M365,179.37s-13.36,14.27-23.91,15.14-21.45,0-29.23-2.82C334.47,192.5,332.14,198.68,365,179.37Z"
                            fill="#707171"
                            opacity="0.3"
                        />
                        <path
                            id="Path_149"
                            d="M256.85,208.2c3.73.18,9.94-1.91,10.55-2.47.76-.69-1.35-10.19-1.35-10.19h-.35C266.74,199.07,267.61,206.66,256.85,208.2Z"
                            fill="#fcecd2"
                            opacity="0.6"
                        />
                        <path
                            id="Path_150"
                            d="M407.42,106.75h8.42a1.87,1.87,0,0,1,1.87,1.87v67.91a1.88,1.88,0,0,1-1.88,1.88h-8.42V106.75Z"
                            transform="translate(848.44 119.28) rotate(157.87)"
                            fill="#f4f5f6"
                        />
                        <path
                            id="Path_151"
                            d="M436.74,181l29.62-11.15L433.18,88.23,403.55,99.39A2.77,2.77,0,0,0,402,103l31.08,76.47a2.79,2.79,0,0,0,3.66,1.53Z"
                            fill="#707171"
                        />
                        <path
                            id="Path_152"
                            d="M250.71,200c-1.24,1.56-1.07,6.1,3.61,9.13-12.15-.44-21.83-7.79-21.83-16.77,0-9.28,10.31-16.8,23-16.8,10.94,0,20.09,5.57,22.43,13-3.85,2.39-7.46,3-11.62,4.17C253.74,195,252.54,197.69,250.71,200Z"
                            fill="#707171"
                            opacity="0.3"
                        />
                    </g>
                    <g id="Group_3" clipPath="url(#clip-path-2)">
                        <path
                            id="Path_153"
                            d="M256,320c6.55.39,12.14.49,15.57.51a12,12,0,0,0,4.29-10.48C269,313.29,262.32,316.63,256,320Z"
                            fill="#e3e2e3"
                        />
                        <path
                            id="Path_154"
                            d="M232,334.48c10.85,1.92,21.33,2.91,28.45,3.44h0c6.45-1.73,9.15-8.34,8.89-15.94.46-.26.92-.53,1.32-.81a95.092,95.092,0,0,1-16.59-.11h0A243.383,243.383,0,0,0,232,334.48Z"
                            fill="#e3e2e3"
                        />
                        <path
                            id="Path_155"
                            d="M205.78,345.59c9.05,6.84,32.08,9.78,42.24,10.8,10.41-1.36,9.86-11.29,8-17.46-6.52-.14-14.05-1-25-3.75a129.918,129.918,0,0,0-12,9.48l-3.57-5.36c-1,.76-2,1.5-2.95,2.26-3.38-2.58-7.78-7.24-12-12-1,.69-2,1.36-3.08,2h0C197.07,332.11,194.61,337.13,205.78,345.59Z"
                            fill="#e3e2e3"
                        />
                        <path
                            id="Path_156"
                            d="M120,300.1v69.95l29.07-.28s8.94,2.58,28.51,5.46c14.24,2.09,28-.53,40.42-1.57-13.22-3.43-21.3-14.3-20.36-19.53,1-5.64,7.1-5.76,7.56-5.76-.28.12-4.91,2-5.92,5.37-1.31,9.81,14.26,17.11,20.84,19.76,5.84,2.55,8.91,2.84,12.5,2.55,13.7-1.14,11.78-15.35,8.62-19.75-6.21-.79-13.59-1.71-18.65-2.38-10.37-1.33-21.58-7.76-24.85-13.72-3-5.46-.74-8.2-.35-8.62-11.08,6.64-26.19,10.07-45.44,2.77-20.2-7.67-21-21-15.85-33.24A49.24,49.24,0,0,1,120,300.1Z"
                            fill="#e3e2e3"
                        />
                        <path
                            id="Path_157"
                            d="M254.12,321.06a95.1,95.1,0,0,0,16.59.11c.29-.21.57-.41.82-.62-3.43,0-9-.12-15.57-.51C255.34,320.37,254.74,320.71,254.12,321.06Z"
                            fill="#707171"
                            opacity="0.3"
                        />
                        <path
                            id="Path_158"
                            d="M256.06,338.93c-.07-.18-.14-.37-.19-.55a13.51,13.51,0,0,0,4.61-.46c-7.12-.53-17.6-1.52-28.45-3.44h0c-.34.23-.64.47-1,.7C242,337.89,249.54,338.79,256.06,338.93Z"
                            fill="#707171"
                            opacity="0.3"
                        />
                        <path
                            id="Path_159"
                            d="M197.72,340.2c3.27,6,14.48,12.39,24.85,13.72,5.06.67,12.44,1.59,18.65,2.38v-.07a25,25,0,0,0,6.84.16c-10.16-1-33.19-4-42.24-10.8-11.17-8.46-8.71-13.48-8.41-14C197,332,194.7,334.74,197.72,340.2Z"
                            fill="#707171"
                            opacity="0.3"
                        />
                        <path
                            id="Path_160"
                            d="M218,373.66h0c.71-.07,1.4-.11,2.09-.16-6.58-2.65-22.15-9.95-20.84-19.76,1-3.34,5.64-5.25,5.92-5.37-.46,0-6.54.12-7.56,5.76C196.68,359.36,204.76,370.23,218,373.66Z"
                            fill="#707171"
                            opacity="0.3"
                        />
                        <path
                            id="Path_161"
                            d="M200.5,329.53c4.22,4.79,8.62,9.45,12,12,1-.76,2-1.5,2.95-2.26,2.7-2,5.48-4,8.38-6a3.64,3.64,0,0,1,.42-.3c6.54-4.47,13.52-8.82,20.77-13a11.2,11.2,0,0,1,1.2-.69c9.17-5.28,18.75-10.28,28.42-15a721.608,721.608,0,0,1,91.43-36.75S357.62,255.68,339.2,243c-9.22,1.52-23.9,9.66-59.1,25.86q-5.39,2.47-10.78,5a10.67,10.67,0,0,1,5.55,1.71c9.22,5.53-1.45,16.74-3.75,18.77-.32.28-1,.11-1.08.46-5.78,2.51-11,5.5-16.36,7.9a96.72,96.72,0,0,1-33.51,2.86c-.73,2.62-5.39,11.51-14,19.37h0a57.158,57.158,0,0,1-5.67,4.6Z"
                            fill="#707171"
                            opacity="0.3"
                        />
                        <path
                            id="Path_162"
                            d="M356.72,264.18a723.407,723.407,0,0,0-91.43,36.75c-9.67,4.7-19.25,9.7-28.42,15a12.1,12.1,0,0,0-1.2.7c-7.25,4.17-14.23,8.52-20.77,13-.14.09-.28.18-.42.29q-4.28,2.92-8.25,5.92c-1.89-1.94-3.85-4.1-5.77-6.28a58.7,58.7,0,0,0,5.67-4.59h0c8.59-7.86,13.24-16.75,14-19.38a96.53,96.53,0,0,0,33.51-2.85c5.39-2.39,10.57-5.39,16.35-7.9.1-.35.77-.18,1.09-.46,2.3-2,13-13.24,3.75-18.77a10.65,10.65,0,0,0-5.55-1.7q5.4-2.52,10.78-5c31.05-14.28,46.14-22.3,55.59-25C350.05,254.79,356.72,264.18,356.72,264.18Z"
                            fill="#f4f5f6"
                        />
                        <path
                            id="Path_163"
                            d="M215.45,339.3l3.57,5.36a129.926,129.926,0,0,1,12-9.48c.33-.23.63-.47,1-.7h0a243.4,243.4,0,0,1,22.07-13.42h0c.62-.35,1.22-.69,1.84-1,6.36-3.41,13-6.75,19.86-10,51.64-24.32,89-34.3,89-34.3l-5.07-6.18c-11.28,3.55-37.52,11.57-85.09,34.75-9.67,4.69-19.25,9.69-28.42,15a11.205,11.205,0,0,0-1.2.69c-7.25,4.17-14.23,8.52-20.77,13a3.636,3.636,0,0,0-.42.3C220.93,335.27,218.15,337.27,215.45,339.3Z"
                            fill="#707171"
                        />
                        <path
                            id="Path_164"
                            d="M151.93,334.35c19.25,7.3,34.36,3.87,45.44-2.77h0c1.08-.65,2.09-1.32,3.08-2a57.162,57.162,0,0,0,5.67-4.58h0c8.59-7.86,13.25-16.75,14-19.37a96.719,96.719,0,0,0,33.51-2.86c5.39-2.4,9.36-4.17,15.14-6.68.09-.34,2-1.4,2.3-1.68,2.3-2,13-13.24,3.75-18.77a10.67,10.67,0,0,0-5.55-1.71h0a8.647,8.647,0,0,0-1.54.14c-2.95.53-5.39,2.44-7.49,4.06h0a13.771,13.771,0,0,1-3.64,2.3,2.931,2.931,0,0,1-.44.12,6.72,6.72,0,0,1-1.43.14c-5.14.3-15.71-1.57-22.76-1.29-7.76.27-13.24-.88-21.3-3.46S166.9,286,152.5,295.49a36.55,36.55,0,0,1-16.42,5.62C131,313.32,131.73,326.68,151.93,334.35Z"
                            fill="#e3e2e3"
                        />
                        <path
                            id="Path_165"
                            d="M256.59,280.4a11.17,11.17,0,0,0,5.36,4.54c3.55,1.36,9.68-4.49,9.68-6.33-1.06-3.09-2.65-4.22-3.87-4.61-2.95.53-5.39,2.44-7.49,4.06h0A13.77,13.77,0,0,1,256.59,280.4Z"
                            fill="#fff"
                        />
                        <path
                            id="Path_166"
                            d="M271.63,278.61c0,1.85-6.13,7.69-9.67,6.33a11.19,11.19,0,0,1-5.37-4.53,6.1,6.1,0,0,0,1.19-.57,11.11,11.11,0,0,0,4.18,2.93c3,1.15,7.81-2.84,9.25-5.22C271.36,277.88,271.5,278.23,271.63,278.61Z"
                            fill="#f4f5f6"
                        />
                        <path
                            id="Path_167"
                            d="M220.17,305.56s-12.81,34.57-53.59,34.1C172.93,336.71,198.56,338.25,220.17,305.56Z"
                            fill="#707171"
                            opacity="0.3"
                        />
                        <path
                            id="Path_168"
                            d="M206.38,350.37a1.88,1.88,0,0,0-1.73.59c-1.41,1.54-4.25,5.18-2.67,8,2.07,3.64,6.33,4.6,7.76,3.17s4.19-5.95,3.33-8.49C212.42,351.69,208.31,350.72,206.38,350.37Z"
                            fill="#fff"
                        />
                        <path
                            id="Path_169"
                            d="M213.22,355.27a14.35,14.35,0,0,1-3.46,6.84c-1.44,1.42-5.7.47-7.76-3.17a4.51,4.51,0,0,1,0-4.05,3.71,3.71,0,0,0,.45,2.15c2.06,3.64,6.32,4.59,7.75,3.17a15.461,15.461,0,0,0,3.02-4.94Z"
                            fill="#f4f5f6"
                        />
                        <path
                            id="Path_170"
                            d="M201.41,330.56s-3.25,3.23-2.62,5,3.77,6.2,7.2,4.13l2.34-1.78Z"
                            fill="#fff"
                        />
                        <path
                            id="Path_171"
                            d="M208.33,337.88,206,339.66c-3.42,2.07-6.56-2.38-7.2-4.13a2,2,0,0,1,.06-1.29c.76,1.84,3.82,6,7.14,4l1.54-1.17Z"
                            fill="#f4f5f6"
                        />
                        <path
                            id="Path_172"
                            d="M297,269.74a20.78,20.78,0,0,1-20.79,20.78,13.823,13.823,0,0,1-1.58-.06c3.37-4.33,6.74-11,.25-14.94a10.65,10.65,0,0,0-5.55-1.7q5.4-2.535,10.78-5c5.59-2.57,10.67-4.93,15.28-7.1a20.631,20.631,0,0,1,1.61,8.02Z"
                            fill="#707171"
                            opacity="0.3"
                        />
                        <path
                            id="Path_173"
                            d="M111.85,295.18H121a5.69,5.69,0,0,1,5.69,5.69v69.46A5.69,5.69,0,0,1,121,376h-9.12V295.18Z"
                            fill="#f4f5f6"
                        />
                        <path
                            id="Path_174"
                            d="M109,380.22H77.51c-9-11.44-19.69-28.71-24.73-39.26-6.21-13-14.59-33.64-17.72-50.22H109a4.53,4.53,0,0,1,3.13,1.3,4.39,4.39,0,0,1,1.29,3.12V375.8a4.42,4.42,0,0,1-4.42,4.42Z"
                            fill="#707171"
                        />
                    </g>
                    <path
                        id="Path_175"
                        d="M436.73,181l27.4-10.32,12.62-4.87c-2.67-11.83-12.55-30.47-17.65-40.45-6.37-10-13.49-21.91-25.93-37.14L403.55,99.39A2.78,2.78,0,0,0,402,103l31.09,76.47a2.79,2.79,0,0,0,3.64,1.53Z"
                        fill="#707171"
                    />
                    <path
                        id="Path_176"
                        d="M109,380.23H77.5c-9-11.45-19.69-28.71-24.72-39.26-6.22-13-14.6-33.65-17.73-50.23H109a4.51,4.51,0,0,1,3.13,1.3,4.41,4.41,0,0,1,1.29,3.13V375.8a4.42,4.42,0,0,1-4.42,4.43Z"
                        fill="#707171"
                    />
                </g>
            </g>
        </SVGIcon>
    );
}
