import React from 'react';
import { Div } from 'UIKit/index';
import DCNoResultFound from 'Assets/icons/DCNoResultFound';

const NoFound = ({ title, description, }) => {

    return (
        <Div className={'InstaReportNoData'}>
            <Div className={'IRNDIcon'}>
                <DCNoResultFound />
            </Div>
            <Div className={'IRNDTitle'}>
                {title}
            </Div>
            <Div className={'IRNDDescription'}>
                {description}
            </Div>
        </Div>
    );
};

export default NoFound;
