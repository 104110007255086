import React from 'react';

import { SVGIcon } from '../../UIKit/index';

export default function DCFillMessage(props) {
    return (
        <SVGIcon {...props} viewBox="0 0 496 496">
            <g>
                <path d="m344 59.496h-296c-26.47 0-48 21.53-48 48v172c0 26.47 21.53 48 48 48h43.08l.92 43.34c.281 13.002 15.209 20.285 25.63 12.44 78.785-59.398 67.621-50.982 73.99-55.78h152.38c26.47 0 48-21.53 48-48v-172c0-26.47-21.53-48-48-48zm-93 191h-110c-8.84 0-16-7.16-16-16s7.16-16 16-16h110c8.84 0 16 7.16 16 16s-7.16 16-16 16zm29-70h-168c-8.84 0-16-7.16-16-16s7.16-16 16-16h168c8.84 0 16 7.16 16 16s-7.16 16-16 16zm216 27v132c0 26.51-21.49 48-48 48h-43.15l-.85 33.41c-.227 8.69-7.363 15.59-16 15.59-6.322 0-1.703 1.809-82.86-49h-70.14c-12.236 0-23.414-4.602-31.897-12.165-10.987-9.796-4.097-27.974 10.623-27.974h145.57c31.041 0 56.204-25.163 56.204-56.204v-115.657c0-8.837 7.163-16 16-16h16.5c26.51 0 48 21.49 48 48z" />
            </g>
        </SVGIcon>
    );
}
