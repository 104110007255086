import { InstareportView } from 'apis/instareports/index.api';
import useToast from 'Modules/Toasts';
import React, { useEffect, useRef } from 'react';
import { useRouteMatch } from 'react-router';
import { useHistory } from 'react-router-dom';
import { CircularLoader, Div } from '../../UIKit';

const ViewInstaReport = () => {
    const route = useRouteMatch();
    const { showToast } = useToast();
    const firstRef = useRef(true);
    const history = useHistory(true);

    useEffect(() => {
        if (firstRef.current) {
            let params = route.params;
            InstareportView(params)
                .then(data => {
                    if (data?.success) history.push(`/view-report/${params.iid}`);
                    else {
                        history.push('/instareports/my-instareports');
                        showToast({ type: 'error', message: 'Something went wrong!' });
                    }
                })
                .catch(err => {
                    history.push('/instareports/my-instareports');
                    showToast({ type: 'error', message: err });
                });
            firstRef.current = false;
        }
    }, [history, route, showToast]);

    return (
        <Div>
            <CircularLoader />
        </Div>
    );
};

export default ViewInstaReport;
