import React from 'react';

import { Div, Skeleton } from '../index';

import './style.scss';

const AccordionSkeleton = ({ customClass, ...rest }) => {
    return (
        <Div className={'accordionRoot ' + (customClass ? customClass : '')} {...rest}>
            <Div className={'accordionRootInner'}>
                <Skeleton className={'arIHeader '} height={45} />
            </Div>
        </Div>
    );
};

export default AccordionSkeleton;
