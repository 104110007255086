import React from 'react';

import { SVGIcon } from '../../UIKit/index';

export default function DCReply(props) {
    return (
        <SVGIcon {...props} viewBox="0 0 512 405">
            <path d="M6.02,162.89L239.08,3.97c4.83-3.3,11.42-2.05,14.71,2.78c1.2,1.76,1.84,3.84,1.84,5.98v95.61  c163,6.75,252.23,168.05,254.37,285.43v0.54c0,5.85-4.74,10.59-10.59,10.59l0,0c-5.78,0-10.5-4.64-10.59-10.43v-0.62  c-1.19-28.47-112.6-151.25-233.16-158.22v95.07c0,5.85-4.73,10.59-10.58,10.59c-2.13,0-4.21-0.64-5.98-1.84L6.02,180.39  c-4.83-3.29-6.08-9.88-2.79-14.71C3.98,164.58,4.92,163.64,6.02,162.89z" />
        </SVGIcon>
    );
}
