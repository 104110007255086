import React from 'react';
import PropTypes from 'prop-types';
import { Div } from '../../UIKit/index';

import './style.scss';

const FooterBar = ({ customClass, children, DialogFooterBar }) => {
    return (
        <Div
            className={
                'footerBar ' +
                (customClass ? customClass : '') +
                (DialogFooterBar ? ' DialogFooterBar' : '')
            }
        >
            <Div className={'footerBarInner'}>{children}</Div>
        </Div>
    );
};

FooterBar.propTypes = {
    customClass: PropTypes.string,
    children: PropTypes.element,
    DialogFooterBar: PropTypes.bool,
};

export default FooterBar;
