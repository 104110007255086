import LogRocket from 'logrocket';
import { getAccountDetails, getUserDetails } from 'Utils/localStorageUtils';
import { authSendgrid, authTwilio, authSetupForEmail } from 'apis/root/index.api';
import { getLocalStorageAuthenticateObject } from 'Utils/auth';

function loadScript(scriptURL) {
    const script = document.createElement('script');
    script.src = scriptURL;
    script.async = true;
    script.id = 'ze-snippet';
    document.body.appendChild(script);
    return new Promise((resolve, reject) => {
        script.onload = () => {
            document.body.removeChild(script);
            resolve();
        };
        script.onerror = () => {
            document.body.removeChild(script);
            reject();
        };
    });
}

export const setupGoogleAnalytics = () => {
    if (process.env.REACT_APP_ENV === 'prod') {
        loadScript('https://www.googletagmanager.com/gtag/js?id=G-WZGHY4B9VV')
            .then(() => {
                window.dataLayer = window.dataLayer || [];
                function gtag() {
                    // eslint-disable-next-line no-undef
                    dataLayer.push(arguments);
                }
                gtag('js', new Date());
                gtag('config', 'G-WZGHY4B9VV');
            })
            .catch(err => {
                console.error(err);
            });
    }
};

export const setupScheduleCall = async () => {
    await loadScript('https://d3pw37i36t41cq.cloudfront.net/embed_v2.js')
        .then(() => {
            setTimeout(() => {
                window._wf = window._wf || [];
                window._wf.push({ id: 'u4RpWadUbPPVP57u5ZnNcezf', source: 'dashboard' });
                window._wf_initialized = false;
                window._wfStartTime = new Date().getTime();
                window.window._wf_initialized = false;
                window.window._wfStartTime = new Date().getTime();
            }, 1500);
        })
        .catch(err => {
            console.error('Error in cloudfront script', err);
        });
};

export const setUpLogRocket = () => {
    if (process.env.REACT_APP_ENV !== 'dev') {
        LogRocket.init(process.env.REACT_APP_LOGROCKET_TOKEN);
        const authObj = getLocalStorageAuthenticateObject();

        const {
            old_id,
            id,
            name,
            email,
            created,
            number_of_users,
            revenue,
            utm,
            partnership,
            domain,
            address,
            phone,
            website,
            active_site,
            owner,
            sub_accounts,
            plan,
            branding,
            stripe_connected,
            listings,
            purchased_fulfillment,
        } = authObj.auth.account;
        const affiliateTiers = {
            1: 'Starter',
            2: 'Diamond',
            3: 'Legend',
        };
        LogRocket.identify(getUserDetails().id, {
            accountName: getAccountDetails().name,
            userName: getUserDetails().name,
            email: getUserDetails().email,
            tier: getAccountDetails().plan.tier,
            custom_launcher_selector: '.talk-to-us',
            hide_default_launcher: true,
            user_hash: authObj.auth.user?.intercom_hash,
            user_id: authObj.auth.user?.old_id || authObj.auth.user?.id,
            name: authObj.auth.user?.name,
            timezone: authObj.auth.user?.preferences?.timezone,
            created_at: authObj.auth.user?.created,
            phone: authObj.auth.user?.phone,
            'V2 - User ID': authObj.auth.user?.id,
            'V2 - First Name': authObj.auth.user?.first_name,
            'V2 - Last Name': authObj.auth.user?.last_name,
            'V2 - User Role': authObj.auth.user?.role,
            'V2 - Profile Image Uploaded': !!authObj.auth.user?.image,
            'V2 - Gender': authObj.auth.user?.gender,
            'V2 - Account Owner': authObj.auth.user?.is_owner,
            'V2 - Upcoming Birthday': authObj.auth.user?.dob?.next,
            'V2 - Language': authObj.auth.user?.preferences?.preferred_lang,
            company: {
                id: old_id || id,
                created_at: created,
                size: number_of_users,
                website,
                name,
                'V2 - Account ID': id,
                'V2 - Lifetime Revenue': revenue?.lifetime,
                'V2 - Monthly Revenue': revenue?.monthly,
                'V2 - UTM Term': utm?.term,
                'V2 - UTM Source': utm?.source,
                'V2 - UTM Medium': utm?.medium,
                'V2 - UTM Campaign': utm?.campaign,
                'V2 - UTM Content': utm?.content,
                'V2 - Affiliate Referrals': partnership?.stats?.active_referrals,
                'V2 - Affiliate Referrals Total': partnership?.stats?.all_referrals,
                'V2 - Pending Affiliate Commission':
                    (partnership?.stats?.to_be_paid?.value || 0) / 100,
                'V2 - Paid Affiliate Commission':
                    (partnership?.stats?.lifetime_earning?.value || 0) / 100,
                'V2 - Affiliate Tier': affiliateTiers[partnership?.level || 1],
                'V2 - Affiliate Link': partnership?.referral_link,
                'V2 - Has Purchased Anything': !!revenue?.lifetime,
                'V2 - Custom Domain Configured': !!(domain.indexOf('.dashclicks.com') === -1),
                'V2 - Address': address?.street,
                'V2 - Address 2': address?.unit,
                'V2 - City': address?.city,
                'V2 - State': address?.state_province,
                'V2 - Zip Code': address?.postal_code,
                'V2 - Country': address?.country,
                'V2 - Phone Number': phone,
                'V2 - Website URL': website,
                'V2 - Sites Activated': active_site,
                'V2 - Account Owners First Name': owner?.first_name,
                'V2 - Account Owners Last Name': owner?.last_name,
                'V2 - Sub-Accounts': sub_accounts,
                'V2 - Active Domain': domain,
                'V2 - Active Domain without HTTPS': domain.replace('https://', ''),
                'V2 - Email Address': email,
                'V2 - Software Plan': plan.display_name,
                'V2 - Phone Number Activated': !!authObj.auth.user?.sms_numbers.length,
                'V2 - Logo Uploaded': !!branding?.image,
                'V2 - Favicon Uploaded': !!branding?.images?.favicon,
                'V2 - Login Image Uploaded': !!branding?.images?.login_image,
                'V2 - Payments Activated': !!stripe_connected,
                'V2 - Listings Activated': !!listings?.active,
                'V2 - First Time Fulfillment Purchase': !!purchased_fulfillment,
            },
        });
    }
};

export const setUpSendGrid = async () => {
    if (!getAccountDetails()?.communications?.sendgrid) {
        try {
            await authSetupForEmail();
            await authSendgrid();
        } catch (err) {
            console.error(err);
        }
    }
};

export const setupTwilio = async () => {
    if (!getAccountDetails()?.communications?.twilio) {
        try {
            await authTwilio();
        } catch (err) {
            console.error(err);
        }
    }
};

export const setupSegmetrics = async () => {
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.async = true;
    script.src = '//tag.segmetrics.io/a1XXAO.js';
    document.body.appendChild(script);
    return new Promise((resolve, reject) => {
        script.onload = () => {
            document.body.removeChild(script);
            resolve();
        };
        script.onerror = () => {
            document.body.removeChild(script);
            reject();
        };
    });
};
