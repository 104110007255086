import React, { Fragment } from 'react';

import DCDetailsCard from 'Assets/icons/DCDetailsCard';
// import DCFacebookAd1 from 'Assets/icons/DCFacebookAd1';
import DCGeoLocations from 'Assets/icons/DCGeoLocations';
import DCGoogleAd from 'Assets/icons/DCGoogleAd';
import DCPhone from 'Assets/icons/DCPhone';
import DCScores from 'Assets/icons/DCScores';
import DCSEOSearch from 'Assets/icons/DCSEOSearch';
import DCSocialShare from 'Assets/icons/DCSocialShare';
import DCTwoStar from 'Assets/icons/DCTwoStar';
import DCWebView from 'Assets/icons/DCWebView';
// import NMLogo from 'Assets/images/mmlogo.png';
import PhoneNumberView from '../../../../Modules/PhoneNumber/phoneNumberView';
import { Div, Image, Link, UserInfo } from 'UIKit/index';
import generateImage from 'Utils/generateImage';
import { calculateGrade } from 'Utils/helper';

const Sidebar = ({ handleClick, details, scores, user, branding }) => {
    return (
        <Div className={'EPSidebar '}>
            <Div className={'EPSInner'}>
                <Div
                    className={'EPSILogo'}
                    style={{
                        backgroundColor: branding?.colors?.primary,
                    }}
                >
                    <Image src={generateImage(branding?.image, branding?.name, false)} alt={''} />
                </Div>
                <Div className={'EPSIHead'}>
                    <Div className={'EPSIHTitle'}>{details?.name}</Div>
                    <Div className={'EPSIHAddress'}>
                        {[details?.address?.street, details?.address?.unit]
                            .filter(el => !!el)
                            .join(', ')}
                        <div>
                            {[
                                details?.address?.city,
                                details?.address?.state_province,
                                details?.address?.postal_code,
                                details?.address?.country,
                            ]
                                .filter(el => !!el)
                                .join(', ')}
                        </div>
                    </Div>
                </Div>
                <Div className={'EPSIBody'} id={'allDOTabs'}>
                    <Div className={'EPSIBTab active'} id={'TabID0'} onClick={() => handleClick(0)}>
                        <Div className={'EPSIBTIcon'}>
                            <DCDetailsCard />
                        </Div>
                        <Div className={'EPSIBTTitle'}>Business Details</Div>
                    </Div>
                    <Div className={'EPSIBTab'} id={'TabID1'} onClick={() => handleClick(1)}>
                        <Div className={'EPSIBTIcon'}>
                            <DCScores />
                        </Div>
                        <Div className={'EPSIBTTitle'}>Overall Scores</Div>
                        <Div
                            className={
                                'EPSIBTTag' + ' Grade' + calculateGrade(scores?.totalPercentage)
                            }
                        >
                            {scores?.totalPercentage}%
                        </Div>
                    </Div>
                    <Div className={'EPSIBTab'} id={'TabID2'} onClick={() => handleClick(2)}>
                        <Div className={'EPSIBTIcon'}>
                            <DCGeoLocations />
                        </Div>
                        <Div className={'EPSIBTTitle'}>Listings</Div>
                        <Div
                            className={
                                'EPSIBTCir Grade' + (scores?.listings ? scores?.listings : 'F')
                            }
                        >
                            {scores?.listings}
                        </Div>
                    </Div>
                    <Div className={'EPSIBTab'} id={'TabID3'} onClick={() => handleClick(3)}>
                        <Div className={'EPSIBTIcon'}>
                            <DCTwoStar />
                        </Div>
                        <Div className={'EPSIBTTitle'}>Reviews</Div>
                        <Div
                            className={
                                'EPSIBTCir Grade' + (scores?.reviews ? scores?.reviews : 'F')
                            }
                        >
                            {scores?.reviews}
                        </Div>
                    </Div>
                    <Div className={'EPSIBTab'} id={'TabID4'} onClick={() => handleClick(4)}>
                        <Div className={'EPSIBTIcon'}>
                            <DCSocialShare />
                        </Div>
                        <Div className={'EPSIBTTitle'}>Social</Div>
                        <Div
                            className={'EPSIBTCir Grade' + (scores?.social ? scores?.social : 'F')}
                        >
                            {scores?.social}
                        </Div>
                    </Div>
                    {details?.website?.length > 0 && (
                        <Fragment>
                            <Div
                                className={'EPSIBTab'}
                                id={'TabID5'}
                                onClick={() => handleClick(5)}
                            >
                                <Div className={'EPSIBTIcon'}>
                                    <DCWebView />
                                </Div>
                                <Div className={'EPSIBTTitle'}>Website</Div>
                                <Div
                                    className={
                                        'EPSIBTCir Grade' +
                                        (scores?.website ? scores?.website : 'F')
                                    }
                                >
                                    {scores?.website}
                                </Div>
                            </Div>
                            <Div
                                className={'EPSIBTab'}
                                id={'TabID6'}
                                onClick={() => handleClick(6)}
                            >
                                <Div className={'EPSIBTIcon'}>
                                    <DCSEOSearch />
                                </Div>
                                <Div className={'EPSIBTTitle'}>SEO</Div>
                                <Div
                                    className={
                                        'EPSIBTCir Grade' + (scores?.seo ? scores?.seo : 'F')
                                    }
                                >
                                    {scores?.seo}
                                </Div>
                            </Div>
                            <Div
                                className={'EPSIBTab'}
                                id={'TabID7'}
                                onClick={() => handleClick(7)}
                            >
                                <Div className={'EPSIBTIcon'}>
                                    <DCGoogleAd />
                                </Div>
                                <Div className={'EPSIBTTitle'}>Google Ads</Div>
                                <Div
                                    className={
                                        'EPSIBTCir Grade' +
                                        (scores?.googleAds ? scores?.googleAds : 'F')
                                    }
                                >
                                    {scores?.googleAds}
                                </Div>
                            </Div>
                        </Fragment>
                    )}
                    {/* <Div className={'EPSIBTab'} id={'TabID8'} onClick={() => handleClick(8)}>
                        <Div className={'EPSIBTIcon'}>
                            <DCFacebookAd1 />
                        </Div>
                        <Div className={'EPSIBTTitle'}>Facebook Ads</Div>
                    </Div> */}
                </Div>
                <Div className={'EPSIFooter'}>
                    <Div className={'EPSIFHead'}>Have a question?</Div>
                    <Div className={'EPSIFBody'}>
                        <UserInfo
                            name={`${user?.name} at ${user?.businessName}`}
                            email={user?.email}
                            image={generateImage(user?.image, user?.name, false, user?.email)}
                        />
                        {user?.phone && (
                            <Div className={'EPSIFBNum'}>
                                <Div className={'EPSIFBNIcon'}>
                                    <DCPhone />
                                </Div>
                                <Link href={`tel:${user?.phone}`} className={'EPSIFBNTitle'}>
                                    <PhoneNumberView value={user?.phone} />
                                </Link>
                            </Div>
                        )}
                    </Div>
                </Div>
            </Div>
        </Div>
    );
};

export default Sidebar;
