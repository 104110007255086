import './style.scss';

import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { useHistory, useLocation } from 'react-router-dom';

import DCArrowBack from '../../Assets/icons/DCArrowBack';
import DCArrowNext from '../../Assets/icons/DCArrowNext';
import DCCrossClose from '../../Assets/icons/DCCrossClose';
import usePrevious from '../../Hooks/usePrevious';
import useToast from '../../Modules/Toasts';
import { Button, Dialog, Div, H1, IconButton, TopBar } from '../../UIKit/index';

function useQuery() {
    return new URLSearchParams(useLocation().search);
}

function Sidebar({ stepList, numStep }) {
    return (
        <Div className={'SDBIRIStepList'}>
            {stepList?.current.map((step, index) => (
                <>
                    <Div
                        className={`SDBIRISLItem ${
                            index < numStep || numStep === stepList.current.length - 1
                                ? 'completeStep'
                                : ''
                        }${
                            index === numStep && numStep !== stepList.current.length - 1
                                ? 'activeStep'
                                : ''
                        }`}
                    >
                        <Div className={'SDBIRISLINum'}>{index + 1}</Div>
                        <Div className={'SDBIRISLIInfo'}>
                            <Div className={'SDBIRISLIITitle'}>{step?.title}</Div>
                            <Div className={'SDBIRISLIIDes'}>{step?.description}</Div>
                        </Div>
                    </Div>
                </>
            ))}
        </Div>
    );
}

const StepperDialog = props => {
    const { open, onClose, steps, onOpen, childProps, id } = props;
    const previousOpen = usePrevious(open);

    const { showToast } = useToast();
    const stepList = useRef([...steps]);
    const setStepList = data => {
        stepList.current = data;
    };
    const [numStep, setNumStep] = useState(0);
    const history = useHistory();
    const [sidebarKey, setSidebarKey] = useState(0);
    const [continueLoading, setContinueLoading] = useState(false);
    const query = useQuery();

    useEffect(() => {
        if (open && !previousOpen) {
            history.push({
                search: `step=${steps[0].path}`,
                state: history.location.state,
            });
        }
    }, [history, open, previousOpen, steps]);

    useEffect(() => {
        const path = query.get('step');
        const ind = stepList.current.findIndex(obj => obj.path === path);
        if (ind !== -1) {
            setNumStep(ind);
            // onOpen();
        } else {
            if (!open && previousOpen) onClose();
        }
    }, [onClose, onOpen, open, previousOpen, query, stepList]);

    useEffect(() => {
        const path = query.get('step');
        const ind = stepList.current.findIndex(obj => obj.path === path);
        if (ind !== -1) {
            // onOpen();
            setNumStep(0);
            history.push({
                search: `step=${stepList.current[0].path}`,
                state: id ? { ...history.location.state, id } : history.location.state,
            });
        } else {
            onClose();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const cleanup = useCallback(() => {
        stepsData.current = {};
        setCanContinue(true);
        onContinue.current = () => {};
        stepList.current = steps;
        setNumStep(0);
    }, [steps]);

    useEffect(() => {
        return () => {
            cleanup();
        };
    }, [cleanup]);

    useEffect(() => {
        if (!open && previousOpen) {
            cleanup();
        }
    }, [cleanup, open, previousOpen]);

    const goToNext = useCallback(() => {
        if (numStep < stepList.current.length - 1) {
            history.push({
                search: `?step=${stepList.current[numStep + 1].path}`,
                state: id ? { ...history.location.state, id } : history.location.state,
            });
        }
    }, [history, id, numStep]);

    const onContinueClick = useCallback(() => {
        if (onContinue.current) {
            try {
                if (!!onContinue.current && typeof onContinue.current.then === 'function') {
                    onContinue.current().then(() => {
                        goToNext();
                    });
                } else {
                    // is a normal function
                    const AsyncFunction = (async () => {}).constructor;

                    if (onContinue.current instanceof AsyncFunction && AsyncFunction !== Function) {
                        onContinue.current().then(() => {
                            goToNext();
                        });
                    } else {
                        if (onContinue.current.funcType === 'promise') {
                            onContinue
                                .current()
                                .then(() => {
                                    if (onContinue.current.shouldContinue) {
                                        goToNext();
                                    }
                                })
                                .catch(err => {
                                    throw err;
                                });
                        } else {
                            onContinue.current();
                            if (
                                Object.prototype.hasOwnProperty.call(
                                    onContinue.current,
                                    'shouldContinue',
                                )
                            ) {
                                if (onContinue.current.shouldContinue) {
                                    goToNext();
                                }
                            } else goToNext();
                        }
                    }
                }
            } catch (err) {
                if (err.message !== 'Rejected') {
                    showToast({
                        type: 'error',
                        message: err.message || err || 'Something went wrong!',
                        duration: 1500,
                    });
                    setCanContinue(true);
                }
                // snackbar
            }
        } else goToNext();
    }, [goToNext, showToast]);

    const stepsData = useRef({});

    const [canContinue, setCanContinue] = useState(true);

    const onContinue = useRef(() => {});

    const setStepsData = useCallback(data => {
        stepsData.current = { ...stepsData.current, ...data };
    }, []);

    const Component = useMemo(() => {
        return stepList.current[numStep].component;
    }, [numStep, stepList]);

    const manageSteps = useCallback(
        (id, newSteps, removedSteps = []) => {
            let tempSteps = [...stepList.current];

            let toBeRemoved = [...removedSteps, ...newSteps.map(el => el.id)];

            // cleaning up dependents
            let dependents = [...removedSteps];
            while (dependents.length > 0) {
                const item = dependents.pop();
                const newDep = [];
                tempSteps.forEach(el => {
                    if (el.parent === item) {
                        newDep.push(el.id);
                    }
                });
                toBeRemoved.push(...newDep);
                dependents = dependents.concat(newDep);
            }

            tempSteps = tempSteps.filter(el => !toBeRemoved.includes(el.id));

            if (newSteps.length > 0) {
                const indx = tempSteps.findIndex(obj => obj.id === id);
                if (indx !== -1) {
                    // add at the index indx the elements of newSteps
                    tempSteps.splice(indx + 1, 0, ...newSteps);
                }
            }

            setStepList(tempSteps);
            setSidebarKey(Date.now());
        },
        [stepList],
    );

    return (
        <Dialog dialogType={'fullWidth'} open={open} onClose={onClose}>
            <Div className={'SteperDialog'}>
                <TopBar customClass={'SDTopBar'}>
                    <Div className={'HeaderBar'}>
                        <Div className={'STBLeft'}>
                            <H1 className={'HeaderBarTitle'}>{stepList.current[numStep].title}</H1>
                            <Div className={'STBBtn'}>
                                {!(numStep === 0 || numStep === stepList.current.length - 1) && (
                                    <IconButton
                                        customClass={'BlackOutlineBtn'}
                                        onClick={() => {
                                            if (numStep > 0) {
                                                onContinue.current = () => {};
                                                setCanContinue(true);
                                                setContinueLoading(false);
                                                history.goBack();
                                                // setNumStep(numStep - 1);
                                            }
                                        }}
                                    >
                                        <DCArrowBack />
                                    </IconButton>
                                )}
                                {numStep !== stepList.current.length - 1 && (
                                    <Button
                                        buttonClass={'BlackOutlineBtn'}
                                        iconName={<DCArrowNext />}
                                        iconPlacement={'right'}
                                        disabled={!canContinue || continueLoading}
                                        onClick={onContinueClick}
                                        loading={continueLoading}
                                    >
                                        Continue
                                    </Button>
                                )}
                            </Div>
                        </Div>
                        <Div className={'STBRight'}>
                            <Div className={'HeaderBarClose'}>
                                <IconButton onClick={onClose}>
                                    <DCCrossClose />
                                </IconButton>
                            </Div>
                        </Div>
                    </Div>
                </TopBar>
                <Div className={'SteperDialogBody'}>
                    <Div className={'SDBInner'}>
                        <Div className={'SDBILeft'}>
                            <Component
                                {...stepList.current[numStep].props}
                                {...childProps}
                                {...{
                                    stepsData,
                                    setStepsData,
                                    canContinue,
                                    setCanContinue,
                                    setContinueLoading,
                                    stepList,
                                    cleanup,
                                    onContinue,
                                    handleSteps: manageSteps,
                                    doContinue: goToNext,
                                    doClose: onClose,
                                }}
                            />
                        </Div>
                        <Div className={'SDBIRight'}>
                            <Div className={'SDBIRInner'}>
                                <Sidebar key={sidebarKey} {...{ stepList, numStep }} />
                            </Div>
                        </Div>
                    </Div>
                </Div>
            </Div>
        </Dialog>
    );
};

export default StepperDialog;
