import React from 'react';

import { SVGIcon } from '../../UIKit/index';

export default function DCListingManager(props) {
    return (
        <SVGIcon {...props} viewBox="0 0 512 455.9">
            <g transform="translate(0 -28.5)">
                <path
                    fill="#4380A0"
                    d="M456.9,435.8H55.7c-11.4,0-20.7-9.3-20.7-20.7l0,0v-270c0-11.4,9.3-20.7,20.7-20.7l0,0h401.2   c11.4,0,20.7,9.3,20.7,20.7c0,0,0,0,0,0v270C477.5,426.5,468.3,435.7,456.9,435.8z"
                />
                <path
                    fill="#3B7191"
                    d="M477.5,415c0,11.4-9.2,20.7-20.6,20.8c0,0,0,0,0,0h-30.9c11.4,0,20.6-9.3,20.6-20.8c0,0,0,0,0,0   V145.2c0.1-11.4-9.1-20.7-20.5-20.8h30.8c11.4,0,20.6,9.3,20.6,20.7c0,0,0,0,0,0L477.5,415z"
                />
                <path
                    fill="#A9DBF5"
                    d="M485.3,484.4h-458c-14.8,0-26.7-12-26.7-26.7v-20.6c0-2.3,1.8-4.1,4.1-4.1c0,0,0,0,0,0h503.2   c2.3,0,4.1,1.8,4.1,4.1l0,0v20.6C512,472.4,500,484.4,485.3,484.4z"
                />
                <path
                    fill="#88C3E0"
                    d="M507.9,433H477c2.3,0,4.1,1.8,4.1,4.1v0v20.6c0,14.8-12,26.7-26.8,26.7h30.9   c14.8,0,26.8-12,26.8-26.7v-20.6C512,434.8,510.2,433,507.9,433L507.9,433z"
                />
                <path
                    fill="#F3F3F3"
                    d="M446.7,398.1c0,2.2-1.8,4-4,4H69.9c-2.2,0-4-1.8-4-4V159.2c0-2.2,1.8-4,4-4h372.8   c2.2,0,4,1.8,4,4L446.7,398.1z"
                />
                <path
                    fill="#E4E4E4"
                    d="M442.7,402.1h-26.9V155.3h26.9c2.2,0,4,1.8,4,4l0,0v238.8C446.7,400.3,444.9,402.1,442.7,402.1   C442.7,402.1,442.7,402.1,442.7,402.1z"
                />
                <path
                    fill="#88C3E0"
                    d="M291.2,457.6h-70c-6.8,0-12.3-5.5-12.3-12.3l0,0V433h94.7v12.3c0,6.8-5.5,12.4-12.3,12.4   C291.3,457.6,291.3,457.6,291.2,457.6z"
                />
                <g transform="translate(11.429 28.5)">
                    <path
                        fill="#F78E36"
                        d="M358,114.1c0,68.5-47.9,85.6-71.3,126.7s-22.2,69.1-40.9,69.1c-18,0-18.1-29.9-40.9-69.1    c-24.5-42-71.3-58.2-71.3-126.7c-0.7-62,49-112.7,111-113.4c62-0.7,112.7,49,113.4,111C358,112.5,358,113.3,358,114.1L358,114.1z"
                    />
                    <path
                        fill="#F86F1B"
                        d="M245.9,1.3c-5.2,0-10.3,0.4-15.4,1.1c55.6,7.9,96.8,55.5,96.7,111.7    c0,68.5-47.9,85.6-71.3,126.7c-9.4,18.4-17.9,37.4-25.2,56.8c3.9,7.8,8.2,12.3,15.2,12.3c18.7,0,17.5-27.8,40.9-69.1    S358,182.6,358,114.1C358.2,52,308,1.5,245.9,1.3z"
                    />
                </g>
                <circle fill="#FFD54F" cx="256.2" cy="141.9" r="43" />
            </g>
        </SVGIcon>
    );
}
