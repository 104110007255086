export const baseColumnFields = {
    person_contact_information: {
        name: { name: 'Name', section: 'contact_info' },
        email: { name: 'Email Address', section: 'contact_info' },
        phone: { name: 'Phone Number', section: 'contact_info' },
        dob: { name: 'Date of Birth', section: 'contact_info' },
        gender: { name: 'Gender', section: 'contact_info' },
        contact_source: { name: 'Contact Source', section: 'contact_info' },
        lead_status: { name: 'Contact Status', section: 'contact_info' },
        last_activity_date: { name: 'Last Activity Date', section: 'contact_info' },
        last_contacted: { name: 'Last Contacted', section: 'contact_info' },
        became_customer: { name: 'Became a Customer On', section: 'contact_info' },
        'address.street': { name: 'Street Address', section: 'general_info' },
        'address.unit': { name: 'Building Number / Apartment Number', section: 'general_info' },
        'address.city': { name: 'City', section: 'general_info' },
        'address.state_province': { name: 'State/Province', section: 'general_info' },
        'address.postal_code': { name: 'Zip/Postal Code', section: 'general_info' },
        'address.country': { name: 'Country', section: 'general_info' },
        website: { name: 'Website', section: 'general_info' },
        job_title: { name: 'Job Title', section: 'general_info' },
        preferred_lang: { name: 'Preferred Language', section: 'general_info' },
    },
    business_contact_information: {
        name: { name: 'Business Name', section: 'contact_info' },
        email: { name: 'Email Address', section: 'contact_info' },
        phone: { name: 'Phone Number', section: 'contact_info' },
        website: { name: 'Website', section: 'contact_info' },
        'address.street': { name: 'Street Address', section: 'contact_info' },
        'address.unit': { name: 'Building / Apartment / Room Number', section: 'contact_info' },
        'address.city': { name: 'City', section: 'contact_info' },
        'address.state_province': { name: 'State/Province', section: 'contact_info' },
        'address.postal_code': { name: 'Zip/Postal Code', section: 'contact_info' },
        'address.country': { name: 'Country', section: 'contact_info' },
        contact_source: { name: 'Contact Source', section: 'contact_info' },
        lead_status: { name: 'Contact Status', section: 'contact_info' },
        last_activity_date: { name: 'Last Activity Date', section: 'contact_info' },
        last_contacted: { name: 'Last Contacted', section: 'contact_info' },
        became_customer: { name: 'Became a Customer On', section: 'contact_info' },
        industry: { name: 'Industry', section: 'general_info' },
        number_of_employees: { name: 'Number of Employees', section: 'general_info' },
        annual_revenue: { name: 'Annual Revenue', section: 'general_info' },
        time_zone: { name: 'Time Zone', section: 'general_info' },
        company_type: { name: 'Company Type', section: 'general_info' },
        'social.linkedin': { name: 'LinkedIn Business Page', section: 'general_info' },
        'social.facebook': { name: 'Facebook Business Page', section: 'general_info' },
        'social.yelp': { name: 'Yelp Business Page', section: 'general_info' },
        'social.twitter': { name: 'Twitter Business Page', section: 'general_info' },
        'social.instagram': { name: 'Instagram Page', section: 'general_info' },
        'social.youtube': { name: 'YouTube Page', section: 'general_info' },
        'social.pinterest': { name: 'Pinterest Page', section: 'general_info' },
    },
};

export const contactInformationSection = [
    { id: 'pif-0', name: 'Contact Information', accessor: 'contact_info' },
    { id: 'pif-1', name: 'General Information', accessor: 'general_info' },
    { id: 'pif-2', name: 'Additional Information', accessor: 'additional_info' },
];

export const contactConfigInformation = [
    { name: 'ContactInformation', title: 'Contact Information' },
    { name: 'GeneralInformation', title: 'General Information' },
    { name: 'AdditionalInformation', title: 'Additional Information' },
];
