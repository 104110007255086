import React, { Fragment, useCallback, useState } from 'react';

import { v4 as uuidv4 } from 'uuid';

import CreateFilterDropdown from './createFilterDropdown';
import FilterCondition from './filterCondition';
import FilterItem from './filterItem';

function FilterGroup(props) {
    const {
        fields,
        preventAdding,
        groupFields: group,
        updateBaseGroup,
        onRemoveFromBaseGroup,
        groupID,
        operator,
        updateChildGroupOperator,
        setHasError,
        closeOptions,
        setCloseOptions,
    } = props;

    const [itemConditionActive, setItemConditionActive] = useState(false);
    const handleItemConditionActiveState = () => {
        setItemConditionActive(prevOpen => !prevOpen);
    };

    const addToGroup = useCallback(
        itemToAdd => {
            const newGroup = [...group, { id: uuidv4(), ...itemToAdd, isNew: true }];
            updateBaseGroup(groupID, newGroup);
        },
        [group, groupID, updateBaseGroup],
    );

    const updateGroupOperator = operator => {
        updateChildGroupOperator(groupID, operator);
    };

    const onUpdate = value => {
        const fieldID = value.id;
        const newGroup = [...group];
        const foundIndex = newGroup.findIndex(f => f.id === fieldID);
        if (foundIndex !== -1) {
            newGroup[foundIndex] = value;
            updateBaseGroup(groupID, newGroup);
        }
    };

    const removeFromGroup = id => {
        let newGroup = [...group];
        newGroup = newGroup.filter(f => f.id !== id);
        setHasError(prev => {
            const neHasError = { ...prev };
            delete neHasError[id];
            return { ...neHasError };
        });
        if (!newGroup.length) {
            onRemoveFromBaseGroup();
        } else {
            updateBaseGroup(groupID, newGroup);
        }
    };

    return group.length ? (
        <Fragment>
            {group.map((f, i) => (
                <Fragment key={f.id}>
                    <FilterItem
                        field={f}
                        setHasError={setHasError}
                        onUpdate={onUpdate}
                        onRemove={() => {
                            removeFromGroup(f.id);
                        }}
                        closeOptions={closeOptions}
                        setCloseOptions={setCloseOptions}
                        fields={fields}
                    />
                    {group.length > i + 1 ? (
                        <FilterCondition
                            activeClass={itemConditionActive}
                            handleActionState={handleItemConditionActiveState}
                            groupOperator={operator}
                            updateGroupOperator={updateGroupOperator}
                        />
                    ) : null}
                </Fragment>
            ))}
            <CreateFilterDropdown
                preventAdding={preventAdding}
                type={'iconButton'}
                addToGroup={addToGroup}
                fields={fields}
            />
        </Fragment>
    ) : null;
}

export default FilterGroup;
