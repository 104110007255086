import React from 'react';

import { SVGIcon } from '../../UIKit/index';

export default function DCKeywords(props) {
    return (
        <SVGIcon {...props} viewBox="0 0 30.391 30.336">
            <g transform="translate(0.3 0.3)">
                <path
                    d="M18.2,24.39a6.2,6.2,0,1,1,6.2-6.2A6.2,6.2,0,0,1,18.2,24.39Zm0-11.151A4.956,4.956,0,1,0,23.151,18.2,4.961,4.961,0,0,0,18.2,13.239Z"
                    transform="translate(2.868 2.868)"
                    strokeWidth="0.6"
                />
                <path
                    d="M24.413,25.032a.622.622,0,0,1-.439-.181L19.863,20.74a.619.619,0,0,1,.876-.876l4.111,4.111a.619.619,0,0,1-.437,1.057Z"
                    transform="translate(4.704 4.704)"
                    strokeWidth="0.6"
                />
                <path
                    d="M13.01,26.019H3.1a3.1,3.1,0,0,1-3.1-3.1V3.1A3.1,3.1,0,0,1,3.1,0H16.727a3.1,3.1,0,0,1,3.1,3.1v8.673a.62.62,0,0,1-1.239,0V3.1a1.861,1.861,0,0,0-1.859-1.859H3.1A1.861,1.861,0,0,0,1.239,3.1V22.922A1.861,1.861,0,0,0,3.1,24.78H13.01a.62.62,0,0,1,0,1.239Z"
                    strokeWidth="0.6"
                />
                <path
                    d="M9.62,16.015A.62.62,0,0,1,9,15.4V5.62A.62.62,0,0,1,9.62,5h3.717a.62.62,0,0,1,.62.62v5.6a.62.62,0,0,1-1.239,0V6.239H10.239V15.4A.62.62,0,0,1,9.62,16.015Z"
                    transform="translate(2.151 1.195)"
                    strokeWidth="0.6"
                />
                <path
                    d="M7.337,16.434H3.62a.62.62,0,0,1-.62-.62V9.62A.62.62,0,0,1,3.62,9H7.337a.62.62,0,0,1,.62.62v6.2A.62.62,0,0,1,7.337,16.434ZM4.239,15.2H6.717V10.239H4.239Z"
                    transform="translate(0.717 2.151)"
                    strokeWidth="0.6"
                />
                <path
                    d="M10.7,15.239H2.62A.62.62,0,0,1,2.62,14H10.7a.62.62,0,0,1,0,1.239Z"
                    transform="translate(0.478 3.346)"
                    strokeWidth="0.6"
                />
            </g>
        </SVGIcon>
    );
}
