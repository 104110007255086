import React from 'react';

import { SVGIcon } from '../../UIKit/index';

export default function DCSwitchOff(props) {
    return (
        <SVGIcon {...props} viewBox="0 0 512 512">
            <path
                fill="#90A4AE"
                d="M437.333,181.333H256c-5.891,0-10.667,4.776-10.667,10.667v128c0,5.891,4.776,10.667,10.667,10.667  h181.333C478.571,330.667,512,297.237,512,256S478.571,181.333,437.333,181.333z"
            />
            <circle fill="#CFD8DC" cx="138.667" cy="256" r="138.667" />
            <circle fill="#F44336" cx="138.667" cy="256" r="74.667" />
        </SVGIcon>
    );
}
