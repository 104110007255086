import React from 'react';

import { SVGIcon } from '../../UIKit/index';

export default function DCVertical3Dots(props) {
    return (
        <SVGIcon {...props} viewBox="0 0 512 512">
            <g>
                <circle cx="245.4" cy="79.68" r="67.44" />
                <circle cx="251.3" cy="256.43" r="67.44" />
                <circle cx="254" cy="433.18" r="67.44" />
            </g>
        </SVGIcon>
    );
}
