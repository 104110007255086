import React, { Fragment, useEffect, useRef, useState } from 'react';

import DCFillDownArray from 'Assets/icons/DCFillDownArray';
import DCFillUpArray from 'Assets/icons/DCFillUpArray';
import { Div, Link } from 'UIKit/index';

const IssueRow = ({ data }) => {
    const rowRef = useRef();
    const [accordion, setAccordion] = useState(false);

    const handleAccordion = () => {
        setAccordion(prevOpen => !prevOpen);
    };

    const rowDescription = () => {
        const [path] = data?.description.split('[Learn more]');
        const link = data?.description.split('[Learn more]').pop();
        return (
            <Fragment>
                {path}{' '}
                <Link href={link.replace(/[()]/g, '')} target="_blank">
                    Learn more
                </Link>
            </Fragment>
        );
    };

    useEffect(() => {
        /**
         * Alert if clicked on outside of element
         */
        function handleClickOutside(event) {
            if (accordion && rowRef.current && !rowRef.current.contains(event.target)) {
                setAccordion(false);
            }
        }
        document.addEventListener('click', handleClickOutside);
        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener('click', handleClickOutside);
        };
    }, [accordion]);

    return (
        <Fragment>
            <Div className={'EPCIssueRow'} reference={rowRef}>
                <Div className={'EPCIRHead'} onClick={handleAccordion}>
                    <Div className={'EPCIRTitleDate'}>
                        <Div className={'EPCIRTDTitle'}>{data?.title}</Div>
                        <Div className={'EPCIRTDTime'}>{data?.displayValue}</Div>
                        {typeof data?.details?.headings === 'object' ? (
                            <Div className={'EPCIRTDArrow'}>
                                {accordion === false ? <DCFillDownArray /> : <DCFillUpArray />}
                            </Div>
                        ) : null}
                    </Div>
                    <Div className={'EPCIRDes'}>{rowDescription()}</Div>
                </Div>
                {accordion === true ? (
                    <Fragment>
                        {typeof data?.details?.headings === 'object' ? (
                            <Div className={'EPCIRBody'}>
                                <Div className={'EPCIRBHead EPCIRBRow'}>
                                    {data.details?.headings.map(item => (
                                        <Div className={'EPCIRBRCol'} key={item.key}>
                                            {item.text || item.label}
                                        </Div>
                                    ))}
                                </Div>
                                {data?.details?.items.map(item => (
                                    <Div className={'EPCIRBRow'} key={item.key}>
                                        {data.details?.headings.map(col => (
                                            <Div className={'EPCIRBRCol'} key={'col-key' + col.key}>
                                                {typeof item[col.key] === 'number'
                                                    ? item[col.key].toFixed(2)
                                                    : typeof item[col.key] != 'object'
                                                    ? item[col.key]
                                                    : typeof item[col.key] === 'object'
                                                    ? item[col.key].url
                                                    : null}
                                            </Div>
                                        ))}
                                    </Div>
                                ))}
                            </Div>
                        ) : null}
                    </Fragment>
                ) : null}
            </Div>
        </Fragment>
    );
};

export default IssueRow;
