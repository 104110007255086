import React from 'react';

import { SVGIcon } from '../../UIKit/index';

export default function DCPhoneMessage(props) {
    return (
        <SVGIcon {...props} viewBox="0 0 512 512">
            <g>
                <path d="M224,480h64a8,8,0,0,0,0-16H224a8,8,0,0,0,0,16Z" />
                <path d="M428,120H392V36A28.031,28.031,0,0,0,364,8H148a28.031,28.031,0,0,0-28,28v84H84a28.031,28.031,0,0,0-28,28V364a28.031,28.031,0,0,0,28,28h36v84a28.031,28.031,0,0,0,28,28H364a28.031,28.031,0,0,0,28-28V392h36a28.031,28.031,0,0,0,28-28V148A28.031,28.031,0,0,0,428,120ZM285.44,24l-4.5,16H230.25l-4-16ZM136,36a12.01,12.01,0,0,1,12-12h61.75l6.49,25.94A8,8,0,0,0,224,56h63a8,8,0,0,0,7.7-5.83L302.06,24H364a12.01,12.01,0,0,1,12,12v84H136ZM84,136H428a12,12,0,0,1,11.95,11.08L256,239.06,72.05,147.08A12,12,0,0,1,84,136Zm-.92,239.95A12,12,0,0,1,72,364V164.94l93.27,46.64ZM376,476a12.01,12.01,0,0,1-12,12H148a12.01,12.01,0,0,1-12-12V392H376ZM100.94,376l78.64-157.27,72.84,36.43a8.049,8.049,0,0,0,7.16,0l72.84-36.43L411.06,376ZM440,364a12,12,0,0,1-11.08,11.95L346.73,211.58,440,164.94Z" />
            </g>
        </SVGIcon>
    );
}
