import React from 'react';

import { SVGIcon } from '../../UIKit/index';

export default function DCUser(props) {
    return (
        <SVGIcon {...props} viewBox="0 0 512 512">
            <path d="M111.02,384.64c0,0-65.86-49.99-46.02-176.95c0,0,34.12-126.17,162.67-146.8c0,0,95.22-15.87,160.29,50.78   c0,0,62.69,50.78,63.48,134.9c0,0,11.9,75.38-49.99,138.07l41.26,20.63c0,0,85.7-80.94,45.23-218.22   c0,0-28.57-119.03-164.26-164.26c0,0-134.1-42.85-245.99,70.62c0,0-134.1,145.21-11.9,311.85L111.02,384.64z" />
            <path d="M65.79,405.27c0,0,69.83,92.05,197.58,92.05c0,0,115.87,1.73,186.88-100.31l-44.1-17.31l-6.29,6.27   c0,0-12.43-7.54-26.19-7.8l-14.55-4.63l-55.81-24.07l-3.17-1.59l-0.79-35.97c0,0,13.75-9.52,19.31-43.64c0,0,8.73,1.15,10.84-12.52   c0,0,6.35-31.39,0-35.88c0,0,0-1.79-4.43-1.59c0,0,4.43-33.06,2.84-37.82c-1.59-4.76-0.26-48.14-55.02-58.98   c0,0-43.64-9.79-73.8,25.92c0,0-16.49,16.66-13.67,43.47l2.82,27.33c0,0-9.52-1.76-6.7,18.52l1.41,17.99c0,0,2.29,14.81,12.17,13.4   c0,0,3,30.51,18.87,44.44l-0.88,35.97l-42.59,19.49l-17.72,6.35c0,0-10.84,4.5-25.13,5.55l-14.81,6.88l-1.85-2.12l-23.01-1.32   L65.79,405.27z" />
        </SVGIcon>
    );
}
