import { CallAPI } from 'Utils/apiCall';
import config from 'config';
const mainUrl = `${config.REACT_APP_API_BASE_URL}/${process.env.REACT_APP_API_VERSION}/accounts`;
const api = new CallAPI();

export const getAccounts = params => {
    const url = `${mainUrl}`;
    return api.request('GET', url, null, null, null, params);
};

export const createSubAccount = body => {
    const url = `${mainUrl}/invite`;
    return api.request('POST', url, body);
};

export const resendSubAccount = async id => {
    const url = `${mainUrl}/${id}/invite`;
    return api.request('POST', url);
};

export const cancelInviteAPI = async id => {
    const url = `${mainUrl}/${id}/cancel-invite`;
    return api.request('DELETE', url);
};

export const UpdateEmailPendingInvite = async (id, body) => {
    const url = `${mainUrl}/${id}`;
    return api.request('PUT', url, body);
};

export const UpdateSubAccountEmail = async (id, body) => {
    const url = `${mainUrl}/${id}/updateownerinfo`;
    return api.request('PUT', url, body);
};
