import React from 'react';

import { SVGIcon } from '../../UIKit/index';

export default function DCLiveChat(props) {
    return (
        <SVGIcon {...props} viewBox="0 0 512 512">
            <g>
                <linearGradient
                    id="DCLiveChat1"
                    gradientUnits="userSpaceOnUse"
                    x1="256"
                    x2="256"
                    y1="512"
                    y2="0"
                >
                    <stop stopOpacity="1" stopColor="#addcff" offset="0" />
                    <stop stopOpacity="1" stopColor="#eaf6ff" offset="0.4864864864864865" />
                    <stop stopOpacity="1" stopColor="#eaf6ff" offset="0.9973" />
                </linearGradient>
                <linearGradient
                    id="DCLiveChat2"
                    gradientUnits="userSpaceOnUse"
                    x1="256"
                    x2="256"
                    y1="379.867"
                    y2="132.133"
                >
                    <stop stopOpacity="1" stopColor="#00c0ff" offset="0" />
                    <stop stopOpacity="1" stopColor="#5558ff" offset="1" />
                </linearGradient>
                <g>
                    <circle
                        cx="256"
                        cy="256"
                        fill="url(#DCLiveChat1)"
                        r="256"
                        data-original="url(#DCLiveChat1)"
                    />
                    <path
                        d="m188.294 202.914h31.726c4.886 0 8.848-3.961 8.848-8.848v-53.086c0-4.886-3.961-8.848-8.848-8.848h-106.172c-4.886 0-8.848 3.961-8.848 8.848v53.086c0 4.886 3.961 8.848 8.848 8.848h31.726l15.104 15.104c1.727 1.728 3.992 2.592 6.256 2.592s4.529-.864 6.256-2.592zm131.751 141.563h-179.654c-9.757 0-17.695-7.938-17.695-17.695s7.938-17.695 17.695-17.695h88.477c19.514 0 35.391-15.876 35.391-35.391 0-19.514-15.876-35.391-35.391-35.391h-36.913c-3.652-10.298-13.487-17.695-25.021-17.695-14.636 0-26.543 11.907-26.543 26.543s11.907 26.543 26.543 26.543c11.534 0 21.369-7.397 25.021-17.695h36.913c9.757 0 17.695 7.938 17.695 17.695s-7.938 17.695-17.695 17.695h-88.477c-19.514 0-35.391 15.876-35.391 35.391 0 19.514 15.876 35.391 35.391 35.391h179.655c3.652 10.298 13.487 17.695 25.021 17.695 14.636 0 26.543-11.907 26.543-26.543s-11.907-26.543-26.543-26.543c-11.535-.001-21.369 7.397-25.022 17.695zm78.107-106.172h-106.172c-4.886 0-8.848 3.961-8.848 8.848v53.086c0 4.886 3.961 8.848 8.848 8.848h31.726l15.104 15.104c1.727 1.728 3.992 2.592 6.256 2.592s4.529-.864 6.256-2.592l15.104-15.104h31.726c4.886 0 8.848-3.961 8.848-8.848v-53.086c0-4.887-3.961-8.848-8.848-8.848z"
                        fill="url(#DCLiveChat2)"
                        data-original="url(#DCLiveChat2)"
                    />
                </g>
            </g>
        </SVGIcon>
    );
}
