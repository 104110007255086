import React from 'react';

import { SVGIcon } from '../../UIKit/index';

export default function DCFormPending(props) {
    return (
        <SVGIcon {...props} viewBox="-1 0 512 512">
            <path d="m360 242.839844v-207.839844c0-19.300781-15.699219-35-35-35h-290c-19.300781 0-35 15.699219-35 35v381c0 19.300781 15.699219 35 35 35h227.148438c24.152343 36.710938 65.714843 61 112.851562 61 74.4375 0 135-60.5625 135-135 0-79.980469-69.660156-143.085938-150-134.160156zm-325 178.160156c-2.757812 0-5-2.242188-5-5v-381c0-2.757812 2.242188-5 5-5h290c2.757812 0 5 2.242188 5 5v214.722656c-14.539062 5.15625-27.941406 12.734375-39.71875 22.253906-3.734375-1.410156 6.6875-.976562-155.28125-.976562-8.285156 0-15 6.714844-15 15s6.714844 15 15 15h128.484375c-6.320313 9.242188-11.523437 19.308594-15.410156 30h-113.074219c-8.285156 0-15 6.714844-15 15s6.714844 15 15 15h105.953125c-2.425781 20.433594-.191406 40.886719 6.414063 60zm340 61c-57.898438 0-105-47.101562-105-105s47.101562-105 105-105 105 47.101562 105 105-47.101562 105-105 105zm0 0" />
            <path d="m135 121h150c8.285156 0 15-6.714844 15-15s-6.714844-15-15-15h-150c-8.285156 0-15 6.714844-15 15s6.714844 15 15 15zm0 0" />
            <path d="m135 181h150c8.285156 0 15-6.714844 15-15s-6.714844-15-15-15h-150c-8.285156 0-15 6.714844-15 15s6.714844 15 15 15zm0 0" />
            <path d="m135 241h150c8.285156 0 15-6.714844 15-15s-6.714844-15-15-15h-150c-8.285156 0-15 6.714844-15 15s6.714844 15 15 15zm0 0" />
            <path d="m90 106c0 8.285156-6.714844 15-15 15s-15-6.714844-15-15 6.714844-15 15-15 15 6.714844 15 15zm0 0" />
            <path d="m90 166c0 8.285156-6.714844 15-15 15s-15-6.714844-15-15 6.714844-15 15-15 15 6.714844 15 15zm0 0" />
            <path d="m90 226c0 8.285156-6.714844 15-15 15s-15-6.714844-15-15 6.714844-15 15-15 15 6.714844 15 15zm0 0" />
            <path d="m90 286c0 8.285156-6.714844 15-15 15s-15-6.714844-15-15 6.714844-15 15-15 15 6.714844 15 15zm0 0" />
            <path d="m90 346c0 8.285156-6.714844 15-15 15s-15-6.714844-15-15 6.714844-15 15-15 15 6.714844 15 15zm0 0" />
            <path d="m375 301c-8.285156 0-15 6.714844-15 15v45h-30c-8.285156 0-15 6.714844-15 15s6.714844 15 15 15h45c8.285156 0 15-6.714844 15-15v-60c0-8.285156-6.714844-15-15-15zm0 0" />
        </SVGIcon>
    );
}
