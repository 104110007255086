import React from 'react';

import { SVGIcon } from '../../UIKit/index';

export default function DCGrayAttachment(props) {
    return (
        <SVGIcon {...props} viewBox="0 0 452.21 501.2">
            <g id="Artboard_4" transform="translate(-29.97 -6.8)">
                <ellipse
                    id="_Path_"
                    cx="183.78"
                    cy="18.04"
                    rx="183.78"
                    ry="18.04"
                    transform="translate(79.26 471.92)"
                    fill="#f4f5f6"
                />
                <circle
                    id="Ellipse_1"
                    cx="222.5"
                    cy="222.5"
                    r="222.5"
                    transform="translate(31.47 8.3)"
                    fill="#fff"
                    stroke="#707171"
                    strokeMiterlimit="10"
                    strokeWidth="3"
                />
                <rect
                    id="Rectangle_1"
                    width="1.62"
                    height="24.7"
                    transform="translate(43.78 241.296) rotate(-6.67)"
                    fill="#e3e2e3"
                />
                <rect
                    id="Rectangle_2"
                    width="1.62"
                    height="12.46"
                    transform="translate(47.702 274.954) rotate(-6.67)"
                    fill="#e3e2e3"
                />
                <path
                    id="Path_1"
                    d="M156.52,191.09l-1.91-5.36a17.33,17.33,0,0,0-18.32-11.39l-76.16,8.91a17.32,17.32,0,0,0-15.19,19.21L66.62,387.8a11.87,11.87,0,0,0,13.17,10.41l272.48-31.86a11.88,11.88,0,0,0,10.41-13.18L344,193a11.88,11.88,0,0,0-13.18-10.42L172.55,201.05A15.15,15.15,0,0,1,156.52,191.09Z"
                    fill="#e3e2e3"
                />
                <rect
                    id="Rectangle_3"
                    width="87.79"
                    height="120.94"
                    transform="translate(78.035 127.652) rotate(-11.27)"
                    fill="#f4f5f6"
                />
                <rect
                    id="Rectangle_4"
                    width="87.79"
                    height="120.94"
                    transform="translate(78.035 127.652) rotate(-11.27)"
                    fill="#f4f5f6"
                />
                <path
                    id="Path_2"
                    d="M152.64,139l-53,10.57A4.75,4.75,0,0,1,94,145.88h0a4.76,4.76,0,0,1,3.73-5.59l53-10.57a4.76,4.76,0,0,1,5.59,3.73h0a4.76,4.76,0,0,1-3.68,5.55Z"
                    fill="#e3e2e3"
                />
                <path
                    id="Path_3"
                    d="M156.55,158.65l-53,10.57a4.75,4.75,0,0,1-5.58-3.73h0a4.75,4.75,0,0,1,3.73-5.59l53-10.56a4.73,4.73,0,0,1,5.58,3.72h0a4.74,4.74,0,0,1-3.73,5.59Z"
                    fill="#e3e2e3"
                />
                <path
                    id="Path_4"
                    d="M160.45,178.26l-53,10.57a4.75,4.75,0,0,1-5.58-3.73h0a4.73,4.73,0,0,1,3.72-5.58l53-10.57a4.74,4.74,0,0,1,5.58,3.73h0a4.73,4.73,0,0,1-3.72,5.58Z"
                    fill="#e3e2e3"
                />
                <path
                    id="Path_5"
                    d="M164.36,197.87l-53,10.57a4.75,4.75,0,0,1-5.59-3.73h0a4.75,4.75,0,0,1,3.73-5.58l53-10.57a4.75,4.75,0,0,1,5.58,3.73h0A4.75,4.75,0,0,1,164.36,197.87Z"
                    fill="#e3e2e3"
                />
                <path
                    id="Path_6"
                    d="M153.78,220.37l-38.54,7.69a4.76,4.76,0,0,1-5.59-3.73h0a4.75,4.75,0,0,1,3.73-5.59l38.54-7.68a4.76,4.76,0,0,1,5.59,3.73h0A4.75,4.75,0,0,1,153.78,220.37Z"
                    fill="#e3e2e3"
                />
                <rect
                    id="Rectangle_5"
                    width="87.79"
                    height="120.94"
                    transform="matrix(0.991, -0.136, 0.136, 0.991, 199.82, 99.531)"
                    fill="#f4f5f6"
                />
                <rect
                    id="Rectangle_6"
                    width="87.79"
                    height="120.94"
                    transform="matrix(0.991, -0.136, 0.136, 0.991, 199.82, 99.531)"
                    fill="#f4f5f6"
                />
                <path
                    id="Path_7"
                    d="M273.6,115.41,220,122.74a4.75,4.75,0,0,1-5.35-4.06h0a4.75,4.75,0,0,1,4.06-5.34l53.6-7.34a4.76,4.76,0,0,1,5.35,4.06h0a4.75,4.75,0,0,1-4.06,5.35Z"
                    fill="#e3e2e3"
                />
                <path
                    id="Path_8"
                    d="M276.31,135.23l-53.58,7.33a4.75,4.75,0,0,1-5.35-4.06h0a4.76,4.76,0,0,1,4.07-5.35L275,125.82a4.75,4.75,0,0,1,5.35,4.06h0a4.75,4.75,0,0,1-4.04,5.35Z"
                    fill="#e3e2e3"
                />
                <path
                    id="Path_9"
                    d="M240.21,160.35l-14.77,2a4.74,4.74,0,0,1-5.34-4.06h0a4.75,4.75,0,0,1,4.06-5.35l14.76-2a4.75,4.75,0,0,1,5.35,4.06h0a4.75,4.75,0,0,1-4.06,5.35Z"
                    fill="#e3e2e3"
                />
                <path
                    id="Path_10"
                    d="M268.83,156.43l-14.76,2a4.75,4.75,0,0,1-5.35-4.06h0a4.76,4.76,0,0,1,4.06-5.35l14.77-2a4.75,4.75,0,0,1,5.34,4.06h0a4.74,4.74,0,0,1-4.06,5.35Z"
                    fill="#e3e2e3"
                />
                <path
                    id="Path_11"
                    d="M281.73,174.85l-53.57,7.33a4.74,4.74,0,0,1-5.35-4.06h0a4.74,4.74,0,0,1,4.06-5.34l53.58-7.34a4.75,4.75,0,0,1,5.34,4.06h0a4.74,4.74,0,0,1-4.06,5.35Z"
                    fill="#e3e2e3"
                />
                <path
                    id="Path_12"
                    d="M269.8,196.67,230.87,202a4.75,4.75,0,0,1-5.35-4.06h0a4.74,4.74,0,0,1,4.06-5.35l38.93-5.33a4.75,4.75,0,0,1,5.35,4.06h0a4.75,4.75,0,0,1-4.06,5.35Z"
                    fill="#e3e2e3"
                />
                <rect
                    id="Rectangle_7"
                    width="88.145"
                    height="121.429"
                    transform="translate(118.094 112.767) rotate(-5.143)"
                    fill="#f4f5f6"
                />
                <rect
                    id="Rectangle_8"
                    width="88.145"
                    height="121.429"
                    transform="translate(118.094 112.767) rotate(-5.143)"
                    fill="#f4f5f6"
                />
                <path
                    id="Path_13"
                    d="M190.18,132.24l-53.87,4.69a4.75,4.75,0,0,1-5.15-4.32h0a4.76,4.76,0,0,1,4.32-5.14l53.88-4.69a4.74,4.74,0,0,1,5.14,4.32h0a4.75,4.75,0,0,1-4.32,5.14Z"
                    fill="#e3e2e3"
                />
                <path
                    id="Path_14"
                    d="M191.92,152.16,138,156.86a4.76,4.76,0,0,1-5.14-4.32h0a4.76,4.76,0,0,1,4.32-5.15l53.87-4.69a4.74,4.74,0,0,1,5.14,4.32h0A4.74,4.74,0,0,1,191.92,152.16Z"
                    fill="#e3e2e3"
                />
                <path
                    id="Path_15"
                    d="M193.65,172.08l-53.87,4.7a4.75,4.75,0,0,1-5.14-4.32h0a4.74,4.74,0,0,1,4.32-5.14l53.87-4.7a4.75,4.75,0,0,1,5.14,4.32h0a4.74,4.74,0,0,1-4.32,5.14Z"
                    fill="#e3e2e3"
                />
                <path
                    id="Path_16"
                    d="M195.39,192l-53.87,4.69a4.75,4.75,0,0,1-5.15-4.32h0a4.76,4.76,0,0,1,4.32-5.14l53.87-4.7a4.76,4.76,0,0,1,5.15,4.32h0A4.76,4.76,0,0,1,195.39,192Z"
                    fill="#e3e2e3"
                />
                <path
                    id="Path_17"
                    d="M182.4,213.21l-39.15,3.41a4.74,4.74,0,0,1-5.14-4.31h0a4.75,4.75,0,0,1,4.32-5.15l39.15-3.41a4.75,4.75,0,0,1,5.14,4.32h0A4.75,4.75,0,0,1,182.4,213.21Z"
                    fill="#e3e2e3"
                />
                <rect
                    id="Rectangle_9"
                    width="87.79"
                    height="120.94"
                    transform="translate(223.624 103.497) rotate(-2.76)"
                    fill="#f4f5f6"
                />
                <rect
                    id="Rectangle_10"
                    width="87.79"
                    height="120.94"
                    transform="translate(223.624 103.497) rotate(-2.76)"
                    fill="#f4f5f6"
                    opacity="0.8"
                />
                <path
                    id="Path_18"
                    d="M295.73,125.78l-54,2.6a4.74,4.74,0,0,1-5-4.51h0a4.75,4.75,0,0,1,4.52-5l54-2.61a4.75,4.75,0,0,1,5,4.51h0a4.75,4.75,0,0,1-4.52,5.01Z"
                    fill="#e3e2e3"
                />
                <path
                    id="Path_19"
                    d="M296.69,145.75l-54,2.61a4.74,4.74,0,0,1-5-4.51h0a4.75,4.75,0,0,1,4.51-5l54-2.6a4.74,4.74,0,0,1,5,4.51h0a4.74,4.74,0,0,1-4.51,4.99Z"
                    fill="#e3e2e3"
                />
                <path
                    id="Path_20"
                    d="M258.52,167.62l-14.88.71a4.74,4.74,0,0,1-5-4.51h0a4.74,4.74,0,0,1,4.51-5l14.89-.72a4.74,4.74,0,0,1,5,4.51h0A4.77,4.77,0,0,1,258.52,167.62Z"
                    fill="#e3e2e3"
                />
                <path
                    id="Path_21"
                    d="M287.38,166.22l-14.88.72a4.74,4.74,0,0,1-5-4.51h0a4.74,4.74,0,0,1,4.51-5l14.89-.72a4.74,4.74,0,0,1,5,4.51h0a4.75,4.75,0,0,1-4.52,5Z"
                    fill="#e3e2e3"
                />
                <path
                    id="Path_22"
                    d="M298.62,185.7l-54,2.61a4.76,4.76,0,0,1-5-4.52h0a4.76,4.76,0,0,1,4.52-5l54-2.6a4.74,4.74,0,0,1,5,4.51h0a4.74,4.74,0,0,1-4.52,5Z"
                    fill="#e3e2e3"
                />
                <path
                    id="Path_23"
                    d="M284.82,206.39l-39.25,1.89a4.74,4.74,0,0,1-5-4.51h0a4.74,4.74,0,0,1,4.51-5l39.25-1.9a4.77,4.77,0,0,1,5,4.52h0a4.76,4.76,0,0,1-4.51,5Z"
                    fill="#e3e2e3"
                />
                <path
                    id="Path_24"
                    d="M180.84,194.52l-1.39-5.24c-2-7.44-9.06-12-17.08-11.11l-76.15,8.91c-9.5,1.11-17,9.55-16.8,18.85l4.32,181.41c.15,6.38,5.55,10.93,12.07,10.17l272.47-31.87a13.09,13.09,0,0,0,11.52-12.92l-3.73-156.83c-.15-6.38-5.56-10.93-12.07-10.17L195.78,204.23C188.77,205.05,182.57,201,180.84,194.52Z"
                    fill="#f4f5f6"
                />
                <path
                    id="Path_25"
                    d="M180.84,194.52l-1.39-5.24c-2-7.44-9.06-12-17.08-11.11l-76.15,8.91c-9.5,1.11-17,9.55-16.8,18.85l4.32,181.41c.15,6.38,5.55,10.93,12.07,10.17l272.47-31.87a13.09,13.09,0,0,0,11.52-12.92l-3.73-156.83c-.15-6.38-5.56-10.93-12.07-10.17L195.78,204.23C188.77,205.05,182.57,201,180.84,194.52Z"
                    fill="#707171"
                />
                <path
                    id="Path_26"
                    d="M414.94,145.61,406,136.67l-4.82,14s7.55,4.07,14.77.23Z"
                    fill="#ffc3bd"
                />
                <path
                    id="Path_27"
                    d="M464.85,174.14l1.55-.13c.31,0,.53-.21.49-.4l-1.3-6.6c0-.19-.33-.33-.64-.3l-1.55.13c-.31,0-.53.21-.49.4l1.3,6.6A.62.62,0,0,0,464.85,174.14Z"
                    fill="#f4f5f6"
                />
                <path
                    id="Path_28"
                    d="M472.96,225.05l9.22-100.09L462.5,109.89l-60.08-5.54L391.99,217.59Z"
                    fill="#f4f5f6"
                />
                <path
                    id="Path_29"
                    d="M472.96,225.05l9.22-100.09L462.5,109.89l-60.08-5.54L391.99,217.59Z"
                    fill="#f4f5f6"
                    opacity="0.8"
                />
                <path id="Path_30" d="M482.18,124.96,462.5,109.89l5.16,18.04Z" fill="#f4f5f6" />
                <circle
                    id="Ellipse_2"
                    cx="24.54"
                    cy="24.54"
                    r="24.54"
                    transform="translate(370.839 190.298) rotate(-9.37)"
                    fill="#e3e2e3"
                />
                <circle
                    id="Ellipse_3"
                    cx="19.57"
                    cy="19.57"
                    r="19.57"
                    transform="matrix(0.785, -0.62, 0.62, 0.785, 371.543, 207.311)"
                    fill="#fff"
                />
                <path
                    id="Path_31"
                    d="M411.12,208.83l-8.63-1.01,1.01-8.63-6.19-.73-1.01,8.63-8.64-1.01-.72,6.19,8.63,1.01-1.01,8.64,6.19.72,1.01-8.63,8.63,1.01Z"
                    fill="#707171"
                />
                <path
                    id="Path_32"
                    d="M467.49,145.7h0a6.7,6.7,0,0,1-7.29,6.06l-43.82-4a6.7,6.7,0,0,1-6.05-7.29h0a6.68,6.68,0,0,1,7.28-6.05l43.82,4a6.7,6.7,0,0,1,6.06,7.28Z"
                    fill="#e3e2e3"
                />
                <path
                    id="Path_33"
                    d="M452.56,163.79h0a6.69,6.69,0,0,1-7.28,6.05L414.65,167a6.71,6.71,0,0,1-6.06-7.29h0a6.7,6.7,0,0,1,7.29-6.05l30.62,2.81a6.7,6.7,0,0,1,6.06,7.32Z"
                    fill="#e3e2e3"
                />
                <circle
                    id="Ellipse_4"
                    cx="13.09"
                    cy="13.09"
                    r="13.09"
                    transform="translate(402.86 20.7)"
                    fill="#fff"
                    stroke="#e2e2e2"
                    strokeMiterlimit="10"
                    strokeWidth="2"
                />
                <path
                    id="Path_34"
                    d="M461.38,76.51h-8.9V67.62a1.11,1.11,0,0,0-1.11-1.11h0a1.12,1.12,0,0,0-1.13,1.11v8.89h-8.88a1.14,1.14,0,0,0-1.12,1.14,1.11,1.11,0,0,0,1.12,1.1h8.88v8.91a1.13,1.13,0,0,0,1.13,1.11,1.11,1.11,0,0,0,1.11-1.11h0V78.75h8.9a1.11,1.11,0,0,0,1.12-1.1,1.14,1.14,0,0,0-1.12-1.14Z"
                    fill="#e2e2e2"
                    stroke="#e2e2e2"
                    strokeMiterlimit="10"
                    strokeWidth="0.75"
                />
                <path
                    id="Path_35"
                    d="M108.87,425.11H99.06V415.3a1.23,1.23,0,0,0-1.23-1.22h0a1.25,1.25,0,0,0-1.24,1.22v9.81H86.8a1.23,1.23,0,1,0,0,2.46h9.79v9.82a1.24,1.24,0,0,0,1.24,1.22,1.22,1.22,0,0,0,1.23-1.22h0v-9.82h9.81a1.23,1.23,0,0,0,0-2.46Z"
                    fill="#e2e2e2"
                    stroke="#e2e2e2"
                    strokeMiterlimit="10"
                    strokeWidth="0.75"
                />
            </g>
        </SVGIcon>
    );
}
