import React from 'react';

import { SVGIcon } from '../../UIKit/index';

export default function DCGrayNotes(props) {
    return (
        <SVGIcon {...props} viewBox="0 0 494.04 450">
            <g id="Notes_" transform="translate(-9.32 -30.25)">
                <circle
                    id="Ellipse_9"
                    cx="201.48"
                    cy="201.48"
                    r="201.48"
                    transform="translate(10.82 31.75)"
                    fill="#fff"
                    stroke="#707171"
                    strokeMiterlimit="10"
                    strokeWidth="3"
                />
                <rect
                    id="Rectangle_89"
                    width="78.47"
                    height="78.47"
                    transform="matrix(0.725, -0.688, 0.688, 0.725, 131.867, 167.332)"
                    fill="#e3e2e3"
                    opacity="0.5"
                />
                <rect
                    id="Rectangle_90"
                    width="15.8"
                    height="78.47"
                    transform="matrix(0.725, -0.688, 0.688, 0.725, 177.319, 124.2)"
                    fill="#707171"
                    opacity="0.5"
                />
                <rect
                    id="Rectangle_91"
                    width="78.47"
                    height="78.47"
                    transform="translate(107.29 195.718) rotate(-11.98)"
                    fill="#e3e2e3"
                />
                <rect
                    id="Rectangle_92"
                    width="78.47"
                    height="15.8"
                    transform="translate(107.283 195.714) rotate(-11.98)"
                    fill="#707171"
                    opacity="0.5"
                />
                <path
                    id="Path_120"
                    d="M403.71,199.22S396.7,186,407.2,181c0,0,7.4-2.47,12.63-1,0,0-10.36,10.92-8.21,12.72s10.67-13,10.67-13,15.14,1.9,19.39,5.26c0,0-15,7.52-12.47,8.73s18.5-5.87,18.5-5.87,13.26,5.45,10.64,13.69-8,9.78-10,9.08-13.2-8.1-13.85-6.45,5.44,8.9,10.53,10.32c0,0-12.13,8.53-17.9,4s-5.21-9.57-8.82-12.32-7.06-3.51-5.82-1.26,8.12,5.71,9.51,10.3,2.83,7.81-3.62,5.3-16.23-9.85-15.45-15.56Z"
                    fill="#e3e2e3"
                />
                <path
                    id="Path_121"
                    d="M451.52,200.23s-37.13-9.87-47.81-1a27.81,27.81,0,0,1-9.82,8.79l-1.41,2.19s8-5.38,10.44-5.28C402.92,204.92,402.28,190.84,451.52,200.23Z"
                    fill="#fff"
                />
                <path
                    id="Path_122"
                    d="M393.89,208a24.09,24.09,0,0,0-12.32,14.63l1.43.46s5-10.41,10.64-13.66S393.89,208,393.89,208Z"
                    fill="#fff"
                />
                <path
                    id="Path_123"
                    d="M364.14,158.38s-25.48-13.29-14.35-32.69c0,0,9-12,18.94-15.28,0,0-4.5,28.56.86,29.07s2.73-32.19,2.73-32.19,26-13.48,36.4-12.79c0,0-15.54,28.23-10.22,27.4s22.87-29.45,22.87-29.45,26.92-5.81,31.74,10.1-1.95,24.14-5.93,25.26-29.72,1.55-29,4.87,18.31,8.15,27.91,4.86c0,0-9.91,26.72-24,25.8s-18.68-9.48-27.39-9.89-15,2.12-10.57,4.33,19.07.2,26.26,5.95,13,9.27,0,12.33-36.39,2.09-41.37-7.8Z"
                    fill="#f4f5f6"
                />
                <path
                    id="Path_124"
                    d="M440.9,107.93s-69.5,24.81-76.76,50.45c0,0,.67,11.35-6,24.59l.15,5s6.84-17.26,10.78-19.72C369.11,168.26,352.75,146.67,440.9,107.93Z"
                    fill="#e3e2e3"
                />
                <path
                    id="Path_125"
                    d="M358.18,183s-10.33,16.75-3.57,36.57l2.76-.83s-3.35-22,2-33.2S358.18,183,358.18,183Z"
                    fill="#e3e2e3"
                />
                <path
                    id="Path_126"
                    d="M284.1,266.76s-.24-21.48-16.93-20.62c0,0-11,2-16.6,7.46,0,0,20.57,6.66,19.09,10.41s-22.38-9.08-22.38-9.08-17.8,12.79-20.85,19.95c0,0,24.08-.81,21.72,2.46s-27.37,5.28-27.37,5.28-13,16-4,24.58,16.76,6.87,18.85,4.59,11.1-19.29,13-17.65-.76,15-6.2,20.25c0,0,21.16,2.43,25.31-7.27s0-15.66,2.68-21.6,6.48-9.28,6.46-5.59-6.32,12.78-4.91,19.53,1.78,11.79,8.2,4.19,13.71-23.56,8.8-30.22Z"
                    fill="#e3e2e3"
                />
                <path
                    id="Path_127"
                    d="M224.49,300.88s40.06-37.95,59.61-34.12a39.93,39.93,0,0,0,18.42,4.35l3.29,1.8s-13.83-1.28-16.8.52C289,273.43,280.15,255.21,224.49,300.88Z"
                    fill="#e3e2e3"
                />
                <path
                    id="Path_128"
                    d="M302.52,271.11s14.67-1.22,25.59,10l-1.48,1.56s-13.51-9.67-22.81-9.92S302.52,271.11,302.52,271.11Z"
                    fill="#e3e2e3"
                />
                <path
                    id="Path_129"
                    d="M274.59,196.82s12.74-6.64,7.18-16.34c0,0-4.51-6-9.48-7.64,0,0,2.25,14.28-.43,14.54s-1.36-16.1-1.36-16.1-13-6.74-18.2-6.39c0,0,7.77,14.11,5.11,13.69S246,163.86,246,163.86s-13.46-2.91-15.87,5,1,12.07,3,12.63,14.86.77,14.48,2.44-9.16,4.07-14,2.42c0,0,5,13.36,12,12.9s9.33-4.74,13.69-4.94,7.49,1.06,5.28,2.16-9.53.1-13.13,3-6.49,4.63,0,6.16,18.19,1.05,20.68-3.9Z"
                    fill="#f4f5f6"
                />
                <path
                    id="Path_130"
                    d="M236.21,171.6S271,184,274.59,196.82a26.61,26.61,0,0,0,3,12.3l-.08,2.5s-3.42-8.63-5.39-9.86C272.1,201.76,280.28,191,236.21,171.6Z"
                    fill="#e3e2e3"
                />
                <path
                    id="Path_131"
                    d="M277.57,209.12a23.18,23.18,0,0,1,1.78,18.29L278,227s1.68-11-1-16.6S277.57,209.12,277.57,209.12Z"
                    fill="#e3e2e3"
                />
                <path
                    id="Path_132"
                    d="M306.45,118.85l-1.25-1a3.16,3.16,0,0,1-.42-4.44l8.74-10.54a3.15,3.15,0,0,1,4.44-.41l1.8,1.49a.637.637,0,0,1-.79,1l-1.8-1.49a1.91,1.91,0,0,0-2.7.25l-8.74,10.54a1.92,1.92,0,0,0,.26,2.7l1.25,1a1.93,1.93,0,0,0,2.71-.25l5.8-7a.62.62,0,0,1,.87-.09.63.63,0,0,1,.08.87l-5.8,7a3.17,3.17,0,0,1-4.45.37Z"
                    fill="#707171"
                />
                <path
                    id="Path_133"
                    d="M298.07,130.07l-2.25-1.87a5.11,5.11,0,0,1-.42-7.2l13.13-15.84a.637.637,0,0,1,1,.79L296.4,121.79a3.87,3.87,0,0,0,.26,5.46l2.25,1.87a3.88,3.88,0,0,0,5.41-.76l15-18a3.87,3.87,0,0,0-.26-5.46.637.637,0,0,1,.79-1,5.11,5.11,0,0,1,.42,7.2l-14.95,18a5.12,5.12,0,0,1-7.25.97Z"
                    fill="#707171"
                />
                <path
                    id="Path_134"
                    d="M156.29,136.69l-1.4.84a3.17,3.17,0,0,1-4.34-1.06l-7.09-11.7a3.17,3.17,0,0,1,1.07-4.34l2-1.21a.62.62,0,0,1,.64,1.06l-2,1.21a1.92,1.92,0,0,0-.65,2.64l7.09,11.7a1.94,1.94,0,0,0,2.64.65l1.4-.85a1.93,1.93,0,0,0,.64-2.64l-4.7-7.77a.62.62,0,0,1,.2-.85.63.63,0,0,1,.85.21l4.71,7.77a3.17,3.17,0,0,1-1.06,4.34Z"
                    fill="#707171"
                />
                <path
                    id="Path_135"
                    d="M164.14,148.28l-2.5,1.52a5.13,5.13,0,0,1-6.95-2L144,130.24a.62.62,0,0,1,.21-.85.63.63,0,0,1,.85.21l10.66,17.6a3.89,3.89,0,0,0,5.25,1.54l2.5-1.52a3.88,3.88,0,0,0,1.06-5.36l-12.15-20a3.87,3.87,0,0,0-5.25-1.54.62.62,0,0,1-.64-1.06,5.11,5.11,0,0,1,6.95,2l12.14,20A5.11,5.11,0,0,1,164.14,148.28Z"
                    fill="#707171"
                />
                <rect
                    id="Rectangle_93"
                    width="123.16"
                    height="120.02"
                    rx="11.88"
                    transform="translate(380.2 231.41)"
                    fill="#707171"
                />
                <rect
                    id="Rectangle_94"
                    width="123.16"
                    height="114.89"
                    rx="11.63"
                    transform="translate(375.24 233.37)"
                    fill="#fff"
                    opacity="0.3"
                />
                <rect
                    id="Rectangle_95"
                    width="123.16"
                    height="120.01"
                    rx="2.53"
                    transform="translate(365.32 231.41)"
                    fill="#e3e2e3"
                />
                <rect
                    id="Rectangle_96"
                    width="7.65"
                    height="120.01"
                    transform="translate(465.53 231.41)"
                    fill="#707171"
                />
                <rect
                    id="Rectangle_97"
                    width="143.94"
                    height="198.52"
                    transform="translate(271.31 152.92)"
                    fill="#707171"
                />
                <rect
                    id="Rectangle_98"
                    width="143.94"
                    height="198.52"
                    transform="translate(267.6 152.92)"
                    fill="#fff"
                    opacity="0.3"
                />
                <rect
                    id="Rectangle_99"
                    width="124.2"
                    height="171.29"
                    transform="translate(277.47 166.53)"
                    fill="#fff"
                />
                <rect
                    id="Rectangle_100"
                    width="54.68"
                    height="21.81"
                    rx="8.31"
                    transform="translate(312.23 150.58)"
                    fill="#707171"
                />
                <rect
                    id="Rectangle_101"
                    width="124.2"
                    height="0.52"
                    transform="translate(277.47 174.3)"
                    fill="#e3e2e3"
                />
                <rect
                    id="Rectangle_102"
                    width="124.2"
                    height="0.52"
                    transform="translate(277.47 183.3)"
                    fill="#e3e2e3"
                />
                <rect
                    id="Rectangle_103"
                    width="124.2"
                    height="0.52"
                    transform="translate(277.47 192.3)"
                    fill="#e3e2e3"
                />
                <rect
                    id="Rectangle_104"
                    width="124.2"
                    height="0.52"
                    transform="translate(277.47 201.3)"
                    fill="#e3e2e3"
                />
                <rect
                    id="Rectangle_105"
                    width="124.2"
                    height="0.52"
                    transform="translate(277.47 210.31)"
                    fill="#e3e2e3"
                />
                <rect
                    id="Rectangle_106"
                    width="124.2"
                    height="0.52"
                    transform="translate(277.47 219.31)"
                    fill="#e3e2e3"
                />
                <rect
                    id="Rectangle_107"
                    width="124.2"
                    height="0.52"
                    transform="translate(277.47 228.31)"
                    fill="#e3e2e3"
                />
                <rect
                    id="Rectangle_108"
                    width="124.2"
                    height="0.52"
                    transform="translate(277.47 237.31)"
                    fill="#e3e2e3"
                />
                <rect
                    id="Rectangle_109"
                    width="124.2"
                    height="0.52"
                    transform="translate(277.47 246.31)"
                    fill="#e3e2e3"
                />
                <rect
                    id="Rectangle_110"
                    width="124.2"
                    height="0.52"
                    transform="translate(277.47 256.11)"
                    fill="#e3e2e3"
                />
                <rect
                    id="Rectangle_111"
                    width="124.2"
                    height="0.52"
                    transform="translate(277.47 265.11)"
                    fill="#e3e2e3"
                />
                <rect
                    id="Rectangle_112"
                    width="124.2"
                    height="0.52"
                    transform="translate(277.47 274.11)"
                    fill="#e3e2e3"
                />
                <rect
                    id="Rectangle_113"
                    width="124.2"
                    height="0.52"
                    transform="translate(277.47 283.11)"
                    fill="#e3e2e3"
                />
                <rect
                    id="Rectangle_114"
                    width="124.2"
                    height="0.52"
                    transform="translate(277.47 292.11)"
                    fill="#e3e2e3"
                />
                <rect
                    id="Rectangle_115"
                    width="124.2"
                    height="0.52"
                    transform="translate(277.47 301.12)"
                    fill="#e3e2e3"
                />
                <rect
                    id="Rectangle_116"
                    width="124.2"
                    height="0.52"
                    transform="translate(277.47 310.12)"
                    fill="#e3e2e3"
                />
                <rect
                    id="Rectangle_117"
                    width="124.2"
                    height="0.52"
                    transform="translate(277.47 319.12)"
                    fill="#e3e2e3"
                />
                <rect
                    id="Rectangle_118"
                    width="124.2"
                    height="0.52"
                    transform="translate(277.47 328.12)"
                    fill="#e3e2e3"
                />
                <path
                    id="Path_136"
                    d="M119.35,261.31c.66-.47,2.16-1.5,2.23-1.27a1,1,0,0,1-.2.74,6.51,6.51,0,0,1,4-.15,1.66,1.66,0,0,1,1.07.71.71.71,0,0,1-.25,1.12c-1.11.69-.79.28-2.33,1.28s-3.43,1.27-4.56.56l-1.52-.25-.18-2.49S118.47,261.45,119.35,261.31Z"
                    fill="#e3e2e3"
                />
                <path
                    id="Path_137"
                    d="M79.65,240.41s3.57,4.23,8.12,2.46a13.709,13.709,0,0,0,1-4.11v-.62c0-2,.09-3.07.09-3.07h0c-.2-.08-6.95-2.85-6.94-2.62C82,237.76,79.65,240.41,79.65,240.41Z"
                    fill="#e3e2e3"
                />
                <path
                    id="Path_138"
                    d="M82.58,233.71a10.55,10.55,0,0,0,6.19,4.43c0-2,.09-3.07.09-3.07h0C88.67,235,82.39,233.36,82.58,233.71Z"
                    fill="#707171"
                    opacity="0.3"
                />
                <path
                    id="Path_139"
                    d="M90.27,234.87s-7.59,1.78-8.62-3-2.72-7.7,2.08-9,6.14.26,6.87,1.76S92.22,234.28,90.27,234.87Z"
                    fill="#e3e2e3"
                />
                <path
                    id="Path_140"
                    d="M81.91,233.2c-.11.07-1.74.57-4.83-5.59a5.34,5.34,0,0,1,.31-4.9c1.14-1.85,5.15-3.31,8.48-3.94S90,220,90.79,221.9a10.15,10.15,0,0,0,3.2,3.61c-2.87,1.6-6.34-.69-7.53-.32s-1.63,2.27-1.72,2.88-.23,1.11-1.73.57-.69,2.13-.44,2.64C82.57,231.28,82.77,232.65,81.91,233.2Z"
                    fill="#707171"
                />
                <path
                    id="Path_141"
                    d="M62.36,351.45H57.77c-1,0-2-1-2.3-2.73s-2.25-2.73-2.68-4.18,1.77-3.48,1.77-3.48l.37-.47.38-.49L59,341s.09.86.14,2c0,.79.06,1.71,0,2.52-.11,1.93.54,4,2.25,4.38S62.36,351.45,62.36,351.45Z"
                    fill="#4c4c4c"
                />
                <path
                    id="Path_142"
                    d="M94.34,351.45H82.17a1.157,1.157,0,0,1-.26-.08c-.82-.4-.56-3.52-.46-4.48v-.28s4.28-3.43,4.87-.8l.06.24h0c.75,2.45,4.07,3.57,6,3.88a2.09,2.09,0,0,1,1.96,1.52Z"
                    fill="#4c4c4c"
                />
                <path
                    id="Path_143"
                    d="M91.89,281.23s-.65,8.11-1.42,17.86c-1,13.25-2.28,29.52-2.41,32.61-.21,5.35-1.07,13.91-1.07,13.91s-2,2-6.1,1.18a139.481,139.481,0,0,1-4.66-25.16v-.64c-1-13.16-2.46-40.89-2.46-40.89Z"
                    fill="#7f7f7f"
                />
                <path
                    id="Path_144"
                    d="M91.89,281.23s-.65,8.11-1.42,17.86L76.23,321.63v-.64c-1-13.16-2.46-40.89-2.46-40.89Z"
                    fill="#fff"
                    opacity="0.3"
                />
                <path
                    id="Path_145"
                    d="M88.92,282.19S77.2,311.57,69.49,325.38,59.38,343,59.38,343s-4.42-1.76-4.82-3c0,0,2.95-26.65,8.11-33.15,2.91-3.67,6.34-14.69,6.5-18.55s1-11.48,1-11.48Z"
                    fill="#7f7f7f"
                />
                <path
                    id="Path_146"
                    d="M62.19,276.85l-.13,1.89a4.07,4.07,0,0,1-2.24,5.08s-1.5.6-1.56-.56.13-.73,0-2.36a3.775,3.775,0,0,1,1.61-3.71l.89-1Z"
                    fill="#e3e2e3"
                />
                <path
                    id="Path_147"
                    d="M60.82,278.23c1.3.41,1.51.32,1.51.32s6.31-11.5,7-12.55,13.41-12.89,13.41-12.89l-3.28-12.23s-14.52,18.8-16.09,21.5c-2.59,4.46-4.11,14.81-4.11,14.81a3.86,3.86,0,0,0,1.56,1.04Z"
                    fill="#707171"
                />
                <path
                    id="Path_148"
                    d="M94.36,257.91c0,2.52-.19,5.34-.39,8.21v.51c-.13,1.78-.26,3.56-.42,5.3-.06.69-.13,1.38-.2,2.06v.09a2.945,2.945,0,0,1-.07.78c-.64,6.5-1.31,11.64-1.43,11.72a.74.74,0,0,1-.5.13c-1.71-.22-6.8.17-11-.74a31.509,31.509,0,0,1-9.75-4c-.43-.25-1.4-.92-1.32-1.42s.22-1.34.41-2.33a126.65,126.65,0,0,0,2.09-16.38c.07-1.3.18-2.58.32-3.84a57.329,57.329,0,0,1,1.7-9.17c1.42-5.15,4.45-9,7.08-10.54.35-.2,1.28.9,3,2s3.06.55,4.67.41L90.1,243a15.74,15.74,0,0,1,4,8.42c0,.27.07.57.1.89a54.342,54.342,0,0,1,.16,5.6Z"
                    fill="#707171"
                />
                <path
                    id="Path_149"
                    d="M94.36,257.91c0,2.52-.19,5.34-.39,8.21v.51c-9.75-6.1-11.25-17.26-11.25-17.26a26.6,26.6,0,0,1,11.54,2.89,54.341,54.341,0,0,1,.1,5.65Z"
                    fill="#fff"
                    opacity="0.3"
                />
                <path
                    id="Path_150"
                    d="M119,262.72c.1-1.45-.65-1.69-.65-1.69s-14.69-2.66-15.94-3-11.93-11.51-11.93-11.51-4.1-4.62-7.45-3.6a6.65,6.65,0,0,0-1.48,11.87c5,3.08,15,9.14,17.42,9.83,5.26,1.55,19.4.13,19.4.13a4.62,4.62,0,0,0,.63-2.03Z"
                    fill="#707171"
                />
                <rect
                    id="Rectangle_119"
                    width="116.75"
                    height="68.32"
                    transform="translate(117.19 283.11)"
                    fill="#e3e2e3"
                />
                <rect
                    id="Rectangle_120"
                    width="9.4"
                    height="1.92"
                    rx="0.73"
                    transform="translate(114.7 286.49)"
                    fill="#707171"
                />
                <rect
                    id="Rectangle_121"
                    width="9.4"
                    height="1.92"
                    rx="0.73"
                    transform="translate(114.7 290.16)"
                    fill="#707171"
                />
                <rect
                    id="Rectangle_122"
                    width="9.4"
                    height="1.92"
                    rx="0.73"
                    transform="translate(114.7 300.06)"
                    fill="#707171"
                />
                <rect
                    id="Rectangle_123"
                    width="9.4"
                    height="1.92"
                    rx="0.73"
                    transform="translate(114.7 303.73)"
                    fill="#707171"
                />
                <rect
                    id="Rectangle_124"
                    width="9.4"
                    height="1.92"
                    rx="0.73"
                    transform="translate(114.7 313.63)"
                    fill="#707171"
                />
                <rect
                    id="Rectangle_125"
                    width="9.4"
                    height="1.92"
                    rx="0.73"
                    transform="translate(114.7 317.3)"
                    fill="#707171"
                />
                <rect
                    id="Rectangle_126"
                    width="9.4"
                    height="1.92"
                    rx="0.73"
                    transform="translate(114.7 327.2)"
                    fill="#707171"
                />
                <rect
                    id="Rectangle_127"
                    width="9.4"
                    height="1.92"
                    rx="0.73"
                    transform="translate(114.7 330.87)"
                    fill="#707171"
                />
                <rect
                    id="Rectangle_128"
                    width="9.4"
                    height="1.92"
                    rx="0.73"
                    transform="translate(114.7 340.77)"
                    fill="#707171"
                />
                <rect
                    id="Rectangle_129"
                    width="9.4"
                    height="1.92"
                    rx="0.73"
                    transform="translate(114.7 344.44)"
                    fill="#707171"
                />
                <path id="Path_151" d="M272.04,272.97v78.47H193.57V272.97Z" fill="#707171" />
                <path
                    id="Path_152"
                    d="M272.04,272.97v78.47H193.57V272.97Z"
                    fill="#fff"
                    opacity="0.3"
                />
                <path
                    id="Path_153"
                    d="M281.92,273v14.8s.44,27.46,1.42,36.84c1.86,17.72,7.28,26.8,7.28,26.8H212s-6.66-9.91-8.49-25.63c-1.1-9.44,0-38,0-38V273Z"
                    fill="#e3e2e3"
                />
                <rect
                    id="Rectangle_130"
                    width="78.47"
                    height="14.83"
                    transform="translate(203.46 272.97)"
                    fill="#707171"
                    opacity="0.5"
                />
                <path
                    id="Path_154"
                    d="M318.31,246.1l-.89-1.68s.44-3-3.3-4.87a1.82,1.82,0,0,0-2.14.56c-.71,1,1.35.84,1.24,2.6a3.25,3.25,0,0,0,1.89,3.36l1,1.11Z"
                    fill="#e3e2e3"
                />
                <path
                    id="Path_155"
                    d="M353.66,242.09A29.62,29.62,0,0,1,332.77,263c-5.52,1.61-18.06-16.56-18.06-16.56s.44-1.67,3.2-1.29c0,0,12.21,8.08,13.75,9,1.36.78,10.33-10,14.42-11.75a10.46,10.46,0,0,1,7.58-.31Z"
                    fill="#707171"
                />
                <rect
                    id="Rectangle_131"
                    width="3.304"
                    height="16.632"
                    transform="translate(305.912 241.336) rotate(-81.384)"
                    fill="#707171"
                />
                <path id="Path_156" d="M302.55,240.04l3.57,2.22.5-3.27Z" fill="#e3e2e3" />
                <path
                    id="Path_157"
                    d="M322.09,242.33h3.3V243a1.22,1.22,0,0,1-1.22,1.22h-.78a1.3,1.3,0,0,1-1.3-1.3v-.59Z"
                    transform="matrix(0.152, -0.988, 0.988, 0.152, 34.11, 526.33)"
                    fill="#e3e2e3"
                />
                <path
                    id="Path_158"
                    d="M374.77,341.22s2.19,2,1.76,3.42-2.4,2.37-2.67,4.11-1.34,2.69-2.3,2.69H367s-.71-1.23,1-1.6,2.36-2.41,2.25-4.3a33,33,0,0,1,.16-4.43l3.64-.84Z"
                    fill="#4c4c4c"
                />
                <path
                    id="Path_159"
                    d="M347.86,346.39s.39,4.56-.57,5H334.97s0-1.42,2-1.74,5.45-1.48,6-4.06S347.86,346.39,347.86,346.39Z"
                    fill="#4c4c4c"
                />
                <path
                    id="Path_160"
                    d="M337.46,282.05s.65,8,1.41,17.58c1,13,2.29,29.05,2.41,32.09.21,5.27,1.07,13.69,1.07,13.69s2,2,6.1,1.16a136.421,136.421,0,0,0,4.66-24.76,2.768,2.768,0,0,1,.05-.63c1-12.95,2.46-40.24,2.46-40.24Z"
                    fill="#7f7f7f"
                />
                <path
                    id="Path_161"
                    d="M337.46,282.05s.65,8,1.41,17.58l14.24,22.18a2.766,2.766,0,0,1,.05-.63c1-12.95,2.46-40.24,2.46-40.24Z"
                    fill="#fff"
                    opacity="0.3"
                />
                <path
                    id="Path_162"
                    d="M340.42,283s11.72,28.91,19.42,42.5S370,342.89,370,342.89s4.42-1.74,4.82-2.93c0,0-2.95-26.23-8.11-32.63-2.91-3.61-6.34-14.46-6.5-18.25s-1-11.3-1-11.3Z"
                    fill="#7f7f7f"
                />
                <path
                    id="Path_163"
                    d="M355.84,238.43s-7.93,3.83-12.5,2.41a10.889,10.889,0,0,1,3.1-2.48c-.16-.91-.29-1.71-.36-2.38a22.478,22.478,0,0,1-.19-2.44l3.06-2.41,2.9-2.3A11.67,11.67,0,0,0,355.84,238.43Z"
                    fill="#e3e2e3"
                />
                <path
                    id="Path_164"
                    d="M337,282.71a.7.7,0,0,0,.47.19c1.72,0,6.73.91,11,.48a32.171,32.171,0,0,0,10.14-2.87c.46-.21,1.49-.76,1.48-1.25s-.07-1.33-.14-2.33c-.15-2-.35-4.79-.41-8.12-.05-2.46,0-5.22.16-8.13v-.59c0-1.07.07-2.15.07-3.2a54.66,54.66,0,0,0-.72-9.52c-.86-5-2.48-9-4.86-10.7-.32-.24-2.29.71-4.12,1.6a7.63,7.63,0,0,1-4.92.59l-1.58,1.38a15.6,15.6,0,0,0-5,7.79c-.77,3.08-1.23,8.65-1.47,14.43-.13,2.63-.21,5.26-.25,7.77v.87C336.75,277.52,336.83,282.62,337,282.71Z"
                    fill="#707171"
                />
                <path
                    id="Path_165"
                    d="M351.9,231.3a10.48,10.48,0,0,1-5.82,4.68,22.485,22.485,0,0,1-.19-2.44l3.06-2.41Z"
                    fill="#707171"
                    opacity="0.3"
                />
                <path
                    id="Path_166"
                    d="M344.5,233.43s7.56,1.24,8.27-3.41,2.16-7.62-2.64-8.61-6,.65-6.63,2.15S342.55,233,344.5,233.43Z"
                    fill="#e3e2e3"
                />
                <path
                    id="Path_167"
                    d="M355.52,220.86c.06-.08.22-.15.58-.16a1.46,1.46,0,0,0-.66-.19,1.79,1.79,0,0,0-1.18-1.57,1.05,1.05,0,0,1,.51.76c-1-1-3.07-1.73-8.86-1.27-7.64.6-5.12,5.49-4.13,6.07s3.47-1,5.32-.86,2.19,2.8,2.81,3.17.38-.28,1.23-.66.92,1,.68,1.93.92,2.11.92,2.11l1.17-1.27c1.17-1.26,2.28-6.62,1.75-7.76A1.4,1.4,0,0,1,355.52,220.86Z"
                    fill="#707171"
                />
                <path
                    id="Path_168"
                    d="M317.4,244.34a1.46,1.46,0,0,0-.45-1.49c-.71-.46-1.37-1.71-1.67-1.54-.62.35.34,1.45.16,2.81C315.38,244.6,316.5,245.86,317.4,244.34Z"
                    fill="#e3e2e3"
                />
                <path
                    id="Path_169"
                    d="M359.47,268.81c-.05-2.46,0-5.22.16-8.13v-.59c-.72-3.56-1.81-6.72-3.41-6.83C356.25,253.26,351.48,260.76,359.47,268.81Z"
                    fill="#fff"
                    opacity="0.3"
                />
                <path
                    id="Path_170"
                    d="M365.78,281.74s-.4,3.86-.84,4.59a16.1,16.1,0,0,1-3.08,3.52c-.79.46.48-3.07.32-3.76s-.65,1.25-1,1.3-.26-1.7-.27-2.34,1.7-2.21,2.3-3.56S365.78,281.74,365.78,281.74Z"
                    fill="#e3e2e3"
                />
                <path
                    id="Path_171"
                    d="M354.14,236.64s4.79,2.26,7.08,5.74c3.56,5.41,7.68,25.1,7.76,26.92s-3,14.7-3,14.7-3.13-.21-3.94-1.79c0,0,.63-13.23.23-14.08s-13.05-17.74-11.63-21.34S354.14,236.64,354.14,236.64Z"
                    fill="#707171"
                />
                <ellipse
                    id="_Path_"
                    cx="152.91"
                    cy="15.01"
                    rx="152.91"
                    ry="15.01"
                    transform="translate(61.09 450.23)"
                    fill="#f4f5f6"
                />
                <circle
                    id="Ellipse_10"
                    cx="8.83"
                    cy="8.83"
                    r="8.83"
                    transform="translate(40.59 163.29)"
                    fill="#fff"
                    stroke="#e2e2e2"
                    strokeMiterlimit="10"
                    strokeWidth="2"
                />
                <circle
                    id="Ellipse_11"
                    cx="13.27"
                    cy="13.27"
                    r="13.27"
                    transform="translate(65.21 110.63)"
                    fill="#fff"
                    stroke="#e2e2e2"
                    strokeMiterlimit="10"
                    strokeWidth="2"
                />
                <path
                    id="Path_172"
                    d="M112.25,372.76h-5.69v-5.68a.71.71,0,0,0-.71-.71h0a.73.73,0,0,0-.72.71v5.68H99.45a.73.73,0,0,0-.71.73.7.7,0,0,0,.71.7h5.68v5.7a.72.72,0,0,0,.72.7.7.7,0,0,0,.71-.7h0v-5.7h5.69a.72.72,0,0,0,.72-.7.73.73,0,0,0-.72-.73Z"
                    fill="#e2e2e2"
                    stroke="#e2e2e2"
                    strokeMiterlimit="10"
                    strokeWidth="0.75"
                />
                <path
                    id="Path_173"
                    d="M150.92,392.48h-7.31v-7.29a.91.91,0,0,0-.91-.91h0a.92.92,0,0,0-.93.91v7.29h-7.29a.93.93,0,0,0-.92.93.92.92,0,0,0,.92.91h7.29v7.31a.92.92,0,0,0,.93.91.91.91,0,0,0,.91-.91h0v-7.31h7.31a.91.91,0,0,0,.91-.91.93.93,0,0,0-.91-.93Z"
                    fill="#e2e2e2"
                    stroke="#e2e2e2"
                    strokeMiterlimit="10"
                    strokeWidth="0.75"
                />
                <circle
                    id="Ellipse_12"
                    cx="18.1"
                    cy="18.1"
                    r="18.1"
                    transform="translate(196.573 410.327) rotate(-80.75)"
                    fill="#f4f5f6"
                />
                <circle
                    id="Ellipse_13"
                    cx="12.71"
                    cy="12.71"
                    r="12.71"
                    transform="translate(224.32 373.31)"
                    fill="#e3e2e3"
                />
            </g>
        </SVGIcon>
    );
}
