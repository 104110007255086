import React from 'react';
import PropTypes from 'prop-types';
import DCHelpOutline from '../../Assets/icons/DCHelpOutline';
import { Div, ToolTip } from '../index';

import './style.scss';

const TooltipWithIcon = ({ customClass, icon, tooltipContent, tooltipPlacement }) => {
    return (
        <ToolTip content={tooltipContent || 'Tooltip Content'} placement={tooltipPlacement}>
            <Div className={'TooltipWithIcon ' + (customClass ? customClass : '')}>
                {icon || <DCHelpOutline />}
            </Div>
        </ToolTip>
    );
};

TooltipWithIcon.propTypes = {
    customClass: PropTypes.string,
    icon: PropTypes.element,
    tooltipContent: PropTypes.string,
    tooltipPlacement: PropTypes.string,
};
export default TooltipWithIcon;
