import React from 'react';
import { Page, Text, View, Circle, Svg, Font } from '@react-pdf/renderer';
import { calculateGrade } from 'Utils/helper';
import { styles } from './style';
import { instareportFontFamily } from '../components/constant';

Font.register({ ...instareportFontFamily });

const Highchartpercent = ({ score }) => {
    const percent = `${score?.totalPercentage || 0}%`;
    const pi = 3.14159265358979;
    const Twopi = pi * 2;
    const circumference = Twopi * 20;
    const arclengthPercent = score?.totalPercentage / 100;
    const l = `${arclengthPercent * circumference} ,${circumference}`;
    const gradeToColor = { A: '#08cdce', B: '#ff3cf9', C: '#4151f5', D: '#ffaf3a', F: '#fe6786' };
    const gradeColor = gradeToColor[calculateGrade(score?.totalPercentage)];
    return (
        <View
            style={{
                justifyContent: 'center',
                alignItems: 'center',
                display: 'flex',
                flexDirection: 'row',
            }}
        >
            <Svg x="0px" y="0px" viewBox="0 0 50 50" class="svg" style={{ height: 100 }}>
                <Circle cx="25" cy="25" r="20" stroke="#26A3F6" strokeWidth={5} />
                <Circle
                    cx="25"
                    cy="25"
                    r="20"
                    stroke={gradeColor}
                    strokeWidth={5}
                    strokeDasharray={l}
                    transform="rotate(90 40 105) translate(0,-50)"
                />
                <Text
                    x="26"
                    y="27"
                    textAnchor="middle"
                    stroke="red"
                    strokeWidth={1}
                    alignment-baseline="middle"
                    style={{ fontSize: 8 }}
                >
                    {' '}
                    {percent}
                </Text>
            </Svg>
        </View>
    );
};

const ColorBox = ({ score }) => {
    const gradeToColor = { A: '#08cdce', B: '#ff3cf9', C: '#4151f5', D: '#ffaf3a', F: '#fe6786' };
    const color = gradeToColor[score];
    return (
        <View
            style={{
                backgroundColor: `${color}`,
                height: 50,
                width: 50,
                alignItems: 'center',
                borderRadius: 25,
                marginLeft: 11,
                marginTop: 8,
            }}
        >
            <Text
                style={{
                    textAlign: 'center',
                    marginTop: 16,
                    fontSize: 14,
                    fontWeight: 'ultrabold',
                }}
            >
                {score}
            </Text>
        </View>
    );
};

const OverallScoreDownload = ({ score }) => {
    return (
        <Page>
            {Object.keys(score || {}).length ? (
                <View style={styles.section}>
                    <View style={styles.HeadingView}>
                        <Text style={styles.heading}>Overall Score</Text>
                    </View>
                    <View style={styles.ACContent}>
                        <Highchartpercent score={score} />
                    </View>
                    <View style={styles.OverAllSection}>
                        {score?.listings ? (
                            <View style={styles.LSFieldOverall}>
                                <Text>Listings</Text>
                                <ColorBox score={score?.listings} />
                            </View>
                        ) : (
                            <View />
                        )}
                        {score?.reviews ? (
                            <View style={styles.LSFieldOverall}>
                                <Text>Reviews</Text>
                                <ColorBox score={score?.reviews} />
                            </View>
                        ) : (
                            <View />
                        )}
                        {score?.social ? (
                            <View style={styles.LSFieldOverall}>
                                <Text>Social</Text>
                                <ColorBox score={score?.social} />
                            </View>
                        ) : (
                            <View />
                        )}
                        {score?.website ? (
                            <View style={styles.LSFieldOverall}>
                                <Text>Website</Text>
                                <ColorBox score={score?.website} />
                            </View>
                        ) : (
                            <View />
                        )}
                        {score?.seo ? (
                            <View style={styles.LSFieldOverall}>
                                <Text>SEO</Text>
                                <ColorBox score={score?.seo} />
                            </View>
                        ) : (
                            <View />
                        )}
                        {score?.googleAds ? (
                            <View style={styles.LSFieldOverall}>
                                <Text>Google Ads</Text>
                                <ColorBox score={score?.googleAds} />
                            </View>
                        ) : (
                            <View />
                        )}
                    </View>
                </View>
            ) : (
                <View />
            )}
        </Page>
    );
};

export default OverallScoreDownload;
