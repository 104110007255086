import React from 'react';

import { SVGIcon } from '../../UIKit/index';

export default function DCTags(props) {
    return (
        <SVGIcon {...props} viewBox="0 0 502.71 421.427">
            <g id="Tags" transform="translate(-4.91 -53.113)">
                <circle
                    id="Ellipse_1"
                    data-name="Ellipse 1"
                    cx="13.28"
                    cy="13.28"
                    r="13.28"
                    transform="translate(480.06 248.96)"
                    fill="#fff"
                    stroke="#e1e1e1"
                    strokeMiterlimit="10"
                    strokeWidth="2"
                />
                <circle
                    id="Ellipse_2"
                    data-name="Ellipse 2"
                    cx="15.29"
                    cy="15.29"
                    r="15.29"
                    transform="translate(30.12 200.17)"
                    fill="#fff"
                    stroke="#e1e1e1"
                    strokeMiterlimit="10"
                    strokeWidth="2"
                />
                <path
                    id="Path_1"
                    data-name="Path 1"
                    d="M230.62,107.54h-11.2V96.35A1.4,1.4,0,0,0,218,95h0a1.43,1.43,0,0,0-1.42,1.39v11.19H205.42A1.42,1.42,0,0,0,204,109a1.4,1.4,0,0,0,1.4,1.39h11.2V121.6A1.43,1.43,0,0,0,218,123a1.4,1.4,0,0,0,1.4-1.39h0V110.36h11.2A1.41,1.41,0,0,0,232,109a1.43,1.43,0,0,0-1.38-1.46Z"
                    fill="#e1e1e1"
                    stroke="#e1e1e1"
                    strokeMiterlimit="10"
                    strokeWidth="0.75"
                />
                <path
                    id="Path_2"
                    data-name="Path 2"
                    d="M108.66,89.92H94.82V76.1a1.72,1.72,0,0,0-1.72-1.72h0a1.75,1.75,0,0,0-1.76,1.72V89.92H77.54a1.74,1.74,0,0,0,0,3.48h13.8v13.84A1.76,1.76,0,0,0,93.1,109a1.72,1.72,0,0,0,1.72-1.72h0V93.4h13.84a1.74,1.74,0,1,0,0-3.48Z"
                    fill="#e1e1e1"
                    stroke="#e1e1e1"
                    strokeMiterlimit="10"
                    strokeWidth="0.75"
                />
                <ellipse
                    id="_Path_"
                    data-name="&lt;Path&gt;"
                    cx="237.2"
                    cy="125.6"
                    rx="237.2"
                    ry="125.6"
                    transform="translate(4.91 223.34)"
                    fill="#f3f4f5"
                />
                <path
                    id="Path_3"
                    data-name="Path 3"
                    d="M408.93,176.16a53.281,53.281,0,0,1,.6,7.94,52.29,52.29,0,1,1-44.66-51.75l32.18-32.18H308.81l-198,198L243.24,430.68l198.05-198-.54-88.3Z"
                    fill="#717272"
                />
                <path
                    id="Path_4"
                    data-name="Path 4"
                    d="M485.77,56.27l-.09-.09a10.46,10.46,0,0,0-14.8,0l-99.74,99.73a.82.82,0,0,1-.95.17c-8.84-4.12-19.91-4.25-30.93,2a23.59,23.59,0,0,0-9,9.15c-16.5,30,13.92,60.43,43.92,43.94a23.57,23.57,0,0,0,9.16-9c6.27-11,6.13-22.13,2-31a.84.84,0,0,1,.16-.95L485.77,70a9.74,9.74,0,0,0,0-13.73Z"
                    fill="#e3e2e2"
                />
            </g>
        </SVGIcon>
    );
}
