import React from 'react';
import DCCrossClose from 'Assets/icons/DCCrossClose';
import { Dialog, Div, H1, H4, Br, P, IconButton, TopBar } from 'UIKit';
import './style.scss';
import PropTypes from 'prop-types';
import { getAccountDetails } from 'Utils/localStorageUtils';
import { getAccountType } from 'Utils/auth';
function TermsModal({ open, onClose }) {
    const accountName =
        getAccountType() === 'main' ? 'DashClicks' : getAccountDetails()?.name || '';

    const accountEmail =
        getAccountType() === 'main' ? 'info@dashclicks.com' : getAccountDetails()?.email || '';

    return (
        <Dialog dialogType={'centerZoom sizeMd'} open={open} onClose={onClose}>
            <Div className="TermAndConditions">
                <TopBar>
                    <Div className={'HeaderBar'}>
                        <H1 className={'HeaderBarTitle'}>Terms and Conditions</H1>
                        <Div className={'HeaderBarClose'}>
                            <IconButton onClick={onClose}>
                                <DCCrossClose />
                            </IconButton>
                        </Div>
                    </Div>
                </TopBar>
                <Div className={'TACBody'}>
                    <Br />
                    <H4>Refund Policy</H4>
                    <Br />
                    <P>
                        All Services offered by {accountName} are pre-paid, or are on a prepaid
                        subscription basis, which are non-refundable. Based on the purchased
                        services, {accountName} is required to add such services to softwares
                        immediately after the purchase which may incur a fee. The account setup
                        process is initiated immediately after the purchase. The monthly maintenance
                        services are initiated by the monthly recurring subscription. All sales are
                        final. We reserve the right to modify, suspend or terminate access to
                        {accountName} at any time without notice or refund, if we determine that you
                        have violated our Terms of Service. Terminated accounts will not receive
                        partial credit. No refunds or credits are applicable as software fees and
                        labor costs are incurred by {accountName} once the payment has been
                        processed for both the initial purchase fees and the monthly recurring
                        subscription fees.
                    </P>
                    <Br />
                    <H4>Cancellation Policy</H4>
                    <Br />
                    <P>
                        You may cancel your subscription(s) at any time by logging into your account
                        and following the instructions on your account dashboard for cancellation or
                        by contacting us at {accountEmail}. Such cancellation notice must be sent by
                        the account owner or an authorized signatory. Inactivity does not constitute
                        automatic cancellation, so unless you submit your cancellation request via
                        your dashboard, you will continue to be charged for subscription to the
                        Services. All cancellation requests will take effect at the end of the
                        current subscription period in which the cancellation request is made, and
                        you will be responsible for all fees and any applicable taxes and other
                        charges rendered up through the cancellation date. You may request more
                        information in regards to cancelling services with {accountName} by sending
                        an email to {accountEmail}. Cancellation requests made after either the
                        initial purchase charge or the monthly recurring subscription charge will
                        only stop all the following monthly recurring subscription fees from being
                        charged. {accountName} does not refund partial month payments. Upon
                        finalization of a cancellation request regarding a service/subscription: No
                        future payments will be processed, all work will cease to continue, all data
                        corresponding to the service will be discarded, and access to the platform
                        for that subscription will not be available for your client.
                    </P>
                    <Br />
                    {getAccountType() === 'main' && (
                        <>
                            <H4>Media Buying Services</H4>
                            <Br />
                            <P>
                                Facebook Ads & Google Ads: Pausing an entire campaign, pausing ads,
                                or shutting down a campaign for a brief period is not related to
                                your subscription fees paid to {accountName}. Pausing any part of
                                the service will not pause the subscription fees you pay to{' '}
                                {accountName} for such service. If the credit card on file in the
                                Facebook/Instagram platform used to pay for the ad spend is declined
                                and as a result the ads are not running, this also is not related to
                                your subscription fees paid to {accountName}. Subscription fees will
                                be collected regardless of the status of the ads/campaign as
                                software fees and labor costs are still incurred. To cancel/stop the
                                subscription fees related to a campaign from {accountName}, you must
                                submit a cancellation request from within your account or contact
                                {accountName} at least seven days prior to your next bill date as
                                mentioned above.
                            </P>
                            <Br />
                            <H4>SEO Services </H4>
                            <Br />
                            <P>
                                Please note, for the {accountName} SEO team to be able to perform
                                work on a website, that website must be managed using a CMS (content
                                management system) and must have an easily accessible, navigable,
                                and editable backend system. Examples of CMS systems that our SEO
                                team can work on include WordPress, Drupal, Wix, GoDaddy (website
                                builder), and the {accountName} website builder.
                            </P>
                            <Br />
                            <P>
                                If the website that is being asked to work on is a custom site
                                (HTML, PHP, etc.) that requires the input of code to make changes
                                via FTP and/or cPanel, we will not work on the site.
                            </P>
                            <Br />
                            <P>
                                Also, we MUST receive admin access to the backend of the websites'
                                CMS system to fulfill the SEO work. We must also be permitted to
                                make the necessary backend changes on the site without the need for
                                prior approval. We will NOT be sending work to be approved by you,
                                the partner, or the sub-account before implementing the work.
                                Sending work for approval before implementation is outside of the
                                scope of our work and protocols.
                            </P>
                            <Br />
                            <P>
                                Also, the SEO work must be implemented by our team. We will not be
                                sending out the work that's needed to be done to be handled
                                externally. Sending work to be implemented externally is outside of
                                the scope of our work and protocols.
                            </P>
                            <Br />
                            <P>
                                Again, our team needs admin access to the backend CMS and MUST be
                                allowed to implement the work without approval. During the monthly
                                reporting, our communications team will send all of the work that
                                our fulfillment team has performed. If any changes need to be done,
                                you, the partner, can let us know at that point, and our team will
                                be notified to make the alterations.
                            </P>
                            <Br />
                            <H4>Results Disclaimer</H4>
                            <Br />
                            <P>
                                Please note, when it comes to marketing, nothing is guaranteed. With
                                that being said, results for any of our services (SEO, Google Ads,
                                Facebook Ads, etc.) cannot be guaranteed. When purchasing a service
                                from {accountName}, you are acknowledging that there is a certain
                                risk factor involved with marketing in general and things may not go
                                as planned. {accountName} produces generally favorable results for
                                our clients, however, it is never certain and less than satisfactory
                                results should be expected at times.
                            </P>
                            <Br />
                            <P>
                                Also, {accountName} cannot make any projections for results as there
                                are many different factors at play including business type, target
                                demographic, budget, target region, etc. After the product is
                                purchased from us and the onboarding documents are filled out, our
                                specialists can review the information and once that is done, they
                                can make an assessment and estimate the sort of results that we can
                                expect, however, nothing is guaranteed.
                            </P>
                            <Br />
                            <P>
                                If results are not what was originally expected, this does not
                                justify any refunds or credits from {accountName}. Again, with the
                                purchase of a service from {accountName}, you are acknowledging any
                                possible risks associated with marketing.
                            </P>
                            <Br />
                        </>
                    )}
                    <H4>Bill Date Adjustment</H4>
                    <Br />
                    <P>
                        Bill dates are issued when your initial payment is processed, the same date
                        will be used moving forward on a month to month basis. {accountName}{' '}
                        integrates directly to multiple softwares and directly to our fulfillment
                        centers, due to the strict scheduling to operate on a global level, bill
                        dates can not be adjusted or moved.
                    </P>
                    <Br />
                    <H4>Declined Payments</H4>
                    <Br />
                    <P>
                        Declined payments for active subscriptions may result in service
                        interruption. If for any reason, a payment for an active subscription is
                        declined, you must contact {accountName} immediately to resolve the
                        delinquent payment. {accountName} may contact you to resolve the issue, but
                        the responsibility to resolve the issue resides on the account owner. You
                        may update your payment information through the {accountName} platform. If
                        the issue can not be resolved immediately, a grace period of up to 5
                        business days will be granted in which services will continue, and all
                        corresponding data will remain in our system. During the grace period, if
                        you do not communicate with {accountName} in regards to satisfying the
                        balance, or if you are unreachable, {accountName} may attempt to process the
                        payment with the credit card that was previously authorized on the account.
                        Once the grace period is over, and no communication was made, or if you were
                        unreachable, {accountName} will treat the subscription as a cancellation. To
                        prevent service interruption, loss of data, and reactivation to a previously
                        active subscription, be sure to communicate with {accountName} prior to the
                        declined payment or directly after.
                    </P>
                    <Br />
                    <H4>Chargebacks</H4>
                    <Br />
                    <P>
                        We work to resolve any disagreements with our clients mutually. In the event
                        a chargeback is initiated with our merchant, we will assess your account
                        with a 50.00 fee, per chargeback, regardless of the outcome. In addition,
                        any outstanding balances accumulated as a result of the chargeback(s) will
                        have to be paid before we reactivate the subscription, render any service,
                        or share any files. Following a chargeback that was not resolved, the client
                        will be banned from using the {accountName} platform and all related
                        {accountName}
                        services.
                    </P>
                    <Br />
                    <P>
                        Disputes: In the event a dispute is initiated, we will assess your account
                        with a 50.00 fee, per dispute, regardless of the outcome. Any disputes
                        related in any way to the service/subscription may lead to legal
                        proceedings. You agree to submit to the jurisdiction regarding any legal
                        proceedings which will be governed in the State of Florida. You agree not to
                        initiate any legal proceeding against {accountName} in any other state.
                        Following a dispute, the client will be banned from using the {accountName}
                        platform and all related {accountName} services.
                    </P>
                </Div>
            </Div>
        </Dialog>
    );
}

TermsModal.propTypes = {
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
};

export default TermsModal;
