import React from 'react';

import { SVGIcon } from '../../UIKit/index';

export default function DCIPs(props) {
    return (
        <SVGIcon {...props} viewBox="0 0 428 512">
            <g transform="translate(-990.529 -9910.19)">
                <g transform="translate(990.529 9910.19)">
                    <path
                        fill="#FF9B51"
                        d="M214.42,512L56.98,356.11c0,0-79.34-74.26-47.11-204.12c0,0,40.91-149.69,204.55-150.93    c0,0,172.32-8.66,209.51,178.14c0,0,30.99,107.63-78.1,202.87L214.42,512z"
                    />
                    <path
                        fill="#FF8B36"
                        d="M213.71,1.06v510.92L47.68,346.63c0,0-62.55-59.54-43.1-172.25    C4.58,174.39,30.24,6.01,213.71,1.06z"
                    />
                    <text
                        fontSize="122.4866px"
                        color="#FFFFFF"
                        transform="matrix(1.0021 0 0 1 118.5225 218.6465)"
                    >
                        IPs
                    </text>
                </g>
            </g>
        </SVGIcon>
    );
}
