import React, { useReducer } from 'react';

import { instaReportActions } from '../Actions/index';
import { InstaReportContext } from '../Context/index';
import { instaReportReducer } from '../Reducer/index';
import { instaReportStore } from '../Store/index';

const InstaReportContextProvider = props => {
    const [state, dispatch] = useReducer(instaReportReducer, instaReportStore);

    const value = {
        status: state.status,
        initialMyInstareportLoad: state.initialMyInstareportLoad,
        fields: state.fields,
        page: state.page,
        totalResults: state.totalResults,
        totalPages: state.totalPages,
        pageLimit: state.pageLimit,
        data: state.data,
        sortString: state.sortString,
        myInstaReportSearchText: state.myInstaReportSearchText,

        selectedInstaReport: state.selectedInstaReport,
        statusString: state.statusString,
        additionalInfo: state.additionalInfo,

        setStatus: value => {
            dispatch({
                type: instaReportActions.SET_STATUS,
                value,
            });
        },
        setMyInstaReportInitialLoad: value => {
            dispatch({
                type: instaReportActions.SET_INITIAL_MY_INSTAREPORT_LOAD,
                value,
            });
        },
        setFields: value => {
            dispatch({
                type: instaReportActions.SET_FIELDS,
                value,
            });
        },
        setPage: value => {
            dispatch({
                type: instaReportActions.SET_PAGE,
                value,
            });
        },
        setTotalResults: value => {
            dispatch({
                type: instaReportActions.SET_TOTAL_RESULTS,
                value,
            });
        },
        setTotalPages: value => {
            dispatch({
                type: instaReportActions.SET_TOTAL_PAGES,
                value,
            });
        },
        setPageLimit: value => {
            dispatch({
                type: instaReportActions.SET_PAGE_LIMIT,
                value,
            });
        },
        setData: value => {
            dispatch({
                type: instaReportActions.SET_DATA,
                value,
            });
        },
        setSortString: value => {
            dispatch({
                type: instaReportActions.SET_SORT_STRING,
                value,
            });
        },
        setMyInstaReportSearch: value => {
            dispatch({
                type: instaReportActions.SET_MY_INSTAREPORT_SEARCH_TEXT,
                value,
            });
        },
        setSelectedInstaReport: value => {
            dispatch({
                type: instaReportActions.SET_SELECTED_INSTAREPORT,
                value,
            });
        },
        setStatusString: value => {
            dispatch({
                type: instaReportActions.SET_STATUS_STRING,
                value,
            });
        },
        setAdditionalInfo: value => {
            dispatch({
                type: instaReportActions.SET_ADDITIONAL_INFO,
                value,
            });
        },
    };

    return (
        <InstaReportContext.Provider value={value}>{props.children}</InstaReportContext.Provider>
    );
};

export default InstaReportContextProvider;
