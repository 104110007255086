import React from 'react';

import { SVGIcon } from '../../UIKit/index';

export default function DCGrayUser(props) {
    return (
        <SVGIcon {...props} viewBox="0 0 454.9 497.55">
            <g id="Users" transform="translate(-29.67 -6)">
                <circle
                    id="Ellipse_9"
                    cx="225.45"
                    cy="225.45"
                    r="225.45"
                    transform="translate(31.67 8)"
                    fill="#fff"
                    stroke="#707171"
                    strokeMiterlimit="10"
                    strokeWidth="4"
                />
                <path
                    id="Path_120"
                    d="M244.86,362.55c-9.86,0-20.18,0-30,1.16-3.71.44-10.35.05-14.07.43-7.42.76-10.95-1.33-18.4-.67-6,.53-12,.84-18,1.52a60.64,60.64,0,0,1-17.38-.56c-4.12-.77-8.26-1.2-12.32-2.44a48,48,0,0,0-14.18-2.34c-6.73.05-13.29.06-19.74,2.5A19.31,19.31,0,0,0,94,366.22c-3.93,4.12-4.46,14.41-.53,18.68,6.61,7.2,20.73,10,30,10.15,10.16.15,20-.64,30.2-1.1,9.46-.43,18.89-1.05,28.36-1,19.34.05,38.53,4.52,57.84,3.17,18.14-1.27,36.47-5.35,54.65-5.35,18.64,0,37.15.15,55.66,2.74,8.06,1.13,16.28,1.53,24.38,2.32,7.26.7,14.23,0,21.46-.67a49.649,49.649,0,0,0,17.62-4.93c4.36-2.18,12.14-9,13.48-14.16,3.75-14.59-52.89-14.24-61.58-14.21-9.73,0-19.54.37-29.26,0a66.393,66.393,0,0,1-12.11-1.92c-4-.91-8-.68-12-1.06-16.81-1.6-30.45,3.76-47.31,3.76C259.35,362.6,250.35,362.53,244.86,362.55Z"
                    fill="#f4f5f6"
                />
                <path
                    id="Path_121"
                    d="M413.31,282.45l1.6,37.29-4.71,63.4-7.05.5-8.57-57.58-11.85-48.73Z"
                    fill="#636363"
                />
                <path
                    id="Path_122"
                    d="M403.4,386.62l7.9,3.27,3.54-4.09-4.09-3.54-7.62.54Z"
                    fill="#636363"
                />
                <path
                    id="Path_123"
                    d="M363.39,269.26l-1.55,28.19,4.59,83.8,7.06.35,10.58-54.96,9.34-60.28Z"
                    fill="#636363"
                />
                <path
                    id="Path_124"
                    d="M373.3,384.58l-7.82,3.43-3.63-4,4.01-3.63,7.63.38Z"
                    fill="#636363"
                />
                <path
                    id="Path_125"
                    d="M404.44,186.23l-22-2.36s-12.26,1.31-20.58,30.42c-4.62,16.19-6,41.71-6.37,61.16.52,2.78,3.54,14.93,16.33,12.87,21.95-3.54,33.63-7.08,43.19-5.31,8.15,1.77,7.46-9.44,6.56-18l-3.24-26.47Z"
                    fill="#707171"
                />
                <path
                    id="Path_126"
                    d="M400.19,158.8c-5.56-1.22-11.27-2.32-16.83-1.22a4.69,4.69,0,0,0-3.19,1.72,5,5,0,0,0-.51,1.34c-1.41,5.22-3.19,13,.25,17.75a8.43,8.43,0,0,0,2.48,2.24,12.271,12.271,0,0,0,4.1,1.61,22.391,22.391,0,0,1-1.24,3.79c1.27,1.91,7.2,2.14,8.3.3l.18-4.77a12,12,0,0,0,2.08-1.07,13.45,13.45,0,0,0,5.17-7c1.06-3.09,2.54-6,3.51-9.17a4.41,4.41,0,0,0,0-3.49c-.64-1.1-2-1.47-3.27-1.75Z"
                    fill="#be6753"
                />
                <path
                    id="Path_127"
                    d="M381.7,161.79a17.26,17.26,0,0,1,9.45-2.34c7.8.56,10.66,6.29,11.07,13.47,2.15-2.73,6.19-6.17,6.43-9.69.2-2.87-.2-6.8-1.81-9.28-1.51-2.33-5.37-3.61-7.66-4.95l-6.49-3.81a4.29,4.29,0,0,0-1.42-.63,4.41,4.41,0,0,0-1.83.21l-15.33,4c-1,.25-2.1.62-2.46,1.57a3.23,3.23,0,0,0,0,1.82c.58,2.66,1.69,5.37,2,8.06.31,1.56,1.24,9.56,4,8.64.81-1.33.93-3,1.56-4.39A6.35,6.35,0,0,1,381.7,161.79Z"
                    fill="#504a45"
                />
                <path
                    id="Path_128"
                    d="M382.41,183.87l-8.15,2.48-29.74,31.16-1.77,10.27,14.22,23.47,3.84-8.6-6.02-17.35,9.56-6.73,12.75-28.68Z"
                    fill="#a5b4ba"
                />
                <path
                    id="Path_129"
                    d="M419.41,175.14l-1.64-.8a49.76,49.76,0,0,0,8.51-28.3c0-25.17-17.75-45.64-39.57-45.64s-39.56,20.47-39.56,45.6a49.74,49.74,0,0,0,8.51,28.3c-.86.4-1.43.69-1.68.82a65.46,65.46,0,0,0-38.17,59.29v15.82a12.36,12.36,0,0,0,12.35,12.35h117.1a12.36,12.36,0,0,0,12.35-12.35V234.45a65.47,65.47,0,0,0-38.2-59.31Z"
                    fill="#fff"
                />
                <path
                    id="Path_130"
                    d="M417.56,179c-.21.26-.41.52-.63.78-8,9.38-18.73,14.54-30.22,14.54s-22.21-5.16-30.21-14.54l-.64-.78a61.12,61.12,0,0,0-35.77,55.45v15.82a8.07,8.07,0,0,0,8.07,8.07h117.1a8.07,8.07,0,0,0,8.08-8.07V234.45A61.13,61.13,0,0,0,417.56,179Z"
                    fill="#e3e2e3"
                />
                <path
                    id="Path_131"
                    d="M386.71,187.41c9.28,0,17.71-4.2,24-11A44.81,44.81,0,0,0,422,146c0-22.84-15.8-41.37-35.3-41.37S351.42,123.2,351.42,146a44.81,44.81,0,0,0,11.29,30.32C369,183.21,377.44,187.41,386.71,187.41Z"
                    fill="#e3e2e3"
                />
                <path
                    id="Path_132"
                    d="M322.58,206.11l4.37-2.5,3.98,7.23-4.18,7.42-6.66-3.09Z"
                    fill="#707171"
                />
                <path
                    id="Path_133"
                    d="M450.85,202.71l-4.38-2.49-3.97,7.22,4.17,7.42,6.67-3.08Z"
                    fill="#707171"
                />
                <path
                    id="Path_134"
                    d="M125.18,285.46,94.54,274.19l6.32,53.67-3.66,46.3-.1.31h.07v.1l6.04.58v-.01l.23.03,13.49-41.25,7.14-43.94h0Z"
                    fill="#636363"
                />
                <path
                    id="Path_135"
                    d="M102.9,377.26l-6.27-.16-1.6,5.29.16.05h-.04l10.36,6.14,4.92.55.63-5.63-1.33-.98Z"
                    fill="#636363"
                />
                <path
                    id="Path_136"
                    d="M146.04,286.38l-9.19,91.89-6.06.27-6.09-49.61-10.56-49.42Z"
                    fill="#636363"
                />
                <path
                    id="Path_137"
                    d="M136.84,380.4l8.03,4.55-.75,3.79-14.36-2.16.85-5.46Z"
                    fill="#636363"
                />
                <path
                    id="Path_138"
                    d="M160.41,309.46c1.94-1.35,2-3.69,2-6a58.359,58.359,0,0,0-1.8-13.2,197.267,197.267,0,0,1-5.07-28.12c-2.48-26.91-11-45-11-45l-2.75-7.31c-.54-.95-1.11-1.89-1.7-2.82-3.42-5.37-9.37-14.5-15.73-16l-.4-.09c-6.69-1.47-15.25-.79-21.3,2.46a21.24,21.24,0,0,0-3.17,2.26c-3,2.38-5.37,3.67-6.64,7.3-1.11,3.16-1.37,6.6-2,9.87-1.23,6.64-2,13.36-3,20-1.86,12.53-3.43,25.09-5.42,37.6-1,6.43-2,12.88-2.53,19.38-.43,5.75-5.61,11.59-8.53,16.74-1,1.79,13.55,6.44,14.86,6.74,6,1.41,12.47,1,18.7,1.08q20.48.09,40.92-1.26c4.53-.3,10.11-1.17,14.12-3.42a3.481,3.481,0,0,0,.44-.21Z"
                    fill="#707171"
                />
                <path
                    id="Path_139"
                    d="M116.46,190.17s-7.69-2.07-8.75,15.28-2.83,61.25-5.31,73.29-6.2,35.65-6.2,35.65l23.55.82,26.91-2.12s-9.21-17-10.62-29,1.06-57.36-5-74.35S123.8,190.48,116.46,190.17Z"
                    fill="#707171"
                />
                <path
                    id="Path_140"
                    d="M151.26,228.82l1.77,2.12,6.38-8.85-25.5-28.33-9.56-2.83,4.61,12.39Z"
                    fill="#707171"
                />
                <path
                    id="Path_141"
                    d="M129.96,221.49l-5.6-4.27,1.72-2.47,4.48,2.07.27-1.54,4.34,4.23-1.44,2.81Z"
                    fill="#707171"
                />
                <path
                    id="Path_142"
                    d="M151.87,220.4l-.25.55-17.44-3.37-2.57,5.72,21.42,7.64Z"
                    fill="#707171"
                />
                <path
                    id="Path_143"
                    d="M127.19,176.43c1.16,3.49-.52,7.22-2,10.4-1.31,2.82-1.77,4.68,1.56,5.44,1.88.43,5.2,1.23,6.85-.2,1.24-1.09.54-4.45-.06-5.78-.47-1-1.69-1.54-2.13-2.57a3.66,3.66,0,0,1-.21-1.36,19.07,19.07,0,0,1,.77-5.57,18.781,18.781,0,0,0,1-3.71c.16-1.85-1.15-4-1.84-5.7-1.27-3.16-5.72-7.39-9.44-4.91a5.42,5.42,0,0,0-1.76,2.45,4.44,4.44,0,0,0-.48,1.74c0,2.43,2.18,2.83,3.71,4a12.09,12.09,0,0,1,4.03,5.77Z"
                    fill="#3a332d"
                />
                <path
                    id="Path_144"
                    d="M120.11,190.06a10.47,10.47,0,0,0,7-6.56,21,21,0,0,0,1-9.81c-1.12-11-14.78-10.28-19.41-1.86C104.58,179.16,110.72,192.27,120.11,190.06Z"
                    fill="#dbc6aa"
                />
                <path
                    id="Path_145"
                    d="M120.1,188.88c0,1.25-.11,2.5-.16,3.75a.58.58,0,0,1-.74.66l-3.06-.11a3.26,3.26,0,0,1-1.48-.28c-1-.52-1.17-1.84-1-2.94a9.42,9.42,0,0,0,.32-3.28c2.1,1,4.1-1.31,6.21-.27Z"
                    fill="#dbc6aa"
                />
                <path
                    id="Path_146"
                    d="M115.7,154.64c-3.59,1.22-5.89,4.85-9.39,6.3-2,.83-4.39.94-6,2.37-3.68,3.2-.72,9.91-3.74,13.73-.63.8-1.47,1.4-2.1,2.19-1.36,1.71-1.54,4.05-1.66,6.23a7.05,7.05,0,0,0,0,1.52c.47,5,7.78,4.71,10.69,4.39,3.19-.36,2.12-2.13,2.12-2.13l-.11-.14a6.09,6.09,0,0,1,2.14-5.53,7.32,7.32,0,0,0,1.72-1.51c.94-1.44,0-3.35.18-5.06.56-6.92,12.17-5.44,15.44-10.36C129,160.6,122.47,152.34,115.7,154.64Z"
                    fill="#3a332d"
                />
                <path
                    id="Path_147"
                    d="M150.47,175.14c-.54-.27-1.09-.54-1.65-.8a49.69,49.69,0,0,0,8.52-28.3c0-25.17-17.75-45.64-39.57-45.64S78.2,120.87,78.2,146a49.67,49.67,0,0,0,8.52,28.3c-.86.4-1.43.69-1.68.82a65.46,65.46,0,0,0-38.17,59.29v15.82a12.36,12.36,0,0,0,12.35,12.35h117.1a12.36,12.36,0,0,0,12.35-12.35V234.45a65.47,65.47,0,0,0-38.2-59.31Z"
                    fill="#fff"
                />
                <path
                    id="Path_148"
                    d="M148.62,179l-.64.78c-8,9.38-18.73,14.54-30.21,14.54s-22.21-5.16-30.21-14.54l-.64-.78a61.12,61.12,0,0,0-35.77,55.45v15.82a8.07,8.07,0,0,0,8.07,8.07h117.1a8.07,8.07,0,0,0,8.07-8.07V234.45A61.12,61.12,0,0,0,148.62,179Z"
                    fill="#e3e2e3"
                />
                <path
                    id="Path_149"
                    d="M117.77,187.41c9.27,0,17.7-4.2,24-11A44.81,44.81,0,0,0,153.06,146c0-22.84-15.8-41.37-35.29-41.37S82.48,123.2,82.48,146a44.81,44.81,0,0,0,11.29,30.32C100.07,183.21,108.5,187.41,117.77,187.41Z"
                    fill="#e3e2e3"
                />
                <path
                    id="Path_150"
                    d="M53.64,206.11l4.37-2.5,3.97,7.23-4.17,7.42-6.66-3.09Z"
                    fill="#707171"
                />
                <path
                    id="Path_151"
                    d="M181.9,202.71l-4.37-2.49-3.97,7.22,4.17,7.42,6.66-3.08Z"
                    fill="#707171"
                />
                <path
                    id="Path_152"
                    d="M282.63,378.46l.57,4.47-8.37,5.63-5.05-3.9,3.9-5.05,8.37-5.62Z"
                    fill="#707171"
                />
                <path
                    id="Path_153"
                    d="M282.91,376.96l-6.45-57.05,7.76-63.86-1.74-13.58-34.7-1.32-1.25,22.11L252.36,320l21.91,59.88Z"
                    fill="#636363"
                />
                <path
                    id="Path_154"
                    d="M244.53,178.03l-17.45,4.75-20.19,35.76,9.56,8.86,13.46-15.94Z"
                    fill="#babcbe"
                />
                <path
                    id="Path_155"
                    d="M222.33,247.54l-.09,57.57,11.58,74.1,8.34-.08,4.59-68.53,10.73-68.59Z"
                    fill="#636363"
                />
                <path
                    id="Path_156"
                    d="M242.15,382.65l-8.97,4.6-4.56-4.47,4.47-4.55,9.02-.09Z"
                    fill="#707171"
                />
                <path
                    id="Path_157"
                    d="M287.81,267.12c-.08-2.5-.22-4.58-.23-5.32-.18-6.69-.78-13.37-1.62-20-.75-6-1.7-20.74-2.71-26.66-.85-4.88-3.78-17.08-5.9-21.61-5.26-11.27-3.64-12.31-26.78-15.66-10.14-1.47-12.54,4.85-20.83,12.12-.81.72-.77,5-1,6.12-.33,2-.69,4-1.1,6q-.48,2.34-1.08,4.65c-4.12,15.93-8.87,31.25-9.84,47.77-.27,4.66-1.71,9.18-1.58,13.88a2.48,2.48,0,0,0,.4,1.54c1.4,1.77,9.13-.93,11.47-1.16a170.559,170.559,0,0,1,18.07-.91,114.708,114.708,0,0,1,18.41,1.71,39.58,39.58,0,0,1,11.81,3.56c3.45,1.8,6,4.46,9.46,6.33a1.81,1.81,0,0,0,1.07.31C287.89,279.45,288,272.37,287.81,267.12Z"
                    fill="#707171"
                />
                <path
                    id="Path_158"
                    d="M250.55,181c1.16.58,3.92.78,6.79-.34a1.53,1.53,0,0,0,1-1.41,41.431,41.431,0,0,0-.42-5.22c2.15-.76,4.64-1.89,5-4.15a6.19,6.19,0,0,0-.13-2.14q-.75-4.41-1.52-8.8a7.78,7.78,0,0,0-.91-2.93,6.7,6.7,0,0,0-4.63-2.5,16.08,16.08,0,0,0-13,3.17,7,7,0,0,0-2.19,2.9c-.64,1.78-.16,3.74.32,5.57s.88,3.38,1.33,5.07c.51,1.94,1.24,4.15,3.11,4.88,1,.38,2.17.27,3.07.84C249.94,177,249.72,179.35,250.55,181Z"
                    fill="#dbc6aa"
                />
                <path
                    id="Path_159"
                    d="M225.5,151.66c-.78-7.28,2.4-14.95,8.39-13.42,3.63.92,6.88,2.45,10.53,1.09,3.43-1.28,5.91-.76,9.5.52,3.88,1.38,9.09,1.71,12.6,3.66a10.37,10.37,0,0,1,3.55,3.51,15.62,15.62,0,0,1,.05,16.51,22.518,22.518,0,0,1-2.43,3.06c-1.83,2-4,4.44-6.54,5.18-.06-2.25,2-9.36.26-11.21-2.75-2.93-9-1.91-12.37-1.18-3.71.78-7,3.42-10.71,4C230.2,164.6,226.21,158.29,225.5,151.66Z"
                    fill="#3a332d"
                />
                <path
                    id="Path_160"
                    d="M292.86,136.16q-.94-.48-1.92-.93a58.17,58.17,0,0,0,10-33.09c0-29.43-20.76-53.37-46.27-53.37S208.4,72.71,208.4,102.14a58.12,58.12,0,0,0,10,33.09c-1,.46-1.67.8-2,1a76.56,76.56,0,0,0-44.62,69.33V224a14.46,14.46,0,0,0,14.44,14.44H323.09A14.45,14.45,0,0,0,337.53,224V205.5A76.56,76.56,0,0,0,292.86,136.16Z"
                    fill="#fff"
                />
                <path
                    id="Path_161"
                    d="M290.7,140.67c-.25.31-.49.62-.74.92-9.36,11-21.9,17-35.33,17s-26-6-35.33-17c-.25-.3-.49-.61-.74-.92a71.46,71.46,0,0,0-41.83,64.84V224a9.44,9.44,0,0,0,9.44,9.44H323.09a9.44,9.44,0,0,0,9.44-9.44V205.5a71.46,71.46,0,0,0-41.83-64.83Z"
                    fill="#707171"
                />
                <path
                    id="Path_162"
                    d="M254.63,150.51c10.84,0,20.7-4.91,28.07-12.92,8.11-8.84,13.2-21.45,13.2-35.45,0-26.72-18.48-48.37-41.27-48.37s-41.27,21.65-41.27,48.37c0,14,5.09,26.61,13.2,35.45C233.93,145.6,243.79,150.51,254.63,150.51Z"
                    fill="#707171"
                />
                <path
                    id="Path_163"
                    d="M207.98,238.22l-1.91-4.66,7.68-3,6.82,5.1-3.92,6.21Z"
                    fill="#e3e2e3"
                />
                <path
                    id="Path_164"
                    d="M293.72,238.2l1.89-4.67-7.69-2.98-6.79,5.13,3.94,6.19Z"
                    fill="#e3e2e3"
                />
                <ellipse
                    id="_Path_"
                    cx="159.1"
                    cy="15.62"
                    rx="159.1"
                    ry="15.62"
                    transform="translate(97.62 472.31)"
                    fill="#f4f5f6"
                />
                <circle
                    id="Ellipse_10"
                    cx="13.14"
                    cy="13.14"
                    r="13.14"
                    transform="translate(167.8 402.18)"
                    fill="#fff"
                    stroke="#e2e2e2"
                    strokeMiterlimit="10"
                    strokeWidth="2"
                />
                <circle
                    id="Ellipse_11"
                    cx="7.59"
                    cy="7.59"
                    r="7.59"
                    transform="translate(185.81 415.31)"
                    fill="#fff"
                    stroke="#e2e2e2"
                    strokeMiterlimit="10"
                    strokeWidth="2"
                />
                <path
                    id="Path_165"
                    d="M69.11,171.56H62.77v-6.33a.79.79,0,0,0-.79-.79h0a.81.81,0,0,0-.8.79v6.33H54.86a.82.82,0,0,0-.8.81.81.81,0,0,0,.8.79h6.32v6.33a.81.81,0,0,0,.8.79.79.79,0,0,0,.79-.79h0v-6.33h6.34a.81.81,0,0,0,.8-.79.82.82,0,0,0-.8-.81Z"
                    fill="#e2e2e2"
                    stroke="#e2e2e2"
                    strokeMiterlimit="10"
                    strokeWidth="0.75"
                />
                <path
                    id="Path_166"
                    d="M258.65,427.66h-6.34v-6.33a.79.79,0,0,0-.79-.79h0a.79.79,0,0,0-.8.79v6.33h-6.33a.8.8,0,0,0,0,1.59h6.33v6.34a.79.79,0,0,0,.8.78.78.78,0,0,0,.79-.78h0v-6.34h6.34a.8.8,0,0,0,0-1.59Z"
                    fill="#e2e2e2"
                    stroke="#e2e2e2"
                    strokeMiterlimit="10"
                    strokeWidth="0.75"
                />
                <path
                    id="Path_167"
                    d="M456.29,166.6H450v-6.33a.78.78,0,0,0-.78-.79h0a.81.81,0,0,0-.81.79v6.33H442a.8.8,0,1,0,0,1.59h6.32v6.34a.81.81,0,0,0,.81.79.79.79,0,0,0,.78-.79h0v-6.34h6.34a.8.8,0,1,0,0-1.59Z"
                    fill="#e2e2e2"
                    stroke="#e2e2e2"
                    strokeMiterlimit="10"
                    strokeWidth="0.75"
                />
                <circle
                    id="Ellipse_12"
                    cx="13.14"
                    cy="13.14"
                    r="13.14"
                    transform="translate(158.59 70.97)"
                    fill="#fff"
                    stroke="#e2e2e2"
                    strokeMiterlimit="10"
                    strokeWidth="2"
                />
                <circle
                    id="Ellipse_13"
                    cx="8"
                    cy="8"
                    r="8"
                    transform="translate(334.75 68.11)"
                    fill="#fff"
                    stroke="#e2e2e2"
                    strokeMiterlimit="10"
                    strokeWidth="2"
                />
                <circle
                    id="Ellipse_14"
                    cx="4.78"
                    cy="4.78"
                    r="4.78"
                    transform="translate(326.15 108.6)"
                    fill="#e3e2e3"
                />
                <path
                    id="Path_168"
                    d="M310.75,48.39A6.43,6.43,0,1,1,304.32,42a6.43,6.43,0,0,1,6.43,6.39Z"
                    fill="#e3e2e3"
                />
                <circle
                    id="Ellipse_15"
                    cx="4.78"
                    cy="4.78"
                    r="4.78"
                    transform="translate(350.68 409.84)"
                    fill="#e3e2e3"
                />
                <path
                    id="Path_169"
                    d="M312.18,415.32a6.43,6.43,0,1,1-6.43-6.43A6.43,6.43,0,0,1,312.18,415.32Z"
                    fill="#e3e2e3"
                />
                <path
                    id="Path_170"
                    d="M440.15,121.63a6.43,6.43,0,1,1-6.43-6.43,6.43,6.43,0,0,1,6.43,6.43Z"
                    fill="#e3e2e3"
                />
                <path
                    id="Path_171"
                    d="M191,130.53a6.18,6.18,0,1,1-6.17-6.18,6.18,6.18,0,0,1,6.17,6.18Z"
                    fill="#e3e2e3"
                />
                <path
                    id="Path_172"
                    d="M402.51,84.11a3.69,3.69,0,1,1-3.69-3.68,3.69,3.69,0,0,1,3.69,3.68Z"
                    fill="#e3e2e3"
                />
                <path
                    id="Path_173"
                    d="M123.85,80.43a3.68,3.68,0,1,1-1.074-2.609,3.68,3.68,0,0,1,1.074,2.609Z"
                    fill="#e3e2e3"
                />
                <path
                    id="Path_174"
                    d="M218.42,36.91a5.05,5.05,0,1,1-1.479-3.581,5.06,5.06,0,0,1,1.479,3.581Z"
                    fill="#e3e2e3"
                />
            </g>
        </SVGIcon>
    );
}
