import React from 'react';

import { SVGIcon } from '../../UIKit/index';

export default function DCCapterra(props) {
    return (
        <SVGIcon {...props} viewBox="0 0 454 344.983">
            <path fill="#FF9D28" d="M8.905 127.38l135.181.027 82.199.014V45.987z" />
            <path fill="#68C5ED" d="M226.285 45.987V333.49L328.95 7.534z" />
            <path fill="#044D80" d="M226.285 127.421l-82.199-.014 82.199 206.083z" />
            <path fill="#E54747" d="M8.905 127.38l156.26 52.905-21.079-52.878z" />
        </SVGIcon>
    );
}
