import React from 'react';

import { Div, IconButton, Span, ToolTip } from '../../UIKit/index';
import PropTypes from 'prop-types';
import './style.scss';

const DragBox = ({ title, disabled, buttons }) => {
    return (
        <Div className={'DragBoxMain ' + (disabled ? 'dragDisabled' : '')}>
            <Div className={'DBMTitle'}>{title}</Div>
            <Div className={'DBMBtns'}>
                {buttons?.map((item, index) => (
                    <IconButton key={index} onClick={item.onClick} customClass={'DBMBBtn'}>
                        {item?.tooltipMessage ? (
                            <Span style={{ color: item.iconColor }}>
                                <ToolTip content={item.tooltipMessage}>{item.iconName}</ToolTip>
                            </Span>
                        ) : (
                            <Span style={{ color: item.iconColor }}>{item.iconName}</Span>
                        )}
                    </IconButton>
                ))}
            </Div>
        </Div>
    );
};

DragBox.propTypes = {
    title: PropTypes.string,
    disabled: PropTypes.bool,
    buttons: PropTypes.arrayOf(PropTypes.object),
};

export default DragBox;
