import React from 'react';

import { SVGIcon } from '../../UIKit/index';

export default function DCNoProperty(props) {
    return (
        <SVGIcon {...props} viewBox="0 0 512 512">
            <g>
                <path
                    d="M423.68,141.89H130.84V75.01H415.94c4.27,0,7.73,3.46,7.73,7.73v59.15Z"
                    style={{ fill: '#a8bac2' }}
                />
                <path
                    d="M458.63,306.87h-43.2l.07,52.45h-53.29v44.01H21.32c-11.73,0-21.24-9.51-21.24-21.24V76.67c0-11.73,9.51-21.24,21.24-21.24h115.66l7.78,19.59,21.63,54.47,244.95-1.89c4.26-.04,8.4,.51,12.33,1.55,20.11,5.29,34.95,23.58,34.95,45.37v132.36Z"
                    style={{ fill: '#f1f8fc' }}
                />
                <rect
                    x=".08"
                    y="359.33"
                    width="362.13"
                    height="18.08"
                    style={{ fill: '#a8bac2' }}
                />
                <path
                    d="M220.68,186.99l2.54,86.68h12.27l2.54-86.68h-17.35Zm8.67,101.31c-6.14,0-10.66,4.7-10.66,11.2s4.33,11.19,10.66,11.19,10.66-4.87,10.66-11.19-4.15-11.2-10.66-11.2Z"
                    style={{ fill: '#a8bac2' }}
                />
            </g>
            <polygon
                points="511.92 376.09 458.63 376.09 458.63 322.81 431.44 322.81 431.44 376.09 378.15 376.09 378.15 403.29 431.44 403.29 431.44 456.57 458.63 456.57 458.63 403.29 511.92 403.29 511.92 376.09"
                style={{ fill: '#a8bac2' }}
            />
        </SVGIcon>
    );
}
