import React from 'react';

import { SVGIcon } from '../../UIKit/index';

export default function DCPDF(props) {
    return (
        <SVGIcon {...props} viewBox="0 0 385 512">
            <g>
                <path d="M381.48,109.71L274.79,3.02c-2-2-4.72-3.12-7.55-3.12H43.19C19.62-0.08,0.53,19.01,0.51,42.58     v426.76C0.53,492.9,19.62,512,43.19,512.01h298.73c23.56-0.02,42.66-19.11,42.68-42.68V117.26     C384.6,114.43,383.48,111.71,381.48,109.71z M277.9,36.23l70.27,70.36h-48.94c-11.78-0.02-21.32-9.56-21.34-21.34L277.9,36.23z      M363.14,469.25c-0.02,11.78-9.56,21.32-21.34,21.34H43.19c-11.78-0.02-21.32-9.56-21.34-21.34V42.58     c0.02-11.78,9.56-21.32,21.34-21.34h213.38v63.9c0.02,23.56,19.11,42.66,42.68,42.68h64.01L363.14,469.25z" />
                <path d="M246.95,313.09c-8.9-6.87-17.41-14.22-25.51-22.01c-8.14-8.11-15.37-15.99-21.66-23.51     c6.82-17.49,11.56-35.72,14.11-54.32c0-35.27-12.79-42.68-32.01-42.68c-14.7,0-32.01,7.58-32.01,43.7     c2.35,21.51,11.46,41.72,26.01,57.72c-4.23,12.9-9.2,27.8-14.7,44.59c-2.67,8.05-5.61,15.52-8.82,22.4     c-2.5,1.12-4.94,2.26-7.29,3.44c-8.49,4.23-16.55,8.05-24.01,11.61c-34.06,16.14-56.58,26.8-56.58,47.88     c0,15.28,16.61,24.78,32.01,24.78c19.84,0,49.79-26.45,71.69-71.13c23.78-8.76,48.29-15.37,73.24-19.75     c13.47,12.35,29.54,21.53,47.03,26.86c26.45,0,32.01-15.28,32.01-28.13c0-25.22-28.83-25.22-42.68-25.22     C267.44,309.95,257.15,311.21,246.95,313.09z M96.53,405.32c-3.84,0.1-7.61-1.11-10.67-3.44c0-7.55,22.54-18.25,44.38-28.6     l4.23-2C118.43,394.54,102.59,405.32,96.53,405.32L96.53,405.32z M171.21,214.28c0-22.37,6.94-22.37,10.67-22.37     c7.55,0,10.67,0,10.67,21.34c-1.7,11.37-4.55,22.53-8.49,33.33c-7.11-9.41-11.54-20.56-12.84-32.27V214.28z M179.38,328.91     c0.67-1.86,1.31-3.73,1.94-5.61c3.97-11.76,7.52-22.54,10.7-32.15c4.43,4.88,9.2,9.88,14.31,14.99c2,2,6.97,6.5,13.58,12.14     c-13.17,2.91-27.16,6.47-40.53,10.7V328.91z M299.24,334.46c0,4.79,0,6.79-9.9,6.85c-6.4-2.61-12.43-6.05-17.93-10.23     c2.94-0.32,5.23-0.5,6.47-0.5c15.9,0.06,20.25,1.59,21.37,3.94L299.24,334.46z" />
            </g>
        </SVGIcon>
    );
}
