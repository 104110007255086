import React from 'react';

import { SVGIcon } from '../../UIKit/index';

export default function DCDealCRM(props) {
    return (
        <SVGIcon {...props} viewBox="0 0 512 512">
            <g transform="translate(-5.737 -5.332)">
                <path
                    fill="#577797"
                    d="M43.82,65.96h435.21c17.43,0,31.56,14.13,31.56,31.56c0,0,0,0,0,0v298.74   c0,17.43-14.13,31.56-31.56,31.56l0,0H43.82c-17.43,0-31.56-14.13-31.56-31.56V97.52C12.26,80.09,26.39,65.96,43.82,65.96z"
                />
                <path
                    fill="#D9EAFF"
                    d="M479.03,65.96H43.82c-17.43,0-31.56,14.13-31.56,31.56v268.71H510.6V97.52   c0-17.43-14.12-31.56-31.55-31.56c0,0,0,0-0.01,0L479.03,65.96z"
                />
                <path
                    fill="#0372F8"
                    d="M160.41,491.34h202.04c5.65,0,10.23,4.58,10.23,10.23l0,0c0,5.65-4.58,10.23-10.23,10.23   H160.41c-5.65,0-10.23-4.58-10.23-10.23l0,0C150.17,495.93,154.75,491.34,160.41,491.34z"
                />
                <path fill="#0372F8" d="M307.68,427.83h-92.51l-9.5,63.51h111.49L307.68,427.83z" />
                <path
                    fill="#FFFFFF"
                    d="M402.22,385.78c-5.24,0-9.5,4.25-9.5,9.5c0,5.24,4.25,9.5,9.5,9.5c5.24,0,9.5-4.25,9.5-9.5l0,0   C411.72,390.03,407.46,385.78,402.22,385.78z"
                />
                <path
                    fill="#FFFFFF"
                    d="M435.46,385.78c-5.24,0.01-9.49,4.27-9.48,9.51c0.01,5.24,4.27,9.49,9.51,9.48   c5.24-0.01,9.49-4.27,9.48-9.51c0-2.52-1.01-4.93-2.79-6.71C440.39,386.77,437.97,385.78,435.46,385.78z"
                />
                <path
                    fill="#FFFFFF"
                    d="M468.66,385.78c-5.24,0-9.5,4.25-9.5,9.5c0,5.24,4.25,9.5,9.5,9.5c5.24,0,9.5-4.25,9.5-9.5l0,0   C478.15,390.03,473.9,385.78,468.66,385.78z"
                />
                <circle fill="#FFFFFF" cx="261.42" cy="176.65" r="96.82" />
                <path
                    fill="#0372F8"
                    d="M418.49,149.4h-23.57c-3.47-17.09-10.2-33.34-19.84-47.87l16.68-16.68   c3.81-3.81,3.81-9.98,0-13.79L367.02,46.3c-3.8-3.81-9.97-3.81-13.77-0.01c0,0-0.01,0.01-0.01,0.01l-16.68,16.68   c-14.53-9.64-30.79-16.38-47.87-19.85V19.58c0-5.38-4.36-9.74-9.73-9.75h-35.02c-5.38,0-9.75,4.36-9.75,9.75v23.55   c-17.09,3.48-33.34,10.22-47.87,19.86l-16.7-16.68c-3.8-3.81-9.98-3.81-13.78-0.01c0,0,0,0-0.01,0.01l-24.75,24.75   c-3.81,3.81-3.81,9.98,0,13.79l16.68,16.68c-9.64,14.53-16.38,30.79-19.86,47.87h-23.56c-5.38,0-9.75,4.36-9.75,9.75l0,0v35.02   c0,5.38,4.36,9.75,9.75,9.75h23.56c3.48,17.09,10.22,33.35,19.87,47.89l-16.68,16.68c-3.81,3.81-3.81,9.98,0,13.79l24.75,24.75   c3.8,3.81,9.98,3.81,13.78,0.01c0,0,0,0,0.01-0.01l16.68-16.68c14.53,9.63,30.78,16.37,47.87,19.84v23.53   c0,5.38,4.36,9.75,9.75,9.75h35.02c5.38,0,9.75-4.36,9.75-9.75l0,0v-23.54c17.08-3.47,33.34-10.2,47.87-19.84L353.23,307   c3.8,3.81,9.97,3.81,13.77,0.01c0,0,0.01-0.01,0.01-0.01l24.75-24.75c3.81-3.81,3.81-9.98,0-13.79l-16.68-16.66   c9.63-14.53,16.37-30.79,19.84-47.88h23.56c5.38,0.01,9.75-4.35,9.76-9.73c0-0.01,0-0.03,0-0.04v-35.02c0-5.38-4.36-9.75-9.75-9.75   L418.49,149.4z M261.42,273.44c-53.47,0-96.82-43.34-96.83-96.82c0-53.47,43.34-96.82,96.82-96.83s96.82,43.34,96.83,96.82   c0,0.01,0,0.02,0,0.04c0,53.47-43.35,96.81-96.82,96.82V273.44z"
                />
                <text
                    fontSize="65.2875px"
                    fontFamily="Roboto-Bold"
                    fill="#6B8AAA"
                    transform="matrix(1 0 0 1 191.7595 197.4961)"
                >
                    CRM
                </text>
            </g>
        </SVGIcon>
    );
}
