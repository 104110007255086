import React from 'react';

import { SVGIcon } from '../../UIKit/index';

export default function DCGrayVideo(props) {
    return (
        <SVGIcon {...props} viewBox="0 0 500.7 475.18">
            <g id="Explainer_Video" transform="translate(-4.3 -16.5)">
                <circle
                    id="Ellipse_9"
                    cx="214.18"
                    cy="214.18"
                    r="214.18"
                    transform="translate(75.14 18)"
                    fill="#fff"
                    stroke="#707171"
                    strokeMiterlimit="10"
                    strokeWidth="3"
                />
                <path
                    id="Path_120"
                    d="M352.91,195h107.2l-17.46,12.6v24.79a5.17,5.17,0,0,1-5.18,5.17H352.91a5.17,5.17,0,0,1-5.17-5.17V200.17A5.17,5.17,0,0,1,352.91,195Z"
                    fill="#f4f5f6"
                />
                <path
                    id="Path_121"
                    d="M357.39,212.52c0,.1,16.16.18,36.1.18s36.11-.08,36.11-.18-16.16-.19-36.11-.19S357.39,212.41,357.39,212.52Z"
                    fill="#707171"
                />
                <path
                    id="Path_122"
                    d="M357.39,222c0,.11,16.16.19,36.1.19s36.11-.08,36.11-.19-16.16-.18-36.11-.18S357.39,221.93,357.39,222Z"
                    fill="#707171"
                />
                <rect
                    id="Rectangle_89"
                    width="5.02"
                    height="16.8"
                    transform="translate(185.1 103.52)"
                    fill="#e3e2e3"
                />
                <rect
                    id="Rectangle_90"
                    width="5.02"
                    height="19.24"
                    transform="translate(143.2 101.08)"
                    fill="#e3e2e3"
                />
                <rect
                    id="Rectangle_91"
                    width="5.02"
                    height="23.25"
                    transform="translate(153.67 97.07)"
                    fill="#e3e2e3"
                />
                <rect
                    id="Rectangle_92"
                    width="5.02"
                    height="21.97"
                    transform="translate(164.15 98.35)"
                    fill="#e3e2e3"
                />
                <rect
                    id="Rectangle_93"
                    width="5.02"
                    height="29.41"
                    transform="translate(174.62 90.92)"
                    fill="#e3e2e3"
                />
                <rect
                    id="Rectangle_94"
                    width="5.02"
                    height="29.26"
                    transform="translate(195.57 91.06)"
                    fill="#e3e2e3"
                />
                <rect
                    id="Rectangle_95"
                    width="5.02"
                    height="19.65"
                    transform="translate(206.05 100.67)"
                    fill="#e3e2e3"
                />
                <rect
                    id="Rectangle_96"
                    width="5.02"
                    height="27.97"
                    transform="translate(216.52 92.35)"
                    fill="#e3e2e3"
                />
                <rect
                    id="Rectangle_97"
                    width="5.02"
                    height="34.28"
                    transform="translate(227 86.05)"
                    fill="#e3e2e3"
                />
                <rect
                    id="Rectangle_98"
                    width="5.02"
                    height="28.12"
                    transform="translate(237.47 92.21)"
                    fill="#e3e2e3"
                />
                <path
                    id="Path_123"
                    d="M374.66,118.76l41.49-6.33a42,42,0,1,1-36.88-35.38Z"
                    fill="#f4f5f6"
                />
                <path
                    id="Path_124"
                    d="M388.12,77.19l-5,32.15,31.56-5.93S410,85.39,388.12,77.19Z"
                    fill="#e3e2e3"
                />
                <path
                    id="Path_125"
                    d="M263.87,67.06a20.57,20.57,0,1,1,20.89,20.33A20.57,20.57,0,0,1,263.87,67.06Zm30.4-.49a9.84,9.84,0,1,0-9.68,10A9.84,9.84,0,0,0,294.27,66.57Z"
                    fill="#e3e2e3"
                />
                <path
                    id="Path_126"
                    d="M263.87,67.06a20.64,20.64,0,0,1,20.23-21l.18,10.78a9.881,9.881,0,1,0,.31,19.76l.17,10.78a20.63,20.63,0,0,1-20.89-20.32Z"
                    fill="#707171"
                />
                <path
                    id="Path_127"
                    d="M282.26,44.41a23.5,23.5,0,0,1,24.06,23.41l-12.55.2c-.11-6.37-5-10.38-11.3-10.28Z"
                    fill="#f4f5f6"
                />
                <path
                    id="Path_128"
                    d="M21.09,338.93v18c0,1.66.74,2.26,1.9,2.26s1.9-.6,1.9-2.26v-18h3.46v17.75c0,3.73-1.86,5.87-5.46,5.87s-5.46-2.14-5.46-5.87V338.93Z"
                    fill="#ececec"
                />
                <path
                    id="Path_129"
                    d="M38.51,362.25a7.22,7.22,0,0,1-.33-2.87v-3.66c0-2.17-.74-3-2.4-3H34.51v9.5H30.85V338.93h5.53c3.79,0,5.43,1.77,5.43,5.37v1.83c0,2.39-.77,4-2.4,4.73,1.83.76,2.43,2.53,2.43,5v3.6a6.82,6.82,0,0,0,.4,2.83Zm-4-20v7.16h1.43c1.37,0,2.2-.6,2.2-2.46v-2.3c0-1.66-.56-2.4-1.86-2.4Z"
                    fill="#ececec"
                />
                <path
                    id="Path_130"
                    d="M25,313.91a3.2,3.2,0,0,1-3.22,3.38,2.14,2.14,0,0,1-2.17-1.24L19,319.34h-.77l1.54-7.79h.74l-.18.91a2.85,2.85,0,0,1,2.21-.95,2.3,2.3,0,0,1,2.46,2.4Zm-.78,0a1.66,1.66,0,0,0-1.87-1.77,2.48,2.48,0,0,0-2.45,2.67,1.66,1.66,0,0,0,1.87,1.76A2.48,2.48,0,0,0,24.25,314Z"
                    fill="#e1e1e1"
                />
                <path
                    id="Path_131"
                    d="M31.92,313.36a3.9,3.9,0,0,1-.07.65l-.65,3.23h-.77l.65-3.25a2.351,2.351,0,0,0,.06-.51c0-.81-.49-1.28-1.47-1.28a2.22,2.22,0,0,0-2.32,2l-.6,3.05H26l1.6-8h.77l-.64,3.19a2.79,2.79,0,0,1,2.17-.89,1.79,1.79,0,0,1,2.02,1.81Z"
                    fill="#e1e1e1"
                />
                <path
                    id="Path_132"
                    d="M39.1,311.55,35,318c-.7,1.08-1.23,1.41-2.05,1.41a1.86,1.86,0,0,1-1.36-.51l.42-.56a1.3,1.3,0,0,0,1,.41c.49,0,.87-.22,1.31-.92l.4-.6-1.42-5.66H34l1.18,4.85,3.1-4.85Z"
                    fill="#e1e1e1"
                />
                <path
                    id="Path_133"
                    d="M344.23,388H228.84l18.8,13.57v26.68a5.57,5.57,0,0,0,5.57,5.57h91a5.57,5.57,0,0,0,5.57-5.57V393.61a5.57,5.57,0,0,0-5.55-5.61Z"
                    fill="#f4f5f6"
                />
                <path
                    id="Path_134"
                    d="M339.42,406.9c0,.11-17.41.2-38.87.2s-38.87-.09-38.87-.2,17.4-.2,38.87-.2S339.42,406.79,339.42,406.9Z"
                    fill="#707171"
                />
                <path
                    id="Path_135"
                    d="M339.42,417.15c0,.11-17.41.2-38.87.2s-38.87-.09-38.87-.2,17.4-.2,38.87-.2S339.42,417,339.42,417.15Z"
                    fill="#707171"
                />
                <path
                    id="Path_136"
                    d="M443.27,378.34c0,.11-11.33.2-25.3.2s-25.3-.09-25.3-.2,11.33-.19,25.3-.19S443.27,378.23,443.27,378.34Z"
                    fill="#1a2e35"
                />
                <path
                    id="Path_137"
                    d="M406.93,340S387,317,379.46,294s-3.95-20.31-3.95-20.31,10.16,22.29,17.87,33.06,21.45,26.57,21.45,26.57Z"
                    fill="#e1e1e1"
                />
                <path
                    id="Path_138"
                    d="M410.9,277.3a1.16,1.16,0,0,0-2.16.74c1.21,8.06,3.84,27,3.74,39.25-.14,15.94,0,22.1,0,22.1h8.3s-1.47-32.95-3.34-43C416.12,289.35,413.33,281.53,410.9,277.3Z"
                    fill="#e1e1e1"
                />
                <path
                    id="Path_139"
                    d="M415.43,336.71a88,88,0,0,0,6.83-24.78c.68-5.86-.54-13.79-2.35-24.38a65.32,65.32,0,0,1-1.05-11.73,2,2,0,0,1,3.72-.92c1,1.78,3.9,7.89,4.63,11.72,2,10.44,4.29,15.67,2.55,25.58s-8.17,25.18-8.17,25.18Z"
                    fill="#e1e1e1"
                />
                <path
                    id="Path_140"
                    d="M422.9,337.38s21.9-47.05,23.41-54,6.68-23.06,7.29-22.15,1.51,9.4-2.43,19.12-20.79,58.5-20.79,58.5H422.9"
                    fill="#e1e1e1"
                />
                <path
                    id="Path_141"
                    d="M413,335.28a4.785,4.785,0,0,1-.62-.42c-.38-.3-1-.71-1.69-1.3-1.46-1.14-3.5-2.89-5.89-5.19A99.241,99.241,0,0,1,389,308a133.526,133.526,0,0,1-10.84-23.49c-1.1-3.12-1.91-5.68-2.43-7.46-.27-.88-.45-1.58-.58-2a3.69,3.69,0,0,1-.17-.72,3.119,3.119,0,0,1,.26.69l.67,2c.57,1.76,1.42,4.3,2.56,7.4a142,142,0,0,0,10.93,23.36,102.679,102.679,0,0,0,15.72,20.39c2.34,2.33,4.35,4.1,5.76,5.29.7.61,1.27,1,1.64,1.36a3.328,3.328,0,0,1,.48.46Z"
                    fill="#fff"
                />
                <path
                    id="Path_142"
                    d="M415.42,312.71a8.062,8.062,0,0,1-.19-1.38c-.11-1-.24-2.25-.41-3.78-.34-3.18-.88-7.57-1.66-12.39s-1.64-9.16-2.3-12.3c-.33-1.5-.6-2.75-.8-3.7a8.253,8.253,0,0,1-.25-1.38,8.57,8.57,0,0,1,.42,1.33c.25.87.57,2.12.95,3.68.75,3.12,1.67,7.46,2.44,12.29s1.27,9.25,1.53,12.44c.14,1.6.22,2.89.26,3.79a6.867,6.867,0,0,1,.01,1.4Z"
                    fill="#fff"
                />
                <path
                    id="Path_143"
                    d="M420.55,274.53a2.51,2.51,0,0,1,.26.59l.66,1.75c.29.76.62,1.7.94,2.8s.74,2.35,1.06,3.77c.17.71.35,1.45.54,2.21s.32,1.59.49,2.43c.35,1.67.57,3.48.84,5.38a80.649,80.649,0,0,1-.89,24.87c-.4,1.88-.75,3.67-1.23,5.31-.23.82-.41,1.63-.65,2.38s-.48,1.48-.7,2.17c-.42,1.39-.93,2.6-1.33,3.68s-.8,2-1.14,2.73-.58,1.26-.78,1.69a3.266,3.266,0,0,1-.3.58,3.049,3.049,0,0,1,.21-.62c.18-.43.41-1,.7-1.72s.69-1.66,1.06-2.75.87-2.3,1.26-3.69c.22-.69.44-1.42.67-2.17s.41-1.56.63-2.38c.45-1.63.79-3.42,1.17-5.29a88.8,88.8,0,0,0,1.37-12.34,91.133,91.133,0,0,0-.48-12.4c-.25-1.89-.46-3.7-.8-5.36-.15-.84-.28-1.65-.45-2.42s-.35-1.5-.51-2.21c-.3-1.42-.69-2.66-1-3.77s-.6-2.05-.87-2.82-.42-1.32-.57-1.77a5.754,5.754,0,0,1-.16-.63Z"
                    fill="#fff"
                />
                <path
                    id="Path_144"
                    d="M453.35,262.14a1.663,1.663,0,0,1,0,.21,4.49,4.49,0,0,1-.1.61c-.09.54-.22,1.33-.43,2.35-.42,2-1.13,4.93-2.22,8.47s-2.6,7.68-4.38,12.22-3.82,9.52-5.76,14.8-3.5,10.42-4.93,15.09-2.76,8.87-4.06,12.34a78,78,0,0,1-3.45,8c-.45.94-.85,1.63-1.11,2.11l-.31.54c-.07.12-.11.18-.12.17s0-.07.08-.19l.26-.56c.24-.5.61-1.2,1-2.14.89-1.87,2.07-4.61,3.31-8.09s2.57-7.66,4-12.34,2.92-9.84,4.9-15.13,4-10.26,5.8-14.8,3.31-8.66,4.44-12.17,1.89-6.41,2.36-8.41c.24-1,.39-1.79.52-2.32.06-.24.1-.44.14-.61a.8.8,0,0,1,.06-.15Z"
                    fill="#fff"
                />
                <path
                    id="Path_145"
                    d="M385.83,251.75a1.88,1.88,0,0,0-3.1,1.92,54.888,54.888,0,0,0,2.75,7.08c3,6.39,11.85,29.34,16,41.95s10,36.12,10,36.12l8.84-1.44s-8.09-38.63-16-52.55C397.94,273.64,391.6,257.48,385.83,251.75Z"
                    fill="#e1e1e1"
                />
                <path
                    id="Path_146"
                    d="M403.89,310.58a5.879,5.879,0,0,1,.46,1.06c.26.69.61,1.7,1,3,.82,2.52,1.81,6,2.82,9.95s1.83,7.47,2.41,10.06c.27,1.22.49,2.24.67,3.06a5.154,5.154,0,0,1,.18,1.14,5.881,5.881,0,0,1-.36-1.1c-.21-.8-.48-1.82-.81-3l-2.54-10c-1-3.91-2-7.44-2.68-10-.35-1.2-.64-2.2-.88-3A5.453,5.453,0,0,1,403.89,310.58Z"
                    fill="#e1e1e1"
                />
                <path
                    id="Path_147"
                    d="M415.88,338.1a1.169,1.169,0,0,1-.05-.24,6.332,6.332,0,0,0-.1-.69c-.09-.61-.17-1.52-.36-2.68-.32-2.32-.9-5.67-1.85-9.75a141.348,141.348,0,0,0-4.14-14.12c-1.81-5.23-4.09-10.89-6.66-16.75-5.2-11.69-10.4-22.07-13.81-29.74-1.73-3.82-3.06-6.95-3.94-9.13-.45-1.08-.77-1.93-1-2.51l-.25-.66a1.049,1.049,0,0,1-.07-.23.721.721,0,0,1,.11.21c.08.17.18.38.29.65l1.08,2.47c.93,2.16,2.31,5.26,4.07,9.06,3.49,7.63,8.73,18,13.93,29.7,2.58,5.86,4.85,11.54,6.65,16.79a134.5,134.5,0,0,1,4,14.19,98.705,98.705,0,0,1,1.71,9.8c.16,1.16.21,2.07.28,2.69a2.9,2.9,0,0,0,.05.7.729.729,0,0,1,.06.24Z"
                    fill="#fff"
                />
                <path
                    id="Path_148"
                    d="M420.28,337.38a8.916,8.916,0,0,1-.3-1.48c-.17-1-.41-2.41-.69-4.07-.61-3.42-1.53-8.14-2.87-13.27s-2.82-9.71-4-13c-.57-1.58-1-2.89-1.39-3.88a9.794,9.794,0,0,1-.47-1.44,8.609,8.609,0,0,1,.64,1.37c.38.9.92,2.2,1.53,3.84a129.106,129.106,0,0,1,4.1,13c1.34,5.15,2.22,9.9,2.75,13.34.25,1.73.44,3.12.54,4.09a9.346,9.346,0,0,1,.16,1.5Z"
                    fill="#e1e1e1"
                />
                <rect
                    id="Rectangle_99"
                    width="34.5"
                    height="42.03"
                    transform="translate(403.92 336.11)"
                    fill="#707171"
                />
                <g id="Group_2" opacity="0.3">
                    <rect
                        id="Rectangle_100"
                        width="34.5"
                        height="42.03"
                        transform="translate(403.92 336.11)"
                    />
                </g>
                <rect
                    id="Rectangle_101"
                    width="30.61"
                    height="42.03"
                    transform="translate(396.81 336.11)"
                    fill="#707171"
                />
                <rect
                    id="Rectangle_102"
                    width="349.85"
                    height="231.35"
                    rx="14.57"
                    transform="translate(15.76 141.69)"
                    fill="#707171"
                />
                <path
                    id="Path_149"
                    d="M4.3,358.1h379V364A14.36,14.36,0,0,1,369,378.34H18.66A14.36,14.36,0,0,1,4.3,364Z"
                    fill="#707171"
                />
                <path
                    id="Path_150"
                    d="M156.19,355.93l4.71,7.48h66.18l4.37-7.48Z"
                    fill="#fff"
                    opacity="0.3"
                />
                <g id="Group_3" opacity="0.5">
                    <path
                        id="Path_151"
                        d="M362.6,355.93c0,.12-77.65.22-173.41.22s-173.43-.1-173.43-.22,77.63-.23,173.43-.23S362.6,355.8,362.6,355.93Z"
                        fill="#fff"
                    />
                </g>
                <rect
                    id="Rectangle_103"
                    width="328.44"
                    height="194.82"
                    rx="3.27"
                    transform="translate(25.78 154.99)"
                    fill="#e3e2e3"
                />
                <path id="Path_152" d="M353.87,350.1H25.42l.36-180.55H353.87Z" fill="#fff" />
                <path
                    id="Path_153"
                    d="M52.71,162.52a2.62,2.62,0,1,1-.764-1.859,2.62,2.62,0,0,1,.764,1.859Z"
                    fill="#707171"
                />
                <path
                    id="Path_154"
                    d="M36.82,162.52a2.63,2.63,0,1,1-2.63-2.63,2.63,2.63,0,0,1,2.63,2.63Z"
                    fill="#707171"
                />
                <path
                    id="Path_155"
                    d="M44.45,162.52a2.63,2.63,0,1,1-2.63-2.63A2.63,2.63,0,0,1,44.45,162.52Z"
                    fill="#707171"
                />
                <rect
                    id="Rectangle_104"
                    width="328.79"
                    height="17.43"
                    transform="translate(25.42 332.66)"
                    fill="#e3e2e3"
                />
                <path
                    id="Path_156"
                    d="M37.85,343.4v.6H35.23v-.45l1.41-1.34c.34-.33.4-.53.4-.73a.606.606,0,0,0-.66-.51,1,1,0,0,0-.83.37l-.46-.36a1.64,1.64,0,0,1,1.35-.59c.77,0,1.27.39,1.27,1a1.42,1.42,0,0,1-.59,1.12l-.95.91Z"
                    fill="#707171"
                />
                <path
                    id="Path_157"
                    d="M38.2,341.64a.41.41,0,0,1,.42-.43.43.43,0,0,1,0,.85.42.42,0,0,1-.42-.42Zm0,1.93a.41.41,0,0,1,.42-.42.4.4,0,0,1,.41.42.41.41,0,0,1-.41.42.42.42,0,0,1-.42-.42Z"
                    fill="#707171"
                />
                <path id="Path_158" d="M40.69,340.4V344H40v-3h-.75v-.56Z" fill="#707171" />
                <path
                    id="Path_159"
                    d="M44.56,343.15h-.63V344h-.64v-.81H41.34v-.46l1.75-2.29h.71l-1.65,2.19h1.16v-.72h.62v.72h.63Z"
                    fill="#707171"
                />
                <rect
                    id="Rectangle_105"
                    width="269.49"
                    height="1.23"
                    transform="translate(50.36 341.73)"
                    fill="#fff"
                />
                <rect
                    id="Rectangle_106"
                    width="84.22"
                    height="3.28"
                    transform="translate(50.16 340.41)"
                    fill="#707171"
                />
                <path
                    id="Path_160"
                    d="M137.09,342.34a4.09,4.09,0,1,1-1.2-2.9A4.1,4.1,0,0,1,137.09,342.34Z"
                    fill="#707171"
                />
                <path
                    id="Path_161"
                    d="M326.64,342.3a1.19,1.19,0,0,1-1.3,1.16c-1,0-1.54-.63-1.54-1.77a1.68,1.68,0,0,1,1.74-1.89,1.82,1.82,0,0,1,.94.23l-.25.5a1.16,1.16,0,0,0-.67-.17c-.67,0-1.09.41-1.09,1.22h0a1.2,1.2,0,0,1,.95-.37,1.11,1.11,0,0,1,1.22,1.09Zm-.64,0c0-.37-.28-.6-.71-.6a.64.64,0,0,0-.72.61c0,.33.26.6.74.6a.62.62,0,0,0,.69-.58Z"
                    fill="#707171"
                />
                <path
                    id="Path_162"
                    d="M327,341.09a.42.42,0,1,1,.42.42.41.41,0,0,1-.42-.42Zm0,1.93a.446.446,0,1,0,0-.02Z"
                    fill="#707171"
                />
                <path
                    id="Path_163"
                    d="M328.22,341.63c0-1.17.64-1.83,1.48-1.83s1.49.66,1.49,1.83-.64,1.83-1.49,1.83S328.22,342.8,328.22,341.63Zm2.3,0c0-.86-.33-1.25-.82-1.25s-.82.39-.82,1.25.34,1.25.82,1.25S330.52,342.49,330.52,341.63Z"
                    fill="#707171"
                />
                <path
                    id="Path_164"
                    d="M331.63,341.63c0-1.17.64-1.83,1.48-1.83s1.49.66,1.49,1.83-.64,1.83-1.49,1.83S331.63,342.8,331.63,341.63Zm2.3,0c0-.86-.33-1.25-.82-1.25s-.82.39-.82,1.25.34,1.25.82,1.25S333.93,342.49,333.93,341.63Z"
                    fill="#707171"
                />
                <path id="Path_165" d="M340.34,341.41h-1.23v-3.54h3.53v1.23h-2.3Z" fill="#707171" />
                <path id="Path_166" d="M342.64,346.03H339.1V342.5h1.23v2.3h2.31Z" fill="#707171" />
                <path
                    id="Path_167"
                    d="M348.29,346.03h-3.54V344.8h2.31v-2.31h1.23Z"
                    fill="#707171"
                />
                <path id="Path_168" d="M348.29,341.4h-1.23v-2.3h-2.31v-1.23h3.54Z" fill="#707171" />
                <path
                    id="Path_169"
                    d="M70.46,180.29H51.1l-9.67,16.76,9.67,16.77H70.46l9.68-16.77Z"
                    fill="#f4f5f6"
                    stroke="#707171"
                    strokeMiterlimit="10"
                    strokeWidth="1"
                />
                <path
                    id="Path_170"
                    d="M52.69,200.33a16.64,16.64,0,0,1-2.37-3.05,5.79,5.79,0,0,1-.8-3.71c.12-.75.44-1.46.49-2.21.07-1-.37-2.12,0-3.09.57-1.52,2.62-1.75,3.86-2.8,1-.82,1.43-2.14,2.49-2.83a4.66,4.66,0,0,1,3.21-.43,12.25,12.25,0,0,0,3.29.28,20.175,20.175,0,0,0,2.28-.61,3.09,3.09,0,0,1,2.3.16,1.49,1.49,0,0,1,.61,2,2.5,2.5,0,0,1,3.23,1.76,3.79,3.79,0,0,1-1.42,3.7A8.41,8.41,0,0,1,66,191.08a16,16,0,0,1-11.45-1.79"
                    fill="#707171"
                />
                <path
                    id="Path_171"
                    d="M70.63,204.06c-.13-2.35-.8-7.16-1.46-11.52a9.24,9.24,0,0,0-9.79-7.73H58.9c-5.15.64-7.47,5.27-6.68,10.29l1.51,18.08H66.29L66,210S70.9,208.93,70.63,204.06Z"
                    fill="#e3e2e3"
                />
                <g id="Group_4" opacity="0.3">
                    <path
                        id="Path_172"
                        d="M66,209.94a11.71,11.71,0,0,1-6.59-1.38s1.77,3.4,6.69,2.64Z"
                        fill="#707171"
                    />
                </g>
                <path
                    id="Path_173"
                    d="M67.57,196.71a.7.7,0,0,1-.63.75.69.69,0,0,1-.78-.58.71.71,0,0,1,.64-.75.68.68,0,0,1,.77.58Z"
                    fill="#707171"
                />
                <path
                    id="Path_174"
                    d="M68.27,195c-.08.09-.65-.26-1.41-.21s-1.3.46-1.39.38,0-.21.27-.4a2,2,0,0,1,1.1-.41,1.82,1.82,0,0,1,1.13.28C68.22,194.79,68.31,194.94,68.27,195Z"
                    fill="#707171"
                />
                <path
                    id="Path_175"
                    d="M60.36,197.22a.69.69,0,0,1-.63.74.66.66,0,0,1-.77-.57.7.7,0,0,1,.63-.75.66.66,0,0,1,.77.58Z"
                    fill="#707171"
                />
                <path
                    id="Path_176"
                    d="M60.78,195.62c-.09.1-.65-.26-1.41-.21s-1.3.47-1.39.38,0-.21.27-.4a2,2,0,0,1,1.1-.41,1.89,1.89,0,0,1,1.13.28C60.72,195.42,60.82,195.58,60.78,195.62Z"
                    fill="#707171"
                />
                <path
                    id="Path_177"
                    d="M63.92,201a5.82,5.82,0,0,1,1.23-.3c.19,0,.37-.08.4-.22a1,1,0,0,0-.17-.55l-.68-1.42a25.149,25.149,0,0,1-1.55-3.71A24.731,24.731,0,0,1,65,198.36l.66,1.43a1.07,1.07,0,0,1,.15.74.5.5,0,0,1-.3.3,2.459,2.459,0,0,1-.33.07,5.191,5.191,0,0,1-1.26.1Z"
                    fill="#707171"
                />
                <path
                    id="Path_178"
                    d="M60.77,192.68c-.06.21-.83.16-1.72.32s-1.59.45-1.72.28.05-.28.33-.5a3.08,3.08,0,0,1,1.26-.53,3,3,0,0,1,1.36.07C60.62,192.43,60.8,192.58,60.77,192.68Z"
                    fill="#707171"
                />
                <path
                    id="Path_179"
                    d="M67.84,192.84c-.12.18-.66,0-1.29.07s-1.16.19-1.3,0,0-.25.24-.43a1.87,1.87,0,0,1,1-.35,1.85,1.85,0,0,1,1,.28C67.81,192.59,67.89,192.76,67.84,192.84Z"
                    fill="#707171"
                />
                <path
                    id="Path_180"
                    d="M52.87,199c-.09,0-3.48-.78-3.14,2.61s4,2.28,4,2.18S52.87,199,52.87,199Z"
                    fill="#e3e2e3"
                />
                <path
                    id="Path_181"
                    d="M52.23,202.68s-.06,0-.15.1a.61.61,0,0,1-.44.06,1.49,1.49,0,0,1-.81-1.25,2,2,0,0,1,.08-.86.71.71,0,0,1,.4-.5.31.31,0,0,1,.37.13c0,.09,0,.16.06.16s.06-.06,0-.2a.44.44,0,0,0-.15-.2.48.48,0,0,0-.34-.05.82.82,0,0,0-.56.59,1.91,1.91,0,0,0-.11,1,1.54,1.54,0,0,0,1,1.38.62.62,0,0,0,.53-.14C52.23,202.76,52.24,202.68,52.23,202.68Z"
                    fill="#707171"
                />
                <path
                    id="Path_182"
                    d="M68.92,188.07c-.23,1.35-3,2.58-5.23,2.52-2.09-.07-3.13-1.1-4.65-1.84a2.538,2.538,0,0,0-2.26-.25c-1.06.17-1.6,1.41-2.2,2.3,1.13,2.46.68,5.32.2,8a1.34,1.34,0,0,1-.35.81,1.07,1.07,0,0,1-1.37-.2A4.25,4.25,0,0,1,52,197.23a13.84,13.84,0,0,1-.33-5.51,9.5,9.5,0,0,1,2.29-5,9,9,0,0,1,6.16-2.81,11.08,11.08,0,0,1,6.57,1.87,6.46,6.46,0,0,1,2.24,2.26"
                    fill="#707171"
                />
                <path
                    id="Path_183"
                    d="M64.42,203l-1.72-.81a.72.72,0,0,0-.32-.08.52.52,0,0,0-.3.21,1.6,1.6,0,0,0-.19,1.76,1.35,1.35,0,0,0,1.62.6,1.87,1.87,0,0,0,1-1.63Z"
                    fill="#e3e2e3"
                />
                <path
                    id="Path_184"
                    d="M63.57,204.84a1.14,1.14,0,0,1-1,.09,1.57,1.57,0,0,1-.89-.73,1.69,1.69,0,0,1,.3-2,1.289,1.289,0,0,1,.3-.22.51.51,0,0,1,.45,0c.4.22.66.41.93.58.5.34.8.57.78.61a2.813,2.813,0,0,1-.92-.37l-.92-.48h-.14a1.18,1.18,0,0,0-.21.18,1.39,1.39,0,0,0-.32.49A1.31,1.31,0,0,0,62,204a1.37,1.37,0,0,0,.67.64,3.71,3.71,0,0,0,.9.2Z"
                    fill="#707171"
                />
                <path
                    id="Path_185"
                    d="M63,197.05a2.915,2.915,0,0,0-.18-.84,3.53,3.53,0,0,0-1.3-1.86,3.37,3.37,0,1,0,0,5.4,3.51,3.51,0,0,0,1.3-1.85c.15-.53.14-.85.18-.85a.72.72,0,0,1,0,.23,2.51,2.51,0,0,1-.07.65,3.46,3.46,0,0,1-1.27,2,3.65,3.65,0,0,1-3.49.57,3.7,3.7,0,0,1,0-7,3.65,3.65,0,0,1,3.49.57,3.57,3.57,0,0,1,1.27,2,2.51,2.51,0,0,1,.07.65.72.72,0,0,1,0,.33Z"
                    fill="#1a2e35"
                    opacity="0.5"
                />
                <path
                    id="Path_186"
                    d="M71.47,196.72a2.742,2.742,0,0,0-.18-.82A3.43,3.43,0,0,0,70,194.09a3.28,3.28,0,1,0-3.11,5.7,3.34,3.34,0,0,0,3.12-.44,3.47,3.47,0,0,0,1.26-1.81c.15-.51.14-.82.18-.82a.68.68,0,0,1,0,.22,2.79,2.79,0,0,1-.06.64,3.44,3.44,0,0,1-1.25,2,3.61,3.61,0,1,1,0-5.7,3.44,3.44,0,0,1,1.25,2,2.79,2.79,0,0,1,.06.64A.68.68,0,0,1,71.47,196.72Z"
                    fill="#1a2e35"
                    opacity="0.5"
                />
                <path
                    id="Path_187"
                    d="M64.58,195.94c-.08.07-.42-.15-.87-.13s-.78.23-.86.16.22-.54.85-.55S64.68,195.88,64.58,195.94Z"
                    fill="#1a2e35"
                    opacity="0.5"
                />
                <path
                    id="Path_188"
                    d="M56.16,196.55c.06.09-.59.69-1.47,1.34a5.081,5.081,0,0,1-1.7,1c-.06-.08.59-.68,1.47-1.34a5,5,0,0,1,1.7-1Z"
                    fill="#1a2e35"
                    opacity="0.5"
                />
                <path
                    id="Path_189"
                    d="M126,196.88c0,.11-8.7.2-19.43.2s-19.44-.09-19.44-.2,8.7-.2,19.44-.2S126,196.77,126,196.88Z"
                    fill="#707171"
                />
                <path
                    id="Path_190"
                    d="M108,201c0,.11-4.62.19-10.32.19s-10.32-.08-10.32-.19,4.62-.2,10.32-.2S108,200.84,108,201Z"
                    fill="#707171"
                />
                <path
                    id="Path_191"
                    d="M242.44,226.94a29.63,29.63,0,0,1,14,18c3.63,13,14.74,46.85,14.74,46.85s6.35,7.47,7,13-.68,18.91-2.72,21.41a5.76,5.76,0,0,1-4.95,2.08l-26.73-2.53-10.52-70.47Z"
                    fill="#707171"
                />
                <path
                    id="Path_192"
                    d="M154.06,225.71s-19.11,6-23.88,11.21l15.65,72.79-.25,23h90.6c0-1.13.82-76.26.82-76.26l5.44-29.48-32.2-5.67-6.35-5-35.83,1.81-5.45,5.45Z"
                    fill="#707171"
                />
                <g id="Group_5" opacity="0.3">
                    <path
                        id="Path_193"
                        d="M161.32,288.86c-.28-.62-.68-1.28-1.35-1.42-1.09-.23-1.82,1.06-2.19,2.11a95.591,95.591,0,0,1-11.9,23.24L145.65,324c5.28-4.44,10.49-9.21,13.85-15.23S164.16,295.15,161.32,288.86Z"
                    />
                </g>
                <path
                    id="Path_194"
                    d="M130.18,236.92s-6.29,8.58-8.91,14.18S97,303.77,95.69,308.63,91,328.8,100.91,332.35h20.36L141.71,291l1-48Z"
                    fill="#707171"
                />
                <path
                    id="Path_195"
                    d="M122.58,302.5a9.4,9.4,0,0,0-1.39-.93,15.6,15.6,0,0,0-4.28-1.55,19.9,19.9,0,0,0-3.11-.44,12.17,12.17,0,0,0-3.57.24,13.33,13.33,0,0,0-6.86,4.3,18.54,18.54,0,0,0-3.88,7.21A35.8,35.8,0,0,0,98.27,318c-.19,1.93-.26,3.5-.33,4.58,0,.5-.06.92-.08,1.24a2.189,2.189,0,0,1,0,.44,1.67,1.67,0,0,1,0-.44v-1.25a42.5,42.5,0,0,1,.2-4.59,33.92,33.92,0,0,1,1.16-6.74,18.54,18.54,0,0,1,3.93-7.37,13.48,13.48,0,0,1,7.07-4.4,12.479,12.479,0,0,1,3.67-.2,18.925,18.925,0,0,1,3.15.49,14.45,14.45,0,0,1,4.29,1.69,6.241,6.241,0,0,1,1,.75A1.739,1.739,0,0,1,122.58,302.5Z"
                    fill="#1a2e35"
                />
                <path
                    id="Path_196"
                    d="M112.92,294.28a2.209,2.209,0,0,1,.69-.12,12.459,12.459,0,0,1,1.89,0,15.1,15.1,0,0,1,2.77.41,17.8,17.8,0,0,1,3.24,1.15,17.281,17.281,0,0,1,2.94,1.8,15.81,15.81,0,0,1,2.07,1.9,10.86,10.86,0,0,1,1.16,1.48,2.4,2.4,0,0,1,.35.6c-.06,0-.61-.77-1.72-1.89a17.406,17.406,0,0,0-2.08-1.81,20.132,20.132,0,0,0-2.88-1.73,19.461,19.461,0,0,0-3.17-1.15,16.743,16.743,0,0,0-2.71-.48C113.91,294.26,112.93,294.35,112.92,294.28Z"
                    fill="#1a2e35"
                />
                <path
                    id="Path_197"
                    d="M131.33,292a4.119,4.119,0,0,1-.94-.07c-.6-.08-1.48-.17-2.55-.25a44.7,44.7,0,0,0-8.42.19,43.669,43.669,0,0,0-8.23,1.81c-1,.33-1.86.62-2.42.84a4.881,4.881,0,0,1-.9.29,4.141,4.141,0,0,1,.84-.44c.55-.26,1.36-.59,2.39-1a38.5,38.5,0,0,1,16.76-2,24.487,24.487,0,0,1,2.55.37,4.428,4.428,0,0,1,.92.26Z"
                    fill="#1a2e35"
                />
                <path
                    id="Path_198"
                    d="M189.32,251.8a1.579,1.579,0,0,1-.21-.25l-.56-.74-2-2.84-7.09-10,.31-.05-3.58,10.52-.08.24-.21-.14-.42-.27a21.83,21.83,0,0,1-4.43-3.74,26.491,26.491,0,0,1-3.17-4.38,36.41,36.41,0,0,1-3.48-8.42,59.412,59.412,0,0,1-1.32-6c-.12-.69-.21-1.23-.28-1.65a1.433,1.433,0,0,0-.1-.41v-.14a6.38,6.38,0,0,1,.58,2.17c.29,1.41.7,3.47,1.44,5.95a37,37,0,0,0,3.53,8.31,25.812,25.812,0,0,0,3.13,4.3,21.64,21.64,0,0,0,4.36,3.67l.42.27-.29.1c1.28-3.73,2.51-7.28,3.61-10.5l.12-.34.2.29,7,10.09,2,2.89q.3.48.51.78a2.421,2.421,0,0,1,.01.29Z"
                    fill="#1a2e35"
                />
                <g id="Group_6" opacity="0.3">
                    <path
                        id="Path_199"
                        d="M212.9,286.55A41.13,41.13,0,0,0,218.14,311c4.21,7.33,10.39,13.87,18.09,17.38.25-7.48-.11-16.28.14-23.75Z"
                    />
                </g>
                <path
                    id="Path_200"
                    d="M212.05,276.71s-3.29.92-.82,7.66,3.29,6.33,4.93,12.64,3.57,12.06,6.45,15.63,13.57,10.83,13.57,10.83l9.6-9.05s-1.58-6.74-1.2-11.32,1.34-15.83,1.2-17.75-2.19-6.58-2.19-6.58v-3.19l-4.94-15.18s-2.74-1.24-3.7,3.15l-1.65-4.66s-2.6-.41-3.29,4.93.55,12.76.55,12.76l-1.37,9.32-2.06,6.31-1.78.27s-2.68-.73-4.15-5.78S215.75,275.62,212.05,276.71Z"
                    fill="#e3e2e3"
                />
                <g id="Group_7" opacity="0.3">
                    <path
                        id="Path_201"
                        d="M242.57,300.56a1.26,1.26,0,0,1,0-.34l-.06-1c-.05-.92-.12-2.19-.21-3.77-.15-3.28-.35-7.83-.59-13.15v.11a1.92,1.92,0,0,0-1.94-.51,2.83,2.83,0,0,0-1.77,1.3c-.84,1.35-.88,3.1-1.15,4.8-.52,3.84-1,7.5-1.46,10.84-.23,1.66-.47,3.25-.8,4.72a30.216,30.216,0,0,1-1.19,4,33.7,33.7,0,0,0-1,3.25,9.7,9.7,0,0,0-.31,2.54,7.82,7.82,0,0,0,.38,2.15,3,3,0,0,1-.23-.52,6.15,6.15,0,0,1-.3-1.62,9.369,9.369,0,0,1,.25-2.6,29.757,29.757,0,0,1,1-3.29,32.757,32.757,0,0,0,1.13-4c.31-1.46.54-3,.76-4.7.44-3.34.93-7,1.44-10.84.12-.86.21-1.7.36-2.55a6.659,6.659,0,0,1,.84-2.41,3.26,3.26,0,0,1,2-1.47,2.3,2.3,0,0,1,2.31.64h0v.06c.18,5.32.34,9.88.45,13.16,0,1.58.07,2.86.1,3.78v1a1.3,1.3,0,0,1-.01.42Z"
                        fill="#707171"
                    />
                </g>
                <g id="Group_8" opacity="0.3">
                    <path
                        id="Path_202"
                        d="M237.32,283.28a4.3,4.3,0,0,1-.4-.85,20.751,20.751,0,0,1-.81-2.46,25,25,0,0,1-.69-3.78,11.57,11.57,0,0,1,0-2.29,4.87,4.87,0,0,1,.83-2.35,3.35,3.35,0,0,1,1.76-1.32h.14l.08.13c1.39,2.45,2.6,4.62,3.44,6.18l1,1.84a3.161,3.161,0,0,1,.31.69,3.38,3.38,0,0,1-.43-.63c-.29-.48-.66-1.07-1.08-1.78-.91-1.52-2.16-3.66-3.57-6.11l.23.09a2.93,2.93,0,0,0-1.55,1.18,4.62,4.62,0,0,0-.77,2.16,11.719,11.719,0,0,0,0,2.22,28.5,28.5,0,0,0,.6,3.74c.24,1.06.5,1.9.68,2.48A4.891,4.891,0,0,1,237.32,283.28Z"
                        fill="#707171"
                    />
                </g>
                <g id="Group_9" opacity="0.3">
                    <path
                        id="Path_203"
                        d="M235,284.52a3.481,3.481,0,0,1-.09-.88c0-.64-.06-1.44-.1-2.38-.06-2-.13-4.8-.17-7.88a69.5,69.5,0,0,1,.12-7.89,11.861,11.861,0,0,1,.52-2.35,2.809,2.809,0,0,1,.35-.81,22.318,22.318,0,0,0-.59,3.19c-.19,2,0,4.78,0,7.86s.06,5.86.06,7.87v2.39A4.089,4.089,0,0,1,235,284.52Z"
                        fill="#707171"
                    />
                </g>
                <g id="Group_10" opacity="0.3">
                    <path
                        id="Path_204"
                        d="M226.91,309.8c-.06,0,.55-.75,1.29-2a17.8,17.8,0,0,0,1.12-2.34,19.1,19.1,0,0,0,.85-3.06,18.823,18.823,0,0,0,.27-3.16,17,17,0,0,0-.23-2.58c-.25-1.46-.56-2.33-.5-2.36s.13.21.28.6a10.742,10.742,0,0,1,.49,1.71,14.429,14.429,0,0,1,.32,2.63,16.689,16.689,0,0,1-.24,3.23,17.379,17.379,0,0,1-.91,3.12,14.57,14.57,0,0,1-1.21,2.35,10.271,10.271,0,0,1-1.07,1.43C227.09,309.66,226.93,309.82,226.91,309.8Z"
                        fill="#707171"
                    />
                </g>
                <path
                    id="Path_205"
                    d="M243.76,305.09l8.33,2.89s8-4.12,13.44,0,10.25,14.95,8.74,19.34c0,0-3.79,4.67-6.67,5.36s-14.07-2-18.73-3-12.69-6.2-12.69-6.2Z"
                    fill="#e3e2e3"
                />
                <g id="Group_11" opacity="0.3">
                    <path
                        id="Path_206"
                        d="M244.78,317.36a3.42,3.42,0,0,1-.08-.74q-.06-.84-.12-2c-.09-1.7-.21-4.05-.24-6.65a65,65,0,0,1,.19-6.66c.07-.85.17-1.54.25-2a2.43,2.43,0,0,1,.18-.72c.06,0-.07,1.05-.15,2.75s-.12,4-.08,6.63.09,4.84.12,6.65v2a2.66,2.66,0,0,1-.07.74Z"
                        fill="#707171"
                    />
                </g>
                <path
                    id="Path_207"
                    d="M272.25,307.65a25.342,25.342,0,0,0-2.77-2.5,15.24,15.24,0,0,0-16.88-.93,26.005,26.005,0,0,0-3,2.18,4,4,0,0,1,.69-.74,13.742,13.742,0,0,1,2.19-1.68,15,15,0,0,1,17.2.95,14,14,0,0,1,2,1.91A4.189,4.189,0,0,1,272.25,307.65Z"
                    fill="#1a2e35"
                />
                <path
                    id="Path_208"
                    d="M271.18,300.71a15.83,15.83,0,0,0-3.46-1.92,15.43,15.43,0,0,0-9.38-.28,21.41,21.41,0,0,0-4.85,2A24.233,24.233,0,0,0,250,303c-.93.8-1.64,1.5-2.14,2a5.591,5.591,0,0,1-.78.74,5.361,5.361,0,0,1,.67-.84,27.842,27.842,0,0,1,2.07-2.09,22,22,0,0,1,3.52-2.58,21,21,0,0,1,4.92-2.08,15,15,0,0,1,9.59.4,11.3,11.3,0,0,1,2.56,1.44c.28.21.47.4.61.52S271.19,300.7,271.18,300.71Z"
                    fill="#1a2e35"
                />
                <path
                    id="Path_209"
                    d="M271.25,294.69c0,.07-1.48-.37-3.9-.7a31.085,31.085,0,0,0-4.27-.27,33.914,33.914,0,0,0-5.21.47,33.5,33.5,0,0,0-5.06,1.34,31.932,31.932,0,0,0-3.92,1.71c-2.16,1.13-3.38,2.05-3.42,2a5.792,5.792,0,0,1,.83-.66,11.147,11.147,0,0,1,1-.71c.42-.26.88-.57,1.43-.85a28.3,28.3,0,0,1,3.93-1.81,32.632,32.632,0,0,1,5.11-1.39,32.3,32.3,0,0,1,5.28-.44,27.71,27.71,0,0,1,4.31.36A16.444,16.444,0,0,1,269,294c.48.1.89.21,1.22.31A5,5,0,0,1,271.25,294.69Z"
                    fill="#1a2e35"
                />
                <path
                    id="Path_210"
                    d="M207.43,221a6.87,6.87,0,0,1,.58,2.21,21.94,21.94,0,0,1-.32,6.47,40,40,0,0,1-1.07,4.5,34.79,34.79,0,0,1-2.19,5c-1.8,3.46-4.05,7.24-6.67,11.26l-.3.47V242l.29.14-7,5.67.06-.09a73.649,73.649,0,0,0-3.12,16.89c-.69,9.15-.14,17.91.29,25.85s.62,15.12.86,21.14.46,10.88.64,14.24c.1,1.67.18,3,.23,3.87a9.889,9.889,0,0,0,.07,1,1.393,1.393,0,0,1,0,.34,2.477,2.477,0,0,1-.05-.34c0-.24-.06-.58-.11-1-.08-.89-.19-2.19-.3-3.87-.23-3.36-.49-8.23-.76-14.24s-.59-13.18-.95-21.13-1-16.71-.33-25.9a73.81,73.81,0,0,1,3.16-17v-.06h0l7-5.66.29-.24v8.74l-.31-.1c5.17-8,9.37-15,10-20.61a22.211,22.211,0,0,0,.41-6.41,12.133,12.133,0,0,0-.31-1.67A5.238,5.238,0,0,0,207.43,221Z"
                    fill="#1a2e35"
                />
                <path id="Path_211" d="M193.94,318.06h-.27a2.13,2.13,0,1,0,.27,0Z" fill="#fff" />
                <path id="Path_212" d="M193.94,289.07h-.27a2.13,2.13,0,1,0,.27,0Z" fill="#fff" />
                <path
                    id="Path_213"
                    d="M193.66,263.13a2.13,2.13,0,0,0,.28-4.24h-.27a2.13,2.13,0,0,0,0,4.25Z"
                    fill="#fff"
                />
                <path
                    id="Path_214"
                    d="M161.18,187.05a52.29,52.29,0,0,1-7.22-9.31,17.76,17.76,0,0,1-2.44-11.32c.38-2.27,1.34-4.43,1.5-6.73.21-3.16-1.12-6.46,0-9.42,1.75-4.63,8-5.34,11.76-8.54,2.94-2.49,4.37-6.53,7.61-8.63,2.82-1.83,6.45-1.79,9.79-1.32s6.69,1.32,10,.87c2.38-.32,4.62-1.28,7-1.86s4.93-.73,7,.48,3.24,4.15,1.88,6.13c3.86-1.79,8.78,1.23,9.83,5.35s-1.06,8.58-4.33,11.3-7.52,4-11.7,4.78c-11.85,2.23-24.7,1-34.91-5.44"
                    fill="#707171"
                />
                <path
                    id="Path_215"
                    d="M202.83,226.88c-.58-4.49-1.06-8.23-1.08-8.69,0,0,15-3.37,14.16-18.53-.4-7.32-2.45-22.34-4.47-35.9a28.26,28.26,0,0,0-29.85-24l-1.46.1c-15.69,2-22.78,16.4-20.38,32l8.47,52.41s3.61,11.6,11.34,13.61l9.76,13.93,1.61-6,7.27-6.33a28.8,28.8,0,0,0,3.58-5.33,11.67,11.67,0,0,0,1.05-7.27Z"
                    fill="#e3e2e3"
                />
                <path
                    id="Path_216"
                    d="M201.76,218a35.39,35.39,0,0,1-20.12-4.29s5.4,10.58,20.41,8.21Z"
                    fill="#e3e2e3"
                />
                <path
                    id="Path_217"
                    d="M206.58,176.78a2.17,2.17,0,0,1-1.94,2.32,2.06,2.06,0,0,1-2.36-1.8,2.16,2.16,0,0,1,1.93-2.32,2.08,2.08,0,0,1,2.37,1.8Z"
                    fill="#707171"
                />
                <path
                    id="Path_218"
                    d="M208.69,171.41c-.25.3-2-.8-4.3-.64s-3.95,1.44-4.22,1.17c-.13-.12.11-.64.81-1.24a6,6,0,0,1,3.36-1.28,5.68,5.68,0,0,1,3.44.86C208.54,170.78,208.81,171.27,208.69,171.41Z"
                    fill="#707171"
                />
                <path
                    id="Path_219"
                    d="M184.59,178.35a2.16,2.16,0,0,1-1.93,2.32,2.06,2.06,0,0,1-2.36-1.8,2.17,2.17,0,0,1,1.93-2.32,2.07,2.07,0,0,1,2.36,1.8Z"
                    fill="#707171"
                />
                <path
                    id="Path_220"
                    d="M185.85,173.37c-.25.3-2-.8-4.3-.64s-4,1.44-4.22,1.17c-.14-.11.1-.64.81-1.23a5.94,5.94,0,0,1,3.36-1.29,5.7,5.7,0,0,1,3.44.86C185.69,172.75,186,173.24,185.85,173.37Z"
                    fill="#707171"
                />
                <path
                    id="Path_221"
                    d="M195.45,190.06a15.89,15.89,0,0,1,3.73-.93c.59-.1,1.14-.26,1.22-.67a3,3,0,0,0-.52-1.73l-2.06-4.41c-2.87-6.28-5-11.46-4.74-11.57s2.79,4.88,5.66,11.16q1,2.33,2,4.44a3.41,3.41,0,0,1,.46,2.31,1.49,1.49,0,0,1-.91.94,3.83,3.83,0,0,1-1,.2C196.93,190.09,195.46,190.2,195.45,190.06Z"
                    fill="#707171"
                />
                <path
                    id="Path_222"
                    d="M185.84,164.21c-.19.65-2.53.51-5.24,1s-4.87,1.4-5.27.86c-.18-.26.17-.88,1-1.55a9.25,9.25,0,0,1,8-1.43C185.38,163.44,185.92,163.91,185.84,164.21Z"
                    fill="#707171"
                />
                <path
                    id="Path_223"
                    d="M207.38,164.73c-.37.56-2,.12-3.94.22s-3.54.59-3.95.07c-.18-.26,0-.81.72-1.36a5.7,5.7,0,0,1,6.36-.22C207.29,163.93,207.55,164.46,207.38,164.73Z"
                    fill="#707171"
                />
                <path
                    id="Path_224"
                    d="M161.74,183c-.26-.09-10.61-2.38-9.59,8s12.22,7,12.21,6.66S161.74,183,161.74,183Z"
                    fill="#e3e2e3"
                />
                <path
                    id="Path_225"
                    d="M159.78,194.2c-.05,0-.16.15-.45.32a1.85,1.85,0,0,1-1.35.16c-1.13-.3-2.22-1.94-2.45-3.79a6,6,0,0,1,.25-2.63,2.1,2.1,0,0,1,1.21-1.52.91.91,0,0,1,1.11.39c.17.28.12.49.18.5s.21-.19.08-.61a1.18,1.18,0,0,0-.47-.61,1.39,1.39,0,0,0-1-.16,2.53,2.53,0,0,0-1.72,1.79,6,6,0,0,0-.34,2.94c.27,2.07,1.53,3.91,3.07,4.2a1.89,1.89,0,0,0,1.61-.44C159.79,194.45,159.82,194.22,159.78,194.2Z"
                    fill="#707171"
                />
                <path
                    id="Path_226"
                    d="M210.68,149.65c-.71,4.11-9,7.88-16,7.68-6.37-.19-9.52-3.35-14.15-5.6-2.93-1.42-2.93-1.42-6.91-.78-3.22.53-4.89,4.32-6.69,7,3.44,7.5,2.05,16.22.59,24.34a4.08,4.08,0,0,1-1.05,2.47c-1.16,1-3,.41-4.18-.63-1.83-1.68-2.66-4.16-3.26-6.56a42.73,42.73,0,0,1-1-16.8,29.14,29.14,0,0,1,7-15.17,27.6,27.6,0,0,1,18.78-8.57,33.81,33.81,0,0,1,20,5.69,19.65,19.65,0,0,1,6.84,6.9"
                    fill="#707171"
                />
                <path
                    id="Path_227"
                    d="M197,195.3l-5.27-2.46a1.81,1.81,0,0,0-1-.24,1.54,1.54,0,0,0-.91.62,4.89,4.89,0,0,0-.58,5.38,4.12,4.12,0,0,0,4.94,1.82c1.87-.78,2.77-2.94,2.92-5Z"
                    fill="#e3e2e3"
                />
                <path
                    id="Path_228"
                    d="M194.36,200.79a1.962,1.962,0,0,1-.77.31,4.66,4.66,0,0,1-2.27-.17,4.71,4.71,0,0,1-2.61-2.17,4.87,4.87,0,0,1-.33-4.16,5.22,5.22,0,0,1,1.23-1.84,4.12,4.12,0,0,1,.85-.64,1.3,1.3,0,0,1,.61-.2,1.44,1.44,0,0,1,.61.12,6.637,6.637,0,0,1,.82.47l.72.44,1.28.8a11.41,11.41,0,0,1,2.44,1.74,11,11,0,0,1-2.72-1.26l-1.33-.72-.74-.41c-.25-.13-.52-.3-.74-.39a.56.56,0,0,0-.58.06,3.8,3.8,0,0,0-.69.54,4.54,4.54,0,0,0-1,1.57,4.23,4.23,0,0,0,.22,3.53,4.32,4.32,0,0,0,2.17,2,5,5,0,0,0,2,.38C194.05,200.78,194.34,200.73,194.36,200.79Z"
                    fill="#707171"
                />
                <path
                    id="Path_229"
                    d="M192.69,177a18.258,18.258,0,0,0-.41-2.61,10.69,10.69,0,0,0-3.93-5.85,10.54,10.54,0,0,0-4.48-2,10.68,10.68,0,0,0-5.58.47,10,10,0,0,0-2.68,1.45,10.17,10.17,0,0,0-2.24,2.25,10.58,10.58,0,0,0,0,12.52,10.22,10.22,0,0,0,2.24,2.26,10,10,0,0,0,2.68,1.44,10.68,10.68,0,0,0,14-7.34,18.26,18.26,0,0,0,.41-2.62v.69a4.57,4.57,0,0,1-.07.84,6.662,6.662,0,0,1-.2,1.12,10.08,10.08,0,0,1-1.17,2.92,10.74,10.74,0,0,1-2.74,3.12,10.86,10.86,0,0,1-10.35,1.61,10.37,10.37,0,0,1-2.78-1.48,10.51,10.51,0,0,1-2.33-2.33,10.89,10.89,0,0,1,0-13,10.917,10.917,0,0,1,10.86-4.26,10.82,10.82,0,0,1,4.59,2.07,10.61,10.61,0,0,1,2.74,3.12,9.93,9.93,0,0,1,1.17,2.91,6.943,6.943,0,0,1,.2,1.13,4.41,4.41,0,0,1,.07.83v.52A1.25,1.25,0,0,1,192.69,177Z"
                    fill="#1a2e35"
                    opacity="0.5"
                />
                <path
                    id="Path_230"
                    d="M218.46,176a19.1,19.1,0,0,0-.41-2.55,10.37,10.37,0,0,0-13.62-7.15,9.6,9.6,0,0,0-2.61,1.41,9.48,9.48,0,0,0-2.19,2.19,10.3,10.3,0,0,0,0,12.2,9.639,9.639,0,0,0,2.18,2.19,9.8,9.8,0,0,0,2.62,1.41,10.31,10.31,0,0,0,5.43.46,10.17,10.17,0,0,0,4.36-1.92,10.38,10.38,0,0,0,3.83-5.69,19.367,19.367,0,0,0,.41-2.55,5.67,5.67,0,0,1,0,.67,5.252,5.252,0,0,1-.06.82,6.36,6.36,0,0,1-.2,1.09,10.38,10.38,0,0,1-3.81,5.88,10.5,10.5,0,0,1-4.47,2,10.61,10.61,0,0,1-5.62-.44,9.88,9.88,0,0,1-2.71-1.45,10.14,10.14,0,0,1-2.27-2.26,10.676,10.676,0,0,1,15.09-14.8,10.44,10.44,0,0,1,3.81,5.88,6.639,6.639,0,0,1,.2,1.1,5.251,5.251,0,0,1,.06.82,5.668,5.668,0,0,1-.02.69Z"
                    fill="#1a2e35"
                    opacity="0.5"
                />
                <path
                    id="Path_231"
                    d="M197.43,173.65s-.29-.17-.74-.38a4.19,4.19,0,0,0-3.78.06c-.45.24-.68.46-.73.42s.14-.33.59-.65a3.69,3.69,0,0,1,4-.07C197.28,173.33,197.47,173.62,197.43,173.65Z"
                    fill="#1a2e35"
                    opacity="0.5"
                />
                <path
                    id="Path_232"
                    d="M171.77,175.51a46.049,46.049,0,0,1-4.71,3.76,45.271,45.271,0,0,1-4.95,3.45,46.31,46.31,0,0,1,4.71-3.76,47,47,0,0,1,4.95-3.45Z"
                    fill="#1a2e35"
                    opacity="0.5"
                />
                <g id="Group_12" opacity="0.3">
                    <path
                        id="Path_233"
                        d="M162.87,224.47c-1.05,10.21,2.83,21,11,27.17a2.5,2.5,0,0,0,2.21.7,2.42,2.42,0,0,0,1.14-1.18A19.88,19.88,0,0,0,179.68,238c-1.29,3.41-2.41,6.77-3.72,10.34C168.83,244,164.38,234.83,162.87,224.47Z"
                    />
                </g>
                <path
                    id="Path_234"
                    d="M190.59,247.68l6-2.19,1,10.16s6.49-11.82,9.17-17.21,1.3-14.9,1.3-14.9,1,8.28-5.53,18.86l-4.91,8V242Z"
                    opacity="0.3"
                />
                <g id="Group_13" opacity="0.3">
                    <path
                        id="Path_235"
                        d="M120.69,299.33a16.12,16.12,0,0,0-8.7-1.09,16.76,16.76,0,0,0-8.67,3.28,9.64,9.64,0,0,0-3.78,8.18h.3c2.09-5.67,6.26-9.8,11.64-10.3a17.67,17.67,0,0,1,14.32,5.83C125.44,302.33,123.37,300.6,120.69,299.33Z"
                    />
                </g>
                <g id="Group_14" opacity="0.3">
                    <path
                        id="Path_236"
                        d="M272.64,305.14a13.72,13.72,0,0,0-7.76-4,17.42,17.42,0,0,0-14.48,4.19l1.15-.93a18.16,18.16,0,0,1,9.64-2.62,13.91,13.91,0,0,1,9.19,3.67c2.25,2.14,3.6,5,4.88,7.85a9.81,9.81,0,0,0-2.62-8.16Z"
                    />
                </g>
                <path
                    id="Path_237"
                    d="M233.91,257.94a3.251,3.251,0,0,1,.27-.82l.85-2.19c.73-1.84,1.73-4.39,2.78-7.22s2-5.42,2.62-7.29l.78-2.21a4.281,4.281,0,0,1,.33-.8,3.161,3.161,0,0,1-.19.84c-.14.54-.37,1.31-.66,2.26-.59,1.89-1.46,4.49-2.51,7.33s-2.1,5.38-2.89,7.2c-.4.91-.73,1.64-1,2.14A4,4,0,0,1,233.91,257.94Z"
                    fill="#1a2e35"
                />
                <path
                    id="Path_238"
                    d="M139.07,258.68c-.11,0-.93-3.56-1.83-8s-1.55-8.05-1.44-8.07.92,3.55,1.82,8S139.17,258.65,139.07,258.68Z"
                    fill="#1a2e35"
                />
                <path
                    id="Path_239"
                    d="M137.65,303.43c.23.12-2.76-23.45-2.76-23.45s1.89-17.88,3-18.71v-6.3s2.92-1.78,3.71,1.08c0,0,2.59-2.25,3,3.11s.54,10.84.54,10.84L151,284.43l1,.48s1.24-11.3,2-13,1.21-5.71,3.95-5.1,3,2.87,2.47,6.56-1.1,7.5-.88,9.88,1.31,7.62,1,11.79-2.61,8.56-5,11.65-7,9.46-9.45,11.39C145.05,318.78,137.65,303.43,137.65,303.43Z"
                    fill="#e3e2e3"
                />
                <g id="Group_15" opacity="0.3">
                    <path
                        id="Path_240"
                        d="M140.56,284.25a4.58,4.58,0,0,1,0-1c0-.72.11-1.64.18-2.73.18-2.31.45-5.5.77-9,.17-1.76.33-3.43.47-5a8.5,8.5,0,0,0,0-2.13,3,3,0,0,0-.72-1.62,3.23,3.23,0,0,0-2.36-1,6.072,6.072,0,0,0-1,.13,1.312,1.312,0,0,1,.24-.13,2.55,2.55,0,0,1,.74-.15,3.29,3.29,0,0,1,2.56,1,3.2,3.2,0,0,1,.84,1.76,8.59,8.59,0,0,1,0,2.21c-.13,1.53-.28,3.21-.44,5-.32,3.52-.63,6.71-.88,9l-.31,2.73A4.42,4.42,0,0,1,140.56,284.25Z"
                        fill="#707171"
                    />
                </g>
                <g id="Group_16" opacity="0.3">
                    <path
                        id="Path_241"
                        d="M143.66,274.61a8.939,8.939,0,0,0-1,1.86,32.51,32.51,0,0,0-1,5.09c-.31,2-.61,3.84-.85,5.15a11.06,11.06,0,0,1-.47,2.11,12.63,12.63,0,0,1,.2-2.15c.16-1.33.42-3.15.73-5.17a24.83,24.83,0,0,1,1.09-5.13,4.65,4.65,0,0,1,.84-1.37,1.37,1.37,0,0,1,.46-.39Z"
                        fill="#707171"
                    />
                </g>
                <g id="Group_17" opacity="0.3">
                    <path
                        id="Path_242"
                        d="M141.54,255.74a2.67,2.67,0,0,1,.2.75c.11.48.24,1.18.38,2.05.29,1.74.6,4.15.8,6.84s.24,5.12.22,6.88a14.937,14.937,0,0,1-.08,2.08,2.67,2.67,0,0,1-.09.77,24.47,24.47,0,0,1-.11-2.85c0-1.76-.13-4.18-.33-6.86s-.46-5.08-.68-6.82a23.92,23.92,0,0,1-.31-2.84Z"
                        fill="#707171"
                    />
                </g>
                <g id="Group_18" opacity="0.3">
                    <path
                        id="Path_243"
                        d="M158.19,277.84a2.14,2.14,0,0,1-1.94.16c-1.11-.33-1.62-1.18-1.52-1.22a11.448,11.448,0,0,0,1.63.85A13.26,13.26,0,0,0,158.19,277.84Z"
                        fill="#707171"
                    />
                </g>
                <path
                    id="Path_244"
                    d="M138,300.5l-15.25,6.4h-9.08s-9,2.81-10.37,11.23-1.58,12.76,2.37,13.8,15.11,1.16,20.64-.68,20-13.52,20-13.52l1.4-8.46Z"
                    fill="#e3e2e3"
                />
                <g id="Group_19" opacity="0.3">
                    <path
                        id="Path_245"
                        d="M141.54,309.8a3.407,3.407,0,0,1-.43-.39,9.728,9.728,0,0,1-1-1.26,17.16,17.16,0,0,1-2.13-4.83,28.76,28.76,0,0,1-.94-5.18A9.42,9.42,0,0,1,137,296a15.319,15.319,0,0,1,.33,2.15,38.481,38.481,0,0,0,1.05,5.11,20,20,0,0,0,2,4.78C141,309.14,141.6,309.75,141.54,309.8Z"
                        fill="#707171"
                    />
                </g>
                <g id="Group_20" opacity="0.3">
                    <path
                        id="Path_246"
                        d="M148.18,312.48a15.239,15.239,0,0,0,.87-3.27,10.52,10.52,0,0,0-2.05-7.65c-.93-1.3-2-2.43-2.84-3.52a16.78,16.78,0,0,1-2.07-3.12,8.36,8.36,0,0,1-.76-2.41,2.71,2.71,0,0,1,0-.92,10.44,10.44,0,0,0,1,3.2,18,18,0,0,0,2.09,3c.89,1.07,1.94,2.19,2.89,3.52a10.91,10.91,0,0,1,1.9,4.17,10.441,10.441,0,0,1,.1,3.75,9.14,9.14,0,0,1-.7,2.42,5,5,0,0,1-.3.61A.68.68,0,0,1,148.18,312.48Z"
                        fill="#707171"
                    />
                </g>
                <path
                    id="Path_247"
                    d="M124.67,275.76c-.09.05-1.75-3-3.71-6.76a76.66,76.66,0,0,1-3.37-6.94,75.891,75.891,0,0,1,3.72,6.76A76.279,76.279,0,0,1,124.67,275.76Z"
                    fill="#fff"
                />
                <path
                    id="Path_248"
                    d="M149.33,248.31a47.574,47.574,0,0,1,2.3-5.63,50.841,50.841,0,0,1,2.65-5.48,46.132,46.132,0,0,1-2.29,5.64,47,47,0,0,1-2.66,5.47Z"
                    fill="#fff"
                />
                <path
                    id="Path_249"
                    d="M173.48,266.13a70.315,70.315,0,0,1-6.61-8,37,37,0,0,1,3.46,3.87,35.541,35.541,0,0,1,3.15,4.13Z"
                    fill="#fff"
                />
                <path
                    id="Path_250"
                    d="M167.93,290.63a27.538,27.538,0,0,1,2.6-3.92,28.774,28.774,0,0,1,2.92-3.7,28.35,28.35,0,0,1-2.6,3.93,30.149,30.149,0,0,1-2.92,3.69Z"
                    fill="#fff"
                />
                <path
                    id="Path_251"
                    d="M160.45,325.24a7.379,7.379,0,0,1-.89-1.51c-.49-1-1.13-2.31-1.77-3.82s-1.17-2.92-1.52-3.94a7.331,7.331,0,0,1-.48-1.68,7.85,7.85,0,0,1,.74,1.58c.47,1.14,1,2.44,1.62,3.88l1.66,3.86a8.74,8.74,0,0,1,.64,1.63Z"
                    fill="#fff"
                />
                <path
                    id="Path_252"
                    d="M181.69,310.53a27.754,27.754,0,0,1-4.56-.69,26.319,26.319,0,0,1-4.5-1.07,27.79,27.79,0,0,1,4.57.69,26.351,26.351,0,0,1,4.49,1.07Z"
                    fill="#fff"
                />
                <path
                    id="Path_253"
                    d="M198.17,278.19a56.479,56.479,0,0,1,4.35-5,54,54,0,0,1,4.65-4.71,56.479,56.479,0,0,1-4.36,5,56,56,0,0,1-4.64,4.71Z"
                    fill="#fff"
                />
                <path
                    id="Path_254"
                    d="M214.75,321.32c-.09.07-2.52-2.75-5.44-6.29s-5.21-6.47-5.13-6.54,2.52,2.75,5.44,6.29S214.83,321.25,214.75,321.32Z"
                    fill="#fff"
                />
                <path
                    id="Path_255"
                    d="M111.85,302.94c.05.1-2.17,1.23-4.59,3.09s-4.11,3.69-4.2,3.61a5.5,5.5,0,0,1,1-1.28,24.339,24.339,0,0,1,2.94-2.64,23.324,23.324,0,0,1,3.32-2.13,5.36,5.36,0,0,1,1.53-.65Z"
                    fill="#fff"
                />
                <path
                    id="Path_256"
                    d="M118,286.31a30.549,30.549,0,0,1-3.86,5.81,16.1,16.1,0,0,1,1.77-3,15.86,15.86,0,0,1,2.09-2.81Z"
                    fill="#fff"
                />
                <path
                    id="Path_257"
                    d="M132,302.64a9.92,9.92,0,0,1-1.46-6.43c.11,0,.15,1.48.56,3.25S132.11,302.59,132,302.64Z"
                    fill="#fff"
                />
                <path
                    id="Path_258"
                    d="M134.08,250.79a11.22,11.22,0,0,1-2.9.61,11.071,11.071,0,0,1-3,.22,11.091,11.091,0,0,1,2.9-.61A11.06,11.06,0,0,1,134.08,250.79Z"
                    fill="#fff"
                />
                <path
                    id="Path_259"
                    d="M144.57,232.19a12.419,12.419,0,0,1-2.54,1.63,12.09,12.09,0,0,1-2.73,1.29,11.511,11.511,0,0,1,2.54-1.63,11.3,11.3,0,0,1,2.73-1.29Z"
                    fill="#fff"
                />
                <path
                    id="Path_260"
                    d="M174.06,240.05a28.158,28.158,0,0,1-2.9-3.49,27.161,27.161,0,0,1-2.59-3.73,26.231,26.231,0,0,1,2.9,3.49A25.159,25.159,0,0,1,174.06,240.05Z"
                    fill="#fff"
                />
                <path
                    id="Path_261"
                    d="M211.51,258.75a13.289,13.289,0,0,1-2.71-3.39,13.7,13.7,0,0,1-1.85-3.92c.11-.05.92,1.7,2.18,3.72S211.59,258.68,211.51,258.75Z"
                    fill="#fff"
                />
                <path
                    id="Path_262"
                    d="M225.8,263.46a22.492,22.492,0,0,1-1.55,3.89,22.2,22.2,0,0,1-1.91,3.73,44.539,44.539,0,0,1,3.46-7.62Z"
                    fill="#fff"
                />
                <path
                    id="Path_263"
                    d="M227.79,244a43.869,43.869,0,0,1-3.8-4.27,42.922,42.922,0,0,1-3.5-4.51,79.162,79.162,0,0,1,7.3,8.78Z"
                    fill="#fff"
                />
                <path
                    id="Path_264"
                    d="M206,228.11a34.4,34.4,0,0,1-.29-7.31A34.4,34.4,0,0,1,206,228.11Z"
                    fill="#fff"
                />
                <path
                    id="Path_265"
                    d="M223.76,227.12a27.2,27.2,0,0,1-4.64.82,27.48,27.48,0,0,1-4.7.43,29,29,0,0,1,4.65-.82A30.162,30.162,0,0,1,223.76,227.12Z"
                    fill="#fff"
                />
                <path
                    id="Path_266"
                    d="M251.79,247.71a47.17,47.17,0,0,1-2.79-5.32,45.386,45.386,0,0,1-2.44-5.5,49.2,49.2,0,0,1,2.79,5.32,45.383,45.383,0,0,1,2.44,5.5Z"
                    fill="#fff"
                />
                <path
                    id="Path_267"
                    d="M247.07,265.5a24.788,24.788,0,0,1,.66-4.28,23.551,23.551,0,0,1,1-4.2,24.931,24.931,0,0,1-.66,4.28,22.84,22.84,0,0,1-1,4.2Z"
                    fill="#fff"
                />
                <path
                    id="Path_268"
                    d="M260.08,279.53a46.9,46.9,0,0,1-2.24-5.78,49.922,49.922,0,0,1-1.86-5.91,47.106,47.106,0,0,1,2.23,5.78,50.558,50.558,0,0,1,1.87,5.91Z"
                    fill="#fff"
                />
                <path
                    id="Path_269"
                    d="M258.26,287.73a13.22,13.22,0,0,1-7-3.8c.08-.09,1.46,1.06,3.4,2.1S258.3,287.62,258.26,287.73Z"
                    fill="#fff"
                />
                <path
                    id="Path_270"
                    d="M274.92,306.44a11,11,0,0,1-1.35-2.55,10.51,10.51,0,0,1-1-2.71,21.131,21.131,0,0,1,2.34,5.26Z"
                    fill="#fff"
                />
                <path
                    id="Path_271"
                    d="M176.55,332.52a14.769,14.769,0,0,1-.58-3.33,15,15,0,0,1-.2-3.37,28.4,28.4,0,0,1,.78,6.7Z"
                    fill="#fff"
                />
                <path
                    id="Path_272"
                    d="M227.65,331.23a14.431,14.431,0,0,1,.5-3.25,14.092,14.092,0,0,1,.87-3.18,27.1,27.1,0,0,1-1.37,6.43Z"
                    fill="#fff"
                />
                <path
                    id="Path_273"
                    d="M237.45,182.07l6.61-11.65a31.22,31.22,0,1,1,7.47,8.31h0Z"
                    fill="#f4f5f6"
                    stroke="#707171"
                    strokeMiterlimit="10"
                    strokeWidth="1"
                />
                <path
                    id="Path_274"
                    d="M237.45,182.07l14.12-3.23h.14l-.09-.12h0l-.14.14a31.9,31.9,0,0,0,6.41,3.86,31.31,31.31,0,0,0,8.55,2.44,28.471,28.471,0,0,0,4.93.25c.85-.05,1.71-.07,2.58-.14l2.64-.4a31.39,31.39,0,0,0,19.47-12.5,31,31,0,0,0,5.26-12,31.72,31.72,0,0,0-.36-13.86,30.79,30.79,0,0,0-6.68-12.81L293,132.3a18.63,18.63,0,0,0-1.37-1.29c-.47-.41-.9-.87-1.41-1.24l-1.49-1.11a14.369,14.369,0,0,0-1.55-1l-1.58-1-1.66-.8a13.061,13.061,0,0,0-1.67-.74,31.82,31.82,0,0,0-14-2.15,30.78,30.78,0,0,0-12.82,3.79,31.66,31.66,0,0,0-14.79,18.2A33.89,33.89,0,0,0,239.33,155a30.859,30.859,0,0,0,1.53,8.75,31.728,31.728,0,0,0,3.12,6.71v-.1l-6.53,11.7,6.69-11.6v-.05h0A30.8,30.8,0,0,1,239.6,155a33.6,33.6,0,0,1,1.4-10,31.31,31.31,0,0,1,14.67-18,30.43,30.43,0,0,1,12.68-3.73,31.35,31.35,0,0,1,13.8,2.13,15.06,15.06,0,0,1,1.65.73l1.63.8,1.56,1a11.24,11.24,0,0,1,1.53,1L290,130c.5.36.93.82,1.4,1.22a12.545,12.545,0,0,1,1.35,1.28l1.26,1.38a30.54,30.54,0,0,1,6.6,12.66,31.549,31.549,0,0,1,.37,13.69,30.77,30.77,0,0,1-5.19,11.91,31.12,31.12,0,0,1-19.24,12.4l-2.61.41c-.86.07-1.72.09-2.56.14a27.14,27.14,0,0,1-4.89-.23,30.9,30.9,0,0,1-8.48-2.38,32.4,32.4,0,0,1-6.39-3.8l-.58-.45.45.58h0l.05-.16Z"
                    fill="#f4f5f6"
                    stroke="#707171"
                    strokeMiterlimit="10"
                    strokeWidth="1"
                />
                <path
                    id="Path_275"
                    d="M279,152.81a3,3,0,1,0,.861-2.153A3,3,0,0,0,279,152.81Z"
                    fill="#707171"
                />
                <path
                    id="Path_276"
                    d="M266.82,152.81a3,3,0,1,0,.878-2.172A3,3,0,0,0,266.82,152.81Z"
                    fill="#707171"
                />
                <path
                    id="Path_277"
                    d="M254.67,151.29a3,3,0,1,0,1.843-1.441,3,3,0,0,0-1.843,1.441Z"
                    fill="#707171"
                />
                <circle
                    id="Ellipse_10"
                    cx="18.71"
                    cy="18.71"
                    r="18.71"
                    transform="translate(429.6 139.99)"
                    fill="#fff"
                    stroke="#e2e2e2"
                    strokeMiterlimit="10"
                    strokeWidth="2"
                />
                <circle
                    id="Ellipse_11"
                    cx="9.82"
                    cy="9.82"
                    r="9.82"
                    transform="translate(470.19 198.54)"
                    fill="#fff"
                    stroke="#e2e2e2"
                    strokeMiterlimit="10"
                    strokeWidth="2"
                />
                <path
                    id="Path_278"
                    d="M209.32,399.92h-7.88v-7.87a1,1,0,0,0-1-1h0a1,1,0,0,0-1,1v7.87h-7.85a1,1,0,0,0,0,2h7.85v7.88a1,1,0,0,0,2,0h0v-7.9h7.88a1,1,0,0,0,0-2Z"
                    fill="#e2e2e2"
                    stroke="#e2e2e2"
                    strokeMiterlimit="10"
                    strokeWidth="0.75"
                />
                <path
                    id="Path_279"
                    d="M485.71,258.83h-7.87V251a1,1,0,0,0-1-1h0a1,1,0,0,0-1,1v7.87H468a1,1,0,0,0,0,2h7.86v7.87a1,1,0,0,0,2,0h0v-7.87h7.87a1,1,0,0,0,0-2Z"
                    fill="#e2e2e2"
                    stroke="#e2e2e2"
                    strokeMiterlimit="10"
                    strokeWidth="0.75"
                />
                <ellipse
                    id="_Path_"
                    cx="153.59"
                    cy="15.08"
                    rx="153.59"
                    ry="15.08"
                    transform="translate(136.87 461.52)"
                    fill="#f4f5f6"
                />
            </g>
        </SVGIcon>
    );
}
