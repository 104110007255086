import React from 'react';
import { SVGIcon } from '../../UIKit/index';

export default function DCGrayAutomated(props) {
    return (
        <SVGIcon {...props} viewBox="0 0 463.86 500.9">
            <defs>
                <linearGradient
                    id="linear-gradient"
                    x1="0.329"
                    y1="0.554"
                    x2="-0.255"
                    y2="0.738"
                    gradientUnits="objectBoundingBox"
                >
                    <stop offset="0" stopColor="#707171" />
                    <stop offset="1" stopColor="#e3e2e3" />
                </linearGradient>
                <linearGradient
                    id="linear-gradient-2"
                    x1="0.727"
                    y1="0.007"
                    x2="0.207"
                    y2="1.135"
                />
                <linearGradient
                    id="linear-gradient-3"
                    x1="0.636"
                    y1="1.231"
                    x2="0.531"
                    y2="0.666"
                    gradientUnits="objectBoundingBox"
                >
                    <stop offset="0" stopColor="#e3e2e3" />
                    <stop offset="1" stopColor="#707171" />
                </linearGradient>
                <linearGradient
                    id="linear-gradient-4"
                    x1="0.253"
                    y1="0.019"
                    x2="0.711"
                    y2="1.039"
                    gradientUnits="objectBoundingBox"
                >
                    <stop offset="0.48" stopColor="#707171" />
                    <stop offset="1" stopColor="#e3e2e3" />
                </linearGradient>
                <linearGradient
                    id="linear-gradient-5"
                    x1="0.278"
                    y1="1.028"
                    x2="0.771"
                    y2="0.051"
                />
                <linearGradient
                    id="linear-gradient-6"
                    x1="0.405"
                    y1="0.935"
                    x2="0.732"
                    y2="0.106"
                />
                <linearGradient
                    id="linear-gradient-7"
                    x1="-0.357"
                    y1="0.403"
                    x2="2.519"
                    y2="0.714"
                    gradientUnits="objectBoundingBox"
                >
                    <stop offset="0" stopColor="#707171" />
                    <stop offset="1" stopColor="#606060" />
                </linearGradient>
                <linearGradient
                    id="linear-gradient-8"
                    x1="-113.934"
                    y1="0.805"
                    x2="-112.725"
                    y2="0.805"
                    gradientUnits="objectBoundingBox"
                >
                    <stop offset="0" stopColor="#606060" />
                    <stop offset="1" stopColor="#707171" />
                </linearGradient>
                <linearGradient
                    id="linear-gradient-9"
                    x1="0.329"
                    y1="0.554"
                    x2="-0.255"
                    y2="0.738"
                />
                <linearGradient id="linear-gradient-10" x1="0" y1="0.5" x2="1" y2="0.5" />
                <linearGradient id="linear-gradient-11" x1="0" y1="0.5" x2="1.001" y2="0.5" />
            </defs>
            <g id="Passive_earning" transform="translate(-24.79 -5.72)">
                <g id="Layer_1">
                    <circle
                        id="Ellipse_9"
                        cx="230.43"
                        cy="230.43"
                        r="230.43"
                        transform="translate(26.29 7.22)"
                        fill="#fff"
                        stroke="#707171"
                        strokeMiterlimit="10"
                        strokeWidth="3"
                    />
                    <path
                        id="Path_120"
                        d="M153.34,131.93c1.61,10.45-7.17,24.46,5.53,30.91,3.68,1.88,8,3.15,10.87,6.29s2,7.9,2,11.87c0,4.16.72,8,3.14,11.48,3.57,5.15,9.5,6,15,8,6.3,2.38,6.59,6.82,5.91,12.91-1.43,13.05,12.19,20.51,18.29,30.21s6.76,19.88,1.27,29.9a61.849,61.849,0,0,1-10.12,13.16c-3,3.06-6.82,5.72-10,8.72h0c-.46.43-.86.93-1.33,1.31h0a10.56,10.56,0,0,0-1.51,1.7c4.53-5.65,4.19-29.17-3.94-35.61s-17.28-8.51-18.15-20.58-6.85-8.37-18.64-12.51-18-10.2-14.12-20.13,3.43-9.63-18.17-25.51-14.52-47-5-59.43a23.62,23.62,0,0,1,11.44-7.45,16.318,16.318,0,0,1,2.11-.37,24.49,24.49,0,0,1,8.91.76C143.77,119.46,152.13,124.07,153.34,131.93Z"
                        fill="#f4f5f6"
                    />
                    <path
                        id="Path_121"
                        d="M153.34,131.93c1.61,10.45-7.17,24.46,5.53,30.91,3.68,1.88,8,3.15,10.87,6.29s2,7.9,2,11.87c0,4.16.72,8,3.14,11.48,3.57,5.15,9.5,6,15,8,6.3,2.38,6.59,6.82,5.91,12.91-1.43,13.05,12.19,20.51,18.29,30.21s6.76,19.88,1.27,29.9a61.849,61.849,0,0,1-10.12,13.16c-3,3.06-6.82,5.72-10,8.72a30.179,30.179,0,0,0,4.26-7.28c3.37-7.52,6.32-15.94,4.54-24.25-.93-4.34-3-5.13-6.82-7.19-5.87-3.19-10.93-7.79-12.43-14.64s-1.39-14.35-6.08-19.86c-7.21-8.5-23.27-5.35-24.87-18.85-1-8.25,2.59-17-2-24.73-3.09-5.24-9.18-6.74-14-9.8a36.72,36.72,0,0,1-12-13.35c-8.59-15.37-4.11-29.08,2.09-38.62a24.49,24.49,0,0,1,8.91.76C143.77,119.46,152.13,124.07,153.34,131.93Z"
                        fill="#e3e2e3"
                    />
                    <path
                        id="Path_122"
                        d="M196,111.67c-4.44,8.26-7.66,15.29.63,22.55,13.33,11.68,12.52,26,7,36.45-5.36,10.1-22,31.39-38.47,30.54a14.641,14.641,0,0,1-1.74-.17c1.91-2.07,18.22-2,4.88-25.39s2.72-20.41,3.84-34.86-18.31-11.68-32.71-34.67c-3.94-6.3-3.26-19.8.74-25.84,3.47-5.23,9.12-8.79,15.44-10.69l.39-.12c8.35-2.41,17.81-2,24.9,1.31C196,77.74,204.34,96.21,196,111.67Z"
                        fill="#f4f5f6"
                    />
                    <path
                        id="Path_123"
                        d="M196,111.67c-4.44,8.26-7.66,15.29.63,22.55,13.33,11.68,12.52,26,7,36.45-5.36,10.1-22,31.39-38.47,30.54,5.23-1.84,11-4.8,14.77-8.68,5.45-5.55,7.34-14.57,6.94-22.13-.34-6.24-2.23-12.29-2.63-18.52-.28-4.33,1.23-8.5,1-12.8a43.77,43.77,0,0,0-2.93-12.28,38.789,38.789,0,0,0-8.13-13.07C164.62,103.2,147,93.59,152.5,76.81a34.94,34.94,0,0,1,3.5-7.34c8.35-2.41,17.81-2,24.9,1.31C196,77.74,204.34,96.21,196,111.67Z"
                        fill="#e3e2e3"
                    />
                    <circle
                        id="Ellipse_10"
                        cx="9.33"
                        cy="9.33"
                        r="9.33"
                        transform="translate(69.39 286.41)"
                        fill="#fff"
                        stroke="#e2e2e2"
                        strokeMiterlimit="10"
                        strokeWidth="2"
                    />
                    <circle
                        id="Ellipse_11"
                        cx="14.74"
                        cy="14.74"
                        r="14.74"
                        transform="translate(53.58 229.64)"
                        fill="#fff"
                        stroke="#e2e2e2"
                        strokeMiterlimit="10"
                        strokeWidth="2"
                    />
                    <path
                        id="Path_124"
                        d="M452.5,170.16h-7.42v-7.41a.93.93,0,0,0-.92-.93h0a1,1,0,0,0-1,.93v7.41h-7.4a.93.93,0,0,0,0,1.86h7.4v7.42a1,1,0,0,0,1,.93.93.93,0,0,0,.92-.93h0V172h7.42a.93.93,0,0,0,0-1.86Z"
                        fill="#e2e2e2"
                        stroke="#e2e2e2"
                        strokeMiterlimit="10"
                        strokeWidth="0.75"
                    />
                    <ellipse
                        id="_Path_"
                        cx="136.7"
                        cy="13.42"
                        rx="136.7"
                        ry="13.42"
                        transform="translate(120.38 479.78)"
                        fill="#f4f5f6"
                    />
                    <path
                        id="Path_125"
                        d="M480.23,392.65,119.4,389.48v9.38a5,5,0,0,0,3.7,5.1l102,18.48a13.69,13.69,0,0,0,3.67.17l247.51-21.07c2.21-.18,3.92-2.36,3.92-5Z"
                        fill="#606060"
                    />
                    <path
                        id="Path_126"
                        d="M349.28,377.44,123.13,394.6a7,7,0,0,1-7.34-7.29V213.66a7.61,7.61,0,0,1,7.34-7.85H349.28c3.34,0,6,3.17,6,7.09v157C355.31,373.8,352.62,377.18,349.28,377.44Z"
                        fill="#707171"
                    />
                    <path
                        id="Path_127"
                        d="M352.89,377.44,126.73,394.6a6.994,6.994,0,0,1-7.33-7.29V213.66a7.61,7.61,0,0,1,7.33-7.85H352.89c3.33,0,6,3.17,6,7.09v157C358.92,373.8,356.22,377.18,352.89,377.44Z"
                        fill="#707171"
                    />
                    <path
                        id="Path_128"
                        d="M345.84,366.37,135.2,381.28a5.7,5.7,0,0,1-6-6v-152a6.23,6.23,0,0,1,6-6.43l210.64-.94c2.76,0,5,2.59,5,5.81V360.18C350.82,363.41,348.6,366.18,345.84,366.37Z"
                        fill="#d8dee8"
                    />
                    <path
                        id="Path_129"
                        d="M247.8,210.56a2.62,2.62,0,1,1-2.6-2.92,2.79,2.79,0,0,1,2.6,2.92Z"
                        fill="#fff"
                    />
                    <path
                        id="Path_130"
                        d="M298.4,329.81c-17.4-10.08-31.14,2.69-44.4-9.91-8-7.63-4.08-13.33-13.95-24.74-10.94-12.64-18.05-8.39-29-19.77-13.89-14.38-6.94-25.58-18.91-35.9-14.51-12.53-30.17-.94-44-13-6-5.19-9.23-12.72-10.94-20.73H123.69a4.45,4.45,0,0,0-4.29,4.6v180a4.06,4.06,0,0,0,4.29,4.27l230.49-17.57a3.68,3.68,0,0,0,2.78-1.82c-16.87-.66-27.24-6.74-33.87-13C310.13,350,312.38,337.89,298.4,329.81Z"
                        fill="#fff"
                        opacity="0.48"
                    />
                    <path
                        id="Path_131"
                        d="M361.9,376.66,119.4,394.73l105.73,19.15a13.69,13.69,0,0,0,3.67.17l251.43-21.4L365.76,376.78a17.8,17.8,0,0,0-3.86-.12Z"
                        fill="#707171"
                    />
                    <path
                        id="Path_132"
                        d="M154.78,395.18l201.9-15.26,62.48,8.59L219.01,404.83Z"
                        fill="#e3e2e3"
                    />
                    <path
                        id="Path_133"
                        d="M364.22,143.76l-197.43-6.49a11.57,11.57,0,0,0-12,11.67V341.36a11.43,11.43,0,0,0,12,11.54l197.43-8.43c5.71-.24,10.32-5.42,10.32-11.57V155.23C374.54,149.08,369.93,143.94,364.22,143.76Z"
                        fill="#707171"
                    />
                    <path
                        id="Path_134"
                        d="M166.79,349.07a7.79,7.79,0,0,1-8.16-7.86V149.05a7.89,7.89,0,0,1,8.16-8l197.43,6.23c3.89.12,7,3.62,7,7.81V333c0,4.19-3.14,7.72-7,7.88Z"
                        fill="#fff"
                        opacity="0.74"
                    />
                    <path
                        id="Path_135"
                        d="M364.22,143.76l-197.43-6.49a11.57,11.57,0,0,0-12,11.67v8.65l219.76,5.63v-8C374.54,149.08,369.93,143.94,364.22,143.76Z"
                        fill="#707171"
                    />
                    <path
                        id="Path_136"
                        d="M176.14,148.39a3.79,3.79,0,0,1-3.9,3.81,4.05,4.05,0,0,1-3.9-4,3.78,3.78,0,0,1,3.9-3.81,4.06,4.06,0,0,1,3.9,4Z"
                        fill="#fff"
                    />
                    <path
                        id="Path_137"
                        d="M189.73,148.78a3.75,3.75,0,0,1-3.86,3.79,4,4,0,0,1-3.87-4,3.75,3.75,0,0,1,3.87-3.79A4,4,0,0,1,189.73,148.78Z"
                        fill="#fff"
                    />
                    <path
                        id="Path_138"
                        d="M203.19,149.17a3.72,3.72,0,0,1-3.82,3.78,4,4,0,0,1-3.83-4,3.72,3.72,0,0,1,3.83-3.77A4,4,0,0,1,203.19,149.17Z"
                        fill="#fff"
                    />
                    <g id="Group_2" opacity="0.5">
                        <path
                            id="Path_139"
                            d="M287.48,217.73c-23.06-33.55-63-22.88-88.22-59.95A81.119,81.119,0,0,1,189.49,138l-22.7-.75a11.57,11.57,0,0,0-12,11.67V341.36a11.43,11.43,0,0,0,12,11.54L357,344.78c-4.36-6.28-42.31-29.56-52.11-65.29C295.91,246.8,301.39,238,287.48,217.73Z"
                            fill="#fff"
                        />
                    </g>
                    <path
                        id="Path_140"
                        d="M363.81,214.63l-194.3-.23v-6.35l194.3.79Z"
                        fill="#f4f5f6"
                    />
                    <path
                        id="Path_141"
                        d="M363.81,201.12l-194.3-1.65V183.19l194.3,3.07Z"
                        fill="#f4f5f6"
                    />
                    <path
                        id="Path_142"
                        d="M199.57,249l-25.77.16V224.49h25.77Zm-22.76-3.22,18.9-.09V227.86h-18.9Z"
                        fill="#e3e2e3"
                    />
                    <path
                        id="Path_143"
                        d="M359.55,231.09l-149.24.09v-6.64l149.24.15Z"
                        fill="#e3e2e3"
                    />
                    <path
                        id="Path_144"
                        d="M359.55,241.41l-149.24.47v-6.27l149.24-.25Z"
                        fill="#e3e2e3"
                    />
                    <path
                        id="Path_145"
                        d="M199.57,285.25l-25.77.42V261.05l25.77-.24Zm-22.76-3,18.9-.3V264.18l-18.9.2Z"
                        fill="#e3e2e3"
                    />
                    <path
                        id="Path_146"
                        d="M359.55,265.94l-149.24,1.42v-6.65l149.24-1.17Z"
                        fill="#e3e2e3"
                    />
                    <path
                        id="Path_147"
                        d="M359.55,276.25l-149.24,1.81v-6.27l149.24-1.58Z"
                        fill="#e3e2e3"
                    />
                    <path
                        id="Path_148"
                        d="M199.57,321.54l-25.77.69V297.61l25.77-.51Zm-22.76-2.37,18.9-.5V300.51l-18.9.39Z"
                        fill="#e3e2e3"
                    />
                    <path
                        id="Path_149"
                        d="M359.55,300.79l-149.24,2.74v-6.65l149.24-2.49Z"
                        fill="#e3e2e3"
                    />
                    <path
                        id="Path_150"
                        d="M359.55,311.1l-149.24,3.13v-6.27l149.24-2.9Z"
                        fill="#e3e2e3"
                    />
                    <path
                        id="Path_151"
                        d="M182.76,247.82a2.77,2.77,0,0,1-2-.76l-7.75-7.32a2.57,2.57,0,0,1,0-3.76,2.91,2.91,0,0,1,3.95,0l5.37,5.1L194.78,224a2.84,2.84,0,0,1,3.83-.66,2.57,2.57,0,0,1,.69,3.67L185,246.67a2.8,2.8,0,0,1-2,1.14Z"
                        fill="#707171"
                    />
                    <path
                        id="Path_152"
                        d="M183.44,284.49a2.83,2.83,0,0,1-2-.76l-7.74-7.32a2.57,2.57,0,0,1,0-3.76,2.91,2.91,0,0,1,4,0l5.37,5.1,12.4-17.07a2.84,2.84,0,0,1,3.83-.66,2.56,2.56,0,0,1,.69,3.67l-14.25,19.66a2.82,2.82,0,0,1-2,1.14Z"
                        fill="#707171"
                    />
                    <path
                        id="Path_153"
                        d="M407.15,148.41c0,24.34-19,43.49-43.36,42.75-25.32-.78-46.62-22.65-46.62-48.82s21.3-45.16,46.62-42.53C388.12,102.35,407.15,124.07,407.15,148.41Z"
                        fill="url(#linear-gradient)"
                    />
                    <path
                        id="Path_154"
                        d="M413.73,146.31c0,24.34-19,43.5-43.37,42.75-25.32-.78-46.61-22.65-46.61-48.82S345,95.08,370.36,97.72C394.69,100.25,413.73,122,413.73,146.31Z"
                        fill="url(#linear-gradient-2)"
                    />
                    <path
                        id="Path_155"
                        d="M411.82,146.18c0,23.27-18.22,41.55-41.46,40.8C346.22,186.2,326,165.33,326,140.39s20.27-43.07,44.41-40.59C393.6,102.18,411.82,122.91,411.82,146.18Z"
                        fill="url(#linear-gradient-3)"
                    />
                    <path
                        id="Path_156"
                        d="M410.45,146.09c0,22.5-17.62,40.16-40.09,39.4-23.3-.78-42.85-20.94-42.85-45s19.55-41.58,42.85-39.22C392.83,103.56,410.45,123.59,410.45,146.09Z"
                        fill="#f4f5f6"
                    />
                    <path
                        id="Path_157"
                        d="M370.13,108.94h0a2.44,2.44,0,0,1,2.16,2.4v4.15a60.42,60.42,0,0,1,7.19,1.24c1.76.42,3.52.91,5.26,1.48a2.42,2.42,0,0,1,1.65,2.49l-.51,5.2a1.94,1.94,0,0,1-2.78,1.61,29.291,29.291,0,0,0-3.53-1.31,31.781,31.781,0,0,0-6.66-1.25h-.62v11.87c6.24,1.74,10.82,4.09,13.78,7a14.88,14.88,0,0,1,4.41,11,14.47,14.47,0,0,1-4.88,11.43q-4.91,4.32-13.8,4.86v4.57a2.08,2.08,0,0,1-2.17,2.11h0a2.29,2.29,0,0,1-2.17-2.29V171h-1a62.811,62.811,0,0,1-8-.86,52.3,52.3,0,0,1-5.53-1.27,2.38,2.38,0,0,1-1.73-2.46l.6-6.68a2.11,2.11,0,0,1,2.95-1.74,43.066,43.066,0,0,0,4.86,1.59,42,42,0,0,0,7.77,1.3v-13.2C361.44,146.14,357,144,354,141a15.05,15.05,0,0,1-4.44-11.2q0-7.05,4.81-10.87c3.19-2.54,7.73-3.8,13.58-3.8V110.9a2,2,0,0,1,2.18-1.96ZM368,124.78a7.27,7.27,0,0,0-4.32,1.52,4.44,4.44,0,0,0-1.56,3.56,4.75,4.75,0,0,0,1.44,3.54,10.07,10.07,0,0,0,4.44,2.27Zm3.84,23.9v12.1a6.69,6.69,0,0,0,4.63-1.43,5.21,5.21,0,0,0,1.61-4.08,5.55,5.55,0,0,0-1.5-4,11,11,0,0,0-4.78-2.59Z"
                        fill="url(#linear-gradient-4)"
                    />
                    <path
                        id="Path_158"
                        d="M363.11,151.6c1.35-13.38,13.65-19,12-31.34-1-7.59-6.74-14-13.15-19-19.45,2-34.49,18.14-34.49,39.21,0,24.05,19.55,44.21,42.85,45a40.189,40.189,0,0,0,6.62-.32C367.73,176,361.94,163.09,363.11,151.6Z"
                        fill="#707171"
                        opacity="0.2"
                    />
                    <path
                        id="Path_159"
                        d="M405.07,118.31v.19a82.217,82.217,0,0,0-6.43-7.26c0-.08-.09-.16-.12-.24a43.209,43.209,0,0,0-4.09-7.55A41.751,41.751,0,0,0,387.89,96c-.3-.3-.66-.57-1-.86l-.51-.42-.25-.22-.27-.2-1.06-.81-.27-.21-.28-.19-.55-.38c-.74-.53-1.53-1-2.29-1.47-.4-.24-.81-.45-1.21-.68l-.6-.33-.62-.31a38.429,38.429,0,0,0-10.58-3.53l-1.4-.26L365.63,86l-.7-.08h-6.19l-.85.06h-.63l-1.37.2a3.213,3.213,0,0,0-.68.11l-.67.13-.67.14-.34.06-.33.08-1.32.34a2.137,2.137,0,0,0-.64.19l-.65.2-.64.19-.62.23-1.25.46-1.21.52a34.909,34.909,0,0,0-8.51,5.35l-.46.39-.44.41c-.29.27-.59.53-.86.81h0c-.54.55-1.08,1.08-1.57,1.65-.13.14-.25.28-.37.43l-.19.21-.18.22-.7.87-.18.21-.16.23-.32.44c-.21.3-.44.57-.62.88a32.86,32.86,0,0,0-3.62,7,32.478,32.478,0,0,0-1.61,6.26L327,115c0,.23-.07.45-.08.67a12.316,12.316,0,0,1-.11,1.26,11.417,11.417,0,0,0,0,1.17v2a.919.919,0,0,0,0,.19v.46h15.63L333,131.27l-22.09,24.15-31.59-34.69H295.9a2.23,2.23,0,0,0,.08-.51c.06-.48.13-1,.21-1.52a6.822,6.822,0,0,1,.12-.86,3.791,3.791,0,0,1,.07-.45,3,3,0,0,1,.08-.45q.17-.87.36-1.83.1-.48.21-1c.07-.33.16-.68.25-1,.18-.7.36-1.43.58-2.19l.72-2.35c.12-.41.28-.81.43-1.23s.31-.82.46-1.25a59.05,59.05,0,0,1,14.28-21.61c.43-.44.91-.84,1.36-1.26.24-.2.47-.41.7-.62l.35-.32.36-.29,1.47-1.2.37-.3.39-.29.77-.57c1-.78,2.11-1.48,3.19-2.2.55-.35,1.11-.68,1.67-1l.85-.5.87-.47a56,56,0,0,1,15-5.61l2-.39c.67-.12,1.35-.21,2-.31l1-.15,1-.09,1-.1c.34,0,.67-.07,1-.08.68,0,1.35-.11,2-.15.17,0,.34-.07.51-.07h.5l1,.06h1a4.716,4.716,0,0,1,1,.07l2,.12h1.06l.85.1.42.06h.47l1.06.16,1.06.16.53.08.5.1,1.94.41c.32.07.64.16,1,.24l1,.26.95.25.94.3c.62.2,1.24.39,1.85.61l1.83.68a52.2,52.2,0,0,1,13,7.43l.7.55.69.58c.45.38.9.75,1.34,1.15.85.8,1.71,1.57,2.49,2.41l.59.61.3.31.28.31,1.1,1.26.27.32.26.32.52.64c.33.43.69.84,1,1.28a53.461,53.461,0,0,1,4.39,7c.59,1.13,1.11,2.25,1.58,3.36a49.8,49.8,0,0,1,2.93,9.48c.06.36.13.72.19,1.07s.14.68.18,1c.09.68.18,1.33.26,2s.11,1.23.16,1.81c0,.28.05.56.07.84s.07.53.08.79C405,117.35,405.07,117.85,405.07,118.31Z"
                        fill="url(#linear-gradient-5)"
                    />
                    <path
                        id="Path_160"
                        d="M405.07,118.31v.19a82.217,82.217,0,0,0-6.43-7.26c0-.08-.09-.16-.12-.24a43.209,43.209,0,0,0-4.09-7.55A41.751,41.751,0,0,0,387.89,96c-.3-.3-.66-.57-1-.86l-.51-.42-.25-.22-.27-.2-1.06-.81-.27-.21-.28-.19-.55-.38c-.74-.53-1.53-1-2.29-1.47-.4-.24-.81-.45-1.21-.68l-.6-.33-.62-.31a38.429,38.429,0,0,0-10.58-3.53l-1.4-.26L365.63,86l-.7-.08h-6.19l-.85.06h-.63l-1.37.2a3.213,3.213,0,0,0-.68.11l-.67.13-.67.14-.34.06-.33.08-1.32.34a2.137,2.137,0,0,0-.64.19l-.65.2-.64.19-.62.23-1.25.46-1.21.52a34.909,34.909,0,0,0-8.51,5.35l-.46.39-.44.41c-.29.27-.59.53-.86.81h0c7-8.69,18.39-14.34,31.22-14.34,13.15,0,24.76,5.93,31.73,15,.59,1.13,1.11,2.25,1.58,3.36a49.8,49.8,0,0,1,2.93,9.48c.06.36.13.72.19,1.07s.14.68.18,1c.09.68.18,1.33.26,2s.11,1.23.16,1.81c0,.28.05.56.07.84s.07.53.08.79C405,117.35,405.07,117.85,405.07,118.31Z"
                        fill="url(#linear-gradient-6)"
                    />
                    <path
                        id="Path_161"
                        d="M353.22,213.87s1-3.94.3-4.94-5.29-5-4.85-3.72a8.71,8.71,0,0,0,1.09,2.19s-2.45,1.13-.87,2.84,2.1,2.1,2.18,3.63S352.17,215.92,353.22,213.87Z"
                        fill="#e3e2e3"
                    />
                    <path
                        id="Path_162"
                        d="M396.9,383.73a35.392,35.392,0,0,1,.48,4.62c-.07,1.21-13.51,1.83-13.65.92s2.23-2.35,3.89-2.86,6.51-1.87,6.51-1.87Z"
                        fill="#606060"
                    />
                    <path
                        id="Path_163"
                        d="M393.64,380.34l.42,4.23a3.08,3.08,0,0,0,2.94.24l.69-4.61Z"
                        fill="#e3e2e3"
                    />
                    <path
                        id="Path_164"
                        d="M420.88,389.25a33.688,33.688,0,0,1,.48,4.61c-.07,1.21-13.51,1.83-13.64.93s2.22-2.35,3.88-2.86,6.51-1.87,6.51-1.87Z"
                        fill="#606060"
                    />
                    <path
                        id="Path_165"
                        d="M417.62,385.86l.43,4.23a3,3,0,0,0,2.93.23l.69-4.6Z"
                        fill="#e3e2e3"
                    />
                    <path
                        id="Path_166"
                        d="M396.07,269.86q-2.32,4.72-4.66,9.45c-1.25,18-1.87,37.52-1.47,58.42.3,15.56,1.13,30.29,2.32,44.12l6.32-.29a63.15,63.15,0,0,0,7-26.51c.34-11.8-3.23-13.36-3-24.65.3-14.64,6.43-18.54,9.7-36.15a93.91,93.91,0,0,0,1.09-26.19Z"
                        fill="url(#linear-gradient-7)"
                    />
                    <path
                        id="Path_167"
                        d="M413.34,268.06,404,269c-5.6,9.74-7.16,19.88-6.18,24.36,1.39,6.34,5.07,8.38,4.86,14.74s-3.91,7.94-6.27,15.37c-1.63,5.14-1.2,9.87-.27,19.12,1.87,18.63,6.37,24.32,2.6,30.43a13.73,13.73,0,0,1-6.78,5.38c.09,1.14.19,2.29.28,3.42l6.32-.29a63.15,63.15,0,0,0,7-26.51c.34-11.8-3.23-13.36-3-24.65.3-14.64,6.43-18.54,9.7-36.15a93.912,93.912,0,0,0,1.08-26.16Z"
                        fill="#606060"
                    />
                    <path
                        id="Path_168"
                        d="M404.43,275.53c2.92,15.9,5.5,32.92,7.51,51,2.42,21.8,3.72,42.35,4.23,61.43l6.93.19c9.11-18,9.67-30.24,8.36-38.2a115.086,115.086,0,0,1-2.2-18.06,126.657,126.657,0,0,1,.68-16.76c1.1-12.56,2.42-15.3,2.61-24.91.18-9,.22-13.58-2.35-16.25C423.41,266.93,405.77,274.91,404.43,275.53Z"
                        fill="url(#linear-gradient-8)"
                    />
                    <path
                        id="Path_169"
                        d="M354.43,213.26l-4.62-.32c-3.62,14.66-1,28.88,7.16,34.11,12.8,8.17,36.57-7.23,41.72-24,2.26-7.35,1.75-18-2.77-20-1.26-.58-3.2-.67-7.42,1.7-12.6,7.07-12.93,20.44-19.33,21.57C366.32,226.8,361.73,225,354.43,213.26Z"
                        fill="#707171"
                    />
                    <path
                        id="Path_170"
                        d="M389.64,282.53c-2.21-3.67.57-7.22,1.2-14.18.72-7.9-2.32-9.21-5.33-22.29-2-8.7-3.92-17.05-1.62-26.83,1.09-4.68,3-12.63,9.53-16.47,3.4-2,8-1.78,17.16-1.3,11.29.59,16.93.88,21.14,3.1.62.32,13.43,7.29,14.31,18.64.9,11.56-11.69,13.11-13.37,28.53-.75,6.91.59,17.56,1.46,24.46.84,6.62,1.41,8.32.11,10.33-3.16,4.83-12.81,3.45-32.1.7C394.55,286.14,391.3,285.28,389.64,282.53Z"
                        fill="#707171"
                    />
                    <path
                        id="Path_171"
                        d="M419,249.25c.81,3,3.75,5.24,5.79,12.55.44,1.58,3.58,12.81-.49,15.58-2.35,1.59-4.47-1.43-10.18-1.83-9.9-.68-14.62,7.64-21.08,5.37a8.88,8.88,0,0,1-4.08-3.17,6.51,6.51,0,0,0,.67,4.78c1.66,2.75,4.91,3.61,12.49,4.69,19.29,2.75,28.94,4.13,32.1-.7,1.3-2,.73-3.71-.11-10.33-.87-6.9-2.21-17.55-1.46-24.46,1.68-15.42,14.27-17,13.37-28.53a17.889,17.889,0,0,0-2.9-8.23C427.17,221.43,416.44,239.81,419,249.25Z"
                        fill="#606060"
                    />
                    <path
                        id="Path_172"
                        d="M439.17,272,436,274a7.49,7.49,0,0,0-4-2.46c-2.33-.51-5,.23-5.08,1s1.59,1.2,1.84,1.3c2,.75,3.55.12,4.37.94a6.54,6.54,0,0,0,1.49,1.33,5.589,5.589,0,0,0,.57.3c1.36.52,3.7-1.84,3.9-2Z"
                        fill="#e3e2e3"
                    />
                    <path
                        id="Path_173"
                        d="M429.74,230.08c-2.31-5.53-3.92-14-.27-26.59,24,9.48,36.32,31.94,31.53,49.75-3.95,14.68-18.31,21.83-21.52,23.34-.74-1.7-1.49-3.4-2.23-5.09,3.71-4.14,7.9-10.25,7.3-17.23C443.73,244.59,434.41,241.29,429.74,230.08Z"
                        fill="#707171"
                    />
                    <path
                        id="Path_174"
                        d="M411.17,190.07a25.671,25.671,0,0,0,1.5,7.36c1.18,2.85,7.38,4.64,8.64,5.27s-2.35,2.49-6,2.86-14-.46-15.86-1.84,1.5-1.27,4.09-2.22,4.09-6,3.63-8.32S411.17,190.07,411.17,190.07Z"
                        fill="#e3e2e3"
                    />
                    <path
                        id="Path_175"
                        d="M405.43,188.55c-1.89-1.86-2.15-2.07-2.35-2.71-.83-2.57,1.16-6.09,3.78-7,.13,0,1.89-.61,2.55.15s-.26,1.54.13,2.78,1.39,1.26,3.26,2.78c1.54,1.26,2.5,2,2.72,3.14.45,2.27-2.29,5.56-4.72,5.47C409.92,193.18,409.69,192.73,405.43,188.55Z"
                        fill="#606060"
                    />
                    <path
                        id="Path_176"
                        d="M410.56,193.56a5.34,5.34,0,0,1-5.05,1.52c-2.74-.68-2-6.94,0-8a3.56,3.56,0,0,1,2.79.14c.54.26.69.53,1.58.93s1.07.33,1.29.59.4,1-.46,2.76a1.51,1.51,0,0,1,1.7-.24.9.9,0,0,1,.3.42C412.93,192.51,412.13,193.29,410.56,193.56Z"
                        fill="#e3e2e3"
                    />
                    <path
                        id="Path_177"
                        d="M335.93,208c0,17.26-13.5,30.84-30.74,30.31-18-.55-33.05-16.06-33.05-34.61s15.09-32,33.05-30.15C322.43,175.38,335.93,190.78,335.93,208Z"
                        fill="url(#linear-gradient-9)"
                    />
                    <path
                        id="Path_178"
                        d="M340.59,206.55c0,17.26-13.49,30.84-30.74,30.31-18-.55-33.05-16.05-33.05-34.61s15.09-32,33.05-30.15C327.1,173.9,340.59,189.29,340.59,206.55Z"
                        fill="url(#linear-gradient-10)"
                    />
                    <path
                        id="Path_179"
                        d="M339.24,206.46c0,16.5-12.92,29.46-29.39,28.93-17.12-.56-31.49-15.36-31.49-33s14.37-30.54,31.49-28.78C326.32,175.26,339.24,190,339.24,206.46Z"
                        fill="#707171"
                    />
                    <path
                        id="Path_180"
                        d="M338.27,206.4c0,15.95-12.5,28.47-28.42,27.93-16.53-.55-30.38-14.84-30.38-31.9s13.85-29.47,30.38-27.8C325.77,176.24,338.27,190.44,338.27,206.4Z"
                        fill="#f4f5f6"
                    />
                    <path
                        id="Path_181"
                        d="M309.68,180.06h0a1.74,1.74,0,0,1,1.54,1.7v2.94a43.367,43.367,0,0,1,5.09.88c1.25.3,2.5.65,3.73,1a1.72,1.72,0,0,1,1.17,1.77l-.36,3.68a1.38,1.38,0,0,1-2,1.15,20.34,20.34,0,0,0-2.5-.94,23.16,23.16,0,0,0-4.73-.88h-.43v8.42c4.42,1.23,7.66,2.9,9.76,5a10.55,10.55,0,0,1,3.13,7.8,10.25,10.25,0,0,1-3.46,8.1q-3.48,3.07-9.79,3.45v3.24a1.47,1.47,0,0,1-1.53,1.5h0a1.64,1.64,0,0,1-1.54-1.63V224h-.68a46,46,0,0,1-5.67-.6c-1.34-.24-2.64-.54-3.92-.9a1.7,1.7,0,0,1-1.23-1.75l.43-4.74a1.5,1.5,0,0,1,2.09-1.23,30.561,30.561,0,0,0,9,2.05v-9.36c-4.24-1-7.42-2.6-9.51-4.66a10.71,10.71,0,0,1-3.15-7.94,9.28,9.28,0,0,1,3.42-7.71c2.26-1.8,5.48-2.69,9.62-2.69v-3a1.38,1.38,0,0,1,1.52-1.41Zm-1.54,11.23a5.1,5.1,0,0,0-3.06,1.08,3.12,3.12,0,0,0-1.1,2.52,3.39,3.39,0,0,0,1,2.51,7.14,7.14,0,0,0,3.14,1.61Zm2.72,16.94v8.58a4.7,4.7,0,0,0,3.29-1,3.66,3.66,0,0,0,1.14-2.88,3.94,3.94,0,0,0-1.06-2.83A7.87,7.87,0,0,0,310.86,208.23Z"
                        fill="url(#linear-gradient-11)"
                    />
                    <path
                        id="Path_182"
                        d="M304.7,210.3c1-9.48,9.68-13.5,8.54-22.22-.7-5.38-4.77-9.91-9.32-13.45-13.79,1.45-24.45,12.87-24.45,27.8,0,17.06,13.85,31.35,30.38,31.9a27.931,27.931,0,0,0,4.69-.23C308,227.57,303.88,218.45,304.7,210.3Z"
                        fill="#707171"
                        opacity="0.2"
                    />
                </g>
            </g>
        </SVGIcon>
    );
}
