import React from 'react';

import { SVGIcon } from '../../UIKit/index';

export default function DCAffiliate(props) {
    return (
        <SVGIcon {...props} viewBox="0 0 512 512">
            <g>
                <path
                    d="m327.7 375.7a7.971 7.971 0 0 1 -5.657-2.344l-111.7-111.7a8 8 0 0 1 0-11.314l114.2-114.195a8 8 0 1 1 11.315 11.313l-108.545 108.54 106.046 106.046a8 8 0 0 1 -5.659 13.654z"
                    fill="#6ee7f5"
                />
                <circle cx="144" cy="256" fill="#fbb540" r="72" />
                <circle cx="368" cy="96" fill="#2ad3b3" r="72" />
                <path
                    d="m416 138.42v11.24a71.984 71.984 0 0 1 -96 0v-11.24a34.437 34.437 0 0 1 34.42-34.42h27.16a34.415 34.415 0 0 1 34.42 34.42z"
                    fill="#2ad3b3"
                />
                <path
                    d="m157.923 255.434-19.2-12.33a5.233 5.233 0 0 1 -2.723-4.229v-1.175a4.852 4.852 0 0 1 1.89-3.611 9.974 9.974 0 0 1 12.22 0 4.848 4.848 0 0 1 1.89 3.611 8 8 0 0 0 16 0 20.855 20.855 0 0 0 -7.483-15.764 24.466 24.466 0 0 0 -8.517-4.69v-9.246a8 8 0 0 0 -16 0v9.246a24.482 24.482 0 0 0 -8.517 4.69 20.855 20.855 0 0 0 -7.483 15.764v1.175a21.166 21.166 0 0 0 10.077 17.691l19.2 12.33a5.233 5.233 0 0 1 2.723 4.229v1.175a4.852 4.852 0 0 1 -1.89 3.611 9.974 9.974 0 0 1 -12.22 0 4.848 4.848 0 0 1 -1.89-3.611 8 8 0 0 0 -16 0 20.855 20.855 0 0 0 7.483 15.764 24.466 24.466 0 0 0 8.517 4.69v9.246a8 8 0 0 0 16 0v-9.246a24.482 24.482 0 0 0 8.517-4.69 20.855 20.855 0 0 0 7.483-15.764v-1.175a21.166 21.166 0 0 0 -10.077-17.691z"
                    fill="#d18d28"
                />
                <path
                    d="m416 138.42v11.24a71.984 71.984 0 0 1 -96 0v-11.24a34.437 34.437 0 0 1 34.42-34.42h27.16a34.415 34.415 0 0 1 34.42 34.42z"
                    fill="#04957c"
                />
                <path
                    d="m397.67 108a32.008 32.008 0 0 1 -59.34 0 34.42 34.42 0 0 1 16.09-4h27.16a34.361 34.361 0 0 1 16.09 4z"
                    fill="#04957c"
                />
                <path
                    d="m397.67 108a32.008 32.008 0 0 1 -59.34 0 34.42 34.42 0 0 1 16.09-4h27.16a34.361 34.361 0 0 1 16.09 4z"
                    fill="#03705d"
                />
                <circle cx="368" cy="416" fill="#41b2ff" r="72" />
                <path
                    d="m416 458.42v11.24a71.984 71.984 0 0 1 -96 0v-11.24a34.437 34.437 0 0 1 34.42-34.42h27.16a34.415 34.415 0 0 1 34.42 34.42z"
                    fill="#41b2ff"
                />
                <path
                    d="m416 458.42v11.24a71.984 71.984 0 0 1 -96 0v-11.24a34.437 34.437 0 0 1 34.42-34.42h27.16a34.415 34.415 0 0 1 34.42 34.42z"
                    fill="#007af6"
                />
                <path
                    d="m397.67 428a32.008 32.008 0 0 1 -59.34 0 34.42 34.42 0 0 1 16.09-4h27.16a34.361 34.361 0 0 1 16.09 4z"
                    fill="#009cf7"
                />
                <path
                    d="m397.67 428a32.008 32.008 0 0 1 -59.34 0 34.42 34.42 0 0 1 16.09-4h27.16a34.361 34.361 0 0 1 16.09 4z"
                    fill="#0a71cd"
                />
                <circle cx="368" cy="88" fill="#04957c" r="32" />
                <circle cx="368" cy="408" fill="#007af6" r="32" />
            </g>
        </SVGIcon>
    );
}
