import React from 'react';

import { SVGIcon } from '../../UIKit/index';

export default function DCPSDFile(props) {
    return (
        <SVGIcon {...props} viewBox="0 0 512 512">
            <path
                d="m392 488h-272a48 48 0 0 1 -48-48v-368a48 48 0 0 1 48-48h224l96 96v320a48 48 0 0 1 -48 48z"
                fill="#005eff33"
            />
            <path d="m256 296-112-112 32-48h160l32 48z" fill="#005eff" />
            <path d="m176 136-32 48h224l-32-48z" fill="#005eff50" />
            <path
                d="m72 360h368a0 0 0 0 1 0 0v80a48 48 0 0 1 -48 48h-272a48 48 0 0 1 -48-48v-80a0 0 0 0 1 0 0z"
                fill="#005eff"
            />
            <path d="m440 120h-48a48 48 0 0 1 -48-48v-48z" fill="#005eff50" />
            <g fill="#fff">
                <path d="m256 400h16a8 8 0 0 0 0-16h-16a24 24 0 0 0 0 48 8 8 0 0 1 0 16h-16a8 8 0 0 0 0 16h16a24 24 0 0 0 0-48 8 8 0 0 1 0-16z" />
                <path d="m192 384h-16a8 8 0 0 0 -8 8v64a8 8 0 0 0 16 0v-24h8a24 24 0 0 0 0-48zm0 32h-8v-16h8a8 8 0 0 1 0 16z" />
                <path d="m304 384a8 8 0 0 0 -8 8v64a8 8 0 0 0 8 8 40 40 0 0 0 0-80zm8 62.629v-45.258a24 24 0 0 1 0 45.258z" />
            </g>
            <path d="m256 296 48-112h-96z" fill="#005eff50" />
            <path d="m232 136-24 48h96l-24-48z" fill="#005eff" />
        </SVGIcon>
    );
}
