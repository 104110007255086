import React from 'react';

import { SVGIcon } from '../../UIKit/index';

export default function DCFourBox(props) {
    return (
        <SVGIcon {...props} viewBox="0 0 512 512">
            <path d="M458.36-0.49H53.03C23.63-0.49-0.3,23.42-0.3,52.84v405.34c0,29.42,23.94,53.33,53.33,53.33h405.34  c29.39,0,53.33-23.91,53.33-53.33V52.84C511.7,23.42,487.76-0.49,458.36-0.49z M469.03,52.84v181.34h-192v-192h181.34  C464.25,42.17,469.03,46.97,469.03,52.84z M53.03,42.17h181.34v192h-192V52.84C42.36,46.97,47.14,42.17,53.03,42.17z M42.36,458.17  V276.84h192v192H53.03C47.14,468.84,42.36,464.04,42.36,458.17z M458.36,468.84H277.03v-192h192v181.34  C469.03,464.04,464.25,468.84,458.36,468.84z" />
        </SVGIcon>
    );
}
