import React from 'react';

import { SVGIcon } from '../../UIKit/index';

export default function DCBuildInstaReport1(props) {
    return (
        <SVGIcon {...props} viewBox="0 0 512 512">
            <path d="M509.6,385.9V-0.7H2.1v386.6h193.3v59.5h-29.7v29.7H346v-29.7h-29.7v-59.5H509.6z M31.8,356.2  v-39.7h299.4v-29.7H143l79.6-79.6l44.6,44.6l123.4-123.4v36.5h29.7V75.7h-89.2v29.7h40.4L267.2,209.8l-44.6-44.6L101,286.8H31.8  V29.1h448.1v327.1H31.8z M286.6,445.4h-61.5v-59.5h61.5V445.4z" />
            <path d="M360.9,286.8h29.7v29.7h-29.7V286.8z" />
            <path d="M420.4,286.8h29.7v29.7h-29.7V286.8z" />
        </SVGIcon>
    );
}
