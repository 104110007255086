import payWallLimits from 'Constants/payWallLimits';
import { getAuthDetails } from 'Utils/localStorageUtils';

const isUnderPayWall = (field, currentLimit) => {
    const type = getAuthDetails()?.account?.plan.tier || 'tier0';
    const planLimit = payWallLimits[type][field];

    if (planLimit.unit === 0) {
        return { success: false, data: [] };
    }
    if (planLimit.unit === -1) {
        return { success: true };
    }
    if (currentLimit < planLimit.unit) {
        return { success: true };
    } else {
        return { success: false, data: [] };
    }
};

export default isUnderPayWall;
